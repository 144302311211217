import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom'
import {
  Nav,
  Navbar,
  NavItem
} from 'react-bootstrap/lib/'

// Image assets
import iconQuiniela from '../../assets/images/icon-quiniela.png'
import iconApuestas from '../../assets/images/icon-apuestas.png'
import iconCasino from '../../assets/images/icon-casino.png'
import logoBrand from '../../assets/images/logoMx.png'
import iconInvitar from '../../assets/images/invitarIconBlanco-50.png'
import iconSlots from '../../assets/images/icon-slots.png'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Navbar for not logged user
export default function GuestNavBar () {
  const { openLoginModal, openSignupModal } = useAuthContext()

  return (
    <Navbar default defaultExpanded fluid collapseOnSelect>
      <Navbar.Header>
        <Navbar.Brand>
          <NavLink activeClassName='active' to='/'>
            <img src={logoBrand} className='pickwin-logo-not-logged' />
          </NavLink>
        </Navbar.Brand>
      </Navbar.Header>
      <Nav className='pickwin-nav-right' pullLeft>
        <LinkContainer activeClassName='activeNav' to='/' style={{ borderLeft: '0px', borderRight: '1px solid white', marginLeft: '1rem' }}>
          <NavItem className='nav-item' eventKey={2}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={iconApuestas} height='22' />
              <div style={{ width: '65px', lineHeight: '0px' }}>
                <span className='textNavbar'>APUESTAS DEPORTIVAS</span>
              </div>
            </div>
          </NavItem>
        </LinkContainer>
        <LinkContainer activeClassName='activeNav' to='/casino' style={{ borderLeft: '0px' }}>
          <NavItem className='nav-item' eventKey={2}>
            <MediaQuery minWidth={1110}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={iconCasino} height='22' />
                <div style={{ width: '40px', lineHeight: '0px' }}>
                  <span className='textNavbar'>CASINO</span>
                </div>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
              <img src={iconCasino} height='22' />
              <div className='nav-subtitle'>CASINO</div>
            </MediaQuery>
          </NavItem>
        </LinkContainer>
        <LinkContainer activeClassName='activeNav' to='/slots'>
            <NavItem className='nav-item' eventKey={2}>
              <MediaQuery minWidth={1110}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={iconSlots} height='22' />
                  <div style={{ width: '30px', lineHeight: '0px' }}>
                    <span className='textNavbar'>SLOTS</span>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
                <img src={iconSlots} height='22' />
                <div className='nav-subtitle'>SLOTS</div>
              </MediaQuery>
            </NavItem>
          </LinkContainer>
        <LinkContainer activeClassName='activeNav' to='/lobby' style={{ borderLeft: '0px' }}>
          <NavItem className='nav-item' eventKey={2}>
            <MediaQuery minWidth={1110}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={iconQuiniela} height='22' />
                <div style={{ width: '40px', lineHeight: '0px' }}>
                  <span className='textNavbar'>QUINIELAS</span>
                </div>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
              <img src={iconQuiniela} height='22' />
              <div className='nav-subtitle'>QUINIELAS</div>
            </MediaQuery>
          </NavItem>
        </LinkContainer>

      </Nav>
      <Nav className='pickwin-nav-right' pullRight>
        <NavItem className='nav-item' onClick={openSignupModal} eventKey={7}>
          <span className='textNavbar'>
            <img src={iconInvitar} className='img-responsive navbar-icons' /> REGÍSTRATE
          </span>
        </NavItem>
        <NavItem className='nav-item' onClick={openLoginModal} eventKey={6}>
          <span className='textNavbar'>
            <i className='ion-log-in' /> INGRESAR
          </span>
        </NavItem>
      </Nav>
    </Navbar>
  )
}
