import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import utils from '../utils/utils'

// Hooks
import useAuthContext from '../hooks/useAuthContext'

/**
 * This component cant be accessed by the user if he istn logged
 * This returns a redirection to the lobby
 */
const PrivateRoute = ({ gameType, component: Component, ...rest }) => {
  const { openLoginModal, loginStatus } = useAuthContext()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          utils.getReferer() === null ||
          utils.getReferer() === undefined ||
          utils.getReferer() === 'undefined'
        ) {
          const search = props.location.search
  
          if (search !== undefined || search !== null || search !== 'undefined') {
            const params = new URLSearchParams(search)
            utils.setReferer(params.get('referer'))
          }
        }
        if (utils.isLogged()) {
          if (!utils.isVerified(utils.getUserInfo())) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { prevLocation: window.location.pathname },
                }}
              />
            )
          } else {
            return <Component {...props} />
          }
        } else {
          if (props.location) {
            let redirObj
            let gameId = null
            let entryId = props.match.params.entryId
  
            switch (gameType) {
              case 'survivor':
                gameId = props.match.params.survivorId
                break;
              case 'bracket':
                gameId = props.match.params.bracketId
                break;
              case 'quiniela':
                gameId = props.match.params.poolId
                break;
              case 'quinielaGrupo':
                gameId = props.match.params.poolGroupId
                break;
            }
  
            redirObj = { id: gameId, game_type: gameType, entry_id: entryId }
  
            if (redirObj.id || redirObj.gameType) {
              utils.saveDataNotLoggedUser(redirObj)
            } else {
              console.error('not a game url')
            }
          }

          if (!loginStatus) {
            openLoginModal()
          }

          return null
        }
      }}
    />
  )
}

export default PrivateRoute

