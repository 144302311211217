import React from 'react'
import styled, { keyframes } from 'styled-components'

export default function ModalBody (props) {
  const stopPropagation = (event) => {
    event.stopPropagation()
    const el = document.querySelector('.fade[tabindex="-1"]')

    if (el) {
      el.removeAttribute('tabindex')
    }
  }

  return (
    <Content onMouseDown={stopPropagation} style={props.style}>
      {props.children}
    </Content>
  )
}

const Content = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: auto 0;
`
