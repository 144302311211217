import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import numeral from 'numeral'

// Components
import Ripples from './Ripples'

// Assets
import imgArrow from '../../assets/img/Flechas verdes.png'
import useUserContext from '../../hooks/useUserContext'

// If user update in progress only is animated in Navbar
// But if user is levelup then is open modal progress and play the video
// ------------------------------------------------------------------------------------
export default function ProgressBarLevel () {
  const { dynamicProgress, showRipples } = useUserContext()

  return (
    <Link activeClassName='activeNav' to='/usuario/recompensas'>
      <Box>
        <Bar>
          {showRipples && (
            <ArrowSmall src={imgArrow} />
          )}
          <BarContainer>
            <Progress style={{ width: dynamicProgress }} />
          </BarContainer>
          <Ripples show={showRipples} />
        </Bar>
        <Text>{numeral(dynamicProgress / 100).format('0%')}</Text>
      </Box>
    </Link>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  width: 140px;
  height: 15px;
`

const Bar = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 0.5em;
  position: relative;
  flex-grow: 50;
  transition: 1.3s ease;
  box-shadow: 0 0 0 0 transparent;
`

const BarContainer = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 0.5em;
  border: 1px solid #FFF;
  position: relative;
  overflow: hidden;
  flex-grow: 50;
  transition: 1.3s ease;
  border: 1px solid #FFF;
  z-index: 2;
`

const Progress = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #78BC28;
  transition: width 10ms linear;
`

const Text = styled.div`
  flex-grow: 1;
`

const animaArrow = keyframes`
  0% {
    opacity: 0;
    transform: rotate(90deg) translateY(50px) scale(0);
  }

  50% {
    opacity: 1;
    transform: rotate(90deg) translateY(-20px) scale(1);
  }

  100% {
    transform: rotate(90deg) translateY(-120px) scale(0);
    opacity: 0;
  }
`

const ArrowSmall = styled.img`
  width: 1.7em;
  transform: rotate(90deg);
  position: absolute;
  top: -1.5em;
  opacity: 0;
  animation: 3s ease ${animaArrow} 500 forwards 2s;
`
