import React from 'react';
import PicksGrupo from '../PicksGrupo/PicksGrupo';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import {
  Nav,
  NavItem,
  ControlLabel,
} from 'react-bootstrap/lib';

import Marcadores from '../Marcadores/Marcadores';
import Envivo from '../Envivo/Envivo';
import ChatPanel from '../ChatPanel/ChatPanel';
import {
  MainHeaderPicksGrupo
} from '../../utils/quinielasComponents';
import Utils from '../../utils/quinielasUtils';
import API from '../../utils/api';
import TablaPosiciones from '../TablaPosiciones/TablaPosiciones';
import $ from 'jquery';
import PicksGrupoBracket from '../PicksGrupoBracket';
import Premios from '../Premios';
import './bracketPointsInfoContainer.scss';
import '../BracketPoints/bracketPoints.scss';
import BracketPointsResults from '../BracketPoints/components/BracketPointsResults';
import BracketPointsGroupPicks from '../BracketPoints/components/BracketPointsGroupPicks';
import BracketPoints from '../BracketPoints/BracketPoints';
import BracketSoccerScoreboard from '../BracketSoccerScoreboard';

class BracketPointsInfoContainer extends React.Component {
  constructor(props) {
    super(props);
    let tempState = this.props.estado;
    if (tempState === undefined) {
      tempState = {};
      tempState.actualPage = 0;
      tempState.posActualPage = 0;
      tempState.actualRound = 0;
      tempState.scrollElement = null;
    }

    this.state = tempState;

    this.selectSection = this.selectSection.bind(this);
    this.getAganarBracket = Utils.getAganaBracket.bind(this);
    this.calculateTiesPrizes = Utils.calculateTiesPrizes.bind(this);
    this.nextPageStandings = Utils.nextPageStandings.bind(this);
    this.siguientePagPositions = this.siguientePagPositions.bind(this);
    this.updateStandingsFixturePoints = Utils.updateStandingsFixturePoints.bind(this);
    this.updateStandingsPropsPoints = Utils.updateStandingsPropsPoints.bind(this);
    this.updateStandingsPosition = Utils.updateStandingsPosition.bind(this);
    this.updateStandingsAganar = Utils.updateStandingsAganar.bind(this);
    this.updateState = this.updateState.bind(this);

    this.changeRound = (e) => {
      console.log('%c ChangeRound ', 'color: rgb(213, 210, 57); font-size:2em;');
      console.log(e);
      this.setState({
        activeRound: e,
      });
    };
  }

  /**
   * This function handles a call to the API
   * to get the new page of the positions
   */
  siguientePagPositions(eventKey) {
    console.log('Next Positions...');
    let loaderElement = $('#loading');
    let tempActiveSort = null;
    // console.log(loaderElement);
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex';
    }
    // loading.style.position('flex');
    let temporalPositions = this.props.posiciones.items;
    // console.log('siguiente');
    //let val = eventKey;

    this.props.queryParams.page = this.props.queryParams.page + 1;

    API.fetchBracketStandings(
      this.props.poolInfo.bracket.id,
      this.props.queryParams
    ).then((response) => {
      this.setState(
        (prevState) => {
          console.log(tempActiveSort);
          let newPosiciones = response.data;

          let newItems = response.data.items !== null ? response.data.items : null;

          newItems.forEach((item) => {
            temporalPositions.push(item);
          });

          if (this.props.poolInfo.status === 'live') {
            this.updateStandingsPosition(temporalPositions);
            this.updateStandingsAganar(temporalPositions);
            // temporalPositions.sort(firstBy('total_possibly_correct', -1).thenBy('entry_id'));
          } else {
            // temporalPositions.sort(firstBy('rank').thenBy('entry_id'));
          }

          // console.log('NEWPos',temporalPositions);
          newPosiciones.items = temporalPositions;
          return {
            posiciones: newPosiciones,
            posActualPage: this.props.queryParams + 1,
          };
        },
        () => {
          // if(this.props.sortPositionList !== undefined){
          //   setTimeout( () => {
          //     console.log(tempActiveSort);
          //     this.props.sortPositionList( tempActiveSort, true );
          //   }, 150);
          // }
          if (this.updateScrollPositions !== undefined) {
            this.updateScrollPostions(this.state.posiciones);
          }
          if (loaderElement !== undefined && loaderElement.length > 0) {
            loaderElement[0].style.display = 'none';
            //let avatars = $('.score-avatars').height('auto');
          }
        }
      );
    });
  }

  selectSection(which) {
    let weekId;
    if (this.props.poolInfo.pool === undefined) {
      weekId = null;
    } else {
      if (this.props.poolInfo.pool.type === 'week') {
        if (this.props.poolInfo.pool.week_id === null) {
          weekId = this.props.fixtures[0].week_id;
          if (this.props.poolInfo.tiebreakers[weekId] === undefined) {
            weekId = '';
          }
        } else {
          weekId = this.props.poolInfo.pool.week_id;
        }
      } else if (this.props.poolInfo.pool.type === 'full' && this.props.poolWeekSelected !== null) {
        weekId = this.props.poolWeekSelected;
      } else {
        weekId = this.state.poolWeeks.current.id;
      }
    }
    if (which === undefined) {
      which = 0;
    }

    //which = 2;
    switch (Number(which)) {
      case 0:
        return (
          <div className=" flex-row multiple-quiniela-info principal">
            <div className="flex-col">
              <BracketPoints
                bracket={this.props.bracketInfo}
                userData={this.props.userData}
                isLogged={this.props.logged}
                showLoginPopUp={this.props.showLoginPopUp}
                showRegister={this.props.showReRegister}
                isPopUp={false}
                matches={this.props.bracketMatches}
                stages={this.props.bracketStages}
                groups={this.props.bracketGroups}
                standings={this.props.standings}
                tickets={this.props.bracketTickets}
                siguientePagPositions={this.props.siguientePagPositions}
                updateUserInfo={this.props.updateUserInfo}
                selectedEntryData={this.props.selectedEntryData}
                entryId={
                  this.props.selectedEntry !== null &&
                  this.props.selectedEntry !== undefined
                    ? this.props.selectedEntry
                    : null
                }
                refreshAfterGuardar={this.props.refreshAfterGuardar}
                showAbonar={this.props.showAbonar}
              />
            </div>
          </div>
        );
        break;
      case 1:
        return (
          <div className="flex-row multiple-quiniela-info principal">
            <div className="first-section col-xs-12 col-sm-6">
              <TablaPosiciones
                isVisible={true}
                actualPage={this.state.posActualPage}
                changeSearchNick={this.props.changeSearchNick}
                entryId={this.props.selectedEntry}
                entry={this.props.selectedEntryData}
                fetchSearchableStandings={this.props.fetchSearchableStandings}
                loaded={this.props.loaded}
                nextPageStandings={this.props.nextPageStandings}
                poolInfo={this.props.bracketInfo}
                // activeStage={this.props.activeStage}
                posiciones={this.props.standings}
                resetNickValue={this.props.resetNickValue}
                siguientePagPositions={this.props.siguientePagPositions}
                selectedEntry={this.props.selectedEntry}
                standings={this.props.standings}
                submittingSearch={this.props.submittingSearch}
                type="bracket"
                userData={this.props.userData}
                activeSort={this.props.activeSort}
                getAganar={this.getAganarBracket}
                premios={this.props.bracketPrizes}
                sortPositionList={this.props.sortPositionList}
                tiesDistribution={this.props.bracketTiesDistribution}
                calculateTiesPrizes={this.calculateTiesPrizes}
              />
            </div>

            <div className="second-section col-xs-12 col-sm-3">
              {this.props.logged ? (
                <ChatPanel
                  gameType="bracket"
                  entries={this.props.entries}
                  handleMessage={this.props.handleMessage}
                  handleMaxHeight={this.props.handleMaxHeight}
                  loaded={this.props.loaded}
                  logged={this.props.logged}
                  newMessage={this.props.newMessage}
                  selectedEntry={this.props.selectedEntry}
                  resetMessage={this.props.resetMessage}
                  quinielaID={this.props.bracketInfo.id}
                  sendMessage={this.props.sendMessage}
                  userAvatar={this.props.logged ? this.props.userData : null}
                  userNick={this.props.logged ? this.props.userData.nick : null}
                  withRegister={this.props.withRegister}
                />
              ) : (
                <div className="message-not-logged">
                  {' '}
                  Necesitas Ingresar para poder usar el chat
                </div>
              )}
            </div>

            <div className="col-xs-12 col-sm-3 border-gradient" style={{margin: '14px 8px'}}>
              <Premios
                weeks={null}
                estado={this.props.loaded}
                loaded={this.props.loaded}
                poolInfo={this.props.bracketInfo}
                poolWeekSelected={null}
                premios={this.props.bracketPrizes}
                standings={this.props.standings}
                type="lobby"
                game="series"
              />
            </div>
          </div>
        );
        break;
      case 2:
        return (
          <BracketPointsGroupPicks
            currentStage={this.props.bracketCurrentStage}
            bracketStages={this.props.bracketStages}
            bracketTeams={this.props.bracketTeams}
            matches={this.props.bracketMatches}
            selectedEntryData={this.props.selectedEntryData}
            bracketInfo={this.props.bracketInfo}
            entryId={this.props.selectedEntry}
            groupPicks={this.props.bracketGroupPicks}
            // eventHandlers
            onStageChange={this.props.onStageChange}
            loadMoreStandings={this.props.loadMoreStandings}
            loadingGroup={this.props.loadingGroup}
          />
        );
        break;
      case 3:
        return (
          <div className="multiple-quiniela-info marcadores">
            <div className="first-section col-xs-12 col-md- 6">
              <BracketSoccerScoreboard bracket={this.props.bracketInfo} webSocket={this.props.websocket}></BracketSoccerScoreboard>
            </div>
          </div>
        );
        break;
      case 4:
        return (
          <div className="multiple-quiniela-info live">
            <div className="first-section col-xs-12">
              <Envivo
                liveFixturesData={this.props.liveFixturesData}
                fixtures={this.state.fixtures}
                logged={this.state.logged}
                poolInfo={this.state.poolInfo}
                userData={this.state.userData}
                posiciones={this.props.posiciones}
                premios={this.state.premios}
              />
            </div>
          </div>
        );
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {

  }

  updateState() {

  }

  componentDidMount() {
    this.setState({
      premios: this.props.bracketPrizes,
    });
  }

  render() {
    let conditionalComponent = this.selectSection(this.props.selection);
    return conditionalComponent;
  }
}

export default BracketPointsInfoContainer;
