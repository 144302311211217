import React from 'react';
import { Nav, NavItem } from 'react-bootstrap/lib';

import { QuinielaGroupsContextProvider } from '../../context/QuinielaGroupsContext';

import utils from '../../utils/utils';

import TablaPosicionesGroupPool from '../TablaPosicionesGroupPool';
import TablaPosicionesGroupPoolF1 from '../TablaPosicionesGroupPool/TablaPosicionesGroupPoolF1';
import ChatPanel from '../ChatPanel';

import QuinielaGroupsUI from '../QuinielaGroupsUI';
import ContestControlsF1 from '../QuinielaGroupsUI/ContestControlsF1';
import Premios from '../Premios';
import PremiosF1 from '../Premios/PremiosF1';
import PointsDistribution from '../PointsDistribution';
import PointsDistributionF1 from '../PointsDistribution/PointsDistributionF1';

import Leaderboard from '../Leaderboard/Leaderboard';
import LeaderboardF1 from '../Leaderboard/LeaderboardF1';
import PicksGrupo from '../PicksGrupo/PicksGrupo';
import PicksGrupoPoolGroup from '../PicksGrupoPoolGroup';
import PicksGrupoPoolGroupGolf from '../PicksGrupoPoolGroup/PicksGrupoPoolGroupGolf'
import PicksGrupoPoolGroupF1 from '../PicksGrupoPoolGroup/PicksGrupoPoolGroupF1';
import API from '../../utils/api';
import _ from 'underscore';
import actions from '../QuinielaGroupsUI/actions';

const HandleLayout = ({
  selectedEntry,
  allEntries,
  poolId,
  dispatch,
  withRegister,
  newMessage,
  myEntry,
  poolInfo,
  selection,
  changeSection,
  searchableNick,
  queryParams,
  prizes,
  activeSort,
  submittingSearch,
  standings,
  userToAdd,
  errorFollow,
  successFollow,
  submittingInvite,
  inviteStatus,
  showAddConfirmation,
  currentContest,
  contestStandings,
  contestPrizes,
  contestTiesDistribution,
}) => {
  let conditionalComponent = null;

  let infoContainer = null;
  selection = Number(selection);
  const [isFull, setIsFull] = React.useState('full');

  const handleContestChange = (contestId) => {
    // Update contest
    let currentContest = poolInfo.contests.find((item) => item.id === contestId);
    dispatch({ type: 'updateCurrentContest', payload: { currentContest } });

    // Update players
    API.fetchPoolGroupPlayers(poolInfo.id, contestId)
    .then((response) => {
      let bucketPlayers = [];
      _.forEach(response.data.players, (item, index) => {
        item.bucket = null;
        bucketPlayers.push(item);
      });

      let buckets = {};
      poolInfo.contests.map((contest) => {
        buckets[contest.id] = [];
        for (let i = 0; i < poolInfo.num_picks; i++) {
          const obj = {};
          obj['id'] = i+1;
          obj['players'] = bucketPlayers;
          obj['isOpen'] = false;
          obj['player'] = null;
          obj['pick'] = null;
          buckets[contest.id][i] = obj;
        }
      });
      dispatch({ type: 'updatePlayersF1', payload: { players: response.data.players, buckets } });
    });

    // update group picks
    API.fetchPoolGroupGroupPicks(poolInfo.id, null, contestId)
      .then((responseGroupPicks) => {
        dispatch({ type: 'setGroupPicks', payload: { groupPicks: responseGroupPicks.data } });
      });

    // update contest standings
    API.getContestStandings(poolInfo.id, contestId).then((responseContestStandings) => {
      dispatch({ type: 'updateContestStandings', payload: { contestStandings: responseContestStandings.data } });

      //update aganar contest
      let fakeObj = {
        contest_id: currentContest.id,
        group_pool_id: poolInfo.id,
        standings: responseContestStandings.data.items,
        ties_distribution: responseContestStandings.data.ties_distribution,
      };
      actions.updateStandingsAmountContest(dispatch, fakeObj);
    });
  }

  const ControlWeek = ({ whichList, changePosiciones, currentWeekName }) => {
    return (
      <Nav
        bsStyle="pills"
        className="flex-row row-no-padding tablas-weekley"
        activeKey={whichList}
        onSelect={changePosiciones}
      >
        <NavItem eventKey={'full'}>General</NavItem>
        <NavItem eventKey={'contest'}>{currentWeekName}</NavItem>
      </Nav>
    );
  };

  const handleTablePosition = (value) => {
    if (value === 'contest') {
      let fakeObj = {
        contest_id: currentContest.id,
        group_pool_id: poolInfo.id,
        standings: contestStandings,
        ties_distribution: contestTiesDistribution,
      };
      actions.updateStandingsAmountContest(dispatch, fakeObj);
    }
    setIsFull(value);
  }

  switch (selection) {
    case 1:
      infoContainer = (
        <div className="multiple-quiniela-info principal flex-row row-no-padding responsive-sm">
          <div className="first-section flex-col">
            {poolInfo.sport_id === 7 && poolInfo.weekly_prize ? (
              <div className="flex-row align-items-center justify-content-center">
                <div className="flex-col col-center col-no-padding col-98 ">
                  <ControlWeek
                    whichList={isFull}
                    changePosiciones={(value) => {handleTablePosition(value)}}
                    currentWeekName={currentContest.abbreviation}
                  />
                </div>
              </div>
            ) : null}
            <div className="flex-row">
              <div className="flex-col col-no-padding">
                <div className="flex-row">
                  {poolInfo.sport_id !== 7 && (
                    <TablaPosicionesGroupPool
                      submittingInvite={submittingInvite}
                      showAddConfirmation={showAddConfirmation}
                      inviteStatus={inviteStatus}
                      successFollow={successFollow}
                      errorFollow={errorFollow}
                      userToAdd={userToAdd}
                      posiciones={standings}
                      premios={prizes}
                      poolId={poolId}
                      dispatch={dispatch}
                      myEntry={myEntry}
                      entryId={withRegister ? selectedEntry : null}
                      withRegister={withRegister}
                      selectedEntry={selectedEntry}
                      activeSort={activeSort}
                      queryParams={queryParams}
                      searchableNick={searchableNick}
                      submittingSearch={submittingSearch}
                      poolInfo={poolInfo}
                      type="lobby"
                    />
                  ) || (

                    <TablaPosicionesGroupPoolF1
                      submittingInvite={submittingInvite}
                      showAddConfirmation={showAddConfirmation}
                      inviteStatus={inviteStatus}
                      successFollow={successFollow}
                      errorFollow={errorFollow}
                      userToAdd={userToAdd}
                      posiciones={standings}
                      premios={prizes}
                      poolId={poolId}
                      dispatch={dispatch}
                      myEntry={myEntry}
                      entryId={withRegister ? selectedEntry : null}
                      withRegister={withRegister}
                      selectedEntry={selectedEntry}
                      activeSort={activeSort}
                      queryParams={queryParams}
                      searchableNick={searchableNick}
                      submittingSearch={submittingSearch}
                      poolInfo={poolInfo}
                      type="lobby"
                      isFull={isFull}
                      currentContest={currentContest}
                      contestStandings={contestStandings}
                      contestPrizes={contestPrizes}
                      contestTiesDistribution={contestTiesDistribution}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex-row">
              <div className=" flex-col col-no-padding">
                {poolInfo.sport_id !== 7 && (
                  <Premios
                    estado={poolInfo}
                    loaded={poolInfo}
                    poolInfo={{ group_pool: poolInfo }}
                    premios={prizes}
                    standGroups={null}
                    standings={standings}
                    type={'lobby'}
                  />
                ) || (
                  <PremiosF1
                    estado={poolInfo}
                    loaded={poolInfo}
                    poolInfo={{ group_pool: poolInfo }}
                    premios={prizes}
                    contestPrizes={contestPrizes}
                    standGroups={null}
                    standings={standings}
                    type={'lobby'}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex-col">
            <div className="flex-row">
              <div className="flex-col second-section ">
                <ChatPanel
                  dispatch={dispatch}
                  gameType="group_pool"
                  entries={allEntries}
                  loaded={true}
                  logged={utils.isLogged()}
                  point
                  newMessage={newMessage}
                  selectedEntry={selectedEntry}
                  quinielaID={poolInfo.id}
                  userAvatar={utils.isLogged() ? utils.getUserInfo() : null}
                  userNick={utils.isLogged() ? utils.getUserInfo().nick : null}
                  withRegister={withRegister}
                />
              </div>
            </div>
            <div className="flex-row">
              {poolInfo.sport_id === 7 && (
                <PointsDistributionF1 pointsDistribution={poolInfo.points_distributions} />
              )}

              {(poolInfo.sport_id !== 7 && poolInfo.sport_id !== 6) && (
                <PointsDistribution pointsDistribution={poolInfo.points_distributions} />
              )}
            </div>
          </div>
        </div>
      );
      break;
    case 2:
      if (poolInfo.sport_id === 7) {
        infoContainer = <PicksGrupoPoolGroupF1 />;
      } else if (poolInfo.sport_id === 6) {
        // sport_id === 6 Golf
        infoContainer = <PicksGrupoPoolGroupGolf />
      } else {
        infoContainer = <PicksGrupoPoolGroup />;
      }
      break;
    case 3:
      if (poolInfo.sport_id === 7) {
        infoContainer = <LeaderboardF1 />;
      } else {
        infoContainer = <Leaderboard />;
      }
      break;
  }

  if (poolInfo.status === 'upcoming') {
    //Quiniela con registro o con SUP
    if (withRegister || utils.isSuPick()) {
      return (
        <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
          {poolInfo.type === 'full' && (
            <ContestControlsF1
              label=""
              currentContest={currentContest}
              contests={poolInfo.contests}
              handleContestChange={(contestId) => {handleContestChange(contestId)}}
            />
          ) || null}
          <div id="picksQuiniela" className="lobby  col-xs-12 col-md-4">
            <QuinielaGroupsUI />
          </div>
          <div
            id="multiple-quiniela-container"
            className={` ${
              poolInfo.is_full && withRegister ? ' col-xs-12 col-md-8 ' : 'col-xs-12 col-md-8 '
            }`}
          >
            <Nav
              bsStyle="tabs"
              bsClass="tab-container nav flex-row "
              onSelect={(e) => {
                changeSection(dispatch, e);
              }}
            >
              <NavItem
                eventKey="1"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 1 ? 'active' : null
                }`}
              >
                PRINCIPAL
              </NavItem>
              <NavItem
                eventKey="2"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 2 ? 'active' : null
                }`} // disabled={this.state.entriesInfo === null ? true: false}
                style={{ display: 'initial' }}
              >
                PICKS DE GRUPO
              </NavItem>
              <NavItem
                eventKey="3"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 3 ? 'active' : null
                }`}
                style={{ display: 'initial' }}
              >
                LEADERBOARD
              </NavItem>
            </Nav>
            {infoContainer}
          </div>
        </div>
      );
    }
    //Quiniela llena
    else if (poolInfo.is_full) {
    }
    //QuinielaDefault
    else {
      return (
        <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
          {poolInfo.type === 'full' && (
            <ContestControlsF1
              label=""
              currentContest={currentContest}
              contests={poolInfo.contests}
              handleContestChange={(contestId) => {handleContestChange(contestId)}}
            />
          ) || null}
          <div id="picksQuiniela" className="lobby  col-xs-12 col-md-4">
            <QuinielaGroupsUI />
          </div>
          <div id="multiple-quiniela-container" className={` col-xs-12 col-md-8 `}>
            <Nav
              bsStyle="tabs"
              bsClass="tab-container nav flex-row "
              onSelect={(e) => {
                changeSection(dispatch, e);
              }}
            >
              <NavItem
                eventKey="1"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 1 ? 'active' : null
                }`}
              >
                PRINCIPAL
              </NavItem>
            </Nav>
            {infoContainer}
          </div>
        </div>
      );
    }
  } else {

  /**
   * Not Upcomoing
   */
    return (
      <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
        {poolInfo.type === 'full' && (
            <ContestControlsF1
              label=""
              currentContest={currentContest}
              contests={poolInfo.contests}
              handleContestChange={(contestId) => {handleContestChange(contestId)}}
            />
          ) || null}
        {withRegister ? (
          <div id="picksQuiniela" className="lobby  col-xs-12 col-md-4">
            <QuinielaGroupsUI />
          </div>
        ) : null}

        <div
          id="multiple-quiniela-container"
          className={` ${
            poolInfo.is_full || !withRegister ? ' col-xs-12' : 'col-xs-12 col-md-8  '
          }`}
        >
          <Nav
            bsStyle="tabs"
            bsClass="tab-container nav flex-row "
            onSelect={(e) => {
              changeSection(dispatch, e);
            }}
          >
            <NavItem
              eventKey="1"
              className={`trapezoid flex-col col-no-padding col-center ${
                selection === 1 ? 'active' : null
              }`}
            >
              PRINCIPAL
            </NavItem>
            <NavItem
              eventKey="2"
              className={`trapezoid flex-col col-no-padding col-center ${
                selection === 2 ? 'active' : null
              }`} // disabled={this.state.entriesInfo === null ? true: false}
              style={{ display: 'initial' }}
            >
              PICKS DE GRUPO
            </NavItem>
            <NavItem
              eventKey="3"
              className={`trapezoid flex-col col-no-padding col-center ${
                selection === 3 ? 'active' : null
              }`}
              style={{ display: 'initial' }}
            >
              LEADERBOARD
            </NavItem>
          </Nav>
          {infoContainer}
        </div>
      </div>
    );
  }

  return conditionalComponent;
};

export default HandleLayout;
