import React from 'react';
import {
  Button,
  Modal,
  FormControl,
  FormGroup,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap/lib';
import moment from 'moment';
import { Prompt } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import AbonarSaldo from '../AbonarSaldo';
import guardar from '../../assets/images/guardarPicks-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';
import picks from '../../assets/images/quinielasIconBlanco-50.png';
// import  popUpReglas from '../../assets/images/banners/quinielaGolfMainRuiles.png'
import { ConfirmationOverlay } from '../QuinielaPicksUI/QuinielaPicksUI';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import Picks from './Picks';
import PicksF1 from './PicksF1';
import utils from '../../utils/utils';
import actions from './actions';
import _ from 'underscore';

import numeral from 'numeral';
const popUpReglas = 'https://storage.googleapis.com/pw-resources/banners/app/group-pool-info.png';

const CallToAction = ({ enoughBalance, withRegister, poolInfo, myEntry, dispatch, currentContest }) => {
  let isLogged = utils.isLogged();

  let conditionalComponent = null;

  if (isLogged) {
    if (withRegister) {
      conditionalComponent = (
        <Button
          className={`guardar-picks btn-success callToAction ${
            currentContest.status !== 'upcoming' ? 'hide' : ''
          }`}
          onClick={(e) => {
            actions.postSavePicks(dispatch, myEntry).then((response) => {
              console.log(response);
              if (response.success) {
                actions.handleOverlay(dispatch, false, true);
                setTimeout(() => {
                  actions.handleOverlay(dispatch, false, false);
                }, 1200);

                actions.handlerStatusPicksUnsave(dispatch);
                // Update userBalance
                // Change location to new entry
              } else {
                console.error(response);
                // showError
                actions.handleOverlay(dispatch, true, false);
                setTimeout(() => {
                  actions.handleOverlay(dispatch, false, false);
                }, 1200);
              }
            });
          }}
        >
          <img className="pool-icons" src={guardar} />
          <span>Guardar Picks</span>
        </Button>
      );
    } else {
      if (enoughBalance) {
        conditionalComponent = (
          <Button
            className="guardar-picks btn-success callToAction"
            onClick={(e) => {
              actions.handleRegistration(dispatch);
            }}
          >
            <img className="pool-icons" src={guardar} />
            <span>Guardar Picks y Regístrame</span>
          </Button>
        );
      } else {
        conditionalComponent = (
          <Button
            className="guardar-picks btn-success callToAction"
            onClick={() => {
              actions.handleOpenAbonar(dispatch);
            }}
          >
            <img className="pool-icons" src={abonar} />
            <span>ABONAR SALDO</span>
          </Button>
        );
      }
    }
  } else {
    conditionalComponent = (
      <Button disabled className="guardar-picks btn-success callToAction">
        <img className="pool-icons" src={guardar} />
        <span> GUARDAR PICKS Y REGÍSTRAME </span>
      </Button>
    );
  }

  return <div className="btn-wrap">{conditionalComponent}</div>;
};

/**
 * This component Copy the picks of a similar Pool
 * @param misQuinielas   { boolean }
 * @param show           { boolean }
 * @param similarEntries { Array }
 * @param handleCopy     { function }
 * @param currentEntry   { Object }
 */
export const CopiarPicks = ({
  dispatch,
  currentEntry,
  misQuinielas,
  show,
  similarEntries,
  handleCopy,
  withRegister,
}) => {
  // console.log(similarEntries);
  if (!show) {
    return null;
  }

  if (misQuinielas) {
    return (
      <DropdownButton
        id="similarEntriesDropdown"
        noCaret
        rootCloseEvent={'click'}
        title={`Copiar Picks`}
        className=" flex-col copiarPicks"
      >
        {similarEntries.similar_entries.map((simi, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleCopy(dispatch, simi.id).then((response) => {
                console.log(response);
              });
            }}
          >
            {`
                  [${!simi.number ? 0 : simi.number}]
                  ${simi.group_pool.name_replaced}
                  ${numeral(simi.group_pool.entry_fee / 100).format('0')}`}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  } else {
    return (
      <div className=" flex-col btn-wrap col-no-padding groupPool-copy">
        <DropdownButton
          id="similarEntriesDropdown"
          noCaret
          rootCloseEvent={'click'}
          title={`Copiar Picks`}
          className=" flex-col copiarPicks"
        >
          {similarEntries.similar_entries.map((simi, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleCopy(dispatch, simi.id).then((response) => {
                  actions.updatePicksToCopy(
                    dispatch,
                    response.data.entry,
                    currentEntry,
                    currentEntry.group_pool_entry_id,
                    withRegister
                  );
                });
              }}
            >
              {`
                  [${!simi.number ? 0 : simi.number}]
                  ${simi.group_pool.name_replaced}
                  ${numeral(simi.group_pool.entry_fee / 100).format('0')}`}
            </MenuItem>
          ))}
        </DropdownButton>
      </div>
    );
  }
};

const TieValue = ({ myEntry, tie, dispatch, currentContest, players }) => {
  if (myEntry.group_pool_user_tiebreakers[currentContest.id].hasOwnProperty(tie.id)) {
    return (
      <div className="flex-container end align">
        {!myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value &&
        myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value !== 0
          ? 'N/D'
          : myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value === 0
          ? 'E'
          : myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value}
      </div>
    );
  } else {
    return <div className="flex-container end align"> N/D </div>;
  }
};

const TieValueF1 = ({ myEntry, tie, dispatch, currentContest, players }) => {
  if (myEntry.group_pool_user_tiebreakers[currentContest.id].hasOwnProperty(tie.id)) {
    let playerName = '';
    let player = !myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value &&
        myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value !== 0
          ? 'N/D'
          : myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value === 0
          ? 'E'
          : myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value

    if (player !== "N/D" && player !== "E") {
      let temp = _.find(players, (item, index) => item.id === player);
      playerName = temp && temp.name && temp.name || '';
    }
    return (
      <div className="flex-container end align">
        {playerName}
      </div>
    );
  } else {
    return <div className="flex-container end align"> N/D </div>;
  }
};

const TiesOptions = ({ myEntry, dispatch, tie, currentContest, players }) => {
  let listOptions = [];
  let counter = 0;
  let selectedValue;
  if (myEntry.group_pool_user_tiebreakers !== undefined) {
    selectedValue = myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id]
      ? myEntry.group_pool_user_tiebreakers[currentContest.id][tie.id].value
      : null;
  }

  switch (tie.type) {
    case 'golf_cut_score':
      for (let minV = tie.config_parsed.min; minV <= tie.config_parsed.max; minV++) {
        let optionCut = (
          <option key={counter} value={minV}>
            {' '}
            {minV === 0 ? 'E' : minV}{' '}
          </option>
        );
        listOptions = [...listOptions, optionCut];
        counter++;
      }
      break;
    case 'golf_winner_score':
      for (let minV = tie.config_parsed.min; minV <= tie.config_parsed.max; minV++) {
        let optionWinner = (
          <option key={counter} value={minV}>
            {' '}
            {minV === 0 ? 'E' : minV}{' '}
          </option>
        );
        listOptions = [...listOptions, optionWinner];
        counter++;
      }
      break;
    case 'race_positions_won':
      _.each(players, (item, index) => {
        let optionWinner = (
          <option key={counter} value={index}>
            {' '}
            {item.name}
          </option>
        );
        listOptions = [...listOptions, optionWinner];
        counter++;
      });
      break;
    case 'race_positions_lost':
      _.each(players, (item, index) => {
        let optionWinner = (
          <option key={counter} value={index}>
            {' '}
            {item.name}
          </option>
        );
        listOptions = [...listOptions, optionWinner];
        counter++;
      });
      break;
  }
  return (
    <FormGroup controlId={`tiebreaker-${tie.type}`}>
      <FormControl
        componentClass="select"
        value={!isNaN(selectedValue) ? selectedValue : ''}
        className={`tie-${tie.type}`}
        onChange={(e) => {
          actions.handleTie(dispatch, tie.id, e.target.value, currentContest);
        }}
      >
        <option value="null" />
        {listOptions}
      </FormControl>
    </FormGroup>
  );
};

const Ties = ({ gameStatus, ties, dispatch, myEntry, currentContest }) => {
  let list;
  if (ties[currentContest.id].length > 1) {
    list = ties[currentContest.id].map((tie) => {
      return (
        <div key={tie.id} className="bucket-item ">
          <div className="flex-col ">
            <div className="flex-row row-center row-no-padding">
              <div className="flex-col">{tie.label}</div>
              <div className="flex-col ">
                {gameStatus !== 'upcoming' ? (
                  <TieValue myEntry={myEntry} tie={tie} currentContest={currentContest} />
                ) : (
                  <TiesOptions myEntry={myEntry} dispatch={dispatch} tie={tie} currentContest={currentContest} />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  // console.log('Render Ties Component',ties);
  return (
    <div className="flex-row ">
      <div className="flex-col col-no-padding">
        <div className="flex-row title-quiniela sub" style={{ marginBottom: '10px' }}>
          <div className="flex-col title-wrapper">Desempates</div>
        </div>
        {list}
      </div>
    </div>
  );
};

const TiesF1 = ({ gameStatus, ties, dispatch, myEntry, currentContest, players }) => {
  let list = ties[currentContest.id].map((tie) => {
    // console.log('Tie:', tie );
    return (
      <div key={tie.id} className="bucket-item ">
        <div className="flex-col ">
          <div className="flex-container column">
            <div style={{marginBottom: '5px'}}>{tie.label}</div>
            <div>
              {gameStatus !== 'upcoming' ? (
                <TieValueF1 myEntry={myEntry} tie={tie} currentContest={currentContest} players={players} />
              ) : (
                <TiesOptions myEntry={myEntry} dispatch={dispatch} tie={tie} currentContest={currentContest} players={players} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });

  // console.log('Render Ties Component',ties);
  return (
    <div className="flex-row ">
      <div className="flex-col col-no-padding">
        <div className="flex-row title-quiniela sub" style={{ marginBottom: '10px' }}>
          <div className="flex-col title-wrapper">Desempates</div>
        </div>
        {list}
      </div>
    </div>
  );
};

/**
 * This components show if an error ocurred in the process of handling the Notifications
 * @param { Function } handleError
 * @param { Boolean } show
 * @param { Object }  error
 * @reutrn Component
 */
const Error = ({ dispatch, handleError, show, error }) => {
  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={show} keyboard={false}>
      <div className="panel-abonar">
        <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo failed '}`}>
          <span>Error</span>
          <button
            className="cerrar-panel"
            onClick={() => {
              handleError(dispatch);
            }}
          >
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-alert-circled alert" />
              {error.message}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SuccessfullChanges = ({ handleSuccess, show, dispatch }) => {
  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={show} keyboard={false}>
      <div className="panel-abonar">
        <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo '}`}>
          <span>Se guardaron con éxito</span>
          <button
            className="cerrar-panel"
            onClick={() => {
              handleSuccess(dispatch);
            }}
          >
            <i className="ion-ios-close-empty" />
          </button>
        </div>
        <div className="flex-row pickwin-panel">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-checkmark-circled success" />
              ¡Tus notificaciones se guardaron correctamente!
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * This component renders the Title of the UI
 *
 * @param { entriesInfo }  Object
 * @param { misQuinielas } Boolean
 * @param { myEntry }      Object
 * @param { withRegister } Boolean
 */
const Title = ({ entriesInfo, myEntry, withRegister, dispatch, misQuinielas, showPopUpRules }) => {
  return (
    <div className={`flex-row title-quiniela`}>
      <div className="flex-col col-no-padding title-wrapper">
        <div className="flex-row row-no-padding row-center">
          <img src={picks} className="pool-icons tablas" />
          <span>
            MIS PICKS
            <small>
              {`${
                entriesInfo &&
                entriesInfo.hasOwnProperty('other_entries') &&
                entriesInfo.other_entries.length > 0
                  ? '[' + entriesInfo.group_pool_entry.number + ']'
                  : ''
              }`}
            </small>
          </span>
          <span className="btn-help flex-container justify-end">
            <ReactTooltip id="help-survivor" place="top" type="light" effect="solid">
              ¿Cómo funcionan las Quinielas de Grupos?
            </ReactTooltip>
            <i
              data-tip
              data-for="help-survivor"
              className="ion-help-circled"
              onClick={() => {
                showPopUpRules(dispatch);
              }}
            ></i>
          </span>
        </div>
      </div>
    </div>
  );
};

const DeadlineTeeTime = ({ poolInfo, currentContest }) => {
  if (currentContest.status !== 'upcoming') {
    return null;
  } else {
    return (
      <div className="deadline-picks flex-container align justify">
        <i className="ion-alert-circled" style={{ color: '#fff900', marginRight: '5px' }}></i>
        Fecha limite para registrar Picks:
        <strong
          style={{ color: 'rgb(244, 0, 52)', textTransform: 'capitalize', marginLeft: '3px' }}
        >
          {moment(currentContest.deadline).format('ddd D MMM HH:mm')}
        </strong>
      </div>
    );
  }
};

const QuinielaGroupsUI = ({ updateUserInfo, updateUserBalance }) => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);
  // console.log("STATEGUI:", state);

  React.useEffect(() => {
    if (!state.withRegister) {
      actions.handlePopUpReglas(dispatch);
    }
  }, []);

  React.useEffect(() => {
    actions.updateEnoughBalanceOnly(dispatch);
  }, [state.showAbonar]);

  const handleShowModalPicksUnsave = (nextLocation) => {
    if (!state.confirmedNavigation) {
      console.log("HALDEMODAL");
      actions.handleShowModalPicksUnsave(dispatch);
      return false;
    }
    return true;
  }

  const handlerStatusPicksUnsave = () => {
    actions.handlerStatusPicksUnsave(dispatch);
  }

  if (!state.mounted) {
    return (
      <div className="flex-container justify align column">
        <i className="ion-load-a loading " />
      </div>
    );
  }
  return (
    <div className="panel-pickwin border-gradient" id="picksContainer">
      <Prompt
        when={state.picksUnsave}
        message={handleShowModalPicksUnsave} />
        <Modal
          backdropClassName="tutorialSurvivorBackdrop"
          dialogClassName="infoReferralsPopUp modal-dialog-xss"
          onHide={() => {}}
          show={state.showModalPicksUnsave}
        >
          <div className="panel-abonar">
            <div
              id="panelTitle"
              className="row-center flex-row row-no-padding panel-abonar-titulo"
              style={{ justifyContent: 'initial' }}
            >
              <span className="flex-col">No has guardado tus picks</span>
              <button
                className="cerrar-panel "
                onClick={handlerStatusPicksUnsave}
              >
                <i className="ion-ios-close-empty"></i>
              </button>
            </div>
          </div>
          <div className="flex-row row-no-padding pickwin-panel">
            <div className="flex-col panel-pickwin row-center">
              <div className="flex-container justify column">
                <h5 className='text-center mt-1'>Hiciste modificaciones a tus picks. No olvides guardar tus picks después de hacer modificaciones</h5>
                <button type="button" className="guardar-picks btn btn-success" onClick={handlerStatusPicksUnsave}><span> CERRAR </span></button>
              </div>
            </div>
          </div>
        </Modal>

      <Modal
        backdropClassName="tutorialSurvivorBackdrop"
        dialogClassName="infoReferralsPopUp"
        show={state.showPopUpReglas}
        onHide={() => {
          actions.handlePopUpReglas(dispatch);
        }}
      >
        {state.poolInfo.sport_id !== 7 && (
          <>
            <div className="panel-abonar">
              <div
                id="panelTitle"
                className="row-center flex-row row-no-padding panel-abonar-titulo"
                style={{ justifyContent: 'initial' }}
              >
                <span className="flex-col col-80">¿Cómo funciona? </span>
                <button
                  className="cerrar-panel"
                  onClick={() => {
                    actions.handlePopUpReglas(dispatch);
                  }}
                  style={{ marginLeft: '55px' }}
                >
                  <i className="ion-ios-close-empty"></i>
                </button>
              </div>
            </div>
            <div className="flex-row row-no-padding pickwin-panel">
              <div className="flex-col panel-pickwin row-center">
                <img src={popUpReglas} style={{ maxWidth: '473px', width: '100%', height: 'auto' }} />
              </div>
            </div>
          </>
        ) || (
          <>
            <div className="panel-abonar">
              <div
                id="panelTitle"
                className="row-center flex-row row-no-padding panel-abonar-titulo"
                style={{ justifyContent: 'initial' }}
              >
                <span className="flex-col col-80">¿Cómo funciona? </span>
                <button
                  className="cerrar-panel"
                  onClick={() => {
                    actions.handlePopUpReglas(dispatch);
                  }}
                  style={{ marginLeft: '55px' }}
                >
                  <i className="ion-ios-close-empty"></i>
                </button>
              </div>
            </div>
            <div className="flex-row row-no-padding pickwin-panel">
              <div className="flex-col panel-pickwin row-center">
                <img src="https://storage.googleapis.com/pw-resources/banners/app/f1-group-pool-info.jpg" style={{ maxWidth: '473px', width: '100%', height: 'auto' }} />
              </div>
            </div>
          </>
        ) }
      </Modal>
      <AbonarSaldo
        type="modal"
        dispatch={dispatch}
        updateBalance={actions.updateUserBalance}
        showAbonarSaldo={state.showAbonar}
        updatePanelSate={actions.handleOpenAbonar}
        showAbonarPop={state.showAbonar}
        updateUserInfo={updateUserInfo}
        updateUserBalance={updateUserBalance}
      />
      <Modal
        show={state.submitting}
        className="responseDepositModal absoluteModal"
        dialogClassName="responseDepositDialog"
        backdropClassName="responseDepositBackdrop"
      >
        <div className="flex-container justify align column">
          {state.errorPartnerRegistration && state.errorPartnerRegistration.status === true ? (
            <i className="ion-alert-circled alert"></i>
          ) : (
            <i className="ion-load-a loading"> </i>
          )}
          <div className="flex-row row-center">
            <div className="flex-col col-center text-center">
              <strong>
                {state.errorPartnerRegistration && state.errorPartnerRegistration.status === true
                  ? `${state.errorPartnerRegistration.message}`
                  : 'Espera un momento porfavor...'}
              </strong>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationOverlay
        isError={state.saveError}
        dispatch={dispatch}
        errorMessage={state.error}
        isSuccess={state.saveSuccess}
        saveMessage={'Salvados'}
        showOverlay={state.showOverlay}
        label={'Guardado'}
        currentSavedPicks={null}
        allPicks={null}
        isCopy={state.isCopy}
      />
      <Title
        dispatch={dispatch}
        withRegister={state.withRegister}
        entriesInfo={state.allEntries}
        misQuinielas={state.misQuinielas}
        myEntry={state.myEntry}
        showPopUpRules={actions.handlePopUpReglas}
      />
      <DeadlineTeeTime poolInfo={state.poolInfo} currentContest={state.currentContest} />
      {!state.similarPools ||
      state.similarPools.similar_entries.length < 1 ||
      state.currentContest.status !== 'upcoming' ? null : (
        <CopiarPicks
          buckets={state.poolBuckets}
          ties={state.tiebreakers}
          dispatch={dispatch}
          withRegister={state.withRegister}
          currentEntry={state.myEntry}
          misQuinielas={state.misQuinielas}
          show={true}
          similarEntries={state.similarPools}
          handleCopy={actions.fetchCopyPicks}
        />
      )}
      <div className="quiniela-picks flex-col col-no-padding">
        {state.poolInfo.sport_id !== 7 && (
          <>
            <Picks
              disabled={state.currentContest.status !== 'upcoming'}
              poolInfo={state.poolInfo}
              myEntry={state.myEntry}
              poolBuckets={state.poolBuckets}
              bucketKeys={state.bucketKeys}
              dispatch={dispatch}
              currentContest={state.currentContest}
            />
            {state.poolInfo.use_tiebreakers ? (
              <Ties
                myEntry={state.myEntry}
                dispatch={dispatch}
                ties={state.tiebreakers}
                gameStatus={state.currentContest.status}
                currentContest={state.currentContest}
              />
            ) : null}
          </>
        ) || (
          <>
            <PicksF1
              disabled={state.currentContest.status !== 'upcoming'}
              poolInfo={state.poolInfo}
              myEntry={state.myEntry}
              poolBuckets={state.poolBuckets}
              bucketKeys={state.bucketKeys}
              dispatch={dispatch}
              currentContest={state.currentContest}
              groupPicks={state.groupPicks}
              allEntries={state.allEntries}
              players={state.players}
            />
            {state.poolInfo.use_tiebreakers ? (
              <TiesF1
                myEntry={state.myEntry}
                dispatch={dispatch}
                ties={state.tiebreakers}
                gameStatus={state.currentContest.status}
                currentContest={state.currentContest}
                players={state.players}
              />
            ) : null}
          </>
        )}

        <CallToAction
          poolInfo={state.poolInfo}
          updateUserInfo={updateUserInfo}
          updateUserBalance={updateUserBalance}
          submitting={state.submitting}
          enoughBalance={state.enoughBalance}
          withRegister={state.withRegister}
          myEntry={state.myEntry}
          dispatch={dispatch}
          currentContest={state.currentContest}
        />
      </div>
    </div>
  );
};

export default QuinielaGroupsUI;
