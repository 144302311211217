import React from 'react';
import { Button, ButtonToolbar, ControlLabel } from 'react-bootstrap/lib';

// import { CheckboxCustom } from './CheckboxCustom';

import NotificationItemFormsy from './NotificationItemFormsy';

const CheckboxCustom = ({ name, amountType, sportId, tournamentId, inputHandler, isChecked }) => {
  return (
    <label
      className="pickwin-checkbox-label flex-container justify align column"
      onChange={() => {
        inputHandler(tournamentId, sportId, amountType);
      }}
    >
      <input className="pickwin-checkbox" type="checkbox" checked={isChecked} onChange={() => {}} />
      <div className="checkbox-helper" />
    </label>
  );
};

const TournamentList = ({
  tournamentsList,
  tournamentsValue,
  updateTournamentsNotifications,
  updateHandleAllAmoutPerSport,
  sportId,
  updateEntryNotifications,
}) => {
  // console.log('Tournaments List ',  tournamentsList);

  let list = tournamentsList.map((tournament) => {
    let tournamentId = tournament.id;
    // console.log( tournamentsValue, tournamentId , tournament );
    let isActiveAmountLow,
      isActiveAmountMedium,
      isActiveAmountHigh = false;
    if (tournamentsValue && tournamentsValue[tournamentId]) {
      // console.log(tournamentsValue[ tournamentId ] );
      isActiveAmountLow = tournamentsValue[tournamentId].amounts['low'];
      isActiveAmountMedium = tournamentsValue[tournamentId].amounts['medium'];
      isActiveAmountHigh = tournamentsValue[tournamentId].amounts['high'];
    }
    // console.log(tournamentId, sportId);
    // return null

    return (
      <div key={tournament.id} className="flex-row row-center ">
        <div className="flex-col col-40">
          <div className=" flex-row row-center">
            <ControlLabel className="flex-col">{tournament.name}</ControlLabel>
            <Button
              className="btn flex-col col-30"
              onClick={() => {
                updateTournamentsNotifications(tournament.id, sportId);
              }}
            >
              Todas
            </Button>
          </div>
        </div>
        <div className="flex-col col-20">
          <CheckboxCustom
            name="amount_notification"
            amountType="low"
            sportId={sportId}
            tournamentId={tournament.id}
            inputHandler={updateEntryNotifications}
            isChecked={isActiveAmountLow}
          />
        </div>
        <div className="flex-col col-20">
          <CheckboxCustom
            name="amount_notification"
            amountType="medium"
            sportId={sportId}
            tournamentId={tournament.id}
            inputHandler={updateEntryNotifications}
            isChecked={isActiveAmountMedium}
          />
        </div>
        <div className="flex-col col-20">
          <CheckboxCustom
            name="amount_notification"
            amountType="high"
            sportId={sportId}
            tournamentId={tournament.id}
            inputHandler={updateEntryNotifications}
            isChecked={isActiveAmountHigh}
          />
        </div>
      </div>
    );
  });

  return (
    <div className="flex-col ">
      <div className={`flex-row ${tournamentsList.length <= 1 ? 'hidden' : ''}`}>
        <div className="flex-col col-40"></div>
        <div className="flex-col col-20">
          <div className="flex-row row-center responsive-sm">
            <Button
              className="flex-col col-60 btn-default btn-all-amounts "
              onClick={() => {
                updateHandleAllAmoutPerSport(sportId, 'low');
              }}
            >
              Todas
            </Button>
          </div>
        </div>
        <div className="flex-col col-20">
          <div className="flex-row row-center responsive-sm">
            <Button
              className="flex-col col-60 btn-default btn-all-amounts "
              onClick={() => {
                updateHandleAllAmoutPerSport(sportId, 'medium');
              }}
            >
              {' '}
              Todas{' '}
            </Button>
          </div>
        </div>
        <div className="flex-col col-20">
          <div className="flex-row row-center responsive-sm">
            <Button
              className="flex-col col-60 btn-default btn-all-amounts "
              onClick={() => {
                updateHandleAllAmoutPerSport(sportId, 'high');
              }}
            >
              {' '}
              Todas{' '}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-row">
        <ControlLabel className="flex-col col-40 small">Ligas:</ControlLabel>
        <ControlLabel className="flex-col col-20 text-center small">$1 - $49</ControlLabel>
        <ControlLabel className="flex-col col-20 text-center small">$50 - $250</ControlLabel>
        <ControlLabel className="flex-col col-20 text-center small">+$251</ControlLabel>
      </div>
      {list}
    </div>
  );
};

const SportsList = ({
  sportsList,
  updateSportsNotifications,
  notification,
  updateTournamentsNotifications,
  updateHandleAllAmoutPerSport,
  updateEntryNotifications,
}) => {
  let tournamentsValue = [];
  let tournamentsList = [];
  sportsList = sportsList.filter(
    (sport) =>
      sport.id === 1 || sport.id === 2 || sport.id === 3 || sport.id === 4 || sport.id === 9
  );
  // console.log('SportsList',sportsList);
  let list = sportsList.map((sport) => {
    let isActive = false;

    let sV = notification[sport.id];

    if (sV) {
      // console.log('sports value ',  sV, notification , sport );
      tournamentsValue = notification[sport.id].tournaments;
      tournamentsList = sport.tournaments;
      isActive = sV.enabled;
    }

    // console.log(tournamentsValue);
    return (
      <div key={sport.id} className="notification-sport-item flex-col">
        <div className="flex-row responsive-sm row-no-padding">
          <div className="flex-col ">
            <NotificationItemFormsy
              label={sport.name}
              checkboxType={'sportCheckbox'}
              sportId={sport.id}
              inputHandler={updateSportsNotifications}
              defaultValue={isActive}
              name={sport.name}
              sportsList={sportsList}
              type="SWITCH"
            />
          </div>
        </div>
        {isActive ? (
          <TournamentList
            tournamentsList={tournamentsList}
            sportId={sport.id}
            tournamentsValue={!tournamentsValue ? [] : tournamentsValue}
            notification={notification}
            updateHandleAllAmoutPerSport={updateHandleAllAmoutPerSport}
            updateEntryNotifications={updateEntryNotifications}
            updateTournamentsNotifications={updateTournamentsNotifications}
          />
        ) : null}
      </div>
    );
  });
  return (
    <div className="flex-row point-format-wizard form-group">
      <ButtonToolbar>{list}</ButtonToolbar>
    </div>
  );
};

const SportsNotifications = ({
  notification,
  updateSportsNotifications,
  updateTournamentsNotifications,
  updateHandleAllAmoutPerSport,
  updateEntryNotifications,
  sportsList,
}) => {
  // console.log('SportsNotification', sportsList);
  return (
    <div className="flex-row">
      <div className="flex-col">
        {/*<NotificationItemFormsy
              name='game_type_notification'
              type='GAME_TYPE'
              inputHandler={ updateGameTypeNotifications }
              defaultValue={ enabledGameType }
              />*/}
        <SportsList
          notification={notification}
          sportsList={sportsList}
          updateHandleAllAmoutPerSport={updateHandleAllAmoutPerSport}
          updateTournamentsNotifications={updateTournamentsNotifications}
          updateSportsNotifications={updateSportsNotifications}
          updateEntryNotifications={updateEntryNotifications}
        />
      </div>
    </div>
  );
};

export default SportsNotifications;
