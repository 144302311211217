import { useEffect, useState } from 'react'
import getFormatTime from '../utils/getFormatTime'

// Hook for countDown with posivilities of pause, play and reset
const useCountDown = (initialCount, autoplay = false) => {
  const [countDown, setCountDown] = useState(initialCount)
  const [isPlaying, setIsPlaying] = useState(autoplay)

  useEffect(() => {
    if (countDown >= 1 && isPlaying) {
      const interval = setInterval(() => {
        setCountDown(prev => prev - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [countDown, isPlaying])

  function reset () {
    setCountDown(initialCount)
  }

  function play () {
    setIsPlaying(true)
  }

  function pause () {
    setIsPlaying(false)
  }

  return {
    leftTime: getFormatTime(countDown),
    isFinished: countDown === 0,
    isPlaying,
    reset,
    pause,
    play
  }
}

export default useCountDown

