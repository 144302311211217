import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

export default function ModalPanel (props) {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (!isMounted) {
      window.setTimeout(() => {
        // Patch bootstrap modal tabindex bug
        const el = document.querySelector('.fade[tabindex="-1"]')

        if (el) {
          el.removeAttribute('tabindex')
        }

        setIsMounted(true)
      }, 200)
    }
  }, [isMounted])

  const handleClose = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (props.onClose) {
      props.onClose()
    }
    if (props.onHide) {
      props.onHide()
    }
  }

  if (!isMounted) return null

  return (
    <Modal onMouseDown={handleClose} tabIndex='1'>
      {props.children}
    </Modal>
  )
}

const show = keyframes`
  from {
    opacity: 0;
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const Modal = styled.div`
  background-color: #000000eb;
  backdrop-filter: blur(3px) grayscale(0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  animation: 350ms ease ${show};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-y: auto;
`
