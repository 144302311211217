import { withFormsy } from 'formsy-react';
import React from 'react';
import cvvHelper from '../../assets/images/cvvHelper.png';

import { listCountries } from './listCountries';
import {
  Form,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  Popover,
  OverlayTrigger,
  HelpBlock,
} from 'react-bootstrap/lib';
import MediaQuery from 'react-responsive';

/**
 * Check type of device
 * @type {string}
 */
const uagent = navigator.userAgent.toLowerCase();
let device = null;
const detectDevice = () => {
  // console.log(navigator.userAgent);
  if (uagent.search('iphone') > -1) {
    device = 'apple';
  } else if (uagent.search('ipad') > -1) {
    device = 'apple';
  } else if (uagent.search('ipod') > -1) {
    device = 'apple';
  } else if (uagent.search('ndroid') > -1) {
    device = 'android';
  }
  return device;
};

const popoverCvv = (
  <Popover id="popover-cvv" title="Busca tu cvv">
    <img src={cvvHelper} className="img-responsive" />
  </Popover>
);

class FormsySms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countriesOptions: listCountries,
    };
    this.changeValue = this.changeValue.bind(this);

    /**
     * Renders the available countries to an option
     * @return countriesOptions { Array } Array of the options
     */
    this.renderCountries = () => {
      let countriesOptions = this.state.countriesOptions.map((country) => {
        return (
          <option
            key={country.ccode}
            value={country.ccode}
          >{`${country.country}  (+${country.code})`}</option>
        );
      });
      return countriesOptions;
    };
  }

  changeValue(event) {
    if (event.currentTarget.id !== 'cellphonecelphone' && event.currentTarget.id !== 'country') {
      if (event.currentTarget.value.length <= 1) {
        this.props.setValue(event.currentTarget.value);
      }
    } else {
      this.props.setValue(event.currentTarget.value);
    }
  }

  focusValue(event) {
    if (event.currentTarget.value.length >= 1) {
      this.props.setValue("");
    }
  }

  render() {
    let conditionalComponent = null;
    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    /**
     * Handles the Help messages given from the parent
     */
    let helpMessages =
      this.props.help !== undefined
        ? this.props.help.map((help, i) => <HelpBlock key={i}>{help}</HelpBlock>)
        : null;

    /**
     * This switch handles the type of input of the form
     */
    switch (this.props.type) {
      case 'CELLPHONE':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name + 'celphone'}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <ControlLabel>{this.props.label}</ControlLabel>
              <FormControl
                placeholder="5512345678"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || ''}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              ></FormControl>
            </FormGroup>

            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'CODE_0':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <FormControl
                className="0"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                value={this.props.getValue() || ''}
                onKeyUp={(e) => {
                  this.props.checkNext(e, 0, 1);
                }}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, 0);
                }}
                onFocus={(e) => {this.focusValue(e)}}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'CODE_1':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <FormControl
                className="1"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                value={this.props.getValue() || ''}
                onKeyUp={(e) => {
                  this.props.checkNext(e, 1, 2);
                }}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, 1);
                }}
                onFocus={(e) => {this.focusValue(e)}}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'CODE_2':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <FormControl
                className="2"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                minLength={1}
                min={1}
                maxLength={1}
                max={1}
                value={this.props.getValue() || ''}
                onKeyUp={(e) => {
                  this.props.checkNext(e, 2, 3);
                }}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, 2);
                }}
                onFocus={(e) => {this.focusValue(e)}}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'CODE_3':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <FormControl
                className="3"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                value={this.props.getValue() || ''}
                onKeyUp={(e) => {
                  this.props.checkNext(e, 3, 4);
                }}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, 3);
                }}
                onFocus={(e) => {this.focusValue(e)}}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'CODE_4':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <FormControl
                className="4"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength="1"
                value={this.props.getValue() || ''}
                onKeyUp={(e) => {
                  this.props.checkNext(e, 4, null);
                }}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, 4);
                }}
                onFocus={(e) => {this.focusValue(e)}}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'COUNTRY':
        // console.log(monthlyDays);
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup
              controlId={this.props.name}
              className={detectDevice() === 'apple' ? 'ios' : ''}
            >
              <ControlLabel>{this.props.label}</ControlLabel>
              <FormControl
                componentClass="select"
                required
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              >
                ><option value={null}>Selecciona tú país</option>
                {this.renderCountries()}
              </FormControl>
            </FormGroup>
          </div>
        );
        break;
    }
    return conditionalComponent;
  }
}

export default withFormsy(FormsySms);
