import React from 'react';

const PointsDistributionF1 = ( { pointsDistribution, isPopUp } ) => {
  let distList = pointsDistribution.map((item, index) => {
    return (
      <div key={ index } className='leaderboard-item flex-row'>
          <div className='flex-col col-center col-50'>
            {index === 0 && (
              <>Exacto</>
            ) || (
              <>+/- {index}</>
            )}
          </div>
          <div className='flex-col'>
          <div className='flex-container end align'>
            { item }pts
          </div>
          </div>
      </div>
    )
  } )
  return  (
    <div className='flex-col panel-pickwin col-no-padding border-gradient' style={ isPopUp ? { } : { margin:'10px'}}>
      <div className='flex-row row-no-padding title-quiniela row-center uppercase'>
        <i className=' ion-android-list pool-icons' style={{margin:'5px'}} />Sistema de puntuación
      </div>
      <div className='flex-col leaderboard-list'>
        <div className='flex-row row-no-padding header-leaderboard'>
          <div className='flex-col col-center col-50'>
            Lugares de Diferencia
          </div>
          <div className='flex-col '>
            <div className='flex-container end align'>
              Puntos
            </div>
          </div>
        </div>
        <div className={` flex-col col-no-padding overflow overflow-y ${ isPopUp ? 'isPopup' : 'overflow-200' } `} style={{minHeight: '212px'}}>
          { distList }
        </div>
        <div className="flex-col" style={{fontSize: '0.8em'}}>Se darán puntos adicionales en caso de pronosticar correctamente cualquiera de las 3 primeras posiciones de la carrera. (10 puntos al 1er lugar, 5 puntos al 2do lugar, 2 puntos al 3er lugar)</div>
      </div>
    </div>
  )
}

export default PointsDistributionF1;
