import styled from 'styled-components'
import React from 'react'

export default function PickTable (props) {
  const {
    ownUser,
    poolInfo,
    cutOffIDList,
    currentPlayer,
    currentContest
  } = props

  if (!poolInfo) return null

  const { current_round } = currentContest
  const { r1, r2, r3, r4 } = currentPlayer.data
  const showLineThrough = (cutOffIDList[currentPlayer.player_id] && poolInfo.status !== 'upcoming')

  return (
    <Table ownUser={ownUser}>
      {showLineThrough && (
        <Gradient />
      )}
      <tr>
        <th>
          <i className={`flex-container flag miquiniela flag-icon ${currentPlayer.logo}`}></i>
        </th>
        <th colspan='2'>
          { currentPlayer.name }
        </th>
        <th style={{ color: getScoreColor(currentPlayer.score) }}>
          { currentPlayer.score }
        </th>
        {showLineThrough && <LineThrough />}
      </tr>
      <tr>
        <td>r1</td>
        <td>r2</td>
        <td>r3</td>
        <td>r4</td>
      </tr>
      {(poolInfo.status === 'upcoming') && (
        <tr>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
      )}
      {poolInfo.status !== 'upcoming' && (
        <tr>
        <td>{current_round >= 1 && r1 !== null ? r1 : '-'}</td>
        <td>{current_round >= 2 && r2 !== null ? r2 : '-'}</td>
        <td>{current_round >= 3 && r3 !== null ? r3 : '-'}</td>
        <td>{current_round >= 4 && r4 !== null ? r4 : '-'}</td>
      </tr>
      )}
    </Table>
  )
}

const getScoreColor = (score) => {
  if (score > 0) return 'rgb(255, 199, 48)'
  if (score < 0) return '#78bc28'
  return 'inherit'
}

const Table = styled.table`
  border: 1px solid #FFF;
  width: 100%;
  font-size: 0.8em;
  letter-spacing: 0.3px;
  min-width: 110px;
  position: relative;
  background: ${p => p.ownUser ? 'linear-gradient(90deg, #000046, #214283, #1cb5e0, #214283, #000046)': 'inherit'};
  background-size: 600% 100%;

  tr {
    position: relative;
  }

  th, td {
    border: 1px solid #6a6a6a;
    text-align: center;
  }

  th {
    font-weight: 900;

    i {
      margin: 0 auto;
      display: block;
    }
  }

  td {
    width: 25%;
  }
`

const LineThrough = styled.div`
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: 2px solid red;
  z-index: 1000;
`

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  background: linear-gradient(181deg,#ff000063,#7300003d);
`
