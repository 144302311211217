import React from 'react';
import axios from 'axios';
import participantes from '../../assets/images/participantesIconBlanco-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';
import Loading from '../Loading/Loading';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';
import BracketsUI from '../BracketsUI/BracketsUI';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';

import { Button } from 'react-bootstrap/lib';
import { Redirect, Link } from 'react-router-dom';

import RegisterPanel from '../RegisterPanel';
import Premios from '../Premios';
import Participantes from '../Participantes';
// import '../../assets/stylesheets/flags-sprites-complete.scss';
import picks from '../../assets/images/quinielasIconBlanco-50.png';
import guardar from '../../assets/images/guardarPicks-50.png';
import utils from '../../utils/utils';
import Utils from '../../utils/quinielasUtils';
import API from '../../utils/api';

const RegisterSteps = (props) => {
  let conditionalComponent = (
    <div id="registerSteps" className="row">
      <div className="col-xs-12 text-center register-title-container">
        <span className="register-title">
          <i className="ion-flag" />
          Sigue estos pasos para participar en el bracket.
        </span>
      </div>
      {props.isBalanceEnough ? (
        <div className="col-xs-12 steps-container">
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Llena tus picks</span>
          </div>
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">2</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu bracket</span>
          </div>
        </div>
      ) : (
        <div className="col-xs-12 steps-container">
          <div className="col-xs-4  register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Llena tus picks</span>
          </div>
          <div className="col-xs-4  register-step-item">
            <span className="step-number">2</span>
            <img src={abonar} className="step-icon" />
            <span className="step-label">Abona saldo</span>
          </div>
          <div className="col-xs-4  register-step-item">
            <span className="step-number">3</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu bracket</span>
          </div>
        </div>
      )}
    </div>
  );
  return conditionalComponent;
};

const NotLoggedUserBar = ({ redirectLogin, redirectRegistro }) => {
  return (
    <div className="buttons-row">
      <Button onClick={redirectLogin} data-id="login" className="btn-lobby-login" bsSize="small">
        <i className="ion-log-in" /> <span data-id="login">INGRESAR</span>
      </Button>
      <Button
        onClick={redirectRegistro}
        data-id="registro"
        className="btn-lobby-login"
        bsSize="small"
      >
        <span data-id="registro">CREAR CUENTA</span>
      </Button>
    </div>
  );
};

class BracketPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enoughBalance: false,
      activeRound: 1,
      bracketEntries: null,
      bracketStages: null,
      errorCopiar: null,
      bracketEntriesInfo: null,
      item: this.props.item !== null && this.props.item[0] !== null ? this.props.item[0].id : null,
      loaded: false,
      logged: null,
      picks: [],
      bracketInfo: null,
      premios: [],
      redirectLobby: false,
      redirectLogin: null,
      redirectWithEntry: false,
      scrollElement: null,
      showPanelRegister: false,
      showPanel: false,
      showAbonar: false,
      showTutorial: false,
      similarPools: [],
      posActualPage: 1,
      bracketStandings: [],
      temporalRegisterPicks: null,
      userData: null,
    };
    this.queryParams = {
      page: 1,
      limit: 50,
      nick: '',
      sort: [],
      order: null,
    };
    this.confirmationCopy = this.confirmationCopy.bind(this);
    this.copiarPicks = Utils.copiarPicks.bind(this);
    this.redirect = this.redirect.bind(this);
    this.redirectLogin = this.redirectLogin.bind(this);
    this.redirectRegistro = this.redirectRegistro.bind(this);

    this.showAbonar = () => {
      this.setState({ showAbonar: true });
    };

    this.hideAbonar = () => {
      this.setState({ showAbonar: false });
    };

    this.showTutorial = () => {
      this.setState({ showTutorial: true });
      console.log('Show Tutorial');
    };

    this.closeTutorial = () => {
      this.setState({ showTutorial: false });
    };

    this.showPanelRegister = () => {
      this.setState({ showPanelRegister: true });
    };

    this.closePanelRegister = () => {
      this.setState({ showPanelRegister: false });
    };

    this.siguientePagPositions = this.siguientePagPositions.bind(this);
  }

  siguientePagPositions(eventKey) {
    console.log('Next Positions...');
    let loaderElement = $('#loading');
    // console.log(loaderElement);
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex';
    }
    // loading.style.position('flex');
    let temporalPositions = this.state.bracketStandings.items;
    // console.log('siguiente');
    let val = eventKey;
    this.queryParams.page = this.state.posActualPage + 1;
    API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams).then((response) => {
      this.setState(
        (prevState) => {
          // console.log(response.data);
          let newPosiciones = response.data;
          let newItems = response.data.items !== null ? response.data.items : null;
          newItems.forEach((item) => {
            temporalPositions.push(item);
          });
          newPosiciones.items = temporalPositions;
          return {
            standings: newPosiciones,
            posActualPage: prevState.posActualPage + 1,
          };
        },
        () => {
          if (loaderElement !== undefined && loaderElement.length > 0) {
            loaderElement[0].style.display = 'none';
            let avatars = $('.score-avatars').height('auto');
          }
        }
      );
    });
  }

  /**
   * This function handles the overlay behaviour
   * affter the user copies picks from other entry
   */
  confirmationCopy() {
    console.log('handleConfirmationCopy');
    setTimeout(() => {
      this.setState({
        errorCopiar: null,
      });
    }, 1800);
    // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function redirects to the register page
   * by changing the state of the component
   */
  redirectRegistro() {
    // console.log('Redirection');
    this.setState({
      redirectRegistro: true,
    });
  }

  /**
   * This function redirects to the login page
   * by changing the state of the component
   */
  redirectLWithEntry() {
    // console.log('Redirection');
    this.setState({
      redirectWithEntry: true,
    });
  }

  /**
   * This function redirects to the login page
   * by changing the state of the component
   */
  redirectLogin() {
    // console.log('Redirection');
    this.setState({
      redirectLogin: true,
    });
  }

  /**
   * This function redirects to the lobby
   * by changing the state of the component
   */
  redirect() {
    // console.log('Redirection');
    this.setState({
      redirectLobby: true,
    });
  }

  /*********************
   * Lifecycle Methods
   *********************/
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.loaded) {
      if (this.state.redirectLobby !== nextState.redirectLobby) {
        return true;
      } else if (nextState.scrollElement !== this.state.scrollElement) {
        // console.log('C');
        return true;
      } else if (this.state.activeRound !== nextState.activeRound) {
        return true;
      } else if (this.state.showAbonar !== nextState.showAbonar) {
        return true;
      } else if (this.state.showPanel !== nextState.showPanel) {
        return true;
      } else if (this.state.showTutorial !== nextState.showTutorial) {
        return true;
      } else if (this.state.showPanelRegister !== nextState.showPanelRegister) {
        return true;
      } else if (this.state.copiarPicksExito !== nextState.copiarPicksExito) {
        return true;
      } else if (nextState.errorCopiar !== this.state.errorCopiar) {
        console.log('C');
        return true;
      } else if (
        nextState.bracketStandings.items.length !== this.state.bracketStandings.items.length
      ) {
        console.log('Update Standings');
        return true;
      } else if (nextState.poolWeekSelected !== this.state.poolWeekSelected) {
        return true;
      } else if (nextState.posActualPage !== this.state.posActualPage) {
        console.log('Update Standings');
        return true;
      } else if (
        this.state.enoughBalance !== null &&
        nextState.enoughBalance !== this.state.enoughBalance
      ) {
        console.log('Update balance');
        return true;
      } else if (
        this.props.userData !== null &&
        this.props.userData.balanceReal !== nextProps.userData.balanceReal
      ) {
        console.log('Update balance');
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    const which = this.state.item;
    const bracket = this.props.item[0];

    if (which === null) {
      return;
    }

    let userData = utils.getUserInfo();

    if (userData) {
      API.fetchUserData()
        .then((response) => {
          this.props.updateUserInfo(response.data);
          utils.setUserInfo(response.data);
          userData = response.data;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching User data',
          });
        });
    }

    axios
      .all([
        API.fetchBracketsDetails(which),
        API.fetchBracketStandings(which, this.queryParams),
        API.fetchTickets(bracket.id, 'bracket'),
        API.fetchBracketGroups(bracket.tournament_instances[0].id),
        API.fetchBracketStages(bracket.tournament_instances[0].id),
      ])
      .then(
        axios.spread((bracketInfoResponse, bracketStandingsResponse, ticketsAvailable, bracketGroupsData, bracketStagesData) => {
          const bracketInfoData = bracketInfoResponse.data.bracket;
          const bracketStandingsData = bracketStandingsResponse.data;
          const tickets_ = ticketsAvailable ? ticketsAvailable.data.tickets : null;
          const bracketGroups = bracketGroupsData.data;
          const bracketStages = bracketStagesData.data.stages;
          const bracketMatches = bracketStagesData.data.matches;
          const bracketCurrentStage = bracketStagesData.data.current;;

          if (bracketInfoData.status !== 'upcoming') {
            console.log('Not  Upcoming');
            console.log('Redirecting...');
            this.setState({ redirectLobby: true, bracketInfo: bracketInfoData });
          }

          this.setState(
            () => {
              let tempMatches = {};
              let teams = [];

              bracketGroups.groups.map((group) => {
                group.teams.forEach((team) => {
                  teams.push(team);
                });
              });

              bracketStages.forEach((stage) => {
                let stageMatches = [];
                bracketMatches.forEach((match) => {
                  if (match.stage_id === stage.id) {
                    stageMatches.push(match);
                  }
                });
                tempMatches[stage.name] = stageMatches;
              });

              if (!userData) {
                let redirObj;
                redirObj = {
                  id: this.state.bracketInfo.id,
                  game_type: 'bracket',
                };
                utils.saveDataNotLoggedUser(redirObj);
              }

              if (!userData) {
                return {
                  item: this.props.item[0].id,
                  enoughBalance: false,
                  bracketInfo: bracketInfoData,
                  userData: userData,
                  bracketStandings: bracketStandingsData,
                  logged: this.props.logged,
                  bracketStages: bracketStages,
                  bracketTeams: teams,
                  bracketGroups: bracketGroups.groups,
                  bracketMatches: tempMatches,
                  matches: bracketMatches,
                  bracketCurrentStage: bracketCurrentStage,
                };
              } else {
                let balanceEnough = utils.isBalanceEnough(
                  bracketInfoData.entry_currency,
                  bracketInfoData.entry_fee
                );

                return {
                  enoughBalance: balanceEnough,
                  bracketInfo: bracketInfoData,
                  userData: userData,
                  bracketStandings: bracketStandingsData,
                  logged: this.props.logged,
                  bracketStages: bracketStages,
                  bracketTeams: teams,
                  bracketGroups: bracketGroups.groups,
                  bracketMatches: tempMatches,
                  matches: bracketMatches,
                  bracketCurrentStage: bracketCurrentStage,
                  loaded: true,
                };
              }
            }
          );
        })
      )
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching bracket in popup',
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {

  }

  render() {
    let conditionalComponent;
    if (this.state.redirectLobby) {
      let nameQuina = this.state.bracketInfo.slug;
      conditionalComponent = (
        <Redirect
          to={{
            pathname: `/bracketPerfect/${nameQuina}/${this.state.bracketInfo.id}`,
          }}
        />
      );
    } else if (this.state.redirectRegistro) {
      let url = 'registrate';
      // console.log('Registrate');
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: url,
          }}
        />
      );
    } else {
      // console.log('Not Redirecting...');
      if (this.state.loaded) {
        let nameQuina = this.state.bracketInfo.slug;

        // console.log('LOADED...');
        // console.log(this.state);
        conditionalComponent = (
          <div className="quiniela-info-container">
            <div className="quiniela-container">
              {this.state.logged ? null : (
                <NotLoggedUserBar
                  redirectLogin={this.props.showLoginPopUp}
                  redirectRegistro={this.props.showRegisterPopUp}
                />
              )}
              <RegisterSteps isBalanceEnough={this.state.enoughBalance} />
              <div className="quinielas-info row">
                <div className="flex-row responsive-sm">
                  <div id="picksQuiniela" className="pool-info flex-col">
                    <div
                      className={`picks-message-overlay
                          ${
                            this.state.errorCopiar !== null
                              ? this.state.errorCopiar
                                ? 'show-message failed'
                                : 'show-message'
                              : ''
                          }`}
                    >
                      {this.state.errorCopiar !== null ? (
                        this.state.errorCopiar ? (
                          <span>Intentalo nuevamente ...</span>
                        ) : (
                          <div>
                            <span>¡Tus pick se copiaron con éxito!</span>
                            <i className="ion-checkmark-circled" />
                            <span>No olvides Guardarlos</span>
                          </div>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      id={`bracketContainer${
                        this.state.bracketEntriesInfo === null
                          ? ''
                          : '-' + this.state.bracketEntriesInfo.bracket_entry.id
                      }`}
                      className="panel-pickwin flex-container column"
                    >
                      <div className="row title-quiniela ">
                        <div className="title-wrapper">
                          <img src={picks} className="pool-icons tablas" />
                          <span>MIS BRACKET</span>
                        </div>
                      </div>

                      <BracketsUI
                        scrollElement={this.state.scrollElement}
                        allBracketTeams={this.state.bracketTeams}
                        bracketStages={this.state.bracketStages}
                        getCurrentPicks={this.state.getCurrentPicks}
                        type="popup"
                        showReRegister={this.state.showReRegister}
                        logged={this.state.logged}
                        selectedEntryData={null}
                        selectedEntry={null}
                        entryId={null}
                        enoughBalance={this.state.enoughBalance}
                        bracketInfo={this.state.bracketInfo}
                        groups={this.state.bracketGroups}
                        matches={this.state.bracketMatches}
                        refreshAfterGuardar={null}
                        updateUserInfo={this.props.updateUserInfo}
                        userData={this.state.userData}
                      />

                      <Button
                        href={`/bracketPerfect/${nameQuina}/${this.state.bracketInfo.id}`}
                        className="btn-lobby"
                        bsStyle="default"
                        disabled={!this.state.logged}
                        onClick={(e) => {
                          // this.disableClickedButton (e, 2000);
                          // this.redirect();
                        }}
                      >
                        <Link to={`/bracketPerfect/${nameQuina}/${this.state.bracketInfo.id}`}>
                          <span style={{ padding: '0 10px' }}>IR A LOBBY BRACKET</span>
                        </Link>
                      </Button>
                    </div>
                  </div>
                  {/* <div className="second-panel flex-col col-20">
                    <div className="panel-pickwin">
                      <div className="row title-quiniela">
                        <div className="title-wrapper">
                          <img src={participantes} className="pool-icons tablas" />
                          <span>Participantes</span>
                        </div>
                      </div>
                      <Participantes
                        type="bracket"
                        users={this.state.bracketStandings}
                        siguientePagPositions={this.siguientePagPositions}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <RegisterPanel
                closePanel={this.closePanelRegister}
                entryId={null}
                logged={this.state.logged}
                picks={this.state.picks}
                pool={this.state.bracketInfo}
                matches={this.state.bracketMatches}
                popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                quinaID={this.state.item}
                redirectToMisQuinielas={this.redirectToMisQuinielas}
                showPanel={this.state.showPanelRegister}
                showTutorial={this.showTutorial}
                temporalEntry={this.state.temporalRegisterPicks}
                updateStateFunc={this.sendFullRegister}
                updateUserInfo={this.props.updateUserInfo}
                userData={utils.getUserInfo()}
              />
              <AbonarSaldo
                type="modal"
                showAbonarSaldo={this.state.showAbonar}
                updatePanelSate={this.hideAbonar}
                showAbonarPop={this.showAbonar}
                updateUserInfo={this.props.updateUserInfo}
              />
            </div>
          </div>
        );
      } else {
        // console.log('NOT LOADED...');
        conditionalComponent = <Loading label="Cargando..." />;
      }
    }
    return conditionalComponent;
  }
}
export default BracketPopUp;
