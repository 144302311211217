import styled from 'styled-components'

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 0 1em;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    margin: 0;
  }
`

export const Column1 = styled.div`
  width: 100%;

  @media screen and (min-width: 1200px) {
    width: 60%;
  }
`

export const Column2 = styled.div`
  width: 100%;
  position: relative;

  @media screen and (min-width: 1200px) {
    width: 40%;
  }
`
