import React from 'react';
import $ from 'jquery';

import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';

const ContestControlsF1 = ({ label, currentContest, contests, handleContestChange }) => {  
  if (!contests) {
    return null;
  }

  let contestButtons = contests.map((contest) => {
    return (
      <ToggleButton
        key={contest.id}
        className='bottom-separation font9'        
        value={contest.id}
        style={{width: '55px'}}        
      >
        {contest.abbreviation}
      </ToggleButton>
    );
  });
  
  return (
    <div className="flex-row font-chivo">
      <ButtonToolbar id="week-controls" className="flex-col">
        <ToggleButtonGroup          
          className={`
              ${$(window).width() <= 768 ? 'flex-container align justify' : 'flex-row row-wrap'}`}
          name="weeks-controls"
          type="radio"
          value={currentContest.id}
          onChange={handleContestChange}
        >
          {contestButtons}
        </ToggleButtonGroup>
      </ButtonToolbar>
    </div>
  );
};

export default ContestControlsF1;
