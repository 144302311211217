import numeral from 'numeral'
import React, { useEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import useSound from 'use-sound'

// Components
import LevelName from './LevelName'

// Assets
import imgArrow from '../../assets/img/Flechas verdes.png'
import sound from '../../assets/sounds/level-progress.wav'

BarLevel.defaultProps = {
  start: 0,
  end: 0,
  playing: false,
  duration: 1.8,
  fps: 60
}

// 1 - If is playing then animate progres
// 2 - When progress is 100% then change level name
export default function BarLevel (props) {
  const tick = useRef()

  const [progress, setProgress] = useState(props.start)
  const [playSound] = useSound(sound, { soundEnabled: !props.muted })

  const DURATION = 1000 // ms


  useEffect(() => {
    const realFPS = 1000 / props.fps
    const stepsCount = props.duration * 1000 / realFPS
    const step = (props.end - props.start) / stepsCount

    if (props.playing) {
      // Reseting count
      setProgress(props.start)

      // Animation transition
      tick.current = window.setInterval(() => {
        setProgress(prev => {
          const next = prev + step

          if (next >= props.end) {
            window.clearInterval(tick.current)
            tick.current = null

            window.setTimeout(() => {
              props.setCurrentLevelName(props.currentAnimation.nextLevel)
              // setProgress(0)
              playSound()
            }, 800)
          }

          return next <= props.end ? next : props.end
        })
      }, realFPS)
    }
  }, [props.playing])

  return (
    <Container>
      <Box>
        <Progress
          style={{
            width: `${progress}%`
          }}
        >
          <Arrows
            progress={progress}
            duration={DURATION / 2}
          />
        </Progress>
      </Box>
      <Info>
        <LevelName levelName={props.currentLevelName} />
        <span>{numeral(progress / 100).format('%')}</span>
      </Info>
    </Container>
  )
}

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 2.5em;
  width: 350px;
  margin-top: 1em;
`

const animaArrow = keyframes`
  from {
    opacity: 0;
    transform: rotate(90deg) translateY(0) scale(0.7);
  }
  to {
    opacity: 1;
    transform: rotate(90deg) translateY(5em) scale(1);
  }
`

const ArrowSmall = styled.img`
  width: 1.7em;
  transform: rotate(90deg);
  position: absolute;
  top: -1.9em;
  right: 4em;
  animation: 1.5s ${animaArrow} alternate infinite;
`

const ArrowSmall2 = styled.img`
  width: 2.3em;
  transform: rotate(90deg);
  position: absolute;
  bottom: -1.52em;
  right: 7em;
  animation: 1.5s ${animaArrow} alternate infinite 440ms;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin: 1.5em 0 2.5em 0;
`

const Box = styled.div`
  border: 2px solid whitesmoke;
  position: relative;
  height: 1em;
  border-radius: 0.3em;
  width: 100%;
`

const Progress = styled.div`
  height: calc(100% + 4px);
  background-color: #a9ff00;
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 0.3em 0.6em 0.6em 0.3em;
  box-shadow: 0 0 23px 2px #a9ff00c7;
  width: 0%;
  transition: width 50ms linear;

  div {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: -0.2em;
    width: 4em;
  }
`

const LighBox = styled.span`
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 2em;
  height: 2em;
  background-color: #bcff38;
  filter: blur(16px);
`

const Arrows = (props) => {
  return (
    <div style={{
      opacity: props.progress >= 8 ? 1 : 1,
      transition: `opacity ${props.duration}ms ease`,
      width: '3em'
    }}
    >
      <LighBox />
      {props.progress >= 10 && (
        <>
          <ArrowSmall src={imgArrow} />
          <ArrowSmall2 src={imgArrow} />
        </>
      )}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 39.38 30.59'
        style={{ position: 'relative', left: 1, top: 1 }}
      >
        <path
          style={{
            fill: 'none',
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3
          }}
          d='m25.29 1.5 12.59 13.79-12.59 13.8'
        />
        <path
          style={{
            fill: 'none',
            opacity: 0.9,
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2
          }}
          d='m17.19 1.5 12.6 13.79-12.6 13.8'
        />
        <path
          style={{
            fill: 'none',
            opacity: 0.7,
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2
          }}
          d='m9.1 1.5 12.59 13.79L9.1 29.09'
        />
        <path
          style={{
            fill: 'none',
            opacity: 0.5,
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2
          }}
          d='m1 1.5 12.59 13.79L1 29.09'
        />
      </svg>
    </div>
  )
}
