import React from 'react';

import { Button } from 'react-bootstrap/lib';

import picks from '../../assets/images/quinielasIconBlanco-50.png';
import guardar from '../../assets/images/guardarPicks-50.png';
import gaUtils from '../GAWrapper';
import utils from '../../utils/utils';
import participantes from '../../assets/images/participantesIconBlanco-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';

import { Redirect } from 'react-router';
import actions from '../QuinielaGroupsUI/actions';
import PointsDistribution from '../PointsDistribution';
import PointsDistributionF1 from '../PointsDistribution/PointsDistributionF1';

import RegisterPanel from '../RegisterPanel';
import Participantes from '../Participantes';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import QuinielaGroupsUI from '../QuinielaGroupsUI';
import Premios from '../Premios';
import PremiosF1 from '../Premios/PremiosF1';
import $ from 'jquery';

const PopUpButtons = ({ isLogged, enoughBalance, dispatch, poolInfo }) => {
  let conditionalComponent = null;

  if (isLogged) {
    if (enoughBalance) {
      conditionalComponent = (
        <Button
          className="guardar-picks callToAction"
          bsStyle="success"
          disabled={poolInfo.is_full || utils.isSuPick() || !enoughBalance}
          onClick={() => {
            actions.handleRegistration(dispatch);
          }}
        >
          <img className="pool-icons" src={guardar} />
          <span>GUARDAR PICKS Y REGÍSTRAME </span>
        </Button>
      );
    } else {
      conditionalComponent = (
        <Button
          className="guardar-picks callToAction"
          bsStyle="success"
          onClick={() => {
            actions.handleOpenAbonar(dispatch);
          }}
        >
          <img className="pool-icons" src={abonar} />
          <span>ABONAR SALDO</span>
        </Button>
      );
    }
  }

  return conditionalComponent;
};

const RegisterSteps = ({ isBalanceEnough }) => {
  let conditionalComponent = null;

  if (isBalanceEnough) {
    conditionalComponent = (
      <div id="registerSteps" className="row">
        <div className="col-xs-12 text-center register-title-container">
          <span className="register-title">
            <i className="ion-flag" />
            Sigue estos pasos para participar en esta quiniela.
          </span>
        </div>
        <div className="col-xs-12 steps-container">
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Elige tús picks</span>
          </div>
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">2</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu quiniela</span>
          </div>
        </div>
      </div>
    );
  } else if (!isBalanceEnough) {
    conditionalComponent = (
      <div id="registerSteps" className="row">
        <div className="col-xs-12 text-center register-title-container">
          <span className="register-title">
            <i className="ion-flag" />
            Sigue estos pasos para participar en esta quiniela.
          </span>
        </div>
        <div className="col-xs-12 steps-container">
          <div className="col-xs-4  register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Elige tú pick</span>
          </div>
          <div className={`col-xs-4  register-step-item `}>
            <span className="step-number">2</span>
            <img src={abonar} className="step-icon" />
            <span className="step-label">Abona saldo</span>
          </div>
          <div className="col-xs-4  register-step-item">
            <span className="step-number">3</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu quiniela</span>
          </div>
        </div>
      </div>
    );
  }

  return conditionalComponent;
};

const QuinielaGrupoPopUp = ({
  poolId,
  updateUserInfo,
  updateUserBalance,
  userData,
  userBalance,
  selectedPool,
}) => {

  let { state, dispatch } = React.useContext(QuinielaGroupsContext);

  React.useEffect(() => {
    let fakeMatch = {
      isExact: true,
      params: {
        slug: selectedPool.slug,
      },
    };
    gaUtils.handleDocumentAttributes(fakeMatch);

    if (utils.isLogged()) {
      //console.log('%c Initial Side Effect in Popup', 'color:rgb(255, 199, 48) ; font-size: 2em;');

      Promise.all([
        actions.getPoolDetails(dispatch, poolId),
        actions.getUserData(dispatch),
        actions.getUserTickets(dispatch, poolId),
        actions.getPoolStandings(dispatch, poolId, state.queryParams),
        actions.getSimilarByPoolGroup(dispatch, poolId),
      ])
        .then(
          (values) => {
            let [poolDetails, userTickets] = [...values];
            if (poolDetails.data.group_pool.status !== 'upcoming') {
              if (selectedPool.entries) {
                window.location = `/quinielaGrupo/${poolDetails.data.group_pool.slug}/${poolDetails.data.group_pool.id}/${selectedPool.entries[0].id}`;
              } else {
                window.location = `/quinielaGrupo/${poolDetails.data.group_pool.slug}/${poolDetails.data.group_pool.id}`;
              }
              return;
            }
            // console.log( poolDetails );

            actions.isBalanceEnough(
              dispatch,
              poolDetails.data.group_pool.entry_fee,
              poolDetails.data.group_pool.entry_currency,
              userTickets.data.tickets
            );
            actions.initializeEntry(
              dispatch,
              poolId,
              poolDetails.data.buckets,
              poolDetails.data.bucketKeys,
              poolDetails.data.group_pool.group_pool_tiebreakers,
              null,
              false,
              poolDetails.data.players,
              poolDetails.data.current_contest,
              poolDetails.data.group_pool.entry_currency,
              poolDetails.data.group_pool.contests,
            );
            actions.updateLocation(poolDetails.data.group_pool.slug, poolId, null, true);

            dispatch({ type: 'isReady' });
          },
          (reason) => {
            console.error(reason);
            dispatch({ type: 'isError', payload: { error: reason } });
          }
        )
        .catch((error) => {
          console.error(error);
          dispatch({ type: 'isError', payload: { error: error } });
        });
    } else {
      console.log(
        '%c Initial Side Effect in Popup withouth auth',
        'color:rgb(255, 199, 48) ; font-size: 2em;'
      );

      Promise.all([
        actions.getPoolDetails(dispatch, poolId),
        actions.getPoolStandings(dispatch, poolId, state.queryParams),
      ])
        .then(
          (values) => {
            let [poolDetails, poolStandings] = [...values];
            // console.log(values);
            // console.log( poolDetails );

            if (poolDetails.data.group_pool.status !== 'upcoming') {
              // window.location = `quinielaGrupo/${poolDetails.data.group_pool.slug}/${poolDetails.data.group_pool.id}`;
              return;
            }

            actions.isBalanceEnough(
              dispatch,
              poolDetails.data.group_pool.entry_fee,
              poolDetails.data.group_pool.entry_currency,
              []
            );
            actions.initializeEntry(
              dispatch,
              poolId,
              poolDetails.data.buckets,
              poolDetails.data.bucketKeys,
              poolDetails.data.group_pool.group_pool_tiebreakers,
              null,
              false,
              poolDetails.data.players,
              poolDetails.data.current_contest,
              poolDetails.data.group_pool.entry_currency,
              poolDetails.data.group_pool.contests,
            );
            actions.updateLocation(poolDetails.data.group_pool.slug, poolId, null, true);

            dispatch({ type: 'isReady' });
          },
          (reason) => {
            console.error(reason);
            dispatch({ type: 'isError', payload: { error: reason } });
          }
        )
        .catch((error) => {
          console.error(error);
          dispatch({ type: 'isError', payload: { error: error } });
        });
    }
  }, []);

  const maxHeightPanel = () => {
    if (state.poolInfo.sport_id !== 7) {
      setTimeout(() => {
        let maxHeight = $('.panel-pickwin .premios-list').height();
        $('#sistemaPuntos .panel-pickwin').height(maxHeight);
        $('#participantesQuiniela .panel-pickwin').height(maxHeight);
      }, 800);
    } else {
      setTimeout(() => {
        $('#sistemaPuntos .panel-pickwin').height('100%');
        $('#sistemaPuntos .panel-pickwin').css({marginBottom: '10px'});
      }, 800);
    }
  };

  if (!state.mounted) {
    return (
      <div className="flex-container justify align">
        {' '}
        <i className="ion-load-a loading" />{' '}
      </div>
    );
  } else if (state.redirect) {
    console.log(state.redirectTo);
    return (
      <Redirect
        push
        to={{
          pathname: state.redirectTo
            ? state.redirectTo
            : `/quinielaGrupo/${state.poolInfo.slug}/${poolId}`,
        }}
      />
    );
  } else {
    return (
      <div className="quiniela-info-container">
        <div className="quiniela-container">
          <RegisterSteps isBalanceEnough={state.enoughBalance} />
          <RegisterPanel
            closePanel={() => {
              console.log('close');
            }}
            dispatch={dispatch}
            entryId={null}
            updateStateFunc={actions.handleRegistration}
            handleConfirmation={() => {
              console.log('handleConfirmation click');
            }}
            logged={utils.isLogged()}
            registerPicks={state.myEntry}
            payType={state.poolInfo.entry_currency}
            pool={state.poolInfo}
            myEntries={
              state.entriesInfo
                ? [state.entriesInfo.entry, ...state.entriesInfo.other_entries]
                : null
            }
            quinaID={poolId}
            refreshAfterGuardar={() => {
              console.log('Refresh after guardar');
            }}
            returnError={state.returnError}
            showPanel={state.showRegistration}
            showTutorial={() => {
              console.log('show tutorial');
            }}
            type="group_pool"
          />
          <div className="quinielas-info flex-row">
            <div className="flex-col">
              <div id="picksQuiniela" className="pool-info col-sm-12 col-md-5" style={{marginTop: '-9px'}}>
                <QuinielaGroupsUI
                  updateUserBalance={updateUserBalance}
                  updateUserInfo={updateUserInfo}
                />
              </div>
              <div className="second-panel col-sm-12 col-md-7">
                <div
                  className="flex-row responsive-sm row-no-padding"
                  style={{ marginBottom: '10px' }}
                >
                  <div id="premiosQuiniela" className={`popup  col-md-8 col-xs-12`}>
                    <div className="flex-col col-no-padding">
                      <div className="panel-pickwin">
                        { state.poolInfo.sport_id !== 7 && (
                          <Premios
                            weeks={null}
                            estado={state.mounted}
                            loaded={state.mounted}
                            poolInfo={{ group_pool: state.poolInfo }}
                            premios={state.prizes}
                            standGroups={null}
                            standings={state.standings}
                            type={'popup'}
                          />
                        ) || (
                          <PremiosF1
                            estado={state.mounted}
                            loaded={state.mounted}
                            poolInfo={{ group_pool: state.poolInfo }}
                            premios={state.prizes}
                            contestPrizes={state.contestPrizes}
                            standGroups={null}
                            standings={state.standings}
                            type={'popup'}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div id="participantesQuiniela" className={`popup col-md-4 col-xs-12 `}>
                    <div className="flex-col panel-pickwin col-no-padding" style={maxHeightPanel()}>
                      <div className="flex-row title-quiniela">
                        <div className="title-wrapper">
                          <img src={participantes} className="pool-icons tablas" />
                          <span>Participantes</span>
                        </div>
                      </div>
                      <Participantes
                        users={state.standings}
                        type="group_pool"
                        siguientePagPositions={() => {
                          console.log('Sigueiente pos');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div id="sistemaPuntos" className={`popup col-md-8 col-xs-12 `}>
                  {state.poolInfo.sport_id === 7 && (
                    <PointsDistributionF1
                      pointsDistribution={state.poolInfo.points_distributions}
                      isPopUp={true}
                    />
                  )}

                  {(state.poolInfo.sport_id !== 6 && state.poolInfo.sport_id !== 7) && (
                    <PointsDistribution
                      pointsDistribution={state.poolInfo.points_distributions}
                      isPopUp={true}
                    />
                  )}
                </div>

                <Button
                  className={`btn-lobby`}
                  bsStyle="default"
                  disabled={!utils.isLogged()}
                  onClick={(e) => {
                    actions.redirectTo(dispatch, `/quinielaGrupo/${state.poolInfo.slug}/${poolId}`);
                  }}
                >
                  <span style={{ padding: '0 10px' }}>IR A LOBBY DE QUINIELA</span>
                </Button>
                <PopUpButtons
                  isLogged={utils.isLogged()}
                  enoughBalance={state.enoughBalance}
                  poolInfo={state.poolInfo}
                  dispatch={dispatch}
                  updateUserInfo={updateUserInfo}
                  updateUserBalance={updateUserBalance}
                  entriesInfo={state.entriesInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default QuinielaGrupoPopUp;
