import React from 'react';
import PicksGrupo from '../PicksGrupo/PicksGrupo';
import { ScrollSync } from 'react-scroll-sync';
import { Button, Nav, Modal, NavItem } from 'react-bootstrap/lib';

import Premios from '../Premios';
import 'jquery';
import Marcadores from '../Marcadores/Marcadores';
import ChatPanel from '../ChatPanel/ChatPanel';
import { MainHeaderPicksGrupo } from '../../utils/quinielasComponents';
import Utils from '../../utils/quinielasUtils';
import utils from '../../utils/utils';

import API from '../../utils/api';
import TablaPosiciones from '../TablaPosiciones/TablaPosiciones';
import TablaSemanalPosiciones from '../TablaSemanalPosiciones/TablaSemanalPosiciones';
import $ from 'jquery';

const PanelConfirmation = ({
  showConfirmation,
  hideConfirmation,
  title,
  label,
  errorStatus,
  errorTitle,
  pdfFiles,
}) => {
  if (!showConfirmation) return null;
  if (!pdfFiles.file) {
    return (
      <Modal
        bsSize="sm"
        dialogClassName="confirmationPanelPopUp"
        show={showConfirmation}
        keyboard={false}
      >
        <div className="panel-abonar">
          <div
            id="panelTitle"
            className={`flex-row row-no-padding panel-abonar-titulo ${errorStatus ? 'failed' : ''}`}
          >
            <span>{errorStatus ? errorTitle : title}</span>
            <button className="cerrar-panel" onClick={hideConfirmation}>
              <div className="btn-icon-close">
                <i className="ion-ios-close-empty" />
              </div>
            </button>
          </div>
          <div
            className="pickwin-panel flex-container jusify align column"
            style={{ paddingBottom: '10px' }}
          >
            <i className="ion-alert-circled alert"></i>
            <span>
              Aún no se ha generado ningún archivo, los archivos se generan conforme comiencen los
              partidos.
            </span>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      bsSize="sm"
      dialogClassName="confirmationPanelPopUp"
      show={showConfirmation}
      keyboard={false}
    >
      <div className="panel-abonar">
        <div
          id="panelTitle"
          className={`flex-row row-no-padding panel-abonar-titulo ${errorStatus ? 'failed' : ''}`}
        >
          <span>{errorStatus ? errorTitle : title}</span>
          <button className="cerrar-panel" onClick={hideConfirmation}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className=" pickwin-panel flex-row">
          <div className="flex-col col-60 col-offset-20">
            <div className="flex-container justify align column text-center">
              <div className="flex-row">
                <pre
                  style={{ marginTop: '10px' }}
                  className="flex-col col-90 col-offset-5 col-center"
                >
                  Checksum: {pdfFiles.file.checksum}
                </pre>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-row row-center text-center">
              <div className="flex-col col-center  ">
                <i className="ion-help-circled"></i> Puedes corroborar que tu archivo se descargo
                correctamente comparando el checksum.
              </div>
            </div>
          </div>
        </div>

        <div className="btn-wrap flex-row">
          <div className="flex-col col-90 col-offset-5">
            <div className="flex-row">
              <Button
                className=" btn-pick btn-rojo flex-col col-40 col-offset-5 col-center"
                onClick={hideConfirmation}
              >
                Salir
              </Button>
              <Button
                className=" btn-pick flex-col col-center col-offset-10 col-40"
                target="blank"
                href={pdfFiles.file.url + pdfFiles.file.file}
              >
                <i className="ion-android-download"></i>
                {label}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ControlWeek = ({ whichList, changePosiciones, currentWeekName }) => {
  return (
    <Nav
      bsStyle="pills"
      className="flex-row row-no-padding tablas-weekley"
      activeKey={whichList}
      onSelect={changePosiciones}
    >
      <NavItem eventKey={'full'}>General</NavItem>
      <NavItem eventKey={'weekly'}>{currentWeekName}</NavItem>
    </Nav>
  );
};

class InfoContainer extends React.Component {
  constructor(props) {
    super(props);
    let tempState = this.props.estado;
    tempState.actualPage = 1;
    tempState.posActualPage = 1;
    tempState.posiciones = this.props.posiciones;
    tempState.whichList = 'full';
    tempState.showPdfConfirmation = false;
    this.state = tempState;
    // console.log('info container state...',this);
    // console.table(this.props.estado)
    this.selectSection = this.selectSection.bind(this);
    this.getAganar = Utils.getAganar.bind(this);
    this.nextPageStandings = Utils.nextPageStandings.bind(this);
    this.siguientePagPositions = this.siguientePagPositions.bind(this);
    this.updateStandingsFixturePoints = Utils.updateStandingsFixturePoints.bind(this);
    this.updateStandingsPropsPoints = Utils.updateStandingsPropsPoints.bind(this);
    this.updateStandingsPosition = Utils.updateStandingsPosition.bind(this);
    this.updateStandingsAganar = Utils.updateStandingsAganar.bind(this);
    this.updateState = this.updateState.bind(this);

    this.changePosiciones = (eventKey) => {
      console.log('ChangeList', eventKey);
      this.setState((prevState) => {
        return { whichList: eventKey };
      });
    };

    this.showPdfConfirmation = () => {
      this.setState({ showPdfConfirmation: true });
    };
    this.hidePdfConfirmation = () => {
      this.setState({ showPdfConfirmation: false });
    };
  }
  /**
   * This function handles a call to the API
   * to get the new page of the positions
   * @param eventKey
   */
  siguientePagPositions(eventKey) {
    console.log('Next Positions...');
    let loaderElement = $('#loading');
    let tempActiveSort = null;
    // console.log(loaderElement);
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex';
    }
    // loading.style.position('flex');
    let temporalPositions = this.props.posiciones.items;
    // console.log('siguiente');
    let val = eventKey;

    this.props.queryParams.page = this.props.queryParams.page + 1;

    let tempResp = API.fetchStandings(this.props.poolInfo.pool.id, this.props.queryParams).then(
      (response) => {
        this.setState(
          (prevState) => {
            // console.log( tempActiveSort );
            let newPosiciones = response.data;

            let newItems = response.data.items !== null ? response.data.items : null;

            if (newItems !== null) {
              newItems.forEach((item) => {
                temporalPositions.push(item);
              });
            }

            // console.log(this.props.poolInfo.pool.status);
            if (this.props.poolInfo.pool.status === 'live') {
              this.updateStandingsAganar(temporalPositions);
              // temporalPositions.sort(firstBy('total_possibly_correct', -1).thenBy('entry_id'));
            } else {
              // temporalPositions.sort(firstBy('rank').thenBy('entry_id'));
            }

            // console.log('NEWPos',temporalPositions);
            newPosiciones.items = temporalPositions;
            return {
              posiciones: newPosiciones,
              posActualPage: this.props.queryParams.page + 1,
            };
          },
          () => {
            // if(this.props.sortPositionList !== undefined){
            //   setTimeout( () => {
            //     console.log(tempActiveSort);
            //     this.props.sortPositionList( tempActiveSort, true );
            //   }, 150);
            // }
            if (this.updateScrollPositions !== undefined) {
              this.updateScrollPostions(this.state.posiciones);
            }
            if (loaderElement !== undefined && loaderElement.length > 0) {
              loaderElement[0].style.display = 'none';
              let avatars = $('.score-avatars').height('auto');
            }
          }
        );
      }
    );
  }

  getNameWeekSelected = () => {
    if (this.props.poolInfo.pool.type !== 'full') return '';

    const element = this.props.poolWeeks.weeks.find((e) => e.id === this.props.poolWeekSelected);
    if (element) {
      return element.week_name;
    } else {
      return '';
    }
  };

  selectSection(which) {
    // console.log( 'SelectSection', which );
    let weekId;
    if (this.props.poolInfo.pool.type === 'week') {
      if (this.props.poolInfo.pool.week_id === null) {
        // console.log(this.props.fixtures)
        weekId = this.props.fixtures[0].week_id;
        if (this.props.poolInfo.tiebreakers[weekId] === undefined) {
          weekId = '';
        }
      } else {
        // console.log('%c HERE', 'background:#D9E6AA');
        weekId = this.props.poolInfo.pool.week_id;
        // console.log(weekId, this.props.poolInfo.week_id);
      }
    } else if (this.props.poolInfo.pool.type === 'full' && this.props.poolWeekSelected !== null) {
      // console.log('PoolWeek Selected  isnt Null', this.props.poolWeekSelected, this.state.poolWeeks.current.id);
      weekId = this.props.poolWeekSelected;
    } else {
      // console.log('PoolWeek Selected  is NULL', this.props.poolWeekSelected, this.state.poolWeeks.current.id);
      weekId = this.state.poolWeeks.current.id;
    }
    // console.log('%c Changing Selection','color:rgb(170, 163, 39);font-size:1.2em;' );
    // console.log(which);
    switch (Number(which)) {
      case 1:
        let currentWeekName =
          this.props.poolInfo.pool.type === 'week'
            ? this.props.poolWeeks.current.week_name
            : this.props.poolWeeks.weeks.find((week) => this.props.poolWeekSelected === week.id)
                .week_name;
        return (
          <div className="multiple-quiniela-info principal flex-row row-no-padding responsive-sm">
            <div className="first-section flex-col" style={{ paddingTop: '4px' }}>
              {this.state.poolInfo.pool.weekly_prize ? (
                <div className="flex-row align-items-center justify-content-center">
                  <div className="flex-col col-center col-no-padding col-98 ">
                    <ControlWeek
                      whichList={this.state.whichList}
                      changePosiciones={this.changePosiciones}
                      currentWeekName={currentWeekName}
                    />
                  </div>
                </div>
              ) : null}
              <div className="flex-row">
                <div className="flex-col col-no-padding">
                  <TablaPosiciones
                    cambiarEntradas={this.props.cambiarEntradas}
                    isVisible={this.state.whichList === 'full'}
                    activeSort={this.props.activeSort}
                    actualPage={this.state.posActualPage}
                    changeSearchNick={this.props.changeSearchNick}
                    entryId={this.props.selectedEntry}
                    entry={this.props.entriesInfo !== null ? this.props.entriesInfo.entry : null}
                    fetchSearchableStandings={this.props.fetchSearchableStandings}
                    getAganar={this.getAganar}
                    loaded={this.state.loaded}
                    liveFixturesData={this.props.liveFixturesData}
                    nextPageStandings={this.nextPageStandings}
                    poolInfo={this.props.estado.poolInfo}
                    poolWeekSelected={this.props.poolWeekSelected}
                    posiciones={this.props.posiciones}
                    premios={this.props.estado.premios}
                    resetNickValue={this.props.resetNickValue}
                    siguientePagPositions={this.siguientePagPositions}
                    sortPositionList={this.props.sortPositionList}
                    selectedEntry={this.props.selectedEntry}
                    sortChanged={this.props.sortChanged}
                    standGroups={this.props.estado.standGroups}
                    standings={this.props.posiciones}
                    submittingSearch={this.props.submittingSearch}
                    tipoPremio={this.props.estado.premios.pool_prizes[0].prizes[0].type}
                    type="lobby"
                    userData={this.props.userData}
                    userBalance={this.props.userBalance}
                    parlay={this.props.parlay}
                    parlayDeadlines={this.props.parlayDeadlines}
                  />
                  {this.state.poolInfo.pool.weekly_prize ? (
                    <TablaSemanalPosiciones
                      handleMaxHeight={this.props.handleMaxHeight}
                      isVisible={this.state.whichList !== 'full'}
                      currentWeek={
                        !this.props.poolWeekSelected
                          ? this.props.poolWeeks.current.id
                          : this.props.poolWeekSelected
                      }
                      fixtures={this.state.fixtures}
                      liveFixturesData={this.props.liveFixturesData}
                      logged={this.state.logged}
                      poolInfo={this.state.poolInfo}
                      poolWeeks={this.props.poolWeeks}
                      posiciones={this.props.posiciones}
                      premios={this.state.premios}
                      standGroups={this.state.standGroups.groups}
                      userData={this.props.userData}
                      entry={this.props.entriesInfo !== null ? this.props.entriesInfo.entry : null}
                      entryId={this.props.selectedEntry}
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex-row">
                <div className="flex-col col-no-padding">
                  <div className="flex-row">
                    <Premios
                      weeks={this.props.poolWeeks}
                      estado={this.props.estado}
                      loaded={this.props.estado.loaded}
                      poolInfo={this.props.estado.poolInfo}
                      poolWeekSelected={this.props.poolWeekSelected}
                      premios={this.props.estado.premios}
                      standGroups={this.props.estado.standGroups}
                      standings={this.props.posiciones}
                      type="lobby"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col second-section" style={{marginTop: '5px', height: 'auto'}}>
              {this.props.estado.logged ? (
                <ChatPanel
                  gameType="pool"
                  entries={this.props.estado.entries}
                  handleMessage={this.props.handleMessage}
                  handleMaxHeight={this.props.handleMaxHeight}
                  loaded={this.props.estado.loaded}
                  logged={this.props.estado.logged}
                  newMessage={this.props.newMessage}
                  selectedEntry={this.props.selectedEntry}
                  resetMessage={this.props.resetMessage}
                  quinielaID={this.props.estado.quinielaID}
                  sendMessage={this.props.sendMessage}
                  userAvatar={this.props.estado.logged ? this.props.userData : null}
                  userNick={utils.isLogged() ? this.props.userData.nick : null}
                  withRegister={this.props.withRegister}
                />
              ) : (
                <div className="message-not-logged">
                  {' '}
                  Necesitas Ingresar para poder usar el chat
                </div>
              )}
            </div>
          </div>
        );
        break;
      case 2:
        let tiebreakers;
        if (
          this.props.poolInfo.pool.type === 'full' &&
          this.props.estado.poolInfo.tiebreakers[''] !== undefined
        ) {
          tiebreakers = this.props.estado.poolInfo.tiebreakers[''];
        } else {
          tiebreakers = this.props.estado.poolInfo.tiebreakers[weekId];
        }
        return (
          <ScrollSync>
            <div className="multiple-quiniela-info grupo">
              <div className="upper-section">
                <div className="container-picks">
                  <div className="upper-table">
                    <div className="upper-table-category headers">
                      <MainHeaderPicksGrupo
                        entriesInfo={
                          this.props.entriesInfo === null ? null : this.props.entriesInfo.entry
                        }
                        fixturesInfo={this.props.fixtures}
                        fixtureProps={this.props.fixtureProps}
                        format={this.props.poolInfo.pool.point_format}
                        liveClosedFixtures={this.props.liveClosedFixtures}
                        liveFixtureData={this.props.liveFixturesData}
                        poolInfo={this.state.poolInfo}
                        pdfFiles={this.state.pdfFiles}
                        showPdfConfirmation={this.showPdfConfirmation}
                        hidePdfConfirmation={this.hidePdfConfirmation}
                        poolWeekSelected={this.props.poolWeekSelected}
                        tiebreakInfo={tiebreakers}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lower-section">
                <PanelConfirmation
                  submitting={this.state.submittingDownload}
                  showConfirmation={this.state.showPdfConfirmation}
                  hideConfirmation={this.hidePdfConfirmation}
                  title={'Descarga el pdf de Picks de Grupo'}
                  label={'Descarga el archivo.'}
                  afterConfirmation={this.afterConfirmation}
                  errorStatus={this.state.errorStatus}
                  errorLabel={this.state.errorMessage}
                  errorTitle={'Error'}
                  pdfFiles={this.state.pdfFiles}
                />
                <PicksGrupo
                  nextPicks={this.props.nextPicks}
                  nextProps={this.props.nextProps}
                  selectedEntry={this.props.selectedEntry}
                  entriesInfo={
                    this.props.entriesInfo === null ? null : this.props.entriesInfo.entry
                  }
                  fixtures={this.props.fixtures}
                  fixtureProps={this.props.fixtureProps}
                  handleMaxHeight={this.props.handleMaxHeight}
                  handleUpdateReset={this.props.handleUpdateReset}
                  liveClosedFixtures={this.props.liveClosedFixtures}
                  liveFixtureData={this.props.liveFixturesData}
                  logged={this.state.logged}
                  loadedPicks={this.props.loadedPicks}
                  poolInfo={this.state.poolInfo}
                  poolWeeks={this.props.estado.poolWeekSelected}
                  poolWeekSelected={weekId}
                  quinielaID={this.state.quinielaID}
                  standings={this.props.posiciones}
                  tiebreakers={this.state.tiebreakers}
                  updatedStandings={this.props.updatedStandings}
                  nameWeekSelected={this.getNameWeekSelected()}
                />
              </div>
            </div>
          </ScrollSync>
        );
        break;
      case 3:
        return (
          <div className="multiple-quiniela-info marcadores">
            <div className="first-section flex-col">
              {
                <Marcadores
                  liveFixturesData={this.props.liveFixturesData}
                  loaded={this.state.loaded}
                  poolWeekSelected={this.props.poolWeekSelected}
                  fixtures={this.props.fixtures}
                  logged={this.state.logged}
                  handleMaxHeight={this.props.handleMaxHeight}
                  poolInfo={this.state.poolInfo}
                  userData={this.props.userData}
                />
              }
            </div>
          </div>
        );
        break;
      case 4:
        return (
          <div className="multiple-quiniela-info live">
            <div className="first-section flex-col">
              <TablaSemanalPosiciones
                currentWeek={this.props.poolWeeks.current}
                fixtures={this.state.fixtures}
                liveFixturesData={this.props.liveFixturesData}
                logged={this.state.logged}
                poolInfo={this.state.poolInfo}
                poolWeeks={this.props.poolWeeks}
                posiciones={this.props.posiciones}
                premios={this.state.premios}
                standGroups={this.state.standGroups.groups}
                userData={this.props.userData}
              />
            </div>
          </div>
        );
        break;
    }
  }

  shouldComponentUpdate(nextProps, prevState) {
    if (nextProps.selection !== this.props.selection) {
      return true;
    } else if (nextProps.liveFixturesData !== this.props.liveFixturesData) {
      // console.log('LIVEFIXTURE');
      return true;
    } else if (
      /**
       * NextProps  changed
       */
      nextProps.nextProps !== this.props.nextProps &&
      nextProps.nextProps !== null
    ) {
      return true;
    } else if (
      /**
       * NextPicks  changed
       */
      nextProps.nextPicks !== this.props.nextPicks &&
      nextProps.nextPicks !== null
    ) {
      return true;
    } else if (nextProps.updatedStandings !== this.props.updatedStandings) {
      // console.log('LIVEFIXTURE');
      return true;
    } else if (
      this.props.posiciones &&
      this.props.posiciones.items !== null &&
      this.props.posiciones.items.length > 0 &&
      nextProps.posiciones.items !== null &&
      nextProps.posiciones.items.length > 0 &&
      nextProps.posiciones.items[nextProps.posiciones.items.length - 1].user_prize.base_amount !==
        this.props.posiciones.items[this.props.posiciones.items.length - 1].user_prize.base_amount
    ) {
      // console.log('Standings Updated');
      return true;
    } else if (nextProps.availableMessages !== this.props.availableMessages) {
      return true;
    } else if (nextProps.selectedEntry !== this.props.selectedEntry) {
      return true;
    } else if (prevState.actualPage !== this.state.actualPage) {
      return true;
    } else if (prevState.posActualPage !== this.state.posActualPage) {
      return true;
    } else if (nextProps.livePropsData !== this.props.livePropsData) {
      return true;
    } else if (
      this.props.posiciones.items !== null &&
      nextProps.posiciones.items !== null &&
      nextProps.posiciones.items[nextProps.posiciones.items.length - 1] !==
        this.props.posiciones.items[this.props.posiciones.items.length - 1]
    ) {
      return true;
    } else if (nextProps.updatedStandings !== this.props.updatedStandings) {
      return true;
    } else if (nextProps.poolWeekSelected !== this.props.poolWeekSelected) {
      // console.log('PoolWEek Updated in Info Container')
      return true;
    } else if (nextProps.newMessage.length !== this.props.newMessage.length) {
      return true;
    } else if (nextProps.updatedStandings !== this.props.updatedStandings) {
      return true;
    } else {
      return true;
    }
  }

  componentDidMount() {
    let gameType = this.props.poolInfo.hasOwnProperty('pool')
      ? 'pool'
      : this.props.poolInfo.hasOwnProperty('survivor')
      ? 'survivor'
      : 'bracket';

    let gameId = this.props.poolInfo[gameType].id;

    // console.log(gameType, gameId);
    API.fetchGamePdf(gameId, gameType).then((gamePdfResponse) => {
      // console.log(gamePdfResponse.data);
      this.setState({
        pdfFiles: gamePdfResponse.data,
      });
    });
    setTimeout(() => {
      this.props.handleMaxHeight();
    }, 200);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.premios !== prevProps.premios) {
      this.updateState();
    }
    if (
      prevProps.posiciones &&
      this.props.posiciones.items &&
      prevProps.posiciones.items.length !== this.props.posiciones.items.length
    ) {
      console.log('Pos Changed');
      this.setState(
        {
          posiciones: this.props.posiciones.items.length,
        },
        () => {
          $('.score-avatars').height('20px');
          $('.score-avatars').width('20px');
        }
      );
    }
    if (this.props.selection !== prevProps.selection) {
      setTimeout(() => {
        // console.log('Changed section');
        // $('ul.lista-posiciones.quiniela').height('auto');
        if (this.props.selection === '1' && this.props.poolInfo.pool.weekly_prize) {
          // console.log('Dont update height');
          return;
        }

        this.props.handleMaxHeight();
      }, 100);
    }
  }

  updateState() {
    // let avatars = $('.score-avatars').style.height('auto');
    // console.log(avatars);
    this.setState({
      premios: this.props.premios,
    });
  }

  render() {
    // console.log('Render Info Container');
    let conditionalComponent = this.selectSection(this.props.selection);
    return conditionalComponent;
  }
}

export default InfoContainer;
