import React from 'react'
import styled from 'styled-components'

// Components
import GlowText from './GlowText'
import Link from './Link'
import Text from '../../Text/Text'

export default function NextLevelChart (props) {
  const { nextLevel, points } = props.userLoyalty

  const nextWinCash = (points.total_rake * nextLevel.reward) / 100
  const nextWinVip = (points.total_rake * nextLevel.extra_reward) / 100


  const imageNextLevel = require(`../../../assets/img/levels/next/level-${nextLevel.id}.png`)

  return (
    <Panel>
      <Box>
        <img src={imageNextLevel} />
        <Col>
          <Text as='h6' style={{width: 'fit-content' }}>Rembolso a tu saldo:</Text>
            <GlowText
              currency
              animated
              shine
              color1='#FFF'
              color2='#FFF'
              glow={1}
              end={nextWinCash}
              fontSize={35}
              delay={5000}
              sphereSize={22}
            />

          <Text as='h6' style={{width: 'fit-content' }}>Rembolso en puntos VIP:</Text>

            <GlowText
              animated
              shine
              color1='#FFF'
              color2='#FFF'
              glow={1}
              end={nextWinVip}
              fontSize={35}
              delay={7000}
              sphereSize={22}
            />
        </Col>
       
        {/* <Glow /> */}
      </Box>
      <Link href='/landing/programa-de-lealtad'>
        Ver terminos y condiciones
      </Link>
    </Panel>
  )
}

const Panel = styled.div`
  padding: 1em;
`

const Box = styled.div`
  display: flex;
  align-content: center;
  position: relative;

  img {
    display: block;
    margin: 0;
    max-height: 11em;
  }
`

const Glow = styled.div`
  position: absolute;
  top: 13%;
  right: 17%;
  width: 15em;
  height: 3em;
  z-index: 10;
  background-color: #ffffff91;
  filter: blur(36px);
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`
