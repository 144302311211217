import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Futbol from '../../assets/images/FutbolBall-50.png';

const SoccerScoreboardMatch = ({ fixture }) => {
  return (
    <div className="marcador-partido-item col-md-12 col-lg-6">
      <div className="marcador-panel flex-col col-no-padding border-gradient-marcador">
        <div className="backBlack">
          <div className="marcador-titulo flex-row ">
            <div className="flex-col col-10  col-center text-center">
              <img className="marcadores-ball-icon" src={Futbol}></img>
            </div>
            <LabelTime fixture={fixture} />
          </div>

          <div className="marcador-container flex-container">
            <div className="marcador-equipos flex-col col-no-padding">
              <div className="headerTimesFixture">
                <div className="team-label" />
                <div className="marcadores col-no-padding flex-col">
                  <div className="score-team flex-row row-center">
                    <span className="flex-col text-center">1</span>
                    <span className="flex-col text-center">2</span>
                    <span className="flex-col text-center" style={{ padding: '0 7px' }}>
                      T
                    </span>
                  </div>
                </div>
              </div>

              <div className="equipo home flex-row row-no-padding">
                <div className="team-label col-25 flex-col col-center">
                  <div className="flex-row row-center">
                    <div className="team flex-col col-no-padding col-center">
                      {fixture.home_team.logo === null ? (
                        <div />
                      ) : (
                        <i className={`${fixture.home_team.logo} home`} />
                      )}
                      <span>{fixture.home_team.abbreviation}</span>
                    </div>
                  </div>
                </div>

                <div className="marcadores col-no-padding flex-col">
                  <div className="flex-col col-no-padding">
                    <span className="score-team flex-row">
                      <span className="half">{fixture.stats?.score_home_first_half ?? 0}</span>
                      <span className="half">{fixture.stats?.score_home_second_half ?? 0}</span>
                      <span className="total">{fixture.score_home}</span>
                    </span>
                  </div>
                </div>
              </div>

              <div className="equipo home flex-row row-no-padding">
                <div className="team-label col-25 flex-col col-center">
                  <div className="flex-row row-center">
                    <div className="team flex-col col-no-padding col-center">
                      {fixture.away_team.logo === null ? (
                        <div />
                      ) : (
                        <i className={`${fixture.away_team.logo} home`} />
                      )}
                      <span>{fixture.away_team.abbreviation}</span>
                    </div>
                  </div>
                </div>

                <div className="marcadores col-no-padding flex-col">
                  <div className="flex-col col-no-padding">
                    <span className="score-team flex-row">
                      <span className="half">{fixture.stats?.score_away_first_half ?? 0}</span>
                      <span className="half">{fixture.stats?.score_away_second_half ?? 0}</span>
                      <span className="total">{fixture.score_away}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="marcador-acciones flex-col col-no-padding">
              <FixtureEvents fixture={fixture}></FixtureEvents>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LabelTime = ({ fixture }) => {
  if (fixture.status == 'upcoming') {
    return (
      <div className="title-juego-wrapper flex-row row-no-padding">
        <div className=" titulo-juego-fecha flex-col">
          {moment(fixture.match_date).format('MM/D/YY @ H:mm')}
        </div>
        <div className=" label-prox flex-col col-20 col-cente text-center">Próx.</div>
      </div>
    );
  } else if (fixture.status == 'closed') {
    return (
      <div className="title-juego-wrapper flex-row row-no-padding">
        <div className=" titulo-juego-fecha flex-col">
          {moment(fixture.match_date).format('MM/D/YY @ H:mm')}
        </div>
        <div className="label-finalizado flex-col col-20 col-center text-center">Finalizó</div>
      </div>
    );
  } else if (fixture.status == 'live') {
    return (
      <div className="title-juego-wrapper flex-row row-no-padding">
        <div className="qrtr-title flex-col col-center col-20 text-center">
          <small className="flex-container justify-start">{fixture.stats?.quarter}</small>
        </div>
        <div className="timer-title flex-col col-center text-center">
          <small className="flex-container justify-start">
            <i className="ion-ios-timer" style={{ marginRight: '3px' }} /> {fixture.stats?.timer}
          </small>
        </div>
        <span className="label-live flex-col col-center col-20 text-center">Live</span>
      </div>
    );
  }

  return null
};

const FixtureEvents = ({ fixture }) => {
  let events = fixture.events ?? [];
  return events.map((event, index) => {
    return (
      <div key={index} className="acciones-container">
        <div className="minute">{event.minute}</div>
        <div className={`team ${event.team === 'visitorteam' ? `away` : `home`}`}>
          {event.team === 'visitorteam'
            ? fixture.away_team.abbreviation
            : fixture.home_team.abbreviation}
        </div>
        <div className="player">{event.player}</div>
        <div className="type">
          <div className={event.type} />
        </div>
      </div>
    );
  });
};

LabelTime.propTypes = {
  fixture: PropTypes.object.isRequired,
};

FixtureEvents.propTypes = {
  fixture: PropTypes.object.isRequired,
};

SoccerScoreboardMatch.propTypes = {
  fixture: PropTypes.object.isRequired,
  sportId: PropTypes.number.isRequired,
};

export default SoccerScoreboardMatch;
