import React from 'react'

// Components
import Login from './Login'
import Signup from './Signup'
import CompleteFacebookData from './CompleteFacebookData'
import VerificationSMS from './VerificationSMS'
import CloseOtherSessions from './CloseOtherSessions'
import ForgotPassword from './ForgotPassword'
import WelcomeSuccess from './WelcomeSuccess'
import ModalPanel from '../ModalPanel'
import NotificationBar from './NotificationBar'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Constants
import { LOGIN_STATUS as STATUS } from '../../context/AuthContext'


export default function Auth () {
  const { loginStatus, closeModal } = useAuthContext()

  function getActiveStep () {
    switch (loginStatus) {
      case STATUS.LOGIN_FORM: return (
        <Login />
      )
      case STATUS.SIGNUP_FORM: return (
        <Signup />
      )
      case STATUS.COMPLETE_FACEBOOK_DATA: return (
        <CompleteFacebookData />
      )
      case STATUS.SMS_CONFIRMATION: return (
        <VerificationSMS />
      )
      case STATUS.CLOSE_ANOTHER_SESSIONS: return (
        <CloseOtherSessions />
      )
      case STATUS.FORGOT_PASSWORD: return (
        <ForgotPassword />
      )
      case STATUS.WELCOME_SUCCESS: return (
        <WelcomeSuccess />
      )
    }
  }

  if (!loginStatus) return null

  return (
    <ModalPanel onClose={closeModal}>
      <NotificationBar />
      {getActiveStep()}
    </ModalPanel>
  )
}
