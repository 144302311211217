import React from 'react'
import styled from 'styled-components'

import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

Stack.defaultProps = {}

Stack.propTypes = {
  ...shorthandProptypes
}

export default function Stack (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  const styles = {
    ...stylesShortcuts,
    flexDirection: props.direction,
    gap: props.spacing || '0.5em',
    alignItems: props.align || '',
    justifyContent: props.justify || '',
    flexWrap: props.wrap ? 'wrap' : ''
  }

  return (
    <StackBase style={styles}>
      {props.children}
    </StackBase>
  )
}

const StackBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  width: 100%;
`
