import numeral from 'numeral'
import React from 'react'
import styled from 'styled-components'

// Hooks
import useCountUp from '../../../hooks/useCountUp'

export default function BannerStore ({ balanceVip }) {
  const num = useCountUp({
    start: 0,
    end: balanceVip,
    duration: 1,
    delay: 1
  })

  const balance = numeral(num / 100).format('0,000.00')

  return (
    <Box>
      <Points>
        <strong>{balance}</strong>
        <span>Puntos VIP</span>
      </Points>
      <Gradient />
      <GradientLine />
    </Box>
  )
}


const Box = styled.div`
  display: flex;
  gap: 1em;
  background: #000;
  height: 80px;
  margin: 1em auto 2em auto;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  background: linear-gradient(359deg,#181818,#282828);
  box-shadow: 0px 1px 2px inset #686868, 1px 0px 9px inset #1c1c1c9e;
  font-size: 1.2em;
  position: relative;
`


const Points = styled.div`
  width: 200px;
  height: 107px;
  margin: -0.5em auto 0 auto;
  background-color: #262625;
  border-radius: 1em;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 1px 2px inset #686868, 1px 0px 9px inset #1c1c1c9e;

  span {
    font-size: 0.7em;
    line-height: 2;
  }

  strong {
    color: #99d928;
    font-size: 1.4em;
    font-size: 1.4em;
    font-family: 'Chivo';
    font-weight: 400;
    background: linear-gradient(to right, #88e700 0%, #ddff00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`


const Gradient = styled.div`
  width: 85%;
  height: 70%;
  background: radial-gradient(circle,rgb(135 177 61 / 52%) 0%,rgba(0,0,0,0) 94%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const GradientLine = styled.div`
  width: 99%;
  height: 0.2em;
  background: radial-gradient(circle,rgb(135 177 61) 0%,rgba(0,0,0,0) 94%);
  position: absolute;
  top: 74%;
  left: 50%;
  transform: translate(-50%,-50%);
`