import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Redirect, Link } from 'react-router-dom';
import {
  Button,
} from 'react-bootstrap/lib/';

import API from '../../utils/api';
import Loading from '../Loading/Loading';
import Utils from '../../utils/quinielasUtils';
import utils from '../../utils/utils';
import Futbol from '../../assets/images/FutbolBall-50.png';
import Americano from '../../assets/images/AmericanoBall-50.png';
import Basketball from '../../assets/images/BasketballBall-50.png';
import Baseball from '../../assets/images/BaseballBall-50.png';
import Golf from '../../assets/images/GolfBall-50.png';
import F1 from '../../assets/images/Volante-50.png';
import Tenis from '../../assets/images/TennisBall-50.png';
import Box from '../../assets/images/box.png';
import Hockey from '../../assets/images/Hockey.png';
import closeR from '../../assets/images/closeRed.png';
import closeW from '../../assets/images/close.png';

import numeral from 'numeral';
import firstBy from 'thenby';

import SubscriptionRxs from '../../utils/subscriptions';
import TablaPosiciones from '../TablaPosiciones/TablaPosiciones';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';
import io from 'socket.io-client';

const ConfirmationOverlay = ({ showOverlay, label, currentSavedPicks, allPicks }) => {
  let picksStatus = null;
  let iconStatus = null;
  if (currentSavedPicks !== allPicks) {
    picksStatus = ` Recuerda ingresar todos tus picks sólo has guardado ${currentSavedPicks} de los ${allPicks} picks. `;
    iconStatus = <i className="ion-alert-circled" />;
  } else {
    /**
     * Complete Picks
     **/
    picksStatus = `!Felicidades tus picks están completos¡`;
    iconStatus = <i className="ion-checkmark-circled" />;
  }
  return (
    <div className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}`}>
      <span>
        {' '}
        {iconStatus} {picksStatus}{' '}
      </span>
    </div>
  );
};

class MiBracketPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entriesInfo: [],
      grouStandings: [],
      loaded: false,
      activeStage: 0,
      numeroEntries: this.props.numeroEntries,
      liveFixturesData: this.props.liveFixturesData,
      bracketInfo: [],
      poolWeekSelected: null,
      posActualPage: 1,
      premios: [],
      quinaIndex: null,
      redirectLobby: false,
      selectedWeekfixtures: [],
      searchNick: null,
      selectedEntryData: null,
      standings: [],
      submitting: false,
      submittingSearch: false,
      activeSort: {
        posiciones: false,
        jugadores: false,
        aciertos: false,
        premios: false,
      },
      bracketTiesDistribution: null,
      bracketPrizes: null,
    };

    this.redirectLobby = this.redirectLobby.bind(this);
    this.selectLayout = this.selectLayout.bind(this);
    this.setSport = this.setSport.bind(this);
    this.changeStatusPool = this.changeStatusPool.bind(this);
    this.getAganarBracket = Utils.getAganaBracket.bind(this);
    this.calculateTiesPrizes = Utils.calculateTiesPrizes.bind(this);
    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      order: null,
    };
    this.ws = null;

    this.resetNickValue = () => {
      this.setState(
        {
          searchNick: '',
          submittingSearch: true,
        },
        () => {
          this.queryParams.nick = '';
          API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams).then(
            (response) => {
              this.callbackSearchUser(response.data);
            }
          );
        }
      );
    };

    this.changeSearchNick = (ev) => {
      console.log('changeNick');
      this.queryParams.page = 1;
      this.setState({
        searchNick: ev.target.value,
      });
    };

    this.fetchSearchableStandings = (ev, obj) => {
      if (!this.state.bracketInfo === undefined) {
        return;
      }
      this.setState({ submittingSearch: true });
      // if(this.state.submittingSearch === true ) { return;}
      // console.log('%c Search users', 'color:rgb(110, 223, 85);font-size:1.5em;');
      // console.log(ev.target.value);
      // console.log(ev.target);
      this.queryParams.nick = this.state.searchNick;
      API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams)
        .then((response) => {
          // console.log(response);
          this.callbackSearchUser(response.data);
        })
        .catch((error) => console.log(error));
    };

    this.fetchSearchableStandings = _.debounce(this.fetchSearchableStandings, 500);

    this.callbackSearchUser = (data) => {
      console.log(data);
      let newStandings = data;

      this.setState({
        emptyList: data.items.length === 0 ? true : false,
        standings: data,
        submittingSearch: false,
        // liveFixtureData: Date.now(),
        // sortChanged: true,
      });
    };

    this.handleTableHeight = () => {
      // console.log('%c Handles Table Pos Height', 'background: rgb(255, 199, 48);');
      // console.log( this.props.maxHeight );
      let panelElements = $(`.panelMisQuinielas.bracket`);
      let myPanel = null;
      if (panelElements.length !== 0) {
        // console.log('elements',panelElements)
        _.each(panelElements, (el, elIdx) => {
          if (
            el.attributes.hasOwnProperty('data-entry') &&
            el.attributes['data-entry'].value === this.props.entry.id
          ) {
            myPanel = el;
            // console.log( 'myElement', myPanel );
          }
        });
      }
      if (myPanel) {
        setTimeout(() => {
          $(myPanel)
            .find('.position-list .overflow-360')
            .attr('style', '');

          let maxHeight =
            this.props.maxHeight < 360 ? $('.control-registros').height() : this.props.maxHeight;
          // console.log('calculate table size...', $(myPanel).height() );
          let titlePanelHeight = $(myPanel)
            .find('.title-quiniela.main')
            .height();
          let titleButtonsHeight = $(myPanel)
            .find('.titulo-mis-picks')
            .height();
          let positionsList = $(myPanel).find('.lista-posiciones');
          let titlePositionsList = $(myPanel)
            .find('.position-list .title-quiniela')
            .height();
          let searchFieldHeight = $(myPanel)
            .find('#search-field-nick')
            .height();
          let headerPositionsHeight = $(myPanel)
            .find('.position-list .position-header')
            .height();
          // console.log( maxHeight, titlePanelHeight , titleButtonsHeight , titlePositionsList , searchFieldHeight , headerPositionsHeight);
          let positionListHeight = Math.ceil(
            maxHeight -
              30 -
              (titlePanelHeight +
                titleButtonsHeight +
                titlePositionsList +
                searchFieldHeight +
                headerPositionsHeight)
          );

          // console.log(positionListHeight)
          if (positionListHeight < 0 || positionListHeight === NaN) {
            return;
          }

          positionsList.height(positionListHeight - 25);
          $(myPanel)
            .find('.position-list .overflow-360')
            .animate({ height: positionListHeight }, '100');
        }, 300);
      }
    };

    /**
     * This function handles a call to the API
     * to get the new page of the positions
     */
    this.siguientePagPositions = (eventKey) => {
      console.log('Next Positions...');
      let loaderElement = $('#loading');
      let tempActiveSort = null;
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.state.standings.items;
      // console.log('siguiente');
      let val = eventKey;

      this.queryParams.page = this.queryParams.page + 1;

      let tempResp = API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams).then(
        (response) => {
          this.setState(
            (prevState) => {
              console.log(tempActiveSort);
              let newPosiciones = response.data;

              let newItems = response.data.items !== null ? response.data.items : null;

              newItems.forEach((item) => {
                temporalPositions.push(item);
              });

              // console.log('NEWPos',temporalPositions);
              newPosiciones.items = temporalPositions;
              return {
                standings: newPosiciones,
                posActualPage: this.queryParams + 1,
              };
            },
            () => {
              // if(this.props.sortPositionList !== undefined){
              //   setTimeout( () => {
              //     console.log(tempActiveSort);
              //     this.props.sortPositionList( tempActiveSort, true );
              //   }, 150);
              // }
              if (this.updateScrollPositions !== undefined) {
                this.updateScrollPostions(this.state.posiciones);
              }
              if (loaderElement !== undefined && loaderElement.length > 0) {
                loaderElement[0].style.display = 'none';
                let avatars = $('.score-avatars').height('auto');
              }
            }
          );
        }
      );
    };

    this.sortPositionList = (type) => {
      this.setState({
        submittingSearch: true,
      });

      let tempActiveSort = this.state.activeSort;
      let orderStatus;
      switch (type) {
        case 'posiciones':
          orderStatus = this.state.activeSort.posiciones ? -1 : 1;
          this.queryParams.sort = 'rank';
          tempActiveSort.posiciones = !tempActiveSort.posiciones;
          if (tempActiveSort.posiciones) {
            tempActiveSort.jugadores = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'jugadores':
          orderStatus = this.state.activeSort.jugadores ? -1 : 1;
          this.queryParams.sort = 'nick';
          tempActiveSort.jugadores = !tempActiveSort.jugadores;
          if (tempActiveSort.jugadores) {
            tempActiveSort.posiciones = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'aciertos':
          orderStatus = this.state.activeSort.aciertos ? -1 : 1;
          this.queryParams.sort = 'points';
          if (this.state.bracketInfo.status === 'closed') {
            tempActiveSort.aciertos = !tempActiveSort.aciertos;
            if (tempActiveSort.aciertos) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          } else {
            tempActiveSort.aciertos = !tempActiveSort.aciertos;
            if (tempActiveSort.aciertos) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          }
          break;
        case 'premios':
          orderStatus = this.state.activeSort.premios ? -1 : 1;
          this.queryParams.sort = 'prize';
          tempActiveSort.premios = !tempActiveSort.premios;
          break;
        default:
          return;
      }

      this.setState(
        (prevState) => {
          return {
            activeSort: tempActiveSort,
            sortChanged: !prevState.sortChanged,
            liveFixturesData: Date.now(),
            actualPage: 1,
          };
        },
        () => {
          //let temporalSortStatus = this.state.activeSort;
          this.queryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
          this.queryParams.page = 1;
          console.log(this.queryParams);
          API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams)
            .then((response) => {
              console.log('Response', response.data);
              this.callbackSort(response.data);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error in quiniela fetching standings',
              });
            });
          // this.queryParams.sort = [];
          console.log('After Sort', this.state.standings.items[0].entry_id, this.state.sortChanged);
        }
      );
    }

    this.callbackSort = (data) => {
      let newStandings = data;
      console.log('prevStandings', newStandings);

      let newUpdatedPositions = newStandings.items;
      if (this.state.bracketInfo.status === 'live') {
        newUpdatedPositions = this.updateStandingsAganar(
          newStandings.items,
          this.state.bracketInfo.prize_currency
        );
      }

      this.setState(
        {
          emptyList: data.items.length === 0 ? true : false,
          standings: data,
          submittingSearch: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };
  }

  changeStatusPool(poolId) {
    let tempAvailablePools = this.state.poolInfo;
    console.log('changeStatus Pool', tempAvailablePools);
    console.log('changeStatus Pool', poolId);
    tempAvailablePools.pool.status = 'live';
    tempAvailablePools.pool.upcoming = false;
    tempAvailablePools.pool.time_left = 0;
    let tempFixtures = this.state.fixtures;
    tempFixtures[0].enabled = false;
    // tempFixtures[0].sort_status = 0;
    let tempFixturesProps = [];
    this.state.fixtureProps.forEach((fixtProps) => {
      if (fixtProps.fixture_id === tempFixtures[0].id) {
        fixtProps.enabled = false;
        fixtProps.fixture.enabled = false;
        fixtProps.fixture.time_left = 0;
        // fixtProps.sort_status = 0;
        tempFixturesProps.push(fixtProps);
      } else {
        tempFixturesProps.push(fixtProps);
      }
    });
    this.setState(
      {
        changePoolStatus: true,
        poolInfo: tempAvailablePools,
        fixtures: tempFixtures,
        liveFixturesData: Date.now(),
      },
      () => {
        this.setState({
          changePoolStatus: false,
          liveFixturesData: Date.now(),
        });
      }
    );
  }
  /**
   * This function calls the appi to reload the Fixtures of the selected Week
   * @param weekID
   */
  changeWeek(weekID) {
    console.log('WEeks Updated', weekID);
    this.setState({
      loaded: false,
    });
    let temporalWeeklyStandings, temporalWeekFixtures;
    axios
      .all([
        API.fetchWeekFixtures(this.state.poolInfo.pool.id, weekID),
        API.fetchWeeklyStandings(this.state.poolInfo.pool.id, weekID),
      ])
      .then(
        axios.spread((temporalWeekFixtures, temporalWeeklyStandings) => {
          console.log('WEeks Updated', temporalWeeklyStandings, temporalWeekFixtures, weekID);
          this.setState({
            loaded: true,
            poolWeekSelected: weekID,
            fixtures: temporalWeekFixtures.data.fixtures,
            weeklyStandings: temporalWeeklyStandings.data,
          });
        })
      )
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching Axios all in Mi Bracket',
        });
      });
  }

  /**
   * This function change the redirection state
   */
  redirectLobby() {
    this.setState({
      redirectLobby: true,
    });
  }

  /**
   * This function gets the sport  icon of the current quiniela
   * @param sport_id
   * @returns img tag
   */
  setSport(sport_id) {
    return (
      <img
        className="title-ball-icon"
        src={
          sport_id === 1
            ? Futbol
            : sport_id === 2
            ? Americano
            : sport_id === 3
            ? Basketball
            : sport_id === 4
            ? Baseball
            : sport_id === 5
            ? Tenis
            : sport_id === 6
            ? Golf
            : sport_id === 7
            ? F1
            : sport_id === 8
            ? Box
            : sport_id === 9
            ? Hockey
            : null
        }
      />
    );
  }

  selectLayout(status) {
    let moneyFormat = this.state.bracketInfo.entry_currency === 'real' ? '$0,000' : '0,000';
    let conditionalComponent = null;
    let bracketUrl = `/bracket/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntryData.id}`;
    if( this.state.bracketInfo.sport_id === 5 ) {
      bracketUrl = `/bracketTennisLobby/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntryData.id}`;
    } else if ( this.state.bracketInfo.type === 'perfect' ) {
      bracketUrl = `/bracketPerfect/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntryData.id}`;
    } else if ( this.state.bracketInfo.type === 'points' && this.state.bracketInfo.sport_id !== 5 ) {
      bracketUrl = `/bracketPoints/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntryData.id}`;
    }

    conditionalComponent = (
      <div
        key={this.props.entry.id}
        ref="picksQuinielas"
        id={`picksContainer-${this.props.entry.id}`}
        className={`flex-col dashboard-item ${status}`}
      >
        <div
          className="panelMisQuinielas"
          data-entry={this.props.entry.id}
          ref={this.myBracketRef}
          style={
            this.props.maxHeight !== 0
              ? { height: this.props.maxHeight + 5, marginBottom: '15px' }
              : {}
          }
        >
          <div className=" flex-row title-quiniela main">
            <div className="col-xs-10 title-container-mis-quinielas">
              {this.setSport(this.state.bracketInfo.sport_id)}
              <div
                className={`${this.state.bracketInfo.tournament_instances[0].tournament.flag_class} miquiniela`}
              />
              <span>
                {' '}
                {this.state.bracketInfo.name_replaced}{' '}
                {numeral(this.state.bracketInfo.entry_fee / 100).format(moneyFormat)}{' '}
                {this.state.bracketInfo.entry_currency === 'real'
                  ? 'MXN'
                  : this.state.bracketInfo.entry_currency === 'pickcoin'
                  ? 'PC'
                  : 'Rf'}
              </span>
              <span style={{ fontSize: 'small', margin: '0 3px' }}>
                {this.props.entry.number === null ? null : `[${this.props.entry.number}]`}
              </span>
            </div>
            <div className=" col-xs-2 btn-close">
              <Button
                className="closeQuinaBtn"
                onClick={() => {
                  // this.deleteData();
                  this.props.closeMiQuina(this.props.index);
                  this.props.closedPanel(this.state.bracketInfo.id);
                }}
              >
                {/*<span><i className="glyphicon glyphicon-remove"></i></span>*/}
                <div className="btn-icon-close">
                  <img src={closeW} className={'closeIcon'} />
                </div>
              </Button>
            </div>
          </div>
          <div className="flex-row titulo-mis-picks panel-quiniealas">
            <div className="flex-col ">
              <div className="flex-row">
                <div className=" flex-col col-no-padding boton-titulo-dash">
                  <Link
                    className="flex-row row-no-padding"
                    to={bracketUrl}
                  >
                    <Button className="ir-quiniela flex-container">Ir al bracket</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col col-no-padding">
            <TablaPosiciones
              isVisible={true}
              resetNickValue={this.resetNickValue}
              parentType="misquinielas"
              actualPage={this.state.posActualPage}
              changeSearchNick={this.changeSearchNick}
              entryId={this.state.selectedEntry}
              entry={this.state.selectedEntryData}
              fetchSearchableStandings={this.fetchSearchableStandings}
              loaded={this.state.loaded}
              nextPageStandings={this.nextPageStandings}
              poolInfo={this.state.bracketInfo}
              activeStage={this.state.activeStage}
              posiciones={this.state.standings}
              siguientePagPositions={this.siguientePagPositions}
              selectedEntry={this.state.selectedEntry}
              standings={this.state.standings}
              submittingSearch={this.state.submittingSearch}
              type="bracket"
              userData={this.state.userData}
              sortPositionList={this.sortPositionList}
              activeSort={this.state.activeSort}
              getAganar={this.getAganarBracket}
              tiesDistribution={this.state.bracketTiesDistribution}
              calculateTiesPrizes={this.calculateTiesPrizes}
              premios = {this.state.premios}
              myRegisters = {true}
              bracketHeight = {this.props.miQuinielaRef}
            />
          </div>
        </div>
      </div>
    );
    return conditionalComponent;
  }

  updateStandings(newPositions) {
    console.log('Updating Positions');
    console.log(newPositions);
    // console.log(newPositions[0].total_possibly_correct);
    console.log('prize_currency', this.state.poolInfo.pool.prize_currency);
    if (newPositions[0].pool_id !== this.state.poolInfo.pool.id) {
      return;
    }
    let tempStandings = this.state.standings;
    let tempSelectedEntryData = this.state.selectedEntryData;

    newPositions = this.updateStandingsPosition(newPositions);
    newPositions = this.updateStandingsAganar(
      newPositions,
      this.state.poolInfo.pool.prize_currency
    );
    tempStandings['items'] = newPositions;
    console.log('newPositions', newPositions);
    newPositions.forEach((position) => {
      if (position.entry_id === this.state.selectedEntryData.id) {
        console.log('THIS');
        console.log(position);
        tempSelectedEntryData['standing'].rank = position.rank;
        tempSelectedEntryData['standing'].points = position.total_possibly_correct;
        tempSelectedEntryData['standing'].total_possibly_correct = position.total_possibly_correct;
        tempSelectedEntryData['standing'].user_prize = position.user_prize;
        tempSelectedEntryData['user_prize'] = position.user_prize;
        console.log(tempSelectedEntryData);
      }
    });
    // console.log('Update Standings');
    if (this.isMounted) {
      this.setState({
        selectedEntryData: tempSelectedEntryData,
        standings: tempStandings,
      });
    }
  }

  updateData() {
    console.log('Update data');
    const entryID = this.props.entry.id;
    const poolID = this.props.entry.pool_id;
    const type = this.props.entry.type;
    const format = this.props.entry.format;
    if (this.state.poolInfo.pool.closed) {
      return;
    }

    if (this.props.updatedId === this.props.entry.id) {
      // console.log('This Component was updated', this.props.updatedId, this.props.entry.id);
      axios
        .all([
          API.getEntriesInfo(entryID),
          // API.fetchFixtures(poolID),
          // API.fetchIndividualPool(poolID),
          // API.fetchPoolPrizes(poolID),
          // API.fetchStandings(poolID),
          // API.fetchStandingsGroups(poolID),
          API.fetchSimilarEntries(entryID),
          // API.fetchWeek(poolID),
          // API.fetchWeekFixtures(poolID,  type === 'full' ? this.props.entry.week_current_id : this.props.entry.week_id),
          // type === 'full'
          //   ? API.fetchWeeklyStandings(poolID,  type === 'full' ? this.props.entry.week_current_id : this.props.entry.week_id)
          //   : null
        ])
        .then(
          axios.spread((dataEntriesInfo, dataSimilarEntries) => {
            // console.log(dataSimilarEntries, this.state.poolInfo.pool.id);
            let responses = [dataEntriesInfo, dataSimilarEntries];
            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
            }
            this.setState(
              {
                entriesInfo: dataEntriesInfo.data,
                selectedEntryData: dataEntriesInfo.data.entry,
                similarPools: dataSimilarEntries.data,
              },
              () => {
                this.props.afterUpdatePanels();
                this.setLoaded();
              }
            );
          })
        );
    } else {
      console.log('This Component wasnt updated', this.props.updatedId, this.props.entry.id);
      API.fetchSimilarEntries(entryID).then((response) => {
        // console.log(dataSimilarEntries, this.state.poolInfo.pool.id);
        this.setState(
          {
            similarPools: response.data,
          },
          () => {
            this.props.afterUpdatePanels();
            this.setLoaded();
            // console.log('data Updated !');
          }
        );
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.refs.picksQuinielas && this.state.loaded !== prevState.loaded) {
      this.handleTableHeight();
    }
    if (this.props.maxHeight !== prevProps.maxHeight) {
      this.handleTableHeight();
    }
    // if (this.refs.picksQuinielas && this.state.loaded !== prevState.loaded
    //     && this.state.poolWeekSelected !== prevState.poolWeekSelected) {
    //     // console.log('Change WEek and ready to MAxHeight');
    //     this.props.resetHeight();
    //     this.props.handleMaxHeight();
    // }
    if (
      this.refs.picksQuinielas &&
      this.state.loaded !== prevState.loaded &&
      this.props.update !== prevProps.update
    ) {
      console.log('Update after mod other panel');
      // this.props.handleMaxHeight();
      this.handleTableHeight();
      //
    }
    if (
      this.refs.picksQuinielas &&
      prevState.weeklyStandingsActive !== this.state.weeklyStandings
    ) {
      this.handleTableHeight();
    }
  }

  /**
   * Get all the  data of the selected entry
   * -- Entry Info
   * -- Fixtures Info
   * -- Pool Info
   * -- Pool Prizes
   * -- Standings
   * -- Group Standings
   * -- similar Pools
   * -- Week Info
   * -- Week Fixtures
   *
   */
  componentDidMount() {
    /****************************
     ******* FixtureClosed Received
     *****************************
     * This subscription handles the state when a fixture was closed from the live pools
     * @param fixtureID
     */

    const bracketEntryId = this.props.entry.id;
    const bracketId = this.props.entry.bracket_id;

    // Connect WS
    let jwToken = utils.getToken();
    const socketUrl = process.env.REACT_APP_PW_SOCKET_LIVE_PANE;
    this.ws = io.connect(socketUrl, {
      query: { token: jwToken },
      secure: true,
      transports: ['websocket'],
    });

    this.ws.on('connect', () => {
      this.ws.emit('subscribe', Number(bracketId), 'bracket');
    });

    this.ws.on('bracket_standings:update', (data) => {
      let allStandings = this.state.standings;
      allStandings.items = data.standings;

      this.setState(() => {
        return {standings: allStandings, bracketTiesDistribution: data.ties_distribution};
      });
    });

    axios
      .all([
        API.fetchBracketsDetails(bracketId),
        API.fetchBracketEntry(bracketEntryId),
        API.fetchUserData(),
        API.fetchBracketStandings(bracketId, this.queryParams),
      ])
      .then(
        axios.spread(
          (bracketInfoResponse, bracketEntryResponse, userData_, bracketStandingsResponse) => {
            let responses = [
              bracketInfoResponse,
              bracketEntryResponse,
              userData_,
              bracketStandingsResponse,
            ];
            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
            }

            const bracketInfoData = bracketInfoResponse.data;
            const bracketEntryData = bracketEntryResponse.data;
            const updatedUserData = userData_.data;
            const bracketStandingsData = bracketStandingsResponse.data;
            const bracketTiesDistribution = bracketInfoResponse.data.ties_distribution

            this.setState({
              bracketInfo: bracketInfoData.bracket,
              isLogged: true,
              loaded: true,
              mounted: true,
              selectedEntry: bracketEntryId,
              selectedEntryData: bracketEntryData.bracket_entry,
              standings: bracketStandingsData,
              userData: updatedUserData,
              withRegister: true,
              premios: bracketInfoData.bracket_prizes,
              bracketTiesDistribution: bracketTiesDistribution,
            });
          }
        )
      )
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error Fetching All in MiBracket Panel',
        });
        this.props.closeMiQuina(this.props.index);
        this.props.closedPanel(this.state.bracketInfo.id);
      });
  }

  componentWillUnmount() {
    if (this.fixturesSubscription) {
      // console.log('Closed Subscritpio in fixtures');
      this.fixturesSubscription.unsubscribe();
    }
    if (this.propsSubscriptionSubscription) {
      // console.log('Closed Subscritpio in Props');
      this.propsSubscriptionSubscription.unsubscribe();
    }
    if (this.updatedStandingsSubscription) {
      // console.log('Closed Subscritpio in Props');
      this.updatedStandingsSubscription.unsubscribe();
    }
    if (this.poolCloseUpdateSubscription) {
      // console.log('Closed Subscritpio in poolClose');
      this.poolCloseUpdateSubscription.unsubscribe();
    }
    if (this.poolUpdateSubscription) {
      // console.log('Closed Subscritpio in poolUpdate');
      this.poolUpdateSubscription.unsubscribe();
    }
    if (this.fixtureCloseSubscription) {
      // console.log('Closed Subscritpio in fixtureClose');
      this.fixtureCloseSubscription.unsubscribe();
    }

    this.ws.disconnect();
  }

  render() {
    let selectedWeek;
    let itPassed;
    let isFull;
    let isMixed;
    let now = moment();
    let endWeek;
    let poolStatus;
    let conditionalComponent = null;
    if (this.state.loaded) {
      // console.log(this);
      // isMixed = this.state.poolInfo.pool.format === 'mixed' ? true : false;
      // endWeek = moment(this.state.poolInfo.pool.registration_deadline);
      poolStatus = this.state.bracketInfo.status;
      // console.log(poolStatus, itPassed, selectedWeek, isFull);
      if (this.state.redirectLobby) {
        conditionalComponent = (
          <Redirect
            push
            to={{
              pathname: `/bracket/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntryData.id}`,
            }}
          />
        );
      } else {
        console.log('poolStatus', poolStatus);
        conditionalComponent = this.selectLayout(poolStatus);
      }
    } else {
      conditionalComponent = (
        <div
          id={`picksContainer-`}
          className={`col-xs-12 col-md-6 col-lg-4 dashboard-item upcoming`}
        >
          <div className="panel-pickwin mis">
            <Loading />
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}
export default MiBracketPanel;
