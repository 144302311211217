import { keyframes } from 'styled-components'

export const rippleWave = keyframes`
  0% {
    box-shadow: 0px 0px 0px 0px #b1ff1300;
  }

  40% {
    box-shadow: 0px 0px 0px 5px #b1ff1399;
  }

  80% {
    box-shadow: 0px 0px 0px 10px #b1ff1300;
  }

  100% {
    box-shadow: 0px 0px 0px 10px #b1ff1300;
  }
`
