import React from 'react';

const Loading = (props) => (
  <div id="loading">
    <span className="circuloExterno">
      <span className="circuloInterno"></span>
    </span>
    {/*<span>*/}
    {/*<img className='loading-animation' src={pickLoader}/>*/}
    {/*</span>*/}
    <span className="label-load">
      <span>{props.label}</span>
    </span>
  </div>
);

export default Loading;
