import React from 'react';

const UserDataContext = React.createContext();

export class UserDataContextProvider extends React.Component {
  render() {
    return (
      <UserDataContext.Provider value={this.props}>{this.props.children}</UserDataContext.Provider>
    );
  }
}

export const UserDataContextConsumer = UserDataContext.Consumer;
