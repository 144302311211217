import React from 'react'
import styled from 'styled-components'
import _ from 'underscore'

const ListBucketsShow = ({
  poolBuckets,
  myEntry,
  currentContest,
  poolInfo
}) => {
  if (!poolBuckets) return null

  poolBuckets = poolBuckets[currentContest.id].sort()

  const worst = currentContest.par + 10
  const worstByRound = worst - currentContest.par
  const worstScoreNum = (worst * 4) - (currentContest.par * 4)

  const listShowBase = poolBuckets.map((bucket) => {
    const defaultValue = {
      notPicked: true,
      name: 'N/D',
      score_num: worstScoreNum,
      score: worstScoreNum,
      data: {
        hole: '',
        r1: worstByRound,
        r2: worstByRound,
        r3: worstByRound,
        r4: worstByRound
      }
    }

    const item = {
      ...bucket.players.find((o) => o.id === myEntry.group_pool_picks[currentContest.id][bucket.id].pick)
    }

    // Check if user has selected a player
    const selectedPlayer = myEntry.hasOwnProperty('group_pool_picks') && myEntry.group_pool_picks.hasOwnProperty(currentContest.id) && item.id
      ? item
      : defaultValue

    selectedPlayer.bucket = bucket

    return selectedPlayer
  })

  const listShowSorted = listShowBase.sort((a, b) => a.score_num - b.score_num)

  const pickOff = poolInfo.pick_cutoff || 0

  // Mapped and check if is cuttoff
  const listShowSortedCutted = listShowSorted.map((item, index) => ({
    ...item,
    isCutOff: (pickOff && pickOff > 0 && index >= poolBuckets.length - pickOff)
  }))

  const listShow = listShowSortedCutted.map(selectedPlayer => {
    const { bucket } = selectedPlayer

    const isMarkWorstPick = (poolInfo.sport_id === 6 && selectedPlayer.isCutOff)

    let colorStyle = {}

    if (selectedPlayer.score > 0) {
      colorStyle = { color: 'rgb(255, 199, 48)' }
    } else if (selectedPlayer.score < 0) {
      colorStyle = { color: '#78bc28' }
    }

    return (
      <div
        key={bucket.id}
        data-rank={selectedPlayer ? selectedPlayer.score : null}
        className='bucket-item'
      >
        <div className='btn-group-pick-collapse flex-row row-no-padding row-center '>
          {/* <div className="flex-col col-5">{bucket.id} </div> */}

          <div className='flex-col  text-center'>
            {selectedPlayer.position}{' '}
            {!selectedPlayer.position && selectedPlayer.position !== 0 ? '-' : '°'}
          </div>
          <div className='uppercase flex-col col-40 d-flex' style={{ display: 'flex', position: 'relative', color: selectedPlayer.name === 'N/D' ? 'red' : 'inherit' }}>
            {isMarkWorstPick && <LineThrough />}
            <div className='flex-col'>
              {selectedPlayer.name !== 'N/D' && (
                <img
                  alt={selectedPlayer.name}
                  src={selectedPlayer.avatar}
                  className='player-avatar'
                  style={{ width: '24px', height: '24px' }}
                />
              )}
            </div>
            <div className='flex-row row-center row-no-padding' style={{ overflow: 'hidden' }}>
              <i className={`${selectedPlayer.logo}`} style={{ marginRight: '3px' }} />
              <div className='overflow-words'>{selectedPlayer.name}</div>
            </div>
          </div>
          <div className='flex-col text-center' style={colorStyle}>
            {selectedPlayer.score === '0' ? 'E' : selectedPlayer.score_num}
          </div>

          <div className='flex-col  text-center'>
            {selectedPlayer.data && selectedPlayer.data.hole !== ''
              ? selectedPlayer.data.hole
              : '-'}
          </div>
          <div className='flex-col  text-center'>
            {selectedPlayer.data && selectedPlayer.data.today ? selectedPlayer.data.today : '-'}
          </div>
          <div className='flex-col  text-center'>
            {(currentContest.current_round >= 1 && selectedPlayer.data.r1) ? selectedPlayer.data.r1 : '-'}
          </div>
          <div className='flex-col  text-center'>
            {(currentContest.current_round >= 2 && selectedPlayer.data.r2) ? selectedPlayer.data.r2 : '-'}
          </div>
          <div className='flex-col  text-center'>
            {(currentContest.current_round >= 3 && selectedPlayer.data.r3) ? selectedPlayer.data.r3 : '-'}
          </div>
          <div className='flex-col  text-center'>
            {(currentContest.current_round >= 4 && selectedPlayer.data.r4) ? selectedPlayer.data.r4 : '-'}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className='flex-col col-no-padding'>
      <div className='flex-row title-quiniela sub'>
        <div className='flex-col title-wrapper title-wrapper--golf'>
          <span>Picks - {currentContest.name}</span>
          <span>PAR: {currentContest.par}</span>
          <span>T: {myEntry.group_pool_standing.points}</span>
        </div>
      </div>
      <div
        className='header-picks-partidos flex-row row-no-padding results'
        style={{ padding: '4px' }}
      >
        {/* <div className="flex-col col-5">#</div> */}
        <div className='flex-col '>Pos</div>
        <div className='flex-col'>&nbsp;</div>
        <div className='flex-col col-30'>Jugador</div>
        <div className='flex-col '>Sc</div>
        <div className='flex-col '>H</div>
        <div className='flex-col '>Hoy</div>
        <div className='flex-col '>R1</div>
        <div className='flex-col '>R2</div>
        <div className='flex-col '>R3</div>
        <div className='flex-col '>R4</div>
      </div>
      {listShow}
    </div>
  )
}

/**
 * This component Renders the title and the list of picks
 */
const PresentationPicks = ({ disabled, poolInfo, poolBuckets, dispatch, myEntry, currentContest }) => {
  return (
    <ListBucketsShow
      disabled={disabled}
      poolInfo={poolInfo}
      poolBuckets={poolBuckets}
      myEntry={myEntry}
      dispatch={dispatch}
      currentContest={currentContest}
    />
  )
}

const LineThrough = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: 1px solid red;
`

export default PresentationPicks
