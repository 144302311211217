import React from 'react';
import API from '../../utils/api';
import utils from '../../utils/utils';
import Utils from '../../utils/quinielasUtils';
import Cookies from 'js-cookie';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

import NavigationBar from '../NavigationBar';
import { Route, Redirect } from 'react-router-dom';
import RecuperarPanel from '../RecuperarPanel';
import pickwinLogo from '../../assets/images/pickwinColores-50.png';

import { ControlLabel, FormGroup, FormControl, Button, HelpBlock } from 'react-bootstrap';

class ListLeagues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sports: null,
      selectedSport: null,
      listLeagues: null,
    };
    console.log('ListLeaguesProps', this.props);
  }

  render() {
    let listItems;
    let listaLigas = [];
    if (this.props.sports !== null) {
      // console.log('ListLeagues Updated', this.props.sports);
      this.props.sports.sports.forEach((sport) => {
        listItems = sport.tournaments.map((torneo) => {
          let obj = {};
          obj = {
            name: torneo.name,
            id: torneo.id,
          };
          // console.log(obj)
          listaLigas.push(obj);
          return obj;
        });
      });
      // console.log(listaLigas);
      listaLigas = listaLigas.map((element, i) => (
        <option value={`${element.id}`} id={element.id} key={i}>
          {element.name}
        </option>
      ));
      // console.log(listaLigas)
    } else {
      listaLigas = [];
    }
    return (
      <FormGroup id="formLiga" className="col-xs-12 col-sm-10  ">
        <ControlLabel>Liga Favorita</ControlLabel>
        <FormControl
          componentClass="select"
          placeholder="Selecciona tu Liga Favorita"
          onChange={this.props.getLiga}
        >
          <option value="null">Selecciona tu Liga</option>
          {listaLigas}
        </FormControl>
        <FormControl.Feedback />
      </FormGroup>
    );
  }
}

function EquipoFavorito(props) {
  // console.log('Choose one',props.equipos);
  let listaEquipos = [];
  let listaItems;
  let conditionalComponent;

  if (props.equipos !== null) {
    Object.keys(props.equipos).forEach((team) => {
      let obj = {
        name: props.equipos[team],
        id: team,
      };
      listaEquipos.push(obj);
      // console.log(obj);
      // return obj;
    });
    listaEquipos = listaEquipos.map((equipo, i) => {
      // console.log(equipo.id);
      return (
        <option value={`${equipo.id}`} id={equipo.id} key={i}>
          {equipo.name}
        </option>
      );
    });
  }
  // console.log(listaEquipos);
  conditionalComponent = (
    <FormGroup id="formEquipo" className=" col-xs-12 col-sm-10  ">
      <ControlLabel>Equipo Favorito</ControlLabel>
      <FormControl
        componentClass="select"
        placeholder="Selecciona tu Equipo favorito"
        onChange={props.getLiga}
      >
        <option value={`null`}>Selecciona a tu equipo Favorito</option>
        {listaEquipos}
      </FormControl>
      <FormControl.Feedback />
      {/* <HelpBlock>Si tienes Código amigo, ingresalo en este campo</HelpBlock> */}
    </FormGroup>
  );
  return conditionalComponent;
}

class RegistroFB extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      correctMail: null,
      correctName: null,
      correctNameLast: null,
      correctNick: null,
      correctPswd: null,
      fbRegistration: null,
      formMail: this.props.fbData.email,
      formName: this.props.fbData.first_name,
      formNameLast: this.props.fbData.last_name,
      formNews: false,
      formNick:
        !this.props.fbData || !this.props.fbData.name
          ? ''
          : this.props.fbData.name.replace(' ', '').substr(0, 8) +
            (Math.floor(Math.random() * (9999 - 1000)) + 1000),
      formOldenough: false,
      formPswd: null,
      formPswdConfirm: null,
      formPicture: this.props.fbData.picture.data.url,
      formReferral: utils.getReferer(),
      formTos: false,
      loggedIn: false,
      mailError: false,
      mailErrorLabel: null,
      mounted: false,
      nameLastNumber: null,
      nameLastQnty: null,
      nameNumber: null,
      nameQnty: null,
      nickError: false,
      passError: false,
      registerSuccess: null,
      submitting: false,
      userData: null,
      userFullData: null,
      userId: null,
      validForm: false,
      validMail: false,
      validName: false,
      validNick: false,
      validPassword: false,
      validPasswordConf: false,
      validReferal: false,
    };

    this.initFingerprint = utils.initFingerprint.bind(this);

    // console.log(this.props.fbData);
    this.disableClickedButton = Utils.disableClickedButton.bind(this);
    this.enviarRegistroFB = this.enviarRegistroFB.bind(this);
    this.enviarRegistro = this.enviarRegistro.bind(this);
    this.getEquipo = this.getEquipo.bind(this);
    this.getLiga = this.getLiga.bind(this);
    this.getMail = this.getMail.bind(this);
    this.getName = this.getName.bind(this);
    this.getNameLast = this.getNameLast.bind(this);
    this.getNews = this.getNews.bind(this);
    this.getNick = this.getNick.bind(this);
    this.getOld = this.getOld.bind(this);
    this.getPswd = this.getPswd.bind(this);
    this.getPswdConf = this.getPswdConf.bind(this);
    this.getPswdConf = this.getPswdConf.bind(this);
    this.getReferall = this.getReferall.bind(this);
    this.getTos = this.getTos.bind(this);
    this.getTos = this.getTos.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.updateEquipos = this.updateEquipos.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.selectMailing = this.selectMailing.bind(this);

    this.callbackRegister = (response) => {
      console.log(response.data);
      if (response.data.success) {
        //Registro exitoso
        this.callbackLogin(response);
      } else {
        //Detectar errores
        const errors = response.data.errors;
        const errorsKeys = Object.keys(errors);
        console.log(errors, errorsKeys);
        errorsKeys.forEach((err) => {
          console.error(err, errors[err]);
          switch (err) {
            case 'nick':
              const errorTypeNick = Object.keys(errors[err]);
              console.log(errors[err][errorTypeNick[0]]);
              this.setState({
                registroSuccess: false,
                nickError: true,
                correctNick: false,
                nickErrorType: errorTypeNick[0],
                nickErrorLabel: errors[err][errorTypeNick[0]],
              });
              break;
            case 'email':
              const errorTypeMail = Object.keys(errors[err]);
              console.log(errors[err][errorTypeMail[0]]);
              this.setState({
                registroSuccess: false,
                correctMail: false,
                mailError: true,
                mailErrorType: errorTypeMail[0],
                mailErrorLabel: errors[err][errorTypeMail[0]],
              });
              break;
          }
        });
      }
    };

    this.callbackLogin = (response) => {
      console.log('Callback Login ', response.data, this.props);
      if (response.data.success) {
        utils.setUserInfo(response.data.user);

        if (this.props.type === 'popup') {
          console.log('Registration in Popup');
          this.setState(
            {
              // redirectToLobby: true,
              // registerSuccess: true,
              submitting: false,
            },
            () => {
              if (this.props.updateUserInfo) {
                this.props.updateUserInfo(response.data);
              }
              utils.clearUserMetadata();
              this.props.hideRegister();
            }
          );
        } else {
          this.setState({
            redirectToLobby: true,
            registerSuccess: true,
            submitting: false,
          });
        }
      } else {
        console.error('Error al hacer login despues del registro', response);
      }
    };
  }

  selectMailing() {
    $('input#newsletter').prop('checked', true);
  }

  updateEquipos() {
    console.log('Actualizar Equipos');
    API.fetchTeams(this.state.formLiga).then((response) => {
      // console.log(response.data.teams);
      this.setState({
        equiposDisponibles: response.data.teams,
      });
    });
  }

  enviarRegistro() {
    console.log('Send Register');
    console.log(this.state.fingerprint);
    this.setState({ submitting: true });
    let userMetadata = utils.getUserMetadata()
    userMetadata = _.map(userMetadata, function (val, key) {
      return { name: key, value: val }
    })
    // push currentUrl
    userMetadata.push({ name: 'currentUrl', value: window.location.href })

    // this.getFingerprint();
    let form = {
      fingerprint: this.state.fingerprint,
      referalCode: this.state.formReferral,
      name: this.state.formName,
      last_name: this.state.formNameLast,
      nick: this.state.formNick,
      email: this.state.formMail,
      password: this.state.formPswd,
      cpassword: this.state.formPswdConfirm,
      tos: this.state.formTos,
      oldenough: this.state.formOldenough,
      newsletter: this.state.formNews,
      provider_uid: this.props.fbData.userID,
      profile_picture: this.props.fbData.picture.data.url,
      users_metadata: userMetadata,
      source: 'web',
    };

    console.log(form);
    let url = '/api/users/register.json';
    if (this.state.registroSuccess === false) {
      this.setState({
        nickError: false,
        nickErrorType: null,
        nickErrorLabel: null,
        mailError: false,
        mailErrorType: null,
        mailErrorLabel: null,
      });
    }

    API.postFacebookRegister(form)
      .then((response) => {
        this.callbackRegister(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getName(e) {
    let val = e.target.value;
    // console.log(val);
    this.setState(
      {
        formName: val,
      },
      () => {
        this.validateForm();
      }
    );
  }

  getEquipo(e) {
    let val = e.target.value;
    // console.log(val);
    this.setState(
      {
        formEquipo: val,
      },
      () => {
        this.validateForm();
      }
    );
  }

  getNameLast(e) {
    let val = e.target.value;
    // console.log(val);
    this.setState(
      {
        formNameLast: val,
      },
      () => {
        this.validateForm();
      }
    );
  }

  getMail(e) {
    let val = e.target.value;
    // console.log(val);
    this.setState(
      {
        formMail: val,
      },
      () => {
        this.validateForm();
      }
    );
  }

  getNick(e) {
    let val = e.target.value;
    // console.log(val);
    this.setState(
      {
        formNick: val,
      },
      () => {
        this.validateForm();
      }
    );
  }

  getPswd(e) {
    let val = e.target.value;
    // console.log(val);
    // console.log(this)
    this.setState(
      () => {
        return {
          formPswd: val,
        };
      },
      () => {
        this.validateForm();
      }
    );
  }

  getPswdConf(e) {
    let val = e.target.value;
    console.log(val);
    // console.log(this)
    this.setState(
      () => {
        return {
          formPswdConfirm: val,
        };
      },
      () => {
        this.validateForm();
      }
    );
  }

  getTos(e) {
    let val = e.target.value;
    // console.log(val);
    // console.log(this)
    this.setState(
      (prevState) => {
        if (prevState.formTos === '1') {
          return {
            formTos: false,
          };
        } else if (prevState.formTos === false) {
          return {
            formTos: '1',
          };
        }
      },
      () => {
        this.validateForm();
      }
    );
  }

  getOld(e) {
    let val = e.target.value;
    // console.log(val);
    // console.log(this)
    this.setState(
      (prevState) => {
        if (prevState.formOldenough === '1') {
          return {
            formOldenough: false,
          };
        } else if (prevState.formOldenough === false) {
          return {
            formOldenough: '1',
          };
        }
      },
      () => {
        this.validateForm();
      }
    );
  }

  getNews(e) {
    // console.log(val);
    let val = e.target.value;
    // console.log(this)
    this.setState(
      () => {
        return {
          formNews: val,
        };
      },
      () => {
        this.validateForm();
      }
    );
  }

  getLiga(e) {
    let val = e.target.value;
    console.log(val);
    console.log(this);
    this.setState(
      () => {
        return {
          formLiga: val,
        };
      },
      () => {
        this.updateEquipos();
        this.validateForm();
      }
    );
  }

  getReferall(e) {
    let val = e.target.value;
    // console.log(val);
    this.setState(
      {
        formReferral: val,
      },
      () => {
        this.validateForm();
      }
    );
  }

  getValidationState(which) {
    const regexAlphabetic = /^[A-z ]+$/;
    const regexMail = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    switch (which) {
      case 'name':
        // console.log(wich, this.state )
        if (this.state.formName === null) {
          return 'warning';
        } else if (this.state.formName.length >= 4) {
          this.setState({ nameQnty: false });
          if (regexAlphabetic.test(this.state.formName)) {
            this.setState({ correctName: true, nameNumber: false });
            return 'success';
          } else {
            this.setState({ correctName: false, nameNumber: true });
            return 'error';
          }
        } else {
          this.setState({ correctName: false, nameQnty: true });
          return 'error';
        }
        break;
      case 'namelast':
        // console.log(wich, this.state )
        if (this.state.formNameLast === null) {
          return 'warning';
        } else if (this.state.formNameLast.length >= 4) {
          this.setState({ nameLastQnty: false });
          if (regexAlphabetic.test(this.state.formNameLast)) {
            this.setState({ correctNameLast: true, nameLastNumber: false });
            return 'success';
          } else {
            this.setState({ correctNameLast: false, nameLastNumber: true });
            return 'error';
          }
        } else {
          this.setState({ correctNameLast: false, nameLastQnty: true });
          return 'error';
        }
        break;
      case 'mail':
        // console.log(wich, this.state )
        // console.log('Validating Mail');
        if (this.state.formMail === null) {
          this.setState({
            correctMail: null,
          });
          return 'warning';
        } else if (this.state.formMail.length > 10) {
          if (regexMail.test(this.state.formMail)) {
            this.setState({ correctMail: true });
            return 'success';
          } else {
            this.setState({ correctMail: false });
            return 'error';
          }
        } else {
          this.setState({ correctMail: false });
          return 'error';
        }
        break;
      case 'nick':
        // console.log(wich, this.state )
        if (this.state.formNick === null) {
          return 'warning';
        } else if (this.state.formNick.length >= 4 && this.state.formNick.length <= 12) {
          this.setState({ correctNick: true });
          return 'success';
        } else {
          this.setState({ correctNick: false, errorNick: true });
          return 'error';
        }
        break;
      case 'pass':
        // console.log(wich, this.state )
        if (this.state.formPswd === null) {
          return 'warning';
        } else if (this.state.formPswd.length >= 6) {
          return 'success';
        } else {
          return 'error';
        }
        break;
      case 'passConf':
        // console.log(wich, this.state )
        if (this.state.formPswdConfirm === null) {
          return 'warning';
        } else if (this.state.formPswdConfirm === this.state.formPswd) {
          this.setState(
            {
              correctPswd: true,
            },
            () => {
              return 'success';
            }
          );
          return 'success';
        } else {
          return 'error';
        }
        break;
    }
  }

  validateForm() {
    // console.log('Validation');
    // this.state.formPswd === this.state.formPswdConf ,
    // this.state.formName.length > 4  ,
    // this.state.formMail.length > 10 ,
    // this.state.formNick.length > 4 ,
    // this.state.formPswd.length >old 8)
    if (
      this.state.correctName &&
      this.state.correctNameLast &&
      this.state.correctPswd &&
      this.state.correctMail &&
      this.state.correctNick &&
      this.state.formTos === '1' &&
      this.state.formOldenough === '1'
    ) {
      // console.log('ready' )
      this.setState({
        validForm: true,
      });
    } else {
      // console.log('NOT ready' )
      // console.log(this.state)
      this.setState({
        validForm: false,
      });
    }
  }

  updateUserData(userD) {
    // console.log('UPDATEDx' ,userD.data.success);
    this.setState({
      loggedIn: userD.data.success,
      userFullData: userD.data,
      userId: userD.data.user.id,
    });
    console.log(this.state.userFullData);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.equiposDisponibles !== this.state.equiposDisponibles) {
      return true;
    } else if (nextState.submitting !== this.state.submitting) {
      return true;
    } else if (nextState.fbData !== this.state.fbData) {
      return true;
    } else if (nextState.registerSuccess !== this.state.registerSuccess) {
      return true;
    } else if (nextState.fbFailed !== this.state.fbFailed) {
      return true;
    } else if (nextState.fbRegistration !== this.state.fbRegistration) {
      return true;
    } else if (nextState.equiposDisponibles !== this.state.equiposDisponibles) {
      console.log('Should go?');
      return true;
    } else if (nextState.formLiga !== this.state.formLiga) {
      return true;
    } else if (nextState.formMail !== this.state.formMail) {
      return true;
    } else if (nextState.formName !== this.state.formName) {
      return true;
    } else if (nextState.formNameLast !== this.state.formNameLast) {
      return true;
    } else if (nextState.formNews !== this.state.formNews) {
      return true;
    } else if (nextState.formNick !== this.state.formNick) {
      return true;
    } else if (nextState.formOldenough !== this.state.formOldenough) {
      return true;
    } else if (nextState.formPswd !== this.state.formPswd) {
      return true;
    } else if (nextState.formPswdConfirm !== this.state.formPswdConfirm) {
      return true;
    } else if (nextState.formTos !== this.state.formTos) {
      return true;
    } else if (nextState.mailError !== this.state.mailError) {
      return true;
    } else if (nextState.mounted !== this.state.mounted) {
      return true;
    } else if (nextState.nickError !== this.state.nickError) {
      return true;
    } else if (nextState.passError !== this.state.passError) {
      return true;
    } else if (nextState.sports !== this.state.sports) {
      return true;
    } else if (nextState.validForm !== this.state.validForm) {
      return true;
    } else if (nextState.userId !== this.state.userId) {
      return true;
    } else {
      return false;
    }
    return false;
  }

  enviarRegistroFB() {
    console.log('RegistrarseFB...');
    // console.log(this.state)
    let form = {
      fingerprint: this.state.fingerprint,
      referalCode: this.state.formReferral,
      name: this.state.formName,
      last_name: this.state.formNameLast,
      nick: this.state.formNick,
      email: this.state.formMail,
      password: this.state.formPswd,
      cpassword: this.state.formPswdConfirm,
      tos: this.state.formTos,
      oldenough: this.state.formOldenough,
      newsletter: this.state.formNews,
      provider_uid: this.props.fbData.userID,
      profile_picture: this.props.fbData.picture.data.url,
      source: 'web',
    };
    API.postFacebookRegister(form)
      .then((response) => {
        console.log(response.data);
        this.setState(() => {
          return {
            fbRegistration: response.data,
          };
        });
      })
      .catch((error) => {
        console.log('ERROR al registrarse con FB', error);
        // utils.catchErrorRaven(
        //   error,
        //   utils.getUserInfo(),
        //   'Error trying to register with FB'
        // );
      });
  }

  componentDidMount() {
    window.fbq('track', 'PageView');
    window.fbq('track', 'Lead');

    const userData = utils.getUserInfo();

    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        // console.log('requestIdleCallback');
        this.setState({
          fingerprint: this.initFingerprint(),
          formReferall: utils.getReferer(),
          mounted: true,
          userData: userData,
          userId: userData ? userData.user.id : null,
        });
      });
    } else {
      setTimeout(() => {
        // console.log('requestIdleCallback Didnt exist');
        this.setState({
          fingerprint: this.initFingerprint(),
          formReferall: utils.getReferer(),
          mounted: true,
          userData: userData,
          userId: userData ? userData.user.id : null,
        });
      }, 500);
    }

    API.fetchSportsIndex()
      .then((response) => {
        console.log(response.data);
        this.setState({ sports: response.data }, () => {
          this.selectMailing();
        });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching sports index',
        });
        utils.logout();
      });
  }

  render() {
    let tempName = this.state.formName;
    let tempLastName = this.state.formNameLast;
    let tempMail = this.state.formMail;
    let tempNick = this.state.formNick;
    let conditionalComponent;
    if (this.state.registerSuccess !== null && this.state.registerSuccess) {
      let notLoggedData = JSON.parse(utils.getDataNotLoggedUser());
      console.log('notLoggedData', notLoggedData);
      if (!notLoggedData) {
        conditionalComponent = (
          <Redirect
            to={{
              pathname: '/',
              state: this.state,
            }}
          />
        );
      } else {
        let gameType = null;
        switch (notLoggedData.game_type) {
          case 'pool':
            gameType = 'quiniela';
            break;
          default:
            gameType = notLoggedData.game_type;
        }
        conditionalComponent = (
          <div>
            <Redirect
              to={{
                pathname: `${gameType}/${gameType}-invite/${notLoggedData.id}`,
              }}
            />
          </div>
        );
      }
    } else {
      // console.log(this.state.fbRegistration)
      conditionalComponent = (
        <div className="flex-row">
          <div className="flex-col">
            <div className="register-user-form">
              <div id="panelTitle" className="flex-row row-center ">
                <div className="flex-col">Regístrate con tu cuenta de Facebook</div>
                {this.props.type && this.props.type === 'popup' ? (
                  <div className="flex-col col-10">
                    <button className="cerrar-panel" onClick={this.props.hideRegister}>
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="titleForm">
                <img src={pickwinLogo} className="img-responsive" />
                <h5>Parece que todavía no estas registrado en Pickwin</h5>
                <h5> Completa el formulario para comenzar a jugar</h5>
                <h5 style={{ color: '#F9CC30' }}>
                  Asegurate que tu nombre y apellidos sean correctos e iguales a los de tu
                  identificación, te los pediremos al momento de hacer un retiro.
                </h5>
              </div>
              <div className="form-container">
                <FormGroup
                  id="fbformName"
                  className="col-xs-12 col-sm-6"
                  validationState={this.getValidationState('name')}
                >
                  <ControlLabel>Nombre</ControlLabel>
                  <FormControl type="text" value={tempName} onChange={this.getName} />
                  <FormControl.Feedback />
                  {this.state.nameNumber ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      Sólo puedes utilizar letras para ingresar tu nombre.
                    </div>
                  ) : null}
                  {this.state.nameQnty ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      Tu nombre debe contener al menos 4 letras.
                    </div>
                  ) : null}
                  {/* <HelpBlock>El nombre completo como lo tienes en tu estado de cuenta bancario.</HelpBlock> */}
                </FormGroup>
                <FormGroup
                  id="formName"
                  className="col-xs-12 col-sm-6"
                  validationState={this.getValidationState('namelast')}
                >
                  <ControlLabel>Apellidos</ControlLabel>
                  <FormControl type="text" value={tempLastName} onChange={this.getNameLast} />
                  <FormControl.Feedback />
                  {this.state.nameLastNumber ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      Sólo puedes utilizar letras para ingresar tu apellido.
                    </div>
                  ) : null}
                  {this.state.nameLastQnty ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      Tu Apellido debe contener al menos 4 letras.
                    </div>
                  ) : null}
                  {/* <HelpBlock>El nombre completo como lo tienes en tu estado de cuenta bancario.</HelpBlock> */}
                </FormGroup>
                <FormGroup
                  id="gbFormMail"
                  className="col-xs-12 col-sm-6"
                  validationState={this.getValidationState('mail')}
                >
                  <ControlLabel>Mail</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.formMail !== null ? this.state.formMail : tempMail}
                    onChange={this.getMail}
                  />
                  <FormControl.Feedback />
                  {this.state.mailError ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      {this.state.mailErrorLabel}
                    </div>
                  ) : null}
                  {/* <HelpBlock>El nombre completo como lo tienes en tu estado de cuenta bancario.</HelpBlock> */}
                </FormGroup>
                <FormGroup
                  id="fbFormNick"
                  className="col-xs-12 col-sm-6"
                  validationState={this.getValidationState('nick')}
                >
                  <ControlLabel>Apodo</ControlLabel>
                  <FormControl type="text" value={tempNick} onChange={this.getNick} />
                  <FormControl.Feedback />
                  <HelpBlock>
                    Te sugerimos este Nick, por que el que seleccionaste ya lo usa otro pickwinero
                  </HelpBlock>
                  {this.state.nickError ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      {this.state.nickErrorLabel}
                    </div>
                  ) : null}
                  {/* <HelpBlock>Te sugerimos este Nick, por que el que seleccionaste ya lo usa otro pickwinero</HelpBlock> */}
                </FormGroup>
                <FormGroup
                  id="fbFormPswd"
                  className="col-xs-12 col-sm-6"
                  validationState={this.getValidationState('pass')}
                >
                  <ControlLabel>Contraseña</ControlLabel>
                  <FormControl type="password" onChange={this.getPswd} />
                  <FormControl.Feedback />
                  <HelpBlock>Tu contraseña debe de tener al menos 6 carácteres.</HelpBlock>
                  {this.state.passError !== false ? (
                    <div className={'login-failed'} style={{ width: '100%' }}>
                      {this.state.passError.unique !== undefined
                        ? 'Este Nick ya esta ocupado, intenta con uno diferente'
                        : 'Este campo es obligatorio, neceisats una contraseña para poder ingresar a pickwin'}
                    </div>
                  ) : null}
                  {/* <HelpBlock>Y debe contener letras o números</HelpBlock> */}
                </FormGroup>
                <FormGroup
                  id="fbFormPswdConfirm"
                  className="col-xs-12 col-sm-6"
                  validationState={this.getValidationState('passConf')}
                >
                  <ControlLabel>Confirma tu Contraseña</ControlLabel>
                  <FormControl type="password" onChange={this.getPswdConf} />
                  <FormControl.Feedback />
                  <HelpBlock>Confirma la contresaña que ingresaste</HelpBlock>
                  {this.state.formPswd === this.state.formPswdConfirm ? null : (
                    <span className="login-failed">Tus contraseñas no son iguales</span>
                  )}
                </FormGroup>
                <FormGroup id="fbFormReferall" className="col-xs-12">
                  <ControlLabel>¿Quién te invitó?</ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.formReferral}
                    onChange={this.getReferall}
                  />
                  <FormControl.Feedback />
                  <HelpBlock>Escribe el nick o el código amigo de quién te invitó.</HelpBlock>
                </FormGroup>
                {/*<ListLeagues*/}
                {/*sports={this.state.sports}*/}
                {/*getLiga={this.getLiga}/>*/}
                {/*<EquipoFavorito*/}
                {/*liga={this.state.formLiga}*/}
                {/*equipos={this.state.equiposDisponibles}*/}
                {/*updateEquipos={this.updateEquipos}/>*/}
                <div className="list-checkbox col-xs-12">
                  <label className="row" id={'tos'}>
                    <div className="col-xs-1">
                      <input
                        type="checkbox"
                        name={'tos'}
                        id={`tos`}
                        value={this.state.formTos}
                        onChange={this.getTos}
                      />
                      <div className={`checkbox-custom `} />
                    </div>
                    <div className="col-xs-11">
                      <span>Acepto términos y Condiciones</span>
                    </div>
                  </label>
                  <label className="row" id={'oldenough'}>
                    <div className="col-xs-1">
                      <input
                        type="checkbox"
                        name={'oldenough'}
                        id={`oldenough`}
                        value="1"
                        onChange={this.getOld}
                      />
                      <div className={`checkbox-custom `} />
                    </div>
                    <div className="col-xs-11">
                      <span>Certifico que tengo al menos 18 años de edad</span>
                    </div>
                  </label>
                  <label className="row" id={'newsletter'}>
                    <div className="col-xs-1">
                      <input
                        type="checkbox"
                        name={'newsletter'}
                        id={`newsletter`}
                        value="1"
                        onChange={this.getNews}
                      />
                      <div className={`checkbox-custom `} />
                    </div>
                    <div className="col-xs-11">
                      <span>
                        Acepto que Pickwin me envíe avisos y comunicados por correo electrónico.
                      </span>
                    </div>
                  </label>
                </div>
                <div className="buttons-register">
                  <Button
                    className="btn-pick"
                    onClick={(e) => {
                      this.enviarRegistro();
                    }}
                    disabled={this.state.submitting || !this.state.validForm}
                  >
                    <span>Completa tu Registro</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}
export default RegistroFB;
