// Libraries
import React from 'react';
import _ from 'underscore';
import InfiniteScroll from 'react-infinite-scroller';
import $ from 'jquery';

// Components
import Loading from '../Loading';

// Styles
import '../BracketInfoContainer/bracketInfoContainer.scss';
import '../Bracket/bracket.scss';

//Images
import avatar from '../../assets/images/avatar-50.png';

const UserDescription = ({standing, selectedEntryId}) => {
  const userPick = Number(selectedEntryId) === Number(standing.bracket_entry_id);
  return (
    <div className={`flex-row no-padding row-bracket mis-picks ${userPick ? 'user-entry' : ''}`}>
      <div className='table-header-item flex-container start pl-05'>
        <div className="avatar-bracket">
          <img src={(standing.avatar === null || standing.avatar === "/img/profile-image-01.png") ? avatar : standing.avatar }
          className={standing.avatar === null || standing.avatar === "/img/profile-image-01.png" ? 'player-avatar' : ''} />
        </div>
        <span className="picks-rank m-05">{` ${standing.rank}° `}</span>
        <span>
          {standing.nick}
          {standing.number !== null ? (
            <sup style={{fontWeight: 'lighter', fontSize: '75%', margin: '0 2px'}} >
              {standing.number}
            </sup>
          ) : ('')}
        </span>
      </div>
    </div>
  );
}

const UserPicks = ({ standing, matches, userTieBreakers, tieBreakers, allMatches }) => {
  const picks = standing.bracket_picks;
  const props = standing.tennis_pick_props

  return (
    <div className={`flex-row no-padding row-bracket mis-picks`}>
      {matches.map((match, i) => {
        if ( picks === null || !picks.hasOwnProperty(match.id) ) {
          if (match.sort_status === 1) {
            return (
              <div className={`table-header-item game-item item-content-tennis`} key={i}>
                <i className="ion-close" style={{ color: '#E00034' }}></i>
              </div>
            );
          } else {
            return (
              <div className="item-content-tennis border-right border-bottom column flex-container align justify" style={{flex:'0 0'}} key={i}>
                <div className={`flex-row no-padding border-bottom fc-align looser`} style={{height: '70%'}}>
                  <div className="flex-container align justify">
                    <i className="ion-close" style={{ color: '#E00034' }}></i>
                  </div>
                </div>
                <div className="flex-row no-padding" style={{height: '30%'}}>
                  <div className={`col-50 flex-container align justify border-right looser`}>
                    <i className="ion-close" style={{ color: '#E00034' }}></i>
                  </div>
                  <div className={`col-50 flex-container align justify looser`}>
                    <i className="ion-close" style={{ color: '#E00034' }}></i>
                  </div>
                </div>
              </div>
            );
          }
        }

        const pick = picks[match.id];
        let prop = null;
        if (props !== null) {
          prop = props[match.id];
        }
        // el match no esta vivo
        if ( match.sort_status === 1) {
          return (
            <div className={`table-header-item game-item item-content-tennis`} key={i}>
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            </div>
          );
        }

        let selectedTeam = null;
        let selectedWinningGame = null;
        let selectedPropGame = null;
        if ( pick.pick === match.home_team_id ) {
          selectedTeam = match.player_one;
          selectedWinningGame = match.winning_game;
        }
        if ( pick.pick === match.away_team_id ) {
          selectedTeam = match.player_two;
          selectedWinningGame = match.winning_game;
        }
        selectedPropGame = match.tennis_props.length > 0 ? match.tennis_props[0].winning_pick : null;

        //Colors
        let correctPicks = [false, false, false];
        if ( match.status !== 'upcoming' ) {
          var correctness = 0;
          if ( pick.pick !== null && pick.pick === match.winning_pick ) {
            correctness = correctness ^ (1 << 2);
            correctPicks[0] = true;
          }
          if ( pick.winning_game !== null && pick.winning_game === match.winning_game ) {
            correctness = correctness ^ (1 << 1);
            correctPicks[1] = true;
          }
          if ( (prop !== null && prop !== undefined) && prop.pick === match.tennis_props[0].winning_pick ) {
            correctness = correctness ^ (1 << 0);
            correctPicks[2] = true;
          }
          correctness = (correctness >>> 0).toString(2);
        }

        // correct and incorrect pick class
        let pickResultClass = correctPicks[0] ? "winner" : "looser";

        return (
          <div className="item-content-tennis border-right border-bottom column flex-container align justify" key={i}>
            {selectedTeam &&
              <div className={`flex-row no-padding border-bottom fc-align ${pickResultClass}`} style={{height: '70%'}}>
                <div className="col-30">
                  <div className="avatar-bracket">
                    <img src={(!selectedTeam.avatar || selectedTeam.avatar === null) ? avatar : selectedTeam.avatar}
                    className={`${(!selectedTeam.avatar || selectedTeam.avatar === null) && 'player-avatar'}`} />
                  </div>
                </div>
                <div className="col-70 text-small overflow-words uppercase">
                  {selectedTeam.name}
                </div>
              </div>
            ||
              <div className={`flex-row no-padding border-bottom fc-align looser`} style={{height: '70%'}}>
                <div className="flex-container align justify">
                  <i className="ion-close" style={{ color: '#E00034' }}></i>
                </div>
              </div>
            }
            <div className="flex-row no-padding" style={{height: '30%'}}>
              <div className={`col-50 flex-container align justify border-right
                ${match.sort_status !== 1 && correctPicks[0] && correctPicks[1] && 'winner'}
                ${match.sort_status !== 1 && (correctPicks[0] && !correctPicks[1]) && 'looser'}
              `}>
                {pick.winning_game === null || pick.winning_game === '' ? <i className="ion-close" style={{ color: '#E00034' }}></i> : pick.winning_game}
              </div>
              <div className={`col-50 flex-container align justify
                ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && correctPicks[2]) && 'winner'}
                ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && !correctPicks[2]) && 'looser'}
              `}>
                {prop === null || prop === undefined ? <i className="ion-close" style={{ color: '#E00034' }}></i> : prop.pick === "under" ? '-' : '+'}
              </div>
            </div>
          </div>
        );
      })}

      {tieBreakers.map((tieBreaker) => <MyTieBreakers key={tieBreaker.id} tieBreaker={tieBreaker} userTieBreakers={userTieBreakers} matches={allMatches} /> )}
    </div>
  );
}

const UserPoints = ({standing, closed, selectedEntryId}) => {
  const userPick = Number(selectedEntryId) === Number(standing.bracket_entry_id);
  return (
    <div className={`flex-row no-padding row-bracket border-bottom mis-picks ${userPick ? 'user-entry' : ''}`}>
      <div className='flex-container align justify font-chivo text-medium'>
        {closed ? standing.points : standing.possible_points}
      </div>
    </div>
  );
}

const MyTieBreakers = ({ tieBreaker, userTieBreakers, matches }) => {
  if (userTieBreakers && userTieBreakers.length === undefined) {
    const userBreaker = userTieBreakers[tieBreaker.id];
    let matchFinded = null;
    if (userBreaker) {
      matchFinded = matches.find(match => (Number(match.away_team_id) === Number(userBreaker.val) || Number(match.home_team_id) === Number(userBreaker.val)));
    }

    let selectedTeam = null;
    if (matchFinded) {
      if ( Number(userBreaker.val) == Number(matchFinded.home_team_id) ) {
        selectedTeam = matchFinded.player_one;
      }
      if ( Number(userBreaker.val) == Number(matchFinded.away_team_id) ) {
        selectedTeam = matchFinded.player_two;
      }
    }

    return (
      <div className='flex-container align justify border-right border-bottom item-content-tennis' style={{flex:'0 0'}}>
        {tieBreaker.enabled &&
          <>
            {(userBreaker === undefined || userBreaker.val === null) &&
              <i className="ion-close" style={{ color: '#E00034' }}></i>
            ||
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            }
          </>
        ||
          <>
            {selectedTeam && selectedTeam.name || <i className="ion-close" style={{ color: '#E00034' }}></i>}
          </>
        }
      </div>
    );
  } else {
    return (
      <div className='flex-container align justify border-right border-bottom item-content-tennis' style={{flex:'0 0'}}>
        {tieBreaker.enabled &&
          <i className="ion-close" style={{ color: '#E00034' }}></i>
        ||
          <i className="ion-close" style={{ color: '#E00034' }}></i>
        }
      </div>
    );
  }
}

const PicksGroupBracketTennis = ({ matches, allMatches, groupPicks, closed, tieBreakers, loadMoreStandings, selectedEntryId }) => {
  const loadMore = (eventKey) => {
    loadMoreStandings(eventKey);
  }

  React.useEffect(() => {
    const headerScroll = window.document.getElementById("content-header-bracket");
    const lowerScroll = window.document.getElementById("content-lower-bracket");

    let ignoreEvent = false;
    let header = $('#content-header-bracket');
    let lower = $('#content-lower-bracket');
    header.scroll( function() {
        var ignore = ignoreEvent;
        ignoreEvent = false
        if (!ignore) {
            ignoreEvent = true;
            lower.scrollLeft($(this).scrollLeft());
        }} );

    lower.scroll( function() {
        var ignore = ignoreEvent;
        ignoreEvent = false;
        if (!ignore) {
            ignoreEvent = true;
            header.scrollLeft($(this).scrollLeft());
        }} );
    }, []);

  return (
    <div className="lower-section">
      <div className="container-picks">
        <div className="tabla-grupos">
          <div className="lower-table border-gradient">
            <div className='flex-row no-padding'>
              <div className='col-20 table-lower-item font-chivo pl-1 text-14 border-bottom border-right'>
                PIKWINERO
              </div>
              <div className='col-70 border-bottom'></div>
              <div className='col-10 flex-container align justify font-chivo text-14 border-bottom'>PTS</div>
            </div>

            <div className="overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal">
              <InfiniteScroll
                pageStart={1}
                loadMore={loadMore}
                hasMore={groupPicks.page >= groupPicks.pages ? false : true}
                loader={<Loading key={'labelLoader00'} label="..." />}
                useWindow={false}
              >
                <div className='flex-row no-padding no-margin-top'>
                  <div className='col-20'>
                    {groupPicks.items.map((standing, i) => <UserDescription standing={standing} selectedEntryId={selectedEntryId} key={`user-${i}-${standing.bracket_entry_id}`}/>)}
                  </div>
                  <div className='col-70 table-header-overflow' id="content-lower-bracket">
                    {groupPicks.items.map((standing, i) => <UserPicks standing={standing} matches={matches} userTieBreakers={standing.bracket_user_tiebreakers} tieBreakers={tieBreakers} allMatches={allMatches} key={`picks-${i}-${standing.bracket_entry_id}`} />)}
                  </div>
                  <div className='col-10 border-left'>
                    {groupPicks.items.map((standing, i) => <UserPoints closed={closed} standing={standing} selectedEntryId={selectedEntryId} key={`points-${i}-${standing.bracket_entry_id}`}/> )}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PicksGroupBracketTennis;