import React from 'react'
import styled from 'styled-components'
import { Icon } from '@iconify/react'

export default function PrevModalButton (props) {
  return (
    <Button {...props}>
      <Icon icon='ep:arrow-left-bold' />
    </Button>
  )
}

const Button = styled.button`
  outline: 0;
  border: 0;
  background-color: transparent;
  font-size: 3.2em;
  color: #a4a4a5;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translateY(-50%) translateX(-360px);
  transition: opacity 300ms ease;

  :hover {
    opacity: 0.7;
  }
`
