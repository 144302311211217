import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Loading from '../Loading';
import SubscriptionRxs from '../../utils/subscriptions';
import { firstBy } from 'thenby';
import avatar from '../../assets/images/avatar-50.png';

import API from '../../utils/api';
import $ from 'jquery';

window.jQuery.fn.synchroniseScroll = function(direction) {
  console.log('initscroll', direction);
  var elements      = this;
  var lastScrolling = null;
  var lastScroll    = performance.now();

  if (elements.length <= 1) {
    console.log('no elements');
    return;
  }


  elements.scroll(function(e) {
    if ( lastScrolling === null || (performance.now() - lastScroll >= 900) ) {
      lastScrolling = e.currentTarget;
    }
    if ( lastScrolling !== e.currentTarget ) {
      return;
    }

    var left = $(this).scrollLeft();
    var top  = $(this).scrollTop();

    elements.each(function(idx, el) {
      if ( el === lastScrolling ) { return; }
      if (direction === 'horizontal' && $(el).scrollLeft() != left) {
        $(el).scrollLeft(left);
      }
      if (direction === 'vertical' && $(el).scrollTop() != top ) {
        $(el).scrollTop(top);
      }
    });

    lastScroll = performance.now();

  });


};

const MyPicksF1 = ({ contests, entry }) => {
  const { survivor_picks } = entry;

  const thePicks = contests.map((contest) => {
    const pick = survivor_picks[contest.id];
    let pickComponent;
    if (pick) {
      pickComponent = (
        <div key={contest.id} className="item-container-group-pool custome-size uppercase">
          <div className="contest-group-pool-pick">
            <div className="flex-row row-center no-margin no-padding">
              <div className="flex-col col-15 text-center">
                {pick.rr === undefined ? `${pick.p.finish_pos}º` : `${pick.rr.finish_pos}º`}
              </div>
              <div className="flex-col col-65 text-center">
                {pick.p.name}
              </div>
              <div className='flex-col col-20 text-center'>
                <i className={`player-flag ${pick.p.logo}`}></i>
              </div>
            </div>
            <div className="flex-container center between" style={{borderTop: '1px solid #6a6a6a', padding: '5px'}}>
              <div className="flex-col no-padding">
                <img
                  className="player-avatar"
                  src={pick.p.avatar === null ? avatar : pick.p.avatar}
                  alt=""
                />
              </div>
              <div className="flex-col no-padding">
                <img className="" src={pick.p.alt_avatar === null ? '' : pick.p.alt_avatar} style={{width: '90px', height: 'auto'}} />
                <div className='flex-container justify center mt-05 font9'>{pick.team_name}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      pickComponent = <div key={contest.id} className="item-container-group-pool custome-size"></div>;
    }

    return pickComponent;
  });

  return (
    <div className="lista-container ownUser">
      <div className="userList group" style={{ margin: '0px', padding: '0px' }}>
        <ul className="list-unstyled lower-list">
          <li className="flex-row  responsive-sm row-no-padding lower-table-item-group user ">
            <div className="flex-container justify align text-center item-container-group-pool" alt="">
              MIS PICKS
            </div>
          </li>
        </ul>
      </div>
      <div className="dataList sync-horizontal" style={{ overflow: 'hidden' }}>
        <ul className="list-unstyled flex-col col-no-padding lower-list">
          <li className="flex-row row-no-padding lower-table-item-group user">{thePicks}</li>
        </ul>
      </div>

      <div className="totalesList flex-container justify align" style={{ paddingBottom: '0px' }}>
        <div className="lower-table-item-group flex-container justify align">
          <div className="item-container-group-pool flex-container align justify">
            {entry.survivor_standing.alive ? (
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            ) : (
                <i className="ion-close" style={{ color: '#E00034' }}></i>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserPicks = ({ contests, currentContest, standing, closed, strikesEnabled, sportId }) => {
  const survivor_picks = standing.survivor_picks;

  const picks = contests.map((contest) => {
    let pick =
      survivor_picks !== null && survivor_picks.hasOwnProperty(contest.id)
        ? survivor_picks[contest.id]
        : null;
    let pickComponent;

    if (pick) {
      let className = null;
      pick.elimination = standing.elimination_contest_id === contest.id;

      let thePick = (
        <div className="flex-row img-container row-center">
          <div className="shirt-picks grupo">
            <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
          </div>
        </div>
      );

      if (pick.pick && pick.en) {
        thePick = (
          <div className="contest-group-pool-pick">
            <div className="flex-row row-center no-margin no-padding">
              <div className="flex-col col-15 text-center">
                {pick.finish_pos === null ? '-' : `${pick.finish_pos}º`}
              </div>
              <div className="flex-col col-65 text-center">
                {pick.player}
              </div>
              <div className='flex-col col-20 text-center'>
                <i className={`player-flag ${pick.logo}`}></i>
              </div>
            </div>
            <div className="flex-container center between" style={{borderTop: '1px solid #6a6a6a', padding: '5px'}}>
              <div className="flex-col no-padding">
                <img
                  className="player-avatar"
                  src={pick.avatar === null ? avatar : pick.avatar}
                  alt=""
                />
              </div>
              <div className="flex-col no-padding">
                <img className="" src={pick.alt_avatar === null ? '' : pick.alt_avatar} style={{width: '90px', height: 'auto'}} />
                <div className='flex-container justify center mt-05 font9'>{pick.team_name}</div>
              </div>
            </div>
          </div>
        );
      }

      if (!strikesEnabled) {
        if (pick.st !== 2 && pick.en && !pick.correct) {
          className = 'incorrect';
        }
        if (pick.st === 2 && pick.en && !pick.correct) {
          className = 'strikethrough';
        }
        if (pick.en && pick.correct) {
          className = 'correct';
        }
      } else {
        // live
        if ((pick.st === 0 || pick.st === 2) && pick.correct) {
          className = 'correct';
        }
        // finished
        if (pick.st === 2) {
          if (!pick.correct && pick.strike && !pick.elimination) {
            className = 'strike';
          }
          if (!pick.correct && pick.strike && pick.elimination) {
            className = 'strike strikethrough';
          }
        }
        // live
        if (pick.st === 0) {
          if (!pick.correct && pick.strike && !pick.elimination) {
            className = 'strike';
          }
          if (!pick.correct && pick.strike && pick.elimination) {
            className = 'incorrect';
          }
        }
      }

      pickComponent = (
        <div key={contest.id} className={`item-container-group-pool custome-size uppercase ${className}`}>
          {thePick}
        </div>
      );
    } else {
      let innerComponent;
      if (!standing.alive && contest.id > standing.elimination_contest_id) {
        innerComponent = null;
      } else if (!standing.alive && contest.id === standing.elimination_contest_id) {
        innerComponent = (
          <div className="flex-row img-container row-center">
            <div className="shirt-picks grupo">
              <i className="ion-close" style={{ color: '#E00034' }}></i>
            </div>
          </div>
        );
      } else {
        if (standing.alive && contest.id === currentContest.id && !closed) {
          innerComponent = (
            <div className="flex-row img-container row-center">
              <div className="shirt-picks grupo">
                <i className="ion-close" style={{ color: '#E00034' }}></i>
              </div>
            </div>
          );
        }
      }

      pickComponent = (
        <div key={contest.id} className="item-container-group-pool custome-size">
          {innerComponent}
        </div>
      );
    }

    return pickComponent;
  });

  return <li className="flex-row row-no-padding lower-table-item-group user">{picks}</li>;
};

const SurvivorContestStandingsTable = ({
  standings,
  contests,
  currentContest,
  closed,
  strikesEnabled,
  entry,
  loadNext,
  pager,
  loading,
  sportId
}) => {
  const standingsList = standings.map((standing) => {
    const ownUser = entry && entry.id === standing.survivor_entry_id;
    return (
      <ul key={standing.survivor_standing_id} className="list-unstyled lower-list">
        <li className="flex-row row-no-padding responsive-sm lower-table-item-group user">
          <div
            className={`flex-container start align item-container-group-pool ${
              ownUser ? 'ownUser' : null
              }`}
            style={{ padding: '0px 5px' }}
          >
            <div className="image-container">
              <img
                className="avatarPicksGrupo"
                src={
                  standing.avatar === '/img/profile-image-01.png'
                    ? avatar
                    : standing.avatar
                }
                alt=""
              />
            </div>
            <span className="picks-rank" style={{ margin: '0 2px' }}>
              {standing.rank}°
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number ? (
                <sup style={{ fontWeight: 'lighter', fontSize: '75%' }}>{standing.number}</sup>
              ) : null}
            </span>
          </div>
        </li>
      </ul>
    );
  });

  const aliveList = standings.map((standing) => {
    const ownUser = entry && entry.id === standing.survivor_entry_id;
    return (
      <div
        key={standing.survivor_standing_id}
        className={`flex-row row-center row-no-padding lower-table-item-group ${
          ownUser ? 'ownUser' : null
          }`}
      >
        <div className="flex-col item-container-group-pool">
          <div className="shirt-picks ">
            {standing.alive ? (
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            ) : (
                <i className="ion-close" style={{ color: '#E00034' }}></i>
              )}
          </div>
        </div>
      </div>
    );
  });

  const userPicks = standings.map((standing) => {

    return (
      <UserPicks
        key={standing.survivor_standing_id}
        standing={standing}
        contests={contests}
        currentContest={currentContest}
        closed={closed}
        strikesEnabled={strikesEnabled}
        sportId={sportId}
      />
    );
  });

  return (
    <div className="lista-container flex-row row-no-padding">
      <div className="overflow overflow-y overflow-picks-grupo userList group sync-vertical">
        {standingsList}
      </div>
      <div className="overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal">
        <InfiniteScroll
          pageStart={1}
          loadMore={loadNext}
          hasMore={!loading && pager.page < pager.pages}
          useWindow={false}
          threshold={150}
          initialLoad={false}
          loader={<Loading key={'labelLoader00'} label="..." />}
        >
          <ul className="list-unstyled flex-col col-no-padding lower-list">{userPicks}</ul>
        </InfiniteScroll>
      </div>
      <div className="overflow overflow-y overflow-picks-grupo totalesList sync-vertical">
        {aliveList}
      </div>
    </div>
  );
};

const SurvivorContestGroupPicksF1 = ({ survivor, currentContest, entry, players, ...props }) => {
  const [standings, setStandings] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pager, setPager] = useState({
    page: 1,
    pages: 1,
  });

  useEffect(() => {
    API.getSurvivorContestGroupPicksF1(survivor.id, pager.page).then((response) => {
      const data = response.data;
      const updatedStandings = sortStandings(data.items);

      setStandings(() => updatedStandings);
      setPager(() => {
        return { page: data.page, pages: data.pages };
      });
      setLoaded(true);
      setLoading(false);

      window.jQuery('.sync-horizontal').synchroniseScroll('horizontal');
      window.jQuery('.sync-vertical').synchroniseScroll('vertical');
    });
  }, [pager.page, survivor.id]);

  useEffect(() => {
    function onStandingsUpdateF1(data) {
      console.log('onStandingsUpdateF1', data, players);

      const survivorId = data.survivorId;
      if (survivorId !== survivor.id) {
        return;
      }

      if (!standings || standings.length === 0) {
        return;
      }

      const newStandings = data.standings;
      let updatedStandings = standings.map((standing) => {
        const updatedStanding = newStandings.find(
          (_standing) => _standing.survivor_entry_id === standing.survivor_entry_id
        );

        return {
          ...standing,
          ...{
            rank: updatedStanding.rank,
            alive: updatedStanding.alive,
            strikes: updatedStanding.strikes,
            elimination_contest_id: updatedStanding.elimination_contest_id,
            open_picks: updatedStanding.open_picks,
          },
        };
      });

      updatedStandings = updatedStandings.sort(
        firstBy((a, b) => {
          return a.rank - b.rank;
        })
          .thenBy((a, b) => {
            const maxInt = Math.pow(2, 53) - 1;
            const halfInt = Math.floor(maxInt / 2);
            let aPos = maxInt;
            let bPos = maxInt;
            if (a.survivor_picks && a.survivor_picks.hasOwnProperty(currentContest.id)) {
              aPos =
                a.survivor_picks[currentContest.id].finish_pos === null ||
                  a.survivor_picks[currentContest.id].finish_pos === ''
                  ? halfInt
                  : a.survivor_picks[currentContest.id].finish_pos;
            }
            if (b.survivor_picks && b.survivor_picks.hasOwnProperty(currentContest.id)) {
              bPos =
                b.survivor_picks[currentContest.id].finish_pos === null ||
                  b.survivor_picks[currentContest.id].finish_pos === ''
                  ? halfInt
                  : b.survivor_picks[currentContest.id].finish_pos;
            }
            return aPos - bPos;
          })
          .thenBy((a, b) => {
            let aPick,
              bPick = Math.pow(2, 53) - 1;
            if (a.survivor_picks && a.survivor_picks.hasOwnProperty(currentContest.id)) {
              aPick = a.survivor_picks[currentContest.id].player;
            }
            if (b.survivor_picks && b.survivor_picks.hasOwnProperty(currentContest.id)) {
              bPick = b.survivor_picks[currentContest.id].player;
            }
            if (aPick > bPick) {
              return 1;
            }
            if (bPick > aPick) {
              return -1;
            }
            return 0;
          })
          .thenBy('survivor_entry_id')
      );

      //UPDATE INFO PICK FROM PLAYER
      let newUpdateStandings = updatedStandings.map((item) => {
        let itemPlayer = players.find(p => p.id === item.survivor_picks[currentContest.id].pick);
        item.survivor_picks[currentContest.id].finish_pos = itemPlayer.finish_pos;
        return item;
      });

      setStandings(() => newUpdateStandings);
    }

    const standingsObserver = SubscriptionRxs.survivorStandingsUpdatedReceivedF1$._subscribe(
      onStandingsUpdateF1
    );

    return () => {
      console.log('cleanup');
      standingsObserver.unsubscribe();
    };
  }, [loaded, currentContest.id, standings, survivor.id]);

  useEffect(() => {
    const onPlayersUpdateF1 = (data) => {
      console.log('F1onPlayersUpdate', data);
      const contestId = data.contest_id;
      const playerResults = data.race_results;

      const updatedStandings = standings.map((standing) => {
        if (standing.survivor_picks !== null && standing.survivor_picks.hasOwnProperty(contestId)) {
          let pick = standing.survivor_picks[contestId];
          let player = playerResults.find((player) => player.player_id === pick.pick);

          let eliminationType = currentContest.elimination_type;
          let threshold = survivor.elimination_threshold;
          if (eliminationType === 'cut') {
            if (player.finish_pos === '' || player.finish_pos === 'null' || player.finish_pos === null) {
              pick.correct = false;
              pick.strike = true;
            } else {
              pick.correct = true;
              pick.strike = false;
            }
          } else {
            if (player.finish_pos === null || player.finish_pos > threshold) {
              pick.correct = false;
              pick.strike = true;
            } else {
              pick.correct = true;
              pick.strike = false;
            }
          }


          pick = {
            ...pick,
            ...{
              finish_pos: player.finish_pos,
              laps: player.laps,
              laps_num: player.laps_num,
              pit: player.pit,
              time: player.time,
            },
          };
          standing.survivor_picks[contestId] = pick;
        }

        return standing;
      });

      setStandings(() => updatedStandings);
    };

    const playersObserver = SubscriptionRxs.groupPoolPlayersUpdateReceivedF1$.subscribe(
      onPlayersUpdateF1
    );
    return () => {
      console.log('cleanup');
      playersObserver.unsubscribe();
    };
  }, [loaded, currentContest.elimination_threshold, currentContest.elimination_type, standings]);

  const sortStandings = (items) => {
    items.sort(
      firstBy((a, b) => {
        return a.rank - b.rank;
      })
        .thenBy((a, b) => {
          const maxInt = Math.pow(2, 53) - 1;
          const halfInt = Math.floor(maxInt / 2);
          let aPos = maxInt;
          let bPos = maxInt;
          if (a.survivor_picks && a.survivor_picks.hasOwnProperty(currentContest.id)) {
            aPos =
              a.survivor_picks[currentContest.id].position === null ||
                a.survivor_picks[currentContest.id].position === ''
                ? halfInt
                : a.survivor_picks[currentContest.id].position;
          }
          if (b.survivor_picks && b.survivor_picks.hasOwnProperty(currentContest.id)) {
            bPos =
              b.survivor_picks[currentContest.id].position === null ||
                b.survivor_picks[currentContest.id].position === ''
                ? halfInt
                : b.survivor_picks[currentContest.id].position;
          }
          return aPos - bPos;
        })
        .thenBy((a, b) => {
          let aPick,
            bPick = Math.pow(2, 53) - 1;
          if (a.survivor_picks && a.survivor_picks.hasOwnProperty(currentContest.id)) {
            aPick = a.survivor_picks[currentContest.id].player;
          }
          if (b.survivor_picks && b.survivor_picks.hasOwnProperty(currentContest.id)) {
            bPick = b.survivor_picks[currentContest.id].player;
          }
          if (aPick > bPick) {
            return 1;
          }
          if (bPick > aPick) {
            return -1;
          }
          return 0;
        })
        .thenBy('survivor_entry_id')
    );
    return items;
  };

  const loadNext = () => {
    if (pager.page >= pager.pages) {
      return true;
    }

    let nextPage = pager.page + 1;

    setLoading(true);
    console.log('nextPage', nextPage);

    API.getSurvivorContestGroupPicks(survivor.id, nextPage).then((response) => {
      var data = response.data;
      let newStandings = [...standings, ...data.items];
      newStandings = sortStandings(newStandings);
      setStandings(newStandings);

      setTimeout(() => {
        setPager({ ...pager, page: nextPage });
        setLoading(false);
      }, 500);
    });
  };

  const contestsList = () => {
    return survivor.contests.map((contest) => {
      return (
        <div key={contest.id} className="upper-table-item group custome-size" style={{minWidth: '180px'}}>
          <img src={contest.logo} className="contest-logo" alt="" />
          {contest.abbreviation}
        </div>
      );
    });
  };

  return (
    <div className="multiple-quiniela-info grupo flex-row ">
      <div className="upper-section">
        <div className="container-picks">
          <div className="upper-table">
            <div className="upper-table-category headers">
              <div className="row-list">
                <div className="title-container">
                  <div className="upper-table-category group">CARRERAS</div>
                </div>
                <div className="picksData sync-horizontal">
                  <div className="data-row">{contestsList()}</div>
                </div>

                <div className="total-container flex-col col-center">
                  <div className="upper-table-item group total">VIVO</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-section">
        <div className="container-picks">
          <div className="tabla-grupos">
            <div className="lower-table border-gradient">
              {entry ? <MyPicksF1 contests={survivor.contests} entry={entry.survivor_entry} /> : null}
              <SurvivorContestStandingsTable
                standings={standings}
                contests={survivor.contests}
                currentContest={currentContest}
                closed={survivor.closed}
                strikesEnabled={survivor.strikes_enabled}
                entry={entry ? entry.survivor_entry : null}
                loadNext={loadNext}
                pager={pager}
                loading={loading}
                sportId={survivor.sport_id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurvivorContestGroupPicksF1;
