import styled from 'styled-components'


const Link = styled.a`
  color: #AFE140;
  font-size: 0.8em;
  margin-top: 1.5em;
  cursor: pointer;
  display: block;

  :hover {
    opacity: 0.7;
  }
`

export default Link
