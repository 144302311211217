import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

// Assets
import imgArrow from '../../../assets/img/Flechas verdes.png'

BarLevel.defaultProps = {
  progress: 0,
  // Delay in Millisecods
  delay: 500
}

export default function BarLevel (props) {
  const [progress, setProgress] = useState(0)

  const DURATION = progress * 4666 / 100

  useEffect(() => {
    window.setTimeout(() => {
      setProgress(props.progress)
    }, props.delay)
  }, [props.progress])

  return (
    <Box>
      <Progress
        style={{
          width: `${progress}%`,
          transition: `width ${DURATION}ms ease`
        }}
      >
        <Arrows
          progress={progress}
          duration={DURATION / 2}
        />
      </Progress>
    </Box>
  )
}

const animaArrow = keyframes`
  from {
    opacity: 0.1;
    transform: rotate(90deg) translateY(0) scale(0.7);
  }
  to {
    opacity: 1;
    transform: rotate(90deg) translateY(2em) scale(1);
  }
`

const ArrowSmall = styled.img`
  width: 1.7em;
  transform: rotate(90deg);
  position: absolute;
  top: -1.9em;
  right: 4em;
  animation: 1.3s ${animaArrow} alternate infinite;
`

const ArrowSmall2 = styled.img`
  width: 2.3em;
  transform: rotate(90deg);
  position: absolute;
  bottom: -1.52em;
  right: 7em;
  animation: 1.3s ${animaArrow} alternate infinite 440ms;
`

const Box = styled.div`
  border: 2px solid whitesmoke;
  margin: 1.5em 0 2.5em 0;
  position: relative;
  height: 1em;
  border-radius: 0.3em;
`

const Progress = styled.div`
  height: calc(100% + 4px);
  background-color: #a9ff00;
  position: absolute;
  left: -2px;
  top: -2px;
  border-radius: 0.3em 0.6em 0.6em 0.3em;
  box-shadow: 0 0 23px 2px #a9ff00c7;
  width: 0%;

  div {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: -0.2em;
    width: 4em;
  }
`

const LighBox = styled.span`
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 2em;
  height: 2em;
  background-color: #bcff38;
  filter: blur(16px);
`

const Arrows = (props) => {
  return (
    <div style={{
      opacity: props.progress >= 8 ? 1 : 0,
      transition: `opacity ${props.duration}ms ease`,
      width: '3em'
    }}
    >
      <LighBox />
      {props.progress >= 30 && (
        <>
          <ArrowSmall src={imgArrow} />
          <ArrowSmall2 src={imgArrow} />
        </>
      )}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 39.38 30.59'
        {...props}
      >
        <path
          style={{
            fill: 'none',
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 3
          }}
          d='m25.29 1.5 12.59 13.79-12.59 13.8'
        />
        <path
          style={{
            fill: 'none',
            opacity: 0.9,
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2
          }}
          d='m17.19 1.5 12.6 13.79-12.6 13.8'
        />
        <path
          style={{
            fill: 'none',
            opacity: 0.7,
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2
          }}
          d='m9.1 1.5 12.59 13.79L9.1 29.09'
        />
        <path
          style={{
            fill: 'none',
            opacity: 0.5,
            stroke: '#C1FF32',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: 2
          }}
          d='m1 1.5 12.59 13.79L1 29.09'
        />
      </svg>
    </div>
  )
}
