import React from 'react';
import numeral from 'numeral';
import posicionesIcon from '../../assets/images/posicionesIconBlanco-50.png';

import groupPoolActions from '../QuinielaGroupsUI/actions';

import avatar from '../../assets/images/avatar-50.png';
import utils from '../../utils/utils';
import { FormControl, Button, Modal } from 'react-bootstrap';
import $ from 'jquery';

import { ISRTooltip } from '../TablaPosiciones/TablaPosiciones';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from '../Loading';

/**
 * This component handles the button follow friend actions and renders
 * @param followSuccess { Boolean }
 * @param followUser    { Function }
 * @param followError   { Boolean }
 * @param user          { Object }
 * @return Component
 *
 */
const ButtonFollowFriend = ({ followSuccess, followError, dispatch, user }) => {
  if (!user || !utils.isLogged()) {
    return null;
  }
  let conditionalComponent = null;
  // console.info(followSuccess);
  // console.error(followError);
  //
  // If is your friend or is you dont show it
  if (!utils.isLogged() || (user && user.user_id === utils.getUserInfo().id) || user.friend !== 0) {
    return (
      <div className="posiciones-category friend quina flex-container align justify">
        <i className="ion-" />
      </div>
    );
  } else {
    if (!followSuccess && !followError) {
      conditionalComponent = (
        <div className="posiciones-category friend quina flex-container align justify">
          <span
            className={` flex-row row-center btn-addFriend row-no-padding`}
            onClick={() => {
              groupPoolActions.openAddConfirmation(dispatch, user);
            }}
          >
            <i className="ion-plus-round flex-col col-center"></i>
          </span>
        </div>
      );
    } else if (followSuccess) {
      conditionalComponent = (
        <div className="flex-container align justify friend quina position-category successFollow">
          <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
        </div>
      );
    } else if (followError) {
      conditionalComponent = (
        <div className="flex-container align justify friend quina position-category errorFollow">
          <i className="ion-close-round " style={{ color: '#e00034' }}></i>
        </div>
      );
    }
    return conditionalComponent;
  }
};

export const PopUpConfirmationAdd = ({
  title,
  submitting,
  inviteStatus,
  dispatch,
  show,
  hideConfirmation,
  label,
  handleInvite,
  userToAdd,
}) => {
  if (!show) {
    return null;
  }
  let content = null;
  if (inviteStatus === 'error') {
    content = (
      <span className="flex-col col-80 col-offset-10">
        <div className="flex-container justify align column text-center">
          <i className="ion-alert-circled alert" />
        </div>
        <strong className="flex-row row-no-padding">
          <div className="flex-col col-center text-center">
            Sucedió un error al invitar a {userToAdd ? userToAdd.user.nick : 'tu amigo'}
          </div>
        </strong>
        <span className="flex-row row-no-padding">
          <div className="flex-col col-center text-center">¿Quieres intentarlo de nuevo?</div>
        </span>
      </span>
    );
  } else if (inviteStatus === 'success') {
    content = (
      <span className="flex-col col-80 col-offset-10 col-center col-no-padding text-center ">
        <div className="flex-container justify align column text-center">
          <i className="ion-checkmark-circled success" />
        </div>
        <strong className="flex-row row-no-padding">
          <div className="flex-col col-center">
            Agregaste a {userToAdd ? userToAdd.user.nick : 'tu amigo'}.
          </div>
        </strong>
      </span>
    );
  } else {
    content = (
      <span className="flex-col col-80 col-offset-10 col-center col-no-padding text-center">
        <div className="flex-container justify align column text-center">
          <i className="ion-person-add big-icon" />
        </div>
        <strong className="flex-row row-no-padding text-center">
          <div className="flex-col col-center  text-center ">
            ¿Quieres agregar a {userToAdd ? userToAdd.user.nick : 'pickwinero'} como tu amigo?
          </div>
        </strong>
      </span>
    );
  }

  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={show} keyboard={false}>
      <div className="panel-abonar">
        <div
          id="panelTitle"
          className={`flex-row row-no-padding panel-abonar-titulo ${
            inviteStatus === 'error' ? 'failed' : 'Error'
          }`}
        >
          <span>{inviteStatus === 'error' ? 'No se pudo enviar tu solicitud' : title}</span>
          <button
            className="cerrar-panel"
            onClick={() => {
              hideConfirmation(dispatch);
            }}
          >
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel" style={{ margin: '10px 0px' }}>
          {content}
        </div>
        <div className="flex-row row-center">
          <div className="flex-col col-90 col-offset-5">
            <div className="flex-row">
              <Button
                className={`flex-col col-center btn-rojo btn-submit text-center ${
                  inviteStatus === 'success' ? 'col-80 col-offset-10' : 'col-40 col-offset-5'
                }`}
                onClick={() => {
                  hideConfirmation(dispatch);
                }}
              >
                {inviteStatus === 'success' ? 'Cerrar' : 'No'}
              </Button>
              <Button
                disabled={submitting}
                className={` ${
                  inviteStatus === 'success' ? 'hide' : ''
                } flex-col col-40 col-offset-5 col-center btn-pick btn-submit text-center`}
                onClick={() => {
                  handleInvite(dispatch, userToAdd.user_id);
                  // console.log('click');
                }}
              >
                {submitting ? (
                  <div className="flex-container justify align column">
                    <i className="ion-load-a loading small"></i>
                  </div>
                ) : (
                  'Si'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SearchableInput = ({
  placeholder,
  value,
  dispatch,
  fetchStandings,
  changeSearchNick,
  resetValue,
  submitting,
  poolId,
  queryParams,
}) => {
  // console.log( 'Searchable' );
  //const emptySearch = value === '' ? true : false;
  return (
    <div className="flex-row row-no-padding row-center" id="search-field-nick">
      <div id="searchableNickInput" className="flex-col col-75 col-offset-5 col-no-padding">
        <FormControl
          onChange={(e) => {
            // console.log( e.target.value );
            changeSearchNick(dispatch, e.target.value);
            fetchStandings(dispatch, queryParams, poolId);
          }}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
      <div className="flex-col col-no-padding col-15 btn-search-field">
        <Button
          onClick={(e) => {
            console.log('Clicked');
            resetValue(dispatch);
            fetchStandings(dispatch, queryParams, poolId);
          }}
          className="btn-default"
        >
          <i className={`${submitting ? 'ion-load-a loading' : 'ion-close-circled'}`}> </i>
        </Button>
      </div>
    </div>
  );
};

class TablaPosicionesGroupPool extends React.Component {
  constructor(props) {
    super();
    this.state = {};

    this.isRowLoaded = ({ index }) => {
      return !!this.props.posiciones.items[index];
    };

    this.loadMoreRows = ({ startIndex, stopIndex }) => {
      // console.log('Load More...', this );
      if (this.props.queryParams.page >= this.props.queryParams.pages) {
        return;
      } else {
        groupPoolActions.siguientePagPositions(this.props.dispatch).then((response) => {
          groupPoolActions.getMorePoolStandings(
            this.props.dispatch,
            this.props.poolId,
            this.props.queryParams
          );
        });
        setTimeout(() => {
          $('.score-avatars').height(22);
          $('.score-avatars').width(22);
        }, 700);
      }
    };

    this.rowUser = (entry) => {
      const userData = utils.getUserInfo();
      // console.log( 'userData', userData);
      // console.log( 'myEntry', this.props.myEntry);

      // let myStanding = this.props.posiciones.items.find( i => i.group_pool_entry_id === entry.group_pool_entry_id );
      if (!entry || !entry.group_pool_standing) {
        return null;
      }
      // if( !myStanding ){  return null }
      return (
        <li className="position-item user flex-row no-row-padding">
          <div className="posiciones-category friendAdd flex-col "></div>
          <div className="posiciones-category posición flex-col  quina"></div>
          <div className="posiciones-category posición flex-col col-15  quina">
            {entry.group_pool_standing.rank}°
          </div>
          <div className="posiciones-category jugador flex-col col-40 quina">
            <img
              src={userData.avatar === null ? avatar : `${userData.avatar_dir}${userData.avatar}`}
              className="img-circle score-avatars"
            />
            <span>{`${userData.nick}`}</span>
            {
              this.props.myEntry.hasOwnProperty('number') && this.props.myEntry.number !== null ?
                (<sup style={{fontSize: 'smaller', margin: '7px 0px 0 2px'}}>{this.props.myEntry.number}</sup>)
              :
                null
            }
          </div>
          <div className="posiciones-category points flex-col col-15 quina">
            {entry.group_pool_standing.points}
          </div>
          <div className="posiciones-category a-ganar flex-col col-20 quina">
            {!entry.group_pool_user_prize
              ? numeral(0).format('$0,000.00')
              : numeral(entry.group_pool_user_prize / 100).format('$0,000.00')}
          </div>
        </li>
      );
    };

    this.rowRenderer = (props) => {
      // console.log(this.props)
      let content = null;
      let { poolInfo, entryId, successFollow, errorFollow } = this.props;
      const usersItems = this.props.posiciones.items;
      const standing = usersItems[props.index];
      const { user, points, rank } = standing;
      let avatarImg = null;
      if (user.avatar !== null) {
        avatarImg = <img src={`${user.avatar_dir}${user.avatar}`} className='img-circle score-avatars' alt='avatar' />
      } else {
        avatarImg = <img src={avatar} alt='avatar' className='img-circle score-avatars' />
      }

      const followSuccess = !successFollow
        ? false
        : successFollow.find(
            (success) => success.status && success.id === usersItems[props.index].user_id
          );
      const followError = !errorFollow
        ? false
        : errorFollow.find((error) => error.status && error.id === usersItems[props.index].user_id);

      if (!usersItems) {
        return;
      }

      content = (
        <div
          key={props.key}
          className={`flex-row row-no-padding flex-container align table-positions-row ${entryId === standing.group_pool_entry_id ? 'user' : '' }`}
        >
          <ButtonFollowFriend
            dispatch={this.props.dispatch}
            followSuccess={followSuccess}
            followError={followError}
            openAddConfirmation={groupPoolActions.openAddConfirmation}
            user={standing}
          />
          <div className="flex-col col-15 text-center text-bold">
            <span>{rank}°</span>
          </div>
          <div className="flex-col col-40 flex-container align">
            {avatarImg}
            <span>{user.nick}</span>{' '}
            <sup>{standing.group_pool_entry.number ?? ''}</sup>
          </div>
          <div className="flex-col col-15 text-center">
            <span>{points} </span>
          </div>
          <div className="flex-col col-20 text-right">
            {poolInfo.status === 'closed' && standing.user_prize.base_amount ? (
              <ISRTooltip
                availablePrize={numeral(
                  standing.user_prize.base_amount / 100
                ).format('$0,000.00')}
                moneyFormat={'$0,000.00'}
                tax={standing.user_prize.tax}
              />
            ) : null}
            <span className='' style={{paddingRight: '4px'}}>
              {numeral(standing.user_prize.amount / 100).format('$0,000.00')}
            </span>
          </div>
        </div>
      );
      return (
        <div
          className={`${props.index % 2 ? 'even-colored-row' : ''}`}
          key={props.key}
          style={props.style}
        >
          {content}
        </div>
      );
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.list) {
      this.list.forceUpdateGrid();
    }
  }

  render() {
    let {
      posiciones,
      submittingSearch,
      poolId,
      withRegister,
      searchableNick,
      userToAdd,
      queryParams,
      myEntry,
      type,
      dispatch,
      showAddConfirmation,
      submittingInvite,
      inviteStatus,
    } = this.props;

    return (
      <div className="flex-col col-no-padding">
        <PopUpConfirmationAdd
          title={'Agrega a tus amigos en pickwin'}
          label={`Quieres agregar a ${userToAdd ? userToAdd.user.nick : 'pickwinero'} como amigo`}
          submitting={submittingInvite}
          dispatch={dispatch}
          inviteStatus={inviteStatus}
          show={showAddConfirmation}
          handleInvite={groupPoolActions.followUser}
          hideConfirmation={groupPoolActions.closeAddConfirmation}
          userToAdd={userToAdd}
        />
        <div className={`position-list border-gradient`}>
          <div className="title-quiniela">
            <div className="title-wrapper">
              <img src={posicionesIcon} className="pool-icons tablas" />{' '}
              <span className='font-chivo'>Tabla de Posiciones</span>
            </div>
          </div>
          <SearchableInput
            dispatch={dispatch}
            poolId={poolId}
            placeholder={'Buscar a pickwineros por su nick '}
            value={searchableNick}
            queryParams={queryParams}
            fetchStandings={groupPoolActions.getSearchableStandings}
            changeSearchNick={groupPoolActions.changeSearchNick}
            resetValue={groupPoolActions.resetNickValue}
            submitting={submittingSearch}
          />
          <div className="position-header flex-row row-no-padding ">
            <div className="posiciones-category flex-col col-center">
              <i className="ion-ios-people" style={{ fontSize: '1.5em' }}></i>
            </div>
            <div className="sortableClick posiciones-category flex-col col-15 quina">
              <span>Pos</span>
            </div>
            <div className="sortableClick posiciones-category jugador flex-col col-40 quina">
              <span>Jugador</span>
            </div>
            <div className="sortableClick posiciones-category flex-col col-15 quina">
              <span>Puntos</span>
            </div>
            <div className="sortableClick posiciones-category a-ganar flex-col col-20 quina">
              <span>A ganar</span>
            </div>
          </div>
          <div
            className={`overflow no-border overflow-invisible overflow-y ${
              type === 'lobby' ? 'overflow-300' : 'overflow-360'
            }`}
          >
            <div className="lista-posiciones quiniela">
              {withRegister ? this.rowUser(myEntry) : null}
              {submittingSearch ? (
                <div className="flex-container justify align">
                  <i className="ion-load-a loading" />
                </div>
              ) : !posiciones ||
                !posiciones.hasOwnProperty('items') ||
                posiciones.items.length < 1 ? (
                <div className="flex-container column justify align">
                  <i className="ion-sad big-ionicon" />
                  <span className="text-center">
                    Lo sentimos, no encontramos ningún pickwinero.
                  </span>
                </div>
              ) : (
                // <InfiniteLoader
                //   isRowLoaded={this.isRowLoaded}
                //   loadMoreRows={this.loadMoreRows}
                //   rowCount={posiciones.count}
                //   threshold={50}
                // >
                //   {({ onRowsRendered, registerChild }) => (
                //     <AutoSizer>
                //       {({ height, width }) => (
                //         <List
                //           ref={(registerChild) => (this.list = registerChild)}
                //           height={withRegister ? height - 25 : height}
                //           overscanRowCount={30}
                //           onRowsRendered={onRowsRendered}
                //           rowCount={posiciones.items.length}
                //           rowHeight={25}
                //           rowRenderer={this.rowRenderer}
                //           width={width}
                //         />
                //       )}
                //     </AutoSizer>
                //   )}
                // </InfiniteLoader>

                <div style={{height: '100%', overflowY: 'scroll'}}>
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={this.loadMoreRows}
                    hasMore={this.props.posiciones.page === this.props.posiciones.pages ? false : true}
                    loader={<Loading key={'labelLoader00'} label="..." />}
                    useWindow={false}
                  >
                    {this.props.posiciones.items.map((pos, i) => {
                      let props = {key: i, index: i, style: null}
                      return this.rowRenderer(props);
                    })}
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TablaPosicionesGroupPool;
