import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

// Components
import RegistroFormPanel from '../RegistroFormPanel'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Utils
import gaUtils from '../GAWrapper'
import PageWrapper from '../PageWrapper'
import utils from '../../utils/utils'

const initialState = {
  loggedIn: false,
  fbFailed: null,
  registerSuccess: null,
  userData: null,
  userFullData: null,
  userId: null,
  referal: null,
  validForm: false
}


export default function Registro () {
  const { isAuthenticated, isInitialized, openSignupModal } = useAuthContext()

   useEffect(() => {
     const fakeMatch = {
       isExact: true,
       params: {
         slug: 'Registrate'
       }
     }

     gaUtils.handleDocumentAttributes(fakeMatch)
  }, [])

  useEffect(() => {
    if (!isAuthenticated && isInitialized) {
      openSignupModal()
    }
  }, [isAuthenticated, isInitialized])

  if (!isInitialized) return null

  return <Redirect to='/' />
}

