import React from 'react';
import { ControlLabel } from 'react-bootstrap/lib';

import { CSSTransitionGroup } from 'react-transition-group';

import NotificationItemFormsy from './NotificationItemFormsy';
// import {Option} from 'react-select';

import SportsNotifications from './SportsNotifications';

const FormularioNotifications = ({
  notifications,
  updateData,
  initializeTournaments,
  updateSwitchData,
  sportsList,
  updateSportsList,
  updateSportsNotifications,
  updateGameTypeNotifications,
  updateEntryNotifications,
  updateTournamentsNotifications,
  updateHandleAllAmoutPerSport,
  mounted,
  isReady,
}) => {
  if (mounted) {
    return (
      <div className="flex-col col-no-padding">
        <div className="flex-row row-center">
          <div className="flex-col category-notification">
            <div className="flex-row ">
              <div className="flex-col">
                <ControlLabel className="flex-row">Mis Quinielas</ControlLabel>
                <p className="flex-row">
                  Elige que notificaciones te gustaría recibir acerca de los concursos en los que
                  estes participando.
                </p>
              </div>
            </div>
            <div className={`notification-sport-item`}>
              <NotificationItemFormsy
                defaultValue={notifications['mypools']['pick_reminder']}
                inputHandler={updateSwitchData}
                label="Si no has guardado tu primer pick"
                subLabel="Elige que notificaciones te gustaría recibir acerca de los concursos en los que estes participando"
                name="pick_reminder"
                switchCategory={'mypools'}
                required
                type="SWITCH"
              />
            </div>
            <div className={`notification-sport-item`}>
              <NotificationItemFormsy
                defaultValue={notifications['mypools']['live_position']}
                inputHandler={updateSwitchData}
                label={'Tu posición durante los concursos'}
                subLabel={'En que posición te encuentras al terminar el día'}
                name={'live_position'}
                required
                switchCategory={'mypools'}
                type={'SWITCH'}
              />
            </div>
            <div className={`notification-sport-item`}>
              <NotificationItemFormsy
                defaultValue={notifications['mypools']['final_results']}
                inputHandler={updateSwitchData}
                label={'Tus resultados al terminar'}
                subLabel={
                  'En que posición has quedado y en caso de haber recibido algún premio, cual fue'
                }
                name={'final_results'}
                required
                switchCategory={'mypools'}
                type={'SWITCH'}
              />
            </div>
          </div>
        </div>
        <div className="flex-row row-center">
          <div className="flex-col category-notification">
            <div className="flex-row row-center">
              <div className="flex-col">
                <ControlLabel className="flex-row row-center">Mis Amigos</ControlLabel>
                <p className="flex-row row-center">
                  {' '}
                  Elige que notificaciones te gustaría recibir acerca de las actividades que
                  realicen tus amigos en Pickwin
                </p>
              </div>
            </div>
            <div className={`notification-sport-item`}>
              <NotificationItemFormsy
                defaultValue={notifications['social']['challenges']}
                inputHandler={updateSwitchData}
                label="Retos"
                subLabel="Recibe avisos cuando un amigo te rete a jugar un concurso en Pickwin"
                name="challenges"
                switchCategory={'social'}
                required
                type="SWITCH"
              />
            </div>
            <div className={`notification-sport-item`}>
              <NotificationItemFormsy
                defaultValue={notifications['social']['friend_requests']}
                inputHandler={updateSwitchData}
                label={'Solicitudes de amistad'}
                subLabel="Cuando un usuario de Pickwin te envie una solicitud de amistad"
                name={'friend_requests'}
                required
                switchCategory={'social'}
                type={'SWITCH'}
              />
            </div>
          </div>
        </div>
        <div className="flex-row row-center">
          <div className="flex-col category-notification">
            <div className="flex-row row-center">
              <div className="flex-col">
                <ControlLabel className="flex-row row-center">Avisos </ControlLabel>
                <p className="flex-row row-center">
                  Pickwin te avisará de concursos a los que no estes actualmente registrado
                </p>
                <p className="flex-row row-center">
                  Únicamente elige los deportes y rangos de entradas de los que te interesen recibir
                  notificaciones
                </p>
              </div>
            </div>
            <SportsNotifications
              notification={notifications['promotions']}
              updateHandleAllAmoutPerSport={updateHandleAllAmoutPerSport}
              updateSportsNotifications={updateSportsNotifications}
              updateTournamentsNotifications={updateTournamentsNotifications}
              updateEntryNotifications={updateEntryNotifications}
              updateGameTypeNotifications={updateGameTypeNotifications}
              sportsList={sportsList}
            />
          </div>
        </div>
        {/*<div className={ `notification-sport-item` }>
          <NotificationItemFormsy
            defaultValue={ notifications[2].enabled }
            inputHandler={ updateSwitchData }
            label={ notifications[2].label }
            gameType='pool'
            name={ notifications[2].name } 
            required
            type={ notifications[2].type } 
          />
        </div>
        */}
      </div>
    );
  } else {
    return (
      <div className="flex-container justify align">
        <i className="ion-load-a loading"></i>
      </div>
    );
  }
};

export default FormularioNotifications;
