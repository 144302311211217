import { withFormsy } from 'formsy-react';
import React from 'react';
import styled from 'styled-components'
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl as FormControlBase,
  Popover,
  OverlayTrigger,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  HelpBlock,
} from 'react-bootstrap/lib';
import '../../assets/stylesheets/overrides/range-slider.scss';
import Slider from 'react-rangeslider';

import moment from 'moment';
import $ from 'jquery';
import _ from 'underscore';

import futbol from '../../assets/images/FutbolBall-50.png';
import americano from '../../assets/images/AmericanoBall-50.png';
import hockey from '../../assets/images/Hockey.png';
import golf from '../../assets/images/GolfBall-50.png';
import f1 from '../../assets/images/Volante-50.png';

const FormControl = styled(FormControlBase)`
  padding-left: 0.6em !important;
`

/**
 *This function render the popUp of reglas that is attached into the
 *Scoreboard
 */
export const popoverQueEsStrike = (
  <Popover id="reglas-popover">
    <div className="flex-col">
      <strong className="flex-row row-center popover-title text-center">
        ¿Que son los strikes?
      </strong>
      <div className="flex-row row-center ">
        En caso de escoger Survivor con "strikes", si el equipo que elegiste empata no serás
        eliminado hasta que llegues al límite de strikes permitidos.
        <br />
        Los empates no eliminarán al participante.
      </div>
    </div>
  </Popover>
);
export const popoverMaxStrikes = (
  <Popover id="reglas-popover" title="Max Strikes">
    <div className="flex-col">
      ¿Número máximo de strikes?
      <br />
      <small>
        <strong>Máximo strikes</strong>, Los participantes únicamente contrarán con estos strikes
        antes de ser eliminados. Al acumular este número de strikes, serán eliminados.
      </small>
    </div>
  </Popover>
);

class Vertical extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: this.props.defaultValue !== null ? this.props.defaultValue : 0,
    };
    this.handleChange = (value) => {
      this.props.handlePorcentajeSemanal(value);
      this.setState({
        value: value,
      });
    };
  }

  render() {
    const { value } = this.state;
    return (
      <div className="slider-horizontal">
        <div className="value">{`${value}%`}</div>
        <Slider
          min={0}
          max={100}
          value={value}
          orientation="horizontal"
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

class CrearQuinielaFormsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changeValue = (event) => {
      // console.log('event',event);
      if (!event.currentTarget) {
        this.props.setValue(event.value);
      } else {
        this.props.setValue(event.currentTarget.value);
      }
    };
  }

  render() {
    let conditionalComponent = null;
    const errorMessage = this.props.getErrorMessage();
    let helpMessages =
      this.props.help !== undefined
        ? this.props.help.map((help, i) => <HelpBlock style={{ fontSize: '0.8em'}} key={i}>{help}</HelpBlock>)
        : null;
    switch (this.props.type) {
      case 'TEXT':
        conditionalComponent = (
          <div className="flex-col">
            <FormGroup controlId={this.props.name}>
              <ControlLabel style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <FormControl
                type="text"
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || ''}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              ></FormControl>
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  defaultValue={this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                >
                  {this.props.deportesAvailable}
                </ToggleButtonGroup>
              </ButtonToolbar>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'NAME':
        conditionalComponent = (
          <div className="flex-col">
            <FormGroup controlId={this.props.name}>
              <ControlLabel style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <FormControl
                type="text"
                pattern="[A-Za-z0-9\s]*"
                onBlur={() => {
                  this.props.checkAvailability();
                }}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || ''}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'ENTRY_FEE':
        let valueDefault = this.props.defaultValue;
        conditionalComponent = (
          <div className="flex-container column ">
            <FormGroup controlId={this.props.name}>
              <ControlLabel style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row row-no-padding input-container">
                <div className="flex-col col-10" style={{ minWidth: '2em' }}>
                  <span className="input-group-addon">$</span>
                </div>
                <FormControl
                  className="flex-col "
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="number"
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                ></FormControl>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'STRIKES_ENABLED':
        conditionalComponent = (
          <div className="flex-col col-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <div className="flex-row ">
                <ControlLabel className="" style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>{' '}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="top"
                  overlay={popoverQueEsStrike}
                >
                  <i className="ion-help-circled " style={{ marginLeft: '5px' }} />
                </OverlayTrigger>
              </div>
              <div className="flex-row">
                <ButtonToolbar className="">
                  <ToggleButtonGroup
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    name="strikesEnabled"
                    style={{ gap: '0.3em' }}
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value={true}
                    >
                      Con strikes
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value={false}
                    >
                      Sin strikes
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'NUM_STRIKES':
        conditionalComponent = (
          <div className="flex-container column ">
            <FormGroup controlId={this.props.name}>
              <ControlLabel style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row row-no-padding input-container">
                <div className="flex-col col-10" style={{ minWidth: '2em' }}>
                  <span className="input-group-addon">
                    {' '}
                    <i className="ion-record" />
                  </span>
                </div>
                <FormControl
                  className="flex-col "
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="number"
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                ></FormControl>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'COMISSION_STATUS':
        conditionalComponent = (
          <div className="flex-container">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row row-no-padding ">{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    name="useTies"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={'true'}
                    >
                      Si
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={'false'}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'COMISSION_FEE':
        conditionalComponent = (
          <div className="flex-container column ">
            <FormGroup controlId={this.props.name}>
              <ControlLabel style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row row-no-padding input-container">
                <FormControl
                  className="flex-col "
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={this.props.getValue() || ''}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                ></FormControl>
                <div className="flex-col col-10" style={{ minWidth: '2em' }}>
                  <span className="input-group-addon">%</span>
                </div>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'MAX_MULTIENTRIES':
        let defaultMax = this.props.defaultValue;
        conditionalComponent = (
          <div className="flex-container column ">
            <FormGroup controlId="gameEntry">
              <ControlLabel style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row row-no-padding input-container">
                <div className="flex-col col-10" style={{ minWidth: '2em' }}>
                  <span className="input-group-addon">#</span>
                </div>
                <FormControl
                  className="flex-col "
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="number"
                  min="1"
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                ></FormControl>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'SPORT':
        if (!this.props.deportesTorneos) {
          return null;
        }
        // let deportesAvailable = this.props.deportesTorneos;
        const sportsButtons = _.map(this.props.deportesTorneos, (deportes, deporteIdx) => {
          let icon = null;
          switch (deportes.id) {
            case 1:
              icon = <img key={deportes.id} src={futbol} />;
              break;
            case 2:
              icon = <img key={deportes.id} src={americano} />;
              break;
            case 6:
              icon = <img key={deportes.id} src={golf} />;
              break;
            case 7:
              icon = <img key={deportes.id} src={f1} />;
              break;
            case 9:
              icon = <img key={deportes.id} src={hockey} />;
              break;
            default:
              icon = null;
              break;
          }
          return (
            <ToggleButton key={deportes.id} value={deportes.id} className="btn-sport-wizard">
              {icon}
            </ToggleButton>
          );
        });
        // console.log('sportsButtons',sportsButtons );
        conditionalComponent = (
          <div className=" wizard-sports-toggle flex-col">
            <FormGroup controlId={this.props.name}>
              <ControlLabel style={{ display: 'flex', justifyContent: 'center', lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <ButtonToolbar className="sports-wizard-selector">
                <ToggleButtonGroup
                  type="radio"
                  style={{ gap: '0.6em', display: 'flex', justifyContent: 'center' }}
                  defaultValue={this.props.defaultValue}
                  name="sportOptions"
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                >
                  {sportsButtons}
                </ToggleButtonGroup>
              </ButtonToolbar>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'POINT_FORMAT':
        conditionalComponent = (
          <div className="flex-col col-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row">{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    name="pointFormat"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value={'line'}
                    >
                      Con línea
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value={'natural'}
                    >
                      Sin línea
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'GAME_LENGTH':
        conditionalComponent = (
          <div className="flex-row">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row"  style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    style={{ gap: '0.6em' }}
                    defaultValue={this.props.defaultValue}
                    name="gameLength"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value={'week'}
                    >
                      Próxima jornada
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value={'full'}
                    >
                      Temporada completa
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'AUTO_PICK':
        conditionalComponent = (
          <div className="flex-row">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row" style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              {this.props.small && (
                <div style={{ fontSize: '0.75em', lineHeight: 1.7 }}>{this.props.small}</div>
              )}
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    style={{ gap: '0.6em' }}
                    defaultValue={this.props.defaultValue}
                    name="gameLength"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={true}
                    >
                      Si
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={false}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'LIMIT_MULTIENTRIES':
        // console.log('LIMIT_MULTIENTRIES');
        // console.log(this.props.defaultValue);
        conditionalComponent = (
          <div className="flex-row row-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row" style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    style={{ gap: '0.6em' }}
                    defaultValue={this.props.getValue() || this.props.defaultValue}
                    name="limitMultientries"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={true}
                    >
                      Si
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={false}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'USE_TIES':
        conditionalComponent = (
          <div className="flex-row">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row row-no-padding " style={{ lineHeight: 2, paddingBottom: '0.2em' }}>{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    style={{ gap: '0.6em' }}
                    defaultValue={this.props.defaultValue}
                    name="useTies"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={'true'}
                    >
                      Si
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={'false'}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'WEEKLY_PRIZES':
        conditionalComponent = (
          <div className="flex-container">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row row-no-padding ">{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    name="useTies"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={true}
                    >
                      Si
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format rounded font-chivo"
                      value={false}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'TIE_TYPE':
        conditionalComponent = (
          <div className="flex-container">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row">{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    name="tieType"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value="new"
                    >
                      2 Desempates
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value="old"
                    >
                      3 Desempates
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'WEEKLY_PERCENTAGE':
        conditionalComponent = (
          <div className="flex-container">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row">{this.props.label}</ControlLabel>
              <div className="flex-row">
                <Vertical
                  defaultValue={this.props.defaultValue}
                  handlePorcentajeSemanal={this.props.inputHandler}
                  changeValue={this.changeValue}
                />
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'WEEKLY_PRIZE_SCHEME':
        conditionalComponent = (
          <div className="flex-container">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row" style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    name="gameLength"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value="first"
                    >
                      1er Lugar
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value="top"
                    >
                      Top 3
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'PRIZE_SCHEME':
        conditionalComponent = (
          <div className="flex-col">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row"  style={{ lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="radio"
                    style={{ gap: '0.6em' }}
                    defaultValue={this.props.defaultValue}
                    name="gameLength"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value="dynamic"
                    >
                      Automáticamente
                    </ToggleButton>
                    <ToggleButton
                      defaultValue={this.props.defaultValue}
                      className="btn-point-format"
                      value="custom"
                    >
                      Personalizado
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'PRIZES_LIST':
        conditionalComponent = (
          <div className="flex-col">
            <div className="flex-row">
              <FormGroup className="flex-col" controlId={this.props.name}>
                <ControlLabel className="flex-col ">{this.props.label}</ControlLabel>
                <div className="flex-row row-no-padding input-container">
                  <FormControl
                    className="flex-col "
                    type="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    minLength={this.props.minLength}
                    maxLength={this.props.maxLength}
                    value={this.props.getValue() || this.props.defaultValue}
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  ></FormControl>
                  <div className="flex-col col-10" style={{ minWidth: '2em' }}>
                    <span className="input-group-addon">
                      <i className="ion-ribbon-b"></i>
                    </span>
                  </div>
                </div>
              </FormGroup>
            </div>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );

        break;
      case 'PRIZES_CUSTOM':
        let options = [];
        let maxPrizes = 100;
        if (!this.props.defaultValue) {
          // console.log('default value not exist');
          maxPrizes = this.props.setMaxPrizeQnty(
            this.props.prizesSum,
            this.props.listPrizes.length
          );
        } else if (this.props.defaultValue > 0 && this.props.prizesSum === 100) {
          // console.log('default value exist and priesSum is 100');
          maxPrizes = this.props.defaultValue;
        } else if (this.props.defaultValue > 0 && this.props.prizesSum !== 100) {
          if (this.props.defaultValue <= this.props.prizesSum) {
            // console.log('default value exist and is minor or equal than prizesSim ')
            if (
              this.props.defaultValue <
              this.props.setMaxPrizeQnty(this.props.prizesSum, this.props.listPrizes.length)
            ) {
              // console.log('default value exist and is minor than max ', this.props.defaultValue,  this.props.setMaxPrizeQnty( this.props.prizesSum, this.props.listPrizes.length) );
              maxPrizes = this.props.setMaxPrizeQnty(
                this.props.prizesSum,
                this.props.listPrizes.length
              );
            } else {
              // console.log('default value exist and is greateror equal than max ', this.props.defaultValue,  this.props.setMaxPrizeQnty( this.props.prizesSum, this.props.listPrizes.length) );
              maxPrizes =
                this.props.defaultValue +
                this.props.setMaxPrizeQnty(this.props.prizesSum, this.props.listPrizes.length);
            }
          } else {
            // console.log('default value exist and is minor than max ', this.props.defaultValue,  this.props.setMaxPrizeQnty( this.props.prizesSum, this.props.listPrizes.length) );
            maxPrizes = this.props.setMaxPrizeQnty(
              this.props.prizesSum,
              this.props.listPrizes.length
            );
          }
        }
        // console.log('Real maxPrizes', maxPrizes);
        for (let i = maxPrizes; i >= 0; i--) {
          options.push(i);
        }
        // options.sort(-1);
        conditionalComponent = (
          <div className="flex-col col-no-padding">
            <FormGroup
              className="flex-col col-no-padding point-format-wizard"
              controlId={this.props.name}
            >
              {/* <ControlLabel className='flex-row'>{this.props.label}</ControlLabel> */}
              <div className="flex-row row-no-padding input-container">
                <FormControl
                  componentClass="select"
                  value={this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e, this.props.prizeId);
                  }}
                >
                  <option value="0">%</option>
                  {options.map((opt, i) => {
                    return (
                      <option key={i} value={opt}>
                        {opt + '%'}
                      </option>
                    );
                  })}
                </FormControl>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'SELECT_WEEK':
        const listWeeks = this.props.weeks.map((jornada) => {
          return (
            <ToggleButton
              defaultValue={this.props.defaultValue}
              className={`jornadaItem btn-point-format flex-col ${
                $(window).width() < 669 ? 'col-30' : 'col-20'
                }`}
              value={jornada.id}
              key={jornada.id}
            >
              <div className="flex-col col-no-padding  titulo ">
                <strong className="numJornada flex-container justify align">
                  { [6,7].includes(this.props.sportId) ? jornada.abbreviation : jornada.week_name }
                </strong>
                <span className="fechaJornada flex-col col-no-padding">
                  <span
                    className="flex-container justify align fecha "
                    style={{ textTransform: 'capitalize' }}
                  >
                    {moment(jornada.start_date).format('DD/MM')}
                  </span>
                </span>
              </div>
            </ToggleButton>
          );
        });

        conditionalComponent = (
          <div className="flex-container">
            <FormGroup className="flex-col point-format-wizard" controlId={this.props.name}>
              <ControlLabel className="flex-row" style={{ display: 'flex', justifyContent: 'center', lineHeight: 2 }}>{this.props.label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    className="flex-container justify align wrap"
                    type="radio"
                    defaultValue={this.props.defaultValue}
                    style={{ gap: '0.3em'}}
                    name="tieType"
                    onChange={(e) => {
                      this.changeValue(e);
                      this.props.inputHandler(e);
                    }}
                  >
                    {listWeeks}
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      default:
        conditionalComponent = null;
        break;
    }
    return conditionalComponent;
  }
}

export default withFormsy(CrearQuinielaFormsy);
