import React, { createContext, useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { isbot } from 'isbot'

// Hooks
import useFingerprint from '../hooks/useFingerprint'
import useLocalStorage from '../hooks/useLocalStorage'

// Utils
import utils, { readyToVerify } from '../utils/utils'
import API from '../utils/api'
import { isValidToken, validateUserSessions, logoutSession, setSession } from '../utils/session'
import history from '../routes/history'
import gaUtils from '../components/GAWrapper/GAWrapper'
import { publish, subscribe, unsubscribe } from '../utils/events'
import branchUtils from '../utils/branchUtils'
import sleep from '../Xmerald-UI/utilities/sleep'

export const LOGIN_STATUS = {
  LOGIN_FORM: 'LOGIN_FORM',
  SIGNUP_FORM: 'SIGNUP_FORM',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  COMPLETE_FACEBOOK_DATA: 'COMPLETE_FACEBOOK_DATA',
  CLOSE_ANOTHER_SESSIONS: 'CLOSE_ANOTHER_SESSIONS',
  SMS_CONFIRMATION: 'SMS_CONFIRMATION',
  REDIRECTING: 'REDIRECTING',
  REDIRECTING_PREV_URL: 'REDIRECTING_PREV_URL',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  WELCOME_SUCCESS: 'WELCOME_SUCCESS'
}

const getStartAuth = () => {
  try {
    return isValidToken(utils.getToken())
  } catch (error) {}

  return false
}

const initialState = {
  // General Emum status is used for checking
  // The login step, if is null the modal is hidden
  loginStatus: null,

  // Bookean when a request is fetching
  // is user for enable or disable buttons
  submitting: false,

  // If the login request failed
  isFailedLogin: false,

  // User data when login success
  userData: null,

  // If facebook login failed
  fbFailed: null,

  // The facebook oAuth response
  fbData: null,

  // Boolean for checking if is logged
  isAuthenticated: getStartAuth(),

  // If all auth proces is completed, util
  // When user reload the page
  isInitialized: false,

  // Used when the user has more active session
  // this is the response from the API
  sessionsData: null,

  isLoginSuccess: false,
  isLoginFacebookSuccess: false,

  // Bolean used for show button for close another
  // active session, if failed is used for show message
  closeSessionsStatus: true,

  // If session was expired or token is invalid
  // Util for show expired notification
  isSessionExpired: false,

  readyToVerify: readyToVerify(),

  jwt: null
}

const AuthContext = createContext(initialState)

AuthProvider.propTypes = {
  children: PropTypes.node
}


// const MUNDIAL_KEY = 'has-seen-world-landing'
// const MUNDIAL_LANDING_URL = 'https://www.pickwin.mx/mkt/mundial'

function AuthProvider ({ children }) {
  const fingerPrint = useFingerprint()
  const [user, setUser] = useState(utils.getUserInfo())
  const token = utils.getToken()
  const isTokenOK = isValidToken(token)
  const [state, setBaseState] = useState(initialState)
  const [isAuth, setIsAuth] = useLocalStorage('isAuth', false)

  const setState = (state) => setBaseState(prevState => ({
    ...prevState,
    ...state
  }))

  useEffect(() => {
    const isLogged = utils.isLogged()
    const  hasSeenLanding = JSON.parse(
      window.localStorage.getItem('hasSeenLanding')
    )

    const isBot = isbot(navigator.userAgent)

    const searchParams = new URLSearchParams(location.href)

    // Detects if user came from branck link
    const isFromBranch = Boolean(
      window.location.href.includes('.app.link') ||
      document.referrer.includes('.app.link') ||
      searchParams.get('_branch_referrer') ||
      searchParams.get('_branch_match_id')
    )

    const blackList = [
      'legal/terminos',
      'legal/privacidad'
    ]

    const notRedirect = blackList.some(path => window.location.href.includes(path))

    // Redirect to landing only first time and if user is not logged
    if (!isLogged && !hasSeenLanding && !isBot && !isFromBranch && !notRedirect) {
      // Redirect
      window.location.assign('/landing' + window.location.search)
      window.localStorage.setItem('hasSeenLanding', true)
    }
  }, [])

  useLayoutEffect(() => {
    initialize()
    setState({
      isInitialized: true,
      closeSessionsStatus: true,
      fbData: null
    })

    setIsAuth(state.isAuthenticated)
  }, [state.isAuthenticated])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname === '/' && user && !user.active) {
        return redirectIfBannedUser()
      }
      validateUserSessions()
    })

    return unlisten
  }, [])

  useEffect(() => {
    // 🚩 Will migrated to context
    subscribe('openLoginModal', openLoginModal)
    subscribe('openSignupModal', openSignupModal)
    subscribe('closeModal', closeModal)
    subscribe('logoutExpired', logoutExpired)
    subscribe('logout', logout)

    return () => {
      unsubscribe('openLoginModal')
      unsubscribe('openSignupModal')
      unsubscribe('closeModal')
      unsubscribe('logoutExpired')
      unsubscribe('logout', logout)
    }
  }, [])

  const noop = () => {}

  useEffect(() => {
    API.subscribeSocketLiveLobby(
      noop,
      noop,
      noop,
      noop,
      async () => {
        if (isAuth) {
          // Wait to enable other session to check if is needed inactive session here
          await sleep(1000)

          API.checkOtherSessionsUser().then((response) => {
            // User has active sessions, redirect to
            // another page to close active sessions
            if (window.location.pathname !== '/close-sessions') {
              if (response && response.data.active_sessions) {
                window.localStorage.setItem('redirectAfterLogin', window.location.pathname)
                window.location = '/close-sessions'
              }
            }
          })
        }
      }
    );

    return () => {
      API.unSubscribeSocketLobby()
    }
  }, [isAuth])

  useEffect(() => {
    const interval = setInterval(() => {
      const isTokenExpired = utils.isTokenExpired(utils.getToken())
      if (isTokenExpired && state.isAuthenticated) {
        logoutExpired()
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [state.isAuthenticated])

  const initialize = async () => {
    if (!token) return

    // If sesson has expired then logout and show the
    // notitification expired and redirect to login
    if (!isTokenOK) return logoutExpired()

    if (!user) return

    // 1 - Check if user is banned
    if (redirectIfBannedUser()) return

    // 2 - Pendig SMS Verification
    // if (!user.identity_verified) {
    //   // submitting: null,
    //   return setState({ loginStatus: LOGIN_STATUS.SMS_CONFIRMATION })
    // }

    // 3 - Set Context user
    setUser(user)
    refreshUserData()
    setState({ isAuthenticated: true })

    // 4 - Set session (axios common headers)
    setSession(token)
  }

  const refreshUserData = async () => {
    try {
      const responseUserData = await API.fetchUserData()
      setState({
        userData: responseUserData
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  function redirectIfBannedUser () {
    if (user && !user.active) {
      return window.location.replace('/validar-usuario')
    }
  }

  const logout = async (redirectToHome = true) => {
    try {
      await API.postLogoutData()
    } catch (error) {}
    setState({ isAuthenticated: false })
    logoutSession()

    if (redirectToHome) {
      window.location.replace('/')
    } else {
      window.location.reload()
    }
  }

  const logoutExpired = async () => {
    // Caching the prev route
    chachePrevPath()
    // try {
    //   await API.postLogoutData()
    // } catch (error) {}
    logoutSession()
    setState({
      ...initialState,
      isSessionExpired: true,
      isAuthenticated: false,
      loginStatus: LOGIN_STATUS.LOGIN_FORM
    })
  }

  const redirectAfterSuccess = () => {
    // Is the stored data for redirection
    const notLoggedData = utils.getDataNotLoggedUser()

    setState({
      // submitting: false,
      isFailedLogin: false,
      // loginStatus: null,
      notLoggedData
    })

    utils.removeDataNotLoggedUser()
  }

  const sendCredentials = async (username, password, callback) => {
    if (callback) {
      callbackCache = callback
    }
    // Username and password are saved for retry if failed
    setState({ submitting: 'email-loading', username, password })
    closeSessionExpired()

    try {
      // First we check if cretentials are correct and if user
      // haven't another active session on another browser or tab
      const response = await API.postLoginData(username, password, fingerPrint)

      utils.checkAxiosAllErrors([response])
      if (!response || !response.data.success) {
        // If user has more active sessions on another tab or browser
        if (response && response.data.code === 0) {
          return setState({
            submitting: false,
            isFailedLogin: true,
            sessionsData: response.data,
            loginStatus: LOGIN_STATUS.CLOSE_ANOTHER_SESSIONS
          })
        }

        // Invalid credentials
        return setState({
          submitting: false,
          isFailedLogin: true,
          loginStatus: LOGIN_STATUS.INVALID_CREDENTIALS
        })
      }

      // If all is ok we get the user data
      const responseUserData = await API.fetchUserData()

      gaUtils.trackUserId(responseUserData.data.user.id)
      gaUtils.setEnhancedUserData(responseUserData.data.user)
      gaUtils.trackUserLogin()

      // const isVerified = utils.isVerified(responseUserData.data.user)
      // if (!isVerified) return setState({
      //   loginStatus: null,
      //   isLoginSuccess: true,
      //   isAuthenticated: true,
      //   userData: responseUserData,
      //   submitting: null,
      //   // Open SMS verification
      //   // loginStatus: LOGIN_STATUS.SMS_CONFIRMATION,
      // })

      publish('checkPopupBonuses')

      // If all is OK
      setState({
        // loginStatus: null,
        isLoginSuccess: true,
        isAuthenticated: true,
        userData: responseUserData,
        jwt: response.data.jwtoken
      })
      utils.setUserInfo(responseUserData.data)
      publish('updateUserInfo', responseUserData.data)
      setSession(response.data.jwtoken)
      redirectAfterSuccess()
    } catch (error) {
      console.error(error)
      // If credentials are invalid or the
      // enpoint is not available
      setState({
        submitting: false,
        isFailedLogin: true,
        // loginStatus: LOGIN_STATUS.INVALID_CREDENTIALS
      })
    }
  }

  // Is used for login and Facebook Signup
  const responseFacebook = async (fbResponse, callback) => {
    if (callback) {
      callbackCache = callback
    }

    // If the response from facebook is not valid
    if (!fbResponse.userID && !fbResponse.accessToken) {
      return console.error('response dont include userId and token')
    }

    setState({ submitting: 'facebook-loading' })
    closeSessionExpired()

    try {
      const res = await API.postFacebookLogin(fbResponse.userID, fbResponse.accessToken)

      // If failed facebook post login
      if (res instanceof Error || !res.data) {
        return setState({
          fbFailed: true,
          loginStatus: LOGIN_STATUS.COMPLETE_FACEBOOK_DATA,
          fbData: fbResponse,
          // submitting: false
        })
      }

      // Close another active sessions
      if (res.data.code === 0 && res.data.sessions.length > 0) {
        return setState({
          submitting: false,
          isFailedLogin: true,
          sessionsData: res.data,
          loginStatus: LOGIN_STATUS.CLOSE_ANOTHER_SESSIONS,
          fbResponse
        })
      }

      // Login success
      gaUtils.trackUserId(res.data.user.id)
      gaUtils.setEnhancedUserData(res.data.user)
      gaUtils.trackUserLogin()
      // const isVerified = utils.isVerified(res.data.user)

      // if (!isVerified) return setState({
      //   submitting: false,
      //   isFailedLogin: false,
      //   isAuthenticated: true,
      //   isLoginFacebookSuccess: true,
      //   submitting: null,
      //   // Shows verification modal
      //   // loginStatus: LOGIN_STATUS.SMS_CONFIRMATION,
      //   userData: res
      // })

      publish('checkPopupBonuses')

      setState({
        submitting: false,
        isFailedLogin: false,
        isAuthenticated: true,
        isLoginFacebookSuccess: true,
        jwt: res.data.jwtoken,
        // Clean or close modal
        // loginStatus: null,
        userData: res
      })

      redirectAfterSuccess()
      utils.setUserInfo(res.data)
      publish('updateUserInfo', res.data)

      callbackCache(res.data)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const handleModalSessions = () => {
    setState({
      submitting: true,
      isFailedLogin: false,
      loginStatus: LOGIN_STATUS.LOGIN_FORM
    })

    if (state.fbResponse) {
      responseFacebook(state.fbResponse)
    } else {
      sendCredentials(state.username, state.password)
    }
  }

  const onCloseAllSessionsClicked = async () => {
    const { token } = state.sessionsData
    setState({ submitting: true })

    try {
      const response = await API.closeAllSessions(token)
      if (response.data.success) {
        handleModalSessions()
      } else {
        setState({ closeSessionsStatus: false })
      }
    } catch (error) {
      setState({ closeSessionsStatus: false })
    }
  }

  // Signup Email
  const signupEmail = (data, callback) => {
    setState({ submitting: 'email-loading' })
    if (callback) {
      callbackCache = callback
    }

    API.postRegister(data)
      .then((response) => {
        if (response.data !== undefined && response.data.success) {
          // Success
          gaUtils.trackSuccessfullRegister();
          gaUtils.trackUserId(response.data.user.id);
          gaUtils.setEnhancedUserData(response.data.user)

          branchUtils.setUserId(response.data.user.id);
          branchUtils.sendEvent('COMPLETE_REGISTRATION', {});

          utils.setShowGameFreeRoll(0, true);
          utils.setUserInfo(response.data);
          utils.setToken(response.data.jwtoken);

          setUser(response.data.user);
          setState({ isAuthenticated: true });

          publish('checkPopupBonuses');

          setState({
            isAuthenticated: true,
            // loginStatus: null,
            // submitting: null,
            // loginStatus: LOGIN_STATUS.SMS_CONFIRMATION,
            jwt: response.data.jwtoken
          });

          // Set session (axios common headers)
          setSession(response.data.jwtoken);
          publish('updateUserInfo', response.data);

          callbackCache(response);
        } else {
          Sentry.captureException(new Error(JSON.stringify(response.data.errors)), {
            extra: 'Error In registro form'
          })
          setState({ submitting: false })
          callbackCache(response)
        }
      })
      .catch((error) => {
        Sentry.captureException(error, { extra: 'Error Posting Register' })
      })
  }

  const postFacebookRegister = async (formData, callback) => {
    setState({ submitting: 'facebook-loading' })
    try {
      const response = await API.postFacebookRegister(formData)
      if (response.data.success) {

        gaUtils.trackSuccessfullRegister();
        gaUtils.trackUserId(response.data.user.id);
        gaUtils.setEnhancedUserData(response.data.user)

        branchUtils.setUserId(response.data.user.id);
        branchUtils.sendEvent('COMPLETE_REGISTRATION', {});

        // Signup Success
        utils.setUserInfo(response.data);
        setUser(response.data.user);
        setState({
          isAuthenticated: true,
          // submitting: null,
          // loginStatus: LOGIN_STATUS.SMS_CONFIRMATION,
          // loginStatus: null,
          jwt: response.data.jwtoken
        });
        setSession(response.data.jwtoken);
        publish('updateUserInfo', response.data);
        publish('checkPopupBonuses');
      } else {
        const errors = response.data.errors
        callback(errors)
      }
    } catch (error) {}
    setState({ submitting: false })
  }

  const openLoginModal = () => {
    setState({
      loginStatus: LOGIN_STATUS.LOGIN_FORM
    })

    chachePrevPath()
  }

  const openSignupModal =  () => {
    setState({
      loginStatus: LOGIN_STATUS.SIGNUP_FORM
    })

    chachePrevPath()
  }

  const openForgotPasswordModal = () => {
    setState({ loginStatus: LOGIN_STATUS.FORGOT_PASSWORD })
  }

  const closeModal = () => {
    // This list is for prevent close modal
    const holdModalList = [
      LOGIN_STATUS.SMS_CONFIRMATION,
      LOGIN_STATUS.COMPLETE_FACEBOOK_DATA
    ]

    const availableToClose = !holdModalList.find(status => status === state.loginStatus)

    if (availableToClose){
      setState({ loginStatus: null, closeSessionsStatus: true })
    }
  }

  const chachePrevPath = () => {
    // Help us to cache the current route for make redirections
    // after login if is need it, is revalidated with timeout
    // for another async modals that change the path such poolslist
    let pathCached = window.location.href

    localStorage.setItem('redirectAfterLogin', pathCached)
  }


  const closeSessionExpired = () => {
    setState({ isSessionExpired: false })
  }

  return (
    <AuthContext.Provider
      value={{
        initialize,
        logout,
        user,
        setUser,
        isAuth,
        fingerPrint,
        sendCredentials,
        responseFacebook,
        handleModalSessions,
        onCloseAllSessionsClicked,
        redirectAfterSuccess,
        closeSessionExpired,
        logoutExpired,
        // Signup
        signupEmail,
        openLoginModal,
        openSignupModal,
        openForgotPasswordModal,
        closeModal,
        postFacebookRegister,
        ...state
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

let callbackCache = () => {}

export { AuthProvider, AuthContext }
