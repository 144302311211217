import { withFormsy } from 'formsy-react';
import React from 'react';
import cvvHelper from '../../assets/images/cvvHelper.png';
import {
  Form,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  Popover,
  OverlayTrigger,
  HelpBlock,
} from 'react-bootstrap/lib';
import MediaQuery from 'react-responsive';

class BuscarQuinielaFormsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changeValue = (event) => {
      this.props.setValue(event.currentTarget.value);
    };
  }

  render() {
    let conditionalComponent = null;
    const errorMessage = this.props.getErrorMessage();
    let helpMessages =
      this.props.help !== undefined
        ? this.props.help.map((help, i) => <HelpBlock key={i}>{help}</HelpBlock>)
        : null;

    switch (this.props.type) {
      case 'TEXT':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup controlId={this.props.name}>
              <ControlLabel>{this.props.label}</ControlLabel>
              <FormControl
                type="text"
                // onBlur={ () => { this.props.getAvailabilityName( ); } }
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || ''}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'PASSWORD':
        conditionalComponent = (
          <div className="col-xs-12">
            <FormGroup controlId={this.props.name}>
              <ControlLabel>{this.props.label}</ControlLabel>
              <FormControl
                type="text"
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || ''}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
    }
    return conditionalComponent;
  }
}

export default withFormsy(BuscarQuinielaFormsy);
