
function animateTitleBase () {
  const TITLES = [
    'Pickwin.mx - Apuestas, casino',
    'Pickwin.mx - Casino y deportes',
    'Pickwin.mx - Bono bienvenida',
    'Pickwin.mx - Bono depósito',
    'Pickwin.mx - Apuestas deportivas',
    'Pickwin.mx - Casino, ruleta, blackjack en línea',
    'Pickwin.mx - Juegos de casino y maquinas tragamonedas'
  ]

  let currentIndex = 0

  setInterval(() => {
    if (currentIndex + 1 === TITLES.length) {
      currentIndex = 0
    } else {
      currentIndex++
    }

    document.title = TITLES[currentIndex]
  }, 2500)

}

animateTitleBase()
