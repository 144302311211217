import React from 'react';
import TransferFormsy from './TransferFormsy';
import Formsy from 'formsy-react';
import {
  Button,
  Modal,
} from 'react-bootstrap/lib';
import API from '../../utils/api';
import numeral from 'numeral';
import avatar from '../../assets/images/avatar-100.png';
import cash from '../../assets/images/cash1-50.png';

const ConfirmationTransfer = ({
  successfullTransaction,
  submitTransfer,
  closeConfirmation,
  show,
  error,
  errorMessage,
  userToTransfer,
  amountToTransfer,
  submitting,
}) => {
  if (!show) {
    return null;
  }
  return (
    <Modal
      keyboard={true}
      bsSize="small"
      backdropClassName="confirmationTransferBackdrop"
      show={show}
      onHide={closeConfirmation}
    >
      <div className="transactionPanel panel-pickwin">
        <div id="panelTitle" className={`panel-abonar-titulo ${error ? 'failed' : ''}`}>
          <span>{error ? 'ERROR' : 'CONFIRMA LOS DATOS DE TU TRANSFERENCIA'}</span>
          <button className="cerrar-panel" onClick={closeConfirmation}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty"></i>
            </div>
          </button>
        </div>
        <div className="exchangeContainer col-xs-10 col-xs-offset-1">
          {successfullTransaction ? (
            <div className="flex-container column justify align">
              <i className="ion-checkmark-circled success"></i>
              <div className="flex-col col-center text-center">
                Tú transferencia se completó con éxito.
              </div>
            </div>
          ) : error ? (
            <div className="flex-container column justify align">
              <i className="ion-alert-circled alert"></i>
              <div className="flex-col col-center text-center">
                Lo sentimos encontramos un error:
              </div>
              <div
                className="flex-col col-center text-center"
                style={{ marginBottom: '10px', fontWeight: 'bold' }}
              >
                {errorMessage}
              </div>
            </div>
          ) : (
                <div className="flex-container column">
                  <div className="flex-row ">
                    <div className="flex-col">
                      <div className="nota ">
                        Por favor asegurate que los datos del usuario al que le vas a transferir saldo
                        sean correctos.
                  </div>
                      <div className="nota ">
                        Las transferencias de saldo son finales e irreversibles.
                  </div>
                      <div className="flex-row" style={{ margin: ' 10px 0' }}>
                        <div className="flex-container justify align">
                          <img
                            src={
                              !userToTransfer.avatar
                                ? avatar
                                : userToTransfer.avatar_dir + userToTransfer.avatar
                            }
                            className="transfer-avatar"
                            alt=""
                          />
                        </div>
                        <div className="flex-col col-center">
                          <div className="flex-container justify align " style={{ fontSize: '1.2em' }}>
                            {userToTransfer.nick}
                          </div>
                          <div className="balance real flex-container justify align ">
                            <img src={cash} className="balance-icons" alt="" />
                            <span className="balance-real">
                              {numeral(amountToTransfer).format('$0,000.00')} MXN
                        </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-container align justify">
                    Este monto pasará a ser saldo no retirable en la cuenta que reciba la transferencia.
              </div>
                  <div className="flex-row btn-wrap">
                    <Button className="btn-rojo flex-col col-40 col-5" onClick={closeConfirmation}>
                      {' '}
                  CANCELAR{' '}
                    </Button>
                    <Button
                      className="btn-pick flex-col col-40 col-offset-10"
                      disbaled={submitting}
                      onClick={submitTransfer}
                    >
                      {' '}
                      {!submitting ? 'TRANSFERIR' : <i className="ion-load-a"></i>}{' '}
                    </Button>
                  </div>
                </div>
              )}
        </div>
      </div>
    </Modal>
  );
};

class TransferFormulario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      isReady: false,
      userToTransfer: null,
      canSubmit: false,
      showConfirmation: false,
      showResponse: false,
      successfullTransaction: false,
      errorTransfer: false,
      errorMessage: '',
      errors: null,
      transferModel: { nickToTransfer: '', amountToTransfer: 0, userPassword: '' },
    };
    this.resetForm = () => {
      this.setState({
        userToTransfer: null,
        showConfirmation: false,
        showResponse: false,
        successfullTransaction: false,
        errorTransfer: false,
        errorMessage: '',
        errors: null,
        // transferModel : { nickToTransfer: '', amountToTransfer: 0, userPassword: ''}
      });
    };

    this.submitTransfer = () => {
      this.setState({ submitting: true });

      let transferObject = {
        nick: this.state.transferModel.nickToTransfer,
        amount: this.state.transferModel.amountToTransfer,
        password: this.state.transferModel.userPassword,
        user: { id: this.state.userToTransfer.id },
      };

      console.log(transferObject);

      API.postTransfer(transferObject).then((response) => {
        this.callbackTransfer(response.data);
      });
    };

    this.callbackTransfer = (data) => {
      console.log(data);
      if (data.success) {
        this.props.updateUserBalance(data.balance);
        this.props.updateUserAvailable(data.availableForCashout);

        this.setState({
          successfullTransaction: true,
          errorTransfer: false,
          errorMessage: '',
          error: false,
          submitting: false,
        });
      } else {
        this.setState({
          errorTransfer: true,
          errorMessage: data.error,
          error: true,
          submitting: false,
        });
      }
    };

    this.enableButton = () => {
      // console.log( 'enableButton' );
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      //  console.log( 'disableButton' );
      this.setState({ canSubmit: false });
    };
    this.closeConfirmation = () => {
      this.setState({ showConfirmation: false }, () => {
        this.resetForm();
      });
    };
    this.openConfirmation = () => {
      this.setState({ showConfirmation: true });
    };
    this.searchNick = () => {
      this.setState({ submitting: true });
      API.searchUser(this.state.transferModel.nickToTransfer).then((response) => {
        this.callbackSearchNick(response.data);
      });
    };

    this.callbackSearchNick = (data) => {
      console.log(data);
      if (data.hasOwnProperty('user') && data.user !== null) {
        this.setState(
          {
            userToTransfer: data.user,
          },
          () => {
            this.setState({ showConfirmation: true, submitting: false });
          }
        );
      } else {
        this.setState(
          {
            errorTransfer: true,
            errorMessage: 'El usuario no existe.',
          },
          () => {
            this.setState({ showConfirmation: true, submitting: false });
          }
        );
      }
    };
    /**
     * Handle the Nick of the user to transfer
     * @param e is the event that handles the character changes
     * **/
    this.handleNickToTransfer = (e) => {
      let temporalTransferModel = this.state.transferModel;

      temporalTransferModel.nickToTransfer = e.target.value;

      this.setState({ transferModel: temporalTransferModel });
    };
    /**
     * Handle the Password of the private
     * @param e is the event that handles the character changes
     * **/
    this.handleUserPassword = (e) => {
      let temporalTransferModel = this.state.transferModel;

      temporalTransferModel.userPassword = e.target.value;

      this.setState({ transferModel: temporalTransferModel });
    };

    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleAmountToTransfer = (e) => {
      let temporalTransferModel = this.state.transferModel;

      temporalTransferModel.amountToTransfer = e.target.value;

      this.setState({ transferModel: temporalTransferModel });
    };
  }

  render() {
    return (
      <div className="flex-col col-no-padding">
        <Formsy
          onValidSubmit={(e) => {
            this.openConfirmation();
          }}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <div className="flex-container justify align column">
            <div className="flex-col">
              <TransferFormsy
                label="Escribe el nick del usuario al que le quieras transferir saldo."
                name="nickToTransfer"
                defaultValue={this.state.transferModel.nickToTransfer}
                inputHandler={this.handleNickToTransfer}
                validations={'isExisty'}
                validationError={'Campo requerido.'}
                type="NICK"
                required
              />
            </div>
            <div className="flex-col">
              <TransferFormsy
                label="Escribe el monto que quieres transferir."
                name="amountToTransfer"
                defaultValue={this.state.transferModel.amountToTransfer}
                inputHandler={this.handleAmountToTransfer}
                validations={{
                  isEnoughRule: (values, value) => {
                    let maxValue = this.props.maxForTransfer/100;
                    return Number(value) <= maxValue && Number(value) >= 1;
                  },
                }}
                validationError={`El monto a transferir debe de ser de $1 a ${

                   numeral(this.props.maxForTransfer/ 100).format('$0,000')
                  } MXN`}
                type="AMOUNT"
                required
              />
            </div>
            <div className="flex-col">
              <TransferFormsy
                label="Escribe la contraseña de tú cuenta."
                name="userPassword"
                defaultValue={this.state.transferModel.userPassword}
                inputHandler={this.handleUserPassword}
                validations={'isExisty'}
                validationError={'Escribe tu contraseña actual de Pickwin.'}
                type="PASSWORD"
                required
              />
            </div>
          </div>
          <div className="flex-col ">
            <div className="flex-row" style={{ paddingLeft: '0', paddingRight: '0' }}>
              <Button
                className="btn-pick btn-search-field flex-col col-center text-center "
                disabled={!this.state.canSubmit || this.state.submitting}
                onClick={this.searchNick}
              >
                {this.state.submitting ? <i className="ion-load-a loading"></i> : ' Buscar Usuario'}{' '}
              </Button>
            </div>
            <div className="flex-row">
              <div className="flex-col">
                <i className="ion-help-circled"></i> Únicamente podrás transferir saldo retirable,
                desde $1 MXN hasta {numeral(this.props.maxForTransfer/100).format('$0,000')} MXN.
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-col">
                <i className="ion-help-circled"></i> Podrás realizar una (1) transferencia cada
                24hrs.
              </div>
            </div>
          </div>
        </Formsy>
        <ConfirmationTransfer
          closeConfirmation={this.closeConfirmation}
          show={this.state.showConfirmation}
          error={this.state.error}
          submitTransfer={this.submitTransfer}
          submitting={this.state.submitting}
          error={this.state.errorTransfer}
          errorMessage={this.state.errorMessage}
          userToTransfer={this.state.userToTransfer}
          successfullTransaction={this.state.successfullTransaction}
          amountToTransfer={this.state.transferModel.amountToTransfer}
        />
      </div>
    );
  }
}

export default TransferFormulario;
