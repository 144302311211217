import React from 'react'
import ReactDOM from 'react-dom'
import propTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

// Components
import Iconify from '../Iconify'

// Hooks
import useRealNode from '../../hooks/useRealNode'
import Text from '../Text'

ConfirmModal.propTypes = {
  // If modal is showing
  show: propTypes.bool,

  // Buttons texts
  confirmText: propTypes.string,
  cancelText: propTypes.string,

  // Main message
  message: propTypes.string,

  // Title for bar
  title: propTypes.string,

  // Handler for close modal
  onClose: propTypes.func,
  onHide: propTypes.func
}

ConfirmModal.defaultProps = {
  confirmText: 'Si',
  cancelText: 'No'
}

export default function ConfirmModal (props) {
  const node = useRealNode('modal-node-portal')

  const handleClose = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (props.onClose) {
      props.onClose()
    }
    if (props.onHide) {
      props.onHide()
    }
  }

  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  if (!props.show || !node) return null

  return ReactDOM.createPortal(
    (
      <ModalPanel onMouseDown={handleClose} tabIndex='1'>
        <Content onMouseDown={stopPropagation}>
          <Bar type={props.type}>
            <Text center as='h6'>{props.title}</Text>
            <CloseButton onClick={handleClose}>
              <Iconify icon='ic:baseline-close' />
            </CloseButton>
          </Bar>
          <Text center as='p' mt={25} mb={0}>{props.message}</Text>
          <Text center as='p' mt={5} mb={0}>{props.message2}</Text>
          <ButtonsDelete>
            <ButtonConfirm onMouseDown={props.onConfirm} className='guardar-picks btn-rojo'>{props.confirmText}</ButtonConfirm>
            <ButtonCancel onMouseDown={handleClose} className='guardar-picks'>{props.cancelText}</ButtonCancel>
          </ButtonsDelete>
        </Content>
      </ModalPanel>
    ), node)
}

// ------- Styled Components -------

const ModalPanel = styled.div`
  background-color: #1b1c1e;
  background-color: rgb(0 0 0 / 75%);
  backdrop-filter: blur(3px) grayscale(1);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-y: auto;
  padding: 1em;
`

const show = keyframes`
  from {
    transform: scale(0.8) translateY(-50px);
  }
  to {
    transform: scale(1);
  }
`

const Content = styled.div`
  transform: scale(0.8) translateY(-50px);
  width: 100%;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  background-color: #000000;
  border: 1px solid #414244;
  min-height: 180px;
  will-change: transform;
  animation: 350ms ease ${show} forwards;
  border-radius: 0.5em;
  overflow: hidden;
`

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  position: relative;
  background: ${props => {
    switch (props.type) {
      case 'error': return 'linear-gradient(rgb(181 41 41 / 80%),#b13d3d)'
      default: return 'linear-gradient(rgba(120,181,41,.8),#87b13d)'
    }
  }};
`

const CloseButton = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    opacity: 0.7;
  }
`

const ButtonsDelete = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
  gap: 2em;
`

const ButtonBase = styled.button`
  border: 0;
  outline: 0;
  color: #FFF;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.5em 2.5em;
  border-radius: 1em;
  transition: transform 200ms ease;
  will-change: transform;
  width: 80px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
    transform: scale(1.02);
  }
`

const ButtonCancel = styled(ButtonBase)`
  background-color: #88ba44;
`

const ButtonConfirm = styled(ButtonBase)`
  background-color: #ff0000;
`
