// Libraries
import React from 'react'
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib'
import _ from 'underscore'
import { PulseLoader } from 'react-spinners'

// Components
import PicksGroupBracketPoints from './PicksGroupBracketPoints'

const BracketPointsGroupPicks = ({
  currentStage,
  bracketStages,
  bracketTeams,
  matches,
  selectedEntryData,
  bracketInfo,
  entryId,
  groupPicks,
  // eventHandlers
  onStageChange,
  loadMoreStandings,
  loadingGroup
}) => {
  const [filteredMatches, setFilteredMatches] = React.useState([])
  const [stages, setStages] = React.useState(null)

  React.useEffect(() => {
    // sacar los matches para poder iterar en picks de grupo
    let theMatches
    if (['Grupos', 'Octavos'].includes(currentStage.name)) {
      theMatches = _.where(matches, { stage_id: currentStage.id })
    } else {
      const stageIds = _.chain(bracketStages).filter(function (_stage) {
        return _stage.name !== 'Grupos' && _stage.name != 'Octavos'
      }).pluck('id').value()

      theMatches = _.filter(matches, function (_match) {
        return stageIds.includes(_match.stage_id)
      })
    }

    setFilteredMatches(theMatches)
  }, [currentStage])

  React.useEffect(() => {
    const newStages = []
    _.each(bracketStages, function (stage) {
      if (['Cuartos', 'Semifinales'].includes(stage.name)) { return }
      newStages.push({ id: stage.id, name: stage.name })
    })

    setStages(newStages)
  }, [])

  const GroupNames = ({ group }) => {
    return (
      <div className='item-content border-right column flex-container align justify border-bottom'>
        {group &&
          <div className='font-chivo text-bold text-small'>
            {group.name}
          </div> ||
            <label>PD</label>}
      </div>
    )
  }

  const WinningPick = ({ match }) => {
    let team = null
    if (match.home_team_id !== null && match.away_team_id !== null) {
      if (match.winning_pick === match.home_team_id) {
        team = match.home_team
      }
      if (match.winning_pick === match.away_team_id) {
        team = match.away_team
      }
    }

    return (
      <div className='item-content border-right column flex-container align justify border-bottom' style={{ maxHeight: '50px' }}>
        {team &&
          <div className='flex-row no-padding'>
            <div className='flex-container full align justify relative'>
              <i className={`quina ${team.logo}`} />
              <small className='team-name-perfect'>{team.abbreviation}</small>
            </div>
          </div> ||
            <label className='text-small'>PD</label>}
      </div>
    )
  }

  const MyPicks = ({ match }) => {
    let userPick = null
    if (selectedEntryData && selectedEntryData.bracket_picks.hasOwnProperty(match.number)) {
      userPick = selectedEntryData.bracket_picks[match.number]
    }

    if (userPick === null) {
      return (
        <div className='item-content border-right column flex-container align justify border-bottom'>
          <i className='ion-close' style={{ color: '#E00034' }} />
        </div>
      )
    }

    const selectedTeam = bracketTeams[userPick.pick]

    if (selectedTeam === null && match.sort_status === 1) {
      return (
        <div className='item-content border-right column flex-container align justify'>
          <i className='ion-close' style={{ color: '#E00034' }} />
        </div>
      )
    }

    // Colors
    // let correctPicks = [false, false, false];
    // let pickResultClass = correctPicks[0] ? "winner" : "looser";
    let pickResultClass = ''
    if (match.status !== 'upcoming') {
      if (match.winning_pick !== null && match.winning_pick === userPick.pick) {
        pickResultClass = 'winner'
      } else {
        pickResultClass = 'looser'
      }
      // var correctness = 0;
      // if ( userPick.pick !== null && userPick.pick === match.winning_pick ) {
      //   correctness = correctness ^ (1 << 2);
      //   correctPicks[0] = true;
      // }
      // if ( userPick.winning_game !== null && userPick.winning_game === match.winning_game ) {
      //   correctness = correctness ^ (1 << 1);
      //   correctPicks[1] = true;
      // }
      // correctness = (correctness >>> 0).toString(2);
    }

    return (
      <div className='item-content border-right column flex-container align justify border-bottom'>
        {selectedTeam &&
          <div className={`flex-row no-padding fc-align ${match.sort_status !== 1 && pickResultClass}`} style={{ height: '100%' }}>
            <div className='flex-row no-padding'>
              <div className='flex-container full align justify relative'>
                <i className={`quina ${selectedTeam.logo}`} />
                <small className='team-name-perfect'>{selectedTeam.abbreviation}</small>
              </div>
            </div>
          </div> ||
            <div className='flex-row no-padding fc-align' style={{ height: '100%' }}>
              <div className='flex-container align justify'>
                <i className='ion-close' style={{ color: '#E00034' }} />
              </div>
            </div>}
      </div>
    )
  }

  return (
    <div className='flex-row multiple-quiniela-info grupo'>
      <div className='flex-col upper-section'>
        <div className='container-picks'>
          <div className='upper-table' style={{ paddingRight: '6px' }}>
            <div className='upper-table-category headers'>

              {/* Handlers for change active stage group */}
              <div className='flex-row font-chivo'>
                <ButtonToolbar id='week-controls' className='flex-col'>
                  <ToggleButtonGroup
                    className='flex-container align justify'
                    name='weeks-controls'
                    style={{ width: '100%' }}
                    type='radio'
                    value={currentStage.id}
                    onChange={onStageChange}
                  >
                    {stages && stages.map((stage, i) => {
                      return (
                        <ToggleButton
                          key={stage.id}
                          className='bottom-separation font16'
                          value={stage.id}
                          style={{ maxWidth: '15%', width: '15%', minWidth: 155, fontSize: '12px', position: 'relative' }}
                        >
                          {stage.name}
                          {loadingGroup === stage.id && (
                            <PulseLoader
                              color='#FFF'
                              size={9}
                              style={{
                                position: 'absolute',
                                right: '1em',
                                top: '0.3em'
                              }}
                            />
                          )}
                        </ToggleButton>
                      )
                    })}

                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>

              {/* Header */}
              <div className='flex-row no-padding border-top'>
                <div className='col-20'>
                  <div className='flex-row no-padding row-bracket mis-picks'>
                    <div className='table-header-item font-chivo' />
                  </div>
                  <div className='flex-row no-padding row-bracket mis-picks'>
                    <div className='table-header-item font-chivo'>PICK GANADOR</div>
                  </div>
                  <div className='flex-row no-padding row-bracket mis-picks'>
                    <div className='table-header-item font-chivo text-medium'>MIS PICKS</div>
                  </div>
                </div>
                <div className='col-70 table-header-overflow' id='content-header-bracket' style={{ overflow: 'hidden' }}>
                  {/* Groups */}
                  <div className='flex-row no-padding'>
                    <div className='perfect-pick-item mis-picks'>
                      {filteredMatches.map(match => {
                        return <GroupNames key={match.id} group={match} />
                      })}
                      <div className='item-content border-right column flex-container align justify border-bottom' style={{ height: '100%' }}>
                        <div className='font-chivo text-bold text-small'>D1</div>
                      </div>
                      <div className='item-content border-right column flex-container align justify border-bottom' style={{ height: '100%' }}>
                        <div className='font-chivo text-bold text-small'>D2</div>
                      </div>
                    </div>
                  </div>
                  {/* Wining Pick */}
                  <div className='flex-row no-padding'>
                    <div className='perfect-pick-item mis-picks'>
                      {filteredMatches.map(match => {
                        return <WinningPick key={match.id} match={match} />
                      })}
                      {bracketInfo !== null && bracketInfo.bracket_tiebreakers !== null &&
                        bracketInfo.bracket_tiebreakers.map((breaker, i) => <UserTieWinning key={breaker.id} breaker={breaker} />)}
                    </div>
                  </div>
                  {/* My Picks */}
                  <div className='flex-row no-padding'>
                    <div className='perfect-pick-item mis-picks'>
                      {filteredMatches.map(match => {
                        return <MyPicks key={match.id} match={match} />
                      })}
                      {bracketInfo !== null && bracketInfo.bracket_tiebreakers !== null &&
                        bracketInfo.bracket_tiebreakers.map((breaker, i) => <MyTieBreakers key={breaker.id} userBreaker={selectedEntryData && selectedEntryData.bracket_user_tiebreakers} breaker={breaker} matches={matches} />)}
                    </div>
                  </div>
                </div>
                <div className='col-10 flex-container align justify border-left'>Puntos</div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className='flex-col lower-section' style={{ paddingTop: '0px' }}>
        {(groupPicks !== null || groupPicks !== undefined) &&
          <PicksGroupBracketPoints
            matches={filteredMatches}
            allMatches={matches}
            teams={bracketTeams}
            groupPicks={groupPicks}
            closed={bracketInfo.closed}
            tieBreakers={bracketInfo.bracket_tiebreakers}
            loadMoreStandings={loadMoreStandings}
            selectedEntryId={entryId}
          />}
      </div>
    </div>
  )
}

const UserTieWinning = ({ breaker }) => {
  return (
    <>
      {(!breaker.enabled && breaker.result !== null) &&
        <div className='item-content border-right column flex-container align justify border-bottom'>
          <label className='text-small'>{breaker.result !== null ? breaker.result : 'PD'}</label>
        </div> ||
          <div className='item-content border-right column flex-container align justify border-bottom'>
            <label className='text-small'>PD</label>
          </div>}
    </>
  )
}

const MyTieBreakers = ({ userBreaker, breaker, matches }) => {
  if (userBreaker !== undefined && userBreaker !== null) {
    userBreaker = userBreaker.find(ub => ub.bracket_tiebreaker_id === breaker.id)
  }
  // Find match
  let matchFinded
  if (userBreaker !== undefined && userBreaker !== null) {
    matchFinded = matches.find(match => (match.away_team_id === userBreaker.value || match.home_team_id === userBreaker.value))
  }

  let selectedTeam = null
  if (matchFinded !== undefined) {
    if (userBreaker.value == matchFinded.home_team_id) {
      selectedTeam = matchFinded.home_team
    }
    if (userBreaker.value == matchFinded.away_team_id) {
      selectedTeam = matchFinded.away_team
    }
  }

  return (
    <div className='item-content-perfect border-right border-bottom column flex-container align justify' style={{ height: '100%', fontSize: '0.8em' }}>
      {breaker.enabled &&
        <>
          {((userBreaker === undefined || userBreaker === null) || userBreaker.value === null) &&
            <i className='ion-close' style={{ color: '#E00034' }} /> ||
              <i className='ion-checkmark' style={{ color: '#78BC28' }} />}
        </> ||
          <>
            {selectedTeam !== null ? selectedTeam.abbreviation : 'ND'}
          </>}
    </div>
  )
}

export default BracketPointsGroupPicks
