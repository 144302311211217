import React, { forwardRef } from 'react'
import { PulseLoader } from 'react-spinners'
import styled, { keyframes } from 'styled-components'
import propTypes from 'prop-types'

import Iconify from '../../Iconify'

const TextField = forwardRef((props, ref) => {
  const handleChange = (event) => {
    const text = event.target.value

    props.onChange({ [props.name]: text })
  }

  const id = `${props.name}-id`

  return (
    <InputBox>
      <Label htmlFor={id}>{props.label}</Label>
      <InputContainer style={{ maxWidth: props.maxWidth }}>
        <InputBase
          name={props.name}
          onChange={handleChange}
          onBlur={props.onBlur}
          value={props.value}
          ref={ref}
          autoComplete='off'
          id={id}
          $hasError={props.error}
          type={props.type}
          style={props.inputStyle}
        />

        {(props.loading) && (
          <SpinerBox>
            <PulseLoader size={7} color='#3c8c03' />
          </SpinerBox>
        )}

        {props.isValid && (
          <SpinerBox>
            <Iconify icon='ooui:success' color='#2ea12a' />
          </SpinerBox>
        )}

        {props.error && (
          <SpinerBox>
            <Iconify icon='bxs:error-circle' color='orangered' />
          </SpinerBox>
        )}
      </InputContainer>

      {props.helpText && (
        <HelpText style={{ color: props.error ? 'orangered' : '#2ea12a' }}>
          {props.helpText}
        </HelpText>
      )}
    </InputBox>
  )
})

TextField.defaultProps = {
  type: 'text'
}

export default TextField

const HelpText = styled.div`
  font-size: 1em;
  color: red;
  text-align: center;
  line-height: 2;
`

const InputBox = styled.div`
  width: 100%;
  margin-bottom: 1em;
`

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.6) translateY(-50%);
  }

  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
`

const SpinerBox = styled.div`
  position: absolute;
  right: 0.5em;
  top: 50% ;
  transform: translateY(-50%);
  background-color: #ffffffc4;
  padding: 3px;
  animation: ${zoomIn} 300ms ease forwards;
  line-height: 1;
  font-size: 1.2em;
`

const InputContainer = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
`

export const InputBase = styled.input`
  width: 100%;
  padding: 0 0.5em;
  border-radius: 0.3em;
  color: #000;
  outline: 0;
  border: 0;
  box-shadow: 0px 0px 0px 0px #6abd0b00;
  transition: box-shadow 150ms linear;
  will-change: box-shadow;
  line-height: 2.3;
  
  &:focus {
    box-shadow: ${p => p.$hasError ? '0px 0px 1px 4px #eb2943' : '0px 0px 1px 4px #78bc28'};
  }
`

const Label = styled.label`
  line-height: 2.5;
  font-size: 1.1em;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
