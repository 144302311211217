import FireBase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/remote-config'
import _ from 'underscore';

const buildProduction = process.env.NODE_ENV === 'production';

var firebaseConfig = null;
firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MID
};


var defaults = {
  "incode_enabled": false,
};

var types = {
  "incode_enabled": "bool"
};

const FireBaseUtils = {
  setConfigValue: (key, value) => {
    defaults[key] = value;
  },

  data: () => {
    return {
      "data": defaults,
    }
  },

  init: async () => {
    return new Promise(resolve => {
      console.log('Firebase initialize', firebaseConfig);

      // Only initializaApp if not exists, in another case
      // use the same instance
      if (!FireBase.apps.length) {
        FireBase.initializeApp(firebaseConfig)
      } else {
        FireBase.app()
      }

      // disable analytics
      // FireBase.analytics();
      let remoteConfig = FireBase.remoteConfig();
      //remoteConfig.settings.minimumFetchIntervalMillis = 60 * 10000;
      remoteConfig.defaultConfig = defaults;
      remoteConfig.fetchAndActivate().then((result) => {

        console.log('Firebase result', result);
        var configs = remoteConfig.getAll();
        console.log("Firebase configs:", configs);

        _.each(configs, function(el, name) {

          console.log('Firebase config', el, name);
          var type = types[name];
          var value;

          switch( type ) {
            case 'bool':
              value = el.asBoolean();
              break;
            case 'number':
              value = el.asNumber();
              break;
            case 'string':
              value = el.asString();
              break;
          }

          console.log('Firebase name, val', name, value);

          FireBaseUtils.setConfigValue(name, value);

          resolve();
        });
      });
    });
  },
}

export default FireBaseUtils;
