import React from 'react';
import NavigationBar from '../NavigationBar';
import Footer from '../Footer';
import utils from '../../utils/utils';

//console.log("POOLS:",pools);
class Ayuda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: utils.getUserInfo(),
      logged: utils.isLogged(),
    }
  }
  render() {
    return (
      <div className='page-wrapper'>
        <div className="inner-wrapper">
          <NavigationBar logged={this.state.logged} userData={this.state.userData} />
          <div className="container">
            <div className="col-xs-10- col-xs-offset-1">
              <ul id="expList" className='unstyled-list '>
                <a href="#" className="clickme" >Información General</a>
                <div className="box34" >
                  <ul>
                    <li className="collapsed">¿Qué es Pickwin?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Pickwin es una plataforma en linea de quinielas deportivas en donde podrás jugar contra tus amigos o contra otros usuarios. En pickwin tambien podrás crear quinielas privadas de cualquier tipo de deportes.</p>
                        </blockquote>
                      </ul>
                    </li>
                    <li className="collapsed">¿Qué tipo de quinielas puedo jugar en Pickwin?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda"><strong>Quinielas Privadas:</strong> Juega únicamente con tu familia, amigos, los godines o con quien tu quieras. Puedes ser el administrador y crear la quiniela, así tu decides quien juega. O bien, alguien más puede invitarte y participar en una quiniela privada.</p>
                          <p className="payuda"><strong>Quinielas Públicas</strong>: Son quinielas que se publican en el Lobby de Pickwin y cualquier usuario puede participar.</p>
                        </blockquote>
                      </ul>
                    </li>
                    <li className="collapsed">¿En Pickwin puedo jugar con dinero Real?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Si, puedes abonar saldo a tu cuenta para jugar las quinielas de dinero real. Puedes hacer un depósito con tu tarjeta de crédito o generar un código de compra para pagar en tiendas de conveniencia. </p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                </div>

                <a href="#" className="clickme"  >¿Cómo me registro?</a>
                <div className="box34" >
                  <ul>
                    <center>

                      <iframe width="560" height="315" src="https://www.youtube.com/embed/5FCbfyIWZOY" frameBorder="0" allowFullScreen=""  ></iframe>

                    </center>
                    <p className="payuda">
                    </p>
                  </ul>
                </div>
                <a href="#" className="clickme"  >¿Cómo deposito?</a>

                <div className="box34" >
                  <ul>
                    <center>

                      <iframe width="560" height="315" src="https://www.youtube.com/embed/WMQ2OoKgh-s" frameBorder="0" allowFullScreen=""  ></iframe>

                    </center>

                    <p className="payuda">
                    </p>

                  </ul>
                </div>

                <a href="#" className="clickme"  >¿Cómo me registro a una quiniela?</a>
                <div className="box34" >
                  <ul>
                    <center>

                      <iframe width="560" height="315" src="https://www.youtube.com/embed/dKC5J6vqWJc" frameBorder="0" allowFullScreen=""  ></iframe>

                    </center>
                    <p className="payuda">
                    </p>
                    <ul>

                    </ul>
                  </ul></div>
                <a href="#" className="clickme"  >Tu Cuenta</a>
                <div className="box34" >
                  <ul>
                    <li className="collapsed">¿Cómo recuperar mi contraseña?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">1)  Ingresa a www.pickwin.mx, en la página superior derecha selecciona el botón de “Login”</p>
                          <p className="payuda">2)  Da click sobre “¿Olvidaste tu contraseña? ”</p>
                          <p className="payuda">3)  Escribe tu email con el cual creaste tu cuenta</p>
                          <p className="payuda">4)  Te llegará un mail para recuperar tu contraseña</p>
                        </blockquote>
                      </ul>
                    </li>
                    <li className="collapsed">¿Cómo puedo cambiar mi contraseña?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">1)  En la página principal selecciona el botón de “Cuenta” que se encuentra en el borde superior</p>
                          <p className="payuda">2)  Selecciona “ Información Personal ”, en la parte inferior derecha selecciona la palabra “Editar”</p>
                          <p className="payuda">3)  Edita tu contraseña</p>

                        </blockquote>
                      </ul>
                    </li>
                    <li className="collapsed">¿Puedo cambiar mi mail de registro?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Por razones de seguridad los usuarios no pueden cambiar el email de registro.</p>
                        </blockquote>
                      </ul>
                    </li>
                    <li className="collapsed">¿Puedo cambiar mi nickname?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Por razones de seguridad los usuarios no pueden cambiar su nickname.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed"> ¿Qué es el Estatus?
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">
                            El estatus es la manera como pickwin te va a premiar por tu lealtad y repetición en la pagina. Entre mas juegues mas estrellas tendrás y podrás tener mejores beneficios. Busca las quinielas gratuitas con bolsas garantizadas en el lobby según tu nivel de estatus.
                        </p>
                          <p className="payuda"><strong>Niveles</strong>
                          </p><table className="table" >
                            <thead>
                              <tr>
                                <th>Estrellas</th>
                                <th>Número de picoins jugados mensualmente</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center"><i className="status status-enabled"></i></td>
                                <td className="text-center">0 - 999</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                </td>
                                <td className="text-center">1,000 - 12,799</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                </td>
                                <td className="text-center">12,800 - 214,999</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                </td>
                                <td className="text-center">215,000 - 3,399,999</td>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                  <i className="status status-enabled"></i>
                                </td>
                                <td className="text-center">3,400,000 en adelante</td>
                              </tr>
                            </tbody>
                          </table>
                          <p></p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                </div>
                <a href="#" className="clickme"  >Glosario Pickwin</a>
                <div className="box34" >
                  <ul>
                    <li className="collapsed">Quinielas Públicas:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Las quinielas que se publican en el lobby de Pickwin y a las que puede participar cualquier usuario de Pickwin. Éstas se encuentran en el Lobby de la página y generalmente son sin cupo limitado.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Quinielas Privadas:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Quinielas que puedes jugar con un grupo cerrado de personas limitadas. Cada quiniela privada tiene un administrador (creador de la quiniela) encargado de invitar a los jugadores así como editar las características de la quiniela.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Quinielas Cupo Limitado:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Quinielas públicas limitadas a pocas personas.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Quiniela con línea o spread:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">
                            {"La linea (o spread) es una ventaja en puntos, goles o carreras (baseball) que se le otorgan al equipo NO favorito de un partido determinado para balancear a los dos equipos.<br><br>En las quinielas con línea deberás restarle al marcador final el total de puntos que le quitan al equipo favorito.<br><br>Ejemplo: Si juegan Patriots (-7) vs Browns, el favorito es Patriots y si es marcador final es 30-20 a los 30 puntos de los Patriots deberás restarle 7. Por lo tanto, el resultado final es 23-20."}
                          </p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Quiniela natural:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Son quinielas en las que no se le da ventaja a ningún equipo.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Picks:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">
                            {'Los picks son la selección de tus pronósticos. Es decir, si entre un local, empate y visitante tu escoges local, tu pick es: local. Se marcan los círculos en color verde cuando haces tu selección.<br><br>"Jala tus Picks" es una forma pickwinera de decir, ¡suerte!'}</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Picoins:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">¿Qué son las Picoins? Las picoins es la moneda que utilizamos en Pickwin.mx. Con esta podrás registrarte a las quinielas que tenemos en la página y podrás cambiarlas por premios en nuestra tienda.</p>
                          <p className="payuda"><strong>¿Cómo obtengo picoins?</strong> Todas las semanas te regalaremos 200 picoins siempre y cuando tu saldo este por debajo de 50 Picoins. Recuerda estar participando activamente ya que si no haz jugado en 6 semanas se te dejaran de abonar tus picoins.</p>
                          <p className="payuda"><strong>¿Los picoins tienen vigencia?</strong> No, siempre y cuando estes activo en la plataforma, las picoins no tienen vigencia, sin embargo, sí después de 12 meses no haz participando en ninguna quiniela, los Picoins serán retirados de tu cuenta.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Bolsa:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">
                            {'Es el total acumulado que se repartirá a los ganadores de una quiniela. La bolsa incrementa cuando un usuario más se registra en una quiniela.<br><br>Para calcularlo: número de entradas * costo por entrada.'}</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                  <ul>
                    <li className="collapsed">Bolsa garantizada:
                    <ul >
                        <blockquote className="blockayuda">
                          <p className="payuda">Es el premio mínimo garantizado que se repartirá entre los ganadores de una quiniela. En caso de que el número de entradas rebase el garantizado, la bolsa seguirá incrementando.</p>
                        </blockquote>
                      </ul>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div id="push"></div>
          </div>
          <Footer />
        </div>
      </div>


    )
  }

}


export default Ayuda;
