import React from 'react'
import numeral from 'numeral'

// Components
import Cards from './Cards'

// Utils
import utils from '../../../../../utils/utils'

// Assets
import billete from '../../../../../assets/images/cash1-50.png'
import pickcoin from '../../../../../assets/images/pickcoin1-50.png'


export default function MyCards (props) {
  return (
    <div className='flex-col col-90 col-offset-5'>
      <div className='flex-row row-center mb-3'>
        <div className='user-info-real flex-container justify align  column '>
          <div className=''>
            <div className='flex-row row-center '>
              <img src={billete} className='pool-icons' alt='' style={{ marginRight: '5px' }} />
              <span>Balance:</span>
              <strong className='flex-col text-center green-text'>
                {numeral(utils.getUserBalance().balanceReal / 100).format('$0,000.00')}MXN
              </strong>
            </div>
          </div>
        </div>
        <div className='user-info-real flex-container justify align column '>
          <div>
            <div className='flex-row row-center '>
              <img src={pickcoin} className='pool-icons' alt='' style={{ marginRight: '5px' }} />
              <span>Balance:</span>
              <strong className='flex-col text-center yellow-text'>
                {numeral(utils.getUserBalance().balancePc / 100).format('0,000.00')}PC
              </strong>
            </div>
          </div>
        </div>
      </div>
      <Cards
        updateUserInfo={props.updateUserInfo}
        updateUserBalance={props.updateUserBalance}
      />
    </div>
  )
}
