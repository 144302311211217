import React from 'react';
import NavigationBar from '../NavigationBar';
import pickLoader from '../../assets/images/quinielasIconBlanco-50.png';
import utils from '../../utils/utils';
import Loading from '../Loading/Loading';
import Footer from '../Footer/Footer';

class NoMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: null,
      userData: null,
    };
    console.log(this.state);
  }

  componentDidMount() {
    this.setState({
      logged: utils.isLogged(),
      userData: utils.getUserInfo(),
    });
  }

  render() {
    // console.log(this.props);
    let conditionalComponent = null;
    if (this.state.userData === null) {
      if (this.props.isPanel) {
        conditionalComponent = (
          <div className="container">
            <div className=" col-xs-10 col-xs-offset-1  perdido">
              <div className="content-lost">
                <span className="title">
                  <i className="ion-alert-circled"></i> Error:
                </span>
                <div className="row">
                  <span className=" col-xs-10 col-xs-offset-1 message">{this.props.label}</span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        conditionalComponent = <Loading label="Estamos buscando dónde estás" />;
      }
    } else {
      if (this.props.isPanel) {
        conditionalComponent = (
          <div className="container">
            <div className=" col-xs-10 col-xs-offset-1  perdido">
              <div className="content-lost">
                <span className="title">
                  <i className="ion-alert-circled"></i> Error:
                </span>
                <div className="row">
                  <span className=" col-xs-10 col-xs-offset-1 question">
                    Encontramos un error en tu petición.
                  </span>
                </div>
                <div className="row">
                  <span className=" col-xs-10 col-xs-offset-1 message">{this.props.label}</span>
                </div>
                <div className="contacto col-xs-10 col-xs-offset-1">
                  <span>
                    <a href="mailto:hola@pickwin.net">
                      <i className="ion-email"></i> hola@pickwin.net
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        conditionalComponent = (
          <div className="page-wrapper">
            <div className="inner-wrapper">
              <NavigationBar logged={this.state.logged} userData={this.state.userData} />
              <div className="container">
                <div className=" col-xs-10 col-xs-offset-1  perdido">
                  <div className="content-lost">
                    <span className="title">
                      <i className="ion-alert-circled"></i> Error 404:
                    </span>
                    <div className="row">
                      <span className=" col-xs-10 col-xs-offset-1 question">¿ Estás perdido ?</span>
                    </div>
                    <div className="row">
                      <span className=" col-xs-10 col-xs-offset-1 message">
                        Esta página no existe, verifica la dirección o contáctanos en:
                      </span>
                    </div>
                    <div className="contacto col-xs-10 col-xs-offset-1">
                      <span>
                        <a href="mailto:hola@pickwin.net">
                          <i className="ion-email"></i> hola@pickwin.net
                        </a>
                      </span>
                      <span className="">
                        <i className="ion-paper-airplane"></i> O si lo prefieres en los chats de las
                        quinielas.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="push"></div>
            <Footer />
          </div>
        );
      }
    }
    return conditionalComponent;
  }
}

export default NoMatch;
