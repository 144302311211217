import React from 'react'
import numeral from 'numeral'
import { Modal } from 'react-bootstrap'

// Assets
import billete from '../../../assets/images/cash1-50.png'

const GameInfoPopup = ({ game, onLaunchGameClicked }) => {
  const [showPopup, setShowPopup] = React.useState(false)

  const onPlayClicked = (mode) => {
    setShowPopup(false)
    onLaunchGameClicked(mode)
  }

  const onInformationClicked = (e) => {
    e.preventDefault()
    setShowPopup(!showPopup)
  }

  return (
    <>
      <a href='#' className='help-link' onClick={onInformationClicked}>
        <i className='icon ion-information-circled' />
      </a>

      <Modal
        show={showPopup}
        onHide={() => setShowPopup(false)}
        dialogClassName='game-info-modal'
        className='abonarModal absoluteModal'
        backdropClassName='abonarModalBackdrop'
      >
        <div className='panel-abonar'>
          <div id='panelTitle' className='panel-abonar-titulo font-chivo'>
            <span>{game.name}</span>
            <button className='cerrar-panel' onClick={() => setShowPopup(false)}>
              <div className='btn-icon-close'><i className='ion-ios-close-empty' /></div>
            </button>
          </div>

          <div className='panel-pickwin'>

            <div className='flex-row'>
              <div className='flex-col col-40'>
                <img className='game-thumbnail img-responsive' src={game.thumbnail} alt={game.name} />
              </div>
              <div className='flex-col'>
                <div className='flex-row'>
                  <div className='flex-col'>
                    <h4 className='ff-chivo text-italic'>
                      {game.name}
                    </h4>
                    <div className='flex-row'>
                      <div className='flex-col'><strong>Tipo:</strong></div>
                      <div className='flex-col'>{game.game_type}</div>
                    </div>
                    <div className='flex-row'>
                      <div className='flex-col'><strong>Apuesta Mínima:</strong></div>
                      <div className='flex-col'><img src={billete} style={{ width: '25px' }} />  ${numeral(game.min_bet).format('0,000')}</div>
                    </div>
                    <div className='flex-row'>
                      <div className='flex-col'><strong>Apuesta Máxima:</strong></div>
                      <div className='flex-col'><img src={billete} style={{ width: '25px' }} />  ${numeral(game.max_bet).format('0,000')}</div>
                    </div>
                  </div>
                </div>
                <div className='flex-row'>
                  <div className='flex-col'>
                    <button onClick={(e) => onPlayClicked(false)} className='btn btn-block btn-pick justify-content-center'>Jugar</button>
                  </div>
                </div>
                {game.has_demo && (
                  <div className='flex-row'>
                    <div className='flex-col'>
                      <button onClick={(e) => onPlayClicked(true)} className='btn btn-block btn-pick justify-content-center'>Demo</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default GameInfoPopup
