import React from 'react';
import {
  FormControl,
  FormGroup,
  Button,
  ControlLabel,
  HelpBlock,
  Modal,
} from 'react-bootstrap/lib';
import API from '../../utils/api';

class InvitarAmigos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateStateFunc: this.props.updateStateFunc,
      showPanel: this.props.showPanel,
      poolInfo: this.props.pool,
      userData: this.props.userDataProps,
      redirectLobbyPool: false,
      respuestas: null,
      invitationSend: null,
      listMails: '',
      submitting: false,
      mensaje: '',
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.handleMensaje = this.handleMensaje.bind(this);
    this.handleListMails = this.handleListMails.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
  }

  sendInvitation(e) {
    this.setState({
      submitting: true,
    });
    let invitationData = {
      pool_id:
        this.state.poolInfo.pool !== undefined
          ? this.state.poolInfo.pool.id
          : this.state.poolInfo.id,
      emails: this.state.listMails,
      message: this.state.mensaje,
    };
    API.postInviteEmails(invitationData).then((response) => {
      console.log(response);
      if (response !== undefined && response.data.result.success) {
        console.log('Success');
        this.setState({
          submitting: false,
          invitationSend: true,
          respuestas: response.data.result.success,
        });
      } else {
        console.log('Failed');
        this.setState({
          submitting: false,
          invitationSend: false,
          errorInvite: true,
        });
      }
    });
  }

  handleMensaje(e) {
    let val = e.target.value;
    this.setState({
      mensaje: val,
    });
  }
  handleListMails(e) {
    let val = e.target.value;
    this.setState({
      listMails: val,
    });
  }

  close() {
    // console.log('Close panel');
    this.setState({
      showPanel: false,
    });
  }

  open() {
    console.log('InvitationPanel, open', this.state);
    this.setState({
      showPanel: true,
    });
  }

  componentDidMount() {
    // console.log(this.props.pool);
    // this.props.pool !== undefined  ?
    //     this.setState({
    //         payType:this.props.payType,
    //         logged: this.props.logged
    //     })
    //     :
    //     null
    // console.log('Panel Mounted', this.state)
  }

  render() {
    let conditionalComponent;
    conditionalComponent = (
      <div>
        <Modal
          backdropClassName="backdropInvitarMail"
          dialogClassName="invitarMailModalDialog"
          className="invitarMailModal"
          show={this.props.showPanel}
          bsSize="sm"
          onHide={() => {
            if (this.props.dispatch) {
              this.props.updateStateFunc(this.props.dispatch);
            } else {
              this.props.updateStateFunc();
            }
          }}
        >
          {this.props.showPanel ? (
            this.state.respuestas !== null ? (
              this.state.respuestas ? (
                <div id="invitar-panel">
                  <div id="panelTitle" className="panel-abonar-titulo">
                    <span>{'Tu invitación se envío con éxito'}</span>
                    <button
                      className="cerrar-panel"
                      onClick={() => {
                        if (this.props.dispatch) {
                          this.props.updateStateFunc(this.props.dispatch);
                        } else {
                          this.props.updateStateFunc();
                        }
                      }}
                    >
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                  <div className=" pickwin-panel firstSection">
                    <div className="mensaje">
                      Ahora sólo espera a que tus invitados se registren, y diviértanse jugando en
                      Pickwin.
                    </div>
                  </div>
                </div>
              ) : (
                <div id="invitar-panel">
                  <div id="panelTitle" className="panel-abonar-titulo failed">
                    <span>
                      {
                        'Tenemos algunos problemas enviando tu invitación, por favor inténtalo nuevamente'
                      }
                    </span>
                    <button
                      className="cerrar-panel"
                      onClick={() => {
                        if (this.props.dispatch) {
                          this.props.updateStateFunc(this.props.dispatch);
                        } else {
                          this.props.updateStateFunc();
                        }
                      }}
                    >
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                  <div className="pickwin-panel firstSection">
                    <FormGroup id="invitarAmigos">
                      <div className="direcciones-correo-list">
                        <ControlLabel>Direcciones</ControlLabel>
                        <FormControl
                          componentClass="textarea"
                          value={this.state.listMails}
                          placeholder={`amigo@correo.net\namigo@correo.net\namigo@correo.net\namigo@correo.net`}
                          onChange={this.handleListMails}
                        />
                        <HelpBlock>
                          Escribe las direcciones de tus amigos, escribe únicamente una dirección de
                          correo electrónico por línea.
                        </HelpBlock>
                      </div>
                      <div className="mensaje-correo">
                        <ControlLabel>Mensaje Personalizado</ControlLabel>
                        <FormControl
                          componentClass="textarea"
                          value={this.state.mensaje}
                          placeholder="Escribe tu mensaje aquí"
                          onChange={this.handleMensaje}
                        />
                        <HelpBlock>
                          Escribe un mensaje personalizado para tus amigos.(opcional)
                        </HelpBlock>
                      </div>
                    </FormGroup>
                    {this.state.errorInvite ? (
                      <div className="error-formulario">
                        Tuvimos un problema al enviar tus invitaciones, por favor intentalo
                        nuevamente más tarde.
                      </div>
                    ) : null}
                    <div className="btn-wrapper">
                      <Button
                        className="btn-regstrate callToAction"
                        bsStyle="success"
                        onClick={this.sendInvitation}
                        disabled={this.state.listMails.length > 10 ? false : true}
                      >
                        <span>
                          {' '}
                          ENVIAR INVITACIONES <i className="ion-ios-paperplane" />
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div id="invitar-panel">
                <div id="panelTitle" className="panel-abonar-titulo">
                  <span>{'Envía un correo a tus amigos para saber quién es el mejor.'}</span>
                  <button
                    className="cerrar-panel"
                    onClick={() => {
                      if (this.props.dispatch) {
                        this.props.updateStateFunc(this.props.dispatch);
                      } else {
                        this.props.updateStateFunc();
                      }
                    }}
                  >
                    <div className="btn-icon-close">
                      <i className="ion-ios-close-empty" />
                    </div>
                  </button>
                </div>
                <div className="pickwin-panel firstSection">
                  <FormGroup id="invitarAmigos">
                    <div className="direcciones-correo-list">
                      <ControlLabel>Direcciones</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        value={this.state.listMails}
                        placeholder={`amigo@correo.net\namigo@correo.net\namigo@correo.net\namigo@correo.net`}
                        onChange={this.handleListMails}
                      />
                      <HelpBlock>
                        Escribe las direcciones de tus amigos, escribe únicamente una dirección de
                        correo electrónico por línea.
                      </HelpBlock>
                    </div>
                    <div className="mensaje-correo">
                      <ControlLabel>Mensaje Personalizado</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        value={this.state.mensaje}
                        placeholder="Escribe tu mensaje aquí"
                        onChange={this.handleMensaje}
                      />
                      <HelpBlock>
                        Escribe un mensaje personalizado para tus amigos.(opcional)
                      </HelpBlock>
                    </div>
                  </FormGroup>
                  {this.state.errorInvite ? (
                    <div className="error-formulario">
                      Tuvimos un problema al enviar tus invitaciones, por favor intentalo nuevamente
                      más tarde.
                    </div>
                  ) : null}
                  <div className="btn-wrapper">
                    <Button
                      bsStyle="success"
                      onClick={this.sendInvitation}
                      disabled={this.state.listMails.length > 10 ? false : true}
                    >
                      <span>
                        {' '}
                        ENVIAR INVITACIONES <i className="ion-ios-paperplane" />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )
          ) : (
            <span>LOADING...</span>
          )}
        </Modal>
      </div>
    );

    // console.log('Render Panel',this.props);
    return conditionalComponent;
  }
}

export default InvitarAmigos;
