import React, { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
// import { useLockBodyScroll } from "@uidotdev/usehooks"
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

// Components
import Iconify from '../../../components/Iconify/Iconify'
import Shine from './Shine'
import { Bar, Box, CloseButton, Content, Modal, Move, Title, VisibleBar } from './Modal.styled'

const targetElement = document.querySelector('html')

ModalComponent.propTyes = {
  // Functions to inverse control for
  // close modal from parent
  onHide: propTypes.func,
  onClose: propTypes.func,

  // If modal is show or hiden
  // by default it's true
  show: propTypes.bool,

  // Modal title bar
  title: propTypes.string,

  // children or content
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node
  ])
}

ModalComponent.defaultProps = {
  show: true,
  onlyCloseOnCross: false
}

export default function ModalComponent (props) {
  const modalContentRef = useRef()

  useEffect(() => {
    if (props.show) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [props.show])

  const handleCloseButton = (event) => {
    event.stopPropagation()
    event.preventDefault()

    distpachClose()
  }

  const handleCloseBackground = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (props.onlyCloseOnCross) return
    distpachClose()
  }

  const distpachClose = () => {
    if (props.onClose) {
      props.onClose()
    }

    if (props.onHide) {
      props.onHide()
    }
  }

  const stopPropagation = (event) => {
    event.stopPropagation()
    const el = document.querySelector('.fade[tabindex="-1"]')

    if (el) {
      el.removeAttribute('tabindex')
    }
  }

  const showCloseButton = props.onClose || props.onHide

  if (!props.show) return null

  return (
    <Modal onMouseDown={handleCloseBackground} tabIndex='1'>
      <Content
        onMouseDown={stopPropagation}
        style={props.style}
        ref={modalContentRef}
      >
        <Bar>
          {/* <GreenGlass /> */}
          <VisibleBar>
            <Title>{props.title}</Title>
            {showCloseButton && (
              <CloseButton onClick={handleCloseButton}>
                <Iconify icon='ep:close-bold' />
              </CloseButton>
            )}
          </VisibleBar>
          <Move>
            <Shine />
          </Move>
        </Bar>
        <Box>{props.children}</Box>
      </Content>
    </Modal>
  )
}
