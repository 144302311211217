import React from 'react';
import PropTypes from 'prop-types';

import API from '../../utils/api';
import SoccerScoreboardMatch from './SoccerScoreboardMatch';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import Loading from '../Loading';

const BracketSoccerScoreboard = ({ bracket, webSocket }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [weeks, setWeeks] = React.useState([]);
  const [selectedWeek, setSelectedWeek] = React.useState(null);
  const [fixtures, setFixtures] = React.useState([]);

  const fixturesRef = React.useRef(fixtures);

  const onWeekChange = week => {
    setSelectedWeek(week);
    setFixtures(week.fixtures);
  };

  const onFixturesUpdate = data => {

    const liveFixures = data.live_fixtures;
    const results = data.results;

    const newFixtures = fixturesRef.current.map(fixture => {
      const liveFixture = liveFixures.find(liveFixture => liveFixture.id === fixture.id);
      if (liveFixture) {
        fixture.score_home = liveFixture.score_home;
        fixture.score_away = liveFixture.score_away;
        fixture.winning_pick = liveFixture.winning_pick;
        fixture.winning_pick_line = liveFixture.winning_pick_line;
      }

      if ( results.hasOwnProperty(fixture.feed_id) ) {
        let fixtureResults = results[fixture.feed_id];
        fixture.stats = fixtureResults;
        fixture.events = fixtureResults.events;
        fixture.stats.quarter = fixtureResults?.quarter ?? null;
        fixture.stats.timer = fixtureResults.timer;
        fixture.stats.score_home_first_half = fixtureResults.score_home_first_half;
        fixture.stats.score_away_first_half = fixtureResults.score_away_first_half;
      }
      return fixture;
    });

    setFixtures(newFixtures);
  };

  React.useEffect(() => {
    fixturesRef.current = fixtures;
  }, [fixtures]);

  React.useEffect(() => {
    console.log('webSocket', webSocket);

    webSocket.on('fixture:update', onFixturesUpdate);
  }, [webSocket]);

  React.useEffect(() => {
    API.getTournamentFixtures(bracket.tournament_instances[0].id).then(function(response) {
      const { current, weeks } = response.data;

      setWeeks(weeks);

      if (current == null) {
        setSelectedWeek(weeks[0]);
        setFixtures(weeks[0].fixtures);
      } else {
        setSelectedWeek(current);
        setFixtures(current.fixtures);
      }

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex-row font-chivo">
        <ButtonToolbar className="flex-col">
          <ToggleButtonGroup
            className="flex-container align justify"
            type="radio"
            name="weeks-controls"
            value={selectedWeek}
            onChange={onWeekChange}
            style={{ width: '100%' }}
          >
            {weeks.map((week, index) => {
              return (
                <ToggleButton
                  value={week}
                  key={week.id}
                  className="bottom-separation font16"
                  style={{ maxWidth: '15%', width: '15%', fontSize: '12px' }}
                >
                  {week.week_name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>

      <div>
        {fixtures && fixtures.map((fixture, index) => {
          return (
            <SoccerScoreboardMatch key={fixture.id} fixture={fixture} sportId={bracket.sport_id} />
          );
        })}
      </div>
    </div>
  );
};

BracketSoccerScoreboard.propTypes = {
  bracket: PropTypes.object.isRequired,
  webSocket: PropTypes.object.isRequired,
};

export default BracketSoccerScoreboard;
