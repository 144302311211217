import React from 'react'
import SurvivorName from './SurvivorName'
import SurvivorSport from './SurvivorSport'
import SurvivorDuration from './SurvivorDuration'
import SurvivorPrice from './SurvivorPrice'
import SurvivorAwards from './SurvivorAwards'
import SurvivorSuccess from './SurvivorSuccess'

export default function SurvivorPrivate () {
  return (
    <>
      <SurvivorName />
      <SurvivorSport />
      <SurvivorDuration />
      <SurvivorPrice />
      <SurvivorAwards />
      <SurvivorSuccess />
    </>
  )
}
