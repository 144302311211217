import React from 'react';
import PageWrapper from '../PageWrapper';

import Futbol from '../../assets/images/sports/042-ball.png';
import Americano from '../../assets/images/sports/037-rugby.png';
import Hockey from '../../assets/images/sports/028-hockey-stick.png';
import Basketball from '../../assets/images/sports/009-basketball.png';
import Box from '../../assets/images/sports/011-boxing.png';
import Baseball from '../../assets/images/sports/005-baseball-ball.png';
import Ciclismo from '../../assets/images/sports/021-finish-line.png';
import Carreras from '../../assets/images/sports/023-flag.png';
import Golf from '../../assets/images/sports/026-golf-bag.png';
import Tennis from '../../assets/images/sports/048-tennis-ball.png';
import Natacion from '../../assets/images/sports/046-swim.png';
import Atletismo from '../../assets/images/sports/044-stopwatch.png';
import Badminton from '../../assets/images/sports/003-shuttlecock.png';
import Boliche from '../../assets/images/sports/010-bowling.png';
import Curling from '../../assets/images/sports/030-kettlebell.png';
import Esgrima from '../../assets/images/sports/047-sword.png';
import Pingpong from '../../assets/images/sports/034-ping-pong.png';
import Voleyball from '../../assets/images/sports/032-volleyball.png';
import VoleyballPlaya from '../../assets/images/sports/032-volleyball.png';
import Waterpolo from '../../assets/images/sports/046-swim.png';

import './sportRules.scss';

const SportRules = () => {
  setTimeout(() => {
    const links = document.querySelectorAll('.sport-menu-container a');
    for (const link of links) {
      link.addEventListener('click', clickHandler);
    }
  }, 1000);

  function clickHandler(e) {
    e.preventDefault();
    const href = this.getAttribute('href');

    document.querySelector(href).scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <PageWrapper isLegals>
      <div className="container">
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div className="col-sm-3">
            <div className="sport-rules-container border-gradient">
              <div className="sport-menu-container">
                <a href="#general">
                  <div className="flex-row sport-item-rule active">Generales</div>
                </a>
                <a href="#futbol">
                  <div className="flex-row sport-item-rule">
                    <img src={Futbol} />
                    <span>Fútbol</span>
                  </div>
                </a>
                <a href="#basquetbol">
                  <div className="flex-row sport-item-rule">
                    <img src={Basketball} />
                    <span>Basquetbol</span>
                  </div>
                </a>

                <a href="#tenis">
                  <div className="flex-row sport-item-rule">
                    <img src={Tennis} />
                    <span>Tenis</span>
                  </div>
                </a>

                <a href="#americano">
                  <div className="flex-row sport-item-rule">
                    <img src={Americano} />
                    <span>Americano</span>
                  </div>
                </a>

                <a href="#baseball">
                  <div className="flex-row sport-item-rule">
                    <img src={Baseball} />
                    <span>Béisbol</span>
                  </div>
                </a>

                <a href="#hockey">
                  <div className="flex-row sport-item-rule">
                    <img src={Hockey} />
                    <span>Hockey</span>
                  </div>
                </a>

                <a href="#balonmano">
                  <div className="flex-row sport-item-rule">
                    <img src={Futbol} />
                    <span>Balónmano</span>
                  </div>
                </a>

                <a href="#voleibol">
                  <div className="flex-row sport-item-rule">
                    <img src={Voleyball} />
                    <span>Voleibol</span>
                  </div>
                </a>

                <a href="#golf">
                  <div className="flex-row sport-item-rule">
                    <img src={Golf} />
                    <span>Golf</span>
                  </div>
                </a>

                <a href="#box">
                  <div className="flex-row sport-item-rule">
                    <img src={Box} />
                    <span>Box</span>
                  </div>
                </a>

                <a href="#carreras">
                  <div className="flex-row sport-item-rule">
                    <img src={Carreras} />
                    <span>Deportes Motor</span>
                  </div>
                </a>

                <a href="#rugby">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Rugby} /> */}
                    <span>Rugby</span>
                  </div>
                </a>

                <a href="#snooker">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Bill} /> */}
                    <span>Snooker</span>
                  </div>
                </a>

                <a href="#dardos">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Rugby} /> */}
                    <span>Dardos</span>
                  </div>
                </a>

                <a href="#invierno">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Rugby} /> */}
                    <span>Derportes Invierno</span>
                  </div>
                </a>

                <a href="#badminton">
                  <div className="flex-row sport-item-rule">
                    <img src={Badminton} />
                    <span>Badminton</span>
                  </div>
                </a>

                <a href="#futsal">
                  <div className="flex-row sport-item-rule">
                    <img src={Futbol} />
                    <span>Futsal</span>
                  </div>
                </a>

                <a href="#tenis-mesa">
                  <div className="flex-row sport-item-rule">
                    <img src={Pingpong} />
                    <span>Tenis de mesa</span>
                  </div>
                </a>

                <a href="#e-sports">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Pingpong} /> */}
                    <span>E-Sports</span>
                  </div>
                </a>

                <a href="#cricket">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Pingpong} /> */}
                    <span>Cricket</span>
                  </div>
                </a>

                <a href="#surfing">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Pingpong} /> */}
                    <span>Surfing</span>
                  </div>
                </a>

                <a href="#otros">
                  <div className="flex-row sport-item-rule">
                    {/* <img src={Pingpong} /> */}
                    <span>Otros</span>
                  </div>
                </a>
                {/*
                <a href="#ciclismo">
                  <div className="flex-row sport-item-rule">
                    <img src={Ciclismo} />
                    <span>Ciclismo</span>
                  </div>
                </a>

                <a href="#futbol-australiano">
                  <div className="flex-row sport-item-rule">
                    <img src={Futbol} />
                    <span>Fútbol Australiano</span>
                  </div>
                </a> */}

                {/* <a href="#natacion">
                  <div className="flex-row sport-item-rule">
                    <img src={Natacion} />
                    <span>Natación</span>
                  </div>
                </a>
                <a href="#atletismo">
                  <div className="flex-row sport-item-rule">
                    <img src={Atletismo} />
                    <span>Atletismo</span>
                  </div>
                </a>
                <a href="#badminton">
                  <div className="flex-row sport-item-rule">
                    <img src={Badminton} />
                    <span>Badminton</span>
                  </div>
                </a>
                <a href="#boliche">
                  <div className="flex-row sport-item-rule">
                    <img src={Boliche} />
                    <span>Boliche</span>
                  </div>
                </a>
                <a href="#curling">
                  <div className="flex-row sport-item-rule">
                    <img src={Curling} />
                    <span>Curling</span>
                  </div>
                </a>
                <a href="#esgrima">
                  <div className="flex-row sport-item-rule">
                    <img src={Esgrima} />
                    <span>Esgrima</span>
                  </div>
                </a>

                <a href="#voley-playa">
                  <div className="flex-row sport-item-rule">
                    <img src={VoleyballPlaya} />
                    <span>Vóley de playa</span>
                  </div>
                </a>
                <a href="#waterpolo">
                  <div className="flex-row sport-item-rule">
                    <img src={Waterpolo} />
                    <span>Waterpolo</span>
                  </div>
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-sm-9">
            <div className="sport-rules-container border-gradient">
              <div className="rules-wrap">
                {/* begin rules-wrap */}
                <div className="rules-sports-container">
                  <h1 id="general" style={{ textAlign: 'center' }}>
                    REGLAS GENERALES
                  </h1>
                  <hr />
                  <div>
                    <ul>
                      <li>
                        <span>
                          Si el resultado de un mercado no puede ser verificado oficialmente, nos
                          reservamos el derecho de retrasar la liquidación hasta la confirmación
                          oficial.
                        </span>
                      </li>
                      <li>
                        <span>
                          Nos reservamos el derecho de anular cualquier mercado si las líneas
                          ofrecidas ya disponían de un resultado oficial.
                        </span>
                      </li>
                      <li>
                        <span>
                          En el caso de que el pago del boleto se haya calculado de forma
                          evidentemente incorrecta, nos reservamos el derecho de anular cualquier
                          boleto.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Todos los mercados se liquidarán de acuerdo con el resultado oficial. Si
                          el resultado de un evento no puede ser verificado oficialmente, nos
                          reservamos el derecho de anular cualquier mercado.
                        </span>
                      </li>
                      <li>
                        <span>
                          En el caso de una liquidación incorrecta de los mercados, nos reservamos
                          el derecho de corregirlos en cualquier momento.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido no se ajusta al formato generalmente aceptado (por ejemplo,
                          duración inusual del período, procedimiento de recuento, formato de un
                          partido, etc.); nos reservamos el derecho de anular cualquier mercado.
                          Aplica ciertas excepciones de acuerdo a las reglas generales de cada
                          deporte.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si las reglas o el formato de un partido difieren de nuestra norma
                          aceptada, nos reservamos el derecho de anular cualquier mercado.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido no se completa o no se juega (por ejemplo, por
                          descalificación, interrupción, retirada, cambios en las tablas, etc.),
                          todos los mercados no decididos se consideran nulos. Aplica ciertas
                          excepciones de acuerdo a las reglas generales de cada deporte.
                        </span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <span>
                        Los mercados de jugadores específicos están disponibles con estos términos y
                        condiciones:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <b>
                          <span>
                            Todos los mercados de jugadores específicos incluyen tiempo extra
                            (overtime o OT) para resultados de apuesta. Los tiros de penalti no se
                            toman en cuenta.
                          </span>
                        </b>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>
                          Si algún jugador seleccionado para cualquier tipo de apuesta no participa
                          en el partido, entonces todas las apuestas realizadas a ese jugador serán
                          anuladas.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>
                          Si el partido empieza pero se abandona o suspende en cualquier momento
                          antes de un final oficial y no se resume dentro de 48 horas, todas las
                          apuestas sin resultados serán nulas.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>
                          Si llega a ocurrir algún cambio de sede, todas las apuestas realizadas
                          antes del cambio&nbsp; serán nulas.
                        </span>
                      </li>
                      <li>
                        <span>
                          Apuestas realizadas en un jugador cuyo evento está pospuesto o cancelado
                          antes del comienzo del mismo
                        </span>
                        (Partido no iniciado)
                        <span>
                          se dejarán como pendientes, las apuestas tendrán acción si el evento se
                          reanuda dentro de las siguientes 48 horas. Si el evento no comienza dentro
                          de las próximas 48 horas, las apuestas serán nulas.
                        </span>
                      </li>
                      <li>
                        <span>Para que los </span>
                        <b>
                          <i>mercados especiales</i>
                        </b>
                        <span>
                          de cualquier evento deportivo tengan validez, el evento debe celebrarse el
                          día acordado, en caso contrario toda apuesta realizada a dichos mercados
                          será anulada.
                        </span>
                      </li>
                      <li>
                        <span>
                          Bajo ninguna circunstancia están permitidas las apuestas correlacionadas,
                          salvo que las reglas de un deporte en específico digan lo contrario.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="futbol" style={{ textAlign: 'center' }}>
                    REGLAS DE FUTBOL / SOCCER
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los mercados están basados en el resultado del tiempo regular. Esto
                        incluye cualquier falta o tiempo fuera, pero no incluye tiempo extra, tiempo
                        asignado para un penalti o gol de oro. A menos que se indique lo contrario.
                      </span>
                    </p>
                    <p>
                      <span>
                        El VAR (Árbitro asistente de video) se tomará en cuenta. Las decisiones
                        tomadas después de un hecho relevante, pueden resultar en apuestas que ya
                        fueron calificadas terminen siendo anuladas, así como apuestas ganadoras
                        terminen siendo perdedoras y viceversa. Las apuestas anuladas serán a partir
                        del momento del hecho relevante, hasta el momento de la decisión oficial
                        anunciada.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Se hace una excepción a esta regla cuando se trata de partidos amistosos. En
                        esos casos, todos los mercados se califican en base al resultado actual al
                        final del partido (excluyendo cualquier tiempo extra), independientemente si
                        se jugaron los 90 minutos. Ésta regla solo aplica a partidos con tiempo
                        regular de 45 minutos por mitad.
                      </span>
                    </p>
                    <p>
                      <span>
                        Algunos partidos pueden tener diferentes periodos de tiempo en el campo. En
                        esos casos se aplican las siguientes reglas:&nbsp;
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Partidos de 90 minutos (3 tiempos de 30 minutos): todas las apuestas a
                          tiempo completo tienen validez, las apuestas a primera o segunda mitad se
                          considerarán nulas.
                        </span>
                      </li>
                      <li>
                        <span>
                          Partidos de 80 minutos (2 tiempos de 40 minutos): todas las apuestas
                          tienen validez.
                        </span>
                      </li>
                      <li>
                        <span>
                          Partidos cuyo formato no sea 2 tiempos de 45 minutos o los mencionados
                          anteriormente: todos los mercados serán anulados.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        En caso de que un partido se juegue antes de la fecha u hora de inicio
                        indicada, las apuestas se mantendrán siempre que se realicen antes de la
                        hora de inicio revisada.
                      </span>
                    </p>
                    <p>
                      <span>
                        Todas las apuestas a un partido abandonado antes de la finalización del
                        tiempo reglamentario serán anuladas, a menos que el partido se vuelva a
                        programar y se juegue en la misma fecha (hora local) o se indique lo
                        contrario en las reglas, excepto las apuestas a cualquier mercado que se
                        haya determinado de forma incondicional.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si se cambia el lugar de celebración de un partido, las apuestas ya
                        realizadas se mantendrán siempre que el equipo local siga siendo designado
                        como tal. Si el equipo local y el visitante de un partido se invierten, las
                        apuestas basadas en el listado original serán anuladas.
                      </span>
                    </p>
                    <p>
                      <b>Mercados de tarjetas</b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          La tarjeta amarilla cuenta como 1 tarjeta y la tarjeta roja o
                          amarillo-roja como 2. La 2da amarilla de un jugador que provoca una
                          tarjeta amarilla-roja no se considera. En consecuencia, un jugador no
                          puede tener más de 3 tarjetas.
                        </span>
                      </li>
                      <li>
                        <span>
                          La liquidación se hará de acuerdo con todas las tarjetas mostradas durante
                          los 90 minutos regulares de juego.
                        </span>
                      </li>
                      <li>
                        <span>
                          Las tarjetas mostradas después del partido no son tomadas en consideración
                          para efectos de apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          No se consideran las tarjetas de los no jugadores (jugadores ya
                          sustituidos, directivos, jugadores en el banquillo).
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>Prórroga-Tiempo Extra</b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Todas las apuestas se determinan en función de las estadísticas oficiales
                          de la Prórroga-Tiempo extra. Los goles, córners, etc. que se hayan marcado
                          durante el tiempo reglamentario no cuentan. La prórroga-tiempo extra no
                          incluye la tanda de penaltis.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si el partido no llega a la prórroga-tiempo extra [OT], todas las apuestas
                          se anulan.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>Mercados de córners</b>
                    </p>
                    <ul>
                      <li>
                        <span>Los córners concedidos pero no ejecutados no cuentan..</span>
                      </li>
                    </ul>
                    <p>
                      <b>Primer goleador</b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando haya un gol en propia puerta no se considerará a efectos de la
                          liquidación del primer goleador y se ignorará.
                        </span>
                      </li>
                      <li>
                        <span>
                          Todos los jugadores que hayan participado en el partido desde el inicio
                          del mismo serán considerados para efectos de apuestas.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un jugador entra en el partido después de que se haya marcado el primer
                          gol, se anulará.
                        </span>
                      </li>
                      <li>
                        <span>
                          El mercado se resolverá en base a la inserción televisiva y a las
                          estadísticas oficiales proporcionadas por las Ligas oficiales.
                        </span>
                      </li>
                      <li>Este mercado no incluye Over Time.</li>
                    </ul>
                    <p>
                      <b>Goleador en cualquier momento</b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Los goles en propia puerta no se tendrán en cuenta a efectos de la
                          determinación del goleador en cualquier momento.
                        </span>
                      </li>
                      <li>
                        <span>
                          Todos los jugadores que hayan participado en el partido serán considerados
                          para efectos de apuestas. Si por cualquier motivo un jugador no incluido
                          en la lista marca un gol, todas las apuestas por los jugadores incluidos
                          en la lista se mantienen.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido no ha finalizado en las 48 horas siguientes al saque
                          inicial, todas las apuestas se considerarán anuladas, excepto las de los
                          jugadores que ya hayan marcado.
                        </span>
                      </li>
                      <li>
                        <span>
                          El mercado se determinará en base a la inserción en TV y a las
                          estadísticas oficiales proporcionadas por las Ligas oficiales.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>Reglas de liquidación del fútbol</b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando un mercado sigue abierto y los siguientes eventos ya se han
                          verificado, por ejemplo: goles, tarjetas y penaltis, nos reservamos el
                          derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un partido sea interrumpido o aplazado y no continúe dentro de las
                          48 horas siguientes a la fecha de inicio, todas las apuestas serán
                          anuladas.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando los nombres de los equipos o la categoría se muestren
                          incorrectamente, nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si el mercado se ha abierto con una tarjeta roja incorrecta o inexistente,
                          nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si las cuotas se ofrecen con un tiempo de partido incorrecto (más de 5
                          minutos), nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR (1-X-2):
                        <span>
                          Este mercado es el conocido 3-way, dando a elegir entre una victoria del
                          local, un empate o una victoria del visitante.
                        </span>
                        (No incluye Tiempo extra)
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>1= Local</span>
                  </p>
                  <p>
                    <span>X= Empate</span>
                  </p>
                  <p>
                    <span>2= Visitante</span>
                  </p>
                  <p>
                    <b>GANADOR:</b>
                    <span>
                      Mejor conocido 2-way. A diferencia del 3-way, el 2-way te permite seleccionar
                      una victoria del local o una victoria del visitante, al no tener la opción del
                      empate, si el partido termina en empate, las apuestas serán reembolsadas.
                    </span>
                    <b>(Incluye tiempo extra)</b>
                    <span>.</span>
                  </p>
                  <p>&nbsp;</p>
                  <div>
                    <p>
                      <b>Hándicap Europeo</b>
                    </p>
                    <p>
                      <span>E</span>
                      <span>
                        s una apuesta de 3 vías, similar a una apuesta de resultado de partido 1X2.
                        Por eso también se encuentra como "Handicap" o "3-way handicap" en todos los
                        deportes y apuestas similares como el mercado de la primera mitad se
                        resolverán de acuerdo con estas reglas. En cualquier partido con una
                        diferencia significativa de habilidad entre los dos equipos, se coloca un
                        hándicap en el equipo que tenga desventaja, con el fin de hacer más difícil
                        que el favorito gane la apuesta o lo contrario con el favorito. Hasta ahora
                        la función es la misma con el Asian Handicap. Aquí está la diferencia
                        significativa: el Hándicap Europeo (abreviado como EH) es siempre un número
                        entero, lo que significa que puede ser igualado con una diferencia de goles.
                        En lugar de colocar un Hándicap Asiático de -1,5 goles o -0,25 goles, el
                        Hándicap Europeo es siempre del tipo -1, -2, -3 etc. Como en el Handicap
                        Asiático el objetivo principal es deshacerse del resultado del empate (con
                        un hándicap no entero se puede realmente ganar o perder la apuesta), el
                        Hándicap Europeo realmente tiene un resultado de "empate". Que no es, por
                        supuesto, el empate real del partido, sino la "igualación" del hándicap
                        específico establecido por la casa de apuesta. Solo en caso de seleccionar
                        la opción del empate en una apuesta pre-match, la ventaja o desventaja
                        siempre se aplica al equipo local.
                      </span>
                    </p>
                    <p>
                      <span>Las apuestas de hándicap europeo suelen aparecer así:</span>
                    </p>
                    <p>
                      <b>Local&nbsp; (2) - Empate (-2) - Visitante (-2)</b>
                    </p>
                    <p>
                      <span>
                        ¿Qué significa esto? Veámoslo más de cerca con los siguientes ejemplos:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <b>Local (2):</b>
                        <span>
                          La apuesta se considerará ganadora si el equipo local gana el partido,
                          consigue un empate o incluso pierde por un gol.
                        </span>
                      </li>
                      <li>
                        <b>Empate (-2):</b>
                        <span>
                          En este caso, el evento debe finalizar 4-2 para que la apuesta se
                          considere ganadora. En el caso de ser Empate (2), el evento debe finalizar
                          0-2 para que la la apuesta se considere ganadora.
                        </span>
                      </li>
                      <li>
                        <b>Visitante (-2):</b>
                        <span>
                          La apuesta se considerará ganadora si el equipo visitante debe ganar por
                          tres goles o más.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Los ejemplos antes mencionados se aplican para el Hándicap europeo en una
                        apuesta pre-match. Cuando se quiera realizar una apuesta de Hándicap europeo
                        en vivo los mercados se mostrarán de la siguiente manera:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <b>Local (2:0):</b>
                        <span>
                          La apuesta se considerará ganadora si el equipo local gana el partido,
                          consigue un empate o incluso pierde por un gol.
                        </span>
                      </li>
                      <li>
                        <b>Empate (0:2):</b>
                        <span>
                          En este caso, el evento debe finalizar 4-2 para que la apuesta se
                          considere ganadora. En el caso de ser Empate (2:0), el evento debe
                          finalizar 0-2 para que la la apuesta se considere ganadora.
                        </span>
                      </li>
                    </ul>
                    <p style={{ textAlign: 'left' }}>
                      <b>Visitante (2:0):</b>
                      <span>
                        La apuesta se considerará ganadora si el equipo visitante gana por tres
                        goles o más.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <b>Handicap Asiático</b>
                    </p>
                    <p>
                      <span>
                        El mercado asiatico es un tipo especial de hándicap utilizado en encuentros
                        de fútbol. Dependiendo de la capacidad de cada equipo, un hándicap es
                        otorgado para el juego. Esto permite que las probabilidades para cada equipo
                        sean más similares, permitiendo más oportunidades de apuesta competitiva.
                        Todas las apuestas en hándicap Asiático en apuesta en vivo (incluyendo
                        apuestas de 1ra y 2da mitad) son establecidas de acuerdo al marcador. Los
                        goles anotados antes de que la apuesta sea realizada no son incluidos para
                        propósitos de calificación de apuestas.
                      </span>
                    </p>
                    <table className="table table-bordered table-condensed">
                      <tbody>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>Handicap Asiático</b>
                            </p>
                          </td>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>Significado de la apuesta</b>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>0</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana el partido. Si hay un empate (0 goles de
                              diferencia), tu apuesta es reembolsada.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>0.25</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana el partido. Si hay un empate, su apuesta se
                              divide por la mitad: una mitad se considera una victoria, la otra
                              mitad se considera un empate y sus apuestas son reembolsadas.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>0.5</b>
                            </p>
                          </td>
                          <td>
                            <span>Ganas si tu equipo empata o gana el partido.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>0.75</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana o empata el partido. Si pierde con una
                              diferencia de un gol, pierde la mitad de lo arriesgado.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>1</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana o empata el partido. Si pierde con una
                              diferencia de un gol, su apuesta es reembolsada.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>-0.25</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana el partido. Si empata, pierdes la mitad de lo
                              arriesgado.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>-0.5</b>
                            </p>
                          </td>
                          <td>
                            <span>Ganas si tu equipo gana el partido.</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>-0.75</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana el partido con una diferencia de dos o más
                              goles. Si gana con un gol, su apuesta se divide por la mitad: una
                              mitad se considera una victoria, la otra mitad se considera un empate
                              y sus apuestas son reembolsadas.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p style={{ textAlign: 'center' }}>
                              <b>-1</b>
                            </p>
                          </td>
                          <td>
                            <span>
                              Ganas si tu equipo gana el partido con una diferencia de dos o más
                              goles. Si gana con una diferencia de un gol, su apuesta es
                              reembolsada.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ul>
                    <li>
                      <b>
                        TOTAL GOLES - MÁS / MENOS:
                        <span>
                          TOTALES ó OVER/UNDER se trata de predecir el total de goles de un partido.
                          Si se interrumpe un evento, todas las apuestas serán nulas, a menos que el
                          resultado de la apuesta realizada ya haya sido determinado.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Ejemplo: Más de (3.5), en el partido a disputar, la suma de los goles hechos
                      por ambos equipos debe ser mayor a 3.5, es decir, 4 goles o más.
                    </span>
                  </p>
                  <ul>
                    <li>
                      <b>
                        AMBOS EQUIPOS ANOTÀRAN:
                        <span>
                          Predecir si ambos equipos marcarán al menos un gol en el juego. Los goles
                          propios cuentan para el equipo al que se le atribuye el gol. Si se
                          interrumpe un partido, las apuestas serán nulas, a menos que el resultado
                          de estas apuestas ya esté determinado.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <div>
                        <b>TOTAL DE GOLES DESPUÉS DE X MINUTOS JUGADOS: </b>Este mercado hace
                        referencia al total de goles que se realicen durante el tiempo que se haya
                        seleccionado.
                      </div>
                      <div></div>
                      <div></div>
                    </li>
                    <li>
                      <strong>Ejemplo:</strong> Total de goles después de 70 minutos jugados
                      <b>(Menos de 1.5)</b>
                    </li>
                  </ul>
                  <div>
                    La apuesta se considerará ganadora si hacen menos de 2 goles antes de los 70 min
                    de juego.
                  </div>
                  <div>
                    Si los goles son marcados después del minuto 70, no se tomarán en cuenta.
                  </div>
                  <div></div>
                  <ul>
                    <li>
                      Total de goles después de 80 minutos jugados <b>(Más de 1.5)</b>
                    </li>
                  </ul>
                  <div>
                    La apuesta se considerará ganadora si hacen más de 2 goles antes de los 80 min
                    de juego.
                  </div>
                  <div>
                    Si los goles son marcados después del minuto 80, no se tomarán en cuenta.
                  </div>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>AMBOS EQUIPOS ANOTÀRAN 2+ GOLES:</b>
                      <span>
                        al igual que el mercado anterior, se decide si ambos equipos anotan, SI o
                        NO. La diferencia en éste radica en que de elegir SI, ambos equipos deben
                        anotar 2 goles o más.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        EMPATE Y AMBOS EQUIPOS MARCAN:
                        <span>
                          este mercado se resuelve de la misma forma que los anteriores, SI o NO. La
                          peculiaridad de éste mercado es que de elegir SI, deben cumplirse las dos
                          condiciones; ambos equipos deben anotar, y el evento debe terminar en
                          empate.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>EMPATE APUESTA NO VÁLIDA:</strong>
                      <span>
                        Predecir qué equipo será el ganador. En caso de empate, todas las apuestas
                        serán nulas. Si se interrumpe un juego, las apuestas serán nulas.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>PRIMER GOL SE MARCA INTERVALO DE CADA (15 MIN):</strong>
                      <span>
                        este mercado da la opción en intervalos de 15 minutos en el que el apostador
                        elige cuándo se realizará el primer gol. Así como también tiene la opción de
                        decir que no habrá goles en todo el partido.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li style={{ listStyleType: 'none' }}>
                      <ul>
                        <li>
                          <b>PAR/IMPAR:</b>
                          <span>
                            Predecir si el puntaje final del evento sumará un puntaje par o impar.
                            Cuando no se marcan goles, se considera como ”Par” a efectos de
                            liquidación. Si se interrumpe un partido, las apuestas serán nulas, a
                            menos que ya se haya determinado un resultado.
                          </span>
                        </li>
                        <li>
                          <b>Par/Impar Por Equipos</b>
                          <b>: </b>
                          <span>
                            Predice si los goles acumulados por un equipo específico sumarán un
                            número par o impar. Cuando ese equipo no marca goles, se considera como
                            “Par” a efectos de liquidación. Si se interrumpe un partido, las
                            apuestas serán nulas, a menos que ya se haya determinado un resultado.
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        MARGEN DE VICTORIA:
                        <span>
                          Este mercado se basa en predecir qué equipo ganará con qué margen de
                          ventaja, o si el evento terminará en empate.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>MARCADOR CORRECTO:</strong>
                      <span>
                        Predice el marcador correcto del partido eligiendo entre los resultados
                        dados. Hay otra variación de este mercado disponible: Puntuación exacta de
                        la 1era mitad - hay que predecir la puntuación correcta de la primera mitad
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <span>Por ejemplo:</span>
                  </p>
                  <p>
                    <span>
                      Usted apuesta en la línea: 1:0, 2:0, 3:0. Si el resultado final es 1:0 o 2:0 o
                      3:0, usted ganará. Si se produce cualquier otro resultado, su apuesta será
                      perdedora.este mercado trata de predecir el marcador correcto de las opciones
                      ofertadas, en caso de no estar disponible la opción deseada, estará la opción
                      OTRO.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        DOBLE OPORTUNIDAD:
                        <span>
                          en éste mercado, como su nombre lo dice, se tiene la oportunidad de
                          predecir dos de los tres posibles resultados del partido. Abarcando las
                          opciones: 1/X = Equipo local o empate, 1/2 = Equipo local o Equipo
                          visitante, y X/2 = empate o Equipo visitante.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li style={{ listStyleType: 'none' }}>
                      <ul>
                        <li>
                          <b>¿PRIMER EQUIPO EN MARCAR? </b>
                          <span>
                            Este mercado, da la opción de elegir cuál equipo marcará el primer gol
                            del partido, así como también la opción de que no haya goles.
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        ¿ÚLTIMO EQUIPO EN MARCAR?Da
                        <span>
                          la opción de elegir cuál equipo marcará el último gol del partido, así
                          como también la opción de que no haya goles.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TOTAL GOALS:</strong>
                      <span>
                        no confundir con los totales (Over/Under), éste mercado se enfoca en la
                        cantidad exacta de goles que habrá en el partido, es decir, la suma de los
                        goles del equipo local y el equipo visitante.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TOTAL GOLES 1:</strong>
                      <span>
                        Engloba la suma de los goles de ambos equipos. La diferencia es que éste
                        mercado tiene la opción SÍ; la cual afirma una cantidad de goles. Y la
                        opción NO; que es cualquier cantidad de&nbsp; goles que no sean la cantidad
                        seleccionada.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>¿EN QUÉ TIEMPO SE MARCARÁ EL 1ER GOL?:</strong>
                      <span>
                        Predecir en qué tiempo se marcará el primer gol, también tiene la opción de
                        empate.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TOTAL DE GOLES PRIMERA MITAD/SEGUNDA MITAD:</strong>
                      <span>
                        Este mercado consta de una lista de opciones a elegir de entre las cuales se
                        cree que sean los goles que marcarán en el primer y segundo tiempo. También,
                        al igual que el mercado TOTAL GOLES 1, tiene la opción adicional de SI y NO.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>MINUTO DEL ÚLTIMO GOL (15 MIN):</strong>
                      <span>
                        el mismo criterio que el mercado PRIMER GOL SE MARCA INTERVALO DE CADA (15
                        MIN), solo que aplica para el último gol del partido.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>GOL EN LOS PRIMEROS X MIN:</strong>
                      <span>
                        Esta es una variedad de mercados que pueden variar la cantidad X de tiempo,
                        dependiendo del evento. Tienen las opciones de SI y NO para el caso de dicho
                        intervalo.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>¿EQUIPO LOCAL/VISITANTE MARCARÁ EN AMBOS TIEMPOS?:</strong>
                      <span>
                        mercado enfocado en un equipo (local o visitante) y si dicho equipo anotará
                        o no en ambas mitades del partido.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TIEMPO DE MAYOR GOLES:</strong>
                      <span>el tiempo en el que se marcarán la mayor cantidad de goles.</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>EQUIPO LOCAL/VISITANTE - ¿GANARÁ AL MENOS UN TIEMPO?:</strong>
                      <span>
                        como el nombre del mercado lo sugiere, el equipo a seleccionar debe ser el
                        ganador de al menos una de las mitades del evento. En otras palabras, el
                        equipo que más goles anotó en una mitad.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>LOCAL/VISITANTE GANA SIN RECIBIR GOL:</strong>
                      <span>
                        éste mercado hace énfasis en que el equipo seleccionado no solo gana el
                        partido, debe hacerlo sin haber recibido goles.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>LOCAL/VISITANTE NO SUFRE GOL:</strong>
                      <span>
                        igual que el mercado anterior LOCAL/VISITANTE GANA SIN RECIBIR GOL, la
                        diferencia es que en éste mercado también cuenta si el evento termina en
                        empate (0-0).
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>LOCAL/VISITANTE APUESTA NO VÁLIDA:</strong>
                      <span>
                        es una “versión alternativa” del EMPATE APUESTA NO VÁLIDA, la diferencia es
                        que en ésta opción la apuesta no válida es uno de los dos equipos, el cual
                        será especificado en el nombre del mercado al momento de la apuesta.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TOTAL DE TIROS DE ESQUINA (MAS / MENOS) O EXACTO:</strong>
                      <span>
                        al igual que totales, éste mercado trata de predecir la cantidad de tiros de
                        esquina que se harán en el partido, dando la opción menos de, más de o
                        exactamente una cantidad específica.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>¿QUIÉN COBRA EL ÚLTIMO TIRO DE ESQUINA?:</strong>
                      <span>Predecir qué equipo cobrará el último tiro de esquina.</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>NÚMERO EXACTO DE GOLES:</strong>
                      <span>
                        este mercado dará una opción amplia del cual sería la suma total de los
                        goles anotados en el partido, también incluye la opción SIN GOLES.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>PRÓXIMO EQUIPO EN MARCAR:</b>
                      <span>
                        este mercado normalmente se ve disponible en las apuestas en vivo, trata de
                        predecir cual será el equipo en hacer el siguiente gol, teniendo también en
                        cuenta la opción NO, dando a entender que no habrá próximo gol en el evento.
                      </span>
                    </li>
                    <li>
                      <b>EQUIPOS SIN RECIBIR GOL</b>
                      <b>: </b>
                      <span>
                        Marcador en blanco significa que un equipo terminará el juego sin recibir un
                        gol. Los goles propios son válidos a efectos de liquidación. Si se
                        interrumpe un juego, todas las apuestas serán nulas, a menos que la
                        liquidación ya esté determinada incondicionalmente.
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span>Por ejemplo:&nbsp;</span>
                  </p>
                  <p>
                    <span>
                      Equipo A en blanco "Sí" - Para que la apuesta se resuelva como ganador el
                      equipo A no debe recibir ningún gol (incluyendo un gol en propia puerta) en el
                      juego. Si lo hace, la apuesta se pierde.
                    </span>
                  </p>
                  <p>
                    <span>
                      Equipo A en blanco "No" - Para que la apuesta se resuelva como ganador el
                      equipo A debe recibir un gol (un gol en propia puerta también cuenta) en el
                      juego. Si no lo hace, la apuesta se pierde..
                    </span>
                  </p>
                  <p>
                    <b>MEDIO TIEMPO/TIEMPO COMPLETO: </b>
                    <span>
                      Predice el resultado de la primera mitad y el tiempo completo por separado. Si
                      se interrumpe un partido, las apuestas serán nulas.
                    </span>
                  </p>
                  <p>
                    <span>
                      Por ejemplo: para ganar una apuesta de "1/X", el equipo local debe ganar el
                      primer tiempo y el resultado del partido debe ser un empate. El tiempo extra y
                      la tanda de penaltis no cuentan.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        MERCADOS ESPECIALES: los mercados especiales son mercados que tratan de
                        predecir situaciones particulares en el partido. Estas situaciones
                        particulares pueden variar dependiendo del evento en sí, el torneo, la
                        división, etc. Por lo que no siempre van a ser las mismas predicciones. EN
                        CASO DE TENER DUDAS AL MOMENTO DE SELECCIONAR UN MERCADO ESPECIAL, SIEMPRE
                        SE RECOMIENDA CONTACTAR A LOS EJECUTIVOS DE ATENCIÓN AL CLIENTE PARA MAYOR
                        INFORMACIÓN.
                      </b>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE FÚTBOL/SOCCER. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA
                      PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS. CABE
                      DESTACAR, LOS MERCADOS ANTES MENCIONADOS HARÁN ÉNFASIS EN SI TOMARÁN EN CUENTA
                      O NO EL TIEMPO EXTRA (INCL. OT), Y EN OTROS CASOS SI SÓLO TOMARÁ EN CUENTA EL
                      TIEMPO REGULAR (RT).
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="basquetbol" style={{ textAlign: 'center' }}>
                    REGLAS DE BASQUETBOL
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los partidos deben comenzar en la fecha prevista (hora local) para que
                        las apuestas tengan acción. Se hace una excepción si se anuncia una hora de
                        inicio incorrecta en nuestra página web. Si las apuestas se ofrecen con una
                        hora de partido incorrecta (más de 5 minutos), nos reservamos el derecho de
                        anular cualquier apuesta.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si se cambia la sede de un partido, las apuestas ya realizadas se mantendrán
                        siempre que el equipo local siga siendo designado como tal. Si el equipo
                        local y el visitante de un partido se invierten, las apuestas basadas en el
                        listado original serán anuladas.
                      </span>
                    </p>
                    <p>
                      <span>
                        Para que las apuestas tomen acción los juegos de 48 minutos deben durar al
                        menos 43 minutos y los juegos de 40 minutos deben durar al menos 35 minutos.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si un partido no termina en empate, pero se juega una prórroga con fines de
                        clasificación, los mercados se liquidarán de acuerdo con el resultado al
                        final del tiempo reglamentario.
                      </span>
                    </p>
                    <p>
                      <span>
                        Los mercados no tienen en cuenta la Prórroga-Tiempo Extra a menos que se
                        indique lo contrario en la descripción del mismo, (Incl. OT o Sólo OT):
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Ganador (Incl. OT)</span>
                      </li>
                      <li>
                        <span>Handicap Asiático (Incl. OT)</span>
                      </li>
                      <li>
                        <span>Ganador (Incl. OT) &amp; Más / Menos (Incl .OT)</span>
                      </li>
                      <li>
                        <span>Más / Menos (Incl .OT)</span>
                      </li>
                      <li>
                        <span>Equipo Local - Más / Menos (Incl .OT)</span>
                      </li>
                      <li>
                        <span>Equipo Visitante - Más / Menos (Incl .OT)</span>
                      </li>
                      <li>
                        <span>Margen de victoria (Incl. OT)</span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        Nota: Incl. OT implica resultado del tiempo regular y la Prórroga-Tiempo
                        extra, en el caso de que el mercado diga Sólo OT u Only OT el tiempo regular
                        no tendrá validez para efectos de esa apuesta.
                      </b>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del basquetbol</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando un partido sea aplazado, abandonado o interrumpido por cualquier
                          motivo y no continúe en un plazo de 48 horas, se anularán todas las
                          apuestas no determinadas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando las cuotas se ofrezcan con un tiempo de partido incorrecto (más de
                          5 minutos), nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Liga de campeonato - Si un partido termina en empate y no se juega la
                          prórroga, las apuestas de tiempo regular (2 vías) se aplicará la regla
                          push. Si hay prórroga, los mercados se liquidarán en base al resultado
                          oficial.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido no termina en empate, pero se juega la prórroga a efectos de
                          clasificación, los mercados se liquidarán según el resultado al final del
                          tiempo reglamentario.
                        </span>
                      </li>
                    </ul>
                    <h3>Mercados</h3>
                    <ul>
                      <li>
                        <strong>GANADOR DEL PARTIDO:</strong>
                        <span>
                          Te permite seleccionar una victoria del local o una victoria del
                          visitante.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>EMPATE APUESTA NO VÁLIDA (Tiempo Regular):</strong>
                        <span>
                          Si el partido termina en un empate después del tiempo reglamentario del
                          juego, todas las apuestas son consideradas nulas.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>DOBLE OPORTUNIDAD:</strong>
                        <span>
                          Predecir dos de los tres posibles resultados del partido. Abarcando las
                          opciones: 1/X = Equipo local o empate, 1/2 = Equipo local o Equipo
                          visitante, y X/2 = empate o Equipo visitante.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <b>HÁNDICAP: </b>
                        <span>
                          Predice el resultado del juego después de aplicado el hándicap al
                          resultado final.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>Por ejemplo:</span>
                    </p>
                    <p>
                      <span>Warriors: (+6,5) 2.0</span>
                    </p>
                    <p>
                      <span>Indiana: (-6,5) 1.8</span>
                    </p>
                    <p>
                      <span>
                        Si Indiana gana el partido (100-96), las apuestas en el hándicap serán
                        perdedoras. Esto es porque el propósito de la apuesta sería empezar el
                        partido con -6.5 puntos, y después los marcadores tendrían que restar 6.5
                        puntos del resultado final. Esto haría que el evento terminara 93.5-96 a
                        favor de los Warriors
                      </span>
                    </p>
                    <p>
                      <span>
                        Si Indiana gana 105-95, al ajustar el hándicap sería 98.5-95, lo que
                        significa que Indiana todavía quedaría como ganadora.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        <strong>TOTAL PUNTOS ( MÁS DE / MENOS DE ):</strong>
                        <span>
                          <strong> TOTAL PUNTOS ó OVER/UNDER</strong> es un mercado muy conocido
                          donde se trata de predecir si el puntaje total de un partido sumará más o
                          menos que un monto específico. Ejemplo: Más de (219.5), en el partido a
                          disputar, la suma de los puntos hechos por ambos equipos debe ser mayor a
                          219.5, es decir, 220 puntos o más.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>DESCANSO/FINAL OR DOBLE RESULTADO:</strong>
                        <span>
                          este mercado, por su nombre, puede llegar a ser un poco confuso. Lo que
                          realmente trata de predecir este mercado es, cuál será el equipo que gane
                          en el medio tiempo, y cuál será el equipo ganador a tiempo completo,
                          permitiendo la opción del empate. Ejemplo:
                        </span>
                        Empate/Local
                        <span>
                          , esta opción resulta ganadora si al final del 2do cuarto, es decir el
                          final de la primera mitad, el marcador está empatado, y al final del
                          partido el equipo local gana.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>TIEMPO EXTRAORDINARIO (SI/NO):</strong>
                        <span>
                          este mercado se resuelve de forma muy sencilla, como su nombre lo dice, es
                          predecir si el evento llegará a tiempo extra.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>PRIMERO EN ANOTAR X PUNTOS:</strong>
                        <span>
                          Se debe elegir entre las 3 columnas de opciones, siendo la primera columna
                          opciones de puntaje al que el equipo local llegará primero, la segunda
                          columna es el empate, y la tercera columna vendría siendo las opciones de
                          puntaje al que el equipo visitante llegará primero. Ahora, ¿por qué hay
                          una opción de empate? Es imposible que ambos equipos lleguen al mismo
                          tiempo a un puntaje establecido, por lo que al tener esto en cuenta la
                          opción del empate hace énfasis en que
                        </span>
                        NINGUNO
                        <span>de los equipos llegará al puntaje determinado.</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>PAR/IMPAR:</strong>
                        <span>
                          este mercado se trata de predecir si el puntaje final del evento sumará un
                          puntaje par o impar.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>PRIMERA MITAD:</strong>
                        <span>
                          Todos los mercados de la 1ª mitad (2 opciones, 3 opciones, asiáticos, Más
                          / Menos, Total de puntos del equipo, Total de pares / impares y Total de
                          pares / impares del equipo) se liquidarán de acuerdo con el resultado de
                          la primera mitad únicamente. Si el juego se interrumpe antes del medio
                          tiempo, todas las apuestas del primer tiempo serán anuladas.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>SEGUNDA MITAD:</strong>
                        <span>
                          Todos los mercados de la segunda mitad (2 opciones, asiáticos, más /
                          menos, total de puntos del equipo, total impar / par y total impar / par
                          del equipo) se liquidarán de acuerdo con el resultado de la segunda mitad,
                          incluido el tiempo extra si se juega. Si el juego se interrumpe, las
                          apuestas se anularán. Se hace una excepción si quedan 5 minutos o menos de
                          tiempo de juego programado. En este caso, las apuestas se liquidarán de
                          acuerdo con el resultado en el momento de la interrupción.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <b>Más/Exactamente/ Menos:</b>
                        <span>
                          Predice el total de puntos anotados por ambos equipos, el cual estará por
                          encima, exactamente o por debajo de un número específico dado.
                        </span>
                      </li>
                      <li>
                        <b>
                          MERCADOS ESPECIALES: los mercados especiales son mercados que tratan de
                          predecir situaciones particulares en el partido. Estas situaciones
                          particulares pueden variar dependiendo del evento en sí, el torneo, la
                          división, etc. Por lo que no siempre van a ser las mismas predicciones. EN
                          CASO DE TENER DUDAS AL MOMENTO DE SELECCIONAR UN MERCADO ESPECIAL, SIEMPRE
                          SE RECOMIENDA CONTACTAR A LOS EJECUTIVOS DE ATENCIÓN AL CLIENTE PARA MAYOR
                          INFORMACIÓN.
                        </b>
                      </li>
                    </ul>
                    <p>
                      <b>
                        NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                        DEPORTE DE BASQUETBOL. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA
                        PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS. CABE
                        DESTACAR, LOS MERCADOS ANTES MENCIONADOS HARÁN ÉNFASIS EN SI TOMARÁN EN
                        CUENTA O NO EL TIEMPO EXTRA (INCL. OT), Y EN OTROS CASOS SI SÓLO TOMARÁ EN
                        CUENTA EL TIEMPO REGULAR (RT).
                      </b>
                    </p>
                    <p>
                      <b>
                        TAMBIÉN SE DEBE HACER ÉNFASIS EN LAS DIFERENCIAS ENTRE APUESTAS POR
                        “TIEMPOS” Y “CUARTOS”, YA QUE BASQUETBOL ES UN DEPORTE DONDE SE JUEGA POR
                        CUARTOS, LAS APUESTAS POR “TIEMPO” SE DEFINEN COMO APUESTAS A PRIMERA MITAD
                        (1ER TIEMPO) Y SEGUNDA MITAD (2DO TIEMPO). MIENTRAS LAS APUESTAS A CUARTOS O
                        PERIODOS SE REALIZAN CON TOTAL NORMALIDAD, 1ºCUARTO, 2º CUARTO, 3º CUARTO Y
                        4ºCUARTO.
                      </b>
                    </p>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="tenis" style={{ textAlign: 'center' }}>
                    REGLAS DE TENIS
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        En caso de producirse alguna de las siguientes circunstancias, todas las
                        apuestas se mantendrán:&nbsp;
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Un cambio de horario y/o día de partido.</span>
                      </li>
                      <li>
                        <span>Un cambio de sede.</span>
                      </li>
                      <li>
                        <span>Un cambio de pista cubierta a pista exterior o viceversa.</span>
                      </li>
                      <li>
                        <span>Un cambio de superficie (antes o durante el partido).</span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Un retraso en el inicio de un partido no afectará a la validez de las
                        apuestas, ni tampoco una suspensión, siempre que el juego se reanude y el
                        partido se complete dentro de las 48 horas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Cuando se produzca la retirada o el abandono de cualquier jugador, todas las
                        apuestas no decididas serán anuladas. Mientras que las apuestas decididas
                        serán calificadas acorde al resultado oficial.
                      </span>
                    </p>
                    <p>
                      <span>
                        Cuando un partido finaliza antes de que ciertos puntos/juegos hayan
                        terminado, todos los mercados relacionados con los puntos/juegos afectados
                        se consideran nulos.
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del tenis</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando los jugadores/equipos se muestren de forma incorrecta, nos
                          reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un jugador se retira, todos los mercados no decididos se consideran
                          anulados.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un partido se decida por un Súper tie-break, se considerará el
                          tercer set.
                        </span>
                      </li>
                      <li>
                        <span>Cada tie-break o Súper tie-break cuenta como 1 juego.</span>
                      </li>
                    </ul>
                    <h3>Mercados</h3>
                    <ul>
                      <li>
                        <strong>GANADOR DEL PARTIDO:</strong>
                        <span>Predice qué jugador será el ganador del juego.</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>SET - GANADOR:</strong>
                        <span>
                          este mercado define qué jugador ganará qué set. Ejemplo: 2º SET GANADOR =
                          el jugador que ganará el segundo set.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>TOTAL DE JUEGOS (MÁS / MENOS):</strong>
                        <span>
                          Se refiere a los juegos en el partido. Ganar/Perder es determinado por el
                          número de juegos acumulados por ambos jugadores. Si el número de juegos
                          reglamentario no se completa todas las apuestas quedarán anuladas. Un
                          súper tie break será considerado como un juego para las apuestas
                          Más/Menos.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>GANADOR JUEGO (SET / JUEGO):</strong>
                        <span>
                          Este mercado trata de predecir que jugador va a ganar un juego en
                          especifico.
                        </span>
                        <span>
                          Ejemplo: 2 (2/9) = El jugador visitante gana en el segundo set el juego 9.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <b>MARCADOR CORRECTO:</b>
                        <span>
                          Este mercado trata de predecir el marcador correcto de las opciones
                          ofertadas. Si el juego no se completa por cualquier razón, todas las
                          apuestas son anuladas.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>Por ejemplo:</span>
                    </p>
                    <p>
                      <span>Marcador Correcto 2-0</span>
                    </p>
                    <ul>
                      <li>
                        <strong>HÁNDICAP ASIÁTICO (JUEGOS):</strong> Se refiere a que jugador será
                        el ganador en el total de juegos otorgando una ventaja o desventaja al
                        jugador de acuerdo a tu selección.
                        <span>
                          Si el juego no se completa por cualquier razón, todas las apuestas son
                          anuladas.
                        </span>
                      </li>
                    </ul>
                    <p>
                      Ejemplo: 1(2.5) Tu selección será ganadora si el local posee una cantidad de
                      juegos mayor al visitante o si pierde por 2 juegos.
                    </p>
                    <ul>
                      <li>
                        <strong>GANADOR CON HÁNDICAP – SETS:</strong> Se refiere a que jugador será
                        el ganador en el total de Sets otorgando una ventaja o desventaja al jugador
                        de acuerdo a tu selección.
                        <span>
                          Si el juego no se completa por cualquier razón, todas las apuestas son
                          anuladas.
                        </span>
                      </li>
                    </ul>
                    <p>
                      Ejemplo: 2(-1.5) Tu selección será ganadora si el visitante gana con una
                      ventaja de 2 sets.
                    </p>
                    <ul>
                      <li>
                        <b>Total de Juegos Par/Impar: </b>
                        <span>
                          Predice si el número total de juegos dentro de cada partido será un número
                          par o impar. Si hubiere uno o más sets con ganador de tie break registrado
                          todas las apuestas de este mercado serán válidas para la liquidación.
                        </span>
                      </li>
                      <li>
                        <b>Jugador A Ganar más Juegos: </b>
                        <span>
                          Uno de los dos jugadores/equipos tendrá que ganar el mayor número de
                          juegos dentro del partido para ganar este mercado. El mercado de empate
                          (tie) 1X2 significa que el número de juegos entre los dos
                          jugadores/equipos debe ser igual.
                        </span>
                        <span>
                          Si hubiere uno o más sets con ganador de tie break registrado todas las
                          apuestas de este mercado serán válidas para la liquidación.
                        </span>
                      </li>
                      <li>
                        <b>Ganador del Juego: </b>
                        <span>
                          Predice qué jugador será el ganador del juego. El juego escogido se
                          describe en el nombre del tipo de apuesta, por ejemplo: 2nd Set – 7th Game
                          – Winner
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Si un juego no se completa por cualquier razón, las apuestas serán
                        canceladas. Los puntos tie break no son considerados en este tipo de
                        apuesta.
                      </span>
                    </p>
                    <p>
                      <b>
                        NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                        DEPORTE DE TENIS. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA PÁGINA
                        SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.
                      </b>
                    </p>
                    <p>
                      <b>
                        TAMBIÉN SE HACE MENCIÓN A QUE ESTE DEPORTE SE JUEGA POR SETS, POR LO QUE
                        MERCADOS QUE SE JUEGUEN POR “MITADES” NO EXISTEN, Y EN CASO DE UN MERCADO
                        HACER ÉNFASIS EN “PERIODOS”, SE TOMARÁ COMO SETS.
                      </b>
                    </p>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="americano" style={{ textAlign: 'center' }}>
                    REGLAS DE FUTBOL AMERICANO
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los partidos deben comenzar en la fecha prevista (hora local) para que
                        las apuestas tengan acción.&nbsp;
                      </span>
                      <span>
                        Los partidos abandonados o aplazados son nulos a menos que se vuelvan a
                        organizar y se jueguen en la misma fecha (hora local) o se indique lo
                        contrario en las reglas.
                      </span>
                    </p>
                    <p>
                      <span>
                        Todas las apuestas al resultado del partido se reembolsan, a menos que se
                        indique lo contrario, excepto las apuestas a cualquier mercado que se haya
                        determinado de forma incondicional.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si se cambia el lugar de celebración de un partido, las apuestas ya
                        realizadas se mantendrán siempre que el equipo local siga siendo designado
                        como tal. Si el equipo local y el visitante de un partido se invierten, las
                        apuestas basadas en el listado original serán anuladas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        En caso de cualquier retraso (lluvia, oscuridad o cualquier otra razón)
                        todos los mercados permanecerán sin determinar y tendrán validez tan pronto
                        como el partido continúe (siempre y cuando sea dentro de las 48 horas).
                      </span>
                    </p>
                  </div>
                  <p>
                    <b>Temporada regular:</b>
                    <span>
                      las apuestas de tiempo completo de 3 vías de Moneyline se liquidan en tiempo
                      completo o después de que se haya jugado el tiempo extra. El tiempo extra
                      podría resultar en un empate en los juegos de la temporada regular.
                    </span>
                  </p>
                  <p>
                    <span>&nbsp;</span>
                    <b>Playoffs:</b>
                    <span>
                      las apuestas de tiempo completo de 3 vías de Moneyline se liquidan en tiempo
                      completo, excluyendo las horas extras.
                    </span>
                  </p>
                  <p>
                    <span>
                      Los juegos deben durar al menos 55 minutos para que las apuestas tengan
                      acción. Si se suspende un juego antes de que se jueguen 55 minutos completos,
                      se reembolsará todas las apuestas sobre el resultado del juego a menos que se
                      indique lo contrario, excepto las apuestas en cualquier mercado que se haya
                      determinado incondicionalmente.
                    </span>
                  </p>
                  <div>
                    <p>
                      <span>
                        Los mercados no tienen en cuenta la Prórroga-Tiempo Extra a menos que se
                        indique lo contrario en la descripción del mercado (Incl. OT o Sólo OT):
                      </span>
                    </p>
                    <p>
                      <span>Ganador del partido (Incl. OT)</span>
                    </p>
                    <p>
                      <span>Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Handicap Asiático (Incl. OT)</span>
                    </p>
                    <p>
                      <span>Equipo Local - Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Equipo Visitante - Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Equipo Local - Par / Impar (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Equipo Visitante - Par / Impar (Incl .OT)</span>
                    </p>
                    <p>
                      <b>
                        Nota: Incl. OT implica el resultado del tiempo regular y la Prórroga-Tiempo
                        extra, en el caso de que el mercado diga Sólo OT u Only OT el tiempo regular
                        no tendrá validez para efectos de esa apuesta.
                      </b>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del fútbol americano</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Si las cuotas se ofrecen con un tiempo de partido incorrecto (más de 5
                          minutos), nos veremos obligados a anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si los equipos se muestran de forma incorrecta, nos reservamos el derecho
                          de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido es aplazado, abandonado o interrumpido por cualquier motivo
                          y no continúa en un plazo de 48 horas, todas las apuestas no realizadas
                          serán anuladas.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR DEL PARTIDO:
                        <span>
                          Predecir el ganador del juego. Las apuestas incluyen tiempo extra si se
                          juega.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TOTAL PUNTOS - MÁS / MENOS: </strong>
                      <span>
                        <strong>TOTAL PUNTOS ó OVER/UNDER</strong> se trata de predecir si el
                        puntaje total de un partido sumará más o menos que un monto específico.
                        Ejemplo: Más de (43.5), en el partido a disputar, la suma de los puntos
                        hechos por ambos equipos debe ser mayor a 43.5, es decir, 44 puntos o más.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>HÁNDICAP:</strong>
                      <span>
                        Predice el resultado del juego después de aplicado el hándicap al resultado
                        final.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>DESCANSO/FINAL:</strong>
                      <span>
                        predecir este mercado es, cuál será el equipo que gane en el medio tiempo, y
                        cuál será el equipo ganador a tiempo completo, permitiendo la opción del
                        empate. Ejemplo:
                      </span>
                      Empate/Visitante
                      <span>
                        , esta opción resulta ganadora si al final del 2do cuarto, es decir el final
                        de la primera mitad, el marcador está empatado, y al final del partido el
                        equipo Visitante gana.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>TIEMPO EXTRAORDINARIO (SI/NO):</strong>
                      <span>Predecir si el evento llegará a tiempo extra.</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>CUARTO CON MÁS ANOTACIÓN:</strong>
                      <span>
                        Predecir cuál de los cuartos será en el que se haga la mayor cantidad de
                        puntos, dando la posibilidad de elegir la opción
                      </span>
                      IGUALES
                      <span>
                        , la cual indica que ningún cuarto tendrá mayor cantidad de puntos que los
                        otros.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>MARGEN DE VICTORIA:</strong>
                      <span>
                        Cuál será el margen de victoria que tendrá un equipo sobre el otro, dando la
                        opción del EMPATE. Ejemplo: EQUIPO LOCAL 8-14. Para que se dé la apuesta, el
                        partido debe terminar con el equipo local ganando con una ventaja
                        comprendida entre 8 y 14 puntos, es decir 12-0, 30-20, 27-19, etc… si el
                        margen de victoria del evento no está comprendido entre los límites
                        elegidos, la apuesta es perdedora independientemente de que el equipo local
                        haya sido el ganador del partido.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>PAR/IMPAR:</strong>
                      <span>
                        este mercado trata de predecir si el puntaje final del evento sumará un
                        puntaje par o impar.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>DOBLE OPORTUNIDAD:</strong>
                      <span>
                        en éste mercado, como su nombre lo dice, se tiene la oportunidad de predecir
                        dos de los tres posibles resultados del partido. Abarcando las opciones: 1/X
                        = Equipo local o empate, 1/2 = Equipo local o Equipo visitante, y X/2 =
                        empate o Equipo visitante.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>EMPATE APUESTA NO VÁLIDA:</b>
                      <span>
                        este mercado es el muy bien conocido 2-way. Es decir, de darse la opción del
                        empate, las apuestas son reembolsadas.
                      </span>
                    </li>
                    <li>
                      <b>PRIMERA MITAD: </b>
                      <span>
                        Las apuestas se decidieron solo en el resultado de la primera mitad. Las
                        apuestas se anularán si el partido se abandona antes del medio tiempo. Si un
                        partido se abandona durante la segunda mitad, todas las apuestas de la
                        primera mitad siguen siendo válidas.
                      </span>
                    </li>
                    <li>
                      <b>SEGUNDA MITAD:</b>
                      <span>
                        Las apuestas establecidas en el resultado de la segunda mitad solo incluyen
                        tiempos extras.
                      </span>
                    </li>
                    <li>
                      <b>1er / 2do / 3er cuarto</b>
                      <span>: Las apuestas establecidas solo en los cuartos de resultados.</span>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Si no se completa todo el juego, las apuestas en cuartos tendrán acción en
                      caso de que se complete el período relevante.
                    </span>
                  </p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE FÚTBOL AMERICANO/NFL. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS
                      EN LA PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.
                      CABE DESTACAR, LOS MERCADOS ANTES MENCIONADOS HARÁN ÉNFASIS EN SI TOMARÁN EN
                      CUENTA O NO EL TIEMPO EXTRA (INCL. OT), Y EN OTROS CASOS SI SÓLO TOMARÁ EN
                      CUENTA EL TIEMPO REGULAR (RT).
                    </b>
                  </p>
                  <p>
                    <b>
                      TAMBIÉN SE DEBE HACER ÉNFASIS EN LAS DIFERENCIAS ENTRE APUESTAS POR “TIEMPOS”
                      Y “CUARTOS”, YA QUE BASQUETBOL ES UN DEPORTE DONDE SE JUEGA POR CUARTOS, LAS
                      APUESTAS POR “TIEMPO” SE DEFINEN COMO APUESTAS A PRIMERA MITAD (1ER TIEMPO) Y
                      SEGUNDA MITAD (2DO TIEMPO). MIENTRAS LAS APUESTAS A CUARTOS O PERIODOS SE
                      REALIZAN CON TOTAL NORMALIDAD, 1ºCUARTO, 2º CUARTO, 3º CUARTO Y 4ºCUARTO.
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="baseball" style={{ textAlign: 'center' }}>
                    REGLAS DE BÉISBOL
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Los nombres de los mercados no reflejan los términos reales utilizados en el
                        béisbol, por lo que podría haber alguna diferencia basada en las
                        traducciones, pero estos son los términos referidos:
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Nombre del mercado &nbsp; </i>
                      </b>
                      <b>
                        <i> -&nbsp; </i>
                      </b>
                      <b>
                        <i>Término usado en béisbol</i>
                      </b>
                    </p>
                    <p>
                      <span>Periodo</span>
                      <span> -</span>
                      <span> Inning</span>
                    </p>
                    <p>
                      <span>Prórroga(OT)&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                      <span> - </span>
                      <span> Extra Inning</span>
                    </p>
                    <p>
                      <span>
                        Mitad &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </span>
                      <span> - </span>
                      <span> Primeros 5 Innings</span>
                    </p>
                    <p>
                      <span>
                        Los mercados no consideran las prórrogas o extra inning a menos que se
                        indique lo contrario (Incl. OT o Sólo OT o Extra Inning):
                      </span>
                    </p>
                    <p>
                      <span>Ganador (Incl. OT)</span>
                    </p>
                    <p>
                      <span>Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Equipo Local - Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Equipo Visitante - Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Handicap Asiático (Incl. OT)</span>
                    </p>
                    <p>
                      <span>Par/Impar (Puntos) (Incl. OT)</span>
                    </p>
                    <p>
                      <span>Ganador (Incl. OT) &amp; Más / Menos (Incl .OT)</span>
                    </p>
                    <p>
                      <span>Multi Runs (Incl. OT)</span>
                    </p>
                    <p>&nbsp;</p>
                  </div>
                  <p>
                    <span>
                      Todas las apuestas de partidos de Pre-temporada se liquidan en función a las
                      carreras anotadas dentro del tiempo reglamentario acordado en el partido; el
                      mismo puede finalizar en empate en los partidos de pretemporada.
                    </span>
                  </p>
                  <p>
                    En cualquier situación en la que se aplique la regla de misericordia, los
                    partidos se consideran completos a efectos de apuestas, y todas las apuestas
                    Money line, totales y run line tendrán acción.
                  </p>
                  <p>
                    <span>
                      Una vez las páginas oficiales den por finalizado el encuentro, este tendrá
                      acción,&nbsp;también aplica para aquellos eventos de béisbol que estén
                      programados para 7 innings.
                    </span>
                  </p>
                  <p>
                    El juego debe durar al menos 9 innings completos (u 8 ½ innings si el equipo
                    local está ganando) para que las apuestas en la línea Más/Menos o Run line
                    tengan acción. De concluir el partido antes de lo estipulado, todas las apuestas
                    no decididas serán anuladas. Mientras que las apuestas decididas serán
                    calificadas acorde al resultado, es decir:
                  </p>
                  <ul>
                    <li>
                      <span>
                        Si se apostó al mercado <strong>Over/Under (7.5)</strong> y el evento antes
                        de ser declarado terminado llevan <strong>8</strong> carreras, las apuestas
                        realizadas a la opción
                        <strong>Under serán calificadas perdedoras</strong>, y las
                        <strong>Over como ganadoras</strong>.
                      </span>
                    </li>
                    <li>
                      <span>
                        Si se apostó al mercado <strong>Over/Under (7.5)</strong> y el evento antes
                        de ser declarado terminado llevan
                      </span>
                      <span>
                        <b>5</b> carreras, <strong>las apuestas serán anuladas</strong>.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <span>
                      El mercado de 3 opciones MoneyLine está relacionado con los primeros 9
                      innings. El acuerdo excluirá cualquier extra inning jugado.
                    </span>
                  </p>
                  <p>
                    <span>
                      El mercado de extra inning se refiere a cualquier extra inning jugado después
                      de que el resultado de los primeros 9 innings completos sea un empate.
                    </span>
                  </p>
                  <p>
                    <span>
                      El Período de Liquidación más Alto se liquidará con el resultado que incluye
                      cualquier extra inning jugado.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <div>
                    <p>
                      <b>
                        Nota: Incl. OT implica el resultado de los Innings regulares y los
                        Extra-Innings, en el caso de que el mercado diga Sólo OT u Only OT los
                        Innings regulares no tendrá validez para efectos de esa apuesta.
                      </b>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del béisbol</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Si un partido se interrumpe, se cancela o se pospone y no se continúa el
                          mismo día, todos los mercados no decididos se consideran anulados.
                        </span>
                      </li>
                      <li>
                        <span>
                          Como se menciona en la regla general, los primeros 5 Innings de un partido
                          se cuentan como la primera mitad, indiferentemente si el mismo tiene una
                          duración de&nbsp; 6 Innings o más. Es decir, una vez que el partido llegue
                          a la parte alta del 6to Inning, todas las apuestas de primera mitad tienen
                          validez, en caso de haber jugado juego completo, las líneas serán
                          calificadas con el resultado oficial.
                        </span>
                      </li>
                    </ul>
                    <h3>Mercados</h3>
                    <ul>
                      <li>
                        <strong>GANADOR:</strong>
                        <span>
                          Mejor conocido 2-way. A diferencia del 3-way, el 2-way te permite
                          seleccionar una victoria del local o una victoria del visitante, al no
                          tener la opción del empate, si el partido termina en empate, las apuestas
                          serán reembolsadas.
                        </span>
                        (Incluye tiempo extra)<span>.</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>GANADOR (1-X-2):</strong>
                        <span>
                          Este mercado es el conocido 3-way, dando a elegir entre una victoria del
                          local, un empate o una victoria del visitante.
                        </span>
                        (No incluye Tiempo extra)
                      </li>
                    </ul>
                    <p>
                      <span>1= Local</span>
                    </p>
                    <p>
                      <span>X= Empate</span>
                    </p>
                    <p>
                      <span>2= Visitante</span>
                    </p>
                    <ul>
                      <li>
                        <strong>TOTAL PUNTOS - MÁS / MENOS:</strong>
                        <span>
                          Predice el total el total de carreras logradas. Ejemplo: Más de (8.5), en
                          el partido a disputar, la suma de las carreras hechas por ambos equipos
                          debe ser mayor a 8.5, es decir, 9 carreras o más.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>HÁNDICAP ASIÁTICO:</strong>
                        <span>
                          El mercado asiatico es un tipo especial de hándicap. Dependiendo de la
                          capacidad de cada equipo, un hándicap es otorgado para el juego. Esto
                          permite que las probabilidades para cada equipo sean más similares,
                          permitiendo más oportunidades de apuesta competitiva. Todas las apuestas
                          en hándicap Asiático en apuesta en vivo son establecidas de acuerdo al
                          marcador.
                        </span>
                      </li>
                      <li>
                        <strong>MARGEN DE VICTORIA:</strong>
                        <span>
                          se trata de predecir cuál será el margen de victoria que tendrá un equipo
                          sobre el otro, dando la opción del EMPATE.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Ejemplo: EQUIPO VISITANTE 3-4. Para que se dé la apuesta, el partido debe
                        terminar con el equipo visitante ganando con una ventaja comprendida entre 3
                        y 4 carreras, es decir 3-0, 7-3, 10-6, etc… si el margen de victoria del
                        evento no está comprendido entre los límites elegidos, la apuesta es
                        perdedora independientemente de que el equipo visitante haya sido el ganador
                        del partido.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <strong>PAR/IMPAR:</strong>
                        <span>
                          este mercado trata de predecir si el puntaje final del evento sumará un
                          puntaje par o impar. El juego debe durar al menos 9 entradas completas (u
                          8½ entradas si el equipo local está por delante) para que las apuestas
                          tengan acción.
                        </span>
                      </li>
                      <li>
                        <strong>EXTRA INNING (SI/NO):</strong>
                        <span>se trata de predecir si el evento se definirá en extra inning.</span>
                      </li>
                      <li style={{ listStyleType: 'none' }}></li>
                    </ul>
                    <ul>
                      <li>
                        <strong>PRIMER EQUIPO A MARCAR:</strong>
                        <span>se trata de predecir qué equipo anotará la primera carrera.</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>ÚLTIMO EQUIPO A MARCAR:</strong>
                        <span>Se trata de predecir qué equipo anotará la última carrera.</span>
                      </li>
                      <li>
                        <strong>MULTI RUNS:</strong>
                        <span>
                          este mercado trata de predecir la cantidad de carreras que se harán en el
                          evento entre una cantidad de opciones a elegir, a diferencia de TOTAL
                          PUNTOS / MÁS O MENOS, las opciones no son más o menos que un puntaje
                          determinado, sino rangos de puntos. Ejemplo: 8-9. Para que la apuesta
                          resulte ganadora, la cantidad de carreras deben sumar 8 o 9 carreras,
                          cualquier otro resultado será perdedor.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li style={{ listStyleType: 'none' }}></li>
                    </ul>
                    <ul>
                      <li>
                        <strong>EQUIPO CON MAYOR PUNTUACIÓN EN UNA ENTRADA:</strong>
                        <span>
                          como su nombre lo indica, éste mercado trata de predecir qué equipo será
                          el que tenga la mayor cantidad de carreras en un inning. Dando también a
                          elegir la opción IGUALES.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>DESPUÉS DE 5 INNINGS / TIEMPO COMPLETO:</strong>
                        <span>
                          es el DESCANSO/FINAL del béisbol, tomando como primera mitad los primeros
                          5 innings del partido. Ejemplo:
                        </span>
                        Visitante/Local,
                        <span>
                          esta opción resulta ganadora si al final del 5to inning, es decir el final
                          de la primera mitad, el equipo visitante debe ir ganando el partido, y al
                          final del partido el equipo local gana.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>DOBLE RESULTADO / HT FT:</strong>
                        <span>
                          Predice el resultado de un partido en el medio tiempo (primeros 5 innings)
                          y en el tiempo completo (después de culminados los 9 innings).
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Por ejemplo: Si elige 1/X, apuesta a que el equipo de casa lidere en la
                        primera mitad y el partido termine en empate.
                      </span>
                    </p>
                    <p>
                      <span>No se consideran los extra innings.</span>
                    </p>
                    <ul>
                      <li>
                        <b>Equipo con más carreras en un inning: </b>
                        <span>
                          Qué equipo anotará más carreras en un inning. Se aplican reglas Push.
                        </span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <b>
                        NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                        DEPORTE DE BÉISBOL. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA
                        PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS. CABE
                        DESTACAR, LOS MERCADOS ANTES MENCIONADOS HARÁN ÉNFASIS EN SI TOMARÁN EN
                        CUENTA O NO EL TIEMPO EXTRA (INCL. OT), Y EN OTROS CASOS SI SÓLO TOMARÁ EN
                        CUENTA EL TIEMPO REGULAR (RT).
                      </b>
                    </p>
                    <p>
                      <b>
                        TAMBIÉN SE DEBE HACER ÉNFASIS EN LAS DIFERENCIAS ENTRE APUESTAS POR “MITAD”
                        E “INNINGS”, YA QUE BÉISBOL ES UN DEPORTE DONDE SE JUEGA POR INNINGS, LAS
                        APUESTAS POR “TIEMPO” O “MITAD” SE DEFINEN COMO APUESTAS A PRIMERA MITAD
                        (PRIMEROS 5 INNINGS) Y SEGUNDA MITAD (INNINGS COMPRENDIDOS DESPUÉS DEL 5TO
                        INNING). MIENTRAS LAS APUESTAS A INNINGS O PERIODOS SE REALIZAN CON TOTAL
                        NORMALIDAD, 1ºINNING, 2º INNING, ETC…
                      </b>
                    </p>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="hockey" style={{ textAlign: 'center' }}>
                    REGLAS DE HOCKEY SOBRE HIELO
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los partidos deben comenzar en la fecha prevista (hora local del
                        estadio) para que las apuestas tengan acción. La excepción es si anunciamos
                        una hora de inicio incorrecta.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Si se cambia el lugar de celebración de un partido, las apuestas ya
                        realizadas se mantendrán siempre que el equipo local siga siendo designado
                        como tal. Si el equipo local y el visitante de un partido se invierten, las
                        apuestas realizadas en base a la lista original serán anuladas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Los juegos deben durar un mínimo de 55 minutos para que las apuestas tomen
                        acción. Si un juego se suspende antes de que se hayan jugado 55 minutos
                        completos, todas las apuestas sobre el resultado del juego se reembolsan a
                        menos que se indique lo contrario, excepto las apuestas en cualquier mercado
                        que se haya determinado como incondicional.
                      </span>
                    </p>
                    <p>
                      <span>
                        Los campeonatos nacionales e internacionales de hockey sobre hielo, se
                        calculan tomando en cuenta el tiempo regular del partido, a menos que se
                        mencione lo contrario.
                      </span>
                    </p>
                    <p>
                      <b>Apuestas Pre-Match</b>
                    </p>
                    <p>
                      <span>
                        Las apuestas pre-match se liquidarán de acuerdo con el marcador final,
                        incluido el tiempo extra si la apuesta lo indica (OT), y la tanda de
                        penaltis si es necesario o tiempo regular excluyendo el tiempo extra (RT),
                        según los términos del mercado de apuestas específico. Los resultados de los
                        partidos decididos por una tanda de penaltis incluirán toda la tanda de
                        penaltis como un gol para el equipo ganador.
                      </span>
                    </p>
                    <p>
                      <span>
                        Los partidos amistosos y los partidos amistosos internacionales en vivo se
                        resolverán según el puntaje reglamentario, al menos que se indique lo
                        contrario.
                      </span>
                    </p>
                    <p>
                      <b>NHL</b>
                    </p>
                    <p>
                      <span>
                        Las apuestas de partidos de pretemporada y el tiempo regular de la NHL se
                        liquidan en función del puntaje al final del tiempo reglamentario.
                      </span>
                    </p>
                    <p>
                      <span>
                        Los siguientes tipos de apuestas de la NHL se liquidarán según el tiempo
                        reglamentario:
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <ul>
                      <li>
                        <strong>3 Way Moneyline</strong>
                      </li>
                      <li>
                        <strong>
                          Asian Lines – Handicap &amp; OU Puck Line (Handicap) (3 Way)
                        </strong>
                      </li>
                      <li>
                        <strong>Doble Oportunidad</strong>
                      </li>
                      <li>
                        <strong>Primero en Marcar</strong>
                      </li>
                      <li>
                        <strong>Último en Marcar</strong>
                      </li>
                      <li>
                        <strong>Draw No Bet</strong>
                      </li>
                      <li>
                        <strong>Número de Goles por equipo</strong>
                      </li>
                      <li>
                        <strong>Número de goles</strong>
                      </li>
                      <li>
                        <strong>Período de mayor puntuación</strong>
                      </li>
                      <li>
                        <strong>Próximo Gol</strong>
                      </li>
                      <li>
                        <strong>A Ganar más períodos</strong>
                      </li>
                      <li>
                        <strong>1ero en Hacer 3 Goles</strong>
                      </li>
                      <li>
                        <strong>Total de Tiros a Goles</strong>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      <span>
                        Los mercados no tienen en cuenta la Prórroga-Tiempo Extra a menos que se
                        indique lo contrario en la descripción del mercado (Incl. OT o Sólo OT):
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Ganador (Incl.OT)</span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        Nota: Incl. OT implica el resultado del tiempo regular y la Prórroga-Tiempo
                        extra, en el caso de que el mercado diga Sólo OT u Only OT el tiempo regular
                        no tendrá validez para efectos de esa apuesta.
                      </b>
                    </p>
                    <p>
                      <span>
                        Todos los mercados (excepto los de período, tiempo extra y tanda de
                        penaltis) se consideran sólo para el tiempo regular, a menos que se mencione
                        en el mercado.&nbsp;
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del hockey sobre hielo</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Si el mercado permanece abierto cuando los siguientes eventos ya han
                          tenido lugar: goles y penaltis, nos reservamos el derecho de anular
                          cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si las cuotas se ofrecen con un tiempo de partido incorrecto (más de 5
                          minutos), nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido es aplazado, abandonado o interrumpido por cualquier motivo
                          y no continúa en un plazo de 48 horas, todas las apuestas no realizadas
                          serán anuladas.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR:
                        <span>
                          Mejor conocido 2-way. A diferencia del 3-way, el 2-way te permite
                          seleccionar una victoria del local o una victoria del visitante, al no
                          tener la opción del empate, si el partido termina en empate, las apuestas
                          serán reembolsadas.
                        </span>
                        (Incluye tiempo extra)<span>.</span>
                      </b>
                    </li>
                    <li>
                      <strong>GANADOR (1-X-2):</strong>
                      <span>
                        Este mercado es el conocido 3-way, dando a elegir entre una victoria del
                        local, un empate o una victoria del visitante.
                      </span>
                      (No incluye Tiempo extra)
                    </li>
                  </ul>
                  <p>
                    <span>1= Local</span>
                  </p>
                  <p>
                    <span>X= Empate</span>
                  </p>
                  <p>
                    <span>2= Visitante</span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        DOBLE OPORTUNIDAD:
                        <span>
                          en éste mercado, como su nombre lo dice, se tiene la oportunidad de
                          predecir dos de los tres posibles resultados del partido. Abarcando las
                          opciones: 1/X = Equipo local o empate, 1/2 = Equipo local o Equipo
                          visitante, y X/2 = empate o Equipo visitante.
                        </span>
                      </b>
                    </li>
                    <li>
                      <b>EMPATE APUESTA NO VÁLIDA:</b>
                      <span>
                        este mercado es el muy bien conocido 2-way. Es decir, de darse la opción del
                        empate, las apuestas son reembolsadas.
                      </span>
                    </li>
                    <li>
                      <b>PAR/IMPAR:</b>
                      <span>
                        Predice si el número total de goles acumulados en un partido sumará un
                        número par o impar.
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Cualquier partido que resulte en 0-0 se liquidará como un número par de goles.
                    </span>
                  </p>
                  <ul>
                    <li>
                      <b>PERIODO DE MAYOR PUNTUACIÓN:</b>
                      <span>
                        se trata de predecir en qué período se anotarán la mayor cantidad de puntos.
                        Dando a elegir también la opción de IGUALES.
                      </span>
                    </li>
                    <li>
                      <b>
                        PRIMER EQUIPO A MARCAR:
                        <span>
                          Este mercado trata de predecir qué equipo anotará el primer punto.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>ÚLTIMO EQUIPO A MARCAR:</strong>
                      <span>
                        Este mercado trata de predecir qué equipo anotará el último punto.
                      </span>
                    </li>
                    <li>
                      <strong>MARCADOR CORRECTO:</strong>
                      <span>
                        trata de predecir el marcador correcto con el que finalizará el partido,
                        además de las opciones disponibles, estará la opción OTRO, que engloba
                        cualquier otro posible marcador no reflejado en las demás opciones.
                      </span>
                    </li>
                    <li>
                      <b>TIEMPO EXTRAORDINARIO (SI/NO):</b>
                      <span>
                        este mercado se resuelve de forma muy sencilla, como su nombre lo dice, es
                        predecir si el evento llegará a tiempo extra.
                      </span>
                    </li>
                    <li>
                      <b>
                        MARGEN DE VICTORIA:
                        <span>
                          se trata de predecir cuál será el margen de victoria que tendrá un equipo
                          sobre el otro, dando la opción del EMPATE. Ejemplo: LOCAL 2. Para que se
                          dé la apuesta, el partido debe terminar con el equipo visitante ganando
                          con una ventaja exacta de 2 puntos, es decir 2-0, 3-1, 4-2, etc… Si el
                          margen de victoria del evento no está comprendido entre los límites
                          elegidos, la apuesta es perdedora independientemente de que el equipo
                          visitante haya sido el ganador del partido.
                        </span>
                      </b>
                    </li>
                    <li>
                      <strong>TOTAL GOLES - MÁS / MENOS:</strong>
                      <span>
                        <strong> TOTAL PUNTOS ó OVER/UNDER</strong> se trata de predecir si el
                        puntaje total de un partido sumará más o menos que un monto específico.
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Ejemplo: Más de (3), en el partido a disputar, la suma de los puntos hechos
                      por ambos equipos debe ser mayor a 3, es decir, 4 puntos o más.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        HÁNDICAP ASIÁTICO:
                        <span>
                          Los mercados asiáticos se establecen según el resultado del Tiempo
                          regular. El Hándicap Asiático en vivo se liquida de acuerdo con el puntaje
                          del resto del juego después de que se realizó la apuesta. No se incluyen
                          los goles marcados antes de la apuesta.
                        </span>
                      </b>
                    </li>
                    <li>
                      <strong>HÁNDICAP EUROPEO:</strong>
                      <span>
                        En un Handicap de 3 vías, la línea se establece de modo que también pueda
                        haber un resultado de empate, lo que permite 3 apuestas potenciales.
                        Handicap (-1):&nbsp; ganas si tu equipo gana el partido con una diferencia
                        de goles de dos o más. Empate: Ganas si el equipo con (-1) hándicap gana el
                        partido con exactamente un gol de diferencia. Hándicap (+1): ganas si tu
                        equipo empata o gana el partido.
                      </span>
                    </li>
                    <li>
                      <strong>NÚMERO DE GOLES:</strong> Apuesta a la cantidad total de goles
                      marcados por los dos equipos durante el tiempo de juego oficial de 60 minutos.
                      Los goles en propia puerta se cuentan a efectos de apuestas.
                    </li>
                    <li>
                      <b>
                        Total de Goles por Equipo:
                        <span>Predice el total de goles logrados por el equipo seleccionado.</span>
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>Más 0.5 – Ganan si el equipo elegido marca 1 o más goles</span>
                  </p>
                  <p>
                    <span>Menos 0.5 – Ganan si el equipo elegido no marca.</span>
                  </p>
                  <p>
                    <span>Más 1.5 – Ganan si el equipo elegido marca 2 o más goles.</span>
                  </p>
                  <p>
                    <span>Menos 1.5 – Ganan si el equipo elegido marca 1 o menos goles.</span>
                  </p>
                  <p>
                    <span>Los autogoles cuentan a efectos de esta apuesta.</span>
                  </p>
                  <p>
                    <b>1st/2nd/3rd Periodo</b>
                  </p>
                  <p>
                    <span>
                      Las apuestas se liquidan únicamente en función del resultado del primer /
                      segundo / tercer período. El tercer período excluye las horas extraordinarias.
                      El período en cuestión debe completarse para que las apuestas tengan acción.
                      Si se interrumpe un partido durante el segundo período, todas las apuestas del
                      primer período siguen siendo válidas. Si se interrumpe un partido durante el
                      tercer período, todas las apuestas del primer/segundo período siguen siendo
                      válidas.
                    </span>
                  </p>
                  <p>
                    <b>Primero en Marcar / último en marcar / Próximo Gol</b>
                  </p>
                  <p>
                    <span>
                      Qué equipo marcará el primer / último / próximo gol en un partido. Los goles
                      en propia puerta cuentan para el equipo acreditado con el gol.
                    </span>
                  </p>
                  <p>
                    <span>
                      Si un partido es suspendido después que se marcó un gol, todas las apuestas en
                      el equipo del “Primero en Marcar” serán válidas, el “Último en Marcar” se
                      anulará, el equipo del “Siguiente en Marcar” que ya haya sido definido se
                      mantendrá, pero el que no se haya decretado se anulará.
                    </span>
                  </p>
                  <p>
                    <span>
                      Si se interrumpe un evento sin que se marque ningún gol, se anularán todas las
                      apuestas de "Primero en marcar / Último en marcar".
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE HOCKEY SOBRE HIELO. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS
                      EN LA PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.
                      CABE DESTACAR, LOS MERCADOS ANTES MENCIONADOS HARÁN ÉNFASIS EN SI TOMARÁN EN
                      CUENTA O NO EL TIEMPO EXTRA (INCL. OT), Y EN OTROS CASOS SI SÓLO TOMARÁ EN
                      CUENTA EL TIEMPO REGULAR (RT).
                    </b>
                  </p>
                  <p>
                    <b>
                      TAMBIÉN SE DEBE HACER ÉNFASIS EN QUE HOCKEY SOBRE HIELO ES UN DEPORTE DONDE SE
                      JUEGA POR PERIODOS, 3 PARA SER EXACTOS. ES DECIR, LAS APUESTAS POR “TIEMPO” O
                      “MITADES” NO EXISTEN. MIENTRAS LAS APUESTAS POR PERIODOS SE REALIZAN CON TOTAL
                      NORMALIDAD, 1º PERIODO, 2º PERIODO, 3º PERIODO.
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="balonmano" style={{ textAlign: 'center' }}>
                    REGLAS DE BALONMANO / HANDBALL
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Las estadísticas proporcionadas por el sitio web oficial de la competición o
                        el partido en cuestión se utilizarán para la liquidación. En caso de que las
                        estadísticas no estén disponibles en el sitio web oficial o haya pruebas
                        significativas de que el sitio web oficial es incorrecto, utilizaremos una
                        fuente independiente para liquidar las apuestas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        En ausencia de pruebas consistentes e independientes o en presencia de
                        pruebas conflictivas significativas, las apuestas se liquidarán en base a
                        nuestras propias estadísticas. En caso de tenerlas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Todos los juegos se basan en el resultado al final de un juego programado de
                        60 minutos a menos que se indique lo contrario. Si no se juegan los 60
                        minutos programados, las apuestas se anularán, a menos que se indique lo
                        contrario. Se hace una excepción para los juegos en los que se usa una regla
                        de misericordia: el resultado en el momento de la llamada a la regla de
                        misericordia se usará para fines de liquidación.
                      </span>
                    </p>
                    <p>
                      <span>
                        Las estadísticas proporcionadas por el sitio web oficial de la liga o
                        partido correspondiente se utilizarán para fines de liquidación. En caso de
                        que las estadísticas no estén disponibles en el sitio web oficial o haya
                        evidencia significativa de que el sitio web oficial es incorrecto,
                        utilizaremos una fuente independiente para liquidar las apuestas.
                      </span>
                    </p>
                    <p>
                      <span>
                        Los mercados no tienen en cuenta el tiempo extra a menos que se indique lo
                        contrario (Incl. OT o Sólo OT):
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Par / Impar (Incl. OT)</span>
                      </li>
                      <li>
                        <span>Equipo local - Par / Impar (Incl. OT)</span>
                      </li>
                      <li>
                        <span>Equipo visitante - Par / Impar (Incl. OT)</span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        <i>Reglas de liquidación del balonmano o handball</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Si un partido es aplazado, abandonado o interrumpido por cualquier motivo
                          y no continúa en un plazo de 48 horas, todas las apuestas no determinadas
                          serán anuladas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando las cuotas se ofrecen con un tiempo de partido incorrecto (más de 5
                          minutos), nos reservamos el derecho a anular cualquier apuesta.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR (1-X-2):
                        <span>
                          Este mercado es el conocido 3-way, dando a elegir entre una victoria del
                          local, un empate o una victoria del visitante.
                        </span>
                        (No incluye Tiempo extra)
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>1= Local</span>
                  </p>
                  <p>
                    <span>X= Empate</span>
                  </p>
                  <p>
                    <span>2= Visitante</span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        TOTAL GOLES - MÁS / MENOS:
                        <span>
                          TOTAL PUNTOS ó OVER/UNDER se trata de predecir si el puntaje total de un
                          partido sumará más o menos que un monto específico. Ejemplo: Más de
                          (42.5), en el partido a disputar, la suma de los puntos hechos por ambos
                          equipos debe ser mayor a 42.5, es decir, 43 puntos o más.
                        </span>
                      </b>
                    </li>
                    <li>
                      <b>EMPATE APUESTA NO VÁLIDA:</b>
                      <span>
                        este mercado es el muy bien conocido 2-way. Es decir, de darse la opción del
                        empate, las apuestas son reembolsadas.
                      </span>
                    </li>
                    <li>
                      <b>HÁNDICAP EUROPEO: </b>
                      <span>
                        Es una apuesta de 3 vías, similar a una apuesta de resultado de partido 1X2.
                        Por eso también se encuentra como "Hándicap" o "3-way hándicap". El Hándicap
                        Europeo tiene un resultado de "empate". Que no es, por supuesto, el empate
                        real del partido, sino la "igualación" del hándicap específico establecido
                        por la casa.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <b>PAR/IMPAR:</b>
                      <span>
                        este mercado trata de predecir si el puntaje final del evento sumará un
                        puntaje par o impar.
                      </span>
                    </li>
                    <li>
                      <b>DOBLE OPORTUNIDAD:</b>
                      <span>
                        en éste mercado, como su nombre lo dice, se tiene la oportunidad de predecir
                        dos de los tres posibles resultados del partido. Abarcando las opciones: 1/X
                        = Equipo local o empate, 1/2 = Equipo local o Equipo visitante, y X/2 =
                        empate o Equipo visitante.
                      </span>
                    </li>
                    <li>
                      <b>RESULTADO MEDIO TIEMPO Y TIEMPO COMPLETO: </b>
                      <span>
                        este mercado es lo que en otros deportes está definido como DESCANSO/FINAL.
                        Es decir, qué equipo ganará en el marcador al terminar la primera mitad, y
                        qué equipo resultará ganador a tiempo completo.
                      </span>
                    </li>
                    <li>
                      <b>Primera Mitad: </b>
                      <span>
                        Las apuestas (1X2, Handicap y Más/Menos) se liquidan únicamente en función
                        del resultado del primer tiempo.
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Las apuestas se anularán si el partido se interrumpe antes del medio tiempo.
                    </span>
                  </p>
                  <p>
                    <span>
                      Si se interrumpe un partido durante la segunda mitad, todas las apuestas de la
                      primera mitad siguen siendo válidas.
                    </span>
                  </p>
                  <ul>
                    <li>
                      <b>Segunda Mitad: </b>
                      <span>
                        Las apuestas (1X2, Handicap y Más/Menos) se liquidan únicamente en función
                        del resultado del segundo tiempo.
                      </span>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Las apuestas se anularán si el partido se interrumpe antes del segundo tiempo.
                    </span>
                    <b></b>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE BALONMANO O HANDBALL. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS
                      EN LA PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.
                      CABE DESTACAR, LOS MERCADOS ANTES MENCIONADOS HARÁN ÉNFASIS EN SI TOMARÁN EN
                      CUENTA O NO EL TIEMPO EXTRA (INCL. OT), Y EN OTROS CASOS SI SÓLO TOMARÁ EN
                      CUENTA EL TIEMPO REGULAR (RT).
                    </b>
                  </p>
                  <p>
                    <b>
                      TAMBIÉN SE DEBE HACER ÉNFASIS EN QUE BALONMANO O HANDBALL ES UN DEPORTE DONDE
                      SE JUEGA POR MITADES. ES DECIR, LAS APUESTAS POR “TIEMPO” O “MITADES” SE
                      APLICAN DE FORMA REGULAR. EN CASO DE HABER ALGÚN MERCADO HACIENDO MENCIÓN A
                      “PERÍODO” ES LO MISMO QUE HACER MENCIÓN A LA PALABRA “MITAD” EN ESTE CASO.
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="voleibol" style={{ textAlign: 'center' }}>
                    REGLAS DE VOLEIBOL
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Si se cambia el lugar de celebración de un partido, las apuestas ya
                        realizadas se mantendrán siempre que el equipo local siga siendo designado
                        como tal.&nbsp;
                      </span>
                      <span>
                        Si el equipo local y el visitante de un partido se invierten, las apuestas
                        basadas en el listado original serán anuladas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        El set de oro no se tomará en consideración para efectos de apuesta.
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del voleibol</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Si un partido es aplazado, abandonado o interrumpido por cualquier motivo
                          y no continúa en un plazo de 48 horas, todas las apuestas no realizadas
                          serán anuladas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando las cuotas se ofrezcan con un tiempo de partido incorrecto (más de
                          5 minutos), nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                    </ul>
                    <h3>Mercados</h3>
                    <ul>
                      <li>
                        <strong>GANADOR DEL PARTIDO:</strong>
                        <span>
                          mejor conocido 2-way. A diferencia del 3-way, el 2-way te permite
                          seleccionar una victoria del local o una victoria del visitante, al no
                          tener la opción del empate, si el partido termina en empate, las apuestas
                          serán reembolsadas.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>TOTAL DE SETS (MEJOR DE 5):</strong>
                        <span>
                          este mercado se trata de predecir cuantos sets se jugarán en el partido,
                          dando a elegir desde 3 hasta 5 sets reglamentarios.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>TOTAL PUNTOS (MÁS DE/MENOS DE): </strong>
                        <span>
                          <strong>TOTAL PUNTOS ó OVER/UNDER</strong> se trata de predecir si el
                          puntaje total de un partido sumará más o menos que un monto específico.
                          Ejemplo: Más de (166.5), en el partido a disputar, la suma de los puntos
                          hechos por ambos equipos debe ser mayor a 166.5, es decir, 167 puntos o
                          más.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>RESULTADO EN SETS (MEJOR DE 5):</strong>
                        <span>
                          se trata de predecir el puntaje correcto en cuanto a SETS se refiere,
                          dejando al jugador la posibilidad de armar el resultado deseado.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>HÁNDICAP ASIÁTICO (SETS):</strong>
                        <span>
                          Predice el ganador del juego en sets, Las apuestas son nulas si el número
                          legal de sets no se completa o cambia. Hay que tomar en consideración que
                          el hándicap otorga una ventaja o desventaja siempre al equipo local.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span>
                        Ejemplo 1: Si realizas una apuesta y tomas al equipo visitante (2.5),
                        estarás realizando tu apuesta dando 2.5 sets de ventaja al equipo Local, es
                        decir que el Visitante deberá ganar por una ventaja de 3 Sets para que tu
                        apuesta sea considerada ganadora.
                      </span>
                    </p>
                    <p>
                      <span>
                        Ejemplo 2: Si realizas una apuesta y tomas al equipo visitante (-2.5),
                        estarás realizando tu apuesta dando -2.5 sets de desventaja al equipo Local,
                        es decir que tus apuesta será considerada ganadora si el visitante gana o
                        pierde por menos de 3 sets.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <strong>PAR/IMPAR:</strong>
                        <span>
                          este mercado trata de predecir si el puntaje final del evento sumará un
                          puntaje par o impar.
                        </span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <strong>4º SET JUGADO:</strong>
                        <span>se trata de predecir si el evento llegará al 4º SET.</span>
                      </li>
                      <li>
                        <strong>5º SET JUGADO:</strong>
                        <span>se trata de predecir si el evento llegará al 5º SET.</span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                        DEPORTE DE VOLEIBOL. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA
                        PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE
                        MENCIONADOS.&nbsp;
                      </b>
                    </p>
                    <p>
                      <b>
                        TAMBIÉN SE HACE MENCIÓN A QUE ESTE DEPORTE SE JUEGA POR SETS, POR LO QUE
                        MERCADOS QUE SE JUEGUEN POR “MITADES” NO EXISTEN, Y EN CASO DE UN MERCADO
                        HACER ÉNFASIS EN “PERIODOS”, SE TOMARÁ COMO SETS.
                      </b>
                    </p>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="golf" style={{ textAlign: 'center' }}>
                    REGLAS DE GOLF
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Si se retrasa el inicio de una ronda, o si se suspende el juego durante una
                        ronda, todas las apuestas pendientes seguirán siendo válidas durante 48
                        horas. Si el aplazamiento dura más de 48 horas, todas las apuestas
                        pendientes se anularán y se devolverá el dinero.&nbsp;
                      </span>
                      <span>
                        Si un golfista se retira antes del comienzo de un torneo, todas las apuestas
                        por ese participante serán declaradas nulas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Las apuestas se mantienen una vez que el jugador ha jugado el primer
                        hoyo.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        En caso de que haya varios ganadores, se aplicarán las reglas de "Dead
                        Heat".&nbsp;
                      </span>
                    </p>
                    <p>
                      <b>Apuestas de campeonato</b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Todas las apuestas de torneo se determinarán en función del jugador que
                          gane el trofeo. Se tiene en cuenta el resultado de las
                          eliminatorias.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Todas las apuestas se mantienen excepto las realizadas a participantes que
                          no compiten en la primera ronda.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          El campeón de la competición y las clasificaciones se determinarán de
                          acuerdo con las normas oficiales del respectivo organismo rector.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          En el caso de que haya un cambio o una disminución del número reservado de
                          rondas o aperturas jugadas en la competición, las apuestas realizadas en
                          este mercado se mantendrán.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Si una competición se reduce a menos del número de rondas previsto, todas
                          las apuestas realizadas después del último tiro de la ronda anterior
                          completada serán anuladas.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <p>
                    <b>Outright</b>
                  </p>
                  <p>
                    <span>
                      Todas las apuestas absolutas se liquidan según el jugador que gane el trofeo.
                      Se tiene en cuenta el resultado de los play-offs.
                    </span>
                  </p>
                  <p>
                    <span>
                      Todas las apuestas son válidas, excepto las realizadas a los participantes que
                      no compiten en la primera ronda.
                    </span>
                  </p>
                  <p>
                    <span>
                      El campeón de la competencia y las posiciones serán determinadas por las
                      reglas oficiales del respectivo órgano de gobierno.
                    </span>
                  </p>
                  <p>
                    <span>
                      En el caso de que haya un cambio o una disminución en el número reservado de
                      rondas o aperturas jugadas en la competencia, las apuestas establecidas en
                      este mercado se mantendrán.
                    </span>
                  </p>
                  <p>
                    <span>
                      Si una competición se reduce a menos del número de rondas planificado, todas
                      las apuestas realizadas después del último disparo de la ronda completada
                      anterior serán anuladas.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>Doble Oportunidad 1X2</b>
                  </p>
                  <p>
                    <span>Haciendo una apuesta en 2 resultados diferentes.</span>
                  </p>
                  <p>
                    <span>Por ejemplo:</span>
                  </p>
                  <p>
                    <span>
                      El equipo local gana o empata, El equipo visitante gana o empata, Sin empate.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <b>2-Way</b>
                  </p>
                  <p>
                    <span>Predice el ganador del juego.</span>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="box" style={{ textAlign: 'center' }}>
                    REGLAS DE BOXEO / PELEA
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Las apuestas donde se define qué luchador será el ganador del combate, en
                        caso de empate se anularán todas las apuestas y se devolverán los importes
                        apostados. En caso tal de ofrecer la opción del empate, se aplica la regla
                        del 3-way. Si cualquiera de los luchadores es sustituido por otro luchador,
                        todas las apuestas serán anuladas y se devolverán los importes
                        apostados.&nbsp;
                      </span>
                      <span>
                        Si un combate es aplazado, abandonado o interrumpido por cualquier motivo y
                        no continúa en un plazo de 48 horas, todas las apuestas no realizadas serán
                        anuladas.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Si el número de asaltos programados en un combate cambia, las apuestas
                        realizadas en este se mantendrán. Si la decisión es una victoria por
                        mayoría, se clasificará como una decisión dividida. Si el combate no termina
                        por la cantidad establecida de rounds (knock out, k.o. técnico, etc), todas
                        las apuestas serán válidas. Si se trata de una decisión unánime, las
                        apuestas se resolverán con la cantidad de golpes acertados, donde los tres
                        jueces estén de acuerdo sobre qué luchador ha ganado el combate.&nbsp;
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Liquidación de apuestas</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Las apuestas se determinarán en función del resultado oficial dado por los
                          jueces una vez concluido el combate, y no se verán afectadas por ninguna
                          apelación o modificación futura del resultado (a menos que la modificación
                          se haya producido por un error humano).
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="carreras" style={{ textAlign: 'center' }}>
                    REGLAS DE DEPORTES DE MOTOR
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Si una carrera o clasificación se pospone por cualquier motivo, todas las
                        apuestas seguirán siendo válidas durante 48 horas.&nbsp;
                      </span>
                      <span>
                        Los DNS son siempre nulos, los DNF son siempre perdedor, en caso de DQ antes
                        de la salida son nulos, los DQ después de la salida son perdedor.
                      </span>
                    </p>
                    <p>
                      <span>
                        Las reglas de Dead Heat se aplican en caso de múltiples ganadores.&nbsp;
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Apuestas de carrera</i>
                      </b>
                      <span>&nbsp;</span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Todas las apuestas de carrera se determinan en función de la clasificación
                          oficial de la Federación Internacional del Automóvil (FIA), el organismo
                          rector del deporte, en el momento de la presentación del podio.&nbsp;
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        <i>Apuestas de campeonato</i>
                      </b>
                      <span>&nbsp;</span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Las apuestas se calificarán según la clasificación de la FIA
                          inmediatamente después de la última carrera de la temporada y no se verán
                          afectadas por ninguna penalización o descenso de categoría
                          posterior.&nbsp;
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        <i>Constructores</i>
                      </b>
                      <span>&nbsp;</span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Define cuál participante será el mejor constructor de la temporada de
                          Fórmula 1 de acuerdo con la clasificación del Campeonato de Constructores,
                          y las reglas especificadas por la FIA.&nbsp;
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        <i>Vuelta rápida</i>
                      </b>
                      <span>&nbsp;</span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Se utilizará el resultado oficial de la FIA en el momento de la
                          presentación del podio de la carrera.
                        </span>
                      </li>
                    </ul>
                    <p>
                      <b>
                        <i>MOTOCICLETAS - Campeonato de la categoría</i>
                      </b>
                      <span>&nbsp;</span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Se puede competir con todo o no. Las apuestas se determinarán por el
                          número de puntos acumulados tras la presentación del podio de la última
                          carrera de la temporada y no se verán afectadas por ninguna consulta
                          posterior.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="rugby" style={{ textAlign: 'center' }}>
                    REGLAS DE RUGBY
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los partidos deben comenzar en la fecha prevista (hora local) para que
                        las apuestas tengan acción, la excepción es si anunciamos una hora de inicio
                        incorrecta.
                      </span>
                      <span>
                        A menos que se indique lo contrario, las apuestas de partidos de Rugby 7s
                        (sevens) y 10s (tens) se valoran según el juego reglamentario específico del
                        torneo y excluyen la prórroga (tiempo extra) si se juega.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Todas las apuestas de Rugby se valoran en función de los 80 minutos de
                        juego. El término "80 minutos de juego" incluye cualquier tiempo de
                        interrupción, a menos que se indique lo contrario.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Si se cambia el lugar de celebración del partido anunciado, se anularán
                        todas las apuestas para dicho partido. En caso de cambio de adversario
                        respecto al anunciado, se anularán todas las apuestas para ese
                        partido.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Todos los mercados (excepto el del descanso, el de la primera parte, el de
                        la prórroga y el de la tanda de penaltis) se consideran sólo para el tiempo
                        reglamentario.
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del rugby</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando el mercado permanezca abierto cuando ya se hayan producido los
                          siguientes eventos: cambios en el marcador o tarjetas rojas, nos
                          reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando el mercado se abrió con una tarjeta roja inexistente o incorrecta,
                          nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando las cuotas se ofrezcan con un tiempo de partido incorrecto (más de
                          5 minutos), nos veremos obligados a anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un partido se interrumpe o se pospone y no se continúa dentro de
                          las 48 horas siguientes a la fecha de inicio, todas las apuestas no
                          realizadas serán anuladas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando los nombres de los equipos o la categoría se muestren de forma
                          incorrecta, nos reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR (1-X-2):
                        <span>
                          Este mercado es el conocido 3-way, dando a elegir entre una victoria del
                          local, un empate o una victoria del visitante.
                        </span>
                        (No incluye Tiempo extra)
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>1= Local</span>
                  </p>
                  <p>
                    <span>X= Empate</span>
                  </p>
                  <p>
                    <span>2= Visitante</span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        EMPATE APUESTA NO VÁLIDA:
                        <span>
                          este mercado es el muy bien conocido 2-way. Es decir, de darse la opción
                          del empate, las apuestas son reembolsadas.
                        </span>
                      </b>
                    </li>
                    <li>
                      <b>TOTAL PUNTOS ( MÁS DE / MENOS DE ):</b> TOTAL PUNTOS ó OVER/UNDER es un
                      mercado muy conocido donde se trata de predecir si el puntaje total de un
                      partido sumará más o menos que un monto específico. Ejemplo: Más de (66.5), en
                      el partido a disputar, la suma de los puntos hechos por ambos equipos debe ser
                      mayor a 66.5, es decir, 67 puntos o más.
                    </li>
                    <li>
                      <b>
                        GANADOR CON HANDICAP:
                        <span>Predice el ganador, aplicando el hándicap dado.</span>
                      </b>
                    </li>
                    <li>
                      <b>PRIMERA MITAD:</b>
                      <span>
                        Las apuestas se liquidan únicamente en función del resultado de la primera
                        mitad.Las apuestas se anularán si el partido se interrumpe antes del medio
                        tiempo. Si se interrumpe un partido durante la segunda mitad, todas las
                        apuestas de la primera mitad siguen siendo válidas.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE RUBY. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA PÁGINA
                      SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.&nbsp;
                    </b>
                  </p>
                  <p>
                    <b>
                      TAMBIÉN SE HACE MENCIÓN A QUE ESTE DEPORTE SE JUEGA POR MITADES, SIENDO
                      POSIBLE LAS APUESTAS POR PRIMERA Y SEGUNDA MITAD. Y EN CASO DE UN MERCADO
                      HACER ÉNFASIS EN “PERIODOS”, SE TOMARÁ COMO MITAD.
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="snooker" style={{ textAlign: 'center' }}>
                    REGLAS DE SNOOKER
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Apuestas a jugadores que terminan no participando en el torneo serán
                        anuladas, con excepción de las apuestas clasificatorias y dicho jugador no
                        logre clasificar al torneo principal. Estas apuestas se considerarán
                        perdedoras a efectos de liquidación. Todos los participantes de un torneo
                        determinado serán valorados para ganar el torneo en su totalidad. Las
                        apuestas mutuas están disponibles. Los detalles se muestran bajo el título
                        de la competición.&nbsp;
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del snooker</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando un partido sea interrumpido o aplazado y no continúe en un plazo de
                          48 horas después de la fecha de inicio, todas las apuestas no decididas
                          serán anuladas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un jugador/equipo se retira, todos los mercados no decididos se
                          consideran anulados.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR:
                        <span>
                          Mejor conocido 2-way. A diferencia del 3-way, el 2-way te permite
                          seleccionar una victoria del local o una victoria del visitante, al no
                          tener la opción del empate, si el partido termina en empate, las apuestas
                          serán reembolsadas.
                        </span>
                      </b>
                    </li>
                    <li>
                      <b>TOTAL FRAME (MAS/MENOS):</b>
                      <span>
                        este mercado trata de predecir si en el evento se jugarán más o menos frames
                        que un número determinado. Ejemplo: Más de (7.5), en el partido a disputar,
                        se deben jugar más de 7.5 frames, es decir 8 o más.
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="rules-sports-container">
                  <h2 id="dardos" style={{ textAlign: 'center' }}>
                    REGLAS DE DARDOS
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los partidos deben comenzar en la fecha prevista (hora local) para que
                        las apuestas tengan acción. La excepción es si anunciamos una hora de inicio
                        incorrecta.
                      </span>
                    </p>
                    <p>
                      <span>&nbsp;</span>
                      <b>
                        <i>Reglas de liquidación de dardos o darts</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando los jugadores/equipos se muestren de forma incorrecta, nos
                          reservamos el derecho de anular cualquier apuesta.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un partido se interrumpe o se aplaza y no se continúa en un plazo
                          de 48 horas después de la fecha de inicio, se anularán todas las apuestas
                          no realizadas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>La diana cuenta como color rojo de salida.</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="invierno" style={{ textAlign: 'center' }}>
                    REGLAS DE DEPORTES DE INVIERNO
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todas las apuestas se liquidan de acuerdo con los resultados oficiales de
                        las federaciones de deportes de invierno que rigen la carrera
                        correspondiente, incluso si no se celebran todas las pruebas programadas.
                        Las apuestas se liquidarán según el resultado oficial declarado tras la
                        finalización de la carrera. Las apelaciones y descalificaciones posteriores
                        no afectarán a las apuestas.&nbsp;
                      </span>
                      <span>
                        Si un evento no se celebra según lo previsto, a menos que se posponga debido
                        a las condiciones meteorológicas, todas las apuestas se anularán. Se hace
                        una excepción si se anuncia una hora de inicio incorrecta en nuestra página
                        web.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Si un evento se abandona o se suspende y no se celebra en un plazo de 48
                        horas (hora local) y en el mismo campo (pista), todas las apuestas se
                        anularán.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Hay una excepción a la regla anterior para los eventos olímpicos de
                        invierno.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Las apuestas a cualquier participante que participe en la clasificación de
                        un evento específico pero que luego no se clasifique para la(s) ronda(s)
                        principal(es) se calificarán como perdedoras.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span>
                        Se aplican las reglas de "Dead Heat" en caso de que haya varios ganadores.
                      </span>
                    </p>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="badminton" style={{ textAlign: 'center' }}>
                    REGLAS DE BADMINTON
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        En caso de que alguno de los jugadores nombrados en un partido cambie antes
                        de que éste comience, todas las apuestas serán anuladas.&nbsp;
                      </span>
                      <span>
                        El set de oro no se considera en ninguno de los mercados mencionados.&nbsp;
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación del bádminton</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando un partido sea interrumpido o aplazado y no continúe en un plazo de
                          48 horas después de la fecha de inicio, todas las apuestas no decididas
                          serán anuladas.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR ENCUENTRO:
                        <span>
                          Mejor conocido 2-way. A diferencia del 3-way, el 2-way te permite
                          seleccionar una victoria del local o una victoria del visitante, al no
                          tener la opción del empate, si el partido termina en empate, las apuestas
                          serán reembolsadas.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>MAS/MENOS:</strong>
                      <span>
                        <strong> MAS/MENOS ó OVER/UNDER</strong> es un mercado muy conocido donde se
                        trata de predecir si el puntaje total de un partido sumará más o menos que
                        un monto específico. Ejemplo: Más de (78.5), en el partido a disputar, la
                        suma de los puntos hechos por ambos participantes debe ser mayor a 78.5, es
                        decir, 79 puntos o más.
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>HÁNDICAP ASIÁTICO:</strong>
                      <span>
                        El mercado asiatico es un tipo especial de hándicap utilizado en encuentros
                        de fútbol. Dependiendo de la capacidad de cada equipo, un hándicap es
                        otorgado para el juego. Esto permite que las probabilidades para cada equipo
                        sean más similares, permitiendo más oportunidades de apuesta competitiva.
                        Todas las apuestas en hándicap Asiático en apuesta en vivo (incluyendo
                        apuestas de 1ra y 2da mitad) son establecidas de acuerdo al marcador. Los
                        goles anotados antes de que la apuesta fue realizada no son incluidos para
                        propósitos de calificación de apuestas.
                      </span>
                    </li>
                    <li>
                      <strong>MARCADOR CORRECTO:</strong>
                      <span>
                        se trata de predecir el puntaje correcto en cuanto a SETS se refiere,
                        dejando al jugador la posibilidad de armar el resultado deseado.
                      </span>
                    </li>
                    <li>
                      <b>PAR/IMPAR:</b>
                      <span>
                        este mercado trata de predecir si el puntaje final del evento sumará un
                        puntaje par o impar.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE BÁDMINTON. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA
                      PÁGINA SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.
                    </b>
                  </p>
                  <p>
                    <b>
                      TAMBIÉN SE HACE MENCIÓN A QUE ESTE DEPORTE SE JUEGA POR SETS, POR LO QUE
                      MERCADOS QUE SE JUEGUEN POR “MITADES” NO EXISTEN, Y EN CASO DE UN MERCADO
                      HACER ÉNFASIS EN “PERIODOS”, SE TOMARÁ COMO SETS.
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="futsal" style={{ textAlign: 'center' }}>
                    REGLAS DE FUTSAL
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        El tiempo reglamentario debe completarse para que las apuestas sean válidas,
                        a menos que se indique lo contrario.&nbsp;
                      </span>
                      <b>
                        <i>Reglas de liquidación del futsal</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando un partido sea interrumpido o aplazado y no continúe en un plazo de
                          48 horas después de la fecha de inicio, todas las apuestas no decididas
                          serán anuladas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un equipo se retira, todas las apuestas no decididas serán anuladas.
                        </span>
                      </li>
                    </ul>
                  </div>
                  <h3>Mercados</h3>
                  <ul>
                    <li>
                      <b>
                        GANADOR (1-X-2):
                        <span>
                          Este mercado es el conocido 3-way, dando a elegir entre una victoria del
                          local, un empate o una victoria del visitante.
                        </span>
                        (No incluye Tiempo extra)
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>1= Local</span>
                  </p>
                  <p>
                    <span>X= Empate</span>
                  </p>
                  <p>
                    <span>2= Visitante</span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        EMPATE APUESTA NO VÁLIDA:
                        <span>
                          este mercado es el muy bien conocido 2-way. Es decir, de darse la opción
                          del empate, las apuestas son reembolsadas.
                        </span>
                      </b>
                    </li>
                    <li>
                      <b>DOBLE OPORTUNIDAD:</b> en éste mercado, como su nombre lo dice, se tiene la
                      oportunidad de predecir dos de los tres posibles resultados del partido.
                      Abarcando las opciones: 1/X = Equipo local o empate, 1/2 = Equipo local o
                      Equipo visitante, y X/2 = empate o Equipo visitante.
                    </li>
                    <li>
                      <b>
                        TOTAL GOLES - MÁS / MENOS:
                        <span>
                          TOTALES ó OVER/UNDER se trata de predecir el total de goles de un partido.
                          Si se interrumpe un evento, todas las apuestas serán nulas, a menos que el
                          resultado de la apuesta realizada ya haya sido determinado.
                        </span>
                      </b>
                    </li>
                  </ul>
                  <p>
                    <span>
                      Ejemplo: Más de (3.5), en el partido a disputar, la suma de los goles hechos
                      por ambos equipos debe ser mayor a 3.5, es decir, 4 goles o más.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <b>
                        HÁNDICAP ASIÁTICO:
                        <span>
                          El mercado asiatico es un tipo especial de hándicap utilizado en
                          encuentros de fútbol. Dependiendo de la capacidad de cada equipo, un
                          hándicap es otorgado para el juego. Esto permite que las probabilidades
                          para cada equipo sean más similares, permitiendo más oportunidades de
                          apuesta competitiva. Todas las apuestas en hándicap Asiático en apuesta en
                          vivo (incluyendo apuestas de 1ra y 2da mitad) son establecidas de acuerdo
                          al marcador. Los goles anotados antes de que la apuesta fue realizada no
                          son incluidos para propósitos de calificación de apuestas.
                        </span>
                      </b>
                    </li>
                    <li>
                      <strong>MARCADOR EXACTO:</strong>
                      <span>
                        este mercado trata de predecir el marcador correcto de las opciones
                        ofertadas, en caso de no estar disponible la opción deseada, estará la
                        opción OTRO.
                      </span>
                    </li>
                    <li>
                      <b>AMBOS EQUIPOS ANOTAN:</b> Predecir si ambos equipos marcarán al menos un
                      gol en el juego. Los goles propios cuentan para el equipo al que se le
                      atribuye el gol. Si se interrumpe un partido, las apuestas serán nulas, a
                      menos que el resultado de estas apuestas ya esté determinado.
                    </li>
                    <li>
                      <b>PAR/IMPAR:</b>
                      <span>
                        este mercado trata de predecir si el puntaje final del evento sumará un
                        puntaje par o impar.
                      </span>
                    </li>
                    <li>
                      <b>GOL LOCAL:</b>
                      <span>
                        este mercado trata de predecir cuántos goles hará el equipo local en el
                        partido, incluyendo la opción 0.
                      </span>
                    </li>
                    <li>
                      <b>GOL VISITANTE:</b>
                      <span>
                        este mercado trata de predecir cuántos goles hará el equipo visitante en el
                        partido, incluyendo la opción 0.
                      </span>
                    </li>
                    <li style={{ listStyleType: 'none' }}></li>
                    <li>
                      <b>
                        MITAD CON MÁS GOLES:
                        <span>
                          este mercado trata de predecir cuál de los tiempos tendrá más goles,
                          teniendo también como opción EMPATE, la cual se define como: ambos tiempos
                          tendrán la misma cantidad de goles.
                        </span>
                      </b>
                    </li>
                    <li>
                      <b>PRÓXIMO EQUIPO EN MARCAR:</b>
                      <span>
                        este mercado normalmente se ve disponible en las apuestas en vivo, trata de
                        predecir cual será el equipo en hacer el siguiente gol, teniendo también en
                        cuenta la opción NO, dando a entender que no habrá próximo gol en el evento.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <b>
                      NOTA: LA LISTA ANTERIOR REFLEJA EN SU MAYORÍA LOS MERCADOS OFERTADOS PARA EL
                      DEPORTE DE FUTSAL. LOS DEMÁS MERCADOS DE ESTE DEPORTE REFLEJADOS EN LA PÁGINA
                      SON VARIANTES, O COMBINACIONES DE LOS ANTERIORMENTE MENCIONADOS.&nbsp;
                    </b>
                  </p>
                  <p>
                    <b>
                      TAMBIÉN SE HACE MENCIÓN A QUE ESTE DEPORTE SE JUEGA POR MITADES, SIENDO
                      POSIBLE LAS APUESTAS POR PRIMERA Y SEGUNDA MITAD. Y EN CASO DE UN MERCADO
                      HACER ÉNFASIS EN “PERIODOS”, SE TOMARÁ COMO MITAD.
                    </b>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="tenis-mesa" style={{ textAlign: 'center' }}>
                    REGLAS DE TENIS DE MESA
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <strong>Reglas en función a los mercados</strong>
                    </p>
                    <p>
                      En caso de que alguno de los jugadores nombrados en un partido cambie antes de
                      que éste comience el partido, todas las apuestas serán anuladas. En el caso de
                      que un partido comience pero no se complete, todas las apuestas serán
                      anuladas. En el caso de que un partido no se termine, todos los mercados
                      indecisos se considerarán nulos.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <strong>Reglas de liquidación del tenis de mesa</strong>
                    </p>
                    <ul>
                      <li>
                        Cuando un partido sea interrumpido o aplazado y no continúe en un plazo de
                        48 horas después de la fecha de inicio, todas las apuestas no decididas
                        serán anuladas.
                      </li>
                      <li>
                        Cuando los jugadores/equipos se muestren incorrectamente, nos reservamos el
                        derecho de anular cualquier apuesta.
                      </li>
                      <li>
                        Cuando un jugador se retira, todos los mercados indecisos se consideran
                        anulados.
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p>
                      Ocurre una excepción importante en la cancelación de las apuestas en las Ligas
                      de Tenis de Mesa de Ucrania y Rusia que, si de los dos contendientes uno se
                      retira o no juega, mientras se mantenga la fecha y hora del evento, el mismo
                      sigue teniendo acción siempre y cuando uno de los dos jugadores acepte el
                      cambio de contrincante y siempre y cuando se mantenga al menos uno de los dos
                      jugadores listados en el evento.
                    </p>
                    <p>
                      La cancelación del evento también se aplica siempre y cuando se trate de un
                      partido en eliminatorias o finales, donde no deben existir cambios en los
                      jugadores listados.
                    </p>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="e-sports" style={{ textAlign: 'center' }}>
                    REGLAS DE E-SPORTS
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Los mercados no consideran el tiempo extra a menos que se indique lo
                        contrario.
                      </span>
                      <span>
                        En caso de que se produzca un cambio de nombre de un equipo como
                        consecuencia de que éste abandone una organización, se una a otra o cambia
                        oficialmente de nombre, todas las apuestas se mantendrán.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si la alineación del equipo cambia una vez cerrados los mercados, todas las
                        apuestas serán válidas. Esto se aplica a los cambios de equipo/personal una
                        vez iniciado el evento. Si un equipo comienza con su alineación, y luego un
                        participante experimenta errores de conexión, si otra persona lo sustituye,
                        todas las apuestas realizadas en ese evento serán válidas.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si un equipo recibe un mapa de ventaja de acuerdo con el formato y las
                        reglas específicas del torneo, el equipo que reciba el mapa, será
                        considerado el ganador del mapa a efectos de liquidación.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si un equipo gana un partido por rendición, el partido se considerará
                        terminado y todas las apuestas se mantendrán.
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación de los E-Sports</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Los mercados se establecerán en base a los resultados publicados
                          oficialmente.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si el partido se publica de forma incorrecta, nos reservamos el derecho de
                          anular las apuestas.
                        </span>
                      </li>
                      <li>
                        <span>
                          En caso de que se produzca un walkover o una retirada, todos los mercados
                          indecisos se considerarán nulos.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido o mapa no se termina, todos los mercados indecisos se
                          considerarán nulos.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si un partido o mapa se repite debido a una desconexión o a problemas
                          técnicos no relacionados con los jugadores, todos los mercados no
                          decididos se considerarán nulos. La repetición del partido o mapa se
                          gestionará por separado. Las apuestas previas al juego se mantendrán en el
                          partido o mapa repetido de acuerdo con el resultado oficial.
                        </span>
                      </li>
                      <li>
                        <span>
                          Si el número estándar de mapas se modifica o difiere de los ofrecidos para
                          las apuestas, nos reservamos el derecho de anular las apuestas.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando un partido se interrumpe o se aplaza y no se continúa en un plazo
                          de 48 horas después de la fecha de inicio, se anularán todas las apuestas
                          no decididas.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="cricket" style={{ textAlign: 'center' }}>
                    REGLAS DE CRICKET
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Los mercados no tomarán en consideración los super overs a menos que se
                        mencione lo contrario.
                      </span>
                      <span>
                        Las carreras de penalización no se consideran en ningún mercado de over o
                        delivery (los mercados de overs múltiples no se consideran para esta regla).
                      </span>
                    </p>
                    <p>
                      <span>
                        Veinte 20: deben jugarse todos los overs programados para que se resuelvan
                        los mercados no decididos, a menos que la entrada haya llegado a su
                        conclusión natural.
                      </span>
                    </p>
                    <p>
                      <span>
                        ODIs: se debe jugar un mínimo del 90 % de los overs asignados para una
                        entrada en el momento en que se realiza la apuesta para que los mercados se
                        resuelvan, a menos que la entrada haya llegado a su conclusión natural.
                      </span>
                    </p>
                    <p>
                      <b>
                        <i>Reglas de liquidación de cricket</i>
                      </b>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Cuando un partido sea interrumpido o aplazado y no continúe en las 48
                          horas siguientes a la fecha de inicio, todas las apuestas no decididas
                          serán anuladas.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando el partido esté empatado y las reglas oficiales de la competición
                          no determinen un ganador; o si las reglas de la competición determinan el
                          ganador mediante el lanzamiento de una moneda o un sorteo, todos los
                          mercados no decididos se considerarán nulos.&nbsp;
                        </span>
                      </li>
                      <li>
                        <span>
                          En caso de que un over no se complete, todos los mercados no decididos en
                          este over específico se consideran nulos, a menos que la entrada haya
                          llegado a su conclusión natural, por ejemplo, declaración, equipo fuera,
                          etc.
                        </span>
                      </li>
                      <li>
                        <span>
                          Cuando los mercados permanezcan abiertos con un marcador incorrecto que
                          tenga un impacto significativo en los precios, será nuestra preocupación
                          anular cualquier apuesta.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="rules-sports-container">
                  <h2 id="surfing" style={{ textAlign: 'center' }}>
                    REGLAS DE SURFING
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todas las apuestas se liquidan de acuerdo con los resultados oficiales del
                        organizador de la carrera correspondiente, incluso si no se celebran todas
                        las pruebas programadas. Las apuestas se liquidarán de acuerdo con el
                        resultado oficial declarado tras la finalización de la carrera. Las
                        apelaciones y descalificaciones posteriores no afectarán a las apuestas.
                      </span>
                      <span>
                        Si un evento no se celebra según lo previsto, a menos que se posponga debido
                        a las condiciones meteorológicas, todas las apuestas se anularán. Se hace
                        una excepción si se anuncia una hora de inicio incorrecta en nuestra página
                        web.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si un evento se abandona o se suspende y no se celebra en un plazo de 48
                        horas (hora local) y en el mismo campo (pista), todas las apuestas se
                        anularán.
                      </span>
                    </p>
                    <p>
                      <span>
                        Se aplican las reglas de Dead Heat en caso de que haya varios ganadores.
                      </span>
                    </p>
                  </div>
                  <h3>Mercados</h3>
                  <p>
                    <span>
                      Las apuestas absolutas predicen el ganador de la liga/torneo. Las apuestas se
                      liquidarán de acuerdo con la clasificación final, a menos que se indique lo
                      contrario.
                    </span>
                  </p>
                </div>

                <div className="rules-sports-container">
                  <h2 id="otros" style={{ textAlign: 'center' }}>
                    REGLAS DE ATLETISMO, HOCKEY SOBRE HIERBA, POOL, WATERPOLO, OLIMPIADAS, VOLEIBOL
                    PLAYERO, LACROSSE, FÚTBOL PLAYERO, FÚTBOL GAÉLICO, HURLING GAÉLICO, ETC…
                  </h2>
                  <hr />
                  <div>
                    <p>
                      <span>
                        Todos los juegos deben comenzar en la fecha programada (hora local) para que
                        las apuestas tengan acción. Si algún partido se juega antes de la fecha o la
                        hora de inicio indicada, las apuestas se mantendrán siempre que se realicen
                        no más tarde de la hora de inicio revisada.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si un partido se interrumpe y continúa dentro de las 48h después del inicio
                        de este, todas las apuestas abiertas se liquidarán según el resultado final.
                        Por otro lado, todas las apuestas no decididas se considerarán nulas.
                      </span>
                    </p>
                    <p>
                      <span>
                        Si se cambia el lugar de un partido, las apuestas se anularán a menos que se
                        indique lo contrario.
                      </span>
                    </p>
                    <p>
                      <span>
                        En los mercados 2-way se aplican las reglas push a menos que se indique lo
                        contrario. Las apuestas en apuestas simples se devuelven, y en
                        múltiplos/parlays, la selección se trata como no participante.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span>Para las olimpíadas las siguientes reglas aplican:</span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <span>
                        La presentación del podio determinará la liquidación de las apuestas.
                      </span>
                    </p>
                    <p>
                      <span>
                        Las descalificaciones y/o apelaciones posteriores no afectarán las apuestas.
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      <b>Futbol de Playa</b>
                    </p>
                    <p>
                      <span>
                        Predecir el resultado al final del tiempo normal. El tiempo normal es de 36
                        minutos de juego.
                      </span>
                    </p>
                    <p>
                      <span>Si se pospone un partido, todas las apuestas serán anuladas.</span>
                    </p>
                    <p>
                      <span>
                        Si un partido comienza, pero no se completa, las apuestas del partido se
                        liquidarán según el resultado oficial. Otros mercados serán nulos a menos
                        que se haya determinado un mercado ganador.
                      </span>
                    </p>
                  </div>
                  <p>&nbsp;</p>
                  <p>
                    <b>Water polo</b>
                  </p>
                  <p>
                    <span>
                      Todos los mercados de partidos se liquidarán en el tiempo reglamentario, a
                      menos que se indique lo contrario. El tiempo reglamentario debe completarse
                      para que las apuestas sean válidas, a menos que se indique lo contrario. En el
                      caso de que un partido comience, pero no se complete, las apuestas serán
                      anuladas.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <div>
                    <p>
                      <b>Volleyball de Playa</b>
                    </p>
                    <p>
                      <span>
                        En el caso de que alguno de los jugadores nombrados en un partido cambie
                        antes de que comience el partido, todas las apuestas serán anuladas.
                      </span>
                    </p>
                    <p>
                      <span>
                        En el caso de que un partido comience, pero no se complete, todas las
                        apuestas serán anuladas.
                      </span>
                    </p>
                  </div>
                  <div></div>
                  <div>
                    <p>
                      <b>Ciclismo</b>
                    </p>
                    <p>
                      <span>
                        Todas las apuestas se liquidan según el resultado en el momento de la
                        presentación del podio. Cualquier descalificación o apelación que lleve a un
                        cambio en los resultados realizados después de eso no se tomará en
                        consideración.
                      </span>
                    </p>
                  </div>
                  <p>
                    <span>
                      Se anularán las apuestas realizadas a jugadores que no comiencen la carrera
                      señalada.
                    </span>
                  </p>
                  <p>
                    <span>Carrera/Etapa Ganador</span>
                  </p>
                  <p>
                    <span>Predice qué ciclista o equipo ganará la carrera / etapa en cuestión</span>
                  </p>
                  <div>
                    <p>
                      <b>Para los Juegos Olímpicos, se aplicarán las siguientes reglas:</b>
                      <span>&nbsp;</span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          La presentación del podio determinará la liquidación de las apuestas .
                        </span>
                      </li>
                      <li>
                        <span>
                          Las descalificaciones y/o apelaciones posteriores no afectarán a las
                          apuestas.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* end rules-wrap */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SportRules;
