import React from 'react'

// Images assets
import chromeIcon from '../../assets/images/chrome.png'
import firefoxIcon from '../../assets/images/mozilla.png'
import safariIcon from '../../assets/images/safari.png'

export default function LegacyBrowserNotification ({ isSupported, closeNote, showNote }) {
  if (isSupported) {
    return null
  }
  return (
    <div
      id='not-supported-message'
      className={` ${showNote ? '' : 'not-show'} flex-row row-center`}
    >
      <div className='big-icon alert'>
        <i className='ion-alert-circled' />
      </div>
      <strong>
        Te recomendamos que actualices la versión de tu navegador, sólo asi podrás tener la mejor
        experiencia jugando en Pickwin
      </strong>
      <div className='flex-col '>
        <div className='flex-row'>
          <a href='https://www.google.com/chrome/' target='blank'>
            <img className='browse-badge' src={chromeIcon} />
          </a>
          <a href='https://support.apple.com/downloads/safari' target='blank'>
            <img className='browse-badge' src={safariIcon} />
          </a>
          <a href='https://www.mozilla.org/en-US/firefox/new/' target='blank'>
            <img className='browse-badge' src={firefoxIcon} />
          </a>
        </div>
      </div>

      <button
        className='btn-close-panel'
        onClick={() => {
          closeNote()
        }}
      >
        <i className='ion-ios-close-outline' />
      </button>
    </div>
  )
}
