!(function (s, f) {
  void 0 === s[f] &&
    (s[f] = {
      on() {
        this.q.push({ f: "on", args: arguments });
      },
      off() {
        this.q.push({ f: "off", args: arguments });
      },
      emit() {
        this.q.push({ f: "emit", args: arguments });
      },
      q: [],
    });
})(window, "btoEventBus");
