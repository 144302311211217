import React, { useEffect, useState } from 'react';
import styles from './index.module.sass'

export default function HorizontalProgressBar({ progress, backgroundColor, isPercent, data }) {
  const [width, setWidth] = useState("0%")
  let stylesBar = {
    inner: { width: width }
  };

  useEffect(() => {
    setTimeout(function () {
      setWidth(progress + '%');
    }, 500);
  }, []);

  return (
    <div className={styles.barContainer}>
      <div className={`${styles.progress} ${styles.rounded}`}>
        <div className={`${styles.progress_inner} ${styles.with_shadow}`} style={{ width: width, backgroundColor: backgroundColor }}></div>
      </div >
      <div className={styles.percentText}>{isPercent && width || data}</div>
    </div>
  );
}