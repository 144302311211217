import React from 'react';
import 'url-search-params-polyfill';
import PageWrapper from '../PageWrapper';
//import createHistory from 'history/createBrowserHistory';
import Billboard from '../Billboard';
import Pool from '../Pool';
import API from '../../utils/api';
import utils from '../../utils/utils';
import Loading from '../Loading';
import AbonarSaldo from '../AbonarSaldo';
import FirstTimeDownloadPanel from '../FirstTimeDownloadPanel';
import MediaQuery from 'react-responsive';
import { Navbar, Nav, NavItem, Button } from 'react-bootstrap';
import iconPrivadas from '../../assets/images/privadaIconBlanco-50.png';
import iconQuinielas from '../../assets/images/quinielasIconBlanco-50.png';
import { LinkContainer } from 'react-router-bootstrap';
import abonarSaldo from '../../assets/images/abonarIconBlanco-50.png';
import PanelQuinielasPrivadas from '../PanelQuinielasPrivadas';
import gaUtils from '../GAWrapper';
import { UserDataContextConsumer } from '../../context/UserDataContext';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import HorizontalProgressBar from '../Generals/HorizontalProgressBar';
import Modalhome from '../ModalHome/ModalHome';
import PromotionsSelection from '../PromotionsSelection';
import Survey from '../Survey/Survey';

//const history = createHistory();
const history = require('history').createBrowserHistory();

class ExtraNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAbonarSaldo: false,
      showQuinielasPrivadas: false,
    };
    this.updatePanelSate = this.updatePanelSate.bind(this);
    this.showAbonarPop = this.showAbonarPop.bind(this);
    this.handleQuinielasPrivada = () => {
      this.setState((prevState) => {
        return { showQuinielasPrivadas: !prevState.showQuinielasPrivadas };
      });
    };
  }
  /**
   * This Function makes visible the Abonar Saldo Panel
   * */
  showAbonarPop() {
    console.log('ShowAbonar');
    this.setState((prevState) => {
      return {
        showAbonarSaldo: !prevState.showAbonarSaldo,
      };
    });
  }

  /**
   * This Function hides the Abonar Saldo Panel
   * */
  updatePanelSate() {
    console.log('Updating Abonar Pop');
    this.setState({
      showAbonarSaldo: false,
    });
  }

  render() {
    return (
      <Navbar
        className="navbar-custom-extra flex-container"
        fixedTop
        inverse
        fluid
        collapseOnSelect
      >
        <Nav className="pickwin-nav-extra flex-col col-95">
          {/*<LinkContainer className="nav-item" exact to='/'>*/}
          {/*<NavItem className="nav-item" eventKey={1} >*/}
          {/*<Button className='btn-pick' bsSize='small'>*/}
          {/*<img src={iconLobby} className="img-responsive navbar-icons"/>*/}
          {/*<span className="textNavbar">LOBBY</span>*/}
          {/*</Button>*/}
          {/*</NavItem>*/}
          {/*</LinkContainer>*/}
          <div className="flex-row">
            <LinkContainer className="nav-item flex-col" to="/mis_quinielas">
              <NavItem className="nav-item flex-row" eventKey={2}>
                <Button className="btn-pick mis-quinielas " bsSize="small">
                  <img src={iconQuinielas} className="img-responsive navbar-icons" alt="" />
                  <span className="textNavbar">MIS QUINIELAS</span>
                </Button>
              </NavItem>
            </LinkContainer>
            <AbonarSaldo
              type="modal"
              showAbonarSaldo={this.state.showAbonarSaldo}
              updatePanelSate={this.updatePanelSate}
              showAbonarPop={this.showAbonarPop}
              updateUserBalance={this.props.updateUserBalance}
              updateUserInfo={this.props.updateUserInfo}
            />
            <NavItem className="nav-item flex-col">
              <Button className="btn-pick abonar" bsSize="small" onClick={this.showAbonarPop}>
                <img className="img-responsive navbar-icons" src={abonarSaldo} alt="" />
                <span className="">ABONAR</span>
              </Button>
            </NavItem>
            <PanelQuinielasPrivadas
              showQuinielasPrivadas={this.state.showQuinielasPrivadas}
              updatePanelState={this.handleQuinielasPrivada}
            />
            <NavItem className="nav-item flex-col">
              <Button
                className="btn-pick privadas"
                bsSize="small"
                onClick={this.handleQuinielasPrivada}
              >
                <img className="img-responsive navbar-icons" src={iconPrivadas} alt="" />
                <span className="">PRIVADAS</span>
              </Button>
            </NavItem>
          </div>
          {/* <div className="flex-row">
            <div className="flex-col" style={{ position: 'relative' }}>
              <i className="shineEffect" />
              <Button
                id="referralsButton"
                onClick={() => {
                  window.location.assign('/usuario/invitados');
                }}
              >
                <div className="flex-col col-no-padding col-center">
                  <span className=" flex-container justify align balance-text">
                    ¡Gana hasta <strong>$750,0 </strong> <small>MXN</small>!
                  </span>
                  <span className="flex-container justify align ">
                    <strong style={{ fontSize: '70%', lineHeight: '1.2' }}>Invitando amigos</strong>
                  </span>
                </div>
              </Button>
            </div>
          </div> */}
          <LinkContainer
            to="/usuario/recompensas"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div>
              <HorizontalProgressBar
                progress={78}
                backgroundColor="#78BC28"
                data=""
                isPercent={true}
              />
            </div>
          </LinkContainer>
        </Nav>
      </Navbar>
    );
  }
}

/**
 * This fucntion return the Lobby for Users
 * if the user is loggNa
 */
const LobbyUsers = (props) => {
  return props.mounted ? (
    <div className="container">
      <Billboard
        changePoolPopUpView={props.changePoolPopUpView}
        changeBracketPopUpView={props.changeBracketPopUpView}
        changeSurvivorPopUpView={props.changeSurvivorPopUpView}
        deviceType={props.deviceType}
        billboardIds={props.billboardIds}
        billboardRegisterStatus={props.billboardRegisterStatus}
        logged={utils.isLogged()}
      />
      <MediaQuery maxWidth={659}>
        {utils.isLogged() ? <ExtraNavBar updateUserInfo={props.updateUserInfo} /> : null}
      </MediaQuery>
      <Pool
        handleTutorial={props.handleTutorial}
        handleTutorialStep={props.handleTutorialStep}
        referer={props.referer}
        bannerChanged={props.bannerChanged}
        billboardIds={props.billboardIds}
        changePoolPopUpView={props.changePoolPopUpView}
        changeBracketPopUpView={props.changeBracketPopUpView}
        changeSurvivorPopUpView={props.changeSurvivorPopUpView}
        checkRegisterInBillboard={props.checkRegisterInBillboard}
        closePopUpNewUsers={props.closePopUpNewUsers}
        deleteNewUserStatus={props.deleteNewUserStatus}
        logged={props.isLogged}
        popUpNewUsers={props.popUpNewUsers}
        popUpNewUsersStatus={props.popUpNewUsersStatus}
        popUpPoolId={props.popUpPoolId}
        popUpPoolGroupId={props.popUpPoolGroupId}
        popUpBracketId={props.popUpBracketId}
        popUpSurvivorId={props.popUpSurvivorId}
        popUpView={props.popUpView}
        updateUserBalance={props.updateUserBalance}
        updateUserInfo={props.updateUserInfo}
        userData={props.userData}
        match={props.match}
      />
    </div>
  ) : (
    <Loading label="Cargando..." />
  );
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      logged: false,
      bannerChanged: false,
      popUpNewUsersStatus: false,
      popUpNewUsers: null,
      userData: null,
      userLoyalty: null,
      billboardRegisterStatus: [],
      showVideo: false
    }

    this.changePoolPopUpView = this.changePoolPopUpView.bind(this);
    this.isLogged = this.isLogged.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.checkRegisterInBillboard = this.checkRegisterInBillboard.bind(this);
    this.closePopUpNewUsers = this.closePopUpNewUsers.bind(this);

    this.noThanks = () => {
      this.setState({ isFirstTimeUser: false });
      history.push(`/`);
    };

    this.deleteNewUserStatus = () => {
      this.setState({ popUpNewUsers: null, popUpNewUsersStatus: false });
    };
    this.newRegister = () => {
      this.setState({
        popUpNewUser: true,
      });
    };

    this.changeBracketPopUpView = (popUpBracketId) => {
      console.log('Change popUpBracketId', popUpBracketId);
      this.setState({
        popUpBracketId,
        popUpView: true,
      });
    };

    this.changePoolGroupPopUpView = (popUpPoolGroupId) => {
      console.log('Change popUpPoolGroupId', popUpPoolGroupId);
      this.setState({
        popUpPoolGroupId,
        popUpView: true,
      });
    };

    this.changeSurvivorPopUpView = (popUpSurvivorId) => {
      console.log('Change popUpPoolId', popUpSurvivorId);
      this.setState({
        popUpSurvivorId,
        popUpView: true,
      });
    };
  }

  /**
   * This function updates the state of the user info
   * by Getting the updated data from the API
   */
  updateUserInfo(data) {
    // update data balances and stuff
    console.log('%c updateUserInfo in HOME', 'color:rgb(39, 159, 40); font-size:1.5em;');
    if (!data && data !== 'isNewRegister') {
      API.fetchUserData()
        .then((response) => {
          utils.setUserInfo(response.data);
          this.setState(() => {
            // console.log('UpdateingUSerIngo in Home');
            return {
              // popUpPoolId : null,
              // popUpView : 0,
              userData: response.data,
              logged: response.data.user !== null ? true : false,
            };
          });
        })
        .catch((error) => {
          console.error(error);
          // Sentry.captureMessage( 'Token/Session Expired');
          Sentry.captureException(error, {
            extra: 'Token/Expired ',
          });
          utils.logout();
        });
    } else if (data === 'isNewRegister') {
      API.fetchUserData()
        .then((response) => {
          let newUserData = response.data;
          console.log(newUserData);
          newUserData.is_new = true;
          newUserData.entry_count = 1;
          utils.setUserInfo(newUserData);
          this.setState(() => {
            // console.log('UpdateingUSerIngo in Home');
            return {
              // popUpPoolId : null,
              // popUpView : 0,
              userData: newUserData,
              logged: response.data.user !== null ? true : false,
            };
          });
        })
        .catch((error) => {
          // Sentry.captureMessage( 'Erro Updating User Info in Home');
          console.error(error);

          Sentry.captureException(error, {
            extra: 'Error updating User Info in Home',
          });
          utils.logout();
        });
    } else if (data.hasOwnProperty('token')) {
      //Is widget
      axios.headers.common['Authorization'] = `Bearer ${data.token}`;
      this.setState(
        {
          userData: data.user,
          logged: true,
        },
        () => {
          console.log('Logged in successfull');
        }
      );
    } else {
      this.setState(() => {
        // console.log('UpdateingUSerIngo in Home');
        return {
          // popUpPoolId : null,
          // popUpView : 0,
          userData: data,
          logged: !data.user ? false : true,
        };
      });
    }
  }

  checkRegisterInBillboard(pools) {
    console.log('CheckREgister ', this.props.billboardIds);
    if (this.props.billboardIds === undefined) {
      return;
    }
    let billboardPools = pools.filter((pool) => {
      this.props.billboardIds.forEach((billboard) => {
        if (Number(billboard) === pool.id) {
          console.log(Number(billboard), pool.id);
          return pool;
        }
      });
    });
    console.log(billboardPools);
    billboardPools.map((billboardPool) => {
      if (billboardPool.first_entry !== undefined) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({
      billboardRegisterStatus: billboardPools,
    });
    return billboardPools;
  }

  /**
   * This Function sets the logged state of the User
   */
  isLogged() {
    console.log('isLogged?', this.state.userData);
    if (this.state.userData !== null) {
      //  console.log('userData available',this.state);
      if (this.state.userData.user !== null) {
        this.setState({
          logged: true,
        });
      }
    } else {
      // console.log('userData null',this.state.userData);
      this.setState({
        logged: false,
      });
    }
  }

  /************************
   *  LifeCycle Methods
   **************************/
  componentDidMount() {
    let fakeMatch = {
      isExact: true,
      params: {
        slug: 'Quinielas y Apuestas Deportivas | Pickwin',
      },
    };

    gaUtils.handleDocumentAttributes(fakeMatch);

    const showTutorialSurvivor = utils.getTutorialSurvivorStatus()

    if (!showTutorialSurvivor) {
      utils.showTutorialSurvivorPopup();
    }

    let userData = utils.getUserInfo();

    API.fetchBannerData()
      .then((response) => {
        this.setState({
          billboardIds: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    const redirectTo = utils.getDataNotLoggedUser()
    const { params } = this.props.match

    let popUpPoolId = null
    let popUpPoolGroupId = null
    let popUpBracketId = null
    let popUpSurvivorId = null
    /**
     * If the link has the params of poolId and showPopUp
     * change the state and pass it through the childrens
     * to get the view with the popup loaded and open
     */
    if (params?.poolId) {
      popUpPoolId = Number(params.poolId)
    }
    
    else if (params?.poolGroupId) {
      popUpPoolGroupId = Number(params.poolGroupId)
    }
    
    else if (params?.bracketId) {
      popUpBracketId = Number(params.bracketId);
    }
    
    else if (params?.survivorId) {
      popUpSurvivorId = Number(params.survivorId);
    }
    
    else if (redirectTo && redirectTo?.game_type === 'quinielaGrupo') {
      popUpPoolGroupId = Number(redirectTo.id)
    }
    
    else if (
      redirectTo && (
        redirectTo?.game_type === 'quiniela' ||
        redirectTo?.game_type === 'bracket' ||
        redirectTo?.game_type === 'survivor'
      )
    ) {
      popUpPoolId = Number(redirectTo.id)
    }
    
    utils.removeDataNotLoggedUser();
    try {
      utils.getUserMetadataRegister( window.location.search );
    } catch (e) {}

    /**
     * Check if the referer query exist,
     * if it exist in the link update the local storage item
     */
    if (
      utils.getReferer() === null ||
      utils.getReferer() === undefined ||
      utils.getReferer() === 'undefined'
    ) {
      // console.log('Referer Empty')

      const search = this.props.location.search.substring(1);

      if (search !== undefined || search !== null || search !== 'undefined') {
        const params = new URLSearchParams(search);
        // console.log( params.get('sports') );
        utils.setReferer(params.get('referer'));

        if (params.has('filters') && params.get('filters') === '1' ) {
          utils.setFilters(params);
        }
        // TODO: update url from localStorage
        // else {
        //   utils.removeFilters();
        // }
        // console.log('Set new referer in HOME');
      }
    }

    /**
     * Check if the landing query exist,
     * if it exist in the link update the local storage item
     */
    // console.log(popUpNewUserStatus);
    // if (popUpNewUserStatus !== null) {
    //   this.setState({
    //     popUpNewUsers       : popUpNewUserStatus,
    //     popUpNewUsersStatus : true
    //   });
    // }

    this.setState(() => {
      // console.log('Id SurvivorPopup', popUpSurvivorId);
      // console.log(popUpBracketId);
      return {
        referer: utils.getReferer(),
        // isFirstTimeUser       : utils.isFirstTimeUser(),
        popUpBracketId: popUpBracketId,
        popUpSurvivorId: popUpSurvivorId,
        popUpPoolId,
        popUpPoolGroupId: popUpPoolGroupId,
        popUpView:
          popUpPoolId !== null ||
          popUpBracketId !== null ||
          popUpSurvivorId !== null ||
          popUpPoolGroupId !== null,
        mounted: true,
        userData: utils.getUserInfo(),
        userLoyalty: utils.getUserLoyalty(),
        logged: utils.isLogged(),
      };
    });
  }

  changePoolPopUpView(popUpPoolId) {
    console.log('Change popUpPoolId', popUpPoolId);
    this.setState(
      (prevState) => {
        return {
          popUpPoolId,
          popUpView: true,
          bannerChanged: !prevState.bannerChanged,
        };
      },
      () => {
        setTimeout(() => {
          console.log('FALSEE');
          this.setState({
            popUpView: false,
            popUpPoolId: null,
          });
        }, 1250);
      }
    );
  }

  closePopUpNewUsers() {
    console.log('Close PopUpP New users');
    this.setState(
      {
        popUpNewUsersStatus: false,
      },
      () => {
        // utils.deletePopUpNewUser();
      }
    );
  }

  render() {
    if (!this.state.mounted) {
      return <Loading label="Espera un momento..." />;
    }
    // console.log( this.state.popUpNewUsers );
    // console.log( this.state.popUpNewUsersStatus );
    if (utils.isFirstTimeUser() && utils.isLogged() && $(window).width() < 769) {
      return <FirstTimeDownloadPanel noThanks={this.noThanks} />;
    } else {
      return (
        <PageWrapper>
          <UserDataContextConsumer>
            {({
              userData,
              userBalance,
              updateUserInfo,
              updateUserBalance,
              isNewFlowActive,
              handleTutorial,
              handleTutorialStep,
            }) => {
              return (
                <React.Fragment>

                  <LobbyUsers
                    isNewFlowActive={isNewFlowActive}
                    handleTutorial={handleTutorial}
                    handleTutorialStep={handleTutorialStep}
                    updateUserBalance={updateUserBalance}
                    updateUserInfo={updateUserInfo}
                    userData={userData}
                    userBalance={userBalance}
                    bannerChanged={this.state.bannerChanged}
                    billboardIds={this.state.billboardIds}
                    changePoolPopUpView={this.changePoolPopUpView}
                    changeSurvivorPopUpView={this.changeSurvivorPopUpView}
                    changeBracketPopUpView={this.changeBracketPopUpView}
                    changePoolGroupPopUpView={this.changePoolGroupPopUpView}
                    checkRegisterInBillboard={this.checkRegisterInBillboard}
                    closePopUpNewUsers={this.closePopUpNewUsers}
                    deleteNewUserStatus={this.deleteNewUserStatus}
                    deviceType={this.props.deviceType}
                    mounted={this.state.mounted}
                    referer={this.state.referer}
                    popUpNewUsers={this.state.popUpNewUsers}
                    popUpNewUsersStatus={this.state.popUpNewUsersStatus}
                    popUpPoolId={this.state.popUpPoolId}
                    popUpPoolGroupId={this.state.popUpPoolGroupId}
                    popUpBracketId={this.state.popUpBracketId}
                    popUpSurvivorId={this.state.popUpSurvivorId}
                    popUpView={this.state.popUpView}
                    isLogged={utils.isLogged()}
                    match={this.props.match}
                  />
                  {userData !== false &&
                  <>
                    <PromotionsSelection
                      updateUserInfo={updateUserInfo}
                      updateUserBalance={updateUserBalance}
                    />
                    <Survey />
                  </>
                  }
                </React.Fragment>
              );
            }}
          </UserDataContextConsumer>
        </PageWrapper>
      );
    }
  }
}

export default Home;
