import React from 'react';
import '../../assets/stylesheets/types-sprites.scss';
import firstBy from 'thenby';
import { useState } from 'react';
import { useEffect } from 'react';
import avatar from '../../assets/images/avatar-50.png';

const categories = [
  { label: 'Pos', size: 10 },
  { label: 'Jugador', size: 30 },
  { label: 'Sc', size: null },
  { label: 'H', size: null },
  { label: 'Hoy', size: null },
  { label: 'R1', size: null },
  { label: 'R2', size: null },
  { label: 'R3', size: null },
  { label: 'R4', size: null },
];

const categoriesF1 = [
  { label: 'Pos', size: 10 },
  { label: 'Piloto', size: 30 },
  { label: 'Vueltas', size: null },
  { label: 'Tiempo', size: null },
  { label: 'Pits', size: null },
];

const LeadersHeader = ({ categories }) => {
  let listCategories = categories.map((c, cIdx) => {
    return (
      <li
        key={cIdx}
        className={`flex-col col-no-padding col-${c.size} ${
          c.label !== 'Jugador' ? 'text-center col-center' : ''
          } col-center`}
      >
        {c.label}
      </li>
    );
  });
  return <ul className="header-leaderboard flex-row row-center">{listCategories}</ul>;
};

const LeadersHeaderF1 = ({ categories }) => {
  let listCategories = categories.map((c, cIdx) => {
    return (
      <li
        key={cIdx}
        className={`flex-col col-no-padding col-${c.size} ${
          c.label !== 'Jugador' ? 'text-center col-center' : ''
          } col-center`}
      >
        {c.label}
      </li>
    );
  });
  return <ul className="header-leaderboard flex-row row-center">{listCategories}</ul>;
};

const SurvivorLeaderBoard = ({ survivor, myEntry, players, contest }) => {
  const [thePlayers, setThePlayers] = useState(players);
  if (survivor.sport_id === 7) {
    players = players.sort(firstBy('finish_pos'));
  }

  const sortByPosition = (asc) => {
    return function (a, b) {
      if (a.position === b.position) {
        return 0;
      }
      else if (a.position === null) {
        return 1;
      }
      else if (b.position === null) {
        return -1;
      }
      else if (asc) {
        return a.position < b.position ? -1 : 1;
      }
      else {
        return a.position < b.position ? 1 : -1;
      }
    };
  }

  useEffect(() => {
    let _players = players;
    if (survivor.sport_id !== 7) {
      console.log("orden golf");
      _players = players.sort(sortByPosition(true));
    } else {
      console.log("orden f1");
      _players = players.sort(firstBy('finish_pos'));
    }
    setThePlayers((prevState) => {
      return [...prevState, _players]
    });
  }, [players]);

  let list = thePlayers.map((player, i) => {
    //console.log(player);
    let selectedPlayer = null;

    // Si este jugador esta en mis picks

    if (myEntry && myEntry.survivor_entry.survivor_picks.hasOwnProperty(contest.id)) {
      const pick = myEntry.survivor_entry.survivor_picks[contest.id];
      selectedPlayer = pick.pick === player.id;
    }
    // console.log( 'selectedPlauyer', selectedPlayer);

    let colorStyle = {};
    let positionStyle = {};

    if (player.score > 0) {
      colorStyle = { color: 'rgb(255, 199, 48)' };
    } else if (player.score < 0) {
      colorStyle = { color: '#78bc28' };
    }

    if (player.position > 0 && player.position <= contest.elimination_threshold) {
      positionStyle = { color: 'rgb(120, 188, 40)', fontWeight: '600' };
    } else {
      positionStyle = { color: '#e00034', fontWeight: '600' };
    }

    return (
      <li
        key={i}
        className={`flex-row row-center row-no-padding leaderboard-item ${
          selectedPlayer ? 'myPick' : ''
          } `}
      >
        <div
          className={`flex-col col-${categories[0].size} col-center text-center `}
          style={positionStyle}
        >
          {player.position}
          {!player.position && player.position !== '0' ? '-' : '°'}
        </div>
        <div className={`flex-col col-${categories[1].size} `}>
          <div className="flex-row row-center uppercase">
            <img
              src={player.avatar === null ? avatar : player.avatar}
              className="player-avatar"
            />
            <i className={player.logo} style={{ marginRight: '5px' }} />
            <div className="overflow-words"> {player.name} </div>
          </div>
        </div>
        <div
          className={`flex-col col-${categories[2].size} col-center text-center`}
          style={colorStyle}
        >
          {player.score === '0' ? 'E' : player.score}
        </div>
        <div className={`flex-col  col-${categories[3].size} col-center text-center `}>
          {player.data && player.data.hole && player.data.hole !== '' ? player.data.hole : '-'}
        </div>
        <div className={`flex-col  col-${categories[4].size} col-center text-center`}>
          {player.data && player.data.today && player.data.today !== '' ? player.data.today : '-'}
        </div>
        <div className={`flex-col  col-${categories[5].size} col-center text-center`}>
          {player.data && player.data.r1 ? player.data.r1 : '-'}
        </div>
        <div className={`flex-col col-${categories[6].size} col-center text-center`}>
          {player.data && player.data.r2 ? player.data.r2 : '-'}
        </div>
        <div className={`flex-col col-${categories[7].size} col-center text-center`}>
          {player.data && player.data.r3 ? player.data.r3 : '-'}
        </div>
        <div className={`flex-col col-${categories[8].size} col-center text-center`}>
          {player.data && player.data.r4 ? player.data.r4 : '-'}
        </div>
      </li>
    );
  });

  let listF1 = thePlayers.map((player, i) => {
    let selectedPlayer = null;

    // Si este jugador esta en mis picks
    if (myEntry && myEntry.survivor_entry.survivor_picks.hasOwnProperty(contest.id)) {
      const pick = myEntry.survivor_entry.survivor_picks[contest.id];
      selectedPlayer = pick.pick === player.id;
    }
    // console.log( 'selectedPlauyer', selectedPlayer);

    let colorStyle = {};
    let positionStyle = {};

    // if (player.score > 0) {
    //   colorStyle = { color: 'rgb(255, 199, 48)' };
    // } else if (player.score < 0) {
    //   colorStyle = { color: '#78bc28' };
    // }
    //colorStyle = { color: 'rgb(255, 199, 48)' };

    // if (player.finish_pos > 0 && player.finish_pos <= contest.elimination_threshold) {
    //   positionStyle = { color: 'rgb(120, 188, 40)', fontWeight: '600' };
    // } else {
    //   positionStyle = { color: '#e00034', fontWeight: '600' };
    // }
    //positionStyle = { color: 'rgb(120, 188, 40)', fontWeight: '600' };

    return (
      <li
        key={i}
        className={`flex-row row-center row-no-padding leaderboard-item ${
          selectedPlayer ? 'myPick' : ''
          } `}
      >
        <div
          className={`flex-col col-${categories[0].size} col-center text-center `}>
          {player.finish_pos}
        </div>
        <div className={`flex-col col-${categories[1].size} `}>
          <div className='flex-row'>
            <div className='flex-col col-20' style={{marginRight: '1rem'}}>
              <img src={player.avatar === null ? avatar : player.avatar} className="player-avatar" style={{width: '30px', height: 'auto', justifyContent: 'center', alignItems: 'center'}}/>
            </div>
            <div className='flex-col col-80'>
              <div className='flex-container'>
                <i className={player.logo} style={{ marginRight: '5px' }} />
                <div className="overflow-words"> {player.name} </div>
              </div>
              <div>
                <img className="" src={player.alt_avatar === null ? '' : player.alt_avatar} style={{width: '55px', height: 'auto'}} />
              </div>
            </div>
          </div>
          {/* <div className='flex-container center'>
            <div>
              <img src={player.avatar === null ? avatar : player.avatar} className="player-avatar" style={{width: '30px', height: 'auto'}}/>
            </div>
            <div className='flex-container'>
              <i className={player.logo} style={{ marginRight: '5px' }} />
              <div className="overflow-words"> {player.name} </div>
            </div>
          </div> */}
        </div>
        <div
          className={`flex-col col-${categories[2].size} col-center text-center`}>
          {player.laps}
        </div>
        <div className={`flex-col  col-${categories[3].size} col-center text-center `}>
          {player.time === null ? '-' : player.time}
        </div>
        <div className={`flex-col  col-${categories[4].size} col-center text-center`}>
          {player.pit}
        </div>
      </li>
    );
  });

  return (
    <div className=" multiple-quiniela-info principal flex-row row-no-padding responsive-sm lista-jugadores panel-pickwin flex-container justify align">
      <div style={{ margin: '15px ' }} className="flex-col col-95 col-no-padding panel-pickwin">
        <div className={`flex-row title-quiniela`}>
          <div className="flex-col title-wrapper">
            <div className="flex-row row-no-padding row-center">
              <i className=" ion-podium " />
              <span className="flex-col">
              {survivor.sport_id !== 7 && (
                'LEADERBOARD'
              ) || (
                'RESULTADOS'
              )}
              </span>
            </div>
          </div>
        </div>

        {survivor.sport_id !== 7 && (
          <LeadersHeader categories={categories} />
        ) || (
          <LeadersHeaderF1 categories={categoriesF1} />
        )}
        <ul className="list-unstyled flex-col col-no-padding leaderboard-list overflow-y overflow overflow-picks-grupo">
          {survivor.sport_id !== 7 && (
            <React.Fragment>
              {list}
            </React.Fragment>
          ) || (
            <React.Fragment>
              {listF1}
            </React.Fragment>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SurvivorLeaderBoard;
