import React from 'react'
import {
  Button,
  Modal
} from 'react-bootstrap/lib/'
import ReactCrop from 'react-image-crop'
import FileBase64 from 'react-file-base64'

// Components
import Iconify from '../../../../Iconify'

// Utils
import API from '../../../../../utils/api'
import utils from '../../../../../utils/utils'

// Hooks
import useMergedState from '../../../../../hooks/useMergedState'

// Assets
import seleccionar from '../../../../../assets/images/crop.png'
import avatar from '../../../../../assets/images/invitarIconBlanco-50.png'
import avatarV from '../../../../../assets/images/invitarIconVerde-50.png'

// Styles
import '../../../../../assets/stylesheets/overrides/react-crop.scss'

function UploadAvatar(props) {
  const [state, setState] = useMergedState({
    userData: props.userData,
    imgSrc: null,
    imgCropped: null,
    file: null,
    cropped: false,
    cropImgLoaded: false,
    crop: {
      aspect: 1
    },
    updatedAvatar: false
  })

  /**
   * This function restore the default data
   */
  const restoreData = () => {
    setState({
      imgSrc: null,
      imgCropped: null,
      file: null,
      cropped: false,
      cropImgLoaded: false,
      crop: {
        aspect: 1
      },
      updatedAvatar: false
    })
  }

  /**
   * This function post the image and data to the server
   */
  const actualizarAvatar = () => {
    const newAvatar = {
      avatar: state.imgCropped
    }

    API.postAvatar(newAvatar).then((response) => {
      if (response.data.success) {
        setState(
          {
            updatedAvatar: true
          },
          () => {
            API.fetchUserData()
              .then((response) => {
                if (response.status === 200) {
                  utils.setUserInfo(response.data)
                  props.updatedAvatar()
                }
              })
              .catch((err) => {
                utils.settUserContextRaven(utils.getUseInfo())
                utils.catchErrorRaven(err, utils.getUserInfo, 'Error in Change avatar')
              })
          }
        )
      } else {
        setState({
          updatedAvatar: false
        })
      }
    })
  }

  /**
   * Ready to crop
   */
  const ready = () => {
    setState({
      cropImgLoaded: true
    })
  }

  /**
   * This function Load the image
   * @param src
   * @param callback
   */
  const loadImage = (src, callback) => {
    let image = new Image()
    image.onload = function() {
      callback(image)
      image = null
    }
    image.src = src
  }

  /**
   * This function Crop the image by
   * @param imgDest
   * @param imgSrc
   * @param crop
   */
  const cropImage = (imgDest, imgSrc, crop) => {
    loadImage(imgSrc, cropAfterLoad.bind(this))
    function cropAfterLoad(loadedImg) {
      const imageWidth = loadedImg.naturalWidth
      const imageHeight = loadedImg.naturalHeight
      const cropX = (crop.x / 100) * imageWidth
      const cropY = (crop.y / 100) * imageHeight
      const cropWidth = (crop.width / 100) * imageWidth
      const cropHeight = (crop.height / 100) * imageHeight
      const canvas = document.createElement('canvas')

      canvas.width = cropWidth
      canvas.height = cropHeight
      const ctx = canvas.getContext('2d')
      ctx.drawImage(loadedImg, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight)
      // canvas.toBlob will be faster and non-blocking but is currently only supported in FF.
      canvas.toBlob(function(blob) {
        const url = URL.createObjectURL(blob)
        imgDest.onload = function() {
          URL.revokeObjectURL(url)
          ready()
        }
        imgDest.src = url
      })

      imgDest.src = canvas.toDataURL('image/jpeg')
      setState({
        imgCropped: imgDest.src,
        fileCropped: imgDest
      })
      ready()
    }
  }

  /**
   * This function Saves the Image
   */
  const saveImage = () => {
    cropImage(state.file.file, state.imgSrc, state.crop)
  }

  const onImageLoaded = (crop) => {
    setState({
      crop
    })
  }

  const onCropComplete = (crop) => {
    setState({
      crop,
      cropped: true
    })
  }

  const getFiles = (files) => {
    setState({
      imgSrc: files.base64,
      file: files,
      imgCropped: null,
      fileCropped: null,
      cropImgLoaded: false,
      cropped: false,
      updatedAvatar: false
    })
  }

  let conditionalComponent

  conditionalComponent = (
    <Modal
      dialogClassName='cambiarAvatar row'
      className='cambio-avatar'
      show={props.showCambiarAvatar}
      onHide={props.updatePanelState}
    >
      <div className='panel-cambiar-avatar flex-container column'>
        <div id='panelTitle' className='panel-abonar-titulo col-xs-12'>
          {state.updatedAvatar
            ? (
              <span>¡ Felicidades !</span>
            )
            : (
              <span>Cambia tu avatar</span>
            )}
          <button
            className='cerrar-panel'
            onClick={() => {
              props.updatePanelState()
              restoreData()
            }}
          >
            <div className='btn-icon-close'>
              <i className='ion-ios-close-empty' />
            </div>
          </button>
        </div>
        {!state.updatedAvatar
          ? (
            <div className='crop-note'>
              <div className='pasos'>
                <div className='titulo-pasos'>
                  <span className='numero-pasos'>1</span>
                  <span>Selecciona</span>
                </div>
                <div className='titulo-pasos'>
                  <span className='numero-pasos'>2</span>
                  <span>Recorta</span>
                </div>
                <div className='titulo-pasos'>
                  <span className='numero-pasos'>3</span>
                  <span>Actualiza</span>
                </div>
              </div>
            </div>
          )
          : null}
        {state.imgSrc !== null
          ? (
            state.updatedAvatar
              ? (
                <div className='avatar-actualizado'>
                  <div className='panel-notificacion'>Tu avatar se cambió con éxito</div>
                  <i className='ion-checkmark-circled' />
                  <Button
                    bsStyle='danger'
                    className='guardar-picks btn-rojo flex-col col-50 col-offset-25'
                    onClick={() => {
                      props.updatePanelState()
                      restoreData()
                    }}
                  >
                    {' '}
                    REGRESAR{' '}
                  </Button>
                </div>
              )
              : (
                <div>
                  <ReactCrop
                    src={state.cropImgLoaded ? state.imgCropped : state.imgSrc}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    minHeight={20}
                    minWidth={20}
                    keepSelection
                    crop={state.cropImgLoaded ? { aspect: 1 } : state.crop}
                  />
                  <div className='image-control-buttons'>
                    <Button
                      className='crop-btn'
                      onClick={saveImage}
                      disabled={!state.cropped}
                    >
                      <img className='crop-icon' src={seleccionar} /> Recorta tu imagen
                      </Button>
                    <Button
                      onClick={(e) => {
                        actualizarAvatar()
                        props.disableClickedButton(e, 6000)
                      }}
                      disabled={!state.cropImgLoaded}
                    >
                      {' '}
                      <img
                        className='crop-icon'
                        src={state.cropImgLoaded ? avatarV : avatar}
                      />{' '}
                      Actualiza tu avatar
                      </Button>
                  </div>
                </div>
              )
          )
          : null}
        <div className='cropPanel'>
          <label
            className='custom-file-upload mt-2 mb-2'
            style={state.file !== null ? { display: 'none' } : null}
          >
            <Iconify icon='mdi:image' className='mr-1' />
            Selecciona tu imagen
            <FileBase64 multiple={false} onDone={getFiles} />
          </label>
          <span className='col-xs-10 col-xs-offset-1 mt-2 mb-3 text-center'>
            Tú imagen debe de ser de un formato cuadrado máximo de 500 x 500 px
          </span>
        </div>
      </div>
    </Modal>
  )
  return conditionalComponent
}

export default UploadAvatar
