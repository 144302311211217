import React from 'react'
import styled from 'styled-components'

import BenefitList from './BenefitList'
import GlowText from './GlowText'
import Text from '../../Text'

function LevelBenefits (props) {
  const { level = {}, points = {} } = props.userLoyalty
  const winCash = (points.total_rake * level.reward) / 100
  const winVip = (points.total_rake * level.extra_reward) / 100

  const list = props.benefits[level.name] || []
  
  return (
    <>
      <Row>
        <Text as='h6' style={{width: 'fit-content' }}>Rembolso a tu saldo:</Text>
        <GlowText
          currency
          animated
          shine
          glow={1}
          end={winCash}
          fontSize={35}
          sphereSize={22}
        />
      </Row>
      <Row>
        <Text as='h6' style={{width: 'fit-content' }}>Rembolso en puntos VIP:</Text>
        <GlowText
          animated
          shine
          glow={1}
          end={winVip}
          fontSize={35}
          sphereSize={22}
        />
      </Row>
      <Glow />
      <Text as='h6' mb={15} mt={25}>Beneficios de tu nivel:</Text>
      <BenefitList
        list={list}
      />
    </>
  )
}

const Glow = styled.div`
  position: absolute;
  top: 3%;
  right: 10%;
  width: 10em;
  height: 3em;
  z-index: 10;
  background-color: #a7ff0091;
  filter: blur(36px);
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`

export default LevelBenefits

