import React from 'react';

import {
  Button,
  ButtonGroup,
  Modal,
} from 'react-bootstrap/lib/';

import { Redirect } from 'react-router-dom';

import BuscarQuinielaPrivada from '../BuscarQuinielaPrivada/BuscarQuinielaPrivada';
import CrearQuinielaPrivada from '../CrearQuinielaPrivada/CrearQuinielaPrivada';

/**
 * This component handles the Main function of the panel
 * Searh or Create
 * @param {showControl}         boolean - this value handles the visibility of the panel
 * @param {showSearchSection}   boolean  - this value handles the visibility of the Section
 * @param {handleSearchSection} function  - this function activates the Search section
 * @param {showCreatePool}   function  - this value handles the visibility of the Section
 * @param {handleCreateSection} function  - this function activates the create section
 */
const ChoosePanelquinielaType = ({
  showControls,
  showSearchSection,
  showCreatePool,
  handleSection,
}) => {
  if (showControls === false) {
    return null;
  }
  return (
    <div className="flex-row">
      <ButtonGroup className="private-controls-buttons flex-container justify center">
        <Button
          className={`flex-col rounded font-chivo ${showSearchSection ? 'active' : ''} `}
          onClick={() => {
            handleSection('search');
          }}
        >
          <i className="ion-search"> </i> Buscar
        </Button>
        {/* <BuscarQuinielaPrivada showPanel={ showSearchSection }/> */}
        <Button
          className={`flex-col rounded font-chivo ${showCreatePool ? 'active' : ''} `}
          onClick={() => {
            handleSection('create');
          }}
        >
          <i className="ion-android-add-circle" /> Crear
        </Button>
        {/* <CrearQuinielaPrivada showPanel={ showCreatePool }/> */}
      </ButtonGroup>
    </div>
  );
};

const ChooseGameType = ({ showGameType, showControls, handleGameType }) => {
  if (showControls === false) {
    return null;
  }

  return (
    <div className="flex-row">
      <ButtonGroup className="private-controls-buttons flex-container justify center">
        <Button
          className={`flex-col rounded font-chivo ${showGameType === 'pool' ? 'active' : ''} `}
          onClick={() => {
            handleGameType('pool');
          }}
        >
          Quiniela
        </Button>
        <Button
          className={`flex-col rounded font-chivo ${showGameType === 'survivor' ? 'active' : ''} `}
          onClick={() => {
            handleGameType('survivor');
          }}
        >
          Survivor
        </Button>
      </ButtonGroup>
    </div>
  );
};

class PanelQuinielasPrivadas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      busquedaExitosa: null,
      crearQuiniela: false,
      errorMessage: null,
      gameType: null,
      logged: this.props.logged,
      modifiedData: false,
      quinielaCodigo: null,
      quinielaNombre: null,
      quinielaPrivSearchData: null,
      readyToSearch: false,
      showCloseConfirmation: false,
      showControls: true,
      showGameTypeControls: false,
      showCreatePool: false,
      showCreateSurvivor: false,
      showSearchSection: false,
      userData: this.props.userData,
    };
    /**
     * This functions handle the Sections of the panel
     */
    this.handleSearchSection = () => {
      // console.log('handleSearch');
      this.setState((prevState) => {
        return {
          showSearchSection: !prevState.showSearchSection,
          showCreatePool: prevState.showSearchSection,
          showControls: false,
        };
      });
    };
    this.handleSection = (section) => {
      // console.log('handleCreate');
      this.setState((prevState) => {
        return {
          // showCreatePool   : section === 'create',
          showSearchSection: section === 'search',
          showControls: false,
          showGameTypeControls: section === 'create',
        };
      });
    };

    this.handleCancelSelection = () => {
      // console.log('handleCreate');
      this.setState((prevState) => {
        return {
          showCreatePool: false,
          showCreateSurvivor: false,
          showSearchSection: false,
          showControls: true,
        };
      });
    };
    /**
     * This function handles if the panel has some unsaved  changes
     */
    this.changeModifiedState = () => {
      this.setState({
        modifiedData: true,
      });
    };
    /**
     * This function resets the data as default
     */
    this.resetData = () => {
      this.setState({
        busquedaExitosa: null,
        crearQuiniela: false,
        errorMessage: null,
        gameType: null,
        modifiedData: false,
        quinielaCodigo: null,
        quinielaNombre: null,
        quinielaPrivSearchData: null,
        readyToSearch: false,
        showCloseConfirmation: false,
        showControls: true,
        showCreatePool: false,
        showCreateSurvivor: false,
        showSearchSection: false,
        showGameTypeControls: false,
      });
    };

    /**
     * This functions handle the visibility of the close confirmation
     */
    this.showCloseConfirmation = () => {
      this.setState({
        showCloseConfirmation: true,
      });
    };
    this.hideCloseConfirmation = (which) => {
      this.setState(
        {
          showCloseConfirmation: false,
        },
        () => {
          if (which === 2) {
            this.props.updatePanelState();
            this.resetData();
          }
        }
      );
    };

    /**
     *
     */
    this.handleGameType = (gameType) => {
      console.log(gameType);
      this.setState({
        gameType: gameType,
        showCreatePool: gameType === 'pool',
        showCreateSurvivor: gameType === 'survivor',
        showGameTypeControls: false,
      });
    };
  }

  render() {
    // let loggedIn = false;
    let conditionalComponent;
    this.state.busquedaExitosa
      ? (conditionalComponent = (
          <Redirect
            push
            to={{
              state: { privateQuiniela: this.state.quinielaPrivSearchData },
              pathname: `/quiniela/${this.state.quinielaPrivSearchData.pool.slug}/${this.state.quinielaPrivSearchData.pool.id}`,
            }}
          />
        ))
      : this.state.crearQuiniela
      ? (conditionalComponent = (
          <Redirect
            push
            to={{
              pathname: '/crear/quiniela',
            }}
          />
        ))
      : (conditionalComponent = (
          <Modal
            dialogClassName="quinielasPrivadasModal modal-dialog-xxxs"
            // backdrop={false}
            className="privadasModal"
            show={this.props.showQuinielasPrivadas}
            // onHide={this.props.updatePanelState}
          >
            <Modal
              dialogClassName="quinielasPrivadasModal col-offset-xs-2 col-xs-8 col-offset-sm-2 col-sm-8  col-offset-4  col-md-4"
              show={this.state.showCloseConfirmation}
              backdropClassName="cancelConfirmationBackdrop"
              className="privadasModal"
            >
              <div className="panel-quiniela-privada confirmacion flex-container justify align">
                <div id="panelTitle" className="quinielas-privadas-titulo flex-row row-no-padding">
                  <span>¿Estás seguro?</span>
                </div>
                <div className="flex-col text-center">
                  <div className="flex-container justify align">
                    <i className="ion-alert-circled alert" />
                  </div>
                  <div>
                    ¿Seguro que quieres cerrar la ventana, ya tienes algunas opciones seleccionadas?
                  </div>
                </div>
                <div className="flex-col col-80 ">
                  <div className="flex-row">
                    <div className="flex-col">
                      <Button
                        className="btn-rojo btn-block btn-submit"
                        onClick={() => {
                          this.hideCloseConfirmation(1);
                        }}
                      >
                        <span>No</span>
                      </Button>
                    </div>
                    <div className="flex-col">
                      <Button
                        className="btn-block btn-pick btn-submit"
                        onClick={() => {
                          this.hideCloseConfirmation(2);
                        }}
                      >
                        <span>Si</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="panel-quiniela-privada">
              <div id="panelTitle" className=" quinielas-privadas-titulo">
                <span>Quinielas Privadas</span>
                <button
                  className="cerrar-panel"
                  onClick={() => {
                    if (this.state.modifiedData) {
                      this.showCloseConfirmation();
                    } else {
                      this.props.updatePanelState();
                      this.resetData();
                    }
                  }}
                >
                  <div className="btn-icon-close">
                    <i className="ion-ios-close-empty" />
                  </div>
                </button>
              </div>
              <div className="privadas-container ">
                <div className="flex-container justify align">
                  {this.state.showControls ? (
                    <span className="intro-copy">
                      <i className="ion-flag" /> En esta sección puedes buscar y crear quinielas
                      privadas.
                    </span>
                  ) : null}
                </div>
                <ChoosePanelquinielaType
                  showControls={this.state.showControls}
                  showSearchSection={this.state.showSearchSection}
                  showCreatePool={this.state.showCreatePool}
                  handleSection={this.handleSection}
                />
                <div className="flex-container justify align">
                  {this.state.showGameTypeControls ? (
                    <span className="intro-copy">
                      <i className="ion-flag" /> ¿Que tipo de juego quieres crear?
                    </span>
                  ) : null}
                </div>
                <ChooseGameType
                  showControls={this.state.showGameTypeControls}
                  showGameType={this.state.showGameType}
                  handleGameType={this.handleGameType}
                />
                <CrearQuinielaPrivada
                  modifiedData={this.state.modifiedData}
                  changeModifiedState={this.changeModifiedState}
                  showPanel={this.state.showCreateSurvivor || this.state.showCreatePool}
                  handleCancelSelection={this.handleCancelSelection}
                  gameType={this.state.gameType}
                />
                {this.state.showSearchSection ? (
                  <BuscarQuinielaPrivada
                    modifiedData={this.state.modifiedData}
                    changeModifiedState={this.changeModifiedState}
                    showPanel={this.state.showSearchSection}
                    handleCancelSelection={this.handleCancelSelection}
                  />
                ) : null}
              </div>
            </div>
          </Modal>
        ));
    return conditionalComponent;
  }
}
export default PanelQuinielasPrivadas;
