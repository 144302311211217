import axios from 'axios'
import jwtDecode from 'jwt-decode'
import API from '../utils/api'
import { publish } from './events'
import utils from './utils'

axios.defaults.headers.common['PW-Platform'] = 'web'
axios.defaults.headers.common['PW-Version'] = '2.0'

// Window expiration Time 2 minutes
const EXPIRED_MILLISECONDS_GAP = 1000 * 60 * 2

// Checks if the token is valid and not expired
export function isValidToken (token) {
  if (!token || token === null || token === undefined || token === 'undefined') {
    return false
  }

  const decode = jwtDecode(token) 
  // If has less than 5 minutes to
  // expiration time logout the user
  const expirationTime = decode.exp * 1000 - EXPIRED_MILLISECONDS_GAP
  const isValid = Date.now() < expirationTime

  if (isValid) {
    setSession(token)
  }

  return isValid
}

export function setSession (token) {
  if (token && token !== 'undefined') {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    utils.setToken(token)
  }
}

export function logoutSession () {
  const sessionKeys = [
    'isAuth',
    'user',
    'balance',
    'allowed',
    'jwtoken',
    'referer',
    'prevPoolId',
    'redirectTo',
    'userLoyalty',
    'popUpNewUser',
    'mobileBanner',
    'readyToVerify',
    'prevBracketId',
    'isNewFlowActive',
    'showGameFreeRoll',
    'showGameTutorial',
    'hasSentAnalytics',
    'misQuinielasItems',
    'showTutorialLobby',

    // For new context the others will be removed
    // When all will be migrated to user context
    'user-context',
    'availableCashout-context',
    'setUserBalance-context',
    'promotions-context',
    'userLoyalty-context'
  ]

  sessionKeys.forEach(key => window.localStorage.removeItem(key))
}

// Check other user sessions
export function validateUserSessions () {
  return new Promise(resolve => {
    if (utils.getToken()) {
      API.checkOtherSessionsUser().then((response) => {
        resolve()
        // User has active sessions, redirect to
        // another page to close active sessions
        if (response && response.data.active_sessions && window.location.pathname !== '/close-sessions') {
          window.localStorage.setItem('redirectAfterLogin', window.location.pathname)
  
          window.location = '/close-sessions'
        }
      })
    }
    resolve()
  })
}
