import React, { useEffect, useState } from 'react'

// Components
import { Typography, Box, MultiOption, InputNumber } from '../../../Xmerald-UI'
import CustomAwards from '../shared/CustomAwards'

// Store
import { useWizardPrivate } from '../wizardPrivate.store'

// Utils
import API from '../../../utils/api'
import WizardLayout from '../shared/Layout'

export default function SurvivorAwards () {
  const step = useWizardPrivate(state => state.step)
  const setStep = useWizardPrivate(state => state.setStep)
  const survivorData = useWizardPrivate(state => state.survivorData)
  const setSurvivorData = useWizardPrivate(state => state.setSurvivorData)
  const setCreatedSurvivor = useWizardPrivate(state => state.setCreatedSurvivor)

  const [creating, setCreating] = useState(false)
  const [errorMessage, setErrorMessage] = useState()


  useEffect(() => {
    // Generate new fields if number of prizes list changes
    if (survivorData.prizes_list) {
      setSurvivorData({
        survivor_prizes: Array(Number(survivorData.prizes_list))
          .fill(0)
          .map((_, index) => ({
            position: index + 1,
            type: 'percentage',
            percentage: 0
          }))
      })
    }
  }, [survivorData.prizes_list])

  const submit = async () => {
    setCreating(true)
    try {
      const response = await API.postCreatePrivateSurvivor(survivorData)

      if (response?.data?.survivor) {
        setCreatedSurvivor(response?.data?.survivor)
        setStep('SURVIVOR_SUCCESS')
      }
    } catch (error) {
      setErrorMessage(error)
    }
    setCreating(false)
  }

  if (step !== 'SURVIVOR_AWARDS') return null

  let disabledNext = false

  const isCustomPrice = survivorData.prize_scheme === 'custom'

  if (isCustomPrice) {
    // Calcule total with every custom price
    const total = survivorData.survivor_prizes
      .reduce((prev, next) => ({ percentage: (prev.percentage || 0) + next.percentage }), { percentage: 0 })

    // If user not filled 100%
    if (total.percentage < 100) {
      disabledNext = true
    } else {
      disabledNext = false
    }

    // If some of pool_prices is 0
    if (survivorData.survivor_prizes.find(item => item.percentage === 0)) {
      disabledNext = true
    }
  }

  return (
    <WizardLayout
      prevStep='SURVIVOR_PRICE'
      onClickNext={submit}
      disabledNext={disabledNext}
      loadingNext={creating}
    >
      <Box maxWidth={600} width='100%'>
        <MultiOption
          onChange={setSurvivorData}
          keyName='prize_scheme'
          title='¿Cómo quieres que se repartan los premios al final de la survivor?'
          value={survivorData.prize_scheme}
          options={[
            { name: 'Automáticamente', value: 'dynamic' },
            { name: 'Personalizado', value: 'custom' }
          ]}
        />

        {errorMessage && (
          <Typography center color='orangered' size='1em' my={2}>Ha ocurrido un error favor de intentenarlo nuevamente</Typography>
        )}

        {survivorData.prize_scheme === 'custom' && (
          <>
            <InputNumber
              value={Number(survivorData.prizes_list)}
              name='num'
              min={1}
              maxWidth={80}
              label='¿Cuántos premios quieres entregar?'
              onChange={(data) => {
                const prizes_list = String(data.num)
                setSurvivorData({ prizes_list })
              }}
            />
            <CustomAwards
              prizesList={survivorData.survivor_prizes}
              onChange={(survivor_prizes) => setSurvivorData({ survivor_prizes })}
            />
          </>
        )}
      </Box>
    </WizardLayout>
  )
}
