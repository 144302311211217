import React, { useState, useEffect } from 'react'
import { PulseLoader } from 'react-spinners'
import { useQuery } from '@tanstack/react-query'

// Components
import WizardLayout from '../shared/Layout'
import { Typography, Stack, Box, ButtonGlass } from '../../../Xmerald-UI'

// store
import { useWizardPrivate } from '../wizardPrivate.store'

// Utils
import API from '../../../utils/api'

// Assets
import futbol from '../../../assets/images/FutbolBall-50.png'
import americano from '../../../assets/images/AmericanoBall-50.png'
import hockey from '../../../assets/images/Hockey.png'
import golf from '../../../assets/images/GolfBall-50.png'
import f1 from '../../../assets/images/Volante-50.png'

const getSportImage = (id) => {
  switch (id) {
    case 1: return futbol
    case 2: return americano
    case 6: return golf
    case 7: return f1
    case 9: return hockey
    default: return null
  }
}

export default function SurvivorSport () {
  const { data, isLoading } = useQuery(
    ['tournament-sports-survivor'],
    () => API.fetchSports('survivor').then(({ data }) => {
      return {
        sports: Object.values(data.sports)
      }
    }))

  const step = useWizardPrivate(state => state.step)
  const setSurvivorData = useWizardPrivate(state => state.setSurvivorData)
  const setActiveTournamentID = useWizardPrivate(state => state.setActiveTournamentID)
  const [sportActive, setSportActive] = useState({})
  const [activeTournament, setActiveTournament] = useState({})

  useEffect(() => {
    // Emit changes to store
    if (sportActive.id) {
      setSurvivorData({ sport_id: sportActive.id })

      // When we change the sport it's reset the activeTournament
      setActiveTournament({})
    }
  }, [sportActive])

  useEffect(() => {
    // Emit changes to store
    if (activeTournament.id) {
      setSurvivorData({
        tournament_instances: { _ids: [activeTournament.id] }
      })
      setActiveTournamentID(activeTournament.id)
    }
  }, [activeTournament])

  if (step !== 'SURVIVOR_SPORT') return null

  const disabledNext = sportActive.id === undefined || activeTournament.id === undefined

  return (
    <WizardLayout
      prevStep='SURVIVOR_NAME'
      nextStep='SURVIVOR_DURATION'
      disabledNext={disabledNext}
    >
      <Box width={300} mx='auto'>
        <Typography center size='1.2em' mt={20}>Selecciona el deporte</Typography>

        {isLoading && (
          <div>
            <PulseLoader
              color='#78BC28'
              size={12}
            />
          </div>
        )}

        {(!isLoading && data) && (
          <Stack my={20} direction='row' spacing='1em' justify='center'>
            {data.sports?.map(sport => (
              <ButtonGlass
                height={65}
                size='0.8em'
                data={sport}
                key={sport.id}
                name={sport.name}
                image={(getSportImage(sport.id))}
                active={sport.name === sportActive.name}
                onChange={(active) => setSportActive(active)}
              />
            ))}
          </Stack>
        )}

        {sportActive?.tournament_instances && (
          <Box>
            <Typography center size='1.2em' mt={20}>Elige una Liga</Typography>
            <Stack my={20} direction='row' spacing='1em' justify='center'>
              {sportActive?.tournament_instances?.map(item => (
                <ButtonGlass
                  size='0.9em'
                  data={item}
                  height={45}
                  key={item.name}
                  name={item.name}
                  active={item.name === activeTournament.name}
                  onChange={(active) => setActiveTournament(active)}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>

    </WizardLayout>

  )
}
