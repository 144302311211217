import React, { createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

// Hooks
import useAuthContext from '../hooks/useAuthContext.js'
import useLocalStorage from '../hooks/useLocalStorage'

// Utils
import API from '../utils/api'
import utils from '../utils/utils'
import { subscribe, unsubscribe } from '../utils/events.js'
import useProgressBarLevel from '../hooks/useProgressBarLevel.js'
import { validateUserSessions } from '../utils/session.js'

const initialState = {}

const UserContext = createContext(initialState)

UserProvider.propTypes = {
  children: PropTypes.node
}

function UserProvider ({ children }) {
  const { isAuthenticated, isInitialized,  logout } = useAuthContext()
  const [userData, setUserData] = useLocalStorage('user-context', initialState)
  const [availableCashout, setAvailableCashout] = useLocalStorage('availableCashout-context', {})
  const [userBalance, setUserBalance] = useLocalStorage('setUserBalance-context', {})
  const [promotions, setPromotions] = useLocalStorage('promotions-context', {})
  const [userLoyalty, setUserLoyalty] = useLocalStorage('userLoyalty-context', {})

  useEffect(() => {
    // 🚩 For Legacy
    const userBalancelistener = (data) => {
      if (data.detail) {
        setUserBalance(data.detail)
      }
    }

    const userDataListener = (data) => {
      if (data.detail) {
        setUserData(data.detail)
      }
    }

    subscribe('setUserBalance-context', userBalancelistener)
    subscribe('setUserData-context', userDataListener)
    return () => {
      unsubscribe('setUserBalance-context', userBalancelistener)
      unsubscribe('setUserData-context', userDataListener)
    }
  }, [])

  // When user is authentificated runs this effect
  // to get or refresh data from server
  useEffect(() => {
    if (isAuthenticated && isInitialized) {
      // Make things here
      initializeUserData()
    }
  }, [isAuthenticated, isInitialized])

  const updateData = (data) => {
    utils.setUserInfo(data)
    // Everywhere the user is consumed with utils.getUserInfo()
    // Should be replaced by consumer context with useUserContext or HOC WithContext

    // Future: Try don't send nested user object to be consistent
    if (userData.hasOwnProperty('user')) {
      setUserData(data.user)
    } else {
      setUserData(data)
    }

    const balanceObj = {
      balancePc: data.balancePc,
      balancePcReal: data.balancePcReal,
      balanceReal: data.balanceReal,
      balanceReferral: data.balanceReferral,
      balanceFreeroll: !data.balanceFreeroll ? 0 : data.balanceFreeroll,
      balanceBonus: !data.balanceBonus ? 0 : data.balanceBonus,
      tickets: data.tickets || [],
    }

    setUserBalance(balanceObj)
    // 🚩 For legacy
    utils.setUserBalance(data)
    setPromotions(data.promotions)
    setUserLoyalty(utils.getUserLoyalty())

    // const info = {
    //   isNewTosConfirmed: data.user.tos,
    //   userData: data.user,
    //   promotions: data.promotions,
    //   userBalance: balanceObj,
    //   // availableCashout: responseAvailable.data,
    //   userDataChanged: true,
    //   userLoyalty: utils.getUserLoyalty()
    // }
  }

  const initializeUserData = async () => {
    try {
      const { data } = await API.fetchUserData()
      updateData(data)
      initializeAvailableCashout()

      const prevPath = window.location.href.includes('reset_password')
        ? '/'
        : localStorage.getItem('redirectAfterLogin')

      validateUserSessions().then(() => {
        if (prevPath && window.location.pathname !== '/close-sessions') {
          localStorage.removeItem('redirectAfterLogin')
          setTimeout(() => {
            window.location.assign(prevPath)
          }, 300)
        }
      })
    } catch (error) {
      if (error.message === 'Network Error') {
        logout()
      }
    }
  }

  const initializeAvailableCashout = async () => {
    try {
      const { data } = await API.fetchAvailableCashout()
      setAvailableCashout(data)
    } catch (error) {
      console.error('Error getting userData', error)
      Sentry.captureException(error)
    }
  }

  const updateUserAvailable = (available) => {
    if (!available) {
      console.log('Without Available ')
      API.fetchAvailableCashout()
        .then(({ data }) => {
          setAvailableCashout(data)
        })
        .catch((error) => {
          console.error('Error getting branch Link', error)
          Sentry.captureException(error)
        })
    } else {
      console.log('WithAvailable ')
      setAvailableCashout(available)
    }
  }

  const updateUserInfo = async (userData) => {
    if (!userData || userData === 'isNewRegister') {
      try {
        const { data } = await API.fetchUserData()
        userData = data
      } catch (error) {
        Sentry.captureException(error, {
          extra: 'Error fetching user data'
        })
      }
      return
    }

    if (typeof userData !== 'object') {
      return
    }

    // Update data local
    // updateData(userData)
    // FOR LEGACY
    // utils.setUserInfo(userData)
    updateData(userData)
  }

  // Maybe a better name is updateRealBalance
  const updateUserBalance = (newBalance) => {
    if (newBalance === undefined) {
      console.error('Balance is undefined')
      return
    }

    const balance = {
      ...userBalance,
      ...newBalance
    }

    if (newBalance.balanceReal !== undefined) {
      balance.balanceReal = newBalance.balanceReal
      balance.balanceBonus = newBalance.balanceBonus
    } else {
      balance.balanceReal = newBalance
    }

    // 🚩 For Legacy
    utils.setUserBalance(balance)
    setUserBalance(balance)
  }

  // If user is authenticated and user was initialized
  const isLogged = Boolean(
    isAuthenticated && (
      userData !== null && Object.keys(userData).length
    )
  )

  return (
    <UserContext.Provider
      value={{
        userData,
        availableCashout,
        userBalance,
        promotions,
        userLoyalty,
        isLogged,
        // Methods
        updateUserAvailable,
        updateUserBalance,
        updateUserInfo,
        setUserBalance,
        initializeUserData,
        // Progressbar and user level up
        ...useProgressBarLevel(userLoyalty, isLogged)
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { UserProvider, UserContext }

