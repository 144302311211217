import { withFormsy } from 'formsy-react';
import React from 'react';
import {
  FormGroup,
  Checkbox,
  ControlLabel,
  FormControl,
  HelpBlock,
} from 'react-bootstrap/lib';

class RetirarFormsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popOverState: false };
    this.changeValue = (event) => {
      this.props.setValue(event.currentTarget.value);
    };
    this.openPopOver = () => {
      this.setState({ popOverState: true });
    };
  }

  render() {
    let conditionalComponent = null;
    const errorMessage = this.props.getErrorMessage();
    let helpMessages =
      this.props.help !== undefined
        ? this.props.help.map((help, i) => <HelpBlock key={i}>{help}</HelpBlock>)
        : null;
    switch (this.props.type) {
      case 'TEXT':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              onBlur={() => {
                this.props.validateInput();
              }}
              value={this.props.getValue() || ''}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center ">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'USERNAME':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              onBlur={() => {
                this.props.validateInput('userName');
              }}
              value={this.props.getValue() || ''}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'LASTNAME':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              onBlur={() => {
                this.props.validateInput('lastName');
              }}
              value={this.props.getValue() || ''}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center ">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'PASSWORD':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="password"
              minLength={this.props.minLength}
              maxLength={this.props.maxLength}
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('password');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'AMOUNT':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              pattern="[0-9]*"
              inputMode="number"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('amount');
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'PHONE':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="number"
              inputMode="tel"
              pattern="[0-9]*"
              maxLength={32}
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('phoneNumber');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            <div className="help-block">
              Por favor escribe tu número con lada sin espacios ni guiones.
              <br />
              Te pedimos tu telefono para contactarte y apoyarte si lo necesitas.
            </div>
          </div>
        );
        break;
      case 'RETIRO':
        // console.log('Retiro');
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              componentClass="select"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('method');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            >
              <option value=""> Selecciona tú método de retiro </option>
              <option value="spei">Transferencia a tu cuenta bancaria</option>
              <option value="card">Abona a tu tarjeta de débito </option>
              {/* <option value='astropaycard'>Abona a tu tarjeta de astropay</option> */}
            </FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'BANKS':
        // console.log( this.props.banksList );

        const listBanks =
          !this.props.banksList || !this.props.banksList.length ? (
            <option value={null}> N/A </option>
          ) : (
            this.props.banksList.map((bank) => {
              return (
                <option key={bank.bank_code} value={bank.bank_code}>
                  {' '}
                  {bank.bank}{' '}
                </option>
              );
            })
          );
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              componentClass="select"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('bankCode');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            >
              <option value={null}> Selecciona tú banco </option>
              {listBanks}
            </FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'CARD_NUMBER':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              maxLength={16}
              inputMode="numeric"
              pattern="[0-9]*"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('cardNumber');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'SPEI_NUMBER':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              pattern="[0-9]*"
              inputMode="number"
              maxLength={18}
              autoComplete="cc-nummber"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('speiNumber');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'MAIL':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              type="text"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('email');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            ></FormControl>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'ID_TYPE':
        conditionalComponent = (
          <div className="flex-col">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              componentClass="select"
              value={this.props.getValue() || ''}
              onBlur={() => {
                this.props.validateInput('idType');
              }}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e);
              }}
            >
              <option value={null}> Selecciona el tipo de identificación</option>
              <option value={'ine'}> INE / IFE </option>
              <option value={'pasaporte'}> Pasaporte </option>
              <option value={'cartilla'}> Cartilla de identidad militar </option>
            </FormControl>
            <span className="  flex-row  row-no-padding error-formulario ">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'FILE':
        conditionalComponent = (
          <div className="flex-col col-center">
            <ControlLabel className=" flex-container justify align text-center">
              {this.props.label}
            </ControlLabel>
            <label id="uploadFileForm">
              <input
                className="inputFile"
                placeholder=""
                name={this.props.name}
                type="file"
                onBlur={() => {
                  this.props.validateInput(
                    this.props.label === 'Frente' ? 'formFileUploadFront' : 'formFileUploadBack'
                  );
                }}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              />
              {this.props.fileData !== undefined && this.props.fileData !== null ? (
                <i className="ion-checkmark-circled"></i>
              ) : (
                <i className="ion-images"></i>
              )}
              {this.props.fileData !== undefined && this.props.fileData !== null ? (
                this.props.fileData.name
              ) : (
                <span className="text-center">Selecciona una imagen</span>
              )}
            </label>
            <span className="  flex-row  row-no-padding error-formulario text-center">
              {errorMessage}
            </span>
            {helpMessages}
          </div>
        );
        break;
      case 'SAVE_INFO':
        conditionalComponent = (
          <FormGroup
            className="flex-row row-center row-no-padding point-format-wizard"
            controlId={this.props.name}
          >
            <ControlLabel className="">{this.props.label}</ControlLabel>
            <div className="">
              <Checkbox onChange={this.props.inputHandler} inline={true}>
                <div className={` checkbox-custom `} />
              </Checkbox>
            </div>
          </FormGroup>
        );
      break;
      default:
        break;
    }
    return conditionalComponent;
  }
}

export default withFormsy(RetirarFormsy);
