import React, { useState } from 'react'

// Components
import { useWizardPrivate } from '../wizardPrivate.store'
import Button from '../shared/Button'
import { Stack, Box, Divider, MultiOption } from '../../../Xmerald-UI'

export default function CreateType () {
  const step = useWizardPrivate(state => state.step)
  const setStep = useWizardPrivate(state => state.setStep)
  const [type, setType] = useState()

  if (step !== 'CREATE_TYPE') return null

  const next = () => {
    if (type === 'survivor') {
      setStep('SURVIVOR_NAME')
    }
    if (type === 'quiniela') {
      setStep('QUINIELA_NAME')
    }
  }
  
  const handlerBack = () => {
    setStep('WELCOME')
    setType(null)
  }

  return (
    <Box>
      <Box maxWidth={500} pb={5}>
        <MultiOption
          onChange={({ type }) => setType(type)}
          keyName='type'
          title='¿Que tipo de juego quieres crear?'
          // error='Debes seleccionar la duracion'
          value={type}
          options={[
            {
              name: 'Quiniela',
              value: 'quiniela'
            },
            {
              name: 'Survivor',
              value: 'survivor'
            }
          ]}
        />
      </Box>

      <Divider />
      <Stack direction='row' spacing='1em' justify='center' mt={20} mb={20}>
        <Button
          onClick={handlerBack}
          className='guardar-picks btn-rojo'
        >
          REGRESAR
        </Button>
        <Button
          disabled={!type}
          className='guardar-picks'
          onClick={next}
        >
          SIGUIENTE
        </Button>
      </Stack>
    </Box>
  )
}
