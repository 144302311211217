
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useWizardPrivate } from '../wizardPrivate.store'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'

// Components
import { Box, Stack, Typography, Grid, Iconify } from '../../../Xmerald-UI'

// Utils
import sleep from '../../../Xmerald-UI/utilities/sleep'
import API from '../../../utils/api'

const TOURNAMENTS = '/api/tournament_instances/next_weeks/'

export default function PoolFullTournament () {
  const activeTournamentID = useWizardPrivate(state => state.activeTournamentID)
  const { data, isLoading, isFetching } = useQuery(
    [TOURNAMENTS, activeTournamentID],
    async () => {
      await sleep()
      const { data } = await API.fetchTournamentInstancesWeek(activeTournamentID)
      return data
    }
  )

  return (
    <Box>
      <Typography center size='1.2em' mt={20} mb={10}>
        Jornadas {isFetching && <Iconify icon='eos-icons:three-dots-loading' color='#70f324' />}
      </Typography>
      {isLoading && (
        <Grid columns='repeat(3, 1fr)' gap='0.5em'>
          <Skeleton count={1} height={60} />
          <Skeleton count={1} height={60} />
          <Skeleton count={1} height={60} />
        </Grid>
      )}

      {!isLoading && data?.weeks && (
        <Stack columns='repeat(3, 1fr)' gap='0.5em' direction='row' wrap justify='center'>
          {data.weeks.map(week => (
            <Week key={week.id} week={week} />
          ))}
        </Stack>
      )}

    </Box>
  )
}

const Week = ({ week }) => {
  return (
    <FixtureBox>
      <Stack direction='column'>
        <Typography center size='1.3em' color='#8cc63f' mt={5}>{week.week_name}</Typography>
        <Typography center size='0.8em'>{moment(week.start_date).format('MM/DD/YY hh:mm')}</Typography>
      </Stack>
    </FixtureBox>
  )
}

const FixtureBox = styled.div`
  min-height: 60px;
  width: 30%;
  background-color: #111;
  border: 1px solid #3f3f3f;
  border-radius: 0.5em;
  padding: 0.3em;
`
