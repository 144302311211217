import React from 'react';
import { Portal, RootCloseWrapper } from 'react-overlays';
import reglas from '../../assets/images/reglasIconBlanco-50.png';
import $ from 'jquery';
import '../Score/header.scss';

class ReglasOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };

    this.show = () => this.setState({ show: !this.state.show });

    this.hide = () => this.setState({ show: false });
  }

  setPositionAndWidthModalRules = () => {
    const widthWindow = $(window).width();
    //let leftStyle = {};
    let widthStyle = {};
    if (widthWindow < 350) {
      widthStyle = { minWidth: '280px' };
    } else if (widthWindow < 660) {
      widthStyle = { minWidth: '350px' };
    } else if (widthWindow < 950) {
      widthStyle = { minWidth: '350px' };
    } else {
      widthStyle = { minWidth: '450px' };
    }

    return {
      position: 'absolute',
      zIndex: '99999999999999',
      top: '3rem',
      ...widthStyle,
    };
  };

  render() {
    let child = this.props.whichPopover;
    const styleContentRules = this.setPositionAndWidthModalRules();
    return (
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <RootCloseWrapper onRootClose={this.hide}>
          <div className='button-rules button-header-background1 button-header-radius' onClick={this.show}>
            <span>Reglas</span>
            <i className='green icon ion-arrow-down-b' style={{fontSize: '16px'}}></i>
            <Portal container={() => this.container}></Portal>
            <div
              className={`flex-container overflow column overflow-y overflow-600 ${
                this.props.gameInfo.status === 'upcoming' ? '' : 'centerAligned'
                } `}
              id="popover-custom-container"
              ref={(c) => {
                this.container = c;
              }}
            />
          </div>
        </RootCloseWrapper>
        {this.state.show && <div style={{ ...styleContentRules }}>{child}</div>}
      </div>
    );
  }
}

export default ReglasOverlay;
