import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

// Utils
import utils from '../../../utils/utils'

export default function ProductCard (props) {
  const { balanceVip = 0 } = utils.getUserBalance()
  const [animated, setAnimated] = useState(false)
  const { ref, inView } = useInView({ threshold: 0 })

  useEffect(() => {
    if (inView) {
      setAnimated(true)
    }
  }, [inView])

  const progress = balanceVip * 100  / props.product.price
  const price = numeral(props.product.price / 100).format('0,000')

  const activeStyles = {
    transitionDelay: getDelay(props.index) + 'ms'
  }

  if (animated) {
    activeStyles.opacity  = 1
    activeStyles.transform  = 'translateY(0) scale(1)'
  }

  return (
    <Card style={activeStyles} ref={ref}>
      <Image src={props.product.image_url} className='img-responsive'/>
      <Content>
        <Bar>
          <Progress style={{ width: progress + '%' }} />
        </Bar>
        <Title>{props.product.name}</Title>
        <Price>{price} pts</Price>
        <Button
          className='btn-pick mis-quinielas'
          onClick={() => props.setActiveProduct(props.product)}
        >
          CANJEAR
        </Button>
      </Content>
    </Card>
  )
}

const getDelay = (index) => {
  if (index % 2 === 0) return 60
  if (index % 3 === 0) return 120
  if (index % 4 === 0) return 180
  return 0
}

const Card = styled.div`
  width: 100%;
  border: 1px solid #ffffff59;
  display: flex;
  flex-direction: column;
  min-height: 31rem;
  background-color: rgb(255 255 255 / 16%);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(5px);
  border-radius: 0.25em;
  transform: translateY(50px) scale(0.5);
  opacity: 0;
  will-change: trasform, opacity;
  box-shadow: 0px -3px 5px -2px #98d7466b inset, 0px -8px 22px -5px rgb(255 255 255 / 29%) inset;
  box-shadow: 0px 4px 7px #00000063, 0px -3px 4px -3px #ffffff6b inset, 0px -8px 22px -5px rgb(255 255 255 / 29%) inset;
  transition: all 650ms ease;
  opacity: 0;
  transform: translateY(50px) scale(0.5);
`

const Image = styled.img`
  width: 100%;
  background-color: #cccccc;
`

const Content = styled.div`
  padding: 1em;
`

const Title = styled.div`
  text-align: center;
  font-family: 'Chivo';
  font-weight: 400;
  font-size: 0.9em;
  margin: 0.5em 0;
  text-shadow: 1px 1px 3px #00000099;
  height: 35px;
`

const Bar = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 0.5em;
  border: 1px solid #FFF;
  position: relative;
  overflow: hidden;
  transition: 1.3s ease;
  border: 1px solid #FFF;
  z-index: 2;
  background: #343333bf;
`

const Price = styled.span`
  font-size: 0.9em;
  margin-bottom: 1em;
  text-align: center;
  display: block;
`

const Progress = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #78BC28;
  transition: width 10ms linear;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
`
