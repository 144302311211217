// Libraries
import React from "react";
import moment from "moment";
import _ from 'underscore';

// Components
import Clock from '../../Clock/index';

// Nota
// No hay empates
// el id del equipo ganador esta en match.winning_pick
// el id del equipo que eligio el usuario esta en pick.pick
// un pick es correcto cuando pick.pick == match.winning_pick && match.winning_pick !== null
// si match.status != 'upcoming' esta en vivo o terminado y match.winning_pick = null quiere decir que estan empatados


const BracketPointsPick = ({ match, pick, onPickChange, bracket, side, teamList, tieBreakers, onSelectTie, bracketUserTieBreakers, bracketEntry }) => {
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  // var sumWons = 0;

  const SelectTeam = ({ teamList, tieId, enabled }) => {
    let allTeams = [];
    _.each(teamList, (team) => {
      allTeams.push(team);
    });

    let deadline = new Date(bracket.tiebreakers_deadline === undefined ? bracket.deadline : bracket.tiebreakers_deadline);
    let now = new Date();
    let tieEnabled = enabled;

    if (now <= deadline) {
      tieEnabled = true;
    } else {
      tieEnabled = false;
    }

    let teamBreaker;
    if (bracketUserTieBreakers) {
      teamBreaker = allTeams.find(team => team.id === bracketUserTieBreakers[tieId].value);
    }

    const optionStyles = {
      color: '#71bc39',
      backgroundColor: '#272727'
    }

    return (
      <>
        {tieEnabled && bracketUserTieBreakers &&
          <select
            className="bracket-game-select ties"
            value={bracketUserTieBreakers && (bracketUserTieBreakers[tieId].value === null ? '' : bracketUserTieBreakers[tieId].value)}
            onChange={(e) => {onSelectTie(e, tieId)} }
            disabled={!tieEnabled}
          >
            <option value="" style={optionStyles}></option>
            {allTeams && allTeams.map((team, i) => (
              <option
               value={Number(team.id)}
               style={optionStyles}
               key={i}
              >
                {team.name}
              </option>)
            )}
          </select>
        ||
          <label>{teamBreaker !== undefined ? teamBreaker.name : 'ND'}</label>
        }
      </>
    );
  }

  React.useEffect(() => {
    if (pick.pick && pick.pick === match.home_team_id) {
      setSelectedTeam(match.home_team);
    }
    if (pick.pick && pick.pick === match.away_team_id) {
      setSelectedTeam(match.away_team);
    }
    // sumWons = match.games_won_away + match.games_won_home;
  }, [pick.pick]);

  return (
    <>
      <li className="tournament-bracket__item">
        <div className="tournament-bracket__match">
          <div className={`match-content
            ${match.status !== 'upcoming' && match.winning_pick !== null && match.winning_pick === pick.pick && 'correct'}
            ${match.status !== 'upcoming' && match.winning_pick !== pick.pick && 'incorrect'}
            ${match.winning_pick === null && match.winning_game === 0 && selectedTeam !== null && 'selected'}
          `}>
            <div className="match-header font-chivo text-bold text-italic uppercase text-small">
              <div className="text-right">
                {match.status === 'upcoming' && (
                  <>
                    {match.time_left > 3600 && moment(match.deadline).format("ddd DD, MMM HH:mm")}
                    {match.time_left > 0 && (match.time_left) <= 3600 &&
                      <div>
                        <Clock type="row" poolId={bracket.id} fixtureID={0} closingDate={match.deadline} />
                      </div>
                    }
                  </>
                )}
                {/* en vivo azul */}
                {match.status === 'live' && (<span>En Vivo</span>)}
                {/* finalizo en rojo */}
                {match.status === 'finished' && (<span>Finalizo</span>)}
              </div>
            </div>

            <div className={`fixture-picks flex-row row-center
              ${match.winning_pick != null && match.winning_pick === pick.pick && 'correct'}
              ${match.winning_pick != null && match.winning_pick !== pick.pick && 'incorrect'}
            `}>
              <div className="flex-col">

                {/* <!-- home --> */}
                {(match.home_team && (
                  <div className="flex-row row-center">
                    <div className="flex-col pick-container">
                      <label
                        htmlFor={`bracket-pick-home-${match.id}`}
                        className={`pick ${ pick.pick === match.home_team_id ? 'checked' : null} `}
                      >
                        <div className="flex-col col-20">
                          <i className={`quina ${match.home_team.logo}`}></i>
                        </div>
                        <div className="flex-col col-ellipsis">
                          <div className="text-bold ff-chivo text-italic text-left">
                            {match.home_team.name}
                          </div>
                        </div>
                        <div className="flex-col col-10 text-bold text-large text-italic ff-chivo">
                          {match.games_won_home}
                        </div>
                        <div className="flex-col col-15">
                            <input
                              id={`bracket-pick-home-${match.id}`}
                              disabled={!match.enabled}
                              name={`bracket-pick-${match.id}`}
                              type="radio"
                              value={match.home_team_id}
                              checked={Number(pick.pick) === match.home_team_id}
                              onChange={() => {}}
                              onClick={() =>
                                onPickChange(match.id, match.home_team_id, 'home')
                              }
                            />
                            <a className="pick-helper"></a>
                        </div>
                      </label>
                    </div>
                  </div>
                )) || (
                  <div className="team-placeholder flex-row row-center">
                    <div className="flex-container align pick-container">
                      <label className="ml-05 text-bold ff-chivo text-italic">PD</label>
                    </div>
                  </div>
                )}

                <div className="h-divider"></div>

                {/* <!-- away --> */}
                {(match.away_team && (
                  <div className="flex-row row-center">
                    <div className="flex-col pick-container">
                      <label
                        htmlFor={`bracket-pick-away-${match.id}`}
                        className={`pick ${ pick.pick === match.away_team_id ? 'checked' : null} `}
                      >
                        <div className="flex-col col-20">
                          <i className={`quina ${match.away_team.logo}`}></i>
                        </div>
                        <div className="flex-col">
                          <div className="text-bold ff-chivo text-italic text-left">
                            {match.away_team.name}
                          </div>
                        </div>
                        <div className="flex-col col-10 text-bold text-large text-italic ff-chivo">
                          {match.games_won_away}
                        </div>
                        <div className="flex-col col-15">
                          <input
                            id={`bracket-pick-away-${match.id}`}
                            disabled={!match.enabled}
                            name={`bracket-pick-${match.id}`}
                            type="radio"
                            value={match.away_team_id}
                            checked={Number(pick.pick) === match.away_team_id}
                            onChange={() => {}}
                            onClick={() =>
                              onPickChange(match.id, match.away_team_id, 'away')
                            }
                          />
                          <a className="pick-helper"></a>
                        </div>
                      </label>
                    </div>
                  </div>
                )) || (
                  <div className="team-placeholder flex-row row-center">
                    <div className="flex-container align pick-container">
                      <label className="ml-05 text-bold ff-chivo text-italic">PD</label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {side === 'center' &&
            <div className="match-content tie-breakers" style={{top: 'calc(100% - 9em)', width: '100%', marginLeft: '-1em'}}>
              <div className="match-header font-chivo text-bold text-italic uppercase text-small">
                Desempates
                <span className="pull-right">
                {moment(bracket.deadline).format("ddd DD, MMM HH:mm")}
              </span>
              </div>
              <div className="fixture-picks flex-row row-center">
                <div className="flex-col">
                  {tieBreakers.map((tie, i) => {
                    return (
                      <div className="flex-row row-center m-05" key={tie.id}>
                        <div className="flex-col font-chivo text-bold text-italic">
                          <div className="tie-item">
                            <div className="col-50">#{i+1} - {tie.label}</div>
                            <div className="col-50 text-right flex-container justify">
                              <SelectTeam teamList={ teamList } tieId={tie.id} enabled={tie.enabled} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
        </div>
      </li>
    </>
  );
};

export default BracketPointsPick;
