import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import numeral from 'numeral'

// Components
import LinkStyled from './Link'
import Text from '../../Text'

export default function LastMonthStats (props) {
  const { lastReward, lastLevel } = props.userLoyalty

  if (!lastReward) return null

  return (
    <Panel>
      <Text
        bold
        size={20}
        mb={20}
        style={{ textTransform: 'uppercase' }}
      >
        RESULTADOS MES ANTERIOR
      </Text>

      <Box>
        <div>
          <Text>Nivel alcanzado:</Text>
          <Text>Puntos acumulados:</Text>
          <Text>Calificables:</Text>
          <Text>No Calificables:</Text>
          <Text>Monto rembolsado:</Text>
        </div>

        <div>
          <Text>{lastLevel.name}</Text>
          <Text>{numeral(lastReward.points).format('0,0')}</Text>
          <Text>{numeral(lastReward.redeemable).format('0,0')}</Text>
          <Text>{numeral(lastReward.unredeemable).format('0,0')}</Text>
          <Text>${numeral(lastReward.amount / 100).format('0,0.00')}</Text>
        </div>
      </Box>
      <Link to='/usuario/historial_recompensas'>
        <LinkStyled>Historico</LinkStyled>
      </Link>
    </Panel>
  )
}

const Panel = styled.div`
  padding: 1em;
  margin: 1em;

  div:nth-child(1) {
    margin-right: 2em;
  }
`

const Box = styled.div`
  display: flex;
  line-height: 1.5;
`
