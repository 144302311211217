import { useEffect, useRef, useState } from 'react'
import _ from 'underscore'
import useSound from 'use-sound'

// Utils
import API from '../utils/api'

// Assets
import sound from '../assets/sounds/level-progress.wav'

// Wait to first user interaction to fetch  animation levelup or progress
// We need wait to avoid DOMException: play()
// failed because the user didn't interact with the document first.
const useFirstBodyClick = () => {
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    setIsClicked(false)
    window.focus()

    const handleFocus = () => {
      // Only for iframe pages like casino | slots | book
      const iframeEl = document.querySelector('iframe')

      if (iframeEl !== null){
        setIsClicked(true) 
      }
    }
    
    document.body.onclick = () => {
      setIsClicked(true)
    }

    window.addEventListener('blur', handleFocus)

    return () => {
      window.removeEventListener('blur', handleFocus)
    }
  }, [])

  return isClicked
}


const getProgress = (userLoyalty) => {
  let progress = 0

  if (!!userLoyalty && userLoyalty.points) {
    progress = getLevelProgress(userLoyalty)
  }

  return progress
}

const getLevelProgress = (userLoyalty) => {
  const { level, points } = userLoyalty
  return ((points.total - level.min_points) / (level.max_points - level.min_points)) * 100
}

// If user update in progress only is animated in Navbar
// But if user is levelup then is open modal progress and play the video
// ------------------------------------------------------------------------------------

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export default function useProgressBarLevel (userLoyalty, isLogged) {
  const tick = useRef()
  const isUserInteractive = useFirstBodyClick()

  const [currentLevelName, setCurrentLevelName] = useState('')
  const [currentAnimation, setCurrentAnimation] = useState(null)
  const [animationStack, setAnimationStack] = useState([])
  const [showRipples, setShowRipples] = useState(false)
  const progress = getProgress(userLoyalty)
  const [width, setWidth] = useState(progress)
  const [muted, setMuted] = useState(isSafari)
  const [playSound] = useSound(sound, { soundEnabled: !muted })

  useEffect(() => {
    if (userLoyalty && userLoyalty.lastLevel) {
      setWidth(getProgress(userLoyalty))
      setCurrentLevelName(userLoyalty.lastLevel.name)
    }
  }, [userLoyalty])

  useEffect(() => {
    if (isUserInteractive && isLogged) {
      API.getLevelAnimations().then(({ data: { animations } }) => {
        if (animations && animations.length > 0) {
          createAnimationStack(animations)
        }
      })
  
      function createAnimationStack (animations) {
        const levelAnimations = _.groupBy(animations, 'level_id')
  
        // Agrouping animation stack
        const stack = []
  
        _.each(levelAnimations, function (_levelAnimations, level_id) {
          const animationIds = _.pluck(_levelAnimations, 'id')
  
          // Get min progress and max progress from _levelAnimations.data.progress
          const minProgress = _.min(_levelAnimations, function (animation) {
            return animation.data.progress
          })
  
          const maxProgress = _.max(_levelAnimations, function (animation) {
            return animation.data.progress
          })
  
          const last = _.last(_levelAnimations)
  
          let data = {
            type: last.type,
            minProgress,
            maxProgress,
            animationIds, 
            level_id
          }
  
          if (last.type === 'levelup') {
            data = {
              ...data,
              nextLevel: last.data.level_name,
              last
            }
          }
  
          stack.push(data)
        })
  
        setAnimationStack(stack)
      }
    }
    
  }, [isUserInteractive, isLogged])

  useEffect(() => {
    if (animationStack.length > 0) {
      playAnimations()
    }

    function playAnimations () {
      const currentAnimation = animationStack[0]

      if (currentAnimation.type === 'levelup') {
        setCurrentAnimation(currentAnimation)
      } else {
        animaTransition(currentAnimation)
      }
    }
  }, [animationStack])

  const onNextAnimation = async () => {
    setAnimationStack(animationStack.slice(1))
    setCurrentAnimation(null)
    pushWatchedAnimations([ ...currentAnimation.animationIds ])
  }

  const animaTransition = (currentAnimation) => {
    const fps = 60
    const duration = 2.5
    const realFPS = 1000 / fps
    const stepsCount = duration * 1000 / realFPS

    setShowRipples(true)

    window.setTimeout(() => playSound(), 1500)
    window.setTimeout(() => setShowRipples(false), 6000)

    const end = currentAnimation.maxProgress.data.progress
    const start = currentAnimation.minProgress.data.progress

    const step = (end - start) / stepsCount
    // Reseting count
    setWidth(start)

    window.setTimeout(() => {
      // Animate progress transition
      tick.current = window.setInterval(() => {
        setWidth(prev => {
          const next = prev + step

          if (next >= end) {
            window.clearInterval(tick.current)
            tick.current = null
            pushWatchedAnimations(currentAnimation.animationIds)
          }

          return next <= end ? next : end
        })
      }, realFPS)
    }, 2000)
  }

  const pushWatchedAnimations = (ids) => {
    if (!!ids && ids.length) {
      API
        .markAnimationsWatched({ ids })
        .catch((error) => {
          Sentry.captureException(error, { extra: 'Error to push watched animations' })
        })
    }
  }

  return {
    dynamicProgress: width,
    showRipples,
    animationStack,
    onNextAnimation,
    currentAnimation,
    setAnimationStack,
    currentLevelName,
    setCurrentAnimation,
    setCurrentLevelName,
    muted,
    setMuted,
    isSafari
  }
}
