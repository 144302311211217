import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Components
import { Iconify } from '../../../Xmerald-UI'

// Store
import { useWizardPrivate } from '../wizardPrivate.store'

Steps.defaultProps = {
  numberOfSteps: 5,
  currentStep: 1
}

export default function Steps ({ numberOfSteps }) {
  const step = useWizardPrivate(state => state.step)
  const startAt = step.includes('SURVIVOR') ? 5 : 0
  const currentStepNumber = useWizardPrivate(state => state.currentStepNumber) - startAt

  const StepList = Array(numberOfSteps).fill(0).map((_, index, list) => {
    const stepNumber = index + 1
    const isNotLast = stepNumber < list.length
    const isCurrent = currentStepNumber === stepNumber
    const isCompleted = currentStepNumber > stepNumber

    return (
      <Step
        key={index}
        isNotLast={isNotLast}
        isCurrent={isCurrent}
        isCompleted={isCompleted}
        stepNumber={stepNumber}
      />
    )
  })

  return (
    <Container>
      {StepList}
    </Container>
  )
}

const Step = ({ isNotLast, isCurrent, isCompleted, stepNumber }) => {
  const [current, setCuttent] = useState(false)

  useEffect(() => {
    if (isCurrent) {
      window.setTimeout(() => {
        setCuttent(true)
      }, 300)
    }
  }, [isCurrent])

  const stylesProgress = {
    width: current ? '50%' : '0%',
    transitionDuration: current ? '1500ms' : '0ms',
    boxShadow: current ? 'rgb(120, 188, 40) 0px 0px 8px 2px' : 'rgb(120, 188, 40) 0px 0px 0 0'
  }

  if (isCompleted) {
    stylesProgress.width = '100%'
  }

  const ballStyles = {
    transform: current ? 'scale(1.2)' : 'scale(1)',
    color: current ? '#FFF' : '#000',
    textShadow: current ? '0 2px 1px #0000004f' : '0 0 0 #00000000'
  }

  return (
    <StepBox>
      <StepBall style={ballStyles}>
        {!isCompleted && stepNumber}
        {isCompleted && <Iconify icon='mdi:success-bold' />}
      </StepBall>
      {isNotLast && (
        <StepProgress>
          <Progress style={stylesProgress} />
        </StepProgress>
      )}
    </StepBox>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  width: 350px;
  margin: 0 auto;
  margin-bottom: 1em;
`

const StepBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  &:last-child {
    width: fit-content;
  }
`

const StepBall = styled.div`
  width: 1.7em;
  height: 1.7em;
  border-radius: 1.7em;
  flex-shrink: 0;
  line-height: 1;
  background-color: #78BC28;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: all 350ms linear;
`

const StepProgress = styled.div`
  width: 100%;
  height: 0.2em;
  background-color: #4d4d4d;
  position: relative;
`

const Progress = styled.div`
  position: absolute;
  left: 0;
  width: 0%;
  height: 100%;
  transition: width 500ms ease-out, box-shadow 350ms;
  background-color: #78BC28;
  border-radius: 0 2px 2px 0;
`
