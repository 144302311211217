import React from 'react';

const QuinielaDataContext = React.createContext();

export class QuinielaDataProvider extends React.Component {
  render() {
    return (
      <QuinielaDataContext.Provider value={this.props}>
        {this.props.children}
      </QuinielaDataContext.Provider>
    );
  }
}

export const QuinielaDataConsumer = QuinielaDataContext.Consumer;
