// Libraries
import React from 'react';
import axios from 'axios';
import API from '../../utils/api';
import utils from '../../utils/utils';
import { Button } from 'react-bootstrap/lib';
import { Redirect, Link } from 'react-router-dom';
import _, { all, matches } from 'underscore';
import { Modal } from 'react-bootstrap/lib';
import { firstBy } from 'thenby';
import moment from 'moment/moment';

// Components
import Loading from '../Loading/Loading';
import BracketPointsPick from './components/BracketPointsPick';
import RegisterPanel from '../RegisterPanel';

// Images
import picks from '../../assets/images/quinielasIconBlanco-50.png';
import guardar from '../../assets/images/guardarPicks-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';

// styles
import './bracketPoints.scss';

const BracketPoints = (props) => {
    const [state, setState] = React.useState({
      redirectLobby: false,
      bracket: props.bracket,
      userData: props.userData,
      enoughBalance: false,
      standings: null,
      isLogged: props.isLogged,
      groups: null,
      groupsLeft: null,
      groupsRight: null,
      stages: null,
      matches: null,
      isLoaded: false,
      bracketSides: null,
      errorCopiar: null,
      bracketEntry: null,
      picks: {},
      entryId: null,
      showRegisterPanel: false,
      popUpNewUsersStatus: false,
      showPanelFirstRegister: false,
      showPanelReRegister: false,
      showSavedConfirmation: false,
      submitting: false,
      showPopUpReglas: false,
      tieBreakers: props.bracket.bracket_tiebreakers,
      bracketUserTieBreakers: null,
      teams: null,
    });
  const [stagesCount, setStagesCount] = React.useState(0);
  const [teamList, setTeamList] = React.useState(null);
  const [queryParams, setQueryParams] = React.useState({
    page: 1,
    limit: 50,
    nick: '',
    sort: [],
    order: null,
  });

  function onPickChanged(matchId, pick, which) {
    let match = _.find(state.matches, { id: matchId });
    let picks = state.picks;
    picks[match.number].pick = pick;
    picks[match.number].team = which;

    setState({...state, picks:picks});

    changeChildren(match, pick, which);
    if (match.set_not_selected) {
      changeChildLooser(match, picks);
    }
  }

  const onTeamSelected = ( side, matchNumber, group, place, teamId ) => {
    let allPicks = state.picks;
    let allMatches = state.matches;

    allPicks[matchNumber].pick = teamId;

    let groupSides = state.groupSides;
    let groupIdx   = groupSides[side].findIndex((_group) => _group.id === group.id);
    let theGroup   = groupSides[side][groupIdx];

    let matchIndex  = allMatches.findIndex((_match) => _match.number === matchNumber);

    let keyFirst  = `1${theGroup.short_name}`; //1A 1B 1C etc...
    let keySecond = `2${theGroup.short_name}`; //2A 2B 2C etc...

    let firstId  = allPicks[ keyFirst ].pick;
    let secondId = allPicks[ keySecond ].pick;

    // indicar que ya seleccionamos un equipo del grupo
    theGroup.teams.forEach((_team) => {
      _team.selected = false;

      if (_team.id === Number(firstId)) {
        allMatches[matchIndex].home_team_id = firstId;
        allMatches[matchIndex].home_team = _team;
        _team.selected = 1;
      }
      if (_team.id === Number(secondId)) {
        allMatches[matchIndex].away_team_id = secondId;
        allMatches[matchIndex].away_team = _team;
        _team.selected = 2;
      }

    });
    groupSides[side][groupIdx] = theGroup;

    setState({...state, picks: allPicks, groupSides: groupSides, matches: allMatches});

    if ( place === 'first' ) {
      changeChildren(allMatches[matchIndex], firstId, 'home');
    }
    if ( place === 'second' ) {
      changeChildren(allMatches[matchIndex], secondId, 'away');
    }

    // si set_not_selected === true, quiere decir que cuando cambie el pick
    // debo de poner el que no elegí en el que tenga como padre a match.number
    if ( allMatches[matchIndex].set_not_selected ) {
      changeChildLooser(allMatches[matchIndex]);
    }

  };

  const changeChildren = (parent, pick, which) => {
    let picks = state.picks;
    let matches = state.matches;

    var current = _.find(matches, function (_child) {
      return (
        _child.home_parent === parent.number ||
        _child.away_parent === parent.number
      );
    });
    if (!current) {
      return;
    }
    // if no pick has been made stop
    if (!picks[parent.number].pick) {
      return;
    }

    var team = null;
    if (parent.home_team && parent.home_team.id === Number(pick)) {
      team = parent.home_team;
    }
    if (parent.away_team && parent.away_team.id === Number(pick)) {
      team = parent.away_team;
    }

    if (current.home_parent === parent.number) {
      current.home_team_id = team.id;
      current.home_team = team;
    }
    if (current.away_parent === parent.number) {
      current.away_team_id = team.id;
      current.away_team = team;
    }

    if (!picks[current.number].pick) {
      changeChildren(current, pick, which);
    } else {
      var currentPick = picks[current.number];
      if (currentPick.team === "home" && parent.number === current.home_parent) {
        picks[current.number].pick = pick;
        picks[current.number].team = "home";
        setState({...state, picks: picks});
        changeChildren(current, pick, "home");
      }
      if (currentPick.team === "away" && parent.number === current.away_parent) {
        picks[current.number].pick = pick;
        picks[current.number].team = "away";
        setState({...state, picks: picks});
        changeChildren(current, pick, "away");
      }
    }
    if( current.set_not_selected ) {
      changeChildLooser(current);
    }
  };

  const changeChildLooser = (parent, allPicks = {}, allMatches = {}) => {
    let picks = state.picks.length === undefined ? allPicks : state.picks;
    let matches = state.matches === null ? allMatches : state.matches;

    // check the pick
    var parentPick = picks[parent.number];

    if ( !parentPick || !parentPick.pick ) {  return; }

    var child = _.find(matches, function (c) {
      return c.show_not_selected === true && (c.home_parent === parent.number || c.away_parent === parent.number);
    });

    if ( !child ) { return; }

    var team = null;

    if ( parentPick.pick === parent.home_team_id ) {
      team = parent.away_team;
    } else if ( parentPick.pick === parent.away_team_id ) {
      team = parent.home_team;
    }
    if ( child.home_parent === parent.number ) {
      child.home_team_id = team != null ? team.id : null;
      child.home_team = team;
    }
    if ( child.away_parent === parent.number ) {
      child.away_team_id = team != null ? team.id : null;
      child.away_team = team;
    }
    if( picks[child.number] && picks[child.number].pick && picks[child.number].team === 'home' ) {
      picks[child.number].pick = child.home_team_id;
    }
    if( picks[child.number] && picks[child.number].pick && picks[child.number].team === 'away' ) {
      picks[child.number].pick = child.away_team_id;
    }

    setState({...state, picks: picks});
  };

  const NotLoggedUserBar = ({ redirectLogin, redirectRegistro }) => {
    return (
      <div className="buttons-row">
        <Button onClick={redirectLogin} data-id="login" className="btn-lobby-login" bsSize="small">
          <i className="ion-log-in" /> <span data-id="login">INGRESAR</span>
        </Button>
        <Button
          onClick={redirectRegistro}
          data-id="registro"
          className="btn-lobby-login"
          bsSize="small"
        >
          <span data-id="registro">CREAR CUENTA</span>
        </Button>
      </div>
    );
  };

  const RegisterSteps = (props) => {
    let conditionalComponent = (
      <div id="registerSteps" className="row">
        <div className="col-xs-12 text-center register-title-container">
          <span className="register-title">
            <i className="ion-flag" />
            Sigue estos pasos para participar en el bracket.
          </span>
        </div>
        {props.isBalanceEnough ? (
          <div className="col-xs-12 steps-container">
            <div className="col-xs-6 col-md-6 register-step-item">
              <span className="step-number">1</span>
              <img src={picks} className="step-icon" />
              <span className="step-label">Llena tus picks</span>
            </div>
            <div className="col-xs-6 col-md-6 register-step-item">
              <span className="step-number">2</span>
              <img src={guardar} className="step-icon" />
              <span className="step-label">Guarda tu bracket</span>
            </div>
          </div>
        ) : (
          <div className="col-xs-12 steps-container">
            <div className="col-xs-4  register-step-item">
              <span className="step-number">1</span>
              <img src={picks} className="step-icon" />
              <span className="step-label">Llena tus picks</span>
            </div>
            <div className="col-xs-4  register-step-item">
              <span className="step-number">2</span>
              <img src={abonar} className="step-icon" />
              <span className="step-label">Abona saldo</span>
            </div>
            <div className="col-xs-4  register-step-item">
              <span className="step-number">3</span>
              <img src={guardar} className="step-icon" />
              <span className="step-label">Guarda tu bracket</span>
            </div>
          </div>
        )}
      </div>
    );
    return conditionalComponent;
  };

  const SavedPicksOverlay = ({ showOverlay, label, currentSavedPicks, allPicks }) => {
    let picksStatus = null;
    let iconStatus = null;

    if (currentSavedPicks === 0) {
      picksStatus = `No tienes ningún picks guardado. Recuerda ingresar y guardar todos tus picks. `;
    } else {
      picksStatus = (
        <div className="flex-container column align justify">
          <div className="flex-container justify">
            {' '}
            <strong className="title">¡Picks Guardados!</strong>
          </div>
          <div className="flex-container justify">
            {' '}
            Tienes {currentSavedPicks}/{allPicks} picks guardados.{' '}
          </div>
          <div className="flex-container justify">Recuerda ingresar y guardar todos tus picks. </div>
        </div>
      );
    }

    /**
     * Incomplete Picks
     **/
    if (currentSavedPicks !== allPicks) {
      iconStatus = <i className="ion-alert-circled" />;
    } else {
      /**
       * Complete Picks
       **/
      picksStatus = `¡Felicidades tus picks están completos!`;
      iconStatus = <i className="ion-checkmark-circled" />;
    }
    return (
      <div className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}`}>
        <span>
          {' '}
          {iconStatus} {picksStatus}{' '}
        </span>
      </div>
    );
  };

  const createGroups = (matches, stages) => {
    let bracketSides = _.chain(matches)
      .groupBy("side")
      .value();

    _.each(bracketSides, (matches, side) => {

      var byStage = _.groupBy(matches, "stage_id");
      bracketSides[side] = byStage;

    });
    setStagesCount(stages.length);
    return bracketSides;
  }

  const handleRegisterPanel = () => {
    setState({
      ...state,
      showRegisterPanel: !state.showRegisterPanel,
    });
  }

  const handleConfirmation = (newEntryID) => {
    window.location.assign(
      `/bracketPoints/${state.bracket.slug}/${state.bracket.id}/${newEntryID}`
    );
  };

  const handlePanelFirstRegister = () => {
    setState({
      ...state,
      showPanelFirstRegister: !state.showPanelFirstRegister,
    });
  }

  const handlePanelReRegister = () => {
    setState({
      ...state,
      showPanelReRegister: !state.showPanelReRegister,
    });
  }

  const returnErrors = (value) => {
    console.log("ERROR:", value);
  }

  const guardarBracket = (bracketId, picks, entryId, bracketUserTieBreakers) => {
    setState({
      ...state,
      submitting: true,
    });

    let temporalRegisterPicks = {
      bracket_entry_id: entryId,
      bracket_id: bracketId,
      bracket_picks: picks,
      bracket_user_tiebreakers: bracketUserTieBreakers,
    };

    API.postSaveBracketPicks(temporalRegisterPicks).then((response) => {
      callbackBracketSavedPicks(response);
    });
  }

  const handleSavedConfirmation = () => {
    setState({
      ...state,
      showSavedConfirmation: true,
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          showSavedConfirmation: false,
        }
      });
    }, 2500);
  }

  const callbackBracketSavedPicks = (response) => {
    if (response.data) {
      const newEntry = response.data.bracketEntry.id;
      console.log('NewEntryBracket', newEntry);

      if (props.refreshAfterGuardar !== null) {
        props.refreshAfterGuardar(state.entryId, 'save');
        setTimeout(() => {
          handleSavedConfirmation();
          setState((prevState) => {
            return {
              ...prevState,
              submitting: false,
            }
          });
        }, 500);
      }
    }
  };

  const handlePopUpReglas = () => {
    setState({...state, showPopUpReglas: !state.showPopUpReglas});
  }

  const onSelectTie = (e, tieId) => {
    let usertieBreakers = state.bracketUserTieBreakers;
    usertieBreakers[tieId].value = Number(e.target.value);
    setState({...state, bracketUserTieBreakers: usertieBreakers});
  }

  const initPicks = (matches, bracketId, bracketEntryId, picks) => {
    if ( _.isEmpty(picks) ) {
      picks = {};
    }

    _.each(matches, (_match) => {
      //if (_match !== undefined)
      if ( !picks.hasOwnProperty(_match.number) ) {
        picks[ _match.number ] = {
          id:               null,
          bracket_id:       bracketId,
          bracket_entry_id: bracketEntryId !== null ? Number(bracketEntryId) : null,
          pick:             null,
          match_id:         Number(_match.id),
          winning_game:    null,
        };
      }
    });
    return picks;
  };

  React.useEffect(() => {
    if (state.bracket === null) {
      return;
    }

    if (props.matches && props.stages) {
      let balanceEnough = utils.isBalanceEnough(
        state.bracket.entry_currency,
        state.bracket.entry_fee
      );

      const picks = initPicks(
        props.matches,
        props.bracket.id,
        props.entryId,
        props.selectedEntryData ? props.selectedEntryData.bracket_picks : {},
      );

      //let allTeams = _.chain( props.groups ).pluck('teams').flatten().indexBy('id').value() ;
      let allMatches = props.matches;

      let allTeams = {};
      props.matches.map((match) => {
        if (match.is_seed && match.home_team_id !== null && match.away_team_id !== null) {
          allTeams[match.home_team_id] = match.home_team;
          allTeams[match.away_team_id] = match.away_team;
        }
      });
      allTeams = _.sortBy(allTeams, 'name');

      allMatches = allMatches.map((match) => {
        if (!match.is_seed) {
          match.home_team_id = null;
          match.home_team    = null;
          match.away_team_id = null;
          match.away_team    = null;
        }
        return match;
      });

      // revisar picks empezando por los grupos
      // check picks from groups

      let groups = props.groups;

      //revisar picks y matches hacia adelante
      _.each( allMatches, function(parent) {

        let pick = picks[ parent.number ];

        if ( pick === undefined ) { return;}
        if ( !pick.pick || pick.pick === null ) { return; }

        let childIndex = allMatches.findIndex(c => (c.home_parent === parent.number || c.away_parent === parent.number));
        if ( childIndex === -1 ) { return; }

        let child = allMatches[childIndex];
        if ( child.home_team_id === null ) {

          // set childHomeTeam
          if ( child.home_parent === parent.number && pick.pick === parent.home_team_id) {
            allMatches[childIndex].home_team_id = parent.home_team_id;
            allMatches[childIndex].home_team    = parent.home_team;
          }
          if ( child.home_parent === parent.number && pick.pick === parent.away_team_id) {
            allMatches[childIndex].home_team_id = parent.away_team_id;
            allMatches[childIndex].home_team    = parent.away_team;
          }

        }

        if ( child.away_team_id === null ) {
          if ( child.away_parent === parent.number && pick.pick === parent.home_team_id) {
            allMatches[childIndex].away_team_id = parent.home_team_id;
            allMatches[childIndex].away_team    = parent.home_team;
          }
          if ( child.away_parent === parent.number && pick.pick === parent.away_team_id) {
            allMatches[childIndex].away_team_id = parent.away_team_id;
            allMatches[childIndex].away_team    = parent.away_team;
          }
        }

        if ( picks[child.number].pick === child.home_team_id ) {
          picks[child.number].team = 'home';
        }
        if ( picks[child.number].pick === child.away_team_id ) {
          picks[child.number].team = 'away';
        }
      });


      _.chain(allMatches).filter({'set_not_selected': true}).each((match) => {
        changeChildLooser(match, picks, allMatches);
      });

      _.chain(allMatches).filter({'show_not_selected': true}).each((match) => {
        if ( picks[match.number].pick === match.home_team_id ) {
          picks[match.number].team = 'home';
        }
        if ( picks[match.number].pick === match.away_team_id ) {
          picks[match.number].team = 'away';
        }
      });

      let bracketSides = createGroups(allMatches, props.stages);

      let groupSides = _.groupBy(groups, 'side');

      setState({
        ...state,
        groups: groups,
        groupSides: groupSides,
        bracket: props.bracket,
        stages: props.stages,
        matches: allMatches,
        standings: props.standings,
        bracketSides,
        isLoaded: true,
        enoughBalance: balanceEnough,
        entryId: props.entryId,
        bracketEntry: props.selectedEntryData,
        picks: picks,
        userData: props.userData,
        teams: allTeams,
      });
    } else {
      axios.all([
        API.fetchBracketStandings(state.bracket.id, queryParams),
        utils.isLogged() ? API.fetchTickets(state.bracket.id, 'bracket') : '',
        API.fetchBracketsDetails(state.bracket.id),
      ]).then(axios.spread((standingsResponse, ticketsResponse, bracketDetailResponse) => {
        const standings = standingsResponse.data;
        const tickets = ticketsResponse.data;
        const bracketDetail = bracketDetailResponse.data

        if (state.bracket.status !== 'upcoming') {
          setState({ ...state, redirectLobby: true });
        }

        let balanceEnough = utils.isBalanceEnough(
          state.bracket.entry_currency,
          state.bracket.entry_fee
        );

        if (!state.userData) {
          setState({
            ...state,
            enoughBalance: false,
            userData: props.userData,
            standings: standings,
            isLogged: props.isLogged,
            standings,
            tieBreakers: bracketDetail.bracket.bracket_tiebreakers,
          });
        } else {
          setState({
            ...state,
            enoughBalance: balanceEnough && tickets.length ? false : true,
            userData: props.userData,
            standings: standings,
            isLogged: props.isLogged,
            entryId: props.entryId,
            bracketEntry: props.selectedEntryData,
            tieBreakers: bracketDetail.bracket.bracket_tiebreakers,
          });
        }

        axios.all([
          API.fetchBracketGroups(state.bracket.tournament_instances[0].id),
          API.fetchBracketStagesByBracketId(state.bracket.id),
        ])
        .then(axios.spread((groupsResponse, stageResponse) => {
          let matches =  stageResponse.data.matches;
          let stages = stageResponse.data.stages;
          let groups = groupsResponse.data.groups;

          let groupSides = _.groupBy(groups, 'side');

          let bracketSides = createGroups(matches, stages);

          let allMatches = matches.map((match) => {
            if (!match.is_seed) {
              match.home_team_id = null;
              match.home_team    = null;
              match.away_team_id = null;
              match.away_team    = null;
            }
            return match;
          });

          const picks = initPicks(
            allMatches,
            state.bracket.id,
            state.entryId,
            {},
          );

          //let allTeams = _.chain( groups ).pluck('teams').flatten().indexBy('id').value() ;
          let allTeams = {};
          matches.map((match) => {
            if (match.is_seed && match.home_team_id !== null && match.away_team_id !== null) {
              allTeams[match.home_team_id] = match.home_team;
              allTeams[match.away_team_id] = match.away_team;
            }
          });

          setState({
            ...state,
            groups,
            stages,
            matches: allMatches,
            bracketSides,
            groupSides: groupSides,
            isLoaded: true,
            standings: standings,
            enoughBalance: balanceEnough && tickets.length ? false : true,
            entryId: props.entryId,
            bracketEntry: props.selectedEntryData,
            picks: picks,
            tieBreakers: bracketDetail.bracket.bracket_tiebreakers,
            userData: props.userData,
            teams: allTeams,
          });
        }));
      }));
    }
  }, [props.entryId]);

  React.useEffect(() => {
    if (state.userData !== props.userData) {
      setState({...state, userData: props.userData, isLogged: true});
    }
  }, [props.userData]);

  React.useEffect(() => {
    if (props.bracket.status === "upcoming" && state.isLoaded) {
      let showRules = localStorage.getItem("rulesBracketPoints");
      if (showRules === null) {
        handlePopUpReglas();
        localStorage.setItem("rulesBracketPoints", true);
      }
    }
  }, [state.isLoaded]);

  React.useEffect(() => {
    if (state.tieBreakers !== undefined) {
      let userTie = {};
      state.tieBreakers.map(tie => {
        let userTieBreaker = state.bracketEntry && state.bracketEntry.bracket_user_tiebreakers.find(item => item.bracket_tiebreaker_id === tie.id);
        let item = {
          id: (userTieBreaker !== undefined && userTieBreaker !== null) ? userTieBreaker.id : null,
          bracket_id: state.bracket.id,
          bracket_entry_id: state.bracketEntry !== null && state.bracketEntry !== undefined ? state.bracketEntry.id : null,
          bracket_tiebreaker_id: tie.id,
          value: (userTieBreaker !== undefined && userTieBreaker !== null) ? userTieBreaker.value : null,
        }
        userTie[tie.id] = item;
      });
      setTimeout(() => {
        setState((prevState) => {
          return {
            ...prevState,
            bracketUserTieBreakers: userTie,
          }
        });
      }, 400);
      //setState({...state, bracketUserTieBreakers: userTie});
    }
  }, [state.bracketEntry, state.tieBreakers]);

  // Render
  if (state.redirectLobby) {
    let bracketName = state.bracket.slug;
    let bracketId = state.bracket.id;
    return (
      <Redirect
        to={{
          pathname: `/bracketPoints/${ bracketName }/${ bracketId }`,
        }}
      />
    );
  } else {
    return (
      <>
        {state.isLogged ? null : (
          <NotLoggedUserBar
            redirectLogin={props.showLoginPopUp}
            redirectRegistro={props.showRegisterPopUp}
          />
        )}

        {!state.isLoaded ?
          <Loading label="Cargando..." />
        :
          <>
            {props.isPopUp &&
              <RegisterSteps isBalanceEnough={state.enoughBalance} />
            }

            <div className="quinielas-info row">
              <div className="flex-row responsive-sm">
                <div id="picksQuiniela" className="pool-info flex-col">
                  <div
                    className={`picks-message-overlay
                      ${
                        state.errorCopiar !== null
                          ? state.errorCopiar
                            ? 'show-message failed'
                            : 'show-message'
                          : ''
                      }`}
                  >
                    {state.errorCopiar !== null ? (
                      state.errorCopiar ? (
                        <span>Intentalo nuevamente ...</span>
                      ) : (
                        <div>
                          <span>¡Tus pick se copiaron con éxito!</span>
                          <i className="ion-checkmark-circled" />
                          <span>No olvides Guardarlos</span>
                        </div>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="panel-pickwin flex-container column" >
                    <div className="row title-quiniela ">
                      <div className="title-wrapper">
                        <img src={picks} className="pool-icons tablas" />
                        <span>MI BRACKET</span>
                      </div>
                    </div>

                    {/* -------------------------- */}
                    <div className={`bracket-overflow bracket-overflow-${state.bracket.sport_id}`} id='overFlowStyles'>
                      <div className='bracket-container' style={{width: 'calc((200px*'+(stagesCount+2)+') + 28em)'}}>
                        {
                        Object.entries(state.bracketSides).map( ([side, _stages]) => {
                          return (
                            <div key={side} className={`
                              tournament-bracket
                              ${side === 'left' ? 'left-container' : ''}
                              ${side === 'center' ? 'center-container' : ''}
                              ${side === 'right' ? 'right-container' : ''}
                            `}>
                              {
                                Object.entries(_stages).map(([stageId, matches]) => {
                                let stage = state.stages.find((stage) => stage.id === Number(stageId));
                                return(
                                  <div className="tournament-bracket__round" key={stageId}>
                                    <div className={`tournament-bracket__round-title col-offset-${matches.length}`}>
                                      <div className="round-name">
                                        <div className="inner">{stage && stage.name}</div>
                                      </div>
                                    </div>
                                    <ul className={`tournament-bracket__list`}>
                                      {
                                        matches.map((match) =>
                                          <BracketPointsPick
                                            key={match.id}
                                            match={match}
                                            pick={state.picks[match.number]}
                                            onPickChange={onPickChanged}
                                            bracket = {state.bracket}
                                            side={side}
                                            teamList={state.teams}
                                            tieBreakers={state.tieBreakers}
                                            onSelectTie={onSelectTie}
                                            bracketUserTieBreakers={state.bracketUserTieBreakers}
                                            bracketEntry = {state.bracketEntry}
                                          />                                      )
                                      }
                                    </ul>
                                  </div>
                                );

                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* -------------------------- */}

                    {props.bracket.status === 'closed' ? null : props.bracket.close !== 1 && utils.isLogged() ? (
                      <div className="btn-wrapper">
                        {state.bracket.is_full ? (
                          <span className="login-failed">
                            Esta quiniela esta llena, busca otra similar en nuestro lobby
                          </span>
                        ) : (
                          <Button
                            data-id="guardar"
                            disabled={(!state.isLoaded || !utils.isLogged() || state.submitting)}
                            onClick={(e) => {
                              if (state.entryId !== null) {
                                guardarBracket(
                                  state.bracket.id,
                                  state.picks,
                                  state.entryId,
                                  state.bracketUserTieBreakers,
                                );
                              } else {
                                if (state.enoughBalance) {
                                  props.updateUserInfo();
                                  handleRegisterPanel();
                                } else {
                                  props.showAbonar();
                                }
                              }
                            }}
                            className="guardar-picks callToAction"
                            bsStyle="default"
                          >
                            {state.entryId === null &&
                              <>
                                <img src={state.enoughBalance ? guardar : abonar} className="bolsa-img" />
                                <span data-id="guardar">
                                  {' '}
                                  {state.enoughBalance ? 'GUARDAR BRACKET Y REGÍSTRAME' : 'ABONAR SALDO'}
                                </span>
                              </>
                            ||
                              <>
                                <img src={guardar} className="bolsa-img" />
                                <span data-id="guardar">
                                  {' '}
                                  {'GUARDAR BRACKET'}
                                </span>
                              </>
                            }
                          </Button>
                        )}
                      </div>
                    ) : null}

                    {props.isPopUp &&
                      <Button
                        href={`/bracketPoints/${state.bracket.slug}/${state.bracket.id}`}
                        className="btn-lobby"
                        bsStyle="default"
                        disabled={!state.isLogged}
                        onClick={(e) => {
                        }}
                      >
                        <span style={{ padding: '0 10px' }}>IR A LOBBY BRACKET</span>
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>

            <RegisterPanel
              type="bracket"
              closePanel={handleRegisterPanel}
              entryId={state.entryId}
              handleConfirmation={handleConfirmation}
              logged={state.isLogged}
              matches={state.matches}
              payType={state.bracket.entry_currency}
              pool={state.bracket}
              pools={null}
              popUpNewUsersStatus={state.popUpNewUsersStatus}
              quinaID={state.bracket.id}
              refreshAfterGuardar={props.refreshAfterGuardar}
              registerPicks={state.picks}
              returnErrors={returnErrors}
              showPanel={state.showRegisterPanel}
              showPanelFirstRegister={state.showPanelFirstRegister}
              showPanelReRegister={state.showPanelReRegister}
              showTutorial={false}
              updatePanelFirstRegister={handlePanelFirstRegister}
              updatePanelReRegister={handlePanelReRegister}
              updateStateFunc={handleRegisterPanel}
              updateUserInfo={props.updateUserInfo}
              userData={state.userData}
              withRegister={state.entryId !== null}
              bracketUserTieBreakers={state.bracketUserTieBreakers}
            />
            <SavedPicksOverlay
              showOverlay={state.showSavedConfirmation}
              allPicks={state.matches.length}
              currentSavedPicks={
                _.filter(state.picks, (pick) => pick.pick !== null).length
              }
              label="¡ Felicidades tu bracket se guardo con éxito!"
            />
            <Modal
              dialogClassName="infoReferralsPopUp"
              show={state.showPopUpReglas}
              onHide={() => { }}
            >
              <div className="panel-abonar">
                <div
                  id="panelTitle"
                  className="row-center flex-row row-no-padding panel-abonar-titulo"
                  style={{ justifyContent: 'initial' }}
                >
                  <span className="flex-col col-80">¿Cómo funciona? </span>
                  <button
                    className="cerrar-panel"
                    onClick={() => {
                      handlePopUpReglas();
                    }}
                    style={{ marginLeft: '55px' }}
                  >
                    <i className="ion-ios-close-empty"></i>
                  </button>
                </div>
              </div>
              <div className="flex-row row-no-padding pickwin-panel">
                <div className="flex-col panel-pickwin row-center">
                  <img src="https://storage.googleapis.com/pw-resources/banners/app/popup-game-points-bracket.jpg" style={{ maxWidth: '473px', width: '100%', height: 'auto' }} />
                </div>
              </div>
            </Modal>
          </>
        }
      </>
    );
  }
}

export default BracketPoints;
