import styled, { css } from 'styled-components'

// For extend inherit in LinkButton etc.
export const LinkBase = css`
  color: #87b13d;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  transition: color 150ms ease;

  &:hover {
    color: #b2e755;
  }
`

const Link = styled.a`
  ${LinkBase}
`

export default Link
