import history from '../routes/history'
import API from '../utils/api'
import utils from '../utils/utils'

//Get Zendesk Suggestions and set in localStorage
const date = new Date()
const zendeskTime = localStorage.getItem('zendeskTime')
const user = utils.getUserInfo()

if (zendeskTime === null) {
  date.setDate(date.getDate() +1)
  localStorage.setItem('zendeskTime', date.getTime())
}

if (new Date().getTime() > zendeskTime || zendeskTime === null) {
  API.getZendeskSuggestions()
  .then((response) => {
    date.setDate(date.getDate() +1)
    localStorage.setItem('zendeskSuggestions', JSON.stringify(response.data.web))
    localStorage.setItem('zendeskTime', date.getTime())
  })
  .catch((error) => {
    console.log(error)
  })
}

zendeskSuggestions(history.location.pathname)

function zendeskSuggestions(location) {
  const suggestions = JSON.parse(localStorage.getItem("zendeskSuggestions"))
  if (suggestions) {
    location = location.replace(/\//g, "-")
    if (suggestions[location] !== undefined) {
      window.zE('webWidget', 'helpCenter:setSuggestions', { search: suggestions[location] })
    } else {
      window.zE('webWidget', 'helpCenter:setSuggestions', { search: window.document.title })
    }
  }

  //Zendex identify
  if (user !== null) {
    window.zE(function() {
      window.zE.identify({
        name: user.nick,
        email: user.email
      })
    })
  }
}

history.listen((location, action) => {
  zendeskSuggestions(location.pathname)
})

