import React from 'react';
import {
  FormGroup,
  ControlLabel,
  Checkbox,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap/lib';
import Switch from 'rc-switch';
import $ from 'jquery';

import baseball from '../../assets/images/BaseballBall-50.png';
import americano from '../../assets/images/AmericanoBall-50.png';
import futbol from '../../assets/images/FutbolBall-50.png';
import basketball from '../../assets/images/BasketballBall-50.png';
import tennis from '../../assets/images/TennisBall-50.png';
import golf from '../../assets/images/GolfBall-50.png';
import f1 from '../../assets/images/Volante-50.png';
import hockey from '../../assets/images/Hockey.png';
import box from '../../assets/images/box.png';

class NotificationItemFormsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.currentSport = (sportName) => {
      let sportIcon = null;
      switch (sportName) {
        case 'Fútbol':
          sportIcon = <img src={futbol} className="notification-sports-icon" />;
          break;
        case 'Fútbol Americano':
          sportIcon = <img src={americano} className="notification-sports-icon" />;
          break;
        case 'Basketball':
          sportIcon = <img src={basketball} className="notification-sports-icon" />;
          break;
        case 'Baseball':
          sportIcon = <img src={baseball} className="notification-sports-icon" />;
          break;
        case 'Tenis':
          sportIcon = <img src={tennis} className="notification-sports-icon" />;
          break;
        case 'Golf':
          sportIcon = <img src={golf} className="notification-sports-icon" />;
          break;
        case 'F1':
          sportIcon = <img src={f1} className="notification-sports-icon" />;
          break;
        case 'Box':
          sportIcon = <img src={box} className="notification-sports-icon" />;
          break;
        case 'Hockey':
          sportIcon = <img src={hockey} className="notification-sports-icon" />;
          break;
      }

      return sportIcon;
    };

    this.getGamesValue = (gameVal) => {
      let enabledValues = Object.keys(gameVal).filter((g) => gameVal[g].enabled);
      // console.log(enabledValues);
      return enabledValues;
    };

    this.renderToggleButtons = (options) => {
      // console.log( 'Render Toggle Buttons' );
      // console.log( options );
      let optionsList = Object.keys(options);
      let toggleOptions = optionsList.map((o) => {
        return (
          <ToggleButton key={o} className="btn-point-format" value={o}>
            {o === 'pool' ? 'Quiniela' : o === 'survivor' ? 'Survivor' : 'Bracket'}
          </ToggleButton>
        );
      });

      return toggleOptions;
    };

    this.renderSports = (options, sportId) => {
      // console.log( 'Render Sports Buttons' );
      // console.log( options );
      // let optionsList = Object.keys( options );

      let toggleIcon = null;
      switch (sportId) {
        case 1:
          toggleIcon = <img className="pool-icon" src={futbol} />;
          break;
        case 2:
          toggleIcon = <img className="pool-icon" src={americano} />;
          break;
        case 3:
          toggleIcon = <img className="pool-icon" src={basketball} />;
          break;
        case 4:
          toggleIcon = <img className="pool-icon" src={baseball} />;
          break;
        case 5:
          toggleIcon = <img className="pool-icon" src={tennis} />;
          break;
        case 6:
          toggleIcon = <img className="pool-icon" src={golf} />;
          break;
        case 7:
          toggleIcon = <img className="pool-icon" src={f1} />;
          break;
        case 8:
          toggleIcon = <img className="pool-icon" src={box} />;
          break;
        case 9:
          toggleIcon = <img className="pool-icon" src={hockey} />;
          break;
      }

      return toggleIcon;
    };
  }

  render() {
    let conditionalComponent = null;
    let {
      amountType,
      checkboxType,
      defaultValue,
      disabled,
      gameType,
      //id,
      inputHandler,
      label,
      tournamentId,
      name,
      sportId,
      switchCategory,
      sportsList,
      subLabel,
      type,
    } = this.props;

    switch (type) {
      case 'STATUS_GAME':
        conditionalComponent = (
          <FormGroup
            className="flex-row row-center row-no-padding point-format-wizard"
            controlId={name}
          >
            <ControlLabel className="flex-col col-80">{label}</ControlLabel>
            <div className="">
              <Checkbox onChange={inputHandler} inline={true}>
                <div className={` checkbox-custom `} />
              </Checkbox>
            </div>
          </FormGroup>
        );
        break;
      case 'CHECKBOX':
        conditionalComponent = (
          <label
            onChange={(e) => {
              checkboxType !== 'leaguesCheckbox' &&
                checkboxType !== 'amountCheckbox' &&
                checkboxType === 'tournamentsCheckbox'
                ? inputHandler(tournamentId, sportId)
                : checkboxType === 'amountCheckbox'
                  ? inputHandler(tournamentId, sportId, amountType)
                  : inputHandler(sportId);
            }}
            className={`flex-row row-center row-no-padding point-format-wizard 
              ${checkboxType ? 'capitalizedCheckbox ' : ''} 
              ${
              checkboxType === 'amountCheckbox'
                ? ' flex-container justify align center column '
                : ''
              } `}
            controlId={name}
          >
            <div className="flex-col col-85">
              {checkboxType === 'sportCheckbox' ? this.currentSport(name) : null}
              {label}
            </div>
            <div className="">
              <Checkbox checked={defaultValue} disabled={disabled} inline={true}>
                <div className={` checkbox-custom ${disabled ? 'disabled' : ''} `} />
              </Checkbox>
            </div>
          </label>
        );
        break;
      case 'GAME':
        conditionalComponent = (
          <FormGroup className="flex-col point-format-wizard" controlId={name}>
            <ControlLabel className="flex-row">{label}</ControlLabel>
            <div className="flex-row">
              <ButtonToolbar>
                <ToggleButtonGroup
                  type="checkbox"
                  defaultValue={this.getGamesValue(defaultValue)}
                  name={name}
                  onChange={(e) => {
                    inputHandler(e);
                  }}
                >
                  {this.renderToggleButtons(defaultValue)}
                </ToggleButtonGroup>
              </ButtonToolbar>
            </div>
          </FormGroup>
        );
        break;
      case 'AMOUNT':
        conditionalComponent = (
          <div className="flex-row row-center row-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={name}>
              <div className="flex-row">
                {disabled ? null : (
                  <ButtonToolbar>
                    <ToggleButtonGroup
                      type="checkbox"
                      defaultValue={defaultValue}
                      name={name}
                      onChange={(e) => {
                        inputHandler(sportId, tournamentId, e);
                      }}
                    >
                      <ToggleButton className="btn-point-format" value={'small'}>
                        <i className="ion-social-usd" />
                      </ToggleButton>
                      <ToggleButton className="btn-point-format" value={'medium'}>
                        <i className="ion-social-usd" />
                        <i className="ion-social-usd" />
                      </ToggleButton>
                      <ToggleButton className="btn-point-format" value={'high'}>
                        <i className="ion-social-usd" />
                        <i className="ion-social-usd" />
                        <i className="ion-social-usd" />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                )}
              </div>
            </FormGroup>
            {/*
              <span className='error-formulario'>{errorMessage}</span>
              { helpMessages }
            */}
          </div>
        );
        break;
      case 'STATUS':
        conditionalComponent = (
          <div className="flex-row row-center row-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={name}>
              <ControlLabel className="flex-row">{label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="checkbox"
                    defaultValue={defaultValue}
                    name={name}
                    onChange={(e) => {
                      inputHandler(gameType, e);
                    }}
                  >
                    <ToggleButton className="btn-point-format" value={'upcoming'}>
                      Próximas
                    </ToggleButton>
                    <ToggleButton className="btn-point-format" value={'live'}>
                      En Vivo
                    </ToggleButton>
                    <ToggleButton className="btn-point-format" value={'closed'}>
                      Cerradas
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            {/*
              <span className='error-formulario'>{errorMessage}</span>
              { helpMessages }
            */}
          </div>
        );
        break;
      case 'SPORTS':
        conditionalComponent = (
          <div className="flex-row row-center row-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={name}>
              <ControlLabel className="flex-row">{label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    className="flex-container justify align  wrap"
                    type="checkbox"
                    defaultValue={defaultValue}
                    name={name}
                    onChange={(e) => {
                      inputHandler(gameType, e);
                    }}
                  >
                    {this.renderSports(sportsList)}
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
            {/*
              <span className='error-formulario'>{errorMessage}</span>
              { helpMessages }
            */}
          </div>
        );
        break;
      case 'TYPE':
        conditionalComponent = (
          <div className="flex-row row-center row-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={name}>
              <ControlLabel className="flex-row">{label}</ControlLabel>
              <div className="flex-row">
                <ButtonToolbar>
                  {gameType === 'pool' ? (
                    <ToggleButtonGroup
                      type="checkbox"
                      defaultValue={defaultValue}
                      name={name}
                      onChange={(e) => {
                        inputHandler(gameType, e);
                      }}
                    >
                      <ToggleButton className="btn-point-format" value={'open'}>
                        Abiertas
                      </ToggleButton>
                      <ToggleButton className="btn-point-format" value={'limited'}>
                        Cupo Limitado
                      </ToggleButton>
                      <ToggleButton className="btn-point-format" value={'max'}>
                        Max
                      </ToggleButton>
                    </ToggleButtonGroup>
                  ) : null}
                  {gameType === 'survivor' ? (
                    <ToggleButtonGroup
                      type="checkbox"
                      defaultValue={defaultValue}
                      name={name}
                      onChange={(e) => {
                        inputHandler(gameType, e);
                      }}
                    >
                      <ToggleButton className="btn-point-format" value={'withStrikes'}>
                        Con Strikes
                      </ToggleButton>
                      <ToggleButton className="btn-point-format" value={'noStrikes'}>
                        Sin Strikes
                      </ToggleButton>
                    </ToggleButtonGroup>
                  ) : null}
                </ButtonToolbar>
              </div>
            </FormGroup>
            {/*
              <span className='error-formulario'>{errorMessage}</span>
              { helpMessages }
            */}
          </div>
        );
        break;
      case 'GAME_TYPE':
        conditionalComponent = (
          <div className="flex-row row-center row-no-padding">
            <FormGroup className="flex-col point-format-wizard" controlId={name}>
              <div className="flex-row">
                <ButtonToolbar>
                  <ToggleButtonGroup
                    type="checkbox"
                    defaultValue={defaultValue}
                    name={name}
                    onChange={(e) => {
                      inputHandler(name, e);
                    }}
                  >
                    <ToggleButton className="btn-point-format" value={'pool'}>
                      Quinielas
                    </ToggleButton>
                    <ToggleButton className="btn-point-format" value={'survivor'}>
                      Survivors
                    </ToggleButton>
                    <ToggleButton className="btn-point-format" value={'bracket'}>
                      Brackets
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>
            </FormGroup>
          </div>
        );
        break;
      case 'SWITCH':
        conditionalComponent = (
          <label
            onClick={(e) => {
              checkboxType === 'sportCheckbox'
                ? inputHandler(sportId)
                : inputHandler(name, switchCategory);
            }}
            className="flex-row row-center switch-label"
          >
            {checkboxType === 'sportCheckbox' ? this.renderSports(sportsList, sportId) : null}
            <FormGroup
              className={`flex-col ${$(window).width <= 669 ? ' col-75' : ' col-80'} `}
              controlId={name}
            >
              <ControlLabel className="flex-row row-center">{label}</ControlLabel>
              {subLabel ? <small className="flex-row row-center">{subLabel}</small> : null}
            </FormGroup>
            <div className="flex-col switch-input text-center">
              <Switch disabled={disabled} checked={defaultValue} />
            </div>
            {/*
            <span className='error-formulario'>{errorMessage}</span>
            { helpMessages }
          */}
          </label>
        );
        break;
      case 'SWITCH_CHECKBOX':
        conditionalComponent = (
          <label
            onClick={(e) => {
              inputHandler(name, switchCategory);
            }}
            className="flex-row row-center row-no-padding "
          >
            <div className="flex-col col-80">
              {checkboxType === 'sportCheckbox' ? this.currentSport(name) : null}
              {label}
            </div>
            <div className="">
              <Checkbox checked={defaultValue} disabled={disabled} inline={true}>
                <div className={` checkbox-custom ${disabled ? 'disabled' : ''} `} />
              </Checkbox>
            </div>
            {/*
            <span className='error-formulario'>{errorMessage}</span>
            { helpMessages }
          */}
          </label>
        );
        break;
    }
    return conditionalComponent;
  }
}

export default NotificationItemFormsy;
