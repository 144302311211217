import React from 'react'
import styled from 'styled-components'

export default function BannerPromotions () {
  return (
    <Box>
      <Content>
        <strong>Promociones</strong>
      </Content>
      <Gradient />
      <GradientLine />
      <GradientBall />
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: 1em;
  background: #000;
  height: 80px;
  margin: 1.2em auto;
  align-items: center;
  justify-content: center;
  border-radius: 3em 3em 0.1em 0.1em;
  background: linear-gradient(359deg,#181818,#080808);
  box-shadow: 0px 1px 2px inset #686868, 1px 0px 9px inset #1c1c1c9e;
  font-size: 1.2em;
  position: relative;
`

const Content = styled.div`
  width: 280px;
  height: 107px;
  margin: -0.5em auto 0 auto;
  background-color: #0c0c0c;
  border-radius: 1em;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 1px 2px inset #686868, 1px 0px 9px inset #1c1c1c9e;

  span {
    font-size: 0.7em;
    line-height: 2;
  }

  strong {
    color: #99d928;
    font-size: 1.4em;
    font-size: 1.4em;
    font-family: 'Chivo';
    font-weight: 400;
    background: linear-gradient(to right, #88e700 0%, #ddff00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`


const Gradient = styled.div`
  width: 85%;
  height: 70%;
  background: radial-gradient(circle, #78bc288c 0%,rgba(0,0,0,0) 94%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const GradientLine = styled.div`
  width: 99%;
  height: 0.2em;
  background: radial-gradient(circle,#78bc28 0%,rgba(0,0,0,0) 94%);
  position: absolute;
  top: 74%;
  left: 50%;
  transform: translate(-50%,-50%);
`

const GradientBall = styled.div`
  width: 70%;
  height: 0.7em;
  background: radial-gradient(circle,#77bc2883 0%,rgba(0,0,0,0) 94%);
  position: absolute;
  top: 61%;
  left: 50%;
  border-radius: 50em;
  transform: translate(-50%,-50%);
`
