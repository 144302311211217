import React from 'react';

import utils from '../../utils/utils';
import ReactTooltip from 'react-tooltip';
import { ShareButtons } from 'react-share';

import { Modal, Button } from 'react-bootstrap/lib';

import bannerInvitaReferral from '../../assets/images/banners/banner-referral-invita.jpg';

import Clipboard from 'clipboard';
import NewReferralMailInvite from '../NewReferralMailInvite';
import $ from 'jquery';

let clipboardCode;
const { FacebookShareButton, TwitterShareButton } = ShareButtons;

const PanelInvitarAmigos = ({
  title,
  showPanel,
  openInvitePanel,
  closePanel,
  branchLink,
  whatsAppInvitationReferer,
  showMailInvite,
  hidePanel,
}) => {
  clipboardCode = new Clipboard('.copiar-btn');
  clipboardCode.on('success', (e) => {
    const wich = e.trigger.attributes['data-clipboard-target'].value;
    $(wich).addClass('copied');
    e.clearSelection();
  });

  const userData = utils.getUserInfo();
  return (
    <Modal
      bsSize="sm"
      backdropClassName="inviteFriendsBackdrop"
      dialogClassName="inviteFriendsBackdrop"
      onHide={closePanel}
      show={showPanel}
    >
      <div className="panel-abonar">
        <div id="panelTitle" className="panel-abonar-titulo">
          <span>
            <i className="ion-flag" /> Referidos
          </span>
          <button className="cerrar-panel" onClick={closePanel}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="col-xs-12 pickwin-panel">
          <div className="detalles-privadas-compartir-container">
            <div className="col-xs-12">
              <div className="panel-invite-title text-center">
                <img src={bannerInvitaReferral} className="img-responsive" alt="" />
              </div>
              <div className="flex-row row-center flex-container justify">
                <div className="codigo-amigo flex-col">
                  <input
                    id="copiar-clipboard"
                    value={
                      branchLink !== undefined
                        ? branchLink
                        : `https://www.pickwin.mx/?referer=${userData ? userData.referalCode : ''}`
                    }
                  />
                </div>
                <div className="copiar-button-codigo flex-col col-15">
                  <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                    Copia tú código y compártelo
                  </ReactTooltip>
                  <button
                    data-tip
                    data-for="copiar-tool"
                    className="copiar-btn"
                    data-clipboard-target="#copiar-clipboard"
                  >
                    <i className="ion-ios-copy " />
                    <span className="visible-md">Copiar</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-group inviteToPlay flex-row">
              <FacebookShareButton
                quote={`Te invito a jugar en pickwin, regístrate aquí: ${
                  branchLink !== undefined
                    ? branchLink
                    : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                  } `}
                hashtag={'#Pickwin #registrate #JalaTusPicks'}
                url={
                  branchLink !== undefined
                    ? branchLink
                    : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                }
              >
                <Button className="fb-btn">
                  Por Facebook <i className="ion-social-facebook" />
                </Button>
              </FacebookShareButton>
              <TwitterShareButton
                title={`Te invito a jugar en pickwin, regístrate aquí: ${
                  branchLink !== undefined
                    ? branchLink
                    : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                  }`}
                url={
                  branchLink !== undefined
                    ? branchLink
                    : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                }
                hasthag={'#Pickwin #registrate #JalaTusPicks'}
              >
                <Button className="twitter-btn">
                  {' '}
                  Por Twitter <i className="ion-social-twitter" />
                </Button>
              </TwitterShareButton>
              <div className="SocialMediaShareButton">
                <Button className="mail-btn" onClick={openInvitePanel}>
                  Por Mail <i className="ion-email" />
                </Button>
              </div>
              <div className="SocialMediaShareButton">
                <Button
                  className="whatsapp-btn"
                  onClick={() => {
                    whatsAppInvitationReferer(branchLink);
                  }}
                >
                  Por WhatsApp <i className="ion-social-whatsapp" />
                </Button>
              </div>
            </div>
          </div>
          <div className="btn-wrap">
            <Button className="btn-rojo gracias" onClick={closePanel}>
              No, gracias
            </Button>
          </div>
          <NewReferralMailInvite showPanel={showMailInvite} hidePanel={hidePanel} />
        </div>
      </div>
    </Modal>
  );
};

export default PanelInvitarAmigos;
