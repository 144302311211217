import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'


// Components
import Clock from '../Clock'
import SurvivorPick from './SurvivorPick'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
import { PicksSoccerNFL } from './components/PicksSoccerNFL'


export const ResetButton = styled.button`
  background-color: #098af1;
  color: #FFF;
  height: 1.4em;
  border-radius: 0.2em;
  border: 0;
  line-height: 1;
`

export const HeaderPicks = styled.div`
  height: 40px;
  background-color: black;
  border: 1px solid gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.9em;
  font-weight: bold;
  font-family: Chivo;
  font-style: italic;
  margin: 0 0.5em;
`

const LiveInfo = ({ fixture }) => {
  if (fixture.enabled || fixture.finished) {
    return null;
  } else {
    // Si esta vivo
    return (
      <div className="row-props-container">
        <span className="live-pool flex-container">
          <div className="flex-col col-20 col-no-padding live-label">Live!</div>
          <div className="flex-col col-no-padding live-data">
            <div className="flex-container justify-end">
              <span>
                {fixture.stats && fixture.stats.hasOwnProperty('quarter')
                  ? fixture.stats.quarter !== null && fixture.stats.quarter !== ''
                    ? fixture.stats.quarter
                    : ''
                  : ''}
              </span>
              <span>
                {fixture.stats && fixture.stats.hasOwnProperty('timer')
                  ? fixture.stats.timer !== null && fixture.stats.timer !== ''
                    ? fixture.stats.timer + '"'
                    : ''
                  : ''}
                {fixture.stats && fixture.stats.hasOwnProperty('timer') ? (
                  fixture.stats.timer !== null && fixture.stats.timer !== '' ? (
                    <i className="ion-ios-timer" />
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
        </span>
      </div>
    );
  }
};

/**
 * This component presents the corresponding fixtures
 * given the week and the entry
 * @const ListFixtures
 */
const ListFixtures = ({
  currentEntry,
  myEntry,
  currentWeek,
  firstOf,
  withRegister,
  selectedWeek,
  entriesInfo,
  handlePick,
  survivorFixtures,
  survivorTeams,
  survivorWeeks,
  updateStartedFixture,
  pointFormat,
  survivorInfo,
  allFixtures,
  teams,
  resetPicks,
  removePick,
  previousWeek,
  updateStartedFirstMatchOfWeek
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)


    // Regresa un Booleano si se debe mostrar o no el boton de borrar todos los picks
    // Basandose en si existen picks que el usurio puede eliminar (que no hayan comenzado)
    // Si tiene picks que ya pasaron el boton no se reveria de mosstrar
    const getShowRemovePicks = () => {

      if (!myEntry?.survivor_picks) return
    
      return Boolean(Object
          .entries(myEntry.survivor_picks)
          .map(([ weekId, survivor_pick ]) => {
            const pick = survivor_pick.pick 
            const fixture = allFixtures.fixtures[weekId]
            const firstMatch = fixture[0]
            const isUserSelectedFirstMatch = firstMatch.home_team.id === pick || firstMatch.away_team.id === pick
        
            const isPassedDeadlineWeek = fixture?.time_left <= 0
              
            // Fecha de cuando paso el primer partido
            const isPassedFirstMatchDate = firstMatch?.survivor_time_left <= 0
              
            let isDisabled = isPassedDeadlineWeek
                
            // Soccer
            if (survivorInfo.sport_id === 1 && isPassedFirstMatchDate) {
              isDisabled = true
            } else {
              // NFL
              if (isPassedFirstMatchDate && isUserSelectedFirstMatch) {
                isDisabled = true
              } else if (!isUserSelectedFirstMatch && isPassedDeadlineWeek) {
                isDisabled = true
              }
            }
            
            return  !isDisabled ? pick : null
        })
        .find(item => item))
    }


    const showRemovePicks = getShowRemovePicks()

  /**
   * List the features
   */
  let listFixtures = survivorFixtures.map((fixt) => {
    const matchDate = moment(fixt.match_date);

    const closingDate = moment(fixt.match_date);

    const nowData = moment();

    const itPassedSec = closingDate.diff(nowData, 'seconds');

    // Cambia el color de el contador dependiendo del tiempo
    // restante para comenzar el survivor
    let lessThan24Hours = itPassedSec < 86400 ? true : false;
    let lessThan1Hour = itPassedSec < 3600 ? true : false;

    return (
      <div key={fixt.id} className=" flex-col col-no-padding">
        <LiveInfo fixture={fixt} />
        <div
          id="matchesRow"
          className={`row-props flex-row row-no-padding row-center ${
            fixt.id % 2 === 0 ? 'even-row' : ''
          }`}
        >
          <div className={'pickCell flex-col ' + (pointFormat === 'natural' ? 'col-40' : 'col-30')}>
            <SurvivorPick
              survivorFixtures={survivorFixtures}
              firstOf={firstOf}
              withRegister={withRegister}
              fixture={fixt}
              availableTeams={survivorTeams}
              survivorEntryData={myEntry}
              type="home"
              teamData={fixt.home_team}
              currentEntry={currentEntry}
              currentWeek={currentWeek}
              selectedWeek={selectedWeek}
              handlePick={handlePick}
            />
          </div>

          {pointFormat === 'line' ? (
            fixt.favorite !== null ? (
              <div className="pickCell linea flex-col col-20">
                <div className="flex-row row-center row-no-padding">
                  <div className="flex-container column item-linea local">
                    {fixt.favorite === 'home' ? fixt.advantage : null}
                  </div>
                  <div className="flex-container column item-linea away">
                    {fixt.favorite === 'away' ? fixt.advantage : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className="pickCell linea flex-container col-20 column justify align">PD</div>
            )
          ) : null}
          <div className={'pickCell flex-col ' + (pointFormat === 'natural' ? 'col-40' : 'col-30')}>
            <SurvivorPick
              survivorFixtures={survivorFixtures}
              firstOf={firstOf}
              withRegister={withRegister}
              fixture={fixt}
              availableTeams={survivorTeams}
              survivorEntryData={myEntry}
              currentEntry={currentEntry}
              type="away"
              teamData={fixt.away_team}
              currentWeek={currentWeek}
              selectedWeek={selectedWeek}
              handlePick={handlePick}
            />
          </div>
          {fixt.enabled ? (
            lessThan1Hour ? (
              <span style={{ border: '2px solid gold'}}>
              <Clock
                type="row"
                updateStartedFixture={updateStartedFixture}
                fixtureID={fixt.id}
                closingDate={closingDate}
              />
              </span>
              
            ) : (
              <div className="pickCell flex-container column justify align cierre">
                <span
                  style={
                    lessThan24Hours
                      ? { color: 'rgb(211, 36, 43)', textTransform: 'capitalize' }
                      : { color: '#78BC28', textTransform: 'capitalize' }
                  }
                >
                  {closingDate.format('DD MMM')}
                </span>
                <span
                  style={
                    lessThan24Hours
                      ? { color: 'rgb(211, 36, 43)', textTransform: 'capitalize' }
                      : { color: '#78BC28', textTransform: 'capitalize' }
                  }
                >
                  {closingDate.format('HH:mm ')}
                </span>
              </div>
            )
          ) : (
            <div className="pickCell flex-container column justify align ">
              <span>
                {' '}
                {fixt.score_home}-{fixt.score_away}{' '}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  });


  
  const showConfirmAll = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowConfirmDelete(true)
  }

  const onConfirm = () => {
    resetPicks()
    setShowConfirmDelete(false)
  }

  const onClose = () => {
    setShowConfirmDelete(false)
  }


  return (
    <div>
      <form id="survivorForm">
        {/* Status Survivor */}
        <HeaderPicks>
          <span >SEMANA </span>
          <span >PICK </span>

          <ConfirmModal
            show={showConfirmDelete}
            onConfirm={onConfirm}
            onClose={onClose}
            title='REINICIAR PICKS'
            message='¿Estás seguro que deseas borrar tus picks?'
            message2='Se perderán todos tus picks y no podrás recuperarlos.'
          />
          
          <span >
            {showRemovePicks && (
              <ResetButton onClick={showConfirmAll}>BORRAR PICKS</ResetButton>
            )}
          </span>

          <span >CIERRE </span>

        </HeaderPicks>

        {/* Solo mostrar para deportes que sea Soccer o NFL */}
        {(survivorInfo.sport_id === 6 || survivorInfo.sport_id === 7) && (
          <div className="header-picks-partidos flex-row row-no-padding results" >
            <div
              className={
                'flex-col col-center text-center pick-category ' +
                (pointFormat === 'natural' ? 'col-40' : 'col-30')
              }
            >
              LOCAL
            </div>
            {pointFormat === 'line' ? (
              <div
                className={
                  'flex-col col-center text-center pick-category linea ' +
                  (pointFormat === 'natural' ? 'col-40' : 'col-20')
                }
              >
                LÍNEA
              </div>
            ) : null}
            <div
              className={
                'flex-col col-center pick-category text-center ' +
                (pointFormat === 'natural' ? 'col-40' : 'col-30')
              }
            >
              VISITANTE
            </div>
            <div className="flex-col col-center pick-category text-center">
              {firstOf.enabled ? 'FECHA' : 'RES.'}
            </div>
          </div>
        )}
        

        {/* ------ Control de picks ------- */}
        {(survivorInfo.sport_id === 1 || survivorInfo.sport_id === 2) 
          ? <PicksSoccerNFL  // <-- Para Soccer y NFL
              survivorWeeks={survivorWeeks}
              allFixtures={allFixtures}
              handlePick={handlePick}
              removePick={removePick}
              currentEntry={currentEntry}
              teams={teams}
              pointFormat={pointFormat}
              updateStartedFixture={updateStartedFixture}
              survivorInfo={survivorInfo}
              survivorEntryData={myEntry}
              currentWeek={currentWeek}
              previousWeek={previousWeek}
              updateStartedFirstMatchOfWeek={updateStartedFirstMatchOfWeek}
            /> 
          : <div className="flex-col col-no-padding">{listFixtures}</div> // <-- Para Golf y F1
        }
      </form>
    </div>
  );
};

export default ListFixtures;
