import './VerificationSMS.css'
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import VerificationInput from 'react-verification-input'
import * as Sentry from '@sentry/browser'

// Components
import TextField from '../Form/TextField'
import SelectCountry from '../Form/SelectCountry'
import Button from '../Form/Button'
import ModalBody from '../ModalPanel/ModalBody'
import ModalPanel from '../ModalPanel/ModalPanel'
import Typography from '../../Xmerald-UI/components/Typography/Typography'

// Hooks
import useCountDown from '../../hooks/useCountDown'
import useMergedState from '../../hooks/useMergedState'
import useAuthContext from '../../hooks/useAuthContext'

// Utils
import utils from '../../utils/utils'
import API from '../../utils/api'
import { publish, subscribe, unsubscribe } from '../../utils/events'

const initialState = {
  showModal: false,
  // Phone number input
  phone: '',
  // If country code was selected
  countryCode: false,
  // Number list for verification
  verificationCode: [],
  // If code was sended via SMS
  codeSended: false,
  // If is submitting to endpoint
  codeResended: false,
  // If is requesting another code
  resendingCode: false,
  submitting: false,
  errorMessage: null,
}

export default function VerificationSMS () {
  const { setUser } = useAuthContext()
  const phoneRef = useRef()
  const countDown = useCountDown(120)
  const [state, setState] = useMergedState(initialState)
  const [showModal, setShowModal] = useState(false)

  const showModalHandler = () => setShowModal(true)

  useEffect(() => {
    subscribe('SMS:SHOW_MODAL', showModalHandler)

    return () => unsubscribe('SMS:SHOW_MODAL', showModalHandler)
  }, [])

  const handleChangeCountryCode = (code) => {
    setState({ countryCode: code })
    phoneRef.current.focus()
  }

  const handleChangePhoneNumber = (_, value) => {
    setState({
      errorMessage: '',
      phone: value
    })
  }

  const areOnlyNumbers = (value) => {
    if (!value) return false
    return value.match(/^\d+$/gm)
  }

  // function submit the phone and code data to send the sms
  // Step 1:
  const requestSMSCode = async (resend) => {
    if (!areOnlyNumbers(state.phone) || state.phone.length < 10) {
      return setState({ errorMessage: 'El numero ingresado no es correcto' })
    }
    if (resend === 'resend') {
      setState({ resendingCode: true })
    } else {
      setState({ submitting: true })
    }
    try {
      const smsData = {
        country: state.countryCode.ccode,
        phone: state.phone,
        code: [null, null, null, null, null]
      }

      const { data } = await API.requestSms(smsData)
      setState({ codeResended: resend === 'resend' })
      if (!data.success) {
        setState({ errorMessage: data.error })
      } else {
        countDown.reset()
        countDown.play()
        setState({
          errorMessage: data.error,
          codeSended: true
        })
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
    setState({
      resendingCode: false,
      submitting: false
    })
  }

  // function submit the verification code
  // Step 2:
  const submitVerificationCode = async (code) => {
    setState({ submitting: true })

    const smsData = {
      country: state.countryCode.ccode,
      phone: state.phone,
      code
    }

    try {
      const { data } = await API.verifySms(smsData)
      if (!data.success) {
        setState({ errorMessage: data.error })
      } else {
        const userDataTemp = utils.getUserInfo()
        userDataTemp.identity_verified = true

        const userBalance = utils.getUserBalance()
        const userDummy = {
          user: userDataTemp,
          balanceReal: userBalance.balanceReal,
          balancePcReal: userBalance.balancePcReal,
          balancePc: userBalance.balancePc
        }

        publish('updateUserInfo', userDummy)
        setUser(userDummy)
        utils.setUserInfo(userDummy)
        utils.removeReadyToVerify()
        publish('SMS:ACOUNT_VERIFIED', userDataTemp)
        setShowModal(false)
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
    setState({ submitting: false })
  }

  const handleChangeCode = (code) => {
    const codeList = code
      .split('')
      .map(Number)

    setState({
      verificationCode: codeList,
      errorMessage: null
    })

    // Automatic send verification code
    if (code.length === 5 && !state.submitting) {
      submitVerificationCode(codeList)
    }
  }

  const isValidFormRequestCode = (
    areOnlyNumbers(state.phone) && state.phone.length === 10 && state.countryCode
  )

  const isVerificationCodeOK = state.verificationCode.length === 5

  if (!showModal) return null

  if (!state.codeSended) {
    return (
      <ModalPanel>
        <Panel>
          <PhoneImage src='/images/Grafico celular confirmar cuenta.png' />
          <ModalBody style={{ maxWidth: 350 }}>
            <div>
              <Typography center mb={5}>Para continuar con tu depósito, necesitamos verificar tu cuenta. Se realizará el cargo terminando este proceso.</Typography>
            </div>
            <SelectCountry
              options={['Mexico']}
              onChange={handleChangeCountryCode}
              value={state.countryCode}
              style={{ marginTop: '1em' }}
            />
            <TextField
              type='tel'
              ref={phoneRef}
              placeholder='Celular'
              iconSrc='/images/Icono telefono.png'
              inputMode='numeric'
              pattern='[0-9]*'
              onChange={handleChangePhoneNumber}
              onEnter={requestSMSCode}
              minLength={10}
              maxLength={10}
            />
            {(state.phone && !areOnlyNumbers(state.phone)) && (
              <HelpText style={{ color: 'red' }}>Sólo puedes ingresar números.</HelpText>
            )}
            {state.errorMessage && (
              <HelpText style={{ color: 'red' }}>{state.errorMessage}</HelpText>
            )}
            <HelpText>Escribe tu número celular con la clave lada de tu ciudad, sin espacios, guiones ni 044.</HelpText>
            <Button
              bgColor={isValidFormRequestCode ? '#88ba44' : '#787878'}
              loading={state.submitting}
              onClick={requestSMSCode}
            >
              Enviar código
            </Button>
          </ModalBody>
        </Panel>
      </ModalPanel>
    )
  }

  return (
    <ModalPanel>
      <Panel>
        <PhoneImage src='/images/Grafico celular código.png' />
        <ModalBody  style={{ maxWidth: 350 }}>
          <Typography  center mb={5} size='1em'>Si no recibes el código de mensaje de texto contáctanos haciendo clic en el botón de ayuda.</Typography>
          <Typography  center mb={5} size='1em'>El código que recibas únicamente será valido por los próximos 15 minutos</Typography>
          <VerificationInput
            placeholder=''
            validChars='0-9'
            inputProps={{ type: 'tel' }}
            length={5}
            autoFocus
            value={state.verificationCode.join('')}
            onChange={handleChangeCode}
            classNames={{
              container: 'sms-container',
              character: 'character',
              characterInactive: 'character--inactive',
              characterSelected: 'character--selected'
            }}
          />
          <HelpText>Escribe el código usando las casillas</HelpText>
          <Button
            loading={state.submitting}
            onClick={() => submitVerificationCode(state.verificationCode)}
            disabled={state.submitting || !isVerificationCodeOK}
            bgColor={isVerificationCodeOK ? '#88ba44' : '#787878'}
          >
            Verificar
          </Button>
          {state.errorMessage && <HelpText style={{ color: 'red' }}>{state.errorMessage}</HelpText>}
          <Button
            bgColor={countDown.isFinished ? '#88ba44' : '#787878'}
            loading={state.resendingCode}
            disabled={!countDown.isFinished || state.resendingCode}
            onClick={() => requestSMSCode('resend')}
          >
            <div>Reenviar Codigo</div>
          </Button>
          <Button
            bgColor='#88ba44'
            onClick={() => window.open('https://wa.me/5215511866442?text=Tengo%20problemas%20para%20verificar', '_blank')}
          >
            <div>Ayuda</div>
          </Button>
          {!countDown.isFinished && (
            <TextCountDown>{countDown.leftTime}</TextCountDown>
          )}
          {state.codeResended && (
            <div className='flex-container justify align column text-center'>
              <i className='ion-checkmark-circled success' />
              <strong>Tu código ha sido reenviado.</strong>
            </div>
          )}
        </ModalBody>
      </Panel>
    </ModalPanel>
  )
}


const Panel = styled.div`
  display: flex;
  gap: 1em;
  margin: auto 0;
  width: 100%;
  max-width: 700px;
  padding: 1em;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const HelpText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 1em;
  margin-bottom: 2em;
  line-height: 1.5;
`

const PhoneImage = styled.img`
  max-width: 220px;
`

const TextCountDown = styled.div`
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
`
