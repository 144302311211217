import React, { useRef, useLayoutEffect } from 'react'

// Components
import { Typography, Box, InputCurrency, InputNumber } from '../../../Xmerald-UI'
import { useWizardPrivate } from '../wizardPrivate.store'
import WizardLayout from '../shared/Layout'

export default function QuinielaPrice () {
  const priceRef = useRef()
  const step = useWizardPrivate(state => state.step)
  const quinielaData = useWizardPrivate(state => state.quinielaData)
  const setQuinielaData = useWizardPrivate(state => state.setQuinielaData)

  useLayoutEffect(() => {
    priceRef.current?.focus()
  }, [priceRef, step])

  if (step !== 'QUINIELA_PRICE') return null

  const disabledNext = (
    !quinielaData.entry_fee ||
    quinielaData.entry_fee < 0 ||
    quinielaData.affiliate_commission === null ||
    Number(quinielaData.affiliate_commission) > 15 ||
    Number(quinielaData.affiliate_commission) < 0
  )

  const getFeeError = () => {
    if (Number(quinielaData.entry_fee) < 0) {
      return 'No puede ser un precio negativo'
    }
  }

  const getComisionError = () => {
    if (Number(quinielaData.affiliate_commission) < 0) {
      return 'No puede ser menor a 0%'
    }
    if (Number(quinielaData.affiliate_commission) > 15) {
      return 'No puede ser mayor a 15%'
    }
  }

  const comisionError = getComisionError()

  return (
    <WizardLayout
      prevStep='QUINIELA_DURATION'
      nextStep='QUINIELA_AWARDS'
      disabledNext={disabledNext}
    >
      <Box>
        <InputCurrency
          label='Elige el precio de tú quiniela.'
          name='entry_fee'
          prefix='$ '
          placeholder='$ 1000.00'
          value={quinielaData.entry_fee}
          onChange={setQuinielaData}
          maxLength={5}
          maxWidth={350}
          ref={priceRef}
          error={getFeeError()}
          helpText={getFeeError()}
        />

        <InputNumber
          label='¿Cuánto quieres llevarte de comisión?'
          name='affiliate_commission'
          placeholder='15 %'
          suffix=' %'
          value={quinielaData.affiliate_commission}
          error={comisionError}
          helpText={comisionError}
          min={0}
          max={15}
          maxWidth={90}
          onChange={setQuinielaData}
        />

        <Typography center color='#a0a0a0' size='0.9em'>Tu comisión puede ser de 0 a 15%, Pickwin reservará 2% de comisión adicional para operación.</Typography>
      </Box>
    </WizardLayout>
  )
}
