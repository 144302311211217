import React, { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'
import CurrencyInput from 'react-currency-input-field'

// Components
import Iconify from '../../Iconify'
import Ripple from '../../Ripple/Ripple'

const InputNumber = forwardRef((props, ref) => {
  const handleChange = (value, name) => {
    props.onChange({ [name]: value })
  }

  const id = `${props.name}-id`

  const increment = () => {
    const nextValue = Number(props.value || 0) + 1

    if (nextValue <= props.max) {
      props.onChange({ [props.name]: nextValue })
    }
  }

  const decrement = () => {
    const nextValue = Number(props.value || 0) - 1
    if (nextValue >= props.min) {
      props.onChange({ [props.name]: nextValue })
    }
  }

  return (
    <InputBox>
      <Label htmlFor={id}>{props.label}</Label>
      <InputContainer style={{ maxWidth: props.maxWidth }}>
        <InputBase
          id={id}
          name={props.name}
          prefix={props.prefix}
          suffix={props.suffix}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          decimalsLimit={props.decimalsLimit}
          value={props.value}
          onValueChange={handleChange}
          maxLength={props.maxLength}
          type={props.type}
          ref={ref}
        />
        <Handlers>
          <Handler onClick={increment}>
            <Iconify icon='vaadin:plus' />
            <Ripple />
          </Handler>
          <Handler onClick={decrement}>
            <Iconify icon='tabler:minus' />
            <Ripple />
          </Handler>
        </Handlers>
      </InputContainer>

      {props.helpText && (
        <HelpText style={{ color: props.error ? 'orangered' : '#2ea12a' }}>
          {props.helpText}
        </HelpText>
      )}
    </InputBox>
  )
})

InputNumber.defaultProps = {
  decimalsLimit: 2,
  placeholder: '',
  prefix: ' ',
  type: 'text',
  min: 0,
  max: Infinity
}

export default InputNumber

const HelpText = styled.div`
  font-size: 1em;
  color: orangered;
  text-align: center;
  line-height: 2;
`

const InputBox = styled.div`
  width: 100%;
  margin-bottom: 1em;
`

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.6) translateY(-50%);
  }

  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
`

const Handlers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  line-height: 1;
`

const Handler = styled.button`
  background: #78bc28;
  color: #FFF;
  font-size: 0.7em;
  border: 0;
  outline: 0;
  height: 50%;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  width: 2.5em;
  border-radius: 0 0.3em 0 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-radius: 0 0 0.3em 0;
  }
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
`

export const InputBase = styled(CurrencyInput)`
  width: 100%;
  max-width: 150px;
  padding: 0 0.5em;
  border-radius: 0.3em;
  color: #000;
  outline: 0;
  border: 0;
  box-shadow: 0px 0px 0px 0px #6abd0b00;
  transition: box-shadow 150ms linear;
  will-change: box-shadow;
  line-height: 2.3;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c9c9c9;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #c9c9c9;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #c9c9c9;
  }
  
  &:focus {
    box-shadow: ${p => p.$hasError ? '0px 0px 1px 4px #eb2943' : '0px 0px 1px 4px #78bc28'};
  }
`

const Label = styled.label`
  line-height: 2.5;
  font-size: 1.1em;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
