import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/index';
import utils from '../../utils/utils';

class ErrorComponent extends React.Component {
  render() {
    return (
      <div className="page-wrapper">
        <div className="inner-wrapper">
          <NavigationBar
            logged={utils.isLogged()}
            userData={utils.getUserInfo()}
          />
          <div className="container">Error</div>
          <div id="push" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default ErrorComponent;
