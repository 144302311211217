import React from 'react';
import premios from '../../assets/images/premiosIconBlanco-50.png';
import billete from '../../assets/images/cash1-50.png';
import pickcoin from '../../assets/images/pickcoin1-50.png';
import ticketsIcon from '../../assets/images/silver_ticket_pick_empty.png';
import reaglo from '../../assets/images/gift-50.png';
import numeral from 'numeral';
import utils from '../../utils/utils';
import { Nav, NavItem } from 'react-bootstrap/lib/';

import { ISRTooltip } from '../TablaPosiciones/TablaPosiciones';
import $ from 'jquery';

/**
 * This component renders the weekly_prizes if available
 * return { Component }
 */
const PremiosSemanales = ({
  available,
  acronymPrize,
  entryCurrency,
  weeklyPrizes,
  weeklyPrizeScheme,
  weeklyPercentage,
  currencyImage,
  type,
  weeks,
}) => {
  let weeklyPrizeNum = ((available / 100) * (weeklyPercentage / 10000)) / weeks.weeks.length;
  let prizeFormat = '$0,000.00';
  let weeklyPrize = numeral(weeklyPrizeNum).format(prizeFormat);

  let weeklyPrizesList = null;

  if (weeklyPrizeScheme === 'first') {
    weeklyPrize = numeral(weeklyPrizes[0]._amount / 100).format(prizeFormat);

    weeklyPrizesList = (
      <div className={'even-colored-row premios-item row-center flex-row'}>
        <div className="flex-col col-40 ">
          <span className="bolsa-img">
            1° <small>Lugar</small>
          </span>
          <small>100.00%</small>
        </div>
        <div className="flex-col">
          <div className="bolsa-gral text-right flex-container end align flex-row ">
            <ISRTooltip
              availablePrize={numeral(weeklyPrizes[0]._amount / 100).format('$0,000.0')}
              tax={weeklyPrizes[0].tax}
              moneyFormat={'$0,000.00'}
            />
            <span className="bolsa-img">
              <img src={currencyImage} className="score-icons" />
            </span>
            <span className={entryCurrency === 'pickcoin' ? 'cant-premio pickcoin' : 'cant-premio'}>
              {numeral(weeklyPrizes[0].amount / 100).format(prizeFormat)}
              <small>{entryCurrency === 'pickcoin' ? 'PC' : 'MXN'}</small>
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    weeklyPrizesList = (
      <div className="list-premios">
        <div className={'even-colored-row premios-item row-center flex-row'}>
          <div className="flex-col col-40 ">
            <span className="bolsa-img">
              1° <small>Lugar</small>
            </span>
            <small> 50.00%</small>
          </div>
          <div className="flex-col col-no-padding ">
            <div className="bolsa-gral flex-container align end flex-row text-right">
              <ISRTooltip
                availablePrize={weeklyPrizes[0]._amount / 100}
                tax={weeklyPrizes[0].tax}
                moneyFormat={'$0,000.00'}
              />
              <span className="bolsa-img">
                <img src={currencyImage} className="score-icons" />
              </span>
              <span
                className={entryCurrency === 'pickcoin' ? 'cant-premio pickcoin' : 'cant-premio'}
              >
                {numeral(weeklyPrizes[0].amount / 100).format('$0,000.00')}
                <small>{entryCurrency === 'pickcoin' ? 'PC' : 'MXN'}</small>
              </span>
            </div>
          </div>
        </div>
        <div className={' premios-item flex-row'}>
          <div className="flex-col col-40 ">
            <span className="bolsa-img">
              2° <small>Lugar</small>
            </span>
            <small> 30.00%</small>
          </div>
          <div className="flex-col col-no-padding">
            <div className="bolsa-gral flex-container align end flex-row text-right">
              <ISRTooltip
                availablePrize={weeklyPrizes[1]._amount / 100}
                tax={weeklyPrizes[1].tax}
                moneyFormat={'$0,000.00'}
              />
              <span className="bolsa-img">
                <img src={currencyImage} className="score-icons" />
              </span>
              <span
                className={entryCurrency === 'pickcoin' ? 'cant-premio pickcoin' : 'cant-premio'}
              >
                {numeral(weeklyPrizes[1].amount / 100).format('$0,000.00')}
                <small>{entryCurrency === 'pickcoin' ? 'PC' : 'MXN'}</small>
              </span>
            </div>
          </div>
        </div>
        <div className={'even-colored-row premios-item row-center flex-row'}>
          <div className="flex-col col-40 ">
            <span className="bolsa-img">
              3° <small>Lugar</small>
            </span>
            <small> 20.00%</small>
          </div>
          <div className="flex-col col-no-padding ">
            <div className="bolsa-gral flex-container align end flex-row text-right">
              <ISRTooltip
                availablePrize={weeklyPrizes[2]._amount / 100}
                tax={weeklyPrizes[2].tax}
                moneyFormat={'$0,000.00'}
              />
              <span className="bolsa-img">
                <img src={currencyImage} className="score-icons" />
              </span>
              <span
                className={entryCurrency === 'pickcoin' ? 'cant-premio pickcoin' : 'cant-premio'}
              >
                {numeral(weeklyPrizes[2].amount / 100).format('$0,000.00')}
                <small>{entryCurrency === 'pickcoin' ? 'PC' : 'MXN'}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-row no-row-padding">
      <div className="flex-col col-no-padding">
        <div className=" title-quiniela">
          <strong
            className="pull-right cantidad-bolsa"
            style={
              entryCurrency === 'pickcoin'
                ? { color: '#F9CC30' }
                : entryCurrency === 'real'
                ? { color: '#78BC28' }
                : null
            }
          >
            <img src={currencyImage} className="score-icons" />
            {weeklyPrizeScheme === 'first'
              ? numeral(weeklyPrizes[0]._amount / 100).format(prizeFormat)
              : numeral(
                  (weeklyPrizes[0]._amount + weeklyPrizes[1]._amount + weeklyPrizes[2]._amount) /
                    100
                ).format(prizeFormat)}
            <small>{acronymPrize}</small>
          </strong>
          Por semana
        </div>
        <div
          className={` overflow overflow-y ${type === 'popup' ? 'overflow-360 ' : 'overflow-200 '}`}
        >
          <div className="lista-premios ">{weeklyPrizesList}</div>
        </div>
      </div>
    </div>
  );
};

const ControlWeekPrizes = ({ whichList, changePrizesList }) => {
  return (
    <Nav
      bsStyle="pills"
      className="flex-row row-no-padding tablas-weekley"
      activeKey={whichList}
      onSelect={changePrizesList}
    >
      <NavItem eventKey={1}>Finales</NavItem>
      <NavItem eventKey={2}>Semanales</NavItem>
    </Nav>
  );
};

const PremiosAmount = ({ isFull, premio, prizeCurrency, prizeFormat, availableFull }) => {
  let conditionalComponent = null;
  let currencyAcronym = null;
  if (prizeCurrency === 'pickcoin') {
    currencyAcronym = <small> PC </small>;
  } else if (prizeCurrency === 'real') {
    currencyAcronym = <small> MXN </small>;
  }

  switch (premio.type) {
    case 'gift':
      conditionalComponent = (
        <span className={`cant-premio desc-premio`}>{premio.description}</span>
      );
      break;
    case 'ticket':
      conditionalComponent = (
        <span className={`cant-premio desc-premio `}>{premio.description}</span>
      );
      break;
    case 'percentage':
      if (isFull) {
        conditionalComponent = (
          <span className={`cant-premio `}>
            {numeral(((availableFull / 100) * premio.percentage) / 10000).format(prizeFormat)}{' '}
            {currencyAcronym}
          </span>
        );
      } else {
        conditionalComponent = (
          <span className={`cant-premio `}>
            {numeral(premio.amount / 100).format(prizeFormat)} {currencyAcronym}
          </span>
        );
      }
      break;
    default:
      conditionalComponent = (
        <span className={`cant-premio ${prizeCurrency === 'pickcoin' ? 'pickcoin' : ''} `}>
          {numeral(premio.amount / 100).format(prizeFormat)} {currencyAcronym}
        </span>
      );
      break;
  }

  return conditionalComponent;
};

//console.log("POOLS:",pools);
class Premios extends React.Component {
  constructor(props) {
    super(props);

    console.dir(props);
    // take into account weekly prizes
    let gameInfo,
      listPremios = null;

    if (this.props.poolInfo.hasOwnProperty('pool')) {
      gameInfo = this.props.poolInfo.pool;
      listPremios = this.props.premios.pool_prizes[0].prizes;
    } else if (this.props.poolInfo.hasOwnProperty('group_pool')) {
      gameInfo = this.props.poolInfo.group_pool;
      listPremios = this.props.premios;
    } else {
      gameInfo = this.props.poolInfo;
      listPremios = this.props.premios;
    }

    // console.log( this.props.poolInfo);
    console.log(gameInfo);
    let available = gameInfo.available;

    if (gameInfo.game_type === 'pool' && gameInfo.type === 'full' && gameInfo.weekly_prize === 1) {
      let endPercentage = gameInfo.end_percentage / 10000;
      available = gameInfo.available * endPercentage;
    }

    this.state = {
      mounted: false,
      usrData: null,
      gameInfo: gameInfo,
      listPremios: listPremios,
      type: this.props.type,
      available: available,
      showPrizesList: 1,
    };

    this.rowRenderer = this.rowRenderer.bind(this);

    this.changePrizesList = (eventKey) => {
      console.log('ChangeList');
      this.setState((prevState) => {
        return { showPrizesList: eventKey };
      });
    };

    this.getUpperSpace = () => {
      // console.log('getupper')
      const premiosListOverflow = $('.premios-list .overflow');
      const listaPremios = $('.lista-premios ');
      // console.log(premiosListOverflow, listaPremios);
      if (premiosListOverflow.length > 0 && listaPremios.length > 0) {
        // console.log('Resize Tabla');
        // console.log(premiosListOverflow[0].offsetHeight, listaPremios[0]);
        listaPremios[0].style.height = premiosListOverflow[0].offsetHeight + 'px';
      }
    };

    // console.log('Premios');
  }

  rowRenderer() {
    if (
      this.state.listPremios === undefined ||
      this.state.listPremios === null ||
      this.state.listPremios.length === 0
    ) {
      console.log('escape');
      return;
    }
    let prizeFormat = null;
    let currencyImage = null;

    switch (this.state.gameInfo.prize_currency) {
      case 'ticket':
        prizeFormat = '0';
        currencyImage = ticketsIcon;
        break;
      case 'real':
        prizeFormat = '$0,000.00';
        currencyImage = billete;
        break;
      case 'pickcoin':
        prizeFormat = '0,000';
        currencyImage = pickcoin;
        break;
      default:
    }

    let perfectPool =
      this.state.gameInfo.perfect_prize && this.state.gameInfo.perfect_prize !== null
        ? {
            _amount: this.state.gameInfo.perfect_prize,
            _percentage: 0,
            amount: this.state.gameInfo.perfect_real_amount,
            percentage: 0,
            position: 0,
            refund: 0,
            tax: this.state.gameInfo.perfect_tax,
            type: 'percentage',
          }
        : undefined;

    let listPremios = perfectPool
      ? [perfectPool, ...this.state.listPremios]
      : [...this.state.listPremios];

    let content = listPremios.map((premio, idx) => {
      let percentagePrize = premio._percentage / 10000;
      return (
        <div
          key={idx}
          className={
            idx % 2 === 0
              ? 'even-colored-row premios-item row-center flex-row'
              : 'premios-item flex-row'
          }
        >
          {premio.position === 0 ? (
            <div className="flex-col col-40 ">
              <span className="bolsa-img">
                <small>QUINIELA PERFECTA</small>
              </span>
            </div>
          ) : (
            <div className="flex-col col-40 ">
              <span className="bolsa-img">
                {premio.position}° <small>Lugar</small>
              </span>
              {premio.percentage === null ? null : (
                <small> {numeral(percentagePrize).format('0.00%')}</small>
              )}
            </div>
          )}
          <div className="flex-col col-no-padding ">
            <div
              className={`
                ${
                  premio.type === 'gift'
                    ? 'bolsa-gral'
                    : premio.type === 'ticket'
                    ? 'bolsa-gral text-right ticket'
                    : 'bolsa-gral text-right'
                }
                flex-container end align flex-row
              `}
            >
              {premio.type === 'ticket' || this.state.gameInfo.prize_currency !== 'real' ? null : (
                <ISRTooltip
                  tax={premio.tax}
                  availablePrize={numeral(premio._amount / 100).format(prizeFormat)}
                  moneyFormat={prizeFormat}
                />
              )}
              <span className="bolsa-img">
                {premio.type !== 'gift' ? (
                  <img src={currencyImage} className="score-icons" />
                ) : null}
              </span>
              <PremiosAmount
                availableFull={this.state.gameInfo.available_end}
                isFull={this.state.gameInfo.type === 'full'}
                premio={premio}
                prizeCurrency={this.state.gameInfo.prize_currency}
                prizeFormat={prizeFormat}
                available={this.state.available}
              />
            </div>
          </div>
        </div>
      );
    });

    return content;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.loaded) {
      return;
    }

    let gameInfo,
      prevGameInfo,
      listPremios,
      weeklyPrizes = null;
    let available = null;
    // console.log( this.props, this.props.premios);
    if (this.props.poolInfo.hasOwnProperty('pool')) {
      gameInfo = this.props.poolInfo.pool;
      listPremios = this.props.premios.pool_prizes[0].prizes;
      weeklyPrizes = this.props.premios.weekly;
    } else if (this.props.poolInfo.hasOwnProperty('group_pool')) {
      // console.log('isPoolGroup');
      // console.log(this.props.premios[0]);
      gameInfo = this.props.poolInfo.group_pool;
      listPremios = this.props.premios;
      weeklyPrizes = this.props.premios.weekly;
    } else {
      gameInfo = this.props.poolInfo.survivor;
      listPremios = this.props.premios;
      weeklyPrizes = this.props.premios.weekly;
    }

    if (prevProps.poolInfo.hasOwnProperty('pool')) {
      gameInfo = prevProps.poolInfo.pool;
    } else if (prevProps.poolInfo.hasOwnProperty('group_pool')) {
      // console.log('groupPools');
      gameInfo = prevProps.poolInfo.group_pool;
      if (prevState.available !== gameInfo.available) {
        this.setState({
          listPremios: listPremios,
          gameInfo: gameInfo,
          available: gameInfo.available,
          weeklyPrizes: weeklyPrizes,
        });
      }
    } else {
      gameInfo = prevProps.poolInfo;
    }

    // console.log( gameInfo );

    if (gameInfo.game_type === 'pool' && gameInfo.type === 'full' && gameInfo.weekly_prize === 1) {
      let endPercentage = gameInfo.end_percentage / 10000;
      available = gameInfo.available * endPercentage;
    }
    // console.log(

    if (gameInfo && prevGameInfo && gameInfo.available !== prevGameInfo.available) {
      // console.log(gameInfo);
      // console.log(listPremios);
      // console.log('updatePrizes');
      this.setState({
        listPremios: listPremios,
        gameInfo: gameInfo,
        available: gameInfo.available,
        weeklyPrizes: weeklyPrizes,
      });
    }
  }

  componentDidMount() {
    this.setState(
      () => {
        return {
          mounted: true,
          userData: utils.getUserInfo(),
          logged: utils.isLogged(),
        };
      },
      () => {
        setTimeout(() => {
          this.getUpperSpace();
        }, 150);
      }
    );
  }

  render() {
    let moneyFormat = null;
    let currencyImage = null;
    let acronymPrize = null;
    let availablePrize = this.state.gameInfo.available / 100;
    let availablePrizeWeekly = this.state.gameInfo.available_end / 100;
    let availablePerWeekPrize = this.state.gameInfo.available_per_week / 100;
    let availableWeeksPrize = this.state.gameInfo.available_weeks / 100;
    switch (this.state.gameInfo.prize_currency) {
      case 'ticket':
        availablePrize = this.state.gameInfo.prize_count;
        moneyFormat = '0';
        acronymPrize = '';
        currencyImage = ticketsIcon;
        break;
      case 'gift':
        availablePrize = this.state.gameInfo.prize_count;
        moneyFormat = '0';
        acronymPrize = '';
        currencyImage = reaglo;
        break;
      case 'real':
        moneyFormat = '$0,000.00';
        if (this.state.gameInfo.type === 'full' && this.state.gameInfo.weekly_prize) {
          // console.log('avPrize', availablePrize);
          // availablePrize = availablePrize * ( this.state.gameInfo.end_percentage / 10000);
        }

        availablePrizeWeekly = numeral(availablePrizeWeekly).format(moneyFormat);
        availableWeeksPrize = numeral(availableWeeksPrize).format(moneyFormat);
        availablePerWeekPrize = numeral(availablePerWeekPrize).format(moneyFormat);
        availablePrize = numeral(availablePrize).format(moneyFormat);

        acronymPrize = 'MX';
        currencyImage = billete;
        break;
      case 'pickcoin':
        acronymPrize = 'PC';
        moneyFormat = '0,000';

        availablePrizeWeekly = numeral(availablePrizeWeekly).format(moneyFormat);
        availableWeeksPrize = numeral(availableWeeksPrize).format(moneyFormat);
        availablePerWeekPrize = numeral(availablePerWeekPrize).format(moneyFormat);
        availablePrize = numeral(availablePrize).format(moneyFormat);

        currencyImage = pickcoin;
        break;
      default:
    }

    return this.state.mounted ? (
      <div className="premios-list lobby border-gradient">
        <div className="flex-col col-no-padding">
          <div className=" flex-row row-no-padding title-quiniela">
            <div className="title-wrapper">
              <img src={premios} className="pool-icons tablas" />
              <span className='font-chivo'>Premios</span>
            </div>
          </div>
          {this.state.gameInfo.weekly_prize ? (
            <div className="flex-row justify-content-center align-items-center">
              <div className="flex-col col-95">
                <ControlWeekPrizes
                  whichList={this.state.showPrizesList}
                  changePrizesList={this.changePrizesList}
                />
              </div>
            </div>
          ) : null}
          {this.state.showPrizesList === 2 ? (
            <PremiosSemanales
              type={this.props.type}
              available={this.state.gameInfo.available}
              acronymPrize={acronymPrize}
              weeklyPrizes={this.props.premios.weekly}
              currencyImage={currencyImage}
              entryCurrency={this.state.gameInfo.entry_currency}
              weeklyPrizeScheme={this.state.gameInfo.weekly_prize_scheme}
              weeklyPercentage={this.state.gameInfo.weekly_percentage}
              weeks={this.props.weeks}
            />
          ) : (
            <div className="flex-row row-no-padding">
              <div className="flex-col col-no-padding">
                <div className=" flex-row row-center ">
                  {this.state.gameInfo.type === 'full' && this.state.gameInfo.weekly_prize ? (
                    <React.Fragment>
                      <div className="flex-col ">
                        <div className="flex-row uppercase">
                          <span className="flex-col text-center font-chivo">Al terminar</span>
                        </div>
                        <div className="flex-row">
                          <span
                            className="cantidad-bolsa flex-container justify align "
                            style={
                              this.state.gameInfo.prize_currency === 'pickcoin'
                                ? { color: '#F9CC30' }
                                : this.state.gameInfo.prize_currency === 'real'
                                ? { color: '#78BC28' }
                                : null
                            }
                          >
                            <img src={currencyImage} className="score-icons" />
                            {availablePrizeWeekly}
                            <small>{acronymPrize}</small>
                          </span>
                        </div>
                      </div>
                      <div className="flex-col">
                        <div className="flex-row uppercase">
                          <span className="flex-col text-center font-chivo">Semanales</span>
                        </div>
                        <div className="flex-row">
                          <span
                            className="cantidad-bolsa flex-container justify align "
                            style={
                              this.state.gameInfo.prize_currency === 'pickcoin'
                                ? { color: '#F9CC30' }
                                : this.state.gameInfo.prize_currency === 'real'
                                ? { color: '#78BC28' }
                                : null
                            }
                          >
                            <img src={currencyImage} className="score-icons" />
                            {availableWeeksPrize}
                            <small>{acronymPrize}</small>
                          </span>
                        </div>
                      </div>
                      <div className="flex-col">
                        <div className="flex-row uppercase">
                          <span className="flex-col text-center font-chivo">Por Semana</span>
                        </div>
                        <div className="flex-row">
                          <span
                            className="cantidad-bolsa flex-container justify align "
                            style={
                              this.state.gameInfo.prize_currency === 'pickcoin'
                                ? { color: '#F9CC30' }
                                : this.state.gameInfo.prize_currency === 'real'
                                ? { color: '#78BC28' }
                                : null
                            }
                          >
                            <img src={currencyImage} className="score-icons" />
                            {availablePerWeekPrize}
                            <small>{acronymPrize}</small>
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="flex-col">
                      <div className="flex-row row-center">
                        <div className="flex-col col-no-padding uppercase font-chivo">Al terminar</div>
                        <strong
                          className="cantidad-bolsa flex-col col-no-padding"
                          style={
                            this.state.gameInfo.prize_currency === 'pickcoin'
                              ? { color: '#F9CC30' }
                              : this.state.gameInfo.prize_currency === 'real'
                              ? { color: '#78BC28' }
                              : null
                          }
                        >
                          <div className="flex-container align end">
                            <img src={currencyImage} className="score-icons" />
                            {availablePrize}
                            <small>{acronymPrize}</small>
                          </div>
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
                {this.props.game && this.props.game === "series" &&
                  <div>
                    <div className="lista-premios popup">{this.rowRenderer()}</div>
                  </div>
                ||
                  <div className={` overflow overflow-y ${this.props.type === 'popup' ? 'overflow-360 ' : 'overflow-200 '}`}>
                    <div className="lista-premios popup">{this.rowRenderer()}</div>
                  </div>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div id="loading">Cargando...</div>
    );
  }
}

export default Premios;
