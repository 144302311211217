import React from 'react'

// Assets
import pdf from '../../../../../assets/images/pdf.png'

export default function MyDocuments (props) {
  let listaDocumentos
  if (props.documents !== null) {
    listaDocumentos = props.documents.documents
    listaDocumentos = listaDocumentos.map((documento) => {
      return (
        <div key={documento.id + documento.size} className='mis-documentos-item'>
          <img src={pdf} className='img-responsive' alt='' />
          <span>{documento.type}</span>
        </div>
      )
    })
  }
  return <div className='mis-docs-container'>{listaDocumentos}</div>
}
