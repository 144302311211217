import React from 'react';
import { DropdownButton, Checkbox, ToggleButtonGroup, ToggleButton } from 'react-bootstrap/lib/';
import ReactTooltip from 'react-tooltip';
import MediaQuery from 'react-responsive';
import API from '../../utils/api';
import "./index.scss";

import utils from '../../utils/utils';
import _ from 'underscore-contrib';

import baseball from '../../assets/images/BaseballBall-50.png';
import americano from '../../assets/images/AmericanoBall-50.png';
import futbol from '../../assets/images/FutbolBall-50.png';
import basketball from '../../assets/images/BasketballBall-50.png';
import tenis from '../../assets/images/TennisBall-50.png';
import golf from '../../assets/images/GolfBall-50.png';
import F1 from '../../assets/images/Volante-50.png';
import hockey from '../../assets/images/Hockey.png';
import box from '../../assets/images/box.png';
import cash1 from '../../assets/images/cash1-50.png';
import cash2 from '../../assets/images/cash2-50.png';
import cash3 from '../../assets/images/cash3-50.png';
import pickcoins1 from '../../assets/images/pickcoin1-50.png';
import pickcoins1Plata from '../../assets/images/pickcoin1-50-plata.png';
import pickcoins2 from '../../assets/images/pickcoin2-50.png';
import pickcoins2Plata from '../../assets/images/pickcoin2-50-plata.png';
import pickcoins3 from '../../assets/images/pickcoin3-50.png';
import pickcoins3Plata from '../../assets/images/pickcoin3-50-plata.png';
import iconQuiniela from '../../assets/images/icon-quiniela.png';
import iconSurvivor from '../../assets/images/icon-survivor.png';
import iconCl from '../../assets/images/icon-cl.png';
import iconMax10 from '../../assets/images/icon-max10.png';
import iconParlay from '../../assets/images/icon-parlay.png';
import iconMax from '../../assets/images/icon-max.png';
import iconPick5 from '../../assets/images/icon-pick5.png';
import iconH2h from '../../assets/images/icon-1vs1.png';
import bracketIcon from '../../assets/images/type_game/bracket-type.png';

// import ListLeagues from '../ListLeagues';

const history = require('history').createBrowserHistory();

let sportsList = [];

const MontosButtons = ({
  isPickcoinsActive,
  isPickcoinsPlataActive,
  filtersMontosValue,
  handleFilterClick,
}) => {
  let conditionalComponent = null;

  conditionalComponent = (
    <ToggleButtonGroup
      name="monto"
      value={filtersMontosValue}
      type="checkbox"
      onChange={(e) => {
        handleFilterClick('buyIn', e);
      }}
    >
      <ToggleButton value={'all'} className="itemFilter">
        <span className="monto-item monto-all">TODAS</span>
      </ToggleButton>
      <ToggleButton value={'low'} data-tip data-for="low-qnty" className="itemFilter">
        <span span className={'cash-qnty'}>
          <div className="concurso-item concurso-all">
            <img
              src={cash1}
              className="pool-icons filter"
              style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '4px' }}
            />
            <div className="subtitle">$1 a $49</div>
          </div>
        </span>
        <ReactTooltip place="top" type="light" id="low-qnty" effect="solid">
          de $1 a $49
        </ReactTooltip>
      </ToggleButton>
      <ToggleButton value={'medium'} data-tip data-for="med-qnty" className="itemFilter">
        <span className={'cash-qnty'}>
          <div className="concurso-item concurso-all">
            <img
              src={cash2}
              className="pool-icons filter"
              style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '2px' }}
            />
            <div className="subtitle">$50 a $251</div>
          </div>
        </span>
        <ReactTooltip place="top" type="light" id="med-qnty" effect="solid">
          de $50 a $251
        </ReactTooltip>
      </ToggleButton>
      <ToggleButton value={'high'} data-tip data-for="high-qnty" className="itemFilter">
        <span className={'cash-qnty'}>
          <div className="concurso-item concurso-all">
            <img
              src={cash3}
              className="pool-icons filter"
              style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '2px' }}
            />
            <div className="subtitle">+ de $251</div>
          </div>
        </span>
        <ReactTooltip place="top" type="light" id="high-qnty" effect="solid">
          + de $251
        </ReactTooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );

  return conditionalComponent;
};

class AccummulativeFilters extends React.Component {
  constructor(props) {
    super(props);

    const allowedFilters = [
      'buyIn',
      'currency',
      'gameType',
      'isFull',
      'isPrivate',
      'league',
      'sports',
      'status',
      'type'
    ];

    let storedFilters = utils.getFilters();

    let filtersModel = {
      sports: ['all'],
      gameType: ['all'],
      currency: ['real', 'pickcoin'],
      buyIn: ['all'],
      type: ['all'],
      league: ['all'],
      status: ['proximas'],
      isFull: [false],
      isPrivate: [false],
      contestMenu: false,
      coinMenu: false,
      buyInMenu: false,
      typeMenu: false,
    };

    if (storedFilters !== null) {
      filtersModel = { ...filtersModel, ...storedFilters };
    }

    this.state = {
      filtersModel: filtersModel,
      ...this.props,
      openDrop: false,
    };

    let sc;

    this.checkSportsAvailability = this.checkSportsAvailability.bind(this);
    this.handleClickDropdown = this.handleClickDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);

    this.handleFilterClick = (clickedCategory, value) => {
      let modFilters = this.state.filtersModel;
      let stateContainAll = false;
      let leagueContainAll = false;
      let valueContainAll = false;
      let newValues = [null];

      switch (clickedCategory) {
        case 'gameType':
          modFilters.gameType = [value];
          break;
        case 'currency':
          modFilters.currency.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });

          if (stateContainAll && valueContainAll) {
            newValues = _.without(value, 'all');
            modFilters.currency = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.currency = ['all'];
          } else if (value.length === 0) {
          } else {
            modFilters.currency = value;
          }
          break;
        case 'buyIn':
          modFilters.buyIn.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.buyIn = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.buyIn = ['all'];
          } else if (value.length === 0) {
            //modFilters.buyIn = modFilters.buyIn;
          } else {
            modFilters.buyIn = value;
          }
          break;
        case 'type':
          modFilters.type.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.type = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.type = ['all'];
          } else if (value.length === 0) {
            //modFilters.type = modFilters.type;
          } else {
            modFilters.type = value;
          }
          break;
        case 'sports':
          modFilters.sports.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.sports = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.sports = ['all'];
          } else if (value.length === 0) {
            //modFilters.sports = modFilters.sports;
          } else {
            modFilters.sports = value;
          }
          break;
        case 'league':
          modFilters.league.forEach((filter) => {
            if (filter === 'all') {
              leagueContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });

          if (leagueContainAll && valueContainAll) {
            newValues = _.without(value, 'all');
            modFilters.league = newValues;
          } else if (valueContainAll && !leagueContainAll) {
            modFilters.league = ['all'];
          } else if (value.length === 0) {
            //modFilters.league = modFilters.league;
          } else {
            modFilters.league = value;
          }
          break;
        case 'isFull':
          // console.log( modFilters.isFull );
          modFilters.isFull = [!modFilters.isFull[0]];
          break;
        case 'isPrivate':
          modFilters.isPrivate = [value];
          break;
        case 'status':
          modFilters.status.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.status = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.status = ['all'];
          } else if (value.length === 0) {
            //modFilters.status = modFilters.status;
          } else {
            modFilters.status = value;
          }
          break;
      }

      // eliminate all default values
      let urlFilters = _.chain(modFilters)
        .pick(allowedFilters)
        .pairs()
        .filter((pair) => {
          if (_.isBoolean(pair[1]) && pair[1] === false) {
            return false;
          }
          if (_.isArray(pair[1]) && (
            _.contains(pair[1], 'all')
            ||
            _.contains(pair[1], 'proximas')
            ||
            _.contains(pair[1], false)
          )
          ) {
            return false;
          }
          return true;
        })
        .object()
        .value();

      if (!_.isEmpty(urlFilters)) {
        localStorage.setItem('queryStringFilters', JSON.stringify(urlFilters));
        history.replace({
          pathname: '/lobby',
          search: '?filters=1&' + _.toQuery(urlFilters),
        });
      } else {
        localStorage.setItem('queryStringFilters', '{}');
        history.replace({
          pathname: '/lobby',
          search: null,
        });
      }


      this.setState({ filtersModel: modFilters }, () => {
        this.props.filterBar(this.state.filtersModel);
        this.props.updateMonto(this.state.filtersModel);
      });

    };
  }

  closeDropdown() {
    // console.log(e.target);
    // let drop = $('.misRegistros .dropdown');
    // let dropButton = $('#dropdown-registros-multiples');
    // drop !== undefined ? drop.removeClass('open'): null;
    // dropButton !== undefined ? dropButton.attr('aria-expanded', 'false'): null;
    setTimeout(() => {
      this.setState({ openDrop: false });
    }, 150);
  }

  checkSportsAvailability(which) {
    let any = this.props.pools.filter((pool) => {
      if (pool.sport_id === which) {
        return pool;
      }
    });

    if (any.length > 0) {
      sportsList.push(which);
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    // let queryStringFilters = utils.getFilters();
    let queryStringFilters = false;
    if (queryStringFilters) {
      console.log('queryStringFilters');
      console.log(queryStringFilters);
      let tempFiltersModel = this.state.filtersModel;
      if (queryStringFilters.hasOwnProperty('sports')) {
        tempFiltersModel.sports = queryStringFilters.sports;
      }
      if (queryStringFilters.hasOwnProperty('gameType')) {
        tempFiltersModel.gameType = queryStringFilters.gameType;
      }
      if (queryStringFilters.hasOwnProperty('buyIn')) {
        tempFiltersModel.buyIn = queryStringFilters.buyIn;
      }
      if (queryStringFilters.hasOwnProperty('currency')) {
        tempFiltersModel.currency = queryStringFilters.currency;
      }
      if (queryStringFilters.hasOwnProperty('type')) {
        tempFiltersModel.type = queryStringFilters.type;
      }
      if (queryStringFilters.hasOwnProperty('league')) {
        tempFiltersModel.league = queryStringFilters.league;
      }
      if (queryStringFilters.hasOwnProperty('status')) {
        tempFiltersModel.status = queryStringFilters.status;
      }
      if (queryStringFilters.hasOwnProperty('isfull')) {
        tempFiltersModel.isFull[0] = Boolean(queryStringFilters.isfull[0]);
      }
      if (queryStringFilters.hasOwnProperty('isPrivate')) {
        tempFiltersModel.isPrivate = queryStringFilters.isPrivate;
      }

      console.log('Setting Filters from query', tempFiltersModel);
      this.setState({ filtersModel: tempFiltersModel }, () => {
        this.props.filterBar(this.state.filtersModel);
      });
    } else {
      this.props.filterBar(this.state.filtersModel);
    }
  }

  handleClickDropdown() {
    this.setState((prevState) => {
      return {
        openDrop: !prevState.openDrop,
      };
    });
  }

  render() {
    const dataArray = new Set(sportsList);
    sportsList = [...dataArray];

    return (
      <div className={'filter-desktop'}>
        <div className="filter-category">
          <MediaQuery maxWidth={1365}>
            <div className="control-title row">CONCURSO</div>
            <div className='menu-container'>
              <div className='menu-all' onClick={() => { this.setState({ contestMenu: !this.state.contestMenu }) }}>
                <span>CONCURSO</span>
              </div>
              <div className={`menu-body ${this.state.contestMenu ? 'active' : ''}`}>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('gameType', 'all'); }}>
                  <div className={`flex-col ${this.state.filtersModel.gameType.indexOf('all') !== -1 && 'item-active'}`}>TODOS</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('gameType', 'pool'); }}>
                  <div className='col-33'><img src={iconQuiniela} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.gameType.indexOf('pool') !== -1 && 'item-active'}`}>Quiniela</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('gameType', 'survivor'); }}>
                  <div className='col-33'><img src={iconSurvivor} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.gameType.indexOf('survivor') !== -1 && 'item-active'}`}>Survivor</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('gameType', 'bracket'); }}>
                  <div className='col-33'><img src={bracketIcon} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.gameType.indexOf('series') !== -1 && 'item-active'}`}>Bracket</div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1365}>
            <div className="control-title row">CONCURSO</div>
            <ToggleButtonGroup
              name="concurso"
              type="radio"
              value={this.state.filtersModel.gameType[0]}
              onChange={(e) => {
                this.handleFilterClick('gameType', e);
              }}
            >
              <ToggleButton value={'all'} className="itemFilter">
                <span className="concurso-item concurso-all">TODOS</span>
              </ToggleButton>
              <ToggleButton value={'pool'} className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img
                    data-tip
                    data-for="contest-quiniela"
                    src={iconQuiniela}
                    width="26"
                    height="26"
                  />
                  <div className="subtitle">Quiniela</div>
                </div>
                <ReactTooltip id="contest-quiniela" place="top" type="light" effect="solid">
                  Quinielas
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'survivor'} className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img
                    data-tip
                    data-for="contest-survivor"
                    src={iconSurvivor}
                    width="26"
                    height="26"
                  />
                  <div className="subtitle">Survivor</div>
                </div>
                <ReactTooltip id="contest-survivor" place="top" type="light" effect="solid">
                  Survivor
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'bracket'} className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img
                    data-tip
                    data-for="contest-survivor"
                    src={bracketIcon}
                    width="26"
                    height="26"
                  />
                  <div className="subtitle">Bracket</div>
                </div>
                <ReactTooltip id="contest-bracket" place="top" type="light" effect="solid">
                  Bracket
                </ReactTooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </MediaQuery>
        </div>

        {/* -------------------------- */}

        <React.Fragment>
          <div className="filter-category">
            {sportsList.length >= 5 && (
              <div>
                <div className="control-title row">MONEDA</div>
                <div className='menu-container'>
                  <div className='menu-all' onClick={() => { this.setState({ coinMenu: !this.state.coinMenu }) }}>
                    <span>MONEDA</span>
                  </div>
                  <div className={`menu-body ${this.state.coinMenu ? 'active' : ''}`}>
                    <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('currency', 'all'); }}>
                      <div className={`flex-col ${this.state.filtersModel.currency.indexOf('all') !== -1 && 'item-active'}`}>TODOS</div>
                    </div>
                    <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('currency', 'real'); }}>
                      <div className='col-33'><img src={cash1} style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '4px' }} /></div>
                      <div className={`flex-col v-align ${this.state.filtersModel.currency.indexOf('real') !== -1 && 'item-active'}`}>Real</div>
                    </div>
                    <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('currency', 'pickcoin'); }}>
                      <div className='col-33'><img src={pickcoins1} width="26" height="20" /></div>
                      <div className={`flex-col ${this.state.filtersModel.currency.indexOf('pickcoin') !== -1 && 'item-active'}`}>Pickcoin</div>
                    </div>
                    <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('currency', 'referral'); }}>
                      <div className='col-33'><img src={pickcoins1Plata} width="26" height="20" /></div>
                      <div className={`flex-col ${this.state.filtersModel.currency.indexOf('referral') !== -1 && 'item-active'}`}>Token</div>
                    </div>
                  </div>
                </div>
              </div>
            ) || (
                <MediaQuery minWidth={1165}>
                  <div className="control-title row">
                    <span>MONEDA</span>
                  </div>

                  <ToggleButtonGroup
                    name="moneda"
                    type="checkbox"
                    value={this.state.filtersModel.currency}
                    onChange={(e) => {
                      this.handleFilterClick('currency', e);
                    }}
                  >
                    <ToggleButton value={'all'} className="itemFilter">
                      <span className="moneda-item moneda-all">TODAS</span>
                    </ToggleButton>
                    <ToggleButton value={'real'} className="itemFilter">
                      <div className="concurso-item concurso-all">
                        <img
                          data-tip
                          data-for="moneda-real"
                          className="moneda-real pool-icons filter"
                          src={cash1}
                          style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '4px' }}
                        />
                        <div className="subtitle">Real</div>
                      </div>
                      <ReactTooltip id="moneda-real" place="top" type="light" effect="solid">
                        Moneda Real
                      </ReactTooltip>
                    </ToggleButton>
                    <ToggleButton value={'pickcoin'} className="itemFilter">
                      <div className="concurso-item concurso-all">
                        <img
                          data-tip
                          data-for="moneda-pickcoin"
                          className="moneda-pickcoins pool-icons filter"
                          src={pickcoins1}
                          width="30"
                          height="30"
                        />
                        <div className="subtitle">Pickcoin</div>
                      </div>
                      <ReactTooltip id="moneda-pickcoin" place="top" type="light" effect="solid">
                        Moneda Pickcoin
                      </ReactTooltip>
                    </ToggleButton>
                    <ToggleButton value={'referral'} className="itemFilter">
                      <div className="concurso-item concurso-all">
                        <img
                          data-tip
                          data-for="moneda-referral"
                          className="moneda-referral pool-icons filter"
                          src={pickcoins1Plata}
                          width="30"
                          height="30"
                        />
                        <div className="subtitle">Token</div>
                      </div>
                      <ReactTooltip id="moneda-referral" place="top" type="light" effect="solid">
                        Moneda Token
                      </ReactTooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MediaQuery>
              )}
          </div>
        </React.Fragment>

        {/* ------------------- */}

        <div className="filter-category">
          <MediaQuery maxWidth={1200}>
            <div className="control-title row">MONTO</div>
            <div className='menu-container'>
              <div className='menu-all' onClick={() => { this.setState({ buyInMenu: !this.state.buyInMenu }) }}>
                <span>MONTO</span>
              </div>
              <div className={`menu-body ${this.state.buyInMenu ? 'active' : ''}`}>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('buyIn', ['all']); }}>
                  <div className={`flex-col ${this.state.filtersModel.buyIn.indexOf('all') !== -1 && 'item-active'}`}>TODOS</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('buyIn', ['low']); }}>
                  <div className='col-33'><img src={cash1} style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '4px' }} /></div>
                  <div className={`flex-col v-align ${this.state.filtersModel.buyIn.indexOf('low') !== -1 && 'item-active'}`}>$1 a $49</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('buyIn', ['medium']); }}>
                  <div className='col-33'><img src={cash1} style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '4px' }} /></div>
                  <div className={`flex-col v-align ${this.state.filtersModel.buyIn.indexOf('medium') !== -1 && 'item-active'}`}>$50 a $251</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('buyIn', ['high']); }}>
                  <div className='col-33'><img src={cash1} style={{ width: '70%', height: 'auto', marginTop: '7px', marginBottom: '4px' }} /></div>
                  <div className={`flex-col v-align ${this.state.filtersModel.buyIn.indexOf('high') !== -1 && 'item-active'}`}>+ de $251</div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1200}>
            <div className="control-title row">
              <span>MONTO</span>
            </div>
            <MontosButtons
              handleFilterClick={this.handleFilterClick}
              isPickcoinsActive={this.props.pickcoinsActive}
              isPickcoinsPlataActive={this.props.pickcoinsPlataActive}
              filtersMontosValue={this.state.filtersModel.buyIn}
            />
          </MediaQuery>
        </div>

        {/* ------------------------- */}

        <div className="filter-category">
          <MediaQuery maxWidth={1200}>
            <div className="control-title row">TIPO</div>
            <div className='menu-container'>
              <div className='menu-all' onClick={() => { this.setState({ typeMenu: !this.state.typeMenu }) }}>
                <span>TIPO</span>
              </div>
              <div className={`menu-body ${this.state.typeMenu ? 'active' : ''}`}>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['all']); }}>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('all') !== -1 && 'item-active'}`}>TODOS</div>
                </div>

                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['abierta']); }}>
                  <div className='col-33'><img src={iconQuiniela} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('abierta') !== -1 && 'item-active'}`}>Abierta</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['cupo']); }}>
                  <div className='col-33'><img src={iconCl} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('cupo') !== -1 && 'item-active'}`}>Limitado</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['max']); }}>
                  <div className='col-33'><img src={iconMax} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('max') !== -1 && 'item-active'}`}>MAX</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['h2h']); }}>
                  <div className='col-33'><img src={iconH2h} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('h2h') !== -1 && 'item-active'}`}>1 vs 1</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['pick5']); }}>
                  <div className='col-33'><img src={iconPick5} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('pick5') !== -1 && 'item-active'}`}>Pick 5</div>
                </div>
                <div className='flex-row menu-item' onClick={() => { this.handleFilterClick('type', ['parlay']); }}>
                  <div className='col-33'><img src={iconParlay} width="26" height="26" /></div>
                  <div className={`flex-col ${this.state.filtersModel.type.indexOf('parlay') !== -1 && 'item-active'}`}>Parlay</div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={1200}>
            <div className="control-title row">
              <span>TIPO</span>
            </div>
            <ToggleButtonGroup
              name="type"
              value={this.state.filtersModel.type}
              onChange={(e) => {
                this.handleFilterClick('type', e);
              }}
              type="checkbox"
            >
              <ToggleButton value={'all'} className="itemFilter">
                <span className="type-item type-all">TODAS</span>
              </ToggleButton>
              <ToggleButton value={'abierta'} data-tip data-for="type-abierta" className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img src={iconQuiniela} width="26" height="26" />
                  <div className="subtitle">Abierta</div>
                </div>
                <ReactTooltip id="type-abierta" place="top" type="light" effect="solid">
                  Abierta
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'cupo'} data-tip data-for="type-cupol" className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img src={iconCl} width="26" height="26" />
                  <div className="subtitle">Limitado</div>
                </div>
                <ReactTooltip id="type-cupol" place="top" type="light" effect="solid">
                  Cupo Limitado
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'max'} data-tip data-for="type-max" className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img src={iconMax} width="26" height="26" />
                  <div className="subtitle">MAX</div>
                </div>
                <ReactTooltip id="type-max" place="top" type="light" effect="solid">
                  MAX
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'h2h'} data-tip data-for="type-h2h" className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img src={iconH2h} width="26" height="26" />
                  <div className="subtitle">1 vs 1</div>
                </div>
                <ReactTooltip id="type-h2h" place="top" type="light" effect="solid">
                  1 vs 1
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'pick5'} data-tip data-for="type-pick5" className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img src={iconPick5} width="26" height="26" />
                  <div className="subtitle">Pick 5</div>
                </div>
                <ReactTooltip id="type-pick5" place="top" type="light" effect="solid">
                  Pick 5
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton value={'parlay'} data-tip data-for="type-parlay" className="itemFilter">
                <div className="concurso-item concurso-all">
                  <img src={iconParlay} width="26" height="26" />
                  <div className="subtitle">Parlay</div>
                </div>
                <ReactTooltip id="type-pick5" place="top" type="light" effect="solid">
                  Parlay
                </ReactTooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </MediaQuery>
        </div>

        {/* ------------------------- */}

        <div className="filter-category">
          <div className="control-title row">
            <span>DEPORTE</span>
          </div>
          <ToggleButtonGroup
            name="deporte"
            type="checkbox"
            value={this.state.filtersModel.sports}
            onChange={(e) => {
              this.handleFilterClick('sports', e);
            }}
          >
            <ToggleButton value={'all'} className="itemFilter">
              <span className={'deporte-item deporte-all'}>TODOS</span>
            </ToggleButton>
            <ToggleButton
              value={'Baseball'}
              style={this.checkSportsAvailability(4) ? null : { display: 'none' }}
              className="itemFilter"
            >
              <img className="pool-icons filter" src={baseball} data-tip data-for="base" />
              <ReactTooltip place="top" type="light" id="base" effect="solid">
                Baseball
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(3) ? null : { display: 'none' }}
              value={'Basketball'}
              data-tip
              data-for="basket"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={basketball} />
              <ReactTooltip place="top" type="light" id="basket" effect="solid">
                Basketball
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(1) ? null : { display: 'none' }}
              value={'Fútbol'}
              data-tip
              data-for="fut"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={futbol} />
              <ReactTooltip place="top" type="light" id="fut" effect="solid">
                Futból
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(2) ? null : { display: 'none' }}
              value={'Fútbol Americano'}
              data-tip
              data-for="americano"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={americano} />
              <ReactTooltip place="top" type="light" id="americano" effect="solid">
                Futból Americano
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(5) ? null : { display: 'none' }}
              value={'Tenis'}
              data-tip
              data-for="tenis"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={tenis} />
              <ReactTooltip place="top" type="light" id="tenis" effect="solid">
                Tennis
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(6) ? null : { display: 'none' }}
              value={'Golf'}
              data-tip
              data-for="golf"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={golf} />
              <ReactTooltip place="top" type="light" id="golf" effect="solid">
                Golf
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(7) ? null : { display: 'none' }}
              value={'F1'}
              data-tip
              data-for="F1"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={F1} />
              <ReactTooltip place="top" type="light" id="F1" effect="solid">
                Carreras
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(8) ? null : { display: 'none' }}
              value={'Box'}
              data-tip
              data-for="box"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={box} />
              <ReactTooltip place="top" type="light" id="box" effect="solid">
                Box
              </ReactTooltip>
            </ToggleButton>
            <ToggleButton
              style={this.checkSportsAvailability(9) ? null : { display: 'none' }}
              value={'Hockey'}
              data-tip
              data-for="hockey"
              className="itemFilter"
            >
              <img className="pool-icons filter" src={hockey} />
              <ReactTooltip place="top" type="light" id="hockey" effect="solid">
                Hockey
              </ReactTooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="dropdown-filter">
          <div className="control-title row">
            <span>LIGA</span>
          </div>
          <DropdownButton
            className="btn-filter-dropdown itemFilter"
            title="LIGAS"
            id="dropdown-liga"
          >
            <ListLeagues
              selectedSport={this.state.filtersModel.sports}
              closeDropdown={this.closeDropdown}
              filtersLiga={this.state.filtersModel.league}
              checkFilterLiga={this.handleFilterClick}
            />
          </DropdownButton>
        </div>
        <div className="dropdown-filter">
          <div className="control-title row">
            <span>ESTADO</span>
          </div>

          <DropdownButton
            className="btn-filter-dropdown itemFilter"
            title="ESTADO"
            id="dropdown-liga"
          >
            <ToggleButtonGroup
              name="estado"
              type="checkbox"
              value={this.state.filtersModel.status}
              onChange={(e) => {
                this.handleFilterClick('status', e);
              }}
            >
              <ToggleButton value={'all'}>
                <span className={'estado-item estado-all'}>TODOS</span>
              </ToggleButton>
              <ToggleButton value={'proximas'}>
                <span className={'estado-item estado-proximas'}>PRÓXIMAS</span>
              </ToggleButton>
              <ToggleButton value={'curso'}>
                <span className={'estado-item estado-curso'}>EN CURSO</span>
              </ToggleButton>
              <ToggleButton value={'finalizadas'}>
                <span className={'estado-item estado-finalizadas'}>FINALIZADAS</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </DropdownButton>
        </div>
        <div className="filter-category">
          <div className="control-title row">
            <span>LLENAS</span>
          </div>
          <div className="justify align column flex-container">
            <Checkbox
              name="llenas"
              checked={this.state.filtersModel.isFull[0]}
              onChange={(e) => {
                this.handleFilterClick('isFull');
              }}
            >
              <div className={`checkbox-custom`}></div>
            </Checkbox>
          </div>
        </div>
        {!utils.isLogged() || !utils.isSuPick() ? null : (
          <div className="filter-category">
            <div className="control-title row">
              <span>PRIVADAS</span>
            </div>
            <ToggleButtonGroup
              name="privadas"
              type="radio"
              value={this.state.filtersModel.isPrivate}
              onChange={(e) => {
                this.handleFilterClick('isPrivate', e);
              }}
            >
              <ToggleButton value={true}>SI</ToggleButton>
              <ToggleButton value={false}>NO</ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        {this.props.filtering ? (
          <span className="flex-container justify align">
            {' '}
            <i className="ion-load-a loading small"></i>
          </span>
        ) : null}
      </div>
    );
  }
}

class ListLeagues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: null,
      selectedSport: this.props.selectedSport,
      listLeagues: this.props.filtersLiga,
    };
    this.filterLeagues = this.filterLeagues.bind(this);
    // console.log('ListLeaguesProps',this.props)
  }

  filterLeagues(sports, availableLeagues) {
    // console.log("Filtrando Ligas");
    // console.log(sports, availableLeagues);
    // let modFilters = this.state.filtersModel;
    let sportsContainAll = false;
    let newValues = [];

    sports.forEach((filter) => {
      if (filter === 'all') {
        sportsContainAll = true;
      }
    });

    if (sportsContainAll) {
      return availableLeagues;
    }

    sports.forEach((sport) => {
      availableLeagues.forEach((league) => {
        if (sport === league.sport) {
          // console.log('Same sport');
          // console.log(sport, league.sport);
          newValues = [...newValues, league];
        }
      });
    });

    // console.log(newValues);

    return newValues;
  }

  componentDidMount() {
    API.fetchSportsIndex().then((response) => {
      this.setState(() => {
        return {
          allSports: response.data.sports,
        };
      });
    });
  }
  render() {
    // console.log('ListLeagues',this);
    //let listItems;
    let listaLigas;
    let listaTorneos = [];
    if (this.state.allSports !== null && this.state.allSports !== undefined) {
      // console.log("ListLeagues Updated", this.state.allSports);
      this.state.allSports.forEach((sport) => {
        let deporte = sport.name;
        //Iterar sobre cada deporte y extraer cada toreno instanciado en su clase
        sport.tournaments.forEach((tourn) => {
          let obj = {};
          obj = {
            name: tourn.name,
            id: tourn.id,
            sport: deporte,
          };
          listaTorneos.push(obj);
        });
      });
      // console.log(listaTorneos);
      listaLigas = this.filterLeagues(this.props.selectedSport, listaTorneos);
      listaLigas = listaLigas.map((element, i) => (
        <ToggleButton value={`${element.id}`} className="dropdown-liga" key={i}>
          {element.name}
        </ToggleButton>
      ));
      // console.log(listaLigas)
    } else {
      listaLigas = [];
    }
    return (
      <ToggleButtonGroup
        id="filtros-ligas"
        name="liga"
        type="checkbox"
        value={this.props.filtersLiga}
        onChange={(e) => {
          this.props.checkFilterLiga('league', e);
        }}
      >
        <ToggleButton value={`all`} id={'ligas-all'}>
          <span>TODAS</span>
        </ToggleButton>
        {listaLigas}
      </ToggleButtonGroup>
    );
  }
}

class FiltersLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeBar: this.props.typeBar,
      tournament: this.props.setTitle,
      pickcoinsActive: true,
      pickcoinsPlataActive: false,
    };
    this.updateMontoIcons = this.updateMontoIcons.bind(this);
  }

  updateMontoIcons(filtersModel) {
    let pickcoinsActive = filtersModel.currency[0] === 'pickcoin';
    let pickcoinsPlataActive = filtersModel.currency[0] === 'referral';
    this.setState({
      pickcoinsActive,
      pickcoinsPlataActive,
    });

    // console.log('Updating Monto...' , this.state.pickcoinsActive);
  }

  render() {
    let conditionalComponent;
    conditionalComponent = (
      <div className="filters-col">
        <AccummulativeFilters
          {...this.props}
          {...this.state}
          filterBar={this.props.filterBar}
          updateMonto={this.updateMontoIcons}
        />
      </div>
    );
    return conditionalComponent;
  }
}

export default FiltersLobby;
