import React from 'react';
import axios from 'axios';
import Loading from '../Loading/Loading';
import { Nav, NavItem } from 'react-bootstrap/lib';
import 'jquery';
// import "../../assets/stylesheets/flags-sprites-complete.scss";
import RegisterPanel from '../RegisterPanel';
import InfoContainer from '../InfoContainer/InfoContainer';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';
import API from '../../utils/api';
import quinaUtils from '../../utils/utils';
import Utils from '../../utils/quinielasUtils';
import QuinielaPicksUI from '../QuinielaPicksUI/QuinielaPicksUI';
import { QuinielaDataConsumer } from '../../context/QuinielaDataContext';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';

const HandleLayout = ({
  updateEntriesParent,
  similarPools,
  cambiarEntradas,
  enoughBalance,
  updateStartedFixture,
  handleAbonar,
  handleMaxHeight,
  handleMessage,
  status,
  withRegister,
  state,
  fixtures,
  setSameEntry,
  selection,
  changeSection,
  userData,
  updateCurrentEntry,
  userBalance,
  reiniciarCambioDeEntrada,
  propFirstFixture,
  parley,
  parleyDeadlines,
  weeks,
  handleWeekChange,
  selectedWeek
}) => {
  let conditionalComponent = null;
  let currentW = null;

  if (state.poolInfo.pool.type === 'full') {
    currentW = state.poolWeeks.weeks.filter((w) => w.id === state.poolWeekSelected)[0];
  } else {
    currentW = state.poolWeeks.current;
  }

  let infoContainer = (
    <QuinielaDataConsumer>
      {({
        activeSort,
        entriesInfo,
        fetchSearchableStandings,
        fixtureProps,
        fixtures,
        nextPicks,
        nextProps,
        poolInfo,
        poolWeeks,
        poolWeekSelected,
        premios,
        selectedEntry,
        selectedEntryData,
        sortChanged,
        standings,
        submittingSearch,
        queryParams,
        updateClosedFixture,
        updateStartedFixture,
        updateScrollPositions,
      }) => {
        // console.log( state );
        return (
          <InfoContainer
            activeSort={activeSort}
            availableMessages={state.availableMessages}
            cambiarEntradas={state.cambiarEntradas}
            changeSearchNick={state.changeSearchNick}
            entriesInfo={entriesInfo}
            estado={state.estado}
            enoughBalance={enoughBalance}
            fetchSearchableStandings={fetchSearchableStandings}
            fixtureProps={fixtureProps}
            fixtures={fixtures}
            handleMaxHeight={handleMaxHeight}
            handleMessage={handleMessage}
            handleUpdateReset={state.handleUpdateReset}
            liveClosedFixtures={state.liveClosedFixtures}
            liveFixturesData={state.liveFixturesData}
            loadedPicks={state.loadedPicks}
            newMessage={!state.newMessage ? '' : state.newMessage}
            nextPicks={nextPicks}
            nextProps={nextProps}
            poolInfo={poolInfo}
            poolWeeks={poolWeeks}
            poolWeekSelected={poolWeekSelected}
            posiciones={standings}
            premios={premios}
            queryParams={queryParams}
            resetMessage={state.resetMessage}
            resetNickValue={state.resetNickValue}
            resetPosiciones={state.resetPosiciones}
            selectedEntry={selectedEntry}
            selectedEntryData={selectedEntryData}
            selection={selection}
            sendMessage={state.sendMessage}
            sortChanged={sortChanged}
            sortPositionList={state.sortPositionList}
            submittingSearch={submittingSearch}
            updateClosedFixture={updateClosedFixture}
            updatedStandings={state.updatedStandings}
            userData={userData}
            userBalance={userBalance}
            updateScrollPostions={updateScrollPositions}
            updateStartedFixture={updateStartedFixture}
            withRegister={withRegister}
            parlay={parley}
            parlayDeadlines={parleyDeadlines}
          />
        );
      }}
    </QuinielaDataConsumer>
  );
  /**
   *  Upcoming:
   *  If the user is registered or is the superuser show the full view
   *  else hide the navigation bar for sections
   */
  if (status === 'upcoming') {
    if (withRegister || quinaUtils.isSuPick()) {
      return (
        <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
          <div id="picksQuiniela" className="lobby  col-xs-12 col-md-4">
            <QuinielaPicksUI
              setSameEntry={setSameEntry}
              updateCurrentEntry={updateCurrentEntry}
              updateEntriesParent={updateEntriesParent}
              updateStartedFixture={updateStartedFixture}
              similarPools={similarPools}
              cambiarEntradas={cambiarEntradas}
              handleAbonar={handleAbonar}
              enoughBalance={enoughBalance}
              entriesInfo={state.entriesInfo}
              fixtures={state.fixtures}
              parley={parley}
              firstFixture={propFirstFixture}
              fixtureProps={state.fixtureProps}
              tiebreakers={state.poolInfo.tiebreakers}
              entryInfo={state.selectedEntryData}
              poolInfo={state.poolInfo}
              similarPools={similarPools}
              currentWeek={currentW}
              poolWeeks={state.poolWeeks}
              reiniciarCambioDeEntrada={reiniciarCambioDeEntrada}

              // Prop drilling <WeeksControls />
              weeks={weeks}
              handleWeekChange={handleWeekChange}
              selectedWeek={selectedWeek}
            />
          </div>
          <div
            id="multiple-quiniela-container"
            className={` ${
              state.poolInfo.pool.is_full ? ' col-xs-12 col-md-8' : 'col-xs-12 col-md-8 '
            }`}
          >
            <Nav bsStyle="tabs" bsClass="tab-container nav flex-row " onSelect={changeSection}>
              <NavItem
                eventKey="1"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 1 ? 'active' : null
                }`}
              >
                PRINCIPAL
              </NavItem>
              <NavItem
                eventKey="2"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 2 ? 'active' : null
                }`} // disabled={this.state.entriesInfo === null ? true: false}
                style={{ display: 'initial' }}
              >
                PICKS DE GRUPO
              </NavItem>
              <NavItem
                eventKey="3"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 3 ? 'active' : null
                }`}
                style={{ display: 'initial' }}
              >
                MARCADORES
              </NavItem>
            </Nav>
            {infoContainer}
          </div>
        </div>
      );
    } else if (state.poolInfo.pool.is_full) {
      return (
        <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
          <div
            id="multiple-quiniela-container"
            className={` ${state.poolInfo.pool.is_full ? 'col-xs-12 ' : 'col-xs-12 col-md-8 '}`}
          >
            <Nav bsStyle="tabs" bsClass="tab-container nav flex-row " onSelect={changeSection}>
              <NavItem
                eventKey="1"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 1 ? 'active' : null
                }`}
              >
                PRINCIPAL
              </NavItem>
            </Nav>
            {infoContainer}
          </div>
        </div>
      );
    } else {
      return (
        <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
          <div id="picksQuiniela" className="lobby  col-xs-12 col-md-4">
            <QuinielaPicksUI
              setSameEntry={setSameEntry}
              updateCurrentEntry={updateCurrentEntry}
              updateEntriesParent={updateEntriesParent}
              updateStartedFixture={updateStartedFixture}
              similarPools={state.similarPools}
              cambiarEntradas={cambiarEntradas}
              handleAbonar={handleAbonar}
              enoughBalance={enoughBalance}
              entriesInfo={state.entriesInfo}
              fixtures={state.fixtures}
              parley={parley}
              firstFixture={propFirstFixture}
              fixtureProps={state.fixtureProps}
              tiebreakers={state.poolInfo.tiebreakers}
              entryInfo={state.selectedEntryData}
              poolInfo={state.poolInfo}
              similarPools={similarPools}
              updateUserInfo={state.updateUserInfo}
              currentWeek={currentW}
              poolWeeks={state.poolWeeks}

              // Prop drilling <WeeksControls />
              weeks={weeks}
              handleWeekChange={handleWeekChange}
              selectedWeek={selectedWeek}
            />
          </div>
          <div
            id="multiple-quiniela-container"
            className={` ${state.poolInfo.pool.is_full ? ' col-xs-12 ' : 'col-xs-12 col-md-8 '}`}
          >
            <Nav bsStyle="tabs" bsClass="tab-container nav flex-row " onSelect={changeSection}>
              <NavItem
                eventKey="1"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 1 ? 'active' : null
                }`}
              >
                PRINCIPAL
              </NavItem>
            </Nav>
            {infoContainer}
          </div>
        </div>
      );
    }
  } else {
    /**
     * Closed || Live :
     * if the user is registered or is the superuser show the fullview
     * else  dont show the MisPicks component
     */
    if (withRegister || quinaUtils.isSuPick()) {
      return (
        <div className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1">
          <div id="picksQuiniela" className="lobby  col-xs-12 col-md-4">
            <QuinielaPicksUI
              setSameEntry={setSameEntry}
              updateCurrentEntry={updateCurrentEntry}
              updateEntriesParent={updateEntriesParent}
              updateStartedFixture={updateStartedFixture}
              handleAbonar={handleAbonar}
              cambiarEntradas={cambiarEntradas}
              enoughBalance={enoughBalance}
              entriesInfo={state.entriesInfo}
              fixtures={fixtures}
              parley={parley}
              firstFixture={propFirstFixture}
              fixtureProps={state.fixtureProps}
              tiebreakers={state.poolInfo.tiebreakers}
              similarPools={similarPools}
              entryInfo={state.selectedEntryData}
              poolInfo={state.poolInfo}
              currentWeek={currentW}
              poolWeeks={state.poolWeeks}

              // Prop drilling <WeeksControls />
              weeks={weeks}
              handleWeekChange={handleWeekChange}
              selectedWeek={selectedWeek}
            />
          </div>
          <div
            id="multiple-quiniela-container"
            className={` ${
              state.poolInfo.pool.is_full && withRegister
                ? ' col-xs-12 col-md-8 '
                : 'col-xs-12 col-md-8 '
            }`}
          >
            <Nav bsStyle="tabs" bsClass="tab-container nav flex-row " onSelect={changeSection}>
              <NavItem
                eventKey="1"
                className={`trapezoid flex-col col-no-padding col-center ${(selection === 1 &&
                  'active') ||
                  null}`}
              >
                PRINCIPAL
              </NavItem>
              <NavItem
                eventKey="2"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 2 ? 'active' : null
                }`} // disabled={this.state.entriesInfo === null ? true: false}
                style={{ display: 'initial' }}
              >
                PICKS DE GRUPO
              </NavItem>
              <NavItem
                eventKey="3"
                className={`trapezoid flex-col col-no-padding col-center ${
                  selection === 3 ? 'active' : null
                }`}
                style={{ display: 'initial' }}
              >
                MARCADORES
              </NavItem>
            </Nav>
            {infoContainer}
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="multiple-quiniela-container"
          className="quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1"
        >
          <Nav bsStyle="tabs" bsClass="tab-container nav flex-row " onSelect={changeSection}>
            <NavItem
              eventKey="1"
              className={`trapezoid flex-col col-no-padding col-center ${
                selection === 1 ? 'active' : null
              }`}
            >
              PRINCIPAL
            </NavItem>
            <NavItem
              eventKey="2"
              className={`trapezoid flex-col col-no-padding col-center ${
                selection === 2 ? 'active' : null
              }`} // disabled={this.state.entriesInfo === null ? true: false}
              style={{ display: 'initial' }}
            >
              PICKS DE GRUPO
            </NavItem>
            <NavItem
              eventKey="3"
              className={`trapezoid flex-col col-no-padding col-center ${
                selection === 3 ? 'active' : null
              }`}
              style={{ display: 'initial' }}
            >
              MARCADORES
            </NavItem>
          </Nav>
          {infoContainer}
        </div>
      );
    }
  }
  return conditionalComponent;
};

class QuinielaLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualPageStanddings: 1,
      availableMessages: null,
      cambiarEntradas: null,
      desempates: null,
      enoughBalance: this.props.enoughBalance,
      entries: null,
      entriesInfo: null,
      errorCopiar: null,
      estadoDesempates: false,
      estadoPicks: false,
      estadoProps: false,
      estadoRegistro: false,
      firstUpdate: false,
      fixtures: null,
      firstFixture: null,
      guardarPicksExito: false,
      item: null,
      liveFixturesData: null,
      livePropsData: null,
      loaded: false,
      logged: null,
      my_pools: null,
      newMessage: '',
      picks: [],
      picks_props: [],
      picksCopiados: false,
      poolInfo: null,
      poolWeeks: this.props.estado.poolWeeks,
      poolWeekSelected: this.props.poolWeekSelected,
      premios: null,
      confirmationAfterGuardar: null,
      selection: 1,
      showTutorial: false,
      showPanel: false,
      showPanelFirstRegister: false,
      showPanelReRegister: false,
      showAbonar: false,
      showConfirmation: false,
      similarPools: this.props.similarPools,
      socketData: null,
      standGroups: null,
      standings: null,
      submitting: false,
      temporalRegisterPicks: null,
      tieScoreAway: null,
      tieScoreHome: null,
      tiesData: [],
      tiesPicks: null,
      tieWeekGoals: null,
      userData: null,
      updateCurrentEntry: false,
      currentSavedPicks: 0,
    };
    // console.log('QuinielaLobby',this);
    // this.changeWeek = this.changeWeek.bind(this);
    /***************************
     **Quiniela Lobby  Methods**
     **************************/
    this.changeSection = this.changeSection.bind(this);
    this.confirmationCopy = this.confirmationCopy.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleMaxHeight = this.handleMaxHeight.bind(this);
    // this.handleMaxHeight = _.debounce(
    //   this.handleMaxHeight,
    //   300
    // );
    this.handleMessage = this.handleMessage.bind(this);
    this.initializeTies = Utils.initializeTies.bind(this);
    this.handlePanel = this.handlePanel.bind(this);
    this.redirect = this.redirect.bind(this);
    this.resetMessage = this.resetMessage.bind(this);
    this.resetPicks = this.resetPicks.bind(this);
    this.returnErros = this.returnErros.bind(this);
    this.timerToRedirect = this.timerToRedirect.bind(this);
    this.updateConfirmation = this.updateConfirmation.bind(this);
    this.updateStateFunc = this.updateStateFunc.bind(this);

    /*******************************
     **Quiniela Utilities  Methods**
     ******************************/
    this.copyTies = Utils.copyTies.bind(this);
    this.copiarPicks = Utils.copiarPicks.bind(this);
    this.guardarPicks = Utils.guardarPicks.bind(this);
    this.registroRapido = Utils.registroRapido.bind(this);
    this.setWeekName = Utils.setWeekName.bind(this);
    this.updatePicks = Utils.updatePicks.bind(this);
    this.updateTieData = Utils.updateTieData.bind(this);

    this.setSameEntry = (entry) => {
      console.log('SetSameEntry', entry);
      this.setState({ temporalRegisterPicks: entry });
    };

    this.showAbonar = () => {
      this.setState({ showAbonar: true });
    };
    this.hideAbonar = () => {
      this.setState({ showAbonar: false });
    };

    this.showTutorial = () => {
      this.setState({ showTutorial: true });
    };
    this.closeTutorial = () => {
      console.log('Close');
      this.setState({ showTutorial: false });
    };

    this.showRegisterPanel = () => {
      this.setState({ showPanel: true });
    };
    this.closeRegisterPanel = () => {
      console.log('Here');
      this.setState({
        showPanel: false,
        showPanelFirstRegister: false,
        showPanelReRegister: false,
      });
      this.props.closeFirstRegister();
    };
  }

  /**
   * ChangeSection of MultiplePanel
   * @param event
   */
  changeSection(event) {
    console.log('changing section', event);
    this.setState({
      selection: parseInt(event),
    });
  }

  /**
   * This function handles the overlay behaviour
   * affter the user copies picks from other entry
   */
  confirmationCopy() {
    console.log('handleConfirmationCopy');
    setTimeout(() => {
      this.setState({
        errorCopiar: null,
      });
    }, 1800);
    // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function handles the overlay behaviour
   * to appear and dissapear
   */
  handleConfirmation() {
    // console.log("handleConfirmation");
    setTimeout(() => {
      this.setState({
        errorGuardarPicks: false,
        guardarPicksExito: false,
        showPanel: false,
      });
    }, 3000);
    // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function handles the overlay behaviour
   * to appear and dissapear
   */
  handleChangeEntry() {
    console.log('handlechangeEntry');
    setTimeout(() => {
      this.setState(
        {
          cambiarEntradas: true,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                cambiarEntradas: null,
              },
              () => {
                // this.initializeTies();
              }
            );
          }, 1500);
        }
      );
    }, 1500);
  }

  /**
   * Check the heights of picksCOntainer and if it is bigger than the multiple panel
   * set the height as the picksContainer
   */
  handleMaxHeight() {
    if ($(window).width() <= 768) {
      return;
    }

    // console.log(
    //   '%c Handling Height',
    //   'color:rgb(39, 190, 252);font-size:1.2:em;'
    // );
    // setTimeout( () => {
    const offsetMargin = 10;
    // const offsetChat = 160;
    const offsetPositions = 55;
    // const offsetPremios = 65;
    // const firstSection = $();
    const offsetNavItems = 32;
    const offsetPicksGruposTitle = 35;
    let offsetHeaderPicksGrupo;
    // const offsetRowUserItem = this.props.withRegister ? 25 : 0;
    // console.log('check height');
    const picksElements = $('#picksQuiniela');
    const offsetPremios = $('.premios-list .title-quiniela').height();
    const multipleContainer = $('#multiple-quiniela-container .multiple-quiniela-info');
    const positionList = $('.position-list .overflow');
    const premiosList = $('.premios-list .overflow');
    const picksGrupo = $('.tabla-grupos .overflow');
    const marcadoresList = $('.lista-marcadores .overflow');
    const upperTable = $('.upper-table');

    // console.log('picksElements', picksElements.height());
    offsetHeaderPicksGrupo =
      upperTable.length > 0 ? upperTable[0].offsetHeight + offsetNavItems : 0;
    /*
     ** Resize Positions and Premios
     */
    if (picksElements.length > 0 && multipleContainer.length > 0) {
      /**
         if picksPanel is bigger, re calculate multipleCont height  */
      if (picksElements[0].offsetHeight - 50 > multipleContainer[0].offsetHeight) {
        // console.log(positionList, multipleContainer, positionList, picksGrupo, marcadoresList);
        /**
         * Resize Positions
         */
        if (positionList.length > 0) {
          //   console.log('resize Position List', positionList[0], this.state.poolInfo.pool.closed);
          /**
           * Resize MultipleQuinielaPanel
           */

          multipleContainer[0].style.height =
            $(picksElements).height() - (offsetNavItems + offsetMargin * 2) + 'px';
          // console.log('Multiple ready');
          // console.log(multipleContainer[0].style.height);

          let extraControls = this.state.poolInfo.pool.weekly_prize ? 180 : 0;

          if (this.state.poolInfo.pool.closed) {
            positionList[0].style.height =
              $(picksElements).height() * 0.6 -
              extraControls -
              50 -
              (offsetPositions + offsetMargin) +
              'px';
            /**
             * Resize Premios
             */
            if (premiosList[0] !== undefined) {
              premiosList[0].style.height =
                $(picksElements).height() * 0.35 -
                extraControls -
                70 -
                (offsetPremios + offsetMargin * 2) +
                'px';
            }
          } else {
            positionList[0].style.height =
              picksElements.height() * 0.6 -
              extraControls -
              50 -
              (offsetPositions + offsetMargin) +
              'px';
            // console.log('position ready');
            // console.log('PicksHeight',picksElements.height()*.6)
            // console.log( 'extracontrols',extraControls)
            // console.log( 50 )
            // console.log( 'offsets',offsetPositions+ offsetMargin)
            // console.log( positionList[0].style.height );

            /**
             * Resize Premios
             */
            if (!this.state.poolInfo.pool.weekly_prize) {
              if (premiosList[0] !== undefined) {
                premiosList[0].style.height =
                  $(picksElements).height() * 0.35 -
                  extraControls -
                  90 -
                  (offsetPremios + offsetMargin * 2) +
                  'px';
                // console.log('premios ready');
                // console.log( premiosList[0].style.height );
              }
            }
          }
        } else if (picksGrupo.length > 0) {
          /**
           * Resize PicksGrupos
           */
          // console.log('resize Picks PGrupo', picksGrupo, picksGrupo.length);
          multipleContainer[0].style.height =
            picksElements[0].offsetHeight - (offsetNavItems + offsetMargin * 2) + 'px';
          for (let i = 0; i < picksGrupo.length; i++) {
            // console.log( picksGrupo[i]);
            if (picksGrupo[i].style !== undefined && picksGrupo[i].style.height !== undefined) {
              // console.log(picksGrupo[i]);
              picksGrupo[i].style.height =
                multipleContainer[0].offsetHeight -
                (25 + offsetHeaderPicksGrupo + offsetMargin + offsetPicksGruposTitle) +
                'px';
            }
          }
        } else if (marcadoresList.length > 0) {
          /**
           * Resize Marcadores
           */
          multipleContainer[0].style.height =
            picksElements[0].offsetHeight - (offsetNavItems + offsetMargin * 2) + 'px';
          marcadoresList[0].style.height =
            multipleContainer[0].offsetHeight - offsetMargin * 2 + 'px';
        }
      }
    }
    // }, 500)
    // return;

    // console.log($(window).width() <= 768);
  }

  /**
   * this function handles the message action to send it
   * @param e
   */
  handleMessage(e) {
    console.log('handleMessage', e.target.value);
    this.setState({
      newMessage: e.target.value,
    });
  }

  /**
   * This Function Handle the panel
   */
  handlePanel() {
    // console.log("Handle Panel");
    this.setState({
      showPanel: true,
    });
  }

  /**
   * This function redirects to the lobby after an action that deserves it
   */
  redirect() {
    console.log('Redirection');
    this.setState({
      redirectLobby: true,
    });
  }

  /**
   * This function resets the Picks after a entries Cancelation
   */
  resetPicks() {
    console.log('%c %s', 'background:#8C191F; font-size:20px;', 'Entries Cancelled');
    this.setState({
      picks: [],
      picks_props: [],
      tiesData: [],
      estadoDesempates: false,
      estadoPicks: false,
      estadoProps: false,
      estadoRegistro: false,
    });
  }

  /**
   * This function reset to null the satate of the message
   */
  resetMessage() {
    console.log('resetMEssage');
    this.setState({
      newMessage: '',
    });
  }

  /**
   * This handles the callback of a error in save picks
   * @param result
   */
  returnErros(result) {
    console.log('Callback Register Errors');
    console.log(result);
    this.setState({
      guardarPicksExito: result,
    });
  }

  /**
   * This handles a redirection after some action that deserves it
   */
  timerToRedirect() {
    // console.log('StartTimer');
    setTimeout(() => {
      this.handleConfirmation();
    }, 3500);
  }

  /**
   * This function closes the panel
   */
  updateStateFunc() {
    this.setState({
      showPanel: false,
    });
  }

  /**
   * This function handles the overlay behaviour
   * to its initial state
   */
  updateConfirmation() {
    console.log('updateConfirmation');
    this.setState({
      showConfirmation: false,
    });
  }

  /***********************
   * Lifetime Methods
   * **********************/

  componentDidUpdate(prevProps, prevState) {
    if (this.props.userBalance.balanceReal !== prevProps.userBalance.balanceReal) {
      console.log('update Balance');
      this.setState({
        userData: this.props.userData,
        userBalance: this.props.userBalance,
        enoughBalance: this.props.userBalance.balanceReal >= this.state.poolInfo.pool.entry_fee,
      });
    }
    // console.log(this.props.liveFixturesData);
    // console.log(prevProps.liveFixturesData);
    /**
     *Week Changed
     **/
    // if(this.props.poolWeekSelected !== prevProps.poolWeekSelected && this.props.loadedPicks){
    //   setTimeout( () => {
    //     this.handleMaxHeight();
    //   }, 250);
    // }
    /**
     * Entry Changed
     */
    if (
      this.props.selectedEntryData !== null &&
      this.props.selectedEntryData !== prevProps.selectedEntryData
    ) {
      return;
      // console.log('Update Entry DAta')
      let tempPicks = [],
        tempProps = [],
        tempTies = [];
      this.props.selectedEntryData.picks.forEach((pick) => {
        let obj = {};
        obj[pick.fixture_id] = {
          entry_id: pick.entry_id,
          fixture_id: pick.fixture_id,
          id: pick.id,
          pick: pick.pick,
          pool_id: pick.pool_id,
          week_id: pick.week_id,
        };
        tempPicks.push(obj);
      });
      this.props.selectedEntryData.picks_props.forEach((prop) => {
        // console.log(prop);
        let obj = {};
        obj[prop.prop_id] = {
          entry_id: prop.entry_id,
          fixture_id: prop.fixture_id,
          id: prop.id,
          pick: prop.pick,
          pool_id: prop.pool_id,
          prop_id: prop.prop_id,
        };
        tempProps.push(obj);
      });
      this.props.selectedEntryData.user_tiebreakers.forEach((tie) => {
        let obj = {};
        obj[tie.tiebreaker_id] = {
          id: tie.id,
          entry_id: tie.entry_id,
          tiebreaker_id: tie.tiebreaker_id,
          pick: tie.value,
        };
        tempTies.push(obj);
      });
      // console.log(this.props.poolInfo);
      // this.setState({ tiePicks: this.initializeTies( this.props.poolInfo, this.props.selectedEntryData)});
      if (!this.state.picksCopiados) {
        // console.log('WHERE?');
        this.setState(
          {
            picks: tempPicks,
            picks_props: tempProps,
            tiesData: tempTies,
            // tiePicks: this.initializeTies( this.props.poolInfo, this.props.selectedEntryData ),
            picksGuardados: this.props.selectedEntryData.picks.length,
            liveFixtureData: Date.now(),
          },
          () => {
            // this.initializeTies( this.props.poolInfo,this.props.selectedEntryData );
            // this.copyTies( this.props.selectedEntryData );
          }
        );
      }
    }

    if (prevProps.poolWeekSelected !== this.props.poolWeekSelected) {
      /**
       * PoolWeek changed but it exxiste
       */
      if (this.props.poolWeekSelected !== null) {
        API.fetchWeekFixtures(this.props.poolInfo.pool.id, this.props.poolWeekSelected).then(
          (response) => {
            // console.log('haHAngeWeek');
            this.setState(
              {
                firstFixture: response.data.first,
                fixtures: response.data.fixtures,
                poolWeekSelected: this.props.poolWeekSelected,
              },
              () => {
                this.setState({
                  tiePicks: this.initializeTies(
                    this.props.poolInfo,
                    !this.props.entriesInfo || !this.props.entriesInfo.entry
                      ? null
                      : this.props.entriesInfo.entry
                  ),
                });
              }
            );
          }
        );
        return true;
      }
    }

    /**
     * Standings Changed
     */
    if (this.props.updatedStandings !== prevProps.updatedStandings) {
      console.log('UpdateCOMPONENT');
    }
    if (this.props.cambiarEntradas && prevProps.cambiarEntradas === null) {
      // console.log('changed');
      this.setState(
        {
          tiesPicks: this.initializeTies(this.props.poolInfo, this.props.selectedEntryData),
        },
        () => {
          // console.log('READY');
        }
      );
    }

    /**
     * selectedEntry Changed
     * so not copy
     */
    if (prevProps.selectedEntry !== this.props.selectedEntry) {
      this.setState({
        picksCopiados: false,
      });
    }

    /**
     * Copied picks
     */
    if (prevState.clipboardPicks === undefined && this.state.clipboardPicks !== undefined) {
    }
    /**
     * Layout is ready
     */
    if (this.refs.picksLobbyQuina && !this.state.firstUpdate) {
      // console.log('Updated First');
      setTimeout(() => {
        this.setState({
          firstUpdate: true,
        });

        this.handleMaxHeight();
      }, 500);
    }
  }

  componentDidMount() {
    // console.log('Quiniela Lobby is mounting',this.props.estado.logged);
    let entrieData;
    if (this.props.estado.logged) {
      // console.log('logged', this.props.estado.fixtures);
      let currentWeek;
      if (this.props.estado.poolWeeks.current.id === undefined) {
        currentWeek = '';
      } else {
        if (this.props.estado.poolWeeks.current.id === null) {
          currentWeek = '';
        } else {
          currentWeek = this.props.estado.poolWeeks.current.id;
        }
      }
      // console.log('Current Week ID:', currentWeek);
      this.setState({
        logged: this.props.estado.logged,
        item: this.props.estado.item,
        poolInfo: this.props.estado.poolInfo,
        premios: this.props.estado.premios,
        userData: this.props.userData,
        fixtures: this.props.fixtures,
        firstFixture: this.props.firstFixture,
        fixtureProps: this.props.fixtureProps,
        similarPools: this.props.estado.similarPools,
        standings: this.props.standings,
        my_pools: this.props.estado.my_pools,
        entries: this.props.entries,
        standGroups: this.props.estado.standGroups,
        entriesInfo: null,
        poolWeeks: this.props.estado.poolWeeks,
        poolWeekSelected: currentWeek,
        updatedStandings: this.props.updatedStandings,
      });
      if (
        this.props.entries !== undefined &&
        this.props.entries !== null &&
        this.props.entries.length >= 1
      ) {
        console.log('Entries');
        // let currentSavedPicks =
        //   this.props.selectedEntryData.pick_prop_count +
        //   this.props.selectedEntryData.pick_count;
        let allPicks = this.props.poolInfo.fixtures_count + this.props.poolInfo.props_count;
        //Tiene Entradas para esta quiniela
        // console.log('Tiene entradas en esta quiniela ');
        if (this.props.estado.poolInfo.pool.type === 'full') {
          let entries_, fixtPerWeek_;
          axios
            .all([
              API.getEntriesInfo(this.props.entries[0].id),
              API.fetchWeekFixtures(this.props.estado.poolInfo.pool.id, currentWeek),
            ])
            .then(
              axios.spread((entries, fixtPerWeek) => {
                entries_ = entries.data;
                fixtPerWeek_ = fixtPerWeek.data;
                // console.log('%c Recieving QI Data...', 'font-size:20px' );
                // console.log('DataPEr WEek ', entries_, fixtPerWeek_);

                this.setState(
                  () => {
                    console.log('SetEntryData');
                    this.props.changeSelectedEntry(entries_.entry.id);
                    return {
                      enoughBalance: this.props.enoughBalance,
                      fixtures: fixtPerWeek_.fixtures,
                      entriesInfo: entries_,
                      desempates: entries_.entry.user_tiebreakers,
                      loaded: true,
                    };
                  },
                  () => {
                    this.props.updateFixtures(fixtPerWeek_.fixtures);
                    if (this.props.estado.poolInfo.pool.use_tibreakers) {
                      console.log(this.state.entriesInfo);
                      this.setState({
                        liveFixtureData: Date.now(),
                        tiesPicks: this.initializeTies(
                          this.props.estado.poolInfo,
                          !this.props.entriesInfo.entry ? null : this.props.entriesInfo.entry
                        ),
                      });
                    }
                  }
                );
              })
            )
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error in Quiniela Lobby',
              });
            });
        } else if (this.props.estado.poolInfo.pool.type === 'week') {
          let entries_, fixtPerWeek_;
          axios
            .all([
              API.getEntriesInfo(this.props.entries[0].id),
              API.fetchWeekFixtures(this.props.estado.poolInfo.pool.id, currentWeek),
            ])
            .then(
              axios.spread((entries, fixtPerWeek) => {
                entries_ = entries.data;
                fixtPerWeek_ = fixtPerWeek.data;
                // console.log('DataPEr WEek ', entries_, fixtPerWeek_, this.state.poolWeekSelected);
                // console.log('%c Recieving QI Data...', 'font-size:20px' );
                this.setState(
                  () => {
                    this.props.changeSelectedEntry(entries_.entry.id);
                    return {
                      enoughBalance: this.props.enoughBalance,
                      fixtures: fixtPerWeek_.fixtures,
                      entriesInfo: entries_,
                      desempates: entries_.entry.user_tiebreakers,
                      loaded: true,
                    };
                  },
                  () => {
                    this.props.updateFixtures(fixtPerWeek_.fixtures);
                    if (this.props.estado.poolInfo.pool.use_tibreakers) {
                      console.log(this.state.entriesInfo);
                      this.setState({
                        tiesPicks: this.initializeTies(
                          this.props.estado.poolInfo,
                          !this.props.entriesInfo.entry ? null : this.props.entriesInfo.entry
                        ),
                      });
                    }
                  }
                );
              })
            )
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error Fetchin all in Quiniela Lobby',
              });
            });
        }
      } else {
        //No tiene Entradas
        // console.log('%c Logged without entries', 'background:red');
        this.setState(
          () => {
            // console.log(this.state);
            return {
              entriesInfo: null,
              loaded: true,
            };
          },
          () => {
            if (this.props.poolInfo.pool.use_tibreakers) {
              // console.log(this.props.entriesInfo);
              this.setState({
                tiesPicks: this.initializeTies(
                  this.props.poolInfo,
                  !this.props.entriesInfo ? null : this.props.entriesInfo.entry
                ),
              });
            }
          }
        );
      }
    } else {
      // console.log('notLOgged', this.props.estado.entries);
      this.setState({
        logged: false,
        loaded: false,
      });
    }
    if (this.props.estado.poolInfo.pool.status === 'live') {
      // console.log('Si hay partidos live')

      this.setState({
        liveFixtureData: this.props.liveFixtureData,
        livePropsData: this.props.livePropsData,
      });
    } else {
      // console.log('No hay partidos live')
    }
  }

  /***********************
   * Render
   * **********************/

  render() {
    let isFull = null;
    let conditionalComponent;
    if (this.state.loaded) {
      isFull = this.state.poolInfo.pool.type === 'full' ? true : false;
      // console.log('%c QL RENDER', 'background:#31A7F5;');
      // console.log(this.props.userData);
      conditionalComponent = (
        <div id="quiniela-lobby-container">
          {/* <TutorialSlideShow
            deleteNewUserStatus={this.props.deleteNewUserStatus}
            numSlides={5}
            showTutorial={this.state.showTutorial}
            closeTutorial={this.closeTutorial}
          /> */}
          {this.state.logged === true ? (
            <RegisterPanel
              handleTutorialStep={this.props.handleTutorialStep}
              closePanel={this.closeRegisterPanel}
              entryId={this.props.selectedEntry}
              handleConfirmation={this.handleConfirmation}
              logged={this.state.logged}
              payType={this.state.poolInfo.pool.entry_currency}
              pool={this.state.poolInfo.pool}
              pools={null}
              myEntries={
                this.props.entriesInfo
                  ? [this.props.entriesInfo.entry, ...this.props.entriesInfo.other_entries]
                  : null
              }
              popUpNewUsersStatus={this.props.popUpNewUsersStatus}
              quinaID={this.state.poolInfo.pool.id}
              refreshAfterGuardar={this.props.refreshAfterGuardar}
              registerPicks={this.state.temporalRegisterPicks}
              returnErrors={this.returnErros}
              showPanel={this.state.showPanel}
              showPanelFirstRegister={this.props.showPanelFirstRegister}
              showPanelReRegister={this.props.showPanelReRegister}
              showTutorial={this.showTutorial}
              updatePanelFirstRegister={this.props.updatePanelFirstRegister}
              updatePanelReRegister={this.props.updatePanelReRegister}
              updateStateFunc={this.updateStateFunc}
              updateUserInfo={this.props.updateUserInfo}
              updateUserBalance={this.props.updateUserBalance}
              updateAfterRegister={this.props.updateAfterRegister}
              userData={quinaUtils.getUserInfo()}
              userBalance={this.props.userBalance}
              withRegister={this.props.withRegister}
            />
          ) : null}
          <div className="quiniela-container lobby">
            <HandleLayout
              setSameEntry={this.setSameEntry}
              updateCurrentEntry={this.props.updateCurrentEntry}
              updateEntriesParent={this.props.updateEntriesParent}
              updateStartedFixture={this.props.changeStatusPool}
              cambiarEntradas={this.props.cambiarEntradas}
              handleAbonar={this.showAbonar}
              handleMaxHeight={this.handleMaxHeight}
              enoughBalance={this.state.enoughBalance}
              handleMessage={this.handleMessage}
              status={this.state.poolInfo.pool.status}
              withRegister={this.props.withRegister}
              userData={quinaUtils.getUserInfo()}
              userBalance={this.props.userBalance}
              state={this.props}
              fixtures={this.props.fixtures}
              propFirstFixture={this.state.firstFixture}
              similarPools={this.props.similarPools}
              selection={this.state.selection}
              changeSection={this.changeSection}
              reiniciarCambioDeEntrada={this.props.reiniciarCambioDeEntrada}
              parley={this.props.parley}
              parleyDeadlines={this.props.parleyDeadlines}

              // Prop drilling <WeeksControls />
              weeks={this.props.weeks}
              handleWeekChange={this.props.changeSelectedWeek}
              selectedWeek={this.props.selectedWeek}
            />
            <AbonarSaldo
              handleTutorialStep={this.props.handleTutorialStep}
              type="modal"
              showAbonarSaldo={this.state.showAbonar}
              updatePanelSate={this.hideAbonar}
              showAbonarPop={this.showAbonar}
              updateUserInfo={this.props.updateUserInfo}
              updateUserBalance={this.props.updateUserBalance}
            />
          </div>
        </div>
      );
    } else {
      conditionalComponent = <Loading label="Cargando información de la quiniela..." />;
    }
    return conditionalComponent;
  }
}

export default QuinielaLobby;
