import React from 'react';

import Formsy from 'formsy-react';
import {
  Form,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  HelpBlock,
} from 'react-bootstrap/lib';
import API from '../../utils/api';

import CrearQuinielaFormulario from './CrearQuinielaFormulario';
import CrearSurvivorFormulario from '../CrearSurvivorFormulario';

const WizardItem = ({ active, id }) => {
  const isCurrent = active === Number(id);
  const isActive = Number(id) < active;
  return (
    <div className={`wizard-item flex-col ${isActive ? 'active' : ''}`}>
      <div className=" flex-container justify ">
        {isCurrent && id !== 5
          ? ''
          : // <i className=" signal ion-arrow-right-c"></i>
            null}
        {isActive ? (
          <i className="ion-checkmark-circled"></i>
        ) : (
          <span className={`formStep ${isCurrent ? 'current' : ''} ${isActive ? 'active' : ''}`}>
            <span className="formStepInside"> {id} </span>
          </span>
        )}
      </div>
      <div
        className={`flex-row row-no-padding wizard-progress ${isActive ? 'active' : ''}  ${
          isCurrent ? 'current' : ''
        }`}
      ></div>
    </div>
  );
};

const WizardStatus = ({ activeSection }) => {
  let conditionalComponent = (
    <div className="status-wizard flex-row row-no-padding">
      <WizardItem id="1" active={activeSection} />
      <WizardItem id="2" active={activeSection} />
      <WizardItem id="3" active={activeSection} />
      <WizardItem id="4" active={activeSection} />
      <WizardItem id="5" active={activeSection} />
    </div>
  );
  if (activeSection > 5) {
    conditionalComponent = (
      <div className="status-wizard success flex-row row-no-padding">
        <div className="flex-container justify align">
          <i className="ion-checkmark-circled"></i>
        </div>
      </div>
    );
  }
  return conditionalComponent;
};

const ErrorMessage = ({ showError, label }) => {
  if (showError) {
    return (
      <div className="error-message">
        <i className="ion-alert-circled"></i> {label}
      </div>
    );
  } else {
    return null;
  }
};

class CrearQuinielaPrivada extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: 1,
      showPanel: false,
      submitting: false,
      canSubmit: false,
      errorMessage: '',
      searchError: false,
    };

    this.crearPrivadaData = {
      comission_status: false, // optional
      affiliate_commission: null, //optional
      end_percentage: '100', //Required
      entry_fee: '1', //Required
      limited_capacity: false, //Optional
      limit_maxentries: false, //Optional
      max_multientries: null, //Optional
      max_entries: null, //Optional
      name: '', //Required
      password: '', //Required
      point_format: 'natural', //Required
      prize_scheme: 'dynamic', //Required
      prizes_list: null, //Optional
      send_notifications: null, //Optional
      sport_id: null, //Required
      tournament_instances: null, //Required
      pool_prizes: null, //Optional
    };

    // this.enableButton = () => {
    //   this.setState({ canSubmit:true });
    //  };
    // this.disableButton = () => {
    //   this.setState({ canSubmit:false });
    // };
    this.resetData = () => {
      this.setState({ gameName: '', gamePass: '', canSubmit: false, submitting: false });
    };
    this.nextStep = () => {
      this.setState((prevState) => {
        return { activeSection: prevState.activeSection + 1 };
      });
      // console.log('updateCreatePrivateData', this.crearPrivadaData );
    };
    this.prevStep = () => {
      this.setState((prevState) => {
        return { activeSection: prevState.activeSection - 1 };
      });
      // console.log('updateCreatePrivateData', this.crearPrivadaData );
    };
    this.updateCreatePrivateData = (data) => {
      this.props.changeModifiedState();
      console.log('updateCreatePrivateData', data);
      this.crearPrivadaData = data;
    };
  }

  render() {
    if (this.props.showPanel) {
      return (
        <div className="formulario-crear-quina-priv flex-container column justify align">
          <div className="flex-container justfy align">
            {this.state.activeSection > 5 ? null : (
              <span className="intro-copy">{`Crea tu ${
                this.props.gameType === 'pool' ? 'quiniela privada' : 'survivor privado'
              } en 5 sencillos pasos.`}</span>
            )}
          </div>
          <WizardStatus gameType={this.props.gameType} activeSection={this.state.activeSection} />
          <div className="flex-col">
            {this.props.gameType === 'pool' ? (
              <CrearQuinielaFormulario
                handleCancelSelection={this.props.handleCancelSelection}
                activeSection={this.state.activeSection}
                canSubmit={this.state.canSubmit}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                submitting={this.state.submitting}
                updateCreatePrivateData={this.updateCreatePrivateData}
              />
            ) : (
              <CrearSurvivorFormulario
                handleCancelSelection={this.props.handleCancelSelection}
                activeSection={this.state.activeSection}
                canSubmit={this.state.canSubmit}
                nextStep={this.nextStep}
                prevStep={this.prevStep}
                submitting={this.state.submitting}
                updateCreatePrivateData={this.updateCreatePrivateData}
              />
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default CrearQuinielaPrivada;
