import styled from 'styled-components'
import React from 'react'

// Hooks
import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

Grid.defaultProps = {}

Grid.propTypes = {
  ...shorthandProptypes
}

export default function Grid (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  return (
    <GridBase
      style={{
        ...stylesShortcuts,
        maxWidth: props.maxWidth || 'initial',
        gap: props.gap,
        gridTemplateColumns: props.columns
      }}
    >
      {props.children}
    </GridBase>
  )
}

const GridBase = styled.div`
  display: grid;
`
