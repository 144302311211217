import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import ReactTooltip from 'react-tooltip'
import * as Sentry from '@sentry/browser'
import { Button, Modal } from 'react-bootstrap/lib'
import { Redirect } from 'react-router-dom'

// Components
import Loading from '../Loading'
import { ConfirmationOverlay } from '../../utils/utils'
import RegisterPanel from '../RegisterPanel'
import ListFixtures from './ListFixtures'

// Utils
import utils from '../../utils/utils'
import API from '../../utils/api'
import { publish } from '../../utils/events'

// Assets
import guardar from '../../assets/images/guardarPicks-50.png'
import abonar from '../../assets/images/abonarIconBlanco-50.png'
import picks from '../../assets/images/quinielasIconBlanco-50.png'
import tutorialsurvivorNfl from '../../assets/images/tutorial/survivor/tutorialSurvivor.jpg'
import tutorialsurvivorFut from '../../assets/images/tutorial/survivor/tutorialSurvivorFut.jpg'

moment.locale('es')

const CallToActionButton = ({
  withRegister,
  selectedEntry,
  selectedWeek,
  submitting,
  guardarPicksSurvivor,
  updateUserInfo,
  currentWeek,
  enoughBalance,
  updateTemporalRegisterPicks,
  myEntry,
  showRegisterPanel,
  showAbonarPanel,
  alive,
  survivor
}) => {
  let isLogged = utils.isLogged();
  let conditionalComponent = null;

  if (isLogged) {
    if (withRegister) {
      if (alive.alive === true) {
        conditionalComponent = (
          <Button
            data-id="guardar"
            disabled={submitting}
            onClick={(e) => {
              guardarPicksSurvivor();
            }}
            className="guardar-picks callToAction"
            bsStyle="default"
          >
            <img src={guardar} className="bolsa-img" alt="" />
            <span data-id="guardar">{'GUARDA TU PICK'}</span>
          </Button>
        );
      } else {
        conditionalComponent = '';
      }
    } else {
      if (enoughBalance) {
        conditionalComponent = (
          <Button
            data-id="guardar"
            disabled={submitting || !isLogged || utils.isSuPick()}
            onClick={(e) => {
              showRegisterPanel();
            }}
            className="guardar-picks callToAction"
            bsStyle="default"
          >
            <img src={guardar} className="bolsa-img" alt="" />
            <span data-id="guardar">{'GUARDA TU PICK Y REGÍSTRATE'}</span>
          </Button>
        );
      } else if (survivor.entry_currency !== 'ticket') {
        conditionalComponent = (
          <Button
            data-id="guardar"
            disabled={submitting || !isLogged || utils.isSuPick()}
            onClick={(e) => {
              showAbonarPanel();
            }}
            className="guardar-picks callToAction"
            bsStyle="default"
          >
            <img src={abonar} className="bolsa-img" alt="" />
            <span data-id="guardar">{'ABONAR SALDO'}</span>
          </Button>
        );
      }
    }
  } else {
    conditionalComponent = (
      <Button
        data-id="guardar"
        disabled={true}
        onClick={(e) => {
          showRegisterPanel();
        }}
        className="guardar-picks callToAction"
        bsStyle="default"
      >
        <img src={guardar} className="bolsa-img" alt="" />
        <span data-id="guardar">{'GUARDA TU PICK Y REGÍSTRAME'}</span>
      </Button>
    );
  }

  return conditionalComponent;
};

/**
  This Component handles and presents the intecation between the entries
 * and the Form presented to the client
 *
 * @class SurvivorUI
 * @extends {React.Component}
 */
class SurvivorUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableTeams: null,
      errorSavePicks: false,
      showOverlay: false,
      statusEntry: false,
      guardarPicksStatus: false,
      currentSavedPicks: 0,
      reditectToLobby: false,
      showTutorial: false,
      submitting: false,

      // Representa la entrada de picks del usuario de la entrada actual o en su defecto
      // el objeto temporal si se abre el survivorPopup
      myEntry: {
        id: null,
        survivor_id: null,
        survivor_entry_id: null,
        user_id: null,
        survivor_picks: null,
      },
    };

    /**
     * This function handle the registration Panel
     */
    this.showRegisterPanel = () => {
      this.setState({
        showRegisterPanel: true,
      });
    };
    this.closeRegisterPanel = () => {
      this.setState({
        showRegisterPanel: false,
      });
    };

    this.handleConfirmation = (response) => {
      this.closeRegisterPanel();
      this.setState(
        {
          guardarPicksStatus: true,
          currentPicksSaved: 0,
        },
        () => {
          setTimeout(() => {
            this.setState({
              guardarPicksStatus: false,
            });
          }, 1000);
        }
      );
    };

    this.handleError = (errorMessage) => {
      Sentry.captureException(errorMessage, { extra: 'Error Saving SurvivorPicks' });
      this.setState(
        {
          errorSavePicks: true,
          errorMessage: errorMessage,
        },
        () => {
          setTimeout(() => {
            this.setState({
              errorSavePicks: false,
            });
          }, 1800);
        }
      );
    };

    this.showTutorial = () => {
      this.setState({
        showTutorial: true,
      });
    };
    this.hideTutorial = () => {
      this.setState(
        {
          showTutorial: false,
        },
        () => {
          utils.hideTutorialSurvivorPopUp();
        }
      );
    };

    /**
     * This function receives the data fetched from the server
     * and handles the type of data
     * @param { Object } data - data response frm the server after save picks
     */
    this.callbackGuardarPicks = (response) => {
      /**
       * If the response is success this snippet handles
       * the Overlay and the confirmation of the saved picks
       */
      if (response.data.success || response.data.survivor_entry) {
        this.handleConfirmation();
      } else {
        /**
         * If the response isnt successfull this snippet handles
         * the Overlay and the error message after saved picks
         */
        this.handleError(response.data.error);
      }

      this.setState(
        {
          submitting: false,
        },
        () => {
          if (this.props.refreshAfterGuardarInLobby) {
            this.props.refreshAfterGuardarInLobby(response.data);
          }
          if (this.props.isPopUp) {
            this.handleRedirectToLobby(response.data.survivorEntries[0].id);
          }
        }
      );
    };
    /**
     * This function sends the Entry object to the server
     * to save the entry
     */
    this.guardarPicksSurvivor = (type) => {
      // console.log('%c Guardar Pick ', 'font-size:2em; color: #FEC830;');
      // console.table( this.state.myEntry );
      if (type === undefined) {
        this.setState({
          submitting: true,
        });
      }

      API.postSaveSurvivorPicks(this.state.myEntry)
        .then((response) => {
          // console.log(response);
          this.callbackGuardarPicks(response);
        })
        .catch((error) => {
          /**
           * If the API return an error this snippet handles
           * the Overlay and the error message of the saved picks
           */
          // console.error(error);
          Sentry.captureException(error, { extra: 'Error Saving picks' });
          this.handleError('Encontramos un error en tu petición.');
          this.setState({
            submitting: false,
          });
        });
    };

    /**
     * This function handles the clicked pick and updates the state of the entry
     * @param {Number} weekId - this is the key to update the pick
     * @param {Number} teamId - this is the new value of the pick
     */

    this.handlePick = (teamId, weekId, fixtureId) => {
      // ------
      console.log('%c Handle Pick ', 'font-size:2em; color: #05acee;');
      let temporalEntry = this.state.myEntry;
      let temporalAvTeams = this.state.availableTeams;

      if (
        !temporalEntry.survivor_picks.length < 1 ||
        !temporalEntry.survivor_picks[weekId] ||
        temporalEntry.survivor_picks[weekId].pick === teamId
      ) {
        return;
      }

      let avTeamsKeys = Object.keys(temporalAvTeams);

      publish('UPDATE_MY_PICKS', 'justSave')

      /**
       * Deselect the previous week selected
       */
      avTeamsKeys.forEach((teamKey) => {
        if (temporalAvTeams[teamKey].week_id === weekId && temporalAvTeams[teamKey].selected) {
          temporalAvTeams[teamKey].selected = false;
          temporalAvTeams[teamKey].week_id = null;
        }
      });


      temporalAvTeams[teamId].selected = true;
      temporalAvTeams[teamId].week_id = weekId;

      temporalEntry.survivor_picks[weekId].pick = teamId;
      temporalEntry.survivor_picks[weekId].fixture_id = fixtureId;
      console.log('🚀 ~ SurvivorUI ~ constructor ~ temporalEntry:', temporalEntry)
      // debugger

      this.setState(
        {
          myEntry: temporalEntry,
          availableTeams: temporalAvTeams,
        },
        () => {
          if (this.props.updateTemporalRegisterPicks) {
            this.props.updateTemporalRegisterPicks(this.state.myEntry);
          }
        }
      );

      if (this.props.withRegister) {
        this.guardarPicksSurvivor("handle");
      }
    };

    this.resetPicks = (e) => {
      if (e) {
        e.preventDefault()
      }

      const currentEntry = !this.props.selectedEntryData ? null : this.props.selectedEntryData


      // Esta funcion debe devolver el id del pick de match seleccionado si es el primer
      // partido y el usuario lo selecciono o si selecciono otro que no es el primero y ya
      // paso la fecha del fixture.time_left === 0 si alguna de estas dos condiciones
      // no se cumplen devolver null
      const getSurvivorPicks = () => {
        return Object
          .entries(this.state.myEntry.survivor_picks)
          .map(([ weekId, survivor_pick ]) => {
            const pick = survivor_pick.pick
            const fixture = this.props.allFixtures.fixtures[weekId]
            const firstMatch = fixture[0]
            const isUserSelectedFirstMatch = firstMatch.home_team.id === pick || firstMatch.away_team.id === pick
        
            const isPassedDeadlineWeek = fixture?.time_left <= 0
              
            // Fecha de cuando paso el primer partido
            const isPassedFirstMatchDate = firstMatch?.survivor_time_left <= 0
              
            let isDisabled = isPassedDeadlineWeek
                
            // Soccer
            if (this.props.survivorInfo.sport_id === 1 && isPassedFirstMatchDate) {
              isDisabled = true
            } else {
              // NFL
              if (isPassedFirstMatchDate && isUserSelectedFirstMatch) {
                isDisabled = true
              } else if (!isUserSelectedFirstMatch && isPassedDeadlineWeek) {
                isDisabled = true
              }
            }
            
            return  {...survivor_pick, pick: isDisabled ? pick : null }
        })
        .reduce((a, b) => ({ ...a, [b.week_id]: b }), {})
      }

      const survivorPicks = getSurvivorPicks()

      if (!this.props.withRegister) {
        this.props.updateTemporalRegisterPicks({
          ...this.state.myEntry,
          survivor_picks: survivorPicks
        })
        return this.state.myEntry = {
          ...this.state.myEntry,
          survivor_picks: survivorPicks
        }
      }

      
      const data = {
        "survivor_entry_id": currentEntry.id,
        "survivor_id": this.props.survivorInfo.id,
        "survivor_picks": survivorPicks
      }

      if (currentEntry) {
        API.resetAllPicks(currentEntry.id, this.props.survivorInfo.id, data)
          .then(res => {})
      }

      this.state.myEntry = {
        ...this.state.myEntry,
        survivor_picks: survivorPicks
      }

      this.guardarPicksSurvivor('unset')
    }

    this.removePick = (pickId, weekId) => {

      this.state.myEntry = {
        ...this.state.myEntry,
        survivor_picks: {
          ...this.state.myEntry.survivor_picks,
          [weekId]: {
            ...this.state.myEntry.survivor_picks[weekId],
            pick: null
          }
        }
      }

      if (!this.props.withRegister) {
        this.props.updateTemporalRegisterPicks(this.state.myEntry)
        return this.setState({})
      }

      const currentEntry = !this.props.selectedEntryData ? null : this.props.selectedEntryData

      const data =  {
        "survivor_id": this.props.survivorInfo.id,
        "survivor_entry_id":currentEntry.id,
        "pick_id":pickId
      }
  
      if (currentEntry) {
        API.unsetPick(pickId, currentEntry.id, this.props.survivorInfo.id, data)
          .then(res => {
            this.guardarPicksSurvivor('unset')
          })
      }
    }

    this.initializeTeams = () => {
      let newTeams = {};

      this.props.survivorTeams.forEach((team) => {
        newTeams[team.id] = {
          selected: false,
          week_id: null,
        };
      });

      this.setState(
        {
          availableTeams: newTeams,
        },
        () => {
          this.initializePicks(this.props.survivorWeeks, this.props.entriesInfo);
        }
      );
    };

    this.handleRedirectToLobby = (entryToRedirect) => {
      console.log('Handle!', entryToRedirect);
      this.setState({
        redirectToLobby: true,
        redirectToEntry: entryToRedirect,
      });
    };

    /**
     * This function initialize the model in the state
     * to handle correctly the pick selected
     * @param { Array } weeks - the array of the available weeks
     * @param { Object } entries - the array of the available entries
     */
    this.initializePicks = (weeks) => {
      // console.log('%c Initialize Picks','color:#2E8CCF; font-size:2em;');
      // console.log('weeks',weeks);
      // console.log('entry',entries, this.props);
      let temporalPicks = {};
      let temporalAvTeams = this.state.availableTeams;
      // myEntry: {
      //   id: null,
      //   survivor_id: null,
      //   survivor_entry_id: null,
      //   user_id: null,
      //   survivor_picks: null,
      // },
      let temporalEntry = this.state.myEntry;
      
      /**
       * Create a pick for every Week
       */
      weeks.forEach((week, index) => {
        // console.log(week);
        let currentPick = null;

        if (
          this.props.withRegister &&
          this.props.selectedEntryData?.survivor_picks !== undefined &&
          this.props.selectedEntryData?.survivor_picks !== null
        ) {
          // console.log(typeof(this.props.selectedEntryData))
          if (typeof this.props.selectedEntryData === 'Array') {
            currentPick = !this.props.selectedEntryData?.survivor_picks[0]
              ? null
              : this.props.selectedEntryData?.survivor_picks[0].pick;
          } else {
            currentPick = !this.props.selectedEntryData?.survivor_picks[week.id]
              ? null
              : this.props.selectedEntryData?.survivor_picks[week.id].pick;
          }
          if (currentPick && temporalAvTeams) {
            console.log(currentPick);
            temporalAvTeams[currentPick].selected = true;
            temporalAvTeams[currentPick].week_id = week.id;
          }
        }
        if (this.props.selectedEntryData !== null) {
          // console.info(   this.props.selectedEntryData?.survivor_picks , typeof(this.props.selectedEntryData?.survivor_picks) );
        }
        temporalPicks[week.id] = {
          id: null,
          survivor_id: Number(this.props.survivorId),
          survivor_entry_id: this.props.withRegister ? this.props.selectedEntryData?.id : null,
          user_id: !this.props.userData ? null : this.props.userData.id,
          pick:
            this.props.withRegister &&
              currentPick !== -1 &&
              currentPick !== null &&
              this.props.selectedEntryData?.survivor_picks[week.id] !== null
              ? this.props.selectedEntryData?.survivor_picks[week.id].pick
              : null,
          week_id: week.id,
          fixture_id:
            this.props.withRegister &&
              currentPick !== -1 &&
              currentPick !== null &&
              this.props.selectedEntryData?.survivor_picks[week.id] !== null
              ? this.props.selectedEntryData?.survivor_picks[week.id].fixture_id
              : null,
        };
      });

      temporalEntry.user_id = Number(!this.props.userData ? null : this.props.userData.id);
      temporalEntry.survivor_id = Number(this.props.survivorId);
      temporalEntry.survivor_picks = temporalPicks;
      temporalEntry.survivor_entry_id = this.props.withRegister
        ? Number(this.props.selectedEntryData?.id)
        : null;

      this.setState({
        myEntry: temporalEntry,
        availableTeams: temporalAvTeams,
      });
    };
  }

  componentDidMount() {
    let showTutorial = utils.getTutorialSurvivorStatus();

    let showGameTutorial = utils.getShowGameTutorial();

    if (
      showGameTutorial &&
      showGameTutorial.hasOwnProperty('show') &&
      showGameTutorial.show &&
      showGameTutorial.step === 0
    ) {
      console.log('Show the abonar tutorial');
      this.props.showAbonarPanel();
      showTutorial = false;
    }

    if (utils.isLogged()) {
      // console.log(utils.getUserInfo());
      if (utils.isLogged() && utils.getUserInfo().survivors_entry_count < 1) {
        this.setState({
          showTutorial: showTutorial,
        });
      }
    } else {
      console.log('HEre');
      this.setState({
        showTutorial: showTutorial,
      });
    }
    // if ( this.state.survivorInfo.sport_id !== 6 ) {
    //   this.initializeTeams();
    // }
    if (this.props.survivorInfo.sport_id !== 6 && this.props.survivorInfo.sport_id !== 7) {
      this.initializeTeams();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.withRegister &&
      !prevProps.withRegister &&
      this.props.selectedEntryData?.survivor_picks !== null
    ) {
      // console.log('%c New Entry Register', 'color:#df4b26; font-size:2em;');
      this.initializePicks(this.props.survivorWeeks, this.props.entriesInfo);
      this.initializeTeams();
    } else if (this.props.withRegister && !prevProps.withRegister) {
      // console.log('%c New Entry Register', 'color:#df4b26; font-size:2em;');
      this.initializePicks(this.props.survivorWeeks, this.props.entriesInfo);
      this.initializeTeams();
    } else if (
      this.props.selectedEntryData &&
      this.props.selectedEntryData?.id !== prevProps?.selectedEntryData?.id
    ) {
      // console.log('%c CHanged Entry', 'color:#df4b26; font-size:2em;');
      this.initializePicks(this.props.survivorWeeks, this.props.entriesInfo);
      this.initializeTeams();
    }
  }

  render() {
    let isAlive = (this.props.entriesInfo !== null && this.props.entriesInfo !== undefined) && this.props.entriesInfo.survivor_entry ? this.props.entriesInfo.survivor_entry.survivor_standing : null;
    // console.log("SURVUI:", this.props.entriesInfo);
    let userStanding = this.props.standings.items.find((e) => e.user_id === this.props.userData.id);
    let conditionalComponent = null;
    if (this.state.redirectToLobby) {
      // console.log('Redirection!');
      // console.log(this.state.redirectToEntry);
      let nameQuina = this.props.survivorInfo.slug;
      conditionalComponent = (
        <Redirect
          to={{
            pathname: `/survivor/${nameQuina}/${this.props.survivorInfo.id}/${this.state.redirectToEntry}`,
          }}
        />
      );
    } else if (this.props.loadedPicks) {
      const weekFinded = this.props.survivorWeeks.find(
        (week) => week.id === this.props.selectedWeek
      );

      conditionalComponent = (
        <div id="picksQuiniela" className="lobby border-gradient" style={{marginRight: '5px'}}>
          <Modal
            backdropClassName="tutorialSurvivorBackdrop"
            dialogClassName="infoReferralsPopUp"
            show={this.state.showTutorial}
            onHide={this.hideTutorial}
          >
            <div className="panel-abonar">
              <div id="panelTitle" className="panel-abonar-titulo">
                <span>¿Cómo funciona el Survivor? </span>
                <button className="cerrar-panel" onClick={this.hideTutorial}>
                  <div className="btn-icon-close">
                    <i className="ion-ios-close-empty"></i>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-row row-no-padding pickwin-panel">
              <div className="flex-col panel-pickwin row-center">
                {this.props.survivorInfo.sport_id === 1 ? (
                  <img
                    src={tutorialsurvivorFut}
                    style={{ maxWidth: '473px', width: '100%', height: 'auto' }}
                    alt=""
                  />
                ) : (
                    <img
                      src={tutorialsurvivorNfl}
                      style={{ maxWidth: '473px', width: '100%', height: 'auto' }}
                      alt=""
                    />
                  )}
              </div>
            </div>
          </Modal>
          <Modal
            show={this.state.submitting}
            className="responseDepositModal absoluteModal"
            dialogClassName="responseDepositDialog"
            backdropClassName="responseDepositBackdrop"
          >
            <div className="flex-container justify align column">
              <i className="ion-load-a loading"> </i>
              <div className="flex-row row-center">
                <div className="flex-col col-center text-center">
                  <strong>Espera un momento porfavor...</strong>
                </div>
              </div>
            </div>
          </Modal>
          <RegisterPanel
            handleTutorialStep={this.props.handleTutorialStep}
            closePanel={this.closeRegisterPanel}
            entryId={this.props.selectedEntryId}
            handleConfirmation={this.handleConfirmation}
            handleRedirectToLobby={this.handleRedirectToLobby}
            logged={utils.isLogged()}
            payType={this.props.survivorInfo.entry_currency}
            pool={this.props.survivorInfo}
            pools={null}
            isPopUp={this.props.isPopUp}
            popUpNewUsersStatus={this.props.popUpNewUsersStatus}
            quinaID={this.props.survivorId}
            refreshAfterGuardar={this.props.refreshAfterGuardarInLobby}
            registerPicks={this.state.myEntry}
            returnErrors={this.returnErros}
            showPanel={this.state.showRegisterPanel}
            showTutorial={this.props.showTutorial}
            type={'survivor'}
            updateStateFunc={this.closeRegisterPanel}
            updateUserInfo={this.props.updateUserInfo}
            userData={this.props.userData}
            withRegister={this.props.withRegister}
          />
          <ConfirmationOverlay
            isError={true}
            showOverlay={this.state.errorSavePicks}
            label={'Error'}
            errorMessage={this.state.errorMessage}
          />
          <ConfirmationOverlay
            allPicks={this.props.fixtures.length}
            currentSavedPicks={this.state.currentsavedPicks}
            label="!Felicidades, tu pick de la semana se guardo con éxito!"
            showOverlay={this.state.guardarPicksStatus}
            type="survivor"
          />
          <div id="picksContainer" className="panel-pickwin">
            <div className="flex-row title-quiniela">
              <div className="flex-col col-no-padding title-wrapper">
                <div className="flex-row row-no-padding row-center">
                  <img src={picks} className="pool-icons tablas" alt="" />
                  <span>
                    MI PICKS
                  </span>
                  <span className="btn-help flex-container justify-end">
                    <ReactTooltip id="help-survivor" place="top" type="light" effect="solid">
                      ¿Cómo funciona el survivor?
                    </ReactTooltip>
                    <i
                      data-tip
                      data-for="help-survivor"
                      className="ion-help-circled"
                      onClick={this.showTutorial}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-row row-center deadline-info">
              <div className="flex-col col-center text-center">
                <span className="flex-container align justify">
                  <span className="flex-container align justify">
                    <i
                      className="ion-alert-circled"
                      style={{ color: '#fff900', marginRight: '5px' }}
                    ></i>
                    Fecha limite para registrar Pick:
                    <strong
                      style={{
                        color: 'rgb(244, 0, 52)',
                        textTransform: 'capitalize',
                        marginLeft: '3px',
                      }}
                    >
                      {moment(this.props.firstOf.deadline).format('ddd D MMM HH:mm')}
                    </strong>
                  </span>
                </span>
              </div>
            </div>


            <ListFixtures
              firstOf={this.props.firstOf}
              currentEntry={!this.props.selectedEntryData ? null : this.props.selectedEntryData}
              pointFormat={this.props.survivorInfo.point_format}
              currentWeek={this.props.currentWeek}
              selectedWeek={this.props.selectedWeek}
              entriesInfo={this.props.entriesInfo}
              myEntry={this.state.myEntry}
              survivorFixtures={this.props.fixtures}
              survivorTeams={this.state.availableTeams}
              teams={this.props.survivorTeams}
              survivorWeeks={this.props.survivorWeeks}
              handlePick={this.handlePick}
              resetPicks={this.resetPicks}
              removePick={this.removePick}
              updateStartedFixture={this.props.updateStartedFixture}
              withRegister={this.props.withRegister}
              survivorInfo={this.props.survivorInfo}
              allFixtures={this.props.allFixtures}
              previousWeek={this.props.previousWeek}
              updateStartedFirstMatchOfWeek={this.props.updateStartedFirstMatchOfWeek}
            />
            <div className="flex-row row-center row-no-padding">
              <div className="flex-col">

                  <CallToActionButton
                    withRegister={this.props.withRegister}
                    selecteEntry={this.props.selectedEntryData}
                    selectedWeek={this.props.selectedWeek}
                    currentWeek={this.props.currentWeek}
                    enoughBalance={this.props.enoughBalance}
                    submitting={this.state.submitting}
                    guardarPicksSurvivor={this.guardarPicksSurvivor}
                    updateUserInfo={this.props.updateUserInfo}
                    updateTemporalRegisterPicks={this.props.updateTemporalRegisterPicks}
                    myEntry={this.state.myEntry}
                    survivor={this.props.survivorInfo}
                    showRegisterPanel={this.showRegisterPanel}
                    showAbonarPanel={this.props.showAbonarPanel}
                    alive={isAlive}
                  />

              </div>
            </div>
          </div>
        </div>
      );
    } else {
      conditionalComponent = (
        <div className="panel-pickwin">
          <Loading label="..." />
        </div>
      );
    }

    return conditionalComponent;
  }
}

export default SurvivorUI;
