import styled from 'styled-components'

const HelpText = styled.div`
  margin-bottom: 2em;
  text-align: center;
  color: ${props => props.color || '#FFF'};

  strong {
    color: #88ba44;
    font-weight: bold;
    margin: 0 0.4em;
    cursor: pointer;
    :hover { opacity: 0.8; }
  }
`

export default HelpText
