import React, { Component } from 'react';

import { FormControl } from 'react-bootstrap';

class SelectGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderOptions = (options) => {
      let listOptions = options.map((option, optionIdx) => {
        // const stringObject = option.
        // console.log(option.label, option.value.disabled);
        return (
          <option
            disabled={option.value.disabled}
            key={!option.teamID ? optionIdx : option.teamID}
            value={this.props.label}
            data-value={JSON.stringify(option.value)}
          >
            {option.label}
          </option>
        );
      });

      return listOptions;
    };
  }

  componentDidMount() {}

  render() {
    // console.log(this.props.options);
    let jsonNull;
    if (this.props.options.length < 1) {
      return null;
    } else {
      jsonNull = JSON.stringify({
        disabled: false,
        groupID: this.props.options[0].value.groupID,
        groupShortName: this.props.options[0].value.groupShortName,
        matchId: this.props.options[0].value.matchID,
        selectionType: this.props.options[0].value.selectionType,
        teamID: null,
      });
      return (
        <FormControl
          componentClass="select"
          className={`select-team`}
          disabled={this.props.bracketStatus !== 'upcoming'}
          value={this.props.value}
          onChange={this.props.onChange}
        >
          {this.props.options[0].value.selectionType === 'second' ? (
            <option value="second" data-value={jsonNull}>
              2° Lugar del Grupo
            </option>
          ) : (
            <option value="first" data-value={jsonNull}>
              1° Lugar del Grupo
            </option>
          )}
          {this.renderOptions(this.props.options)}
        </FormControl>
      );
    }
  }
}

export default SelectGroup;
