import React from 'react';
import LobbyModal from '../LobbyModal';
import RegisterPanel from '../RegisterPanel';
import CancelRegister from '../CancelRegister';
import Loading from '../Loading';
import { Redirect } from 'react-router-dom';
import HeadersPool from '../HeadersPool';
import RegisterPanelMultyEntry from '../RegisterPanelMultyEntry';

import FiltersLobby from '../FiltersLobby';
import FilterControlsMobile from '../FilterControlsMobile';
import MediaQuery from 'react-responsive';
import RowLobby from '../RowLobby';
import gaUtils from '../GAWrapper';
import API from '../../utils/api';
import $ from 'jquery';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import utils from '../../utils/utils';

import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import { Modal } from 'react-bootstrap/lib/';

import firstBy from 'thenby';
import regaloNewUser from '../../assets/images/banners/regaloNewUser.jpg';

import { publish } from '../../utils/events'
//import createHistory from 'history/createBrowserHistory';

//const history = createHistory();
const history = require('history').createBrowserHistory();

let sortOrderState = [
  {
    id: 0,
    name: 'deporte',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 1,
    name: 'quinielas',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 2,
    name: 'tipo',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 3,
    name: 'entradas',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 4,
    name: 'bolsa',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 5,
    name: 'registro',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 6,
    name: 'cierre',
    order: false,
    actualState: 0,
    prevState: 0,
  },
  {
    id: 7,
    name: 'estatus',
    order: false,
    actualState: 0,
    prevState: 0,
  },
];
const cache = new CellMeasurerCache({
  defaultHeight: $(window).width() < 670 ? 120 : 32,
  fixedWidth: true,
  keyMapper: () => 1,
});

/**
 * This pop up is For new users and closes the current Popup if it is
 * @param popUpNewUsersStatus    { String }
 * @param popUpNewUsers          { Boolean }
 * @param changePoolPopUpView    { Function }
 * @param closePopUpNewUsers     { Function }
 * @return Component
 */
const PopUpNewUser = ({
  popUpNewUsersStatus,
  popUpNewUsers,
  changePoolPopUpView,
  closePopUpNewUsers,
}) => {
  return popUpNewUsersStatus ? (
    <Modal dialogClassName="popUpNewUsers" onHide={closePopUpNewUsers} show={popUpNewUsersStatus}>
      <div className="row">
        <div className="col-xs-12 banner-container">
          <div className="row">
            <div className="col-xs-offset-11 col-xs-1">
              <button className="cerrar-panel pop newUsers">
                <i
                  className="ion-ios-close-empty"
                  onClick={() => {
                    closePopUpNewUsers(true);
                  }}
                />
              </button>
            </div>
          </div>
          <div className="bannerContainer row">
            <div className="col-xs-10 col-xs-offset-1">
              <a
                onClick={(e) => {
                  console.log('Clicked', popUpNewUsers.poolId);
                  closePopUpNewUsers(true);
                  setTimeout(() => {
                    changePoolPopUpView(popUpNewUsers.poolId);
                  }, 500);
                }}
              >
                <img src={popUpNewUsers.bannerUrl} className="billboards" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};

/**
 * This pop up is For the Regalo of a user
 * @param popUpNewUsersStatus    { String }
 * @param showPopUpRegalo        { Boolean }
 * @param closePopUpRegalo       { Function }
 * @return Component
 */
const PopUpRegalo = ({ popUpNewUsers, showPopUpRegalo, closePopUpRegalo }) => {
  return showPopUpRegalo ? (
    <Modal dialogClassName="popUpNewUsers" onHide={closePopUpRegalo} show={showPopUpRegalo}>
      <div className="row">
        <div className="col-xs-12 banner-container">
          <div className="row">
            <div className="col-xs-offset-11 col-xs-1">
              <button className="cerrar-panel pop newUsers">
                <i className="ion-ios-close-empty" onClick={closePopUpRegalo} />
              </button>
            </div>
          </div>
          <div className="bannerContainer row">
            <div className="col-xs-10 col-xs-offset-1">
              <a
                onClick={(e) => {
                  closePopUpRegalo(e, popUpNewUsers.fallBackPoolId, true);
                }}
              >
                <img src={regaloNewUser} className="billboards" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};

class PoolsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogin: false,
      showRegister: false,
      itemSelected: null,
      loaded: false,
      logged: this.props.logged,
      mounted: false,
      multyEntry: [],
      popUpRegaloViewed: false,
      pools: this.props.pools,
      quinaID: null,
      bracket: null,
      survivor: null,
      registerPicks: null,
      isOrganic: null,
      redirectPool: null,
      redirectLogin: null,
      multyEntryChanged: false,
      showModal: false,
      showPanel: false,
      showTutorial: false,
      showPanelCancel: false,
      showPopUpRegalo: false,
      showPanelMultyEntry: false,
      showQuinielasPrivadas: false,
      sortOrder: sortOrderState,
      userData: this.props.userData,
    };

    this.handleClickPool = this.handleClickPool.bind(this);
    this.handleMultyEntry = this.handleMultyEntry.bind(this);
    this.handleRegistrationClick = this.handleRegistrationClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.registerMultyEntry = this.registerMultyEntry.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.sortOrder = this.sortOrder.bind(this);
    this.updatePanelCancelState = this.updatePanelCancelState.bind(this);
    this.updatePanelState = this.updatePanelState.bind(this);
    this.updatePanelRegisterState = this.updatePanelRegisterState.bind(this);
    this.updateState = this.updateState.bind(this);
    this.setItemSelected = this.setItemSelected.bind(this);

    /**
     * This Function open a popup of the pool
     * @param id { Number }
     *
     */
    this.openPoolPopUp = (id) => {
      console.log('OpenthisPool', id);

      let openThisPool = this.props.available.filter((pool) => pool.id === id);
      if (!openThisPool) {
        API.fetchIndividualPool(id).then((poolResponse) => {
          console.log('Fetch thisPool', poolResponse);
          openThisPool = [poolResponse.data];
        });
      }
      console.log('OpenthisPool', openThisPool);

      this.setState({
        quinaID: id,
        showModal: true,
        itemSelected: openThisPool,
      });
    };

    /**
     * This methods handles the login ppopup
     */
    this.hideLoginPopUp = () => {
      // this.setState({ showLogin: false });
      // TODO: When this component is migrated
      // will be dispatched from hook auth context
      publish('closeModal')
    };
    this.showLoginPopUp = () => {
      // this.setState({ showLogin: true });
      publish('openLoginModal')
    };

    /**
     * This Methods handles the registro popup
     */
    this.hideRegisterPopUp = () => {
      console.log('hideRegister');
      publish('closeModal')
      // this.setState({ showRegister: false });
    };

    this.showRegisterPopUp = () => {
      publish('openSignupModal')
      // this.setState({ showRegister: true });
    };

    /**
     * This function close the popupregao and opens a special pool after it was closed
     * @param e       { Object }
     * @param poolID  { Number }
     */
    this.closePopUpRegalo = (e, poolID, imageClicked) => {
      console.log('Close PopUp Regalo', poolID);
      this.setState({ showPopUpRegalo: false, popUpRegaloViewed: true }, () => {
        console.log('Delete local Storage');
        poolID = poolID !== undefined && poolID !== null ? this.redirectToFreeroll(poolID) : null;
        utils.deletePopUpNewUser();
        this.props.deleteNewUserStatus();
        if ($(window).width() <= 669 && !imageClicked) {
          // window.location = '/descargar_app';
          utils.setFirstTimeUser();
          utils.setFromLanding();
        } else if (!imageClicked) {
          console.log('Cerraron regalo');
          utils.setFromLanding();
          utils.setShowTutorial();
        } else if (imageClicked) {
          this.openPoolPopUp(poolID);
          utils.setFromLanding();
        }
      });
    };
    /**
     * This function redirects to the free  roll after close the popup
     * @param poolId { Number }
     */
    this.redirectToFreeroll = (poolId) => {
      console.log('Redirect to FreeRoll', poolId);
      this.setState(
        {
          quinaID: poolId,
          itemSelected: this.props.available.filter((pool) => pool.id === poolId),
          showModal: true,
          mounted: true,
        },
        () => {
          history.push({ pathname: `/lobby/quinielaPop/quinielaFreeRoll/${poolId}` });

          let fakeMatch = {
            isExact: true,
            params: {
              slug: 'Quiniela Free Roll',
              poolId: poolId,
            },
            path: `/lobby/quinielaPop/:slug/:poolId`,
            url: `/lobby/quinielaPop/quinielaFreeRoll/poolId}`,
          };
          gaUtils.handleDocumentAttributes(fakeMatch);
        }
      );
    };
    /**
     * This method open the popup regalo
     * @param e { Object }
     */
    this.openPopUpRegalo = (e) => {
      this.setState({ showPopUpRegalo: true });
    };

    /**
     * This methods handles thepop up of the  tutorial
     */
    this.showTutorial = () => {
      this.setState({ showTutorial: true });
    };
    this.closeTutorial = () => {
      this.setState({ showTutorial: false }, () => {
        utils.deletePopUpNewUser();
        this.props.deleteNewUserStatus();
      });
    };

    /**
     * This Method close the QuinielaPopup
     *
     */
    this.closeQuinielaPopUp = () => {
      this.setState({ showModal: false });
      utils.removeDataNotLoggedUser();
      if (this.state.redirectPool === false || this.state.redirectPool === null) {
        history.push({pathname: '/lobby'});

        gaUtils.handleDocumentAttributes();
      }
    };

    this.closePanel = () => {
      this.setState({ showPanel: false, itemSelected: null });
    };

    this.registerPop = () => {
      this.setState({ registerState: false });
    };

    this.registerState = (regState) => {
      this.setState({ registerState: regState });
    };
    this.updateMultiEntryState = () => {
      this.setState({ showPanelMultyEntry: false });
    };

    this.updateRegisterPicks = (temPicks) => {
      console.log('UpdateRegisterPicks');
      this.setState({ registerPicks: temPicks });
    };
  }

  componentDidMount() {
    let showTutorial = utils.getShowTutorial();
    let showGameFreeRoll = utils.getShowGameFreeRoll();
    let isOrganic = utils.getShowTutorialLobby();
    let openLandingPool = this.props.popUpNewUsersStatus;
    // console.log( this.props.popUpNewUsersStatus , openLandingPool );
    if (utils.isLogged() && !utils.isVerified(utils.getUserInfo())) {
      this.setState({ notVerified: true, mounted: true });
    }
    // console.log(this.props.popUpPoolId, this.props.popUpView );
    if (this.props.popUpView && this.props.popUpPoolId) {
      // console.log('normal pool');
      if (openLandingPool && this.props.popUpNewUsers.gameId) {
        // console.log('%c PopUpNewUser Pool', 'font-size:2em');
        // let landingGame = null;
        // switch( this.props.popUpNewUsers.gameType ){
        //   case 'pool':
        //   break;
        //   case 'bracket':
        //   break;
        //   case 'survivor':
        //   break;
        //   case 'group_pool':
        //   break;
        // }

        let landingPool = this.props.available.find(
          (pool) =>
            pool.id === this.props.popUpNewUsers.gameId &&
            pool.game_type === this.props.popUpNewUsers.gameType
        );

        if (landingPool !== -1 && landingPool) {
          let synthEvent = { target: { attributes: { id: 'register' } } };
          this.setState(
            {
              mounted: true,
            },
            () => {
              this.handleClickPool(landingPool.id, landingPool.game_type, synthEvent);
            }
          );
        }
      } else if (this.props.externalPool !== null) {
        console.log('Here');
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          quinaID: this.props.popUpPoolId,
          itemSelected: [this.props.externalPool.pool],
          showModal: this.props.popUpView,
          mounted: true,
        });
      } else {
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          quinaID: this.props.popUpPoolId,
          itemSelected: this.props.available.filter((pool) => {
            if (pool.id === this.props.popUpPoolId) {
              return pool;
            }
          }),
          showModal: this.props.popUpView,
          mounted: true,
        });
      }
    } else if (this.props.popUpView && this.props.popUpPoolGroupId) {
      // console.log('%c pool Group', 'color:rgb(255, 199, 48); font-size:2em;');
      if (openLandingPool && this.props.popUpNewUsers.poolId) {
        // console.log('%c PopUpNewUser Pool', 'font-size:2em');
        let landingPool = this.props.available.find(
          (pool) => pool.id === this.props.popUpNewUsers.poolId && pool.game_type === 'pool'
        );

        if (landingPool !== -1 && landingPool) {
          let synthEvent = { target: { attributes: { id: 'register' } } };
          this.setState(
            {
              mounted: true,
            },
            () => {
              this.handleClickPool(landingPool.id, landingPool.game_type, synthEvent);
            }
          );
        }
      } else if (this.props.externalPool !== null) {
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          quinaGrupoID: this.props.popUpPoolGroupId,
          itemSelected: [this.props.externalPool.group_pool],
          showModal: this.props.popUpView,
          mounted: true,
        });
      } else {
        console.log('external pool doesnt exist');
        history.push({ pathname: '/lobby' });
        gaUtils.handleDocumentAttributes();
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          quinaGrupoID: this.props.popUpPoolGroupId,
          itemSelected: this.props.available.filter((pool) => {
            if (pool.id === this.props.popUpPoolGroupId) {
              // console.log(pool);
              return pool;
            }
          }),
          showModal: this.props.popUpView,
          mounted: true,
        });
      }
    } else if (
      this.props.popUpView &&
      this.props.popUpBracketId !== undefined &&
      this.props.popUpBracketId !== null
    ) {
      console.log('normal bracket', );
      if (this.props.externalPool !== null) {
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          bracketID: this.props.popUpBracketId,
          itemSelected: [this.props.externalPool],
          showModal: this.props.popUpView,
          mounted: true,
        });
      } else {
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          bracketID: this.props.popUpBracketId,
          itemSelected: this.props.available.filter((pool) => {
            if (pool.id === this.props.popUpBracketId) {
              console.log(pool);
              return pool;
            }
          }),
          showModal: this.props.popUpView,
          mounted: true,
        });
      }
    } else if (
      this.props.popUpView &&
      this.props.popUpSurvivorId !== undefined &&
      this.props.popUpSurvivorId !== null
    ) {
      console.log('normal survivor');
      if (this.props.externalPool !== null) {
        // console.log(this.props.externalPool);
        let selectedItem = this.props.externalPool;
        if (this.props.externalPool.hasOwnProperty('survivor')) {
          selectedItem = this.props.externalPool.survivor;
        }
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          survivorID: this.props.popUpSurvivorId,
          itemSelected: [selectedItem],
          showModal: this.props.popUpView,
          mounted: true,
        });
      } else {
        this.setState({
          isOrganic: showGameFreeRoll,
          showGameFreeRoll: showGameFreeRoll,
          showTutorial: showTutorial,
          survivorID: this.props.popUpSurvivorId,
          itemSelected: this.props.available.filter((pool) => {
            if (pool.id === this.props.popUpSurvivorId) {
              console.log(pool);
              return pool;
            }
          }),
          showModal: this.props.popUpView,
          mounted: true,
        });
      }
    } else if (openLandingPool && this.props.popUpNewUsers.poolId) {
      console.log('%c PopUpNewUser Pool', 'font-size:2em');
      let landingPool = this.props.available.find(
        (pool) => pool.id === this.props.popUpNewUsers.poolId && pool.game_type === 'pool'
      );

      if (landingPool !== -1 && landingPool) {
        let synthEvent = { target: { attributes: { id: 'register' } } };
        this.setState(
          {
            isOrganic: showGameFreeRoll,
            mounted: true,
          },
          () => {
            this.handleClickPool(landingPool.id, landingPool.game_type, synthEvent);
          }
        );
      }
    } else {
      // console.log('normal behaviour');
      this.setState({
        isOrganic: showGameFreeRoll,
        showGameFreeRoll: showGameFreeRoll,
        showTutorial: showTutorial,
        mounted: true,
      });
    }
  }

  /**
   * This method handle sthe sort orfer of the pools
   * @param e { Object }
   */
  sortOrder(e) {
    console.log('SortOrder PoolList');

    let categoryClicked = e;

    //  console.table(this.props.pools);
    console.log(categoryClicked);
    let poolS;
    let selected;
    selected = sortOrderState.find((wich) => wich.name === categoryClicked);
    !selected.order ? (selected.order = true) : (selected.order = !selected.order);

    switch (categoryClicked) {
      case 'deporte':
        // selected.order = !selected.order
        if (selected.order) {
          //Invierte el orden
          poolS = this.props.pools.sort(firstBy('sport_id', -1).thenBy('sort_order', -1));
          // console.log('deporte',selected);
        } else {
          //The first click or initial state
          poolS = this.props.pools.sort(firstBy('sport_id', 1).thenBy('sort_order', -1));
        }
        // this.setState({
        //   pools: poolS,
        //   updatePools: true
        // });
        // console.log('estado Real', this.state.sortOrder);
        break;
      case 'quinielas':
        // console.log('%c Sorted by Quinielas', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(firstBy('name_replaced', -1).thenBy('sort_order', -1));
        } else {
          poolS = this.props.pools.sort(firstBy('name_replaced', 1).thenBy('sort_order', -1));
        }
        break;
      case 'tipo':
        // console.log('%c Sorted by Tipo', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(
            firstBy('point_format', -1)
              .thenBy('format', -1)
              .thenBy('sort_order', -1)
          );
        } else {
          poolS = this.props.pools.sort(
            firstBy('point_format', 1)
              .thenBy('format', 1)
              .thenBy('sort_order', -1)
          );
        }
        break;
      case 'entradas':
        // console.log('%c Sorted by Entradas', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(
            firstBy('entry_fee', -1)
              .thenBy('sort_order', -1)
              .thenBy('entry_currency', -1)
          );
        } else {
          poolS = this.props.pools.sort(
            firstBy('entry_fee', 1)
              .thenBy('sort_order', -1)
              .thenBy('entry_currency', -1)
          );
        }
        // console.table(poolS);
        let pickcoinsPool = [],
          realMoneyPool = [];
        pickcoinsPool = poolS.filter((pool) => pool.entry_currency === 'pickcoin');
        realMoneyPool = poolS.filter((pool) => pool.entry_currency === 'real');
        poolS = [...realMoneyPool, ...pickcoinsPool];
        // console.log('New Pools', newPools);
        // this.setState({ pools: newPools, updatePools: true, }, () => {
        //   this.props.updateSortedPools(newPools);,
        // });
        break;
      case 'bolsa':
        console.log('%c Sorted by Bolsa', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(firstBy('available', -1).thenBy('sort_order', -1));
        } else {
          poolS = this.props.pools.sort(firstBy('available', 1).thenBy('sort_order', -1));
        }
        console.log(poolS);
        break;
      case 'cierre':
        // console.log('%c Sorted by Cierre', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(firstBy('deadline_tz', -1).thenBy('sort_order', -1));
        } else {
          poolS = this.props.pools.sort(firstBy('deadline_tz', 1).thenBy('sort_order', -1));
        }
        // console.table(poolS);
        break;
      case 'registro':
        // console.log('%c Sorted by Registro', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(firstBy('entry_count', -1).thenBy('sort_order', -1));
        } else {
          poolS = this.props.pools.sort(firstBy('entry_count', 1).thenBy('sort_order', -1));
        }
        // console.table(poolS);
        break;
      case 'estatus':
        // console.log('%c Sorted by Estatus', 'font-size:20px');
        if (selected.order) {
          poolS = this.props.pools.sort(
            firstBy('status', -1)
              .thenBy('entries', -1)
              .thenBy('sort_order', -1)
          );
        } else {
          poolS = this.props.pools.sort(
            firstBy('status', 1)
              .thenBy('entries', 1)
              .thenBy('sort_order', -1)
          );
        }
        break;

      default:
        break;
    }
    this.setState(
      {
        pools: poolS,
      },
      () => {
        this.props.updateSortedPools(poolS);
      }
    );
  }

  updateState() {
    console.log('UpdateState');
    utils.removeDataNotLoggedUser();
    this.setState(
      {
        showModal: false,
        // timeleft:this.props.pools.time_left,
      },
      () => {
        this.setState(
          {
            itemSelected: null,
            quinaID: null,
          },
          () => {
            if (utils.getFromLanding()) {
              utils.removeFromLanding();
              utils.setFirstTimeUser();
              // utils.setShowTutorial()
            }
            console.log(this.state.isOrganic);
            if (this.state.isOrganic) {
              console.log('Is organic and closed popup');
              this.props.handleTutorialStep(1, 'help', true);
              utils.removeTutorialLobby();
            }
            if (utils.isNewUserForProduct()) {
              console.log('is Newuser');
              utils.deletePopUpNewUser();
            }
          }
        );
      }
    );
    console.log(this.state);
    // if( Landing )

    if (this.state.redirectPool === false || this.state.redirectPool === null) {
      history.push({ pathname: '/lobby' });
      gaUtils.handleDocumentAttributes();
    }
  }

  updatePanelCancelState(pool) {
    console.log('update Cancel', pool);
    this.setState(
      {
        quinaID: pool.id,
        itemSelected: [pool],
        showPanelCancel: true,
      },
      () => {
        console.log('Now');
        console.log(this.state);
      }
    );
  }

  updatePanelState() {
    // console.log('Modal update');

    this.setState({
      showModal: true,
    });
  }

  updatePanelRegisterState(e, pool) {
    console.log('update', e, pool);
    if (!pool) {
      return;
    }
    this.setState({
      quinaID: pool.id,
      itemSelected: [pool],
      showPanel: true,

      bracketID: pool.game_type === 'bracket' ? pool.id : undefined,
      quinaID: pool.game_type === 'pool' ? pool.id : undefined,
      quinaGrupoID: pool.game_type === 'group_pool' ? pool.id : undefined,
      survivorID: pool.game_type === 'survivor' ? pool.id : undefined,
    });


    //this.setState({showPanel: true, showPanelMultyEntry: true, showModal: true, showRegister: true});
    //console.log('STATE:', this.state.showPanel);
  }

  registerMultyEntry(e) {
    // console.log("%c Register  multy Entries", "backgorund:rgb(92, 164, 0)");
    // console.log(this.state.multyEntry);
    this.setState({
      showPanelMultyEntry: true,
    });
  }

  handleMultyEntry(e) {
    let idSelected = Number(e.target.value);
    // console.log(
    //   "%c Adding Multi Entries",
    //   "background:#E62865;font-size:20px;"
    // );
    // console.log(this);
    console.log(idSelected);
    const userData = utils.getUserInfo();
    if (!userData) {
      return;
    }
    // let balanceReal = userData.balanceReal;
    let balance = null;
    let temporalMultiEntry = this.state.multyEntry;
    let isRepeated;
    let indexSelected;
    let poolSelected;
    isRepeated = temporalMultiEntry.find((entry, i) => {
      if (entry.idPool === idSelected) {
        indexSelected = i;
        return entry;
      }
    });
    // console.log(isRepeated);
    // console.log(idSelected);
    if (isRepeated === undefined) {
      let temporalObj = {};

      poolSelected = this.props.pools.find((pool) => pool.id === idSelected);

      balance =
        poolSelected.entry_currency === 'real'
          ? userData.balanceReal
          : poolSelected.entry_currency === 'pickcoin'
          ? userData.balancePc
          : userData.balanceReferral;

      let maxEntries = Math.floor(balance / poolSelected.entry_fee);

      temporalObj = {
        pool: poolSelected,
        qnty: 0,
        idPool: idSelected,
        maxEntries:
          poolSelected.pickngo && maxEntries > poolSelected.max_entries - poolSelected.entry_count
            ? poolSelected.max_entries - poolSelected.entry_count
            : maxEntries,
        totalEntries: 0 * (poolSelected.entry_fee / 100 + poolSelected.commission_fee / 100),
        payType: poolSelected.entry_currency,
      };
      // console.log(temporalMultiEntry);
      temporalMultiEntry.push(temporalObj);
      this.setState(
        {
          multyEntry: temporalMultiEntry,
          multyEntryChanged: true,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                multyEntryChanged: false,
              },
              () => {
                if (this.list !== undefined && this.list !== null) {
                  this.list.forceUpdateGrid();
                }
              }
            );
          }, 250);
        }
      );
    } else {
      //let currentIndex = temporalMultiEntry.indexOf(idSelected);
      // console.log('Repeated', isRepeated, currentIndex, indexSelected);
      temporalMultiEntry.splice(indexSelected, 1);
      this.setState(
        {
          multyEntry: temporalMultiEntry,
          multyEntryChanged: true,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                multyEntryChanged: false,
              },
              () => {
                if (this.list !== undefined && this.list !== null) {
                  this.list.forceUpdateGrid();
                }
              }
            );
          }, 250);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const updateForceList = () => {
      if (this.list !== undefined && this.list !== null) {
        this.list.forceUpdateGrid();
      }
    };

    if (this.props.updatePools !== prevProps.updatePools) {
      // console.log('Update Pools');
      this.setState(
        {
          logged: this.props.logged,
          pools: this.props.pools,
          userData: this.props.userData,
        },
        () => {
          updateForceList();
        }
      );
    }

    if (this.state.multyEntry.length !== prevState.multyEntry.length) {
      // console.log( 'multyEntry Changed' );
      if (this.list !== undefined && this.list !== null) {
        this.list.forceUpdateGrid();
      }
    }
    if (this.props.poolsChanged && this.props.poolsChanged !== prevProps.poolsChanged) {
      // console.log('Update Pools');
      // console.log(this.list);
      if (this.list !== undefined && this.list !== null) {
        this.list.forceUpdateGrid();
      }
      this.setState(
        {
          logged: this.props.logged,
          pools: this.props.pools,
          userData: this.props.userData,
        },
        () => {
          if (this.list !== undefined && this.list !== null) {
            this.list.forceUpdateGrid();
          }
        }
      );
    }

    if (
      this.state.itemSelected !== undefined &&
      this.state.itemSelected !== null &&
      this.state.showModal === true
    ) {
      if (!this.state.itemSelected[0]) {
        return;
      } else {
        //console.log('DIDUPDATE', this.state.itemSelected);
        if (this.state.itemSelected[0].game_type === 'bracket') {
          history.push({
            pathname: `/lobby/bracketPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
          });
          let fakeMatch = {
            isExact: true,
            params: {
              slug: this.state.itemSelected[0].slug,
              bracketId: this.state.itemSelected[0].id,
            },
            path: `/lobby/bracketPop/:slug/:bracketId`,
            url: `/lobby/bracketPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}}`,
          };
          gaUtils.handleDocumentAttributes(fakeMatch);
        } else if (this.state.itemSelected[0].game_type === 'pool') {
          history.push({
            pathname: `/lobby/quinielaPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
          });
          let fakeMatch = {
            isExact: true,
            params: {
              slug: this.state.itemSelected[0].slug,
              poolId: this.state.itemSelected[0].id,
            },
            path: `/lobby/quinielaPop/:slug/:poolId`,
            url: `/lobby/quinielaPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}}`,
          };
          gaUtils.handleDocumentAttributes(fakeMatch);
        } else if (this.state.itemSelected[0].game_type === 'survivor') {
          history.push({
            pathname: `/lobby/survivorPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
          });
          let fakeMatch = {
            isExact: true,
            params: {
              slug: this.state.itemSelected[0].slug,
              survivorId: this.state.itemSelected[0].id,
            },
            path: `/lobby/survivorPop/:slug/:survivorId`,
            url: `/lobby/survivorPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}}`,
          };
          gaUtils.handleDocumentAttributes(fakeMatch);
        } else if (this.state.itemSelected[0].game_type === 'group_pool') {
          history.push({
            pathname: `/lobby/quinielaGrupoPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
          });
          let fakeMatch = {
            isExact: true,
            params: {
              slug: this.state.itemSelected[0].slug,
              groupPoolId: this.state.itemSelected[0].id,
            },
            path: `/lobby/quinielaGrupoPop/:slug/:poolGroupId`,
            url: `/lobby/quinielaGrupoPop/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}}`,
          };
          gaUtils.handleDocumentAttributes(fakeMatch);
        }
      }
    }
    if (this.props.userData !== null && prevProps.userData === null) {
      this.setState({
        logged: utils.isLogged(),
        pools: this.props.pools,
        userData: this.props.userData,
      });
    }
    if (this.props.logged && !prevProps.logged) {
      console.log('User logged in');
      this.setState({
        logged: utils.isLogged(),
        pools: this.props.pools,
        userData: this.props.userData,
      });
    }

    if (
      this.props.userData !== null &&
      prevProps.userData !== null &&
      this.props.userData.balanceReal !== prevProps.userData.balanceReal
    ) {
      this.setState({
        userData: this.props.userData,
      });
    }

    if (
      this.props.bannerChanged !== prevProps.bannerChanged ||
      (this.props.popUpView &&
        !prevProps.popUpView &&
        this.props.popUpPoolId !== prevProps.popUpPoolId)
    ) {
      console.log('Debugging PopUp');

      let clickedPool = this.props.available.filter((pool) => {
        if (pool.id === this.props.popUpPoolId) {
          console.log(pool);
          return pool;
        }
      });

      console.log(clickedPool);
      if (clickedPool === undefined || clickedPool === -1 || !clickedPool.length) {
        console.log('Pool isnt avaialble');
        API.fetchIndividualPool(this.props.popUpPoolId).then((response) => {
          this.setState(
            {
              quinaID: this.props.popUpPoolId,
              itemSelected: [response.data.pool],
              showModal: this.props.popUpView,
              mounted: true,
            },
            () => {
              if (clickedPool) {
                history.push({
                  pathname: `/lobby/quinielaPop/${
                    !this.state.itemSelected ? 'quinielaRedirect' : this.state.itemSelected[0].slug
                  }/${this.props.popUpPoolId}`,
                });
                let fakeMatch = {
                  isExact: true,
                  params: {
                    slug: this.state.itemSelected[0].slug,
                    poolId: this.state.itemSelected[0].id,
                  },
                  path: `/lobby/quinielaPop/:slug/:poolId`,
                  url: `/lobby/quinielaPop/${
                    !this.state.itemSelected ? 'quinielaRedirect' : this.state.itemSelected[0].slug
                  }/${this.props.popUpPoolId}}`,
                };
                gaUtils.handleDocumentAttributes(fakeMatch);
              }
            }
          );
        });
      } else {
        this.setState(
          {
            quinaID: this.props.popUpPoolId,
            itemSelected: clickedPool,
            showModal:
              this.props.popUpView === true && clickedPool !== -1 && clickedPool !== undefined
                ? true
                : false,
            mounted: clickedPool !== -1 && clickedPool !== undefined,
          },
          () => {
            if (clickedPool) {
              history.push({
                pathname: `/lobby/quinielaPop/${
                  !this.setState.itemSelected ? 'quinielaRedirect' : this.state.itemSelected[0].slug
                }/${this.props.popUpPoolId}`,
              });
              let fakeMatch = {
                isExact: true,
                params: {
                  slug: this.state.itemSelected[0].slug,
                  poolId: this.state.itemSelected[0].id,
                },
                path: `/lobby/quinielaPop/:slug/:poolId`,
                url: `/lobby/quinielaPop/${
                  !this.state.itemSelected ? 'quinielaRedirect' : this.state.itemSelected[0].slug
                }/${this.props.popUpPoolId}}`,
              };
              gaUtils.handleDocumentAttributes(fakeMatch);
            }
          }
        );
      }
    }
    if (this.props.popUpNewUsersStatus) {
      // console.log(this.props.popUpNewUsersStatus);
      // console.log(this.props.popUpNewUsers);
      // this.setState({
      //   showTutorial: true
      // });
    }

    if (this.props.isReconectSocketLobby) {
      setTimeout(() => {
        updateForceList();
      }, 300);
    }
  }

  handleRegistrationClick(e) {
    console.log('%c Show Panel', 'font-size:20px');
    this.setState({
      showPanel: false,
    });
  }

  handleCancelClick(e) {
    if (!this.state.showPanelCancel) {
      return;
    }
    console.log('%c Show Panel Cancel', 'font-size:20px');
    this.setState({
      showPanelCancel: false,
    });
  }

  handleClickPool(poolId, gameType, e, withRegister) {
    if (!poolId || !gameType) {
      return;
    }

    let gameSelected = this.props.available.filter(
      (quiniela) => quiniela.id === poolId && gameType === quiniela.game_type
    );
    // console.log(gameSelected);

    this.setState((prevState) => ({
      bracketID: gameSelected[0].game_type === 'bracket' ? poolId : undefined,
      quinaID: gameSelected[0].game_type === 'pool' ? poolId : undefined,
      quinaGrupoID: gameSelected[0].game_type === 'group_pool' ? poolId : undefined,
      survivorID: gameSelected[0].game_type === 'survivor' ? poolId : undefined,
      itemSelected: gameSelected,
      redirectPool: null,
    }));
    this.updatePanelState();
  }

  renderRows(props) {
    // console.log('renderRows', props);
    let items;
    // if( this.state.redirectVerify ){
    //   console.error('Cuenta sin verify')
    //   return <Redirect to={{pathname: '/verificarCuenta'}}/>
    // }

    if (this.state.mounted) {
      items = this.props.pools;
      // console.log(items[ props.index ].game_type);

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={1}
          key={props.key}
          parent={props.parent}
          rowIndex={props.index}
        >
          {({ measure }) => (
            <div
              className={`${props.index % 2 ? 'even-colored-row' : ''}`}
              key={props.key}
              style={props.style}
            >
              <RowLobby
                multyEntry={this.state.multyEntry}
                multyEntryChanged={this.state.multyEntryChanged}
                changePoolStatus={this.props.changePoolStatus}
                changeStatusPool={this.props.changeStatusPool}
                handleClickPool={this.handleClickPool}
                handleMultyEntry={this.handleMultyEntry}
                index={props.index}
                logged={this.state.logged}
                pool={items[props.index]}
                showPanel={this.state.showPanel}
                showLoginPopUp={this.showLoginPopUp}
                showPanelCancel={this.state.showPanelCancel}
                updatedPool={this.props.updatedPool}
                updatePools={this.props.updatePools}
                poolsChanged={this.props.poolsChanged}
                updateStateFunc={this.updatePanelRegisterState}
                updateStateFuncCancel={this.updatePanelCancelState}
                userDataProps={this.state.userData}
              />
            </div>
          )}
        </CellMeasurer>
      );
    }
  }

  rowRenderer(props) {
    let listItems = this.props.pools;
    // console.log('Row Renderer Monbile',this.props, props);
    // console.log(props, listItems[props.index]);
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={props.key}
        parent={props.parent}
        rowIndex={props.index}
      >
        {({ measure }) => (
          <div
            className={`${props.index % 2 ? 'even-colored-row' : ''}`}
            key={props.key}
            style={props.style}
          >
            {/* <RowPoolMobile
              changePoolStatus={this.props.changePoolStatus}
              changeStatusPool={this.props.changeStatusPool}
              handleClickPool={this.handleClickPool}
              handleMultyEntry={this.handleMultyEntry}
              index={props.index}
              key={listItems[props.index].id}
              logged={this.state.logged}
              pool={listItems[props.index]}
              showPanel={this.state.showPanel}
              showLoginPopUp={this.showLoginPopUp}
              updatedPool={this.props.updatedPool}
              updatePools={this.props.updatePools}
              updateStateFunc={this.handleRegistrationClick}
              userDataProps={this.state.userData}
            /> */}
          </div>
        )}
      </CellMeasurer>
    );
  }

  setItemSelected(survivor) {
    let tempItemSelected = this.state.itemSelected;
    if (tempItemSelected[0] && survivor) {
      tempItemSelected[0].contests = survivor.contests;
    }
    this.setState({
      itemSelected: tempItemSelected,
    });
  }

  render() {
    let items;
    let listItems;
    let conditionalComponent;
    if (!this.state.mounted) {
      conditionalComponent = (
        <div className="pool-container">
          <Loading label="Cargando quinielas ..." />
        </div>
      );
    } else if (this.state.mounted) {
      items = this.props.pools;
      listItems = items.map((item, i) => i);
      // console.log(listItems)
    }

    // if(this.state.notVerified){
    //   conditionalComponent = (
    //     <Redirect
    //       to={{pathname:'verificarCuenta'}}
    //       />
    //   )
    // } else
    if (this.state.redirectLogin) {
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `ingresar`,
          }}
        />
      );
    } else if (this.state.redirectPool === true) {
      console.log('REdirectPool', this.state);
      if (
        this.state.itemSelected[0].first_entry !== undefined &&
        this.state.itemSelected[0].first_entry !== null
      ) {
        console.log('redirecting', this.state.itemSelected[0]);
        if (this.state.itemSelected[0].game_type === 'pool') {
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/quiniela/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}/${this.state.itemSelected[0].first_entry.id}`,
              }}
            />
          );
        } else if (this.state.itemSelected[0].game_type === 'survivor') {
          console.log('survivor');
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/survivor/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}/${this.state.itemSelected[0].first_entry.id}`,
              }}
            />
          );
        } else if (this.state.itemSelected[0].game_type === 'group_pool') {
          console.log('survivor');
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/quinielaGrupo/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}/${this.state.itemSelected[0].first_entry.id}`,
              }}
            />
          );
        } else {
          console.log("Bracket Basket");
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/bracket/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}/${this.state.itemSelected[0].first_entry.id}`,
              }}
            />
          );
        }
      } else {
        console.log('redirectin without log');
        if (this.state.itemSelected[0].game_type === 'pool') {
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/quiniela/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
              }}
            />
          );
        } else if (this.state.itemSelected[0].game_type === 'survivor') {
          console.log('survivor');
          console.log(this.state.itemSelected[0]);
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/survivor/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
              }}
            />
          );
        } else if (this.state.itemSelected[0].game_type === 'group_pool') {
          console.log('survivor');
          console.log(this.state.itemSelected[0]);
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/quinielaGrupo/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
              }}
            />
          );
        } else {
          console.log("bracket basket");
          conditionalComponent = (
            <Redirect
              push
              to={{
                pathname: `/bracket/${this.state.itemSelected[0].slug}/${this.state.itemSelected[0].id}`,
              }}
            />
          );
        }
      }
    } else {
      conditionalComponent = (
        <div className="pool-container loaded">
          <MediaQuery minWidth={670}>
            <FiltersLobby
              filtering={this.props.filtering}
              typeBar="pools"
              setTitle={this.state.tournament}
              setTitleFunc={this.setTitleDrop}
              filterBar={this.props.filterBar}
              filteredPool={this.filteredPool}
              activeFiltersProps={this.state.activeFilters}
              pools={this.props.available}
            />
          </MediaQuery>
          <MediaQuery maxWidth={669}>
            <FilterControlsMobile
              typeBar="pools"
              setTitle={this.state.tournament}
              setTitleFunc={this.setTitleDrop}
              filterBar={this.props.filterBar}
              // filterBarStatusProp={this.props.filterBarStatusProp}
              // filterBarStatusProp={this.filterBarStatus}
              filteredPool={this.filteredPool}
              activeFiltersProps={this.state.activeFilters}
              pools={this.props.available}
            />
          </MediaQuery>
          <MediaQuery minWidth={670}>
            <HeadersPool
              multyEntry={this.state.multyEntry}
              sortOrderState={this.state.sortOrder}
              sortOrderFunc={this.sortOrder}
              registerMultyEntry={this.registerMultyEntry}
            />
          </MediaQuery>
          <div className={`pool-container-list overflow overflow-y overflow-half-screen`}>
            {this.state.mounted ? (
              !listItems.length ? (
                <div className="no-pools-available flex-container column align justify">
                  <i className="ion-sad big-ionicon " />
                  <span className="text-center">
                    Lo sentimos, por el momento no tenemos ninguna quiniela que se ajuste a los
                    filtros que seleccionaste.
                  </span>
                </div>
              ) : (
                <ul className="pools-list mobile">
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        ref={(list) => (this.list = list)}
                        deferredMeasurementCache={cache}
                        height={height}
                        overscanRowCount={1}
                        rowCount={this.props.pools.length}
                        rowHeight={$(window).width() < 769 ? 120 : 32}
                        width={width}
                        rowRenderer={this.renderRows}
                      />
                    )}
                  </AutoSizer>
                </ul>
              )
            ) : (
              conditionalComponent
            )}
            <LobbyModal
              handleTutorial={this.props.handleTutorial}
              handleTutorialStep={this.props.handleTutorialStep}
              available={this.props.available}
              bracketID={
                this.state.bracketID !== undefined || this.state.bracketID !== null
                  ? this.state.bracketID
                  : this.props.popUpBracketId
              }
              handleCancelClick={this.handleCancelClick}
              items={this.state.itemSelected}
              logged={this.state.logged}
              openPopUpRegalo={this.openPopUpRegalo}
              pools={this.state.pools}
              popUpNewUsers={this.props.popUpNewUsers}
              popUpNewUsersStatus={this.props.popUpNewUsersStatus}
              quinaID={this.state.quinaID}
              quinaGrupoID={this.state.quinaGrupoID}
              redirectToMisQuinielas={this.props.redirectToMisQuinielas}
              showModal={this.state.showModal}
              showPanel={this.state.showPanel}
              showLoginPopUp={this.showLoginPopUp}
              hideLoginPopUp={this.hideLoginPopUp}
              showRegisterPopUp={this.showRegisterPopUp}
              hideRegisterPopUp={this.hideRegisterPopUp}
              updatePanelCancelState={this.updatePanelCancelState}
              updatePanelState={this.updatePanelRegisterState}
              updateRegisterPicks={this.updateRegisterPicks}
              updateStateProp={this.updateState}
              updateUserInfo={this.props.updateUserInfo}
              updateUserBalance={this.props.updateUserBalance}
              userData={this.props.userData}
              userBalance={this.props.userBalance}
              setItemSelected={this.setItemSelected}
              match={this.props.match}
            />
            <RegisterPanel
              handleTutorial={this.props.handleTutorial}
              handleTutorialStep={this.props.handleTutorialStep}
              isPopUp={true}
              type={
                (this.state.bracketID && 'bracket') ||
                (this.state.survivorID && 'survivor') ||
                (this.state.quinaID && 'pool') ||
                (this.state.groupPoolId && 'group_pool')
              }
              closeQuinielaPopUp={this.closeQuinielaPopUp}
              closePanel={this.closePanel}
              entryId={null}
              logged={this.state.logged}
              payType={
                this.state.itemSelected === undefined ||
                this.state.itemSelected === null ||
                this.state.itemSelected.length === 0 ||
                this.state.itemSelected[0] === null ||
                this.state.itemSelected[0] === undefined
                  ? null
                  : this.state.itemSelected[0].entry_currency
              }
              pool={this.state.itemSelected === null ? null : this.state.itemSelected[0]}
              pools={null}
              item={this.state.itemSelected}
              popUpNewUsersStatus={this.props.popUpNewUsersStatus}
              quinaID={this.state.quinaID}
              registerPicks={this.state.registerPicks}
              registerState={this.registerState}
              showPanel={this.state.showPanel}
              showTutorial={this.showTutorial}
              updateStateFunc={this.handleRegistrationClick}
              updateUserInfo={this.props.updateUserInfo}
              userData={this.props.userData}
            />
            {/* <TutorialSlideShow
              openPoolPopUp={this.openPoolPopUp}
              popUpNewUsers={this.props.popUpNewUsers}
              deleteNewUserStatus={this.props.deleteNewUserStatus}
              numSlides={3}
              showTutorial={this.state.showTutorial}
              closeTutorial={this.closeTutorial}
              redirectToMisQuinielas={this.props.redirectToMisQuinielas}
            /> */}
            <CancelRegister
              type={'lobby'}
              logged={this.state.logged}
              payType={
                this.state.itemSelected === undefined ||
                this.state.itemSelected === null ||
                this.state.itemSelected.length === 0 ||
                this.state.itemSelected[0] === null
                  ? null
                  : this.state.itemSelected[0].entry_currency
              }
              showPanel={this.state.showPanelCancel}
              quinaID={this.state.quinaID}
              pool={
                !this.state.itemSelected ||
                this.state.itemSelected.length < 1 ||
                this.state.itemSelected[0] === null
                  ? null
                  : this.state.itemSelected[0]
              }
              pools={null}
              userDataProps={this.state.userData}
              updateStateFunc={this.handleCancelClick}
              redirectAfterCancel={this.props.redirectAfterCancel}
              updateUserInfo={this.props.updateUserInfo}
              updateUserBalance={this.props.updateUserBalance}
            />
            <RegisterPanelMultyEntry
              logged={utils.isLogged()}
              registerPicks={this.state.registerPicks}
              showPanel={this.state.showPanelMultyEntry}
              multyEntry={this.state.multyEntry}
              userDataProps={utils.getUserInfo()}
              userBalance={utils.getUserBalance()}
              updateStateFunc={this.updateMultiEntryState}
              refreshAfterRegister={this.props.refreshAfterMultipleRegister}
              updateUserInfo={this.props.updateUserInfo}
              // registerState={this.registerState}
            />
            {!this.state.popUpRegaloViewed ? (
              <PopUpRegalo
                popUpNewUsers={this.props.popUpNewUsers}
                showPopUpRegalo={this.state.showPopUpRegalo}
                closePopUpRegalo={this.closePopUpRegalo}
                redirectToFreeroll={this.redirectToFreeroll}
              />
            ) : null}
            <PopUpNewUser
              popUpNewUsersStatus={false}
              popUpNewUsers={this.props.popUpNewUsers}
              changePoolPopUpView={this.changePopUpView}
              closePopUpNewUsers={this.closePopUpNewUsers}
            />
            <Modal
              show={this.state.registerState}
              dialogClassName="registerStatePopUpModal"
              onHide={this.registerPop}
            >
              <div className="retirar exito">¡Tu registro se ha completado con éxito!</div>
            </Modal>
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}

export default PoolsList;
