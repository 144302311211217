import React, { useState, useEffect, useRef } from 'react'

// Utils
import API from '../../../utils/api'
import ConfirmModal from '../../ConfirmModal'
import useLocalStorage from '../../../hooks/useLocalStorage'

const GameContainer = ({ game, gameOpened, onGameClose, demo }) => {
  const iframeRef = useRef(null)
  const [showExitModal, setShowExitModal] = useState(false)
  const [sessionGameData, setSessionGameData] = useLocalStorage(
    'sessionGameData',
    {
      game_code: null,
      token_id: null,
      type: null,
      demo
    }
  )

  const [animation, setAnimation] = useState('open')
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    document.body.classList.add('casino-game-opened')

    return () => {
      document.body.classList.remove('casino-game-opened')
    }
  }, [gameOpened])

  useEffect(() => {
    if (demo !== undefined && demo === true) {
      API.launchDemoGame(game.id, false).then(response => {
        const { success, url, error, game_code, token_id } = response.data

        if (success) {
          iframeRef.current.setAttribute('src', url)

          setSessionGameData({
            game_code,
            token_id,
            type: 'slots',
            demo: true
          })

        } else {
          alert('Error: ' + error)
          onGameClose(sessionGameData)
        }
      })
    } else {
      API.launchGame(game.id, false).then(response => {
        const { success, url, error, game_code, token_id } = response.data

        if (success) {
          iframeRef.current.setAttribute('src', url)

          setSessionGameData({
            game_code,
            token_id,
            type: 'slots',
            demo: false
          })

        } else {
          alert('Error: ' + error)
          onGameClose(sessionGameData)
        }
      })
    }

    return () => {
      iframeRef.current.setAttribute('src', '')
      iframeRef.current = null
    }
  }, [demo, game.id])

  const hide = async () => {
    setAnimation('close')
    await new Promise(r => setTimeout(r, 200))
    onGameClose(sessionGameData)
  }

  // Escuchar evento que se ejecuta cuando el usuario cierra la ventana o navegador
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''
      onGameClose(sessionGameData)
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sessionGameData]);

  const hideRequest = () => {
    setShowExitModal(true)
  }

  return (
    <div className={`game-container ${animation}`}>
      <ConfirmModal
        onClose={() => setShowExitModal(false)}
        onConfirm={hide}
        show={showExitModal}
        message='¿Estas seguro que quieres salir del juego?'
      />
      <div className='game-container-header'>
        <div className='thumbnail-container'>
          <img src={game.thumbnail} alt={game.name} />
        </div>
        <h1>{game.name} {demo ? ' - DEMO' : null}</h1>
        <a className='btn-close' onClick={hideRequest}>
          <i className='icon ion-close' />
        </a>
      </div>
      <div className='game-container-body'>
        {/* { demo && (
          <div className="demo-warning">
            <i className="icon ion-alert-circled"></i> Este juego se encuentra en modo demostración, no se pueden realizar apuestas. <i className="icon ion-alert-circled"></i>
          </div>
        )} */}
        <iframe
          title={game.name}
          id='partner-iframe'
          width='100%'
          height='100%'
          frameBorder={0}
          ref={iframeRef}
          sandbox='allow-scripts allow-forms allow-same-origin allow-popups'
        />
      </div>
    </div>
  )
}

export default GameContainer
