// Libraries
import React from 'react';
import moment from "moment";
import _ from 'underscore';

// Components
import Clock from '../Clock/index';

// Images
import avatar from '../../assets/images/avatar-50.png';

const BracketTennisPick = ({
  match,
  bracket,
  pick,
  pickProp,
  stage,
  index,
  onGameChanged,
  onPickChanged,
  onPropChanged,
  maxStage,
  offset,
  heightConnector
}) => {
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  var setsPlayerOne = [];
  var setsPlayerTwo = [];
  var tennisProps = null;

  if (match.tennis_props.length > 0) {
    tennisProps = match.tennis_props[0];
  }

  if (match.tennis_fixtures.length > 0) {
    _.forEach(match.tennis_fixtures[0].sets_player_one, (set) => {
      setsPlayerOne.push(set);
    });
    _.forEach(match.tennis_fixtures[0].sets_player_two, (set) => {
      setsPlayerTwo.push(set);
    });
  }


  React.useEffect(() => {
    if (pick.pick && pick.pick === match.home_team_id) {
      setSelectedTeam(match.player_one);
    }
    if (pick.pick && pick.pick === match.away_team_id) {
      setSelectedTeam(match.player_two);
    }
  }, [pick.pick]);

  //Colors
  let correctPicks = [false, false, false];
  if ( match.status !== 'upcoming' ) {
    var correctness = 0;
    if ( pick.pick !== null && pick.pick === match.winning_pick ) {
      correctness = correctness ^ (1 << 2);
      correctPicks[0] = true;
    }
    if ( pick.winning_game !== null && pick.winning_game === match.winning_game ) {
      correctness = correctness ^ (1 << 1);
      correctPicks[1] = true;
    }
    if ( (pickProp.pick !== null && pickProp.pick !== undefined) && pickProp.pick === tennisProps.winning_pick ) {
      correctness = correctness ^ (1 << 0);
      correctPicks[2] = true;
    }
    correctness = (correctness >>> 0).toString(2);
  }

  var selectedTeamName = null;
  if (pick.pick && pick.pick === match.home_team_id) {
    selectedTeamName= match.player_one;
  }
  if (pick.pick && pick.pick === match.away_team_id) {
    selectedTeamName= match.player_two;
  }

  let newOffset = offset * ((2*index+1)-1);
  let classListStyle = {transform: `translateY(${newOffset}px) translateZ(0px)`};
  return (
    <li className={`tournament-bracket-tennis__item relative`} style={classListStyle}>
      <div className={`tournament-bracket-tennis__match`} id={`stage-${stage.id}-${index}`}>
        <div className={`match-content
          ${match.status == 'live' ? 'match-live ' : ''}
          ${match.status == 'upcoming' ? 'match-upcoming ' : ''}
          ${match.status == 'finished' ? 'match-finished ' : ''}
          ${match.sort_status !== 1 && correctPicks[0] && correctPicks[1] && correctPicks[2] && 'correct'}
          ${match.sort_status === 1 && (selectedTeam !== null || pick.pick !== null) && 'selected'}
        `}>

          <div className="match-header font-chivo text-bold text-italic uppercase text-small">

            {match.status === "live" && (
              <>
                <span className='is_blue'>EN VIVO</span>
                { match.live_status !== undefined && <span className="pull-right">{match.live_status}</span> }
              </>
            )}

            {match.status === "upcoming" && (
              <>
                CIERRE
                <span className="pull-right">
                  {/* TODO: tiene que ir en rojo cuando faltan menos de 24 horas */}
                  {match.time_left > 86400 && moment(match.deadline).format("ddd DD, MMM HH:mm")}
                  {match.time_left > 0 && (match.time_left) <= 86400 &&
                    <div>
                      <Clock type="row" poolId={bracket.id} fixtureID={0} closingDate={match.deadline} />
                    </div>
                  }
                </span>
              </>
            )}


            {match.status === "finished" && <span className='is_red'>FINALIZO</span>}
          </div>

          <div className={`flex-row row-center tennis-body-pick-${stage.id}`}>
            <div className="flex-col col-no-padding">
              {/* Picks */}
              <div className={`fixture-picks
                ${match.sort_status !== 1 && correctPicks[0] && 'correct'}
                ${match.sort_status !== 1 && !correctPicks[0] && 'incorrect'}
              `}>
                {/* <!-- home --> */}
                {(match.player_one && (
                  <div className="flex-row row-center">
                    <div className="flex-col">
                      <label
                        htmlFor={`bracket-pick-home-${match.id}`}
                        className={`pick ${pick.pick === match.home_team_id ? 'checked' : null} `}
                      >
                        <div className="flex-col col-15">
                          <div className="avatar-bracket">
                            <img src={(match.player_one.avatar === null) ? avatar : `${match.player_one.avatar}`} />
                          </div>
                        </div>

                        <div className="flex-col col-ellipsis">
                          <div className="flex-container text-left font12 text-uppercase">{match.player_one.name}</div>
                        </div>

                        { match.player_1_game_score !== undefined &&
                          <div className="flex-col col col-10 text-italic font12">
                            {match.player_1_game_score}
                          </div>
                        }

                        <div className="flex-col col-25">
                          <div className="flex-container align around text-small font-chivo">
                            {setsPlayerOne.map((set, i) => <label key={i} className='font12'>{set}</label>)}
                          </div>
                        </div>

                        {/* <div className="flex-col col-10 font-chivo">
                          {match.tennis_fixtures.length > 0 ? match.tennis_fixtures[0].player_1_score : 0}
                        </div> */}

                        <div className="flex-col col-15">
                          <input
                            disabled={!match.enabled}
                            id={`bracket-pick-home-${match.id}`}
                            name={`bracket-pick-${match.id}`}
                            type="radio"
                            value={match.home_team_id ? match.home_team_id : ''}
                            checked={pick.pick && pick.pick === match.home_team_id}
                            onChange={() => {}}
                            onClick={() => {
                              onPickChanged(match.id, match.home_team_id, "home");
                            }}
                          />
                          <a className="pick-helper"></a>
                        </div>
                      </label>
                    </div>
                  </div>
                )) || (
                  <div className="team-placeholder flex-row row-center">
                    <div className="flex-col">
                      <label
                        htmlFor={`bracket-pick-home-no-player-${match.id}`}
                        className={`pick ${pick.pick && pick.pick == match.home_team_id ? 'checked' : null} `}
                      >
                        <div className="flex-col col-15">
                          <div className="avatar-bracket">
                            <img src={avatar} className="player-avatar" />
                          </div>
                        </div>

                        <div className="flex-col col-ellipsis">
                          <div className="flex-container text-left font12 text-uppercase">{match.player_one && match.player_one.name}</div>
                        </div>

                        <div className="flex-col col-25">
                          <div className="flex-container align around text-small font-chivo">
                            {setsPlayerOne.map((set, i) => <label key={i} className='font12'>{set}</label>)}
                          </div>
                        </div>

                        {/* <div className="flex-col col-10 font-chivo">
                          {match.tennis_fixtures.length > 0 ? match.tennis_fixtures[0].player_1_score : ''}
                        </div> */}

                        <div className="flex-col col-15">
                          <input
                            id={`bracket-pick-home-no-player-${match.id}`}
                            disabled={!match.enabled || !match.home_team_id}
                            name={`bracket-pick-no-player-${match.id}`}
                            type="radio"
                            value={match.home_team_id ? match.home_team_id : ''}
                            checked={pick.pick && pick.pick === match.home_team_id}
                            onChange={() => {}}
                            onClick={() => {
                              onPickChanged(match.id, match.home_team_id, "home");
                            }}
                          />
                          <a className="pick-helper"></a>
                        </div>
                      </label>
                    </div>
                  </div>
                )}

                <div className="h-divider"></div>

                {/* <!-- away --> */}
                {(match.player_two && (
                  <div className="flex-row row-center">
                    <div className="flex-col">
                      <label
                        htmlFor={`bracket-pick-away-${match.id}`}
                        className={`pick ${pick.pick === match.away_team_id ? 'checked' : null} `}
                      >
                        <div className="flex-col col-15">
                          <div className="avatar-bracket">
                            <img src={(match.player_two.avatar === null) ? avatar : `${match.player_two.avatar}`} />
                          </div>
                        </div>

                        <div className="flex-col col-ellipsis">
                          <div className="flex-container text-left font12 text-uppercase">{match.player_two.name}</div>
                        </div>

                        { match.player_2_game_score !== undefined &&
                          <div className="flex-col col col-10 text-italic font12">
                            {match.player_2_game_score}
                          </div>
                        }

                        <div className="flex-col col-25">
                          <div className="flex-container align around text-small font-chivo">
                            {setsPlayerTwo.map(set => <label key={'h-'+set+'-'+Math.random()} className='font12'>{set}</label>)}
                          </div>
                        </div>

                        {/* <div className="flex-col col-10 font-chivo">
                          {match.tennis_fixtures.length > 0 ? match.tennis_fixtures[0].player_2_score : 0}
                        </div> */}

                        <div className="flex-col col-15">
                          <input
                            disabled={!match.enabled}
                            id={`bracket-pick-away-${match.id}`}
                            name={`bracket-pick-${match.id}`}
                            type="radio"
                            value={match.away_team_id ? match.away_team_id : ''}
                            checked={pick.pick && pick.pick === match.away_team_id}
                            onChange={() => {}}
                            onClick={() => {
                              onPickChanged(match.id, match.away_team_id, "away");
                            }}
                          />
                          <a className="pick-helper"></a>
                        </div>
                      </label>
                    </div>
                  </div>
                )) || (
                  <div className="flex-col">
                    <label
                      htmlFor={`bracket-pick-away-no-player-${match.id}`}
                      className={`pick ${pick.pick == match.away_team_id ? 'checked-' : null} `}
                    >
                      <div className="team-placeholder flex-row row-center">
                        <div className="flex-col col-15">
                          <div className="avatar-bracket">
                            <img src={avatar} className="player-avatar" />
                          </div>
                        </div>

                        <div className="flex-col col-ellipsis">
                          <div className="flex-container text-left font12 text-uppercase">{match.player_two && match.player_two.name}</div>
                        </div>

                        <div className="flex-col col-25">
                          <div className="flex-container align around text-small font-chivo">
                            {setsPlayerTwo.map(set => <label key={'ht-'+set+'-'+Math.random()} className='font12'>{set}</label>)}
                          </div>
                        </div>

                        {/* <div className="flex-col col-10 font-chivo">
                          {match.tennis_fixtures.length > 0 ? match.tennis_fixtures[0].player_1_score : ''}
                        </div> */}

                        <div className="flex-col col-15">
                          <input
                            id={`bracket-pick-away-no-player-${match.id}`}
                            disabled={!match.enabled || !match.away_team_id}
                            name={`bracket-pick-no-player-${match.id}`}
                            type="radio"
                            value={match.away_team_id ? match.away_team_id : ''}
                            checked={pick.pick && pick.pick === match.away_team_id}
                            onChange={() => {}}
                            onClick={() => {
                              onPickChanged(match.id, match.away_team_id, "away");
                            }}
                          />
                          <a className="pick-helper"></a>
                        </div>
                      </div>
                    </label>
                  </div>
                )}
              </div>

              <div className="h-divider"></div>

              {/* Numero de sets */}
              <div className={`flex-row row-center pick-game plr-05p fixture-picks
                ${match.sort_status !== 1 && correctPicks[0] && correctPicks[1] && 'correct'}
                ${match.sort_status !== 1 && (correctPicks[0] && !correctPicks[1]) && 'incorrect'}
              `}>
                <div className="flex-col col-70 text-bold ff-chivo text-italic text-small uppercase text-center">
                  {selectedTeamName !== null && (
                    <span>{selectedTeamName.name} en el set</span>
                  )}

                  {selectedTeamName === null && <span>En el set:</span>}
                </div>
                <div className="flex-col">
                  <select
                    className="bracket-game-select"
                    value={pick.winning_game !== null ? pick.winning_game : ''}
                    onChange={(e) => { onGameChanged(match.id, e.target.value) }}
                    disabled={!match.enabled}
                  >
                    <option value=""></option>
                    {match.game_options.map((set, i) => {
                      return <option key={i} value={set}>{set}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="h-divider"></div>

              <div className="pick-game flex-container align justify ptb-05">
                <label className='text-bold ff-chivo text-italic text-small uppercase'>{tennisProps ? tennisProps.name : "Número de juegos"}</label>
              </div>

              <div className="h-divider"></div>

              {/* Tennis props */}
              <div className="flex-row relative tennis-props-container">
                <div className={`flex-col col-50 fixture-picks
                  ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && correctPicks[2]) && 'correct'}
                  ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && !correctPicks[2]) && 'incorrect'}
                `}>
                  {/* Radio 1 */}
                  <label
                    htmlFor={`bracket-pick-props-1-${match.id}`}
                    className={`pick ptb-05 ${pickProp.pick && pickProp.pick === 'under' ? 'checked' : null} `}
                  >
                    <div className="flex-col col-20">
                      <input
                        id={`bracket-pick-props-1-${match.id}`}
                        disabled={!match.enabled}
                        name={`bracket-props-${match.id}`}
                        type="radio"
                        value={"under"}
                        checked={pickProp.pick && pickProp.pick === 'under'}
                        onChange={() => {}}
                        onClick={() => {
                          onPropChanged(match.id, "under");
                        }}
                      />
                      <a className="pick-helper"></a>
                    </div>
                    <div className="flex-col col-80">
                      <div className='text-bold ff-chivo text-italic font11 uppercase text-left flex-container'>
                        {tennisProps && tennisProps.boolean ? 'Si' : 'Menos de'}&nbsp;
                        {tennisProps && tennisProps.value ? <label className='is_yellow'>{tennisProps.value}</label> : <label className='is_yellow'>PD</label>}
                      </div>
                    </div>
                  </label>
                </div>
                <div className="flex-col col-1 separator"></div>
                <div className={`flex-col col-50 fixture-picks
                  ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && correctPicks[2]) && 'correct'}
                  ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && !correctPicks[2]) && 'incorrect'}
                `}>
                  {/* Radio 2 */}
                  <label
                    htmlFor={`bracket-pick-props-2-${match.id}`}
                    className={`pick ptb-05 ${pickProp.pick && pickProp.pick === 'over' ? 'checked' : null} `}
                  >
                    <div className="flex-col col-80">
                      <div className='text-bold ff-chivo text-italic font11 uppercase text-right flex-container end'>
                        {tennisProps && tennisProps.boolean ? 'Si' : 'Más de'}&nbsp;
                        {tennisProps && tennisProps.value ? <label className='is_yellow'>{tennisProps.value}</label> : <label className='is_yellow'>PD</label>}
                      </div>
                    </div>

                    <div className="flex-col col-20">
                      <input
                        id={`bracket-pick-props-2-${match.id}`}
                        disabled={!match.enabled}
                        name={`bracket-props-${match.id}`}
                        type="radio"
                        value={"over"}
                        checked={pickProp.pick && pickProp.pick === 'over'}
                        onChange={() => {}}
                        onClick={() => {
                          onPropChanged(match.id, "over");
                        }}
                      />
                      <a className="pick-helper"></a>
                    </div>
                  </label>
                </div>
                { match.status !== 'upcoming' && (
                  <div className='bracket-props-result text-small text-bold font-chivo'>
                    {tennisProps ? tennisProps.result : 'PD'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {match.stage_id !== maxStage.id &&
        <>
          {(index+1)%2 === 1 &&
            <div class="connector top" style={{height: heightConnector}}>&nbsp;</div>
          ||
            <div class="connector bottom" style={{height: heightConnector}}>&nbsp;</div>
          }
        </>
      }
    </li>
  );
}

export default BracketTennisPick;
