import React from 'react';
import API from '../../utils/api';
import { Redirect, Link } from 'react-router-dom';
import {
  Button,
  Modal,
} from 'react-bootstrap/lib';
import numeral from 'numeral';
import billete from '../../assets/images/cash1-50.png';
import pickcoin from '../../assets/images/pickcoin1-50.png';
import AbonarSaldo from '../AbonarSaldo';
import { DisplayFlag, DisplaySport } from '../../utils/quinielasComponents';

import utils from '../../utils/utils';
import _ from 'underscore';

class RegisterPanelMultyEntry extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      errorCupo: false,
      errorMultyEntry: null,
      logged: this.props.logged,
      multyEntry: this.props.multyEntry,
      redirectLobbyPool: false,
      redirectLogin: false,
      registrationData: this.props.registerPicks,
      registrationSuccess: null,
      respuestas: null,
      showAbonarPop: false,
      showPanel: this.props.showPanel,
      specificErrorMessage: null,
      submitting: false,
      totalAmountPc: 0,
      totalAmountReal: 0,
      updatedBalancePc: this.props.logged ? this.props.userBalance.balancePc / 100 : null,
      updatedBalanceReal: this.props.logged ? this.props.userBalance.balanceReal / 100 : null,
      updateStateFunc: this.props.updateStateFunc,
      userData: this.props.userDataProps,
    };

    this.redirect = this.redirect.bind(this);
    this.registration = this.registration.bind(this);
    this.abonarSaldoPanel = this.abonarSaldoPanel.bind(this);
    this.updateAbonarPop = this.updateAbonarPop.bind(this);
    this.changeQnty = this.changeQnty.bind(this);
    this.changeQntyNumber = this.changeQntyNumber.bind(this);
    this.resetBalance = this.resetBalance.bind(this);
    this.resetState = this.resetState.bind(this);

    this.calculateAmounts = () => {
      // console.log(
      //   '%c Calculate Amounts',
      //   'font-size:2em; color:rgb(120, 188, 40);'
      // );

      let multyPc = _.reduce(
        this.state.multyEntry,
        (acc, curr, i) => {
          if (curr.payType === 'pickcoin') {
            curr.totalEntries = curr.pool.entry_fee * curr.qnty;
            return acc + curr.totalEntries;
          } else {
            return acc + 0;
          }
        },
        0
      );

      let multyReal = _.reduce(
        this.state.multyEntry,
        (acc, curr, i) => {
          if (curr.payType === 'real') {
            curr.totalEntries = curr.pool.entry_fee * curr.qnty;
            return acc + curr.totalEntries;
          } else {
            return acc + 0;
          }
        },
        0
      );

      // console.log('PC', multyPc);
      // console.log('MXN', multyReal);

      this.setState({
        totalAmountPc: multyPc,
        totalAmountReal: multyReal,
      });
    };

    this.callbackErrorMultyRegister = (errorData) => {
      const errors = errorData.obj;
      if (!errors) {
        this.setState({
          specificErrorMessage: ['Detectamos un error, por favor inténtalo de nuevo más tarde'],
          errorMessage: 'Detectamos un error, por favor inténtalo de nuevo más tarde',
          submitting: false,
          errorMultyEntry: true,
          registrationSuccess: false,
        });
        return;
      }

      const errorKeys = Object.keys(errors);
      let specificErrorMessage = [];
      errorKeys.forEach((errorKey) => {
        const completeErrorMessage = `${errors[errorKey].pool.name_replaced} ${errors[errorKey][0].message}`;
        specificErrorMessage.push(completeErrorMessage);
      });
      // console.log(specificErrorMessage);
      this.setState({
        specificErrorMessage: specificErrorMessage,
        errorMessage: errorData.error,
        submitting: false,
        errorMultyEntry: true,
        registrationSuccess: false,
      });
    };
    // console.log('Register Panel', this);
  }

  resetState() {
    console.log('Reset');
    this.setState({
      updatedBalanceReal: this.props.logged ? this.props.userBalance.balanceReal / 100 : null,
      updatedBalancePc: this.props.logged ? this.props.userBalance.balancePc / 100 : null,
      redirectLogin: false,
      multyEntry: this.props.multyEntry,
      updateStateFunc: this.props.updateStateFunc,
      showPanel: this.props.showPanel,
      logged: this.props.logged,
      submitting: false,
      userData: this.props.userDataProps,
      registrationData: this.props.registerPicks,
      redirectLobbyPool: false,
      showAbonarPop: false,
      errorCupo: false,
      respuestas: null,
      totalAmountPc: 0,
      totalAmountReal: 0,
      errorMultyEntry: null,
      registrationSuccess: null,
      isReady: false,
    });
  }

  resetBalance() {
    // console.log('%c Reset Balance', 'background:#FFEC07;');
    let temporalMultiEntry = this.state.multyEntry;
    temporalMultiEntry = temporalMultiEntry.map((temp) => {
      temp.qnty = 0;
      return temp;
    });
    // console.log(temporalMultiEntry);
    this.setState({
      updatedBalanceReal: this.props.userBalance.balanceReal / 100,
      updatedBalancePc: this.props.userBalance.balancePc / 100,
      multyEntry: temporalMultiEntry,
    });
  }

  changeQnty(id, type, entry_currency) {
    // console.log('%c Change QNTY', 'background:#A10011;font-size:20px;');
    // console.log(id, type, entry_currency);

    // let isFull = false;
    // let maxEntires = null;
    // let qntyPool = 0;
    let indexPool;
    // let entryCurrency = entry_currency;
    let tempMultyEntry = this.state.multyEntry;
    // let temporalBalanceReal = this.state.updatedBalanceReal;
    // let temporalBalancePc = this.state.updatedBalancePc;
    //Find Pool
    let selPool = tempMultyEntry.find((entry, i) => {
      // console.log(entry.idPool, id);
      if (entry.idPool === id) {
        indexPool = i;
        return entry;
      }
    });

    if (selPool.pool.max_multientries > 0) {
      selPool.maxEntries =
        selPool.pool.max_multientries - (selPool.pool.entries ? selPool.pool.entries.length : 0);
    }
    // console.log(selPool);

    if (type === '+') {
      if (selPool.pool.max_multientries > 0 && selPool.qnty >= selPool.maxEntries) {
        console.log('Max entries allowed');
        return;
      }
      selPool.qnty++;
    } else {
      selPool.qnty--;
    }

    // console.log(temporalBalanceReal, temporalBalancePc);
    tempMultyEntry[indexPool] = selPool;
    // console.log(selPool);

    let isReady = tempMultyEntry.find((multy) => multy.qnty > 0);

    this.setState(
      {
        isReady: isReady !== -1 && isReady !== undefined,
        multyEntry: tempMultyEntry,
      },
      () => {
        this.calculateAmounts();
      }
    );
  }

  changeQntyNumber(e, id, entry_currency) {
    console.log('%c Change QNTY Number', 'background:#A10011;font-size:20px;');
    console.log(e.target.value, id, entry_currency);
    let qntyPool = e.target.value;
    let indexPool;
    let entryCurrency = entry_currency;
    let tempMultyEntry = this.state.multyEntry;
    let temporalBalanceReal = this.state.updatedBalanceReal;
    let temporalBalancePc = this.state.updatedBalancePc;
    //Find Pool
    let selPool = tempMultyEntry.find((entry, i) => {
      console.log(entry.idPool, id);
      if (entry.idPool === id) {
        indexPool = i;
        return entry;
      }
    });
    console.log(this.state.multyEntry, selPool, indexPool);
    //change qnty depending type
    //Update Pool Object
    selPool.qnty = qntyPool;
    // console.log(temporalBalanceReal);
    // console.log(selPool);
    if (selPool.payType === 'real') {
      selPool.totalEntries = selPool.qnty * (selPool.pool.entry_fee / 100);
      temporalBalanceReal = temporalBalanceReal - selPool.totalEntries;
    } else if (selPool.payType === 'mixed') {
      selPool.totalEntries = selPool.qnty * (selPool.pool.entry_fee / 100);
      temporalBalancePc = temporalBalancePc - selPool.totalEntries;
    } else {
      selPool.totalEntries = selPool.qnty * (selPool.pool.entry_fee / 100);
      temporalBalancePc = temporalBalancePc + selPool.totalEntries;
    }
    // console.log(temporalBalanceReal, temporalBalancePc);
    tempMultyEntry[indexPool] = selPool;
    // console.log(selPool);
    this.setState({
      updatedBalanceReal: temporalBalanceReal,
      updatedBalancePc: temporalBalancePc,
      multyEntry: tempMultyEntry,
    });
  }

  abonarSaldoPanel() {
    this.setState({
      showAbonarPop: true,
    });
  }

  updateAbonarPop() {
    this.setState(
      {
        showAbonarPop: false,
      },
      () => {
        this.resetState();
      }
    );
  }

  registration() {
    this.setState({
      submitting: true,
    });
    console.log('%c Registering user into Multiple Quinas', 'background:#000; color:#fff;');
    let temporalRegister = this.state.multyEntry.map((multi) => {
      let obj = {};
      if (multi.qnty === 0) {
        return null;
      } else {
        obj[multi.idPool] = {
          id: multi.idPool,
          num_entries: multi.qnty,
          game_type: multi.pool.game_type,
        };
        // console.log(obj);
      }
      return obj;
    });
    temporalRegister = temporalRegister.filter((temp) => temp !== null);
    // console.log(temporalRegister);
    temporalRegister = _.reduce(
      temporalRegister,
      (acc, cur) => {
        let regKey = Object.keys(cur);
        // console.log(acc, cur, regKey);
        acc[regKey[0]] = cur[regKey[0]];
        return acc;
      },
      {}
    );
    // console.log(temporalRegister);
    API.postMultyEntry(temporalRegister).then((response) => {
      console.log(response);
      if (response.data.success && response.data.entries.length > 0) {
        this.setState(
          () => {
            return {
              submitting: false,
              errorMultyEntry: false,
              registrationSuccess: true,
            };
          },
          () => {
            this.props.updateUserInfo();
            this.props.refreshAfterRegister();
          }
        );
      } else {
        // console.log(response.data);
        this.callbackErrorMultyRegister(response.data);
      }
    });
  }

  redirect() {
    this.setState({
      redirectLogin: true,
    });
  }

  componentDidMount() {
    // console.log(this.props.pool);
    if (this.props.multyEntryPools !== undefined) {
      this.setState({
        multyEntry: this.props.multyEntry,
        logged: this.props.logged,
      });
    }
    // console.log('Multiple Registers', this.state)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.multyEntry.lenght) {
      return;
    }
    if (
      prevProps.multyEntry.length !== this.props.multyEntry.length ||
      this.props.multyEntry[0].id !== prevProps.multyEntry[0].id
    ) {
      console.log('updated MultyEntry', this.props.userDataProps);
      this.setState({
        multyEntry: this.props.multyEntry,
        userData: this.props.userDataProps,
        updatedBalancePc: this.props.userBalance.balancePc / 100,
        updatedBalanceReal: this.props.userBalance.balanceReal / 100,
      });
    }
    if (this.props.userBalance.balanceReal !== prevProps.userBalance.balanceReal) {
      this.setState({
        multyEntry: this.props.multyEntry,
        userData: this.props.userDataProps,
        updatedBalancePc: this.props.userBalance.balancePc / 100,
        updatedBalanceReal: this.props.userBalance.balanceReal / 100,
      });
    }
  }

  render() {
    let conditionalComponent;
    if (this.state.errorMultyEntry !== null && !this.state.errorMultyEntry) {
      // console.log('Refresh After Register');
      setTimeout(() => {
        this.props.refreshAfterRegister();
      }, 2000);
      conditionalComponent = (
        <Modal
          backdropClassName="backdropRegister"
          dialogClassName="registerModal modal-dialog-xxs"
          className="registerModal"
          bsSize="lg"
          show={this.props.showPanel}
          onHide={this.props.updateStateFunc}
        >
          {this.props.showPanel ? (
            <div id="register-panel">
              <div className="multiple-register-container">
                <div className="row registro-titulo">
                  <span>Estamos Procesando tus Registros, espera un momento</span>
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
      );
    } else {
      // console.log('Noral Behaviour');
      this.state.redirectLogin
        ? (conditionalComponent = <Redirect to={{ pathname: 'ingresar' }} />)
        : (conditionalComponent = (
          <div>
            <Modal
              backdropClassName="backdropRegister"
              dialogClassName="registerModal modal-dialog-xxs"
              className="registerModal"
              show={this.props.showPanel}
              onHide={this.props.updateStateFunc}
            >
              {this.props.showPanel ? (
                <div id="register-panel">

                  <div className="multiple-register-container">
                    <div className="row registro-titulo">
                      <span className="title-multy-entry">Tu registro a varias quinielas</span>
                      <button
                        className="cerrar-panel"
                        onClick={(e) => {
                          this.props.updateStateFunc();
                          this.resetBalance();
                          this.resetState();
                        }}
                      >
                        <div className="btn-icon-close">
                          <i className="ion-ios-close-empty" />
                        </div>
                      </button>
                    </div>
                    <div className="balance-multiple">
                      <div className="balance-titulo">
                        <span>Balance Disponible:</span>
                      </div>
                      <div className="balance-datos">
                        <div className="balance-data">
                          <span className="registro-balance-icon">
                            <img src={billete} />
                          </span>
                          <span
                            className={
                              this.state.updatedBalanceReal === 0 ? 'empty-balance' : null
                            }
                          >
                            {` ${numeral(this.state.updatedBalanceReal).format('$0,000.0')}`}
                            <small>MXN</small>{' '}
                          </span>
                          <React.Fragment>
                            <span className={`registro-balance-icon`}>
                              <img src={pickcoin} />
                            </span>
                            <span
                              className={
                                this.state.updatedBalancePc === 0 ? 'empty-balance' : null
                              }
                            >
                              {` ${numeral(this.state.updatedBalancePc).format('0,000.0')}`}{' '}
                              <small>PC</small>
                            </span>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                    <div className="multiple-register-list">
                      <div className="flex-row">
                        <div className="flex-col col-30 register-category uppercase nombre-quiniela">
                          <strong className="uppercase flex-container justify align column">
                            Quiniela
                            </strong>
                        </div>
                        <div className="flex-col col-25 register-category costo-entrada">
                          <strong className="uppercase flex-container justify align column">
                            Entrada
                            </strong>
                        </div>
                        <div className="flex-col col-30 register-category costo-entrada">
                          <strong className="uppercase flex-container justify align column">
                            Comisión
                            </strong>
                        </div>
                        <div className="flex-col col-15 register-category costo-comision">
                          <strong className="uppercase flex-container justify align column">
                            Cantidad
                            </strong>
                        </div>
                      </div>
                      {this.state.multyEntry.map((entryPool, i) => (
                        <div key={i} className="multiple-register-item flex-row row-no-padding">
                          <div className="flex-col col-30 register-category nombre-quiniela">
                            <DisplayFlag type="quiniela" flag={entryPool.pool} />
                            <DisplaySport
                              id={entryPool.idPool}
                              sports={entryPool.pool.sport}
                              type={'pool'}
                            />
                            {entryPool.pool.name_replaced.length >= 15
                              ? entryPool.pool.name_replaced.slice(0, 15) + '...'
                              : entryPool.pool.name_replaced}
                          </div>
                          {entryPool.pool.entry_currency === 'real' ? (
                            <div className=" flex-col col-25 register-category costo-entrada">
                              <span className="registro-balance-icon">
                                <img src={billete} />
                              </span>
                              <span className="costo-data">
                                {numeral(entryPool.pool.entry_fee / 100).format('$0,000')}
                                <small>MXN</small>
                              </span>
                            </div>
                          ) : (
                              <div className="flex-col col-25 register-category costo-entrada">
                                <span className="registro-balance-icon">
                                  <img src={pickcoin} />
                                </span>
                                <span className="costo-data">
                                  {numeral(entryPool.pool.entry_fee / 100).format('0,000.0 ')}
                                  <small>PC</small>
                                </span>
                              </div>
                            )}
                          {entryPool.pool.entry_currency === 'real' ? (
                            <div className="flex-col col-30  register-category costo-comision">
                              <div className="flex-row row-center row-no-padding">
                                <span className="flex-col col-20 text-center registro-balance-icon">
                                  <img src={billete} />
                                </span>
                                {entryPool.qnty === 0 ? (
                                  <span className="flex-col text-center costo-data">
                                    {numeral(0).format('$0,000.00 ')} <small>MXN</small>
                                  </span>
                                ) : (
                                    <span className="flex-col text-center costo-data">
                                      {numeral(
                                        (entryPool.pool.entry_fee * entryPool.qnty -
                                          entryPool.pool.commission_fee * entryPool.qnty) /
                                        100
                                      ).format('$0,000.00 ')}{' '}
                                      <small>MXN</small>
                                    </span>
                                  )}
                                <span className="flex-col col-5 text-center">+</span>
                                {entryPool.qnty === 0 ? (
                                  <span className="costo-data flex-col text-center">
                                    {numeral(0).format('$0,000.0 ')}
                                    <small>MXN</small>
                                  </span>
                                ) : (
                                    <span className="costo-data flex-col text-center">
                                      {numeral(
                                        (entryPool.pool.commission_fee * entryPool.qnty) / 100
                                      ).format('$0,000.0 ')}
                                      <small>MXN</small>
                                    </span>
                                  )}
                              </div>
                            </div>
                          ) : (
                              <div className={`flex-col col-30  register-category costo-comision `}>
                                <span className="registro-balance-icon">
                                  <img src={pickcoin} />
                                </span>
                                {entryPool.qnty === 0 ? (
                                  <span className="costo-data">
                                    {numeral(0).format('0,000.0 ')}
                                    <small>PC</small>
                                  </span>
                                ) : (
                                    <span className="costo-data">
                                      {numeral(
                                        (entryPool.pool.entry_fee * entryPool.qnty -
                                          entryPool.pool.commission_fee * entryPool.qnty) /
                                        100
                                      ).format('0,000.0 ')}
                                      <small>PC</small>
                                    </span>
                                  )}
                                <span>+</span>
                                {entryPool.qnty === 0 ? (
                                  <span className="costo-data">
                                    {numeral(0).format('0,000.0 ')}
                                    <small>PC</small>
                                  </span>
                                ) : (
                                    <span className="costo-data">
                                      {numeral(
                                        (entryPool.pool.commission_fee / 100) * entryPool.qnty
                                      ).format('0,000.0 ')}
                                      <small>PC</small>
                                    </span>
                                  )}
                              </div>
                            )}
                          <div className="flex-col col-15 register-category cantidad-entradas">
                            <div className="flex-row">
                              <div className="flex-container justify align">
                                <button
                                  data-id={entryPool.idPool}
                                  onClick={() =>
                                    this.changeQnty(
                                      entryPool.idPool,
                                      '-',
                                      entryPool.pool.entry_currency
                                    )
                                  }
                                  className="btn-quantity btn"
                                  disabled={entryPool.qnty === 0}
                                >
                                  <span>-</span>
                                </button>
                              </div>
                              <div className="flex-container justify align">
                                {entryPool.payType === 'real' ? (
                                  entryPool.pool.entry_fee / 100 >
                                    this.state.updatedBalanceReal ? (
                                      <input
                                        data-id={entryPool.idPool}
                                        disabled={true}
                                        onChange={(e) => {
                                          this.changeQntyNumber(
                                            e,
                                            entryPool.idPool,
                                            entryPool.payType
                                          );
                                        }}
                                        value={entryPool.qnty}
                                        name={entryPool.idPool}
                                        type="text"
                                      />
                                    ) : (
                                      <input
                                        data-id={entryPool.idPool}
                                        value={entryPool.qnty}
                                        onChange={(e) => {
                                          this.changeQntyNumber(
                                            e,
                                            entryPool.idPool,
                                            entryPool.payType
                                          );
                                        }}
                                        name={entryPool.idPool}
                                        type="text"
                                      />
                                    )
                                ) : entryPool.pool.entry_fee / 100 >
                                  this.state.updatedBalancePc ? (
                                      <input
                                        data-id={entryPool.idPool}
                                        disabled={true}
                                        onChange={(e) => {
                                          this.changeQntyNumber(
                                            e,
                                            entryPool.idPool,
                                            entryPool.payType
                                          );
                                        }}
                                        value={entryPool.qnty}
                                        name={entryPool.idPool}
                                        type="text"
                                      />
                                    ) : (
                                      <input
                                        data-id={entryPool.idPool}
                                        onChange={(e) => {
                                          this.changeQntyNumber(
                                            e,
                                            entryPool.idPool,
                                            entryPool.payType
                                          );
                                        }}
                                        value={entryPool.qnty}
                                        name={entryPool.idPool}
                                        type="text"
                                      />
                                    )}
                              </div>
                              <div className="flex-container align justify">
                                {entryPool.payType === 'real' ? (
                                  <button
                                    data-id={entryPool.idPool}
                                    onClick={() =>
                                      this.changeQnty(
                                        entryPool.idPool,
                                        '+',
                                        entryPool.pool.entry_currency
                                      )
                                    }
                                    className="btn-quantity btn"
                                    disabled={
                                      entryPool.qnty >= entryPool.maxEntries ||
                                      this.state.totalAmountReal / 100 >
                                      this.state.updatedBalanceReal
                                    }
                                  >
                                    <span>+</span>
                                  </button>
                                ) : (
                                    <button
                                      data-id={entryPool.idPool}
                                      onClick={() =>
                                        this.changeQnty(
                                          entryPool.idPool,
                                          '+',
                                          entryPool.pool.entry_currency
                                        )
                                      }
                                      className="btn-quantity btn"
                                      disabled={
                                        (entryPool.pool !== undefined &&
                                          entryPool.pool.max_multientries > 0 &&
                                          entryPool.qnty >= entryPool.pool.max_multientries) ||
                                        entryPool.qnty >= entryPool.maxEntires ||
                                        this.state.totalAmountPc / 100 > this.state.updatedBalancePc
                                      }
                                    >
                                      <span>+</span>
                                    </button>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="balance-multiple">
                    <div className="balance-titulo">
                      <span>Se descontaran de tu cuenta:</span>
                    </div>
                    <div className="balance-datos">
                      <div className="balance-data">
                        <span className="registro-balance-icon">
                          <img className="pools-icon" src={billete} />
                        </span>
                        <span
                          className={this.state.updatedBalanceReal === 0 ? 'empty-balance' : null}
                        >
                          {` ${numeral(this.state.totalAmountReal / 100).format('$0,000.0')}`}
                          <small>MXN</small>{' '}
                        </span>
                        <React.Fragment>
                          <span className="registro-balance-icon">
                            <img className="pools-icon" src={pickcoin} />
                          </span>
                          <span
                            className={
                              this.props.userDataProps.balancePc === 0 ? 'empty-balance' : null
                            }
                          >
                            {` ${numeral(this.state.totalAmountPc / 100).format('0,000.0')}`}
                            <small>PC</small>
                          </span>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                  {this.state.errorMultyEntry !== null ? (
                    this.state.errorMultyEntry === true ? (
                      <div className="flex-container justify align error-formulario multiple-errors">
                        <span>
                          {this.state.specificErrorMessage.length > 1
                            ? this.state.errorMessage
                            : null}
                        </span>
                        {this.state.specificErrorMessage.length > 0
                          ? this.state.specificErrorMessage.map((specError, i) => {
                            return <span key={i}>{specError}</span>;
                          })
                          : null}
                      </div>
                    ) : (
                        <div className="error-msj-custom exito">
                          <p>¡Tus registros están listos!</p>
                          <p>¡Ahora ingresa tus picks!</p>
                        </div>
                      )
                  ) : null}
                  <div className="register-buttons">
                    <Button
                      className={' guardar-picks btn-rojo'}
                      bsStyle="danger"
                      onClick={(e) => {
                        this.props.updateStateFunc();
                        this.resetBalance();
                        this.resetState();
                      }}
                    >
                      Regresar
                      </Button>
                    <Button
                      className={'guardar-picks'}
                      disabled={
                        utils.isSuPick() ||
                        this.state.submitting ||
                        this.state.totalAmountReal / 100 > this.state.updatedBalanceReal ||
                        this.state.totalAmountPc / 100 > this.state.updatedBalancePc ||
                        !this.state.isReady
                      }
                      bsStyle="success"
                      type="submit"
                      onClick={this.registration}
                    >
                      Confirmar Registros
                      </Button>
                  </div>
                </div>
              ) : null}
            </Modal>
            <AbonarSaldo
              showAbonarSaldo={this.state.showAbonarPop}
              updatePanelSate={this.updateAbonarPop}
              showAbonarPop={this.abonarSaldoPanel}
              updateUserInfo={this.props.updateUserInfo}
            />
          </div>
        ));
    }
    // console.log('Render Panel',this.props);
    return conditionalComponent;
  }
}

export default RegisterPanelMultyEntry;
