import React from 'react';
import axios from 'axios';
import Score from '../Score/Score';
import ScoreMobile from '../ScoreMobile/ScoreMobile';
import RegisterPanel from '../RegisterPanel/RegisterPanel';
import CancelRegister from '../CancelRegister/CancelRegister';
import Footer from '../Footer/Footer';
import BracketInfoContainer from '../BracketInfoContainer/BracketInfoContainer';
import InvitarAmigos from '../InvitarAmigos';
import ErrorBoundary from '../ErrorBoundary';
import gaUtils from '../GAWrapper';
import Loading from '../Loading/Loading';
//import createHistory from 'history/createBrowserHistory';
import { Nav, NavItem, Modal, Button } from 'react-bootstrap';
import utils from '../../utils/utils';
import API from '../../utils/api';
import NavigationBar from '../NavigationBar/NavigationBar';
import MediaQuery from 'react-responsive';
import ReactTooltip from 'react-tooltip';
import branchUtils from '../../utils/branchUtils';
import Clipboard from 'clipboard';
import { ShareButtons } from 'react-share';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';
import { ClockContextProvider } from '../../context/ClockContext';
import Utils from '../../utils/quinielasUtils';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';
import io from 'socket.io-client';
import { firstBy } from 'thenby';

let clipboardCode;
//const history = createHistory();
const history = require('history').createBrowserHistory();

const { FacebookShareButton, TwitterShareButton } = ShareButtons;

const PanelInvitarAmigos = ({
  title,
  bracket,
  openInvitePanel,
  closePanel,
  branchLink,
  whatsAppInvitation,
}) => {
  const userData = utils.getUserInfo();
  return (
    <div className="panel-abonar">
      <div id="panelTitle" className="panel-abonar-titulo">
        <span>
          <i className="ion-flag" />
          {title}
        </span>
        <button className="cerrar-panel" onClick={closePanel}>
          <div className="btn-icon-close">
            <i className="ion-ios-close-empty" />
          </div>
        </button>
      </div>
      <div className="col-xs-10 col-xs-offset-1 panel-pickwin">
        <div className="detalles-privadas-compartir-container">
          <span className="panel-invite-title">Comparte este link con tus amigos</span>
          <div className="flex-row row-center flex-container justify">
            <div className="codigo-amigo flex-col ">
              <input
                id="copiar-clipboard"
                value={
                  branchLink !== undefined
                    ? branchLink
                    : `https://www.pickwin.mx/lobby/quinielaPop/${bracket.slug}/${bracket.id}?referer=${userData.referalCode}`
                }
              />
            </div>
            <div className="copiar-button-codigo flex-col col-15">
              <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                Copia el enlace y compártelo
              </ReactTooltip>
              <button
                data-tip
                data-for="copiar-tool"
                className="copiar-btn"
                data-clipboard-target="#copiar-clipboard"
              >
                <i className="ion-ios-copy" />
                <span className="visible-md">Copiar</span>
              </button>
            </div>
          </div>
          <div className="btn-group inviteToPlay flex-row">
            <FacebookShareButton
              quote={`Participa en el bracket ${bracket.name_replaced} visitando este link `}
              hashtag={'#Pickwin #registrate #JalaTusPicks'}
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/bracket/${bracket.slug}/${bracket.id}?referer=${userData.referalCode}`
              }
            >
              <Button className="fb-btn">
                {' '}
                Por Facebook <i className="ion-social-facebook" />
              </Button>
            </FacebookShareButton>
            <TwitterShareButton
              title={`Participa en el bracket ${bracket.name_replaced} visitando este link `}
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/bracket/${bracket.slug}/${bracket.id}?referer=${userData.referalCode}`
              }
              hasthag={'#Pickwin #registrate #JalaTusPicks'}
            >
              <Button className="twitter-btn">
                {' '}
                Por Twitter <i className="ion-social-twitter" />
              </Button>
            </TwitterShareButton>
            <div className="SocialMediaShareButton">
              <Button className="mail-btn" onClick={openInvitePanel}>
                {' '}
                Por Mail <i className="ion-email" />
              </Button>
            </div>
            <div className="SocialMediaShareButton">
              <Button className="whatsapp-btn" onClick={whatsAppInvitation}>
                {' '}
                Por WhatsApp <i className="ion-social-whatsapp" />
              </Button>
            </div>
          </div>
        </div>
        <div className="btn-wrap">
          <Button className="btn-rojo gracias" onClick={closePanel}>
            No, gracias
          </Button>
        </div>
      </div>
    </div>
  );
};

class BracketsLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bracketStages: null,
      bracketInfo: null,
      bracketPrizes: null,
      bracketTiesDistribution: null,
      bracketMatches: null,
      branchLink: null,
      bracketPremios: null,
      bracketGroupPicks: null,
      bracketGroupPicksSeries: null,
      activeRound: 0,
      activeStage: 1,
      mounted: false,
      groups: null,
      withRegister: false,
      selection: 0,
      selectedEntry: null,
      showInvitePanel: false,
      groupPicksPage: 1,
      showPanelCancel: false,
      showPanelFirstRegister: false,
      showPanelReRegister: false,
      showMailInvitation: false,
      submittingSearch: false,
      popUpNewUsers: false,
      popUpNewUsersStatus: false,
      showTutorial: false,
      searchNick: '',
      selectedTeams: {
        // A: { first: null, second: null },
        // B: { first: null, second: null },
        // C: { first: null, second: null },
        // D: { first: null, second: null },
        // E: { first: null, second: null },
        // F: { first: null, second: null },
        // G: { first: null, second: null },
        // H: { first: null, second: null },
      },
      picks: {
        // '1A': null,
        // '2A': null,
        // '1B': null,
        // '2B': null,
        // '1C': null,
        // '2C': null,
        // '1D': null,
        // '2D': null,
        // '1E': null,
        // '2E': null,
        // '1F': null,
        // '2F': null,
        // '1G': null,
        // '2G': null,
        // '1H': null,
        // '2H': null,
        // '49': null,
        // '50': null,
        // '51': null,
        // '52': null,
        // '53': null,
        // '54': null,
        // '55': null,
        // '56': null,
        // '57': null,
        // '58': null,
        // '59': null,
        // '60': null,
        // '61L': null,
        // '62L': null,
        // '61': null,
        // '62': null,
        // '63': null,
        // '64': null,
      },
      notSelected: {
        // '61': null,
        // '62': null,
      },
      userBalance: null,
      activeSort: {
        posiciones: false,
        jugadores: false,
        aciertos: false,
        premios: false,
      },
      submittingSearch: false,
      sortChanged: false,
      showAbonar: false,
    };
    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
    };

    this.websocket = null;

    this.updateStandingsAganar = Utils.updateStandingsAganarBracket.bind(this);

    this.showTutorial = () => {
      this.setState({ showTutorial: true });
    };

    this.closeTutorial = () => {
      console.log('Close');
      this.setState({ showTutorial: false });
    };

    this.deleteNewUserStatus = () => {
      this.setState({ popUpNewUsers: null, popUpNewUsersStatus: false });
    };

    this.changeSearchNick = (ev) => {
      console.log('changeNick');
      this.queryParams.page = 1;
      this.setState({
        searchNick: ev.target.value,
      });
    };

    this.callbackSearchUser = (data) => {
      console.log(data);
      //let newStandings = data;

      this.setState({
        emptyList: data.items.length === 0 ? true : false,
        standings: data,
        submittingSearch: false,
        // liveFixtureData: Date.now(),
        // sortChanged: true,
      });
    };

    this.showSendMail = () => {
      console.log('ShowPanelSendMail');
      this.setState((prevState) => {
        return {
          showInviteMail: !prevState.showInviteMail,
        };
      });
    };

    this.whatsAppInvitation = () => {
      console.log('WhatsApp invitation');
      branchUtils.redirectToWhatsApp(this.state.branchLink, 'open', this.state.bracketInfo.id);
    };

    this.updateBranchLink = (newLink) => {
      console.log('UpdateBranchLink');
      this.setState({
        branchLink: newLink,
      });
    };

    this.showInviteFriendsPanel = () => {
      console.log('Show Invite Panel');
      this.setState({
        showInvitePanel: true,
      });
    };

    this.hideInviteFriendsPanel = () => {
      console.log('Hide Invite Panel');
      this.setState({
        showInvitePanel: false,
      });
    };

    this.resetNickValue = () => {
      this.setState(
        {
          searchNick: '',
          submittingSearch: true,
        },
        () => {
          this.queryParams.nick = '';
          this.queryParams.page = 1;
          API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams)
            .then((response) => {
              const responses = [response];

              try {
                utils.checkAxiosAllErrors(responses);
              } catch (e) {
                console.error(e);
                Sentry.captureException(e);
              }

              this.callbackSearchUser(response.data);
            })
            .catch((e) => {
              Sentry.captureException(e);
            });
        }
      );
    };

    this.fetchSearchableStandings = (ev, obj) => {
      if (!this.state.bracketInfo === undefined) {
        return;
      }
      this.setState({ submittingSearch: true });
      // if(this.state.submittingSearch === true ) { return;}
      // console.log('%c Search users', 'color:rgb(110, 223, 85);font-size:1.5em;');
      // console.log(ev.target.value);
      // console.log(ev.target);
      this.queryParams.nick = this.state.searchNick;
      API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams)
        .then((response) => {
          const responses = [response];

          try {
            utils.checkAxiosAllErrors(responses);
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
          }
          // console.log(response);
          this.callbackSearchUser(response.data);
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    };

    this.fetchSearchableStandings = _.debounce(this.fetchSearchableStandings, 500);

    /**rgba(#00ff00)
     *Turn on Panel Popups
     **/
    this.handleClickReRegister = () => {
      this.setState({ showPanelReRegister: true });
    };

    this.updatePanelCancelState = () => {
      this.setState({ showPanelCancel: true });
    };

    this.handleClickFirstRegister = () => {
      this.setState({ showPanelFirstRegister: true });
    };

    this.updatePanelReRegister = () => {
      this.setState({ showPanelReRegister: false });
    };

    this.handleCancelClick = () => {
      this.setState({ showPanelCancel: false });
    };

    this.updatePanelFirstRegister = () => {
      this.setState({ showPanelFirstRegister: false });
    };

    this.changeGroupPickStage = (newStage) => {
      console.log('ChangeGroupPicks Stage ', newStage);
      this.setState(
        {
          activeStage: newStage,
          // groupPicksPage: 1
        }
        // , () =>{
        //   API.fetchBracketGroupPicks(this.state.bracketInfo.id, this.state.activeStage, this.state.groupPicksPage)
        //   .then( response => {
        //     this.setState({
        //       bracketGroupPicks: response.data,
        //     })
        //   })
        // }
      );
    };

    this.redirectToLobby = () => {
      window.location.assign('/');
    };

    this.redirectAfterCancel = () => {
      this.setState({ redirectToLobby: true });
    };

    this.redirectAfterCancelCurrentEntry = () => {
      this.setState({ redirectToSame: true });
    };

    this.refreshAfterGuardar = (bracketEntryId, typeRegister) => {
      // Update Entries
      // Update Standings
      // Update bracketInfo
      console.log('Refresh some data to be updated after saved picks');
      console.log(bracketEntryId, typeRegister);
      if (typeRegister !== null && typeRegister === 'reRegister') {
        let currentEntryId = this.props.match.params.bracketEntryId;

        if (currentEntryId === undefined || currentEntryId === bracketEntryId) {
          window.location.assign(
            `/bracket/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${bracketEntryId}`
          );
        }

        axios
          .all([
            API.fetchBracketEntry(bracketEntryId),
            API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams),
            API.fetchBracketsDetails(this.state.bracketInfo.id),
            // API.fetchBracketGroupPicks(
            //   this.state.bracketInfo.id,
            //   this.state.activeStage,
            //   this.state.groupPicksPage
            // ),
            API.fetchBracketSeriesGroupPicks(this.state.bracketInfo.id, this.state.groupPicksPage),
          ])
          .then(
            axios.spread(
              (
                bracketEntriesResponse,
                bracketStandingsResponse,
                bracketsDetailsResponse,
                bracketGroupPicksResponse,
                bracketGroupPicksSeriesResponse,
              ) => {
                const responses = [
                  bracketEntriesResponse,
                  bracketStandingsResponse,
                  bracketsDetailsResponse,
                  bracketGroupPicksResponse,
                  bracketGroupPicksSeriesResponse,
                ];

                try {
                  utils.checkAxiosAllErrors(responses);
                } catch (e) {
                  console.error(e);
                  Sentry.captureException(e);
                }

                this.setState({
                  submitting: false,
                  selectedEntry: bracketEntryId,
                  bracketInfo: bracketsDetailsResponse.data.bracket,
                  entries: bracketEntriesResponse.data,
                  bracketEntries: bracketEntriesResponse.data,
                  selectedEntryData: bracketEntriesResponse.data.bracket_entry,
                  standings: bracketStandingsResponse.data,
                  bracketGroupPicks: bracketGroupPicksResponse.data,
                  showPanelReRegister: false,
                  loadedPicks: true,
                  mounted: true,
                  bracketGroupPicksSeries: bracketGroupPicksSeriesResponse.data,
                });
              }
            )
          )
          .catch((error) => {
            Sentry.captureException(error);
          });
      } else if (typeRegister !== null && typeRegister === 'save') {
        axios
          .all([
            API.fetchBracketEntry(bracketEntryId),
            API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams),
            API.fetchBracketsDetails(this.state.bracketInfo.id),
            // API.fetchBracketGroupPicks(
            //   this.state.bracketInfo.id,
            //   this.state.activeStage,
            //   this.state.groupPicksPage,
            // ),
            API.fetchBracketSeriesGroupPicks(this.state.bracketInfo.id, this.state.groupPicksPage),
          ])
          .then(
            axios.spread(
              (bracketEntriesResponse, bracketStandingsResponse, bracketsDetailsResponse, bracketGroupPicksResponse, bracketGroupPicksSeriesResponse) => {
                const responses = [
                  bracketEntriesResponse,
                  bracketStandingsResponse,
                  bracketsDetailsResponse,
                  bracketGroupPicksResponse,
                  bracketGroupPicksSeriesResponse
                ];

                try {
                  utils.checkAxiosAllErrors(responses);
                } catch (e) {
                  console.error(e);
                  Sentry.captureException(e);
                }
                this.setState({
                  submitting: false,
                  selectedEntry: bracketEntryId,
                  bracketInfo: bracketsDetailsResponse.data.bracket,
                  entries: bracketEntriesResponse.data,
                  bracketEntries: bracketEntriesResponse.data,
                  selectedEntryData: bracketEntriesResponse.data.bracket_entry,
                  standings: bracketStandingsResponse.data,
                  bracketGroupPicks: bracketGroupPicksResponse.data,
                  showPanelReRegister: false,
                  loadedPicks: true,
                  mounted: true,
                  bracketGroupPicksSeries: bracketGroupPicksSeriesResponse.data,
                });
              }
            )
          )
          .catch((error) => {
            Sentry.captureException(error);
          });
      }
    };

    this.getCurrentPicks = (picks) => {
      console.log(picks);
      this.setState({ picks: picks });
    };

    this.refreshAfterCancel = (newEntries, entryID, bracketID, currentCancelation) => {
      console.log('%c AfterCancel', 'color:rgb(127, 168, 249)');
      console.log('newEntries', newEntries);

      console.log(newEntries, entryID, bracketID, this.state.selectedEntry);

      let currentEntryExist = newEntries.find(
        (newEntry) => newEntry.id === Number(this.state.selectedEntry)
      );

      if (newEntries.length === 0) {
        //Redirect to the main lobby
        console.log('Redirect to lobby');
      }

      axios
        .all([
          API.fetchBracketEntry(entryID),
          API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams),
          API.fetchBracketsDetails(this.state.bracketInfo.id),
          API.fetchBracketSeriesGroupPicks(this.state.bracketInfo.id, this.state.groupPicksPage),
        ])
        .then(
          axios.spread(
            (bracketEntriesResponse, bracketStandingsResponse, bracketsDetailsResponse, bracketGroupPicksSeriesResponse) => {
              const responses = [
                bracketEntriesResponse,
                bracketStandingsResponse,
                bracketsDetailsResponse,
                bracketGroupPicksSeriesResponse,
              ];

              try {
                utils.checkAxiosAllErrors(responses);
              } catch (e) {
                console.error(e);
                Sentry.captureException(e);
              }

              this.setState(
                {
                  submitting: false,
                  selectedEntry: entryID,
                  bracketInfo: bracketsDetailsResponse.data.bracket,
                  entries: bracketEntriesResponse.data,
                  bracketEntries: bracketEntriesResponse.data,
                  selectedEntryData: bracketEntriesResponse.data.bracket_entry,
                  standings: bracketStandingsResponse.data,
                  showPanelReRegister: false,
                  loadedPicks: true,
                  mounted: true,
                  bracketGroupPicksSeries: bracketGroupPicksSeriesResponse.data,
                },
                () => {
                  if (currentEntryExist === undefined) {
                    console.log('Current entry has been deleted,  reassign path');
                    history.push(
                      `/bracket/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntry}`
                    );
                  }
                }
              );
            }
          )
        )
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching Axios all in Bracket Lobby',
          });
        });
    };

    /**
     * This function handles a call to the API
     * to get the new page of the positions
     */
    this.siguientePagPositions = (eventKey) => {
      console.log('Next Positions...');
      let loaderElement = $('#loading');
      let tempActiveSort = null;
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.state.standings.items;
      // console.log('siguiente');
      //let val = eventKey;

      this.queryParams.page = this.queryParams.page + 1;

      API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams).then((response) => {
        const responses = [response];

        try {
          utils.checkAxiosAllErrors(responses);
        } catch (e) {
          console.error(e);
          Sentry.captureException(e);
        }

        this.setState(
          (prevState) => {
            console.log(tempActiveSort);
            let newPosiciones = response.data;

            let newItems = response.data.items !== null ? response.data.items : null;

            newItems.forEach((item) => {
              temporalPositions.push(item);
            });

            // console.log('NEWPos',temporalPositions);
            newPosiciones.items = temporalPositions;
            return {
              standings: newPosiciones,
              posActualPage: this.queryParams + 1,
            };
          },
          () => {
            // if(this.props.sortPositionList !== undefined){
            //   setTimeout( () => {
            //     console.log(tempActiveSort);
            //     this.props.sortPositionList( tempActiveSort, true );
            //   }, 150);
            // }
            if (this.updateScrollPositions !== undefined) {
              this.updateScrollPostions(this.state.posiciones);
            }
            if (loaderElement !== undefined && loaderElement.length > 0) {
              loaderElement[0].style.display = 'none';
              //let avatars = $('.score-avatars').height('auto');
            }
          }
        );
      });
    };

    this.updateUserInfo = (data) => {
      if (data === undefined) {
        API.fetchUserData()
          .then((response) => {
            const responses = [response];

            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }
            this.setState({ userData: response.data });
          })
          .catch((error) => {
            Sentry.captureException(error, {
              extra: 'Error fetching individual bracket in lobby',
            });
          });
      } else {
        this.setState({
          userData: data,
        });
      }
    };

    this.changeSection = (e) => {
      this.setState({ selection: e });
    };

    /**
     * this function Change the Entry Id in a multy entry quiniela
     * @param value
     */
    this.changeSelectedEntry = (value, type) => {
      console.log('changeSelectedEntry', value, type);
      if (value === undefined) {
        return;
      }
      this.setState({
        loadedPicks: false,
      });
      //let similar_, entries_;
      API.fetchBracketEntry(value)
        .then((entries) => {
          const responses = [entries];

          try {
            utils.checkAxiosAllErrors(responses);
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
          }
          let entries_ = entries.data;
          // similar_ = similar.data;
          let entryData;
          if (entries_.bracket_entry.id === value) {
            entryData = entries_.bracket_entry;
          } else {
            entryData = entries_.other_entries.find((entry) => entry.id === value);
          }
          let cambioE = true;
          let loadedP = true;
          if (type !== undefined) {
            cambioE = null;
            loadedP = false;
          }

          this.setState({
            selectedEntry: value,
            entries: entryData,
            selectedEntryData: entryData,
            loadedPicks: true,
            mounted: true,
          });
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching bracket entry',
          });
        });
    };

    this.updateUserInfo = (userData) => {
      //  console.log( 'UpdateUser Info from children in PAge Wrapper' );
      if (!userData || userData === 'isNewRegister') {
        //  console.log( 'UserData dont exist so fetch it' );
        API.fetchUserData()
          .then((responseUserData) => {
            utils.setUserInfo(responseUserData.data);
            const balanceObj = utils.setUserBalance(responseUserData.data);
            this.setState({
              isLogged: true,
              userData: responseUserData.data.user,
              userBalance: balanceObj,
            });
          })
          .catch((error) => {
            Sentry.captureException(error, {
              extra: 'Error fetching user data',
            });
          });
      } else {
        //  console.log( 'UpdatedData from user' , userData );
        if (typeof userData !== 'object') {
          return;
        }

        utils.setUserInfo(userData);
        const balanceObj = {
          balanceReal: userData.balanceReal,
          balancePc: !userData.balancePc ? 0 : userData.balancePc,
          balanceReferral: !userData.balanceReferral ? 0 : userData.balanceReferral,
          balanceFreeroll: !userData.balanceFreeroll ? 0 : userData.balanceFreeroll,
          balanceBonus: !userData.balanceBonus ? 0 : userData.balanceBonus,
          tickets: !userData.tickets ? [] : userData.tickets,
        };
        if (userData.hasOwnProperty('user')) {
          console.log('UpdatedData from user with user', userData);
          this.setState({
            userData: userData.user,
            userBalance: balanceObj,
            isLogged: true,
            isNewTosConfirmed: userData.user.tos,
            userLoyalty: utils.getUserLoyalty(),
          });
        } else {
          console.log('UpdatedData from user without', userData);
          this.setState({
            userData: userData,
            userBalance: balanceObj,
            isLogged: true,
            isNewTosConfirmed: userData.tos,
            userLoyalty: utils.getUserLoyalty(),
          });
          console.log('UpdatedState from user without', this.state);
        }
      }
    };

    this.updateUserBalance = (newBalance) => {
      if (newBalance === undefined) {
        console.error('Balance is undefined');
        return;
      }
      const balanceObj = utils.getUserBalance();
      if (newBalance.balanceReal !== undefined) {
        balanceObj.balanceReal = newBalance.balanceReal;
        balanceObj.balanceBonus = newBalance.balanceBonus;
      } else {
        balanceObj.balanceReal = newBalance;
      }
      console.log(balanceObj);
      utils.setUserBalance(balanceObj);
      this.setState({ userBalance: balanceObj, isLogged: true });
    };

    this.sortPositionList = (type) => {
      this.setState({
        submittingSearch: true,
      });

      let tempActiveSort = this.state.activeSort;
      let orderStatus;
      switch (type) {
        case 'posiciones':
          orderStatus = this.state.activeSort.posiciones ? -1 : 1;
          this.queryParams.sort = 'rank';
          tempActiveSort.posiciones = !tempActiveSort.posiciones;
          if (tempActiveSort.posiciones) {
            tempActiveSort.jugadores = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'jugadores':
          orderStatus = this.state.activeSort.jugadores ? -1 : 1;
          this.queryParams.sort = 'nick';
          tempActiveSort.jugadores = !tempActiveSort.jugadores;
          if (tempActiveSort.jugadores) {
            tempActiveSort.posiciones = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'aciertos':
          orderStatus = this.state.activeSort.aciertos ? -1 : 1;
          this.queryParams.sort = 'points';
          if (this.state.bracketInfo.status === 'closed') {
            tempActiveSort.aciertos = !tempActiveSort.aciertos;
            if (tempActiveSort.aciertos) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          } else {
            tempActiveSort.aciertos = !tempActiveSort.aciertos;
            if (tempActiveSort.aciertos) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          }
          break;
        case 'premios':
          orderStatus = this.state.activeSort.premios ? -1 : 1;
          this.queryParams.sort = 'prize';
          tempActiveSort.premios = !tempActiveSort.premios;
          break;
        default:
          return;
      }

      this.setState(
        (prevState) => {
          return {
            activeSort: tempActiveSort,
            sortChanged: !prevState.sortChanged,
            liveFixturesData: Date.now(),
            actualPage: 1,
          };
        },
        () => {
          //let temporalSortStatus = this.state.activeSort;
          this.queryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
          this.queryParams.page = 1;
          console.log(this.queryParams);
          API.fetchBracketStandings(this.state.bracketInfo.id, this.queryParams)
            .then((response) => {
              console.log('Response', response.data);
              this.callbackSort(response.data);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error in quiniela fetching standings',
              });
            });
          // this.queryParams.sort = [];
          console.log('After Sort', this.state.standings.items[0].entry_id, this.state.sortChanged);
        }
      );
    }

    this.callbackSort = (data) => {
      let newStandings = data;
      console.log('prevStandings', newStandings);

      let newUpdatedPositions = newStandings.items;
      if (this.state.bracketInfo.status === 'live') {
        newUpdatedPositions = this.updateStandingsAganar(
          newStandings.items,
          this.state.bracketInfo.prize_currency
        );
      }

      this.setState(
        {
          emptyList: data.items.length === 0 ? true : false,
          standings: data,
          submittingSearch: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    this.handleAbonar = () => {
      this.setState({showAbonar: !this.state.showAbonar});
    }

    this.loadMoreStandings = (page) => {
      let query = this.queryParams;
      query.page = page;
      const bracketId = this.props.match.params.bracketId;
      const bracketEntryId = this.props.match.params.bracketEntryId;

      axios.all([
          API.fetchBracketSeriesGroupPicks(bracketId, query.page),
        ]).then(
          axios.spread((bracketGroupPicksSeriesResponse,) => {
            const responses = [
              bracketGroupPicksSeriesResponse
            ];

            const bracketGroupPicksSeriesData = bracketGroupPicksSeriesResponse.data;

            let tempGroupPick = this.state.bracketGroupPicksSeries;
            tempGroupPick.page = bracketGroupPicksSeriesData.page;
            tempGroupPick.pages = bracketGroupPicksSeriesData.pages;
            bracketGroupPicksSeriesData.items.map((pick) => {
              tempGroupPick.items.push(pick);
            });

            this.setState({
              bracketGroupPicksSeries: tempGroupPick,
            });
          }));
    }
  }

  componentDidMount() {
    gaUtils.handleDocumentAttributes(this.props.match);

    clipboardCode = new Clipboard('.copiar-btn');
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      console.log('wich', wich);
      $(wich).addClass('copied');
      e.clearSelection();
    });

    let userData = utils.getUserInfo();
    let isLogged = false;
    // console.log(this.props);
    const bracketId = this.props.match.params.bracketId;
    const bracketEntryId = this.props.match.params.bracketEntryId;

    if (userData === undefined || userData === null) {
      console.log('Not Logged');
    } else {
      isLogged = true;
      let balance = utils.getUserBalance();
      this.setState({userBalance: balance});
    }

    let jwToken = utils.getToken();
    const socketUrl = process.env.REACT_APP_PW_SOCKET_LIVE_PANE;
    this.websocket = io.connect(socketUrl, {
      query: { token: jwToken },
      secure: true,
      transports: ['websocket'],
    });

    this.websocket.on('connect', () => {
      this.websocket.emit('subscribe', Number(bracketId), 'bracket');
    });

    this.websocket.on('error', (error) => {
      console.log('WS-ERROR', error);
    });

    this.websocket.on('bracket_standings:update', (data) => {
      let allStandings = this.state.standings;
      let allGroupPicks = this.state.bracketGroupPicksSeries;
      allStandings.items = data.standings;

      allGroupPicks.items.map(gp => {
        let pick = data.standings.find(standing => standing.user_id === gp.user_id);
        if (pick) {
          gp.points = pick.points;
          gp.possible_points = pick.possible_points;
          gp.possible_rank = pick.possible_rank;
          gp.rank = pick.rank;
          gp.user_prize = pick.user_prize;
        }
      });
      allGroupPicks.items.sort(firstBy('rank').thenBy('posible_rank'));

      this.setState(() => {
        return {standings: allStandings, bracketTiesDistribution: data.ties_distribution, bracketGroupPicksSeries: allGroupPicks};
      });
    });

    this.websocket.on('matches:update', (data) => {
      let matches = this.state.bracketMatches;

      data.matches.map(match => {
        let matchIndex = matches.findIndex(item => item.id === match.id);
        if ( matchIndex === -1 ) {return;}

        matches[matchIndex].games_won_away = match.games_won_away;
        matches[matchIndex].games_won_home = match.games_won_home;
        matches[matchIndex].match_date = match.match_date;
        matches[matchIndex].match_deadline = match.match_deadline;
        matches[matchIndex].winning_game = match.winning_game;
        matches[matchIndex].winning_pick = match.winning_pick;
      });

      this.setState({bracketMatches: matches});
    });

    this.websocket.on('fixture:update', (data) => {
      const {results, live_fixtures} = data;
      let matches = this.state.bracketMatches;

      _.each( results, (result) => {
        _.each( matches, (match) => {

          var fixtureIdx = match.fixtures.findIndex((fix) => fix.feed_id === result.feed_id );
          if ( fixtureIdx === -1 ) {return;}

          if ( !match.fixtures[fixtureIdx].stats ) {
            match.fixtures[fixtureIdx].stats = {};
          }
          match.fixtures[fixtureIdx].stats = result;
          match.fixtures[fixtureIdx].stats.quarter = result.hasOwnProperty('quarter') ? result.quarter : null;
          match.fixtures[fixtureIdx].stats.timer = result.timer

          // search fixture
          var fixture = _.find(live_fixtures, {id: match.fixtures[fixtureIdx].id});
          if ( fixture ) {
            match.fixtures[fixtureIdx].score_home        = fixture.score_home;
            match.fixtures[fixtureIdx].score_away        = fixture.score_away;
            match.fixtures[fixtureIdx].winner_team_id    = fixture.winner_team_id;
            match.fixtures[fixtureIdx].winning_pick      = fixture.winning_pick;
            match.fixtures[fixtureIdx].winning_pick_line = fixture.winning_pick_line;
          }

        });
      });

      this.setState({bracketMatches: matches});
    });

    //User is already registered
    if (bracketId && bracketEntryId) {
      API.fetchBracketsDetails(bracketId).then((bracketDetailsResponse) => {
        bracketDetailsResponse.data.bracket.tournament_instances.map(tournament => {
          this.websocket.emit('subscribe', Number(tournament.id), 'bracket-tournament-instance');
        });
        axios
          .all([
            API.fetchBracketEntry(bracketEntryId),
            API.fetchBracketGroups(bracketDetailsResponse.data.bracket.tournament_instances[0].id),
            API.fetchBracketStages(bracketDetailsResponse.data.bracket.tournament_instances[0].id),
            API.fetchUserData(),
            API.fetchBracketStandings(bracketId, this.queryParams),
            // API.fetchBracketGroupPicks(
            //   bracketId,
            //   this.state.activeStage,
            //   this.state.groupPicksPage
            // ),
            API.fetchTickets(bracketId, 'bracket'),
            API.fetchBracketSeriesGroupPicks(bracketId, this.state.groupPicksPage),
          ])
          .then(
            axios.spread(
              (
                bracketEntryResponse,
                bracketGroupsResponse,
                bracketStagesResponse,
                userData_,
                bracketStandingsResponse,
                // bracketGroupPicksResponse,
                bracketTicketsResponse,
                bracketGroupPicksSeriesResponse,
              ) => {
                const responses = [
                  bracketEntryResponse,
                  bracketGroupsResponse,
                  bracketStagesResponse,
                  userData_,
                  bracketStandingsResponse,
                  // bracketGroupPicksResponse,
                  bracketTicketsResponse,
                  bracketGroupPicksSeriesResponse
                ];

                try {
                  utils.checkAxiosAllErrors(responses);
                } catch (e) {
                  console.error(e);
                  Sentry.captureException(e);
                }

                const bracketEntryData = bracketEntryResponse.data;
                const bracketGroups = bracketGroupsResponse.data;
                const bracketStages = bracketStagesResponse.data.stages;
                const bracketMatches = bracketStagesResponse.data.matches;
                const currentStage = bracketStagesResponse.data.current;
                const updatedUserData = userData_.data;
                const bracketStandingsData = bracketStandingsResponse.data;
                // const bracketGroupPicksData = bracketGroupPicksResponse.data;
                const bracketTicketsData = bracketTicketsResponse.data.tickets;
                const bracketGroupPicksSeriesData = bracketGroupPicksSeriesResponse.data;

                let tempMatches = {};

                let teams = [];

                bracketGroups.groups.map((group) => {
                  group.teams.forEach((team) => {
                    teams.push(team);
                  });
                });

                let balanceEnough = utils.isBalanceEnough(
                  bracketDetailsResponse.data.bracket.entry_currency,
                  bracketDetailsResponse.data.bracket.entry_fee
                );

                this.setState(
                  {
                    bracketEntries: bracketEntryData,
                    // bracketGroupPicks: bracketGroupPicksData,
                    bracketGroupPicksSeries: bracketGroupPicksSeriesData,
                    bracketInfo: bracketDetailsResponse.data.bracket,
                    bracketPrizes: bracketDetailsResponse.data.bracket_prizes,
                    bracketTiesDistribution: bracketDetailsResponse.data.ties_distribution,
                    bracketStages: bracketStages,
                    bracketMatches: bracketMatches,
                    bracketTeams: teams,
                    enoughBalance: !balanceEnough && !bracketTicketsData.length ? false : true,
                    groups: bracketGroups.groups,
                    isLogged: isLogged,
                    matches: bracketMatches,
                    mounted: true,
                    selectedEntry: bracketEntryId,
                    selectedEntryData: bracketEntryData.bracket_entry,
                    standings: bracketStandingsData,
                    userData: updatedUserData,
                    withRegister: true,
                    bracketTickets: bracketTicketsData,
                    bracketCurrentStage: currentStage,
                  },
                  () => {
                    history.push(
                      `/bracket/${this.state.bracketInfo.slug}/${this.state.bracketInfo.id}/${this.state.selectedEntry}`
                    );
                  }
                );
              }
            )
          )
          .catch((error) => {
            Sentry.captureException(error);
            //window.location.assign('/');
          });
      });
    } else {
      // User without a entry
      console.log("bracket without entry");
      API.fetchBracketsDetails(bracketId)
        .then((bracketDetailsResponse) => {
          bracketDetailsResponse.data.bracket.tournament_instances.map(tournament => {
            this.websocket.emit('subscribe', Number(tournament.id), 'bracket-tournament-instance');
          });
          axios
            .all([
              API.fetchBracketGroups(
                bracketDetailsResponse.data.bracket.tournament_instances[0].id
              ),
              API.fetchBracketStages(
                bracketDetailsResponse.data.bracket.tournament_instances[0].id
              ),
              API.fetchUserData(),
              API.fetchBracketStandings(bracketId),
              API.fetchTickets(bracketId, 'bracket'),
              API.fetchBracketSeriesGroupPicks(bracketId, this.state.groupPicksPage),
            ])
            .then(
              axios.spread(
                (
                  bracketGroupsResponse,
                  bracketStagesResponse,
                  userData_,
                  bracketStandingsResponse,
                  bracketTicketsResponse,
                  bracketGroupPicksSeriesResponse
                ) => {
                  const responses = [
                    bracketGroupsResponse,
                    bracketStagesResponse,
                    userData_,
                    bracketStandingsResponse,
                    bracketTicketsResponse,
                    bracketGroupPicksSeriesResponse
                  ];

                  try {
                    utils.checkAxiosAllErrors(responses);
                  } catch (e) {
                    console.error(e);
                    Sentry.captureException(e);
                  }

                  const bracketGroups = bracketGroupsResponse.data;
                  const bracketStages = bracketStagesResponse.data.stages;
                  const bracketMatches = bracketStagesResponse.data.matches;
                  const currentStage = bracketStagesResponse.data.current;
                  const updatedUserData = userData_.data;
                  const bracketStandingsData = bracketStandingsResponse.data;
                  const bracketTicketsData = bracketTicketsResponse.data.tickets;
                  const bracketGroupPicksSeriesData = bracketGroupPicksSeriesResponse.data;
                  let tempMatches = {};
                  let teams = [];

                  bracketGroups.groups.map((group) => {
                    group.teams.forEach((team) => {
                      teams.push(team);
                    });
                  });

                  let balanceEnough = utils.isBalanceEnough(
                    bracketDetailsResponse.data.bracket.entry_currency,
                    bracketDetailsResponse.data.bracket.entry_fee
                  );
                  this.setState({
                    selection: 0,
                    bracketEntries: null,
                    bracketInfo: bracketDetailsResponse.data.bracket,
                    bracketPrizes: bracketDetailsResponse.data.bracket_prizes,
                    bracketTiesDistribution: bracketDetailsResponse.data.ties_distribution,
                    bracketStages: bracketStages,
                    bracketMatches: bracketMatches,
                    bracketGroupPicksSeries: bracketGroupPicksSeriesData,
                    bracketTeams: teams,
                    enoughBalance: !balanceEnough && !bracketTicketsData.length ? false : true,
                    groups: bracketGroups.groups,
                    isLogged: isLogged,
                    matches: tempMatches,
                    mounted: true,
                    standings: bracketStandingsData,
                    userData: updatedUserData,
                    bracketTickets: bracketTicketsData,
                    bracketCurrentStage: currentStage,
                  });
                }
              )
            )
            .catch((error) => {
              Sentry.captureException(error);
            });
        })
        .catch((error) => {
          Sentry.captureException(error);
          window.location = '/';
        });
    }
  }

  componentWillUnmount() {
    this.websocket.disconnect();
  }

  render() {
    if (this.state.mounted) {
      return (
        <ErrorBoundary>
          <div className="page-wrapper">
            <div className="inner-wrapper">
              <div className="quina-lobby-wrapper">
                <NavigationBar
                  isLogged={this.state.isLogged}
                  userBalance={this.state.userBalance}
                  userData={this.state.userData}
                  updateUserBalance={this.updateUserBalance}
                  updateUserInfo={this.updateUserInfo}
                />
              </div>
              <ClockContextProvider>
                <div className="container-fluid">
                  <MediaQuery query="(max-width: 659px)">
                    <ScoreMobile
                      changeSelectedEntry={this.changeSelectedEntry}
                      changeStatusPool={this.changeStatusPool}
                      entries={this.state.bracketEntries}
                      handleCancelPanel={this.handleCancelClick}
                      handleClickFirstRegister={this.handleClickFirstRegister}
                      handleClickReRegister={this.handleClickReRegister}
                      liveClosedFixtures={this.state.liveClosedFixtures}
                      withRegister={this.state.withRegister}
                      logged={this.state.isLogged}
                      poolInfo={this.state.bracketInfo}
                      //pools={this.state.pools}
                      popup={false}
                      quinaID={this.state.bracketInfo.id}
                      redirectToLobby={this.redirectToLobby}
                      refreshAfterCancel={this.refreshAfterCancel}
                      showInvite={this.showInviteFriendsPanel}
                      hideInvite={this.hideInviteFriendsPanel}
                      selectedEntry={this.state.selectedEntry}
                      type="bracket"
                      updateClosedQuiniela={this.updateClosedQuiniela}
                      updateStateFuncCancel={this.updatePanelCancelState}
                      updateUserInfo={this.updateUserInfo}
                      status={this.state.bracketInfo.status}
                    />
                  </MediaQuery>
                  <MediaQuery query="(min-width: 660px)">
                    <Score
                      changeSelectedEntry={this.changeSelectedEntry}
                      changeStatusPool={this.changeStatusPool}
                      entries={this.state.bracketEntries}
                      handleCancelPanel={this.handleCancelClick}
                      handleClickFirstRegister={this.handleClickFirstRegister}
                      handleClickReRegister={this.handleClickReRegister}
                      liveClosedFixtures={this.state.liveClosedFixtures}
                      withRegister={this.state.withRegister}
                      logged={this.state.isLogged}
                      poolInfo={this.state.bracketInfo}
                      //pools={this.state.pools}
                      popup={false}
                      quinaID={this.state.bracketInfo.id}
                      redirectToLobby={this.redirectToLobby}
                      refreshAfterCancel={this.refreshAfterCancel}
                      selectedEntry={this.state.selectedEntry}
                      showInvite={this.showInviteFriendsPanel}
                      hideInvite={this.hideInviteFriendsPanel}
                      updateBranchLink={this.updateBranchLink}
                      type="bracket"
                      updateClosedQuiniela={this.updateClosedQuiniela}
                      updateStateFuncCancel={this.updatePanelCancelState}
                      updateUserInfo={this.updateUserInfo}
                      status={this.state.bracketInfo.status}
                    />
                  </MediaQuery>
                  <div
                    id="multiple-quiniela-container"
                    className={'quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1'}
                  >
                    <Nav
                      bsStyle="tabs"
                      bsClass="tab-container flex-row row-no-padding nav"
                      onSelect={this.changeSection}
                    >
                      <NavItem
                        eventKey="0"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(this.state.selection) === 0 ? 'active' : null
                        }`}
                      >
                        BRACKET
                      </NavItem>
                      <NavItem
                        eventKey="1"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(this.state.selection) === 1 ? 'active' : null
                        }`}
                      >
                        POSICIONES
                      </NavItem>
                      <NavItem
                        eventKey="2"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(this.state.selection) === 2 ? 'active' : null
                        }`}
                        style={
                          this.state.bracketInfo.closed
                            ? { display: 'initial' }
                            : this.state.bracketInfo.status === 'upcoming'
                            ? this.state.withRegister === false
                              ? { display: 'none' }
                              : { display: 'initial' }
                            : { display: 'initial' }
                        }
                      >
                        PICKS DE GRUPO
                      </NavItem>
                      <NavItem
                        eventKey="3"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(this.state.selection) === 3 ? 'active' : null
                        }`}
                      >
                        MARCADORES
                      </NavItem>
                    </Nav>
                    <Modal
                      dialogClassName="invitarAmigosModalDialog modal-dialog-xxxs"
                      className="invitarAmigosModal"
                      backdropClassName="invitarAmigosBackdrop"
                      show={this.state.showInvitePanel}
                      onHide={this.hideInviteFriendsPanel}
                    >
                      <PanelInvitarAmigos
                        title={`Invita a tus amigos a jugar el bracket ${this.state.bracketInfo.name_replaced}`}
                        bracket={this.state.bracketInfo}
                        whatsAppInvitation={this.whatsAppInvitation}
                        openInvitePanel={this.showSendMail}
                        closePanel={this.hideInviteFriendsPanel}
                        branchLink={this.state.branchLink}
                      />
                    </Modal>
                    <InvitarAmigos
                      pool={this.state.bracketInfo}
                      showPanel={this.state.showInviteMail}
                      userDataProps={this.state.userData}
                      updateStateFunc={this.showSendMail}
                    />
                    <BracketInfoContainer
                      enoughBalance={this.state.enoughBalance}
                      activeSort={this.state.activeSort}
                      activeStage={this.state.activeStage}
                      availableMessages={this.state.availableMessages}
                      bracketGroupPicks={this.state.bracketGroupPicks}
                      bracketGroups={this.state.groups}
                      bracketInfo={this.state.bracketInfo}
                      bracketPrizes={this.state.bracketPrizes}
                      bracketTiesDistribution={this.state.bracketTiesDistribution}
                      bracketEntriesInfo={this.state.bracketEntriesInfo}
                      bracketMatches={this.state.bracketMatches}
                      bracketStages={this.state.bracketStages}
                      bracketTeams={this.state.bracketTeams}
                      changeSearchNick={this.changeSearchNick}
                      changeGroupPickStage={this.changeGroupPickStage}
                      entriesInfo={this.state.bracketEntriesInfo}
                      fetchSearchableStandings={this.fetchSearchableStandings}
                      getCurrentPicks={this.getCurrentPicks}
                      groupPicksPage={this.state.groupPicksPage}
                      handleMaxHeight={this.handleMaxHeight}
                      handleMessage={this.handleMessage}
                      handleUpdateReset={this.props.handleUpdateReset}
                      liveClosedFixtures={this.props.liveClosedFixtures}
                      liveFixturesData={this.props.liveFixturesData}
                      loaded={this.state.mounted}
                      loadedPicks={this.state.loadedPicks}
                      logged={this.state.isLogged}
                      newMessage={this.state.newMessage}
                      nextPicks={this.props.nextPicks}
                      nextProps={this.props.nextProps}
                      poolInfo={this.state.bracketInfo}
                      poolWeekSelected={this.props.poolWeekSelected}
                      posiciones={this.props.bracketStandings}
                      queryParams={this.queryParams}
                      refreshAfterGuardar={this.refreshAfterGuardar}
                      resetMessage={this.resetMessage}
                      resetNickValue={this.resetNickValue}
                      selectedEntry={this.state.selectedEntry}
                      selectedEntryData={this.state.selectedEntryData}
                      selection={this.state.selection}
                      sendMessage={this.sendMessage}
                      showReRegister={this.state.showPanelReRegister}
                      siguientePagPositions={this.siguientePagPositions}
                      sortChanged={this.props.sortChanged}
                      sortPositionList={this.sortPositionList}
                      standGroups={this.state.standGroups}
                      standings={this.state.standings}
                      submittingSearch={this.state.submittingSearch}
                      updateClosedFixture={this.props.updateClosedFixture}
                      updatedStandings={this.props.updatedStandings}
                      updateScrollPostions={this.updateScrollPositions}
                      updateStartedFixture={this.props.updateStartedFixture}
                      updateUserInfo={this.updateUserInfo}
                      userData={this.state.userData}
                      withRegister={this.state.withRegister}
                      bracketTickets={this.state.bracketTickets}
                      bracketGroupPicksSeries={this.state.bracketGroupPicksSeries}
                      showAbonar={this.handleAbonar}
                      loadMoreStandings={this.loadMoreStandings}
                      bracketCurrentStage={this.state.bracketCurrentStage}
                    />
                  </div>
                  <RegisterPanel
                    type="bracket"
                    closePanel={this.updatePanelFirstRegister}
                    entryId={this.state.selectedEntry}
                    handleConfirmation={this.handleConfirmation}
                    logged={this.state.isLogged}
                    payType={this.state.bracketInfo.entry_currency}
                    pool={this.state.bracketInfo}
                    matches={this.state.bracketStages}
                    pools={null}
                    popUpNewUsersStatus={this.state.popUpNewUsersStatus}
                    quinaID={this.state.bracketInfo.id}
                    refreshAfterGuardar={this.refreshAfterGuardar}
                    registerPicks={this.state.picks}
                    returnErrors={this.returnErros}
                    showPanel={this.state.showRegisterPanel}
                    showPanelFirstRegister={this.state.showPanelFirstRegister}
                    showPanelReRegister={this.state.showPanelReRegister}
                    showTutorial={this.showTutorial}
                    updatePanelFirstRegister={this.updatePanelFirstRegister}
                    updatePanelReRegister={this.updatePanelReRegister}
                    updateStateFunc={this.updatePanelReRegister}
                    updateUserInfo={this.updateUserInfo}
                    userData={this.state.userData}
                    withRegister={this.state.withRegister}
                  />
                  <CancelRegister
                    allEntries={this.state.bracketEntries}
                    entries={this.state.bracketEntries}
                    logged={this.state.logged}
                    pool={this.state.bracketInfo}
                    pools={null}
                    quinaID={this.state.bracketInfo.id}
                    refreshAfterCancel={this.refreshAfterCancel}
                    redirectAfterCancel={this.redirectAfterCancel}
                    redirectToLobby={this.redirectToLobby}
                    registerPicks={this.state.registerPicks}
                    registerState={this.registerState}
                    showPanel={this.state.showPanelCancel}
                    type="bracket"
                    updateStateFunc={this.handleCancelClick}
                    updateUserInfo={this.updateUserInfo}
                    userDataProps={this.state.userData}
                  />
                  <AbonarSaldo
                    type="modal"
                    showAbonarSaldo={this.state.showAbonar}
                    updatePanelSate={this.handleAbonar}
                    showAbonarPop={this.handleAbonar}
                    updateUserInfo={this.updateUserInfo}
                  />
                </div>
              </ClockContextProvider>
              <div id="push" />
            </div>
            <Footer />
          </div>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary>
          <Loading label="..." />
        </ErrorBoundary>
      );
    }
  }
}

export default BracketsLobby;
