import React from 'react';
import '../../assets/stylesheets/types-sprites.scss';
import firstBy from 'thenby';
import avatar from '../../assets/images/avatar-50.png';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import _ from 'underscore';

const categories = [
  { label: 'Pos', size: 10 },
  { label: 'Piloto', size: 30 },
  { label: 'Vueltas', size: null },
  { label: 'Tiempo', size: null },
  { label: 'Pits', size: null },
];

const LeadersHeader = ({ categories }) => {
  let listCategories = categories.map((c, cIdx) => {
    return (
      <li
        key={cIdx}
        className={`flex-col col-no-padding col-${c.size} ${
          c.label !== 'Jugador' ? 'text-center col-center' : ''
          } col-center`}
      >
        {c.label}
      </li>
    );
  });
  return <ul className="header-leaderboard flex-row row-center">{listCategories}</ul>;
};

const LeadersList = ({ players, buckets, dispatch, myEntry }) => {
  let sortedPlayers = _.map(players, (p) => p);

  sortedPlayers = sortedPlayers.sort(firstBy('finish_pos'));

  let list = _.map(sortedPlayers, (player) => {
    let selectedPlayer = null;

    if (myEntry) {
      selectedPlayer = _.find(myEntry.group_pool_picks, (pick) => pick.pick === player.id);
    }

    let colorStyle = {};

    if (player.score > 0) {
      colorStyle = { color: 'rgb(255, 199, 48)' };
    } else if (player.score < 0) {
      colorStyle = { color: '#78bc28' };
    }

    return (
      <li
        key={player.id}
        className={`flex-row row-center row-no-padding leaderboard-item ${
          selectedPlayer ? 'myPick' : ''
          } `}
      >
        <div className={`flex-col col-${categories[0].size} col-center text-center `}>
          {player.finish_pos}{'°'}
        </div>
        <div className={`flex-col col-${categories[1].size} `}>
          <div className="flex-row row-center uppercase">
            <div>
              <img alt={player.name} src={player.avatar === null ? avatar : player.avatar} className="player-avatar" style={{width: '28px', height: '28px'}} />
            </div>
            <div>
              <div className='flex-container'>
                <i className={player.logo} style={{ marginRight: '5px' }} />
                <div>{player.name}</div>
              </div>
              <div className='flex-container'>
                <img src={player.alt_avatar} style={{width: '70px', height: '15px'}} />
              </div>
            </div>
            {/* <i className={player.logo} style={{ marginRight: '5px' }} />
            <div className='overflow-words'> {player.name} </div> */}
          </div>
        </div>
        <div className={`flex-col col-${categories[2].size} col-center text-center`} style={colorStyle}>
          {player.laps}
        </div>
        <div className={`flex-col col-${categories[3].size} col-center text-center`}>
          {player.time}
        </div>
        <div className={`flex-col col-${categories[4].size} col-center text-center`}>
          {player.pit}
        </div>
      </li>
    );
  });
  //console.log(list);
  return (
    <ul className="list-unstyled flex-col col-no-padding leaderboard-list overflow-y overflow overflow-picks-grupo">
      {list}
    </ul>
  );
};

const LeaderboardF1 = () => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);

  return (
    <div className=" multiple-quiniela-info principal flex-row row-no-padding responsive-sm lista-jugadores panel-pickwin flex-container justify align">
      <div style={{ margin: '15px ' }} className="flex-col col-95 col-no-padding panel-pickwin">
        <div className={`flex-row title-quiniela`}>
          <div className="flex-col title-wrapper">
            <div className="flex-row row-no-padding row-center">
              <i className=" ion-podium " />
              <span className="flex-col">LEADERBOARD</span>
            </div>
          </div>
        </div>
        <LeadersHeader categories={categories} />
        <LeadersList
          myEntry={state.myEntry}
          players={state.players}
          buckets={state.poolBuckets}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
};

export default LeaderboardF1;
