import React from 'react'
import _ from 'underscore'
import $ from 'jquery'
import InfiniteScroll from 'react-infinite-scroller'

// Components
import Loading from '../Loading'
import PickTable from './PickTable'

// Context
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext'
import groupPoolActions from '../QuinielaGroupsUI/actions'

// Assets
import avatar from '../../assets/images/avatar-50.png'

window.jQuery.fn.synchroniseScroll = function (direction) {
  const elements = this
  let lastScrolling = null
  let lastScroll = performance.now()

  if (elements.length <= 1) return

  elements.scroll(function (e) {
    if (lastScrolling === null || (performance.now() - lastScroll >= 900)) {
      lastScrolling = e.currentTarget
    }
    if (lastScrolling !== e.currentTarget) {
      return
    }

    const left = $(this).scrollLeft()
    const top = $(this).scrollTop()

    elements.each(function (idx, el) {
      if (el === lastScrolling) { return }
      if (direction === 'horizontal' && $(el).scrollLeft() != left) {
        $(el).scrollLeft(left)
      }
      if (direction === 'vertical' && $(el).scrollTop() != top) {
        $(el).scrollTop(top)
      }
    })

    lastScroll = performance.now()
  })
}

const TitlesList = () => {
  let titleList = ['Grupos']
  titleList = titleList.map((listItem, index) => {
    return (
      <div key={index} className='upper-table-category group'>
        {listItem}
      </div>
    )
  })
  return <div> {titleList} </div>
}

const UserItem = ({ myEntry, withRegister, standing }) => {
  const ownUser = withRegister && myEntry.group_pool_entry_id === standing.group_pool_entry_id

  if ($(window).width() <= 769) {
    return (
      <ul key={standing.group_pool_entry_id} className='list-unstyled lower-list'>
        <li className=' flex-row row-no-padding responsive-sm lower-table-item-group user '>
          <div
            className={`flex-container column justify align item-container-group-pool
          ${ownUser ? 'ownUser' : ''} `}
          >
            <span className='picks-rank'> {standing.rank}°</span>
            <span className=' ' style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number !== null
                ? (
                  <sup
                    style={{
                      fontWeight: 'lighter',
                      fontSize: '75%',
                      margin: '0 2px'
                    }}
                  >
                    {standing.number}
                  </sup>
                  )
                : (
                    ''
                  )}
            </span>
          </div>
        </li>
      </ul>
    )
  }

  return (
    <ul key={standing.group_pool_entry_id} className='list-unstyled lower-list' style={{ width: '100%' }}>
      <li className=' flex-row row-no-padding responsive-sm lower-table-item-group user'>
        <div
          className={`flex-container start align item-container-group-pool  ${
            ownUser ? 'ownUser' : ''
            }`}
          style={{ padding: '0 5px' }}
        >
          <div className='image-container'>
            <img
              alt={standing.nick} className='avatarPicksGrupo'
              src={standing.avatar === '/img/profile-image-01.png' ? avatar : standing.avatar}
            />
          </div>
          <span style={{ margin: '5px' }} className='picks-rank'>
            {' '}
            {standing.rank}°
          </span>
          <span style={{ fontWeight: 'bold' }}>
            {standing.nick}
            {standing.number !== null
              ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px'
                  }}
                >
                  {standing.number}
                </sup>
                )
              : (
                  ''
                )}
          </span>
        </div>
      </li>
    </ul>
  )
}

/**
 * This component renders a list of the standings
 */
const UsersList = ({ standings, myEntry, withRegister }) => {
  if (standings.items.length < 1) {
    return null
  }

  const usersList = standings.items.map((standing) => {
    return (
      <UserItem
        myEntry={myEntry}
        standing={standing}
        withRegister={withRegister}
        key={standing.group_pool_entry_id}
      />
    )
  })

  return (
    <div className='overflow overflow-y overflow-picks-grupo userList group sync-vertical'>
      {usersList}
    </div>
  )
}

const MainHeader = ({ buckets, ties, poolInfo, currentContest }) => {
  return (
    <div className='row-list'>
      <div className='title-container'>
        <TitlesList />
      </div>
      <div className='picksData sync-horizontal' style={{ width: '66%' }}>
        <div className='data-row'>
          {buckets[currentContest.id].map((b) => {
            return (
              <div key={b.id} className='upper-table-item group '>
                {' '}
                Grupo #{b.id}{' '}
              </div>
            )
          })}
          {poolInfo.use_tiebreakers === true && (
            _.map(ties[currentContest.id], (tie, tieIdx) => {
              return (
                <div key={tieIdx} className='upper-table-item tie group'>
                  {' '}
                  D {tieIdx + 1}{' '}
                </div>
              )
            })
          ) || null}
        </div>
      </div>
      <div className='total-container flex-col col-center' style={{ width:'calc(14% - 4px)' }}>
        <div className='upper-table-item  group total'>Puntos</div>
      </div>
    </div>
  )
}

const PicksList = ({
  players,
  buckets,
  standings,
  ties,
  gameStatus,
  myEntry,
  myEntryId,
  currentContest,
  poolInfo
}) => {
  const userRowList = standings.items.map((userInfo) => {
    let isMyEntry = false
    let ownUser = false

    if (myEntry) {
      if (!userInfo) {
        isMyEntry = false
      } else {
        isMyEntry = userInfo.group_pool_entry_id === myEntry.group_pool_entry_id
      }
    } else {
      ownUser = userInfo.group_pool_entry_id === myEntryId
    }

    let selectedTies = []

    if (currentContest) {
      selectedTies = _.map(ties[currentContest.id], (tie) => {
        const currentTie =
          !userInfo.group_pool_user_tiebreakers || !userInfo.group_pool_user_tiebreakers[tie.id]
            ? null
            : userInfo.group_pool_user_tiebreakers[tie.id]
        if (!currentTie || currentTie.value === null) {
          // Not Found
          return { state: 'TIE_NOT_PICKED' }
        } else if (currentTie && gameStatus === 'upcoming' && !isMyEntry) {
          return { state: 'TIE_PICKED_SUCCESS' }
        } else {
          // Found currentTie
          return {
            state: 'TIE',
            ownUser,
            tie,
            tieValue: !currentTie.value && currentTie.value === '0' ? 'E' : currentTie.value
          }
        }
      })
    }

    const selectedPicks = buckets[currentContest.id].map((bucket, index) => {
      // Default player N/D when user don't picked one
      const worst = currentContest.par + 10
      const worstByRound = worst - currentContest.par
      const worstScoreNum = (worst * 4) - (currentContest.par * 4)

      const defaultValue = {
        notPicked: true,
        name: 'N/D',
        player_id: Math.random().toString(16),
        score_num: worstScoreNum,
        score: worstScoreNum,
        data: {
          hole: '',
          r1: worstByRound,
          r2: worstByRound,
          r3: worstByRound,
          r4: worstByRound
        },
        bucket: bucket.id
      }

      const currentPick = !userInfo.group_pool_picks || !userInfo.group_pool_picks[bucket.id]
        ? null
        : userInfo.group_pool_picks[bucket.id]

      // No current pick
      if (!currentPick) {
        if (gameStatus === 'upcoming') {
          return {
            index,
            state: 'NOT_PICKED',
            currentPlayer: defaultValue
          }
        }
        return {
          index,
          state: 'NOT_PICKED_START',
          currentPlayer: defaultValue,
          poolInfo,
          ownUser,
          currentContest
        }

      // Other users (is not my entry) have picked and the game is not start yet
      } else if (currentPick && gameStatus === 'upcoming' && !isMyEntry) {
        if (currentPick.pick) {
          return { index, state: 'PICKED_SUCCESS' }
        } else {
          return { index, state: 'NOT_PICKED' }
        }
      } else {
         // The game has been started
        const currentPlayer = players[currentPick.pick]

        if (currentPlayer) {
          return {
            state: 'PICKED_START',
            ownUser,
            poolInfo,
            currentPlayer,
            currentContest
          }
        } else {
          return {
            state: 'NOT_PICKED_START',
            ownUser,
            poolInfo,
            currentPlayer: defaultValue,
            currentContest
          }
        }
      }
    })

    const PICKS_COLUMNS = selectedPicks.map((item, index) => {
      switch (item.state) {
        case 'NOT_PICKED': return (
          <div className='item-container-group-pool' key={index}>
            <div className='flex-row img-container'>
              <div className='shirt-picks grupo withoutPick'>
                <i className='ion-close' style={{ color: 'rgb(244, 0, 52)' }} />
              </div>
            </div>
          </div>
        )

        case 'PICKED_SUCCESS': return (
          <div className='item-container-group-pool' key={index}>
            <div className=' flex-row img-container'>
              <div className='shirt-picks grupo withoutPick'>
                <i className='ion-checkmark-round' />
              </div>
            </div>
          </div>
        )

        case 'PICKED_START':
        case 'NOT_PICKED_START': {
          const cutOffIDList = getCutIDList({ selectedPicks, poolInfo })

          return (
            <PickTable
              key={index}
              index={item.index}
              ownUser={item.ownUser}
              poolInfo={item.poolInfo}
              cutOffIDList={cutOffIDList}
              currentPlayer={item.currentPlayer}
              currentContest={item.currentContest}
            />
          ) }

        default : return null
      }
    })

    const TIES_COLUMNS = selectedTies.map(item => {
      switch (item.state) {
        case 'TIE_NOT_PICKED': return (
          <div className='item-container-group-pool tie' key={item.index}>
            <div className=' flex-row roimg-container'>
              <div className='shirt-picks grupo withoutPick'>
                <i className='ion-close' style={{ color: 'rgb(244, 0, 52)' }} />
              </div>
            </div>
          </div>
        )

        case 'TIE_PICKED_SUCCESS': return (
          <div className='item-container-group-pool tie' key={item.index}>
            <div className=' flex-row img-container'>
              <div className='shirt-picks grupo withoutPick'>
                <i className='ion-checkmark-round' />
              </div>
            </div>
          </div>
        )

        case 'TIE': return (
          <div
            key={item.index}
            // style={{ width: 90 }}
            className={`item-container-group-pool tie flex-row row-no-padding  ${
              item.ownUser ? 'ownUser' : ''
              }`}
          >
            <div className='flex-container justify align item-tie-value '>
              {item.tieValue}
            </div>
          </div>
        )

        default : return null
      }
    })

    return (
      <li
        key={userInfo.group_pool_entry_id}
        className='flex-row row-no-padding lower-table-item-group user '
      >
        {PICKS_COLUMNS}
        {TIES_COLUMNS}
      </li>
    )
  })

  return (
    <ul className='list-unstyled flex-col col-no-padding lower-list' style={{ height: '100%', width: '100%' }}>
      {userRowList}
    </ul>
  )
}

const TotalesList = ({ standings, myEntry }) => {
  const totalesList = standings.items.map((standing) => (
    <li
      key={standing.group_pool_entry_id}
      className={`lower-table-item-group flex-col col-no-padding ${
        myEntry && myEntry.group_pool_entry_id === standing.group_pool_entry_id ? 'ownUser' : ''
      }`}
    >
      <div className='item-container-group-pool flex-row row-center'>
        <div className='shirt-picks'>
          <div> {standing.points}</div>
        </div>
      </div>
    </li>
  ))

  return <ul className='list-unstyled lower-list' style={{ width: '100%' }}>{totalesList}</ul>
}

const TablaPicksGrupo = ({
  myEntry,
  buckets,
  ties,
  players,
  groupPicks,
  submitting,
  loadMore,
  withRegister,
  queryParams,
  gameStatus,
  currentContest,
  poolInfo
}) => {
  return (
    <div className='lista-container flex-row row-no-padding'>
      <UsersList standings={groupPicks} withRegister={withRegister} myEntry={myEntry} />
      <div className='overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal' style={{ width: '66%' }}>
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={
            !(submitting || (groupPicks && groupPicks.pages === queryParams.page))
          }
          threshold={150}
          loader={<Loading key='labelLoader00' label='...' />}
          useWindow={false}
        >
          <PicksList
            ties={ties}
            myEntryId={myEntry.group_pool_entry_id}
            standings={groupPicks}
            gameStatus={gameStatus}
            players={players}
            buckets={buckets}
            withRegister={withRegister}
            currentContest={currentContest}
            poolInfo={poolInfo}
          />
        </InfiniteScroll>
      </div>
      <div className='overflow overflow-y overflow-picks-grupo totalesList sync-vertical'>
        <TotalesList myEntry={myEntry} standings={groupPicks} />
      </div>
    </div>
  )
}

const PicksGrupoPoolGroup = () => {
  const { state, dispatch } = React.useContext(QuinielaGroupsContext)

  const userRow = () => {
    const fakeStanding = { ...state.myEntry.group_pool_standing }

    fakeStanding.group_pool_picks = state.myEntry.group_pool_picks[state.currentContest.id]
    fakeStanding.group_pool_user_tiebreakers = state.myEntry.group_pool_user_tiebreakers[state.currentContest.id] // <=

    return (
      <div className='lista-container ownUser'>
        <div className='userList group' style={{ margin: '0', padding: '0' }}>
          <ul className='list-unstyled lower-list'>
            <li className=' flex-row  responsive-sm row-no-padding lower-table-item-group user '>
              <div className='flex-container justify align text-center item-container-group-pool'>
                Mis Picks
              </div>
            </li>
          </ul>
        </div>
        <div className='dataList sync-horizontal' style={{ width: '66%', overflow: 'hidden' }}>
          <PicksList
            queryParams={state.queryParamsGroupPicks}
            ties={state.tiebreakers}
            standings={{ items: [fakeStanding] }}
            gameStatus={state.poolInfo.status}
            players={state.players}
            buckets={state.poolBuckets}
            myEntry={state.myEntry}
            poolInfo={state.poolInfo}
            areMyPicks
            withRegister={state.withRegister}
            currentContest={state.currentContest}
          />
        </div>
        <div className='totalesList flex-container justify align ' style={{ paddingBottom: '0' }}>
          <div className='lower-table-item-group flex-container justify align'>
            <div className='item-container-group-pool flex-container align justify '>
              {(!state.myEntry.group_pool_standing || !state.myEntry.group_pool_standing.points)
                ? '—'
                : state.myEntry.group_pool_standing.points}{' '}
              pts
            </div>
          </div>
        </div>
      </div>
    )
  }

  const loadMore = () => {
    if (state.submittingGroupPicks) {
      return
    }

    groupPoolActions.siguientePagGroupPicks(dispatch).then((response) => {
      groupPoolActions
        .getMoreGroupPicks(dispatch, state.poolInfo.id, state.queryParamsGroupPicks)
        .then((response) => {
          groupPoolActions.submittingGroupPicks(dispatch)
        })
    })
  }

  const mounted = React.useRef()
  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      setTimeout(() => {
        window.jQuery('.sync-horizontal').synchroniseScroll('horizontal')
        window.jQuery('.sync-vertical').synchroniseScroll('vertical')
      }, 1200)
    } else {
    }
  })

  return (
    <div className='multiple-quiniela-info grupo flex-row'>
      <div className='upper-section'>
        <div className='container-picks'>
          <div className='upper-table'>
            <div className='upper-table-category  headers border-gradient font-chivo'>
              <MainHeader
                ties={state.tiebreakers}
                myEntry={state.myEntry}
                buckets={state.poolBuckets}
                players={state.players}
                poolInfo={state.poolInfo}
                currentContest={state.currentContest}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='lower-section' style={{ marginTop: '5px' }}>
        <div className='container-picks'>
          <div className='tabla-grupos'>
            <div
              className={` picks-message-overlay ${
                state.submittingGroupPicks ? 'show-message' : 'hide-message'
                } `}
            >
              <div className='flex-container justify align'>
                <strong>...</strong>
              </div>
            </div>
            <div className='lower-table border-gradient'>
              {state.withRegister ? userRow() : null}
              <TablaPicksGrupo
                submitting={state.submittingGroupPicks}
                poolId={state.poolInfo.id}
                loadMore={loadMore}
                dispatch={dispatch}
                gameStatus={state.poolInfo.status}
                queryParams={state.queryParamsGroupPicks}
                myEntry={state.myEntry}
                withRegister={state.withRegister}
                buckets={state.poolBuckets}
                ties={state.tiebreakers}
                players={state.players}
                groupPicks={state.groupPicks}
                currentContest={state.currentContest}
                poolInfo={state.poolInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Returns an object with player's IDs when is cut off
const getCutIDList = ({ selectedPicks, poolInfo }) => {
  // Picks for user row userInfo.group_pool_picks
  const sorted = [...selectedPicks].sort((a, b) => a.currentPlayer.score_num - b.currentPlayer.score_num)

  // Number how many worst users is cut off
  const pickCutoff = (poolInfo && poolInfo.pick_cutoff > 0) ? poolInfo.pick_cutoff : 0

  let cutOffIDList = {}

  if (poolInfo && pickCutoff && pickCutoff > 0) {
    const cutOffList = [...sorted.slice(-pickCutoff).filter(Boolean)]
    cutOffIDList = cutOffList.reduce((a, b) => ({ ...a, [b.currentPlayer.player_id]: b }), {})
  }

  return cutOffIDList
}

export default PicksGrupoPoolGroup
