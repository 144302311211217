import React from 'react';

import moment from 'moment';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap/lib';

import {
  DisplayFlag,
  DisplaySport,
  DisplayIconsType,
  DisplayPremioMisQuinielas,
  DisplayBolsaMisQuinielas,
  PanelConfirmation,
} from '../../utils/quinielasComponents';

import FilterQuinielaControls from '../FilterQuinielaControls';

import pickcoins from '../../assets/images/pickcoin1-50.png';
import pickcoinsPlata from '../../assets/images/pickcoin1-50-plata.png';
import pickLogo from '../../assets/images/checked.png';
import notPick from '../../assets/images/cancel.png';
import billete from '../../assets/images/cash1-50.png';
import freeRollIcon from '../../assets/images/cash1-50.png';

const CancelationButton = ({ entry, status, handleConfirmationCancel, entryId }) => {
  if (status !== 'upcoming' || (entry.private && !entry.id)) {
    return null;
  }
  return (
    <Button
      className="cancelButtonEntries"
      onClick={() => {
        handleConfirmationCancel(entryId);
      }}
    >
      <i className="ion-close-circled" style={{ margin: '0' }}></i>
    </Button>
  );
};

/**
 * This component renders the list of available entries of the user_referals
 * @param entries                   { Array }
 * @param gameType                  { String }
 * @param handleConfirmationCancel  { Function }
 * @param handleMisQuinielas        { Function }
 * @param isDashActive              { Boolean }
 * @param showHideConfirmation      { Boolean }
 * @param sportsList                { Array }
 * @param updatedEntries            { Array }
 * @param userData                  { Object }
 */
const ListaEntries = ({
  entries,
  gameType,
  handleConfirmationCancel,
  handleMisQuinielas,
  isDashActive,
  showHideConfirmation,
  sportsList,
  updatedEntries,
  userData,
}) => {
  const listaEntries = entries.map((entry, i) => {
    if (entry.hidden === 1) {
      return;
    }

    let currencyIcon = null;
    let currencyFormat = null;
    let currencyColor = {};
    let flag = null;
    let gameId = null;
    let gameUrl = '';

    switch (entry.entry_currency) {
      case 'real':
        currencyIcon = <img src={billete} className="pool-icons comision" />;
        currencyFormat = '$0';
        currencyColor = { color: '#78b529' };
        break;
      case 'pickcoin':
        currencyIcon = <img src={pickcoins} className="pool-icons comision" />;
        currencyFormat = '0';
        currencyColor = { color: '#f9b13b' };
        break;
      case 'referral':
        currencyIcon = <img src={pickcoinsPlata} className="pool-icons comision" />;
        currencyFormat = '0';
        currencyColor = { color: '##fff' };
        break;
      case 'freroll':
        currencyIcon = <img src={freeRollIcon} className="pool-icons comision" />;
        currencyFormat = '0';
        currencyColor = { color: '##fff' };
        break;
    }


    switch (entry.game_type) {
      case 'pool':
        gameId = entry.pool_id;
        break;
      case 'survivor':
        gameId = entry.survivor_id;
        break;
      case 'bracket':
        gameId = entry.bracket_id;
        break;
      case 'group_pool':
        gameId = entry.group_pool_id;
        break;
    }

    if ( entry.game_type === 'pool' ) {
      gameUrl = `/quiniela/quiniela/${gameId}`;
    }
    else if ( entry.game_type === 'survivor' ) {
      gameUrl = `/survivor/survivor/${gameId}`;
    }
    else if ( entry.game_type === 'group_pool' ) {
      gameUrl = `/quinielaGrupo/quiniela/${gameId}`;
    }
    else if ( entry.game_type === 'bracket' ) {
      console.log(entry)
      gameUrl = `/bracket/bracket/${gameId}`;
      // if ( entry.sport_id === 5 ) {
      //   gameUrl = `/bracketTennisLobby/bracket/${gameId}`;
      // }
      // // else if ( entry.type !== 'perfect' ) {
      // //   gameUrl = `/bracketPerfect/bracket/${gameId}`;
      // // }
      // // else if ( entry.type === 'points' && entry.sport_id !== 5 ) {
      // else {
      //   gameUrl = `/bracketPoints/bracket/${gameId}`;
      // }
    }

    if ( entry.id ) {
      gameUrl += `/${entry.id}`;
    }

    sportsList.forEach((sport) => {
      if (sport.id === entry.sport_id) {
        sport.tournaments.forEach((tournament) => {
          if (tournament.id === entry.tournament_ids) {
            // console.log(tournament, entry)
            flag = tournament.flag_class;
          }
        });
      }
    });

    let registerIsFull = false;
    if (
      (entry.game_type === 'pool' || entry.game_type === 'group_pool') &&
      entry.max_picks === null
    ) {
      registerIsFull = entry.pool_total_picks === entry.entry_total_picks;
    }
    if (entry.game_type === 'pool' && entry.max_picks !== null) {
      registerIsFull = entry.max_picks === entry.entry_total_picks;
    }

    let animateThis = false;

    updatedEntries.forEach((uEntry) => {
      if (entry.id === uEntry) {
        animateThis = true;
      }
    });

    // console.log('animateThis entry ', entry)
    // console.log(animateThis)
    return (
      <li
        data-id={entry.id}
        key={entry.id === null ? i + entry.user_id : entry.id}
        className={`entries-list-item ${isDashActive(entry.id)} ${
          animateThis ? 'updated-live' : ''
        } `}
        onClick={(e) => {
          handleMisQuinielas(e);
        }}
        style={
          entry.status === 'closed'
            ? { borderColor: '#d3242b' }
            : entry.status === 'upcoming'
            ? { borderColor: '#337ab7' }
            : { borderColor: '#87b13d' }
        }
      >
        <div className="wraper-control-entries col-xs-12">
          <div data-id={entry.id} className="entry-titulo">
            <DisplayFlag dataId={entry.id} type="misQuinas" flag={flag} />
            <DisplaySport sports={entry.sport_btn_class} dataId={entry.id} type={'quiniela'} />
            <DisplayIconsType
              id={entry.id}
              type={entry.game_type}
              isPick5={entry.max_picks !== null}
              variation={entry.variation}
            />
            <span data-id={entry.id}>
              {entry.name_replaced}
              {entry.number !== null ? (
                <small data-id={entry.id} style={{ margin: '0 3px' }}>
                  [{entry.number === 'null' ? 1 : entry.number}]
                </small>
              ) : null}
            </span>
          </div>
          <div data-id={entry.id} className="entries-info">
            <div data-id={entry.id} className=" flex-row row-no-padding entries-info-item">
              <span data-id={entry.id} className=" flex-col col-no-padding cierre">
                <span
                  data-id={entry.id}
                  style={
                    moment(entry.deadline).diff(moment()) < 0
                      ? entry.status === 'live'
                        ? { color: '#78BC28' }
                        : { color: '#E00034' }
                      : { color: '#fff' }
                  }
                >
                  {entry.status === 'live'
                    ? ' EN CURSO'
                    : entry.status == 'closed'
                    ? ' CERRADA'
                    : `CIERRE ${moment(entry.deadline).format('D MMM YY ')}`}
                </span>
              </span>
              {entry.game_type === 'survivor' ? (
                <span data-id={entry.id} className="flex-container justify align cierre">
                  <span data-id={entry.id}>VIVO: </span>
                  <img data-id={entry.id} src={entry.alive ? pickLogo : notPick} />
                </span>
              ) : entry.game_type === 'group_pool' || entry.game_type === 'pool' ? (
                <span data-id={entry.id} className="flex-container justify align  cierre">
                  <span data-id={entry.id}>PICKS: </span>
                  <span data-id={entry.id} className="position-entry">
                    {entry.entry_total_picks}/
                    {entry.max_picks != null ? entry.max_picks : entry.pool_total_picks}
                  </span>
                  <img data-id={entry.id} src={registerIsFull ? pickLogo : notPick} />
                </span>
              ) : null}
            </div>
            <div data-id={entry.id} className="entries-info-item">
              <div data-id={entry.id} className="entry-entry-type">
                <span data-id={entry.id}>
                  <span data-id={entry.id}>ENTRADA:</span>
                  {currencyIcon}
                  <span data-id={entry.id} style={currencyColor}>
                    {numeral(entry.entry_fee / 100).format(currencyFormat)}
                  </span>
                </span>
                {entry.game_type === 'survivor' && entry.alive ? (
                  <span data-id={entry.id} className="flex-container justify align cierre">
                    <span data-id={entry.id}>PICK: </span>
                    <img
                      data-id={entry.id}
                      src={entry.pick_count === entry.entry_total_picks ? pickLogo : notPick}
                    />
                  </span>
                ) : null}
                {entry.game_type === 'group_pool' ? (
                  <span data-id={entry.id} className="flex-container justify align  cierre">
                    <span data-id={entry.id}>PUNTOS: </span>
                    <span data-id={entry.id} className="position-entry">
                      {entry.points}
                    </span>
                  </span>
                ) : null}
              </div>
            </div>
            <div data-id={entry.id} className="entries-info-item">
              <DisplayBolsaMisQuinielas entry={entry} />
            </div>
            <div data-id={entry.id} className="entries-info-item">
              <DisplayPremioMisQuinielas entry={entry} />
              <span data-id={entry.id} className="posicion">
                <span data-id={entry.id} className="position-entry">
                  POS: {` ${entry.rank}/${entry.entry_count}`}
                </span>
              </span>
            </div>
            <div className="bottom-buttons">
              {entry.status === 'closed' && (
                <Button
                  className="btn-hide-game flex-col"
                  onClick={() => {
                    showHideConfirmation(entry.id);
                  }}
                >
                  <i className="ion-eye-disabled" />
                </Button>
              )}

              <Link
                className="btn-ver-quiniela flex-col"
                to={gameUrl}
              >
                <i data-id={gameId} className="ion-eye" />
              </Link>
              <CancelationButton
                entry={entry}
                status={entry.status}
                handleConfirmationCancel={handleConfirmationCancel}
                entryId={entry.id}
              />
              {entry.administrator_id === userData.id ? (
                entry.game_type === 'pool' ? (
                  <Link
                    data-id={gameId}
                    className="btn-editar-privada flex-col"
                    to={'/editar/quiniela/' + gameId}
                  >
                    <i data-id={gameId} className="ion-edit" />
                  </Link>
                ) : entry.gameType === 'bracket' ? (
                  <Link
                    data-id={gameId}
                    className="btn-editar-privada flex-col"
                    to={'/editar/bracket/' + gameId}
                  >
                    <i data-id={gameId} className="ion-edit" />
                  </Link>
                ) : (
                  <Link
                    data-id={gameId}
                    className="btn-editar-privada flex-col"
                    to={'/editar/survivor/' + gameId}
                  >
                    <i data-id={gameId} className="ion-edit" />
                  </Link>
                )
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <ul className="lista-entradas">
      {entries.length > 0 ? listaEntries : <span> No tienes ninguna entrada. </span>}
    </ul>
  );
};

let filterButtons = [
  {
    id: 0,
    name: 'moneda-all',
    category: 'moneda',
    sortOder: 1,
    active: true,
    //Resetear Todos los botones a false
  },
  {
    id: 1,
    name: 'moneda-real',
    category: 'moneda',
    sortOder: 1,
    active: false,
  },
  {
    id: 2,
    name: 'moneda-pickcoins',
    category: 'moneda',
    sortOder: 1,
    active: false,
  },
  {
    id: 3,
    name: 'monto-alto',
    category: 'monto',
    sortOder: 1,
    active: false,
  },
  {
    id: 4,
    name: 'monto-medio',
    category: 'monto',
    sortOder: 1,
    active: false,
  },
  {
    id: 5,
    name: 'monto-bajo',
    category: 'monto',
    sortOder: 1,
    active: false,
  },
  {
    id: 6,
    name: 'type-abierta',
    category: 'type',
    sortOder: 1,
    active: false,
  },
  {
    id: 7,
    name: 'type-cupo',
    category: 'type',
    sortOder: 1,
    active: false,
  },
  {
    id: 8,
    name: 'deporte-all',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 9,
    name: 'deporte-baseball',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 10,
    name: 'deporte-basketball',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 11,
    name: 'deporte-futbol',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 12,
    name: 'deporte-americano',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 13,
    name: 'deporte-tenis',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 14,
    name: 'deporte-golf',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 15,
    name: 'deporte-F1',
    category: 'deporte',
    sortOder: 1,
    active: false,
  },
  {
    id: 16,
    name: 'estado-all',
    category: 'estado',
    sortOder: 1,
    active: false,
  },
  {
    id: 17,
    name: 'estado-live',
    category: 'estado',
    sortOder: 1,
    active: false,
  },
  {
    id: 18,
    name: 'estado-upcoming',
    category: 'estado',
    sortOder: 1,
    active: false,
  },
  {
    id: 19,
    name: 'estado-closed',
    category: 'estado',
    sortOder: 1,
    active: false,
  },
];

class EntriesControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: filterButtons,
      showConfirmation: false,
      hideThis: null,
      errorHidingEntry: false,
      submitting: false,
    };
    this.showHideConfirmation = (id) => {
      console.log('ShowConfirmation');
      this.setState({ showConfirmation: true, hideThis: id });
    };
    this.hideConfirmation = () => {
      this.setState({
        showConfirmation: false,
        hideThis: null,
        errorHidingEntry: false,
      });
    };
    this.sendHideConfirmation = (id) => {
      console.log('SendHide');
      this.setState({ submitting: true });
      let promise = null;
      let gameType = this.props.filteredEntries.find((entry) => {
        if (entry.id === this.state.hideThis) {
          return entry.game_type;
        }
      });
      console.log('GameType', gameType);
      console.log('using the Delete method ');
      promise = new Promise((resolve, reject) => {
        this.props
          .deleteThisEntry(this.state.hideThis, gameType.game_type)
          .then((response) => {
            console.log(response);
            if (response) {
              this.setState({
                submitting: false,
              });
              resolve(true);
            } else {
              reject(false);
              this.setState({
                submitting: false,
                errorHidingEntry: true,
              });
            }
          })
          .catch((error) => {
            reject(false);
            this.setState({
              submitting: false,
              errorHidingEntry: true,
            });
          });
      });
      return promise;
    };
  }

  render() {
    // console.log('Render EntriesControls');
    //let listaEntries;
    //let listaMyPools;
    let conditionalComponent = null;
    if (this.props.mounted) {
      if (this.props.filteredEntries !== undefined && this.props.filteredEntries !== null) {
        conditionalComponent = (
          <div
            className={`controls-wrapper col-xs-7 col-sm-4 col-md-3 col-lg-3 col-hd-2 active
                    ${this.props.collapseOpen ? 'closed ' : null}`}
          >
            <div className={`control-registros ${this.props.collapseOpen ? 'closed  ' : null}`}>
              <FilterQuinielaControls
                activeDashboard={this.props.activeDashboard}
                activeFiltersProps={this.state.activeFilters}
                changeSortOrder={this.props.changeSortOrder}
                defaultFilters={this.props.defaultFilters}
                filterBarStatus={this.props.filtersBarAccumulative}
                pools={this.props.filteredEntries}
                resetDefaultFilters={this.props.resetDefaultFilters}
                sortOrder={this.props.sortOrder}
              />
              <PanelConfirmation
                submitting={this.state.submitting}
                showConfirmation={this.state.showConfirmation}
                hideConfirmation={this.hideConfirmation}
                title={'¿Seguro que quieres esconder esta quiniela?'}
                label={
                  'Esta quiniela ya no aparecerá en tu lista de quinielas.\n ¿Quieres continuar?'
                }
                errorStatus={this.state.errorHidingEntry}
                errorLabel={'No se encontro la entrada. ¿Quieres intentarlo de nuevo?'}
                errorTitle={'Error'}
                afterConfirmation={this.sendHideConfirmation}
                id={this.state.hideThis}
              />
              <div className="entries-registradas">
                <ul className="lista-entradas">
                  <ListaEntries
                    updatedEntries={this.props.updatedEntries}
                    entries={this.props.filteredEntries}
                    isDashActive={this.props.isDashActive}
                    sportsList={this.props.sports}
                    handleMisQuinielas={this.props.handleMisQuinielas}
                    showHideConfirmation={this.showHideConfirmation}
                    userData={this.props.userData}
                    handleConfirmationCancel={this.props.handleConfirmationCancel}
                    hideConfirmationCancel={this.props.hideConfirmationCancel}
                  />
                </ul>
              </div>
            </div>
          </div>
        );
      } else {
        conditionalComponent = null;
      }
    } else {
      conditionalComponent = <span>Cargando...</span>;
    }
    return conditionalComponent;
  }
}

export default EntriesControls;
