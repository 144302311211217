import React, { useEffect } from 'react'
import gaUtils from '../GAWrapper'
import { Redirect } from 'react-router-dom'
import useAuthContext from '../../hooks/useAuthContext'

export default function Login() {
  const { isAuthenticated, isInitialized, openLoginModal } = useAuthContext()

  useEffect(() => {
     const fakeMatch = {
       isExact: true,
       params: {
         slug: 'Ingresar'
       }
     }
     gaUtils.handleDocumentAttributes(fakeMatch)
  }, [])

  useEffect(() => {
    if (!isAuthenticated && isInitialized) {
      openLoginModal()
    }
  }, [isAuthenticated, isInitialized])

  if (!isInitialized) return null

  return <Redirect to='/' />
}

