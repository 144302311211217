
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useWizardPrivate } from '../wizardPrivate.store'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'

// Components
import { Box, Stack, Typography, Grid, Iconify, ButtonGlass } from '../../../Xmerald-UI'

// Utils
import useTournamentWeeks from './useTournamenWeeks'

export default function SelectTournamentWeek () {
  const activeTournamentID = useWizardPrivate(state => state.activeTournamentID)
  const survivorData = useWizardPrivate(state => state.survivorData)
  const setSurvivorData = useWizardPrivate(state => state.setSurvivorData)

  const { data, isLoading, isFetching } = useTournamentWeeks(activeTournamentID)

  return (
    <Box>
      <Typography center size='1.1em' weight='bold' mt={30} mb={10}>
        Selecciona la jornada inicial de tú survivor {isFetching && <Iconify icon='eos-icons:three-dots-loading' color='#70f324' />}
      </Typography>
      {isLoading && (
        <Grid columns='repeat(3, 1fr)' gap='0.5em'>
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </Grid>
      )}

      {!isLoading && data?.weeks && (
        <Stack columns='repeat(3, 1fr)' gap='0.5em' direction='row' wrap justify='center'>
          {data.weeks.map(week => (
            <ButtonGlass
              key={week.id}
              active={week.id === survivorData.starting_week}
              data={week.id}
              height={65}
              size='0.8em'
              onChange={(starting_week) => setSurvivorData({ starting_week })}
            >
              {week.id === survivorData.starting_week && (
                <Select>
                  <Iconify icon='vaadin:dot-circle' color='#78bc28' size='1.2em' />
                </Select>
              )}
              <Stack direction='column'>
                <Typography center size='1.3em' color='#8cc63f' mt={5}>{week.week_name} {week.abbreviation}</Typography>
                <Typography center size='0.8em'>{moment(week.start_date).format('MM/DD/YY hh:mm')}</Typography>
              </Stack>
            </ButtonGlass>
          ))}
        </Stack>
      )}
    </Box>
  )
}

const Select = styled.div`
  position: absolute;
  top: 0em;
  right: 0.5em;
`
