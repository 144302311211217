import React from 'react';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import InfiniteScroll from 'react-infinite-scroller';
import avatar from '../../assets/images/avatar-50.png';
import groupPoolActions from '../QuinielaGroupsUI/actions';



import Loading from '../Loading';
import $ from 'jquery';
import _ from 'underscore';
import { ConsoleView } from 'react-device-detect';

window.jQuery.fn.synchroniseScroll = function(direction) {
  console.log('initscroll', direction);
  var elements      = this;
  var lastScrolling = null;
  var lastScroll    = performance.now();

  if (elements.length <= 1) {
    console.log('no elements');
    return;
  }


  elements.scroll(function(e) {
    if ( lastScrolling === null || (performance.now() - lastScroll >= 900) ) {
      lastScrolling = e.currentTarget;
    }
    if ( lastScrolling !== e.currentTarget ) {
      return;
    }

    var left = $(this).scrollLeft();
    var top  = $(this).scrollTop();

    elements.each(function(idx, el) {
      if ( el === lastScrolling ) { return; }
      if (direction === 'horizontal' && $(el).scrollLeft() != left) {
        $(el).scrollLeft(left);
      }
      if (direction === 'vertical' && $(el).scrollTop() != top ) {
        $(el).scrollTop(top);
      }
    });

    lastScroll = performance.now();

  });


};

const TitlesList = () => {
  let titleList = ['Posiciones'];
  titleList = titleList.map((listItem, index) => {
    return (
      <div key={index} className="upper-table-category group">
        {listItem}
      </div>
    );
  });
  return <div> {titleList} </div>;
};

const UserItem = ({ myEntry, withRegister, standing }) => {
  let conditionalComponent = null;

  // console.log( myEntry , withRegister, standing  );

  let ownUser = withRegister && myEntry.group_pool_entry_id === standing.group_pool_entry_id;
  if ($(window).width() <= 769) {
    conditionalComponent = (
      <ul key={standing.group_pool_entry_id} className="list-unstyled lower-list">
        <li className={` flex-row row-no-padding responsive-sm lower-table-item-group user `}>
          <div
            className={`flex-container column justify align item-container-group-pool
          ${ownUser ? 'ownUser' : ''} `}
          >
            <span className="picks-rank"> {standing.rank}°</span>
            <span className=" " style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number !== null ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px',
                  }}
                >
                  {standing.number}
                </sup>
              ) : (
                  ''
                )}
            </span>
          </div>
        </li>
      </ul>
    );
  } else {
    conditionalComponent = (
      <ul key={standing.group_pool_entry_id} className="list-unstyled lower-list">
        <li className={` flex-row row-no-padding responsive-sm lower-table-item-group user `}>
          <div
            className={`flex-container start align item-container-group-pool  ${
              ownUser ? 'ownUser' : ''
              }`}
            style={{ padding: '0 5px' }}
          >
            <div className="image-container">
              <img alt={standing.nick} className="avatarPicksGrupo"
                src={standing.avatar === '/img/profile-image-01.png' ? avatar : standing.avatar} />
            </div>
            <span style={{ margin: '5px' }} className="picks-rank">
              {' '}
              {standing.rank}°
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number !== null ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px',
                  }}
                >
                  {standing.number}
                </sup>
              ) : (
                  ''
                )}
            </span>
          </div>
        </li>
      </ul>
    );
  }

  return conditionalComponent;
};

/**
 * This component renders a list of the standings
 */
const UsersList = ({ standings, myEntry, withRegister }) => {
  if (standings.items.length < 1) {
    return null;
  }
  // console.log('standings',standings);
  let usersList = standings.items.map((standing, index) => {
    // console.log( standing );
    return (
      <UserItem
        //key={standing.group_pool_entry_id}
        key={index}
        standing={standing}
        myEntry={myEntry}
        withRegister={withRegister}
      />
    );
  });
  return (
    <div className="overflow overflow-y overflow-picks-grupo userList group sync-vertical">
      {usersList}
    </div>
  );
};

const MainHeader = ({ buckets, ties, myEntry, players, poolInfo, currentContest }) => {
  return (
    <div className="row-list">
      <div className="title-container">
        <TitlesList />
      </div>
      <div className="picksData sync-horizontal">
        <div className="data-row">
          {buckets.map((b) => {
            return (
              <div key={b.id} className="upper-table-item group ">
                {' '}
                P{b.id}{' '}
              </div>
            );
          })}
          {poolInfo.use_tiebreakers === true && (
            _.map(ties[currentContest.id], (tie, tieIdx) => {
              return (
                <div key={tieIdx} className="upper-table-item tie  group ">
                  {'   '}
                  D {tieIdx + 1}
                  {'   '}
                </div>
              );
            })
          ) || null}
        </div>
      </div>
      <div className="total-container flex-col col-center text-center" style={{padding: '0px', height: '40px'}}>
        <div className="flex-row text-center" style={{minHeight: '100%', padding: '0px'}}>
          {poolInfo.type === 'full' && (
            <>
              <div className="col-50 flex-container justify align">
                PTS
              </div>
              <div style={{borderRight: '1px solid', width: '1px'}}></div>
              <div className="col-50 flex-container justify align">
                PTS
              </div>
            </>
          ) || (
            <div className="flex-container justify align">
              PTS
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PicksList = ({
  players,
  buckets,
  standings,
  ties,
  gameStatus,
  myEntry,
  myEntryId,
  withRegister,
  currentContest,
  pointsDistribution,
}) => {
  let userRow = standings.items.map((userInfo, index) => {
    let isMyEntry = false;

    let ownUser = false;

    if (myEntry) {
      if (!userInfo) {
        isMyEntry = false;
      } else {
        isMyEntry = userInfo.group_pool_entry_id === myEntry.group_pool_entry_id;
      }
    } else {
      ownUser = userInfo.group_pool_entry_id === myEntryId;
    }

    let selectedTies;
    let selectedPicks
    if (currentContest) {
      selectedTies = _.map(ties[currentContest.id], (tie, index) => {
        let currentTie =
          !userInfo.group_pool_user_tiebreakers || !userInfo.group_pool_user_tiebreakers[tie.id]
            ? null
            : userInfo.group_pool_user_tiebreakers[tie.id];

        if (userInfo.group_pool_user_tiebreakers && userInfo.group_pool_user_tiebreakers[currentContest.id]) {
          currentTie = userInfo.group_pool_user_tiebreakers[currentContest.id][tie.id]
            ? userInfo.group_pool_user_tiebreakers[currentContest.id][tie.id]
            : null;
        }


        if (currentTie === null) {
          return (
            <div key={index} className="item-container-group-pool tie ">
              <div className=" flex-row roimg-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                </div>
              </div>
            </div>
          );
        } else if (currentTie && gameStatus !== 'upcoming' && !isMyEntry  && currentTie.en === 0 && currentTie.value === 1) {
          return (
            <div key={tie.id} className="item-container-group-pool tie">
              <div className=" flex-row img-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-checkmark-round success"></i>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={tie.id}
              className={`item-container-group-pool tie flex-row row-no-padding  ${
                ownUser ? 'ownUser' : ''
                }`}
            >
              <div className="flex-container justify align item-tie-value ">
                { players[currentTie.value] && players[currentTie.value].name}
                {currentTie.en === 0 && currentTie.value === 1 && <i className="ion-checkmark success" style={{fontSize: '12px'}}></i>}
                {currentTie.en === 0 && currentTie.value === 0 && <i className="ion-close" style={{fontSize: '12px'}}></i>}
              </div>
            </div>
          );
        }
      });

      selectedPicks = buckets.map((bucket) => {
        let colorStyle = {};

        let currentPick = !userInfo.group_pool_picks || !userInfo.group_pool_picks[bucket.id]
          ? null
          : userInfo.group_pool_picks[bucket.id];

        if (userInfo.group_pool_picks && userInfo.group_pool_picks[currentContest.id]) {
          currentPick = userInfo.group_pool_picks[currentContest.id][bucket.id]
            ? userInfo.group_pool_picks[currentContest.id][bucket.id]
            : null;
        }

        if (!currentPick) {
          return (
            <div key={bucket.id} className="item-container-group-pool">
              <div className=" flex-row img-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                </div>
              </div>
            </div>
          );
        } else if (currentPick && gameStatus !== 'upcoming' && !isMyEntry && currentPick.en === 0 && currentPick.pick === 1) {
          return (
            <div key={bucket.id} className="item-container-group-pool">
              <div className=" flex-row img-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-checkmark-round success"></i>
                </div>
              </div>
            </div>
          );
        } else {
          let currentPlayer = null;
          currentPlayer = players[currentPick.pick];

          if (currentPlayer) {
            // GET DIF
            var diff = Math.abs( bucket.id - currentPlayer.finish_pos );
            var pts = 0;
            if ( pointsDistribution && pointsDistribution.hasOwnProperty(diff) && currentPlayer.finish_pos <= 10 ) {
              pts = pointsDistribution[diff];
            }

            return (
              <div
                key={bucket.id}
                className={`item-container-group-pool ${ownUser ? 'ownUser' : ''}`}
              >
                <div className="flex-row group-pool-pick row-center">
                  <i className={currentPlayer.logo} style={{ margin: '0 3px' }} />
                  <div className="small uppercase text-center col-70 flex-col overflow-words">
                    {currentPlayer.name}{' '}
                  </div>
                </div>
                <div className="flex-row group-pool-pick-stats row-no-padding row-center">
                  <div className="flex-col col-33 col-no-padding  text-center ">
                    <div className="flex-container justify align small">
                      {currentPlayer.finish_pos}{'° '}
                    </div>
                  </div>
                  <div className="flex-col col-33 col-no-padding  text-center ">
                    <div className=" flex-container justify align small " style={colorStyle}>
                      {diff}
                    </div>
                  </div>
                  <div className="flex-col col-33 col-no-padding  text-center">
                    <div className="flex-container justify align">{pts}pts</div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={bucket.id} className="item-container-group-pool ">
                <div className=" flex-row roimg-container">
                  <div className={`shirt-picks grupo withoutPick`}>
                    {currentPick.en === 0 && currentPick.pick == 0 && <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>}
                    {currentPick.en === 0 && currentPick.pick == 1 && <i className="ion-checkmark-round success"></i>}
                  </div>
                </div>
              </div>
            );
          }
        }
      });
    }

    return (
      <li
        //key={userInfo.group_pool_entry_id}
        key={index}
        className={`flex-row row-no-padding lower-table-item-group user `}
      >
        {selectedPicks}
        {selectedTies}
      </li>
    );
  });

  return <ul className="list-unstyled flex-col col-no-padding lower-list">{userRow}</ul>;
};

const TotalesList = ({ standings, myEntry, poolInfo }) => {
  let totalesList = standings.items.map((standing, index) => {
    return (
      <li
        //key={standing.group_pool_entry_id}
        key={index}
        className={`lower-table-item-group flex-col col-no-padding ${
          myEntry && myEntry.group_pool_entry_id === standing.group_pool_entry_id ? 'ownUser' : ''
          }`}
      >
        <div className="item-container-group-pool flex-row row-center" style={{height: '70px'}}>
          <div className="flex-row text-center" style={{minHeight: '100%', padding: '0px'}}>
            {poolInfo.type === 'full' && (
              <>
                {/* Contest points */}
                <div className="col-50 flex-container justify align">
                  {standing.contest_points}
                </div>
                <div style={{borderRight: '1px solid', width: '1px'}}></div>
                {/* General points */}
                <div className="col-50 flex-container justify align">
                  {standing.points}
                </div>
              </>
            ) || (
              <div className="flex-container justify align">
                {standing.points}
              </div>
            )}
          </div>
        </div>
      </li>
    );
  });
  return <ul className="list-unstyled lower-list" style={{width: '100%'}}>{totalesList}</ul>;
};

const TablaPicksGrupo = ({
  myEntry,
  dispatch,
  poolId,
  buckets,
  ties,
  players,
  groupPicks,
  submitting,
  loadMore,
  withRegister,
  queryParams,
  gameStatus,
  currentContest,
  pointsDistribution,
  poolInfo
}) => {
  return (
    <div className="lista-container flex-row row-no-padding">
      <UsersList standings={groupPicks} withRegister={withRegister} myEntry={myEntry} />
      <div className="overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal">
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={
            submitting || (groupPicks && groupPicks.pages === queryParams.page) ? false : true
          }
          threshold={150}
          loader={<Loading key={'labelLoader00'} label="..." />}
          useWindow={false}
        >
          <PicksList
            ties={ties}
            myEntryId={myEntry.group_pool_entry_id}
            standings={groupPicks}
            gameStatus={gameStatus}
            players={players}
            buckets={buckets}
            withRegister={withRegister}
            currentContest={currentContest}
            pointsDistribution={pointsDistribution}
          />
        </InfiniteScroll>
      </div>
      <div className="overflow overflow-y overflow-picks-grupo totalesList sync-vertical">
        <TotalesList myEntry={myEntry} standings={groupPicks} poolInfo={poolInfo} />
      </div>
    </div>
  );
};

const PicksGrupoPoolGroupF1 = () => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);
  //console.log("PGSTATE:", state);

  const userRow = () => {
    let fakeStanding = { ...state.myEntry.group_pool_standing };
    fakeStanding['group_pool_picks'] = state.myEntry.group_pool_picks;
    fakeStanding['group_pool_user_tiebreakers'] = state.myEntry.group_pool_user_tiebreakers;

    var userContestPoints = 0;
    if (state.poolInfo.type === 'full' && state.myEntry && state.myEntry.contest_standings && state.myEntry.contest_standings[state.currentContest.id]) {
      userContestPoints = state.myEntry.contest_standings[state.currentContest.id].points;
    }

    return (
      <div className="lista-container ownUser">
        <div className="userList group" style={{ margin: '0', padding: '0' }}>
          <ul className="list-unstyled lower-list">
            <li className={` flex-row  responsive-sm row-no-padding lower-table-item-group user `}>
              <div className="flex-container justify align text-center item-container-group-pool">
                Mis Picks
              </div>
            </li>
          </ul>
        </div>
        <div className="dataList sync-horizontal " style={{ overflow: 'hidden' }}>
          <PicksList
            queryParams={state.queryParamsGroupPicks}
            ties={state.tiebreakers}
            standings={{ items: [fakeStanding] }}
            gameStatus={state.poolInfo.status}
            players={state.players}
            buckets={state.poolBuckets[state.currentContest.id]}
            myEntry={state.myEntry}
            withRegister={state.withRegister}
            currentContest={state.currentContest}
          />
        </div>
        <div className="totalesList flex-container justify align " style={{ paddingBottom: '0' }}>
          <div className="lower-table-item-group flex-container justify align">
            <div className="item-container-group-pool flex-container align justify " style={{height: '100%'}}>
              <div className="flex-row text-center" style={{minHeight: '100%', padding: '0px'}}>
                {state.poolInfo.type === 'full' && (
                  <>
                    <div className="col-50 flex-container justify align" style={{marginRight: '0px'}}>
                      {userContestPoints}
                    </div>
                    <div style={{borderRight: '1px solid', width: '1px'}}></div>
                    <div className="col-50 flex-container justify align">
                      {state.myEntry.group_pool_standing && state.myEntry.group_pool_standing.points}
                    </div>
                  </>
                ) || (
                  <div className="flex-container justify align">
                    {state.myEntry.group_pool_standing && state.myEntry.group_pool_standing.points}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadMore = () => {
    console.log('more...', state.submittingGroupPicks);
    if (state.submittingGroupPicks) {
      return;
    }

    groupPoolActions.siguientePagGroupPicks(dispatch).then((response) => {
      console.log(response, state.queryParamsGroupPicks);
      groupPoolActions
        .getMoreGroupPicks(dispatch, state.poolInfo.id, state.queryParamsGroupPicks, state.currentContest.id)
        .then((response) => {
          groupPoolActions.submittingGroupPicks(dispatch);
        });
    });
  };

  const mounted = React.useRef();
  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      setTimeout(() => {
        // console.log('Sync');// do componentDidUpate logic
        window.jQuery('.sync-horizontal').synchroniseScroll('horizontal');
        window.jQuery('.sync-vertical').synchroniseScroll('vertical');
      }, 1200);
    } else {
    }
  });

  return (
    <div className="multiple-quiniela-info grupo flex-row">
      <div className="upper-section">
        <div className="container-picks">
          <div className="upper-table">
            <div className="upper-table-category  headers border-gradient font-chivo">
              <MainHeader
                ties={state.tiebreakers}
                myEntry={state.myEntry}
                buckets={state.poolBuckets[state.currentContest.id]}
                players={state.players}
                poolInfo={state.poolInfo}
                currentContest={state.currentContest}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lower-section" style={{marginTop: '5px'}}>
        <div className="container-picks">
          <div className="tabla-grupos">
            <div
              className={` picks-message-overlay ${
                state.submittingGroupPicks ? 'show-message' : 'hide-message'
                } `}
            >
              <div className="flex-container justify align">
                <strong>...</strong>
              </div>
            </div>
            <div className="lower-table border-gradient">
              {state.withRegister ? userRow() : null}
              <TablaPicksGrupo
                submitting={state.submittingGroupPicks}
                poolId={state.poolInfo.id}
                loadMore={loadMore}
                dispatch={dispatch}
                gameStatus={state.poolInfo.status}
                queryParams={state.queryParamsGroupPicks}
                myEntry={state.myEntry}
                withRegister={state.withRegister}
                buckets={state.poolBuckets[state.currentContest.id]}
                ties={state.tiebreakers}
                players={state.players}
                groupPicks={state.groupPicks}
                dispatch={dispatch}
                currentContest={state.currentContest}
                pointsDistribution={state.poolInfo.points_distributions}
                poolInfo={state.poolInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PicksGrupoPoolGroupF1;
