import React from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Button, ButtonGroup, FormControl } from 'react-bootstrap/lib/';
import API from '../../utils/api';

class RecuperarPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      email: undefined,
      success: null,
      token: null,
      response: null,
    };
    console.log(this);
    this.sendCredentials = this.sendCredentials.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
  }

  sendCredentials(event) {
    // console.log('sending-Credential');
    //let that = this;
    console.log('%c sending credentials', 'background:rgb(42, 205, 217)');
    //let url = '/api/users/recover.json';
    // credentials are correct?
    // if they are correct send to the lobby
    // if they arent send an alert or message
    API.postRecoverPass(this.state.email).then((response) => {
      // console.log('Logged In successfull');
      if (response.data.success) {
        this.setState(() => {
          return {
            response: response,
            success: response.data.success,
          };
        });
      } else {
        this.setState(() => {
          return {
            response: response,
            success: response.data.success,
          };
        });
      }
      console.log(this.state);
      //this.props.updateUserDataFunc(that.state.userData);
    });
  }

  handleEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  render() {
    let conditionalComponent;

    // let loggedIn = false;
    // if (this.state.userData === null) {
    //   loggedIn = false;
    // } else {
    //   loggedIn = true;
    // }

    if (this.state.success === null) {
      conditionalComponent = (
        <Form horizontal onSubmit={this.sendCredentials}>
          <span style={{ textAlign: 'center' }}>
            Se enviarán instrucciones para cambiar tu contraseña al email con el que te registraste.
          </span>
          <div className="field-form">
            <FormGroup>
              <FormControl onChange={this.handleEmail} placeholder="Email" />
            </FormGroup>
          </div>
          <Button
            onClick={this.sendCredentials}
            className="login-btn"
            bsStyle="primary"
            bsSize="large"
            block
          >
            Recuperar Contraseña
          </Button>
        </Form>
      );
    } else {
      if (this.state.success) {
        conditionalComponent = (
          <div style={{ margin: '15px' }}>
            <span
              style={{
                color: '#fff',
                fontSize: '16px',
                fontWeight: 'bold',
                height: '100px',
                margin: 'auto',
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
                alignText: 'center',
                flexDirection: 'column',
              }}
            >
              {`Ahora revisa tu correo para restablecer tu contraseña.`}
              <i
                className="ion-checkmark-circled"
                style={{ color: '#78BC28', fontSize: '52px', textAlign: 'center' }}
              ></i>
            </span>
            <div className="btn-wrapper">
              <Link to={'/'}>
                <Button className="login-btn" bsStyle="primary" bsSize="large" block>
                  Regresar al Lobby Principal
                </Button>
              </Link>
            </div>
          </div>
        );
      } else {
        conditionalComponent = (
          <Form horizontal>
            <span style={{ textAlign: 'center' }}>
              Se enviarán instrucciones para cambiar tu contraseña al email con el que te
              registraste.
            </span>
            <div className="field-form">
              <FormGroup>
                <FormControl onChange={this.handleEmail} placeholder="Email" />
              </FormGroup>
            </div>
            <span className={'error-formulario'}>
              {' '}
              El email que ingresaste no existe, por favor ingrésalo nuevamente.
            </span>
            <Button
              onClick={this.sendCredentials}
              className="login-btn"
              bsStyle="primary"
              bsSize="large"
              block
            >
              Recuperar Contraseña
            </Button>
          </Form>
        );
      }
    }

    return (
      <div id="loginPanel">
        <div id="panelTitle" className="flex-row  row-center row-no-padding ">
          <span>RECUPERAR PASSWORD</span>
        </div>
        <div className="login-fields">{conditionalComponent}</div>
      </div>
    );
  }
}
export default RecuperarPanel;
