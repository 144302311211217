import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

Checkbox.propTypes = {
  label: propTypes.string.isRequired
}

export default function Checkbox ({ label, value, ...props }) {
  const id = typeof label === 'string'
    ? label.toLowerCase().replace(/\s/g, '-')
    : ''

  const handleChange = (event) => {
    event.stopPropagation()
    if (event.target && typeof props.onChange === 'function') {
      props.onChange(props.name, event.target.checked)
    }
  }

  return (
    <Field {...props}>
      <Input
        id={id}
        checked={value}
        type='checkbox'
        onChange={handleChange}
      />
      <label htmlFor={id}>{label}</label>
    </Field>
  )
}

const Input = styled.input`
  // Take it out of document flow
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
  }

  // Box
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 17px;
    height: 17px;
    background: transparent;
    border: 2px solid #78bc28;
    border-radius: 0.3em;
  }

  // Box hover
  &:hover + label:before {
    transition: background 100ms;
    background: #3a3a3a;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px #88ba4429;
  }

  // Box checked
  &:checked + label:before {
    background: #78bc28;
  }
  
  // Disabled state label
  &:disabled + label {
    color: #78bc28;
    cursor: auto;
  }

  // Disabled box
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
`

const Field = styled.div`
  position: relative;
  user-select: none;
`
