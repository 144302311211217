import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

Particles.defaultProps = {
  // Number of particles generated
  count: 60,
  // Start delay in ms
  delay: 0
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Particles ({ count, show, delay }) {
  const [particles, setParticles] = useState([])

  useEffect(() => {
    // Build initial random particles
    const initialParticles = Array(count)
      .fill(null)
      .map((_, index) => ({
        index,
        x: Math.random() * 120 * (Math.random() > 0.5 ? -1 : 1),
        y: Math.random() * 120 * (Math.random() > 0.5 ? -1 : 1),
        angle: getRandomInt(-90, 90), 
        delay: Math.random() * 900,
        opacityStart: 0,
        opacityEnd: Math.random(),
        scaleStart: 0.2,
        scaleEnd: Math.random()
      }))

    window.setTimeout(() => {
      setParticles(initialParticles)

      const animatiionDuration = 2500
      const maxDelay = Math.max(...initialParticles.map(({ delay }) => delay))
      const timeoutToUnmount = maxDelay + animatiionDuration

      setTimeout(() => {
        // Cleaning particles in DOM
        setParticles([])
      }, timeoutToUnmount)
    
    }, delay)
  }, [delay])

  if (!show) return null

  return (
    <>
      {particles.map(particle => (
        <ParticleComponent
          data={particle}
          key={particle.index}
        />
      ))}
    </>
  )
}

const ParticleComponent = ({ data }) => {
  const [transform, setTransform] = useState(`translateX(-50%) translateY(-50%) rotate(0deg) scale(${data.scaleStart})`)
  const [opacity, setOpacity] = useState(data.opacityStart)

  useEffect(() => {
    window.setTimeout(() => {
      setTransform(`translateX(${data.x}px) translateY(${data.y}px) rotate(${data.angle}deg) scale(${0})`)
      setOpacity(data.opacityEnd)
    }, data.delay)
  }, [])

  return (
    <Particle
      style={{
        transform,
        opacity
      }}
    />
  )
}

const Particle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 1em;
  background-color: #F2E3A3;
  background: radial-gradient(#fff8d8, #C8AE72);
  will-change: transform, opacity;
  transition: transform 2500ms ease, opacity 500ms ease-out 130ms;
  position: absolute;
  transform: translateX(0) translateY(0) rotate(0deg);
  left: 50%;
  top: 50%;
`
