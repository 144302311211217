// Global config app

const config = {
  documentData: {
    title: 'Quinielas y Apuestas Deportivas | Pickwin',
    description:
      'Especialistas en quinielas deportivas. Apuestas de futbol (Liga MX, Serie A, La Liga, Bundesliga), NBA, NFL y más. ¡Regístrate y gana!',
  },

  userMenu: [
    { title: 'Mi Cuenta', link: '/usuario/mi-cuenta', icon: 'ic:baseline-account-circle', id: 'mi-cuenta' },
    { title: 'Abonar Saldo', link: '/usuario/abonar', icon: 'vaadin:piggy-bank-coin', id: 'abonar' },
    { title: 'Programa de Lealtad', link: '/usuario/recompensas', icon: 'ant-design:trophy-filled', id: 'recompensas' },
    // Only shows referral VIP for user enable option has_vip_referrals
    { title: 'Invitados VIP', link: '/usuario/invitados-vip', icon: 'mingcute:vip-2-fill', id: 'invitados-vip' },
    { title: 'Tienda VIP', link: '/usuario/tienda', icon: 'zondicons:store-front', id: 'tienda' },
    { title: 'Bonos', link: '/usuario/bonos', icon: 'mdi:present-outline', id: 'bonos' },
    { title: 'Retirar', link: '/usuario/retirar', icon: 'solar:hand-money-bold', id: 'retirar' },
    { title: 'Historial', link: '/usuario/historial', icon: 'fontisto:history', id: 'historial' },
    { title: 'Transferir Fondos', link: '/usuario/canjear', icon: 'material-symbols:compare-arrows-rounded', id: 'canjear' },
    { title: 'Historial Programa de Lealtad', link: '/usuario/historial_recompensas', icon: 'material-symbols:list-alt-rounded', id: 'historial_recompensas' },
    // { title: 'Centro De Notificaciones', link: '/usuario/notificaciones', icon: 'mdi:bell-notification', id: 'notificaciones' },
    // { title: 'Amigos', link: '/usuario/amigos', icon: 'fa-solid:users', id: 'amigos' },
    // { title: 'Invitados', link: '/usuario/invitados', icon: 'ri:user-fill', id: 'invitados' },
    // { title: 'Historial', link: '/usuario/historial', icon: 'fontisto:history', id: 'historial' },
    // { title: 'Mis Tarjetas', link: '/usuario/mis_tarjetas', icon: 'ic:round-credit-card', id: 'mis_tarjetas' },
    // { title: 'Información Personal', link: '/usuario/personal', icon: 'ic:sharp-radio-button-checked', id: 'personal' },
    // { title: 'APUESTAS PASADAS', link: '/usuario/old-bets', icon: 'material-symbols:list-alt-rounded' }
  ],

  accountMenu: [
    { title: 'Mi Cuenta', link: '/usuario/', icon: 'ic:sharp-radio-button-checked' },
    { title: 'Mis Tarjetas', link: '/usuario/', icon: 'ic:round-credit-card' },
    { title: 'Amigos', link: '/usuario/amigos', icon: 'fa-solid:users' },
    { title: 'Notificaciones', link: '/usuario/notificaciones', icon: 'mdi:bell-notification' },
    { title: 'Invitados', link: '/usuario/Mensajes', icon: 'ri:user-fill' },
    // { title: 'Invitados VIP', link: '/usuario/invitados-vip', icon: 'mingcute:vip-2-fill' },
  ]
}

export default config
