import React from 'react';
import gaUtils from '../GAWrapper';
import axios from 'axios';
import participantes from '../../assets/images/participantesIconBlanco-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';
import MediaQuery from 'react-responsive';
import {
  Button,
  Modal,
} from 'react-bootstrap/lib';
import { Redirect, Link } from 'react-router-dom';
import WeeksControls from '../WeeksControls';
import SurvivorUI from '../SurvivorUI';
import RegisterPanel from '../RegisterPanel';
import Premios from '../Premios';
import Participantes from '../Participantes';
// import '../../assets/stylesheets/flags-sprites-complete.scss';
import picks from '../../assets/images/quinielasIconBlanco-50.png';
import guardar from '../../assets/images/guardarPicks-50.png';
import utils from '../../utils/utils';
import API from '../../utils/api';
import SurvivorContestUI from '../SurvivorContestUI/SurvivorContestUI';
import $ from 'jquery';

const PopUpButtons = ({
  isLogged,
  survivor,
  enoughBalance,
  showPanelAbonar,
  showPanelRegister,
  nameQuina,
  firstEntry,
}) => {
  let callToActionBtn = null;
  let lowerRow = null;

  if (isLogged) {
    if (enoughBalance) {
      // Solo para survivor VIP
      callToActionBtn = (
        <Button
          className="guardar-picks callToAction"
          onClick={() => {
            showPanelRegister();
          }}
        >
          <img src={guardar} className="pool-icons" />
          <span>{'GUARDAR PICK Y REGÍSTRAME'}</span>
        </Button>
      );
    } else if (survivor.entry_currency !== 'ticket') {
      callToActionBtn = (
        <Button
          className="guardar-picks callToAction"
          onClick={() => {
            showPanelAbonar();
          }}
        >
          <img src={abonar} className="pool-icons" />
          <span>{'ABONAR SALDO'}</span>
        </Button>
      );
    }
  } else if (survivor.entry_currency !== 'ticket') {
      callToActionBtn = (
        <Button
          className="guardar-picks callToAction"
          btnStyle="success"
          disabled
          onClick={() => {
            showPanelAbonar();
          }}
        >
          <img src={abonar} className="pool-icons" />
          <span>{'ABONAR SALDO'}</span>
        </Button>
      );
  }

  lowerRow = (
    <Button
      href={`/survivor/${nameQuina}/${survivor.id}/${
        !utils.isLogged() || !firstEntry || !firstEntry.survivor_entry
          ? ''
          : firstEntry.survivor_entry.id
        }`}
        className="guardar-picks callToAction"
        bsStyle="default"
        disabled={!utils.isLogged()}
        style={{ marginTop: '1em' }}
        onClick={(e) => {
            // window.location.assign(`/survivor/${nameQuina}/${ this.state.survivor.id }/${!utils.isLogged() || !this.state.firstEntry ||  !this.state.firstEntry.survivor_entry  ? ''  : this.state.firstEntry.survivor_entry.id}`);
            // this.disableClickedButton (e, 2000);
        }}
      >
        <span style={{ padding: '0 10px' }}>IR A LOBBY DE SURVIVOR</span>
    </Button>
  );
  // console.log(callToActionBtn, lowerRow)

  return (
    <div className="flex-row">
      <div className="flex-row">
        <div className="flex-col">
          {callToActionBtn}
          {isLogged ? lowerRow : null}
        </div>
      </div>
    </div>
  );
};

const RegisterSteps = (props) => {
  let conditionalComponent = (
    <div id="registerSteps" className="row">
      <div className="col-xs-12 text-center register-title-container">
        <span className="register-title">
          <i className="ion-flag" />
          Sigue estos pasos para participar en el survivor.
        </span>
      </div>
      {props.isBalanceEnough ? (
        <div className="col-xs-12 steps-container">
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Elige tú pick</span>
          </div>
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">2</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu survivor</span>
          </div>
        </div>
      ) : (
          <div className="col-xs-12 steps-container">
            <div className="col-xs-4  register-step-item">
              <span className="step-number">1</span>
              <img src={picks} className="step-icon" />
              <span className="step-label">Elige tú pick</span>
            </div>
            <div className="col-xs-4  register-step-item">
              <span className="step-number">2</span>
              <img src={abonar} className="step-icon" />
              <span className="step-label">Abona saldo</span>
            </div>
            <div className="col-xs-4  register-step-item">
              <span className="step-number">3</span>
              <img src={guardar} className="step-icon" />
              <span className="step-label">Guarda tu survivor</span>
            </div>
          </div>
        )}
    </div>
  );
  return conditionalComponent;
};

const NotLoggedUserBar = ({ redirectLogin, redirectRegistro }) => {
  return (
    <div className="buttons-row">
      <Button onClick={redirectLogin} data-id="login" className="btn-lobby-login" bsSize="small">
        <i className="ion-log-in" /> <span data-id="login">INGRESAR</span>
      </Button>
      <Button
        onClick={redirectRegistro}
        data-id="registro"
        className="btn-lobby-login"
        bsSize="small"
      >
        <span data-id="registro">CREAR CUENTA</span>
      </Button>
    </div>
  );
};

class SurvivorPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enoughBalance: false,
      activeRound: 1,
      survivorEntries: null,
      errorCopiar: null,
      survivorEntriesInfo: null,
      item: this.props.item !== null && this.props.item[0] !== null ? this.props.item[0].id : null,
      loaded: false,
      logged: null,
      picks: [],
      submitting: false,
      survivor: null,
      firstEntry: null,
      premios: null,
      redirectLobby: false,
      redirectLogin: null,
      redirectWithEntry: false,
      showPanelRegister: false,
      showPanel: false,
      showAbonar: false,
      showTutorial: false,
      posActualPage: 1,
      survivorStandings: [],
      temporalRegisterPicks: null,
      userData: null,

      allFixtures: {}
    };
    this.queryParams = {
      page: 1,
      nick: '',
      sort: [],
      order: null,
    };
    this.handleRedirectToLobby = (redirectToEntry) => {
      console.log(redirectToEntry);
      this.setState({ redirectLobby: true, redirectToEntry: redirectToEntry });
    };

    this.siguientePagPositions = (eventKey) => {
      let loaderElement = $('#loading');
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.state.standings.items;
      console.log('siguiente');
      //let val = eventKey;
      console.log(this.queryParams.page, this.state.posActualPage);
      this.queryParams.page = this.state.posActualPage + 1;
      if (this.queryParams.page > this.state.survivorStandings.pages) {
        return;
      }
      console.log('Next Positions...');
      API.fetchSurvivorStandings(this.state.survivor.id, this.queryParams).then((response) => {
        if (!response || !response.data || !response.data.items) {
          return;
        }
        this.setState(
          (prevState) => {
            // console.log(response.data);
            let newPosiciones = response.data;
            let newItems = response.data.items !== null ? response.data.items : null;
            newItems.forEach((item) => {
              temporalPositions.push(item);
            });
            console.log('NEWPos', temporalPositions);
            newPosiciones.items = temporalPositions;
            return {
              survivorStandings: newPosiciones,
              posActualPage: prevState.posActualPage + 1,
            };
          },
          () => {
            if (loaderElement !== undefined && loaderElement.length > 0) {
              loaderElement[0].style.display = 'none';
              //let avatars = $('.score-avatars').height('auto');
            }
          }
        );
      });
    };

    /**
     * Abonar Panel handlers
     */
    this.showAbonarPanel = () => {
      this.setState({ showAbonar: true });
    };

    this.hideAbonarPanel = () => {
      let balanceEnough = utils.isBalanceEnough(
        this.state.survivor.entry_currency,
        this.state.survivor.entry_fee
      );
      console.log('hasEnoughBalance', balanceEnough);
      this.setState({
        showAbonar: false,
        enoughBalance: balanceEnough,
      });

      // this.setState({
      //   showAbonar: false,
      //   enoughBalance:
      //     (!balanceEnough && !this.state.ticketsAvailable) ||
      //     !this.state.ticketsAvailable.length
      //       ? false
      //       : true,
      // });
    };

    this.showTutorial = () => {
      this.setState({ showTutorial: true });
      console.log('Show Tutorial');
    };

    this.closeTutorial = () => {
      this.setState({ showTutorial: false });
    };

    this.showPanelRegister = () => {
      this.setState({ showPanelRegister: true });
    };

    this.closePanelRegister = () => {
      this.setState({ showPanelRegister: false });
    };

    this.openRegistrationPopUp = () => { };

    /**
     * Get the entry info to use it in the Register Panel
     * @param { Object } temporalEntry - The object that will be updated in the state
     *
     */
    this.updateTemporalRegisterPicks = (temporalEntry) => {
      console.log(temporalEntry);
      this.setState({
        temporalRegisterPicks: temporalEntry,
      });
    };

    /**
     * This function updates the currentweek
     * and gets the corresponding fixtures to update the Form
     * and picks
     */
    this.changeSelectedWeek = (id) => {
      console.log('%c ChangeSelected Week', 'color:#fec830; font-size:2em;');
      console.log(id);
      this.setState({
        loadedPicks: false,
      });

      API.fetchSurvivorFixtures(id).then((response) => {
        this.setState({
          selectedWeek: id,
          fixtures: response.data.fixtures,
          loadedPicks: true,
        });
      });

    };

    /**
     * This function get the Corresponding data for the
     * selected survivor game when the user dont have an available entry
     *
     * @param { Number } survivorId - The id of the survivorGame
     *
     *
     */
    this.getWithoutRegisterData = (survivorId) => {
      console.log('Without Register');
      /**
       * Update user Data
       * fetchTickets
       * fetch survivorDetails
       * fetchWeek
       * fetch fixtures
       * fetch standings
       * fetch standingsGroup
       * fetch prizes
       */
      axios
        .all([
          API.fetchSurvivorDetails(survivorId),
          API.fetchSurvivorStandings(survivorId, this.state.queryParams),
          API.fetchSurvivorAllFixtures(survivorId)
          // API.fetchSurvivorStandingsGroups (survivorId ),
          // API.fetchSurvivorPrizes( survivorId ),
        ])
        .then(
          axios.spread((survivorResponse, survivorStandingsResponse, survivorAllFixturesResponse) => {
            let survivor = survivorResponse.data.survivor;
            let currentWeek = survivorResponse.data.currentWeek;
            let previousWeek = survivorResponse.data.previousWeek;
            let currentContest = survivorResponse.data.currentContest;
            let survivorPrizes = survivorResponse.data.survivor_prizes;
            let allFixtures = survivorAllFixturesResponse.data

            /**
             *Check if the Game started
             */
            // console.log( survivor.status );

            if (survivor.status !== 'upcoming') {
              this.setState({ redirectLobby: true, survivor: survivor });
            }

            this.props.setItemSelected(survivor);
            this.setState({
              redirectLobby: false,
              survivorId: survivorId,
              survivor: survivor,
              standings: survivorStandingsResponse.data,
              logged: utils.isLogged(),
              prizes: survivorPrizes,
              userData: this.props.userData,
              withRegister: false,
              loadedPicks: true,
              allFixtures,
              previousWeek
            });

            if (this.state.logged) {
              axios
                .all([
                  API.fetchTickets(survivorId, 'survivor'),
                  API.fetchFirstEntrySurvivor(survivorId),
                ])
                .then(
                  axios.spread((responseTickets, responseFirstEntry) => {
                    let balanceEnough = utils.isBalanceEnough(
                      this.state.survivor.entry_currency,
                      this.state.survivor.entry_fee
                    );

                    this.setState({
                      ticketsAvailable: responseTickets.data.tickets,
                      enoughBalance:
                        !balanceEnough && !responseTickets.data.tickets.length ? false : true,
                      firstEntry: responseFirstEntry.data,
                    });
                  })
                );
            }

            if (survivor.sport_id !== 6 && survivor.sport_id !== 7) {
              axios
                .all([
                  API.fetchSurvivorFixtures(currentWeek.id),
                  API.fetchSurvivorTeams(survivorId),
                ])
                .then(
                  axios.spread((response, survivorTeamsResponse) => {
                    this.setState({
                      firstOf: response.data.firstOf,
                      fixtures: response.data.fixtures,
                      mounted: true,
                      survivorTeams: survivorTeamsResponse.data.teams,
                      survivorWeeks: survivorResponse.data.survivor.weeks,
                      currentWeek: currentWeek,
                      selectedWeek: currentWeek.id,
                    });
                  })
                );
            } else {
              API.getSurvivorContestPlayers(currentContest.id).then((response) => {
                let data = response.data;
                this.setState({
                  currentContest: currentContest,
                  players: data.players,
                  mounted: true,
                });
              });
            }
          })
        );
    };
  }

  componentDidUpdate(prevProps, prevState) {
    //////////////////////////////////////////////////////////////////////////
    // Get the tickets and update the user info if the logegd status changed
    //////////////////////////////////////////////////////////////////////////
    if (!prevProps.logged && this.props.logged) {
      API.fetchTickets(this.state.survivor.id, 'survivor').then((response) => {
        API.fetchFirstEntrySurvivor(this.state.survivor.id).then((responseFirstEntry) => {
          console.log('ResponseFirst ENtry', responseFirstEntry);

          let balanceEnough = utils.isBalanceEnough(
            this.state.survivor.entry_currency,
            this.state.survivor.entry_fee
          );

          this.setState({
            firstEntry: responseFirstEntry.data,
            logged: utils.isLogged(),
            userData: this.props.userData,
            tickets: response.data.tickets,
            enoughBalance: !balanceEnough && !response.data.tickets.length ? false : true,
          });
        });
      });
    }
  }

  componentDidMount() {
    console.log(this.showPanelRegister, this.showPanelReRegister);
    if (!this.props.item) {
      return;
    } else {
      let survivorId = this.props.item[0].id;
      let survivorSlug = this.props.item[0].slug;
      if (!survivorId) {
        survivorSlug = this.props.item[0].survivor.slug;
        survivorId = this.props.item[0].survivor.id;
      }
      let fakeMatch = {
        isExact: true,
        params: {
          slug: survivorSlug,
        },
      };
      gaUtils.handleDocumentAttributes(fakeMatch);

      console.log(survivorId);
      this.getWithoutRegisterData(survivorId);
    }
  }

  render() {
    let conditionalComponent = null;

    if (this.state.redirectLobby) {
      console.log('Redirection!');
      console.log(this.state.survivor);
      let nameQuina = this.state.survivor.slug;
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/survivor/${nameQuina}/${this.state.survivor.id}/${
              !this.state.redirectToEntry ? '' : this.state.redirectToEntry
              }`,
          }}
        />
      );
    } else {
      if (this.state.mounted) {
        let nameQuina = this.state.survivor.slug;
        conditionalComponent = (
          <div className="quiniela-info-container survivor ">
            <AbonarSaldo
              type="modal"
              showAbonarSaldo={this.state.showAbonar}
              updatePanelSate={this.hideAbonarPanel}
              showAbonarPop={this.showAbonarPanel}
              updateUserInfo={this.props.updateUserInfo}
            />

            <RegisterPanel
              isPopUp={true}
              closePanel={this.closePanelRegister}
              entryId={this.state.selectedEntryId}
              handleConfirmation={this.handleConfirmation}
              logged={this.state.logged}
              payType={this.state.survivor.entry_currency}
              pool={this.state.survivor}
              pools={null}
              type={'survivor'}
              logged={utils.isLogged()}
              popUpNewUsersStatus={this.props.popUpNewUsersStatus}
              quinaID={this.state.survivorId}
              refreshAfterGuardar={this.refreshAfterGuardarInLobby}
              registerPicks={this.state.temporalRegisterPicks}
              returnErrors={this.returnErros}
              reRegister={this.state.reRegister}
              showPanel={this.state.showPanelReRegister}
              showPanelFirstRegister={this.state.showPanelRegister}
              showPanelReRegister={this.state.showScorePanelRegister}
              showTutorial={this.showTutorial}
              updatePanelFirstRegister={this.closeScorePanelFirstRegister}
              updatePanelReRegister={this.closeScorePanelReRegister}
              updateStateFunc={this.closePanelRegister}
              updateUserInfo={this.props.updateUserInfo}
              userData={this.state.userData}
              handleRedirectToLobby={this.handleRedirectToLobby}
              withRegister={this.state.withRegister}
            />
            <Modal
              show={this.state.submitting}
              className="responseDepositModal absoluteModal"
              dialogClassName="responseDepositDialog"
              backdropClassName="responseDepositBackdrop"
            >
              <div className="flex-container justify align column">
                <i className="ion-load-a loading"> </i>
                <div className="flex-row row-center">
                  <div className="flex-col col-center text-center">
                    <strong>Espera un momento porfavor...</strong>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="quiniela-container">
              {utils.isLogged() ? null : (
                <NotLoggedUserBar
                  redirectLogin={this.props.showLoginPopUp}
                  redirectRegistro={this.props.showRegisterPopUp}
                />
              )}
              <RegisterSteps isBalanceEnough={this.state.enoughBalance} />

              {/* -------- Botones que controlan la semana que se esta visualizando -------- */}
              {(this.state.survivor.sport_id !== 6 &&
                this.state.survivor.sport_id !== 7 &&
                this.state.survivor.sport_id !== 1 &&
                this.state.survivor.sport_id !== 2
              ) ? (
                <WeeksControls
                  label="Selecciona una jornada"
                  currentWeek={this.state.currentWeek.id}
                  selectedWeek={this.state.selectedWeek}
                  weeks={this.state.survivorWeeks}
                  handleWeekChange={this.changeSelectedWeek}
                />
              ) : null}

              <div className="quinielas-info flex-row responsive-md">
                {this.state.survivor.status === 'upcoming' ? (
                  <div className={`flex-col col-40 `}>
                    <div
                      style={{ position: 'relative' }}
                      className={`quinielas-info flex-col col-no-padding`}
                    >
                      {this.state.survivor.sport_id !== 6 && this.state.survivor.sport_id !== 7 ? (
                        <SurvivorUI
                          showAbonarPanel={this.showAbonarPanel}
                          hideAbonarPanel={this.hideAbonarPanel}
                          showAbonar={this.state.showAbonar}
                          firstOf={this.state.firstOf}
                          isPopUp={true}
                          refreshAfterGuardarInLobby={this.refreshAfterGuardarInLobby}
                          enoughBalance={this.state.enoughBalance}
                          currentWeek={this.state.currentWeek}
                          selectedWeek={this.state.selectedWeek}
                          changeSelectedWeek={this.changeSelectedWeek}
                          entriesInfo={this.state.entriesInfo}
                          fixtures={this.state.fixtures}
                          loadedPicks={this.state.loadedPicks}
                          selectedEntryData={this.state.selectedEntryData}
                          survivorInfo={this.state.survivor}
                          survivorId={this.state.survivorId}
                          survivorWeeks={this.state.survivorWeeks}
                          survivorTeams={this.state.survivorTeams}
                          survivorWeekSelected={this.state.survivorWeekSelected}
                          userData={this.state.userData}
                          updateTemporalRegisterPicks={this.updateTemporalRegisterPicks}
                          updateUserInfo={this.props.updateUserInfo}
                          withRegister={this.state.withRegister}
                          standings={this.state.standings}
                          allFixtures={this.state.allFixtures}
                          previousWeek={this.state.previousWeek}
                        />
                      ) : (
                          <SurvivorContestUI
                            survivor={this.state.survivor}
                            currentContest={this.state.currentContest}
                            players={this.state.players}
                            updateTemporalRegisterPicks={this.updateTemporalRegisterPicks}
                            enoughBalance={this.state.enoughBalance}
                            showAbonarPanel={this.showAbonarPanel}
                            hideAbonarPanel={this.hideAbonarPanel}
                            showPanelRegister={this.showPanelRegister}
                            isPopUp={true}
                          ></SurvivorContestUI>
                        )}
                    </div>
                    <MediaQuery maxWidth={768}>
                      {this.state.survivor.point_format === 'line' ? (
                        <div className="nota-mis-picks">
                          <i className="ion-alert-circled" />
                          <span>
                            Las líneas de los partidos no cambiaran una vez que se han publicado.
                            Aquellas líneas que no aparezcan publicadas podrán ser publicadas hasta
                            5 minutos antes de que comience el partido correspondiente.
                          </span>
                        </div>
                      ) : null}
                    </MediaQuery>
                  </div>
                ) : null}
                <div className="second-panel flex-col">
                  <div className="flex-row responsive-sm ">
                    <div id="premiosQuiniela" className="flex-col">
                      <div className="flex-row row-no-padding">
                        <div className="flex-col col-no-padding panel-pickwin survivor">
                          <Premios
                            weeks={this.state.survivorWeeks}
                            loaded={this.state.mounted}
                            poolInfo={this.state.survivor}
                            premios={this.state.prizes}
                            standings={this.state.standings}
                            type="popup"
                          />
                        </div>
                      </div>
                    </div>
                    <div id="participantesQuiniela" className={`popup  flex-col `}>
                      <div className="flex-container panel-pickwin survivor">
                        <div className="flex-row title-quiniela">
                          <div className="title-wrapper">
                            <img src={participantes} className="pool-icons tablas" />
                            <span>Participantes</span>
                          </div>
                        </div>
                        <Participantes
                          users={this.state.standings}
                          type="survivor"
                          siguientePagPositions={this.siguientePagPositions}
                        />
                      </div>
                    </div>
                  </div>
                  <PopUpButtons
                    nameQuina={nameQuina}
                    firstEntry={this.state.firstEntry}
                    isLogged={utils.isLogged()}
                    survivor={this.state.survivor}
                    showPanelRegister={this.showPanelRegister}
                    showPanelAbonar={this.showAbonarPanel}
                    survivorEntries={this.state.survivorEntries}
                    enoughBalance={this.state.enoughBalance}
                  />
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        );
      }
    }

    return conditionalComponent;
  }
}

export default SurvivorPopup;
