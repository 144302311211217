import React from 'react';
import Loading from '../Loading';
import moment from 'moment';
import Futbol from '../../assets/images/FutbolBall-50.png';
import Americano from '../../assets/images/AmericanoBall-50.png';
import Basketball from '../../assets/images/BasketballBall-50.png';
import Baseball from '../../assets/images/BaseballBall-50.png';
import '../../assets/stylesheets/types-sprites.scss';
import _ from 'underscore';

import firstBy from 'thenby';

moment.locale('es');

const RecordTeam = ({ fixture, type, recordType }) => {
  let conditionalComponent = null;

  if (type === 'home') {
    if (recordType === 'default') {
      conditionalComponent = (
        <span className="stats-record flex-row">
          [{' '}
          {!fixture.away_team.team_stats || !fixture.home_team.team_stats.win
            ? 0
            : fixture.home_team.team_stats.win}
          -
          {!fixture.away_team.team_stats || !fixture.home_team.team_stats.lost
            ? 0
            : fixture.home_team.team_stats.lost}
          -
          {!fixture.away_team.team_stats || !fixture.home_team.team_stats.ties
            ? 0
            : fixture.home_team.team_stats.ties}
          ]
        </span>
      );
    } else if ('withRoad') {
      conditionalComponent = (
        <span className="stats-record">
          {`[${
            !fixture.home_team.team_stats || !fixture.home_team.team_stats.win
              ? 0
              : fixture.home_team.team_stats.win
            }
            -${
            !fixture.home_team.team_stats || !fixture.home_team.team_stats.lost
              ? 0
              : fixture.home_team.team_stats.lost
            }]`}
        </span>
      );
    }
  } else {
    if (recordType === 'default') {
      conditionalComponent = (
        <span className="stats-record flex-row">
          [
          {!fixture.away_team.team_stats || !fixture.away_team.team_stats.win
            ? 0
            : fixture.away_team.team_stats.win}
          -
          {!fixture.away_team.team_stats || !fixture.away_team.team_stats.lost
            ? 0
            : fixture.away_team.team_stats.lost}
          -
          {!fixture.away_team.team_stats || !fixture.away_team.team_stats.ties
            ? 0
            : fixture.away_team.team_stats.ties}
          ]
        </span>
      );
    } else if ('withRoad') {
      conditionalComponent = (
        <span className="stats-record">
          {`[${
            !fixture.away_team.team_stats || !fixture.away_team.team_stats.win
              ? 0
              : fixture.away_team.team_stats.win
            }-
            ${
            !fixture.away_team.team_stats || !fixture.away_team.team_stats.lost
              ? 0
              : fixture.away_team.team_stats.lost
            }]`}
        </span>
      );
    }
  }

  return conditionalComponent;
};

/**
 *This function Return the label of the time depending of the status
 */
function LabelTime(props) {
  // console.log('LabelTime',props);
  if (props.fixture.sort_status == 0) {
    // console.log(isLive, props);
    return (
      <div className="title-juego-wrapper flex-row row-no-padding">
        {props.fixture.stats && props.fixture.stats.hasOwnProperty('quarter') ? (
          <div className="qrtr-title flex-col col-center col-20 text-center">
            <small className="flex-container justify-start">{props.fixture.stats.quarter}</small>
          </div>
        ) : null}
        {props.fixture.stats && props.fixture.stats.hasOwnProperty('timer') ? (
          <div className="timer-title flex-col col-center text-center">
            <small className="flex-container justify-start">
              <i className="ion-ios-timer" style={{ marginRight: '3px' }} />{' '}
              {props.fixture.stats.timer}
            </small>
          </div>
        ) : null}
        {/**Away Drive */
          props.fixture.stats &&
            props.fixture.stats.hasOwnProperty('awaydrive') &&
            props.fixture.stats.awaydrive ? (
              <div className="timer-title flex-col col-20 text-center">
                <small className="flex-container justify-end align">
                  {props.fixture.stats.awaydrive}
                </small>
              </div>
            ) : null}
        {/**Away ball */
          props.fixture.stats &&
            props.fixture.stats.hasOwnProperty('away_ball') &&
            props.fixture.stats.away_ball ? (
              <div className="timer-title flex-col col-15 text-center">
                <small className="flex-container  align">- {props.fixture.stats.away_ball}</small>
              </div>
            ) : null}
        {/**Home Drive */
          props.fixture.stats &&
            props.fixture.stats.hasOwnProperty('homedrive') &&
            props.fixture.stats.homedrive ? (
              <div className="timer-title flex-col col-20">
                <small className="flex-container justify-end align">
                  {props.fixture.stats.homedrive}
                </small>
              </div>
            ) : null}
        {/**Home Ball */
          props.fixture.stats &&
            props.fixture.stats.hasOwnProperty('home_ball') &&
            props.fixture.stats.home_ball ? (
              <div className="timer-title flex-col text-center col-15">
                <small className="flex-container algin">- {props.fixture.stats.home_ball}</small>
              </div>
            ) : null}
        <span className="label-live flex-col col-center col-20 text-center">Live</span>
      </div>
    );
  } else if (props.fixture.sort_status == 1) {
    return (
      <div className="title-juego-wrapper flex-row row-no-padding">
        <div className=" titulo-juego-fecha flex-col">
          {moment(props.fixture.match_date).format('MM/D/YY @ H:mm')}
        </div>
        <div className=" label-prox flex-col col-20 col-cente text-center">Próx.</div>
      </div>
    );
  }  else if (props.fixture.sort_status == 2) {
    return (
      <div className="title-juego-wrapper flex-row row-no-padding">
        <div className=" titulo-juego-fecha flex-col">
          {moment(props.fixture.match_date).format('MM/D/YY @ H:mm')}
        </div>
        <div className="label-finalizado flex-col col-20 col-center text-center">Finalizó</div>
      </div>
    );
  }
}

/**
 *This function return the Score of the Quiniela
 *Depending the status of the Quiniela
 */
const AsignarMarcador = (props) => {
  let conditionalComponent = null;
  // console.log('%c %s %o','font-size:30px; color:#7BD900;','Asignar Marcador',props);
  // console.log(props.fixture);
  let closingDate = moment(props.fixture.deadline);
  let nowDate = moment();
  let itPassedSec = closingDate.diff(nowDate, 'seconds');
  // console.log(closingDate, new Date());
  // console.log(itPassedSec, itPassedSec < 86400)
  let format24h = false;
  let itStarted = false;
  let isLive = false;

  let gameInfo = props.poolInfo;

  if (itPassedSec < 86400) {
    format24h = true;
  }
  if (itPassedSec < 0) {
    itStarted = true;
    // this.props.updateStartedFixture(fixture.id);
    if (!props.fixture.finished) {
      isLive = true;
    }
  }

  if (!gameInfo.closed) {
    // console.log('Not Closed');
    /********************
     * Upcoming Fixture *
     ********************/
    if (props.fixture.enabled) {
      // console.log('Is not closed and is enabled UPCOMING');
      // console.log(props.fixture);
      //Soccer
      conditionalComponent = (
        <span className="marcadores col-no-padding flex-col">
          {gameInfo.sport_id === 1 ? (
            <span className="score-team">
              <span className="half">0</span>
              <span className="half">0</span>
            </span>
          ) : (
              <RecordTeam fixture={props.fixture} type={props.typeTeam} recordType={'default'} />
            )}
        </span>
      );
    } else {
      /****************
       * Live Fixture *
       ****************/
      // console.log('Live Fixture ', props.fixture);
      switch (gameInfo.sport_id) {
        //Soccer        [half]
        case 1:
          console.log('Soccer');
          conditionalComponent = (
            <span className="marcadores col-no-padding flex-col">
              {props.fixture.hasOwnProperty('stats') &&
                props.fixture.stats.hasOwnProperty('score_home_first_half') ? (
                  <div className="flex-col col-no-padding">
                    <span className="score-team flex-row">
                      <span className="flex-col ">
                        {props.typeTeam === 'home'
                          ? props.fixture.stats.score_home_first_half
                          : props.fixture.stats.score_away_first_half}
                      </span>
                      <span className="flex-col">
                        {props.typeTeam === 'home'
                          ? props.fixture.stats.score_home_second_half
                          : props.fixture.stats.score_away_second_half}
                      </span>
                      <span className="flex-col">
                        {props.typeTeam === 'home'
                          ? props.fixture.score_home
                          : props.fixture.score_away}
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="flex-col col-no-padding">
                    <span className="score-team flex-row">
                      <span className="half">0</span>
                      <span className="half">0</span>
                      <span className="total">0</span>
                    </span>
                  </div>
                )}
            </span>
          );
          break;
        //Gringo Sports [4qtr]
        case 2:
        case 3:
          // console.log('Gringos');
          conditionalComponent = (
            <span className="marcadores col-no-padding flex-col">
              {props.fixture.hasOwnProperty('stats') &&
                props.fixture.stats.hasOwnProperty('homeq1') &&
                props.fixture.stats.homeq1 !== null ? (
                  <span className="stats-quarter flex-row row-no-padding">
                    <div className="flex-col  text-center">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homeq1
                        : props.fixture.stats.awayq1}
                    </div>
                    <div className="flex-col  text-center">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homeq2
                        : props.fixture.stats.awayq2}
                    </div>
                    <div className="flex-col  text-center">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homeq3
                        : props.fixture.stats.awayq3}
                    </div>
                    <div className="flex-col  text-center">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homeq4
                        : props.fixture.stats.awayq4}
                    </div>
                    <div className="flex-col total text-center">
                      {props.typeTeam === 'home'
                        ? props.fixture.score_home
                        : props.fixture.score_away}
                    </div>
                  </span>
                ) : (
                  <span className="flex-row stats-quarter">
                    <div className="flex-col text-center">0</div>
                    <div className="flex-col text-center">0</div>
                    <div className="flex-col text-center">0</div>
                    <div className="flex-col text-center">0</div>
                    <div className="flex-col total text-center">0</div>
                  </span>
                )}
            </span>
          );
          break;
        // Hockey [periods]
        case 9:
          console.log('Hockey');
          conditionalComponent = (
            <span className="marcadores col-no-padding flex-col">
              {props.fixture.hasOwnProperty('stats') &&
                props.fixture.stats.hasOwnProperty('homep1') &&
                props.fixture.stats.homep1 !== null ? (
                  <span className="stats-quarter">
                    <div className="qrtr">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homep1
                        : props.fixture.stats.awayp1}
                    </div>
                    <div className="qrtr">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homep2
                        : props.fixture.stats.awayp2}
                    </div>
                    <div className="qrtr">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homep3
                        : props.fixture.stats.awayp3}
                    </div>
                    <div className="qrtr">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.homeso
                        : props.fixture.stats.awayso}
                    </div>
                    <div className="total">
                      {props.typeTeam === 'home'
                        ? props.fixture.score_home
                        : props.fixture.score_away}
                    </div>
                  </span>
                ) : (
                  <span className="stats-quarter">
                    <div className="qrtr">0</div>
                    <div className="qrtr">0</div>
                    <div className="qrtr">0</div>
                    <div className="qrtr">0</div>
                  </span>
                )}
            </span>
          );
          break;
        //Baseball      [innings]
        case 4:
          conditionalComponent = (
            <span className="marcadores col-no-padding flex-col">
              {props.fixture.hasOwnProperty('stats') &&
                props.fixture.stats.hasOwnProperty('home') &&
                props.fixture.stats.home.innings.length > 0 ? (
                  <div className="flex-col ">
                    <span className="stats-quarter flex-row row-no-padding">
                      <div className="innings text-center flex-col">
                        {!props.fixture.stats[props.typeTeam].runs
                          ? '0'
                          : props.fixture.stats[props.typeTeam].runs}
                      </div>
                      <div className="innings text-center flex-col">
                        {!props.fixture.stats[props.typeTeam].hits
                          ? '0'
                          : props.fixture.stats[props.typeTeam].hits}
                      </div>
                      <div className="innings text-center flex-col">
                        {!props.fixture.stats[props.typeTeam].errors
                          ? '0'
                          : props.fixture.stats[props.typeTeam].errors}
                      </div>
                      <div className="innings text-center flex-col">
                        {!props.fixture.stats[props.typeTeam].runs
                          ? '0'
                          : props.fixture.stats[props.typeTeam].runs}
                      </div>
                    </span>
                  </div>
                ) : (
                  <div className="flex-col ">
                    <span className="stats-quarter flex-row row-no-padding">
                      <div className="innings text-center flex-col">0</div>
                      <div className="innings text-center flex-col">0</div>
                      <div className="innings text-center flex-col">0</div>
                      <div className="innings text-center flex-col">0</div>
                    </span>
                  </div>
                )}
            </span>
          );
          break;
      }
    }
  } else {
    console.log('Closed Game ', props.fixture);
    switch (gameInfo.sport_id) {
      //Soccer        [half]
      case 1:
        console.log('Soccer');
        conditionalComponent = (
          <span className="marcadores col-no-padding flex-col">
            {props.fixture.hasOwnProperty('stats') &&
              props.fixture.stats.hasOwnProperty('score_home_first_half') ? (
                <div className="flex-col col-no-padding">
                  <span className="score-team flex-row">
                    <span className="flex-col ">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.score_home_first_half
                        : props.fixture.stats.score_away_first_half}
                    </span>
                    <span className="flex-col">
                      {props.typeTeam === 'home'
                        ? props.fixture.stats.score_home_second_half
                        : props.fixture.stats.score_away_second_half}
                    </span>
                    <span className="flex-col">
                      {props.typeTeam === 'home'
                        ? props.fixture.score_home
                        : props.fixture.score_away}
                    </span>
                  </span>
                </div>
              ) : (
                <div className="flex-col col-no-padding">
                  <span className="score-team flex-row">
                    <span className="half">0</span>
                    <span className="half">0</span>
                    <span className="total">0</span>
                  </span>
                </div>
              )}
          </span>
        );
        break;
      //Gringo Sports [4qtr]
      case 2:
      case 3:
        console.log('Gringos');
        conditionalComponent = (
          <span className="marcadores col-no-padding flex-col">
            {props.fixture.hasOwnProperty('stats') &&
              props.fixture.stats.hasOwnProperty('homeq1') &&
              props.fixture.stats.homeq1 !== null ? (
                <span className="stats-quarter flex-row row-no-padding">
                  <div className="flex-col  text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homeq1
                      : props.fixture.stats.awayq1}
                  </div>
                  <div className="flex-col  text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homeq2
                      : props.fixture.stats.awayq2}
                  </div>
                  <div className="flex-col  text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homeq3
                      : props.fixture.stats.awayq3}
                  </div>
                  <div className="flex-col  text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homeq4
                      : props.fixture.stats.awayq4}
                  </div>
                  <div className="flex-col total text-center">
                    {props.typeTeam === 'home' ? props.fixture.score_home : props.fixture.score_away}
                  </div>
                </span>
              ) : (
                <span className="flex-row stats-quarter">
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col total text-center">0</div>
                </span>
              )}
          </span>
        );
        break;
      // Hockey [periods]
      case 9:
        console.log('Hockey');
        conditionalComponent = (
          <span className="marcadores col-no-padding flex-col">
            {props.fixture.hasOwnProperty('stats') &&
              props.fixture.stats.hasOwnProperty('homep1') &&
              props.fixture.stats.homep1 !== null ? (
                <span className="stats-quarter flex-row row-center">
                  <div className="flex-col text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homep1
                      : props.fixture.stats.awayp1}
                  </div>
                  <div className="flex-col text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homep2
                      : props.fixture.stats.awayp2}
                  </div>
                  <div className="flex-col text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homep3
                      : props.fixture.stats.awayp3}
                  </div>
                  <div className="flex-col text-center">
                    {props.typeTeam === 'home'
                      ? props.fixture.stats.homeso
                      : props.fixture.stats.awayso}
                  </div>
                  <div className="flex-col text-center">
                    {props.typeTeam === 'home' ? props.fixture.score_home : props.fixture.score_away}
                  </div>
                </span>
              ) : (
                <span className="stats-quarter flex-row row-center">
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col text-center">0</div>
                  <div className="flex-col text-center">0</div>
                </span>
              )}
          </span>
        );
        break;
      //Baseball      [innings]
      case 4:
        conditionalComponent = (
          <span className="marcadores col-no-padding flex-col">
            {props.fixture.hasOwnProperty('stats') &&
              props.fixture.stats.hasOwnProperty('home') &&
              props.fixture.stats.home.innings.length > 0 ? (
                <div className="flex-col ">
                  <span className="stats-quarter flex-row row-no-padding">
                    <div className="innings text-center flex-col">
                      {!props.fixture.stats[props.typeTeam].runs
                        ? '0'
                        : props.fixture.stats[props.typeTeam].runs}
                    </div>
                    <div className="innings text-center flex-col">
                      {!props.fixture.stats[props.typeTeam].hits
                        ? '0'
                        : props.fixture.stats[props.typeTeam].hits}
                    </div>
                    <div className="innings text-center flex-col">
                      {!props.fixture.stats[props.typeTeam].errors
                        ? '0'
                        : props.fixture.stats[props.typeTeam].errors}
                    </div>
                  </span>
                </div>
              ) : (
                <div className="flex-col ">
                  <span className="stats-quarter flex-row row-no-padding">
                    <div className="innings text-center flex-col">0</div>
                    <div className="innings text-center flex-col">0</div>
                    <div className="innings text-center flex-col">0</div>
                  </span>
                </div>
              )}
          </span>
        );
        break;
    }
  }

  return conditionalComponent;
};

/**
 *This function return the Actions if the Quiniela is live of closed
 **/
function AsignarAcciones(props) {
  let conditionalComponent = null;
  let gameInfo = props.poolInfo.hasOwnProperty('pool') ? props.poolInfo.pool : props.poolInfo;

  let listEvents = props.fixture.events !== undefined ? props.fixture.events : [];
  // console.log('%c Asignar Acciones', 'background:#31A7F5;font-size:1.2em;');
  // console.log(props);
  // console.log(props.fixture.finished);
  if (props.fixture.finished) {
    if (!props.fixture.events) {
      return null;
    }
    /**
     * This Condition works to Baseball Matches
     * **/
    if (gameInfo.sport_id === 4) {
      if (props.fixture.stats === undefined || !props.fixture.fixture_event) {
        listEvents = <div className="acciones-container" />;
      } else {
        let passingAway = JSON.parse(props.fixture.fixture_event.passing_away);
        let passingHome = JSON.parse(props.fixture.fixture_event.passing_home);
        console.log('%c events Baseball', 'color:rgb(105, 67, 124); font-size:1.2em;');
        // console.log(passingAway);
        // console.log(passingHome);
        //console.log(props.fixture.fixture_event);
        listEvents = (
          <div className="acciones-container flex-container column baseball">
            <div className="away-stats">
              <div className="stats team away">{props.fixture.away_team.abbreviation}</div>
              <div className="stats player away">
                {!passingAway ? '0' : passingAway.name} {!passingAway ? '0' : passingAway.position}
              </div>
              <div className="stats away">{`${!passingAway ? '0' : passingAway.home_runs}-H, ${
                !passingAway ? '0' : passingAway.runs
                }-R`}</div>
            </div>
            <div className="home-stats">
              <div className="stats team home">{props.fixture.home_team.abbreviation}</div>
              <div className="stats player home">
                {!passingHome ? '0' : passingHome.name} {!passingHome ? '0' : passingHome.position}
              </div>
              <div className="stats home">{`${!passingHome ? '0' : passingHome.home_runs}-H, ${
                !passingHome ? '0' : passingHome.runs
                }-R`}</div>
            </div>
          </div>
        );
      }
    } else if (gameInfo.sport_id === 1) {
      /**
       * This Works to Futbol Soccer
       * **/
      listEvents = props.fixture.events.map((res, i) => (
        <div className="acciones-container" key={i}>
          <div className="minute">{res.minute}'</div>
          <div
            className={`team ${
              res.team === 'visitorteam' || res.team === 'awayteam' ? `away` : `home`
              }`}
          >
            {res.team === 'visitorteam' || res.team === 'awayteam'
              ? props.fixture.away_team.abbreviation
              : props.fixture.home_team.abbreviation}
          </div>
          <div className="player">{res.player}</div>
          <div className="type">
            <div className={res.type} />
          </div>
        </div>
      ));
      // listEvents = listEvents.slice(0,4);
    } else if (gameInfo.sport_id === 3) {
      /**
       * This Works to Basketball
       * **/
      let allPasses = [];
      if (props.fixture.stats.passing_home !== undefined &&  props.fixture.stats.passing_away !== undefined && props.fixture.stats.passing_home !== null &&  props.fixture.stats.passing_away !== null ) {
        allPasses = [...props.fixture.stats.passing_home, ...props.fixture.stats.passing_away];
      }

      let maxPasses = _.max(allPasses, (pass) => {
        return pass.points;
      });
      let maxRebounds = _.max(allPasses, (pass) => {
        return pass.rebounds;
      });
      let maxAssist = _.max(allPasses, (pass) => {
        return pass.assists;
      });
      // console.log('BAsket');
      // console.log(maxPassesHome)
      listEvents = (
        <div>
          <div className="acciones-container stats">
            <div className={`type`}>Puntos:</div>
            <div className={`player`}>{maxPasses.name}</div>
            <div className={`pos `}>${maxPasses.pos}</div>
            {/*<div className="home team"> {props.fixture.home_team.abbreviation}</div>*/}
            <div className={`points`}>{maxPasses.points} pts.</div>
            {/*<div className={`rebounds home`}>{maxPasses.rebounds} reb. </div>*/}
          </div>
          <div className="acciones-container stats">
            <div className={`type`}>Rebotes:</div>
            <div className={`player`}>{maxRebounds.name}</div>
            <div className={`pos `}>${maxRebounds.pos}</div>
            {/*<div className="home team"> {props.fixture.home_team.abbreviation}</div>*/}
            {/*<div className={`points home`}>{maxRebounds.points} pts.</div>*/}
            <div className={`points `}>{maxRebounds.rebounds} reb. </div>
          </div>
          <div className="acciones-container stats">
            <div className={`type`}>Asistencias:</div>
            <div className={`player`}>{maxAssist.name}</div>
            <div className={`pos `}>${maxAssist.pos}</div>
            {/*<div className="home team"> {props.fixture.home_team.abbreviation}</div>*/}
            <div className={`points `}>{maxAssist.assists} asis.</div>
            {/*<div className={`rebounds home`}>{maxAssist.rebounds} reb. </div>*/}
          </div>
        </div>
      );
      // listEvents = listEvents.slice(0,4);
    } else if (gameInfo.sport_id === 2) {
      /**
       * This Condition works to Americano Matches
       * **/
      // console.log('AMERICANO');
      // console.log(props.fixture);
      // console.log(props.fixture.stats);
      listEvents = (
        <div>
          {props.fixture.hasOwnProperty('stats') &&
            props.fixture.stats.hasOwnProperty('passing') &&
            props.fixture.stats.passing ? (
              <div className="acciones-container stats">
                <div className={'type'}>Pass:</div>
                <div className={`player`}>{props.fixture.stats.passing.name}</div>
                <div className={`team ${props.fixture.stats.passing.team}`}>
                  {props.fixture.stats.passing.team === 'home'
                    ? props.fixture.home_team.abbreviation
                    : props.fixture.away_team.abbreviation}
                </div>
                <div className={`yards ${props.fixture.stats.passing.team}`}>
                  {props.fixture.stats.passing.yards} yds.
              </div>
              </div>
            ) : null}
          {props.fixture.hasOwnProperty('stats') &&
            props.fixture.stats.hasOwnProperty('rushing') &&
            props.fixture.stats.rushing ? (
              <div className="acciones-container stats">
                <div className={'type'}>Rush:</div>
                <div className={`player`}> {props.fixture.stats.rushing.name}</div>
                <div className={`team ${props.fixture.stats.rushing.team}`}>
                  {props.fixture.stats.rushing.team === 'home'
                    ? props.fixture.home_team.abbreviation
                    : props.fixture.away_team.abbreviation}
                </div>
                <div className={`yards ${props.fixture.stats.rushing.team}`}>
                  {props.fixture.stats.rushing.yards} yds.
              </div>
              </div>
            ) : null}
          {props.fixture.hasOwnProperty('stats') &&
            props.fixture.stats.hasOwnProperty('receiving') &&
            props.fixture.stats.receiving ? (
              <div className="acciones-container stats">
                <div className={'type'}>Rec:</div>
                <div className={`player`}> {props.fixture.stats.receiving.name}</div>
                <div className={`team ${props.fixture.stats.receiving.team}`}>
                  {props.fixture.stats.receiving.team === 'home'
                    ? props.fixture.home_team.abbreviation
                    : props.fixture.away_team.abbreviation}
                </div>
                <div className={`yards ${props.fixture.stats.receiving.team}`}>
                  {props.fixture.stats.receiving.yards} yds.
              </div>
              </div>
            ) : null}
        </div>
      );
    } else {
      /**
       * This Works For all the others
       * **/
      listEvents = props.fixture.events.map((res, i) => (
        <div className="acciones-container" key={i}>
          <div className="minute">{res.minute}'</div>
          {/*<div className={'type'}><div className={res.type}></div></div>*/}
          <div
            className={`team ${
              res.team === 'visitorteam' || res.team === 'awayteam' ? `away` : `home`
              }`}
          >
            {res.team === 'visitorteam' || res.team === 'awayteam'
              ? props.fixture.away_team.abbreviation
              : props.fixture.home_team.abbreviation}
          </div>
          <div className="player">{res.player}</div>
        </div>
      ));
      // listEvents = listEvents.sliceslice(0,4);
    }
    conditionalComponent = <div className="acciones">{listEvents}</div>;
    // console.log('Partido ya finalizo', props, props.fixture);
  } else {
    /**
     * Not Finished
     */
    if (props.fixture.enabled) {
      // let marcador = props.fixture;
      // let resultados = Object.keys(props.resultadosEnvivo).map(key => props.resultadosEnvivo[key]);
      // console.log('Found it', resultados);
      // resultados = resultados.find(resultado => {
      //   // console.log(resultado.feed_id, marcador.feed_id);
      //   if (resultado.feed_id === marcador.feed_id){
      //     return resultado.feed_id
      //   }
      // });
      // console.log('Results Live', props);
      if (!props.fixture.events) {
        return null;
      }
      let listEvents = props.fixture.events !== undefined ? props.fixture.events : [];
      /**
       * This Works on Basketball
       * **/
      if (gameInfo.sport_id === 3) {
        // console.log('Basketball', props.fixture.events);
        listEvents = props.fixture.events.map((res, i) => (
          <div className="acciones-container" key={i}>
            <div className="time">{res.time}'</div>
            <div className="score">
              {res.awayscore}-{res.localscore}
            </div>
            <div className="description">{res.description}</div>
          </div>
        ));
      } else if (gameInfo.sport_id === 1) {
        /**
         * This Works on Soccer
         * **/
        listEvents = listEvents.map((res, i) => {
          // console.log(res, props)
          return (
            <div className="acciones-container" key={i}>
              <div className="minute">{res.minute}'</div>
              <div className={'type'}>
                <div className={res.type} />
              </div>
              <div
                className={`team ${
                  res.team === 'visitorteam' || res.team === 'awayteam' ? `away` : `home`
                  }`}
              >
                {res.team === 'visitorteam' || res.team === 'awayteam'
                  ? props.fixture.away_team.abbreviation
                  : props.fixture.home_team.abbreviation}
              </div>
              <div className="player">{res.player}</div>
            </div>
          );
        });
      } else if (
        /**
         * This Works on Americano
         * **/
        gameInfo.sport_id === 2 ||
        gameInfo.sport_id === 4
      ) {
        listEvents = listEvents.map((res, i) => {
          // console.log(res, props);
          return (
            <div className="acciones-container" key={i}>
              <div className="minute">{res.minute}'</div>
              <div className={'type'}>
                <div className={res.type} />
              </div>
              <div
                className={`team ${
                  res.team === 'visitorteam' || res.team === 'awayteam' ? `away` : `home`
                  }`}
              >
                {res.team === 'visitorteam' || res.team === 'awayteam'
                  ? props.fixture.away_team.abbreviation
                  : props.fixture.home_team.abbreviation}
              </div>
              <div className="player">{res.player}</div>
            </div>
          );
        });
      }
      conditionalComponent = <div className="acciones">{listEvents}</div>;
    } else {
      // console.log('Results Live', resultados,props);
      let listEvents = props.fixture.events !== undefined ? props.fixture.events : null;
      // let listEventsAway;
      // let listEventsHome;
      if (listEvents === null) {
        conditionalComponent = <div className="acciones flex-col col-no-padding">{listEvents}</div>;
      } else {
        if (props.fixture.away_team.sport_id === 4) {
          if (!props.fixture.events) {
            listEvents = <div className="acciones-container flex-row" />;
          } else {
            //console.log(props.fixture.events);
            // let passingAway = JSON.parse(props.fixture.fixture_event.passing_away);
            // let passingHome = JSON.parse(props.fixture.fixture_event.passing_home);
            console.log('%c events Baseball', 'color:rgb(105, 67, 124); font-size:1.2em;');

            listEvents = props.fixture.events.map((event, i) => {
              let isHome = event.team === 'hometeam';
              return (
                <div className="acciones-container flex-row baseball" key={i}>
                  <div className={isHome ? 'home-stats' : 'away-stats'}>
                    <div className="flex-row row-center row-no-padding">
                      <div className={`stats flex-col col-20 team ${isHome ? 'home' : 'away'}`}>
                        {isHome
                          ? props.fixture.home_team.abbreviation
                          : props.fixture.away_team.abbreviation}
                      </div>
                      <div className={`flex-col small`}>{event.play}</div>
                      <div className={`stats flex-col col-10 `}>{event.inning}</div>
                    </div>
                  </div>
                </div>
              );
            });
          }
        } else if (props.fixture.away_team.sport_id === 3) {
          /**
           * Basketball
           */
          listEvents = listEvents.map((res, i) => {
            // console.log(res, props)
            return (
              <div className="acciones-container flex-row" key={i}>
                <div className="minute">{res.time} </div>
                {/*<div className={`team ${res.team === 'visitorteam' ? `away` : `home` }`}>{res.team === 'visitorteam' ? props.fixture.away_team.abbreviation : props.fixture.home_team.abbreviation}</div>*/}
                <div className="description">{res.description}</div>
                <div className="score">
                  {res.localscore}-{res.awayscore}
                </div>
              </div>
            );
          });
        } else {
          listEvents = listEvents.map((res, i) => {
            // console.log(res, props)
            return (
              <div className="acciones-container flex-row " key={i}>
                <div className="minute">{res.minute}'</div>
                <div
                  className={`team ${
                    res.team === 'awayteam' || res.team === 'visitorteam' ? `away` : `home`
                    }`}
                >
                  {res.team === 'awayteam' || res.team === 'visitorteam'
                    ? props.fixture.away_team.abbreviation
                    : props.fixture.home_team.abbreviation}
                </div>
                <div className="player">{res.player}</div>
                <div className={'type'}>
                  <div className={res.type} />
                </div>
              </div>
            );
          });
        }
        conditionalComponent = <div className="acciones flex-col col-no-padding">{listEvents}</div>;
      }
    }
  }
  return conditionalComponent;
}

/**
 *This function return the TablaMarcadores component of all the available fixtures
 **/
function TablaMarcadores(props) {
  console.log(props);
  // let listaActions;
  // let liveFixture_;
  // let liveResult_;
  // let partidosEnvivoSocket;
  let liveExist = false;
  let gameInfo = props.poolInfo.hasOwnProperty('pool') ? props.poolInfo.pool : props.poolInfo;

  console.log(gameInfo);

  // let tournamentInstance = gameInfo.tournament_instances[0].id;

  // let resultadosEnvivoSocket = [] ;
  let listFixtures = props.fixtures;
  // console.log(listFixtures[0]);
  // console.log('%c Tablas de Marcadores','background:#7BD900; font-size:20px;');
  // console.log(props);
  /**
   * Mapp LiveFixtures available
   * **/
  // Aqui se puede generar la lista de las acciones si el partido esta en vivo
  listFixtures = listFixtures.filter((fixt) => {
    if (fixt.period === null) {
      return fixt;
    }
  });
  // console.log(listFixtures);
  listFixtures = listFixtures.map((fixt, i) => {
    return (
      <div key={i} className="marcador-partido-item col-md-12 col-lg-6">
        <div className="marcador-panel flex-col col-no-padding border-gradient-marcador">
          <div className="backBlack">
            <div className="marcador-titulo flex-row ">
              <div className="flex-col col-10  col-center text-center">
                <img
                  className="marcadores-ball-icon"
                  src={
                    gameInfo.sport.id === 1
                      ? Futbol
                      : gameInfo.sport.id === 2
                        ? Americano
                        : gameInfo.sport.id === 3
                          ? Basketball
                          : gameInfo.sport.id === 4
                            ? Baseball
                            : null
                  }
                />
              </div>
              {
                <LabelTime
                  fixture={fixt}
                  fixtureId={fixt.id}
                // resultadosEnvivo={resultadosEnvivoSocket}
                />
              }
            </div>
            <div className="marcador-container flex-container">
              <div className="marcador-equipos flex-col col-no-padding">
                {!fixt.enabled ? (
                  (fixt.hasOwnProperty('stats') &&
                    fixt.stats.hasOwnProperty('awayq4') &&
                    fixt.stats.awayq4 !== null) ||
                    (fixt.hasOwnProperty('stats') &&
                      fixt.stats.hasOwnProperty('awayp1') &&
                      fixt.stats.awayp1) ? (
                      <div className="headerTimesFixture">
                        <div className="team-label " />
                        <div className="marcadores col-no-padding flex-col">
                          <div className="score-team flex-row row-center">
                            <span className="flex-col text-center">1</span>
                            <span className="flex-col text-center ">2</span>
                            <span className="flex-col text-center ">3</span>
                            <span className="flex-col text-center ">4</span>
                            <span className="flex-col text-center ">T</span>
                          </div>
                        </div>
                      </div>
                    ) : fixt.hasOwnProperty('stats') && fixt.stats.hasOwnProperty('score_away_first_half') && (fixt.stats.score_away_first_half !== null && fixt.stats.score_away_first_half !== undefined) ?
                      (
                        <div className="headerTimesFixture">
                          <div className="team-label" />
                          <div className="marcadores col-no-padding flex-col">
                            <div className="score-team flex-row row-center">
                              <span className="flex-col text-center">1</span>
                              <span className="flex-col text-center">2</span>
                              <span className="flex-col text-center" style={{ padding: '0 7px' }}>T</span>
                            </div>
                          </div>
                        </div>
                      ) : fixt.home_team.sport_id === 9 ? (
                        <div className="headerTimesFixture">
                          <div className="team-label" />
                          <div className="marcadores col-no-padding flex-col">
                            <div className="score-team flex-row row-center">
                              <span className="flex-col text-center">1</span>
                              <span className="flex-col text-center">2</span>
                              <span className="flex-col text-center">3</span>
                              <span className="flex-col text-center">4</span>
                              <span className="flex-col text-center">T</span>
                            </div>
                          </div>
                        </div>
                      ) : fixt.home_team.sport_id !== 4 ? (
                        <div className="headerTimesFixture">
                          <div className="team-label" />
                          <div className="marcadores col-no-padding flex-col">
                            <div className="score-team" />
                            <span className="total">T</span>
                          </div>
                        </div>
                      ) : gameInfo.closed || gameInfo.upcoming ? (
                        <div className="headerTimesFixture">
                          <div className="team-label" />
                          <div className="marcadores col-no-padding flex-col">
                            <span className="record-header flex-row row-no-padding row-center">
                              <span className="flex-col text-center">R</span>
                              <span className="flex-col text-center">H</span>
                              <span className="flex-col text-center">E</span>
                            </span>
                          </div>
                        </div>
                      ) : (
                              <div className="headerTimesFixture">
                                <div className="team-label" />
                                <div className="marcadores col-no-padding flex-col">
                                  <span className="record-header flex-row row-no-padding row-center">
                                    <span className="flex-col text-center">R</span>
                                    <span className="flex-col text-center">H</span>
                                    <span className="flex-col text-center">E</span>
                                    <span className="flex-col text-center">T</span>
                                  </span>
                                </div>
                              </div>
                            )
                ) : (
                    <div className="headerTimesFixture">
                      <div className="team-label" />
                      <div className="marcadores col-no-padding flex-col">
                        <div className="score-team" />
                        <span className="total">T</span>
                      </div>
                    </div>
                  )}
                <div className="equipo home flex-row row-no-padding">
                  <div className="team-label col-25 flex-col col-center">
                    <div className="flex-row row-center">
                      <div className="team flex-col col-no-padding col-center">
                        {fixt.home_team.logo === null ? (
                          <div />
                        ) : (
                            <i className={`${fixt.away_team.logo} home`} />
                          )}
                        <span>{fixt.away_team.abbreviation}</span>
                      </div>
                      <div className="flex-col col-no-padding">
                        <div className="flex-row">
                          {props.poolInfo.hasOwnProperty('survivor') &&
                            props.poolInfo.survivor.status === 'live' &&
                            !fixt.enabled ? null : (
                              <span className="linea">
                                {fixt.advantage === null
                                  ? ''
                                  : fixt.favorite === 'away'
                                    ? fixt.advantage
                                    : ''}
                              </span>
                            )}
                        </div>
                        <div className="flex-row">
                          {fixt.stats &&
                            fixt.stats.hasOwnProperty('awaydrive') &&
                            fixt.stats.awaydrive ? (
                              <img
                                className="marcadores-ball-icon"
                                src={
                                  gameInfo.sport.id === 1
                                    ? Futbol
                                    : gameInfo.sport.id === 2
                                      ? Americano
                                      : gameInfo.sport.id === 3
                                        ? Basketball
                                        : gameInfo.sport.id === 4
                                          ? Baseball
                                          : null
                                }
                              />
                            ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <AsignarMarcador
                      typeTeam={'away'}
                      fixtureId={fixt.id}
                      fixture={fixt}
                      // partidosEnvivo={partidosEnvivoSocket}
                      // resultadosEnvivo={props.liveFixturesData.results}
                      poolInfo={gameInfo}
                    />
                  }
                </div>
                <div className="equipo home flex-row no-padding row-no-padding">
                  <div className="team-label col-25 flex-col col-center">
                    <div className="flex-row row-center ">
                      <div className="team col-no-padding col-center">
                        {fixt.home_team.logo === null ? (
                          <div />
                        ) : (
                            <i className={`${fixt.home_team.logo} home`} />
                          )}
                        <span>{fixt.home_team.abbreviation}</span>
                      </div>
                      <div className="flex-col col-no-padding">
                        <div className="flex-row">
                          {props.poolInfo.hasOwnProperty('survivor') &&
                            props.poolInfo.survivor.status === 'live' &&
                            !fixt.enabled ? null : (
                              <span className="linea">
                                {fixt.advantage === null
                                  ? ''
                                  : fixt.favorite === 'home'
                                    ? fixt.advantage
                                    : ''}
                              </span>
                            )}
                        </div>
                        <div className="flex-row">
                          {fixt.stats &&
                            fixt.stats.hasOwnProperty('homedrive') &&
                            fixt.stats.homedrive ? (
                              <img
                                className="marcadores-ball-icon"
                                src={
                                  gameInfo.sport.id === 1
                                    ? Futbol
                                    : gameInfo.sport.id === 2
                                      ? Americano
                                      : gameInfo.sport.id === 3
                                        ? Basketball
                                        : gameInfo.sport.id === 4
                                          ? Baseball
                                          : null
                                }
                              />
                            ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    <AsignarMarcador
                      typeTeam={'home'}
                      fixtureId={fixt.id}
                      fixture={fixt}
                      // partidosEnvivo={partidosEnvivoSocket}
                      // resultadosEnvivo={props.liveFixturesData.results}
                      poolInfo={gameInfo}
                    />
                  }
                </div>
              </div>
              <div className="marcador-acciones flex-col col-no-padding">
                <AsignarAcciones
                  fixtureId={fixt.id}
                  // resultadosEnvivo={props.liveFixturesData.results}
                  // partidosEnvivo={partidosEnvivoSocket}
                  poolInfo={props.poolInfo}
                  fixture={fixt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="lista-marcadores border-gradient">
      <div className="row overflow overflow-y overflow-picks-grupo">
        {listFixtures}
        {liveExist ? <Loading label="Cargando marcadores en Vivo" /> : null}
      </div>
    </div>
  );
}

class Marcadores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: this.props.logged,
      loaded: this.props.loaded,
      userData: this.props.userData,
      fixtures: this.props.fixtures,
      poolInfo: this.props.poolInfo,
      liveFixturesData: this.props.liveFixturesData,
      partidosLiveQuina: null,
    };
    moment.locale('es');
    this.handleLiveFixtures = this.handleLiveFixtures.bind(this);
  }

  handleLiveFixtures(fixt) {
    this.setState({
      partidosLiveQuina: fixt,
    });
  }

  /*******************
   * LifeCycle Methods
   ********************/
  componentDidMount() {
    // console.log('Mounted');
    this.props.handleMaxHeight();
    // console.log(
    //   this.state.fixtures.sort(
    //   firstBy('sort_status', 1)
    //     .thenBy('deadline',-1)
    // ));

    // let sortedFixtures = this.state.fixtures.sort(
    //   firstBy('sort_status', 1)
    //     .thenBy('deadline',-1));
    // this.setState({fixtures, sortedFixtures});
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.poolWeekSelected !== prevProps.poolWeekSelected) {
    //   this.setState({
    //     fixtures: this.props.fixtures
    //   });
    // }
  }

  render() {
    let fixturesList = [...this.props.fixtures];

    let conditionalComponent = null;
    if (this.props.loaded) {
      conditionalComponent = (
        <TablaMarcadores
          logged={this.state.logged}
          poolInfo={this.props.poolInfo}
          fixtures={fixturesList.sort(firstBy('closed', -1).thenBy('sort_status', 1))}
          handleLiveFixtures={this.handleLiveFixtures}
        />
      );
    } else {
      conditionalComponent = <Loading label="Cargando los marcadores actualizados..." />;
    }
    return conditionalComponent;
  }
}

export default Marcadores;
