import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'


// Utils
import { getCurrencyFormatSmall } from '../../../../../../utils/currencyFormat'
import Particles from './Particles'

const CurrencyText = ({ start, end, duration, animated, delay, fps   }) => {
  const [num, setNum] = useState(0)

  const tick = useRef()

  useEffect(() => {
    if (animated) {
      const realFPS = 1000 / fps
      const stepsCount = duration * 1000 / realFPS
      const step = (end - start) / stepsCount

      window.setTimeout(() => {
        tick.current = window.setInterval(() => {
          setNum(prev => {
            const next = Math.round(prev + step)

            if (next >= end) {
              window.clearInterval(tick.current)
              tick.current = null
            }

            return next <= end ? next : end
          })
        }, realFPS)
      }, delay)
    }
  }, [animated, delay, end])

  let currency = getCurrencyFormatSmall(Math.round(num))

  return (
    <Content>
      <Particles show={num > 1} count={150} />
      <Text>{currency}</Text>
    </Content>
  )
}

CurrencyText.defaultProps = {
  start: 0,
  end: 0,
  duration: 1.5,
  animated: true,
  delay: 500,
  fps: 60
}

const Content = styled.div`
  position: relative;
  z-index: 2;
`

const Text = styled.div`
  background: #F9E799;
  background: linear-gradient(to bottom, #F9E799 41%, #C8AE72 50%, #F2E3A3 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin: 0.4em 0;
  font-size: 2.4em;
`

export default CurrencyText
