import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import API from '../../utils/api';

import { ContentTerminos } from '../Terminos/Terminos';

const NewTosConfirmation = ({
  isLogged,
  isNewTosConfirmed,
  handleTosConfirmation,
  isLegals
}) => {
  let [tosChecked, handleTos] = useState(false);
  let [submitting, handleSubmit] = useState(false);

  const submitNewTosConfirmation = () => {
    console.log('Submit New Tos');
    handleSubmit(true);

    // setTimeout( () => {
    //   handleSubmit( false );
    //   handleTosConfirmation( true );
    // } , 1500 );

    API.updateUserTos({ tos: tosChecked ? 1 : 0 })
      .then(response => {
        console.log(response);
        if (response.data.success) {
          handleSubmit(false);
          handleTosConfirmation(true);
        }
        else {
          console.error('is an error', response);
          handleSubmit(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }


  if (isNewTosConfirmed || isLegals || !isLogged) {
    return null
  }
  else {
    return (
      <Modal
        show={true}
        bsSize="lg"
        backdropClassName="confirmationBackdrop"
        dialogClassName="confirmationModal"
        className='flex-container justify align column'
      >
        <div className='flex-row row-no-padding responsive-sm row-center panel-confirmation'>
          <div className='flex-col col-80 col-offset-10 panel-pickwin'>
            <div className='flex-row row-no-padding row-center' id='panelTitle'>
              <span> Actualizamos nuestros términos y condiciones </span>
            </div>
            <div className='flex-row row-no-padding responsive-sm'>
              <div className='flex-col col-80 col-offset-10 col-center '>
                <div className='flex-row '>
                  <div className='flex-col '>
                    <p>Estimado Pickwinero,</p>
                    <p> Hemos actualizado nuestros términos y condiciones los cuales te pedimos revisar y si estás de acuerdo, aceptarlos para poder seguir hanciendo uso de Pickwin. </p>
                    <p> <strong> Estos entrarán en vigor el día 10 de julio del 2019 a las 00:00 horas de la Ciudad de México. </strong> </p>
                  </div>
                </div>
                <div className='flex-row'>
                  <div className='overflow overflow-y overflow-300 no-border'>
                    <div className='flex-col'>
                      <div className='responsive-sm flex-row'>
                        <ContentTerminos isExternal />
                      </div>
                      <label className="flex-row row-center" id={'tos'}>
                        <div className="flex-col col-10">
                          <input
                            type="checkbox"
                            name={'tos'}
                            id={`tos`}
                            value={tosChecked}
                            onChange={() => { handleTos(!tosChecked); }}
                          />
                          <div className={`checkbox-custom `} />
                        </div>
                        <div className="flex-col">
                          <span className='flex-row'> He leído los Términos y Condiciones </span>
                        </div>
                      </label>
                      <div className='flex-row row-no-padding'>
                        <Button
                          onClick={() => { submitNewTosConfirmation(); }}
                          disabled={!tosChecked || submitting}
                          className='flex-col btn-pick btn-default callToAction'>
                          {submitting ? <i className='ion-load-a loading small' /> : 'Acepto los nuevos Términos y Condiciones'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default NewTosConfirmation;