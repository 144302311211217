// Libraries
import React from 'react'
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';

// Components
import BracketTennis from './BracketTennis';
import TablaPosiciones from '../TablaPosiciones/TablaPosiciones';
import ChatPanel from '../ChatPanel/ChatPanel';
import Premios from '../Premios';
import BracketTennisGroupPicks from './BracketTennisGroupPicks';
import BracketTennisScores from './BracketTennisScores';

const BracketTennisContainer = (props) => {
  const { selection } = props;
  const [selectedStage, setSelectedStage] = React.useState(props.bracketCurrentStage ? props.bracketCurrentStage.id : props.bracketStages[0].id);

  const handleStageChange = (stage) => {
    setSelectedStage(stage);
  }

  // Render
  switch (Number(selection)) {
    case 0: // Bracket
      return (
        <div className=" flex-row multiple-quiniela-info principal">
          <div className="flex-col">
            <BracketTennis {...props} />
          </div>
        </div>
      );

    case 1: // Positions
      return (
        <div className="flex-row multiple-quiniela-info principal">
          <div className="first-section col-xs-12 col-sm-6">
            <TablaPosiciones
              isVisible={true}
              actualPage={props.posActualPage}
              changeSearchNick={props.changeSearchNick}
              entryId={props.selectedEntry}
              entry={props.selectedEntryData}
              fetchSearchableStandings={props.fetchSearchableStandings}
              loaded={props.loaded}
              nextPageStandings={props.nextPageStandings}
              poolInfo={props.bracketInfo}
              activeStage={props.activeStage}
              posiciones={props.standings}
              resetNickValue={props.resetNickValue}
              siguientePagPositions={props.siguientePagPositions}
              selectedEntry={props.selectedEntry}
              standings={props.standings}
              submittingSearch={props.submittingSearch}
              type="bracket"
              userData={props.userData}
              activeSort={props.activeSort}
              getAganar={props.getAganaBracketTennis}
              premios={props.bracketPrizes}
              sortPositionList={props.sortPositionList}
              tiesDistribution={props.bracketTiesDistribution}
              calculateTiesPrizes={props.calculateTiesPrizes}
            />
          </div>

          <div className="second-section col-xs-12 col-sm-3">
            {props.isLogged ? (
              <ChatPanel
                gameType="bracket"
                entries={props.entries}
                handleMessage={props.handleMessage}
                handleMaxHeight={props.handleMaxHeight}
                loaded={props.isLogged}
                logged={props.isLogged}
                newMessage={props.newMessage}
                selectedEntry={props.selectedEntry}
                resetMessage={props.resetMessage}
                quinielaID={props.bracketInfo.id}
                sendMessage={props.sendMessage}
                userAvatar={props.isLogged ? props.userData : null}
                userNick={props.isLogged ? props.userData.nick : null}
                withRegister={props.withRegister}
              />
            ) : (
              <div className="message-not-logged">
                {' '}
                Necesitas Ingresar para poder usar el chat
              </div>
            )}
          </div>

          <div className="col-xs-12 col-sm-3 border-gradient" style={{margin: '14px 8px'}}>
            <Premios
              weeks={null}
              estado={props.loaded}
              loaded={props.loaded}
              poolInfo={props.bracketInfo}
              poolWeekSelected={null}
              premios={props.bracketPrizes}
              standGroups={props.bracketGroupPicksSeries}
              standings={props.standings}
              type="lobby"
              game="series"
            />
          </div>
        </div>
      );

    case 2: // Picks de grupo
      return (
        <BracketTennisGroupPicks
          currentStage={props.bracketCurrentStage}
          bracketStages={props.bracketStages}
          matches={props.bracketMatches}
          selectedEntryData={props.selectedEntryData}
          bracketInfo={props.bracketInfo}
          entryId={props.selectedEntry}
          groupPicks={props.groupPicks}
          // eventHandlers
          onStageChange={props.onStageChange}
          loadMoreStandings={props.loadMoreStandings}
        />
      );

    case 3: // Marcadores
      return (
        <BracketTennisScores
          matches={props.bracketMatches}
          bracketCurrentStage={props.bracketCurrentStage}
          bracketStages={props.bracketStages}
          bracketInfo={props.bracketInfo}
        />
      );
  }
}

export default BracketTennisContainer;