import React from 'react';
import firstBy from 'thenby';
import API from './api';
import Futbol from '../assets/images/FutbolBall-50.png';
import Americano from '../assets/images/AmericanoBall-50.png';
import Basketball from '../assets/images/BasketballBall-50.png';
import Baseball from '../assets/images/BaseballBall-50.png';
import Golf from '../assets/images/GolfBall-50.png';
import Tenis from '../assets/images/TennisBall-50.png';
import Box from '../assets/images/box.png';
import F1 from '../assets/images/box.png';
import Hockey from '../assets/images/Hockey.png';
import utils from './utils';
import $ from 'jquery';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';

const quinielaUtils = {
  mapUnsavedPicks: function (picks, picksProps, ties, entryID) {
    console.log('%c Map Unsaved Picks ', ' background: #78BC28; ');
    console.log(picks, picksProps, ties, entryID);
    entryID = entryID === null ? '' : '-' + entryID;

    let listPicks = picks.map((pick) => {
      return _.values(pick)[0];
    });

    // let listTies = ties.map(tie => {
    //   return _.values(tie)[0];
    // });

    if (listPicks.length > 0) {
      listPicks.forEach((pick) => {
        let searchThis = `#picksContainer${entryID}  #propFixture-${pick.fixture_id}-${pick.pick}`;
        let deselectLabelHome = `#picksContainer${entryID} #propFixture-${pick.fixture_id}-home`;
        let deselectLabelTie = `#picksContainer${entryID} #propFixture-${pick.fixture_id}-tie`;
        let deselectLabelAway = `#picksContainer${entryID} #propFixture-${pick.fixture_id}-away`;

        $(deselectLabelHome).removeClass('selected');
        $(deselectLabelTie).removeClass('selected');
        $(deselectLabelAway).removeClass('selected');

        // console.log(searchThis);
        let labelSelected = $(searchThis);

        $(labelSelected).addClass('selected');

        let inputSelected = $(searchThis).find('input');

        // console.log(inputSelected);

        $(inputSelected).prop('checked', true);
      });
    }

    // availableTies = _.values(availableTies)[0];

    // console.log(listTies, availableTies);
    console.log(listPicks, picks);
  },
  /**
   * This functon is called in Quinielas COmponent to load next picks
   *  when the socket receives a fixture that belong to the current pool
   *  it receives an array of ids that belong to the pool
   *  and checks it after the find process
   * @param fixturesToLoad
   */
  loadNextPicksStandings: function (fixturesToLoad) {
    // let loadingNextPicks = true;
    let newStandings = this.state.standings;
    API.loadNextPicks(this.state.poolInfo.pool.id, fixturesToLoad).then((response) => {
      let data = response.data;
      let newPicks = data.picks;
      if (!newStandings.items.length) {
        return;
      }
      newStandings.items.forEach((_standing) => {
        if (!newPicks.hasOwnProperty(_standing.entry_id)) {
          return;
        }
        var _newPicks = newPicks[_standing.entry_id];
        if (_standing.picks) {
          _standing.picks = _standing.picks.concat(_newPicks);
        } else {
          _standing.picks = _newPicks;
        }
      });
      this.setState(
        {
          standings: newStandings,
          liveFixturesData: Date.now(),
          updatedStandings: true,
          nextPicks: response.data,
        },
        () => {
          console.log('NewPicks received');
        }
      );
      // loadingNextPicks = false;
    });
  },
  loadNextPicksPropsStandings: function (propsToLoad) {
    // let loadingNextPicksProps = true;
    let newStandings = this.state.standings;
    API.loadNextPicksProps(this.state.poolInfo.pool.id, propsToLoad).then((response) => {
      let data = response.data;
      let newPicksProps = data.picksProps;
      if (!newStandings && !newStandings.items.length) {
        console.log('no standings');
        return;
      }
      newStandings.items.forEach((_standing) => {
        if (!newPicksProps.hasOwnProperty(_standing.entry_id)) {
          return;
        }
        let _newPicksProps = newPicksProps[_standing.entry_id];
        if (_standing.picks_props) {
          // console.log(stangi)
          _standing.picks_props = _standing.picks_props.concat(_newPicksProps);
        } else {
          _standing.picks_props = _newPicksProps;
        }
      });
      this.setState(
        {
          standings: newStandings,
          liveFixturesData: Date.now(),
          updatedStandings: true,
          nextProps: response.data,
        },
        () => {
          console.log('NewProps received');
        }
      );
      // loadingNextPicksProps = false;
    });
  },
  loadNextPicksPicksGrupo: function (fixturesToLoad) {
    console.log('Load Picks PicksGrupo');
    // console.log(!this.props.nextPicks.hasOwnProperty('picks'));
    let newPicksGrupal = this.state.picksGrupal;
    if (this.props.nextPicks === null) {
      return;
    }
    // console.log('this.')
    let data = this.props.nextPicks;
    let newPicks = data.picks;
    let fixtures = this.props.fixtures;
    if (!newPicksGrupal.items.length) {
      return;
    }
    newPicksGrupal.items.forEach((_standing) => {
      if (!newPicks && !newPicks.hasOwnProperty(_standing.entry_id)) {
        return;
      }
      let _newPicks = newPicks[_standing.entry_id];
      if (_newPicks === undefined) {
        return;
      }
      if (!_standing.hasOwnProperty('picks')) {
        _standing.picks = {};
      }
      _newPicks.forEach((newPick) => {
        if (_standing.picks && _standing.picks.hasOwnProperty(newPick.fixture_id)) {
          _standing.picks[newPick.fixture_id].pick = newPick.pick;
          let fixture = fixtures.find((_fixture) => _fixture.id === newPick.fixture_id);
          if (newPick.pick === 'tie') {
            _standing.picks[newPick.fixture_id].pres = 'EMP';
          } else if (fixture && newPick.pick === 'home') {
            _standing.picks[newPick.fixture_id].pres = fixture.home_team.abbreviation;
          } else if (fixture && newPick.pick === 'away') {
            _standing.picks[newPick.fixture_id].pres = fixture.away_team.abbreviation;
          }
        }
      });
    });
    // newPicksGrupal.items  = _newPicks;
    this.setState(
      {
        picksGrupal: newPicksGrupal,
      },
      () => {
        console.log('Loaded PicksGrupal After');
      }
    );
  },
  loadNextPicksPicksPropsGrupo: function (propsToLoad) {
    console.log('Load Props PicksGrupo');
    let fixtureProps = this.props.fixtureProps;
    let newPicksGrupal = this.state.picksGrupal;

    if (this.props.nextProps === null) {
      return;
    }
    let data = this.props.nextProps;
    let newPicks = data.picksProps;
    if (!newPicksGrupal.items.length) {
      return;
    }
    newPicksGrupal.items.forEach((_standing) => {
      if (!newPicks.hasOwnProperty(_standing.entry_id)) {
        return;
      }
      let _newPicks = newPicks[_standing.entry_id];
      if (_newPicks === undefined) {
        return;
      }
      if (!_standing.hasOwnProperty('pick_props')) {
        _standing.pick_props = {};
      }
      _newPicks.forEach((newPickProps) => {
        let picksProps = fixtureProps.find(
          (fixtureProp) => fixtureProp.id === newPickProps.prop_id
        );
        if (_standing.pick_props && _standing.pick_props.hasOwnProperty(newPickProps.prop_id)) {
          _standing.pick_props[newPickProps.prop_id].pick = newPickProps.pick_props;
          if (newPickProps.pick === 'under') {
            if (picksProps.boolean) {
              _standing.pick_props[newPickProps.prop_id].pres = 'SI';
              _standing.pick_props[newPickProps.prop_id].pick = newPickProps.pick;
            } else {
              _standing.pick_props[newPickProps.prop_id].pres = 'MENOR';
              _standing.pick_props[newPickProps.prop_id].pick = newPickProps.pick;
            }
          } else if (newPickProps.pick === 'over') {
            if (picksProps.boolean) {
              _standing.pick_props[newPickProps.prop_id].pres = 'NO';
              _standing.pick_props[newPickProps.prop_id].pick = newPickProps.pick;
            } else {
              _standing.pick_props[newPickProps.prop_id].pres = 'MAYOR';
              _standing.pick_props[newPickProps.prop_id].pick = newPickProps.pick;
            }
          }
        }
      });
    });
    // console.log(newPicksGrupal[0].pick_props)
    this.setState(
      {
        picksGrupal: newPicksGrupal,
      },
      () => {
        console.log('After Update PicksGrupal Picks Props');
      }
    );
    // loadingNextPicks = false;
  },
  /**
   * This function handles the overlay behaviour
   * affter the user copies picks from other entry
   */
  confirmationCopy: function () {
    console.log('handleConfirmationCopy');
    setTimeout(() => {
      this.setState({
        errorCopiar: null,
      });
    }, 1800);
  },
  /**
   * This function disbales the button temporarily when its clicked
   * @param e
   * @param t
   */
  disableClickedButton: function (e, t) {
    // console.log(e.target.parentNode.localName);
    if (t === undefined) {
      t = 1200;
    }
    const clickedElement = e.target;
    clickedElement.classList.add('disabled');
    clickedElement.classList.add('loading');
    //
    if (clickedElement.parentNode.localName === 'button') {
      const parentClickedElement = clickedElement.parentNode;
      console.log(parentClickedElement);
      $(clickedElement).prop('disabled', false);
      parentClickedElement.classList.add('disabled');
      parentClickedElement.classList.add('loading');
      setTimeout(() => {
        parentClickedElement.classList.remove('disabled');
        parentClickedElement.classList.remove('loading');
      }, t);
    }
    setTimeout(() => {
      $(clickedElement).prop('disabled', false);
      clickedElement.classList.remove('disabled');
      clickedElement.classList.remove('loading');
    }, t);
  },
  /**
   * This Function handle the get new  Page
   * In the infiniteScroll function
   * @param el
   * @param currentPage
   */
  nextPageStandings: function () {
    let temporalStandings = this.props.posiciones.items;
    // console.log('%c NEXT PAGE IN STANDINGS','backgorund:#009BF5;font-size:20px;')
    // console.log(this.props.posiciones.items);
    // console.log(this.state.actualPage+1);
    // console.log(this);
    API.fetchStandings(this.state.poolInfo.pool.id, this.state.actualPage + 1).then((response) => {
      console.log(response);
      this.setState((prevState) => {
        let newStandingsFull = response.data;
        let newItems = response.data.items;
        // console.log(newItems);
        newItems.forEach((item) => {
          temporalStandings.forEach((standing) => {
            if (item.entry_id !== standing.entry_id) {
              temporalStandings.push(item);
            }
          });
        });
        newStandingsFull.items = temporalStandings;
        // console.log(newStandingsFull);
        return {
          posiciones: newStandingsFull,
          actualPage: prevState.actualPage + 1,
        };
      });
    });
  },
  /**
   * This Function handle the get new  Page
   * In the infiniteScroll function
   * @param el
   * @param currentPage
   */
  getNewPageScrolling: function (el, currentPage) {
    // const panelHeight = el[0].scrollHeight;
    // const scrollPosition = el.scrollTop();
    // console.log(panelHeight, scrollPosition);
  },
  /**
   * This Function sets the week name
   * based on the weekSelected
   * It returns a string
   * @param weekSelected
   * @returns WeekName
   */
  setWeekName: function (weekSelected) {
    let weekName = null;
    // console.log(this.props.poolWeeks, this.state.poolWeeks);
    if (this.state.poolWeeks === undefined) {
      if (this.props.poolInfo.pool.type === 'week') {
        return null;
      }
      if (this.props.poolWeeks === undefined) {
        return null;
      }
      if (weekSelected === this.props.poolWeeks.current.id) {
        weekName = this.props.poolWeeks.current.week_name;
      } else {
        let findWeek = this.props.poolWeeks.weeks.find((week) => week.id === weekSelected);
        if (findWeek !== undefined) {
          weekName = findWeek.week_name;
        }
      }
    } else {
      if (this.state.poolInfo.pool.type === 'week') {
        return null;
      }
      if (this.state.poolWeeks === undefined) {
        return null;
      }
      if (weekSelected === this.state.poolWeeks.current.id) {
        weekName = this.state.poolWeeks.current.week_name;
      } else {
        let findWeek = this.state.poolWeeks.weeks.find((week) => week.id === weekSelected);
        if (findWeek !== undefined) {
          weekName = findWeek.week_name;
        }
      }
    }

    // console.log('WeekName:',weekName);
    weekName = weekName === undefined ? null : weekName;
    return weekName;
  },
  /**
   * This function sets the logo of the sport fiven the id
   * and returns a component with that info
   * @param sport_id
   * @returns {Component}
   */
  setSport: function (sport_id) {
    return (
      <img
        className="title-ball-icon img-responsive"
        src={
          sport_id === 1
            ? Futbol
            : sport_id === 2
              ? Americano
              : sport_id === 3
                ? Basketball
                : sport_id === 4
                  ? Baseball
                  : sport_id === 5
                    ? Tenis
                    : sport_id === 6
                      ? Golf
                      : sport_id === 7
                        ? F1
                        : sport_id === 8
                          ? Box
                          : sport_id === 9
                            ? Hockey
                            : null
        }
        alt=""
      />
    );
  },
  /**
   * This function Updates the Points given by the fixtures in a Live Quiniela
   * Using the Realtime Data
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsFixturePoints: function (positions) {
    // console.log('%c %s %o %o','background:#7BD900; font-size:20px;','Updating Standings',positions);
    let updatedPositions = [];
    let fixtures = this.state.fixtures === undefined ? this.props.fixtures : this.state.fixtures;
    let poolInfo = this.state.poolInfo === undefined ? this.props.poolInfo : this.state.poolInfo;

    positions.forEach((standing, i) => {
      // console.log(standing);
      let extraPoints = 0;
      let originalPoints = Number(standing.original_points);
      let propsExtraPoints = Number(standing.props_extra_points);
      let possiblyCorrect = Number(standing.total_possibly_correct);

      fixtures.forEach((fixt) => {
        if (fixt.sort_status !== 0) {
          return;
        }
        if (!standing.picks) {
          return;
        }

        let pick = standing.picks.find((pick) => pick.fixture_id === fixt.id);
        if (!pick) {
          return;
        }

        if (poolInfo.pool.point_format === 'line' && pick.pick === fixt.winning_pick_line) {
          extraPoints++;
        }
        if (poolInfo.pool.point_format === 'natural' && pick.pick === fixt.winning_pick) {
          extraPoints++;
        }
        possiblyCorrect = originalPoints + extraPoints + propsExtraPoints;
      });
      // console.log('PossiblyCorrect',possiblyCorrect);
      standing['extra_points'] = extraPoints;
      standing['props_extra_points'] = propsExtraPoints;
      standing['total_possibly_correct'] = possiblyCorrect;
      updatedPositions.push(standing);
      // console.log('Updated',standing);
      // console.log(positions);
    });
    // console.log(updatedPositions);
    return updatedPositions;
  },
  /**
   * This Function Get if the user has a correct answer in his Fixtures
   * It uses the fixture Id to check his pick with the winning Pick
   * @param fixtId
   * @returns boolean
   */
  getWinningPickLive: function (fixtId, typePick) {
    let liveFixt = undefined;
    let livePick;
    // console.log('%c %s %o','background:#36A121; font-size:20px;','Get Winning Pick ', this);
    if (this.state.selectedEntryData === null) {
      // console.log('whithout register');
      return null;
    }
    // console.log(liveFixt.winning_pick== livePick.pick);
    else if (this.props.selectedEntryData === null) {
      // console.log('whithout register');
      return null;
    }

    if (this.props.selectedEntryData !== undefined) {
      livePick = this.props.selectedEntryData.picks.find((pick) => pick.fixture_id === fixtId);
    } else if (this.state.selectedEntryData !== undefined) {
      livePick = this.state.selectedEntryData.picks.find((pick) => pick.fixture_id === fixtId);
    } else {
      return null;
    }

    // console.log(livePick, liveFixt);
    if (liveFixt !== undefined) {
      // console.log('Is LIVE');
      // console.log( typePick, liveFixt.winning_pick, livePick.pick, fixtId);
      // console.log( typePick === livePick.pick, fixtId);
      if (livePick === undefined) {
        return null;
      }
      if (this.state.poolInfo.pool.point_format === 'line') {
        console.log(liveFixt.winning_pick_line, livePick.pick);
        if (livePick.pick === typePick && livePick.pick === liveFixt.winning_pick_line) {
          return true;
        } else if (livePick.pick === typePick && livePick.pick !== liveFixt.winning_pick_line) {
          return false;
        } else {
          return null;
        }
      } else if (this.state.poolInfo.pool.point_format === 'natural') {
        if (livePick.pick === typePick && livePick.pick === liveFixt.winning_pick) {
          return true;
        } else if (livePick.pick === typePick && livePick.pick !== liveFixt.winning_pick) {
          return false;
        } else {
          return null;
        }
      }
    } else {
      // console.log('%c %s %o','background:#36A121; font-size:20px;','Get Winning Pick ');
      // console.log('Finished ');
      // console.log(this.state.fixtures, this.props.fixtures, fixtId);
      liveFixt = this.state.fixtures.find((live) => live.id === fixtId);
      if (liveFixt === undefined) {
        liveFixt = this.props.fixtures.find((live) => live.id === fixtId);
      }
      // console.log(liveFixt);
      if (liveFixt.enabled) {
        return null;
      }

      if (livePick === undefined) {
        return null;
      }
      // console.log( this.state.poolInfo.pool.point_format);
      if (this.state.poolInfo.pool.point_format === 'line') {
        // console.log( 'Home',liveFixt.home_team.abbreviation, 'away',liveFixt.away_team.abbreviation );
        // console.log( livePick.pick === typePick );
        // console.log( livePick.pick  === liveFixt.winning_pick_line );

        if (liveFixt.winning_pick_line === 'tie') {
          return null;
        } else if (livePick.pick === typePick && livePick.pick === liveFixt.winning_pick_line) {
          return true;
        } else if (livePick.pick === typePick && livePick.pick !== liveFixt.winning_pick_line) {
          return false;
        } else {
          return null;
        }
      } else {
        // console.log(liveFixt.winning_pick_line, livePick.pick);

        if (livePick.pick === typePick && livePick.pick === liveFixt.winning_pick) {
          return true;
        } else if (livePick.pick === typePick && livePick.pick !== liveFixt.winning_pick) {
          return false;
        } else {
          return null;
        }
      }
    }
  },
  /**
   * This function Updates the Points of the props in a Live Quiniela
   * Using the Realtime Data
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsPropsPoints: function (positions) {
    // console.log('%c %s %o %o','background:#7BD900; font-size:20px;','Updating Standings PRops',positions);
    let updatedPositions = [];
    let poolInfo = this.state.poolInfo === undefined ? this.props.poolInfo : this.state.poolInfo;
    if (poolInfo.pool.format !== 'mixed') {
      return positions;
    }
    positions.forEach((standing, i) => {
      // console.log(standing.picks_props);
      let extraPoints = Number(standing.extra_points);
      let originalPoints = Number(standing.original_points);
      let propsExtraPoints = 0;
      let possiblyCorrect = standing.total_possibly_correct;
      // console.log(standing);
      this.state.fixtureProps.forEach((_prop) => {
        if (_prop.fixture.sort_status !== 0) {
          return;
        }
        if (!standing.picks_props) {
          return;
        }
        let pickProp = standing.picks_props.find((_pickProp) => _pickProp.prop_id === _prop.id);
        if (!pickProp) {
          return;
        }

        if (pickProp.pick === _prop.winning_pick) {
          propsExtraPoints++;
        }
        possiblyCorrect = originalPoints + extraPoints + propsExtraPoints;
      });
      standing['extra_points'] = extraPoints;
      standing['props_extra_points'] = propsExtraPoints;
      standing['total_possibly_correct'] = possiblyCorrect;
      updatedPositions.push(standing);
    });
    return updatedPositions;
  },
  /**
   * This function Updates the Standing Position in a Live Quiniela
   * Using the Realtime Data
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsPosition: function (positions) {
    // console.log('%c %s %o %o','background:#7BD900; font-size:20px;','Updating Standings Positions',positions);
    let rank = 0;
    let nextRank = 1;
    let maxPoints = Number.MAX_SAFE_INTEGER;
    let updatedPositions = [];
    // console.log('1',positions);
    positions.sort(firstBy('total_possibly_correct', -1).thenBy('entry_id'));
    // console.log('2',positions);
    _.each(positions, (position, i) => {
      if (position.total_possibly_correct < maxPoints) {
        maxPoints = position.total_possibly_correct;
        rank = nextRank;
      }
      // console.log(position);
      // position.standings = {rank: rank, total_possibly_correct: position.total_possibly_correct, points: position.total_possibly_correct };
      position.rank = rank;
      nextRank++;
      updatedPositions.push(position);
    });

    return updatedPositions;
  },
  /**
   * This function Updates the Amount of prize in a Live Quiniela
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsAganar: function (positions, prize_currency) {
    let updatedPositions = [];

    // let newStatePositions = {...this.state.standings};

    let tiesDistribution = this.state.poolInfo.ties_distribution;

    if (!tiesDistribution || !positions) {
      return;
    }

    console.log('Updated StateStandings aganar');
    console.log(prize_currency);
    let forStanding;

    let prizes =
      this.state.premios === undefined
        ? this.props.premios.pool_prizes[0].prizes
        : this.state.premios.pool_prizes[0].prizes;
    // let prizes = premios.pool_prizes[0].prizes;

    let available =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.available
        : this.state.poolInfo.pool.available;

    let endPercentage =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.end_percentage
        : this.state.poolInfo.pool.end_percentage;

    let weeklyPrize =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.weekly_prize
        : this.state.poolInfo.pool.weekly_prize;

    let poolType =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.type
        : this.state.poolInfo.pool.type;

    /**
     * Ticket Currency prize
     * **/
    if (prize_currency === 'ticket') {
      // console.log('updateStandings Tickets');

      let maxPrizedPosition = _.max(prizes, (prize) => {
        return Number(prize.position);
      });

      let grouped = _.groupBy(positions, 'rank');

      // console.log('grouped', grouped);
      // console.log('positions', positions);
      // console.log('prizes', prizes);
      // console.log('maxPrizedPosition', maxPrizedPosition);

      _.each(grouped, (positions, rank) => {
        rank = Number(rank);
        if (rank > maxPrizedPosition.position) {
          // console.log('Bigger than rank', maxPrizedPosition.position, 'Empatadis',positions.length, 'Actual',rank);
          positions.forEach((position, posIdx) => {
            //let premio = _.find(prizes, position);
            // console.log(position, premio);
            positions[posIdx].prize_amount = 0;
            positions[posIdx].user_prize.amount = 0;
            positions[posIdx].user_prize.base_amount = 0;
            positions[posIdx].user_prize.description = null;
            updatedPositions.push(positions[posIdx]);
          });
        } else {
          if (positions.length === 1) {
            // console.log('Unique position');
            let premio = _.find(prizes, { position: rank });
            // console.log('Rank',rank,'Premio', premio);
            positions[0].user_prize.description = premio.description;
            // console.log(positions[0].id, positions[0].user.nick , positions[0].rank, positions[0].user_prize.amount);
            updatedPositions.push(positions[0]);
          } else {
            positions.forEach((position, idx) => {
              if (
                position.hasOwnProperty('user_prize') &&
                prizes[position.rank] &&
                prizes[position.rank].hasOwnProperty('description')
              ) {
                position.user_prize.description = prizes[position.rank].description;
              } else {
                position.user_prize.description = null;
              }
              updatedPositions.push(position);
            });
          }
        }
      });
      return updatedPositions;
    } else {
      /**
       * Normal Currency prize
       * **/
      // console.log('%c %s %o %o','background:#7BD900; font-size:20px;','Updating Standings A ganar',positions)
      // console.log(tiesDistribution);
      // take into account the weekly prizes
      if (poolType === 'full' && weeklyPrize) {
        let percentage = endPercentage / 10000;
        available = available * percentage;
      }

      // let available = poolInfo.pool.available;
      //let index = this.state.index === undefined ? this.props.index : this.state.index;
      // console.log( 'Prizes\n' , prizes );
      // prizes =prizes.map(prize => prize.position);
      let maxPrizedPosition = _.max(prizes, (prize) => {
        return Number(prize.position);
      });

      positions.map((standing) => {
        // console.log(standing);
        standing['prize_amount'] = 0;
        standing['user_prize'].amount = 0;
        standing['user_prize'].base_amount = 0;
        standing['user_prize'].percentage = 0;
        standing['user_prize'].description = null;
      });

      // let grouped = _.groupBy(positions, 'rank');
      // console.table(tiesDistribution,);
      // console.table(positions);

      tiesDistribution.forEach((tie, tieIdx) => {
        let rank = tie.rank;
        // console.log(rank, maxPrizedPosition.position);

        if (rank > maxPrizedPosition.position) {
          //console.error('rank is bigger than maxPrizesPosition', maxPrizedPosition.position, rank);
          return;
        }

        let acc = 0;

        for (let i = rank; i < rank + tie.ties; i++) {
          let prize = prizes[i - 1];
          if (!prize) {
            continue;
          }
          acc += prize.percentage;
        }

        forStanding = (available * (acc / 10000)) / tie.ties;

        // console.log('ForStanding',forStanding);
        tie['prize_amount'] = forStanding;
      });

      positions.map((position) => {
        let prize = _.find(tiesDistribution, { rank: position.rank });
        if (!prize) {
          position.user_prize.amount = 0;
          position.prize_amount = 0;
        } else {
          position.user_prize.amount = prize.prize_amount;
          position.prize_amount = prize.prize_amount;
        }

        updatedPositions.push(position);
      });

      // console.log(updatedPositions);
      return updatedPositions;
    }
  },
  /**
   * This function Updates the Amount of prize in a Live Quiniela
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsAganarBracket: function (positions, prize_currency) {
    let updatedPositions = [];
    let tiesDistribution = this.state.bracketInfo.ties_distribution ? this.state.bracketInfo.ties_distribution : null;

    if (!tiesDistribution || !positions) {
      return;
    }

    console.log('Updated StateStandings aganar');
    console.log(prize_currency);
    let forStanding;

    let prizes =
      this.state.premios === undefined
        ? this.props.premios.pool_prizes[0].prizes
        : this.state.premios.pool_prizes[0].prizes;
    // let prizes = premios.pool_prizes[0].prizes;

    let available =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.available
        : this.state.poolInfo.pool.available;

    let endPercentage =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.end_percentage
        : this.state.poolInfo.pool.end_percentage;

    let weeklyPrize =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.weekly_prize
        : this.state.poolInfo.pool.weekly_prize;

    let poolType =
      this.state.poolInfo === undefined
        ? this.props.poolInfo.pool.type
        : this.state.poolInfo.pool.type;

    /**
     * Ticket Currency prize
     * **/
    if (prize_currency === 'ticket') {
      // console.log('updateStandings Tickets');

      let maxPrizedPosition = _.max(prizes, (prize) => {
        return Number(prize.position);
      });

      let grouped = _.groupBy(positions, 'rank');

      // console.log('grouped', grouped);
      // console.log('positions', positions);
      // console.log('prizes', prizes);
      // console.log('maxPrizedPosition', maxPrizedPosition);

      _.each(grouped, (positions, rank) => {
        rank = Number(rank);
        if (rank > maxPrizedPosition.position) {
          // console.log('Bigger than rank', maxPrizedPosition.position, 'Empatadis',positions.length, 'Actual',rank);
          positions.forEach((position, posIdx) => {
            //let premio = _.find(prizes, position);
            // console.log(position, premio);
            positions[posIdx].prize_amount = 0;
            positions[posIdx].user_prize.amount = 0;
            positions[posIdx].user_prize.base_amount = 0;
            positions[posIdx].user_prize.description = null;
            updatedPositions.push(positions[posIdx]);
          });
        } else {
          if (positions.length === 1) {
            // console.log('Unique position');
            let premio = _.find(prizes, { position: rank });
            // console.log('Rank',rank,'Premio', premio);
            positions[0].user_prize.description = premio.description;
            // console.log(positions[0].id, positions[0].user.nick , positions[0].rank, positions[0].user_prize.amount);
            updatedPositions.push(positions[0]);
          } else {
            positions.forEach((position, idx) => {
              if (
                position.hasOwnProperty('user_prize') &&
                prizes[position.rank] &&
                prizes[position.rank].hasOwnProperty('description')
              ) {
                position.user_prize.description = prizes[position.rank].description;
              } else {
                position.user_prize.description = null;
              }
              updatedPositions.push(position);
            });
          }
        }
      });
      return updatedPositions;
    } else {
      /**
       * Normal Currency prize
       * **/
      // console.log('%c %s %o %o','background:#7BD900; font-size:20px;','Updating Standings A ganar',positions)
      // console.log(tiesDistribution);
      // take into account the weekly prizes
      if (poolType === 'full' && weeklyPrize) {
        let percentage = endPercentage / 10000;
        available = available * percentage;
      }

      // let available = poolInfo.pool.available;
      //let index = this.state.index === undefined ? this.props.index : this.state.index;
      // console.log( 'Prizes\n' , prizes );
      // prizes =prizes.map(prize => prize.position);
      let maxPrizedPosition = _.max(prizes, (prize) => {
        return Number(prize.position);
      });

      positions.map((standing) => {
        // console.log(standing);
        standing['prize_amount'] = 0;
        standing['user_prize'].amount = 0;
        standing['user_prize'].base_amount = 0;
        standing['user_prize'].percentage = 0;
        standing['user_prize'].description = null;
      });

      // let grouped = _.groupBy(positions, 'rank');
      // console.table(tiesDistribution,);
      // console.table(positions);

      tiesDistribution.forEach((tie, tieIdx) => {
        let rank = tie.rank;
        // console.log(rank, maxPrizedPosition.position);

        if (rank > maxPrizedPosition.position) {
          //console.error('rank is bigger than maxPrizesPosition', maxPrizedPosition.position, rank);
          return;
        }

        let acc = 0;

        for (let i = rank; i < rank + tie.ties; i++) {
          let prize = prizes[i - 1];
          if (!prize) {
            continue;
          }
          acc += prize.percentage;
        }

        forStanding = (available * (acc / 10000)) / tie.ties;

        // console.log('ForStanding',forStanding);
        tie['prize_amount'] = forStanding;
      });

      positions.map((position) => {
        let prize = _.find(tiesDistribution, { rank: position.rank });
        if (!prize) {
          position.user_prize.amount = 0;
          position.prize_amount = 0;
        } else {
          position.user_prize.amount = prize.prize_amount;
          position.prize_amount = prize.prize_amount;
        }

        updatedPositions.push(position);
      });

      // console.log(updatedPositions);
      return updatedPositions;
    }
  },
  /**
   * This Function Get if the user has a correct answer in his Props
   * It uses the fixture Id , the propId and the type of value of the Prop
   * And check his pick with the winning Pick
   * @param fixtId propId type
   * @returns boolean
   */
  getWinningProp: function (propId, type) {
    // console.log('%c %s %o','background:#36A121; font-size:20px;','Get Winning Prop', this);
    if (this.state.entriesInfoTemp !== undefined) {
      return null;
    }
    if (!this.state.selectedEntryData === null) {
      return null;
    } else if (!this.props.selectedEntryData === null) {
      return null;
    }
    // console.log(liveFixt.winning_pick== livePick.pick);

    if (this.state.selectedEntryData && this.state.selectedEntryData.hasOwnProperty('picks')) {
      let pickProp = this.state.selectedEntryData.picks_props.find(
        (_pickProp) => _pickProp.prop_id === propId
      );
      let prop = this.state.fixtureProps.find((_prop) => _prop.id === propId);
      if (!pickProp || !prop) {
        return null;
      }
      if (pickProp.pick !== type) {
        return null;
      }
      // console.log( prop.id, 'pick', pickProp.pick, "prop", prop.winning_pick, 'type',type ,   "match", pickProp.pick === prop.winning_pick );
      if (prop.fixture.sort_status === 1) {
        return null;
      }
      return pickProp.pick === prop.winning_pick;
    }
    // console.log(liveFixt.winning_pick== livePick.pick);
    else if (this.props.selectedEntryData && this.props.selectedEntryData.hasOwnProperty('picks')) {
      let pickProp = this.props.selectedEntryData.picks_props.find(
        (_pickProp) => _pickProp.prop_id === propId
      );
      let prop = this.props.fixtureProps.find((_prop) => _prop.id === propId);
      if (!pickProp || !prop) {
        return null;
      }
      if (pickProp.pick !== type) {
        return null;
      }
      if (prop.fixture.sort_status === 1) {
        return null;
      }
      // console.log( prop.id, 'pick', pickProp.pick, "prop", prop.winning_pick,  "match", pickProp.pick === prop.winning_pick );
      return pickProp.pick === prop.winning_pick;
    } else {
      return null;
    }
    // if(this.props.selectedEntryData !== undefined){
    //   livePick = this.props.selectedEntryData.picks_props.find(pick => pick.prop_id === propId);
    // }
    // else {
    //   livePick = this.state.selectedEntryData.picks_props.find(pick => pick.prop_id === propId);
    // }
    // if(this.props.fixtureProps !== undefined){
    //   liveProp = this.props.fixtureProps.find(prop => prop.prop_id === propId);
    // }
    // console.log(livePick, liveProp, this.props.fixtureProps);
    // console.log('Is LIVE');
    // console.log( liveProp, livePick, );
    // if(livePick === undefined){ return null}
    // if(liveProp.winning_pick ===  typePick  &&  livePick.pick === liveProp.winning_pick){
    //   return true
    // }
    // else if(liveProp.winning_pick === typePick && livePick.pick !== liveProp.winning_pick) {
    //   return false;
    // }
    // else {
    //   return null;
    // }
  },
  /**
   * This Function Get if the user pick of certain Fixture
   * It uses the fixture Id to compare ithem
   * @param fixtId
   * @returns boolean
   */
  getSelectedProp: function (fixtId, propId, teamType) {
    let livePick, liveFixt, liveProp;
    // console.log('%c %s %o','background:#36A121; font-size:20px;','Get selected Propk', this);

    if (this.state.selectedEntryData === null) {
      // console.log('whithout register');
      return null;
    }
    // console.log(liveFixt.winning_pick== livePick.pick);
    else if (this.props.selectedEntryData === null) {
      // console.log('whithout register');
      return null;
    }

    if (this.props.selectedEntryData !== undefined) {
      livePick = this.props.selectedEntryData.picks_props.find((pick) => pick.prop_id === propId);
    } else {
      livePick = this.state.selectedEntryData.picks_props.find((pick) => pick.prop_id === propId);
    }

    if (this.state.liveFixturesData !== null) {
      liveFixt = this.state.liveFixturesData.live_fixtures.find((live) => live.id === fixtId);
    }
    if (this.state.livePropsData !== null) {
      liveProp = this.state.livePropsData.find((live) => live.id === propId);
    }

    // console.log( livePick);
    if (liveProp !== undefined && liveFixt !== undefined) {
      console.log(liveFixt, liveProp);
    } else {
      liveFixt = this.state.fixtures.find((live) => live.id === fixtId);

      // console.log(liveFixt)
    }

    // if(this.state.selectedEntryData === undefined) {
    //   if (this.props.selectedEntryData !== null) {
    //     pick = this.props.selectedEntryData.picks_props.find(pick => pick.prop_id === propId);
    //     console.log(pick, teamType, fixtId)
    //     if (pick !== undefined) {
    //       if(teamType === pick.pick){
    //         return true
    //       } else {return false}
    //     }
    //   }
    // }
    // else {
    //   if(this.state.selectedEntryData !== null){
    //     pick = this.state.selectedEntryData.picks.find(pick => pick.prop_id === propId);
    //     console.log(pick, teamType, fixtId)
    //     if(pick !== undefined ) {
    //       if(teamType === pick.pick){
    //         return true
    //       } else {return false}
    //     }
    //   }
    // }
    // return false;
  },
  /**
   * This Function Get if the user has a correct answer in his Fixtures
   * It uses the fixture Id to check his pick with the winning Pick
   * @param fixtId
   * @returns boolean
   */
  getWinningPick: function (fixtId, teamType) {
    let fixture;
    let pick;
    // console.log('%c %s %o','background:#36A121; font-size:20px;','Get Winning Pick', this, teamType);
    if (this.state.selectedEntryData === undefined) {
      if (this.props.selectedEntryData !== null) {
        pick = this.props.selectedEntryData.picks.find((pick) => pick.fixture_id === fixtId);
        fixture = this.state.fixtures.find((fixt) => fixt.id === fixtId);
        // console.log(this.state.fixtures,fixture , pick.pick);
        if (pick === undefined) {
          return;
        }
        if (fixture === undefined) {
          return;
        }
        if (teamType === 'home') {
          if (pick.pick === teamType) {
            if (pick.pick === fixture.winning_pick) {
              // console.log('true');
              return true;
            }
          } else {
            return null;
          }
        } else if (teamType === 'away') {
          if (pick.pick === teamType) {
            // console.log(fixture);
            if (pick.pick === fixture.winning_pick) {
              // console.log('true');
              return true;
            }
          } else {
            return null;
          }
        } else if (teamType === 'tie') {
          if (pick.pick === teamType) {
            if (pick.pick === fixture.winning_pick) {
              // console.log('true');
              return true;
            }
          } else {
            return null;
          }
        } else {
          return false;
        }
      }
    } else {
      if (this.state.selectedEntryData !== null) {
        pick = this.state.selectedEntryData.picks.find((pick) => pick.fixture_id === fixtId);
        fixture = this.state.fixtures.find((fixt) => fixt.id === fixtId);
        // console.log(this.state.fixtures,fixture , pick.pick);
        if (pick === undefined) {
          return;
        }
        if (fixture === undefined) {
          return;
        }
        if (teamType === 'home') {
          if (pick.pick === teamType) {
            if (pick.pick === fixture.winning_pick) {
              // console.log('true');
              return true;
            } else {
              return false;
            }
          } else {
            return null;
          }
        } else if (teamType === 'away') {
          if (pick.pick === teamType) {
            if (pick.pick === fixture.winning_pick) {
              // console.log('true');
              return true;
            } else {
              return false;
            }
          } else {
            return null;
          }
        } else if (teamType === 'tie') {
          if (pick.pick === teamType) {
            if (pick.pick === fixture.winning_pick) {
              // console.log('true');
              return true;
            } else {
              return false;
            }
          } else {
            return null;
          }
        } else {
          return false;
        }
      }
    }
    return false;
  },
  /**
   * This Function returns the amount of Prize that will be assigned to the user
   * It uses the rank to ssearch the equivalence of the prize
   * @param rank
   * @returns amount
   */
  getAganar: function (rank) {
    let aGanar, totalPremios, tipoPremios;
    // console.log('Get a Ganar ');
    // console.log(rank, this.props.premios.pool_prizes[0].prizes);
    if (this.state.premios === undefined) {
      aGanar = this.props.premios.pool_prizes[0].prizes.find((prize) => prize.position === rank);
      totalPremios = this.props.poolInfo.pool.available;
      tipoPremios = this.props.premios.pool_prizes[0].prizes[0].type;
      // console.log(this.props.posiciones.items[aGanar.position-1] );
      // console.log(aGanar, this.props.poolInfo.pool.closed, tipoPremios);
      if (aGanar === undefined) {
        // console.log('No hay premio');
        return '0';
      } else if (this.props.poolInfo.pool.closed) {
        if (aGanar.type === 'percentage') {
          // console.log('Cerrada Percentage' );
          return (this.props.poolInfo.pool.available * (aGanar.percentage / 1000)) / 1000;
        } else {
          // console.log('Cerrada ' );
          return aGanar.amount / 1000;
        }
      } else {
        // console.log('Upcoming');
        // console.log(this.props.posiciones.items,this.props.posiciones.items.length , aGanar.position-1);
        if (aGanar.position === 0) {
          // console.log(this.props.posiciones.items[aGanar.position].prize_amount /100 );
          return this.props.posiciones.items[aGanar.position].amount / 100;
        } else {
          if (tipoPremios === 'percentage') {
            return ((aGanar.percentage / 100) * this.props.poolInfo.pool.available) / 100 / 100;
          } else if (tipoPremios === 'fixed') {
            return ((aGanar.percentage / 100) * this.props.poolInfo.pool.available) / 100 / 100;
          } else {
            return ((aGanar.percentage / 100) * this.props.poolInfo.pool.available) / 100 / 100;
          }
        }
      }
    } else {
      aGanar = this.state.premios.pool_prizes[0].prizes.find((prize) => prize.position === rank);
      totalPremios = this.state.poolInfo.pool.available;
      tipoPremios = this.state.premios.pool_prizes[0].prizes[0].type;
      // console.log(this.props.posiciones.items[aGanar.position-1] );
      // console.log(aGanar, this.props.poolInfo.pool.closed, tipoPremios);
      if (aGanar === undefined) {
        // console.log('No hay premio');
        return '0';
      } else if (this.state.poolInfo.pool.closed) {
        if (aGanar.type === 'percentage') {
          // console.log('Cerrada Percentage' );
          return (this.state.poolInfo.pool.available * (aGanar.percentage / 1000)) / 1000;
        } else {
          // console.log('Cerrada ' );
          return aGanar.amount / 1000;
        }
      } else {
        // console.log('Upcoming');
        // console.log(this.props.posiciones.items,this.props.posiciones.items.length , aGanar.position-1);
        if (aGanar.position === 0) {
          // console.log(this.props.posiciones.items[aGanar.position].prize_amount /100 );
          return this.state.posiciones.items[aGanar.position].amount / 100;
        } else {
          if (tipoPremios === 'percentage') {
            return ((aGanar.percentage / 100) * this.state.poolInfo.pool.available) / 100;
          } else if (tipoPremios === 'fixed') {
            return ((aGanar.percentage / 100) * this.state.poolInfo.pool.available) / 100;
          } else {
            return ((aGanar.percentage / 100) * this.state.poolInfo.pool.available) / 100;
          }
        }
      }
    }
  },
  /**
   * This Function returns the amount of Prize that will be assigned to the user
   * It uses the rank to ssearch the equivalence of the prize
   * @param rank
   * @returns amount
   */
   getAganaBracket: function (rank) {
    let aGanar, totalPremios, tipoPremios;
    let premios = this.state.premios.length === 0 ? this.props.bracketPrizes : this.state.premios;
    let bracket = this.props.bracketInfo !== undefined ? this.props.bracketInfo : this.state.bracketInfo;
    let standings = this.props.standings !== undefined ? this.props.standings : this.state.standings;

    aGanar = premios.find((prize) => prize.position === rank);
    totalPremios = bracket.available;
    tipoPremios = premios[0].type;

    if (aGanar === undefined) {
      return '0';
    } else if (bracket.closed) {
      if (aGanar.type === 'percentage') {
        return (bracket.available * (aGanar.percentage / 1000)) / 1000;
      } else {
        return aGanar.amount / 1000;
      }
    } else {
      if (aGanar.position === 0) {
        return standings.items[aGanar.position].amount / 100;
      } else {
        if (tipoPremios === 'percentage') {
          return ((aGanar.percentage / 100) * bracket.available) / 100;
        } else if (tipoPremios === 'fixed') {
          return ((aGanar.percentage / 100) * bracket.available) / 100 / 100;
        } else {
          return ((aGanar.percentage / 100) * bracket.available) / 100 / 100;
        }
      }
    }
  },
  calculateTiesPrizes: function (available, premios, tiesDistribution) {
    //Get last position
    var maxPrizedPosition = _.max(premios, function(prize) {
      return prize.position;
    });

    //Initialize all ties with 0
    _.map(tiesDistribution, function(ties) {
      ties.percentage  = 0;
      ties.amount      = 0;
      ties.type        = null;
      ties.description = null;
    });

    // Throught them
    tiesDistribution.map((ties) => {
      // if the current is bigger than the smalles payed position break
      if ( ties.rank > maxPrizedPosition.position ) {
        return;
      } else {
        var rank    = ties.rank; // cual es la posicion
        var numTies = ties.ties; // cuantos lugares empatados hay en esa posición
        var acc = 0; // cuanto le va a tocar a cada uno
        var prize; // el premio

        // iterate through all of the tied positions
        for ( var i = rank ; i < rank + numTies; i++ ) {
          prize = premios[i-1];
          if ( !prize ) { continue; }
          if ( prize.type !== 'ticket' ) {
            // acumular el premio
            acc += prize.percentage;
          } else {
            acc = 10000;
          }
        }
        prize = _.find(premios, {'position': rank});
        if ( prize && prize.type == 'ticket' ) {
          ties.type        = 'ticket';
          ties.description = prize.description;
        }
        // porcentaje acumulado de las posiciones empatadas
        ties.percentage = acc;
        ties.amount     = ( available * (acc/10000) ) / numTies ;
      }
    });

    return tiesDistribution;
  },
  /**
   * This Function returns the amount of Prize full quiniela
   * that will be assigned to the user at the end of the week
   * It uses the rank to ssearch the equivalence of the prize
   * @param rank
   * @returns amount
   */
  getAganarWeekly: function (user) {
    // console.log('Get a Ganar WEekley');
    // console.log(user);
    // console.log(rank, this.props.premios.pool_prizes[0].prizes);
    let aGanar = user;
    let totalPremios, tipoPremios, tipoPremioSemanal, pool, poolWeeks;
    pool = this.state.poolInfo.pool;
    poolWeeks = this.state.poolWeeks;
    totalPremios = this.state.poolInfo.pool.available;
    tipoPremios = this.state.premios.pool_prizes[0].prizes[0].type;
    tipoPremioSemanal = this.state.poolInfo.pool.weekly_prize_scheme;
    // console.log('in Sate');
    // console.log(this.props.posiciones.items[aGanar.position-1] );
    // console.log(aGanar, this.props.poolInfo.pool.closed, tipoPremios);
    // console.log(aGanar);
    let weekleyStandings = _.groupBy(this.state.weeklyStandings.items, 'rank');
    // console.log(weekleyStandings);
    // console.log(aGanar.rank)
    if (aGanar === undefined) {
      // console.log('No hay premio');
      return '0';
    } else {
      // console.log('Upcoming');
      // console.log(this.props.posiciones.items,this.props.posiciones.items.length , aGanar.position-1);
      if (tipoPremios === 'percentage') {
        if (!aGanar.rank) {
          return 0;
        }
        let premioSemanal = ((aGanar.percentage / 100) * totalPremios) / 100 / 100;
        return premioSemanal / weekleyStandings[aGanar.rank].length;
      } else if (tipoPremios === 'fixed') {
        if (tipoPremioSemanal === 'first') {
          if (aGanar.rank !== 1) {
            return 0;
          } else {
            let premioSemanal =
              (totalPremios * pool.weekly_percentage) / 1000 / poolWeeks.weeks.length / 1000;
            return premioSemanal / weekleyStandings[aGanar.rank].length;
          }
        }
      } else {
        let premioSemanal =
          (totalPremios * pool.weekly_percentage) / 1000 / poolWeeks.weeks.length / 1000;
        return premioSemanal / weekleyStandings[aGanar.rank].length;
      }
    }
  },
  /**
   * This function Updates the Picks selected for the user
   * it handles the event triggered by the radio buttton
   * and extracts the data from the attributes and id
   * @param e
   */
  updatePicks: function (pickType, value, fixtureId) {
    // console.log('%c UpdatingPicks', 'font-size:20px; background:#009BF5');
    // console.log( pickType, value, fixtureId );
    // let pick = e.target.attributes['id'].value.split('-');
    let entryId = null;
    if (!this.props.withRegister) {
      entryId = '';
    } else {
      if (this.state.selectedEntryData !== undefined && this.state.selectedEntryData !== null) {
        entryId = '-' + this.state.selectedEntryData.id;
      } else if (
        this.props.selectedEntryData !== undefined &&
        this.props.selectedEntryData !== null
      ) {
        entryId = '-' + this.props.selectedEntryData.id;
      }
      // console.log(entryId);
    }

    // console.log( `#picksContainer${entryId} label#${fixtureId}-${value}` );

    let pickLabelSelected = $(`#picksContainer${entryId} label#${pickType}-${fixtureId}-${value}`);
    $(`#picksContainer${entryId} input#${pickType}-${fixtureId}-${value}`).prop('checked', true);

    // let isHomeSelected = `${pickType}-${fixtureId}-home`,
    //   isTieSelected = `${pickType}-${fixtureId}-tie`,
    //   isAwaySelected = `${pickType}-${fixtureId}-away`,
    //   isOverSelected = `${pickType}-${fixtureId}-over`,
    //   isUnderSelected = `${pickType}-${fixtureId}-under`;
    // // console.log(pick);
    // // console.log('Querys ');
    // // console.log(e.target.attributes['id'].nodeValue);

    // // console.log(`#picksContainer${entryId} label#${e.target.attributes['id'].nodeValue}`, isHomeSelected, isTieSelected, isAwaySelected);

    // isHomeSelected = $(`#picksContainer${entryId} label#${isHomeSelected}`).removeClass('selected');
    // isTieSelected = $(`#picksContainer${entryId} label#${isTieSelected}`).removeClass('selected');
    // isAwaySelected = $(`#picksContainer${entryId} label#${isAwaySelected}`).removeClass('selected');
    // isOverSelected = $(`#picksContainer${entryId} label#${isOverSelected}`).removeClass('selected');
    // isUnderSelected = $(`#picksContainer${entryId} label#${isUnderSelected}`).removeClass(
    //   'selected'
    // );

    // console.log('REsults ');

    // console.log(pickLabelSelected);

    // isHomeSelected.length > 0 ? isHomeSelected[0].classList.remove('selected'): null;
    // isTieSelected.length > 0 ? isTieSelected[0].classList.remove('selected'): null ;
    // isAwaySelected.length > 0 ? isAwaySelected[0].classList.remove('selected'):null ;
    // isOverSelected.length > 0 ? isOverSelected[0].classList.remove('selected'):null ;
    // isUnderSelected.length > 0 ? isUnderSelected[0].classList.remove('selected'): null;

    pickLabelSelected.addClass('selected');
    // console.log(pickLabelSelected, isHomeSelected, isTieSelected, isAwaySelected);

    if (this.state.numeroEntries === undefined) {
      /**
       * This condition works in QuinielaLobby or QuinielaINfo Popup
       */
      let pickId = Number(fixtureId);
      // Quinielas en Lobby o Info
      // console.log('Quinielas Lobby / Info', e.target.value, this, pickId);
      let inputType = pickType;
      let fixtures = this.props.fixtures;
      if (fixtures === undefined) {
        fixtures = this.state.fixtures;
      }
      let entryId;
      if (this.props.selectedEntry !== null || this.props.selectedEntry !== undefined) {
        entryId = this.props.selectedEntry;
      } else {
        entryId = null;
      }
      // console.log(entryId);
      // let fixtureID = this.state.fixtures[0].id;
      let props = [];
      let selectedPick;
      let selectedProp;
      let fixtTemp = this.state.picks;
      let propTemp = this.state.picks_props;
      if (inputType === 'propPartido') {
        props = fixtures.map((fixt) => {
          if (fixt.props.length > 0) {
            if (fixt.props.length > 1) {
              let props = fixt.props.map((prop) => {
                // console.log(prop);
                return prop;
              });
              return props;
            } else {
              return fixt.props[0];
            }
          }
        });
        props = [].concat.apply([], props);
        props = props.filter((prop) => prop !== undefined);
        // console.log(props, pickId);
        // console.log('%c Adding Picks_Props...','font-size:20px; color:#00ff00;');
        let props_ = {};
        //Este puede servir para otro tipo de quiniela
        // selectedProp = props.find( prop => prop.fixture_id === pickId);
        //Este sirve para las multi props
        selectedProp = props.find((prop) => prop.id === pickId);
        selectedProp =
          selectedProp === undefined
            ? (selectedProp = props.find((prop) => prop.fixture_id === pickId))
            : null;
        // console.log(selectedProp, pickId);
        /**
         * Setting Props
         * @type {{id: null, fixture_id: (*|number), entry_id: *, prop_id, pool_id, pick}}
         */
        let picks_propsTemporales = {
          id: null,
          fixture_id: selectedProp.fixture_id,
          entry_id: entryId,
          prop_id: selectedProp.id,
          pool_id: this.state.poolInfo.pool.id,
          pick: value,
        };
        props_[selectedProp.id] = picks_propsTemporales;
        this.state.picks_props.forEach((prop, i) => {
          // let obj = {};
          // let indexDuplicate;
          let id = Object.keys(prop);
          id = id[0];
          console.log(Number(id));
          if (id === selectedProp.id) {
            // picks_.splice(i,1);
            console.log(props_, picks_propsTemporales, selectedProp);
            propTemp.splice(i, 1);
          }
        });
        propTemp.push(props_);
        this.setState({
          picks_props: propTemp,
          temporalRegisterPicks: this.setTemporalRegister(),
        });
        // let temporalRegisterResult = this.setTemporalRegister();
        // console.log(temporalRegisterResult);
      } else if (inputType === 'propFixture') {
        // console.log('%c Adding Picks...', 'font-size:20px; color:#ff0000;');
        // console.log(fixtures, pickId, selectedPick);
        let picks_ = {};
        selectedPick = fixtures.find((fixture, i) => fixture.id === pickId);
        // console.log(selectedPick);
        let picksTemporales = {};
        /**
         * Setting Picks/Fixtures
         */
        picksTemporales = {
          id: null,
          fixture_id: pickId,
          entry_id: entryId,
          week_id: selectedPick.week_id,
          pool_id: this.state.poolInfo.pool.id,
          pick: value,
        };
        // console.log(picksTemporales);
        picks_[selectedPick.id] = picksTemporales;
        // console.log(this);
        if (this.state.picks.length > 0) {
          /**
           * Find if it is duplicated take the index and overwrite it
           */
          this.state.picks.forEach((pick, i) => {
            // let obj = {};
            // let indexDuplicate;
            let id = Object.keys(pick);
            id = id[0];
            // console.log(Number(id));
            if (id === selectedPick.id) {
              // picks_.splice(i,1);
              console.log(picks_, picksTemporales, selectedPick);
              fixtTemp.splice(i, 1);
            }
          });
        }
        fixtTemp.push(picks_);
        this.setState({
          picks: fixtTemp,
          temporalRegisterPicks: this.setTemporalRegister(),
        });
      }
    } else {
      /**
       * This condition works with MisQuinielasDashboard
       */
      console.log('Mis Quinielas', this);
      let pickId = Number(fixtureId);
      let inputType = pickType;
      let fixtures = this.state.fixtures;
      let selectedPick;
      let selectedProp;
      // let fixtTemp = this.state.picks[this.props.numeroEntries];
      // let propTemp =this.state.picks_props[this.props.numeroEntries];
      let fixtTemp = this.state.picks;
      let propTemp = this.state.picks_props;

      if (inputType === 'propPartido') {
        /**
         * Setting Props
         */
        console.log('%c Adding Picks_Props...', 'font-size:20px; color:#00ff00;');
        console.log(fixtures, pickId, this.props.numeroEntries);
        let props_ = {};
        //let propIndex;
        selectedProp = fixtures.find((fixt) => {
          // fixt.id === pickId
          // console.log(fixt.props);
          let founded = fixt.props.find((prop, indx) => {
            // console.log(prop.id, pickId);
            if (prop.id === pickId) {
              console.log('Founded!', prop);
              //propIndex = prop;
              return prop;
            }
          });
          return founded;
        });

        // console.log(selectedProp, propIndex);

        let picks_propsTemporales = {
          id: null,
          fixture_id: selectedProp.id,
          entry_id: this.props.entry.id,
          prop_id: pickId,
          pool_id: this.state.poolInfo.pool.id,
          pick: value,
        };
        props_[pickId] = picks_propsTemporales;
        propTemp.push(props_);
        this.setState({
          picks_props: propTemp,
          temporalRegisterPicks: this.setTemporalRegister(),
        });
        // this.estadoProps();
      } else if (inputType === 'propFixture') {
        /**
         * Setting Picks/Fixtures
         */
        console.log('%c Adding Picks...', 'font-size:20px; color:#ff0000;');
        // console.log(fixtures);
        let picks_ = {};
        selectedPick = fixtures.find((fixture, i) => fixture.id === pickId);
        console.log(selectedPick);
        if (selectedPick === undefined) {
          console.log('No encontro el pick seleccionado');
          selectedPick = fixtures.fixtures.find((fixture, i) => fixture[0].id === pickId);
          console.log(selectedPick);
        }
        console.log(typeof this.state.poolInfo);
        let picksTemporales = {};
        if (typeof this.state.poolInfo === 'object') {
          picksTemporales = {
            id: null,
            fixture_id: pickId,
            entry_id: this.props.entry.id,
            week_id: selectedPick.week_id,
            pool_id: this.state.poolInfo.pool.id,
            pick: value,
          };
        } else {
          picksTemporales = {
            id: null,
            fixture_id: pickId,
            entry_id: null,
            week_id: selectedPick.week_id,
            pool_id: this.state.poolInfo.pool.id,
            pick: value,
          };
        }

        // console.log(picksTemporales);
        // console.log(picks_);
        // console.log(fixtTemp);

        let itExist = false;
        let idxExist = null;
        fixtTemp.find((temp, tempIdx) => {
          // console.log(temp, temp[selectedPick.id]);
          if (temp[selectedPick.id]) {
            itExist = true;
            idxExist = tempIdx;
          }
        });
        // console.log('itExist', itExist, selectedPick.id);

        if (itExist) {
          console.log();
          picks_[selectedPick.id] = picksTemporales;
          fixtTemp[idxExist] = picks_;
          // fixtTemp.push(picks_);
        } else {
          picks_[selectedPick.id] = picksTemporales;
          fixtTemp.push(picks_);
        }

        this.setState({
          picks: fixtTemp,
          temporalRegisterPicks: this.setTemporalRegister(),
        });
      }
    }
  },
  initializeEntry: function (
    entryInfo,
    fixtures,
    fixtureProps,
    poolInfo,
    currentWeek,
    entryModel,
    action
  ) {
    // console.log('%c InitializeEntry','color: #78bc28 ; font-size:2em; ')
    // console.log( 'entryModel from local-', entryModel );
    let promise;

    let entryId = !entryInfo ? null : entryInfo.id;
    let temporalEntryModel = entryModel;
    let picksFromEntry = {};
    let picksPropsFromEntry = {};
    //let ties_ = {};

    promise = new Promise((resolve, reject) => {
      // console.log( 'Inside the promise ',entryId);
      // console.log( 'tempModel', temporalEntryModel );

      if (!entryId) {
        /**
         * Inintialize Picks from available fixtures
         */
        fixtures.forEach((fixt) => {
          // console.log('fixt.id');
          // console.log(fixt.id);
          picksFromEntry[fixt.id] = {
            entry_id: null,
            fixture_id: fixt.id,
            id: null,
            pick: null,
            pool_id: poolInfo.pool.id,
            week_id: fixt.week_id,
          };
          // console.log( picks_[fixt.id] )
          // console.log( picksFromEntry )
        });

        /**
         * Inintialize PicksProps from available fixtureProps
         */
        if (fixtureProps) {
          fixtureProps.forEach((fixtprop) => {
            picksPropsFromEntry[fixtprop.id] = {
              entry_id: null,
              fixture_id: fixtprop.fixture_id,
              id: null,
              pick: null,
              pool_id: poolInfo.pool.id,
              prop_id: fixtprop.id,
            };
          });
        }

        // console.log(picksFromEntry, picksPropsFromEntry);
      } else {
        /**
         * With Entry
         */
        console.log('With Entry');
        fixtures.forEach((fixt) => {
          let currentPick = _.find(entryInfo.picks, (pick) => pick.fixture_id === fixt.id);
          // console.log( 'currentPick', currentPick )
          if (currentPick !== -1 && currentPick !== undefined) {
            // console.log('Entry pick from fixt id ',fixt.id,': ', currentPick);
            picksFromEntry[fixt.id] = {
              entry_id: entryId,
              fixture_id: fixt.id,
              id: currentPick.id,
              pick: currentPick.pick,
              // pick            : currentPick.pick === null ? entryModel.picks[pick.fixture_id].pick : currentPick.pick ,
              pool_id: poolInfo.pool.id,
              week_id: fixt.week_id,
            };
          } else {
            // console.log(entryModel.picks)
            if (
              entryModel.picks &&
              entryModel.picks.length !== 0 &&
              entryModel.entry_id === entryId
            ) {
              currentPick = entryModel.picks[fixt.id];
            }
            // console.log('Local pick from fixt id ',fixt.id,': ', currentPick);
            // console.log( currentPick );
            picksFromEntry[fixt.id] = {
              entry_id: entryId,
              fixture_id: fixt.id,
              id: null,
              pick: currentPick ? currentPick.pick : null,
              pool_id: poolInfo.pool.id,
              week_id: fixt.week_id,
            };
          }
        });

        if (fixtureProps) {
          fixtureProps.forEach((fixtprop) => {
            let currentPickProp = -1;
            if (entryInfo.picks_props) {
              currentPickProp = entryInfo.picks_props.find((pick) => pick.prop_id === fixtprop.id);
            }
            if (currentPickProp !== -1 && currentPickProp !== undefined) {
              picksPropsFromEntry[fixtprop.id] = {
                entry_id: entryId,
                fixture_id: fixtprop.fixture_id,
                id: currentPickProp.id,
                pick: currentPickProp.pick,
                pool_id: poolInfo.pool.id,
                prop_id: currentPickProp.prop_id,
              };
            } else {
              picksPropsFromEntry[fixtprop.id] = {
                entry_id: entryId,
                fixture_id: fixtprop.fixture_id,
                id: null,
                pick: null,
                pool_id: poolInfo.pool.id,
                prop_id: fixtprop.id,
              };
            }
          });
        }
        // console.log('WithEntry',picksFromEntry, picksPropsFromEntry);
      }

      temporalEntryModel.entry_id = entryId;
      temporalEntryModel.pool_id = poolInfo.pool.id;

      const { picks } = temporalEntryModel;

      /**
       * Reassign Picks
       */
      let newPicks = {};
      _.forEach(picksFromEntry, (actualEntry) => {
        newPicks = { ...newPicks, [actualEntry.fixture_id]: { ...actualEntry } };
      });

      if (
        picks &&
        (Object.values(picks).length !== Object.values(picksFromEntry).length ||
          action === 'change-week')
      ) {
        let newPicks = {};
        _.forEach(picks, (pick) => {
          if (newPicks[pick.fixture_id]) {
            newPicks[pick.fixture_id].pick = pick.pick;
          } else {
            newPicks = { ...newPicks, [pick.fixture_id]: { ...pick } };
          }
        });
      }

      /**
       * Reassign PicksProps
       */

      let newPicksProps = { ...temporalEntryModel.picksFromEntryprops, ...picksPropsFromEntry };
      // console.log(newPicksProps)
      _.forEach(newPicksProps, (newPick) => {
        _.forEach(picksPropsFromEntry, (temporalPick) => {
          if (newPick.prop_id === temporalPick.prop_id) {
            newPick.pick = temporalPick.pick;
          }
        });
      });

      temporalEntryModel.picks = newPicks;
      temporalEntryModel.picks_props = newPicksProps;
      // temporalEntryModel.user_tiebreakers = this.initializeTies( poolInfo, null );

      if (!temporalEntryModel) {
        // console.error('Rejected');
        reject(new Error('Entry Model Dont exist'));
      } else {
        // console.log('Resolved',temporalEntryModel);
        resolve(temporalEntryModel);
      }
    });

    return promise;
  },
  initializeTies: function (poolInfo, selectedEntry) {
    // console.log( '%c InitializeTies', 'color:#78bc28; font-size:2em;' );
    // console.log( selectedEntry );
    // console.log( poolInfo.pool.type );

    let tieWeek = Object.keys(poolInfo.tiebreakers)[0];

    let availableTies = [];

    if (poolInfo.pool.type === 'full' && poolInfo.tiebreakers ) {

      /**
       * Iterate throug every Weekly tie to add them to the available ties
       */
      // console.log('full');
      Object.keys(poolInfo.tiebreakers).forEach((tiebreak) => {
        let key = tiebreak === '' ? '' : Number(tiebreak);
        poolInfo.tiebreakers[key].forEach((tie) =>
          availableTies.push({ tie: tie, week_id: key })
        );
      });
    } else {
      let tiebreakerWeeks = _.values(poolInfo.tiebreakers[tieWeek]);
      tiebreakerWeeks.forEach((tie, i) => {
        let customObj = { tie: null, week_id: tiebreakerWeeks[i].week_id };
        customObj.tie = tie;
        availableTies.push({ tie: tie, week_id: tiebreakerWeeks[i].week_id });
      });
    }

    let tiesPicks = {};

    availableTies.forEach((avTie) => {
      let isTypeScore = false;
      const tieID = avTie.tie.id;
      // console.log(avTie);
      if (avTie.tie.type === 'score') {
        isTypeScore = true;
      }
      let currentTie = !selectedEntry
        ? null
        : selectedEntry.user_tiebreakers !== null
          ? selectedEntry.user_tiebreakers.find((userTie) => userTie.tiebreaker_id === avTie.tie.id)
          : null;

      // console.log('current Tie', currentTie);

      if (isTypeScore) {
        // console.log('Score',currentTie);
        // console.log('selectedTies',selectedEntry.user_tiebreakers);
        // console.log('tiebreakerID',tie.id);
        tiesPicks[tieID] = {
          week_id: avTie.week_id,
          id: !currentTie ? null : currentTie.id,
          entry_id: !selectedEntry ? null : selectedEntry.id,
          tiebreaker_id: tieID,
          score_home: !currentTie || !currentTie.score_home ? null : currentTie.score_home,
          score_away: !currentTie || !currentTie.score_away ? null : currentTie.score_away,
        };
      } else {
        // console.log(currentTie);
        tiesPicks[tieID] = {
          week_id: avTie.week_id,
          id: !currentTie ? null : currentTie.id,
          entry_id: !selectedEntry ? null : selectedEntry.id,
          tiebreaker_id: tieID,
          value: !currentTie ? null : currentTie.value,
        };
      }
    });

    // console.log(tiesPicks);

    return tiesPicks;
  },
  /**
   * This function Updates the TieData of the user
   * it handles the event triggered by the select tag
   * and extracts the data from the attributes and id
   * @param e
   */
  updateTieData: function (e, entryID, weekID, tiebreakers) {
    if (this.state.myEntry.entry_id !== null) {
      this.setState({picksUnsave: true});
    }

    let tieType = e.target.id.split('-');
    // console.log('%c Updating Tie Data', 'background:#00FF14; font-size:20px;');
    // console.log(this);
    // console.log(e.target);
    // console.log(tieType);
    // console.log(e.target.attributes['data-teamtype']);
    let inputType = tieType[1];
    let tieData = e.target.value;
    let tieId = parseInt(tieType[2]);
    // let tiesTemp = this.state.tiesData;
    // let desempates = tiebreakers;
    let selectedTieData;
    // let isFull;

    // console.log('tieType', tieType);
    // console.log('tieId', tieId);
    // console.log('tieData', tieData);
    // console.log('listTies', this.state.tiesPicks);

    let listTies = this.state.tiesPicks;

    // listTies[tieId].value = tieData;

    // this.setState({
    //   tiesPicks: listTies
    // });

    // console.log(weekID, desempates);
    if (inputType === 'first_goal' || inputType === 'last_goal') {
      inputType = 'number';
    }

    switch (inputType) {
      case 'score':
        // let temporalScore = {
        //   tieScoreHome: this.state.tieScoreHome,
        //   tieScoreAway: this.state.tieScoreAway
        // };
        console.log('%c Adding Score TieBreakers ...', 'font-size:20px; color:#0000ff;');

        if (tieType[3] === 'home') {
          listTies[tieId].score_home = tieData;
          // temporalScore['tieScoreHome'] = tieData;
          // console.log(tieType[3], tieData, temporalScore);
        } else if (tieType[3] === 'away') {
          listTies[tieId].score_away = tieData;
          // temporalScore['tieScoreAway'] = tieData;
          // console.log(tieType[3], tieData, temporalScore);
        }
        //let tiesScore_ = {};
        selectedTieData = this.state.poolInfo.tiebreakers[weekID].find(
          (tieData) => tieData.id === tieId
        );
        break;
      case 'number':
        listTies[tieId].value = tieData;
        break;
      case 'minute':
        listTies[tieId].value = tieData;
        break;
      case 'week_goals':
        listTies[tieId].value = tieData;
        break;
      case 'tournament_goals':
      case 'tournament_looser_goals':
      case 'tournament_winner_goals':
        listTies[tieId].value = tieData;
        break;
      case 'team':
        listTies[tieId].value = tieData;
        break;
      case 'team_points':
        //let typeTeam = e.target.attributes['data-teamtype'].value;
        // console.log(
        //   '%c Adding team_points TieBreakers ...',
        //   'font-size:20px; color:#0000ff;'
        // );
        listTies[tieId].value = tieData;
        break;
      default:
        listTies[tieId].value = tieData;
        break;
    }
    let temporalEntry = this.state.myEntry;

    temporalEntry.user_tiebreakers = listTies;

    this.setState({
      myEntry: temporalEntry,
      tiesPicks: listTies,
    });
  },

  /**
   * This function Check if the available Props are complete to the register Form
   */
  estadoProps: function () {
    console.log('Estado Props', this);
    // let totalF =
    //   this.state.numeroEntries === undefined
    //     ? this.state.fixtures.length
    //     : this.state.fixtures.length;
    let totalProps = 0;
    if (this.state.poolInfo.pool.format === 'mixed') {
      totalProps =
        this.state.numeroEntries === undefined
          ? this.state.fixtures.filter((fixt) => fixt.props.length > 0)
          : this.state.fixtures.filter((fixt) => fixt.props.length > 0);
    }

    // let cuantos = this.state.picks_props.length;
    // let estado = false;
    let regTMP = this.setTemporalRegister();
    console.log('regTMP', regTMP);
    this.setState({
      temporalRegisterPicks: regTMP,
    });
    // }
    // console.log('%c Estado deDF G Props', 'background:rgb(232, 70, 0)')
    // console.log('PicksProps', totalProps, cuantos)
  },
  /**
   * This function Check if the available Picks are complete to the register Form
   */
  estadoPicks: function () {
    console.log('EstadoPicks', this.state);
    let total =
      this.state.numeroEntries === undefined
        ? this.state.fixtures.length
        : this.state.fixtures.length;
    let cuantos = this.state.picks.length;
    let estado = false;
    let regTMP = this.setTemporalRegister();
    // console.log(total, cuantos);
    if (total === cuantos) {
      estado = true;
      this.setState({
        estadoPicks: estado,
      });
    } else if (total === 0) {
      estado = true;
      this.setState({
        estadoPicks: estado,
      });
    } else {
      estado = false;
    }
    this.setState({
      picksGuardados: cuantos,
      temporalRegisterPicks: regTMP,
    });
    // }
  },
  /**
   * This function Check if the available Ties are complete to the register Form
   */
  estadoDesempates: function () {
    let weekID;
    if (this.state.poolInfo.pool.type === 'full') {
      if (this.props.poolWeeks !== undefined) {
        weekID = this.props.poolWeeks.current.id;
      } else {
        weekID = this.state.poolWeeks.current.id;
      }
    } else if (this.state.poolInfo.pool.type === 'week') {
      weekID = this.state.fixtures[0].week_id;
    } else {
      weekID = this.state.poolInfo.pool.week_id === null ? '' : this.state.poolInfo.pool.week_id;
    }
    let total = null;
    if (
      this.state.poolInfo.pool.type === 'full' ||
      this.state.poolInfo.pool.week_id === null ||
      this.state.poolInfo.pool.week_id === ''
    ) {
      total = _.size(this.state.poolInfo.tiebreakers);
    } else {
      total = _.size(this.state.poolInfo.tiebreakers);
    }

    let regTMP = this.setTemporalRegister();
    let cuantos = this.state.tiesData.length;
    console.log('edo desempate', cuantos);
    console.log('edo Total', total);
    console.log('Reg Temp', regTMP);
    let estado = false;
    if (total === cuantos) {
      estado = true;
      this.setState(
        {
          estadoDesempates: estado,
          temporalRegisterPicks: regTMP,
        },
        () => {
          console.log(this.state.temporalRegisterPicks);
        }
      );
    } // }
  },
  copyTies: function (entryToCopy) {
    console.log('%c Copy  Ties ...', 'background:#F18737; font-size:20px;');
    console.log(this.state.tiesPicks);
    console.log(entryToCopy);
    let temporalTiePicks = this.state.tiesPicks;

    let counter = 0;
    _.each(temporalTiePicks, (tiePick) => {
      console.log(entryToCopy.user_tiebreakers[counter]);
      console.log(tiePick);

      if (!entryToCopy.user_tiebreakers[counter]) {
        console.error('Tie Not found ');
        return;
      } else if (
        tiePick.week_id === null &&
        entryToCopy.user_tiebreakers[counter].tiebreker.week_id === null
      ) {
        console.log('Special Exception');
        tiePick.value = entryToCopy.user_tiebreakers[counter].value;
      } else if (
        tiePick.week_id &&
        entryToCopy.user_tiebreakers[counter].tiebreaker.week_id &&
        tiePick.week_id !== entryToCopy.user_tiebreakers[counter].tiebreaker.week_id
      ) {
        console.error('Tie isnt same week ');
        return;
      }

      tiePick.value = entryToCopy.user_tiebreakers[counter].value;
      console.log('This to That', entryToCopy.user_tiebreakers[counter], tiePick);

      if (tiePick.score_home) {
        tiePick.score_home = entryToCopy.user_tiebreakers[counter].score_home;
      } else if (tiePick.score_away) {
        tiePick.score_away = entryToCopy.user_tiebreakers[counter].score_away;
      }
      counter++;
    });

    // console.log(foundIndex);
    // console.log(foundValue);
    // console.log('thisWeekTie',thisWeekTie);

    this.setState({
      tiesPicks: temporalTiePicks,
    });
  },
  /**
   * This Function copy the picks from andesignated entry
   * the id will be used to extract the data from the entry and put it in a clipboard inside the state
   * @param id
   */
  copiarPicks: function (id) {
    console.log('%c Copiando Picks ...', 'background:#F132FF; font-size:20px;');
    // console.log(this);
    let postura = this.state.similarPools.similar_entries.find((simi) => simi.id === id);
    // console.log('copiarPicks');
    // console.log(postura);
    API.fetchCopyPicks(id).then((response) => {
      let picks = response.data.entry.picks;
      let props = response.data.entry.picks_props;
      let ties = response.data.entry.user_tiebreakers;
      console.log('copy this', response);
      if (picks.length === 0 && props.length === 0 && ties.length === 0) {
        this.setState({
          errorCopiar: true,
        });
      } else {
        this.setState({
          errorCopiar: false,
        });
      }

      let poolId =
        this.props.poolInfo !== undefined
          ? this.props.poolInfo.pool.id
          : this.state.poolInfo.pool.id;
      let tiesKeys = Object.keys(this.state.poolInfo.tiebreakers);

      let nicePicks = picks.map((pick) => {
        let obj_ = {};
        obj_[pick.fixture_id] = {
          id: null,
          fixture_id: pick.fixture_id,
          entry_id: null,
          week_id: pick.week_id,
          pool_id: poolId,
          pick: pick.pick,
        };
        // console.log('Picks',obj_);
        return obj_;
      });
      let niceProps = props.map((prop) => {
        let obj_ = {};
        obj_[prop.prop_id] = {
          id: null,
          fixture_id: prop.fixture_id,
          entry_id: null,
          prop_id: prop.prop_id,
          pool_id: poolId,
          pick: prop.pick,
        };
        // console.log('Props',obj_);
        return obj_;
      });
      let niceTies = [];
      ties.forEach((tie, tieIndx) => {
        let listTies = [];
        let tiesList = [];
        tiesKeys.forEach((tieKey, tieKeyIndx) => {
          // console.log(
          //   'Tie',
          //   tieKeyIndx,
          //   this.state.poolInfo.tiebreakers[tieKey]
          // );
          this.state.poolInfo.tiebreakers[tieKey].forEach((tie, tieIndx) => {
            // console.log(ties[tieIndx], tie);
            // console.log(myTie);
            let obj_ = {};
            obj_[tie.id] = {
              id: null,
              entry_id:
                this.props.selectedEntry !== undefined || this.props.selectedEntry !== null
                  ? this.props.selectedEntry
                  : null,
              tiebreaker_id: tie.id,
              value:
                ties[tieIndx] && ties[tieIndx].hasOwnProperty('value') ? ties[tieIndx].value : null,
            };
            // console.log('Ties object', obj_);
            tiesList.push(obj_);
          });
        });
        // if(listTies.length > 1){
        //   console.log('ties List',tiesList);
        //   niceTies = [...tiesList, ...niceTies];
        // }
        // else {
        niceTies = tiesList;
        // }
      });
      console.log('Nice Ties', niceTies);

      this.setState(
        {
          clipboardPicks: response.data.entry,
          picks: nicePicks,
          picks_props: niceProps,
          tiesData: niceTies,
          copiarPicksExito: response.status === 200 ? true : false,
          picksCopiados: true,
        },
        () => {
          this.copyTies(response.data.entry);

          if (this.handleCopiarPicks !== undefined) {
            this.handleCopiarPicks();
          }
        }
      );
      if (this.state.clipboardPicks !== null) {
        this.setState({
          temporalRegisterPicks: this.setTemporalRegister(),
        });
      }
      // console.log('Copy Complete', this.state.temporalRegisterPicks)
    });
  },
  /**
   * This function Saves with a (POST) the Picks avilable in the state
   * into the server
   * @param e
   */
  guardarPicks: function (todos) {
    console.log('Submitting', this.state.submitting);
    if (this.state.submitting) {
      return;
    }
    console.log('Guardar Picks', this);
    let tempRegister = this.setTemporalRegister();
    let guardarStatus = null;
    //This is going to be passed to the registration
    let temporalRegister;
    // console.log('Temporal Sve Picks',tempRegister);
    if (tempRegister !== undefined) {
      // console.log('tempRegister Not Null');
      // console.log('Temporal Picks Not Null', this.state.selectedEntryData);
      /**
       * Setting to MisQuinielasPanel
       */
      this.setState({
        submitting: true,
        errorMessage: null,
      });

      if (this.state.numeroEntries !== undefined || this.state.numeroEntries !== null) {
        if (this.state.selectedEntryData !== undefined || this.state.selectedEntryData !== null) {
          // console.log('Just Save Picks');
          let picksWithId = tempRegister.picks;
          let tempKeys;
          if (picksWithId !== null) {
            tempKeys = Object.keys(picksWithId);
            tempKeys = tempKeys.forEach((item) => {
              picksWithId[item].entry_id = tempRegister.entry_id;
            });
          }
          let picksPropsWithId = tempRegister.picks.props;
          if (picksPropsWithId !== null) {
            tempKeys = Object.keys(picksPropsWithId);
            tempKeys = tempKeys.forEach((item) => {
              picksPropsWithId[item].entry_id = tempRegister.entry_id;
            });
          }
          let tiesWithId = tempRegister.picks.props;
          if (tiesWithId !== null) {
            tempKeys = Object.keys(tiesWithId);
            tempKeys = tempKeys.map((item) => {
              tiesWithId[item].entry_id = tempRegister.entry_id;
            });
          }

          temporalRegister = {
            entry_id: this.state.selectedEntryData.id,
            num_entries: 1,
            pool_id: this.state.poolInfo.pool.id,
            picks: tempRegister.picks,
            picks_props: tempRegister.picks_props,
            save_for_all: todos,
            user_tiebreakers: tempRegister.user_tiebreakers,
          };
          // console.log(temporalRegister,this.state.selectedEntryData);
        } else {
          // console.log('Not selectecEntry available');
          temporalRegister = {
            entry_id: null,
            num_entries: 1,
            pool_id: this.state.poolInfo.pool.id,
            picks: tempRegister.picks,
            save_for_all: todos,
            picks_props: tempRegister.picks_props,
            user_tiebreakers: tempRegister.user_tiebreakers,
          };
        }
      } else if (this.props.selectedEntry !== undefined || this.props.selectedEntry !== null) {
        // console.log('Temporal Register with Picks', temporalRegister);
        temporalRegister = {
          entry_id: this.props.selectedEntry,
          num_entries: 1,
          pool_id: this.state.poolInfo.pool.id,
          picks: tempRegister.picks,
          picks_props: tempRegister.picks_props,
          save_for_all: todos,
          user_tiebreakers: tempRegister.user_tiebreakers,
        };
      } else {
        temporalRegister = {
          entry_id: null,
          num_entries: 1,
          pool_id: this.state.poolInfo.pool.id,
          picks: tempRegister.picks,
          picks_props: tempRegister.picks_props,
          user_tiebreakers: tempRegister.user_tiebreakers,
        };
      }
      // console.log('TemporalRegister', temporalRegister);
      API.postSavePicks(temporalRegister)
        .then((response) => {
          console.log('response savePicks', response);
          if (response.data.success) {
            var data = response.data;
            let allPicksCount = data.entry.pick_count + data.entry.pick_prop_count;

            this.setState(
              {
                guardarPicksExito: true,
                submitting: false,
                currentSavedPicks: allPicksCount,
              },
              () => {
                if (this.props.updateMisQuinielas !== undefined) {
                  console.log('Guardar de Picks COMPLETOS');
                  this.props.updateMisQuinielas();
                }
                if (this.updateEntryData) {
                  this.updateEntryData();
                }
                guardarStatus = true;
              }
            );
          } else {
            console.log(response.data.error);
            this.setState(
              {
                errorMessage: response.data.error,
                errorGuardarPicks: true,
                submitting: false,
              },
              () => {
                if (this.updateEntryData) {
                  this.updateEntryData();
                }
                let userData = utils.getUserInfo();
                Sentry.captureException(
                  new Error(JSON.stringify(response.data.error), JSON.stringify(temporalRegister))
                );
                // Sentry.captureMessage('Error is savePicks', temporalRegister);
                guardarStatus = false;
              }
            );
          }
        })
        .catch((error) => {
          console.log('catch savepicks', error);
          this.setState(
            {
              errorGuardarPicks: true,
              submitting: false,
            },
            () => {
              if (this.updateEntryData) {
                this.updateEntryData();
              }
              let userData = utils.getUserInfo();
              Sentry.captureException(error, { extra: 'Error in savePicks' });
              guardarStatus = false;
            }
          );
        });
    } else {
      // console.log('not Temporal Register available',this);
      let temporalRegister;
      if (this.state.entriesInfo.entry === undefined) {
        let temporalRegister = {
          entry_id: this.state.entriesInfo.id,
          pool_id: this.state.poolInfo.pool.id,
        };
      } else {
        let temporalRegister = {
          entry_id: this.state.entriesInfo.entry.id,
          pool_id: this.state.poolInfo.pool.id,
        };
      }
      // console.log('Temporal Register without Picks', temporalRegister);
      API.postSavePicks(temporalRegister)
        .then((response) => {
          // console.log(response);
          if (response.data.success) {
            this.setState(
              {
                guardarPicksExito: true,
                submitting: false,
              },
              () => {
                if (this.updateEntryData) {
                  this.updateEntryData();
                }
                guardarStatus = true;
                // console.log('Guardar Picks  true');
              }
            );
          } else {
            this.setState(
              {
                errorMessage: response.error,
                errorGuardarPicks: true,
                submitting: false,
              },
              () => {
                let userData = utils.getUserInfo();
                Sentry.captureException(
                  new Error(JSON.stringify(response.error), JSON.stringify(temporalRegister))
                );
                guardarStatus = false;
              }
            );
          }
        })
        .catch((error) => {
          this.setState(
            {
              errorGuardarPicks: true,
              submitting: false,
            },
            () => {
              if (this.updateEntryData) {
                this.updateEntryData();
              }
              let userData = utils.getUserInfo();
              Sentry.captureException(error);
              guardarStatus = false;
            }
          );
        });
    }
    //Guardar las picks en el mismo entry id
  },
  /**
   * this function Make a registration withpout any picks available
   * @param e
   */
  registroRapido: function (e) {
    //Registrarse a la quiniela
    console.log('Registro Rapido');
    console.log(this.state);
    this.setTemporalRegister();
    this.setState({
      showPanel: true,
    });
  },
  /**
   * This utility finds a user in the list and add a class if it existe
   **/
  highlightThisUser(currentId, userId) {
    if (currentId === userId) {
      return 'highLightUser';
    }
  },
  /**
   * This function update the Fixtures Live Data
   */
  updateFixtureLive(fixtureData) {
    console.log('Live Update');
  },
  updateEntryWithLiveData(userData, entryData, liveFixture) {
    console.log('update Entry with live Data');
    console.log(entryData, liveFixture);
    let updatedEntryData = null;

    // entryData.

    return updatedEntryData;
  },
};

export default quinielaUtils;
