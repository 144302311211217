import React, { useEffect } from 'react'
import numeral from 'numeral'
import axios from 'axios'
import moment from 'moment'
import $ from 'jquery'
import _ from 'underscore'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'
import { Redirect } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroller'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, MenuItem, Nav, NavItem as NavItemBase } from 'react-bootstrap/lib'

// Components
import Rewards from './Rewards'
import DropdownFilter from './DropdownFilter'
import RewardsHistory from './RewardsHistory'
import Iconify from '../Iconify'
import Store from '../Store/Store'
import ErrorBoundary from '../ErrorBoundary'
import Loading from '../Loading'
import Cards from './Account/components/Cards'
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo'
import UploadAvatar from './Account/components/MyAccount/UploadAvatar'
import Retirar from '../Retirar/Retirar'
import ListadoAmigos from '../ListadoAmigos'
import Bonuses from './Bonuses'
import Referrals from './Account/components/Referrals'
import TransferRealToUser from '../TransferRealToUser/index'
import PageWrapper from '../PageWrapper'
import { UserDataContextConsumer } from '../../context/UserDataContext'
import { NotificationControlsContextProvider } from '../../context/NotificationControlsContext'
import NotificationControls from '../NotificationControls/'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import gaUtils from '../GAWrapper'
import quinielaUtils from '../../utils/quinielasUtils'

// Assets
import billete from '../../assets/images/cash1-50.png'
import pickcoin from '../../assets/images/pickcoin1-50.png'
import pickcoinPlata from '../../assets/images/pickcoin1-50-plata.png'
import freeRollIcon from '../../assets/images/currency-gift.png'
import historial from '../../assets/images/historialIconBlanco-50.png'
import flechas from '../../assets/images/flechasIconBlanco-50.png'
import iconPDF from '../../assets/images/pdf-icon.png'

// Config
import config from '../../config'
import Account from './Account/Account'

// Styles
import '../../../node_modules/react-image-crop/lib/ReactCrop.scss'

import useMergedState from '../../hooks/useMergedState'
import ReferralsVIP from './Account/components/ReferralsVIP'

function UserCategory (props) {
  const initialState = {
    category: props.activeCategory,
    mounted: false,
    limitHistPage: 1,
    filtroTipo: null,
    filtroContest: null,
    submitting: false,
    showAbonarSaldo: false,
    filtroMoneda: null,
    filtroPeriodo: 'week',
    actualPaginaHistorial: 1,
    selectedFilterTipo: 'Tipo',
    selectedFilterMoneda: 'Moneda',
    selectedFilterPeriodo: 'Semanal',
    selectedFilterContest: 'Todos los concursos',
    queryParams: {
      page: 1,
      type: '',
      range: 'week',
      currency: 'real',
      wager: ''
    }
  }

  const [state, setState] = useMergedState(initialState)

  useEffect(() => {
    moment.locale('es')
    const fakeMatch = {
      isExact: true,
      params: {
        slug:
          'Usuario ' +
          props.activeCategory.charAt(0).toUpperCase() +
          props.activeCategory.slice(1)
      }
    }
    gaUtils.handleDocumentAttributes(fakeMatch)

    setState({ mounted: true })
  }, [])

  const siguientePag = (eventKey) => {
    if (state.queryParams.page >= props.limitHistPage) {
      return
    }

    const loaderElement = $('#loading')

    if (props.submitting || state.submitting) {
      return
    }

    setState({ submitting: true })

    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex'
    }

    const temporalQuery = state.queryParams

    temporalQuery.page = temporalQuery.page + 1

    const temporalTransactions = props.transacciones.transactions

    API.fetchTransactions(temporalQuery).then((responseTransaction) => {
      console.log(responseTransaction)
      setState(
        (prevState) => {
          const newTransactions = responseTransaction.data
          const newItems = responseTransaction.data.transactions
          newItems.forEach((item) => {
            temporalTransactions.push(item)
          })

          newTransactions.transactions = temporalTransactions

          return {
            submitting: false,
            queryParams: temporalQuery,
            transacciones: newTransactions,
            logged: props.logged,
            actualPaginaHistorial: prevState.actualPaginaHistorial + 1
          }
        },
        () => {
          if (loaderElement !== undefined && loaderElement.length > 0) {
            loaderElement[0].style.display = 'none'
          }
        }
      )
    })
  }

  const showAbonarPop = () => {
    setState((prevState) => {
      return {
        showAbonarSaldo: !prevState.showAbonarSaldo
      }
    })
  }

  const updatePanelSate = () => {
    setState({
      showAbonarSaldo: false
    })
  }

  const updateAvatarState = () => {
    props.updateUserInfo()
    setState({
      cambiarAvatarPanel: false
    })
  }

  const handleTypeFilterMoneda = (eventKey) => {
    let pased = null
    if (eventKey === 'pickcoin') {
      pased = 'Pickcoins'
    } else if (eventKey === 'referral') {
      pased = 'Referral'
    } else if (eventKey === 'real') {
      pased = 'Real'
    } else if (eventKey === 'freeRoll') {
      pased = 'Regalo'
    } else if (eventKey === 'bonus') {
      pased = 'bonus'
    } else {
      pased = 'Todas'
    }
    const temporalQueryParams = state.queryParams
    temporalQueryParams.currency = eventKey === 'allCurrency' ? '' : eventKey
    temporalQueryParams.page = 1

    setState(
      {
        queryParams: temporalQueryParams,
        filtroMoneda: eventKey === 'allCurrency' ? '' : eventKey,
        selectedFilterMoneda: pased
      },
      () => {
        props.getTransactions(
          temporalQueryParams.currency,
          temporalQueryParams.type,
          temporalQueryParams.range,
          temporalQueryParams.wager
        )
      }
    )
  }

  const handleTypeFilterPerido = (eventKey) => {
    let pased = null
    if (eventKey === 'week') {
      pased = 'Semanal'
    } else if (eventKey === 'last_month') {
      pased = 'Mes Pasado'
    } else if (eventKey === 'six_months') {
      pased = 'Seis Meses'
    } else {
      pased = 'Todos'
    }

    const temporalQueryParams = state.queryParams
    temporalQueryParams.range = eventKey === 'allPeriodo' ? '' : eventKey
    temporalQueryParams.page = 1
    setState(
      {
        queryParams: temporalQueryParams,
        filtroPeriodo: eventKey,
        selectedFilterPeriodo: pased
      },
      () => {
        props.getTransactions(
          temporalQueryParams.currency,
          temporalQueryParams.type,
          temporalQueryParams.range,
          temporalQueryParams.wager
        )
      }
    )
  }

  const handleTypeFilterContest = (eventKey) => {
    let pased = null
    if (eventKey === '') {
      pased = 'Todos los concursos'
    } else if (eventKey === '0') {
      pased = 'Quiniela'
    } else if (eventKey === '1') {
      pased = 'Apuestas'
    } else {
      pased = 'Todos los concursos'
    }

    const temporalQueryParams = state.queryParams
    temporalQueryParams.wager = eventKey
    temporalQueryParams.page = 1
    setState(
      {
        queryParams: temporalQueryParams,
        filtroContest: eventKey,
        selectedFilterContest: pased
      },
      () => {
        props.getTransactions(
          temporalQueryParams.currency,
          temporalQueryParams.type,
          temporalQueryParams.range,
          temporalQueryParams.wager
        )
      }
    )
  }

  const handleTypeFilterTipo = (eventKey) => {
    let pased = null
    if (eventKey === 'gift') {
      pased = 'Premios'
    } else if (eventKey === 'entry_fee') {
      pased = 'Entrada'
    } else if (eventKey === 'winnings') {
      pased = 'Ganancias'
    } else if (eventKey === 'refund') {
      pased = 'Reembolso'
    } else if (eventKey === 'exchange') {
      pased = 'Canje'
    } else if (eventKey === 'purchase') {
      pased = 'Compra'
    } else if (eventKey === 'referral') {
      pased = 'Referidos'
    } else if (eventKey === 'comission') {
      pased = 'Comisión quiniela'
    } else if (eventKey === 'wager') {
      pased = 'Apuesta'
    } else if (eventKey === 'bonus') {
      pased = 'Bono'
    } else {
      pased = 'Todas'
    }

    const temporalQueryParams = state.queryParams
    temporalQueryParams.type = eventKey === 'allTypes' ? '' : eventKey
    temporalQueryParams.page = 1

    setState(
      {
        queryParams: temporalQueryParams,
        filtroTipo: eventKey,
        selectedFilterTipo: pased
      },
      () => {
        props.getTransactions(
          temporalQueryParams.currency,
          temporalQueryParams.type,
          temporalQueryParams.range,
          temporalQueryParams.wager
        )
      }
    )
  }

  const getWagerPdf = (id) => {
    API.getWagerPdf(id)
      .then((response) => {
        const data = response.data
        if (data.pdf && data.pdf.url) {
          window.open(data.pdf.url, '_blank')
        }
      })
  }

  const parseType = (item) => {
    switch (item) {
      case 'gift': return 'Gift'
      case 'entry_fee': return 'Entrada'
      case 'winnings': return 'Ganancias'
      case 'refund': return 'Rembolso'
      case 'bonus': return 'Bono'
      case 'cashout_request': return 'Cashout'
      case 'exchange': return 'Canje'
      case 'purchase': return 'Compra'
      case 'referral': return 'Referido'
      case 'transfer_in': return 'Transferencia'
      case 'transfer_out': return 'Transferencia'
      case 'wager': return 'Apuesta'
      default: return item
    }
  }

  useEffect(() => {
    if (props.activeCategory === 'historial' && !props.transacciones) {
      props.getTransactions()
    }
  }, [])

  let conditionalComponent
  let transaccionesLista
  if (
    (props.activeCategory === 'historial' && !props.transacciones) ||
    (props.activeCategory === 'personal' && !props.userData) ||
    (props.activeCategory === 'abonar' && !props.userData)
  ) {
    conditionalComponent = <Loading label='Estamos buscando tu información ...' />
  } else {
    transaccionesLista =
      props.transacciones !== null ? props.transacciones.transactions : []
    if (transaccionesLista.length > 0) {
      transaccionesLista = transaccionesLista.map((transaccion, i) => {
        let currencyIcon = null
        let currencyAbbrev = null
        let currencyFormat = null

        switch (transaccion.currency) {
          case 'real':
            currencyIcon = <img src={billete} className='table-icons' alt='' />
            currencyFormat = '$0,000.00'
            currencyAbbrev = 'MXN'
            break
          case 'pickcoin':
            currencyIcon = <img src={pickcoin} className='table-icons' alt='' />
            currencyFormat = '0,000.00'
            currencyAbbrev = 'PC'
            break
          case 'referral':
            currencyIcon = <img src={pickcoinPlata} className='table-icons' alt='' />
            currencyFormat = '0'
            currencyAbbrev = 'TK'
            break
          case 'freeroll':
            currencyIcon = <img src={freeRollIcon} className='table-icons' alt='' />
            currencyFormat = '0'
            currencyAbbrev = ''
            break
        }

        return (
          <li
            className='transaction-item flex-row'
            key={`${transaccion.id}${i}${transaccion.entry_id}`}
          >
            <div className='transaction-category flex-col col-15 fecha'>
              <span>{moment(transaccion.created).format('DD/M/YY H:m')}</span>
            </div>
            <div className='transaction-category flex-col col-10 type'>
              <span>{parseType(transaccion.type)}</span>
            </div>
            <div className='transaction-category flex-col col-20 type'>
              <span>{transaccion.concept}</span>
            </div>
            <div className='transaction-category flex-col col-20 amount'>
              {currencyIcon}
              <span>
                {numeral(transaccion.amount / 100).format(currencyFormat)} {currencyAbbrev}
              </span>
            </div>
            <div className='transaction-category flex-col col-20 balance'>
              {currencyIcon}
              <span>
                {numeral(transaccion.balance / 100).format(currencyFormat)} {currencyAbbrev}
              </span>
            </div>
            
            {
              transaccion.type === 'wager'
                ? (
                  <div className='flex-col col-10 text-center'>
                    <a href='#' onClick={(e) => getWagerPdf(transaccion.pool_id)}>
                      <img src={iconPDF} alt='Descargar Boleto' style={{ width: '24px' }} />
                    </a>
                  </div>
                )
                : (
                  <div className='flex-col col-10'  />
                ) 
            }

          </li>
        )
      })
    }

    const userLoyalty = utils.getUserLoyalty()

    switch (props.activeCategory) {
      case 'abonar':
        conditionalComponent = (
          <div className='user-categories-container col-xs-12 col-md-9  '>
            <div className='category-user row' style={{ paddingTop: '0px' }}>
              <div className='detalles-cuenta'>
                <div className='flex-container justify align'>
                  <div className='flex-col'>
                    <div className='titulo-user-info'>
                      <div className='flex-col-10'>
                        <img src={flechas} className='title-icon' alt='' />
                      </div>
                      <div className='flex-col'>
                        <span>Detalles de Cuenta</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex-row'>
                  <div className='user-info-real flex-container justify align  column '>
                    <div>
                      <div className='flex-row row-center '>
                        <img src={billete} className='pool-icons' alt='' style={{ width: '25px', height: '15px', marginRight: '5px' }} />
                        <span>Balance:</span>
                        <strong className='flex-col text-center green-text'>
                          {numeral(utils.getUserBalance().balanceReal / 100).format('$0,000.00')}MXN
                        </strong>
                      </div>
                    </div>
                  </div>

                  <div className='user-info-real flex-container justify align  column '>
                    <div className=''>
                      <div className='flex-row row-center '>
                        <img src={pickcoin} className='pool-icons' alt='' style={{ width: '25px', height: '15px', marginRight: '5px' }} />
                        <span>Balance:</span>
                        <strong className='flex-col text-center yellow-text'>
                          {numeral(utils.getUserBalance().balancePc / 100).format('0,000.00')}PC
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <AbonarSaldo
              type='static'
              disableClickedButton={props.disableClickedButton}
              showAbonarSaldo
              MisTarjetas={props.misTarjetas}
              updateUserBalance={props.updateUserBalance}
              updateUserInfo={props.updateUserInfo}
            />
            <UploadAvatar
              disableClickedButton={props.disableClickedButton}
              showCambiarAvatar={state.cambiarAvatarPanel}
              updatePanelState={updateAvatarState}
              userData={state.userData}
              updatedAvatar={props.updatedAvatar}
            />
            <AbonarSaldo
              disableClickedButton={props.disableClickedButton}
              type='modal'
              showAbonarSaldo={state.showAbonarSaldo}
              updatePanelSate={updatePanelSate}
              showAbonarPop={showAbonarPop}
              updateUserInfo={props.updateUserInfo}
              updateUserBalance={props.updateUserBalance}
            />
          </div>
        )
        break
      case 'invitados-vip':
          conditionalComponent = (
            <div className='user-categories-container col-xs-12 col-md-9'>
              <div className='category-user row' style={{ paddingTop: '1em' }}>
                <div className='detalles-cuenta'>
                  <div className='flex-container justify align'>
                    <div className='flex-col'>
                      <div className='titulo-user-info'>
                        <div className='flex-col-10'>
                          <img src={flechas} className='title-icon' alt='' />
                        </div>
                        <div className='flex-col'>
                          <span>Invitados VIP</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReferralsVIP
                    // General
                    userData={props.userData}
                    onShowAddBalance={showAbonarPop}
                    disableClickedButton={props.disableClickedButton}
                    showCambiarAvatar={state.cambiarAvatarPanel}
                    updatePanelState={updateAvatarState}
                    updatedAvatar={props.updatedAvatar}
                    // Docs
                    misDocumentos={props.misDocumentos}
                    misTickets={props.misTickets}
                    // Amigos
                    availableCashout={props.availableCashout}
                    openAbonar={showAbonarPop}
                    userBalance={props.userBalance}
                    updateUserBalance={props.updateUserBalance}
                    updateUserInfo={props.updateUserInfo}
                    // Referrals
                    showAbonar={showAbonarPop}
                  />
                </div>
              </div>
            </div>
          )
      break
      case 'bonos':
        conditionalComponent = (
          <div className='user-categories-container col-xs-12 col-md-9'>
            <div className='category-user row' style={{ paddingTop: '1em' }}>
              <div className='detalles-cuenta'>
                <div className='flex-container justify align'>
                  <div className='flex-col'>
                    <div className='titulo-user-info'>
                      <div className='flex-col-10'>
                        <img src={flechas} className='title-icon' alt='' />
                      </div>
                      <div className='flex-col'>
                        <span>Bonos</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Bonuses />
              </div>
            </div>
          </div>
        )
        break
      case 'tienda':
        conditionalComponent = (
          <div className='user-categories-container col-xs-12 col-md-9  '>
            <div className='category-user row' style={{ paddingTop: '1em' }}>
              <div className='detalles-cuenta'>
                <div className='flex-container justify align'>
                  <div className='flex-col'>
                    <div className='titulo-user-info'>
                      <div className='flex-col-10'>
                        <img src={flechas} className='title-icon' alt='' />
                      </div>
                      <div className='flex-col'>
                        <span>Tienda VIP</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Store />
              </div>
            </div>
          </div>
        )
        break
      case 'mis_tarjetas':
        conditionalComponent = (
          <div className='user-categories-container col-xs-12 col-md-9  '>
            <div className='category-user row'>
              <div className='detalles-cuenta'>
                <div className='titulo-user-info col-xs-10 col-xs-offset-1'>
                  <div className='flex-col-10'>
                    <img src={flechas} className='title-icon' alt='' />
                  </div>
                  <div className='flex-col'>
                    <span>Detalles de Cuenta</span>
                  </div>
                </div>
                <div className='flex-col col-90 col-offset-5'>
                  <div className='flex-row row-center '>
                    <div className='user-info-real flex-container justify align  column '>
                      <div className=''>
                        <div className='flex-row row-center '>
                          <img src={billete} className='pool-icons' alt='' style={{ marginRight: '5px' }} />
                          <span>Balance:</span>
                          <strong className='flex-col text-center green-text'>
                            {numeral(utils.getUserBalance().balanceReal / 100).format('$0,000.00')}MXN
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className='user-info-real flex-container justify align column '>
                      <div className=''>
                        <div className='flex-row row-center '>
                          <img src={pickcoin} className='pool-icons' alt='' style={{ marginRight: '5px' }} />
                          <span>Balance:</span>
                          <strong className='flex-col text-center yellow-text'>
                            {numeral(utils.getUserBalance().balancePc / 100).format('0,000.00')}PC
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Cards
                    updateUserInfo={props.updateUserInfo}
                    updateUserBalance={props.updateUserBalance}
                  />
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 'canjear':
        conditionalComponent = (
          <div className='col-md-9 col-xs-12 user-categories-container'>
            <TransferRealToUser
              type='static'
              availableCashout={props.availableCashout}
              updateUserAvailable={props.updateUserAvailable}
              updateUserInfo={props.updateUserInfo}
              updateUserBalance={props.updateUserBalance}
            />
          </div>
        )
        break
      case 'notificaciones':
        conditionalComponent = (
          <div className='col-md-9 col-xs-12 user-categories-container'>
            <div className='flex-container justify align column'>
              <NotificationControlsContextProvider>
                <NotificationControls />
              </NotificationControlsContextProvider>
            </div>
          </div>
        )
        break
      case 'historial':
        conditionalComponent = (
          <div className='user-categories-container col-xs-12 col-md-9  '>
            <div className='category-user row historial'>
              <div className='info-historial flex-container column'>
                <div className='titulo-user-info col-xs-10 col-xs-offset-1'>
                  <div className=''>
                    <img src={historial} className='title-icon' alt='' />
                  </div>
                  <div className='flex-col'>
                    <span>Historial</span>
                  </div>
                </div>
                <div className='subtitulo-user-info col-xs-10 col-xs-offset-1'>
                  <div className='flex-col col-15'>
                    <img src={flechas} className='title-icon' alt='' />
                  </div>
                  <div className='flex-col'>
                    <span>Mostrando transacciones</span>
                  </div>
                </div>
                <div className='user-historial-balance'>
                  <div className='user-balance'>
                    <div className='historial-balance-item'>
                      <span>
                        <img src={billete} className='pool-icons' alt='' />
                      </span>
                      <span>
                        <strong className='mr-05'>Balance:</strong>
                        <strong className='green-text'>{numeral(utils.getUserBalance().balanceReal / 100).format('$0,000.00')}MXN</strong>
                      </span>
                    </div>
                    <div className='historial-balance-item mb-2'>
                      <span>
                        <img src={pickcoin} className='pool-icons' alt='' />
                      </span>
                      <span>
                        <strong className='mr-05'>Balance:</strong>
                        <strong>
                          {numeral(utils.getUserBalance().balancePc / 100).format('0,000')}
                          {' PC'}
                        </strong>
                      </span>
                    </div>
                  </div>
                  <div className='deposito-btn'>
                    <Button className='btn-cuenta' bsStyle='success' onClick={showAbonarPop}>
                      <span>DEPOSITAR</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='historial-transacciones'>
                <div className='historial-filters'>
                  <DropdownFilter id='monedas-filtros' title={state.selectedFilterMoneda}>
                    <MenuItem eventKey='allCurrency' onSelect={handleTypeFilterMoneda}>
                      Todas
                    </MenuItem>
                    <MenuItem eventKey='real' onSelect={handleTypeFilterMoneda}>
                      Real
                    </MenuItem>
                    <MenuItem eventKey='pickcoin' onSelect={handleTypeFilterMoneda}>
                      Pickcoin
                    </MenuItem>
                    <MenuItem eventKey='referral' onSelect={handleTypeFilterMoneda}>
                      Token
                    </MenuItem>
                    <MenuItem eventKey='freeRoll' onSelect={handleTypeFilterMoneda}>
                      Regalo
                    </MenuItem>
                    <MenuItem eventKey='bonus' onSelect={handleTypeFilterMoneda}>
                      FreePlay
                    </MenuItem>
                  </DropdownFilter>
                  <DropdownFilter id='tipos-filtro' title={state.selectedFilterTipo}>
                    <MenuItem eventKey='allTypes' onSelect={handleTypeFilterTipo}>
                      Todas
                    </MenuItem>
                    <MenuItem eventKey='gift' onSelect={handleTypeFilterTipo}>
                      Premio
                    </MenuItem>
                    <MenuItem eventKey='entry_fee' onSelect={handleTypeFilterTipo}>
                      Entrada
                    </MenuItem>
                    <MenuItem eventKey='wager' onSelect={handleTypeFilterTipo}>
                      Apuesta
                    </MenuItem>
                    <MenuItem eventKey='winnings' onSelect={handleTypeFilterTipo}>
                      Ganancias
                    </MenuItem>
                    <MenuItem eventKey='refund' onSelect={handleTypeFilterTipo}>
                      Reembolso
                    </MenuItem>
                    <MenuItem eventKey='bonus' onSelect={handleTypeFilterTipo}>
                      Bono
                    </MenuItem>
                    <MenuItem eventKey='exchange' onSelect={handleTypeFilterTipo}>
                      Canje
                    </MenuItem>
                    <MenuItem eventKey='purchase' onSelect={handleTypeFilterTipo}>
                      Compra
                    </MenuItem>
                    <MenuItem eventKey='referral' onSelect={handleTypeFilterTipo}>
                      Referidos
                    </MenuItem>
                    <MenuItem eventKey='comission' onSelect={handleTypeFilterTipo}>
                      Comisión Quiniela
                    </MenuItem>
                  </DropdownFilter>
                  <DropdownFilter id='concurso-filtro' title={state.selectedFilterContest}>
                    <MenuItem eventKey='' onSelect={handleTypeFilterContest}>
                      Todos los concursos
                    </MenuItem>
                    <MenuItem eventKey='0' onSelect={handleTypeFilterContest}>
                      Quiniela
                    </MenuItem>
                    <MenuItem eventKey='1' onSelect={handleTypeFilterContest}>
                      Apuestas
                    </MenuItem>
                  </DropdownFilter>
                  <DropdownFilter id='periodo-filtro' title={state.selectedFilterPeriodo}>
                    <MenuItem eventKey='allPeriodo' onSelect={handleTypeFilterPerido}>
                      Todas
                    </MenuItem>
                    <MenuItem eventKey='week' onSelect={handleTypeFilterPerido}>
                      Semanal
                    </MenuItem>
                    <MenuItem eventKey='last_month' onSelect={handleTypeFilterPerido}>
                      Mes Pasado
                    </MenuItem>
                    <MenuItem eventKey='six_months' onSelect={handleTypeFilterPerido}>
                      Seis Meses
                    </MenuItem>
                  </DropdownFilter>
                </div>
                <div className='lista-historial'>
                  {props.transacciones !== null ? (
                    <ul className='lista-transactions'>
                      <li className='transaction-header flex-row '>
                        <div className='transaction-category flex-col col-15 type'>Fecha</div>
                        <div className='transaction-category flex-col col-10 type'>Tipo</div>
                        {/* <div className="transaction-category flex-colcol-5 pool">ID</div> */}
                        <div className='transaction-category flex-col col-20 type'>Quiniela</div>
                        <div className='transaction-category flex-col col-20 amount' style={{ paddingLeft: 33 }}>
                          Cantidad
                        </div>
                        <div className='transaction-category flex-col col-20 balance' style={{ paddingLeft: 33 }}>
                          Balance
                        </div>
                        <div className='flex-col col-10 text-center'>
                          <Iconify icon='fa:download'/>
                        </div>
                      </li>
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={siguientePag}
                        hasMore={
                          props.transacciones.pages !== state.actualPaginaHistorial
                        }
                        loader={<Loading key='inif' label='...' />}
                        useWindow={false}
                      >
                        {props.submitting
                          ? (
                            <div className='flex-container justify align'>
                              <i className='ion-load-a loading' />
                            </div>
                            )
                          : transaccionesLista.length === 0
                            ? (
                              <div className='flex-container column justify align'>
                                <div className='flex-col col-center text-center'>
                                  No tienes ningun movimiento disponible.
                                </div>
                              </div>
                              )
                            : (
                                transaccionesLista
                              )}
                      </InfiniteScroll>
                    </ul>
                  ) : null}
                </div>
              </div>
              <AbonarSaldo
                showAbonarSaldo={state.showAbonarSaldo}
                updatePanelSate={updatePanelSate}
                showAbonarPop={showAbonarPop}
                updateUserInfo={props.updateUserInfo}
                type='modal'
              />
            </div>
          </div>
        )
        break
      case 'retirar':
        if (props.misDocumentos !== null) {
          conditionalComponent = (
            <Retirar
              // solicitar Retiro
              disableClickedButton={props.disableClickedButton}
              availableCashout={props.availableCashout}
              documentos={props.misDocumentos.documents}
              validForm={state.validForm}
              formName={state.formName}
              formPhone={state.formPhone}
              formBank={state.formBank}
              formMethod={state.formMethod}
              formAccount={state.formAccount}
              formAmount={state.formAmount}
              formIdNumber={state.formIdNumber}
              formIdExpirationYear={state.formIdExpirationYear}
              formIdExpirationMonth={state.formIdExpirationMonth}
              formIdExpirationDay={state.formIdExpirationDay}
              formFileUpload={state.formFileUpload}
              formPswd={state.formPswd}
              passwordIncorrecto={state.passwordIncorrecto}
              documentoIncorrecto={state.documentoIncorrecto}
              retiroExitoso={state.retiroExitoso}
              montoIncorrecto={state.montoIncorrecto}
              userData={props.userData}
              userBalance={props.userBalance}
              updateUserBalance={props.updateUserBalance}
              updateUserInfo={props.updateUserInfo}
            />
          )
        } else {
          conditionalComponent = <Loading label='Cargando Info ...' />
        }
        break
      case 'invitados':
        conditionalComponent = (
          <div>
            <AbonarSaldo
              showAbonarSaldo={state.showAbonarSaldo}
              updatePanelSate={updatePanelSate}
              showAbonarPop={showAbonarPop}
              updateUserInfo={props.updateUserInfo}
              type='modal'
            />
            <Referrals
              showAbonar={showAbonarPop}
              updateUserInfo={props.updateUserInfo}
              userData={props.userData}
            />
          </div>
        )
        break
      case 'invitados VIP':
          conditionalComponent = (
            <div>
              <AbonarSaldo
                showAbonarSaldo={state.showAbonarSaldo}
                updatePanelSate={updatePanelSate}
                showAbonarPop={showAbonarPop}
                updateUserInfo={props.updateUserInfo}
                type='modal'
              />
              <Referrals
                showAbonar={showAbonarPop}
                updateUserInfo={props.updateUserInfo}
                userData={props.userData}
              />
            </div>
          )
          break
      case 'amigos':
        conditionalComponent = (
          <div>
            <AbonarSaldo
              showAbonarSaldo={state.showAbonarSaldo}
              updatePanelSate={updatePanelSate}
              showAbonarPop={showAbonarPop}
              updateUserInfo={props.updateUserInfo}
              type='modal'
            />
            <ListadoAmigos
              availableCashout={props.availableCashout}
              userData={props.userData}
              openAbonar={showAbonarPop}
              userBalance={props.userBalance}
              updateUserBalance={props.updateUserBalance}
              updateUserInfo={props.updateUserInfo}
            />
          </div>
        )
        break
      case 'recompensas':
        conditionalComponent = <Rewards userLoyalty={userLoyalty} />
        break
      case 'historial_recompensas':
        conditionalComponent = <RewardsHistory />
        break
      default:
        conditionalComponent = (
          <div className='user-categories-container col-xs-12 col-md-9  '>
            <div className='category-user row'>
              <div className='col-xs-10 col-xs-offset-1 titulo-user-info'>
                <div className=''>
                  <img src={flechas} className='title-icon' alt='' />
                </div>
                <div className='flex-col'>
                  <span>Información Personal</span>
                </div>
              </div>
              <div className='col-lg-10 col-lg-offset-1 col-xs-12'>
                <Account
                  // General
                  userData={props.userData}
                  onShowAddBalance={showAbonarPop}
                  disableClickedButton={props.disableClickedButton}
                  showCambiarAvatar={state.cambiarAvatarPanel}
                  updatePanelState={updateAvatarState}
                  updatedAvatar={props.updatedAvatar}
                  // Docs
                  misDocumentos={props.misDocumentos}
                  misTickets={props.misTickets}
                  // Amigos
                  availableCashout={props.availableCashout}
                  openAbonar={showAbonarPop}
                  userBalance={props.userBalance}
                  updateUserBalance={props.updateUserBalance}
                  updateUserInfo={props.updateUserInfo}
                  // Referrals
                  showAbonar={showAbonarPop}
                />
              </div>
            </div>
            {props.promotions
              ? (
                <div className='category-user row'>
                  <div className='detalles-cuenta'>
                    <div className='titulo-user-info col-xs-10 col-xs-offset-1'>
                      <div className='flex-col-10'>
                        <img src={flechas} className='title-icon' alt='' />
                      </div>
                      <div className='flex-col'>
                        <span>Promoción Duplicamos tu Primer Depósito</span>
                      </div>
                    </div>

                    <div className='flex-row'>
                      <div className='flex-col col-offset-10 col-60'>
                        Liberado:{' '}
                        <strong>
                          ${numeral(props.promotions.amount_given / 100).format('0,000.00')}
                        </strong>
                      </div>
                      <div className='flex-col'>
                        Total:{' '}
                        <strong>
                          ${numeral(props.promotions.amount / 100).format('0,000.00')}
                        </strong>
                      </div>
                    </div>
                    <div className='flex-row'>
                      <div className='flex-col col-offset-10 col-80'>
                        <div className='progressBar'>
                          <div
                            className='currentProgress'
                            style={{
                              width:
                              (props.promotions.amount_given /
                                props.promotions.amount) *
                                100 +
                              '%'
                            }}
                          />
                          <div className='toNextLevelProgress' style={{ width: '100%' }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              : null}
            <AbonarSaldo
              disableClickedButton={props.disableClickedButton}
              showAbonarSaldo={state.showAbonarSaldo}
              updatePanelSate={updatePanelSate}
              showAbonarPop={showAbonarPop}
              updateUserInfo={props.updateUserInfo}
              type='modal'
            />
          </div>
        )
    }
  }
  return <ErrorBoundary isPanel>{conditionalComponent}</ErrorBoundary>
}

const NavItem = styled(NavItemBase)`
  a {
    gap: 0.5em 0.7em;
    display: flex !important;
    align-items: center;
  }
  
  svg {
    font-size: 16px;
  }

  span {
    font-size: 13px;
  }
`



/**
 *This function return the UserInfoMenu component
 @param props
 */
const UserInfoMenu = (props) => {
  const hideReferralVIP = !props?.userData?.has_vip_referrals

  let menuList = config.userMenu

  if (hideReferralVIP) {
    menuList = config.userMenu.filter(item => item.id !== 'invitados-vip')
  }

  return (
    <Nav bsStyle='pills' stacked>
      {menuList.map(option => (
        <LinkContainer
          to={option.link}
          className={`user-abonar user-item ${props.activeCategory === option.id ? 'active' : ' '} `}
        >
          <NavItem id={option.id} onClick={props.handleSelect}>
            <Iconify icon={option.icon} />
            <span>{option.title}</span>
          </NavItem>
        </LinkContainer>
      ))}
    </Nav>
  )
}

function InfoContainer (props) {
  const initialState = {
    showAddDocsValue: false,
    showAddCardValue: false,
    addCard: false,
    showChangePass: false,
    addDocs: false,
    editInfo: false,
    editAvatar: false,
    activeCategory: props.selection,
    misDocumentos: props.misDocumentos
  }

  const [state, setState] = useMergedState(initialState)

  useEffect(() => {
    if (props.activeCategory !== state.activeCategory) {
      setState({
        activeCategory: props.activeCategory
      })
    }
  }, [props.activeCategory, state.activeCategory])

  const showPass = () => {
    setState({
      showChangePass: !state.showChangePass
    })
  }

  const handleSelect = (e) => {
    if (!e.target.attributes.id) return

    const which = e.target.attributes.id.value
    let cual = props.location.split('/usuario/')
    cual = cual[1]
    setState(
      {
        activeCategory: which
      },
      () => {
        const fakeMatch = {
          isExact: true,
          params: {
            slug: 'Usuario ' + which.charAt(0).toUpperCase() + which.slice(1)
          }
        }
        gaUtils.handleDocumentAttributes(fakeMatch)
        props.changeCategory()
      }
    )
  }

  const showAddCard = () => {
    setState((prevState) => ({
      showAddCardValue: !prevState.showAddCardValue
    }))
  }

  const showAddDocs = () => {
    setState((prevState) => ({
      showAddDocsValue: !prevState.showAddDocsValue
    }))
  }

  if (!props.userData) {
    return (
      <Loading label='Cargando datos del usuario...' />
    )
  }

  return (
    <div className='container'>
      <div className='row'>
        <div id='info-container'>
          <div className='row'>
            <div className='titulo-info col-xs-12'>
              <span> Mi Cuenta </span>
            </div>
            <div className='col-xs-12 col-md-3 user-info-menu'>
              <UserInfoMenu
                activeCategory={state.activeCategory}
                handleSelect={handleSelect}
                userData={props?.userData}
              />
            </div>
            <UserCategory
              limitHistPage={props.limitHistPage}
              userDataChanged={props.userDataChanged}
              submitting={props.submitting}
              activeCategory={state.activeCategory}
              updateCardList={props.updateCardList}
              availableCashout={props.availableCashout}
              addCard={state.addCard}
              disableClickedButton={props.disableClickedButton}
              addDocs={state.addCard}
              changeCategory={props.changeCategory}
              commissionRange={props.commissionRange}
              deleteCard={props.deleteCard}
              handleDelete={props.handleDelete}
              handleClose={props.handleClose}
              handleAddCard={props.handleAddCard}
              getCommissions={props.getCommissions}
              getTransactions={props.getTransactions}
              misDocumentos={props.misDocumentos}
              misTickets={props.misTickets}
              referals={props.referals}
              showAbonarPop={props.showAbonarPop}
              showAddCard={showAddCard}
              showAddCardValue={state.showAddCardValue}
              showAddDocs={showAddDocs}
              showAddDocsValue={state.showAddDocsValue}
              showChangePass={state.showChangePass}
              showPass={showPass}
              transacciones={props.transacciones}
              userStatus={props.userStatus}
              userData={props.userData}
              promotions={props.promotions}
              userBalance={props.userBalance}
              updatedAvatar={props.updatedAvatar}
              updateUserInfo={props.updateUserInfo}
              updateUserAvailable={props.updateUserAvailable}
              updateUserBalance={props.updateUserBalance}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function UserInfo (props) {
  const selection = props.location.pathname.split('/usuario/')

  const initialState = {
    actualPaginaHistorial: 1,
    availableCashout: null,
    avatarUpdated: false,
    commissionRange: 'total',
    logged: false,
    misDocumentos: null,
    misTarjetas: null,
    mounted: false,
    referals: null,
    selection,
    showModal: false,
    submitting: false,
    transacciones: null,
    userData: null,
    promotions: null,
    userDataChanged: false,
    queryParams: {
      page: 1,
      currency: 'real',
      range: 'week',
      type: '',
      wager: ''
    }
  }
  const [state, setState] = useMergedState(initialState)

  const disableClickedButton = quinielaUtils.disableClickedButton.bind(this)

  // componentDidMount
  useEffect(() => {
    API.fetchUserData().then((response) => {
      const responses = [response]
      try {
        utils.checkAxiosAllErrors(responses)
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
      }
      utils.setUserInfo(response.data)

      const userStatus = {
        prizes_count: response.data.prizes_count,
        currentLevel: response.data.currentLevel,
        nextLevel: response.data.nextLevel,
        status: response.data.status
      }

      const promotions = response.data.promotions

      setState(
        {
          userStatus,
          userData: response.data,
          promotions,
          mounted: true
        },
        () => {
          isLogged()
          axios
            .all([
              API.fetchTicketsAvailable(),
              API.fetchUserDocuments(),
              API.fetchAvailableCashout()
            ])
            .then(
              axios.spread((availableTickets, userDocs, availableCashout) => {
                setState(
                  {
                    availableCashout: availableCashout.data,
                    misDocumentos: userDocs.data,
                    misTickets: availableTickets.data.tickets,
                    mounted: true
                  },
                  () => {
                    changeCategory()
                    getTransactions('real', '', 'week')
                  }
                )
              })
            )
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error Fetching Axios all in USer Info'
              })
            })
        }
      )
    })
  }, [])

  // componentDidUpdate
  useEffect(() => {
    const which = props.location.pathname.split('/usuario/')

    if (which && (which[1] !== state.selection)) {
      setState({ selection: which[1] })
    }
  }, [props.location.pathname])

  const updateCardList = () => {
    API.fetchOpenPayCards().then((response) => {
      setState({
        misTarjetas: response.data
      })
    })
  }

  const deleteCard = (id) => {
    setState({
      showModal: true,
      deleteCardId: id
    })
  }

  const handleClose = () => {
    setState({
      showModal: false
    })
  }

  const handleAddCard = () => {
    API.addOpenPayCard().then((response) => {
      console.log(response)
    })
  }

  const handleDelete = () => {
    API.postOpenPayCardsDelete(state.deleteCardId).then((response) => {
      if (response.data.success) {
        const tempCards = state.misTarjetas
        let deletedCardIndx = null
        const deletedCard = tempCards.cards.find((card, cardIndx) => {
          if (card.id === state.deleteCardId) {
            deletedCardIndx = cardIndx
            return card
          }
        })
        if (deletedCard !== undefined) {
          tempCards.cards.splice(deletedCardIndx, 1)
          setState({
            showModal: false,
            misTarjetas: tempCards
          })
        }
      }
    })
  }

  const updatedAvatar = () => {
    setState({
      avatarUpdated: true,
      userData: utils.getUserInfo()
    })
  }

  /**
   * This function Handles the category of the component
   @param range
   */
  const changeCategory = () => {
    let which = props.location.pathname.split('/usuario/')
    which = which[1]
    return which
  }

  /**
   * This function get the info of the API  to show it tin the tables o Commmision
   @param range
   */
  const isLogged = () => {
    if (state.userData !== null) {
      if (state.userData.user !== null) {
        setState({
          logged: true
        })
      }
    } else {
      setState({
        logged: false
      })
    }
  }

  /**
   * This calls to the API  to fetch Transactions
   @param range
   */
  const getTransactions = (moneda, tipo, periodo, wager) => {
    const temporalQuery = state.queryParams

    wager = wager === undefined ? '' : wager
    wager = wager !== '' ? parseInt(wager) : wager

    temporalQuery.currency = moneda
    temporalQuery.type = tipo
    temporalQuery.range = periodo
    temporalQuery.wager = wager

    setState({ submitting: true })

    API.fetchTransactions(temporalQuery).then((response) => {
      setState({
        submitting: false,
        limitHistPage: response.data.pages,
        queryParams: temporalQuery,
        transacciones: response.data,
        mounted: true
      })
    })
  }

  let conditionalComponent
  if (state.userData !== undefined && state.userData !== null && state.mounted) {
    conditionalComponent = (
      <UserDataContextConsumer>
        {({
          userData,
          userBalance,
          promotions,
          updateUserBalance,
          updateUserInfo,
          updateUserAvailable
        }) => {
          return (
            <InfoContainer
              limitHistPage={state.limitHistPage}
              userDataChanged={state.userDataChanged}
              submitting={state.submitting}
              activeCategory={state.selection}
              disableClickedButton={disableClickedButton}
              changeCategory={changeCategory}
              commissionRange={state.commissionRange}
              handleAddCard={handleAddCard}
              handleDelete={handleDelete}
              handleClose={handleClose}
              updateCardList={updateCardList}
              deleteCard={deleteCard}
              availableCashout={state.availableCashout}
              getTransactions={getTransactions}
              location={props.location.pathname}
              misDocumentos={state.misDocumentos}
              misTickets={state.misTickets}
              referals={state.referals}
              selection={state.selection}
              // showPass={showPass}
              transacciones={state.transacciones}
              userData={userData}
              promotions={promotions}
              userStatus={state.userStatus}
              userBalance={userBalance}
              updatedAvatar={updatedAvatar}
              updateUserInfo={updateUserInfo}
              updateUserAvailable={updateUserAvailable}
              updateUserBalance={updateUserBalance}
            />
          )
        }}
      </UserDataContextConsumer>
    )
  } else if (
    utils.getUserInfo() === undefined ||
    (utils.getUserInfo() === null && state.mounted)
  ) {
    // Truly Not Logged
    conditionalComponent = (
      <Redirect
        push
        to={{
          pathname: '/ingresar'
        }}
      />
    )
  } else if (!state.mounted) {
    conditionalComponent = <Loading label='Cargando Info...' />
  }
  return <PageWrapper>{conditionalComponent}</PageWrapper>
}


export default UserInfo
