import API from './api';
import firstBy from 'thenby';
import _ from 'underscore';
import { publish } from './events';

const survivorUtils = {
  /***************************
   * Handlers for web Socket *
   **************************/
  /**
   * This function handles the fixture Update socket
   * and changes the state of by modifying the the data related that of the fixture
   * Score time and status
   * @param { fixtureLiveData } - Object - it contains all the data of the Live Fixture
   */
  handleFixtureUpdateSocket: function(fixtureLiveData) {
    // console.log('%c Fixture Update','color:rgb(46, 140, 207); font-size:2em;');

    if (!this.state.survivor || !this.state.fixtures) {
      return;
    }

    let tempFixtures = this.state.fixtures;
    let currentWeek = this.state.selectedWeek;
    let temporalEntryData = this.state.selectedEntryData;
    let currentPick = temporalEntryData !== null ? temporalEntryData.survivor_picks[currentWeek] : null;
    //let foundFixture = false;
    let winnerTeam = null;
    let tempCurrentFixture = null;
    let socketLiveFixtures = fixtureLiveData.live_fixtures;
    let socketLiveResults = fixtureLiveData.results;

    if (!socketLiveFixtures || !socketLiveResults) {
      return;
    }

    // console.log('Live Results', socketLiveResults);
    // console.log('Live Fixtures', socketLiveFixtures);
    // console.log(this);
    /**
     * Replace the live data of the fixture
     * */
    socketLiveFixtures.forEach((liveFixture) => {
      tempFixtures.forEach((fixture, fixtureIdx) => {
        tempCurrentFixture = fixture;

        if (fixture.id !== liveFixture.id) {
          return;
        }
        //foundFixture = true;

        // console.log('Found Fixture', liveFixture)

        tempCurrentFixture['score_home'] = liveFixture.score_home;
        tempCurrentFixture['score_away'] = liveFixture.score_away;
        tempCurrentFixture['winning_pick'] = liveFixture.winning_pick;
        tempCurrentFixture['winning_pick_line'] = liveFixture.winning_pick_line;

        if (currentPick && currentPick.fixture_id === liveFixture.id) {
          const which =
            this.state.survivor.point_format === 'natural' ? 'winning_pick' : 'winning_pick_line';
          const winningPick = liveFixture[which];
          winnerTeam = 'tie';

          if (winningPick === 'home') {
            winnerTeam = liveFixture.home_team;
          }
          if (winningPick === 'away') {
            winnerTeam = liveFixture.away_team;
          }

          if (currentPick.pick === winnerTeam.id) {
            currentPick.correct = true;
            currentPick.strike = false;
          } else {
            if (!this.state.survivor.strikes_enabled) {
              if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                currentPick.correct = true;
                currentPick.strike = false;
              }
              if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                currentPick.correct = false;
                currentPick.strike = false;
              }
            } else {
              if (this.state.survivor.point_format === 'natural') {
                if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                  currentPick.correct = false;
                  currentPick.strike = false;
                }
                if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                  currentPick.correct = false;
                  currentPick.strike = true;
                }
              } else {
                //line

                if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                  currentPick.correct = false;
                  currentPick.strike = true;
                }
                if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                  currentPick.correct = false;
                  currentPick.strike = true;
                }
              }
            }
          }

          // currentPick.correct = winnerTeam.id === currentPick.pick || winnerTeam === 'tie';

          // if( currentPick.hasOwnProperty('strike') ){
          //   if( winnerTeam  === 'tie') {
          //     currentPick.correct   = false;
          //     currentPick.strike    = true;
          //   }
          //   else if ( winnerTeam !== 'tie' && winnerTeam.id !== currentPick.pick ) {
          //     currentPick.strike    = false;
          //     currentPick.correct   = false;
          //   }
          // }

          temporalEntryData.survivor_picks[currentWeek] = currentPick;
          //Update Current Entry
        }
        /**
         * Replace the live results of the fixture
         * */
        let liveResults = socketLiveResults[tempCurrentFixture.feed_id];

        if (liveResults) {
          if (!tempCurrentFixture.hasOwnProperty('stats')) {
            tempCurrentFixture['stats'] = {};
          }
          tempCurrentFixture['stats'] = liveResults;
          tempCurrentFixture['stats']['quarter'] = liveResults.hasOwnProperty('quarter')
            ? liveResults.quarter
            : null;

          tempCurrentFixture['stats']['timer'] = liveResults.timer;
          tempCurrentFixture['stats']['score_home_first_half'] = liveResults.score_home_first_half;
          tempCurrentFixture['stats']['score_away_first_half'] = liveResults.score_away_first_half;
          tempCurrentFixture['events'] = {};
          tempCurrentFixture['events'] = liveResults.events;
        }
        tempFixtures[fixtureIdx] = tempCurrentFixture;
      }); //end foreach fixtures from state
    }); //end foreach fixtures from socket

    //
    //Update state
    //

    this.setState(
      {
        fixtures: tempFixtures,
        selectedEntryData: temporalEntryData,
      },
      () => {
        if (this.state.survivor.status === 'live') {
          // this.updateStandingsAganar(this.state.standings.items);
        }
      }
    );
  },
  /**
   * This function handles the fixture closed socket
   * and changes the state of by modifying the status, finished flag and sort_status of the fixutre
   * @param { fixtureData } - Object - it contains all the data of the Closed Fixture
   */
  fixtureCloseSocket: function(fixtureData) {
    // console.log('%c Fixture close Update','color:rgb(223, 75, 38); font-size:2em;');
    // console.log( fixtureData );
    if (!this.state.fixtures) {
      return;
    }
    // if( !this.state.standings || !this.state.standings.items ){ return }

    let fixtureIdx = null;
    let tempFixtures = this.state.fixtures;
    // let tempStandings     = this.state.standings;
    let updatedFixture = this.state.fixtures.find((fixture, fixtureIndx) => {
      if (fixture.id === fixtureData) {
        fixtureIdx = fixtureIndx;
        return fixture;
      }
    });

    if (updatedFixture === -1 || updatedFixture === undefined) {
      return;
    }

    updatedFixture.finished = true;
    updatedFixture.sort_status = 2;
    updatedFixture.status = 'closed';

    tempFixtures[fixtureIdx] = updatedFixture;

    this.setState({ fixtures: tempFixtures });
  },
  /**
   * This function handles the pool closed socket
   * and changes the state of by modifying the status, finished flag and sort_status of the fixutre
   * Thes functions also update the standings as the final status of the survivor
   * @param { poolClosedData } - Object - it contains all the data of the Closed Pool
   */
  handleCloseSurvivor: function(survivorCloseData) {
    // console.log('%c Survivor close Update','color:rgb(223, 75, 38); font-size:2em;');
    // console.log( survivorCloseData );

    if (!this.state.survivor) {
      return;
    }
    if (!this.state.standings) {
      return;
    }

    let tempSurvivorInfo = this.state.survivorInfo;
    let tempStandings = this.state.standings;
    let nextStandings = survivorCloseData.standings;
    let prevStandings = this.state.standings.items;
    let currentEntriesData = this.state.entriesInfo;

    if (!survivorCloseData && survivorCloseData.survivor) {
      return;
    }
    /**
     * If the closePool is the same as the current poolInfo
     * update the status, the upcoming and closed boolean
     * values in the  mainState
     */
    if (survivorCloseData.survivor !== tempSurvivorInfo.survivor.id) {
      return;
    }

    tempSurvivorInfo.survivor.closed = true;
    tempSurvivorInfo.survivor['status'] = 'closed';
    tempSurvivorInfo.survivor['upcoming'] = false;

    /**
     * Update the Standings with the final Standings
     * after the close action
     */

    tempStandings.items.forEach((prevStanding, prevStandingIdx) => {
      nextStandings.forEach((nextStanding, nextStandingIdx) => {
        if (nextStanding.survivor_entry_id !== prevStanding.survivor_entry_id) {
          return;
        }
        // prevStanding['total_possibly_correct'] = nextStanding.points;
        prevStanding['points'] = nextStanding.points;
        prevStanding['rank'] = nextStanding.rank;
        prevStanding['strikes'] = nextStanding.strikes;
        prevStanding['alive'] = nextStanding.alive;
        prevStanding['user_prize'].base_amount = nextStanding.base_amount;
        prevStanding['user_prize'].amount = nextStanding.prize_amount;
        prevStanding['user_prize'].tax = nextStanding.tax;
        prevStanding['prize_amount'] = nextStanding.prize_amount;
        prevStandings[prevStandingIdx] = prevStanding;
      });
    });

    // console.log('Closed Pool');
    // Reassign the new standigns

    // prevStandings = this.updateStandingsAganar( prevStandings, this.state.survivor.prize_currency );
    tempStandings['items'] = prevStandings;

    tempStandings.items.sort(
      firstBy('rank')
        .thenBy('alive', -1)
        .thenBy('open_picks', -1)
        .thenBy('strikes')
        .thenBy('pick_count', -1)
        .thenBy('survivor_entry_id')
    );
    // Update Entry data
    // currentEntriesData.entry = utils.updateEntry( currentEntriesData.entry, closedPool.standings );

    console.log('CloseUpdatePoolSubscription');
    this.setState(
      {
        survivorEntries: currentEntriesData,
        survivorInfo: tempSurvivorInfo,
        standings: tempStandings,
      },
      console.log('The survivor is closed! and updated Standings ')
    );
  },
  /**
   * This function handles the standings update socket
   * and changes the state of the app by modifying the order of the standings with the live data
   * @param { standingsUpdateData } - Object - it contains all the data of the Updated standings
   */
  standingsUpdateSocket: function(standingsUpdateData) {
    // console.log('%c standings Update','color:rgb(46, 140, 207); font-size:2em;');
    // console.table(this.state.standings.items);
    // console.log(standingsUpdateData.standings);
    // check not loaded
    if (!this.state.survivorInfo) {
      return;
    }
    if (standingsUpdateData.survivorId !== this.state.survivorInfo.survivor.id) {
      return;
    }

    if (!this.state.standings) {
      return;
    }
    // console.log( this.state.standings )

    let tempStandings = this.state.standings.items.map((standing) => {
      let updated = standingsUpdateData.standings.find(
        (updatedStanding) => updatedStanding.survivor_entry_id === standing.survivor_entry_id
      );
      if (!updated) {
        return standing;
      }
      // console.log('updated', updated);
      return {
        ...standing,
        ...{
          rank: updated.rank,
          alive: updated.alive,
          elimination_week_id: updated.elimination_week_id,
          elimination_contest_id: updated.elimination_contest_id,
          strikes: updated.strikes,
          strikes_available: updated.strikes_available,
          open_picks: updated.open_picks,
          mod: Date.now(),
        },
      };
    });
    let tempSelectedEntryData = this.state.selectedEntryData;

    // console.log(this.state.survivor);

    // const howMany = 100 * this.state.queryParams.page;
    // tempStandings.items = standingsUpdateData.standings.slice( 0, howMany );

    let updatedTiesDistribution = this.updatePrizeDistribution(
      standingsUpdateData.ties_distribution,
      this.state.survivorInfo.survivor.available,
      this.state.prizes
    );
    // console.log(updatedTiesDistribution);

    if (updatedTiesDistribution) {
      this.setState({ tiesDistribution: updatedTiesDistribution }, () => {
        tempStandings = this.updateStandingsAganar(
          tempStandings,
          this.state.survivorInfo.survivor.entry_currency,
          this.state.tiesDistribution,
          this.state.prizes,
          this.state.survivorInfo.survivor
        );
      });
    }

    /*******************************
     * Recalculate the entry Prize *
     *******************************/
    if (this.state.withRegister) {
      // this.setState({
      //   submitting: true
      // });
      // this.updateCurrentEntry();
      // console.log( 'TSED', tempSelectedEntryData );
      standingsUpdateData.standings.forEach((position) => {
        if (position.survivor_entry_id === this.state.selectedEntryData.id) {
          if (!tempSelectedEntryData.hasOwnProperty('survivor_standing')) {
            return;
          }
          tempSelectedEntryData.survivor_standing.strikes = position.strikes;
          tempSelectedEntryData.survivor_standing.rank = position.rank;
          tempSelectedEntryData.survivor_standing.points = position.points;
          tempSelectedEntryData.survivor_standing.posible_points = position.possible_points;
          tempSelectedEntryData.alive = position.alive;

          // tempSelectedEntryData.survivor_standing.user_prize                = position.user_prize;
          // tempSelectedEntryData.user_prize                                  = position.user_prize;
          // tempSelectedEntryData.user_prize                         = position.user_prize;
          // tempSelectedEntryData.user_prize                         = position.user_prize;
        }
      });
    }

    const newStadings = this.state.standings;
    newStadings.items = tempStandings;
    // tempStandings["items"] = standingsUpdateData.standings;

    this.setState(
      {
        standings: newStadings,
      },
      () => {
        this.setState(
          {
            standingsChanged: true,
          },
          () => {
            setTimeout(() => {
              console.log();
              this.setState({
                standingsChanged: false,
                // submitting:false,
              });
            }, 500);
          }
        );
      }
    );
  },

  playersUpdateSocket: function(data) {
    const playerResults = data.player_results;

    let players = this.state.players.map((player) => {
      let res = playerResults.find((_player) => player.id === _player.player_id);
      if (!res) {
        return player;
      }

      player = {
        ...player,
        ...{
          position: res.position,
          score: res.score,
          score_num: res.score_num,
          data: res.data,
        },
      };

      return player;
    });

    players = players.sort(
      firstBy('score_num')
        .thenBy('position')
        .thenBy('name')
    );
    this.setState({ players: players });

    let currentContest = this.state.currentContest;
    let temporalEntryData = this.state.selectedEntryData;
    let currentPick =  temporalEntryData !== null ? temporalEntryData.survivor_picks[currentContest.id] : null;
    const regExp = /AM|PM/;
    if (currentPick) {
      let player = playerResults.find((_player) => _player.player_id === currentPick.pick);
      // console.log( player, !regExp.test(player.data.hole) || player.position !== null );
      if (player && (!regExp.test(player.data.hole) || player.position !== null)) {
        currentPick.pr.position = player.position;
        currentPick.pr.score = player.score;
        currentPick.pr.score_num = player.score_num;
        currentPick.pr.data = player.data;
        currentPick.modified = Date.now();

        let eliminationType = currentContest.elimination_type;
        let threshold = currentContest.elimination_threshold;
        let currentContestId = currentContest.id;

        if (eliminationType === 'cut') {
          if (player.position === '' || player.position === 'null' || player.position === null) {
            currentPick.correct = false;
            currentPick.strike = true;
          } else {
            currentPick.correct = true;
            currentPick.strike = false;
          }
        } else {
          if (player.position === null || player.position >= threshold) {
            currentPick.correct = false;
            currentPick.strike = true;
          } else {
            currentPick.correct = true;
            currentPick.strike = false;
          }
        }

        temporalEntryData.mod = Date.now();
        temporalEntryData.survivor_picks[currentContestId] = currentPick;

        this.setState({
          selectedEntryData: temporalEntryData,
        });
      }
    }
  },

  playersRaceUpdateSocket: function(data) {
    const playerResults = data.race_results;

    let players = this.state.players.map((player) => {
      let res = playerResults.find((_player) => parseInt(player.id) === parseInt(_player.player_id));
      if (!res) {
        return player;
      }
      player.finish_pos = res.finish_pos;
      player.laps = res.laps;
      player.laps_num = res.laps_num;
      player.pit = res.pit;
      player.time = res.time;

      return player;
    });

    players = players.sort(
      firstBy('finish_pos')
    );

    this.setState({ players: players });

    let currentContest = this.state.currentContest;
    let temporalEntryData = this.state.selectedEntryData;
    let currentPick = temporalEntryData.survivor_picks[currentContest.id];

    if (currentPick && currentPick.pick ) {
      let player = playerResults.find((_player) => _player.player_id === currentPick.pick);
      // console.log( player, !regExp.test(player.data.hole) || player.position !== null );
      if (player && player.finish_pos != null) {

        currentPick.rr.finish_pos = player.finish_pos;
        currentPick.rr.time       = player.time;
        currentPick.rr.laps       = player.laps;
        currentPick.rr.laps_num   = player.laps_num;
        currentPick.rr.pit        = player.pit;
        currentPick.modified = Date.now();

        let threshold = this.state.survivor.elimination_threshold; // ?? sacar del survivor
        let currentContestId = currentContest.id;

        // marcar como eliminados si el piloto no queda en el umbral definido
        if ( player.finish_pos === null || player.finish_pos > threshold) {
          currentPick.correct = false;
          currentPick.strike  = true;
        } else {
          currentPick.correct = true;
          currentPick.strike  = false;
        }

        temporalEntryData.mod = Date.now();
        temporalEntryData.survivor_picks[currentContestId] = currentPick;

        this.setState({
          selectedEntryData: temporalEntryData,
        });
      }
    }
  },
  /**************************
   * Registration Functions *
   **************************/
  registraSurvivor: function(survivorId, survivorEntry, survivorEntryId, payType, cantRegistros) {
    console.log('%c Registrar Survivor', 'color:#df4b26; font-size:2em;');
    console.log(survivorId, survivorEntry, survivorEntryId, payType, cantRegistros);
    this.setState({
      submitting: true,
    });
    /**
     * New Register
     */
    if (survivorEntry !== undefined && survivorEntry !== null) {
      survivorEntry['num_entries'] = Number(cantRegistros);
    } else {
      survivorEntry = {
        id: null,
        survivor_id: Number(survivorId),
        survivor_entry_id: !survivorEntry ? null : Number(survivorEntry),
        num_entries: Number(cantRegistros),
        bracket_picks: [],
      };
    }

    console.log('Entry Ready to Send');
    console.log(survivorEntry);
    console.log(this);

    API.postSaveSurvivorPicks(survivorEntry)
      .then((response) => {
        
        publish('UPDATE_MY_PICKS', 'justSave')
        console.log(response);
        console.log(this.callbackRegistratSurvivor);
        this.callbackRegistraSurvivor(response);
      })
      .catch((error) => {
        console.error(error);
        this.callbackRegistraSurvivor('Error');
      });
  },
  callbackRegistratSurvivor: function() {},
  /*****************************************
   * Standings and prizes Related methods **
   ****************************************/
  getAganar: function(rank, prizesList, tiesDistribution, standings, gameInfo) {
    // console.log('%c Get currentAganar Survivor', 'font-size:2em; color:#2e8ccf;')
    // console.log(gameInfo)
    let totalPremios = gameInfo.available;
    let tipoPremios = prizesList[0].type;

    let forStanding;

    let currentAganar = prizesList.find((prize) => prize.position === rank);

    let maxPrizedPosition = _.max(prizesList, (prize) => {
      return Number(prize.position);
    });

    tiesDistribution.forEach((tie, tieIdx) => {
      let rank = tie.rank;
      // console.log(rank, maxPrizedPosition.position);

      if (rank > maxPrizedPosition.position) {
        //    console.error('rank is bigger than maxPrizesPosition', maxPrizedPosition.position, rank);
        return;
      }

      let acc = 0;

      for (let i = rank; i < rank + tie.ties; i++) {
        let prize = prizesList[i - 1];
        if (!prize) {
          continue;
        }
        acc += prize.percentage;
      }

      forStanding = (totalPremios * (acc / 10000)) / tie.ties;

      // console.log('ForStanding',forStanding);
      tie['prize_amount'] = forStanding;
    });
    // console.log(tiesDistribution)

    // If Aganr dont exist currentAganar is 0
    if (!currentAganar) {
      return '0';
    } else if (gameInfo.status === 'closed') {
      if ((tipoPremios = 'percentage')) {
        return (totalPremios * (currentAganar.percentage / 1000)) / 1000;
      } else {
        return currentAganar.amount / 1000;
      }
    } else {
      if (currentAganar.position === 0) {
        let isTied = tiesDistribution.find((tie) => tie.rank === currentAganar.position);

        if (isTied && isTied.ties > 1) {
          return isTied.prize_amount / 100;
        }

        return standings.items[currentAganar.position].amount / 100;
      } else {
        let isTied = tiesDistribution.find((tie) => tie.rank === currentAganar.position);

        if (tipoPremios === 'percentage') {
          if (isTied && isTied.ties > 1) {
            return isTied.prize_amount / 100;
          }
          return (totalPremios * (currentAganar.percentage / 1000)) / 1000;
        } else {
          if (isTied && isTied.ties > 1) {
            return isTied.prize_amount / 100;
          }
          return (totalPremios * currentAganar.percentage) / 1000 / 1000;
        }
      }
    }
  },
  /**
   * This function Updates the Standings list in  a Live survivor
   * It uses the current positions and returns the updated positions
   * @param { Object } newStandings   - it hasthe new standings
   * @param { Object } prevStandings  - it has the previous standings
   * @returns {Array}
   */
  updateStandingsComplete: function(newStandings, prevStandings) {
    let newUpdatedPositions = prevStandings.items;
    newUpdatedPositions.forEach((standing) => {
      newStandings.forEach((nextStanding) => {
        // console.log(nextStanding)
        if (nextStanding.entry_id !== standing.entry_id) {
          return;
        }
        standing['rank'] = nextStanding.rank;
        standing['strikes'] = nextStanding.strikes;
        standing['points'] = nextStanding.points;
        standing['total_possibly_correct'] = nextStanding.total_possibly_correct;
        standing['user_prize']['amount'] = nextStanding.prize_amount;
        standing['prize_amount'] = nextStanding.prize_amount;
      });
    });
    return newUpdatedPositions;
  },
  /**
   * This function Updates the Amount of prize in a Live Quiniela
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsAganar: function(positions, prize_currency, tiesDistribution, prizes, gameInfo) {
    let updatedPositions = [];
    // console.log('%c UpdateStandingsAganar', 'color:#2e8ccf; font-size:2em;')
    // console.log(positions, prize_currency, tiesDistribution,prizes, gameInfo);

    // let newStatePositions = {...this.state.standings};
    if (!tiesDistribution || !positions) {
      return;
    }

    let forStanding;
    let available = gameInfo.available;
    let endPercentage = gameInfo.end_percentage;
    let weeklyPrize = gameInfo.weekly_prize;
    let poolType = gameInfo.type;

    // console.log('Updated StateStandings aganar ');

    /**
     * Ticket Currency prize
     * **/
    if (prize_currency === 'ticket') {
      // console.log('updateStandings Tickets');
      let maxPrizedPosition = _.max(prizes, (prize) => {
        return Number(prize.position);
      });

      let grouped = _.groupBy(positions, 'rank');

      _.each(grouped, (positions, rank) => {
        rank = Number(rank);
        if (rank > maxPrizedPosition.position) {
          positions.forEach((position, posIdx) => {
            //let premio = _.find(prizes, position);
            positions[posIdx].prize_amount = 0;
            positions[posIdx].user_prize.amount = 0;
            positions[posIdx].user_prize.base_amount = 0;
            positions[posIdx].user_prize.description = null;
            updatedPositions.push(positions[posIdx]);
          });
        } else {
          if (positions.length === 1) {
            let premio = _.find(prizes, { position: rank });
            positions[0].user_prize.description = premio.description;
            updatedPositions.push(positions[0]);
          } else {
            positions.forEach((position, idx) => {
              position.user_prize.description = prizes[position.rank].description;
              updatedPositions.push(position);
            });
          }
        }
      });
      return updatedPositions;
    } else {
      /**
       * Normal Currency prize
       * **/
      // console.log('%c %s %o %o','background:#7BD900; font-size:20px;','Updating Standings A ganar',prizes)

      // take into account the weekly prizes
      if (poolType === 'full' && weeklyPrize) {
        let percentage = endPercentage / 10000;
        available = available * percentage;
      }

      let maxPrizedPosition = _.max(prizes, (prize) => {
        return Number(prize.position);
      });

      positions.map((standing) => {
        // console.log(standing);
        standing['prize_amount'] = 0;
        standing['user_prize'].amount = 0;
        standing['user_prize'].base_amount = 0;
        standing['user_prize'].percentage = 0;
        standing['user_prize'].description = null;
      });

      tiesDistribution.forEach((tie, tieIdx) => {
        let rank = tie.rank;
        if (rank > maxPrizedPosition.position) {
          return;
        }

        let acc = 0;

        for (let i = rank; i < rank + tie.ties; i++) {
          let prize = prizes[i - 1];
          if (!prize) {
            continue;
          }
          acc += prize.percentage;
        }

        forStanding = (available * (acc / 10000)) / tie.ties;

        tie['prize_amount'] = forStanding;
      });

      positions.map((position) => {
        let prize = _.find(tiesDistribution, { rank: position.rank });

        if (!prize) {
          return;
        }
        // console.log(prize);

        position.user_prize.amount = prize.prize_amount;
        position.user_prize.base_amount = prize.prize_amount;
        position.prize_amount = prize.prize_amount;

        updatedPositions.push(position);
      });

      return updatedPositions;
    }
  },

  /**
   * This function Updates the Standing Position in a Live Quiniela
   * Using the Realtime Data
   * It uses the current positions and returns the updated positions
   * @param positions
   * @returns {Array}
   */
  updateStandingsPosition: function(positions) {
    // let maxPoints = Number.MAX_SAFE_INTEGER;
    // let rank = 0;
    // let nextRank = 1;
    let updatedPositions = [];

    if (!positions) {
      return;
    }

    /**
     * Here we have to add another sort condition to handle the time when the user lose
     */
    positions.sort(
      firstBy('rank')
        .thenBy('alive', -1)
        .thenBy('open_picks', -1)
        .thenBy('strikes')
        .thenBy('pick_count', -1)
        .thenBy('survivor_entry_id')
    );

    return updatedPositions;
  },
  updatePrizeDistribution: function(tiesDistribution, available, prizesList) {
    // console.log('%c TiesDistribution', 'font-size:2em;');
    // console.log(tiesDistribution);
    // console.log(available);
    // console.log(prizesList);

    if (!tiesDistribution) {
      return null;
    }

    let newTiesDistribution = [];

    let forStanding = 0;
    tiesDistribution.forEach((tie) => {
      let rank = tie.rank;
      let acc = 0;

      for (let i = rank; i < rank + tie.ties; i++) {
        let prize = prizesList[i - 1];
        if (!prize) {
          continue;
        }
        acc += prize.percentage;
      }

      forStanding = (available * (acc / 10000)) / tie.ties;

      let currentPrizeAmount = prizesList.find((prize) => {
        // console.log(prize.position , rank );
        if (prize.position === rank) {
          return prize;
        }
      });

      // console.log( currentPrizeAmount );

      if (!currentPrizeAmount) {
        tie['prize_amount'] = 0;
      }

      tie['prize_amount'] = forStanding;

      // console.log(tie, forStanding);

      newTiesDistribution.push(tie);
    });

    // console.log('newTieDistribution', newTiesDistribution)
    return newTiesDistribution;
  },
};

export default survivorUtils;
