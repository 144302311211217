import React from 'react'

// Components
import { Divider, Stack, Box } from '../../../Xmerald-UI'
import Button from '../shared/Button'

// Store
import { useWizardPrivate } from '../wizardPrivate.store'
import { BarLoader } from 'react-spinners'

export default function WizardButtons (props) {
  const setStep = useWizardPrivate(state => state.setStep)

  return (
    <Box>
      <Divider />
      <Stack direction='row' spacing='1em' justify='center' mt={20} mb={20}>
        <Button
          onClick={() => setStep(props.prevStep)}
          className='guardar-picks btn-rojo'
        >
          REGRESAR
        </Button>
        <Button
          disabled={props.disabledNext}
          className='guardar-picks'
          onClick={(e) => props.onClickNext ? props.onClickNext(e) : setStep(props.nextStep)}
        >
          BUSCAR
        </Button>
      </Stack>
    </Box>
  )
}
