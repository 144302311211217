import React from 'react';

import utils from '../../utils/utils';
import { Redirect } from 'react-router';

import { Modal, Overlay, Button } from 'react-bootstrap/lib';
import PageWrapper from '../PageWrapper';

//import createHistory from 'history/createBrowserHistory';

import { DescargarAppBanner } from '../RegistroFormPanel';
import { detectDevice } from '../../utils/utils';
//const history = createHistory();
const history = require('history').createBrowserHistory();
const uagent = navigator.userAgent.toLowerCase();
let device = null;

// /**
//  * This method detects the device that the user is using
//  * @return device { String }
//  */
// const detectDevice = () => {
//   // console.log(navigator.userAgent);
//   if (uagent.search('iphone') > -1) {
//     device = 'apple';
//   } else if (uagent.search('ipad') > -1) {
//     device = 'apple';
//   } else if (uagent.search('ipod') > -1) {
//     device = 'apple';
//   } else if (uagent.search('ndroid') > -1) {
//     device = 'android';
//   }
//   return device;
// };

/**
 * This component handles and renders the CTA based on the device type
 *
 */
const CallToAction = ({ action, deviceType }) => {
  let icon = null;

  switch (deviceType) {
    case 'android':
      icon = 'ion-social-android';
      break;
    case 'apple':
      icon = 'ion-social-apple';
      break;
  }

  return (
    <Button
      onClick={action}
      type="submit"
      className="callToAction btn-form btn-pick flex-row row-no-padding"
    >
      <span className="btn-text flex-row row-center row-no-padding">
        <i className={icon} style={{ marginRight: '5px' }} /> <span>Descarga la app</span>
      </span>
    </Button>
  );
};

const NoThanks = ({ isPanel, closePanel, deviceType }) => {
  return (
    <div className="flex-row">
      <button
        className="btn-default btn-rojo btn-pick text-center flex-col"
        onClick={() => {
          closePanel();
        }}
      >
        {' '}
        No, Gracias{' '}
      </button>
    </div>
  );
};

const PanelContent = ({ handleDownload, deviceType }) => {
  return (
    <div className=" flex-col colcenter col-no-padding">
      <DescargarAppBanner
        label="¡ Ten la mejor experiencia !"
        path="https://pickwin.net/landing/descarga-android"
        deviceType={deviceType}
        handleClick={handleDownload}
      />
    </div>
  );
};

class FirstTimeDownloadPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      showPanel: false,
      branchLink: null,
      mounted: false,
      isPanel: !this.props.isPanel ? false : true,
    };

    this.closePanel = () => {
      console.log('ClosePanel');
      this.setState({ showPanel: false }, () => {
        utils.turnOffFirstTimeUser();
        if (!this.props.isPanel) {
          this.setState({ redirectToLobby: true, to: '/' });
        }
        if (this.props.noThanks) {
          utils.setShowTutorial();
          this.props.noThanks();
        }
      });
    };

    this.redirectToLobby = () => {
      this.setState({ redirect: true }, () => {
        utils.turnOffFirstTimeUser();
      });
    };
    this.handleDownload = () => {
      console.log('0handleDownload');
      utils.turnOffFirstTimeUser();
      // this.setState({ redirectToLobby : true });
    };
  }

  componentDidMount() {
    if (this.props.location) {
      this.setState(
        {
          prevLocation: this.props.location.pathname,
        },
        () => {
          history.push(`/descargar_app`);
        }
      );
    }
  }

  render() {
    if (this.state.redirectToDownload) {
      console.log('RedirectTo Download');
      return <Redirect push to={`https://pickwin.net/landing/descarga-android`} />;
    }

    if (this.state.redirectToLobby) {
      let pathToRedirect = this.state.prevLocation ? this.state.prevLocation : '/';
      if (pathToRedirect === '/descargar_app') {
        pathToRedirect = '/';
      }
      return <Redirect push to={{ pathname: pathToRedirect }} />;
    }

    if (this.props.isPanel) {
      return (
        <Modal
          bsSize="sm"
          backdropClassName="inviteFriendsBackdrop"
          dialogClassName="inviteFriendsBackdrop"
          onHide={() => {
            this.closePanel();
            this.setState({ branchLink: null });
          }}
          show={this.props.showPanel}
        >
          <div className="panel-abonar">
            <div id="panelTitle" className="panel-abonar-titulo">
              <span>
                <i className="ion-android-download" />
                {this.props.title}
              </span>
              <button
                className="cerrar-panel"
                onClick={() => {
                  this.closePanel();
                  this.setState({ branchLink: null });
                }}
              >
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty" />
                </div>
              </button>
            </div>
            <div className="col-xs-12 panel-pickwin">
              <div className="flex-row">
                <PanelContent deviceType={detectDevice()} handleDownload={this.redirectToLobby} />
              </div>
              <NoThanks deviceType={detectDevice()} closePanel={this.closePanel} />
            </div>
          </div>
        </Modal>
      );
    } else {
      return (
        <PageWrapper>
          <div className="container panel-pickwin">
            <div className="flex-row">
              <PanelContent deviceType={detectDevice()} handleDownload={this.handleDownload} />
            </div>
            <NoThanks deviceType={detectDevice()} closePanel={this.closePanel} />
          </div>
        </PageWrapper>
      );
    }
  }
}

export default FirstTimeDownloadPanel;
