import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Components
import { WeekItem } from './WeekItemPick'
import { WeekItemStats } from './WeekItemStats'


const Panel = styled.div``


export const PicksSoccerNFL = (props) => {
  const {
    teams,
    survivorWeeks,
    allFixtures,
    handlePick,
    removePick,
    currentEntry,
    pointFormat,
    survivorInfo,
    currentWeek,
    previousWeek,
    survivorEntryData,
    updateStartedFixture,
    updateStartedFirstMatchOfWeek
  } = props

  const isNFLSoccer = survivorInfo.sport_id === 1 || survivorInfo.sport_id === 2

  const [colapsedStatus, setColapsedStatus] = useState({})
  const [iscolapsedFirstTime, setIscolapsedFirstTime] = useState(false)


  useEffect(() => {

    if (Object.keys(colapsedStatus).length === 0 && Boolean(currentWeek) && Boolean(survivorWeeks)) {

      // Creamos objeto para revisar si la semana esta expandida o no
      // con la firma de 
      // {
      //    [idDeSemana]:  { id: 1, isExpanded: false, isCurrent: false },
      //    [idDeSemana2]: { id: 1, isExpanded: false, isCurrent: false },
      // }

      // ------------------------------------------------------------------
      const status = survivorWeeks.reduce((a, week, index) => {
        
        let isExpanded = false

        const hasPreviousWeek = Boolean(previousWeek)

        // Si es una jornada posterior
        if (hasPreviousWeek && (week.id == previousWeek?.id)) {
          if (currentWeek.started == true) {
            isExpanded = false
          } else {
            isExpanded = true
          }
        }

        // Si es jornada atual
        if (hasPreviousWeek && (week.id == currentWeek.id)) {
          if (currentWeek.started == true) {
            isExpanded = true
          }
        }

        // Si es la primera semana
        if (!hasPreviousWeek && (week.id == currentWeek.id)) {
          isExpanded = true
        }

        return {
          ...a,
          [week.id]: {
            isExpanded,
            isCurrent: week.id === currentWeek.id,
            id: week.id,
            index,
            isPressedByUser: false
          }
        }
      }, {})

      setColapsedStatus(status)
    }

  }, [currentWeek, previousWeek, survivorWeeks, colapsedStatus])

  const toggleColapsed = (weekId, isExpanded) => {

    setColapsedStatus(status => {
      let previous = status[previousWeek?.id]

      // Si la actual esta expandida, la proxima se quiere expandir y no es la actual
      // Solo la primera vez
      if (previous?.isExpanded && isExpanded && weekId !== previous?.id && !iscolapsedFirstTime) {
        previous = {
          ...previous,
          isExpanded: false
        }

        setIscolapsedFirstTime(true)
      }

      // Si es la actual respetar si usuario quiere expandir o no
      if (weekId === previous?.id) {
        previous = {
          ...previous,
          isExpanded
        }
      }

      // Toggle estatus si esta expandido o no
      return {
        ...status,
        [weekId]: {
          ...status[weekId],
          isExpanded,
        },
        [previous?.id]: previous
      }
    })
  }

  const proxyHandlePick = (teamId, weekId, fixtureId) => {
    handlePick(teamId, weekId, fixtureId)

    const getNext = (colapsedStatus, actualIndex) => {
      const next = Object
        .entries(colapsedStatus)
        .find(([id, status]) => status.index > actualIndex)

      return next ? next[1] : null
    }


    const actualExpanded = colapsedStatus[weekId] 
    const next = getNext(colapsedStatus, actualExpanded?.index)

    // Si hay semana siguiente y es la primera vez que se expande por el usuario
    // O por que es la semana actual auto-expandida entonces:

    // Expandir la siguiente
    if (next && !actualExpanded.isPressedByUser) {
      setColapsedStatus(status => {
        return {
          ...status,
          [next.id]: {
            ...status[next.id],
            isExpanded: true
          }
        }
      })
    }

    // Contraer la semana pick-eada solo la primera vez
    setColapsedStatus(status => {
      return {
        ...status,
        [actualExpanded.id]: {
          ...status[actualExpanded.id],
          isExpanded: status[actualExpanded.id].isPressedByUser,
          isPressedByUser: true
        }
      }
    })

  }

  return (
    <Panel style={{ overflowY: 'auto', maxHeight: isNFLSoccer ? 600 : 'inherit' }}>
      {survivorWeeks.map((week) => {
        const firstOfWeek = allFixtures?.firstsOfWeek?.[week.id]
        if (firstOfWeek?.enabled === 0 || firstOfWeek?.time_left <= 0) {
            return (
              <WeekItemStats
                key={week.id}
                week={week}
                teams={teams}
                allFixtures={allFixtures}
                currentWeek={currentWeek}
                currentEntry={currentEntry}
                survivorInfo={survivorInfo}
                toggleColapsed={toggleColapsed}
                stats={allFixtures?.pickStats?.[week.id]}
                colapsedStatus={colapsedStatus[week.id]}
            />
            )
        }

        return (
          <WeekItem
            key={week.id}
            week={week}
            teams={teams}
            handlePick={proxyHandlePick}
            removePick={removePick}
            pointFormat={pointFormat}
            currentWeek={currentWeek}
            currentEntry={currentEntry}
            survivorInfo={survivorInfo}
            allFixtures={allFixtures}
            toggleColapsed={toggleColapsed}
            survivorEntryData={survivorEntryData}
            colapsedStatus={colapsedStatus[week.id]}
            updateStartedFixture={updateStartedFixture}
            updateStartedFirstMatchOfWeek={updateStartedFirstMatchOfWeek}
          />
        )
      })}
    </Panel>
  )
}
