import React, {useState, useEffect } from 'react'
import styled from 'styled-components'
import { PulseLoader } from 'react-spinners'
import numeral from 'numeral'

// Utils
import API from '../../../utils/api'

// Components
import Text from '../../Text'
import { Link } from 'react-router-dom'
import ModalDetails from './components/ModalDetails'
import Iconify from '../../Iconify/Iconify'


export default function Bonuses (props) {
  const [bonuses, setBonuses] = useState([])
  const [loading, setLoading] = useState(false)
  const [current, setCurrentDetails] = useState(null)

  useEffect(() => {
    loadBonuses()
  }, [])

  const loadBonuses = async () => {
    setLoading(true)
    try {
      const { data: { userBonuses } } = await API.getUserBonuses()
      console.log('🚀 ~ file: Bonuses.js:29 ~ loadBonuses ~ userBonuses:', userBonuses)
      const { data: catalogList } = await API.fetchPromotions()
      console.log('🚀 ~ file: Bonuses.js:31 ~ loadBonuses ~ catalogList:', catalogList)
      
      const userBonusesData = userBonuses.map(userBonus => {
        const fullData = catalogList.find(bonus => (
          bonus && bonus.id === userBonus.bonus_id
        ))

        return {
          ...userBonus,
          fullData
        }
      })

      setBonuses(userBonusesData.filter(bonus => bonus.fullData))
    } catch (error) {
      
    }
    setLoading(false)
  }

  const showDetails = (bonus) => {
    setCurrentDetails(bonus)
  }

  const closeDetails = () => {
    setCurrentDetails(null)
  }

  return (
    <Content>
      {loading && <PulseLoader color='#FFF' style={{ padding: 50 }} />}
      {(!loading && bonuses.length === 0) && (
        <Box>
          <Text as='h6' mt={10} mb={220} ml={5}>
            <Iconify icon='mdi:trophy' style={{ marginTop: 5, marginRight: 5 }}/>
            <Text as='span'>¡Actualmente, no cuentas con algún bono activo!</Text>
          </Text>
        </Box>
      )}
      {(!loading && bonuses.length > 0) && (
        <>
          {bonuses.map(bonus => (
            <Box
              key={bonus.id}
              onClick={() => showDetails(bonus)}
            >
              <Image
                src={bonus.fullData.imgDesktop}
                alt={bonus.fullData.title}
              />
              <Info>
                <Text as='h6' mb={20}>{bonus.fullData.title}</Text>
                <Text size='0.9em' mb={5}>Bono: {getCurrency(bonus.playthroughs.amount / 100)} </Text>
                <Text size='0.9em' mb={5}>Playthrough: {getCurrency(bonus.playthroughs.playthrough / 100)}</Text>
                <BarBox>
                  <ProgressBar style={{ width: bonus.playthroughs.playthrough / 100 + '%'}} />
                </BarBox>
                <Descripcion
                  dangerouslySetInnerHTML={{ __html: bonus.fullData.descriptionShort }}
                />
                <Terms href={bonus.fullData.url} target='_blank'>
                  * Ver términos y condiciones
                </Terms>
              </Info>
            </Box>
          ))}
        </>
      )}

      <ModalDetails
        onClose={closeDetails}
        current={current}
      />
    </Content>
  )
}

const getCurrency = (amount) => {
  return numeral(amount).format('$0,000.00')
}

const BarBox = styled.div`
  position: relative;
  width: 100%;
  height: 1em;
  border: 1px solid #FFF;
  border-radius: 0.5em;
  overflow: hidden;
  margin: 1em 0;
`

const ProgressBar = styled.div`
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: #78bc28;
`

const Descripcion = styled.div`
  ul {
    list-style: none;
    margin: 0.5em 0;
    padding: 0;
    padding-left: 2em;
  }

  ul li:before {
    content: "•";
    color: #78BC28;
    display: inline-block; width: 1em;
    margin-left: -1em
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-height: 100px;
`
const Image = styled.img`
  max-width: 235px;
  padding: 1em;
`

const Info = styled.div``

const Box = styled.div`
  display: flex;
  gap: 1em;
  padding: 0.5em;

  :hover {
    opacity: 0.92;
    cursor: pointer;
  }
`

const Terms = styled.a`
  display: block;
  text-align: right;
  font-size: 0.9em;
  margin-top: 2em;
`
