import React, { useReducer, createContext } from 'react';
import mockNotifications from '../tests/__mockData__/testNotificationsControls/mockNotificationsControlsv3.json';
import _ from 'underscore';
/**
 * Context of the NotificationControls
 */
const NotificationControlsContext = createContext();

/**
 * This is the initial state
 */
let initialState = {
  mounted: false,
  submitting: false,
  isValid: false,
  notificationsAvailable: [],
  error: { isError: false, message: null },
  successfullChanges: false,
  controlsData: [],
  showError: false,
};

/**
 * The reducer handles the state of the NotificationControls
 * @param { Object } state
 * @param { Object } action
 * @return newState
 */
const reducer = (state, action) => {
  let newNotifications = state.notificationsAvailable;
  // console.log( "reducing .",state);  
  switch (action.type) {
    case 'updateSportsList':
      return { ...state, sportsList: action.payload };
    case 'initializeTournaments':
      console.log(action.payload);
      newNotifications = mockNotifications.notifications;
      action.payload.forEach((sport) => {
        let torunamentsPerSport = {};
        sport.tournaments.forEach((sTour) => {
          if (
            newNotifications['promotions'][sport.id] &&
            newNotifications['promotions'][sport.id].enabled
          ) {
            let tournamentObj = { amounts: { high: true, medium: true, low: true } };
            torunamentsPerSport[sTour.id] = tournamentObj;
            newNotifications['promotions'][sport.id].tournaments[sTour.id] = tournamentObj;
          } else {
            console.error('sport not enabled', sport.name);
          }
        });
      });
      return { ...state, notificationsAvailable: newNotifications, sportsList: action.payload };
    case 'isReady':
      return { ...state, mounted: true };
    case 'resetData':
      return initialState;
    case 'changeSubmit':
      return { ...state, submitting: action.payload };
    case 'handleErrorWindow':
      return { ...state, showError: !state.showError };
    case 'updateNotifications':
      return { ...state, notificationsAvailable: action.payload };
    case 'updateSwitchData':
      // console.log( 'update Switch Data', action.payload, newNotifications );
      newNotifications = state.notificationsAvailable;
      newNotifications[action.payload.category][action.payload.name] = !newNotifications[
        action.payload.category
      ][action.payload.name];
      return { ...state, notificationsAvailable: newNotifications };

    case 'updateSportsNotifications':
      // console.log('sports Notification', action );

      newNotifications = state.notificationsAvailable;
      // console.log( newNotifications[3].value.sports[action.payload.value] );

      newNotifications['promotions'][action.payload.value].enabled = !newNotifications[
        'promotions'
      ][action.payload.value].enabled;

      if (newNotifications['promotions'][action.payload.value].enabled) {
        // console.log('Sport Enabled');
        // console.log( newNotifications[ 'promotions' ][action.payload.value].tournaments );
        state.sportsList.forEach((sport) => {
          if (sport.id === action.payload.value) {
            sport.tournaments.forEach((sportTournaments) => {
              if (
                newNotifications['promotions'][action.payload.value].tournaments[
                sportTournaments.id
                ]
              ) {
                // console.log('Tournament exist');
                newNotifications['promotions'][action.payload.value].tournaments[
                  sportTournaments.id
                ].amounts = { high: true, medium: true, low: true };
              } else {
                // console.log('Tournament dont exist', sportTournaments );
                newNotifications['promotions'][action.payload.value].tournaments[
                  sportTournaments.id
                ] = {
                  enabled: true,
                  amounts: { high: true, medium: true, low: true },
                  id: sportTournaments.id,
                };
              }
            });
          }
        });
      } else {
        // console.log('Sport NotEnabled');
        // console.log( newNotifications[ 'promotions' ][action.payload.value].tournaments );
        state.sportsList.forEach((sport) => {
          if (sport.id === action.payload.value) {
            sport.tournaments.forEach((sportTournaments) => {
              if (
                newNotifications['promotions'][action.payload.value].tournaments[
                sportTournaments.id
                ]
              ) {
                newNotifications['promotions'][action.payload.value].tournaments[
                  sportTournaments.id
                ].amounts = { high: false, medium: false, low: false };
              } else {
                newNotifications['promotions'][action.payload.value].tournaments[
                  sportTournaments.id
                ] = { enabled: false, amounts: { high: false, medium: false, low: false } };
              }
            });
          }
        });
      }

      // console.log( newNotifications[3].value.sports[action.payload.value] );
      return { ...state, notificationsAvailable: newNotifications };
    case 'updateTournamentsNotifications':
      newNotifications = state.notificationsAvailable;
      console.log('update tournaments Notifications');
      console.log(action.payload);
      // let objOn = { amounts: { high: true, low: true, medium: true } };
      // let objOff = { amounts: { high: false, low: false, medium: false } };
      // console.log(  newNotifications[ 'promotions' ][  action.payload.sportId  ]);

      let currentTournament =
        newNotifications['promotions'][action.payload.sportId].tournaments[
        action.payload.tournamentId
        ];
      console.log(currentTournament);
      newNotifications['promotions'][action.payload.sportId].tournaments[
        action.payload.tournamentId
      ].amounts['low'] = true;
      newNotifications['promotions'][action.payload.sportId].tournaments[
        action.payload.tournamentId
      ].amounts['medium'] = true;
      newNotifications['promotions'][action.payload.sportId].tournaments[
        action.payload.tournamentId
      ].amounts['high'] = true;

      return { ...state, notificationsAvailable: newNotifications };
    case 'updateEntryNotifications':
      // console.log('update Entry Notifications', action.payload, state.notificationsAvailable );      
      newNotifications = state.notificationsAvailable;
      // console.log(newNotifications['promotions'][ action.payload.sportId ].tournaments);

      let currentTournamentVar =
        newNotifications['promotions'][action.payload.sportId].tournaments[
        action.payload.tournamentId
        ];

      if (currentTournamentVar) {
        // console.log('This tournament amounts before ',newNotifications['promotions'][ action.payload.sportId ].tournaments[ action.payload.tournamentId][action.payload.amountType]);
        newNotifications['promotions'][action.payload.sportId].tournaments[
          action.payload.tournamentId
        ]['amounts'][action.payload.amountType] = !newNotifications['promotions'][
          action.payload.sportId
        ].tournaments[action.payload.tournamentId]['amounts'][action.payload.amountType];
        // console.log('This tournament amounts after ',newNotifications['promotions'][ action.payload.sportId ].tournaments[ action.payload.tournamentId][action.payload.amountType]);
      } else {
        console.error('This tournament dontexist ');
      }

      return { ...state, notificationsAvailable: newNotifications };
    // case 'isReady':
    //   // console.log('isReady',action.payload);
    //   return { ...state, submitting: action.payload };
    case 'isError':
      // console.log('isError',action.payload);
      return {
        ...state,
        submitting: false,
        error: { isError: action.payload.value, message: action.payload.message },
        mounted: true,
        showError: true,
      };
    case 'handleSuccessfullChanges':
      // console.log('isSuccessfull', );
      return {
        ...state,
        submitting: false,
        successfullChanges: !state.successfullChanges,
        error: { isError: false, message: null },
        mounted: true,
        showError: false,
      };
    case 'updateHandleAllAmoutPerSport':
      console.log('%c All amounts per sport', 'font-size: 2em;');
      newNotifications = state.notificationsAvailable;
      console.log(newNotifications);
      _.each(newNotifications['promotions'][action.payload.sportId].tournaments, (tournament) => {
        console.log(tournament);
        console.log('action.payload,amountType', action.payload.amountType);
        if (tournament.hasOwnProperty('amounts')) {
          tournament['amounts'][action.payload.amountType] = true;
        } else {
          console.log(tournament['amounts'][action.payload.amountType]);
          tournament['amounts'][action.payload.amountType] = true;
        }
      });
      return { ...state };
    default:
      // console.log('Unknown reducer')
      return { ...state };
  }
};

/**
 * The provider gives the let see and handles the state and dispatch
 * It also returns the childrens nested in the Provider
 * @param {Object} props
 */
const NotificationControlsContextProvider = (props) => {
  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };
  return (
    <NotificationControlsContext.Provider value={value}>
      {props.children}
    </NotificationControlsContext.Provider>
  );
};

const NotificationControlsContextConsumer = NotificationControlsContext.Consumer;

export {
  NotificationControlsContext,
  NotificationControlsContextProvider,
  NotificationControlsContextConsumer,
};
