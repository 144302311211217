import React from 'react';
// import SearchUserPopover from './SearchUserPopover'
import { FormControl } from 'react-bootstrap/lib';
import avatar from '../../assets/images/avatar-50.png';
import { CSSTransitionGroup } from 'react-transition-group';
import { Button } from 'react-bootstrap/lib';
import api from '../../utils/api';
import _ from 'underscore';
import $ from 'jquery';

// const DEFAULT_AUTOCOMPLETE_STATE = {
//   value: null,
//   error: null,
//   options: [],
//   loading: false,
//   visible: false
// }

const OptionsList = ({ followUser, searchedResults, submitting }) => {
  let conditionalComponent = null;

  if (submitting) {
    conditionalComponent = (
      <div id="dropdown-search" className="flex-container column justify align">
        <i className="ion-load-a loading"></i>
      </div>
    );
  } else if (!searchedResults) {
    return null;
  } else if (searchedResults.length === 0) {
    return (
      <div id="dropdown-search" className="flex-container column justify align">
        <i style={{ fontSize: '2em' }} className="ion-alert"></i>
        <div className="text-center"> No se encontro a ningún resultado.</div>
      </div>
    );
  } else if (searchedResults.length >= 1) {
    // console.log(searchedResults);
    let optionList = searchedResults.map((result) => {
      // console.log(result.nick);
      return (
        <div className="flex-row optionItem row-center " key={result.id}>
          <div className="flex-col col-10 col-center ">
            {!result.avatar_url ? (
              <img src={avatar} className="avatar-img" alt="" />
            ) : (
                <img src={result.avatar_url} className="avatar-img" alt="" />
              )}
          </div>
          <div className="flex-col col-50 ">{result.nick}</div>
          {result.is_friend ? (
            <div className="flex-col col-no-padding">
              <div className="flex-container align justify already-friend ">
                <span>
                  <strong>{result.nick} </strong>
                  {` ya es tú amigo`}
                </span>{' '}
                <i style={{ marginLeft: '4px' }} className="ion-checkmark-round"></i>
              </div>
            </div>
          ) : result.hasOwnProperty('invitation_send') && result.invitation_send ? (
            <Button id="btn-follow" className="send-invite btn flex-col col-no-padding">
              <div className="flex-row row-center">
                <div className="flex-container align justify">
                  {$(window).width() < 768 ? 'Agregado' : 'Se agregó con éxito'}
                  <i className="ion-checkmark-round"></i>
                </div>
              </div>
            </Button>
          ) : result.hasOwnProperty('invitation_send') && !result.invitation_send ? (
            <Button id="btn-follow" className="error btn flex-col col-no-padding">
              <div className="flex-row row-center">
                <div className="flex-container align justify">
                  Sucedió un error
                  <i className="ion-close-round"></i>
                </div>
              </div>
            </Button>
          ) : (
                  <Button
                    id="btn-follow"
                    className=" btn flex-col col-no-padding"
                    onClick={() => {
                      followUser(result.id);
                    }}
                  >
                    <div className="flex-row row-center row-no-padding">
                      <div className="flex-container align justify">
                        {$(window).width() < 768 ? 'Agregar' : 'Agregar como amigo'}
                        <i className="ion-ios-personadd"></i>
                      </div>
                    </div>
                  </Button>
                )}
        </div>
      );
    });
    // console.log(optionList);
    conditionalComponent = (
      <div id="dropdown-search" className="flex-col">
        <div className="flex-container justify algin ">
          <strong className="text-center">
            {' '}
            Sigue y reta a los demás pickwineros para saber quién es el mejor.
          </strong>
        </div>
        <div id="optionInviteList" className="flex-row overflow-y overflow-300 overflow ">
          <div className="flex-col col-no-padding">
            <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={200}
              transitionEnter={true}
              transitionEnterTimeout={200}
              transitionLeave={true}
              transitionLeaveTimeout={200}
            >
              {optionList}
            </CSSTransitionGroup>
          </div>
        </div>
      </div>
    );
  }

  return conditionalComponent;
};

const SearchableInput = ({
  placeholder,
  value,
  fetchResults,
  changeSearchNick,
  resetValue,
  submitting,
}) => {
  return (
    <div className="flex-col" id="search-field-nick">
      <div id="searchableNickInput" className="col-xs-9 ">
        <FormControl
          onChange={(e) => {
            changeSearchNick(e);
            fetchResults(e);
          }}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
      <div className="col-xs-1  btn-search-field">
        <Button
          onClick={(e) => {
            console.log('Clicked');
            resetValue();
          }}
          className="btn-default"
        >
          <i className={`${submitting ? 'ion-load-a loading' : 'ion-close-circled'}`}> </i>
        </Button>
      </div>
    </div>
  );
};

class UserInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchThisNick: '',
      fetchResults: null,
      searchedResults: null,
      submitting: false,
      error: false,
      errorObject: null,
    };

    this.successCallback = (options) => {
      console.log(options);
      this.setState({
        searchedResults: options.data,
        submitting: false,
      });
    };

    this.errorCallback = (error) => {
      console.log(error);
      this.setState({
        error: true,
        errorObject: error,
        submitting: false,
      });
    };

    this.changeSearchNick = (e) => {
      // console.log( e.target.value );
      this.setState({ searchThisNick: e.target.value });
    };
    this.resetValue = () => {
      this.setState({ searchThisNick: '', searchedResults: null });
    };

    this.onSearch = () => {
      let searchObject = null;
      searchObject = { search: this.state.searchThisNick, offset: 0, limit: 10 };

      if (this.state.searchThisNick === '') {
        this.setState({ searchedResults: null });
      } else {
        this.setState({ submitting: true });
        api
          .searchUsers(searchObject)
          .then((options) => this.successCallback(options))
          .catch((error) => this.errorCallback(error));
      }
    };

    this.onSearch = _.debounce(this.onSearch, 500);

    this.followUser = (id) => {
      console.log(' %c Follow User +  ', 'color:rgb(223, 75, 38) ; font-size:2em ;');
      api.followUser(id).then((response) => {
        if (response.data.message === 'ok') {
          let temporalResults = this.state.searchedResults;

          let invitationSendIndex = _.findIndex(temporalResults, (result) => result.id === id);
          if (invitationSendIndex === -1) {
            console.error('User not found in results!');
            this.setState({
              errorFollow: true,
            });
          }

          temporalResults[invitationSendIndex]['invitation_send'] = true;

          this.setState({
            searchedResults: temporalResults,
          });
        }
        console.log(response);
      });
    };
    this.unfollowUser = (id) => {
      console.log(' %c UnFollow User -  ', 'color:rgb(223, 75, 38) ; font-size:2em ;');
      api.unfollowUser(id).then((response) => {
        console.log(response);
      });
    };

    this.rowProps = ({ id, nick, is_friend: isFriend }) => {
      return {
        key: id.toString(),
        value: id.toString(),
        disabled: isFriend,
        children: (
          <div style={{ width: '100%' }}>
            {nick}
            {/* {isFriend ? (
              <Icon type="check" style={{ float: 'right' }} />
            ) : ( */}
            <Button shape="circle" icon="user-add" style={{ float: 'right' }} />
            {/* )} */}
          </div>
        ),
      };
    };

    this.onSelect = ({ userId, options }) => {
      console.log('InviteUserAsFriend -> onSelect', userId, options);
      const { is_friend: isFriend } = _.find(options, ({ id }) => id.toString() === userId);
      if (!isFriend) {
        api.followUser(userId).then((response) => {
          console.log(response);
        });
      } else {
        api.unfollowUser(userId).then((response) => {
          console.log(response);
        });
      }
    };
  }

  render() {
    return (
      <div className="flex-col">
        <div className="flex-row">
          <SearchableInput
            placeholder="Agrega a tus amigos pickwineros"
            value={this.state.searchThisNick}
            fetchResults={this.onSearch}
            changeSearchNick={this.changeSearchNick}
            resetValue={this.resetValue}
            submitting={this.state.submitting}
          />
        </div>
        <OptionsList
          followUser={this.followUser}
          unfollowUser={this.unfollowUser}
          searchedResults={this.state.searchedResults}
          submitting={this.state.submitting}
        />
      </div>
    );
  }
}

export default UserInvitation;
