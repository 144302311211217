import { keyframes } from 'styled-components'

export const squareToBordered = keyframes`
  0% {
    border-radius: 0%;
  }

  70% {
    border-radius: 50%;
  }

  100% {
    border-radius: 50%;
  }
`
