// Utilities for attach and dispatch custom events
// are utils for communicate events and send data
// in nested components avoiding the prop drilling

export function subscribe (eventName, listener) {
  document.addEventListener(eventName, listener)
}

export function unsubscribe (eventName, listener) {
  document.removeEventListener(eventName, listener)
}

export function publish (eventName, data) {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}
