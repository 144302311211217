export const listCountries =  [
  // {country:'Afghanistan', ccode:'AF', code:'93'},
  // {country:'Albania', ccode:'AL', code:'355'},
  // {country:'Algeria', ccode:'DZ', code:'213'},
  // {country:'Andorra', ccode:'AD', code:'376'},
  // {country:'Angola', ccode:'AO', code:'244'},
  // {country:'Anguilla', ccode:'AI', code:'1-264'},
  // {country:'Antarctica', ccode:'AQ', code:'672'},
  // {country:'Antigua and Barbuda', ccode:'AG', code:'1-268'},
  // {country:'Argentina', ccode:'AR', code:'54'},
  // {country:'Armenia', ccode:'AM', code:'374'},
  // {country:'Aruba', ccode:'AW', code:'297'},
  // {country:'Australia', ccode:'AU', code:'61'},
  // {country:'Austria', ccode:'AT', code:'43'},
  // {country:'Azerbaijan', ccode:'AZ', code:'994'},
  // {country:'Bahamas', ccode:'BS', code:'1-242'},
  // {country:'Bahrain', ccode:'BH', code:'973'},
  // {country:'Bangladesh', ccode:'BD', code:'880'},
  // {country:'Barbados', ccode:'BB', code:'1-246'},
  // {country:'Belarus', ccode:'BY', code:'375'},
  // {country:'Belize', ccode:'BZ', code:'501'},
  // {country:'Benin', ccode:'BJ', code:'229'},
  // {country:'Bermuda', ccode:'BM', code:'1-441'},
  // {country:'Bhutan', ccode:'BT', code:'975'},
  // {country:'Bolivia', ccode:'BO', code:'591'},
  // {country:'Bosnia and Herzegovina', ccode:'BA', code:'387'},
  // {country:'Botswana', ccode:'BW', code:'267'},
  // {country:'Brazil', ccode:'BR', code:'55'},
  // {country:'British Indian Ocean Territory', ccode:'IO', code:'246'},
  // {country:'British Virgin Islands', ccode:'VG', code:'1-284'},
  // {country:'Brunei', ccode:'BN', code:'673'},
  // {country:'Bulgaria', ccode:'BG', code:'359'},
  // {country:'Burkina Faso', ccode:'BF', code:'226'},
  // {country:'Burundi', ccode:'BI', code:'257'},
  // {country:'Cambodia', ccode:'KH', code:'855'},
  // {country:'Cameroon', ccode:'CM', code:'237'},
  // {country:'Canada', ccode:'CA', code:'1'},
  // {country:'Cape Verde', ccode:'CV', code:'238'},
  // {country:'Cayman Islands', ccode:'KY', code:'1-345'},
  // {country:'Central African Republic', ccode:'CF', code:'236'},
  // {country:'Chad', ccode:'TD', code:'235'},
  // {country:'Chile', ccode:'CL', code:'56'},
  // {country:'China', ccode:'CN', code:'86'},
  // {country:'Christmas Island', ccode:'CX', code:'61'},
  // {country:'Cocos Islands', ccode:'CC', code:'61'},
  // {country:'Colombia', ccode:'CO', code:'57'},
  // {country:'Comoros', ccode:'KM', code:'269'},
  // {country:'Cook Islands', ccode:'CK', code:'682'},
  // {country:'Costa Rica', ccode:'CR', code:'506'},
  // {country:'Croatia', ccode:'HR', code:'385'},
  // {country:'Cuba', ccode:'CU', code:'53'},
  // {country:'Curacao', ccode:'CW', code:'599'},
  // {country:'Cyprus', ccode:'CY', code:'357'},
  // {country:'Czech Republic', ccode:'CZ', code:'420'},
  // {country:'Democratic Republic of the Congo', ccode:'CD', code:'243'},
  // {country:'Denmark', ccode:'DK', code:'45'},
  // {country:'Djibouti', ccode:'DJ', code:'253'},
  // {country:'Dominica', ccode:'DM', code:'1-767'},
  // {country:'Dominican Republic', ccode:'DO', code:'1-809, 1-829, 1-849'},
  // {country:'East Timor', ccode:'TL', code:'670'},
  // {country:'Ecuador', ccode:'EC', code:'593'},
  // {country:'Egypt', ccode:'EG', code:'20'},
  // {country:'El Salvador', ccode:'SV', code:'503'},
  // {country:'Equatorial Guinea', ccode:'GQ', code:'240'},
  // {country:'Eritrea', ccode:'ER', code:'291'},
  // {country:'Estonia', ccode:'EE', code:'372'},
  // {country:'Ethiopia', ccode:'ET', code:'251'},
  // {country:'Falkland Islands', ccode:'FK', code:'500'},
  // {country:'Faroe Islands', ccode:'FO', code:'298'},
  // {country:'Fiji', ccode:'FJ', code:'679'},
  // {country:'Finland', ccode:'FI', code:'358'},
  // {country:'French Polynesia', ccode:'PF', code:'689'},
  // {country:'Gabon', ccode:'GA', code:'241'},
  // {country:'Gambia', ccode:'GM', code:'220'},
  // {country:'Georgia', ccode:'GE', code:'995'},
  // {country:'Germany', ccode:'DE', code:'49'},
  // {country:'Ghana', ccode:'GH', code:'233'},
  // {country:'Gibraltar', ccode:'GI', code:'350'},
  // {country:'Greece', ccode:'GR', code:'30'},
  // {country:'Greenland', ccode:'GL', code:'299'},
  // {country:'Grenada', ccode:'GD', code:'1-473'},
  // {country:'Guatemala', ccode:'GT', code:'502'},
  // {country:'Guernsey', ccode:'GG', code:'44-1481'},
  // {country:'Guinea', ccode:'GN', code:'224'},
  // {country:'Guinea-Bissau', ccode:'GW', code:'245'},
  // {country:'Guyana', ccode:'GY', code:'592'},
  // {country:'Haiti', ccode:'HT', code:'509'},
  // {country:'Honduras', ccode:'HN', code:'504'},
  // {country:'Hong Kong', ccode:'HK', code:'852'},
  // {country:'Hungary', ccode:'HU', code:'36'},
  // {country:'Iceland', ccode:'IS', code:'354'},
  // {country:'India', ccode:'IN', code:'91'},
  // {country:'Indonesia', ccode:'ID', code:'62'},
  // {country:'Iran', ccode:'IR', code:'98'},
  // {country:'Iraq', ccode:'IQ', code:'964'},
  // {country:'Ireland', ccode:'IE', code:'353'},
  // {country:'Isle of Man', ccode:'IM', code:'44-1624'},
  // {country:'Ivory Coast', ccode:'CI', code:'225'},
  // {country:'Jamaica', ccode:'JM', code:'1-876'},
  // {country:'Japan', ccode:'JP', code:'81'},
  // {country:'Jersey', ccode:'JE', code:'44-1534'},
  // {country:'Jordan', ccode:'JO', code:'962'},
  // {country:'Kazakhstan', ccode:'KZ', code:'7'},
  // {country:'Kenya', ccode:'KE', code:'254'},
  // {country:'Kiribati', ccode:'KI', code:'686'},
  // {country:'Kosovo', ccode:'XK', code:'383'},
  // {country:'Kuwait', ccode:'KW', code:'965'},
  // {country:'Kyrgyzstan', ccode:'KG', code:'996'},
  // {country:'Laos', ccode:'LA', code:'856'},
  // {country:'Latvia', ccode:'LV', code:'371'},
  // {country:'Lebanon', ccode:'LB', code:'961'},
  // {country:'Lesotho', ccode:'LS', code:'266'},
  // {country:'Liberia', ccode:'LR', code:'231'},
  // {country:'Libya', ccode:'LY', code:'218'},
  // {country:'Liechtenstein', ccode:'LI', code:'423'},
  // {country:'Lithuania', ccode:'LT', code:'370'},
  // {country:'Luxembourg', ccode:'LU', code:'352'},
  // {country:'Macau', ccode:'MO', code:'853'},
  // {country:'Macedonia', ccode:'MK', code:'389'},
  // {country:'Madagascar', ccode:'MG', code:'261'},
  // {country:'Malawi', ccode:'MW', code:'265'},
  // {country:'Malaysia', ccode:'MY', code:'60'},
  // {country:'Maldives', ccode:'MV', code:'960'},
  // {country:'Mali', ccode:'ML', code:'223'},
  // {country:'Malta', ccode:'MT', code:'356'},
  // {country:'Marshall Islands', ccode:'MH', code:'692'},
  // {country:'Mauritania', ccode:'MR', code:'222'},
  // {country:'Mauritius', ccode:'MU', code:'230'},
  // {country:'Mayotte', ccode:'YT', code:'262'},
  {country:'Mexico', ccode:'MX', code:'52'},
  // {country:'Micronesia', ccode:'FM', code:'691'},
  // {country:'Moldova', ccode:'MD', code:'373'},
  // {country:'Monaco', ccode:'MC', code:'377'},
  // {country:'Mongolia', ccode:'MN', code:'976'},
  // {country:'Montenegro', ccode:'ME', code:'382'},
  // {country:'Montserrat', ccode:'MS', code:'1-664'},
  // {country:'Morocco', ccode:'MA', code:'212'},
  // {country:'Mozambique', ccode:'MZ', code:'258'},
  // {country:'Myanmar', ccode:'MM', code:'95'},
  // {country:'Namibia', ccode:'NA', code:'264'},
  // {country:'Nauru', ccode:'NR', code:'674'},
  // {country:'Nepal', ccode:'NP', code:'977'},
  // {country:'Netherlands', ccode:'NL', code:'31'},
  // {country:'Netherlands Antilles', ccode:'AN', code:'599'},
  // {country:'New Caledonia', ccode:'NC', code:'687'},
  // {country:'New Zealand', ccode:'NZ', code:'64'},
  // {country:'Nicaragua', ccode:'NI', code:'505'},
  // {country:'Niger', ccode:'NE', code:'227'},
  // {country:'Nigeria', ccode:'NG', code:'234'},
  // {country:'Niue', ccode:'NU', code:'683'},
  // {country:'North Korea', ccode:'KP', code:'850'},
  // {country:'Norway', ccode:'NO', code:'47'},
  // {country:'Oman', ccode:'OM', code:'968'},
  // {country:'Pakistan', ccode:'PK', code:'92'},
  // {country:'Palau', ccode:'PW', code:'680'},
  // {country:'Palestine', ccode:'PS', code:'970'},
  // {country:'Panama', ccode:'PA', code:'507'},
  // {country:'Papua New Guinea', ccode:'PG', code:'675'},
  // {country:'Paraguay', ccode:'PY', code:'595'},
  // {country:'Peru', ccode:'PE', code:'51'},
  // {country:'Philippines', ccode:'PH', code:'63'},
  // {country:'Pitcairn', ccode:'PN', code:'64'},
  // {country:'Poland', ccode:'PL', code:'48'},
  // {country:'Portugal', ccode:'PT', code:'351'},
  // {country:'Puerto Rico', ccode:'PR', code:'1-787, 1-939'},
  // {country:'Qatar', ccode:'QA', code:'974'},
  // {country:'Republic of the Congo', ccode:'CG', code:'242'},
  // {country:'Reunion', ccode:'RE', code:'262'},
  // {country:'Romania', ccode:'RO', code:'40'},
  // {country:'Russia', ccode:'RU', code:'7'},
  // {country:'Rwanda', ccode:'RW', code:'250'},
  // {country:'Saint Barthelemy', ccode:'BL', code:'590'},
  // {country:'Saint Helena', ccode:'SH', code:'290'},
  // {country:'Saint Kitts and Nevis', ccode:'KN', code:'1-869'},
  // {country:'Saint Lucia', ccode:'LC', code:'1-758'},
  // {country:'Saint Martin', ccode:'MF', code:'590'},
  // {country:'Saint Pierre and Miquelon', ccode:'PM', code:'508'},
  // {country:'Saint Vincent and the Grenadines', ccode:'VC', code:'1-784'},
  // {country:'Samoa', ccode:'WS', code:'685'},
  // {country:'San Marino', ccode:'SM', code:'378'},
  // {country:'Sao Tome and Principe', ccode:'ST', code:'239'},
  // {country:'Saudi Arabia', ccode:'SA', code:'966'},
  // {country:'Senegal', ccode:'SN', code:'221'},
  // {country:'Serbia', ccode:'RS', code:'381'},
  // {country:'Seychelles', ccode:'SC', code:'248'},
  // {country:'Sierra Leone', ccode:'SL', code:'232'},
  // {country:'Singapore', ccode:'SG', code:'65'},
  // {country:'Sint Maarten', ccode:'SX', code:'1-721'},
  // {country:'Slovakia', ccode:'SK', code:'421'},
  // {country:'Slovenia', ccode:'SI', code:'386'},
  // {country:'Solomon Islands', ccode:'SB', code:'677'},
  // {country:'Somalia', ccode:'SO', code:'252'},
  // {country:'South Africa', ccode:'ZA', code:'27'},
  // {country:'South Korea', ccode:'KR', code:'82'},
  // {country:'South Sudan', ccode:'SS', code:'211'},
  // {country:'Sri Lanka', ccode:'LK', code:'94'},
  // {country:'Sudan', ccode:'SD', code:'249'},
  // {country:'Suriname', ccode:'SR', code:'597'},
  // {country:'Svalbard and Jan Mayen', ccode:'SJ', code:'47'},
  // {country:'Swaziland', ccode:'SZ', code:'268'},
  // {country:'Sweden', ccode:'SE', code:'46'},
  // {country:'Switzerland', ccode:'CH', code:'41'},
  // {country:'Syria', ccode:'SY', code:'963'},
  // {country:'Taiwan', ccode:'TW', code:'886'},
  // {country:'Tajikistan', ccode:'TJ', code:'992'},
  // {country:'Tanzania', ccode:'TZ', code:'255'},
  // {country:'Thailand', ccode:'TH', code:'66'},
  // {country:'Togo', ccode:'TG', code:'228'},
  // {country:'Tokelau', ccode:'TK', code:'690'},
  // {country:'Tonga', ccode:'TO', code:'676'},
  // {country:'Trinidad and Tobago', ccode:'TT', code:'1-868'},
  // {country:'Tunisia', ccode:'TN', code:'216'},
  // {country:'Turkmenistan', ccode:'TM', code:'993'},
  // {country:'Turks and Caicos Islands', ccode:'TC', code:'1-649'},
  // {country:'Tuvalu', ccode:'TV', code:'688'},
  // {country:'Uganda', ccode:'UG', code:'256'},
  // {country:'Ukraine', ccode:'UA', code:'380'},
  // {country:'United Arab Emirates', ccode:'AE', code:'971'},
  // {country:'United Kingdom', ccode:'GB', code:'44'},
  // {country:'Uruguay', ccode:'UY', code:'598'},
  // {country:'Uzbekistan', ccode:'UZ', code:'998'},
  // {country:'Vanuatu', ccode:'VU', code:'678'},
  // {country:'Vatican', ccode:'VA', code:'379'},
  // {country:'Venezuela', ccode:'VE', code:'58'},
  // {country:'Vietnam', ccode:'VN', code:'84'},
  // {country:'Wallis and Futuna', ccode:'WF', code:'681'},
  // {country:'Western Sahara', ccode:'EH', code:'212'},
  // {country:'Yemen', ccode:'YE', code:'967'},
  // {country:'Zambia', ccode:'ZM', code:'260'},
  // {country:'Zimbabwe', ccode:'ZW', code:'263'},
  ];
