import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/browser'

// Utils
import API from '../utils/api'

const history = createBrowserHistory()

// Initial prev location is used to cache and after
// know what is the previous route
let prevLocation = {
  pathname: window.location.pathname
}

const checkList = [
  '/slots',
  '/casino'
]

history.listen((nextLocation) => {
  // We check if route changes came from one includes in checklist
  // In this case we notify to backend the session game exit
  if (checkList.find(path => path === prevLocation?.pathname)) {
    let data = getSessionData()

    if (data) {
      closeGameSessionData(data)
    }
  }

  prevLocation = nextLocation
})

const getSessionData = () => {
  try {
    let data = JSON.parse(window.localStorage.getItem('sessionGameData'))

    if (data?.type && data?.token_id) return data
  } catch (error) {}
}

const cleanGameSessionData = () => {
  window.localStorage.setItem(
    'sessionGameData',
    JSON.stringify({
      game_code: null,
      token_id: null,
      type: null
    })
  )
}

export const closeGameSessionData = (data) => {
  API.closeGameSession(data)
    .catch((error) => {
      Sentry.captureException(error, {
        extra: 'Error to close game session'
      })
    })
    .finally(cleanGameSessionData)
}

export default history

