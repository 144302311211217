//Libraires
import React from 'react';
import { Modal } from 'react-bootstrap/lib/';
import API from '../../utils/api';
import AbonarSaldo from '../AbonarSaldo';
import utils from '../../utils/utils';

//Components
import Loading from "../Loading";

//Images
import header from '../../assets/images/header-bonos.png';

//Styles
import './promotions-selection.scss'
import _ from 'underscore';

const PromotionsSelection = ({ updateUserInfo, updateUserBalance }) => {
  const [state, setState] = React.useState({
    showModalPromotion: false,
    promotion: [],
    loading: true,
    showMessage: false,
    showAbonar: false,
  });

  const handleModalPromotions = () => {
    setState({ ...state, showModalPromotion: !state.showModalPromotion });
  }

  const handleModalMessage = () => {
    setState({...state, showMessage: !state.showMessage});
  }

  const handleModalAbonar = () => {
    setState({...state, showAbonar: !state.showAbonar, showModalPromotion: false});
  }

  React.useEffect(() => {
    let userData = utils.getUserInfo();
    let promotion = null;

    if (userData === null) {
      return;
    }

    // API.fetchPromotions().then((response) => {
    //   const { data } = response;
    //   const enabledPromotions = data.filter((promotion) => (promotion && promotion.enabled));

    //   if ( _.isEmpty(enabledPromotions) ) {
    //     return;
    //   }

    //   let userBonus = userData.userBonuses[0] ?? null;

    //   if (userBonus !== null && (userBonus && userBonus.bonus_id === 1)) {
    //     if (!userBonus.completed && userBonus.used === 0) {
    //       promotion = enabledPromotions[0];
    //       setState({
    //         ...state,
    //         promotion,
    //         loading: false,
    //         showModalPromotion: true,
    //       });
    //     }

    //     if (userBonus.completed) {
    //       promotion = enabledPromotions[1];
    //       let bonusPurchase = userData.userBonuses.find((bonus) => bonus.id === promotion.id);

    //       if (bonusPurchase === null || bonusPurchase === undefined) {
    //         //Check show every
    //         let bonusTime = window.localStorage.getItem("bonusTime");
    //         let secondsNow = new Date().getTime() / 1000;
    //         let showEvery = promotion.showEvery * 86400;
    //         let show = false;

    //         if (bonusTime === null) {
    //           window.localStorage.setItem("bonusTime", secondsNow+showEvery);
    //           show = true;
    //         } else {
    //           if (secondsNow >= bonusTime) {
    //             localStorage.setItem("bonusTime", secondsNow+showEvery)
    //             show = true;
    //           }
    //         }

    //         setState({
    //           ...state,
    //           promotion,
    //           loading: false,
    //           showModalPromotion: show,
    //         });
    //       } else {
    //         window.localStorage.removeItem("bonusTime");
    //       }
    //     }
    //   } else {

    //     //if userBonus is empty show purchase bonus
    //     promotion = enabledPromotions[0];

    //     if (!promotion) return null
    //     let bonusPurchase = userData.userBonuses.find((bonus) => bonus.bonus_id === promotion.id);
    //     if (bonusPurchase === null || bonusPurchase === undefined) {
    //       //Check show every
    //       let bonusTime = window.localStorage.getItem("bonusTime");
    //       let secondsNow = new Date().getTime() / 1000;
    //       let showEvery = promotion.showEvery * 86400;
    //       let show = false;

    //       if (bonusTime === null) {
    //         window.localStorage.setItem("bonusTime", secondsNow + showEvery);
    //         show = true;
    //       } else {
    //         if (secondsNow >= bonusTime) {
    //           localStorage.setItem("bonusTime", secondsNow + showEvery)
    //           show = true;
    //         }
    //       }

    //       setState({
    //         ...state,
    //         promotion,
    //         loading: false,
    //         showModalPromotion: show,
    //       });
    //     }
    //   }
    // });
  }, []);

  return (
    state.promotion.length !== 0 &&
    <>
      <Modal
        dialogClassName="modal-dialog-xxxs"
        className="abonarModal absoluteModal"
        backdropClassName="abonarModalBackdrop"
        show={state.showModalPromotion}
      >
        <div className="panel-abonar">
          <div id="panelTitle" className="panel-abonar-titulo font-chivo">
            <span>{state.promotion.title}</span>
            <button className="cerrar-panel" onClick={() => { handleModalPromotions() }} >
              <div className="btn-icon-close"><i className="ion-ios-close-empty" /></div>
            </button>
          </div>
          <div className="col-xs-12 panel-pickwin">
            <div className='mt-2 text-center font20'>
              {state.promotion.successMsg}
            </div>

            <div className='mt-2 text-center'>
              <a href={state.promotion.url} target="_blank">
                <img src={state.promotion.imgDesktop} alt="" width="100%" />
              </a>
            </div>

            <div className="mt-2 text-center">
              {state.promotion.id === 1 &&
                <a href={state.promotion.redirect.desktop}>
                  <button type="button" className="btn-lobby btn btn-default" style={{width: "70%"}}>{state.promotion.okText}</button>
                </a>
              }
              {state.promotion.id === 2 &&
                <button type="button" onClick={handleModalAbonar} className="btn-lobby btn btn-default" style={{width: "70%"}}>{state.promotion.okText}</button>
              }
              <a href={state.promotion.url} target="_blank" >Términos y Condiciones</a>
            </div>
          </div>
        </div>
      </Modal>

      <AbonarSaldo
        type="modal"
        showAbonarSaldo={state.showAbonar}
        updatePanelSate={handleModalAbonar}
        showAbonarPop={false}
        updateUserInfo={updateUserInfo}
        updateUserBalance={updateUserBalance}
      />
    </>
  );
}

export default PromotionsSelection
