import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import Clipboard from 'clipboard'
import * as Sentry from '@sentry/browser'
import $ from 'jquery'
import _ from 'underscore'
import { ShareButtons } from 'react-share'
import {
  MenuItem
} from 'react-bootstrap/lib'

// Components
import Loading from '../../../../Loading'
import ListReferral from './ListReferral'
import SelectTypeReferral from './SelectTypeReferral'
import PanelInvitaJugar from '../../../../PanelInvitaJugar'
import PanelInvitarAmigos from '../../../../PanelInvitarAmigos'
import ConfirmationDeleteReferral from './ConfirmationDeleteReferral'

// Utils
import API from '../../../../../utils/api'
import utils from '../../../../../utils/utils'
import branchUtils from '../../../../../utils/branchUtils'

// Assets
import invitados from '../../../../../assets/images/invitarIconBlanco-50.png'
import pickcoin from '../../../../../assets/images/pickcoin1-50.png'
import billete from '../../../../../assets/images/cash1-50.png'


let clipboardCode

const {
  FacebookShareButton,
  TwitterShareButton
} = ShareButtons

class Referrals extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSocialSpace: null,
      branchLink: null,
      showMailInvite: false,
      showInvitePanel: false,
      showInfoPopUp: false,
      showInviteJugar: false,
      deleteError: false,
      showSocialSpace: false,
      showInvitationResponse: false,
      referals: null,
      submitting: false,
      commissionRange: 'total',
      mounted: false,
      selectedType: 'new',
      referalCommissions: null,
      poolCommissions: null,
      refererToDelete: null,
      userInviteObject: null,
      referalsQueryParams: {
        page: 1,
        limit: 100
      }
    }

    this.showPopUpInfo = () => {
      this.setState({
        showInfoPopUp: true
      })
    }

    this.hidePopUpInfo = () => {
      utils.removeReferralsPopUp()
      this.setState({
        showInfoPopUp: false
      })
    }

    this.hideInvitationResponse = () => {
      this.setState({ showInvitationResponse: false })
    }

    this.sendChallenge = (userId, poolId) => {
      const challangeData = {
        pool_id: poolId,
        user_id: userId
      }
      // console.log(challangeData);
      API.postChallengeUser(challangeData)
        .then((response) => {
          console.log(response)
          if (response.data.success) {
            this.setState({
              responseStatus: true,
              showInvitationResponse: true
            })
          } else {
            this.setState({
              responseStatus: false,
              showInvitationResponse: true
            })
          }
        })
        .catch((error) => {
          this.setState({
            responseStatus: false,
            showInvitationResponse: true
          })
          console.error(error)
        })
    }

    this.getBranchLink = () => {
      return branchUtils.createLink(utils.getUserInfo(), 1234, 'pool')
    }

    this.getBranchLinkChallenge = (pool, gameType) => {
      return branchUtils.createLinkChallenge(utils.getUserInfo(), pool, gameType)
    }

    this.getBranchLinkReferrer = () => {
      console.log('Get branchLink referer')
      return branchUtils.createLinkInviteReferrer(utils.getUserInfo()).then((url) => {
        console.log(url)
        this.setState({ branchLink: url })
      })
    }

    this.updateBranchLink = (newLink) => {
      this.setState({
        branchLink: newLink
      })
    }

    this.whatsAppInvitationReferer = (branchLink) => {
      console.log('WhatsApp Invitation Referrer')
      branchUtils.redirectNewReferrerToWhatsApp(branchLink, 'open')
    }

    this.whatsAppInvitationChallenge = (branchLink, pool) => {
      console.log('WhatsApp Invitation Challenge')
      branchUtils.redirectChallengeToWhatsApp(branchLink, 'open', pool)
    }

    this.openInviteJugar = (user) => {
      this.setState({
        userInviteObject: user,
        showInviteJugar: true
      })
    }

    this.hideInviteJugar = () => {
      this.setState({
        showInviteJugar: false
      })
    }

    this.openMailPanel = () => {
      this.setState({
        showMailInvite: true
      })
    }

    this.hideMailPanel = () => {
      this.setState({ showMailInvite: false })
    }

    this.openInvitePanel = () => {
      this.setState({
        showInvitePanel: true
      })
    }
    this.hideInvitePanel = () => {
      this.setState({ showInvitePanel: false, branchLink: null })
    }
    /**
   * This function handles the type of referal that will be shown in the render
   @param value
   */
    this.handleSelectedType = (e) => {
      this.setState({
        selectedType: e
      })
    }

    /**
   * This function get the info of the API  to show it tin the tables o Commmision
   @param range
   */
    this.getCommissions = (range) => {
      this.setState({
        submitting: true,
        commissionRange: range === undefined ? 'total' : range
      })
      // console.log('Get the commission',range);
      if (range === undefined) {
        // console.log('%c UNDEFINED', 'backgorund:');
        const query = '?range=total'
        API.fetchUserReferrals(query).then((response) => {
          console.log('Get  Commissions', response.data)
          this.setState({
            submitting: false,
            referals: response.data.referals,
            poolCommissions: response.data.poolCommissions,
            referalCommissions: response.data.referalCommissions
          })
        })
      } else {
        // console.log('%c DEFINED', 'backgorund:');
        if (range === 'week' || range === 'month' || range === 'semester' || range === 'total') {
          // console.log('range');
          const query = `?range=${range}`
          API.fetchUserReferrals(query).then((response) => {
            console.log('Get  Commissions', response.data)
            this.setState({
              submitting: false,
              referals: response.data.referals,
              poolCommissions: response.data.poolCommissions,
              referalCommissions: response.data.referalCommissions
            })
          })
        }
      }
    }

    this.addReferral = (id) => {
      // Call APi that adds referral
    }
    this.sendInvite = () => {
      // SendInvite
    }

    this.openConfirmation = (id) => {
      console.log(id)
      const deleteReferal = this.state.newReferrals.find((referal) => referal.id === id)
      console.log(id, deleteReferal)
      this.setState({
        refererId: id,
        refererToDelete: deleteReferal.referred_user,
        showConfirmation: true
      })
    }

    this.closeConfirmation = () => {
      this.setState({
        refererToDelete: null,
        showConfirmation: false,
        deleteError: false
      })
    }

    this.deleteReferral = (id, refererUserId) => {
      // API call that deletes referer
      console.log('Delete Referal', id)
      this.setState({
        submitting: true
      })

      API.deleteReferral(id)
        .then((response) => {
          if (response.data.success) {
            this.setState(
              {
                deleteError: false,
                submitting: false
              },
              () => {
                const temporalReferrals = this.state.newReferrals
                const deleteThis = _.findIndex(
                  temporalReferrals,
                  (referral) => referral.user_id === refererUserId
                )
                console.info(deleteThis)
                if (!deleteThis || deleteThis === -1) {
                  this.setState({
                    deleteError: true
                  })
                }

                temporalReferrals.splice(deleteThis, 1)
                this.setState(
                  {
                    newReferrals: temporalReferrals
                  },
                  () => {
                    this.closeConfirmation()
                  }
                )
              }
            )
          } else {
            this.setState(
              {
                deleteError: true,
                submitting: false
              },
              () => {
                // this.closeConfirmation();
              }
            )
          }
        })
        .catch((error) => {
          this.setState(
            {
              deleteError: true,
              submitting: false
            },
            () => {
              // this.closeConfirmation();
            }
          )
        })
    }
    /**
     * This function Handle what kind of Commisson will be shown
     * @param eventKey
     */
    this.handleCommissionsFilter = (eventKey) => {
      console.log('%c Handling Comission Filter ...', 'background-color:#FF8A09')
      console.log(eventKey)
      this.getCommissions(eventKey)
    }
    /**
     * This function activate/opens the Social Component in the Invitados Section
     * @param eventKey
     */
    this.checkSocialSpace = (eventKey) => {
      console.log(eventKey)
      this.setState((prevState) => {
        return {
          activeSocialSpace: eventKey,
          showSocialSpace:
            eventKey === prevState.activeSocialSpace ? !prevState.showSocialSpace : true
        }
      })
    }
  }

  componentDidMount () {
    clipboardCode = new Clipboard('.copiar-btn')
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value
      $(wich).addClass('copied')
      e.clearSelection()
    })

    const showInfoPopUp = !!utils.getReferralsStatus()

    this.setState({
      submitting: true
    })

    if (this.props.userData.has_old_referrals) {
      API.fetchUserReferrals()
        .then((userReferralsResponse) => {
          // console.log(response);
          API.fetchNewReferrals()
            .then((newReferralsResponse) => {
              this.setState({
                showInfoPopUp,
                newReferrals: newReferralsResponse.data.referrals,
                // newReferrals: mockNewReferral.response.data.referrals,
                isNewReferral: !this.props.userData.has_old_referrals,
                referals: userReferralsResponse.data.referals,
                poolCommissions: userReferralsResponse.data.poolCommissions,
                referalCommissions: userReferralsResponse.data.referalCommissions,
                mounted: true,
                submitting: false
              })
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Erro Fetching New Referrals'
              })
            })
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Erro Fetching User Referrals'
          })
        })
    } else {
      /**
       * NEw Referrals PRogram
       */
      API.fetchNewReferrals()
        .then((newReferralsResponse) => {
          this.setState({
            showInfoPopUp,
            newReferrals: newReferralsResponse.data.referrals,
            // newReferrals: mockNewReferral.response.data.referrals,
            isNewReferral: !this.props.userData.has_old_referrals,
            mounted: true,
            submitting: false
          })
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error, {
            extra: 'Erro Fetching New Referrals'
          })
        })
    }
  }

  render () {
    let conditionalComponent = null
    if (!this.state.mounted) {
      // console.log(this.state.mounted);
      conditionalComponent = <Loading label='Cargando información de tus referidos...' />
    } else {
      let listaInvitados
      let listaAmigos
      let listaAmigosAmigos
      let listaTotalPickcoins
      let listaTotalReal
      let listaTotalAll

      // console.log(this.state.mounted);

      listaInvitados =
        this.state.referals !== null && this.state.referals.length > 0 ? this.state.referals : []
      // console.log(listaInvitados);
      if (listaInvitados.length > 0) {
        listaInvitados = listaInvitados.map((invitado, i) => (
          <div className='flex-row invitados-item' key={invitado.Users.nick}>
            <div className='flex-col col-30 invitados-category nick'>{invitado.Users.nick}</div>
            <div className='flex-col col-5 invitados-category tipo'>{invitado.level}</div>
            <div
              className='flex-col col-15 invitados-category estatus'
              style={invitado.last_entry !== null ? { color: '#87B13D' } : { color: '#D3242B' }}
            >
              {invitado.last_entry !== null
                ? (
                  <i className='ion-checkmark-circled' />
                  )
                : (
                  <i className='ion-close-circled' />
                  )}
            </div>
            <div className=' flex-col invitados-category postura'>
              {invitado.last_entry === null
                ? 'N/A'
                : moment(invitado.last_entry).format('D/MM/YY h:m')}
            </div>
          </div>
        ))

        listaAmigos = this.state.referalCommissions.filter((comision) => comision.level === 1)

        // console.log(listaAmigos);
        if (listaAmigos.length > 1) {
          listaAmigos = listaAmigos.map((comision, i) => (
            <div className='flex-row comision-item amigos' key={i}>
              <div className='flex-col comision-category nombre'>Amigos</div>
              <div className='flex-col comision-category real'>
                {comision.currency === 'real'
                  ? `${numeral(comision.total / 100).format('$0,000.00')} MXN`
                  : null}
              </div>
              <div className='flex-col comision-category pickcoins'>
                {comision.currency === 'pickcoin'
                  ? `${numeral(comision.total / 100).format('0,000')} PC`
                  : null}
              </div>
            </div>
          ))
          listaAmigos = listaAmigos.filter((lista, i) => {
            if (i > 0) {
              if (listaAmigos[i - 1].key !== lista.key) {
                // console.log(lista, listaAmigos[i], listaAmigos[i-1])
                return lista
              }
            }
          })
        } else if (listaAmigos.length === 1) {
          listaAmigos = (
            <div className='flex-row comision-item amigos'>
              <div className='flex-col comision-category nombre'>Amigos</div>
              <div className='flex-col comision-category real'>
                {listaAmigos.currency === 'real'
                  ? `${numeral(listaAmigos.total / 100).format('$0,000.00')} MXN`
                  : null}
              </div>
              <div className='flex-col comision-category pickcoins'>
                {listaAmigos.currency === 'pickcoin'
                  ? `${numeral(listaAmigos.total / 100).format('0,000')} PC`
                  : null}
              </div>
            </div>
          )
        }
        listaAmigosAmigos = this.state.referalCommissions.filter(
          (comision) => comision.level === 2
        )
        // console.log('LISTA AMIGOS DE AMIGOS', listaAmigosAmigos);
        if (listaAmigosAmigos.length > 1) {
          listaAmigosAmigos = listaAmigosAmigos.map((comision, i) => {
            console.log(comision)
            return (
              <div className='flex-row comision-item amigos-amigos' key={i}>
                <div className='flex-col comision-category nombre'>Amigos de Amigos</div>
                <div className='flex-col comision-category real'>
                  {comision.currency === 'real'
                    ? `${numeral(Number(comision.total) / 100).format('0,000')} MXN`
                    : null}
                </div>
                <div className='flex-col comision-category pickcoins'>
                  {comision.currency === 'pickcoin'
                    ? `${numeral(Number(comision.total) / 100).format('0,000')} PC`
                    : null}
                </div>
              </div>
            )
          })
        } else if (listaAmigosAmigos.length === 1) {
          listaAmigosAmigos = listaAmigosAmigos.map((comision, i) => (
            <div className='flex-row comision-item amigos-amigos' key={i}>
              <div className='flex-col comision-category nombre'>Amigos de Amigos</div>
              <div className='flex-col comision-category real'>
                {comision.currency === 'real'
                  ? `${numeral(comision.total / 100).format('0,000')} MXN`
                  : null}
              </div>
              <div className='flex-col comision-category pickcoins'>
                {comision.currency === 'pickcoin'
                  ? `${numeral(comision.total / 100).format('0,000')}`
                  : null}
              </div>
            </div>
          ))
        }
        listaAmigosAmigos = listaAmigosAmigos.map((comision, i) => (
          <div className='flex-row comision-item amigos-amigos' key={i}>
            <div className='flex-col comision-category nombre'>Amigos de Amigos</div>
            <div className='flex-col comision-category real'>
              {comision.currency === 'pickcoin'
                ? numeral(listaAmigosAmigos[i + 1].total / 1000).format('0,000')
                : numeral(comision.total / 1000).format('$0,000.00')}{' '}
              MXN
            </div>
            <div className='flex-col comision-category pickcoins'>
              {numeral(comision.total / 100).format('0,000')} PC
            </div>
          </div>
        ))
        listaAmigosAmigos = listaAmigosAmigos.filter((lista, i) => {
          if (i > 0) {
            if (listaAmigosAmigos[i - 1].key !== lista.key) {
              // console.log(lista, listaAmigosAmigos[i], listaAmigosAmigos[i-1])
              return lista
            }
          }
        })

        listaTotalPickcoins = this.state.referalCommissions
        // console.log(listaTotalPickcoins);
        listaTotalPickcoins = _.reduce(
          listaTotalPickcoins,
          (memo, num) => {
            console.log(memo)
            if (num.currency === 'pickcoin') {
              console.log(memo, Number(num.total))
              return memo + Number(num.total)
            }
            return memo
          },
          0
        )

        listaTotalReal = this.state.referalCommissions
        // console.log(listaTotalReal);
        listaTotalReal = _.reduce(
          listaTotalReal,
          (memo, num) => {
            console.log(memo)
            if (num.currency === 'real') {
              console.log(memo, Number(num.total))
              return memo + Number(num.total)
            }
            return memo
          },
          0
        )

        listaTotalAll = (
          <div className='flex-row comision-item amigos'>
            <div className='flex-col comision-category nombre'>Total</div>
            <div className='flex-col comision-category real'>
              {numeral(listaTotalReal / 100).format('$0,000.00')} MXN
            </div>
            <div className='flex-col comision-category pickcoins'>
              {numeral(listaTotalPickcoins / 100).format('0,000')} PC
            </div>
          </div>
        )
      }

      conditionalComponent = (
        <div>
          <ConfirmationDeleteReferral
            submitting={this.state.submitting}
            deleteError={this.state.deleteError}
            showConfirmation={this.state.showConfirmation}
            closeConfirmation={this.closeConfirmation}
            deleteReferral={this.deleteReferral}
            refererId={this.state.refererId}
            refererToDelete={this.state.refererToDelete}
          />
          <PanelInvitarAmigos
            branchLink={this.state.branchLink}
            closePanel={this.hideInvitePanel}
            openInvitePanel={this.openMailPanel}
            hidePanel={this.hideMailPanel}
            showPanel={this.state.showInvitePanel}
            showMailInvite={this.state.showMailInvite}
            title='Invita a tus amigos a jugar en Pickwin'
            whatsAppInvitationReferer={this.whatsAppInvitationReferer}
          />
          <PanelInvitaJugar
            branchLink={this.state.branchLink}
            closePanel={this.hideInviteJugar}
            getBranchLinkChallenge={this.getBranchLinkChallenge}
            hideInvitationResponse={this.hideInvitationResponse}
            hidePanel={this.hideMailPanel}
            openInvitePanel={this.openMailPanel}
            poolSelected={this.poolSelected}
            responseStatus={this.state.responseStatus}
            sendChallenge={this.sendChallenge}
            showInvitationResponse={this.state.showInvitationResponse}
            showMailInvite={this.state.showMailInvite}
            showPanel={this.state.showInviteJugar}
            title='Recuerda a '
            userInviteObject={this.state.userInviteObject}
            whatsAppInvitationChallenge={this.whatsAppInvitationChallenge}
          />
          <div className='category-user invitados flex-row'>
            <div className='info-invitados flex-col col-no-padding'>
              <div className='titulo-user-info flex-row'>
                <div className=''>
                  <img src={invitados} className='title-icon' />
                </div>
                <div className='flex-col'>
                  <span>{this.state.selectedType === 'new' ? 'Referidos' : 'Invitados'}</span>
                </div>
              </div>
              <SelectTypeReferral
                selectedType={this.state.selectedType}
                isNewReferral={this.state.isNewReferral}
                handleSelectedType={this.handleSelectedType}
              />
              {this.state.selectedType === 'old'
                ? (
                  <div>
                    <div className='user-ganancias'>
                      <div className='titulo-gananacias'>
                        <span className='ganancias-titulo'>Mis Ganancias</span>
                        <div className='ganancias-user  real'>
                          {this.state.poolCommissions !== null
                            ? this.state.poolCommissions.total_real === null
                              ? 0
                              : numeral(this.state.poolCommissions.total_real / 100).format(
                                '$0,000.00'
                              )
                            : 0}
                          MXN
                        </div>
                        <div className='ganancias-user pickcoins'>
                          {this.state.poolCommissions !== null
                            ? this.state.poolCommissions.total_pickcoin === null
                              ? 0
                              : numeral(this.state.poolCommissions.total_pickcoin / 100).format(
                                '0,000'
                              )
                            : 0}{' '}
                          PC
                        </div>
                      </div>
                      <div className='tablas-invitacion flex-row responsive-sm'>
                        <div className='user-tabla comisiones flex-col '>
                          <div className=' flex-row titulo-mis-ganancias'>
                            <i className='ion-cash' />
                            <span>Mis Comisiones</span>
                          </div>
                          <div className='flex-row tabla-comisiones'>
                            <div className='flex-col'>
                              <div className='flex-row filtros-comisiones'>
                                <MenuItem
                  eventKey='total'
                  className={` flex-col
                              ${this.state.commissionRange === 'total' ? 'active' : null}`}
                  onSelect={this.handleCommissionsFilter}
                >
                                Total
                </MenuItem>
                                <MenuItem
                  eventKey='week'
                  className={`
                              flex-col
                            ${this.state.commissionRange === 'week' ? 'active' : null}`}
                  onSelect={this.handleCommissionsFilter}
                >
                                Semana
                </MenuItem>
                                <MenuItem
                  eventKey='month'
                  className={`
                            flex-col
                            ${this.state.commissionRange === 'month' ? 'active' : null}`}
                  onSelect={this.handleCommissionsFilter}
                >
                                Mes
                </MenuItem>
                                <MenuItem
                  eventKey='semester'
                  className={` flex-col ${
                                  this.state.commissionRange === 'semester' ? 'active' : null
                                  }`}
                  onSelect={this.handleCommissionsFilter}
                >
                                6 Meses
                </MenuItem>
                              </div>
                              <div className='flex-row '>
                                <div className='flex-col'>
                  <div className='flex-row'>
                                  <div className='flex-col titulo-comisiones'>
                                    <span>Quinielas Públicas</span>
                                  </div>
                                </div>
                  <div className='flex-col data-comisiones quinielas'>
                                  <div className='flex-row comision-item header '>
                                    <span style={{ color: 'transparent' }}>{' none'}</span>
                                    <span>
                                      MXN
                                      <img src={billete} className='pool-icons comision' />
                                    </span>
                                    <span>
                                      PC
                                      <img src={pickcoin} className='pool-icons comision' />
                                    </span>
                                  </div>
                                  {this.state.submitting
                                    ? (
                                      <div className='flex-container justify align'>
                                        <i className='ion-load-a loading' />
                                      </div>
                                      )
                                    : (
                                      <div>
                                        {listaAmigos}
                                        {listaAmigosAmigos}
                                        {listaTotalAll}
                                      </div>
                                      )}
                                </div>
                </div>
                              </div>
                              <div className='flex-col '>
                                <div className='flex-row'>
                  <div className='flex-col titulo-comisiones '>
                                  <span>Quinielas Privadas</span>
                                </div>
                </div>
                                <div className=' flex-col data-comisiones quinielas'>
                  <div className='flex-row'>
                                  <div className='flex-col comision-item header'>
                                    <span style={{ color: 'transparent' }}>{' none'}</span>
                                    <span>
                                      MXN
                                      <img src={billete} className='pool-icons comision' />
                                    </span>
                                    <span>
                                      PC
                                      <img src={pickcoin} className='pool-icons comision' />
                                    </span>
                                  </div>
                                </div>
                  {this.state.submitting
                                  ? (
                                    <div className='flex-container justify align'>
                                      <i className='ion-load-a loading' />
                                    </div>
                                    )
                                  : (
                                    <div className='flex-row'>
                                      <div className=' flex-col comision-item'>Total</div>
                                      <div className=' flex-col comision-category '>
                                        <div className='flex-row'>
                                          <div className='flex-col comision-category real'>
                                            {this.state.referals !== null
                                              ? !this.state.poolCommissions.total_real
                                                  ? numeral(0).format('$0,000.00')
                                                  : numeral(
                                                    this.state.poolCommissions.total_real / 100
                                                  ).format('$0,000.00')
                                              : 0}
                                            MXN
                                          </div>
                                          <div className=' comision-category pickcoins flex-container align end'>
                                            {this.state.referals !== null
                                              ? !this.state.poolCommissions.total_pickcoin
                                                  ? numeral(0).format('0,000')
                                                  : numeral(
                                                    this.state.poolCommissions.total_pickcoin / 100
                                                  ).format('0,000')
                                              : 0}
                                            PC
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    )}
                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='user-tabla invitados  flex-col responsive-sm'>
                          <div className='titulo-mis-ganancias flex-row'>
                            <i className='ion-person-add' />
                            <span>Mis Invitados</span>
                          </div>
                          <div className='flex-row row-no-padding tabla-comisiones'>
                            <div className='flex-col'>
                              <div className='flex-row filtros-comisiones'>
                                <MenuItem
                  eventKey='total'
                  className={` flex-col ${
                                  this.state.commissionRange === 'total' ? 'active' : null
                                  }`}
                  onSelect={this.handleCommissionsFilter}
                >
                                Total
                </MenuItem>
                                <MenuItem
                  eventKey='week'
                  className={` flex-col ${
                                  this.state.commissionRange === 'week' ? 'active' : null
                                  }`}
                  onSelect={this.handleCommissionsFilter}
                >
                                Semana
                </MenuItem>
                                <MenuItem
                  eventKey='month'
                  className={` flex-col ${
                                  this.state.commissionRange === 'month' ? 'active' : null
                                  }`}
                  onSelect={this.handleCommissionsFilter}
                >
                                Mes
                </MenuItem>
                                <MenuItem
                  eventKey='semester'
                  className={` flex-col ${
                                  this.state.commissionRange === 'semester' ? 'active' : null
                                  }`}
                  onSelect={this.handleCommissionsFilter}
                >
                                6 Meses
                </MenuItem>
                              </div>
                              <div className=' flex-row '>
                                <div className='flex-col col-no-padding'>
                  <div className='flex-row row-center titulo-comisiones  '>
                                  <span className='flex-col col-30 col-center invitados-category nick'>
                                    Nick
                                  </span>
                                  <span className='flex-col col-5 col-center invitados-category tipo'>
                                    Tipo
                                  </span>
                                  <span className='flex-col col-15 col-center invitados-category estatus'>
                                    Estatus
                                  </span>
                                  <span className='flex-col col-center invitados-category postura'>
                                    {' '}
                                    Última Postura
                                  </span>
                                </div>
                  {this.state.submitting
                                  ? (
                                    <div className='flex-container justify align'>
                                      <i className='ion-load-a loading' />
                                    </div>
                                    )
                                  : (
                                    <div className=' flex-row data-comisiones'>
                                      {!listaInvitados
                                        ? (
                                          <span className='flex-row'>
                                            Aún no tienes ningún invitado, Invita a alguien para recibir
                                            algunos beneficios.
                                            <i className='ion-reply' />
                                          </span>
                                          )
                                        : (
                                            listaInvitados
                                          )}
                                    </div>
                                    )}
                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                : (
                  <div className='flex-row row-no-padding'>
                    <ListReferral
                      openAbonar={this.props.showAbonar}
                      updateUserInfo={this.props.updateUserInfo}
                      getBranchLink={this.getBranchLink}
                      getBranchLinkChallenge={this.getBranchLinkChallenge}
                      getBranchLinkReferrer={this.getBranchLinkReferrer}
                      updateBranchLink={this.updateBranchLink}
                      openInvitePanel={this.openInvitePanel}
                      openInviteJugar={this.openInviteJugar}
                      hideInviteJugar={this.hideInviteJugar}
                      openConfirmation={this.openConfirmation}
                      newReferrals={this.state.newReferrals}
                    />
                  </div>
                  )}
            </div>
          </div>
        </div>
      )
    }
    return conditionalComponent
  }
}
export default Referrals
