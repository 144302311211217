import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'

// Componnents
import { Iconify } from '../../../Xmerald-UI'

moment.locale('es')

export const WeekItemStats = (props) => {
  const {
    week,
    stats,
    currentEntry,
    teams,
    survivorInfo,
    colapsedStatus,
    toggleColapsed,
  } = props

  const { isExpanded = false } = colapsedStatus || {}

  const [listLeft, listRight] = getSplitedList(stats)

  const selectedPick = currentEntry?.survivor_picks?.[week.id];

  // 1 - Buscamos el ultimo strike
  const lastStrike = Object.values(currentEntry?.survivor_picks || {})?.reverse()?.find(entry  =>  !entry.correct)
  const isLastIncorrect = lastStrike?.id === selectedPick?.id && !currentEntry?.survivor_standing?.alive

  const selectedTeam = teams.find(team => team.id === selectedPick?.pick)

  const toggle = () => {
    toggleColapsed(week.id, !isExpanded)
  }

  const correct = stats?.reduce((sum, pick) => {
    return pick?.correct == 1 ? sum + pick?.count : sum
  }, 0)
  
  const incorrect = stats?.reduce((sum, pick) => {
    return pick?.correct == 0 ? sum + pick?.count : sum
  }, 0)

  const getBorderColor = (correct) => {
    if (correct === null) return 'transparent'
    if (!correct) return '#d81212'
    if (correct) return '#72BE1E'
  }

  return (
    <WeekPanel>
        <Panel onClick={toggle}>
          <div><Iconify icon={isExpanded ? 'eva:arrow-down-fill': 'eva:arrow-right-fill'} /></div>
          <WeekName style={{ backgroundColor: getWeekNameColor(selectedPick, survivorInfo, isLastIncorrect)}}>
            {week.week_name}
          </WeekName>

          <TeamName>
            <i class={'team-logo home ' +  selectedTeam?.logo}></i>
            <span>{selectedTeam?.short_name}</span>
          </TeamName>
        </Panel>

        {isExpanded && (
          <PickList>

            <ListHeader>
              <div className='team'>Entradas: <Strong>{correct + incorrect}</Strong></div>
              <div className='team'>VIVOS: <Strong>{correct}</Strong></div>
              <div className='end'>ELIMINADOS: <Strong>{incorrect}</Strong></div>
            </ListHeader>

            <Stats>
              <StatCol>
                {listLeft.map((team, index) => (
                  <Stat key={index}>
                    <Position style={{ opacity: team.correct ? 1 : 0.6}}>{team.rank}°</Position>
                    <TeamInfo>
                      <i class={'team-logo s home ' + team.logo}></i>
                    </TeamInfo>

                    <Team>
            
                      {/* Los strikes se muestan en color amarillo y solo para soccer */}
                      {team.strike && survivorInfo?.sport_id === 1
                        ? <TeamTie>{team.abbreviation.substring(0, 3)}</TeamTie>
                        : (
                          <TeamCircle
                            style={{
                              borderColor: getBorderColor(team.correct),
                              opacity: (team.correct || team.correct === null) ? 1 : 0.6
                              }}
                            >
                            {team.abbreviation.substring(0, 3)}
                          </TeamCircle>
                        )
                      }
              
                    </Team>
                    <Num style={{ opacity: team.correct ? 1 : 0.6}}>{team.count}</Num>
                    <Percentage style={{ opacity: team.correct ? 1 : 0.6}}>
                      {(team.percent * 100).toFixed(2)}%
                    </Percentage>
                  </Stat>
                ))}
              </StatCol>
              <StatCol>
                {listRight.map((team, index) => (
                  <Stat key={index}>
                    <Position style={{ opacity: team.correct ? 1 : 0.6}}>{team.rank}°</Position>
                    <TeamInfo>
                      <i class={'team-logo s home ' + team.logo}></i>
                    </TeamInfo>
                    <Team>
                      {/* Los strikes se muestan en color amarillo y solo para soccer */}
                      {team.strike && survivorInfo?.sport_id === 1
                        ? <TeamTie>{team.abbreviation.substring(0, 3)}</TeamTie>
                        : (
                          <TeamCircle
                            style={{
                              borderColor: getBorderColor(team.correct),
                              opacity: (team.correct || team.correct === null) ? 1 : 0.6
                              }}
                            >
                            {team.abbreviation.substring(0, 3)}
                          </TeamCircle>
                        )
                      }

                    </Team>
                    <Num style={{ opacity: team.correct ? 1 : 0.6}}>{team.count}</Num>
                    <Percentage style={{ opacity: team.correct ? 1 : 0.6}}>{(team.percent * 100).toFixed(2)}%</Percentage>
                  </Stat>
                ))}
              </StatCol>
              
            </Stats>
          </PickList>
        )}
      </WeekPanel>
  )
}

function getSplitedList (list) {

  if (!list || list?.length === 0) return [[],[]]

  const leng = list.length / 2

  const isOdd = list?.length % 2 === 1

  if (isOdd) {
    return [list.slice(0, leng + 1), list.slice(leng + 1)]
  }

  return [list.slice(0, leng), list.slice(leng)]
}

const getWeekNameColor = (selectedPick, survivorInfo, isLastIncorrect) => {
  if (selectedPick?.strike && survivorInfo.strikes_enabled) return 'yellow'
  if (selectedPick?.correct) return '#72BE1E'
  if (!selectedPick?.correct && isLastIncorrect) return '#be1e1e'
}


const TeamName = styled.div`
  font-family: Chivo;
  font-style: italic;
  font-weight: bold;
  display: flex;
  align-items: center;
  display: flex;
  border-radius: 0.4em;
  height: 1.5em;
  text-transform: uppercase;
  font-size: 0.85em;
`


const WeekName = styled.div`
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  font-weight: bold;
  text-shadow: 0 0 4px #000000fd;
  line-height: 2;
`

const Strong = styled.span`
  font-family: Chivo;
  font-style: italic;
  font-weight: bold;
  margin-left: 0.3em;
`


const Stats = styled.div`
  display: flex;

  font-size: 1em;

  @media screen and (min-width: 1200px) {
    font-size: 1.1em;
  }
`

const Stat = styled.div`
  display: flex;
  padding: 0 3%;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 0.75em;
  border-bottom: 1px solid gray;
  height: 33px;
`

const StatCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  &:nth-child(1n) {
    border-right: 1px solid gray;;
  }
`

const TeamCircle = styled.span`
  font-size: 0.65em;
  width: 3.8em;
  height: 3.8em;
  line-height: 1;
  border-radius: 2.5em;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-weight: bold;
  box-sizing: border-box;
`

const TeamTie = styled.span`
  font-size: 0.65em;
  width: 3.8em;
  height: 3.8em;
  border-radius: 2.5em;
  display: flex;
  justify-content: center;
  border: 1px solid yellow;
  align-items: center;
  font-style: italic;
  line-height: 1;
  font-weight: bold;
  font-family: Chivo;
  box-sizing: border-box;
`

const TeamInfo = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 25px;
  max-width: 25px;
  flex-shrink: 0;

  & i {
    transform: scale(0.75) translateX(-5px);
  }
`

const Team = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 25px;
  flex-shrink: 0;

  & i {
    transform: scale(0.75);
  }
`


const Position = styled.span`
  flex-basis: 1.7em;
  flex-shrink: 0;
`

const Percentage = styled.span`
  width: 5em;
  font-size: 0.85em;
  text-align: right;
`

const Num = styled.span`
  width: 3em;
  font-size: 0.85em;
  text-align: right;
`

const WeekPanel = styled.div`
  min-height: 2.4em;
  margin: 0.5em 0.5em;
  border: 1px solid #ffffff;
`

const Panel = styled.div`
  background-color: #1b1919;
  width: 100%;
  height: 2.4em;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  cursor: pointer;
  user-select: none;

  svg:first {
    font-size: 14px;
  }
`

const PickList = styled.div``

const ListHeader = styled.div`
  height: 2em;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-bottom: 1px solid #c5c5c5;
  border-top: 1px solid #c5c5c5;
  padding: 0 0.7em;
  height: 2.5em;
  font-style: italic;

  .team {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .end {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
