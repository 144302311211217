import React from 'react';
import utils from '../utils/utils';
import firstBy from 'thenby';
import moment from 'moment';
import _ from 'underscore';

/**
 * Context of the QuinielaGroups
 */
const QuinielaGroupsContext = React.createContext();

/**
 * This is the initial state
 */
let initialState = {
  activeSort: { posiciones: false, jugadores: false, aciertos: false, premios: false },
  branchLink: null,
  enoughBalance: false,
  entries: null,
  successFollow: null,
  errorFollow: null,
  entriesInfo: null,
  error: { error: false, message: null },
  groupPicks: [],
  isValid: false,
  inviteStateus: null,
  mounted: false,
  myEntry: {},
  newMessage: '',
  players: [],
  pointDistribution: [],
  poolBuckets: [],
  bucketKeys: [],
  poolInfo: false,
  prizes: [],
  contestPrizes: [],
  contestStandings: [],
  contestTiesDistribution: [],
  redirect: false,
  redirectTo: undefined,
  similarPools: null,
  selecctedEntry: null,
  selection: 1,
  showAbonar: false,
  showError: false,
  showInvite: false,
  showRegistration: false,
  socketUpdate: null,
  showReRegistration: false,
  showCancelation: false,
  showMailPanel: false,
  showSuccessfull: false,
  showPopUpReglas: false,
  showAddConfirmation: false,
  userToAdd: null,
  showOverlay: false,
  saveSuccess: false,
  saveError: false,
  standings: [],
  submittingInvite: false,
  submittingSearch: false,
  submittingGroupPicks: false,
  searchableNick: '',
  submitting: false,
  queryParams: {
    page: 1,
    limit: 100,
    nick: '',
    sort: [],
    uid: utils.isLogged() ? utils.getUserInfo().id : undefined,
  },
  queryParamsGroupPicks: { page: 1, limit: 100 },
  tiebreakers: [],
  tickets: [],
  userData: {},
  userBalance: {},
  withRegister: false,
  currentContest: {},
  showModalPicksUnsave: false,
  picksUnsave: false,
  confirmedNavigation: false,
};

/**
 * The reducer handles the state of the QuinielaGroups
 * @param { Object } state
 * @param { Object } action
 * @return newState
 */
const reducer = (state, action) => {
  //console.log( "reducing .",state);
  let currentEntry = null;
  switch (action.type) {
    case 'isSubmittingGroupPicks':
      console.log('Is Submitting');
      return { ...state, submittingGroupPicks: !state.submittingGroupPicks };
    case 'isReady':
      // console.log('Is Ready');
      return { ...state, mounted: true };
    case 'resetData':
      return initialState;
    case 'changeSubmit':
      return { ...state, submitting: !state.submitting };
    case 'changeSearchNick':
      let temporalQueries = state.queryParams;
      temporalQueries.nick = action.payload.value;
      return { ...state, queryParams: temporalQueries, searchableNick: action.payload.value };
    case 'changeSection':
      return { ...state, selection: action.payload.section };
    case 'handleError':
      console.log('handle Error', action);
      return { ...state, showError: false, error: { error: false, message: null } };
    case 'handleMessage':
      console.log('handle Message', action.payload);
      let message = action.payload.message;
      return { ...state, newMessage: message };
    case 'handlePopUpReglas':
      // console.log('handle PopUpReglas' );
      return { ...state, showPopUpReglas: !state.showPopUpReglas };
    case 'handleOverlay':
      // console.log('handleOverlay', action );
      return {
        ...state,
        showOverlay: !state.showOverlay,
        isCopy: action.payload.isCopy,
        saveError: action.payload.isError,
        saveSuccess: action.payload.isSuccess,
      };
    case 'handleOpenAbonar':
      console.log('Handle Abonar...');
      console.log('current value ', state.showAbonar);
      return { ...state, showAbonar: !state.showAbonar };
    case 'handleRegistration':
      console.log('Handle Registration...');
      console.log('current value ', state.showRegistration);
      return { ...state, showRegistration: !state.showRegistration };
    case 'handleReRegistration':
      console.log('Handle ReRegistration...');
      console.log('current value ', state.showRegistration);
      return { ...state, showReRegistration: !state.showReRegistration };
    case 'updateStartedPool':
      // console.log('Update Started Pool')
      let temporalStartedPool = state.poolInfo;

      temporalStartedPool.status = 'live';

      return { ...state, poolInfo: temporalStartedPool };
    case 'updateBranchLink':
      console.log('New Url!', action.payload);
      return { ...state, branchLink: action.payload.url };
    case 'updateInviteStatus':
      return { ...state, inviteStatus: action.payload.status };
    case 'closeAddConfirmation':
      console.log('close add confirmation');
      return { ...state, showAddConfirmation: false, userToAdd: null };
    case 'openAddConfirmation':
      console.log('open add confirmation');
      return { ...state, showAddConfirmation: true, userToAdd: action.payload.userToAdd };
    case 'handlePick':
      if (!state.myEntry.hasOwnProperty('group_pool_picks')) {
        return { ...state };
      }

      //Check if entry id is null to check unsave picks
      if (state.myEntry.group_pool_entry_id !== null) {
        state.picksUnsave = true;
      }

      console.log('Handle Pick...', action.payload, state.myEntry);
      const selectedValue = action.payload.value.hasOwnProperty('value')
        ? action.payload.value.value
        : action.payload.value;
      currentEntry = state.myEntry;
      currentEntry.group_pool_picks[action.payload.currentContestId][action.payload.bucketId].pick = Number(selectedValue);
      currentEntry.group_pool_picks[action.payload.currentContestId][action.payload.bucketId].bucket_number = Number(
        action.payload.bucketId
      );
      return { ...state, myEntry: currentEntry };
    case 'handlePickF1':
      if (!state.myEntry.hasOwnProperty('group_pool_picks')) {
        return { ...state };
      }

      //Check if entry id is null to check unsave picks
      if (state.myEntry.group_pool_entry_id !== null) {
        state.picksUnsave = true;
      }

      const selectedValueF1 = action.payload.value.hasOwnProperty('value')
        ? action.payload.value.value
        : action.payload.value;
      currentEntry = state.myEntry;
      currentEntry.group_pool_picks[action.payload.currentContestId][action.payload.bucketId].pick = Number(selectedValueF1);
      currentEntry.group_pool_picks[action.payload.currentContestId][action.payload.bucketId].bucket_number = Number(
        action.payload.bucketId
      );

      // set player to bucket selected
      _.forEach(state.players, (player, id) => {
        if (Number(id) === Number(selectedValueF1)) {
          player.bucket = action.payload.bucketId;
        } else if (player.bucket === action.payload.bucketId) {
          player.bucket = null;
        }
      });

      //quit buckets with duplicates
      setTimeout(() => {
        let tmp = state.poolBuckets[state.currentContest.id][action.payload.bucketId-1].players.filter(p => p.bucket === action.payload.bucketId);
        tmp.map((item) => {
          if (item.id !== selectedValueF1) {
            item.bucket = null;
          }
        })
      }, 1000);
      //console.log("PLAERSELECTED2:", state.players);

      return { ...state, myEntry: currentEntry };
    case 'showMailPanel':
      console.log('ShowMail');
      return { ...state, showMailPanel: !state.showMailPanel };
    case 'handleBucket':
      console.log('Handle Bucket...', action.payload);
      let temporalBuckets = state.poolBuckets[state.currentContest.id];
      let bucket = temporalBuckets.find((item) => item.id === action.payload.bucketId+1);
      if (bucket) {
        bucket.isOpen = !bucket.isOpen;
      }

      return { ...state };
    case 'handleBucketF1':
      console.log('Handle Bucket f1...', action.payload);
      let temporalBucketsF1 = state.poolBuckets[state.currentContest.id];
      let bucketF1 = temporalBucketsF1.find((item) => item.id === action.payload.bucketId+1);
      if (bucketF1) {
        bucketF1.isOpen = !bucketF1.isOpen;
      }

      return { ...state };
      //return { ...state, poolBuckets: temporalBuckets };
    case 'initializeBuckets':
      console.log( 'initializeBuckets' , action);
      //return { ...state, poolBuckets: action.payload.newBuckets };
      return { ...state };
    case 'handleTie':
      if (!state.myEntry.hasOwnProperty('group_pool_user_tiebreakers')) {
        return { ...state };
      }

      //Check if entry id is null to check unsave picks
      if (state.myEntry.group_pool_entry_id !== null) {
        state.picksUnsave = true;
      }

      console.log('Handle Tie...', action);
      currentEntry = state.myEntry;
      currentEntry.group_pool_user_tiebreakers[action.payload.currentContestId][action.payload.tieId].value = Number(
        action.payload.value
      );
      console.log(currentEntry);
      return { ...state, myEntry: currentEntry };
    case 'handleCancellation':
      console.log('Handle Cancellation...');

      return { ...state, showCancellation: !state.showCancellation };
    case 'handleInvitePanel':
      console.log('Handle Invite Panel...');
      return { ...state, showInvite: !state.showInvite };
    // case 'isReady':
    //   return { ...state, submitting: action.payload };
    // console.log('isReady',action.payload);
    case 'isError':
      console.error('isError', action.payload);
      //window.location = '/';
      return {
        ...state,
        submitting: false,
        error: { error: action.payload.error, message: action.payload.error.message },
        // mounted        : true,
        showError: true,
      };
    case 'handleshowSuccessfull':
      // console.log('isSuccessfull', );
      return {
        ...state,
        submitting: false,
        showSuccessfull: !state.showSuccessfull,
        error: { isError: false, message: null },
        mounted: true,
        showError: false,
      };
    case 'copyEntry':
      console.log('Copiar Enttry', action);

      return {
        ...state,
        submitting: false,
      };
    case 'updateBalance':
      let tempUserBalance = state.userBalance;
      console.log('updateBalance', state.userBalance);
      tempUserBalance.balanceReal = action.payload.newBalance;

      let balanceEnough = utils.isBalanceEnough(
        state.poolInfo.entry_currency,
        state.poolInfo.entry_fee
      );
      // tempUserBalance.balanceReal >= state.poolInfo.entry_fee;

      console.log('is', balanceEnough);

      console.log(tempUserBalance);
      utils.setUserBalance(tempUserBalance);
      return {
        ...state,
        userBalance: tempUserBalance,
        enoughBalance: !balanceEnough && state.tickets && state.tickets.length < 1 ? false : true,
      };
    case 'updateEnoughBalanceOnly':
      console.log("check enouchBalance....");
      let balanceEnoughOnly = utils.isBalanceEnough(
        state.poolInfo.entry_currency,
        state.poolInfo.entry_fee
      );
      return {
        ...state,
        enoughBalance: !balanceEnoughOnly && state.tickets && state.tickets.length < 1 ? false : true,
      }
    case 'updatePoolClose':
      // console.log('updatePoolClose', state.poolInfo.id, action.payload.poolCloseData);

      if (
        !state.poolInfo ||
        state.poolInfo.id !== action.payload.poolCloseData.groupPoolId ||
        !state.standings ||
        state.standings.items.length < 1
      ) {
        return { ...state };
      }
      // console.log( 'Continue...' );

      let tempPoolInfo = state.poolInfo;

      let updateClosedStandings = action.payload.poolCloseData.standings;

      let tempClosedStandings = state.standings;

      tempPoolInfo.status = 'closed';
      tempPoolInfo.closed = true;

      let tempMyEntry = state.myEntry;

      updateClosedStandings.forEach((uStand, uStandIdx) => {
        if (state.withRegister && uStand.group_pool_entry_id === tempMyEntry.group_pool_entry_id) {
          // console.log('--------Edit My Entry with new data--------');
          // console.log( tempMyEntry, uStand );
          tempMyEntry.group_pool_standing['rank'] = uStand.rank;
          tempMyEntry.group_pool_standing['points'] = uStand.points;
          tempMyEntry.group_pool_standing['possible_points'] = uStand.possible_points;
          tempMyEntry.group_pool_standing['group_pool_user_prize'] = uStand.prize_amount;
          tempMyEntry['group_pool_user_prize'] = uStand.prize_amount;
        }

        tempClosedStandings.items.forEach((pStand, pStandIdx) => {
          if (pStand.group_pool_entry_id !== uStand.group_pool_entry_id) {
            return;
          }
          pStand['rank'] = uStand.rank;
          pStand['points'] = uStand.points;
          pStand['possible_points'] = uStand.possible_points;
          pStand['user_prize'].amount = uStand.prize_amount;
          pStand['user_prize'].base_amount = uStand.base_amount;
          pStand['user_prize'].tax = uStand.prize_tax;
          pStand['prize_amount'] = uStand.prize_amount;
        });
      });

      tempClosedStandings.items.sort(firstBy('rank').thenBy('score_num'));

      return {
        ...state,
        poolInfo: tempPoolInfo,
        standings: tempClosedStandings,
      };
    case 'updatePoolDetails':
      console.log('Set  pool details', action.payload);
      return {
        ...state,
        submitting: false,
        poolInfo: action.payload.poolInfo.group_pool,
        players: action.payload.players,
        poolBuckets: action.payload.buckets,
        bucketKeys: action.payload.bucketKeys,
        tiebreakers: action.payload.poolInfo.group_pool.group_pool_tiebreakers,
        tiesDistribution: action.payload.poolInfo.ties_distribution,
        prizes: action.payload.poolInfo.prizes,
        contestPrizes: action.payload.poolInfo.contest_prizes,
        contestStandings: action.payload.contestStandings,
        contestTiesDistribution: action.payload.contestStandings.ties_distribution,
        currentContest: action.payload.poolInfo.current_contest,
      }
    case 'addNewEntriesToStandings':
      console.log('Add New Entries to Standings');
      console.log(action);
      let userData = utils.getUserInfo();

      let mainEntry, otherEntries;

      let newStandingsFromEntries = [];

      action.payload.newEntries.forEach((newEntry, neIdx) => {
        let standingModel = {
          friend: 0,
          group_pool_entry: { number: newEntry.number },
          group_pool_entry_id: newEntry.group_pool_standing.group_pool_entry_id,
          group_pool_id: newEntry.group_pool_id,
          id: newEntry.group_pool_standing.id,
          points: 0,
          possible_points: 0,
          possible_rank: 0,
          rank: 0,
          user: {
            avatar: null,
            avatar_dir: null,
            full_name: null,
            id: userData.id,
            is_new: userData.is_new,
            nick: userData.nick,
          },
          user_id: userData.id,
          user_prize: {
            amount: null,
            currency: null,
            description: null,
          },
        };

        if (neIdx === 0) {
          mainEntry = standingModel;
        } else {
          if (otherEntries !== undefined) {
            otherEntries = [...otherEntries, standingModel];
          }
        }

        newStandingsFromEntries = [...newStandingsFromEntries, standingModel];
      });

      //let myEntries = { group_pool_entry: { mainEntry, other_entries: otherEntries } };

      let tempStand = state.standings;

      console.log('tempStand');
      console.log(tempStand);
      tempStand.items = [...tempStand.items, ...newStandingsFromEntries];
      console.log('tempStand with newStandigns');

      console.log(tempStand);

      // console.log( 'newStandingsFromEntries' );
      // console.log( newStandingsFromEntries );

      return { ...state, standings: tempStand, withRegister: true };
    case 'updateEnoughBalance':
      // console.log('Balance enough?', action.payload)
      return {
        ...state,
        enoughBalance: action.payload.balanceEnough,
      };
    case 'closeAllBuckets':
      let closedBuckets;
      if (state.poolBuckets[state.currentContest.id] !== undefined) {
        closedBuckets = state.poolBuckets[state.currentContest.id];
      } else {
        closedBuckets = state.poolBuckets;
      }

      closedBuckets.forEach((b) => {
        b.isOpen = false;
      });
      return { ...state };
    case 'openAllBuckets':
      let openBuckets;
      if (state.poolBuckets[state.currentContest.id] !== undefined) {
        openBuckets = state.poolBuckets[state.currentContest.id];
      } else {
        openBuckets = state.poolBuckets;
      }

      openBuckets.forEach((b) => {
        b.isOpen = true;
      });
      return { ...state };
    case 'updateEntry':
      // console.log( 'updateEntry',  action.payload  );
      return {
        ...state,
        submitting: false,
        myEntry: action.payload.entryUpdated,
        witRegister: action.payload.withRegister,
        selectedEntry: action.payload.entryUpdated.group_pool_entry_id,
      };
    case 'updateInitialEntry':
      //console.log('updateInitialEntry', action.payload);
      return {
        ...state,
        submitting: false,
        allEntries: action.payload.myEntriesFromServer,
        withRegister: true,
        selectedEntry: action.payload.myEntriesFromServer.group_pool_entry.id,
      };
    case 'initializeUserInfo':
      // console.log('Set UserInfo',  action.payload  );
      utils.setUserInfo(action.payload.userInfo);
      utils.setUserBalance(action.payload.userInfo);
      let balance = {
        balancePc: action.payload.userInfo.balancePc,
        balanceReal: action.payload.userInfo.balanceReal,
        balancePcReal: action.payload.userInfo.balancePcReal,
        balanceReferral: action.payload.userInfo.balanceReferral,
      };
      return {
        ...state,
        userInfo: action.payload.userInfo.user,
        userBalance: balance,
      };

    case 'updatePoolBuckets':
      console.log('updatePoolBuckets');
      return {
        ...state,
        submitting: false,
        poolBuckets: action.payload.poolBuckets,
      };
    case 'redirect':
      console.log('redirect', action.payload);
      return {
        ...state,
        redirect: true,
        redirectTo: action.payload.to,
      };
    case 'setTickets':
      // console.log('SetTickets',action.payload.tickets );
      return {
        ...state,
        tickets: action.payload.tickets,
      };
    case 'nextGroupPickPage':
      let temporalQueryParamsGroupPicks = state.queryParamsGroupPicks;
      if (temporalQueryParamsGroupPicks.page >= temporalQueryParamsGroupPicks.pages) {
        return { ...state };
      } else {
        temporalQueryParamsGroupPicks.page = temporalQueryParamsGroupPicks.page + 1;
        console.log('updated Query params', temporalQueryParamsGroupPicks);
        return { ...state, queryParamsGroupPicks: temporalQueryParamsGroupPicks };
      }

    case 'setGroupPicks':
      // console.log('SetGroupPicks',action.payload.groupPicks );
      return {
        ...state,
        groupPicks: action.payload.groupPicks,
      };
    case 'setMoreGroupPicks':
      console.log('SetGroupPicks', action.payload.groupPicks);

      let temporalMoreGroupPicks = state.groupPicks;
      console.log('prev', temporalMoreGroupPicks.items.length);

      temporalMoreGroupPicks.items = [
        ...temporalMoreGroupPicks.items,
        ...action.payload.groupPicks.items,
      ];

      console.log('updated', temporalMoreGroupPicks.items.length);
      return {
        ...state,
        groupPicks: temporalMoreGroupPicks,
      };
    case 'setSimilarPools':
      // console.log('SetTickets',action.payload.tickets );
      return {
        ...state,
        similarPools: action.payload.similarPools,
      };
    case 'setStandings':
      // console.log('SetStandings', action );
      // if( state.poolInfo && state.poolInfo.status !== 'upcoming'  ){
      //   console.log('Update a ganar from start', state);
      // }

      return {
        ...state,
        standings: action.payload.standings,
      };
    case 'setMoreStandings':
      console.log('SetMoreStandings', action);
      // if( state.poolInfo && state.poolInfo.status !== 'upcoming'  ){
      //   console.log('Update a ganar from start', state);
      // }

      let temporalMoreStandings = state.standings;
      console.log('prev', temporalMoreStandings.items.length);

      temporalMoreStandings.items = [
        ...temporalMoreStandings.items,
        ...action.payload.standings.items,
      ];

      console.log('updated', temporalMoreStandings.items.length);
      return {
        ...state,
        standings: temporalMoreStandings,
      };
    case 'nextStandingPage':
      let temporalQueryParams = state.queryParams;
      if (temporalQueryParams.page >= temporalQueryParams.pages) {
        return { ...state };
      } else {
        temporalQueryParams.page = temporalQueryParams.page + 1;
        // console.log(temporalQueryParams);
        return { ...state, queryParams: temporalQueryParams };
      }

    case 'updatePlayers':
      // console.log('updatePlayers');
      // console.log(action);
      if (!state.players) {
        return { ...state };
      }
      var player_results = action.payload.newPlayers.player_results;

      let temporalPlayers = state.players;

      _.each(temporalPlayers, (player, idx) => {
        var res = _.find(player_results, { player_id: player.id });
        if (!res) {
          return;
        }
        player.position = res.position;
        player.score = res.score;
        player.score_num = res.score_num;
        player.data = res.data;
        player.points = 0;

        var pts = _.find(state.poolInfo.points_distributions, { position: res.position });
        if (pts) {
          player.points = pts.points;
        }
      });

      return {
        ...state,
        players: temporalPlayers,
      };
    case 'updatePlayersF1':
      return {
        ...state,
        poolBuckets: action.payload.buckets,
        players: action.payload.players,
      }
    case 'updatePlayersF1Socket':
      if (state.currentContest.id !== action.payload.raceResults.contest_id) {
        console.log("contestid diferent");
        return { ...state };
      }

      var raceResults = action.payload.raceResults.race_results;
      raceResults.map((player) => {
        if (state.players && state.players[player.player_id]) {
          state.players[player.player_id].finish_pos = player.finish_pos;
          state.players[player.player_id].laps = player.laps;
          state.players[player.player_id].pit = player.pit;
          state.players[player.player_id].time = player.time;
        }
      });

      return {
        ...state,
      }
    case 'updateStandingsAganar':
      //validations
      if (!state.poolInfo || state.poolInfo.id !== action.payload.newStandings.group_pool_id) {
        return { ...state };
      }
      //init vars
      var tiesDistribution = action.payload.newStandings.ties_distribution;
      var prizesDistribution = state.prizes;
      var available = state.poolInfo.available;
      var type = state.poolInfo.type;
      var standings = action.payload.newStandings.standings;
      var myEntry = state.myEntry;
      // si es de temporada completa y tiene premios semanales se van a dividir los premios
      // 1 % del  premio para el final de la temporada y otro % para entregar todas las semanas
      if ( type == 'full' ) {
        // esto es lo que queda para el final de la temporada
        available = state.poolInfo.available_end;
      }
      // mapear el monto de acuerdo al porcentaje del premio
      prizesDistribution.map( function(prize) {
        prize.percentage = prize.percentage;
        prize.amount = available * (prize.percentage/10000);
      });
      //get max proze position
      var maxPrizedPosition = _.max(prizesDistribution, function(prize) {
        return prize.position;
      });
      // initialize all ties with 0
      _.map(tiesDistribution, function(ties) {
        ties.percentage  = 0;
        ties.amount      = 0;
        ties.type        = null;
        ties.description = null;
      });

      //calculate percentage and prize amount
      tiesDistribution.map((ties) => {
        if (ties.rank > maxPrizedPosition.position) {
          return;
        } else {
          var rank = ties.rank; // cual es la posicion
          var numTies = ties.ties; // cuantos lugares empatados hay en esa posición
          var acc = 0; // cuanto le va a tocar a cada uno
          var prize; // el premio

          // iterate through all of the tied positions
          for ( var i = rank ; i < rank + numTies; i++ ) {
            prize = prizesDistribution[i-1];
            if ( !prize ) { continue; }
            if ( prize.type !== 'ticket' ) {
              // acumular el premio
              acc += prize.percentage;
            } else {
              acc = 10000;
            }
          }
          prize = _.find(prizesDistribution, {'position': rank});
          if ( prize && prize.type == 'ticket' ) {
            ties.type = 'ticket';
            ties.description = prize.description;
          }
          // porcentaje acumulado de las posiciones empatadas
          ties.percentage = acc;
          ties.amount = ( available * (acc/10000) ) / numTies ;
        }
      });

      //set each standing wint your prize rank
      standings.map((st) => {
        st.user_prize.amount = 0;
        st.user_prize.percentage = 0;
        let tmpSt = tiesDistribution.find((tie) => tie.rank === st.rank);
        if (tmpSt && tmpSt.amount !== 0) {
          st.user_prize.amount = tmpSt.amount;
          st.user_prize.percentage = tmpSt.percentage;
        }
      })

      //set prize to user standing
      if (myEntry.group_pool_standing && myEntry.group_pool_standing.rank) {
        myEntry.group_pool_user_prize = 0;
        let tmpSt = tiesDistribution.find((tie) => tie.rank === myEntry.group_pool_standing.rank);
        if (tmpSt && tmpSt.amount !== 0) {
          myEntry.group_pool_user_prize = tmpSt.amount;
        }
      }

      // console.log("MAX:", maxPrizedPosition);
      // console.log("TIESDIST:", tiesDistribution);
      // console.log("STANDINGS:", standings);
      var tmpStandingsUpdate = {
        items: standings
      }
      return {
        ...state,
        standings: tmpStandingsUpdate,
      };
    case 'updateStandingsAganarContest':
      //validations
      if (!state.poolInfo || state.poolInfo.id !== action.payload.newStandings.group_pool_id) {
        console.log("poolid diferent");
        return { ...state };
      }

      if (state.currentContest.id !== action.payload.newStandings.contest_id) {
        console.log("contestid diferent");
        return { ...state };
      }

      var tmpContest = state.poolInfo.contests.find((contest) => contest.id === state.currentContest.id);
      if (tmpContest && tmpContest.status !== "live") {
        console.log("contest is not live");
        return { ...state };
      }

      //init vars
      var tiesDistribution = action.payload.newStandings.ties_distribution;
      var prizesDistribution = state.contestPrizes;
      var available = state.poolInfo.available;
      var type = state.poolInfo.type;
      var standings = action.payload.newStandings.standings;
      var myEntry = state.myEntry;
      // si es de temporada completa y tiene premios semanales se van a dividir los premios
      // 1 % del  premio para el final de la temporada y otro % para entregar todas las semanas
      if ( type === 'full' ) {
        available = state.poolInfo.available_per_contest;
      }
      // mapear el monto de acuerdo al porcentaje del premio
      prizesDistribution.map( function(prize) {
        prize.percentage = prize.percentage;
        prize.amount = available * (prize.percentage/10000);
      });
      //get max proze position
      var maxPrizedPosition = _.max(prizesDistribution, function(prize) {
        return prize.position;
      });
      // initialize all ties with 0
      _.map(tiesDistribution, function(ties) {
        ties.percentage  = 0;
        ties.amount      = 0;
        ties.type        = null;
        ties.description = null;
      });

      //calculate percentage and prize amount
      tiesDistribution.map((ties) => {
        if (ties.rank > maxPrizedPosition.position) {
          return;
        } else {
          var rank = ties.rank; // cual es la posicion
          var numTies = ties.ties; // cuantos lugares empatados hay en esa posición
          var acc = 0; // cuanto le va a tocar a cada uno
          var prize; // el premio

          // iterate through all of the tied positions
          for ( var i = rank ; i < rank + numTies; i++ ) {
            prize = prizesDistribution[i-1];
            if ( !prize ) { continue; }
            if ( prize.type !== 'ticket' ) {
              // acumular el premio
              acc += prize.percentage;
            } else {
              acc = 10000;
            }
          }
          prize = _.find(prizesDistribution, {'position': rank});
          if ( prize && prize.type == 'ticket' ) {
            ties.type = 'ticket';
            ties.description = prize.description;
          }
          // porcentaje acumulado de las posiciones empatadas
          ties.percentage = acc;
          ties.amount = ( available * (acc/10000) ) / numTies ;
        }
      });

      //set each standing wint your prize rank
      if (standings.items) {
        standings.items.map((st) => {
          st.contest_prize.amount = 0;
          st.contest_prize.percentage = 0;
          let tmpSt = tiesDistribution.find((tie) => tie.rank === st.rank);
          if (tmpSt && tmpSt.amount !== 0) {
            st.contest_prize.amount = tmpSt.amount;
            st.contest_prize.percentage = tmpSt.percentage;
          }
        })
      } else {
        standings.map((st) => {
          st.contest_prize.amount = 0;
          st.contest_prize.percentage = 0;
          let tmpSt = tiesDistribution.find((tie) => tie.rank === st.rank);
          if (tmpSt && tmpSt.amount !== 0) {
            st.contest_prize.amount = tmpSt.amount;
            st.contest_prize.percentage = tmpSt.percentage;
          }
        })
      }

      //set prize to user standing
      myEntry.group_pool_user_prize_contest = 0;
      let tmpStContest = tiesDistribution.find((tie) => tie.rank === myEntry.group_pool_standing.rank);
      if (tmpStContest && tmpStContest.amount !== 0) {
        myEntry.group_pool_user_prize_contest = tmpStContest.amount;
      }

      // console.log("MAX:", maxPrizedPosition);
      // console.log("TIESDIST:", tiesDistribution);
      // console.log("STANDINGS:", standings);
      var tmpStandings = {
        items: standings
      }
      return {
        ...state,
      };
    case 'updateStandingsSocket':
      if( !action.payload.standingsUpdate.standings || !state.standings || state.standings.items < 1 ){ return { ...state }}

      action.payload.standingsUpdate.standings.map((item) => {
        item.friend=0;
      });

      state.standings.items = action.payload.standingsUpdate.standings;

      //update myEntry
      var tmpEntry = state.standings.items.find((item) => item.group_pool_entry_id === state.myEntry.group_pool_entry_id );
      if (tmpEntry) {
        state.myEntry.group_pool_standing.rank = tmpEntry.rank
        state.myEntry.group_pool_standing.points = tmpEntry.points
        state.myEntry.group_pool_standing.possible_points = tmpEntry.possible_points
      }

      return {
        ...state ,
        //standings: temporalStandingsState
      };
    case 'mapSTandignsDataToPickGroup':
      if ( !action.payload.standingsUpdate.standings || !state.groupPicks || state.groupPicks.items < 1 ) {
        return { ...state }
      }

      if (!state.poolInfo || state.poolInfo.id !== action.payload.standingsUpdate.group_pool_id) {
        console.log("poolid diferent");
        return { ...state };
      }

      let updatedStandings        = action.payload.standingsUpdate.standings;
      let temporalGroupPicksState = state.groupPicks;

      temporalGroupPicksState.items.forEach( standing => {
        var updated = updatedStandings.find((st) => st.group_pool_entry_id === standing.group_pool_entry_id );
        if ( updated ) {
          standing.rank            = updated.rank;
          standing.points          = updated.points;
          standing.possible_points = updated.possible_points;
        }
      });

      temporalGroupPicksState.items.sort(firstBy('rank').thenBy('group_pool_entry_id'));

      return {
        ...state ,
        groupPicks: temporalGroupPicksState
      }
    case 'updateQueryParams':
      return { ...state, queryParams: action.payload.queryParams };
    case 'updateStandingsSocketAganar':
      console.log('updateStandings aganar');
    case 'updateCurrentContest':
      return {...state, currentContest: action.payload.currentContest}
    case 'updateContestStandings':
      state.contestStandings = action.payload.contestStandings
      return { ...state }
    case 'handleShowModalPicksUnsave':
      console.log("ENTRAAAAMODALLL");
      state.showModalPicksUnsave = !state.showModalPicksUnsave;
      return { ...state }
    case 'handlerStatusPicksUnsave':
      state.showModalPicksUnsave = false;
      state.confirmedNavigation = true;
      state.picksUnsave = false;
      return { ...state }
    case 'updateContestStandingsSoket':
      if (!state.poolInfo || state.poolInfo.id !== action.payload.contestStandings.group_pool_id) {
        console.log("poolid diferent");
        return { ...state };
      }

      if (state.currentContest.id !== action.payload.contestStandings.contest_id) {
        console.log("contestid diferent");
        return { ...state };
      }

      state.contestStandings.items = action.payload.contestStandings.standings

      //update points and rank in state.groupPicks
      let updatedStandingsContest = action.payload.contestStandings.standings;
      let temporalGroupPicksStateContest = state.groupPicks;

      temporalGroupPicksStateContest.items.forEach( standing => {
        var updated = updatedStandingsContest.find((st) => st.group_pool_entry_id === standing.group_pool_entry_id );
        if ( updated ) {
          standing.cotest_rank = updated.rank;
          standing.contest_points = updated.points;
          standing.contest_possible_points = updated.possible_points;
        }
      });
      temporalGroupPicksStateContest.items.sort(firstBy('rank').thenBy('group_pool_entry_id'));

      //update contest in myEntry
      let myContest = updatedStandingsContest.find(st => st.group_pool_entry_id === state.myEntry.contest_standings[state.currentContest.id].group_pool_entry_id);
      state.myEntry.contest_standings[state.currentContest.id].rank = myContest.rank;
      state.myEntry.contest_standings[state.currentContest.id].points = myContest.points;
      state.myEntry.contest_standings[state.currentContest.id].possible_points = myContest.possible_points;

      return { ...state , groupPicks: temporalGroupPicksStateContest}
    default:
      console.error('Unknown reducer', action.type);
      return { ...state };
  }
};

/**
 * The provider gives the let see and handles the state and dispatch
 * It also returns the childrens nested in the Provider
 * @param {Object} props
 */
const QuinielaGroupsContextProvider = (props) => {
  // let parentProps= { ...props };

  // console.log( "props", props );

  initialState = { ...initialState };

  let [state, dispatch] = React.useReducer(reducer, initialState);

  let value = { state, dispatch };

  if (typeof props.children === 'function') {
    // console.log('Children is a function' );
    return (
      <QuinielaGroupsContext.Provider value={value}>
        {props.children(props)}
      </QuinielaGroupsContext.Provider>
    );
  }
  return (
    <QuinielaGroupsContext.Provider value={value}>{props.children}</QuinielaGroupsContext.Provider>
  );
};

const QuinielaGroupsContextConsumer = QuinielaGroupsContext.Consumer;

export { QuinielaGroupsContext, QuinielaGroupsContextProvider, QuinielaGroupsContextConsumer };
