import React from 'react'
import styled from 'styled-components'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Components
import Iconify from '../Iconify'


export default function NotificationBar () {
  const { isSessionExpired, closeSessionExpired } = useAuthContext()

  const handleClose = (event) => {
    event.stopPropagation()
    closeSessionExpired()
  }

  return (
    <Notification style={{ top: isSessionExpired ? 0 : -200 }}>
      <span>Tú sesión ha expirado, puedes ingresar nuevemente</span>
      <button onMouseDown={handleClose}>
        <Iconify icon='mingcute:close-fill' />
      </button>
    </Notification>
  )
}

const Notification = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 4em;
  background-color: #78bc28;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 550ms ease-out;
  will-change: top;
  font-size: 1.3em;
  gap: 2em;

  & button {
    background-color: transparent;
    outline: 0;
    border: 0;
    padding: 0;
    height: 1em;
  }
`