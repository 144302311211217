import React from 'react';
import { Button } from 'react-bootstrap/lib/';

class HeadersPool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOrderState: this.props.sortOrderState,
    };
    this.checkActiveSort = this.checkActiveSort.bind(this);
    console.log();
  }

  restartSortState() {
    let sortOrder_ = this.state.sortOrderState.map((sortButton) => (sortButton.order = false));
    this.setState({
      sortOrderState: sortOrder_,
    });
  }

  checkActiveSort(cual) {
    // console.log('CheckActiveSort',cualEs)
    let sortType;
    //let sortOrder_ = this.state.sortOrderState.map((sortButton) => (sortButton = false));
    // console.log(sortOrder_);
    !this.state.sortOrderState[cual].order ? (sortType = 'caretF') : (sortType = 'caretFR');
    return sortType;
  }
  // const pool = props.pool;
  // console.log('HP',props);
  render() {
    return (
      <ul className="pool-row-header">
        <li
          className="pool-category deporte"
          onClick={() => {
            this.props.sortOrderFunc('deporte');
          }}
        >
          <span>
            DEP.<span className={this.checkActiveSort(0)}></span>
          </span>
        </li>
        <li
          className="pool-category quinielas"
          onClick={() => {
            this.props.sortOrderFunc('quinielas');
          }}
        >
          <span>
            QUINIELAS<span className={this.checkActiveSort(1)}></span>
          </span>
        </li>
        <li
          className="pool-category tipo"
          onClick={() => {
            this.props.sortOrderFunc('tipo');
          }}
        >
          <span>
            TIPO<span className={this.checkActiveSort(2)}></span>
          </span>
        </li>
        <li
          className="pool-category entradas"
          onClick={() => {
            this.props.sortOrderFunc('entradas');
          }}
        >
          <span>
            ENTRADA<span className={this.checkActiveSort(3)}></span>
          </span>
        </li>
        <li
          className="pool-category bolsa"
          onClick={() => {
            this.props.sortOrderFunc('bolsa');
          }}
        >
          <span>
            BOLSA<span className={this.checkActiveSort(4)}></span>
          </span>
        </li>
        <li
          className="pool-category registro"
          onClick={() => {
            this.props.sortOrderFunc('registro');
          }}
        >
          <span>
            REG.<span className={this.checkActiveSort(5)}></span>
          </span>
        </li>
        <li
          className="pool-category cierre"
          onClick={() => {
            this.props.sortOrderFunc('cierre');
          }}
        >
          <span>
            CIERRE<span className={this.checkActiveSort(6)}></span>
          </span>
        </li>
        <li
          className="pool-category estatus"
          onClick={() => {
            this.props.sortOrderFunc('estatus');
          }}
        >
          <span>
            ESTATUS<span className={this.checkActiveSort(7)}></span>
          </span>
        </li>
        <li className="pool-category registrate hidden-sm">
          <span>
            <Button
              className="btn-registrate"
              bsStyle="success"
              disabled={this.props.multyEntry.length > 0 ? false : true}
              onClick={this.props.registerMultyEntry}
            >
              REGÍSTRATE
            </Button>
          </span>
        </li>
      </ul>
    );
  }
}

export default HeadersPool;
