// Libraries
import React from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
import { Button, Modal } from 'react-bootstrap/lib';
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';
import { firstBy } from 'thenby';
import moment from "moment";

// Components
import BracketTennisRegisterSteps from './BracketTennisRegisterSteps';
import BracketTennisPick from './BracketTennisPick';
import utils from '../../utils/utils';
import API from '../../utils/api';
import RegisterPanel from '../RegisterPanel';

// Styles
import '../Bracket/bracket.scss';
import './bracketTennis.scss';

// Images
import guardar from '../../assets/images/guardarPicks-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';

const BracketTennis = props => {
  const [columnSelected, setColumnSelected] = React.useState(null);
  const [selectedStage, setSelectedStage] = React.useState(props.bracketCurrentStage ? props.bracketCurrentStage.id : props.bracketStages[0].id);
  const [stagesCount, setStagesCount] = React.useState(0);
  const [teamList, setTeamList] = React.useState([]);
  const [state, setState] = React.useState({
    redirectLobby: false,
    bracketInfo: props.bracketInfo,
    userData: props.userData,
    enoughBalance: props.enoughBalance,
    standings: props.standings,
    isLogged: props.isLogged,
    groups: null,
    stages: props.bracketStages,
    matches: props.bracketMatches,
    isLoaded: false,
    bracketSides: null,
    bracketSidesFiltered: null,
    errorCopiar: null,
    picks: {},
    picksProps: {},
    popUpNewUsersStatus: false,
    showSavedConfirmation: false,
    submitting: false,
    tieBreakers: props.bracketInfo.bracket_tiebreakers,
    bracketUserTieBreakers: null,
    blocks: [],
    picksBlocks: [],
    maxStage: null,
    showPopUpReglas: false,
  });

  const handleStageChange = (stage) => {
    setSelectedStage(stage);
    if (stage === props.bracketStages[0].id) {
      setColumnSelected(null);
    } else {
      setColumnSelected(stage);
    }
    // let element = document.getElementById(`stage-${stage}-0`);
    // element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
  };

  const SavedPicksOverlay = ({ showOverlay, label, currentSavedPicks, allPicks }) => {
    let picksStatus = null;
    let iconStatus = null;

    if (currentSavedPicks === 0) {
      picksStatus = `No tienes ningún picks guardado. Recuerda ingresar y guardar todos tus picks. `;
    } else {
      picksStatus = (
        <div className="flex-container column align justify">
          <div className="flex-container justify">
            {' '}
            <strong className="title">¡Picks Guardados!</strong>
          </div>
          <div className="flex-container justify">
            {' '}
            Tienes {currentSavedPicks}/{allPicks} picks guardados.{' '}
          </div>
          <div className="flex-container justify">Recuerda ingresar y guardar todos tus picks. </div>
        </div>
      );
    }

    /**
     * Incomplete Picks
     **/
    if (currentSavedPicks !== allPicks) {
      iconStatus = <i className="ion-alert-circled" />;
    } else {
      /**
       * Complete Picks
       **/
      picksStatus = `¡Felicidades tus picks están completos!`;
      iconStatus = <i className="ion-checkmark-circled" />;
    }
    return (
      <div className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}`}>
        <span>
          {' '}
          {iconStatus} {picksStatus}{' '}
        </span>
      </div>
    );
  };

  const createGroups = (matches, stages, filtered = false) => {
    var bracketSides = _.groupBy(matches, 'side');
    var bracketList = [];
    var count = 0;

    _.each(bracketSides, (items, side) => {
      var byStage = _.groupBy(items, 'stage_id');
      var stageList = { side, sideItems: [] };
      _.each(byStage, (item, stage) => {
        let stageItem = _.find(stages, st => Number(st.id) === Number(stage));
        item.stage = stageItem;
        stageList.sideItems.push(item);
        count++;
      });
      bracketList.push(stageList);
    });
    setStagesCount(count);
    return bracketList;
  };

  const changeChildren = (parent, pick, which) => {
    let allPicks = state.picks;
    let allMatches = state.matches;
    var currentIndex = allMatches.findIndex(child => Number(child.home_parent) === Number(parent.id) || Number(child.away_parent) === Number(parent.id));
    if (currentIndex === -1) {
      return;
    }
    let current = allMatches[currentIndex];

    // if no pick has been made stop
    if ( allPicks[parent.id].pick === null ) {
      return;
    }

    var player = null;
    if (parent.player_one && parent.player_one.id === pick) {
      player = parent.player_one;
    }
    if (parent.player_two && parent.player_two.id === pick) {
      player = parent.player_two;
    }

    if (player && current.home_parent == parent.id) {
      current.home_team_id = player.id;
      current.player_one = player;
    }
    if (player && current.away_parent == parent.id) {
      current.away_team_id = player.id;
      current.player_two = player;
    }

    allMatches[currentIndex] = current;
    setState((prevState) => {
      return {
        ...prevState,
        matches: allMatches,
      }
    });

    var currentPick = allPicks[current.id];
    if ( currentPick.pick === null) {
      changeChildren(current, pick, which);
    } else {
      if ( currentPick.team === 'home' && parent.id === Number(current.home_parent)) {
        allPicks[ current.id ].pick = pick;
        setState((prevState) => {
          return {
            ...prevState,
            picks: allPicks
          }
        });
        changeChildren(current, pick, 'home');
      }
      if ( currentPick.team === 'away' && parent.id == Number(current.away_parent)) {
        allPicks[ current.id ].pick = pick;
        setState((prevState) => {
          return {
            ...prevState,
            picks: allPicks
          }
        });
        changeChildren(current, pick, 'away');
      }
    }
  };

  const onPickChanged = (matchId, pick, which) => {
    let allPicks = state.picks;
    console.clear();
    var match = state.matches.find(match => match.id === matchId);
    allPicks[matchId].pick = pick;
    allPicks[matchId].team = which;
    setState({...state, picks: allPicks});
    changeChildren(match, pick, which);
  };

  const onGameChanged = (matchId, gameNumber) => {
    let picks = state.picks;
    picks[matchId].winning_game = gameNumber;
    setState({
      ...state,
      picks,
    });
  };

  const onPropChanged = (matchId, value) => {
    let picksProps = state.picksProps;
    picksProps[matchId].pick = value;
    setState({
      ...state,
      picksProps,
    });
  };

  const initializeBracketTennisPicks = (bracketId, bracketEntryId, matches, picks, picksProps) => {
    if (_.isEmpty(picks)) {
      picks = {};
    }
    if (_.isEmpty(picksProps)) {
      picksProps = {};
    }

    _.each(matches, _match => {
      if (!picks.hasOwnProperty(_match.id)) {
        picks[_match.id] = {
          id: null,
          bracket_id: bracketId,
          bracket_entry_id: bracketEntryId !== null ? Number(bracketEntryId) : null,
          pick: null,
          match_id: Number(_match.id),
          winning_game: null,
          team: null,
        };
      } else {
        picks[_match.id].team = null;
      }

      if (!picksProps.hasOwnProperty(_match.id)) {
        picksProps[_match.id] = {
          bracket_entry_id: bracketEntryId !== null ? Number(bracketEntryId) : null,
          bracket_id: bracketId,
          id: null,
          match_id: Number(_match.id),
          pick: null,
          tennis_fixture_id: _match.tennis_fixtures.length > 0 ? _match.tennis_fixtures[0].id : null,
          tennis_prop_id: _match.tennis_props.length > 0 ? _match.tennis_props[0].id : null,
        };
      }
    });

    return {picks, picksProps};
  };

  const createBracketSide = (stageId) => {
    let filteredMatches = _.groupBy(state.matches, 'stage_id');
    let filteredStages = [];
    let firstStage = props.bracketStages[0].id;
    let lastStage = props.bracketStages[props.bracketStages.length-1].id;
    _.forEach(filteredMatches, (item, key) => {
      item.map((match) => { filteredStages.push(match); });
    });

    let bracketSidesFiltered = createGroups(state.matches, state.stages, true);
    setState({...state, bracketSidesFiltered});
    return filteredStages;
  };

  const saveBracket = (bracketId, picks, entryId, bracketUserTieBreakers, picksProps) => {
    setState({
      ...state,
      submitting: true,
    });

    let temporalRegisterPicks = {
      bracket_entry_id: entryId,
      bracket_id: bracketId,
      bracket_picks: picks,
      bracket_user_tiebreakers: bracketUserTieBreakers,
      tennis_pick_props: picksProps,
    };

    API.postSaveBracketPicks(temporalRegisterPicks).then((response) => {
      callbackBracketSavedPicks(response);
    });
  };

  const callbackBracketSavedPicks = (response) => {
    if (response.data) {
      const newEntry = response.data.bracketEntry.id;

      if (props.refreshAfterGuardar !== null) {
        props.refreshAfterGuardar(props.selectedEntry, 'save');
        setTimeout(() => {
          handleSavedConfirmation();
          setState((prevState) => {
            return {
              ...prevState,
              submitting: false,
              bracketSides: state.bracketSides,
            }
          });
        }, 500);
      }
    }
  };

  const handleSavedConfirmation = () => {
    setState({
      ...state,
      showSavedConfirmation: true,
    });
    setTimeout(() => {
      setState((prevState) => {
        return {
          ...prevState,
          showSavedConfirmation: false,
        }
      });
    }, 2000);
  };

  const handlePopUpReglas = () => {
    setState({...state, showPopUpReglas: !state.showPopUpReglas});
  };

  const onSelectTie = (e, tieId) => {
    let usertieBreakers = state.bracketUserTieBreakers;
    usertieBreakers[tieId].value = Number(e.target.value);
    setState({...state, bracketUserTieBreakers: usertieBreakers});
  };

  const SelectTeam = ({ teamList, tieId, enabled }) => {
    let deadline = new Date(state.bracketInfo.tiebreakers_deadline);
    let now = new Date();
    let tieEnabled = enabled;

    if (now <= deadline) {
      tieEnabled = true;
    } else {
      tieEnabled = false;
    }

    let teamBreaker;
    if (state.bracketUserTieBreakers) {
      teamBreaker = teamList.find(team => team.id === state.bracketUserTieBreakers[tieId].value);
    }
    return (
      <>
        {tieEnabled &&
          <select
            className="bracket-game-select ties"
            value={state.bracketUserTieBreakers && state.bracketUserTieBreakers[tieId].value}
            onChange={(e) => {onSelectTie(e, tieId)} }
            disabled={!tieEnabled}
          >
            <option value=""></option>
            {teamList && teamList.map((team, i) => { return (<option value={Number(team.id)} key={i}>{team.name}</option>)  })}
          </select>
        ||
          <label>{teamBreaker !== undefined ? teamBreaker.name : 'ND'}</label>
        }
      </>
    );
  }

  const SelectPoints = ({ config, tieId, enabled }) => {
    let deadline = new Date(state.bracketInfo.tiebreakers_deadline);
    let now = new Date();
    let tieEnabled = enabled;

    if (now <= deadline) {
      tieEnabled = true;
    } else {
      tieEnabled = false;
    }

    let userPoints;
    if (state.bracketUserTieBreakers) {
      userPoints = state.bracketUserTieBreakers[tieId].value
    }

    //Options
    let options = [];
    for (let i = 0; i < config.max; i++) {
      options.push(<option value={i} key={i}>{i}</option>);
    }

    return (
      <>
        {tieEnabled &&
          <select
            className="bracket-game-select ties"
            value={state.bracketUserTieBreakers && state.bracketUserTieBreakers[tieId].value}
            onChange={(e) => {onSelectTie(e, tieId)} }
            disabled={!tieEnabled}
          >
            {options}
          </select>
        ||
          <label>{userPoints !== undefined ? userPoints : 'ND'}</label>
        }
      </>
    );
  }

  // Load and initialize data
  React.useEffect(() => {
    // Check redirect
    if (state.bracketInfo.status !== 'upcoming') {
      setState({ ...state, redirectLobby: true });
    }

    //Initialize Picks
    let responseInitializePicks = initializeBracketTennisPicks(
      state.bracketInfo.id,
      props.selectedEntry,
      state.matches,
      props.selectedEntryData ? props.selectedEntryData.bracket_picks : {},
      props.selectedEntryData ? props.selectedEntryData.tennis_pick_props : {}
    );

    // Groups
    let bracketSides = createGroups(state.matches, props.bracketStages);

    //Show only 5 stages
    let filteredStages = createBracketSide(selectedStage)

    // Groups Filtered
    let bracketSidesFiltered = createGroups(filteredStages, props.bracketStages, true);

    // Get max id stage
    const maxStage = state.stages.reduce(function(prev, current) {
      return (prev.id > current.id) ? prev : current
    })

    setState({
      ...state,
      bracketSides,
      bracketSidesFiltered,
      picks: responseInitializePicks.picks,
      picksProps: responseInitializePicks.picksProps,
      maxStage,
      isLoaded: true,
    });
  }, [props.selectedEntry]);

  // Remap picks saved
  React.useEffect(() => {
    if (Object.entries(state.picks).length > 0) {
      let allEntryPicks = props.selectedEntryData ? props.selectedEntryData.bracket_picks : {};
      let allPicks = allEntryPicks
      let allMatches = state.matches;

      setState({
        ...state,
        matches: allMatches,
        bracketSidesFiltered: state.bracketSidesFiltered,
      });

      if (allEntryPicks.length === 0) {
        allMatches.map(match => {
          allPicks[match.id] = {
            id: null,
            bracket_id: state.bracketInfo.id,
            bracket_entry_id: props.selectedEntry,
            pick: null,
            match_id: Number(match.id),
            winning_game: null,
            team: null,
          };
        });
        setState({...state, picks: allPicks});
      } else {
        //check matches vs picks
        //look ahead not current
        allMatches.forEach((parent) => {

          let pick = allPicks[ parent.id ];
          if ( pick === undefined ) {return ;}
          if ( pick.pick == null || pick.pick == undefined ) { return; }

          // let child = _.find(allMatches, (c) => c.home_parent == parent.id || c.away_parent == parent.id);
          let childIdx = _.findIndex(allMatches, (c) => c.home_parent == parent.id || c.away_parent == parent.id);

          if ( childIdx === -1 ) { return; }
          let child = allMatches[ childIdx ];

          if ( child.home_team_id !== null && child.away_team_id !== null ) {
            return;
          }

          // if the next match doesn't have a home player defined then bring the player
          // from the previous pick
          if ( child.home_team_id === null ) {
            // set childHomeTeam
            if ( child.home_parent == parent.id && pick.pick == parent.home_team_id) {
              child.home_team_id = parent.home_team_id;
              child.player_one   = parent.player_one;
            }
            if ( child.home_parent == parent.id && pick.pick == parent.away_team_id) {
              child.home_team_id = parent.away_team_id;
              child.player_one   = parent.player_two;
            }

          }

          // if the next match doesn't have a home player defined then bring the player
          // from the previous pick
          if ( child.away_team_id === null ) {
            if ( child.away_parent == parent.id && pick.pick == parent.home_team_id) {
              child.away_team_id = parent.home_team_id;
              child.player_two   = parent.player_one;
            }
            if ( child.away_parent == parent.id && pick.pick == parent.away_team_id) {
              child.away_team_id = parent.away_team_id;
              child.player_two   = parent.player_two;
            }
          }

          // set child
          allPicks[childIdx] = child;
          if ( allPicks[child.id].pick == child.home_team_id ) {
            allPicks[child.id].team = 'home';
          }
          if ( allPicks[child.id].pick == child.away_team_id ) {
            allPicks[child.id].team = 'away';
          }

        });

        setState({...state, picks: allPicks, matches: allMatches});
      }

    }
  }, [state.picks, props.selectedEntry]);

  //Team List
  React.useEffect(() => {
    let list = [];
    if (state.matches !== null) {
      state.matches.map((match) => {
        if (match.player_one !== null) {
          if (!list.find(item => item.id === match.player_one.id)) {
            list.push({id: match.player_one.id ,name: match.player_one.name})
          }
        }
        if (match.player_two !== null) {
          if (!list.find(item => item.id === match.player_two.id)) {
            list.push({id: match.player_two.id ,name: match.player_two.name})
          }
        }
      });
      list = _.uniq(list);
      setTeamList(list.sort(firstBy('name')));
    }
  }, [state.matches]);

  // Init Tie Brackers
  React.useEffect(() => {
    if (state.tieBreakers !== undefined) {
      let userTie = {};
      state.tieBreakers.map(tie => {
        let userTieBreaker = props.selectedEntryData && props.selectedEntryData.bracket_user_tiebreakers.find(item => item.bracket_tiebreaker_id === tie.id);
        let item = {
          id: (userTieBreaker !== undefined && userTieBreaker !== null) ? userTieBreaker.id : null,
          bracket_id: state.bracketInfo.id,
          bracket_entry_id: props.selectedEntryData !== null && props.selectedEntryData !== undefined ? props.selectedEntryData.id : null,
          bracket_tiebreaker_id: tie.id,
          value: (userTieBreaker !== undefined && userTieBreaker !== null) ? userTieBreaker.value : null,
        }
        userTie[tie.id] = item;
      });
      setTimeout(() => {
        setState((prevState) => {
          return {
            ...prevState,
            bracketUserTieBreakers: userTie,
          }
        });
      }, 400);
    }
  }, [state.tieBreakers, props.selectedEntry]);

  React.useEffect(() => {
    if (state.bracketInfo.status === "upcoming" && state.isLoaded) {
      if (props.showBracketModalInfo < 2) {
        handlePopUpReglas();
        props.handleShomModalInfo();
      }
    }
  }, [state.isLoaded]);

  // Render
  if (state.redirectLobby) {
    let bracketName = state.bracketInfo.slug;
    let bracketId = state.bracketInfo.id;
    return (
      <Redirect
        to={{
          pathname: `/bracketTennisLobby/${bracketName}/${bracketId}`,
        }}
      />
    );
  } else {
    return (
      <div className="quinielas-info row">
        <div className="flex-row responsive-sm">
          <div id="picksQuiniela" className="pool-info flex-col">
            <div
              className={`picks-message-overlay ${
                state.errorCopiar !== null
                  ? state.errorCopiar
                    ? 'show-message failed'
                    : 'show-message'
                  : ''
              }`}
            >
              {state.errorCopiar !== null ? (
                state.errorCopiar ? (
                  <span>Inténtalo nuevamente ...</span>
                ) : (
                  <div>
                    <span>¡Tus pick se copiaron con éxito!</span>
                    <i className="ion-checkmark-circled" />
                    <span>No olvides Guardarlos</span>
                  </div>
                )
              ) : (
                ''
              )}
            </div>

            {props.isPopUp && <BracketTennisRegisterSteps isBalanceEnough={state.enoughBalance} />}

            <div className="panel-pickwin flex-container column relative">
              {/* Bracket Map */}
              <div className="row title-quiniela ">
                <div className="flex-container align">
                  <div className="flex-container font-chivo">
                    <ButtonToolbar id="week-controls" className="flex-col">
                      <ToggleButtonGroup
                        className="flex-container align justify"
                        name="weeks-controls"
                        style={{width: '99.5%', textTransform: 'none'}}
                        type="radio"
                        value={selectedStage}
                        onChange={handleStageChange}
                      >
                        {state.stages.map((stage, i) => {
                          return (
                            <ToggleButton
                              key={stage.id}
                              className='bottom-separation font16'
                              value={stage.id}
                              style={{maxWidth: '15%', width: '15%', fontSize: '12px'}}
                            >
                              {stage.name}
                            </ToggleButton>
                          );
                        })}

                      </ToggleButtonGroup>
                    </ButtonToolbar>
                  </div>
                </div>
              </div>

              {/* ---------BRACKET PICKS----------------- */}
              <div
                className={`bracket-overflow-tennis bracket-overflow-tennis-${state.bracketInfo.sport_id}`}
                id="overFlowStyles"
              >
                <div
                  className="bracket-container-tennis"
                  style={{ width: `calc((250px*${state.tieBreakers.length > 0 ? stagesCount+1 : stagesCount}) + ${state.tieBreakers.length > 0 ? 22 : 20}rem)` }}
                >
                  {state.bracketSidesFiltered &&
                    state.bracketSidesFiltered.map((item, i) => {
                      let newIndex = 2;
                      let indexConnector = 1;
                      return (
                        <div key={i} className="tournament-bracket-tennis left-container-tennis">
                          {item.sideItems.map((matches, index) => {
                            let classListWrapper = "";
                            let classListStyle = {};
                            let classBorderBefore = "";
                            let offset = 0;

                            // height connector
                            let heightConnector = 87 * (Math.pow(2, -1+(index+1)));

                            // Set all picks in offset 0 and connectors in height 87px
                            if (matches.stage.id <= selectedStage) {
                              offset = 0;
                              classListStyle = {transform: `none`};
                              classBorderBefore = "border-before";
                              heightConnector = 87;
                            }

                            // Hide connectors if no stage selected
                            if (matches.stage.id < selectedStage && columnSelected === null) {
                              classListWrapper = "selected-stage";
                            }

                            // Calculate offset for each stage after current stage and no column selected
                            if (matches.stage.id > selectedStage && columnSelected === null) {
                              offset = (87/2)*(-2 + Math.pow(2, newIndex));
                              classListStyle = {transform: `translateY(${offset}px) translateZ(0px)`};
                              newIndex++;

                              heightConnector = 87 * (Math.pow(2, -1+(indexConnector+1)));
                              indexConnector++;
                            }

                            // Calculate new offset for each stage after current stage and column selected
                            if (matches.stage.id > selectedStage && columnSelected !== null) {
                              offset = (87/2)*(-2 + Math.pow(2, newIndex));
                              classListStyle = {transform: `translateY(${offset}px) translateZ(0px)`};
                              newIndex++;
                            }

                            // Hide connectors for all stages minor to selected stage
                            if (matches.stage.id < selectedStage && columnSelected !== null) {
                              classListWrapper = "selected-stage";
                            }

                            // Recalculate height connectors for all stages after to stage selected
                            if (matches.stage.id >= selectedStage && columnSelected !== null) {
                              heightConnector = 87 * (Math.pow(2, -1+(indexConnector)));
                              indexConnector++;
                            }

                            return (
                              <>
                                <div className="tournament-bracket-tennis__round" key={index} onClick={() => {handleStageChange(matches.stage.id)}}>
                                  <div className="tournament-bracket-tennis__round-title pointer">
                                    <div className="round-name">
                                      <div className="inner">{matches.stage.name}</div>
                                    </div>
                                  </div>
                                  <ul className={`tournament-bracket-tennis__list ${classListWrapper} ${classBorderBefore} mt-1`} style={classListStyle}>
                                    {matches.map((match, i) => (
                                      <BracketTennisPick
                                        key={match.id}
                                        index={i}
                                        match={match}
                                        pick={state.picks[match.id]}
                                        pickProp={state.picksProps[match.id]}
                                        onPickChanged={onPickChanged}
                                        onGameChanged={onGameChanged}
                                        onPropChanged={onPropChanged}
                                        bracket={state.bracketInfo}
                                        side={item.side}
                                        bracketEntry={props.selectedEntryData}
                                        stage={matches.stage}
                                        maxStage={state.maxStage}
                                        offset={offset}
                                        heightConnector={heightConnector}
                                      />
                                    ))}
                                  </ul>
                                </div>

                                {/* Tie Breakers */}
                                {matches.stage.id === state.maxStage.id && state.tieBreakers.length > 0 &&
                                  <div className="tournament-bracket-tennis__round" key={`ties-${index}` } onClick={() => {handleStageChange(matches.stage.id)}}>
                                    <div className="tournament-bracket-tennis__round-title pointer">
                                      <div className="round-name">
                                        <div className="inner">DESEMPATES</div>
                                      </div>
                                    </div>
                                    <ul className={`tournament-bracket-tennis__list tie-breaker ${classListWrapper} ${classBorderBefore} mt-1`} style={classListStyle}>
                                      <li className={`tournament-bracket-tennis__item relative`}>
                                        <div className="tournament-bracket-tennis__match relative">
                                          <div className="match-content tie-breakers-tennis">
                                            <div className="match-header font-chivo text-bold text-italic uppercase text-small">
                                              Desempates
                                              <span className="pull-right">
                                              {moment(state.tieBreakers.deadline).format("ddd DD, MMM HH:mm")}
                                            </span>
                                            </div>
                                            <div className="fixture-picks flex-row row-center">
                                              <div className="flex-col">
                                                {state.tieBreakers.map(tie => {
                                                  return (
                                                    <div className="flex-row row-center m-05" key={tie.id}>
                                                      <div className="flex-col font-chivo text-bold text-italic">
                                                        <div className="tie-item">
                                                          {tie.type !== "team_points" &&
                                                            <>
                                                              <div className="col-50">{tie.label}</div>
                                                              <div className="col-50 text-right flex-container justify">
                                                                <SelectTeam teamList={ teamList } tieId={tie.id} enabled={tie.enabled} />
                                                              </div>
                                                            </>
                                                          }
                                                          {tie.type === "team_points" &&
                                                            <>
                                                              <div className="col-70">{tie.label}</div>
                                                              <div className="col-30 text-right flex-container justify">
                                                                <SelectPoints config={ tie.config_parsed } tieId={tie.id} enabled={tie.enabled} />
                                                              </div>
                                                            </>
                                                          }
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                }
                              </>
                            );
                          })}
                        </div>

                      );
                    })}
                </div>
              </div>
              {/* ---------END BRACKET PICKS----------------- */}

              {state.bracketInfo.status === 'closed' ? null : state.bracketInfo.close !== 1 && utils.isLogged() ? (
                <div className="btn-wrapper">
                  {state.bracketInfo.is_full ? (
                    <span className="login-failed">
                      Esta quiniela esta llena, busca otra similar en nuestro lobby
                    </span>
                  ) : (
                    <Button
                      data-id="guardar"
                      disabled={(!state.isLoaded || !utils.isLogged() || state.submitting)}
                      onClick={(e) => {
                        if (props.selectedEntry !== null) {
                          saveBracket(
                            state.bracketInfo.id,
                            state.picks,
                            props.selectedEntry,
                            state.bracketUserTieBreakers,
                            state.picksProps,
                          );
                        } else {
                          if (state.enoughBalance) {
                            props.updateUserInfo();
                            props.handleClickRegisterPanel();
                          } else {
                            props.handleAbonar();
                          }
                        }
                      }}
                      className="guardar-picks callToAction"
                      bsStyle="default"
                    >
                      {props.selectedEntry === null &&
                        <>
                          <img src={state.enoughBalance ? guardar : abonar} className="bolsa-img" />
                          <span data-id="guardar">
                            {' '}
                            {state.enoughBalance ? 'GUARDAR BRACKET Y REGÍSTRAME' : 'ABONAR SALDO'}
                          </span>
                        </>
                      ||
                        <>
                          <img src={guardar} className="bolsa-img" />
                          <span data-id="guardar">
                            {' '}
                            {'GUARDAR BRACKET'}
                          </span>
                        </>
                      }
                    </Button>
                  )}
                </div>
              ) : null}

              {props.isPopUp &&
                <>
                  <Button
                    href={`/bracketTennisLobby/${state.bracketInfo.slug}/${state.bracketInfo.id}`}
                    className="btn-lobby"
                    bsStyle="default"
                    disabled={!state.isLogged}
                    onClick={(e) => {
                    }}
                  >
                    <span style={{ padding: '0 10px' }}>IR A LOBBY BRACKET</span>
                  </Button>
                </>
              }
              <RegisterPanel
                type="bracket"
                closePanel={props.handleClickRegisterPanel}
                entryId={props.selectedEntry}
                handleConfirmation={props.isPopUp ? props.handleConfirmation : undefined}
                logged={state.isLogged}
                payType={state.bracketInfo.entry_currency}
                pool={state.bracketInfo}
                matches={state.stages}
                pools={null}
                popUpNewUsersStatus={state.popUpNewUsersStatus}
                quinaID={state.bracketInfo.id}
                refreshAfterGuardar={props.refreshAfterGuardar}
                registerPicks={state.picks}
                registerPicksProps={state.picksProps}
                showPanel={props.showPanelRegister}
                updateUserInfo={props.updateUserInfo}
                userData={state.userData}
                withRegister={props.withRegister}
                updateStateFunc={props.handleClickRegisterPanel}
                reRegister={props.reRegister}
                bracketUserTieBreakers={state.bracketUserTieBreakers}
              />
              <SavedPicksOverlay
                showOverlay={state.showSavedConfirmation}
                allPicks={state.matches.length}
                currentSavedPicks={
                  props.selectedEntry === null ? null : props.selectedEntryData.pick_count
                }
                label="¡ Felicidades tu bracket se guardo con éxito!"
              />
              <Modal
                dialogClassName="infoReferralsPopUp"
                show={state.showPopUpReglas}
                onHide={() => { }}
              >
                <div className="panel-abonar">
                  <div
                    id="panelTitle"
                    className="row-center flex-row row-no-padding panel-abonar-titulo"
                    style={{ justifyContent: 'initial' }}
                  >
                    <span className="flex-col col-80">¿Cómo funciona? </span>
                    <button
                      className="cerrar-panel"
                      onClick={() => {
                        handlePopUpReglas();
                      }}
                      style={{ marginLeft: '55px' }}
                    >
                      <i className="ion-ios-close-empty"></i>
                    </button>
                  </div>
                </div>
                <div className="flex-row row-no-padding pickwin-panel">
                  <div className="flex-col panel-pickwin row-center">
                    <img src="https://storage.googleapis.com/pw-resources/banners/app/popup-game-bracket-tenis.jpg" style={{ maxWidth: '473px', width: '100%', height: 'auto' }} />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default BracketTennis;
