//Libraries
import React from 'react'
import API from '../../utils/api';
import gaUtils from '../GAWrapper/GAWrapper';
import utils from '../../utils/utils';
import Clipboard from 'clipboard';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import MediaQuery from 'react-responsive';
import { Nav, NavItem, Modal, Button } from 'react-bootstrap';
import _ from 'underscore';
import io from 'socket.io-client';
import { firstBy } from 'thenby';

// Components
import InvitarAmigos from '../InvitarAmigos';
import ErrorBoundary from '../ErrorBoundary';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/Footer';
import Loading from '../Loading/Loading';
import Score from '../Score/Score';
import ScoreMobile from '../ScoreMobile/ScoreMobile';
import { ClockContextProvider } from '../../context/ClockContext';
import branchUtils from '../../utils/branchUtils';
import PanelInvitarAmigos from '../PanelInvitarAmigos';
import CancelRegister from '../CancelRegister/CancelRegister';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';
import BracketTennisContainer from './BracketTennisContainer';
import Utils from '../../utils/quinielasUtils';
import RegisterPanel from '../RegisterPanel';

const BracketTennisLobby = (props) => {
  const { match, isPopUp } = props;
  const queryParams = { page: 1, limit: 100, nick: '' };
  const history = require('history').createBrowserHistory();
  const [websocket, setWebSocket] = React.useState(null);
  const [groupPicks, setGroupPicks] = React.useState(null);
  const groupPicksRef = React.useRef(groupPicks);
  groupPicksRef.current = groupPicks;

  const [state, setState] = React.useState({
    activeSort: {
    posiciones: false,
      jugadores: false,
      aciertos: false,
      premios: false,
    },
    activeRound: 0,
    activeStage: 1,
    bracketEntries: null,
    bracketCurrentStage: null,
    bracketGroupPicks: null,
    bracketInfo: null,
    bracketPrizes: null,
    bracketTiesDistribution: null,
    bracketStages: null,
    bracketMatches: null,
    bracketMatchesOriginal: null,
    bracketTeams: null,
    bracketTickets: null,
    branchLink: null,
    enoughBalance: null,
    groupPicksPage: 1,
    groups: null,
    isLogged: false,
    isPopUp: isPopUp === undefined ? false : isPopUp,
    loadedPicks: true,
    mounted: false,
    popUpNewUsersStatus: false,
    picks: {},
    picksProps: {},
    posActualPage: 1,
    redirectToLobby: false,
    registerPicks: null,
    selection: 0,
    selectedEntry: null,
    selectedEntryData: null,
    searchNick: null,
    showAbonar: false,
    showPanelCancel: false,
    showPanelRegister: false,
    showPanelRegisterLobby: false,
    showInvitePanel: false,
    showInviteMail: false,
    submittingSearch: false,
    userData: null,
    userBalance: null,
    standings: null,
    withRegister: false,
    reRegister: false,
    showBracketModalInfo: 1,
    sortChanged: false,
  });

  const updateStandingsAganar = React.useCallback(() => {
    Utils.updateStandingsAganarBracket();
  }, []);

  const getBracketData = (bracketId, bracketEntryId, isLogged = false, websocketInit = null) => {
    API.fetchBracketsDetails(bracketId).then((detailResponse) => {
      detailResponse.data.bracket.tournament_instances.map(tournament => {
        console.log("WS:", websocketInit);
        if (websocketInit) {
          websocketInit.emit('subscribe', Number(tournament.id), 'bracket-tournament-instance');
        }
      });

      axios
        .all([
          API.fetchBracketGroups(detailResponse.data.bracket.tournament_instances[0].id),
          API.fetchBracketStages(detailResponse.data.bracket.tournament_instances[0].id),
          isLogged && API.fetchUserData(),
          API.fetchBracketStandings(bracketId, queryParams),
          isLogged && API.fetchTickets(bracketId, 'bracket'),
        ])
        .then(
          axios.spread(
            (
              groupsResponse,
              stagesResponse,
              userData_,
              standingsResponse,
              ticketsResponse,
            ) => {
              const responses = [
                groupsResponse,
                stagesResponse,
                userData_,
                standingsResponse,
                ticketsResponse,
              ];

              try {
                utils.checkAxiosAllErrors(responses);
              } catch (error) {
                console.log("ERROR:", error);
                Sentry.captureException(error);
              }

              let teams = [];
              groupsResponse.data.groups.map((group) => {
                group.teams.forEach((team) => {
                  teams.push(team);
                });
              });

              let balanceEnough = utils.isBalanceEnough(
                detailResponse.data.bracket.entry_currency,
                detailResponse.data.bracket.entry_fee
              );

              // get Grouop Picks
              API.getTennisBracketGroupPicks(bracketId, stagesResponse.data.current.id, queryParams).then(groupPicksResponse => {
                //Get Entry Data
                if (bracketEntryId !== undefined && bracketEntryId !== null) {
                  API.fetchBracketEntry(bracketEntryId).then(response => {
                    setGroupPicks(groupPicksResponse.data);
                    setState({
                      ...state,
                      bracketInfo: detailResponse.data.bracket,
                      bracketPrizes: detailResponse.data.bracket_prizes,
                      bracketTiesDistribution: detailResponse.data.ties_distribution,
                      bracketStages: stagesResponse.data.stages,
                      bracketMatches: stagesResponse.data.matches,
                      bracketMatchesOriginal: stagesResponse.data.matches,
                      bracketTeams: teams,
                      bracketTickets: ticketsResponse ? ticketsResponse.data.tickets : null,
                      bracketCurrentStage: stagesResponse.data.current,
                      bracketEntries: response.data,
                      enoughBalance: !balanceEnough && ticketsResponse && !ticketsResponse.data.tickets.length ? false : true,
                      entryID: bracketEntryId,
                      groups: groupsResponse.data.groups,
                      isLogged: isLogged,
                      mounted: true,
                      standings: standingsResponse.data,
                      selectedEntry: bracketEntryId,
                      selectedEntryData: response.data.bracket_entry,
                      userData: userData_.data,
                      userBalance: userData_.data,
                      withRegister: bracketEntryId,
                      loadedPicks: true,
                      showPanelRegister: false,
                      showPanelRegisterLobby: false,
                    });

                    history.push(
                      `/bracketTennisLobby/${detailResponse.data.bracket.slug}/${detailResponse.data.bracket.id}/${bracketEntryId}`
                    );
                  });
                } else {
                  setGroupPicks(groupPicksResponse.data);
                  setState({
                    ...state,
                    bracketInfo: detailResponse.data.bracket,
                    bracketPrizes: detailResponse.data.bracket_prizes,
                    bracketTiesDistribution: detailResponse.data.ties_distribution,
                    bracketStages: stagesResponse.data.stages,
                    bracketMatches: stagesResponse.data.matches,
                    bracketMatchesOriginal: stagesResponse.data.matches,
                    bracketTeams: teams,
                    enoughBalance: !balanceEnough && ticketsResponse && !ticketsResponse.data.tickets.length ? false : true,
                    groups: groupsResponse.data.groups,
                    isLogged: isLogged,
                    mounted: true,
                    selectedEntry: bracketEntryId === undefined ? null : bracketEntryId,
                    standings: standingsResponse.data,
                    userData: userData_.data,
                    userBalance: userData_.data,
                    withRegister: bracketEntryId === undefined ? false : true,
                    bracketTickets: ticketsResponse ? ticketsResponse.data.tickets : null,
                    bracketCurrentStage: stagesResponse.data.current,
                    loadedPicks: true,
                    showPanelRegister: false,
                    showPanelRegisterLobby: false,
                  });
                }
              });
            }
          )
        )
        .catch((error) => {
          console.log("ERROR:", error);
          Sentry.captureException(error);
          //window.location.assign('/');
        });
      });
  }

  const updateUserBalance = (newBalance) => {
    const balanceObj = utils.getUserBalance();
    if (newBalance.balanceReal !== undefined) {
      balanceObj.balanceReal = newBalance.balanceReal;
      balanceObj.balanceBonus = newBalance.balanceBonus;
    } else {
      balanceObj.balanceReal = newBalance;
    }

    utils.setUserBalance(balanceObj);
    setState({ ...state, userBalance: balanceObj, isLogged: true });
  };

  const updateUserInfo = (userData) => {
    if (!userData || userData === 'isNewRegister') {
      API.fetchUserData()
        .then((responseUserData) => {
          utils.setUserInfo(responseUserData.data);
          const balanceObj = utils.setUserBalance(responseUserData.data);
          setState(prevState => {
            return {
              ...prevState,
              isLogged: true,
              userData: responseUserData.data.user,
              userBalance: balanceObj,
            }
          });
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching user data',
          });
        });
    } else {
      if (typeof userData !== 'object') {
        return;
      }

      utils.setUserInfo(userData);
      const balanceObj = {
        balanceReal: userData.balanceReal,
        balancePc: !userData.balancePc ? 0 : userData.balancePc,
        balanceReferral: !userData.balanceReferral ? 0 : userData.balanceReferral,
        balanceFreeroll: !userData.balanceFreeroll ? 0 : userData.balanceFreeroll,
        balanceBonus: !userData.balanceBonus ? 0 : userData.balanceBonus,
        tickets: !userData.tickets ? [] : userData.tickets,
      };
      if (userData.hasOwnProperty('user')) {
        setState({
          ...state,
          userData: userData.user,
          userBalance: balanceObj,
          isLogged: true,
          isNewTosConfirmed: userData.user.tos,
          userLoyalty: utils.getUserLoyalty(),
        });
      } else {
        setState({
          ...state,
          userData: userData,
          userBalance: balanceObj,
          isLogged: true,
          isNewTosConfirmed: userData.tos,
          userLoyalty: utils.getUserLoyalty(),
        });
      }
    }
  };

  const changeSelectedEntry = (value, type) => {
    if (value === undefined) {
      return;
    }

    getBracketData(state.bracketInfo.id, value, state.isLogged, websocket);
  };

  const handleClickRegisterPanel = (reRegister = false) => {
    setState({ ...state, showPanelRegister: !state.showPanelRegister, reRegister, });
  };

  const handleClickRegisterPanelLobby = (reRegister = false) => {
    setState({ ...state, showPanelRegisterLobby: !state.showPanelRegisterLobby, reRegister, });
  };

  const redirectToLobby = () => {
    window.location.assign('/');
  };

  const refreshAfterCancel = (newEntries, entryID, bracketID, currentCancelation) => {
    let currentEntryExist = newEntries.find(
      (newEntry) => newEntry.id === Number(state.selectedEntry)
    );

    getBracketData(bracketID, entryID, true, websocket);
  };

  const handleInviteFriendsPanel = () => {
    setState({ ...state, showInvitePanel: !state.showInvitePanel });
  };

  const handleShowCancelPanel = () => {
    setState({ ...state, showPanelCancel: !state.showPanelCancel });
  };

  const updateBranchLink = (newLink) => {
    setState({ ...state, branchLink: newLink });
  };

  const changeSection = (e) => {
    setState({ ...state, selection: e });
  };

  const showSendMail = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showInviteMail: !prevState.showInviteMail,
      };
    });
  };

  const whatsAppInvitation = () => {
    branchUtils.redirectToWhatsApp(state.branchLink, 'open', state.bracketInfo.id);
  };

  const refreshAfterGuardar = (bracketEntryId, typeRegister) => {
    let bracketId = props.match.params.bracketId;
    setState({...state, loadedPicks: false, showPanelRegister: false, showPanelRegisterLobby: false});

    getBracketData(bracketId, bracketEntryId, true, websocket);
  };

  const redirectAfterCancel = () => {
    setState({ ...state, redirectToLobby: true });
  };

  const handleAbonar = () => {
    setState({ ...state, showAbonar: !state.showAbonar});
  }

  const changeSearchNick = (ev) => {
    queryParams.page = 1;
    setState({
      ...state,
      searchNick: ev.target.value,
    });
  };

  const getAganaBracket = (rank) => {
    let aGanar, totalPremios, tipoPremios;
    let premios = state.bracketPrizes;
    let bracket = state.bracketInfo
    let standings = state.standings;

    aGanar = premios.find((prize) => prize.position === rank);
    totalPremios = bracket.available;
    tipoPremios = premios[0].type;

    if (aGanar === undefined) {
      return '0';
    } else if (bracket.closed) {
      if (aGanar.type === 'percentage') {
        return (bracket.available * (aGanar.percentage / 1000)) / 1000;
      } else {
        return aGanar.amount / 1000;
      }
    } else {
      if (aGanar.position === 0) {
        return standings.items[aGanar.position].amount / 100;
      } else {
        if (tipoPremios === 'percentage') {
          return ((aGanar.percentage / 100) * bracket.available) / 100;
        } else if (tipoPremios === 'fixed') {
          return ((aGanar.percentage / 100) * bracket.available) / 100 / 100;
        } else {
          return ((aGanar.percentage / 100) * bracket.available) / 100 / 100;
        }
      }
    }
  };

  const calculateTiesPrizes = (available, premios, tiesDistribution) => {
    var maxPrizedPosition = _.max(premios, function(prize) {
      return prize.position;
    });

    //Initialize all ties with 0
    _.map(tiesDistribution, function(ties) {
      ties.percentage  = 0;
      ties.amount      = 0;
      ties.type        = null;
      ties.description = null;
    });

    // Throught them
    tiesDistribution.map((ties) => {
      // if the current is bigger than the smalles payed position break
      if ( ties.rank > maxPrizedPosition.position ) {
        return;
      } else {
        var rank    = ties.rank; // cual es la posicion
        var numTies = ties.ties; // cuantos lugares empatados hay en esa posición
        var acc = 0; // cuanto le va a tocar a cada uno
        var prize; // el premio

        // iterate through all of the tied positions
        for ( var i = rank ; i < rank + numTies; i++ ) {
          prize = premios[i-1];
          if ( !prize ) { continue; }
          if ( prize.type !== 'ticket' ) {
            // acumular el premio
            acc += prize.percentage;
          } else {
            acc = 10000;
          }
        }
        prize = _.find(premios, {'position': rank});
        if ( prize && prize.type == 'ticket' ) {
          ties.type        = 'ticket';
          ties.description = prize.description;
        }
        // porcentaje acumulado de las posiciones empatadas
        ties.percentage = acc;
        ties.amount     = ( available * (acc/10000) ) / numTies ;
      }
    });

    return tiesDistribution;
  };

  const sortPositionList = (type) => {
    let tempActiveSort = state.activeSort;
    let orderStatus;
    switch (type) {
      case 'posiciones':
        orderStatus = state.activeSort.posiciones ? -1 : 1;
        queryParams.sort = 'rank';
        tempActiveSort.posiciones = !tempActiveSort.posiciones;
        if (tempActiveSort.posiciones) {
          tempActiveSort.jugadores = false;
          tempActiveSort.aciertos = false;
          tempActiveSort.premios = false;
        }
        break;
      case 'jugadores':
        orderStatus = state.activeSort.jugadores ? -1 : 1;
        queryParams.sort = 'nick';
        tempActiveSort.jugadores = !tempActiveSort.jugadores;
        if (tempActiveSort.jugadores) {
          tempActiveSort.posiciones = false;
          tempActiveSort.aciertos = false;
          tempActiveSort.premios = false;
        }
        break;
      case 'aciertos':
        orderStatus = state.activeSort.aciertos ? -1 : 1;
        queryParams.sort = 'points';
        if (state.bracketInfo.status === 'closed') {
          tempActiveSort.aciertos = !tempActiveSort.aciertos;
          if (tempActiveSort.aciertos) {
            tempActiveSort.jugadores = false;
            tempActiveSort.posiciones = false;
            tempActiveSort.premios = false;
          }
        } else {
          tempActiveSort.aciertos = !tempActiveSort.aciertos;
          if (tempActiveSort.aciertos) {
            tempActiveSort.jugadores = false;
            tempActiveSort.posiciones = false;
            tempActiveSort.premios = false;
          }
        }
        break;
      case 'premios':
        orderStatus = state.activeSort.premios ? -1 : 1;
        queryParams.sort = 'prize';
        tempActiveSort.premios = !tempActiveSort.premios;
        break;
      default:
        return;
    }

    queryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
    queryParams.page = 1;
    API.fetchBracketStandings(state.bracketInfo.id, queryParams)
      .then((response) => {
        callbackSort(response.data);
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error in quiniela fetching standings',
        });
      });

    setState(prevState => {
      return {
        ...prevState,
        activeSort: tempActiveSort,
        sortChanged: !state.sortChanged,
        liveFixturesData: Date.now(),
        actualPage: 1,
      }
    });
  }

  const callbackSort = (data) => {
    let newStandings = data;
    let newUpdatedPositions = newStandings.items;

    if (state.bracketInfo.status === 'live') {
      newUpdatedPositions = updateStandingsAganar(
        newStandings.items,
        state.bracketInfo.prize_currency
      );
    }

    // setState({
    //   ...state,
    //   emptyList: data.items.length === 0 ? true : false,
    //   standings: data,
    //   submittingSearch: false,
    //   liveFixtureData: Date.now(),
    //   sortChanged: false,
    // });
    setState(prevState => {
      return {
        ...prevState,
        emptyList: data.items.length === 0 ? true : false,
        standings: data,
        submittingSearch: false,
        liveFixtureData: Date.now(),
        sortChanged: false,
      }
    });
  };

  const siguientePagPositions = (eventKey) => {
    let loaderElement = $('#loading');
    let tempActiveSort = null;
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex';
    }
    let temporalPositions = state.standings.items;

    queryParams.page = queryParams.page + 1;

    API.fetchBracketStandings(state.bracketInfo.id, queryParams).then((response) => {
      const responses = [response];
      try {
        utils.checkAxiosAllErrors(responses);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }

      setState((prevState) => {
        let newPosiciones = response.data;
        let newItems = response.data.items !== null ? response.data.items : null;

        newItems.forEach((item) => {
          temporalPositions.push(item);
        });

        newPosiciones.items = temporalPositions;
        return {
          ...prevState,
          standings: newPosiciones,
          posActualPage: queryParams + 1,
        };
      });

      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'none';
      }
    });
  };

  const handleConfirmation = (newEntryID) => {
    window.location.assign(
      `/bracketTennisLobby/${state.bracketInfo.slug}/${state.bracketInfo.id}/${newEntryID}`
    );
  };

  const getAganaBracketTennis = (rank) => {
    let aGanar, totalPremios, tipoPremios;
    let premios = state.bracketPrizes;
    let bracket = state.bracketInfo;
    let standings = state.standings;

    aGanar = premios.find((prize) => prize.position === rank);
    totalPremios = bracket.available;
    tipoPremios = premios.length > 0 ? premios[0].type : '';

    if (aGanar === undefined) {
      return '0';
    } else if (bracket.closed) {
      if (aGanar.type === 'percentage') {
        return (bracket.available * (aGanar.percentage / 1000)) / 1000;
      } else {
        return aGanar.amount / 1000;
      }
    } else {
      if (aGanar.position === 0) {
        return standings.items[aGanar.position].amount / 100;
      } else {
        if (tipoPremios === 'percentage') {
          return ((aGanar.percentage / 100) * bracket.available) / 100;
        } else if (tipoPremios === 'fixed') {
          return ((aGanar.percentage / 100) * bracket.available) / 100 / 100;
        } else {
          return ((aGanar.percentage / 100) * bracket.available) / 100 / 100;
        }
      }
    }
  };

  const handleShomModalInfo = () => {
    setState({...state, showBracketModalInfo: state.showBracketModalInfo+1});
  };

  const onStageChange = ( stageId ) => {
    let stageFinded = state.bracketStages.find(item => item.id === stageId);

    API.getTennisBracketGroupPicks(state.bracketInfo.id, stageId, queryParams)
    .then((response) => {
      setGroupPicks(response.data);
      setState( {...state, bracketCurrentStage: stageFinded});
    }).catch((error) => {
      alert(JSON.stringify(error));
    });
  };

  const loadMoreStandings = (page) => {
    queryParams.page = page;
    const bracketId = state.bracketInfo.id;

    API.getTennisBracketGroupPicks(bracketId, state.bracketCurrentStage.id, queryParams).then(response => {
      if (response.status === 200) {
        response.data.items = [...groupPicks.items, ...response.data.items];
        setGroupPicks(response.data);
      }
    });
  };

  //Get all data
  React.useEffect(() => {
    gaUtils.handleDocumentAttributes(match);
    let clipboardCode;
    clipboardCode = new Clipboard('.copiar-btn');
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      $(wich).addClass('copied');
      e.clearSelection();
    });

    const bracketId = props.match.params.bracketId !== undefined ? props.match.params.bracketId : props.id;
    const bracketEntryId = state.selectedEntry !== null ? state.selectedEntry : props.match.params.bracketEntryId;

    let isLogged = false;
    let userData = utils.getUserInfo();
    if (userData !== undefined && userData !== null) {
      isLogged = true;
      let balance = utils.getUserBalance();
      setState({
        ...state,
        userData: userData,
        userBalance: balance
      });
    }

    let jwToken = utils.getToken();
    const socketUrl = process.env.REACT_APP_PW_SOCKET_LIVE_PANE;
    let websocketInit = io.connect(socketUrl, {
      query: { token: jwToken },
      secure: true,
      transports: ['websocket'],
    });
    websocketInit.on('connect', () => {
      websocketInit.emit('subscribe', Number(bracketId), 'bracket');
    });

    setWebSocket(websocketInit);

    getBracketData(bracketId, bracketEntryId, isLogged, websocketInit);

    return () => {
      websocketInit.disconnect();
    }
  }, []);

  const onStandingsUpdate = React.useCallback((data) => {
    let allStandings = state.standings;
    let tiesDistribution = state.bracketTiesDistribution;

    // actualizar los premios de la distribución de posiciones empatadas de acuerdo a state.bracketInfo.available.
    // ???

    // buscar mi entry y actualizar solo mi entry...
    if ( state.selectedEntry !== null ) {
      let updatedStanding = data.standings.find((_standing) => { return _standing.bracket_entry_id === state.selectedEntry });
      if ( updatedStanding ) {
        let myEntry = state.selectedEntryData;
        myEntry.bracket_standing.points          = updatedStanding.points;
        myEntry.bracket_standing.possible_points = updatedStanding.possible_points;
        myEntry.bracket_standing.rank            = updatedStanding.rank;
        myEntry.user_prize.amount                = updatedStanding.user_prize.amount;
        myEntry.user_prize.description           = updatedStanding.user_prize.description;


        setState( prevState => {
          return {
            ...prevState,
            selectedEntryData: myEntry,
          }
        });
      }
    }

    let tempBracketGroupPicks = groupPicksRef.current;
    tempBracketGroupPicks.items.map((_group) => {
      var standing = data.standings.find((_standing) => _standing.bracket_entry_id == _group.bracket_entry_id );
      if ( !standing ) { return; }
      _group.rank = standing.rank;
      _group.points = standing.points;
      _group.possible_points = standing.points;
    });
    tempBracketGroupPicks.items.sort(firstBy('rank').thenBy('bracket_entry_id'));
    setGroupPicks(tempBracketGroupPicks);


    if (allStandings.items.length > 0) {
      var howMany = 100 * state.standings.page;
      allStandings.items = data.standings.slice(0, howMany);
    }

    setState(prevState => {
      return {
        ...prevState,
        standings: allStandings,
        bracketTiesDistribution: tiesDistribution,
      }
    });
  });

  // Web socket
  React.useEffect(() => {
    if (websocket && state.bracketInfo) {
      websocket.on('error', (error) => {
        console.log('WS-ERROR', error);
      });

      websocket.on('matches:update', (data) => {
        let matches = state.bracketMatches;

        data.matches.map(match => {
          let matchIndex = matches.findIndex(item => item.id === match.id);
          if ( matchIndex === -1 ) {return;}

          matches[matchIndex].games_won_away = match.games_won_away;
          matches[matchIndex].games_won_home = match.games_won_home;
          matches[matchIndex].match_date = match.match_date;
          matches[matchIndex].match_deadline = match.match_deadline;
          matches[matchIndex].winning_game = match.winning_game;
          matches[matchIndex].winning_pick = match.winning_pick;
        });

        setState(prevState => {
          return {
            ...prevState,
            bracketMatches: matches,
          }
        });
      });

      websocket.on('bracket_standings:update', onStandingsUpdate);

      websocket.on('tennis_props:update', (data) => {
        let allProps = data;
        let allMatches = state.bracketMatches;
        allProps.map((prop) => {
          let index = state.bracketMatches.findIndex(item => item.id === prop.match_id);
          if (index !== -1) {
            allMatches[index].tennis_props[0] = prop;
          }
        });
        setState(prevState => {
          return {
            ...prevState,
            bracketMatches: allMatches
          }
        });
      });

      websocket.on('tenis_fixture:update', (data) => {


        let allMatches = state.bracketMatches;
        _.each(data.results, (result) => {

          allMatches.map((match) => {

            var fixtureIdx = _.findIndex(match.tennis_fixtures, {feed_id: result.feed_id});
            if ( fixtureIdx === -1 ) { return; }

            match.live_status         = result.live_status;
            match.player_1_game_score = result.player_1_game_score;
            match.player_2_game_score = result.player_2_game_score;


            var fixture = _.find(data.live_fixtures, {id: match.tennis_fixtures[fixtureIdx].id});
            if ( fixture ) {
              match.tennis_fixtures[fixtureIdx].player_1_score    = fixture.player_1_score;
              match.tennis_fixtures[fixtureIdx].sets_player_one   = fixture.sets_player_one;
              match.tennis_fixtures[fixtureIdx].player_2_score    = fixture.player_2_score;
              match.tennis_fixtures[fixtureIdx].sets_player_two   = fixture.sets_player_two;
              match.tennis_fixtures[fixtureIdx].winner_id         = fixture.winner_id;
              match.tennis_fixtures[fixtureIdx].sets_played       = fixture.sets_played;
              match.tennis_fixtures[fixtureIdx].games_played      = fixture.games_played;
            }

          });


        });

        setState(prevState => {
          return {
            ...prevState,
            bracketMatches: allMatches,
          }
        });

      });

      websocket.on('tennis_fixture:close', (data) => {
        let allMatches = state.bracketMatches;
        allMatches.map((match) => {
          if ( match.id != data.match_id ) { return; }
          match.status = 'finished';
          match.sort_status = 2;
          match.finished = true;
        });

        setState(prevState => {
          return {
            ...prevState,
            bracketMatches: allMatches,
          }
        });

      });

      websocket.on('match:new_data', (data) => {
        var match = data.match;
        let allMatches = state.bracketMatches;
        var matchIdx = allMatches.findIndex(item => item.id === match.id);
        if( matchIdx == -1 ) { return; }

        if ( match.home_team_id != null ) {
          allMatches[matchIdx].player_one = match.player_one;
          allMatches[matchIdx].player_two = match.player_two;
        }
        if ( match.away_team_id != null ) {
          allMatches[matchIdx].home_team_id = match.home_team_id;
          allMatches[matchIdx].away_team_id = match.away_team_id;
        }

        setState(prevState => {
          return {
            ...prevState,
            bracketMatches: allMatches,
          }
        });

      });
    }
  }, [state.bracketInfo, websocket]);

  // Render
  // console.log("BTL:", state);
  if (state.mounted) {
    return (
      <ErrorBoundary>
        {isPopUp !== undefined && isPopUp === true &&
          <>
            {/* // Bracket Content Modal */}
            <BracketTennisContainer
              updateUserInfo={updateUserInfo}
              handleClickRegisterPanel={handleClickRegisterPanel}
              handleAbonar={handleAbonar}
              handleConfirmation={handleConfirmation}
              handleShomModalInfo={handleShomModalInfo}
              {...state}
            />
          </>
        ||
          <div className="page-wrapper">
            <div className="inner-wrapper">
              <div className="quina-lobby-wrapper">
                <NavigationBar
                  isLogged={state.isLogged}
                  userBalance={state.userBalance}
                  userData={state.userData}
                  updateUserBalance={updateUserBalance}
                  updateUserInfo={updateUserInfo}
                />
              </div>
              <ClockContextProvider>
                <div className="container-fluid">
                  <MediaQuery query="(max-width: 659px)">
                    <ScoreMobile
                      changeSelectedEntry={changeSelectedEntry}
                      entries={state.bracketEntries}
                      handleCancelPanel={handleShowCancelPanel}
                      handleClickFirstRegister={handleClickRegisterPanel}
                      handleClickReRegister={() => {handleClickRegisterPanelLobby(true)}}
                      withRegister={state.withRegister}
                      logged={state.isLogged}
                      poolInfo={state.bracketInfo}
                      popup={false}
                      quinaID={state.bracketInfo.id}
                      redirectToLobby={redirectToLobby}
                      refreshAfterCancel={refreshAfterCancel}
                      showInvite={handleInviteFriendsPanel}
                      hideInvite={handleInviteFriendsPanel}
                      selectedEntry={state.selectedEntry}
                      type="bracket"
                      updateStateFuncCancel={handleShowCancelPanel}
                      updateUserInfo={updateUserInfo}
                      status={state.bracketInfo.status}
                    />
                  </MediaQuery>
                  <MediaQuery query="(min-width: 660px)">
                    <Score
                      changeSelectedEntry={changeSelectedEntry}
                      entries={state.bracketEntries}
                      handleCancelPanel={handleShowCancelPanel}
                      handleClickFirstRegister={handleClickRegisterPanel}
                      handleClickReRegister={() => {handleClickRegisterPanelLobby(true)}}
                      withRegister={state.withRegister}
                      logged={state.isLogged}
                      poolInfo={state.bracketInfo}
                      popup={false}
                      quinaID={state.bracketInfo.id}
                      redirectToLobby={redirectToLobby}
                      refreshAfterCancel={refreshAfterCancel}
                      selectedEntry={state.selectedEntry}
                      showInvite={handleInviteFriendsPanel}
                      hideInvite={handleInviteFriendsPanel}
                      updateBranchLink={updateBranchLink}
                      type="bracket"
                      updateStateFuncCancel={handleShowCancelPanel}
                      updateUserInfo={updateUserInfo}
                      status={state.bracketInfo.status}
                    />
                  </MediaQuery>
                  <div
                    id="multiple-quiniela-container"
                    className={'quinielas-info lobby col-lg-12  col-fhd-10 col-fhd-offset-1 col-rt-10 col-rt-offset-1'}
                  >
                    <Nav
                      bsStyle="tabs"
                      bsClass="tab-container flex-row row-no-padding nav"
                      onSelect={changeSection}
                    >
                      <NavItem
                        eventKey="0"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(state.selection) === 0 ? 'active' : null
                        }`}
                      >
                        BRACKET
                      </NavItem>
                      <NavItem
                        eventKey="1"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(state.selection) === 1 ? 'active' : null
                        }`}
                      >
                        POSICIONES
                      </NavItem>
                      <NavItem
                        eventKey="2"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(state.selection) === 2 ? 'active' : null
                        }`}
                        style={
                          state.bracketInfo.closed
                            ? { display: 'initial' }
                            : state.bracketInfo.status === 'upcoming'
                            ? state.withRegister === false
                              ? { display: 'none' }
                              : { display: 'initial' }
                            : { display: 'initial' }
                        }
                      >
                        PICKS DE GRUPO
                      </NavItem>
                      {/* <NavItem
                        eventKey="3"
                        className={`trapezoid flex-col col-no-padding col-center ${
                          parseInt(state.selection) === 3 ? 'active' : null
                        }`}
                      >
                        MARCADORES
                      </NavItem> */}
                    </Nav>
                    <Modal
                      dialogClassName="invitarAmigosModalDialog modal-dialog-xxxs"
                      className="invitarAmigosModal"
                      backdropClassName="invitarAmigosBackdrop"
                      show={state.showInvitePanel}
                      onHide={handleInviteFriendsPanel}
                    >
                      <PanelInvitarAmigos
                        title={`Invita a tus amigos a jugar el bracket ${state.bracketInfo.name_replaced}`}
                        bracket={state.bracketInfo}
                        whatsAppInvitation={whatsAppInvitation}
                        openInvitePanel={handleInviteFriendsPanel}
                        closePanel={handleInviteFriendsPanel}
                        branchLink={state.branchLink}
                        showPanel={state.showInvitePanel}
                      />
                    </Modal>
                    {/* <InvitarAmigos
                      pool={state.bracketInfo}
                      showPanel={state.showInvitePanel}
                      userDataProps={state.userData}
                      updateStateFunc={handleInviteFriendsPanel}
                    /> */}

                    <BracketTennisContainer
                      getAganaBracket={getAganaBracket}
                      changeSearchNick={changeSearchNick}
                      calculateTiesPrizes={calculateTiesPrizes}
                      sortPositionList={sortPositionList}
                      siguientePagPositions={siguientePagPositions}
                      updateUserInfo={updateUserInfo}
                      refreshAfterGuardar={refreshAfterGuardar}
                      handleClickRegisterPanel={handleClickRegisterPanel}
                      handleAbonar={handleAbonar}
                      getAganaBracketTennis={getAganaBracketTennis}
                      handleShomModalInfo={handleShomModalInfo}
                      onStageChange={onStageChange}
                      groupPicks={groupPicks}
                      loadMoreStandings={loadMoreStandings}
                      reRegister={state.reRegister}

                      {...state}
                    />
                  </div>
                  <RegisterPanel
                    type="bracket"
                    closePanel={handleClickRegisterPanelLobby}
                    entryId={state.selectedEntry}
                    handleConfirmation={props.isPopUp ? props.handleConfirmation : undefined}
                    logged={state.isLogged}
                    payType={state.bracketInfo.entry_currency}
                    pool={state.bracketInfo}
                    matches={state.bracketStages}
                    pools={null}
                    popUpNewUsersStatus={state.popUpNewUsersStatus}
                    quinaID={state.bracketInfo.id}
                    refreshAfterGuardar={refreshAfterGuardar}
                    registerPicks={state.picks}
                    registerPicksProps={state.picksProps}
                    showPanel={state.showPanelRegisterLobby}
                    updateUserInfo={updateUserInfo}
                    userData={state.userData}
                    withRegister={state.withRegister}
                    updateStateFunc={handleClickRegisterPanelLobby}
                    reRegister={state.reRegister}
                  />
                  <CancelRegister
                    allEntries={state.bracketEntries}
                    entries={state.bracketEntries}
                    logged={state.isLogged}
                    pool={state.bracketInfo}
                    pools={null}
                    quinaID={state.bracketInfo.id}
                    refreshAfterCancel={refreshAfterCancel}
                    redirectAfterCancel={redirectAfterCancel}
                    redirectToLobby={redirectToLobby}
                    registerPicks={state.registerPicks}
                    showPanel={state.showPanelCancel}
                    type="bracket"
                    updateStateFunc={handleShowCancelPanel}
                    updateUserInfo={updateUserInfo}
                    userDataProps={state.userData}
                  />
                  <AbonarSaldo
                    type="modal"
                    showAbonarSaldo={state.showAbonar}
                    updatePanelSate={handleAbonar}
                    showAbonarPop={handleAbonar}
                    updateUserInfo={updateUserInfo}
                  />
                </div>
              </ClockContextProvider>
              <div id="push" />
            </div>
            <Footer />
          </div>
        }
      </ErrorBoundary>
    );
  } else {
    return (
      <ErrorBoundary>
        <Loading label="..." />
      </ErrorBoundary>
    );
  }
}

export default BracketTennisLobby;
