import styled, { css } from 'styled-components'
import { LinkBase } from '../Link'

// Extending styles from Link
const LinkButton = styled.button`
  ${LinkBase};
  background-color: transparent;
  border: 0;
  outline: 0;
  line-height: 1;
  padding: 0.8em 0;
`

export default LinkButton
