import React from 'react';
import API from '../../../utils/api';
import { isMobile } from 'react-device-detect';
import styles from './index.module.sass';
import _ from 'underscore';

export default function RewardVideo({ userLoyalty }) {
  const [modalVideo, setModalVideo] = React.useState(true);
  const [videoClass, setVideoClass] = React.useState('');
  const [showPlayButton, setShowPlayButton] = React.useState(false);
  const { animation } = userLoyalty;

  React.useEffect(() => {
    if ( animation !== undefined && animation !== null && !_.isEmpty(animation) ) {
      var video = document.getElementById('myVideo');
      video.oncanplaythrough = function() {
        var playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              console.log('PLAYING');
            })
            .catch((error) => {
              console.log('PLAYERROR:', error);
              setShowPlayButton(true);
            });
        }
      };
      setVideoClass(styles.videoContainerEffect);
      video.onended = () => {
        setVideoClass('');
        setTimeout(() => {
          setModalVideo(false);
        }, 1500);

        //set video to seen:true
        API.userRewardVideo({ id: animation.id }).then((response) => {});
      };
    }
  }, []);

  function playVideo() {
    var video = document.getElementById('myVideo');
    try {
      video.play();
      setShowPlayButton(false);
    } catch(e) {
      setShowPlayButton(true);
      console.log(e);
    }
  }

  function closeVideo() {
    API.userRewardVideo({ id: animation.id }).then((response) => {});
    setVideoClass('');
    setModalVideo(false);
    //set video to seen:true
  }

  var width = '200px';

  var height = '200px';
  if (!isMobile) {
    width = '800px';
    height = '450px';
  } else {
    width = '100%';
    height = 'auto';
  }
  // debugger;
  return (
    <>
      { modalVideo && !_.isEmpty(animation) && animation !== null && (
        <div className={styles.rewardVideoContainer}>


          <div className={`${styles.videoContainer} ${videoClass}`}>
            <div className={`${styles.videoCloseButton}`} onClick={e => closeVideo()}><i className="icon ion-close-circled"></i></div>
            {(showPlayButton && (
              <div
                className={`${(isMobile && styles.buttonPlayMobile) || styles.buttonPlay}`}
                onClick={playVideo}
              >
                <i className="ion-play"></i>
              </div>
            )) ||
              ''}
            <video width={width} height={height} id="myVideo">
              <source
                src={`https://pw-resources.storage.googleapis.com/videos/horizontal/level-${animation.data.level_id}.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </>
  );
}
