import { useEffect, useRef, useState } from 'react';
import useLatest from './useLatest';
import useMemoizedFn from './useMemoizedFn';
import useUnmount from './useUnmount';

import io from 'socket.io-client';


export let ReadyState

;(function(ReadyState) {
  ReadyState[(ReadyState["Connecting"] = 0)] = "Connecting"
  ReadyState[(ReadyState["Open"] = 1)] = "Open"
  ReadyState[(ReadyState["Closing"] = 2)] = "Closing"
  ReadyState[(ReadyState["Closed"] = 3)] = "Closed"
})(ReadyState || (ReadyState = {}))


export default function useWebSocket(
  socketUrl,
  options,
) {


  const {
    reconnectLimit = 3,
    reconnectInterval = 3 * 1000,
    manual = false,
    onOpen,
    onClose,
    onMessage,
    onError,
    queryParams,
    protocols,
  } = options || {};

  const onOpenRef = useLatest(onOpen);
  const onCloseRef = useLatest(onClose);
  const onMessageRef = useLatest(onMessage);
  const onErrorRef = useLatest(onError);

  const reconnectTimesRef = useRef(0);
  const reconnectTimerRef = useRef();
  const websocketRef = useRef();

  const unmountedRef = useRef(false);


  const [readyState, setReadyState] = useState(ReadyState.Closed);

  const connectWs = () => {

    if ( websocketRef.current ) {
      websocketRef.current.close();
    }

    const ws = io.connect(socketUrl, {
      query: queryParams,
      secure: true,
      transports: ['websocket'],
    });


    setReadyState(ReadyState.Connecting);

    ws.on('connect', () => {
      setReadyState(ReadyState.Open);
      onOpenRef.current && onOpenRef.current();
      reconnectTimesRef.current = 0;
    });

    ws.on('disconnect', () => {
      setReadyState(ReadyState.Closing);
      onCloseRef.current && onCloseRef.current();
    });

    websocketRef.current = ws;

  };


  const connect = () => {
    reconnectTimesRef.current = 0;
    connectWs();
  };

  const disconnect = () => {
    if (reconnectTimerRef.current) {
      clearTimeout(reconnectTimerRef.current);
    }

    reconnectTimesRef.current = reconnectLimit;
    websocketRef.current.close();
  };


  useEffect(() => {
    if (!manual) {
      connect();
    }
  }, [socketUrl, manual]);

  useUnmount(() => {
    unmountedRef.current = true;
    disconnect();
  });

  return {
    readyState,
    connect: useMemoizedFn(connect),
    disconnect: useMemoizedFn(disconnect),
    webSocket: websocketRef.current,
  }

};
