//Libraries
import React from 'react';
import ImageTransitions from '../ImageTransitions/ImageTransitions';
import API from '../../utils/api';
import Loading from '../Loading';
import moment from 'moment';

//Css
import './billboard.scss';

const Billboard = (props) => {
  const [optionsLeft, setOptionsLeft] = React.useState([]);
  const [optionsCenter, setOptionsCenter] = React.useState([]);
  const [optionsRight, setOptionsRight] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const filterByDate = (item) => {
    if ( item.startDate === undefined && item.endDate === undefined ) {
      return true;
    }
    const now = moment();
    if ( item.startDate !== undefined ) {
      let startDate = moment(item.startDate);
      if ( startDate.isAfter(now) ) {
        return false;
      }
    }

    if ( item.endDate !== undefined ) {
      let endDate = moment(item.endDate);
      if ( endDate.isBefore(now) ) {
        return false;
      }
    }

    return true;
  };

  React.useEffect(() => {
    API.getBillboards().then((response) => {
      let data = response.data;

      let optionsLeft = [];
      let optionsCenter = [];
      let optionsRight = [];

      //Billboards left
      data.left
      .filter(filterByDate)
      .forEach((item) => {
        let options = {
          imgSrc: item.image,
          containerName: "billboard-image-left",
          columns: 7,
          margin: 1,
          interval: item.duration*1000,
          url: item.url,
          external: item.external
        }
        optionsLeft.push(options);
      });
      setOptionsLeft(optionsLeft);

      //Billboards center
      data.center
      .filter(filterByDate)
      .forEach((item) => {
        let options = {
          imgSrc: item.image,
          containerName: "billboard-image-center",
          columns: 7,
          margin: 1,
          interval: item.duration*1000,
          url: item.url,
          external: item.external
        }
        optionsCenter.push(options);
      });
      setOptionsCenter(optionsCenter);

      //Billboards right
      data.right
      .filter(filterByDate)
      .forEach((item) => {
        let options = {
          imgSrc: item.image,
          containerName: "billboard-image-right",
          columns: 7,
          margin: 1,
          interval: item.duration*1000,
          url: item.url,
          external: item.external
        }
        optionsRight.push(options);
      });
      setOptionsRight(optionsRight);

      setIsLoaded(true);
    });
  }, []);

  if (!props.billboardIds) {
    return null;
  } else {
    if (!isLoaded) {
      return <Loading label="Cargando..." />;
    } else {
      return (
        <div className="row">
          <div className="flex-container billboard-general">
            <div className="col-xs-4" id="b-left">
              <div className="billboard-container left">
                <div className="billboard-item">
                  <ImageTransitions options={optionsLeft} id={'left'} />
                </div>
              </div>
            </div>
            <div className="col-xs-4 mobile-center">
              <div className="billboard-container center">
                <div className="billboard-item">
                  <ImageTransitions options={optionsCenter} id={'center'} />
                </div>
              </div>
            </div>
            <div className="col-xs-4" id="b-right">
              <div className="billboard-container right">
                <div className="billboard-item">
                  <ImageTransitions options={optionsRight} id={'right'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};
export default Billboard;
