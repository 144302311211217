import React from 'react';
import Loading from '../Loading';
import Utils from '../../utils/quinielasUtils';

import {
  Premios,
  //TablaPosicionesLive
} from '../../utils/quinielasComponents';

function AsignarPartidos(props) {
  let conditionalComponent = null;
  let partidosEnvivoSocket;
  let liveFixture_;
  let listPartidos;
  console.log('AsignarPartidos', props.liveFixturesData);
  //Buscar partidos en vivo
  if (props.liveFixturesData !== null || props.liveFixturesData !== undefined) {
    if (props.liveFixturesData.live_fixtures.length > 0) {
      console.log('si hay data envivo desde el socket');
      //Filtrar las que estan en nuestra lista de fixtures
      partidosEnvivoSocket = props.liveFixturesData.live_fixtures.filter((liveFixt) => {
        // console.log(liveFixt);
        liveFixture_ = props.fixtures.find((fixt) => {
          // console.log(fixt, liveFixt.id);
          if (liveFixt.id === fixt.id) {
            // console.log('Found!', 'FixtureID', fixt, 'liveFixtureID', liveFixt);
            return liveFixt;
          }
        });
        // console.log('%c Partido Encontrado:','background:#FFEC07','font-size:28px;');
        // console.log(liveFixture_);
        return liveFixture_;
      });
      console.log('%c Partido Encontrado:', 'background:#FFEC07; font-size:28px;');
      console.log(partidosEnvivoSocket);

      // let resultados = Object.keys(props.liveFixturesData.results).map(key => props.liveFixturesData[key].results);
      let resultados = Object.keys(props.liveFixturesData.results);
      resultados = resultados.map((key) => {
        return props.liveFixturesData.results[key];
        // console.log(props.liveFixturesData.results[Number(key)] )
      });
      // console.log(resultados);
      resultados = resultados.filter((resultado) => {
        let results_ = partidosEnvivoSocket.find((partidoLive) => {
          if (partidoLive.feed_id === resultado.feed_id) {
            console.log(partidoLive, resultado);
            return resultado;
          }
        });
        console.log(results_);
        return results_;
      });
      console.log('%c Resultados LIVE Encontrados:', 'background:#FFEC07;font-size:28px;');
      console.log(resultados);
      if (resultados.length > 0) {
        //Depende del deporte es la información que vamos a utilizar
        // console.log(resultados[0].events);
        listPartidos = resultados.map((res, i) => {
          let currentFixture = props.fixtures.find((f) => f.feed_id === res.feed_id);
          console.log('Current Fixture in Live Games', currentFixture);
          console.log(i);
          return (
            <div key={i} className="partidos-envivo-item">
              <div className="local-item">
                <span>{currentFixture.home_team.abbreviation}</span>
              </div>
              <div className="linea-item">
                <div className="linea-home">
                  <span>
                    {currentFixture.favorite === 'home' ? currentFixture.score_home_line : null}
                  </span>
                </div>
                <div className="linea-away">
                  <span>
                    {currentFixture.favorite === 'away' ? currentFixture.score_away_line : null}
                  </span>
                </div>
              </div>
              <div className="visitante-item">
                <span>{currentFixture.away_team.abbreviation}</span>
              </div>
              <div className="mi-pick-item">
                <span>
                  {partidosEnvivoSocket[i].winning_pick === 'home'
                    ? currentFixture.home_team.abbreviation
                    : currentFixture.away_team.abbreviation}
                </span>
              </div>
              <div className="resultado-item">
                <span>{`${res.homescore} - ${res.awayscore}`}</span>
              </div>
              {/*<div className="pick-clave-item">*/}
              {/*/!*<span>{`${partidosEnvivoSocket[i].score_home} - ${partidosEnvivoSocket[i].score_away}`}</span>*!/*/}
              {/*<span>N/A</span>*/}
              {/*</div>*/}
            </div>
          );
        });
        conditionalComponent = <div className="partidos-row">{listPartidos}</div>;
      } else {
        //No Hay Envivo
      }
    } else {
      // No hay partidos Envivo Disponibles
      conditionalComponent = <span className="nota-live">No hay partidos en vivo</span>;
    }
  } else {
    // No hay partidos Envivo Disponibles
    conditionalComponent = <span>N/D</span>;
  }
  console.log(conditionalComponent);
  return conditionalComponent;
}

class Envivo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: this.props.logged,
      userData: this.props.userData,
      fixtures: this.props.fixtures,
      poolInfo: this.props.poolInfo,
      liveData: this.props.liveFixturesData,
      partidosEnvivo: null,
    };
    this.getAganar = Utils.getAganar.bind(this);
    // console.log('Envivo',this)
  }

  componentDidMount() { }

  // shouldComponentUpdate(nextProps, nextState){
  //     if(this.state.liveData !== this.props.liveFixturesData){
  //         return true
  //     }     else {
  //         return false
  //     }
  // }
  render() {
    let conditionalComponent;
    if (this.props.liveFixturesData === undefined || this.props.liveFixturesData === null) {
      console.log('Esperando data de socket');
      conditionalComponent = <Loading label="Cargando partidos Envivo.." />;
    } else {
      console.log('Data lista ', this.state.liveData);
      conditionalComponent = (
        <div className="envivo-container">
          <div className="partidos-envivo-tabla">
            <div className="titulo-envivo">{'Partidos en vivo'}</div>
            <div className="tabla-superior-envivo headers partidos-envivo-item">
              <div className="tabla-superior item header local-item">Local</div>
              <div className="tabla-superior item header linea-item">Línea</div>
              <div className="tabla-superior item header visitante-item">Visitante</div>
              <div className="tabla-superior item header mi-pick-item">Mi Pick</div>
              <div className="tabla-superior item header resultado-item">Resultado</div>
              {/*<div className="tabla-superior item header pick-clave-item">Pick Clave</div>*/}
            </div>
            <AsignarPartidos
              fixtures={this.props.fixtures}
              liveFixturesData={this.props.liveFixturesData}
              resultadosEnvivo={this.props.liveFixturesData}
              poolInfo={this.props.poolInfo}
            />
          </div>
          <div className="posiciones-envivo">
            {/* <TablaPosicionesLive
                                getAganar={this.getAganar}
                                standings={this.props.standings}
                                tipoPremio={this.props.premios.pool_prizes[0].prizes[0].type}
                                premios={this.props.premios}
                                poolInfo={this.props.poolInfo}
                                standGroups={this.props.standGroups}
                            /> */}
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}

export default Envivo;
