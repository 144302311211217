import React, { useState, useEffect, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import firstBy from 'thenby'

// Components
import MyPlays from './MyPlays'
import utils from '../../utils/utils'
import Loading from '../Loading'
import PageWrapper from '../PageWrapper'
import GameCard from './components'
import { Button, Iconify } from '../../Xmerald-UI'
import GameContainer from './components/GameContainer'

// Utils
import API from '../../utils/api'
import useWebSocket from '../../hooks/useWebSocket'
import { closeGameSessionData } from '../../routes/history'

// Hooks
import useUserContext from '../../hooks/useUserContext'

// Styles
import './index.scss'

const history = require('history').createBrowserHistory()

const sortOptions = [
  { name: 'Popularidad', value: 'rank' },
  { name: 'Nombre', value: 'name' },
  { name: 'Lanzamiento', value: 'launch_date' },
]

const Slots = () => {
  const { updateUserBalance } = useUserContext()

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString)

  const [isLoading, setIsLoading] = useState(true)
  const [games, setGames] = useState([])
  const [types, setTypes] = useState([])
  const [totalGames, setTotalGames] = useState(0)
  const [showMyGames, setShowMyGames] = useState(false)

  // Filters
  const [sortBy, setSortBy] = useState(urlParams.get('sortBy') || 'rank')
  const [searchText, setSearchText] = useState(urlParams.get('searchText') || '')
  const [selectedType, setSelectedType] = useState(urlParams.get('type') || 'all')
  const [sortDirection, setSortDirection] = useState(Number(urlParams.get('sortDirection') || 1))
  const [gameSlug, setGameSlug] = useState(urlParams.get('game') || '')
  const [activeGame, setActiveGame] = useState(null)
  const [gameOpened, setGameOpened] = useState(false)
  const [isDemo, setIsDemo] = useState(false)

  useEffect(() => {
    history.replace({
      pathname: '/slots',
      search: `?type=${selectedType}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchText=${searchText}&game=${gameSlug}`
    })
  }, [searchText, selectedType, sortBy, sortDirection, gameSlug])

  useEffect(() => {
    if (gameSlug === '') {
      return setActiveGame(null)
    }
  
    if (gameSlug && games.length > 0) {
      // Get game by slug
      const active = getGameBySlug(gameSlug)

      setActiveGame(active)
    }
  }, [gameSlug, games])


  const getGameBySlug = (slug) => {
    const active = games.find(game => game.name.toLowerCase().replace(/\s/g, '-') === slug)

    return active
  }

  // websocket
  const websocketUrl = process.env.REACT_APP_PW_SOCKET_WAGERS
  const { readyState, webSocket } = useWebSocket(websocketUrl, {
    queryParams: { token: utils.getToken() }
  })

  const onMessage = useCallback(data => {
    const { balance, currency } = data

    let userBalance = utils.getUserBalance()

    if (currency === 'real') {
      userBalance = { ...userBalance, balanceReal: balance }
      updateUserBalance(userBalance)
    }
  }, [])

  useEffect(() => {
    if (readyState === 1) {
      webSocket.emit('subscribe', utils.getUserInfo().id)
      webSocket.on('wager:balance', onMessage)
    }
  }, [readyState, webSocket, onMessage])

  useEffect(() => {
    API.getGames().then(response => {
      const { games, types, total } = response.data
      setTotalGames(total)
      setGames(games)
      setTypes(types)
      setIsLoading(false)
    })
  }, [])

  
  const closeGameHanldler = (data) => {

    if (data?.type && data?.token_id) {
      closeGameSessionData(data)
    }
    
    setGameSlug('')
  }

  const setGameTypeFilter = type => {
    setSelectedType(type.game_type || 'all')
    setSearchText('')
  }

  const onShowMyGamesClicked = (e) => {
    e.preventDefault()
    setShowMyGames(true)
  }

  const handleSort = (sortBy) => () => {
    setSortBy(prev => {
      if (prev === sortBy) {
        setSortDirection(direction => direction * -1)
      }
      return sortBy
    })
  }

  const getSortedFilterGames = (games) => {

    if (!games) return []
    let filteredGames = games
  
    // Filter
    if (selectedType !== 'all') {
      filteredGames = games.filter(game => game.game_type === selectedType)
    }
    
    if (searchText !== '' && searchText.length > 2) {
      filteredGames = filteredGames.filter(game => game.name.toLowerCase().match(searchText.toLowerCase()))
    }

    // Sort by
    const sorted = filteredGames.sort(firstBy(sortBy, sortDirection))

    return sorted
  }

  const sortedFilterGames = getSortedFilterGames(games)

  if (isLoading) {
    return (
      <PageWrapper>
        <div className='loading-container'>
          <Loading />
        </div>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <div className='casino-container'>
        <div className='side-bar'>

          <div className='sidebar-widget'>
            <div className='sidebar-header'>
              <span>JUEGOS</span>
            </div>
            <div className='sidebar-menu'>
              <ul>
                <li>
                  <div className={`sidebar-menu-item ${selectedType === 'all' ? 'active' : ''}`}>
                    <ButtonBar  className={selectedType === 'all' ? 'active' : ''} onClick={e => { e.preventDefault(); setGameTypeFilter('all') }}>
                      <span className='title'>
                        <span className={`game-icon icon-casino-game-all icon`} />
                        <span>Todos</span>
                      </span>
                      <span className='count'>{totalGames}</span>
                    </ButtonBar>
                  </div>
                </li>

                {types.map((type, idx) => {
                  return (
                    <div className='sidebar-menu-item'>
                      <ButtonBar className={type.game_type === selectedType ? 'active' : ''}  onClick={e => { e.preventDefault(); setGameTypeFilter(type) }}>
                        <span className='title'>
                          <span className={`game-icon icon-casino-${type.icon_class} icon`} />
                          <span>{type.game_type}</span>
                        </span>
                        <span className='count'>{type.count}</span>
                      </ButtonBar>
                    </div>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className='sidebar-widget'>
            <div className='sidebar-header'>
              <span>HISTORIAL</span>
            </div>
            <div className='sidebar-menu'>
              <ul>
                <li>
                  <div className='sidebar-menu-item'>
                    <ButtonBar onClick={onShowMyGamesClicked}>
                      <span className='title'>
                        <span className='game-icon game-history icon' />
                        <span>Juegos</span>
                      </span>
                    </ButtonBar>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='main-content'>
          <Sections>
            <FiltersBar>
              <Input
                placeholder='Buscar'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Filters>
                {sortOptions.map(option => (
                  <ButtonBar
                    onClick={handleSort(option.value)}
                    className={sortBy === option.value ? 'active' : ''}
                    style={{ width: 'auto'}}
                  >
                    {option.name}
                    <span
                      style={{
                        lineHeight: 1,
                        marginLeft: '0.3em',
                        visibility: sortBy === option.value ? 'visible' : 'hidden'
                      }}>
                      {sortDirection === 1 && <Iconify icon='eva:arrow-down-fill' />}
                      {sortDirection === -1 && <Iconify icon='eva:arrow-up-fill' />}
                    </span>
                  </ButtonBar>
                ))}
              </Filters>
            </FiltersBar>
            {sortedFilterGames.length === 0 && searchText !== '' && (
              <div className='text-center mt-2'>No se han encontrado slots con el texto <strong>{searchText}</strong></div>
            )}
            <div className='inner-grid'>
              {sortedFilterGames.map(game => (
                <GameCard
                  key={game.id}
                  game={game}
                  setGameSlug={setGameSlug}
                  gameSlug={gameSlug}
                  activeGame={activeGame}
                  gameOpened={gameOpened}
                  setGameOpened={setGameOpened}
                  setIsDemo={setIsDemo}
                  isDemo={isDemo}
                />
              ))}
            </div>
          </Sections>
        </div>
      </div>

      <Modal
        show={showMyGames}
        className='absoluteModal'
        dialogClassName='modal-dialog-xxxs'
      >
        <div>
          <div id='panelTitle' className='panel-abonar-titulo'>
            <span>Historial</span>
            <button className='cerrar-panel' onClick={() => setShowMyGames(false)}>
              <div className='btn-icon-close'>
                <i className='ion-ios-close-empty' />
              </div>
            </button>
          </div>
        </div>
        <MyPlays />
      </Modal>
      {Boolean(activeGame) && (
        <GameContainer
          game={activeGame}
          gameOpen={activeGame?.name}
          demo={isDemo}
          onGameClose={closeGameHanldler}
        />
      )}
    </PageWrapper>
  )
}

export default Slots


const ButtonBar = styled.button`
    background: #337ab7;
    border: 1px solid #FFF;
    border-radius: 1em;
    background: black;
    color: #fff;
    padding: 0 5px;
    font-family: 'Chivo';
    font-size: 1em;
    width: 100%;
    text-shadow: 1px 1px 1px #00000047;
    height: 2.2em;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.5em;

    &:hover {
      background: linear-gradient(358deg, #535353, #999999);
    }

    &.active {
      background: linear-gradient(358deg, #78b529, #219c26);
    }

     & .icon {
      width: 3em;
    }

    & .count {
      flex-grow: 9;
      text-align: right;
    }

    & .title {
      height: 100%;
      display: inline-flex;
      align-items: center;
    }
`

const FiltersBar = styled.div`
  width: 100%;
  min-height: 4em;
  background-color: #000000;
  top: 0;
  border-top: 1px solid #292929;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  padding: 0.5em 0;
`

const Filters= styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  background-color: transparent;
  border: 1px solid gray;
  padding: 0.4em 1em;
  border-radius: 0.2em;
  outline: 0;

  &:focus {
    border: 1px solid #78bc28;
  }
`
