// Libraries
import React from "react";
import moment from "moment";

// Components
import Clock from '../Clock/index';

const BracketPick = ({ match, pick, onPickChange, onGameChange, bracket, side, teamList, tieBreakers, onSelectTie, bracketUserTieBreakers, bracketEntry }) => {
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  var sumWons = 0;

  React.useEffect(() => {
    if (pick.pick && pick.pick == match.home_team_id) {
      setSelectedTeam(match.home_team);
    }
    if (pick.pick && pick.pick == match.away_team_id) {
      setSelectedTeam(match.away_team);
    }

    sumWons = match.games_won_away + match.games_won_home;
  }, [pick.pick]);

  const SelectTeam = ({ teamList, tieId, enabled }) => {
    let teamBreaker;
    if (bracketUserTieBreakers) {
      teamBreaker = teamList.find(team => team.id === bracketUserTieBreakers[tieId].value);
    }
    return (
      <>
        {enabled &&
          <select
            className="bracket-game-select ties"
            value={bracketUserTieBreakers && bracketUserTieBreakers[tieId].value}
            onChange={(e) => {onSelectTie(e, tieId)} }
            disabled={!enabled}
          >
            <option value=""></option>
            {teamList && teamList.map((team, i) => { return (<option value={Number(team.id)} key={i}>{team.name}</option>)  })}
          </select>
        ||
          <label>{teamBreaker !== undefined ? teamBreaker.name : 'ND'}</label>
        }
      </>
    );
  }

  // Bracket Pick status returns (correct|incorrect) are css clases
  const getPickStatus = () => {
    if (bracket.status === 'upcoming') return ''

    if (match.sort_status !== 1 && match.winning_pick === pick.pick && match.winning_game === pick.winning_game) return 'correct'

    if ((sumWons >= 4 && match.winning_game === pick.winning_game) && (sumWons < pick.winning_game)) {
      return 'correct'
    }
  
    return 'incorrect'
  }


  return (
    <li className="tournament-bracket__item">
      <div className="tournament-bracket__match">
        <div className={`match-content
          ${match.sort_status !== 1 && match.winning_game !== null && match.winning_game === pick.winning_game && match.winning_pick === pick.pick && 'correct'}
          ${match.sort_status !== 1 && match.winning_pick !== pick.pick && 'incorrect'}
          ${match.winning_pick === null && match.winning_game === 0 && selectedTeam !== null && 'selected'}
        `}>
          <div className="match-header font-chivo text-bold text-italic uppercase text-small">
            CIERRE
            <span className="pull-right">
              {match.sort_status === 1 && (
                <>
                  {match.time_left > 3600 && moment(match.deadline).format("ddd DD, MMM HH:mm")}
                  {match.time_left > 0 && (match.time_left) <= 3600 &&
                    <div>
                      <Clock type="row" poolId={bracket.id} fixtureID={0} closingDate={match.deadline} />
                    </div>
                  }
                </>
              )}
              {match.sort_status !== 1 && moment(match.deadline).format("ddd DD, MMM HH:mm")}
            </span>
          </div>

          <div className={`fixture-picks flex-row row-center
            ${match.winning_pick != null && match.winning_pick === pick.pick && 'correct'}
            ${match.winning_pick != null && match.winning_pick !== pick.pick && 'incorrect'}
          `}>
            <div className="flex-col">
              {/* <!-- away --> */}
              {(match.away_team && (
                <div className="flex-row row-center">
                  <div className="flex-col">
                    <label
                      htmlFor={`bracket-pick-away-${match.id}`}
                      className={`pick ${ pick.pick == match.away_team_id ? 'checked' : null} `}
                    >
                      <div className="flex-col">
                        <i className={`quina ${match.away_team.logo}`}></i>
                      </div>
                      <div className="flex-col">
                        <div className="text-bold ff-chivo text-italic">
                          {match.away_team.abbreviation}
                        </div>
                        <div>
                          {match.away_team.team_stats &&
                            match.away_team.sport_id != 1 && (
                              <small>
                                ({match.away_team.team_stats.win}-
                                {match.away_team.team_stats.lost})
                              </small>
                            )}
                          {match.away_team.team_stats &&
                            match.away_team.sport_id == 1 && (
                              <small>
                                ({match.away_team.team_stats.win}-
                                {match.away_team.team_stats.ties}-
                                {match.away_team.team_stats.lost})
                              </small>
                            )}
                        </div>
                      </div>
                      <div className="flex-col text-bold text-large text-italic ff-chivo">
                        {match.games_won_away}
                      </div>
                      <div className="flex-col">
                        <input
                          id={`bracket-pick-away-${match.id}`}
                          disabled={!match.enabled}
                          name={`bracket-pick-${match.id}`}
                          type="radio"
                          value={match.away_team_id}
                          checked={pick.pick === match.away_team_id}
                          onChange={() => {}}
                          onClick={() =>
                            onPickChange(match.id, match.away_team_id)
                          }
                        />
                        <a className="pick-helper"></a>
                      </div>
                    </label>
                  </div>
                </div>
              )) || (
                <div className="team-placeholder flex-row row-center">
                  <div className="flex-col text-bold ff-chivo text-italic">
                      {/* switchear por deportes */}
                      {/* <i class="quina" ng-class="{
                      'team-gen-soccer':     match.sport_id == 1,
                      'team-gen-football':   match.sport_id == 2,
                      'team-gen-basketball': match.sport_id == 3,
                      'team-gen-baseball':   match.sport_id == 4,
                    }"></i> */}
                    <i className="quina team-gen-basketball"></i> PD
                  </div>
                </div>
              )}

              <div className="h-divider"></div>

              {/* <!-- home --> */}
              {(match.home_team && (
                <div className="flex-row row-center">
                  <div className="flex-col">
                    <label
                      htmlFor={`bracket-pick-home-${match.id}`}
                      className={`pick ${ pick.pick == match.home_team_id ? 'checked' : null} `}
                    >
                      <div className="flex-col">
                        <i className={`quina ${match.home_team.logo}`}></i>
                      </div>
                      <div className="flex-col">
                        <div className="text-bold ff-chivo text-italic">
                          {match.home_team.abbreviation}
                        </div>
                        <div>
                          {match.home_team.team_stats &&
                            match.home_team.sport_id != 1 && (
                              <small>
                                ({match.home_team.team_stats.win}-
                                {match.home_team.team_stats.lost})
                              </small>
                            )}
                          {match.home_team.team_stats &&
                            match.home_team.sport_id == 1 && (
                              <small>
                                ({match.home_team.team_stats.win}-
                                {match.home_team.team_stats.ties}-
                                {match.home_team.team_stats.lost})
                              </small>
                            )}
                        </div>
                      </div>
                      <div className="flex-col text-bold text-large text-italic ff-chivo">
                        {match.games_won_home}
                      </div>
                      <div className="flex-col">
                          <input
                            id={`bracket-pick-home-${match.id}`}
                            disabled={!match.enabled}
                            name={`bracket-pick-${match.id}`}
                            type="radio"
                            value={match.home_team_id}
                            checked={pick.pick === match.home_team_id}
                            onChange={() => {}}
                            onClick={() =>
                              onPickChange(match.id, match.home_team_id)
                            }
                          />
                          <a className="pick-helper"></a>
                      </div>
                    </label>
                  </div>
                </div>
              )) || (
                <div className="team-placeholder flex-row row-center">
                  <div className="flex-col text-bold ff-chivo text-italic">
                    <i className="quina team-gen-basketball"></i> PD
                  </div>
                </div>
              )}

              {match.home_team_id && match.away_team_id && (
                <>
                  <div className="h-divider"></div>
                  <div className={`flex-row row-center pick-game ${getPickStatus()}`}>
                    <div className="flex-col col-70 text-bold ff-chivo text-italic text-small text-center uppercase">
                      {selectedTeam !== null && (
                        <span className="underline">{selectedTeam.abbreviation} en el Juego</span>
                      )}

                      {selectedTeam === null && <span>En el juego:</span>}
                    </div>
                    <div className="flex-col">
                      <select
                        className="bracket-game-select"
                        value={pick.winning_game !== null ? pick.winning_game : ''}
                        onChange={(e) => onGameChange(match.id, e.target.value)}
                        disabled={!match.enabled}
                      >
                        <option value=""></option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {side === 'center' &&
          <div className="match-content tie-breakers">
            <div className="match-header font-chivo text-bold text-italic uppercase text-small">
              Desempates
              <span className="pull-right">
              {moment(bracket.deadline).format("ddd DD, MMM HH:mm")}
            </span>
            </div>
            <div className="fixture-picks flex-row row-center">
              <div className="flex-col">
                {tieBreakers.map(tie => {
                  return (
                    <div className="flex-row row-center m-05" key={tie.id}>
                      <div className="flex-col font-chivo text-bold text-italic">
                        <div className="tie-item">
                          <div className="col-50">{tie.label}</div>
                          <div className="col-50 text-right flex-container justify">
                            <SelectTeam teamList={ teamList } tieId={tie.id} enabled={tie.enabled} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      </div>
    </li>
  );
};

export default BracketPick;
