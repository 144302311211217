import React, { useState } from 'react'
import moment from 'moment'
import numeral from 'numeral'
import _ from 'underscore'
import styled from 'styled-components'
import { Button } from 'react-bootstrap/lib'

// Components
import ChangePass from './ChangePass'
import TicketsList from './TicketsList'
import MyDocuments from './MyDocuments'
import UploadAvatar from './UploadAvatar'

// Utils
import utils from '../../../../../utils/utils'

// Assets
import billete from '../../../../../assets/images/cash1-50.png'
import avatar from '../../../../../assets/images/avatar-100.png'
import pickcoin from '../../../../../assets/images/pickcoin1-50.png'
import misDocs from '../../../../../assets/images/documentosIconBlanco-50.png'
import tickets from '../../../../../assets/images/silver_ticket_pick_empty.png'

export default function MyAccount (props) {
  const [showPass, setShowPass] = useState(false)
  const [showAvatarModal, setShowAvatarModal] = useState(false)

  return (
    <div>
      <Box>
        <div className='info-personal col-md-6'>
          <div className='flex-col col-no-padding'>
            <div className='personal-data '>
              <div className='personaldata-row '>
                <span>{props.userData.full_name}</span>
              </div>
              <div className='personaldata-row'>
                <span>{props.userData.nick}</span>
              </div>
              <div className='personaldata-row'>
                <span style={{ color: '#87b13d' }}>{props.userData.email}</span>
              </div>
              <div className='personaldata-row'>
                <span>{moment(props.userData.created).fromNow(moment())}</span>
              </div>
            </div>
            <div className='editar-info'>
              <div className='editarinfoPersonal' onClick={() => setShowPass(p => !p)}>
                <i className='ion-edit' />
                <span>Editar contraseña</span>
              </div>
            </div>
          </div>
        </div>
        <div className='user-picture col-md-6'>
          <div className='user-info-personal-container'>
            <div className='picture-container'>
              <img
                src={
                  !utils.getUserInfo().hasOwnProperty('avatar')
                    ? avatar
                    : utils.getUserInfo().avatar === avatar
                      ? avatar
                      : utils.getUserInfo().avatar_dir + utils.getUserInfo().avatar
                }
                className='user-info-avatar img-resposive'
                alt=''
              />
              <div className='btn-cambiar-avatar' onClick={() => setShowAvatarModal(true)}>
                <i className='ion-camera' />
                <span> Cambia tu avatar</span>
              </div>
            </div>
          </div>

          <UploadAvatar
            disableClickedButton={props.disableClickedButton}
            showCambiarAvatar={showAvatarModal}
            updatePanelState={() => setShowAvatarModal(false)}
            userData={props.userData}
            updatedAvatar={() => setShowAvatarModal(true)}
          />
        </div>
      </Box>
      

      <ChangePass
        showPass={() => setShowPass(p => !p)}
        showChangePass={showPass}
      />

      <div className='category-user row'>
        <div className='detalles-cuenta'>
          <div className='titulo-user-info col-xs-10 col-xs-offset-1'>
            <div className='flex-col'>
              <span>Detalles de Cuenta</span>
            </div>
          </div>
          <div className='flex-col col-90 col-offset-5'>
            <div className='flex-row row-center '>
              <div className='user-info-real flex-container justify align  column '>
                <div className=''>
                  <div className='flex-row row-center '>
                    <img src={billete} className='pool-icons' alt='' />
                    <span>Balance :</span>
                  </div>
                  <div className='flex-row row-center '>
                    <strong className='flex-col text-center'>
                      {numeral(utils.getUserBalance().balanceReal / 100).format(
                        '$0,000.00'
                      )}
                      MXN
                    </strong>
                  </div>
                </div>
              </div>
              <div className='user-info-real flex-container justify align column '>
                <div className=''>
                  <div className='flex-row row-center '>
                    <img src={pickcoin} className='pool-icons' alt='' />
                    <span>Balance :</span>
                  </div>
                  <div className='flex-row row-center'>
                    <strong className='flex-col text-center'>
                      {numeral(utils.getUserBalance().balancePc / 100).format('0,000')}
                      PC
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-row row-center' style={{ marginTop: '8px' }}>
              <div className='deposito-btn flex-col col-33 col-offset-33'>
                <Button
                  className='btn-cuenta flex-row row-no-padding'
                  bsStyle='success'
                  onClick={props.onShowAddBalance}
                >
                  <span className='text-center flex-col col-center'>DEPOSITAR</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='category-user row '>
        <div className='mis-documentos'>
          <div className='titulo-user-info col-xs-10 col-xs-offset-1'>
            <div className='flex-col col-15'>
              <img src={misDocs} className='title-icon' alt='' />
            </div>
            <div className='flex-col'>
              <span>Mis Identificaciones</span>
            </div>
          </div>
          <div className='mis-documentos-data'>
            <div className='documentos-guardados'>
              {props.misDocumentos !== null &&
              props.misDocumentos.documents !== undefined &&
              props.misDocumentos.documents !== null &&
              props.misDocumentos.documents.length > 0
                ? (
                  <MyDocuments documents={props.misDocumentos} />
                  )
                : (
                  <div className='flex-container justify align'>
                    {' '}
                    <span>No tienes ningún documento guardado</span>{' '}
                  </div>
                  )}
            </div>
          </div>
        </div>
      </div>
      <div className='category-user row'>
        <div className='detalles-cuenta'>
          <div className='titulo-user-info col-xs-10 col-xs-offset-1'>
            <div className='flex-col col-15'>
              <img src={tickets} className='title-icon' alt='' />
            </div>
            <div className='flex-col'>
              <span>Mis Tickets</span>
            </div>
          </div>
          <div className='col-xs-12 col-lg-10 col-lg-offset-1'>
            <TicketsList
              ticketsList={props.misTickets}
              handleTicket={() => {
                console.log('Test')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}


const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
  border-top: #424041 0.2px solid;
`
