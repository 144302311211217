import styled from 'styled-components'
import React from 'react'

// Components
import TableInfo from './TableInfo'

// Assets
import pleca from '../assets/pleca3.svg'
import bgGrayPattern from '../assets/bg-pw.svg'
import imageTitle from '../assets/invitados-text.svg'

const BannerInfo = () => {
  return (
    <Box>
      <Header>
        <Separator />
        <ImageTitle src={imageTitle} />
        <Title>PROGRAMA DE INVITADOS</Title>
        <Separator />                                             
        <PlecaBox />
      </Header>
      <TableInfo />
    </Box>
  )
}

const Header = styled.div`
  height: 4.2em;
  position: relative;
  z-index: 10;
  overflow: hidden;
`

const ImageTitle = styled.img`
  width: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 750px){
    width: 50%;
  }
`


const PlecaBox = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  height: 3em;
  position: absolute;
  top: 0px;
  z-index: -1;
  height: 100%;
  width: 100%;
  left: 0;
  background: url(${pleca});
  background-repeat: no-repeat;
  background-size: cover;
`

const Box = styled.div`
  padding: 1em;
  border: 2px solid #575757;
  border-radius: 0.6em;
  padding: 1em 0;
  margin: 1em 0;
  position: relative;
  z-index: 3;
  background: url(${bgGrayPattern});
  background-size: 120%;
`


const Title = styled.h3`
  /* background: #F9E799; */
  /* background: linear-gradient(to bottom, #F9E799 41%, #C8AE72 50%, #F2E3A3 64%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  line-height: 2.2;
  margin: 0;
  color: transparent;
`

const Separator = styled.div`
  width: 100%;
  height: 0.2em;
  background-color: red;
  margin: 0 auto;
  background: radial-gradient(rgb(242, 227, 163), rgb(115 102 46),transparent, transparent);
`


export default BannerInfo
