/** OBJECT VALUES POLYFILL */
if (!Object.values || Object.values === undefined) {
  Object.values = (obj) => {
    if (obj === undefined || obj === null) {
      return;
    }
    Object.keys(obj).map((key) => obj[key])
  }
}

