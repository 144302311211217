import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import numeral from 'numeral'
import propTypes from 'prop-types'

GlowText.defaultProps = {
  // Start number
  start: 0,
  // End or target number
  end: 20000,
  // Duration in seconds to complete the animation
  duration: 4,
  // Frames or calcules per second
  fps: 60,
  // If is count up
  animated: false,
  // If is shinning effect
  shine: false,
  // Delay for count up
  delay: 2000,
  // Number of glow
  glow: 2,
  sphereSize: 35,
  color1: '#62f527',
  color2: '#b2ff00',
  fontSize: 55
}

GlowText.propTypes = {
  start: propTypes.number,
  end: propTypes.number,
  duration: propTypes.number,
  fps: propTypes.number,
  animated: propTypes.bool,
  shine: propTypes.bool,
  delay: propTypes.number,
  glow: propTypes.number,
  sphereSize: propTypes.number,
  color1: propTypes.string,
  color2: propTypes.string,
  fontSize: propTypes.number
}

export default function GlowText (props) {
  const {
    animated,
    start,
    end,
    fps,
    delay,
    duration,
    glow,
    color1,
    color2,
    shine,
    fontSize
  } = props

  const [num, setNum] = useState(
    animated
      ? start
      : end
  )

  const tick = useRef()

  useEffect(() => {
    if (animated) {
      const realFPS = 1000 / fps
      const stepsCount = duration * 1000 / realFPS
      const step = (end - start) / stepsCount

      window.setTimeout(() => {
        tick.current = window.setInterval(() => {
          setNum(prev => {
            const next = Math.round(prev + step)

            if (next >= end) {
              window.clearInterval(tick.current)
              tick.current = null
            }

            return next <= end ? next : end
          })
        }, realFPS)
      }, delay)
    }
  }, [animated, delay])

  let currency = Math.round(num)

  if (props.currency) {
    currency = numeral(num).format('$0,000')
  }

  return (
    <Box>
      <Text data-text={currency} glow={glow} style={{ fontSize }}>
        {currency}
      </Text>

      <Gradient style={{ background: `linear-gradient(45deg, ${color1}, ${color2})` }} />
      {shine && (
        <Mask>
          <Dodge style={{ background: `radial-gradient(circle, white, black ${props.sphereSize}%) center / 25% 25%` }}/>
        </Mask>
      )}
    </Box>
  )
}

const Box = styled.div`
  position: relative;
  background-color: #000000;
  width: fit-content;
  filter: contrast(110%) brightness(190%);
`

const Text = styled.span`
  color: #fff;
  background-color: #000;
  font-size: 60px;
  font-weight: bold;
  font-family: Arial;
  text-transform: uppercase;

  :before {
    content: attr(data-text);
    position: absolute;
    mix-blend-mode: difference;
    filter: ${p => `blur(${p.glow}px)`};
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 10;
  background: linear-gradient(45deg, #62f527, #b2ff00);
  mix-blend-mode: multiply;
`

const Anima = keyframes`
  100% {
    transform: translate3d(50%, 50%, 0);
  }
`

const Dodge = styled.div`
  background: radial-gradient(circle, white, black 35%) center / 25% 25%;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -100%;
  left: -100%;
  mix-blend-mode: color-dodge;
  animation: ${Anima} 5s infinite linear;
`

const Mask = styled.div`
  overflow: hidden;
  width: 100%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 0;
  bottom: 0;
  right: 0;
`
