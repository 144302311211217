import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import $ from 'jquery';

import utils from '../../utils/utils';

import {
  DisplaySport,
  DisplayIconsType,
  DisplayFlag,
  DisplayTypes,
  DisplayEntries,
  DisplayDate,
  DisplayBolsa,
  RowBadge,
} from '../../utils/quinielasComponents';

import DisplayEstatus from '../DisplayEstatus';

class RowLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathGame: null,
      userData: this.props.userDataProps,
      showModal: false,
      showPanel: this.props.showPanel,
      pool: this.props.pool,
      poolId: this.props.pool.id,
      updateStateFunc: this.handleRegistrationClick,
      updatedPool: null,
      enoughBalance: false,
    };
    moment.locale('es');
    this.actionLive = this.actionLive.bind(this);
    this.getPathName = (pool) => {
      let pathGame = null;
      switch (pool.game_type) {
        case 'pool':
          pathGame = 'quiniela';
          break;
        case 'bracket':
          switch (Number(pool.sport_id)) {
            case 3:
              pathGame = 'bracket';
            break;
            case 5:
              pathGame = 'bracketTennisLobby';
            break;
            case 1:
              if (pool.type === "perfect") {
                pathGame = 'bracketPerfect';
              }
              if (pool.type === 'points') {
                pathGame = 'bracketPoints';
              }
            break;
          }

          break;
        case 'survivor':
          pathGame = 'survivor';
          break;
        case 'group_pool':
          pathGame = 'quinielaGrupo';
          break;
      }
      // console.log( 'pathGame', pathGame, pool );
      return pathGame;
    };

    this.isBalanceEnough = () => {
      let balanceEnough = utils.isBalanceEnough(
        this.props.pool.entry_currency,
        this.props.pool.entry_fee
      );

      this.setState({
        enoughBalance: balanceEnough,
      });
    };
  }

  actionLive(poolId) {
    let poolUpdated = $('ul[data-id=' + poolId + ']');
    // console.log(poolUpdated);
    if (this.props.updatedPool.id === poolId) {
      poolUpdated.removeClass('updated-live');
      poolUpdated.addClass('updated-live');
    }
    setTimeout(() => {
      poolUpdated.removeClass('updated-live');
    }, 1500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updatedPool) {
      if (this.props.updatedPool.id === this.props.pool.id) {
        this.actionLive(this.props.pool.id);
      }
    }

    if (
      this.props.multyEntry.length !== prevProps.multyEntry.length ||
      (this.props.multyEntryChanged && !prevProps.multyEntryChanged)
    ) {
      this.setState(
        {
          userData: this.props.userDataProps,
          pool: this.props.pool,
          poolId: this.props.pool.id,
        },
        () => {
          this.isBalanceEnough();
        }
      );
    }

    if (
      (!prevProps.logged && this.props.logged) ||
      this.props.updatePools !== prevProps.updatePools ||
      this.props.poolsChanged !== prevProps.poolsChanged
    ) {
      // console.log('Row Lobby Update');
      this.setState(
        {
          userData: this.props.userDataProps,
          showPanel: this.props.showPanel,
          pool: this.props.pool,
          poolId: this.props.pool.id,
        },
        () => {
          this.isBalanceEnough();
        }
      );
    }
  }

  componentDidMount() {
    this.setState(
      {
        pathGame: this.getPathName(this.props.pool),
        updateStateFunc: this.handleRegistrationClick,
      },
      () => {
        this.isBalanceEnough();
      }
    );
  }

  render() {
    // console.log('RowLobby',!utils.isLogged(), !this.state.enoughBalance, this.props.pool.is_full );

    let isMultyRegisterDisabled =
      !utils.isLogged() ||
      !this.state.enoughBalance ||
      this.props.pool.is_full ||
      this.props.pool.status !== 'upcoming';
    // let isMultyRegisterDisabled = true;
    let hasEntries = this.props.pool.hasOwnProperty('first_entry');
    let itStarted = this.props.pool.status !== 'upcoming';
    let isSelectedMulty = false;
    if (this.props.multyEntry && this.props.multyEntry.length > 0) {
      this.props.multyEntry.forEach((mE) => {
        if (mE.idPool === this.props.pool.id) {
          isSelectedMulty = true;
        }
      });
    }

    // console.log( isSelectedMulty, this.props.pool.name_replaced)
    // console.log( "  hasEntries" );
    // console.log( hasEntries );
    // console.log( " ItStarted" );
    // console.log( itStarted );
    const pool = this.props.pool;
    let conditionalComponent = null;

    if (this.state.pool !== undefined) {
      conditionalComponent = (
        <React.Fragment>
          {$(window).width() >= 769 ? null : (
            <div
              data-game-type={pool.game_type}
              data-id={pool.id}
              id={pool.limited_capacity ? 'limited-capacity' : ''}
              className=" pool-item pool-row-mobile row "
              style={$(window).width() < 769 ? {} : { display: 'none' }}
              onClick={(e) => {
                if (hasEntries || itStarted) {
                  window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                    hasEntries ? pool.first_entry.id : ''
                  }`;
                } else {
                  this.props.handleClickPool(pool.id, pool.game_type, e);
                }
              }}
            >
              <div
                data-id={pool.id}
                data-game-type={pool.game_type}
                className="row pool-item-upper"
              >
                <div
                  data-id={pool.id}
                  data-game-type={pool.game_type}
                  className="pool-category quinielas mobile"
                >
                  <div
                    data-id={pool.id}
                    data-game-type={pool.game_type}
                    className="content-category"
                  >
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="title-wrapper"
                    >
                      <div
                        data-id={pool.id}
                        data-game-type={pool.game_type}
                        className="deporte-wrapper"
                      >
                        <DisplaySport id={pool.id} sports={pool.sport} type="pool" />
                        <DisplayIconsType
                          id={pool.id}
                          type={pool.game_type}
                          isPick5={pool.hasOwnProperty('max_picks') && pool.max_picks !== null}
                          variation={pool.variation}
                        />
                      </div>
                      <div
                        data-id={pool.id}
                        data-game-type={pool.game_type}
                        className="flag-wrapper"
                      >
                        <DisplayFlag type="pool" flag={pool} />
                      </div>
                      <span
                        className={`
                          flex-col col-no-padding
                          ${pool.featured === 1 || pool.featured === true ? 'featured-name' : ''}
                          ${
                            (pool.featured === 1 || pool.featured === true) && pool.sort_order === 1
                              ? 'special-featured'
                              : ''
                          }
                        `}
                        data-id={pool.id}
                        data-game-type={pool.game_type}
                      >
                        {pool.name_replaced}
                      </span>
                    </div>
                    <div data-id={pool.id} data-game-type={pool.game_type} className="type-wrapper">
                      <RowBadge pool={pool} label={'M'} condition={'max_multientries'} />
                      <DisplayTypes id={pool.id} format={pool} />
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-id={pool.id}
                data-game-type={pool.game_type}
                className="flex-row pool-item-lower"
              >
                <div
                  data-id={pool.id}
                  data-game-type={pool.game_type}
                  className="flex-col flex-col-20  col-no-padding "
                >
                  <div
                    data-id={pool.id}
                    data-game-type={pool.game_type}
                    className="flex-container justify align column"
                  >
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="title-category"
                    >
                      Entrada
                    </div>
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="content-category"
                    >
                      <DisplayEntries id={pool.id} entries={pool} />
                    </div>
                  </div>
                </div>
                <div
                  data-id={pool.id}
                  data-game-type={pool.game_type}
                  className="flex-col col-no-padding col-25"
                >
                  <div
                    data-id={pool.id}
                    data-game-type={pool.game_type}
                    className="flex-container justify align column"
                  >
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="title-category"
                    >
                      Bolsa
                    </div>
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="content-category"
                    >
                      <DisplayBolsa poolInfo={pool} />
                      {pool.hasOwnProperty('perfect_prize') && pool.perfect_prize !== null ? (
                        <span
                          style={{ fontSize: '11px', color: '#fff900', letterSpacing: '-0.0125em' }}
                          className="uppercase"
                        >
                          +({numeral(pool.perfect_prize / 100).format('($0a)')} PERF)
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  data-id={pool.id}
                  data-game-type={pool.game_type}
                  className="flex-col col-no-padding col-20"
                >
                  <div
                    data-id={pool.id}
                    data-game-type={pool.game_type}
                    className="flex-container justify align column"
                  >
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="title-category text-center"
                    >
                      Reg.
                    </div>
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="content-category"
                    >
                      <span data-id={pool.id} data-game-type={pool.game_type}>
                        {pool.entry_count} {pool.pickngo ? `/ ${pool.max_entries}` : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  data-id={pool.id}
                  data-game-type={pool.game_type}
                  className="flex-col col-no-padding"
                >
                  <div
                    data-id={pool.id}
                    data-game-type={pool.game_type}
                    className="flex-container justify align column"
                  >
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="title-category"
                    >
                      Cierre
                    </div>
                    <div
                      data-id={pool.id}
                      data-game-type={pool.game_type}
                      className="content-category"
                    >
                      <span data-id={pool.id} data-game-type={pool.game_type}>
                        <DisplayDate
                          changeStatusPool={this.props.changeStatusPool}
                          mobile="true"
                          id={pool.id}
                          fecha={pool.deadline}
                          poolInfo={pool}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div data-id={pool.id} data-game-type={pool.game_type} className="button-wrapper">
                <div
                  data-id={pool.id}
                  data-game-type={pool.game_type}
                  className="col-xs-12 pool-category estatus mobile"
                >
                  <DisplayEstatus
                    dataId={pool.id}
                    handleMultyEntry={this.handleMultyEntry}
                    isMobile={true}
                    items={this.state.itemSelected}
                    logged={this.props.logged}
                    pool={pool}
                    quinaID={pool.id}
                    showLoginPopUp={this.props.showLoginPopUp}
                    showPanel={this.props.showPanel}
                    updateStateFunc={this.props.updateStateFunc}
                    updateStateFuncCancel={this.props.updateStateFuncCancel}
                    userDataProps={this.props.userDataProps}
                  />
                </div>
              </div>
            </div>
          )}
          {$(window).width() < 769 ? null : (
            <>
            <ul
              data-game-type={pool.game_type}
              data-id={pool.id}
              id={pool.limited_capacity ? 'limited-capacity' : ''}
              className={`pool-item pool-row row `}
              style={$(window).width() >= 769 ? {} : { display: 'none' }}
            >
              <li data-id={pool.id} className="pool-category deporte" onClick={(e) => {
                if (hasEntries || itStarted) {
                  window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                    hasEntries ? pool.first_entry.id : ''
                  }`;
                } else {
                  this.props.handleClickPool(pool.id, pool.game_type, e);
                }
              }}>
                <DisplaySport
                  id={pool.id}
                  sports={pool.sport}
                  type={pool.game_type === 'group_pool' ? 'group_pool' : 'pool'}
                />
                <DisplayIconsType
                  id={pool.id}
                  type={pool.game_type}
                  isPick5={pool.hasOwnProperty('max_picks') && pool.max_picks !== null}
                  variation={pool.variation}
                />
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category quinielas flex-row row-no-padding overflow-words"
                onClick={(e) => {
                  if (hasEntries || itStarted) {
                    window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                      hasEntries ? pool.first_entry.id : ''
                    }`;
                  } else {
                    this.props.handleClickPool(pool.id, pool.game_type, e);
                  }
                }}
              >
                <DisplayFlag type="pool" flag={pool} />
                <span
                  data-game-type={pool.game_type}
                  className={`
                    flex-col col-no-padding col-80 overflow-words
                    ${pool.featured === 1 || pool.featured === true ? 'featured-name' : ''}
                    ${
                      (pool.featured === 1 || pool.featured === true) && pool.sort_order === 1
                        ? 'special-featured'
                        : ''
                    }
                  `}
                  data-id={pool.id}
                >
                  {pool.name_replaced}
                </span>
              </li>
              <li data-game-type={pool.game_type} data-id={pool.id} className="pool-category tipo" onClick={(e) => {
                if (hasEntries || itStarted) {
                  window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                    hasEntries ? pool.first_entry.id : ''
                  }`;
                } else {
                  this.props.handleClickPool(pool.id, pool.game_type, e);
                }
              }}>
                <DisplayTypes id={pool.id} format={pool} />
                <RowBadge pool={pool} label={'M'} condition={'max_multientries'} />
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category entradas"
                onClick={(e) => {
                  if (hasEntries || itStarted) {
                    window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                      hasEntries ? pool.first_entry.id : ''
                    }`;
                  } else {
                    this.props.handleClickPool(pool.id, pool.game_type, e);
                  }
                }}
              >
                <DisplayEntries id={pool.id} entries={this.state.pool} />
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category bolsa "
                onClick={(e) => {
                  if (hasEntries || itStarted) {
                    window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                      hasEntries ? pool.first_entry.id : ''
                    }`;
                  } else {
                    this.props.handleClickPool(pool.id, pool.game_type, e);
                  }
                }}
              >
                <DisplayBolsa poolInfo={pool} />
                {pool.hasOwnProperty('perfect_prize') && pool.perfect_prize !== null ? (
                  <div
                    style={{ fontSize: '11px', color: '#fff900', letterSpacing: '-0.0125em' }}
                    className="uppercase"
                  >
                    +({numeral(pool.perfect_prize / 100).format('($0a)')} PERF)
                  </div>
                ) : null}
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category registro"
                onClick={(e) => {
                  if (hasEntries || itStarted) {
                    window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                      hasEntries ? pool.first_entry.id : ''
                    }`;
                  } else {
                    this.props.handleClickPool(pool.id, pool.game_type, e);
                  }
                }}
              >
                <span data-game-type={pool.game_type} data-id={pool.id}>
                  {this.state.pool.entry_count}
                  {this.state.pool.pickngo === true ? ` / ${this.state.pool.max_entries}` : ''}
                </span>
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category cierre "
                onClick={(e) => {
                  if (hasEntries || itStarted) {
                    window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                      hasEntries ? pool.first_entry.id : ''
                    }`;
                  } else {
                    this.props.handleClickPool(pool.id, pool.game_type, e);
                  }
                }}
              >
                <span className="flex-col" data-game-type={pool.game_type} data-id={pool.id}>
                  <DisplayDate
                    changeStatusPool={this.props.changeStatusPool}
                    changePoolStatus={this.props.changePoolStatus}
                    id={pool.id}
                    fecha={pool.deadline}
                    poolInfo={pool}
                  />
                </span>
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category estatus"
                onClick={(e) => {
                  if (hasEntries || itStarted) {
                    window.location = `/${this.getPathName(pool)}/${pool.slug}/${pool.id}/${
                      hasEntries ? pool.first_entry.id : ''
                    }`;
                  } else {
                    this.props.handleClickPool(pool.id, pool.game_type, e);
                  }
                }}
              >
                <DisplayEstatus
                  data-id={pool.id}
                  handleMultyEntry={this.handleMultyEntry}
                  isMobile={false}
                  items={this.state.itemSelected}
                  logged={this.props.logged}
                  pool={this.state.pool}
                  quinaID={this.state.poolId}
                  showPanel={this.props.showPanel}
                  showPanelCancel={this.props.showPanelCancel}
                  updateStateFunc={this.props.updateStateFunc}
                  updateStateFuncCancel={this.props.updateStateFuncCancel}
                  userDataProps={this.props.userDataProps}
                  showLoginPopUp={this.props.showLoginPopUp}
                />
              </li>
              <li
                data-game-type={pool.game_type}
                data-id={pool.id}
                className="pool-category registrate hidden-sm"
              >
                <label data-tip data-for="multyEntry" data-game-type={pool.game_type} id={pool.id}>
                  <input
                    data-game-type={pool.game_type}
                    data-id={pool.id}
                    type="checkbox"
                    name={pool.id}
                    id={`${pool.id}`}
                    disabled={isMultyRegisterDisabled}
                    value={pool.id}
                    checked={isSelectedMulty}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.handleMultyEntry(e);
                    }}
                  />
                  <div
                    data-game-type={pool.game_type}
                    className={`checkbox-custom ${isMultyRegisterDisabled ? 'disabled' : ''}`}
                  />
                </label>
              </li>
            </ul>
            </>
          )}
        </React.Fragment>
      );
    }
    return conditionalComponent;
  }
}
export default RowLobby;
