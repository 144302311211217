import React from 'react'
import styled from 'styled-components'
import { PulseLoader } from 'react-spinners'

// Components
import ProductCard from './components/ProductCard'
import Filters from './components/Filters'
import Text from '../Text'
import ConfirmModalPurchase from './components/ConfirmModalPurchase'
import BannerStore from './components/BannerStore'

// Hooks
import useProducts from './hooks/useProduts'

export default function Store () {
  const {
    productsFiltered,
    categories,
    error,
    loading,
    activeFilter,
    setActiveFilter,
    setActiveProduct,
    activeProduct,
    balanceVip,
    purchaseSuccess
  } = useProducts()

  return (
    <Content>
      <BannerStore balanceVip={balanceVip} />
      <Filters
        categories={categories}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      {error && <Text color='red' center>Ha ocurrido un error al obtener los productos</Text>}
      {loading && (
        <PulseLoader
          color='#FFF'
          size={15}
        />
      )}
      <Products>
        {productsFiltered.map((product, index) => (
          <ProductCard
            key={product.id}
            index={index}
            product={product}
            setActiveProduct={setActiveProduct}
          />
        ))}
      </Products>
      <ConfirmModalPurchase
        setActiveProduct={setActiveProduct}
        activeProduct={activeProduct}
        balanceVip={balanceVip}
        purchaseSuccess={purchaseSuccess}
      />
    </Content>
  )
}

const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  width: 100%;
  margin: 1em auto;
  gap: 1em;
  row-gap: 1.2em;
  padding: 0 1em;
`

const Content = styled.div`
  padding: 0 1em;
`
