import API from './api';

const bracketUtils = {
  registraBracket: function(bracketId, picks, entryId, same_picks, payment_type, num_entries, userTieBreakers, picksProps) {
    console.log('New Register', this, bracketId);
    this.setState({
      submitting: true,
    });

    let temporalRegisterPicks = {
      bracket_entry_id: null,
      num_entries: num_entries,
      entry_payment: payment_type,
      use_same_picks: same_picks,
      bracket_id: bracketId,
      bracket_picks: picks,
      bracket_user_tiebreakers: userTieBreakers,
    };

    console.log('TEmpREg', temporalRegisterPicks);
    API.postSaveBracketPicks(temporalRegisterPicks).then((response) => {
      this.callbackBracketRegister(response);
    });
  },
  registraBracketTennis: function(bracketId, picks, entryId, same_picks, payment_type, num_entries, userTieBreakers, picksProps, reRegister) {
    console.log('New Register Tennis', this, bracketId);
    this.setState({
      submitting: true,
    });

    let temporalRegisterPicks = {
      bracket_entry_id: null,
      num_entries: num_entries,
      entry_payment: payment_type,
      use_same_picks: same_picks,
      bracket_id: bracketId,
      bracket_picks: reRegister && !same_picks ? {} : picks,
      bracket_user_tiebreakers: userTieBreakers,
      tennis_pick_props: reRegister && !same_picks ? {} : picksProps,
    };

    console.log('TEmpREg', temporalRegisterPicks);
    API.postSaveBracketPicks(temporalRegisterPicks).then((response) => {
      this.callbackBracketRegister(response);
    });
  },
  guardarBracket: function(bracketId, picks, entryId) {
    console.log('GuardarBracket', bracketId, picks, entryId);

    this.setState({
      submitting: true,
    });

    let temporalRegisterPicks = {
      bracket_entry_id: entryId,
      bracket_id: bracketId,
      bracket_picks: picks,
    };

    console.log('TempSave', temporalRegisterPicks);
    API.postSaveBracketPicks(temporalRegisterPicks).then((response) => {
      this.callbackBracketSavedPicks(response);
    });
  },

  renderScroll: function(content) {
    var docStyle = document.documentElement.style;

    var engine;
    if (window.opera && Object.prototype.toString.call(window.opera) === '[object Opera]') {
      engine = 'presto';
    } else if ('MozAppearance' in docStyle) {
      engine = 'gecko';
    } else if ('WebkitAppearance' in docStyle) {
      engine = 'webkit';
    } else if (typeof navigator.cpuClass === 'string') {
      engine = 'trident';
    }

    var vendorPrefix = {
      trident: 'ms',
      gecko: 'Moz',
      webkit: 'Webkit',
      presto: 'O',
    }[engine];

    var helperElem = document.createElement('div');
    var undef;

    var perspectiveProperty = vendorPrefix + 'Perspective';
    var transformProperty = vendorPrefix + 'Transform';

    if (helperElem.style[perspectiveProperty] !== undef) {
      return function(left, top, zoom) {
        content.style[transformProperty] =
          'translate3d(' + -left + 'px,' + -top + 'px,0) scale(' + zoom + ')';
      };
    } else if (helperElem.style[transformProperty] !== undef) {
      return function(left, top, zoom) {
        content.style[transformProperty] =
          'translate(' + -left + 'px,' + -top + 'px) scale(' + zoom + ')';
      };
    } else {
      return function(left, top, zoom) {
        content.style.marginLeft = left ? -left / zoom + 'px' : '';
        content.style.marginTop = top ? -top / zoom + 'px' : '';
        content.style.zoom = zoom || '';
      };
    }
  },
};

export default bracketUtils;
