// Libraries
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Modal } from 'react-bootstrap/lib'

// Components
import PageWrapper from '../PageWrapper'
import { UserDataContextConsumer } from '../../context/UserDataContext'
import CasinoWs from './CasinoWs'
import AbonarSaldo from '../AbonarSaldo'
import MyPlays from './MyPlays'
import Loading from '../Loading'

// Hooks
import useLocalStorage from '../../hooks/useLocalStorage'

// Utils
import API from '../../utils/api'

// Styles
import './index.scss'
import { closeGameSessionData } from '../../routes/history'

const EvCasino = (props) => {
  const action = props.match.params.action
  const iframeRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showSaldo, setShowSaldo] = useState(false)
  const [showMessageSaldo, setShowMessageSaldo] = useState(false)
  const [showMessageError, setShowMessageError] = useState(false)
  const [showMyGames, setShowMyGames] = useState(false)
  const [casinoUrl, setCasinoUrl] = useState('')
  const [sessionGameData, setSessionGameData] = useLocalStorage(
    'sessionGameData',
    {
      game_code: null,
      token_id: null,
      type: null
    }
  )

  // Escuchar evento que se ejecuta cuando el usuario cierra la ventana o navegador
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
        event.returnValue = ''
       setSessionGameData()

      if (sessionGameData?.type && sessionGameData?.token_id) {
        closeGameSessionData({
          game_code: sessionGameData.game_code,
          token_id: sessionGameData.token_id,
          type: 'casino',
          demo: false
        })
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload)
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [sessionGameData])

  const history = require('history').createBrowserHistory()

  // listener to message event
  const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = useRef()

    useEffect(() => {
      savedHandler.current = handler
    }, [handler])

    useEffect(() => {
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event)

      // Add event listener
      element.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    }, [eventName, element]) // Re-run if eventName or element changes
  }

  // recibir mensajes de betslip
  const handler = useCallback((event) => {
    const messageHandler = event.data
    if (event.origin !== 'https://pickwin.bet') {
      return
    }
    console.log('PMESSAGE ⬅️', event.origin, messageHandler)
    switch (messageHandler.type) {
      case 'insufficientBalance':
        setShowMessageSaldo(true)
        break
      default:
        break
    }
  })

  const onWagerCreated = (wager) => {

  }

  // function to change visibilito to abonar saldo
  const updatePanelSate = () => {
    setShowSaldo(false)
  }

  const handleCloseMyGames = () => {
    setShowMyGames(false)
    history.push('/casino')
  }

  // agregar listener de message al window
  useEventListener('message', handler)

  // Get iframe data
  useEffect(() => {
    API.launchCasino().then((response) => {
      const { success, url, error, token_id } = response.data
      if (success) {
        setSessionGameData({
          type: 'casino',
          token_id,
          game_code: null,
          demo: false
        })
    
        setCasinoUrl(url)
        if (iframeRef.current !== null) {
          iframeRef.current.setAttribute('src', url)
        }
        setIsLoading(false)
      } else {
        alert('Error: ' + error)
      }
    })
  }, [])

  // Check casino actions on url and get info
  useEffect(() => {
    switch (action) {
      case 'my-games':
        setShowMyGames(!showMyGames)
        break
      default:
        setShowMyGames(false)
        break
    }
  }, [props.location])

  return (
    <PageWrapper>
      {isLoading && (
        <div className='ev-casino-container'>
          <Loading />
        </div>
      )}
      {!isLoading && (
        <div className='ev-casino-container'>
          <CasinoWs
            onWagerCreated={onWagerCreated}
            setShowMessageSaldo={setShowMessageSaldo}
            setShowMessageError={setShowMessageError}
          />
          <iframe
            ref={iframeRef}
            src={casinoUrl}
            className='ev-casino-iframe'
            sandbox='allow-scripts allow-forms allow-same-origin'
            title='Casino'
            width='100%'
            height='100%'
            frameBorder='0'
          />
          <AbonarSaldo
            type='modal'
            showAbonarSaldo={showSaldo}
            updatePanelSate={updatePanelSate}
            showAbonarPop={false}
          />
          {(showMessageSaldo === true && (
            <div className='messageSaldo'>
              <h4>No cuentas con saldo suficiente</h4>
              <div className='flex-container between' style={{ width: '100%' }}>
                <button
                  className='buttonSaldo'
                  style={{ width: '120px' }}
                  onClick={() => {
                    setShowSaldo(true)
                    setShowMessageSaldo(false)
                  }}
                >
                  Abonar Saldo
                </button>

                <button
                  className='buttonSaldo'
                  style={{ width: '120px', background: '#d6242b' }}
                  onClick={() => {
                    setShowMessageSaldo(false)
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          )) ||
            ''}
          {(showMessageError === true && (
            <div className='messageSaldo'>
              <h4>Ocurrio un error. Intentalo más tarde</h4>
              <button
                className='buttonSaldo'
                style={{ width: '120px' }}
                onClick={() => {
                  setShowMessageError(false)
                }}
              >
                OK
              </button>
            </div>
          )) ||
            ''}
          <Modal
            show={showMyGames}
            className='absoluteModal'
            dialogClassName='modal-dialog-xxxs'
          >
            <div className=''>
              <div id='panelTitle' className='panel-abonar-titulo'>
                <span>mis jugadas</span>
                <button
                  className='cerrar-panel'
                  onClick={handleCloseMyGames}
                >
                  <div className='btn-icon-close'>
                    <i className='ion-ios-close-empty' />
                  </div>
                </button>
              </div>
              <MyPlays />
            </div>
          </Modal>
        </div>
      )}
    </PageWrapper>
  )
}

export default EvCasino


