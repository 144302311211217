import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Button, Popover, OverlayTrigger, Overlay } from 'react-bootstrap/lib';
import moment from 'moment';
import { List, AutoSizer, InfiniteLoader, createTableMultiSort } from 'react-virtualized';
import { CSSTransitionGroup } from 'react-transition-group';
import $ from 'jquery';

import avatar from '../../assets/images/avatar-50.png';
import NotificationControls from '../NotificationControls';
import { NotificationControlsContextProvider } from '../../context/NotificationControlsContext';

/**
 * This component handles the Actions section in the notification Row
 * @param handleActions { Function }
 * @return Component
 */
const ActionsToggle = ({ handleActions }) => {
  return (
    <Button className={` btn-more btn-transparent flex-col`} onClick={handleActions}>
      <i className="ion-android-more-vertical" />
    </Button>
  );
};

/**
 * This component Renders the Notification Actions based on the currentNotification porperty
 * @param currentNotification { Object }
 * @param handleNotificationClick { Function }
 * @param handleArchive { Function }
 */
const NotificationActions = ({ currentNotification, handleNotificationClick, handleArchive }) => {
  // console.log('NotificationActions', currentNotification);
  let actionType;
  let icon;

  if (!currentNotification.actions.length) {
    return (
      <div
        id="notificationActions"
        className={` ${
          currentNotification.isOpen ? ' flex-col  col-no-padding show col-40' : ' invisible '
        } `}
      >
        <div className="flex-row">
          <ArchiveButton currentNotification={currentNotification} handleArchive={handleArchive} />
        </div>
      </div>
    );
  }
  let actionButtons = currentNotification.actions.map((action, i) => {
    if (action.type === 'link') {
      actionType = 'link';
      icon = 'ion-link';
    } else {
      let href = action.href.split('/');
      console.log('href', href);
      actionType = href[href.length - 1];

      if (actionType === 'accept') {
        icon = 'ion-checkmark-circled';
      } else {
        icon = 'ion-close-circled';
      }
    }
    // console.log('ActionType =', actionType);
    return (
      <div
        key={currentNotification.id + '-' + currentNotification.created + '-' + i}
        className="flex-col col-no-padding col-center"
      >
        <Button
          className={`btn-actions flex-row row-center row-no-padding ${actionType}`}
          onClick={() => {
            handleNotificationClick(
              currentNotification.id,
              currentNotification.viewed,
              action,
              currentNotification.type
            );
          }}
        >
          <i className={`${icon} flex-col text-center`} />
        </Button>
      </div>
    );
  });

  return (
    <div
      id="notificationActions"
      className={` ${
        currentNotification.isOpen ? ' flex-col  col-no-padding show col-40' : ' invisible '
      } `}
    >
      <div className="flex-row">
        {actionButtons}
        <ArchiveButton currentNotification={currentNotification} handleArchive={handleArchive} />
      </div>
    </div>
  );
};
/**
 * The button send a request to archive the notification and returns the newStatus
 *  @param currentNotification { Object }
 *  @param handleArchive { Function }
 */
const ArchiveButton = ({ currentNotification, handleArchive }) => {
  return (
    <div className="flex-container column justify align notification-action-item">
      <ReactTooltip id="archiveNotification" place="bottom" type="light" effect="solid">
        Eliminar Notificación
      </ReactTooltip>
      <Button
        data-for="archiveNotification"
        data-tip
        className={'btn btn-archive flex-row row-center row-no-padding btn-notification-action'}
        onClick={() => {
          handleArchive(currentNotification);
        }}
      >
        <i className={'ion-ios-trash flex-col'}></i>
      </Button>
    </div>
  );
};

/**
 * This component mark all the notifications of the list as radio
 * @param markAllNotificationsAsRead { Function }
 * @returns Component
 */
const MarkAllRead = ({ markAllNotificationsAsRead }) => {
  return (
    <Button className="flex-col btn-mark-all" onClick={markAllNotificationsAsRead}>
      {' '}
      Marcar todas leídas{' '}
    </Button>
  );
};

/**
 * This component Archive all the notifications
 * @param markAllNotificationsAsRead { Function }
 * @returns Component
 */
const ArchiveAllRead = ({ handleArchive }) => {
  return (
    <Button
      className="flex-col btn-mark-all"
      onClick={() => {
        handleArchive('all');
      }}
    >
      {' '}
      Archivar todas
    </Button>
  );
};

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      notifications: null,
      error: false,
      successFriendResolve: false,
      submitting: false,
      showNotificationControls: false,
      unread: 0,
    };

    this.handleNotificationControls = () => {
      console.log('show');
      this.setState({ showNotificationControls: !this.state.showNotificationControls });
    };

    /**
     * This method intiialize the notificationSliders status
     * @param notifications { Object }
     * @return newNotifications { Object }
     */
    this.initializeNotificationSliders = (notifications) => {
      if (!notifications || !notifications.hasOwnProperty('data')) {
        return null;
      }
      // console.log('-----InitializeNotificatiosn-----', notifications.data );

      // let newNotifications = notifications;
      let newNotifications = notifications;
      newNotifications.data = newNotifications.data.map((n) => {
        n['isOpen'] = false;
        return n;
      });
      // console.log(newNotifications.data)
      return newNotifications;
    };

    /**
     * This method handle the actions in the notification
     */
    this.handleActions = (notificationId) => {
      // console.log(  '%c ------handleActions----' , 'background:#78bc28; font-size:2em;');
      // console.log( this.state.notifications );
      let temporalNotiications = this.state.notifications;

      temporalNotiications.data.forEach((n) => {
        if (n.id === notificationId) {
          n.isOpen = !n.isOpen;
        }
      });
      // console.log(temporalNotiications)

      this.setState({ notifications: temporalNotiications }, () => {
        if (this.list) {
          this.list.forceUpdateGrid();
        }
      });
    };

    /**
     * This renders the notification based on the react-virtualized renderer props
     * it uses props.styles to set the position of the list items
     * @param props { Object }
     * -@return Component
     */
    this.notificationRenderer = (props) => {
      let currentNotification = this.state.notifications.data[props.index];
      // console.log('In renderer')
      // console.log(currentNotification)
      let actionButtons = currentNotification.actions.map((action) => {
        let tooltipTxt = '';
        let classes = '';
        let btnIcon = '';
        let place = '';

        switch (currentNotification.type) {
          case 'friend_request':
            let parsedType = action.href.split('/');
            classes = 'btn-' + parsedType[parsedType.length - 1];
            tooltipTxt =
              parsedType[parsedType.length - 1] === 'accept'
                ? 'Aceptar invitación'
                : 'Rechazar invitación';
            btnIcon =
              parsedType[parsedType.length - 1] === 'accept'
                ? 'ion-checkmark-circled'
                : 'ion-close-circled';
            place = parsedType[parsedType.length - 1] === 'accept' ? 'top' : 'bottom';

            break;
          case 'pool_request':
            tooltipTxt = `Ver quiniela`;
            btnIcon = 'ion-link';
            classes = 'btn-go-to-request';
            place = 'top';
            break;
        }
        return (
          <div
            className="flex-container column justify align notification-action-item"
            key={action.href}
          >
            <ReactTooltip id={action.href} place={place} type="light" effect="solid">
              {tooltipTxt}
            </ReactTooltip>
            <Button
              key={action.href}
              data-for={action.href}
              data-tip
              className={classes + ' flex-row row-center row-no-padding btn-notification-action'}
              onClick={() => {
                this.props.handleNotificationClick(
                  currentNotification.id,
                  currentNotification.viewed,
                  action,
                  currentNotification.type
                );
              }}
            >
              <i className={btnIcon + ' flex-col'}></i>
            </Button>
          </div>
        );
      });

      if (actionButtons.length === 0) {
        if (this.props.error) {
          actionButtons = (
            <div
              className="flex-container column justify align error-resolver notification-action-item"
              style={{ color: '#e00034' }}
            >
              <span>Error</span>
              <i className="ion-close-round"></i>
            </div>
          );
        } else if (this.props.successFriendResolve || currentNotification.viewed) {
          actionButtons = null;
        }
      }

      return (
        <div
          id={'notification-' + currentNotification.id}
          key={currentNotification.id + currentNotification.created}
          style={props.style}
          onMouseEnter={() => {
            if (currentNotification.viewed) {
              return;
            }
            this.props.markNotificationAsRead({ id: currentNotification.id });
          }}
          className={` notification-item flex-container ${
            currentNotification.viewed ? 'viewed' : 'notViewed'
          } overflow-notification-wrapper overflow overflow-invisible align`}
        >
          <div
            id="notification-container"
            className={`flex-col col-95 ${currentNotification.isOpen ? 'isOpen' : 'notOpen'}  `}
          >
            <div className="flex-row row-center">
              <div className={`flex-col col-15 notification-avatar`}>
                <img
                  className="flex-row row-no-padding  avatar-notification"
                  src={
                    currentNotification.creator.avatar_url
                      ? currentNotification.creator.avatar_url
                      : avatar
                  }
                />
              </div>
              <div className="flex-col notification-message col-75">
                <div className="flex-row row-no-padding">{currentNotification.message}</div>
                <div className="flex-container align extra-info">
                  {moment(currentNotification.created).fromNow()}
                </div>
              </div>
              <div className="flex-col col-10 col-no-padding">
                <ActionsToggle
                  handleActions={() => {
                    this.handleActions(currentNotification.id);
                  }}
                />
              </div>
            </div>
          </div>
          <NotificationActions
            handleNotificationClick={this.props.handleNotificationClick}
            handleArchive={this.props.handleArchive}
            currentNotification={currentNotification}
          />
        </div>
      );
    };

    this.loadMoreRows = () => {
      console.log('More Rows');
      this.props.loadMoreNotifications();
    };

    this.handleArchive = (currentNotification) => {
      // console.log('-------- Handle Archive --------');
      // console.log(currentNotification);
    };

    this.isRowLoaded = ({ index }) => {
      // console.log(index);
      return !!this.props.notifications.data[index];
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps.updateList , this.props.updateList );
    if (prevProps.updateList !== this.props.updateList || prevProps.unread !== this.props.unread) {
      // console.log('List Changed');
      // console.log( this.list );
      let newNotifications = this.props.notifications;

      newNotifications = this.initializeNotificationSliders(newNotifications);

      this.setState({ notifications: newNotifications }, () => {
        if (this.list) {
          this.list.forceUpdateGrid();
        }
      });
    } else if (
      prevProps.notifications.hasOwnProperty('data') &&
      prevProps.notifications.page !== this.props.notifications.page
    ) {
      console.log('Update Grid');
      let newNotifications = this.props.notifications;

      newNotifications = this.initializeNotificationSliders(newNotifications);
      this.setState({ notifications: newNotifications }, () => {
        if (this.list) {
          this.list.forceUpdateGrid();
        }
      });
    }
  }

  render() {
    let conditionalComponent = null;

    if (this.props.show) {
      conditionalComponent = (
        <div id="notificationList" className=" notifications-dropdown">
          <div className="user-info-dropdown flex-col">
            <div className=" flex-row row-center dropdown-title">
              <strong className="flex-col text-center col-no-padding" style={{ fontSize: '1.1em' }}>
                {' '}
                {this.state.showNotificationControls
                  ? 'Centro de Notificaciones'
                  : 'Mis Notificaciones'}{' '}
              </strong>
              <Button className="btn-transparent hidden" onClick={this.handleNotificationControls}>
                <i
                  className={`${
                    this.state.showNotificationControls
                      ? 'ion-arrow-left-a'
                      : 'ion-android-settings'
                  }`}
                  style={{ fontSize: '1.5em' }}
                />
              </Button>
            </div>
          </div>
          {this.state.showNotificationControls ? (
            <NotificationControlsContextProvider>
              <NotificationControls />
            </NotificationControlsContextProvider>
          ) : (
            <div className="flex-row row-center">
              {!this.props.notifications.hasOwnProperty('data') ||
              this.props.notifications.data.length === 0 ? (
                <div className="flex-col text-center">No tienes notificaciones.</div>
              ) : (
                <div className="flex-col ">
                  <div className="list-notification ">
                    <InfiniteLoader
                      isRowLoaded={this.isRowLoaded}
                      loadMoreRows={this.loadMoreRows}
                      rowCount={this.props.notifications.total}
                      threshold={2}
                    >
                      {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                          {({ height, width }) => (
                            <List
                              ref={(registerChild) => (this.list = registerChild)}
                              height={height}
                              onRowsRendered={onRowsRendered}
                              rowCount={this.props.notifications.data.length}
                              rowHeight={$(window).width() > 1400 ? 64 : 58}
                              rowRenderer={this.notificationRenderer}
                              width={width}
                            />
                          )}
                        </AutoSizer>
                      )}
                    </InfiniteLoader>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.showNotificationControls ? null : (
            <div className="flex-row ">
              <div className="flex-col ">
                {!this.props.unread || this.props.unread === 0 ? null : (
                  <MarkAllRead markAllNotificationsAsRead={this.props.markAllNotificationsAsRead} />
                )}
                {!this.props.notifications.hasOwnProperty('data') ||
                this.props.notifications.data.length < 1 ? null : (
                  <ArchiveAllRead handleArchive={this.props.handleArchive} />
                )}
              </div>
            </div>
          )}
        </div>
      );
    }

    return conditionalComponent;
  }
}

export default NotificationList;
