import React from 'react'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'underscore'
import * as Sentry from '@sentry/browser'
import { Link, Prompt } from 'react-router-dom'
import { Button, DropdownButton, MenuItem, Modal } from 'react-bootstrap/lib'

// Components
import Picks from './Picks'
import PicksProps from './PicksProps'
import { Desempates } from '../../utils/quinielasComponents'
import EstatusQuiniela from './EstatusQuiniela'
import ErrorBoundary from '../ErrorBoundary'
import RegisterPanel from '../RegisterPanel/RegisterPanel'
import { QuinielaDataConsumer } from '../../context/QuinielaDataContext'

// Utils
import { publish } from '../../utils/events'
import Utils from '../../utils/quinielasUtils'
import utils from '../../utils/utils'
import API from '../../utils/api'

// Assets
import picks from '../../assets/images/quinielasIconBlanco-50.png'
import guardar from '../../assets/images/guardarPicks-50.png'
import abonar from '../../assets/images/abonarIconBlanco-50.png'
import WeeksControls from '../WeeksControls'


const URLS_IMAGES = {
  referidos: 'https://storage.googleapis.com/pw-resources/banners/app/referral-pool-info.jpg',
  maxPicks: 'https://storage.googleapis.com/pw-resources/banners/app/maxpicks-pool-info.jpg',
  maxPicksFull:'https://storage.googleapis.com/pw-resources/banners/app/maxpicks-full-pool-info.jpg',
  gratuitas: 'https://storage.googleapis.com/pw-resources/banners/app/freerroll-pool-info.jpg',
  parlay: 'https://storage.googleapis.com/pw-resources/banners/app/parlay-pool-info.png',
};

/**
 * SelectWeek Component it changes the weekID and the fixtures of the state
 * @param currentWeek     { Object }
 * @param poolWeeks       { Object }
 * @param changeWeek      { Function }
 * @return Component
 */
const SelectWeek = ({ currentWeek, poolWeeks, changeWeek }) => {
  return (
    <div className=" flex-col col-no-padding col-center boton-titulo-dash jornadas">
      <DropdownButton
        id="jornadasTemporadaDropdown"
        rootCloseEvent={'click'}
        title={`Jornadas`}
        className="seleccionaJornada flex-row text-center"
      >
        {poolWeeks.weeks.map((week, i) => {
          let currentWeekId =
            !currentWeek && currentWeek.id === undefined ? poolWeeks.current.id : currentWeek.id;

          return (
            <MenuItem key={i} onClick={() => changeWeek(week.id)}>
              {week.id === currentWeekId ? <i className="ion-arrow-right-a" /> : ''}
              {week.week_name}
            </MenuItem>
          );
        })}
      </DropdownButton>
    </div>
  );
};

/**
 * This component Copy the picks of a similar Pool
 * @param currentEntry   { Object }
 * @param misQuinielas   { boolean }
 * @param handleCopy     { function }
 * @param poolId         { int }
 */
export const CopiarPicks = ({
  currentEntry, misQuinielas, handleCopy, poolId
}) => {

  let [ similarEntries, setSimilarEntries] = React.useState([]);
  let [ loading, setLoading ] = React.useState(false);

  const fetchEntries = () => {
    setSimilarEntries([]);
    if ( currentEntry && currentEntry.id ) {
      setLoading(true);
      API.fetchSimilarEntries(currentEntry.id)
      .then((response) => {
        const data = response.data;

        // debugger
        if ( data.similar_entries !== false ) {
          setSimilarEntries(data.similar_entries);
        }
        setLoading(false);
      });
    } else {
      setLoading(true);
      API.fetchSimilarPools(poolId)
      .then((response) => {
        const data = response.data;
        if ( data.similar_entries !== false ) {
          setSimilarEntries(data.similar_entries);
        }
        setLoading(false);
      });
    }
  };

  const menuItems = similarEntries.map((simi, i) => {
    return(
      <MenuItem
        key={i}
        onClick={() => {
          handleCopy(simi.id);
        }}>
        {`
          [${ simi.name === null  ? 0 : simi.name }]
          ${simi.pool.name_replaced}
          ${numeral( simi.pool.entry_fee / 100 ).format('0')}`
        }
      </MenuItem>
    );
  });

  return (

      <div className={ misQuinielas ? '' : 'flex-col btn-wrap col-no-padding' }>
        <DropdownButton
          id="similarEntriesDropdown"
          noCaret
          rootCloseEvent={'click'}
          title={`Copiar Picks`}
          className=" flex-col copiarPicks"
          onClick={() => fetchEntries() }
          >
          {
            loading ? <small>Cargando...</small> :
              similarEntries.length === 0 ?
              <small>No encontramos quinielas</small>
              :
              menuItems
          }
        </DropdownButton>
      </div>

  );

}
/**
 * This is the A sample button of a call to action that handles various states to show the correct button
 * @param misQuinielas        { boolean }
 * @param submitting          { boolean }
 * @param enoughBalance       { boolean }
 * @param handleCallToAction  { Function }
 * @param isDisabled          { boolean }
 * @param isFirstRegister     { boolean }
 *@return Component
 */
const CallToActionButton = ({
  misQuinielas,
  submitting,
  enoughBalance,
  handleCallToAction,
  handleAbonar,
  isReferral,
  isDisabled,
  isFirstRegister,
  withRegister,
}) => {
  // console.log( enoughBalance )
  if (misQuinielas) {
    return (
      <div className="btn-wrap">
        <Button
          bsStyle="success"
          disabled={isDisabled}
          onClick={() => {
            handleCallToAction('justSave');
          }}
          className="guardar-picks callToAction"
        >
          <img src={guardar} className="pool-icons" />
          <span> GUARDAR PICKS</span>
        </Button>
      </div>
    );
  } else if (!isFirstRegister) {
    return (
      <div className="btn-wrap">
        <Button
          bsStyle="success"
          disabled={isDisabled || submitting}
          onClick={() => {
            handleCallToAction('justSave');
          }}
          className="guardar-picks callToAction"
        >
          {submitting ? null : <img src={guardar} className="pool-icons" />}
          {submitting ? (
            <div className="flex-container justify align">
              <i className="ion-load-a loading small"></i>
            </div>
          ) : (
            <span> GUARDAR PICKS </span>
          )}
        </Button>
      </div>
    );
  } else if (!enoughBalance && !withRegister & isReferral) {
    return (
      <div className="btn-wrap">
        <a href="/usuario/invitados" className="flex-row">
          <Button bsStyle="success" disabled={isDisabled} className="guardar-picks callToAction">
            <span> INVITA A TUS AMIGOS </span>
          </Button>
        </a>
      </div>
    );
  } else if (!enoughBalance && !withRegister) {
    return (
      <div className="btn-wrap">
        <Button
          bsStyle="success"
          disabled={!utils.isLogged() || isDisabled}
          onClick={() => {
            handleCallToAction('abonar');
          }}
          className="guardar-picks callToAction"
        >
          <img src={abonar} className="pool-icons" />
          <span> ABONAR SALDO</span>
        </Button>
      </div>
    );
  } else if (!withRegister) {
    return (
      <div className="btn-wrap">
        <Button
          bsStyle="success"
          disabled={isDisabled || !utils.isLogged()}
          onClick={() => {
            handleCallToAction('firstRegister');
          }}
          className="guardar-picks callToAction"
        >
          <img src={guardar} className="pool-icons" />
          <span> GUARDAR PICKS Y REGÍSTRAME</span>
        </Button>
      </div>
    );
  }
};

/**
 * This component Render a Overlay with a message to the user
 * @param isError               { Boolean }
 * @param errorMessage          { String }
 * @param isSuccess             { Boolean }
 * @param successMessage        { String }
 * @param isCopy                { Boolean }
 * @param showOverlay           { Boolean }
 * @param label                 { String }
 * @param currentSavedPicks     { Number }
 * @param allPicks              { Object }
 * @return Component
 */
export const ConfirmationOverlay = ({
  isError,
  errorMessage,
  isSuccess,
  successMessage,
  isCopy,
  showOverlay,
  label,
  currentSavedPicks,
  allPicks,
  parlay,
  parlayFlag,
}) => {
  let picksStatus = null;
  let iconStatus = null;
  if (isError) {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          <strong className="title">¡Tenemos un error al guardar tus picks!</strong>
        </div>
        <div className="flex-container justify">
          {!errorMessage ? 'Por favor inténtalo nuevamente' : errorMessage}
        </div>
      </div>
    );
    iconStatus = <i className="ion-alert-circled" />;
  } else if (isCopy) {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          <strong className="title">¡Tus picks se copiaron con éxito!</strong>
        </div>
        <div className="flex-container justify">Recuerda guardar tus picks.</div>
      </div>
    );
  } else {
    // Cuando no ha llenado todos los picks
    if (currentSavedPicks !== allPicks) {
      picksStatus = (
        <div className="flex-container column align justify">
          <div className="flex-container justify">
            <strong className="title" style={{ fontSize: '1.4em'}}>¡Picks Guardados!</strong>
          </div>
          <div className="flex-container justify">
            {parlayFlag === true && (
              <>
                Tienes {currentSavedPicks}/{parlay.length} picks guardados.
              </>
            ) || (
              <>
                Tienes {currentSavedPicks}/{allPicks} picks guardados.
              </>
            )}
          </div>
          <div className="flex-container justify" style={{ fontSize: '0.85em'}}>Recuerda ingresar todos tus picks.</div>
        </div>
      );
      iconStatus = <i className="ion-alert-circled" />;
    } else {
      /**
       * Complete Picks
       **/
      picksStatus = `¡Felicidades tus picks están completos!`;
      iconStatus = <i className="ion-checkmark-circled" />;
    }
  }
  return (
    <div
      className={`picks-message-overlay ${showOverlay ? 'show-message ' : 'hide-message '}
      ${isError ? 'error ' : ''}
      ${isCopy ? 'copy ' : ''}`}
    >
      <span>
        {iconStatus} {picksStatus}
      </span>
    </div>
  );
};

class QuinielaPicksUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWeek: null,
      copyInProgress: false,
      isFirstRegister: false,
      isReady: false,
      myEntry: null,
      picks: null,
      poolWeeks: false,
      props: null,
      savedPicks: 0,
      savedProps: 0,
      saveError: false,
      saveSuccess: false,
      copyStatus: null,
      savedTies: 0,
      saveStatus: null,
      showConfirmPanel: false,
      showOverlay: false,
      submitting: false,
      ties: null,
      entryModel: {
        entry_id: null,
        num_entries: 1,
        pool_id: null,
        picks: null,
        picks_props: null,
        user_tiebreakers: null,
        use_same_picks: 0,
      },
      pickModel: {
        entry_id: null,
        fixture_id: null,
        id: null,
        pick: null,
        pool_id: null,
        week_id: null,
      },
      pickPropModel: {
        entry_id: null,
        fixture_id: null,
        id: null,
        pick: null,
        pool_id: null,
        prop_id: null,
      },
      tiesModel: {
        entry_id: null,
        id: null,
        value: null,
        tiebreaker_id: null,
      },
      modalTutorial: {
        show: false,
        title: '',
        routeImage: '',
      },
      numPicks: -1,
      showModalPicksUnsave: false,
      picksUnsave: false,
      confirmedNavigation: false,
    };

    this.updateTieData = Utils.updateTieData.bind(this);
    this.initializeTies = Utils.initializeTies.bind(this);
    this.initializeEntry = Utils.initializeEntry.bind(this);

    this.validatePoolType = (pick) => {
      const { pool } = this.props.poolInfo;

      if (pool.type === 'full') {
        return pick.pick !== null && pick.week_id === this.props.currentWeek.id;
      } else {
        return pick.pick !== null;
      }
    }

    //helpe
    this.checkLen = (entry) => {
      var allPicks = { ...entry.picks, ...entry.picks_props };
  
      const numPicks = Object.values(allPicks).reduce((acc, pick) => {
        return this.validatePoolType(pick) ? acc + 1 : acc;
      }, 0);
  
      return numPicks;
    };

    /**
     * This method update the Entry Data after the entry data is updated by the server
     * Change property my Entry of the mainState
     * @param data { Object } New Entry Data
     */
    this.updateMyEntryData = (data) => {
      console.log("update entry data");
      let temporalEntry = this.state.myEntry;
      temporalEntry.entry_id = data.entryId;
      _.forEach(temporalEntry.picks, (pick) => {
        pick.entry_id = data.entryId;
      });
      if (this.props.fixtureProps && this.props.fixtureProps.length > 0) {
        _.forEach(temporalEntry.picks_props, (pick) => {
          pick.entry_id = data.entryId;
        });
      }
      if (this.props.poolInfo.pool.use_tibreakers) {
        _.forEach(temporalEntry.user_tiebreakers, (tie) => {
          tie.entry_id = data.entryId;
        });
      }

      this.setState({ myEntry: temporalEntry });
    };

    /**
     * This method handles the confirmation timeout to dissapear
     *
     */
    this.showConfirmation = (time) => {
      time = !time ? 2500 : time;

      this.setState({ showOverlay: true }, () => {
        setTimeout(() => {
          this.setState({ showOverlay: false });
        }, time);
      });
    };

    /**
     * This function handles the CTA Button and his different actions
     * @param type { String } The type of the CTA
     *                        This could be:
     *                         'abonar' : it opens the Modal to make a deposit,
     *                         'firstRegister': show the Confirmation Register  Panel,
     *                         'reRegister': shoq The confirmation Register and hcanges the flag or tghe reRegister  Status,
     *                         'justSave':  POST  the new data to the server ,
     */
    this.handleCallToAction = (type) => {
  
      if (this.state.submitting) {
        return;
      }

      this.handleStatusPicksUnsave();

      switch (type) {
        case 'abonar':
          console.log('%c Abonar', 'color:#2e8ccf; font-size:2em;');
          this.props.handleAbonar();
          break;
        case 'firstRegister':
          console.log('%c FirstRegister', 'color:#2e8ccf; font-size:2em;');
          console.log(this.state.myEntry);
          this.setState({
            showConfirmPanel: true,
            isFirstRegister: true,
          });
          break;
        case 'reRegister':
          console.log('%c reRegister', 'color:#2e8ccf; font-size:2em;');
          this.setState({
            showConfirmPanel: true,
            isReRegister: true,
          });
          break;
        case 'justSave':
          this.setState({ submitting: true });
          console.log('justSave');
          API.postSavePicks(this.state.myEntry)
            .then((response) => {
              console.log(response);
              if (response.data.success) {
                // Emit event to notify Picks group
                publish('UPDATE_MY_PICKS', 'justSave')


                let numPicks = -1;
                numPicks = this.checkLen(this.state.myEntry);

                this.setState(
                  {
                    saveSuccess: true,
                    submitting: false,
                    savedPicks: response.data.entry.pick_count,
                    savedProps: response.data.entry.pick_prop_count,
                    savedTies: Object.keys(response.data.user_tiebreakers).length,
                    numPicks
                  },
                  () => {
                    this.showConfirmation();
                    // console.log( 'exist', this.props.updateEntriesParent )
                    if (this.props.updateEntriesParent) {
                      this.props.updateEntriesParent(this.state.myEntry, response.data.entry);
                    }
                    if (this.props.updateControlEntries) {
                      this.props.updateControlEntries(
                        response.data.entry,
                        this.state.myEntry.entry_id,
                        'pool'
                      );
                    }
                  }
                );
              } else {
                this.setState(
                  {
                    saveError: true,
                    errorMessage: response.data.error,
                    submitting: false,
                  },
                  () => {
                    this.showConfirmation();
                  }
                );
              }
            })
            .catch((e) => {
              this.setState(
                {
                  saveError: true,
                  errorMessage: 'Tenemos un error',
                  submitting: false,
                },
                () => {
                  this.showConfirmation();
                }
              );
            });
          break;
      }
    };

    this.showConfirmPanel = () => {
      this.setState({ showConfirmPanel: true });
    };

    this.closePanelRegister = () => {
      this.setState({ showConfirmPanel: false });
    };

    this.callbackRegister = (data) => {};

    this.handleRemovePick = (type, itemId, value, group, parleyFlag, itemGroups) => {
      if (this.props.poolInfo.pool.max_picks === null && this.props.poolInfo.pool.parlay_flag === null) {
        return false;
      }

      let temporalEntry = this.state.myEntry;
      console.log("TEMPENTRYREMOVE", temporalEntry.picks);
      console.log("ITEMGROUPS:", itemGroups);

      // -----------
      if (parleyFlag) {
        let i = 0;
        itemGroups.map(e => {
          if (group === e.group) {
            itemGroups.splice(i, 1);
            temporalEntry.picks[e.entryId].pick = null;
            this.setState(
              { myEntry: temporalEntry, picks: temporalEntry.picks, numPicks: this.state.numPicks },
              () => {
                if (this.props.updateRegisterPicks) {
                  this.props.updateRegisterPicks(this.state.myEntry);
                }
              }
            );
          }
          i++;
        });
      }
      // -----------

      if (type === 'pick' && temporalEntry.picks.hasOwnProperty(itemId) && temporalEntry.picks[itemId].pick !== null) {
        console.log("ENTRAREMOVE");
        // parlay
        if (parleyFlag) {
          let index = itemGroups.findIndex(i => (i.group === group && i.itemId === itemId));
          if (index) {
            itemGroups.splice(index, 1);
          }
        }

        temporalEntry.picks[itemId].pick = null;
        this.setState(
          { myEntry: temporalEntry, picks: temporalEntry.picks, numPicks: this.state.numPicks },
          () => {
            if (this.props.updateRegisterPicks) {
              this.props.updateRegisterPicks(this.state.myEntry);
            }
          }
        );
      }

      if (type === 'pick_prop' && temporalEntry.picks_props.hasOwnProperty(itemId) && temporalEntry.picks_props[itemId].pick !== null) {
        temporalEntry.picks_props[itemId].pick = null;
        this.setState(
          {
            myEntry: temporalEntry,
            props: temporalEntry.picks_props,
            numPicks: this.state.numPicks,
          },
          () => {
            if (this.props.updateRegisterPicks) {
              this.props.updateRegisterPicks(this.state.myEntry);
            }
          }
        );
      }
    };

    /**
     * This method handles the Pick by its type and change the myEntry property of the main state
     * @param type        { String } it could be pick or pick_prop
     * @param itemId      { Number } it has to be the prop_id or the fixture_id that has been clicked
     * @param value       { String } it is the value of the clicked element : it could be home, tie, away, under, over , over_under
     */
    this.handlePick = (type, itemId, value, group, parleyFlag, itemGroups) => {
      //check if handle pick with register
      if (this.state.myEntry.entry_id !== null) {
        this.setState({picksUnsave: true});
      }

      let numPicks = -1;
      numPicks = this.checkLen(this.state.myEntry);

      if (parleyFlag) {
        //validate one item per group
        let find = itemGroups.find(e => e.group === group);
        if (find === undefined) {
          itemGroups.push({group, itemId});
        } else {
          return;
        }
      }

      if (this.props.poolInfo.pool.max_picks !== null && this.props.poolInfo.pool.max_picks > 0) {
        if (numPicks >= this.props.poolInfo.pool.max_picks) {
          return;
        }
      }

      let temporalEntry = this.state.myEntry;
      //console.log("TEMPENTRYADD", temporalEntry.picks);
      const isSelectedAll = Object.values(this.state.myEntry.picks).length + Object.values(this.state.myEntry.picks_props).length === numPicks;

      if (type === 'pick' && temporalEntry.picks.hasOwnProperty(itemId)) {
  

        temporalEntry.picks[itemId].pick = value;

        let numPicks = -1;
        numPicks = this.checkLen(temporalEntry);

        this.setState(
          {
            myEntry: temporalEntry,
            picks: temporalEntry.picks,
            numPicks: isSelectedAll ? numPicks : numPicks,
          },
          () => {
            if (this.props.updateRegisterPicks) {
              this.props.updateRegisterPicks(this.state.myEntry);
            }
          }
        );
      }

      if (type === 'pick_prop' && temporalEntry.picks_props.hasOwnProperty(itemId)) {
        temporalEntry.picks_props[itemId].pick = value;
        this.setState(
          {
            myEntry: temporalEntry,
            props: temporalEntry.picks_props,
            numPicks: isSelectedAll ? numPicks : numPicks,
          },
          () => {
            if (this.props.updateRegisterPicks) {
              this.props.updateRegisterPicks(this.state.myEntry);
            }
          }
        );
      }
    };

    /**
     * This method replaces the current entry picks, picks_props, and ties of the mainState
     * @param toCopyEntry { Object  } it contains the data to the entry that has to be copied
     *
     */
    this.replaceEntryWithCopy = (toCopyEntry) => {
      // console.log('------------ReplaceEntry With Copy------------');

      let newEntry = this.state.myEntry;
      let availableFixtures = this.props.fixtures;
      let availableFixturesProps = this.props.fixtureProps;

      // Copy picks
      _.forEach(toCopyEntry.picks, (copyPick) => {
        let fixturePick = availableFixtures.find((fixt) => fixt.id === copyPick.fixture_id);
        if (newEntry.picks[copyPick.fixture_id] && fixturePick && fixturePick.enabled) {
          newEntry.picks[copyPick.fixture_id].pick = copyPick.pick;
        }
      });

      if (this.props.fixtureProps.length > 0 && toCopyEntry.picks_props.length > 0) {
        // console.log('copy Props')
        // console.log( this.props.fixtureProps)
        // Copy Props
        _.forEach(toCopyEntry.picks_props, (copyPick) => {
          let fixtureProp = availableFixturesProps.find(
            (fixt) => fixt.fixture_id === copyPick.fixture_id
          );
          if (newEntry.picks_props[copyPick.prop_id] && fixtureProp && fixtureProp.enabled) {
            newEntry.picks_props[copyPick.prop_id].pick = copyPick.pick;
          }
        });
      }

      //Copy Ties
      _.forEach(toCopyEntry.user_tiebreakers, (copyTie, origIdx) => {
        let destIdx = 0;
        _.forEach(newEntry.user_tiebreakers, (newTie) => {
          // console.log( 'Copy Tie Id', copyTie);
          // console.log( 'Copy Tie object', copyTie.tiebreaker);
          // console.log( 'New Tie Id', newTie);
          // console.log( 'New Tie Object', newTie);
          if (destIdx === origIdx && newTie && copyTie.tiebreaker) {
            if (newTie.week_id === copyTie.tiebreaker.week_id) {
              if (copyTie.tiebreaker.type === 'score') {
                newTie.score_away = copyTie.score_away;
                newTie.score_home = copyTie.score_home;
              } else {
                // console.log(newTie,copyTie);
                newTie.value = copyTie.value;
              }
            }
          }
          destIdx++;
        });
      });

      const countPicks = this.checkLen(this.state.myEntry)

      const numPicks = countPicks <= 0
       ? 0
       : countPicks - 1

      this.setState({ numPicks })


      // console.log( '------Result:-----' );
      // console.table( newEntry.user_tiebreakers );
      return newEntry;
    };

    /**
     * This fetch the entry That wants to be copied
     * @param entryId { Number }
     *
     */
    this.handleCopy = (entryId) => {
      // console.log('handleCopy ');
      // console.log( entryId );
      this.setState({ copyInProgress: true, showOverlay: true });

      API.fetchCopyPicks(entryId)
        .then((response) => {
          if (response.status === 200) {
            this.setState(
              {
                myEntry: this.replaceEntryWithCopy(response.data.entry),
              },
              () => {
                setTimeout(() => {
                  this.setState({ copyInProgress: false, showOverlay: false });
                }, 1000);
              }
            );
          }
          // console.log(response);
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
          this.setState({ errorOcurred: e, errorStatus: true });
        });
    };

    this.handlePopUpReferral = () => {
      this.setState({
        modalTutorial: { ...this.state.modalTutorial, show: !this.state.modalTutorial.show },
      });
    };

    this.validateShowModalImageTutorial = () => {
      let modalDefault = {
        show: false,
        title: '',
        routeImage: '',
      };

      if (this.props.entriesInfo) return modalDefault;

      if (this.props.poolInfo.pool.entry_currency === 'referral') {
        modalDefault = {
          routeImage: URLS_IMAGES.referidos,
          show: true,
          title: 'Quiniela Referidos',
        };
        return modalDefault;
      }

      if (this.props.poolInfo.pool.entry_currency === 'freeroll') {
        modalDefault = {
          routeImage: URLS_IMAGES.gratuitas,
          show: true,
          title: 'Quiniela Gratuita',
        };
        return modalDefault;
      }

      if (this.props.poolInfo.pool.max_picks !== null) {
        modalDefault = {
          routeImage:
            this.props.poolInfo.pool.type === 'full'
              ? URLS_IMAGES.maxPicksFull
              : URLS_IMAGES.maxPicks,
          show: true,
          title: 'Quiniela Pick5',
        };
        return modalDefault;
      }

      if (this.props.poolInfo.pool.parlay_flag !== null) {
        modalDefault = {
          routeImage: URLS_IMAGES.parlay,
          show: true,
          title: 'Quiniela Parlay',
        };
        return modalDefault;
      }

      return modalDefault;
    };

    this.handleShowModalPicksUnsave = (nextLocation) => {
      if (!this.confirmedNavigation) {
        this.setState({showModalPicksUnsave: true});
        return false;
      }

      return true;
    }

    this.handleStatusPicksUnsave = () => {
      this.setState({
        showModalPicksUnsave: false,
        confirmedNavigation: true,
        picksUnsave: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //debugger;

    if (prevProps.cambiarEntradas !== this.props.cambiarEntradas && this.props.cambiarEntradas) {
      console.log('Cambio de Entrada');
      this.initializeEntry(
        this.props.entryInfo,
        this.props.fixtures,
        this.props.fixtureProps,
        this.props.poolInfo,
        this.props.currentWeek.id,
        this.state.myEntry
      )
        .then((response) => {
          let temporalEntry = response;
          let tieData = this.initializeTies(this.props.poolInfo, this.props.entryInfo);
          console.log(response);
          temporalEntry.user_tiebreakers = tieData;

          // Here
          let numPicks = this.checkLen(temporalEntry);

          this.setState({
            myEntry: temporalEntry,
            tiesPicks: tieData,
            numPicks: numPicks,
          });
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        })
        .finally(() => {
          //this.props.reiniciarCambioDeEntrada();
        });
    }

    if (prevProps.updateCurrentEntry !== this.props.updateCurrentEntry) {
      console.log('update Entry to registaration');
      if (this.props.setSameEntry) {
        this.props.setSameEntry(this.state.myEntry);
      }
    }

    if (prevProps.currentWeek.id !== this.props.currentWeek.id) {
      console.log(
        'Cambio de semana',
        this.state.entryModel,
        this.state.myEntry,
        this.props.entryInfo,
        prevProps.currentWeek.id,
        this.props.currentWeek.id
      );

      let temporalEntry = this.props.entryInfo;
      if (temporalEntry && temporalEntry.user_tiebreakers) {
        temporalEntry.user_tiebreakers = _.map(this.state.tiesPicks, (tiePick) => {
          let obj = {
            entry_id: this.state.myEntry.entry_id,
            score_away: tiePick.score_away,
            score_home: tiePick.score_home,
            value: tiePick.value,
            tiebreaker_id: tiePick.tiebreaker_id,
            week_id: tiePick.week_id,
          };
          // console.log(obj);
          return obj;
        });
      }

      this.initializeEntry(
        temporalEntry,
        this.props.fixtures,
        this.props.fixtureProps,
        this.props.poolInfo,
        this.props.currentWeek.id,
        this.state.myEntry,
      )
        .then((response) => {
          let responseEntry = response;
          let tieData = this.initializeTies(this.props.poolInfo, this.props.entryInfo);
          responseEntry.user_tiebreakers = tieData;
          this.setState({
            myEntry: responseEntry,
            tiesPicks: tieData,
            numPicks: this.checkLen(responseEntry),
          });
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
    }
  }

  /***********************
   * Render
   * **********************/

  componentDidMount() {
    // console.log( 'QuinielaPicksUI' );
    // console.log( this.props.currentWeek );

    let showGameTutorial = utils.getShowGameTutorial();

    if (
      showGameTutorial &&
      showGameTutorial.hasOwnProperty('show') &&
      showGameTutorial.show &&
      showGameTutorial.step === 0
    ) {
      this.handleCallToAction('abonar');
      // this.props.handleTutorialStep(  );
    }

    this.initializeEntry(
      this.props.entryInfo,
      this.props.fixtures,
      this.props.fixtureProps,
      this.props.poolInfo,
      this.props.currentWeek.id,
      this.state.entryModel,
      this.state.entryModel
    )
      .then((response) => {
        let temporalEntry = response;
        let tieData = this.initializeTies(this.props.poolInfo, this.props.entryInfo);
        // console.log( response );
        temporalEntry.user_tiebreakers = tieData;

        this.setState({
          modalTutorial: this.validateShowModalImageTutorial(),
          myEntry: temporalEntry,
          tiesPicks: tieData,
          numPicks: this.checkLen(temporalEntry),
        });
      })
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
      });
  }

  validarTotalPickContador = () => {
    if (this.props.poolInfo.pool.max_picks !== null) {
      return this.props.poolInfo.pool.max_picks;
    }

    if (this.props.poolInfo.pool.format !== 'mixed' && this.props.poolInfo.pool.type === 'full') {
      return this.props.currentWeek.fixture_count;
    }

    return this.props.poolInfo.fixtures_count + this.props.poolInfo.props_count;
  };

  render() {
    // If user has other entries
    const hasOtherEntries = (
      this.props.entriesInfo &&
      this.props.entriesInfo.hasOwnProperty('other_entries') &&
      this.props.entriesInfo?.other_entries?.length > 0
    )

    // Current active entry
    const activeEntryNumber = this.props.entryInfo?.number === null
      ? 1
      : this.props?.entryInfo?.number

    let conditionalComponent;
    // isFull
    conditionalComponent = (
      <QuinielaDataConsumer>
        {({
          entriesInfo,
          fixtureProps,
          handleTutorialStep,
          fixtures,
          firstFixture,
          poolInfo,
          poolWeeks,
          poolWeekSelected,
          popUpNewUsersStatus,
          selectedEntry,
          similarPools,
          selectedEntryData,
          updateAfterRegister,
          updateUserInfo,
          userData,
          withRegister,
          updateEntriesParent,
        }) => {
          return (
            <React.Fragment>
              <Prompt
                when={this.state.picksUnsave}
                message={this.handleShowModalPicksUnsave}
                />
              <Modal
                backdropClassName="tutorialSurvivorBackdrop"
                dialogClassName="infoReferralsPopUp modal-dialog-xss"
                onHide={() => {}}
                show={this.state.showModalPicksUnsave}
              >
                <div className="panel-abonar">
                  <div
                    id="panelTitle"
                    className="row-center flex-row row-no-padding panel-abonar-titulo"
                    style={{ justifyContent: 'initial' }}
                  >
                    <span className="flex-col">No has guardado tus picks</span>
                    <button
                      className="cerrar-panel "
                      onClick={this.handleStatusPicksUnsave}
                    >
                      <i className="ion-ios-close-empty"></i>
                    </button>
                  </div>
                </div>
                <div className="flex-row row-no-padding pickwin-panel">
                  <div className="flex-col panel-pickwin row-center">
                    <div className="flex-container justify column">
                      <h5 className='text-center mt-1'>Hiciste modificaciones a tus picks. No olvides guardar tus picks después de hacer modificaciones</h5>
                      <button type="button" className="guardar-picks btn btn-success" onClick={this.handleStatusPicksUnsave}><span> CERRAR </span></button>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                backdropClassName="tutorialSurvivorBackdrop"
                dialogClassName="infoReferralsPopUp"
                onHide={this.handlePopUpReferral}
                show={this.state.modalTutorial.show}
              >
                <div className="panel-abonar">
                  <div
                    id="panelTitle"
                    className="row-center flex-row row-no-padding panel-abonar-titulo"
                    style={{ justifyContent: 'initial' }}
                  >
                    <span className="flex-col">{this.state.modalTutorial.title}</span>
                    <button
                      className="cerrar-panel "
                      onClick={() => {
                        this.handlePopUpReferral();
                      }}
                    >
                      <i className="ion-ios-close-empty"></i>
                    </button>
                  </div>
                </div>
                <div className="flex-row row-no-padding pickwin-panel">
                  <div className="flex-col panel-pickwin row-center" style={{marginTop: '0px'}}>
                    <img
                      src={this.state.modalTutorial.routeImage}
                      style={{ maxWidth: '473px', width: '100%', height: 'auto' }}
                    />
                  </div>
                </div>
              </Modal>
              <Modal
                show={this.state.submitting}
                className="responseDepositModal absoluteModal"
                dialogClassName="responseDepositDialog"
                backdropClassName="responseDepositBackdrop"
              >
                <div className="flex-container justify align column">
                  <i className="ion-load-a loading"> </i>
                  <div className="flex-row row-center">
                    <div className="flex-col col-center text-center">
                      <strong>'Espera un momento porfavor...'</strong>
                    </div>
                  </div>
                </div>
              </Modal>
              <RegisterPanel
                handleTutorialStep={
                  handleTutorialStep ? handleTutorialStep : this.props.handleTutorialStep
                }
                updateMyEntryData={this.updateMyEntryData}
                closePanel={this.closePanelRegister}
                entryId={selectedEntry ? selectedEntry : null}
                logged={utils.isLogged()}
                picks={this.state.picks}
                pool={poolInfo.pool}
                popUpNewUsersStatus={popUpNewUsersStatus}
                props={this.state.props}
                quinaID={poolInfo.pool.id}
                updateAfterRegister={updateAfterRegister}
                redirectToMisQuinielas={this.redirectToMisQuinielas}
                showPanel={this.state.showConfirmPanel}
                showTutorial={this.showTutorial}
                registerPicks={this.state.myEntry}
                temporalEntry={this.state.myEntry}
                tiebreakers={this.state.tiesPicks}
                updateStateFunc={this.closePanelRegister}
                updateUserInfo={updateUserInfo}
                userData={utils.getUserInfo()}
                userBalance={utils.getUserBalance()}
              />
              <div className="panel-pickwin border-gradient-modal" id="picksContainer">
                <div className='backBlack'>
                  <ConfirmationOverlay
                    isError={this.state.saveError}
                    errorMessage={this.state.errorMessage}
                    isSuccess={this.state.saveSuccess}
                    saveMessage={'Salvados'}
                    showOverlay={this.state.showOverlay}
                    label={'Guardado'}
                    currentSavedPicks={this.state.numPicks}
                    allPicks={
                      this.validarTotalPickContador()

                      // this.props.poolInfo.pool.max_picks !== null ?
                      //   (this.props.poolInfo.pool.max_picks)
                      //   :
                      //   (
                      //     this.props.poolInfo.pool.format !== 'mixed' ?
                      //       ( this.props.poolInfo.fixtures_count )
                      //       :
                      //       ( this.props.poolInfo.fixtures_count + this.props.poolInfo.props_count)
                      //   )
                    }
                    isCopy={this.state.copyInProgress}
                    parlay={this.props.parley}
                    parlayFlag={poolInfo.pool.parlay_flag}
                  />
                  <div
                    className={`flex-row ${
                      this.props.misQuinielas ? 'titulo-mis-picks panel-quiniealas' : 'title-quiniela'
                    }`}
                  >
                    {this.props.misQuinielas ? (
                      <div className="flex-container column tit">
                        <div className="flex-row row-no-padding font-chivo">MIS PICKS</div>
                        {this.props.poolInfo.pool.type === 'full' ? (
                          <span className=" flex-row row-no-padding weekName" style={{fontSize: '0.7em'}}>
                            <i className="ion-flag" />
                            {this.props.poolInfo.pool.type === 'full'
                              ? this.props.currentWeek.week_name
                              : this.props.poolWeeks.current.week_name}
                          </span>
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex-col col-40 col-no-padding title-wrapper">
                        <div className="flex-row row-no-padding row-center">
                          <img src={picks} className="pool-icons tablas" />
                          <span className='font-chivo'>
                            MIS PICKS
                            {hasOtherEntries && (
                              <sup>[{activeEntryNumber}]</sup>
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                    {this.props.misQuinielas ? (
                      <div className="flex-container column justify align " style={{ gap: '0.3em'}}>
                        {this.props.poolInfo.pool.type === 'full' ? (
                          <div className="flex-col col-no-padding  col-center boton-titulo-dash jornadas">
                            <SelectWeek
                              currentWeek={this.props.currentWeek}
                              poolWeeks={this.props.poolWeeks}
                              changeWeek={this.props.changeWeek}
                            />
                          </div>
                        ) : null}
                        <div className="flex-row row-no-padding "  style={{ gap: '0.3em'}}>
                          {this.props.poolInfo.pool.status !== 'closed' &&
                          this.props.similarPools &&
                          this.props.similarPools.similar_entries.length > 0 ? (
                            <div className="flex-col boton-titulo-dash col-center col-no-padding">
                              <CopiarPicks
                                misQuinielas={this.props.misQuinielas}
                                currentEntry={this.props.entryInfo}
                                handleCopy={this.handleCopy}
                                poolId={this.props.poolInfo.pool.id}
                                replaceEntryWithCopy={this.replaceEntryWithCopy}
                                show={
                                  this.props.similarPools &&
                                  this.props.similarPools.similar_entries.length > 0
                                }
                              />
                            </div>
                          ) : null}
                          <div className="flex-col boton-titulo-dash">
                            <Link
                              className="flex-row row-no-padding"
                              to={`/quiniela/${this.props.poolInfo.pool.slug}/${this.props.poolInfo.pool.id}/${this.props.entryInfo.id}`}
                            >
                              <Button className="ir-quiniela flex-container">Ir a quiniela</Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex-col col-no-padding col-center">
                        <div className="flex-row">
                          <CopiarPicks
                            misQuinielas={this.props.misQuinielas}
                            currentEntry={this.props.entryInfo}
                            handleCopy={this.handleCopy}
                            poolId={this.props.poolInfo.pool.id}
                            replaceEntryWithCopy={this.replaceEntryWithCopy}
                            show={
                              this.props.similarPools &&
                              this.props.similarPools.hasOwnProperty('similar_entries') &&
                              this.props.similarPools.similar_entries.length > 0
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {(this.props.poolInfo.pool.max_picks !== null && !this.props.misQuinielas) || this.props.poolInfo.pool.parlay_flag ? (
                    <div
                      className="flex-row row-center"
                      style={{
                        paddingTop: '4px',
                        fontSize: '80%',
                        borderBottom: '#424041 0.2px solid',
                      }}
                    >
                      {this.props.poolInfo.pool.parlay_flag === null && (
                        <div className="flex-col">
                          Esta quiniela tiene un límite de {this.props.poolInfo.pool.max_picks} picks
                          por semana
                          <br />
                          <span className="important-message">
                            La fecha límite para ingresar todos tus picks es el{' '}
                            {moment(this.props.firstFixture.deadline).format('ddd DD, MMM HH:mm')}
                          </span>
                        </div>
                      ) || (
                        <div className="flex-col">
                          Esta quiniela tiene un límite de {this.props.poolInfo.pool.max_picks} picks
                          por semana
                          <br />
                          <span className="important-message">
                            La fecha límite para ingresar todos tus picks es el{' '}
                            {moment(this.props.firstFixture.deadline).format('ddd DD, MMM HH:mm')}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : null}

                   {/* Solo mostrar si son menos de 4 jornadas de lo contrario se renderiza en la parte superios en WeeksControl */}
                    {this.props?.weeks?.length <= 4 && (
                      <div className='inside-seasons-picks'>
                        <WeeksControls
                          // Prop drilling <WeeksControls />
                          weeks={this.props.weeks}
                          handleWeekChange={this.props.handleWeekChange}
                          selectedWeek={this.props.selectedWeek}
                        />
                      </div>
                    )}
                  <EstatusQuiniela
                    misQuinielas={this.props.misQuinielas}
                    myEntry={this.state.myEntry}
                    fixtureProps={this.props.fixtureProps}
                    poolInfo={this.props.poolInfo}
                    fixtures={this.props.fixtures}
                    firstFixture={this.props.firstFixture}
                    tiebreakers={this.props.tiebreakers}
                    numPicks={this.state.numPicks}
                    poolWeekSelected={poolWeekSelected}
                    parley={this.props.parley}
                  />
                  <div className="quiniela-picks">

                    <Picks
                      updateStartedFixture={this.props.updateStartedFixture}
                      poolInfo={poolInfo}
                      fixtures={this.props.fixtures}
                      parley={this.props.parley}
                      firstFixture={this.props.firstFixture}
                      myEntry={this.state.myEntry}
                      handlePick={this.handlePick}
                      handleRemovePick={this.handleRemovePick}
                      poolWeeks={this.props.poolWeeks}

                      // Prop drilling <WeeksControls />
                      weeks={this.props.weeks}
                      handleWeekChange={this.props.handleWeekChange}
                      selectedWeek={this.props.selectedWeek}
                    />
                    <PicksProps
                      poolInfo={poolInfo}
                      fixtures={fixtures}
                      fixtureProps={fixtureProps}
                      firstFixture={this.props.firstFixture}
                      myEntry={this.state.myEntry}
                      handlePick={this.handlePick}
                      handleRemovePick={this.handleRemovePick}
                    />
                    {poolInfo.pool.use_tibreakers ? (
                      <div className="quiniela-desempates">
                        <Desempates
                          enoughBalance={this.props.enoughBalance}
                          poolWeeks={poolWeeks}
                          poolWeekSelected={poolWeekSelected}
                          updateTieData={this.updateTieData}
                          entriesInfo={entriesInfo}
                          isLoaded={
                            this.state.tiesPicks !== undefined && this.state.tiesPicks !== null
                          }
                          fixtures={fixtures}
                          desempates={poolInfo.tiebreakers}
                          poolInfo={poolInfo}
                          tiesPicks={this.state.tiesPicks}
                        />
                      </div>
                    ) : null}
                    {poolInfo.pool.status !== 'closed' ? (
                      <CallToActionButton
                        isReferral={poolInfo.pool.entry_currency === 'referral'}
                        withRegister={withRegister}
                        isDisabled={
                          !utils.isLogged() ||
                          utils.getUserInfo().group_id === 2 ||
                          (poolInfo.pool.is_full && !withRegister)
                        }
                        misQuinielas={this.props.misQuinielas}
                        submitting={this.state.submitting}
                        enoughBalance={this.props.enoughBalance}
                        handleCallToAction={this.handleCallToAction}
                        handleAbonar={this.handleAbonar}
                        isFirstRegister={!entriesInfo}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </QuinielaDataConsumer>
    );

    return <ErrorBoundary isPanel> {conditionalComponent} </ErrorBoundary>;
  }
}

export default QuinielaPicksUI;
