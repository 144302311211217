import React, { useState, useEffect } from 'react';
import utils from '../../utils/utils';
import branchUtils from '../../utils/branchUtils';

import {
  Button,
  Modal
} from 'react-bootstrap/lib/';

import { Link, Redirect } from 'react-router-dom';

import infoFreeRollTutorial from '../../assets/images/tutorial/freeRollTutorial/infoLobbyTutorial.png';

import labelHelp from '../../assets/images/tutorial/help/label.png';
import pickwinero from '../../assets/images/tutorial/help/pickwinero.png';

import step1UI from '../../assets/images/tutorial/uiTutorial/step1-picks.png';
import step2UI from '../../assets/images/tutorial/uiTutorial/step2-props.png';
import step3UI from '../../assets/images/tutorial/uiTutorial/step3-ties.png';
import step4UI from '../../assets/images/tutorial/uiTutorial/step4-misquinas.png';

import stepAbonar from '../../assets/images/tutorial/productFlow/step0-abonar.png';

const TutorialControls = ({
  handleTutStep,
  handleRedirection,
  type,
  handleSteps,
  stepTutorial,
}) => {
  if (type === 'product' && stepTutorial === 0) {
    return (
      <div className="flex-row">
        <Button
          className="btn-pick callToAction flex-col col-80 col-offset-10"
          onClick={() => {
            console.log('Remove the FreeRoll localStorage and initialize tutorial state');

            handleTutStep(0, 'product', false);
            handleTutStep(0, 'organic', false);
            utils.removeGameTutorial();
            utils.deletePopUpNewUser();
          }}
        >
          OK
        </Button>
      </div>
    );
  }
  if (type === 'freeRoll' && stepTutorial === 1) {
    return (
      <div className="flex-row">
        <Button
          className="btn-pick callToAction flex-col col-40 col-offset-30"
          onClick={() => {
            console.log('Remove the FreeRoll localStorage and initialize tutorial state');
            handleTutStep(0, 'freeRoll', false);

            handleTutStep(0, 'organic', false);
            // utils.showTutorialLobby( 0 );
            utils.removeShowGameFreeRoll();
          }}
        >
          OK
        </Button>
      </div>
    );
  } else {
    return (
      <div className="flex-row">
        <Button
          className="btn-pick callToAction flex-col col-80 col-offset-10"
          onClick={() => {
            console.log('HEEREE');
            if (stepTutorial === 3) {
              console.log('clicked Mis quinielas');
              handleTutStep(0, 'organic', false);
              utils.removeTutorialLobby();
              handleRedirection(true);
              // utils.setReadyToVerify( true );
            } else if (stepTutorial === 4) {
            } else {
              handleSteps(stepTutorial + 1);
            }
          }}
        >
          OK
        </Button>
      </div>
    );
  }
};

const TutorialCanvas = ({ stepTutorial, type }) => {
  let conditionalComponent = null;

  if (type === 'freeRoll') {
    conditionalComponent = (
      <div className="infoFreeRollTutorial flex-row row-no-padding">
        <img className="img-responsive" src={infoFreeRollTutorial} />
      </div>
    );
  } else if (type === 'product') {
    conditionalComponent = (
      <div className="flex-row row-no-padding">
        <img className="tutorial-images" src={stepAbonar} />
      </div>
    );
  } else {
    switch (stepTutorial) {
      case 0:
        conditionalComponent = (
          <div className="flex-row row-no-padding infoFreeRollTutorial">
            <img className="tutorial-images" src={step1UI} />
          </div>
        );
        break;
      case 1:
        conditionalComponent = (
          <div className="flex-row row-no-padding infoFreeRollTutorial 1">
            <img className="tutorial-images" src={step2UI} />
          </div>
        );
        break;
      case 2:
        conditionalComponent = (
          <div className="flex-row row-no-padding infoFreeRollTutorial 2">
            <img className="tutorial-images" src={step3UI} />
          </div>
        );
        break;
      case 3:
        conditionalComponent = (
          <div className="flex-row row-no-padding infoFreeRollTutorial 3">
            <img className="tutorial-images" src={step4UI} />
          </div>
        );
        break;
    }
  }

  return <div className="flex-row row-no-padding row-center">{conditionalComponent}</div>;
};

/**
 *
 * Component for Game Product Tutorial
 * */
const TutorialPickwinUI = ({ show, step, handleTut, handleTutStep }) => {
  let [showTutorial, handleTutorial] = useState(false);
  let [handleAbonarSado] = useState(false);
  let [handleSteps, setHandleSteps] = useState(0);
  let [redirect, handleRedirection] = useState(false);

  const redirectToMisQuinas = () => {
    console.log('Redirect');
    handleRedirection(true);
  };

  const changeSteps = (nextStep) => {
    console.log('Steps Changed', nextStep);
    if (nextStep === 3) {
      console.log('Deactivate show');
      handleTutStep(nextStep, 'organic', false);
      handleTutorial(false);
    } else {
      setHandleSteps(nextStep);
      handleTutStep(nextStep, 'organic', true);
      handleTutorial(true);
    }
  };
  // console.log('TutorialPickwinUI',show,step);

  useEffect(() => {
    if (show) {
      handleTutorial(true);
      setHandleSteps(step);
      console.log('Open Tutorial panel');
    }
  }, [show, step]);

  // console.log( showTutorial );
  let conditionalComponent = null;
  if (!showTutorial || !show) {
    return null;
  }

  console.log(redirect);

  if (redirect) {
    console.log('Redirect Here');
    conditionalComponent = <Redirect push to={{ pathname: '/mis_quinielas' }} />;
  } else {
    conditionalComponent = (
      <div id="pickwinOverlay" className="tutorialFullScreen">
        <div className="flex-container justify align column">
          <TutorialCanvas type={'gameTutorial'} stepTutorial={step} />
          <TutorialControls
            handleRedirection={redirectToMisQuinas}
            handleTut={handleTut}
            handleTutStep={handleTutStep}
            type="gameTutorial"
            stepTutorial={step}
            handleAbonarSaldo={handleAbonarSado}
            handleSteps={changeSteps}
          />
        </div>
      </div>
    );
  }

  return conditionalComponent;
};
export const TutorialAbonar = ({ show, step, handleTut, handleTutStep }) => {
  let [showTutorial, handleTutorial] = useState(false);
  let [handleAbonarSado] = useState(false);
  let [handleSteps] = useState(0);

  const changeSteps = (nextStep) => {
    console.log('Steps Changed', nextStep);
    if (nextStep !== 0) {
      console.log('Deactivate show');
      handleTutStep(nextStep, 'organic', false);
      handleTutorial(false);
    } else {
      handleSteps(nextStep);
      handleTutStep(nextStep, 'organic', true);
      handleTutorial(true);
    }
  };
  // console.log('TutorialPickwinUI',show,step);

  useEffect(() => {
    if (show) {
      handleTutorial(true);
      handleSteps(step);
      console.log('Open Tutorial panel');
    }
  }, [show, step, handleSteps]);

  // console.log( showTutorial );
  let conditionalComponent = null;
  if (!showTutorial || !show) {
    return null;
  }

  conditionalComponent = (
    <div id="pickwinOverlay" className="tutorialFullScreen">
      <div className="flex-container justify align column">
        <TutorialCanvas type={'product'} stepTutorial={step} />
        <TutorialControls
          handleTut={handleTut}
          handleTutStep={handleTutStep}
          type="product"
          stepTutorial={step}
          handleAbonarSaldo={handleAbonarSado}
          handleSteps={changeSteps}
        />
      </div>
    </div>
  );

  return conditionalComponent;
};

/**
 * Component for FreeRoll
 */
const ShowFreeRoll = ({ show, step, handleTut, handleTutStep, remove }) => {
  let [showTutorial, handleTutorial] = useState(false);
  let [handleAbonarSado] = useState(false);
  let [handleSteps, setHandleSteps] = useState(0);
  let [redirect, handleRedirection] = useState(false);

  const changeSteps = (nextStep) => {
    console.log('Steps Changed', nextStep);
    if (nextStep === 3) {
      handleTutStep(nextStep, 'organic', false);
      handleTutorial(false);
      handleRedirection(true);
    } else {
      handleSteps(nextStep);
      handleTutStep(nextStep, 'organic', true);
      handleTutorial(true);
    }
  };
  // console.log('TutorialPickwinUI',show,step);

  useEffect(() => {
    if (show) {
      handleTutorial(true);
      setHandleSteps(step);
      // console.log( 'Open Tutorial panel' );
    }
  }, [show, step, handleSteps]);

  // console.log( 'showTutorial' );
  // console.log( showTutorial , show, step, redirect );
  let conditionalComponent = null;
  if (redirect) {
    // console.log('Redirect Here');
    conditionalComponent = <Redirect push to={{ pathname: '/mis_quinielas' }} />;
  } else {
    if (!showTutorial && !show) {
      return null;
    }
    if (step !== 1) {
      return null;
    }
    conditionalComponent = (
      <div id="pickwinOverlay" className="tutorialFullScreen">
        <div className="flex-container justify align column">
          <TutorialCanvas type={'freeRoll'} stepTutorial={step} />
          <TutorialControls
            handleRedirection={handleRedirection}
            handleTut={handleTut}
            handleTutStep={handleTutStep}
            type="freeRoll"
            stepTutorial={step}
            handleAbonarSaldo={handleAbonarSado}
            handleSteps={changeSteps}
          />
        </div>
      </div>
    );
  }

  return conditionalComponent;
};
export const HelpPanel = ({ show, step, handleTut, handleTutStep, remove }) => {
  let [showTutorial, handleTutorial] = useState(false);

  const openWhatsAppHelp = () => {
    // console.log('OpenWhatsAppHelp');
    branchUtils.openWhatsAppHelp();
  };

  useEffect(() => {
    if (show) {
      handleTutorial(true);
      // console.log( 'Open help panel' );
    }
  }, [show]);

  // console.log( 'show help' );
  // console.log( showTutorial , show  );
  let conditionalComponent = null;
  if (!showTutorial && !show) {
    utils.removeHelpPanel();
    return null;
  }

  conditionalComponent = (
    <Modal
      size="lg"
      backdropClassName="backdropWelcome"
      dialogClassName="welcomeModal"
      className="welcomeModal"
      show={showTutorial}
      onHide={() => {
        handleTutStep(0, 'help', false);
        handleTutorial(false);
        //  utils.setReadyToVerify( true );
      }}
    >
      <div id="loginPanel" className="panel-pickwin tutorialBackground">
        <div id="panelTitle" className=" flex-row row-center row-no-padding">
          <span className="flex-col">AYUDA</span>
          <div className="flex-col col-10">
            <button
              className="cerrar-panel"
              onClick={() => {
                handleTutStep(0, 'help', false);
                handleTutorial(false);
                //  utils.setReadyToVerify( true );
              }}
            >
              <div className="btn-icon-close">
                <i className="ion-ios-close-empty" />
              </div>
            </button>
          </div>
        </div>
        <div className="flex-col">
          <div className="flex-row row-center">
            <div className="flex-col col-center col-80 col-offset-10">
              <div className="flex-row row-no-padding">
                <img className="img-responsive" src={labelHelp} />
              </div>
              <div className="flex-row row-no-padding">
                <div className="flex-container justify align column">
                  <img className="img-responsive" src={pickwinero} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="flex-col col-80 col-offset-10">
              <Button className="flex-row btn-pick text-center " onClick={openWhatsAppHelp}>
                <span className="flex-col"> Chatear con alguien de Pickwin </span>
              </Button>
              {step === 1 ? null : (
                <Link to="/">
                  <Button
                    className="flex-row "
                    onClick={() => {
                      utils.setShowGameFreeRoll(0, true);
                    }}
                  >
                    {' '}
                    <span className="flex-col uppercase"> Ver mis quinielas Gratis </span>{' '}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return conditionalComponent;
};

export { TutorialPickwinUI, ShowFreeRoll };
