import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap/lib/'
import 'jquery'

// Hooks
import useMergedState from '../../hooks/useMergedState'
import useUserContext from '../../hooks/useUserContext'

// Utils
import utils from '../../utils/utils'

// Assets
import bannerPromocion from '../../assets/images/banners/duplicamos-abonar-saldo.png'
import flechas from '../../assets/images/flechasIconBlanco-50.png'
import FormDepositoFeenicia from '../FormDepositoFeenicia'
import closeImg from '../../assets/img/bonos/Icono-cerrar-ventana.png'
import bannerImage from '../../assets/img/bonos/banner.png'

const initialState = {
  isNewAndDeposit: false,
  showExchangePanel: false,
  tokenErrors: {
    1001: 'cvv2 debe de ser de 3 dígitos.',
    2001: 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.',
    2002: 'La tarjeta con este número ya se encuentra registrada en el cliente.',
    2003: 'El cliente con este identificador externo (External ID) ya existe.',
    2004: 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.',
    2005: 'La fecha de vencimiento de la tarjeta es anterior a la fecha actual.',
    2006: 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
    2007: 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.',
    2008: 'La tarjeta no es valida para puntos Santander.',
    2009: 'El código de seguridad de la tarjeta (CVV2) es inválido.'
  },
  requestErrors: {
    1001: 'Revisa el cvv2 de tu tarjeta.',
    2001: 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.',
    2002: 'La tarjeta con este número ya se encuentra registrada en el cliente.',
    2003: 'El cliente con este identificador externo (External ID) ya existe.',
    2004: 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.',
    2005: 'La fecha de vencimiento de la tarjeta es anterior a la fecha actual.',
    2006: 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
    2007: 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.',
    2008: 'La tarjeta no es valida para puntos Santander.',
    2009: 'El código de seguridad de la tarjeta (CVV2) es inválido.',
    1018: 'El número de intentos ha sido excedido',
    3001: 'La tarjeta fue declinada.',
    3002: 'La tarjeta ha expirado.',
    3003: 'La tarjeta no tiene fondos suficientes.',
    3004: 'La tarjeta ha sido identificada como una tarjeta robada.',
    3005: 'La tarjeta ha sido rechazada por el sistema antifraudes.',
    3006: 'La operación no esta permitida para este cliente o esta transacción.',
    3007: 'La tarjeta fue declinada.',
    3008: 'La tarjeta no es soportada en transacciones en línea.',
    3009: 'La tarjeta fue reportada como perdida.',
    3010: 'El banco ha restringido la tarjeta.',
    3011: 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.',
    3012: 'Se requiere solicitar al banco autorización para realizar este pago.'
  }
}

function AddBalance (props) {
  const [state, setState] = useMergedState(initialState)
  const { userData, updateUserInfo, updateUserBalance } = useUserContext()

  const handleNewUserWithDeposit = (status) => {
    setState({ isNewAndDeposit: status })
  }

  const initializeTutorial = () => {
    props.handleTutorialStep(0, 'organic', true)
  }

  const handleHelpPanel = () => {
    utils.removeTutorialLobby()
    props.handleTutorialStep(0, 'help', true)
  }

  switch (props.type) {
    case 'modal': return (
      <Modal
        dialogClassName='abonarSaldoModal'
        className='abonarModal absoluteModal'
        backdropClassName='abonarModalBackdrop'
        show={props.showAbonarSaldo}
      >

        <CloseButton
          onClick={() => {
            if (props.handleTutorialStep && state.isNewAndDeposit) {
              initializeTutorial()
            } else if (
              props.handleTutorialStep &&
              !state.isNewAndDeposit &&
              userData.is_new &&
              userData.entry_count === 0
            ) {
              handleHelpPanel()
            }
            if (props.dispatch) {
              props.updatePanelSate(props.dispatch)
            } else {
              props.updatePanelSate()
            }
          }}
        >
          <img src={closeImg} alt="" />
        </CloseButton>

        <div className='panel-abonar --transparent'>
          <Title>Abonar Saldo</Title>
          {/* <div id='panelTitle' className='panel-abonar-titulo'>
            <span>Abonar Saldo</span>
            <button
              className='cerrar-panel'
              onClick={() => {
                if (props.handleTutorialStep && state.isNewAndDeposit) {
                  initializeTutorial()
                } else if (
                  props.handleTutorialStep &&
                  !state.isNewAndDeposit &&
                  userData.is_new &&
                  userData.entry_count === 0
                ) {
                  handleHelpPanel()
                }
                if (props.dispatch) {
                  props.updatePanelSate(props.dispatch)
                } else {
                  props.updatePanelSate()
                }
              }}
            >
              <div className='btn-icon-close'>
                <i className='ion-ios-close-empty' />
              </div>
            </button> 
          </div>
*/}

          {/* <div className='col-xs-12 panel-pickwin'>
            <a target='blank' href='https://blog.pickwin.mx/2018/08/14/aqui-te-explicamos-como-funciona-el-bono-por-deposito/'>
              <img src={bannerPromocion} className='img-responsive' />
            </a>
          </div> */}
          <div className='col-xs-12'>
            <div style={{ margin: '15px' }}>
              <FormDepositoFeenicia
                handleNewUserWithDeposit={handleNewUserWithDeposit}
                handleTutorialStep={props.handleTutorialStep}
                dispatch={props.dispatch}
                type={props.type}
                // updateEnoughBalance={props.updateBalance}
                updateUserInfo={updateUserInfo}
                updateUserBalance={updateUserBalance}
                closeAbonar={props.close}
              />
            </div>
          </div>
        </div>
      </Modal>
    )

    case 'static': return (
      <div className=' category-user row'>
        <div className='abonarSaldoUserInfo col-xs-12'>
          <div className='info-personal row'>
            <div className='titulo-user-info col-xs-12'>
              <div className='titulo-user-info'>
                <img src={flechas} className='title-icon' />
                <span>Abonar Saldo</span>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-xs-12">
                <a
                  target="blank"
                  href="https://blog.pickwin.mx/2018/08/14/aqui-te-explicamos-como-funciona-el-bono-por-deposito/"
                >
                  <img src={bannerPromocion} className="img-responsive" />
                </a>
              </div>
            </div> */}
            <div className='col-xs-12'>
              <div style={{ margin: '15px' }}>
                <FormDepositoFeenicia
                  handleNewUserWithDeposit={handleNewUserWithDeposit}
                  type={props.type}
                  updateUserInfo={updateUserInfo}
                  updateUserBalance={updateUserBalance}
                  closeAbonar={props.close}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    default: return null
  }
}

const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  outline: 0;
  width: 2.5em;
  margin-left: auto;
  display: block;
  top: 1.3em;
  position: relative;

  img {
    width: 100%;
  }
`

const Title = styled.div`
  font-size: 1.5em;
  font-family: 'Chivo';
  font-weight: 400;
  text-align: center;
  line-height: 1.8;
`

const Banner = styled.img`
  width: 100%;
  min-height: 80px;
  max-width: 722px;
  margin: 0 auto;
  display: block;
`

export default AddBalance
