import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import _ from 'underscore'
import ReactTooltip from 'react-tooltip'

// Assets
import tickets from '../../../../../assets/images/silver_ticket_pick_empty.png'


export default function TicketsList ({ ticketsList, handleTicket }) {
  let list = null
  if (!ticketsList) {
    return null
  }
  list = _.map(ticketsList, (ticket, ticketIndx) => {
    let gameType = null

    switch (ticket.model) {
      case 'Pools':
        gameType = 'quiniela'
        break
      case 'Brackets':
        gameType = 'bracket'
        break
      case 'Survivors':
        gameType = 'survivor'
        break
      default:
        break
    }
    let entryFee = 0
    let ticketAmount = 0
    const ticketLink = !ticket.entity_id
      ? 'Abierto'
      : `/${gameType}/${gameType}/${ticket.entity_id}`

    if (!ticket.amount) {
      switch (gameType) {
        case 'quiniela':
          if (!ticket.pool) {
            entryFee = 0
          } else {
            entryFee = ticket.pool.entry_fee
          }
          break
        case 'bracket':
          if (!ticket.bracket) {
            entryFee = 0
          } else {
            entryFee = ticket.bracket.entry_fee
          }
          break
        case 'survivor':
          if (!ticket.survivor) {
            entryFee = 0
          } else {
            entryFee = ticket.survivor.entry_fee
          }
          break
      }
    }

    ticketAmount = !ticket.amount
      ? numeral(entryFee / 100).format('$0,000')
      : numeral(ticket.amount / 100).format('$0,000')

    let labelToolTip = null
    let link = null
    // console.log(ticketAmount);

    switch (gameType) {
      case 'quiniela':
        labelToolTip = '¡Ir a la Quiniela!'
        if (!ticket.pool) {
          link = null
        } else {
          link = (
            <a data-tip data-for={`goTo${ticket.id}`} className='shareable-link' href={ticketLink}>
              {' '}
              <i className='ion-link' /> {ticket.pool.name_replaced}{' '}
            </a>
          )
        }
        break
      case 'bracket':
        labelToolTip = '¡Ir al Bracket!'
        if (!ticket.bracket) {
          link = null
        } else {
          link = (
            <a data-tip data-for={`goTo${ticket.id}`} className='shareable-link' href={ticketLink}>
              {' '}
              <i className='ion-link' /> {ticket.bracket.name_replaced}{' '}
            </a>
          )
        }

        break
      case 'survivor':
        labelToolTip = '¡Ir al Survivor!'
        if (!ticket.survivor) {
          link = null
        } else {
          link = (
            <a data-tip data-for={`goTo${ticket.id}`} className='shareable-link' href={ticketLink}>
              {' '}
              <i className='ion-link' /> {ticket.survivor.name_replaced}{' '}
            </a>
          )
        }
        break
    }

    return (
      <div key={ticket.id} className='flex-container'>
        <div className='flex-row row-center '>
          <div className='flex-col col-10 col-center ticket-category'>
            <img src={tickets} className='pool-icons' alt='' />
          </div>
          <div className='flex-col col-40 col-center ticket-category'>
            {!ticket.entity_id ? <span>{ticketLink}</span> : link}
          </div>
          <div className='flex-col col-center col-15 ticket-categolink'> {ticketAmount} </div>
          <div className='flex-col col-center text-center  ticket-category'>
            {!ticket.expiration_date
              ? 'Sin vencimiento'
              : moment(ticket.expiration_date).format('DD/MM/YY hh:mm  ')}
          </div>
          <ReactTooltip id={`goTo${ticket.id}`} place='top' type='light' effect='solid'>
            {labelToolTip}
          </ReactTooltip>
        </div>
      </div>
    )
  })

  return list.length > 0
    ? (
      <div className='lista-tickets' style={{ paddingBottom: '20px' }}>
        <div className='flex-row row-center ticket-category-header'>
          <div className='flex-col col-10 ticket-category'> </div>
          <div className='flex-col col-40 ticket-category'> Juego </div>
          <div className='flex-col col-15  ticket-category'> Monto </div>
          <div className='flex-col text-center ticket-category'> Vence </div>
        </div>
        {list}
      </div>
      )
    : (
      <div className='lista-tickets' style={{ paddingBottom: '20px' }}>
        <div className='flex-row'>
          <div className='flex-col'>
            <div className='flex-container align justify'>
              <i className='ion-sad' style={{ fontSize: '3em' }} />
            </div>
            <div className='flex-row'>
              <span className='flex-container justify align'>No tienes tickets disponibles.</span>
            </div>
          </div>
        </div>
      </div>
      )
}
