import { withFormsy } from 'formsy-react';
import React from 'react';

import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from 'react-bootstrap/lib';

class TransferFormsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.changeValue = (event) => {
      // console.log('event',event);
      if (!event.currentTarget) {
        this.props.setValue(event.value);
      } else {
        this.props.setValue(event.currentTarget.value);
      }
    };
  }

  render() {
    let conditionalComponent = null;
    const errorMessage = this.props.getErrorMessage();
    let helpMessages =
      this.props.help !== undefined
        ? this.props.help.map((help, i) => <HelpBlock key={i}>{help}</HelpBlock>)
        : null;
    switch (this.props.type) {
      case 'NICK':
        conditionalComponent = (
          <div className="flex-container column">
            <FormGroup controlId={this.props.name}>
              <ControlLabel>{this.props.label}</ControlLabel>
              <FormControl
                type="text"
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || ''}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e);
                }}
              ></FormControl>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'AMOUNT':
        conditionalComponent = (
          <div className="flex-container column">
            <FormGroup controlId={this.props.name}>
              <ControlLabel>{this.props.label}</ControlLabel>
              <div className="flex-row row-no-padding row-center">
                <div className="flex-col col-10">
                  <span className="input-group-addon">$</span>
                </div>
                <FormControl
                  style={{ padding: '0', height: '34px' }}
                  className="flex-col "
                  inputMode="numeric"
                  min="1"
                  pattern="[0-9]*"
                  type="number"
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || ''}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                ></FormControl>
              </div>
            </FormGroup>

            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;
      case 'PASSWORD':
        conditionalComponent = (
          <div className="flex-container column ">
            <FormGroup controlId={this.props.name}>
              <ControlLabel>{this.props.label}</ControlLabel>
              <div className="flex-row row-no-padding input-container">
                <FormControl
                  className="flex-col "
                  type="password"
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e);
                  }}
                ></FormControl>
              </div>
            </FormGroup>
            <span className="error-formulario">{errorMessage}</span>
            {helpMessages}
          </div>
        );
        break;

      default:
        break;
    }
    return conditionalComponent;
  }
}

export default withFormsy(TransferFormsy);
