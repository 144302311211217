import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { Modal, Button } from 'react-bootstrap/lib';
import { Redirect } from 'react-router';
import ReactTooltip from 'react-tooltip';

import API from '../../utils/api';
import gaUtils from '../GAWrapper';
import utils from '../../utils/utils';
import PageWrapper from '../PageWrapper';
import actions from '../QuinielaGroupsUI/actions';
import {
  QuinielaGroupsContext,
  QuinielaGroupsContextProvider,
} from '../../context/QuinielaGroupsContext';
import { UserDataContextConsumer } from '../../context/UserDataContext';

import CancelRegister from '../CancelRegister/CancelRegister';
import Score from '../Score';
import ScoreMobile from '../ScoreMobile';
import RegisterPanel from '../RegisterPanel';
import InvitarAmigos from '../InvitarAmigos';
import AbonarSaldo from '../AbonarSaldo';
import HandleLayout from './HandleLayout';
import * as Sentry from '@sentry/browser';
import Clipboard from 'clipboard';


// import { PanelInvitarAmigos } from '../Quiniela';

import { ShareButtons } from 'react-share';

import InviteFriendsPlay from '../InviteFriendsPlay';
import invitaAmigos from '../../assets/images/invita-amigos-mundial.jpg';
import { subscribe, unsubscribe } from '../../utils/events';

const { FacebookShareButton, TwitterShareButton } = ShareButtons;

let clipboardCode;
export const PanelInvitarAmigos = ({
  title,
  dispatch,
  pool,
  openInvitePanel,
  closePanel,
  branchLink,
  whatsAppInvitation,
}) => {

  useEffect(() => {
    clipboardCode = new Clipboard('.copiar-btn');
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      console.log('wich', wich);
      $(wich).addClass('copied');
      e.clearSelection();
    });
  }, [])

  console.log('Panel', pool);
  let isMundial = false;
  let inviteText = '';
  if (pool.id === 12408) {
    isMundial = true;
    title = 'INVITA A TUS AMIGOS Y GANA ENTRADAS AL MUNDIAL PERFECTO';
    inviteText =
      'YO ESTOY JUGANDO LA QUINIELA DEL MUNDIAL TE INVITO A JUGAR LA MEJOR QUINIELA DEL MUNDIAL CON BOLSA DE $3,000,000';
  }

  const userData = utils.getUserInfo();
  return (
    <div className="panel-abonar">
      <div id="panelTitle" className="panel-abonar-titulo">
        <span>
          <i className="ion-flag" />
          {title}
        </span>
        <button
          className="cerrar-panel"
          onClick={() => {
            closePanel(dispatch);
          }}
        >
          <div className="btn-icon-close">
            <i className="ion-ios-close-empty" />
          </div>
        </button>
      </div>
      <div className="col-xs-12">
        <InviteFriendsPlay />
      </div>
      <div className="col-xs-12">
        <div className="detalles-privadas-compartir-container">
          {pool.id === 12408 ? (
            <img src={invitaAmigos} className="img-responsive" />
          ) : (
            <div className="col-xs-12">
              <div className="panel-invite-title text-center">
                Comparte este link con tus amigos
              </div>
              <div className="flex-row row-center flex-container justify">
                <div className="codigo-amigo flex-col">
                  <input
                    id="copiar-clipboard"
                    value={
                      branchLink !== undefined
                        ? branchLink
                        : `https://www.pickwin.mx/lobby/quinielaGrupoPop/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
                    }
                  />
                </div>
                <div className="copiar-button-codigo flex-col col-15">
                  <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                    {' '}
                    Copia el enlace y compártelo
                  </ReactTooltip>
                  <button
                    data-tip
                    data-for="copiar-tool"
                    className="copiar-btn"
                    data-clipboard-target="#copiar-clipboard"
                  >
                    <i className="ion-ios-copy " />
                    <span className="visible-md">Copiar</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="btn-group inviteToPlay flex-row">
            <FacebookShareButton
              quote={
                !isMundial
                  ? `Participa en la quiniela ${pool.name_replaced} visitando este link `
                  : inviteText
              }
              hashtag={'#Pickwin #registrate #JalaTusPicks'}
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/quiniela/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
              }
            >
              <Button className="fb-btn">
                {' '}
                Por Facebook <i className="ion-social-facebook" />
              </Button>
            </FacebookShareButton>
            <TwitterShareButton
              title={
                !isMundial
                  ? `Participa en la quiniela ${pool.name_replaced} visitando este link `
                  : inviteText
              }
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/quinielaGrupo/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
              }
              hasthag={'#Pickwin #registrate #JalaTusPicks'}
            >
              <Button className="twitter-btn">
                {' '}
                Por Twitter <i className="ion-social-twitter" />
              </Button>
            </TwitterShareButton>
            <div className="SocialMediaShareButton">
              <Button
                className="mail-btn"
                onClick={() => {
                  openInvitePanel(dispatch);
                }}
              >
                {' '}
                Por Mail <i className="ion-email" />
              </Button>
            </div>
            <div className="SocialMediaShareButton">
              <Button
                className="whatsapp-btn"
                onClick={() => {
                  whatsAppInvitation(branchLink, pool);
                }}
              >
                {' '}
                Por WhatsApp <i className="ion-social-whatsapp" />
              </Button>
            </div>
          </div>
          {pool.id === 12408 ? (
            <div className="flex-row">
              <div className="flex-col text-center">
                <small>
                  <strong>
                    *Por cada amigo que se registre a la quiniela de 3 millones, te regalamos una
                    entrada para jugar el mundial Perfecto
                  </strong>
                </small>
              </div>
            </div>
          ) : null}
        </div>
        <div className="btn-wrap">
          <Button
            className="btn-rojo gracias"
            onClick={() => {
              closePanel(dispatch);
            }}
          >
            No, gracias
          </Button>
        </div>
      </div>
    </div>
  );
};

const QuinielaGroupWrapper = ({
  isLogged,
  userData,
  userBalance,
  updateUserBalance,
  updateUserInfo,
  location,
  match,
}) => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);
  let poolId = match.params.poolGroupId;
  let entryId = match.params.entryId;
  //console.log("QGLSTATE:", state);



  const loadAllValues = () => {
    gaUtils.handleDocumentAttributes(match);

    if (entryId && !utils.isSuPick()) {
      console.log('%c Initial Side Effect with Entry', 'color:rgb(255, 199, 48) ; font-size: 2em;');
      Promise.all([
        actions.getPoolDetails(dispatch, poolId),
        actions.getUserData(dispatch),
        actions.getUserTickets(dispatch, poolId),
        actions.getPoolEntry(dispatch, entryId),
        actions.getSimilarPools(dispatch, entryId),
      ])
        .then(
          (values) => {
            let [poolDetails, userData, userTickets, poolEntry] = [...values];

            actions.getGroupPicks(dispatch, poolId, state.queryParamsGroupPicks, poolDetails.data.current_contest.id)
              .then((response) => {
                //console.log("GETGROUPPICKS", response);
              });

            actions.isBalanceEnough(
              dispatch,
              poolDetails.data.group_pool.entry_fee,
              poolDetails.data.group_pool.entry_currency,
              userTickets.data.tickets
            );

            actions.updateLocation(
              poolDetails.data.group_pool.slug,
              poolId,
              entryId,
              false,
              match,
              location
            );

            actions
              .initializeEntry(
                dispatch,
                poolId,
                poolDetails.data.buckets,
                poolDetails.data.bucketKeys,
                poolDetails.data.group_pool.group_pool_tiebreakers,
                poolEntry.data.group_pool_entry,
                true,
                poolDetails.data.players,
                poolDetails.data.current_contest,
                poolDetails.data.group_pool.entry_currency,
                poolDetails.data.group_pool.contests,
              )
              .then((response) => {
                actions.getPoolStandings(dispatch, poolId, state.queryParams, poolDetails.data.current_contest.id).then((response) => {
                  let poolStandings = response;
                  if (poolDetails.data.group_pool.status === 'live') {
                    let fakeObj = {
                      group_pool_id: poolDetails.data.group_pool.id,
                      standings: poolStandings && poolStandings.hasOwnProperty('data') ? poolStandings.data.items : [],
                      ties_distribution: poolDetails.data.ties_distribution,
                    };
                    actions.updateStandingsAmount(dispatch, fakeObj);
                  } else {
                    actions.initializeBucketsState(
                      dispatch,
                      state.poolBuckets, //poolDetails.data.buckets,
                      poolEntry.data.group_pool_entry,
                      poolDetails.data.current_contest,
                    );
                  }
                });
              });

            dispatch({ type: 'isReady' });

            API.subscribeSocketLiveGolf(
              poolId,
              poolDetails.data.group_pool.contests,
              (dispatch, callbackStandingsSocket) => {
                actions.updateStandingsSocket(dispatch, callbackStandingsSocket);
              },
              (dispatch, callbackPlayersSocket) => {
                actions.updatePlayersSocket(dispatch, callbackPlayersSocket);
              },
              (dispatch, callbackUpdateClose) => {
                actions.updatePoolClose(dispatch, callbackUpdateClose);
              },
              (dispatch, callbackUpdateRaceResultsSoket) => {
                actions.updateRaceResultsSoket(dispatch, callbackUpdateRaceResultsSoket);
              },
              (dispatch, callbackUpdateContestStandingsSocket) => {
                actions.updateContestStandingsSoket(dispatch, callbackUpdateContestStandingsSocket);
              },
              dispatch
            );
          },
          (reason) => {
            console.error(reason);
            Sentry.captureException(reason);
            dispatch({ type: 'isError', payload: { error: reason } });
          }
        )
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          window.location = '/quinielaGrupo/sinRegistro/' + poolId;
          dispatch({ type: 'isError', payload: { error: error } });
        });
    } else {
      console.log('%c Initial Side Effect without Entry', 'color:rgb(255, 199, 48) ; font-size: 2em;');
      Promise.all([
        actions.getPoolDetails(dispatch, poolId),
        actions.getUserData(dispatch),
        actions.getUserTickets(dispatch, poolId),
        actions.getSimilarByPoolGroup(dispatch, poolId),
      ])
        .then(
          (values) => {
            //let poolStandings;
            let [poolDetails, userData, userTickets] = [...values];

            actions.getGroupPicks(dispatch, poolId, state.queryParamsGroupPicks, poolDetails.data.current_contest.id)
              .then((response) => {
                //console.log("GETGROUPPICKS", response);
              });

            actions.isBalanceEnough(
              dispatch,
              poolDetails.data.group_pool.entry_fee,
              poolDetails.data.group_pool.entry_currency,
              userTickets.data.tickets
            );

            actions.updateLocation(poolDetails.data.group_pool.slug, poolId, null, false);

            actions
              .initializeEntry(
                dispatch,
                poolId,
                poolDetails.data.buckets,
                poolDetails.data.bucketKeys,
                poolDetails.data.group_pool.group_pool_tiebreakers,
                null,
                false,
                poolDetails.data.players,
                poolDetails.data.current_contest,
                poolDetails.data.group_pool.entry_currency,
                poolDetails.data.group_pool.contests,
              )
              .then((response) => {
                actions.getPoolStandings(dispatch, poolId, state.queryParams, poolDetails.data.current_contest.id).then((response) => {
                  let poolStandings = response;
                  if (poolDetails.data.group_pool.status === 'live') {
                    let fakeObj = {
                      group_pool_id: poolDetails.data.group_pool.id,
                      standings: poolStandings && poolStandings.hasOwnProperty('data') ? poolStandings.data.items : [],
                      ties_distribution: poolDetails.data.ties_distribution,
                    };
                    actions.updateStandingsAmount(dispatch, fakeObj);
                  }
                });
              });

            dispatch({ type: 'isReady' });

            API.subscribeSocketLiveGolf(
              poolId,
              poolDetails.data.group_pool.contests,
              (dispatch, callbackStandingsSocket) => {
                actions.updateStandingsSocket(dispatch, callbackStandingsSocket);
              },
              (dispatch, callbackPlayersSocket) => {
                actions.updatePlayersSocket(dispatch, callbackPlayersSocket);
              },
              (dispatch, callbackUpdateClose) => {
                actions.updatePoolClose(dispatch, callbackUpdateClose);
              },
              (dispatch, callbackUpdateRaceResultsSoket) => {
                actions.updateRaceResultsSoket(dispatch, callbackUpdateRaceResultsSoket);
              },
              (dispatch, callbackUpdateContestStandingsSocket) => {
                actions.updateContestStandingsSoket(dispatch, callbackUpdateContestStandingsSocket);
              },
              dispatch
            );
          },
          (reason) => {
            console.error(reason);
            dispatch({ type: 'isError', payload: { error: reason } });
          }
        )
        .catch((error) => {
          console.error(error);
          dispatch({ type: 'isError', payload: { error: error } });
        });
    }
  }


  // Get entry ID from URL
  const getCurrentEntryID = () => {
    const entryIDString = window.location.href.split('/')[6]
    const entryParamID = Number(entryIDString)

    return isNaN(entryParamID) ? entryId : entryParamID
  }

  const refreshValues = () => {
    gaUtils.handleDocumentAttributes(match);

    const currentEntryID = getCurrentEntryID()

    if (entryId && !utils.isSuPick()) {
      Promise.all([
        actions.getPoolDetails(dispatch, poolId),
        actions.getUserData(dispatch),
        actions.getUserTickets(dispatch, poolId),
        actions.getPoolEntry(dispatch, currentEntryID),
        actions.getSimilarPools(dispatch, currentEntryID),
      ])
        .then(
          (values) => {
            let [poolDetails, , userTickets, poolEntry] = [...values];

            actions.getGroupPicks(dispatch, poolId, state.queryParamsGroupPicks, poolDetails.data.current_contest.id)

            actions.getPoolStandings(dispatch, poolId, state.queryParams, poolDetails.data.current_contest.id).then((response) => {
              let poolStandings = response;
              if (poolDetails.data.group_pool.status === 'live') {
                let fakeObj = {
                  group_pool_id: poolDetails.data.group_pool.id,
                  standings: poolStandings && poolStandings.hasOwnProperty('data') ? poolStandings.data.items : [],
                  ties_distribution: poolDetails.data.ties_distribution,
                };
                actions.updateStandingsAmount(dispatch, fakeObj);
              } else {
                actions.initializeBucketsState(
                  dispatch,
                  state.poolBuckets,
                  poolEntry.data.group_pool_entry,
                  poolDetails.data.current_contest,
                );
              }
            });

            actions.getPoolStandings(dispatch, poolId, state.queryParams, poolDetails.data.current_contest.id).then((response) => {
              let poolStandings = response;
              if (poolDetails.data.group_pool.status === 'live') {
                let fakeObj = {
                  group_pool_id: poolDetails.data.group_pool.id,
                  standings: poolStandings && poolStandings.hasOwnProperty('data') ? poolStandings.data.items : [],
                  ties_distribution: poolDetails.data.ties_distribution,
                };
                actions.updateStandingsAmount(dispatch, fakeObj);
              } else {
                actions.initializeBucketsState(
                  dispatch,
                  state.poolBuckets,
                  poolEntry.data.group_pool_entry,
                  poolDetails.data.current_contest,
                );
              }
            });

            dispatch({ type: 'isReady' });
          },
          (reason) => {
            console.error(reason);
            Sentry.captureException(reason);
            dispatch({ type: 'isError', payload: { error: reason } });
          }
        )
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          window.location = '/quinielaGrupo/sinRegistro/' + poolId;
          dispatch({ type: 'isError', payload: { error: error } });
        });
    } else {
      Promise.all([
        actions.getPoolDetails(dispatch, poolId),
        actions.getUserData(dispatch),
        actions.getUserTickets(dispatch, poolId),
        actions.getSimilarByPoolGroup(dispatch, poolId),
      ])
        .then(
          (values) => {
            //let poolStandings;
            let [poolDetails, userData, userTickets] = [...values];

            actions.getGroupPicks(dispatch, poolId, state.queryParamsGroupPicks, poolDetails.data.current_contest.id)
              .then((response) => {
                //console.log("GETGROUPPICKS", response);
              });

            actions.isBalanceEnough(
              dispatch,
              poolDetails.data.group_pool.entry_fee,
              poolDetails.data.group_pool.entry_currency,
              userTickets.data.tickets
            );

            actions.updateLocation(poolDetails.data.group_pool.slug, poolId, null, false);

            actions
              .initializeEntry(
                dispatch,
                poolId,
                poolDetails.data.buckets,
                poolDetails.data.bucketKeys,
                poolDetails.data.group_pool.group_pool_tiebreakers,
                null,
                false,
                poolDetails.data.players,
                poolDetails.data.current_contest,
                poolDetails.data.group_pool.entry_currency,
                poolDetails.data.group_pool.contests,
              )
              .then((response) => {
                actions.getPoolStandings(dispatch, poolId, state.queryParams, poolDetails.data.current_contest.id).then((response) => {
                  let poolStandings = response;
                  if (poolDetails.data.group_pool.status === 'live') {
                    let fakeObj = {
                      group_pool_id: poolDetails.data.group_pool.id,
                      standings: poolStandings && poolStandings.hasOwnProperty('data') ? poolStandings.data.items : [],
                      ties_distribution: poolDetails.data.ties_distribution,
                    };
                    actions.updateStandingsAmount(dispatch, fakeObj);
                  }
                });
              });

            dispatch({ type: 'isReady' });
          },
          (reason) => {
            console.error(reason);
            dispatch({ type: 'isError', payload: { error: reason } });
          }
        )
        .catch((error) => {
          console.error(error);
          dispatch({ type: 'isError', payload: { error: error } });
        });
    }
  }

  React.useEffect(loadAllValues, []);
  
  React.useEffect(() => {
    subscribe('POOL_LOBBY:REFRESH_DATA', refreshValues)

    return () => unsubscribe('POOL_LOBBY:REFRESH_DATA', refreshValues)
  }, [])

  // console.log(state);
  if (!state || !state.mounted) {
    return (
      <div className="flex-container justify align column">
        <i className="ion-load-a loading " />
      </div>
    );
  } else if (state.redirect) {
    console.log('Redirect');
    return <Redirect to={{ pathname: state.redirectTo }} />;
  } else {
    return (
      <div className="container">
        <Modal
          dialogClassName="invitarAmigosModalDialog modal-dialog-xxxs"
          className="invitarAmigosModal"
          backdropClassName="invitarAmigosBackdrop"
          show={state.showInvite}
          onHide={() => {
            actions.handleInvitePanel(dispatch);
          }}
        >
          <PanelInvitarAmigos
            title={`Invita a tus amigos a jugar la quiniela `}
            pool={state.poolInfo}
            dispatch={dispatch}
            whatsAppInvitation={actions.whatsAppInvitation}
            openInvitePanel={actions.showSendMail}
            closePanel={actions.handleInvitePanel}
            branchLink={state.branchLink}
          />
        </Modal>
        <InvitarAmigos
          pool={state.poolInfo}
          dispatch={dispatch}
          showPanel={state.showMailPanel}
          userDataProps={utils.getUserInfo()}
          updateStateFunc={actions.showSendMail}
        />
        <MediaQuery query="(max-width: 659px)">
          <ScoreMobile
            players={state.players}
            buckets={state.poolBuckets}
            bucketKeys={state.bucketKeys}
            ties={state.tiebreakers}
            changeSelectedEntry={actions.getPoolEntry}
            changeStatusPool={actions.updateStartedPool}
            dispatch={dispatch}
            entries={state.allEntries}
            handleCancelPanel={actions.handleCancellation}
            handleClickFirstRegister={actions.handleRegistration}
            handleClickReRegister={actions.handleReRegistration}
            hideInvite={actions.handleInvitePanel}
            logged={isLogged}
            poolInfo={{ pool: state.poolInfo }}
            popup={false}
            quinaID={poolId}
            redirectToLobby={() => {
              console.log('redirect to lobby');
            }}
            refreshAfterCancel={() => {
              console.log('Refresh after Cancel');
            }}
            selectedEntry={state.selectedEntry}
            showInvite={actions.handleInvitePanel}
            status={state.poolInfo.status}
            updateAfterRegister={() => {
              console.log('After Register action');
            }}
            updateBranchLink={actions.updateBranchLink}
            updateClosedQuiniela={() => {
              console.log('Quiniela Closed');
            }}
            updateStateFuncCancel={actions.handleCancellation}
            updateUserInfo={updateUserInfo}
            withRegister={state.withRegister}
            currentContest={state.currentContest}
            entryCurrency={state.poolInfo.entry_currency}
            contests={state.poolInfo.contests}
          />
        </MediaQuery>
        <MediaQuery query="(min-width: 660px)">
          <Score
            buckets={state.poolBuckets}
            players={state.players}
            bucketKeys={state.bucketKeys}
            ties={state.tiebreakers}
            changeSelectedEntry={actions.getPoolEntry}
            changeStatusPool={actions.updateStartedPool}
            dispatch={dispatch}
            entries={state.allEntries}
            handleCancelPanel={actions.handleCancellation}
            handleClickFirstRegister={actions.handleRegistration}
            handleClickReRegister={actions.handleReRegistration}
            hideInvite={actions.handleInvitePanel}
            logged={isLogged}
            poolInfo={{ pool: state.poolInfo }}
            popup={false}
            quinaID={poolId}
            redirectToLobby={() => {
              console.log('redirect to lobby');
            }}
            refreshAfterCancel={() => {
              console.log('Refresh after Cancel');
            }}
            selectedEntry={state.selectedEntry}
            showInvite={actions.handleInvitePanel}
            status={state.poolInfo.status}
            updateAfterRegister={() => {
              console.log('After Register action');
            }}
            updateBranchLink={actions.updateBranchLink}
            updateClosedQuiniela={() => {
              console.log('updateClosedQuiniela');
            }}
            updateStateFuncCancel={actions.handleCancellation}
            updateUserInfo={updateUserInfo}
            withRegister={state.withRegister}
            currentContest={state.currentContest}
            entryCurrency={state.poolInfo.entry_currency}
            contests={state.poolInfo.contests}
          />
        </MediaQuery>
        <CancelRegister
          // payType={this.state.itemSelected === null ? null: this.state.itemSelected[0].entry_currency}
          allEntries={state.allEntries}
          dispatch={dispatch}
          entries={state.entries}
          logged={isLogged}
          pool={state.poolInfo}
          quinaID={poolId}
          refreshAfterCancel={() => {
            console.log('refresh after cancel');
          }}
          redirectAfterCancel={() => {
            console.log('redirect after cancel');
          }}
          redirectToLobby={() => {
            console.log('redirect lobby');
          }}
          registerPicks={state.registerPicks}
          registerState={() => {
            console.log('register state');
          }}
          showPanel={state.showCancellation}
          type="group_pool"
          updateStateFunc={actions.handleCancellation}
          updateUserInfo={updateUserInfo}
          updateUserBalance={updateUserBalance}
          userDataProps={userData}
        />
        <AbonarSaldo
          type="modal"
          dispatch={dispatch}
          showAbonarSaldo={state.showAbonar}
          updatePanelSate={actions.handleOpenAbonar}
          showAbonarPop={actions.handleOpenAbonar}
          updateUserInfo={actions.updateUserInfo}
          updateUserBalance={actions.updateUserBalance}
        />
        <RegisterPanel
          updateUserBalance={updateUserBalance}
          closePanel={() => {
            console.log('close');
          }}
          dispatch={dispatch}
          entryId={state.selectedEntry}
          buckets={state.poolBuckets}
          bucketKeys={state.bucketKeys}
          players={state.players}
          ties={state.tiebreakers}
          updateStateFunc={actions.handleRegistration}
          handleConfirmation={() => {
            console.log('handleConfirmation click');
          }}
          logged={isLogged}
          registerPicks={state.myEntry}
          payType={state.poolInfo.entry_currency}
          selectedEntry={state.allEntries ? state.allEntries.group_pool_entry : null}
          pool={state.poolInfo}
          myEntries={
            state.entriesInfo ? [state.entriesInfo.entry, ...state.entriesInfo.other_entries] : null
          }
          quinaID={poolId}
          refreshAfterGuardar={() => {
            console.log('Refresh after guardar');
          }}
          returnError={state.returnError}
          showPanel={state.showRegistration}
          showTutorial={() => {
            console.log('show tutorial');
          }}
          currentContest={state.currentContest}
          entryCurrency={state.poolInfo.entry_currency}
          contests={state.poolInfo.contests}
          match={match}
        />
        <RegisterPanel
          updateUserBalance={updateUserBalance}
          closePanel={actions.handleReRegistration}
          dispatch={dispatch}
          buckets={state.poolBuckets}
          bucketKeys={state.bucketKeys}
          players={state.players}
          ties={state.tiebreakers}
          selectedEntry={state.allEntries ? state.allEntries.group_pool_entry : null}
          entryId={state.selectedEntry}
          updateStateFunc={actions.handleReRegistration}
          handleConfirmation={() => {
            console.log('handleConfirmation click');
          }}
          logged={isLogged}
          payType={state.poolInfo.entry_currency}
          pool={state.poolInfo}
          myEntries={
            state.entriesInfo ? [state.entriesInfo.entry, ...state.entriesInfo.other_entries] : null
          }
          quinaID={poolId}
          refreshAfterGuardar={() => {
            console.log('Refresh after guardar');
          }}
          returnError={state.returnError}
          showPanel={state.showReRegistration}
          showTutorial={() => {
            console.log('show tutorial');
          }}
          currentContest={state.currentContest}
          entryCurrency={state.poolInfo.entry_currency}
          contests={state.poolInfo.contests}
        />
        <div className="quiniela-lobby-container">
          <div className="quiniela-container-lobby">
            <HandleLayout
              submittingInvite={state.submittingInvite}
              showAddConfirmation={state.showAddConfirmation}
              inviteStatus={state.inviteStatus}
              userToAdd={state.userToAdd}
              successFollow={state.succesFollow}
              errorFollow={state.errorFollow}
              allEntries={state.allEntries}
              queryParams={state.queryParams}
              searchableNick={state.searchableNick}
              newMessage={state.newMessage}
              dispatch={dispatch}
              prizes={state.prizes}
              contestPrizes={state.contestPrizes}
              poolId={poolId}
              myEntry={state.myEntry}
              standings={state.standings}
              similarPools={state.similarPools}
              poolInfo={state.poolInfo}
              selection={state.selection}
              selectedEntry={state.withEntry ? state.allEntries.group_pool_entry : null}
              activeSort={state.activeSort}
              submittingSearch={state.submittingSearch}
              updateEntries={() => {
                console.log('UpdateEntries');
              }}
              changeSection={actions.changeSection}
              withRegister={state.withRegister}
              currentContest={state.currentContest}
              contestStandings={state.contestStandings}
              contestPrizes={state.contestPrizes}
              contestTiesDistribution={state.contestTiesDistribution}
            />
          </div>
        </div>
      </div>
    );
  }
};

const QuinielaGroupsLobby = ({ location, match }) => {
  return (
    <PageWrapper>
      <UserDataContextConsumer>
        {({ isLogged, userData, userBalance, updateUserInfo, updateUserBalance }) => {
          return (
            <QuinielaGroupsContextProvider>
              <QuinielaGroupWrapper
                location={location}
                match={match}
                isLogged={isLogged}
                userData={userData}
                userBalance={userBalance}
                updateUserInfo={updateUserInfo}
                updateUserBalance={updateUserBalance}
              />
            </QuinielaGroupsContextProvider>
          );
        }}
      </UserDataContextConsumer>
    </PageWrapper>
  );
};

export default QuinielaGroupsLobby;
