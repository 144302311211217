import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ReactDOM from 'react-dom'

// Components
import BarLevel from './BarLevel'
import useUserContext from '../../hooks/useUserContext'
import Iconify from '../../components/Iconify'

export default function ModalLevelUp () {
  const {
    onNextAnimation,
    currentAnimation,
    currentLevelName,
    setCurrentLevelName,
    muted,
    setMuted,
    isSafari
  } = useUserContext()

  const videoRef = useRef()
  const [playing, setPlaying] = useState(false)

  // When the video is playing all animations are triggered
  useEffect(() => {
    if (currentAnimation) {
      playVideo()
    }
  }, [currentAnimation])

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.setAttribute('src', `https://pw-resources.storage.googleapis.com/videos/levels/level-${currentAnimation.last.data.level_id}-new.mp4`)
      videoRef.current.load()

      videoRef.current.addEventListener('canplay', function () {
        try {
          videoRef.current.play()
        } catch (e) {
          setPlaying(false)
        }
      })

      videoRef.current.addEventListener('play', function () {
        if (!videoRef.current.paused) {
          setPlaying(true)
        }
      })

      videoRef.current.addEventListener('ended', function () {
        onNextAnimation()
        setPlaying(false)
      })
    }
  }

  const play = (event) => {
    event.stopPropagation()

    if (videoRef.current.paused) {
      videoRef.current
        .play()
        .catch(error => console.log(error))
      setPlaying(true)
    }
  }

  const disableMuted = () => {
    setMuted(!muted)
  }

  if (!currentAnimation) return null

  const start = currentAnimation.minProgress.data.progress
  const end = currentAnimation.maxProgress.data.progress

  const content = (
    <Container onClick={play}>
      <BarLevel
        start={start}
        end={end}
        muted={muted}
        playing={playing}
        currentLevelName={currentLevelName}
        setCurrentLevelName={setCurrentLevelName}
        currentAnimation={currentAnimation}
      />
      <VideoContainer>
        {(isSafari && playing) && (
          <MutedButton  onClick={disableMuted}>
            {muted && <Iconify icon='ion:volume-mute-sharp' />}
            {!muted && <Iconify icon='ion:volume-high-sharp' />}
          </MutedButton>
        )}
        <Video
          ref={videoRef}
          muted={muted}
        />
      </VideoContainer>
    </Container>
  )

  return ReactDOM.createPortal(content, document.querySelector('body'))
}

const show = keyframes`
  0% {
    transform: scale(1.9);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 1000ms ease;
  background-color: rgba(0,0,0, 0.8);
  animation: ${show} 1s ease forwards;
  backdrop-filter: blur(2px) grayscale(1);
`

const VideoContainer = styled.div`
  position: relative;
`

const showAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const MutedButton = styled.button`
  position: absolute;
  top: -0.5em;
  right: -0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  font-size: 1.8em;
  background: #a9ff00;
  color: black;
  padding: 0.1em 0.2em;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  transform: scale(0);
  animation: ${showAnimation} 400ms ease 600ms forwards;
`

const Video = styled.video`
  height: 60vh;
`

