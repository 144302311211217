import React from 'react';
import CrearQuinielaFormsy from '../CrearQuinielaPrivada/CrearQuinielaFormsy';

import Formsy from 'formsy-react';
import { addValidationRule } from 'formsy-react';

import { Redirect } from 'react-router-dom';

import {
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  ControlLabel,
} from 'react-bootstrap/lib';
import API from '../../utils/api';

import emojiRegex from 'emoji-regex';

import { LoadingProcess, ErrorMessage } from '../../utils/quinielasComponents';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

/**
 * Custom validators for Formsy
 */
addValidationRule('maxStrikes', (values, value, maxValue) => {
  console.log('maxValue');
  console.log(maxValue);
  return Number(value) <= maxValue && Number(value) > 1;
});
addValidationRule('maxValueCommission', (values, value) => {
  return Number(value) <= 15;
});
addValidationRule('maxPrizes', (values, value) => {
  return Number(value) <= 10;
});
addValidationRule('minRegister', (values, value) => {
  return Number(value) > 0;
});
addValidationRule('notEmoticons', (values, value) => {
  let regEx = emojiRegex();
  return !regEx.test(value) && value !== null && value !== undefined && value !== '';
});

/**
 * This Component Renders the List Of Prizes
 * @param prizesQnty            { Number }
 * @param prizeSum              { Number }
 * @param type                  { String }
 * @param handlePrizesCustom    { Function }
 * @param crearPrivadaData      { Object }
 * @param listPrizes            { Array }
 * @param setMaxPrizeQnty       { Function }
 * @return Component
 */

export const ListaPremios = ({
  prizesQnty,
  prizesSum,
  type,
  handlePrizesCustom,
  crearPrivadaData,
  listPrizes,
  setMaxPrizeQnty,
}) => {
  prizesQnty = Number(prizesQnty);
  // console.log('prizesQnty', prizesQnty );
  // console.log('CrearPrivadaData', crearPrivadaData.prizes_list);
  // console.log('CrearPrivadaData', crearPrivadaData.prizes_list);

  let listaPremios = [];

  for (let i = 1; i <= prizesQnty; i++) {
    listaPremios.push(i);
  }

  listaPremios = listaPremios.map((premio, premioIdx) => {
    return (
      <div key={premioIdx} className="flex-col col-no-padding col-30 prize-item">
        <div className="flex-row row-center row-no-padding">
          <div className="flex-container justify align">
            <span>{premio}°</span>
          </div>
          <div className="flex-container justify align">
            <i className="ion-trophy"></i>
          </div>
        </div>
        <div className="flex-row row-center ">
          <div className="flex-col">
            <CrearQuinielaFormsy
              prizesSum={prizesSum}
              listPrizes={listPrizes}
              prizeId={premio}
              setMaxPrizeQnty={setMaxPrizeQnty}
              name={'gamePrizesCustom' + premio}
              defaultValue={listPrizes[premioIdx].percentage}
              inputHandler={handlePrizesCustom}
              type="PRIZES_CUSTOM"
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="flex-col">
      <div className="flex-row">
        <div className="flex-col">
          <div className="control-label">Lista de premios</div>
        </div>
      </div>
      <div className="flex-container justify align wrap">{listaPremios}</div>
    </div>
  );
};

/**
 * This COmponent Renders the Available Tournaments to create a private game
 * @param deporte          { Object }
 * @param lista            { Array }
 * @param handleTorneos    { Function }
 * @param defaultValue     { Number }
 * @return Component
 */
export const Torneos = ({ deporte, lista, handleTorneos, defaultValue }) => {
  // console.log('lista',lista );
  let listaTorneos = _.find(lista, (sport) => sport.id === deporte);
  if (!listaTorneos) {
    return;
  }
  listaTorneos = listaTorneos.tournament_instances.map((torneo, torneoIdx) => {
    return (
      <ToggleButton
        defaultValue={defaultValue}
        className={`btn-torneo flex-col ${$(window).width() < 669 ? 'col-50' : 'col-30'}`}
        key={torneo.id}
        value={torneo.id}
      >
        {torneo.name}
      </ToggleButton>
    );
  });
  return (
    <ButtonToolbar className="toolbar-toreno">
      <ToggleButtonGroup type="radio" name="torneo" onChange={handleTorneos}>
        {listaTorneos.length === 0 ? (
          <div className="intro-copy">
            Lo sentimos pero por el momento no tenemos ligas disponibles.
          </div>
        ) : (
            listaTorneos
          )}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

/**
 * This component handles the wizard state
 * @param prizeSum                { Number }
 * @param activeSection           { Number }
 * @param canSubmit               { Boolean }
 * @param nameExist               { Boolean }
 * @param submitting              { Boolean }
 * @param nextStep                { function }
 * @param prevStep                { Number }
 * @param submitSearch            { Function }
 * @param handleCancelSelection   { Function }
 * @param crearPrivadaData        { Object }
 * @return Component
 */
export const WizardButtons = ({
  prizesSum,
  activeSection,
  canSubmit,
  nameExist,
  submitting,
  nextStep,
  prevStep,
  submitSearch,
  handleCancelSelection,
  crearPrivadaData,
}) => {
  let isReady = activeSection === 5;
  let conditionalComponent = null;
  if (isReady) {
    if (crearPrivadaData.prize_scheme === 'custom' && prizesSum !== 100) {
      canSubmit = false;
    }
    conditionalComponent = (
      <div className="flex-col col-90">
        <div className="buttons-wizard flex-row row-no-padding">
          <div className="flex-col">
            <Button className="btn-pick btn-wizard " onClick={prevStep}>
              <span>Anterior</span>
            </Button>
          </div>
          <div className="flex-col">
            <Button
              className="btn-pick btn-submit btn-wizard"
              disabled={!canSubmit || submitting || nameExist}
              onClick={() => {
                submitSearch();
                nextStep();
              }}
            >
              <span>Crea tu survivor</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (activeSection === 1) {
    conditionalComponent = (
      <div className="flex-col col-90">
        <div className="buttons-wizard flex-row row-no-padding">
          <div className="flex-col">
            <Button className="btn-rojo btn-wizard " onClick={handleCancelSelection}>
              <span>Regresar</span>
            </Button>
          </div>
          <div className="flex-col">
            <Button
              disabled={!canSubmit || nameExist}
              className="btn-pick btn-wizard"
              onClick={nextStep}
            >
              <span>Siguiente</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (activeSection > 5) {
    conditionalComponent = null;
  } else {
    if (activeSection === 2) {
      canSubmit =
        crearPrivadaData.tournament_instances !== undefined &&
        crearPrivadaData.tournament_instances !== null;
    } else if (activeSection === 3) {
    } else if (activeSection === 4) {
      // if(crearPrivadaData.entry_fee )
      // canSubmit = crearPrivadaData.entry_fee !== 0 ;
    }
    conditionalComponent = (
      <div className="flex-col col-90">
        <div className="buttons-wizard flex-row row-no-padding">
          <div className="flex-col">
            <Button className="btn-rojo btn-wizard " onClick={prevStep}>
              <span>Anterior</span>
            </Button>
          </div>
          <div className="flex-col">
            <Button disabled={!canSubmit} className="btn-pick btn-wizard" onClick={nextStep}>
              <span>Siguiente</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return conditionalComponent;
};

class CrearSurvivorFormulario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      deportesTorneos: null,
      errorCreation: false,
      errorMessage: false,
      errors: null,
      initialWeek: null,
      isReady: false,
      jornadasLoaded: false,
      listPrizes: [],
      maxStrikes: 2,
      nameExist: false,
      prizesReady: true,
      searchingName: false,
      submitting: false,
      successfullCreation: false,
    };
    this.crearPrivadaData = {
      name: '', //Required
      password: '', //Required
      entry_fee: '', //Required
      limit_multientries: false,
      max_multientries: null,
      prize_scheme: 'dynamic', //Required
      affiliate_commission: 0, //optional
      sport_id: null, //Required
      survivor_prizes: null, //Optional
      tournament_instances: null, //Required
      starting_week: null,
      strikes_enabled: false,
      max_strikes: 0,
    };

    /**
     *  This object handle the privata Custom Prize scheme
     * **/
    this.crearPrivadaPremioCustom = {
      position: null,
      type: 'percentage',
      percentage: null,
    };
    /**
     * Handle the name of the private
     * @param e is the event that handles the character changes
     * **/
    this.handleName = (e) => {
      this.crearPrivadaData.name = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (this.state.nameExist) {
        this.disableButton();
      }
    };
    /**
     * Handle the Password of the private
     * @param e is the event that handles the character changes
     * **/
    this.handlePassword = (e) => {
      this.crearPrivadaData.password = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (this.state.nameExist) {
        this.disableButton();
      }
    };

    /**
     * Handle the sport id selected by the user
     * @param value is the input value of the user
     * It also handles the degfault value of the point format
     * Depending of the selected sport id
     * **/
    this.handleStrikesEnabled = (value) => {
      console.log('strikesEnabled', value);
      this.crearPrivadaData.strikes_enabled = value;
      if (value === false) {
        this.crearPrivadaData.max_strikes = 0;
      }
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the sport id selected by the user
     * @param value is the input value of the user
     * It also handles the degfault value of the point format
     * Depending of the selected sport id
     * **/
    this.handleQntyStrikes = (e) => {
      console.log('handleQntyStrikes', e);
      // if( e.target.value < 0 ){
      //   console.log('negative value');
      //   this.crearPrivadaData.max_strikes = -1*(e.target.value);
      // }
      // else {
      //   console.log('postivie value');
      // }
      this.crearPrivadaData.max_strikes = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the sport id selected by the user
     * @param value is the input value of the user
     * It also handles the degfault value of the point format
     * Depending of the selected sport id
     * **/
    this.handleSport = (value) => {
      // console.log('handleSport', e );
      this.crearPrivadaData.sport_id = value;

      if (value === 1) {
        this.crearPrivadaData.strikes_enabled = true;
        this.crearPrivadaData.max_strikes = 0;
      } else {
        this.crearPrivadaData.strikes_enabled = false;
        this.crearPrivadaData.max_strikes = 0;
      }
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the Tournament list of the private game
     * @param value is the value that changes the id selected by the user
     * it also enables the next button in the wizard
     * **/
    this.handleToggleTorneos = (value) => {
      this.setState({ jornadasLoaded: false });
      this.crearPrivadaData.tournament_instances = { _ids: [value] };
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (this.crearPrivadaData.tournament_instances !== null) {
        this.enableButton();
      }
      if (this.crearPrivadaData.tournament_instances === null) {
        this.disableButton();
      }
    };
    /**
     * Handle the Point Format  input of the private game
     * @param value is the point_format value
     * **/
    this.handlePointFormat = (value) => {
      this.crearPrivadaData.point_format = value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };

    /**
     * Handle the boolean value of Limit_multienries
     * @param value is the data that handles the limit_multientries of the game
     * **/
    this.handleLimitMultientries = (value) => {
      this.crearPrivadaData.limit_multientries = value;
      this.crearPrivadaData.max_multientries = value ? '' : null;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      // console.log('Updated creation data', this.crearPrivadaData );
    };
    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleMaxMultientries = (e) => {
      // let val = e.target.value === 0 ? 1 : e.target.value ;
      this.crearPrivadaData.max_multientries = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleEntryFee = (e) => {
      // let val = e.target.value === 0 ? 1 : e.target.value ;
      this.crearPrivadaData.entry_fee = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };

    /**
     * Handle the InitialWeek of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleInitialWeek = (e) => {
      console.log('Handle InitialWeek', e);
      let temporalWeeks = this.state.listaJornadas;

      let iWeek = temporalWeeks.find((w) => w.id === e);
      console.log('InitialWeek:', iWeek);
      this.setState({ initialWeek: iWeek, maxStrikes: iWeek.max_strikes }, () => {
        this.crearPrivadaData.starting_week = e;
        this.props.updateCreatePrivateData(this.crearPrivadaData);
      });
    };

    /**
     * Handle the value of the comission-fee
     * @param value is the value that changes the fee selected by the user
     * **/
    this.handleComissionFee = (e) => {
      // console.log( e.target.value );
      const value = e.target.value;
      this.crearPrivadaData.affiliate_commission = value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      // console.log(this.crearPrivadaData)
    };

    /**
     * Handle the value of the general prize  scheme
     * @param value is the value that changes the scheme of general prizes by the user
     * **/
    this.handlePrizeScheme = (value) => {
      this.crearPrivadaData.prize_scheme = value;
      if (value === 'custom') {
        this.disableButton();
        // this.crearPrivadaData.prizes_list = 10;
      } else {
        this.crearPrivadaData.prizes_list = null;
      }
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the value of the prize_list
     * @param value is the value that changes the prize list qnty
     * **/
    this.handlePrizeList = (e) => {
      this.setState({ prizesReady: false });
      this.crearPrivadaData.prizes_list = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      this.calculateQntyPrizes(this.crearPrivadaData.prizes_list);
    };
    /**
     * Calculate qnty and list  default prizes
     * @param qntyPrizes how many prizes the user wants
     * **/
    this.calculateQntyPrizes = (qntyPrizes) => {
      if (!qntyPrizes || (isNaN(qntyPrizes) && qntyPrizes !== 0)) {
        this.setState({ listPrizes: [], prizesReady: true, customPriesSum: 0 });
      } else {
        let newPrizesList = Object.keys(Array.apply(0, Array(Number(qntyPrizes))));
        newPrizesList = newPrizesList.map((prize, prizeIdx) => {
          let prizeModel = { ...this.crearPrivadaPremioCustom };
          // console.log('prizeModel', prizeModel);
          let currPosistion = prizeIdx + 1;
          prizeModel.position = Number(currPosistion);
          // prizeModel.percentage = 10;
          return prizeModel;
        });
        // console.log('newPrizeList', newPrizesList);
        this.setState({ listPrizes: newPrizesList }, () => {
          this.calculateListPrizes();
        });
      }
    };

    this.calculateQntyPrizes = _.debounce(this.calculateQntyPrizes, 1000);

    /**
     * this function calculates the left of the custom prizes
     * **/
    this.calculateListPrizes = () => {
      console.log('%c calculateListPrizes', 'font-size:2em; color:rgb(110, 193, 113);');
      let _listPrizes = this.state.listPrizes.slice();
      console.log(_listPrizes);
      if (!_listPrizes || !_listPrizes.length) {
        this.setState({ prizesReady: true, customPrizesSum: null });
        return;
      }
      let sum = _.reduce(
        _listPrizes,
        (acc, curr) => {
          if (curr.percentage === null) {
            return (acc += 0);
          } else {
            return (acc += curr.percentage);
          }
        },
        0
      );

      console.log('customPrizeSum', sum);

      this.setState(
        {
          prizesReady: true,
          customPrizesSum: sum,
        },
        () => {
          console.log('customPrizesSum changed');
        }
      );
    };

    this.setMaxPrizeQnty = (sum, prizesItems) => {
      const left = 100 - sum;
      return left;
    };

    /**
     * Handle the custom perecentages of the list prize_scheme
     * @param e is the event emmited,
     * @param prizeId is the position that mod the percentage
     * **/
    this.handlePrizesCustom = (e, prizeId) => {
      // console.log( 'Prizes Custom', e.target.value , prizeId );
      let temporalPrizes = this.state.listPrizes.slice();
      // console.log('temporalPrizes', temporalPrizes);
      temporalPrizes.forEach((prize, prizeIdx) => {
        // console.log('prize', prize);
        if (prizeId - 1 !== prizeIdx) {
          return;
        }
        prize.percentage = Number(e.target.value);
        // console.log('prizeId', prizeId, prize);
      });
      // console.log('temporalPrizes', temporalPrizes);
      this.setState({ listPrizes: temporalPrizes }, () => {
        this.calculateListPrizes();
      });
    };

    /**
     * Check ig the name selected is available
     * **/
    this.checkAvailability = () => {
      this.setState({ searchingName: true });
      API.fetchNamePrivateSurvivorStatus(this.crearPrivadaData.name)
        .then((response) => {
          this.setState({ nameExist: !response.data.available, searchingName: false });
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error, {
            extra: 'Error checking availability name in survivor private',
          });
        });
    };
    /**
     * This function handles the submition to create a survivor
     * */
    this.submitSearch = (e) => {
      // e.persist();
      console.log('%c Crear survivor', 'color:rgb(17, 125, 193); font-size:2em;');
      console.log('crearPrivadaData', this.crearPrivadaData);
      this.setState({ submitting: true });
      if (this.crearPrivadaData.prize_scheme === 'custom') {
        this.crearPrivadaData['survivor_prizes'] = this.state.listPrizes;
      }
      API.postCreatePrivateSurvivor(this.crearPrivadaData).then((response) => {
        this.callbackCrearSurvivor(response.data);
      });
    };
    /**
     * This function handles the response of the post CreateQuiniela
     * @param data response data of the API call
     * **/
    this.callbackCrearSurvivor = (data) => {
      console.log('%c Callback Crear survivor ', 'color:rgb(17, 186, 140); font-size:2em;');
      console.log(data);
      if (!data.errors) {
        this.setState(
          {
            successfullCreation: true,
            submitting: false,
            activeSection: 6,
            createdSurvivor: data.survivor,
          },
          () => {
            setTimeout(() => {
              localStorage.setItem('createdPrivate', 'true');
              this.setState(
                {
                  redirect: true,
                },
                () => {
                  setTimeout(() => {
                    let path = window.location.pathname.split('/');
                    if (path.length > 1 && path[1] === 'survivor') {
                      window.location = `/survivor/${this.state.createdSurvivor.slug}/${this.state.createdSurvivor.id}`;
                    }
                  }, 800);
                }
              );
            }, 1200);
          }
        );
      } else {
        let errorsList = [];
        let typeErrors = Object.keys(data.errors).map((error) => {
          errorsList = Object.keys(error).map((errorType) => {
            errorType.push(errorType);
          });
        });

        this.setState(
          {
            submitting: false,
            errorCreation: true,
            errors: typeErrors,
          },
          () => {
            //const userData = utils.getUserInfo();
          }
        );
      }
    };

    this.enableButton = () => {
      console.log('enableButton');
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      console.log('disableButton');
      this.setState({ canSubmit: false });
    };
  }

  componentDidMount() {
    API.fetchSports('survivor').then((response) => {
      // console.log(response);
      this.setState({
        deportesTorneos: response.data.sports,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeSection &&
      this.props.activeSection === 3 &&
      this.crearPrivadaData.tournament_instances._ids &&
      !this.state.jornadasLoaded &&
      !this.state.submitting
    ) {
      this.setState({ submitting: true });

      console.log(this.crearPrivadaData, this.state.jornadasLoaded);
      API.fetchTournamentInstancesWeekSurvivor(this.crearPrivadaData.tournament_instances._ids[0])
        .then((response) => {
          this.setState(
            {
              submitting: false,
              listaJornadas: response.data.weeks,
              initialWeek: response.data.weeks[0],
              maxStrikes: response.data.weeks[0].max_strikes,
              jornadasLoaded: true,
            },
            () => {
              this.crearPrivadaData.starting_week = response.data.weeks[0].id;
            }
          );
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error, { extra: 'Error fetching Weeks ' });
        });
    }
  }

  render() {
    let conditionalComponent = null;
    switch (this.props.activeSection) {
      /**
       * Informacion Basica
       * **/
      case 1:
        conditionalComponent = (
          <div className="flex-col input-box-responsive">
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Cómo quieres que se llame tu survivor?"
                name="gameName"
                inputHandler={this.handleName}
                type="NAME"
                checkAvailability={this.checkAvailability}
                defaultValue={this.crearPrivadaData.name}
                validations="notEmoticons"
                validationError="El nombre no puede tener un emoji ☹"
                required
              />
            </div>
            <LoadingProcess show={this.state.searchingName} />
            <ErrorMessage
              showError={this.state.nameExist}
              label="El nombre de esta survivor ya esta ocupado."
            />
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Que contraseña quieres que tenga tu survivor?"
                name="gamePass"
                inputHandler={this.handlePassword}
                type="TEXT"
                validations="isExisty"
                validationError="La contraseña es obligatoria"
                required
              />
            </div>
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Quieres limitar los multi registros?"
                name="limit_multientries"
                defaultValue={this.crearPrivadaData.limit_multientries}
                inputHandler={this.handleLimitMultientries}
                type="LIMIT_MULTIENTRIES"
              />
            </div>
            {this.crearPrivadaData.limit_multientries ? (
              <div className="flex-row ">
                <CrearQuinielaFormsy
                  label="Registros máximos por usuario"
                  name="gameEntry"
                  defaultValue={this.crearPrivadaData.max_multientries}
                  inputHandler={this.handleMaxMultientries}
                  validations={('isNumeric', { minRegister: 15 })}
                  validationError="Debe ser mayor a 0"
                  type="MAX_MULTIENTRIES"
                  required
                />
              </div>
            ) : null}
          </div>
        );
        break;
      /**
       * Deporte y torneo
       * **/
      case 2:
        conditionalComponent = (
          <div className="flex-col  col-90">
            <div className="flex-row">
              <div className="flex-col">
                <CrearQuinielaFormsy
                  label="Elige un deporte"
                  deportesTorneos={this.state.deportesTorneos}
                  name="gameSport"
                  defaultValue={this.crearPrivadaData.sport_id}
                  inputHandler={this.handleSport}
                  type="SPORT"
                  validationError="Debes de elegir un deporte."
                  required
                />
                {this.crearPrivadaData.sport_id !== null ? (
                  <div className=" wizard-tournament-toggle flex-col">
                    <ControlLabel style={{ display: 'flex', justifyContent: 'center', lineHeight: 2 }}>Elige una Liga</ControlLabel>
                    <Torneos
                      defaultValue={this.crearPrivadaData.tournament_instances}
                      deporte={this.crearPrivadaData.sport_id}
                      lista={this.state.deportesTorneos}
                      handleTorneos={this.handleToggleTorneos}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
        break;
      /**
       * Formato y duracion
       * **/
      case 3:
        console.log(this.crearPrivadaData);
        if (this.state.jornadasLoaded) {
          conditionalComponent = (
            <div className="flex-col col-60">
              {this.crearPrivadaData.sport_id === 1 ? (
                <div className="flex-row row-no-padding">
                  <CrearQuinielaFormsy
                    label="¿Quieres que tu survivor tenga strikes?"
                    name="gameStrikesEnabled"
                    inputHandler={this.handleStrikesEnabled}
                    type="STRIKES_ENABLED"
                    defaultValue={this.crearPrivadaData.strikes_enabled}
                    validationError="Elige alguna opción"
                  />
                </div>
              ) : null}

              {this.crearPrivadaData.sport_id === 1 &&
                this.crearPrivadaData.strikes_enabled === true ? (
                  <div className="flex-row ">
                    <div className="flex-col ">
                      <CrearQuinielaFormsy
                        label="¿Cuántos strikes quieres que tenga tu survivor?"
                        name="gameStrikes"
                        defaultValue={this.crearPrivadaData.max_strikes}
                        inputHandler={this.handleQntyStrikes}
                        type="NUM_STRIKES"
                        validations={{ maxStrikes: this.state.maxStrikes }}
                        validationError={`Tu survivor debe de tener entre 2 y ${this.state.maxStrikes} strikes.`}
                        help={[
                          `Máximo ${this.state.maxStrikes} strikes.`,
                          `Los participantes únicamente contarán con estos strikes antes de ser eliminados. `,
                          'Al acumular este número de strikes, serán eliminados.',
                        ]}
                      />
                    </div>
                  </div>
                ) : null}
              <CrearQuinielaFormsy
                label="Selecciona la jornada inicial de tú survivor"
                name="gameInitialWeek"
                weeks={this.state.listaJornadas}
                sportId={this.crearPrivadaData.sport_id}
                inputHandler={this.handleInitialWeek}
                type="SELECT_WEEK"
                defaultValue={
                  !this.crearPrivadaData.starting_week
                    ? this.state.listaJornadas[0].id
                    : this.crearPrivadaData.starting_week
                }
                validationError="Debes elegir cuando comienza tu survivor"
              />
            </div>
          );
        } else {
          conditionalComponent = (
            <div className="flex-col  col-90 ">
              <div className="flex-container justify align">
                <i className="ion-load-a loading"></i>
              </div>
            </div>
          );
        }
        break;
      /**
       * Participantes
       * **/
      case 4:
        conditionalComponent = (
          <div className="flex-col col-40">
            <div className="flex-row ">
              <div className="flex-col">
                <div className="flex-row">
                  <CrearQuinielaFormsy
                    label="Elige el precio de tú survivor."
                    name="gameEntry"
                    defaultValue={this.crearPrivadaData.entry_fee}
                    inputHandler={this.handleEntryFee}
                    type="ENTRY_FEE"
                    maxLength={5}
                    validations={'isNumeric'}
                    validationError="Debes de elegir un precio"
                    required
                  />
                </div>
                <div className="flex-row ">
                  <CrearQuinielaFormsy
                    label="¿Cuánto quieres llevarte de comisión?"
                    name="gameComissionFee"
                    defaultValue={this.crearPrivadaData.affiliate_commission}
                    inputHandler={this.handleComissionFee}
                    maxLength={2}
                    type="COMISSION_FEE"
                    validations={('isNumeric', { maxLength: 2 }, { maxValueCommission: 15 })}
                    validationError={'Tu comisión no puede ser mayor al 15%.'}
                    help={[
                      'Tu comisión puede ser de 0 a 15%, Pickwin reservará 2% de comisión adicional para operación.',
                    ]}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        );
        break;
      /**
       * Premios
       * **/
      case 5:
        conditionalComponent = (
          <div className="flex-col col-60">
            <div className="flex-row ">
              <CrearQuinielaFormsy
                label="¿Cómo quieres que se repartan los premios al final de la survivor?"
                name="gamePrizeScheme"
                defaultValue={this.crearPrivadaData.prize_scheme}
                inputHandler={this.handlePrizeScheme}
                type="PRIZE_SCHEME"
              />
            </div>
            {this.crearPrivadaData.prize_scheme === 'custom' ? (
              <div className="flex-row">
                <CrearQuinielaFormsy
                  label="¿Cuántos premios quieres entregar?"
                  name="gamePrizeScheme"
                  maxLength={4}
                  defaultValue={this.crearPrivadaData.prizes_list}
                  inputHandler={this.handlePrizeList}
                  validations={'isNumeric'}
                  validationError={'Debe de ser un número.'}
                  type="PRIZES_LIST"
                  required
                />
              </div>
            ) : null}
            {this.crearPrivadaData.prizes_list !== null ? (
              this.state.prizesReady ? (
                <ListaPremios
                  type="input"
                  prizesSum={this.state.customPrizesSum}
                  listPrizes={this.state.listPrizes}
                  crearPrivadaData={this.crearPrivadaData}
                  handlePrizesCustom={this.handlePrizesCustom}
                  prizesQnty={this.crearPrivadaData.prizes_list}
                  setMaxPrizeQnty={this.setMaxPrizeQnty}
                />
              ) : (
                  <div className="flex-container justify align column">
                    {' '}
                    <i className="ion-load-a loading"></i>{' '}
                    <small> Estamos calculando tus premios </small>{' '}
                  </div>
                )
            ) : null}
          </div>
        );
        break;
    }
    if (this.state.redirect) {
      console.log('redirect');
      conditionalComponent = (
        <Redirect
          push
          to={{
            state: { newQuinielaState: this.state.createdSurvivor },
            pathname: `/survivor/${this.state.createdSurvivor.slug}/${this.state.createdSurvivor.id}`,
          }}
        />
      );
    }
    return (
      <Formsy
        onValidSubmit={(e) => {
          this.submitSearch();
        }}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <div className="flex-container justify align column">
          {this.state.redirect ? (
            <Redirect
              push
              to={{
                state: { newSurvivorState: this.state.createdSurvivor },
                pathname: `/survivor/${this.state.createdSurvivor.slug}/${this.state.createdSurvivor.id}`,
              }}
            />
          ) : null}
          {this.state.successfullCreation ? (
            <span>¡Felicidades tu survivor se creo con éxito!</span>
          ) : (
              conditionalComponent
            )}
          {this.state.errorCreation}
          <WizardButtons
            crearPrivadaData={this.crearPrivadaData}
            handleCancelSelection={this.props.handleCancelSelection}
            activeSection={this.props.activeSection}
            canSubmit={this.state.canSubmit}
            prizesSum={this.state.customPrizesSum}
            nextStep={this.props.nextStep}
            prevStep={this.props.prevStep}
            submitSearch={this.submitSearch}
            submitting={this.state.submitting}
            nameExist={this.state.nameExist}
          />
        </div>
      </Formsy>
    );
  }
}

export default CrearSurvivorFormulario;
