import styled from 'styled-components'

const Title = styled.div`
  text-align: center;
  margin: 1.4em 0 2em 0;
  font-size: 1.3em;
  font-weight: bold;
`

export default Title
