import React from 'react'
import { Modal, Button } from 'react-bootstrap/lib'

const ConfirmationDeleteReferral = ({
  showConfirmation,
  closeConfirmation,
  deleteReferral,
  refererToDelete,
  submitting,
  deleteError,
  refererId
}) => {
  return (
    <Modal
      bsSize='sm'
      backdropClassName='confirmationBackdrop'
      dialogClassName='confirmationModal'
      onHide={closeConfirmation}
      show={showConfirmation}
    >
      <div className='panel-confirmacion flex-container justify column align'>
        <div
          id='panelTitle'
          className={`flex-row row-no-padding row-center ${
            deleteError ? 'failed' : ''
          }`}
        >
          <span>{deleteError ? 'Lo sentimos' : '¿Estás seguro?'}</span>
        </div>
        <div className='flex-col text-center'>
          <div className='flex-container justify align'>
            <i className='ion-alert-circled alert' />
          </div>
          {!deleteError
            ? (
              <div className='flex-container column justify align'>
                <div className='flex-row text-center'>
                  <span className=' flex-col text-center'>
                    Esta acción <strong>no</strong> puede ser cambiada y perderás
                    todos los datos referentes a este invitado.
                  </span>
                </div>
                <div className='flex-row'>
                  <span className='flex-col text-center'>
                    <span>¿Seguro que quieres eliminar a tu invitado</span>
                    <strong style={{ marginLeft: '5px' }}>
                      {!refererToDelete ? '' : refererToDelete.nick}
                    </strong>
                    <span>?</span>
                  </span>
                </div>
              </div>
              )
            : (
              <div className='flex-container column justify align'>
                <div className='flex-row text-center'>
                  <span className=' flex-col text-center '>
                    Enconotramos un error eliminando a tu referido, por favor
                    inténtalo más tarde.
                  </span>
                </div>
              </div>
              )}
        </div>
        <div className='flex-row row-center'>
          <div className='flex-col col-center'>
            <Button
              className='btn-rojo btn-block btn-submit'
              onClick={closeConfirmation}
            >
              <span className='flex-col text-center '>
                {deleteError ? 'Salir' : 'No'}
              </span>
            </Button>
          </div>
          {deleteError
            ? null
            : (
              <div className='flex-col col-center'>
                <Button
                  disabled={submitting}
                  className=' text-center btn-block btn-success btn-submit'
                  onClick={() => {
                    deleteReferral(refererId, refererToDelete.id)
                  }}
                >
                  {submitting
                    ? (
                      <div className='flex-container justify align '>
                        <i className='ion-load-a loading small' />
                      </div>
                      )
                    : (
                      <span className='flex-col text-center '>Si</span>
                      )}
                </Button>
              </div>
              )}
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationDeleteReferral
