import React from 'react';
import { NavLink } from 'react-router-dom';
import twitterButton from '../../assets/images/twitterButton.png';
import facebookButton from '../../assets/images/fbButton.png';
import instagramButton from '../../assets/images/igButton.png';
import correoButton from '../../assets/images/mailButton.png';
import pickwin from '../../assets/images/logoMx.png';
import segob from '../../assets/images/segob.png';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  render() {
    return (
      <div className="footer flex-row row-no-padding responsive-sm row-center">
        <div className="container flex-col col-80 col-offset-10 col-no-padding">
          <div className="footer-upper flex-row row-no-padding responsive-sm row-center">
            <div className="footer-left flex-col">
              <ul className="flex-row row-center legals">
                <li className="legal-item flex-col ">
                  <NavLink
                    className="flex-row row-no-padding"
                    activeClassName="activeNav"
                    to="/legal/terminos"
                  >
                    <span className="flex-col textNavbar text-center">Terminos y Condiciones</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <NavLink
                    className="flex-row row-no-padding"
                    activeClassName="activeNav"
                    to="/legal/privacidad"
                  >
                    <span className="flex-col text-center textNavbar">Aviso de Privacidad</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <NavLink
                    className="flex-row row-no-padding"
                    activeClassName="activeNav"
                    to="/legal/legales"
                  >
                    <span className=" flex-col text-center textNavbar">Legales</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <NavLink
                    className="flex-row row-no-padding"
                    activeClassName="activeNav"
                    to="/legal/reglas-deportes"
                  >
                    <span className=" flex-col text-center textNavbar">Reglas y Apuestas Deportivas</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <NavLink
                    className="flex-row row-no-padding"
                    activeClassName="activeNav"
                    to="/legal/responsible-gaming"
                  >
                    <span className=" flex-col text-center textNavbar">Juego Responsable</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <NavLink
                    className="flex-row row-no-padding"
                    activeClassName="activeNav"
                    to="/promociones"
                  >
                    <span className=" flex-col text-center textNavbar">Promociones</span>
                  </NavLink>
                </li>
                <li className="legal-item flex-col ">
                  <a
                    className="flex-row row-no-padding"
                    href="https://pickwin.zendesk.com/hc/es-mx"
                    target="_blank"
                  >
                    <span className=" flex-col text-center textNavbar">Preguntas Frecuentes</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-center flex-col">
              <div className="logos-footer row">
                {/*<img src={pickBco} className={'logo-footer'}/>*/}
                <span>©</span>
                <img src={pickwin} />
              </div>
            </div>
            <div className="footer-right flex-col">
              <ul className="unstyled-list nav-contacto-footer">
                <li className="nav-categories-item">
                  <a target="_blank" href="https://www.facebook.com/pickwin.mx" rel="noopener noreferrer">
                    {/*<i className="ion-social-facebook-outline"></i>*/}
                    <img src={facebookButton} className="img-responsive navbar-icons social" />
                  </a>
                </li>
                <li className="nav-categories-item">
                  <a target="_blank" href="https://twitter.com/PickwinMX" rel="noopener noreferrer">
                    {/*<i className="ion-social-twitter-outline"></i>*/}
                    <img src={twitterButton} className="img-responsive navbar-icons social" />
                  </a>
                </li>
                <li className="nav-categories-item">
                  <a target="_blank" href="https://instagram.com/pickwin.mx" rel="noopener noreferrer">
                    {/* <i className="ion-social-instagram-outline"></i> */}
                    <img src={instagramButton} className="img-responsive navbar-icons social" />
                  </a>
                </li>
                <li className="nav-categories-item">
                  <a href="mailto:hola@pickwin.net">
                    {/*<i className="ion-ios-email-outline"></i>*/}
                    <img src={correoButton} className="img-responsive navbar-icons social" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-row row-center responsive-sm row-no-padding row-center">
            <div className="flex-col col-80">
              <p className="text-center" style={{ padding: '8px ' }}>
                <small>
                  WWW.PICKWIN.MX OPERADA EN MEXICO POR PRODUCCIONES MÓVILES S.A. DE C.V., TITULAR DEL PERMISO <strong>DGAJS/SCEVF/P-06/2005-TER</strong> EN UNIÓN DE UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. DE CONFORMIDAD CON LOS OFICIOS <strong>DGJS/1580/2021</strong> Y <strong>DGJS/DCRCA/2772/2021</strong>.
                  <br/>
                  JUEGOS PROHIBIDOS PARA MENORES DE EDAD, JUEGUE RESPONSABLEMENTE, NO OLVIDE QUE EL PRINCIPAL PROPÓSITO ES LA RECREACIÓN, DIVERSIÓN Y ESPARCIMIENTO.
                </small>
              </p>
            </div>
            <div className="flex-col  col-center text-center">
              <img src={segob} className="img-responsive logo-gob" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
