import React from 'react';
import {
  Modal,
} from 'react-bootstrap/lib/';

import utils from '../../utils/utils';
import 'jquery';

class Modalhome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    let userData = utils.getUserInfo();
    return (
      <Modal
          dialogClassName={`${this.props.modalHomeInfo.modalClass}`}
          className={`abonarModal absoluteModal`}
          backdropClassName="abonarModalBackdrop"
          show={this.props.showModalHome}
          onHide={() => {

          }}
        >
          <div className="panel-abonar">
            <div id="panelTitle" className="panel-abonar-titulo">
              <span>{this.props.modalHomeInfo.title}</span>

              <div className="btn-icon-close" onClick={() => {
                  if (this.props.dispatch) {
                    this.props.updatePanelSate(this.props.dispatch);
                  } else {
                    this.props.updatePanelSate();
                  }
                }}
                style={{fontSize: '30px', fontWeight: 'bold', marginRight: '10px', cursor: 'pointer'}}>
                <i className="ion-ios-close-empty" />
              </div>
            </div>

            <div className="modalHomeContent">
              <a href={this.props.modalHomeInfo.href} target={this.props.modalHomeInfo.target}>
                <img src={this.props.modalHomeInfo.image} className="img-responsive" style={{margin: '0 auto'}} />
              </a>
            </div>

          </div>
        </Modal>
    );
  }
}
export default Modalhome;
