import React from 'react';
import _ from 'underscore';

/**
 * [EstatusQuiniela Renders the Estatus of the entry ]
 * @param { Object } myEntry       [description]
 * @param { Array } fixtures       [description]
 * @param { Array } fixturesProps  [description]
 * @param { Object } tiebreakers   [description]
 * @param { Object } poolInfo      [description]
 * @return Component
 */
const EstatusQuiniela = ({
  myEntry,
  fixtures,
  fixtureProps,
  tiebreakers,
  misQuinielas,
  poolInfo,
  numPicks,
  poolWeekSelected,
  parley,
}) => {
  if (misQuinielas) {
    return null;
  }

  let statusReady,
    picksReady,
    tiesReady = false;
  let totalPicks = 0;
  if (poolInfo.pool.max_picks !== null) {
    totalPicks = poolInfo.pool.max_picks;
  } else {
    if (poolInfo.pool.parlay_flag === null) {
      totalPicks = fixtures.length;
      if (poolInfo.pool.format === 'mixed') {
        totalPicks += fixtureProps.length;
      }
    } else {
      totalPicks = parley.length;
    }
  }

  let picksUser = numPicks;
  let tiesUser = 0;

  let hasRegister;
  if (myEntry) {
    if (poolInfo.pool.use_tibreakers) {
      tiesUser = _.reduce(
        myEntry.user_tiebreakers,
        (acc, tie) => {
          if (tie.value || tie.value === 0 || tie.score_away || tie.score_home) {
            return (acc += 1);
          } else {
            return acc;
          }
        },
        0
      );

      if (myEntry && poolInfo.pool.type === 'full') {
        const elements = _.filter(
          myEntry.user_tiebreakers,
          (tiebreaker) => tiebreaker.week_id === poolWeekSelected
        );
        tiesReady = elements.every((e) => e.value);
      } else {
        tiesReady = Object.keys(myEntry.user_tiebreakers).length === tiesUser;
      }
    } else {
      tiesReady = true;
    }

    picksReady = picksUser === totalPicks;

    hasRegister = myEntry.entry_id !== null ? true : false;

    if (picksReady && tiesReady && hasRegister) {
      statusReady = true;
    }
  }

  let conditionalComponent = (
    <div className="flex-row row-center estatusWrapper">
      <div className="flex-col col-no-padding font-chivo">
        Estatus:{' '}
        <span
          className="estatus-value"
          style={statusReady ? { color: '#87b13d', fontWeight: 'bold' } : { color: '#d3242b' }}
        >
          {statusReady ? (
            <strong>
              {' '}
              Listo <i className="ion-checkmark-circled" />
            </strong>
          ) : (
              'Pendiente'
            )}
        </span>
      </div>
      <div className="flex-col col-no-padding font-chivo">
        Picks Guardados:{' '}
        <span
          className="picks-guardados-value"
          style={picksReady ? { color: '#87b13d', fontWeight: 'bold' } : { color: '#d3242b' }}
        >
          {picksUser}/{totalPicks}
        </span>
      </div>
      {poolInfo.pool.use_tibreakers ? (
        <div className="flex-col col-no-padding font-chivo">
          Desempates:{' '}
          <span
            className="desempates-value"
            style={tiesReady ? { color: '#87b13d', fontWeight: 'bold' } : { color: '#d3242b' }}
          >
            {tiesReady ? 'Listo' : 'Pendiente'}
          </span>
        </div>
      ) : null}
    </div>
  );

  return conditionalComponent;
};

export default EstatusQuiniela;
