import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import numeral from 'numeral';
import moment from 'moment';
import { Link } from 'react-router-dom'
import API from '../../../utils/api';
import styles from './index.module.sass';
import historial from '../../../assets/images/historialIconBlanco-50.png';

export default function RewardHistory() {
  const [periods, setPeriods] = useState([]);
  const [userPeriod, setUserPeriod] = useState(null);
  const [userHistory, setUserHistory] = useState(null);
  const [notHistory, setNotHistory] = useState(null)

  useEffect(() => {
    API.fetchUserRewardHistory(userPeriod).then(response => {
      if (response.data.history != null && response.data.periods != null) {
        if (!response.data.periods[0]) {
          return setNotHistory(true)
        }
        setPeriods(response.data.periods.reverse());
        setUserHistory(response.data.history);
        setUserPeriod(response.data.periods[0].period);
      }
    })
  }, []);

  function handleShowHistory(period) {
    API.fetchUserRewardHistory(period).then(response => {
      setUserHistory(response.data.history);
      setUserPeriod(period);
    });
  }

  return (
    <div className="user-categories-container col-xs-12 col-md-9">
      <div className={styles.rewardsContainer}>
        <img id="historial" src={historial} className="pool-icons" alt="" />
        <div className={styles.rewardTitle}>Historial Programa de Lealtad</div>
      </div>

      {notHistory && (
        <NoHistory>
          <p>Este mes aun no cuentas con historial del <Link to='/usuario/recompensas'>Programa de Lealtad</Link></p>
          <p>Entre más juegues más podrás ganar, Conoce más del programa de lealtad <Link to='/landing/programa-de-lealtad'>aqui.</Link></p>
        </NoHistory>
      )}

      <div className={styles.historyYears}>
        {periods.map((item, i) => (
          <div
            key={item.period || i}
            className={`${styles.year} ${userPeriod === item.period ? styles.active : ''}`}
            onClick={() => handleShowHistory(item.period)}
          >
            {item.period}
          </div>
        ))}
      </div>

      {userPeriod !== null && userHistory !== null && (
        <div className={styles.historyTableContent}>
          <table>
            <thead>
              <tr>
                <th>Mes</th>
                <th>Nivel Alcanzado</th>
                <th>Total puntos</th>
                <th>Monto ganado</th>
              </tr>
            </thead>
            <tbody>
              {userHistory.map((period, idx) => {
                return (
                  <tr key={period.id || idx}>
                    <td>{moment(period.period).format('MMMM')}</td>
                    <td>{period.level.name}</td>
                    <td>
                      <b className={styles.primary}>
                        {numeral(period.points).format('0,0')} /{' '}
                        {numeral(period.level.max_points).format('0,0')}
                      </b>
                    </td>
                    <td>
                      <b className={styles.primary}>
                        ${numeral(period.amount / 100).format('0.00')}
                      </b>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const NoHistory = styled.div`
  margin: 3em 1.5em;

  a {
    color: #AFE140;
  }
`