// Libraries
import React from 'react';
import { Modal } from 'react-bootstrap/lib/';
import API from '../../utils/api';

const Survey = () => {
  const [show, setShow] =  React.useState(false);
  const [survey, setSurvey] =  React.useState(null);
  const [optionSelected, setOptionSelected] =  React.useState(false);

  const handleModalSurvey = () => {
    setShow(false);
  };

  const selectOption = (id) => {
    let option = survey.survey_options.find((option) => option.id === id);
    let data = {"option": option}
    API.saveSurvey(data).then((response) => {
      if (response.data.response) {
        setOptionSelected(true);
      }
    });
  };

  React.useEffect(() => {
    API.getSurveys().then((response) => {
      if (response.data.survey !== null) {
        setSurvey(response.data.survey);
        setShow(true);
      }
    });
  } , []);

  return (
    <>
      {survey !== null &&
        <Modal
          dialogClassName="modal-dialog-xxxxs"
          className="abonarModal absoluteModal"
          backdropClassName="abonarModalBackdrop"
          show={show}
        >
          <div className="panel-abonar">
            <div id="panelTitle" className="panel-abonar-titulo font-chivo">
              <span>{survey.title}</span>
              <button className="cerrar-panel" onClick={() => { handleModalSurvey() }} >
                <div className="btn-icon-close"><i className="ion-ios-close-empty" /></div>
              </button>
            </div>
            <div className="col-xs-12 panel-pickwin">
              {!optionSelected &&
                <>
                  <div className='mt-2 text-center font20'>
                    {survey.description}
                  </div>

                  <div className='mt-2 text-center'>
                    <img src={survey.imgs.desktop} alt="" width="100%" />
                  </div>

                  <div className='mb-2 text-center'>
                    <div className="flex-container around align wrap">
                      {survey.survey_options.map((option) => {
                        return (
                          <button type="button" class="btn-pick btn btn-default m-05 text-centered" onClick={() => {selectOption(option.id)}} style={{width: '150px', justifyContent: 'center'}}>
                            {option.title}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </>
              ||
                <div className='mt-2 flex-container align justify column'>
                  <label className='mb-1 font20'>¡Gracias por tu respuesta!</label>
                  <button type="button" className="btn-pick btn btn-default mb-2" onClick={() => {setShow(false)}} style={{width: '150px', justifyContent: 'center'}}>
                    CERRAR
                  </button>
                </div>
              }
            </div>
          </div>
        </Modal>
      }
    </>
  );
}

export default Survey;
