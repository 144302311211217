import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import _ from 'underscore'

// Components
import Button from '../Form/Button'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import { subscribe, unsubscribe } from '../../utils/events'

// Assets
import closeImage from '../../assets/img/bonos/Icono-cerrar-ventana.png'

export default function PopupBonuses () {
  const user = utils.getUserInfo()
  const [bonus, setBonus] = useState(null)

  useEffect(() => {
    if ((user && user.identity_verified) && !bonus) {
      checkBonuses()
    }
  }, [user, bonus])

  useEffect(() => {
    // 🚩 Will migrated to context
    subscribe('checkPopupBonuses', checkBonuses)

    return () => {
      unsubscribe('checkPopupBonuses')
    }
  }, [])

  const checkBonuses = async () => {
    try {
      const { data } = await API.getUserPopups()
      if (data && data.popup) {
        setBonus(data.popup)
        // Make as viewed
        markAsViewed(data.popup)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const markAsViewed = async (popup) => {
    try {
      const res = await API.postMarkSeenPopup(popup.id)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const close = () => {
    setBonus(null)
  }

  const stop = (e) => {
    e.stopPropagation()
  }

  if (!bonus) return null

  const content = (
    <Container onClick={close}>
        <ButtonClose onClick={close}>
          <img src={closeImage} alt='close' />
        </ButtonClose>
        <Box onClick={stop}>
          <Link onClick={close} to={bonus.action_desktop}>
            <BigImage src={bonus.image_desktop} />
          </Link>

          <Link onClick={close} to={bonus.action_desktop}>
            <Button bgColor='#88ba44'>Ir a depositar</Button>
          </Link>
        </Box>
    </Container>
  )

  return ReactDOM.createPortal(content, document.querySelector('body'))
}

const BigImage = styled.img`
  width: 100%;
  max-width: 900px;
  cursor: pointer;
`

const ButtonClose = styled.button`
  width: 2em;
  background: transparent;
  outline: 0;
  border: 0;
  position: absolute;
  right: 5vw;
  top: 3vh;
`

const show = keyframes`
  0% {
    transform: scale(0.60);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const showShadow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 1000ms ease;
  background-color: rgba(0,0,0,0.65);
  backdrop-filter: blur(5px) grayscale(1);
  animation: ${showShadow} 550ms ease forwards;
`

const Box = styled.div`
  width: fit-content;
  margin: 1em auto;
  animation: ${show} 330ms ease-out forwards;
  will-change: transform, opacity;
  overflow: hidden;

  button {
    width: 250px;
    margin: 1em auto;
  }
`
