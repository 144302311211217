// Libraries
import { useEffect } from 'react'
import io from 'socket.io-client'
import * as Sentry from '@sentry/browser'

// Components
import utils from '../../utils/utils'
import API from '../../utils/api'

// Hooks
import useAppContext from '../../hooks/useAppContext'
import useUserContext from '../../hooks/useUserContext'

const CasinoWs = ({ onWagerCreated, setShowMessageSaldo, setShowMessageError }) => {
  const { jwt } = useAppContext()
  const { userData, updateUserBalance } = useUserContext()

  // Get websocket subscription
  useEffect(() => {
    const socketUrl = process.env.REACT_APP_PW_SOCKET_WAGERS
    const websocket = io.connect(socketUrl, {
      query: { token: jwt },
      secure: true,
      transports: ['websocket']
    })

    websocket.on('connect', () => {
      websocket.emit('subscribe', userData.id)
    })

    websocket.on('wager:created', (data) => {
      const wagerAmount = Number(data.amount)
      const balance = utils.getUserBalance()
      balance.balanceReal = balance.balanceReal - wagerAmount
      updateUserBalance(balance)

      if (data.wager) {
        const wager = data.wager
        onWagerCreated(wager)
        API.trackWagerFB(data.amount)
      }
    })

    websocket.on('wager:balance', (data) => {
      const newBalance = Number(data.balance)
      const balance = utils.getUserBalance()
      balance.balanceReal = newBalance
      updateUserBalance(balance)
    })

    websocket.on('wager:error', (data) => {
      if (data && data.code) {
        switch (data.code) {
          // Error
          case 0:
            setShowMessageError(true)
            break
          // Saldo
          case 1:
            setShowMessageSaldo(true)
            break
          default:
            break
        }
      }

      console.log('Wager Error:', data)
      Sentry.captureException(data, {
        extra: 'Error in Casino Wager:error'
      })
    })

    return () => {
      websocket.disconnect()
    }
  }, [])

  return null
}

export default CasinoWs
