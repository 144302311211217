import React from 'react';
import styled from 'styled-components';
import cvvHelper from '../../assets/images/cvvHelper.png';
import billete from '../../assets/images/cash1-50.png';
import cardAmericanExpress from '../../assets/images/payment-icon-set/icons/american-express-curved-32px.png';
import cardMasterCard from '../../assets/images/payment-icon-set/icons/mastercard-curved-32px.png';
import cardMaestroCard from '../../assets/images/payment-icon-set/icons/maestro-curved-32px.png';
import cardVisa from '../../assets/images/payment-icon-set/icons/visa-curved-32px.png';
import cardVisaElectron from '../../assets/images/payment-icon-set/icons/visa-electron-curved-32px.png';

import openPayBlanco from '../../assets/images/kit_openpay/openpay_contraste.png';
import branchUtils from '../../utils/branchUtils';
import ButtonNew from '../Form/Button'
import gaUtils from '../GAWrapper';

import errorsOpenPay from './errorsOpenPay';

import numeral from 'numeral';
import API, { socketPurchases } from '../../utils/api';
import AbonarFormsy from '../AbonarFormsy';
import Formsy from 'formsy-react';
import utils from '../../utils/utils';
import {
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  Popover,
} from 'react-bootstrap/lib';
import SignatureCanvas from 'react-signature-canvas'
import Text from '../Text/Text';

import { publish, subscribe, unsubscribe } from '../../utils/events'

import tarjetas from '../../assets/images/newPaymentIcons/ccardswithAMex.png';
import { Iconify } from '../../Xmerald-UI';

const popoverCvv = (
  <Popover id="popover-cvv" title="Busca tu código de seguridad">
    <img src={cvvHelper} className="img-responsive" />
  </Popover>
);

const emptyDataCard = {
  card_number: null,
  holder_name: null,
  expiration_month: null,
  expiration_year: null,
  cvv2: null,
};

const ExtraErrors = ({ canSubmit, fieldsWithErrors, errors }) => {
  const convertNameField = (str) => {
    let converted = null;
    switch (str) {
      case 'expiration_month':
        converted = 'Mes: ';
        break;
      case 'expiration_year':
        converted = 'Año: ';
        break;
      case 'cvv2':
        converted = 'Código: ';
        break;
    }
    return converted;
  };

  if (canSubmit || !fieldsWithErrors) {
    return null;
  } else {
    let errorsList = [];
    errors.forEach((err) => {
      fieldsWithErrors.forEach((field) => {
        if (field.name === err) {
          errorsList = [
            ...errorsList,
            <span key={field.name} className="error-formulario start">
              <strong>{convertNameField(field.name)}</strong> {field.errorMessage}
            </span>,
          ];
        }
      });
    });

    return errorsList;
  }
};

const DepositResponse = ({
  isNewUser,
  dispatch,
  showDepositResponse,
  closeDepositResponse,
  errorWithMarkup,
  tipoDeposito,
  handleShowCard,
  netellerLink,
  astroPayLink,
  error,
  nick,
  savedCard,
  newCard,
  type,
  paymentResponse,
  newUserBalance,
  errorMessage,
  updateUserInfo,
  cantidadDeposito,
  otraCantidad,
}) => {
  const openWhatsAppHelp = () => {
    branchUtils.openWhatsAppHelp();
  };

  let conditionalComponent = null;
  let firstSentence = null;
  let secondarySentence = null;
  let panelTitle = null;

  let isNewUserHelp = null;

  isNewUserHelp = (
    <div className="flex-col col-80 col-no-padding col-offset-10 text-center transaction-success">
      <div className="flex-container column justify align " style={{ marginBottom: '10px' }}>
        <i className="ion-help-circled" />
        <strong className="  text-center">¿Necesitas ayuda?</strong>
        <Button className="flex-row btn-pick text-center " onClick={openWhatsAppHelp}>
          <span className="flex-col"> Chatear con alguien de Pickwin </span>
        </Button>
      </div>
    </div>
  );

  if (error) {
    panelTitle = 'Algo anda mal';

    firstSentence = (
      <span className=" flex-container justify align column">
        <i className="icon-failed ion-close-circled"></i>
        <span>{nick} tenemos un problema con tu transacción</span>
      </span>
    );
    if (errorWithMarkup) {
      secondarySentence = (
        <div className="flex-container column text-center align justify">
          <strong>Error: {error.code} </strong>
          <span style={{ marginBottom: '15px' }}>
            <div
              className=" flex-col col-offset-10 col-80"
              dangerouslySetInnerHTML={{ __html: error.message }}
            />
          </span>
        </div>
      );
    } else {
      secondarySentence = (
        <div className="flex-container column text-center align justify">
          <strong>Error: {error.code} </strong>
          <span style={{ marginBottom: '15px' }}>
            {error.message
              ? JSON.stringify(error.message)
              : typeof error === 'string'
                ? error
                : 'Algo no anda bien.'}
          </span>
        </div>
      );
    }
  } else {
    if (type === 'deposit') {
      panelTitle = 'Tu depósito se aprobó exitosamente';
      firstSentence = (
        <span className=" flex-container justify align column">
          <Iconify
            icon='ooui:success'
            color='#78bc28'
            style={{
              fontSize: '2.5em',
              margin: '0.8em 0'
            }}
          />
          <span>{nick} tu balance actual es de:</span>
        </span>
      );
      secondarySentence = (
        <div className="flex-col col-80 col-no-padding col-offset-10 text-center transaction-success">
          <div className="flex-container justify align " style={{ marginBottom: '10px' }}>
            <img src={billete} className="pool-icons" />
            <span className="text-container" style={{ fontSize: '1.5em', color: '#78bc28' }}>
              ${numeral(newUserBalance / 100).format('0,000.00')}
              <small>MXN</small>
            </span>
          </div>
        </div>
      );
    } else {
      panelTitle = 'Tu tarjeta se guardo correctamente';
      firstSentence = (
        <span className=" flex-container justify align column">
          <i className="icon-success ion-card"></i>
          <span>Se agregó exitosamente</span>
        </span>
      );
      secondarySentence = (
        <div className="flex-col col-80 col-no-padding col-offset-10 text-center transaction-success">
          <span className="text-center"> Ahora ya puedes abonar con ella.</span>
        </div>
      );
    }
  }

  conditionalComponent = (
    <Modal
      show={showDepositResponse}
      className="responseDepositModal absoluteModal"
      dialogClassName="responseDepositDialog modal-dialog-xxxs"
      backdropClassName="responseDepositBackdrop"
      style={{marginTop: '2rem'}}
    >
      <div className="">
        <div id="panelTitle" className={`panel-abonar-titulo ${error ? 'failed' : ''}`}>
          <span>{panelTitle}</span>
          <button
            className="cerrar-panel"
            onClick={() => {
              closeDepositResponse();
              if (handleShowCard) {
                if (!error) {
                  handleShowCard(false);
                }
              }
            }}
          >
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty"></i>
            </div>
          </button>
        </div>
        <div className="panel-pickwin" style={{ marginBottom: '15px' }}>
          {firstSentence}
          {secondarySentence}
          {!error && paymentResponse ? (
            <div className="flex-col col-80 col-offset-10 col-no-padding text-center transaction-success">
              <div className="flex-row ">
                <div className="flex-col col-center text-center">
                  <strong> Número de Autorización: </strong>{' '}
                  <span> {paymentResponse.authorization} </span>
                </div>
              </div>
              <div className="flex-row ">
                  {paymentResponse.id && (
                    <div className="flex-col col-center text-center">
                      <strong> Id de Orden: </strong> <span> {paymentResponse.id} </span>
                    </div>
                  )}
              </div>
            </div>
          ) : null}
          {savedCard && newCard ? (
            <React.Fragment>
              <div className="flex-container justify align">
                <div className="flex-col col-90 col-no-padding">
                  <div className="flex-container justify align ">
                    <i className="ion-card" style={{ fontSize: '2em' }} />
                  </div>
                  <div className="flex-row row-center row-no-padding">
                    <strong className="flex-col col-center text-center ">
                      Esta tarjeta ya está en tu perfil y se usará para tus proximos retiros.
                    </strong>
                  </div>
                  <a href="/usuario/mis_tarjetas" className="flex-row row-center">
                    <strong
                      className="flex-col col-center text-center"
                      style={{ color: '#78bc28' }}
                    >
                      Aquí puedes consultar todas tus tarjetas guardadas.
                    </strong>
                  </a>
                </div>
              </div>
            </React.Fragment>
          ) : null}
          {error ? isNewUserHelp : null}
        </div>
      </div>
    </Modal>
  );

  return conditionalComponent;
};

export const SavedCards = ({
  type,
  cardsList,
  handleCardsList,
  handleShowAddNewCard,
  handleCvvChange,
  selectedCard,
  other_amount,
  amountComponent,
  handleDeleteCard,
  handleShowForm,
  resetForm,
  activeMessage,
  handleSaveCard
}) => {
  const [showDeleteCard, setShowDeleteCard] = React.useState(false);
  const [deleteDisabled, setDeleteDisabled] = React.useState(false);
  const [cardDelete, setCardDelete] = React.useState({id: null, card_number: null});

  const handleDeleteCardButton = (card) => {
    setCardDelete({id: card.id, card_number: card.card_number});
    setShowDeleteCard(true);
  }

  async function handleConfirmDeleteCard(cardId) {
    setDeleteDisabled(true);
    const result = await handleDeleteCard(cardId);
    setDeleteDisabled(false);
    setShowDeleteCard(false);
    resetForm();
    if (result.cardsLength === 0) {
      handleShowForm(true);
    }
    if (result.status !== 'success') {
      alert("Error en la transacción");
    }
  }

  if (!cardsList || cardsList.length < 1) {
    return (
      <div className='row mt-1'>
        <div className='col-md-9'>
            <strong
              className="flex-container justify align help-note"
              style={{
                color: '#e5e6e8',
                textAlign: 'center',
                marginBottom: '5px',
                justifyContent: 'flex-start'
              }}
            >
              <i className="ion-alert-circled" />
              <span>{activeMessage}</span>
            </strong>
        </div>
        <div className='col-md-3'>
          {amountComponent}
        </div>
      </div>
    );
  }

  let buttonsAvailableCards = cardsList.map((card) => {
    let cardBrand = null;
    // console.log(card.brand);
    switch (card.brand) {
      case 'visa':
        cardBrand = cardVisa;
        break;
      case 'american_express':
        cardBrand = cardAmericanExpress;
        break;
      case 'visa_electron':
        cardBrand = cardVisaElectron;
        break;
      case 'mastercard':
        cardBrand = cardMasterCard;
        break;
      case 'maestro':
        cardBrand = cardMaestroCard;
        break;
    }
    return (
      <ToggleButton key={card.id} value={card.id} className="btn-formulario flex-col col-50" style={{flexWrap: 'wrap'}}>
        <img src={cardBrand} className="card-logo" />
        <strong style={{ marginRight: '5px' }}> {card.card_number} </strong>
        {` ${card.expiration_month}/${card.expiration_year}`}
        <div style={{marginLeft: '10px'}} onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleDeleteCardButton(card);}}>
          <i className='icon ion-trash-a' style={{fontSize: '14px'}}></i>
        </div>
      </ToggleButton>
    );
  });

  return (
    <div className="flex-row row-center row-no-padding mt-1">
      <div className="flex-col col-no-padding">
        <div style={{ marginBottom: '1rem' }}>
          <Modal
            dialogClassName="modal-dialog-xxxs"
            className=""
            show={showDeleteCard}
            onHide={() => {setShowDeleteCard(false)}}
            style={{marginTop: '5rem', zIndex: '1065'}}
          >
            <div className="transactionPanel">
              <div id="panelTitle" className="panel-abonar-titulo failed">
                <span>Borrar tarjeta</span>
                <button className="cerrar-panel" onClick={() => {setShowDeleteCard(false)}}>
                  <div className="btn-icon-close">
                    <i className="ion-ios-close-empty"></i>
                  </div>
                </button>
              </div>
              <div className="flex-row">
                <div className='flex-col text-center'>
                  <h5>¿Esta seguro que desea eliminar la tarjeta con terminación <strong>{cardDelete.card_number}</strong>?</h5>
                </div>
              </div>
              <div className="flex-row" style={{marginBottom: '1rem', width: '50%', margin: '0 auto'}}>
                <div className="flex-container center around">
                  <div className='flex-col'>
                    <Button disabled={deleteDisabled} className="btn-pick btn-default btn btn-rojo flex-col" onClick={() => {handleConfirmDeleteCard(cardDelete.id)}}>
                      Eliminar
                    </Button>
                  </div>
                  <div className='flex-col'>
                    <Button disabled={deleteDisabled} className="btn-pick btn-default btn flex-col" onClick={() => {setShowDeleteCard(false)}}>
                      Cancelar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <div className='row'>
            <div className='col-md-9'>
                <strong
                  className="flex-container justify align help-note"
                  style={{
                    color: '#e5e6e8',
                    textAlign: 'center',
                    marginBottom: '5px',
                    justifyContent: 'flex-start'
                  }}
                >
                  <i className="ion-alert-circled" />
                  <span>{activeMessage}</span>
                </strong>
            </div>
            <div className='col-md-3'>
              {amountComponent}
            </div>
          </div>

        </div>

        <div className='row'>
          <ButtonToolbar className="cantidad-abonar-toolbar flex-row row-no-padding">
              <ToggleButtonGroup
                className="flex-row"
                type="radio"
                name="savedCard"
                value={selectedCard ? selectedCard.id : null}
                onChange={handleCardsList}
                style={{flexWrap: 'wrap'}}
              >
                {buttonsAvailableCards}

                {/* New Card */}
                {type === 'cashout' ? null : (
                  <ButtonNewCard
                    onClick={handleShowAddNewCard}
                    className="btn-formulario flex-col col-50"
                  >
                    <strong style={{ marginLeft: '5px' }}> Nueva Tarjeta </strong>
                  </ButtonNewCard>
                )}
              </ToggleButtonGroup>
            </ButtonToolbar>
          </div>

        {
        selectedCard !== null ?
        (
          <div className="flex-container center justify">
            <div className='flex-container center justify my-2' style={{marginBottom: '1rem'}}>
              <div className="flex-col col-70">
                <strong>CÓDIGO DE SEGURIDAD</strong><br/>
                <small>Ingresa el código del reverso de tu tarjeta.</small>
              </div>
              <div className="flex-col">
                <AbonarFormsy
                  required
                  type={'SECURITY_CODE'}
                  name={'cvv2'}
                  label={'Código de seguridad'}
                  defaultValue={''}
                  placeholder='CVV'
                  validations={{ minLength: 3, maxLength: 4 }}
                  validationError={'El cvv tiene 3-4 dígitos.'}
                  inputHandler={handleCvvChange}
                  noColWidth={true}
                />
              </div>
            </div>
          </div>
        )
        : null
        }

        <div className="flex-row row-no-padding help-note" style={{marginBottom: '1rem'}}>
          <div className='cashier-card-warning'>
            <i className="ion-alert-circled"></i>
            <h5 className="text-center">
              ¡Recuerda!, La tarjeta que uses para depositar, será la tarjeta a la que abonaremos
              tus fondos cuando solicites un retiro. en los casos que se apliquen.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

class AddCardOpenPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      showIframe: false,
      iframeSource: '',
      paymentDataModel: {
        with3dsecure: true,
        amount: null,
        token: null,
        other_amount: null,
        card_id: null,
        cvv2: null,
        device_session_id: null,
        card: {
          holder_name: '',
          card_number: '',
          expiration_month: '',
          expiration_year: '',
          cvv2: '',
        },
        name: null,
        last_name: null,
        method: 'credit',
        gateway: 'openpay',
        save_card: false,
      },
      cardData: {
        amount: null,
        other_amount: null,
        card_id: null,
        card: {
          holder_name: '',
          card_number: '',
          expiration_month: '',
          expiration_year: '',
          cvv2: '',
          name: '',
          last_name: '',
        },
        method: 'credit',
        gateway: 'openpay',
        save: false,
      },
      error: null,
      monuted: false,
      savedCard: false,
      newCard: null,
      selectedCard: null,
      hasSavedCards: false,
      showResponseDeposit: false,
      showResponseSave: false,
      canSubmit: false,
      submitting: false,
      itNeedsVerification: false,
      requestStatus: null,
      fieldsWithErrors: null,
      showSignatureModal: false,
      showVoucherModal: false,
      requestPurchase: null,
      submitSignature: false,
      showErrorSignature: false,
    };

    this.handleName = (e) => {
      let temporalData = this.state.paymentDataModel;
      temporalData.name = e.target.value;
      this.setState({
        paymentDataModel: temporalData
      });

      let temporalPaymentData = this.state.paymentDataModel

      // Get holder full name
      // const holderName = (e.target.value || '') + ' ' + (this.state.paymentDataModel.last_name || '')
      // temporalPaymentData.card.holder_name = holderName
      this.setState({
        cardData: temporalPaymentData,
      });

      // document.getElementById('holder_name').value = holderName

      // this.refs.form.inputs.forEach((input) => {
      //   if (input.props.name === 'holder_name') {
      //     input.state.isPristine = false
      //     input.state.isValid = true
      //     input.state.value = holderName
      //   }
      // })
    };

    this.handleLastName = (e) => {
      let temporalData = this.state.paymentDataModel;
      temporalData.last_name = e.target.value;
      this.setState({ paymentDataModel: temporalData });

      let temporalPaymentData = this.state.paymentDataModel

      // Get holder full name
      // const holderName = (this.state.paymentDataModel.name || '') + ' ' + (e.target.value || '')

      // temporalPaymentData.card.holder_name =  holderName
      this.setState({
        cardData: temporalPaymentData,
      });

      // document.getElementById('holder_name').value = holderName

      // this.refs.form.inputs.forEach((input) => {
      //   if (input.props.name === 'holder_name') {
      //     input.state.isPristine = false
      //     input.state.isValid = true
      //     input.state.value = holderName
      //   }
      // })
    };

    this.resetForm = () => {
      if (this.props.resetFormAmount) {
        this.props.resetFormAmount();
      }
      console.log('ResetForm');
      let initialForm = {
        amount: 'other',
        token: null,
        device_session_id: this.props.deviceSession,
        other_amount: 200,
        card_id: null,
        card: {
          holder_name: null,
          card_number: null,
          expiration_month: null,
          expiration_year: null,
          cvv2: null,
        },
        name: null,
        last_name: null,
        save_card: undefined,
      };

      this.setState(
        {
          paymentDataModel: initialForm,
          cardData: initialForm,
          fieldsWithErrors: null,
          resetForm: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ resetForm: false });
          }, 300);
        }
      );
    };

    this.convertToPesos = (cant, otraCantidad) => {
      // console.log(cant, otraCantidad);
      switch (cant) {
        case 2:
          return '$50';
          break;
        case 4:
          return '$200';
          break;
        case 5:
          return '$100';
          break;
        case 7:
          return '$500';
          break;
        case 'other':
          return `$${otraCantidad}`;
          break;
      }
    };

    this.handleSaveCard = (e) => {
      let temporalPaymentData = this.state.paymentDataModel;
      temporalPaymentData.save_card = !temporalPaymentData.save_card;

      console.log('Payment Model');

      this.setState({
        cardData: temporalPaymentData,
      });
    };

    this.handleCardData = (e, category) => {
      // console.log( e, category);
      let temporalPaymentData = this.state.paymentDataModel;
      temporalPaymentData.card[category] = e.target.value;

      // console.log( 'Payment Model' );
      // console.log( temporalPaymentData );

      this.setState({
        cardData: temporalPaymentData,
      });
    };

    this.closeResponseDeposit = () => {
      this.setState({ showResponseDeposit: false }, () => {
        setTimeout(() => {
          this.setState({ error: null });
        }, 800);
      });
    };

    this.closeResponseSave = () => {
      this.setState({ showResponseSave: false }, () => {
        if (!this.state.error) {
          this.resetForm();
        }

        // if( this.props.handleShowCard ){
        //   this.props.handleShowCard( false );
        // }
        // else {
        setTimeout(() => {
          this.setState({ error: null });
        }, 800);
        // }
      });
    };

    this.enableButton = () => {
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      let fieldsWithErrors = [];
      // console.log('Invalid Form');
      // console.log(this.refs.form);
      this.refs.form.inputs.forEach((input) => {
        let fieldObject = { name: null, errorMessage: null };
        // console.log(input.props.name);
        // console.log('pristine:',input.isPristine());
        // console.log('valid:',input.isValid());
        if (
          !input.isPristine() &&
          !input.isValid() &&
          (input.props.name === 'expiration_month' ||
            input.props.name === 'expiration_year' ||
            input.props.name === 'cvv2')
        ) {
          fieldObject.name = input.props.name;
          fieldObject.errorMessage = input.props.validationError;
          fieldsWithErrors = [...fieldsWithErrors, fieldObject];
        }
      });
      //  console.log(  'fieldsWithErrors', fieldsWithErrors );
      this.setState({ canSubmit: false, fieldsWithErrors: fieldsWithErrors });
    };

    this.showPaymentResponse = (data) => {
      let newBalance = utils.getUserBalance().balanceReal + data.amount * 100;
      let temporalPaymentData = this.state.paymentDataModel;
      temporalPaymentData.card = emptyDataCard;

      this.setState(
        {
          submitting: false,
          savedCard: data.card && data.card.hasOwnProperty('created'),
          newCard: data.card,
          showResponseDeposit: true,
          paymentDataModel: temporalPaymentData,
          newBalance: newBalance,
          paymentResponse: data.response,
        },
        () => {
          if (data.card && data.card.hasOwnProperty('created')) {
            this.props.addNewCard(data.card);
          }
          // console.log(this.props);
          this.resetForm();

          const transaction = {
            amount: data.amount,
            provider: 'OpenPay',
            transactionId: data?.response?.id,
          };
          // console.log( 'send this transaction', transaction );
          // API.trackPurchaseSuccessFB(transaction);
          // gaUtils.trackUserPurchase(transaction);

          if (this.props.blockUi) {
            this.props.blockUi();
          }
          console.log("before update balance");
          if (this.props.dispatch) {
            this.props.updateUserInfo(this.props.dispatch);
          } else {
            if (this.props.updateUserBalance) {
              console.log("update_balance");
              this.props.updateUserBalance(newBalance);
            }
          }

          //this.handleScrollModal();
        }
      );
    };

    this.handleScrollModal = () => {
      console.log('Handle Scroll');
      setTimeout(() => {
        window.scrollTo(0, 0);
        // $('window').animate({ scrollTop: 0 }, 'slow');
      }, 250);
    };

    this.showPaymentError = (error, code, data) => {
      console.log(error, data);
      let temporalPayment = this.state.paymentDataModel;
      temporalPayment.card = emptyDataCard;

      if (this.props.blockUi) {
        this.props.blockUi();
      }

      let errorMessage = null;
      if (error.code === '0000') {
        errorMessage = error.error;
      } else {
        errorMessage = errorsOpenPay.tokenErrors[error.code];
      }
      this.setState(
        {
          submitting: false,
          paymentDataModel: temporalPayment,
          showResponseDeposit: true,
          error: { message: errorMessage },
          errorWithMarkup: true,
          showForm: true,
        },
        () => {
          // if( savedCard){
          //   this.props.addNewCard( card )
          //   }
          this.handleScrollModal();
        }
      );
    };

    this.callbackPayment = (data) => {
      let userData = utils.getUserInfo();
      this.handleScrollModal();
      if (data.success) {
        if (userData.is_new) {
          if (this.props.handleNewUserWithDeposit) {
            this.props.handleNewUserWithDeposit(true);
          }
        }
        this.props.updateUserInfo();

        // if (this.state.paymentDataModel.with3dsecure === false) {
        //   this.showPaymentResponse(data);
        // }

        if (this.state.paymentDataModel.with3dsecure === true) {
          if (this.props.releaseUI) {
            this.props.releaseUI();
          }

          this.resetForm();

          let temporalPaymentData = this.state.paymentDataModel;
          temporalPaymentData.card = emptyDataCard;

          this.setState(
            {
              submitting: false,
              savedCard: data.card && data.card.hasOwnProperty('created'),
              newCard: data.card,
              paymentDataModel: temporalPaymentData,
              paymentResponse: data.response,
            },
            () => {
              if (data.card && data.card.hasOwnProperty('created')) {
                this.props.addNewCard(data.card);
              }}
            )
        }
      } else {
        this.showPaymentError(data);
      }
    };

    this.callbackSave = (data) => {
      this.handleScrollModal();

      if (data.card) {
        this.showSaveResponse(data);
      } else {
        this.showSaveError(data);
      }
    };

    this.handleShowAddNewCard = () => {
      let newPaymentData = this.state.paymentDataModel;

      newPaymentData.card = emptyDataCard;
      newPaymentData.card_id = null;

      this.setState({ showForm: true, selectedCard: null, paymentDataModel: newPaymentData });
    };

    this.handleCardsList = (selectedCard) => {
      let card = null;
      this.props.cardsListOpenPay.forEach((c) => {
        if (c.id === selectedCard) {
          card = c;
        }
      });

      let newPaymentData = this.state.paymentDataModel;

      newPaymentData.card_id = selectedCard;
      newPaymentData.card = {};

      this.setState({
        selectedCard: card,
        showForm: false,
        paymentDataModel: newPaymentData,
        canSubmit: false,
      });

      if (this.state.paymentDataModel.cvv2 !== null) {
        this.setState({canSubmit: true});
      }
    };

    this.checkIfNeedsVerification = (amount) => {
      let userData = utils.getUserInfo();

      if (userData.is_new && amount >= 3000) {
        return true;
      } else if (!userData.identity_verified && amount > 20000) {
        return true;
      }
      return false;
    };

    this.handleDepositoCantidad = (eventKey) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;

      temporalPaymentDataModel.other_amount = eventKey === 'other' ? '300' : null;
      temporalPaymentDataModel.amount = eventKey;

      this.setState({ paymentDataModel: temporalPaymentDataModel });
    };

    this.handleOtraCantidad = (e) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;

      temporalPaymentDataModel.other_amount = e.target.value.replace(/[e,E]/, '');

      let itNeedsVerification = this.checkIfNeedsVerification(
        temporalPaymentDataModel.other_amount
      );

      this.setState({ paymentDataModel: temporalPaymentDataModel, itNeedsVerification });
    };

    this.handleCvvChange = (e) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;
      temporalPaymentDataModel.cvv2 = e.target.value;
      this.setState({ paymentDataModel: temporalPaymentDataModel });
    };

    this.submitSaveCard = () => {
      if (this.props.blockUi) {
        this.props.blockUi();
      }

      this.setState({ submitting: true });

      window.OpenPay.token.create(
        this.state.paymentDataModel.card,
        (response) => {
          this.successTokenizedCard(response);
        },
        (error) => {
          this.errorTokenizedCard(error);
        }
      );

      return
    };

    this.submitPaymentAwaitSMS = () => {
      this.submitPayment()
    }

    subscribe('SMS:ACOUNT_VERIFIED', this.submitPaymentAwaitSMS)

    this.submitPayment = () => {
      const userData = utils.getUserInfo();
      // 1 - Check if user is verified
      // 2 - If user is verified make a payment
      // 3 - If user is not verified show the modal to verify
      // 4 - When user confirms number make a payment
      if (!userData.identity_verified) {
        return publish('SMS:SHOW_MODAL')
      }

      if (this.state.paymentDataModel.other_amount < 5000) {
        if (this.props.blockUi) {
          this.props.blockUi();
        }
      }
      this.setState({ submitting: true });
      if (!this.state.paymentDataModel.card_id) {
        window.OpenPay.token.create(
          this.state.paymentDataModel.card,
          (response) => {
            this.successTokenizedCard(response);
          },
          (error) => {
            this.errorTokenizedCard(error);
          }
        );
      } else {
        this.doPurchaseOpenPay();
      }
    };

    this.doPurchaseOpenPay = () => {
      console.log('%c Do Purchase OpenPay', 'font-size:2em;');

      // Cuando se usa el componente no en popup si no en /usuario/mi-cuenta
      // Donde solo se guarda la tarjeta
      if (this.props.justSave) {
        let saveCard = {
          name: this.state.cardData.card._name,
          token: this.state.paymentDataModel.token,
          device_session_id: this.state.paymentDataModel.device_session_id,
        };

        API.addOpenPayCard(saveCard)
          .then((responseAddCard) => {
            if (responseAddCard.data.success) {
              if (this.props.updateCardList) {
                this.props.updateCardList(this.state.newCard);
                let temporalPaymentData = this.state.paymentDataModel;
                temporalPaymentData.card = null;
                temporalPaymentData.with3dsecure = true // OpenPay.card.cardType(this.state.paymentDataModel.card.card_number) !== 'American Express'

                this.setState(
                  {
                    paymentDataModel: temporalPaymentData,
                  },
                  () => {
                    API.postDoPurchase(this.state.paymentDataModel)
                      .then((response) => {
                        this.callbackPayment(response.data);
                      })
                      .catch((err) => {
                        this.showPaymentError(err);
                      });
                  }
                );
              }
            } else {
              this.showSaveError(responseAddCard.data.error);
            }
          })
          .catch((error) => {
            console.error(error);
            this.showSaveError(error);
          });
      }

      // why we need this?
      // Cuando es nueva tarjeta y usuario presiona [*] Guardar tarjeta
      if (this.state.paymentDataModel.save_card) {
        let temporalPaymentData = this.state.paymentDataModel;

        temporalPaymentData.with3dsecure = true // OpenPay.card.cardType(this.state.paymentDataModel.card.card_number) !== 'American Express'
        temporalPaymentData.card = null;
        temporalPaymentData.card_id = null;
        temporalPaymentData.cvv2 = null;

        temporalPaymentData.extra = {
          'url': window.location.href,
          'cookies': utils.getCookiesKeyVal(),
        };

        this.setState(
          {
            paymentDataModel: temporalPaymentData,
          },
          () => {
            API.postDoPurchase(this.state.paymentDataModel)
              .then((response) => {
                // Si es 3D Secure
                if (response.data.show_redirect) {
                  this.setState({
                    showIframe: true,
                    iframeSource: response.data.redirect_url
                  })
                }
                if (response.data.show_voucher) {
                  this.setState({requestPurchase: response.data}, () => {
                    this.setState({showVoucherModal: true});
                  });
                } else {
                  this.callbackPayment(response.data);
                  this.props.updateCardList();
                }
              })
              .catch((err) => {
                console.error(err);
                this.showPaymentError(err);
              });
          }
        );
      } else {
        // En pago con tarjetas guardadas (tokenizadas) o cuando se agrega una nueva tarjeta 🫤🤦‍♂️
        let temporalPaymentData = this.state.paymentDataModel;

        // const isWidh3DSecure = () => {
        //   if (typeof this.state.selectedCard?.brand === 'string') {
        //     return this.state.selectedCard?.brand !== 'american_express'
        //   }

        //   if (typeof temporalPaymentData.card.card_number === 'string') {
        //     return OpenPay.card.cardType(temporalPaymentData.card.card_number) !== 'American Express'
        //   }
        // }

        temporalPaymentData.with3dsecure = true // isWidh3DSecure()
        temporalPaymentData.card.card_number

        temporalPaymentData.card = null;
        temporalPaymentData.extra = {
          'url': window.location.href,
          'cookies': utils.getCookiesKeyVal(),
        };

        this.setState(
          {
            paymentDataModel: temporalPaymentData,
          },
          () => {
            API.postDoPurchase(this.state.paymentDataModel)
              .then((response) => {

                // If is 3D Secure
                if (response.data.show_redirect) {
                  this.setState({
                    showIframe: true,
                    iframeSource: response.data.redirect_url
                  })
                }
                if (response.data.show_voucher) {
                  this.setState({requestPurchase: response.data}, () => {
                    this.setState({showVoucherModal: true});
                  });
                } else {
                  this.callbackPayment(response.data);
                }
              })
              .catch((err) => {
                this.showPaymentError(err);
              });
          }
        );
      }
    };

    this.successTokenizedCard = (response) => {
      let temporalPaymentData = this.state.paymentDataModel;
      temporalPaymentData.token = response.data.id;
      this.setState(
        {
          paymentDataModel: temporalPaymentData,
          newCard: response.data.card,
        },
        () => {
          if (!this.props.justSave) {
            this.doPurchaseOpenPay();
          } else {
            if (this.state.paymentDataModel.save_card) {

              let saveCard = {
                name: this.state.cardData.name,
                last_name: this.state.cardData.last_name,
                token: this.state.paymentDataModel.token,
                device_session_id: this.state.paymentDataModel.device_session_id,
              };

              API.addOpenPayCard(saveCard).then((responseAddCard) => {
                if (responseAddCard.data.success) {
                  this.setState({ submitting: false });
                  if (this.props.updateCardList) {
                    this.props.updateCardList(response.data.card);
                    if (this.props.blockUi) {
                      this.props.blockUi();
                    }
                    if (this.props.resetData) {
                      this.props.resetData(false);
                    }
                  }
                } else {
                  this.showSaveError(responseAddCard.data.error);
                }
              });
            }
          }
        }
      );
      // this.callbackPayment( response.data );
    };

    this.showSaveResponse = (data) => {
      this.setState({ submitting: false, showResponseSave: true }, () => {

        if (this.props.blockUi) {
          this.props.blockUi();
        }
        this.handleScrollModal();
        let newCard = data.card;
        if (this.props.updateCardList) {
          this.props.updateCardList(newCard);
        } else {

        }
      });
    };

    this.errorTokenizedCard = (error) => {
      if (this.blockUi) {
        this.blockUi();
      }
      // let currentError = errorsOpenPay.tokenErrors[error.data.error_code];
      this.showSaveError(error, error.hasOwnProperty('data'), () => {
        this.handleScrollModal();
      });
      // console.error( currentError );
    };

    this.showSaveError = (error, isRequestError) => {
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      this.setState(
        {
          submitting: false,
          showResponseSave: true,
          error: {
            message: isRequestError
              ? errorsOpenPay.tokenErrors[error.data.error_code]
              : errorsOpenPay.tokenErrors[error.code],
          },
          code: error.code,
        },
        () => {
          this.handleScrollModal();
        }
      );
    };

    this.resetData = () => {
      this.setState({
        requestStatus: null,
      });
    };

    this.showForm = (value) => {
      this.setState({showForm: value});
    }

    this.showSignatureModal = (value) => {
      this.setState({showSignatureModal: value});
    }

    this.showVoucherModal = (value) => {
      this.setState({showVoucherModal: value});
    }

    this.showErrorSignature = (value) => {
      this.setState({showErrorSignature: value});
    }

    this.getDataFromCanvas = (canvas) => {
      this.setState({submitSignature: true});
      let interviewSession;
      let onBoarding = API.getIncodeOnBoarding();
      onBoarding.createSession('MX').then((session) => {
        interviewSession = session;
        let params = {type: 'signature', session: interviewSession};
        return API.incodeSaveSession(params);
      })
      .then((response) => {
        canvas.getCanvas().toBlob((blob) => {
          onBoarding.addSignature({
            token: interviewSession.token,
            image: blob,
          }).then((response) => {
            let params = {transactionId: this.state.requestPurchase.response.cctransaction, session: interviewSession};
            API.incodeSignVoucher(params).then((response) => {
              if (response.data.success) {
                this.props.blockUi();
                this.setState({submitSignature: false});
                this.showSignatureModal(false);
                this.callbackPayment(this.state.requestPurchase);
                this.props.updateCardList();
              } else {
                this.setState({submitSignature: false});
                this.showSignatureModal(false);
                alert("No se pudo completar la compra, para poder terminar con tu compra por favor comunicate a soporte@pickwin.net")
              }
            });
          });
        }, 'image/png', 1);

      })
      .catch((err) => {

      });
    }

    // si el blob no funciona usar esta
    this.getBinaryImage = (e) => {
      for (var t = atob(e.split(",")[1]), n = [], r = 0; r < t.length; r++) {
        n.push(t.charCodeAt(r));
      }
      return new Blob([new Uint8Array(n)])
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.amount !== this.props.amount ||
      prevProps.other_amount !== this.props.other_amount
    ) {
      let newPaymentData = this.state.paymentDataModel;

      newPaymentData.amount = this.props.amount;
      newPaymentData.other_amount = this.props.other_amount;
      this.setState({ paymentDataModel: newPaymentData });
    }
  }

  componentDidMount() {
    //add incode onBoarding sdk
    var fileref=document.createElement('script');
    fileref.setAttribute("type","text/javascript");
    fileref.setAttribute("src", "https://sdk.incode.com/sdk/onBoarding-1.48.0.js");
    document.getElementsByTagName("head")[0].appendChild(fileref);

    let newPaymentData = this.state.paymentDataModel;

    newPaymentData.amount = this.props.amount ? this.props.amount : null;
    newPaymentData.other_amount = this.props.other_amount ? this.props.other_amount : null;
    newPaymentData.device_session_id = this.props.deviceSession;
    newPaymentData.save_card = this.props.justSave;

    this.setState(
      {
        paymentDataModel: newPaymentData,
        hasSavedCards: this.props.cardsListOpenPay && this.props.cardsListOpenPay.length > 0,
        showForm: !this.props.cardsListOpenPay || this.props.cardsListOpenPay.length === 0,
        mounted: true,
        selectedCard:
          this.props.cardsListOpenPay && this.props.cardsListOpenPay.length > 0
            ? this.props.cardsListOpenPay[0]
            : null,
        cardsList: this.props.cardsListOpenPay
      },
      () => {
        if (this.state.hasSavedCards) {
          let newPaymentData = this.state.paymentDataModel;
          newPaymentData.card_id = this.props.cardsListOpenPay[0].id;
          newPaymentData.card = {};
          this.setState({ paymentDataModel: newPaymentData, canSubmit: true });
        }
      }
    );


    socketPurchases.on('purchase:completed', (data) => {
    // debugger
    //   {
    //     "amount": 20000,
    //     "balance": 5087133,
    //     "trans_id": "trxsecafcfce79dr0czd",
    //     "status_url": "https://staging.pickwin.net/api/cctransactions/confirm?id=trxsecafcfce79dr0czd"
    //  }

      const transaction = {
        amount: data.amount,
        provider: 'OpenPay',
        transactionId: data?.trans_id,
      };

      API.trackPurchaseSuccessFB(transaction);
      gaUtils.trackUserPurchase(transaction);

      const newBalance = data.balance

      this.setState({
        iframeSource: data.status_url,
        showResponseDeposit: true,
        newBalance: newBalance,
        paymentResponse: {
          authorization: data.trans_id,
          id: ''
        },
      })

      if (data.amount >= 500000 && this.props.blockUi) {
        window.setTimeout(() => this.props.blockUi(), 500)
      }

      if (this.props.dispatch) {
        this.props.updateUserInfo(this.props.dispatch);
      } else {
        if (this.props.updateUserBalance) {
          this.props.updateUserBalance(newBalance);
        }
      }
    })

    socketPurchases.on('purchase:failed', (data) => {
      this.setState({
        iframeSource: data.status_url
      })
    })
  }

  componentWillUnmount () {
    unsubscribe('SMS:ACOUNT_VERIFIED', this.submitPaymentAwaitSMS)
  }

  getVoucherPDF (cctransaction) {
    const isDev = process.env.NODE_ENV === 'development'

    return `https://docs.google.com/viewer?url=https://pw-resources.storage.googleapis.com/vouchers/voucher-${isDev ? 'devel-': ''}${cctransaction}.pdf&embedded=true&hl=es`
  }

  closeIframe = () => {
    this.setState({ showIframe: false, iframeSource: '' })
  }

  render() {
    let userData = utils.getUserInfo();
    let signatureCanvas = null;

    if (!this.props.show || !this.state.mounted) {
      return null;
    }

    return (
      <React.Fragment>
        <Modal
          show={this.state.showSignatureModal}
          className=""
          dialogClassName="modal-dialog-xxxs"
          backdropClassName=""
          style={{marginTop: '2rem', zIndex: '1070'}}
        >
          <React.Fragment>
            <div id="panelTitle" className={`panel-abonar-titulo`}>
              <span>Firmar voucher</span>
              <button
                className="cerrar-panel"
                onClick={() => {
                  this.showSignatureModal(false);
                  this.showErrorSignature(true);
                  this.setState({submitting: false});
                }}
              >
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty"></i>
                </div>
              </button>
            </div>
            <div className="panel-pickwin" style={{ marginBottom: '15px' }}>
              <div className="flex-col col-80 col-offset-10 col-no-padding text-center transaction-success">
                <div className="flex-row text-center">
                  Con el puntero del mouse o tu dedo, firma en el recuadro blanco de abajo y da click en aceptar para continuar con tu proceso
                </div>
                <div className="flex-row">
                  <SignatureCanvas penColor='black' backgroundColor="rgb(255, 255, 255)" canvasProps={{width: 400, height: 400, className: 'signature-canvas'}} ref={(ref) => {signatureCanvas = ref;}} style={{touchAction: 'none'}}/>
                </div>

                <div className="flex-row flex-container justify align column">
                  <div onClick={() => {signatureCanvas.clear()}} style={{fontSize: '16px', fontWeight: 'bold', margin: '1rem', cursor: 'pointer'}}>Limpiar firma</div>
                  <Button
                    onClick={() => {this.getDataFromCanvas(signatureCanvas)}}
                    type="button"
                    className="btn-pick"
                    disabled={this.state.submitSignature}
                    style={{width: '200px', marginTop: '1rem'}}
                  >
                    <span className="flex-col col-center text-center">
                      Aceptar
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Modal
          show={this.state.showVoucherModal}
          className=""
          dialogClassName="modal-dialog-xxxs"
          backdropClassName=""
          style={{marginTop: '2rem', zIndex: '1070'}}
        >
          <React.Fragment>
            <div id="panelTitle" className={`panel-abonar-titulo`}>
              <span>Transacción exitosa</span>
              <button
                className="cerrar-panel"
                onClick={() => {
                  this.showVoucherModal(false);
                  this.showErrorSignature(true);
                  this.setState({submitting: false});
                }}
              >
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty"></i>
                </div>
              </button>
            </div>
            <div className="panel-pickwin" style={{ marginBottom: '15px' }}>
              <div className="flex-col col-80 col-offset-10 col-no-padding transaction-success">
                {this.state.requestPurchase !== null && (
                  <React.Fragment>
                    <div className="flex-row">
                      <div className="flex-col"><strong>Autorización:</strong></div>
                      <div className="flex-col"><strong>{this.state.requestPurchase.response.authorization}</strong></div>
                    </div>
                    <div className="flex-row">
                      <div className="flex-col"><strong>Transacción:</strong></div>
                      {this.state.requestPurchase.response.id && (
                        <div className="flex-col"><strong>{this.state.requestPurchase.response.id}</strong></div>
                      )}
                    </div>

                    <div className="flex-row mt-1">
                      Para terminar con tu compra requerimos que firmes el siguiente comprobante de compra
                    </div>

                    <div className="flex-row mt-1">
                      <iframe src={this.getVoucherPDF(this.state.requestPurchase.response.cctransaction)} style={{width: '100%', height: '500px'}} />
                    </div>

                    <div className="flex-row">
                      <Button
                        onClick={() => {
                          this.showVoucherModal(false);
                          this.showSignatureModal(true);
                        }}
                        type="button"
                        className="btn-pick"
                        disabled={false}
                        style={{width: '100%', marginTop: '1rem'}}
                      >
                        <span className="flex-col col-center text-center">
                          Firmar
                        </span>
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <Modal
          show={this.state.showErrorSignature}
          className=""
          dialogClassName="modal-dialog-xxxs"
          backdropClassName=""
          style={{marginTop: '2rem', zIndex: '1070'}}
        >
          <React.Fragment>
            <div id="panelTitle" className={`panel-abonar-titulo failed`}>
              <span>Error al Firmar voucher</span>
              <button
                className="cerrar-panel"
                onClick={() => {this.showErrorSignature(false)}}
              >
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty"></i>
                </div>
              </button>
            </div>
            <div className="panel-pickwin" style={{ marginBottom: '15px' }}>
              <div className="flex-col col-80 col-offset-10 col-no-padding text-center transaction-success">
                No firmaste el comprobante de compra, para poder terminar con tu compra por favor comunicate a {' '}
                <strong><a href="mailto:soporte@pickwin.net">soporte@pickwin.net</a></strong>
                {' '} o a través de {' '}
                <a href="#" onClick={() => {branchUtils.openWhatsAppHelp();}}>
                  <strong>whatsApp <i className='ion-social-whatsapp'></i></strong>
                </a>
              </div>
            </div>
          </React.Fragment>
        </Modal>
        <DepositResponse
          isNewUser={utils.getUserInfo().is_new}
          errorWithMarkup={this.state.errorWithMarkup}
          dispatch={this.props.dispatch}
          paymentResponse={this.state.paymentResponse}
          showDepositResponse={this.state.showResponseDeposit}
          closeDepositResponse={this.closeResponseDeposit}
          nick={utils.getUserInfo().nick}
          handleShowCard={this.props.handleShowCard}
          type={'deposit'}
          savedCard={this.state.savedCard}
          newCard={this.state.newCard}
          newUserBalance={this.state.newBalance}
          error={this.state.error}
          updateUserInfo={this.props.updateUserInfo}
        />
        <DepositResponse
          isNewUser={utils.getUserInfo().is_new}
          errorWithMarkup={this.state.errorWithMarkup}
          dispatch={this.props.dispatch}
          handleShowCard={this.props.handleShowCard}
          paymentResponse={this.state.paymentResponse}
          showDepositResponse={this.state.showResponseSave}
          closeDepositResponse={this.closeResponseSave}
          type={'save'}
          nick={utils.getUserInfo().nick}
          newUserBalance={this.state.newBalance}
          error={this.state.error}
          updateUserInfo={this.props.updateUserInfo}
        />
        <Formsy ref="form" onValid={this.enableButton} onInvalid={this.disableButton}>
          <div className="flex-col col-no-padding" id="card-info">
            <SavedCards
              cardsList={this.props.cardsListOpenPay}
              handleCardsList={this.handleCardsList}
              handleShowAddNewCard={this.handleShowAddNewCard}
              handleCvvChange={this.handleCvvChange}
              selectedCard={this.state.selectedCard}
              other_amount={this.props.other_amount}
              amountComponent={this.props.amountComponent}
              handleDeleteCard={this.props.handleDeleteCard}
              handleShowForm={this.showForm}
              resetForm={this.resetForm}
              handleSaveCard={this.handleSaveCard}
              activeMessage={this.props.activeMessage}
            />
            {this.state.showForm ? (
              <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div className="flex-container justify align column" style={{marginRight: '1rem'}}>
                    <div className="flex-col col-center col-no-padding  text-center">
                      <img src={tarjetas} />
                    </div>
                  </div>
                </div>
                {!userData.name ? (
                  <div className="flex-row row-center row-no-padding mt-1">
                    <AbonarFormsy
                      col="col-45"
                      type={'TEXT'}
                      required
                      resetForm={this.state.resetForm}
                      defaultValue={''}
                      name={'name'}
                      placeholder='NOMBRE'
                      validations={{ minLength: 2 }}
                      validationError={'Utiliza tu nombre.'}
                      inputHandler={this.handleName}
                    />
                    <AbonarFormsy
                      col="col-50 col-offset-5"
                      type={'TEXT'}
                      required
                      resetForm={this.state.resetForm}
                      defaultValue={''}
                      name={'last_name'}
                      placeholder='APELLIDOS'
                      validations={{ minLength: 2 }}
                      validationError={'Utiliza tu apellido.'}
                      inputHandler={this.handleLastName}
                    />
                  </div>
                ) : null}
                <Text className={`flex-row row-center ${!userData.name ? '' : 'hide'}`} size={14}>
                  Por favor dinos tu nombre y apellidos. Estos serán verificados al momento de
                  realizar alguna solicitud de retiro.
                </Text>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '1em' }}>
                    <AbonarFormsy
                      type={'TEXT'}
                      required
                      resetForm={this.state.resetForm}
                      // defaultValue={''}
                      name={'holder_name'}
                      // label={'Nombre Completo'}
                      dataId='holder-name'
                      placeholder='NOMBRE COMO APARECE EN LA TARJETA'
                      validations={{ minLength: 2 }}
                      validationError={'Utiliza el nombre como aparece en la tarjeta. '}
                      inputHandler={this.handleCardData}
                      defaultValue={ this.state.cardData.holder_name}
                    />

                    <AbonarFormsy
                      type={'CARD_NUMBER'}
                      name={'card_number'}
                      defaultValue={''}
                      resetForm={this.state.resetForm}
                      // label={'Número de tarjeta'}
                      placeholder={'NÚMERO DE TARJETA'}
                      required
                      validationError={'El número de tu tarjeta debe de tener de 12-16 dígitos.'}
                      validations={
                        ('isNumeric',
                        {
                          isBetween: (values, value) => {
                            return value >= 100000000000 && value <= 99999999999999999;
                          },
                        })
                      }
                      inputHandler={this.handleCardData}
                      col={true}
                    />
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <AbonarFormsy
                      required
                      type={'MONTH'}
                      resetForm={this.state.resetForm}
                      validations={{
                        isBetween: (values, value) => {
                          return value >= 1 && value <= 12;
                        },
                      }}
                      defaultValue={''}
                      validationError={'Ingresa el mes en dos dígitos.'}
                      name={'expiration_month'}
                      inputHandler={this.handleCardData}
                    />
                  </div>
                  <div className="col-sm-4">
                    <AbonarFormsy
                      required
                      resetForm={this.state.resetForm}
                      type={'YEAR'}
                      validations={{
                        isBetween: (values, value) => {
                          return value >= 19 && value <= 66;
                        },
                      }}
                      defaultValue={''}
                      validationError={'Ingresa útimos 2 digitos del año'}
                      name={'expiration_year'}
                      inputHandler={this.handleCardData}
                    />
                  </div>
                  <div className="col-sm-4">
                    <AbonarFormsy
                      required
                      resetForm={this.state.resetForm}
                      type={'SECURITY_CODE'}
                      name={'cvv2'}
                      justSave={this.props.justSave}
                      // label={'Código de seguridad'}
                      placeholder='CVV'
                      defaultValue={''}
                      validations={{ minLength: 3, maxLength: 4 }}
                      validationError={'El cvv tiene 3-4 dígitos.'}
                      inputHandler={this.handleCardData}
                    />
                  </div>
                </div>
                {this.props.justSave ? null : (
                  <div className="flex-row row-center">
                    <AbonarFormsy
                      type={'SAVE_CARD'}
                      name={'saveCard'}
                      label={'Guardar tarjeta'}
                      inputHandler={this.handleSaveCard}
                    />
                  </div>
                )}
                <div className="flex-row row-center row-no-padding">
                  <div className="flex-col">
                    <ExtraErrors
                      errors={['expiration_month', 'expiration_year']}
                      canSubmit={this.state.canSubmit}
                      fieldsWithErrors={this.state.fieldsWithErrors}
                    />
                  </div>
                  <div className="flex-col">
                    <ExtraErrors
                      errors={['cvv2']}
                      canSubmit={this.state.canSubmit}
                      fieldsWithErrors={this.state.fieldsWithErrors}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
          <div className="flex-row no-padding">
            {this.props.justSave && this.state.showForm ? (
              <Button
                onClick={this.submitSaveCard}
                type="submit"
                className="callToAction btn-pick flex-col"
                disabled={!this.state.canSubmit || this.state.submitting ? true : false}
              >
                <span className="flex-col col-center text-center ">
                  {this.state.submitting ? (
                    <div className="flex-container justify align">
                      <i className="ion-load-a loading small"></i>
                    </div>
                  ) : (
                      <div className="flex-col ">{`Guardar Tarjeta`}</div>
                    )}
                </span>
              </Button>
            ) : (
                <ButtonNew
                  type="submit"
                  bgColor='#88ba44'
                  className='btn-pick btn btn-sm btn-default'
                  onClick={this.submitPayment}
                  loading={this.state.submitting}
                  disabled={
                    !this.state.canSubmit || this.state.submitting || this.props.errorOtraCantidad
                      ? true
                      : false
                  }
                  style={{
                    maxWidth: 300,
                    margin: '1em auto'
                  }}
                >
                  Abonar {this.convertToPesos(
                    this.state.paymentDataModel.amount,
                    this.state.paymentDataModel.other_amount
                  )}
                </ButtonNew>
              )}
          </div>
          <div className="flex-row row-no-padding">
            <div className="flex-col">
              <div className="flex-row ">
                <p className="flex-col text-center col-no-padding">
                  Los cargos a tu tarjeta serán realizados por{' '}
                  <a href="https://www.openpay.mx/caracteristicas.html" target="_blank">
                    {' '}
                    <strong> Openpay </strong>
                  </a>
                </p>
              </div>
              <div className="flex-container justify align  ">
                <div className="fle-col col-center text-center col-no-padding">
                  <img
                    className=" img-responsive"
                    src={openPayBlanco}
                    style={{ filter: 'invert(1)', maxHeight: '50px', width: 'auto' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Formsy>

        {/* ----------  Confirm popup for 3dSecure ----------  */}
        <Modal
          dialogClassName="modal-dialog-xxxs"
          className="abonarModal absoluteModal"
          backdropClassName="abonarModalBackdrop"
          show={this.state.showIframe}
        >

          <div className="panel-abonar" style={{ maxWidth: 600, margin: '0 auto' }}>
            <div id="panelTitle" className="panel-abonar-titulo font-chivo">
              <span>Confirmación de pago</span>
              <button className="cerrar-panel" onClick={this.closeIframe} >
                <div className="btn-icon-close"><i className="ion-ios-close-empty" /></div>
              </button>
            </div>
            <div className="col-xs-12 panel-pickwin" style={{ padding: 0 }}>
              <Iframe src={this.state.iframeSource} frameBorder={0} />
            </div>
          </div>
        </Modal>

      </React.Fragment>
    );
  }
}

const Iframe = styled.iframe`
  height: 100%;
  height: 700px;
  background-color: #FFF;
`

const ButtonNewCard = styled.button`
  color: #000;
  flex-wrap: wrap;
  border: 2px solid black;

  &:focus {
    background-color: #88ba44;
    color: #FFF;
  }
`

export default AddCardOpenPay;
