import React, { useEffect, useRef } from 'react'
import { Box, InputNumber, MultiOption, Typography } from '../../../Xmerald-UI'

// Components
import SelectTournament from './SelectTournament'
import WizardLayout from '../shared/Layout'

// Hooks
import { useWizardPrivate } from '../wizardPrivate.store'
import useTournamentWeeks from './useTournamenWeeks'

export default function SurvivorDuration () {
  const limitStrikessRef = useRef()
  const step = useWizardPrivate(state => state.step)
  const survivorData = useWizardPrivate(state => state.survivorData)
  const setSurvivorData = useWizardPrivate(state => state.setSurvivorData)
  const activeTournamentID = useWizardPrivate(state => state.activeTournamentID)

  // Weeks
  const { data, isLoading } = useTournamentWeeks(activeTournamentID)

  useEffect(() => {
    if (limitStrikessRef.current) {
      limitStrikessRef.current?.focus()
    }
  }, [limitStrikessRef, survivorData.strikes_enabled])

  if (step !== 'SURVIVOR_DURATION') return null

  const getMaxStrikes = () => {
    // Default 3 strikes
    if (!data?.weeks) return 3

    const selectedWeek = data?.weeks?.find(week => week.id === survivorData.starting_week)?.max_strikes

    if (!selectedWeek) {
      // If user don't select the week then take the first data week
      return data.weeks[0]?.max_strikes
    }

    // User selected a week
    return selectedWeek
  }

  const maxStrikes = getMaxStrikes()

  const errorLimitStrikes = (survivorData.strikes_enabled && (
    survivorData.max_strikes < 2 ||
    survivorData.max_strikes > maxStrikes
  ))

  const disabledNext = (
    survivorData.starting_week === null ||
    errorLimitStrikes
  )

  const getLimitError = () => {
    if (Number(survivorData.max_strikes) > maxStrikes) {
      return `Debe ser maximo de ${maxStrikes}`
    }
    if (Number(survivorData.max_strikes) <= 0) {
      return `Tu survivor debe de tener entre 2 y ${maxStrikes} strikes`
    }
  }

  return (
    <WizardLayout
      prevStep='SURVIVOR_SPORT'
      nextStep='SURVIVOR_PRICE'
      disabledNext={disabledNext}
    >
      <Box maxWidth={500}>
        <MultiOption
          onChange={setSurvivorData}
          keyName='strikes_enabled'
          title='¿Quieres que tu survivor tenga strikes?'
          value={survivorData.strikes_enabled}
          options={[
            {
              name: 'Con Strikes',
              value: true
            },
            {
              name: 'Sin Strikes',
              value: false
            }
          ]}
        />
        {survivorData.strikes_enabled && !isLoading && (
          <Box>
            <InputNumber
              label='¿Cuántos strikes quieres que tenga tu survivor?'
              name='max_strikes'
              placeholder=''
              preffix='# '
              value={survivorData.max_strikes}
              error={getLimitError()}
              helpText={getLimitError()}
              min={2}
              max={maxStrikes}
              maxWidth={90}
              ref={limitStrikessRef}
              onChange={setSurvivorData}
            />

            <Box center size='0.9em' mt={20}>
              <Typography>Máximo {maxStrikes} strikes.</Typography>
              <Typography>Los participantes únicamente contarán con estos strikes antes de ser eliminados.</Typography>
              <Typography>Al acumular este número de strikes, serán eliminados.</Typography>
            </Box>
          </Box>
        )}

        <SelectTournament />
      </Box>
    </WizardLayout>
  )
}
