import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

// Components
import TextField from '../Form/TextField'
import Button from '../Form/Button'
import Checkbox from '../Form/Checkbox'
import TextLink from '../Form/TextLink'
import HelpText from '../Form/HelpText'
import Logo from './Logo'
import Title from './Title'
import ModalBody from '../ModalPanel/ModalBody'
import Iconify from '../Iconify'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'
import useMergedState from '../../hooks/useMergedState'
import { publish } from '../../utils/events'

const initialState = {
  username: '',
  password: ''
}

export default function Login () {
  const usernameRef = useRef()
  const passwordRef = useRef()
  const [error, setError] = useState(false)
  const [state, setFormState] = useMergedState(initialState)
  const {
    openForgotPasswordModal,
    openSignupModal,
    submitting,
    sendCredentials,
    isFailedLogin,
    responseFacebook
  } = useAuthContext()

  useEffect(() => {
    usernameRef.current.focus()
  }, [])

  useEffect(() => {
    // Cretential storage manager
    if (window.PasswordCredential || window.FederatedCredential) {
      window.navigator.credentials.get({
        password: true,
        federated: {
          providers: [
            'https://accounts.google.com'
          ]
        },
        mediation: 'optional'
      }).then(function (c) {
        if (c !== null && c.type === 'password') {
          const cretentials = {
            username: c.id,
            password: c.password
          }
          setFormState(cretentials)
          submitLogin(cretentials.username, cretentials.password)
        }
      })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [])

  const submitLogin = (username, password) => {
    sendCredentials(username, password, (data) => {
      publish('updateUserInfo', data)
    })
  }

  const submit = () => {
    if (!state.username && !state.password) {
      setError(true)
      return window.setTimeout(() => setError(false), 3000)
    }
    submitLogin(state.username, state.password)
  }

  const handleChangeValues = (key, value) => {
    setFormState({ [key]: value })
  }

  return (
    <ModalBody>
      <Logo
        src='/images/Pickwin.png'
        alt='Pickwin logo'
      />
      <Title>Iniciar Sesión</Title>
      <FacebookLogin
        autoLoad
        appId='973176056074364'
        fields='id ,name ,first_name ,last_name ,gender ,installed ,verified ,email ,picture'
        scope='email, public_profile'
        disableMobileRedirect
        callback={responseFacebook}
        render={renderProps => (
          <Button
            bgColor='#2d5b9b'
            loading={renderProps.isProcessing || submitting === 'facebook-loading'}
            disabled={renderProps.isDisabled}
            onClick={renderProps.onClick}
          >
            Facebook login
          </Button>
        )}
      />

      <TextField
        name='username'
        ref={usernameRef}
        value={state.username}
        iconSrc='/images/Icono Mail.png'
        placeholder='Email o nombre de usuario'
        onEnter={() => passwordRef.current.focus()}
        onChange={handleChangeValues}
      />
      <Checkbox
        label='Recordar datos'
        style={{ marginLeft: '0.7em', marginBottom: '2em' }}
      />
      <TextField
        type='password'
        name='password'
        onEnter={submit}
        ref={passwordRef}
        value={state.password}
        placeholder='Contraseña'
        iconSrc='/images/Icono candado.png'
        onChange={handleChangeValues}
      />
      <TextLink onClick={openForgotPasswordModal}>¿Has olvidado tu contraseña?</TextLink>
      {(error || isFailedLogin) && (
        <HelpText color='red'>
          <Iconify
            icon='dashicons:warning'
            style={{
              fontSize: '1.4em',
              verticalAlign: 'bottom',
              marginRight: '0.2em'
            }}
          />
          <span>Por favor revisa tus datos, e inténtalo nuevamente.</span>
        </HelpText>
      )}
      <Button
        bgColor='#88ba44'
        onClick={submit}
        loading={submitting === 'email-loading'}
      >
        Iniciar sesión
      </Button>
      <HelpText>¿No tienes una cuenta? <strong onClick={openSignupModal}>Crear cuenta</strong></HelpText>
      <HelpText style={{ marginBottom: 0 }}>Si tienes algún problema con tu registro y requieres ayuda, contáctanos</HelpText>

      <WhatsApp href='https://wa.me/5215511866442?text=Tengo%20problemas%20para%20iniciar%20sesión' target='_blank'>
        <img
          src='/images/Icono whats.png'
          alt='Whatsapp logo button'
        />
      </WhatsApp>
    </ModalBody>
  )
}

const WhatsApp = styled.a`
  margin: 0 auto;
  cursor: pointer;

  img {
    max-width: 35px;
  }
`
