import React, { useEffect } from 'react'
import { Box, MultiOption } from '../../../Xmerald-UI'

import { useWizardPrivate } from '../wizardPrivate.store'
import PoolFixtures from './PoolFixtures'
import PoolFullTournament from './PoolFullTournament'
import WizardLayout from '../shared/Layout'

export default function QuinielaDuration () {
  const step = useWizardPrivate(state => state.step)
  const quinielaData = useWizardPrivate(state => state.quinielaData)
  const setQuinielaData = useWizardPrivate(state => state.setQuinielaData)

  // Emit data to store
  useEffect(() => {
    setQuinielaData({ tiebreaker_type: quinielaData.use_tibreakers ? 'new' : null })
  }, [quinielaData.use_tibreakers])

  useEffect(() => {
    if (quinielaData.weekly_prize) {
      setQuinielaData({
        weekly_prize_scheme: 'first',
        weekly_percentage: 50,
        end_percentage: 50
      })
    } else {
      setQuinielaData({
        weekly_prize_scheme: null,
        weekly_percentage: null,
        end_percentage: null
      })
    }
  }, [quinielaData.weekly_prize])

  if (step !== 'QUINIELA_DURATION') return null

  const disabledNext = (
    quinielaData.type === undefined ||
    quinielaData.autopicks === null ||
    quinielaData.use_tibreakers === null
  )

  return (
    <WizardLayout
      prevStep='QUINIELA_SPORT'
      nextStep='QUINIELA_PRICE'
      disabledNext={disabledNext}
    >
      <Box maxWidth={500}>
        <MultiOption
          onChange={setQuinielaData}
          keyName='type'
          title='Elige la duración de tu quiniela'
          // error='Debes seleccionar la duracion'
          value={quinielaData.type}
          options={[
            {
              name: 'Próxima jornada',
              value: 'week'
            },
            {
              name: 'Temporada completa',
              value: 'full'
            }
          ]}
        />

        <MultiOption
          onChange={setQuinielaData}
          keyName='autopicks'
          title='¿Quieres que tu quiniela tenga autopick?'
          // error='Debes de seleccionarla una opcion'
          subtitle='(En caso de no haber seleccionado un pick para uno o varios partidos que ya hayan comenzado se asignará automáticamente el equipo local como pick)'
          value={quinielaData.autopicks}
          options={[
            { name: 'SI', value: true },
            { name: 'NO', value: false }
          ]}
        />

        <MultiOption
          onChange={setQuinielaData}
          keyName='use_tibreakers'
          title='¿Quieres que tu quiniela tenga desempates?'
          // error='Debes de seleccionarla una opcion'
          value={quinielaData.use_tibreakers}
          options={[
            { name: 'SI', value: true },
            { name: 'NO', value: false }
          ]}
        />

        {quinielaData.type === 'full' && (
          <MultiOption
            onChange={setQuinielaData}
            keyName='weekly_prize'
            title='¿Quieres que tu quiniela tenga premios semanales?'
            value={quinielaData.weekly_prize}
            options={[
              { name: 'SI', value: true },
              { name: 'NO', value: false }
            ]}
          />
        )}

        {quinielaData.type === 'week' && (
          <PoolFixtures />
        )}

        {quinielaData.type === 'full' && (
          <PoolFullTournament />
        )}
      </Box>
    </WizardLayout>

  )
}
