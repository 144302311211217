import React, { useContext, useEffect } from 'react';
import { ClockContext } from '../../context/ClockContext';
import ClockType from './ClockType';
import ClockActions from './ClockActions';

const ClockHandler = ({
  endDate,
  type,
  updateStartedFixture,
  changeStatusPool,
  changeStatusButton,
  id,
  fixtureId,
  poolId,
}) => {
  let { state, dispatch } = useContext(ClockContext);
  useEffect(() => {
    if (type === 'initializer') {
      // console.log('Initialize Clock');
      ClockActions.initializeClock(dispatch, endDate, id).then((response) => {
        // console.log( response );
      });
    }
  }, [dispatch, endDate, id]);

  useEffect(() => {
    if (type !== 'initializer') {
      ClockActions.calculateCountdown(dispatch, endDate, id)
        .then((response) => {
          if (response.success) {
            // console.log( response );
            // console.log( state.timers[ id ] , state.timers[ id ] );
            if ((state.timers[id] && !response.data) || !response.data.isFinished) {
              ClockActions.updateTimer(dispatch, state.timers[id]);
            } else {
              if (state.timers[id].countdown.hours === 0 && state.timers[id].countdown.min === 0 && state.timers[id].countdown.sec === 0) {
                ClockActions.stopTimer(
                  poolId,
                  fixtureId,
                  state.timers[id],
                  type,
                  updateStartedFixture,
                  changeStatusButton,
                  changeStatusPool
                );
              }
            }
          } else {
            // console.error('WTF!', response, fixtureId , poolId );
            // ClockActions.updateTimer( dispatch, state.timers[id] );

            ClockActions.stopTimer(
              poolId,
              fixtureId,
              state.timers[id],
              type,
              updateStartedFixture,
              changeStatusButton,
              changeStatusPool
            );
          }
        })
        .catch((err) => {
          // console.error( err );
          ClockActions.stopTimer(
            poolId,
            fixtureId,
            state.timers[id],
            type,
            updateStartedFixture,
            changeStatusButton,
            changeStatusPool
          );
        });
    }
  }, [state.elapsed]);

  useEffect(() => {
    if (type === 'flip') {
      ClockActions.initFlipClock(
        dispatch,
        endDate,
        poolId,
        fixtureId,
        changeStatusPool,
        updateStartedFixture
      );
    }
  }, [dispatch, endDate, poolId, fixtureId, changeStatusPool, updateStartedFixture, type]);

  return (
    <div className="flex-container justify align ">
      <ClockType
        type={type}
        countDown={state.timers[id] ? state.timers[id].countdown : state.countdown}
      />
    </div>
  );
};

export default ClockHandler;
