import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap/lib';
import { ConfirmationOverlay } from '../../utils/utils';
import SurvivorContestPick from './SurvivorContestPick';
import SurvivorContestPickF1 from './SurvivorContestPickF1';
import ReactTooltip from 'react-tooltip';
import RegisterPanel from '../RegisterPanel';

import API from '../../utils/api';
import utils from '../../utils/utils';

import _ from 'underscore';
import moment from 'moment';

import guardar from '../../assets/images/guardarPicks-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';
import picks from '../../assets/images/quinielasIconBlanco-50.png';
import avatar from '../../assets/images/avatar-50.png';

const SurvivorContestUI = ({ survivor, players, currentContest, isPopUp, ...props }) => {

  const contests = survivor.contests;
  const scrollableRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPlayerList, setShowPlayerList] = useState(false);
  const [thePlayers, setPlayers] = useState(players);
  const [myEntry, setMyEntry] = useState({
    id: null,
    user_id: null,
    survivor_id: survivor.id,
    survivor_entry_id: null,
    number: null,
    elimination_contest_id: null,
    alive: true,
    survivor_picks: {},
  });

  //unnecesary pff
  const [showRegisterPanel, setShowRegisterPanel] = useState(false);
  const closeRegisterPanel = () => {
    setShowRegisterPanel(false);
  };
  const handleConfirmation = (response) => {
    console.log(response);
  };
  const handleRedirectToLobby = (entry) => {
    console.log(entry);
  };

  // const indexedPlayers = _.indexBy(players, 'id');
  const groupedPlayers = _.chain(thePlayers)
    .sortBy('team_name')
    .groupBy((player) => player.name.charAt(0))
    .value();

  useEffect(() => {
    if (props.entry) {
      const theEntry = {
        id: props.entry.id,
        survivor_entry_id: props.entry.id,
        user_id: props.entry.user_id,
        number: props.entry.number,
        elimination_contest_id: props.entry.survivor_standing.elimination_contest_id,
        alive: props.entry.survivor_standing.alive,
        survivor_picks: props.entry.survivor_picks,
      };

      setMyEntry((entry) => {
        return { ...entry, ...theEntry };
      });
    }
  }, [JSON.stringify(props.entry)]);

  useEffect(() => {
    _.each(players, (player) => {
      player.selected = false;
      player.contestId = null;
    });

    if (props.entry) {
      _.each(props.entry.survivor_picks, (pick, contestId) => {
        const playerIdx = players.findIndex((_player) => _player.id === pick.pick);
        if (playerIdx === -1) {
          return;
        }
        players[playerIdx].selected = true;
        players[playerIdx].contest_id = contestId;
      });
    }

    setPlayers(() => {
      return players;
    });
  }, [players, props.entry]);

  const gotoLetter = (letter) => {
    const indicator = document.querySelector(`#index-${letter}`);
    const position = indicator.offsetTop - scrollableRef.current.offsetTop;
    scrollableRef.current.scroll({ top: position, behavior: 'smooth' });
  };

  const savePicks = () => {
    setSubmitting(true);
    API.postSaveSurvivorPicks(myEntry).then((response) => {
      let data = response.data;
      console.log("SAVESURVRESP:", response);
      setSubmitting(false);

      if (data.hasOwnProperty('survivor_entry')) {
        setShowConfirmation(true);
        setTimeout(() => setShowConfirmation(false), 2000);
      }
    });
  };

  const togglePlayerList = () => {
    setShowPlayerList(!showPlayerList);
  };

  const setPick = (player) => {
    let contestId = currentContest.id;
    if (player.selected && player.contest_id !== contestId) {
      return;
    }
    setShowPlayerList(false);

    if (survivor.sport_id !== 7) {
      let picks = myEntry.survivor_picks;
      let hole = null;
      if (player.hole) {
        hole = player.hole;
      } else if (player.data && player.data.hasOwnProperty('hole')) {
        hole = player.data.hole;
      }
      if (picks[contestId]) {
        picks[contestId].pick = player.id;
        picks[contestId].p = player;
        picks[contestId].pr = {
          position: player.position,
          score: player.score,
          hole: hole,
          data: player.data,
        };
      } else {
        picks[contestId] = {
          survivor_id: survivor.id,
          survivor_entry_id: null,
          contest_id: currentContest.id,
          pick: player.id,
          p: player,
          pr: {
            position: player.position,
            score: player.score,
            hole: hole,
            data: player.data,
          },
        };
      }

      setMyEntry({
        ...myEntry,
        survivor_picks: picks,
      });
    } else { // F1
      let picks = myEntry.survivor_picks;

      if (picks[contestId]) {
        picks[contestId].pick = player.id;
        picks[contestId].p = player;
        picks[contestId].rr = {
          finish_pos: player.finish_pos,
          laps: player.laps,
          laps_num: player.laps_num,
          pit: player.pit,
          starting_pos: player.starting_pos,
          time: player.time,
        };
      } else {
        picks[contestId] = {
          survivor_id: survivor.id,
          survivor_entry_id: null,
          contest_id: currentContest.id,
          pick: player.id,
          p: player,
          rr: {
            finish_pos: player.finish_pos,
            laps: player.laps,
            laps_num: player.laps_num,
            pit: player.pit,
            starting_pos: player.starting_pos,
            time: player.time,
          },
        };
      }

      setMyEntry({
        ...myEntry,
        survivor_picks: picks,
      });
    }

    if (props.updateTemporalRegisterPicks) {
      props.updateTemporalRegisterPicks(myEntry);
      if (myEntry.id !== null) {
        savePicks();
      }
    }
  };

  const getPlayersList = (players) => {
    const list = players.map((player) => {
      return (
        <div
          key={player.id}
          className="flex-row row-center player"
          onClick={(e) => setPick(player)}
        >
          {survivor.sport_id !== 7 && (
            <React.Fragment>
              <div className="flex-col col-20">
                <img
                  className="player-avatar"
                  src={player.avatar === null ? avatar : player.avatar}
                />
              </div>
              <div className="flex-col uppercase">
                <i className={`flag ${player.logo}`}></i>
                {player.name}
              </div>
            </React.Fragment>
          ) || (
            <div className="flex-row" style={{fontSize: '85%'}}>
              <div className="flex-col col-15 flex-container justify align">
                <img className="player-avatar" src={player.avatar === null ? avatar : player.avatar} />
              </div>
              <div className="flex-col col-55 uppercase">
                <div>
                  <i className={`flag ${player.logo}`}></i>
                  <strong>{player.name}</strong>
                </div>
                <div>
                  <img className="" src={player.alt_avatar === null ? '' : player.alt_avatar} style={{width: '85px', height: 'auto'}} />
                </div>
              </div>
              <div className="flex-col col-30">
                <strong>({player.team_name})</strong>
              </div>
            </div>
          )}
        </div>
      );
    });
    return list;
  };

  const indexList = _.map(groupedPlayers, (players, letter) => {
    return (
      <a key={letter} onClick={() => gotoLetter(letter)}>
        {letter}
      </a>
    );
  });

  const playersList = _.map(groupedPlayers, (players, letter) => {
    const thePlayers = getPlayersList(players);

    return (
      <div key={letter}>
        {survivor.sport_id !== 7 && (
          <div id={`index-${letter}`} className="players-index-indicator">
            {letter}
          </div>
        )}
        <div className="players-list">{thePlayers}</div>
      </div>
    );
  });

  const contestsList = contests.map((contest) => {
    let deadline;
    switch (contest.status) {
      case 'upcoming':
        deadline = <small>{moment(contest.deadline).format('ddd D MMM HH:mm')}</small>;
        break;
      case 'live':
        deadline = <small style={{ fontWeight: 700, color: '#31a7f5' }}>EN VIVO</small>;
        break;
      case 'closed':
        deadline = <small style={{ fontWeight: 700, color: '#d3242b' }}>FIN</small>;
        break;
    }

    let pick = undefined;
    if (myEntry.survivor_picks !== undefined) {
      pick = myEntry.survivor_picks[contest.id];
    }

    return (
      <div className="contest-container" key={contest.id}>
        <div className="flex-row row-center uppercase">
          <div className="flex-col col-ellipsis">
            <img src={contest.logo} className="contest-logo" />
            {contest.name}
          </div>
          <div className="flex-col col-35 text-right">{deadline}</div>
        </div>

        {survivor.sport_id === 7 &&
          <SurvivorContestPickF1
            key={pick !== undefined ? pick.id : contest.id}
            pick={pick}
            players={thePlayers}
            contest={contest}
            currentContest={currentContest}
            eliminationContestId={myEntry.elimination_contest_id}
            alive={myEntry.alive}
            togglePlayerList={togglePlayerList}
          ></SurvivorContestPickF1>
          ||
          <SurvivorContestPick
            key={pick !== undefined ? pick.id : contest.id}
            pick={pick}
            players={thePlayers}
            contest={contest}
            currentContest={currentContest}
            eliminationContestId={myEntry.elimination_contest_id}
            alive={myEntry.alive}
            togglePlayerList={togglePlayerList}
          ></SurvivorContestPick>
        }

        {contest.id === currentContest.id && thePlayers.length ? (
          <div className={showPlayerList ? 'visible' : 'hidden'}>
            {survivor.sport_id !== 7 && (
              <div className="item-pager">{indexList}</div>
            )}
            <div ref={scrollableRef} className="overflow overflow-y overflow-360">
              <div className="players-container">{playersList}</div>
            </div>
          </div>
        ) : null}

        {contest.id === currentContest.id && thePlayers.length === 0 ? (
          <div className={showPlayerList ? 'visible' : 'hidden'}>
            <div className="flex-row survivor-contest-pick">
              <div className="flex-col">La lista de jugadores aún no está disponible</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <div className="contests-container panel-pickwin">
      <div className={`flex-row title-quiniela`}>
        <div className="flex-col col-no-padding title-wrapper">
          <div className="flex-row row-no-padding row-center">
            <img src={picks} className="pool-icons tablas" />
            <span>
              MIS PICKS
              <small>
                {`${myEntry && myEntry.hasOwnProperty('number') && myEntry.number !== null ? '[' + myEntry.number + ']' : ''}`}
              </small>
            </span>
            <span className="btn-help flex-container justify-end">
              <ReactTooltip id="help-survivor" place="top" type="light" effect="solid">
                ¿Cómo funcionan los Survivors?
              </ReactTooltip>
              <i
                data-tip
                data-for="help-survivor"
                className="ion-help-circled"
                onClick={() => {
                  setShowTutorial(true);
                }}
              ></i>
            </span>
          </div>
        </div>
      </div>

      <RegisterPanel
        handleTutorialStep={props.handleTutorialStep}
        closePanel={closeRegisterPanel}
        entryId={myEntry.id}
        handleConfirmation={handleConfirmation}
        handleRedirectToLobby={handleRedirectToLobby}
        logged={utils.isLogged()}
        payType={survivor.entry_currency}
        pool={survivor}
        pools={null}
        isPopUp={props.isPopUp}
        popUpNewUsersStatus={props.popUpNewUsersStatus}
        quinaID={survivor.id}
        refreshAfterGuardar={props.refreshAfterGuardarInLobby}
        registerPicks={myEntry}
        showPanel={showRegisterPanel}
        showTutorial={props.showTutorial}
        type={'survivor'}
        updateStateFunc={closeRegisterPanel}
        updateUserInfo={props.updateUserInfo}
        userData={props.userData}
        withRegister={props.withRegister}
      />

      <Modal
        show={submitting}
        className="responseDepositModal absoluteModal"
        dialogClassName="responseDepositDialog"
        backdropClassName="responseDepositBackdrop"
      >
        <div className="flex-container justify align column">
          <i className="ion-load-a loading"> </i>
          <div className="flex-row row-center">
            <div className="flex-col col-center text-center">
              <strong>Espera un momento porfavor...</strong>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        backdropClassName="tutorialSurvivorBackdrop"
        dialogClassName="infoReferralsPopUp"
        show={showTutorial}
      >
        {survivor.sport_id !== 7 && (
          <>
            <div className="panel-abonar">
              <div id="panelTitle" className="panel-abonar-titulo">
                <span>¿Cómo funciona el Survivor?</span>
                <button className="cerrar-panel" onClick={() => setShowTutorial(false)}>
                  <div className="btn-icon-close">
                    <i className="ion-ios-close-empty"></i>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-row row-no-padding pickwin-panel">
              <div className="flex-col panel-pickwin row-center">
                <img
                  src="https://storage.googleapis.com/pw-resources/banners/app/survivor-info-golf.jpg"
                  style={{ maxWidth: '473px', width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </>
        ) || (
          <>
            <div className="panel-abonar">
              <div id="panelTitle" className="panel-abonar-titulo">
                <span>¿Cómo funciona el Survivor F1?</span>
                <button className="cerrar-panel" onClick={() => setShowTutorial(false)}>
                  <div className="btn-icon-close">
                    <i className="ion-ios-close-empty"></i>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex-row row-no-padding pickwin-panel">
              <div className="flex-col panel-pickwin row-center">
                <img
                  src="https://storage.googleapis.com/pw-resources/banners/app/survivor-info-f1.jpg"
                  style={{ maxWidth: '473px', width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </>
        )}
      </Modal>

      <ConfirmationOverlay
        allPicks={true}
        currentSavedPicks={true}
        label="!Felicidades, tu pick se guardo con éxito!"
        showOverlay={showConfirmation}
        type="survivor"
      />

      <div style={{ overflowY: 'scroll', maxHeight: props.isPopUp ? '450px' : '740px' }}>
        {contestsList}
      </div>

      <div className="flex-row row-center">
        <div className="flex-col">
          {props.entry && props.entry.survivor_standing.alive === true ? (
            <Button
              className="guardar-picks callToAction"
              disabled={submitting}
              onClick={() => savePicks()}
            >
              <img src={guardar} className="bolsa-img" />
              <span>GUARDA TU PICK</span>
            </Button>
          ) : null}

          {!props.entry && props.enoughBalance ? (
            <Button
              className="guardar-picks callToAction"
              disabled={submitting}
              onClick={() => {
                if (props.showPanelRegister) {
                  props.showPanelRegister();
                } else {
                  setShowRegisterPanel(true);
                }
                // console.log( 'showPanelRegister' );
                // console.log( props.showPanelRegister )
              }}
            >
              <img src={guardar} className="bolsa-img" />
              <span data-id="guardar">GUARDA TU PICK Y REGÍSTRATE</span>
            </Button>
          ) : null}

          {!props.entry && !props.enoughBalance ? (
            <Button
              data-id="guardar"
              disabled={submitting || !utils.isLogged()}
              onClick={(e) => {
                props.showAbonarPanel();
              }}
              className="guardar-picks callToAction"
              bsStyle="default"
            >
              <img src={abonar} className="bolsa-img" />
              <span data-id="guardar">{'ABONAR SALDO'}</span>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

SurvivorContestUI.propTypes = {
  survivor: PropTypes.object,
  currentContest: PropTypes.object,
  players: PropTypes.array,
  updateTemporalRegisterPicks: PropTypes.func,
};

export default SurvivorContestUI;
