import React from 'react';
import moment from 'moment';
import $ from 'jquery';
const d3Timer = require('d3-timer');
// moment.locale('es');

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type === undefined || this.props.type === null ? null : this.props.type,
      closingDate: this.props.closingDate,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      dummyTime: this.props.faltante,
      almost: false,
      interval: 0,
    };

    this.interval = null;
    this.initFlipClick = this.initFlipClick.bind(this);
    this.calculateCountdown = this.calculateCountdown.bind(this);

    this.runCountdownInterval = () => {
      this.interval = d3Timer.interval(() => {
        console.log('Countdown interval tick');
        var timeLeft = this.calculateCountdown(this.props.closingDate);
        if (timeLeft) {
          this.setState({ timeLeft });
        } else {
          this.interval.stop();
        }
      }, 1000);
    };
  }

  getInitialState() {
    let date = this.calculateCountdown(this.props.closingDate);
    return { date };
  }

  componentDidMount() {
    let timeLeft = this.calculateCountdown(this.props.closingDate);
    if (timeLeft <= 0) {
      return;
    }

    // console.log( 'd3Timer' );
    // console.log( d3Timer );
    this.setState({
      timeLeft,
    });

    this.interval = d3Timer.interval(() => {
      timeLeft = this.calculateCountdown(this.props.closingDate);
      // console.log('Countdown interval tick');
      // console.log( timeLeft );

      if (timeLeft) {
        this.setState({ timeLeft });
      } else {
        this.interval.stop();
      }
    }, 1000);

    if (this.props.type === 'flip') {
      this.initFlipClick();
    } else if (this.props.type === 'row' && this.props.updateStartedFixture !== undefined) {
      console.log('init Timeout in row with changestatusPool');
      let leftTime;
      let closing = moment(this.props.closingDate);
      let now = moment();
      leftTime = closing.diff(now, 'miliseconds');
      console.log(leftTime);
      if (leftTime > 0) {
        if (leftTime > 0x7fffffff) {
          leftTime = 0x7fffffff;
        }
        setTimeout(() => {
          this.props.updateStartedFixture(this.props.fixtureID);
          if (this.props.changeStatusPool) {
            console.log(
              'Change status of fixture',
              this.props.poolId,
              this.props.fixtureID,
              this.props
            );
            this.props.changeStatusPool(this.props.poolId, this.props.fixtureID);
          }
          console.log('Cerro!');
        }, leftTime);
      }
    } else if (this.props.type === 'row' && this.props.changeStatusPool !== undefined) {
      console.log(this.props.fixtureID, 'init Timeout in row with changestatusPool');
      let leftTime;
      let closing = moment(this.props.closingDate);
      let now = moment();
      leftTime = closing.diff(now, 'miliseconds');
      // console.log(leftTime);
      if (leftTime > 0) {
        if (leftTime > 0x7fffffff) {
          leftTime = 0x7fffffff;
        }
        this.interval = d3Timer.timer(() => {
          console.log(this.props.poolId, this.props.fixtureID);
          this.props.changeStatusPool(this.props.poolId, this.props.fixtureID, 0);
          console.log('Cerro!');
        }, leftTime);
      }
    } else if (this.props.type === 'row-stopwatch' && this.props.changeStatusButton) {
      let leftTime;
      let closing = moment(this.props.closingDate);
      let now = moment();
      leftTime = closing.diff(now, 'miliseconds');
      // console.log(leftTime);
      if (leftTime > 0) {
        if (leftTime > 0x7fffffff) {
          leftTime = 0x7fffffff;
        }
        this.interval = d3Timer.timer(() => {
          this.props.changeStatusButton();
          console.log('Habilitado!');
        }, leftTime);
      }
    }
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate) {
    let end = moment(endDate);
    let now = moment();
    let diff = (end - now) / 1000;
    // console.log(diff, endDate);
    // clear countdown when date is reached
    if (diff <= 0) return false;
    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;
    return timeLeft;
  }

  stop() {
    if (this.interval) {
      this.interval.stop();
    } else {
      console.log('dont exist interval');
    }
  }

  initFlipClick() {
    let pastDate = this.state.closingDate;
    pastDate = moment(pastDate);
    let diff = pastDate / 1000 - moment() / 1000;
    // console.log(diff);
    // console.log('FlipClock');
    // console.log(diff);
    // let flipC = $('.flipClock').FlipClock(diff, {
    //   clockFace: 'DailyCounter',
    //   countdown: true,
    //   language: 'spanish',
    //   callbacks: {
    //     stop: () => {
    //       console.log('Se Cerro');
    //       if (this.props.changeStatusPool !== undefined) {
    //         if (this.props.dispatch) {
    //           this.props.changeStatusPool(this.props.dispatch);
    //         } else {
    //           this.props.changeStatusPool(this.props.poolId, this.props.fixtureID);
    //         }
    //       }

    //       if (this.props.updateClosedQuiniela !== undefined) {
    //         this.props.updateClosedQuiniela();
    //       }
    //     },
    //   },
    //   // ... your options here
    // });
    // console.log(flipC);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }

  render() {
    let conditionalClock;
    const countDown = !this.state.timeLeft ? this.state : this.state.timeLeft;
    // console.log( countDown );
    if (countDown.hours <= 0 && countDown.min <= 0 && countDown.sec <= 0) {
      // console.log('this Quiniela justStarted');
      // console.log(this.addLeadingZeros(countDown.hours), this.addLeadingZeros(countDown.min), this.addLeadingZeros(countDown.sec));
      // this.props.quinielaLive();
    }
    switch (this.state.type) {
      case 'row':
        conditionalClock = (
          <div className="closing-regular-countdown">
            <div className="countdown">
              <div className="countdown-container">
                <span
                  style={this.state.hours <= 24 ? { color: '#ff0000' } : { color: '#E00034' }}
                >{`${this.addLeadingZeros(countDown.hours)}:`}</span>
                <span
                  style={this.state.hours <= 24 ? { color: '#ff0000' } : { color: '#E00034' }}
                >{`${this.addLeadingZeros(countDown.min)}:`}</span>
                <span style={this.state.hours <= 24 ? { color: '#ff0000' } : { color: '#E00034' }}>
                  {this.addLeadingZeros(parseInt(countDown.sec)) === NaN
                    ? 0
                    : `${this.addLeadingZeros(parseInt(countDown.sec))}`}
                </span>
                {/* <i className={'ion-android-alarm-clock hidden-xs '} style={ { 'marginLeft':'5px' } } /> */}
              </div>
            </div>
          </div>
        );
        break;
      case 'row-stopwatch':
        conditionalClock = (
          <div className="flex-col col-no-padding col-15 closing-regular-countdown">
            <div className="countdown">
              <div className="countdown-container">
                <span>{`${this.addLeadingZeros(countDown.min)}:`}</span>
                <span>
                  {this.addLeadingZeros(parseInt(countDown.sec)) === NaN
                    ? 0
                    : `${this.addLeadingZeros(parseInt(countDown.sec))}`}
                </span>
              </div>
            </div>
          </div>
        );
        break;
      case 'title':
        conditionalClock = (
          <div className="closing-countdown">
            <div className="row countdown-title">
              <span>Empieza:</span>
            </div>
            <div className="row countdown">
              <div className="col-sm-3 time-cell">
                <div className="row title-cell">
                  <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
                </div>
                <div className="row date-cell">
                  <span>{this.addLeadingZeros(countDown.days)}</span>
                </div>
              </div>
              <div className="col-sm-3 time-cell">
                <div className="row title-cell">
                  <span>Hours</span>
                </div>
                <div className="row date-cell">
                  <span>{this.addLeadingZeros(countDown.hours)}</span>
                </div>
              </div>
              <div className="col-sm-3 time-cell">
                <div className="row title-cell">
                  <span>Min</span>
                </div>
                <div className="row date-cell">
                  <strong>{this.addLeadingZeros(countDown.min)}</strong>
                </div>
              </div>
              <div className="col-sm-3 time-cell">
                <div className="row title-cell">
                  <span>Sec</span>
                </div>
                <div className="row date-cell ">
                  <strong>{this.addLeadingZeros(countDown.sec)}</strong>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 'pool':
        conditionalClock = (
          <div className="closing-pool-countdown">
            <div className="countdown">
              <div className="countdown-container">
                <strong
                  style={this.state.hours <= 24 ? { color: '#ff0000' } : { color: '#fff000' }}
                >{`${this.addLeadingZeros(countDown.hours)}:`}</strong>
                <strong
                  style={this.state.hours <= 24 ? { color: '#ff0000' } : { color: '#fff000' }}
                >{`${this.addLeadingZeros(countDown.min)}:`}</strong>
                <strong
                  style={this.state.hours <= 24 ? { color: '#ff0000' } : { color: '#fff000' }}
                >
                  {this.addLeadingZeros(countDown.sec) === NaN
                    ? 0
                    : `${this.addLeadingZeros(countDown.sec)} `}
                </strong>
              </div>
            </div>
          </div>
        );
        break;
      case 'flip':
        conditionalClock = <div className="flipClock" />;
        break;
      default:
        break;
    }
    // console.log(this.addLeadingZeros(countDown.hours), this.addLeadingZeros(countDown.min), this.addLeadingZeros(countDown.sec));
    return conditionalClock;
  }
}

Clock.defaultProps = {
  date: new Date(),
};

export default Clock;
