import React, { useEffect, useState } from 'react'

// Components
import { Typography, Stack, Box, MultiOption, InputRange, InputNumber } from '../../../Xmerald-UI'
import CustomAwards from '../shared/CustomAwards'

// Store
import { useWizardPrivate } from '../wizardPrivate.store'

// Utils
import API from '../../../utils/api'
import WizardLayout from '../shared/Layout'

export default function QuinielaAwards () {
  const step = useWizardPrivate(state => state.step)
  const setStep = useWizardPrivate(state => state.setStep)
  const quinielaData = useWizardPrivate(state => state.quinielaData)
  const setQuinielaData = useWizardPrivate(state => state.setQuinielaData)
  const setCreatedPool = useWizardPrivate(state => state.setCreatedPool)

  const [creating, setCreating] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  // Emit store the end percentage
  useEffect(() => {
    setQuinielaData({
      end_percentage: 100 - quinielaData.weekly_percentage
    })
  }, [quinielaData.weekly_percentage])

  // Generate new fields if number of prizes list changes
  useEffect(() => {
    if (quinielaData.prizes_list) {
      setQuinielaData({
        pool_prizes: Array(Number(quinielaData.prizes_list))
          .fill(0)
          .map((_, index) => ({
            position: index + 1,
            type: 'percentage',
            percentage: 0
          }))
      })
    }
  }, [quinielaData.prizes_list])

  const submit = async () => {
    setCreating(true)
    try {
      const response = await API.postCreatePrivate(quinielaData)

      if (response?.data?.pool) {
        setCreatedPool(response?.data?.pool)
        setStep('QUINIELA_SUCCESS')
      }
    } catch (error) {
      setErrorMessage(error)
    }
    setCreating(false)
  }

  if (step !== 'QUINIELA_AWARDS') return null

  let disabledNext = false

  const isCustomPrice = quinielaData.prize_scheme === 'custom'

  if (isCustomPrice) {
    // Check every custom price
    // Calcule total
    const total = quinielaData.pool_prizes
      .reduce((prev, next) => ({ percentage: (prev.percentage || 0) + next.percentage }), { percentage: 0 })

    // If user not filled 100%
    if (total.percentage < 100) {
      disabledNext = true
    } else {
      disabledNext = false
    }

    // If some of pool_prices is 0
    if (quinielaData.pool_prizes.find(item => item.percentage === 0)) {
      disabledNext = true
    }
  }

  return (
    <WizardLayout
      prevStep='QUINIELA_PRICE'
      onClickNext={submit}
      disabledNext={disabledNext}
      loadingNext={creating}
    >
      <Box maxWidth={600} width='100%'>
        {(quinielaData.weekly_prize && quinielaData.type === 'full') && (
          <Box>
            <Typography center size='1.2em'>Elige el porcentaje asignado a los premios semanales.</Typography>
            <Typography center size='2em' mb={10}>{quinielaData.weekly_percentage}%</Typography>
            <InputRange
              min={0}
              max={100}
              name='weekly_percentage'
              value={quinielaData.weekly_percentage}
              onChange={setQuinielaData}
            />

            <MultiOption
              onChange={setQuinielaData}
              keyName='weekly_prize_scheme'
              title='¿Cómo quieres que se repartan los premios semanales?'
              value={quinielaData.weekly_prize_scheme}
              options={[
                { name: '1er Lugar', value: 'first' },
                { name: 'Top 3', value: 'top' }
              ]}
            />

            {quinielaData.weekly_prize_scheme === 'first' && (
              <Typography center my={10}>¡ Todo al 1° !</Typography>
            )}

            {quinielaData.weekly_prize_scheme === 'top' && (
              <Stack direction='row' maxWidth={350} mx='auto'>
                <Typography center my={10}>1° - 50%</Typography>
                <Typography center my={10}>2° - 30%</Typography>
                <Typography center my={10}>3° - 20%</Typography>
              </Stack>
            )}
          </Box>
        )}

        <MultiOption
          onChange={setQuinielaData}
          keyName='prize_scheme'
          title='¿Cómo quieres que se repartan los premios cuando termine tu quiniela?'
          value={quinielaData.prize_scheme}
          options={[
            { name: 'Automáticamente', value: 'dynamic' },
            { name: 'Personalizado', value: 'custom' }
          ]}
        />

        {errorMessage && (
          <Typography center color='orangered' size='1em' my={2}>Ha ocurrido un error favor de intentenarlo nuevamente</Typography>
        )}

        {quinielaData.prize_scheme === 'custom' && (
          <>
            <InputNumber
              value={Number(quinielaData.prizes_list)}
              name='num'
              min={1}
              maxWidth={80}
              label='¿Cuántos premios quieres entregar?'
              onChange={(data) => {
                const prizes_list = String(data.num)
                setQuinielaData({ prizes_list })
              }}
            />
            <CustomAwards
              prizesList={quinielaData.pool_prizes}
              onChange={(pool_prizes) => setQuinielaData({ pool_prizes })}
            />
          </>
        )}
      </Box>
    </WizardLayout>
  )
}
