import React from 'react'
import firstBy from 'thenby'
import numeral from 'numeral'
import InfiniteScroll from 'react-infinite-scroller'
import { FormControl, Button } from 'react-bootstrap'
import * as Sentry from '@sentry/browser'
import $ from 'jquery'
import _ from 'underscore'

// Components
import Loading from '../Loading'
import { ISRTooltip } from '../TablaPosiciones/TablaPosiciones'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import Utilities from '../../utils/quinielasUtils'
import SubscriptionRxs from '../../utils/subscriptions'

// Assets
import avatar from '../../assets/images/avatar-50.png'
import posiciones from '../../assets/images/posicionesIconBlanco-50.png'
import ticketIcon from '../../assets/images/silver_ticket_pick_empty.png'

import '../TablaPosiciones/TablaPosiciones.css'


const ButtonFollowFriend = ({ followSuccess, followError, followUser, user }) => {
  let conditionalComponent = null;
  // console.info(followSuccess);
  // console.error(followError);
  if (user.user_id === utils.getUserInfo().id) {
    return (
      <div className="posiciones-category friend quina flex-container align justify">
        <i className="ion-" />
      </div>
    );
  } else {
    if (!followSuccess && !followError) {
      conditionalComponent = (
        <div className="posiciones-category friend quina flex-container align justify">
          <Button
            className={` flex-row row-center row-no-padding btn-addFriend`}
            onClick={() => {
              followUser(user.user_id);
            }}
          >
            <i className="ion-plus-round flex-col col-center"></i>
          </Button>
        </div>
      );
    } else if (followSuccess) {
      conditionalComponent = (
        <div className="flex-container align justify friend quina position-category successFollow">
          <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
        </div>
      );
    } else if (followError) {
      conditionalComponent = (
        <div className="flex-container align justify friend quina position-category errorFollow">
          <i className="ion-close-round " style={{ color: '#e00034' }}></i>
        </div>
      );
    }
    return conditionalComponent;
  }
};

export const Aganar = ({ poolInfo, userData, getPrize, currentWeek }) => {
  let moneyFormat = null;
  let currencyImage = null;
  let acronymPrize = null;
  let availablePrize = null;

  switch (poolInfo.prize_currency) {
    case 'ticket':
      moneyFormat = '0';
      acronymPrize = '';
      currencyImage = ticketIcon;
      if (poolInfo.status === 'upcoming') {
        currencyImage = null;
        availablePrize = '-';
      } else {
        availablePrize = !userData.prize_amount ? '-' : userData.prize_amount;
      }
      // currencyImage = ticketsIcon;
      break;
    case 'real':
      moneyFormat = '$0,000.00';
      acronymPrize = 'MX';
      availablePrize = numeral(userData.weekly_prize.amount / 100).format(moneyFormat);
      // availablePrize = !userData.weekly_prize.amount
      //   ? numeral(getPrize(userData.rank)).format(moneyFormat)
      //   : numeral(userData.weekly_prize.amount / 100).format(moneyFormat);
      // // currencyImage = billete;
      break;
    case 'pickcoin':
      acronymPrize = 'PC';
      moneyFormat = '0,000';
      availablePrize = numeral(userData.weekly_prize.amount / 100).format(moneyFormat);
      // availablePrize = !userData.weekly_prize.amount
      //   ? numeral(getPrize(userData.rank)).format(moneyFormat)
      //   : numeral(userData.weekly_prize.amount / 100).format(moneyFormat);
      // currencyImage = pickcoin1;
      break;
    default:
  }

  if (currentWeek.completed && userData.weekly_prize.base_amount) {
    // console.log(userData);
    return (
      <div className="posiciones-category friend quina flex-container align justify" style={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <ISRTooltip
          tax={userData.weekly_prize.tax}
          availablePrize={numeral(userData.weekly_prize.base_amount / 100).format(moneyFormat)}
          moneyFormat={moneyFormat}
        />
        {poolInfo.prize_currency === 'ticket' &&
          userData.user_prize !== null &&
          userData.user_prize.description !== null ? (
            <img src={currencyImage} className="pool-icons" />
          ) : null}
        <span className="cant"> {availablePrize} </span>
        <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
      </div>
    );
  }

  return (
    <div className="posiciones-category a-ganar flex-col col-no-padding flex-container end" style={{ paddingRight: '4px' }}>
      {poolInfo.prize_currency === 'ticket' &&
        userData.user_prize !== null &&
        userData.user_prize.description !== null ? (
          <img src={currencyImage} className="pool-icons" />
        ) : null}
      <span className="cant"> {availablePrize} </span>
      <small style={{ display: 'flex', alignItems: 'center' }}>{acronymPrize}</small>
    </div>
  );
};

const SearchableInput = ({
  placeholder,
  value,
  fetchStandings,
  changeSearchNick,
  resetValue,
  submitting,
}) => {
  //const emptySearch = value === '' ? true : false;
  return (
    <div className="flex-row row-no-padding row-center" id="search-field-nick">
      <div id="searchableNickInput" className="flex-col col-75 col-ofset-5 col-no-padding ">
        <FormControl
          onChange={(e) => {
            changeSearchNick(e);
            fetchStandings(e);
          }}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
      <div className="flex-col col-15 btn-search-field">
        <Button
          onClick={(e) => {
            console.log('Clicked');
            resetValue();
          }}
          className="btn-default"
        >
          <i className={`${submitting ? 'ion-load-a loading' : 'ion-close-circled'}`}> </i>
        </Button>
      </div>
    </div>
  );
};

class TablaSemanalPosiciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSort: {
        posiciones: false,
        jugadores: false,
        aciertos: false,
        premios: false,
      },
      currentWeek: null,
      poolWeeks: null,
      poolInfo: null,
      posActualPage: 1,
      weeklyPositions: null,
      mounted: false,
      searchNick: '',
      usersItems: null,
      sortChanged: false,
      remoteRowCount: 0,
      submitting: false,
      submittingSearch: false,
    };
    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      order: 'ASC',
    };
    this.rowRenderer = this.rowRenderer.bind(this);
    this.loadMoreRows = this.loadMoreRows.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);

    this.followUser = (id) => {
      console.log(' %c Follow User +  ', 'color:rgb(223, 75, 38) ; font-size:2em ;');
      console.log(id);
      API.followUser(id)
        .then((response) => {
          console.log(response);
          if (response.data.message === 'ok') {
            if (!this.state.successFollow) {
              this.setState(
                {
                  successFollow: [{ id, status: true }],
                },
                () => {
                  this.list.forceUpdateGrid();
                }
              );
            } else {
              let temSuccessFollow = [...this.state.successFollow, { id, status: true }];
              this.setState(
                {
                  successFollow: temSuccessFollow,
                },
                () => {
                  this.list.forceUpdateGrid();
                }
              );
            }
          }
          // console.log(response);
        })
        .catch((e) => {
          if (!this.state.errorFollow) {
            this.setState(
              {
                errorFollow: [{ id, status: true }],
              },
              () => {
                this.list.forceUpdateGrid();
              }
            );
          } else {
            let temErrorFollow = [...this.state.errorFollow, { id, status: true }];
            this.setState(
              {
                errorFollow: temErrorFollow,
              },
              () => {
                this.list.forceUpdateGrid();
              }
            );
          }

          console.error(e);
          Sentry.captureException(e);
        });
    };

    this.resetValue = () => {
      this.props.resetNickValue();
      $('#searchableNickInput .form-control').val('');
    };

    this.changeWeek = (value) => {
      this.setState({
        submitting: true,
      });
      API.fetchWeeklyStandings(this.props.poolInfo.pool.id, value).then((response) => {
        this.setState({
          currentWeek: value,
          posicionesSemanales: response.data,
          submitting: false,
        });
      });
    };

    /**
     * This is the callback of the sort method
     * @param {} data
     */
    this.callbackSort = (data) => {
      let newStandings = data;
      let newUpdatedPositions = null;
      this.setState({
        submittingSearch: true,
      });
      console.log('callbackSort', newStandings);

      if (this.props.poolInfo.pool.status === 'live') {
        newUpdatedPositions = this.updateWeeklyStandingsAganar(
          newStandings.items,
          this.state.poolWeeks.ties_distribution
        );
        newStandings.items = newUpdatedPositions;
      }
      // console.log('returned standings ', newUpdatedPositions);

      // newStandings.items = newUpdatedPositions;

      // console.log('newstandings', newStandings);

      this.setState(
        {
          emptyList: newStandings.items.length === 0 ? true : false,
          standings: newStandings,
          submittingSearch: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
          submitting: false,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    this.callbackSearchUser = (data) => {
      console.log('CB SEARCH USER', data);
      let newStandings = data;
      let newUpdatedPositions = null;
      if (this.props.poolInfo.pool.status === 'live' && this.queryParams.order !== 'nick') {
        console.log('callbackSearchUser');
        newUpdatedPositions = this.updateWeeklyStandingsAganar(
          newStandings.items,
          this.state.poolWeeks.ties_distribution
        );
        newStandings.items = newUpdatedPositions;
      }

      this.setState(
        {
          emptyList: newStandings.items.length === 0 ? true : false,
          posicionesSemanales: newStandings,
          submittingSearch: false,
          submitting: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    /**
     * This function fetch the standings with the modified query params
     * it hast o rewrite the standings data, and store the standigs default
     */
    this.fetchSearchableStandings = (ev, obj) => {
      if (!this.props.poolInfo.pool === undefined) {
        return;
      }
      this.setState({ submittingSearch: true, submitting: true });
      // if(this.state.submittingSearch === true ) { return;}
      // console.log('%c Search users', 'color:rgb(110, 223, 85);font-size:1.5em;');
      // console.log(ev.target.value);
      // console.log(ev.target);

      this.queryParams.nick = this.state.searchNick;
      this.queryParams.page = 1;

      API.fetchWeeklyStandings(
        this.props.poolInfo.pool.id,
        this.props.currentWeek,
        this.queryParams
      )
        .then((response) => {
          // console.log(response);
          this.callbackSearchUser(response.data);
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error getting weekly standings',
          });
          console.log(error);
        });
    };

    this.fetchSearchableStandings = _.debounce(this.fetchSearchableStandings, 500);

    this.changeSearchNick = (ev) => {
      console.log('changeNick');
      this.setState({ searchNick: ev.target.value });
    };

    /**
     * This function reset the nick value and
     * fetch again the data with the default queryParams
     */
    this.resetNickValue = () => {
      this.queryParams.nick = '';
      this.queryParams.page = 1;

      $('#searchableNickInput .form-control').val('');

      this.setState(
        {
          searchNick: '',
          submittingSearch: true,
        },
        () => {
          API.fetchWeeklyStandings(
            this.props.poolInfo.pool.id,
            this.props.currentWeek,
            this.queryParams
          )
            .then((response) => {
              this.callbackSearchUser(response.data);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error resetting weekly standings',
              });
            });
        }
      );
    };

    /**
     * This function handles a call to the API
     * to get the new page of the positions
     * @param eventKey
     */
    this.siguientePagPositions = (eventKey) => {
      console.log('Next Positions...', eventKey);
      console.log(this.state.submitting, !this.state.mounted);
      if (this.state.submitting || !this.state.mounted) {
        return;
      }
      let loaderElement = $('#loading');
      // let tempActiveSort = null;
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.state.posicionesSemanales.items;
      // console.log('siguiente');
      //let val = eventKey;

      this.queryParams.page = this.queryParams.page + 1;

      if (this.queryParams.page > this.state.posicionesSemanales.pages) {
        return;
      }

      API.fetchWeeklyStandings(
        this.props.poolInfo.pool.id,
        this.props.currentWeek,
        this.queryParams
      )
        .then((response) => {
          this.setState(
            (prevState) => {
              // console.log( tempActiveSort );
              let newPosiciones = response.data;

              let newItems = response.data.items !== null ? response.data.items : null;

              newItems.forEach((item) => {
                temporalPositions.push(item);
              });

              newPosiciones.items = temporalPositions;
              return {
                posicionesSemanales: newPosiciones,
                posActualPage: this.queryParams + 1,
              };
            },
            () => {
              // if(this.props.sortPositionList !== undefined){
              //   setTimeout( () => {
              //     console.log(tempActiveSort);
              //     this.props.sortPositionList( tempActiveSort, true );
              //   }, 150);
              // }
              if (this.updateScrollPositions !== undefined) {
                this.updateScrollPostions(this.state.posiciones);
              }
              if (loaderElement !== undefined && loaderElement.length > 0) {
                loaderElement[0].style.display = 'none';
                //let avatars = $('.score-avatars').height('auto');
              }
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error getting next page weekly standings ',
          });
          this.resetNickValue();
        });
    };

    /**
     * This Function changes the week of the weeklyPrizes
     * @param {the next weekId} value
     */
    this.changeWeek = (value) => {
      this.setState({
        submitting: true,
      });
      this.queryParams.page = 1;
      API.fetchWeeklyStandings(
        this.props.poolInfo.pool.id,
        this.props.currentWeek,
        this.queryParams
      )
        .then((response) => {
          let tempPos = response.data;
          if (
            this.props.poolInfo.pool.status === 'live' &&
            this.state.poolWeeks.hasOwnProperty('current') &&
            this.props.currentWeek === this.state.poolWeeks.current.id
          ) {
            console.log('changeWeek');
            tempPos.items = this.updateWeeklyStandingsAganar(
              tempPos.items,
              this.state.poolWeeks.ties_distribution
            );
            // console.log(tempPos);
          }

          this.setState({
            currentWeek: this.props.currentWeek,
            posicionesSemanales: tempPos,
            posActualPage: 1,
            submitting: false,
          });
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error getting other week in  weekly standings ',
          });
        });

      this.updateMyWeeklyStandings()
    };

    this.updateWeeklyStandingsAganar = (posiciones, tiesDistribution) => {
      // console.log('Update Weekly Standings');
      // console.log(this.props.premios.weekly);
      // console.log(tiesDistribution);
      // console.log(posiciones);
      if (!tiesDistribution || !posiciones) {
        return posiciones;
      }

      let maxPrizedPosition = _.max(this.props.premios.weekly, (prize) => prize.position);
      _.map(tiesDistribution, (ties) =>  {
        ties.percentage = 0;
        ties.amount = 0;
      });

      _.each( tiesDistribution, (ties) => {
        if ( ties.rank > maxPrizedPosition.position ) {
          return;
        } else {
          let rank = ties.rank;
          let numTies = ties.ties;
          let acc = 0;
          // iterate through all of the tied positions
          for ( let i = rank ; i < rank + numTies; i++ ) {
            let prize = this.props.premios.weekly[i-1];
            if ( !prize ) { continue; }
            acc += prize.percentage;
          }
          ties.percentage = acc;
          ties.amount     = ( this.props.poolInfo.pool.available_per_week *  (acc/10000) ) / numTies ;
        }
      });

      let temporalPosiciones = posiciones;
      temporalPosiciones.map((standing) => {
        standing.weekly_prize.amount     = 0;
        standing.weekly_prize.percentage = 0;
      });

      _.each(tiesDistribution, (tie) => {
        if ( tie.amount == 0) { return; }
        _.chain(temporalPosiciones)
        .where({rank: tie.rank })
        .map((_standing) => {
          _standing.weekly_prize.amount = tie.amount;
        });
      });

      this.updateMyWeeklyStandings()

      return temporalPosiciones;
    };

    this.getCurrentWeek = () => {
      return this.props.poolWeeks.current.id === this.props.currentWeek
        ?  this.props.poolWeeks.current
        : this.props.poolWeeks.weeks.find((week) => week.id === this.props.currentWeek)
    }

    this.updateMyWeeklyStandings = async () => {

      const isValid = (
        this.props?.poolInfo?.pool?.id &&
        this.props.currentWeek &&
        this.props?.userData?.id
      )
    
      if (isValid) {
        const { data } = await API.getMyWeeklyStandings(
          this.props.poolInfo.pool.id,
          this.props.currentWeek,
          this.props.userData.id
        )

        const currentWeek = this.getCurrentWeek()

        if (data && data?.items && data?.items?.length) {
          this.setState({
            myWeekStandings:  {
              ...data?.items[0],
              // Passing current week to consum it easily in JSX
              currentWeek,
              // Get avatar image src from user or using default image instead
              avatarImg: this.props?.userData?.avatar !== null
                ? `${this.props?.userData?.avatar_dir}${this.props?.userData?.avatar}`
                : avatar
            }
          })
        } else {
          this.setState({
            myWeekStandings:  null,
            currentWeek
          })
        }
      }
    }

    /**
     * This function finds the prize that belongs to the Weekly Prize
     * and returns the according prize
     * @param {The rank of the prize} rank
     */
    this.getPrize = (rank) => {
      let typeWeekPrize = this.props.poolInfo.pool.weekly_prize_scheme

      let temporalPosiciones = this.state.posicionesSemanales.items;

      let maxPrizedPosition = typeWeekPrize === 'first' ? 1 : 3;

      let weeklyPrizeNum =
        ((this.props.poolInfo.pool.available / 100) *
          (this.props.poolInfo.pool.weekly_percentage / 10000)) /
        this.state.poolWeeks.weeks.length;

      //let weeklyPrize = numeral(weeklyPrizeNum).format('$0,000.00');
      let rankTies = _.groupBy(temporalPosiciones, 'rank');

      /**
       * Reassign weekly prizes with ties
       */
      _.each(rankTies, (temporalPosiciones, rank) => {
        // console.log(rank);
        /**
         * Dont has prize
         */
        if (rank > maxPrizedPosition) {
          temporalPosiciones.forEach((position, posIdx) => {
            //let premio = null;
            position.weekly_prize.amount = 0;
          });
        } else {
          /**
           * Calculate the prize with ties
           */
          temporalPosiciones.forEach((position, positionIdx) => {
            if (typeWeekPrize === 'first' && rank === 1) {
              let premio = weeklyPrizeNum / rankTies[1].length;
              position.weekly_prize.amount = premio * 100;
            } else if (typeWeekPrize === 'top') {
              if (rank === 1) {
                weeklyPrizeNum = weeklyPrizeNum * 0.5;
                let prizeTie = weeklyPrizeNum / rankTies[1].length;
                return prizeTie;
              } else if (rank === 2) {
                if (rankTies[1].length > 2) {
                }
                return weeklyPrizeNum * 0.3;
              } else if (rank === 3) {
                return weeklyPrizeNum * 0.2;
              }
            }
          });

          console.log(temporalPosiciones);
          let rankedPrize = temporalPosiciones.find((pos) => pos.rank === rank);
          console.log(rankedPrize);
          // this.setState({
          //   posicionesSemanales: temporalPosiciones
          // });

          return rankedPrize.weekly_prize.amount;
        }
      });
    };

    this.callbackSort = (data) => {
      let newStandings = data;
      let newUpdatedPositions = null;
      if (this.props.poolInfo.pool.status === 'live') {
        console.log('callbackSort');
        newUpdatedPositions = this.updateWeeklyStandingsAganar(
          newStandings.items,
          this.state.poolWeeks.ties_distribution
        );
        newStandings.items = newUpdatedPositions;
      }

      this.setState(
        {
          emptyList: newStandings.items.length === 0 ? true : false,
          posicionesSemanales: newStandings,
          submittingSearch: false,
          submitting: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    /**
     * This function handles the Sort order in the TablasPostions
     * @param type
     */
    this.sortPositionList = (type) => {
      console.log('%c Sort PositionList', 'color:rgb(254, 86, 150); font-size:1.2em;');
      this.setState({
        submittingSearch: true,
        submitting: true,
      });
      // console.log(type);
      //let tempPositions = this.state.posicionesSemanales;
      let tempActiveSort = this.state.activeSort;
      //let tempItems = this.state.posicionesSemanales.items;
      let orderStatus;
      switch (type) {
        case 'posiciones':
          orderStatus = this.state.activeSort.posiciones ? -1 : 1;
          this.queryParams.sort = 'rank';
          // console.log(type, orderStatus);
          tempActiveSort.posiciones = !tempActiveSort.posiciones;
          if (tempActiveSort.posiciones) {
            tempActiveSort.jugadores = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'jugadores':
          orderStatus = this.state.activeSort.jugadores ? -1 : 1;
          this.queryParams.sort = 'nick';
          // console.log(type, orderStatus);
          tempActiveSort.jugadores = !tempActiveSort.jugadores;
          if (tempActiveSort.jugadores) {
            tempActiveSort.posiciones = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'aciertos':
          orderStatus = this.state.activeSort.aciertos ? -1 : 1;
          this.queryParams.sort = 'points';
          if (this.props.poolInfo.pool.status === 'closed') {
            // console.log(type, orderStatus);
            tempActiveSort.aciertos = !tempActiveSort.aciertos;
            if (tempActiveSort.aciertos) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          } else {
            // console.log(type, orderStatus);
            tempActiveSort.aciertos = !tempActiveSort.aciertos;
            if (tempActiveSort.aciertos) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          }
          break;
        case 'premios':
          orderStatus = this.state.activeSort.premios ? -1 : 1;
          this.queryParams.sort = 'prize';
          // console.log(type, orderStatus);
          // tempItems = tempItems.sort(
          //   firstBy(function(item){ return item.user_prize.amount}, orderStatus)
          //     .thenBy("entry_id", -1)
          // );
          tempActiveSort.premios = !tempActiveSort.premios;
          break;
        default:
          return;
      }

      // tempPositions['items'] = tempItems;
      this.setState(
        (prevState) => {
          console.log(tempActiveSort);
          // console.log(prevState.activeSort);
          return {
            activeSort: tempActiveSort,
            sortChanged: !prevState.sortChanged,
            liveFixturesData: Date.now(),
            actualPage: 1,
            // standings: tempPositions,
          };
        },
        () => {
          //let temporalSortStatus = this.state.activeSort;
          this.queryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
          this.queryParams.page = 1;
          console.log(this.queryParams);
          API.fetchWeeklyStandings(
            this.props.poolInfo.pool.id,
            this.props.currentWeek,
            this.queryParams
          )
            .then((response) => {
              console.log('Response', response.data);
              this.callbackSort(response.data);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error sorting in weekly  standings ',
              });
            });
          // this.queryParams.sort = [];
          // console.log(
          //   'After Sort',
          //   this.state.standings.items[0].entry_id,
          //   this.state.sortChanged
          // );
        }
      );
    };

    /**
     * the renderer without infiniteloader
     */
    this.rowWeeklyList = () => {
      const usersItems = this.state.posicionesSemanales.items;
      // console.log('Row Renderer Premios',props.isScrolling, props.index, index);
      // console.log('Row Renderer Pos',props.index , usersItems);
      if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
        console.log('escape');
        return;
      }
      //let prizeFormat = this.props.premios.pool.entry_currency === 'real' ? '$0,000.00' : '0,000';

      let weekList = usersItems.map((item, idx) => {
        return (
          <div
            key={item.entry_id}
            id={Utilities.highlightThisUser(item.user_id, 64560)}
            className={` position-item flex-row quina
            ${idx % 2 ? 'even-colored-row' : ''}
            ${this.props.entryId === item.entry_id ? 'user' : ''}`}
          >
            <div className="posiciones-category rank quina">
              <span>{item.rank}°</span>
            </div>
            <div className="posiciones-category jugador quina">
              <img
                src={item.avatar === null ? avatar : `${item.avatar}`}
                className="img-circle score-avatars"
              />
              <span>{`${item.nick}`}</span>
              <sup>{`${item.number !== undefined ? item.number : ''}`}</sup>
            </div>
            <div className="posiciones-category aciertos quina">
              <span>
                {`${this.props.poolInfo.pool.closed ? item.points : item.total_possibly_correct}`}{' '}
              </span>
            </div>
            <div className="posiciones-category a-ganar quina">
              {/*<Aganar
                poolInfo={this.props.poolInfo.pool}
                userData={item}
                getAganar={this.props.getAganar}
              />*/}
            </div>
          </div>
        );
      });
      return weekList;
    };
  }

  rowRenderer(props) {
    const usersItems = this.state.posicionesSemanales.items;
    const standing = usersItems[props.index];
    const { entry_id, user, points, total_possibly_correct, rank, user_id } = standing;
    let avatarImg = null
    if (user.avatar !== null) {
      avatarImg = <img src={`${user.avatar_dir}${user.avatar}`} className='img-circle score-avatars' alt='avatar' />
    } else {
      avatarImg = <img src={avatar} alt='avatar' className='img-circle score-avatars' />
    }

    const followSuccess = !this.state.successFollow
      ? false
      : this.state.successFollow.find(
        (success) => success.status && success.id === user_id
      );
    const followError = !this.state.errorFollow
      ? false
      : this.state.errorFollow.find(
        (error) => error.status && error.id === user_id
      );

    let currentWeek = null;

    if (this.props.poolWeeks.current.id === this.props.currentWeek) {
      currentWeek = this.props.poolWeeks.current;
    } else {
      currentWeek = this.props.poolWeeks.weeks.find((week) => week.id === this.props.currentWeek);
    }

    if (standing === undefined || standing === null || standing.length === 0) {
      console.log('escape');
      return;
    }

    return (
      <div
        id={Utilities.highlightThisUser(user_id, 64560)}
        key={props.key}
        style={props.style}
        className={`flex-row no-padding row-center ${this.props.entryId === entry_id ? 'own-highlight' : ''} table-positions-row ${props.index % 2 ? 'even-colored-row' : ''}`}
      >
        <ButtonFollowFriend
          followSuccess={followSuccess}
          followError={followError}
          followUser={this.followUser}
          user={standing}
        />
        <div className="flex-col col-15 text-center text-bold">
          <span>{rank}°</span>
        </div>
        <div className="flex-col col-30 flex-container align">
          {avatarImg}
          <span>{user.nick}</span>
          <sup>{(standing.entry.number !== undefined && standing.entry.number !== null) ? usersItems[props.index].entry.number : ''}</sup>
        </div>
        <div className="flex-col col-15 text-center">
          <span>
            {this.props.poolInfo.pool.closed ? points : total_possibly_correct}{' '}
          </span>
        </div>
        <div className={`flex-col col-30  text-bold`}>
          <Aganar
            currentWeek={currentWeek}
            poolInfo={this.props.poolInfo.pool}
            userData={usersItems[props.index]}
            getPrize={this.getPrize}
          />
        </div>
      </div>
    );
  }

  isRowLoaded({ index }) {
    // console.log(index);
    return !!this.state.posicionesSemanales.items[index];
  }

  loadMoreRows({ startIndex, stopIndex }) {
    // console.log('Load More...');
    this.siguientePagPositions();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('ComponentDIdUpdate',prevProps.posiciones.items[0].user_prize.amount, this.props.posiciones.items[0].user_prize.amount)
    // console.log('ComponentDIdUpdate',prevProps.posiciones.items[1].user_prize.amount, this.props.posiciones.items[1].user_prize.amount)
    // console.log('ComponentDIdUpdate',prevProps.liveFixturesData, this.props.liveFixturesData);
    if (prevProps.liveFixturesData !== this.props.liveFixturesData) {
      // console.log('Standings Changed Updated');
      if (this.list !== undefined && this.list !== null) {
        this.list.forceUpdateGrid();
      }
    }
    if (prevProps.sortChanged !== this.props.sortChanged) {
      // console.log('Sort Changed!');
      // this.list.forceUpdate();
      if (this.list !== undefined && this.list !== null) {
        this.list.forceUpdateGrid();
      }
    }
    if (prevProps.currentWeek !== this.props.currentWeek) {
      this.changeWeek();
    }
    if (prevProps.newStandings !== this.props.newStandings && prevProps.newStandings === false) {
      /**
       * Handle Socket data here
       */
    }
    if (
      prevProps.currentWeek &&
      prevProps.currentWeek !== this.props.currentWeek &&
      this.props.currentWeek === this.state.poolWeeks.current.id
    ) {
      let temporalWeekStandings = this.state.posicionesSemanales;
      // console.log('standings', temporalWeekStandings);
      // console.log('ties_distriubution', this.state.poolWeeks.ties_distribution);
      let newWeekStandings = this.updateWeeklyStandingsAganar(
        temporalWeekStandings.items,
        this.state.poolWeeks.ties_distribution
      );
      // console.log('Change week update standigns', newWeekStandings);
      temporalWeekStandings.items = newWeekStandings;
      this.setState({
        posicionesSemanales: temporalWeekStandings,
      });
    }
  }

  componentDidMount() {
    this.weeklyStandingsSubscriptionRx = SubscriptionRxs.weeklyStandingsUpdatedReceived$.subscribe(
      (updatedWeeklyStandings) => {
        if (
          !this.props.poolInfo ||
          !this.state.posicionesSemanales ||
          !this.state.posicionesSemanales.items ||
          !this.state.currentWeek
        ) {
          return;
        }
        if (updatedWeeklyStandings.poolId !== this.props.poolInfo.pool.id) {
          return;
        }
        if (updatedWeeklyStandings.weekId !== this.state.currentWeek) {
          return;
        }

        console.log('Updated via socket', updatedWeeklyStandings);
        let temporalPoolWeeks = this.state.poolWeeks;

        temporalPoolWeeks.ties_distribution = updatedWeeklyStandings.ties_distribution;

        this.setState({
          poolWeeks: temporalPoolWeeks,
        });

        //let allStandingsUpdated = updatedWeeklyStandings.weeklyStandings;
        //let temporalPool = this.props.poolInfo;

        let tempStandings = this.state.posicionesSemanales;

        //let temporalTiesDistribution = updatedWeeklyStandings.ties_distribution;

        let newUpdatedPositions = this.updateWeeklyStandingsAganar(
          updatedWeeklyStandings.weeklyStandings,
          updatedWeeklyStandings.ties_distribution
        );

        let direction = -1;

        if (this.queryParams.order !== 'ASC') {
          direction = 1;
        }

        if (this.props.poolInfo.closed && this.queryParams.sort !== 'nick') {
          updatedWeeklyStandings.weeklyStandings.sort(
            firstBy('total_possibly_correct', direction).thenBy('entry_id')
          );
          if (this.queryParams.nick === null || this.queryParams.nick === '') {
            let howMany = 100 * this.queryParams.page;
            tempStandings.items = updatedWeeklyStandings.weeklyStandings.slice(0, howMany);
          }
        }

        tempStandings['items'] = newUpdatedPositions;

        if (
          this.queryParams.sort !== 'nick' &&
          this.queryParams.nick !== null &&
          this.queryParams.nick !== ''
        ) {
          console.log('should sort nicks');
          tempStandings.items.sort(firstBy('total_possibly_correct, direction').thenBy('entry_id'));
        }

        this.setState({
          posicionesSemanales: tempStandings,
        });
      }
    );
    // console.log('Participantes Semanal');
    // console.log(this.props.poolInfo.pool.id, this.props.currentWeek.id);
    // this.props.handleMaxHeight();
    API.fetchWeeklyStandings(this.props.poolInfo.pool.id, this.props.currentWeek, this.queryParams)
      .then((response) => {
        // console.log(response);
        // console.log(this.props.poolWeeks.ties_distribution);
        let tempPos = response.data;

        if (this.props.poolInfo.pool.status === 'live') {
          // console.log(
          //   'componentdidmount',
          //   this.props.poolWeeks.ties_distribution
          // );
          tempPos.items = this.updateWeeklyStandingsAganar(
            tempPos.items,
            this.props.poolWeeks.ties_distribution
          );
          // console.log(tempPos.items);
        }

        this.setState(
          {
            currentWeek: this.props.currentWeek,
            logged: utils.isLogged(),
            mounted: true,
            poolInfo: this.props.poolInfo,
            poolWeeks: this.props.poolWeeks,
            posicionesSemanales: tempPos,
            userData: utils.getUserInfo(),
            userItems: response.data.items,
          },
          () => {
            setTimeout(() => { }, 500);
            // console.log('Mounted');
          }
        );
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error, {
          extra: 'Error getting weekly standings',
        });
      });
  }

  render() {
    console.log('🚀 ~ file: TablaSemanalPosiciones.js:1163 ~ TablaSemanalPosiciones ~ render ~ const:', this.state.myWeekStandings)
    if (!this.state.mounted) {
      return (
        <div className="flex-container justify align ">
          <i className="ion-load-a loading" />
        </div>
      );
    } else {
      return (
        <div className={`position-list border-gradient ${this.props.isVisible ? '' : 'hidden'}`}>
          <div className="title-quiniela">
            <div className="title-wrapper">
              <img src={posiciones} className="pool-icons tablas" />
              <span className='font-chivo'> Tabla de Posiciones Semanales</span>
            </div>
          </div>
          <SearchableInput
            placeholder={'Buscar a pickwineros por su nick '}
            value={this.state.searchableNick}
            fetchStandings={this.fetchSearchableStandings}
            changeSearchNick={this.changeSearchNick}
            resetValue={this.resetNickValue}
            submitting={this.submittingSearch}
          />
          <div className="position-header flex-row row-no-padding">
            <div className="posiciones-category flex-col col-center">
              <i className="ion-ios-people" style={{ fontSize: '1.5em' }}></i>
            </div>
            <div
              onClick={(e) => {
                this.sortPositionList('posiciones');
              }}
              className="sortableClick posiciones-category flex-col col-15 quina"
            >
              <span>Pos</span>
              {this.state.activeSort && this.state.activeSort.posiciones ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={(e) => {
                this.sortPositionList('jugadores');
              }}
              className="sortableClick posiciones-category flex-col  col-30 quina"
            >
              <span>Jugador</span>
              {this.state.activeSort && this.state.activeSort.jugadores ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={(e) => {
                this.sortPositionList('aciertos');
              }}
              className="sortableClick posiciones-category flex-col col-15 quina"
            >
              <span>Aciertos</span>
              {this.state.activeSort && this.state.activeSort.aciertos ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
            <div
              onClick={(e) => {
                this.sortPositionList('premios');
              }}
              className="sortableClick posiciones-category flex-col col-30 quina"
            >
              {this.props.poolInfo.pool.status === 'closed' ? (
                <span>Ganó</span>
              ) : (
                  <span>A Ganar</span>
                )}
              {this.state.activeSort && this.state.activeSort.premios ? (
                <i className="ion-arrow-up-b" />
              ) : (
                  <i className="ion-arrow-down-b" />
                )}
            </div>
          </div>
          <div className={`overflow no-border overflow-invisible overflow-y overflow-300`}>
            <div className="lista-posiciones quiniela">

            {this.state.myWeekStandings && (
              <li
                className="position-item user flex-row row-no-padding"
                style={{ paddingRight: '6px' }}
              >
                <div className="flex-col col-center posiciones-category friend"></div>
                <div className="posiciones-category rank quina col-15"><span>{this.state.myWeekStandings?.rank}°</span></div>
                <div className="posiciones-category jugador quina col-30">
                  <img src={this.state.myWeekStandings?.avatarImg} className="img-circle score-avatars" alt="avatar" /><span>{this.state.myWeekStandings?.user?.nick}</span>
                  <sup>{(this.state.myWeekStandings?.entry?.number !== undefined && this.state.myWeekStandings?.entry?.number !== null) ? this.state.myWeekStandings?.entry?.number : ''}</sup>
                </div>
                <div className="posiciones-category aciertos quina col-15"><span>{ this.props.poolInfo.pool.closed ? this.state.myWeekStandings?.points : this.state.myWeekStandings?.total_possibly_correct}</span></div>
                <div className="posiciones-category a-ganar quina col-30">
                  <div className="flex-container justify align" style={{ paddingRight: '6px', justifyContent: 'flex-end' }}>
                    <span className="cant">
                      <Aganar
                        currentWeek={this.state.myWeekStandings?.currentWeek}
                        poolInfo={this.props.poolInfo.pool}
                        userData={this.state.myWeekStandings}
                        getPrize={this.getPrize}
                      />
                    </span>
                  </div>
                </div>
              </li>
            )}
              
              {this.state.submitting ? (
                <div className="flex-container justify align">
                  <i className="ion-load-a loading" />
                </div>
              ) : !this.state.posicionesSemanales ||
                this.state.posicionesSemanales.items.length === 0 ? (
                    <div className="flex-container column justify align">
                      <i className="ion-sad big-ionicon" />
                      <span className="text-center">
                        Lo sentimos, no encontramos ningún pickwinero.
                  </span>
                    </div>
                  ) : (
                    // <InfiniteLoader
                    //   isRowLoaded={this.isRowLoaded}
                    //   loadMoreRows={this.loadMoreRows}
                    //   rowCount={this.state.posicionesSemanales.count}
                    //   threshold={1}
                    // >
                    //   {({ onRowsRendered, registerChild }) => (
                    //     <AutoSizer>
                    //       {({ height, width }) => (
                    //         <List
                    //           ref={(registerChild) => (this.list = registerChild)}
                    //           height={height}
                    //           overscanRowCount={30}
                    //           onRowsRendered={onRowsRendered}
                    //           rowCount={this.state.posicionesSemanales.items.length}
                    //           rowHeight={30}
                    //           rowRenderer={this.rowRenderer}
                    //           width={width}
                    //         />
                    //       )}
                    //     </AutoSizer>
                    //   )}
                    // </InfiniteLoader>

                    <div style={{height: '100%', overflowY: 'scroll'}}>
                      <InfiniteScroll
                        pageStart={1}
                        loadMore={this.loadMoreRows}
                        hasMore={this.state.posicionesSemanales.page === this.state.posicionesSemanales.pages ? false : true}
                        loader={<Loading key={'labelLoader00'} label="..." />}
                        useWindow={false}
                      >
                        {this.state.posicionesSemanales.items.map((pos, i) => {
                          let props = {key: i, index: i, style: null}
                          return this.rowRenderer(props);
                        })}
                      </InfiniteScroll>
                    </div>
                  )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TablaSemanalPosiciones;
