import React from 'react';
import firstBy from 'thenby';
import { Redirect } from 'react-router';
// import MasonryLayout from 'masonry-layout';
import {
  Button,
  Modal,
} from 'react-bootstrap/lib/';
import MediaQuery from 'react-responsive';

import Hammer from 'react-hammerjs';

import moment from 'moment';
import axios from 'axios';
import flechaFlip from '../../assets/images/flechasIconBlanco-flipped.png';

import SubscriptionRxs from '../../utils/subscriptions';

import Loading from '../Loading';
import QuinielasDashboard from '../QuinielasDashboard';
import EntriesControls from '../EntriesControls';

import utils from '../../utils/utils';
import API from '../../utils/api';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

const PanelCancelConfirmation = ({
  submitting,
  entryToDelete,
  showConfirmation,
  hideConfirmation,
  title,
  label,
  afterConfirmation,
  errorStatus,
  errorLabel,
  errorTitle,
}) => {
  return (
    <Modal
      bsSize="sm"
      dialogClassName="confirmationPanelPopUp modal-dialog-xxxs"
      show={showConfirmation}
      keyboard={false}
    >
      <div className="panel-abonar">
        <div
          id="panelTitle"
          className={`flex-row row-no-padding panel-abonar-titulo ${errorStatus ? 'failed' : ''}`}
          style={{background: 'linear-gradient(rgba(140, 140, 140, 0.3) 0%, #d3242b 100%)'}}
        >
          <span>{errorStatus ? errorTitle : title}</span>
          <button className="cerrar-panel" onClick={hideConfirmation}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-alert-circled alert" />
              {errorStatus ? errorLabel : label}
            </div>
          </div>
        </div>
        <div className="flex-row row-center">
          <div className="flex-col col-90 col-offset-5">
            {errorStatus ? (
              <div className="flex-row">
                <Button
                  className="flex-col col-center btn-rojo btn-submit text-center"
                  onClick={() => {
                    hideConfirmation();
                  }}
                >
                  OK
                </Button>
              </div>
            ) : (
                <div className="flex-row">
                  <Button
                    className="flex-col col-40 col-offset-5 col-center btn-rojo btn-submit text-center"
                    onClick={() => {
                      hideConfirmation();
                    }}
                  >
                    No
                  </Button>
                  <Button
                    disabled={submitting}
                    className="flex-col col-40 col-offset-5 col-center btn-pick btn-submit text-center"
                    onClick={() => {
                      submitting = true;
                      afterConfirmation()
                        .then((response) => {
                          console.log('Response', response);
                          if (response.success) {
                            hideConfirmation();
                          }
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    {submitting ? (
                      <div className="flex-container justify align column">
                        <i className="ion-load-a loading small"></i>
                      </div>
                    ) : (
                        'Si'
                      )}
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

class MisRegistros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDashboard: [],
      activeEntries: [],
      bracketEntries: [],
      cancelThisEntry: null,
      collapseOpen: false,
      defaultFilters: false,
      entries: [],
      errorStatus: false,
      errorLabel: null,
      liveFixturesData: null,
      logged: this.props.logged,
      mounted: false,
      mypools: null,
      redirectEditQuiniela: false,
      redirectIndex: null,
      redirectLobby: false,
      updateSockets: false,
      showCancelConfirmation: false,
      showQuinielas: null,
      groupPoolSockets: null,
      groupPoolContestSockets: null,
      sortOrder: { rank: 1, number: 1 },
      sports: null,
      updatePanel: false,
      updatedEntries: [],
      userData: null,
    };

    // console.log('MisRegistros', this);
    // this.animateClosePanel          = this.animateClosePanel.bind(this);
    this.closeMiQuina = this.closeMiQuina.bind(this);
    this.editarQuiniela = this.editarQuiniela.bind(this);
    this.filterBarAccumulative = this.filterBarAccumulative.bind(this);
    this.handleMisQuinielas = this.handleMisQuinielas.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.isDashActive = this.isDashActive.bind(this);
    this.openCollapse = this.openCollapse.bind(this);
    this.closeCollapse = this.closeCollapse.bind(this);
    this.redirectMe = this.redirectMe.bind(this);
    this.resetDefaultFilters = this.resetDefaultFilters.bind(this);
    this.resetPosiciones = this.resetPosiciones.bind(this);
    this.resetPanelsHeight = this.resetPanelsHeight.bind(this);
    this.updateActiveDashboard = this.updateActiveDashboard.bind(this);
    this.updateMisQuinielas = this.updateMisQuinielas.bind(this);
    this.updateEntries = this.updateEntries.bind(this);
    this.changeStatusPool = this.changeStatusPool.bind(this);
    this.changeStatusPoolGroup = this.changeStatusPoolGroup.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    this.updateControlEntries = (newEntry, entryId, gameType) => {
      console.log('newEntry', newEntry);
      console.log('entryId', entryId);
      console.log('gameType', gameType);

      if (!entryId || !newEntry || !gameType) {
        console.error('No data available');
        return;
      }

      let temporalEntries = this.state.entries;
      let temporalFilteredEntries = this.state.filteredEntries;

      temporalEntries.forEach((entry) => {
        if (entry.id === entryId && entry.game_type === gameType) {
          console.log('Found it', entry);
          entry.pick_count = newEntry.pick_count;
          entry.pick_prop_count = newEntry.pick_prop_count;
          entry.entry_total_picks = newEntry.pick_prop_count + newEntry.pick_count;
        }
      });
      temporalFilteredEntries.forEach((entry) => {
        if (entry.id === entryId && entry.game_type === gameType) {
          console.log('Found it', entry);
          entry.pick_count = newEntry.pick_count;
          entry.pick_prop_count = newEntry.pick_prop_count;
          entry.entry_total_picks = newEntry.pick_prop_count + newEntry.pick_count;
        }
      });
      this.setState({ entries: temporalEntries, filteredEntries: temporalFilteredEntries });
      console.log();
    };

    this.handleConfirmationCancel = (entryId) => {
      console.log('%c Handle Confirm Cancel', 'color:#78bc28; font-size:2em;');

      let thisEntry = this.state.entries.find((entry) => {
        // console.log(entry.id, entryId);
        if (entry.id === entryId) {
          return entry;
        }
      });

      if (thisEntry !== -1 || thisEntry.hasOwnProperty('id')) {
        console.log(thisEntry);
        let fakeEntryObject = {};
        if (thisEntry.game_type === 'pool') {
          fakeEntryObject.entry = thisEntry;
        } else if (thisEntry.game_type === 'survivor') {
          fakeEntryObject.survivor_entry = thisEntry;
        } else if (thisEntry.game_type === 'bracket') {
          fakeEntryObject['bracket_entry'] = thisEntry;
        } else if (thisEntry.game_type === 'group_pool') {
          fakeEntryObject['group_pool_entry'] = thisEntry;
        }
        console.log(fakeEntryObject);

        this.setState({
          cancelThisEntry: fakeEntryObject,
          showCancelConfirmation: true,
        });
      } else {
        console.error(entryId, ': This Entry Dont exist');
      }
    };

    this.refreshUiAfterCancel = (cancelThisEntry) => {
      console.log('%c Refresh UI', 'color:#78BC28 ;font-size:2em;');
      console.log(cancelThisEntry);
      /**
       * Check if the canceled Entry is open in the dashboard
       */

      this.closeCanceledEntry(cancelThisEntry);

      if (!cancelThisEntry) {
        console.error("The entry to cancel doesn't exist");
        return;
      }

      let temporalEntries = this.state.entries;
      let temporalFilteredEntries = this.state.filteredEntries;

      let myEntryInEntriesIndex = temporalEntries.findIndex(
        (entry) => entry.id === cancelThisEntry
      );
      let myEntryInFilteredIndex = temporalFilteredEntries.findIndex(
        (entry) => entry.id === cancelThisEntry
      );

      if (myEntryInEntriesIndex === -1 && myEntryInEntriesIndex === undefined) {
        console.error('The entry wasnt found');
      } else {
        temporalEntries.splice(myEntryInEntriesIndex, 1);
      }

      if (myEntryInFilteredIndex === -1 && myEntryInFilteredIndex === undefined) {
        console.error('The entry wasnt found');
      } else {
        temporalFilteredEntries.splice(myEntryInFilteredIndex, 1);
      }

      this.setState({
        filteredEntries: temporalFilteredEntries,
        entries: temporalEntries,
      });
    };

    this.closeCanceledEntry = (cancelThisEntry) => {
      if (!this.state.activeEntries.length || !this.state.activeDashboard.length) {
        console.error('Not Active Entries ');
        return;
      }

      let temporalActiveItems = [...this.state.activeDashboard];
      let temporalActiveEntries = [...this.state.activeEntries];

      let activeItemIndex = temporalActiveItems.findIndex(
        (activeItem) => activeItem.id === cancelThisEntry
      );

      if (activeItemIndex === -1 || activeItemIndex === undefined) {
        console.error('Entry wasnt found');
      } else {
        temporalActiveItems.splice(activeItemIndex, 1);
      }

      let activeEntryIndex = temporalActiveEntries.findIndex(
        (activeItem) => activeItem === cancelThisEntry
      );

      if (activeEntryIndex === -1 || activeEntryIndex === undefined) {
        console.error('Entry wasnt found');
      } else {
        temporalActiveEntries.splice(activeEntryIndex, 1);
      }

      this.setState(
        {
          activeDashboard: temporalActiveItems,
          activeEntries: temporalActiveEntries,
        },
        () => {
          utils.setMisQuinielasActiveItems(this.state.activeEntries);
        }
      );
    };

    this.hideConfirmationCancel = () => {
      console.log('%cHide Confirm Cancel', 'color:#df4b26; font-size:2em;');

      this.setState({
        cancelThisEntry: null,
        showCancelConfirmation: false,
        errorStatus: false,
      });
    };

    this.updateUserInfo = (data, newBalances) => {
      // console.log( updatedUserData , newBalances);
      // this.props.updateUserInfo(response.data);

      if (!data) {
        if (!newBalances) {
          //API call
          console.log('Not data privided so fetchit');
          API.fetchUserData().then((response) => {
            const balanceObj = {
              balanceReal: response.data.balanceReal,
              balancePcReal: response.data.balancePcReal,
              balancePc: response.data.balancePc,
              tickets: response.data.tickets,
            };
            console.log(balanceObj);
            this.props.updateUserBalance(balanceObj.balanceReal);
            // this.setState({userData: response.data.user, userBalance: balanceObj,});
          });
        } else {
          console.log('New Balance', newBalances);
          this.props.updateUserBalance(newBalances.balanceReal);
        }
      } else {
        this.props.updateUserInfo(data);
      }
    };

    this.cancelEntry = () => {
      let promise = null;
      let formData = new FormData();
      this.setState({ submitting: true });

      promise = new Promise((resolve, reject) => {
        console.log('%c Cancel Entry', 'color:#981713; font-size:2em;');

        console.log(this.state.cancelThisEntry);

        // console.log( formData );

        if (
          this.state.cancelThisEntry.hasOwnProperty('entry') &&
          this.state.cancelThisEntry.entry.game_type === 'pool'
        ) {
          formData.append('id[]', Number(this.state.cancelThisEntry.entry.id));

          API.postCancelRegister(formData).then((cancelationResponse) => {
            console.log(cancelationResponse.data.result.success);
            if (cancelationResponse.data.result.success) {
              let newBalances = {
                balancePc: cancelationResponse.data.result.balancePc,
                balanceReal: cancelationResponse.data.result.balanceReal,
                balancePcReal: cancelationResponse.data.result.balancePcReal,
                balanceReferral: 0,
              };
              this.updateUserInfo(null, newBalances);
              console.log('Successfull cancelation');
              this.setState({ submitting: false }, () => {
                console.log('Successfull cancelation');
                this.refreshUiAfterCancel(this.state.cancelThisEntry.entry.id);
                resolve({ success: true });
              });
            } else {
              const errorLabel = 'No pudimos cancelar tu entrada  por favor intentalo mas tarde.';
              this.setState(
                { submitting: false, errorStatus: true, errorLabel: errorLabel },
                () => {
                  reject({ success: false, error: new Error(errorLabel) });
                }
              );
            }
          });
        }
        if (
          this.state.cancelThisEntry.hasOwnProperty('survivor_entry') &&
          this.state.cancelThisEntry.survivor_entry.game_type === 'survivor'
        ) {
          formData.append('id[]', Number(this.state.cancelThisEntry.survivor_entry.id));

          API.postUnregisterSurvivor(formData).then((cancelationResponse) => {
            console.log(cancelationResponse.data.result.success);
            if (cancelationResponse.data.result.success) {
              let newBalances = {
                balancePc: cancelationResponse.data.result.balancePc,
                balanceReal: cancelationResponse.data.result.balanceReal,
                balancePcReal: cancelationResponse.data.result.balancePcReal,
                balanceReferral: 0,
              };

              this.updateUserInfo(null, newBalances);

              console.log('Successfull cancelation');
              this.setState({ submitting: false }, () => {
                console.log('Successfull cancelation');
                this.refreshUiAfterCancel(this.state.cancelThisEntry.survivor_entry.id);
                resolve({ success: true });
              });
            } else {
              const errorLabel = 'No pudimos cancelar tu entrada  por favor intentalo mas tarde.';
              this.setState(
                { submitting: false, errorStatus: true, errorLabel: errorLabel },
                () => {
                  reject({ success: false, error: new Error(errorLabel) });
                }
              );
            }
          });
        }
        else if (
          this.state.cancelThisEntry.hasOwnProperty('bracket_entry') &&
          this.state.cancelThisEntry.bracket_entry.game_type === 'bracket') {
            formData.append('id[]', Number(this.state.cancelThisEntry.bracket_entry.id));

            API.postUnregisterBracket(formData).then((cancelationResponse) => {
              console.log(cancelationResponse.data.result.success);
              if (cancelationResponse.data.result.success) {
                let newBalances = {
                  balancePc: cancelationResponse.data.result.balancePc,
                  balanceReal: cancelationResponse.data.result.balanceReal,
                  balancePcReal: cancelationResponse.data.result.balancePcReal,
                  balanceReferral: 0,
                };
                this.updateUserInfo(null, newBalances);
                console.log('Successfull cancelation');
                this.setState({ submitting: false }, () => {
                  console.log('Successfull cancelation');
                  this.refreshUiAfterCancel(this.state.cancelThisEntry.bracket_entry.id);
                  resolve({ success: true });
                });
              } else {
                const errorLabel = 'No pudimos cancelar tu entrada  por favor intentalo mas tarde.';
                this.setState(
                  { submitting: false, errorStatus: true, errorLabel: errorLabel },
                  () => {
                    reject({ success: false, error: new Error(errorLabel) });
                  }
                );
              }
            });
        }
        else if (
          this.state.cancelThisEntry.hasOwnProperty('group_pool_entry') &&
          this.state.cancelThisEntry.group_pool_entry.game_type === 'group_pool'
        ) {
          formData.append('id[]', Number(this.state.cancelThisEntry.group_pool_entry.id));

          console.log('Cancel Pool Group');
          API.postCancelRegisterPoolGroup(formData).then((cancelationResponse) => {
            if (cancelationResponse.data.result.success) {
              let newBalances = {
                balancePc: cancelationResponse.data.result.balancePc,
                balanceReal: cancelationResponse.data.result.balanceReal,
                balancePcReal: cancelationResponse.data.result.balancePcReal,
              };
              this.updateUserInfo(null, newBalances);
              console.log('Successfull cancelation');
              this.setState({ submitting: false }, () => {
                console.log('Successfull cancelation');
                this.refreshUiAfterCancel(this.state.cancelThisEntry.group_pool_entry.id);
                resolve({ success: true });
              });
            }
          });
        }
      });

      return promise;
    };

    this.deleteThisEntry = (id, gameType) => {
      const deleteEntry = new Promise((resolve, reject) => {
        console.log('Delete the entry with id:', id, 'from', this.state.entries, gameType);
        if (gameType === 'pool') {
          API.hideEntry(id)
            .then((response) => {
              console.log(response.data.result.success);
              if (response.data.result.success) {
                console.log('success');
                let tempEntries = this.state.entries;
                let filteredEntries = this.state.filteredEntries;
                /**
                 * Index of entries
                 */
                let deleteIndx = _.findIndex(tempEntries, (entry) => entry.id === id);
                /**
                 * Index of filtered array
                 */
                let deleteIndxFiltered = _.findIndex(filteredEntries, (entry) => entry.id === id);
                console.log(deleteIndxFiltered);
                console.log(deleteIndx);
                /**
                 * Delete the entry in the allEntries array
                 */
                if (
                  deleteIndx === -1 ||
                  deleteIndx === undefined ||
                  deleteIndxFiltered === -1 ||
                  deleteIndxFiltered === undefined
                ) {
                  // console.error('index not found');
                  reject({ error: 'No se encontro esta entrada' });
                } else {
                  console.log('This index ', tempEntries, deleteIndx);
                  tempEntries[deleteIndx].hidden = 1;
                  filteredEntries[deleteIndxFiltered].hidden = 1;
                  // console.log(tempEntries);
                  this.setState({ entries: tempEntries, filteredEntries });
                  resolve(true);
                }
              } else {
                console.log(response);
                reject(false);
              }
            })
            .catch((error) => {
              console.error(error);
              reject(false);
            });
        } else if (gameType === 'bracket') {
          API.hideEntryBracket(id)
            .then((response) => {
              console.log(response.data.result.success);
              if (response.data.result.success) {
                console.log('success');
                let tempEntries = this.state.entries;
                let filteredEntries = this.state.filteredEntries;
                /**
                 * Index of entries
                 */
                let deleteIndx = _.findIndex(tempEntries, (entry) => entry.id === id);
                /**
                 * Index of filtered array
                 */
                let deleteIndxFiltered = _.findIndex(filteredEntries, (entry) => entry.id === id);
                console.log(deleteIndxFiltered);
                console.log(deleteIndx);
                /**
                 * Delete the entry in the allEntries array
                 */
                if (
                  deleteIndx === -1 ||
                  deleteIndx === undefined ||
                  deleteIndxFiltered === -1 ||
                  deleteIndxFiltered === undefined
                ) {
                  // console.error('index not found');
                  reject({ error: 'No se encontro esta entrada' });
                } else {
                  console.log('This index ', tempEntries, deleteIndx);
                  tempEntries[deleteIndx].hidden = 1;
                  filteredEntries[deleteIndxFiltered].hidden = 1;
                  // console.log(tempEntries);
                  this.setState({ entries: tempEntries, filteredEntries });
                  resolve(true);
                }
              } else {
                console.log(response);
                reject(false);
              }
            })
            .catch((error) => {
              console.error(error);
              reject(false);
            });
        } else if (gameType === 'survivor') {
          API.hideSurvivorEntry(id)
            .then((response) => {
              console.log(response.data.result.success);
              if (response.data.result.success) {
                console.log('success');
                let tempEntries = this.state.entries;
                let filteredEntries = this.state.filteredEntries;
                /**
                 * Index of entries
                 */
                let deleteIndx = _.findIndex(tempEntries, (entry) => entry.id === id);
                /**
                 * Index of filtered array
                 */
                let deleteIndxFiltered = _.findIndex(filteredEntries, (entry) => entry.id === id);
                console.log(deleteIndxFiltered);
                console.log(deleteIndx);
                /**
                 * Delete the entry in the allEntries array
                 */
                if (
                  deleteIndx === -1 ||
                  deleteIndx === undefined ||
                  deleteIndxFiltered === -1 ||
                  deleteIndxFiltered === undefined
                ) {
                  // console.error('index not found');
                  reject({ error: 'No se encontro esta entrada' });
                } else {
                  console.log('This index ', tempEntries, deleteIndx);
                  tempEntries[deleteIndx].hidden = 1;
                  filteredEntries[deleteIndxFiltered].hidden = 1;
                  // console.log(tempEntries);
                  this.setState({ entries: tempEntries, filteredEntries });
                  resolve(true);
                }
              } else {
                console.log(response);
                reject(false);
              }
            })
            .catch((error) => {
              console.error(error);
              reject(false);
            });
        } else if (gameType === 'group_pool') {
          // TODO delete from mis quinielas
          return;
          API.hideSurvivorEntry(id)
            .then((response) => {
              console.log(response.data.result.success);
              if (response.data.result.success) {
                console.log('success');
                let tempEntries = this.state.entries;
                let filteredEntries = this.state.filteredEntries;
                /**
                 * Index of entries
                 */
                let deleteIndx = _.findIndex(tempEntries, (entry) => entry.id === id);
                /**
                 * Index of filtered array
                 */
                let deleteIndxFiltered = _.findIndex(filteredEntries, (entry) => entry.id === id);
                console.log(deleteIndxFiltered);
                console.log(deleteIndx);
                /**
                 * Delete the entry in the allEntries array
                 */
                if (
                  deleteIndx === -1 ||
                  deleteIndx === undefined ||
                  deleteIndxFiltered === -1 ||
                  deleteIndxFiltered === undefined
                ) {
                  // console.error('index not found');
                  reject({ error: 'No se encontro esta entrada' });
                } else {
                  console.log('This index ', tempEntries, deleteIndx);
                  tempEntries[deleteIndx].hidden = 1;
                  filteredEntries[deleteIndxFiltered].hidden = 1;
                  // console.log(tempEntries);
                  this.setState({ entries: tempEntries, filteredEntries });
                  resolve(true);
                }
              } else {
                console.log(response);
                reject(false);
              }
            })
            .catch((error) => {
              console.error(error);
              reject(false);
            });
        }
      });
      // console.log(deleteEntry);
      return deleteEntry;
    };

    this.changeSortOrder = (value) => {
      console.log('%c Sort EntryList', 'color:rgb(254, 86, 150); font-size:1.2em;');

      console.log(value);
      let tempEntries = this.state.filteredEntries;

      let liveEntries = this.state.filteredEntries.filter((entry) => entry.status === 'live');
      let closedEntries = this.state.filteredEntries.filter((entry) => entry.status === 'closed');
      let upcomingEntries = this.state.filteredEntries.filter(
        (entry) => entry.status === 'upcoming'
      );

      let tempActiveSort = this.state.sortOrder;
      // console.log()

      switch (value) {
        case 'posiciones':
          tempActiveSort.rank = this.state.sortOrder.rank === 1 ? -1 : 1;
          break;
        case 'number':
          tempActiveSort.number = this.state.sortOrder.number === 1 ? -1 : 1;
          break;
        default:
          return;
      }

      if (value === 'posiciones') {
        // console.log(value, orderStatus);
        liveEntries.sort(
          firstBy('game_type')
            .thenBy('deadline', -1)
            .thenBy('featured')
            .thenBy('rank', this.state.sortOrder.rank)
            .thenBy('number', this.state.sortOrder.number)
        );
      }
      if (value === 'number') {
        // console.log(value, orderStatus);
        liveEntries.sort(
          firstBy('game_type')
            .thenBy('deadline', -1)
            .thenBy('featured')
            .thenBy('number', this.state.sortOrder.number)
            .thenBy('rank', this.state.sortOrder.rank)
        );
      }

      tempEntries = [...liveEntries, ...upcomingEntries, ...closedEntries];

      // tempPositions['items'] = tempItems;
      this.setState({
        sortOrder: tempActiveSort,
        filteredEntries: tempEntries,
      });
    };

    /**
     * This method handles the updates coming from the socket server
     */
    this.updateClosedPool = (closedPool) => {
      console.log('pool closed in controls', closedPool);
      let temporalFilteredEntries = this.state.filteredEntries;
      let temporalDefaultEntries = this.state.entries;

      temporalFilteredEntries.forEach((entry) => {
        if (entry.pool_id === closedPool.pool) {
          console.log('%c Update this entry : ', 'color:#64d240; font-size:2em;');
          // console.table(entry);
          entry.status = 'closed';
        }
      });
      temporalDefaultEntries.forEach((entry) => {
        if (entry.pool_id === closedPool.pool) {
          console.log('%c Update this entry : ', 'color:#64d240; font-size:2em;');
          // console.table(entry);
          entry.status = 'closed';
        }
      });

      this.setState({ filteredEntries: temporalFilteredEntries, entries: temporalDefaultEntries });
    };

    this.updateControlEntriesData = (pool, gameType, myEntry) => {
      console.log('Updaate Control Entries Data', pool, gameType, myEntry);

      let temporalFilteredEntries = this.state.filteredEntries;
      //let temporalDefaultEntries = this.state.entries;

      temporalFilteredEntries.forEach((entry) => {
        if (entry.game_type === gameType) {
          switch (entry.game_type) {
            case 'group_pool':
              if (entry.group_pool_entry_id === myEntry.group_pool_entry_id) {
                console.log('updateMyEntry');
                entry.rank = myEntry.rank;
                entry.points = myEntry.points;
                entry.score_num = myEntry.score_num;
                entry.prize_amount = myEntry.group_pool_user_prize;
              }
              break;
          }
        }
      });
    };

    this.updateControlEntriesLive = (pool, gameType) => {
      // console.log('update pool updated in controls', pool);
      let temporalFilteredEntries = this.state.filteredEntries;
      let temporalDefaultEntries = this.state.entries;

      let updatedEntries = [];

      temporalFilteredEntries.forEach((entry) => {
        if (entry.pool_id === pool.id) {
          // console.log('%c Update this entry : ', 'color:#64d240; font-size:2em;');
          // console.table(entry);
          entry.available = pool.available;
          entry.entry_count = pool.entry_count;
          entry.status = pool.status;

          updatedEntries = [...updatedEntries, entry.id];
        }
      });
      temporalDefaultEntries.forEach((entry) => {
        if (entry.pool_id === pool.id) {
          console.log('%c Update this entry : ', 'color:#64d240; font-size:2em;');
          // console.table(entry);
          entry.available = pool.available;
          entry.entry_count = pool.entry_count;
          entry.status = pool.status;
        }
      });

      this.setState(
        {
          filteredEntries: temporalFilteredEntries,
          entries: temporalDefaultEntries,
          updatedEntries: updatedEntries,
        },
        () => {
          setTimeout(() => {
            console.log('reset updated entries');
            this.setState({ updateEntries: [] });
          }, 2500);
        }
      );
    };

    this.updateSocketStatus = (data) => {
      // console.log( 'UpdateStatusSockets', data );
      //Update GroupPoolSocketsState
      let temporalGroupPoolSockets = this.state.groupPoolSockets;
      let temporalContestSockets = this.state.groupPoolContestSockets;

      _.each(temporalGroupPoolSockets, (groupPoolSock) => {
        // console.log( groupPoolSock );
        let currentSocketResponse = data.groupPoolsSocket[groupPoolSock.groupPoolId];
        if (currentSocketResponse) {
          groupPoolSock.isConnected = currentSocketResponse.isConnected;
          groupPoolSock.isConnecting = false;
        }
      });

      //Update ContestSocketsState

      _.each(temporalContestSockets, (contestSock) => {
        // console.log( contestSock );
        let currentSocketResponse = data.contestsSocket[contestSock.contestId];
        if (currentSocketResponse) {
          contestSock.isConnected = currentSocketResponse.isConnected;
          contestSock.isConnecting = false;
        }
      });

      this.setState({
        groupPoolSockets: temporalGroupPoolSockets,
        groupPoolContestSockets: temporalContestSockets,
      });
    };

    this.initializeGroupPoolSockets = async (activeEntries) => {
      // console.log('%c Initialize groupPoolSockets','font-size:2em;');
      // console.log( this.state.activeEntries );
      try {
        let temporalGroupPoolSockets = {};
        let temporalGroupPoolContestSockets = {};
        let groupPoolEntries = activeEntries.filter((entry) => entry.game_type === 'group_pool');
        groupPoolEntries.forEach((entry) => {
          let isEntryOpen = false;
          this.state.activeEntries.forEach((activeEntry) => {
            //  console.log( activeEntry, entry );
            if (activeEntry === entry.id) {
              // console.log( 'isActive');
              isEntryOpen = true;
            }
          });

          // console.log( isEntryOpen );

          const socketItemModel = {
            groupPoolId: entry.group_pool_id,
            entries: [],
            contestId: entry.contest_ids,
            isConnecting: isEntryOpen,
            isConnected: false,
          };

          const socketContestModel = {
            contestId: entry.contest_ids,
            isConnecting: isEntryOpen,
            entries: [],
            isConnected: false,
          };

          // console.log( temporalGroupPoolSockets[entry.group_pool_id] )
          //GroupPool Socket State

          if (temporalGroupPoolSockets[entry.group_pool_id]) {
          } else {
            temporalGroupPoolSockets[entry.group_pool_id] = socketItemModel;
          }
          //Contest Socket State
          if (temporalGroupPoolContestSockets[entry.contest_ids]) {
          } else {
            temporalGroupPoolContestSockets[entry.contest_ids] = socketContestModel;
          }
        });

        // console.log( 'temporalGroupPoolSockets' );
        // console.log( temporalGroupPoolSockets );

        this.setState(
          {
            groupPoolSockets: temporalGroupPoolSockets,
            groupPoolContestSockets: temporalGroupPoolContestSockets,
          },
          () => { }
        );
        return { success: true, groupPoolSockets: temporalGroupPoolSockets };
      } catch (err) {
        Sentry.captureException(err);
        return { success: false };
      }
    };

    this.closeGroupPoolSocket = (entry) => {
      if (!entry) {
        let error = new Error(' Entry Dont exist');
        Sentry.captureException(error);
        return;
      }
      // console.log(entry);
      if (entry.game_type !== 'group_pool') {
        return;
      }
      // console.log('%c Close subscription of GroupPool Socket', 'font-size:2em; color:#CB1616;')
      let tempGroupPoolSockets = this.state.groupPoolSockets;
      let tempContestsSocket = this.state.groupPoolContestSockets;

      let contestId = entry.contest_ids;

      let socketsWithSameContest = _.filter(
        tempGroupPoolSockets,
        (pSocket) =>
          pSocket.contestId === contestId &&
          pSocket.isConnected &&
          pSocket.groupPoolId !== entry.group_pool_id
      );

      _.each(tempGroupPoolSockets, (socketItem) => {
        if (entry.group_pool_id === socketItem.groupPoolId) {
          console.log(socketItem);
          if (socketItem.entries.length > 1) {
            // console.log('Dont Disconnect' , entry.id);
            let filteredEntries = socketItem.entries.filter((e) => e.id !== entry.id);
            socketItem.entries = filteredEntries;
          } else {
            // console.log('Disconnect' , entry.id);
            socketItem.entries = [];
            socketItem.isConnecting = true;
            tempContestsSocket[socketItem.contestId].entries = socketsWithSameContest;

            let currentContestSocket = tempContestsSocket[socketItem.contestId];
            // console.log( ' Contest Socket of current  pool', currentContestSocket , socketItem );
            if (
              currentContestSocket &&
              currentContestSocket.isConnected &&
              currentContestSocket.entries.length < 1
            ) {
              tempContestsSocket[socketItem.contestId].isConnecting = true;
            }
          }
        }
      });

      this.setState(
        {
          groupPoolSockets: tempGroupPoolSockets,
          groupPoolContestSockets: tempContestsSocket,
          updateSockets: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ updateSockets: false });
          }, 300);
        }
      );
    };

    this.connectGroupPoolSocket = (entry) => {
      // console.log(activeEntries);
      if (entry.game_type !== 'group_pool') {
        return;
      }
      let updateSockets = false;
      // console.log('%c Connect subscriptions of GroupPool Socket', 'font-size:2em; color:#78bc28;')
      let tempGroupPoolSockets = this.state.groupPoolSockets;
      let tempContestsSocket = this.state.groupPoolContestSockets;

      let contestId = entry.contest_ids;

      let socketsWithSameContest = _.filter(
        tempGroupPoolSockets,
        (pSocket) =>
          (pSocket.contestId === contestId && pSocket.isConnected) ||
          pSocket.groupPoolId === entry.group_pool_id
      );

      _.each(tempGroupPoolSockets, (socketItem) => {
        if (entry.group_pool_id === socketItem.groupPoolId) {
          let currentContestSocket = tempContestsSocket[socketItem.contestId];

          if (
            currentContestSocket &&
            !currentContestSocket.isConnected &&
            !currentContestSocket.isConnecting
          ) {
            tempContestsSocket[socketItem.contestId].isConnecting = true;
          }

          // console.log('Connect' , entry.group_pool_id);
          socketItem.isConnecting = true;
          socketItem.entries = [...socketItem.entries, entry];
          tempContestsSocket[socketItem.contestId].entries = socketsWithSameContest;
          updateSockets = socketItem.entries.length <= 1;
        }
      });

      this.setState(
        {
          groupPoolSockets: tempGroupPoolSockets,
          groupPoolContestSockets: tempContestsSocket,
          updateSockets: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ updateSockets: false });
          });
        }
      );
    };

    moment.locale('es');
  }

  /**
   *
   * @param {poolId} poolId
   * This change the status of the pools in the Dashboard
   */
  changeStatusPoolGroup(poolId, game_type) {
    console.log('changeStatusPool ');
    let tempAvailablePools = this.state.filteredEntries;
    let selectedPoolIndx, selectedPool;
    selectedPool = tempAvailablePools.find((pool, poolIdx) => {
      if (pool.id === poolId && pool.game_type === game_type) {
        selectedPoolIndx = poolIdx;
        return pool;
      }
    });

    if (selectedPool !== undefined) {
      tempAvailablePools[selectedPoolIndx].status = 'live';
      tempAvailablePools[selectedPoolIndx].time_left = 0;
      // tempAvailablePools[selectedPoolIndx] = 'live';
      this.setState({
        filteredEntries: tempAvailablePools,
      });
    }
  }

  /**
   *
   * @param {poolId} poolId
   * This change the status of the pools in the Dashboard
   */
  changeStatusPool(poolId) {
    console.log('changeStatusPool');
    let tempAvailablePools = this.state.filteredEntries;
    let selectedPoolIndx, selectedPool;
    selectedPool = tempAvailablePools.find((pool, poolIdx) => {
      if (pool.id === poolId) {
        selectedPoolIndx = poolIdx;
        return pool;
      }
    });
    if (selectedPool !== undefined) {
      tempAvailablePools[selectedPoolIndx].status = 'live';
      tempAvailablePools[selectedPoolIndx].upcoming = false;
      tempAvailablePools[selectedPoolIndx].time_left = 0;

      // tempAvailablePools[selectedPoolIndx] = 'live';
      this.setState({
        filteredEntries: tempAvailablePools,
      });
    }
  }

  /**
   * This handles the scroll
   * @param {*} e
   */
  handleScroll(e) {
    let target = e.target;
    let scrollTop = $(target).scrollTop();
    // console.log( target, scrollTop );
    if (scrollTop > 0 && scrollTop <= 50) {
      $('.controls-wrapper').css({ top: `${60 - scrollTop}px` });
    } else if (scrollTop > 50) {
      $('.controls-wrapper').css({ top: '10px' });
    } else {
      $('.controls-wrapper').css({ top: 'auto' });
    }
  }

  /**
   * This function handles the touchs anctions of hammer
   */
  handleTouch() {
    console.log('Touch Event');
    console.log($('#root').scrollTop());
    if ($(window).width() > 762) {
      return;
    }
    const controlEntries = $('#mis-registros .controls-wrapper');
    //const controlRegistros = $('#mis-registros .control-registros');
    const navBar = $('.navbar-custom');
    const footer = $('.footer');
    const footerOffsetTop = footer.offset().top;
    const footerOffsetThreshold = controlEntries.height() + controlEntries.offset().top;
    const navBarVisible = navBar.offset().top + footerOffsetThreshold >= footerOffsetThreshold / 2;
    const footerVisible = footerOffsetThreshold > footerOffsetTop - 50;
    // console.log('footerthreshold:', footerOffsetThreshold);
    // console.log('footeroffsetTop:', footerOffsetTop);
    // console.log('footerVisibile', footerVisible);
    // console.log('navbarOff:', navBar.offset().top + footerOffsetThreshold);
    // console.log('navbarHeig:', navBar.height());
    // console.log('navVisibile', navBarVisible);
    if (navBarVisible) {
      console.log('Visible Navbar ');
      controlEntries.css('top', 'inherit');
      // controlRegistros.css({'max-height':controlRegistros.height()});
      // controlRegistros.css('height', footerOffset);
      // controlRegistros.css('height', footerOffsetThreshold-60);
    } else if (footerVisible) {
      console.log('Visible Footer ');
      controlEntries.css('top', 10);
      // controlEntries.css(maxHeight: 'calc( 100vh - 220px)');
    } else if (!navBarVisible && !footerVisible) {
      console.log('None of them are visible ');
      controlEntries.css('top', 10);
      // controlRegistros.css({'max-height':controlRegistros.height()+navBar.height()});
      // controlRegistros.css('height', footerOffsetThreshold);
      // controlEntries.css('maxWidth', );
    }

    // console.log($(this).scrollTop()); // Replace this with your code.
  }

  handleTouchEnd() {
    console.log('TouchEnd Event');
    if ($(window).width() > 762) {
      return;
    }
    const controlEntries = $('#mis-registros .controls-wrapper');
    const navBar = $('.navbar-custom');
    const footer = $('.footer');
    const footerOffsetTop = footer.offset().top;
    const footerOffsetThreshold = controlEntries.height() + controlEntries.offset().top;
    const navBarVisible = navBar.offset().top + footerOffsetThreshold >= footerOffsetThreshold - 60;
    const footerVisible = footerOffsetThreshold > footerOffsetTop - 50;
    // console.log('footerthreshold:',footerOffsetThreshold);
    // console.log('footeroffsetTop:',footerOffsetTop);
    // console.log('footerVisibile', footerVisible);
    // console.log('navbarOff:',navBar.offset().top+footerOffsetThreshold);
    // console.log('navbarHeig:',navBar.height());
    // console.log('navVisibile', navBarVisible);
    if (navBarVisible) {
      // console.log('Normal Visible Position');
      controlEntries.css('top', 'inherit');
    } else if (footerVisible) {
      // console.log('New Top Position ');
      controlEntries.css('top', 10);
    } else if (!navBarVisible && !footerVisible) {
      controlEntries.css('top', 'inherit');
    }

    // console.log($(this).scrollTop()); // Replace this with your code.
  }

  /**
   * This function reset the height of the panels when any panel is closed
   */
  resetPanelsHeight() {
    // if($(window).width() < 669){ return}
    console.log('Reset height');
    //let dashElements = $('.dashboard-item .panel-pickwin.mis');
    // console.log(dashElements);
    // _.each(dashElements, el => {
    //   el.style.height = 'auto';
    // });
  }

  /**
   * This function handles the swipe by the anfgle of the move
   * @param e
   */
  handleSwipe(e) {
    //Animate register to left
    // console.log(e);
    switch (e.direction) {
      case 2:
        console.log('Izquierdo');
        this.closeCollapse();
        break;
      case 4:
        console.log('Derecho');
        this.openCollapse();
        break;
    }
  }

  /**
   * This function reset the positions in a live Quiniela
   * @param newPos
   * @param index
   */
  resetPosiciones(newPos, index) {
    // console.log('Reseting Posiciones', this);
    // let temporalStandings = this.state.standingsFull;
    // temporalStandings[index] = newPos;
    // this.setState({
    //   // standingsFull:temporalStandings,
    // })
  }

  /**
   * This function apply the default filters and changes the state of the updatePanel
   */
  resetDefaultFilters() {
    // console.log('ResetDefaultFilters');
    this.setState((prevState) => {
      return {
        updatePanel: !prevState.updatePanel,
        defaultFilters: !prevState.defaultFilters,
      };
    });
  }

  /**
   * It calls the API to update the general state of all the active panels
   */
  updateMisQuinielas() {
    console.log('%c Update Quinielas Data', 'background:#FFEC07; font-size:20px;');
    //let url = `/api/users/pools.json`;

    // console.log('API LOG  Mis quinielas...');
    API.getMisQuinielas()
      .then((response) => {
        // console.log(pools.data.entries);
        // console.log('Update MisQuinielas',response.data.entries.length);
        if (!response) {
          return;
        }
        this.setState(
          () => {
            return {
              entries: response.data.entries,
              mypools: response.data.mypools,
              filteredEntries: [...response.data.entries, ...response.data.bracket_entries],
            };
          },
          () => {
            console.log('resetDefaultFilters');
            this.resetDefaultFilters();
          }
        );
      })
      .catch((error) => {
        Sentry.captureException(error, { extra: 'Error in Get mis quinielas' });
      });
    //If it has Some Quinas Active, update them too
    //Make another function like handleMisQuinielas But ignore the duplication condition
    if (this.state.activeDashboard.length > 0) {
      console.log(this.state.activeDashboard.length);
      let dashD = this.state.activeDashboard;
      console.log(dashD);
      dashD.forEach((dash) => {
        console.log(dash);
        this.updateActiveDashboard(dash);
      });
    }
  }

  /**
   * It updates the state of the MiQuinielaDashboard Component by his index
   * @param dashItem
   */
  updateActiveDashboard(dashItem) {
    console.log('Update ActiveDashboard ', dashItem);
    let activeItem = dashItem;
    let activeEntry,
      activeFixture,
      activePoolInfo,
      activePremios,
      activeStandings,
      activeStandGroups,
      activeSimilarEntries,
      activePoolWeeks,
      activeSelectedWeekFixtures;
    let temporalActiveDash,
      temporalEntriesInfo,
      temporalFixtures_,
      temporalPoolInfo_,
      temporalPremios_,
      temporalStandings_,
      temporalStandGroups_,
      temporalSimilarEntries_,
      temporalPoolWeeks_,
      temporalSelectedWeekFixtures_;
    let clickedEntry = this.state.entries.find((entry) => entry.id === Number(activeItem));
    // console.log('Clic    ked Entry',clickedEntry);
    axios
      .all([
        API.getEntriesInfo(activeItem),
        API.fetchFixtures(clickedEntry.pool_id),
        API.fetchIndividualPool(clickedEntry.pool_id),
        API.fetchPoolPrizes(clickedEntry.pool_id),
        API.fetchStandings(clickedEntry.pool_id),
        API.fetchStandingsGroups(clickedEntry.pool_id),
        API.fetchSimilarPools(clickedEntry.pool_id),
        API.fetchWeek(clickedEntry.pool_id),
        API.fetchWeekFixtures(
          clickedEntry.pool_id,
          clickedEntry.type === 'full' ? clickedEntry.week_current_id : clickedEntry.week_id
        ),
      ])
      .then(
        axios.spread(
          (
            temporalEntries,
            temporalFixtures,
            temporalPoolInfo,
            temporalPremios,
            temporalStandings,
            temporalStandGroups,
            temporalSimilarEntries,
            temporalPoolWeeks,
            temporalSelectedWeekFixtures
          ) => {
            // console.log('Get all the data!');
            // console.log(this.state);
            temporalEntriesInfo =
              this.state.entriesInfo === undefined ? [] : this.state.entriesInfo;
            temporalActiveDash =
              this.state.activeDashboard === undefined ? [] : this.state.activeDashboard;
            temporalFixtures_ = this.state.fixtures === undefined ? [] : this.state.fixtures;
            temporalPoolInfo_ = this.state.poolInfo === undefined ? [] : this.state.poolInfo;
            temporalPremios_ = this.state.premios === undefined ? [] : this.state.premios;
            temporalStandings_ =
              this.state.standingsFull === undefined ? [] : this.state.standingsFull;
            temporalStandGroups_ =
              this.state.standGroups === undefined ? [] : this.state.standGroups;
            temporalSimilarEntries_ =
              this.state.similarPools === undefined ? [] : this.state.similarPools;
            temporalPoolWeeks_ = this.state.poolWeeks === undefined ? [] : this.state.poolWeeks;
            temporalSelectedWeekFixtures_ =
              this.state.selectedWeekFixtures === undefined ? [] : this.state.selectedWeekFixtures;
            activeFixture = temporalFixtures.data;
            activeEntry = temporalEntries.data;
            activePoolInfo = temporalPoolInfo.data;
            activePremios = temporalPremios.data;
            activeStandings = temporalStandings.data;
            activeStandGroups = temporalStandGroups.data;
            activeSimilarEntries = temporalSimilarEntries.data;
            activePoolWeeks = temporalPoolWeeks.data;
            activeSelectedWeekFixtures = temporalSelectedWeekFixtures.data;
            console.log(
              '%s %n %c',
              'DataFetched',
              dashItem,
              'background:green;font-size:20px;color:#fff;'
            );
            console.log(
              temporalActiveDash,
              activeEntry,
              activeFixture,
              activePoolInfo,
              activePremios,
              activeStandings,
              activeStandGroups,
              activeSimilarEntries,
              activePoolWeeks,
              activeSelectedWeekFixtures
            );
            temporalActiveDash = temporalActiveDash.filter((item, i) => item !== 0);
            temporalEntriesInfo = temporalEntriesInfo.filter((item, i) => item !== 0);
            temporalFixtures_ = temporalFixtures_.filter((item, i) => item !== 0);
            temporalPoolInfo_ = temporalPoolInfo_.filter((item, i) => item !== 0);
            temporalPremios_ = temporalPremios_.filter((item, i) => item !== 0);
            temporalStandings_ = temporalStandings_.filter((item, i) => item !== 0);
            temporalStandGroups_ = temporalStandGroups_.filter((item, i) => item !== 0);
            temporalSimilarEntries_ = temporalSimilarEntries_.filter((item, i) => item !== 0);
            temporalPoolWeeks_ = temporalPoolWeeks_.filter((item, i) => item !== 0);
            temporalSelectedWeekFixtures_ = temporalSelectedWeekFixtures_.filter(
              (item, i) => item !== 0
            );
            // console.log(temporalActiveDash.length);
            if (temporalActiveDash.length === 0) {
              console.log('Empty Dash', temporalPoolInfo_);
              // console.log('%c Added Element to Dashboard','background:#00fa00');
              temporalActiveDash.push(activeItem);
              temporalEntriesInfo.push(activeEntry);
              temporalFixtures_.push(activeFixture);
              temporalPoolInfo_.push(activePoolInfo);
              temporalPremios_.push(activePremios);
              temporalStandings_.push(activeStandings);
              temporalStandGroups_.push(activeStandGroups);
              temporalSimilarEntries_.push(activeSimilarEntries);
              temporalPoolWeeks_.push(activePoolWeeks);
              temporalSelectedWeekFixtures_.push(activeSelectedWeekFixtures);

              // console.log('Active Dash', temporalActiveDash, temporalEntriesInfo, temporalFixtures_, temporalPoolInfo_);
              //Add the clicked Item in the array
            } else {
              let indexItemRepeated;
              let checkDuplicateEntry = temporalEntriesInfo.find(
                (entry) => entry.entry.id === activeEntry.entry.id
              );
              let checkRepetition = temporalActiveDash.filter((item, i) => {
                if (item === activeItem) {
                  indexItemRepeated = i;
                  return item;
                }
              });
              console.log('REpetition', checkRepetition, checkDuplicateEntry, indexItemRepeated);
              //Checking Duplicate Entries ID
              if (temporalActiveDash.length < 6) {
                console.log('not Empty Dash');
                // temporalActiveDash.push(activeItem);
                temporalEntriesInfo[indexItemRepeated] = activeEntry;
                temporalFixtures_[indexItemRepeated] = activeFixture;
                temporalPoolInfo_[indexItemRepeated] = activePoolInfo;
                temporalPremios_[indexItemRepeated] = activePremios;
                temporalStandings_[indexItemRepeated] = activeStandings;
                temporalStandGroups_[indexItemRepeated] = activeStandGroups;
                temporalSimilarEntries_[indexItemRepeated] = activeSimilarEntries;
                temporalPoolWeeks_[indexItemRepeated] = activePoolWeeks;
                temporalSelectedWeekFixtures_[indexItemRepeated] = activeSelectedWeekFixtures;
              }
            }
            console.log(temporalSimilarEntries_);
            this.setState(
              () => {
                return {
                  entriesInfo: temporalEntriesInfo,
                  activeDashboard: temporalActiveDash,
                  fixtures: temporalFixtures_,
                  poolInfo: temporalPoolInfo_,
                  premios: temporalPremios_,
                  standingsFull: temporalStandings_,
                  standGroups: temporalStandGroups_,
                  similarPools: temporalSimilarEntries_,
                  poolWeeks: temporalPoolWeeks_,
                  loaded: true,
                  numeroEntries: temporalEntriesInfo.length,
                  selectedWeekFixtures: temporalSelectedWeekFixtures_,
                };
              },
              () => {
                console.log('Updated Active ');
              }
            );
            // console.log(this.state);
            // console.log('%c Handling Finished Quiniela Cerrada o En vivo', 'background:#ff0000; color:#fff; font-size:18px;');
          }
        )
      )
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching Axios all in Mis Registros',
        });
      });
  }

  /**
   * It Redirects to the EditarquinielaPrivada Route
   * @param e
   */
  editarQuiniela(e) {
    console.log('Edita Quiniela');
    let quinielaSelect = e.target.attributes[0].value;
    console.log(quinielaSelect);
    this.setState({
      redirectEditQuiniela: true,
      redirectToPrivate: quinielaSelect,
    });
  }

  /**
   * This redirects to the Quiniela Lobby selected
   * @param cual
   */
  redirectMe(cual) {
    console.log('%c Redirect Me Now!', 'background:#00FF84;font-size:20px;');
    this.setState({
      redirectLobby: true,
      redirectIndex: cual,
    });
  }

  /**
   * It Deletes a Active Quiniela to the ActiveQuiniela Array
   * based in the index of the array of Panels
   * @param cual
   */
  closeMiQuina(cual) {
    console.log('%c Close Mi Quina', 'background:#00F7FF;font-size:20px;');
    let temporalActiveItem = [...this.state.activeDashboard];
    let temporalActiveEntries = [...this.state.activeEntries];
    console.log(temporalActiveItem[cual]);
    this.closeGroupPoolSocket(temporalActiveItem[cual]);

    temporalActiveEntries.splice(cual, 1);
    temporalActiveItem.splice(cual, 1);
    // console.log(cual, temporalActiveItem, temporalActiveEntries);
    this.setState(
      {
        activeDashboard: temporalActiveItem,
        activeEntries: temporalActiveEntries,
      },
      () => {
        utils.setMisQuinielasActiveItems(this.state.activeEntries);
      }
    );
  }

  /**
   * It collapses the Control Filter Panel
   */
  openCollapse() {
    console.log('open Controls');
    $('.controls-wrapper').addClass('active');
    $('.mis-quinielas-dashboard').addClass('active');
    // let quinielasDashboard = $('.mis-quinielas-dashboard');1520441429093
    // this.setState(prevState => {
    //   return {
    //     collapseOpen: true
    //   };
    // });
  }

  /**
   * It collapses the Control Filter Panel
   */
  closeCollapse() {
    console.log('close Controls');
    $('.controls-wrapper').removeClass('active');
    $('.mis-quinielas-dashboard').removeClass('active');
    // if($('.controls-wrapper').hasClass('closed')) {
    //   $('.controls-wrapper').removeClass('closed');
    // }
    // let quinielasDashboard = $('.mis-quinielas-dashboard');
    // this.setState(prevState => {
    //   return {
    //     collapseOpen: false
    //   };
    // });
  }

  /**
   * This runs if you have a previous selection of Active DashboardItems stored in the localStorage
   * And it updates the MiquinielaDashboard Components
   * @param quina
   */
  initMisQuinielas(quina) {
    let activeItem = quina;
    let clickedEntry = this.state.entries.find((entry) => entry.id === Number(activeItem));

    let temporalActiveDashboard = this.state.activeDashboard;
    if (clickedEntry !== -1 && clickedEntry) {
      temporalActiveDashboard.push(clickedEntry);
      this.connectGroupPoolSocket(clickedEntry);
    }

    this.setState({
      activeDashboard: temporalActiveDashboard,
      mounted: true,
    });
    // console.log( "After INIT", this.state.mounted, temporalActiveDashboard );
  }

  /**
   * This Handles if you select a Entry in the Control Panle and fetch corresponding Data
   * and adds the state to a show a new MiquinielaDashboard Component
   * @param quina
   */
  handleMisQuinielas(event) {
    // console.log('HandleMisquinielas', this.state.activeDashboard.length);
    // console.log(event.target.attributes['data-id']);
    // console.log(event.target.parentNode.attributes['data-id']);
    let activeItem =
      event.target.attributes['data-id'] !== undefined &&
        event.target.attributes['data-id'] !== null
        ? event.target.attributes['data-id'].nodeValue
        : event.target.parentNode.attributes['data-id'] !== undefined &&
          event.target.parentNode.attributes['data-id'] !== null
          ? event.target.parentNode.attributes['data-id'].nodeValue
          : null;
    // console.log(activeItem);

    if (activeItem === null) {
      return;
    }
    let clickedEntry = this.state.entries.find((entry) => entry.id === Number(activeItem));
    let temporalActiveItems = this.state.activeDashboard;
    let temporalActiveEntries = this.state.activeEntries;
    let duplicatedIndex = null;
    let isDuplicated = temporalActiveEntries.find((tempEntry, i) => {
      if (tempEntry === activeItem) {
        duplicatedIndex = i;
        return tempEntry;
      }
    });
    // console.log(isDuplicated, duplicatedIndex);
    if (isDuplicated !== undefined) {
      this.closeMiQuina(duplicatedIndex);
    } else {
      if (this.state.activeDashboard.length >= 8) {
        console.log('More than 10');
        return;
      }
      temporalActiveItems.push(clickedEntry);
      temporalActiveEntries.push(activeItem);
      // console.log(temporalActiveEntries, temporalActiveItems);
      this.setState(
        {
          activeEntries: temporalActiveEntries,
          activeDashboard: temporalActiveItems,
        },
        () => {
          this.connectGroupPoolSocket(clickedEntry);
          utils.setMisQuinielasActiveItems(this.state.activeEntries);
        }
      );
    }
  }

  componentWillUnmount() {
    $('.page-wrapper').unbind('scroll');
    $(window).unbind('scroll');
    API.unSubscribeSocketQuinielasDashboard();
    // window.removeEventListener('scroll', this.handleScroll );
    // window.removeEventListener('touchmove', this.handleTouch);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.updateSockets && this.state.updateSockets) {
      //console.log(' %c UpdateSockets! Here', 'font-size:2em;', this.state.groupPoolSockets, this.state.groupPoolContestSockets);
      /**
       * Handle and update Sockets status
       */
      API.subscribeSocketLiveGroupPoolDashboard(
        this.state.groupPoolSockets,
        this.state.groupPoolContestSockets,
        (groupPoolStandingUpdate) => {
          SubscriptionRxs.announceGroupPoolStandingUpdated(groupPoolStandingUpdate);
        },
        (groupPoolPlayersUpdate) => {
          SubscriptionRxs.announceGroupPoolPlayersUpdated(groupPoolPlayersUpdate);
        },
        (groupPoolClose) => {
          SubscriptionRxs.announceCloseGroupPoolUpdated(groupPoolClose);
        },
        (groupPoolRaceResults) => {
          SubscriptionRxs.announceGroupPoolPlayersUpdatedF1(groupPoolRaceResults);
        }
      ).then((response) => {
        this.updateSocketStatus(response);
      });
    }
  }

  /**
   * This fetch the necessary data to show the Control Panel
   */
  componentDidMount() {
    /***************************
     * Subscriptions to socket *
     ***************************/
    API.initilizeSocketQuinielasDashboard().then((response) => {
      if (response.success) {
        API.subscribeSocketLiveLobbyQuinielasDashboard(
          (dataPoolUpdate) => {
            SubscriptionRxs.announcePoolUpdateUpdated(dataPoolUpdate);
          },
          (dataPoolClose) => {
            SubscriptionRxs.announcePoolCloseUpdated(dataPoolClose);
          },
        );
      } else {
        let err = new Error('Socket didnt connect correctly');
        Sentry.captureException(err);
      }
    });

    this.poolUpdateSubscription = SubscriptionRxs.poolUpdateReceived$.subscribe((updatedPool) => {
      if (!this.state.entries || !this.state.filteredEntries) {
        return;
      }

      this.updateControlEntriesLive(updatedPool);
    });

    this.poolCloseUpdateSubscription = SubscriptionRxs.poolCloseReceived$.subscribe(
      (updatedPool) => {
        if (!this.state.entries || !this.state.filteredEntries) {
          return;
        }
        this.updateClosedPool(updatedPool);
      }
    );
    /***************************
     ***************************
     ***************************/

    $('.page-wrapper').scroll(this.handleScroll);
    $(window).scroll(this.handleScroll);

    let tempActive_ = [];

    let misQuinielasStored = utils.getMisQuiniellasActiveItems();
    // console.log(misQuinielasStored);
    // console.log('%c -----misQuinielasStored------', 'color: #64d240 ; font-size: 2em ;');
    if (misQuinielasStored) {
      tempActive_ = misQuinielasStored.split(',');
    }
    axios
      .all([API.fetchSportsIndex(), API.getMisQuinielas(), API.fetchUserData()])
      .then(
        axios.spread((sports, pools, userData) => {
          this.setState(
            () => {
              const responses = [sports, pools, userData];
              try {
                utils.checkAxiosAllErrors(responses);
              } catch (e) {
                console.error(e);
              }

              utils.setUserInfo(userData.data);
              let avPools = [
                ...pools.data.entries,
                ...pools.data.mypools,
                ...pools.data.bracketEntries,
                ...pools.data.survivorEntries,
                ...pools.data.mysurvivors,
                ...pools.data.groupPoolEntries,
              ];
              let allEntries = [
                ...pools.data.entries,
                ...pools.data.mypools,
                ...pools.data.bracketEntries,
                ...pools.data.survivorEntries,
                ...pools.data.mysurvivors,
                ...pools.data.groupPoolEntries,
              ];

              // console.log(avPools);

              let closedEntries = avPools.filter((pool) => pool.status === 'closed');
              let upcomingEntries = avPools.filter((pool) => pool.status === 'upcoming');
              let liveEntries = avPools.filter((pool) => pool.status === 'live');

              // console.log(closedEntries, upcomingEntries, liveEntries);
              closedEntries = closedEntries.sort(
                firstBy('deadline', -1).thenBy('prize_amount' - 1)
              );
              upcomingEntries = upcomingEntries.sort(firstBy('deadline', 1).thenBy('id'));
              liveEntries = liveEntries.sort(
                firstBy('deadline', -1)
                  .thenBy('featured')
                  .thenBy('rank')
                  .thenBy('number')
              );

              let sortedEntries = [...liveEntries, ...upcomingEntries, ...closedEntries];
              // console.log( sortedEntries );
              //              console.log('Mounted');
              //              console.log(sortedEntries[0].id);
              return {
                sports: sports.data.sports,
                activeEntries: misQuinielasStored ? tempActive_ : [],
                mounted: misQuinielasStored ? false : true,
                entries: allEntries,
                bracketEntries: pools.data.bracketEntries,
                survivorEntries: pools.data.survivorEntries,
                mypools: pools.data.mypools,
                mysurvivors: pools.data.mysurvivors,
                filteredEntries: sortedEntries,
                userBalance: this.props.userBalance,
                // activeDashboard: misQuinielasStored !== null ? tempActive_ : [],
                userData: this.props.userData,
              };
            },
            () => {
              this.initializeGroupPoolSockets(this.state.entries).then((response) => {
                if (response.success) {
                  if (tempActive_.length > 0) {
                    // console.log(this.state.mounted)
                    tempActive_.forEach((active) => {
                      this.initMisQuinielas(active);
                    });
                  }
                }
              });
            }
          );
        })
      )
      .catch((error) => {
        Sentry.captureException(error);
      });
  }

  /**
   * This Check if an item in the control Panel is active
   * @param item
   * @returns {*}
   */
  isDashActive(item) {
    // console.log(item, this.state.activeEntries, this.state.activeDashboard);
    let isActive_ = this.state.activeEntries.find((dashItem) => item === Number(dashItem));
    if (isActive_ !== undefined) {
      return 'active';
    } else {
      return null;
    }
  }

  /**
   * This Handle the filters ogf the Filter Controls Panel It uses the state that has already been fetched in the componnentDidMount
   * @param filters
   */
  filterBarAccumulative(filters) {
    console.log('filter', filters)

    let availablePools = this.state.entries;
    let concursoFilters = filters[0];
    let sportFilters = filters[1];
    let statusFilters = filters[3];
    let typeFilters = filters[2];
    let eliminadoActive = null;

    let sortedEntries = availablePools.filter((game, idx) => {
      return statusFilters.includes(game.status);
    });

    sortedEntries = sortedEntries.filter((game, idx) => {
      return typeFilters.includes(game.private);
    });

    if (statusFilters) {
      eliminadoActive = statusFilters.find((filter) => filter === 'eliminado');
      if (eliminadoActive === undefined || eliminadoActive === -1) {
        // console.log('Eliminado not selected', sortedEntries.length);
        sortedEntries = sortedEntries.filter((entry) => {
          // console.log(entry);
          if (!entry.hasOwnProperty('alive')) {
            return entry;
          } else if (entry.alive === 1) {
            return entry;
          }
        });
        // console.log(sortedEntries.length);
      }
    }

    let cupoActive = typeFilters.find((filter) => filter === 2);
    if (cupoActive === undefined || cupoActive === -1) {
      // console.log('Cupo not active so del cupo entries');
      sortedEntries = sortedEntries.filter((entry) => {
        // console.log(entry.max_entries);
        if (entry.max_entries === null) {
          // console.log('Not exist')
          return entry;
        }
      });
    }

    let maxActive = typeFilters.find((filter) => filter === 3);
    if (maxActive === undefined || maxActive === -1) {
      sortedEntries = sortedEntries.filter((entry) => {
        if (entry.max_entries !== null || entry.variation === "parlay") {
          return entry;
        }
      });
    }

    let picks5 = typeFilters.find((filter) => filter === 4);
    if (picks5 === undefined || picks5 === -1) {
      // console.log('max not active so del cupo entries');
      sortedEntries = sortedEntries.filter((entry) => {
        // console.log(entry.max_entries);
        if (!(entry.max_picks && entry.max_picks !== null)) {
          // console.log('Not exist')
          return entry;
        }
      });
    }

    let parlay = typeFilters.find((filter) => filter === 5);
    if (parlay === undefined || parlay === -1) {
      sortedEntries = sortedEntries.filter((entry) => {
        if (entry.variation !== 'parlay') {
          return entry;
        }
      });
    }

    if (sportFilters[0] !== 'all') {
      sortedEntries = sortedEntries.filter((game, idx) => {
        return sportFilters.includes(game.sport_id);
      });
    }

    if (concursoFilters === 'concurso-survivor') {
      sortedEntries = sortedEntries.filter((game, idx) => game.game_type === 'survivor');
    } else if (concursoFilters === 'concurso-quiniela') {
      sortedEntries = sortedEntries.filter((game, idx) => game.game_type === 'pool');
    } else if (concursoFilters === 'concurso-bracket') {
      sortedEntries = sortedEntries.filter((game, idx) => game.game_type === 'bracket');
    }

    let closedEntries = sortedEntries.filter((game) => game.status === 'closed');

    let upcomingEntries = sortedEntries.filter((game) => game.status === 'upcoming');

    let liveEntries = sortedEntries.filter((game) => game.status === 'live');

    closedEntries = closedEntries.sort(firstBy('deadline', -1).thenBy('prize_amount' - 1));

    upcomingEntries = upcomingEntries.sort(firstBy('deadline', 1).thenBy('id'));

    // console.log(this.state);

    liveEntries = liveEntries.sort(
      firstBy('deadline', -1)
        .thenBy('featured')
        .thenBy('rank', this.state.sortOrder.rank)
        .thenBy('number', this.state.sortOrder.number)
    );

    sortedEntries = [...liveEntries, ...upcomingEntries, ...closedEntries];

    this.setState({
      filteredEntries: sortedEntries,
    });
  }

  updateEntries(id, totalPicks) {
    console.log(' Mis quinielas Update ENtries Controls...');
    console.log('This entry was updated', id, 'TotalPicks', totalPicks);

    let promise;

    let tempAllEntries = this.state.filteredEntries;
    let updatedEntryAllIndex = null;
    promise = new Promise((resolve, reject) => {
      console.log('In the promise');

      // const updatedEntryAll = tempAllEntries.find((entry, entryIdx) => {
      //   if (entry.id === id) {
      //     updatedEntryAllIndex = entryIdx;
      //     return entry;
      //   }
      // });

      let tempEntries = this.state.entries;

      let updatedEntryIndex = null;

      // const updatedEntry = tempEntries.find((entry, entryIdx) => {
      //   if (entry.id === id) {
      //     updatedEntryIndex = entryIdx;
      //     return entry;
      //   }
      // });

      console.log(!updatedEntryIndex, !updatedEntryAllIndex);

      if (!updatedEntryIndex || !updatedEntryAllIndex) {
        console.error('not Found');
        reject({ success: false, error: new Error('UpdateEntry not Found!') });
        return;
      }

      tempAllEntries[updatedEntryAllIndex].entry_total_picks = totalPicks;
      tempEntries[updatedEntryIndex].entry_total_picks = totalPicks;

      this.setState(
        {
          entries: tempEntries,
          filteredEntries: tempAllEntries,
        },
        () => {
          console.log('success');
          resolve({ success: 'true', id: id });
        }
      );
    });

    return promise;
  }

  render() {
    let conditionalComponent;
    let gameType;
    let gameInfo;
    // console.log('Render MisRegistros');
    // console.log( this.state.mounted );
    if (this.state.redirectEditQuiniela) {
      if (this.state.poolInfo.hasOwnProperty('pool')) {
        gameType = 'quiniela';
        gameInfo = this.state.poolInfo.pool;
      } else if (this.state.poolInfo.hasOwnProperty('bracket')) {
        gameType = 'bracket';
        gameInfo = this.state.poolInfo.bracket;
      } else if (this.state.poolInfo.hasOwnProperty('survivor')) {
        gameType = 'survivor';
        gameInfo = this.state.poolInfo.survivor;
      }

      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/editar/quiniela/id=${this.state.redirectToPrivate}`,
          }}
        />
      );
    } else {
      if (this.state.mounted) {
        // console.log(this.state.cancelThisEntry)
        conditionalComponent = (
          <div id="mis-registros" className={` ${this.state.collapseOpen ? 'closed ' : ''}`}>
            <PanelCancelConfirmation
              submitting={this.state.submitting}
              entryToDelete={this.state.cancelThisEntry}
              showConfirmation={this.state.showCancelConfirmation}
              hideConfirmation={this.hideConfirmationCancel}
              title={`¿ Estás Seguro ?`}
              label={`Cancelar tú entrada ${
                !this.state.cancelThisEntry || !this.state.cancelThisEntry.entry
                  ? ''
                  : this.state.cancelThisEntry.entry.name_replaced
                }`}
              afterConfirmation={this.cancelEntry}
              errorStatus={this.state.errorStatus}
              errorLabel={this.state.errorLabel}
              errorTitle={'Error cancelando tu entrada.'}
            />
            <EntriesControls
              updatedEntries={this.state.updatedEntries}
              deleteThisEntry={this.deleteThisEntry}
              sortOrder={this.state.sortOrder}
              changeSortOrder={this.changeSortOrder}
              activeDashboard={this.state.activeDashboard}
              collaspseOpen={this.state.collapseOpen}
              defaultFilters={this.state.defaultFilters}
              editarQuiniela={this.editarQuiniela}
              filteredEntries={this.state.filteredEntries}
              mounted={this.state.mounted}
              filtersBarAccumulative={this.filterBarAccumulative}
              handleMisQuinielas={this.handleMisQuinielas}
              handleMisQuinielasPrivadas={this.handleMisQuinielasPrivadas}
              liveFixturesData={this.state.liveFixturesData}
              isDashActive={this.isDashActive}
              mypools={this.state.mypools}
              resetDefaultFilters={this.resetDefaultFilters}
              sports={this.state.sports}
              userData={this.props.userData}
              userBalance={this.props.userBalance}
              handleConfirmationCancel={this.handleConfirmationCancel}
              hideConfirmationCancel={this.hideConfirmationCancel}
            />
            <MediaQuery maxWidth={767}>
              <Hammer onSwipe={this.handleSwipe} direction="DIRECTION_HORIZONTAL">
                <div
                  className={`mis-quinielas-dashboard active col-xs-12 col-sm-9 col-md-10 col-lg-9 col-hd-10
                ${this.state.collapseOpen ? ' full' : null}`}
                >
                  {this.state.activeDashboard.length !== 0 ? (
                    <QuinielasDashboard
                      updateControlEntriesData={this.updateControlEntriesData}
                      updatedEntries={this.state.updatedEntries}
                      updateControlEntriesLive={this.updateControlEntriesLive}
                      updateControlEntries={this.updateControlEntries}
                      changeStatusPool={this.changeStatusPool}
                      changeStatusPoolGroup={this.changeStatusPoolGroup}
                      activeDashboard={this.state.activeDashboard}
                      activeEntries={this.state.activeEntries}
                      closeMiQuina={this.closeMiQuina}
                      getUpdatedWeeksFixtures={this.getUpdatedWeeksFixtures}
                      poolInfo={this.state.poolInfo}
                      redirectMe={this.redirectMe}
                      updateEntries={this.updateEntries}
                      resetPanelsHeight={this.resetPanelsHeight}
                      liveFixturesData={this.state.liveFixturesData}
                      resetPosiciones={this.resetPosiciones}
                      sports={this.state.sports}
                      updateMisQuinielas={this.updateMisQuinielas}
                      userData={this.state.userData}
                    />
                  ) : (
                      <div>
                        <div className="shape">
                          <div className="agregar-quinielas">
                            <div>
                              <img
                                src={flechaFlip}
                                className=" flechas-animacion flechas-seleccion"
                              />
                            </div>
                            <div className="mensaje-agregar-quinielas">
                              <span>Elige las quinielas</span>
                              <strong>de la lista.</strong>
                              <span>para visualizar</span>
                              <span>tus picks</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </Hammer>
            </MediaQuery>
            <MediaQuery minWidth={767}>
              <div
                className={`mis-quinielas-dashboard col-xs-9 col-sm-9 col-lg-9 col-hd-10 ${
                  this.state.collapseOpen ? ' full' : null
                  }`}
              >
                {this.state.activeDashboard.length !== 0 ? (
                  <QuinielasDashboard
                    updateControlEntriesData={this.updateControlEntriesData}
                    updatedEntries={this.state.updatedEntries}
                    updateControlEntries={this.updateControlEntries}
                    activeDashboard={this.state.activeDashboard}
                    activeEntries={this.state.activeEntries}
                    closeMiQuina={this.closeMiQuina}
                    getUpdatedWeeksFixtures={this.getUpdatedWeeksFixtures}
                    poolInfo={this.state.poolInfo}
                    redirectMe={this.redirectMe}
                    updateEntries={this.updateEntries}
                    liveFixturesData={this.state.liveFixturesData}
                    resetPanelsHeight={this.resetPanelsHeight}
                    resetPosiciones={this.resetPosiciones}
                    updateMisQuinielas={this.updateMisQuinielas}
                    userData={this.state.userData}
                  />
                ) : (
                    <div>
                      <div className="shape">
                        <div className="agregar-quinielas">
                          <div>
                            <img src={flechaFlip} className=" flechas-animacion flechas-seleccion" />
                          </div>
                          <div className="mensaje-agregar-quinielas">
                            <span>
                              Selecciona <strong>quinielas</strong>
                            </span>
                            <span>del menú izquierdo.</span>
                            <span>
                              Da <strong>clic</strong> en las quinielas
                          </span>
                            <span>que quieras ver.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </MediaQuery>
            <div />
          </div>
        );
      } else {
        conditionalComponent = <Loading label=" Estamos cargando tus entradas ..." />;
      }
    }
    return conditionalComponent;
  }
}
export default MisRegistros;
