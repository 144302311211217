// Libraries
import React from 'react';
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';
import { firstBy } from 'thenby';

// Components
import PicksGroupBracketTennis from './PicksGroupBracketTennis';
import API from '../../utils/api';

// Images
import avatar from '../../assets/images/avatar-50.png';

const BracketTennisGroupPicks = ({
  currentStage,
  bracketStages,
  matches,
  selectedEntryData,
  bracketInfo,
  entryId,
  groupPicks,
  // eventHandlers
  onStageChange,
  loadMoreStandings,
}) => {

  const [filteredMatches, setFilteredMatches] = React.useState([]);
  const [standingsFiltered, setStandingsFiltered] = React.useState(null);

  React.useEffect(() => {
    let filteredMatchesTemp = matches.filter(match => match.stage_id === currentStage.id);
    setFilteredMatches(filteredMatchesTemp);
  }, [currentStage]);

  const MatchPlayers = ({match}) => {
    return (
      <>
        {match.player_one !== null && match.player_two !== null &&
          <div className='flex-container column border-right item-content-tennis' style={{height: '100%'}}>
            <div className="flex-container border-bottom">
              <div className="col-30">
                <div className="avatar-bracket">
                  <img src={(match.player_one === null || match.player_one.avatar === null) ? avatar : `${match.player_one.avatar}`}
                  className={`${(match.player_one === null || match.player_one.avatar === null) && 'player-avatar'}`} />
                </div>
              </div>
              <div className="col-50 text-small overflow-words uppercase border-right flex-container align">
                <div className="overflow-words">
                  {match.player_one !== null ? match.player_one.name : ''}
                </div>
              </div>
              <div className="col-20 text-small flex-container align justify">{match.games_won_home}</div>
            </div>
            <div className="flex-container">
              <div className="col-30">
                <div className="avatar-bracket">
                  <img src={(match.player_two === null || match.player_two.avatar === null) ? avatar : `${match.player_two.avatar}`}
                  className={`${(match.player_two === null || match.player_two.avatar === null) && 'player-avatar'}`} />
                </div>
              </div>
              <div className="col-50 text-small overflow-words uppercase border-right flex-container align">
                <div className="overflow-words">
                  {match.player_two !== null ? match.player_two.name : ''}
                </div>
              </div>
              <div className="col-20 text-small flex-container align justify">{match.games_won_away}</div>
            </div>
          </div>
        ||
          <div key={match.id} className="item-content flex-container align justify border-right border-bottom flex-row fc-align">PD</div>
        }
      </>
    );
  };

  const WinningPick = ({match}) => {
    let player = null;
    if (match.player_one !== null && match.player_two !== null) {
      if (match.winning_pick === match.home_team_id) {
        player = match.player_one;
      }
      if (match.winning_pick === match.away_team_id) {
        player = match.player_two;
      }
    }

    return (
      <div className="item-content border-right column flex-container align justify border-bottom">
        {player &&
        <>
          <div className="flex-row no-padding border-bottom fc-align" style={{height: '70%'}}>
            <div className="col-30">
              <div className="avatar-bracket">
                <img src={!player.avatar ? avatar : player.avatar} />
              </div>
            </div>
            <div className="col-70 text-small overflow-words uppercase">
              {player.name}
            </div>
          </div>
          <div className="flex-row no-padding" style={{height: '30%'}}>
            <div className="col flex-container align justify border-right">{match.winning_game}</div>
            <div className="col flex-container align justify border-right">{match.tennis_props.length > 0 ? match.tennis_props[0].result : ''}</div>
            <div className="col flex-container align justify">{match.tennis_props.length > 0 ? match.tennis_props[0].winning_pick === "under" ? '-' : '+' : ''}</div>
          </div>
        </>
        ||
          <label>PD</label>
        }
      </div>
    );
  };

  const MyPicks = ({match}) => {
    let userPick = null;
    let userProp = null;
    if (selectedEntryData && selectedEntryData.bracket_picks.hasOwnProperty(match.id)) {
      userPick = selectedEntryData.bracket_picks[match.id];
      userProp = selectedEntryData.tennis_pick_props[match.id];
    }

    if ( userPick === null) {
      return (
        <div className="item-content border-right column flex-container align justify">
          <i className="ion-close" style={{ color: '#E00034' }}></i>
        </div>
      );
    }

    let selectedTeam = null;
    if (match.player_one !== null && match.player_two !== null) {
      if (userPick.pick === match.home_team_id) {
        selectedTeam = match.player_one;
      }
      if (userPick.pick === match.away_team_id) {
        selectedTeam = match.player_two;
      }
    }

    if (selectedTeam === null && match.sort_status === 1) {
      return (
        <div className="item-content border-right column flex-container align justify">
          <i className="ion-close" style={{ color: '#E00034' }}></i>
        </div>
      );
    }

    //Colors
    let correctPicks = [false, false, false];
    if ( match.status !== 'upcoming' ) {
      var correctness = 0;
      if ( userPick.pick !== null && userPick.pick === match.winning_pick ) {
        correctness = correctness ^ (1 << 2);
        correctPicks[0] = true;
      }
      if ( userPick.winning_game !== null && userPick.winning_game === match.winning_game ) {
        correctness = correctness ^ (1 << 1);
        correctPicks[1] = true;
      }
      if ( (userProp !== null && userProp !== undefined) && userProp.pick === match.tennis_props[0].winning_pick ) {
        correctness = correctness ^ (1 << 0);
        correctPicks[2] = true;
      }
      correctness = (correctness >>> 0).toString(2);
    }

    let pickResultClass = correctPicks[0] ? "winner" : "looser";

    return (
      <div className="item-content border-right column flex-container align justify">
        {selectedTeam &&
          <div className={`flex-row no-padding border-bottom fc-align ${match.sort_status !== 1 && pickResultClass}`} style={{height: '70%'}}>
            <div className="col-30">
              <div className="avatar-bracket">
                <img src={!selectedTeam.avatar ? avatar : selectedTeam.avatar} />
              </div>
            </div>
            <div className="col-70 text-small overflow-words uppercase">
              {selectedTeam.name}
            </div>
          </div>
        ||
          <div className={`flex-row no-padding border-bottom fc-align looser`} style={{height: '70%'}}>
            <div className="flex-container align justify">
              <i className="ion-close" style={{ color: '#E00034' }}></i>
            </div>
          </div>
        }

        <div className="flex-row no-padding" style={{height: '30%'}}>
          <div className={`col-50 flex-container align justify border-right
            ${match.sort_status !== 1 && correctPicks[0] && correctPicks[1] && 'winner'}
            ${match.sort_status !== 1 && (correctPicks[0] && !correctPicks[1]) && 'looser'}
          `}>
            {userPick.winning_game ?? <i className="ion-close" style={{ color: '#E00034' }}></i>}
          </div>

          <div className={`col-50 flex-container align justify
            ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && correctPicks[2]) && 'winner'}
            ${match.sort_status !== 1 && (correctPicks[0] && correctPicks[1] && !correctPicks[2]) && 'looser'}
          `}>
            {userProp === undefined || userProp.pick === null ? <i className="ion-close" style={{ color: '#E00034' }}></i> : userProp.pick === "under" ? '-' : '+'}
          </div>
        </div>
      </div>
    );
  };

  const UserTieWinning = ({ breaker }) => {
    return (
      <>
        {breaker.enabled &&
          <div className='flex-container align justify border-bottom border-right item-content-tennis font-chivo text-bold text-italic' style={{height: '100%'}}>
            <label>{breaker.result !== null ? breaker.result : 'PD'}</label>
          </div>
        ||
          <div className='flex-container align justify border-bottom border-right item-content-tennis font-chivo text-bold text-italic' style={{height: '100%'}}>
            <label>PD</label>
          </div>
        }
      </>
    );
  };

  const MyTieBreakers = ({ userBreaker, breaker, matches }) => {
    if (userBreaker !== undefined && userBreaker !== null) {
      userBreaker = userBreaker.find(ub => ub.bracket_tiebreaker_id === breaker.id);
    }
    //find match
    let matchFinded = undefined;
    if (userBreaker !== undefined && userBreaker !== null) {
      matchFinded = matches.find(match => (match.away_team_id === userBreaker.value || match.home_team_id === userBreaker.value));
    }
    let selectedTeam = null;
    if (matchFinded !== undefined) {
      if ( userBreaker.value == matchFinded.home_team_id ) {
        selectedTeam = matchFinded.player_one;
      }
      if ( userBreaker.value == matchFinded.away_team_id ) {
        selectedTeam = matchFinded.player_two;
      }
    }

    return (
      <div className='flex-container align justify border-right item-content-tennis font-chivo text-bold text-italic' style={{height: '100%'}}>
        {breaker.enabled &&
          <>
            {((userBreaker === undefined || userBreaker === null) || userBreaker.value === null) &&
              <i className="ion-close" style={{ color: '#E00034' }}></i>
            ||
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            }
          </>
        ||
          <>
            {selectedTeam !== null ? selectedTeam.name : 'ND' }
          </>
        }
      </div>
    );
  };

  return (
    <div className="flex-row multiple-quiniela-info grupo">
      <div className="flex-col upper-section">
        <div className="container-picks">
          <div className="upper-table" style={{paddingRight: '6px'}}>
            <div className="upper-table-category headers">
              <div className="flex-row font-chivo">
                <ButtonToolbar id="week-controls" className="flex-col">
                  <ToggleButtonGroup
                    className="flex-container align justify"
                    name="weeks-controls"
                    style={{width: '100%'}}
                    type="radio"
                    value={currentStage.id}
                    onChange={onStageChange}
                  >
                    {bracketStages.map((stage, i) => {
                      return (
                        <ToggleButton
                          key={stage.id}
                          className='bottom-separation font16'
                          value={stage.id}
                          style={{maxWidth: '15%', width: '15%', fontSize: '12px'}}
                        >
                          {stage.name}
                        </ToggleButton>
                      );
                    })}

                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>

              <div className='flex-row no-padding border-top'>
                <div className='col-20'>
                  <div className='flex-row no-padding row-bracket player-item'>
                    <div className='table-header-item font-chivo'>Jugadores</div>
                  </div>
                  <div className='flex-row no-padding row-bracket mis-picks'>
                    <div className='table-header-item font-chivo'>Pick Ganador</div>
                  </div>
                  <div className='flex-row no-padding row-bracket mis-picks'>
                    <div className='table-header-item font-chivo text-medium'>MIS PICKS</div>
                  </div>
                </div>
                <div className='col-70 table-header-overflow' id="content-header-bracket">
                  {/* Players */}
                  <div className='flex-row no-padding'>
                    <div className='tennis-pick-item border-bottom player-item'>
                      {filteredMatches.map(match => {
                        return <MatchPlayers key={match.id} match={match} />
                      })}
                      <div className='flex-container align justify border-right item-content-tennis' style={{height: '100%'}}>D1</div>
                      <div className='flex-container align justify border-right item-content-tennis' style={{height: '100%'}}>D1</div>
                    </div>
                  </div>
                  {/* Wining Pick */}
                  <div className='flex-row no-padding'>
                    <div className='tennis-pick-item mis-picks'>
                      {filteredMatches.map(match => {
                        return <WinningPick key={match.id} match={match} />
                      })}
                      {bracketInfo !== null && bracketInfo.bracket_tiebreakers !== null &&
                        bracketInfo.bracket_tiebreakers.map((breaker, i) => <UserTieWinning key={breaker.id} breaker={breaker} /> )
                      }
                    </div>
                  </div>
                  {/* My Picks */}
                  <div className='flex-row no-padding'>
                    <div className='tennis-pick-item mis-picks'>
                      {filteredMatches.map(match => {
                        return <MyPicks key={match.id} match={match} />
                      })}
                      {bracketInfo !== null && bracketInfo.bracket_tiebreakers !== null &&
                        bracketInfo.bracket_tiebreakers.map((breaker, i) => <MyTieBreakers key={breaker.id} userBreaker={selectedEntryData && selectedEntryData.bracket_user_tiebreakers} breaker={breaker} matches={matches} /> )
                      }
                    </div>
                  </div>
                </div>
                <div className='col-10 flex-container align justify border-left'>Puntos</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col lower-section" style={{paddingTop: '0px'}}>
        {groupPicks !== null &&
          <PicksGroupBracketTennis
            matches={filteredMatches}
            allMatches={matches}
            groupPicks={groupPicks}
            closed={bracketInfo.closed}
            tieBreakers={bracketInfo.bracket_tiebreakers}
            loadMoreStandings={loadMoreStandings}
            selectedEntryId={entryId}
          />
        }
      </div>
    </div>
  );
}

export default BracketTennisGroupPicks;