import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import Clipboard from 'clipboard'
import * as Sentry from '@sentry/browser'
import $ from 'jquery'
import _ from 'underscore'

// Components
import Loading from '../../../../Loading'
import PanelInvitaJugar from '../../../../PanelInvitaJugar'
import PanelInvitarAmigos from '../../../../PanelInvitarAmigos'

// Utils
import API from '../../../../../utils/api'
import utils from '../../../../../utils/utils'
import branchUtils from '../../../../../utils/branchUtils'

// Assets
import BannerInfo from './BannerInfo'
import VipReferralRewards from './VipReferralRewards'
import Resume from './Resume'
import { useEffect } from 'react'

// Hooks
import useMergedState from '../../../../../hooks/useMergedState'


let clipboardCode


function ReferralsVIP(props) {
  const [state, setState] = useMergedState({
    activeSocialSpace: null,
    branchLink: null,
    showMailInvite: false,
    showInvitePanel: false,
    showInfoPopUp: false,
    showInviteJugar: false,
    deleteError: false,
    showSocialSpace: false,
    showInvitationResponse: false,
    referals: null,
    submitting: false,
    commissionRange: 'total',
    mounted: false,
    selectedType: 'new',
    referalCommissions: null,
    poolCommissions: null,
    refererToDelete: null,
    userInviteObject: null,
    referalsQueryParams: {
      page: 1,
      limit: 100
    }
  })


  useEffect(() => {
    clipboardCode = new Clipboard('.copiar-btn')
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value
      $(wich).addClass('copied')
      e.clearSelection()
    })

    const showInfoPopUp = !!utils.getReferralsStatus()

    setState({
      submitting: true
    })

    if (props.userData.has_old_referrals) {
      API.fetchUserReferrals()
        .then((userReferralsResponse) => {
          API.fetchNewReferrals()
            .then((newReferralsResponse) => {
              setState({
                showInfoPopUp,
                newReferrals: newReferralsResponse.data.referrals,
                // newReferrals: mockNewReferral.response.data.referrals,
                isNewReferral: !props.userData.has_old_referrals,
                referals: userReferralsResponse.data.referals,
                poolCommissions: userReferralsResponse.data.poolCommissions,
                referalCommissions: userReferralsResponse.data.referalCommissions,
                mounted: true,
                submitting: false
              })
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Erro Fetching New Referrals'
              })
            })
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Erro Fetching User Referrals'
          })
        })
    } else {
      /**
       * NEw Referrals PRogram
       */
      API.fetchNewReferrals()
        .then((newReferralsResponse) => {
          setState({
            showInfoPopUp,
            newReferrals: newReferralsResponse.data.referrals,
            // newReferrals: mockNewReferral.response.data.referrals,
            isNewReferral: !props.userData.has_old_referrals,
            mounted: true,
            submitting: false
          })
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error, {
            extra: 'Erro Fetching New Referrals'
          })
        })
    }
  }, [])

  const hideInvitationResponse = () => {
    setState({ showInvitationResponse: false })
  }

  const sendChallenge = (userId, poolId) => {
    const challangeData = {
      pool_id: poolId,
      user_id: userId
    }
    API.postChallengeUser(challangeData)
      .then((response) => {
        console.log(response)
        if (response.data.success) {
          setState({
            responseStatus: true,
            showInvitationResponse: true
          })
        } else {
          setState({
            responseStatus: false,
            showInvitationResponse: true
          })
        }
      })
      .catch((error) => {
        setState({
          responseStatus: false,
          showInvitationResponse: true
        })
        console.error(error)
      })
  }

  const getBranchLinkChallenge = (pool, gameType) => {
    return branchUtils.createLinkChallenge(utils.getUserInfo(), pool, gameType)
  }

  const getBranchLinkReferrer = () => {
    console.log('Get branchLink referer')
    return branchUtils.createLinkInviteReferrer(utils.getUserInfo()).then((url) => {
      console.log(url)
      setState({ branchLink: url })
    })
  }

  const whatsAppInvitationReferer = (branchLink) => {
    console.log('WhatsApp Invitation Referrer')
    branchUtils.redirectNewReferrerToWhatsApp(branchLink, 'open')
  }

  const whatsAppInvitationChallenge = (branchLink, pool) => {
    console.log('WhatsApp Invitation Challenge')
    branchUtils.redirectChallengeToWhatsApp(branchLink, 'open', pool)
  }

  const hideInviteJugar = () => {
    setState({
      showInviteJugar: false
    })
  }

  const openMailPanel = () => {
    setState({
      showMailInvite: true
    })
  }

  const hideMailPanel = () => {
    setState({ showMailInvite: false })
  }

  const openInvitePanel = () => {
    setState({
      showInvitePanel: true
    })
  }
  const hideInvitePanel = () => {
    setState({ showInvitePanel: false, branchLink: null })
  }

  // This function handles the type of referal that will be shown in the render
  const handleSelectedType = (e) => {
    setState({
      selectedType: e
    })
  }

  // This function get the info of the API  to show it tin the tables o Commmision
  const getCommissions = (range) => {
    setState({
      submitting: true,
      commissionRange: range === undefined ? 'total' : range
    })
    // Get the commission
    if (range === undefined) {
      const query = '?range=total'
      API.fetchUserReferrals(query).then((response) => {
        console.log('Get  Commissions', response.data)
        setState({
          submitting: false,
          referals: response.data.referals,
          poolCommissions: response.data.poolCommissions,
          referalCommissions: response.data.referalCommissions
        })
      })
    } else {
      if (range === 'week' || range === 'month' || range === 'semester' || range === 'total') {
        const query = `?range=${range}`
        API.fetchUserReferrals(query).then((response) => {
          console.log('Get  Commissions', response.data)
          setState({
            submitting: false,
            referals: response.data.referals,
            poolCommissions: response.data.poolCommissions,
            referalCommissions: response.data.referalCommissions
          })
        })
      }
    }
  }

  let conditionalComponent = null

  if (!state.mounted) {
    conditionalComponent = <Loading label='Cargando información de tus referidos...' />
  } else {
    let listaInvitados
    let listaAmigos
    let listaAmigosAmigos
    let listaTotalPickcoins
    let listaTotalReal
    let listaTotalAll


    listaInvitados = state.referals !== null && state.referals.length > 0 ? state.referals : []
    if (listaInvitados.length > 0) {
      listaInvitados = listaInvitados.map((invitado, i) => (
        <div className='flex-row invitados-item' key={invitado.Users.nick}>
          <div className='flex-col col-30 invitados-category nick'>{invitado.Users.nick}</div>
          <div className='flex-col col-5 invitados-category tipo'>{invitado.level}</div>
          <div
            className='flex-col col-15 invitados-category estatus'
            style={invitado.last_entry !== null ? { color: '#87B13D' } : { color: '#D3242B' }}
          >
            {invitado.last_entry !== null
              ? (
                <i className='ion-checkmark-circled' />
                )
              : (
                <i className='ion-close-circled' />
                )}
          </div>
          <div className=' flex-col invitados-category postura'>
            {invitado.last_entry === null
              ? 'N/A'
              : moment(invitado.last_entry).format('D/MM/YY h:m')}
          </div>
        </div>
      ))

      listaAmigos = state.referalCommissions.filter((comision) => comision.level === 1)

      if (listaAmigos.length > 1) {
        listaAmigos = listaAmigos.map((comision, i) => (
          <div className='flex-row comision-item amigos' key={i}>
            <div className='flex-col comision-category nombre'>Amigos</div>
            <div className='flex-col comision-category real'>
              {comision.currency === 'real'
                ? `${numeral(comision.total / 100).format('$0,000.00')} MXN`
                : null}
            </div>
            <div className='flex-col comision-category pickcoins'>
              {comision.currency === 'pickcoin'
                ? `${numeral(comision.total / 100).format('0,000')} PC`
                : null}
            </div>
          </div>
        ))
        listaAmigos = listaAmigos.filter((lista, i) => {
          if (i > 0) {
            if (listaAmigos[i - 1].key !== lista.key) {
              return lista
            }
          }
        })
      } else if (listaAmigos.length === 1) {
        listaAmigos = (
          <div className='flex-row comision-item amigos'>
            <div className='flex-col comision-category nombre'>Amigos</div>
            <div className='flex-col comision-category real'>
              {listaAmigos.currency === 'real'
                ? `${numeral(listaAmigos.total / 100).format('$0,000.00')} MXN`
                : null}
            </div>
            <div className='flex-col comision-category pickcoins'>
              {listaAmigos.currency === 'pickcoin'
                ? `${numeral(listaAmigos.total / 100).format('0,000')} PC`
                : null}
            </div>
          </div>
        )
      }
      listaAmigosAmigos = state.referalCommissions.filter(
        (comision) => comision.level === 2
      )
      if (listaAmigosAmigos.length > 1) {
        listaAmigosAmigos = listaAmigosAmigos.map((comision, i) => {
          console.log(comision)
          return (
            <div className='flex-row comision-item amigos-amigos' key={i}>
              <div className='flex-col comision-category nombre'>Amigos de Amigos</div>
              <div className='flex-col comision-category real'>
                {comision.currency === 'real'
                  ? `${numeral(Number(comision.total) / 100).format('0,000')} MXN`
                  : null}
              </div>
              <div className='flex-col comision-category pickcoins'>
                {comision.currency === 'pickcoin'
                  ? `${numeral(Number(comision.total) / 100).format('0,000')} PC`
                  : null}
              </div>
            </div>
          )
        })
      } else if (listaAmigosAmigos.length === 1) {
        listaAmigosAmigos = listaAmigosAmigos.map((comision, i) => (
          <div className='flex-row comision-item amigos-amigos' key={i}>
            <div className='flex-col comision-category nombre'>Amigos de Amigos</div>
            <div className='flex-col comision-category real'>
              {comision.currency === 'real'
                ? `${numeral(comision.total / 100).format('0,000')} MXN`
                : null}
            </div>
            <div className='flex-col comision-category pickcoins'>
              {comision.currency === 'pickcoin'
                ? `${numeral(comision.total / 100).format('0,000')}`
                : null}
            </div>
          </div>
        ))
      }
      listaAmigosAmigos = listaAmigosAmigos.map((comision, i) => (
        <div className='flex-row comision-item amigos-amigos' key={i}>
          <div className='flex-col comision-category nombre'>Amigos de Amigos</div>
          <div className='flex-col comision-category real'>
            {comision.currency === 'pickcoin'
              ? numeral(listaAmigosAmigos[i + 1].total / 1000).format('0,000')
              : numeral(comision.total / 1000).format('$0,000.00')}{' '}
            MXN
          </div>
          <div className='flex-col comision-category pickcoins'>
            {numeral(comision.total / 100).format('0,000')} PC
          </div>
        </div>
      ))
      listaAmigosAmigos = listaAmigosAmigos.filter((lista, i) => {
        if (i > 0) {
          if (listaAmigosAmigos[i - 1].key !== lista.key) {
            return lista
          }
        }
      })

      listaTotalPickcoins = state.referalCommissions
      listaTotalPickcoins = _.reduce(
        listaTotalPickcoins,
        (memo, num) => {
          console.log(memo)
          if (num.currency === 'pickcoin') {
            console.log(memo, Number(num.total))
            return memo + Number(num.total)
          }
          return memo
        },
        0
      )

      listaTotalReal = state.referalCommissions
      listaTotalReal = _.reduce(
        listaTotalReal,
        (memo, num) => {
          console.log(memo)
          if (num.currency === 'real') {
            console.log(memo, Number(num.total))
            return memo + Number(num.total)
          }
          return memo
        },
        0
      )

      listaTotalAll = (
        <div className='flex-row comision-item amigos'>
          <div className='flex-col comision-category nombre'>Total</div>
          <div className='flex-col comision-category real'>
            {numeral(listaTotalReal / 100).format('$0,000.00')} MXN
          </div>
          <div className='flex-col comision-category pickcoins'>
            {numeral(listaTotalPickcoins / 100).format('0,000')} PC
          </div>
        </div>
      )
    }

    conditionalComponent = (
      <div>
        <PanelInvitarAmigos
          branchLink={state.branchLink}
          closePanel={hideInvitePanel}
          openInvitePanel={openMailPanel}
          hidePanel={hideMailPanel}
          showPanel={state.showInvitePanel}
          showMailInvite={state.showMailInvite}
          title='Invita a tus amigos a jugar en Pickwin'
          whatsAppInvitationReferer={whatsAppInvitationReferer}
        />
        <PanelInvitaJugar
          branchLink={state.branchLink}
          closePanel={hideInviteJugar}
          getBranchLinkChallenge={getBranchLinkChallenge}
          hideInvitationResponse={hideInvitationResponse}
          hidePanel={hideMailPanel}
          openInvitePanel={openMailPanel}
          poolSelected={{}}
          responseStatus={state.responseStatus}
          sendChallenge={sendChallenge}
          showInvitationResponse={state.showInvitationResponse}
          showMailInvite={state.showMailInvite}
          showPanel={state.showInviteJugar}
          title='Recuerda a '
          userInviteObject={state.userInviteObject}
          whatsAppInvitationChallenge={whatsAppInvitationChallenge}
        />
        <div className='flex-row'>
          <div className='info-invitados flex-col col-no-padding' style={{ margin: '0 auto', maxWidth: 700 }}>
            <BannerInfo />
            <CallToAction
              label={
                <div className='flex-col'>
                  {' '}
                  <i className='ion-plus-round' /> <span>Invitar</span>{' '}
                </div>
              }
              action={() => {
                openInvitePanel()
                getBranchLinkReferrer()
              }}
            />
            <Resume />
            <VipReferralRewards />
          </div>
        </div>
      </div>
    )
  }

  return conditionalComponent
}


const CallToAction = ({ label, action }) => {
  return (
    <div className='flex-col col-60 col-offset-20' style={{ maxWidth: 200, margin: '0 auto' }}>
      <button
        className='callToAction flex-row btn-pick btn-success'
        onClick={() => {
          action()
        }}
      >
        {label}
      </button>
    </div>
  )
}

export default ReferralsVIP
