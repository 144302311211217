import React from 'react';
import MetaTags from 'react-meta-tags'
import utils from '../../utils/utils';
import gaUtils from '../GAWrapper';
import PageWrapper from '../PageWrapper';
import Loading from '../Loading';
import TerminosLealtad from '../TerminosLealtad';

import esquemaComunicacion from '../../assets/images/esquemaComunicacion.png';
import samadhi from '../../assets/images/samadhi.png';
import satori from '../../assets/images/satori.png';
import cenadic from '../../assets/images/cenadic.png';

const TablaNumeroTelefonico = ({ indexLabel }) => {
  return (
    <div
      className="flex-col"
      style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
    >
      <div className="flex-row header">
        <div className=" flex-col col-center col-15">
          <strong>3.5.3</strong>
        </div>
        <div className=" flex-col">
          <p>Datos del número telefónico</p>
        </div>
        <div className="flex-col"> telefono </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Etiqueta</div>
        <div className="flex-col col-10"> N/A</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.3.1</strong>
        </div>
        <div className=" flex-col">
          <p>Clave país</p>
        </div>
        <div className="flex-col"> clave_país </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 10-12</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.3.2</strong>
        </div>
        <div className=" flex-col">
          <p>Número teléfono</p>
        </div>
        <div className="flex-col"> numero_telefono </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 10-20</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.3.3</strong>
        </div>
        <div className=" flex-col">
          <p>Correo electrónico</p>
        </div>
        <div className="flex-col"> correo_electrónico </div>
        <div className="flex-col"> opcional</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 5-60</div>
      </div>
    </div>
  );
};
const TablaPersonasFisicas = ({ indexLabel }) => {
  return (
    <div
      className="flex-col"
      style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
    >
      <div className="flex-row header">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}</strong>
        </div>
        <div className=" flex-col">
          <p>Datos de la persona cuando nse trata de persona física</p>
        </div>
        <div className="flex-col"> persona_fisica </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Etiqueta</div>
        <div className="flex-col col-10"> N/A</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.1</strong>
        </div>
        <div className=" flex-col">
          <p>Nombre(s)</p>
        </div>
        <div className="flex-col"> nombre </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-200</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.2</strong>
        </div>
        <div className=" flex-col">
          <p>Apellido Paterno(s)</p>
        </div>
        <div className="flex-col"> apellido paterno </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-200</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.3</strong>
        </div>
        <div className=" flex-col">
          <p>Apellido Materno(s)</p>
        </div>
        <div className="flex-col"> apellido materno </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-200</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.4</strong>
        </div>
        <div className=" flex-col">
          <p>Fecha de nacimiento</p>
        </div>
        <div className="flex-col"> fecha_nacimiento </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">8</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.5</strong>
        </div>
        <div className=" flex-col">
          <p>Registro Federal de Contribuyentes (RFC) </p>
        </div>
        <div className="flex-col"> rfc </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">13</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.6</strong>
        </div>
        <div className=" flex-col">
          <p>Clave Única de Registro de Población (CURP) </p>
        </div>
        <div className="flex-col"> curp </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">18</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.7</strong>
        </div>
        <div className=" flex-col">
          <p>Clave país de nacionalidad </p>
        </div>
        <div className="flex-col"> país_nacionalidad </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">2</div>
      </div>
      {indexLabel === '3.6.1.1' ? null : (
        <div className="flex-row content">
          <div className=" flex-col col-center col-15">
            <strong>{indexLabel}.8</strong>
          </div>
          <div className=" flex-col">
            <p>Actividad económica u ocupación </p>
          </div>
          <div className="flex-col"> actividad_economica </div>
          <div className="flex-col"> Obligatorio</div>
          <div className="flex-col"> Alfanumérico</div>
          <div className="flex-col col-10">7</div>
        </div>
      )}
    </div>
  );
};
const TablaDomicilioNacional = ({ indexLabel }) => {
  return (
    <div
      className="flex-col"
      style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
    >
      <div className="flex-row header">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.1</strong>
        </div>
        <div className=" flex-col">
          <p>Datos del domicilio si es nacional </p>
        </div>
        <div className="flex-col"> nacional </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Etiqueta</div>
        <div className="flex-col col-10"> N/A</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.1.1</strong>
        </div>
        <div className=" flex-col">
          <p>Colonia</p>
        </div>
        <div className="flex-col"> colonia </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-50</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.1.2</strong>
        </div>
        <div className=" flex-col">
          <p>Calle, avenida o vía</p>
        </div>
        <div className="flex-col"> calle </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-100</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.1.3</strong>
        </div>
        <div className=" flex-col">
          <p>Número exterior</p>
        </div>
        <div className="flex-col"> numero_exterior </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-56</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.1.4</strong>
        </div>
        <div className=" flex-col">
          <p>Número interior</p>
        </div>
        <div className="flex-col"> numero_interior </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-40</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.1.5</strong>
        </div>
        <div className=" flex-col">
          <p>Codigo postal</p>
        </div>
        <div className="flex-col"> codgo_postal </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">5</div>
      </div>
    </div>
  );
};
const TablaDomicilioExtranjero = ({ indexLabel }) => {
  return (
    <div
      className="flex-col"
      style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
    >
      <div className="flex-row header">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2</strong>
        </div>
        <div className=" flex-col">
          <p>Datos del domicilio si es extranjero </p>
        </div>
        <div className="flex-col"> extranjero </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Etiqueta</div>
        <div className="flex-col col-10"> N/A</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.1</strong>
        </div>
        <div className=" flex-col">
          <p>país</p>
        </div>
        <div className="flex-col"> pais </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 2</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.2</strong>
        </div>
        <div className=" flex-col">
          <p>Estado provincia</p>
        </div>
        <div className="flex-col"> estado_provincia </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-100</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.3</strong>
        </div>
        <div className=" flex-col">
          <p>Ciudad o población</p>
        </div>
        <div className="flex-col"> ciudad_población </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-56</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.4</strong>
        </div>
        <div className=" flex-col">
          <p>Colonia</p>
        </div>
        <div className="flex-col"> colonia </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-50</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.5</strong>
        </div>
        <div className=" flex-col">
          <p>Calle, avenida o vía</p>
        </div>
        <div className="flex-col"> calle </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-100</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.6</strong>
        </div>
        <div className=" flex-col">
          <p>Número exterior</p>
        </div>
        <div className="flex-col"> numero_exterior </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-56</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.7</strong>
        </div>
        <div className=" flex-col">
          <p>Número interior</p>
        </div>
        <div className="flex-col"> numero_interior </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-40</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.2.2.8</strong>
        </div>
        <div className=" flex-col">
          <p>Codigo postal</p>
        </div>
        <div className="flex-col"> codgo_postal </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">4-12</div>
      </div>
    </div>
  );
};

const TablaPersonasMoral = ({ indexLabel }) => {
  return (
    <div
      className="flex-col"
      style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
    >
      <div className="flex-row header">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}</strong>
        </div>
        <div className=" flex-col">
          <p>Datos de la persona cuando nse trata de persona moral</p>
        </div>
        <div className="flex-col"> persona_moral</div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Etiqueta</div>
        <div className="flex-col col-10"> N/A</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.1</strong>
        </div>
        <div className=" flex-col">
          <p>Denominación o razón social</p>
        </div>
        <div className="flex-col"> denomincación_razon </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-254</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.2</strong>
        </div>
        <div className=" flex-col">
          <p>fecha_constitución</p>
        </div>
        <div className="flex-col"> apellido paterno </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Numérico</div>
        <div className="flex-col col-10"> 8</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.3</strong>
        </div>
        <div className=" flex-col">
          <p>Registro Federal de Contribuyentes (RFC) </p>
        </div>
        <div className="flex-col"> rfc </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">12</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>{indexLabel}.4</strong>
        </div>
        <div className=" flex-col">
          <p>Clave país de nacionalidad </p>
        </div>
        <div className="flex-col"> país_nacionalidad </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">2</div>
      </div>
      {indexLabel === '3.6.1.2' ? null : (
        <div className="flex-row content">
          <div className=" flex-col col-center col-15">
            <strong>{indexLabel}.5</strong>
          </div>
          <div className=" flex-col">
            <p>Actividad económica u ocupación </p>
          </div>
          <div className="flex-col"> actividad_economica </div>
          <div className="flex-col"> Obligatorio</div>
          <div className="flex-col"> Alfanumérico</div>
          <div className="flex-col col-10">7</div>
        </div>
      )}
    </div>
  );
};

const TablaFideicomiso = ({ indexLabel }) => {
  return (
    <div
      className="flex-col"
      style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
    >
      <div className="flex-row header">
        <div className=" flex-col col-center col-15">
          <strong>3.5.1.3</strong>
        </div>
        <div className=" flex-col">
          <p>Datos de la persona cuando se trata de fideicomiso</p>
        </div>
        <div className="flex-col"> fideicomiso</div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Etiqueta</div>
        <div className="flex-col col-10"> N/A</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.1.3.1</strong>
        </div>
        <div className=" flex-col">
          <p>Denominación o razón social</p>
        </div>
        <div className="flex-col"> denomincación_razon </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10"> 1-254</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.1.3.2</strong>
        </div>
        <div className=" flex-col">
          <p>Registro Federal de Contribuyentes (RFC) </p>
        </div>
        <div className="flex-col"> rfc </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">12</div>
      </div>
      <div className="flex-row content">
        <div className=" flex-col col-center col-15">
          <strong>3.5.1.3.3</strong>
        </div>
        <div className=" flex-col">
          <p>Número, referencia identificacdor del fideicomiso </p>
        </div>
        <div className="flex-col"> identificador_fideicomiso </div>
        <div className="flex-col"> Obligatorio</div>
        <div className="flex-col"> Alfanumérico</div>
        <div className="flex-col col-10">1-40</div>
      </div>
    </div>
  );
};

const ProteccionSitio = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <div className="flex-col ">
          <h1 className="flex-col" style={{ fontSize: '130%' }}>
            <u>
              MEDIDAS DE PROTECCIÓN AL SITIO WEB, QUE GARANTIZAN LA INVIOLABILIDAD Y NO MANIPULACIÓN
              DE LAS APUESTAS.
            </u>
          </h1>
          <p>
            El juego limpio es de gran importancia para UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. En
            tal sentido, es importante para la empresa, contar con la correspondiente autorización
            de la Dirección General de Juegos y Sorteos, para llevar a cabo las actividades
            solicitadas.
          </p>
          <p>
            Los juegos que se podrán encontrar a través de los sitios web operados por UNOCAPALI LA PAZ OPERADORA,
            pasan por pruebas rigurosas constantemente.
          </p>
          <p>
            Todos los resultados de los juegos se almacenan y el historial completo de transacciones
            financieras de cada jugador estará siempre disponible para consulta en línea.
          </p>
          <ol>
            <li>
              El Permisionario (Producciones Móviles, S.A. de C.V.), su operador (UNOCAPALI LA PAZ OPERADORA S.A. de C.V.)
              , y personas clave en la operación han cumplido con la
              integridad personal y empresarial y son aptos para operar.
            </li>
            <li>El sistema operativo no se utiliza conjuntamente con alguna actividad ilegal.</li>
            <li>
              Los jugadores son tratados de forma imparcial y las ganancias son pagadas de acuerdo a
              las apuestas y las reglas de apuesta aplicables.
            </li>
          </ol>
          <p>
            La seguridad de registros y apuestas es un tema sumamente importante por lo que se
            manejan diversos sistemas de seguridad en los diferentes niveles de comunicación para
            garantizar un intercambio de información confiable.
          </p>
          <p>
            La arquitectura empleada, considera como punto de entrada un Proxy que a su vez actúa
            como Web Application Firewall para detectar peticiones maliciosas y bloquear el acceso
            en caso de ser necesario, posteriormente pasara a un balanceador de conexiones que
            únicamente permite el flujo de conexiones HTTPS hacia los servidores correspondientes.
          </p>
          <p>
            Cada servidor dentro del clúster que opera en la nube proporcionada por Google Cloud
            únicamente cuenta con los puertos expuestos necesarios para brindar el servicio
            necesario a los usuarios de la plataforma.
          </p>
          <p>
            Las aplicaciones dirigidas a nuestro servidor deben viajar encriptados por medio de
            HTTPS; además, generan una llave de seguridad en cada una de las peticiones por lo que,
            las comunicaciones que hayan calificado en los primeros filtros, serán validadas con los
            registros almacenados comparándola con las registradas y autorizadas en la base de datos
            de nuestro aplicativo.
          </p>
          <p>
            Si una petición es correcta la información es reestructurada y el nuestro servidor crea
            una nueva petición hacia un segundo servidor.
          </p>
          <p>
            Toda la comunicación de trafico entre servidores internas es a través del protocolo
            HTTPS.
          </p>
          <p>
            También contamos con monitoreo de aplicaciones que brinda una vista estadística en vivo
            del tráfico malicioso en nuestros aplicativos. Estamos constantemente revisando y
            mejorando este monitoreo con herramientas nuevas que proporcionan un alertamiento
            inmediato.
          </p>
          <p>
            Toda anomalía detectada es revisada e investigada en todos los niveles del aplicativo
            para, de esta forma, mejorar la seguridad en todos los aspectos y garantizarle la
            seguridad a nuestros clientes.
          </p>
          <p>
            Además de los controles físicos y de servicio que tenemos implementados, también nos
            comprometemos con un sólido ciclo de vida de desarrollo de software que garantiza los
            estándares de codificación de seguridad iniciales y las pruebas se involucran en el
            ciclo de vida del desarrollo. Esto es probado regularmente por nuestro equipo de control
            de calidad. Además de estos, nos comprometemos con las pruebas regulares “PEN test” para
            garantizar que se mantengan las configuraciones y los estándares de seguridad.
          </p>
          <p>
            A continuación se muestra el ejemplo de como funciona nuestro esquema de comunicación.
          </p>
          <img
            className="img-responsive text-center flex-col col-50 col-offset-25 "
            src={esquemaComunicacion}
          ></img>
          <p>
            <string>NOTA:</string>El software de juego de los sitios operados por UNOCAPALI LA PAZ OPERADORA,
            han sido desarrollados por Pwtech, el proveedor de programas líder en la industria de
            apuestas.
          </p>
          <p>
           UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., dentro de su estructura organizacional, cuenta
            con una Unidad Central de Juegos, que le permite ejercer de manera eficaz la labor de
            inspección y control de las actividades de juego. Esta Unidad tiene como finalidad:
          </p>
          <ul>
            <li>Registrar todas las operaciones de los usuarios</li>
            <li>
              Garantizar el correcto funcionamiento de los sistemas y, si fuera necesario,
              reconstruir de manera fiable las actuaciones realizadas.
            </li>
            <li>
              Asegurar la existencia de copias de seguridad y disponer de planes de contingencia
              para la recuperación de datos.
            </li>
            <li>
              Hallarse en posesión de una réplica de la Unidad, en caso de que la principal quedara
              fuera de servicio.
            </li>
          </ul>
          <p>
            La Unidad gestiona además las operaciones de los participantes, estando integrada, a su
            vez, por la interfaz de comunicación denominada plataforma de juego y por los programas
            diseñados para los diferentes tipos de juego. Dentro de la plataforma de juego se
            incluyen las bases de datos de juego, como almacén lógico sobre datos de los
            participantes, operaciones realizadas por los juegos y otras informaciones relativas a
            resultados de eventos deportivos o sobre otros aspectos relevantes en la gestión,
            captación y cruce de apuestas, las tablas de pago, como conjunto de sistemas e
            instrumentos técnicos que permiten realizar las transacciones económicas en ambos
            sentidos, entre los participantes y el operador, a través del medio de pago utilizado
            para el cargo y abono de sus actividades.
          </p>
          <p>
            Por otra parte, se han implementado elementos que integran los sistemas técnicos del
            denominado Registro de Usuario, único para cada jugador, que permite su acceso mediante
            un proceso válido de identificación y la posibilidad de efectuar transacciones
            económicas, la cuenta de usuario, en la que se cargan los ingresos o se abonan los
            premios obtenidos durante las actividades de juego, que en ningún caso podrá tener salgo
            acreedor contra el jugador, y el generador de números aleatorios, que garantice el azar
            y la indeterminación en el resultado.
          </p>
          <p>
           UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. prohíbe el uso de prácticas injustas al utilizar
            los servicios. Hacemos esto para proteger a nuestros usuarios y la integridad de los
            servicios. Entendiéndose como prácticas injustas todas las acciones ilegales que
            disminuya su diversión al jugar en nuestra página web o que dé a otro usuario una
            ventaja injusta sobre usted cuando juegue contra dicha persona.
          </p>
          <p>
            Si descubrimos que actúa en connivencia con otros usuarios u otra actividad que
            consideremos fraudulenta o ilegal, cerraremos su cuenta permanentemente, y cualquier
            saldo será retenido y se dará aviso a las autoridades competentes.
          </p>
          <p>
            Tenemos una política de tolerancia cero en lo relativo al juego inapropiado y las
            actividades ilegales. Si descubrimos que ha hecho trampas o ha intentado realizar algún
            fraude dirigido hacia nosotros o hacia cualquier otro usuario de cualquiera de los
            servicios (incluyendo, pero sin carácter limitativo, manipulación de la partida o fraude
            en el pago, manipulación del sistema de múltiples unidades monetarias, o apostar en
            todos los resultados posibles de un partido o evento), o si sospechamos que ha realizado
            un pago fraudulento, incluyendo el uso de tarjetas de crédito robadas, o cualquier otra
            actividad fraudulenta (incluyendo, sin carácter limitativo, ingresos devueltos u otros
            rembolsos) o transacciones prohibidas (incluyendo, sin carácter limitativo, lavado de
            dinero), o si sus ingresos no son satisfechos por su banco por cualquier motivo, nos
            reservamos el derecho a suspender y/o bloquear su cuenta y a recuperar las deudas
            utilizando cualquier método legal a nuestra disposición, incluyendo, sin carácter
            limitativo, (i) cobrar de su cuenta el importe que nos debe e (ii) informar a agencias
            de cobro para que recuperen la deuda. Esto puede ejercer un impacto perjudicial en su
            calificación crediticia, y nos obligaría a compartir sus datos personales (incluyendo su
            identidad) con las autoridades competentes y a denunciar cualquier actividad delictiva o
            sospechosa a las autoridades competentes. Se reserva a la aceptación de:
          </p>
          <ul>
            <li>Aceptar depósitos provenientes de cuentas de terceros.</li>
            <li>Aceptar depósitos provenientes de cuentas extranjeras cuyo país no lo permita.</li>
            <li>Aceptar depósitos con tarjetas de crédito a nombre de terceros.</li>
            <li>Aceptar pagar retiros en cuentas de terceros.</li>
            <li>No se pagan retiros en cuentas extranjeras cuyo país no lo permita.</li>
            <li>No se pagan retiros solicitados a fondos de caridad.</li>
          </ul>
          <p>
            Nos reservamos el derecho a anular y retener cualquier ganancia obtenida por una persona
            o grupo de personas y a anular y retener sus puntos de usuario si tenemos razones
            fundadas para creer que se está actuando o han actuado para cometer fraude o
            perjudicarnos de alguna manera a nosotros y/o a los servicios y/o al Sistema Central de
            Apuestas Vía Internet.
          </p>
          <p>
            El siguiente documento describe las características de los controles de seguridad del o
            los sitios web operados por “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, que han sido
            implementadas para proteger la integridad, disponibilidad y confiabilidad.
          </p>
          <p>
            De conformidad con el artículo 85 del Reglamento de la Ley Federal de Juegos y Sorteos,
            los establecimientos podrán captar apuestas vía internet, telefónica o electrónica. Para
            ello, deberá establecerse un sistema de control interno para las transacciones que se
            efectúen por estas vías, elaborando por escrito la descripción de los procedimientos y
            reglas que aseguren la inviolabilidad e impidan la manipulación de los sistemas de
            apuestas. En dicho sistema deberá quedar registrado, al menos:
          </p>
          <ul>
            <li>I. El número de la cuenta e identidad del apostador,</li>
            <li>
              II. La fecha, hora, número de la transacción, cantidad apostada y selección
              solicitada.
            </li>
          </ul>
          <p>
            De conformidad con el artículo 87, fracción II del Reglamento de la Ley Federal de
            Juegos y Sorteos, el Operador “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, deberá cumplir
            con el siguiente procedimiento para la expedición de los comprobantes de apuestas:
          </p>
          <ol>
            <li>
              <p>
                No se emitirá boleto alguno, pero la información (número de serie, y un número
                distinto por cada, además de la fecha y hora de expedición, monto apostado, tipo de
                apuesta y selección) de dicha apuesta quedará registrada en el sistema central de
                apuestas inmediatamente después de haber pagado la apuesta.{' '}
              </p>{' '}
              <p>
                Los participantes deberán tener acceso, para consulta e impresión, a una constancia
                de su número de folio y de los derechos que les correspondan{' '}
              </p>
            </li>
            <li>
              Cuando al emitir un comprobante de apuesta se produjera un error en la máquina
              expendedora de boletos, se procederá a cancelarlo de manera administrativa;{' '}
            </li>
            <li>
              Los comprobantes únicamente podrán ser expedidos en los horarios y sitios designados o
              autorizados en el permiso, pudiendo expedir el permisionario comprobantes en forma
              anticipada, y{' '}
            </li>
            <li>
              {' '}
              Los comprobantes de apuesta deberán ser pagados al momento de que sean solicitados
              mediante de medios de pago legalmente aceptados.{' '}
            </li>
          </ol>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col ">
          <h1 className="flex-col" style={{ fontSize: '130%' }}>
            <u>CONTROLES DE SEGURIDAD IMPLEMENTADOS</u>
          </h1>
          <ol>
            <li>
              <strong>Disponibilidad</strong>
            </li>
            <p>
              El centro de datos en donde se almacena el servidor del sitio o sitios web operados
              por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., contienen medidas para garantizar la
              seguridad y confianza, tales como:
            </p>
            <ol>
              <li>
                Enlaces de datos múltiples de proveedores distintos para mitigar el riesgo de un
                punto único de falla.
              </li>
              <li>El o los sitios web, son servidos por un clúster de múltiples servidores.</li>
            </ol>
            <li>
              <strong>Confidencialidad</strong>
            </li>
            <p>
              El acceso a la información del sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. de C.V.,
              están protegidos por distintos controles, que se pueden
              resumir en lo siguiente:
            </p>
            <ol>
              <li>
                El acceso a la cuenta de un cliente está protegido mediante un esquema de datos de
                registro. Solamente clientes que tengan dichos datos podrán ingresar a su cuenta.
              </li>
              <li>
                El sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.,
                están asociados a un certificado digital vigente con un esquema PKI de llave RSA de
                2048 bits y/o Elliptic Curve Public Key 256 bits. Cabe destacar que el uso de llaves
                RSA 2048 son recomendados por NIST en su publicación: “SP 800-57 Part 3”.
              </li>
              <li>
                Cualquier acceso al servidor del sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. de C.V.,
                se encuentran restringidos por los siguientes controles:
              </li>
              <ol type="I">
                <li>
                  Físicos.- Los servidores se encuentran en un centro de datos con acceso
                  restringido mediante controles de autorización de acceso de tipo biométricos.
                </li>
                <li>
                  Procedimentales.- El acceso de los servidores tanto lógico como físico requiere
                  del cumplimiento estricto de procesos de solicitud de acceso. Estos procesos
                  contemplan la aprobación de personal clave dentro de la compañía.
                </li>
                <li>
                  Lógicos.- El acceso lógico a los servidores está restringido por esquemas de
                  autentificación de usuarios. Esto aplica obsesivamente al personal de TI que los
                  administra.
                </li>
              </ol>
            </ol>
            <li>
              <strong>Integridad</strong>
            </li>
            <p>
              La integridad del sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.,
              está garantizada por los siguientes controles y mecanismos:
            </p>
            <ol type="a">
              <li>
                El sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., se
                encuentran protegidos por un proxy web application firewall. Este mecanismo permite
                verificar la racionalidad de las solicitudes que recibe el sitio.
              </li>
              <li>
                El certificado de firma digital del sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. de C.V.,
                están constituidos mediante el uso del algoritmo
                SHA256. Este algoritmo criptográfico es ampliamente recomendado por el NIST en su
                publicación: SP 800-57 Part 3.
              </li>
            </ol>
            <p>
              El sistema de control interno utilizado satisface los requisitos de inviolabilidad e
              impide la manipulación de los sistemas de apuestas, asegurando que en dicho sistema
              queda registrada la siguiente información:
            </p>
            <ol type="a">
              <li>El número de cuenta e identidad del apostador,</li>
              <li>
                La fecha, hora, número de transacción, cantidad apostada y selección solicitada.
              </li>
            </ol>
          </ol>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col ">
          <h1 className="flex-col" style={{ fontSize: '130%' }}>
            <u>DISPOSICIONES GENERALES</u>
          </h1>
          <p>El usuario se deberá sujetar a lo siguiente:</p>
          <ol>
            <li>
              <strong>Identidad y Uso</strong>
            </li>
            <p>
              Para poder utilizar los servicios del sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. de C.V.,
              el usuario deberá declarar lo siguiente:{' '}
            </p>
            <ol type="a">
              <li>Que tiene 18 años de edad, o más.</li>
              <li>Que reside en los Estados Unidos Mexicanos.</li>
              <li>
                Que la información personal que ha proporcionado, es verdadera, correcta,
                actualizada y completa
              </li>
              <li>Que es el titular o el usuario autorizado del método de pago proporcionado;</li>
              <li>Si está utilizando la cuenta de usuario, ser el titular de dicha cuenta, y</li>
              <li>
                Actualizará puntualmente su cuenta, cualquier modificación introducida en su
                información personal o en el método de pago, que resulte necesaria para la
                presentación de los servicios que UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.
              </li>
            </ol>
            <li>
              <strong>Confirmación de la Identidad</strong>
            </li>
            <p>
              El usuario autoriza a “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, a realizar las
              investigaciones adicionales que considere necesarias para confirmar su identidad. Esta
              medida puede incluir la petición de información adicional o la solicitud de medidas
              adicionales dirigidas a confirmar la información que se haya proporcionado.
            </p>
            <li>
              <strong>Utilización de Información Personal</strong>
            </li>
            <p>
              Durante los procesos de registros y recarga de los servicios en el sitio o los sitios
              web operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., el usuario necesitará
              proporcional información personal, tal como nombre, dirección, nombre, dirección,
              número de teléfono, entre otros
            </p>
            <li>
              <strong>Nombre de Usuario y Contraseña</strong>
            </li>
            <p>
              Para poder utilizar los servicios una persona, deberá crear una cuenta dentro del
              sitio o los sitios web operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., con un
              nombre de usuario y una contraseña. El usuario será responsable de lo siguiente:
            </p>
            <ol type="a">
              <li>Elegir una contraseña que no resulte sencilla de adivinar por otros;</li>
              <li>Mantener la seguridad de su nombre de usuario y contraseña, y</li>
              <li>Recordar su nombre de usuario y contraseña.</li>
            </ol>
            <p>
              Asimismo, será responsable de todas las actividades realizadas en su cuenta. En tal
              sentido, se compromete a notificar inmediatamente de cualquier cambio, modificación, o
              sospecha de cualquier actividad atípica observada en su cuenta.
            </p>
            <p>
              De igual forma en el uso y manejo de la tarjeta prepagada del usuario para uso en
              nuestro sitio, en caso de pérdida o extravío deberá de reportarlo de manera inmediata,
              entendiendo que por este hecho no se reembolsará el valor de la misma por lo que fue
              activada. Las medidas que se podrán tener serán, entre otras, la suspensión o
              cancelación de la cuenta, la solicitud de modificación de la clave o la petición de
              información adicional dirigida a confirmar la identidad.
            </p>
            <p>
              Con independencia de lo anterior, UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., podrá
              confiar en la legitimidad de la persona que acceda a la cuenta del usuario o que
              utilice su contraseña y no asumirá responsabilidad alguna ante el usuario ni ante
              terceros derivada de las medidas tomadas o no conforme a lo dispuesto en esta
              cláusula, de los compromisos asumidos sobre la seguridad de la cuenta o de la
              contraseña, del acceso no autorizado a la cuenta, ni el uso de la contraseña.
            </p>
            <p>
              Al decidir si acepta su inscripción, “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, podrá
              suministrar la información que el usuario ha proporcionado, a las agencias de
              referencia de crédito autorizadas para confirmar su identidad y datos de la tarjeta o
              método de pago. Lo anterior, a efecto de comprobar cualquier detalle contra las bases
              de datos (públicas o privadas) a los que tienen acceso, y se mantendrá un registro de
              dicha verificación.
            </p>
            <p>
              El usuario acepta que “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.” pueda procesar,
              utilizar, registrar y divulgar la información personal que usted proporcione en
              relación con su registro, y que tales datos personales pueden ser grabadas. Para su
              protección, las llamadas telefónicas a los servicios al cliente pueden ser grabadas y
              supervisadas.
            </p>
            <p>
              “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.” no abrirá una cuenta a menos que estemos
              convencidos, actuando de manera razonable, que el usuario tiene 18 años o más.
            </p>
            <p>
              “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.” tiene el derecho de verificar cualquier
              información que el usuario le proporcione cuando se abre una cuenta, para asegurarse
              de que son mayores de 18 años.
            </p>
            <p>
              El usuario no será capaz de retirar sus ganancias hasta que el proceso de verificación
              se haya completado satisfactoriamente. En cualquier caso, si el usuario no ha podido
              completar satisfactoriamente nuestros controles de verificación de edad, “CAPALI LA
              PAZ OPERACIONES, S.A. de C.V.”, tiene el derecho de congelar su cuenta, se evitará
              además, que siga apostando hasta que haya completado satisfactoriamente estos
              controles y, si al término de verificación de la edad se le muestra que es menor de
              edad, le devolveremos todas las apuestas, pero bajo ninguna obligación de pagar las
              ganancias.
            </p>
            <p>
              Tendremos derecho de informar a las autoridades pertinentes, otros operadores de
              apuestas deportivas en línea, otros proveedores de servicios online y bancos,
              compañías de tarjetas de crédito, proveedores de pagos electrónicos u otras
              instituciones financieras de su identidad y de cualquier actividad ilegal, fraudulenta
              o indebida sospecha, y usted cooperará completamente con nosotros para investigar
              cualquier actividad.
            </p>
            <li>
              <strong>Uso de los servicios</strong>
            </li>
            <p>
              “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, concede al usuario una licencia no
              exclusiva, personal e intransferible para el servicio de juego por internet. El
              usuario puede utilizar el software en cualquier computadora en el que sea el usuario
              primario.
            </p>
            <p>
              “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, se reserva el derecho a cancelar esta
              licencia en cualquier momento sin previo aviso.
            </p>
            <p>
              El usuario no está autorizado a copiar el software o los materiales escritos asociados
              al tema de casino. La licencia no debe ser asignada o transferida a ninguna otra
              persona que no sea el usuario original. El usuario no está autorizado a sublicenciar,
              asignar o transferir esta licencia ni a alquilar, arrendar o ceder ninguna parte del
              sistema de casino.
            </p>
            <p>
              El usuario acepta que él es único responsable de cualquier tipo de uso del sistema de
              casino on line de la empresa, que se produzca mediante su nombre de usuario y
              contraseña. El usuario se compromete a mantener su nombre de usuario y contraseña en
              secreto y a tratarlos con la máxima confidencialidad. Cualquier uso no autorizado del
              nombre de usuario y la contraseña, será responsabilidad exclusiva del usuario, y será
              considerado como su uso. Cualquier responsabilidad que resulte de dicho uso recaerá
              sobre el usuario.
            </p>
            <p>
              El dinero, en la cuenta del usuario se utiliza para realizar apuestas en los juegos
              vía internet. La empresa operadora se reserva el derecho a rechazar o limitar
              cualquier apuesta. El usuario no está autorizado a efectuar una apuesta cuyo importe
              sea superior al saldo disponible en su cuenta. Todas las ganancias que consiga el
              usuario serán abonadas en su cuenta personal. Los ingresos y pagos se realizarán a
              través del cajero del Sistema de Apuestas. La empresa se reserva el pago de cantidades
              que considere que no hayan sido ingresadas por el sistema de prepago.
            </p>
            <p>
              El usuario acepta y autoriza que la sociedad asigne el procesamiento de ingresos y
              pagos en su cuenta a la compañía de gestión de pagos de la sociedad. En virtud de
              ello, el usuario autoriza a la sociedad a dar dicho tipo de instrucciones en su nombre
              de usuario. “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, se reserva el derecho a retener
              cualquier pago si sospecha o tiene alguna evidencia de que el sistema de captación de
              apuestas en línea ha sido manipulado. Se demandará cualquier usuario u otro individuo
              que haya manipulado o haya intentado manipular el sistema de casino de la sociedad
              para garantizar un elevado nivel de seguridad a la hora de proteger el saldo de la
              cuenta del usuario, “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, realiza controles de
              seguridad aleatorios. Por lo que “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, podrá
              exigir al usuario documentos adicionales para verificar su identidad, así como para
              verificar la titularidad de los medios de pago, como medidas de seguridad.
            </p>
            <li>
              <strong>Importe de la apuesta</strong>
            </li>
            <p>
              Cada usuario determinará el valor de su apuesta según el límite de ésta en cada juego
              en particular. Los requisitos realmente en un juego dependerán totalmente de la
              condición de que el usuario disponga en su cuenta de la cantidad que desea apostar. Si
              no dispone de dicha cantidad, el usuario recibirá un mensaje de error que indicará que
              no cuenta con el balance suficiente para participar en el juego. Como apuesta es
              válido exclusivamente el importe confirmado y registrado por la empresa. Si pierde su
              conexión de internet mientras juega con dinero real, o el juego se interrumpe por
              cualquier otra razón, dependiendo el juego en el que participe y de la fase en que se
              encuentre éste, o bien el importe de su apuesta es devuelta a su cuenta o bien
              continuará el juego exactamente donde lo dejó cuando vuelva a hacer el login.
            </p>
            <li>
              <strong>Modalidades de Pago</strong>
            </li>
            <p>
              “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, ofrece a sus usuarios varios métodos
              diferentes para hacer un depósito o un retiro de una cuenta.
            </p>
            <p>
              Al abrir una cuenta con una tarjeta de débito o de crédito, el usuario debe asegurarse
              de que utiliza su propia tarjeta. Tenemos derecho a suponer que el usuario es el
              titular.
            </p>
            <ol type="A">
              <li>
                Tarjetas de Crédito. El uso de tarjetas de crédito se sujetará a lo establecido en
                éste capítulo y en relación con su emisor, se regirá según lo pactado en el
                respectivo contrato de apertura que el usuario hubiere oportunamente suscrito. En
                este sentido, los aspectos relativos a las mismas, tales como fecha de emisión,
                caducidad, cupo, bloqueos, etc., se regularán por el respectivo contrato de
                apertura, no teniendo “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, responsabilidad
                alguna sobre dichos aspectos.
              </li>
              <li>
                TRANSFERENCIAS SPEI: Se podrán realizar depósitos a la cuenta o wallet electrónico
                del usuario a través de una transferencia SPEI a una cuenta operada por PWTECH, en
                el momento de recibir confirmación de la transferencia su abono será reflejado en la
                cuenta o wallet del usuario. Este servicio es operado por Openpay y el tiempo
                esperado para recibir notificación del pago es de hasta 48 horas hábiles.
              </li>
              <li>
                PAYNET: Los usuarios podrán generar una ficha de depósito que podrán utilizar para
                pagar en establecimientos físicos como seven eleven, farmacias del ahorro, sams club
                entre otros. Una vez hecho el pago con el cajero del establecimiento se notificará
                al sitio web www.pickwin.mx y se abonará en su cuenta o wallet electrónico. El
                servicio operado por Openpay y el tiempo esperado para recibir notificación del pago
                es de hasta 48 horas hábiles.
              </li>
              <li>
                TODITO CASH: Nuestros usuarios pueden realizar depósitos a su cuenta utilizando el
                servicio de ToditoCash. Las tarjetas ToditoCash se pueden adquirir en la red de
                puntos de venta (Oxxo, 7Eleven, Elektra, Banco Azteca) El Establecimiento y Todito
                Cash le cobrarán una comisión por el servicio prestado. CONSULTE LAS COMISIONES
                TODITO CASH
              </li>
            </ol>
            <p>La elección del medio de pago es exclusiva potestad del usuario.</p>
            <p>
              Así como cualquier otro proceso de verificación “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”
              puede emprender, respecto de cualquier titular de la cuenta que depositan de
              tarjetas de débito y de crédito pueden ser obligados a presentar pruebas adicionales
              de su identidad para ayudar en la verificación de edad antes de hacer su primer
              retiro.
            </p>
            <p>
              Mientras que estos controles adicionales no se requieren generalmente para los
              usuarios de tarjetas de crédito, nos reservamos el derecho de solicitar dicha
              documentación antes de procesar un retiro. Por lo tanto, nos reservamos el derecho de
              solicitar una prueba específica de la identidad incluyendo pero no limitado a el
              número de pasaporte, fotocopia de un documento de identidad válido.
            </p>
            <p>
              Todos los documentos solicitados que deberán ser enviadas por correo, escanear, enviar
              por correo-e o por fax a nosotros en la dirección y los números proporcionados en el
              sitio o sitio webs operados por “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”
            </p>
            <p>
              En el caso de que una suma erróneamente acreditado a su cuenta, tendremos derecho de
              hacer el ajuste apropiado para ello.
            </p>
            <p>
              Si cualquier suma se acredita incorrectamente a su cuenta, está obligado a notificar a
             UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., y UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.
              tiene derecho a la reversión de tales créditos y/o recuperar las sumas de usted (con
              intereses) si se retira. Si utiliza las sumas indebidamente abonadas en realizar
              apuestas, UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. puede anular (es decir, cancelar)
              todas esas apuestas y revertir cualquier ganancia.
            </p>
            <p>
              Los usuarios pueden retirar fondos de su cuenta en cualquier momento la prestación de
              todos los pagos han sido confirmadas y previstas han satisfecho nuestros
              procedimientos de identificación.
            </p>
            <li>
              <strong>Impuestos</strong>
            </li>
            <p>
              Cuando se gana usando nuestros productos o servicios, es posible que legalmente el
              usuario esté obligado a rendir cuentas a las autoridades fiscales competentes. Esto
              sigue siendo su responsabilidad y no estamos obligados a rendir cuentas a autoridad
              alguna para ninguna de sus impuestos personales. El usuario indemnizará y nos
              reembolsará los costos, gastos o pérdidas que se puedan causar a nosotros como
              resultado de cualquier reclamo o demanda hecha por cualquier autoridad gubernamental u
              otra, en lo que respecta a las obligaciones de retención de impuestos u obligaciones
              similares a las que puede estar sujeto en relación con el procesamiento de sus
              solicitudes de retiro.
            </p>
            <p>
              El usuario será responsable de la totalidad de impuestos gravados sobre la utilización
              y beneficios del juego en línea, de la recepción de fondos en la cuenta del usuario, y
              del uso de la misma.
            </p>
            <p>
              “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.” no cobra ni obtiene ganancias por depósitos
              por transferencia bancaria, débito o tarjetas de crédito, a pesar de que los clientes
              o usuarios deben tener en cuenta que algunas compañías de tarjetas de crédito
              categorizan las transacciones de apuestas como “efectivo” y pueden cobrar un cargo de
              interés fijo y/o desde la fecha de pago.
            </p>
            <p>
              Retiros mediante transferencia bancaria a las cuentas bancarias internacionales
              (depósitos y retiros) los gastos bancarios, que pueden variar de acuerdo a la cantidad
              de dinero transferido y se cumplen por parte del usuario.
            </p>
            <li>
              <strong>Resolución</strong>
            </li>
            <p>
              En cualquier momento “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.” podrá suspender o
              cancelar la utilización del servicio por cualquier motivo legalmente no permitido, por
              ejemplo, ante la sospecha de un posible fraude o de una actividad atípica, o bien por
              razones de seguridad.
            </p>
            <p>
             UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. tendrá el derecho de retener cualquier retiro,
              en el caso que creamos o sospechemos que usted puede estar involucrado o ha estado
              involucrado en actividades fraudulentas, colusorias, ilegales, apuestas irregulares o
              si nos preocupa la operación de la cuenta del cliente o de la solicitud de retiro. En
              tales casos, podremos comenzar y/o participar y/o asistir a cualquier investigación
              acerca de tales circunstancias.
            </p>
            <li>
              <strong>Juego Responsable</strong>
            </li>
            <p>
             UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., comprometido con la sociedad y con el Juego
              Responsable, está dedicado a ofrecer una experiencia de entretenimiento y diversión,
              positiva para todos nuestros usuarios.
            </p>
            <p>
              Si bien la mayoría de los clientes son capaces de disfrutar su juego, reconocemos que
              para un número muy pequeño de clientes de juego deja de ser divertido. Para aquellos
              clientes que deseen restringir su juego, proporcionamos un centro de auto-exclusión.
            </p>
            <p>
              Que consiste en tener controles y garantías razonables implementados para asegurar
              que, mientras un usuario se encuentre excluido no pueda accesar a nuestro sitio web
              (s) y no recibirá material publicitario sobre juegos y nosotros no podemos ser
              responsables ante él o cualquier tercero si continua jugando en nuestro sitio (s), o
              si cualquier material publicitario es enviado sin darnos cuenta, y si continúa
              apostando por otros sitios de cualquier parte o establecimientos.
            </p>
            <p>
             UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. unirá todos los esfuerzos razonables para
              asegurar el cumplimiento con la auto-exclusión. Sin embargo, no tenemos ninguna
              responsabilidad de ningún tipo si el usuario continúa jugando a través de cuentas
              adicionales en la que ha cambiado cualquiera de los datos de registro (que también
              incluiría si abre una cuenta con sustancialmente la misma información, aunque
              introduzcan en el registro de manera diferente).
            </p>
            <li>
              <strong>Jurisdicción</strong>
            </li>
            <p>
              El software de juego on line, solamente aplicará para usuarios que se encuentren
              dentro de los Estados Unidos Mexicanos, cualquier otro país queda fuera de nuestra
              responsabilidad, y en caso de detectar que esto sea así, será causal suficiente para
              dar de baja la cuenta del usuario.
            </p>
            <li>
              <strong>Transacciones en Moneda Nacional</strong>
            </li>
            <p>
              Las operaciones de juegos con apuestas y sorteos realizados en el o los sitios web
              operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., deberán efectuarse en moneda
              nacional (pesos mexicanos).
            </p>
          </ol>
        </div>
      </div>
    </div>
  );
};
const SistemaControlInterno = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>DESCRIPCIÓN DEL SISTEMA DE CONTROL INTERNO PARA LAS TRANSACCIÓNES VÍA INTERNET</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>INTRODUCCIÓN </h4>
          <p>
            El siguiente documento describe las características de los controles de seguridad de los
            sitios operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., que han sido implementadas
            para proteger la integridad, disponibilidad y confiabilidad, de www.pickwin.mx.
          </p>
          <p>
            De conformidad con el artículo 85 del Reglamento de la Ley Federal de Juegos y Sorteos,
            se dice que los establecimientos podrán captar apuestas vía Internet, o electrónica.
          </p>
          <p>
            Para ello, deberán establecer un sistema de control interno para las transacciones que
            se efectúen por estas vías, elaborando por escrito la descripción de los procedimientos
            y reglas que aseguren la inviolabilidad e impidan la manipulación de los sistemas de
            apuestas. En dicho sistema deberá quedar registrado, al menos:
          </p>
          <ol type="I">
            <li>El número de la cuenta e identidad del apostador, y</li>
            <li>
              La fecha, hora, número de la transacción, cantidad apostada y se ección solicitada.
            </li>
          </ol>
          <p>La mecánica de captación de apuestas se desarrollará más adelante,</p>
          <p>
            De conformidad con el artículo 87 fraccion II del propio Reglamento de la Ley Federal de
            Juegos y Sorteos, el Operador deberá cumplir con el siguiente procedimiento para la
            expedición de los comprobantes de apuesta:
          </p>
          <ol type="I">
            <li>
              En las apuestas vía Internet, o electrónica no se emitirá boleto alguno, pero la
              información de dicha apuesta quedará registrada en el sistema central de apuestas
              inmediatamente después de haber pagado la apuesta, Tratándose de apuestas vía Internet
              o electrónica, los participantes deberán tener acceso, para consulta e impresión, a
              una constancia de su número de folio y de los derechos que les correspondan.
            </li>
          </ol>
          <h4>CONTROLES DE SEGURIDAD IMPLEMENTADOS</h4>
          <ol>
            <li>
              <strong>DISPONIBILIDAD</strong>
              <p>
                El centro de datos en donde se almacena el servidor de los sitios web web
                www.ganamex.mx, www.gallomx.com, www.casinoval.mx, www.pickwin.mx, www.lbsbet.mx,
                entre otros, operados por UNOCAPALI LA PAZ OPERADORA contiene medidas para garantizar
                la seguridad y confianza, como:
              </p>
              <ol type="a">
                <li>
                  Enlaces de datos múltiples de proveedores distintos para mitigar el riesgo de un
                  punto único de falla.
                </li>
                <li>
                  El sitio web www.pickwin.mx, entre otros, operados por UNOCAPALI LA PAZ OPERADORA
                  es servido por un clúster de múltiples servidores.
                </li>
              </ol>
            </li>
            <li>
              <strong>CONFIDENCIALIDAD</strong>
              <p>
                El acceso a la información de los sitios web operados por UNOCAPALI LA PAZ OPERADORA
                están protegido por distintos controles, que se pueden resumir en lo siguiente:
              </p>
              <ol type="a">
                <li>
                  El acceso a la cuenta de un cliente está protegido mediante un esquema de datos de
                  registro. Solamente clientes que tengan dichos datos podrán ingresar a su cuenta.
                </li>
                <li>
                  El sitio web de www.ganamex.mx, www.gallomx.com, www.casinoval.mx, www.pickwin.mx,
                  www.lbsbet.mx, entre otros, operados por UNOCAPALI LA PAZ OPERADORA están asociados
                  a un certificado digital vigente con un esquema PKI de llave RSA de 2048 bits y/o
                  Elliptic Curve Public Key 256 bits. Cabe destacar que el uso de llaves RSA 2048
                  son recomendadas por NIST en su publicación: "SP 800-57 Part 3"
                </li>
                <li>
                  Cualquier acceso al servidor web: www.ganamex.mx, www.gallomx.com,
                  www.casinoval.mx, www.pickwin.mx, www.lbsbet.mx, entre otros, operados por UNOCAPALI LA PAZ OPERADORA S.A. DE C.V,
                  se encuentran restringidos por los siguientes controles:
                </li>
                <ol type="i">
                  <li>
                    Físicos: Los servidores se encuentran en un centro de datos con acceso
                    restringido mediante controles de autorización de acceso de tipo biométricos.
                  </li>
                  <li>
                    Procedimentales: El acceso de los servidores tanto lógico como físico requiere
                    del cumplimiento estricto de procesos de solicitud de acceso. Estos procesos
                    contemplan la aprobación de personal clave dentro de la compañía.
                  </li>
                  <li>
                    Lógicos: El acceso lógico a los servidores está restringido por esquemas de
                    autentificación de usuarios. Esto aplica obsesivamente al personal de TI que los
                    administra.
                  </li>
                </ol>
              </ol>
            </li>
            <li>
              <strong>INTERIDAD</strong>
              <p>
                La integridad de los sitios web operados por UNOCAPALI LA PAZ OPERADORA está
                garantizada por los siguientes controles y mecanismos:
              </p>
              <ol type="a">
                <li>
                  El sitio web: www.ganamex.mx, www.gallomx.com, www.casinoval.mx, www.pickwin.mx,
                  www.lbsbet.mx, entre otros, operados por UNOCAPALI LA PAZ OPERADORA se encuentran
                  protegidos por un proxy que encarga de monitorear peticiones sospechosas, actúa
                  como Firewall antes de los servidores y filtra acciones perniciosas.
                </li>
                <li>
                  El sitio web: www.ganamex.mx, www.gallomx.com, www.casinoval.mx, www.pickwin.mx,
                  www.lbsbet.mx, entre otros, operados por UNOCAPALI LA PAZ OPERADORA se encuentran
                  protegidos por un proxy que encarga de monitorear peticiones sospechosas, actúa
                  como Firewall antes de los servidores y filtra acciones perniciosas.
                </li>
                <p>
                  El sistema de control interno utilizado satisface los requisitos de inviolabilidad
                  e impide la manipulación de los sistemas de apuestas, asegurando que en dicho
                  sistema queda registrada la siguiente información:
                </p>
                <ol type="i">
                  <li>El número de la cuenta e identidad del apostador, y</li>
                  <li>
                    la fecha, hora, número de transacción, cantidad apostada y selección solicitada.
                  </li>
                </ol>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col ">
          <h1 className="flex-col" style={{ fontSize: '130%' }}>
            <u>DISPOSICIONES GENERALES</u>
          </h1>
          <p>El usuario se deb erá sujetar a lo siguiente:</p>
          <ol>
            <li>
              <strong>Identidad y Uso</strong>
            </li>
            <p>
              Para poder utilizar los servicios del sitio o los sitios web operados por
              UNOCAPALI LA PAZ OPERADORA S.A. DE C.V,
              el usuario deberá declarar lo siguiente:{' '}
            </p>
            <ol type="a">
              <li>Que tiene 18 años de edad, o más.</li>
              <li>Que reside en los Estados Unidos Mexicanos.</li>
              <li>
                Que la información personal que ha proporcionado, es verdadera, correcta,
                actualizada y completa
              </li>
              <li>Que es el titular o el usuario autorizado del método de pago proporcionado;</li>
              <li>Si está utilizando la cuenta de usuario, ser el titular de dicha cuenta, y</li>
              <li>
                Actualizará puntualmente su cuenta, cualquier modificación introducida en su
                información personal o en la tarjeta de crédito, que resulte necesaria para la
                presentación de los servicios que ofrece UNOCAPALI LA PAZ OPERADORA, en cualquiera de
                los sitios web a ésta autorizados.
              </li>
            </ol>
            <li>
              <strong>Confirmación de la Identidad</strong>
            </li>
            <p>
              El usuario autoriza a “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, a realizar las
              investigaciones adicionales que considere necesarias para confirmar su identidad. Esta
              medida puede incluir la petición de información adicional o la solicitud de medidas
              adicionales dirigidas a confirmar la información que se haya proporcionado.
            </p>
            <li>
              <strong>Utilización de Información Personal</strong>
            </li>
            <p>
              Durante los procesos de registros y recarga de los servicios en los sitios web
              operados por UNOCAPALI LA PAZ OPERADORA, el usuario necesitará proporcionar información
              personal, tales como, nombre, dirección, número de teléfono, entre otros. El usuario
              otorga su consentimiento a compartir esta información personal con los proveedores de
              servicio para fines de comercialización.
            </p>
            <li>
              <strong>Nombre de Usuario y Contraseña</strong>
            </li>
            <p>
              Para poder utilizar los servicios una persona, deberá crear una cuenta dentro del
              sitio web: www.ganamex.mx, www.gallomx.com, www.casinoval.mx, www.pickwin.mx,
              www.lbsbet.mx, o cualquier otro operados por UNOCAPALI LA PAZ OPERADORA, con un nombre
              de usuario, correo electrónico y una contraseña. El usuario será responsable de lo
              siguiente:
            </p>
            <ol type="a">
              <li>Elegir una contraseña que no resulte sencilla de adivinar por otros;</li>
              <li>Mantener la seguridad de su nombre de usuario y contraseña, y</li>
              <li>Recordar su nombre de usuario y contraseña.</li>
            </ol>
            <p>
              Asimismo, será responsable de todas las actividades realizadas en su cuenta. En tal
              sentido, se compromete a notificar inmediatamente de cualquier cambio, modificación, o
              sospecha de cualquier actividad atípica observada en su cuenta.
            </p>
            <p>
              De igual forma en el uso y manejo de la tarjeta prepagada del usuario para uso en
              nuestro sitio, en caso de pérdida o extravío deberá de reportarlo de manera inmediata,
              entendiendo que por este hecho no se reembolsará el valor de la misma por lo que fue
              activada. Las medidas que se podrán tener serán, entre otras, la suspensión o
              cancelación de la cuenta, la solicitud de modificación de la clave o la petición de
              información adicional dirigida a confirmar la identidad.
            </p>
            <p>
              Con independencia de lo anterior, www.ganamex.mx, www.gallomx.com, www.casinoval.mx,
              www.pickwin.mx, www.lbsbet.mx, entre otros sitios web, operados por UNOCAPALI LA PAZ OPERADORA,
              podrá confiar en la legitimidad de la persona que acceda a la cuenta del
              usuario 0 que utilice su contraseña y no asumirá responsabilidad alguna ante el
              usuario ni ante terceros derivada de las medidas tomadas o no conforme a lo dispuesto
              en esta cláusula, de los compromisos asumidos sobre la seguridad de la cuenta o de la
              contraseña, del acceso no autorizado a la cuenta ni el uso de la contraseña.
            </p>
            <li>
              <strong>Uso de los servicios</strong>
            </li>
            <p>
             UNOCAPALI LA PAZ OPERADORA, concede al usuario una licencia no exclusiva, personal e
              intransferible para el servicio de juego por internet. El usuario puede utilizar el
              software en cualquier computadora en el que Sea el usuario primario.
            </p>
            <p>
             UNOCAPALI LA PAZ OPERADORA se reserva el derecho a cancelar esta licencia en cualquier
              momento Sin previo aviso.
            </p>
            <p>
              El usuario no está autorizado a copiar el software o los materiales escritos asociados
              al tema de casino. La licencia no debe ser asignada o transferida a ninguna otra
              persona que no sea el usuario original. El usuario no está autorizado a sublicenciar,
              asignar o transferir esta licencia ni a alquilar, arrendar o ceder ninguna parte del
              sistema de casino.
            </p>
            <p>
              El usuario acepta que él es único responsable de cualquier tipo de uso del sistema de
              casino on line de la empresa, que se produzca mediante su nombre de usuario y
              contraseña. El usuario se compromete a mantener su nombre de usuario y contraseña en
              secreto y a tratarlos con la máxima confidencialidad. Cualquier uso no autorizado del
              nombre de usuario y la contraseña, será responsabilidad exclusiva del usuario, y será
              considerado como su uso. Cualquier responsabilidad que resulte de dicho uso recaerá
              sobre el usuario.
            </p>
            <p>
              El dinero, en la cuenta del usuario se utiliza para realizar apuestas en los juegos
              vía internet. La empresa operadora se reserva el derecho a rechazar o limitar
              cualquier apuesta. El usuario no está autorizado a efectuar una apuesta cuyo importe
              sea superior al saldo disponible en su cuenta. Todas las ganancias que consiga el
              usuario serán abonadas en su cuenta personal. Los ingresos y pagos se realizarán a
              través del cajero del Sistema de Apuestas. La empresa se reserva el pago de cantidades
              que considere que no hayan sido ingresadas por el sistema de prepago.
            </p>
            <p>
              El usuario acepta y autoriza que la sociedad asigne el procesamiento de ingresos y
              pagos en su cuenta a la compañía de gestión de pagos de la sociedad. En virtud de
              ello, el usuario autoriza a la sociedad a dar dicho tipo de instrucciones en su nombre
              de usuario. La sociedad se reserva el derecho a retener cualquier pago si sospecha 0
              tiene alguna evidencia de que el sistema de casino de la sociedad ha sido manipulado.
              Se demandará cualquier usuario u otro individuo que haya manipulado o haya intentado
              manipular el sistema de casino de la sociedad para garantizar un elevado nivel de
              seguridad a la hora de proteger el saldo de la cuenta del usuario, UNOCAPALI LA PAZ OPERADORA,
              realiza controles de seguridad aleatorios. Por el presente el usuario
              acepta que la sociedad le exija documentos adicionales para verificar su identidad
              como el titular de una tarjeta de crédito en el supuesto caso de un control de
              seguridad.
            </p>
            <li>
              <strong>Importe de la apuesta</strong>
            </li>
            <p>
              Cada usuario determinará el valor de su apuesta según el límite de ésta en cada juego
              en particular. Los requisitos realmente en un juego dependerán totalmente de la
              condición de que el usuario disponga en su cuenta de la cantidad que desea apostar. Si
              no dispone de dicha cantidad, el usuario recibirá un mensaje de error que indicará que
              no cuenta con el balance suficiente para participar en el juego. Como apuesta es
              válido exclusivamente el importe confirmado y registrado por la empresa. Si pierde su
              conexión de internet mientras juega con dinero real, o el juego se interrumpe por
              cualquier otra razón, dependiendo el juego en el que participe y de la fase en que se
              encuentre éste, o bien el importe de su apuesta es devuelta a su cuenta o bien
              continuará el juego exactamente donde lo dejó cuando vuelva a hacer el login.
            </p>
            <li>
              <strong>Modalidades de Pago</strong>
            </li>
            <p>
             UNOCAPALI LA PAZ OPERADORA, ofrece a su usuario distintas modalidades de pago para la
              utilización de los servicios con el fin de satisfacer las necesidades de los
              diferentes segmentos del mercado:
            </p>
            <ol type="A">
              <li>
                Tarjetas de Crédito. El uso de tarjetas de crédito se sujetará a lo establecido en
                éste capítulo y en relación con su emisor, se regirá según lo pactado en el
                respectivo contrato de apertura que el usuario hubiere oportunamente suscrito. En
                este sentido, los aspectos relativos a las mismas, tales como fecha de emisión,
                caducidad, cupo, bloqueos, etc., se regularán por el respectivo contrato de
                apertura, no teniendo “UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.”, responsabilidad
                alguna sobre dichos aspectos.
              </li>
              <li>
                TRANSFERENCIAS SPEI: Se podrán realizar depósitos a la cuenta o wallet electrónico
                del usuario a través de una transferencia SPEI a una cuenta operada por PWTECH, en
                el momento de recibir confirmación de la transferencia su abono será reflejado en la
                cuenta o wallet del usuario. Este servicio es operado por Openpay y el tiempo
                esperado para recibir notificación del pago es de hasta 48 horas hábiles.
              </li>
              <li>
                Los usuarios podrán generar una ficha de depósito que podrán utilizar para pagar en
                establecimientos físicos como seven eleven, farmacias del ahorro, sams club entre
                otros. Una vez hecho el pago con el cajero del establecimiento se notificará al
                sitio web www.pickwin.mx y se abonará en su cuenta o wallet electrónico. El servicio
                operado por Openpay y el tiempo esperado para recibir notificación del pago es de
                hasta 48 horas hábiles.
              </li>
              <li>
                TODITO CASH: Nuestros usuarios pueden realizar depósitos a su cuenta utilizando el
                servicio de ToditoCash. Las tarjetas ToditoCash se pueden adquirir en la red de
                puntos de venta (Oxxo, 7Eleven, Elektra, Banco Azteca) El Establecimiento y Todito
                Cash le cobrarán una comisión por el servicio prestado. CONSULTE LAS COMISIONES
                TODITO CASH
              </li>
              <li>
                PAYPAL: para aquellos usuarios que dispongan de una cuenta en PaypPal, que les
                permita efectuar compras utilizando los servicios de pago ofrecidos por esta última,
                podrán abonar los importes de los servicios adquiridos en el Sitio web mediante este
                sistema de pago. En tal supuesto todas las incidencias relativas a la utilización de
                los servicios provistos por PayPal, tales como el procesamiento de pagos, el envío
                de pagos, comisiones, limitaciones y condiciones de uso del sistema, se regirán de
                acuerdo con lo pactado entre el usuario y PayPal.
              </li>
            </ol>
            <p>La elección del medio de pago es exclusiva potestad del usuario.</p>
            <li>
              <strong>Impuestos</strong>
            </li>
            <p>
              El usuario será responsable de la totalidad de impuestos gravados sobre la utilización
              y beneficios del servicio prestado por UNOCAPALI LA PAZ OPERADORA, de la recepción de
              fondos en la cuenta del usuario de prepago o del uso de la misma.
            </p>
            <li>
              <strong>Resolución</strong>
            </li>
            <p>
              En cualquier momento UNOCAPALI LA PAZ OPERADORA, podrá suspender o cancelar la
              utilización del servicio por cualquier motivo legalmente no permitido, por ejemplo,
              ante la sospecha de un posible fraude o de una actividad atípica, o bien por razones
              de seguridad.
            </p>
            <p>
             UNOCAPALI LA PAZ OPERADORA , se está comprometido con la sociedad y con el Juego
              Responsable.
            </p>
            <p>
              En este sentido, solamente estamos dedicados a ofrecer una experiencia de
              entretenimiento y diversión que sea positiva para todos nuestros clientes.
            </p>
            <ol type="a">
              <li>Siempre se revisará que los clientes sean mayores de edad.</li>
              <li>Nunca otorgar crédito a los clientes. </li>
              <li>
                Señalar que el sitio web de apuestas por internet, es exclusivo para mayores de
                edad.{' '}
              </li>
            </ol>
            <li>
              <strong>Jurisdicción</strong>
            </li>
            <p>
              Solamente aplica el uso del software para usuarios que Se encuentren dentro de los
              Estados Unidos Mexicanos, cualquier otro país queda fuera de nuestras
              responsabilidades y en su caso de detectar que esto sea así, se dará de baja el
              servicio al usuario de manera inmediata.
            </p>
            <li>
              <strong>TODAS LAS TRANSACCIONES SON EN PESOS MEXICANOS.</strong>
            </li>
            <li>
              <strong>
                LAS TARJETAS DE CRÉDITO PERMITIDAS PARA LA COMPRAR DE CRÉDITO EN LÍNEA, SOLAMENTE
                PODRÁN SER LAS EMITIDAS POR LAS INSTITUCIONES BANCARIAS QUE OPERAN EN LA REPÚBLICA
                MEXICANA.
              </strong>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

const ExpedicionComprobantes = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>
            PROCEDIMIENTO PARA LA EXPEDICIÓN DE COMPROBANTES DE APUESTAS EN OPERACIONES VÍA
            INTERNET, TELEFÓNICA O ELECTRÓNICA
          </u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <p>
            El procedimiento que seguirá UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., para la expedición
            de los comprobantes de apuesta, será el siguiente:
          </p>
          <ul>
            <li>
              En las apuestas vía Internet, telefónica o electrónica no se emitirá boleto alguno,
              pero la información de dicha apuesta: número de serie, además de la fecha y hora de
              expedición, monto apostado, tipo de apuesta y selección; quedará registrada en el
              sistema central de apuestas inmediatamente después de haber pagado la apuesta.
            </li>
            <li>
              Tratándose de apuestas vía Internet o electrónica, los participantes tendrán acceso,
              para consulta e impresión, a una constancia de su número de folio y de los derechos
              que les correspondan.
            </li>
            <li>
              Los comprobantes únicamente podrán ser expedidos en los horarios y sitios designados o
              autorizados en el permiso, pudiendo expedir el permisionario comprobantes en forma
              anticipada,
            </li>
            <li>
              Los comprobantes de apuesta deberán ser pagados al momento de que sean solicitados por
              medios de pago legalmente aceptados.
            </li>
          </ul>
          <p>
            {' '}
            <strong>Fundamento Legal:</strong> Art. 85 Reglamento de la Ley Federal de Juegos y
            Sorteos{' '}
          </p>
        </div>
      </div>
    </div>
  );
};

const MecanicaCaptacion = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>MÉCANICA DE CAPTACIÓN DE APUESTAS Y REGLAS GENERALES DE LOS JUEGOS DE APUESTAS.</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <p>
            Las reglas de UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. existen para proteger tanto a la
            compañía como al jugador.
          </p>
          <p>
            Todas las cuentas de los jugadores creadas en línea, serán privadas y confidenciales y
            todas las transacciones serán encriptadas y almacenadas por determinado periodo, para el
            caso que exista alguna disputa/reclamo.
          </p>
          <p>
            Todos nuestros clientes deberán ser mayores de 18 años para poder acceder al sitio y
            hacer uso del mismo.
          </p>
          <p>
           UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., se reserva el derecho de rechazar pronósticos
            en cualquier momento y de cualquier cliente sin ninguna causa, y puede rechazar también
            pronósticos de clientes residentes en estados o provincias donde los pronósticos
            deportivos son prohibidos.
          </p>
          <p>
            Todas las reglas, políticas y dividendos publicados aquí; están sujetos a cambios sin
            previo aviso.
          </p>
          <p>
            A cada cliente se le pide identificarse por medio de su usuario y contraseña en cada
            inicio de sesión vía remota. El cliente tiene responsabilidad absoluta de su contraseña
            y usuario.
          </p>
          <p>
            <stong>
              <p>
                En todas las transacciones realizadas por los clientes o usuarios, quedará
                registrado en el sistema central de apuestas, lo siguiente:
              </p>
              <ol>
                <li>El número de la cuenta e identidad del apostador, y</li>
                <li>
                  La fecha, hora, número de la transacción, cantidad jugada y selección solicitada.
                </li>
              </ol>
            </stong>
          </p>
          <p>
            <strong>Los ganadores y perdedores son según el deporte:</strong>
            <ul>
              <li>Reglas Aplicables por Deporte.</li>
            </ul>
            <p>
              Cada deporte individual tiene sus propias reglas que se detallan en esta sección que
              se refiere a ese deporte. Salvo que la liga, federación, organización deportiva o
              institución declare cancelado o invalido un partido o competencia. Ustedes acepta que
              se aplicarán los siguientes criterios para determinar cancelado un partido o
              competencia:
            </p>
          </p>
          <div
            className="flex-col"
            style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
          >
            <div className="flex-row headers">
              <div className=" flex-col col-center col-30">
                <strong>Deporte</strong>
              </div>
              <div className=" flex-col col-center">
                <strong>Regla</strong>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-center col-30">
                <strong>Soccer</strong>
              </div>
              <div className=" flex-col">
                <p>
                  Si un partido es suspendido antes de concluido la totalidad de tiempo
                  reglamentario (90 minutos) y no es completado en el mismo día hábil del lugar en
                  el que se juegue. En este caso no se tomará en cuenta para la Competencia,
                  incluyendo todas las actividades conexas con dicho partido; como; goles, tarjetas,
                  faltas etc...
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-center col-30">
                <strong>Fútbol Americano</strong>
              </div>
              <div className=" flex-col">
                <p>
                  Si un partido es suspendido antes de concluido la totalidad de tiempo
                  reglamentario (60 minutos) y no es completado en el mismo día hábil del lugar en
                  el que se juegue. En este caso no se tomará en cuenta para la Competencia,
                  incluyendo todas las actividades conexas con dicho partido.
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-center col-30">
                <strong>Basketball profesional (excepto NBA)</strong>
              </div>
              <div className=" flex-col">
                <p>
                  La totalidad del tiempo reglamentario y tiempo extra concluidos en el mismo día
                  hábil del lugar donde se juegue.
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-center col-30">
                <strong>NBA Basketball</strong>
              </div>
              <div className=" flex-col">
                <p>
                  La totalidad del tiempo reglamentario concluidos (48 minutos y tiempo extra en
                  caso de ser necesario), en el mismo día hábil del lugar donde se juegue.
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-center col-30">
                <strong>Collage Basketball</strong>
              </div>
              <div className=" flex-col">
                <p>
                  La totalidad del tiempo reglamentario (40 minutos y tiempo extra) en caso de ser
                  necesario, concluidos en el mismo día hábil del lugar donde se juegue.
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-center col-30">
                <strong>Hockey</strong>
              </div>
              <div className=" flex-col">
                <p>
                  La totalidad del tiempo reglamentario (60 minutos y tiempo extra en caso de ser
                  necesario) concluidos en el mismo día hábil del lugar donde se juegue.
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-30 col-center">
                <strong>Baseball</strong>
              </div>
              <div className=" flex-col">
                <p>
                  9 inning y extra innings en caso de ser necesario, concluidos en el mismo día
                  hábil.
                </p>
              </div>
            </div>

            <div className="flex-row content">
              <div className=" flex-col col-30 col-center">
                <strong>Golf</strong>
              </div>
              <div className=" flex-col">
                <p>Cuando el tour al que pertenece el torneo determine por terminado el torneo.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>Reglas Generales</h4>
          <p>
            Todas las reglas, regulaciones y pagos aquí contenidos están sujetas a cambios y
            revisiones de parte de UNOCAPALI sin necesidad de previo aviso escrito.
          </p>
          <p>
            {' '}
            UNOCAPALI no será responsable de ningún daño o perdida alegada como resultado o causado por
            este Sitio o su contenido. Esto incluye cualquier error de usuario de los contenidos, la
            inhabilidad de cualquier persona de conectarse o de utilizar el Sitio, retraso en las
            operaciones o en la conexión, falla de la líneas de comunicación o cualesquiera errores
            u omisiones de contenido.
          </p>
          <p>
            {' '}
            Todas las reglas, regulaciones y sistemas de puntos de los concursos serán
            constantemente actualizadas en nuestro Sitio, por lo cual le pedimos que se revise las
            reglas antes de hacer cualquier demanda a nuestro personal de servicio al cliente.
          </p>
          <p>
            {' '}
            Todas las participaciones en concursos aceptadas por UNOCAPALI serán validas, a menos que
            haya prueba de que hubo algún tipo de engaño, intentos de "hacking" o que se ha
            intentado engañar intencionalmente.{' '}
          </p>
          <p>
            {' '}
            Los montos en todos los acontecimientos deportivos serán determinados por UNOCAPALI y están
            sujetas a cambio sin previo aviso escrito. UNOCAPALI también se reserva el derecho de
            ajustar o cambiar límites en concursos privados o públicos.
          </p>
          <p>
            {' '}
            UNOCAPALI no aceptará ninguna participación sin tener el usuario ni la contraseña apropiada
            de su cuenta a través de Internet.
          </p>
          <p>
            {' '}
            cada cliente es responsable de mantener en secreto su usuario de cuenta y debe hacer el
            esfuerzo de prevenir el uso de su cuenta personal por terceros. Cualquier transacción
            hecha a la cuenta en donde se hayan dado el usuario y la contraseña correctos quedara
            como valida en nuestros archivos y dependerá únicamente del balance actual de la cuenta.
          </p>
          <p>
            {' '}
            Todas las cuentas se mantienen privadas y confidenciales. Todos los chats de soporte
            serán grabados para la protección de UNOCAPALI y de sus clientes con el fin de resolver
            posibles conflictos.
          </p>
          <p>
            {' '}
            Cuando se participa en concursos a través de Internet, los clientes son los únicos
            responsables de las transacciones en sus cuentas. Por favor asegúrese de revisar sus
            movimientos y corrija cualquier error antes de aceptarlas y meterlas en el sistema.
            UNOCAPALI no se hace responsable de posturas extraviadas o duplicadas hechas por el cliente
            y no atenderá peticiones de discrepancias sobre posturas extraviadas o duplicadas.
          </p>
          <p>
            {' '}
            Las posturas hechas a través de UNOCAPALI no podrán ser cambiadas cinco minutos antes de
            que comience cada juego
          </p>
          <p>
            {' '}
            Una vez que las posturas sean realizadas en UNOCAPALI, el usuario deberá aceptar en la
            ventana de confirmación su registro y participación en el concurso. Una vez confirmada
            la participación, será retirado el saldo correspondiente del concurso del balance
            disponible del usuario. En caso de no contar con un balance suficiente, el usuario podrá
            abonar saldo a su cuenta o sino no podrá completar su participación en el concurso.
          </p>
          <p>
            {' '}
            Cualquier juego que se cancele o posponga será tratado como "no hay acción" y no se
            tomara en cuenta para el concurso realizado. En caso de que el partido se reprograme y
            se lleve a cabo en otra fecha, tampoco se considerará para el concurso en turno.
          </p>
          <p>
            {' '}
            UNOCAPALI depende enteramente en la información proporcionada por el cliente a la hora de
            establecer una cuenta.
          </p>
          <p>
            {' '}
            Aunque nuestro sistema de computadoras mantiene records exactos sobre el balance de
            nuestros clientes, es responsabilidad de cada cliente el asegurarse de que su balance
            esté correcto cada vez que entre a su cuenta por Internet.
          </p>
          <p> El costo de envío de los premios correrá por cuenta del usuario ganador.</p>
          <p>
            {' '}
            El usuario ganador deberá de enviar una copia de su identificación oficial al correo
            hola@Capali.net para verificar su identidad y mayoría de edad.
          </p>
          <p>
            {' '}
            Un Usuario solamente puede tener una cuenta de usuario con UNOCAPALI y utilizará únicamente
            el Servicio usando dicha cuenta. Al Usuario le queda completamente prohibido abrir
            múltiples cuentas en UNOCAPALI. En caso de que UNOCAPALI descubra la existencia de cuentas
            adicionales abiertas por un Usuario, UNOCAPALI podrá cerrar dichas cuentas adicionales sin
            previo aviso y podrá confiscar los fondos de dichas cuentas adicionales
          </p>
        </div>
      </div>
    </div>
  );
};
const ContactoSugerencias = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>DATOS DE CONTACTO PARA QUEJAS Y SUGERENCIAS</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <a className="flex-row" href="mailto:soportecapali@gmail.com ">
            soportecapali@gmail.com{' '}
          </a>
          <strong className="flex-row">Del sitio web https://pickwin.mx</strong>
          <a className="flex-row" href="mailto:soporte@pickwin.net">
            soporte@pickwin.net
          </a>
          <a className="flex-row" href="mailto:hola@pickwin.net">
            {' '}
            hola@pickwin.net{' '}
          </a>
        </div>
      </div>
    </div>
  );
};
const UbicacionServidor = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>UBICACIÓN DEL SERVIDOR Y OFICINAS ADMINISTRATIVAS</u>
        </h1>
      </div>
      <div className="flex-row">Avenida el Pipila No. 256,</div>
      <div className="flex-row">Colonia Independencia,</div>
      <div className="flex-row">C.P. 21290,</div>
      <div className="flex-row">Mexicali, Baja California.</div>
      <div className="flex-row">México.</div>
    </div>
  );
};
const MetodosPago = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>CARACTERÍSTICAS Y MÉTODOS DE PAGO</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <p>
            Los pagos de las apuestas se realizarán únicamente con tarjetas de crédito o débito
            expedidas por instituciones financieras reconocidas por las autoridades nacionales en la
            materia.
          </p>
          <p>
            {' '}
            Todos los retiros, pago de apuestas, premios o devoluciones se realizarán únicamente por
            medio de una transferencia bancaria de la empresa UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.,
            a la tarjeta de débito o cuenta bancaria del usuario previamente validado que lo
            solicita a través de nuestra plataforma como retiro.
          </p>
          <p>
            {' '}
            De acuerdo a las definiciones que el banco central del Estado Mexicano, el Banco de
            México (BANXICO) establece en sus materiales educativos de sistemas de pago, dentro del
            documento “Glosario de términos utilizados en los sistemas de pago y liquidación” se le
            llama Key o Clave a una serie única de dígitos utilizada en combinación con un algoritmo
            criptográfico misma que, se opera a través de diversos sistemas de pago como:, spei,
            Openpay, paynet, todito cash, y similares, que operan como una Clave de Sesión ÚNICA del
            usuario previamente identificado, la cual según el mismo glosario determina como una
            clave criptográfica que se utiliza durante un tiempo limitado, como puede ser una única
            sesión de comunicación o de operación.
          </p>
          <p>
            {' '}
            Considerando que de esta manera, todos los ingresos que se recibieran serían a través
            del sistema bancario e identificando y validando la información de cada usuario de
            acuerdo a los lineamientos previamente autorizados.
          </p>
          <p>
            {' '}
            Una vez que el usuario realiza el ingreso a través de este instrumento, el dinero es
            depositado a la cuenta de la institución financiera correspondiente, misma que a través
            de la integración correspondiente con nuestra plataforma es transformada en Balance o
            Saldo en tiempo real dentro de su sesión de usuario en el sitio www.pickwin.mx operado y
            autorizado a UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.
          </p>
        </div>
      </div>
      <div>
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>MÉTODOS DE PAGO ACEPTADOS</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <ul>
            <li>
              <strong>Visa, Mastercard Y American Express</strong>
              <p>
                Es importante informar que algunos bancos prohíben a los titulares de sus tarjetas
                de crédito, realizar transacciones en casinos on line. Todas las transacciones de
                Visa y MasterCard, así como American Express, los cargos se realizaran por medio de
                Openpay, aparecerán en el estado de cuenta cargadas por PICKWIN.MX
               UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.
              </p>
            </li>
            <li>
              <strong>Transferencia Bancaria</strong>
              <p>
                La transferencia bancaria permite a los usuarios realizar un depósito de dinero a
                los sitios del cliente mediante un banco local. La transferencia bancaria permite
                realizar depósitos y retirar fondos en moneda nacional.
              </p>
            </li>
            <li>
              <strong>Transferencia SPEI</strong>
              <p>
                Se podrán realizar depositos a la cuenta o wallet electrónico del usuario a tráves
                de una transferencia SPEI a una cuenta operada por PWTECH, en el momento de recibir
                confirmación de la transferencia su abono será reflejado en la cuenta o wallet del
                usuario. Este servicio es operado por Openpay y el tiempo esperado para recibir
                notificacion del pago es de hasta 48 horas hábiles.
              </p>
            </li>
            <li>
              <strong>Paynet</strong>
              <p>
                Paynet es un método de pago completamente seguro y libre de fraude. Además permite
                recibir pagos del 82% de la población en México que no cuenta con una tarjeta de
                crédito en más de 15,500 sucursales de diferentes establecimientos como, Seven
                Eleven, Farmacias del ahorro, Sams Club, Walmart etc..
              </p>
            </li>
            <li>
              <strong>Todito Cash</strong>
              <p>
                Nuestros usuarios pueden realizar depósitos a su cuenta utilizando el servicio de
                ToditoCash. Las tarjetas ToditoCash se pueden adquirir en la red de puntos de venta
                (Oxxo, 7Eleven, Elektra, Banco Azteca) El Establecimiento y Todito Cash le cobrarán
                una comisión por el servicio prestado. CONSULTE LAS COMISIONES TODITO CASH
              </p>
              <p>
                Se puede depositar en su cuenta un importe mínimo diario de $350.00 pesos y máximo
                diario de $50,000.00 pesos. Para depositar es muy sencillo, ingrese a su cuenta,
                seleccione “Recarga”, capture el monto que desea abonar, Numero de Tarjeta de
                ToditoCash, NIP de su tarjeta ToditoCash y la contraseña de su cuenta de apuestas
                por Internet, seleccione el botón de “Abonar”.
              </p>
            </li>
            <li>
              <strong>Openpay</strong>
              <p>
                Openpay es una plataforma de pagos que permite de manera rápida y sencilla aceptar
                pagos en tu sitio web o app. Nuestro servicio soporta diferentes modelos de negocio
                como: Marketplaces, tiendas en línea, pagos por suscripciones, aplicaciones móviles,
                dispersiones a tercertos, crowdfunding y otros.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
const DerechosObligaciones = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>DERECHOS Y/O OBLIGACIONES DE LOS USUARIOS, PERMISIONARIO Y/U OPERADOR</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>Derechos y obligaciones del usuario</h4>
          <strong>El usuario tiene derecho a:</strong>
          <ol type="i">
            <li>
              {' '}
              Obtener información clara y veraz sobre las reglas del juego en el que desee
              participar;{' '}
            </li>
            <li>
              {' '}
              Cobrar los premios que le pudieran corresponder en el tiempo y forma establecidos, de
              conformidad con el reglamento específico de cada juego;
            </li>
            <li>
              {' '}
              Utilizar el tiempo de uso correspondiente al precio de la partida de que se trate;{' '}
            </li>
            <li>
              {' '}
              Jugar libremente, sin coacciones o amenazas provenientes de UNOCAPALI LA PAZ OPERADORA,
              de otros jugadores o de cualquier otra tercera persona;{' '}
            </li>
            <li>
              {' '}
              Conocer en cualquier momento el importe que ha jugado o apostado así como a conocer el
              saldo de la cuenta de juego;{' '}
            </li>
            <li> Identificarse de modo seguro; </li>
            <li>
              {' '}
              Recibir la debida información en relación con el tratamiento de sus datos de carácter
              personal y prestar su consentimiento al efecto cuando sea necesario conforme a lo
              previsto en la Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares.
            </li>
            <li>
              {' '}
              Conocer en todo momento la identidad del operador de juego así como a conocer, en el
              caso de reclamaciones o posibles infracciones, la identidad del personal que
              interactúe con él;{' '}
            </li>
            <li> Recibir información sobre la práctica responsable del juego.</li>
          </ol>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <strong>El usuario esta obligado a:</strong>
          <ol type="i">
            <li> Identificarse en los términos establecidos por UNOCAPALI LA PAZ OPERADORA; </li>
            <li>
              {' '}
              No alterar el normal desarrollo de los juegos; adoptar comportamientos basados en la
              honestidad y el respeto hacia los demás usuarios y hacia UNOCAPALI LA PAZ OPERADORA;{' '}
            </li>
            <li>
              {' '}
              Cumplir las normas y reglas que, en relación con los usuarios, se establezcan
              normativamente (Ley Federal de Juegos y Sorteos, y su Reglamento);
            </li>
            <li>
              {' '}
              No ceder el registro de usuario a terceros, ni facilitar el uso no autorizado del
              mismo;{' '}
            </li>
            <li> No realizar transferencias a cuentas de juego de otros jugadores.</li>
          </ol>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>Derechos, obligaciones y responsabilidade de "CAPALLI LA PAZ OPERACIONES"</h4>
          <strong>CAPALLI LA PAZ OPERACIONES está obligada a:</strong>
          <ol type="i">
            <li>
              {' '}
              Conservar estas Condiciones Generales por un plazo de seis (6) años desde la
              cancelación del registro de usuario y adoptar todas las medidas necesarias para la
              protección de los datos del usuario;{' '}
            </li>
            <li>
              {' '}
              Solicitar el consentimiento expreso del usuario para la prórroga de la relación
              contractual en los supuestos de modificación unilateral de las Condiciones Generales o
              novación subjetiva de UNOCAPALI LA PAZ OPERADORA;{' '}
            </li>
            <li>
              {' '}
              Conservar el detalle analítico de los movimientos en relación con la cuenta de juego
              del usuario y de las jugadas efectuadas durante un periodo de seis (6) años;{' '}
            </li>
            <li>
              {' '}
              Integrar los expedientes de identificación de Clientes o Usuarios, cuando se
              involucren operaciones de compra o venta de dichos bienes en actos u operaciones cuyo
              valor sea igual o superior, o equivalente a 325 (trescientos veinticinco) veces el
              salario mínimo vigente.
            </li>
            <li>
              {' '}
              Custodiar, proteger, resguardar y evitar la destrucción u ocultamiento de la
              información y documentación que sirva de soporte a la Actividad Vulnerable, así como
              la que identifique a sus clientes o usuarios, por un plazo de 5 (cinco) años contados
              a partir de la fecha de la realización de la actividad.
            </li>
            <li>
              {' '}
              Registrar de manera inmediata en la cuenta de juego, mediante cargos y abonos, todas
              las operaciones, incluyendo los elementos identificativos completos de las mismas y,
              en particular, los relativos a jugadas, ganancias, devoluciones, ingresos, reintegros
              o bonus recibidos.{' '}
            </li>
            <li>
              {' '}
              Poner en conocimiento de la DGJS, los datos identificativos de aquellos usuarios que
              pudieran suponer un riesgo de colusión o que hayan utilizado fraudulentamente en la
              cuenta de juego tarjetas de crédito.
            </li>
          </ol>
          <p>
           UNOCAPALI LA PAZ OPERADORA no responderá por, ni será responsable de ningún
            incumplimiento o demora en el cumplimiento de las obligaciones que le corresponden que
            traiga causa de eventos ajenos a su control razonable. Si en algún momento UNOCAPALI LA PAZ OPERADORA
            no insiste en el cumplimiento estricto de cualquiera de las obligaciones o
            si no ejercitase cualquiera de los derechos o acciones que le corresponden, ello no
            constituirá una renuncia a dichos derechos o acciones, ni le eximirá al usuario del
            cumplimiento de las obligaciones que correspondan. Ninguna renuncia por parte de
            UNOCAPALI LA PAZ OPERADORA S.A. DE C.V., a cualquiera de las Condiciones Generales será efectiva a menos que
            se indique expresamente que se trata de una renuncia y le sea comunicada al usuario por
            escrito.
          </p>
          <p>
           UNOCAPALI LA PAZ OPERADORA se reserva el derecho a emprender acciones contra cualquier
            persona que, a juicio de aquélla, incumpla las Condiciones Generales, incluyendo, sin
            limitación, la terminación de su registro y del acceso a la Web.
          </p>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <strong>CAPALLI LA PAZ OPERACIONES NO RESPONDERÁ POR:</strong>
          <ol type="i">
            <li> Lucro cesante, ya sea directo o indirecto;</li>
            <li>
              {' '}
              Pérdidas indirectas que puedan producirse como efecto colateral de la pérdida o daño
              principal;{' '}
            </li>
            <li>
              {' '}
              Fallos de funcionamiento de ordenadores y/o transmisión de datos incorrecta o
              demasiada lenta por el proveedor de Internet y/o daños producidos como consecuencia de
              apuestas realizadas por parte del usuario que no sean recibidas o que no sean tenidas
              en cuenta como consecuencia de fallos técnicos en el software o hardware
              (independientemente de que sean o no ajenos a su control);{' '}
            </li>
            <li>
              {' '}
              Cualesquiera pérdidas derivadas de negligencia por parte del usuario; cualquier
              pérdida o daño ocasionado por virus u otro software malicioso que pueda infectar el
              equipo informático del usuario, software, datos u otras propiedades causado por el
              acceso, uso o realización por su parte de descargas del Sitio Web o de transmisiones a
              través de correos electrónicos o documentos adjuntos.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
const MedidasAdoptadas = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>
            MEDIDAS ADOPTADAS PARA LA PREVENCIÓN DE OPERACIONES CON RECURSOS DE PROCEDENCIA ILICITA{' '}
          </u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <p>
           UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. a través de su sitio web autorizado por la
            Secretaria de Gobernación en conjunto con la Dirección General de Juegos y Sorteos, se
            compromete a llevar a cabo los lineamientos para dar cumplimiento a lo establecido en la
            Ley Federal para la Prevención e Identificación de Operaciones con Recursos de
            Procedencia Ilícita, su Reglamento, sus Reglas de Carácter General y demás disposiciones
            que de ellas emanen.
          </p>
          <strong>Contenido</strong>
          <ol>
            <li>
              <a className="flex-col" href="#glosario">
                GLOSARIO
              </a>
            </li>
            <li>
              <a className="flex-col" href="#clientsOperaations">
                OPERAIONES CON CLIENTES
              </a>
            </li>
            <ol type="A">
              <li>
                <a className="flex-col" href="#criteriosId">
                  CRITERIOS DE IDENTIFICACIÓN
                </a>
              </li>
              <li>
                <a className="flex-col" href="#criteriosAviso">
                  CRITERIO DE AVISO
                </a>
              </li>
            </ol>
            <li>
              <a className="flex-col" href="#internalProcess">
                PROCEDIMIENTO INTERNO
              </a>
              <ol type="A">
                <li>
                  <a href="#systemFidelization">FIDELIZACIÓN EN SISTEMA</a>
                </li>
                <li>
                  <a href="#clientsId">
                    INTEGRACIÓN DEL EXPEDIENTE ÚNICO DE IDENTIFICACIÓN DE LOS CLIENTES O USUARIOS
                  </a>
                </li>
              </ol>
            </li>
            <li>
              <a className="flex-col" href="#avisoSHCP">
                AVISO A LA SHCP
              </a>
            </li>
            <li>
              <a className="flex-col" href="#avispSHCPInfo">
                INFORMACIÓN A PRESENTAR EN LOS AVISOS A LA SHCP
              </a>
              <ol type="A">
                <li>DATOS DE LA IDENTIFICACIÓN DE LA PERSONA OBJETO DE AVISO</li>
                <ol type="I">
                  <li>DATOS DE TIPO DE PERSONA OBJETO DE AVISO</li>
                  <li>DATOS DEL TIPO DE DOMICILIO DE LA PERSONA OBJETO DE AVISO</li>
                  <li>DATOS DEL NÚMERO TELEFÓNICO</li>
                </ol>
                <li>DATOS DE IDENTIFICACIÓN DEL BENEFICIARIO CONTROLADOR O DUEÑO BENEFICIARIO</li>
                <ol type="I">
                  <li>
                    {' '}
                    DATOS DE TIPO DE PERSONA DEL BENEFICIARIO CONTROLADOR O DUEÑO BENEFICIARIO
                  </li>
                  <li> DATOS DEL TIPO DE DOMICILIO</li>
                  <li> DATOS DEL NÚMERO TELEFÓNICO</li>
                </ol>
                <li>DETALLES DE LAS OPERACIONES O ACTOS</li>
              </ol>
            </li>
            <li>
              <a className="flex-col" href="#opRestrictions">
                RESTRICCIÓN DE OPERACIONES EN EFECTIVO
              </a>
              <ol type="A">
                <li>MEDIDAS DE RESTRICCIÓN EN BASE A LOS MONTOS OPERADOS</li>
                <ol type="I">
                  <li>RECARGAS O DEPÓSITOS PARA JUEGO</li>
                  <li>RECARGAS O DEPÓSITOS PARA JUEGO</li>
                </ol>
              </ol>
            </li>
            <li>
              <a href="verificationsView">VISITAS DE VERIFICACIÓN</a>
            </li>
            <li>
              <a href="infoMngmt">RESERVA Y MANEJO DE INFORMACIÓN</a>
            </li>
            <li>
              <a href="administravites">SANCIONES ADMINISTRATIVAS</a>
              <ol type="A">
                <li> INFRACCIONES Y SANCIONES</li>
              </ol>
            </li>
            <li>
              <a href="crimes">DELITOS</a>
            </li>
          </ol>
        </div>
      </div>
      <div className="flex-row">
        <ol>
          <li>
            <strong>GLOSARIO</strong>
            <p>Para efectos de este Manual de Política y Procedimiento, se entenderá por:</p>
            <ul>
              <li>
                IDENTIFICACIÓN OFICIAL: Credencial para votar expedida por el Instituto Nacional
                Electoral (INE), Pasaporte Mexicano y demás contempladas dentro de la Ley,
                Reglamento y las Reglas de Carácter General.
              </li>
              <li>
                LEY: a la Ley Federal para la Prevención e Identificación de Operaciones con
                Recursos de Procedencia Ilícita.
              </li>
              <li>
                REGLAMENTO: el Reglamento de la Ley Federal para la Prevención e Identificación de
                Operaciones con Recursos de Procedencia Ilícita.
              </li>
              <li>
                REGLAS: las reglas de carácter general a que se refiere la Ley Federal para la
                Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita.
              </li>
              <li>SECRETARÍA: a la Secretaría de Hacienda y Crédito Público (SHCP).</li>
            </ul>
          </li>
          <li>
            <strong>OPERACIONES CON CLIENTES</strong>
            <p>
             UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. podrá Abstenerse de realizar cualquier acto u
              operación tipificada como actividad vulnerable, cuando sus clientes o usuarios se
              nieguen a proporcionar información o documentación relacionada con su identificación y
              su conocimiento del mismo.
            </p>
            <ol type="A">
              <li>CRITERIOS DE IDENTIFICACIÓN Y AVISO DE CLIENTES</li>
              <ol type="I">
                <li>Objeto de Identificación</li>
                <p>
                  Se deberá identificar a los Clientes cuyo monto de operaciones exceda los 325 UMAS
                  equivalentes a $26,195.00 (VEINTI SEIS MIL CIENTO NOVENTA Y CINCO 00/100 MN).
                  Artículos 17 y 18 de la Ley.
                </p>
                <li>Objeto de Aviso a la SHCP</li>
                <p>
                  La información de los Clientes o Usuarios que excedan el 645 veces el salario
                  mínimo vigente en el Distrito Federal se proporcionará a la Unidad de Inteligencia
                  Financiera a través del Servicio de Administración Tributaria a más tardar el día
                  17 del mes inmediato siguiente a la realización de la operación que le diera
                  origen.
                </p>
                <p>
                  {' '}
                  Queda estrictamente prohibido alertar o dar aviso a los clientes o usuarios
                  respecto de cualquier referencia que sobre éstos se haga en los Avisos a la
                  Secretaría. Artículo 31 de las Reglas
                </p>
              </ol>
            </ol>
          </li>
          <li>
            <strong>PROCEDIMIENTO INTERNO</strong>
            <ol type="A">
              <li>
                <strong>Fidelización en sistema</strong>
                <p>
                  Una vez que se le ha solicitado al Usuario su Identificación Oficial, se procederá
                  a capturar en sistema la siguiente información:
                </p>
                <ul>
                  <li>
                    Apellido paterno, apellido materno y nombre (s) sin abreviaturas o, en caso de
                    ser extranjero, los apellido completos que correspondan y nombre (s).
                  </li>
                  <li>Fecha de nacimiento</li>
                  <li>País de nacimiento</li>
                  <li>Nacionalidad</li>
                  <li>Ocupación</li>
                  <li>Giro del negocio</li>
                  <li>Correo electrónico</li>
                </ul>
              </li>
              <li>
                <strong>
                  INTEGRACIÓN DEL EXPEDIENTE ÚNICO DE IDENTIFICACIÓN DE LOS CLIENTES O USUARIOS
                </strong>
                <p>
                  El Expediente Único de Identificación de cada uno de los clientes, deberá
                  integrarse con los siguientes requisitos (Artículo 12 de las Reglas):
                </p>
                <ol type="I">
                  <li>
                    En Caso de Personas Físicas de Nacionalidad Mexicana o de Nacionalidad
                    Extranjera con las Condiciones de Residente Temporal o Residente Permanente, se
                    deberán asentar los siguientes datos:
                  </li>
                  <ol type="i">
                    <li>
                      Apellido paterno, apellido materno y nombre(s), sin abreviaturas o, en caso de
                      ser extranjero, los apellidos completos que correspondan y nombre (s);
                    </li>
                    <li>Fecha de nacimiento;</li>
                    <li>País de nacimiento;</li>
                    <li>País de nacionalidad;</li>
                    <li>
                      Actividad, ocupación, profesión, actividad o giro del negocio al que se
                      dedique el Cliente o Usuario;
                    </li>
                    <li>
                      Domicilio particular en su lugar de residencia, compuesto de los siguientes
                      datos: nombre de la calle, avenida o vía de que se trate, debidamente
                      especificada; número exterior y, en su caso, interior; colonia o urbanización;
                      demarcación territorial, municipio o demarcación política similar que
                      corresponda, en su caso; ciudad o población, entidad federativa, estado,
                      provincia, departamento o demarcación política similar que corresponda, en su
                      caso; código postal y país;
                    </li>
                    <li>
                      Número(s) de teléfono en que se pueda localizar, incluida la clave de larga
                      distancia y, en su caso, extensión, cuando cuenten con aquél;
                    </li>
                    <li>Correo electrónico, en su caso;</li>
                    <li>
                      Clave Única de Registro de Población y la clave del Registro Federal de
                      Contribuyentes, cuando cuente con ellas, y
                    </li>
                    <li>
                      Datos de la identificación con la que se identificó, consistentes en: nombre
                      de la identificación; autoridad que la emite, y número de la misma. En este
                      punto, es necesario escanear la identificación.
                    </li>
                  </ol>
                  <li>
                    Adicionalmente, tratándose de personas que tengan su lugar de residencia en el
                    extranjero y a la vez cuenten con domicilio en territorio nacional en donde
                    puedan recibir correspondencia dirigida a ellas, se deberá asentar en el
                    expediente los datos relativos a dicho domicilio, con los mismos elementos que
                    los contemplados en el numeral vi) anterior. Incluir copia de los siguientes
                    documentos:
                  </li>
                  <ol type="i">
                    <li>
                      <p>
                        Identificación, que deberá ser, en todo caso, un documento original oficial
                        emitido por autoridad competente, vigente a la fecha de su presentación, que
                        contenga la fotografía, firma y, en su caso, domicilio del propio Cliente o
                        Usuario.
                      </p>
                      <p>
                        Para efectos de lo dispuesto por este inciso, se considerarán como
                        documentos válidos de identificación la credencial para votar expedida por
                        el Instituto Federal Electoral, así como cualquier identificación vigente
                        con fotografía y firma, emitida por autoridades federales, estatales y
                        municipales, y las demás que, en su caso, apruebe la UIF. Asimismo, respecto
                        de las personas físicas de nacionalidad extranjera, se considerarán como
                        documentos válidos de identificación, además de los anteriormente referidos
                        en este párrafo, el pasaporte o la documentación expedida por el Instituto
                        Nacional de Migración que acredite su condición de estancia en el país;
                      </p>
                    </li>
                    <li>
                      <p>
                        Constancia de la Clave Única de Registro de Población, expedida por la
                        Secretaría de Gobernación o Cédula de Identificación Fiscal expedida por el
                        SAT, cuando el Cliente o Usuario cuente con ellas;
                      </p>
                    </li>
                    <li>
                      <p>
                        Comprobante de domicilio, cuando el domicilio manifestado por el Cliente o
                        Usuario no coincida con el de la identificación o ésta no lo contenga. En
                        este supuesto, será necesario que recabemos e integremos al expediente
                        respectivo copia de un documento que acredite el domicilio del Cliente o
                        Usuario, que podrá ser algún recibo de pago por servicios domiciliarios o
                        estados de cuenta bancarios, todos ellos con una antigüedad no mayor a tres
                        meses a su fecha de emisión, o el contrato de arrendamiento vigente a la
                        fecha de presentación por el Cliente o Usuario y registrado ante la
                        autoridad fiscal competente, la Constancia de inscripción en el Registro
                        Federal de Contribuyentes, así como los demás que, en su caso, apruebe la
                        UIF;
                      </p>
                    </li>
                    <li>
                      <p>
                        Constancia por la que se acredite que le solicitamos a nuestro Cliente o
                        Usuario información acerca de si tiene conocimiento de la existencia del
                        Dueño Beneficiario, la cual deberá estar firmada por los que participen
                        directamente en el acto u operación.
                      </p>
                      <p>
                        En el supuesto en que la persona física manifieste que sí tiene conocimiento
                        de la existencia del Dueño Beneficiario, debemos identificarlo (Fracción VII
                        del Artículo 12 de las Reglas de Carácter General), cuando dicho Cliente o
                        Usuario cuente con dicha información, y
                      </p>
                    </li>
                    <li>
                      <p>
                        Carta poder o copia certificada del documento expedido por fedatario
                        público, según corresponda, para el caso en que la persona física actúe como
                        apoderado de otra persona, las cuales deberán cumplir los términos
                        establecidos en la legislación común, a fin de que se acrediten las
                        facultades conferidas al apoderado, así como copia de una identificación
                        oficial y comprobante de domicilio de este, que cumplan con los requisitos
                        señalados en este Anexo respecto de dichos documentos, con independencia de
                        los datos y documentos relativos al poderdante.
                      </p>
                    </li>
                  </ol>
                  <li> En Caso de Personas Morales de Nacionalidad Mexicana</li>
                  <ol type="a">
                    <li>Asentar los siguientes datos:</li>
                    <ol type="i">
                      <li>Denominación o razón social;</li>
                      <li>Fecha de constitución;</li>
                      <li>País de nacionalidad;</li>
                      <li>ctividad, giro mercantil, actividad u objeto social;</li>
                      <li>
                        Domicilio, compuesto de los elementos siguientes: nombre de la calle,
                        avenida o vía de que se trate, debidamente especificada; número exterior y,
                        en su caso, interior; colonia o urbanización; demarcación territorial,
                        municipio o demarcación política similar que corresponda, en su caso; ciudad
                        o población, entidad federativa, estado, provincia, departamento o
                        demarcación política similar que corresponda, en su caso; código postal y
                        país;
                      </li>
                      <li>
                        Número(s) de teléfono de dicho domicilio, incluida la clave de larga
                        distancia y, en su caso, extensión, cuando cuenten con aquél;
                      </li>
                      <li>Correo electrónico, en su caso;</li>
                      <li>
                        Clave del Registro Federal de Contribuyentes, cuando cuente con ella, y
                      </li>
                      <li>
                        Nombre(s) y apellidos paterno y materno, sin abreviaturas, así como fecha de
                        nacimiento, clave del Registro Federal de Contribuyentes o Clave Única de
                        Registro de Población del representante, apoderados legales o personas que
                        realicen el acto u operación a nombre de la persona moral de que se trate.
                        Asimismo, deberán recabar datos de su identificación, consistentes en:
                        nombre de la identificación; autoridad que la emite, y número de la misma;
                      </li>
                    </ol>
                    <li>
                      Incluir copia de los siguientes documentos relativos a la persona moral:
                    </li>
                    <ol type="i">
                      <li>
                        Testimonio o copia certificada del instrumento público que acredite su
                        constitución e inscripción en el registro público que corresponda, de
                        acuerdo con la naturaleza de la persona moral, o de cualquier instrumento en
                        el que consten los datos de su constitución y los de su inscripción en dicho
                        registro, o bien, del documento que, de acuerdo con su naturaleza o el
                        régimen que le resulte aplicable a la persona moral de que se trate,
                        acredite su existencia.
                      </li>
                      <li>
                        En caso de que la persona moral sea de reciente constitución y, en tal
                        virtud, no se encuentre aún inscrita en el registro público que corresponda
                        de acuerdo con su naturaleza, debemos obtener un escrito firmado por persona
                        legalmente facultada que acredite su personalidad en términos del
                        instrumento público que acredite su constitución a que se refiere el inciso
                        b) numeral iv) de este Anexo, en el que conste la obligación de llevar a
                        cabo la inscripción respectiva y proporcionarnos, en su oportunidad, los
                        datos correspondientes;
                      </li>
                      <li>
                        Cédula de Identificación Fiscal expedida por el SAT, en caso de contar con
                        ésta;
                      </li>
                      <li>
                        Comprobante que acredite el domicilio a que se refiere el numeral v), del
                        inciso a) anterior, que podrá ser algún recibo de pago por servicios
                        domiciliarios o estados de cuenta bancarios, todos ellos con una antigüedad
                        no mayor a tres meses a su fecha de emisión, o el contrato de arrendamiento
                        vigente a la fecha de presentación por el Cliente o Usuario y registrado
                        ante la autoridad fiscal competente, la Constancia de inscripción en el
                        Registro Federal de Contribuyentes, así como los demás que, en su caso,
                        apruebe la UIF;
                      </li>
                      <li>
                        Testimonio o copia certificada del instrumento que contenga los poderes del
                        representante o apoderados legales, expedido por fedatario público, cuando
                        no estén contenidos en el instrumento público que acredite la constitución
                        de la persona moral de que se trate, así como la identificación de cada uno
                        de dichos representantes, apoderados legales o personas que realicen el acto
                        u operación a nombre de dicha persona moral, que deberá ser, en todo caso,
                        un documento original oficial emitido por autoridad competente, vigente a la
                        fecha de su presentación, que contenga la fotografía, firma y, en su caso,
                        domicilio de la referida persona. Para efectos de lo dispuesto por este
                        inciso, se considerarán como documentos válidos de identificación la
                        credencial para votar expedida por el Instituto Federal Electoral, así como
                        cualquier identificación vigente con fotografía y firma, emitida por
                        autoridades federales, estatales y municipales.
                      </li>
                      <p>
                        Asimismo, respecto de las personas físicas de nacionalidad extranjera, se
                        considerarán como documentos válidos de identificación, además de los
                        anteriormente referidos en este párrafo, el pasaporte o la documentación
                        expedida por el Instituto Nacional de Migración que acredite su condición de
                        estancia en el país, y
                      </p>
                      <li>
                        Constancia por la que se acredite que le solicitamos a nuestro Cliente o
                        Usuario información acerca de si tiene conocimiento de la existencia del
                        Dueño Beneficiario, la cual deberá estar firmada por los que participen
                        directamente en el acto u operación.
                      </li>
                      <p>
                        En el supuesto en que la persona física manifieste que sí tiene conocimiento
                        de la existencia del Dueño Beneficiario, debemos identificarlo (Fracción VII
                        del artículo 12 de las Reglas de Carácter General), cuando dicho Cliente o
                        Usuario cuente con dicha información (solicitándole la información contenida
                        en el Apartado denominado “INFORMACIÓN A PRESENTAR EN LOS AVISOS A LA
                        SHCP”).
                      </p>
                    </ol>
                  </ol>
                  <li>
                    En Caso de Personas Físicas Extranjeras con las Condiciones de Estancia de
                    Visitante
                  </li>
                  <ol type="a">
                    <li>Asentar los siguientes datos:</li>
                    <ol type="i">
                      <li>Apellidos completos que correspondan y nombre(s);</li>
                      <li>Fecha de nacimiento;</li>
                      <li>País de nacimiento;</li>
                      <li>País de nacionalidad;</li>
                      <li>
                        Actividad, ocupación, profesión, actividad o giro del negocio al que se
                        dedique el Cliente o Usuario;
                      </li>
                      <li>
                        Domicilio particular en su lugar de residencia, compuesto de los elementos
                        siguientes: nombre de la calle, avenida o vía de que se trate, debidamente
                        especificada; número exterior y, en su caso, interior; colonia o
                        urbanización; demarcación territorial, municipio o demarcación política
                        similar que corresponda, en su caso; ciudad o población, entidad federativa,
                        estado, provincia, departamento o demarcación política similar que
                        corresponda, en su caso; código postal y país, y
                      </li>
                      <li>
                        Datos del documento con el que se identificó, consistentes en: nombre de la
                        acreditación; autoridad que la emite, y número de la misma.
                      </li>
                      <p>
                        Adicionalmente, tratándose de personas que tengan su lugar de residencia en
                        el extranjero y a la vez cuenten con domicilio en territorio nacional en
                        donde puedan recibir correspondencia dirigida a ellas, se deberá asentar en
                        el expediente los datos relativos a dicho domicilio, con los mismos
                        elementos que los contemplados en este Anexo.
                      </p>
                    </ol>
                    <li>
                      Incluir copia de los siguientes documentos relativos a la persona física:
                    </li>
                    <ol type="i">
                      <li>
                        Pasaporte o documento original oficial emitido por autoridad competente del
                        país de origen, vigente a la fecha de su presentación, que contenga la
                        fotografía, firma y, en su caso, domicilio del referido Cliente o Usuario,
                        que acredite su nacionalidad
                      </li>
                      <li>
                        Documento oficial expedido por el Instituto Nacional de Migración, que
                        acredite su internación o legal estancia en el país, cuando cuente con este;
                      </li>
                      <li>
                        Comprobante de domicilio, respecto del domicilio en territorio nacional en
                        donde el Cliente o Usuario pueda recibir correspondencia dirigida a este,
                        cuando el domicilio manifestado por el Cliente o Usuario no coincida con el
                        de la identificación o ésta no lo contenga. En este supuesto, será necesario
                        que recabemos e integremos al expediente respectivo copia de un documento
                        que acredite el domicilio del Cliente o Usuario, que podrá ser algún recibo
                        de pago por servicios domiciliarios o estados de cuenta bancarios, todos
                        ellos con una antigüedad no mayor a tres meses a su fecha de emisión, o el
                        contrato de arrendamiento vigente a la fecha de presentación por el Cliente
                        o Usuario y registrado ante la autoridad fiscal competente, la Constancia de
                        inscripción en el Registro Federal de Contribuyentes, así como los demás
                        que, en su caso, apruebe la UIF;
                      </li>
                      <li>
                        Constancia por la que se acredite que le solicitamos a nuestro Cliente o
                        Usuario información acerca de si tiene conocimiento de la existencia del
                        Dueño Beneficiario, la cual deberá estar firmada por los que participen
                        directamente en el acto u operación.
                      </li>
                      <p>
                        En el supuesto en que la persona física manifieste que sí tiene conocimiento
                        de la existencia del Dueño Beneficiario, debemos identificarlo (Fracción VII
                        del Artículo 12 de las Reglas de Carácter General), cuando dicho Cliente o
                        Usuario cuente con dicha información (solicitándole la información contenida
                        en el Apartado denominado “INFORMACIÓN A PRESENTAR EN LOS AVISOS A LA
                        SHCP”), y
                      </p>
                      <li>
                        Carta poder o copia certificada del documento expedido por fedatario
                        público, según corresponda, para el caso en que la persona física actúe como
                        apoderado de otra persona, las cuales deberán cumplir los términos
                        establecidos en la legislación común, a fin de que se acrediten las
                        facultades conferidas al apoderado, así como copia de una identificación
                        oficial y comprobante de domicilio de este, que cumplan con los requisitos
                        señalados en este Anexo respecto de dichos documentos, con independencia de
                        los datos y documentos relativos al poderdante.
                      </li>
                    </ol>
                  </ol>
                  <li>En Caso de Personas Morales de Nacionalidad Extranjera</li>
                  <ol type="a">
                    <li> Asentar los siguientes datos:</li>
                    <ol type="i">
                      <li>Denominación o razón social;</li>
                      <li>Fecha de constitución;</li>
                      <li>País de nacionalidad;</li>
                      <li>
                        Actividad, giro mercantil, u objeto social cuando se establezca una Relación
                        de Negocios o se celebre un acto u operación;
                      </li>
                      <li>
                        Clave del Registro Federal de Contribuyentes cuando cuenten con ella o, en
                        su caso, número de identificación fiscal de su país de nacionalidad;
                      </li>
                      <li>
                        Domicilio, compuesto de los elementos siguientes: nombre de la calle,
                        avenida o vía de que se trate, debidamente especificada; número exterior y,
                        en su caso, interior; colonia o urbanización; demarcación territorial,
                        municipio o demarcación política similar que corresponda, en su caso; ciudad
                        o población, entidad federativa, estado, provincia, departamento o
                        demarcación política similar que corresponda, en su caso; código postal y
                        país;
                      </li>
                      <li>
                        {' '}
                        Número(s) de teléfono de dicho domicilio, incluida la clave de larga
                        distancia y, en su caso, extensión, cuando cuenten con aquél;
                      </li>
                      <li>Correo electrónico, en su caso, y</li>
                      <li>
                        Nombre, así como fecha de nacimiento, clave del Registro Federal de
                        Contribuyentes o Clave Única de Registro de Población del representante,
                        apoderados legales o personas que realicen el acto u operación a nombre de
                        la persona moral de que se trate. Asimismo, deberán recabar datos de su
                        identificación, consistentes en: nombre de la identificación; autoridad que
                        la emite, y número de la misma;
                      </li>
                    </ol>
                    <li>
                      Incluir copia de los siguientes documentos relativos a la persona moral:
                    </li>
                    <ol type="i">
                      <li>
                        Documento que compruebe su constitución, así como información que permita
                        conocer su estructura accionaria y, además se deberá recabar e incluir la
                        documentación que identifique a los accionistas o socios respectivos;
                      </li>
                      <li>
                        {' '}
                        Comprobante que acredité el domicilio a que se refiere el numeral vi), del
                        inciso a) anterior, que podrá ser algún recibo de pago por servicios
                        domiciliarios o estados de cuenta bancarios, todos ellos con una antigüedad
                        no mayor a tres meses a su fecha de emisión, o el contrato de arrendamiento
                        vigente a la fecha de presentación por el Cliente o Usuario y registrado
                        ante la autoridad fiscal competente, la Constancia de inscripción en el
                        Registro Federal de Contribuyentes;
                      </li>
                      <li>
                        Testimonio o copia certificada del instrumento que contenga los poderes del
                        representante o apoderados legales, expedido por fedatario público, cuando
                        no estén contenidos en el documento que compruebe la constitución de la
                        persona moral de que se trate, así como la identificación de dichos
                        representantes, apoderados legales o personas que realicen el acto u
                        operación con dicha persona moral, que deberá ser, en todo caso, un
                        documento original oficial emitido por autoridad competente, vigente a la
                        fecha de su presentación, que contenga la fotografía, firma y, en su caso,
                        domicilio de la referida persona.
                      </li>
                      <p>
                        Para efectos de lo dispuesto por este inciso, se considerarán como
                        documentos válidos de identificación la credencial para votar expedida por
                        el Instituto Federal Electoral, así como cualquier identificación vigente
                        con fotografía y firma, emitida por autoridades federales, estatales y
                        municipales. Asimismo, respecto de las personas físicas de nacionalidad
                        extranjera, se considerarán como documentos válidos de identificación,
                        además de los anteriormente referidos en este párrafo, el pasaporte o la
                        documentación expedida por el Instituto Nacional de Migración que acredite
                        su condición de estancia en el país. En el caso de aquellos representantes o
                        apoderados legales que se encuentren fuera del territorio nacional y que no
                        cuenten con pasaporte, la identificación deberá ser, en todo caso, un
                        documento original oficial emitido por autoridad competente del país de
                        origen, vigente a la fecha de su presentación, que contenga la fotografía,
                        firma y, en su caso, domicilio del citado representante. Para efectos de lo
                        anterior, se considerarán como documentos válidos de identificación, la
                        licencia de conducir y las credenciales emitidas por autoridades federales
                        del país de que se trate. La verificación de la autenticidad de los citados
                        documentos será responsabilidad nuestra;
                      </p>
                      <li>
                        Cédula de Identificación Fiscal expedida por el SAT o en su defecto,
                        comprobante de asignación del número de identificación fiscal, emitido por
                        autoridad competente de su país de nacionalidad, cuando cuenten con
                        cualquiera de éstas, y
                      </li>
                      <li>
                        Constancia por la que se acredite que le solicitamos a nuestro Cliente o
                        Usuario información acerca de si tiene conocimiento de la existencia del
                        Dueño Beneficiario, la cual deberá estar firmada por los que participen
                        directamente en el acto u operación.
                      </li>
                      <p>
                        En el supuesto en que el representante o apoderados legales de la persona
                        moral manifiesten que sí tienen conocimiento de la existencia del Dueño
                        Beneficiario, debemos identificarlo (Fracción VII del Artículo 12 de las
                        Reglas de Carácter General), cuando dicho Cliente o Usuario cuente con dicha
                        información (solicitándole la información contenida en el Apartado
                        denominado “INFORMACIÓN A PRESENTAR EN LOS AVISOS A LA SHCP”).
                      </p>
                      <p>
                        Respecto del documento a que se refiere el numeral i), del inciso b)
                        anterior, debemos requerir que éste se encuentre debidamente legalizado o,
                        en el caso en que el país en donde se expidió dicho documento sea parte de
                        "La Convención por la que se Suprime el Requisito de Legalización de los
                        Documentos Públicos Extranjeros”, adoptada en La Haya, Países Bajos, el 5 de
                        octubre de 1961, bastará que dicho documento lleve fijada la apostilla a que
                        dicha Convención se refiere. En el evento en que el Cliente o Usuario
                        respectivo no presente la documentación referida en el presente párrafo
                        debidamente legalizada o apostillada, será nuestra responsabilidad
                        cerciorarnos de la autenticidad de dicha documentación.
                      </p>
                    </ol>
                  </ol>
                </ol>
              </li>
            </ol>
            <li>AVISO A LA SHCP</li>
            <p>
              Se deberán presentar los Avisos a la Secretaría de manera Mensual a más tardar el día
              17 del mes inmediato siguiente a aquél en que se hubiera llevado a cabo la operación
              que le diere origen y que sea objeto de aviso (Artículos 17, 18 y 23 de la Ley).
            </p>
            <li>NFORMACIÓN A PRESENTAR EN LOS AVISOS A LA SHCP</li>
            <p>
              La presentación de Avisos deberá hacerse a través del Portal de Internet
              https://sppld.sat.gob.mx/sppld/ donde deberán estar debidamente registrados el RFC de
             UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. y la Firma Electrónica vigente.
            </p>
            <ol type="A">
              <li>DATOS DE LA IDENTIFICACIÓN DE LA PERSONA OBJETO DE AVISO</li>
              <ol type="I">
                <li>DATOS DE TIPO DE PERSONA OBJETO DE AVISO</li>
                <ol type="a">
                  <li>Cuando se trata de una Persona Física</li>
                  <TablaPersonasFisicas indexLabel="3.5.1.1" />
                  <li>Cuando se trata de una Persona Moral</li>
                  <TablaPersonasMoral indexLabel="3.5.1.2" />
                  <li>Cuando se trata de un Fideicomiso</li>
                  <TablaFideicomiso indexLabel="3.5.1.3" />
                </ol>
                <li>DATOS DE TIPO DE DOMICILIO DE LA PERSONA OBJETO DE AVISO</li>
                <ol type="a">
                  <li>Cuando el Domicilio es Nacional</li>
                  <TablaDomicilioNacional />
                  <li>Cuando el Domicilio es extranjero</li>
                  <TablaDomicilioExtranjero />
                </ol>
                <li>DATOS DEL NÚMERO TELEFÓNICO</li>
                <ol type="a">
                  <TablaNumeroTelefonico />
                </ol>
              </ol>
              <li>DATOS DE IDENTIFICACIÓN DEL BENEFICIARIO CONTROLADOR O DUEÑO BENEFICIARIO</li>
              <ol type="a">
                <li>Cuando se trata de una Persona Física</li>
                <TablaPersonasFisicas indexLabel="3.6.1.1" />
                <li>Cuando se trata de una Persona Moral</li>
                <TablaPersonasMoral indexLabel="3.6.1.2" />
                <li>Cuando se trata de un Fideicomiso</li>
                <TablaFideicomiso indexLabel="3.6.1.3" />
              </ol>
            </ol>
          </li>
          <li>
            <strong>RESTRICCIÓN DE OPERACIONES EN EFECTIVO</strong>
            <ol type="A">
              <li>MEDIDAS DE RESTRICCIÓN EN BASE A LOS MONTOS OPERADOS</li>
              <ol type="I">
                <li>RECARGAS O DEPÓSITOS</li>
                <p>
                  Quedan prohibidas las recargas o depósitos a través de dinero en efectivo por un
                  valor igual o superior a los 3210 veces el Salario Mínimo Vigente en el Distrito
                  Federal, equivalentes a la cantidad de $258,726.00 (DOSCIENTOS CINCUENTA Y OCHO
                  MIL SETECIENTOS VEINTISES PESOS 00/100 MN) al día en que se realice el pago. Dicha
                  cantidad puede ser en una o en varias transacciones.{' '}
                </p>
                <li> PAGO DE PREMIOS</li>
                <p>
                  {' '}
                  Queda prohibido dar cumplimiento a obligaciones como liquidar o pagar premios en
                  efectivo por un valor igual o superior a los 3210 veces el Salario Mínimo Vigente
                  en el Distrito federal, equivalentes a la cantidad de $258,726.00 (DOSCIENTOS
                  CINCUENTA Y OCHO MIL SETECIENTOS VEINTISES PESOS 00/100 MN). El pago de premios se
                  realizara mediante transferencia bancaria a la cuenta que los usuarios
                  proporcionen para tal efecto, está cuenta deberá estar a nombre del titular de la
                  cuenta.
                </p>
              </ol>
            </ol>
          </li>
          <li>
            <strong>VISITAS DE VERIFICACIÓN</strong>
            <p>
              La Secretaría podrá comprobar el cumplimiento de las obligaciones previstas en la
              Legislación, mediante la práctica de visitas de verificación; para este efecto se
              deberá proporcionar exclusivamente la información y documentos soporte que esté
              directamente relacionada con la Operación (Capítulo V de la Ley).
            </p>
          </li>
          <li>
            <strong> RESERVA Y MANEJO DE INFORMACIÓN</strong>
            <p>
              La información y documentación deberá conservarse de manera física o electrónica, por
              un plazo de cinco años contado a partir de la fecha de la realización de la Operación.
            </p>
          </li>
          <li>
            <strong>SANCIONES ADMINISTRATIVAS</strong>
            <ol type="A">
              <li>INFRACCIONES Y SANCIONES</li>
              <p>
                Las multas y sanciones serán las que se indican en el Artículo 54 de la Ley Federal
                Para la Prevención e identificación de Operaciones con Recursos de Procedencia
                Ilícita, las cuales citamos a continuación:
              </p>
              <ol>
                <li>
                  {' '}
                  Se Aplicará multa equivalente a doscientos y hasta dos mil días de Salario Mínimo
                  general vigente en el Distrito Federal en los siguientes casos:{' '}
                </li>
                <ol type="I">
                  <li>
                    Se abstengan de cumplir con los requerimientos que les formule la Secretaría en
                    términos de esta Ley;
                  </li>
                  <li>
                    Incumplan con cualquiera de las obligaciones establecidas en el artículo 18 de
                    esta Ley;
                  </li>
                  <li>
                    Incumplan con la obligación de presentar en tiempo los Avisos a que se refiere
                    el artículo 17 de esta Ley.
                  </li>
                  <p>
                    La sanción prevista en esta fracción será aplicable cuando la presentación del
                    Aviso se realice a más tardar dentro de los treinta días siguientes a la fecha
                    en que debió haber sido presentado. En caso de que la extemporaneidad u omisión
                    exceda este plazo, se aplicará la sanción prevista para el caso de omisión en el
                    artículo 55 de esta Ley, o
                  </p>
                  <li>
                    Incumplan con la obligación de presentar los Avisos sin reunir los requisitos a
                    que se refiere el artículo 24 de esta Ley;
                  </li>
                </ol>
                <li>
                  {' '}
                  Se aplicará multa equivalente a dos mil y hasta diez mil días de salario mínimo
                  general vigente en el Distrito Federal en el caso de la fracción V del artículo 53
                  de esta LEY, el cual citamos a continuación:{' '}
                </li>
                <ol type="I">
                  <li>Incumplan con las obligaciones que impone el artículo 33 de esta Ley;</li>
                </ol>
                <li>
                  {' '}
                  Se aplicará multa equivalente a diez mil y hasta sesenta y cinco mil días de
                  salario mínimo general vigente en el Distrito Federal, o del diez al cien por
                  ciento del valor del acto u operación, cuando sean cuantificables en dinero, la
                  que resulte mayor en el caso de las fracciones VI y VII del artículo 53 de esta
                  Ley, las cuales se indican a continuación:
                </li>
                <ol type="I">
                  <li>
                    Omitan presentar los Avisos a que se refiere el artículo 17 de esta Ley, y
                  </li>
                  <li>
                    Participen en cualquiera de los actos u operaciones prohibidos por el artículo
                    32 de esta Ley.
                  </li>
                </ol>
              </ol>
            </ol>
          </li>
          <li>
            <strong>DELITOS TIPIFICADOS EN LA LFPIORPI</strong>
            <p>
              Los delitos y las penas que contempla la Ley Federal Para la Prevención e
              identificación de Operaciones con Recursos de Procedencia Ilícita, son los señalados
              en los artículos 62 y 63 de la misma Ley, los cuales transcribimos a continuación:{' '}
            </p>
            <ul>
              <li>
                <strong>Artículo 62.-</strong> Se sancionará con prisión de dos a ocho años y con
                quinientos a dos mil días multa conforme al Código Penal Federal, a quien:
              </li>
              <ol type="I">
                <li>
                  Proporcione de manera dolosa a quienes deban dar Avisos, información,
                  documentación, datos o imágenes que sean falsos, o sean completamente ilegibles,
                  para ser incorporados en aquellos que deban presentarse;
                </li>
                <li>
                  De manera dolosa, modifique o altere información, documentación, datos o imágenes
                  destinados a ser incorporados a los Avisos, o incorporados en avisos presentados.
                </li>
              </ol>
              <li>
                <strong> Artículo 63.-</strong> Se sancionará con prisión de cuatro a diez años y
                con quinientos a dos mil días multa conforme al Código Penal Federal:
              </li>
              <ol type="I">
                <li>
                  Al servidor público de alguna de las dependencias o entidades de la administración
                  pública federal, del Poder Judicial de la Federación, de la Procuraduría o de los
                  órganos constitucionales autónomos que indebidamente utilice la información,
                  datos, documentación o imágenes a las que tenga acceso o reciban con motivo de
                  esta Ley, o que transgreda lo dispuesto por el Capítulo VI de la misma, en materia
                  de la reserva y el manejo de información, y
                </li>
                <li>
                  A quien, sin contar con autorización de la autoridad competente, revele o
                  divulgue, por cualquier medio, información en la que se vincule a una persona
                  física o moral o servidor público con cualquier Aviso o requerimiento de
                  información hecho entre autoridades, en relación con algún acto u operación
                  relacionada con las Actividades Vulnerables, independientemente de que el Aviso
                  exista o no.
                </li>
              </ol>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};
const MedidasLudopatia = () => {
  return (
    <div className="flex-col col-90 col-offset-5">
      <div className="flex-row">
        <h1 className="flex-col" style={{ fontSize: '130%' }}>
          <u>MEDIDAS ADOPTADAS PARA LA PREVENCIÓN DE LA LUDOPATÍA</u>
        </h1>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <p>
            Juego Responsable es la misión de UNOCAPALI LA PAZ OPERADORA S.A. DE C.V.. Te invitamos a
            participar de manera divertida y para ello es importante identifiques qué tipo de
            jugador eres.
          </p>
          <h4>TIPOS DE JUGADOR</h4>
          <ul>
            <li>
              <u>Jugador Social.</u>
            </li>
            <p>
              Participa en juegos de azar y apuesta con el único fin de pasar un rato agradable. La
              cantidad de dinero que apuesta está limitada por su capacidad económica y de esta
              manera no arriesga su estabilidad. Apuesta con gente que conoce y el tiempo que dedica
              al juego no interviene con sus responsabilidades. Puede dejar de jugar en el momento
              en que lo decide.
            </p>
            <li>
              <u>Jugador Profesional</u>
            </li>
            <p>
              Su único fin al participar en juegos de azar y apostar es ganar dinero. No se deja
              llevar por sus emociones y apuesta cuando determina que es muy factible ganar.
            </p>
            <li>
              <u>Jugador Problema.</u>
            </li>
            <p>
              Participa en juegos de azar y apuesta cantidades que tenía destinadas a otro fin.
              Apuesta cantidades que afectan su economía y la de su familia. No tiene capacidad de
              decidir en qué momento retirarse. Está expuesto a convertirse en jugador patológico.
            </p>
            <li>Jugador Patológico.</li>
            <p>
              No tiene control sobre el impulso y tiene la necesidad de jugar a pesar de estar
              consciente de las consecuencias. Es incapaz de abstenerse o detenerse, pues
              experimenta una sensación creciente de excitación y tensión antes de ir a jugar y la
              experiencia placentera al momento de jugar, lo que le lleva a alterar las diferentes
              áreas de su vida como la educativa, laboral, económica, familiar y social. Llega a
              mentir o a cometer delitos como robo, fraude o falsificación.
            </p>
          </ul>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>TEST DE LUDOPATÍA</h4>
          <p>
            Este test te permite identificar si tu manera de jugar es problemática o no, a fin de
            recibir atención especial para prevenir consecuencias negativas del juego.
          </p>
          <ul>
            <li>¿Alguna vez en tu vida has apostado o jugado en exceso?</li>
            <li>¿Te preocupa tu conducta de apuesta o de juego en exceso?</li>
            <li>¿Tienes una necesidad urgente por apostar o jugar?</li>
            <li>
              ¿Has aumentado las cantidades de apuesta o el tiempo dedicado al juego a fin de lograr
              la misma emoción de la primera vez?
            </li>
            <li>¿Has tenido problemas en tu vida por apostar o jugar en exceso?</li>
            <li>¿Has intentado dejar de apostar/jugar sin éxito?</li>
          </ul>
          <p>
            Si tu respuesta es afirmativa para alguna de las preguntas, es importante que recibas
            orientación y atención para tratar tu manera de jugar.
          </p>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>FACTORES DE RIESGO</h4>
          <ul>
            <li>La poca información que se tiene al respecto.</li>
            <li>La aceptación social del juego ocasional, que lleva al juego habitual.</li>
            <li>
              La facilidad para adentrarse en los juegos a través de sorteos aparentemente
              inofensivos.
            </li>
            <li>Adicciones al alcohol o a las drogas.</li>
            <li>Baja tolerancia a la frustración.</li>
            <li>Necesidad de aceptación social.</li>
            <li>Antecedentes de ansiedad, depresión, impulsividad.</li>
            <li>
              En los hombres, la adicción suele comenzar con la adolescencia, mientras que en las
              mujeres suele presentarse entre los 20 y 40 años de edad.{' '}
            </li>
            <li>Las situaciones estresantes pueden empeorar los problemas de juego.</li>
          </ul>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col">
          <h4>FACTORES DE PROTECCIÓN</h4>
          <ul>
            <li>La comunicación e integración familiar basada en valores y principios morales.</li>
            <li>Información acerca del tema.</li>
            <li>
              Ayuda por parte de profesionales, familiares, asociaciones y grupos de autoayuda.
            </li>
            <li>Deseo del ludópata de abandonar el juego.</li>
          </ul>
        </div>
      </div>
      <div className="flex-row">
        <h4>CENTROS DE APOYO</h4>
      </div>
      <div className="flex-row row-center">
        <div className="flex-col">
          <div className="flex-row responsive-sm">
            <div className="flex-col">
              <div className="flex-row">
                <img
                  src={samadhi}
                  className="img-responsive text-center flex-col"
                  style={{ maxHeight: '200px' }}
                />
              </div>
              <div className="flex-row">
                <a
                  href="https://www.centrosamadhi.org"
                  target="_blank"
                  className="flex-col co-center text-center"
                  rel="noopener noreferrer"
                >
                  www.centrosamadhi.org
                </a>
              </div>
              <div className="flex-row">
                <strong className="flex-col col-center text-center">Tel. (61) 4236-7712</strong>
              </div>
            </div>
            <div className="flex-col">
              <div className="flex-row">
                <img
                  src={satori}
                  className="img-responsive text-center flex-col"
                  style={{ maxHeight: '200px' }}
                />
              </div>
              <div className="flex-row">
                <a
                  href="https://www.clinicasatori.com"
                  target="_blank"
                  className="flex-col co-center text-center"
                  rel="noopener noreferrer"
                >
                  www.clinicasatori.com
                </a>
              </div>
            </div>
            <div className="flex-col">
              <div className="flex-row">
                <img
                  src={cenadic}
                  className="img-responsive text-center flex-col"
                  style={{ maxHeight: '200px' }}
                />
              </div>
              <div className="flex-row">
                <a
                  href="http://www.cenadic.salud.gob.mx/"
                  target="_blank"
                  className="flex-col co-center text-center"
                  rel="noopener noreferrer"
                >
                  www.cenadic.salud.gob.mx/
                </a>
              </div>
            </div>
          </div>
          <div className="flex-row">
            <a
              href="http://www.juegosysorteos.gob.mx/es/Juegos_y_Sorteos/Centros_de_Apoyo_al_Ludopata"
              target="_blank"
              className="flex-col co-center text-center"
              rel="noopener noreferrer"
            >
              <i className="ion-link" /> Centros de Apoyo al Ludópata
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContentLegales = () => {
  return (
    <>
      <MetaTags>
        <meta property='og:url' content='https://www.pickwin.mx/legal/legales' />
        <meta property='og:type' content='article' />
        <meta property='og:title' content='Legales' />
        <meta property='og:description' content='Legales' />
      </MetaTags>
      <div className="flex-container column">
        <div className="flex-row hidden-md hidden-lg hidden-xlg panel-pickwin">
          <div className="flex-row">
            <strong className="flex-col text-center">LEGALES </strong>
          </div>
          <ul className=" flex-col navLegalSections">
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#proteccionSitio">
                {' '}
                <i className="ion-link" /> Medidas De Protección Al Sitio Web, Que Garantizan La
                Inviolabilidad Y No Manipulación De Las Apuestas.{' '}
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#sistemaControlInterno">
                {' '}
                <i className="ion-link" /> Descripción Del Sistema De Control Interno Para Las
                Transacciónes Vía Internet
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#expedicionComporobantes">
                {' '}
                <i className="ion-link" /> Procedimiento Para La Expedición De Comprobantes De
                Apuestas En Operaciones Vía Internet, Telefónica O Electrónica
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#mecanicaCaptacion">
                {' '}
                <i className="ion-link" /> Mécanica De Captación De Apuestas Y Reglas Generales De Los
                Juegos De Apuestas.
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#contactoSugerencias">
                {' '}
                <i className="ion-link" /> Datos De Contacto Para Quejas Y Sugerencias
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#ubicacionServidor">
                {' '}
                <i className="ion-link" /> Ubicación Del Servidor Y Oficinas Administrativas
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#metodosPago">
                {' '}
                <i className="ion-link" /> Características Y Métodos De Pago{' '}
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#derechosObligaciones">
                {' '}
                <i className="ion-link" /> Derechos Y/O Obligaciones De Los Usuarios, Permisionario
                Y/U Operador{' '}
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#medidasAdoptadas">
                {' '}
                <i className="ion-link" /> Medidas Adoptadas Para La Prevención De Operaciones Con
                Recursos De Procedencia Ilicita{' '}
              </a>
            </li>
            <li className="flex-row row-center responsive-sm">
              <a className="flex-col" href="#medidasLudopatia">
                {' '}
                <i className="ion-link" /> Medidas Adoptadas Para La Prevención De La Ludopatía{' '}
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-row responsive-md">
          <div className="flex-col col-80 col-offset-10 ">
            <div className="flex-row">
              <div className="flex-col col-no-padding col-20 legal-section hidden-sm hidden-xs overflow overflow-y overflow-legals">
                <div className="flex-row">
                  <strong className="flex-col text-center">LEGALES </strong>
                </div>
                <div className="flex-row">
                  <ul className=" flex-col navLegalSections">
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#proteccionSitio">
                        {' '}
                        <i className="ion-link" /> Medidas De Protección Al Sitio Web, Que Garantizan
                        La Inviolabilidad Y No Manipulación De Las Apuestas.{' '}
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#sistemaControlInterno">
                        {' '}
                        <i className="ion-link" /> Descripción Del Sistema De Control Interno Para Las
                        Transacciónes Vía Internet
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#expedicionComporobantes">
                        {' '}
                        <i className="ion-link" /> Procedimiento Para La Expedición De Comprobantes De
                        Apuestas En Operaciones Vía Internet, Telefónica O Electrónica
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#mecanicaCaptacion">
                        {' '}
                        <i className="ion-link" /> Mécanica De Captación De Apuestas Y Reglas
                        Generales De Los Juegos De Apuestas.
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#contactoSugerencias">
                        {' '}
                        <i className="ion-link" /> Datos De Contacto Para Quejas Y Sugerencias
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#ubicacionServidor">
                        {' '}
                        <i className="ion-link" /> Ubicación Del Servidor Y Oficinas Administrativas
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#metodosPago">
                        {' '}
                        <i className="ion-link" /> Características Y Métodos De Pago{' '}
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#derechosObligaciones">
                        {' '}
                        <i className="ion-link" /> Derechos Y/O Obligaciones De Los Usuarios,
                        Permisionario Y/U Operador{' '}
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#medidasAdoptadas">
                        {' '}
                        <i className="ion-link" /> Medidas Adoptadas Para La Prevención De Operaciones
                        Con Recursos De Procedencia Ilicita{' '}
                      </a>
                    </li>
                    <li className="flex-row row-center r">
                      <a className="flex-col" href="#medidasLudopatia">
                        {' '}
                        <i className="ion-link" /> Medidas Adoptadas Para La Prevención De La
                        Ludopatía{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex-col col-no-padding overflow overflow-y overflow-legals">
                <section className="flex-row legal-section responsive-sm" id="proteccionSitio">
                  <ProteccionSitio />
                </section>
                <section className="flex-row legal-section responsive-sm" id="sistemaControlInterno">
                  <SistemaControlInterno />
                </section>
                <section
                  className="flex-row legal-section responsive-sm"
                  id="expedicionComporobantes"
                >
                  <ExpedicionComprobantes />
                </section>
                <section className="flex-row legal-section responsive-sm" id="mecanicaCaptacion">
                  <MecanicaCaptacion />
                </section>
                <section className="flex-row legal-section responsive-sm" id="contactoSugerencias">
                  <ContactoSugerencias />
                </section>
                <section className="flex-row legal-section responsive-sm" id="ubicacionServidor">
                  <UbicacionServidor />
                </section>
                <section className="flex-row legal-section responsive-sm" id="metodosPago">
                  <MetodosPago />
                </section>
                <section className="flex-row legal-section responsive-sm" id="derechosObligaciones">
                  <DerechosObligaciones />
                </section>
                <section className="flex-row legal-section responsive-sm" id="medidasAdoptadas">
                  <MedidasAdoptadas />
                </section>
                <section className="flex-row legal-section responsive-sm" id="medidasLudopatia">
                  <MedidasLudopatia />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContentPrivacidad = () => {
  return (
    <>
    <MetaTags>
      <meta property='og:url' content='https://www.pickwin.mx/legal/privacidad' />
      <meta property='og:type' content='article' />
      <meta property='og:title' content='Aviso de Privacidad' />
      <meta property='og:description' content='Aviso de Privacidad de Pickwin' />
    </MetaTags>
    <div className="flex-row responsive-md">
      <div className="flex-col col-80 col-offset-10 responsive-sm legal-section lateralPadding">
        <h1 style={{ fontSize: '130%' }}>
          <u>GLOSARIO</u>
        </h1>
        <div>
          <ul>
            <li><strong>Política de Privacidad:</strong> En el presente aviso por escrito se describen las políticas mediante las cuales www.seguroganas.mx (el “Sitio”) informa a los clientes y al público en general la forma en que se manejan los datos personales de los propietarios y a los que el Sitio tiene acceso.</li>
            <li><strong>Contenido:</strong> Texto, imágenes, videos, clips de audio, software y cualquier otro tipo de información que se pueda generar, proporcionar o encontrar en o a través del Sitio.</li>
            <li><strong>Datos Personales:</strong> También se le llama “Información Personal”. Cualquier información referente a un individuo identificado o identificable.</li>
            <li><strong>Datos Personales Confidenciales:</strong> Datos personales que se refieren a la información más íntima de su propietario, la cual, al usarse indebidamente, puede generar discriminación o riesgo hacia su propietario. En particular, se considerará confidencial cuando pueda revelar aspectos como el origen racial o étnico, el estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opinión política y preferencias sexuales.</li>
            <li><strong>Las Partes del Sitio:</strong> filiales, oficinas sede y sucesoras, así como a cada uno de sus empleados, beneficiarios, miembros del personal, asesores, agentes y directores del Sitio.</li>
            <li><strong>Servicios:</strong> Son los servicios que el Sitio pone a disposición de los Usuarios a través del Sitio.</li>
            <li><strong>Sitio:</strong> Sitio web www.seguroganas.mx y su aplicación para dispositivos móviles.</li>
            {/* <li> www.seguroganas.mx: Denominada también como “nosotros”, somos UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. es un operador de Producciones Móviles, S.A. de C.V., Permisionaria autorizada por la Secretaría de Gobernación, quien actúa bajo el amparo del Permiso Federal No. DGAJS/SCEVF/P-06/2005-Ter, en términos de: (i) oficio número DGJS/DGAAD/DCRCA/1512/2018 de fecha 24 de agosto de 2018 emitido por la Dirección de Control y Regulación de Centros de Apuestas Remotas y Salas de Sorteos adscrita a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación, (ii) oficio número DGJS/DGAAD/DCRCA/2345/2018 de fecha 28 de noviembre de 2018 emitido por la Dirección de Control y Regulación de Centros de Apuestas Remotas y Salas de Sorteos adscrita a la Dirección General de Juegos y Sorteos de la Secretaría de Gobernación y (iii) contrato de prestación de servicios para el acceso no exclusivo a la plataforma informática www.seguroganas.mx celebrado con Comercializadora FEDAVI, S. de R.L. de C.V., con domicilio en calle Dr. Ignacio Barajas Lozano número 119, colonia Buenos Aires, alcaldía Cuauhtémoc, Ciudad de México, C.P. 06780. </li>*/}
            <li><strong>Términos y Condiciones:</strong> Son los términos y condiciones que establecen derechos y obligaciones a los que el usuario o visitante no registrado tienen acceso o deben cumplir para poder acceder al Sitio.</li>
            <li><strong>Usuario:</strong> Llamado también “Usted” o “Propietario”. Cualquier persona que haya proporcionado la información solicitada en el Sitio para poder acceder a él, por lo tanto, los derechos y obligaciones establecidos en los Términos y Condiciones pueden ser diferentes a los de un visitante no registrado.</li>
            <li><strong>Visitante no registrado:</strong> Se le puede referir también como “Usted”. Cualquier persona que no tenga la calidad de usuario, pero que pueda tener acceso limitado al Sitio, sin haber proporcionado la información necesaria para ser considerado un usuario.</li>
          </ul>
        </div>

      </div>
    </div>
    <div className="flex-row responsive-md">
      <div className="flex-col col-80 col-offset-10 responsive-sm legal-section lateralPadding">
        <h1 style={{ fontSize: '130%' }}>
          <u>AVISO DE PRIVACIDAD</u>
        </h1>
        <ol start="1" type="1">
          <li>
            <strong>Identidad y Domicilio del Responsable.</strong>
            <p>
              UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. (en lo sucesivo UNOCAPALI) Operador debidamente
              autorizado de Producciones Móviles, S.A. de C.V. (Permisionaria autorizado por la
              Secretaría de Gobernación) es Responsable del Tratamiento de sus datos personales
              conforme a los términos del presente con motivo de uso y/o registro de Usuario que Ud.
              haga de los servicios que presta a través del portal de Internet www.Capali.net (en lo
              sucesivo, el “Portal”).
            </p>
            <p>
              Los términos identificados con mayúscula inicial tendrán el significado que les
              corresponda de acuerdo con la Ley de Protección de la Persona Frente al Tratamiento de
              sus Datos Personales (LPPFTDP) y/o los Términos de Uso de los cuales este Aviso de
              Privacidad es parte integrante.
            </p>
          </li>
        </ol>
        <ol start="2" type="1">
          <li>
            <strong>Datos Personales Sujetos a Tratamiento</strong>
            <p>
              Para los fines establecidos en este Aviso de Privacidad, podemos obtener de usted los
              siguientes datos personales:
            </p>
            <ul type="circle">
              <li>
                Datos de identificación: nombre, nacionalidad, lugar y fecha de nacimiento, registro
                federal de contribuyentes, C.U.R.P., fecha de nacimiento, estado civil, profesión, y
                copia de su identificación oficial, en su caso.
              </li>
              <li>
                Datos de contacto: domicilio incluyendo estado, código postal y país, correo
                electrónico y teléfonos.
              </li>
              <li>
                Registros informáticos relacionados con actividades y comportamiento en internet:
                horario de navegación, dirección de Protocolo de Internet ("IP"), tiempo de
                navegación en nuestro sitio web, secciones consultadas y páginas web que visitó
                antes de ingresar a nuestro Portal.
              </li>
              <li>
                Datos Financieros: para efectos de completar las transacciones en línea, UNOCAPALI
                podrá recolectar su número de tarjeta de crédito o débito, fecha de vencimiento,
                nombre del titular y copia de las tarjetas y recibos de las compras realizadas en EL
                PORTAL, en su caso.
              </li>
            </ul>
            <p>
              Nota: Datos Personales Sensibles. Ocasionalmente podemos recibir de usted datos
              personales que son, o podrían llegar a ser considerados como datos personales
              sensibles por la LPPFTDP, como lo son hábitos y preferencias de juego; nosotros no le
              solicitaremos estos datos. Obtendremos esta información únicamente cuando usted nos la
              proporcione voluntariamente y solamente los usaremos para atender su solicitud, y en
              caso de ser aplicable, cumplir las obligaciones derivadas de la relación jurídica de
              servicios que pudiéramos tener con usted.
            </p>
          </li>
        </ol>
        {/* <ol start="3" type="1">
          <li>
            <strong>Finalidades del tratamiento de sus datos personales.</strong>
            <p>
              La obtención y tratamiento de sus datos personales se realiza para las siguientes
              finalidades, mismas que consideramos como necesarias para la existencia, mantenimiento
              y cumplimiento de la relación que tenemos con usted:
            </p>
            <ol start="1" type="a">
              <li>
                Proporcionarle información de nuestros productos y servicios cuando usted lo
                solicite;
              </li>
              <li>Recibir quejas, y dar seguimiento a solicitudes;</li>
              <li>
                Mantener actualizados nuestros registros a fin de poder responder a sus consultas;
              </li>
              <li>Venta del bien y/o servicio que solicite;</li>
              <li>
                Para la participación en concursos y/o promociones a través de nuestros sitios web
                y/o cuentas de redes sociales oficiales;
              </li>
              <li>Para encuestas de servicios que nos permitan mejorar nuestros productos; y</li>
              <li>Para fines de mercadotecnia y/o estudio de mercado;</li>
              <li>
                Para elaborar los reportes de actividades vulnerables a que estamos obligados
                conforme a la legislación correspondiente.
              </li>
            </ol>
            <p>
              Solamente que usted así nos lo indique, procederemos a inscribirlo a nuestra base de
              datos para enviarle información de nuestros productos, a través del cual recibirá
              mensajes publicitarios, información que usted puede solicitar dejar de recibir en
              cualquier momento, utilizando el mecanismo incluido en todos nuestros correos.
            </p>
            <p>
              Las finalidades identificadas en los incisos a, b, c, d, e son requeridas, con motivo
              de nuestra relación jurídica que tenemos con usted. Las finalidades identificadas en
              los incisos f y g no son necesarias para mantener nuestra relación jurídica por lo que
              usted tiene el derecho a oponerse o bien revocar su consentimiento para que Pickwin
              deje de tratar sus datos personales con dichas finalidades, lo cual podrá
              contactándonos a las direcciones que aparecen en el numeral 8 de este Aviso.
            </p>
          </li>
        </ol> */}
        <ol start="3" type="1">
          <li>
            <strong>Finalidades del Tratamiento.</strong>
            <p>Procesamos la información personal con los siguientes propósitos:</p>
            <p>Usamos tus datos personales para asegurándonos que cumplimos con los requerimientos regulatorios, obligaciones legales y requerimientos relacionados con el negocio y la mejor experiencia. Procesamos tus datos personales para los siguientes propósitos:</p>
            <ul>
              <li>
                Crear y manejar la cuenta del jugador y sus registros: Usamos tus datos personales, como tu nombre y dirección de correo electrónico, para ayudar a crear tu cuenta y, de ser necesario, contactarte mediante tu información de contacto registrada en tu cuenta.
                <br/>
                También usamos tus datos personales, como el tipo de tu dispositivo, ubicación.
              </li>
              <li>
                Verificación de clientes;<br/>
                Identificación y verificación de personas asegurando que los clientes sean mayores de 18 años así como la legislación contra el lavado de dinero. Para verificar tu edad y los detalles que registras en tu cuenta, podemos divulgar dicha información a terceros aprobados con muchas protecciones aplicadas. Esto se requiere para cumplir con nuestras obligaciones legales.
              </li>
              <li>
                Obligaciones legales y regulatorias;<br/>
                Requerimos procesar y retener tus datos personales incluyendo, tu nombre, número de cuenta, fecha de nacimiento y ubicación, para evitar actividades fraudulentas, para detectar y/o investigar crímenes y para asegurar un juego responsable. Adicionalmente, también procesamos tus datos personales para cumplir con nuestros requerimientos de reportes internos y externos. Esto se requiere para cumplir con nuestras obligaciones legales.
              </li>
              <li>
                Mercadotecnia;<br/>
                Proporcionar al Usuario información, comunicaciones y notificaciones sobre nuestros Sitios y Apps, nuestros productos y/o servicios y los productos y servicios de terceros exhibidos en nuestros Sitios y Apps y los términos y condiciones aplicables a los mismos.
              </li>
              <li>
                Prevención de lavado de dinero y actividades fraudulentas;<br/>
                Contamos con un proveedor con diferentes políticas y procedimientos para detectar y prevenir las actividades criminales. Esto incluye detección del fraude y lavado de Dinero. Este procedimiento se necesita para cumplir con nuestras obligaciones legales.
              </li>
              <li>
                Procesar tus transacciones de quinielas y apuestas en línea<br/>
                Usamos tus datos personales tipos de pago, métodos de pago e historial de depósitos y apuestas para permitirte depositar y retirar, así como para realizar nuestros reportes regulatorios.
              </li>
              <li>
                Ofrecer a los jugadores información y soporte sobre nuestros productos y servicios<br/>
                Usamos tus datos para poder brindar una mejor información respecto a nuestros productos y así mejorar la experiencia en nuestra plataforma
              </li>
            </ul>
            <br/>
            <p>Trataremos los datos personales para las siguientes finalidades secundarias o accesorias:</p>
            <ol start="1" type="a">
              <li>Mercadotecnia, publicidad, promoción, prospección comercial, procesos de referencias, la elaboración, valoración y análisis de perfiles de los Usuarios para el desarrollo y ofrecimiento de nuevos productos y servicios, realización de encuestas ajenas a nuestros productos o servicios, fines analíticos y estadísticos, todos ellos relacionados con los productos y servicios de los Responsables, sus afiliadas, subsidiarias,  aliados y otros terceros; y</li>
              <li>Para contactarlo sobre o enviarle información de novedades, noticias, ofertas de los Responsables o terceros, boletines, publicidad o marketing, ventas a distancia, haciendo uso de medios tales como, pero sin limitar, correo electrónico, notificaciones PUSH, mensajes de texto (SMS), mensajería instantánea, mensaje de whatsapp y ventanas de diálogo (pop-ups).</li>
            </ol>
            <br/>
            <strong>Revocación del Consentimiento para el tratamiento de sus datos</strong>
            <p>En caso de que no desee que sus datos personales sean tratados para estas finalidades secundarias o accesorias, Usted puede (i) enviar desde este momento un correo electrónico a soporte@pickwin.net siguiendo el procedimiento a que se refiere el numeral 9 del presente Aviso de Privacidad, o (ii) en el caso del párrafo b. antes mencionado, a través de nuestras Apps.</p>
          </li>
        </ol>

        <ol start="4" type="1">
          <li>
            <strong>Modificación o eliminación de sus datos personales.</strong>
            <p>Usted puede cambiar cualquier información personal en su Cuenta simplemente editándola dentro de su Cuenta o solicitando
              cambios enviando un correo electrónico a soporte@pickwin.net.
              Usted puede solicitar la eliminación de cierta información personal que mantenemos, y haremos esfuerzos comercialmente razonables para cumplir con su solicitud,
              pero tenga en cuenta que podemos estar legalmente o de otra manera obligados a mantener dicha información y no eliminarla
              (o para mantener esta información durante un tiempo determinado, en cuyo caso vamos a cumplir con su solicitud de eliminación sólo después de haber cumplido con tales requisitos).
              Las solicitudes de eliminación pueden hacerse enviando un correo electrónico a soporte@pickwin.net.
              Cuando eliminamos información, ésta se borra de la base de datos activa, pero puede permanecer en nuestros archivos.
              También podemos conservar su información para prevenir el fraude u otra actividad ilegal o para fines similares.
            </p>
          </li>
        </ol>

        <ol start="5" type="1">
          <li>
            <strong>Uso de cookies, web beacons y dispositivos similares.</strong>
            <p>
              En nuestro sitio web, recabamos información empleando cookies, web beacons o
              dispositivos similares. Las cookies, web beacons y dispositivos similares utilizados
              en nuestro sitio web simplifican la administración de los sitios y la navegación a
              través del mismo y mejoran la experiencia en línea del visitante. La información
              recogida incluye, entre otra, (1) dirección IP del usuario (por ejemplo,
              princeton.edu-192.168.1.1), el tipo de navegador que utiliza (Netscape Navigator o
              Microsoft Explorer) y su sistema operativo (Windows o Linux); (2) las páginas de los
              sitios web que visita el usuario; y (3) otros sitios web que visitó el usuario antes
              de acceder al nuestro.
            </p>
            <p>
              Las cookies NO contienen datos personales del usuario como puede ser su nombre o
              dirección de correo electrónico. La mayoría de los navegadores aceptan cookies
              automáticamente y pueden configurarse para que no lo hagan o para que notifiquen al
              usuario cada vez que reciben una cookie. Los siguientes enlaces muestran como ajustar
              la configuración del navegador de los navegadores comúnmente usados:
            </p>
            <ul type="circle">
              <li>
                Chrome{' '}
                <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">
                  https://support.google.com/chrome/answer/95647?hl=es
                </a>
              </li>
              <li>
                Internet Explorer{' '}
                <a
                  href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
              </li>
              <li>
                Firefox{' '}
                <a
                  href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias
                </a>
              </li>
              <li>
                Safari{' '}
                <a href="https://support.apple.com/kb/ph21411?locale=es_ES" target="_blank" rel="noopener noreferrer">
                  https://support.apple.com/kb/ph21411?locale=es_ES
                </a>
              </li>
            </ul>
            <p>
              Por favor considere que si usted elige bloquear las cookies es posible que se afecte
              la forma en que usted usa nuestro sitio web o algún recurso en particular.
            </p>
            <p>
              En ocasiones también utilizamos web beacons. Utilizamos web beacons en nuestros sitios
              web para contabilizar y reconocer a los usuarios mediante al acceso a nuestras
              cookies. De esta forma, podemos personalizar nuestro sitio web y mejorar su
              experiencia de navegación. También podemos incluir web beacons en forma de mensajes de
              correo eléctrico en formato HTML que enviamos para determinar qué mensajes
              electrónicos han sido abiertos.
            </p>
          </li>
        </ol>
        <ol start="6" type="1">
          <li>
            <strong>Remisiones de datos personales a proveedores.</strong>
            <p>
              UNOCAPALI puede remitir total o parcialmente los datos personales de nuestros usuarios a
              proveedores de servicios que nos apoyan en algún proceso. Estos proveedores incluyen:
              (i) empresas que tecnología que nos prestan diversos servicios de comunicaciones,
              contabilidad o infraestructura; (ii) empresas que nos prestan servicios de
              mercadotecnia y estudios de mercado. En todos estos casos, UNOCAPALI se asegura de que
              estas empresas asuman obligaciones contractuales que permitan que los datos personales
              de nuestros usuarios se encuentren protegidos, además de que se hace de su
              conocimiento las obligaciones aplicables conforme a la LPPFTDP.
            </p>
          </li>
        </ol>
        <ol start="7" type="1">
          <li>
            <strong>¿A quién podemos transferir sus datos personales?</strong>
            <p>
              Podemos transferir los datos personales de nuestros usuarios a otras empresas o a
              autoridades en ciertos casos, para las finalidades establecidas en este Aviso de
              Privacidad. Estas transferencias incluyen las siguientes:
            </p>
            <ol type="a">
              <li>
                UNOCAPALI puede transferir datos personales de sus usuarios a autoridades
                administrativas o judiciales, cuando la transferencia sea necesaria o legamente
                exigida para la salvaguarda de un interés público, la protección de los derechos de
                UNOCAPALI, la protección de derechos de terceros o para la procuración o administración
                de justicia.
              </li>
              <li>
                Asimismo, ocasionalmente, UNOCAPALI puede ser parte en alguna transacción corporativa
                (incluyendo fusiones, escisiones, ventas de activos o acciones, reestructuras
                corporativas, entre otras transacciones). En tanto que usualmente los usuarios son
                uno de los activos a ser considerados como parte de la transacción, UNOCAPALI puede
                comunicar sus datos personales a terceros en el contexto de dichas transacciones.
                Entre dichos terceros se encuentran, entre otros, los potenciales adquirentes de los
                activos y los asesores (legales, contables o financieros, entre otros) que
                participen en la transacción. Únicamente comunicaremos sus datos personales a dichos
                terceros en la medida que dicha comunicación resulte necesaria para evaluar o
                completar la transacción o cuando la transferencia resulte necesaria como
                consecuencia de la misma.
              </li>
              <li>
                En otros casos, cuando la ley permita esta transferencia sin el consentimiento
                previo del titular.
              </li>
            </ol>
            <p>
              Todas las transferencias anteriores (y las remisiones incluidas en el numeral 5
              anterior) pueden tener el carácter nacional o internacional; asimismo, todas son
              precisas para el mantenimiento o cumplimiento de la relación comercial con los
              clientes y, por lo tanto, no requerimos el consentimiento del cliente para
              realizarlas.
            </p>
            <p>
              Al proporcionar sus datos personales, usted acepta los términos y condiciones
              establecidos en el presente aviso de privacidad, usted manifiesta su consentimiento
              expreso para que UNOCAPALI haga uso, manejo y tratamiento de los mismos para los fines
              aquí estipulados.
            </p>
            <p>
              Usted consiente su tratamiento tanto dentro como fuera de Curacao y entiende que
              podrán ser tratados por Pickwin, sus sociedades subsidiarias, afiliadas o
              relacionadas, sus distribuidores autorizados y/o por aquellos proveedores de servicios
              con quienes tiene una relación jurídica y fungen como encargados en términos de los
              establecido por la LPPFTDP.
            </p>
            <p>
              Por lo anterior, la información y datos personales que usted proporcione a UNOCAPALI se
              almacenarán en la Base de Datos de UNOCAPALI que podría localizarse fuera de territorio
              costarricense. A su vez, UNOCAPALI en este acto manifiesta que los datos personales que
              sean recolectados de usted, podrán ser proporcionados a cualquier tercero con quien
              UNOCAPALI hubiese contratado servicios para el manejo de la Base de Datos para los fines
              indicados en el presente aviso.
            </p>
          </li>
        </ol>
        <ol start="8" type="1">
          <li>
            <strong>¿Cuáles son sus derechos?</strong>
            <p>
              Usted goza de los derechos de Acceso, Rectificación, Cancelación y Oposición al
              Tratamiento (los “Derechos ARCO”); asimismo, cuenta con el derecho de revocar en
              cualquier tiempo el consentimiento otorgado para el tratamiento de sus datos
              personales en la medida que la ley lo permita. Para el ejercicio de sus Derechos ARCO
              o su derecho de revocación o para formular cualquier duda o queja con relación al
              tratamiento de sus datos personales por favor contacte a nuestro Departamento de Datos
              Personales (“DDP”). Sus solicitudes serán evaluadas en los términos establecidos en la
              LPPFTDP y otras obligaciones aplicables a Pickwin. Nuestro DDP le comunicará (i) la
              información que se le solicitará para que se identifique así como los documentos que
              necesitará enviar junto con su solicitud; (ii) los plazos en los que recibirá una
              contestación sobre su solicitud; (iii) cómo debe de presentar su solicitud, incluyendo
              los formularios que puede usar para presentar su solicitud, si los hubiere, y; (iv) la
              modalidad o medio en que le entregaremos la información a usted. En virtud de lo
              anterior, Pickwin cumplirá en todo momento a las solicitudes que en el ejercicio de
              sus derechos contenidos en la LPPFTDP le haga llegar mediante un correo electrónico.
            </p>
          </li>
        </ol>
        <ol start="9" type="1">
          <li>
            <strong>Contacte a nuestro DDP.</strong>
            <p>
              Para efectos de cualquier aclaración, duda o solicitud conforme el presente aviso de
              privacidad y/o el manejo de sus datos personales, por favor envíenos un correo
              electrónico en atención a:
            </p>
            <p>
              <a href="mailto:hola@pickwin.net">{'hola@pickwin.net'}</a>
            </p>
          </li>
        </ol>

        <ol start="10" type="1">
          <li>
            <strong>
              ¿Qué opciones ofrece UNOCAPALI para limitar el uso o divulgación de sus datos personales?
            </strong>
            <p>
              UNOCAPALI le ofrece la posibilidad de tomar decisiones sobre cómo usamos sus datos
              personales; le ofrecemos diversos medios para informarnos acerca de su decisión.
            </p>
            <p>
              Si usted no desea recibir mensajes publicitarios de nosotros o cancelar su suscripción
              a nuestras bases de datos, usted: (a) puede solicitar cancelar su suscripción a
              nuestras listas de correo haciendo uso del mecanismo establecido en nuestros correos
              electrónicos; y/o (b) podrá contactar a nuestro DDP.
            </p>
          </li>
        </ol>

        <ol start="11" type="1">
          <li>
            <strong>Seguridad de sus datos personales</strong>
            <p>
              Nos comprometemos a cumplir con todo lo dispuesto por la LPPFTDP y a observar en todo
              momento, los principios de licitud, consentimiento, información, calidad, finalidad,
              lealtad, proporcionalidad y responsabilidad contenidos en la misma.
            </p>
            <p>
              UNOCAPALI ha adoptado medidas de seguridad físicas, organizacionales, y técnicas
              razonables para proteger sus datos personales en contra de pérdida, uso indebido o
              acceso no autorizado, alteración o divulgación ilegal de la información y datos
              personales que nos llegase a proporcionar en cumplimiento de lo dispuesto por la
              LPPFTDP.
            </p>
          </li>
        </ol>

        <ol start="12" type="1">
          <li>
            <strong>Conservación y Supresión de Datos</strong>
          </li>
          <p>Los usuarios pueden eliminar su cuenta desde el menú Perfil haciendo clic en el botón "Eliminar cuenta". La información de la tarjeta de crédito se eliminará automáticamente, y los datos personales se conservarán durante 45 días. Transcurrido ese plazo, se anonimizarán automáticamente. Si un usuario desea que su cuenta y sus datos se eliminen inmediatamente, puede ponerse en contacto con nosotros en soporte@pickwin.net.</p>
          <p>Cuando se elimina tu Cuenta, o ésta sea desactivada por nosotros. Hay un plazo de 45 días en el que podrás volver a restaurar tu Cuenta si esta fue eliminada de manera accidental. Pasados esos 45 días, comenzaremos el proceso para eliminar tu información personal de nuestros sistemas, a menos que:</p>
          <ul>
            <li>debemos guardarla para cumplir con la ley aplicable (por ejemplo, si realizas compras dentro de la App, algunos datos personales pueden conservarse con fines contables);</li>
            <li>debemos guardarla para demostrar nuestro cumplimiento con la ley aplicable (por ejemplo, si se bloquea una cuenta, conservamos algunos datos de la cuenta y un registro del comportamiento que ha llevado al bloqueo; esta información se conserva con fines probatorios en caso de consultas o reclamaciones legales relativas al bloqueo);</li>
            <li>existe un problema, reclamo o disputa pendiente que requiere que guardemos la información relevante hasta que se resuelva; o</li>
            <li>la información debe guardarse para nuestros intereses comerciales legítimos, como la prevención del fraude y la mejora de la seguridad de cada Miembro (por ejemplo, es posible que sea necesario guardar información para evitar que un usuario que fue bloqueado por exhibir un comportamiento peligroso o incidentes de seguridad abra una nueva cuenta).</li>
          </ul>
        </ol>

        <ol start="13" type="1">
          <li>
            <strong>Modificaciones al Aviso de Privacidad</strong>
            <p>
              UNOCAPALI se reserva el derecho de efectuar en cualquier momento modificaciones o
              actualizaciones al presente Aviso de Privacidad. El aviso modificado será publicado en
              lugares visibles en nuestra página web o se le hará llegar a través de correo
              electrónico, en caso de contar con información de contacto. Nuestros usuarios podrán
              verificar que el Aviso ha sido modificado en virtud de que haremos notar siempre la
              fecha de última actualización. En caso de que UNOCAPALI realice algún cambio sustancial
              en el tratamiento de sus datos personales, se lo haremos saber a través de una
              notificación. Dichos cambios le serán notificados por correo electrónico a la
              dirección de correo que usted haya registrado al momento de proporcionar sus datos
              personales o anunciando estos cambios en nuestros sitios web. Todo cambio entra en
              nuestra aviso de privacidad entra en vigor a los 15 días naturales de la publicación
              del nuevo aviso. Si usted no esta de acuerdo con las modificaciones, por favor
              contacte a nuestro DDP solicitando la cancelación de sus datos personales.
            </p>
          </li>
        </ol>
        <p className="text-right"><small>Última actualización: marzo de 2024</small></p>
      </div>
    </div>

    </>
 );
};

export const ContentTerminos = ({ isExternal }) => {
  return (
    <>
    <MetaTags>
      <meta property='og:url' content='https://www.pickwin.mx/legal/terminos' />
      <meta property='og:type' content='article' />
      <meta property='og:title' content='Terminos y Condiciones' />
      <meta property='og:description' content='Terminos y Condiciones de Pickwin' />
    </MetaTags>
    <div className="flex-row responsive-sm">
      <div
        className={`flex-col ${
          !isExternal ? 'col-80 col-offset-10' : ''
        }  responsive-sm legal-section lateralPadding`}
      >
        <h3>Términos y Condiciones de Servicio</h3>

        <p>
          <strong className="text-center" style={{ fontSize: '1.2em' }}>
            Fecha de actualización: 1 julio de 2019.
          </strong>
        </p>

        <p>
          POR FAVOR LEA ATENTAMENTE EL SIGUIENTE CONTRATO VINCULANTE. AL USAR EL SITIO USTED
          RECONOCE HABER LEÍDO Y ESTAR DE ACUERDO EN RESPETAR LOS TÉRMINOS Y CONDICIONES DE ESTE
          CONTRATO, LOS TÉRMINOS DE USO DE LA PLATAFORMA Y NUESTRA POLÍTICA DE PRIVACIDAD. SI USTED
          NO ESTÁ DE ACUERDO CON CUALQUIERA DE LOS TÉRMINOS DE ESTE CONTRATO, NO UTILICE ESTE SITIO
          WEB.
        </p>

        <p>
          <strong>1. Partes y objeto de este contrato:</strong>
        </p>
        <p>
          1.1.1 UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. (en lo sucesivo “UNOCAPALI”) Operador de
          Producciones Móviles, S.A. de C.V. (Permisionaria autorizado por la Secretaría de
          Gobernación), a través de la plataforma www.pickwin.mx, quien actúa bajo el amparo del
          Permiso Federal No. DGAJS/SCEVF/P-06/2005-Ter, en términos del oficio número
          DGJS/1580/2021 de fecha 30 de agosto de 2021 emitido por la Dirección de Control y
          Regulación de Centros de Apuestas Remotas y Salas de Sorteos adscrita a la Dirección
          General de Juegos y Sorteos de la Secretaría de Gobernación, y (iii) contrato de
          prestación de servicios para el acceso no exclusivo a la plataforma informática
          www.pickwin.mx celebrado con PWTEC, S.A.P.I DE C.V. (en lo sucesivo “PWTEC”).
        </p>
        <p>
          1.1.2 La página web de www.pickwin.net y/o www.pickwin.mx (colectivamente el “Sitio” o
          “Sitio Web”) - que incluye todo el texto, imágenes, audio, código y otros materiales que
          contienen o proporcionan (colectivamente, el “Contenido”) y todas las características,
          concursos y otros servicios que se ofrecen - son propiedad PWTEC y están operados por
          UNOCAPALI (en lo sucesivo, “Pickwin” o “Nosotros”). Para conocer más a detalle sobre los
          derechos de propiedad intelectual sobre el Sitio, así como sus usos permitidos, favor de
          dirigirse a nuestros Términos de Uso de la Plataforma.
        </p>

        <p>
          1.2 UNOCAPALI ofrece a través del Sitio la posibilidad de participar en competiciones de
          destreza conocidas como competencias Pickwin (en lo sucesivo, las “Competencias” y/o los
          “Servicios”). Algunas de estas Competencias son gratuitas pero usted requerirá depositar
          dinero para jugar en Competencias con costo de inscripción.
        </p>

        <p>
          1.3 Ese contrato cubre los acuerdos entre usted y Nosotros en relación a su uso del
          Servicio. Estos términos son un contrato entre usted y Nosotros.
        </p>

        <p>
          1.4 Para utilizar el Servicio usted tiene que abrir una cuenta personal y única relativa a
          una dirección de residencia, abierta por un individuo y mantenida con Nosotros (la “Cuenta
          de Jugador”). No se permitirá más de una cuenta por Jugador.
        </p>

        <p>
          <strong>2. Elegibilidad</strong>
        </p>
        <p>
          2.1. Usted debe tener al menos 18 años de edad, o la edad legal determinada por ley en el
          país o jurisdicción donde reside, para abrir una cuenta, participar en concursos, o ganar
          premios ofrecidos por el Sitio Web.
        </p>
        <p>
          2.1. Usted debe tener al menos 18 años de edad, o la edad legal determinada por ley en el
          país o jurisdicción donde reside, para abrir una cuenta, participar en concursos, o ganar
          premios ofrecidos por el Sitio Web.
        </p>
        <p>
          2.2. Si usted es menor de 18 años no puede usar el Sitio Web bajo ninguna circunstancia.
        </p>

        <p>
          2.3. Usted puede abrir, usar o controlar una sola Cuenta de Jugador para participar en los
          Servicios. Si Pickwin determina que usted ha abierto, usa o controla más de una Cuenta de
          Jugador, además de cualquier otro derecho que Pickwin pudiera tener, Pickwin se reserva el
          derecho de suspender o cancelar una o todas sus cuentas y dar por terminada, suspendida o
          revocada la concesión de los premios que haya ganado.
        </p>

        <p>
          2.4. Los empleados de Pickwin pueden utilizar el Sitio y lo harán de vez en cuando con el
          propósito exclusivo de probar la experiencia del usuario en el Sitio, socializar y
          competir con los clientes para construir una comunidad, y con otros objetivos razonables y
          justos a discreción de Pickwin. Los empleados no podrán retirar dinero o premios ni
          realizar ninguna otra acción que pueda poner en riesgo la integridad de la Cuenta del
          Jugador.
        </p>

        <p>
          <strong>3. Seguridad</strong>
        </p>
        <p>
          3.1. Sólo permitimos el acceso para utilizar el Servicio a través de redes seguras usando
          codificación de nombre de usuario y contraseña. Usted no puede utilizar el Servicio y
          jugar sin pasar por nuestro ingreso de seguridad de usuario.
        </p>
        <p>
          3.2. Usted no puede usar el Servicio para ningún uso comercial o en nombre de otra
          persona. Cualquier uso que usted haga del Servicio es sólo para sus propios fines
          privados.
        </p>
        <p>
          3.3. Usted debe mantener su nombre de usuario y contraseña confidencial y no debe revelar
          esa información a nadie. Usted no puede utilizar la contraseña de otra persona. Usted será
          responsable de todas las transacciones llevadas a cabo en relación con su Cuenta de
          Jugador usando su contraseña. Cada persona que se identifique a sí misma mediante la
          introducción de su nombre de usuario y contraseña correctos, será asumida por nosotros
          como usted y todas las transacciones en las que el nombre de usuario y la contraseña sean
          ingresados correctamente serán consideradas como válidas.
        </p>

        <p>
          3.4. Usted no permitirá a ningún tercero utilizar su Cuenta de Jugador para usar el
          Servicio y no utilizará la Cuenta de Jugador o método de pago de otra persona para acceder
          al Servicio o al Sitio Web.
        </p>
        <p>
          3.5 Es su responsabilidad asegurarse de que comprende las normas y procedimientos de los
          juegos en el Sitio y el uso del Servicio en general antes de participar en dichos juegos.
        </p>

        <p>
          3.6. Estamos utilizando los mejores métodos disponibles en la actualidad para el cifrado
          de la información de usuario y contraseña, y cualquier otra información confidencial
          transferida desde y hacia nuestros servidores, asegurando así al Jugador y a nosotros
          mismos contra intentos de manipulación por parte de terceros. Usted no va a ingresar,
          acceder o intentar forzar o evitar de alguna manera nuestra seguridad. Si tenemos la
          sospecha de que usted ha intentado o puede estar intentando ingresar, acceder o violar de
          algún modo nuestra seguridad, tendremos derecho a rescindir con efecto inmediato su acceso
          al Servicio y/o bloquear su cuenta, y nos reservamos el derecho de informar a las
          autoridades pertinentes.
        </p>

        <p>
          3.7. Sólo utilizaremos su información personal de acuerdo con nuestra Política de
          Privacidad, que puede ser leída en su totalidad en{' '}
          <a href="https://www.pickwin.mx/aviso-de-privacidad">
            https://www.pickwin.mx/aviso-de-privacidad.
          </a>
        </p>

        <p>
          3.8 Es obligación exclusiva del usuario proteger su contraseña. Cada persona que se
          identifique ingresando con su correo electrónico y su contraseña correctamente, es asumida
          por Pickwin, como el titular de la cuenta, no obstante, pudiera darse el caso de que un
          tercero no autorizado ingresara a su cuenta con los datos correctos.
        </p>

        <p>
          3.9 En el caso de que un tercero realizara un juego, concurso o sorteo, o se pensara que
          ha tenido acceso a la cuenta del usuario, se asumirá que tiene consentimiento del usuario
          titular y ningún juego, concurso o sorteo podrá ser cancelado bajo ninguna circunstancia.
        </p>

        <p>
          3. 10 Pickwin, tiene discreción total para corregir cualquier cuenta que resulte
          beneficiada por cualquier error y se reserva el derecho de responsabilizar al usuario por
          los daños y perjuicios ocasionados, en virtud de haber tomado ventaja de cualquier error
          técnico o administrativo.
        </p>

        <p>
          3.11 Es responsabilidad exclusiva del usuario asegurarse de que toda la información
          proporcionada ha sido perfectamente entendida por Pickwin.
        </p>

        <p>
          <strong>4. Condiciones de participación</strong>
        </p>
        <p>4. Condiciones de participanción</p>
        <p>4.1 Registro</p>

        <p>
          4.1.1. Con el fin de utilizar el Servicio, usted debe registrarse y tener una Cuenta de
          Jugador. Al registrarse como usuario del Servicio, usted se compromete a proporcionar,
          cuando se le solicite, información precisa, actualizada y completa sobre usted ("Datos de
          Registro"), y a actualizar la mencionada información en caso de haber algún cambio. El
          participante al momento de su registro acepta que se registre su dirección de Protocolo
          Internet ("IP") y su nombre de dominio. Una dirección "IP".
        </p>

        <p>
          4.1.2. Ustedes acepta que si se tiene la sospecha de que desde una misma dirección IP
          utilizan los Servicios más de un Jugador y por este hecho se saca una ventaja sobre el
          resto de los Jugadores o el Sitio Pickwin estará facultada para suspender las cuentas y
          retener cualesquier premios obtenidos en dichas condiciones de ventaja.
        </p>

        <p>4.2. Comunicaciones y Prácticas de Información.</p>

        <p>
          4.2.1. Como resultado de su registro en el Sitio, usted puede recibir ciertas
          comunicaciones comerciales de Pickwin. Usted entiende y acepta que estas comunicaciones
          forman parte del registro y que, en la medida requerida por la ley, puede optar en
          cualquier momento, por no recibirlas, ya sea utilizando la funcionalidad de darse de baja
          o enviando un correo electrónico a hola@pickwin.net. Si se da de baja, todavía podremos
          comunicarnos con usted por correo electrónico en la medida permitida por la ley aplicable.
        </p>

        <p>4.3. Descalificación y Cancelación</p>

        <p>
          4.3.1. Pickwin se reserva el derecho de cancelar las Competencias, conforme a la Sección
          17 de los presentes Términos y Condiciones, por causas razonables y justificadas.
          Cualquier partido que se cancele o posponga será tratado como "no hay acción" y no se
          tomara en cuenta para la Competencia en cuestión, siempre y cuando este partido no
          concluya en su totalidad en el mismo día hábil, si la quiniela cuenta con un método de
          desempate, el partido cancelado tampoco contara para el sistema de desempates. Para
          referencia de los tiempos por deporte ir a Sección 17.
        </p>

        <p>
          4.3.2. Si por cualquier motivo el Sitio no está funcionando como estaba previsto
          originalmente (por ejemplo, si el Sitio se corrompe o no permite el uso adecuado o el
          procesamiento de las inscripciones de acuerdo con las normas, o si la infección por un
          virus informático, errores, manipulación, intervención no autorizada, acciones de los
          participantes, fraude, fallas técnicas cualquier otra causa de cualquier tipo, en la
          opinión exclusiva de Pickwin, corrompe o afecta la administración, seguridad,
          imparcialidad, integridad o conducta apropiada del Sitio), Pickwin se reserva el derecho,
          de descalificar a cualquier individuo implicado en o en relación a la causa y/o a
          cancelar, terminar, ampliar, modificar o suspender el Servicio, y seleccionar ganador(es)
          entre todas las participaciones recibidas elegibles. Si se produce dicha cancelación,
          terminación, modificación o suspensión, la notificación podrá ser publicada en el Sitio.
        </p>

        <p>4.4 Adicionalmente, se consideran violaciones a los términos y condiciones:</p>

        <ul>
          <li>
            1. Uso ilegal o no autorizado por UNOCAPALI de cualquier robot, araña, aparato automatizado
            o cualquier otro proceso manual, para monitorear, copiar datos o realizar uso de los
            Servicios Pickwin.
          </li>
          <li>
            2. El usar cualquier contraseña o código de acceso, que no le corresponda si no es el
            destinatario de tal código de acceso o contraseña.
          </li>
          <li>
            3. Utilizar algún tipo de aparato, software o rutina que interfiera con el
            funcionamiento adecuado de los medios.
          </li>
          <li>
            4. Realizar o ejecutar acciones que impongan cargas razonables o desproporcionadas en la
            infraestructura de Pickwin.
          </li>
          <li>
            5. Copiar, reproducir, alterar, modificar o mostrar públicamente algún contenido (con
            excepción del uso personal) de los medios sin previo permiso expreso por escrito de
            Pickwin.
          </li>
        </ul>

        <p>
          Por este acto manifiesta su conformidad para que Pickwin, a discreción y sin previo aviso,
          pueda restringir su acceso al sitio, cancelar la compra de boletos o compras programadas,
          eliminar cualquier contenido del usuario no autorizado o ejercer cualquier otro recurso
          disponible, si tiene elementos o indicios de que su conducta o la de cualquier tercero,
          con la cual se crea que actúa en común o el contenido del usuario que proporciono son
          inconsistentes con los términos establecidos en esta página, con la legislación y que
          violan los derechos de Pickwin.
        </p>

        <p>
          <strong>5. Reglas de competencia </strong>
        </p>

        <p>5.1. Juego de Destreza</p>

        <p>
          5.1.1. El Sitio es una plataforma de juegos de destreza. Los ganadores son determinados
          por los criterios que figuran en las reglas de cada Competencia. En cada Competencia, los
          ganadores son quienes, al utilizar su habilidad y conocimiento sobre los deportes
          profesionales, obtienen la mayor cantidad de aciertos y se vuelven acreedores de un premio
          a ser redimido en picoins (concepto definido en la sección 5.3.1) o en dinero real, según
          se especifique en la sección de premios dentro de cada Competencia.
        </p>
        <p>5.2. Costo de entrada.</p>
        <p>
          5.2.1. Cada Competencia tiene una cuota de inscripción que aparece en créditos de picoins
          o en dinero real según se delimite en las reglas de cada Competencia. Cuando se opta por
          participar en una Competencia, esa cantidad en créditos de picoins o en dinero real, según
          sea el caso, será descontada de su cuenta.
        </p>

        <p>5.3. Picoins</p>
        <p>5.4. Puntos VIP</p>

        <p>
          5.4.1. Las Puntos VIP son un activo ficticio para uso exclusivo interno en el Sito Web que usted utilizará para ingresar a las Competencias y acceder a otros servicios. También es la forma de pago a los ganadores de algunas Competencias. Lo anterior, en el entendido que los Puntos VIP no deberán considerarse como una moneda, o cryptomenda según se establece en la legislación aplicable. No tienen equivalencia monetaria ni pueden ser redimidos o utilizados fuera del Sitio Web.
        </p>

        <p>
          5.3.1. Las Picoins son un activo ficticio para uso exclusivo interno en el Sito Web que
          usted utilizará para ingresar a las Competencias y acceder a otros servicios. También es
          la forma de pago a los ganadores de algunas Competencias. Lo anterior, en el entendido que
          los Picoins no deberán considerarse como una moneda, o cryptomenda según se establece en
          la legislación aplicable. No tienen equivalencia monetaria ni pueden ser redimidos o
          utilizados fuera del Sitio Web.
        </p>

        <p>
          5.4.1. Después de terminar cada Competencia, se anunciarán los ganadores tentativos, pero
          serán objeto de una verificación final. En cada Competencia, los jugadores que acumulen el
          mayor número de puntos y cumplan con los requisitos de elegibilidad y las normas
          aplicables ganarán los picoins o dinero real establecidos en los detalles de la
          Competencia. Los premios se añadirán al saldo de la cuenta del jugador que gane. En caso
          de empate, el premio se dividirá en partes iguales entre los jugadores empatados, a menos
          que se especifique lo contrario.
        </p>

        <p>
          5.4.2. Pickwin ofrece varios tipos de Competencias y Pickwin anunciará, adelantado los
          costos de inscripción y los premios a entregar en cada una de ellas.
        </p>

        <p>
          5.4.3. El cálculo de los premios se basa en los resultados a partir del momento en que la
          puntuación final es tabulada por Pickwin. Una vez que los ganadores son anunciados
          inicialmente por Pickwin, los resultados de la puntuación no serán modificados en función
          de ajustes oficiales realizados por las ligas profesionales, aunque nos reservamos el
          derecho de hacer ajustes basados en errores o irregularidades en la transmisión de
          información que recibimos de nuestro proveedor de estadísticas o en el cálculo de los
          resultados. También podemos hacer ajustes en caso de incumplimiento con estos Términos.
          Pickwin no tiene obligación de retrasar la concesión de un premio a la espera de cualquier
          ajuste, y se reserva el derecho de restituir el pago en caso de cualquier ajuste. Usted se
          compromete a cooperar con nuestros esfuerzos para restituir los pagos.
        </p>

        <p>
          5.4.4. No se permite la sustitución o transferencia de un premio. Todos los impuestos
          asociados a la recepción o uso de cualquier premio son responsabilidad exclusiva del
          ganador. En el caso de que la adjudicación de los premios a los ganadores de la
          Competencia sea cuestionada de manera fundamentada por cualquier autoridad legal, Pickwin
          se reserva el derecho, de determinar si adjudica o no dichos premios. En todas las
          disputas que surjan de la determinación de los ganadores de las Competencias, Pickwin es
          el único juez y sus acciones son definitivas y vinculantes.
        </p>

        <p>5.5. Notificación</p>

        <p>
          5.5.1. Los ganadores son generalmente publicados en el Sitio luego de la finalización de
          cada Competencia.
        </p>

        <p>5.6. Política de reembolso</p>

        <p>
          5.6.1 Todos los pagos son finales. Excepto por los reembolso que procedan de acuerdo con
          la legislación aplicable y únicamente cuando así lo determine la autoridad competente, no
          se emitirán reembolsos. En el caso de una disputa sobre la identidad de la persona que
          ingresa a una Competencia, se considerará que la inscripción fue presentada por la persona
          a cuyo nombre se registró la cuenta.
        </p>

        <p>
          <strong>6. Sus responsabilidades</strong>
        </p>

        <p>
          6.1. Nosotros no hacemos ninguna declaración ni ofrecemos garantía con respecto a la
          legalidad o no del acceso y uso del Servicio en su país de residencia y usted es el único
          responsable de determinar si dicho acceso y uso del Servicio es legal. Garantizamos además
          a este respecto que no tenemos ninguna intención de permitir que usted infrinja ninguna
          ley aplicable.
        </p>

        <p>
          6.2. Usted no podrá transferir de ninguna manera sus derechos establecidos en este
          contrato sin nuestro consentimiento previo por escrito.
        </p>

        <p>
          6.3. Salvo por lo establecido en el presente Contrato y las obligaciones de UNOCAPALI
          referidas en este Sección 6, usted es completamente responsable de todos los impuestos y
          otros gastos fortuitos o que provengan de las ganancias resultantes de la utilización del
          Servicio.
        </p>

        <p>6.4. Es su responsabilidad informarnos de cualquier cambio en sus datos de registro.</p>

        <p>6.5. Pagos</p>
        <p>
          6.5.1. Usted acepta que nosotros o una empresa de procesamiento de pagos en nuestro nombre
          ("Openpay") se encargará de todas las transacciones de la cuenta. Usted acepta
          que el Procesador de Pagos se reserva el derecho de retener cualquier pago si tiene algún
          motivo para creer o sospechar que usted puede estar involucrado o ha incurrido en
          prácticas fraudulentas, de colusión, ilegales, o inapropiadas.
        </p>

        <p>
          6.5.2. Usted acepta abonar en su totalidad todos y cada uno de los pagos que nos deba a
          nosotros o a un tercero en relación al uso del Servicio. Además, acepta no realizar ningún
          rechazo de débito y/o renunciar, cancelar o modificar de otro modo alguno de sus pagos
          vencidos, y en tal caso usted nos reembolsará y compensará por dichos pagos pendientes,
          incluyendo cualquier gasto en el que incurramos durante en el proceso de recolección del
          pago.
        </p>

        <p>
          6.5.3. Usted acepta que su Cuenta de Jugador no se puede utilizar como una cuenta bancaria
          y/o cuenta de transferencias de un instrumento de procesamiento de pagos a otro. A
          discreción única de Pickwin, las Cuentas de Jugador que fallen en facturar un monto de
          depósito al menos una vez (1x) pueden estar sujetas a un recargo de 15% de la cantidad
          depositada (y a otros cargos de la transacción).
        </p>

        <p>
          6.5.4. Por favor tome en cuenta que haremos lo posible por atender su solicitud en la
          forma de pago y la moneda de pago de su retiro. Esto, sin embargo, no puede ser
          garantizado. Por lo tanto, podemos procesar y pagar retiros en un método de pago diferente
          al solicitado por usted, como a través de diferentes proveedores de pago, un giro bancario
          o una transferencia bancaria.
        </p>

        <p>
          6.6. Usted acepta que es el único responsable del suministro y mantenimiento de todos los
          equipos informáticos y redes de telecomunicaciones y servicios de acceso a Internet que
          necesite utilizar para acceder al Servicio. No seremos responsables de ninguna manera por
          cualquier pérdida causada a usted por el servicio de Internet o cualquier proveedor de
          servicios de telecomunicaciones que haya empleado con el fin de acceder al Servicio o al
          Sitio Web.
        </p>

        <p>
          6.7. Usted no cometerá ningún acto o demostrará ninguna conducta que dañe nuestra
          reputación o la de cualquier otro proveedor de servicios relacionado.
        </p>

        <p>
          6.8. Usted reconoce que si abre una Cuenta de Jugador, nos indemnizará y nos mantendrá en
          paz y a salvo de y contra toda y completamente cualquier pérdida, costos, gastos,
          reclamaciones, demandas, responsabilidades y daños causados que puedan surgir como
          consecuencia (a) de su ingreso, uso o reutilización del Sitio Web o el Servicio de forma
          ilegal; (b) del incumplimiento de cualquiera de los términos y disposiciones de estos
          Términos. Asimismo, acepta indemnizar completamente, defender y eximir a Pickwin, sus
          funcionarios, directores, empleados, agentes, contratistas y proveedores de y contra todas
          las reclamaciones, responsabilidades, daños, pérdidas, costos y gastos, incluyendo los
          honorarios de abogados, que puedan surgir de cualquier incumplimiento de estos Términos
          por usted, y cualquier otra responsabilidad derivada de su uso indebido del Servicio.
        </p>

        <p>
          6.9. Nos reservamos el derecho de modificar estos Términos ocasionalmente. Los cambios
          serán publicados dentro de nuestro Sitio por lo que le pedimos verifique si existe una
          notificación de cambio disponible. En caso de que exista algún cambio que consideremos
          sustancial, se lo haremos saber vía correo electrónico.
        </p>
        <ul>
          <li>
            (a) Notificación a Ganadores Todos los usuarios como usted, suscritos al sistema, y
            después de realizado el pago de premios correspondiente al sorteo en el que haya
            participado, si resultase ganador de un premio, podrá recibir un correo electrónico
            notificándole que ha sido un afortunado ganador. No aplica para el juego de
            Instantáneos.
          </li>
          <li>
            (d) Pago de Premios e Impuestos En el pago de premios iguales o mayores a $ 10,000.00
            (Diez Mil pesos 00/100 MN), UNOCAPALI o quien éste designe, entregará constancia de
            retención de impuestos al participante, quien deberá proporcionar su Registro Federal de
            Contribuyentes, RFC o Clave Única de Registro de Población (CURP). Cuando se trate de
            premios menores a la cantidad señalada y a petición del ganador, se entregará constancia
            de retención de impuestos, siempre y cuando, éste proporcione copia de su RFC o CURP. En
            caso de no proporcionar la información solicitada, UNOCAPALI se reserva la obligación de
            pago, hasta en tanto se cumplan con los requisitos fiscales por parte del cliente.
          </li>
        </ul>

        <p>
          <strong>7. Sus declaraciones y garantías</strong>
        </p>

        <p>
          6.11. Un mal funcionamiento anula todas las Competencias y los pagos. El mal
          funcionamiento se define en este contexto como una avería o un error en el funcionamiento
          del Sitio o de su equipo
        </p>

        <p>7. Sus declaraciones y garantías</p>

        <p>7.1. Usted manifiesta y garantiza que:</p>

        <p>7.1.1. es elegible de acuerdo con la definición de estos Términos</p>

        <p>7.1.2. Usted está actuando por su propio derecho y en su propio nombre</p>
        <p>7.1.3. no está restringido por principios legales.</p>

        <p>
          7.1.4. todos los datos que proporcione o haya proporcionado en el proceso de registro en
          el Sitio son exactos y continuará actualizando dichos datos en caso de haber algún cambio.
        </p>

        <p>
          7.1.5. que es plenamente consciente del hecho de que existe un riesgo de perder dinero a
          través de las Competencias.
        </p>

        <p>
          7.1.6. Usted no está depositando fondos procedentes de actividades criminales y / o
          ilegales y / o no autorizados.
        </p>

        <p>
          7.1.7. Usted no conduce de otro modo actividades criminales o ilegales ni/o tiene la
          intención de utilizar su cuenta en relación con tales actividades; no utiliza o pretende
          utilizar o tiene la intención de permitir que otra persona utilice su cuenta para
          cualquier fin ilegal o prohibido, incluyendo, pero sin limitarse al fraude o el blanqueo
          de dinero, bajo cualquier ley, incluyendo sin limitación las leyes de su jurisdicción y
          las leyes que se aplican a nosotros.
        </p>

        <p>
          7.1.6. No está depositando fondos procedentes de actividades criminales o no autorizadas;
        </p>

        <p>
          7.1.7. no realiza actividades criminales y/o intenta utilizar su Cuenta de Jugador en
          relación con esas actividades. No está utilizando o intentando utilizar o intentando
          permitir que otra persona utilice el Servicio y la Cuenta de Jugador para cualquier
          actividad prohibida o ilegal, incluyendo, pero no limitado a, fraude o blanqueo de dinero,
          bajo cualquier ley aplicable en su jurisdicción y las leyes que se aplican a nosotros;
        </p>

        <p>
          7.1.8. no está conspirando o intenta conspirar o tiene la intención de participar, directa
          o indirectamente, en ninguna confabulación con otro jugador en el transcurso de algún
          juego en el que participa o participará en el Sitio.
        </p>

        <p>
          7.1.9. no es menor de (i) 18 años de edad; o (ii) la edad en que este Servicio es legal
          bajo la ley de la jurisdicción que le corresponda, el que sea mayor.
        </p>

        <p>
          7.1.10. los datos de la tarjeta de débito/crédito que nos proporcionó en el proceso de
          registro son los del titular de la Cuenta de Jugador registrada y la tarjeta no ha sido
          reportada como extraviada o robada;
        </p>

        <p>
          7.1.11. no es un funcionario, director, empleado, consultor o agente de Pickwin o de una
          de nuestras compañías afiliadas o subsidiarias o proveedores o vendedores, y usted no
          tiene parentesco con ninguno de ellos (a los efectos de esta cláusula, el término
          "parentesco" significa cónyuge, pareja, padre, hijo o hermano). En el caso en el que usted
          haya violado esta prohibición, usted perderá el derecho a sus ganancias, sin demérito a
          otras acciones que se podrán tomar en su contra.
        </p>

        <p>
          7.1.12. No ha tenido previamente una Cuenta de Jugador que haya sido suspendida o
          cancelada, ni ha tenido rechazos en el débito de fondos a través de una Cuenta de Jugador
          o mantiene otra Cuenta de Jugador activa.
        </p>

        <p>
          <strong>8. Conducta</strong>
        </p>
        <p>
          8.1. Como condición de uso, usted promete no utilizar el Servicio para ningún propósito
          que sea ilegal o prohibido por estos Términos, o cualquier otro fin no previsto por
          Pickwin. A modo de ejemplo, pero no limitado a, usted se compromete a no: abusar, acosar,
          suplantar, intimidar o amenazar a otros usuarios. publicar o transmitir, o hacer que sea
          publicado o transmitido, cualquier Contenido que infrinja, sea calumnioso, difamatorio,
          abusivo, ofensivo, obsceno, pornográfico o de otra manera viole cualquier ley o derecho de
          terceros. utilizar el Servicio para cualquier propósito no autorizado, o en violación de
          cualquier ley, incluyendo las leyes de propiedad intelectual. publicar o transmitir, o
          hacer que sea publicada o transmitida, cualquier comunicación o solicitud destinada a
          obtener la contraseña, cuenta o información privada de cualquier otro usuario. crear o
          enviar correo electrónico no deseado ("spam") a cualquier otro usuario. infringir los
          derechos de propiedad intelectual de Pickwin, sus usuarios o cualquier tercero. publicar
          comentarios con enlaces a programas de afiliados, planes de marketing multi-level, otros
          sitios o contenido fuera de tema. postear, enviar correos electrónicos, transmitir, cargar
          o poner a disposición algún material que contenga un virus de software o cualquier otro
          código informático, archivos o programas diseñados o que funcionen para interrumpir,
          destruir o limitar la funcionalidad de cualquier software o hardware o equipo de
          telecomunicaciones. utilizar un robot, spider, scraper, u otros elementos automatizados,
          para lograr el acceso al Servicio por cualquier propósito (excepto para el acceso de RSS
          feed) sin nuestro permiso expreso por escrito. Además, usted acepta que no podrá: (1)
          tomar cualquier acción que imponga o pueda imponer, a nuestra única discreción, una carga
          no razonable o desproporcionadamente grande en nuestra infraestructura; (2) interferir o
          intentar interferir con el adecuado funcionamiento del Servicio o de cualquier actividad
          realizada en el Servicio; o (3) evitar cualquier medida que utilicemos para impedir o
          restringir el acceso al Servicio. utilizar medios artificiales, incluyendo la creación de
          cuentas de usuario múltiples, para mejorar su posición en el Sitio y en la comunidad.
          publicitar o solicitar, a cualquier usuario comprar o vender cualquier producto o
          servicio, o utilizar cualquier información obtenida del Servicio con el fin de contactar,
          publicitar, solicitar, o vender a cualquier usuario sin su previo consentimiento. vender o
          transferir su perfil. tratar de influir en cualquier evento deportivo que es objeto de un
          Competencia de Pickwin en el que usted está involucrado o en el que tenga un interés
          directo o indirecto.
        </p>

        <p>
          8.2. Una violación de nuestras reglas puede resultar en la eliminación de su Contenido del
          Servicio y/o la cancelación de su cuenta. Usted reconoce y acepta que Pickwin podrá
          eliminar cualquier Contenido creado por el usuario y terminar cualquier Cuenta de Jugador
          en cualquier momento y por cualquier razón (incluyendo, pero no limitado a, la recepción
          de las reclamaciones o alegaciones de terceros o autoridades relacionadas con dicho
          Contenido creado por el usuario). Para reportar violaciones a estos Términos y Condiciones
          de uso, por favor póngase en contacto con nosotros.
        </p>

        <p>
          <strong>9. Reserva de Derechos</strong>
        </p>
        <p>
          En la medida de lo permitido por la legislación aplicable nos reservamos el derecho a:
        </p>

        <p>9.1.1. Negar el registro en el Sitio o el Servicio a cualquier solicitante;</p>

        <p>9.1.2. Negar la entrada a una Competencia;</p>

        <p>
          9.1.3. cambiar, suspender, eliminar, modificar o añadir algún juego o Competencia en el
          Sitio.
        </p>

        <p>
          9.1.4. Hacer preguntas sobre usted, incluyendo una verificación de crédito, crédito de
          terceros y de las instituciones financieras, de acuerdo con la información que usted nos
          ha proporcionado.
        </p>

        <p>
          9.1.5. En el caso de cualquier disputa con respecto a cualquier participación o ganancia,
          nuestra decisión será definitiva y vinculante.
        </p>

        <p>
          9.1.6. Retener cualquier pago o ganancia hasta que la identidad de la persona ganadora sea
          verificada con el fin de garantizar que el pago se realiza a la persona correcta. Para
          ello, le podemos solicitar que nos proporcione una identificación notarial, oficial o
          identificación certificada equivalente conforme a la legislación aplicable en su
          jurisdicción.
        </p>

        <p>
          9.1.7. Podremos, en cualquier momento, revelar a terceros parte de su información personal
          de conformidad con nuestro Aviso de Privacidad.
        </p>

        <p>
          9.1.8. En caso de que sospechemos un fraude o una actividad fraudulenta de su parte o si
          se rechaza el crédito de alguno de sus pagos, tendremos el derecho de retener cualquier
          pago o monto ganado por usted o que usted debía cobrar y, si es necesario, cobrar
          legalmente cualquier pago adeudado por usted.
        </p>

        <p>
          Asimismo, Pickwin podrá solicitar copia de su identificación oficial, copia de la tarjeta
          de crédito o débito, ó copia del recibo de compra firmado por el titular para cualquier
          aclaración.
        </p>

        <p>
          9.1.9. En cualquier momento y sin previo aviso podemos terminar su uso del Servicio y
          bloquear su Cuenta de Jugador si consideramos que usted está en incumplimiento de
          cualquiera de los términos y disposiciones de este Contrato o que está actuando de forma
          ilegal. En tales circunstancias, no estaremos bajo ninguna obligación de devolver o
          reembolsarle ningún fondo de su Cuenta de Jugador.
        </p>

        <p>
          9.1.10. Nos reservamos el derecho, a nuestra sola discreción, de ofrecer y anunciar, de
          vez en cuando, promociones, bonificaciones, bonos u otras ofertas especiales y cada oferta
          estará sujeta a términos y condiciones específicos que serán válidos durante un período de
          tiempo limitado. En relación con los términos específicos de las promociones mencionadas,
          podremos retener cualquier cantidad retirada de su cuenta que será superior a su depósito
          original. Además, nos reservamos el derecho de retener, disminuir o revertir cualquier
          pago o monto obtenido en premios o modificar alguna política en caso de que sospechemos
          que usted está abusando o intentando abusar cualquiera de las siguientes: (i) los bonos;
          (ii) otras promociones; o (iii) la política o reglas específicas determinadas en relación
          con cualquier Competencia o juego.
        </p>

        <p>
          9.1.11. Nos reservamos el derecho a transferir, ceder, sublicenciar o empeñar el presente
          Contrato, en parte o en su totalidad, a cualquier persona sin notificación alguna.
        </p>

        <p>
          9.1.12. En cualquier momento UNOCAPALI podrá suspender o cancelar la utilización del servicio
          por cualquier motivo legalmente no permitido, por ejemplo, ante la sospecha de un posible
          fraude o de una actividad atípica, o bien por razones de seguridad.
        </p>

        <p>
          9.1.13. UNOCAPALI tendrá el derecho de retener cualquier retiro, en el caso que creamos o
          sospechemos que usted puede estar involucrado o ha estado involucrado en actividades
          fraudulentas, colusorias, ilegales, apuestas irregulares o si nos preocupa la operación de
          la cuenta del cliente o de la solicitud de retiro. En tales casos, podremos comenzar y/o
          participar y/o asistir a cualquier investigación acerca de tales circunstancias.
        </p>

        <p>
          <strong>10. Limitaciones de garantía</strong>
        </p>

        <p>
          10.1. Usted entiende y acepta expresamente que el uso del Servicio es bajo su propio
          riesgo. El Servicio (incluyendo el Servicio y el Contenido) se proporciona "TAL CUAL" y
          "según disponibilidad", sin garantías de ningún tipo, ya sea expresa o implícita,
          incluyendo, sin limitación, las garantías implícitas de comerciabilidad, adecuación para
          un particular, propósito o no infracción. Usted reconoce que Pickwin no tiene control
          sobre, y no tiene obligación de tomar cualquier acción en relación a: qué usuarios
          obtienen acceso o uso del Servicio; el efecto que Pickwin o el Contenido puede tener en
          usted; cómo usted puede interpretar o utilizar el Contenido; o qué acciones puede tomar
          como resultado de haber sido expuesto al Contenido. El Servicio puede contener, o dirigir
          a otros sitios web que contienen información que algunas personas pueden encontrar
          ofensiva o inadecuada. Pickwin no asume la responsabilidad con respecto a cualquier
          Contenido incluido en o accesible a través del Servicio, y Pickwin no será responsable de
          la exactitud, el cumplimiento de copyright, legalidad o decencia del material contenido en
          o accedido a través del Servicio.
        </p>

        <p>
          <strong>11. Limitación de la responsabilidad</strong>
        </p>
        <p>
          11.1. USTED RECONOCE Y ACEPTA QUE USTED ASUME TODA LA RESPONSABILIDAD POR EL USO DEL SITIO
          Y DEL SERVICIO. USTED RECONOCE Y ACEPTA QUE CUALQUIER INFORMACIÓN QUE ENVÍE O RECIBA
          DURANTE SU USO DEL SITIO Y DEL SERVICIO NO PUEDE SER SEGURA Y PUEDE SER INTERCEPTADA O
          POSTERIORMENTE ADQUIRIDA POR PERSONAS NO AUTORIZADAS. USTED RECONOCE Y ACEPTA QUE UTILIZA
          EL SITIO Y EL SERVICIO BAJO SU PROPIO RIESGO. RECONOCIENDO LO ANTERIOR, USTED ENTIENDE Y
          ACEPTA QUE, EN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE, NI PICKWIN NI SUS
          PROVEEDORES O LICENCIANTES SERÁN RESPONSABLES POR DAÑOS DIRECTOS, INDIRECTOS,
          ACCIDENTALES, ESPECIALES, CONSECUENTES, PUNITIVOS, EJEMPLARES U OTROS DAÑOS DE CUALQUIER
          TIPO, INCLUYENDO, PERO SIN LIMITACIÓN A, LOS DAÑOS POR PÉRDIDA DE BENEFICIOS, BUENA
          VOLUNTAD, USO, DATOS U OTRAS PÉRDIDAS TANGIBLES O INTANGIBLES O CUALQUIER OTRO DAÑO BASADO
          EN UN CONTRATO, AGRAVIO, ESTRICTA RESPONSABILIDAD, O CUALQUIER OTRA TEORÍA (INCLUSO SI LA
          COMPAÑÍA HUBIESE SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES DAÑOS), COMO RESULTADO DE ESTE
          SITIO O SERVICIO; EL USO O LA IMPOSIBILIDAD DE USO DEL SITIO O EL SERVICIO; ACCESO NO
          AUTORIZADO O ALTERACIÓN DE SUS TRANSMISIONES O DATOS; DECLARACIONES O CONDUCTA DE
          CUALQUIER TERCERO EN EL SITIO O SERVICIO; CUALQUIER ACCIÓN QUE TOMEMOS O NO ADOPTEMOS COMO
          RESULTADO DE COMUNICACIONES QUE NOS ENVÍA; CUALQUIER INGRESO DE INFORMACIÓN INCORRECTO,
          ILEGIBLE, MAL DIRIGIDO, ROBADO, INVÁLIDO O FALSO; ERRORES HUMANOS; FALLAS TÉCNICAS;
          FALLAS, INCLUYENDO DE UTILIDAD PÚBLICA O CORTES DE TELÉFONO; OMISIONES, INTERRUPCIONES,
          TIEMPO DE ESPERA, SUPRESIONES O DEFECTOS DE CUALQUIER DISPOSITIVO O RED, PROVEEDORES, O
          SOFTWARE (INCLUYENDO, PERO NO LIMITADOS A, LOS QUE NO PERMITEN A UN USUARIO PARTICIPAR EN
          EL SERVICIO); CUALQUIER LESIÓN O DAÑO EN LOS EQUIPOS INFORMÁTICOS; INCAPACIDAD PARA
          ACCEDER COMPLETAMENTE AL SITIO O AL SERVICIO O A CUALQUIER OTRO SITIO WEB; ROBO,
          ALTERACIÓN, DESTRUCCIÓN O ACCESO NO AUTORIZADO O ALTERACIÓN DE INSCRIPCIONES, IMAGEN U
          OTROS CONTENIDOS DE CUALQUIER TIPO; DATOS PROCESADOS EN FORMA TARDÍA O INCORRECTA O
          INCOMPLETA O DATOS PERDIDOS; ERRORES TIPOGRÁFICOS, DE IMPRESIÓN U OTROS ERRORES, O
          CUALQUIER COMBINACIÓN DE ÉSTOS; O CUALQUIER OTRO ASUNTO RELACIONADO CON EL SITIO O EL
          SERVICIO. SIN PERJUICIO DE LO CONTRARIO CONTENIDO EN EL PRESENTE CONTRATO, LA
          RESPONSABILIDAD DE LA EMPRESA ANTE USTED POR CUALQUIER CAUSA E INDEPENDIENTEMENTE DE LA
          FORMA DE LA ACCIÓN, ESTARÁ EN TODO MOMENTO LIMITADA A LA CANTIDAD PAGADA POR USTED A LA
          EMPRESA PARA EL USO GENERAL DEL SITIO O EL SERVICIO DURANTE EL PLAZO DE SU INSCRIPCIÓN EN
          EL SITIO, NO INCLUIDOS LOS DERECHOS DE INSCRIPCIÓN PARA COMPETENCIAS FINALIZADOS ANTES DE
          LA CIRCUNSTANCIAS QUE DAN LUGAR A LA RECLAMACIÓN.
        </p>
        <p>
          11.2. Algunas jurisdicciones no permiten la exclusión de ciertas garantías o la limitación
          o exclusión de responsabilidad por daños incidentales o consecuentes. En consecuencia,
          algunas de las limitaciones anteriores pueden no aplicarse a su caso.
        </p>

        <p>
          <strong>12. Fuerza Mayor</strong>
        </p>

        <p>
          12.1. La imposibilidad de Pickwin para cumplir con cualquier disposición de estos Términos
          debido a un caso fortuito o de fuerza mayor como, por ejemplo, huracán, guerra, incendio,
          disturbio, terremoto, terrorismo, actos de enemigos públicos, acciones de autoridades
          gubernamentales fuera del control de Pickwin (exceptuando el cumplimiento de los códigos y
          reglamentos aplicables) u otra causa de fuerza mayor no se considerará un incumplimiento
          de este Contrato.
        </p>

        <p>
          <strong>13. Duración</strong>
        </p>
        <p>
          13.1. Este Contrato es efectivo desde el momento en que usted comienza a usar el Servicio.
        </p>

        <p>
          <strong>14. Modificación de los Términos de Uso</strong>
        </p>

        <p>
          14.1. Pickwin podrá modificar o reemplazar los Términos y Condiciones en cualquier
          momento. La versión más actualizada de los Términos será publicada en nuestro Sitio. Usted
          será responsable de revisar y familiarizarse con dichas modificaciones. Si una revisión de
          los Términos, a nuestro criterio, lo requiere, se lo notificaremos a través de la
          dirección de correo electrónico asociada a su cuenta. El uso continuo del Servicios luego
          de cualquier modificación de los Términos constituye su aceptación de los Términos y
          Condiciones modificadas.
        </p>

        <p>
          <strong>15. Ley y jurisdicción aplicable.</strong>
        </p>
        <p>
          Todas las cuestiones y preguntas relativas a la realización, validez, interpretación y
          cumplimiento de estos Términos, o los derechos y obligaciones de los jugadores, se regirán
          e interpretarán de conformidad con las leyes de los Estados Unidos Mexicanos. Para la
          aplicación, interpretación, ejecución y cumplimiento de los presentes términos y
          condiciones, las partes manifiestan que están de acuerdo en someterse a la jurisdicción de
          los Tribunales Federales con residencia en la Ciudad de México, renunciando en este acto a
          cualquier otra jurisdicción que pudiere corresponderle por razón de su domicilio presente
          o futuro o cualquier otra causa
        </p>

        <p>
          <strong>16. Misceláneos.</strong>
        </p>

        <p>
          La invalidez o inaplicabilidad de cualquier disposición de estos Términos y Condiciones no
          afectará a la validez o aplicación de cualquiera de sus otras disposiciones. En el caso de
          que alguna disposición de los Términos se determine inválida, inaplicable o ilegal, las
          demás disposiciones permanecerán en vigor y serán interpretadas de conformidad con sus
          términos como si la cláusula inválida o ilegal o la disposición no estuvieran contenida en
          este documento. La falta por parte de Pickwin de exigir el cumplimiento de cualquier
          término aquí incluido no constituirá una renuncia a tal disposición. Los Jugadores
          renuncian a cualquier derecho a reclamar ambigüedad de estos Términos. Los títulos de las
          secciones usados en estos Términos son únicamente para efectos de referencia y no se
          considerarán que afecten de ninguna manera el significado o la intención de cualquier
          disposición de los mismos.
        </p>
        <p>
          Información de Contacto: Para cualquier duda o aclaración con respecto a la Promoción, por
          favor, póngase en contacto con <a href="hola@pickwin.net">hola@pickwin.net</a>
        </p>
        <p>
          <strong>17. Reglas Aplicables por Deporte.</strong>
        </p>
        <p>
          Cada deporte individual tiene sus propias reglas que se detallan en esta sección que se
          refiere a ese deporte. Salvo que la liga, federación, organización deportiva o institución
          declare cancelado o invalido un partido o competencia. Ustedes acepta que se aplicarán los
          siguientes criterios para determinar cancelado un partido o competencia:
        </p>

        <div
          className="flex-col"
          style={{ border: '1px solid #fff', background: 'rgba(120,120,120,.3)' }}
        >
          <div className="flex-row headers">
            <div className=" flex-col col-center col-30">
              <strong>Deporte</strong>
            </div>
            <div className=" flex-col col-center">
              <strong>Regla</strong>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-center col-30">
              <strong>Soccer</strong>
            </div>
            <div className=" flex-col">
              <p>
                Si un partido es suspendido antes de concluido la totalidad de tiempo reglamentario
                (90 minutos) y no es completado en el mismo día hábil del lugar en el que se juegue.
                En este caso no se tomará en cuenta para la Competencia, incluyendo todas las
                actividades conexas con dicho partido; como; goles, tarjetas, faltas etc...
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-center col-30">
              <strong>Fútbol Americano</strong>
            </div>
            <div className=" flex-col">
              <p>
                Si un partido es suspendido antes de concluido la totalidad de tiempo reglamentario
                (60 minutos) y no es completado en el mismo día hábil del lugar en el que se juegue.
                En este caso no se tomará en cuenta para la Competencia, incluyendo todas las
                actividades conexas con dicho partido.
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-center col-30">
              <strong>Basketball profesional (excepto NBA)</strong>
            </div>
            <div className=" flex-col">
              <p>
                La totalidad del tiempo reglamentario y tiempo extra concluidos en el mismo día
                hábil del lugar donde se juegue.
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-center col-30">
              <strong>NBA Basketball</strong>
            </div>
            <div className=" flex-col">
              <p>
                La totalidad del tiempo reglamentario concluidos (48 minutos y tiempo extra en caso
                de ser necesario), en el mismo día hábil del lugar donde se juegue.
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-center col-30">
              <strong>Collage Basketball</strong>
            </div>
            <div className=" flex-col">
              <p>
                La totalidad del tiempo reglamentario (40 minutos y tiempo extra) en caso de ser
                necesario, concluidos en el mismo día hábil del lugar donde se juegue.
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-center col-30">
              <strong>Hockey</strong>
            </div>
            <div className=" flex-col">
              <p>
                La totalidad del tiempo reglamentario (60 minutos y tiempo extra en caso de ser
                necesario) concluidos en el mismo día hábil del lugar donde se juegue.
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-30 col-center">
              <strong>Baseball</strong>
            </div>
            <div className=" flex-col">
              <p>
                9 inning y extra innings en caso de ser necesario, concluidos en el mismo día hábil.
              </p>
            </div>
          </div>

          <div className="flex-row content">
            <div className=" flex-col col-30 col-center">
              <strong>Golf</strong>
            </div>
            <div className=" flex-col">
              <p>Cuando el tour al que pertenece el torneo determine por terminado el torneo.</p>
            </div>
          </div>
        </div>

        <h4>REGLAS GENERALES DE PICKWIN</h4>
        <p>
          Todas las reglas, regulaciones y pagos aquí contenidos están sujetas a cambios y
          revisiones de parte de Pickwin sin necesidad de previo aviso escrito.
        </p>
        <p>
          De igual forma usted deberá de revisar antes las reglas aplicables a cada una de las
          quinielas o Productos que están disponibles en el Sitio Web, las cuales serán
          complementarias a los presentes Términos y Condiciones.
        </p>
        <p>
          Pickwin no será responsable de ningún daño o perdida alegada como resultado o causado por
          este Sitio o su contenido. Esto incluye cualquier error de usuario de los contenidos, la
          inhabilidad de cualquier persona de conectarse o de utilizar el Sitio, retraso en las
          operaciones o en la conexión, falla de la líneas de comunicación o cualesquiera errores u
          omisiones de contenido.
        </p>
        <p>
          Todas las reglas, regulaciones y sistemas de puntos de los concursos serán constantemente
          actualizadas en nuestro Sitio, por lo cual le pedimos que se revise las reglas antes de
          hacer cualquier demanda a nuestro personal de servicio al cliente.
        </p>
        <p>
          Todas las participaciones en concursos aceptadas por Pickwin serán validas, a menos que
          haya prueba de que hubo algún tipo de engaño, intentos de "hacking" o que se ha intentado
          engañar intencionalmente.
        </p>
        <p>
          Los montos en todos los acontecimientos deportivos serán determinados por Pickwin y están
          sujetas a cambio sin previo aviso escrito. Pickwin también se reserva el derecho de
          ajustar o cambiar límites en concursos privados o públicos.
        </p>
        <p>
          Pickwin no aceptará ninguna participación sin tener el usuario ni la contraseña apropiada
          de su cuenta a través de Internet.
        </p>
        <p>
          cada cliente es responsable de mantener en secreto su usuario de cuenta y debe hacer el
          esfuerzo de prevenir el uso de su cuenta personal por terceros. Cualquier transacción
          hecha a la cuenta en donde se hayan dado el usuario y la contraseña correctos quedara como
          valida en nuestros archivos y dependerá únicamente del balance actual de la cuenta.
        </p>
        <p>
          Todas las cuentas se mantienen privadas y confidenciales. Todos los chats de soporte serán
          grabados para la protección de Pickwin y de sus clientes con el fin de resolver posibles
          conflictos.
        </p>
        <p>
          Cuando se participa en concursos a través de Internet, los clientes son los únicos
          responsables de las transacciones en sus cuentas. Por favor asegúrese de revisar sus
          movimientos y corrija cualquier error antes de aceptarlas y meterlas en el sistema.
          Pickwin no se hace responsable de posturas extraviadas o duplicadas hechas por el cliente
          y no atenderá peticiones de discrepancias sobre posturas extraviadas o duplicadas.
        </p>
        <p>
          Las posturas hechas a través de Pickwin no podrán ser cambiadas cinco minutos antes de que
          comience cada juego
        </p>
        <p>
          Una vez que las posturas sean realizadas en Pickwin, el usuario deberá aceptar en la
          ventana de confirmación su registro y participación en el concurso. Una vez confirmada la
          participación, será retirado el saldo correspondiente del concurso del balance disponible
          del usuario. En caso de no contar con un balance suficiente, el usuario podrá abonar saldo
          a su cuenta o sino no podrá completar su participación en el concurso.
        </p>
        <p>
          Cualquier juego que se cancele o posponga será tratado como "no hay acción" y no se tomara
          en cuenta para el concurso realizado. En caso de que el partido se reprograme y se lleve a
          cabo en otra fecha, tampoco se considerará para el concurso en turno.
        </p>
        <p>
          Pickwin depende enteramente en la información proporcionada por el cliente a la hora de
          establecer una cuenta.
        </p>
        <p>
          Aunque nuestro sistema de computadoras mantiene records exactos sobre el balance de
          nuestros clientes, es responsabilidad de cada cliente el asegurarse de que su balance esté
          correcto cada vez que entre a su cuenta por Internet.
        </p>
        <p>El costo de envío de los premios correrá por cuenta del usuario ganador.</p>
        <p>
          El usuario ganador deberá de enviar una copia de su identificación oficial al correo
          hola@pickwin.mx para verificar su identidad y mayoría de edad.
        </p>
        <p>
          Un Usuario solamente puede tener una cuenta de usuario con Pickwin y utilizará únicamente
          el Servicio usando dicha cuenta. Al Usuario le queda completamente prohibido abrir
          múltiples cuentas en Pickwin. En caso de que Pickwin descubra la existencia de cuentas
          adicionales abiertas por un Usuario, Pickwin podrá cerrar dichas cuentas adicionales sin
          previo aviso y podrá confiscar los fondos de dichas cuentas adicionales
        </p>
      </div>
    </div>

    <div className="flex-row responsive-sm">
      <div className={`flex-col ${!isExternal ? 'col-80 col-offset-10' : '' }  responsive-sm legal-section lateralPadding`}>
        <TerminosLealtad>1</TerminosLealtad>
      </div>
    </div>
    </>
  );
};

class Terminos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: utils.isLogged() ? utils.getUserInfo() : null,
      logged: utils.isLogged(),
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        userData: utils.isLogged() ? utils.getUserInfo() : null,
        logged: utils.isLogged(),
        mounted: true,
      },
      () => {
        let fakeMatch = {
          isExact: true,
          params: {
            slug: this.props.which.charAt(0).toUpperCase() + this.props.which.slice(1),
          },
        };
        gaUtils.handleDocumentAttributes(fakeMatch);
      }
    );
  }

  render() {
    let conditionalComponent = null;
    let fakeMatch = {
      isExact: true,
      params: {
        slug: this.props.which.charAt(0).toUpperCase() + this.props.which.slice(1),
      },
    };
    if (this.state.mounted) {
      switch (this.props.which) {
        case 'privacidad':
          gaUtils.handleDocumentAttributes(fakeMatch);
          conditionalComponent = <ContentPrivacidad />;
          break;
        case 'terminos':
          gaUtils.handleDocumentAttributes(fakeMatch);
          conditionalComponent = <ContentTerminos />;
          break;
        default:
          gaUtils.handleDocumentAttributes(fakeMatch);
          conditionalComponent = <ContentLegales />;
          break;
      }
    } else {
      conditionalComponent = <Loading label="..." />;
    }

    return (
      <>
        <PageWrapper isLegals>{conditionalComponent}</PageWrapper>
      </>
    )
  }
}

export default Terminos;
