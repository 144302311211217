import React from 'react';
import utils from '../../utils/utils';
import { ShareButtons } from 'react-share';
import { Modal, Button } from 'react-bootstrap/lib';
import avatar from '../../assets/images/avatar-100.png';
import retar from '../../assets/images/vsIconHalf-50.png';

import {
  DisplaySport,
  DisplayFlag,
  DisplayTypes,
} from '../../utils/quinielasComponents';

import numeral from 'numeral';

import API from '../../utils/api';
import * as Sentry from '@sentry/browser';

const ResponseInvitation = ({ title, showPanel, closePanel, responseStatus }) => {
  return (
    <Modal
      bsSize="sm"
      backdropClassName="inviteResponseBackdrop"
      dialogClassName="inviteResponseDialog"
      onHide={closePanel}
      show={showPanel}
    >
      <div className="panel-abonar">
        <div id="panelTitle" className="panel-abonar-titulo">
          <span>
            <i className="ion-flag" />
            {title}
          </span>
          <button className="cerrar-panel" onClick={closePanel}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row">
          <div className="flex-col status-response">
            <div className="flex-row">
              <div className="flex-container align justify">
                {responseStatus ? (
                  <i
                    className="ion-checkmark-circled"
                    style={{ color: '#78b529', fontSize: '2em' }}
                  />
                ) : (
                  <i className="ion-close-circled" style={{ color: '#e00034', fontSize: '2em' }} />
                )}
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-container justify align">
                {responseStatus
                  ? 'Tu recordatorio se envío con éxito.'
                  : 'Tenemos un problema al enviar tu recordatorio.'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const RowSelectPool = ({ poolSelected, poolItem, selectPool, resetInviteData, type }) => {
  let format = null;
  let currency = null;

  let isEnough = null;
  let hasRegister = null;
  let userData = utils.getUserInfo();

  if (type === 'challenge') {
    hasRegister = poolItem.entries.length > 0;
    // console.log( hasRegister )

    // console.log('row Selec ', userData, isEnough);
    isEnough = !poolItem.is_full && userData.balanceReal > poolItem.entry_fee;
    // console.log(userData.balanceReal);
    // console.log(poolItem.entry_fee);
    // console.log(isEnough);
  }

  if (poolItem.entry_currency === 'real') {
    format = '$0,000';
    currency = 'MXN';
  } else if (poolItem.entry_currency === 'pickcoin') {
    format = '0,000';
    currency = 'PC';
  } else {
    format = '0,000';
    currency = 'Rf';
  }

  return (
    <label id={poolItem.id} className="flex-row">
      <div
        className={`flex-col list-pool-item ${
          poolSelected !== null && poolSelected.id === poolItem.id ? 'selected' : ''
        }
        ${type === 'challenge' && !isEnough ? 'disabled' : ''}
        `}
      >
        <div className="flex-row row-no-padding">
          <div className="flex-col">
            <div className="flex-row row-center row-no-padding ">
              <div className="flex-col col-70">
                <div className="flex-row row-center">
                  <DisplaySport id={poolItem.id} sports={poolItem.sport} type={'pool'} />
                  <DisplayFlag type="pool" flag={poolItem} />
                  <div style={poolItem.featured ? { color: '#FFF900' } : null}>
                    {poolItem.name_replaced}
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <DisplayTypes id={poolItem} format={poolItem} />
              </div>
              {hasRegister ? (
                <div className="flex-col">
                  <div className="flex-row">
                    <div className="flex-container justify align column">
                      Registrado
                      <i className="ion-checkmark-circled"></i>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex-row row-no-padding">
          <div className="flex-col">
            <div className="flex-container align justify">Entrada:</div>
            <div className="flex-container align justify">
              {numeral(poolItem.entry_fee / 100).format(format)} {currency}
            </div>
          </div>
          <div className="flex-col">
            <div className="flex-container align justify">Registrados:</div>
            <div className="flex-container align justify">
              {poolItem.entry_count}
              {poolItem.pickngo ? `/${poolItem.max_entries}` : ''}
            </div>
          </div>
          <div className="flex-col">
            <div className="flex-container align justify">Bolsa:</div>
            <div
              className="flex-container align justify"
              style={poolItem.guaranteed_prize !== null ? { color: '#FFF900' } : null}
            >
              {numeral(poolItem.available / 100).format(format)} {currency}
            </div>
          </div>
        </div>
        <input
          id={poolItem.id}
          name={poolItem.id}
          type="radio"
          value={poolItem.id}
          onChange={() => {
            if (resetInviteData) {
              resetInviteData();
            }
            selectPool(poolItem.id);
          }}
        />
      </div>
    </label>
  );
};

const ListPools = ({ pools, selectPool, mounted, poolSelected, resetInviteData, type }) => {
  if (mounted) {
    let listPools = pools.map((pool) => {
      return (
        <RowSelectPool
          type={type}
          key={pool.id}
          poolSelected={poolSelected}
          poolItem={pool}
          selectPool={selectPool}
          resetInviteData={resetInviteData}
        />
      );
    });

    console.log(listPools);
    return (
      <div className="flex-col">
        <div className="flex-row">Elige una quiniela:</div>
        <div className="flex-col overflow-y overflow-300 overflow">{listPools}</div>
      </div>
    );
  } else {
    return (
      <div className="flex-container justify align">
        <i className="ion-load-a loading " />
      </div>
    );
  }
};

class PanelPlayerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      showPanel: false,
      branchLink: null,
      pools: null,
      mounted: false,
      poolSelected: null,
      errorGettingPools: false,
    };

    this.challengeAndRegister = (user, href) => {
      this.setState({ submitting: true });
      let registerObject = {
        num_entries: 1,
        pool_id: this.state.poolSelected.id,
      };

      API.postSavePicks(registerObject)
        .then((responseRegister) => {
          try {
            utils.checkAxiosAllErrors([responseRegister]);
          } catch (e) {
            console.error(e);
          }

          this.props.sendChallenge(user, href);

          this.setState(
            {
              submitting: false,
              registerError: false,
              registerAndInviteSuccess: true,
              registerResponse: responseRegister.data,
            },
            () => {
              this.props.updateUserInfo();
            }
          );
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
          let responseRegister;
          this.setState({
            submitting: false,
            registerError: true,
            registerAndInviteSuccess: false,
            registerResponse: responseRegister,
          });
        });
    };

    this.selectPool = (poolId) => {
      console.log('SelectPool', poolId);
      API.fetchTickets(poolId, 'pool').then((responseTickets) => {
        console.log(responseTickets);
        let poolSelected = this.state.pools.find((pool) => pool.id === poolId);
        if (!poolSelected || poolSelected === -1) {
          return;
        }
        let isEnough = utils.getUserInfo().balanceReal > poolSelected.entry_fee;
        this.setState(
          {
            ticketsForSelected: responseTickets.data,
            poolSelected,
            isEnoughToInvite: !isEnough && !responseTickets.data.tickets.length ? false : true,
            hasRegister: poolSelected.entries.length > 0,
          },
          () => {
            if (!this.props.type) {
              this.props
                .getBranchLinkChallenge(poolSelected, poolSelected.game_type)
                .then((url) => {
                  this.setState({
                    branchLink: url,
                  });
                });
            }
          }
        );
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.showPanel && this.props.showPanel) {
      console.log(this.props.selectedUser);
      API.searchUser(this.props.selectedUser.nick)
        .then((response) => {
          console.log('Response', response);
          this.setState({ userInfo: response.data.user, mounted: true });
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
    }
    if (prevProps.showPanel && !this.props.showPanel) {
      this.setState({ userInfo: null, mounted: false });
    }
  }

  render() {
    if (!this.state.userInfo && this.state.mounted) {
      return (
        <Modal
          bsSize="sm"
          backdropClassName="inviteFriendsBackdrop"
          dialogClassName="inviteFriendsBackdrop"
          onHide={() => {
            this.props.closePanel();
          }}
          show={this.props.showPanel}
        >
          <div className="panel-abonar">
            <div id="panelTitle" className="panel-abonar-titulo">
              <span>
                <i className="ion-flag" />
                Información de{' '}
                {!this.props.selectedUser ? 'pickwinero' : this.props.selectedUser.nick}
              </span>
              <button
                className="cerrar-panel"
                onClick={() => {
                  this.props.closePanel();
                }}
              >
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty" />
                </div>
              </button>
            </div>
            <div
              style={{ paddingBottom: '10px' }}
              className=" panel-pickwin flex-container justify align column"
            >
              <i className="ion-alert-circled alert"></i>
              El usuario no existe
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Modal
        bsSize="sm"
        backdropClassName="inviteFriendsBackdrop"
        dialogClassName="inviteFriendsBackdrop"
        onHide={() => {
          this.props.closePanel();
        }}
        show={this.props.showPanel}
      >
        <div className="panel-abonar">
          <div id="panelTitle" className="panel-abonar-titulo">
            <span>
              <i className="ion-flag" />
              Información de{' '}
              {!this.props.selectedUser ? 'pickwinero' : this.props.selectedUser.nick}
            </span>
            <button
              className="cerrar-panel"
              onClick={() => {
                this.props.closePanel();
              }}
            >
              <div className="btn-icon-close">
                <i className="ion-ios-close-empty" />
              </div>
            </button>
          </div>
          {this.state.mounted ? (
            <div style={{ paddingBottom: '10px' }} className="panel-pickwin flex-col ">
              <div className="flex-row row-center">
                <div className="flex-col col-center text-center">
                  <img
                    className=" avatarUser"
                    src={
                      !this.state.userInfo || !this.state.userInfo.avatar
                        ? avatar
                        : this.state.userInfo.avatar_dir + this.state.userInfo.avatar
                    }
                    alt=""
                  />
                  <div className="flex-row row-center">
                    <strong className="flex-col col-center text-center">
                      {' '}
                      {this.state.userInfo.nick}{' '}
                    </strong>
                  </div>
                </div>
                <div className="flex-col ">
                  <div className="flex-row">
                    <div className="flex-col ">Usuario verificado:</div>
                    <div className="flex-col">
                      {!this.state.userInfo.identity_verified ? (
                        <i className="ion-close-round" style={{ color: '#e00034' }}></i>
                      ) : (
                        <i className="ion-checkmark-round" style={{ color: '#78bc28' }}></i>
                      )}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="flex-col ">Quinielas Jugadas:</div>
                    <div className="flex-col">{this.state.userInfo.entry_count}</div>
                  </div>
                  <div className="flex-row">
                    <div className="flex-col ">Survivors Jugados:</div>
                    <div className="flex-col">
                      {!this.state.userInfo.survivors_entry_count
                        ? 0
                        : this.state.userInfo.survivors_entry_count}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="flex-col ">Brackets Jugados:</div>
                    <div className="flex-col">
                      {!this.state.userInfo.bracket_entry_count
                        ? 0
                        : this.state.userInfo.bracket_entry_count}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="flex-col ">Premios Ganados:</div>
                    <div className="flex-col ">
                      {!this.state.userInfo.prizes_count ? 0 : this.state.userInfo.prizes_count}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" btn-row flex-row row-center">
                <Button
                  className="flex-col col-40 col-offset-5 btn-success btn-rojo"
                  onClick={() => {
                    this.props.closePanel();
                    this.props.showConfirmationDelete(this.props.selectedUser);
                  }}
                >
                  Eliminar <i className="ion-trash-a"></i>
                </Button>
                <Button
                  className="flex-col col-40 col-offset-10 btn-success"
                  onClick={() => {
                    this.props.closePanel();
                    this.props.showConfirmationInvite(this.props.selectedUser);
                  }}
                >
                  Retar <img src={retar} className="btn-icon" alt="" />
                </Button>
              </div>
              <div className="flex-row btn-row">
                <Button
                  className="flex-col col-90 col-offset-5 btn-success"
                  onClick={() => {
                    this.props.closePanel();
                    this.props.showTransfer();
                  }}
                >
                  Transferir Saldo <i className="ion-arrow-swap"></i>
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex-container  justify align">
              <i className="ion-load-a loading"></i>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default PanelPlayerInfo;
