import React from 'react';
import { PopUpConfirmationAdd } from '../TablaPosiciones';
import ErrorBoundary from '../ErrorBoundary';
import MiQuinielaPanel from '../MiQuinielaPanel';
import MiBracketPanel from '../MiBracketPanel';
import MiSurvivorPanel from '../MiSurvivorPanel';
import MiQuinielaGrupoPanel from '../MiQuinielaGrupoPanel';

import { QuinielaGroupsContextProvider } from '../../context/QuinielaGroupsContext';

import Utils from '../../utils/quinielasUtils';
import utils from '../../utils/utils';
import API from '../../utils/api';
import SubscriptionRxs from '../../utils/subscriptions';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

// import "../../assets/stylesheets/flags-sprites-complete.scss";

/**
 * Wrapper That contains the  masonry Component and the logic behind the layouts
 */

class QuinielasDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveFixturesData: null,
      livePropsData: null,
      miQuinaLoaded: false,
      numeroEntries: this.props.numeroEntries,
      redirectLobby: false,
      updatePanels: false,
      updateHeight: false,
      updatedId: null,
      updateDashboard: false,
      activeSockets: [],
      loadedPanels: [],
      userSelectedToAdd: null,
      submittingInvite: false,
      showAddConfirmation: false,
      inviteStatus: null,
      updateLayout: false,
      groupPoolSockets: [],
      statusFollow: null,
      maxHeights: [
        { row: 0, maxHeight: 0, used: true },
        { row: 1, maxHeight: 0, used: false },
        { row: 2, maxHeight: 0, used: false },
        { row: 3, maxHeight: 0, used: false },
      ],
      miQuinielaHeight: 0,
    };
    this.ws =  null;
    this.miQuinielaRef = React.createRef();

    // this.updateGroupPoolSockets = ( selectedGroupPool ) => {
    //   console.log('updateGrouPoolSockets', selectedGroupPool);

    //   let tempGroupPoolSockets = this.state.groupPoolSockets;

    //   tempGroupPoolSockets = [ ...tempGroupPoolSockets, selectedGroupPool ];

    //   console.log(tempGroupPoolSockets);
    //   this.setState( {
    //     groupPoolSockets: tempGroupPoolSockets
    //   } , () => {
    //     API.subscribeGroupPoolQuinielaDashboard(
    //       this.state.groupPoolSockets
    //       )
    //   });
    // }

    this.handleUpdateQuinielaGrupo = () => {
      this.setState(
        {
          updateLayout: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              updateLayout: false,
            });
          }, 800);
        }
      );
    };

    this.handleSelectedUser = (user) => {
      console.log(user);
      this.setState({ userSelectedToAdd: user }, () => {
        this.showAddConfirmation();
      });
    };

    /**
     * This method handles the follow action from the socialAPI
     * @param id { Number }
     */
    this.followUser = (id) => {
      console.log(' %c Follow User +  ', 'color:rgb(223, 75, 38) ; font-size:2em ;');
      console.log(id);
      API.followUser(id)
        .then((response) => {
          console.log(response);
          if (response.data.message === 'ok') {
            if (!this.state.successFollow) {
              this.setState({ inviteStatus: 'success' });
            } else {
              // let temSuccessFollow = [...this.state.successFollow, {id, status:true} ];
              this.setState({ inviteStatus: 'error' });
            }
          }
        })
        .catch((e) => {
          console.log(e);
          // if( !this.state.errorFollow){
          this.setState({ inviteStatus: 'error' });
          console.error(e);
          Sentry.captureException(e);
        });
    };

    this.hideAddConfirmation = () => {
      this.setState({
        showAddConfirmation: false,
        userSelectedToAdd: null,
        inviteStatus: null,
        statusFollow: null,
      });
    };
    this.showAddConfirmation = () => {
      this.setState({ showAddConfirmation: true });
    };

    this.handleMaxHeight = this.handleMaxHeight.bind(this);

    this.handleMaxHeight = _.debounce(this.handleMaxHeight, 300);

    this.resetHeight = this.resetHeight.bind(this);
    this.updatePicks = Utils.updatePicks.bind(this);
    this.updatePanels = this.updatePanels.bind(this);
    this.chunk = this.chunk.bind(this);
    this.loadedPanel = this.loadedPanel.bind(this);
    this.closedPanel = this.closedPanel.bind(this);
    this.afterUpdatePanels = this.afterUpdatePanels.bind(this);
    this.updateTieData = Utils.updateTieData.bind(this);

    this.updateMaxHeightParent = (maxHeight) => {
      console.log('Max Height Parent', maxHeight);
      // this.setState({ maxHeight };

      $('.mis-quinielas').animate({ height: maxHeight + 30 }, 50);
    };

    // console.log(this);
    // console.log(Masonry.Masonry);
    // console.log(MasonryLayout);

    /**
     * This function handle the max height of the bracket panel based on the other quinielas
     */
    this.handleMaxHeightBrackets = (el, maxHeight) => {
      const dashElements = $('.dashboard-item .panel-pickwin.mis');
      const bracketElements = $('#mi-bracket.panel-pickwin');

      // console.log( el , maxHeight);

      if (!el || $(window).width() < 669) {
        return;
      }

      console.log('%c handle MAx Bracekt List', 'color:#000; font-size:2em;');
      // console.log(bracketElements.length, dashElements.length);

      // const panelHeight = maxHeight;
      let panelHeight = el.offsetHeight;
      /**
       * If therer are just bracket panels
       * assign the height of the controls register to
       * the mi-bracket-panels
       */
      if (bracketElements.length === dashElements.length) {
        // console.log('Solo hay brackets');
        panelHeight = $('.control-registros').height();
        let titlePanelHeight = $(el)
          .find('.title-quiniela.main')
          .height();
        let titleButtonsHeight = $(el)
          .find('.titulo-mis-picks')
          .height();
        let positionsList = $(el).find('.lista-posiciones');
        let titlePositionsList = $(el)
          .find('.position-list .title-quiniela')
          .height();
        let searchFieldHeight = $(el)
          .find('#search-field-nick')
          .height();
        let headerPositionsHeight = $(el)
          .find('.position-list .position-header')
          .height();

        // setTimeout(() => {
        let positionListHeight = Math.ceil(
          panelHeight -
          30 -
          (titlePanelHeight +
            titleButtonsHeight +
            titlePositionsList +
            searchFieldHeight +
            headerPositionsHeight) +
          2
        );
        //  console.log(positionListHeight);
        if (positionListHeight < 0 || isNaN(positionListHeight)) {
          return;
        }
        if (positionListHeight > 2000) {
          positionListHeight = 1000;
        }
        positionsList.height(positionListHeight - 25);
        bracketElements.height(panelHeight);

        $(el)
          .find('.position-list .overflow-360')
          .animate({ height: positionListHeight }, '100');
        //
        // $('.mis-quinielas').attr(
        //   'style',
        //   `max-width:120%; height:${positionListHeight}px; position:relative;`
        // );
        $('ul.lista-posiciones.quiniela').attr('style', '');
        // }, '200');
      } else {
        let titlePanelHeight = $(el)
          .find('.title-quiniela.main')
          .height();
        let titleButtonsHeight = $(el)
          .find('.titulo-mis-picks')
          .height();
        let positionsList = $(el).find('.lista-posiciones');
        let titlePositionsList = $(el)
          .find('.position-list .title-quiniela')
          .height();
        let searchFieldHeight = $(el)
          .find('#search-field-nick')
          .height();
        let headerPositionsHeight = $(el)
          .find('.position-list .position-header')
          .height();

        setTimeout(() => {
          let positionListHeight = Math.ceil(
            panelHeight -
            30 -
            (titlePanelHeight +
              titleButtonsHeight +
              titlePositionsList +
              searchFieldHeight +
              headerPositionsHeight)
          );
          // console.log(positionListHeight);
          if (positionListHeight < 0 || isNaN(positionListHeight)) {
            return;
          }
          positionsList.height(positionListHeight - 25);
          $(el)
            .find('.position-list .overflow-360')
            .animate({ height: positionListHeight }, '50');

          $('ul.lista-posiciones.quiniela').attr('style', '');
        }, 200);
      }

      // const el = $('#mi-bracket');

      // console.log( misBrackets );
    };
  }

  chunk(arr, chunkSize) {
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize));
    return R;
  }

  /**
   * Change the state of the loaded panel
   * @param {*} id
   */
  loadedPanel(id, entryId, gameType, status) {
    let panelData = {
      isMounted: true,
      entryId: entryId,
      gameId: id,
      gameType: gameType,
      status: status,
    };

    let tempLoaded = this.state.loadedPanels;

    tempLoaded = [...tempLoaded, panelData];

    this.setState(
      {
        loadedPanels: tempLoaded,
        updateHeight: true,
      },
      () => {
        this.setState({
          updateHeight: false,
        });
      }
    );

    // console.log('loaded'+id);
    // let tempLoaded = this.state.loadedPanels;
    // tempLoaded.push(id);
    // this.setState({
    //   loadedPanels: tempLoaded
    // });
  }

  /**
   * Close panel of the received id panel
   * @param {closed Panel} id
   */
  closedPanel(entryId, id, gameType) {
    return;
    console.log('Closed Panel ', entryId, id, gameType);
    let tempLoaded = this.state.loadedPanels;
    let indxClosed = null;
    let closedPanel = tempLoaded.find((temp, tempIndx) => {
      if (temp.entryId === entryId && temp.gameId === id && gameType === temp.gameType) {
        indxClosed = tempIndx;
        return temp;
      }
    });

    // console.log('closed This',indxClosed, tempLoaded[indxClosed], closedPanel);

    // console.log('loaded',tempLoaded)

    if (indxClosed && indxClosed !== -1) {
      tempLoaded.splice(indxClosed, 1);
    }

    console.log('sliced', tempLoaded);
    this.setState(
      {
        loadedPanels: tempLoaded,
        // updateHeight: true,
      },
      () => {
        // setTimeout( () => {
        this.setState({
          // updateHeight: false
        });
        console.log('After ClosedPanel', this.state);

        // }, 300 );
      }
    );
  }

  /**
   * Update Panel data of the erveivd id
   * @param {updated Panel} id
   */
  updatePanels(id, totalPicks) {
    console.log('Update Panels !!!');
    console.log('This panel was updated', id);
    if (!id) {
      this.setState({
        updatePanels: true,
        liveFixturesData: Date.now(),
      });
    } else {
      console.log('After Update Entries');
      this.props
        .updateEntries(id, totalPicks)
        .then((response) => {
          console.log('Update Entries Promise');
          console.log(response);
          if (response.success) {
            this.setState({ updatePanels: true, updatedId: id });
          } else {
            console.error('Error Updating Panels');
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  afterUpdatePanels(totalPicks) {
    // console.log('AfterUpdate Panels !!!', totalPicks);
    this.setState({ updatePanels: false });
  }

  /**
   * Check the heights of each panel, and set the height oof the biggest panel
   * as a default to the others and the container
   * This function has to be called when the layout is Complete
   * @param none
   */
  handleMaxHeight() {
    return;
  }

  restoreUpdatedDashboard() {
    this.setState({
      updateDashboard: false,
    });
  }

  /**
   * This function Reset the height to auto of the current MiQuinielaComponent
   */
  resetHeight() {
    return;
    console.log('reset Height');
    // let currentElement = $(`#picksContainer-${this.props.selectedEntry}`);
    // console.log(currentElement[0]);
    // currentElement[0].style.height = 'auto';

    let dashElements = $('.dashboard-item .panel-pickwin.mis');
    let dashElementsParents = $('.dashboard-item ');
    let controlEntries = $('#mis-registros .controls-wrapper');

    _.each(dashElements, (el, indexEl) => {
      dashElementsParents[indexEl].style.height = 'auto'; // console.log(el.style.height, maxHeight);
      el.style.height = `auto`;
    });
    if (controlEntries !== undefined && controlEntries.length > 0) {
      // console.log('Element ',controlEntries[0])

      controlEntries[0].style.height = `auto`;
    }
  }

  componentWillUnmount() {
    API.unSubscribeSocketLiveFixtures();
  }

  componentDidMount() {
    API.subscribeSocketLiveFixturesFromDashboard(
      (data) => {
        // console.log('data received');
        SubscriptionRxs.announceFixturesUpdated(data);
      },
      (callbackProp) => {
        SubscriptionRxs.announcePropsUpdated(callbackProp);
      },
      (dataFixtureClose) => {
        SubscriptionRxs.announceFixtureCloseUpdated(dataFixtureClose);
      },
      (dataPoolClose) => {
        SubscriptionRxs.announcePoolCloseUpdated(dataPoolClose);
      },
      (standingsUpdated) => {
        SubscriptionRxs.announceStandingsUpdatedsUpdated(standingsUpdated);
      },
      (standingsWeeklyUpdated) => {
        SubscriptionRxs.announceWeeklyStandingsUpdatedsUpdated(standingsWeeklyUpdated);
      }
    );

    setTimeout(() => {
      if (this.miQuinielaRef.current !== null) {
        const node  = this.miQuinielaRef.current;
        this.setState({ miQuinielaHeight: node.offsetHeight-270 });
        console.log("HEIGHT:", node.offsetHeight);
      }
    }, 200);

  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * If the Qnntty of the Active Dashboard is different Handle the max height of all the panesl
     */

    // console.log()
    if (
      this.props.activeDashboard.length === this.state.loadedPanels.length ||
      this.state.updateLayout
    ) {
      // console.log('REady');
      // this.masonry.layout();
    }
    // console.log(dashElements.length , prevProps.activeDashboard.length , this.props.activeDashboard.length);
    if (prevProps.activeDashboard.length > this.props.activeDashboard.length) {
      //Elementt removed
      // console.log('removed PANEL');
      // this.masonry.layout();
      // this.resetHeight();
      // this.handleMaxHeight();
    }
    if (prevState.updateDashboard === false && this.state.updateDashboard === true) {
      this.restoreUpdatedDashboard();
    }

    if (this.miQuinielaRef.current !== null) {
      const node  = this.miQuinielaRef.current;
      console.log("HEIGHT:", node.offsetHeight);
      console.log("QD:", this.state.miQuinielaHeight, node.offsetHeight-270);
    }
  }

  render() {
    // console.log( "%c QuinielaDashboard All", "background:rgb(255, 110, 28); font-size:20px;" );
    // console.log(this.props);
    // console.log(props);
    let temporalArray0 = [];
    let activeEntries_ = this.props.activeDashboard;
    // let masonryOptions = {
    //   itemSelector: '.dashboard-item',
    //   transitionDuration: '.2s',
    //   minHeight: '600px',
    //   height: 'auto',
    // };
    // let entriesDash = props.activeEntries.filter(quina => quina !== 0);
    // console.log(quinielasDash);
    let conditionalComponent;
    let conditionalDash;
    // let currentEntry;6
    // console.log(activeEntries_);
    if (activeEntries_ !== undefined || activeEntries_ !== null) {
      if (activeEntries_.length > 0 || activeEntries_) {
        temporalArray0 = activeEntries_.map((entry, i) => {
          // console.log(entry.id ,i);

          if (entry === undefined) {
            activeEntries_.splice(1, i);
            let activeMisQuinielas = activeEntries_.map((entry) => {
              if (entry !== undefined) {
                // console.log(entry);
                return entry.id;
              }
            });
            utils.setMisQuinielasActiveItems(activeMisQuinielas);
            return;
          }
          if (entry.game_type === 'bracket') {
            conditionalDash = (
              <MiBracketPanel
                handleSelectedUser={this.handleSelectedUser}
                gameType={entry.game_type}
                changeStatusPool={this.props.changeStatusPool}
                afterUpdatePanels={this.afterUpdatePanels}
                closedPanel={this.closedPanel}
                closeMiQuina={this.props.closeMiQuina}
                entry={entry}
                handleMaxHeight={this.handleMaxHeight}
                index={i}
                key={entry.id}
                liveFixturesData={this.state.liveFixturesData}
                livePropsData={this.state.livePropsData}
                loadedPanel={this.loadedPanel}
                numeroEntries={activeEntries_.length}
                resetHeight={this.resetHeight}
                update={this.state.updatePanels}
                updatedId={this.state.updatedId}
                updateEntries={this.props.updateEntries}
                updatePanelsData={this.state.updatePanels}
                updatePanels={this.updatePanels}
                userData={this.props.userData}
                withRegister={true}
                maxHeight={0}
                miQuinielaRef={this.state.miQuinielaHeight}
              />
            );
          } else if (entry.game_type === 'survivor') {
            conditionalDash = (
              <MiSurvivorPanel
                handleSelectedUser={this.handleSelectedUser}
                gameType={entry.game_type}
                changeStatusPool={this.props.changeStatusPool}
                afterUpdatePanels={this.afterUpdatePanels}
                closedPanel={this.closedPanel}
                closeMiQuina={this.props.closeMiQuina}
                entry={entry}
                handleMaxHeight={this.handleMaxHeight}
                index={i}
                key={entry.id}
                liveFixturesData={this.state.liveFixturesData}
                livePropsData={this.state.livePropsData}
                loadedPanel={this.loadedPanel}
                numeroEntries={activeEntries_.length}
                resetHeight={this.resetHeight}
                update={this.state.updatePanels}
                updatedId={this.state.updatedId}
                updateEntries={this.props.updateEntries}
                updatePanelsData={this.state.updatePanels}
                updatePanels={this.updatePanels}
                userData={this.props.userData}
                withRegister={true}
              />
            );
          } else if (entry.game_type === 'group_pool') {
            conditionalDash = (
              <QuinielaGroupsContextProvider key={entry.id}>
                {(maxHeight) => {
                  {
                    /* console.log( maxHeight ); */
                  }
                  return (
                    <MiQuinielaGrupoPanel
                      changeStatusPoolGroup={this.props.changeStatusPoolGroup}
                      updateGroupPoolSockets={this.updateGroupPoolSockets}
                      afterUpdatePanels={this.afterUpdatePanels}
                      changeStatusPool={this.props.changeStatusPool}
                      closedPanel={this.closedPanel}
                      closeMiQuina={this.props.closeMiQuina}
                      entry={entry}
                      maxHeight={maxHeight}
                      gameType={entry.game_type}
                      handleMaxHeight={this.handleMaxHeight}
                      handleSelectedUser={this.handleSelectedUser}
                      index={i}
                      updateControlEntriesData={this.props.updateControlEntriesData}
                      key={entry.group_pool_entry_id}
                      liveFixturesData={this.state.liveFixturesData}
                      livePropsData={this.state.livePropsData}
                      loadedPanel={this.loadedPanel}
                      numeroEntries={activeEntries_.length}
                      resetHeight={this.resetHeight}
                      update={this.state.updatePanels}
                      updatedId={this.state.updatedId}
                      updateEntries={this.props.updateEntries}
                      updatePanels={this.updatePanels}
                      updatePanelsData={this.state.updatePanels}
                      updateLayout={this.handleUpdateQuinielaGrupo}
                      userData={this.props.userData}
                      withRegister={true}
                    />
                  );
                }}
              </QuinielaGroupsContextProvider>
            );
          } else {
            conditionalDash = (
              <MiQuinielaPanel
                updateControlEntries={this.props.updateControlEntries}
                handleSelectedUser={this.handleSelectedUser}
                enoughBalance={true}
                gameType={entry.game_type}
                changeStatusPool={this.props.changeStatusPool}
                afterUpdatePanels={this.afterUpdatePanels}
                closedPanel={this.closedPanel}
                closeMiQuina={this.props.closeMiQuina}
                entry={entry}
                handleMaxHeight={this.handleMaxHeight}
                index={i}
                key={entry.id}
                liveFixturesData={this.state.liveFixturesData}
                livePropsData={this.state.livePropsData}
                loadedPanel={this.loadedPanel}
                numeroEntries={activeEntries_.length}
                resetHeight={this.resetHeight}
                update={this.state.updatePanels}
                updatedId={this.state.updatedId}
                updateEntries={this.props.updateEntries}
                updatePanelsData={this.state.updatePanels}
                updatePanels={this.updatePanels}
                userData={this.props.userData}
                withRegister={true}
              />
            );
          }
          return conditionalDash;
        });
      }
    }
    // console.log(temporalArray0);
    conditionalComponent = (
      <div id="quinielas-dashboard" className="flex-row row-wrap mis-quinielas">
        <PopUpConfirmationAdd
          title={'Agrega a tus amigos en pickwin'}
          label={`Quieres agregar a ${
            this.state.userSelectedToAdd ? this.state.userSelectedToAdd.user.nick : 'pickwinero'
            } como amigo`}
          submitting={this.state.submittingInvite}
          inviteStatus={this.state.inviteStatus}
          show={this.state.showAddConfirmation}
          handleInvite={this.followUser}
          hideConfirmation={this.hideAddConfirmation}
          userSelected={this.state.userSelectedToAdd}
        />
        {/* <Masonry
          ref={function(c) {
            this.masonry = this.masonry || c.masonry;
          }.bind(this)}
          className={`mis-quinielas`}
          options={ masonryOptions }
          disableImagesLoaded={false}
          >
          <PanelHeightMeasure
            updateHeight={ this.state.updateHeight }
            loadedPanels={ this.state.loadedPanels }
            updateMaxHeightParent={this.updateMaxHeightParent}
            defaultHeight={ 0 }
            itemId='.panelMisQuinielas'> */}
        {temporalArray0.length > 0 ? temporalArray0 : null}
        {/* </PanelHeightMeasure>
        </Masonry> */}
      </div>
    );
    return <ErrorBoundary isPanel>{conditionalComponent}</ErrorBoundary>;
  }
}

export default QuinielasDashboard;
