import React from 'react';
import firstBy from 'thenby';
import _ from 'underscore';

const ListBucketsShow = ({
  poolBuckets,
  myEntry,
  currentContest,
  groupPicks,
  poolInfo
}) => {
  if (!poolBuckets) {
    return null;
  }

  poolBuckets = poolBuckets[currentContest.id].sort(
    firstBy((bucket) => {
      if (!bucket.player) {
        return null;
      }
      return bucket.player.position === null ? Number.MAX_SAFE_INTEGER : bucket.player.position;
    }).thenBy((bucket) => {
      if (!bucket.player) {
        return null;
      }
      return bucket.player.score_num;
    })
  );

  let listShow = _.map(poolBuckets, (bucket, bucketIndex) => {
    const selectedPlayer = myEntry.group_pool_picks[currentContest.id].hasOwnProperty(bucket.id)
      ? bucket.players.find((o) => o.id === myEntry.group_pool_picks[currentContest.id][bucket.id].pick)
      : null;

    let titleButton = (
      <div className="btn-group-pick-collapse disabled align flex-container between ">
        <div className="flex-col col-10">{bucket.id}</div>
        <div className=" uppercase flex-col col-40 overflow-words" style={{ color: '#e00034' }}>
          N/D
        </div>
        <div className="flex-col text-center">-</div>
        <div className="flex-col text-center">-</div>
        <div className="flex-col text-center">0pts</div>
      </div>
    );
    if (selectedPlayer) {
      let colorStyle = {};

      // GET DIF
      var diff = Math.abs( bucket.id - selectedPlayer.finish_pos );
      var pts = 0;
      if (
        poolInfo.points_distributions && poolInfo.points_distributions.hasOwnProperty(diff)
        && selectedPlayer.finish_pos <= 10
      ) {
        pts = poolInfo.points_distributions[diff];
      }

      if (selectedPlayer.score > 0) {
        colorStyle = { color: 'rgb(255, 199, 48)' };
      } else if (selectedPlayer.score < 0) {
        colorStyle = { color: '#78bc28' };
      }

      //BONUS POINTS
      var bonus = 0;
      if (bucket.id === 1) {
        if (bucket.id === selectedPlayer.finish_pos) {
          bonus = 10;
        }
      }
      if (bucket.id === 2) {
        if (bucket.id === selectedPlayer.finish_pos) {
          bonus = 5;
        }
      }
      if (bucket.id === 3) {
        if (bucket.id === selectedPlayer.finish_pos) {
          bonus = 2;
        }
      }

      titleButton = (
        <div className="btn-group-pick-collapse flex-row row-no-padding row-center ">
          <div className="flex-col col-10">{bucket.id}</div>
          <div className="flex-col col-40">
            <div className='flex-container align'>
              <img alt="" src={selectedPlayer.avatar} className="player-avatar" style={{ width: '28px', height: '28px' }} />
              <div className='flex-container column' style={{marginLeft: '5px'}}>
                <div className='flex-container'>
                  <i className={`${selectedPlayer.logo}`} style={{ marginRight: '3px' }} />
                  <div className="overflow-words">{selectedPlayer.name}</div>
                </div>
                <div>
                  <img alt="" src={selectedPlayer.alt_avatar} style={{ width: '60px', height: 'auto' }} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-col  text-center">
            {selectedPlayer.finish_pos}{' '}
          </div>
          <div className="flex-col  text-center">
            {diff}
          </div>
          <div className="flex-col flex-container justify align">
            <div>{pts}&nbsp;</div>
            {bonus !== 0 ? (<div style={{color: '#78bc28'}}>+{bonus}</div>) : null}
          </div>
        </div>
      );
    }

    return (
      <div
        key={bucket.id}
        data-rank={selectedPlayer ? selectedPlayer.score : null}
        className="bucket-item"
      >
        {titleButton}
      </div>
    );
  });

  return (
    <div className="flex-col col-no-padding">
      <div className="flex-row title-quiniela sub">
        <div className="flex-col title-wrapper">Picks</div>
      </div>
      <div
        className="header-picks-partidos flex-row row-no-padding results"
        style={{ padding: '4px' }}
      >
        <div className="flex-col col-10">#</div>
        <div className="flex-col col-40">Piloto</div>
        <div className="flex-col ">Pos</div>
        <div className="flex-col ">Dif</div>
        <div className="flex-col ">Pts</div>
      </div>
      {listShow}
    </div>
  );
};

/**
 * This component Renders the title and the list of picks
 */
const PresentationPicksF1 = ({ disabled, poolInfo, poolBuckets, dispatch, myEntry, currentContest, groupPicks }) => {
  return (
    <ListBucketsShow
      disabled={disabled}
      poolInfo={poolInfo}
      poolBuckets={poolBuckets}
      myEntry={myEntry}
      dispatch={dispatch}
      currentContest={currentContest}
      groupPicks={groupPicks}
    />
  );
};

export default PresentationPicksF1;
