import React from 'react'
import numeral from 'numeral'
import ReactTooltip from 'react-tooltip'

// Components
import InvitedReferralList from '../../../../InvitedReferralList'
import ReferralProgressBar from './ReferralProgressBar'

// Assets
import avatar from '../../../../../assets/images/avatar-50.png'
import billete from '../../../../../assets/images/cash1-50.png'
import rewardIcon from '../../../../../assets/images/iconRewardBorder-50.png'
import inGameIcon from '../../../../../assets/images/iconInGame-50.png'
import bannerMoreInfo from '../../../../../assets/images/banners/banner-programa-lealtad.png'


// const multiplier = 1000;
// const maxQnty = 500;
// console.log('multiplier')
// console.log(multiplier)
// console.log('maxQnty')
// console.log(maxQnty)

const steps = [
  {
    amount: 100,
    earning: 50
  },
  {
    amount: 500,
    earning: 100
  },
  {
    amount: 1500,
    earning: 200
  },
  {
    amount: 3000,
    earning: 400
  }
]

/**
 * This Component renders the a stats item
 * @param amount { Number }
 * @param title { String }
 * @param size { Number }
 * @param format { String }
 * @param tooltipLabel { String }
 * @return Component
 */
const StatsItem = ({ amount, title, size, format, tooltipLabel }) => {
  let styleStatus = ''

  if (title === 'Activos') {
    styleStatus = 'active'
  } else if (title === 'Inactivos') {
    styleStatus = 'inactive'
  } else if (title === 'Completados') {
    styleStatus = 'complete'
  }
  return (
    <div className={`flex-col stats-item col-${size}`}>
      <ReactTooltip id={title} place='bottom' type='light' effect='solid'>
        {tooltipLabel}
      </ReactTooltip>
      <div data-tip data-for={title} className='flex-row stats-title row-no-padding'>
        <div className='flex-col col-no-padding'>{title}</div>
      </div>
      <div data-tip data-for={title} className='flex-row row-no-padding  stats-data'>
        <div className={`flex-col col-no-padding ${styleStatus}`}>
          {format ? numeral(amount).format('$0,000') : amount}
        </div>
      </div>
    </div>
  )
}

/**
 * This Component renders the Table the relation between the prizes given fron referrer / referral Program
 * @param steps { Array }
 * @return Component
 */
const TableEarnings = ({ steps }) => {
  return (
    <div className='flex-row'>
      <div className='flex-col col-no-padding col-center'>
        <a
          href='https://www.pickwin.mx/programa-lealtad/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img src={bannerMoreInfo} className='img-responsive referral-banner ' />
        </a>
      </div>
    </div>
  )

  // console.log(steps);

  const listAmountsPlayed = steps.map((step) => {
    return (
      <div key={step.amount} className='table-item flex-col  col-center text-center'>
        {numeral(step.amount).format('$0,000 ')}
      </div>
    )
  })

  const listEarnings = steps.map((step) => {
    return (
      <div key={step.earning} className='flex-col table-item  col-center text-center'>
        {numeral(step.earning).format('$0,000 ')}
      </div>
    )
  })

  // let arrows = steps.map((step) => {
  //   return (
  //     <div key={step.earning} className=" col-center text-center">
  //       <i className=" ion-arrow-right-a" />{' '}
  //     </div>
  //   );
  // });

  return (
    <div id='table-earnings' className='flex-row'>
      <div className='flex-col cl-no-padding table-container'>
        <div className='flex-row row-center'>
          <div className='flex-col col-no-padding data-table amount'>
            <div className='title table-item flex-row text-center'>
              <img src={inGameIcon} className='flex-col col-center' />
            </div>
            <div className='title table-item col-center col-no-padding text-center'>
              Jugado por tu referido
            </div>
            {listAmountsPlayed}
          </div>
          <div className='flex-col col-20 ' style={{ fontSize: '2em' }}>
            <div className='title table-item col-no-padding col-center text-center'>
              <i className='ion-arrow-right-a' />
            </div>
          </div>
          <div className='flex-col col-no-padding data-table user'>
            <div className='title table-item flex-row text-center'>
              <img src={rewardIcon} className='flex-col col-center' />
            </div>
            <div className='table-item title col-no-padding col-center text-center'>
              Tu Recompensa
            </div>
            {listEarnings}
          </div>
        </div>
      </div>
    </div>
  )
}
/**
 * This Component renders the descritpion of the Program
 * @param title { String }
 * @param content { String }
 * @return Component
 */
const Description = ({ title, content }) => {
  return (
    <div className='flex-col col-center'>
      <div className='flex-row titulo-user-info'>
        <div className='flex-container justify align'>
          <span className='text-center'>{title}</span>
        </div>
      </div>
      <div className='flex-row content-description'>{content}</div>
      <TableEarnings steps={steps} />
    </div>
  )
}

/**
 * Renders the button that containes the cTA of the section
 * @param label { String }
 * @param action { function }
 */
const CallToAction = ({ label, action }) => {
  return (
    <div className='flex-col col-60 col-offset-20'>
      <button
        className='callToAction flex-row btn-pick btn-success'
        onClick={() => {
          action()
        }}
      >
        {label}
      </button>
    </div>
  )
}

const TitleReferral = ({ referredUser }) => {
  return (
    <div className='flex-row row-center  title-referred-user'>
      <div className='flex-col col-no-padding '>
        {referredUser.finished
          ? (
            <div className='flex-row row-center'>
              <strong className='nick'>{referredUser.referred_user.nick}</strong>
              <span>ha completado sus metas.</span>
              <i className='ion-ribbon-b' />
            </div>
            )
          : (
            <div className='flex-row row-center'>
              <div className='flex-col'>
                <strong className='nick'>{referredUser.referred_user.nick}</strong>
                <span>te ha hecho ganar</span>
                <i className='ion-arrow-right-a' />
                <img src={billete} className='pool-icons' />
                <strong>{numeral(referredUser.amount_given / 100).format('$0,000.0')}</strong>
              </div>
            </div>
            )}
      </div>
    </div>
  )
}

const UserInfoReferral = ({ referredUser }) => {
  // console.log('Uer Info Referral', referredUser);
  return (
    <div className='flex-col col-15 col-center'>
      <div className='flex-row row-center'>
        <img
          src={
            !referredUser || !referredUser.avatar || !referredUser.avatar_dir
              ? avatar
              : `${referredUser.avatar_dir}${referredUser.avatar}`
          }
          className='avatar-referral'
        />
      </div>
      {/* <div className="flex-row center">
        <span className="flex-col center text-center">{referredUser.nick}</span>
  </div> */}
    </div>
  )
}

/**
 * Renders the list of the newReferrals
 * @param newReferrals     { Array }
 * @param openInvitePanel  { function }
 * @return Component
 */
const ListReferral = ({
  newReferrals,
  openConfirmation,
  openInvitePanel,
  openInviteJugar,
  getBranchLink,
  getBranchLinkReferrer,
  getBranchLinkChallenge,
  updateBranchLink,
  updateUserInfo,
  openAbonar
}) => {
  // console.log(newReferrals);
  // console.log(MockNewReferrals);
  let refererFinished = newReferrals.filter((referal) => referal.finished)

  // let refererInProgress = newReferrals.filter((referal) => !referal.finished);

  refererFinished = refererFinished.map((referal) => {
    const progress = `${referal.progress}%`
    const remaining = `${100 - referal.progress}%`
    return (
      <li key={referal.id} className='flex-col referal-item col-no-padding finished'>
        <TitleReferral referredUser={referal} />
        <div className='flex-row'>
          <UserInfoReferral referredUser={referal.referred_user} />
          <div className='flex-col col-center'>
            <ReferralProgressBar
              referal={referal}
              progress={progress}
              remaining={remaining}
              steps={[
                { percentage: 10, prize: 50 },
                { percentage: 25, prize: 100 },
                { percentage: 50, prize: 250 },
                { percentage: 100, prize: 600 }
              ]}
              max={10000}
              type='finished'
            />
          </div>
        </div>
      </li>
    )
  })

  return (
    <div className='flex-col'>
      <div className='flex-row'>
        <div className='flex-col' id='description'>
          <Description title='' content={null} />
        </div>
      </div>
      <div className='flex-row row-no-padding'>
        <CallToAction
          label={
            <div className='flex-col'>
              {' '}
              <i className='ion-plus-round' /> <span>Invitar Amigos</span>{' '}
            </div>
          }
          action={() => {
            openInvitePanel()
            getBranchLinkReferrer()
          }}
        />
      </div>
      <InvitedReferralList
        openAbonar={openAbonar}
        updateUserInfo={updateUserInfo}
        openConfirmation={openConfirmation}
        getBranchLink={getBranchLink}
        getBranchLinkReferrer={getBranchLinkReferrer}
        getBranchLinkChallenge={getBranchLinkChallenge}
        updateBranchLink={updateBranchLink}
        openInvitePanel={openInvitePanel}
        openInviteJugar={openInviteJugar}
        newReferrals={newReferrals}
      />
    </div>
  )
}

export default ListReferral
