import moment from 'moment';
import React from 'react';
import jwt_decode from 'jwt-decode';
import Fingerprint2 from 'fingerprintjs2';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';

import _ from 'underscore-contrib';
import { publish } from './events'

const superId = 2;
const localStorage = window.localStorage;
const uagent = navigator.userAgent.toLowerCase();

export function mockDeadlinesScoreTest(poolInfo) {
  console.log('Mock Score Deadline');
  let newDateObj = moment()
    .add(8, 's')
    .toDate();

  poolInfo.pool.deadline = newDateObj;
  poolInfo.pool.deadline_tz = newDateObj;

  console.log(poolInfo.deadline);
  return poolInfo;
}

export function mockDeadlinesFixtures(fixtures) {
  console.log('Mock Fixtures Deadline', fixtures);
  let newDateObj = moment()
    .add(8, 's')
    .toDate();

  fixtures.forEach((fixture) => {
    fixture.deadline = newDateObj;
  });
  console.log(fixtures);
  return fixtures;
}

export function isBalanceEnough(entryCurrency, entryFee) {
  let isEnough = false;
  let userData = getUserInfo();
  let isLogged = this.isLogged();
  //let balances = getUserBalance();

  // console.log( entryCurrency ,entryFee ,userData ,isLogged )
  if (!entryCurrency || entryFee === undefined || entryFee === null || !userData || !isLogged) {
    return isEnough;
  }
  // console.log( 'balancee', entryFee , balances.balancePc, balances.balancePc >= entryFee );
  switch (entryCurrency) {
    case 'real':
      isEnough = getUserBalance().balanceReal >= entryFee;
      // debugger
      break;
    case 'pickcoin':
      isEnough = getUserBalance().balancePc >= entryFee;
      break;
    case 'referral':
      isEnough = getUserBalance().balanceReferral >= entryFee;
      break;
    case 'freeroll':
      isEnough = getUserBalance().balanceFreeroll >= entryFee;
      break;
    default:
      break;
  }
  return isEnough;
}


export function supportEs6() {
  // console.log( '-----SupportES6-----' );
  if (
    Array.prototype.hasOwnProperty('find') &&
    Array.prototype.hasOwnProperty('filter') &&
    Object.hasOwnProperty('values') &&
    Object.hasOwnProperty('assign')
  ) {
    Sentry.configureScope((scope) => {
      scope.setTag('isSupported', true);
    });
    return true;
  } else {
    console.error('Your browser is old maybe an update will be nice.');
    localStorage.setItem('notSupported', 'true');
    Sentry.configureScope((scope) => {
      scope.setTag('isSupported', false);
    });
    return false;
  }
}

export function detectDevice() {
  let device = null;
  if (uagent.search('iphone') > -1) {
    device = 'apple';
  } else if (uagent.search('ipad') > -1) {
    device = 'apple';
  } else if (uagent.search('ipod') > -1) {
    device = 'apple';
  } else if (uagent.search('ndroid') > -1) {
    device = 'android';
  }
  return device;
}

export function removeReadyToVerify() {
  localStorage.removeItem('readyToVerify');
}

export function setReadyToVerify(status) {
  localStorage.setItem('readyToVerify', status);
}

export function readyToVerify() {
  // console.log('%c Ready ToVerify', 'font-size:2em, color: #629022 ')
  let isReady = localStorage.getItem('readyToVerify');
  if (!isReady || isReady === '') {
    return false;
  }

  try {
    isReady = JSON.parse(isReady);
    // console.log(isReady);
    return isReady;
  } catch (error) {
    // console.error
  }
}

/**
 * this method handles if the user will show the verification panel
 * */
export function isVerified(userData) {
  //  console.log( userData);
  if (!userData || !userData.created) {
    return false;
  }

  // The verification actually is when user
  // deposits the first time
  return true
  // ---------------------------------------
  let userCreated = moment(userData.created);
  let now = moment();
  let isReadyToVerify = readyToVerify();

  let itPassedSec = now.diff(userCreated, 'seconds');

  setReadyToVerify(true);
  isReadyToVerify = true;

  // console.log( userData.identity_verified , itPassedSec );
  // console.log('Verify this user ', userData, isReadyToVerify);
  /**
   * User is verified
   */
  if (userData.identity_verified) {
    return true;
  } else if (!isReadyToVerify) {
    return true;
  } else if (
    /**
     * User isn't verified but it don t has an entry
     */
    (!userData.hasOwnProperty('entry_count') || userData.entry_count < 1) &&
    !isReadyToVerify
  ) {
    return true;
  }
  return false;
}

/**
 * This method check if the received responses arent completed or has an error in it
 * @param { Array } responses
 * return { Array }
 */
export function checkAxiosAllErrors(responses) {
  // console.log('%c Check Axios Failed Responses','color:#2e8ccf; font-size:2em;');
  // console.log( responses );
  if (!responses || responses.length === 0) {
    // console.error( 'responses Empty' );
    throw 'Responses are empty';
  } else {
    // let = [];
    let failedResponses = responses.filter((response) => {
      if (!response) {
        // console.error( 'response dont exist');
        return [null];
      } else if (
        !response.hasOwnProperty('data') ||
        response.status !== 200 ||
        response.data.error
      ) {
        // console.log('Response Active but failed');
        // axiosErrors.push( response.config.url )
        return response;
      }
    });

    // console.log( failedResponses.length, failedResponses[0]  );
    if (failedResponses.length > 0) {
      // console.log('%c A Response failed', 'color:#981713; font-size:2em;')
      // console.log(failedResponses);
      // console.log(failedResponses[0]);

      let url =
        (failedResponses[0] === null || failedResponses[0] === undefined) || !failedResponses[0].hasOwnProperty('config')
          ? 'Didnt Received '
          : failedResponses[0].config.url;

      throw `FailedResponse: ${url} `;
    } else {
      // console.log('%c All Responses succed', 'color:#78bc28; font-size:2em;')
    }
  }
}

/**
 * This method checks if the jwt has been expired or has 5 minutes of activespan
 * @param { String } token
 * return { Boolean }
 */
export function isTokenExpired(token) {
  if (token) {
    const decode = jwt_decode(token);

    // Window expiration Time 2 minutes
    const EXPIRED_MILLISECONDS_GAP = 1000 * 60 * 2

    const expirationTime = decode.exp * 1000 - EXPIRED_MILLISECONDS_GAP
    const isValid = Date.now() < expirationTime

    if (!isValid) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
/**
 * This method initialize the Raven Configuration
 */
export function startRaven(enviroment) {
  console.log('%c Start Raven!', 'background:#052d52; color:#fff; font-size:2em;');
  console.log(enviroment);
  let ravenOptions = {
    dsn: 'https://3e8e1b79291f4e27ab316e8e8369d42f@o508345.ingest.sentry.io/5600723',
    // beforeBreadcrumb(breadcrumb, hint) {
    //   console.log('%c -------BeforeBreadcumb-------', 'color:rgb(52, 98, 187); font-size:2em');
    //   console.log(breadcrumb, hint);
    //   if (breadcrumb.category === 'ui.click') {
    //     const { target } = hint.event;
    //     if (target.ariaLabel) {
    //       breadcrumb.message = target.ariaLabel;
    //     }
    //   }
    //   return breadcrumb;
    // },
    ignoreUrls: [/api2\.branch\.com\//i, /api\.branch\.com\//i],
    // beforeSend: ( event, hint ) => {
    //   const userData = this.getUserInfo();
    //   Sentry.configureScope( ( scope ) => {
    //     console.log('BeforeSend', event , hint, userData, scope);
    //     scope.setUser( {
    //       username: userData.nick,
    //       email: userData.email,
    //       id: userData.id,
    //     } );
    //   } );
    // },
    enviroment: enviroment,
    debug: enviroment === 'debug',
    release: '1.0ravenBuild',
    // ignoreErrors:[
    //   'Token didnt exist',
    //   "\'Promise\' is undefined",
    //   'Expired Session',
    //   'Network Error',
    //   'Using adblock',
    //   "Cannot read property \'__reactInternalInstance$ot5kmqnrk7k\' of null",
    //   '{"nick":{"unique":"Ese apodo ya está tomado"}}',
    //   "Cannot read property '_avast_submit' of undefined",
    //   'No tienes balance suficiente',
    //   's.removedNodes[Symbol.iterator] is not a function',
    //   'Error in registerPanel',
    //   'undefined is not an object (evaluating \'e.data\')',
    //   'Cannot read property \'__reactInternalInstance$vspljvsf3l\' of null',
    //   'Cannot read property \'firstChild\' of null',
    // ]
  };
  // Initialization for new version
  // Sentry 4.2
  Sentry.init(ravenOptions);
}
/**
 *This method starts the contet in the raven instance
 *
 * @param { Object } userData
 *
 */
export function setUserContextRaven(userData) {
  // console.log( 'Setting user ContextRaven', userData);
  if (!userData) {
    const user = this.getUserInfo();
    console.log('this user ', user);
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: user.nick,
        email: user.email,
        id: user.id,
      });
    });
  }

  // console.log( 'this user ', userData );
  Sentry.configureScope((scope) => {
    scope.setUser({
      username: userData.nick,
      email: userData.email,
      id: userData.id,
    });
  });
}

export function catchErrorRaven(error, userData, extraInfo, extra) {
  // console.log('CatchError');
  if (!userData || !userData) {
    return;
  }
  Sentry.captureException(error, {
    extra: extra,
  });
  // Sentry.captureMessage('Error in Component:' + extraInfo);
}

export function postMessageRaven(messageInfo) {
  Sentry.captureMessage('Error Message:' + messageInfo);
}

export function removeDownloadAppView() {
  localStorage.removeItem('viewDownloadApp');
}
/**
 *Delete localStorage data user
 **/

export function clearStorage() {
  console.log('Clear Storage');
  localStorage.removeItem('hasSentAnalytics');
  localStorage.removeItem('readyToVerify');
  localStorage.removeItem('user');
  localStorage.removeItem('userLoyalty');
  localStorage.removeItem('allowed');
  localStorage.removeItem('balance');
  localStorage.removeItem('jwtoken');
  localStorage.removeItem('prevPoolId');
  localStorage.removeItem('prevBracketId');
  localStorage.removeItem('mobileBanner');
  localStorage.removeItem('tokenHash');
  localStorage.removeItem('queryStringFilters');
}

/**
 *
 * @param {URLSearchParams} params
 */
export function setFilters(params) {

  let search = params.toString();
  if ( _.isEmpty(search) ) {
    return;
  }
  let filtersObj = _.fromQuery(search);
  const allowedFilters = [
      'buyIn',
      'currency',
      'gameType',
      'isFull',
      'isPrivate',
      'league',
      'sports',
      'status',
      'type'
  ];
  filtersObj = _.pick(filtersObj, allowedFilters);

  let stringedFilters = JSON.stringify(filtersObj);
  localStorage.setItem('queryStringFilters', stringedFilters);
}

export function removeFilters() {
  localStorage.removeItem('queryStringFilters');
}

export function getFilters() {
  let filters = localStorage.getItem('queryStringFilters');
  let parsed;
  try {
    parsed = JSON.parse(filters);
  } catch (e) {
    return null;
  }
  // console.log(parsed);
  return parsed;
}
/**
 * This methid Sets the createdPrivate flag to true
 */
export function setCreatedPrivate() {
  localStorage.setItem('createdPrivate', 'true');
}
/**
 * This methid removes the createdPrivate item
 */
export function removeCreatedPrivate() {
  localStorage.removeItem('createdPrivate');
}
/**
 * This methid Sets the createdPrivate flag to true
 */
export function setShowPopUpHomeNote(timestamp) {
  console.log('set this timestamp: ', timestamp);
  localStorage.setItem('popUpHomeNote', timestamp);
}
/**
 * This methid Sets the createdPrivate flag to true
 */
export function getShowPopUpHomeNote() {
  const tempData = localStorage.getItem('popUpHomeNote');
  if (!tempData) {
    return null;
  }
  return tempData;
}
/**
 * This methid removes the createdPrivate item
 */
export function removeShowPopUpHomeNote() {
  localStorage.removeItem('popUpHomeNote');
}
/**
 * Save data for not logged user
 **/
export function saveDataNotLoggedUser(redirObj) {
  if (!redirObj) {
    return;
  }
  // console.log('SaveData NosLogged', redirObj);
  localStorage.setItem('redirectTo', JSON.stringify(redirObj));
}

export function getDataNotLoggedUser() {
  const tempData = localStorage.getItem('redirectTo');
  if (!tempData) {
    return null;
  }
  return JSON.parse(tempData);
}

export function removeDataNotLoggedUser() {
  localStorage.removeItem('redirectTo');
}
/**
 * Puts flag to show the invite panel
 */
export function setShowInvitePanel() {
  localStorage.setItem('showInvitePanel', 1);
}
/**
 * Gets the flag to show the invite panel
 */
export function getShowInvitePanel() {
  return localStorage.getItem('showInvitePanel');
}
/**
 * Clears invite panel flag
 */
export function clearShowInvitePanel() {
  localStorage.removeItem('showInvitePanel');
}
export function getUserMetadataRegister(search) {

  let userMetadata = {};
  //check cookies
  const cookieNames = ['_fbc', '_fbp', '_ga', '_gid'];
  for ( const cookieName of cookieNames ) {
    const value = Cookies.get(cookieName);
    if ( value ) {
      userMetadata[cookieName] = value;
    }
  }
  const skipParams = ['buyin[]', 'currency[]', 'filters', 'gameType[]', 'isFull[]', 'league[]', 'sports[]', 'type[]'];
  // check for query string
  let params = new URLSearchParams(search);
  for (let [name, value] of params) {
    if ( !skipParams.includes(name) ) {
      userMetadata[name] = decodeURIComponent(value);
    }
  }
  let stringedMetadata = JSON.stringify(userMetadata);
  localStorage.setItem('userMetadata', stringedMetadata);
}
/**
 * Sets the users metadata
 * @param {object} metadata
 */
export function setUserMetadata(metadata) {
  console.log('METADATA', metadata);
  if (!metadata || metadata.length === 0) {
    return;
  }
  console.log(metadata);
  localStorage.setItem('userMetadata', JSON.stringify(metadata));
}

export function getUserMetadata() {
  let meta = localStorage.getItem('userMetadata');
  let parsed = {};
  try {
    parsed = JSON.parse(meta);
  } catch (e) {
    return null;
  }
  return parsed;
}
export function clearUserMetadata() {
  console.log('Clear USerMEtadata');
  localStorage.removeItem('userMetadata');
}
export function getReferralsStatus() {
  let str = localStorage.getItem('showReferralPopUp');

  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let obj = null;
  try {
    obj = JSON.parse(str);
  } catch (e) {}
  return obj;
}
export function showReferralsPopup() {
  localStorage.setItem('showReferralPopUp', true);
}
export function hideReferralsPopUp() {
  localStorage.setItem('showReferralPopUp', false);
}
export function removeReferralsPopUp() {
  localStorage.setItem('showReferralPopUp', false);
  // localStorage.removeItem('showReferralPopUp');
}
export function getTutorialSurvivorStatus() {
  let str = localStorage.getItem('showTutorialSurvivorPopUp');

  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let obj = null;
  try {
    obj = JSON.parse(str);
  } catch (e) {}
  return obj;
}
export function showTutorialSurvivorPopup() {
  localStorage.setItem('showTutorialSurvivorPopUp', true);
}
export function hideTutorialSurvivorPopUp() {
  localStorage.setItem('showTutorialSurvivorPopUp', false);
}
/**
 * Set the poolId to return later
 **/
export function setPrevPool(poolId) {
  localStorage.setItem('prevPoolId', poolId);
}
/**
 * Get the poolId to return later
 **/
export function getPrevPool() {
  const poolId = localStorage.getItem('prevPoolId');
  return poolId;
}
/**
 * Delete the poolId after the pool was visited
 **/
export function clearPrevPool() {
  localStorage.removeItem('prevPoolId');
}
/**
 * Set the bracketId to return later
 **/
export function setPrevBracket(bracketId) {
  localStorage.setItem('prevBracketId', bracketId);
}
/**
 * get the bracketId to return later
 **/
export function getPrevBracket() {
  const prevBracketId = localStorage.getItem('prevBracketId');
  return prevBracketId;
}
/**
 * Delete the bracketId to return later
 **/
export function clearPrevBracket() {
  localStorage.removeItem('prevBracketId');
}
/**
 * Check the fingerprint and cookie availability
 * and set it to the local
 *
 */
export function initFingerprint() {
  let fingerPrintStorage = localStorage.getItem('fingerprint');
  let fingerprintExist = !localStorage.getItem('fingerprint') ? false : true;

  if (fingerprintExist && fingerPrintStorage !== 'null' && fingerPrintStorage !== 'undefined' && fingerPrintStorage !== '') {
    return localStorage.getItem('fingerprint');
  }

  let fingerPrint = '';

  Fingerprint2.getPromise({})
    .then((components) => {
      // console.log( components );
      let values = components.map((component) => {
        return component.value;
      });

      let salt = moment().unix();

      let toHash = salt + '' + values.join('');

      let murmur = Fingerprint2.x64hash128(toHash, 31);

      fingerPrint = murmur;

      let cookieFingerprint = Cookies.get('fingerprint');
      // console.log( 'CoookieFP', cookieFingerprint, fingerPrint );
      if (cookieFingerprint === undefined) {
        Cookies.set('fingerprint', fingerPrint);
        localStorage.setItem('fingerprint', fingerPrint);
        return fingerPrint;
      } else {
        localStorage.setItem('fingerprint', fingerPrint);
        return fingerPrint;
      }
    })
    .catch((e) => {
      console.error(e);
      Sentry.captureException(e);
    });
}
/**
 * Set the Fingerprint for the user
 **/
export function setFingerprint(fingerprint) {
  localStorage.setItem('fingerprint', fingerprint);
}
export function getFingerprint() {
  return localStorage.getItem('fingerprint');
}

/**
 * Check if user is logged
 **/
export function isLogged() {
  //TODO: check for user.active === 1
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem('user'));
    // console.log(user);
  } catch (error) {
    // console.error('HERE', error);
    this.clearStorage();
    Sentry.captureException(error, {
      extra: 'Error getting UserInfo from storage',
    });
    return false;
  }

  if (user && user.hasOwnProperty('user')) {
    console.log('Old Storage', user.hasOwnProperty('user'));
    localStorage.setItem('user', user.user);
    user = user.user;
  }

  return user !== null;
}
export function hasSeenLanding() {
  let hasSeenLanding = localStorage.getItem('hasSeenLanding');

  if (!hasSeenLanding) {
    return false;
  }
  try {
    hasSeenLanding = JSON.parse(hasSeenLanding);
    return hasSeenLanding;
  } catch (error) {
    return false;
  }
}
/**
 * Get user balance of the user
 **/
export function getUserBalance() {
  let balance = null;
  // console.log( 'getUserBalance' );
  try {
    // console.log(JSON.parse(localStorage.getItem('balance') ));
    balance = JSON.parse(localStorage.getItem('balance'));
  } catch (error) {
    console.error('Error getting balance from local storage', error);
    // this.clearStorage();
    Sentry.captureException(error, {
      extra: 'Error getting UserBalance from storage',
    });
    return null;
  }
  // console.log( balance );

  return balance;
}
/**
 * Get user info of the user
 **/
export function getUserInfo() {
  // console.log('getUserInfo');
  // console.log(localStorage.getItem("user"));
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    console.error(error);
    this.clearStorage();
    Sentry.captureException(error, {
      extra: 'Error getting UserInfo from storage',
    });
    return null;
  }

  if (user && user.hasOwnProperty('user')) {
    // console.log('Old Storage', user.hasOwnProperty('user'), user.user );
    localStorage.setItem('user', JSON.stringify(user.user));
    const balanceObj = {
      balanceReal: user.balanceReal,
      balancePcReal: user.balancePcReal,
      balancePc: user.balancePc,
      balanceReferral: user.balanceReferral,
      balanceFreeroll: !user.balanceFreeroll ? 0 : user.balanceFreeroll,
      tickets: !user.tickets ? [] : user.tickets,
    };
    localStorage.setItem('balance', JSON.stringify(balanceObj));
    return user.user;
  }

  return user;
}
/**
 * Get loyalty info of the user
 **/
export function getUserLoyalty() {
  let userLoyalty = null;
  try {
    userLoyalty = JSON.parse(localStorage.getItem('userLoyalty'));
  } catch (error) {
    console.error(error);
    this.clearStorage();
    Sentry.captureException(error, {
      extra: 'Error getting UserLoyalty from storage',
    });
    return null;
  }

  return userLoyalty;
}
export function setEntryGift(entryGift) {
  console.log('Set entry Gift', entryGift);
  localStorage.setItem('entryGift', JSON.stringify(entryGift));
}
export function getEntryGift() {
  let str = localStorage.getItem('entryGift');
  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let entryGiftObj = null;
  try {
    entryGiftObj = JSON.parse(str);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
  return entryGiftObj;
}
export function removeEntryGift() {
  localStorage.removeItem('entryGift');
}
export function setPopUpDeposit(popUpDeposit) {
  console.log('Set pop up deposit', popUpDeposit);
  localStorage.setItem('popUpDeposit', JSON.stringify(popUpDeposit));
}
export function getPopUpDeposit() {
  let str = localStorage.getItem('popUpDeposit');
  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let popUpDepositObj = null;
  try {
    popUpDepositObj = JSON.parse(str);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
  return popUpDepositObj;
}
export function removePopUpDeposit() {
  localStorage.removeItem('popUpDeposit');
}
/**
 * Delete user local strage to logout
 **/
export function logout() {
  console.log('logout');
  localStorage.removeItem('hasSentAnalytics');
  localStorage.removeItem('user');
  localStorage.removeItem('userLoyalty');
  localStorage.removeItem('readyToVerify');
  localStorage.removeItem('balance');
  localStorage.removeItem('allowed');
  localStorage.removeItem('jwtoken');
  localStorage.removeItem('referer');
  localStorage.removeItem('isNewFlowActive');
  localStorage.removeItem('showGameFreeRoll');
  localStorage.removeItem('showTutorialLobby');
  localStorage.removeItem('showGameTutorial');
  localStorage.removeItem('prevPoolId');
  localStorage.removeItem('misQuinielasItems');
  localStorage.removeItem('prevBracketId');
  localStorage.removeItem('redirectTo');
  localStorage.removeItem('popUpNewUser');
}
export function setAllowedUser(status) {
  localStorage.setItem('allowed', status);
}
/**
 * EnableBanner Moobile
 **/
export function setUserBalance(userData) {
  console.log('Set USer Balance');
  // console.log( userData.tickets );
  if (!userData) {
    return null;
  }

  let balanceObj = {
    balancePc: userData.balancePc,
    balancePcReal: userData.balancePcReal,
    balanceReal: userData.balanceReal,
    balanceReferral: userData.balanceReferral,
    balanceFreeroll: !userData.balanceFreeroll ? 0 : userData.balanceFreeroll,
    balanceBonus: !userData.balanceBonus ? 0 : userData.balanceBonus,
    tickets: userData.tickets,
    balanceVip: userData.balanceVip
  };
  publish('setUserBalance-context', balanceObj)
  localStorage.setItem('balance', JSON.stringify(balanceObj));
  // console.log( "newBalance", balanceObj);
  return balanceObj;
}
/**
 * EnableBanner Mobile
 **/
export function setUserInfo(userData) {
  if (!userData) {
    return;
  }

  Sentry.configureScope((scope) => {
    const user = userData.hasOwnProperty('user') ? userData.user : userData;
    scope.setUser({
      username: user.nick,
      email: user.email,
      id: user.id,
    });
  });
  /**
   * Update value if the user is super user
   * **/
  if (userData.group_id === superId) {
    localStorage.setItem('suPick', true);
  } else {
    localStorage.removeItem('suPick');
  }

  const balanceObj = {
    balancePc: userData.balancePc,
    balancePcReal: userData.balancePcReal,
    balanceReal: userData.balanceReal,
    balanceFreeroll: !userData.balanceFreeroll ? 0 : userData.balanceFreeroll,
    balanceReferral: userData.balanceReferral,
    balanceBonus: userData.balanceBonus,
    tickets: !userData.tickets ? [] : userData.tickets,
  };
  // console.log( balanceObj );
  const userLoyalty = {
    level: userData.level,
    lastLevel: userData.lastLevel,
    nextLevel: userData.nextLevel,
    points: userData.points,
    lastReward: userData.lastReward,
    animation: userData.animation,
  };

  if (userData.user) {
    userData.user.userBonuses = userData.userBonuses;
  } else {
    userData.userBonuses = 0;
  }

  localStorage.setItem('user', JSON.stringify(userData.user));
  localStorage.setItem('userLoyalty', JSON.stringify(userLoyalty));
  localStorage.setItem('balance', JSON.stringify(balanceObj));
}
/**
 *
 *Check if the user is SuperUser
 * @returns boolean
 */
export function isSuPick() {
  // console.log('%c is Super user Pickwin?', 'color:#78bc28; font-size:2em;');
  let isSuPick = localStorage.getItem('suPick');
  let userData = null;
  try {
    userData = JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    localStorage.removeItem('suPick');
    return false;
  }
  // console.log(userData , isSuPick);
  if (!userData || isSuPick === '') {
    localStorage.removeItem('suPick');
    return false;
  } else {
    let userGroupId = userData.group_id;
    if (userGroupId === superId) {
      // console.log(userGroupId , superId);
      localStorage.setItem('suPick', true);
      return true;
    }
    localStorage.removeItem('suPick');
    return false;
  }
}
export function removeTutorialLobby() {
  localStorage.removeItem('showTutorialLobby');
}
// uncomment to enable lobby tutorial after showfreeroll
export function showTutorialLobby(step, status) {
  // console.log('Set Tutorial Lobby', step);
  // let showTutorial = { show: status, step: null };
  // if (step || step === 0) {
  //   showTutorial.step = step;
  // }
  // localStorage.setItem('showTutorialLobby', JSON.stringify(showTutorial));
}
export function getShowTutorialLobby() {
  // console.log('Get Show Tutorial Lobby');

  let showTutorial = localStorage.getItem('showTutorialLobby');
  // console.log('showTutorial');
  // console.log( showTutorial );
  if (showTutorial === '' || !showTutorial) {
    return false;
  }
  try {
    showTutorial = JSON.parse(showTutorial);
    return showTutorial;
  } catch (error) {
    console.error(error);
    return false;
  }
}
export function getShowGameTutorial() {
  let gameTutorial = localStorage.getItem('showGameTutorial');
  // console.log( 'Get Game tutorial' , gameTutorial );
  if (gameTutorial === '' || !gameTutorial) {
    return null;
  }

  try {
    gameTutorial = JSON.parse(gameTutorial);
    // console.log('GameTutorial PArsed', gameTutorial);
    return gameTutorial;
  } catch (error) {
    return null;
  }
}
export function showGameTutorial(step, status) {
  // console.log('Set Game Tutorial', step);
  let showGameTutorial = { show: status, step: null };
  if (step || step === 0) {
    showGameTutorial.show = status;
    showGameTutorial.step = step;
  }
  localStorage.setItem('showGameTutorial', JSON.stringify(showGameTutorial));
}
export function removeGameTutorial() {
  localStorage.removeItem('showGameTutorial');
}
export function removeNewFlowActive() {
  localStorage.removeItem('isNewFlowActive');
}
export function setIsNewFlowActive(status) {
  localStorage.setItem('isNewFlowActive', JSON.stringify(status));
}
export function isNewFlowActive() {
  // console.log('%c Is New flow Active', 'font-size: 2em;')

  let newFlow = {
    active: false,
    type: null,
    gameId: null,
    gameType: null,
  };

  let itHasLastLogin = getLastLogin();
  if (itHasLastLogin) {
    setIsNewFlowActive(newFlow);
    return false;
  } else {
    let organicUser = getShowGameFreeRoll();
    let forProduct = isNewUserForProduct();

    console.log('forProduct', forProduct);
    if (forProduct && forProduct.firstTimeType === 'product') {
      newFlow.gameId = forProduct.poolId;
      newFlow.gameType = 'pool';
      newFlow.type = 'product';
    } else if (organicUser || (forProduct && forProduct.firstTimeType === 'freeroll')) {
      newFlow.type = 'organic';
    } else {
      setIsNewFlowActive(newFlow);
      return false;
    }

    newFlow.active = true;
    setIsNewFlowActive(newFlow);
    return newFlow;
  }
}
export function setLastLoigin() {
  localStorage.setItem('lastLogin', new Date.getTime());
}
export function getLastLogin() {
  let lastLog = localStorage.getItem('lastLogin');
  if (lastLog === '' || !lastLog) {
    return null;
  } else {
    try {
    } catch (error) {
      console.error(error);
    }
  }
}
export function setShowGameFreeRoll(step, status) {
  console.log('Set Game Free Roll Lobby', step);
  let showGameFreeRoll = { show: status, step: null };
  if (step || step === 0) {
    showGameFreeRoll.show = status;
    showGameFreeRoll.step = step;
  }

  localStorage.setItem('showGameFreeRoll', JSON.stringify(showGameFreeRoll));
}
export function removeShowGameFreeRoll() {
  localStorage.removeItem('showGameFreeRoll');
}
export function getShowGameFreeRoll() {
  let organicNewUser = localStorage.getItem('showGameFreeRoll');
  if (organicNewUser === '' || !organicNewUser) {
    return false;
  } else {
    try {
      organicNewUser = JSON.parse(organicNewUser);
      return organicNewUser;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
/**
 * HELP Panel storage
 */
export function setShowHelpPanel(status) {
  localStorage.setItem('helpPanel', JSON.stringify(status));
}
export function removeHelpPanel() {
  localStorage.removeItem('helpPanel');
}
export function getHelpPanel() {
  let helpPanel = localStorage.getItem('helpPanel');
  console.log('Help Panel', helpPanel);
  if (helpPanel === '' || !helpPanel) {
    return null;
  }
  try {
    helpPanel = JSON.parse(helpPanel);
    console.log(helpPanel);
    return helpPanel;
  } catch (error) {
    console.log(error);
    return null;
  }
}
/**
 * Get popup new user state
 **/
export function getPopUpNewUsers() {
  let str = localStorage.getItem('popupNewUsers');
  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let obj = null;
  try {
    obj = JSON.parse(str);
  } catch (e) {}
  return obj;
}
export function isNewUserForProduct() {
  let newUserForProduct = localStorage.getItem('popupNewUsers');
  console.log('Is new from Product Landing?', newUserForProduct);
  if (newUserForProduct === '' || !newUserForProduct) {
    console.log('false');
    return false;
  }

  console.log(newUserForProduct);
  try {
    newUserForProduct = JSON.parse(newUserForProduct);
    console.log(newUserForProduct);
  } catch (e) {
    console.log(false);
    return false;
  }

  console.log(newUserForProduct);

  if (newUserForProduct.hasOwnProperty('gameId')) {
    console.log(newUserForProduct);
    return newUserForProduct;
  } else {
    console.log(newUserForProduct);
    return false;
  }
}
/**
 * Delete data of the new user
 **/
export function deletePopUpNewUser() {
  localStorage.removeItem('popupNewUsers');
}
/**
 * Set the stat of the new user to open the  popup
 **/
export function setPopUpNewUsers(referer) {
  // localStorage.setItem("popupNewUsers", referer);
}
/**
 * Set referer to catch the user referral code
 **/
export function setReferer(referer) {
  if (!referer) {
    return;
  }
  console.log('Set Referer ', referer);
  localStorage.setItem('referer', referer);
}
/**
 * Get referer to add in forms
 **/
export function getReferer() {
  return localStorage.getItem('referer');
}
/**
 * Set token to check User ID
 **/
export function setToken(jwToken) {
  if (jwToken && jwToken !== 'undefined') {
    localStorage.setItem('jwtoken', jwToken)
  }
}
/**
 * Set token hash
 **/
export function setTokenHash(tokenHash) {
  localStorage.setItem('tokenHash', tokenHash);
}
/**
 * Get token to handle User ID
 **/
export function getToken() {
  return localStorage.getItem('jwtoken');
}
/**
 * Get token hash
 **/
 export function getTokenHash() {
  return localStorage.getItem('tokenHash');
}
/**
 * Set Open misquinielas
 **/
export function setMisQuinielasActiveItems(items) {
  localStorage.setItem('misQuinielasItems', items);
}
/**
 * get open mis quinielas
 **/
export function getMisQuiniellasActiveItems() {
  const value = localStorage.getItem('misQuinielasItems');

  if (!value || value.length < 0) {
    return false;
  }

  return value;
}
/**
 * This function update the Entry Data of the user
 * @params prevStanding is the data that will be modified with newStandings
 * @params newStandings is the data with the new values
 * @return the newUpdatedPositions is the prevStanding with the new values mapped
 */
export function updateStandingsComplete(
  newStandings,
  prevStandings,
  updateStandingsFixturePoints,
  updateStandingsPositions,
  updateStandingsAganar
) {
  // console.log(
  //   '%c Standings Updated',
  //   'color:rgb(154, 70, 48); font-size:1.2em;'
  // );
  // console.log(newStandings, prevStandings);
  let newUpdatedPositions = prevStandings.items;
  newUpdatedPositions.forEach((standing) => {
    newStandings.forEach((nextStanding) => {
      // console.log(nextStanding)
      if (nextStanding.entry_id !== standing.entry_id) {
        return;
      }
      standing['points'] = nextStanding.points;
      standing['rank'] = nextStanding.rank;
      standing['total_possibly_correct'] = nextStanding.total_possibly_correct;
      standing['user_prize']['base_amount'] = nextStanding.prize_amount;
      standing['user_prize']['_amount'] = nextStanding._amount;
      standing['user_prize']['tax'] = nextStanding.tax;
      standing['prize_amount'] = nextStanding.prize_amount;
    });
  });

  return newUpdatedPositions;
}
/**
 * This function update the Entry Data of the user
 * @params currentEntry is the data that will be modified with newEntryData
 * @params updatedStandings is the data with the new values
 * @return the updatedEntryData is the prevEntryData with the new values mapped
 */
export function updateEntry(currentEntry, updatedStandings, tiesDistribution) {
  console.log('%c Update Entry live', 'color:rgb(154, 70, 48); font-size:1.2em;');
  console.log('myEntry', currentEntry);
  // console.log('updatedStandings', updatedStandings);

  let myStanding = updatedStandings.find((_standing) => _standing.entry_id === currentEntry.id);
  console.log('MyStanding', myStanding);
  if (!myStanding) {
    return currentEntry;
  }

  currentEntry['standing']['rank'] = myStanding.rank;
  currentEntry['standing']['points'] = myStanding.total_possibly_correct;
  currentEntry['standing']['total_possibly_correct'] = myStanding.total_possibly_correct;
  currentEntry['standing']['rank'] = myStanding.rank;

  if (!currentEntry.user_prize) {
    currentEntry['user_prize'] = {
      base_amount: myStanding.prize_amount,
    };
  } else {
    currentEntry['user_prize'] = {
      base_amount: myStanding.prize_amount,
    };
  }
  // console.log('New',currentEntry);
  return currentEntry;
}

export function ConfirmationOverlay({
  isError,
  errorMessage,
  isCopy,
  showOverlay,
  label,
  type,
  currentSavedPicks,
  allPicks,
}) {
  let picksStatus = null;
  let iconStatus = null;
  if (isError) {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          <strong className="title">¡Tenemos un error al guardar tus picks!</strong>
        </div>
        <div className="flex-container justify">
          {!errorMessage ? 'Por favor inténtalo nuevamente' : errorMessage}
        </div>
      </div>
    );
    iconStatus = <i className="ion-alert-circled"></i>;
  } else if (isCopy) {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          <strong className="title">¡Tus picks se copiaron con éxito!</strong>
        </div>
        <div className="flex-container justify">Recuerda guardar tus picks.</div>
      </div>
    );
  } else {
    if (type === 'survivor') {
      picksStatus = (
        <div className="flex-container column align justify">
          <div className="flex-container justify">
            <strong className="title">¡Pick Guardado!</strong>
          </div>
          <div className="flex-container justify align text-center">{label}</div>
          <div className="flex-container justify align">
            <i className="ion-checkmark"></i>
          </div>
        </div>
      );
      iconStatus = null;
    } else if (currentSavedPicks !== allPicks) {
      picksStatus = (
        <div className="flex-container column align justify">
          <div className="flex-container justify">
            <strong className="title">¡Picks Guardados!</strong>
          </div>
          <div className="flex-container justify align text-center">
            Tienes {currentSavedPicks}/{allPicks} picks guardados.
          </div>
          <div className="flex-container justify align text-center">
            Recuerda ingresar todos tus picks.
          </div>
        </div>
      );
      iconStatus = <i className="ion-alert-circled"></i>;
    } else {
      /**
       * Complete Picks
       **/
      picksStatus = `¡Felicidades tus picks están completos!`;
      iconStatus = <i className="ion-checkmark-circled"></i>;
    }
  }
  return (
    <div
      className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}
      ${isError ? 'error' : ''}  ${isCopy ? 'copy' : ''}`}
    >
      <span>
        {iconStatus} {picksStatus}
      </span>
    </div>
  );
}

export function setFromLanding() {
  localStorage.setItem('fromLanding', 'true');
}
export function removeFromLanding() {
  localStorage.removeItem('fromLanding');
}
export function getFromLanding() {
  // console.log( '%c isFirstUser', 'font-size:2em; color:rgba(53, 122, 240, 1) ;' )
  // console.log(localStorage.getItem('isFirstTimeUser') );
  let str = localStorage.getItem('fromLanding');
  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let obj = null;
  try {
    obj = JSON.parse(str);
  } catch (e) {}
  return obj;
}
export function setShowTutorial() {
  localStorage.setItem('showTutorial', 'true');
}
export function removeShowTutorial() {
  localStorage.removeItem('showTutorial');
}
export function getShowTutorial() {
  // console.log( '%c isFirstUser', 'font-size:2em; color:rgba(53, 122, 240, 1) ;' )
  // console.log(localStorage.getItem('isFirstTimeUser') );
  let str = localStorage.getItem('showTutorial');
  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let obj = null;
  try {
    obj = JSON.parse(str);
  } catch (e) {}
  return obj;
}
export function setFirstTimeUser() {
  localStorage.setItem('isFirstTimeUser', 'true');
}
/**
 * This method checks if is the first time that the user visit the site
 * @return boolean
 */
export function isFirstTimeUser() {
  // console.log( '%c isFirstUser', 'font-size:2em; color:rgba(53, 122, 240, 1) ;' )
  // console.log(localStorage.getItem('isFirstTimeUser') );

  let str = localStorage.getItem('isFirstTimeUser');
  if (str === '' || str === null || str === undefined) {
    return null;
  }
  let obj = null;
  try {
    obj = JSON.parse(str);
  } catch (e) {}
  return obj;
  // console.log('----isFirstTiner-----');
  //
  // console.log( isFirstTimeUser );
  // // console.log( localStorage.getItem('isFirstTimeUser') );
  // if ( isFirstTimeUser && $( window ).width() < 769 ) {
  //   localStorage.setItem( 'isFirstTimeUser', true );
  //   return true
  // } else {
  //   // localStorage.setItem('isFirstTimeUser', false);
  //   return false;
  // }
}
export function turnOffFirstTimeUser() {
  localStorage.setItem('isFirstTimeUser', false);
}
/**
 * This function search if the pool is special
 * and has to use an image
 * @param poolName - String This string is used to check the pool name
 * return boolean
 *
 */
export function itNeedsImage(patternName, name) {
  // console.log('%c It needs Image ', 'color: #2e8ccf ; font-size:2em;');
  const re = new RegExp(patternName, 'i');
  const str = String(name);

  return re.test(str);
}

export function getCookiesKeyVal() {
  var result = {};
  const cookieNames = ['_fbc', '_fbp', '_ga', '_gid'];
  for ( const cookieName of cookieNames ) {
    const cookie = Cookies.get(cookieName);
    if (cookie) {
      result[cookieName] = cookie;
    }
  }
  return result;
}

export default {
  getPopUpNewUsers,
  setUserInfo,
  getUserMetadata,
  setToken,
  setUserBalance,
  getMisQuiniellasActiveItems,
  setMisQuinielasActiveItems,
  getEntryGift,
  getPopUpDeposit,
  getShowInvitePanel,
  updateStandingsComplete,

  mockDeadlinesFixtures,
  isBalanceEnough,
  supportEs6,
  detectDevice,
  removeReadyToVerify,
  setReadyToVerify,
  readyToVerify,
  isVerified,
  checkAxiosAllErrors,
  isTokenExpired,
  startRaven,
  setUserContextRaven,
  catchErrorRaven,
  postMessageRaven,
  removeDownloadAppView,
  clearStorage,
  setFilters,
  removeFilters,
  getFilters,
  setCreatedPrivate,
  removeCreatedPrivate,
  setShowPopUpHomeNote,
  getShowPopUpHomeNote,
  removeShowPopUpHomeNote,
  itNeedsImage,
  turnOffFirstTimeUser,
  isFirstTimeUser,
  setFirstTimeUser,
  getShowTutorial,
  removeShowTutorial,
  setShowTutorial,
  getUserInfo,
  getToken,
  isLogged,
  logout,
  getUserMetadataRegister,
  hasSeenLanding,
  saveDataNotLoggedUser,
  getDataNotLoggedUser,
  getTutorialSurvivorStatus,
  showTutorialSurvivorPopup,
  removeDataNotLoggedUser,
  getReferer,
  setReferer,
  removeHelpPanel,
  initFingerprint,
  getUserBalance,
  getShowGameFreeRoll,
  getShowTutorialLobby,
  getShowGameTutorial,
  getFromLanding,
  removeFromLanding,
  isNewUserForProduct,
  hideTutorialSurvivorPopUp,
  setPrevPool,
  getPrevPool,
  clearPrevPool,
  setPrevBracket,
  getPrevBracket,
  clearPrevBracket,
  isSuPick,
  getReferralsStatus,
  removeTutorialLobby,
  setShowHelpPanel,
  showTutorialLobby,
  updateEntry,
  getUserLoyalty,
  clearUserMetadata,
  setShowGameFreeRoll,
  removeShowGameFreeRoll,
  removeNewFlowActive,
  setTokenHash,
  getTokenHash,
  getFingerprint,
  getCookiesKeyVal,
};
