import React from 'react';
import { Button, FormControl } from 'react-bootstrap/lib';
import API from '../../utils/api';
import { avatarPickwinero } from '../../assets/images/levels/pickwinero-small.png';
import avatar from '../../assets/images/avatar-50.png';
import retar from '../../assets/images/vsIconHalf-50.png';
import iconRetar from '../../assets/images/icon-retar.png';
import * as Sentry from '@sentry/browser';
import _ from 'underscore';

const SearchableInput = ({
  placeholder,
  value,
  fetchFriendsList,
  changeSearchNick,
  resetValue,
  submitting,
}) => {
  const emptySearch = value === '' ? true : false;
  return (
    <div className="row" id="search-field-nick">
      <div id="searchableNickInput" className="col-xs-9 ">
        <FormControl
          onChange={(e) => {
            changeSearchNick(e);
            fetchFriendsList(e);
          }}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </div>
      <div className="col-xs-1  btn-search-field">
        <Button
          onClick={(e) => {
            resetValue();
          }}
          className="btn-default"
        >
          <i className={`${submitting ? 'ion-load-a loading' : 'ion-close-circled'}`}> </i>
        </Button>
      </div>
    </div>
  );
};

class InviteFriendsPlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      friends: null,
      friendsResults: null,
      submitting: false,
      searchableNick: '',
    };
    /**
     * Send the invitation to play with a user
     * @param friend { Object }
     *
     */
    this.sendInvitation = (friend) => {
      console.log(friend);
      // this.setState({ submitting: true });
      const [, quiniela, slug, poolId] = window.location.pathname.split('/');

      const href = `/${quiniela}/${slug}/${poolId}`;

      API.inviteUserToPool({ userId: friend.id, href })
        .then((response) => {
          if (response.hasOwnProperty('message')) {
            let temporalFriends = this.state.friends;

            let friendInvited = _.findIndex(temporalFriends.data, (f) => f.id === friend.id);
            let temporalResults = this.state.friendsResults;
            let friendResultInvited = null;

            if (temporalResults) {
              friendResultInvited = _.findIndex(temporalResults.data, (f) => f.id === friend.id);
            }

            console.log(' temporalFriends');
            console.log(temporalFriends);
            console.log(' friendInvited');
            console.log(friendInvited);

            if (friendInvited === -1 || friendResultInvited === -1) {
              this.setState({ error: true, userToInvite: friend });
            } else {
              temporalFriends.data[friendInvited]['is_invited'] = true;
              if (temporalResults) {
                temporalResults.data[friendResultInvited]['is_invited'] = true;
              }
              this.setState({
                friends: temporalFriends,
                friendResults: temporalResults,
                successfullInvite: true,
                userToInvite: friend,
              });
            }
          } else {
            throw 'Error invitation';
          }
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
        });
    };

    /**
     * This method render the Friends Results
     * @param itemWidth { Number }
     * @return  Component
     */
    this.rendererFriendsResults = (itemWidth) => {
      // console.log('rendererFriendsResults');
      if (!this.state.friendsResults || this.state.friendsResults.data.length < 1) {
        return null;
      }
      let listFriends = this.state.friendsResults.data.map((friend) => {
        let friendActions = null;
        let notificationActions = null;
        if (
          friend.hasOwnProperty('is_invited') &&
          friend.is_invited &&
          this.state.successfullInvite
        ) {
          friendActions = (
            <div className="flex-col friend-actions text-center ">
              <strong className="flex-row"> Retaste a {friend.nick} </strong>
              <small className="flex-row"> ¿Quién será el mejor? </small>
            </div>
          );
          notificationActions = (
            <div className="flex-col notification-actions col-15 col-center text-center ">
              <div className="flex-row row-no-padding row-center challenge-send-icon">
                <i className="ion-trophy flex-col col-center"></i>
              </div>
            </div>
          );
        } else if (friend.hasOwnProperty('is_invited') && !friend.is_invited && this.state.error) {
          friendActions = (
            <div className="flex-col friend-actions">
              <strong className="flex-row"> No pudimos retar a {friend.nick}</strong>
            </div>
          );
          notificationActions = (
            <div className="flex-col notification-actions col-15 col-center">
              <Button className="flex-row row-no-padding row-center btn-got-to-request">
                <i className="ion-close-round flex-col col-center" style={{ color: '#e00034' }}></i>
              </Button>
            </div>
          );
        } else {
          friendActions = (
            <div className="flex-col friend-actions">
              <strong className="flex-row">Reta a {friend.nick} </strong>
            </div>
          );

          notificationActions = (
            <div className="flex-col notification-actions  col-15 col-center">
              <Button className="flex-row row-no-padding row-center btn-challenge">
                <img src={iconRetar} className="pool-icon"></img>
              </Button>
            </div>
          );
        }

        return (
          <div
            onClick={() => {
              this.sendInvitation(friend);
            }}
            key={friend.id + friend.nick}
            className="flex-row friend-item result  row-center"
          >
            <img
              className="flex-col avatar-default  img-responsive"
              src={friend.avatar_url ? friend.avatar_url : avatar}
            />
            {friendActions}
            {notificationActions}
          </div>
        );
      });
      return (
        <div className="flex-row row-no-padding">
          <div className="flex-col">
            <div className="flex-row">
              <strong className="flex-col col-center text-center">Reta a tus amigos</strong>
            </div>
            {!listFriends || !listFriends.length ? (
              <div className="flex-container justify align column">No se encontró ningún amigo</div>
            ) : (
              listFriends
            )}
          </div>
        </div>
      );
    };

    /**
     * This Method renders the Friends List
     * @param itemWith { Number }
     * @return Component
     */
    this.rendererFriends = (itemWidth) => {
      // console.log('rendererFriends');
      let listFriends = this.state.friends.data.map((friend) => {
        let friendActions = null;
        let notificationActions = null;
        if (
          friend.hasOwnProperty('is_invited') &&
          friend.is_invited &&
          this.state.successfullInvite
        ) {
          friendActions = (
            <div className="flex-col friend-actions text-center ">
              <strong className="flex-row"> Retaste a {friend.nick} </strong>
              <small className="flex-row"> ¿Quién será el mejor? </small>
            </div>
          );
          notificationActions = (
            <div className="flex-col notification-actions col-15 col-center text-center ">
              <div className="flex-row row-no-padding row-center challenge-send-icon">
                <i className="ion-trophy flex-col col-center"></i>
              </div>
            </div>
          );
        } else if (friend.hasOwnProperty('is_invited') && !friend.is_invited && this.state.error) {
          friendActions = (
            <div className="flex-col friend-actions text-center ">
              <strong className="flex-row"> No pudimos retar a {friend.nick} a jugar </strong>
            </div>
          );
          notificationActions = (
            <div className="flex-col notification-actions col-15 col-center text-center ">
              <Button className="flex-row row-no-padding row-center btn-got-to-request">
                <i className="ion-close-round flex-col col-center" style={{ color: '#e00034' }}></i>
              </Button>
            </div>
          );
        } else {
          friendActions = (
            <div className="flex-col friend-actions text-center ">
              <strong className="flex-row row-no-padding">Reta a {friend.nick}</strong>
            </div>
          );

          notificationActions = (
            <div className="flex-col notification-actions text-center col-15 col-center">
              <Button className="flex-row row-no-padding row-center btn-challenge">
                <img src={retar} className="pool-icon"></img>
              </Button>
            </div>
          );
        }

        return (
          <div
            key={friend.id + friend.nick}
            onClick={() => {
              this.sendInvitation(friend);
            }}
            className="flex-row row-center  friend-item result"
          >
            <img
              className="flex-col avatar-default  img-responsive"
              src={friend.avatar_url ? friend.avatar_url : avatar}
            />
            {friendActions}
            {notificationActions}
          </div>
        );
      });
      return (
        <div className="flex-col ">
          {listFriends.length > 0 ? listFriends : 'No tienes ningúm amigo.'}
        </div>
      );
    };

    /**
     * This method fetch the results of a query byb user id
     *
     */
    this.fetchFriendsList = () => {
      if (this.state.searchableNick === '') {
        this.setState({});
      } else {
        this.setState({ submitting: true });
        console.log('Fetch Friends List');
        let customQuery = { name: this.state.searchableNick, offset: 0, limit: 5 };
        API.listAlreadyFriendUsers(customQuery)
          .then((options) => {
            console.log(options);
            this.setState({
              friendsResults: options,
              submitting: false,
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    };
    this.fetchFriendsList = _.debounce(this.fetchFriendsList, 500);

    this.resetValue = () => {
      this.setState({ searchableNick: '', friendResults: null });
    };
    /**
     * Handles The searchable Nick
     * @param e { Object }
     *
     */
    this.changeSearchNick = (e) => {
      console.log(e.target.value);
      this.setState({ searchableNick: e.target.value });
    };
  }

  componentDidMount() {
    let customQuery = { name: '', offset: 0, limit: 10 };
    API.listAlreadyFriendUsers(customQuery)
      .then((options) => {
        console.log(options);
        this.setState({
          friends: options,
          mounted: true,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  render() {
    let conditionalComponent = null;
    if (!this.state.mounted || this.state.submitting) {
      conditionalComponent = (
        <div className="flex-container justify align">
          <i className="ion-load-a loading"></i>
        </div>
      );
    } else {
      conditionalComponent = (
        <div className="flex-col ">
          <div className={` searchFriend flex-row ${this.state.friends.length > 0 ? '' : 'hide'}`}>
            <div className="flex-col col-center">
              <div className="flex-row ">
                <div className="flex-col ">
                  <span style={{ textTransform: 'uppercase' }}>Busca a tus amigos y rétalos</span>
                  <SearchableInput
                    placeholder={'Buscar a tus amigos para retarlos  a jugar '}
                    value={this.state.searchableNick}
                    fetchFriendsList={this.fetchFriendsList}
                    changeSearchNick={this.changeSearchNick}
                    resetValue={this.resetValue}
                    submitting={this.state.submitting}
                  />
                  {this.state.searchableNick !== '' && this.state.searchableNick ? (
                    <div
                      id="dropdown-search"
                      className="panel listFriends flex-container justify align wrap"
                    >
                      {this.rendererFriendsResults()}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex-row selectFriend ${this.state.friends.data.length > 0 ? '' : 'hide'}`}
          >
            <div className="flex-col col-center">
              <div className="flex-row ">
                <div className="flex-col ">
                  <span style={{ textTransform: 'uppercase' }}>Reta a alguno tus Amigos</span>
                </div>
              </div>
              <div className="flex-row listFriends ">{this.rendererFriends()}</div>
            </div>
          </div>
          {this.state.friends.data.length < 1 ? (
            <span className="text-center flex-container justify align column">
              {' '}
              <i className="ion-sad flex-col"></i> <span>No tienes ningún amigo.</span>
              <strong> Agrega a pickwineros para poder retarlos y jugar contra ti.</strong>
            </span>
          ) : null}
        </div>
      );
    }
    return conditionalComponent;
  }
}
export default InviteFriendsPlay;
