// Libraries
import React from 'react';
import _ from 'underscore';
import InfiniteScroll from 'react-infinite-scroller';

// Components
import Loading from '../Loading';

// Styles
import '../BracketInfoContainer/bracketInfoContainer.scss';
import '../Bracket/bracket.scss';

//Images
import avatar from '../../assets/images/avatar-50.png';

const UserDescription = ({standing, selectedEntryId}) => {
  const userPick = Number(selectedEntryId) === Number(standing.bracket_entry_id);
  let avatarUser = standing.user ? standing.user.avatar_dir+standing.user.avatar : standing.avatar_dir+standing.avatar;
  let nick = standing.user ? standing.user.nick : standing.nick;
  return (
    <div className={`flex-row no-padding row-bracket ${userPick ? 'user-entry' : ''}`}>
      <div className='table-header-item flex-container start pl-05'>
        <div className="avatar-bracket">
          <img src={(avatarUser === 0) ? avatar : `${avatarUser}`} />
        </div>
        <span className="picks-rank m-05">{` ${standing.rank}° `}</span>
        <span>
          {nick}
          {standing.number !== null ? (
            <sup style={{fontWeight: 'lighter', fontSize: '75%', margin: '0 2px'}} >
              {standing.number}
            </sup>
          ) : ('')}
        </span>
      </div>
    </div>
  );
}

const UserPicks = ({ standing, matches, userTieBreakers, tieBreakers }) => {
  const picks  = standing.bracket_picks;
  return (
    <div className={`flex-row no-padding row-bracket`}>
      {matches.map((match, i) => {
        if ( picks == null || !picks.hasOwnProperty(match.id) ) {
          return (
            <div className={`table-header-item game-item`} key={i}>
              <i className="ion-close" style={{ color: '#E00034' }}></i>
            </div>
          );
        }

        const pick = picks[match.id];
        // el match no esta vivo
        if ( match.sort_status == 1) {
          return (
            <div className={`table-header-item game-item`} key={i}>
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            </div>
          );
        }

        let selectedTeam = null;
        let selectedWinningTeam = null;
        if ( pick.pick == match.home_team_id ) {
          selectedTeam = match.home_team;
          selectedWinningTeam = match.winning_game;
        }
        if ( pick.pick == match.away_team_id ) {
          selectedTeam = match.away_team;
          selectedWinningTeam = match.winning_game;
        }

        // correct and incorrect pick class
        let pickResultClass = pick.pick == match.winning_pick ? "winner" : "looser";
        let pickNumberGameResultClass =  pick.pick == match.winning_pick && pick.winning_game == match.winning_game ? "winner" : "looser";


        return (
          <div key={i}>
            <div className={`table-header-item game-item bs-gp-results text-small text-bold`} >
              <table className="text-center pick-table-item">
                <tbody>
                  <tr>
                    <td className={pickResultClass}>
                      <i className={`quina ${selectedTeam?.logo}`}></i>
                    </td>
                    <td className={pickResultClass}>
                      { selectedTeam == null ? (
                          <i className="ion-close" style={{ color: '#E00034' }}></i>
                        ) :
                        selectedTeam?.abbreviation
                      }
                    </td>
                    <td className={pickNumberGameResultClass}>
                      {pick.winning_game === '' ? '-' : pick.winning_game}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      {tieBreakers.map((tieBreaker) => <MyTieBreakers key={tieBreaker.id} tieBreaker={tieBreaker} userTieBreakers={userTieBreakers} matches={matches} /> )}
    </div>
  );
}

const UserPoints = ({standing, closed, selectedEntryId}) => {
  const userPick = Number(selectedEntryId) === Number(standing.bracket_entry_id);
  return (
    <div className={`flex-row no-padding row-bracket border-bottom ${userPick ? 'user-entry' : ''}`}>
      <div className='flex-container align justify font-chivo text-medium'>
        {closed ? standing.points : standing.possible_points}
      </div>
    </div>
  );
}

const MyTieBreakers = ({ tieBreaker, userTieBreakers, matches }) => {
  if (userTieBreakers && userTieBreakers.length === undefined) {
    const userBreaker = userTieBreakers[tieBreaker.id];
    let matchFinded;
    if (userBreaker) {
      matchFinded = matches.find(match => (match.away_team_id === userBreaker.val || match.home_team_id === userBreaker.val));
    }
    let selectedTeam = null;
    if (matchFinded) {
      if ( userBreaker.val == matchFinded.home_team_id ) {
        selectedTeam = matchFinded.home_team;
      }
      if ( userBreaker.val == matchFinded.away_team_id ) {
        selectedTeam = matchFinded.away_team;
      }
    }

    return (
      <div className='table-header-item game-item font-chivo text-bold text-italic'>
        {tieBreaker.enabled &&
          <>
            {(userBreaker === undefined || userBreaker.val === null) &&
              <i className="ion-close" style={{ color: '#E00034' }}></i>
            ||
              <i className="ion-checkmark" style={{ color: '#78BC28' }}></i>
            }
          </>
        ||
          <>
            {selectedTeam && selectedTeam?.abbreviation || <i className="ion-close" style={{ color: '#E00034' }}></i>}
          </>
        }
      </div>
    );
  } else {
    return (
      <div className='table-header-item game-item font-chivo text-bold text-italic'>
        {tieBreaker.enabled &&
          <i className="ion-close" style={{ color: '#E00034' }}></i>
        ||
          <i className="ion-close" style={{ color: '#E00034' }}></i>
        }
      </div>
    );
  }
}

const PicksGrupoBracket = ({ standings, matches, groupPicks, closed, tieBreakers, loadMoreStandings, selectedEntryId }) => {
  const loadMore = (eventKey) => {
    loadMoreStandings(eventKey);
  }

  React.useEffect(() => {
    const headerScroll = window.document.getElementById("content-header-bracket");
    const lowerScroll = window.document.getElementById("content-lower-bracket");
    headerScroll.addEventListener("scroll", (e) => {
      lowerScroll.scrollLeft = e.target.scrollLeft;
    });
    lowerScroll.addEventListener("scroll", (e) => {
      headerScroll.scrollLeft = e.target.scrollLeft;
    });
  }, []);

  return (
    <div className="lower-section">
      <div className="container-picks">
        <div className="tabla-grupos">
          <div className="lower-table border-gradient">
            <div className='flex-row no-padding'>
              <div className='col-20 table-lower-item font-chivo pl-1 text-14 border-bottom border-right'>
                PIKWINERO
              </div>
              <div className='col-70 border-bottom'></div>
              <div className='col-10 flex-container align justify font-chivo text-14 border-bottom'>PTS</div>
            </div>

            <div className="overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal">
              <InfiniteScroll
                pageStart={1}
                loadMore={loadMore}
                hasMore={groupPicks.page >= groupPicks.pages ? false : true}
                loader={<Loading key={'labelLoader00'} label="..." />}
                useWindow={false}
              >
                <div className='flex-row no-padding no-margin-top'>
                  <div className='col-20'>
                    {standings.items.map((standing, i) => <UserDescription standing={standing} selectedEntryId={selectedEntryId} key={i} />)}
                  </div>
                  <div className='col-70 table-header-overflow' id="content-lower-bracket">
                    {groupPicks.items.map((standing, i) => <UserPicks standing={standing} matches={matches} userTieBreakers={standing.bracket_user_tiebreakers} tieBreakers={tieBreakers} key={i} />)}
                  </div>
                  <div className='col-10 border-left'>
                    {standings.items.map((standing, i) => <UserPoints closed={closed} standing={standing} selectedEntryId={selectedEntryId} key={i} /> )}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PicksGrupoBracket;