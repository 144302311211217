import React from 'react';
import {
  Button,
  OverlayTrigger,
  DropdownButton,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap/lib/';
import groupPoolActions from '../QuinielaGroupsUI/actions';
import firstBy from 'thenby';
import pickwinLogo from '../../assets/images/pickwin-winner-50.png';
import garantizada from '../../assets/images/garantizadaIcon-50.png';
import natural from '../../assets/images/naturalIcon-50.png';
import line from '../../assets/images/lineaIcon-50.png';
import bracket from '../../assets/images/bracketIcon.png';
import pickcoins from '../../assets/images/pickcoin1-50.png';
import pickcoinsPlata from '../../assets/images/pickcoin1-50-plata.png';
import registrate from '../../assets/images/flechasIconBlanco-50.png';
import { Redirect } from 'react-router-dom';
import { DisplayBolsaScore } from '../../utils/quinielasComponents';
import { LinkContainer } from 'react-router-bootstrap';
import branchUtils from '../../utils/branchUtils';
import utils from '../../utils/utils';
import billete from '../../assets/images/cash1-50.png';
import numeral from 'numeral';
import { DisplaySport, DisplayFlag } from '../../utils/quinielasComponents';
import {
  Reglas,
  popoverQueEsStrike,
  popoverQueEsStrikeLinea,
} from '../Score';

// const {
//   FacebookShareButton,
//   GooglePlusShareButton,
//   TwitterShareButton,
//   WhatsappShareButton,
//   EmailShareButton,
// } = ShareButtons;

const LowerRowButtons = ({
  buckets,
  bucketKeys,
  players,
  ties,
  changeSelectedEntry,
  closeDropdown,
  dispatch,
  entries,
  gameInfo,
  getBranchLink,
  handleClickDropdown,
  handleClickFirstRegister,
  isSpecial,
  logged,
  openDrop,
  selectedEntry,
  showInvite,
  type,
  updateBranchLink,
  updateStateFuncCancel,
  withRegister,
  currentContest,
  entryCurrency,
  contests,
}) => {
  let conditionalComponent = null;
  if (!logged) {
    conditionalComponent = (
      <div className="lower-row mobile row">
        <span
          style={{
            color: '#78bc28',
            fontSize: '1em',
            fontWeight: 'bold',
            margin: '0px auto',
            padding: '0 20px',
            textAlign: 'center',
          }}
        >
          Para poder participar inicia sesión ó crea una cuenta.
        </span>
      </div>
    );
  } else if (isSpecial) {
    /**
     * Special Private
     **/
    conditionalComponent = (
      <div className="lower-row row mobile logged">
        <Reglas gameType={gameInfo.game_type} gameInfo={gameInfo} />
      </div>
    );
  } else {
    /**
     * User Logged
     **/
    let listEntries = [];
    let gameType = null;
    if (gameInfo.game_type === 'survivor') {
      gameType = 'survivor';
    } else if (gameInfo.game_type === 'bracket') {
      gameType = 'bracket';
    } else if (gameInfo.game_type === 'group_pool') {
      gameType = 'quinielaGrupo';
    } else {
      gameType = 'quiniela';
    }
    if (entries !== undefined) {
      entries.sort(firstBy('number'));

      listEntries = entries.map((entry, i) => {
        if (!entry) {
          return;
        }
        return (
          <LinkContainer
            key={i}
            data-entry={entry.id}
            // style={
            //   entry.pick_count !== 0
            //     ? { color: '#78b529', fontWeigth: 'bold' }
            //     : { color: '#E00034', fontWeigth: 'bold' }
            // }
            to={`/${gameType}/${gameInfo.slug}/${gameInfo.id}/${entry.id}`}
            onClick={(e) => {
              closeDropdown(e);
              console.log(entry);
              if (dispatch) {
                changeSelectedEntry(dispatch, entry.id).then((response) => {
                  groupPoolActions.getSimilarPools(dispatch, entry.id).then((responseSimilar) => {
                    console.log(responseSimilar);
                    console.log('response change ', response, ties, bucketKeys);
                    groupPoolActions.initializeEntry(
                      dispatch,
                      gameInfo.id,
                      buckets,
                      bucketKeys,
                      ties,
                      response.data.group_pool_entry,
                      true,
                      players,
                      currentContest,
                      entryCurrency,
                      contests,
                    );
                  });
                });
              } else {
                changeSelectedEntry(entry.id);
              }
            }}
          >
            <ToggleButton
              id={entry.id}
              value={entry.id}
              className={`multiple-entry-button ${entry.id === selectedEntry ? 'current' : ''}`}
              onClick={closeDropdown}
            >
              Entrada #{entry.number === null ? '1' : entry.number}
            </ToggleButton>
          </LinkContainer>
        );
      });
    }

    switch (type) {
      case 'popup':
        conditionalComponent = (
          <div className="lower-row row mobile logged">
            <Reglas gameType={gameType} gameInfo={gameInfo} />
          </div>
        );
        break;
      case 'lobby':
        if (!withRegister && gameInfo.status === 'upcoming') {
          conditionalComponent = (
            <div className="lower-row row mobile logged">
              <div className="firstRegistro">
                <Button
                  onClick={() => {
                    if (dispatch) {
                      handleClickFirstRegister(dispatch);
                    } else {
                      handleClickFirstRegister();
                    }
                    return <img src={registrate} className="bolsa-img" />;
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>Regístrate</span>
                </Button>
              </div>
              <Reglas gameInfo={gameInfo} gameType={gameType} />
            </div>
          );
        } else if (withRegister) {
          conditionalComponent = (
            <div className="lower-row logged mobile">
              <div className="row">
                <div className="misRegistros lower-button-container flex-col ">
                  <DropdownButton
                    className="btn-registros-multiples"
                    rootCloseEvent={'click'}
                    title={<span className="btn-text">Registros</span>}
                    id="dropdown-registros-multiples"
                    open={openDrop}
                    onToggle={handleClickDropdown}
                  >
                    <ToggleButtonGroup name="estado" type="radio" onChange={null}>
                      {listEntries.length === 0 ? 'Solo tienes un registro' : listEntries}
                    </ToggleButtonGroup>
                  </DropdownButton>
                </div>
                <div className="flex-col">
                  <Reglas gameInfo={gameInfo} gameType={gameType} />
                </div>
              </div>
              <div className="flex-row buttons ">
                {withRegister && gameInfo.status === 'upcoming' ? (
                  <div className="flex-col cancelarRegistro">
                    <Button
                      onClick={() => {
                        if (dispatch) {
                          updateStateFuncCancel(dispatch);
                        } else {
                          updateStateFuncCancel();
                        }
                      }}
                      id="cancel"
                      className="cancel-score-mobile"
                    >
                      <i className="ion-close-circled"></i>
                    </Button>
                  </div>
                ) : null}
                {withRegister && gameInfo.status === 'upcoming' ? (
                  gameInfo.is_full ? (
                    <div className="volverRegistrar flex-col">
                      {' '}
                      Lo sentimos pero la quiniela ya está llena
                    </div>
                  ) : (
                      <div className="volverRegistrar flex-col ">
                        <Button
                          id="register"
                          onClick={() => {
                            if (dispatch) {
                              handleClickFirstRegister(dispatch);
                            } else {
                              handleClickFirstRegister();
                            }
                          }}
                        >
                          <i className="ion-plus-circled"></i>
                        </Button>
                      </div>
                    )
                ) : null}
                {withRegister && gameInfo.status === 'upcoming' ? (
                  <div className="inviteFriends flex-col col-center">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        getBranchLink().then((url) => {
                          if (dispatch) {
                            showInvite(dispatch);
                            updateBranchLink(dispatch, url, null, gameInfo.id);
                          } else {
                            showInvite();
                            updateBranchLink(url, null, gameInfo.id);
                            // console.log('Open invite');
                          }
                          // branchUtils.redirectToWhatsApp( url, 'location')
                          // let text = window.encodeURIComponent( `!Hola¡ Te invito a jugar en Pickwin ${url}` );
                          // window.location = `whatsapp:/send?text=${text}`;
                        });
                      }}
                      className="invite-to-play"
                    >
                      <i className="ion-social-whatsapp"></i>
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          );
        } else {
          conditionalComponent = (
            <div className="lower-row row mobile logged">
              <div className="flex-col">
                <Reglas gameType={gameType} gameInfo={gameInfo} />
              </div>
            </div>
          );
        }
        break;

      default:
        break;
    }
  }
  return conditionalComponent;
};

// function renderEntriesButtons(entries) {
//   let buttons = entries.map((entry) => {
//     return <div className="multiple-entry-button">Entrada #{entry.id}</div>;
//   });
// }

// const popoverMisQuinielas = (props) =>
//   props.entries !== undefined ? (
//     <Popover
//       id="misquinielas-popover"
//       style={props.entries.length !== 0 ? { borderColor: '#87b13d' } : { borderColor: '#d3242b' }}
//     >
//       {props.entries.length !== 0 ? (
//         // {
//         //     renderEntriesButtons(props);
//         // }
//         <span className="cant-registros">
//           {'Tienes '}
//           <strong style={{ color: '#87b13d' }}>{props.entries.length}</strong>
//           {props.entries.length === 1 ? ' registro' : ' registros'} para esta quiniela
//         </span>
//       ) : (
//         <span className="cant-registros">
//           {'Tienes '}
//           <strong style={{ color: '#d3242b' }}>0</strong>
//           registros para esta quiniela
//         </span>
//       )}
//     </Popover>
//   ) : (
//     <Popover id="misquinielas-popover" style={{ borderColor: '#87b13d' }}>
//       <span className="cant-registros">
//         {'Tienes '}
//         <strong style={{ color: '#d3242b' }}>0</strong>registros para esta quiniela
//       </span>
//     </Popover>
//   );

// let cancelarPopover = (
//   <Popover id="cancelar-popover" title="¿Estás seguro que quieres cancelar tu registro?">
//     <div className="popover-button-container">
//       <Button bsStyle="success">
//         <span>Si</span>
//       </Button>
//       <Button bsStyle="danger">
//         <span>No</span>
//       </Button>
//     </div>
//   </Popover>
// );

function ScorePopup(props) {
  // console.log('SPopup', props.props );
  if (!props.props) {
    return null;
  }

  let currencyIcon = null;
  let currencyFormat = null;
  let currencyAbbrev = null;

  switch (props.props.entry_currency) {
    case 'real':
      currencyIcon = <img src={billete} className="score-icons" />;
      currencyFormat = '$0,000.00';
      currencyAbbrev = 'MXN';
      break;
    case 'pickcoin':
      currencyIcon = <img src={pickcoins} className="score-icons" />;
      currencyFormat = '0,000.00';
      currencyAbbrev = 'PC';
      break;
    case 'referral':
      currencyIcon = <img src={pickcoinsPlata} className="score-icons" />;
      currencyFormat = '0';
      currencyAbbrev = 'TK';
      break;
  }

  let promoImage = null;
  switch (props.props.id) {
    case 12232:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/rotoplas-small.png"
          className="promo-image"
        />
      );
      break;
    case 12609:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/gen-small.png"
          className="promo-image"
        />
      );
      break;
    case 12509:
      promoImage = <div className="promo-image"></div>;
      break;
    case 12752:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/curadeuda-small.png"
          className="promo-image"
        />
      );
      break;
    case 12805:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/google-logo.png"
          className="promo-image"
        />
      );
      break;
    case 12926:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-cinemex.png"
          className="promo-image"
        />
      );
      break;
    case 12222:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-nxt-company.png"
          className="promo-image"
        />
      );
      break;
    default:
      promoImage = null;
      break;
  }
  if (!promoImage && props.itNeedsLogo) {
    promoImage = (
      <img
        src="https://storage.googleapis.com/pw-resources/privadas/quiniela-invex-2018.png"
        className="promo-image"
      />
    );
  }

  return (
    <div id="score-lobby" className="row lobby mobile">
      <div className="row free">
        <div className="pickwin-logo-score col-xs-offset-3 col-xs-4">
          <div className="">
            <img src={pickwinLogo} className="img-responsive" />
          </div>
        </div>
        <div className="col-xs-offset-3 col-xs-1">
          <button
            className="cerrar-panel pop"
            onClick={(e) => {
              props.close(e);
              if (props.popUpNewUserStatus && props.popUpNewUsers.firstTimeType === 'product') {
                console.log('Show Regalo Popup');
                props.openPopUpRegalo();
              }
            }}
          >
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty"></i>
            </div>
          </button>
        </div>
      </div>
      <div className="score-container">
        <div className="left-score-panel">
          {promoImage === null ? (
            <div className="row title-score">
              {props.props.game_type === 'group_pool' ? (
                <DisplaySport sports={props.props.contests} type={'group_pool'} />
              ) : (
                  <DisplaySport sports={props.props.sport} type={'pool'} />
                )}
              <DisplayFlag type="quiniela" flag={props.props} />
              <div className="info-score">
                <div className="score-tipo-container">
                  <div className="score-tipo">
                    <img
                      className="score-icons"
                      src={props.props.point_format === 'line' ? line : null}
                    />
                    <img
                      className="score-icons"
                      src={props.props.point_format === 'natural' ? natural : null}
                    />
                    <img
                      className="score-icons"
                      src={props.props.guaranteed_prize !== null ? garantizada : null}
                    />
                    <img
                      className="score-icons"
                      src={props.props.brackets_entry_count !== undefined ? bracket : null}
                    />
                  </div>
                </div>
              </div>
              <span>
                {props.props.name_replaced !== undefined
                  ? props.props.name_replaced
                  : props.props.name}
              </span>
            </div>
          ) : null}
        </div>
        <div className="center-score-panel ">
          <div className="center-container row">
            {promoImage !== null ? (
              <div className="col-xs-12">{promoImage}</div>
            ) : (
                <div className="center-container-left col-xs-12">
                  <div className="score-entry col-xs-12">
                    <div className="entry-title">
                      <span>Precio:</span>
                    </div>
                    <div className="entry-data">
                      {currencyIcon}
                      <span> {numeral(props.props.entry_fee / 100).format(currencyFormat)} </span>
                      <small> {currencyAbbrev}</small>
                    </div>
                  </div>
                  {props.props.entry_currency === 'real' ? (
                    <div style={{ fontWeight: '700', fontSize: 'x-small', textAlign: 'center' }}>
                      ( {numeral(props.props.real_entry_fee / 100).format('$0,000.00')} para bolsa,{' '}
                      {numeral(props.props.commission_fee / 100).format('$0,000.00')} comisión )
                    </div>
                  ) : null}
                  {props.props.max_multientries > 0 ? (
                    <div style={{ textAlign: 'center' }}>
                      {' '}
                      <small style={{ textAlign: 'center', color: '#fec830', fontWeight: 'bold' }}>
                        {' '}
                      Limitado a {props.props.max_multientries} registros por usuario
                    </small>{' '}
                    </div>
                  ) : null}
                  {props.props.strikes_enabled ? (
                    <div className="flex-container justify align maxStrikes">
                      <div className=""> {props.props.max_strikes} Strikes </div>
                      <div className="">
                        {' '}
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="bottom"
                          overlay={
                            props.props.point_format === 'natural'
                              ? popoverQueEsStrike
                              : popoverQueEsStrikeLinea
                          }
                        >
                          <i
                            className="ion-help-circled"
                            style={{ color: '#fff', marginLeft: '3px' }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            <div className="col-xs-12 center-container-left">
              <div className={'score-entry col-xs-7'}>
                <div className="bolsa-title">
                  <span>Bolsa:</span>
                </div>
                <DisplayBolsaScore poolInfo={props.props} />
              </div>
              <div className="col-xs-5 score-entry">
                <div className="bolsa-title">
                  <span>Registrados: </span>
                </div>
                <span className="bolsa-total">
                  <span>{props.props.entry_count}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {utils.isLogged() && utils.isSuPick() ? null : (
        <LowerRowButtons
          ties={props.ties}
          buckets={props.buckets}
          bucketsKeys={props.bucketsKeys}
          players={props.players}
          dispatch={props.dispatch}
          gameInfo={props.props}
          isSpecial={promoImage !== null}
          logged={props.logged}
          type="popup"
        />
      )}
    </div>
  );
}

function ScoreLobby(props) {
  // console.log('SL', props);
  let currencyIcon = null;
  let currencyFormat = null;
  let currencyAbbrev = null;

  switch (props.props.entry_currency) {
    case 'real':
      currencyIcon = <img src={billete} className="score-icons" />;
      currencyFormat = '$0,000.00';
      currencyAbbrev = 'MXN';
      break;
    case 'pickcoin':
      currencyIcon = <img src={pickcoins} className="score-icons" />;
      currencyFormat = '0,000.00';
      currencyAbbrev = 'PC';
      break;
    case 'referral':
      currencyIcon = <img src={pickcoinsPlata} className="score-icons" />;
      currencyFormat = '0';
      currencyAbbrev = 'TK';
      break;
  }

  let promoImage = null;
  switch (props.props.id) {
    case 12232:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/rotoplas-small.png"
          className="promo-image"
        />
      );
      break;
    case 12609:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/gen-small.png"
          className="promo-mage"
        />
      );
      break;
    case 12509:
      console.log('THIS');
      promoImage = <div className="promo-image"></div>;
      break;
    case 12752:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/curadeuda-small.png"
          className="promo-image"
        />
      );
      break;
    case 12805:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/google-logo.png"
          className="promo-image"
        />
      );
      break;
    case 12926:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-cinemex.png"
          className="promo-image"
        />
      );
      break;
    case 12222:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-nxt-company.png"
          className="promo-image"
        />
      );
      break;
    case 13102:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-maxim.jpg"
          className="promo-image"
        />
      );
      break;
    default:
      promoImage = null;
      break;
  }

  // console.log(promoImage);
  // console.log(props.itNeedsLogo);
  if (promoImage === null && props.itNeedsLogo) {
    promoImage = (
      <img
        src="https://storage.googleapis.com/pw-resources/privadas/quiniela-invex-2018.png"
        className="promo-image"
      />
    );
  }

  // console.log('SL Mombile Entries',promoImage);
  return (
    <div id="score-lobby" className="row lobby mobile">
      <div className="score-container">
        {promoImage === null ? (
          <div className="left-score-panel">
            <div className="row title-score">
              {props.props.game_type === 'group_pool' ? (
                <DisplaySport sports={props.props.contests} type={'group_pool'} />
              ) : (
                  <DisplaySport sports={props.props.sport} type={'pool'} />
                )}
              <DisplayFlag type="quiniela" flag={props.props} />
              <div className="info-score">
                <div className="score-tipo-container">
                  <div className="score-tipo">
                    <img
                      className="score-icons"
                      src={props.props.point_format === 'line' ? line : null}
                    />
                    <img
                      className="score-icons"
                      src={props.props.point_format === 'natural' ? natural : null}
                    />
                    <img
                      className="score-icons"
                      src={props.props.guaranteed_prize !== null ? garantizada : null}
                    />
                    <img
                      className="score-icons"
                      src={props.props.brackets_entry_count !== undefined ? bracket : null}
                    />
                  </div>
                </div>
              </div>
              <span>
                {props.props.name_replaced !== undefined
                  ? props.props.name_replaced
                  : props.props.name}
              </span>
            </div>
          </div>
        ) : null}
        <div className="center-score-panel ">
          <div className="center-container row">
            {promoImage !== null ? (
              <div className="col-xs-12">{promoImage}</div>
            ) : (
                <div className="center-container-left col-xs-12">
                  <div className="score-entry col-xs-12">
                    <div className="entry-title">
                      <span>Precio:</span>
                    </div>
                    <div className="entry-data">
                      {currencyIcon}
                      <span> {numeral(props.props.entry_fee / 100).format(currencyFormat)} </span>
                      <small> {currencyAbbrev}</small>
                    </div>
                  </div>
                  {props.props.entry_currency === 'real' ? (
                    <div style={{ fontWeight: '700', fontSize: 'x-small', textAlign: 'center' }}>
                      ( {numeral(props.props.real_entry_fee / 100).format('$0,000.00')} para bolsa,{' '}
                      {numeral(props.props.commission_fee / 100).format('$0,000.00')} comisión )
                    </div>
                  ) : null}
                  {props.props.max_multientries > 0 ? (
                    <div style={{ textAlign: 'center' }}>
                      {' '}
                      <small style={{ textAlign: 'center', color: '#fec830', fontWeight: 'bold' }}>
                        {' '}
                      Limitado a {props.props.max_multientries} registros por usuario
                    </small>{' '}
                    </div>
                  ) : null}
                  {props.props.strikes_enabled ? (
                    <div className="flex-container justify align maxStrikes">
                      <div className=""> {props.props.max_strikes} Strikes </div>
                      <div className="">
                        {' '}
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="bottom"
                          overlay={
                            props.props.point_format === 'natural'
                              ? popoverQueEsStrike
                              : popoverQueEsStrikeLinea
                          }
                        >
                          <i
                            className="ion-help-circled"
                            style={{ color: '#fff', marginLeft: '3px' }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            <div className="center-container-left col-xs-12">
              <div className={'score-entry col-xs-7'}>
                <div className="bolsa-title">
                  <span>Bolsa:</span>
                </div>
                <DisplayBolsaScore poolInfo={props.props} />
              </div>
              <div className="col-xs-5 score-entry">
                <div className="bolsa-title">
                  <span>Registrados:</span>
                </div>
                <span className="bolsa-total">
                  <span>{props.props.entry_count}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {utils.isLogged() && utils.isSuPick() ? null : (
        <LowerRowButtons
          ties={props.ties}
          buckets={props.buckets}
          bucketsKeys={props.bucketsKeys}
          players={props.players}
          dispatch={props.dispatch}
          changeSelectedEntry={props.changeSelectedEntry}
          closeDropdown={props.closeDropdown}
          gameInfo={props.props}
          entries={props.entries}
          getBranchLink={props.getBranchLink}
          handleClickDropdown={props.handleClickDropdown}
          handleClickFirstRegister={props.handleClickFirstRegister}
          handleClickReRegister={props.handleClickReRegister}
          isSpecial={promoImage !== null}
          promoImage={promoImage}
          logged={props.logged}
          openDrop={props.openDrop}
          selectedEntry={props.selectedEntry}
          showInvite={props.showInvite}
          type="lobby"
          updateStateFuncCancel={props.updateStateFuncCancel}
          updateBranchLink={props.updateBranchLink}
          withRegister={props.withRegister}
          currentContest={props.currentContest}
          entryCurrency={props.entryCurrency}
          contests={props.contests}
        />
      )}
    </div>
  );
}

class ScoreMobile extends React.Component {
  constructor(props) {
    super(props);
    // console.log('ScoreProps', this);
    // console.log('ScoreProps', this.props.entries !== null ? this.props.entries.filter(item => item.pool_id === this.props.quinaID) : null)))
    this.state = {
      entries_: null,
      item: this.props.poolInfo,
      misQuinielas: null,
      mounted: false,
      popup: this.props.popup,
      redirectLogin: false,
      redirectRegister: false,
      selectedEntry: this.props.selectedEntry,
      branchUrl: 'miLink',
      itNeedsLogo: false,
    };
    this.redirectButton = this.redirectButton.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleClickDropdown = this.handleClickDropdown.bind(this);
    // console.log('score', this.state )

    this.getBranchLink = () => {
      return branchUtils.createLink(
        utils.getUserInfo(),
        this.props.poolInfo.pool !== undefined ? this.props.poolInfo.pool : this.props.poolInfo,
        this.props.poolInfo.pool !== undefined
          ? this.props.poolInfo.pool.game_type
          : this.props.poolInfo.game_type
      );
    };
  }
  closeDropdown(e) {
    console.log(e.target);
    //let drop = $('.misRegistros .dropdown');
    //let dropButton = $('#dropdown-registros-multiples');
    // drop !== undefined ? drop.removeClass('open'): null;
    // dropButton !== undefined ? dropButton.attr('aria-expanded', 'false'): null;
    setTimeout(() => {
      this.setState({ openDrop: false });
    }, 500);
  }

  redirectButton(e) {
    //Si dio click a login llevar a login,
    // si dio clic en register redireccionar a register
    let buttonVal = e.target.attributes[0].value;
    if (buttonVal === 'login') {
      console.log(buttonVal);
      this.setState({
        redirectLogin: true,
      });
    } else {
      console.log(buttonVal);
      this.setState({
        redirectRegister: true,
      });
    }
  }

  handleClickDropdown() {
    this.setState((prevState) => {
      return {
        openDrop: !prevState.openDrop,
      };
    });
  }

  componentDidMount() {
    let gameInfo = this.props.poolInfo.hasOwnProperty('pool')
      ? this.props.poolInfo.pool
      : this.props.poolInfo.hasOwnProperty('bracket')
        ? this.props.poolInfo.bracket
        : this.props.poolInfo.survior;
    if (!gameInfo) {
      gameInfo = this.props.poolInfo;
    }
    if (this.props.withRegister) {
      // console.log('Map entries into state', this.props.entries)
      // let newEntries = [];
      // if(this.props.entries.entry !== undefined){
      //   newEntries.push(this.props.entries.entry);
      // }
      // else if(this.props.entries.bracket_entry !== undefined) {
      //   newEntries.push(this.props.entries.bracket_entry);
      // }
      //   if(this.props.entries.other_entries && this.props.entries.other_entries.length > 0){
      //     this.props.entries.other_entries.forEach(entry => {
      //       newEntries.push(entry);
      //     })
      //   }
      //   this.setState({
      //     itNeedsLogo : utils.itNeedsImage('Invex' , gameInfo.name_replaced),
      //     entries     : newEntries,
      //   })
      // }
      // else {
      //   this.setState({
      //     itNeedsLogo : utils.itNeedsImage('Invex' , gameInfo.name_replaced),
      //   })
      // }
      // if (this.props.withRegister) {
      // console.log('Map entries into state', this.props.entries)
      let newEntries = [];
      if (this.props.entries.entry !== undefined) {
        newEntries = [this.props.entries.entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.bracket_entry !== undefined) {
        newEntries = [this.props.entries.bracket_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.survivorEntry !== undefined) {
        console.log('Survivor', this.props.entries.survivorEntry);
        newEntries = [this.props.entries.survivor_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.group_pool_entry !== undefined) {
        newEntries = [this.props.entries.group_pool_entry, ...this.props.entries.other_entries];
      }
      this.setState({
        itNeedsLogo: utils.itNeedsImage('Invex', gameInfo.name_replaced),
        entries: newEntries,
      });
    } else {
      this.setState({
        itNeedsLogo: utils.itNeedsImage('Invex', gameInfo.name_replaced),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * SurivorRemapping Entries
     */
    if (this.props.entries && this.props.entries.survivor_entry && this.props.withRegister) {
      // console.log('changed other entries')
      let newEntriesSurvivor = [];
      if (this.props.entries.survivor_entry !== undefined) {
        newEntriesSurvivor = [
          this.props.entries.survivor_entry,
          ...this.props.entries.other_entries,
        ];
      }
      // console.log(newEntriesSurvivor , this.state.entries);

      if (!this.state.entries) {
        this.setState({
          entries: newEntriesSurvivor,
          popup: this.props.popup,
          item: this.props.poolInfo,
          selectedEntry: this.props.selectedEntry,
        });
      } else if (
        newEntriesSurvivor.length !== this.state.entries.length ||
        this.props.selectedEntry !== prevProps.selectedEntry
      ) {
        this.setState({
          entries: newEntriesSurvivor,
          popup: this.props.popup,
          item: this.props.poolInfo,
          selectedEntry: this.props.selectedEntry,
        });
      }
    }
    if (this.props.selectedEntry !== prevProps.selectedEntry) {
      // console.log('Changed Entry ');

      let newEntries = [];
      if (this.props.entries.entry !== undefined) {
        newEntries = [this.props.entries.entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.bracket_entry) {
        newEntries = [this.props.entries.bracket_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.survivorEntry) {
        newEntries = [this.props.survivor_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.group_pool_entry) {
        newEntries = [this.props.group_pool_entry, ...this.props.entries.other_entries];
      }

      this.setState({
        entries: newEntries,
        popup: this.props.popup,
        item: this.props.poolInfo,
        selectedEntry: this.props.selectedEntry,
      });
    }
  }
  render() {
    let conditionalComponent;
    if (this.state.redirectLogin) {
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/ingresar`,
          }}
        />
      );
    } else if (this.state.redirectRegister) {
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/registrate`,
          }}
        />
      );
    } else {
      if (this.state.popup) {
        conditionalComponent = (
          <ScorePopup
            buckets={this.props.buckets}
            ties={this.props.ties}
            dispatch={this.props.dispatch}
            logged={this.props.logged}
            props={this.props.poolInfo}
            status={this.props.status}
            popUpNewUserStatus={this.props.popUpNewUsersStatus}
            popUpNewUsers={this.props.popUpNewUsers}
            openPopUpRegalo={this.props.openPopUpRegalo}
            // entries={this.state.entries}
            close={this.props.close}
            type={this.props.type}
            itNeedsLogo={this.state.itNeedsLogo}
            redirectFunc={this.redirectButton}
            handleCancelPanel={this.props.handleCancelPanel}
            updateStateFuncCancel={this.props.updateStateFuncCancel}
          />
        );
      } else {
        let gameInfo = this.props.poolInfo;
        if (gameInfo.pool !== undefined) {
          gameInfo = gameInfo.pool;
        } else if (gameInfo.survivor !== undefined) {
          gameInfo = gameInfo.survivor;
        }
        conditionalComponent = (
          <ScoreLobby
            buckets={this.props.buckets}
            bucketsKeys={this.props.bucketKeys}
            players={this.props.players}
            ties={this.props.ties}
            dispatch={this.props.dispatch}
            branchUrl={this.state.branchUrl}
            changeSelectedEntry={this.props.changeSelectedEntry}
            closeDropdown={this.closeDropdown}
            entries={this.state.entries}
            handleCancelPanel={this.props.handleCancelPanel}
            handleClickDropdown={this.handleClickDropdown}
            handleClickFirstRegister={this.props.handleClickFirstRegister}
            handleClickReRegister={this.props.handleClickReRegister}
            getBranchLink={this.getBranchLink}
            logged={this.props.logged}
            itNeedsLogo={this.state.itNeedsLogo}
            props={gameInfo}
            redirectFunc={this.redirectButton}
            selectedEntry={this.props.selectedEntry}
            showPanelCancel={this.props.showPanelCancel}
            showInvite={this.props.showInvite}
            hideInvite={this.props.hideInvite}
            status={this.props.status}
            type={this.props.type}
            updateClosedQuiniela={this.props.updateClosedQuiniela}
            updateBranchLink={this.props.updateBranchLink}
            updateStateFuncCancel={this.props.updateStateFuncCancel}
            withRegister={this.props.withRegister}
            currentContest={this.props.currentContest}
            entryCurrency={this.props.entryCurrency}
            contests={this.props.contests}
          />
        );
      }
    }

    return conditionalComponent;
  }
}

export default ScoreMobile;
