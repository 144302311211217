import React from 'react'
import styled from 'styled-components'

// Components
import Typography from '../../Typography'
import Stack from '../../Stack'
import Box from '../../Box'

InputRange.defaultProps = {
  title: '',
  subtitle: '',
  value: '',
  ketName: '',
  options: []
}

export default function InputRange (props) {
  const id = `${props.name}-id`

  const handleChange = (event) => {
    const value = Number(event.target.value)
    if (props.onChange) {
      props.onChange({ [props.name]: value })
    }
  }

  return (
    <Box maxWidth={500} mx='auto'>
      <Label htmlFor={id}>{props.label}</Label>

      <Typography center color='#8d8d8d' size='0.85em'>{props.subtitle}</Typography>

      {props.error && (
        <Typography center color='#ff542a' size='0.9em' my={5}>
          <Typography mr={5} as='span'>⬤</Typography>
          {props.error}
        </Typography>
      )}

      <InputBase
        id={id}
        type='range'
        name={props.name}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={handleChange}
        min={props.min}
        max={props.max}
      />
    </Box>
  )
}

const InputBase = styled.input`
  /********** Range Input Styles **********/
  /*Range Reset*/
  &[type="range"] {
    -webkit-appearance: none;
      appearance: none;
      background: transparent;
      cursor: pointer;
  }

  /* Removes default focus */
  &[type="range"]:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  &[type="range"]::-webkit-slider-runnable-track {
    background-color: #c0c0c0;
    border-radius: 0.5rem;
    height: 0.5rem;  
  }

  /* slider thumb */
  &[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.5em; /* Centers thumb on the track */

    /*custom styles*/
    background-color: #8cc63f;
    height: 1.5em;
    width: 1.5em;
    border-radius: 1.5em;
  }

  &[type="range"]:focus::-webkit-slider-thumb {   
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem; 
  }

  /******** Firefox styles ********/
  /* slider track */
  &[type="range"]::-moz-range-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  &[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: #8cc63f;
    height: 2rem;
    width: 1rem;
  }

  &[type="range"]:focus::-moz-range-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem; 
  }

`

const Label = styled.label`
  line-height: 2.5;
  font-size: 1.1em;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
`
