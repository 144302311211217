// Libraries
import React from 'react';
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';
import moment from 'moment';
import _ from 'underscore';

// Components
import Clock from '../Clock/index';

// Images
import avatar from '../../assets/images/avatar-50.png';

// styles
import '../Bracket/bracket.scss';
import './bracketTennis.scss'

const BracketTennisScores = ({matches, bracketCurrentStage, bracketStages, bracketInfo}) => {
  const [selectedStage, setSelectedStage] = React.useState(bracketCurrentStage ? bracketCurrentStage.id : bracketStages[0].id);
  const [filteredMatches, setFilteredMatches] = React.useState([]);

  const handleStageChange = (stage) => {
    setSelectedStage(stage);
  }

  React.useEffect(() => {
    let newMatches = matches.filter(match => match.stage_id === selectedStage);
    setFilteredMatches(newMatches);
  }, [selectedStage]);

  return (
    <div className="flex-row multiple-quiniela-info grupo">
      <div className="flex-col upper-section">
        <div className="container-picks">
          <div className="upper-table" style={{paddingRight: '6px'}}>
            <div className="headers">
              <div className="flex-row font-chivo">
                <ButtonToolbar id="week-controls" className="flex-col">
                  <ToggleButtonGroup
                    className="flex-container align justify"
                    name="weeks-controls"
                    style={{width: '100%'}}
                    type="radio"
                    value={selectedStage}
                    onChange={handleStageChange}
                  >
                    {bracketStages.map((stage, i) => {
                      return (
                        <ToggleButton
                          key={stage.id}
                          className='bottom-separation font16'
                          value={stage.id}
                          style={{maxWidth: '15%', width: '15%', fontSize: '12px'}}
                        >
                          {stage.name}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </div>

              <div className="ml-05">
                {filteredMatches.map(match => {
                  var setsPlayerOne = [];
                  var setsPlayerTwo = [];

                  if (match.tennis_fixtures.length > 0) {
                    _.forEach(match.tennis_fixtures[0].sets_player_one, (set) => {
                      setsPlayerOne.push(set);
                    });
                    _.forEach(match.tennis_fixtures[0].sets_player_two, (set) => {
                      setsPlayerTwo.push(set);
                    });
                  }

                  return (
                    <div className="marcador-partido-item-bracket-tennis col-sm-12 col-md-6 col-lg-3 plr-05">
                      <div className="marcador-panel flex-col col-no-padding border-gradient-marcador">
                        <div className="backBlackBracketTennis">
                          <div className='result-item'>
                            {/* Game close */}
                            <div className="flex-row font-chivo text-bold text-italic border-bottom-score">
                              <span className="pull-right">
                                {match.sort_status === 1 && (
                                  <>
                                    {match.time_left > 3600 && moment(match.deadline).format("ddd DD, MMM HH:mm")}
                                    {match.time_left > 0 && (match.time_left) <= 3600 &&
                                      <div>
                                        <Clock type="row" poolId={bracketInfo.id} fixtureID={0} closingDate={match.deadline} />
                                      </div>
                                    }
                                  </>
                                )}
                                {match.sort_status !== 1 && moment(match.deadline).format("ddd DD, MMM HH:mm")}
                              </span>
                            </div>

                            {/* player one */}
                            <div className="flex-row border-bottom-score mtb-05 player-score">
                              <div className="col-50 flex-container align">
                                {match.player_one === null || match.player_one.avatar === null &&
                                  <div className="avatar-bracket">
                                    <img src={avatar} className="player-avatar" />
                                  </div>
                                ||
                                  <div className="avatar-bracket">
                                    <img src={match.player_one.avatar}  />
                                  </div>
                                }
                                <label className='font-chivo text-bold text-italic ml-05'>{match.player_one === null ? '-' : match.player_one.name}</label>
                              </div>
                              <div className="col-50 flex-container align between">
                                {setsPlayerOne.map(set => <label key={'a-'+set+'-'+Math.random()} className='font12'>{set}</label>)}
                              </div>
                              {/* <div className="col-10 text-bold text-italic font16 flex-container align">
                                {match.tennis_fixtures.length > 0 ? match.tennis_fixtures[0].player_1_score : 0}
                              </div> */}
                            </div>

                            {/* player two */}
                            <div className="flex-row mtb-05 player-score">
                              <div className="col-50 flex-container align">
                                {match.player_two === null || match.player_two.avatar === null &&
                                  <div className="avatar-bracket">
                                    <img src={avatar} className="player-avatar" />
                                  </div>
                                ||
                                  <div className="avatar-bracket">
                                    <img src={match.player_two.avatar}  />
                                  </div>
                                }
                                <label className='font-chivo text-bold text-italic ml-05'>{match.player_two === null ? '-' : match.player_two.name}</label>
                              </div>
                              <div className="col-50 flex-container align between">
                                {setsPlayerTwo.map(set => <label key={'a-'+set+'-'+Math.random()} className='font12'>{set}</label>)}
                              </div>
                              {/* <div className="col-10 text-bold text-italic font16 flex-container align">
                                {match.tennis_fixtures.length > 0 ? match.tennis_fixtures[0].player_2_score : 0}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BracketTennisScores;