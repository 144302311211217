import { useQuery } from '@tanstack/react-query'

// UTils
import API from '../../../utils/api'
import sleep from '../../../Xmerald-UI/utilities/sleep'

const TOURNAMENTS = '/api/tournament_instances/survivor_weeks/sport.json'

export default function useTournamentWeeks (activeTournamentID) {
  const queryState = useQuery(
    [TOURNAMENTS, activeTournamentID],
    async () => {
      await sleep()
      const { data } = await API.fetchTournamentInstancesWeekSurvivor(activeTournamentID)
      return data
    },
    {
      enabled: Boolean(activeTournamentID)
    }
  )

  return queryState
}
