import React from 'react';
import { pick } from 'underscore';

const BracketGroupPick = ({
  group,
  side,
  picks,
  onTeamSelected,
}) => {
  const setSelectedTeam = (group, place, teamId) => {
    let matchNumber = null;
    if ( place === 'first' ) {
      matchNumber = `1${group.short_name}`;
    }
    if ( place === 'second' ) {
      matchNumber = `2${group.short_name}`;
    }
    onTeamSelected(side, matchNumber, group, place, teamId)

  };

  return (
    <li className="tournament-bracket__item_group">
      <div className="tournament-bracket__match_group">
        <div className="match-content">
          <div className="match-header font-chivo text-bold text-italic uppercase text-small">
            {group.name}
          </div>

          {/* first place */}
          <div className="flex-row row-center" style={{padding: '5px'}}>
            <div className="flex-col col-25 text-center font-chivo text-bold uppercase">
              1{group.short_name}
            </div>
            <div className="flex-col">
              <select onChange={(e) => setSelectedTeam(group, 'first', e.target.value)} className='select-group' value={picks[`1${group.short_name}`].pick !== null ? picks[`1${group.short_name}`].pick : ''}>
                <option value="">Elige - 1{group.short_name}</option>
                {group.teams.map((_team) => {
                  return (
                    <option disabled={_team.selected === 2} key={_team.id} value={_team.id}>{_team.name}</option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="h-divider"></div>
          {/* second place */}

          <div className="flex-row row-center" style={{padding: '5px'}}>
            <div className="flex-col col-25 text-center font-chivo text-bold uppercase">
              2{group.short_name}
            </div>
            <div className="flex-col">
              <select onChange={(e) => setSelectedTeam(group, 'second', e.target.value)} className='select-group' value={picks[`2${group.short_name}`].pick !== null ? picks[`2${group.short_name}`].pick : '' }>
                <option value="">Elige - 2{group.short_name}</option>
                {group.teams.map((_team) => {
                  return (
                    <option disabled={_team.selected === 1} key={_team.id} value={_team.id}>{_team.name}</option>
                  );

                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </li>
  );

};

export default BracketGroupPick;