import styled from 'styled-components'


const Button = styled.button`
  cursor: pointer;
  border: none;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`

export default Button
