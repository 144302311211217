import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Components
import Button from '../Form/Button'
import Logo from './Logo'
import ModalBody from '../ModalPanel/ModalBody'

export default function CloseOtherSessions () {
  const {
    onCloseAllSessionsClicked,
    sessionsData,
    submitting,
    closeSessionsStatus
  } = useAuthContext()

  return (
    <ModalBody style={{ maxWidth: 500 }}>
      <Logo
        src='/images/Pickwin.png'
        alt='Pickwin logo'
      />
      <Title>Tienes sesiones abiertas en otros dispositivos</Title>
      <Text>Únicamente puedes tener una sesión activa en todo momento, para poder acceder en este dispositivo necesitas cerrar tus sesiones activas</Text>

      {sessionsData && sessionsData.sessions.map((item, index) => {
        return (
          <div key={index}>
            <div className='flex-row mb-1'>
              <div className='flex-col'>
                {moment(item.created).format('DD-MM-YY hh:mm a')}
              </div>
            </div>
            <div className='flex-row'>
              <div className='flex-col col-70'>{item.ua}</div>
              <div className='flex-col col-30' style={{ overflowWrap: 'break-word' }}>
                {item.ip}
              </div>
            </div>
          </div>
        )
      })}

      <div className='flex-row text-center mt-2'>
        <div className='flex-col'>
          {closeSessionsStatus
            ? <Button bgColor='#88ba44' loading={submitting} onClick={onCloseAllSessionsClicked}>CERRAR OTRAS SESIONES</Button>
            : <h4 className='flex-container justify'>No se pudo realizar el cierre de sesiones. Cierra esta ventana e intenta nuevamente</h4>}
        </div>
      </div>

    </ModalBody>
  )
}

const Title = styled.div`
  color: #88ba44;
  font-size: 1.5em;
  text-align: center;
`

const Text = styled.p`
  color: #FFF;
  font-size: 1em;
  text-align: center;
`
