import React from 'react'
import { BarLoader } from 'react-spinners'
import styled from 'styled-components'

const ButtonBase = styled.button`
  border: none;
  outline: none;
  border-radius: 0.4em;
  background-color: ${props => props.bgColor};
  color: #FFF;
  width: 100%;
  min-height: 33px;
  font-size: 1.3em;
  padding: 0.3em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 350ms ease;

  &:hover {
    opacity: 0.75;
  }
`

export default function Button (props) {
  return (
    <ButtonBase
      {...props}
      bgColor={props.disabled ? '#484848' : props.bgColor}
      style={{cursor: props.disabled ? 'not-allowed' : 'pointer', ...props.style}}
      onClick={props.onClick}
    >
      {props.loading
        ? <BarLoader color='#FFF' />
        : props.children}
    </ButtonBase>
  )
}
