import React from 'react'
import { withFormsy } from 'formsy-react'
import { FormControl, ControlLabel } from 'react-bootstrap'

class ChangePassInput extends React.Component {
  constructor (props) {
    super(props)
    this.changeValue = this.changeValue.bind(this)
  }

  changeValue (event) {
    if (event.currentTarget.value === ' ') { return }
    this.props.setValue(event.currentTarget.value)
  }

  render () {
    const errorMessage = this.props.getErrorMessage()
    let conditionalInput = null
    switch (this.props.type) {
      case 'PASS':
        conditionalInput = (
          <>
            <ControlLabel>{this.props.label}</ControlLabel>
            <div className='flex-row row-center'>
              <FormControl
                type='password'
                placeholder={this.props.label}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                defaultValue={this.props.defaultValue}
                value={this.props.getValue() || ''}
                onChange={(e) => { this.changeValue(e); this.props.inputHandler(e) }}
              />
            </div>
            <span className=' flex-row row-center error-formulario'>{errorMessage}</span>
          </>

        )
        break
    }

    return conditionalInput
  }
}

export default withFormsy(ChangePassInput)
