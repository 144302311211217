// Libraries
import React from 'react'
import _ from 'underscore'
import InfiniteScroll from 'react-infinite-scroller'
import $ from 'jquery'

// Components
import Loading from '../../Loading'
import Iconify from '../../Iconify'

// Styles
import '../../BracketPointsInfoContainer/bracketPointsInfoContainer.scss'
import '../bracketPoints.scss'

// Images
import avatar from '../../../assets/images/avatar-50.png'

const UserDescription = ({ standing, selectedEntryId }) => {
  const userPick = Number(selectedEntryId) === Number(standing.bracket_entry_id)
  return (
    <div className={`flex-row no-padding row-bracket mis-picks ${userPick ? 'user-entry' : ''}`}>
      <div className='table-header-item flex-container start pl-05'>
        <div className='avatar-bracket'>
          <img
            src={(standing.avatar === null || standing.avatar === '/img/profile-image-01.png') ? avatar : standing.avatar}
            className={standing.avatar === null || standing.avatar === '/img/profile-image-01.png' ? 'player-avatar' : ''}
          />
        </div>
        <span>
          {standing.nick}
          {standing.number !== null
            ? (
              <sup style={{ fontWeight: 'lighter', fontSize: '75%', margin: '0 2px' }}>
                {standing.number}
              </sup>
              )
            : ('')}
        </span>
      </div>
    </div>
  )
}

const UserPicks = ({ standing, matches, teams, userTieBreakers, tieBreakers, allMatches }) => {
  const picks = standing.bracket_picks

  return (
    <div className='flex-row no-padding row-bracket mis-picks'>
      {matches.map((match, i) => {
        if (picks === null || !picks.hasOwnProperty(match.number)) {
          if (match.sort_status === 1) {
            return (
              <div className='table-header-item game-item item-content-perfect' key={i}>
                <Iconify icon='maki:cross' style={{ fontSize: '1.3em', color: '#E00034' }} />
              </div>
            )
          } else {
            return (
              <div className='item-content-perfect border-right border-bottom column flex-container align justify' style={{ flex: '0 0' }} key={i}>
                <div className='flex-row no-padding border-bottom fc-align' style={{ height: '100%' }}>
                  <div className='flex-container align justify'>
                    <Iconify icon='maki:cross' style={{ fontSize: '1.3em', color: '#E00034' }} />
                  </div>
                </div>
              </div>
            )
          }
        }

        const pick = picks[match.number]
        // el match no esta vivo
        if (match.sort_status === 1) {
          return (
            <div className='table-header-item game-item item-content-perfect' key={i}>
              <i className='ion-checkmark' style={{ color: '#78BC28' }} />
            </div>
          )
        }

        let selectedTeam = null
        if (pick.pick !== null && teams.hasOwnProperty(pick.pick) ) {
          selectedTeam = teams[pick.pick];
        }
        // if (pick.pick === Number(match.home_team_id)) {
        //   selectedTeam = match.home_team
        // }
        // if (pick.pick === Number(match.away_team_id)) {
        //   selectedTeam = match.away_team
        // }

        // Colors
        const correctPicks = [false, false, false]
        if (match.status !== 'upcoming') {
          let correctness = 0
          if (pick.pick !== null && pick.pick === match.winning_pick) {
            correctness = correctness ^ (1 << 2)
            correctPicks[0] = true
          }
          correctness = (correctness >>> 0).toString(2)
        }

        // correct and incorrect pick class
        const pickResultClass = correctPicks[0] ? 'winner' : 'looser'

        return (
          <div className='item-content-perfect border-right border-bottom column flex-container align justify' style={{ maxWidth: '100px' }} key={i}>
            {selectedTeam &&
              <div className={`flex-row no-padding border-bottom fc-align ${pickResultClass}`} style={{ height: '100%' }}>
                <div className='flex-container full align justify relative'>
                  <i className={`quina ${selectedTeam.logo}`} />
                  <small className='team-name-perfect'>{selectedTeam.abbreviation}</small>
                </div>
              </div> ||
                <div className='flex-row no-padding border-bottom fc-align looser' style={{ height: '100%' }}>
                  <div className='flex-container align justify'>
                    <Iconify icon='maki:cross' style={{ fontSize: '1.3em', color: '#E00034' }} />
                  </div>
                </div>}
          </div>
        )
      })}

      {tieBreakers.map((tieBreaker) => <MyTieBreakers key={tieBreaker.id} tieBreaker={tieBreaker} userTieBreakers={userTieBreakers} matches={allMatches} />)}
    </div>
  )
}

const MyTieBreakers = ({ tieBreaker, userTieBreakers, matches }) => {
  if (userTieBreakers && userTieBreakers.length === undefined) {
    const userBreaker = userTieBreakers[tieBreaker.id]
    let matchFinded = null

    if (userBreaker) {
      matchFinded = matches.find(match => (Number(match.away_team_id) === Number(userBreaker.val) || Number(match.home_team_id) === Number(userBreaker.val)))
    }

    let selectedTeam = null
    if (matchFinded) {
      if (Number(userBreaker.val) == Number(matchFinded.home_team_id)) {
        selectedTeam = matchFinded.home_team // matchFinded.player_one;
      }
      if (Number(userBreaker.val) == Number(matchFinded.away_team_id)) {
        selectedTeam = matchFinded.away_team // matchFinded.player_two;
      }
    }

    return (
      <div className='item-content-perfect border-right border-bottom column flex-container align justify' style={{ flex: '0 0' }}>
        {tieBreaker.enabled &&
          <>
            {(userBreaker === undefined || userBreaker.val === null) &&
              <i className='ion-close' style={{ color: '#E00034' }} /> ||
                <i className='ion-checkmark' style={{ color: '#78BC28' }} />}
          </> ||
            <>
              {selectedTeam && selectedTeam.abbreviation || <i className='ion-close' style={{ color: '#E00034' }} />}
            </>}
      </div>
    )
  } else {
    return (
      <div className='item-content-perfect border-right border-bottom column flex-container align justify' style={{ flex: '0 0' }}>
        {/* TODO: Check this */}
        {tieBreaker.enabled
         && (
          <Iconify icon='maki:cross' style={{ fontSize: '1.3em', color: '#E00034' }} />
        )
        || (
          <Iconify icon='maki:cross' style={{ fontSize: '1.3em', color: '#E00034' }} />
        )}
      </div>
    )
  }
}

const UserPoints = ({ standing, closed, selectedEntryId }) => {
  const userPick = Number(selectedEntryId) === Number(standing.bracket_entry_id)
  return (
    <div className={`flex-row no-padding row-bracket border-bottom mis-picks ${userPick ? 'user-entry' : ''}`}>
      <div className='flex-container align justify font-chivo text-medium'>
        {closed ? standing.points : standing.possible_points}
      </div>
    </div>
  )
}

const PicksGroupBracketPoints = ({ matches, allMatches, teams, groupPicks, closed, tieBreakers, loadMoreStandings, selectedEntryId }) => {
  const loadMore = (eventKey) => {
    loadMoreStandings(eventKey)
  }

  React.useEffect(() => {
    let ignoreEvent = false
    const header = $('#content-header-bracket')
    const lower = $('#content-lower-bracket')
    header.scroll(function () {
      const ignore = ignoreEvent
      ignoreEvent = false
      if (!ignore) {
        ignoreEvent = true
        lower.scrollLeft($(this).scrollLeft())
      }
    })

    lower.scroll(function () {
      const ignore = ignoreEvent
      ignoreEvent = false
      if (!ignore) {
        ignoreEvent = true
        header.scrollLeft($(this).scrollLeft())
      }
    })
  }, [])

  if (!groupPicks) return null

  return (
    <div className='lower-section'>
      <div className='container-picks'>
        <div className='tabla-grupos'>
          <div className='lower-table border-gradient'>
            <div className='flex-row no-padding'>
              <div className='col-20 table-lower-item font-chivo pl-1 text-14 border-bottom border-right'>
                PICKWINERO
              </div>
              <div className='col-70 border-bottom' />
              <div className='col-10 flex-container align justify font-chivo text-14 border-bottom'>Puntos</div>
            </div>

            <div className='overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal'>
              <InfiniteScroll
                pageStart={1}
                loadMore={loadMore}
                hasMore={groupPicks.page < groupPicks.pages}
                loader={<Loading key='labelLoader00' label='...' />}
                key={groupPicks.page}
                useWindow={false}
              >
                <div className='flex-row no-padding no-margin-top'>
                  <div className='col-20'>
                    {groupPicks.items.map((standing, i) => <UserDescription standing={standing} selectedEntryId={selectedEntryId} key={`user-${i}-${standing.bracket_entry_id}`} />)}
                  </div>
                  <div className='col-70 table-header-overflow' id='content-lower-bracket'>
                    {groupPicks.items.map((standing, i) => (
                      <UserPicks
                        standing={standing}
                        matches={matches}
                        teams={teams}
                        tieBreakers={tieBreakers}
                        allMatches={allMatches}
                        userTieBreakers={standing.bracket_user_tiebreakers}
                        key={`picks-${i}-${standing.bracket_entry_id}`}
                      />
                    ))}
                  </div>
                  <div className='col-10 border-left'>
                    {groupPicks.items.map((standing, i) => <UserPoints closed={closed} standing={standing} selectedEntryId={selectedEntryId} key={`points-${i}-${standing.bracket_entry_id}`} />)}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PicksGroupBracketPoints
