import React from 'react';
import firstBy from 'thenby';

import Loading from '../Loading';
import PoolsList from '../PoolsList';
import ErrorBoundary from '../ErrorBoundary';

import { Redirect } from 'react-router-dom';
import moment from 'moment';
import API from '../../utils/api';
import utils from '../../utils/utils';
import * as Sentry from '@sentry/browser';
import _ from 'underscore';

// Utils
import { publish } from '../../utils/events'

class Pool extends React.Component {
  constructor(props) {
    super(props);
    //let mounted = false;
    this.state = {
      logged: this.props.logged,
      mounted: false,
      pools: null,
      sortedPools: null,
      externalPool: null,
      allGamesSorted: null,
      poolsChanged: false,
      redirectMisQuinielas: false,
      updatedPool: null,
      updatedPools: false,
      changePoolStatus: false,
      timeUpdateLobby: '',
      isReconectSocketLobby: false,
      filters: [],
    };

    this.mockLobbyPoolsDeadline = (response) => {
      console.log('response', response);
      let newDateObj = moment()
        .add(8, 's')
        .toDate();
      let mockedResponsePools = response;
      mockedResponsePools.data.pools = mockedResponsePools.data.pools.map((pool) => {
        pool.deadline = newDateObj;
        return pool;
      });
      //map all the pools to start in 1 minute

      return mockedResponsePools;
    };

    this.filterBar = (filters) => {
      this.setState({ filtering: true, filters: filters });

      let tempAvailablePools = [...this.state.pools];
      let newPools = [];
      let filtersKeys = Object.keys(filters);
      //let itContainsAll = false;
      let filteredBySports = [];
      let filteredByGameType = [];
      let filteredByCurrency = [];
      let filteredByFee = [];
      let filteredByType = [];
      let filteredByLeague = [];
      let filteredByStatus = [];
      let filteredByFull = [];
      let filteredByPrivate = [];

      /**
       * Iterate through filters to filter the sortedpools
       */
      filtersKeys.forEach((fK) => {
        // console.log('filtering', fK);
        if (fK === 'sports') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              if (newPools.length > 0) {
                filteredBySports = newPools;
              } else {
                filteredBySports = tempAvailablePools;
              }
            } else {
              if (newPools.length > 0) {
                filteredBySports = [
                  ...filteredBySports,
                  ...newPools.filter((p) => p.sport.name === fOpt),
                ];
              } else {
                let tempPools = tempAvailablePools.filter((p) => {
                  if (p.sport && p.sport.name) {
                    if (p.sport.name === fOpt) {
                      return p;
                    }
                  }
                })
                filteredBySports = [
                  ...filteredBySports,
                  ...tempPools,
                ];
              }
            }
          });
          // console.log( 'filteredBySports' , filteredBySports );
          newPools = [...filteredBySports];
        } else if (fK === 'currency') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              filteredByCurrency = newPools;
            } else {
              filteredByCurrency = [
                ...newPools.filter((p) => p.entry_currency === fOpt || p.entry_currency === 'ticket'),
                ...filteredByCurrency.filter((p) => p.entry_currency !== 'ticket'),
              ];
            }
          });
          // console.log( 'filteredByCurrency' , filteredByCurrency );
          newPools = [...filteredByCurrency];
        } else if (fK === 'buyIn') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              filteredByFee = newPools;
            } else {
              if (fOpt === 'low') {
                filteredByFee = [
                  ...filteredByFee,
                  ...newPools.filter((p) => p.entry_fee / 100 < 49 && p.entry_fee / 100 > 0),
                ];
              } else if (fOpt === 'medium') {
                filteredByFee = [
                  ...filteredByFee,
                  ...newPools.filter((p) => p.entry_fee / 100 < 251 && p.entry_fee / 100 > 49),
                ];
              } else if (fOpt === 'high') {
                filteredByFee = [
                  ...filteredByFee,
                  ...newPools.filter((p) => p.entry_fee / 100 > 251),
                ];
              }
            }
          });
          // console.log( 'filteredByFee' , filteredByFee );
          newPools = [...filteredByFee];
        } else if (fK === 'type') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              filteredByType = newPools;
            } else {
              switch (fOpt) {
                case 'abierta':
                  filteredByType = [
                    ...filteredByType,
                    ...newPools.filter((p) => p.variation === 'open'),
                  ];
                  break;
                case 'max':
                  filteredByType = [
                    ...filteredByType,
                    ...newPools.filter((p) => p.variation === 'maxn'),
                  ];
                  break;
                case 'h2h':
                  filteredByType = [
                    ...filteredByType,
                    ...newPools.filter((p) => p.variation === 'h2h'),
                  ];
                  break;
                case 'cupo':
                  filteredByType = [
                    ...filteredByType,
                    ...newPools.filter((p) => p.variation === 'limited'),
                  ];
                  break;
                case 'pick5':
                  filteredByType = [
                    ...filteredByType,
                    ...newPools.filter((p) => p.variation === 'pick5'),
                  ];
                  break;
                case 'parlay':
                  filteredByType = [
                    ...filteredByType,
                    ...newPools.filter((p) => p.variation === 'parlay'),
                  ];
                  break;
                default:
                  break;
              }
            }
          });
          // console.log( 'filteredByType' , filteredByType );
          newPools = [...filteredByType];
        } else if (fK === 'league') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              filteredByLeague = newPools;
            } else {
              filteredByLeague = [
                ...filteredByLeague,
                ...newPools.filter((p) => {
                  if (p.tournament_instances[0] === undefined) {
                    return false;
                  }
                  return parseInt(p.tournament_instances[0].tournament_id) === parseInt(fOpt);
                }),
              ];
            }
          });
          // console.log( 'filteredByLeague' , filteredByLeague );
          newPools = [...filteredByLeague];
        } else if (fK === 'status') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              filteredByStatus = newPools;
            } else {
              if (fOpt === 'proximas') {
                filteredByStatus = [
                  ...filteredByStatus,
                  ...newPools.filter((p) => p.status === 'upcoming'),
                ];
              } else if (fOpt === 'curso') {
                filteredByStatus = [
                  ...filteredByStatus,
                  ...newPools.filter((p) => p.status === 'live'),
                ];
              } else {
                filteredByStatus = [
                  ...filteredByStatus,
                  ...newPools.filter((p) => p.status === 'closed'),
                ];
              }
            }
          });
          // console.log( 'filteredByStatus' , filteredByStatus );
          newPools = [...filteredByStatus];
        }
        if (fK === 'gameType') {
          filters[fK].forEach((fOpt) => {
            if (fOpt === 'all') {
              filteredByGameType = newPools;
            } else {
              filteredByGameType = [
                ...filteredByGameType,
                ...newPools.filter((p) => p.game_type === fOpt),
              ];
            }
          });
          newPools = [...filteredByGameType];
        } else if (fK === 'isFull') {
          if (filters[fK][0]) {
            filteredByFull = newPools;
          } else {
            filteredByFull = [...filteredByFull, ...newPools.filter((p) => !p.is_full)];
            // console.log('filteredByFull with NewPools av', filteredByFull );
          }
          newPools = [...filteredByFull];
        } else if (fK === 'isPrivate') {
          if (filters[fK][0]) {
            newPools = [...newPools.filter((p) => p.private)];
          } else {
            filteredByPrivate = [...newPools.filter((p) => !p.private)];
            // console.log('filteredByPrivate', filteredByPrivate );
            newPools = [...filteredByPrivate];
          }
        }
      });

      // console.log('Filtering finish');
      this.setState({
        sortedPools: newPools,
        updatePools: true,
        filtering: false
      }, () => {
        setTimeout(() => {
          // console.log('Update Pools');
          this.setState({ updatePools: false });
        }, 300);
      });
    };

    this.redirectAfterCancel = this.redirectAfterCancel.bind(this);
    this.refreshAfterMultipleRegister = this.refreshAfterMultipleRegister.bind(this);
    this.refreshAfterRegister = this.refreshAfterRegister.bind(this);
    this.restoreUpdatedPool = this.restoreUpdatedPool.bind(this);
    this.sorting = this.sorting.bind(this);
    this.changeStatusPool = this.changeStatusPool.bind(this);
    this.sortingAndFilterDefault = this.sortingAndFilterDefault.bind(this);

    // websockets
    this.onPoolUpdate = this.onPoolUpdate.bind(this);
    this.onPoolClose = this.onPoolClose.bind(this);
    this.onLobbyRefresh = this.onLobbyRefresh.bind(this);
    this.onPoolAdd = this.onPoolAdd.bind(this);
    this.onSignOutUser = this.onSignOutUser.bind(this);

    this.redirectToMisQuinielas = () => {
      this.setState({ redirectMisQuinielas: true });
    };

    this.updateSortedPools = (newPools) => {
      console.log('updateSortedPools');
      this.setState(
        {
          sortedPools: newPools,
          updatePools: true,
          poolsChanged: true,
        },
        () => {
          this.setState(
            {
              updatePools: false,
              poolsChanged: true,
            },
            () => {
              this.setState({ poolsChanged: false });
            }
          );
        }
      );
    };

    this.sortingAndFilterForPartner = (pools) => {
      // console.log('Default Sorting in Pools');
      let sorted;
      sorted = pools.sort(
        firstBy('private')
          .thenBy('featured', -1)
          .thenBy('sort_order', -1)
          .thenBy('entry_currency', -1)
          .thenBy('is_full')
          .thenBy('deadline')
          .thenBy('available', -1)
          .thenBy('entry_fee')
      );
      let privatePools = _.filter(sorted, (pool) => pool.private);
      let publicPools = _.filter(sorted, (pool) => !pool.private);
      sorted = [...publicPools, ...privatePools];
      sorted = _.filter(sorted, (pool) => pool.status === 'upcoming');
      sorted = _.filter(sorted, (pool) => !pool.is_full);
      sorted = _.filter(sorted, (pool) => pool.entry_currency === 'real');
      // sorted = sorted.filter(pool => !pool.pickngo);
      // console.log(sorted[0].id);
      return sorted;
    };
  }

  changeStatusPool(poolId) {
    console.log('Change PoolStatus', poolId);
    let tempAvailablePools = this.state.sortedPools;

    if (!tempAvailablePools) {
      return;
    }

    let selectedPoolIndx, selectedPool;
    selectedPool = tempAvailablePools.find((pool, poolIdx) => {
      if (pool.id === poolId) {
        selectedPoolIndx = poolIdx;
        return pool;
      }
    });
    // console.log('changeStatus Pool', selectedPool);
    if (selectedPool !== undefined) {
      tempAvailablePools[selectedPoolIndx].status = 'live';
      tempAvailablePools[selectedPoolIndx].upcoming = false;
      tempAvailablePools[selectedPoolIndx].time_left = 0;
      // tempAvailablePools[selectedPoolIndx] = 'live';

      // Filter again after pools Changed
      //
      //tempAvailablePools = this.sortingAndFilterDefault(tempAvailablePools);
      this.filterBar(this.state.filters);

      this.setState(
        {
          changePoolStatus: true,
          poolsChanged: true,
          updatedPool: true,
          //sortedPools: tempAvailablePools,
        },
        () => {
          this.setState({
            updatedPool: false,
            poolsChanged: false,
            changePoolStatus: false,
          });
        }
      );
    }
  }

  /**
   * This function Redirects the user into Mims quininelas after making a Multy entry
   */
  refreshAfterMultipleRegister() {
    console.log('Redirect Mis Quinielas');
    this.setState({
      redirectMisQuinielas: true,
    });
  }

  /**
   * This function updates balance and user info+
   * After the user complete a register into a quiniela
   */
  refreshAfterRegister() {
    this.setState({
      mounted: false,
    });
    API.fetchPools()
      .then(
        (response) => {
          console.log('API LOG FetchPools:', response);
          if (response.data.now !== undefined) {
            // console.log('DATA');
            this.refs.mainPool = this.refs.mainPool
              ? this.setState(() => {
                  API.subscribeSocketClosedFixture((callbackClosedFixture) => {
                    this.setState(
                      () => {
                        return {
                          liveClosedFixtures: callbackClosedFixture,
                        };
                      },
                      () => {
                        console.log('After Closed Fixture', callbackClosedFixture);
                      }
                    );
                  });
                  return {
                    pools: response.data.pools,
                    mounted: true,
                    sortedPools: this.sortingAndFilterDefault(response.data.pools),
                    allGamesSorted: this.sorting(response.data.pools),
                    // redirectMisQuinielas: true
                  };
                })
              : null;
          } else {
            return null;
            // console.log('NOT DATA Available Yet');
          }
        },
        () => {
          utils.setUserInfo();
        }
      )
      .catch((error) => {
        Sentry.captureException(error, { extra: 'Error FetchingPools' });
      });
  }

  /**
   * This function Redirect after a cancelation
   */
  redirectAfterCancel(poolId, entries, withoutEntries) {
    console.log('Cancelation from Lobby');
    console.log('poolId', poolId);
    console.log('entries', entries);
    console.log('withoutEntries', withoutEntries);
    // this.setState({
    //   mounted: false
    // });
    let temporalPool = this.state.pools;
    let temporalDefaultPool = this.state.allGamesSorted;
    let canceledEntries = _.filter(entries, (entry) => entry.selected);

    temporalPool.forEach((tp) => {
      if (tp.id === poolId) {
        // tp.entry_count = tp.entry_count - canceledEntries.length;
        if (tp.guaranteed_prize === null) {
          tp.available = tp.available - tp.entry_fee * canceledEntries.length;
        }

        if (tp.is_full) {
          console.log('isFull');
          tp.is_full = false;
        }

        if (withoutEntries) {
          delete tp.entries;
          delete tp.first_entry;
          delete tp.bracket_entries;
        } else {
          //Search the canceled entries and check if is the first of the array
          console.log('Entries', tp.entries);
          console.log('Canceled Entries', canceledEntries);

          canceledEntries.forEach((cE) => {
            tp.entries = tp.entries.filter((tpE) => {
              if (cE.id !== tpE.id) {
                console.log(tpE);
                return tpE;
              }
            });
          });

          console.log(tp.entries, ' entries remaining');
        }
      }
    });

    this.setState(
      { pools: temporalPool, allGamesSorted: temporalDefaultPool, updatePools: true },
      () => {
        setTimeout(() => {
          this.setState({ updatePools: false });
        }, 200);
      }
    );
  }

  restoreUpdatedPool(poolId) {
    // console.log('RestoreUpdatedPool')
    let temporalAvailables = this.state.sortedPools;
    let poolIndx;
    let updatedPool = temporalAvailables.find((pool, i) => {
      if (pool.id === poolId) {
        poolIndx = i;
        return pool;
      }
    });
    if (updatedPool !== undefined) {
      temporalAvailables[poolIndx]['updatedPool'] = null;
      // this.setState({
      //   sortedPools : temporalAvailables,
      // })
    }
  }

  updateLobby(prevProps, isRefresh) {
    if ((prevProps && !prevProps.logged && this.props.logged) || isRefresh) {
      // console.log('User Logged in');

      //if(isRefresh) this.setState({mounted: false});

      API.fetchPools().then((response) => {
        const responses = [response];
        try {
          utils.checkAxiosAllErrors(responses);
        } catch (e) {
          console.error(e);
          Sentry.captureException(e, { extra: 'Error Updating Lobby ' });
        }
        console.log('response', response);
        let allGames, sortedPools;
        allGames = [
          ...response.data.pools,
          ...response.data.brackets,
          ...response.data.survivors,
          ...response.data.group_pools,
        ];
        sortedPools = this.sortingAndFilterDefault(allGames);

        let allGamesSorted = this.sorting(allGames);
        this.setState(
          {
            timeUpdateLobby: isRefresh ? new Date().getTime() : '',
            updatePools: true,
            pools: allGames,
            sortedPools: sortedPools,
            allGamesSorted: allGamesSorted,
            isReconectSocketLobby: isRefresh ? true : false,
            poolsChanged: isRefresh ? true : this.state.poolsChanged,
          },
          () => {
            if (this.state.isReconectSocketLobby) {
              setTimeout(() => {
                this.setState({ isReconectSocketLobby: false });
              }, 800);
            }
          }
        );
      });
    }
  }

  componentWillUnmount() {
    API.unSubscribeSocketLobby();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateLobby(prevProps);
  }

  onPoolUpdate(pool) {
    if ( _.isEmpty(this.state.pools) ) {
      return;
    }

    let pools = this.state.pools;
    const idx = pools.findIndex(p => p.id === pool.id && p.game_type === pool.game_type);
    if ( idx === -1 ) {
      return;
    }

    pools[idx] = {
      ...pools[idx],
      ...{
        'entry_count': pool.entry_count,
        'available': pool.available,
        'is_full': pool.is_full
      }
    };

    this.setState({
      pools: pools,
      updatePools: true,
      sortedPools: this.sortingAndFilterDefault(pools),
      allGamesSorted: this.sorting(pools),
    }, () => {
      setTimeout(() => {
        this.setState({ updatePools: false });
      }, 200);
    });
    // este no hace sort...
    // this.filterBar(this.state.filters);
  }

  onPoolClose(pool) {
    this.setState({
      poolClosed: pool,
    });
  }

  onLobbyRefresh() {
    this.updateLobby(undefined, true);
  }

  onPoolAdd(pool) {
    let _pools = this.state.pools;
    let allIdx = _.findIndex(_pools, (_pool) => _pool.id === pool.id);
    if (allIdx === -1) {
      _pools.push(pool);
      this.setState({ pools: _pools });
    }
    this.filterBar(this.state.filters);
  }

  // When user signins on another browser
  onSignOutUser(user) {
    var tokenHash = utils.getTokenHash();
    let userData = utils.getUserInfo();

    if ( user !== undefined && userData && user.id === userData.id ) {
      if ( user.hash === null || user.hash !== tokenHash ) {
        utils.clearStorage();
        window.location = '/';
        // For legacy 🚩
        publish('logout')
      }
    }
    if ( user === undefined ) {
      utils.clearStorage();
      window.location = '/';
    }
  }

  componentDidMount() {
    //let isPoolAvailable = false;

    API.subscribeSocketLiveLobby(
      this.onPoolUpdate,
      this.onPoolClose,
      this.onLobbyRefresh,
      this.onPoolAdd,
      () => {
        // Handling in auth context
      }
    );

    API.fetchPools()
      .then((response) => {
        const responses = [response];
        try {
          utils.checkAxiosAllErrors(responses);
        } catch (e) {
          console.error(e);
        }

        if (response && response.data.now !== undefined) {
          if (utils.isLogged()) {

          }
          this.refs.mainPool = this.refs.mainPool
            ? this.setState(
                () => {
                  // console.log(response);
                  let allGames = [
                    ...response.data.pools,
                    ...response.data.brackets,
                    ...response.data.survivors,
                    ...response.data.group_pools,
                  ];

                  // let sortedPools = this.sortingAndFilterDefault(allGames);
                  let sortedPools = allGames;
                  let allGamesSorted = this.sorting(allGames);

                  return {
                    pools: allGames,
                    sortedPools: sortedPools,
                    allGamesSorted: allGamesSorted,
                  };
                },
                () => {
                  /**
                   * Find External Pool for PopUp
                   */
                  this.setState(() => {
                    let externalPool = null;
                    if (this.props.popUpPoolId && response.data.pools) {
                      let poolAvailable = _.find(
                        response.data.pools,
                        (pool) => pool.id === this.props.popUpPoolId
                      );
                      if (poolAvailable) {
                        return {
                          externalPool: externalPool,
                          mounted: true,
                        };
                      } else {
                        /**
                         * If the pool selected via query isn in available, fetch the  pool Info
                         */
                        console.log('Pool Unavailable');
                        API.fetchIndividualPool(this.props.popUpPoolId)
                          .then((response) => {
                            externalPool = response.data;
                            console.log(externalPool);
                            this.setState({
                              externalPool: externalPool,
                              mounted: true,
                            });
                          })
                          .catch((error) => {
                            Sentry.captureException(error, {
                              extra: 'Error Fetching Individual Pool in Main lobby',
                            });
                          });
                      }
                    } else if (this.props.popUpBracketId && response.data.pools !== undefined) {
                      let bracketAvailable = response.data.brackets.find(
                        (bracket) => bracket.id === this.props.popUpBracketId
                      );
                      if (bracketAvailable !== undefined) {
                        return {
                          externalPool: bracketAvailable,
                          mounted: true,
                        };
                      } else {
                        /**
                         * If the pool selected via query isn in available, fetch the  pool Info
                         */
                        console.log('Bracket Unavailable', this.props.popUpBracketId);
                        API.fetchBracketsDetails(this.props.popUpBracketId)
                          .then((response) => {
                            externalPool = response.data;
                            console.log(externalPool);
                            this.setState({
                              externalPool: externalPool,
                              mounted: true,
                            });
                          })
                          .catch((error) => {
                            Sentry.captureException(error, {
                              extra: 'Error fetching individual bracket',
                            });
                          });
                      }
                    } else if (this.props.popUpSurvivorId && response.data.pools !== undefined) {
                      console.log("HEEEEEEEERE");
                      let allGames = [
                        ...response.data.pools,
                        ...response.data.survivors,
                        ...response.data.brackets,
                      ];
                      let survivorAvailable = allGames.find(
                        (survivor) =>
                          survivor.id === this.props.popUpSurvivorId &&
                          survivor.game_type === 'survivor'
                      );
                      if (survivorAvailable !== -1 && survivorAvailable !== undefined) {
                        // console.log(survivorAvailable);
                        return {
                          externalPool: survivorAvailable,
                          mounted: true,
                        };
                      } else {
                        /**
                         * If the pool selected via query isn in available, fetch the  pool Info
                         */
                        console.log('Survivor Unavailable');
                        API.fetchSurvivorDetails(this.props.popUpSurvivorId)
                          .then((response) => {
                            externalPool = response.data;
                            console.log('fetched External Survivor ', externalPool);
                            this.setState({
                              externalPool: externalPool,
                              mounted: true,
                            });
                          })
                          .catch((error) => {
                            Sentry.captureException(error, {
                              extra: 'Error Fetching Individual Survivor in Main lobby',
                            });
                          });
                      }
                    } else if (this.props.popUpPoolGroupId && response.data.pools !== undefined) {
                      // console.log('Pool Group');
                      let allGames = [
                        ...response.data.pools,
                        ...response.data.survivors,
                        ...response.data.brackets,
                      ];
                      let survivorAvailable = allGames.find(
                        (survivor) =>
                          survivor.id === this.props.popUpSurvivorId &&
                          survivor.game_type === 'survivor'
                      );
                      if (survivorAvailable !== -1 && survivorAvailable !== undefined) {
                        // console.log(survivorAvailable);
                        return {
                          externalPool: survivorAvailable,
                          mounted: true,
                        };
                      } else {
                        /**
                         * If the pool selected via query isn in available, fetch the  pool Info
                         */
                        // console.log('PoolGroup Unavailable');
                        API.fetchPoolGroupsDetail(this.props.popUpPoolGroupId)
                          .then((response) => {
                            if (response.data) {
                              externalPool = response.data;
                              // console.log('fetched External PoolGroup ',externalPool);
                              this.setState({
                                externalPool: externalPool,
                                mounted: true,
                              });
                            } else {
                              // console.log( 'Fetch Failed ');
                              this.setState({
                                externalPool: null,
                                mounted: true,
                              });
                            }
                          })
                          .catch((error) => {
                            Sentry.captureException(error, {
                              extra: 'Error Fetching Individual Survivor in Main lobby',
                            });
                          });
                      }
                    } else {
                      // console.log('else')
                      return {
                        externalPool: externalPool,
                        mounted: true,
                      };
                    }
                  });
                }
              )
            : null;
        }
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  }

  /**
   * This is the Default function to set the Default sort Order
   * @param pools
   * @returns {*}
   */
  sortingAndFilterDefault(pools) {
    console.log('Default Sorting in Pools');
    let sorted;
    sorted = pools.sort(
      firstBy('private')
        .thenBy('featured', -1)
        .thenBy('sort_order', -1)
        .thenBy('entry_currency', -1)
        .thenBy('is_full')
        .thenBy('deadline')
        .thenBy('available', -1)
        .thenBy('entry_fee')
    );
    let privatePools = _.filter(sorted, (pool) => pool.private);
    let publicPools = _.filter(sorted, (pool) => !pool.private);

    sorted = [...publicPools, ...privatePools];

    sorted = _.filter(sorted, (pool) => pool.status === 'upcoming');
    sorted = _.filter(sorted, (pool) => !pool.is_full);
    // sorted = sorted.filter(pool => !pool.pickngo);
    // console.log(sorted[0].id);
    return sorted;
  }

  /**
   *This is the non Default Sorting function
   * @param pools
   */
  sorting(pools) {
    console.log('Regular Sorting on Pools');

    let sPools = pools.sort(
      firstBy('private')
        .thenBy('featured', -1)
        .thenBy('sort_order', -1)
        .thenBy('entry_currency', -1)
        .thenBy('is_full')
        .thenBy('deadline')
        .thenBy('available', -1)
        .thenBy('entry_fee')
    );

    let privatePools = _.filter(sPools, (pool) => pool.private);
    let publicPools = _.filter(sPools, (pool) => !pool.private);

    sPools = [...publicPools, ...privatePools];

    // console.log(sPools[0].id);
    // sPools = sPools.filter(pool => !pool.pickngo);
    return sPools;
  }

  render() {
    //  console.log("Pool",this.props.logged);
    return (
      <ErrorBoundary isPanel>
        <div className="mainPoolContainer col-fhd-10 col-fhd-offset-1" ref="mainPool">
          {this.state.mounted ? (
            !this.state.redirectMisQuinielas ? (
              <div>
                <PoolsList
                  handleTutorial={this.props.handleTutorial}
                  handleTutorialStep={this.props.handleTutorialStep}
                  filtering={this.state.filtering}
                  bannerChanged={this.props.bannerChanged}
                  available={this.state.pools}
                  changePoolStatus={this.state.changePoolStatus}
                  changeStatusPool={this.changeStatusPool}
                  poolsChanged={this.state.poolsChanged}
                  changePoolPopUpView={this.props.changePoolPopUpView}
                  changeBracketPopUpView={this.props.changeBracketPopUpView}
                  closePopUpNewUsers={this.props.closePopUpNewUsers}
                  deleteNewUserStatus={this.props.deleteNewUserStatus}
                  filterBar={this.filterBar}
                  landingPopUp={this.state.landingPopUp}
                  logged={this.props.logged}
                  pools={this.state.sortedPools}
                  popUpNewUsers={this.props.popUpNewUsers}
                  popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                  popUpPoolId={this.props.popUpPoolId}
                  popUpPoolGroupId={this.props.popUpPoolGroupId}
                  popUpBracketId={this.props.popUpBracketId}
                  popUpSurvivorId={this.props.popUpSurvivorId}
                  popUpView={this.props.popUpView}
                  externalPool={this.state.externalPool}
                  referer={this.props.referer}
                  redirectToMisQuinielas={this.redirectToMisQuinielas}
                  redirectAfterCancel={this.redirectAfterCancel}
                  refreshAfterMultipleRegister={this.refreshAfterMultipleRegister}
                  refreshAfterRegister={this.refreshAfterRegister}
                  updatedPool={this.state.updatedPool}
                  updatePools={this.state.updatePools}
                  updateSortedPools={this.updateSortedPools}
                  updateUserBalance={this.props.updateUserBalance}
                  updateUserInfo={this.props.updateUserInfo}
                  userData={this.props.userData}
                  isReconectSocketLobby={this.state.isReconectSocketLobby}
                  match={this.props.match}
                />
              </div>
            ) : (
              <Redirect
                push
                to={{
                  pathname: '/mis_quinielas',
                }}
              />
            )
          ) : (
            <Loading label="Espera un momento..." />
          )}
        </div>
      </ErrorBoundary>
    );
  }
}
export default Pool;
