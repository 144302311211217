import React from 'react';
import axios from 'axios';
import flechasIcon from '../../assets/images/flechasIconBlanco-50.png';
import participantes from '../../assets/images/participantesIconBlanco-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';
import copiarPicks from '../../assets/images/copiarIconBlanco-50.png';
import Loading from '../Loading/Loading';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';

import gaUtils from '../GAWrapper';

//import createHistory from 'history/createBrowserHistory';

import MediaQuery from 'react-responsive';
import firstBy from 'thenby';

import { Button, DropdownButton, MenuItem, Modal } from 'react-bootstrap/lib';
import { Redirect, Link } from 'react-router-dom';

import numeral from 'numeral';
import WeeksControls from '../WeeksControls';

import {
  PropsPartidos,
  PicksPartidos,
  Desempates,
  EstatusQuiniela,
  SetQuinielaHeaders,
} from '../../utils/quinielasComponents';
import RegisterPanel from '../RegisterPanel';
import Premios from '../Premios';
import Participantes from '../Participantes';
// import '../../assets/stylesheets/flags-sprites-complete.scss';
import picks from '../../assets/images/quinielasIconBlanco-50.png';
import guardar from '../../assets/images/guardarPicks-50.png';
import invita from '../../assets/images/invitarIconBlanco-50.png';
import utils from '../../utils/utils';
import Utils from '../../utils/quinielasUtils';
import API from '../../utils/api';

import { QuinielaDataProvider } from '../../context/QuinielaDataContext';

import QuinielaPicksUI from '../QuinielaPicksUI/QuinielaPicksUI';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';

//const history = createHistory();
const history = require('history').createBrowserHistory();

const PopUpButtons = ({
  logged,
  poolInfo,
  enoughBalance,
  submitting,
  openPanel,
  updateRegisterPicks,
  temporalRegisterPicks,
  showAbonar,
  entriesInfo,
  updateUserInfo,
  redirect,
}) => {
  let conditionalComponent = null;

  if (!logged || !poolInfo || poolInfo.pool.status !== 'upcoming') {
    conditionalComponent = null;
  } else if (logged) {
    let buttonsContent = null;
    // Without Entries
    if (!poolInfo.entries) {
      buttonsContent = (
        <Button
          className="guardar-picks callToAction"
          bsStyle="success"
          disabled={poolInfo.pool.is_full || utils.isSuPick()}
          onClick={(e) => {
            if (poolInfo.pool.entry_currency === 'referral' && !enoughBalance) {
              window.location = '/usuario/invitados';
            } else {
              if (enoughBalance) {
                openPanel(e, poolInfo.pool);
                updateRegisterPicks(temporalRegisterPicks);
              } else {
                showAbonar();
              }
            }
          }}
        >
          <img
            className={`pool-icons ${
              poolInfo.pool.entry_currency === 'referral' && !enoughBalance ? 'hide' : ''
            }`}
            src={entriesInfo !== null ? flechasIcon : enoughBalance ? guardar : abonar}
          />
          <span>
            {entriesInfo
              ? 'VOLVER A REGISTRARME'
              : enoughBalance
              ? 'GUARDAR PICKS Y REGÍSTRAME'
              : !enoughBalance && poolInfo.pool.entry_currency === 'referral'
              ? 'INVITA A TUS AMIGOS'
              : 'ABONAR SALDO'}
          </span>
        </Button>
      );
    }
    conditionalComponent = (
      <div className="qI-buttons flex-col">
        {buttonsContent}
        <Button
          className="btn-lobby"
          bsStyle="default"
          disabled={!logged}
          onClick={(e) => {
            console.log('Redirect To Lobby');
            redirect();
          }}
        >
          <span style={{ padding: '0 10px' }}>IR A LOBBY DE QUINIELA</span>
        </Button>
      </div>
    );
  }

  return <div className="flex-row">{conditionalComponent}</div>;
};

/**
 * @param This Componoent show the steps guide of how to register and play a PoolList
 * @return Component
 */
const RegisterSteps = (props) => {
  let steps = null;
  if (!props.isBalanceEnough) {
    steps = (
      <div className="col-xs-12 steps-container">
        <div className="col-xs-4  register-step-item">
          <span className="step-number">1</span>
          <img src={props.isReferral ? invita : abonar} className="step-icon" />
          <span className="step-label">
            {props.isReferral ? 'Invita a tus amigos' : 'Abona saldo'}
          </span>
        </div>
        <div className="col-xs-4  register-step-item">
          <span className="step-number">2</span>
          <img src={picks} className="step-icon" />
          <span className="step-label">Llena tus picks</span>
        </div>
        <div className="col-xs-4  register-step-item">
          <span className="step-number">3</span>
          <img src={guardar} className="step-icon" />
          <span className="step-label">Guarda tu quiniela</span>
        </div>
      </div>
    );
  } else if (props.isBalanceEnough) {
    steps = (
      <div className="col-xs-12 steps-container">
        <div className="col-xs-6 col-md-6 register-step-item">
          <span className="step-number">1</span>
          <img src={picks} className="step-icon" />
          <span className="step-label">Llena tus picks</span>
        </div>
        <div className="col-xs-6 col-md-6 register-step-item">
          <span className="step-number">2</span>
          <img src={guardar} className="step-icon" />
          <span className="step-label">Guarda tu quiniela</span>
        </div>
      </div>
    );
  }

  let conditionalComponent = (
    <div id="registerSteps" className="row">
      <div className="col-xs-12 text-center register-title-container">
        <span className="register-title">
          <i className="ion-flag" />
          Sigue estos pasos para participar en la quiniela.
        </span>
      </div>
      {steps}
    </div>
  );
  return conditionalComponent;
};

const NotLoggedUserBar = ({ redirectLogin, redirectRegistro }) => {
  return (
    <div className="buttons-row">
      <Button
        onClick={() => {
          redirectLogin();
        }}
        data-id="login"
        className="btn-lobby-login"
        bsSize="small"
      >
        <i className="ion-log-in" /> <span data-id="login">INGRESAR</span>
      </Button>
      <Button
        onClick={() => {
          redirectRegistro();
        }}
        data-id="registro"
        className="btn-lobby-login"
        bsSize="small"
      >
        <span data-id="registro">CREAR CUENTA</span>
      </Button>
    </div>
  );
};

class QuinielaInfo extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Quinielas Info', this.props );
    this.state = {
      clipboardPicks: null,
      enoughBalance: false,
      entries: null,
      errorCopiar: null,
      entriesInfo: null,
      estadoDesempates: false,
      estadoPicks: false,
      estadoProps: false,
      estadoRegistro: false,
      fixtures: [],
      parley: [],
      firstFixture: null,
      fixtureProps: [],
      item: this.props.item[0].id,
      loaded: false,
      logged: this.props.logged,
      my_pools: null,
      picks: [],
      picks_props: [],
      poolInfo: null,
      submitting: false,
      premios: [],
      redirectLobby: false,
      redirectLogin: null,
      redirectWithEntry: false,
      showPanelRegister: false,
      showPanel: false,
      showAbonar: false,
      showTutorial: false,
      similarPools: [],
      posActualPage: 1,
      standings: [],
      temporalRegisterPicks: null,
      tieScoreAway: null,
      tieScoreHome: null,
      tiesData: [],
      userData: null,
      userBalance: null,
    };
    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      order: null,
    };

    // this.sendFullRegister = this.sendFullRegister.bind(this);
    this.confirmationCopy = this.confirmationCopy.bind(this);
    this.copiarPicks = Utils.copiarPicks.bind(this);
    this.estadoDesempates = Utils.estadoDesempates.bind(this);
    this.estadoPicks = Utils.estadoPicks.bind(this);
    this.estadoProps = Utils.estadoProps.bind(this);
    this.initializeTies = Utils.initializeTies.bind(this);
    this.getWinningPick = Utils.getWinningPick.bind(this);
    this.getWinningPickLive = Utils.getWinningPickLive.bind(this);
    this.getWinningProp = Utils.getWinningProp.bind(this);
    this.mapUnsavedPicks = Utils.mapUnsavedPicks.bind(this);
    this.redirect = this.redirect.bind(this);
    this.redirectLogin = this.redirectLogin.bind(this);
    this.redirectRegistro = this.redirectRegistro.bind(this);
    this.updatePicks = Utils.updatePicks.bind(this);
    this.updateTieData = Utils.updateTieData.bind(this);
    this.updateStandingsPropsPoints = Utils.updateStandingsPropsPoints.bind(this);
    this.updateStandingsAganar = Utils.updateStandingsAganar.bind(this);

    /**
     * This function updates the State
     * of the fixture thtat just started
     * @param fixtureID
     */
    this.updateStartedFixture = (fixtureID) => {
      console.log('updated started fixture', fixtureID, 'Started');
      let indexStartedFixture, indexStartedProps;
      let tempFixturesList = this.state.fixtures;
      let tempPropsList = null;

      if (this.state.poolInfo.pool.format === 'mixed') {
        tempPropsList = this.state.fixtureProps;
        let startedProps = tempPropsList.find((prop, i) => {
          if (prop.fixture_id === fixtureID) {
            indexStartedProps = i;
            return prop;
          }
        });
        if (!startedProps || !startedProps.enabled) {
          return;
        }
        console.log('Prop', startedProps);
        startedProps['enabled'] = false;
        startedProps.fixture.enabled = false;
        tempPropsList[indexStartedProps] = startedProps;
        this.setState({
          fixtureProps: tempPropsList,
          liveFixtureData: Date.now(),
        });
      }

      // console.log(this.state.fixtures);
      let startedFixt = tempFixturesList.find((fixt, i) => {
        if (fixt.id === fixtureID) {
          indexStartedFixture = i;
          return fixt;
        }
      });

      if (!startedFixt || !startedFixt.enabled) {
        return;
      }
      console.log('Fixt', startedFixt);
      startedFixt['sort_status'] = 0;
      startedFixt['enabled'] = false;
      tempFixturesList[indexStartedFixture] = startedFixt;
      this.setState({
        fixtures: tempFixturesList,
        liveFixtureData: Date.now(),
      });
    };

    this.redirectToMisQuinielas = () => {
      this.setState({ redirectToMisQuinielas: true });
    };

    this.showAbonar = () => {
      this.setState({ showAbonar: true });
    };
    this.hideAbonar = () => {
      this.setState({ showAbonar: false });
    };

    this.showTutorial = () => {
      this.setState({ showTutorial: true });
      console.log('Show Tutorial');
    };
    this.closeTutorial = () => {
      this.setState({ showTutorial: false });
    };

    this.updateRegisterPicks = (tempEntry) => {
      this.setState({ temporalRegisterPicks: tempEntry });
    };

    this.showPanelRegister = () => {
      this.setState({ showPanelRegister: true });
    };
    this.closePanelRegister = () => {
      this.setState({ showPanelRegister: false });
    };

    this.changeStatusPool = (poolId, fixtureID) => {
      let tempAvailablePools = this.state.poolInfo;
      console.log('changeStatus Pool', tempAvailablePools);
      console.log('changeStatus Pool', poolId, fixtureID);
      tempAvailablePools.pool.status = 'live';
      tempAvailablePools.pool.upcoming = false;
      tempAvailablePools.pool.time_left = 0;
      let tempFixtures = this.state.fixtures;

      if (fixtureID) {
        tempFixtures.forEach((f) => {
          if (f.id === fixtureID) {
            f.enabled = false;
          }
        });
      } else {
        tempFixtures[0].enabled = false;
      }
      // tempFixtures[0].sort_status = 0;
      let tempFixturesProps = [];
      this.state.fixtureProps.forEach((fixtProps) => {
        if (fixtProps.fixture_id === fixtureID) {
          fixtProps.enabled = false;
          fixtProps.fixture.enabled = false;
          fixtProps.fixture.time_left = 0;
          // fixtProps.sort_status = 0;
          tempFixturesProps.push(fixtProps);
        } else {
          tempFixturesProps.push(fixtProps);
        }
      });
      this.setState(
        {
          changePoolStatus: true,
          updatedPools: true,
          poolInfo: tempAvailablePools,
          fixtures: tempFixtures,
          liveFixturesData: Date.now(),
        },
        () => {
          this.setState({
            changePoolStatus: false,
            liveFixturesData: Date.now(),
          });
        }
      );
    };

    this.siguientePagPositions = this.siguientePagPositions.bind(this);

    this.changeSelectedWeek = (weekID) => {
      console.log("WEEKID:", weekID);
      if (this.state.poolInfo.pool.type === 'full') {
        this.setState({
          loadedPicks: false,
        });
        console.log('%c Changing Week', 'background:#7BD900;font-size:20px  ;');

        API.fetchWeekFixtures(this.state.poolInfo.pool.id, weekID).then((response) => {
          // console.log(weekID, response.data.fixtures)
          let currentWeek =
            this.state.poolWeeks.current.id === weekID
              ? this.state.poolWeeks.current
              : this.state.poolWeeks.weeks.find((w) => w.id === weekID);
          this.setState(
            {
              currentWeek: currentWeek,
              loadedPicks: true,
              poolWeekSelected: weekID,
              fixtures: response.data.fixtures,
              firstFixture: response.data.first,
              selectedWeekFixtures: response.data.fixtures.filter(
                (sel) => sel.week_id === this.state.poolWeekSelected
              ),
              // mounted: true
            },
            () => {
              if (
                this.state.picks.length > 0 ||
                this.state.picks_props.length > 0 ||
                this.state.tiesData.length > 0
              ) {
                console.log(' It has unsaved picks');
                // setTimeout(() => {
                //   this.mapUnsavedPicks(
                //     this.state.picks,
                //     this.state.picks_props,
                //     this.state.tiesData,
                //     this.state.poolInfo.tiebreakers,
                //     null
                //   );
                // }, 500);
              } else {
                console.log(' Dont has unsaved picks');
              }
            }
          );
        });
      }
    };
  }

  siguientePagPositions(eventKey) {
    let loaderElement = $('#loading');
    // console.log(loaderElement);
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex';
    }
    // loading.style.position('flex');
    let temporalPositions = this.state.standings.items;
    console.log('siguiente');
    let val = eventKey;
    console.log(this.queryParams.page, this.state.posActualPage);
    this.queryParams.page = this.state.posActualPage + 1;
    if (this.queryParams.page > this.state.standings.pages) {
      return;
    }
    console.log('Next Positions...');
    API.fetchStandings(this.state.poolInfo.pool.id, this.queryParams).then((response) => {
      if (!response || !response.data || !response.data.items) {
        return;
      }
      this.setState(
        (prevState) => {
          // console.log(response.data);
          let newPosiciones = response.data;
          let newItems = response.data.items !== null ? response.data.items : null;
          newItems.forEach((item) => {
            temporalPositions.push(item);
          });
          console.log('NEWPos', temporalPositions);
          newPosiciones.items = temporalPositions;
          return {
            standings: newPosiciones,
            posActualPage: prevState.posActualPage + 1,
          };
        },
        () => {
          if (loaderElement !== undefined && loaderElement.length > 0) {
            loaderElement[0].style.display = 'none';
            let avatars = $('.score-avatars').height('auto');
          }
        }
      );
    });
  }

  /**
   * This function handles the overlay behaviour
   * affter the user copies picks from other entry
   */
  confirmationCopy() {
    console.log('handleConfirmationCopy');
    setTimeout(() => {
      this.setState({
        errorCopiar: null,
      });
    }, 1800);
    // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function redirects to the register page
   * by changing the state of the component
   */
  redirectRegistro() {
    // console.log('Redirection');
    this.setState({
      redirectRegistro: true,
    });
  }
  /**
   * This function redirects to the login page
   * by changing the state of the component
   */
  redirectLWithEntry() {
    // console.log('Redirection');
    this.setState({
      redirectWithEntry: true,
    });
  }

  /**
   * This function redirects to the login page
   * by changing the state of the component
   */
  redirectLogin() {
    // console.log('Redirection');
    this.setState({
      redirectLogin: true,
    });
  }

  /**
   * This function redirects to the lobby
   * by changing the state of the component
   */
  redirect() {
    // console.log('Redirection');
    this.setState({
      redirectLobby: true,
    });
  }

  /*********************
   * Lifecycle Methods
   *********************/

  componentDidMount() {
    // const that = this;
    const which = this.state.item;
    let entries_;
    let my_pools_;
    let poolI_;
    let premios_;
    let user_;
    let fixtures_;
    let parley_;
    let firstFixture_;
    let similar_;
    let standings_;
    let entriesInfo_;
    let similarPools_;
    let poolWeeks_;
    let tickets_;

    let showOrganicQuinielaTutorial = utils.getShowTutorialLobby();
    console.log(showOrganicQuinielaTutorial);
    if (showOrganicQuinielaTutorial && showOrganicQuinielaTutorial.step !== 3) {
      // this.props.handleTutorial('organic', true , 0);
      this.props.handleTutorialStep(showOrganicQuinielaTutorial.step, 'organic', true);
    }

    // console.log('Loggeado?',this.props.item[0]);

    if (this.props.logged) {
      /**
       * WITH ENTRIES
       */
      if (this.props.item[0].hasOwnProperty('entries') && this.props.item[0].entries.length > 0) {
        console.log('Available Entries');

        // Si tiene Entradas en esta Quiniela
        axios
          .all([
            API.fetchIndividualPool(which),
            API.fetchPoolPrizes(which),
            API.fetchUserData(),
            API.fetchFixtures(which),
            API.fetchSimilarPools(which),
            API.fetchStandings(which, this.queryParams),
            API.getEntriesInfo(this.props.item[0].entries[0].id),
            API.fetchWeek(this.props.item[0].id),
            API.fetchTickets(this.props.item[0].id, 'pool'),
          ])
          .then(
            axios.spread(
              (
                individual,
                prizes,
                usr,
                fixtures,
                similar,
                standings,
                entries,
                entriesInfo,
                poolWeeks,
                tickets
              ) => {
                const responses = [
                  individual,
                  prizes,
                  usr,
                  fixtures,
                  similar,
                  standings,
                  entries,
                  entriesInfo,
                  poolWeeks,
                  tickets,
                ];
                try {
                  utils.checkAxiosAllErrors(responses);
                } catch (e) {
                  console.error(e);
                  Sentry.captureException(e);
                }

                poolI_ = individual.data;
                premios_ = prizes.data;
                user_ = usr.data;
                fixtures_ = fixtures.data.fixtures;
                parley_ = fixtures.data.parlay_blocks;
                firstFixture_ = fixtures.data.first;
                similar_ = similar.data;
                standings_ = standings.data;
                entries_ = entries.data.entries;
                my_pools_ = entries.data.my_pools;
                entriesInfo_ = entriesInfo.data;
                tickets_ = tickets.data.tickets;
                poolWeeks_ = poolWeeks.data;
                // console.log(this);
                console.log('%c Recieving QI Data...', 'font-size:20px');
                console.log(tickets_);
                utils.setUserInfo(usr.data);
                this.props.updateUserInfo(usr.data);

                /**
                 * Check if the game has started
                 */
                if (poolI_.pool.status !== 'upcoming') {
                  this.setState({ redirectLobby: true, poolInfo: poolI_ });
                }

                this.setState(
                  () => {
                    let tempProps = [];
                    /**
                     * List of individual Props to handle in main state
                     */
                    // console.log(fixtures_);
                    fixtures_.forEach((fixture) => {
                      fixture.props.map((prop) => {
                        if (poolI_.pool.format === 'mixed' && fixture.props) {
                          let tempObj = prop;
                          tempObj['fixture'] = {
                            id: fixture.id,
                            sort_status: fixture.sort_status,
                            time_left: fixture.time_left,
                            enabled: fixture.enabled,
                            deadline: fixture.deadline,
                            home_team: {
                              id: fixture.home_team_id,
                              abbreviation: fixture.home_team.abbreviation,
                            },
                            away_team: {
                              id: fixture.away_team_id,
                              abbreviation: fixture.away_team.abbreviation,
                            },
                          };
                          tempObj['enabled'] = fixture.enabled;
                          tempProps.push(tempObj);
                        }
                      });
                    });

                    let balanceEnough = utils.isBalanceEnough(
                      poolI_.pool.entry_currency,
                      poolI_.pool.entry_fee
                    );
                    console.log(premios_);
                    return {
                      // currentWeek: poolWeeks_.current,
                      enoughBalance: !balanceEnough && tickets_.length,
                      poolInfo: poolI_,
                      premios: premios_,
                      userData: user_,
                      fixtures: fixtures_,
                      parley: parley_,
                      fixtureProps: tempProps,
                      similar: similar_,
                      standings: standings_,
                      entries: entries_,
                      my_pools: my_pools_,
                      entriesInfoTemp: entriesInfo_,
                      similarPools: similar_,
                      tickets: tickets_,
                      poolWeeks: poolWeeks_,
                      poolWeekSelected: poolI_.pool.week_id,
                      logged: this.props.logged,
                      loaded: true,
                    };
                  },
                  () => {
                    // this.setState({ tiesPicks: this.initializeTies(this.props.poolInfo, null), });
                  }
                );
              }
            )
          )
          .catch((error) => {
            Sentry.captureException(error);
          });
      } else {
        //  no tiene entradas
        // console.log('No Entries Available');
        let result = axios
          .all([
            API.fetchIndividualPool(which),
            API.fetchPoolPrizes(which),
            API.fetchUserData(),
            API.fetchFixtures(which),
            API.fetchSimilarPools(which),
            API.fetchStandings(which, this.queryParams),
            API.fetchWeek(this.props.item[0].id),
            API.fetchTickets(this.props.item[0].id, 'pool'),
          ])
          .then(
            axios.spread(
              (individual, prizes, usr, fixtures, similar, standings, poolWeeks, tickets) => {
                const responses = [
                  individual,
                  prizes,
                  usr,
                  fixtures,
                  similar,
                  standings,
                  poolWeeks,
                  tickets,
                ];
                try {
                  utils.checkAxiosAllErrors(responses);
                } catch (e) {
                  console.error(e);
                  Sentry.captureException(e);
                }

                poolI_ = individual.data;
                premios_ = prizes.data;
                user_ = usr.data;
                fixtures_ = fixtures.data.fixtures;
                parley_ = fixtures.data.parlay_blocks;
                firstFixture_ = fixtures.data.first;
                similar_ = similar.data;
                standings_ = standings.data;
                poolWeeks_ = poolWeeks.data;
                tickets_ = tickets.data.tickets;
                // console.log(poolWeeks_);
                // console.log('%c Recieving QI Data...', 'font-size:20px');
                // console.log(tickets_);

                utils.setUserInfo(usr.data);
                this.props.updateUserInfo(usr.data);

                /**
                 * Check if the game has started
                 */
                if (poolI_.pool.status !== 'upcoming') {
                  this.setState({ redirectLobby: true, poolInfo: poolI_ });
                }

                this.setState(
                  () => {
                    let tempProps = [];
                    /**
                     * List of individual Props to handle in main state
                     */
                    // console.log(fixtures_);
                    fixtures_.forEach((fixture) => {
                      if (poolI_.pool.format === 'mixed' && fixture.props) {
                        fixture.props.map((prop) => {
                          let tempObj = prop;
                          tempObj['fixture'] = {
                            id: fixture.id,
                            sort_status: fixture.sort_status,
                            time_left: fixture.time_left,
                            enabled: fixture.enabled,
                            deadline: fixture.deadline,
                            home_team: {
                              id: fixture.home_team_id,
                              abbreviation: fixture.home_team.abbreviation,
                            },
                            away_team: {
                              id: fixture.away_team_id,
                              abbreviation: fixture.away_team.abbreviation,
                            },
                          };
                          tempObj['enabled'] = fixture.enabled;
                          tempProps.push(tempObj);
                        });
                      }
                    });
                    let balanceEnough = utils.isBalanceEnough(
                      poolI_.pool.entry_currency,
                      poolI_.pool.entry_fee
                    );

                    return {
                      enoughBalance: !balanceEnough && !tickets_.length ? false : true,
                      poolInfo: poolI_,
                      premios: premios_,
                      userData: user_,
                      fixtureProps: tempProps,
                      fixtures: fixtures_,
                      parley: parley_,
                      firstFixture: firstFixture_,
                      similarPools: similar_,
                      currentWeek: poolWeeks_.current,
                      standings: standings_,
                      poolWeeks: poolWeeks_,
                      poolWeekSelected: poolI_.pool.week_id === null ? poolWeeks_.current.id : poolI_.pool.week_id,
                      logged: this.props.logged,
                      loaded: true,
                    };
                  },
                  () => {
                    // this.setState({ tiesPicks: this.initializeTies(this.props.poolInfo, null), });
                  }
                );
              }
            )
          )
          .catch((error) => {
            console.error('error in axios.all Quiniela Popup', error);
            Sentry.captureException(error);
          });
      }
    } else {
      /*************
       * NOT LOGGED
       ************/
      //Not Logged
      let result = axios
        .all([
          API.fetchIndividualPool(which),
          API.fetchPoolPrizes(which),
          API.fetchFixtures(which),
          API.fetchStandings(which, this.queryParams),
          API.fetchWeek(this.props.item[0].id),
        ])
        .then(
          axios.spread((individual, prizes, fixtures, standings, poolWeeks) => {
            const responses = [individual, prizes, fixtures, standings, poolWeeks];
            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }

            poolI_ = individual.data;
            premios_ = prizes.data;
            fixtures_ = fixtures.data.fixtures;
            parley_ = fixtures.data.parlay_blocks;
            firstFixture_ = fixtures.data.first;
            standings_ = standings.data;
            poolWeeks_ = poolWeeks.data;

            this.setState(
              () => {
                let tempProps = [];
                fixtures_.forEach((fixture) => {
                  if (poolI_.pool.format === 'mixed' && fixture.props) {
                    fixture.props.map((prop) => {
                      let tempObj = prop;
                      tempObj['fixture'] = {
                        id: fixture.id,
                        sort_status: fixture.sort_status,
                        time_left: fixture.time_left,
                        enabled: fixture.enabled,
                        deadline: fixture.deadline,
                        home_team: {
                          id: fixture.home_team_id,
                          abbreviation: fixture.home_team.abbreviation,
                        },
                        away_team: {
                          id: fixture.away_team_id,
                          abbreviation: fixture.away_team.abbreviation,
                        },
                      };
                      tempObj['enabled'] = fixture.enabled;
                      tempProps.push(tempObj);
                    });
                  }
                });

                history.push(`/lobby/quinielaPop/${poolI_.pool.slug}/${poolI_.pool.id}`);
                let fakeMatch = {
                  isExact: true,
                  params: { slug: poolI_.pool.slug, poolId: poolI_.pool.id },
                  path: `/lobby/quinielaPop/:slug/:poolId`,
                  url: `/lobby/quinielaPop/${poolI_.pool.slug}/${poolI_.pool.id}`,
                };
                gaUtils.handleDocumentAttributes(fakeMatch);

                let redirObj;
                redirObj = { id: poolI_.pool.id, game_type: 'pool' };
                utils.saveDataNotLoggedUser(redirObj);

                return {
                  poolInfo: poolI_,
                  premios: premios_,
                  fixtureProps: tempProps,
                  fixtures: fixtures_,
                  parley: parley_,
                  firstFixture: firstFixture_,
                  standings: standings_,
                  poolWeeks: poolWeeks_,
                  poolWeekSelected: poolWeeks_.current.id,
                  currentWeek: poolWeeks_.current,
                  loaded: true,
                };
              },
              () => {
                // this.setState({ tiesPicks: this.initializeTies(this.props.poolInfo, null), });
              }
            );
            // console.log('Component Mounted',this.state);
          })
        )
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userData &&
      this.state.userData &&
      this.props.userBalance &&
      this.props.userBalance.balanceReal !== prevProps.userBalance.balanceReal
    ) {
      console.log('Update User Data');
      this.setState({
        userData: this.props.userData,
        userBalance: this.props.userBalance,
        enoughBalance: utils.isBalanceEnough(
          this.state.poolInfo.pool.entry_currency,
          this.state.poolInfo.pool.entry_fee
        ),
      });
    }

    if (
      this.props.userData &&
      !prevProps.userData &&
      this.state.poolInfo &&
      this.state.poolInfo.hasOwnProperty('pool')
    ) {
      console.log('user Logged In');
      const balanceObj = utils.getUserBalance();
      console.log(balanceObj);
      this.setState({
        logged: utils.isLogged(),
        userData: this.props.userData,
        userBalance: balanceObj,
        enoughBalance: utils.isBalanceEnough(
          this.state.poolInfo.pool.entry_currency,
          this.state.poolInfo.pool.entry_fee
        ),
      });
    }
  }

  render() {
    let conditionalComponent;

    /*****************************
     * Redirect to QuinielaLobby
     ****************************/
    if (this.state.redirectLobby) {
      let nameQuina = this.state.poolInfo.pool.slug;
      console.log('Redirecting...', this.state, nameQuina);

      conditionalComponent = (
        <div className="panel-pickwin">
          <Loading label="Te estamos redirigiendo al lobby..." />
          <Redirect
            push
            to={{
              pathname: `/quiniela/${nameQuina}/${this.state.poolInfo.pool.id}`,
            }}
          />
        </div>
      );
    } else if (this.state.redirectRegistro) {
      /*****************************
       * Redirect to Registro
       ****************************/
      let url = 'registrate';
      // console.log('Registrate');
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: url,
          }}
        />
      );
    } else {
      /*****************************
       * Default Render
       ****************************/
      // console.log('Not Redirecting...');
      if (this.state.loaded) {
        // console.log(this.state.poolInfo.pool.slug);
        let nameQuina = this.state.poolInfo.pool.slug;
        // console.log('LOADED...');
        conditionalComponent = (
          <QuinielaDataProvider
            enoughBalance={this.state.enoughBalance}
            entriesInfo={this.state.allEntries}
            fixtureProps={this.state.fixtureProps}
            fixtures={this.state.fixtures}
            parley={this.state.parley}
            firstFixture={this.state.firstFixture}
            premios={this.state.premios}
            poolInfo={this.state.poolInfo}
            poolWeeks={this.state.poolWeeks}
            poolWeekSelected={this.state.poolWeekSelected}
            userData={this.state.userData}
            userBalance={this.props.userBalance}
            standings={this.state.standings}
            updateUserInfo={this.props.updateUserInfo}
          >
            <Modal
              show={this.state.submitting}
              className="responseDepositModal absoluteModal"
              dialogClassName="responseDepositDialog"
              backdropClassName="responseDepositBackdrop"
            >
              <div className="flex-container justify align column">
                <i className="ion-alert-circled alert"></i>

                <div className="flex-row row-center">
                  <div className="flex-col col-center text-center">
                    <strong>'Espera un momento porfavor...'</strong>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="quiniela-info-container">
              <div className="quiniela-container">
                {utils.isLogged() ? null : (
                  <NotLoggedUserBar
                    redirectLogin={this.props.showLoginPopUp}
                    redirectRegistro={this.props.showRegisterPopUp}
                  />
                )}
                <RegisterSteps
                  isReferral={this.state.poolInfo.pool.entry_currency === 'referral'}
                  isBalanceEnough={this.state.enoughBalance}
                />
                {/* Los botones cambian de lugar, aqui aparecen si son mas de 4 jornadas */}
                {/* Esto se renderiza cuando la quiniela esta (live | upcoming) */}
                {(this.state.poolInfo.pool.type === 'full' && this.state?.poolWeeks?.weeks > 4) ? (
                  <WeeksControls
                    label="Selecciona una jornada"
                    currentWeek={this.state.poolWeeks.current.id}
                    selectedWeek={
                      !this.state.poolWeekSelected
                        ? this.state.poolWeeks.current.id
                        : this.state.poolWeekSelected
                    }
                    weeks={this.state.poolWeeks.weeks}
                    handleWeekChange={this.changeSelectedWeek}
                  />
                ) : null}
                <div className="quinielas-info flex-row">
                  <div className="flex-col">
                    <div id="picksQuiniela" className="pool-info col-sm-12 col-md-5">
                      {/* Popup */}
                      <QuinielaPicksUI
                          handleTutorial={this.props.handleTutorial}
                          handleTutorialStep={this.props.handleTutorialStep}
                          handleAbonar={this.showAbonar}
                          currentWeek={this.state.currentWeek}
                          enoughBalance={this.state.enoughBalance}
                          entriesInfo={this.state.entriesInfo}
                          entryInfo={null}
                          fixtureProps={this.state.fixtureProps}
                          fixtures={this.state.fixtures}
                          parley={this.state.parley}
                          firstFixture={this.state.firstFixture}
                          poolInfo={this.state.poolInfo}
                          poolWeeks={this.state.poolWeeks.weeks}
                          similarPools={this.state.similarPools}
                          tiebreakers={this.state.tiebreakers}
                          updateStartedFixture={this.changeStatusPool}
                          updateRegisterPicks={this.updateRegisterPicks}
                          updateUserInfo={this.props.updateUserInfo}

                          // Prop drilling <WeeksControls />
                          weeks={this.state.poolWeeks.weeks}
                          handleWeekChange={this.changeSelectedWeek}
                          selectedWeek={
                            !this.state.poolWeekSelected
                              ? this.state.poolWeeks.current.id
                              : this.state.poolWeekSelected
                          }
                      />
                    </div>
                    <div className="second-panel col-sm-12 col-md-7">
                      <div className="flex-row responsive-sm row-no-padding" style={{marginTop: '7px'}}>
                        <div
                          id="premiosQuiniela"
                          className={`popup ${
                            this.state.poolInfo.pool.closed
                              ? this.state.entriesInfo === null
                                ? 'col-md-6 col-xs-12 col-sm-6'
                                : 'col-md-8 col-xs-12 col-sm-6'
                              : 'col-md-8 col-xs-12 col-sm-6'
                          }
                            `}
                        >
                          <div className="flex-col col-no-padding">
                            <div className="panel-pickwin border-gradient-modal">
                              <div className='backBlack'>
                                <Premios
                                  weeks={this.state.poolWeeks}
                                  estado={this.state.loaded}
                                  loaded={this.state.loaded}
                                  poolInfo={this.state.poolInfo}
                                  premios={this.state.premios}
                                  standGroups={this.state.standGroups}
                                  standings={this.state.standings}
                                  type="popup"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="participantesQuiniela"
                          className={`popup ${
                            this.state.poolInfo.pool.closed
                              ? this.state.entriesInfo === null
                                ? 'col-md-6 col-xs-12 col-sm-6'
                                : 'col-md-4 col-xs-12 col-sm-6'
                              : 'col-md-4 col-xs-12 col-sm-6'
                          }
                            `}
                        >
                          <div className="flex-container panel-pickwin border-gradient-modal">
                            <div className='backBlack'>
                              <div className="flex-row title-quiniela">
                                <div className="title-wrapper">
                                  <img src={participantes} className="pool-icons tablas" />
                                  <span className='font-chivo'>Participantes</span>
                                </div>
                              </div>
                              <Participantes
                                users={this.state.standings}
                                type="pool"
                                siguientePagPositions={this.siguientePagPositions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {!this.state.logged ? null : (
                          <div className="qI-buttons col-xs-12">
                            {this.state.poolInfo !== null ? (
                              this.state.poolInfo.pool.closed ? null : this.state.poolInfo.pool
                                  .status === 'live' ? null : this.state.poolInfo.entries !== 0 ? (
                                <Button
                                  className="guardar-picks callToAction"
                                  bsStyle="success"
                                  disabled={
                                    utils.isLogged()
                                      ? this.state.poolInfo.pool.is_full || utils.isSuPick()
                                        ? true
                                        : false
                                      : true
                                  }
                                  onClick={(e) => {
                                    if (
                                      this.state.poolInfo.pool.entry_currency === 'referral' &&
                                      !this.state.enoughBalance
                                    ) {
                                      window.location = '/usuario/invitados';
                                    } else {
                                      if (this.state.enoughBalance) {
                                        // this.showPanelRegister();
                                        this.props.openPanel(e, this.state.poolInfo.pool);
                                        this.props.updateRegisterPicks(
                                          this.state.temporalRegisterPicks
                                        );
                                      } else {
                                        this.showAbonar();
                                      }
                                    }
                                  }}
                                >
                                  <img
                                    src={
                                      this.state.entriesInfo !== null
                                        ? flechasIcon
                                        : this.state.enoughBalance
                                        ? guardar
                                        : abonar
                                    }
                                    className={`pool-icons ${
                                      this.state.poolInfo.pool.entry_currency === 'referral' &&
                                      !this.state.enoughBalance
                                        ? 'hide'
                                        : ''
                                    }`}
                                  />
                                  <span>
                                    {this.state.entriesInfo !== null
                                      ? 'VOLVER A REGISTRARME'
                                      : this.state.enoughBalance
                                      ? 'GUARDAR PICKS Y REGÍSTRAME'
                                      : !this.state.enoughBalance &&
                                        this.state.poolInfo.pool.entry_currency === 'referral'
                                      ? 'INVITA A TUS AMIGOS '
                                      : 'ABONAR SALDO'}
                                  </span>
                                </Button>
                              ) : null
                            ) : null}
                            <Button
                              className="btn-lobby"
                              bsStyle="default"
                              disabled={!this.state.logged}
                              onClick={(e) => {
                                console.log('Redirect To Lobby');
                                this.redirect();
                                let isOrganicFlow = utils.getShowTutorialLobby();
                                console.log(isOrganicFlow);
                                if (
                                  this.props.handleTutorialStep &&
                                  isOrganicFlow &&
                                  isOrganicFlow.step === 3 &&
                                  !isOrganicFlow.show
                                ) {
                                  this.props.handleTutorialStep(3, 'organic', false);
                                  utils.removeTutorialLobby();
                                }
                              }}
                            >
                              <span style={{ padding: '0 10px' }}>IR A LOBBY DE QUINIELA</span>
                            </Button>
                          </div>
                        )}
                      </div>
                      <MediaQuery minWidth={769}>
                        {this.state.poolInfo.pool.sport_id === 1 ? (
                          <div className="nota-mis-picks ">
                            <i className="ion-ios-stopwatch" />
                            <span>
                              Recuerda que únicamente se tomarán los resultados del tiempo
                              reglamentario.
                            </span>
                          </div>
                        ) : null}
                        {this.state.poolInfo.pool.point_format === 'line' ? (
                          <div className="nota-mis-picks">
                            <i className="ion-alert-circled" />
                            <span>
                              Las líneas de los partidos no cambiaran una vez que se han publicado.
                              Aquellas líneas que no aparezcan publicadas podrán ser publicadas
                              hasta 5 minutos antes de que comience el partido correspondiente.
                            </span>
                          </div>
                        ) : null}
                      </MediaQuery>
                    </div>
                    <MediaQuery maxWidth={768}>
                      {this.state.poolInfo.pool.sport_id === '1' ? (
                        <div className="nota-mis-picks hidden-md">
                          <i className="ion-ios-stopwatch" />
                          <span>
                            Recuerda que únicamente se tomarán los resultados del tiempo
                            reglamentario.
                          </span>
                        </div>
                      ) : null}
                      {this.state.poolInfo.pool.point_format === 'line' ? (
                        <div className="nota-mis-picks">
                          <i className="ion-alert-circled" />
                          <span>
                            Las líneas de los partidos no cambiaran una vez que se han publicado.
                            Aquellas líneas que no aparezcan publicadas podrán ser publicadas hasta
                            5 minutos antes de que comience el partido correspondiente.
                          </span>
                        </div>
                      ) : null}
                    </MediaQuery>
                  </div>
                </div>
              </div>
              <RegisterPanel
                handleTutorial={this.props.handleTutorial}
                handleTutorialStep={this.props.handleTutorialStep}
                closePanel={this.closePanelRegister}
                entryId={null}
                logged={this.state.logged}
                picks={this.state.picks}
                pool={this.state.poolInfo.pool}
                popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                props={this.state.picks_props}
                quinaID={this.state.item}
                redirectToMisQuinielas={this.redirectToMisQuinielas}
                showPanel={this.state.showPanelRegister}
                showTutorial={this.showTutorial}
                temporalEntry={this.state.temporalRegisterPicks}
                registerPicks={this.state.temporalRegisterPicks}
                tiebreakers={this.state.tiesData}
                updateStateFunc={this.closePanelRegister}
                updateUserInfo={this.props.updateUserInfo}
                userData={utils.getUserInfo()}
              />
              <AbonarSaldo
                type="modal"
                showAbonarSaldo={this.state.showAbonar}
                updatePanelSate={this.hideAbonar}
                showAbonarPop={this.showAbonar}
                updateUserBalance={this.props.updateUserBalance}
                updateUserInfo={this.props.updateUserInfo}
              />
            </div>
          </QuinielaDataProvider>
        );
      } else {
        // console.log('NOT LOADED...');
        conditionalComponent = <Loading label="..." />;
      }
    }
    return conditionalComponent;
  }
}
export default QuinielaInfo;
