import React, { useState, useRef, useEffect, forwardRef, useLayoutEffect } from 'react'
import styled from 'styled-components'

// Components
import { Typography, Box, MultiOption } from '../../../Xmerald-UI'
import WizardButtons from './WizardButtons'

// Store
import { useWizardPrivate } from '../wizardPrivate.store'

// Utils
import API from '../../../utils/api'

export default function Search () {
  const step = useWizardPrivate(state => state.step)
  const nameRef = useRef()

  const [activeSearch, setActiveSearch] = useState()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const [data, setData] = useState({
    name: '',
    password: ''
  })

  useEffect(() => {
    setData({ name: '', password: '' })
    setActiveSearch(null)
  }, [step])

  const handleInput = (newValue) => {
    setData(state => ({ ...state, ...newValue }))
  }

  useLayoutEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus()
    }
  }, [nameRef.current, activeSearch])

  const search = async () => {
    setLoading(true)
    setErrorMessage('')

    const serchPromise = activeSearch === 'quiniela'
      ? API.postSearchPrivate
      : API.postSearchPrivateSurvivor

    try {
      const { data: responseData } = await serchPromise(data)

      if (responseData.success) {
        window.location = `/${responseData.pool.game_type === 'pool' ? 'quiniela' : 'survivor'}/${
          responseData.pool.slug
        }/${responseData.pool.id}/${!responseData.pool.first_entry ? '' : responseData.pool.first_entry.id}`
      } else {
        setErrorMessage(responseData.error)
      }
    } catch (error) {}

    setLoading(false)
  }

  if (step !== 'SEARCH') return null

  const disabledNext = (data.name.length === 0 || data.password.length === 0)

  return (
    <Box maxWidth={300}>

      <MultiOption
        onChange={({ active }) => setActiveSearch(active)}
        keyName='active'
        title='¿Que tipo de juego quieres buscar?'
        value={activeSearch}
        options={[
          { name: 'Quiniela', value: 'quiniela' },
          { name: 'Survivor', value: 'survivor' }
        ]}
      />
      {activeSearch && (
        <Box mt={20} mb={50} mx='auto' maxWidth={250}>
          <TextField
            name='name'
            label={'Nombre de ' + activeSearch}
            value={data.name}
            onChange={handleInput}
            ref={nameRef}
          />
          <TextField
            name='password'
            value={data.password}
            label={'Contrasena ' + activeSearch}
            onChange={handleInput}
          />

          {loading && (
            <Typography center color='#ffffff'>Buscando {activeSearch}</Typography>
          )}

          {errorMessage && (
            <Typography center color='red'>{errorMessage}</Typography>
          )}
        </Box>
      )}

      <Box mt={30}>
        <WizardButtons
          prevStep='WELCOME'
          disabledNext={disabledNext}
          onClickNext={search}
          loadingNext={loading}
        />
      </Box>
    </Box>
  )
}

const TextField = forwardRef((props, ref) => {
  const handleChange = (event) => {
    const text = event.target.value

    props.onChange({ [props.name]: text })
  }

  const id = `${props.name}-id`
  return (
    <InputBox>
      <Label htmlFor={id}>{props.label}</Label>
      <Input name={props.name} onChange={handleChange} value={props.value} ref={ref} autoComplete='off' id={id} />
    </InputBox>
  )
})

const InputBox = styled.div`
  width: 100%;
  margin-bottom: 1em;
`

const Input = styled.input`
  width: 100%;
  padding: 0 0.5em;
  border-radius: 0.3em;
  color: #000;
  outline: 0;
  border: 0;
  box-shadow: 0px 0px 0px 0px #6abd0b00;
  transition: box-shadow 300ms linear;
  will-change: box-shadow;
  line-height: 2.3;
  
  &:focus {
    box-shadow: 0px 0px 1px 4px #78bc28 ;
  }
`

const Label = styled.label`
  line-height: 2.1;
  cursor: pointer;
`
