import React from 'react';
import $ from 'jquery';
import _ from 'underscore';
import moment from 'moment';
// import PanZoomElement from 'react-pan-zoom-element';
import { Button, Nav, NavItem } from 'react-bootstrap';
// import ionic from '../../utils/ionic'

import RegisterPanel from '../RegisterPanel';
import AbonarSaldo from '../AbonarSaldo/AbonarSaldo';
import Loading from '../Loading';

import SelectGroup from './SelectGroup';
import utils from '../../utils/utils';
import bracketUtils from '../../utils/bracketUtils';
import guardar from '../../assets/images/guardarPicks-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';

const RoundsMobileControls = ({ stages, activeRound, changeRound }) => {
  let listRounds = stages.map((stage, roundIdx) => {
    // if(round === 'Grupos'){return;}
    return (
      <NavItem key={stage.id} eventKey={roundIdx}>
        {stage.short_name}
      </NavItem>
    );
  });
  return (
    <div className="flex-col visible-xs">
      <Nav
        bsStyle="pills"
        id="stages-buttons"
        className="rounds-controls hidden-md"
        activeKey={activeRound}
        onSelect={changeRound}
      >
        {listRounds}
      </Nav>
    </div>
  );
};

const ListTeams = ({
  bracketStatus,
  placeholder,
  teams,
  matches,
  isTheLastItem,
  selectedTeams,
  grupoShortName,
  grupoId,
  groups,
  selectionType,
  val,
  handleSelectTeam,
  handleWinnerMatchSelector,
}) => {
  let currentTeam = placeholder;
  let selectedT = null;
  let keyMatch = `${selectionType === 'first' ? '1' + grupoShortName : '2' + grupoShortName}`;
  // console.log('Match', matches);
  let matchId = matches.find((match) => {
    // console.log('matchID', match.number , keyMatch);
    if (match.number === keyMatch) {
      return match;
    }
  });
  // console.log('Exist matchID', matchId);

  if (val !== null) {
    let group = groups.find((group) => group.id === grupoId);
    selectedT = group.teams.find((team) => {
      if (team.id === val) {
        return team;
      }
    });
    currentTeam = selectedT.name;
  }

  let listOfTeams = teams.filter((team, teamIdx) => {
    // console.log('%c Team','color:rgb(118, 171, 230); font-size:1.5em;');
    // console.log(team.selected , selectionType);
    let isDisabled = false;
    if (selectionType === 'first') {
      if (team.selected === 2) {
        isDisabled = true;
      }
    } else if (selectionType === 'second') {
      if (team.selected === 1) {
        isDisabled = true;
      }
    }
    // console.log( team.name, isDisabled );
    if (isDisabled) {
      team['disabled'] = true;
    } else {
      team['disabled'] = false;
    }
    return team;
  });

  listOfTeams = listOfTeams.map((team, i) => {
    return {
      value: {
        teamID: team.id,
        groupID: grupoId,
        groupShortName: grupoShortName,
        selectionType: selectionType,
        disabled: team.disabled,
        matchId: matchId.id,
      },
      label: team.name,
    };
  });
  // console.log(listOfTeams);
  let conditionalComponent = null;

  if (isTheLastItem) {
    // console.log('title', );
    conditionalComponent = (
      <div className="select-up">
        <SelectGroup
          bracketStatus={bracketStatus}
          data-group-id={grupoId}
          data-group-short={grupoShortName}
          searchable={false}
          placeholder={currentTeam}
          name="form-field-name"
          value={currentTeam}
          onChange={handleSelectTeam}
          options={listOfTeams}
          openOnFocus={true}
          scrollMenuIntoView={false}
        />
      </div>
    );
  } else {
    conditionalComponent = (
      <SelectGroup
        bracketStatus={bracketStatus}
        data-group-id={grupoId}
        data-group-short={grupoShortName}
        searchable={false}
        placeholder={currentTeam}
        name="form-field-name"
        value={currentTeam}
        onChange={handleSelectTeam}
        options={listOfTeams}
        openOnFocus={true}
        scrollMenuIntoView={false}
      />
    );
  }
  return conditionalComponent;
};

const SavedPicksOverlay = ({ showOverlay, label, currentSavedPicks, allPicks }) => {
  let picksStatus = null;
  let iconStatus = null;

  if (currentSavedPicks === 0) {
    picksStatus = `No tienes ningún picks guardado. Recuerda ingresar y guardar todos tus picks. `;
  } else {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          {' '}
          <strong className="title">¡Picks Guardados!</strong>
        </div>
        <div className="flex-container justify">
          {' '}
          Tienes {currentSavedPicks}/{allPicks} picks guardados.{' '}
        </div>
        <div className="flex-container justify">Recuerda ingresar y guardar todos tus picks. </div>
      </div>
    );
  }

  /**
   * Incomplete Picks
   **/
  if (currentSavedPicks !== allPicks) {
    iconStatus = <i className="ion-alert-circled" />;
  } else {
    /**
     * Complete Picks
     **/
    picksStatus = `¡Felicidades tus picks están completos!`;
    iconStatus = <i className="ion-checkmark-circled" />;
  }
  return (
    <div className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}`}>
      <span>
        {' '}
        {iconStatus} {picksStatus}{' '}
      </span>
    </div>
  );
};

const SelectTeams = ({
  bracketStatus,
  groups,
  matches,
  selectedTeams,
  handleSelectTeam,
  picks,
}) => {
  let matchesValues = _.values(matches);
  // allMatches = allMatches.map()
  let allMatches = [];
  matchesValues.forEach((matchVal) => {
    // console.log('match', matchVal);
    matchVal.forEach((matchV) => {
      allMatches.push(matchV);
    });
  });

  // console.log(allMatches);
  let selectGroupWinners = groups.map((grupo, groupIndex) => {
    // console.log(selectedTeams[grupo.id]['first']);
    // console.log( picks );
    const isTheLastItem = groupIndex === groups.length - 1;
    return (
      <div key={groupIndex} className="flex-row row-center match-container group-section">
        <div className="flex-col bracket-match-container">
          <span className="flex-row bracket-title">
            <div className="flex-col">{grupo.name}</div>
          </span>
          <ListTeams
            bracketStatus={bracketStatus}
            placeholder={`1° Lugar del Grupo`}
            teams={grupo.teams}
            isTheLastItem={isTheLastItem}
            matches={allMatches}
            selectedTeams={selectedTeams}
            grupoShortName={grupo.short_name}
            grupoId={grupo.id}
            selectionType={'first'}
            groups={groups}
            val={picks['1' + grupo.short_name].pick}
            handleSelectTeam={handleSelectTeam}
          />
          <ListTeams
            bracketStatus={bracketStatus}
            placeholder={`2° Lugar del Grupo`}
            teams={grupo.teams}
            matches={allMatches}
            isTheLastItem={isTheLastItem}
            selectedTeams={selectedTeams}
            grupoShortName={grupo.short_name}
            grupoId={grupo.id}
            groups={groups}
            selectionType={'second'}
            val={picks['2' + grupo.short_name].pick}
            handleSelectTeam={handleSelectTeam}
          />
        </div>
      </div>
    );
  });
  return <div className="matches-list">{selectGroupWinners}</div>;
};

const BracketLayout = ({
  allBracketTeams,
  picks,
  groups,
  handleSelectTeam,
  selectedTeams,
  notSelectedPicks,
  teams,
  matches,
  flipped,
  handleWinnerMatchSelector,
  activeRound,
  changeRound,
  bracketStatus,
}) => {
  // console.log('%c Bracket Layout', 'color:rgb(252, 245, 38); font-size:2em;');
  // console.log('ActiveRound', activeRound );
  activeRound = activeRound + 1;
  const rounds = Object.keys(matches);
  // console.log( teams, matches, rounds, flipped );

  let groupSelection = groups.map((group) => {
    let currentPickFirst = picks[1 + group.short_name].pick;
    let isFirstCorrect = null;
    let currentPickSecond = picks[2 + group.short_name].pick;
    let isSecondCorrect = null;

    if (!currentPickSecond || !currentPickFirst) {
      return (
        <div
          key={group.id}
          id={group.id}
          className="flex-row row-center row-no-padding match-container"
        >
          <div className="flex-col bracket-match-container">
            <div className="flex-row row-no-padding">
              <div className="flex-col bracket-title">
                <div className="flex-row row-no-padding">
                  <div className="flex-col col-center matchNumber">{group.name}</div>
                </div>
              </div>
            </div>
            <div className="flex-row row-no-padding row-center">
              <div className="flex-col">
                <label className={` disabled  bracket-team selected ${group}`}>
                  1{group.short_name}
                  N/A
                </label>
              </div>
            </div>
            <div className="flex-row row-no-padding row-center">
              <div className="flex-col">
                <label className={` disabled  bracket-team selected`}>
                  2{group.short_name}
                  N/A
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    currentPickFirst = allBracketTeams.find((team) => team.id === currentPickFirst);
    currentPickSecond = allBracketTeams.find((team) => team.id === currentPickSecond);

    let firstMatch = matches['Grupos'].find((match) => match.name === '1' + group.short_name);
    let secondMatch = matches['Grupos'].find((match) => match.name === '2' + group.short_name);

    // console.log('Grupo', group);
    // console.log('First', currentPickFirst);
    // console.log('Second', currentPickSecond);
    // console.log(firstMatch);
    // console.log(secondMatch);

    isFirstCorrect =
      !picks[1 + group.short_name].correct && picks[1 + group.short_name].correct !== null
        ? 'perdio'
        : picks[1 + group.short_name].correct === null
        ? 'notEnabled'
        : '';
    isSecondCorrect =
      !picks[2 + group.short_name].correct && picks[2 + group.short_name].correct !== null
        ? 'perdio'
        : picks[2 + group.short_name].correct === null
        ? 'notEnabled'
        : '';

    return (
      <div
        key={group.id}
        id={group.id}
        className="flex-row row-center row-no-padding match-container"
      >
        <div className="flex-col bracket-match-container">
          <div className="flex-row row-no-padding">
            <div className="flex-col bracket-title">
              <div className="flex-row row-no-padding">
                <div className="flex-col col-center matchNumber">{group.name}</div>
              </div>
            </div>
          </div>
          <div className="flex-row row-no-padding row-center">
            <div className="flex-col">
              <label className={` disabled  bracket-team selected  ${isFirstCorrect}`}>
                1{group.short_name}
                <i className={`flag  ${currentPickFirst.logo}`} />
                {currentPickFirst.name}
              </label>
            </div>
          </div>
          <div className="flex-row row-no-padding row-center">
            <div className="flex-col">
              <label className={` disabled  bracket-team selected ${isSecondCorrect}`}>
                2{group.short_name}
                <i className={`flag  ${currentPickSecond.logo}`} />
                {currentPickSecond.name}
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  });

  let roundsColumns = rounds.map((round, roundIdx) => {
    if (round === 'Grupos') {
      return;
    }
    return (
      <div
        id={`round-${roundIdx}`}
        key={round}
        className={` stage-container flex-col col-20 ${round}`}
        data-round-id={roundIdx}
      >
        <div className=" flex-row row-center row-no-padding ">
          <div className=" stage-header flex-col">{round}</div>
        </div>
        <RoundMatches
          bracketStatus={bracketStatus}
          allBracketTeams={allBracketTeams}
          roundId={roundIdx}
          picks={picks}
          notSelectedPicks={notSelectedPicks}
          teams={teams}
          matches={matches[round]}
          handleWinnerMatchSelector={handleWinnerMatchSelector}
        />
      </div>
    );
  });

  let roundsHeaders = rounds.map((round, roundIdx) => {
    return (
      <div className={`flex-col col-20 ${round}`} data-round-id={roundIdx}>
        {round}
      </div>
    );
  });

  return (
    <div className="flex-row row-no-padding">
      <div id="round-0" className=" stage-container flex-col col-20">
        <div className=" flex-row row-center row-no-padding">
          <div className="stage-header flex-col">Grupos</div>
        </div>
        {bracketStatus !== 'upcoming' ? (
          <div className="matches-list ">{groupSelection}</div>
        ) : (
          <SelectTeams
            bracketStatus={bracketStatus}
            groups={groups}
            matches={matches}
            selectedTeams={selectedTeams}
            handleSelectTeam={handleSelectTeam}
            picks={picks}
          />
        )}
      </div>
      {roundsColumns}
    </div>
    // </Hammer>
  );
};

const RoundMatches = ({
  allBracketTeams,
  bracketStatus,
  roundId,
  picks,
  notSelectedPicks,
  teams,
  matches,
  handleWinnerMatchSelector,
}) => {
  // console.log('handleWinnerMatchSelector', handleWinnerMatchSelector );
  let matchesList = matches.map((match) => {
    let homeParentTeam = allBracketTeams.find((team) => team.id === picks[match.home_parent].pick);
    let awayParentTeam = allBracketTeams.find((team) => team.id === picks[match.away_parent].pick);

    let isHomeSelected =
      !homeParentTeam ||
      picks[match.number].pick === null ||
      homeParentTeam.id !== picks[match.number].pick
        ? false
        : true;
    let isAwaySelected =
      !awayParentTeam ||
      picks[match.number].pick === null ||
      awayParentTeam.id !== picks[match.number].pick
        ? false
        : true;

    /**
     * Exception Match
     **/
    if (match.show_not_selected) {
      homeParentTeam = allBracketTeams.find(
        (team) => team.id === notSelectedPicks[match.home_parent]
      );
      awayParentTeam = allBracketTeams.find(
        (team) => team.id === notSelectedPicks[match.away_parent]
      );

      let isCorrect =
        !picks[match.number].correct && picks[match.number].correct !== null
          ? 'perdio'
          : picks[match.number].correct === null
          ? 'notEnabled'
          : '';

      return (
        <div
          key={match.number}
          id={match.number}
          className="flex-row row-center row-no-padding match-container"
          data-match-id={match.number}
        >
          <div className="flex-col bracket-match-container">
            <div className="flex-row row-no-padding">
              <div className="flex-col bracket-title">
                <div className="flex-row row-no-padding">
                  <div className="flex-col col-10 col-center matchNumber">{match.number}</div>
                  <div className="flex-col">
                    <div className="flex-container end">
                      {moment(match.match_date).format('DD/MM/YY @ hh:mm')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row row-no-padding row-center">
              <div className="flex-col">
                <label
                  id={`${
                    match.number === 64
                      ? 'final-round'
                      : match.number === 63
                      ? 'third-place'
                      : 'null'
                  }`}
                  className={`${!match.enabled ? 'disabled' : ''}
                  ${isCorrect}
                  ${match.name} bracket-team ${
                    picks[match.number].pick !== null &&
                    homeParentTeam &&
                    homeParentTeam.id === picks[match.number].pick
                      ? 'selected'
                      : ''
                  } `}
                  data-team-id={!homeParentTeam ? '' : homeParentTeam.id}
                >
                  {picks[64] !== null &&
                  picks[64].name !== null &&
                  homeParentTeam &&
                  homeParentTeam.name === picks[64].name ? (
                    <i className="ion-trophy" />
                  ) : (
                    match.home_parent + 'L'
                  )}
                  <i className={` flag ${!homeParentTeam ? '' : homeParentTeam.logo}`}> </i>
                  {!homeParentTeam ? '' : homeParentTeam.name}
                  {match.enabled ? (
                    <input
                      id={!homeParentTeam ? '' : homeParentTeam.id}
                      name={match.name + match.number}
                      disabled={!match.enabled}
                      type="radio"
                      value={!homeParentTeam ? '' : homeParentTeam.id}
                      onClick={(e) => {
                        handleWinnerMatchSelector(e, match, awayParentTeam);
                      }}
                    />
                  ) : null}
                  {match.enabled ? (
                    <div
                      className={`radio-check ${
                        !homeParentTeam || !match.enabled ? 'disabled' : ''
                      }`}
                    />
                  ) : null}
                </label>
              </div>
            </div>
            <div className="flex-row row-no-padding row-center">
              <div className="flex-col">
                <label
                  id={`${
                    match.number === 64
                      ? 'final-round'
                      : match.number === 63
                      ? 'third-place'
                      : 'null'
                  }`}
                  className={` ${!match.enabled ? 'disabled' : ''}
                  ${isCorrect}
                  ${match.name} bracket-team ${
                    picks[match.number].pick !== null &&
                    awayParentTeam &&
                    awayParentTeam.id === picks[match.number].pick
                      ? 'selected'
                      : ''
                  } `}
                  data-team-id={!awayParentTeam ? '' : awayParentTeam.id}
                >
                  {picks[64] !== null &&
                  picks[64].name !== null &&
                  awayParentTeam &&
                  awayParentTeam.name === picks[64].name ? (
                    <i className="ion-trophy" />
                  ) : (
                    match.away_parent + 'L'
                  )}
                  <i className={` flag ${!awayParentTeam ? '' : awayParentTeam.logo}`}> </i>
                  {!awayParentTeam ? '' : awayParentTeam.name}
                  {match.enabled ? (
                    <input
                      id={!awayParentTeam ? '' : awayParentTeam.id}
                      name={match.name + match.number}
                      disabled={!match.enabled}
                      type="radio"
                      value={!awayParentTeam ? '' : awayParentTeam.id}
                      onClick={(e) => {
                        handleWinnerMatchSelector(e, match, homeParentTeam);
                      }}
                    />
                  ) : null}
                  {match.enabled ? (
                    <div
                      className={`radio-check ${
                        !awayParentTeam || !match.enabled ? 'disabled' : ''
                      }`}
                    />
                  ) : null}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      let isCorrect =
        !picks[match.number].correct && picks[match.number].correct !== null
          ? 'perdio'
          : picks[match.number].correct === null
          ? 'notEnabled'
          : '';

      /****************
       * Default Match
       ****************/
      return (
        <div
          key={match.number}
          id={match.number}
          className="flex-row row-center row-no-padding match-container"
          data-match-id={match.number}
        >
          <div className="flex-col bracket-match-container">
            <div className="flex-row row-no-padding">
              <div className="flex-col bracket-title">
                <div className="flex-row row-no-padding">
                  <div className="flex-col col-10 col-center matchNumber">{match.number}</div>
                  <div className="flex-col">
                    <div className="flex-container end">
                      {moment(match.match_date).format('DD/MM/YY @ hh:mm')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row row-no-padding row-center">
              <div className="flex-col">
                <label
                  id={`${
                    match.number === 64
                      ? 'final-round'
                      : match.number === 63
                      ? 'third-place'
                      : 'null'
                  }`}
                  className={` ${!match.enabled ? 'disabled' : ''}
                  ${isCorrect}
                  bracket-team ${isHomeSelected ? 'selected' : ''}`}
                  data-team-id={!homeParentTeam ? '' : homeParentTeam.id}
                >
                  {match.home_parent}
                  <i className={`flag ${!homeParentTeam ? '' : homeParentTeam.logo}`} />
                  {!homeParentTeam ? '' : homeParentTeam.name}
                  {match.enabled ? (
                    <input
                      id={!homeParentTeam ? '' : homeParentTeam.id}
                      disabled={!homeParentTeam || !match.enabled ? true : false}
                      name={match.name + match.number}
                      type="radio"
                      value={!homeParentTeam ? '' : homeParentTeam.id}
                      onClick={(e) => {
                        handleWinnerMatchSelector(
                          e,
                          match,
                          awayParentTeam ? awayParentTeam.id : null
                        );
                      }}
                    />
                  ) : null}
                  {match.enabled ? (
                    <div
                      className={`radio-check ${
                        !homeParentTeam || !match.enabled ? 'disabled' : ''
                      }`}
                    />
                  ) : null}
                </label>
              </div>
            </div>
            <div className="flex-row row-no-padding row-center">
              <div className="flex-col">
                <label
                  id={`${
                    match.number === 64
                      ? 'final-round'
                      : match.number === 63
                      ? 'third-place'
                      : 'null'
                  }`}
                  className={`bracket-team
                  ${isCorrect}
                  ${isAwaySelected ? 'selected' : ''}`}
                  data-team-id={!awayParentTeam ? '' : awayParentTeam.id}
                >
                  {match.away_parent}
                  <i className={`flag ${!awayParentTeam ? '' : awayParentTeam.logo}`} />
                  {!awayParentTeam ? '' : awayParentTeam.name}
                  {match.enabled ? (
                    <input
                      id={!awayParentTeam ? '' : awayParentTeam.id}
                      disabled={!awayParentTeam || !match.enabled ? true : false}
                      name={match.name + match.number}
                      type="radio"
                      value={!awayParentTeam ? '' : awayParentTeam.id}
                      onClick={(e) => {
                        handleWinnerMatchSelector(
                          e,
                          match,
                          homeParentTeam ? homeParentTeam.id : null
                        );
                      }}
                    />
                  ) : null}
                  {match.enabled ? (
                    <div
                      className={`radio-check ${
                        !awayParentTeam || !match.enabled ? 'disabled' : ''
                      }`}
                    />
                  ) : null}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }
  });
  return (
    <div className="matches-list" data-round-id={roundId}>
      {matchesList}
    </div>
  );
};

class BracketsUI extends React.Component {
  constructor(props) {
    super(props);

    let allMatches = [];
    let scrollView, scrollElement;

    var stages = _.values(this.props.matches);
    stages.forEach((_matches) => {
      allMatches = allMatches.concat(_matches);
    });

    this.state = {
      bracketStages: null,
      activeRound: this.props.activeRound,
      mounted: false,
      groups: null,
      showAbonar: false,

      showRegisterPanel: false,
      positionintTouch: false,
      showSavedConfirmation: false,
      submitting: false,
      allMatches: allMatches,
      popUpNewUsers: false,
      popUpNewUsersStatus: false,
      showTutorial: false,
      selectedTeams: {
        A: { first: null, second: null },
        B: { first: null, second: null },
        C: { first: null, second: null },
        D: { first: null, second: null },
        E: { first: null, second: null },
        F: { first: null, second: null },
        G: { first: null, second: null },
        H: { first: null, second: null },
      },
      /**
       * Picks Model for bracket
       */
      picks: {
        '1A': { id: null, bracket_id: null, pick: null, match_id: null },
        '2A': { id: null, bracket_id: null, pick: null, match_id: null },
        '1B': { id: null, bracket_id: null, pick: null, match_id: null },
        '2B': { id: null, bracket_id: null, pick: null, match_id: null },
        '1C': { id: null, bracket_id: null, pick: null, match_id: null },
        '2C': { id: null, bracket_id: null, pick: null, match_id: null },
        '1D': { id: null, bracket_id: null, pick: null, match_id: null },
        '2D': { id: null, bracket_id: null, pick: null, match_id: null },
        '1E': { id: null, bracket_id: null, pick: null, match_id: null },
        '2E': { id: null, bracket_id: null, pick: null, match_id: null },
        '1F': { id: null, bracket_id: null, pick: null, match_id: null },
        '2F': { id: null, bracket_id: null, pick: null, match_id: null },
        '1G': { id: null, bracket_id: null, pick: null, match_id: null },
        '2G': { id: null, bracket_id: null, pick: null, match_id: null },
        '1H': { id: null, bracket_id: null, pick: null, match_id: null },
        '2H': { id: null, bracket_id: null, pick: null, match_id: null },
        '49': { id: null, bracket_id: null, pick: null, match_id: null },
        '50': { id: null, bracket_id: null, pick: null, match_id: null },
        '51': { id: null, bracket_id: null, pick: null, match_id: null },
        '52': { id: null, bracket_id: null, pick: null, match_id: null },
        '53': { id: null, bracket_id: null, pick: null, match_id: null },
        '54': { id: null, bracket_id: null, pick: null, match_id: null },
        '55': { id: null, bracket_id: null, pick: null, match_id: null },
        '56': { id: null, bracket_id: null, pick: null, match_id: null },
        '57': { id: null, bracket_id: null, pick: null, match_id: null },
        '58': { id: null, bracket_id: null, pick: null, match_id: null },
        '59': { id: null, bracket_id: null, pick: null, match_id: null },
        '60': { id: null, bracket_id: null, pick: null, match_id: null },
        '61L': { id: null, bracket_id: null, pick: null, match_id: null },
        '62L': { id: null, bracket_id: null, pick: null, match_id: null },
        '61': { id: null, bracket_id: null, pick: null, match_id: null },
        '62': { id: null, bracket_id: null, pick: null, match_id: null },
        '63': { id: null, bracket_id: null, pick: null, match_id: null },
        '64': { id: null, bracket_id: null, pick: null, match_id: null },
      },
      notSelected: {
        '61': null,
        '62': null,
      },
    };

    this.showTutorial = () => {
      this.setState({ showTutorial: true });
    };

    this.closeTutorial = () => {
      console.log('Close');
      this.setState({ showTutorial: false });
    };

    this.deleteNewUserStatus = () => {
      this.setState({ popUpNewUsers: null, popUpNewUsersStatus: false });
    };

    /**
     * This function animates scroll of the popup
     * to the top of the page
     */
    this.animateBracketTop = () => {
      if (this.state.positioningTouch) {
        return;
      }
      setTimeout(() => {
        let offsetTop = $('.quinielas-info');
        let scoreLobbyOffset = $('.quinielas-info');
        if (!offsetTop || offsetTop.length < 1) {
          return;
        }
        if (!scoreLobbyOffset || scoreLobbyOffset.length < 1) {
          return;
        }
        offsetTop = $('.quinielas-info').offset().top - $('#score-lobby').offset().top + 40;
        $('.modal').animate({ scrollTop: offsetTop }, 'slow');
        this.setState({ positioningTouch: true });
      }, 500);
    };

    /**
     * This function initialize the Scroller
     */
    this.initializeScroller = () => {
      console.log('%c InitializeScroller ', 'background: rgb(213, 210, 57); font-size:2em;');

      scrollElement = document.querySelector('#bracket-ui-container');
      scrollView = new window.ionic.views.Scroll({
        el: scrollElement,
        zooming: true,
        maxZoom: 1,
        minZoom: 0.5,
        scrollingX: true,
        scrollingY: true,
        bouncing: false,
      });

      setTimeout(() => {
        scrollView.zoomTo(0.5, 1);
      }, 200);

      scrollElement.addEventListener('touchstart', () => {
        this.animateBracketTop();
      });
      // console.log( scrollView.touchEnd, scrollElement );
      // setTimeout(( ) => {
      //   scroll.zoomTo(0.5, 1,0 , 0);
      // }, 200)
    };

    this.changeRound = (e) => {
      console.log('%c ChangeRound ', 'color: rgb(213, 210, 57); font-size:2em;');
      console.log(e);
      this.setState({
        activeRound: e,
      });

      let $section = document.querySelector('#round-' + e);
      let $rect = $section.getBoundingClientRect();
      var left = scrollElement.offsetLeft;
      var top = scrollElement.offsetTop;
      scrollView.scrollBy($rect.left - left, 0, 1);
    };

    this.renderScroll = bracketUtils.renderScroll.bind(this);

    this.registraBracket = bracketUtils.registraBracket.bind(this);

    this.initialPicksModel = (matches, bracketEntryId, picks, bracketInfo) => {
      // console.log('%c Inintialize model picks', 'color:rgb(160, 201, 134); font-size:1.2em;');

      matches.forEach((match) => {
        if (picks[match.number].hasOwnProperty(match.number)) {
          picks[match.number].match_id = match.number;
        } else {
          picks[match.number] = {
            bracket_id: bracketInfo.id,
            bracket_entry_id: bracketEntryId !== null ? bracketEntryId : null,
            pick: null,
            match_id: match.id,
            correct: null,
          };
        }
      });
      this.setState({
        picks: picks,
      });
    };

    this.removePath = (homeParentMatch, awayParentMatch, teamId, prevSelection) => {
      if (!homeParentMatch && !awayParentMatch) {
        return;
      }
      // console.log( '%c RemovePath', 'color:rgb(109, 74, 235); font-size:1.5em;' );
      // console.log( 'HomeParentMatch', homeParentMatch ,'AwayParentMatch', awayParentMatch ,'newSelection', teamId, 'prevSelection', prevSelection );

      let temporalPicks = this.state.picks;

      let temporalNotSelected = this.state.notSelected;

      this.setState({
        notSelected: temporalNotSelected,
      });

      if (homeParentMatch) {
        let currentHomeParent = this.state.allMatches.find(
          (_match) => _match.home_parent === homeParentMatch.number
        );
        let currentAwayParent = this.state.allMatches.find(
          (_match) => _match.away_parent === homeParentMatch.number
        );

        /**
         * Get list of inputs with the same value that was changed
         */
        let searchThis = ` #${homeParentMatch.number} label[data-team-id=${prevSelection}] input`;

        const emptyInput = $(searchThis).val(prevSelection);

        if (emptyInput.length === 0) {
          return;
        }

        for (let el = 0; el < emptyInput.length; el++) {
          console.log($(emptyInput[el]).val());
          $(emptyInput[el]).prop('checked', false);
        }

        // console.log( 'Pick HomeParentMatch', temporalPicks[homeParentMatch.number].pick, 'TeamId', teamId );
        if (prevSelection !== temporalPicks[homeParentMatch.number].pick) {
          return;
        }
        temporalPicks[homeParentMatch.number].pick = null;
        this.removePath(
          currentHomeParent,
          currentAwayParent,
          temporalPicks[homeParentMatch.number].pick,
          prevSelection
        );
      } else if (awayParentMatch) {
        // console.log('%c AwayParentMAtch','color:rgb(119, 197, 204); font-size:1-5em;' );

        // console.log('awayParentTeam', awayParentMatch);

        let currentHomeParent = this.state.allMatches.find(
          (_match) => _match.home_parent === awayParentMatch.number
        );
        let currentAwayParent = this.state.allMatches.find(
          (_match) => _match.away_parent === awayParentMatch.number
        );

        let searchThis = ` #${awayParentMatch.number} label[data-team-id=${prevSelection}] input`;
        let emptyInput = $(searchThis).val(prevSelection);

        // console.log( 'searchThis', searchThis );
        console.log('emptyInput', emptyInput);

        if (emptyInput.length === 0) {
          return;
        }

        for (let el = 0; el < emptyInput.length; el++) {
          console.log($(emptyInput[el]).val());
          $(emptyInput[el]).prop('checked', false);
        }

        if (prevSelection !== temporalPicks[awayParentMatch.number].pick) {
          return;
        }

        temporalPicks[awayParentMatch.number].pick = null;

        this.removePath(
          currentHomeParent,
          currentAwayParent,
          temporalPicks[awayParentMatch.number].pick,
          teamId,
          prevSelection
        );
      }
    };

    this.initializeBracketPicks = (picks) => {
      // console.log('%c Initialize Brackets picks ', 'color:rgb(150, 15, 238); font-size:1.5em;');
      // console.log( picks );
      const picksKeys = Object.keys(this.state.picks);
      //let temporalGroups = this.state.groups;
      //let temporalSelectedTeams = this.state.selectedTeams;

      let allTeams = this.props.allBracketTeams;

      let mappedPicks = this.state.picks;

      /**
       * Map Picks of entry to picks Model
       **/
      picksKeys.forEach((pickKey) => {
        // console.log(isNaN(pickKey));
        if (!picks[pickKey]) {
          mappedPicks[pickKey].pick = null;
        } else {
          let pickedTeam = allTeams.find((team) => {
            if (team.id === picks[pickKey].pick) {
              return team;
            }
          });

          if (!pickedTeam) {
            // console.log('Not This Pick', pickKey);
            mappedPicks[pickKey].pick = null;
          } else {
            /**
             * Exception for Special Match
             **/
            if (pickKey === 61 || pickKey === 62) {
              // console.log('Initialize Special Picks', );
              let currentMatch = this.state.matches.Semifinales.find(
                (match) => match.number === pickKey
              );
              if (!picks[currentMatch.home_parent] || !picks[currentMatch.away_parent]) {
                return;
              }

              let homeTeamId = picks[currentMatch.home_parent].pick;
              let awayTeamId = picks[currentMatch.away_parent].pick;
              let notSelectedPick = null;
              let notPickedTeam = null;
              let temporalNotSelectedPicks = this.state.notSelected;

              if (homeTeamId === pickedTeam.id) {
                notSelectedPick = picks[currentMatch.away_parent];
              }
              if (awayTeamId === pickedTeam.id) {
                notSelectedPick = picks[currentMatch.home_parent];
              }

              notPickedTeam = allTeams.find((allTeam) => allTeam.id === notSelectedPick.pick);

              // console.log('NotSelectedPick', notSelectedPick);
              // console.log('NotSelectedTeam', notPickedTeam);
              // console.log('NotSelectedPick Layout',  notSelectedPickLayout);

              temporalNotSelectedPicks[pickKey] = notSelectedPick.pick;

              this.setState({
                notSelected: temporalNotSelectedPicks,
              });
            }

            mappedPicks[pickKey].pick = pickedTeam.id;
            mappedPicks[pickKey].correct = picks[pickKey].correct;
            // console.log('MappedPick', mappedPicks[pickKey], pickedTeam.id );
          }
        }
        // }
      });
      // this.resetBracket();
      // console.table(mappedPicks);
      // this.mapRadioState( mappedPicks, this.state.allMatches)
      return mappedPicks;
    };

    this.groupSelect = () => {
      Object.keys(this.state.picks).forEach((pickKey) => {
        if (isNaN(pickKey[1])) {
          // console.log('Select init');

          let keyFirst = '1' + pickKey[1];
          let keySecond = '2' + pickKey[1];

          let firstId = this.state.picks[keyFirst].pick;
          let secondId = this.state.picks[keySecond].pick;
          let tempGroups = this.state.groups;
          // console.log('First', keyFirst ,firstId);
          // console.log('second', secondId, secondId);
          tempGroups.forEach((group, grpIdx) => {
            // console.log('group.id', group.id);
            if (group.short_name !== pickKey[1]) {
              return;
            }
            // console.log('pickKey[1]', pickKey[1]);
            group.teams.forEach((_team, teamIdx) => {
              // console.log( 'change teams' );
              _team.selected = false;
              if (_team.id === firstId) {
                _team.selected = 1;
              }
              if (_team.id === secondId) {
                _team.selected = 2;
              }
            });
            // console.table( this.state.picks );
          });
          this.setState({
            groups: tempGroups,
          });
        }
      });
    };

    this.mapRadioState = (picks, matches) => {
      console.log('Map Radios to Picks');
      // console.table(picks );
      const picksValue = _.values(picks);
      picksValue.forEach((pick, pickIdx) => {
        const currentMatch = matches.find((match) => pick.match_id === match.id);
        // console.log('currentMatch', currentMatch);
        if (!currentMatch) {
          return;
        }

        if (pick.pick === null) {
          //let matchId = pick.match_id;
          const searchThisInputs = ` .match-container[data-match-id=${currentMatch.number}] label[data-team-id=${pick.pick}].selected input `;
          let matchContainer = $(searchThisInputs);
          for (let match = 0; match < matchContainer.length; match++) {
            console.log('MatchCont', $(matchContainer[match]));

            $(matchContainer[match]).prop('checked', false);
          }
        } else {
          //let matchId = pick.match_id;

          const searchThisInputs = `label[data-team-id=${pick.pick}].selected input `;
          let matchContainer = $(searchThisInputs);
          // console.log('searchThisInputs', searchThisInputs);
          // console.log('MatchContainer', matchContainer);
          /**
           * Loop through Radios and check the corresponding pick
           **/
          for (let match = 0; match < matchContainer.length; match++) {
            // console.log('MatchCont', $(matchContainer[match]));
            $(matchContainer[match]).prop('checked', true);
          }
        }
      });
    };

    this.showSavedConfirmation = () => {
      console.log('% showConfirmation', 'background:#2a12;');
      this.setState(
        {
          showSavedConfirmation: true,
        },
        () => {
          setTimeout(() => {
            this.hideSavedConfirmation();
          }, 2000);
        }
      );
    };

    this.hideSavedConfirmation = () => {
      this.setState({
        showSavedConfirmation: false,
      });
    };

    this.handleConfirmation = (newEntryID) => {
      console.log('Show Animation to confirm saved ', newEntryID);
      window.location.assign(
        `/bracketPerfect/${this.props.bracketInfo.slug}/${this.props.bracketInfo.id}/${newEntryID}`
      );
    };

    this.selectWinner = (e, data) => {
      console.log('%c Select Winner!', 'color:rgb(13, 48, 45); font-size:1.5em;');
      console.log('Event', e.target);
      console.log(data);
      let teamSelectedIndex = e.target.attributes['data-teamid'].value;
      let tempMatches = this.state.matches;
      console.log('TeamIndex', teamSelectedIndex);
      this.setState({
        matches: tempMatches,
      });
    };

    this.showAbonar = () => {
      this.setState({ showAbonar: true });
    };

    this.hideAbonar = () => {
      this.setState({ showAbonar: false });
    };

    this.showRegisterPanel = () => {
      this.setState({ showRegisterPanel: true });
    };

    this.closeRegisterPanel = () => {
      this.setState({ showRegisterPanel: false });
    };

    this.handleClickFirstRegister = () => {
      this.setState({ showRegisterPanel: true });
    };

    this.handleWinnerMatchSelector = (e, match, notSelectedTeamId) => {
      // console.log( '%c  handle Winner Match', ' color:rgb(101, 132, 244); font-size: 1.5em;' );
      console.log('Match', e.target.value, match, notSelectedTeamId);

      let temporalNotSelectedPicks = this.state.notSelected;

      if (match.set_not_selected) {
        console.log('notSelected');
        // console.log(this.state.notSelected)
        if (temporalNotSelectedPicks[61] === e.target.value) {
          console.log('third');
          temporalNotSelectedPicks[61] = null;
          $('#third-place').removeClass('selected');
          $('#third-place')
            .find('input')
            .prop('checked', false);
        } else if (temporalNotSelectedPicks[62] === e.target.value) {
          console.log('third');
          temporalNotSelectedPicks[62] = null;
          $('#third-place').removeClass('selected');
          $('#third-place')
            .find('input')
            .prop('checked', false);
        }

        temporalNotSelectedPicks[match.number] = notSelectedTeamId;

        console.log(temporalNotSelectedPicks);
      }

      //let thePick;
      let tempPicks = this.state.picks;

      // console.log( e.target.value , tempPicks[match.number].pick );

      if (e.target.value === tempPicks[match.number].pick) {
        return;
      }

      let prevSelection = tempPicks[match.number].pick;

      tempPicks[match.number].pick = Number(e.target.value);
      console.log('ChangedPick', tempPicks[match.number]);

      let currentHomeParent = this.state.allMatches.find(
        (_match) => _match.home_parent === match.number
      );
      let currentAwayParent = this.state.allMatches.find(
        (_match) => _match.away_parent === match.number
      );

      this.removePath(currentHomeParent, currentAwayParent, e.target.value, prevSelection);

      // console.log('CurrentMatch', currentMatch);

      this.setState({
        picks: tempPicks,
        notSelected: temporalNotSelectedPicks,
      });
    };

    this.callbackBracketSavedPicks = (response) => {
      console.log('%c ResponseBracket Register', 'color:rgb(11, 173, 115); font-size:1.2em;');
      console.log(response);
      if (response.data) {
        const newEntry = response.data.bracketEntry.id;
        console.log('NewEntryBracket', newEntry);
        this.setState(
          {
            submitting: false,
          },
          () => {
            console.log('callback finished');
            this.props.refreshAfterGuardar(this.props.selectedEntry, 'save');

            setTimeout(() => {
              this.showSavedConfirmation();
            }, 500);
            if (newEntry !== this.props.selectedEntryData.id) {
              this.handleConfirmation(newEntry);
            }
          }
        );
      }
    };

    this.resetBracket = () => {
      console.log('resetBracket');
      this.setState(
        {
          picks: {
            '1A': { id: null, bracket_id: null, pick: null, match_id: null },
            '2A': { id: null, bracket_id: null, pick: null, match_id: null },
            '1B': { id: null, bracket_id: null, pick: null, match_id: null },
            '2B': { id: null, bracket_id: null, pick: null, match_id: null },
            '1C': { id: null, bracket_id: null, pick: null, match_id: null },
            '2C': { id: null, bracket_id: null, pick: null, match_id: null },
            '1D': { id: null, bracket_id: null, pick: null, match_id: null },
            '2D': { id: null, bracket_id: null, pick: null, match_id: null },
            '1E': { id: null, bracket_id: null, pick: null, match_id: null },
            '2E': { id: null, bracket_id: null, pick: null, match_id: null },
            '1F': { id: null, bracket_id: null, pick: null, match_id: null },
            '2F': { id: null, bracket_id: null, pick: null, match_id: null },
            '1G': { id: null, bracket_id: null, pick: null, match_id: null },
            '2G': { id: null, bracket_id: null, pick: null, match_id: null },
            '1H': { id: null, bracket_id: null, pick: null, match_id: null },
            '2H': { id: null, bracket_id: null, pick: null, match_id: null },
            '49': { id: null, bracket_id: null, pick: null, match_id: null },
            '50': { id: null, bracket_id: null, pick: null, match_id: null },
            '51': { id: null, bracket_id: null, pick: null, match_id: null },
            '52': { id: null, bracket_id: null, pick: null, match_id: null },
            '53': { id: null, bracket_id: null, pick: null, match_id: null },
            '54': { id: null, bracket_id: null, pick: null, match_id: null },
            '55': { id: null, bracket_id: null, pick: null, match_id: null },
            '56': { id: null, bracket_id: null, pick: null, match_id: null },
            '57': { id: null, bracket_id: null, pick: null, match_id: null },
            '58': { id: null, bracket_id: null, pick: null, match_id: null },
            '59': { id: null, bracket_id: null, pick: null, match_id: null },
            '60': { id: null, bracket_id: null, pick: null, match_id: null },
            '61L': { id: null, bracket_id: null, pick: null, match_id: null },
            '62L': { id: null, bracket_id: null, pick: null, match_id: null },
            '61': { id: null, bracket_id: null, pick: null, match_id: null },
            '62': { id: null, bracket_id: null, pick: null, match_id: null },
            '63': { id: null, bracket_id: null, pick: null, match_id: null },
            '64': { id: null, bracket_id: null, pick: null, match_id: null },
          },
          notSelected: {
            '61': null,
            '62': null,
          },
          showRegisterPanel: false,
        },
        () => {
          this.initializeBracketPicks(this.state.picks);
          this.groupSelect();
          this.removeSelectedState();
          //
          // this.removeRadioState(   );
        }
      );
    };

    this.removeSelectedState = () => {
      // console.log('RemoveALL selections');
      let bracketInputs = $('label.bracket-team');
      // console.log('bracketInputs', bracketInputs);
      for (let bracketIdx = 0; bracketIdx < bracketInputs.length; bracketIdx++) {
        // console.log(bracketInputs[bracketIdx]);
        if ($(bracketInputs[bracketIdx]).hasClass('selected')) {
          $(bracketInputs[bracketIdx]).removeClass('selected');
        }
        // console.log(bracketInputs[bracketIdx]);
      }
    };

    this.removeRadioState = () => {
      let bracketInputs = $('label.bracket-team input');
      for (let bracketIdx = 0; bracketIdx < bracketInputs.length; bracketIdx++) {
        // console.log(bracketSelected[bracketIdx]);
        $(bracketInputs[bracketIdx]).prop('checked', false);
      }
    };

    this.handlePinch = (e) => {
      console.log('handlePinch');
    };

    /**
     * This funciton handles the selected teamSelected
     * in the first
     **/
    this.handleSelectTeam = (e) => {
      console.log('Select Teams', e.target.value);

      let tempPicks = this.state.picks;
      let tempGroups = this.state.groups;

      let temporalNotSelected = this.state.notSelected;

      // console.log($(e.target).find(":selected").data("value"));
      // console.log(e.target.value)
      let elementSelected;

      if (e.target.value === 'second' || e.target.value === 'first') {
        elementSelected = $(e.target)
          .find(':selected')
          .data('value');
      } else {
        elementSelected = $(e.target)
          .find(':selected')
          .data('value');
      }

      console.log(elementSelected);

      let keyFirst = '1' + elementSelected.groupShortName;
      let keySecond = '2' + elementSelected.groupShortName;

      let modifyPlace = elementSelected.selectionType === 'first' ? 1 : 2;
      let modifyKey = modifyPlace + '' + elementSelected.groupShortName;

      let oldTeamId = tempPicks[modifyKey].pick;

      if (oldTeamId === elementSelected.teamID) {
        return;
      }

      tempPicks[modifyKey].pick = elementSelected.teamID;

      let firstId = tempPicks[keyFirst].pick;
      let secondId = tempPicks[keySecond].pick;

      let groupId = elementSelected.groupID;

      tempGroups.forEach((group, grpIdx) => {
        if (group.id !== groupId) {
          return;
        }
        group.teams.forEach((_team, teamIdx) => {
          _team.selected = false;
          if (_team.id === firstId) {
            _team.selected = 1;
          }
          if (_team.id === secondId) {
            _team.selected = 2;
          }
        });
        // console.table( group.teams );
      });

      if (oldTeamId !== null && oldTeamId !== '') {
        Object.keys(tempPicks).forEach((matchNumber) => {
          let pick = tempPicks[matchNumber];
          if (pick.pick === oldTeamId) {
            const searchThisInputs = `.match-container[data-match-id=${matchNumber}] input`;
            const searchThisLabels = `.match-container[data-match-id=${matchNumber}] label.selected`;
            let matchContainer = $(searchThisInputs);
            let labelContainer = $(searchThisLabels);

            // console.log('searchThisInputs', searchThisInputs);
            // console.log('searchThisLabels', searchThisLabels);

            for (let match = 0; match < matchContainer.length; match++) {
              // console.log('MatchCont', $(matchContainer[match]));
              $(matchContainer[match]).prop('checked', false);
            }
            for (let match = 0; match < labelContainer.length; match++) {
              // console.log('LabelCont', $(labelContainer[match]));
              $(labelContainer[match]).removeClass('selected');
            }

            if (temporalNotSelected[61] === pick.pick) {
              console.log('delete 3 y 4');
              temporalNotSelected[61] = null;
              // $('#third-place').find('input').prop('checked', false);
            } else if (temporalNotSelected[62] === pick.pick) {
              console.log('delete 3 y 4');

              temporalNotSelected[62] = null;
              // $('#third-place').find('input').prop('checked', false);
            }

            tempPicks[matchNumber].pick = null;

            // console.log('Search', searchThis );
            // console.log('Found', matchContainer );
            // console.log('Same Pick',temporalPicks[tempPick]);
          }
        });
      }

      this.setState({
        picks: tempPicks,
        notSelected: temporalNotSelected,
        groups: tempGroups,
      });
    };

    this.guardarBracket = bracketUtils.guardarBracket.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedEntry !== this.props.selectedEntry) {
      // this.resetBracket();
      if (
        !this.props.selectedEntryData.bracket_picks ||
        this.props.selectedEntryData.bracket_picks.length === 0
      ) {
        console.log('Delete  Picks');
        this.resetBracket();
        this.initialPicksModel(
          this.state.allMatches,
          this.props.selectedEntry,
          this.state.picks,
          this.props.bracketInfo
        );
        this.removeRadioState();
      } else {
        console.log('UpdatePicks');
        // this.resetBracket();
        this.removeRadioState();
        console.log(this.props.selectedEntryData.bracket_picks);
        const mappedPicks = this.initializeBracketPicks(this.props.selectedEntryData.bracket_picks);
        this.setState(
          {
            picks: mappedPicks,
          },
          () => {
            this.groupSelect();
            this.mapRadioState(this.state.picks, this.state.allMatches);
          }
        );
      }
    }
    if (prevProps.showReRegister !== this.props.showReRegister && this.props.showReRegister) {
      this.props.getCurrentPicks(this.state.picks);
    }
  }

  componentDidMount() {
    this.setState(
      {
        groups: this.props.groups,
        matches: this.props.matches,
        mounted: true,
      },
      () => {
        this.initialPicksModel(
          this.state.allMatches,
          this.props.selectedEntry,
          this.state.picks,
          this.props.bracketInfo
        );
        if (window.innerWidth < 670) {
          this.initializeScroller();
        }

        if (
          !this.props.selectedEntryData ||
          this.props.selectedEntryData.bracket_picks.length === 0
        ) {
          return;
        }
        // console.log('DidMount',this.props.selectedEntryData);
        const mappedPicks = this.initializeBracketPicks(this.props.selectedEntryData.bracket_picks);

        this.setState(
          {
            picks: mappedPicks,
          },
          () => {
            setTimeout(() => {
              this.mapRadioState(this.state.picks, this.state.allMatches);
              this.groupSelect();
              // this.mapSelectedState( mappedPicks );
            }, 500);
          }
        );
      }
    );
  }

  render() {
    if (!this.state.mounted) {
      return <Loading label="Cargando información del bracket..." />;
    } else {
      return (
        <div>
          <RoundsMobileControls
            stages={this.props.bracketStages}
            activeRound={this.state.activeRound}
            changeRound={this.changeRound}
          />
          <div
            id="bracket-ui-container"
            className={`scroll-view ionic-scroll scroll-xy ${!this.props.type ? 'lobby' : 'popup'}`}
          >
            <div className="scroll">
              <div className="bracket-inner-overflow">
                {/* <TutorialSlideShow
                  deleteNewUserStatus={this.deleteNewUserStatus}
                  numSlides={5}
                  showTutorial={this.state.showTutorial}
                  closeTutorial={this.closeTutorial}
                /> */}
                <BracketLayout
                  allBracketTeams={this.props.allBracketTeams}
                  bracketStatus={this.props.bracketInfo.status}
                  activeRound={this.props.activeRound}
                  changeRound={this.props.changeRound}
                  flipped={false}
                  groups={this.state.groups}
                  handleSelectTeam={this.handleSelectTeam}
                  handleWinnerMatchSelector={this.handleWinnerMatchSelector}
                  matches={this.props.matches}
                  notSelectedPicks={this.state.notSelected}
                  picks={this.state.picks}
                  selectedTeams={this.state.selectedTeams}
                  teams={this.state.groups}
                />
              </div>
            </div>
            <SavedPicksOverlay
              showOverlay={this.state.showSavedConfirmation}
              allPicks={this.state.allMatches.length}
              currentSavedPicks={
                !this.props.selectedEntry ? null : this.props.selectedEntryData.pick_count
              }
              label="¡ Felicidades tu bracket se guardo con éxito!"
            />
          </div>
          {this.props.bracketInfo.status === 'closed' ? null : this.props.bracketInfo.status ===
              'upcoming' && utils.isLogged() ? (
            <div className="btn-wrapper">
              {this.props.selectedEntryData !== null ? (
                <Button
                  data-id="guardar"
                  disabled={this.state.submitting}
                  onClick={(e) => {
                    console.log('%c Guarda Bracket', 'color:rgb(213, 57, 139);font-size:1.5em;');
                    if (this.props.entryId !== null) {
                      this.guardarBracket(
                        this.props.bracketInfo.id,
                        this.state.picks,
                        this.props.selectedEntry
                      );
                    } else {
                      this.props.updateUserInfo();
                      this.handleClickFirstRegister();
                    }
                  }}
                  className="guardar-picks callToAction"
                  bsStyle="default"
                >
                  <img src={guardar} className="bolsa-img" />
                  <span data-id="guardar">{'GUARDAR TU BRACKET'}</span>
                </Button>
              ) : this.props.bracketInfo.is_full ? (
                <span className="login-failed">
                  Esta quiniela esta llena, busca otra similar en nuestro lobby
                </span>
              ) : (
                <Button
                  data-id="guardar"
                  disabled={this.state.submitting || (utils.isLogged() && utils.isSuPick())}
                  onClick={(e) => {
                    if (this.props.enoughBalance) {
                      // console.log('Registro y Guardar Picks')
                      this.props.updateUserInfo();
                      this.handleClickFirstRegister();
                    } else {
                      this.showAbonar();
                    }
                  }}
                  className="guardar-picks callToAction"
                  bsStyle="default"
                >
                  <img src={this.props.enoughBalance ? guardar : abonar} className="bolsa-img" />
                  <span data-id="guardar">
                    {' '}
                    {this.props.enoughBalance ? 'GUARDAR BRACKET Y REGÍSTRAME' : 'ABONAR SALDO'}
                  </span>
                </Button>
              )}
            </div>
          ) : null}
          <AbonarSaldo
            type="modal"
            showAbonarSaldo={this.state.showAbonar}
            updatePanelSate={this.hideAbonar}
            showAbonarPop={this.showAbonar}
            updateUserInfo={this.props.updateUserInfo}
          />
          <RegisterPanel
            type="bracket"
            closePanel={this.closeRegisterPanel}
            entryId={this.props.entryId}
            handleConfirmation={this.handleConfirmation}
            logged={this.props.logged}
            matches={this.props.matches}
            payType={this.props.bracketInfo.entry_currency}
            pool={this.props.bracketInfo}
            pools={null}
            popUpNewUsersStatus={this.props.popUpNewUsersStatus}
            quinaID={this.props.bracketInfo.id}
            refreshAfterGuardar={this.props.refreshAfterGuardar}
            registerPicks={this.state.picks}
            returnErrors={(errors) => { console.log(errors); }}
            showPanel={this.state.showRegisterPanel}
            showPanelFirstRegister={this.state.showPanelFirstRegister}
            showPanelReRegister={this.state.showPanelReRegister}
            showTutorial={this.showTutorial}
            updatePanelFirstRegister={this.props.updatePanelFirstRegister}
            updatePanelReRegister={this.props.updatePanelReRegister}
            updateStateFunc={this.closeRegisterPanel}
            updateUserInfo={this.props.updateUserInfo}
            userData={this.props.userData}
            withRegister={this.props.withRegister}
          />
        </div>
      );
    }
  }
}

export default BracketsUI;
