import React, { useState, useRef, useLayoutEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { useQuery } from '@tanstack/react-query'
import hasEmoji from 'has-emoji'

import { Box, TextField, MultiOption, InputNumber } from '../../../Xmerald-UI'
import { useWizardPrivate } from '../wizardPrivate.store'
import WizardLayout from '../shared/Layout'

// Utils
import API from '../../../utils/api'

export default function QuinielaName () {
  const nameRef = useRef()
  const limitEntriesRef = useRef()
  const {
    step,
    setQuinielaData,
    quinielaData: { name, password, limit_multientries, max_multientries }
  } = useWizardPrivate(state => state)

  const [nameSearch] = useDebounce(name, 400)

  const [nameTouched, setNameTouched] = useState()
  const [passwordTouched, setPasswordTouched] = useState()

  const nameAvailableData = useQuery(
    ['name', nameSearch],
    () => API.fetchNamePrivateStatus(nameSearch).then(({ data }) => data),
    {
      enabled: nameSearch.trim().length > 2
    }
  )

  useLayoutEffect(() => {
    nameRef.current?.focus()
  }, [step])

  useLayoutEffect(() => {
    if (limit_multientries) {
      limitEntriesRef.current?.focus()
    }
  }, [limit_multientries])

  if (step !== 'QUINIELA_NAME') return null

  const getValidatingName = () => {
    if (hasEmoji(name)) return { error: true, message: 'El nombre no puede tener un emoji ☹' }
    if (name.trim().length === 0 && nameTouched) return { error: true, message: 'Escribe el nombre...' }
    if (name.trim().length < 5 && nameTouched) return { error: true, message: 'El nombre es demaciado corto' }
    if (nameAvailableData.isFetching) return { error: false, message: 'Validando nombre...' }
    if (nameAvailableData.data?.available) return { error: false, message: 'El nombre esta disponible', success: true }
    if (name.trim().length > 5 && !nameAvailableData.data?.available) return { error: true, message: 'El nombre no esta disponible' }
    return {}
  }

  const nameError = getValidatingName()

  const getValidatingPass = () => {
    if (password.trim().length < 1 && passwordTouched) return { error: true, message: 'La contraseña es requerida' }
    return {}
  }

  const passError = getValidatingPass()

  const disabledNext = (
    hasEmoji(name) ||
    name.length < 5 ||
    password.length < 2 ||
    nameAvailableData.isFetching ||
    !nameAvailableData.data?.available ||
    limit_multientries && !max_multientries ||
    limit_multientries && max_multientries <= 0
  )

  const getLimitError = () => {
    if (Number(max_multientries) === 0) {
      return 'Ingresa el numero'
    }
    if (Number(max_multientries) < 0) {
      return 'No puede ser menor a 0'
    }
  }

  return (
    <WizardLayout
      prevStep='CREATE_TYPE'
      nextStep='QUINIELA_SPORT'
      disabledNext={disabledNext}
    >
      <Box maxWidth={400} width='100%'>
        <Box mt={20} mb={50} mx='auto'>
          <TextField
            name='name'
            value={name}
            ref={nameRef}
            onChange={setQuinielaData}
            helpText={nameError.message}
            label='¿Cómo quieres que se llame tu quiniela?'
            loading={nameAvailableData.isFetching}
            isValid={!nameAvailableData.isFetching && nameAvailableData.data?.available}
            isInvalid={nameError.error}
            error={nameError.error}
            maxWidth={250}
            onBlur={() => setNameTouched(true)}

          />
          <TextField
            name='password'
            value={password}
            label='¿Que contraseña quieres que tenga tu quiniela?'
            onChange={setQuinielaData}
            error={passError.error}
            helpText={passError.message}
            maxWidth={250}
            onBlur={() => setPasswordTouched(true)}
          />

          <MultiOption
            onChange={setQuinielaData}
            keyName='limit_multientries'
            title='¿Quieres limitar los multi registros?'
            value={limit_multientries}
            options={[
              { name: 'SI', value: true },
              { name: 'NO', value: false }
            ]}
          />

          {limit_multientries && (
            <Box>
              <InputNumber
                label='Registros máximos por usuario'
                name='max_multientries'
                placeholder=''
                preffix='# '
                value={max_multientries}
                error={getLimitError()}
                helpText={getLimitError()}
                min={1}
                maxWidth={90}
                ref={limitEntriesRef}
                onChange={setQuinielaData}
              />
            </Box>
          )}
        </Box>
      </Box>
    </WizardLayout>
  )
}
