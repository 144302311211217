import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';

import gaUtils from '../GAWrapper';

import { Modal, Button } from 'react-bootstrap';
import Score from '../Score';
import ScoreMobile from '../ScoreMobile';
import MediaQuery from 'react-responsive';
import Loading from '../Loading';
import survivorUtils from '../../utils/survivorUtils';
import utils from '../../utils/utils';
import API from '../../utils/api';
import branchUtils from '../../utils/branchUtils';

import invitaAmigos from '../../assets/images/invita-amigos-mundial.jpg';

import CancelRegister from '../CancelRegister/CancelRegister';
import PageWrapper from '../PageWrapper';
import InvitarAmigos from '../InvitarAmigos/InvitarAmigos';
import SubscriptionRxs from '../../utils/subscriptions';
import RegisterPanel from '../RegisterPanel';

import WeeksControls from '../WeeksControls';

import { ShareButtons } from 'react-share';

import { UserDataContextConsumer } from '../../context/UserDataContext';

import ReactTooltip from 'react-tooltip';

import Clipboard from 'clipboard';

import SurvivorInfoContainer from '../SurvivorInfoContainer';
//import createHistory from 'history/createBrowserHistory';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

//const history = createHistory();
const history = require('history').createBrowserHistory();
let clipboardCode;
const {
  FacebookShareButton,
  TwitterShareButton,
} = ShareButtons;

/**
 * This component shows a panel to invite friends
 * @param title                 { String }
 * @param pool                  { Objeect }
 * @param openInvitePanel       { Function }
 * @param closePanel            { Function }
 * @param whatsAppInvitation    { Function }
 * @param branchLink            { String }
 */
const PanelInvitarAmigos = ({
  title,
  pool,
  openInvitePanel,
  closePanel,
  branchLink,
  whatsAppInvitation,
}) => {
  let isMundial = false;
  let inviteText = '';
  if (pool.id === 12408) {
    isMundial = true;
    title = 'INVITA A TUS AMIGOS Y GANA ENTRADAS AL MUNDIAL PERFECTO';
    inviteText =
      'YO ESTOY JUGANDO LA QUINIELA DEL MUNDIAL TE INVITO A JUGAR LA MEJOR QUINIELA DEL MUNDIAL CON BOLSA DE $3,000,000';
  }

  const userData = utils.getUserInfo();
  return (
    <div className="panel-abonar">
      <div id="panelTitle" className="panel-abonar-titulo">
        <span>
          <i className="ion-flag" />
          {title}
        </span>
        <button className="cerrar-panel" onClick={closePanel}>
          <div className="btn-icon-close">
            <i className="ion-ios-close-empty" />
          </div>
        </button>
      </div>
      <div className="col-xs-12 pickwin-panel">
        <div className="detalles-privadas-compartir-container">
          {pool.id === 12408 ? (
            <img src={invitaAmigos} className="img-responsive" />
          ) : (
            <div className="col-xs-12">
              <div className="panel-invite-title text-center">
                Comparte este link con tus amigos
              </div>
              <div className="flex-row row-center flex-container justify">
                <div className="codigo-amigo flex-col">
                  <input
                    id="copiar-clipboard"
                    value={
                      branchLink !== undefined
                        ? branchLink
                        : `https://www.pickwin.mx/lobby/quinielaPop/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
                    }
                  />
                </div>
                <div className="copiar-button-codigo flex-col col-15">
                  <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                    {' '}
                    Copia el enlace y compártelo
                  </ReactTooltip>
                  <button
                    data-tip
                    data-for="copiar-tool"
                    className="copiar-btn"
                    data-clipboard-target="#copiar-clipboard"
                  >
                    <i className="ion-ios-copy " />
                    <span className="visible-md">Copiar</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="btn-group inviteToPlay flex-row">
            <FacebookShareButton
              quote={
                !isMundial
                  ? `Participa en el survivor ${pool.name_replaced} visitando este link `
                  : inviteText
              }
              hashtag={'#Pickwin #registrate #JalaTusPicks'}
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/quiniela/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
              }
            >
              <Button className="fb-btn">
                {' '}
                Por Facebook <i className="ion-social-facebook" />
              </Button>
            </FacebookShareButton>
            <TwitterShareButton
              title={
                !isMundial
                  ? `Participa en el survivor ${pool.name_replaced} visitando este link `
                  : inviteText
              }
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/quiniela/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
              }
              hasthag={'#Pickwin #registrate #JalaTusPicks'}
            >
              <Button className="twitter-btn">
                {' '}
                Por Twitter <i className="ion-social-twitter" />
              </Button>
            </TwitterShareButton>
            <div className="SocialMediaShareButton">
              <Button className="mail-btn" onClick={openInvitePanel}>
                {' '}
                Por Mail <i className="ion-email" />
              </Button>
            </div>
            <div className="SocialMediaShareButton">
              <Button className="whatsapp-btn" onClick={whatsAppInvitation}>
                {' '}
                Por WhatsApp <i className="ion-social-whatsapp" />
              </Button>
            </div>
          </div>
          {pool.id === 12408 ? (
            <div className="flex-row">
              <div className="flex-col text-center">
                <small>
                  <strong>
                    *Por cada amigo que se registre a la quiniela de 3 millones, te regalamos una
                    entrada para jugar el mundial Perfecto
                  </strong>
                </small>
              </div>
            </div>
          ) : null}
        </div>
        <div className="btn-wrap">
          <Button className="btn-rojo gracias" onClick={closePanel}>
            No, gracias
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * This component contain all the data of the Survivor Selected *
 * @class SurvivorLobby
 */
class SurvivorLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cambiarEntradas: null,
      changedStatus: false,
      createdPrivate: null,
      defaultStandings: null,
      branchLink: null,
      emptyList: false,
      fixtures: [],
      invitarAmigosMail: false,
      items: [],
      liveClosedFixtures: null,
      liveFixturesData: Date.now(),
      loaded: false,
      loadedPicks: false,
      logged: false,
      mounted: false,
      updateMyPicks: false,
      survivor: null,
      survivorWeeks: null,
      weekSelected: null,
      redirectLobby: null,
      redirectLobbyWithEntry: false,
      redirectMisQuinielas: null,
      redirectToSame: null,
      searchNick: null,
      reRegister: false,
      selectedEntryId: null,
      selectedEntryData: null,
      selectedWeekFixtures: [],
      showInvite: false,
      showInvitePanel: false,
      showScorePanelRegister: false,
      showPanelCancel: false,
      showPanelFirstRegister: false,
      showReRegister: false,
      sortChanged: false,
      standings: null,
      submittingSearch: false,
      survivorEntries: null,
      survivorId: null,
      firstEntry: null,
      ticketsAvailable: null,
      temporalRegisterPicks: null,
      updatedStandings: false,
      userData: null,
      withRegister: false,
      standingsChanged: false,
      queryParams: {
        page: 1,
        limit: 100,
        nick: '',
        sort: [],
        uid: utils.isLogged() ? utils.getUserInfo().id : undefined,
      },

      activeSort: {
        posiciones: false,
        jugadores: false,
        alive: false,
        premios: false,
      },

      allFixtures: {},
      previousWeek: null
    };

    /**
     * Live Socket handlers
     */
    this.handleFixtureUpdateSocket = survivorUtils.handleFixtureUpdateSocket.bind(this);
    this.handleCloseSurvivor = survivorUtils.handleCloseSurvivor.bind(this);
    this.fixtureCloseSocket = survivorUtils.fixtureCloseSocket.bind(this);
    this.standingsUpdateSocket = survivorUtils.standingsUpdateSocket.bind(this);
    this.updatePrizeDistribution = survivorUtils.updatePrizeDistribution.bind(this);
    this.updateStandingsComplete = survivorUtils.updateStandingsComplete.bind(this);
    this.updateStandingsAganar = survivorUtils.updateStandingsAganar.bind(this);
    this.updateStandingsPosition = survivorUtils.updateStandingsPosition.bind(this);

    this.updatePlayerResults = survivorUtils.playersUpdateSocket.bind(this);
    this.updateRaceResults = survivorUtils.playersRaceUpdateSocket.bind(this);

    /**
     * This method initialize the socket to handle live data
     */

    this.initializeSocket = () => {
      console.log('%c Initialize Socket', 'color:rgb(46, 140, 207); font-size:2em;');
      console.log("INITSOCKETSTATE:", this.state);
      API.subscribeSocketLiveSurvivor(
        this.state.survivorId,
        (fixtureUpdateData) => {
          SubscriptionRxs.announceFixturesUpdated(fixtureUpdateData);
          this.handleFixtureUpdateSocket(fixtureUpdateData);
        },
        (closeSurvivorData) => {
          this.handleCloseSurvivor(closeSurvivorData);
        },
        (fixtureCloseData) => {
          SubscriptionRxs.announceFixtureCloseUpdated(fixtureCloseData);
          this.fixtureCloseSocket(fixtureCloseData);
        },
        (standingsUpdatedData) => {
          // let tempQueryParams = this.state.queryParams;
          // tempQueryParams.page = this.state.standings.pages;

          // this.setState({ queryParams: tempQueryParams });
          SubscriptionRxs.announceSurvivorStandingsUpdatedsUpdated(standingsUpdatedData);
          this.standingsUpdateSocket(standingsUpdatedData);
        },
        (statsData) => {
          if ( statsData.survivorId !== this.state.survivor.id ) { return; }

          // Soccer and NFL
          if (this.state.survivor.sport_id === 1 || this.state.survivor.sport_id === 2) {

            const weekId = statsData.weekId;
            const stats  = statsData.pickStats;

            if ( this.state.allFixtures?.pickStats) {
              this.state.allFixtures.pickStats[weekId] = stats
              this.setState({})
            }
          }
        }
      );
    };

    this.initializeContestsSocket = () => {
      API.subscribeLiveSurvivorContestSocket(
        this.state.survivorId,
        this.state.currentContest.id,
        (standingsUpdatedData) => {
          console.log('standings:update');
          SubscriptionRxs.announceSurvivorStandingsUpdatedsUpdated(standingsUpdatedData);
          SubscriptionRxs.announceSurvivorStandingsUpdatedsUpdatedF1(standingsUpdatedData);
          this.standingsUpdateSocket(standingsUpdatedData);
        },
        (closeSurvivorData) => {
          this.handleCloseSurvivor(closeSurvivorData);
        },
        (playerData) => {
          SubscriptionRxs.announceGroupPoolPlayersUpdated(playerData);
          this.updatePlayerResults(playerData);
        },
        (raceData) => {
          SubscriptionRxs.announceGroupPoolPlayersUpdatedF1(raceData);
          this.updateRaceResults(raceData);
        }
      );
    };

    this.handleRedirectToLobby = () => {
      this.setState({
        redirectToLobby: true,
      });
    };

    /**
     * this function handles the redirection from whats app click
     */
    this.whatsAppInvitation = () => {
      console.log('WhatsApp invitation');
      branchUtils.redirectToWhatsApp(this.state.branchLink, 'open', this.state.survivor.id);
    };

    /**
     * Standings handles
     * - Nick Search
     * - Infinite Scroller
     */
    this.changeSearchNick = (ev) => {
      // console.log('changeNick');
      let temporalQueryParams = this.state.queryParams;
      temporalQueryParams.nick = ev.target.value;
      this.setState({
        searchNick: ev.target.value,
        queryParams: temporalQueryParams,
      });
    };

    /**
     * This function fetch the standings with the modified query params
     * it hast o rewrite the standings data, and store the standigs default
     */
    this.fetchSearchableStandings = (ev, obj) => {
      if (!this.state.survivor === undefined) {
        return;
      }
      let temporalQueryParams = this.state.queryParams;

      temporalQueryParams.page = 1;
      this.setState({
        submittingSearch: true,
        queryParams: temporalQueryParams,
      });
      // if(this.state.submittingSearch === true ) { return;}
      // console.log('%c Search users', 'color:rgb(110, 223, 85);font-size:1.5em;');
      // console.log(ev.target.value);
      // console.log(ev.target);

      //let newPage = 1;
      API.fetchSurvivorStandings(this.state.survivor.id, temporalQueryParams)
        .then((response) => {
          // console.log(response);
          this.callbackSearchUser(response.data);
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error in quiniela so redirect',
          });
          this.resetNickValue();
          // this.nickReset();
          console.log(error);
        });
    };

    this.fetchSearchableStandings = _.debounce(this.fetchSearchableStandings, 500);

    /**
     * This function resets the searchLink property
     * and fetch the standings
     */
    this.resetNickValue = () => {
      let temporalQueryParams = this.state.queryParams;

      temporalQueryParams.nick = '';
      temporalQueryParams.page = 1;

      this.setState(
        {
          searchNick: '',
          submittingSearch: true,
          queryParams: temporalQueryParams,
        },
        () => {
          API.fetchSurvivorStandings(this.state.survivor.id, this.state.queryParams).then(
            (response) => {
              this.callbackSearchUser(response.data);
            }
          );
        }
      );
    };

    /**
     * This function handles the Sort order in the TablasPostions
     * @param type
     */
    this.sortPositionList = (type) => {
      console.log('%c Sort PositionList', 'color:rgb(254, 86, 150); font-size:1.2em;', type);
      this.setState({
        submittingSearch: true,
      });
      // console.log(type);
      let temporalQueryParams = this.state.queryParams;
      //let tempPositions = this.state.standings;
      let tempActiveSort = this.state.activeSort;
      //let tempItems = this.state.standings.items;

      let orderStatus;
      switch (type) {
        case 'posiciones':
          orderStatus = this.state.activeSort.posiciones ? -1 : 1;
          temporalQueryParams.sort = 'rank';
          // console.log(type, orderStatus);
          tempActiveSort.posiciones = !tempActiveSort.posiciones;
          if (tempActiveSort.posiciones) {
            tempActiveSort.jugadores = false;
            tempActiveSort.aciertos = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'jugadores':
          orderStatus = this.state.activeSort.jugadores ? -1 : 1;
          temporalQueryParams.sort = 'nick';
          // console.log(type, orderStatus);
          tempActiveSort.jugadores = !tempActiveSort.jugadores;
          if (tempActiveSort.jugadores) {
            tempActiveSort.posiciones = false;
            tempActiveSort.alive = false;
            tempActiveSort.premios = false;
          }
          break;
        case 'alive':
          orderStatus = this.state.activeSort.alive ? -1 : 1;
          temporalQueryParams.sort = 'alive';
          if (this.state.survivor.status === 'closed') {
            // console.log(type, orderStatus);
            tempActiveSort.alive = !tempActiveSort.alive;
            if (tempActiveSort.alive) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          } else {
            // console.log(type, orderStatus);
            tempActiveSort.alive = !tempActiveSort.alive;
            if (tempActiveSort.alive) {
              tempActiveSort.jugadores = false;
              tempActiveSort.posiciones = false;
              tempActiveSort.premios = false;
            }
          }
          break;
        case 'premios':
          orderStatus = this.state.activeSort.premios ? -1 : 1;
          temporalQueryParams.sort = 'prize';
          if (tempActiveSort.premios) {
            tempActiveSort.jugadores = false;
            tempActiveSort.posiciones = false;
            tempActiveSort.alive = false;
          }
          tempActiveSort.premios = !tempActiveSort.premios;
          break;
        default:
          return;
      }
      temporalQueryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
      temporalQueryParams.page = 1;
      this.setState(
        (prevState) => {
          console.log(tempActiveSort);
          console.log(prevState.activeSort);
          return {
            activeSort: tempActiveSort,
            sortChanged: !prevState.sortChanged,
            queryParams: temporalQueryParams,
            liveFixturesData: Date.now(),
            actualPage: 1,
            // standings: tempPositions,
          };
        },
        () => {
          API.fetchSurvivorStandings(this.state.survivor.id, this.state.queryParams)
            .then((response) => {
              console.log('Response', response.data);
              this.callbackSort(response.data);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                extra: 'Error in quiniela fetching standings',
              });
            });
          // this.queryParams.sort = [];
        }
      );
    };

    this.changeStatusPool = (poolId) => {
      let tempAvailablePools = this.state.survivorInfo;
      console.log('changeStatus Pool', tempAvailablePools);
      console.log('changeStatus Pool', poolId);
      tempAvailablePools.survivor.status = 'live';
      tempAvailablePools.survivor.time_left = 0;
      let tempFixtures = this.state.fixtures;
      tempFixtures[0].enabled = false;
      // tempFixtures[0].sort_status = 0;

      this.setState(
        {
          changePoolStatus: true,
          updatedPools: true,
          survivorInfo: tempAvailablePools,
          fixtures: tempFixtures,
          liveFixturesData: Date.now(),
        },
        () => {
          this.setState({
            changePoolStatus: false,
            liveFixturesData: Date.now(),
          });
        }
      );
    };

    /**
     * This function handles the max height of the components to set all the same height
     */
    this.handleMaxHeight = (section) => {
      // console.log(
      //   '%c Handling Height',
      //   'color:rgb(39, 190, 252);font-size:1.2:em;'
      // );
      // console.log($(window).width() <= 768);
      if ($(window).width() <= 768) {
        return;
      }
      let misPicksHeight = $('#picksContainer').height();
      let multipleContainerHeight = $('.multiple-quiniela-info').height();
      let tabContainerHeight = $('#multiple-quiniela-container .tab-container').height();
      //let upperSectionPicksGrupoHeight = $('.multiple-quiniela-info .upper-section').height();
      //let userRowHeight = $('.lista-container.ownUser').height();

      //let firstSectionHeight = $('.multiple-quiniela-info .first-section').height();

      //let positionListHeight = $('.position-list .overflow');
      if (misPicksHeight > multipleContainerHeight) {
        $('.lista-marcadores .overflow').animate(
          {
            height: misPicksHeight - tabContainerHeight - 30,
          },
          30
        );
      }

      // $('.lista-container .overflow').animate({
      //   height:misPicksHeight - tabContainerHeight - upperSectionPicksGrupoHeight - userRowHeight - 60 ,
      // },300);

      setTimeout(() => {
        // this.resizePositons();
        // this.resizePremios();
      }, 500);

      setTimeout(() => {
        if (misPicksHeight > multipleContainerHeight) {
          // $('.multiple-quiniela-info.principal ').animate({
          //   height: misPicksHeight - tabContainerHeight ,
          // },300);
        }
      }, 400);

      // console.log( misPicksHeight >= multipleContainerHeight ? 'Picks': 'Multiple')
    };

    /**
     * This function handlesThe size of the Prizes panel
     */
    this.resizePremios = () => {
      let firstSectionHeight = $('.multiple-quiniela-info .first-section').height();
      let premiosListTitleHeight = $('.premios-list .flex-row.title-quiniela').height();
      let premiosListTitleSubHeight = $('.premios-list .flex-col .title-quiniela').height();

      $('.premios-list').height(firstSectionHeight * 0.4 - 15);
      $('.premios-list .overflow').height(
        firstSectionHeight * 0.4 - 32 - premiosListTitleHeight - premiosListTitleSubHeight
      );
      $('.premios-list .lista-premios').height(
        firstSectionHeight * 0.4 - 30 - premiosListTitleHeight - premiosListTitleSubHeight
      );
    };

    /**
     * This function handles the Suze of the position panel
     */
    this.resizePositons = () => {
      let firstSectionHeight = $('.multiple-quiniela-info .first-section').height();
      let positionListHeaderHeight = $('.position-list .title-quiniela').height();
      let positionListSearchFieldHeight = $('.position-list #search-field-nick').height();
      let positionSrtableHeaderHeight = $('.position-list .position-header').height();
      //let positionListUserRow = $('.position-list .position-item.user').height();

      $('.position-list').height(firstSectionHeight * 0.6);

      $('.position-list .overflow').height(
        firstSectionHeight * 0.6 - positionListHeaderHeight,
        positionListSearchFieldHeight,
        positionSrtableHeaderHeight
      );
      $('.position-list .lista-posiciones.survivor').height(
        firstSectionHeight * 0.6 -
          positionListHeaderHeight -
          positionListSearchFieldHeight -
          positionSrtableHeaderHeight -
          5
      );
    };

    /**
     * This function handles a call to the API
     * to get the new page of the positions
     * @param eventKey
     */
    this.siguientePagPositionsStandings = (eventKey) => {
      // console.log('Next Positions...');
      let loaderElement = $('#loading');
      //let tempActiveSort = null;
      // console.log(loaderElement);
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'flex';
      }
      // loading.style.position('flex');
      let temporalPositions = this.state.standings.items;
      // console.log('siguiente');
      //let val = eventKey;

      let temporalQueryParams = this.state.queryParams;

      temporalQueryParams.page += 1;

      if (temporalQueryParams.page > this.state.standings.pages || this.state.submitting) {
        return;
      }

      API.fetchSurvivorStandings(this.state.survivor.id, temporalQueryParams).then((response) => {
        this.setState(
          (prevState) => {
            // console.log( tempActiveSort );
            let newPosiciones = response.data;

            let newItems = response.data.items !== null ? response.data.items : null;

            if (newItems !== null) {
              newItems.forEach((item) => {
                temporalPositions.push(item);
              });
            }

            if (this.state.survivor.status === 'live') {
              this.updateStandingsAganar(temporalPositions);
            }

            // console.log('NEWPos',temporalPositions);
            newPosiciones.items = temporalPositions;
            return {
              standings: newPosiciones,
              queryParams: temporalQueryParams,
            };
          },
          () => {
            if (loaderElement !== undefined && loaderElement.length > 0) {
              loaderElement[0].style.display = 'none';
              //let avatars = $('.score-avatars').height('auto');
            }
          }
        );
      });
    };

    /**
     * This function checks if the balance is enugh for a register
     */
    this.isBalanceEnough = () => {
      let balanceEnough = utils.isBalanceEnough(
        this.state.survivor.entry_currency,
        this.state.survivor.entry_fee
      );

      this.setState({
        enoughBalance: !balanceEnough && !this.state.ticketsAvailable.length ? false : true,
      });
    };

    /**
     * This functions handles the invite friends panel
     */
    this.showSendMail = () => {
      this.setState((prevState) => {
        return {
          invitarAmigosMail: !prevState.invitarAmigosMail,
        };
      });
    };

    this.hideInviteFriends = () => {
      console.log('Close');
      localStorage.removeItem('createdPrivate');
      this.setState({ createdPrivate: false });
    };

    /**
     * this function handles the callback of the Sort standings
     */
    this.callbackSort = (data) => {
      let newStandings = data;
      let newUpdatedPositions = newStandings.items;
      if (this.state.survivor.status === 'live') {
        newUpdatedPositions = this.updateStandingsAganar(
          newStandings.items,
          this.state.survivor.prize_currency,
          this.state.tiesDistribution,
          this.state.prizes,
          this.state.survivor
        );
      }

      // console.log('newstandings', newStandings);

      this.setState(
        {
          emptyList: data.items.length === 0 ? true : false,
          standings: newStandings,
          submittingSearch: false,
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    /**
     * This function handles the callback of a user search
     */
    this.callbackSearchUser = (data) => {
      // console.log(data);
      let newStandings = data;
      if (this.state.survivor.status === 'live' && this.state.queryParams.order !== 'nick') {
        // console.log('HERE');
        //let newUpdatedPositions = this.updateStandingsAganar(newStandings.items);
      }

      this.setState({
        emptyList: data.items.length === 0 ? true : false,
        standings: data,
        submittingSearch: false,
      });
    };

    /**
     * This function updates the State
     * of the fixture thtat just started
     * @param fixtureID
     */
    this.updateStartedFixture = (fixtureID, firstOfWeek) => {

      // Used for Soccer and NFL
      if (firstOfWeek) {
        return this.updateFirstOfWeek(firstOfWeek)
      }

      let indexStartedFixture;
      let tempFixturesList = this.state.fixtures;

      let currentPick = this.state.selectedEntryData[this.state.currentWeek];

      let startedFixt = tempFixturesList.find((fixt, i) => {
        if (fixt.id === fixtureID) {
          indexStartedFixture = i;
          return fixt;
        }
      });

      if (!startedFixt || !startedFixt.enabled) {
        return;
      }
      console.log('Fixt', startedFixt);
      startedFixt['sort_status'] = 0;
      startedFixt['enabled'] = false;
      startedFixt['enabled_survivor'] = false;
      
      tempFixturesList[indexStartedFixture] = startedFixt;

      if (currentPick && currentPick.fixture_id === fixtureID) {
        console.log('My pick is the same as the closed fixture');
        tempFixturesList = tempFixturesList.map((fixture) => {
          fixture['enabled'] = false;
          fixture['enabled_survivor'] = false;
        });
      }

      this.setState({
        fixtures: tempFixturesList,
      });
    };

    /**
     * This function updates the State of the first match
     * when the first match just started
     * @param fixtureID
     */
    this.updateStartedFirstMatchOfWeek = (firstMatch) => {

      this.setState(state => ({
        ...state,
        allFixtures: {
          ...state.allFixtures,
          fixtures: {
            ...state.allFixtures.fixtures,
            [firstMatch.week_id]: state.allFixtures.fixtures[firstMatch.week_id].map(fixture => {
              if (fixture.id === firstMatch.id) {
                return {
                  ...fixture,
                  survivor_time_left: 0,
                  enabled_survivor: false
                }
              }

              return fixture
            })
          }
        }
      }))
    }

    /**
     * This function updates the State of the allFixtures
     * when the week just started (time_left === 0)
     * @param fixtureID
     */
    this.updateFirstOfWeek = (firstOfWeek) => {
      this.setState(state => ({
        ...state,
        allFixtures: {
          ...state.allFixtures,
          firstsOfWeek: {
            ...state.allFixtures.firstsOfWeek,
            [firstOfWeek.week_id]: {
              ...state.allFixtures.firstsOfWeek[firstOfWeek.week_id],
              enabled: 0
            }
          }
        }
      }))
    }

    /**
     * Get the entry info to use it in the Register Panel
     * @param { Object } temporalEntry - The object that will be updated in the state
     *
     */
    this.updateTemporalRegisterPicks = (temporalEntry) => {
      console.log('updateTemporalRegisterPicks', temporalEntry);
      this.setState({ temporalRegisterPicks: temporalEntry });
    };

    /**
     * This function updates the selectedEntry
     * and gets the corresponding Data to update the state
     *
     * @param { Number } id - the id of the entry
     */
    this.changeSelectedEntry = (id) => {
      if (!id) {
        return;
      }


      console.log('%c ChangeSelected Entry', 'color:#fec830; font-size:2em;')
      // console.log(id);
      API.fetchSurvivorEntry(id)
        .then((response) => {
          // console.log(response)
          this.setState(
            {
              selectedEntryId: id,
              survivorEntries: response.data,
              selectedEntryData: response.data.survivor_entry,
            },
            () => {
              this.handleMaxHeight();
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error, { extra: 'Error changing Entry' });
        });
    };

    /**
     * This function updates the currentweek
     * and gets the corresponding fixtures to update the Form
     * and picks
     */
    this.changeSelectedWeek = (id) => {
      console.log('%c ChangeSelected Week', 'color:#fec830; font-size:2em;');
      console.log(id);
      this.setState({
        loadedPicks: false,
      });

      API.fetchSurvivorFixtures(id).then((response) => {
        this.setState(
          {
            selectedWeek: id,
            firstOf: response.data.firstOf,
            fixtures: response.data.fixtures,
            loadedPicks: true,
          },
          () => {
            setTimeout(() => {
              this.handleMaxHeight();
            }, 500);
          }
        );
      });
    };

    /**
     * This function replace the data in localStorage with
     * the data fetched from the api of
     */
    this.updateUserInfo = () => {
      console.log('UpdateInfo');
      API.fetchUserData()
        .then((response) => {
          utils.setUserInfo(response.data);
          this.setState({ userData: response.data });
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching user data',
          });
        });
    };

    /************************************
     * Handlers of the cancelation Panel
     * **********************************/
    this.closeCancelPanel = (e) => {
      this.setState({
        showPanelCancel: false,
      });
    };

    this.openCancelPanel = () => {
      this.setState({
        showPanelCancel: true,
      });
    };

    /***************************************
     * Handlers of register in ScoreBoard
     * ************************************/
    this.closeScorePanelRegister = () => {
      this.setState({
        showScorePanelRegister: false,
      });
    };

    this.openScorePanelRegister = () => {
      this.setState({
        showScorePanelRegister: true,
      });
    };

    this.closeScorePanelFirstRegister = () => {
      this.setState({
        showScorePanelRegister: false,
      });
    };

    this.openScorePanelFirstRegister = () => {
      this.setState({
        showScorePanelRegister: true,
      });
    };

    this.closeScorePanelReRegister = () => {
      console.log('closeReregister');
      this.setState({
        showScorePanelRegister: false,
        reRegister: false,
      });
    };

    this.openScorePanelReRegister = () => {
      console.log('openReregister');
      // this.updateTemporalRegisterPicks('newRegister');
      this.setState({
        showScorePanelRegister: true,
        reRegister: true,
      });
    };

    this.showInviteFriendsPanel = () => {
      console.log('showInviteFriends');
      this.setState({
        showInvitePanel: true,
      });
    };

    this.hideInviteFriendsPanel = () => {
      console.log('showInviteFriends');

      localStorage.removeItem('createdPrivate');
      this.setState({
        showInvitePanel: false,
      });
    };

    /**
     * This function has to update the info of the survivorEntries property in state
     * it has to update the path with the newRegistered Entry data
     * @param { Object } entryData - the corresponding data of the new entry
     *
     */
    this.refreshAfterGuardarInLobby = (entryData) => {
      console.log('RefreshAfterGuardar in Lobby', entryData);
      console.log('SurvivorEntries', this.state.survivorEntries);
      // this.updateUserInfo();
      let newEntry = !entryData.survivorEntries
        ? entryData.survivor_entry
        : entryData.survivorEntries[0];

      /****************************************************
       * If the user dont has Entries update the state and add the new one as the current
       * Else add the entry to the other_entries list
       ****************************************************************** */

      if (!newEntry) {
        return;
      }

      // Optimistic UI
      this.setState({
        selectedEntryData: {
          ...this.state.selectedEntryData,
          survivor_picks: entryData.survivor_picks
        }
      })

      API.fetchSurvivorEntry(newEntry.id)
      .then((newEntryResponse) => {
        // console.log(newEntryResponse);
        API.fetchSurvivorStandings(this.state.survivor.id)
          .then((response) => {
            API.fetchSurvivorDetails(this.state.survivor.id).then((responseDetails) => {
              this.setState(
                {
                  survivor: responseDetails.data.survivor,
                  updateMyPicks: true,
                  standings: response.data,
                  survivorEntries: newEntryResponse.data,
                  selectedEntryId: newEntryResponse.data.survivor_entry.id,
                  selectedEntryData: newEntryResponse.data.survivor_entry,
                  withRegister: true,
                },
                () => {
                  this.closeScorePanelFirstRegister();
                  history.push({
                    pathname: `/survivor/${this.state.survivor.slug}/${this.state.survivor.id}/${this.state.selectedEntryId}`,
                  });
                }
              );
            });
          })
          .catch((error) => {
            Sentry.captureException(error, {
              extra: 'Error fetching Standings after register',
            });
            // window.location.assign('/');
          });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching Standings after register',
        });
        // window.location.assign('/');
      });
    };

    /**
     * This function has to update the info of the survivorEntries property in state
     * it has to update the path with the first Entry data
     * @param { Object } entryData - the corresponding data of the new entry
     *
     */
    this.refreshAfterCancelInLobby = (remiainingEntries, firstEntryId, poolId, deletedEntries) => {
      console.log('DeletedEntries', deletedEntries, deletedEntries.length);
      console.log('Current SurvivorEntries', this.state.survivorEntries);

      let temporalEntries = this.state.survivorEntries;

      /**
       * Multiple deletion
       */
      if (deletedEntries.length > 1) {
        deletedEntries.forEach((delEntry) => {
          /**
           * Is the delEntry my CurrEntry?
           */
          if (temporalEntries.survivor_entry.id === delEntry.id) {
            console.log('Deleted The current  Entry');
            temporalEntries.survivor_entry = remiainingEntries[0];
          } else {
            console.log("delete multiple entries")
            let deletedEntryIndex = _.findIndex(
              temporalEntries.other_entries,
              (otherEntry) => otherEntry.id === delEntry.id
            );
            console.log(deletedEntryIndex);
            if (deletedEntryIndex === -1 || deletedEntryIndex === undefined) {
              return;
            }
            temporalEntries.other_entries.splice(deletedEntryIndex, 1);
          }
        });
      } else {
        /**
         * The deleted Entry is my current?
         */
        if (temporalEntries.survivor_entry.id === deletedEntries[0].id) {
          console.log('Deleted The current  Entry');
          temporalEntries.survivor_entry = remiainingEntries[0];
        } else {
          console.log("delete one entry");
          deletedEntries.forEach((delEntry) => {
            let deletedEntryIndex = _.findIndex(
              temporalEntries.other_entries,
              (otherEntry) => otherEntry.id === delEntry.id
            );
            console.log(deletedEntryIndex);
            if (deletedEntryIndex === -1 || deletedEntryIndex === undefined) {
              return;
            }
            temporalEntries.other_entries.splice(deletedEntryIndex, 1);
          });
        }
      }

      API.fetchSurvivorStandings(this.state.survivor.id, this.state.queryParams)
        .then((response) => {
          console.log('temporalEntries', temporalEntries);

          let temporalSurvivorInfo = this.state.survivor;

          temporalSurvivorInfo.entry_count -= deletedEntries.length;
          temporalSurvivorInfo.alive_count -= deletedEntries.length;
          temporalSurvivorInfo.available -= temporalSurvivorInfo.entry_fee * deletedEntries.length;

          this.setState(
            {
              survivor: temporalSurvivorInfo,
              standings: response.data,
              survivorEntries: temporalEntries,
              selectedEntryId: temporalEntries.survivor_entry.id,
              selectedEntryData: temporalEntries.survivor_entry,
            },
            () => {
              this.closeCancelPanel();
              history.push({
                pathname: `/survivor/${this.state.survivor.slug}/${this.state.survivor.id}/${this.state.selectedEntryId}`,
              });
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching SurvivorStandings after cancel ',
          });
          // window.location.assign('/');
        });
    };

    /**
     * This function fetches the branch deeplink
     */
    this.getBranchLink = () => {
      return branchUtils.createLink(utils.getUserInfo(), this.state.survivor, 'survivor');
    };

    /**
     *  This function updates the branch Link after it is ready
     */
    this.updateBranchLink = (newLink) => {
      console.log('UpdateBranchLink');
      this.setState({
        branchLink: newLink,
      });
    };

    /**
     * This function get the Corresponding data for the
     * selected survivor game when the user has an available entry
     *
     * @param { Number } survivorId - The id of the survivorGame
     * @param { Number }survivorEntryId - The id of the Entry that the user request
     *
     */
    this.getWithRegisterData = (survivorId, survivorEntryId) => {
      console.log('With Register');
      /**
       * Update user Data
       * fetchTickets
       * fetch SurvivorEntries
       * fetch survivorDetails
       * fetchWeek
       * fetch fixtures
       * fetch standings
       * fetch standingsGroup
       * fetch prizes
       * fetch Similar Entries
       */
      axios
        .all([
          API.fetchUserData(),
          API.fetchTickets(survivorId, 'survivor'),
          API.fetchSurvivorDetails(survivorId),
          API.fetchSurvivorEntry(survivorEntryId),
          API.fetchSurvivorTeams(survivorId),
          API.fetchSurvivorStandings(survivorId, this.state.queryParams),
          API.fetchSurvivorAllFixtures(survivorId)
        ])
        .then(
          axios.spread(
            (
              userData,
              tickets,
              survivorResponse,
              survivorEntry,
              survivorTeams,
              survivorStandings,
              allFixtures
            ) => {
              const responses = [
                userData,
                tickets,
                survivorResponse,
                survivorEntry,
                survivorTeams,
                survivorStandings,
                allFixtures
              ];
              try {
                utils.checkAxiosAllErrors(responses);
              } catch (e) {
                console.error(e);
                Sentry.captureException(e);
              }

              const { survivor, currentWeek, previousWeek } = survivorResponse.data

              let balanceEnough = utils.isBalanceEnough(
                survivor.entry_currency,
                survivor.entry_fee
              );


              this.setState({
                enoughBalance: !balanceEnough && !tickets.length ? false : true,
                survivor: survivor,
                survivorId: survivorId,
                standings: survivorStandings.data,
                survivorEntries: survivorEntry.data,
                selectedEntryData: survivorEntry.data.survivor_entry,
                selectedEntryId: survivorEntryId,
                prizes: survivorResponse.data.survivor_prizes,
                logged: utils.isLogged(),
                ticketsAvailable: null,
                userData: userData.data,
                withRegister: true,
                tiesDistribution: this.updatePrizeDistribution(
                  survivorResponse.data.ties_distribution,
                  survivor.available,
                  survivorResponse.data.survivor_prizes
                ),
                allFixtures: allFixtures.data,
                previousWeek
              });

              history.push({
                pathname: `/survivor/${this.state.survivor.slug}/${this.state.survivor.id}/${this.state.selectedEntryId}`,
              });

              if (survivor.sport_id !== 6 && survivor.sport_id !== 7) {
                API.fetchSurvivorFixtures(currentWeek.id)
                  .then((responseFixtures) => {
                    this.setState(
                      {
                        selectedWeek: currentWeek.id,
                        currentWeek: currentWeek,
                        firstOf: responseFixtures.data.firstOf,
                        fixtures: responseFixtures.data.fixtures,
                        survivorTeams: survivorTeams.data.teams,
                        survivorWeeks: survivor.weeks,
                        loadedPicks: true,
                        mounted: true,
                      },
                      () => {
                        this.handleMaxHeight();
                        // console.log('Private', this.state.createdPrivate)

                        if (this.state.survivor.status === 'live') {
                          let temporalStands = this.updateStandingsAganar(
                            this.state.standings.items,
                            this.state.survivor.prize_currency,
                            this.state.tiesDistribution,
                            this.state.prizes,
                            this.state.survivor
                          );
                          let tempStandings = this.state.standings;
                          tempStandings.items = temporalStands;

                          let myTemporalEntry = this.state.selectedEntryData;

                          myTemporalEntry.user_prize = myTemporalEntry.survivor_user_prize;

                          this.setState(
                            {
                              selectedEntryData: myTemporalEntry,
                              standings: tempStandings,
                              standingsChanged: true,
                            },
                            () => {
                              this.setState({
                                standingsChanged: false,
                              });
                            }
                          );
                        }

                        if (this.state.createdPrivate) {
                          console.log('showInvite');
                          this.getBranchLink().then((url) => {
                            this.showInviteFriendsPanel();
                            this.updateBranchLink(url, null, this.state.survivor.id);
                          });
                        }

                        this.initializeSocket();
                      }
                    );
                  })
                  .catch((error) => {
                    console.error(error);
                    //   this.setState({
                    //     redirect:true,
                    //   });
                    // Sentry.captureException(error, {extra:'Error fetching initial Data in Survivor Lobby'} );
                    // window.location.assign('/');
                  });
              } else {
                let currentContest = survivorResponse.data.currentContest;

                API.getSurvivorContestPlayers(currentContest.id).then((response) => {
                  let data = response.data;
                  this.setState(
                    {
                      currentContest: currentContest,
                      players: data.players,
                      mounted: true,
                    },
                    () => {
                      this.handleMaxHeight();
                      this.initializeContestsSocket();
                    }
                  );
                });
              }
            }
          )
        )
        .catch((error) => {
          console.error(error);

          // this.setState({
          //   redirect:true,
          // })

          // Sentry.captureException( error )
          // window.location.assign('/');
        });
    };

    /**
     * This function get the Corresponding data for the
     * selected survivor game when the user dont have an available entry
     *
     * @param { Number } survivorId - The id of the survivorGame
     *
     *
     */
    this.getWithoutRegisterData = (survivorId) => {
      console.log("withoutRegisterData");
      /**
       * Update user Data
       * fetchTickets
       * fetch survivorDetails
       * fetchWeek
       * fetch fixtures
       * fetch standings
       * fetch standingsGroup
       * fetch prizes
       */
      axios
        .all([
          API.fetchUserData(),
          API.fetchTickets(survivorId, 'survivor'),
          API.fetchSurvivorDetails(survivorId),
          API.fetchSurvivorTeams(survivorId),
          API.fetchSurvivorStandings(survivorId, this.state.queryParams),
          API.fetchFirstEntrySurvivor(survivorId),
          API.fetchSurvivorAllFixtures(survivorId)
        ])
        .then(
          axios.spread(
            (
              userData,
              tickets,
              survivorResponse,
              survivorTeams,
              standingsSurvivor,
              firstEntryResponse,
              allFixtures
            ) => {
              const responses = [
                userData,
                tickets,
                survivorResponse,
                survivorTeams,
                standingsSurvivor,
                firstEntryResponse,
                allFixtures
              ];

              try {
                utils.checkAxiosAllErrors(responses);
              } catch (e) {
                console.error(e);
                Sentry.captureException(e);
              }
              let hasEntry = false;
              let firstEntry = firstEntryResponse.data;

              if (
                firstEntry !== undefined &&
                firstEntry !== null &&
                firstEntry.survivor_entry !== null
              ) {
                hasEntry = true;
                API.fetchSurvivorEntry(firstEntry.survivor_entry.id)
                  .then((responseEntry) => {
                    const responses = [responseEntry];

                    try {
                      utils.checkAxiosAllErrors(responses);
                    } catch (e) {
                      console.error(e);
                      Sentry.captureException(e);
                    }
                    this.setState({
                      survivorEntries: responseEntry.data,
                      selectedEntryId: firstEntry.survivor_entry.id,
                      selectedEntryData: responseEntry.data.survivor_entry,
                      withRegister: hasEntry,
                    });
                    history.push({
                      pathname: `/survivor/${this.state.survivor.slug}/${this.state.survivor.id}/${this.state.selectedEntryId}`,
                    });
                    this.handleMaxHeight();
                    this.initializeSocket();
                  })
                  .catch((error) => {
                    // this.setState({
                    //   redirect:true,
                    // })
                    // Sentry.captureException( error );
                    console.error(error);
                  });
              }

              const { survivor, currentWeek, previousWeek } = survivorResponse.data

              let balanceEnough = utils.isBalanceEnough(
                survivor.entry_currency,
                survivor.entry_fee
              );

              console.log('Without Register');

              this.setState({
                enoughBalance: !balanceEnough && !tickets.data.tickets.length ? false : true,
                survivor: survivor,
                survivorId: survivorId,
                standings: standingsSurvivor.data,
                firstEntry: firstEntry.data,
                prizes: survivorResponse.data.survivor_prizes,
                logged: utils.isLogged(),
                ticketsAvailable: tickets.data.tickets,
                userData: userData.data,
                withRegister: hasEntry,
                tiesDistribution: this.updatePrizeDistribution(
                  survivorResponse.data.ties_distribution, 
                  survivor.available,
                  survivorResponse.data.survivor_prizes
                ),
                allFixtures: allFixtures.data,
                previousWeek
              });

              history.push({
                pathname: `/survivor/${this.state.survivor.slug}/${this.state.survivor.id}`,
              });

              console.log(survivor.sport_id);
              if (survivor.sport_id !== 6 && survivor.sport_id !== 7) {
                console.log('fech fixtures');
                API.fetchSurvivorFixtures(currentWeek.id)
                  .then((response) => {
                    console.log('Register Surv');
                    this.setState(
                      {
                        selectedWeek: currentWeek.id,
                        currentWeek: currentWeek,
                        firstOf: response.data.firstOf,
                        fixtures: response.data.fixtures,
                        survivorTeams: survivorTeams.data.teams,
                        survivorWeeks: survivor.weeks,
                        loadedPicks: true,
                        mounted: true,
                      },
                      () => {
                        // console.log(this.state.firstEntry);
                        // console.log('Private', this.state.createdPrivate)
                        if (this.state.createdPrivate) {
                          console.log('showInvite');
                          this.getBranchLink().then((url) => {
                            this.showInviteFriendsPanel();
                            this.updateBranchLink(url, null, this.state.survivor.id);
                          });
                        }
                      }
                    );
                  })
                  .catch((error) => {
                    console.error(error);
                    // this.setState({
                    //   redirect:true,
                    // })

                    // Sentry.captureException( error );
                    // window.location.assign('/');
                  });
              } else {
                let currentContest = survivorResponse.data.currentContest;

                API.getSurvivorContestPlayers(currentContest.id).then((response) => {
                  let data = response.data;
                  this.setState(
                    {
                      currentContest: currentContest,
                      players: data.players,
                      mounted: true,
                    },
                    () => {
                      this.handleMaxHeight();
                      this.initializeContestsSocket();
                    }
                  );
                });
              }
            }
          )
        )
        .catch((error) => {
          // this.setState({
          //   redirect:true,
          // })

          // console.error(error);
          Sentry.captureException(error);
          // window.location.assign('/');
        });
    };
  }

  componentWillUnmount() {
    console.log('Unmounting SurvivorLobby...');
    API.unSubscribeLiveSurvivorSocket();
  }
  componentDidMount() {
    gaUtils.handleDocumentAttributes(this.props.match);

    clipboardCode = new Clipboard('.copiar-btn');
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      console.log('wich', wich);
      $(wich).addClass('copied');
      e.clearSelection();
    });

    const createdPrivate = JSON.parse(localStorage.getItem('createdPrivate'));

    if (createdPrivate) {
      // if(this.props.location.state.hasOwnProperty('newSurvivorState'))
      console.log('%c Recently Created Survivor', 'color:#78bc28; font-size:2em;');
      this.setState({ createdPrivate: true });
    }

    const survivorId = this.props.match.params.survivorId;
    const survivorEntryId = this.props.match.params.survivorEntryId;

    this.setState({ survivorId: survivorId });
    if (utils.isLogged()) {
      //const userData = utils.getUserInfo();
      API.fetchUserData()
        .then((responseUserData) => {
          const responses = [responseUserData];

          try {
            utils.checkAxiosAllErrors(responses);
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
          }
          //  console.log('survivorId',survivorId,'EntryId',survivorEntryId,responseUserData)
          if (responseUserData.data.user) {
            /**
             * If the user has a register fetch the corresponding data
             */
            if (survivorEntryId && survivorId) {
              this.getWithRegisterData(survivorId, survivorEntryId);
            } else {
              this.getWithoutRegisterData(survivorId);
            }
          } else {
            // redirect to lobby
          }
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching userinitial Data in Survivor Lobby',
          });
          // window.location.assign('/');
          //Redirect to lobby
        });
      // console.log('Is Logged',utils.isLogged(), userData);
    } else {
      // Redirigir al lobby
    }
  }

  render() {
    let conditionalComponent = null;
    if (this.state.redirect) {
      conditionalComponent = <Redirect to={{ pathname: '/' }} />;
    } else if (this.state.mounted) {
      console.log('Here');

      conditionalComponent = (
        <PageWrapper>
          <UserDataContextConsumer>
            {({
              userData,
              userBalance,
              updateUserInfo,
              updateUserBalance,
              isLogged,
              handleTutorialStep,
            }) => {
              return (
                <div className="quina-lobby-wrapper">
                  <InvitarAmigos
                    pool={this.state.survivor}
                    showPanel={this.state.invitarAmigosMail}
                    userDataProps={userData}
                    updateStateFunc={this.showSendMail}
                  />
                  <Modal
                    dialogClassName="invitarAmigosModalDialog modal-dialog-xxxs"
                    className="invitarAmigosModal"
                    style={{marginTop: '4rem'}}
                    backdropClassName="invitarAmigosBackdrop"
                    show={this.state.showInvitePanel}
                    onHide={this.hideInvitePanel}
                  >
                    <PanelInvitarAmigos
                      title={` Invita a tus amigos a jugar el survivor ${this.state.survivor.name_replaced}`}
                      pool={this.state.survivor}
                      whatsAppInvitation={this.whatsAppInvitation}
                      openInvitePanel={this.showSendMail}
                      closePanel={this.hideInviteFriendsPanel}
                      branchLink={this.state.branchLink}
                    />
                  </Modal>

                  <div className="container">
                    <CancelRegister
                      allEntries={this.state.survivorEntries}
                      entries={this.state.survivorEntries}
                      logged={isLogged}
                      pool={this.state.survivor}
                      pools={null}
                      quinaID={this.state.survivorId}
                      refreshAfterCancel={this.refreshAfterCancelInLobby}
                      redirectAfterCancel={this.redirectAfterCancel}
                      redirectToLobby={this.redirectToLobby}
                      registerPicks={this.state.registerPicks}
                      registerState={this.registerState}
                      showPanel={this.state.showPanelCancel}
                      type="survivor"
                      updateStateFunc={this.closeCancelPanel}
                      updateUserInfo={updateUserInfo}
                      userDataProps={userData}
                    />
                    <RegisterPanel
                      handleTutorialStep={handleTutorialStep}
                      closePanel={this.closeScorePanelRegister}
                      entryId={this.state.selectedEntryId}
                      handleConfirmation={this.handleConfirmation}
                      logged={isLogged}
                      payType={this.state.survivor.entry_currency}
                      pool={this.state.survivor}
                      pools={null}
                      type={'survivor'}
                      popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                      quinaID={this.state.survivorId}
                      refreshAfterGuardar={this.refreshAfterGuardarInLobby}
                      registerPicks={this.state.temporalRegisterPicks}
                      returnErrors={this.returnErros}
                      reRegister={this.state.reRegister}
                      showPanel={this.state.showPanelReRegister}
                      showPanelFirstRegister={this.state.showScorePanelRegister}
                      showPanelReRegister={this.state.showScorePanelRegister}
                      showTutorial={this.showTutorial}
                      updatePanelFirstRegister={this.closeScorePanelFirstRegister}
                      updatePanelReRegister={this.closeScorePanelReRegister}
                      updateStateFunc={this.closeScorePanelRegister}
                      updateUserInfo={updateUserInfo}
                      userData={userData}
                      userBalance={userBalance}
                      withRegister={this.state.withRegister}
                    />
                    <MediaQuery query="(max-width: 659px)">
                      <ScoreMobile
                        changeSelectedEntry={this.changeSelectedEntry}
                        changeStatusPool={this.changeStatusPool}
                        entries={this.state.survivorEntries}
                        handleCancelPanel={this.closeCancelPanel}
                        handleClickFirstRegister={this.openScorePanelFirstRegister}
                        handleClickReRegister={this.openScorePanelReRegister}
                        liveClosedFixtures={this.state.liveClosedFixtures}
                        withRegister={this.state.withRegister}
                        logged={isLogged}
                        poolInfo={this.state.survivor}
                        pools={this.state.pools}
                        popup={false}
                        quinaID={this.state.survivorId}
                        showInvite={this.showInviteFriendsPanel}
                        hideInvite={this.hideInviteFriendsPanel}
                        redirectToLobby={this.redirectToLobby}
                        refreshAfterCancel={this.refreshAfterCancelInLobby}
                        selectedEntry={this.state.selectedEntryId}
                        updateClosedQuiniela={this.updateClosedQuiniela}
                        updateStateFuncCancel={this.openCancelPanel}
                        updateUserInfo={updateUserInfo}
                        updateBranchLink={this.updateBranchLink}
                        status={this.state.survivor.status}
                      />
                    </MediaQuery>
                    <MediaQuery query="(min-width: 660px)">
                      <Score
                        changeSelectedEntry={this.changeSelectedEntry}
                        changeStatusPool={this.changeStatusPool}
                        entries={this.state.survivorEntries}
                        handleCancelPanel={this.closeCancelPanel}
                        handleClickFirstRegister={this.openScorePanelFirstRegister}
                        handleClickReRegister={this.openScorePanelReRegister}
                        liveClosedFixtures={this.state.liveClosedFixtures}
                        withRegister={this.state.withRegister}
                        logged={isLogged}
                        poolInfo={this.state.survivor}
                        pools={this.state.pools}
                        popup={false}
                        quinaID={this.state.survivorId}
                        redirectToLobby={this.redirectToLobby}
                        refreshAfterCancel={this.refreshAfterCancelInLobby}
                        selectedEntry={this.state.selectedEntryId}
                        showInvite={this.showInviteFriendsPanel}
                        hideInvite={this.hideInviteFriendsPanel}
                        updateBranchLink={this.updateBranchLink}
                        updateClosedQuiniela={this.updateClosedQuiniela}
                        updateStateFuncCancel={this.openCancelPanel}
                        updateUserInfo={updateUserInfo}
                        status={this.state.survivor.status}
                        ticketsAvailable={this.state.ticketsAvailable}
                      />
                    </MediaQuery>

                    {/* -------- Botones que controlan la semana que se esta visualizando -------- */}
                    {(this.state.survivor.sport_id !== 6 &&
                      this.state.survivor.sport_id !== 7 &&
                      this.state.survivor.sport_id !== 1 &&
                      this.state.survivor.sport_id !== 2) &&
                    (this.state.withRegister || utils.isSuPick()
                  ) ? (
                      <WeeksControls
                        label="Selecciona una jornada"
                        currentWeek={this.state.currentWeek.id}
                        selectedWeek={this.state.selectedWeek}
                        weeks={this.state.survivorWeeks}
                        handleWeekChange={this.changeSelectedWeek}
                      />
                    ) : null}
                    <SurvivorInfoContainer
                      handleTutorialStep={handleTutorialStep}
                      activeSort={this.state.activeSort}
                      standingsChanged={this.state.standingsChanged}
                      tiesDistribution={this.state.tiesDistribution}
                      sortChanged={this.state.sortChanged}
                      firstOf={this.state.firstOf}
                      updateMyPicks={this.state.updateMyPicks}
                      changeSearchNick={this.changeSearchNick}
                      changeSelectedEntry={this.changeSelectedEntry}
                      changeSelectedWeek={this.changeSelectedWeek}
                      currentWeek={this.state.currentWeek}
                      enoughBalance={this.state.enoughBalance}
                      entries={this.state.survivorEntries}
                      entriesInfo={this.state.survivorEntries}
                      fetchSearchableStandings={this.fetchSearchableStandings}
                      fixtures={this.state.fixtures}
                      handleMaxHeight={this.handleMaxHeight}
                      loadedPicks={this.state.loadedPicks}
                      prizes={this.state.prizes}
                      refreshAfterGuardarInLobby={this.refreshAfterGuardarInLobby}
                      resetNickValue={this.resetNickValue}
                      selectedEntryData={this.state.selectedEntryData}
                      selectedEntryId={this.state.selectedEntryId}
                      selectedWeek={this.state.selectedWeek}
                      sortPositionList={this.sortPositionList}
                      siguientePagPositionsStandings={this.siguientePagPositionsStandings}
                      standGroups={this.state.standGroups}
                      standings={this.state.standings}
                      submittingSearch={this.state.submittingSearch}
                      survivorId={this.state.survivorId}
                      survivorInfo={this.state.survivor}
                      survivor={this.state.survivor}
                      survivorTeams={this.state.survivorTeams}
                      survivorWeeks={this.state.survivorWeeks}
                      isBalanceEnough={this.isBalanceEnough}
                      updateStartedFixture={this.updateStartedFixture}
                      survivorWeekSelected={this.state.survivorWeekSelected}
                      updateTemporalRegisterPicks={this.updateTemporalRegisterPicks}
                      updateUserInfo={updateUserInfo}
                      userData={userData}
                      withRegister={this.state.withRegister}
                      currentContest={this.state.currentContest}
                      players={this.state.players}
                      allFixtures={this.state.allFixtures}
                      previousWeek={this.state.previousWeek}
                      updateStartedFirstMatchOfWeek={this.updateStartedFirstMatchOfWeek}
                    />
                  </div>
                </div>
              );
            }}
          </UserDataContextConsumer>
        </PageWrapper>
      );
    } else {
      conditionalComponent = <Loading label="Cargando información" />;
    }
    return conditionalComponent;
  }
}

export default SurvivorLobby;
