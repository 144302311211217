import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Nav,
  NavItem
} from 'react-bootstrap/lib/';

import numeral from 'numeral';

import {
  DisplayFlag,
  TablaPosicionesMisQuinielas,
  TablaPosicionesMisQuinielasWeekly,
} from '../../utils/quinielasComponents';

import API from '../../utils/api';
import Loading from '../Loading/Loading';
import Utils from '../../utils/quinielasUtils';
import utils from '../../utils/utils';
import Futbol from '../../assets/images/FutbolBall-50.png';
import Americano from '../../assets/images/AmericanoBall-50.png';
import Basketball from '../../assets/images/BasketballBall-50.png';
import Baseball from '../../assets/images/BaseballBall-50.png';
import Golf from '../../assets/images/GolfBall-50.png';
import Tenis from '../../assets/images/TennisBall-50.png';
import Box from '../../assets/images/box.png';
import F1 from '../../assets/images/Volante-50.png';
import Hockey from '../../assets/images/Hockey.png';
import closeW from '../../assets/images/close.png';

import firstBy from 'thenby';

import SubscriptionRxs from '../../utils/subscriptions';

import QuinielaPicksUI from '../QuinielaPicksUI/QuinielaPicksUI';
import { QuinielaDataProvider } from '../../context/QuinielaDataContext';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';

const ConfirmationOverlay = ({
  isError,
  errorMessage,
  isCopy,
  showOverlay,
  currentSavedPicks,
  allPicks,
}) => {
  let picksStatus = null;
  let iconStatus = null;
  if (isError) {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          <strong className="title">¡Tenemos un error al guardar tus picks!</strong>
        </div>
        <div className="flex-container justify">
          {!errorMessage ? 'Por favor inténtalo nuevamente' : errorMessage}
        </div>
      </div>
    );
    iconStatus = <i className="ion-alert-circled" />;
  } else if (isCopy) {
    picksStatus = (
      <div className="flex-container column align justify">
        <div className="flex-container justify">
          <strong className="title">¡Tus picks se copiaron con éxito!</strong>
        </div>
        <div className="flex-container justify">Recuerda guardar tus picks.</div>
      </div>
    );
  } else {
    if (currentSavedPicks !== allPicks) {
      picksStatus = (
        <div className="flex-container column align justify">
          <div className="flex-container justify">
            {' '}
            <strong className="title">¡Picks Guardados!</strong>
          </div>
          <div className="flex-container justify">
            {' '}
            Tienes {currentSavedPicks}/{allPicks} picks guardados.{' '}
          </div>
          <div className="flex-container justify">Recuerda ingresar todos tus picks. </div>
        </div>
      );
      iconStatus = <i className="ion-alert-circled" />;
    } else {
      /**
       * Complete Picks
       **/
      picksStatus = `¡Felicidades tus picks están completos!`;
      iconStatus = <i className="ion-checkmark-circled" />;
    }
  }
  return (
    <div
      className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}
       ${isError ? 'error' : ''}  ${isCopy ? 'copy' : ''}`}
    >
      <span>
        {' '}
        {iconStatus} {picksStatus}{' '}
      </span>
    </div>
  );
};

class MiQuinielaPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copiarPicksExito: null,
      entriesInfo: [],
      errorCopiar: null,
      errorGuardarPicks: null,
      estadoDesempates: false,
      estadoPicks: false,
      estadoProps: false,
      estadoRegistro: false,
      fixtures: [],
      parley: [],
      parlayDeadlines: [],
      firstFixture: null,
      grouStandings: [],
      guardarPicksExito: null,
      loaded: false,
      my_pools: null,
      numeroEntries: this.props.numeroEntries,
      liveFixturesData: this.props.liveFixturesData,
      picks: [],
      picks_props: [],
      poolInfo: [],
      poolWeeks: [],
      poolWeekSelected: null,
      premios: [],
      quinaIndex: null,
      redirectLobby: false,
      selectedWeekfixtures: [],
      selectedEntryData: null,
      showCopy: false,
      showOverlay: false,
      similarPools: [],
      standings: [],
      submitting: false,
      temporalRegisterPicks: null,
      tieScoreAway: null,
      tieScoreHome: null,
      tiesData: [],
      tieWeekGoals: null,
      weeklyStandings: null,
      weeklyLoaded: false,
      weeklyStandingsActive: false,
      currentSavedPicks: null,
      successFollow: false,
      errorFollow: false,
    };
    // this.changeWeek = this.changeWeek.bind(this);
    this.copiarPicks = Utils.copiarPicks.bind(this);
    this.copyTies = Utils.copyTies.bind(this);
    this.estadoDesempates = Utils.estadoDesempates.bind(this);
    this.estadoPicks = Utils.estadoPicks.bind(this);
    this.estadoProps = Utils.estadoProps.bind(this);
    this.getAganar = Utils.getAganar.bind(this);
    this.getAganarWeekly = Utils.getAganarWeekly.bind(this);
    this.getWinningPick = Utils.getWinningPick.bind(this);
    this.getWinningPickLive = Utils.getWinningPickLive.bind(this);
    this.getWinningProp = Utils.getWinningProp.bind(this);
    this.guardarPicks = Utils.guardarPicks.bind(this);
    this.initializeTies = Utils.initializeTies.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleCopiarPicks = this.handleCopiarPicks.bind(this);
    this.handleWeeklyActive = this.handleWeeklyActive.bind(this);
    this.redirectLobby = this.redirectLobby.bind(this);
    this.selectLayout = this.selectLayout.bind(this);
    this.setLoaded = this.setLoaded.bind(this);
    this.setSport = this.setSport.bind(this);
    this.setWeekName = Utils.setWeekName.bind(this);
    this.updateData = this.updateData.bind(this);
    this.updateStandings = this.updateStandings.bind(this);
    this.updatePicks = Utils.updatePicks.bind(this);
    this.updateStandingsAganar = Utils.updateStandingsAganar.bind(this);
    this.updateStandingsFixturePoints = Utils.updateStandingsFixturePoints.bind(this);
    this.updateStandingsPosition = Utils.updateStandingsPosition.bind(this);
    this.updateStandingsPropsPoints = Utils.updateStandingsPropsPoints.bind(this);
    this.updateTieData = Utils.updateTieData.bind(this);
    this.disableClickedButton = Utils.disableClickedButton.bind(this);
    this.changeStatusPool = this.changeStatusPool.bind(this);
    // console.log('MiquinielaPanel');
    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      order: null,
      uuid: utils.isLogged() ? utils.getUserInfo().id : undefined,
    };
    this.followUser = (id) => {
      console.log(' %c Follow User +  ', 'color:rgb(223, 75, 38) ; font-size:2em ;');
      console.log(id);
      API.followUser(id)
        .then((response) => {
          console.log(response);
          if (response.data.message === 'ok') {
            if (!this.state.successFollow) {
              this.setState({
                successFollow: [{ id, status: true }],
                liveFixturesData: Date.now(),
              });
            } else {
              let temSuccessFollow = [...this.state.successFollow, { id, status: true }];
              this.setState({
                successFollow: temSuccessFollow,
                liveFixturesData: Date.now(),
              });
            }
          }
        })
        .catch((e) => {
          if (!this.state.errorFollow) {
            this.setState({
              errorFollow: [{ id, status: true }],
              liveFixturesData: Date.now(),
            });
          } else {
            let temErrorFollow = [...this.state.errorFollow, { id, status: true }];
            this.setState({
              errorFollow: temErrorFollow,
              liveFixturesData: Date.now(),
            });
          }
          console.error(e);
          Sentry.captureException(e);
        });
    };

    this.updateWeeklyStandingsAganar = (posiciones, tiesDistribution) => {
      console.log('Update Weekly Standings');

      // console.log(tiesDistribution, posiciones);
      if (!tiesDistribution || !posiciones) {
        return posiciones;
      }

      // let tiesDistribution = this.state.poolWeeks.ties_distribution;

      let typeWeekPrize = this.state.poolInfo.pool.weekly_prize_scheme;

      let temporalPosiciones = posiciones;

      let maxPrizedPosition = typeWeekPrize === 'first' ? 1 : 3;

      let prizes =
        typeWeekPrize === 'first'
          ? [{ amount: null, type: 'percentage', percentage: 1 }]
          : [
              { amount: null, type: 'percentage', percentage: 0.5 },
              { amount: null, type: 'percentage', percentage: 0.3 },
              { amount: null, type: 'percentage', percentage: 0.2 },
            ];


      let weeklyPrizeNum =
        (this.state.poolInfo.pool.available *
          (this.state.poolInfo.pool.weekly_percentage / 10000)) /
        this.state.poolWeeks.weeks.length;

      console.log(weeklyPrizeNum);
      //let weeklyPrize = numeral(weeklyPrizeNum).format('$0,000.00');

      // let rankTies = _.groupBy(temporalPosiciones, 'rank');
      _.map(tiesDistribution, function(ties) {
        ties.percentage = 0;
        ties.amount = 0;
      });
      _.map(temporalPosiciones, function(standing) {
        standing.weekly_prize.amount = 0;
      });

      _.each(tiesDistribution, function(ties) {
        if (ties.rank > maxPrizedPosition) {
          return;
        } else {
          let rank = ties.rank;
          let numTies = ties.ties;
          let acc = 0;

          for (let i = rank; i < rank + numTies; i++) {
            let prize = prizes[i - 1];
            if (!prize) {
              continue;
            }
            acc += prize.percentage;
          }

          ties.percentage = acc;
          ties.amount = (weeklyPrizeNum * acc) / numTies;

          _.chain(temporalPosiciones)
            .where({ rank: ties.rank })
            .map(function(_standing) {
              _standing.weekly_prize.amount = ties.amount;
            });
        }
      });

      return temporalPosiciones;
    };

    /**
     * This function updates the State
     * of the fixture thtat just started
     * @param fixtureID
     */
    this.updateStartedFixture = (fixtureID) => {
      console.log('updated started fixture', fixtureID, 'Started');
      if (!this.state.fixtures) {
        return;
      }
      // console.log('fixture', fixtureID, 'Started');
      let indexStartedFixture;
      let tempFixturesList = this.state.fixtures;
      // console.log(this.state.fixtures);
      let startedFixt = tempFixturesList.find((fixt, i) => {
        if (fixt.id === fixtureID) {
          indexStartedFixture = i;
          return fixt;
        }
      });
      if (!startedFixt || !startedFixt.enabled) {
        return;
      }

      startedFixt['sort_status'] = 0;
      startedFixt['enabled'] = false;
      tempFixturesList[indexStartedFixture] = startedFixt;
      this.setState({
        fixtures: tempFixturesList,
        liveFixturesData: Date.now(),
      });
    };

    /**
     * This function calls the appi to reload the Fixtures of the selected Week
     * @param weekID
     */
    this.changeWeek = (weekID) => {
      console.log('WEeks Updated', weekID);
      // this.setState({ loaded: false , });
      //let temporalWeeklyStandings, temporalWeekFixtures, oldPicks;

      if (this.state.poolInfo.pool.type === 'full' && this.state.poolInfo.pool.weekly_prize) {
        axios
          .all([
            API.fetchWeekFixtures(this.state.poolInfo.pool.id, weekID),
            API.fetchWeeklyStandingsPartial(this.state.poolInfo.pool.id, weekID),
          ])
          .then(
            axios.spread((temporalWeekFixtures, temporalWeeklyStandings) => {
              let poolWeek =
                weekID === this.state.poolWeeks.current.id
                  ? this.state.poolWeeks.current
                  : this.state.poolWeeks.weeks.filter((w) => w.id === weekID)[0];

              console.log('------PoolWeek updated-------');
              console.log(poolWeek);
              let currentWeek =
                this.state.poolWeeks.current.id === weekID
                  ? this.state.poolWeeks.current
                  : this.state.poolWeeks.weeks.find((w) => w.id === weekID);
              console.log(currentWeek);
              this.setState({
                loaded: true,
                poolWeekSelected: weekID,
                fixtures: temporalWeekFixtures.data.fixtures,
                firstFixture: temporalWeekFixtures.data.first,
                weeklyStandings: temporalWeeklyStandings.data,
                currentWeek: currentWeek,
                poolWeek,
              });
            })
          )
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error, {
              extra: 'Error fetching Axios all in Mi Quiniela',
            });
            this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');

            this.props.closeMiQuina(this.props.index);
          });
      } else {
        API.fetchWeekFixtures(this.state.poolInfo.pool.id, weekID)
          .then((temporalWeekFixtures) => {
            let currentWeek =
              this.state.poolWeeks.current.id === weekID
                ? this.state.poolWeeks.current
                : this.state.poolWeeks.weeks.find((w) => w.id === weekID);
            console.log('currentWeek');
            console.log(currentWeek);
            this.setState({
              // loaded: true,
              poolWeekSelected: weekID,
              fixtures: temporalWeekFixtures.data.fixtures,
              firstFixture: temporalWeekFixtures.data.first,
              currentWeek: currentWeek,
            });
          })
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error, {
              extra: 'Error fetchingWeeks in MisQuinielas',
            });
            this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');

            this.props.closeMiQuina(this.props.index);
          });
      }
    };

    this.updateEntryData = () => {
      // console.log('MiquinielaPanel updateEntryData');
      API.getEntriesInfo(this.state.selectedEntryData.id)
        .then((dataEntriesInfo) => {
          try {
            utils.checkAxiosAllErrors([dataEntriesInfo]);
          } catch (e) {
            console.error(e);
            // Sentry.captureException( e );
          }
          let totalPicks =
            dataEntriesInfo.data.entry.pick_count + dataEntriesInfo.data.entry.pick_prop_count;
          this.setState(
            {
              entriesInfo: dataEntriesInfo.data,
              totalPicks: totalPicks,
              selectedEntryData: dataEntriesInfo.data.entry,
              // similarPools          : dataSimilarEntries.data,
            },
            () => {
              this.handleConfirmation(totalPicks);
              // this.props.afterUpdatePanels();
              // this.setLoaded();
              // console.log('data Updated !');
            }
          );
        })
        .catch((error) => {
          console.error(error);

          Sentry.captureException(error);
          this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');
          this.props.closeMiQuina(this.props.index);
        });
    };
  }

  changeStatusPool(poolId, fixtureID, sortStatus) {
    let tempAvailablePools = this.state.poolInfo;
    // console.log('changeStatus Pool', tempAvailablePools);
    // console.log('changeStatus Pool', poolId, fixtureID);
    tempAvailablePools.pool.status = 'live';
    tempAvailablePools.pool.upcoming = false;
    tempAvailablePools.pool.time_left = 0;

    let tempFixtures = this.state.fixtures;

    if (fixtureID) {
      tempFixtures.forEach((f) => {
        if (f.id === fixtureID) {
          f.enabled = false;
          f.time_left = 0;
          f.sort_status = sortStatus;
        }
      });
    } else {
      tempFixtures[0].enabled = false;
      tempFixtures[0].time_left = 0;
      tempFixtures[0].sort_status = sortStatus;
    }
    // tempFixtures[0].sort_status = 0;
    let tempFixturesProps = [];
    this.state.fixtureProps.forEach((fixtProps) => {
      if (fixtProps.fixture_id === fixtureID) {
        fixtProps.enabled = false;
        fixtProps.fixture.enabled = false;
        fixtProps.fixture.time_left = 0;
        fixtProps.sort_status = sortStatus;
        // fixtProps.sort_status = 0;
        tempFixturesProps.push(fixtProps);
      } else {
        tempFixturesProps.push(fixtProps);
      }
    });
    this.setState(
      {
        changePoolStatus: true,
        updatedPools: true,
        poolInfo: tempAvailablePools,
        fixtures: tempFixtures,
        liveFixturesData: Date.now(),
      },
      () => {
        this.setState({
          changePoolStatus: false,
          liveFixturesData: Date.now(),
        });
      }
    );
  }

  /**
   * Change Type of Tablas de Posicones
   * @param eventKey
   */
  handleWeeklyActive(eventKey) {
    console.log(eventKey);
    if (eventKey === 1) {
      // API.getEntriesInfo(this.state.myEntry.id)
      // .then(response => {
      //   this.setState({
      //     entriesInfo: response.data,
      //     weeklyStandingsActive: false,
      //   })
      // })
      this.setState({
        // entriesInfo: response.data,
        weeklyStandingsActive: false,
      });
    } else if (eventKey === 2) {
      let temporalWeekStandings = this.state.weeklyStandings;
      console.log(this.state.weeklyStandings, this.state.poolWeeks);
      // let updatedWeeklyPosition = this.updateWeeklyStandingsAganar( this.state.weeklyStandings.items, this.state.poolWeeks.ties_distribution );
      // console.log(updatedWeeklyPosition);
      // temporalWeekStandings.items = updatedWeeklyPosition;
      this.setState({
        weeklyStandings: temporalWeekStandings,
        weeklyStandingsActive: true,
      });
    }
  }

  /**
   * This function sets a timeout to hide the overlay of saved picks
   */
  handleCopyConfirmation() {
    console.log('handleConfirmationCopy');
    setTimeout(() => {
      this.setState({
        errorCopiarPicks: null,
        copiarPicksExito: null,
      });
    }, 2000);
  }

  /**
   * This function sets a timeout to hide the overlay of saved picks
   */
  handleConfirmation(totalPicks) {
    console.log('handleConfirmation', totalPicks);

    this.props.updatePanels(this.props.entry.id, this.state.currentSavedPicks);

    this.setState(
      {
        showOverlay: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorGuardarPicks: false,
            showOverlay: false,
          });
        }, 3000);
      }
    );
    // }, 100);
  }

  /**
   * This function sets a timeout to handle the overlay of copied picks
   */
  handleCopiarPicks() {
    // console.log('handleCopiarPicks');
    setTimeout(() => {
      this.setState({
        currentSavedPicks:
          this.state.clipboardPicks.pick_count + this.state.clipboardPicks.pick_prop_count,
        copiarPicksExito: null,
      });
    }, 1800); // this.setState({
    //     showConfirmation:true,
    // })
  }

  /**
   * This function change the redirection state
   */
  redirectLobby() {
    this.setState({
      redirectLobby: true,
    });
  }

  /**
   * This function gets the sport  icon of the current quiniela
   * @param sport_id
   * @returns img tag
   */
  setSport(sport_id) {
    switch(sport_id) {
      case 1:
        return  <img className="title-ball-icon" src={Futbol}/>
      case 2:
        return  <img className="title-ball-icon" src={Americano}/>
      case 3:
        return  <img className="title-ball-icon" src={Basketball}/>
      case 4:
        return  <img className="title-ball-icon" src={Baseball}/>
      case 5:
        return  <img className="title-ball-icon" src={Tenis}/>
      case 6:
        return  <img className="title-ball-icon" src={Golf}/>
      case 7:
        return  <img className="title-ball-icon" src={F1}/>
      case 8:
        return  <img className="title-ball-icon" src={Box}/>
      case 9:
        return  <img className="title-ball-icon" src={Hockey}/>
    }
  }

  setLoaded() {
    // console.log('Set Loaded');
    this.setState(
      {
        loaded: true,
        liveFixturesData: this.props.liveFixturesData,
      },
      () => {
        this.props.loadedPanel(
          this.state.poolInfo.pool.id,
          this.state.myEntry.id,
          'pool',
          this.state.poolInfo.pool.status
        );
      }
    );
  }

  selectLayout(status, isFull, isClosed, isMixed) {
    // console.log('Selecting Layout...');
    // console.log('Status...', status);
    // console.log('Full...', isFull);
    // console.log('Closed...',isClosed);
    // console.log('Mixed...', isMixed);
    let moneyFormat = this.state.poolInfo.pool.entry_currency === 'real' ? '$0,000' : '0,000';

    let conditionalComponent = null;
    conditionalComponent = (
      <div
        key={this.props.entry.id}
        ref="picksQuinielas"
        id={`picksContainer-${this.props.entry.id}`}
        className={`flex-col dashboard-item ${status}`}
        style={{flex: 'none'}}
      >
        <QuinielaDataProvider
          updateStartedFixture={this.changeStatusPool}
          enoughBalance={this.props.enoughBalance}
          entriesInfo={this.state.entriesInfo}
          fixtureProps={this.state.fixtureProps}
          fixtures={this.state.fixtures}
          firstFixture={this.state.firstFixture}
          premios={this.state.premios}
          poolInfo={this.state.poolInfo}
          poolWeeks={this.state.poolWeeks}
          poolWeekSelected={this.state.poolWeekSelected}
          userData={this.state.userData}
          standings={this.state.standings}
          updateUserInfo={this.props.updateUserInfo}
          withRegister={true}
        >
          <div className="panelMisQuinielas">
            <ConfirmationOverlay
              showOverlay={this.state.showOverlay}
              label="!Felicidades, tus picks se guardaron con éxito!"
              allPicks={
                Number(this.state.poolInfo.fixtures_count) + Number(this.state.poolInfo.props_count)
              }
              currentSavedPicks={this.state.currentSavedPicks}
            />
            <ConfirmationOverlay
              isError
              errorMessage={this.state.errorMessage}
              showOverlay={this.state.errorGuardarPicks}
              allPicks={
                Number(this.state.poolInfo.fixtures_count) + Number(this.state.poolInfo.props_count)
              }
              currentSavedPicks={this.state.currentSavedPicks}
            />
            <div
              className={`picks-message-overlay ${
                this.state.copiarPicksExito !== null
                  ? this.state.copiarPicksExito
                    ? 'show-message'
                    : ''
                  : ''
              }`}
            >
              {this.state.copiarPicksExito !== null ? (
                this.state.copiarPicksExito ? (
                  <span className="flex-row text-center">
                    <div className="flex-col">
                      ¡Felicidades, tus picks se copiaron con éxito!
                      <br />
                      <strong>No olvides guardar</strong>
                    </div>
                  </span>
                ) : (
                  <span>Inténtalo de nuevo...</span>
                )
              ) : (
                ''
              )}
            </div>
            <div className="flex-row title-quiniela main">
              <div className="col-xs-10 title-container-mis-quinielas">
                <div className="flex-row row-no-padding row-center">
                  <div className="flex-col col-5">
                    <div className="flex-container justify align">
                      {this.setSport(this.state.poolInfo.pool.sport_id)}
                    </div>
                  </div>
                  <div className="">
                    <DisplayFlag type="miPanel" flag={this.state.poolInfo.pool} />
                  </div>
                  <span className="flex-col ">
                    <div className="flex-row">
                      {this.state.poolInfo.pool.name_replaced}{' '}
                      {numeral(this.state.poolInfo.pool.entry_fee / 100).format(moneyFormat)}{' '}
                      {this.state.poolInfo.pool.entry_currency === 'real'
                        ? 'MXN'
                        : this.state.poolInfo.pool.entry_currency === 'pickcoin'
                        ? 'PC'
                        : 'R'}

                      {Boolean(this.props.entry.number) && (
                        <span
                          style={{
                            fontSize: 'small',
                            margin: '0 3px',
                          }}
                        >
                          {`[${this.props.entry.number}]`}
                        </span>
                      )}
                    
                    </div>
                  </span>
                </div>
              </div>
              <div className=" col-xs-2 btn-close">
                <Button
                  className="closeQuinaBtn"
                  onClick={() => {
                    // this.deleteData();
                    this.props.closeMiQuina(this.props.index);
                  }}
                >
                  {/*<span><i className="glyphicon glyphicon-remove"></i></span>*/}
                  <div className="btn-icon-close">
                    <img src={closeW} className={'closeIcon'} alt="" />
                  </div>
                </Button>
              </div>
            </div>
            <QuinielaPicksUI
              misQuinielas
              updateStartedFixture={this.changeStatusPool}
              updateControlEntries={this.props.updateControlEntries}
              similarPools={this.state.similarPools}
              cambiarEntradas={null}
              changeWeek={this.changeWeek}
              handleAbonar={null}
              enoughBalance={this.props.enoughBalance}
              entriesInfo={this.state.entriesInfo}
              fixtures={this.state.fixtures}
              parley={this.state.parley}
              firstFixture={this.state.firstFixture}
              fixtureProps={this.state.fixtureProps}
              tiebreakers={this.state.tiebreakers}
              entryInfo={this.state.selectedEntryData}
              poolWeeks={this.state.poolWeeks}
              poolInfo={this.state.poolInfo}
              currentWeek={this.state.currentWeek}
              poolWeek={this.state.poolWeeks.current}
            />
            {isFull && this.state.poolInfo.pool.weekly_prize ? (
              <Nav
                bsStyle="pills"
                className="tablas-weekley"
                activeKey={this.state.weeklyStandingsActive === true ? 2 : 1}
                onSelect={this.handleWeeklyActive}
              >
                <NavItem eventKey={1}>Tabla General</NavItem>
                <NavItem eventKey={2}>Tabla Semanal</NavItem>
              </Nav>
            ) : null}
            {this.state.weeklyStandingsActive && this.state.poolInfo.pool.weekly_prize ? (
              <div className="position-wrapper">
                <TablaPosicionesMisQuinielasWeekly
                  handleSelectedUser={this.props.handleSelectedUser}
                  followUser={this.followUser}
                  followSuccess={this.state.successFollow}
                  followError={this.state.errorFollow}
                  entry={this.state.entriesInfo.entry}
                  entryId={this.state.selectedEntryData.id}
                  getAganar={this.getAganarWeekly}
                  getAganarWeekly={this.getAganarWeekly}
                  isLoaded={this.state.loaded}
                  poolWeeks={this.state.poolWeeks}
                  posiciones={this.state.weeklyStandings}
                  tipoPremio={this.state.premios.pool_prizes[0].prizes[0].type}
                  premios={this.state.premios}
                  poolInfo={this.state.poolInfo}
                  standGroups={this.state.grouStandings}
                  updateStandingsFixturePoints={this.updateStandingsFixturePoints}
                  updateStandingsPropsPoints={this.updateStandingsPropsPoints}
                  updateStandingsPosition={this.updateStandingsPosition}
                  updateStandingsAganar={this.updateStandingsAganar}
                  userData={utils.getUserInfo()}
                  currentWeek={this.state.currentWeek}
                />
              </div>
            ) : (
              <TablaPosicionesMisQuinielas
                handleSelectedUser={this.props.handleSelectedUser}
                followUser={this.followUser}
                followSuccess={this.state.successFollow}
                followError={this.state.errorFollow}
                entryId={this.state.selectedEntryData.id}
                entry={this.state.entriesInfo.entry}
                getAganar={this.getAganar}
                isLoaded={this.state.loaded}
                poolInfo={this.state.poolInfo}
                posiciones={this.state.standings}
                premios={this.state.premios}
                resetPosiciones={this.resetPosiciones}
                standGroups={this.state.grouStandings}
                tipoPremio={this.state.premios.pool_prizes[0].prizes[0].type}
                updateStandingsAganar={this.updateStandingsAganar}
                updateStandingsFixturePoints={this.updateStandingsFixturePoints}
                updateStandingsPosition={this.updateStandingsPosition}
                updateStandingsPropsPoints={this.updateStandingsPropsPoints}
                userData={utils.getUserInfo()}
                parlay={this.state.parley}
                parlayDeadlines={this.state.parlayDeadlines}
              />
            )}
          </div>
        </QuinielaDataProvider>
      </div>
    );
    return conditionalComponent;
  }

  updateStandings(newPositions) {
    // console.log('Updating Positions');
    // console.log(newPositions);
    // console.log(newPositions[0].total_possibly_correct);
    // console.log('prize_currency', this.state.poolInfo.pool.prize_currency);
    if (!this.state.poolInfo || !newPositions[0]) {
      return;
    }
    if (newPositions[0].pool_id !== this.state.poolInfo.pool.id) {
      return;
    }
    let tempStandings = this.state.standings;
    let tempSelectedEntryData = this.state.selectedEntryData;

    newPositions = this.updateStandingsPosition(newPositions);
    newPositions = this.updateStandingsAganar(
      newPositions,
      this.state.poolInfo.pool.prize_currency
    );
    tempStandings['items'] = newPositions;
    // console.log('newPositions', newPositions);
    newPositions.forEach((position) => {
      if (position.entry_id === this.state.selectedEntryData.id) {
        // console.log('THIS');
        // console.log(position);
        tempSelectedEntryData['standing'].rank = position.rank;
        tempSelectedEntryData['standing'].points = position.total_possibly_correct;
        tempSelectedEntryData['standing'].total_possibly_correct = position.total_possibly_correct;
        tempSelectedEntryData['standing'].user_prize = position.user_prize;
        tempSelectedEntryData['user_prize'] = position.user_prize;
        // console.log(tempSelectedEntryData);
      }
    });
    // console.log('Update Standings');
    // if (this.isMounted) {
    //   this.setState({
    //     selectedEntryData: tempSelectedEntryData,
    //     standings: tempStandings
    //   });
    // }
  }

  updateData() {
    console.log('Update data');
    console.log('updatedId');
    console.log(this.props.updatedId);

    const entryID = this.props.entry.id;
    //const poolID = this.props.entry.pool_id;
    //const type = this.props.entry.type;
    //const format = this.props.entry.format;
    if (this.state.poolInfo.pool.closed) {
      return;
    }

    if (this.props.updatedId && this.props.updatedId === this.props.entry.id) {
      console.log('This Component was updated', this.props.updatedId, this.props.entry.id);
      axios
        .all([API.getEntriesInfo(entryID), API.fetchSimilarEntries(entryID)])
        .then(
          axios.spread((dataEntriesInfo, dataSimilarEntries) => {
            // console.log(dataSimilarEntries, this.state.poolInfo.pool.id);
            this.setState(
              {
                entriesInfo: dataEntriesInfo.data,
                selectedEntryData: dataEntriesInfo.data.entry,
                similarPools: dataSimilarEntries.data,
              },
              () => {
                this.props.afterUpdatePanels(
                  this.state.entriesInfo.entry.pick_count +
                    this.state.entriesInfo.entry.pick_prop_count
                );
                this.setLoaded();
              }
            );
          })
        )
        .catch((error) => {
          console.error(error);

          Sentry.captureException(error, {
            extra: 'Error fetching Axios all in Mi quiniela UpdateData',
          });
          this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');
          this.props.closeMiQuina(this.props.index);
        });
    } else {
      console.log('This Component wasnt updated', this.props.updatedId, this.props.entry.id);

      API.fetchSimilarEntries(entryID)
        .then((response) => {
          // console.log(dataSimilarEntries, this.state.poolInfo.pool.id);
          this.setState(
            {
              similarPools: response.data,
            },
            () => {
              this.props.afterUpdatePanels(
                this.state.entriesInfo.entry.pick_count +
                  this.state.entriesInfo.entry.pick_prop_count
              );
              this.setLoaded();
              // console.log('data Updated !');
            }
          );
        })
        .catch((error) => {
          console.error(error);

          Sentry.captureException(error, {
            extra: 'Error InMisquinielas SimilarEntries',
          });
        });
      // this.props.closedPanel(this.state.poolInfo.pool.id);
      // this.props.closeMiQuina(this.props.index);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * Panel ready
     */
    // if (this.refs.picksQuinielas && this.state.loaded &&  !prevState.loaded && this.state.loaded !== prevState.loaded) {
    //   // console.log('First Update PicksREady', 'with different Entry');
    //   this.props.handleMaxHeight();
    //   // this.props.resetPanelsHeight();
    // }
    // /**
    //  * Panel Week Changed
    //  */
    // if (
    //   this.refs.picksQuinielas &&
    //   this.state.loaded !== prevState.loaded &&
    //   this.state.poolWeekSelected !== prevState.poolWeekSelected
    // ) {
    //   // console.log('Change WEek and ready to MAxHeight');
    //   this.props.resetHeight();
    //   this.props.handleMaxHeight();
    // }
    // /**
    //  * Other panel was updated
    //  */
    // if (
    //   this.refs.picksQuinielas &&
    //   this.state.loaded !== prevState.loaded &&
    //   this.props.update !== prevProps.update
    // ) {
    //   // console.log('Update after mod other panel');
    //   this.props.handleMaxHeight();
    // }
    // /**
    //  * Panel Weekly Standings Active
    //  */
    // if (
    //   this.refs.picksQuinielas &&
    //   prevState.weeklyStandingsActive !== this.state.weeklyStandings
    // ) {
    //   // console.log('WEekly Standings CHange');
    //   this.props.resetHeight();
    //   this.props.handleMaxHeight();
    // }
    // /**
    // /**
    //  * Panel Update
    //  */
    // if (this.props.update !== prevProps.update) {
    //   if (this.props.update ) {
    //     // console.log('update This Panel Finnaly',this);
    //     this.updateData();
    //   }
    // }
    // if (this.props.liveFixturesData !== prevProps.liveFixturesData) {
    //   // console.log('liveFixtData');
    //   this.setState({
    //     liveFixturesData: this.props.liveFixturesData
    //   });
    // }
  }

  /**
   * Get all the  data of the selected entry
   * -- Entry Info
   * -- Fixtures Info
   * -- Pool Info
   * -- Pool Prizes
   * -- Standings
   * -- Group Standings
   * -- similar Pools
   * -- Week Info
   * -- Week Fixtures
   *
   */
  componentDidMount() {
    /**
     * Init SubscriptionRxjs for each panel
     * @type {SubscriptionRx | ISubscriptionRx | AnonymousSubscriptionRx | * | TeardownLogic}
     */
    this.fixturesSubscriptionRx = SubscriptionRxs.fixtureReceived$.subscribe((updatedFixtures) => {
      if (!this.state.fixtures.length && !this.state.fixtures) {
        return;
      }

      let tempFixtures = this.state.fixtures;
      // console.log( this.state.fixtures );
      let newFixtures = updatedFixtures.live_fixtures;
      let newResults = updatedFixtures.results;
      let tempStandings = this.state.standings;
      let foundFixture = false;
      let foundResult = false;
      if (!newFixtures.length && !newFixtures) {
        return;
      }
      if (!newResults.length && !newResults) {
        return;
      }
      /**
       * Find if the avalable Fixtures
       * exist in the updatedFixtures
       * and update the score and the events
       * of each fixture un the mainState
       */
      tempFixtures.forEach((fixture, idx) => {
        /**
         * Replace Score and WinningPick
         */
        newFixtures.forEach((newFixture) => {
          if (fixture.id !== newFixture.id) {
            return;
          }
          let tempCurrentFixture = fixture;
          foundFixture = true;
          // console.log('UPDATED FIXTURE')
          // console.log(newFixture)
          tempCurrentFixture['score_home'] = newFixture.score_home;
          tempCurrentFixture['score_away'] = newFixture.score_away;
          tempCurrentFixture['winning_pick'] = newFixture.winning_pick;
          tempCurrentFixture['winning_pick_line'] = newFixture.winning_pick_line;
          tempFixtures[idx] = tempCurrentFixture;
          // console.log(tempFixtures);
        });
        /**
         * Replace Results
         */
        _.each(newResults, (liveResult) => {
          let tempCurrentFixture = fixture;
          if (fixture.feed_id !== liveResult.feed_id) {
            return;
          }
          foundResult = true;
          // console.log('Live REsults', liveResult, fixture);
          if (!fixture.hasOwnProperty('stats')) {
            tempCurrentFixture['stats'] = {};
          }
          tempCurrentFixture['stats']['quarter'] = liveResult.hasOwnProperty('quarter')
            ? liveResult.quarter
            : null;
          tempCurrentFixture['stats']['timer'] = liveResult.timer;
          tempCurrentFixture['stats']['score_home_first_half'] = liveResult.score_home_first_half;
          tempCurrentFixture['stats']['score_away_first_half'] = liveResult.score_away_first_half;
          tempCurrentFixture['events'] = {};
          tempCurrentFixture['events'] = liveResult.events;
        });
      });
      if (foundFixture || foundResult) {
        this.setState(
          {
            fixtures: tempFixtures,
          },
          () => {
            if (this.state.poolInfo.pool.status === 'live') {
              this.updateStandings(this.state.standings.items);
            }
          }
        );
      }
    });

    /****************************
     ******* PropUpdate Received
     *****************************
     * This subscription handles when a new propsUpdate stream is received from the live pools
     * @param updateProps
     */
    this.propsSubscriptionRx = SubscriptionRxs.propsReceived$.subscribe((updatedProps) => {
      if (
        this.state.fixtureProps !== undefined &&
        this.state.fixtureProps !== null &&
        this.state.fixtureProps.length < 0
      ) {
        return;
      }
      let tempProps = this.state.fixtureProps;
      let foundProp = false;
      let tempStandings = this.state.standings;
      // console.log( updatedProps );
      // console.log( tempProps );
      if (!updatedProps.length && !updatedProps) {
        return;
      }
      // console.log( 'FixturePropsUpdated' );
      /**
       * Find if the available fixtureProp
       * exist in the updateProps
       * and update the winning pick
       * in the mainState
       */
      if (tempProps === undefined || tempProps === null) {
        return;
      }
      tempProps.forEach((fixtureProp, idx) => {
        updatedProps.forEach((propUpdated) => {
          // console.log(propUpdated, fixtureProp, idx);
          if (propUpdated.id !== fixtureProp.id) {
            return;
          }
          let tempFixtureProp = fixtureProp;
          foundProp = true;
          tempFixtureProp.result = propUpdated.result;
          tempFixtureProp.winning_pick = propUpdated.winning_pick;
          tempFixtureProp.winning_prop = propUpdated.winning_prop;
          tempProps[idx] = tempFixtureProp;
        });
      });
      if (foundProp) {
        this.setState(
          {
            fixtureProps: tempProps,
          },
          () => {
            // console.log('After Update Props updatethe Standings ...');
            if (this.state.poolInfo.pool.status === 'live') {
              this.updateStandings(this.state.standings.items);
            }
          }
        );
      }
    });
    /****************************
     ******* updatedStandings Received
     *****************************
     * This subscription handles the state when a new updateStanding stream is received from the live pools
     * @param updateStandingsData
     */
    this.updatedStandingsSubscription = SubscriptionRxs.standingsUpdatedReceived$.subscribe(
      (updateStandingsData) => {
        if (!this.state.poolInfo.pool || !this.state.standings) {
          return;
        }

        if (updateStandingsData.poolId !== this.state.poolInfo.pool.id) {
          return;
        }
        if (!this.state.standings && this.state.standings.items.length === 0) {
          return;
        }
        // console.log(
        //   '%c Standings Updated',
        //   'background:#68B300; font-size:20px;'
        // );
        // console.log(updateStandingsData.standings);
        let currentEntriesData = this.state.entriesInfo;

        let tempPoolInfo = this.state.poolInfo;

        tempPoolInfo['ties_distribution'] = updateStandingsData.ties_distribution;

        let tempStandings = this.state.standings;
        let newUpdatedPositions;

        // console.log(tempStandings.items[0]);
        // updateStandingsData.standings.splice(0, 5);
        // console.log('stateStandings: \n', tempStandings.items[0]);
        this.setState(
          {
            poolInfo: tempPoolInfo,
          },
          () => {
            newUpdatedPositions = this.updateStandingsAganar(
              updateStandingsData.standings,
              this.state.poolInfo.pool.prize_currency
            );
            newUpdatedPositions = utils.updateStandingsComplete(
              newUpdatedPositions,
              tempStandings,
              this.updateStandingsFixturePoints,
              this.updateStandingsPositions,
              this.updateStandingsAganar
            );
          }
        );

        currentEntriesData.entry = utils.updateEntry(
          currentEntriesData.entry,
          updateStandingsData.standings
        );
        tempStandings['items'] = newUpdatedPositions;

        if (tempStandings.items.length > 0) {
          tempStandings.items.sort(firstBy('rank', 1).thenBy('entry_id'));
        }

        this.setState({
          entriesInfo: currentEntriesData,
          standings: tempStandings,
          updatedStandings: true,
        });
      }
    );
    /****************************
     ******* updatedWeeklyStandings Received
     *****************************
     * This subscription handles the state when a new updateStanding stream is received from the live pools
     * @param updateStandingsData
     */
    this.weeklyStandingsSubscriptionRx = SubscriptionRxs.weeklyStandingsUpdatedReceived$.subscribe(
      (updatedWeeklyStandings) => {
        if (
          !this.state.poolInfo ||
          !this.state.weeklyStandings ||
          !this.state.weeklyStandings.items ||
          !this.state.poolWeekSelected
        ) {
          return;
        }
        if (updatedWeeklyStandings.poolId !== this.state.poolInfo.pool.id) {
          return;
        }
        if (updatedWeeklyStandings.weekId !== this.state.poolWeekSelected) {
          return;
        }

        console.log('Updated via socket', updatedWeeklyStandings);

        let temporalPoolWeeks = this.state.poolWeeks;

        temporalPoolWeeks.ties_distribution = updatedWeeklyStandings.ties_distribution;

        this.setState({
          poolWeeks: temporalPoolWeeks,
        });

        //let allStandingsUpdated = updatedWeeklyStandings.weeklyStandings;
        //let temporalPool = this.state.poolInfo;

        let tempStandings = this.state.weeklyStandings;

        //let temporalTiesDistribution = updatedWeeklyStandings.ties_distribution;

        let newUpdatedPositions = this.updateWeeklyStandingsAganar(
          updatedWeeklyStandings.weeklyStandings,
          updatedWeeklyStandings.ties_distribution
        );

        if (this.state.poolInfo.closed) {
          let direction;
          updatedWeeklyStandings.weeklyStandings.sort(
            firstBy('total_possibly_correct', direction).thenBy('entry_id')
          );
        }

        tempStandings['items'] = newUpdatedPositions;

        this.setState({
          weeklyStandings: tempStandings,
        });
      }
    );

    /****************************
     ******* PoolUpdate Received
     *****************************
     * This subscription handles the state when a new updatedPool stream is received from the live pools
     * @param updatedPool
     */
    this.poolUpdateSubscription = SubscriptionRxs.poolUpdateReceived$.subscribe((updatedPool) => {
      if (!this.state.poolInfo) {
        return;
      }
      let tempPoolInfo = this.state.poolInfo;
      let tempPoolInfoPool = this.state.poolInfo.pool;
      //let poolFound = false;
      if (!updatedPool && updatedPool.pool) {
        return;
      }
      /**
       * Find if the updatedPool
       * is the same as the current poolInfo
       * and update the available prize and the entry count
       * of the pool object
       */
      if (updatedPool.id !== tempPoolInfo.id) {
        return;
      }
      console.log('FixturePropsUpdated');
      // console.log( updatedProps );
      // console.log( tempPoolInfo );
      tempPoolInfoPool['entry_count'] = updatedPool.pool.entry_count;
      tempPoolInfoPool['available'] = updatedPool.pool.available;
      tempPoolInfo['pool'] = tempPoolInfoPool;

      this.setState({
        poolInfo: tempPoolInfo,
      });
    });

    /****************************
     ******* closedPool Received
     *****************************
     * This subscription handles the state when a new Pool was closed from the live pools
     * @param closedPool
     */
    this.poolCloseUpdateSubscription = SubscriptionRxs.poolCloseReceived$.subscribe(
      (closedPool) => {
        // console.log('Closed Pool', closedPool)
        if (!this.state.poolInfo) {
          return;
        }
        let tempPoolInfo = this.state.poolInfo;
        let tempStandings = this.state.standings;
        let tempPoolInfoPool = this.state.poolInfo.pool;
        let nextStandings = closedPool.standings;
        let prevStandings = this.state.standings.items;
        let currentEntriesData = this.state.entriesInfo;

        if (!closedPool && closedPool.pool) {
          return;
        }
        /**
         * If the closePool is the same as the current poolInfo
         * update the status, the upcoming and closed boolean
         * values in the  mainState
         */
        if (!tempPoolInfo.pool || closedPool.pool !== tempPoolInfo.pool.id) {
          return;
        }

        tempPoolInfo.pool['closed'] = true;
        tempPoolInfo.pool['status'] = 'closed';
        tempPoolInfo.pool['upcoming'] = false;

        /**
         * UpdateTHe Standings with the final Standings
         * after the close action
         */
        prevStandings.forEach((prevStanding, prevStandingIdx) => {
          nextStandings.forEach((nextStanding, nextStandingIdx) => {
            if (nextStanding.entry_id !== prevStanding.entry_id) {
              return;
            }
            prevStanding['total_possibly_correct'] = nextStanding.points;
            prevStanding['points'] = nextStanding.points;
            prevStanding['rank'] = nextStanding.rank;
            prevStanding['original_points'] = nextStanding.points;
            prevStandings[prevStandingIdx] = prevStanding;
          });
        });
        // console.log('Closed Pool');
        // Reassign the new standigns
        prevStandings = this.updateStandingsAganar(
          prevStandings,
          this.state.poolInfo.pool.prize_currency
        );
        // prevStandings.sort(firstBy('rank').thenBy('entry_id'));
        tempStandings['items'] = prevStandings;

        // Update Entry data
        currentEntriesData.entry = utils.updateEntry(
          currentEntriesData.entry,
          closedPool.standings
        );

        console.log('CloseUpdatePoolSubscription');
        this.setState(
          {
            entriesInfo: currentEntriesData,
            poolInfo: tempPoolInfo,
            standings: tempStandings,
          },
          console.log('The pool is closed! and updated Standings ')
        );
      }
    );
    /****************************
     ******* FixtureClosed Received
     *****************************
     * This subscription handles the state when a fixture was closed from the live pools
     * @param fixtureID
     */
    this.fixtureCloseSubscription = SubscriptionRxs.fixtureCloseReceived$.subscribe((fixtureID) => {
      let tempFixtures = this.state.fixtures;
      let tempProps = this.state.fixtureProps;
      let fixtureIdx = 0;
      let tempItems = this.state.standings.items;
      if (!tempFixtures) {
        return;
      }
      let fixture = tempFixtures.find((_fixture, fixtIdx) => {
        fixtureIdx = fixtIdx;
        return _fixture.id === fixtureID;
      });

      if (!fixture) {
        return;
      }

      fixture.finished = true;
      fixture.sort_status = 2;
      tempFixtures[fixtureIdx] = fixture;

      tempItems.forEach((_standing, standingIdx) => {
        if (!_standing.picks) {
          return;
        }
        let pickIndex = _.findIndex(_standing.picks, (_pick) => _pick.fixture_id === fixture.id);
        if (!pickIndex) {
          return;
        }
        let winningPick =
          this.state.poolInfo.pool.point_format === 'line'
            ? fixture.winning_pick_line
            : fixture.winning_pick;
        if (_standing.picks[pickIndex] && _standing.picks[pickIndex].pick === winningPick) {
          _standing.points += 1;
          _standing.original_points += 1;
        }
        tempItems[standingIdx] = _standing;
      });
      if (fixture.props) {
        tempProps.forEach((_prop, propIdx) => {
          if (_prop.fixture_id !== fixture.id) {
            return;
          }
          _prop.fixture.finished = true;
          _prop.fixture.sort_status = 2;
          tempProps[propIdx] = _prop;

          tempItems.forEach((_standing, standingIdx) => {
            if (!_standing.picks_props) {
              return;
            }
            let pickPropIdx = _.findIndex(
              _standing.picks_props,
              (_pickProp) => _pickProp.prop_id === _prop.id
            );
            if (pickPropIdx < 0) {
              return;
            }
            if (
              _standing.picks_props[pickPropIdx] &&
              _standing.picks_props[pickPropIdx] === _prop.winning_pick
            ) {
              _standing.points += 1;
              _standing.original_points += 1;
            }
            tempItems[standingIdx] = _standing;
          });
        });
      }
      console.log('FixtureSubscription');
      this.setState({
        fixtures: tempFixtures,
        fixtureProps: tempProps,
      });
    });

    const entryID = this.props.entry.id;
    const poolID = this.props.entry.pool_id;
    const type = this.props.entry.type;
    //const format = this.props.entry.format;

    let temporalEntriesInfo,
      // temporalFixtures,
      // temporalPoolInfo,
      // temporalPremios,
      // temporalStandings,
      // temproalGroupStandings,
      // termpoalSimilarEntries,
      // temporalPoolWeeks,
      temporalSelectedWeekFixtures;
      // temporalWeelkyStandings;

    axios
      .all([
        API.getEntriesInfo(entryID),
        API.fetchFixtures(poolID),
        API.fetchIndividualPool(poolID),
        API.fetchPoolPrizes(poolID),
        API.fetchStandingsPartial(poolID, this.queryParams),
        API.fetchStandingsGroups(poolID),
        API.fetchSimilarEntries(entryID),
        API.fetchWeek(poolID),
      ])
      .then(
        axios.spread(
          (
            dataEntriesInfo,
            dataFixtures,
            dataPoolInfo,
            dataPremios,
            dataStandings,
            dataGroupStandings,
            dataSimilarEntries,
            dataPoolWeeks
          ) => {
            let responses = [
              dataEntriesInfo,
              dataFixtures,
              dataPoolInfo,
              dataPremios,
              dataStandings,
              dataGroupStandings,
              dataSimilarEntries,
              dataPoolWeeks,
            ];
            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
            }
            let dataWeeklyStandings = null;
            // console.log(dataPoolInfo);
            // console.log(dataPoolWeeks);
            let currentWeek = dataPoolWeeks.data.current.id;
            if (type === 'full' && dataPoolInfo.data.pool.weekly_prize) {
              API.fetchWeeklyStandingsPartial(poolID, currentWeek)
                .then((responseStandings) => {
                  API.fetchWeekFixtures(poolID, currentWeek)
                    .then((response) => {
                      temporalSelectedWeekFixtures = response.data;
                      // console.log(temporalSelectedWeekFixtures);
                      // this.setState({
                      //   poolWeeks: temporalSelectedWeekFixtures,
                      // });
                    })
                    .catch((error) => {
                      console.error(error);

                      Sentry.captureException(error, {
                        extra: 'Error fetching Axios all in Mi Quiniela fetching weekly fixtures',
                      });
                      this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');
                      this.props.closeMiQuina(this.props.index);
                    });
                  dataWeeklyStandings = responseStandings.data;
                  // console.log('Weekly Standings', dataWeeklyStandings);
                  // console.log('Weeks', temporalSelectedWeekFixtures);
                  //Revisar el primer llamado de weekstandings
                  //this;
                  this.setState({
                    weeklyStandings: dataWeeklyStandings,
                  });
                })
                .catch((error) => {
                  console.error(error);

                  Sentry.captureException(error, {
                    extra: 'Error fetching Axios all in Mi Quiniela weeklyStandings',
                  });
                  this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');
                  this.props.closeMiQuina(this.props.index);
                });
              // console.log('it has Weekly Prize');
            } else {
              API.fetchStandingsPartial(poolID)
                .then((response) => {
                  dataWeeklyStandings = response.data;
                })
                .catch((error) => {
                  console.error(error);

                  Sentry.captureException(error, {
                    extra: 'Error fetching Axios all in Mi Quiniela Fetching Standings',
                  });
                  this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');
                  this.props.closeMiQuina(this.props.index);
                });
            }

            /**
             * List FixtureProps in other instance of the main state
             * @type {Array}
             */
            let tempProps = [];
            dataFixtures.data.fixtures.forEach((fixture) => {
              if (dataPoolInfo.data.pool.format === 'mixed' && fixture.props) {
                fixture.props.map((prop) => {
                  let tempObj = prop;
                  tempObj['fixture'] = {
                    id: fixture.id,
                    sort_status: fixture.sort_status,
                    time_left: fixture.time_left,
                    enabled: fixture.enabled,
                    deadline: fixture.deadline,
                    finished: fixture.finished,
                    home_team: {
                      id: fixture.home_team_id,
                      abbreviation: fixture.home_team.abbreviation,
                    },
                    away_team: {
                      id: fixture.away_team_id,
                      abbreviation: fixture.away_team.abbreviation,
                    },
                  };
                  tempObj['enabled'] = fixture.enabled;
                  tempProps.push(tempObj);
                });
              }
            });

            if (dataPoolInfo.data.pool.status !== 'upcoming') {
              // console.log(dataStandings.data)
              // console.log(dataStandings.data.items[0])
              if (!dataPoolInfo.data.pool.closed) {
                // console.log('default sort list!')
                dataStandings.data.items.sort(
                  firstBy('total_possibly_correct', -1).thenBy('entry_id')
                );
              } else {
                dataStandings.data.items.sort(firstBy('rank', 1).thenBy('entry_id'));
              }
              // console.log(dataStandings.data.items[0]);
            }
            // console.log('HERE');
            // console.log(dataWeeklyStandings);
            //

            this.setState(
              {
                entriesInfo: dataEntriesInfo.data,
                myEntry: dataEntriesInfo.data.entry,
                currentWeek: dataPoolWeeks.data.current,
                fixtures: dataFixtures.data.fixtures,
                parley: dataFixtures.data.parlay_blocks,
                parlayDeadlines: dataFixtures.data.parlay_deadlines,
                firstFixture: dataFixtures.data.first,
                fixtureProps: tempProps,
                numeroEntries: this.props.numeroEntries,
                poolInfo: dataPoolInfo.data,
                premios: dataPremios.data,
                standings: dataStandings.data,
                grouStandings: dataGroupStandings.data,
                similarPools: dataSimilarEntries.data,
                liveFixturesData: this.props.liveFixturesData,
                poolWeeks: dataPoolWeeks.data,
                poolWeekSelected: dataPoolWeeks.data.current.id,
                selectedWeekfixtures: temporalSelectedWeekFixtures,
                selectedEntryData:
                  dataEntriesInfo.data.entry.id === entryID
                    ? dataEntriesInfo.data.entry
                    : dataEntriesInfo.data.other_entries.find(
                        (other_entry) => other_entry.id === entryID
                      ),
                weeklyStandings: dataWeeklyStandings,
                currentSavedPicks:
                  dataEntriesInfo.data.entry.pick_count +
                  dataEntriesInfo.data.entry.pick_prop_count,
              },
              () => {
                this.setState({
                  tiesPicks: this.initializeTies(this.state.poolInfo, this.state.selectedEntryData),
                });

                if (this.state.poolInfo.pool.status === 'live') {
                  let temporalStandings = this.state.standings;
                  // console.log(
                  //   'Default reassign prizes in Live',
                  //   temporalStandings
                  // );
                  let updatedPositions = this.updateStandingsAganar(
                    temporalStandings.items,
                    this.state.poolInfo.pool.prize_currency
                  );
                  // console.log(updatedPositions);
                  // console.log(this.state.entriesInfo);
                  let entryData = this.state.entriesInfo;
                  updatedPositions.forEach((updatedPos) => {
                    if (
                      this.state.entriesInfo.hasOwnProperty('entry') &&
                      updatedPos.entry_id === this.state.entriesInfo.entry.id
                    ) {
                      // console.log('this');
                      // console.log(updatedPos);
                      entryData.entry.standing.rank = updatedPos.rank;
                      if (entryData.entry.user_prize === null) {
                        entryData.entry.user_prize = {
                          amount: updatedPos.prize_amount,
                        };
                      }
                      entryData.entry.user_prize = updatedPos.user_prize;
                      // console.log(entryData);
                      // entryData['entry']
                    }
                  });

                  temporalStandings['items'] = updatedPositions;

                  this.setState(
                    {
                      entriesInfo: entryData,
                      standings: temporalStandings,
                    },
                    () => {
                      // console.log('After TemporalStandings');
                    }
                  );
                }
                this.setLoaded();
              }
            );
          }
        )
      )
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');
        this.props.closeMiQuina(this.props.index);
      });
  }

  componentWillUnmount() {
    console.log(this.props.entry.id, this.props.entry.pool_id);
    this.props.closedPanel(this.props.entry.id, this.props.entry.pool_id, 'pool');

    if (this.fixturesSubscription) {
      // console.log('Closed Subscritpio in fixtures');
      this.fixturesSubscription.unsubscribe();
    }
    if (this.propsSubscriptionSubscription) {
      // console.log('Closed Subscritpio in Props');
      this.propsSubscriptionSubscription.unsubscribe();
    }
    if (this.updatedStandingsSubscription) {
      this.updatedStandingsSubscription.unsubscribe();
    }
    if (this.weeklyStandingsSubscriptionRx) {
      this.weeklyStandingsSubscriptionRx.unsubscribe();
    }
    if (this.poolCloseUpdateSubscription) {
      // console.log('Closed Subscritpio in poolClose');
      this.poolCloseUpdateSubscription.unsubscribe();
    }
    if (this.poolUpdateSubscription) {
      // console.log('Closed Subscritpio in poolUpdate');
      this.poolUpdateSubscription.unsubscribe();
    }
    if (this.fixtureCloseSubscription) {
      // console.log('Closed Subscritpio in fixtureClose');
      this.fixtureCloseSubscription.unsubscribe();
    }
  }

  render() {
    let selectedWeek;
    let itPassed;
    let isFull;
    let isMixed;
    let now = moment();
    let endWeek;
    let poolStatus;
    let conditionalComponent = null;

    if (this.state.loaded) {
      isMixed = this.state.poolInfo.pool.format === 'mixed' ? true : false;
      endWeek = moment(this.state.poolInfo.pool.registration_deadline);
      poolStatus = this.state.poolInfo.pool.status;
      /**
       * Condition to set variables if the pool is Type Full
       */
      if (this.state.poolInfo.pool.type === 'full') {
        isFull = true;
        // console.log(this.state.poolWeeks, this.state.poolWeekSelected);
        if (
          this.state.poolWeekSelected === null ||
          this.state.poolWeeks.current.id === this.state.poolWeekSelected
        ) {
          selectedWeek = this.state.poolWeeks.current.id;
          if (moment(this.state.poolWeeks.current.end_date).diff(now) < 0) {
            itPassed = true;
          } else {
            itPassed = false;
          }
        } else {
          // console.log(this.state.poolWeekSelected, this.state.poolWeeks);
          let poolWeek;
          if (this.state.poolWeekSelected === undefined || this.state.poolWeekSelected === null) {
            poolWeek = this.state.poolWeeks.current.id;
          } else {
            poolWeek = this.state.poolWeekSelected;
          }
          let findWeek = this.state.poolWeeks.weeks.find((week) => poolWeek === week.id);
          selectedWeek = findWeek;
          // console.log(poolWeek, selectedWeek , findWeek, this.state.poolWeeks);
          if (findWeek !== undefined) {
            if (moment(findWeek.end_date).diff(now) < 0) {
              itPassed = true;
            } else {
              itPassed = false;
            }
          }
        }
        // console.log(selectedWeek);
        endWeek = moment(selectedWeek.end_date);
        selectedWeek = selectedWeek.id;
      } else {
        isFull = false;
        selectedWeek = this.state.poolInfo.pool.week_id;
        if (moment(this.state.poolInfo.pool.deadline).diff(now, 'seconds') < 0) {
          itPassed = true;
        } else {
          itPassed = false;
        }
      }
      // console.log(poolStatus, itPassed, selectedWeek, isFull);
      if (this.state.redirectLobby) {
        conditionalComponent = (
          <Redirect
            push
            to={{
              pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntryData.id}`,
            }}
          />
        );
      } else {
        // console.log('poolStatus', poolStatus);
        conditionalComponent = this.selectLayout(poolStatus, isFull, itPassed, isMixed);
      }
    } else {
      conditionalComponent = (
        <div id={`picksContainer-`} className={`flex-col dashboard-item upcoming`}>
          <div className="panelMisQuinielas">
            <Loading />
          </div>
        </div>
      );
    }
    return conditionalComponent;
  }
}
export default MiQuinielaPanel;
