import React, { useEffect, useState } from 'react'

// Components
import Modalhome from '../ModalHome'


const TIMEOUT = 3000

const PopupWelcome = () => {
  const [showModalHome, setShowModalHome] = useState(false)
  const [modalHomeInfo, setModalHomeInfo] = useState(false)

  useEffect(() => {
    fetch('https://pickwinfree.firebaseio.com/popup-welcome/desktop.json')
      .then(response => response.json())
      .then(json => {
        const bannerHomeTime = localStorage.getItem('bannerHomeTime')
        const secondsNow = new Date().getTime() / 1000

        if (json.show === false) return
          

        if (bannerHomeTime === null) {
          localStorage.setItem('bannerHomeTime', secondsNow + json.showEvery)
          setTimeout(() => setShowModalHome(true), TIMEOUT)
        } else {
          if (secondsNow >= bannerHomeTime) {
            localStorage.setItem('bannerHomeTime', secondsNow + json.showEvery)
            setTimeout(() => setShowModalHome(true), TIMEOUT)
          }
        }
        setModalHomeInfo(json)
      })
  }, [])

  return (
    <Modalhome
      showModalHome={showModalHome}
      modalHomeInfo={modalHomeInfo}
      updatePanelSate={() => setShowModalHome(!showModalHome)}
    />
  )
}

export default PopupWelcome
