import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import styled from 'styled-components'

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  style: PropTypes.object
}

export default function Iconify ({ icon, style, ...other }) {
  return (
    <Box
      icon={icon}
      style={{ ...style }}
      {...other}
    />
  )
}

const Box = styled(Icon)``
