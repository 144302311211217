import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ReactDOM from 'react-dom'
import { PulseLoader } from 'react-spinners'
import numeral from 'numeral'

// Components
import Text from '../../Text'
import Image from './Image'
import Iconify from '../../Iconify'

// Utils
import API from '../../../utils/api'

// Hooks
import useMergedState from '../../../hooks/useMergedState'
import BalanceReal from './BalanceReal'
import useUserContext from '../../../hooks/useUserContext'



const initialState = {
  // If loading product details
  loading: false,
  // Product details
  product: {},
  // Purcharse comments
  comments: '',
  // Purcharse status
  successPurchase: false,
  purchaseError: false,
  nextBalanceReal: 0
}

export default function ConfirmModalPurchase (props) {
  const { initializeUserData, userBalance } = useUserContext()
  const { balanceVip, activeProduct } = props
  const [state, setState] = useMergedState(initialState)
  const timeout = useRef()

  useEffect(() => {
    if (activeProduct.name) {
      API.getStoreProductDetails(activeProduct.id)
        .then(({ data }) => {
          setState(data)
        })
    }
  }, [activeProduct.name])

  if (!activeProduct.name) return null

  const confirmPurchase = async () => {
    setState({ loading: true })

    try {
      const res = await API.postProductPurchase({
        product_id: activeProduct.id,
        comments: state.comments
      })

      if (res.data.success) {
        setState({ successPurchase: true })

        // Discount Balance vip
        props.purchaseSuccess(activeProduct.price)

        // If is change balance vip to real balance
        if (activeProduct.product_type === 'cash') {
          // Show real balance count up
          setState({ nextBalanceReal: res.data.balances.real })

          // Update global balance
          initializeUserData()
        }

        timeout.current = window.setTimeout(() => {
          close()
        }, 5000)
      }
    } catch (error) {
      setState({ purchaseError: true })
      window.setTimeout(() => {
        setState({ purchaseError: false })
      }, 3000)
    }
    setState({ loading: false })
  }

  const close = () => {
    props.setActiveProduct({})
    setState(initialState)

    window.clearTimeout(timeout.current)
  }

  const stop = (e) => {
    e.stopPropagation()
  }

  const progress = balanceVip * 100  / activeProduct.price
  const price = numeral(activeProduct.price / 100).format('0,000')
  const disabled = progress < 100
  const balanceVipFormated = numeral(balanceVip / 100).format('0,000')

  const remaining = (activeProduct.price - balanceVip) / 100

  const content = (
    <Container onClick={close}>
        <Box onClick={stop}>
          <Bar>
            <button onClick={close}>
              <Iconify
                icon='material-symbols:close'
                style={{ fontSize: 20 }}
              />
            </button>
          </Bar>

          {state.purchaseError && (
            <Status>
              <Iconify
                icon='ic:round-error-outline'
                style={{color:'orangered', fontSize: 40, margin: '0.5em auto'}}
              />
              <Text as='h6' center my={5} color='red'>¡Ocurrio un error al intentar hacer compra!</Text>
            </Status>
          )}

          {state.successPurchase && (
            <Status>
              <Iconify
                icon='ep:success-filled'
                style={{ color:'#6ac700', fontSize: 55, marginTop: '1em' }}
              />
              <Iconify
                icon='material-symbols:shopping-cart-checkout-sharp'
                style={{ color:'gray', fontSize: 40, margin: '0.5em auto' }}
              />
              <Text as='h5' center my={5}>¡Tu compra fue exitosa!</Text>
              <Text center as='h6' mt={5}>Tus puntos VIP</Text>
              <Text center as='h6' mt={5} color='#6ac700'>{balanceVipFormated}</Text>
              {activeProduct.product_type === 'cash' && (
                <BalanceReal
                  prevBalance={userBalance.balanceReal}
                  nextBalance={state.nextBalanceReal}
                />
              )}
            </Status>
          )}

          {!state.successPurchase && (
            <>
              <Body>
                <Col>
                  <Image src={activeProduct.image_url} />
                  <ProgressBar>
                    <Progress style={{ width: progress + '%' }} />
                  </ProgressBar>
                </Col>
                <Information>
                  <Title>{activeProduct.name}</Title>
                  <Price>Precio {price} pts</Price>
                  <Text as='p' size={12} color='gray'>Puntos VIP disponibles {balanceVipFormated}</Text>
                  <Divider />
                  <Text as='p' size={14} my={5}>{activeProduct.description}</Text>
                  <Link href='https://www.pickwin.mx/landing/programa-de-lealtad/#tyc-tienda' target='_blank'>Terminos y Condiciones</Link>
                </Information>
              </Body>
              <Extra>
                {disabled && (
                  <Button
                    className='btn-pick mis-quinielas'
                    disabled
                  >
                    Estas a solo {remaining} punto{remaining > 1 ? 's': ''}
                  </Button>
                )}
                {!disabled && (
                  <>
                    <Textarea
                      placeholder='Comentarios'
                      value={state.comments}
                      onChange={({ target }) => setState({ comments: target.value })}
                    />
                    <Button
                      className='btn-pick mis-quinielas'
                      onClick={confirmPurchase}
                    >
                      {state.loading ? <PulseLoader color='#FFF' /> : 'CANJEAR AHORA'}
                    </Button>
                  </>
                )}
              </Extra>
            </>
          )}
        </Box>
    </Container>
  )

  return ReactDOM.createPortal(content, document.querySelector('body'))
}

const Link = styled.a`
  color: #AFE140;
  font-size: 0.8em;
  margin-top: 1.5em;
  cursor: pointer;
  display: block;

  :hover {
    opacity: 0.7;
  }
`

const Divider = styled.div`
  margin: 0.2em 0;
  width: 100%;
  border-bottom: 1px dashed #484848;
`

const Extra = styled.div`
  padding: 1em;

  button {
    width: fit-content;
    padding: 0.6em 3em !important;
    margin: 1em auto;
  }
`

const Col = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;

  @media (min-width: 780px) {
    width: 50%;
  }
`

const Information = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
  height: 100%;

  @media (min-width: 780px) {
    width: 50%;
  }
`

const show = keyframes`
  0% {
    transform: scale(0.60);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${show} 530ms ease-out forwards;
`


const showShadow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 1000ms ease;
  background-color: rgba(0,0,0, 0.7);
  backdrop-filter: blur(5px) grayscale(1);
  animation: ${showShadow} 550ms ease forwards;
`

const Title = styled.div`
  font-family: 'Chivo';
  font-weight: 400;
  font-size: 1.3em;
  margin: 0.5em 0;
  text-shadow: 1px 1px 3px #00000099;
`

const Box = styled.div`
  width: 100%;
  max-width: 615px;
  min-height: 500px;
  margin: 1em;
  animation: ${show} 330ms ease-out forwards;
  will-change: transform, opacity;
  background: #000000;
  border-radius: 0.8em;
  overflow: hidden;
  border: 1px solid #383838;
`

const Bar = styled.div`
  width: 100%;
  height: 30px;
  background-color: lime;
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(181deg, #87c342, #518b0f);

  button {
    background: transparent;
    border: 0;
    outline: 0;
    margin-right: 1em;

    :hover {
      opacity: 0.5;
    }
  }
`

const Textarea = styled.textarea`
  border: 1px solid #525252bf;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin: 0.5em 0;
  width: 100%;
  background-color: transparent;
  color: #FFF;
`


const Button = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;

  :disabled {
    filter: grayscale(1);
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  margin: 0 auto;

  @media screen and (min-width: 780px) {
    flex-direction: row;
  }
`

const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 0.5em;
  border: 1px solid #FFF;
  position: relative;
  overflow: hidden;
  transition: 1.3s ease;
  border: 1px solid #FFF;
  z-index: 2;
  background: #343333bf;
`

const Price = styled.span`
  font-size: 1em;
`

const Progress = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #78BC28;
  transition: width 10ms linear;
`
