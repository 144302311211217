import React from 'react'
import $ from 'jquery'
import _ from 'underscore'
import firstBy from 'thenby'
import * as Sentry from '@sentry/browser'
import InfiniteScroll from 'react-infinite-scroller'

// Components
import Loading from '../Loading'
import TablaPicksGrupoSurvivor from '../TablaPicksGrupoSurvivor'

// Utils
import API from '../../utils/api'
import utilities from '../../utils/utils'
import Utils from '../../utils/quinielasUtils'
import { subscribe } from '../../utils/events'
import SubscriptionRxs from '../../utils/subscriptions'

// Assets
import avatar from '../../assets/images/avatar-50.png'

/**
 *This function returns the Main Table of PickGrupo
 */

window.jQuery.fn.synchroniseScroll = function (direction) {
  console.log('initscroll', direction)
  const elements = this
  let lastScrolling = null
  let lastScroll = performance.now()

  if (elements.length <= 1) {
    console.log('no elements')
    return
  }

  elements.scroll(function (e) {
    if (lastScrolling === null || (performance.now() - lastScroll >= 900)) {
      lastScrolling = e.currentTarget
    }
    if (lastScrolling !== e.currentTarget) {
      return
    }

    const left = $(this).scrollLeft()
    const top = $(this).scrollTop()

    elements.each(function (idx, el) {
      if (el === lastScrolling) { return }
      if (direction === 'horizontal' && $(el).scrollLeft() != left) {
        $(el).scrollLeft(left)
      }
      if (direction === 'vertical' && $(el).scrollTop() != top) {
        $(el).scrollTop(top)
      }
    })

    lastScroll = performance.now()
  })
}

const TIME_CHECK_AFTER_WS_CHANGE = 1000 * 60 * 5 // 5 min

const OwnPick = ({ fixture, pick, maxPicks, pointFormat, allowTies, parlayFlag }) => {
  // 0 live
  // 1 upcoming
  // 2 closed

  let innerPresentation = null
  let team = null
  let colClass = null

  if (pick !== null) {
    if (pick.pick === 'home') {
      team = fixture.home_team
      innerPresentation = <i className={`quina ${team.logo} picksG home team-icon`} />
    }
    if (pick.pick === 'away') {
      team = fixture.away_team
      innerPresentation = <i className={`quina ${team.logo} picksG home team-icon`} />
    }
    if (pick.pick === 'tie') {
      team = null
      innerPresentation = 'E'
    }
    if (pick.pick === null && maxPicks !== null) {
      innerPresentation = ''
    }
  } else {
    if (maxPicks === null && parlayFlag === null) {
      innerPresentation =
        fixture.sort_status !== 1
          ? (
            <i className='ion-close' style={{ color: '#E00034' }} />
            )
          : null
    } else {
      innerPresentation = ''
    }
  }

  if (fixture.sort_status !== 1) {
    if (pick !== null) {
      if (pointFormat === 'natural') {
        if ((!allowTies && fixture.winning_pick === 'tie') || fixture.winning_pick === 'pick') {
          colClass = ''
        } else {
          colClass = fixture.winning_pick === pick.pick ? 'gano' : 'perdio'
        }
      }
      if (pointFormat === 'line') {
        if (
          (!allowTies && fixture.winning_pick_line === 'tie') ||
          fixture.winning_pick_line === 'pick'
        ) {
          colClass = ''
        } else {
          colClass = fixture.winning_pick_line === pick.pick ? 'gano' : 'perdio'
        }
      }
    } else {
      if (maxPicks === null && parlayFlag === null) {
        colClass = 'perdio'
      } else {
        colClass = null
      }
    }
  }

  return (
    <div className='item-container'>
      <div className={`shirt-picks ${colClass}`}>
        {innerPresentation}
        {team !== null ? <small className='team-name'>{team.abbreviation}</small> : null}
      </div>
    </div>
  )
}

const OwnPropPick = ({ prop, pickProp, maxPicks }) => {
  // 0 live
  // 1 upcoming
  // 2 closed
  let innerPresentation = null
  let colClass = null

  if (pickProp !== null) {
    if (!prop.boolean && pickProp.pick === 'under') {
      innerPresentation = <i className='ion-minus' />
    }
    if (!prop.boolean && pickProp.pick === 'over') {
      innerPresentation = <i className='ion-plus' />
    }
    if (prop.boolean && pickProp.pick === 'under') {
      innerPresentation = <i className='ion-checkmark' />
    }
    if (prop.boolean && pickProp.pick === 'over') {
      innerPresentation = <i className='ion-close' />
    }
    if (maxPicks !== null && pickProp.pick === null) {
      innerPresentation = ''
    }
  } else {
    if (maxPicks === null) {
      innerPresentation =
        prop.fixture.sort_status !== 1
          ? (
            <i className='ion-close' style={{ color: '#E00034' }} />
            )
          : null
    } else {
      innerPresentation = ''
    }
  }

  if (prop.fixture.sort_status !== 1) {
    if (pickProp !== null) {
      if (prop.winning_pick === 'under' || prop.winning_pick === 'over') {
        colClass = prop.winning_pick === pickProp.pick ? 'gano' : 'perdio'
      }
    }
  }

  return (
    <div className='item-container'>
      <div className={`shirt-picks ${colClass}`}>{innerPresentation}</div>
    </div>
  )
}

const OwnUserTiebreaker = ({ tiebreaker, userTiebreaker, teams }) => {
  let innerPresentation = <span style={{ color: '#E00034' }}>N/D</span>
  let team = null

  if (userTiebreaker !== null) {
    if (userTiebreaker.value === null) {
      innerPresentation = 'ND'
    } else {
      if (tiebreaker.type === 'team') {
        team = teams.hasOwnProperty(userTiebreaker.value) ? teams[userTiebreaker.value] : null
        innerPresentation = <i className={`${team.logo} helmet home`} />
      } else if (tiebreaker.type === 'score') {
        innerPresentation = <span style={{ color: '#E00034' }}>N/D</span>
        try {
          const val = JSON.parse(tiebreaker.value)
          innerPresentation = `<span>${val.away}-${val.home}</span>`
        } catch (e) {}
      } else {
        innerPresentation = userTiebreaker.value
      }
    }
  }

  return (
    <div className='item-container'>
      <div className='shirt-picks'>
        {innerPresentation}
        {team !== null ? <small>{team.abbreviation}</small> : null}
      </div>
    </div>
  )
}

const FixturePick = ({ fixture, pick, maxPicks, pointFormat, allowTies, parlayFlag }) => {
  // 0 live
  // 1 upcoming
  // 2 closed

  let colClass = null
  let innerPresentation = null
  let team = null

  if (pick !== null) {
    if (fixture.sort_status !== 1 || pick.st === 1) {
      switch (pick.pick) {
        case 'home':
          team = fixture.home_team
          innerPresentation = <i className={`quina ${team.logo} picksG home team-icon`} />
          break

        case 'away':
          team = fixture.away_team
          innerPresentation = <i className={`quina ${team.logo} picksG home team-icon`} />
          break

        case 'tie':
          team = null
          innerPresentation = 'E'
          break
      }
      colClass = null
      if (fixture.sort_status !== 1) {
        if (pointFormat === 'natural') {
          if ((!allowTies && fixture.winning_pick === 'tie') || fixture.winning_pick === 'pick') {
            colClass = ''
          } else {
            colClass = fixture.winning_pick === pick.pick ? 'gano' : 'perdio'
          }
        }

        if (pointFormat === 'line') {
          if (
            (!allowTies && fixture.winning_pick_line === 'tie') ||
            fixture.winning_pick_line === 'pick'
          ) {
            colClass = ''
          } else {
            colClass = fixture.winning_pick_line === pick.pick ? 'gano' : 'perdio'
          }
        }
      }
    } else {
      if (maxPicks !== null || parlayFlag !== null) {
        // do not show anything
        innerPresentation = null
      } else {
        innerPresentation = <i className='ion-checkmark' style={{ color: '#78BC28' }} />
      }
    }
  } else {
    if (maxPicks !== null || parlayFlag !== null) {
      colClass = null
      innerPresentation = null
    } else {
      colClass = fixture.sort_status !== 1 ? 'perdio' : null
      innerPresentation = <i className='ion-close' style={{ color: '#E00034' }} />
    }
  }

  return (
    <div className='item-container'>
      <div className={`shirt-picks ${colClass}`}>
        {innerPresentation}
        {team !== null ? <small className='team-name'>{team.abbreviation}</small> : null}
      </div>
    </div>
  )
}

const PropPick = ({ prop, pickProp, maxPicks }) => {
  // 0 live
  // 1 upcoming
  // 2 closed
  let colClass = null
  let innerPresentation = null

  if (pickProp !== null) {
    if (prop.fixture.sort_status !== 1 || pickProp.st === 1) {
      if (!prop.boolean && pickProp.pick === 'under') {
        innerPresentation = <i className='ion-minus' />
      }
      if (!prop.boolean && pickProp.pick === 'over') {
        innerPresentation = <i className='ion-plus' />
      }
      if (prop.boolean && pickProp.pick === 'under') {
        innerPresentation = <i className='ion-checkmark' />
      }
      if (prop.boolean && pickProp.pick === 'over') {
        innerPresentation = <i className='ion-close' />
      }
      colClass = ''
      if (
        prop.fixture.sort_status !== 1 &&
        (prop.winning_pick === 'under' || prop.winning_pick === 'over')
      ) {
        colClass = prop.winning_pick === pickProp.pick ? 'gano' : 'perdio'
      }
    } else {
      if (maxPicks !== null) {
        // should not show who put what
        colClass = null
        innerPresentation = null
      } else {
        colClass = null
        innerPresentation = <i className='ion-checkmark' style={{ color: '#78BC28' }} />
      }
    }
  } else {
    if (maxPicks === null) {
      colClass = prop.fixture.sort_status !== 1 ? 'perdio' : null
      innerPresentation = <i className='ion-close' style={{ color: '#E00034' }} />
    } else {
      colClass = null
      innerPresentation = null
    }
  }

  return (
    <div className='item-container'>
      <div className={`shirt-picks ${colClass}`}>{innerPresentation}</div>
    </div>
  )
}

const UserTiebreaker = ({ tiebreaker, userTiebreaker, teams }) => {
  //  still available for input so not showing it
  if (tiebreaker.enabled) {
    return (
      <div className='item-container'>
        <div className='shirt-picks'>
          {userTiebreaker !== null
            ? (
              <i className='ion-checkmark' style={{ color: '#78BC28' }} />
              )
            : (
              <i className='ion-close' style={{ color: '#E00034' }} />
              )}
        </div>
      </div>
    )
  } else {
    let innerPresentation = null
    let team = null

    if (userTiebreaker === null) {
      innerPresentation = <span style={{ color: '#E00034' }}>N/D</span>
    } else {
      if (tiebreaker.type === 'team') {
        team = teams.hasOwnProperty(userTiebreaker.val) ? teams[userTiebreaker.val] : null
        if (team) innerPresentation = <i className={`${team.logo} helmet home`} />
      } else if (tiebreaker.type === 'score') {
        innerPresentation = <span style={{ color: '#E00034' }}>N/D</span>
        try {
          const val = JSON.parse(tiebreaker.val)
          innerPresentation = `<span>${val.away}-${val.home}</span>`
        } catch (e) {}
      } else {
        innerPresentation = userTiebreaker.val
      }
    }

    return (
      <div className='item-container'>
        <div className='shirt-picks'>
          {innerPresentation}
          {team !== null ? <span className='team-name'>{team.abbreviation}</span> : null}
        </div>
      </div>
    )
  }
}

const TablaGruposBracket = ({
  poolInfo,
  entryId,
  activeStage,
  bracketGroupPicks,
  bracketTeams,
  actualPagina,
  anteriorPag,
  entriesInfo,
  bracketStages,
  logged,
  picksGrupal,
  bracketInfo,
  siguientePag,
  dataReady
}) => {
  let listaNicksUser = []
  let listaScrollData = []
  let listaTotales = []

  bracketStages = _.values(bracketStages)

  let matchesList = bracketStages[activeStage - 1].map((stage) => stage)

  matchesList = matchesList.sort(firstBy('sort_order'))

  if (activeStage >= 2) {
    matchesList = [
      ...bracketStages[1],
      ...bracketStages[2],
      ...bracketStages[3],
      ...bracketStages[4]
    ]
    matchesList = matchesList.sort(firstBy('sort_order'))
  }

  const userData = utilities.getUserInfo()

  listaNicksUser = bracketGroupPicks.items.map((groupPick) => {
    let userNick = typeof groupPick.nick === 'number' ? groupPick.nick.toString() : groupPick.nick
    userNick = userNick.slice(0, 9)

    return (
      <li
        key={groupPick.bracket_entry_id}
        id={Utils.highlightThisUser(groupPick.user_id, 64560)}
        className={`lower-table-item user ${
          Number(entryId) === groupPick.bracket_entry_id ? 'ownUser' : ''
        }`}
      >
        <div className={`item-container ${groupPick.alive ? 'gano' : 'perdio'}`}>
          <div className='image-container'>
            <img
              className='avatarPicksGrupo'
              src={
                groupPick.avatar === '/img/profile-image-01.png' ? avatar : `${groupPick.avatar}`
              }
            />
          </div>
          <span style={{ fontWeight: 'bold' }}>
            {userNick}
            {groupPick.number !== null
              ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px'
                  }}
                >
                  {groupPick.number}
                </sup>
                )
              : (
                  ''
                )}
          </span>
        </div>
      </li>
    )
  })
  /**
   * Mapping picks and evaluete if is correct
   */
  listaScrollData = bracketGroupPicks.items.map((groupPick) => {
    // let conditionalComponent = null;
    // let isAlive = groupPick.alive;
    let listPicks = null
    /**
     * No Picks Available
     */
    if (!groupPick.bracket_picks) {
      // groupPick

      listPicks = matchesList.map((match, matchIdx) => {
        return (
          <div key={matchIdx} className='lower-table-item fixture'>
            <div className='item-container'>
              <div className='shirt-picks'>
                <i className='ion-close-round' />
              </div>
            </div>
          </div>
        )
      })
    } else {
      /**
       * Picks available
       * **/
      listPicks = matchesList.map((match, matchIdx) => {
        const myBracketPicks = _.values(groupPick.bracket_picks)
        // console.log('group bracket', groupPick.bracket_picks[match.number], match.number );
        /**
         * Current pïck isnt available
         */
        if (groupPick.bracket_picks[match.number] === undefined) {
          // console.log('Picks Not Availablel' , groupPick.bracket_picks[match.number], match.number);
          return (
            <div
              key={matchIdx}
              data-match-id={match.number}
              data-pick-exist={groupPick.bracket_picks[match.number]}
              className='lower-table-item fixture'
            >
              <div className='item-container'>
                <div className='shirt-picks '>
                  <i className={`${'ion-close-round'}`} />
                </div>
              </div>
            </div>
          )
        }

        // let isPick = myBracketPicks.find((myBracketPick, myBracketIdx) => myBracketPick === 1);
        // console.log('isPick', isPick);
        /**
         * Pick ist ready
         */
        if (
          !groupPick.bracket_picks[match.number] ||
          groupPick.bracket_picks[match.number] === 'null'
        ) {
          // console.log('Picks Not Availablel' , groupPick.bracket_picks[match.number], match.number);
          return (
            <div
              key={matchIdx}
              data-match-id={match.number}
              data-pick-exist={groupPick.bracket_picks[match.number]}
              className='lower-table-item fixture'
            >
              <div className='item-container'>
                <div className='shirt-picks '>
                  <i className='ion-close-round' />
                </div>
              </div>
            </div>
          )
        } else {
          /**
           * Evaluate current pick
           */
          if (groupPick.bracket_picks[match.number] !== 1) {
            const teamLogo = bracketTeams.find(
              (team) => team.id === groupPick.bracket_picks[match.number].pick
            )
            if (!teamLogo) {
              // console.log(groupPick);
            }
            const isCorrect =
              groupPick.bracket_picks[match.number].correct === '1'
                ? ''
                : groupPick.bracket_picks[match.number].correct === '0'
                  ? 'perdio'
                  : ''
            return (
              <div key={matchIdx} className='lower-table-item fixture'>
                <div className='item-container'>
                  <div className={`shirt-picks ${isCorrect} bracket`}>
                    <i className={`quina ${!teamLogo ? '' : teamLogo.logo}`} />
                  </div>
                </div>
              </div>
            )
          } else {
            /**
             * Pick in the Limbo
             * **/
            return (
              <div key={matchIdx} className='lower-table-item fixture'>
                <div className='item-container'>
                  <div className='shirt-picks'>
                    <i className='ion-checkmark-round' />
                  </div>
                </div>
              </div>
            )
          }
          // console.log('Picks' , groupPick.bracket_picks[match.number], match.number);
        }
      })
    }
    // listPicks = groupPick.bracket_picks.map()
    return (
      <div key={groupPick.bracket_entry_id} className='lower-table-item'>
        {listPicks}
      </div>
    )
  })

  listaTotales = bracketGroupPicks.items.map((groupPick) => {
    return (
      <div
        key={groupPick.bracket_entry_id}
        className={`lower-table-item fixture ${
          Number(entryId) === groupPick.bracket_entry_id ? 'ownUser' : ''
        }`}
      >
        <div className='lower-table-item fixture'>
          <div className='item-container'>
            <div className={`shirt-picks ${groupPick.alive ? 'gano' : 'perdio'}`}>
              {groupPick.alive
                ? (
                  <i className='ion-checkmark-round' />
                  )
                : (
                  <i className='ion-close-round' />
                  )}
            </div>
          </div>
        </div>
      </div>
    )
  })

  let userRow = {}
  let listPicksUser = null
  if (entriesInfo) {
    userRow = {
      avatar: userData.avatar,
      avatarDir: userData.avatar_dir,
      nick: userData.nick,
      user_id: entriesInfo.user_id,
      number: entriesInfo.number,
      bracket_picks: entriesInfo.bracket_picks,
      alive: entriesInfo.bracket_standing.alive
    }

    /**
     **Map user picks
     **/
    // console.log('matchesList', matchesList);
    listPicksUser = _.values(matchesList).map((match, matchIndx) => {
      if (!userRow.bracket_picks[match.number]) {
        return (
          <div key={match.number} className='item-container'>
            <span className='shirt-picks'>
              <i className='ion-close-round' style={{ color: '#E00034' }} />
            </span>
          </div>
        )
      }

      if (userRow.bracket_picks[match.number].pick !== null) {
        const currentTeam = bracketTeams.find(
          (team) => team.id === userRow.bracket_picks[match.number].pick
        )
        const isCorrect = userRow.bracket_picks[match.number].correct
          ? ''
          : !userRow.bracket_picks[match.number].correct &&
            userRow.bracket_picks[match.number].correct !== null
              ? 'perdio'
              : ''
        return (
          <div key={userRow.bracket_picks[match.number].id} className='item-container'>
            <div className={`shirt-picks team ${isCorrect} bracket`}>
              {!currentTeam.logo
                ? (
                  <span className='quina'> {currentTeam.abbreviation} </span>
                  )
                : (
                  <span className={`quina ${currentTeam.logo} home`} />
                  )}
            </div>
          </div>
        )
      } else {
        return (
          <div key={userRow.bracket_picks[match.number].id} className='item-container'>
            <span className='shirt-picks'>
              <i className='ion-close-round' style={{ color: '#E00034' }} />
            </span>
          </div>
        )
      }
    })
  }

  // User Row Bracket Group

  return (
    <div className='tabla-grupos'>
      <div className='lower-table border-gradient'>
        <div className='row-grupos header'>
          <div className='lower-table-item user title font-chivo'>
            <span>PICKWINERO</span>
          </div>
          <div className='lower-table-item total'>
            <span>VIVO</span>
          </div>
        </div>
        {!entriesInfo
          ? null
          : (
            <div className='lista-container ownUser'>
              <div className='userList ownUser'>
                <ul className='list-unstyled lower-list'>
                  <li className='lower-table-item user ownUser'>
                    <div className='item-container'>
                      <div className='image-container'>
                        <img
                          src={
                          userRow.avatar === '/img/profile-image-01.png'
                            ? avatar
                            : `${userRow.avatarDir + userRow.avatar}`
                        }
                        />
                      </div>
                      <span style={{ fontWeight: 'bold' }}> {userRow.nick} </span>
                      {userRow.number !== null
                        ? (
                          <span
                  style={{
                            fontWeight: 'lighter',
                            fontSize: '75%',
                            margin: '0 2px'
                          }}
                >
                          [{userRow.number}]
                </span>
                          )
                        : (
                            ''
                          )}
                    </div>
                  </li>
                </ul>
              </div>
              <div className='dataList sync-horizontal ownUser'>
                <div className='lower-table-item ownUser'>{listPicksUser}</div>
              </div>
              <div className='totalesList ownUser'>
                <ul className='list-unstyled lower-list'>
                  <div className='lower-table-item fixture ownUser'>
                    <div className='lower-table-item fixture own'>
                      <div className='item-container'>
                        <div className='shirt-picks'>
                          {userRow.alive
                  ? (
                            <i className='ion-checkmark-round' />
                    )
                  : (
                            <i className='ion-close-round' />
                    )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            )}
        {dataReady
          ? (
            <div className='lista-container'>
              <div className='overflow overflow-y overflow-picks-grupo userList sync-vertical'>
                <ul className='list-unstyled lower-list'>{listaNicksUser}</ul>
              </div>
              <div className='overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal'>
                <InfiniteScroll
                  pageStart={1}
                  loadMore={siguientePag}
                  hasMore={!(picksGrupal && picksGrupal.pages === actualPagina)}
                  loader={<Loading key='labelLoader00' label='...' />}
                  useWindow={false}
                >
                  {listaScrollData}
                </InfiniteScroll>
              </div>
              <div className='overflow overflow-y overflow-picks-grupo totalesList sync-vertical'>
                <ul className='list-unstyled lower-list'>{listaTotales}</ul>
              </div>
            </div>
            )
          : (
            <Loading label='...' />
            )}
      </div>
    </div>
  )
}

function TablaGrupos (props) {
  let listaGrupal = []
  let userNick = null
  let userRow = []
  let listaNicksUser = []
  let listaScrollData = []
  let listaScrollUser = []
  let listWeeksTotal = []
  let listaTotales = []
  const listaTeams = []
  let listaGrupalUser = []
  const allTeams = {}

  // TablaGrupos Render
  /**
   * List of available Teams
   */
  props.fixtures.forEach((fixt) => {
    const objHome = {}
    const objAway = {}
    let tempStore

    allTeams[fixt.home_team.id] = fixt.home_team
    allTeams[fixt.away_team.id] = fixt.away_team

    objHome.abbreviation = fixt.home_team.abbreviation
    objHome.id = fixt.home_team.id

    objAway.abbreviation = fixt.away_team.abbreviation
    objAway.id = fixt.away_team.id

    tempStore = [objHome, objAway]
    listaTeams.push(...tempStore)
  })

  const poolWeek = props.poolWeekSelected
  const picksLen = props.fixtures
  let allPicks = picksLen
  let propsLen = props.fixtureProps.length
  let tiesLen
  let listaUserPicks = null

  /**
   * Count the Props if available
   */
  if (props.poolInfo.pool.format === 'mixed') {
    propsLen = props.fixtureProps
    allPicks = allPicks.concat(propsLen)
  }
  if (props.poolInfo.pool.type === 'full' && props.poolInfo.tiebreakers[''] !== undefined) {
    tiesLen = props.poolInfo.tiebreakers['']
  } else {
    tiesLen = props.poolInfo.tiebreakers[poolWeek]
  }

  /**
   * CheckTies
   */
  if (tiesLen === undefined) {
    tiesLen = []
  }
  // console.log(tiesLen);
  allPicks = allPicks.concat(tiesLen)
  if (props.dataReady) {
    listaUserPicks = props.picksGrupal.items.find((item) => item.nick === props.userData.nick)
    listaGrupal = props.picksGrupal.items
    // console.log(props.entriesInfo);
    const entriesInfoPicksProps = []
    /**
     * User row Configuration
     **/
    if (props.entriesInfo && props.entriesInfo.hasOwnProperty('picks_props')) {
      props.entriesInfo.picks_props.forEach((pickProp) => {
        const tempPickProp = pickProp
        const currentFixture = props.fixtures.find((fixture) => pickProp.fixture_id === fixture.id)
        tempPickProp.fixture = currentFixture
        // console.log(tempPickProp);
        entriesInfoPicksProps.push(tempPickProp)
      })

      const weekStanding = props.entriesInfo.weekly_standings
        ? props.entriesInfo.weekly_standings.find((e) => e.week_id === props.poolWeekSelected)
        : undefined

      userRow = {
        avatar: props.userData.avatar,
        avatarDir: props.userData.avatar_dir,
        user_id: props.entriesInfo.user_id,
        rank: props.entriesInfo.standing.rank,
        number: props.entriesInfo.name,
        nick: props.userData.nick,
        picks: props.entriesInfo.picks,
        picks_props: entriesInfoPicksProps,
        points: props.entriesInfo.standing.points,
        parlay_elimination: props.entriesInfo.standing.parlay_elimination ? props.entriesInfo.standing.parlay_elimination : '',
        user_tiebreaker: props.entriesInfo.user_tiebreakers,
        total_possibly_correct: props.entriesInfo.standing.total_possibly_correct,
        weekly_possibly_correct: weekStanding ? weekStanding.total_possibly_correct : ''
      }

      // Map User Picks (userRow)
      props.fixtures.forEach((pick) => {
        const currentPick = userRow.picks.find((userPick) => userPick.fixture_id === pick.id)
        if (!currentPick) {
          listaGrupalUser.push(null)
        } else {
          listaGrupalUser.push(currentPick)
        }
      })

      // Map User Props if are available
      if (props.poolInfo.pool.format === 'mixed') {
        props.fixtureProps.forEach((pick) => {
          const currentPick = userRow.picks_props.find((userPick) => userPick.prop_id === pick.id)
          if (!currentPick) {
            listaGrupalUser.push(null)
          } else {
            listaGrupalUser.push(currentPick)
          }
        })
      }
      // Map Ties
      listaGrupalUser = listaGrupalUser.concat(userRow.user_tiebreaker)
    }

    /**
     * Make the array of the picks of the user at the top of the table
     **/

    // console.log( 'PROPS', props ) ;
    listaScrollUser = []
    if (props.entryId !== null) {
      const userPicks = props.fixtures.map((fixture) => {
        let idx = -1
        let pick = null
        if (props.entriesInfo.picks) {
          idx = props.entriesInfo.picks.findIndex((pick) => pick.fixture_id === fixture.id)
        }
        if (idx >= 0) {
          pick = props.entriesInfo.picks[idx]
        }

        const keyIdx = `PK-${props.entryId}-${fixture.id}`
        return (
          <OwnPick
            key={keyIdx}
            fixture={fixture}
            pick={pick}
            maxPicks={props.poolInfo.pool.max_picks}
            pointFormat={props.poolInfo.pool.point_format}
            allowTies={props.poolInfo.pool.sport.allow_ties}
            parlayFlag={props.poolInfo.pool.parlay_flag}
          />
        )
      })
      let userPickProps = []
      if (props.poolInfo.pool.format === 'mixed') {
        userPickProps = props.fixtureProps.map((prop) => {
          let idx = -1
          let pickProp = null
          if (props.entriesInfo.picks_props) {
            idx = props.entriesInfo.picks_props.findIndex(
              (pickProp) => pickProp.prop_id === prop.id
            )
          }
          if (idx >= 0) {
            pickProp = props.entriesInfo.picks_props[idx]
          }
          const keyIdx = `PP-${props.entryId}-${prop.id}`
          return (
            <OwnPropPick
              key={keyIdx}
              prop={prop}
              pickProp={pickProp}
              maxPicks={props.poolInfo.pool.max_picks}
            />
          )
        })
      }

      let userTiebreakers = []

      if (props.poolInfo.pool.use_tibreakers) {
        userTiebreakers = tiesLen.map((tiebreaker) => {
          let idx = -1
          let userTiebreaker = null
          const keyIdx = `UT-${props.entryId}-${tiebreaker.id}`

          if (props.entriesInfo.user_tiebreakers) {
            idx = props.entriesInfo.user_tiebreakers.findIndex(
              (userTiebreaker) => userTiebreaker.tiebreaker_id === tiebreaker.id
            )
            if (idx >= 0) {
              userTiebreaker = props.entriesInfo.user_tiebreakers[idx]
            }
          }

          return (
            <OwnUserTiebreaker
              key={keyIdx}
              tiebreaker={tiebreaker}
              userTiebreaker={userTiebreaker}
              teams={allTeams}
            />
          )
        })
      }

      listaScrollUser = [...userPicks, ...userPickProps, ...userTiebreakers]
    }
    /****************************************
     * Usuarios Registrados
     * iteracion por cada usuario registrado
     * **************************************/
    if (listaGrupal.length > 0) {
      // console.log("Si hay usuarios regisrados", listaGrupal);
      // Map Row User
      listaNicksUser = listaGrupal.map((user, usuario) => {
        // console.log(user);
        userNick = typeof user.nick === 'number' ? user.nick.toString() : user.nick
        userNick = userNick.slice(0, 9)
        return (
          <li
            key={`standing-nick-${user.entry_id}`}
            id={Utils.highlightThisUser(user.user_id, 64560)}
            className={`lower-table-item user ${props.entryId === user.entry_id ? 'ownUser' : ''}`}
          >
            <div className='item-container'>
              <div className='image-container'>
                <img
                  className='avatarPicksGrupo'
                  src={user.avatar === '/img/profile-image-01.png' ? avatar : `${user.avatar}`}
                />
              </div>
              <span className='picks-rank'>{props.poolInfo.pool.parlay_flag === true ? '' : `${user.rank}°`}</span>
              <span style={{ fontWeight: 'bold' }}>
                {userNick}
                {user.number !== null
                  ? (
                    <sup
                      style={{
                        fontWeight: 'lighter',
                        fontSize: '75%',
                        margin: '0 2px'
                      }}
                    >
                      {user.number}
                    </sup>
                    )
                  : (
                      ''
                    )}
              </span>
            </div>
          </li>
        )
      })

      /**
       * PicksSection of the table
       */
      listaScrollData = listaGrupal.map((user) => {
        const allPicks = props.fixtures.map((fixture) => {
          const pick =
            user.picks && user.picks.hasOwnProperty(fixture.id) ? user.picks[fixture.id] : null
          const keyIdx = `${user.entry_id}-${fixture.id}`
          return (
            <FixturePick
              key={keyIdx}
              fixture={fixture}
              pick={pick}
              maxPicks={props.poolInfo.pool.max_picks}
              pointFormat={props.poolInfo.pool.point_format}
              allowTies={props.poolInfo.pool.sport.allow_ties}
              parlayFlag={props.poolInfo.pool.parlay_flag}
            />
          )
        })

        let allProps = []

        if (props.poolInfo.pool.format === 'mixed') {
          allProps = props.fixtureProps.map((prop) => {
            const pickProp =
              user.pick_props && user.pick_props.hasOwnProperty(prop.id)
                ? user.pick_props[prop.id]
                : null
            const keyIdx = `${user.entry_id}-${prop.id}`
            return (
              <PropPick
                key={keyIdx}
                prop={prop}
                pickProp={pickProp}
                maxPicks={props.poolInfo.pool.max_picks}
              />
            )
          })
        }

        let allUserTiebreakers = []
        if (props.poolInfo.pool.use_tibreakers) {
          allUserTiebreakers = tiesLen.map((tiebreaker) => {
            const keyIdx = `${user.entry_id}-${tiebreaker.id}`
            const userTiebreaker =
              user.user_tiebreakers && user.user_tiebreakers.hasOwnProperty(tiebreaker.id)
                ? user.user_tiebreakers[tiebreaker.id]
                : null
            return (
              <UserTiebreaker
                key={keyIdx}
                tiebreaker={tiebreaker}
                userTiebreaker={userTiebreaker}
                teams={allTeams}
              />
            )
          })
        }

        const listaFull = [...allPicks, ...allProps, ...allUserTiebreakers]

        return (
          <div key={`picks-user-${user.entry_id}`} className='lower-table-item'>
            {listaFull}
          </div>
        )
      })

      listaTotales = listaGrupal.map((user, usuario) => {
        const keyIdx = `TOT-${user.entry_id}`
        return (
          <li
            key={`pts-user-${keyIdx}`}
            id={Utils.highlightThisUser(user.user_id, 64560)}
            className={`lower-table-item total ${props.entryId === user.entry_id ? 'ownUser' : ''}`}
          >
            <div
              className={`item-container ${props.entryId === user.entry_id ? 'user ownUser' : ''}`}
            >
              <span className='total-points '>
                {props.poolInfo.pool.parlay_flag === true && (
                  <>
                    {user.parlay_elimination === null && user.parlay_elimination !== '' && (
                      <i className='ion-checkmark' style={{ color: '#78BC28' }} />
                    ) || (
                      <i className='ion-close' style={{ color: '#E00034' }} />
                    )}
                  </>
                ) || (
                  <>
                    {props.poolInfo.pool.closed ? user.points : user.total_possibly_correct}
                  </>
                )}
              </span>
            </div>
          </li>
        )
      })

      listWeeksTotal = listaGrupal.map((user, usuario) => {
        const keyIdx = `TOT-${user.entry_id}`
        return (
          <li
            key={`pts-user-${keyIdx}`}
            id={Utils.highlightThisUser(user.user_id, 64560)}
            className={`lower-table-item total ${props.entryId === user.entry_id ? 'ownUser' : ''}`}
          >
            <div
              className={`item-container ${props.entryId === user.entry_id ? 'user ownUser' : ''}`}
            >
              <span className='total-points '>
                {props.poolInfo.pool.closed ? user.points : user.weekly_possibly_correct}
              </span>
            </div>
          </li>
        )
      })

      // console.log(listaGrupal, typeof(listaGrupal));
      // FInaliza rows Users
    } else {
      /**
       * NO Hay Usuarios Registrados
       */
      // console.log("No hay usuarios Registrados");l
      return <span> No hay ningun pickwinero registrado</span>
    }
  } else {
    // console.log('NOT READY');
    listaGrupal = []
  }

  const userRowNick = (
    <li className='lower-table-item user ownUser'>
      <div className='item-container'>
        <div className='image-container'>
          <img
            className='avatarPicksGrupo'
            src={
              userRow.avatar === '/img/profile-image-01.png'
                ? avatar
                : `${userRow.avatarDir + userRow.avatar}`
            }
          />
        </div>
        <span className='picks-rank'>{props.poolInfo.pool.parlay_flag === true ? '' : `${userRow.rank}°`}</span>
        <span style={{ fontWeight: 'bold' }}>
          {userRow.nick}
          {userRow.number !== null
            ? (
              <span
                style={{
                  fontWeight: 'lighter',
                  fontSize: '75%',
                  margin: '0 2px'
                }}
              >
                [{userRow.number}]
              </span>
              )
            : (
                ''
              )}
        </span>
      </div>
    </li>
  )

  // const userRowData = null;
  const userRowTotal = (
    <li className='lower-table-item total user ownUser'>
      <div className='item-container user ownUser'>
        <span className='total-points '>
          {props.poolInfo.pool.parlay_flag === true && (
            <>
              {props.entriesInfo !== null && props.entriesInfo.standing.parlay_elimination === null && props.entriesInfo.standing.parlay_elimination !== '' && (
                <i className='ion-checkmark' style={{ color: '#78BC28' }} />
              ) || (
                <i className='ion-close' style={{ color: '#E00034' }} />
              )}
            </>
          ) || (
            <>
              {props.poolInfo.pool.closed ? userRow.points : userRow.total_possibly_correct}
            </>
          )}
        </span>
      </div>
    </li>
  )

  const userRowWeekTotal = (
    <li className='lower-table-item total user ownUser'>
      <div className='item-container user ownUser'>
        <span className='total-points '>
          {props.poolInfo.pool.closed ? userRow.points : userRow.weekly_possibly_correct}
        </span>
      </div>
    </li>
  )
  // console.table(listaGrupal);
  // console.log( typeof(listaGrupal));
  return props.dataReady
    ? (
      <div className='tabla-grupos'>
        <div className='lower-table border-gradient'>
          <div className='row-grupos header'>
            <div className='lower-table-item user title font-chivo'>
              <span>PICKWINERO</span>
            </div>
            {(props.poolInfo.pool.type === 'full' && props.poolInfo.pool.weekly_prize === true)
              ? <div className='flex-col' style={{ pading: 0, alignItems: 'center', textAlign: 'right' }}>
                <div className='btn-group'>
                  <button className={`btn btn-xs ${props.currentOrderType === 'global' ? 'btn-success' : 'btn-default'}`} onClick={() => props.onChangeOrderType('global')}>PG. Global</button>
                  <button className={`btn btn-xs ${props.currentOrderType === 'week' ? 'btn-success' : 'btn-default'}`} onClick={() => props.onChangeOrderType('week')}>PG. Semana</button>
                </div>
                </div>
              : null}
            <div className='lower-table-item total headers-totals font-chivo' style={props.poolInfo.pool.type === 'full' ? { width: '13%', justifyContent: 'flex-end' } : { width: '7%', justifyContent: 'center' }}>
              {
              props.poolInfo.pool.type === 'full' &&
                <span>
                  <a style={{ cursor: 'pointer', color: props.currentOrderType === 'week' ? '#78b529' : null }} onClick={() => props.onChangeOrderType('week')}>{props.nameWeekSelected}</a>
                  {props.currentOrderType === 'week' ? <i className='ion-arrow-down-b' /> : null}
                </span>
            }
              <span style={{ width: props.poolInfo.pool.type === 'full' ? '50%' : '100%' }}>
                <a style={{ cursor: 'pointer', color: props.currentOrderType === 'global' ? '#78b529' : null }} onClick={() => props.onChangeOrderType('global')}>TOTAL</a>
                {props.currentOrderType === 'global' ? <i className='ion-arrow-down-b' /> : null}
              </span>
            </div>
          </div>
          {props.entriesInfo !== null
            ? (
              <div className='lista-container ownUser'>
                <div className='userList ownUser'>
                  <ul className='list-unstyled lower-list'>{userRowNick}</ul>
                </div>
                <div className='dataList sync-horizontal ownUser'>
                  <div className='lower-table-item'>{listaScrollUser}</div>
                </div>
                <div
                  className='totalesList ownUser'
                  style={
                props.poolInfo.pool.type === 'full'
                  ? { width: '14%', paddingRight: '7px', display: 'flex' }
                  : { width: '7%', paddingRight: '7px', display: 'flex' }
              }
                >
                  {props.poolInfo.pool.type === 'full' && (
                    <ul className='list-unstyled lower-list'>{userRowWeekTotal}</ul>
                  )}
                  <ul
                    className='list-unstyled lower-list'
                    style={{ width: props.poolInfo.pool.type === 'full' ? '50%' : '100%' }}
                  >
                    {userRowTotal}
                  </ul>
                </div>
              </div>
              )
            : null}
          <div className='lista-container' style={{ transform: 'translate3d(0, 0, 0)' }}>
            <div className='overflow overflow-y overflow-picks-grupo userList sync-vertical'>
              <ul className='list-unstyled lower-list'>{listaNicksUser}</ul>
            </div>
            <div className='overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal'>
              <InfiniteScroll
                pageStart={1}
                loadMore={props.siguientePag}
                hasMore={props.picksGrupal.pages !== props.actualPagina}
                loader={<Loading key='labelLoader00' label='...' />}
                useWindow={false}
              >
                {listaScrollData}
              </InfiniteScroll>
            </div>
            <div
              className='overflow overflow-y overflow-picks-grupo totalesList sync-vertical'
              style={
              props.poolInfo.pool.type === 'full'
                ? { width: '14%', display: 'inline' }
                : { width: '7%', display: 'inline' }
            }
            >
              {props.poolInfo.pool.type === 'full' && (
                <ul className='list-unstyled lower-list points-totals'>{listWeeksTotal}</ul>
              )}
              <ul
                className='list-unstyled lower-list points-totals'
                style={{ width: props.poolInfo.pool.type === 'full' ? '50%' : '100%' }}
              >
                {listaTotales}
              </ul>
            </div>
          </div>
        </div>
      </div>
      )
    : (
      <div className='tabla-grupos'>
        <Loading label='Cargando picks de grupo...' />
      </div>
      )
}

class PicksGrupo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mounted: false,
      logged: this.props.logged,
      userData: null,
      picksGrupal: null,
      actualPagina: 1,
      availablePdf: null,
      orderType: 'global' // whether to sort by week position or by global position
    }

    // console.log("%c Picks Grupales", "background:#D9E6AA;font-size:20px;");
    // console.log(this.props);
    this.updateGroupPicks = this.updateGroupPicks.bind(this)
    this.siguientePag = this.siguientePag.bind(this)
    this.onChangeOrderType = this.onChangeOrderType.bind(this)
    this.getNewPageScrolling = Utils.getNewPageScrolling.bind(this)
    this.loadNextPicksPicksGrupo = Utils.loadNextPicksPicksGrupo.bind(this)
    this.loadNextPicksPicksPropsGrupo = Utils.loadNextPicksPicksPropsGrupo.bind(this)

    this.updateGroupPicksSurvivor = (newStandings, groupPicks, gameStatus) => {
      // console.log('%c Update Group Picks Survivor', 'color:#2e8ccf; font-size:2em;');
      // console.log(newStandings , groupPicks);
      const temporalPicksGrupal = this.state.picksGrupal
      if (this.state.submitting) {
        return
      }
      if (!groupPicks) {
        return
      }

      newStandings.standings.forEach((standing) => {
        groupPicks.forEach((groupPick, pickIndx) => {
          if (standing.survivor_entry_id !== groupPick.survivor_entry_id) {
            return
          }
          groupPick.strikes = standing.strikes
          groupPick.rank = standing.rank
          groupPick.alive = standing.alive
          groupPick.open_picks = standing.open_picks
          temporalPicksGrupal.items[pickIndx] = groupPick
        })
      })

      if (gameStatus !== 'closed') {
        console.log('Not closed')
        temporalPicksGrupal.items.sort(
          firstBy('rank')
            .thenBy('alive', -1)
            .thenBy('open_picks', -1)
            .thenBy('strikes')
            .thenBy('pick_count', -1)
            .thenBy('survivor_entry_id')
        )
      }

      this.setState({
        picksGrupal: temporalPicksGrupal
      })
    }
  }

  /**
   * This function handles a call to the API
   * to get the new page of the positions
   */
  siguientePag (eventKey) {
    // console.log('Next Picks...');
    // console.log(this.state.mounted, this.state.loadedPicks);
    // console.log(this.state.actualPagina, this.state.picksGrupal.pages);
    // console.log(this.props.type);
    const loaderElement = $('#loading')
    // console.log(loaderElement);
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex'
    }
    // loading.style.position('flex');
    let selectedWeek = this.props.poolWeekSelected
    const temporalPicksGrupal = this.state.picksGrupal.items
    if (selectedWeek === '') {
      if (!this.props.entriesInfo) {
        selectedWeek = this.props.fixtures[0].week_id
      } else {
        selectedWeek = this.props.fixtures[0].week_id
      }
    }
    // console.log('siguiente');
    // console.log('Next PAge, week', selectedWeek);
    if (
      !this.state.mounted ||
      !this.props.loadedPicks ||
      this.state.actualPagina >= this.state.picksGrupal.pages
    ) {
      if (loaderElement !== undefined && loaderElement.length > 0) {
        loaderElement[0].style.display = 'none'
      }
      return
    }
    // let val = eventKey;
    // console.log('Type', this.props.type);

    if (this.props.type === 'bracket') {
      // console.log('BRacket Fetch Groups');
      API.fetchBracketGroupPicks(
        this.props.bracketID,
        this.props.activeStage,
        this.state.actualPagina + 1
      ).then((response) => {
        this.setState(
          (prevState) => {
            const newPicksGrupal = response.data
            const currentPicksGrupal = this.state.picksGrupal
            const newItems = response.data.items
            newItems.forEach((item) => {
              currentPicksGrupal.items.push(item)
            })
            // console.log('NEWPicksGrupal Brackets');
            newPicksGrupal.items = temporalPicksGrupal
            return {
              picksGrupal: currentPicksGrupal,
              logged: this.props.logged,
              mounted: true,
              actualPagina: prevState.actualPagina + 1
            }
          },
          () => {
            if (loaderElement !== undefined && loaderElement.length > 0) {
              loaderElement[0].style.display = 'none'
              // let avatars = $('.score-avatars').height('auto');
            }
          }
        )
      })
    } else if (this.props.type === 'survivor') {
      // console.log('Survivor Fetch Groups');
      API.fetchSurvivorGroupPicks(this.props.survivorID, this.state.actualPagina + 1)
        .then((response) => {
          this.setState(
            (prevState) => {
              const newPicksGrupal = response.data
              const currentPicksGrupal = this.state.picksGrupal
              const newItems = response.data.items
              newItems.forEach((item) => {
                currentPicksGrupal.items.push(item)
              })
              // console.log('NEWPicksGrupal Brackets');
              newPicksGrupal.items = temporalPicksGrupal
              return {
                picksGrupal: currentPicksGrupal,
                logged: this.props.logged,
                mounted: true,
                actualPagina: prevState.actualPagina + 1
              }
            },
            () => {
              if (loaderElement !== undefined && loaderElement.length > 0) {
                loaderElement[0].style.display = 'none'
                // let avatars = $('.score-avatars').height('auto');
              }
            }
          )
        })
        .catch((error) => {
          Sentry.captureException(error, { extra: 'Error Fetching group picks survivor' })
          window.location.assign('/')
        })
    } else {
      API.fetchGroupPicks(
        this.props.quinielaID,
        selectedWeek,
        this.state.actualPagina + 1,
        this.state.orderType
      ).then((response) => {
        this.setState(
          (prevState) => {
            const newPicksGrupal = response.data
            const newItems = response.data.items
            newItems.forEach((item) => {
              temporalPicksGrupal.push(item)
            })
            // console.log('NEWPicksGrupal',newItems,newPicksGrupal);
            newPicksGrupal.items = temporalPicksGrupal
            return {
              picksGrupal: newPicksGrupal,
              logged: this.props.logged,
              mounted: true,
              actualPagina: prevState.actualPagina + 1
            }
          },
          () => {
            if (loaderElement !== undefined && loaderElement.length > 0) {
              loaderElement[0].style.display = 'none'
              // let avatars = $('.score-avatars').height('auto');
            }
          }
        )
      })
    }
  }

  onChangeOrderType (type) {
    if (type === this.state.orderType) {
      return
    }

    const loaderElement = $('#loading')
    if (loaderElement !== undefined && loaderElement.length > 0) {
      loaderElement[0].style.display = 'flex'
    }

    const newPage = 1
    API.fetchGroupPicks(
      this.props.quinielaID,
      this.props.poolWeekSelected,
      newPage,
      type
    ).then((response) => {
      const data = response.data
      this.setState(() => {
        return {
          mounted: true,
          logged: this.props.logged,
          orderType: type,
          picksGrupal: data,
          actualPagina: newPage
        }
      }, () => {
        if (loaderElement != undefined && loaderElement.length > 0) {
          loaderElement[0].style.display = 'none'
          $('.score-avatars').height('auto')
        }
      })
    })
  }

  updateGroupPicks (standings, groupPicks, status) {
    console.log('UpdateGroupPicks')
    const tempPicksGrupal = this.state.picksGrupal
    const tempItems = this.state.picksGrupal.items
    if (!groupPicks || !groupPicks.length) {
      return
    }
    standings.forEach((standing) => {
      groupPicks.forEach((group, idx) => {
        if (standing.entry_id !== group.entry_id) {
          return
        }
        // let currentPick = groupPicks[idx];
        // console.log('Found', group);
        group.rank = standing.rank
        group.total_possibly_correct = standing.total_possibly_correct
        // console.log('Found', group);
        tempItems[idx] = group
      })
    })
    // console.log(tempPicksGrupal);
    if (status !== 'closed') {
      tempItems.sort(firstBy('total_possibly_correct', -1).thenBy('entry_id'))
    } else {
      tempItems.sort(firstBy('rank').thenBy('entry_id'))
    }
    tempPicksGrupal.items = tempItems
    this.setState({
      picksGrupal: tempPicksGrupal
    })
  }

  /***********************
   * Lifecycle Methods
   *************************/
  componentDidMount () {
    this.weeklyStandingsSubscriptionRx = SubscriptionRxs.weeklyStandingsUpdatedReceived$.subscribe(
      (updatedWeeklyStandings) => {
        if (
          this.props.poolInfo.pool.type !== 'full' ||
          !this.state.picksGrupal ||
          !this.state.picksGrupal.items
        ) {
          return
        }
        if (
          Number.parseInt(this.props.poolWeekSelected) !==
          Number.parseInt(updatedWeeklyStandings.weekId)
        ) {
          return
        }

        let { picksGrupal } = this.state
        const items = [...picksGrupal.items]

        updatedWeeklyStandings.weeklyStandings.forEach((week) => {
          const element = items.find((e) => e.entry_id === week.entry_id)
          if (element) {
            element.weekly_possibly_correct = week.total_possibly_correct
            picksGrupal = { ...picksGrupal, items: [...items] }
            this.setState({ picksGrupal })
          }
        })
      }
    )

    // Timestamp to reload data if user has active tab
    this.focusTimestamp = Date.now()

    this.focus = () => {
      const sec = (Date.now() - this.focusTimestamp) / 1000
      const secondsToRefresh = 30

      if (sec >= secondsToRefresh) {
        this.refreshData()
        this.focusTimestamp = Date.now()
      }
    }

    window.addEventListener('focus', this.focus)

    this.resetRefreshTick = () => {
      clearTimeout(this.refreshTick)

      this.refreshTick = setTimeout(() => {
        this.refreshData()
      }, TIME_CHECK_AFTER_WS_CHANGE)
    }

    // console.log('PG MOUNTED', this.props);
    if (this.props.type === 'survivor') {
      /**
       * This function handles the subscription to get the new Standings from the socket
       * it handles the state and sync the new standings with the PicksGrupal
       **/
      this.standingsSubscription = SubscriptionRxs.survivorStandingsUpdatedReceived$._subscribe(
        (standingsUpdated) => {
          this.resetRefreshTick()

          if (this.state.submitting) {
            return
          }
          if (!this.props.standings || this.props.standings.items.length === 0) {
            return
          }
          if (!this.state.picksGrupal || !this.state.picksGrupal.items) {
            return
          }
          /**
           * Lets map the Standings with the Picks Grupal
           * So it has the same live data
           */
          console.log('Subscritpion Standings Next', standingsUpdated)
          // console.log('Subscritpion Standings Next', this.props.standings);
          // console.log('Subscritpion Standings Next', this.state.picksGrupal);
          this.updateGroupPicksSurvivor(
            standingsUpdated,
            this.state.picksGrupal.items,
            this.props.poolInfo.status
          )
        }
      )

      this.fixtureUpdateSubscription = SubscriptionRxs.fixtureReceived$._subscribe(
        (fixtureUpdate) => {
          this.resetRefreshTick()
          // Revisar por fixture y buscar si el pick es correcto o incorrecto de cada postura y pick

          // console.log('FixtureUpdate', fixtureUpdate.live_fixtures);

          if (this.state.submitting) {
            return
          }

          if (!this.state.picksGrupal || !this.state.picksGrupal.items) {
            return
          }

          const temporalPicksGrupal = this.state.picksGrupal
          let updateThePicks = false

          fixtureUpdate.live_fixtures.forEach((liveFixture) => {
            temporalPicksGrupal.items.forEach((standing) => {
              if (liveFixture.week_id !== this.props.poolWeekSelected) {
                return
              }
              if (!standing.survivor_picks) {
                console.error('survivor_picks arent available', standing)
                return
              }
              // console.log(standing.survivor_picks , this.props.poolWeekSelected);

              const currentPick = standing.survivor_picks[this.props.poolWeekSelected]
              if (!currentPick) {
                return
              }
              if (currentPick.fid !== liveFixture.id) {
                return
              }

              updateThePicks = true

              const which =
                this.props.poolInfo.survivor?.point_format === 'natural'
                  ? 'winning_pick'
                  : 'winning_pick_line'

              const winningPick = liveFixture[which]
              let winnerTeam = 'tie'

              if (winningPick === 'home') {
                winnerTeam = liveFixture.home_team
              }
              if (winningPick === 'away') {
                winnerTeam = liveFixture.away_team
              }

              if (currentPick.pick === winnerTeam.id) {
                currentPick.correct = true
                currentPick.strike = false
              } else {
                if (!this.props.poolInfo.survivor.strikes_enabled) {
                  if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                    currentPick.correct = true
                    currentPick.strike = false
                  }
                  if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                    currentPick.correct = false
                    currentPick.strike = false
                  }
                } else {
                  if (this.props.poolInfo.survivor.point_format === 'natural') {
                    if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                      currentPick.correct = false
                      currentPick.strike = false
                    }
                    if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                      currentPick.correct = false
                      currentPick.strike = true
                    }
                  } else {
                    // line

                    if (currentPick.pick !== winnerTeam.id && winnerTeam !== 'tie') {
                      currentPick.correct = false
                      currentPick.strike = true
                    }
                    if (currentPick.pick !== winnerTeam.id && winnerTeam === 'tie') {
                      currentPick.correct = false
                      currentPick.strike = true
                    }
                  }
                }
              }

              standing.survivor_picks[this.props.poolWeekSelected].correct = currentPick.correct
              standing.survivor_picks[this.props.poolWeekSelected].strike = currentPick.strike
            })
          })

          if (updateThePicks) {
            console.log('update the group picks ')

            temporalPicksGrupal.items.sort(
              firstBy('rank')
                .thenBy('alive', -1)
                .thenBy('open_picks', -1)
                .thenBy('strikes')
                .thenBy('pick_count', -1)
                .thenBy('survivor_entry_id')
            )

            this.setState({ picksGrupal: temporalPicksGrupal })
          }
        }
      )

      this.fixtureClosedSubscription = SubscriptionRxs.fixtureCloseReceived$._subscribe(
        (fixtureClosed) => {
          this.resetRefreshTick()

          console.log('%c Fixture Closed', 'font-size:2em; color:#df4b26 ;')
          // console.log( fixtureClosed );
          // console.log( this.state.picksGrupal );
          if (this.state.submitting) {
            return
          }

          if (!this.state.picksGrupal || !this.state.picksGrupal.items.length) {
            return
          }

          const temporalPicksGrupal = this.state.picksGrupal

          temporalPicksGrupal.items.forEach((standing) => {
            if (!standing.hasOwnProperty('survivor_picks')) {
              return
            }
            if (!standing.survivor_picks) {
              return
            }

            if (!standing.survivor_picks[this.props.poolWeekSelected]) {
              return
            }
            console.log('it has picks')
            const currentPick = standing.survivor_picks[this.props.poolWeekSelected]

            if (currentPick.fid === fixtureClosed) {
              standing.survivor_picks[this.props.poolWeekSelected].st = 2
            }
          })

          temporalPicksGrupal.items.sort(
            firstBy('rank')
              .thenBy('alive', -1)
              .thenBy('open_picks', -1)
              .thenBy('strikes')
              .thenBy('pick_count', -1)
              .thenBy('survivor_entry_id')
          )

          this.setState({
            picksGrupal: temporalPicksGrupal
          })
        }
      )
      API.fetchUserData()
        .then((response) => {
          this.setState(
            () => {
              // console.log(response);
              return {
                mounted: false,
                userData: response.data.user,
                logged: response.data.user !== null
              }
            },
            () => {
              API.fetchSurvivorGroupPicks(this.props.survivorID, this.state.actualPagina).then(
                (response) => {
                  this.setState(
                    () => {
                      // console.log('PicksGrupal Survivor',response);
                      const tempPicksGrupal = response.data
                      // if(this.props.poolInfo.pool.status !== 'closed'){
                      //   tempPicksGrupal.items.sort(firstBy('total_possibly_correct', -1).thenBy('entry_id'));
                      // }
                      return {
                        mounted: true,
                        picksGrupal: tempPicksGrupal
                        // picksGrupal: mockSoccerPicksGrupo,
                      }
                    },
                    () => {
                      window.$('.sync-horizontal').synchroniseScroll('horizontal')
                      window.$('.sync-vertical').synchroniseScroll('vertical')
                      // let avatars = $('.score-avatars').height('auto');
                    }
                  )
                }
              )
            }
          )
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error Fetching all  in Picks Grupo'
          })
        })
    } else if (this.props.type === 'bracket') {
      /**
       * This function handles the subscription to get the new Standings from the socket
       * it handles the state and sync the new standings with the PicksGrupal
       **/
      this.standingsSubscription = SubscriptionRxs.standingsUpdatedReceived$._subscribe(
        (standingsUpdated) => {
          this.resetRefreshTick()

          if (
            this.props.standings === undefined ||
            this.props.standing === null ||
            this.props.standings.items.length === 0
          ) {
            return
          }
          if (
            this.state.picksGrupal === undefined ||
            this.state.picksGrupal === null ||
            this.state.picksGrupal.items.length === 0
          ) {
            return
          }
          /**
           * Lets map the Standings with the Picks Grupal
           * So it has the same live data
           */
          console.log('Subscritpion Standings Next', standingsUpdated)
          this.updateGroupPicks(
            standingsUpdated,
            this.state.picksGrupal.items,
            this.state.poolInfo.pool.status
          )
        }
      )
      API.fetchUserData()
        .then((response) => {
          this.setState(
            () => {
              // console.log(response);
              return {
                userData: response.data.user,
                logged: response.data.user !== null
              }
            },
            () => {
              // console.log('SUP');
              API.fetchBracketGroupPicks(
                this.props.bracketID,
                this.props.activeStage,
                this.props.groupPicksPage
              ).then((responseGroup) => {
                console.log('PicksGrupal', responseGroup)
                const tempPicksGrupal = responseGroup.data
                this.setState(
                  {
                    mounted: true,
                    picksGrupal: tempPicksGrupal
                  },
                  () => {
                    window.$('.sync-horizontal').synchroniseScroll('horizontal')
                    window.$('.sync-vertical').synchroniseScroll('vertical')
                    const avatars = $('.score-avatars').height('auto')
                  }
                )
              })
            }
          )
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error Fetching all  in Picks Grupo'
          })
        })
    } else {
      let selectedWeek
      if (this.props.poolInfo.pool.type === 'full' && this.props.poolWeekSelected !== null) {
        selectedWeek = this.props.poolWeekSelected
      } else {
        if (this.props.poolWeekSelected !== null) {
          selectedWeek = this.props.poolWeekSelected
        } else {
          console.error('Error')
        }
      }

      if (selectedWeek === '') {
        selectedWeek = this.props.fixtures[0].week_id
      }
      /**
       * This function handles the subscription to get the new Standings from the socket
       * it handles the state and sync the new standings with the PicksGrupal
       **/
      this.standingsSubscription = SubscriptionRxs.standingsUpdatedReceived$._subscribe(
        (standingsUpdated) => {
          this.resetRefreshTick()

          if (
            this.props.standings === undefined ||
            this.props.standing === null ||
            this.props.standings.items.length === 0
          ) {
            return
          }
          if (
            this.state.picksGrupal === undefined ||
            this.state.picksGrupal === null ||
            this.state.picksGrupal.items.length === 0
          ) {
            return
          }
          /**
           * Lets map the Standings with the Picks Grupal
           * So it has the same live data
           */
          console.log('Subscritpion Standings Next', standingsUpdated)
          this.updateGroupPicks(
            standingsUpdated,
            this.state.picksGrupal.items,
            this.state.poolInfo.pool.status
          )
        }
      )
      API.fetchUserData().then((response) => {
        this.setState(() => {
          // console.log(response);
          return {
            mounted: true,
            userData: response.data.user,
            logged: response.data.user !== null
          }
        })

        if (this.state.logged) {
          API.fetchGroupPicks(this.props.quinielaID, selectedWeek, 1).then((response) => {
            this.setState(
              () => {
                const tempPicksGrupal = response.data
                if (this.props.poolInfo.pool.status !== 'closed') {
                  tempPicksGrupal.items.sort(
                    firstBy('total_possibly_correct', -1).thenBy('entry_id')
                  )
                }
                return {
                  picksGrupal: tempPicksGrupal,
                  logged: this.props.logged
                }
              },
              () => {
                window.jQuery('.sync-horizontal').synchroniseScroll('horizontal')
                window.jQuery('.sync-vertical').synchroniseScroll('vertical')
              }
            )
          })
        } else {
          // consoel.log('PG', 'NOT logged', this);
          // notLogged
        }
      })
    }

    this.handleUpdateMyPicks = () => {
      this.refreshData()
    }

    subscribe('UPDATE_MY_PICKS', this.handleUpdateMyPicks)
  }

  refreshData = () => {
    if (this.props.type === 'survivor') {
      API.fetchUserData()
        .then((response) => {
          this.setState(
            () => {
              return {
                userData: response.data.user,
                logged: response.data.user !== null
              }
            },
            () => {
              API.fetchSurvivorGroupPicks(this.props.survivorID, this.state.actualPagina).then(
                (response) => {
                  this.setState(
                    () => {
                      const tempPicksGrupal = response.data
                      return {
                        mounted: true,
                        picksGrupal: tempPicksGrupal
                      }
                    },
                    () => {
                      window.$('.sync-horizontal').synchroniseScroll('horizontal')
                      window.$('.sync-vertical').synchroniseScroll('vertical')
                    }
                  )
                }
              )
            }
          )
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error Fetching all  in Picks Grupo'
          })
        })
    } else if (this.props.type === 'bracket') {
      API.fetchUserData()
        .then((response) => {
          this.setState(
            () => {
              return {
                userData: response.data.user,
                logged: response.data.user !== null
              }
            },
            () => {
              API.fetchBracketGroupPicks(
                this.props.bracketID,
                this.props.activeStage,
                this.props.groupPicksPage
              ).then((responseGroup) => {
                console.log('PicksGrupal', responseGroup)
                const tempPicksGrupal = responseGroup.data
                this.setState(
                  {
                    mounted: true,
                    picksGrupal: tempPicksGrupal
                  },
                  () => {
                    window.$('.sync-horizontal').synchroniseScroll('horizontal')
                    window.$('.sync-vertical').synchroniseScroll('vertical')
                  }
                )
              })
            }
          )
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error Fetching all  in Picks Grupo'
          })
        })
    } else {
      let selectedWeek
      if (this.props.poolInfo.pool.type === 'full' && this.props.poolWeekSelected !== null) {
        selectedWeek = this.props.poolWeekSelected
      } else {
        if (this.props.poolWeekSelected !== null) {
          selectedWeek = this.props.poolWeekSelected
        } else {
          console.error('Error')
        }
      }

      if (selectedWeek === '') {
        selectedWeek = this.props.fixtures[0].week_id
      }
      API.fetchUserData().then((response) => {
        this.setState(() => {
          // console.log(response);
          return {
            mounted: true,
            userData: response.data.user,
            logged: response.data.user !== null
          }
        })
        if (this.state.logged) {
          API.fetchGroupPicks(this.props.quinielaID, selectedWeek, 1).then((response) => {
            this.setState(
              () => {
                const tempPicksGrupal = response.data
                if (this.props.poolInfo.pool.status !== 'closed') {
                  tempPicksGrupal.items.sort(
                    firstBy('total_possibly_correct', -1).thenBy('entry_id')
                  )
                }
                return {
                  picksGrupal: tempPicksGrupal,
                  logged: this.props.logged
                }
              },
              () => {
                window.jQuery('.sync-horizontal').synchroniseScroll('horizontal')
                window.jQuery('.sync-vertical').synchroniseScroll('vertical')
              }
            )
          })
        }
      })
    }
  }

  componentWillUnmount () {
    console.log('Component will unmount')
    if (this.standingsSubscription) {
      // console.log('Closed Standings Subscription ');
      this.standingsSubscription.unsubscribe()
    }

    if (this.fixtureUpdateSubscription) {
      // console.log('Closed fixture update Subscription ');
      this.fixtureUpdateSubscription.unsubscribe()
    }

    if (this.fixtureClosedSubscription) {
      // console.log('Closed fixture close Subscription ');
      this.fixtureClosedSubscription.unsubscribe()
    }

    if (this.weeklyStandingsSubscriptionRx) {
      this.weeklyStandingsSubscriptionRx.unsubscribe()
    }

    clearTimeout(this.refreshTick)
  }

  componentDidUpdate (prevProps, prevState) {
    // console.log( 'componentDidUpdate' );
    // this.props.handleUpdateReset();
    // console.log( prevProps.updatedStandings , this.props.updatedStandings);
    // console.log(this.props.fixtureProps, prevProps.fixtureProps)
    if (
      prevProps.poolWeekSelected !== this.props.poolWeekSelected &&
      this.props.loadedPicks &&
      this.props.poolInfo.hasOwnProperty('pool')
    ) {
      // console.log('PoolWeekSelected');
      this.setState(
        {
          actualPagina: 1,
          mounted: false,
          orderType: 'global'
        },
        () => {
          API.fetchGroupPicks(
            this.props.quinielaID,
            this.props.poolWeekSelected,
            this.state.actualPagina
          ).then((response) => {
            this.setState(
              (prevState) => {
                return {
                  mounted: true,
                  picksGrupal: response.data,
                  logged: this.props.logged,
                  actualPagina: prevState.actualPagina + 1
                }
              },
              () => {
                console.log('Update Height')
                setTimeout(() => {
                  window.$('.sync-horizontal').synchroniseScroll('horizontal')
                  window.$('.sync-vertical').synchroniseScroll('vertical')
                  this.props.handleMaxHeight()
                }, 50)
              }
            )
          })
        }
      )
    } else if (
      prevProps.poolWeekSelected !== this.props.poolWeekSelected &&
      this.props.loadedPicks &&
      this.props.poolInfo.hasOwnProperty('survivor')
    ) {
      console.log('Survivor WeekSelected Changed')
    }

    if (
      !prevProps.updateMyPicks &&
      this.props.updateMyPicks &&
      this.props.poolInfo.hasOwnProperty('survivor')
    ) {
      // find my Entry and replace the picks with the CurrentEntryPicks,
      API.fetchSurvivorGroupPicks(this.props.survivorID, this.state.actualPagina)
        .then((response) => {
          this.setState({
            picksGrupal: response.data
          })
        })
        .catch((error) => {
          console.error(error)
          Sentry.captureException(error, { extra: 'Error after Updated Picks' })
        })
    }

    if (this.props.activeStage && this.props.activeStage !== prevProps.activeStage) {
      console.log('%c UpdateStage', 'font-size:2em ;')
      if (this.props.type !== 'bracket') {
        return
      }
      const loaderElement = $('#loading')
      if (loaderElement !== undefined && loaderElement.length > 0) {
        // console.log('showLoader');
        // console.log(loaderElement[0]);
        loaderElement[0].style.display = 'flex'
        loaderElement[0].style.background = 'rgba(20,20,20,.8)'
      }

      API.fetchBracketGroupPicks(
        this.props.bracketID,
        this.props.activeStage,
        1,
        this.state.actualPagina * 100
      ).then((response) => {
        this.setState(
          (prevState) => {
            const temporalPicksGrupal = []
            const prevGroupPicks = this.state.picksGrupal
            const newPicksGrupal = response.data
            const newItems = response.data.items
            newItems.forEach((item) => {
              const newPickIndex = _.findIndex(
                prevGroupPicks.items,
                (prevPick) => prevPick.bracket_entry_id === item.bracket_entry_id
              )
              if (newPickIndex === undefined || newPickIndex === -1) {
                // console.log(newPickIndex, item);
                return
              }

              prevGroupPicks.items[newPickIndex].bracket_picks = {
                ...prevGroupPicks.items[newPickIndex].bracket_picks,
                ...item.bracket_picks
              }
            })
            // console.log('NEWPicksGrupal Brackets');
            newPicksGrupal.items = temporalPicksGrupal
            return {
              picksGrupal: prevGroupPicks,
              logged: this.props.logged,
              mounted: true
            }
          },
          () => {
            // console.log('Hide Loader');
            if (loaderElement !== undefined && loaderElement.length > 0) {
              setTimeout(() => {
                loaderElement[0].style.display = 'none'
                loaderElement[0].style.background = 'transparent'
              }, 500)
            }
          }
        )
      })
    }

    if (this.state.picksGrupal !== prevState.picksGrupal && prevState.picksGrupal === null) {
      // console.log('First load');
      if (this.props.type === 'bracket') {
        return
      }
      this.props.handleMaxHeight()
    }

    if (
      this.props.updatedStandings !== prevProps.updatedStandings &&
      this.props.updatedStandings &&
      this.state.picksGrupal
    ) {
      // console.log('standings changed');
      this.updateGroupPicks(
        this.props.standings.items,
        this.state.picksGrupal.items,
        this.props.poolInfo.pool.status
      )
      this.props.handleUpdateReset()
      if (this.props.nextPicks !== null) {
        // console.log('------------>','NExtPicks arrived');
        this.loadNextPicksPicksGrupo()
        this.props.handleUpdateReset()
      }
      if (this.props.nextProps !== null) {
        // console.log('------------>','NExtPicks arrived');
        this.loadNextPicksPicksPropsGrupo()
        this.props.handleUpdateReset()
      }
    }
    // if(this.props.liveFixturesData !== prevProps.liveFixturesData){console.log('LiveData')}
  }

  render () {
    // console.log('Render Picks Grupo');
    // let temporalPicksGrupo, temporalItems;
    let conditionalComponent = null
    if (this.state.mounted) {
      conditionalComponent = (
        <div className='container-picks'>
          {this.props.type === 'bracket'
            ? (
              <TablaGruposBracket
                poolInfo={this.props.poolInfo}
                activeStage={this.props.activeStage}
                actualPagina={this.state.actualPagina}
                anteriorPag={this.anteriorPag}
                bracketGroupPicks={this.state.picksGrupal}
                bracketTeams={this.props.bracketTeams}
                bracketInfo={this.props.poolInfo}
                bracketStages={this.props.bracketStages}
                entriesInfo={this.props.entriesInfo}
                entryId={this.props.selectedEntry}
                liveClosedFixture={this.props.liveClosedFixtures}
                liveFixturesData={this.props.liveFixtureData}
                logged={this.props.logged}
                dataReady={
                !!(this.state.picksGrupal !== undefined && this.state.picksGrupal !== null)
              }
                picksGrupal={this.props.bracketGroupPicks}
                siguientePag={this.siguientePag}
                userData={this.state.userData}
              />
              )
            : this.props.type === 'survivor'
              ? (
                <TablaPicksGrupoSurvivor
                  fixtures={this.props.fixtures}
                  currentWeek={this.props.currentWeek}
                  survivorInfo={this.props.poolInfo}
                  entryId={this.props.selectedEntry}
                  survivorTeams={this.props.survivorTeams}
                  survivorWeeks={this.props.poolWeeks}
                  siguientePag={this.siguientePag}
                  queryParams={this.props.queryParamsPicksGrupo}
                  entriesInfo={this.props.entriesInfo}
                  picksGrupal={this.state.picksGrupal}
                  dataReady={this.state.mounted}
                  withRegister={this.props.withRegister}
                />
                )
              : (
                <TablaGrupos
                  entryId={this.props.selectedEntry}
                  actualPagina={this.state.actualPagina}
                  anteriorPag={this.anteriorPag}
                  dataReady={
                !!(this.state.picksGrupal !== undefined && this.state.picksGrupal !== null)
              }
                  entriesInfo={this.props.entriesInfo}
                  fixtures={this.props.fixtures}
                  fixtureProps={this.props.fixtureProps}
                  liveClosedFixture={this.props.liveClosedFixtures}
                  liveFixturesData={this.props.liveFixtureData}
                  logged={this.state.logged}
                  picksGrupal={this.state.picksGrupal}
                  poolInfo={this.props.poolInfo}
                  poolWeekSelected={this.props.poolWeekSelected}
                  nameWeekSelected={this.props.nameWeekSelected}
                  siguientePag={this.siguientePag}
                  userData={this.state.userData}
                  onChangeOrderType={this.onChangeOrderType}
                  currentOrderType={this.state.orderType}
                />
                )}
        </div>
      )
    } else {
      conditionalComponent = <Loading label='Espera un momento por favor...' />
    }

    return conditionalComponent
  }
}

export default PicksGrupo
