import React from 'react'
import { useWizardPrivate } from './wizardPrivate.store'

// Components
import { Modal } from '../../Xmerald-UI'
import Search from './shared/Search'
import Welcome from './shared/Welcome'
import CreateType from './shared/CreateType'
import Quiniela from './Quiniela'
import SurvivorPrivate from './Survivor'

export default function NewPizardPrivate () {
  const showWizard = useWizardPrivate(state => state.showWizard)
  const close = useWizardPrivate(state => state.close)

  return (
    <Modal
      onlyCloseOnCross
      show={showWizard}
      onClose={close}
      title='Quinielas Privadas'
    >
      <Welcome />
      <Search />
      <CreateType />
      <Quiniela />
      <SurvivorPrivate />
    </Modal>
  )
}
