import { useRef } from 'react'

// This hook is used for React Portals for
// Inject dymamic component in real node
// Returns the node object DOM ref
export default function useRealNode (id) {
  const node = useRef(document.getElementById(id))

  if (node && node.current) return node.current

  node.current = document.createElement('div')

  node.current.setAttribute('id', id)

  // Apending node to real DOM
  document.body.appendChild(node.current)

  return node.current
}
