import React from 'react'
import numeral from 'numeral'
import { Button, FormControl, Modal } from 'react-bootstrap/lib'

// Components
import FormCashout from '../FormCashout'
import ExtraFinancialData from './ExtraFinancialData'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import FireBaseUtils from '../../utils/FireBaseUtils'

// Assets
import flechas from '../../assets/images/flechasIconBlanco-50.png'
import retirarIcon from '../../assets/images/retirarIconBlanco.png'

/**
*This functional component renders the available cash of the user
* @param cashoutInfo
* @param balanceReal
@return FucntionalComponent
**/
const RowPossibleBalance = ({ cashoutInfo, balanceReal }) => {
  return (
    <div className='col-xs-12 text-center'>
      <div className='row possible-balance'>
        <div className='col-xs-6 retirable'>
          <strong>Saldo disponible para retiro:</strong>
          <span>{numeral(cashoutInfo.available / 100).format('$0,000.00')}</span>
        </div>
        <div className='col-xs-6 no-retirable'>
          <strong>Saldo no retirable:</strong>
          <span>{numeral(cashoutInfo.unavailable / 100).format('$0,000.00')}</span>
        </div>
      </div>
      <div className='availableBalance'>
        <span>
          El monto mínimo para retirar saldo es de{' '}
          <strong className='text-pickwin'>$100.00 MXN</strong>
        </span>
        <br />
        <span className='myBalance'>
          Tu balance{' '}
          <strong className='text-pickwin'>
            {numeral(balanceReal / 100).format('$0,000.00')} MXN
          </strong>
        </span>
      </div>
    </div>
  )
}

/**
*This functional component renders
* @param successfullRequest
* @param balanceReal
@return FucntionalComponent
**/
const CashoutInfo = ({ successfullRequest }) => {
  return (
    <div className='info-retirar'>
      <div className='titulo-user-info'>
        <img src={retirarIcon} className='title-icon' />
        <span>Retirar</span>
      </div>
      <div className='mensaje'>
        {successfullRequest
          ? (
            <div className='flex-row'>
              <div className='flex-col'>
                En breve estaremos en contacto contigo, tu transacción esta siendo procesada...
              </div>
            </div>
            )
          : (
            <span>
              Hola Pickwinero, para que sea posible retirar fondos de tu cuenta por favor facilitanos
              la siguiente información.
              <br />
              Te recordamos que para poder retirar tus fondos tendrás que haber jugado al menos el{' '}
              <strong>100%</strong> de lo que hayas depositado la última vez.
              <br />
              Los retiros sólo podrán solicitarse cada <strong>48hrs.</strong>
              <br />
              Después de solicitar tu retiro, el equipo de Pickwin analizará tu petición y te
              informaremos por correo electrónico sobre el resultado de tú petición.
              <br />
            </span>
            )}
      </div>
    </div>
  )
}

class Retirar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      responseCashoutData: null,
      matiVerificationStatus: false,
      showVerificationID: false,
      itNeedsVerification: true,
      showCashoutResponse: false,
      phone: null,
      smsSubmit: false,
      smsButtonText: 'Envíar SMS',
      smsCounter: 120,
      smsGetResult: false,
      smsInterview: null,
      cashOutVerified: this.props.userData.cashouts_verified,
      smsResultStatus: null,
      smsResultStatusClass: null,
      resultIncodeButton: false,
      showIncodeResultModal: false,
      incodeActive: null,
      // Additional financial data
      initializedFinancial: false,
      needFinancialData: false,
      financialData: null,
      financialGetError: null
    }

    this.handleCashoutResponse = () => {
      this.setState((prevState) => {
        return { showCashoutResponse: !prevState.showCashoutResponse }
      })
    }

    this.closeResponseModal = () => {
      this.setState({ showResponseModal: false })
    }

    this.matiVerificationEvent = () => {
      const receiveMessage = (event) => {
        if (event.origin !== 'https://signup.getmati.com') {
          return
        }
        const data = JSON.parse(event.data)

        if (data.action === 'mati-signup-0::exitedSdk') {
          this.handleVerification()
        }

        if (data.action === 'mati-signup-0::userFinishedSdk') {
          this.handleVerification()
        }
      }

      window.addEventListener('message', receiveMessage, false)
    }

    this.metadataVerification = () => {
      const userData = utils.getUserInfo()
      const mD = {
        userId: userData.id,
        nick: userData.nick
      }
      return JSON.stringify(mD)
    }

    this.handleVerification = () => {
      this.setState({ showVerificationID: !this.state.showVerificationID })
    }

    this.handlePhone = (e) => {
      this.setState({ phone: e.target.value })
    }

    this.sendSms = () => {
      this.setState({ smsSubmit: true })

      const phone = (this.props.userData.phone === '' || this.props.userData.phone === null) ? this.state.phone : this.props.userData.phone
      if (phone !== '' || phone !== null) {
        const onBoarding = API.getIncodeOnBoarding()

        const confId = process.env.REACT_APP_INCODE_CONF

        onBoarding.createSession('MX', null, { configurationId: confId }).then((token) => {
          const interviewSession = token
          // save session in pickwin
          const params = { type: 'cashout', session: interviewSession }
          API.incodeSaveSession(params).then((response) => {
            if (response.data.success) {
              const params = { phone, session: interviewSession }

              API.sendIncodeSms(params).then((response) => {
                if (response.data.res.success) {
                  // show result button for user validation in 60 seconds
                  this.setState({ smsInterview: interviewSession })
                  const interval = setInterval(() => {
                    this.setState({ smsCounter: this.state.smsCounter - 1 }, () => {
                      this.setState({ smsButtonText: `Esperando la validación vía SMS: ${this.state.smsCounter} seg.` })
                      if (this.state.smsCounter === 0) {
                        this.setState({ smsGetResult: true })
                        clearInterval(interval)
                      }
                    })
                  }, 1000)
                } else {
                  console.log('Error send sms')
                }
              }).catch(error => {
                console.log(error)
              })
            } else {
              console.log('Error to save session')
            }
          })
        })
      } else {
        alert('Debes ingresar un número de teléfono')
      }
    }

    this.getIncodeResults = () => {
      this.setState({ resultIncodeButton: true })
      API.getIncodeResults(this.state.smsInterview).then((response) => {
        switch (response.data.result) {
          case null:
            this.setState({ smsResultStatus: 'No se pudo realizar la verificación, intentalo de nuevo', smsResultStatusClass: 'failed' })
            break
          case 'OK':
            this.setState({ smsResultStatus: 'Tu verificación ha sido exitosa' })
            break
          case 'MANUAL':
          case 'WARN':
            this.setState({ smsResultStatus: 'No pudimos verificar tus documentos, el equipo de pickwin revisará tu caso. para cualquier aclaración comunicate a soporte@pickwin.net', smsResultStatusClass: 'failed' })
            break
          case 'FAIL':
            this.setState({ smsResultStatus: 'No se pudo verificar tu cuenta, por favor comunicate a soporte@pickwin.net o intentalo de nuevo', smsResultStatusClass: 'failed' })
            break
          case 'UNKNOWN':
          default:
            this.setState({ smsResultStatus: 'No pudimos verificar tu identidad, intentalo nuevamente', smsResultStatusClass: 'failed' })
            break
        }

        this.setState({ smsSubmit: false, resultIncodeButton: false, smsGetResult: false, smsButtonText: 'Envíar SMS', smsCounter: 120 })

        if (response.data.result === 'OK') {
          this.setState({ cashOutVerified: 4 })
        }

        this.showIncodeResultModal(true)
      })
    }

    this.showIncodeResultModal = (value) => {
      this.setState({ showIncodeResultModal: value })
    }
  }

  componentDidMount () {
    // add incode onBoarding sdk
    const fileref = document.createElement('script')
    fileref.setAttribute('type', 'text/javascript')
    fileref.setAttribute('src', 'https://sdk.incode.com/sdk/onBoarding-1.48.0.js')
    document.getElementsByTagName('head')[0].appendChild(fileref)

    FireBaseUtils.init().then(() => {
      const remoteConfig = FireBaseUtils.data()
      this.setState({ incodeActive: remoteConfig.data.incode_enabled })
    })

    this.getFinancialData()
  }

  getFinancialData = async () => {
    try {
      const res = await API.extraFinancialData()
      if (res.data.userData) {
        this.setState({
          needFinancialData: this.getIsNeedFinancialData(res.data.userData),
          initializedFinancial: true,
          financialData: res.data.userData
        })
      } else {
        this.setState({ financialGetError: true })
      }
    } catch (error) {
      console.error(error)
      this.setState({
        financialGetError: true
      })
    }
  }

  getIsNeedFinancialData = (userData) => {
    const { rfc, curp, address, financial_activity } = userData

    if (
      (!rfc || (rfc && rfc.value.length < 13)) ||
      (!curp || (curp && curp.value.length < 18)) ||
      (!address || (address && address.value.length < 5)) ||
      (!financial_activity || (financial_activity && financial_activity.value.length < 5))
    ) {
      return true
    }
  }

  successFinancialConfirmation = async () => {
    this.setState({
      financialData: null,
      needFinancialData: false,
      initializedFinancial: true
    })
  }

  render () {
    let conditionalComponent
    const isValid = !this.props.documentos
      ? null
      : this.props.documentos.find((document) => document.valid)

    const { availableCashout, userBalance } = this.props
    const { initializedFinancial, needFinancialData, financialData } = this.state

    conditionalComponent = (
      <div className='user-categories-container col-xs-12 col-md-9  '>
        <Modal
          show={this.state.showIncodeResultModal}
          className=''
          dialogClassName='modal-dialog-xxxs'
          backdropClassName=''
          style={{ marginTop: '30vh', zIndex: '1070' }}
        >
          <>
            <div id='panelTitle' className={`panel-abonar-titulo ${this.state.smsResultStatusClass}`}>
              <span>Resultados de verficación de identidad</span>
              <button
                className='cerrar-panel'
                onClick={() => { this.showIncodeResultModal(false) }}
              >
                <div className='btn-icon-close'>
                  <i className='ion-ios-close-empty' />
                </div>
              </button>
            </div>
            <div className='panel-pickwin' style={{ marginBottom: '15px' }}>
              <div className='flex-col col-80 col-offset-10 col-no-padding text-center transaction-success'>
                <div className='flex-row text-center'>
                  <strong className='font18'>{this.state.smsResultStatus}</strong>
                </div>
              </div>
            </div>
          </>
        </Modal>

        <div className='category-user retirar row'>
          <CashoutInfo successfullRequest={this.state.successfullRequest} />
          <RowPossibleBalance
            cashoutInfo={availableCashout}
            balanceReal={userBalance.balanceReal}
          />

          {availableCashout.available < 10000 && (
            <div className=' col-xs-8 col-xs-offset-2 notEnoughMessage flex-container column'>
              <i className='ion-sad' />
              <span className='message'>
                {' '}
                Lo sentimos pero no puedes hacer un retiro porque <br />
                tú <strong>saldo disponible para retiro </strong> es menor a $100.00 MXN
              </span>
              <span className='text-container'>
                <i className='ion-paper-airplane' /> Si tienes alguna duda o comentario contactános
                en :
              </span>
              <span className='text-container'>
                <strong>
                  <a href='mailto:soporte@pickwin.net'>soporte@pickwin.net</a>
                </strong>
              </span>
            </div>
          )}

          {this.state.cashOutVerified !== 4 && this.state.incodeActive === true && (
            <div className='flex-col col-no-padding col-80 col-offset-10'>
              <div>
                <p>Para poder continuar necesitamos confirmar tu identidad mediante un mensaje SMS</p>
                <p>Visita el sitio web que se te envie por sms, sigue los pasos de validación en tu celular</p>
                <p>Una vez que hayas terminado y consulta tus resultados cuando el contador termine.</p>
                <p>Por favor ten a la mano:</p>
                <ul>
                  <li>Identificación oficial vigente (INE o Pasaporte)</li>
                </ul>

              </div>
              {this.props.userData.phone === '' || this.props.userData.phone === null
                ? (
                  <div>
                    <FormControl
                      onChange={(e) => { this.handlePhone(e) }}
                      placeholder='Numero de teléfono'
                      type='text'
                      value={this.state.phone}
                      style={{ marginTop: '1rem' }}
                    />
                  </div>
                  )
                : ''}
              {this.state.smsGetResult === false && (
                <Button
                  onClick={() => { this.sendSms() }}
                  type='button'
                  disabled={this.state.smsSubmit}
                  className='btn-form btn-pick flex-container align justify'
                >
                  {this.state.smsButtonText}
                </Button>
              ) || (
                <Button
                  onClick={() => { this.getIncodeResults() }}
                  type='button'
                  disabled={this.state.resultIncodeButton}
                  className='btn-form btn-pick flex-container align justify'
                >
                  Obtener resultados de validación
                </Button>
              )}
            </div>
          )}

          {/* If user needs to update financial data before */}
          {this.state.cashOutVerified === 4 && availableCashout.available >= 10000 && initializedFinancial && needFinancialData && (
            <ExtraFinancialData
              financialData={financialData}
              onSuccess={this.successFinancialConfirmation}
            />
          )}

          {/* If all is ok to request balance */}
          {availableCashout.available >= 10000 && initializedFinancial && !needFinancialData && (
            <div className='form-wrapper'>
              <div className=' flex-row row-center subtitulo-user-info'>
                <img src={flechas} className='title-icon' />
                <span>Validación de datos</span>
              </div>

              {this.state.incodeActive === false && (
                <FormCashout
                  validDoc={!!isValid}
                  documentos={this.propsdocumentos}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  availableForCashout={this.props.availableCashout}
                  incodeActive={this.state.incodeActive}
                />
              )}

              {this.state.cashOutVerified === 4 && this.state.incodeActive === true && (
                <FormCashout
                  validDoc={!!isValid}
                  documentos={this.props.documentos}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  availableForCashout={this.props.availableCashout}
                  incodeActive={this.state.incodeActive}
                />
              )}

         
            </div>
          )}
        </div>
      </div>
    )
    return conditionalComponent
  }
}

export default Retirar
