import React from 'react'
import { Modal } from 'react-bootstrap/lib'

// Components
import Button from '../Form/Button'
import Logos from './Logos'

export default function DepositResponse (props) {
  const {
    showDepositResponse,
    closeDepositResponse,
    imageUrl,
    pdfUrl,
    error,
    nick,
    other_amount,
    convertToPesos,
    amount
  } = props

  return (
    <Modal
      show={showDepositResponse}
      className='responseDepositModal absoluteModal'
      dialogClassName='responseDepositDialog'
      backdropClassName='responseDepositBackdrop'
    >
      <div className='transactionPanel'>
        <div id='panelTitle' className={`panel-abonar-titulo ${error ? 'failed' : ''}`}>
          {error
            ? (
              <span>Tenemos un problema.</span>
              )
            : (
              <span>!Tú ficha de depósito se creó con exito!</span>
              )}
          <button className='cerrar-panel' onClick={closeDepositResponse}>
            <div className='btn-icon-close'>
              <i className='ion-ios-close-empty' />
            </div>
          </button>
        </div>
        <div className='toditoPanelResponse'>
          {!error
            ? (
              <span className='icon-success flex-container  justify column align'>
                <i className='ion-checkmark-circled' />
                <strong> ¡Tu ficha de depósito se creo con éxito!</strong>
              </span>
              )
            : (
              <span className='icon-failed flex-container  justify column align'>
                <i className='ion-close-circled' />
                <span>{nick} tenemos un problema </span>
              </span>
              )}
          <div className='flex-col col-80 col-offset-10 col-no-padding text-center transaction-success'>
            {!error
              ? (
                <>
                  <div className='flex-row row-center row-no-padding'>
                    <div className='flex-col col-no-padding'>
                      <div className='flex-row row-center row-no-padding'>
                        <span className='flex-col col-center text-center'>
                          Descarga el PDF y sigue las instrucciones para realizar la transferencia
                          SPEI:
                        </span>
                      </div>
                      <div className='flex-row '>
                        <strong className='flex-col col-center text-center'>
                          {' '}
                          Código de la ficha {convertToPesos(amount, other_amount)} <small>MXN</small>
                        </strong>
                      </div>
                      <div className='flex-row '>
                        <img
                          className='flex-col col-center img-responsive code-deposit'
                          src={imageUrl}
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex-container justify align '>
                    <small> Ó si prefieres... </small>
                  </div>
                  <Logos />
                  <a href={pdfUrl} target='_blank' className='flex-row row-center' rel='noopener noreferrer'>
                    <Button
                      type='submit'
                      bgColor='#88ba44'
                      style={{
                        maxWidth: 350,
                        margin: '1em auto'
                      }}
                    >
                      <span className=''>
                        <div>
                          <i className='ion-android-download' />
                          {' Descarga tú ficha de depósito '}
                        </div>
                      </span>
                    </Button>
                  </a>
                </>
                )
              : (
                <div className='flex-container column text-center align justify'>
                  <strong>Error: {error.code} </strong>
                  <span style={{ marginBottom: '15px' }}>
                    {error.message ? error.message : error.length > 1 ? error : 'Algo no anda bien'}
                  </span>
                </div>
                )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
