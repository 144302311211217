import utils from './utils';
import branch from 'branch-sdk';

// const fallback = 'https://beta.pickwin.mx';
// let fallback            = process.env.BRANCH_URL;
let fallback =  process.env.REACT_APP_BRANCH_URL;
let branchKey = process.env.REACT_APP_BRANCH_KEY;

// const buildProduction = process.env.NODE_ENV === 'production';

// if (buildProduction) {
//   fallback = process.env.REACT_APP_BRANCH_URL;
//   branchKey = process.env.REACT_APP_BRANCH_KEY;
// }

// const fallback = 'http://pickwin.test';

// console.log(branchKey);
const branchUtils = {
  initBranchUser: () => {
    let userData = utils.getUserInfo();
    // console.log( 'initUser Branch', userData );
    if (!userData) {
      return;
    }

    branch.setIdentity(String(userData.id), (err, data) => {
      // console.log(err);
      // console.log(data);
    });
  },
  openWhatsAppHelp: () => {
    // console.log('Help Me with whats app!');
    window.open('https://wa.me/5215511866442', 'Ayuda');
  },
  redirectToWhatsApp: (link, type, poolId) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    // console.log(navigator.userAgent);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    // console.log('redirectTOWA', link, type, device);
    let text = window.encodeURIComponent(`¡Hola! Te invito a jugar en Pickwin ${link}`);
    /**
     *  Special condition
     * */
    if (poolId === 12408) {
      let title = 'YO ESTOY JUGANDO LA QUINIELA DEL MUNDIAL';
      let description = 'TE INVITO A JUGAR LA MEJOR QUINIELA DEL MUNDIAL CON BOLSA DE $3,000,000';

      text = window.encodeURIComponent(`${title}  ${description} ${link}`);
    }

    // console.log('text', text);
    if (type === 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  /**
   * This function redirects to accept the challenge invitation
   */
  redirectChallengeToWhatsApp: (link, type, pool) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    // console.log(link, type, pool);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    // console.log('redirectTOWA', link, type, device);
    let text = window.encodeURIComponent(
      `¿Ya viste esta quiniela? Yo estoy jugando, éntrale ¡Tiene buena bolsa! ${link}`
    );

    // let text = window.encodeURIComponent(
    //   `Acepta el reto y juega la quiniela ${pool.name_replaced} ${link}`
    // );

    // console.log('text', text);
    if (type === 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  redirectReminderToWhatsApp: (link, type, copyType) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    // console.log(link, type, copyType);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    // console.log('redirectTOWA', link, type, device);

    let text = window.encodeURIComponent(` ${copyType} ${link}`);

    // console.log('text', text);
    if (type === 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  /**
   * This function redirects to accept the challenge invitation
   */
  redirectNewReferrerToWhatsApp: (link, type) => {
    /**
     * Check type of device
     * @type {string}
     */
    const uagent = navigator.userAgent.toLowerCase();
    let device = null;
    // console.log('Redirect New Referer ', link);
    if (uagent.search('iphone') > -1) {
      device = 'apple';
    } else if (uagent.search('ipad') > -1) {
      device = 'apple';
    } else if (uagent.search('ipod') > -1) {
      device = 'apple';
    } else if (uagent.search('ndroid') > -1) {
      device = 'android';
    }
    // console.log('redirectTOWA', link, type, device);
    let text = window.encodeURIComponent(`Te invito a jugar en pickwin, regístrate aquí: ${link}`);
    // debugger

    // console.log('text', text);
    if (type === 'open') {
      window.open(`https://api.whatsapp.com/send?text=${text}`);
    } else {
      if (device === 'apple') {
        window.location = `whatsapp:/send?text=${text}`;
      } else {
        window.location = `https://api.whatsapp.com/send?text=${text}`;
      }
    }
  },
  initBranch: () => {
    // console.info('init branch', branch );
    return new Promise((resolve, reject) => {
      if (!branch) {
        reject({ success: false, error: new Error('Branch doesnt exist') });
      } else {
        // setTimeout(() => {
        //   reject({ success: false, error: new Error('Timeout exceeded') });
        // }, 2000);

        branch.init(
          branchKey,
          (err, branchData) => {
            if (err) {
              // console.error( err );
              reject(err);
            }
            let keys = null;
            if (!branchData || !branchData.data || branchData.data === '') {
              return resolve(`branchData don't containis data`)
            }
            if (branchData.hasOwnProperty('data_parsed')) {
              keys = Object.keys(branchData.data_parsed);
            }

            if (branchData.hasOwnProperty('data_parsed') && keys !== null && keys.length) {
              let data = branchData.data_parsed;
              // console.log('branchData', branchData );

              let userMetadata = {};

              if (data.hasOwnProperty('~channel')) {
                userMetadata['utm_source'] = data['~channel'];
              }
              if (data.hasOwnProperty('~campaign')) {
                userMetadata['utm_campaign'] = data['~campaign'];
              }

              for ( const key in data ) {
                userMetadata[key] = data[key];
              }
              window.localStorage.setItem('userMetadata', JSON.stringify(userMetadata));

              if (!utils.isLogged()) {
                let redirObj;
                if (data.hasOwnProperty('poolId')) {
                  redirObj = { id: data.poolId, game_type: 'pool' };
                } else if (data.hasOwnProperty('bracketId')) {
                  redirObj = { id: data.bracketId, game_type: 'bracket' };
                }
                utils.saveDataNotLoggedUser(redirObj);
              }

              if (!data.hasOwnProperty('$desktop_url')) {
                // console.log('%c Window Location Redirect', 'color: #2e8ccf; font-size:2em; ')
                // console.log(data);

                if (data.hasOwnProperty('poolId') && !data.hasOwnProperty('referer')) {
                  window.location = '/lobby/quinielaPop/quiniela-' + data.poolId + '/' + data.poolId;
                } else if (data.hasOwnProperty('poolId') && data.hasOwnProperty('referer')) {
                  window.location =
                    '/lobby/quinielaPop/quiniela-' +
                    data.poolId +
                    '/' +
                    data.poolId +
                    '?referer=' +
                    data.referer;
                } else if (data.hasOwnProperty('bracketId') && data.hasOwnProperty('referer')) {
                  /**
                   * Bracket ID
                   */
                  window.location = '/lobby/bracketPop/bracket-' + data.bracketId + '/' + data.bracketId;
                } else if (data.hasOwnProperty('bracketId') && data.hasOwnProperty('referer')) {
                  window.location =
                    '/lobby/bracketPop/bracket-' +
                    data.bracketId +
                    '/' +
                    data.bracketId +
                    '?referer=' +
                    data.referer;
                } else if (data.hasOwnProperty('survivorId') && !data.hasOwnProperty('referer')) {
                  /**
                   * GroupPool
                   */
                  window.location =
                    '/lobby/survivorPop/survivor-' + data.survivorId + '/' + data.survivorId;
                } else if (data.hasOwnProperty('survivorId') && data.hasOwnProperty('referer')) {
                  window.location =
                    '/lobby/survivorPop/survivor-' +
                    data.survivorId +
                    '/' +
                    data.survivorId +
                    '?referer=' +
                    data.referer;
                } else if (data.hasOwnProperty('groupPoolId') && !data.hasOwnProperty('referer')) {
                  /**
                   * GroupPool
                   */
                  window.location =
                    '/lobby/quinielaGrupoPop/quiniela-' + data.groupPoolId + '/' + data.groupPoolId;
                } else if (data.hasOwnProperty('groupPoolId') && data.hasOwnProperty('referer')) {
                  window.location =
                    '/lobby/quinielaGrupoPop/quiniela-' +
                    data.groupPoolId +
                    '/' +
                    data.groupPoolId +
                    '?referer=' +
                    data.referer;
                } else if (data.hasOwnProperty('$canonical_identifier')) {
                  window.location = '/registrate?referer=' + data.referer;
                }
              }
            }
            resolve({ success: true });
          }
        );
      }
    });
  },
  /**
   * @param { Object } game Ex. game.pool || game.bracket
   * @return {Promise}
   **/
  createLink: (userData, game, gameType, isPrivate) => {
    // console.log('%c Create Link', 'font-size:2em;');

    let title, description, promise, linkData;
    let available = game.available / 100;
    let formatted = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(available);

    let utm_source = 'Referido';
    let utm_campaign = isPrivate ? 'Quiniela Privada' : 'Quiniela Publica';
    let desktopUrl = '';

    let imageUrl = 'https://www.pickwin.mx/favicons/favicon-194x194.png';

    if (gameType === 'pool') {
      desktopUrl = `${fallback}/lobby/quinielaPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;
      title = 'QUINIELA ' + game.name_replaced;
      description = '¡Hola! Te invito a jugar la quiniela ' + game.name_replaced;
      if (game.guaranteed_prize) {
        description += '; Con bolsa garantizada de ' + formatted;
      }
      if (game.id === 12408) {
        title = 'YO ESTOY JUGANDO LA QUINIELA DEL MUNDIAL';
        description = 'TE INVITO A JUGAR LA MEJOR QUINIELA DEL MUNDIAL CON BOLSA DE $3,000,000';
        imageUrl = 'https://storage.googleapis.com/pw-resources/promos/covermundialfb.jpg';
        utm_campaign = 'RUSIA2018';
      }

      linkData = {
        // $fallback_url: fallback+'/lobby/quinielaPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        poolId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    } else if (gameType === 'bracket') {
      desktopUrl = `${fallback}/lobby/bracketPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;
      title = 'BRACKET ' + game.name_replaced;
      description = '¡Hola! Te invito a jugar el bracket ' + game.name_replaced;
      if (game.guaranteed_prize) {
        description += '; Con bolsa garantizada de ' + formatted;
      }

      linkData = {
        // $fallback_url: fallback+'/bracketPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        bracketId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    } else if (gameType === 'survivor') {
      // console.log('Branch Survivor');
      desktopUrl = `${fallback}/lobby/survivorPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;
      title = 'SURVIVOR ' + game.name_replaced;
      description = '¡Hola! Te invito a jugar el survivor ' + game.name_replaced;
      if (game.guaranteed_prize) {
        description += '; Con bolsa garantizada de ' + formatted;
      }

      linkData = {
        // $fallback_url: fallback+'/survivorPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url:
          fallback +
          '/lobby/survivorPop/' +
          game.slug +
          '/' +
          game.id +
          '?referer=' +
          userData.referalCode +
          '&u=' +
          userData.nick,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        survivorId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    } else if (gameType === 'group_pool') {
      // console.log('Branch Pool Group');
      desktopUrl = `${fallback}/lobby/quinielaGrupoPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;

      title = 'QUINIELA ' + game.name_replaced;
      description = '¡Hola! Te invito a jugar la quiniela ' + game.name_replaced;
      if (game.guaranteed_prize) {
        description += '; Con bolsa garantizada de ' + formatted;
      }

      linkData = {
        // $fallback_url: fallback+'/survivorPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        groupPoolId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    }
    // console.log(linkData);
    promise = new Promise((resolve, reject) => {
      branch.link(
        {
          campaign: utm_campaign,
          channel: utm_source,
          data: linkData,
        },
        (error, url) => {
          if (error) {
            resolve(desktopUrl);
          } else {
            resolve(url);
          }
        }
      );
    });

    return promise;
  },
  /**
   * @param { Object } game Ex. game.pool || game.bracket
   * @return {Promise}
   **/
  createLinkChallenge: (userData, game, gameType, isPrivate) => {
    // console.log('%c Challange Link!', 'font-size:2em;');
    // console.log('game', game, gameType);

    let title, description, promise, linkData;
    let available = game.available / 100;
    let formatted = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(available);
    let desktopUrl = '';
    let utm_source = 'Referido';
    let utm_campaign = isPrivate ? 'Quiniela Privada' : 'Quiniela Publica';
    let imageUrl = 'https://storage.googleapis.com/pw-resources/referrals/referido-reto-001.jpg';

    if (gameType === 'pool') {
      desktopUrl = `${fallback}/lobby/quinielaPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;

      title = 'Te retaron a jugar la quiniela ' + game.name_replaced;
      description = `
      ${userData.nick} te retó a jugar la quiniela
      ${game.name_replaced} con la bolsa de
      ${formatted}`;

      linkData = {
        // $fallback_url: fallback+'/lobby/quinielaPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        poolId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    } else if (gameType === 'group_pool') {
      desktopUrl = `${fallback}/lobby/quinielaGrupoPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;
      title = 'Te retaron a jugar la quiniela ' + game.name_replaced;
      description = `
      ${userData.nick} te retó a jugar la quiniela
      ${game.name_replaced} con la bolsa de
      ${formatted}`;

      linkData = {
        // $fallback_url: fallback+'/lobby/quinielaPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        poolId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    } else if (gameType === 'bracket') {
      desktopUrl = `${fallback}/lobby/bracketPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;
      title = 'Te retaron a jugar el bracket ' + game.name_replaced;
      description = `
      ${userData.nick} te retó a jugar el bracket
      ${game.name_replaced} con la bolsa
      ${game.guaranteed_prize ? 'garantizada' : ''} de
      ${formatted}`;

      linkData = {
        // $fallback_url: fallback+'/bracketPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        bracketId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    } else if (gameType === 'survivor') {
      desktopUrl = `${fallback}/lobby/survivorPop/${game.slug}/${game.id}?referer=${userData.referalCode}&u=${userData.nick}&utm_source=${utm_source}&utm_campaign=${utm_campaign}&u=${userData.nick}`;
      title = 'Te retaron a jugar el survivor ' + game.name_replaced;
      description = `
      ${userData.nick} te retó a jugar el survivor
      ${game.name_replaced} con la bolsa
      ${game.guaranteed_prize ? 'garantizada' : ''} de
      ${formatted}`;

      linkData = {
        // $fallback_url: fallback+'/survivorPop/'+game.slug+'/'+game.id+'?referer='+userData.referalCode+'&u='+userData.nick,
        $desktop_url: desktopUrl,
        $og_title: title,
        $og_description: description,
        $og_image_url: imageUrl,
        survivorId: game.id,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        referer: userData.referalCode,
      };
    }

    promise = new Promise((resolve, reject) => {
      // console.log('branch promise');
      branch.link(
        {
          campaign: utm_campaign,
          channel: utm_source,
          data: linkData,
        },
        (error, url) => {
          // console.log(error, url);
          if (error) {
            reject(error);
          } else {
            resolve(url);
          }
        }
      );
    });

    return promise;
  },
  createLinkInviteReferrer: (userData) => {
    // console.log('Invite Referrer');

    let title, description, promise, linkData;

    let utm_source = 'Referido';
    let utm_campaign = 'Programa Referidos';

    let imageUrl =
      'https://storage.googleapis.com/pw-resources/referrals/referido-invitacion-001.png';

    title = 'PICKWIN';
    description =
      '¡Hola! Juega en la única plataforma de quinielas deportivas. Quinielas de todos los deportes, todos los días.';

    linkData = {
      // $fallback_url: fallback+'/registrate?referer='+userData.referalCode+'&u='+userData.nick,
      $desktop_url:
        fallback +
        '/registrate?referer=' +
        userData.referalCode +
        '&u=' +
        userData.nick +
        '&utm_source=' +
        utm_source +
        '&utm_campaign=' +
        utm_campaign +
        '&u=' +
        userData.nick,
      $og_title: title,
      $og_description: description,
      $og_image_url: imageUrl,
      referer: userData.referalCode,
      utm_source: utm_source,
      utm_campaign: utm_campaign,
      section: 'register',
    };
    promise = new Promise((resolve, reject) => {
      console.log('branch promise');
      branch.link(
        {
          campaign: utm_source,
          channel: utm_campaign,
          data: linkData,
        },
        (error, url) => {
          console.log(error, url);
          if (error) {
            reject(error);
          } else {
            resolve(url);
          }
        }
      );
    });

    return promise;
  },
  createLinkReminder: (userData, type) => {
    console.log('Create link Reminder Referrer');
    console.log(userData);
    console.log(type);

    let title, description, promise, linkData;

    let utm_source = 'Referido';
    let utm_campaign = 'Programa Referidos';

    let imageUrl =
      'https://storage.googleapis.com/pw-resources/referrals/referido-invitacion-001.png';

    title = 'PICKWIN';
    if (type === 'bono') {
      description =
        'Te recuerdo que tu primer depósito se duplica hasta $300 pesos! Aprovecha y empieza a jugar…';
    } else if (type === 'firstQuina') {
      description = 'No te he visto en las quinielas, ¿le vas a entrar a las de esta semana?';
    }

    linkData = {
      // $fallback_url: fallback+'/registrate?referer='+userData.referalCode+'&u='+userData.nick,
      $desktop_url:
        fallback +
        '/registrate?referer=' +
        userData.referalCode +
        '&u=' +
        userData.nick +
        '&utm_source=' +
        utm_source +
        '&utm_campaign=' +
        utm_campaign +
        '&u=' +
        userData.nick,
      $og_title: title,
      $og_description: description,
      $og_image_url: imageUrl,
      referer: userData.referalCode,
      section: 'register',
    };
    promise = new Promise((resolve, reject) => {
      console.log('branch promise');
      branch.link(
        {
          campaign: utm_source,
          channel: utm_campaign,
          data: linkData,
        },
        (error, url) => {
          console.log(error, url);
          if (error) {
            reject(error);
          } else {
            resolve(url);
          }
        }
      );
    });

    return promise;
  },

  /**
   * Sets the identity on branch
   * @param {int} userId
   */
  setUserId: (userId) => {
    branch.setIdentity(String(userId), (err, data) => {
      // console.log(err);
      // console.log(data);
    });
  },

  /**
   * Wrapper for branch.logEvent
   * @param {string} eventName
   * @param {object} data
   */
  sendEvent: (eventName, data) => {
    // console.log('Event Branch', eventName, data);
    branch.logEvent(eventName, data, (err, data) => {
      // console.log(err);
      // console.log(data);
    });
  },
};

export default branchUtils;
