import React, { useEffect, useState } from 'react';
import avatar from '../../assets/images/avatar-50.png';

const SurvivorContestPick = ({
  pick,
  currentContest,
  contest,
  players,
  eliminationContestId,
  alive,
  togglePlayerList,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerList, setPlayerList] = useState(players);

  useEffect(() => {
    if (pick !== undefined && pick.pick) {
      let player = playerList.find((player) => player.id === pick.pick);
      //let playerIdx = playerList.findIndex((player) => player.id === pick.pick);
      setSelectedPlayer(() => player);
    }
    // terrible way...
  }, [JSON.stringify(pick)]);

  useEffect(() => {
    setPlayerList(() => {
      return players;
    });

    if (pick !== undefined && pick.pick) {
      let player = playerList.find((player) => player.id === pick.pick);
      setSelectedPlayer(() => player);
    }
  }, [players]);

  const onRowClicked = () => {
    if (contest.status === 'upcoming') {
      togglePlayerList();
    }
  };

  let pickClass = null;
  if (pick) {
    if (contest.status !== 'upcoming' && pick.correct) {
      pickClass = 'correct';
    }

    if (contest.status !== 'upcoming' && !pick.correct) {
      // && ( !pick.strike || eliminationContestId === contest.id ) ) {
      pickClass = 'incorrect';
    }

    if (!pick.correct && pick.strike && contest.id !== eliminationContestId) {
      pickClass = 'strike';
    }
  }

  return (
    <div className="survivor-contest-pick">
      {selectedPlayer === null ? (
        alive && contest.id === currentContest.id && contest.status === 'upcoming' ? (
          <div
            style={{ padding: '4px', display: 'flex', alignItems: 'center', minHeight: '47px' }}
            onClick={() => onRowClicked()}
          >
            <div className="flex-col">ELIGE JUGADOR</div>
          </div>
        ) : null
      ) : (
          <React.Fragment>
            {contest.id === currentContest.id ? (
              <div
                className={pickClass}
                style={{ padding: '4px', display: 'flex', alignItems: 'center', minHeight: '47px' }}
                onClick={() => onRowClicked()}
              >
                <div className="flex-col col-20 col-no-padding">
                  <img
                    className="player-avatar"
                    src={
                      selectedPlayer.avatar === null ? avatar : selectedPlayer.avatar
                    }
                  />
                </div>
                <div className="flex-col col-no-padding">
                  <div className="uppercase">
                    <i className={`flag ${selectedPlayer.logo}`}></i>
                    {selectedPlayer.name}
                  </div>
                  {contest.status !== 'upcoming' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="flex-col col-no-padding">
                        <strong>POS:</strong>{' '}
                        {selectedPlayer.position === null || selectedPlayer.positon === 'null'
                          ? '-'
                          : selectedPlayer.position + 'º'}
                      </div>
                      <div className="flex-col col-no-padding">
                        <strong>SC:</strong> {selectedPlayer.score}
                      </div>
                      <div className="flex-col col-no-padding">
                        <strong>H:</strong> {selectedPlayer.data.hole}
                      </div>
                      <div className="flex-col col-no-padding">
                        <strong>HOY:</strong> {selectedPlayer.data.today}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
                <div
                  className={pickClass}
                  style={{ padding: '4px', display: 'flex', alignItems: 'center' }}
                >
                  <div className="flex-col col-20 col-no-padding">
                    <img
                      className="player-avatar"
                      src={pick.p.avatar === null ? avatar : pick.p.avatar}
                    />
                  </div>
                  <div className="flex-col col-no-padding">
                    <div className="uppercase">
                      <i className={`flag ${pick.p.logo}`}></i>
                      {pick.p.name} - [{pick.p.id}]
                </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="flex-col col-no-padding">
                        {pick.pr.position === null || pick.pr.positon === 'null'
                          ? '-'
                          : pick.pr.position + 'º'}
                      </div>
                      <div className="flex-col col-no-padding">{pick.pr.score}</div>
                    </div>
                  </div>
                </div>
              )}
          </React.Fragment>
        )}
    </div>
  );
};

export default SurvivorContestPick;
