import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import API from '../../utils/api'


export default function PurchaseBanner () {
  const [banner, setBanner] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    getBanners()
  }, [])

  const getBanners = async () => {
    setLoading(true)
    try {
      const { data } = await API.getPurchaseBanner()

      if (data && data.banner) {
        setBanner(data.banner)
      }

    } catch (error) {
      setError(error)
    }

    setLoading(false)
  }

  if (!banner) return null

  return (
    <a href={banner.url} target={banner.target}>
      <Banner
        src={banner.img}
        alt='banner'
      />
    </a>
  )
}

const Banner = styled.img`
  width: 100%;
  margin-bottom: 1em;
`
