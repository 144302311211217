import React from 'react';
import { Modal } from 'react-bootstrap/lib/';
import MediaQuery from 'react-responsive';
import Score from '../Score';
import ScoreMobile from '../ScoreMobile';
import DisplaySport from '../../utils/quinielasComponents';
import QuinielaInfo from '../QuinielaInfo';
import BracketPopUp from '../BracketPopUp';
import SurvivorPopup from '../SurvivorPopup';
import QuinielaGrupoPopUp from '../QuinielaGrupoPopUp';
import { QuinielaGroupsContextProvider } from '../../context/QuinielaGroupsContext';
import { UserDataContextConsumer } from '../../context/UserDataContext';

import { Redirect } from 'react-router-dom';
import Bracket from '../Bracket/Bracket';
import BracketPerfect from '../BracketPerfect/BracketPerfect';
import BracketTennisLobby from '../BracketTennisLobby/BracketTennisLobby';
import BracketPoints from '../BracketPoints/BracketPoints';

class LobbyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showModal: false,
      showPanel: false,
    };
    this.close = this.close.bind(this);
  }

  close() {
    console.log('close Modal');
    this.setState({
      show: false,
      showModal: false,
    });
  }

  open() {
    this.setState({
      show: true,
      showModal: true,
    });
  }

  render() {
    let conditionalComponent = null;

    let withRegister =
      !this.props.showModal ||
        !this.props.items ||
        !this.props.items[0] ||
        !this.props.items[0].hasOwnProperty('first_entry') ||
        !this.props.items[0].first_entry
        ? false
        : true;

    if (withRegister) {
      console.log('con registro');
      console.log(this.props.items[0]);
      let gameType = null;

      if (this.props.items[0].game_type === 'group_pool') {
        gameType = 'quinielaGrupo';
      } else if (this.props.items[0].game_type === 'pool') {
        gameType = 'quiniela';
      } else if (this.props.items[0].game_type === 'bracket') {
        let game = this.props.items[0];
        switch (game.type) {
          case 'points':
            gameType = 'bracketPoints';
            break;
          case 'perfect':
            gameType = 'bracketPerfect';
            break;
          default:
            gameType = 'bracket';
        }
      } else if (this.props.items[0].game_type === 'survivor') {
        gameType = 'survivor';
      }

      return (
        <Redirect
          push
          to={{
            pathname: `/${gameType}/${this.props.items[0].slug}/${this.props.items[0].id}/${this.props.items[0].first_entry.id}`,
          }}
        />
      );
    } else {
      const isReady =
        !this.props.showModal || !this.props.items || !this.props.items[0] ? false : true;

      const isPool = this.props.quinaID;
      const isPoolGroup = this.props.quinaGrupoID;
      const isBracket = this.props.bracketID;
      const isSurvivor = !this.props.bracketID && !this.props.quinaID && !this.props.quinaGrupoID;
      let bracketInfo = null;
      if (this.props.items && this.props.items[0] && this.props.items[0].hasOwnProperty("bracket")) {
        bracketInfo = this.props.items[0].bracket;
      } else if (this.props.items) {
        bracketInfo = this.props.items[0];
      }
      const sportId = bracketInfo && bracketInfo.sport_id;
      const bracketType = bracketInfo && bracketInfo.type;

      conditionalComponent = (
        <React.Fragment>
          <UserDataContextConsumer>
            {({ isLogged, userData, userBalance, updateUserInfo, updateUserBalance }) => {
              {
                /* console.log( userBalance ); */
              }
              return (
                <Modal
                  bsSize="lg"
                  backdropClassName="quinielaPopUpBackdrop"
                  dialogClassName="quinielasPopUpModal"
                  onHide={this.props.updateStateProp}
                  show={isReady && this.props.showModal}
                >
                  <div className="modal-body">
                    <MediaQuery query="(max-width: 659px)">
                      <ScoreMobile
                        available={this.props.available}
                        close={this.props.updateStateProp}
                        displaySportFunc={DisplaySport}
                        entries={this.props.entries !== undefined ? this.props.entries : null}
                        handleCancelClick={this.props.handleCancelClick}
                        logged={this.props.logged}
                        openPopUpRegalo={this.props.openPopUpRegalo}
                        poolInfo={
                          this.props.items && this.props.items[0] ? this.props.items[0] : null
                        }
                        pools={this.props.pools}
                        popup={true}
                        popUpNewUsers={this.props.popUpNewUsers}
                        popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                        popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                        props={this.props.items !== null ? this.props.items[0] : null}
                        quinaID={this.props.quinaID}
                        type={this.props.bracketID !== undefined ? 'bracket' : undefined}
                        redirectToMisQuinielas={this.redirectToMisQuinielas}
                        updateStateFuncCancel={this.props.updatePanelCancelState}
                      />
                    </MediaQuery>
                    <MediaQuery query="(min-width: 660px)">
                      <Score
                        available={this.props.available}
                        close={this.props.updateStateProp}
                        displaySportFunc={DisplaySport}
                        entries={this.props.entries !== undefined ? this.props.entries : null}
                        handleCancelClick={this.props.handleCancelClick}
                        logged={this.props.logged}
                        openPopUpRegalo={this.props.openPopUpRegalo}
                        poolInfo={this.props.items !== null ? this.props.items[0] : null}
                        pools={this.props.pools}
                        popup={true}
                        popUpNewUsers={this.props.popUpNewUsers}
                        popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                        props={this.props.items !== null ? this.props.items[0] : null}
                        type={this.props.bracketID !== undefined ? 'bracket' : undefined}
                        quinaID={this.props.quinaID}
                        updateStateFuncCancel={this.props.updatePanelCancelState}
                      />
                    </MediaQuery>
                    {isReady && isPool ? (
                      <QuinielaInfo
                        handleTutorial={this.props.handleTutorial}
                        handleTutorialStep={this.props.handleTutorialStep}
                        close={this.props.updateStateProp}
                        hideLoginPopUp={this.props.hideLoginPopUp}
                        hideRegisterPopUp={this.props.hideRegisterPopUp}
                        item={this.props.items}
                        logged={this.props.logged}
                        openPanel={this.props.updatePanelState}
                        openPopUpRegalo={this.props.openPopUpRegalo}
                        popUpNewUsers={this.props.popUpNewUsers}
                        popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                        redirectToMisQuinielas={this.redirectToMisQuinielas}
                        showLoginPopUp={this.props.showLoginPopUp}
                        showRegisterPopUp={this.props.showRegisterPopUp}
                        updateRegisterPicks={this.props.updateRegisterPicks}
                        updateUserInfo={this.props.updateUserInfo}
                        updateUserBalance={this.props.updateUserBalance}
                        userData={this.props.userData}
                        userBalance={this.props.userBalance}
                        withRegister={withRegister}
                      />
                    ) : isReady && isBracket && sportId === 3 ? ( //BRACKET BASKET
                      <Bracket
                        bracket={bracketInfo}
                        userData={this.props.userData}
                        isLogged={this.props.logged}
                        showLoginPopUp={this.props.showLoginPopUp}
                        showRegisterPopUp={this.props.showRegisterPopUp}
                        isPopUp={true}
                        updateUserInfo={this.props.updateUserInfo}
                        selectedEntryData={null}
                        entryId={null}
                        refreshAfterGuardar={null}
                      />
                    ) : isReady && isBracket && sportId === 5 ? (  //BRACKET TENNIS
                      <BracketTennisLobby isPopUp={true} match={this.props.match} id={this.props.bracketID} />
                    ) : isReady && isBracket && sportId === 1 && bracketType === 'perfect' ? (  //BRACKET MUNDIAL PERFECTO
                      <BracketPerfect
                        bracket={bracketInfo}
                        userData={this.props.userData}
                        isLogged={this.props.logged}
                        showLoginPopUp={this.props.showLoginPopUp}
                        showRegisterPopUp={this.props.showRegisterPopUp}
                        isPopUp={true}
                        updateUserInfo={this.props.updateUserInfo}
                        selectedEntryData={null}
                        entryId={null}
                        refreshAfterGuardar={null}
                      />
                    ) : isReady && isBracket && sportId === 1 && bracketType === 'points' ? (  //BRACKET MUNDIAL
                      <BracketPoints
                        bracket={bracketInfo}
                        userData={this.props.userData}
                        isLogged={this.props.logged}
                        showLoginPopUp={this.props.showLoginPopUp}
                        showRegisterPopUp={this.props.showRegisterPopUp}
                        isPopUp={true}
                        updateUserInfo={this.props.updateUserInfo}
                        selectedEntryData={null}
                        entryId={null}
                        refreshAfterGuardar={null}
                      />
                    ) : isReady && isSurvivor ? (
                      <SurvivorPopup
                        close={this.props.updateStateProp}
                        hideLoginPopUp={this.props.hideLoginPopUp}
                        hideRegisterPopUp={this.props.hideRegisterPopUp}
                        item={this.props.items}
                        logged={this.props.logged}
                        openPanel={this.props.updatePanelState}
                        openPopUpRegalo={this.props.openPopUpRegalo}
                        popUpNewUsers={this.props.popUpNewUsers}
                        popUpNewUsersStatus={this.props.popUpNewUsersStatus}
                        redirectToMisQuinielas={this.redirectToMisQuinielas}
                        showLoginPopUp={this.props.showLoginPopUp}
                        showRegisterPopUp={this.props.showRegisterPopUp}
                        updateRegisterPicks={this.props.updateRegisterPicks}
                        updateUserInfo={this.props.updateUserInfo}
                        userData={this.props.userData}
                        withRegister={withRegister}
                        setItemSelected={this.props.setItemSelected}
                      />
                    ) : isReady && isPoolGroup ? (
                      <QuinielaGroupsContextProvider>
                        <QuinielaGrupoPopUp
                          selectedPool={this.props.items[0]}
                          userData={userData}
                          userBalance={userBalance}
                          updateUserInfo={updateUserInfo}
                          updateUserBalance={updateUserBalance}
                          poolId={this.props.quinaGrupoID}
                        />
                      </QuinielaGroupsContextProvider>
                    ) : null}
                  </div>
                </Modal>
              );
            }}
          </UserDataContextConsumer>
        </React.Fragment>
      );
    }
    return conditionalComponent;
  }
}

export default LobbyModal;
