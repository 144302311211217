import React, { useEffect } from 'react'
import { Redirect } from 'react-router'

// Components
import FormSmsVerification from './FormSmsVerification'
import { UserDataContextConsumer } from '../../context/UserDataContext'
import PageWrapper from '../PageWrapper'

// Hooks
import useMergedState from '../../hooks/useMergedState'

// Utils
import utils from '../../utils/utils'
import gaUtils from '../GAWrapper'

const initialState = {
  isLogged: utils.isLogged(),
  isVerified: !utils.isLogged() ? false : utils.isVerified(utils.getUserInfo()),
  title: '',
  redirect: false,
  userData: utils.getUserInfo()
}

// This component is for verificate user via SMS for avoid abuse
function PanelSmsVerification (props) {
  const [state, setState] = useMergedState(initialState)

  useEffect(() => {
    const fakeMatch = {
      isExact: true,
      params: {
        slug: 'Verificar cuenta'
      }
    }

    gaUtils.handleDocumentAttributes(fakeMatch)

    setState({
      isLogged: utils.isLogged(),
      isVerified: utils.isLogged() ? utils.isVerified(utils.getUserInfo()) : false,
      userData: utils.getUserInfo(),
      location: getPrevLocation()
    })
  }, [])

  useEffect(() => {
    if (utils.isLogged()) {
      setState({
        isLogged: utils.isLogged(),
        isVerified: utils.isLogged() ? utils.isVerified(utils.getUserInfo()) : false,
        title: '',
        userData: utils.getUserInfo()
      })
    }
  }, [utils.isLogged()])

  /**
   * If the verification happens in other route
   * after verify redirect to this pathname
   * @returns location { Object }
   */

  const getPrevLocation = () => {
    console.log('Get prevLocation', props.location)
    if (
      props.hasOwnProperty('location') &&
      props.location.hasOwnProperty('state') &&
      props.location.state
    ) {
      return props.location.state.prevLocation
    }
    return '/'
  }

  // If is logged and verified then the user is redirected to previous cached route
  if (state.isLogged && utils.isVerified(utils.getUserInfo())) {
    return (
      <Redirect
        push
        to={{
          pathname: `${
            state.location && state.location !== '/'
              ? state.location
              : '/'
          }`
        }}
      />
    )
  }

  // If is not logged then show the verificaation SMS form
  return (
    <PageWrapper>
      <UserDataContextConsumer>
        {({ updateUserInfo, userData }) => {
          return (
            <div className='container'>
              <div className='flex-row row-no-padding responsive-md row-center'>
                <div className='flex-col col-60 col-offset-20 verify-id panel-pickwin '>
                  <div id='panelTitle' className=' flex-row row-center panel-abonar-titulo'>
                    <span>
                      <i className='ion-alert-circled' /> Verificación de identidad
                    </span>
                  </div>
                  <FormSmsVerification
                    userData={userData}
                    updateUserInfo={updateUserInfo}
                    prevPath={state.location}
                  />
                </div>
              </div>
            </div>
          )
        }}
      </UserDataContextConsumer>
    </PageWrapper>
  )
}

export default PanelSmsVerification
