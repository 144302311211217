import InvitarAmigos from '../InvitarAmigos';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

import React from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap/lib/';

import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';

import API from '../../utils/api';
import Loading from '../Loading';
import billete from '../../assets/images/cash1-50.png';
import pickcoin from '../../assets/images/pickcoin1-50.png';
import MediaQuery from 'react-responsive';

import '../../assets/stylesheets/overrides/range-slider.scss';
import utils from '../../utils/utils.js';
import branchUtils from '../../utils/branchUtils.js';

import EditarQuinielaFormulario from './EditarQuinielaFormulario';
import PageWrapper from '../PageWrapper';

import Clipboard from 'clipboard';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import { ShareButtons } from 'react-share';

let clipboardCode;

const {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} = ShareButtons;

const ListaRegistrados = ({ standings, gameInfo, borrarParticipante }) => {
  let gameType = gameInfo.game_type;

  return (
    <div className="participantes-privada flex-col">
      <span className="flex-row formTitle">Registrados </span>
      <div className="flex-row registrado-privada-header">
        <div className="flex-col col-10 category posiciones">Pos.</div>
        <div className="flex-col col-10 category puntos">Pts.</div>
        <div className="flex-col col-10 category id">Id</div>
        <div className="flex-col col-35 category user">Apodo</div>
        <div className="flex-col  category borrar">
          Quitar de {gameInfo.game_type === 'survivor' ? 'Survivor' : 'Quiniela'}
        </div>
      </div>
      <div className="overflow overflow-360 overflow-y">
        {!standings || standings.items === 0 ? (
          <div className="flex-col col-center">
            <div className="flex-row">
              <strong className="flex-container column justify align text-center">
                <i
                  className="ion-sad-outline"
                  style={{ fontSize: '2.5em', marginBottom: '5px' }}
                ></i>
                No hay nadie registrado a{' '}
                {gameType === 'pool' ? 'esta quiniela.' : 'este survivor.'}
              </strong>
            </div>
          </div>
        ) : (
            standings.items.map((item) => {
              let entryId = gameType === 'pool' ? item.entry_id : item.survivor_entry_id;
              return (
                <div key={entryId} className="category registrado-privada-item flex-row">
                  <div className="category posiciones flex-col col-10">{item.rank}</div>
                  <div className="category puntos flex-col col-10">{item.total_possibly_correct}</div>
                  <div className="category id flex-col col-10">{entryId}</div>
                  <div className="category user flex-col col-35">{item.user.nick}</div>
                  <div className="category borrar flex-col ">
                    <button
                      onClick={() => {
                        borrarParticipante(item.user, entryId);
                      }}
                      data-id={entryId}
                      disabled={gameInfo.status !== 'upcoming'}
                      className="btn-delete"
                    >
                      <i data-id={entryId} className="ion-trash-a" />
                    </button>
                  </div>
                </div>
              );
            })
          )}
      </div>
    </div>
  );
};

const ListPrizes = ({ premios, premiosSemanales, gameInfo }) => {
  // console.log( premios, premiosSemanales, gameInfo );
  let premiosList = gameInfo.game_type === 'survivor' ? premios : premios.pool_prizes[0].prizes;

  if (!gameInfo) {
    return null;
  }
  return (
    <div className="premiosContainer flex-row">
      <div className="detalles-privadas-premios flex-col">
        <span className="formTitle">Premios</span>
        <div className="detalles-privadas-premios-container flex-container align justify ">
          {premiosList.length > 1 ? (
            premiosList.map((premio, i) => (
              <div key={i} className="privada-premios item ">
                <span>
                  <i className="ion-trophy" style={{ marginRight: '3px' }}></i>
                  {premio.position}° <small>Lugar:</small>
                </span>
                <strong>{premio.percentage / 100} %</strong>
              </div>
            ))
          ) : (
              <div className="privada-premios item">
                <span>
                  <i className="ion-trophy" style={{ marginRight: '3px' }}></i>
                1° <small>Lugar</small>
                </span>
                <strong>{premiosList[0].percentage / 100}%</strong>
              </div>
            )}
        </div>
      </div>
      {premiosSemanales ? (
        <div className="detalles-privadas-premios col-xs-12 col-md-offset-1 col-md-4">
          <span className="formTitle">Premios Semanales</span>
          <div className="detalles-privadas-premios-container">
            {gameInfo.weekly_prize_scheme === 'top' ? (
              <div className="privada-premios item">
                <span>
                  1° <small>Lugar:</small>
                </span>
                <strong>50%</strong>
                <span>
                  2° <small>Lugar:</small>
                </span>
                <strong>30%</strong>
                <span>
                  3° <small>Lugar:</small>
                </span>
                <strong>20%</strong>
              </div>
            ) : (
                <div className="privada-premios item">
                  <span>
                    1° <small>Lugar</small>
                  </span>
                  <strong>100%</strong>
                </div>
              )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

/**
 * This component renders the details of the private Pool
 * @param {gameInfo } - Object - this object ontain all the info of the pool,
 * @param { editarExito } - boolean - this value controls the status of the ediion
 * @param { editarQuinielaResponse } - object - This object contains the new data of the edited pool
 */
const QuinielaPrivadaDetails = ({ gameInfo, editarExito, editarQuinielaResponse }) => {
  return (
    <div className="detalles-privadas-datos col-md-4 col-sm-12">
      <div className="detalle-privada item">
        <span>Nombre:</span>
        <span>{gameInfo.name_replaced}</span>
      </div>
      <div className="detalle-privada item">
        <span>Contraseña:</span>
        <span>{gameInfo.password}</span>
      </div>
      <div className="detalle-privada item">
        <span>Deporte:</span>
        <span>{gameInfo.sport.name}</span>
      </div>
      <div className="detalle-privada item">
        <span>Torneo:</span>
        <span>{gameInfo.tournament_instances[0].name}</span>
      </div>
      <div className="detalle-privada item">
        <span>Entrada:</span>
        {gameInfo.entry_currency === 'real' ? <img src={billete} /> : <img src={pickcoin} />}
        {editarExito !== null ? (
          editarExito ? (
            <span className="currency">
              {numeral(editarQuinielaResponse.result.entry_fee / 100).format('0,000.00')}
            </span>
          ) : null
        ) : (
            <span className="currency">{numeral(gameInfo.entry_fee / 100).format('0,000.00')}</span>
          )}
        {gameInfo.entry_currency === 'real' ? (
          <span className="currency">
            <small>MXN</small>
          </span>
        ) : (
            <span className="currency">
              <small>PC</small>
            </span>
          )}
      </div>
      <div className="detalle-privada item">
        <span>Participantes:</span>
        <span>{gameInfo.entry_count}</span>
      </div>
      <div className="detalle-privada item">
        <span>Fecha límite:</span>
        <span>{moment(gameInfo.deadline_tz).format('DD/MM/YY HH:MM')}</span>
      </div>
      <div className="btn-wrap">
        <Link
          to={`/${gameInfo.game_type === 'pool' ? 'quiniela' : 'survivor'}/${gameInfo.slug}/${
            gameInfo.id
            }`}
          className="boton-ir-quiniela"
        >
          IR A MI {gameInfo.game_type === 'pool' ? 'QUINIELA' : 'SURVIVOR'}
        </Link>
      </div>
    </div>
  );
};

const DeleteConfirmation = ({
  title,
  showConfirmation,
  submitting,
  hideConfirmation,
  label,
  removeUser,
  entryToDelete,
  userToDelete,
}) => {
  return (
    <Modal dialogClassName="confirmationPanelPopUp" show={showConfirmation} keyboard={false}>
      <div className="panel-abonar">
        <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo `}>
          <span>{title}</span>
          <button className="cerrar-panel" onClick={hideConfirmation}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-alert-circled alert" />
              {label}
            </div>
          </div>
        </div>
        <div className="flex-row row-center">
          <div className="flex-col col-90 col-offset-5">
            <div className="flex-row">
              <Button
                className="flex-col col-40 col-offset-5 col-center btn-rojo btn-submit text-center"
                onClick={() => {
                  hideConfirmation();
                }}
              >
                No
              </Button>
              <Button
                disabled={submitting}
                className="flex-col col-40 col-offset-5 col-center btn-pick btn-submit text-center"
                onClick={() => {
                  submitting = true;
                  removeUser(entryToDelete)
                    .then((response) => {
                      console.log('Response', response);
                      if (response) {
                        hideConfirmation();
                      }
                    })
                    .catch((error) => {
                      console.error(error, 'error in confirmation');
                    });
                }}
              >
                {submitting ? (
                  <div className="flex-container justify align column">
                    <i className="ion-load-a loading small"></i>
                  </div>
                ) : (
                    'Si'
                  )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

class EditarQuinielaPrivada extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      logged: null,
      updatedPool: false,
      submitting: false,
      userToDelete: null,
      entryToDelte: false,
      branchLink: null,
    };

    // this.getValidationStep = this.getValidationStep.bind(this);
    // this.validatePremios = this.validatePremios.bind(this);
    this.borrarParticipante = this.borrarParticipante.bind(this);
    this.showErrors = this.showErrors.bind(this);
    this.tablaPremios = this.tablaPremios.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);

    this.queryParams = {
      nick: '',
      order: '',
      page: 1,
      limit: 100,
    };

    this.getSurvivorData = (survivorId) => {
      axios
        .all([API.fetchSurvivorDetails(survivorId), API.fetchSurvivorStandingsFull(survivorId)])
        .then(
          axios.spread((gameInfo, standings) => {
            let gameInfo_ = gameInfo.data;
            let standings_ = standings.data;
            // entries_  = entries.data;
            // console.log('%c Recieving QPriv Data...', 'font-size:20px');
            // console.log(premios_);
            this.setState(
              () => {
                return {
                  gameInfo: gameInfo_.survivor,
                  premios: gameInfo_.survivor_prizes,
                  standings: standings_,
                  loaded: true,
                };
              },
              () => {
                this.getBranchLink().then((url) => {
                  this.updateBranchLink(url, null, this.state.gameInfo.id);
                });

                // this.setQuinielaDefaultData();
                // this.previousDataQuiniela();
              }
            );
          })
        )
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching Axios all in Editar Quiniela Privada',
          });
        });
    };

    this.getPoolData = (poolId) => {
      axios
        .all([
          API.fetchIndividualPool(poolId),
          API.fetchPoolPrizes(poolId),
          API.fetchStandings(poolId, this.queryParams),
        ])
        .then(
          axios.spread((gameInfo, premios, standings) => {
            let gameInfo_ = gameInfo.data;
            let premios_ = premios.data;
            let standings_ = standings.data;
            // entries_  = entries.data;
            // console.log('%c Recieving QPriv Data...', 'font-size:20px');
            // console.log(premios_);
            this.setState(
              () => {
                return {
                  gameInfo: gameInfo_.pool,
                  premios: premios_,
                  standings: standings_,
                  loaded: true,
                };
              },
              () => {
                this.getBranchLink().then((url) => {
                  this.updateBranchLink(url, null, this.state.gameInfo.id);
                });
                // this.setQuinielaDefaultData();
                // this.previousDataQuiniela();
              }
            );
          })
        )
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching Axios all in Editar Quiniela Privada',
          });
        });
    };

    this.updatePoolInfo = (newPoolInfo) => {
      console.log(newPoolInfo);

      this.setState(
        {
          gameInfo: newPoolInfo,
          updatedPool: true,
        },
        () => {
          if (this.state.gameInfo.game_type === 'survivor') {
            console.log('Survivor Update after edit');
            console.log(this.state.gameInfo);
            this.setState({
              premios: this.state.gameInfo.survivor_prizes,
            });
          }
        }
      );
    };

    this.selectUserToDelete = (user, value) => {
      console.log('Delete user', value);
      this.setState({
        userToDelete: user,
        entryToDelete: value,
        showConfirmation: true,
      });
    };

    this.hideConfirmation = () => {
      this.setState({
        showConfirmation: false,
        userToDelete: null,
        entryToDelte: null,
      });
    };

    this.whatsAppInvitation = () => {
      console.log('WhatsApp invitation');
      branchUtils.redirectToWhatsApp(this.state.branchLink, 'open', this.state.gameInfo.id);
    };
    this.getBranchLink = () => {
      return branchUtils.createLink(
        utils.getUserInfo(),
        this.state.gameInfo,
        this.state.gameInfo.game_type
      );
    };
    /**
     *  This function updates the branch Link after it is ready
     */
    this.updateBranchLink = (newLink) => {
      console.log('UpdateBranchLink');
      this.setState({
        branchLink: newLink,
      });
    };
  }

  /**
   * This function updates the state of the user info
   * by Getting the updated data from the API
   */
  updateUserInfo() {
    // update data balances and stuff
    API.fetchUserData()
      .then((response) => {
        utils.setUserInfo(response.data);
        this.setState(() => {
          console.log('UpdateingUSerIngo in Home');
          return {
            popUpPoolId: null,
            popUpView: 0,
            userData: response.data,
            logged: response.data.user !== null ? true : false,
          };
        });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error updating userInfo in editQuiniela',
        });
      });
  }

  borrarParticipante(value) {
    console.log('Borrar participante');
    this.setState({ submitting: true });

    let promise = null;

    if (this.state.gameInfo.game_type === 'survivor') {
      let id = Number(value);
      console.log(id);

      promise = new Promise((resolve, reject) => {
        API.postDeleteRegisterSurvivorPrivate(id)
          .then((response) => {
            console.log(response);
            if (response.data.result.success === true) {
              let tempStandings = this.state.standings;
              // console.log('After Delete', tempStandings.items.length);
              let indexDeleted = null;
              let itemDeleted = tempStandings.items.find((item, itemIndex) => {
                // console.log(itemIndex, item, id);
                let entryId = item.hasOwnProperty('entry_id')
                  ? item.entry_id
                  : item.survivor_entry_id;
                if (entryId === id) {
                  indexDeleted = itemIndex;
                  console.log(itemDeleted, itemIndex, tempStandings.items[itemIndex]);
                  return item;
                }
              });
              console.log('Delete this');
              console.log(itemDeleted, indexDeleted, tempStandings.items[indexDeleted]);
              tempStandings.items.splice(indexDeleted, 1);
              // console.log('BeforeDelete', tempStandings.items.length);
              this.setState(
                {
                  standings: tempStandings,
                  deletedUser: true,
                },
                () => {
                  this.setState(
                    {
                      deletedUser: false,
                      submitting: false,
                    },
                    () => {
                      resolve(true);
                    }
                  );
                }
              );
            }
          })
          .catch((error) => {
            reject(false);
            this.setState({
              submitting: false,
            });
            Sentry.captureException(error, {
              extra: 'Error Deleting Register in Private',
            });
          });
      });
    } else if (this.state.gameInfo.game_type === 'pool') {
      /**
       * Delete for Pools
       */
      let id = Number(value);
      console.log(id);

      promise = new Promise((resolve, reject) => {
        API.postDeleteRegisterPrivate(id)
          .then((response) => {
            console.log(response);
            if (response.data.result.success === true) {
              let tempStandings = this.state.standings;
              // console.log('After Delete', tempStandings.items.length);
              let indexDeleted = null;
              // let itemDeleted = tempStandings.items.find((item, itemIndex) => {
              //   // console.log(itemIndex, item, id);
              //   if (item.entry_id === id) {
              //     indexDeleted = itemIndex;
              //     // console.log(itemDeleted, itemIndex, tempStandings.items[itemIndex]);
              //     return item;
              //   }
              // });
              // console.log('Delete this');
              // console.log(itemDeleted, indexDeleted, tempStandings.items[indexDeleted]);
              tempStandings.items.splice(indexDeleted, 1);
              // console.log('BeforeDelete', tempStandings.items.length);
              this.setState(
                {
                  standings: tempStandings,
                  deletedUser: true,
                },
                () => {
                  this.setState(
                    {
                      deletedUser: false,
                      submitting: false,
                    },
                    () => {
                      resolve(true);
                    }
                  );
                }
              );
            }
          })
          .catch((error) => {
            reject(false);
            this.setState({
              submitting: false,
            });
            Sentry.captureException(error, {
              extra: 'Error Deleting Register in Private',
            });
          });
      });
    }

    return promise;
  }

  showErrors() {
    console.log('Show Errors');
    if (this.state.crearQuinielaResponse === undefined) {
      return;
    }
    let listErrors;
    let keyErrors = Object.keys(this.state.crearQuinielaResponse.errors);
    listErrors = keyErrors.map((error, i) => {
      console.log(error);
      let textError = Object.keys(this.state.crearQuinielaResponse.errors[error]).map((text) => {
        console.log(text);
        return this.state.crearQuinielaResponse.errors[error][text];
      });
      return (
        <span key={i} className="errorItem">
          {textError}
        </span>
      );
    });

    console.log(listErrors, this.state.crearQuinielaResponse);
    return <span className="listErrors">{listErrors}</span>;
  }

  tablaPremios(cantPremios, porcentajesPremios) {
    console.log(cantPremios, porcentajesPremios);
    console.log(this);
    let premiosList = [];
    for (let i = 0; i < cantPremios; i++) {
      premiosList.push('');
    }
    premiosList = premiosList.map((premio, i) => {
      return (
        <div key={i} className="privada-premios item">
          <span>
            {i + 1}° <small>Lugar:</small>
          </span>
          <strong>{porcentajesPremios[i] !== null ? porcentajesPremios[i] : 0}%</strong>
        </div>
      );
    });
    return premiosList;
  }

  handleConfirmation() {
    console.log('handleConfirmation');
    setTimeout(() => {
      this.setState({
        editarExito: null,
      });
    }, 3000);
    // this.setState({
    //     showConfirmation:true,
    // })
  }

  handleInvitarPanel(e) {
    console.log('%c Show Panel', 'font-size:20px');
    this.setState({
      showPanel: false,
    });
  }

  componentDidMount() {
    let gameType = this.props.match.params.gameType;
    let privadaId = this.props.match.params.privadaId;

    clipboardCode = new Clipboard('.copiar-btn');
    console.log(clipboardCode);
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      console.log('wich', wich);
      $(wich).addClass('copied');
      e.clearSelection();
    });

    this.setState(() => {
      return {
        userData: utils.getUserInfo(),
        logged: utils.isLogged(),
      };
    });

    if (gameType === 'survivor') {
      this.getSurvivorData(privadaId);
    } else if (gameType === 'quiniela') {
      this.getPoolData(privadaId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.updatedPool &&
      this.state.updatedPool &&
      this.state.gameInfo.game_type === 'pool'
    ) {
      API.fetchPoolPrizes(this.state.gameInfo.id)
        .then((response) => {
          this.setState({
            premios: response.data,
          });
        })
        .catch((error) => {
          Sentry.captureException(error, { extra: 'Error Edit Private' });
        });
    }
  }

  render() {
    console.log("PRIVSTATE:", this.state);
    let conditionalComponent;
    if (this.state.loaded) {
      conditionalComponent = (
        <PageWrapper>
          <div className="row">
            <div className="editar-quiniela-wrapper col-xs-12 col-md-offset-4 col-md-8 col-hd-offset-3 col-hd-6">
              {this.state.editarExito !== null && this.state.editarExito !== undefined ? (
                this.state.editarExito ? (
                  <div className="panel-editar-quiniela-titulo">TÚ QUINIELA SE EDITÓ CON ÉXITO</div>
                ) : (
                    <div className="panel-editar-quiniela-titulo" style={{ background: '#E00034' }}>
                      TUVIMOS UN PROBLEMA AL EDITAR TÚ QUINIELA, POR FAVOR INTÉNTALO DE NUEVO
                    </div>
                  )
              ) : (
                  <div className="panel-editar-quiniela-titulo">
                    EDITA TÚ{' '}
                    {this.state.gameInfo.game_type === 'survivor'
                      ? 'SURVIVOR PRIVADO'
                      : 'QUINIELA PRIVADA'}
                  </div>
                )}
              <div className="crearQuinielaForm editar">
                <div
                  className={`picks-message-overlay ${
                    this.state.editarExito !== null && this.state.editarExito !== undefined
                      ? this.state.editarExito
                        ? 'show-message'
                        : 'show-message failed'
                      : ''
                    }`}
                >
                  {this.state.editarExito !== null && this.state.editarExito !== undefined ? (
                    this.state.editarExito ? (
                      <span>Los cambios se guardaron con éxito</span>
                    ) : (
                        <span>
                          Tuvimos un problema en tu solicitud, porfavor inténtalo nuevamente.
                        </span>
                      )
                  ) : (
                      ''
                    )}
                </div>
                <div className="detalles-quiniela-privada">
                  <span className="formTitle">
                    Detalles de{' '}
                    {this.state.gameInfo.game_type === 'survivor' ? 'survivor' : 'quiniela'}
                  </span>
                  <div className="detalles-privadas-container">
                    <QuinielaPrivadaDetails
                      gameInfo={this.state.gameInfo}
                      editarExito={this.state.editarExito}
                      editarQuinielaResponse={this.state.editarQuinielaResponse}
                    />
                    <div className="detalles-privadas-compartir col-md-8 col-sm-12">
                      <span className="formTitle"> Invita a tus amigos</span>
                      <div className="detalles-privadas-compartir-container">
                        <div className="btn-group">
                          <FacebookShareButton
                            quote={`Participa en ${
                              this.state.gameInfo.game_type === 'pool'
                                ? 'la quiniela'
                                : 'el survivor'
                              } ${this.state.gameInfo.name_replaced} visitando este link `}
                            hashtag={'#Pickwin #registrate #JalaTusPicks'}
                            url={
                              !this.state.branchLink
                                ? `https://www.pickwin.mx/${
                                this.state.gameInfo.game_type === 'pool'
                                  ? 'quinielaPop'
                                  : 'survivorPop'
                                }/${this.state.gameInfo.slug}/${this.state.gameInfo.id}?referer=${
                                this.state.userData.referalCode
                                }`
                                : this.state.branchLink
                            }
                          >
                            <Button className="fb-btn">
                              <i className="ion-social-facebook" /> Compartir
                            </Button>
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={`Participa en ${
                              this.state.gameInfo.game_type === 'pool'
                                ? 'la quiniela'
                                : 'el survivor'
                              } ${this.state.gameInfo.name_replaced} visitando este link `}
                            url={
                              !this.state.branchLink
                                ? `https://www.pickwin.mx/${
                                this.state.gameInfo.game_type === 'pool'
                                  ? 'quinielaPop'
                                  : 'survivorPop'
                                }/${this.state.gameInfo.slug}/${this.state.gameInfo.id}?referer=${
                                this.state.userData.referalCode
                                }`
                                : this.state.branchLink
                            }
                            hasthag={'#Pickwin #registrate #JalaTusPicks'}
                          >
                            <Button className="twitter-btn">
                              <i className="ion-social-twitter" /> Compartir{' '}
                            </Button>
                          </TwitterShareButton>
                          <MediaQuery maxWidth={669}>
                            <WhatsappShareButton
                              title={`Juega en mi ${
                                this.state.gameInfo.game_type === 'pool' ? 'quiniela' : 'survivor'
                                } ${this.state.gameInfo.name_replaced} `}
                              separator="***"
                              url={
                                !this.state.branchLink
                                  ? `https://www.pickwin.mx/${
                                  this.state.gameInfo.game_type === 'pool'
                                    ? 'quinielaPop'
                                    : 'survivorPop'
                                  }/${this.state.gameInfo.slug}/${
                                  this.state.gameInfo.id
                                  }?referer=${this.state.userData.referalCode}`
                                  : this.state.branchLink
                              }
                            >
                              {/* <Button className="whatsapp-btn" href={`whatsapp://send?text=Juega+en+mi+quiniela+mundial2+https%3A%2F%2Fwww.pickwin.mx%2F%3Futm_source%3Dreferral%26utm_link%3Dproduct%26referer%3DZn6doG%23%2Fpool%2Fpopup%2F12147`}> <i className='ion-social-whatsapp'></i> Compartir </Button> */}
                              <Button className="whatsapp-btn">
                                <i className="ion-social-whatsapp" />
                                Compartir
                              </Button>
                            </WhatsappShareButton>
                          </MediaQuery>
                          <Button className="mail-btn" onClick={this.openPanel}>
                            <i className="ion-email" /> Compartir
                          </Button>
                        </div>
                        <span className="formTitle flex-row">
                          Comparte este link con tus amigos
                        </span>
                        <div className="flex-row">
                          <div className="codigo-amigo flex-col">
                            <input
                              id="copiar-clipboard"
                              value={
                                this.state.branchLink !== undefined ? this.state.branchLink : `https://www.pickwin.mx/${
                                  this.state.gameInfo.game_type === 'survivor' ? 'survivorPop' : 'quinielaPop'
                                  }/
                                ${this.state.gameInfo.slug}/${this.state.gameInfo.id}?referer=${
                                  utils.getUserInfo().referalCode
                                  }`
                              }
                              onChange={() => {}}
                            />
                          </div>
                          <div className="copiar-button-codigo flex-col col-15">
                            <ReactTooltip
                              id="copiar-tool"
                              place="bottom"
                              type="light"
                              effect="solid"
                            >
                              Copia tú enlace y compártelo
                            </ReactTooltip>
                            <button
                              data-tip
                              data-for="copiar-tool"
                              className="copiar-btn"
                              data-clipboard-target="#copiar-clipboard"
                            >
                              <i className="ion-ios-copy " />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ListPrizes
                    premios={this.state.premios}
                    premiosSemanales={this.state.quinielaPremiosSemanales}
                    gameInfo={this.state.gameInfo}
                  />
                </div>
                <span className="formTitle">
                  Editar {this.state.gameInfo.game_type === 'survivor' ? 'Survivor' : 'Quiniela'}
                </span>
                <EditarQuinielaFormulario
                  gameInfo={this.state.gameInfo}
                  premios={this.state.premios}
                  activeSection={this.state.activeSection}
                  nextStep={this.state.nextStep}
                  prevStep={this.state.preStep}
                  submitting={this.state.submitting}
                  standings={this.state.standings}
                  updatePoolInfo={this.updatePoolInfo}
                />
                <ListaRegistrados
                  standings={this.state.standings}
                  gameInfo={this.state.gameInfo}
                  borrarParticipante={this.selectUserToDelete}
                />
              </div>
            </div>
          </div>
          <DeleteConfirmation
            title={'Eliminar usuario registrado'}
            showConfirmation={this.state.showConfirmation}
            submitting={this.state.submitting}
            hideConfirmation={this.hideConfirmation}
            label={`¿Seguro que deseas eliminar a ${
              !this.state.userToDelete ? '' : this.state.userToDelete.nick
              }?`}
            removeUser={this.borrarParticipante}
            userToDelete={this.state.userToDelete}
            entryToDelete={this.state.entryToDelete}
          />
          <InvitarAmigos
            pool={this.state.gameInfo}
            showPanel={this.state.showPanel}
            userDataProps={this.state.userData}
            updateStateFunc={this.handleInvitarPanel}
          />
        </PageWrapper>
      );
    } else {
      conditionalComponent = <Loading label="Cargando información la quiniela privada " />;
    }
    return conditionalComponent;
  }
}

export default EditarQuinielaPrivada;
