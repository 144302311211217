import React from 'react';
import MisRegistros from '../MisRegistros';
import API from '../../utils/api';
import ErrorBoundary from '../ErrorBoundary';
import utils from '../../utils/utils';
import gaUtils from '../GAWrapper';
import PageWrapper from '../PageWrapper';
import * as Sentry from '@sentry/browser';

import { UserDataContextConsumer } from '../../context/UserDataContext';

class MisQuinielas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      logged: false,
      userData: null,
    };
    this.updateUserInfo = this.updateUserInfo.bind(this);
    // console.log('MisQuinielas',this)
  }

  /**
   * This position is triggered when the user completes an action
   * that involves the manipulation of the balance of his pickcoins or RealMoney
   */
  updateUserInfo() {
    // console.log("Update User Info");
    API.fetchUserData()
      .then((response) => {
        utils.setUserInfo(response.data);
        this.setState({ userData: response.data });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error Updating USer INfo in mis quinielas',
        });
      });
  }

  componentDidMount() {
    let fakeMatch = {
      isExact: true,
      params: {
        slug: 'Mis Quinielas',
      },
    };
    gaUtils.handleDocumentAttributes(fakeMatch);

    this.setState(() => {
      return {
        mounted: true,
        userData: utils.getUserInfo(),
        logged: utils.isLogged(),
      };
    });
  }

  render() {
    let conditionalComponent = (
      <PageWrapper isFixed>
        <UserDataContextConsumer>
          {({ userData, userBalance, updateUserInfo, updateUserBalance, isLogged }) => {
            return (
              <MisRegistros
                logged={isLogged}
                updateUserInfo={updateUserInfo}
                updateUserBalance={updateUserBalance}
                userData={userData}
                userBalance={userBalance}
              />
            );
          }}
        </UserDataContextConsumer>
      </PageWrapper>
    );

    return <ErrorBoundary>{conditionalComponent}</ErrorBoundary>;
  }
}

export default MisQuinielas;
