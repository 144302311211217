import React from 'react';

export default function TerminosLealtad() {
  return(
    <div id="terminos-lealtad">
        <h3>TÉRMINOS Y CONDICIONES DEL PROGRAMA DE LEALTAD PICKWIN&reg;</h3>
        <h4>PARTES</h4>
        <p>
          UNOCAPALI LA PAZ OPERADORA S.A. DE C.V. (en lo sucesivo “UNOCAPALI”) Operador de
          Producciones Móviles, S.A. de C.V. (Permisionaria autorizado por la Secretaría de
          Gobernación), a través de la plataforma www.pickwin.mx, quien actúa bajo el amparo del
          Permiso Federal No. DGAJS/SCEVF/P-06/2005-Ter, en términos del oficio número
          DGJS/1580/2021 de fecha 30 de agosto de 2021 emitido por la Dirección de Control y
          Regulación de Centros de Apuestas Remotas y Salas de Sorteos adscrita a la Dirección
          General de Juegos y Sorteos de la Secretaría de Gobernación, y (iii) contrato de
          prestación de servicios para el acceso no exclusivo a la plataforma informática
          www.pickwin.mx celebrado con PWTEC, S.A.P.I DE C.V. (en lo sucesivo “PWTEC”).
        </p>
        <p>
          La página web de www.pickwin.net y/o www.pickwin.mx (colectivamente el “Sitio” o “Sitio Web”) - que incluye todo el texto, imágenes, audio, código y otros materiales que contienen o proporcionan (colectivamente, el “Contenido”) y todas las características, concursos y otros servicios que se ofrecen - son propiedad PWTEC y están operados por UNOCAPALI (en lo sucesivo, “Pickwin” o “Nosotros”). Para conocer más a detalle sobre los derechos de propiedad intelectual sobre el Sitio, así como sus usos permitidos, favor de dirigirse a nuestros Términos de Uso de la Plataforma.
        </p>

        <h4>USUARIOS PICKWIN</h4>
        <p>
          Los Jugadores de Pickwin podrán participar en el presente Programa de Lealtad previa aceptación de los presentes Términos y Condiciones del Programa; en el entendido de que deberán ser personas físicas, que tengan domicilio en los Estados Unidos Mexicanos.
        </p>
        <p>
          Los menores de edad no podrán participar en el Programa.
        </p>
        <p>
          Solo podrán participar los Jugadores Pickwin que tengan una Cuenta Pickwin y que hayan aceptado previamente los Términos y Condiciones de Uso de la Plataforma los cuales se entiende trascritos en su integridad en los presentes y serán aplicables en todo lo relacionado a: uso del Sitio Web o Aplicación Móvil, reglas de competencia y condiciones de participación, manejo de Datos Personales, Seguridad, responsabilidad de los Jugadores, Pagos, Declaraciones y Garantías, Conducta, y jurisdicción aplicable.
        </p>

        <h4>INSCRIPCIÓN AL PROGRAMA</h4>
        <p>
          Para inscribirse al Programa bastará que el Jugador de Pickwin acepté los presentes Términos y Condiciones del Programa vía internet en el Sitio Web o la Aplicación Móvil
        </p>
        <p>
          En caso de que los Presentes Términos y Condiciones del Programa sean modificados por Pickwin se notificará a los Jugadores por correo electrónico.
        </p>
        <p>
          Pickwin podrá poner fin de inmediato a estos Términos y Condiciones respecto de usted o, en general, dejar de ofrecer o denegar el acceso a los servicios o cualquier parte de ellos, en cualquier momento y por cualquier motivo de manera unilateral.
        </p>


        <h4>COMO FUNCIONA EL PROGRAMA DE PUNTOS</h4>
        <p>
          Los Jugadores Pickwin acumularan puntos por jugar quinielas públicas, survivors públicos, posturas en el sports book, referir amigos y por lo que jueguen sus referidos.
        </p>
        <p>
          A final de cada mes se acumulará un porcentaje de los puntos calificables al saldo en Pickwin de cada Jugador conforme a lo siguiente:
        </p>

        <p><strong><u>Niveles:</u></strong></p>

        <div className="table-container">
          <table className="table is-fullwidth points-table">
            <thead>
              <tr>
                <th>
                  Nivel
                </th>
                <th>
                  Novato
                </th>
                <th>
                  Pro
                </th>
                <th>
                  Titular
                </th>
                <th>
                  Capitán
                </th>
                <th>
                  MVP
                </th>
                <th>
                  Salón de la Fama
                </th>
                <th>
                  Leyenda
                </th>
                <th>
                  GOAT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>
                    Puntos requeridos
                  </strong>
                </td>
                <td>
                  75
                </td>
                <td>
                  150
                </td>
                <td>
                  350
                </td>
                <td>
                  1,000
                </td>
                <td>
                  5,000
                </td>
                <td>
                  75,000
                </td>
                <td>
                  500,000
                </td>
                <td>
                  2,000,000
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    % de reembolso
                  </strong>
                </td>
                <td>
                  10%
                </td>
                <td>
                  11%
                </td>
                <td>
                  13%
                </td>
                <td>
                  14%
                </td>
                <td>
                  16%
                </td>
                <td>
                  18%
                </td>
                <td>
                  26%
                </td>
                <td>
                  33%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table className="table is-fullwidth levels-table">
          <thead className="has-text-centered">
            <tr>
              <th>
                JUEGO
              </th>
              <th>
                DESCRIPCIÓN
              </th>
              <th>
                PUNTOS OTORGADOS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Quinielas y survivors públicos
              </td>
              <td>
                $1.7 pesos pagados en comisión
              </td>
              <td>
                1 punto calificable
              </td>
            </tr>
            <tr>
              <td>
                Apuestas Deportivas
              </td>
              <td>
                $34 pesos apostados
              </td>
              <td>
                1 punto calificable
              </td>
            </tr>
            <tr>
              <td>
                Invitados
              </td>
              <td>
                Por cada invitado depositante
              </td>
              <td>
                50 puntos no calificables
              </td>
            </tr>
            <tr>
              <td>
                Monto jugado por invitados en Quinielas y Survivors
              </td>
              <td>
                $20 pesos pagados en comisión
              </td>
              <td>
                1 punto no calificable
              </td>
            </tr>
            <tr>
              <td>
                Monto jugado por invitados en Apuestas Deportivas
              </td>
              <td>
                $500 pesos en apuestas
              </td>
              <td>
                1 punto no calificable
              </td>
            </tr>
          </tbody>
        </table>

        <p><strong><u>Ejemplo:</u></strong></p>
        <p>
          <em>Resultado a fin de mes</em>
        </p>
        <table className="table example-table">
          <tbody>
            <tr>
              <th>Puntos totales:</th>
              <td>1,000</td>
            </tr>
            <tr>
              <th>Nivel:</th>
              <td>Capitán</td>
            </tr>
            <tr>
              <th>% de reembolso:</th>
              <td>14%</td>
            </tr>
            <tr>
              <th>Puntos calificables:</th>
              <td>800</td>
            </tr>
            <tr>
              <th>Puntos no calificables</th>
              <td>200</td>
            </tr>
            <tr>
              <th>Reembolso:</th>
              <td>14% &times; 800 = $112 pesos</td>
            </tr>
          </tbody>
        </table>

        <p>
          Los Jugadores Pickwin podrán dar seguimiento al cumplimiento de los objetivos y entrega de los Puntos en la página “https://www.pickwin.mx/programa-lealtad/”  o en la sección de “PROGRAMA DE LEALTAD” en la Aplicación Móvil.
        </p>
        <p>
          El monto de los Puntos entregado como resultado del mes anterior, no se contabilizará dentro de los montos jugados en el mes para la entrega de puntos calificables del mes actual.
        </p>
        <p>
          Los montos de entradas a quinielas o survivors privados o gratuitos no se contabilizarán para la acumulación de Puntos.
        </p>

        <h4>REDENCIÓN DE PUNTOS</h4>
        <p>
          Al final de cada mes los puntos se convertirán de forma automática en parte del saldo de los Jugadores de Pickwin y podrán ser utilizados para jugar los productos Pickwin como jugar quinielas públicas, survivors públicos, posturas en el sports book.
        </p>
        <p>
          Lo anterior en el entendido, de que el Jugador no podrá canjear en efectivo los Puntos del Programa y deberá jugarlos al 100% al menos en una ocasión en quinielas o survivors públicos o en el sports book de Pickwin antes de poder hacer un retiro a cuenta bancaria o tarjeta de débito.
        </p>
        <p>
          Los Puntos Pickwin solo podrán ser utilizados dentro del Sitio Web o la Aplicación Móvil.
        </p>
        <h4>TRANSFERENCIAS A OTRAS CUENTAS</h4>
        <p>
          No se podrán realizar trasferencias de puntos entre Clientes Pickwin.
        </p>
        <h4>VIGENCIA DE LOS PUNTOS</h4>
        <p>
          El saldo en puntos se abonará a fin de cada mes y el usuario tendrá 1 año calendario para usar el saldo abonado a su cuenta dentro del Sitio Web o la Aplicación Móvil.
        </p>
        <h4>FRAUDES</h4>
        <p>
          Pickwin se reserva el derecho a llevar a cabo las acciones que considere pertinentes para suspender de manera automática, temporalmente o dar de baja de manera definitiva a cualquier Jugador de Pickwin, en caso de detectar fraudes, conductas fraudulentas y/o malas prácticas por parte de los Clientes, así como por incumplimiento a los Términos y Condiciones del Programa o bien por así convenir a sus intereses.
        </p>
        <h4>SUSPENSIÓN Y/O BAJA DEFINITIVA DE LA CUENTA</h4>
        <p>
          Pickwin, se reserva el derecho de suspender de manera automática, ya sea temporalmente o dar de baja de manera definitiva a cualquier Jugador Pickwin, por incumplimiento a los Términos y Condiciones de del Programa, por detectar transacciones fraudulentas, o bien por así convenir a sus intereses. Esta suspensión y/o baja se realizará de manera automática, en los casos que así lo ameriten. El Jugador será informado de dicho procedimiento mediante correo electrónico, para que pueda proporcionar en un plazo de 2 (dos) días hábiles la documentación que respalde cualquier transacción en su Cuenta Jugador, que sea considerada como fraudulenta o en incumplimiento a los Términos y Condiciones del Programa, en caso de no recibir respuesta por parte del Jugador, Pickwin suspenderá temporalmente y/o dará de baja de la Cuenta del Jugador.
        </p>
        <p>
          La suspensión temporal tendrá como consecuencia: i) la inhabilitación de la Cuenta Jugador durante el período que dure la investigación por movimientos irregulares de la Cuenta Jugador, el plazo dependerá de cada caso, y ii) el Jugador no podrá durante dicho término acumular Puntos o jugarlos.          </p>
        <p>
          La baja definitiva tiene como consecuencia la cancelación de la Cuenta Jugador, de los Puntos acreditados en la misma y de todos los beneficios del Programa.
        </p>
        <h4>SERVICIO DE ATENCIÓN A CLIENTES</h4>
        <p>
          En caso de existir alguna duda o aclaración respecto al Programa, así como a estos Términos y Condiciones, Usted podrá contactarnos: i) Vía correo electrónico en soporte@pickwin.net
        </p>
    </div>
  );
};

