import React from 'react'
import styled, { keyframes } from 'styled-components'

export default function Ripples ({ show }) {
  if (!show) return null

  return (
    <>
      <Glow style={{ animationDelay: '1500ms' }} />
      <Glow style={{ animationDelay: '2000ms' }} />
    </>
  )
}

const ribble = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.9);
    opacity: 0;
  }
`

const Glow = styled.div`
  position: absolute;
  width: 120%;
  height: 130%;
  opacity: 0;
  z-index: 1;
  top: 50%;
  left: 50%;
  border-radius: 1.4em;
  background-color: #78BC28;
  background: radial-gradient(#6fcd0000, #77bf08);
  transform: translate(-50%, -50%) scale(2);
  animation: ${ribble} 1.5s ease infinite forwards;
`
