import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

Particles.defaultProps = {
  // Number of particles generated
  count: 60,
  // Start delay in ms
  delay: 0
}

export default function Particles ({ count, show, delay }) {
  const [particles, setParticles] = useState([])

  useEffect(() => {
    // Build initial random particles
    const initialParticles = Array(count)
      .fill(null)
      .map((_, index) => ({
        index,
        x: Math.random() * 120 * (Math.random() > 0.5 ? -1 : 1),
        y: Math.random() * 120 * (Math.random() > 0.5 ? -1 : 1),
        angle: Math.random() * Math.PI * 2,
        delay: Math.random() * 700,
        opacityStart: Math.random(),
        opacityEnd: Math.random(),
        scaleStart: Math.random(),
        scaleEnd: Math.random()
      }))

    window.setTimeout(() => {
      setParticles(initialParticles)
    }, delay)
  }, [delay])

  if (!show) return null

  return (
    <>
      {particles.map(particle => (
        <ParticleComponent
          data={particle}
          key={particle.index}
        />
      ))}
    </>
  )
}

const ParticleComponent = ({ data }) => {
  const [transform, setTransform] = useState(`translateX(-50%) translateY(-50%) rotate(0deg) scale(${data.scaleStart})`)
  const [opacity, setOpacity] = useState(data.opacityStart)

  useEffect(() => {
    window.setTimeout(() => {
      setTransform(`translateX(${data.x}px) translateY(${data.y}px) rotate(${data.angle}deg) scale(${0})`)
      setOpacity(data.opacityEnd)
    }, data.delay)
  }, [])

  return (
    <Particle
      style={{
        transform,
        opacity
      }}
    />
  )
}

const Particle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 1em;
  background-color: #c0ff33;
  transition: transform 1300ms ease, opacity 1000ms ease-out;
  position: absolute;
  transform: translateX(0) translateY(0) rotate(0deg);
  left: 50%;
  top: 50%;
`
