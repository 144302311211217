import React, { memo } from 'react'
import styled, { css } from 'styled-components'

// Example of use
// <Text as='h1' size={12} color='lime' center>Example</Text>

export default memo(function Typography ({ as, children, style, ...others }) {

  // Reducing merged styles for direct props and shorthands
  const styles = helperStyles.reduce((prev, [ valueKey, key ]) => {

    const styles = { ...prev }

    if (others[valueKey]) {
      styles[key] = others[valueKey]
    }

    return styles
  }, { ...style})

  // Text Align
  if (others.center) {
    styles.textAlign = 'center'
  }

  if (others.left) {
    styles.textAlign = 'left'
  }

  if (others.right) {
    styles.textAlign = 'right'
  }

  // Margin shorcuts
  if (others.mx) {
    styles.marginLeft = others.mx
    styles.marginRight = others.mx
  }

  if (others.my) {
    styles.marginTop = others.my
    styles.marginBottom = others.my
  }

  // Paddings shorcuts
  if (others.px) {
    styles.paddingLeft = others.px
    styles.paddingRight = others.px
  }

  if (others.py) {
    styles.paddingTop = others.py
    styles.paddingBottom = others.py
  }

  const Component = getElement(as)

  return (
    <Component style={styles} {...others}>
      {children}
    </Component>
  )
})

// Alias shorthand
const helperStyles = [
  ['color', 'color'],
  ['weight', 'fontWeight'], 
  ['size', 'fontSize'],
  ['lineHeight', 'lineHeight'], 
  ['bg', 'background'],
  ['background', 'background'],
  // Margin
  ['margin', 'margin'],
  ['m', 'margin'],
  ['mt', 'marginTop'],
  ['mr', 'marginRight'],
  ['mb', 'marginBottom'],
  ['ml', 'marginLeft'],
  // Padding
  ['padding', 'padding'],
  ['p', 'padding'],
  ['pt', 'paddingTop'],
  ['pr', 'paddingRight'],
  ['pb', 'paddingBottom'],
  ['pl', 'paddingLeft'],
  // Align
  ['textAlign', 'textAlign']
]

function getElement (as) {
  switch (as) {
    case 'h1': return H1
    case 'h2': return H2
    case 'h3': return H3
    case 'h4': return H4
    case 'h5': return H5
    case 'h6': return H6
    case 'body': return Body
    case 'body2': return Body2
    case 'body3': return Body3
    case 'caption': return Caption
    case 'p': return Parragraph
    case 'span': return Span
    case 'a': return A
    case 'link': return A
    default: return Body
  }
}

// ------ Styled Components ------
const Base = css`
  font-family: 'Lato', sans-serif;
  margin: 0;
  width: 100%;
`

const H1 = styled.h1`
  ${Base};
  font-size: 4em;
`

const H2 = styled.h1`
  ${Base};
  font-size: 3em;
`

const H3 = styled.h1`
  ${Base};
  font-size: 2.5em;
`

const H4 = styled.h1`
  ${Base};
  font-size: 2em;
`

const H5 = styled.h1`
  ${Base};
  font-size: 1.5em;
`

const H6 = styled.h1`
  ${Base};
  font-size: 1.2em;
`

const Parragraph = styled.p`
  ${Base};
  font-size: 1em;
`

const Body = styled.p`
  ${Base};
  font-size: 1em;
`

const Body2 = styled.p`
  ${Base};
  font-size: 0.9em;
`

const Body3 = styled.p`
  ${Base};
  font-size: 0.85em;
`

const Caption = styled.p`
  ${Base};
  font-size: 0.8em;
`

const Span = styled.span`
  ${Base};
  font-size: 1em;
`

const A = styled.a`
  ${Base};
  font-size: 1em;
`

