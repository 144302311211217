import React from 'react'
import { useWizardPrivate } from '../wizardPrivate.store'

import { Typography, Stack, Box, Iconify } from '../../../Xmerald-UI'
import Button from './Button'

export default function Welcome () {
  const step = useWizardPrivate(state => state.step)
  const setStep = useWizardPrivate(state => state.setStep)

  if (step !== 'WELCOME') return null

  return (
    <Box>
      <Typography center size='1.2em' mt={50}>En esta sección puedes buscar y crear quinielas privadas.</Typography>
      <Stack direction='row' spacing='1em' justify='center' mt={50}>
        <Button
          className='guardar-picks'
          onClick={() => setStep('SEARCH')}
        >
          <Iconify icon='octicon:search-16' mr={7} pt={4} />
          Buscar
        </Button>
        <Button
          className='guardar-picks'
          onClick={() => setStep('CREATE_TYPE')} 
        >
          <Iconify icon='gridicons:add' mr={7} pt={4} />
          Crear
        </Button>
      </Stack>
    </Box>
  )
}
