import React from 'react';
import ReportIssueFormsy from './ReportIssueFormsy';

import Formsy from 'formsy-react';

import { Redirect } from 'react-router-dom';

import {
  Button
} from 'react-bootstrap/lib';

import API from '../../utils/api';
import utils from '../../utils/utils';


import * as Sentry from '@sentry/browser';


class ReportIssueFormulario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      isReady: false,
      errorMessage: false,
      canSubmit: false,
      successfullReport: false,
      errorReport: false,
      reportData: { name: '', email: '', comments: '', event_id: '' },
    };

    this.enableButton = () => {
      // console.log( 'enableButton' );
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      console.log('disableButton');
      if (
        this.state.reportData.name !== '' &&
        this.state.reportData.email !== '' &&
        this.state.reportData.comments !== ''
      ) {
        console.log('disableButton but enable');
        this.setState({ canSubmit: true });
      } else {
        this.setState({ canSubmit: false });
      }
    };

    this.handleUserName = (e) => {
      let temporalReportData = this.state.reportData;
      temporalReportData.name = e.target.value;
      this.setState({ reportData: temporalReportData });
    };

    this.handleEmail = (e) => {
      let temporalReportData = this.state.reportData;
      temporalReportData.email = e.target.value;
      this.setState({ reportData: temporalReportData });
    };

    this.handleComment = (e) => {
      console.log(e);
      let temporalReportData = this.state.reportData;
      temporalReportData.comments = e.target.value;
      this.setState({ reportData: temporalReportData });
    };
    this.sendFeedback = () => {
      this.setState({ submitting: true });
      let temporalReportData = this.state.reportData;
      temporalReportData.event_id = Sentry.lastEventId();
      API.sendFeedbackSentry(this.state.reportData).then((response) => {
        console.log('response report issue');
        console.log(response);
        this.setState({
          successfullReport: true,
          submitting: false,
        });
      });
    };
  }

  componentDidMount() {
    if (utils.isLogged()) {
      let name = utils.getUserInfo().nick;
      let mail = utils.getUserInfo().email;
      let temporalReportData = this.state.reportData;
      temporalReportData.name = name;
      temporalReportData.email = mail;
      temporalReportData.event_id = Sentry.lastEventId();
      console.log(this.state.reportData);
      this.setState({ reportData: temporalReportData });
    }
  }

  render() {
    let conditionalComponent = null;

    if (this.state.redirect) {
      console.log('redirect');
      conditionalComponent = <Redirect push to={{ pathname: `/` }} />;
    }

    if (this.state.successfullReport && !this.state.submitting) {
      return (
        <div className="flex-col col-80 col-offset-10">
          <div className="flex-container justify align column">
            <i className="ion-checkmark-circled success" />
            <span>¡Gracias, tu reporte se envío con éxito!</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex-col col-80 col-offset-10">
          <Formsy
            onValidSubmit={(e) => {
              this.submitSearch();
            }}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
          >
            <div className="flex-container justify align column">
              {this.state.redirect ? (
                <Redirect
                  push
                  to={{
                    pathname: `/`,
                  }}
                />
              ) : null}
              {this.state.successfullCreation ? (
                <span>Tu reporte se envío con éxito</span>
              ) : (
                  conditionalComponent
                )}
              <div className="flex-row ">
                <ReportIssueFormsy
                  label="Nombre o Apodo"
                  name="userName"
                  inputHandler={this.handleUserName}
                  type="NAME"
                  defaultValue={this.state.reportData.name}
                  validationError="Tu nick o nombre es obligatorio."
                />
              </div>
              <div className="flex-row ">
                <ReportIssueFormsy
                  label="Email"
                  name="userMail"
                  inputHandler={this.handleEmail}
                  type="MAIL"
                  defaultValue={this.state.reportData.email}
                  validations="isEmail"
                  validationError="Ese no parece ser un email válido."
                  required
                />
              </div>
              <div className="flex-row ">
                <ReportIssueFormsy
                  label="¿ Cómo sucedió ?"
                  name="descriptionArea"
                  placeholder="Describe que hiciste ó cómo surgió el error "
                  inputHandler={this.handleComment}
                  type="TEXT"
                  validations="isExisty"
                  validationError="Tu nick o nombre es obligatorio."
                  required
                />
              </div>
              <Button
                className="flex-col btn-pick callToAction"
                disabled={!this.state.canSubmit || this.state.submitting}
                onClick={() => {
                  this.sendFeedback();
                }}
              >
                <i className="ion-bug " /> Enviar Reporte de error
              </Button>
            </div>
          </Formsy>
        </div>
      );
    }
  }
}

export default ReportIssueFormulario;
