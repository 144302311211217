import React from 'react';
import _ from 'underscore';
import avatar from '../../assets/images/avatar-50.png';
import action from './actions';
import PresentationPicksF1 from './PresentationPicksF1';

const Pick = ({ selectedPlayer, player, dispatch, currentContest, bucketId, poolBuckets }) => {
  return (
    <label
      key={player.id}
      style={{cursor: 'pointer'}}
      className={`pick-category flex-row row-center home ${
        selectedPlayer && selectedPlayer.id === player.id ? 'selected' : ''
        }`}
      value={player.id}
      onClick={() => {
        action.handlePickF1(dispatch, bucketId, player.id, currentContest.id, poolBuckets);
      }}
    >
      <div className="pick-category flex-container align">
        <div className="category-container groupPool flex-row">
          <div className={`flex-col col-25 uppercase`}>
            <img className="player-avatar" src={player.avatar} style={{marginRight: '5px'}} />
          </div>
          <div className='col-75 uppercase'>
            <div className='flex-container align'>
              <i className={`${player.logo} `}></i>
              <span className='font9 overflow-words'>{player.name}</span>
            </div>
            <div className='flex-container between align' style={{marginTop: '5px'}}>
              <span className='font8'>{player.team_name}</span>
              <img src={player.alt_avatar} style={{width: '60px', height: 'auto'}} />
            </div>
          </div>
        </div>
      </div>
    </label>
  );
};

const ButtonsPlayers = ({ disabled, myEntry, bucketId, players, dispatch, selectedPlayer, currentContest, poolBuckets }) => {
  let playersList = players.map((player) => {
    return (
      <div key={player.id} className="flex-col col-50 pick-group-wrapper">
        <Pick
          selectedPlayer={selectedPlayer}
          player={player}
          bucketId={bucketId}
          dispatch={dispatch}
          disabled={disabled}
          isCorrect={false}
          currentContest={currentContest}
          poolBuckets={poolBuckets}
        />
      </div>
    );
  });

  return (
    <form className="1">
      <div className="flex-row row-no-padding row-wrap list-players">{playersList}</div>
    </form>
  );
};

/**
 * This component renders the list of picks and props
 * @param fixtures                { Object }
 * @param handlePick              { Function }
 * @param updateStartedFixture    { Function }
 * @param myEntry                 { Object }
 */
const ListBuckets = ({
  disabled,
  poolBuckets,
  dispatch,
  poolInfo,
  updateStartedFixture,
  myEntry,
  currentContest
}) => {
  // if( !poolBuckets || !myEntry ){ return null }
  if (!poolBuckets) {
    return null;
  }

  let listBuckets = _.map(poolBuckets[currentContest.id], (bucket, bucketIndex) => {
    const selectedPlayer = myEntry.hasOwnProperty('group_pool_picks') && myEntry.group_pool_picks.hasOwnProperty(currentContest.id)
        ? bucket.players.find((o) => o.id === myEntry.group_pool_picks[currentContest.id][bucket.id].pick)
        : null;

    let titleButton = (
      <div
        className="btn-group-pick-collapse  align flex-container  "
        onClick={() => {
          action.handleBucketF1(dispatch, bucket.id - 1);
        }}
      >
        <div className="flex-col col-80"> P{bucketIndex + 1}</div>
        <div className="flex-col">
          {bucket.isOpen ? (
            <i className="flex-container end align ion-arrow-up-b"></i>
          ) : (
              <i className="flex-container end align ion-arrow-down-b"></i>
            )}
        </div>
      </div>
    );

    if (selectedPlayer) {
      titleButton = (
        <div
          className="btn-group-pick-collapse  align flex-container between "
          onClick={() => {
            action.handleBucketF1(dispatch, bucketIndex);
          }}
        >
          <div className="flex-col col-10">P{bucketIndex + 1}</div>
          <div className={`team-data start flex-col col-15 uppercase`}>
            <img className="player-avatar" src={selectedPlayer.avatar} />
          </div>
          <div className='col-75 uppercase'>
            <div className='flex-container align'>
              <i className={`${selectedPlayer.logo} `}></i>
              <span className='font10 overflow-words'>{selectedPlayer.name}</span>
            </div>
            <div className='flex-container between align'>
              <span className='font8'>{selectedPlayer.team_name}</span>
              <img src={selectedPlayer.alt_avatar} style={{width: '80px', height: 'auto'}} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div key={bucketIndex} className="bucket-item">
        {titleButton}
        <div className="flex-col col-no-padding">
          {bucket.isOpen ? (
            <ButtonsPlayers
              selectedPlayer={selectedPlayer}
              disabled={disabled}
              myEntry={myEntry}
              bucketId={bucket.id}
              players={bucket.players.filter((item) => item.bucket === null)}
              dispatch={dispatch}
              currentContest={currentContest}
              poolBuckets={poolBuckets}
            />
          ) : null}
        </div>
      </div>
    );
  });
  return (
    <div className="flex-col col-no-padding">
      <div className="flex-row title-quiniela sub">
        <div className="flex-col title-wrapper">Picks</div>
      </div>
      {listBuckets}
    </div>
  );
};

/**
 * This component Renders the title and the list of picks
 */
const PicksF1 = ({ disabled, poolInfo, poolBuckets, dispatch, myEntry, bucketKeys, currentContest, groupPicks, allEntries, players }) => {

  React.useEffect(() => {
    if (allEntries && allEntries.group_pool_entry && allEntries.group_pool_entry.group_pool_picks.hasOwnProperty(currentContest.id)) {
      // clean all buckets
      cleanBuckets();

      // set players from entries user
      allEntries.group_pool_entry.group_pool_picks[currentContest.id].map((entrie) => {
        players[entrie.pick].bucket = entrie.bucket_number
      });
    } else {
     cleanBuckets();
    }

    function cleanBuckets() {
      _.forEach(players, (player, id) => {
        player.bucket = null;
      });
    }
  }, [allEntries]);

  return (
    <div>
      {currentContest.status !== 'upcoming' ? (
        <PresentationPicksF1
          poolInfo={poolInfo}
          bucketKeys={bucketKeys}
          poolBuckets={poolBuckets}
          myEntry={myEntry}
          dispatch={dispatch}
          currentContest={currentContest}
          groupPicks={groupPicks}
        />
      ) : (
          <React.Fragment>
            <ListBuckets
              bucketKeys={bucketKeys}
              disabled={disabled}
              poolInfo={poolInfo}
              poolBuckets={poolBuckets}
              myEntry={myEntry}
              dispatch={dispatch}
              currentContest={currentContest}
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default PicksF1;
