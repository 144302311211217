import React from 'react';
import API from '../../utils/api';
import Utils from '../../utils/quinielasUtils';
import utils from '../../utils/utils';
import bracketUtils from '../../utils/bracketUtils';
import survivorUtils from '../../utils/survivorUtils';
import gaUtils from '../GAWrapper';
import * as Sentry from '@sentry/browser';
import _ from 'underscore';
import { ErrorMessages, DisplayFlag, DisplaySport } from '../../utils/quinielasComponents';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormControl, Modal, ControlLabel, Checkbox } from 'react-bootstrap/lib';
import groupPoolActions from '../QuinielaGroupsUI/actions';
import numeral from 'numeral';
import billete from '../../assets/images/cash1-50.png';
import pickcoin from '../../assets/images/pickcoin1-50.png';
import pickcoinPlata from '../../assets/images/pickcoin1-50-plata.png';
import freeRollIcon from '../../assets/images/currency-gift.png';
import tickets from '../../assets/images/silver_ticket_pick_empty.png';
import iconClose from '../../assets/images/icon-close.png';
import line from '../../assets/images/lineaIcon-50.png';
import garantizada from '../../assets/images/garantizadaIcon-50.png';
import natural from '../../assets/images/naturalIcon-50.png';
import bracket from '../../assets/images/bracketIcon.png';

import AbonarSaldo from '../AbonarSaldo';

import './registerPanel.scss';
import { publish } from '../../utils/events';

/**
 * The title of the panel
 * @param { Object } pool
 * @memberof RegisterPanel
 */
const TitleRegisterPanel = ({ pool }) => {
  let conditionalComponent = null;

  switch (pool.entry_currency) {
    case 'real':
      conditionalComponent = (
        <span className="flex-row row-center row-no-padding">
          <span className="title-wrapper">
            <img src={billete} className="pool-icons " />
            {` ${numeral(pool.real_entry_fee / 100).format('$0,000.00')} `}
          </span>
          <span>
            {`+${numeral(pool.commission_fee / 100).format('$0,000.00')}`}
            <small>MXN</small>
          </span>
        </span>
      );
      break;
    case 'pickcoin':
      conditionalComponent = (
        <span className="flex-row row-center row-no-padding">
          <span className="title-wrapper yellow-text">
            {` ${numeral(pool.real_entry_fee / 100).format('0,000.00')} `}
          </span>
          <span className='yellow-text'>
            {`+${numeral(pool.commission_fee / 100).format('0,000.00')}`}
            <small>PC</small>
          </span>
        </span>
      );
      break;
    case 'referral':
      conditionalComponent = (
        <span className="flex-row row-center row-no-padding">
          <span className="title-wrapper">
            {` ${numeral(pool.real_entry_fee / 100).format('0,000.00')} `}
          </span>
          <span>{`+${numeral(pool.commission_fee / 100).format('0,000.00')}`}</span>
          <small>TK</small>
        </span>
      );
      break;
    case 'freeroll':
      conditionalComponent = (
        <span className="flex-row row-center row-no-padding">
          <span className="title-wrapper">
            {` ${numeral(pool.real_entry_fee / 100).format('0')} `}
          </span>
          <span>{`+${numeral(pool.commission_fee / 100).format('0')}`}</span>
        </span>
      );
      break;
  }

  return conditionalComponent;
};

/**
 *This component makes the calculation of the max registers that the user can do
 * with his available balance
 * if the this.props.pool.entry_currency is null the calculations has to be made to realMoney
 * @param { Array }  currentTickets
 * @param { Boolean }  enoughBalance
 * @param { Object }  currentEntries
 * @param { Boolean }  maxEntries
 * @param { Boolean }  maxCapacity
 * @param { Number }  maxMultyEntries
 * @param { Object }  myEntries
 * @param { String }  payType
 * @param { Boolean }  pickNgo
 * @param { Boolean }  isFull
 * @param { Number }  entryFee
 * @param { Object }  userData
 * @param { Object }  userBalance
 * @param { Number }  cantRegistros
 * @memberof RegisterPanel
 * **/
const MaxRegisterCant = ({
  currentTickets,
  enoughBalance,
  currentEntries,
  maxEntries,
  maxCapacity,
  maxMultyEntries,
  myEntries,
  payType,
  pickNgo,
  isFull,
  entryFee,
  userData,
  userBalance,
  cantRegistros,
  game
}) => {

  let bal;
  let numRegistros;
  let listOptions = [];
  let conditionalComponent = null;
  let currentTicketsLength =
    currentTickets === null || currentTickets.length === 0 ? 0 : currentTickets.length;
  /**
   ** It has max capacity
   **/
  if (maxCapacity) {
    console.log('It has max capacity');
    if (isFull) {
      console.log('It is full');
      conditionalComponent = (
        <div className="fullQuina">Lo sentimos pero esta quiniela ya está llena.</div>
      );
    } else {
      // console.log('It has room ')
      if (payType === null || payType === 'real') {
        bal = userBalance.balanceReal;
      } else if (payType === 'mixed') {
        bal = userBalance.balancePcReal + userBalance.balanceReal;
      } else if (payType === 'pickcoin') {
        bal = userBalance.balancePc;
      } else if (payType === 'referral') {
        bal = userBalance.balanceReferral;
      } else {
        bal = userBalance.balanceFreeroll;
      }
      // console.log( numRegistros );
      // console.log( bal / entryFee );
      // console.log( entryFee );
      // console.log( currentEntries );

      numRegistros = Math.ceil(bal / entryFee) - currentEntries + currentTicketsLength;

      if (maxEntries - currentEntries < numRegistros) {
        console.log('Menos registros que entradas disponibles');
        numRegistros = maxEntries - currentEntries;
      }
      // numRegistros = numRegistros >= 500 ? (numRegistros = 500) : null;
      if (numRegistros >= 500) {
        numRegistros = 500;
      }

      console.log(numRegistros);

      for (let i = 1; i <= numRegistros; i++) {
        // console.log(i);
        listOptions.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      console.log(listOptions, numRegistros, maxEntries);
      if (numRegistros === 0 || !enoughBalance) {
        conditionalComponent = (
          <span>No puedes registrarte a esta quiniela, tu balance no es suficiente</span>
        );
      } else if (numRegistros > 0) {
        conditionalComponent = (
          <FormControl
            className="select-cantidad-registros"
            onChange={cantRegistros}
            componentClass="select"
            placeholder="select"
          >
            {listOptions}
          </FormControl>
        );
      }
    }
  } else if (maxMultyEntries > 0) {
    if (payType === null || payType === 'real') {
      bal = userBalance.balanceReal;
    } else if (payType === 'mixed') {
      bal = userBalance.balancePcReal + userBalance.balanceReal;
    } else if (payType === 'pickcoin') {
      bal = userBalance.balancePc;
    } else if (payType === 'referral') {
      bal = userBalance.balanceReferral;
    } else {
      bal = userBalance.balanceFreeroll;
    }

    numRegistros = Math.floor(bal / entryFee);
    numRegistros = !currentTickets ? numRegistros : numRegistros + currentTickets.length;
    if (numRegistros >= maxMultyEntries) {
      numRegistros = maxMultyEntries;
    }

    console.log(numRegistros, bal);
    console.log(maxMultyEntries);

    // numRegistros = numRegistros >= maxMultyEntries ? (numRegistros = maxMultyEntries) : null;
    if (numRegistros >= maxMultyEntries) {
      numRegistros = maxMultyEntries;
    }
    // (myEntries !== null && myEntries.length > 0) && (numRegistros = numRegistros - myEntries.length) || null;
    console.log(myEntries);
    if (myEntries && myEntries.length > 0) {
      numRegistros = numRegistros - myEntries.length;
    }
    for (let i = 1; i <= numRegistros; i++) {
      // console.log(i);
      listOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    console.log(listOptions, numRegistros, enoughBalance, maxMultyEntries);
    if (numRegistros === 0 || !enoughBalance) {
      let label = 'No puedes registrarte a esta quiniela, tu balance es menor a la entrada';

      if (myEntries && maxMultyEntries > 0) {
        label = `No puedes registrarte más de ${maxMultyEntries} veces a esta quiniela`;
      }
      conditionalComponent = <span>{label}</span>;
    } else if (numRegistros > 0) {
      // console.log('Selecting Qnty');
      conditionalComponent = (
        <FormControl
          className="select-cantidad-registros"
          onChange={cantRegistros}
          componentClass="select"
          placeholder="select"
        >
          {listOptions}
        </FormControl>
      );
    }
  } else {
    /**
     **dont have a limit
     **/
    if (payType === null || payType === 'real') {
      bal = userBalance.balanceReal;
    } else if (payType === 'mixed') {
      bal = userBalance.balancePcReal + userBalance.balanceReal;
    } else if (payType === 'pickcoin') {
      bal = userBalance.balancePc;
    } else if (payType === 'referral') {
      bal = userBalance.balanceReferral;
    } else {
      bal = userBalance.balanceFreeroll;
    }

    if (entryFee === 0) {
      numRegistros = 500;
    } else {
      numRegistros = Math.floor(bal / entryFee);
      numRegistros = !currentTickets ? numRegistros : numRegistros + currentTickets.length;
    }

    if (game.entry_currency === 'ticket') {
      numRegistros = currentTicketsLength
    }
  
    if (parseInt(numRegistros) >= 500) {
      numRegistros = 500;
    }

    //numRegistros = numRegistros >= 500 ? (numRegistros = 500) : null;
    for (let i = 1; i < numRegistros + 1; i++) {
      // console.log(i);
      listOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    if (numRegistros === 0) {
      conditionalComponent = (
        <span>No puedes registrarte a esta quiniela, tu balance es menor a la entrada</span>
      );
    } else if (numRegistros > 0) {
      conditionalComponent = (
        <FormControl
          className="select-cantidad-registros"
          onChange={cantRegistros}
          componentClass="select"
          placeholder="select"
        >
          {listOptions}
        </FormControl>
      );
    }
  }
  return conditionalComponent;
};

/**********************
 *
 **********************/

/**
 * This component show the balance taking into account the tickets and the balance of the user
 * @memberof RegisterPanel
 *  */

const ShowBalance = ({
  entryCurrency,
  entryFee,
  entryFees,
  userBalanceReal,
  userBalancePc,
  userBalanceFreeroll,
  userBalanceReferral,
  cantRegistros,
  cantTickets,
}) => {
  let currentBalance = null;

  let currencyIcon = null;

  let currencyAbbrev = null;
  let currencyFormat = null;

  switch (entryCurrency) {
    case 'real':
      currentBalance = userBalanceReal;
      currencyIcon = <img className="pool-icons" src={billete} />;
      currencyAbbrev = 'MXN';
      currencyFormat = '$0,000.00';
      break;
    case 'pickcoin':
      currentBalance = userBalancePc;
      currencyIcon = <img className="pool-icons" src={pickcoin} />;
      currencyAbbrev = 'PC';
      currencyFormat = '0,000.00';

      break;
    case 'referral':
      currentBalance = userBalanceReferral;
      currencyIcon = <img className="pool-icons" src={pickcoinPlata} />;
      currencyAbbrev = 'TK';
      currencyFormat = '0';
      break;
    case 'freeroll':
      currentBalance = userBalanceFreeroll;
      currencyIcon = <img className="pool-icons" src={freeRollIcon} />;
      currencyAbbrev = '';
      currencyFormat = '0';
      break;
  }

  return (
    <div className="balance-usuario flex-row  row-no-padding register">
      <div className="flex-container balance-real-usr">
        <div className="flex-row row-center">
          <div className="balance-titulo flex-col col-no-padding col-40">
            <span>Balance Disponible:</span>
          </div>
          {!cantTickets || cantTickets.length === 0 ? null : (
            <div className="balance-tickets col-no-padding flex-col col-20">
              <div className="tickets-data flex-row ">
                <span className="flex-container align">
                  <span>[{cantTickets.length}]</span>
                  <img src={tickets} className="pool-icons " />
                </span>
              </div>
            </div>
          )}
          <div className="balance-datos col-no-padding flex-col ">
            <div className="balance-data">
              <span className="registro-balance-icon">{currencyIcon}</span>
              <span className={currentBalance === 0 ? 'empty-balance' : null}>
                {numeral(currentBalance / 100).format(currencyFormat)}{' '}
                <small>{currencyAbbrev}</small>
              </span>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="balance-titulo flex-col col-no-padding col-40 ">
            <span>Se descontará de tu cuenta:</span>
          </div>
          {!cantTickets || cantTickets.length === 0 ? null : (
            <div className="balance-tickets col-no-padding flex-col col-20">
              <div className="tickets-data flex-row ">
                <span className="flex-container align">
                  <span>[{entryFees.tickets}]</span>
                  <img src={tickets} className="pool-icons " />
                </span>
              </div>
            </div>
          )}
          <div className="balance-datos col-no-padding flex-col ">
            <div className="balance-data">
              <span className="registro-balance-icon">{currencyIcon}</span>
              <span className={currentBalance === 0 ? 'empty-balance' : null}>
                {numeral((cantRegistros * entryFee) / 100).format(currencyFormat)}{' '}
                <small>{currencyAbbrev}</small>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 *
 * @class RegisterPanel
 * @extends {React.Component}
 */
class RegisterPanel extends React.Component {
  constructor(props) {
    super(props);
    let payT = null;

    if (this.props.pool && this.props.pool.entry_currenty !== undefined) {
      payT = this.props.pool.entry_currenty;
    } else {
      payT = this.props.entry_currenty;
    }
    this.state = {
      cantRegistros: 1,
      errorCupo: false,
      logged: this.props.logged,
      payType: this.props.payType === null ? null : payT,
      pool: this.props.pool,
      redirectLobbyPool: false,
      redirectLogin: false,
      registrationData: this.props.temporalEntry,
      respuestas: null,
      submitting: false,
      enoughBalance: false,
      samePicks: false,
      mounted: false,
      tickets: null,
      showAbonarPop: false,
      showPanel: this.props.showPanel,
      updateStateFunc: this.props.updateStateFunc,
      userData: this.props.userData,
      componentReady: false,
      entryFees: {
        tickets: 0,
        full_fee: 0,
        real_fee: 0,
        commission_fees: 0,
      },
    };
    this.disableClickedButton = Utils.disableClickedButton.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.redirect = this.redirect.bind(this);
    this.cantRegistros = this.cantRegistros.bind(this);
    this.changePayment = this.changePayment.bind(this);
    this.registration = this.registration.bind(this);
    this.reRegistration = this.reRegistration.bind(this);
    this.abonarSaldoPanel = this.abonarSaldoPanel.bind(this);
    this.updateAbonarPop = this.updateAbonarPop.bind(this);
    this.resetData = this.resetData.bind(this);
    this.handleSamePicks = this.handleSamePicks.bind(this);
    this.setSport = Utils.setSport.bind(this);
    this.registraBracket = bracketUtils.registraBracket.bind(this);
    this.registraBracketTennis = bracketUtils.registraBracketTennis.bind(this);
    this.registraSurvivor = survivorUtils.registraSurvivor.bind(this);

    // console.log('Register Panel',   this);

    this.calculateEntryFees = (game, numEntries, tickets) => {
      let realNumEntries = numEntries;
      let ticketEntries = 0;

      if (tickets && tickets.length) {
        if (numEntries <= tickets.length) {
          ticketEntries = numEntries;
          realNumEntries = 0;
        } else if (numEntries > tickets.length) {
          ticketEntries = tickets.length;
          realNumEntries = numEntries - ticketEntries;
        }
      }

      let newEntryFees = {
        tickets: ticketEntries,
        full_fee: game.entry_fee * realNumEntries,
        real_fee: game.real_entry_fee * realNumEntries,
        commission_fee: game.commission_fee * realNumEntries,
      };
      this.setState({
        componentReady: true,
        entryFees: newEntryFees,
      });
    };

    this.callbackBracketRegister = (response) => {
      console.log('%c ResponseBracket Register', 'color:rgb(11, 173, 115); font-size:1.2em;');
      console.log(response);
      const responseData = response.data;
      /*********************
       * Successfull Register!
       *********************/
      if (responseData.hasOwnProperty('success') && responseData.success) {
        // API.trackPoolRegisterFB(this.props.pool);
        // gaUtils.trackUserGameRegistration(this.props.pool, 'bracket');
        this.props.updateUserInfo();
        this.setState(
          {
            newEntryID: responseData.bracketEntries[0].id,
            respuestas: responseData.error,
            submitting: false,
            redirectLobbyBracket: this.props.refreshAfterGuardar === undefined ? true : false,
            errorCupo: false,
          },
          () => {
            /**
             * First Register of the User
             */
            if (!this.props.userData.entry_count > 0 && this.props.userData.is_new) {
              console.log('First Register show Tutorial');
              // this.props.showTutorial();
              if (this.props.closePanel !== undefined) {
                this.props.closePanel();
              }
              if (this.props.updateUserInfo !== undefined) {
                this.props.updateUserInfo('isNewRegister');
              }
              if (this.props.handleConfirmation !== undefined) {
                this.props.handleConfirmation(this.state.newEntryID);
              }
              if (this.props.returnErrors !== undefined) {
                this.props.returnErrors(true);
              }
            } else {
              console.log('NO First Register');
              /**
               * Not first register
               */
              if (this.props.returnErrors !== undefined) {
                this.props.returnErrors(true);
              }
              if (this.props.refreshAfterGuardar !== undefined && this.props.refreshAfterGuardar !== null) {
                let register = 'reRegister';
                console.log("Refresh function")
                this.props.refreshAfterGuardar(response.data.bracketEntries[0].id, register);
              }
              if (this.props.updateUserInfo !== undefined) {
                //this.props.updateUserInfo();
              }
              if (this.props.handleConfirmation !== undefined) {
                console.log("Handle confirmation function");
                this.props.handleConfirmation(this.state.newEntryID);
              }
            }
          }
        );
        // console.log("BracketRegister", response);
      } else {
        /**
         *Error in the registration process
         **/
        console.log('Error en tu registro', response);
        //let userData = utils.getUserInfo();

        this.setState(
          {
            respuestas: response.data.error,
            errorCupo: true,
            submitting: false,
          },
          () => {
            if (this.props.returnErrors !== undefined) {
              this.props.returnErrors(false);
            }
            if (this.props.updateUserInfo !== undefined) {
              this.props.updateUserInfo();
            }
            if (this.props.handleConfirmation !== undefined) {
              this.props.handleConfirmation();
            }
          }
        );
        console.log('BracketRegister', response);
      }
    };

    this.callbackRegistraSurvivor = (response) => {
      console.log('%c Response Survivor Register', 'color:rgb(11, 173, 115); font-size:1.2em;');
      console.log(response);

      if (response === 'Error') {
        this.setState({
          errorCupo: true,
          submitting: false,
        });
      } else {
        const responseData = response.data;
        /*********************
         * Successfull Register!
         *********************/
        if (responseData.hasOwnProperty('success') && responseData.success) {
          // API.trackPoolRegisterFB(this.props.pool);
          // gaUtils.trackUserGameRegistration(this.props.pool, 'survivor');

          this.props.updateUserInfo();
          this.setState(
            {
              newEntryID: responseData.survivorEntries[0].id,
              respuestas: responseData.error,
              submitting: false,
              redirectLobbyPool: this.props.refreshAfterGuardar === undefined ? true : false,
              errorCupo: false,
              cantRegistros: 1,
            },
            () => {
              /**
               * First Register of the User
               */
              if (!utils.getUserInfo().entry_count > 0 && utils.getUserInfo().is_new) {
                console.log('First Register show Tutorial');
                if (this.props.closePanel !== undefined) {
                  this.props.closePanel();
                }
                if (this.props.updateUserInfo !== undefined) {
                  this.props.updateUserInfo('isNewRegister');
                }
                if (this.props.handleConfirmation !== undefined) {
                  this.props.handleConfirmation(this.state.newEntryID);
                }
                if (this.props.returnErrors !== undefined) {
                  this.props.returnErrors(true);
                }
              } else {
                /**
                 * Not first register
                 */
                if (this.props.returnErrors !== undefined) {
                  this.props.returnErrors(true);
                }

                if (this.props.isPopUp) {
                  if (this.props.handleRedirectToLobby) {
                    console.log('redirection!');
                    this.props.handleRedirectToLobby(response.data.survivorEntries[0].id);
                  } else {
                    console.log('STOP');
                    window.location = `/survivor/${this.props.pool.slug}/${this.props.pool.id}`;
                  }
                } else if (this.props.refreshAfterGuardar !== undefined) {
                  this.props.refreshAfterGuardar(response.data);
                }
                if (this.closePanel) {
                  this.closePanel();
                }
                if (this.props.updateUserInfo !== undefined) {
                  this.props.updateUserInfo();
                }
                if (this.props.handleConfirmation !== undefined) {
                  this.props.handleConfirmation(this.state.newEntryID);
                }
              }
            }
          );
          // console.log("BracketRegister", response);
        } else {
          /**
           *Error in the registration process
           **/
          console.log('Error en tu registro', response);
          let userData = utils.getUserInfo();

          this.setState(
            {
              respuestas: response.data.error,
              errorCupo: true,
              submitting: false,
            },
            () => {
              if (this.props.returnErrors !== undefined) {
                this.props.returnErrors(false);
              }
              if (this.props.updateUserInfo !== undefined) {
                this.props.updateUserInfo();
              }
              if (this.props.handleConfirmation !== undefined) {
                this.props.handleConfirmation();
              }
            }
          );
          console.log('SurvivorRegister', response);
        }
      }
    };
  }
  /**
   * This function Sets Same Picks to true
   * */
  handleSamePicks() {
    this.setState({
      samePicks: true,
    });
  }

  /**
   * This fucntion reset the default data of the Register Panel
   */
  resetData() {
    this.setState({
      redirectLogin: false,
      registrationData: this.props.temporalEntry,
      cantRegistros: 1,
      payType: this.props.payType,
      redirectLobbyPool: false,
      showAbonarPop: false,
      entryFees: {
        tickets: 0,
        full_fee: 0,
        real_fee: 0,
        commission_fees: 0,
      },
      componentReady: true,
      errorCupo: false,
      respuestas: null,
    });
  }

  /**
   * This function Sets the Abonar Panel Active
   * */
  abonarSaldoPanel() {
    this.setState({
      showAbonarPop: true,
    });
  }

  /**
   * This function handle the qnty of the REgisters that the user selects
   * It uses the value of the clicked Element
   * */
  cantRegistros(e) {
    this.setState(
      {
        cantRegistros: e.target.value,
      },
      () => {
        this.calculateEntryFees(this.props.pool, this.state.cantRegistros, this.state.tickets);
      }
    );
  }

  /**
   * This function closes the REgistration Panel
   * */
  close() {
    // console.log('Close panel');
    this.setState({
      showPanel: false,
    });
  }

  /**
   * This Function Change the Payment Method
   * */
  changePayment(value) {
    // console.log('ChangePay', value, this.state);
    this.setState({
      payType: value,
    });
  }

  /**
   * This function Open the Registration Panel
   * */
  open() {
    // console.log('RegPanel, open', this.state);
    this.setState({
      showPanel: true,
      payType: this.state.payType,
    });
  }

  /**
   * This function Sends the Complete registration  t eht quiniela to the server
   * */
  registration() {
    console.log('%c Registering user', 'background:#000; color:#fff; font-size:1.5em;');

    if (this.state.submitting === true) {
      return;
    }
    this.setState({ submitting: true, errorMessage: null });
    // console.log('entryId', this.props.entryId);
    /**
     * Picks available
     */
    if (this.props.registerPicks !== null && this.props.registerPicks !== undefined) {
      let temporalRegisterPicks = this.props.registerPicks;
      let firstRegister = this.props.entryId === null ? true : false;
      console.log('%c temporalRegisterPicks', 'color:rgb(65, 62, 26);font-size:1.5em;');
      // console.log(temporalRegisterPicks)
      /**372
       * First Register
       */
      if (firstRegister) {
        console.log('First Register');
        /**
         *Set data correctly according if it is a pool or a bracket
         **/
        if (this.props.pool.game_type === 'bracket') {
          let bracketPicksKeys = Object.keys(temporalRegisterPicks);
          console.log(bracketPicksKeys, this.props.matches);
          let temporalBracketPicks = {};

          let stagesList = _.values(this.props.matches);
          // console.log('Stages list', stagesList );
          let fullMatches = [];
          stagesList.forEach((stage) => {
            stage.forEach((stageMatch) => {
              fullMatches.push(stageMatch);
            });
          });

          bracketPicksKeys.forEach((bracketKey, bracketIdx) => {
            console.log('FullMatches', fullMatches, bracketKey);
            let myMatch = fullMatches.find((match) => {
              if (match.number === bracketKey) {
                return match;
              }
            });
            if (!myMatch) {
              return;
            }
            // let myMatch = fullMatches[bracketKey];
            console.log('MyMatch', myMatch);
            console.log('MyMatch ', myMatch.id);
            if (!temporalRegisterPicks[bracketKey]) {
              temporalBracketPicks[bracketKey] = null;
            } else {
              let bracketEntryId = this.props.selectedEntryData
                ? this.props.selectedEntryData.id
                : null;
              temporalBracketPicks[bracketKey] = {
                bracket_id:
                  this.props.bracketInfo !== undefined
                    ? this.props.bracketInfo.id
                    : this.props.pool.id,
                bracket_entry_id: bracketEntryId,
                pick: temporalRegisterPicks[bracketKey].id,
                match_id: myMatch.id,
              };
            }
          });

          temporalRegisterPicks = {};
          temporalRegisterPicks['bracket_picks'] = temporalBracketPicks;
          temporalRegisterPicks['bracket_entry_id'] = null;
          temporalRegisterPicks['bracket_id'] = this.props.pool.id;
          temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
          temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
          temporalRegisterPicks['use_same_picks'] = this.state.samePicks;
        } else if (this.props.pool.game_type === 'survivor') {
          console.log('Register First in Survivor');
          console.log(this.props);
        } else {
          console.log('First RegisterPool', temporalRegisterPicks);
          this.setState({ registrationData: temporalRegisterPicks });
        }
      } else if (this.props.pool.game_type === 'survivor') {
        console.log('Registering to a Survivor');
        console.log(this.props);
      } else if (this.props.pool.game_type === 'group_pool') {
        console.log('weird');
      } else {
        /**
         * It is a Multy Entry
         */
        // console.log('Multy Entry');
        if (this.props.pool.game_type === 'bracket') {
          let bracketPicksKeys = Object.keys(temporalRegisterPicks);
          // console.log(bracketPicksKeys);
          let temporalBracketPicks = {};
          bracketPicksKeys.forEach((bracketKey, bracketIdx) => {
            // console.log('bracketPicks', temporalRegisterPicks[bracketKey] );
            if (!temporalRegisterPicks[bracketKey]) {
              temporalBracketPicks[bracketKey] = null;
            } else {
              temporalBracketPicks[bracketKey] = {
                bracket_id: this.props.pool.id,
                bracket_entry_id: null,
                pick: temporalRegisterPicks[bracketKey].id,
                match_id: temporalRegisterPicks[bracketKey].match_id,
              };
            }
          });

          temporalRegisterPicks = {};
          temporalRegisterPicks['bracket_picks'] = temporalBracketPicks;
          temporalRegisterPicks['bracket_entry_id'] = null;
          temporalRegisterPicks['bracket_id'] = this.props.pool.id;
          temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
          temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
          temporalRegisterPicks['use_same_picks'] = this.state.samePicks;
        } else {
          /**
           * Map Picks
           */
          if (temporalRegisterPicks.picks || temporalRegisterPicks.picks.length) {
            let picksKeys = Object.keys(temporalRegisterPicks.picks);
            let tempPicks = [];
            picksKeys.forEach((key) => {
              temporalRegisterPicks.picks[key].entry_id = null;
              temporalRegisterPicks.picks[key].id = null;
              if (this.state.samePicks === false) {
                temporalRegisterPicks.picks[key].pick = null;
              }
            });
          }
          /**
           * Map Ties
           */
          if (
            temporalRegisterPicks.user_tiebreakers ||
            temporalRegisterPicks.user_tiebreakers.length
          ) {
            let tiesKeys = Object.keys(temporalRegisterPicks.user_tiebreakers);
            let tempTies = [];
            tiesKeys.forEach((key) => {
              temporalRegisterPicks.user_tiebreakers[key].entry_id = null;
              temporalRegisterPicks.user_tiebreakers[key].id = null;
              if (this.state.samePicks === false) {
                console.log();
                temporalRegisterPicks.user_tiebreakers[key].pick = null;
                temporalRegisterPicks.user_tiebreakers[key].value = null;
              }
            });
          }
          /**
           * Map Props
           */
          if (temporalRegisterPicks.picks_props || temporalRegisterPicks.picks_props.length) {
            let picksPropsKeys = Object.keys(temporalRegisterPicks.picks_props);
            let tempPProps = [];
            picksPropsKeys.forEach((key) => {
              temporalRegisterPicks.picks_props[key].entry_id = null;
              temporalRegisterPicks.picks_props[key].id = null;
              if (this.state.samePicks === false) {
                temporalRegisterPicks.picks_props[key].pick = null;
              }
            });
          }

          temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
          temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
          temporalRegisterPicks['entry_id'] = null;
          temporalRegisterPicks['use_same_picks'] = this.state.samePicks;
        }
        //Delete the entry_id from the availablePicks
        // console.log("Con props registrados ReRegister", temporalRegisterPicks, 'PROPS',this.props,'Same Picks', this.state.samePicks);

        console.log('Temporal', temporalRegisterPicks);
        this.setState({ registrationData: temporalRegisterPicks });
      }

      /**
       * Make the CORRECT call to the api accordingly the game_type.
       */
      console.dir(this.props);
      console.log('register', this.props.pool.game_type);

      if (this.props.pool.game_type === 'bracket') {
        console.log('Picksadded in bracket');
        console.log(temporalRegisterPicks);
        API.postSaveBracketPicks(temporalRegisterPicks)
          .then((response) => {
            this.callbackBracketRegister(response);
          })
          .catch((err) => {
            console.error(err);
            let userData = utils.getUserInfo();
            Sentry.captureException(err, { extra: 'Error Saving Bracket' });
            this.setState({
              submitting: false,
              errorGuardarPicks: true,
            });
          });
      } else if (this.props.pool.game_type === 'group_pool') {
        //  (this.props.registerPicks.hasOwnProperty('group_pool_picks')) {
        console.log(this.props);
        let groupPoolID = !this.props.quinaID ? this.props.pool.id : this.props.grupoQuinaID;
        // console.log('GroupPoolId',groupPoolID );
        // console.log('PayType', this.props );

        if (this.props.reRegister) {
          console.log('reRegister');
          let survivorId;
          let emptyRegisterPicks = {
            same_picks: this.state.samePicks,
            num_entries: this.state.cantRegistros,
            id: null,
            group_pool_entry_id: null,
            group_pool_id: survivorId,
            group_pool_picks: [],
            group_pool_user_tiebreakrs: [],
            user_id: utils.getUserInfo().id,
          };

          groupPoolActions
            .postSavePicks(this.props.dispatch, emptyRegisterPicks)
            .then((response) => {
              console.log(response);
              if (response) {
                // close Panel
                // Update userBalance
                // init new entry
                // Change location to new entry
                groupPoolActions.handleRegistration(this.props.dispatch);
                // groupPoolActions.redirectTo( this.props.dispatch, redirectTo );
                groupPoolActions.handleOverlay(this.props.dispatch, false, true);

                groupPoolActions.getPoolEntry(
                  this.props.dispatch,
                  response.data.groupPoolEntries[0].id
                );
                groupPoolActions.addNewEntriesToStandings(
                  this.props.dispatch,
                  response.data.groupPoolEntries
                );
                if (this.props.updateUserBalance) {
                  let currentBalanceSpent =
                    this.props.pool.entry_fee * response.data.groupPoolEntries.length;

                  let currentBalance = utils.getUserBalance();

                  currentBalance.balanceReal = currentBalance.balanceReal - currentBalanceSpent;

                  this.props.updateUserBalance(currentBalance);
                }

                setTimeout(() => {
                  groupPoolActions.handleOverlay(this.props.dispatch, false, false);
                }, 1200);
              } else {
                groupPoolActions.handleOverlay(this.props.dispatch, true, false);
                console.error(response);
                // showError
              }
            });
        } else {
          let entryData = {};
          console.log('------------------------------');
          console.log(this.props);
          if (this.props.entryId) {
            console.log('WithRegister');
            entryData = { ...this.props.registerPicks };
            entryData.group_pool_entry_id = null;
            entryData.group_pool_picks = [];
            entryData.group_pool_user_tiebreakers = [];
            entryData.num_entries = this.state.cantRegistros;
          } else {
            console.log('WithoutRegister');
            entryData = { ...this.props.registerPicks };
            entryData.num_entries = this.state.cantRegistros;
          }

          groupPoolActions.postSavePicks(this.props.dispatch, entryData).then((response) => {
            if (response.success) {
              // gaUtils.trackUserGameRegistration(this.props.pool, 'group_pool');
              groupPoolActions.handleOverlay(this.props.dispatch, false, true);

              if (!this.props.entryId) {
                groupPoolActions
                  .getPoolEntry(this.props.dispatch, response.data.groupPoolEntries[0].id)
                  .then((responseEntry) => {
                    groupPoolActions.initializeEntry(
                      this.props.dispatch,
                      this.props.pool.id,
                      this.props.buckets,
                      this.props.bucketKeys,
                      this.props.ties,
                      response.data.groupPoolEntries[0],
                      true,
                      this.props.players,
                      this.props.currentContest,
                      this.props.payType,
                      this.props.contests,
                    );
                  });
              } else {
                groupPoolActions.initializeEntry(
                  this.props.dispatch,
                  this.props.pool.id,
                  this.props.buckets,
                  this.props.bucketKeys,
                  this.props.ties,
                  response.data.groupPoolEntries[0],
                  true,
                  this.props.players,
                  this.props.currentContest,
                  this.props.payType,
                  this.props.contests,
                );
              }
              groupPoolActions.updateLocation(
                this.props.pool.slug,
                this.props.pool.id,
                response.data.groupPoolEntries[0].id,
                false,
                this.props.match,
              );
              groupPoolActions.addNewEntriesToStandings(
                this.props.dispatch,
                response.data.groupPoolEntries
              );

              groupPoolActions.handleRegistration(this.props.dispatch);
              if (this.props.updateUserBalance) {
                let currentBalanceSpent =
                  this.props.pool.entry_fee * response.data.groupPoolEntries.length;

                let currentBalance = utils.getUserBalance();

                currentBalance.balanceReal = currentBalance.balanceReal - currentBalanceSpent;

                this.props.updateUserBalance(currentBalance);
              }

              setTimeout(() => {
                groupPoolActions.handleOverlay(this.props.dispatch, false, false);
              }, 1200);

              // Update userBalance
              // Change location to new entry
            } else {
              console.error(response);
              // showError
              groupPoolActions.handleOverlay(this.props.dispatch, true, false);
            }
          });
        }
      } else {
        temporalRegisterPicks.num_entries = this.state.cantRegistros;
        temporalRegisterPicks.use_same_picks = this.state.samePicks;
        console.log(' reasign canti temporalRegisterPicks', temporalRegisterPicks);
        API.postFastRegister(temporalRegisterPicks)
          .then((response) => {

            publish('UPDATE_MY_PICKS', 'justSave')

            if (utils.getUserInfo().is_new) {
              let tutorialOrganic = utils.getShowTutorialLobby();
              //HAndle tutorial lobby last step
              if (tutorialOrganic && !tutorialOrganic.show && tutorialOrganic.step === 3) {
                console.log('Next step of tutorial after register');
                console.log(tutorialOrganic);
                console.log(this.props);
                // this.props.handleTutorial( 'organic' );
                this.props.handleTutorialStep(3, 'organic', true);
              }
            }

            // console.log(
            //   'PostFastRegister:',
            //   temporalRegisterPicks,
            //   '\nfirstRegister:',
            //   firstRegister
            // );
            const responseData = response.data;
            // console.log(responseData);
            if (responseData.hasOwnProperty('success') && responseData.success) {
              if (this.props.pool.id === 12408) {
                // console.log('set invite from registration');
                utils.setShowInvitePanel();
              }

              // console.log('Success registro', temporalRegisterPicks);
              // console.log(this.props);

              if (responseData.entry_gift) {
                const entryGiftObj = {
                  entry_gift: responseData.entry_gift,
                  pool_id: responseData.poolId,
                };
                const popUpDepositObj = { pool_id: responseData.entry_gift.pool_id };

                console.log('Entry Gift', entryGiftObj);

                utils.setEntryGift(entryGiftObj);
                utils.setPopUpDeposit(popUpDepositObj);
              }

              // API.trackPoolRegisterFB(this.props.pool);
              // gaUtils.trackUserGameRegistration(this.props.pool, 'pool');

              this.props.updateUserInfo();
              this.setState(
                {
                  newEntryID: response.data.entryId,
                  respuestas: response.data.error,
                  submitting: false,
                  redirectLobbyPool: this.props.refreshAfterGuardar === undefined,
                  errorCupo: false,
                },
                () => {
                  if (this.props.updateAfterRegister) {
                    this.props.updateAfterRegister(response.data);
                  }
                  if (this.props.updateMyEntryData) {
                    this.props.updateMyEntryData(response.data);
                  }
                  if (this.props.updateUserInfo !== undefined) {
                    this.props.updateUserInfo('isNewRegister');
                  }
                  /**
                   * First Register of the User Show Tutorial
                   */
                  console.log(this.props.userData);
                  // console.log( utils.getUserInfo().activated );
                  if (!this.props.userData.activated && this.props.userData.is_new) {
                    console.log('First Register show Tutorial');
                    // this.props.showTutorial();
                    if (this.props.closePanel !== undefined) {
                      this.props.closePanel();
                    }
                    if (this.props.handleConfirmation !== undefined) {
                      this.props.handleConfirmation();
                    }
                    if (this.props.returnErrors !== undefined) {
                      this.props.returnErrors(true);
                    }
                  } else {
                    console.log('Not  First Register');
                    console.log(this.props);
                    /**
                     * Not first register
                     */
                    if (this.props.returnErrors !== undefined) {
                      this.props.returnErrors(true);
                    }
                    if (this.props.refreshAfterGuardar !== undefined) {
                      let register = 'reRegister';
                      this.props.refreshAfterGuardar(response.data.entryId, register);
                    }
                    if (this.props.updateUserInfo !== undefined) {
                      this.props.updateUserInfo();
                    }
                    if (this.props.handleConfirmation !== undefined) {
                      this.props.handleConfirmation();
                    }
                  }
                }
              );

              console.log('QuinielaRegister', response);
            } else {
              console.log('Error en tu registro', temporalRegisterPicks, response);
              let userData = utils.getUserInfo();
              Sentry.captureException(new Error(response.data.error), {
                extra: 'Error registering to Quina',
              });
              this.setState(
                {
                  respuestas: response.data.error,
                  errorMessage: response.data.error,
                  errorCupo: true,
                  submitting: false,
                },
                () => {
                  if (this.props.returnErrors !== undefined) {
                    this.props.returnErrors(false);
                  }
                  // if (this.props.updateUserInfo !== undefined) {
                  //   this.props.updateUserInfo();
                  // }
                  if (this.props.handleConfirmation !== undefined) {
                    this.props.handleConfirmation();
                  }
                }
              );
              console.log('QuinielaRegister', response);
            }
          })
          .catch((err) => {
            console.error(err);
            let userData = utils.getUserInfo();
            Sentry.captureException(err, {
              extra: 'Error in Register Panel firstEntry',
            });
            this.setState({
              submitting: false,
              errorGuardarPicks: true,
            });
          });
      }
    } else {
      /**
       * Picks not Available
       */
      console.log('NotDataAv');
      // console.log(this.props.registerPicks);
      // console.log(this.state.cantRegistros);
      let temporalRegisterPicks = {};
      if (this.props.pool.game_type === 'bracket') {
        console.log('NotData in bracket');
        let bracketPicksKeys = Object.keys(temporalRegisterPicks);
        // console.log(bracketPicksKeys);
        let temporalBracketPicks = {};
        bracketPicksKeys.forEach((bracketKey, bracketIdx) => {
          // console.log('bracketPicks', temporalRegisterPicks[bracketKey] );
          if (!temporalRegisterPicks[bracketKey]) {
            temporalBracketPicks[bracketKey] = null;
          } else {
            temporalBracketPicks[bracketKey] = {
              bracket_id: this.props.pool.id,
              bracket_entry_id: null,
              pick: temporalRegisterPicks[bracketKey].id,
              match_id: temporalRegisterPicks[bracketKey].match_id,
            };
          }
        });

        temporalRegisterPicks = {};
        temporalRegisterPicks['bracket_picks'] = temporalBracketPicks;
        temporalRegisterPicks['bracket_entry_id'] = null;
        temporalRegisterPicks['bracket_id'] = this.props.pool.id;
        temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
        temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
        temporalRegisterPicks['use_same_picks'] = this.state.samePicks;

        API.postSaveBracketPicks(temporalRegisterPicks)
          .then((response) => {
            this.callbackBracketRegister(response);
          })
          .catch((err) => {
            console.error(err);

            let userData = utils.getUserInfo();
            Sentry.captureException(err, { extra: 'Error Saving Bracket' });
            this.setState({
              submitting: false,
              errorGuardarPicks: true,
            });
          });
      } else if (this.props.pool.game_type === 'survivor') {
        console.log('NotData in Survivor');
        let survivorPicksKeys = Object.keys(temporalRegisterPicks);
        // console.log(survivorPicksKeys);
        let temporalBracketPicks = {};
        let temporalSurvivorPicks;
        survivorPicksKeys.forEach((survivorKey, survivorId) => {
          // console.log('bracketPicks', temporalRegisterPicks[bracketKey] );
          if (!temporalRegisterPicks[survivorKey]) {
            temporalSurvivorPicks[survivorKey] = null;
          } else {
            temporalSurvivorPicks[survivorKey] = {
              survivor_id: this.props.pool.id,
              survivor_entry_id: null,
              pick: temporalRegisterPicks[survivorKey].id,
            };
          }
        });

        temporalRegisterPicks = {};
        temporalRegisterPicks['survivor_picks'] = temporalBracketPicks;
        temporalRegisterPicks['survivor_entry_id'] = null;
        temporalRegisterPicks['survivor_id'] = this.props.pool.id;
        temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
        temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
        temporalRegisterPicks['use_same_picks'] = this.state.samePicks;

        API.postSaveSurvivorPicks(temporalRegisterPicks)
          .then((response) => {
            this.callbackRegistraSurvivor(response);
          })
          .catch((err) => {
            console.error(err);

            let userData = utils.getUserInfo();
            Sentry.captureException(err, { extra: 'Error Saving Survivor without picks' });
            this.setState({
              submitting: false,
              errorGuardarPicks: true,
            });
          });
      } else if (this.props.pool.game_type === 'group_pool') {
        console.log('Group Pool');
        let emptyRegisterPicks = {
          num_entries: this.state.cantRegistros,
          same_picks: this.state.samePicks,
          id: null,
          group_pool_entry_id: null,
          group_pool_id: this.props.pool.id,
          group_pool_picks: [],
          group_pool_user_tiebreakers: [],
          user_id: utils.getUserInfo().id,
        };

        if (this.props.dispatch) {
          console.log('HAsDispatch');
          groupPoolActions
            .postSavePicks(this.props.dispatch, emptyRegisterPicks)
            .then((response) => {
              console.log(response, this.props);
              if (response) {
                // gaUtils.trackUserGameRegistration(this.props.pool, 'group_pool');

                console.log(this.props);
                if (this.props.entryId) {
                  console.log('ReRegister', this.props.dispatch);
                  groupPoolActions.handleReRegistration(this.props.dispatch);
                } else {
                  groupPoolActions.handleRegistration(this.props.dispatch);
                }

                // let redirectTo =`/quiniela/quiniela/${this.props.quinaID}/${response.data.groupPoolEntries[0].id}`
                // groupPoolActions.redirectTo( this.props.dispatch, redirectTo );
                groupPoolActions.handleOverlay(this.props.dispatch, false, true);
                groupPoolActions.getPoolEntry(
                  this.props.dispatch,
                  response.data.groupPoolEntries[0].id
                );
                groupPoolActions
                  .getSimilarPools(this.props.dispatch, response.data.groupPoolEntries[0].id)
                  .then((similarPoolResponse) => {
                    console.log(similarPoolResponse);
                  });

                if (this.props.updateUserBalance) {
                  let currentBalanceSpent =
                    this.props.pool.entry_fee * response.data.groupPoolEntries.length;

                  let currentBalance = utils.getUserBalance();

                  currentBalance.balanceReal = currentBalance.balanceReal - currentBalanceSpent;

                  this.props.updateUserBalance(currentBalance);
                }
                setTimeout(() => {
                  groupPoolActions
                    .initializeEntry(
                      this.props.dispatch,
                      this.props.pool.id,
                      this.props.buckets,
                      this.props.bucketKeys,
                      this.props.ties,
                      this.props.selectedEntry,
                      true,
                      this.props.players,
                      this.props.currentContest,
                      this.props.entryCurrency,
                      this.props.contests,
                    )
                    .then((response) => {
                      console.log(response);
                    });
                  groupPoolActions.handleOverlay(this.props.dispatch, false, false);
                }, 1200);
              } else {
                groupPoolActions.handleOverlay(this.props.dispatch, true, false);

                console.error(response);
                // showError
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          console.log('HAsnt Dispatch');

          API.savePoolGroup(emptyRegisterPicks).then((response) => {
            console.log(response);
            if (response.data.success) {
              this.props.updateStateFunc();

              this.resetData();
              this.setState({
                respuestas: response.data.error,
                submitting: false,
                newEntryID: response.data.groupPoolEntries[0].id,
                redirectLobbyPool: this.props.refreshAfterGuardar === undefined ? true : false,
              });
            } else {
              console.log('Error en tu registro', temporalRegisterPicks);
              this.setState(
                {
                  errorMessage: response.data.error,
                  respuestas: response.data,
                  errorCupo: true,
                  submitting: false,
                },
                () => {
                  if (this.props.returnErrors !== undefined) {
                    this.props.returnErrors(false);
                  }
                  // if (this.props.updateUserInfo !== undefined) {
                  //   this.props.updateUserInfo();
                  // }
                  if (this.props.handleConfirmation !== undefined) {
                    this.props.handleConfirmation();
                  }

                  // let error = new Error( 'Error Register Group  Pool from Row', response.data  )
                  // throw 'Error Register Group  Pool from Row';
                  // Sentry.captureException( error );
                }
              );
            }
          });
        }
      } else {
        temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
        temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
        temporalRegisterPicks['pool_id'] = this.props.quinaID;
        console.log(temporalRegisterPicks);

        if (utils.getUserInfo().is_new) {
          let tutorialOrganic = utils.getShowTutorialLobby();
          //HAndle tutorial lobby last step
          if (tutorialOrganic && !tutorialOrganic.show && tutorialOrganic.step === 3) {
            console.log('Next step of tutorial after register');
            console.log(tutorialOrganic);
            console.log(this.props);
            // this.props.handleTutorial( 'organic' );
            this.props.handleTutorialStep(3, 'organic', true);
          }
        }

        API.postFastRegister(temporalRegisterPicks)
          .then((response) => {
            publish('UPDATE_MY_PICKS', 'justSave')

            this.setState({ submitting: true });
            const responseData = response.data;
            if (responseData.hasOwnProperty('success') && responseData.success) {
              if (this.props.pool.id === 12408) {
                console.log('set invite from registration');
                utils.setShowInvitePanel();
              }

              if (responseData.entry_gift) {
                const entryGiftObj = {
                  entry_gift: responseData.entry_gift,
                  pool_id: responseData.poolId,
                };
                const popUpDepositObj = { pool_id: responseData.entry_gift.pool_id };

                console.log('Entry Gift', entryGiftObj);

                utils.setEntryGift(entryGiftObj);
                utils.setPopUpDeposit(popUpDepositObj);
              }

              // API.trackPoolRegisterFB(this.props.pool);
              // gaUtils.trackUserGameRegistration(this.props.pool);
              console.log('completando tu registro vacio', temporalRegisterPicks, responseData);
              this.setState(
                {
                  respuestas: responseData.error,
                  submitting: false,
                  newEntryID: responseData.entryId,
                  redirectLobbyPool: this.props.refreshAfterGuardar === undefined ? true : false,
                },
                () => {
                  /**
                   * First Register
                   */
                  console.log('First Register', utils.getUserInfo());
                  if (!utils.getUserInfo().entry_count !== 0 || utils.getUserInfo().is_new) {
                    // console.log('First Register show Tutorial');
                    console.log('closePanel', this.props.closePanel);
                    console.log(this.state.redirectLobbyPool);
                    console.log(this.props.updateAfterRegister);

                    if (this.props.closePanel !== undefined) {
                      this.props.closePanel();
                    }
                    if (this.props.closeQuinielaPopUp !== undefined) {
                      this.props.closeQuinielaPopUp();
                    }
                    if (this.props.handleConfirmation !== undefined) {
                      this.props.handleConfirmation();
                    }
                    if (this.props.updateAfterRegister) {
                      this.props.updateAfterRegister(response.data);
                    }
                    if (this.props.refreshAfterGuardar !== undefined) {
                      let register = 'reRegister';
                      this.props.refreshAfterGuardar(response.data.entryId, register);
                    }
                    if (this.props.updateUserInfo !== undefined) {
                      this.props.updateUserInfo('isNewRegister');
                    }
                    if (this.props.updatePanelReRegister !== undefined) {
                      this.props.updatePanelReRegister();
                    }
                    if (this.props.returnErrors !== undefined) {
                      this.props.returnErrors(true);
                    }
                    // this.props.showTutorial();
                  } else {
                    /**
                     * Multy entry
                     */

                    if (this.props.returnErrors !== undefined) {
                      this.props.returnErrors(true);
                    }

                    if (this.props.refreshAfterGuardar !== undefined) {
                      let register = 'reRegister';
                      this.props.refreshAfterGuardar(response.data.entryId, register);
                    }
                    if (this.props.updateUserInfo !== undefined) {
                      this.props.updateUserInfo();
                    }
                    if (this.props.updateAfterRegister) {
                      this.props.updateAfterRegister(response.data);
                    }
                    if (this.props.handleConfirmation !== undefined) {
                      this.props.handleConfirmation();
                    }
                  }
                }
              );
              // console.log('QuinielaRegister', response);
            } else {
              console.log('Error en tu registro', temporalRegisterPicks);
              this.setState(
                {
                  errorMessage: response.data.error,
                  respuestas: response.data,
                  errorCupo: true,
                  submitting: false,
                },
                () => {
                  if (this.props.returnErrors !== undefined) {
                    this.props.returnErrors(false);
                  }
                  // if (this.props.updateUserInfo !== undefined) {
                  //   this.props.updateUserInfo();
                  // }
                  if (this.props.handleConfirmation !== undefined) {
                    this.props.handleConfirmation();
                  }
                }
              );
            }
          })
          .catch((err) => {
            console.error(err);
            Sentry.captureException(err, {
              extra: 'Erro RegisterPanel without picks',
            });
            this.setState({
              errorGuardarPicks: true,
              submitting: false,
            });
          });
        // console.log(this.state.respuestas);
      }
    }
    // console.log(this.state.respuestas);
  }

  /**
   * This function Sends the Complete registration  t eht quiniela to the server
   * */
  reRegistration() {
    console.log('POOLID', this.props.pool.id);
    console.log('%c Registering user', 'background:#000; color:#fff;');
    console.log(this.props.registerPicks, this.state.cantRegistros);
    if (this.props.registerPicks !== null) {
      console.log('Con props registrados', this.props.registerPicks);
      let temporalRegisterPicks = this.props.registerPicks;
      temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
      temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
      temporalRegisterPicks['entry_id'] = null;
      temporalRegisterPicks['use_same_picks'] = this.state.samePicks;
      console.log('Temporal', temporalRegisterPicks);
      this.setState({
        registrationData: temporalRegisterPicks,
        submitting: true,
      });
      console.log('temporalRegisterPicks', temporalRegisterPicks);
      API.postFastRegister(temporalRegisterPicks)
        .then((response) => {
          if (response.data.success) {
            // if ( this.props.pool.id === 12408 ) {
            //   utils.setShowInvitePanel();
            // }
            if (response.entry_gift) {
              const entryGiftObj = {
                entry_gift: response.entry_gift,
                pool_id: response.poolId,
              };
              const popUpDepositObj = { pool_id: response.entry_gift.pool_id };

              console.log('Entry Gift', entryGiftObj);

              utils.setEntryGift(entryGiftObj);
              utils.setPopUpDeposit(popUpDepositObj);
            }

            this.props.updateUserInfo();
            console.log('completando tu registro', response);
            this.setState(
              {
                submitting: false,
                respuestas: response.data.error,
                newEntryID: response.data.entryId,
                redirectLobbyPool: this.props.refreshAfterGuardar === undefined,
                errorCupo: false,
              },
              () => {
                if (this.props.returnErrors !== undefined) {
                  this.props.returnErrors(true);
                }
                if (this.props.refreshAfterGuardar !== undefined) {
                  let register = 'reRegister';
                  // if(this.props.withRegister){
                  //   register = 'save'
                  // }
                  this.props.refreshAfterGuardar(response.data.entryId, 'reRegister');
                }
                if (this.props.updateUserInfo !== undefined) {
                  this.props.updateUserInfo();
                }
                if (this.props.handleConfirmation !== undefined) {
                  this.props.handleConfirmation();
                }
              }
            );
            console.log('QuinielaRegister', response);
          } else {
            Sentry.captureException(new Error(response.data.error), {
              extra: 'Error in fast register Register Panel',
            });
            this.setState(
              {
                respuestas: response.data.error,
                errorCupo: true,
                submitting: false,
                newEntryID: response.data.entryId,
              },
              () => {
                if (this.props.returnErrors !== undefined) {
                  this.props.returnErrors(false);
                }
                if (this.props.updateUserInfo !== undefined) {
                  this.props.updateUserInfo();
                }
                if (this.props.handleConfirmation !== undefined) {
                  this.props.handleConfirmation();
                }
              }
            );
            console.log('QuinielaRegister', response);
          }
        })
        .catch((err) => {
          console.log('Error fast register');
          Sentry.captureException(err, {
            extra: 'Error fast register RegisterPanel with picks',
          });
          this.setState({
            errorGuardarPicks: true,
            submitting: false,
          });
        });
    } else {
      // console.log("NotDataAv");
      // console.log(this.props.registerPicks);
      // console.log(this.state.cantRegistros);
      let temporalRegisterPicks = {};
      temporalRegisterPicks['num_entries'] = this.state.cantRegistros;
      temporalRegisterPicks['entry_payment'] = this.props.pool.entry_currency;
      temporalRegisterPicks['pool_id'] = this.props.quinaID;
      console.log('temporalRegisterPicks', temporalRegisterPicks);
      this.setState({ submitting: true });
      API.postFastRegister(temporalRegisterPicks)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            console.log('completando tu registro', temporalRegisterPicks);
            this.setState(
              {
                siubmitting: false,
                respuestas: response.data.error,
                newEntryID: response.data.entryId,
                redirectLobbyPool: this.props.refreshAfterGuardar === undefined ? true : false,
              },
              () => {
                if (this.props.returnErrors !== undefined) {
                  this.props.returnErrors(true);
                }
                if (this.props.refreshAfterGuardar !== undefined) {
                  let register = 'reRegister';
                  // if(this.props.withRegister){
                  //   register = 'save'
                  // }
                  this.props.refreshAfterGuardar(response.data.entryId, 'reRegister');
                }
                if (this.props.updateUserInfo !== undefined) {
                  this.props.updateUserInfo();
                }
                if (this.props.handleConfirmation !== undefined) {
                  this.props.handleConfirmation();
                }
              }
            );
            console.log('QuinielaRegister', response);
          } else {
            Sentry.captureException('Error RegisterPanel with picks', {
              extra: 'Error RegisterPanel with picks',
            });
            this.setState(
              {
                respuestas: response.data,
                newEntryID: response.data.entryId,
                errorCupo: true,
                submitting: false,
              },
              () => {
                if (this.props.returnErrors !== undefined) {
                  this.props.returnErrors(false);
                }
                if (this.props.updateUserInfo !== undefined) {
                  this.props.updateUserInfo();
                }
                if (this.props.handleConfirmation !== undefined) {
                  this.props.handleConfirmation();
                }
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
          Sentry.captureException(err, {
            extra: 'Error RegisterPanel with picks',
          });
          this.setState({
            errorGuardarPicks: true,
            submitting: false,
          });
        });
      // console.log(this.state.respuestas);
    }
    // console.log(this.state.respuestas);
  }

  /**
   *This fucntion Handles the redirection after the Registration Process
   *  */
  redirect() {
    this.setState({
      redirectLogin: true,
    });
  }

  /**
   * This function hides the Abonar Panel
   * */
  updateAbonarPop() {
    this.setState({
      showAbonarPop: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * if the panel is open fetch the available tickets
     **/
    if (
      (prevProps.showPanel !== this.props.showPanel && this.props.showPanel) ||
      (prevProps.showPanelFirstRegister !== this.props.showPanelFirstRegister &&
        this.props.showPanelFirstRegister) ||
      (prevProps.showPanelReRegister !== this.props.showPanelReRegister &&
        this.props.showPanelReRegister) ||
      (prevState.showAbonarPop !== this.state.showAbonarPop && !this.state.showAbonarPop)
    ) {
      // console.log( this.props.pool );
      API.fetchTickets(this.props.pool.id, this.props.pool.game_type)
        .then((response) => {
          //   console.log(
          //     '%cShow Panel',
          //     'color:rgb(111, 180, 198); font-size:1.5em;'
          //   );
          let userDataUpdated = utils.getUserInfo();
          let userBalanceUpdated = utils.getUserBalance();
          let balanceEnough = false;
          // console.log('response', response);
          // console.log('user', userDataUpdated);
          if (userDataUpdated) {
            balanceEnough = utils.isBalanceEnough(
              this.props.pool.entry_currency,
              this.props.pool.entry_fee
            );
          }

          this.setState(
            {
              userData: userDataUpdated,
              userBalance: userBalanceUpdated,
              submitting: false,
              enoughBalance:
                !balanceEnough &&
                response.data.hasOwnProperty('tickets') &&
                response.data.tickets.length > 0
                  ? true
                  : balanceEnough,
              tickets: response.data.tickets,
            },
            () => {
              // Sentry.captureException( new Error('This was not error') );

              this.calculateEntryFees(
                this.props.pool,
                this.state.cantRegistros,
                this.state.tickets
              );
              console.log(this);
              // this.cantRegistros(this.state.cantRegistros);
            }
          );
          // console.log('Tickets response', response );
        })
        .catch((error) => {
          // Sentry.captureException( new Error('Error Fetching  Tickets in registerPanel') );
          console.error(error);

          // Sentry.captureException(error, { extra: 'Error Fetching Tickets in Register Panel' });
          this.setState({
            submitting: false,
            errorGuardarPicks: true,
          });
        });
      // console.log( this );
    }
  }

  /*********************
   * Lifecycle Methods
   * *******************/
  componentDidMount() {
    // console.log(this.props);
    if (this.props.pool !== undefined && this.props.pool !== null) {
      this.setState(() => {
        let userDataUpdated = utils.getUserInfo();
        let balanceObj = utils.getUserBalance();
        // console.log( 'BALANCE OBJ', balanceObj )
        let balanceEnough = false;
        if (this.props.logged) {
          balanceEnough = utils.isBalanceEnough(
            this.props.pool.entry_currency,
            this.props.pool.entry_fee
          );
        }

        return {
          userBalance: balanceObj,
          userData: userDataUpdated,
          payType: (this.props.pool && this.props.pool.entry_currency) || null,
          logged: this.props.logged,
          enoughBalance: balanceEnough,
        };
      });
    } else {
      return null;
    }

    if (utils.isLogged()) {
      // API.fetchUserData()
      // .then(response => {
      //   // console.log(response);
      //   try{
      //     utils.checkAxiosAllErrors( [response] );
      //   }
      //   catch(e){
      //     console.error(e);
      //     Sentry.captureException( e );
      //   }
      //   // console.log('response Register', response);
      //   utils.setUserInfo(response.data);
      // console.log(response.data);
      this.setState({
        userData: utils.getUserInfo(),
        mounted: true,
      });
      // });
    } else {
      this.setState({
        userData: null,
        mounted: true,
      });
    }
    // console.log('Panel Mounted', this.state)
  }

  confirmAndSavePicks = () => {
    // Save picks confirm
    if (this.state.submitting) {
      return;
    }
    if (this.props.type === 'bracket') {
      let bracketId = !this.props.quinaID
        ? this.props.pool.id
        : this.props.quinaID;

      console.log('bracketId', bracketId, this.props.pool);
      if (this.props.pool.sport_id === 5) {

        this.registraBracketTennis (
          bracketId,
          this.props.registerPicks,
          this.props.entryId,
          this.state.samePicks,
          this.props.payType,
          this.state.cantRegistros,
          this.props.bracketUserTieBreakers,
          this.props.registerPicksProps,
          this.props.reRegister,
        );
      } else {

        this.registraBracket (
          bracketId,
          //   {
          //     "49": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": 21,
          //         "match_id": 334,
          //         "winning_game": null,
          //         "team": "home"
          //     },
          //     "50": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 335,
          //         "winning_game": null
          //     },
          //     "51": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 336,
          //         "winning_game": null
          //     },
          //     "52": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 337,
          //         "winning_game": null
          //     },
          //     "53": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": 307,
          //         "match_id": 338,
          //         "winning_game": null,
          //         "team": "away"
          //     },
          //     "54": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 339,
          //         "winning_game": null
          //     },
          //     "55": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 340,
          //         "winning_game": null
          //     },
          //     "56": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 341,
          //         "winning_game": null
          //     },
          //     "57": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": 21,
          //         "match_id": 342,
          //         "winning_game": null,
          //         "team": "home"
          //     },
          //     "58": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 344,
          //         "winning_game": null
          //     },
          //     "59": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 343,
          //         "winning_game": null
          //     },
          //     "60": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 345,
          //         "winning_game": null
          //     },
          //     "61": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": 21,
          //         "match_id": 346,
          //         "winning_game": null,
          //         "team": "home"
          //     },
          //     "62": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 347,
          //         "winning_game": null
          //     },
          //     "64": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "pick": null,
          //         "match_id": 348,
          //         "winning_game": null
          //     }
          // }
          this.props.registerPicks,

          // null
          this.props.entryId,

          // false To repeat the same picks
          this.state.samePicks,

          // 'real'
          this.props.payType,

          // 1 - Nunber of registers for available
          this.state.cantRegistros,


          //   {
          //     "7": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "bracket_tiebreaker_id": 7,
          //         "value": null
          //     },
          //     "8": {
          //         "id": null,
          //         "bracket_id": 11,
          //         "bracket_entry_id": null,
          //         "bracket_tiebreaker_id": 8,
          //         "value": null
          //     }
          // }
          this.props.bracketUserTieBreakers,
        );
      }
    } else if (this.props.type === 'survivor') {
      let survivorId = !this.props.quinaID
        ? this.props.pool.id
        : this.props.quinaID;
      console.log('SurvivorId', survivorId);
      console.log('PayType', this.props);

      if (this.props.reRegister) {
        console.log('reRegister survivor');
        let emptyRegisterPicks = {
          id: null,
          survivor_entry_id: null,
          survivor_id: survivorId,
          survivor_picks: [],
          user_id: utils.getUserInfo().id,
        };

        this.registraSurvivor(
          survivorId,
          emptyRegisterPicks,
          null,
          this.props.payType,
          this.state.cantRegistros
        );
      } else {
        this.registraSurvivor(
          survivorId,
          this.props.registerPicks,
          this.props.entryId,
          this.props.payType,
          this.state.cantRegistros
        );
      }
    } else if (this.props.type === 'group_pool') {
      let groupPoolID = !this.props.quinaID
        ? this.props.pool.id
        : this.props.grupoQuinaID;
      console.log('GroupPoolId', groupPoolID);
      console.log('PayType', this.props);

      if (this.props.reRegister) {
        console.log('reRegister');
        let survivorId;
        let emptyRegisterPicks = {
          id: null,
          num_entries: this.state.cantRegistros,
          use_same_picks: this.state.samePicks,
          group_pool_entry_id: null,
          group_pool_id: survivorId,
          group_pool_picks: [],
          user_id: utils.getUserInfo().id,
        };

        groupPoolActions
          .postSavePicks(this.props.dispatch, emptyRegisterPicks)
          .then((response) => {
            console.log(response);
            if (response) {
              // gaUtils.trackUserGameRegistration(this.props.pool, 'group_pool');
              // close Panel
              // Update userBalance
              // init new entry
              // Change location to new entry
              let redirectTo = `/quiniela/quiniela/${this.props.quinaID}/${response.data.groupPoolEntries[0].id}`;
              groupPoolActions.handleRegistration(this.props.dispatch);

              if (this.props.updateUserBalance) {
                let currentBalanceSpent =
                  this.props.pool.entry_fee *
                  response.data.groupPoolEntries.length;

                let currentBalance = utils.getUserBalance();

                currentBalance.balanceReal =
                  currentBalance.balanceReal - currentBalanceSpent;

                this.props.updateUserBalance(currentBalance);
              }
              // groupPoolActions.redirectTo( this.props.dispatch, redirectTo );
              groupPoolActions.handleOverlay(
                this.props.dispatch,
                false,
                true
              );
            } else {
              groupPoolActions.handleOverlay(
                this.props.dispatch,
                true,
                false
              );

              console.error(response);
              // showError
            }
          });
      } else {
        let temporalEntry = { ...this.props.registerPicks };

        temporalEntry.num_entries = this.state.cantRegistros;

        temporalEntry.same_picks = this.state.samePicks;

        groupPoolActions
          .postSavePicks(this.props.dispatch, temporalEntry)
          .then((response) => {
            if (response) {
              // gaUtils.trackUserGameRegistration(this.props.pool, 'group_pool');
              if (this.props.updateUserBalance) {
                let currentBalanceSpent =
                  this.props.pool.entry_fee *
                  response.data.groupPoolEntries.length;

                let currentBalance = utils.getUserBalance();

                currentBalance.balanceReal =
                  currentBalance.balanceReal - currentBalanceSpent;

                this.props.updateUserBalance(currentBalance);
              }

              setTimeout(() => {
                let redirectTo = `/quinielaGrupo/${this.props.pool.slug}/${this.props.pool.id}/${response.data.groupPoolEntries[0].id}`;
                groupPoolActions.handleRegistration(
                  this.props.dispatch
                );
                groupPoolActions.redirectTo(
                  this.props.dispatch,
                  redirectTo
                );
                groupPoolActions.handleOverlay(
                  this.props.dispatch,
                  false,
                  true
                );
              }, 800);

              // Update userBalance
              // Change location to new entry
            } else {
              console.error(response);
              // showError
              groupPoolActions.handleOverlay(
                this.props.dispatch,
                true,
                false
              );
            }
          });
      }
    } else {
      this.registration();
    }
  }

  render() {
    let payType;
    let show = false;
    if (this.props.showPanel) {
      show = this.props.showPanel;
    } else if (this.props.showPanelReRegister) {
      show = this.props.showPanelReRegister;
    } else if (this.props.showPanelFirstRegister) {
      show = this.props.showPanelFirstRegister;
    }

    let conditionalComponent;
    if (this.state.redirectLobbyPool && this.props.pool) {
      console.log('Redirect ', this.props);
      let gameType = this.props.pool.game_type;

      if (gameType === 'pool') {
        gameType = 'quiniela';
      } else if (gameType === 'group_pool') {
        gameType = 'quinielaGrupo';
      }
      // console.log('Redirect to the lobby', this.state.newEntryID);
      // window.location.assign(`/quiniela/${this.props.pool.slug}/${ this.props.pool.id }/${this.state.newEntryID}`)
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/${gameType}/${this.props.pool.slug}/${this.props.pool.id}/${this.state.newEntryID}`,
          }}
        />
      );
    } else if (this.state.redirectLobbyBracket && !this.props.userData.is_new) {
      console.log('Redirect to the bracket', this.state.newEntryID);
      conditionalComponent = (
        <Redirect
          push
          to={{
            // pathname: `/bracket/bracket/${this.props.pool.id}/${this.state.newEntryID}`,
            pathname: `/bracketPoints/${this.props.pool.name_replaced}/${this.props.pool.id}/${this.state.newEntryID}`,
          }}
        />
      );
    } else {
      if (this.state.mounted || this.state.componentReady) {
        // console.log(this.props);
        //console.log('Noral Behaviour');
        this.state.redirectLogin
          ? (conditionalComponent = (
              <Redirect
                to={{
                  pathname: 'ingresar',
                }}
              />
            ))
          : (conditionalComponent = (
              <div>
                <Modal
                  backdropClassName="backdropRegister"
                  dialogClassName="registerModal modal-dialog-xxxs"
                  className="registerModal"
                  show={show}
                  onHide={() => {
                    if (this.props.dispatch) {
                      this.props.updateStateFunc(this.props.dispatch);
                    } else {
                      this.props.updateStateFunc();
                    }
                  }}
                >
                  {show ? (
                    <div id="register-panel">
                      <div id="panelTitle" className="panel-abonar-titulo">
                        <span>Registro {this.props.type}</span>
                        <button className="cerrar-panel"
                          onClick={() => {
                            if (this.props.dispatch) {
                              this.props.updateStateFunc(this.props.dispatch);
                              this.resetData();
                            } else {
                              console.log('No existe esa function');
                              console.log(this.props);
                              if (!this.props.updateStateFunc) {
                                this.props.closePanel();
                              } else {
                                this.props.updateStateFunc();
                              }
                              this.resetData();
                              if (this.props.updatePanelReRegister !== undefined) {
                                this.props.updatePanelReRegister();
                              }
                              if (this.props.updatePanelFirstRegister !== undefined) {
                                this.props.updatePanelFirstRegister();
                              }
                            }
                          }}
                        >
                          <div className="btn-icon-close"><i className="ion-ios-close-empty"></i></div>
                        </button>
                      </div>

                      <div className='flex-row'>
                        <div className='flex-col'>
                          <div className="title-wrapper flex-col register-main-title">
                            {/* {this.props.pool !== undefined
                              ? this.setSport(this.props.pool.sport_id)
                              : this.setSport(this.props.pool.sport_id)} */}
                            <div className='register-main-container'>
                              <DisplaySport sports={this.props.pool.sport} type={'pool'} size={'22px'}/>
                              <div className="flag-wrapper">
                                <DisplayFlag type="pool" flag={this.props.pool} />
                              </div>
                              {this.props.pool.point_format === 'line' && (<img className="score-icons" src={line} />) || ''}
                              {this.props.pool.point_format === 'natural' && (<img className="score-icons" src={natural} />) }
                              <img className="score-icons" src={this.props.pool.guaranteed_prize !== null ? garantizada : null} />
                              <img className="score-icons" src={this.props.pool.brackets_entry_count !== undefined ? bracket : null} />
                              <span className="registro-main-title font18">
                                {this.props.pool.name_replaced !== undefined
                                  ? this.props.pool.name_replaced
                                  : this.props.pool.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="firstSection">

                        <div className="registro-entrada" style={{marginLeft: '15px', fontSize: '17px'}}>

                          {this.props?.pool?.entry_currency !== 'ticket' && (
                            <div className="balance-datos entrada">
                              <div className="balance-data">
                                <strong className='balance-entry'>Entrada:</strong> <strong><TitleRegisterPanel pool={this.props.pool} /></strong>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="pago-registros-quiniela row">
                          <div className="cuantos-registro col-xs-12">
                            <div className="row">
                              {this.state.enoughBalance ? (
                                <span className="registro-subtitulo">
                                  {`¿Cuántos registros quieres en ${
                                    this.props.pool.game_type === 'survivor'
                                      ? 'este survivor'
                                      : 'esta quiniela'
                                  }?`}
                                </span>
                              ) : null}
                            </div>
                            <Form>
                              <MaxRegisterCant
                                enoughBalance={this.state.enoughBalance}
                                myEntries={
                                  this.props.myEntries
                                    ? this.props.myEntries
                                    : this.props.item
                                    ? this.props.item[0].entries
                                    : null
                                }
                                maxMultyEntries={this.props.pool.max_multientries}
                                entryFees={this.state.entryFees}
                                currentTickets={this.state.tickets}
                                currentEntries={this.props.pool.entry_count}
                                maxEntries={this.props.pool.max_entries}
                                maxCapacity={this.props.pool.limited_capacity}
                                payType={this.props.pool.entry_currency}
                                pickNgo={this.props.pool.pickngo}
                                isFull={this.props.pool.is_full}
                                entryFee={this.props.pool.entry_fee}
                                userData={utils.getUserInfo()}
                                userBalance={utils.getUserBalance()}
                                cantRegistros={this.cantRegistros}
                                game={this.props.pool}
                              />
                            </Form>
                          </div>
                        </div>
                        <ShowBalance
                          entryFees={this.state.entryFees}
                          entryCurrency={this.props.pool.entry_currency}
                          entryFee={this.props.pool.entry_fee}
                          userBalanceFreeroll={
                            utils.getUserInfo() === null
                              ? 0
                              : utils.getUserBalance().balanceFreeroll
                          }
                          userBalanceReal={
                            utils.getUserInfo() === null ? 0 : utils.getUserBalance().balanceReal
                          }
                          userBalancePc={
                            utils.getUserInfo() === null ? 0 : utils.getUserBalance().balancePc
                          }
                          userBalanceReferral={
                            utils.getUserInfo() === null
                              ? 0
                              : utils.getUserBalance().balanceReferral
                          }
                          cantRegistros={this.state.cantRegistros}
                          cantTickets={this.state.tickets}
                        />
                        {this.state.cantRegistros > 1 && this.props.type !== 'survivor' ? (
                          <label id="samePicks flex-row" style={{marginLeft: '16px'}}>
                            <ControlLabel className="flex-col col-80">
                              ¿Quieres usar los picks que elegiste para todos tus registros?
                            </ControlLabel>
                            <Checkbox onChange={this.handleSamePicks}>
                              <div className={` checkbox-custom `} />
                            </Checkbox>
                          </label>
                        ) : null}
                        {this.state.errorGuardarPicks ? (
                          <ErrorMessages label="Surgió un error en tu registro, por favor inténtalo nuevamente. " />
                        ) : null}
                        <div className="register-buttons">
                          <Button
                            className={'guardar-picks btn-rojo'}
                            bsStyle="danger"
                            onClick={() => {
                              if (this.props.dispatch) {
                                //THis is for Group Pools with dispatcher
                                this.props.updateStateFunc(this.props.dispatch);
                                this.resetData();
                              } else {
                                console.log(this.props);
                                if (this.props.updateStateFunc) {
                                  this.props.updateStateFunc();
                                } else {
                                  //this.closePanel;
                                }
                                this.resetData();
                                if (this.props.updatePanelReRegister !== undefined) {
                                  this.props.updatePanelReRegister();
                                }
                                if (this.props.updatePanelFirstRegister !== undefined) {
                                  this.props.updatePanelFirstRegister();
                                }
                              }
                            }}
                          >
                            {' '}
                            Regresar{' '}
                          </Button>
                          {this.props.logged ? (
                            <Button
                              bsStyle="success"
                              className={'guardar-picks'}
                              disabled={
                                this.state.submitting ||
                                !this.state.enoughBalance ||
                                (this.props.pool &&
                                  this.props.pool.max_multientries > 0 &&
                                  this.props.item &&
                                  this.props.item[0] &&
                                  this.props.item[0].hasOwnProperty('entries') &&
                                  this.props.item[0].entries.length > 0 &&
                                  this.props.item[0].entries.length >=
                                    this.props.pool.max_multientries)
                              }
                              type="submit"
                              onClick={this.confirmAndSavePicks}
                            >
                              Confirmar Registro
                            </Button>
                          ) : (
                            <Button
                              className={'guardar-picks'}
                              bsStyle="success"
                              onClick={this.redirect}
                              disabled={!!this.props.pool.closed}
                            >
                              Ingresar
                            </Button>
                          )}
                        </div>
                        {this.props.pool !== undefined &&
                        this.props.logged &&
                        !this.state.enoughBalance ? (
                          <div className="message-wrapper">
                            <div className="login-failed">
                              {' '}
                              <i className="ion-alert-circled" /> Tu balance disponible no es
                              suficiente para jugar esta quiniela.{' '}
                            </div>
                            <div className={`button-wrap `}>
                              <Button
                                className="btn-pick registro"
                                onClick={(e) => {
                                  if (this.props.pool.entry_currency === 'referral') {
                                    window.location = '/usuario/invitados';
                                  } else {
                                    this.abonarSaldoPanel(e);
                                  }
                                }}
                              >
                                <i
                                  className={` ${
                                    this.props.pool.entry_currency === 'referral'
                                      ? null
                                      : 'ion-social-usd'
                                  } `}
                                />
                                <span>
                                  {' '}
                                  {this.props.pool.entry_currency === 'referral'
                                    ? 'INVITAR AMIGOS'
                                    : 'ABONAR SALDO'}{' '}
                                </span>
                              </Button>
                            </div>
                          </div>
                        ) : null}
                        {this.props.logged ? (
                          this.props.pool.closed ? (
                            <div className="login-failed">
                              Esta quiniela ya cerró, busca alguna que este activa
                            </div>
                          ) : null
                        ) : null}
                        {this.props.logged ? (
                          this.state.errorCupo ? (
                            <div className="login-failed">
                              <span>
                                {!this.state.errorMessage
                                  ? 'Encontramos un error al registrarte, inténtalo nuevamente'
                                  : this.state.errorMessage}
                              </span>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <span>LOADING...</span>
                  )}
                </Modal>
                <AbonarSaldo
                  dispatch={this.dispatch}
                  showAbonarPop={this.abonarSaldoPanel}
                  showAbonarSaldo={this.state.showAbonarPop}
                  type="modal"
                  updatePanelSate={this.updateAbonarPop}
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                />
              </div>
            ));
      } else {
        conditionalComponent = null;
      }
    }
    // console.log('Render Panel',this.props);
    return conditionalComponent;
  }
}

export default RegisterPanel;
