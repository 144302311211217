import React from 'react';
import { MenuItem, ToggleButtonGroup, ToggleButton } from 'react-bootstrap/lib/';
import API from '../../utils/api';
import ReactTooltip from 'react-tooltip';

import baseball from '../../assets/images/BaseballBall-50.png';
import americano from '../../assets/images/AmericanoBall-50.png';
import futbol from '../../assets/images/FutbolBall-50.png';
import basketball from '../../assets/images/BasketballBall-50.png';
import tenis from '../../assets/images/TennisBall-50.png';
import golf from '../../assets/images/GolfBall-50.png';
import F1 from '../../assets/images/Volante-50.png';
import hockey from '../../assets/images/Hockey.png';
import box from '../../assets/images/box.png';
import iconQuiniela from '../../assets/images/icon-quiniela.png';
import iconSurvivor from '../../assets/images/icon-survivor.png';
import iconBracket from '../../assets/images/type_game/bracket-type.png';
import iconCl from '../../assets/images/icon-cl.png';
import iconMax10 from '../../assets/images/icon-max10.png';
import iconMax from '../../assets/images/icon-max.png';
import iconPick5 from '../../assets/images/icon-pick5.png';
import iconParlay from '../../assets/images/icon-parlay.png';
import icon1vs1 from '../../assets/images/icon-1vs1.png';
import iconPrivate from '../../assets/images/icon-private.png';
import _ from 'underscore';

class FilterQuinielaControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tournament: this.props.setTitle,
      sortedQuinas: [],
      mounted: false,
      quinas: this.props.activeDashboard,
      activeFilters: this.props.activeFiltersProps,
      pickcoinsActive: false,
      filtersType: [0, 1, 2, 3, 4, 5],
      filtersTipo: ['all', 'cupo', 'abierta', 'max'],
      filtersEstado: ['upcoming', 'live', 'closed', 'eliminado'],
      filtersDeporte: ['all'],
      filtersConcurso: 'concurso-all',
    };

    this.checkAccumulativeCategory = this.checkAccumulativeCategory.bind(this);
    this.checkActiveButton = this.checkActiveButton.bind(this);
    this.checkFilterDeporte = this.checkFilterDeporte.bind(this);
    this.checkFilterEstado = this.checkFilterEstado.bind(this);
    this.checkFiltersAll = this.checkFiltersAll.bind(this);
    this.checkFilterType = this.checkFilterType.bind(this);
    this.checkSportsAvailability = this.checkSportsAvailability.bind(this);
    this.parseEvent = this.parseEvent.bind(this);
    this.updateMontoIcons = this.updateMontoIcons.bind(this);
    //  console.log("FilterQuinielaControlsPROPS",  this);

    this.checkFilterTipo = (value) => {};

    this.checkFilterConcurso = (value) => {
      console.log('FilterConcurso value', value);
      this.setState({ filtersConcurso: value }, () => {
        this.props.filterBarStatus(this.checkFiltersAll());
      });
    };
  }

  checkSportsAvailability(which) {
    let any = this.state.pools.filter((pool) => {
      if (pool.sport_id === which) {
        return pool;
      }
    });
    if (any.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkFiltersAll() {
    /**
     * We have to update the filtersALL state
     * to make sure that the filter bar is accumulative of all the categories
     */
    // console.log('%c Checking Filters All', 'background:blue; font-size:20px;color:#fff;');
    // console.log(this.state);;
    let filtersConcursoTemp = this.state.filtersConcurso;
    let filtersDeporteTemp = this.state.filtersDeporte;
    let filtersTypeTemp = this.state.filtersType;
    let filtersEstadoTemp = this.state.filtersEstado;
    let allFilters = [];
    allFilters.push(filtersConcursoTemp, filtersDeporteTemp, filtersTypeTemp, filtersEstadoTemp);
    return allFilters;
  }

  checkFilterDeporte(value) {
    // console.log('%c Checking Filter Deporte', 'background:#ACE6FF;');
    this.setState(
      {
        filtersDeporte: value,
      },
      () => {
        this.props.filterBarStatus(this.checkFiltersAll());
      }
    );
  }

  checkFilterEstado(value) {
    // console.log('%c Checking Filter Deporte', 'background:#ACE6FF;');
    this.setState(
      {
        filtersEstado: value,
      },
      () => {
        this.props.filterBarStatus(this.checkFiltersAll());
      }
    );
  }

  checkFilterType(value) {
    console.log('%c Checking Filter Type', 'background:#ACE6FF;', value);
    this.setState(
      {
        filtersType: value,
      },
      () => {
        this.props.filterBarStatus(this.checkFiltersAll());
      }
    );
  }

  checkAccumulativeCategory(type, value) {
    console.log("TYPW:", type, value);
    switch (type) {
      case 'concurso':
        this.checkFilterConcurso(value);
        break;
      case 'status':
        this.checkFilterEstado(value);
        break;

      case 'type':
        this.checkFilterType(value);
        break;

      case 'sport':
        var idxAll = _.findIndex(value, (_sport) => _sport === 'all');
        if (value.length > 1 && idxAll === 0) {
          value = value.splice(1);
        }
        if (value.length > 1 && idxAll > 0) {
          value = ['all'];
        }
        this.checkFilterDeporte(value);
        break;
    }
  }

  updateMontoIcons() {
    let pickcoinsActive = this.state.activeFilters.find(
      (filter) => filter.name === 'moneda-pickcoins'
    );
    let cashActive = this.state.activeFilters.find((filter) => filter.name === 'moneda-real');
    let allActive = this.state.activeFilters.find((filter) => filter.name === 'moneda-all');
    //Cash Active
    if (pickcoinsActive.active) {
      this.setState({
        pickcoinsActive: true,
      });
    } else if (allActive.active) {
      this.setState({
        pickcoinsActive: false,
      });
    } else if (cashActive.active) {
      this.setState({
        pickcoinsActive: false,
      });
    }
    // console.log('Updating Monto...' , this.state.pickcoinsActive);
  }

  componentDidMount() {
    API.fetchSports().then((response) => {
      this.setState(
        {
          quina: this.state.quina,
          deportes: response.data.sports,
          pools: this.props.pools,
        },
        () => {
          this.props.filterBarStatus(this.checkFiltersAll());
          this.setState({
            mounted: true,
          });
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps, this.props);
    if (this.props.defaultFilters) {
      console.log('resetFilters');
      this.props.filterBarStatus(this.checkFiltersAll());
      this.props.resetDefaultFilters();
    }
  }

  parseEvent(e) {
    let elClicked = e.target.attributes[0].value;
    let parentClicked = e.target.parentNode.attributes[0].value;
    // elClicked = elClicked.split('-')
    // elClicked = elClicked[1];
    // let categoryClicked = elClicked[0];
    console.log('%c ParseEvent', 'font-size:20px');
    console.log(elClicked, parentClicked);
    if (elClicked.indexOf('color') >= 0) {
      this.props.filterBarStatus(parentClicked);
    } else {
      this.props.filterBarStatus(elClicked);
    }
  }

  checkActiveButton(cual) {
    let cualEs = this.state.activeFilters.find((filter) => filter.name === cual);
    let clase;
    cualEs.active
      ? (clase = `btn-filter btn-${cualEs.name} btn-filter-active `)
      : (clase = `btn-filter btn-${cualEs.name}`);
    return clase;
  }

  render() {
    let conditionalComponent;
    conditionalComponent = (
      <div className="filters-col mis-quinas">
        <div className="filtros-registro">
          <div className="filtros-item tipo">
            <div className="control-title row">
              <span>CONCURSO</span>
            </div>
            <ToggleButtonGroup
              name="concurso"
              type="radio"
              value={this.state.filtersConcurso}
              onChange={(filters) => {
                this.checkAccumulativeCategory('concurso', filters);
              }}
            >
              <ToggleButton value={'concurso-all'} className="btn-filter-concurso itemFilterBorder  button-filter-all">
                <span className="concurso-item concurso-all" >TODAS</span>
              </ToggleButton>

              <ToggleButton
                value={'concurso-quiniela'}
                className="btn-filter-concurso itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="type-abierta"
              >
                <img src={iconQuiniela} width="20" height="20" />
                <span className='title'>Quiniela</span>
                <ReactTooltip id="type-abierta" place="top" type="light" effect="">
                  Quiniela
                </ReactTooltip>
              </ToggleButton>

              <ToggleButton
                value={'concurso-survivor'}
                className="btn-filter-concurso itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="type-survivor"
              >
                <img src={iconSurvivor} width="20" height="20" />
                <span className='title'>Survivor</span>
                <ReactTooltip id="type-survivor" place="top" type="light" effect="">
                  Survivor
                </ReactTooltip>
              </ToggleButton>
              <ToggleButton
                value={'concurso-bracket'}
                className="btn-filter-concurso itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="type-bracket"
              >
                <img src={iconBracket} width="20" height="20" />
                <span className='title'>Bracket</span>
                <ReactTooltip id="type-bracket" place="top" type="light" effect="">
                  Bracket
                </ReactTooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="filtros-item tipo">
            <div className="control-title row">
              <span>TIPO</span>
            </div>
            <ToggleButtonGroup
              name="tipo"
              type="checkbox"
              value={this.state.filtersType}
              onChange={(filters) => {
                this.checkAccumulativeCategory('type', filters);
              }}
            >
              <ToggleButton
                value={0}
                className="btn-filter-tipo itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="tipo-publica"
              >
                <img src={iconQuiniela} width="20" height="20" />
                <span className='title'>Publica</span>
                <ReactTooltip id="tipo-publica" place="top" type="light" effect="">
                  Publica
                </ReactTooltip>
              </ToggleButton>

              <ToggleButton
                value={1}
                className="btn-filter-tipo itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="tipo-privada"
              >
                <img src={iconPrivate} width="20" height="20" />
                <span className='title'>Privada</span>
                <ReactTooltip id="tipo-privada" place="top" type="light" effect="">
                  Privada
                </ReactTooltip>
              </ToggleButton>

              <ToggleButton
                value={2}
                className="btn-filter-tipo itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="tipo-cupo"
              >
                <img src={iconCl} width="20" height="20" />
                <span className='title'>Limitado</span>
                <ReactTooltip id="tipo-cupo" place="top" type="light" effect="">
                  Cupo Limitado
                </ReactTooltip>
              </ToggleButton>

              <ToggleButton
                value={3}
                className="btn-filter-tipo itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="tipo-max"
              >
                <img src={iconMax} width="20" height="20" />
                <span className='title'>MAX</span>
                <ReactTooltip id="tipo-max" place="top" type="light" effect="">
                  MAX
                </ReactTooltip>
              </ToggleButton>

              <ToggleButton
                value={4}
                className="btn-filter-tipo itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="tipo-pick5"
              >
                <img src={iconPick5} width="20" height="20" />
                <span className='title'>Pick 5</span>
                <ReactTooltip id="tipo-pick5" place="top" type="light" effect="">
                  Pick 5
                </ReactTooltip>
              </ToggleButton>

              <ToggleButton
                value={5}
                className="btn-filter-tipo itemFilterBorder button-filter-my-quinielas"
                data-tip
                data-for="tipo-parlay"
              >
                <img src={iconParlay} width="20" height="20" />
                <span className='title'>Parlay</span>
                <ReactTooltip id="tipo-parlay" place="top" type="light" effect="">
                  Parlay
                </ReactTooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="filtros-item estado">
            <div className="control-title row">
              <span>ESTADO</span>
            </div>
            <ToggleButtonGroup
              name="estado"
              type="checkbox"
              value={this.state.filtersEstado}
              onChange={(filters) => {
                this.checkAccumulativeCategory('status', filters);
              }}
            >
              <ToggleButton value="live" className="btn-filter-estado itemFilterBorder">
                <span className="estado-item estado-curso">EN CURSO</span>
              </ToggleButton>
              <ToggleButton value="upcoming" className="btn-filter-estado itemFilterBorder">
                <span className="estado-item estado-proximas ">PRÓX</span>
              </ToggleButton>
              <ToggleButton value="closed" className="btn-filter-estado itemFilterBorder">
                <span className="estado-item estado-finalizadas">CERRADAS</span>
              </ToggleButton>
              {this.state.filtersConcurso !== 'concurso-quiniela' ? (
                <ToggleButton value="eliminado" className="btn-filter-estado itemFilterBorder">
                  <span className="estado-item estado-eliminado">ELIMINADO</span>
                </ToggleButton>
              ) : null}
            </ToggleButtonGroup>
          </div>
          {this.state.mounted ? (
            <div className="filtros-item deporte">
              <div className="control-title row">
                <span>DEPORTE</span>
              </div>
              <ToggleButtonGroup
                name="deporte"
                type="checkbox"
                value={this.state.filtersDeporte}
                onChange={(filters) => {
                  this.checkAccumulativeCategory('sport', filters);
                }}
              >
                <ToggleButton value="all" className="btn-filter-deporte itemFilterBorder">
                  <span className="deporte-item deporte-all">TODOS</span>
                </ToggleButton>
                <ToggleButton
                  value={4}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(4) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-baseball pool-icons" src={baseball} alt="" />
                </ToggleButton>
                <ToggleButton
                  value={3}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(3) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img
                    className="deporte-item deporte-basketball pool-icons"
                    src={basketball}
                    alt=""
                  />
                </ToggleButton>
                <ToggleButton
                  value={1}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(1) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-futbol pool-icons" src={futbol} alt="" />
                </ToggleButton>
                <ToggleButton
                  value={2}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(2) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img
                    className=" deporte-item deporte-americano pool-icons"
                    src={americano}
                    alt=""
                  />
                </ToggleButton>
                <ToggleButton
                  value={5}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(5) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-tenis pool-icons" src={tenis} alt="" />
                </ToggleButton>
                <ToggleButton
                  value={6}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(6) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-golf pool-icons" src={golf} alt="" />
                </ToggleButton>
                <ToggleButton
                  value={7}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(7) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-F1 pool-icons" src={F1} alt="" />
                </ToggleButton>
                <ToggleButton
                  value={8}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(8) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-box pool-icons" src={box} alt="" />
                </ToggleButton>
                <ToggleButton
                  value={9}
                  className="btn-filter-deporte itemFilterBorder"
                  style={
                    this.checkSportsAvailability(9) ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <img className="deporte-item deporte-hockey pool-icons" src={hockey} alt="" />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          ) : (
            <div className="filtros-item deporte">
              <div className="control-title row">
                <span>DEPORTE</span>
              </div>
              <ToggleButtonGroup
                name="deporte"
                type="checkbox"
                value={this.state.filtersDeporte}
                onChange={this.checkAccumulativeCategory}
              >
                <ToggleButton value="deporte-all" className="btn-filter-deporte itemFilterBorder">
                  <span className="deporte-item deporte-all">TODOS</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <div className="sortable-header row-no-padding flex-row " style={{ width: '98%' }}>
            <div className="flex-col col-no-padding">
              <div className="flex-row  row- filtros-item">
                <div className="control-title row">
                  <span>ORDEN</span>
                </div>
              </div>
              <div className="flex-row sortableEntries">
                <button
                  onClick={() => {
                    this.props.changeSortOrder('posiciones');
                  }}
                  className={`sortableClick flex-col  posiciones-category rank quina bg-gradient-filter ${
                    this.props.sortOrder.rank === 1 ? 'active' : ''
                  }`}
                >
                  <span>Posición</span>
                  {this.props.sortOrder.rank === 1 ? (
                    <i className="ion-arrow-up-b ml-1" />
                  ) : (
                    <i className="ion-arrow-down-b  ml-1" />
                  )}
                </button>
                <button
                  onClick={() => {
                    this.props.changeSortOrder('number');
                  }}
                  className={`sortableClick flex-col posiciones-category jugador quina bg-gradient-filter ${
                    this.props.sortOrder.number === 1 ? 'active' : ''
                  }`}
                >
                  <span># Registro</span>
                  {this.props.sortOrder.number === 1 ? (
                    <i className="ion-arrow-up-b ml-1" />
                  ) : (
                    <i className="ion-arrow-down-b ml-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return conditionalComponent;
  }
}

export default FilterQuinielaControls;
