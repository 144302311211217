import React, { useState, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import _ from 'underscore'

// Components
import TextField from '../Form/TextField'
import Button from '../Form/Button'
import Checkbox from '../Form/Checkbox'
import HelpText from '../Form/HelpText'
import Logo from './Logo'
import ModalBody from '../ModalPanel/ModalBody'
import Title from './Title'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Utils
import utils from '../../utils/utils'
import isValidEmail from '../../utils/isValidEmail'

const initialState = {
  nick: '',
  name: '',
  lastName: '',
  referalCode: '',
  email: '',
  password: '',
  cpassword: '',
  acceptTerms: false,
  checkAge: false,
  checkGetNews: true
}

export default function CompleteFacebookData () {
  const { submitting, fbData, postFacebookRegister, fingerPrint } = useAuthContext()
  const usernameRef = useRef()
  const [formState, setFormState] = useState(initialState)
  const [validationErrors, setValidationErrors] = useState({})

  useLayoutEffect(() => {
    if (fbData) {
      setFormState(prev => ({
        ...prev,
        name: fbData.first_name,
        lastName: fbData.last_name,
        email: fbData.email,
        nick: fbData.name
          .replace(' ', '')
          .substr(0, 8) + (Math.floor(Math.random() * (9999 - 1000)) + 1000)
      }))

      // Moce cursor end
      const end = usernameRef.current.value.length
      usernameRef.current.setSelectionRange(end, end)
      usernameRef.current.focus()
    }
  }, [fbData])

  const submit = () => {
    let userMetadata = utils.getUserMetadata()
    userMetadata = _.map(userMetadata, function (val, key) {
      return { name: key, value: val }
    })
    // push currentUrl
    userMetadata.push({ name: 'currentUrl', value: window.location.href })

    const formData = {
      fingerprint: fingerPrint,
      referalCode: formState.referalCode,
      name: formState.name,
      last_name: formState.lastName,
      nick: formState.nick,
      email: formState.email,
      password: formState.password,
      cpassword: formState.cpassword,
      tos: formState.acceptTerms ? '1' : false,
      oldenough: formState.checkAge ? '1' : false,
      newsletter: formState.checkGetNews ? '1' : false,
      provider_uid: fbData.userID,
      profile_picture: fbData.picture.data.url,
      users_metadata: userMetadata,
      source: 'web',
    }

    postFacebookRegister(formData, (errors) => {
      const errorsKeys = Object.keys(errors)
      const errorObjectList = {}

      errorsKeys.forEach((key) => {
        const errorTypeKey = Object.keys(errors[key])
        errorObjectList[key] = errors[key][errorTypeKey[0]]
      })

      setValidationErrors(errorObjectList)
    })
  }

  const setValue = (key, value) => {
    // Cleanup errors
    setValidationErrors({})

    // Update form values
    setFormState(prev => ({
      ...prev,
      [key]: value
    }))

    if (key === 'nick') {
      if (value.length < 4 || value.length > 12) {
        return setValidationErrors({ nick: 'Tu Nick debe de contener de 4 a 12 caracteres' })
      }
    }

    if (key === 'email') {
      if (value.length > 3) {
        if (!isValidEmail(value)) {
          return setValidationErrors({ email: 'Email ingresado es invalido' })
        }
      }
    }

    if (key === 'password') {
      if (value.length < 6) {
        return setValidationErrors({ password: 'Debe de tener al menos 6 carácteres.' })
      }
    }

    if (key === 'cpassword') {
      if (value.length >= 6 && formState.password !== value) {
        return setValidationErrors({ cpassword: 'Tus contraseñas no son iguales' })
      }
    }
  }

  const isValidForm = (
    (formState.nick.length >= 4) &&
    (formState.password.length >= 6 && formState.password === formState.cpassword) &&
    isValidEmail(formState.email) &&
    // Checkboxes
    formState.acceptTerms && formState.checkAge
  )

  return (
    <ModalBody>
      <Logo
        src='/images/Pickwin.png'
        alt='Pickwin logo'
      />
      <Title>Completar Registro</Title>
      {/* <HelpText style={{ marginBottom: 0 }}>Parece que todavía no estas registrado en Pickwin</HelpText>
        <HelpText style={{ marginBottom: 0 }} >Completa el formulario para comenzar a jugar</HelpText>
        <HelpText style={{ color: '#f9cc30' }}>Asegurate que tu nombre y apellidos sean correctos e iguales a los de tu identificación, te los pediremos al momento de hacer un retiro.</HelpText> */}
      <TextField
        name='nick'
        placeholder='Nombre de usuario'
        ref={usernameRef}
        onChange={setValue}
        value={formState.nick}
        errorMessage={validationErrors.nick}
        iconSrc='/images/Icono usuario.png'
      />

      {!fbData.name && (
        <TextField
          name='name'
          placeholder='Nombre'
          onChange={setValue}
          value={formState.name}
        />
      )}

      {!fbData.last_name && (
        <TextField
          name='lastName'
          placeholder='Apellido'
          onChange={setValue}
          value={formState.lastName}
        />
      )}

      {!fbData.email && (
        <TextField
          name='email'
          placeholder='Email'
          onChange={setValue}
          value={formState.email}
          errorMessage={validationErrors.email}
          iconSrc='/images/Icono Mail.png'
        />
      )}

      <TextField
        name='password'
        onChange={setValue}
        value={formState.password}
        errorMessage={validationErrors.password}
        placeholder='Contraseña'
        iconSrc='/images/Icono candado.png'
        type='password'
      />
      <TextField
        name='cpassword'
        onChange={setValue}
        value={formState.cpassword}
        errorMessage={validationErrors.cpassword}
        placeholder='Confirmar Contraseña'
        iconSrc='/images/Icono candado.png'
        type='password'
      />
      <TextField
        name='referalCode'
        value={formState.referalCode}
        onChange={setValue}
        placeholder='Código de referido'
        iconSrc='/images/Icono codigo.png'
        style={{ marginBottom: '1em' }}
      />
      <Checkbox
        name='acceptTerms'
        label='Acepto términos y condiciones del sitio'
        value={formState.acceptTerms}
        onChange={setValue}
      />
      <Checkbox
        name='checkAge'
        label='Certifico que soy mayor de 18 años'
        value={formState.checkAge}
        onChange={setValue}
      />
      <Checkbox
        name='checkGetNews'
        label='Acepto recibir comunicados de Pickwin'
        value={formState.checkGetNews}
        onChange={setValue}
      />
      <Button
        bgColor={isValidForm ? '#88ba44' : '#787878'}
        style={{ marginTop: '1.5em' }}
        loading={submitting}
        onClick={submit}
        disabled={submitting === true || !isValidForm}
      >
        Completar Registro
      </Button>
    </ModalBody>
  )
}

