import React from 'react'
import { Button, Modal } from 'react-bootstrap/lib'

// Components
import AddCardOpenPay from '../../../../AddCardOpenPay'

// Utils
import API from '../../../../../utils/api'

// Assets
import misTarjetas from '../../../../../assets/images/tarjetasIconBlanco-50.png'
import cardVisa from '../../../../../assets/images/payment-icon-set/icons/visa-curved-32px.png'
import cardMaestroCard from '../../../../../assets/images/payment-icon-set/icons/maestro-curved-32px.png'
import cardMasterCard from '../../../../../assets/images/payment-icon-set/icons/mastercard-curved-32px.png'
import cardVisaElectron from '../../../../../assets/images/payment-icon-set/icons/visa-electron-curved-32px.png'
import cardAmericanExpress from '../../../../../assets/images/payment-icon-set/icons/american-express-curved-32px.png'


/**
 * This component Render a Overlay with a message to the user
 * @param isError               { Boolean }
 * @param errorMessage          { String }
 * @param isSuccess             { Boolean }
 * @param successMessage        { String }
 * @param isCopy                { Boolean }
 * @param showOverlay           { Boolean }
 * @param label                 { String }
 * @param currentSavedPicks     { Number }
 * @param allPicks              { Object }
 * @return Component
 */
export const InProgressOverlay = ({ message, showOverlay }) => {
  return (
    <div className={`picks-message-overlay ${showOverlay ? 'show-message' : 'hide-message'}`}>
      <div className='flex-container column align justify'>
        <div className='flex-container justify'>
          <strong className='title'>{message}</strong>
        </div>
      </div>
    </div>
  )
}

const CardItem = ({ card, handleShowCardRemove, setAsDefault, type }) => {
  let cardBrand = null
  switch (card.brand) {
    case 'visa':
      cardBrand = cardVisa
      break
    case 'american_express':
      cardBrand = cardAmericanExpress
      break
    case 'visa_electron':
      cardBrand = cardVisaElectron
      break
    case 'mastercard':
      cardBrand = cardMasterCard
      break
    case 'maestro':
      cardBrand = cardMaestroCard
      break
  }

  return (
    <>
      {/* { card.default_card ? <div className='flex-row'><i className=' flex-col ion-asterisk'/>  </div> : null  } */}
      <div
        className={`mis-tarjetas-item flex-row row-center ${
          card.default_card ? 'defaultCard' : ''
        }`}
      >
        <div className='flex-col col-10 '>
          <div className='flex-row row-center'>
            <strong className='flex-col text-center'> Tipo </strong>
          </div>
          <div className='flex-row row-center'>
            <div className='flex-col col-center text-center'>
              {!card.brand
                ? (
                    'N/D'
                  )
                : (
                  <img src={cardBrand} className='card-logo' style={{ maxHeight: '20px' }} />
                  )}
            </div>
          </div>
        </div>
        <div className='flex-col'>
          <div className='flex-row row-center'>
            <strong className='flex-col card-info text-center'>Terminación </strong>
          </div>
          <div className='flex-row row-center'>
            <span className='flex-col card-info text-center'>
              {card.card_number
                ? card.card_number.slice(card.card_number.length - 4, card.card_number.length)
                : 'XXXX'}
            </span>
          </div>
        </div>
        <div className='flex-col'>
          <div className='flex-row row-center'>
            <strong className='flex-col card-info text-center'> Expira </strong>
          </div>
          <div className='flex-row row-center'>
            <span className='flex-col card-info text-center'>
              {' '}
              {card.expiration_month}/{card.expiration_year}{' '}
            </span>
          </div>
        </div>
        {type === 'informative'
          ? null
          : (
            <div className='flex-col col-30'>
              <div className='flex-row row-center'>
                <strong className='flex-col card-info text-center'> Borrar </strong>
              </div>
              <button
                onClick={() => {
                  handleShowCardRemove(card)
                }}
                className='flex-row delete-card btn-rojo'
              >
                <i className='flex-col ion-trash-a' />
              </button>
            </div>
            )}
      </div>
    </>
  )
}

const CardsList = ({ cardsList, handleShowCardRemove, cardsReady }) => {
  if (!cardsReady) {
    return <div className='flex-container justify align '>...</div>
  } else if (!cardsList || cardsList.length === 0) {
    return <span className='flex-col text-center'> No tienes tarjetas guardadas </span>
  } else {
    const listaTarjetas = cardsList.map((card, i) => {
      return <CardItem key={card.id} card={card} handleShowCardRemove={handleShowCardRemove} />
    })

    return (
      <div className='mis-tarjetas-container flex-col overflow-y overflow-350'>{listaTarjetas}</div>
    )
  }
}

const RemoveCardPanel = ({
  show,
  removeCard,
  cardToRemove,
  handleShowCardRemove,
  submitting,
  isError,
  isSuccess
}) => {
  return (
    <Modal
      show={show}
      className='responseDepositModal absoluteModal'
      dialogClassName='responseDepositDialog'
      backdropClassName='responseDepositBackdrop'
    >
      <Modal
        show={submitting}
        className='responseDepositModal absoluteModal'
        dialogClassName='responseDepositDialog'
        backdropClassName='responseDepositBackdrop'
      >
        <div className='flex-container justify align column'>
          <i className='ion-load-a loading' />
          <div className='flex-row'>
            <strong className='flex-col col-center text-center'>Espera un momento ...</strong>
          </div>
        </div>
      </Modal>

      <div className='transactionPanel'>
        <div id='panelTitle' className='panel-abonar-titulo '>
          <span>Borrar tarjeta</span>
          <button className='cerrar-panel' onClick={handleShowCardRemove}>
            <div className='btn-icon-close'>
              <i className='ion-ios-close-empty' />
            </div>
          </button>
        </div>
        <div className='flex-col col-offset-10 col-80'>
          <div className='flex-row'>
            <strong className='flex-col text-center text-uppercase'>
              ¿Seguro que quieres borrar tu tarjeta?
            </strong>
          </div>
          <CardItem card={cardToRemove} type='informative' />
          <div className='flex-row'>
            <div className='flex-col text-center'>
              {isError
                ? 'No pudimos borrar tu tarjeta. Intetálo nuevamente más tarde.'
                : isSuccess
                  ? 'Tu tarjeta se eliminó correctamente.'
                  : ''}
            </div>
          </div>
          <div className='flex-row'>
            <Button
              className={`flex-col ${!isSuccess ? 'col-45 ' : ''} btn-rojo  btn-pick`}
              disabled={submitting}
              onClick={handleShowCardRemove}
            >
              {' '}
              Regresar
            </Button>
            {isSuccess
              ? null
              : (
                <Button
                  className='flex-col col-45 col-offset-10 btn-default btn-pick'
                  disabled={submitting}
                  onClick={() => {
                    removeCard(cardToRemove)
                  }}
                >
                  {' '}
                  {submitting
                    ? (
                      <div className='flex-container justify align '>
                        {' '}
                        <i className='ion-load-a loading small' />{' '}
                      </div>
                      )
                    : (
                        'Borrar tarjeta'
                      )}{' '}
                </Button>
                )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default function Cards ({ updateUserInfo, updateUserBalance }) {
  // let [mounted, isMounted] = React.useState(false);
  const [cardsReady, handleCardsReady] = React.useState(false)
  const [submitting, handleSubmit] = React.useState(false)
  const [submittingSave, handleSubmitSave] = React.useState(false)

  const [cardsList, updateCardList] = React.useState([])
  const [showAddCard, handleShowCard] = React.useState(false)

  const [isError, handleErrorCard] = React.useState(false)
  const [isSuccess, handleSuccessCard] = React.useState(false)

  const [showRemoveCard, handleRemoveCard] = React.useState(false)
  const [cardToRemove, updateCardToRemove] = React.useState(null)

  const [deviceSession, handleDeviceSession] = React.useState(null)

  const handleShowCardRemove = (card) => {
    console.log('show remove Card Panel')
    updateCardToRemove(card)
    handleRemoveCard(!showRemoveCard)
    setTimeout(() => {
      handleErrorCard(false)
      handleSuccessCard(false)
    }, 800)
  }

  const updateNewCard = (newCard) => {
    const newCardList = [...cardsList, newCard]
    // handleShowCard(false);
    updateCardList(newCardList)
  }

  const removeCard = (cardData) => {
    console.log('Remove Card', cardData)
    handleSubmit(true)
    // API.removeCardFeenicia( cardData.id )
    // .then( response => {
    //   console.log( response );
    //   callbackRemoveCard( response.data );
    // } )
    // .catch( err => {
    //   console.error( err );
    //   callbackRemoveCard( err );
    // });
    API.postOpenPayCardsDelete(cardData.id)
      .then((response) => {
        console.log(response)
        callbackRemoveCard(response.data)
      })
      .catch((err) => {
        console.error(err)
        callbackRemoveCard(err)
      })
  }

  const callbackRemoveCard = (data) => {
    console.log(data)

    if (data.success) {
      console.log('success')
      handleRemoveCardSuccess(data)
    } else {
      console.error('errror')
      handleRemoveCardError(data)
    }
  }

  const handleRemoveCardError = (error) => {
    handleErrorCard(true, error)
    handleSubmit(false)
  }

  const handleSubmittingSave = () => {
    handleSubmitSave(!submittingSave)
  }

  const handleRemoveCardSuccess = (data) => {
    handleSuccessCard(true, data)
    handleSubmit(false)
    removeDeletedCard(cardToRemove)
  }

  const removeDeletedCard = (card) => {
    const updatedCards = cardsList.filter((c) => c.id !== card.id)

    console.log(updatedCards)

    updateCardList(updatedCards)
  }

  // React.useEffect( () => {}, [  ])

  React.useEffect(() => {
    console.log('isProduction:', process.env.NODE_ENV === 'production')
    console.log('isTest:', process.env.NODE_ENV === 'compile')
    console.log('isDev:', process.env.NODE_ENV === 'development')

    if (process.env.NODE_ENV === 'production') {
      window.OpenPay.setId('myabfqccohuj4kszwr7y')
      window.OpenPay.setApiKey('pk_e78ceae59eaf42f68c2dba4cbd147265')
    } else {
      window.OpenPay.setSandboxMode(true)
      window.OpenPay.setId('mqfki2pbqpbve54kabor')
      window.OpenPay.setApiKey('pk_f548ab805b93403b91009278e32e3fd4')
    }
    handleDeviceSession(window.OpenPay.deviceData.setup())

    API.fetchOpenPayCards()
      .then((response) => {
        console.log(response)
        updateCardList(response.data.cards)
        handleCardsReady(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const conditionalComponent = (
    <div className='flex-col col-center'>
      <Modal
        show={submittingSave}
        className='responseDepositModal absoluteModal'
        dialogClassName='responseDepositDialog'
        backdropClassName='responseDepositBackdrop'
      >
        <div className='flex-container justify align column'>
          <i className='ion-load-a loading'> </i>
          <div className='flex-row row-center'>
            <div className='flex-col col-center text-center'>
              <strong>Estamos guardando tu tarjeta...</strong>
            </div>
          </div>
        </div>
      </Modal>
      <RemoveCardPanel
        submitting={submitting}
        handleShowCardRemove={handleShowCardRemove}
        cardToRemove={cardToRemove}
        show={showRemoveCard}
        isError={isError}
        isSuccess={isSuccess}
        removeCard={removeCard}
      />
      <section className='category-user row'>
        <div className='mis-documentos'>
          <div className='col-xs-10 col-xs-offset-1 titulo-user-info'>
            <div className='flex-col col-15'>
              <img src={misTarjetas} className='title-icon' />
            </div>
            <div className='flex-col '>
              <span>Tarjetas Guardadas</span>
            </div>
          </div>
          <CardsList
            cardsReady={cardsReady}
            cardsList={cardsList}
            handleShowCardRemove={handleShowCardRemove}
          />
        </div>
      </section>
      <section className='category-user row '>
        {cardsList.length >= 3
          ? null
          : (
            <div className='flex-row '>
              <Button
                className='flex-col callToAction col-center btn-default btn-pick'
                onClick={() => {
                  handleShowCard(!showAddCard)
                }}
              >
                <div className='flex-row row-no-padding row-center'>
                  <div className='flex-col'>
                    <i className='ion-android-add-circle' style={{ marginRight: '4px' }} />
                    Agregar Tarjeta
                  </div>
                </div>
              </Button>
            </div>
            )}

        <div className='mis-documentos-data flex-row row-no-padding row-center'>
          {showAddCard
            ? (
              <div className='flex-col '>
                <AddCardOpenPay
                  deviceSession={deviceSession}
                  handleShowCard={handleShowCard}
                  blockUi={handleSubmittingSave}
                  show
                  justSave
                  dispatch={null}
                  resetData={handleShowCard}
                  updateCardList={updateNewCard}
                  updateUserInfo={updateUserInfo}
                  updateUserBalance={updateUserBalance}
                />
              </div>
              )
            : null}
        </div>
      </section>
    </div>
  )

  return conditionalComponent
}
