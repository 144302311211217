import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'

// Assets
import pickSVG from '../../../assets/img/elements/pickwin-pick.svg'

export default function Image (props) {
  const [loaded, setLoaded] = useState(false)

  return (
    <Box>
      {!loaded && <SkeletonLoader src={pickSVG} />}
      <ImageComponent
        {...props}
        style={{
          display: loaded ? 'block' : 'none',
        }}
        className='game-thumbnail img-responsive'
        onLoad={() => setLoaded(true)}
      />
    </Box>
  )
}


const anima = keyframes`
  0% {
    transform: translateZ(0);
    background-position: -268px 0
  }
  to {
    transform: translateZ(0);
    background-position: 268px 0
  }
`

const show = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const SkeletonLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(90deg, #020202 8%, #406d0699 18%, #020202 33%);
  background-size: 800px 104px;
  transform: translateZ(0);
  background-position: -268px 0;
  animation: ${show} 500ms ease forwards, ${anima} 1.2s linear infinite forwards 0.3s;
`

const Box = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0.4em;
  position: relative;
`

const ImageComponent = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  animation: ${show} 200ms ease forwards;
`
