import { useMemo } from 'react'
import propTypes from 'prop-types'
import getShortcutStyles from '../utilities/getShortcutStyles'

export default function useStyleShortcuts ({ style, ...others }) {
  // We build a styles on steroids with shortcuts to build easy and clean layouts
  // ------------------------------------------------------------------------------
  // Example of use in a Text component
  // <Text size={12} color='lime' center>Example</Text>
  // ------------------------------------------------------------------------------

  const stylesWithShortCuts = useMemo(
    () => getShortcutStyles(style, others),
    [
      style,
      others.color,
      others.bg,
      others.bgColor,
      others.background,
      others.weight,
      others.size,
      others.lineHeight,
      others.bg,
      others.margin,
      others.m,
      others.mr,
      others.mt,
      others.ml,
      others.mx,
      others.my,
      others.padding,
      others.p,
      others.pt,
      others.pr,
      others.pb,
      others.pl,
      others.px,
      others.py,
      others.textAlign,
      others.width,
      others.height,
      others.center,
      others.left,
      others.right,
      others.maxWidth
    ]
  )

  return stylesWithShortCuts
}

const numberOrString = propTypes.oneOfType([
  propTypes.string,
  propTypes.number
])

export const shorthandProptypes = {
  color: propTypes.string,
  weight: propTypes.string,
  bg: propTypes.string,
  background: propTypes.string,
  bgColor: propTypes.string,
  textAlign: propTypes.string,
  height: numberOrString,
  width: numberOrString,
  size: numberOrString,
  lineHeight: numberOrString,
  margin: numberOrString,
  m: numberOrString,
  mx: numberOrString,
  my: numberOrString,
  mt: numberOrString,
  mr: numberOrString,
  mb: numberOrString,
  ml: numberOrString,
  p: numberOrString,
  px: numberOrString,
  py: numberOrString,
  pt: numberOrString,
  pr: numberOrString,
  pb: numberOrString,
  pl: numberOrString,
  maxWidth: numberOrString
}
