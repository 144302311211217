import React from 'react';

const PointsDistribution = ( { pointsDistribution, isPopUp } ) => {
  // console.log( pointsDistribution );

  let distList = pointsDistribution.map( d => {
    return (
      <div key={ d.position } className='leaderboard-item flex-row'>
          <div className='flex-col col-center col-50'>
            {d.position} °
          </div>
          <div className='flex-col'>
          <div className='flex-container end align'>
            { d.points }pts
          </div>
          </div>
      </div>
    )
  } )
  return  (
    <div className='flex-col panel-pickwin col-no-padding border-gradient' style={ isPopUp ? { } : { margin:'10px'}}>
      <div className='flex-row row-no-padding title-quiniela row-center uppercase'>
        <i className=' ion-android-list pool-icons' style={{margin:'5px'}} />Sistema de puntuación
      </div>
      <div className='flex-col leaderboard-list'>
        <div className='flex-row row-no-padding header-leaderboard'>
          <div className='flex-col col-center col-50'>
            Posición
          </div>
          <div className='flex-col '>
            <div className='flex-container end align'>
              Puntos
            </div>
          </div>
        </div>
      <div className={` flex-col col-no-padding overflow overflow-y ${ isPopUp ? 'isPopup' : 'overflow-200' } `}>
        { distList }
      </div>
      </div>
    </div>
  )
}

export default PointsDistribution;
