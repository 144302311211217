import styled from 'styled-components'

const TextLink = styled.div`
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  margin-bottom: 2em;

  :hover {
    opacity: 0.8;
  }
`

export default TextLink
