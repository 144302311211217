import React from 'react';

import utils from '../../utils/utils';

import numeral from 'numeral';

import billete from '../../assets/images/cash1-50.png';
import regalo from '../../assets/images/gift-50.png';
import ticketIcon from '../../assets/images/silver_ticket_pick_empty.png';

const TablaPosMisQuinielaGrupo = ({
  standings,
  poolInfo,
  followUser,
  myEntry,
}) => {
  let userData = utils.getUserInfo();

  if (!userData) {
    return null;
  }

  let isLive = poolInfo.status === 'live';
  let currencyPrizeIcon = null;
  let pickcoins;

  switch (poolInfo.prize_currency) {
    case 'real':
      currencyPrizeIcon = billete;
      break;
    case 'picoin':
      currencyPrizeIcon = pickcoins;
      break;
    case 'gift':
      currencyPrizeIcon = regalo;
      break;
    case 'ticket':
      currencyPrizeIcon = ticketIcon;
      break;
  }

  let rowUser = (
    <li className="position-item user flex-row row-no-padding row-center">
      <div className="posiciones-category col-center friends col-5 flex-col"></div>
      <div className="posiciones-category rank flex-col col-10 col-no-padding">
        <span>{myEntry.group_pool_standing.rank}°</span>
      </div>
      <div className="posiciones-category flex-col col-30 jugador">
        <span>{`${userData.nick}`}</span>
        <span style={{ fontSize: 'smaller', margin: '7px 0px 0 2px' }}>{`${
          myEntry.number !== null
            ? '[' + myEntry.number + ']'
            : ''
          }`}</span>
      </div>
      <div className="posiciones-category aciertos flex-col col-15 col-no-padding">
        <span>{`${myEntry.group_pool_standing.points}`} </span>
      </div>
      <div className="posiciones-category currency flex-col col-10 col-no-padding">
        <img src={currencyPrizeIcon} className="pool-icons" />
      </div>
      {poolInfo.entry_currency === 'pickcoin' ? (
        <div className="posiciones-category a-ganar flex-col col-30">
          {myEntry.user_prize !== undefined && myEntry.user_prize !== null
            ? numeral(myEntry.user_prize.base_amount / 100).format('0,000')
            : numeral(0).format('0,000')}
        </div>
      ) : poolInfo.prize_currency === 'ticket' || poolInfo.prize_currency === 'gift' ? (
        <div className="posiciones-category flex-col col-30 a-ganar">
          {poolInfo.status === 'upcoming'
            ? '-'
            : myEntry.user_prize !== undefined && myEntry.user_prize.description !== null
              ? myEntry.user_prize.description
              : '-'}
        </div>
      ) : (
            <div className="posiciones-category a-ganar a-ganar flex-col col-30 col-center text-center ">
              <div className="flex-container justify align">
                {numeral(myEntry.group_pool_user_prize / 100).format('$0,000.00')}
              </div>
            </div>
          )}
    </li>
  );

  // let buttonFollowFriend = (followSuccess, followError, followUser, user) => {
  //   let conditionalComponent = null;

  //   if (
  //     (utils.isLogged() && user && user.user_id !== utils.getUserInfo().id) ||
  //     user.friend !== 0
  //   ) {
  //     if (!followSuccess && !followError) {
  //       conditionalComponent = (
  //         <div className="posiciones-category friend quina flex-container align justify">
  //           <span
  //             className={` flex-row row-center btn-addFriend row-no-padding`}
  //             onClick={() => {
  //               followUser(user.user_id);
  //             }}
  //           >
  //             <i className="ion-plus-round flex-col col-center"></i>
  //           </span>
  //         </div>
  //       );
  //     } else if (followSuccess) {
  //       conditionalComponent = (
  //         <div className="flex-container align justify friend quina position-category successFollow">
  //           <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
  //         </div>
  //       );
  //     } else if (followError) {
  //       conditionalComponent = (
  //         <div className="flex-container align justify friend quina position-category errorFollow">
  //           <i className="ion-close-round " style={{ color: '#e00034' }}></i>
  //         </div>
  //       );
  //     }
  //   }

  //   return conditionalComponent;
  // };
  let listItems = [];

  // console.log( standings );

  if (!standings || standings.length < 1) {
    listItems = (
      <div className="flex-container jusityf align">
        <span> No hay participantes disponibles</span>
      </div>
    );
  } else {
    //  let tempStandings = standings.splice(0, 5);

    listItems = standings.map((user, i) => {
      if (i >= 5) {
        return null;
      }

      const followSuccess = false;
      const followError = false;

      // FIXME: No entiendo que debe de pasar aqui
      // const followSuccess = !followSuccess
      //   ? false
      //   : followSuccess.find((success) => success.status && success.id === user.user_id);
      // const followError = !followError
      //   ? false
      //   : followError.find((error) => error.status && error.id === user.user_id);

      let buttonFollowFriend = (followSuccess, followError, followUser, user) => {
        let conditionalComponent = null;

        if (
          (utils.isLogged() && user && user.user_id !== utils.getUserInfo().id) ||
          user.friend !== 0
        ) {
          if (!followSuccess && !followError) {
            conditionalComponent = (
              <div className="posiciones-category friend quina flex-container align justify">
                <span
                  className={` flex-row row-center btn-addFriend row-no-padding`}
                  onClick={() => {
                    followUser(user.user_id);
                  }}
                >
                  <i className="ion-plus-round flex-col col-center"></i>
                </span>
              </div>
            );
          } else if (followSuccess) {
            conditionalComponent = (
              <div className="flex-container align justify friend quina position-category successFollow">
                <i className="ion-checkmark-round " style={{ color: '#78bc28' }}></i>
              </div>
            );
          } else if (followError) {
            conditionalComponent = (
              <div className="flex-container align justify friend quina position-category errorFollow">
                <i className="ion-close-round " style={{ color: '#e00034' }}></i>
              </div>
            );
          }
        }

        return conditionalComponent;
      };

      return (
        <li
          key={user.group_pool_entry_id}
          className={`position-item ${
            utils.isLogged() && utils.getUserInfo().id === user.user_id ? 'user' : ''
            }`}
        >
          <div className="posiciones-category col-center friends col-5 flex-col">
            {buttonFollowFriend(followSuccess, followError, followUser, user)}
          </div>
          <div className="posiciones-category rank flex-col col-10">
            <span>{user.rank}°</span>
          </div>
          <div className="posiciones-category jugador flex-col col-30">
            <span>{`${user.user.nick}`}</span>
            <span
              style={{
                fontSize: 'smaller',
                margin: '7px 0px 0 2px',
              }}
            >{`${
              user.group_pool_entry.number !== null
                ? '[' + user.group_pool_entry.number + ']'
                : ''
              }`}</span>
          </div>
          <div className="posiciones-category aciertos flex-col col-15">
            {<span>{`${user.points}`} </span>}
          </div>
          <div className="posiciones-category flex-col col-10 currency">
            <span>
              <img src={currencyPrizeIcon} className={`pool-icons `} />
            </span>
          </div>
          {poolInfo.prize_currency === 'pickcoin' ? (
            isLive ? (
              <div className="posiciones-category a-ganar">
                <span className="cant">
                  {numeral(user.user_prize.base_amount / 100).format('0,000')} PC
                </span>
              </div>
            ) : (
                <div className="posiciones-category a-ganar flex-col col-30 ">
                  <span className="cant">
                    {' '}
                    {numeral(user.user_prize.base_amount / 100).format('0,000')}
                    {' PC'}
                  </span>
                </div>
              )
          ) : poolInfo.prize_currency === 'ticket' || poolInfo.prize_currency === 'gift' ? (
            isLive ? (
              <div className="posiciones-category a-ganar flex-col col-30 ">
                <span className="cant">
                  {user.user_prize.description !== null ? user.user_prize.description : '-'}
                </span>
              </div>
            ) : poolInfo.status === 'upcoming' ? (
              <div className="posiciones-category a-ganar">
                <span className="cant"> - </span>
              </div>
            ) : (
                  <div className="posiciones-category a-ganar flex-col col-30 ">
                    <span className="cant"> {user.user_prize.description} </span>
                  </div>
                )
          ) : isLive ? (
            <div className="posiciones-category a-ganar flex-col col-30 text-center col-center ">
              <div className="flex-container justify align">
                {numeral(user.user_prize.amount / 100).format('$0,0000.00')}
              </div>
            </div>
          ) : (
                  <div className="posiciones-category a-ganar flex-col col-30 col-center text-center  ">
                    <div className="flex-container justify align">
                      {numeral(user.user_prize.amount / 100).format('$0,0000.00')}
                    </div>
                  </div>
                )}
        </li>
      );
    });
  }

  return (
    <div className="position-list border-gradient">
      <div className="title-quiniela mis sub">
        <span>Posiciones</span>
      </div>
      <div>
        <ul className="lista-posiciones quiniela">
          <li className="position-header flex-row row-no-padding row-center ">
            <div className="flex-col posiciones-category "></div>
            <div className="posiciones-category rank flex-col col-10 col-no-padding">
              <span>Mi pos</span>
            </div>
            <div className="posiciones-category jugador flex-col col-30 col-no-padding"></div>
            <div className="posiciones-category aciertos flex-col col-15 col-no-padding">
              <span>Puntos</span>
            </div>
            <div className="posiciones-category  flex-col col-10 col-no-padding">
              <span />
            </div>
            <div className="posiciones-category a-ganar flex-col col-30">
              <div className="flex-container justify align">
                {poolInfo.closed ? (
                  <span className="flex-container justify align">Ganó</span>
                ) : (
                    <span className="flex-container justify align"> A Ganar</span>
                  )}
              </div>
            </div>
          </li>
          {rowUser}
          <li className="position-header flex-row row-no-padding row-center">
            <div className="posiciones-category flex-col  col-center ">
              <i
                className="ion-ios-people flex-container justify align"
                style={{ fontSize: '1.5em' }}
              ></i>
            </div>
            <div className="posiciones-category  flex-col col-10 col-no-padding">
              <span>Pos</span>
            </div>
            <div className="posiciones-category  flex-col col-30 col-no-padding">
              <span>Jugador</span>
            </div>
            <div className="posiciones-category  flex-col col-15 col-no-padding">
              <span>Puntos</span>
            </div>
            <div className="posiciones-category  flex-col col-10 col-no-padding">
              <span />
            </div>
            <div className="posiciones-category  flex-col col-30 col-center">
              {poolInfo.closed ? <span>Ganó</span> : <span>A Ganar</span>}
            </div>
          </li>
          {listItems}
        </ul>
      </div>
    </div>
  );
};

export default TablaPosMisQuinielaGrupo;
