import { useState, useEffect } from 'react'
import Fingerprint2 from 'fingerprintjs2'
import * as Sentry from '@sentry/browser'
import Cookies from 'js-cookie'
import moment from 'moment'

// Key fot localStorage
const KEY = 'fingerprint'

// It's lag time for legacy browsers that
// don't support requestIdleCallback
const GENERATE_DELAY_MS = 1000

// This hook preserves the fingerprint
// and create new if user cleanup the localStorage
export default function useFingerprint() {
  const [fingerprint, setFingerprint] = useState(localStorage.getItem(KEY))

  useEffect(() => {
    getFingerprint().then(setFingerprint)
  }, [])
  

  // Auto update fingerprint if key is removed by the user
  useEffect(() => {
    const listener = async (e) => {
      if (e.storageArea === window.localStorage && e.key === KEY) {
        getFingerprint().then(setFingerprint)
      }
    }

    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])

  return fingerprint
}

// Resolve a fingerprint and save on localstore
// if not exists create new
function getFingerprint() {
  return new Promise((resolve, reject) => {
    const fingerPrintStorage = localStorage.getItem(KEY)
    const fingerprintExist = !fingerPrintStorage ? false : true

    if (
        fingerprintExist &&
        fingerPrintStorage !== '' &&
        fingerPrintStorage !== 'null' &&
        fingerPrintStorage !== 'undefined'
       ) {
      return resolve(localStorage.getItem(KEY))
    }

    function runFingerprint () {
      getGeneratedFingerprint()
       .then(resolve)
       .catch(reject)
    }

    // To ensure consistent fingerprints
    // We need await some miliseconds
    if (window.requestIdleCallback) {
      requestIdleCallback(runFingerprint)
    } else {
      setTimeout(runFingerprint, GENERATE_DELAY_MS)
    }
  })
}

function getGeneratedFingerprint () {
  return new Promise((resolve, reject) => {
    Fingerprint2.getPromise({})
      .then((components) => {
        const values = components.map((component) => {
          return component.value
        })

        const salt = moment().unix()
        const toHash = salt + '' + values.join('')
        const fingerPrint = Fingerprint2.x64hash128(toHash, 31)
        const cookieFingerprint = Cookies.get(KEY)

        if (cookieFingerprint === undefined) {
          Cookies.set(KEY, fingerPrint)
          localStorage.setItem(KEY, fingerPrint)
          resolve(fingerPrint)
        } else {
          localStorage.setItem(KEY, fingerPrint)
          resolve(fingerPrint)
        }
      })
      .catch((e) => {
        console.error(e)
        Sentry.captureException(e)
        reject(e)
      })
  })
}

