import React from 'react';
import Loading from '../Loading';
import avatar from '../../assets/images/avatar-50.png';
import InfiniteScroll from 'react-infinite-scroller';
import MisPicks from './MisPicks';

/**
 * This component renders a list of the standings
 */
const PicksList = ({
  survivorInfo,
  myEntry,
  standings,
  gameStatus,
  survivorWeeks,
  currentWeek,
}) => {
  let userItem = standings.items.map((standing) => {
    // console.log(standing.survivor_picks)
    let picksList = survivorWeeks.map((weekPick) => {
      let currentPick =
        !standing.survivor_picks || !standing.survivor_picks[weekPick.id]
          ? null
          : standing.survivor_picks[weekPick.id];
      let isThisWeek = weekPick.id === currentWeek.id;

      if (!currentPick) {
        return (
          <div key={standing.survivor_entry_id + weekPick.id} className="item-container">
            <div className="img-container">
              {isThisWeek && standing.alive ? (
                <div className={`shirt-picks withoutPick`}>
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                </div>
              ) : standing.elimination_week_id < weekPick.id ? null : (
                <div className={`shirt-picks withoutPick underline`}>
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                  {/*<span>N/D</span>*/}
                </div>
              )}
            </div>
          </div>
        );
      } else {
        let pickStatus = '';

        currentPick.elimination = standing.elimination_week_id === weekPick.id;
        // currentPick['eliminated_this_week'] = standing.elimination_week_id === weekPick.id;
        if (!survivorInfo.strikes_enabled) {
          if (currentPick.st !== 2 && currentPick.en && !currentPick.correct) {
            pickStatus = 'perdio';
          }
          if (currentPick.st === 2 && currentPick.en && !currentPick.correct) {
            pickStatus = 'underline';
          }
          if (currentPick.en && currentPick.correct) {
            pickStatus = 'gano';
          }
        } else {
          if (currentPick.en && currentPick.strike && !currentPick.elimination) {
            pickStatus = 'strike';
          }
          if (currentPick.en && currentPick.strike && currentPick.elimination) {
            pickStatus = 'strike underline';
          }
          // live
          if (
            currentPick.st !== 2 &&
            currentPick.en &&
            !currentPick.correct &&
            !currentPick.strike
          ) {
            pickStatus = 'perdio';
          }
          //closed
          if (
            currentPick.st === 2 &&
            currentPick.en &&
            !currentPick.correct &&
            !currentPick.strike
          ) {
            pickStatus = 'underline';
          }
          if (currentPick.en && currentPick.correct) {
            pickStatus = 'gano';
          }
        }

        return (
          <div key={standing.survivor_entry_id + '' + weekPick.id} className="item-container">
            <div className="img-container">
              {currentPick.en === 1 ? (
                <div className={`shirt-picks ${pickStatus}`}>
                  <i className={`${currentPick.logo} home helmet`}></i>
                  <small className="team-name">
                    {currentPick.team}{' '}
                    {survivorInfo.point_format === 'line' ? (
                      <span className="linea"> {currentPick.advantage} </span>
                    ) : null}{' '}
                  </small>
                </div>
              ) : (
                <div className={`shirt-picks ${pickStatus}`}>
                  {currentPick.pick ? (
                    <i className="ion-checkmark" style={{ color: 'rgb(120, 180, 40)' }}></i>
                  ) : (
                    <i className="ion-close-circled" style={{ color: 'rgb(120, 180, 40)' }}></i>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      }
    });

    return (
      <li key={standing.survivor_entry_id} className={`lower-table-item user  `}>
        {picksList}
      </li>
    );
  });
  return <ul className="list-unstyled lower-list">{userItem}</ul>;
};

/**
 * This component renders a list of the standings
 */
const UsersList = ({ standings, myEntry }) => {
  let usersList = standings.items.map((standing) => {
    // console.log(standing)
    return (
      <ul key={standing.survivor_entry_id} className="list-unstyled lower-list">
        <li
          className={`lower-table-item user ${
            myEntry !== null && myEntry.id === standing.survivor_entry_id ? 'ownUser' : ''
          }`}
        >
          <div className="item-container">
            <div className="image-container">
              <img
                className="avatarPicksGrupo"
                src={standing.avatar === '/img/profile-image-01.png' ? avatar : standing.avatar}
              />
            </div>
            <span className="picks-rank"> {standing.rank}°</span>
            <span style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number !== null ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px',
                  }}
                >
                  {standing.number}
                </sup>
              ) : (
                ''
              )}
            </span>
          </div>
        </li>
      </ul>
    );
  });

  return (
    <div className="overflow overflow-y overflow-picks-grupo userList sync-vertical">
      {usersList}
    </div>
  );
};

/**
 * This component renders a list of the standings
 */
const AliveList = ({ standings, myEntry }) => {
  let totalesList = standings.items.map((standing) => {
    // console.log(standing)
    return (
      <li
        key={standing.survivor_entry_id}
        className={`lower-table-item ${
          myEntry !== null && myEntry.id === standing.survivor_entry_id ? 'ownUser' : ''
        }`}
      >
        <div className="item-container">
          <div className="shirt-picks">
            {standing.alive === 1 ? (
              <i className="ion-checkmark" style={{ color: 'rgb(120, 180, 40)' }}></i>
            ) : (
              <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
            )}
          </div>
        </div>
      </li>
    );
  });
  return (
    <ul className="list-unstyled lower-list" style={{ width: '100%' }}>
      {totalesList}
    </ul>
  );
};

class TablaPicksGrupoSurvivor extends React.Component {
  render() {
    // console.log( this.props );
    let conditionalComponent = null;
    if (this.props.dataReady) {
      conditionalComponent = (
        <div className="lista-container">
          <UsersList
            standings={this.props.picksGrupal}
            myEntry={
              this.props.withRegister && this.props.entriesInfo
                ? this.props.entriesInfo.survivor_entry
                : null
            }
          />
          <div className="overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal">
            <InfiniteScroll
              pageStart={1}
              loadMore={this.props.siguientePag}
              hasMore={
                this.props.picksGrupal && this.props.picksGrupal.pages === this.props.actualPagina
                  ? false
                  : true
              }
              loader={<Loading key={'labelLoader00'} label="..." />}
              useWindow={false}
            >
              {
                <PicksList
                  survivorInfo={this.props.survivorInfo}
                  currentWeek={this.props.currentWeek}
                  standings={this.props.picksGrupal}
                  gameStatus={this.props.survivorInfo.status}
                  survivorTeams={this.props.survivorTeams}
                  survivorWeeks={this.props.survivorWeeks}
                  myEntry={this.props.withRegister ? this.props.entriesInfo.survivor_entry : null}
                />
              }
            </InfiniteScroll>
          </div>
          <div
            className="overflow overflow-y overflow-picks-grupo totalesList sync-vertical"
            style={{ width: '7%' }}
          >
            <AliveList
              standings={this.props.picksGrupal}
              myEntry={this.props.withRegister ? this.props.entriesInfo.survivor_entry : null}
            />
          </div>
        </div>
      );
    } else {
      conditionalComponent = <Loading label="..." />;
    }
    return (
      <div className="tabla-grupos">
        <div className="lower-table border-gradient">
          <div className="row-grupos header font-chivo">
            <div className="lower-table-item user title">
              <span>PICKWINERO</span>
            </div>
            <div
              className="lower-table-item total"
              style={{ justifyContent: 'center', paddingRight: 0 }}
            >
              <span> VIVO </span>
            </div>
          </div>
          <MisPicks
            pointFormat={this.props.survivorInfo.point_format}
            survivorFixtures={this.props.fixtures}
            gameStatus={this.props.survivorInfo.status}
            selectedWeek={this.props.selectedWeek}
            currentWeek={this.props.currentWeek}
            withRegister={this.props.withRegister}
            myEntry={this.props.withRegister ? this.props.entriesInfo.survivor_entry : null}
            survivorTeams={this.props.survivorTeams}
            survivorWeeks={this.props.survivorWeeks}
          />
          {conditionalComponent}
        </div>
      </div>
    );
  }
}

export default TablaPicksGrupoSurvivor;
