import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'

// Components
import { Pick } from './Pick'
import Clock from '../../Clock'
import { Iconify } from '../../../Xmerald-UI'

moment.locale('es')


export const WeekItem = (props) => {
  const {
    week,
    allFixtures,
    handlePick,
    removePick,
    currentEntry,
    teams,
    pointFormat,
    updateStartedFixture,
    survivorInfo,
    survivorEntryData,
    updateStartedFirstMatchOfWeek,
    colapsedStatus,
    toggleColapsed
  } = props

  const { isExpanded = false } = colapsedStatus || {}
  const firstOfWeek = allFixtures?.firstsOfWeek[week.id]
  const selectedPick = survivorEntryData?.survivor_picks?.[week.id];
  const selectedPickEntry = currentEntry?.survivor_picks?.[week.id];


  const selectedTeam = teams.find(team => team.id === selectedPick?.pick)

  // Revisar si ya esta selecionado en otra semana
  // lista de ids seleccionados
  const selectedOtherWeeks = Object
    .values(survivorEntryData?.survivor_picks || {})
    .reduce((a,b) => ( { ...a, [b.pick]: true }), {})

  const closingDate = moment(firstOfWeek?.deadline)
 
  // Cambia el color de el contador dependiendo del tiempo restante
  // para comenzar el survivor
  let lessThan24Hours = firstOfWeek?.time_left < 86400
  let lessThan1Hour = firstOfWeek?.time_left < 3600

  const toggle = () => {
    toggleColapsed(week.id, !isExpanded)
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    e.preventDefault()

    removePick(selectedPickEntry?.id, week.id)
  }

  const fixtureFirts = allFixtures?.fixtures[week.id]?.[0]
  const isUserSelectedFirstMatch = fixtureFirts?.home_team?.id === selectedTeam?.id || fixtureFirts?.away_team?.id === selectedTeam?.id


  function getDisabledRemove () {
    const firstMatch = allFixtures?.fixtures[week.id]?.[0]
    const isPassedDeadlineWeek = firstOfWeek?.time_left <= 0
  
    // Si ya paso el primer partido
    const isPassedFirstMatchDate = firstMatch?.survivor_time_left <= 0

    let isDisabled = isPassedDeadlineWeek
  
    // Soccer
    if (survivorInfo.sport_id === 1 && isPassedFirstMatchDate) {
      isDisabled = true
    } else {
      // NFL
      if (isPassedFirstMatchDate && isUserSelectedFirstMatch) {
        isDisabled = true
      } else if (!isUserSelectedFirstMatch && isPassedDeadlineWeek) {
        isDisabled = true
      }
    }
    return !isDisabled
  }

  return (
    <WeekPanel>

        <Panel onClick={toggle}>
          <Label className='flex'>
            <div><Iconify icon={isExpanded ? 'eva:arrow-down-fill': 'eva:arrow-right-fill'} /></div>
            <div className='name'>{week.week_name}</div>
          </Label>
          
          <Team>
              {selectedTeam && (
                <>
                  <TeamName>
                    <i class={'team-logo home ' + selectedTeam?.logo}></i>
                    <span>{selectedTeam?.short_name}</span>
                  </TeamName>

                  {getDisabledRemove() && (
                    <RemoveButton onClick={handleRemove}><Iconify icon='raphael:cross' /></RemoveButton>
                  )}
                </>
              )}

              {(!selectedTeam && currentEntry?.survivor_standing?.alive) &&  (
                <NotSelectedTeam>ELIGE UN EQUIPO</NotSelectedTeam>
              )}
          </Team>
          
          <Date>
            {(firstOfWeek?.enabled && firstOfWeek?.time_left > 0) && (
              lessThan1Hour ? (
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <Clock
                    type='row'
                    updateStartedFixture={() => updateStartedFixture(null, firstOfWeek)}
                    fixtureID={firstOfWeek?.id}
                    closingDate={closingDate}
                  />
                </div>
              ) : (
                <span>
                  <span
                    style={
                      lessThan24Hours
                        ? { color: '#ff4047' }
                        : { color: '#78BC28' }
                    }
                  >
                    {closingDate.format('ddd DD MMM')}
                  </span>
                  <span
                    style={
                      lessThan24Hours
                        ? { color: '#ff4047' }
                        : { color: '#78BC28' }
                    }
                  >
                    {closingDate.format('HH:mm ')}
                  </span>
                </span>
              )
            )}
          </Date>
        </Panel>

        {isExpanded && (
          <PickList>
            <ListHeader>
              <div className='team'>LOCAL</div>
              <div className='team'>VISITANTE</div>
              <div className='end'>CIERRE</div>
            </ListHeader>

            <div className='picks'>
              {allFixtures?.fixtures[week.id]?.map((fixture_, index) => (
                <Pick
                  key={fixture_?.id}
                  fixture={fixture_}
                  firstOfWeek={firstOfWeek}
                  week={week}
                  isFirstMatch={index === 0}
                  firstMatch={allFixtures?.fixtures[week.id]?.[0]}
                  prevFixture={allFixtures?.fixtures[week.id][index - 1]}
                  handlePick={handlePick}
                  selectedPick={selectedPick}
                  selectedOtherWeeks={selectedOtherWeeks}
                  selectedTeam={selectedTeam}
                  currentEntry={currentEntry}
                  pointFormat={pointFormat}
                  isUserSelectedFirstMatch={isUserSelectedFirstMatch}
                  survivorInfo={survivorInfo}
                  updateStartedFixture={updateStartedFixture}
                  updateStartedFirstMatchOfWeek={updateStartedFirstMatchOfWeek}
                />
              ))}
            </div>
          </PickList>
        )}
      </WeekPanel>
  )
}


const RemoveButton = styled.button`
  background-color: tomato;
  color: #000;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
`

const Team = styled.div`
  font-family: Chivo;
  font-style: italic;
  font-weight: bold;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6em;
  border-radius: 0.4em;
  height: 1.5em;
  padding-right: 0.3em;
  flex-grow: 2;
`

const TeamName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.85em;
`

const Date = styled.div`
  flex-basis: 85px;
  font-size: 9px;
  font-weight: bold;
  line-height: 1;
  color: #e5e5e5;
  text-transform: uppercase;
  margin-left: auto;
  text-align: right;
  font-family: sans-serif;

  .countdown-container {
    justify-content: flex-end !important;
  }
`

const NotSelectedTeam = styled.span`
  font-size: 0.8em;
  font-weight: normal;
  color: gray;
  text-align: left;
  display: block;
  flex-grow: 99;
  margin-left: 1em;
`

const Label = styled.div`
  display: flex;
  width: 3em;
  align-items: center;
  flex-basis: 40px;
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Lato', sans-serif;

  .name {
    font-size: 0.9em;
    line-height: 1;
    margin-left: 0.3em;
  }
`

const WeekPanel = styled.div`
  min-height: 2.4em;
  margin: 0.5em 0.5em;
  border: 1px solid #ffffff;
`

const Panel = styled.div`
  background-color: #1b1919;
  width: 100%;
  height: 2.4em;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  cursor: pointer;
  user-select: none;

  svg:first {
    font-size: 14px;
  }
`

const PickList = styled.div``

const ListHeader = styled.div`
  height: 2em;
  font-size: 0.75em;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-family: Chivo;
  text-align: center;
  border-bottom: 1px solid #c5c5c5;
  border-top: 1px solid #c5c5c5;

  .team {
    width: 40%;
    height: 100%;
    border-right: 1px solid #c5c5c5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .end {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
