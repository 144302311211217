import React, { useRef, useLayoutEffect } from 'react'

// Components
import { Typography, Box, InputCurrency, InputNumber } from '../../../Xmerald-UI'
import { useWizardPrivate } from '../wizardPrivate.store'
import WizardLayout from '../shared/Layout'

export default function SurvivorPrice () {
  const priceRef = useRef()
  const step = useWizardPrivate(state => state.step)
  const survivorData = useWizardPrivate(state => state.survivorData)
  const setSurvivorData = useWizardPrivate(state => state.setSurvivorData)

  useLayoutEffect(() => {
    priceRef.current?.focus()
  }, [priceRef, step])

  if (step !== 'SURVIVOR_PRICE') return null

  const disabledNext = (
    !survivorData.entry_fee ||
    survivorData.entry_fee < 0 ||
    survivorData.affiliate_commission === null ||
    Number(survivorData.affiliate_commission) > 15 ||
    Number(survivorData.affiliate_commission) < 0
  )

  const getFeeError = () => {
    if (Number(survivorData.entry_fee) < 0) {
      return 'No puede ser un precio negativo'
    }
  }

  const getComisionError = () => {
    if (Number(survivorData.affiliate_commission) < 0) {
      return 'No puede ser menor a 0%'
    }
    if (Number(survivorData.affiliate_commission) > 15) {
      return 'No puede ser mayor a 15%'
    }
  }

  const comisionError = getComisionError()

  return (
    <WizardLayout
      prevStep='SURVIVOR_DURATION'
      nextStep='SURVIVOR_AWARDS'
      disabledNext={disabledNext}
    >
      <Box>
        <InputCurrency
          label='Elige el precio de tú survivor.'
          name='entry_fee'
          prefix='$ '
          placeholder='$ 1000.00'
          value={survivorData.entry_fee}
          onChange={setSurvivorData}
          maxLength={5}
          maxWidth={350}
          ref={priceRef}
          error={getFeeError()}
          helpText={getFeeError()}
        />

        <InputNumber
          label='¿Cuánto quieres llevarte de comisión?'
          name='affiliate_commission'
          placeholder='15 %'
          suffix=' %'
          value={Number(survivorData.affiliate_commission)}
          error={comisionError}
          helpText={comisionError}
          min={0}
          max={15}
          maxWidth={90}
          onChange={({ affiliate_commission }) => {
            setSurvivorData({ affiliate_commission: String(affiliate_commission) })
          }}
        />

        <Typography center color='#a0a0a0' size='0.9em'>Tu comisión puede ser de 0 a 15%, Pickwin reservará 2% de comisión adicional para operación.</Typography>
      </Box>
    </WizardLayout>

  )
}
