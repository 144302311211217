import React, { useEffect } from 'react'

// Components
import { Typography, Box, Iconify } from '../../../Xmerald-UI'
import { useWizardPrivate } from '../wizardPrivate.store'

export default function QuinielaSuccess () {
  const step = useWizardPrivate(state => state.step)
  const createdPool = useWizardPrivate(state => state.createdPool)

  useEffect(() => {
    if (createdPool) {
      // Redirect to quiniela
      window.setTimeout(() => {
        const path = window.location.pathname.split('/')
        // if (path.length > 1 && path[1] === 'quiniela') {
        localStorage.setItem('createdPrivate', 'true')
        window.location = `/quiniela/${createdPool.slug}/${createdPool.id}`
        // }
      }, 800)
    }
  }, [createdPool])

  if (step !== 'QUINIELA_SUCCESS') return null

  return (
    <Box maxWidth={300}>
      <Typography center size='1.2em' mt={20}>Tu quiniela fue creada exitosamente</Typography>
      <Typography center size='1em' mt={20} mb={20}>Te estamos redirigiendo</Typography>
      <Typography center>
        <Iconify icon='ep:success-filled' size='3em' color='#8cc63f' />
      </Typography>
    </Box>
  )
}
