import './PanelSmsVerification.css'
import React, { useEffect, useState as useStateNative } from 'react'
import { Button } from 'react-bootstrap/lib'
import { Redirect } from 'react-router-dom'
import Formsy from 'formsy-react'
import * as Sentry from '@sentry/browser'
import VerificationInput from 'react-verification-input'

// Hooks
import useMergedState from '../../hooks/useMergedState'
import useCountdown from '../../hooks/useCountDown'

// Components
import FormsySms from './FormsySms'
import { ErrorMessageValidation } from '../../utils/quinielasComponents'

// Utils
import utils from '../../utils/utils'
import API from '../../utils/api'

const initialState = {
  countryCode: null,
  cellphone: null,
  status: null,
  isCodeSent: null,
  redirectToPrevPath: false,
  isCodeVerified: null,
  errorMessage: '',
  verificationCode: []
}

const STEPS = {
  PHONE_NUMBER: 'PHONE_NUMBER',
  SMS_CODE: 'SMS_CODE',
  SUCCESS: 'SUCCESS'
}

function FormSmsVerification (props) {
  const [state, setState] = useMergedState(initialState)
  const [currentStep, setCurrentStep] = useStateNative(STEPS.PHONE_NUMBER)
  const countDown = useCountdown(120)

  useEffect(() => {
    const notLoggedData = utils.getDataNotLoggedUser()
    if (notLoggedData) {
      setState({ prevPath: props.prevPath })
    }
  }, [])

  const enableButton = () => {
    setState({ canSubmit: true })
  }

  const disableButton = () => {
    setState({ canSubmit: false })
  }

  const handleCountryCode = (e) => {
    setState({ countryCode: e.target.value })
  }

  const handleCellphone = (e) => {
    setState({ cellphone: e.target.value })
  }

  /**
   * function submit the phone and code data to send the sms
   * it creates an object to send it to the server
   * @param smsData { Object }
   */
  // Step 1:
  const submitVerification = async (resend) => {
    setState({
      submitting: true,
      verificationCode: [0, 0, 0, 0, 0]
    })

    try {
      const smsData = {
        country: state.countryCode,
        phone: state.cellphone,
        code: [null, null, null, null, null]
      }

      const { data } = await API.requestSms(smsData)

      setState({ codeResended: resend === 'resend' })

      if (!data.success) {
        setState({
          status: 'Error',
          errorMessage: data.error,
          submitting: false
        })
      } else {
        countDown.reset()
        countDown.play()
        setCurrentStep(STEPS.SMS_CODE)
        setState({
          isCodeSent: true,
          status: 'Succeed',
          submitting: false
        })
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  }

  /**
   * function submit the verification code
   * it creates an object to send it to the server
   * @param smsData { Object }
   */
  const submitVerificationCode = async () => {
    setState({ submitting: true })
    const smsData = {
      country: state.countryCode,
      phone: state.cellphone,
      code: state.verificationCode
    }

    API.verifySms(smsData)
      .then(({ data }) => {
        if (!data.success) {
          setState({
            status: 'ErrorCode',
            errorMessage: data.error,
            submitting: false
          })
        } else {
          const userDataTemp = utils.getUserInfo()
          console.log('response user Data', data.user)
          console.log('after update', userDataTemp)

          userDataTemp.identity_verified = true

          const userBalance = utils.getUserBalance()

          const userDummy = {
            user: userDataTemp,
            balanceReal: userBalance.balanceReal,
            balancePcReal: userBalance.balancePcReal,
            balancePc: userBalance.balancePc
          }

          props.updateUserInfo(userDummy)
          setState({
            status: 'Verified',
            isCodeVerified: true,
            submitting: false
          })

          // Redirections
          setTimeout(() => {
            if (props.closePanel) {
              props.closePanel()
            } else if (props.prevPath) {
              setState({ redirectToPrevPath: true })
            } else {
              window.location = '/'
            }
          }, 800)
        }
      })
      .catch((e) => {
        console.error(e)
        Sentry.captureException(e)
      })
  }

  const isVerificationCodeCompleted = state.verificationCode.length === 5

  switch (currentStep) {
    case STEPS.PHONE_NUMBER: return (
      <Formsy
        onValidSubmit={submitVerification}
        onValid={enableButton}
        onInvalid={disableButton}
      >
        <div className='noticeVerification'>
          <div className='flex-row responsive-sm row-no-padding'>
            <h5 className='notice flex-col col-80 col-offset-10 text-center '>
              Como medida de seguridad, es necesario que verifiques tu cuenta a través de un
              mensaje de texto.
            </h5>
          </div>
          <div className='flex-row responsive-sm row-no-padding'>
            <h5 className='notice flex-col col-80 col-offset-10 text-center'>
              Pickwin te enviará un mensaje de texto (SIN COSTO para ti) con un código de 5
              números para verificar tú cuenta.{' '}
            </h5>
          </div>
        </div>
        <div className='flex-row  row-no-padding'>
          <h5 className='flex-col col-80 col-offset-10 '>
            <i className='ion-checkmark-circled' /> Escribe tu número celular sin espacios ni
            guiones{' '}
          </h5>
        </div>
        <div className='flex-row  row-no-padding'>
          <h5 className='flex-col col-80 col-offset-10 '>
            <i className='ion-checkmark-circled' /> Únicamente incluye la lada de tu ciudad (sin
            044)
          </h5>
        </div>
        <div className='flex-row responsive-sm '>
          <div className='flex-col col-80 col-offset-10'>
            <div className='flex-row'>
              <div className='flex-col col-35'>
                <FormsySms
                  label='País'
                  name='country'
                  inputHandler={handleCountryCode}
                  type='COUNTRY'
                  validations='isExisty'
                  validationerror='Este campo es obligatorio.'
                  required
                />
              </div>
              <div className='flex-col'>
                <FormsySms
                  label='Celular'
                  name='cellphone'
                  inputHandler={handleCellphone}
                  type='CELLPHONE'
                  validations='isNumeric'
                  validationError='Sólo puedes ingresar números.'
                  required
                />
              </div>
            </div>
          </div>
        </div>
        {state.status === 'Error'
          ? (
            <div className='flex-row responsive-sm'>
              <div className='flex-col col-80 col-offset-10'>
                <ErrorMessageValidation setDangeroulsy label={state.errorMessage} />
              </div>
            </div>
            )
          : null}

        <div className='flex-row responsive-sm'>
          <Button
            onClick={submitVerification}
            disabled={state.submitting || !state.canSubmit}
            className=' flex-col col-80 col-offset-10 btn btn-pick callToAction'
          >
            {state.submitting
              ? <i className='ion-load-a loading' />
              : <div>Enviar Código</div>}
          </Button>
        </div>
      </Formsy>
    )

    case STEPS.SMS_CODE: return (
      <Formsy
        onValidSubmit={(e) => {
          submitVerificationCode(e)
        }}
        onValid={enableButton}
        onInvalid={disableButton}
      >
        <div className='flex-row responsive-sm'>
          <div className='flex-col col-90 col-offset-5 col-center'>
            <div className='flex-container  column align justify'>
              <i className='ion-alert-circled alert flex-col col-10 no-padding' />
              <div className='flex-col  text-center'>
                <h4 className='text-center text-alert'>
                  El código que recibas únicamente será válido por los próximos 15 minutos.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className='flex-row row-no-padding'>
          <h5 className='flex-col col-80 col-offset-10 '>
            <i className='ion-checkmark-circled' /> Escribe tu código usando las casillas{' '}
          </h5>
        </div>
        <div className='flex-row responsive-sm'>
          <div className='flex-col col-80 col-offset-10'>
            <div className='flex-row'>
              <div className='box-input-sms'>
                <VerificationInput
                  validChars='0-9'
                  inputProps={{ type: 'tel' }}
                  length={5}
                  autoFocus
                  onChange={(code) => {
                    setState({
                      verificationCode: code.split('').map(item => Number(item))
                    })
                  }}

                  classNames={{
                    container: 'sms-container',
                    character: 'character',
                    characterInactive: 'character--inactive',
                    characterSelected: 'character--selected'
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {state.codeResended && (
          <div className='flex-container justify align column text-center'>
            <i className='ion-checkmark-circled success' />
            <strong>Tu código ha sido reenviado.</strong>
          </div>
        )}

        {state.status === 'ErrorCode' && (
          <div className='flex-row responsive-sm'>
            <div className='flex-col col-80 col-offset-10'>
              <ErrorMessageValidation setDangeroulsy label={state.errorMessage} />
            </div>
          </div>
        )}

        <div className='flex-row responsive-sm'>
          <Button
            onClick={() => {
              submitVerificationCode()
            }}
            disabled={state.submitting || !isVerificationCodeCompleted}
            className=' flex-col col-80 col-offset-10 btn btn-pick callToAction'
          >
            {state.submitting
              ? <i className='flex-container justify align ion-load-a loading small' />
              : <div> Verificar </div>}
          </Button>
        </div>
        <div className='flex-row responsive-sm'>
          <Button
            disabled={!countDown.isFinished}
            onClick={() => {
              submitVerification('resend')
            }}
            className=' flex-col col-80 col-offset-10 btn btn-pick'
          >
            <div className='flex-row row-center row-no-padding '>
              <div className='flex-col col-no-padding'> No he recibido mi código </div>
              {!countDown.isFinished && (
                <span>{countDown.leftTime}</span>
              )}
            </div>
          </Button>
        </div>
      </Formsy>
    )
  }

  if (state.redirectToPrevPath) {
    utils.removeDataNotLoggedUser()
    return <Redirect push replace to={{ pathname: props.prevPath }} />
  }

  if (!utils.isLogged() || utils.isVerified(utils.getUserInfo())) {
    return <Redirect push to={{ pathname: '/' }} />
  }

  if (state.status === 'Verified') {
    return (
      <div className='flex-col'>
        <div className='flex-container column justify align '>
          <i className='ion-checkmark-circled success' />
          <div>¡Felicidades, tú cuenta se verificó con éxito!</div>
        </div>
      </div>
    )
  }

  return null
}

export default FormSmsVerification
