import React from 'react';
import CrearQuinielaFormsy from './CrearQuinielaFormsy';

import Formsy from 'formsy-react';
import { addValidationRule } from 'formsy-react';

import { Redirect } from 'react-router-dom';

import {
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  ControlLabel,
} from 'react-bootstrap/lib';
import API from '../../utils/api';
import utils from '../../utils/utils';
import moment from 'moment';

import emojiRegex from 'emoji-regex';

import { LoadingProcess, ErrorMessage } from '../../utils/quinielasComponents';
import _ from 'underscore';

addValidationRule('maxValueCommission', (values, value) => {
  // console.log('Adding Validation Rule',  values, value, this);
  return Number(value) <= 15;
});
addValidationRule('maxPrizes', (values, value) => {
  // console.log('Adding Validation Rule',  values, value, this);
  return Number(value) <= 10;
});

addValidationRule('minRegister', (values, value) => {
  console.log('minRegister Validation', values, value, this);
  return Number(value) > 0;
});

addValidationRule('notEmoticons', (values, value) => {
  console.log('notEmoticon Validation');

  let regEx = emojiRegex();

  console.log(!regEx.test(value));

  console.log(!regEx.test(value));

  return !regEx.test(value) && value !== null && value !== undefined && value !== '';
});

const ListaPremios = ({
  prizesQnty,
  prizesSum,
  type,
  handlePrizesCustom,
  crearPrivadaData,
  listPrizes,
  setMaxPrizeQnty,
}) => {
  prizesQnty = Number(prizesQnty);
  // console.log('prizesQnty', prizesQnty );
  // console.log('CrearPrivadaData', listPrizes, listaPremios);
  // console.log('CrearPrivadaData', crearPrivadaData.prizes_list);

  let listaPremios = [];

  for (let i = 1; i <= prizesQnty; i++) {
    listaPremios.push(i);
  }

  listaPremios = listaPremios.map((premio, premioIdx) => {
    return (
      <div key={premioIdx} className="flex-col col-no-padding col-30 prize-item">
        <div className="flex-row row-center row-no-padding">
          <div className="flex-container justify align">
            <span>{premio}°</span>
          </div>
          <div className="flex-container justify align">
            <i className="ion-trophy"></i>
          </div>
        </div>
        <div className="flex-row row-center ">
          <div className="flex-col">
            <CrearQuinielaFormsy
              prizesSum={prizesSum}
              listPrizes={listPrizes}
              prizeId={premio}
              setMaxPrizeQnty={setMaxPrizeQnty}
              name={'gamePrizesCustom' + premio}
              defaultValue={listPrizes[premioIdx].percentage}
              inputHandler={handlePrizesCustom}
              type="PRIZES_CUSTOM"
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="flex-col">
      <div className="flex-row">
        <div className="flex-col">
          <div className="control-label">Lista de premios</div>
        </div>
      </div>
      <div className="flex-container justify align wrap">{listaPremios}</div>
    </div>
  );
};

class ListaJornadas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listaJornadas: null,
      loaded: false,
    };
    // console.log(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.jornada !== prevProps.jornada) {
      if (this.props.jornada === 'full') {
        console.log(this);
        this.setState({ loaded: false });
        API.fetchTournamentInstancesWeek(this.props.quinielaTorneo).then((response) => {
          console.log('Full', response);
          this.setState(
            {
              listaJornadas: response.data.weeks,
              // poolWeek: response.data.week
            },
            () => {
              this.setState({ loaded: true });
              console.log(this.state.loaded, this.state.listaJornadas.length);
            }
          );
        });
      } else {
        console.log(this);
        this.setState({ loaded: false });
        API.fetchTournamentInstancesFixtures(this.props.quinielaTorneo).then((response) => {
          console.log('Week', response);
          this.setState(
            {
              listaJornadas: response.data.fixtures,
              poolWeek: response.data.week,
            },
            () => {
              this.setState({ loaded: true });
              console.log(this.state.loaded, this.state.listaJornadas.length);
            }
          );
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.jornada === 'full') {
      console.log(this);
      API.fetchTournamentInstancesWeek(this.props.quinielaTorneo).then((response) => {
        console.log('Full', response);
        this.setState({
          listaJornadas: response.data.weeks,
          loaded: true,
          // poolWeek: response.data.week
        });
      });
    } else {
      console.log(this);
      API.fetchTournamentInstancesFixtures(this.props.quinielaTorneo).then((response) => {
        console.log('Week', response);
        this.setState({
          listaJornadas: response.data.fixtures,
          poolWeek: response.data.week,
          loaded: true,
        });
      });
    }
  }

  render() {
    //let conditionalComponent = null;
    let listaJ = null;
    if (this.state.loaded) {
      console.log(this);
      if (this.props.jornada === 'week') {
        console.log('ListaJornadas');
        console.log(this.state.listaJornadas[0]);
        if (!this.state.listaJornadas[0] || !this.state.listaJornadas[0].home_team) {
          console.log('Here');
          return <div className="jornadaList">No hay jornadas disponibles</div>;
        }
        listaJ = this.state.listaJornadas.map((jornada, jornadaIdx) => {
          return (
            <div className="flex-col col-33 jornadaItem " key={jornada.id}>
              <div className=" flex-row row-no-padding row-center equipos">
                <span className="flex-container justify align home">
                  {jornada.home_team.logo !== null ? (
                    <span className="team flex-container justify align column">
                      <span className="flex-container justify align ">
                        <i className={` ${jornada.home_team.logo} home`}></i>
                      </span>
                      <span className="flex-container justify align column">
                        {jornada.home_team.abbreviation}
                      </span>
                    </span>
                  ) : (
                      <span className="team flex-container justify align">
                        <span className="flex-container justify align column">
                          {jornada.home_team.abbreviation}
                        </span>
                      </span>
                    )}
                </span>
                <span className="flex-container justify align">vs</span>
                <span className="away flex-container justify align">
                  {jornada.away_team.logo !== null ? (
                    <span className="team flex-container justify align column ">
                      <span className="flex-container justify align">
                        <i className={` ${jornada.away_team.logo} home`}></i>
                      </span>
                      <span className="flex-container justify align">
                        {jornada.away_team.abbreviation}
                      </span>
                    </span>
                  ) : (
                      <span className="team flex-container justify align column">
                        <span className="flex-container justify align">
                          {jornada.away_team.abbreviation}
                        </span>
                      </span>
                    )}
                </span>
              </div>
              <div className=" flex-row row-no-padding titulo">
                {/* <span className=" flex-col col-20 col-center numJornada">#{jornadaIdx+1}</span> */}
                <span className="flex-col col-no-padding fechaJornada">
                  <span className="flex-row row-no-padding fecha">
                    {moment(jornada.match_date).format('MM/DD/YY hh:mm')}
                  </span>
                </span>
              </div>
            </div>
          );
        });
      } else {
        listaJ = this.state.listaJornadas.map((jornada, jornadaIdx) => {
          return (
            <div className="jornadaItem flex-col col-33" key={jornada.id}>
              <div className="flex-col col-no-padding  titulo ">
                <span className="numJornada flex-container justify align">{jornada.week_name}</span>
                <span className="fechaJornada flex-col col-no-padding">
                  <span className="flex-container justify align fecha ">
                    {moment(jornada.start_date).format('MM/DD/YY hh:mm')}
                  </span>
                </span>
              </div>
            </div>
          );
        });
      }
    }

    // console.log('Torneos',listaTorneos);
    if (this.state.loaded) {
      return (
        <div className="flex-row">
          <div className="flex-col">
            <span style={{ fontWeight: 'bold' }} className={'text-centered control-label'}>
              {this.props.jornada === 'week' ? 'Partidos' : 'Jornadas'}
            </span>
            <div className="flex-row row-no-padding">
              <div className="jornadaList flex-container wrap justify align">{listaJ}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="jornadaList flex-container justify align wrap ">
          <div className="flex-row row-no-padding">
            <div className="flex-container justify align">
              <i className="ion-load-a loading"></i>
            </div>
          </div>
        </div>
      );
    }
  }
}

const Torneos = ({ deporte, lista, handleTorneos, defaultValue }) => {
  // console.log('lista',lista );
  let listaTorneos = lista.find((sport) => sport.id === deporte);
  if (!listaTorneos) {
    return;
  }
  listaTorneos = listaTorneos.tournament_instances.map((torneo, torneoIdx) => {
    return (
      <ToggleButton
        defaultValue={defaultValue}
        className="btn-torneo"
        key={torneo.id}
        value={torneo.id}
      >
        {torneo.name}
      </ToggleButton>
    );
  });
  return (
    <ButtonToolbar className="toolbar-toreno">
      <ToggleButtonGroup type="radio" name="torneo" onChange={handleTorneos}>
        {listaTorneos.length === 0 ? (
          <div className="intro-copy">
            Lo sentimos pero por el momento no tenemos ligas disponibles.
          </div>
        ) : (
            listaTorneos
          )}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

const WizardButtons = ({
  prizesSum,
  activeSection,
  canSubmit,
  nameExist,
  submitting,
  nextStep,
  prevStep,
  submitSearch,
  handleCancelSelection,
  crearPrivadaData,
}) => {
  let isReady = activeSection === 5;
  let conditionalComponent = null;
  if (isReady) {
    if (crearPrivadaData.prize_scheme === 'custom' && prizesSum !== 100) {
      canSubmit = false;
    }
    conditionalComponent = (
      <div className="flex-col col-90">
        <div className="buttons-wizard flex-row row-no-padding">
          <div className="flex-col">
            <Button className="btn-pick btn-wizard rounded font-chivo" onClick={prevStep}>
              <span>Anterior</span>
            </Button>
          </div>
          <div className="flex-col">
            <Button
              className="btn-pick btn-submit btn-wizard rounded font-chivo"
              disabled={!canSubmit || submitting || nameExist}
              onClick={() => {
                submitSearch();
                nextStep();
              }}
            >
              <span>Crea tu quiniela</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (activeSection === 1) {
    conditionalComponent = (
      <div className="flex-col col-90">
        <div className="buttons-wizard flex-row row-no-padding">
          <div className="flex-col">
            <Button className="btn-rojo btn-wizard rounded font-chivo" onClick={handleCancelSelection}>
              <span>Regresar</span>
            </Button>
          </div>
          <div className="flex-col">
            <Button
              disabled={!canSubmit || nameExist}
              className="btn-pick btn-wizard rounded font-chivo"
              onClick={nextStep}
            >
              <span>Siguiente</span>
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (activeSection > 5) {
    conditionalComponent = null;
  } else {
    if (activeSection === 2) {
      canSubmit =
        crearPrivadaData.tournament_instances !== undefined &&
        crearPrivadaData.tournament_instances !== null;
    } else if (activeSection === 3) {
      canSubmit = crearPrivadaData.type !== undefined && crearPrivadaData.type !== null;
    } else if (activeSection === 4) {
      // if(crearPrivadaData.entry_fee )
      // canSubmit = crearPrivadaData.entry_fee !== 0 ;
    }
    conditionalComponent = (
      <div className="flex-col col-90">
        <div className="buttons-wizard flex-row row-no-padding">
          <div className="flex-col">
            <Button className="btn-rojo btn-wizard rounded font-chivo" onClick={prevStep}>
              <span>Anterior</span>
            </Button>
          </div>
          <div className="flex-col">
            <Button disabled={!canSubmit} className="btn-pick btn-wizard rounded font-chivo" onClick={nextStep}>
              <span>Siguiente</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return conditionalComponent;
};

class CrearQuinielaFormulario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      isReady: false,
      deportesTorneos: null,
      listPrizes: [],
      errorMessage: false,
      canSubmit: false,
      nameExist: false,
      searchingName: false,
      successfullCreation: false,
      errorCreation: false,
      errors: null,
      prizesReady: true,
    };
    this.crearPrivadaData = {
      // "comission_status"                : false, // optional
      affiliate_commission: 0, //optional
      autopicks: true, //optional
      end_percentage: '100', //Required
      entry_fee: '', //Required
      limited_capacity: false, //Optional
      limit_multientries: false, //Optional
      max_multientries: null, //Optional
      max_entries: null, //Optional
      name: '', //Required
      password: '', //Required
      point_format: 'natural', //Required
      prize_scheme: 'dynamic', //Required
      prizes_list: null, //Optional
      send_notifications: true, //Optional
      sport_id: null, //Required
      tiebreaker_type: null, //Optional
      tournament_instances: null, //Required
      type: 'week', //Required
      use_tibreakers: false, //Optional
      weekly_percentage: null, //Optional
      weekly_prize_scheme: null, //Optional
      weekly_prize: false, //Optional
      pool_prizes: null, //Optional
    };
    /**
     *  This object handle the privata Custom Prize scheme
     * **/
    this.crearPrivadaPremioCustom = {
      position: null,
      type: 'percentage',
      percentage: null,
    };
    /**
     * Handle the name of the private
     * @param e is the event that handles the character changes
     * **/
    this.handleName = (e) => {
      this.crearPrivadaData.name = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (this.state.nameExist) {
        this.disableButton();
      }
    };
    /**
     * Handle the Password of the private
     * @param e is the event that handles the character changes
     * **/
    this.handlePassword = (e) => {
      this.crearPrivadaData.password = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (this.state.nameExist) {
        this.disableButton();
      }
    };
    /**
     * Handle the sport id selected by the user
     * @param value is the input value of the user
     * It also handles the degfault value of the point format
     * Depending of the selected sport id
     * **/
    this.handleSport = (value) => {
      // console.log('handleSport', e );
      this.crearPrivadaData.sport_id = value;
      if (value === 1 || value === 4) {
        this.crearPrivadaData.point_format = 'natural';
      } else {
        this.crearPrivadaData.point_format = 'line';
      }
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the Tournament list of the private game
     * @param value is the value that changes the id selected by the user
     * it also enables the next button in the wizard
     * **/
    this.handleToggleTorneos = (value) => {
      this.crearPrivadaData.tournament_instances = { _ids: [value] };
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (this.crearPrivadaData.tournament_instances !== null) {
        this.enableButton();
      }
      if (this.crearPrivadaData.tournament_instances === null) {
        this.disableButton();
      }
    };
    /**
     * Handle the Point Format  input of the private game
     * @param value is the point_format value
     * **/
    this.handlePointFormat = (value) => {
      this.crearPrivadaData.point_format = value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };

    /**
     * Handle the Game length of the game
     * @param value is the data that handles the type of the game
     * it also changes the default data of the use_tibreakers boolean
     * **/
    this.handleGameLength = (value) => {
      this.crearPrivadaData.type = value;
      if (this.crearPrivadaData.type === 'full') {
        this.crearPrivadaData.use_tibreakers = false;
      } else {
        this.crearPrivadaData.use_tibreakers = true;
      }
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      // console.log('Updated creation data', this.crearPrivadaData );
    };
    /**
     * Handle the Game length of the game
     * @param value is the data that handles the type of the game
     * it also changes the default data of the use_tibreakers boolean
     * **/
    this.handleAutoPick = (value) => {
      this.crearPrivadaData.autopicks = value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      // console.log('Updated creation data', this.crearPrivadaData );
    };
    /**
     * Handle the boolean value of Limit_multienries
     * @param value is the data that handles the limit_multientries of the game
     * **/
    this.handleLimitMultientries = (value) => {
      this.crearPrivadaData.limit_multientries = value;
      this.crearPrivadaData.max_multientries = value ? '' : null;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      if (value) {
        this.enableButton();
      }
      // console.log('Updated creation data', this.crearPrivadaData );
    };
    /**
     * Handle if the tiebrekers are active or inactive
     * @param value handles the use of tiebreakers
     * and the default value of the type of ties
     * **/
    this.handleUseTiebreakers = (value) => {
      // console.log('event', e.target.value);
      const valueTies = value === 'true';
      // console.log('handleUseTiebreakers ',valueTies);
      // console.log('use_tibreakers', this.crearPrivadaData.use_tibreakers );
      if (valueTies) {
        this.crearPrivadaData.tiebreaker_type = 'new';
      } else {
        this.crearPrivadaData.tiebreaker_type = null;
      }
      this.crearPrivadaData.use_tibreakers = valueTies;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      // console.log('Updated creation data', this.crearPrivadaData );
    };
    /**
     * Handle the Type of the ties
     * @param value
     * **/
    this.handleTiesType = (e) => {
      this.crearPrivadaData.tiebreaker_type = e;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };

    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleEntryFee = (e) => {
      // let val = e.target.value === 0 ? 1 : e.target.value ;
      this.crearPrivadaData.entry_fee = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleMaxMultientries = (e) => {
      // let val = e.target.value === 0 ? 1 : e.target.value ;
      this.crearPrivadaData.max_multientries = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle If it has a commisssion to the creator
     * @param value is the value that changes the id selected by the user
     * it also enables the next button in the wizard
     * **/
    // this.handleComissionStatus = ( value ) => {
    //   const comisionStatus = value === 'true' ;
    //     // console.log('handleUseTiebreakers ',valueTies);
    //     // console.log('use_tibreakers', this.crearPrivadaData.use_tibreakers );
    //     // if( comisionStatus === 'false' || !comisionStatus ){
    //     //   this.crearPrivadaData.comission_status = false;
    //     // }
    //     this.crearPrivadaData.comission_status = comisionStatus;
    //     this.props.updateCreatePrivateData(this.crearPrivadaData);
    // }
    /**
     * Handle If it has a weekly_prize
     * @param value is the value that changes the id selected by the user
     * it also enables the data reffered to a full quiniela default data
     * **/
    this.handleWeeklyPrize = (value) => {
      const weekleyPrize = value;
      // console.log('handleUseTiebreakers ',valueTies);
      // console.log('use_tibreakers', this.crearPrivadaData.use_tibreakers );
      /**
       * Restore weekly paramentes
       * **/
      if (weekleyPrize === 'false' || !weekleyPrize) {
        this.crearPrivadaData.weekly_prize = false;
        this.crearPrivadaData.use_tibreakers = false;
        this.crearPrivadaData.tiebreaker_type = null;
        this.crearPrivadaData.weekly_percentage = null;
        this.crearPrivadaData.weekly_prize_scheme = null;
        this.crearPrivadaData.end_percentage = null;
      } else {
        this.crearPrivadaData.weekly_prize = weekleyPrize;
        this.crearPrivadaData.weekly_percentage = 50;
        this.crearPrivadaData.weekly_prize_scheme = 'first';
        this.crearPrivadaData.end_percentage = 50;
      }
      console.log(this.crearPrivadaData);
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the value of the comission-fee
     * @param value is the value that changes the fee selected by the user
     * **/
    this.handleComissionFee = (e) => {
      console.log('comision', e.target.value);
      this.crearPrivadaData.affiliate_commission = e.target.value;
      console.log(this.crearPrivadaData);
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the value of the weekly_percetage
     * @param value is the value that changes the weeklypercentage and the endpercentage
     * **/
    this.handleWeeklyPercentage = (value) => {
      this.crearPrivadaData.weekly_percentage = value;
      this.crearPrivadaData.end_percentage = 100 - value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the value of the weeklyScheme
     * @param value is the value that changes the scheme of weekly prizes selected by the user
     * **/
    this.handleWeeklyScheme = (value) => {
      this.crearPrivadaData.weekly_prize_scheme = value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the value of the general prize  scheme
     * @param value is the value that changes the scheme of general prizes by the user
     * **/
    this.handlePrizeScheme = (value) => {
      this.crearPrivadaData.prize_scheme = value;
      if (value === 'custom') {
        this.disableButton();
        // this.crearPrivadaData.prizes_list = 10;
      } else {
        this.crearPrivadaData.prizes_list = null;
      }
      this.props.updateCreatePrivateData(this.crearPrivadaData);
    };
    /**
     * Handle the value of the prize_list
     * @param value is the value that changes the prize list qnty
     * **/
    this.handlePrizeList = (e) => {
      this.setState({ prizesReady: false });
      this.crearPrivadaData.prizes_list = e.target.value;
      this.props.updateCreatePrivateData(this.crearPrivadaData);
      this.calculateQntyPrizes(this.crearPrivadaData.prizes_list);
    };
    /**
     * Calculate qnty and list  default prizes
     * @param qntyPrizes how many prizes the user wants
     * **/
    this.calculateQntyPrizes = (qntyPrizes) => {
      // if(!qntyPrizes){ qntyPrizes = 0}
      if (!qntyPrizes || (isNaN(qntyPrizes) && qntyPrizes !== 0)) {
        console.log('Cero or undefined');
        this.setState({ listPrizes: [], prizesReady: true, customPrizeSum: 0 });
      } else {
        let newPrizesList = Object.keys(Array.apply(0, Array(Number(qntyPrizes))));
        newPrizesList = newPrizesList.map((prize, prizeIdx) => {
          let prizeModel = { ...this.crearPrivadaPremioCustom };
          // console.log('prizeModel', prizeModel);
          let currPosistion = prizeIdx + 1;
          prizeModel.position = Number(currPosistion);
          // prizeModel.percentage = 10;
          return prizeModel;
        });
        console.log('newPrizeList', newPrizesList);
        this.setState({ listPrizes: newPrizesList }, () => {
          this.calculateListPrizes();
        });
      }
    };
    this.calculateQntyPrizes = _.debounce(this.calculateQntyPrizes, 1000);
    /**
     * this function calculates the left of the custom prizes
     * **/
    this.calculateListPrizes = () => {
      console.log('%c calculateListPrizes', 'font-size:2em; color:rgb(110, 193, 113);');
      let _listPrizes = this.state.listPrizes.slice();
      console.log(_listPrizes);
      if (!_listPrizes || !_listPrizes.length) {
        this.setState({ prizesReady: true, customPrizesSum: null });
        return;
      }
      let sum = _.reduce(
        _listPrizes,
        (acc, curr) => {
          if (curr.percentage === null) {
            return (acc += 0);
          } else {
            return (acc += curr.percentage);
          }
        },
        0
      );

      console.log('customPrizeSum', sum);

      this.setState(
        {
          customPrizesSum: sum,
          prizesReady: true,
        },
        () => {
          console.log('customPrizesSum changed');
        }
      );
    };

    this.setMaxPrizeQnty = (sum, prizesItems) => {
      // console.log('%c SetMAxPrize','color:rgb(166, 12, 213); font-size:2em;' );
      // console.log('sum', sum);
      // console.log('prizesItems', prizesItems);
      const left = 100 - sum;
      // console.log('left', left);
      return left;
    };

    /**
     * Handle the custom perecentages of the list prize_scheme
     * @param e is the event emmited,
     * @param prizeId is the position that mod the percentage
     * **/
    this.handlePrizesCustom = (e, prizeId) => {
      // console.log( 'Prizes Custom', e.target.value , prizeId );
      let temporalPrizes = this.state.listPrizes.slice();
      // console.log('temporalPrizes', temporalPrizes);
      temporalPrizes.forEach((prize, prizeIdx) => {
        // console.log('prize', prize);
        if (prizeId - 1 !== prizeIdx) {
          return;
        }
        prize.percentage = Number(e.target.value);
        // console.log('prizeId', prizeId, prize);
      });
      // console.log('temporalPrizes', temporalPrizes);
      this.setState({ listPrizes: temporalPrizes }, () => {
        this.calculateListPrizes();
      });
    };
    /**
     * Check ig the name selected is available
     * **/
    this.checkAvailability = () => {
      this.setState({ searchingName: true });
      console.log('search this name', this.crearPrivadaData.name);
      API.fetchNamePrivateStatus(this.crearPrivadaData.name).then((response) => {
        this.setState({ nameExist: !response.data.available, searchingName: false });
      });
    };
    /**
     * This function handles the submition to create a quiniela
     * */
    this.submitSearch = (e) => {
      // e.persist();
      console.log('%c Crear Quiniela', 'color:rgb(17, 125, 193); font-size:2em;');
      console.log('crearPrivadaData', this.crearPrivadaData);
      this.setState({ submitting: true });
      if (this.crearPrivadaData.prize_scheme === 'custom') {
        this.crearPrivadaData['pool_prizes'] = this.state.listPrizes;
      }
      API.postCreatePrivate(this.crearPrivadaData).then((response) => {
        this.callbackCrearQuiniela(response.data);
      });
    };
    /**
     * This function handles the response of the post CreateQuiniela
     * @param data response data of the API call
     * **/
    this.callbackCrearQuiniela = (data) => {
      console.log('%c Callback Crear Quiniela ', 'color:rgb(17, 186, 140); font-size:2em;');
      console.log(data);
      if (!data.errors) {
        this.setState(
          {
            successfullCreation: true,
            submitting: false,
            activeSection: 6,
            createdPool: data.pool,
          },
          () => {
            setTimeout(() => {
              localStorage.setItem('createdPrivate', 'true');
              this.setState(
                {
                  redirect: true,
                },
                () => {
                  setTimeout(() => {
                    let path = window.location.pathname.split('/');
                    if (path.length > 1 && path[1] === 'quiniela') {
                      window.location = `/quiniela/${this.state.createdPool.slug}/${this.state.createdPool.id}`;
                    }
                  }, 800);
                }
              );
            }, 1200);
          }
        );
      } else {
        this.setState(
          {
            submitting: false,
            errorCreation: true,
            errors: data.errors,
          },
          () => {
            //const userData = utils.getUserInfo();
          }
        );
      }
    };

    this.enableButton = () => {
      // console.log( 'enableButton' );
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      console.log('disableButton');
      this.setState({ canSubmit: false });
    };
  }

  componentDidMount() {
    API.fetchSports().then((response) => {
      // console.log(response);
      this.setState({
        deportesTorneos: response.data.sports,
      });
    });
  }

  render() {
    let conditionalComponent = null;
    switch (this.props.activeSection) {
      /**
       * Informacion Basica
       * **/
      case 1:
        conditionalComponent = (
          <div className="flex-col input-box-responsive">
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Cómo quieres que se llame tu quiniela?"
                name="gameName"
                inputHandler={this.handleName}
                type="NAME"
                checkAvailability={this.checkAvailability}
                defaultValue={this.crearPrivadaData.name}
                validations="notEmoticons"
                validationError="El nombre no puede tener un emoji ☹"
                required
              />
            </div>
            <LoadingProcess show={this.state.searchingName} />
            <ErrorMessage
              showError={this.state.nameExist}
              label="El nombre de esta quiniela ya esta ocupado."
            />
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Que contraseña quieres que tenga tu quiniela?"
                name="gamePass"
                inputHandler={this.handlePassword}
                type="TEXT"
                validations="isExisty"
                validationError="La contraseña es obligatoria"
                required
              />
            </div>
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Quieres limitar los multi registros?"
                name="limit_multientries"
                defaultValue={this.crearPrivadaData.limit_multientries}
                inputHandler={this.handleLimitMultientries}
                type="LIMIT_MULTIENTRIES"
              />
            </div>
            {this.crearPrivadaData.limit_multientries ? (
              <div className="flex-row ">
                <CrearQuinielaFormsy
                  label="Registros máximos por usuario"
                  name="gameEntry"
                  defaultValue={this.crearPrivadaData.max_multientries}
                  inputHandler={this.handleMaxMultientries}
                  validations={('isNumeric', { minRegister: 15 })}
                  validationError="Debe ser mayor a 0"
                  type="MAX_MULTIENTRIES"
                  required
                />
              </div>
            ) : null}
          </div>
        );
        break;
      /**
       * Deporte y torneo
       * **/
      case 2:
        conditionalComponent = (
          <div className="flex-col col-60">
            <div className="flex-row">
              <div className="flex-col">
                <CrearQuinielaFormsy
                  label="Elige un deporte"
                  deportesTorneos={this.state.deportesTorneos}
                  name="gameSport"
                  defaultValue={this.crearPrivadaData.sport_id}
                  inputHandler={this.handleSport}
                  type="SPORT"
                  validationError="Debes de elegir un deporte."
                  required
                />
                {this.crearPrivadaData.sport_id !== null ? (
                  <div className=" wizard-tournament-toggle flex-col">
                    <ControlLabel style={{ display: 'flex', justifyContent: 'center', lineHeight: 2 }}>Elige una Liga</ControlLabel>
                    <Torneos
                      defaultValue={this.crearPrivadaData.tournament_instances}
                      deporte={this.crearPrivadaData.sport_id}
                      lista={this.state.deportesTorneos}
                      handleTorneos={this.handleToggleTorneos}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
        break;
      /**
       * Formato y duracion
       * **/
      case 3:
        conditionalComponent = (
          <div className="flex-col  col-90 ">
            {(this.crearPrivadaData.sport_id !== null && this.crearPrivadaData.sport_id === 1) ||
              this.crearPrivadaData.sport_id === 4 ? null : (
                <CrearQuinielaFormsy
                  label="¿Quieres que tu quiniela lleve línea?"
                  name="gamePointFormat"
                  inputHandler={this.handlePointFormat}
                  type="POINT_FORMAT"
                  defaultValue={this.crearPrivadaData.point_format}
                  validationError="Debes de elegir un formato"
                  required
                />
              )}
            <CrearQuinielaFormsy
              label="Elige la duración de tu quiniela"
              name="gameLength"
              defaultValue={this.crearPrivadaData.type}
              inputHandler={this.handleGameLength}
              type="GAME_LENGTH"
              validationError="Debes de elegir una duración"
              required
            />
            <CrearQuinielaFormsy
              label="¿Quieres que tu quiniela tenga autopick?"
              small='(En caso de no haber seleccionado un pick para uno o varios partidos que ya hayan comenzado se asignará automáticamente el equipo local como pick)'
              name="autoPick"
              defaultValue={this.crearPrivadaData.autopicks}
              inputHandler={this.handleAutoPick}
              type="AUTO_PICK"
              required
            />
            {this.crearPrivadaData.type !== 'full' ? null : (
              <CrearQuinielaFormsy
                label="¿Quieres que tu quiniela tenga premios semanales?"
                name="weeklyTie"
                defaultValue={this.crearPrivadaData.weekly_prize}
                inputHandler={this.handleWeeklyPrize}
                type="WEEKLY_PRIZES"
                validationError="Debes de elegir una duración"
                required
              />
            )}
            {this.crearPrivadaData.type === 'full' &&
              this.crearPrivadaData.weekly_prize !== true ? null : (
                <CrearQuinielaFormsy
                  label="¿Quieres que tu quiniela tenga desempates?"
                  name="useTies"
                  defaultValue={this.crearPrivadaData.use_tibreakers}
                  inputHandler={this.handleUseTiebreakers}
                  type="USE_TIES"
                  validationError="Debes de elegir una duración"
                  required
                />
              )}
            { (utils.getUserInfo().group_id === 1 || utils.getUserInfo().id === 87909) && this.crearPrivadaData.use_tibreakers === true ? (
              <CrearQuinielaFormsy
                label="¿Cuántos desempates?"
                name="tieType"
                defaultValue={this.crearPrivadaData.tiebreaker_type}
                inputHandler={this.handleTiesType}
                type="TIE_TYPE"
                validationError=""
                required
              />
            ) : null}
            {this.crearPrivadaData.type !== null ? (
              <ListaJornadas
                quinielaTorneo={this.crearPrivadaData.tournament_instances['_ids'][0]}
                jornada={this.crearPrivadaData.type}
              />
            ) : null}
          </div>
        );
        break;
      /**
       * Participantes
       * **/
      case 4:
        conditionalComponent = (
          <div className="flex-col col-40">
            <div className="flex-row">
              <div className="flex-col">
                <div className="flex-row">
                  <CrearQuinielaFormsy
                    label="Elige el precio de tú quiniela."
                    name="gameEntry"
                    defaultValue={this.crearPrivadaData.entry_fee}
                    inputHandler={this.handleEntryFee}
                    type="ENTRY_FEE"
                    maxLength={5}
                    validations={'isNumeric'}
                    validationError="Debes de elegir un precio"
                    required
                  />
                </div>

                {/* <div className="flex-row">
                  <CrearQuinielaFormsy
                  label='¿Quieres cobrar una comisión por organizarla?'
                  name='gameComissionStatus'
                  defaultValue={ this.crearPrivadaData.comission_status }
                  inputHandler={ this.handleComissionStatus }
                  type='COMISSION_STATUS'
                  validationError='Debes de elegir un precio'
                  required />
                </div> */}
                <div className="flex-row ">
                  <CrearQuinielaFormsy
                    label="¿Cuánto quieres llevarte de comisión?"
                    name="gameComissionFee"
                    defaultValue={this.crearPrivadaData.affiliate_commission}
                    inputHandler={this.handleComissionFee}
                    maxLength={2}
                    type="COMISSION_FEE"
                    validations={('isNumeric', { maxLength: 2 }, { maxValueCommission: 15 })}
                    validationError={'Tu comisión no puede ser mayor al 15%.'}
                    help={[
                      'Tu comisión puede ser de 0 a 15%, Pickwin reservará 2% de comisión adicional para operación.',
                    ]}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        );
        break;
      /**
       * Premios
       * **/
      case 5:
        conditionalComponent = (
          <div className="flex-col col-70">
            {this.crearPrivadaData.weekly_prize === true ? (
              <div className="flex-row">
                <CrearQuinielaFormsy
                  label="Elige el porcentaje asignado a los premios semanales."
                  name="gameWeeklyPercentage"
                  defaultValue={this.crearPrivadaData.weekly_percentage}
                  inputHandler={this.handleWeeklyPercentage}
                  type="WEEKLY_PERCENTAGE"
                />
              </div>
            ) : null}
            {this.crearPrivadaData.weekly_prize === true ? (
              <div className="flex-col">
                <CrearQuinielaFormsy
                  label="¿Cómo quieres que se repartan los premios semanales?"
                  name="gameWeeklyPrizeScheme"
                  defaultValue={this.crearPrivadaData.weekly_prize_scheme}
                  inputHandler={this.handleWeeklyScheme}
                  type="WEEKLY_PRIZE_SCHEME"
                />
                <div className="flex-row">
                  {this.crearPrivadaData.weekly_prize_scheme === 'top' ? (
                    <div className="flex-row row-no-padding">
                      <div className="flex-col col-no-padding text-center">1° - 50%</div>
                      <div className="flex-col col-no-padding text-center">2° - 30%</div>
                      <div className="flex-col col-no-padding text-center">3° - 20%</div>
                    </div>
                  ) : (
                      <div className="flex-row row-no-padding">
                        <div className="flex-col col-no-padding text-center">¡ Todo al 1° !</div>
                      </div>
                    )}
                </div>
              </div>
            ) : null}
            <div className="flex-row ">
              <CrearQuinielaFormsy
                label="¿Cómo quieres que se repartan los premios al final de la quiniela?"
                name="gamePrizeScheme"
                defaultValue={this.crearPrivadaData.prize_scheme}
                inputHandler={this.handlePrizeScheme}
                type="PRIZE_SCHEME"
              />
            </div>
            {this.crearPrivadaData.prize_scheme === 'custom' ? (
              <div className="flex-row">
                <CrearQuinielaFormsy
                  label="¿Cuántos premios quieres entregar?"
                  name="gamePrizeScheme"
                  maxLength={4}
                  prizesReady={this.state.prizesReady}
                  defaultValue={this.crearPrivadaData.prizes_list}
                  inputHandler={this.handlePrizeList}
                  validations={'isNumeric'}
                  validationError={'Debe de ser un número.'}
                  type="PRIZES_LIST"
                  required
                />
              </div>
            ) : null}
            {this.crearPrivadaData.prizes_list !== null ? (
              this.state.prizesReady ? (
                <ListaPremios
                  type="input"
                  prizesSum={this.state.customPrizesSum}
                  listPrizes={this.state.listPrizes}
                  crearPrivadaData={this.crearPrivadaData}
                  handlePrizesCustom={this.handlePrizesCustom}
                  prizesQnty={this.crearPrivadaData.prizes_list}
                  setMaxPrizeQnty={this.setMaxPrizeQnty}
                />
              ) : (
                  <div className="flex-container justify align column">
                    {' '}
                    <i className="ion-load-a loading"></i>{' '}
                    <small> Estamos calculando tus premios </small>{' '}
                  </div>
                )
            ) : null}
          </div>
        );
        break;
    }
    if (this.state.redirect) {
      console.log('redirect');
      conditionalComponent = (
        <Redirect
          push
          to={{
            state: { newQuinielaState: this.state.createdPool },
            pathname: `/quiniela/${this.state.createdPool.slug}/${this.state.createdPool.id}`,
          }}
        />
      );
    }
    return (
      <Formsy
        onValidSubmit={(e) => {
          this.submitSearch();
        }}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <div className="flex-container justify align column">
          {this.state.redirect ? (
            <Redirect
              push
              to={{
                state: { newQuinielaState: this.state.createdPool },
                pathname: `/quiniela/${this.state.createdPool.slug}/${this.state.createdPool.id}`,
              }}
            />
          ) : null}
          {this.state.successfullCreation ? (
            <span>¡Felicidades tu quiniela se creo con éxito!</span>
          ) : (
              conditionalComponent
            )}
          <WizardButtons
            crearPrivadaData={this.crearPrivadaData}
            handleCancelSelection={this.props.handleCancelSelection}
            activeSection={this.props.activeSection}
            canSubmit={this.state.canSubmit}
            prizesSum={this.state.customPrizesSum}
            nextStep={this.props.nextStep}
            prevStep={this.props.prevStep}
            submitSearch={this.submitSearch}
            submitting={this.state.submitting}
            nameExist={this.state.nameExist}
          />
        </div>
      </Formsy>
    );
  }
}

export default CrearQuinielaFormulario;
