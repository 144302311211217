import React from 'react';
import moment from 'moment';

import utils from '../../utils/utils';
import Redirect from 'react-router';

const TeamRecord = ({ teamData }) => {
  {
    teamData.sport_id =
      teamData.sport_id === 1 ? (
        !teamData.team_stats || !teamData.team_stats.hasOwnProperty('win') ? null : (
          <small className="team-record flex-container justify align">
            {`[${teamData.team_stats.win}-${teamData.team_stats.lost}]`}
          </small>
        )
      ) : null;
  }

  let conditionalComponent = null;

  if (teamData.team_stats && teamData.team_stats.hasOwnProperty('win')) {
    if (teamData.sport_id === 1) {
      conditionalComponent = (
        <small className="team-record flex-container justify align">
          {`[${teamData.team_stats.win}-${teamData.team_stats.ties}-${teamData.team_stats.lost}] `}
        </small>
      );
    } else {
      conditionalComponent = (
        <small className="team-record flex-container justify align">
          {`[${teamData.team_stats.win}-${teamData.team_stats.lost}] `}
        </small>
      );
    }
  }

  return conditionalComponent;
};

/**
 * This Component Handles and present the data of each pick
 * @param { function } handlePick - is the function that handles the click
 *  and update the state of the picks
 * @param { Object } teamData - Is the object that has all the data of the team
 * @param { number } currentWeek - is the key of the object that handles the pick state
 * @param { string } type - is Home or Away
 *
 */
const SurvivorPick = ({
  type,
  currentWeek,
  currentEntry,
  firstOf,
  survivorFixtures,
  selectedWeek,
  teamData,
  fixture,
  availableTeams,
  survivorEntryData,
  withRegister,
  handlePick,
}) => {
  let conditionalComponent = null;

  let isThisWeek = currentWeek.id === selectedWeek;

  let isDisabled = false;

  let alreadySelected = false;

  /**
   * This pick isnt from this week and it has been selected
   */
  if (
    availableTeams &&
    availableTeams[teamData.id] !== undefined &&
    availableTeams[teamData.id].selected &&
    availableTeams[teamData.id].week_id !== selectedWeek
  ) {
    // console.log(availableTeams[teamData.id], selectedWeek);
    isDisabled = true;
    alreadySelected = true;
  } else if (!fixture.enabled_survivor) {
    /**
     * This pick dont has a enabled survivor
     */
    // console.log(availableTeams[teamData.id], selectedWeek);
    isDisabled = true;
  } else {
    /**
     *
     * Si ya comenzo el firstOf
     * y tienes pick de esta semana
     * y ese pick es anterior al firstOf
     *
     */
    const closingDate = moment(firstOf.deadline);
    const nowData = moment();
    let firstOfStarted = closingDate.diff(nowData, 'seconds');
    firstOfStarted = firstOfStarted < 1;

    if (
      currentEntry !== null &&
      currentEntry !== undefined &&
      currentEntry.hasOwnProperty('survivor_picks')
    ) {
      const thisWeekPick = currentEntry.survivor_picks?.[selectedWeek];
      let thisPickFixture = null;

      /**
       * Si ya comenzo el primer partido de la semana
       */
      if (firstOfStarted) {
        isDisabled = true;
      } else if (thisWeekPick) {
        /**
         * Hay pick de esta semana disponible?
         */
        thisPickFixture = survivorFixtures.find((fixt) => fixt.id === thisWeekPick.fixture_id);
        if (thisPickFixture !== -1 && thisPickFixture !== undefined) {
          if (!thisPickFixture.enabled) {
            console.log('HEre');
            // console.log( 'ThisPickFixture', thisPickFixture );
            isDisabled = true;
          }
        }
      }
    }
  }

  if (currentEntry) {
    //If the current entry isnt alive or the current entry has been eliminated
    if (
      !currentEntry.survivor_standing.alive ||
      currentEntry.survivor_standing.elimination_week_id > currentWeek.id
    ) {
      isDisabled = true;
    }
  }

  // Not logged or super user
  if (utils.isSuPick()) {
    isDisabled = true;
  }

  let isSelected =
    !survivorEntryData.survivor_picks || !survivorEntryData.survivor_picks[selectedWeek]
      ? false
      : survivorEntryData.survivor_picks[selectedWeek].pick === teamData.id;

  if (type === 'home') {
    conditionalComponent = (
      <div className="flex-row row-center team-data row-no-padding">
        <div className="flex-col col-15 col-center">
          <div className="flex-container justify align">
            {isThisWeek && !isDisabled ? (
              <div className="radio-container">
                <input
                  name={`survivorWeekPick-${selectedWeek}`}
                  type="radio"
                  disabled={isDisabled}
                  checked={isSelected}
                  value={teamData.id}
                  onChange={() => {}}
                />
                <div className={`radio-check ${isDisabled ? 'disabled' : ''} `} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex-col col-20 col-center text-center team-data">
          {!teamData.logo ? null : (
            <div className="flex-container justify align">
              <i className={`team-logo ${teamData.logo} ${type}`}></i>
            </div>
          )}
        </div>
        <div className="flex-col col-center text-center col-no-padding team-data">
          <span className="team-name flex-container justify align">{teamData.short_name}</span>
          <TeamRecord teamData={teamData} />
        </div>
      </div>
    );
  } else {
    conditionalComponent = (
      <div className="flex-row row-center team-data row-no-padding">
        <div className="flex-col col-center text-center col-no-padding team-data">
          <span className="team-name flex-container justify align">{teamData.short_name}</span>
          <TeamRecord teamData={teamData} />
        </div>
        <div className="flex-col col-20 team-data">
          {!teamData.logo ? null : (
            <div className="flex-container justify align">
              <i className={`team-logo ${teamData.logo} ${type}`}></i>
            </div>
          )}
        </div>
        <div className="flex-col col-center col-15 ">
          <div className="flex-container justify align">
            {isThisWeek && !isDisabled ? (
              <div className="radio-container">
                <input
                  name={`survivorWeekPick-${selectedWeek}`}
                  type="radio"
                  value={teamData.team_id}
                  checked={isSelected}
                  disabled={isDisabled}
                  onChange={() => {}}
                />
                <div className={`radio-check ${isDisabled ? 'disabled' : ''} `} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  let pickStatus = '';
  if (fixture.enabled_survivor || !currentEntry || !currentEntry.survivor_picks) {
    pickStatus = '';
  } else if (currentEntry.survivor_picks[selectedWeek]) {
    if (currentEntry.survivor_picks[selectedWeek].strike) {
      pickStatus = 'strike';
    } else if (currentEntry.survivor_picks[selectedWeek].correct) {
      pickStatus = 'gano';
    } else if (!currentEntry.survivor_picks[selectedWeek].correct) {
      pickStatus = 'perdio';
    }
  }

  return (
    <label
      id={`survivorPick`}
      className={`
                ${alreadySelected ? 'already-selected' : ''}
                ${isSelected ? 'selected' : ''}
                ${
                  fixture.enabled_survivor
                    ? ''
                    : !currentEntry || !currentEntry.survivor_picks
                    ? ''
                    : pickStatus
                }
                ${isDisabled ? 'disabled-survivor' : ''}`}
      onClick={() => {
        if (isThisWeek && !isDisabled) {
          handlePick(teamData.id, selectedWeek, fixture.id);

        }
      }}
    >
      {conditionalComponent}
    </label>
  );
};

export default SurvivorPick;
