//                       __________________
//                     .-'  \ _.-''-._ /  '-.
//                   .-/\   .'.      .'.   /\-.
//                  _'/  \.'   '.  .'   './  \'_
//                 :======:======::======:======:
//                  '. '.  \     ''     /  .' .'
//                    '. .  \   :  :   /  . .'
//                      '.'  \  '  '  /  '.'
//                        ':  \:    :/  :'
//                          '. \    / .'
//                            '.\  /.'                   XMERALD - UI
//                              '\/'
// -------------------------------------------------------------------------------

// Alias shorthand for styles
export const shorthandPropList = [
  ['color', 'color'],
  ['weight', 'fontWeight'],
  ['size', 'fontSize'],
  ['lineHeight', 'lineHeight'],
  ['bg', 'background'],
  ['background', 'background'],
  ['bgColor', 'backgroundColor'],
  // Margin
  ['margin', 'margin'],
  ['m', 'margin'],
  ['mt', 'marginTop'],
  ['mr', 'marginRight'],
  ['mb', 'marginBottom'],
  ['ml', 'marginLeft'],
  // Padding
  ['padding', 'padding'],
  ['p', 'padding'],
  ['pt', 'paddingTop'],
  ['pr', 'paddingRight'],
  ['pb', 'paddingBottom'],
  ['pl', 'paddingLeft'],
  // Align
  ['textAlign', 'textAlign'],
  ['width', 'width'],
  ['height', 'height'],
  ['maxWidth', 'maxWidth']
]

// Only name of shorthand prop
export const listOfXmeProps = shorthandPropList.map(item => item[0])
