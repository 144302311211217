import React from 'react';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import InfiniteScroll from 'react-infinite-scroller';
import avatar from '../../assets/images/avatar-50.png';
import groupPoolActions from '../QuinielaGroupsUI/actions';



import Loading from '../Loading';
import $ from 'jquery';
import _ from 'underscore';

window.jQuery.fn.synchroniseScroll = function(direction) {
  console.log('initscroll', direction);
  var elements      = this;
  var lastScrolling = null;
  var lastScroll    = performance.now();

  if (elements.length <= 1) {
    console.log('no elements');
    return;
  }


  elements.scroll(function(e) {
    if ( lastScrolling === null || (performance.now() - lastScroll >= 900) ) {
      lastScrolling = e.currentTarget;
    }
    if ( lastScrolling !== e.currentTarget ) {
      return;
    }

    var left = $(this).scrollLeft();
    var top  = $(this).scrollTop();

    elements.each(function(idx, el) {
      if ( el === lastScrolling ) { return; }
      if (direction === 'horizontal' && $(el).scrollLeft() != left) {
        $(el).scrollLeft(left);
      }
      if (direction === 'vertical' && $(el).scrollTop() != top ) {
        $(el).scrollTop(top);
      }
    });

    lastScroll = performance.now();

  });


};

const TitlesList = () => {
  let titleList = ['Grupos'];
  titleList = titleList.map((listItem, index) => {
    return (
      <div key={index} className="upper-table-category group">
        {listItem}
      </div>
    );
  });
  return <div> {titleList} </div>;
};

const UserItem = ({ myEntry, withRegister, standing }) => {
  let conditionalComponent = null;

  // console.log( myEntry , withRegister, standing  );

  let ownUser = withRegister && myEntry.group_pool_entry_id === standing.group_pool_entry_id;
  if ($(window).width() <= 769) {
    conditionalComponent = (
      <ul key={standing.group_pool_entry_id} className="list-unstyled lower-list">
        <li className={` flex-row row-no-padding responsive-sm lower-table-item-group user `}>
          <div
            className={`flex-container column justify align item-container-group-pool
          ${ownUser ? 'ownUser' : ''} `}
          >
            <span className="picks-rank"> {standing.rank}°</span>
            <span className=" " style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number !== null ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px',
                  }}
                >
                  {standing.number}
                </sup>
              ) : (
                  ''
                )}
            </span>
          </div>
        </li>
      </ul>
    );
  } else {
    conditionalComponent = (
      <ul key={standing.group_pool_entry_id} className="list-unstyled lower-list">
        <li className={` flex-row row-no-padding responsive-sm lower-table-item-group user `}>
          <div
            className={`flex-container start align item-container-group-pool  ${
              ownUser ? 'ownUser' : ''
              }`}
            style={{ padding: '0 5px' }}
          >
            <div className="image-container">
              <img alt={standing.nick} className="avatarPicksGrupo"
                src={standing.avatar === '/img/profile-image-01.png' ? avatar : standing.avatar} />
            </div>
            <span style={{ margin: '5px' }} className="picks-rank">
              {' '}
              {standing.rank}°
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {standing.nick}
              {standing.number !== null ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px',
                  }}
                >
                  {standing.number}
                </sup>
              ) : (
                  ''
                )}
            </span>
          </div>
        </li>
      </ul>
    );
  }

  return conditionalComponent;
};

/**
 * This component renders a list of the standings
 */
const UsersList = ({ standings, myEntry, withRegister }) => {
  if (standings.items.length < 1) {
    return null;
  }
  // console.log('standings',standings);
  let usersList = standings.items.map((standing) => {
    // console.log( standing );
    return (
      <UserItem
        key={standing.group_pool_entry_id}
        standing={standing}
        myEntry={myEntry}
        withRegister={withRegister}
      />
    );
  });
  return (
    <div className="overflow overflow-y overflow-picks-grupo userList group sync-vertical">
      {usersList}
    </div>
  );
};

const MainHeader = ({ buckets, ties, myEntry, players, poolInfo, currentContest }) => {
  return (
    <div className="row-list">
      <div className="title-container">
        <TitlesList />
      </div>
      <div className="picksData sync-horizontal">
        <div className="data-row">
          {buckets[currentContest.id].map((b) => {
            return (
              <div key={b.id} className="upper-table-item group ">
                {' '}
                Grupo #{b.id}{' '}
              </div>
            );
          })}
          {poolInfo.use_tiebreakers === true && (
            _.map(ties[currentContest.id], (tie, tieIdx) => {
              return (
                <div key={tieIdx} className="upper-table-item tie  group ">
                  {' '}
                  D {tieIdx + 1}{' '}
                </div>
              );
            })
          ) || null}
        </div>
      </div>
      <div className="total-container flex-col col-center">
        <div className="upper-table-item  group total" />
        Puntos
      </div>
    </div>
  );
};

const PicksList = ({
  players,
  buckets,
  standings,
  ties,
  gameStatus,
  myEntry,
  myEntryId,
  withRegister,
  currentContest,
}) => {
  let userRow = standings.items.map((userInfo) => {
    let isMyEntry = false;

    let ownUser = false;

    if (myEntry) {
      if (!userInfo) {
        isMyEntry = false;
      } else {
        isMyEntry = userInfo.group_pool_entry_id === myEntry.group_pool_entry_id;
      }
    } else {
      ownUser = userInfo.group_pool_entry_id === myEntryId;
    }

    let selectedTies;
    if (currentContest) {
      selectedTies = _.map(ties[currentContest.id], (tie, index) => {
        let currentTie =
          !userInfo.group_pool_user_tiebreakers || !userInfo.group_pool_user_tiebreakers[tie.id]
            ? null
            : userInfo.group_pool_user_tiebreakers[tie.id];
        // console.log( currentPick )
        if (!currentTie) {
          // console.log( 'Not Found');
          return (
            <div key={index} className="item-container-group-pool tie ">
              <div className=" flex-row roimg-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                </div>
              </div>
            </div>
          );
        } else if (currentTie && gameStatus === 'upcoming' && !isMyEntry) {
          return (
            <div key={tie.id} className="item-container-group-pool tie">
              <div className=" flex-row img-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-checkmark-round success"></i>
                </div>
              </div>
            </div>
          );
        } else {
          //  console.log( 'Found', currentTie  );
          return (
            <div
              key={tie.id}
              className={`item-container-group-pool tie flex-row row-no-padding  ${
                ownUser ? 'ownUser' : ''
                }`}
            >
              <div className="flex-container justify align item-tie-value ">
                {!currentTie.value && currentTie.value === '0' ? 'E' : currentTie.value}
              </div>
            </div>
          );
        }
      });
    }

    let selectedPicks = buckets[currentContest.id].map((bucket) => {
      // console.log( 'bId',bucket.id, userInfo.group_pool_picks );

      let colorStyle = {};

      let currentPick =
        !userInfo.group_pool_picks || !userInfo.group_pool_picks[bucket.id]
          ? null
          : userInfo.group_pool_picks[bucket.id];

      // console.log( currentPick )

      if (!currentPick) {
        // console.log( 'Not Found');
        return (
          <div key={bucket.id} className="item-container-group-pool">
            <div className=" flex-row img-container">
              <div className={`shirt-picks grupo withoutPick`}>
                <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
              </div>
            </div>
          </div>
        );
      } else if (currentPick && gameStatus === 'upcoming' && !isMyEntry) {
        return (
          <div key={bucket.id} className="item-container-group-pool">
            <div className=" flex-row img-container">
              <div className={`shirt-picks grupo withoutPick`}>
                <i className="ion-checkmark-round success"></i>
              </div>
            </div>
          </div>
        );
      } else {
        let currentPlayer = null;
        //  let currentPlayer = players[ currentPick.pick ];

        currentPlayer = players[currentPick.pick];

        if (currentPlayer) {
          if (currentPlayer.score === 0) {
          } else if (currentPlayer.score > 0) {
            colorStyle = { color: 'rgb(255, 199, 48)' };
          } else if (currentPlayer.score < 0) {
            colorStyle = { color: '#78bc28' };
          }
          // console.log( 'Found', currentPlayer, currentPick.pick, players );
          return (
            <div
              key={bucket.id}
              className={`item-container-group-pool ${ownUser ? 'ownUser' : ''}`}
            >
              <div className="flex-row group-pool-pick row-center">
                <i className={currentPlayer.logo} style={{ margin: '0 3px' }} />
                <div className="small uppercase text-center col-70 flex-col overflow-words">
                  {currentPlayer.name}{' '}
                </div>
              </div>
              <div className="flex-row group-pool-pick-stats row-no-padding row-center">
                <div className="flex-col col-33 col-no-padding  text-center ">
                  <div className="flex-container justify align small">
                    {currentPlayer.position}{' '}
                    {!currentPlayer.position && currentPlayer.position !== '0' ? '-' : '°'}
                  </div>
                </div>
                <div className="flex-col col-33 col-no-padding  text-center ">
                  <div className=" flex-container justify align small " style={colorStyle}>
                    {currentPlayer.score === 0 ? 'E' : currentPlayer.score}
                  </div>
                </div>
                <div className="flex-col col-33 col-no-padding  text-center">
                  <div className="flex-container justify align">{currentPlayer.points}pts</div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="item-container-group-pool ">
              <div className=" flex-row roimg-container">
                <div className={`shirt-picks grupo withoutPick`}>
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                </div>
              </div>
            </div>
          );
        }
      }
    });

    return (
      <li
        key={userInfo.group_pool_entry_id}
        className={`flex-row row-no-padding lower-table-item-group user `}
      >
        {selectedPicks}
        {selectedTies}
      </li>
    );
  });

  return <ul className="list-unstyled flex-col col-no-padding lower-list">{userRow}</ul>;
};

const TotalesList = ({ standings, myEntry }) => {
  let totalesList = standings.items.map((standing) => {
    // console.log(standing)
    return (
      <li
        key={standing.group_pool_entry_id}
        className={`lower-table-item-group flex-col col-no-padding ${
          myEntry && myEntry.group_pool_entry_id === standing.group_pool_entry_id ? 'ownUser' : ''
          }`}
      >
        <div className="item-container-group-pool flex-row row-center">
          <div className="shirt-picks">
            <div className=""> {standing.points}</div>
          </div>
        </div>
      </li>
    );
  });
  return <ul className="list-unstyled lower-list">{totalesList}</ul>;
};

const TablaPicksGrupo = ({
  myEntry,
  dispatch,
  poolId,
  buckets,
  ties,
  players,
  groupPicks,
  submitting,
  loadMore,
  withRegister,
  queryParams,
  gameStatus,
  currentContest,
}) => {
  return (
    <div className="lista-container flex-row row-no-padding">
      <UsersList standings={groupPicks} withRegister={withRegister} myEntry={myEntry} />
      <div className="overflow overflow-y overflow-picks-grupo dataList sync-vertical sync-horizontal">
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={
            submitting || (groupPicks && groupPicks.pages === queryParams.page) ? false : true
          }
          threshold={150}
          loader={<Loading key={'labelLoader00'} label="..." />}
          useWindow={false}
        >
          <PicksList
            ties={ties}
            myEntryId={myEntry.group_pool_entry_id}
            standings={groupPicks}
            gameStatus={gameStatus}
            players={players}
            buckets={buckets}
            withRegister={withRegister}
            currentContest={currentContest}
          />
        </InfiniteScroll>
      </div>
      <div className="overflow overflow-y overflow-picks-grupo totalesList sync-vertical">
        <TotalesList myEntry={myEntry} standings={groupPicks} />
      </div>
    </div>
  );
};

const PicksGrupoPoolGroup = () => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);
  // console.log(state);

  const userRow = () => {
    //const userData = utils.getUserInfo();
    // console.log( 'userData', userData);
    // console.log( 'myEntry', state.myEntry);

    // let myStanding = state.standings.items.find( i => i.group_pool_entry_id === state.myEntry.group_pool_entry_id );
    // console.log( 'myStanding ', myStanding);
    // let myGroupPicks = state.groupPicks.items.find( i =>  i.group_pool_entry_id === state.myEntry.group_pool_entry_id );

    let fakeStanding = { ...state.myEntry.group_pool_standing };

    fakeStanding['group_pool_picks'] = state.myEntry.group_pool_picks;
    fakeStanding['group_pool_user_tiebreakers'] = state.myEntry.group_pool_user_tiebreakers;
    console.log(fakeStanding);

    return (
      <div className="lista-container ownUser">
        <div className="userList group" style={{ margin: '0', padding: '0' }}>
          <ul className="list-unstyled lower-list">
            <li className={` flex-row  responsive-sm row-no-padding lower-table-item-group user `}>
              <div className="flex-container justify align text-center item-container-group-pool">
                Mis Picks
              </div>
            </li>
          </ul>
        </div>
        <div className="dataList sync-horizontal " style={{ overflow: 'hidden' }}>
          <PicksList
            queryParams={state.queryParamsGroupPicks}
            ties={state.tiebreakers}
            standings={{ items: [fakeStanding] }}
            gameStatus={state.poolInfo.status}
            players={state.players}
            buckets={state.poolBuckets}
            myEntry={state.myEntry}
            withRegister={state.withRegister}
            currentContest={state.currentContest}
          />
        </div>
        <div className="totalesList flex-container justify align " style={{ paddingBottom: '0' }}>
          <div className="lower-table-item-group flex-container justify align">
            <div className="item-container-group-pool flex-container align justify ">
              {!state.myEntry.group_pool_standing || !state.myEntry.group_pool_standing.points
                ? '-'
                : state.myEntry.group_pool_standing.points}{' '}
              pts
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadMore = () => {
    console.log('more...', state.submittingGroupPicks);
    if (state.submittingGroupPicks) {
      return;
    }

    groupPoolActions.siguientePagGroupPicks(dispatch).then((response) => {
      console.log(response, state.queryParamsGroupPicks);
      groupPoolActions
        .getMoreGroupPicks(dispatch, state.poolInfo.id, state.queryParamsGroupPicks)
        .then((response) => {
          groupPoolActions.submittingGroupPicks(dispatch);
        });
    });
  };

  const mounted = React.useRef();
  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      setTimeout(() => {
        // console.log('Sync');// do componentDidUpate logic
        window.jQuery('.sync-horizontal').synchroniseScroll('horizontal');
        window.jQuery('.sync-vertical').synchroniseScroll('vertical');
      }, 1200);
    } else {
    }
  });

  return (
    <div className="multiple-quiniela-info grupo flex-row">
      <div className="upper-section">
        <div className="container-picks">
          <div className="upper-table">
            <div className="upper-table-category  headers border-gradient font-chivo">
              <MainHeader
                ties={state.tiebreakers}
                myEntry={state.myEntry}
                buckets={state.poolBuckets}
                players={state.players}
                poolInfo={state.poolInfo}
                currentContest={state.currentContest}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lower-section" style={{marginTop: '5px'}}>
        <div className="container-picks">
          <div className="tabla-grupos">
            <div
              className={` picks-message-overlay ${
                state.submittingGroupPicks ? 'show-message' : 'hide-message'
                } `}
            >
              <div className="flex-container justify align">
                <strong>...</strong>
              </div>
            </div>
            <div className="lower-table border-gradient">
              {state.withRegister ? userRow() : null}
              <TablaPicksGrupo
                submitting={state.submittingGroupPicks}
                poolId={state.poolInfo.id}
                loadMore={loadMore}
                dispatch={dispatch}
                gameStatus={state.poolInfo.status}
                queryParams={state.queryParamsGroupPicks}
                myEntry={state.myEntry}
                withRegister={state.withRegister}
                buckets={state.poolBuckets}
                ties={state.tiebreakers}
                players={state.players}
                groupPicks={state.groupPicks}
                dispatch={dispatch}
                currentContest={state.currentContest}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PicksGrupoPoolGroup;
