import { Subject } from 'rxjs/Subject';

let fixtureAnnounceSource = new Subject();
let fixtureReceived$ = fixtureAnnounceSource.asObservable();

let propsAnnounceSource = new Subject();
let propsReceived$ = propsAnnounceSource.asObservable();

let poolUpdateAnnounceSource = new Subject();
let poolUpdateReceived$ = poolUpdateAnnounceSource.asObservable();

let fixtureCloseAnnounceSource = new Subject();
let fixtureCloseReceived$ = fixtureCloseAnnounceSource.asObservable();

let poolCloseAnnounceSource = new Subject();
let poolCloseReceived$ = poolCloseAnnounceSource.asObservable();

let standingsUpdatedAnnounceSource = new Subject();
let standingsUpdatedReceived$ = standingsUpdatedAnnounceSource.asObservable();

let survivorStandingsUpdatedAnnounceSource = new Subject();
let survivorStandingsUpdatedReceived$ = survivorStandingsUpdatedAnnounceSource.asObservable();

let survivorStandingsUpdatedAnnounceSourceF1 = new Subject();
let survivorStandingsUpdatedReceivedF1$ = survivorStandingsUpdatedAnnounceSourceF1.asObservable();

let weeklyStandingsUpdatedAnnounceSource = new Subject();
let weeklyStandingsUpdatedReceived$ = weeklyStandingsUpdatedAnnounceSource.asObservable();

let closeDepositResponseIframeAnnounceSource = new Subject();
let closeDepositResponseIframeReceived$ = closeDepositResponseIframeAnnounceSource.asObservable();

let closeGroupPoolAnnounceSource = new Subject();
let closeGroupPoolReceived$ = closeGroupPoolAnnounceSource.asObservable();

let groupPoolStandingUpdateAnnounceSource = new Subject();
let groupPoolStandingUpdateReceived$ = groupPoolStandingUpdateAnnounceSource.asObservable();

let groupPoolPlayersUpdateAnnounceSource = new Subject();
let groupPoolPlayersUpdateReceived$ = groupPoolPlayersUpdateAnnounceSource.asObservable();

let groupPoolPlayersUpdateAnnounceSourceF1 = new Subject();
let groupPoolPlayersUpdateReceivedF1$ = groupPoolPlayersUpdateAnnounceSourceF1.asObservable();

const subscriptions = {
  /**
   * Subscription to group pool Players Update in Misquinielas
   */
  groupPoolPlayersUpdateAnnounceSource: groupPoolPlayersUpdateAnnounceSource,
  groupPoolPlayersUpdateReceived$: groupPoolPlayersUpdateReceived$,
  announceGroupPoolPlayersUpdated: function(data) {
    // console.log('%c GroupPool Playerss Update ', 'background:#00e0b3; font-size:20px;');
    // console.log(data);
    // console.log(this);
    if (!data) {
      return;
    }
    this.groupPoolPlayersUpdateAnnounceSource.next(data);
  },
  /**
   * Subscription to group pool Players Update in F1
   */
   groupPoolPlayersUpdateAnnounceSourceF1: groupPoolPlayersUpdateAnnounceSourceF1,
   groupPoolPlayersUpdateReceivedF1$: groupPoolPlayersUpdateReceivedF1$,
   announceGroupPoolPlayersUpdatedF1: function(data) {
     // console.log('%c GroupPool Playerss Update ', 'background:#00e0b3; font-size:20px;');
     // console.log(data);
     // console.log(this);
     if (!data) {
       return;
     }
     this.groupPoolPlayersUpdateAnnounceSourceF1.next(data);
   },
  /**
   * Subscription to group pool closed in quinielas
   */
  groupPoolStandingUpdateAnnounceSource: groupPoolStandingUpdateAnnounceSource,
  groupPoolStandingUpdateReceived$: groupPoolStandingUpdateReceived$,
  announceGroupPoolStandingUpdated: function(data) {
    console.log('%c GroupPool Standings Update ', 'background:#00e0b3; font-size:20px;');
    // console.log(data);
    // console.log(this);
    if (!data) {
      return;
    }
    this.groupPoolStandingUpdateAnnounceSource.next(data);
  },
  /**
   * Subscription to group pool closed in quinielas
   */
  closeGroupPoolAnnounceSource: closeGroupPoolAnnounceSource,
  closeGroupPoolReceived$: closeGroupPoolReceived$,
  announceCloseGroupPoolUpdated: function(data) {
    console.log('%c Close GroupPool ', 'background:#00e0b3; font-size:20px;');
    console.log(data);
    // console.log(this);
    if (!data) {
      return;
    }
    this.closeGroupPoolAnnounceSource.next(data);
  },
  /**
   * Subscription to know when the users close the Response Ifrrame @FormMultiDeposito
   */
  closeDepositResponseIframeAnnounceSource: closeDepositResponseIframeAnnounceSource,
  closeDepositResponseIframeReceived$: closeDepositResponseIframeReceived$,
  announceClosedResponseIframeUpdated: function(data) {
    // console.log('%c Close the iframe ', 'background:#00e0b3; font-size:20px;');
    // console.log(data);
    // console.log(this);
    if (!data) {
      return;
    }
    this.closeDepositResponseIframeAnnounceSource.next(data);
  },
  /**
   * Standings Updates insurvivor by subscription
   */
  survivorStandingsUpdatedAnnounceSource: survivorStandingsUpdatedAnnounceSource,
  survivorStandingsUpdatedReceived$: survivorStandingsUpdatedReceived$,
  announceSurvivorStandingsUpdatedsUpdated: function(data) {
    // console.log('%c Standings Survivor Updated', 'background:#68B300; font-size:20px;');
    // console.log(data);
    if (!data.length && !data) {
      return;
    }
    this.survivorStandingsUpdatedAnnounceSource.next(data);
  },
  /**
   * Standings Updates in survivor by subscription race F1
   */
   survivorStandingsUpdatedAnnounceSourceF1: survivorStandingsUpdatedAnnounceSourceF1,
   survivorStandingsUpdatedReceivedF1$: survivorStandingsUpdatedReceivedF1$,
   announceSurvivorStandingsUpdatedsUpdatedF1: function(data) {
     // console.log('%c Standings Survivor Updated', 'background:#68B300; font-size:20px;');
     // console.log(data);
     if (!data.length && !data) {
       return;
     }
     this.survivorStandingsUpdatedAnnounceSourceF1.next(data);
   },
  /**
   * Standings Updates by subscription
   */
  standingsUpdatedAnnounceSource: standingsUpdatedAnnounceSource,
  standingsUpdatedReceived$: standingsUpdatedReceived$,
  announceStandingsUpdatedsUpdated: function(data) {
    // console.log('%c Standings Updated', 'background:#68B300; font-size:20px;');
    // console.log(data);
    if (!data.length && !data) {
      return;
    }
    this.standingsUpdatedAnnounceSource.next(data);
  },

  /**
   * WeeklyStandings Updates by subscription
   */
  weeklyStandingsUpdatedAnnounceSource: weeklyStandingsUpdatedAnnounceSource,
  weeklyStandingsUpdatedReceived$: weeklyStandingsUpdatedReceived$,
  announceWeeklyStandingsUpdatedsUpdated: function(data) {
    // console.log('%c Weekly Standings Updated', 'background:#68B300; font-size:20px;');
    // console.log(data);
    if (!data.length && !data) {
      return;
    }
    this.weeklyStandingsUpdatedAnnounceSource.next(data);
  },
  /**
   * Fixture Updates by subscription
   */
  fixtureAnnounceSource: fixtureAnnounceSource,
  fixtureReceived$: fixtureReceived$,
  announceFixturesUpdated: function(data) {
    // console.log('%c New fixtures', 'background:#36A121; font-size:20px;');
    //console.log(data.results, data.live_fixtures);
    if (!data && !data.length) {
      return;
    }
    this.fixtureAnnounceSource.next(data);
  },
  /**
   * Props Updates by subscription
   */
  propsAnnounceSource: propsAnnounceSource,
  propsReceived$: propsReceived$,
  announcePropsUpdated: function(data) {
    // console.log('%c New props', 'background:#009BF5;font-size:20px;');
    //console.log(data.results, data.live_fixtures);
    if (!data && !data.length) {
      return;
    }
    this.propsAnnounceSource.next(data);
  },
  /**
   * PoolUpdate by subscription
   */
  poolUpdateAnnounceSource: poolUpdateAnnounceSource,
  poolUpdateReceived$: poolUpdateReceived$,
  announcePoolUpdateUpdated: function(data) {
    console.log('%c New update from Pool', 'background:#FF8713;font-size:20px;');
    //console.log(data.results, data.live_fixtures);
    if (!data && !data.length) {
      return;
    }
    this.poolUpdateAnnounceSource.next(data);
  },
  /**
   * fixtureClose Update by subscription
   */
  fixtureCloseAnnounceSource: fixtureCloseAnnounceSource,
  fixtureCloseReceived$: fixtureCloseReceived$,
  announceFixtureCloseUpdated: function(data) {
    // console.log('%c Fixture Closed', 'background:#D1000A;font-size:20px;');
    //console.log(data.results, data.live_fixtures);
    if (!data && !data.length) {
      return;
    }
    this.fixtureCloseAnnounceSource.next(data);
  },
  /**
   * poolClose Update by subscription
   */
  poolCloseAnnounceSource: poolCloseAnnounceSource,
  poolCloseReceived$: poolCloseReceived$,
  announcePoolCloseUpdated: function(data) {
    // console.log('%c poolClose', 'background:#D1000A;font-size:20px;');
    //console.log(data.results, data.live_fixtures);
    if (!data && !data.length) {
      return;
    }
    this.poolCloseAnnounceSource.next(data);
  },
};

export default subscriptions;
