import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { PulseLoader } from 'react-spinners'

// Components
import Input from './Input'
import Text from '../Text'
import Checkbox from '../Form/Checkbox'

// Utils
import API from '../../utils/api'

const DEFAULT_ERROR = 'Ha ocurrido un error, favor de intentar nuevamente'

const initialState = {
  rfc: '',
  curp: '',
  valid: false,
  address: '',
  financial_activity: ''
}

export default function ExtraFinancialData ({ financialData, onSuccess }) {
  const [loading, setLoading] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errors, setErrors] = useState(initialState)
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (financialData) {
      // Merging parent state
      const nextState = Object
        .keys(financialData)
        .reduce((prev, next) => ({
          ...prev,
          [next]: financialData[next].value
        }), {})

      setState(state => ({ ...state, ...nextState }))
    }
  }, [financialData])

  const onChange = (data, key, value) => {
    setState((prev) => ({
      ...prev,
      ...data
    }))

    checkRealTimeErrors(key, value)
  }

  const submit = async () => {
    setLoading(true)
    setErrorMessage()

    try {
      // Get data to send
      const sendData = Object
        .keys(state)
        .reduce((prev, next) => ({
          ...prev,
          [next]: {
            value: state[next]
          }
        }), {})

      const { data } = await API.updateFinancialData(sendData)
      const { rfc, curp, address, financial_activity } = data.userData

      setLoading(false)

      if (!data.success) {
        return setErrorMessage(DEFAULT_ERROR)
      }

      // Check all received values
      if (!rfc) {
        return setErrorMessage('Debes ingresar tu RFC')
      }
      if (rfc && rfc.value.length < 13) {
        return setErrorMessage('Debes ingresar tu RFC completo')
      }
      if (!curp) {
        return setErrorMessage('Debes ingresar tu CURP')
      }
      if (curp && curp.value.length < 18) {
        return setErrorMessage('Debes ingresar tu CURP completa')
      }
      if (!address || (address && address.value.length < 5)) {
        return setErrorMessage('Debes ingresar tu Direccion')
      }
      if (!financial_activity || (financial_activity && financial_activity.value.length < 5)) {
        return setErrorMessage('Debes ingresar tu Actividad Financiera')
      }

      // Emit to parent
      onSuccess()
    } catch (error) {
      setErrorMessage(DEFAULT_ERROR)
    }
    setLoading(false)
  }

  const disabled = (
    !state.valid ||
    state.rfc.length < 13 ||
    state.curp.length < 18 ||
    state.address.length < 5 ||
    state.financial_activity.length < 5
  )

  const getValidationError = (key, value) => {
    // On Blur validations
    let errorMessage = null

    switch (key) {
      case 'rfc': {
        if (!value) {
          errorMessage = 'El RFC es necesario'
        }
        if (value && value.length < 13) {
          errorMessage = 'El RFC debe ser de 13 caracteres'
        }
        break
      }
      case 'curp': {
        if (!value) {
          errorMessage = 'La CURP es necesaria'
        }
        if (value && value.length < 18) {
          errorMessage = 'La CURP debe ser de 18 caracteres'
        }
        break
      }
      case 'financial_activity': {
        if (!value) {
          errorMessage = 'La Actividad Financiera es necesaria'
        }
        if (value && value.length < 5) {
          errorMessage = 'La Actividad Financiera es necesaria'
        }
        break
      }
      case 'address': {
        if (!value) {
          errorMessage = 'La Direccion es necesaria'
        }
        if (value && value.length < 5) {
          errorMessage = 'La Direccion es necesaria'
        }
        break
      }
    }

    return errorMessage
  }

  const checkRealTimeErrors = (key, value) => {
    // On Blur validations
    let errorMessage = getValidationError(key, value)

    setErrors(prev => ({
      ...prev,
      [key]: {
        errorMessage,
        color: 'lime'
      }
    }))
  }

  const onBlur = (key, value) => {
    // On Blur validations
    let errorMessage = getValidationError(key, value)

    setErrors(prev => ({
      ...prev,
      [key]: {
        errorMessage,
        color: '#ff4c6c'
      }
    }))
  }

  if (!financialData) return null

  return (
    <Box>
      <Text>En cumplimiento a la Ley de Prevención de Lavado de Dinero, es necesario que nis proporciones la siguiente información:</Text>
      <Text as='caption' mt={2}>Unicamiente lo tendrás que llenar 1 vez</Text>
      <Input
        uppercase
        name='rfc'
        label='RFC'
        placeholder='XAXX010101000'
        value={state.rfc}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={13}
        error={errors.rfc}
      />
      <Input
        uppercase
        name='curp'
        label='CURP'
        placeholder='PICK6300321HDELRC09'
        onChange={onChange}
        onBlur={onBlur}
        value={state.curp}
        maxLength={18}
        error={errors.curp}
      />
      <Input
        name='financial_activity'
        label='Actividad Financiera'
        value={state.financial_activity}
        onChange={onChange}
        onBlur={onBlur}
        error={errors.financial_activity}
      />
      <Input
        textarea
        name='address'
        label='Direccion'
        value={state.address}
        onChange={onChange}
        onBlur={onBlur}
        error={errors.address}
      />
      <Checkbox
        name='valid'
        value={state.valid}
        style={{ margin: '1em 0' }}
        onChange={(_, value) => onChange({ valid: value }, 'valid', value)}
        label='Certifico que los datos proporcionados son correctos.'
      />
      {errorMessage && (
        <Text as='caption' color='red'>
          {errorMessage}
        </Text>
      )}
      <button
        type='button'
        onClick={submit}
        disabled={disabled}
        className='btn-form btn-pick flex-container align justify btn btn-default'
      >
        {loading ? <PulseLoader color='#FFF' /> : 'Guardar'}
      </button>
    </Box>
  )
}

const Box = styled.div`
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
`
