import React from 'react'
import {
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap/lib'

const SelectTypeReferral = ({
  selectedType,
  isNewReferral,
  handleSelectedType
}) => {
  if (!isNewReferral) {
    return (
      <div className='flex-row no-padding '>
        <ButtonToolbar className='flex-col referals-selector'>
          <ToggleButtonGroup
            className='flex-row row-no-padding'
            type='radio'
            name='typeReferrals'
            value={selectedType}
            onChange={handleSelectedType}
          >
            <ToggleButton className='flex-col' value='new'>
              Referidos
            </ToggleButton>
            <ToggleButton className='flex-col' value='old'>
              Invitados
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>
    )
  } else {
    return null
  }
}

export default SelectTypeReferral
