import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, DropdownButton, MenuItem
} from 'react-bootstrap/lib/';
import Loading from '../Loading/Loading';
import Futbol from '../../assets/images/FutbolBall-50.png';
import Americano from '../../assets/images/AmericanoBall-50.png';
import Basketball from '../../assets/images/BasketballBall-50.png';
import Baseball from '../../assets/images/BaseballBall-50.png';
import Golf from '../../assets/images/GolfBall-50.png';
import Tenis from '../../assets/images/TennisBall-50.png';
import Box from '../../assets/images/box.png';
import F1 from '../../assets/images/Volante-50.png';
import Hockey from '../../assets/images/Hockey.png';
import closeW from '../../assets/images/close.png';
import TablaPosMisQuinielaGrupo from '../TablaPosMisQuinielaGrupo';
import numeral from 'numeral';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import QuinielaGroupsUI from '../QuinielaGroupsUI';
import SubscriptionRxs from '../../utils/subscriptions';
import API from '../../utils/api';
import _ from 'underscore';

import actions from '../QuinielaGroupsUI/actions';
import * as Sentry from '@sentry/browser';

const MiQuinielaGrupoPanel = ({
  closeMiQuina,
  entry,
  index,
  loadedPanel
}) => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);
  //console.log("MIQUINGROUP:", state);
  let poolId = entry.group_pool_id;
  let entryId = entry.id;

  const SelectContest = ({ currentContest, contests, changeContest }) => {
    return (
      <div className=" flex-col col-no-padding col-center boton-titulo-dash jornadas">
        <DropdownButton
          id="jornadasTemporadaDropdown"
          rootCloseEvent={'click'}
          title={`Carreras`}
          className="seleccionaJornada flex-row text-center"
        >
          {contests.map((contest, i) => {
            let currentContestID = !currentContest && currentContest.id ? currentContest.id : null;

            return (
              <MenuItem key={i} onClick={() => changeContest(contest.id)}>
                {contest.id === currentContestID ? <i className="ion-arrow-right-a" /> : ''}
                {contest.name}
              </MenuItem>
            );
          })}
        </DropdownButton>
      </div>
    );
  };

  const handleContestChange = (contestId) => {
    // Update contest
    let currentContest = state.poolInfo.contests.find((item) => item.id === contestId);
    dispatch({ type: 'updateCurrentContest', payload: { currentContest } });

    // Update players
    API.fetchPoolGroupPlayers(state.poolInfo.id, contestId)
    .then((response) => {
      let bucketPlayers = [];
      _.forEach(response.data.players, (item, index) => {
        item.bucket = null;
        bucketPlayers.push(item);
      });
      let buckets = {};

      state.poolInfo.contests.map((contest) => {
        buckets[contest.id] = [];
        for (let i = 0; i < state.poolInfo.num_picks; i++) {
          const obj = {};
          obj['id'] = i+1;
          obj['players'] = bucketPlayers;
          obj['isOpen'] = false;
          obj['player'] = null;
          obj['pick'] = null;
          buckets[contest.id][i] = obj;
        }
      });

      dispatch({ type: 'updatePlayersF1', payload: { players: response.data.players, buckets } });
    });
  }

  React.useEffect(() => {
    // console.log('%c Initial Side Effect with Entry', 'color:rgb(255, 199, 48) ; font-size: 2em;');

    Promise.all([
      actions.getPoolDetails(dispatch, poolId),
      actions.getUserData(dispatch),
      actions.getUserTickets(dispatch, poolId),
      actions.getPoolEntry(dispatch, entryId),
      //actions.getPoolStandings(dispatch, poolId, state.queryParams),
      actions.getSimilarPools(dispatch, entryId),
    ])
      .then(
        (values) => {
          let [
            poolDetails,
            userData,
            tickets,
            poolEntry,
            //poolStandings,
          ] = [...values];

          actions.isBalanceEnough(
            dispatch,
            poolDetails.data.group_pool.entry_fee,
            poolDetails.data.group_pool.entry_currency
          );
          actions
            .initializeEntry(
              dispatch,
              poolId,
              poolDetails.data.buckets,
              poolDetails.data.bucketKeys,
              poolDetails.data.group_pool.group_pool_tiebreakers,
              poolEntry.data.group_pool_entry,
              true,
              poolDetails.data.players,
              poolDetails.data.current_contest,
              poolDetails.data.group_pool.entry_currency,
              poolDetails.data.group_pool.contests,
            )
            .then((response) => {
              loadedPanel(poolId, entryId, 'group_pool', poolDetails);

              if (poolDetails.data.group_pool.status === 'upcoming') {
                actions.initializeCountdownPool(dispatch, poolDetails);
              }


              actions.getPoolStandings(dispatch, poolId, state.queryParams, poolDetails.data.current_contest.id).then((response) => {
                let poolStandings = response;
                if (poolDetails.data.group_pool.status === 'live') {
                  let fakeObj = {
                    group_pool_id: poolDetails.data.group_pool.id,
                    standings: poolStandings && poolStandings.hasOwnProperty('data') ? poolStandings.data.items : [],
                    ties_distribution: poolDetails.data.ties_distribution,
                  };
                  actions.updateStandingsAmount(dispatch, fakeObj);
                } else {
                  actions.initializeBucketsState(
                    dispatch,
                    state.poolBuckets, //poolDetails.data.buckets,
                    poolEntry.data.group_pool_entry,
                    poolDetails.data.current_contest,
                  );
                }
              });
            });

          dispatch({ type: 'isReady' });

          SubscriptionRxs.groupPoolStandingUpdateReceived$.subscribe(
            (updatedStandings) => {
              actions.updatePlayersSocket(dispatch, updatedStandings).then((response) => {
                actions.updateStandingsAmount(dispatch, updatedStandings);
              });
            }
          );

          SubscriptionRxs.groupPoolPlayersUpdateReceived$.subscribe(
            (updatedPlayers) => {
              // console.log( 'Players Subscription Rxjs', updatedPlayers );
              actions.updatePlayersSocket(dispatch, updatedPlayers);
            }
          );

          SubscriptionRxs.closeGroupPoolReceived$.subscribe(
            (poolClose) => {
              //console.log('Players Subscription Rxjs', updatedPlayers);
              actions.updatePoolClose(dispatch, poolClose);
            }
          );
          SubscriptionRxs.groupPoolPlayersUpdateReceivedF1$.subscribe(
            (raceResultData) => {
              actions.updateRaceResultsSoket(dispatch, raceResultData);
            }
          );
        },
        (reason) => {
          console.error(reason);
          Sentry.captureException(reason);
          dispatch({ type: 'isError', payload: { error: reason } });
        }
      )
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        window.location = '/quinielaGrupo/sinRegistro/' + poolId;
        dispatch({ type: 'isError', payload: { error: error } });
      });
  }, [dispatch, entryId, loadedPanel, poolId, state.queryParams]);

  const setSport = (sport_id) => {
    return (
      <img
        className="title-ball-icon"
        src={
          sport_id === 1
            ? Futbol
            : sport_id === 2
              ? Americano
              : sport_id === 3
                ? Basketball
                : sport_id === 4
                  ? Baseball
                  : sport_id === 5
                    ? Tenis
                    : sport_id === 6
                      ? Golf
                      : sport_id === 7
                        ? F1
                        : sport_id === 8
                          ? Box
                          : sport_id === 9
                            ? Hockey
                            : null
        }
      />
    );
  };

  if (!state || !state.mounted) {
    return (
      <div className="flex-col dashboard-item ">
        <div className="panelMisQuinielas">
          <Loading></Loading>
        </div>
      </div>
    );
  }

  let moneyFormat = state.poolInfo.entry_currency === 'real' ? '$0,000' : '0,000';
  // console.log('maxHeight', state.maxHeight);
  return (
    <div
      key={entryId}
      id={`picksContainer-${entryId}`}
      className={`flex-col dashboard-item ${state.poolInfo.status}`}
      key={entryId}
    >
      <div className="panelMisQuinielas">
        <div className=" flex-row title-quiniela main">
          <div className="col-xs-10 title-container-mis-quinielas">
            {setSport(state.poolInfo.sport_id)}
            <span>
              {' '}
              {state.poolInfo.name_replaced}{' '}
              {numeral(state.poolInfo.entry_fee / 100).format(moneyFormat)}{' '}
              {state.poolInfo.entry_currency === 'real'
                ? 'MXN'
                : state.poolInfo.entry_currency === 'pickcoin'
                  ? 'PC'
                  : 'Rf'}
            </span>
            <span style={{ fontSize: 'small', margin: '0 3px' }}>
              {entry.number === null ? null : `[${entry.number}]`}
            </span>
          </div>
          <div className=" col-xs-2 btn-close">
            <Button
              className="closeQuinaBtn"
              onClick={() => {
                closeMiQuina(index);
              }}
            >
              <div className="btn-icon-close">
                <img src={closeW} className={'closeIcon'} />
              </div>
            </Button>
          </div>
        </div>
        <div className="flex-row titulo-mis-picks panel-quiniealas">
          <div className="flex-col ">
            <div className="flex-row" style={{ gap: '0.3em'}}>
              <div className=" flex-col col-no-padding boton-titulo-dash">
                <Link
                  className="flex-row row-no-padding"
                  to={`/quinielaGrupo/${state.poolInfo.slug}/${poolId}/${entryId}`}
                >
                  <Button className="ir-quiniela flex-container">Ir a la quiniela</Button>
                </Link>
              </div>
              {state.poolInfo.type === 'full' && state.poolInfo.sport_id === 7 ? (
                <div className=" flex-col col-no-padding boton-titulo-dash">
                  <SelectContest
                    currentContest={state.currentContest}
                    contests={state.poolInfo.contests}
                    changeContest={(contestId) => {handleContestChange(contestId)}}
                  />
                </div>
              ) : null}
            </div>
            <div className='flex-container justify align'>
              <h4 className='font-chivo' style={{margin: '0px'}}>{state.currentContest.name}</h4>
            </div>
          </div>
        </div>
        <div>
          <QuinielaGroupsUI />
        </div>
        <TablaPosMisQuinielaGrupo
          poolInfo={state.poolInfo}
          standings={state.standings.items}
          prizes={state.prizes}
          players={state.players}
          myEntry={state.myEntry}
          withRegister={state.withRegister}
        />
      </div>
    </div>
  );
};

export default MiQuinielaGrupoPanel;
