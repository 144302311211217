
// Format seconds as a time string, H:MM:SS or M:SS. Supplying a guide (in
// seconds) will force a number of leading zeros to cover the length of the
// guide.
export default function getFormatTime (seconds, guide) {
  seconds = seconds < 0 ? 0 : seconds
  let s = Math.floor(seconds % 60)
  let m = Math.floor(seconds / 60 % 60)
  let h = Math.floor(seconds / 3600)
  const gm = Math.floor(guide / 60 % 60)
  const gh = Math.floor(guide / 3600)

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = m = s = '-'
  }

  // Check if we need to show hours
  h = (h > 0 || gh > 0) ? h + ':' : ''

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  m = (((h || gm >= 10) && m < 10) ? '0' + m : m) + ':'

  // Check if leading zero is need for seconds
  s = (s < 10) ? '0' + s : s

  return h + m + s
}

