import React from 'react';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';
import avatar from '../../assets/images/avatar-50.png';
import utils from '../../utils/utils';
import Utilities from '../../utils/quinielasUtils';

//console.log("POOLS:",pools);
class Participantes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      usrData: null,
      usersItems: this.props.users.items,
      remoteRowCount: 0,
    };
    this.rowRenderer = this.rowRenderer.bind(this);
    this.rowRendererBracket = this.rowRendererBracket.bind(this);
    this.rowRendererSurvivor = this.rowRendererSurvivor.bind(this);
    this.rowRendererGroupPool = this.rowRendererGroupPool.bind(this);
    this.loadMoreRows = ({ startIndex, stopIndex }) => {
      console.log('Load');
      this.props.siguientePagPositions();
    };
    this.isRowLoaded = ({ index }) => {
      return !!this.state.usersItems[index];
    };
  }
  rowRenderer(props) {
    // console.log(props);
    // If row content is complex, consider rendering a light-weight placeholder while scrolling.
    let content = null;
    const usersItems = this.props.users.items;
    // console.log('Row Renderer Premios',props.isScrolling, props.index, index);
    // console.log('Row Renderer P',props.index , usersItems);
    if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
      console.log('escape');
      return;
    }
    //console.log('Mensaje de otro')
    content = (
      <li
        key={props.key}
        id={Utilities.highlightThisUser(usersItems[props.index].user_id, 64560)}
        className="users-item"
      >
        <div className="score-avatars">
          <img
            src={
              usersItems[props.index].user.avatar === null
                ? avatar
                : `${usersItems[props.index].user.avatar_dir}${usersItems[props.index].user.avatar}`
            }
            className="img-circle "
          />
        </div>
        <div>
          {usersItems[props.index].user.nick}
          <small className="corchete-multy">
            {`${
              usersItems[props.index].entry.number !== undefined && usersItems[props.index].entry.number !== null
                ? '[' + usersItems[props.index].entry.number + ']'
                : ''
              }`}
          </small>
        </div>
      </li>
    );
    return (
      <div
        className={props.index % 2 === 0 ? 'even-colored-row' : ''}
        key={props.key}
        style={props.style}
      >
        {content}
      </div>
    );
  }

  rowRendererGroupPool(props) {
    // console.log(props);
    // If row content is complex, consider rendering a light-weight placeholder while scrolling.
    let content = null;
    const usersItems = this.props.users.items;
    // console.log('Row Renderer Premios',props.isScrolling, props.index, index);
    // console.log('Row Renderer P',props.index , usersItems);
    if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
      content = <div className="flex-container justify align"> No hay ningún jugador</div>;
    }
    //console.log('Mensaje de otro')
    else {
      content = (
        <li
          key={props.key}
          id={Utilities.highlightThisUser(usersItems[props.index].user_id, 64560)}
          className="users-item"
        >
          <div className="score-avatars">
            <img
              src={
                usersItems[props.index].user.avatar === null
                  ? avatar
                  : `${usersItems[props.index].user.avatar_dir}${
                  usersItems[props.index].user.avatar
                  }`
              }
              className="img-circle "
            />
          </div>
          <div>
            {usersItems[props.index].user.nick}
            <small className="corchete-multy">
              {`${
                usersItems[props.index].group_pool_entry.number !== undefined &&  usersItems[props.index].group_pool_entry.number !== null
                  ? '[' + usersItems[props.index].group_pool_entry.number + ']'
                  : ''
                }`}
            </small>
          </div>
        </li>
      );
    }
    return (
      <div
        className={props.index % 2 === 0 ? 'even-colored-row' : ''}
        key={props.key}
        style={props.style}
      >
        {content}
      </div>
    );
  }
  rowRendererSurvivor(props) {
    // console.log(props);
    // If row content is complex, consider rendering a light-weight placeholder while scrolling.
    let content = null;
    const usersItems = this.props.users.items;
    // console.log('Row Renderer Premios',props.isScrolling, props.index, index);
    // console.log('Row Renderer P',props.index , usersItems);
    if (usersItems === undefined || usersItems === null || usersItems.length === 0) {
      console.log('escape');
      return;
    }

    content = (
      <li
        key={props.key}
        id={Utilities.highlightThisUser(usersItems[props.index].user_id, 64560)}
        className="users-item"
      >
        <div className="score-avatars">
          <img
            src={
              usersItems[props.index].user.avatar === null
                ? avatar
                : `${usersItems[props.index].user.avatar_dir}${usersItems[props.index].user.avatar}`
            }
            className="img-circle "
          />
        </div>
        <div>
          {usersItems[props.index].user.nick}
          <small className="corchete-multy">
            {`${
              (usersItems[props.index].survivor_entry.number !== undefined && usersItems[props.index].survivor_entry.number !== null)
                ? '[' + usersItems[props.index].survivor_entry.number + ']'
                : ''
              }`}
          </small>
        </div>
      </li>
    );
    return (
      <div
        className={props.index % 2 === 0 ? 'even-colored-row' : ''}
        key={props.key}
        style={props.style}
      >
        {content}
      </div>
    );
  }
  rowRendererBracket(props) {
    // console.log(props);
    // If row content is complex, consider rendering a light-weight placeholder while scrolling.
    let content = null;
    const usersItems = this.props.users.items;
    // console.log('Row Renderer Premios',props.isScrolling, props.index, index);
    // console.log('Row Renderer P',props.index , usersItems);
    // if(usersItems === undefined || usersItems === null || usersItems.length === 0){ console.log('escape'); return ;}
    //console.log('Mensaje de otro')
    content = (
      <li
        key={props.key}
        id={Utilities.highlightThisUser(usersItems[props.index].user_id, 64560)}
        className="users-item"
      >
        <div className="score-avatars">
          <img
            src={
              usersItems[props.index].user.avatar === null
                ? avatar
                : `${usersItems[props.index].user.avatar_dir}${usersItems[props.index].user.avatar}`
            }
            className="img-circle "
          />
        </div>
        <div>
          {usersItems[props.index].user.nick}
          <small className="corchete-multy">
            {`${
              usersItems[props.index].bracket_entry.number !== undefined
                ? '[' + usersItems[props.index].bracket_entry.number + ']'
                : ''
              }`}
          </small>
        </div>
      </li>
    );
    return (
      <div
        className={props.index % 2 === 0 ? 'even-colored-row' : ''}
        key={props.key}
        style={props.style}
      >
        {content}
      </div>
    );
  }

  componentDidMount() {
    // console.log('Participantes ')
    this.setState(() => {
      return {
        mounted: true,
        userData: utils.getUserInfo(),
        logged: utils.isLogged(),
      };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.list.forceUpdateGrid();
    if (prevProps.users.items.length !== this.props.users.items.length) {
      // console.log('Sort Changed!')
      this.setState({
        userItems: this.props.users.items,
      });
      this.list.forceUpdateGrid();
    }
  }

  render() {
    // console.log('Participantes REnder',this.props.users,  this.state.mounted);
    let conditionalComponent = null;
    if (this.state.mounted) {
      conditionalComponent = (
        <div className="lista-usuarios">
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={this.props.users.count}
            threshold={1}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => {
                  // console.log('height', height);
                  // console.log('width', width );
                  // console.log('onRowsRendered', onRowsRendered);
                  // console.log('this.props.users.items.length', this.props.users.items.length );
                  if (height === 0) {
                    height = 200;
                  }
                  return (
                    <List
                      className={'listaUsers'}
                      ref={(registerChild) => (this.list = registerChild)}
                      height={height}
                      overscanRowCount={20}
                      onRowsRendered={onRowsRendered}
                      rowCount={this.props.users.items.length}
                      rowHeight={25}
                      rowRenderer={
                        this.props.type === 'bracket'
                          ? this.rowRendererBracket
                          : this.props.type === 'survivor'
                            ? this.rowRendererSurvivor
                            : this.props.type === 'group_pool'
                              ? this.rowRendererGroupPool
                              : this.rowRenderer
                      }
                      width={width}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      );
    } else {
      conditionalComponent = <div id="loading"> Cargando... </div>;
    }
    return conditionalComponent;
  }
}

export default Participantes;
