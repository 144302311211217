import React from 'react';
import Loading from '../Loading/Loading';
import API from '../../utils/api';

import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';

import mensajes from '../../assets/images/mensajesIconBlanco-50.png';
import avatar from '../../assets/images/avatar-50.png';
import { FormGroup, FormControl, Button } from 'react-bootstrap';
import moment from 'moment';
import utils from '../../utils/utils';
import Utilities from '../../utils/quinielasUtils';
import $ from 'jquery';

import groupPoolActions from '../QuinielaGroupsUI/actions';

const cache = new CellMeasurerCache({
  defaultHeight: 35,
  fixedWidth: true,
});

class ChatPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      entries: [],
      mensajes: this.props.mensajes,
      newMessage: '',
      userNick: null,
      availableMessages: [],
      updatedMessage: null,
      socketData: null,
    };
    //this.sendMessageOnKeyPressed  = this.sendMessageOnKeyPressed.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.autoScroll = this.autoScroll.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.rowRendererChat = this.rowRendererChat.bind(this);

    this.setStyles = () => {
      let height = Number($('.first-section').height() - 208);

      // console.log(height);
      let styleObj = {
        height: height,
        //height: '683.75px',
        maxHeight: `${Math.floor($('.first-section').height() - 208)}`,
        borderTop: '#424041 1px solid',
      };

      // console.log(styleObj);

      return styleObj;
    };
    // console.log('Chat Props',this.props)
    //
  }

  /**
   * This function sets sthe scroll to the bottom
   * of the current element
   */
  autoScroll() {
    // console.log('%c Scroll it','color:rgb(48, 78, 136); font-size:30px;');
    // let listaMensajes = $('.lista-mensajes');
    let listaMensajes = $('.mensajes-list .overflow-y');
    // console.log('listaMensajes', listaMensajes);
    if (!listaMensajes[0]) {
      return;
    }
    let scroll = listaMensajes[0].scrollHeight;
    // console.log('Scroll', scroll);
    listaMensajes.animate({ scrollTop: scroll });
  }

  handleMessage(e) {
    this.setState({
      newMessage: e.target.value,
    });
  }

  /**
   * this function Sends the message via WS
   */
  sendMessage(e) {
    if (e.hasOwnProperty('key') && e.key !== 'Enter') {
      return;
    }

    if (this.state.newMessage === '') {
      return;
    }
    // console.log('SendMessage via socket io!');

    let nuevoMensajeTemporal = {
      avatar_full: this.props.userAvatar.avatar_dir + this.props.userAvatar.avatar,
      created: moment(),
      id: this.props.userAvatar.id,
      message: this.state.newMessage,
      nick: this.props.userAvatar.nick,
    };

    // let temporalMessages = this.state.availableMessages;
    // this.setState({
    //   // availableMessages: temporalMessages.concat([nuevoMensajeTemporal])
    // }, () => {
    // });
    if (nuevoMensajeTemporal.message === '') {
      return;
    }
    if (this.props.resetMessage !== undefined) {
      this.props.resetMessage();
    }
    //if (this.props.newMessage !== null && this.props.newMessage !== '') {
    API.sendChatMessage(
      this.props.quinielaID,
      this.props.userAvatar.id,
      this.props.gameType,
      nuevoMensajeTemporal
    );
    if (this.props.dispatch) {
      const synthMessage = { target: { value: '' } };
      groupPoolActions.handleMessage(this.props.dispatch, synthMessage);
    }
  }

  /**
   * This function handles the Message sent intro with KEy pressed
   * @param event
   */
  sendMessageOnKeyPressed(event) {
    console.log('SendMessage on Key ENter via socket io!');
    // console.log(this);
    let nuevoMensajeTemporal = {
      avatar_full: this.props.userAvatar.avatar_dir + this.props.userAvatar.avatar,
      created: moment(),
      id: this.props.userAvatar.id,
      message: this.props.newMessage,
      nick: this.props.userAvatar.nick,
    };
    // console.log(nuevoMensajeTemporal);
    if (event.key === 'Enter') {
      let temporalMessages = this.state.availableMessages;
      console.log(this.state);
      if (this.props.newMessage !== null && this.props.newMessage !== '') {
        // temporalMessages.push(nuevoMensajeTemporal);
        console.log('New Message Available');
        console.log(this.props.newMessage);
        API.sendChatMessage(
          this.props.quinielaID,
          this.props.userAvatar.id,
          // this.props.gameType,
          nuevoMensajeTemporal,
          utils.getToken(),
          (callback) =>
            this.setState(
              {
                newMessage: nuevoMensajeTemporal,
                availableMessages: nuevoMensajeTemporal.concat([temporalMessages]),
                socketData: callback,
              },
              () => {
                if (this.props.resetMessage !== undefined) {
                  this.props.resetMessage();
                }
              }
            )
        );
      }
    }
  }

  /*****************************
   * Lifecycle Methods
   *****************************/
  componentDidMount() {
    // window.onResize= () => {
    //   console.log('Resize');
    //   if(this.list !== undefined || this.list !== null){
    //     this.list.forceUpdateGrid();
    //   }
    // };
    // console.log('DIDMOUNT');
    // console.log(this.props);

    API.subscribeSocket(
      this.props.gameType,
      this.props.quinielaID,
      this.props.userAvatar.id,
      this.props.userNick,
      utils.getToken(),
      (callbackAllMessages) =>
        this.setState(
          () => {
            // console.log('callbackAllMessages',callbackAllMessages);
            return {
              availableMessages: callbackAllMessages,
              loaded: true,
              userNick: this.props.userNick,
            };
          },
          () => {
            // console.log('After Suscription')
          }
        ),
      (callbackNewMessage) => {
        let messages = this.state.availableMessages;
        this.setState(
          () => {
            return {
              availableMessages: messages.concat([callbackNewMessage]),
            };
          },
          () => {}
        );
      }
    );

    // TODO: check condition
    if (
      this.props.entries !== undefined &&
      this.props.entries !== null &&
      this.props.entries.length > 0
    ) {
      let newEntries = [];
      newEntries.push(this.props.entries.entry);
      this.props.entries.other_entries.forEach((entry) => {
        newEntries.push(entry);
      });
      this.setState({
        entries: newEntries,
      });
    }
    // API.updateChat(message, () =>{
    //     this.setState({
    //     updatedMessage:message,
    // })});
    // let avatars = $('.score-avatars').height('auto');

    $(window).on('resize', function() {
      console.log('List', this.virtualizedList, this.cellM);
      if (this.virtualizedList !== undefined || this.virtualizedList !== null) {
        // FIXME: virtualizedList.forceUpdateGrid no existe
        // this.virtualizedList.forceUpdateGrid();
      }
    });
    // console.log(avatars);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.newMessage);
    // console.log( this.state.newMessage);
    if (prevState.availableMessages.length !== this.state.availableMessages.length) {
      // console.log('settingTime');
      setTimeout(() => {
        // console.log('doscroll');
        let avatars = $('.score-avatars').height('auto');
        this.autoScroll();
      });
      // console.log(lastMessage);
    }
    if (this.state.userNick !== prevState.userNick) {
      let avatars = $('.score-avatars').height('auto');
    }

    if (this.props.newMessage === '') {
      // console.log('settingTime again');
      setTimeout(() => {
        // console.log('doscroll');
        let avatars = $('.score-avatars').height('auto');
        this.autoScroll();
      });
      // console.log(lastMessage);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log(nextProps.newMessage, this.props.newMessage);
    if (this.state.loaded !== nextState.loaded) {
      return true;
    } else if (
      nextState.availableMessages.length !== this.state.availableMessages.length ||
      nextProps.newMessage !== this.props.newMessage ||
      nextState.newMessage !== this.state.newMessage
    ) {
      return true;
    } else if (nextProps.withRegister !== this.props.withRegister) {
      return true;
    } else {
      return false;
    }
  }

  rowRendererChat() {
    // If row conte2nt is complex, consider rendering a light-weight placeholder while scrolling.
    // console.log('Row Renderer Chat');
    // console.log('Row Renderer',props, cache, parent);
    if (
      this.state.availableMessages === undefined ||
      this.state.availableMessages === null ||
      this.state.availableMessages.length === 0
    ) {
      // console.log('escape');
      return;
      // return <div className='text-center'> No hay mensajes en el chat</div>;
    }
    // console.log('Render with Message',this.state.availableMessages[props.index]);

    let listChat = this.state.availableMessages.map((message, idx) => {
      let splitedNick = message.nick;
      if (message.nick && message.nick.length > 10) {
        splitedNick = message.nick.slice(0, 9);
      }
      if (message.nick === this.state.userNick) {
        // console.log('Mi mensaje', mensaje.nick, this.props.userNick, mensaje.message);
        return (
          <li key={idx} className="flex-row row-no-padding mensaje-item self">
            <div className="mensaje-category usuario col-xs-1">
              <img
                src={this.props.userAvatar.avatar_dir + this.props.userAvatar.avatar}
                className="img-circle score-avatars"
              />
            </div>
            <div className="mensaje-category mensaje col-xs-11">
              <div className="row">
                <span className="nick-messagge">{splitedNick}:</span>
                <span className="date-messagge">{`${moment(message.created).format(
                  'MM-DD hh:mm'
                )}`}</span>
              </div>
              <div className="row">{message.message}</div>
            </div>
          </li>
        );
      } else {
        // console.log('Mensaje de otro')
        // console.log(this.state.availableMessages[props.index] );
        return (
          <div className={`${idx % 2 ? 'even-colored-row' : ''}`} key={idx}>
            <li
              id={this.state.availableMessages.length - 1 === idx ? 'last-message' : ''}
              className={`row mensaje-item ${Utilities.highlightThisUser(message.id, 64560)} `}
            >
              <div className="mensaje-category usuario col-xs-1">
                <img
                  src={
                    message.avatar_full === '/img/profile-image-01.png'
                      ? avatar
                      : message.avatar_full
                  }
                  className="img-circle score-avatars"
                />
              </div>
              <div className="mensaje-category mensaje col-xs-11">
                <div className="row">
                  <span className="nick-messagge">{splitedNick}:</span>
                  <span className="date-messagge">{`${moment(message.created).format(
                    'MM-DD hh:mm'
                  )}`}</span>
                </div>
                <div className="row">{message.message}</div>
              </div>
            </li>
          </div>
        );
      }
    });

    return listChat;
  }

  rowRenderer(props) {
    // If row conte2nt is complex, consider rendering a light-weight placeholder while scrolling.
    let content = null;
    // console.log('Row Renderer',props.isScrolling, props.key , this.state.availableMessages, props.index, index);
    // console.log('Row Renderer',props, cache, parent);
    if (
      this.state.availableMessages === undefined ||
      this.state.availableMessages === null ||
      this.state.availableMessages.length === 0
    ) {
      console.log('escape');
      return;
    }
    // console.log('Render with Message',this.state.availableMessages[props.index]);
    let splitedNick = this.state.availableMessages[props.index].nick;
    if (this.state.availableMessages[props.index].nick.length > 10) {
      splitedNick = this.state.availableMessages[props.index].nick.slice(0, 9);
    }

    if (this.state.availableMessages[props.index].nick === this.state.userNick) {
      // console.log('Mi mensaje', mensaje.nick, this.props.userNick, mensaje.message);
      content = (
        <li key={props.key} className="row mensaje-item self">
          <div className="mensaje-category usuario col-xs-1">
            <img
              src={this.props.userAvatar.avatar_dir + this.props.userAvatar.avatar}
              className="img-circle score-avatars"
            />
          </div>
          <div className="mensaje-category mensaje col-xs-11">
            <div className="row">
              <span className="nick-messagge">{splitedNick}:</span>
              <span className="date-messagge">{`${moment(
                this.state.availableMessages[props.index].created
              ).format('MM-DD hh:mm')}`}</span>
            </div>
            <div className="row">{`${this.state.availableMessages[props.index].message}`}</div>
          </div>
        </li>
      );
    } else {
      // console.log('Mensaje de otro')
      // console.log(this.state.availableMessages[props.index] );
      content = (
        <li
          key={props.key}
          id={this.state.availableMessages.length - 1 === props.index ? 'last-message' : ''}
          className={`row mensaje-item ${Utilities.highlightThisUser(
            this.state.availableMessages[props.index].id,
            64560
          )} `}
        >
          <div className="mensaje-category usuario col-xs-1">
            <img
              src={
                this.state.availableMessages[props.index].avatar_full ===
                '/img/profile-image-01.png'
                  ? avatar
                  : this.state.availableMessages[props.index].avatar_full
              }
              className="img-circle score-avatars"
            />
          </div>
          <div className="mensaje-category mensaje col-xs-11">
            <div className="row">
              <span className="nick-messagge">{splitedNick}:</span>
              <span className="date-messagge">{`${moment(
                this.state.availableMessages[props.index].created
              ).format('MM-DD hh:mm')}`}</span>
            </div>
            <div className="row">{`${this.state.availableMessages[props.index].message}`}</div>
          </div>
        </li>
      );
    }

    return (
      <CellMeasurer
        ref={(ref) => (this.cellM = ref)}
        cache={cache}
        columnIndex={0}
        key={props.key}
        parent={props.parent}
        rowIndex={props.index}
      >
        {({ measure }) => (
          <div
            className={`${props.index % 2 ? 'even-colored-row' : ''}`}
            key={props.key}
            style={props.style}
          >
            {content}
          </div>
        )}
      </CellMeasurer>
    );
  }

  render() {
    // console.logs('render', this);
    let conditionalComponent;
    if (this.state.loaded && this.state.userNick !== null) {
      let listMessages = [];
      // console.log('Props Mensajes',this.state.availableMessages.length);
      if (
        this.state.availableMessages !== undefined &&
        typeof this.state.availableMessages !== 'string' &&
        this.state.availableMessages !== null
      ) {
        listMessages = this.state.availableMessages;
        // console.log('Props Mensajes',listMessages);
      } else {
        listMessages = [];
      }
      console.log(this.props.gameType);
      conditionalComponent = (
        <div className="flex-row">
          <div className=" flex-col mensajes-list col-no-padding border-gradient">
            <div
              className="flex-row title-quiniela"
              style={{ borderBottom: 'transparent 1px solid' }}
            >
              <div className="title-wrapper">
                <img src={mensajes} className="pool-icons tablas" />
                <span>Chat</span>
              </div>
            </div>
            <div className="flex-row ">
              <div
                className={`flex-col col-no-padding overflow overflow-y
                ${this.props.gameType === 'group_pool' ? 'group' : 'NotGroup'}
                ${$(window).width() >= 669 ? '' : 'overflow-300'}`}
                style={this.setStyles()}
              >
                <div className="flex-row">
                  <div className="flex-col lista-mensajes">{this.rowRendererChat()}</div>
                </div>
              </div>
            </div>
            <div className="flex-row row-no-padding">
              <FormGroup className=" flex-col message-text">
                <FormControl
                  disabled={!this.props.withRegister && !utils.isSuPick()}
                  className="insertar-mensaje"
                  onChange={(e) => {
                    if (this.props.dispatch) {
                      groupPoolActions.handleMessage(this.props.dispatch, e);
                    } else {
                      this.handleMessage(e);
                    }
                  }}
                  onKeyPress={this.sendMessage}
                  placeholder="Aquí escribe tu mensaje"
                  // value={this.props.newMessage}
                  type="text"
                />
                <Button
                  className="btn-send-chat"
                  disabled={!this.props.withRegister && !utils.isSuPick()}
                  onClick={this.sendMessage}
                >
                  <span>Enviar</span>
                  <i className="ion-ios-paperplane" />
                </Button>
              </FormGroup>
            </div>
          </div>
        </div>
      );
    } else if (!this.state.userNick) {
      // console.log('No loggeado no se le muestra el chat', this);
      conditionalComponent = (
        <div className="flex-row">
          <div className="flex-col">
            <div
              className={`mensajes-list`}
              style={{ maxHeight: `${$('.first-section').height()}` }}
            >
              <div className="flex-row title-quiniela ">
                <div className="title-wrapper">
                  <img src={mensajes} className="pool-icons tablas" />
                  <span>Chat</span>
                </div>
              </div>
              <div className="flex-row " style={{ borderTop: '#424041 1px solid' }}>
                <div
                  className={`flex-col col-no-padding overflow overflow-y ${
                    $(window).width() >= 669 ? 'overflow-auto' : 'overflow-300'
                  }`}
                  style={
                    $(window).width() >= 669
                      ? {
                          height: `${$('.first-section').height() - 180}`,
                          maxHeight: `${$('.first-section').height() - 180}`,
                        }
                      : null
                  }
                >
                  <div className="flex-row">
                    <div className="flex-col lista-mensajes" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      conditionalComponent = <Loading label="Cargando mensajes..." />;
    }
    return conditionalComponent;
  }
}

export default ChatPanel;
