import React from 'react';

const ClockType = ({ type, countDown }) => {
  let conditionalClock = null;
  const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  };

  switch (type) {
    case 'row':
      conditionalClock = (
        <div className="closing-regular-countdown">
          <div className="countdown">
            <div className="countdown-container">
              <span
                style={countDown.hours <= 24 ? { color: '#ff0000' } : { color: '#E00034' }}
              >{`${addLeadingZeros(countDown.hours)} :`}</span>
              <span
                style={countDown.hours <= 24 ? { color: '#ff0000' } : { color: '#E00034' }}
              >{`${addLeadingZeros(countDown.min)} :`}</span>
              <span style={countDown.hours <= 24 ? { color: '#ff0000' } : { color: '#E00034' }}>
                {addLeadingZeros(parseInt(countDown.sec)) === NaN
                  ? 0
                  : `${addLeadingZeros(parseInt(countDown.sec))}`}
              </span>
              {/* <i className={'ion-android-alarm-clock hidden-xs '} style={ { 'marginLeft':'5px' } } /> */}
            </div>
          </div>
        </div>
      );
      break;
    case 'row-stopwatch':
      conditionalClock = (
        <div className="flex-col col-no-padding col-15 closing-regular-countdown">
          <div className="countdown">
            <div className="countdown-container">
              <span>{`${addLeadingZeros(countDown.min)} :`}</span>
              <span>
                {addLeadingZeros(parseInt(countDown.sec)) === NaN
                  ? 0
                  : `${addLeadingZeros(parseInt(countDown.sec))}`}
              </span>
            </div>
          </div>
        </div>
      );
      break;
    case 'title':
      conditionalClock = (
        <div className="closing-countdown">
          <div className="row countdown-title">
            <span>Empieza:</span>
          </div>
          <div className="row countdown">
            <div className="col-sm-3 time-cell">
              <div className="row title-cell">
                <span>{countDown.days === 1 ? 'Day' : 'Days'}</span>
              </div>
              <div className="row date-cell">
                <span>{addLeadingZeros(countDown.days)}</span>
              </div>
            </div>
            <div className="col-sm-3 time-cell">
              <div className="row title-cell">
                <span>Hours</span>
              </div>
              <div className="row date-cell">
                <span>{addLeadingZeros(countDown.hours)}</span>
              </div>
            </div>
            <div className="col-sm-3 time-cell">
              <div className="row title-cell">
                <span>Min</span>
              </div>
              <div className="row date-cell">
                <strong>{addLeadingZeros(countDown.min)}</strong>
              </div>
            </div>
            <div className="col-sm-3 time-cell">
              <div className="row title-cell">
                <span>Sec</span>
              </div>
              <div className="row date-cell ">
                <strong>{addLeadingZeros(countDown.sec)}</strong>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case 'pool':
      conditionalClock = (
        <div className="closing-pool-countdown">
          <div className="countdown">
            <div className="countdown-container">
              <strong
                style={countDown.hours <= 24 ? { color: '#ff0000' } : { color: '#fff000' }}
              >{`${addLeadingZeros(countDown.hours)} : `}</strong>
              <strong
                style={countDown.hours <= 24 ? { color: '#ff0000' } : { color: '#fff000' }}
              >{`${addLeadingZeros(countDown.min)} : `}</strong>
              <strong style={countDown.hours <= 24 ? { color: '#ff0000' } : { color: '#fff000' }}>
                {addLeadingZeros(countDown.sec) === NaN ? 0 : `${addLeadingZeros(countDown.sec)} `}
              </strong>
            </div>
          </div>
        </div>
      );
      break;
    case 'flip':
      conditionalClock = <div className="flipClock" style={{fontSize: '8px'}}/>;
      break;

    default:
      break;
  }
  // console.log(this.addLeadingZeros(countDown.hours), this.addLeadingZeros(countDown.min), this.addLeadingZeros(countDown.sec));
  return conditionalClock;
};

export default ClockType;
