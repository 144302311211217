
import React, { useState, useRef, useLayoutEffect } from 'react'
import { Box, Iconify, Stack, Typography } from '../../../Xmerald-UI'
import styled from 'styled-components'

// Components
import Ripple from '../../../Xmerald-UI/components/Ripple/Ripple'

// Hooks
import useClickOutside from '../../../hooks/useClickOutside'

CustomAwards.defaultProps = {
  // pool_prizes survivor_prices
  prizesList: [
    // {
    //   "position": 1,
    //   "type": "percentage",
    //   "percentage": 0
    // }
  ],
  onChange: () => {}
}

export default function CustomAwards ({ prizesList, onChange }) {
  const handlerChange = (item) => {
    onChange(
      prizesList.map(_item => _item.position === item.position ? item : _item)
    )
  }

  // Calcule total
  const restToDistribute = prizesList
    .reduce((prev, next) => ({ percentage: (prev.percentage || 0) + next.percentage }), { percentage: 0 })

  return (
    <Box>
      <Stack wrap direction='row' justify='center'>
        {prizesList.map((item, index) => (
          <Award
            item={item}
            key={item.position}
            onChange={handlerChange}
            restToDistribute={restToDistribute.percentage}
          />
        ))}
      </Stack>
    </Box>
  )
}

const Award = ({ item, restToDistribute, onChange }) => {
  const [open, setOpen] = useState(false)

  const itenRef = useRef()

  useClickOutside(itenRef, () => setOpen(false))

  // Here we added the rest plus the current value to show available percentages
  const options = Array(100 - Number(restToDistribute) + Number(item.percentage))
    .fill(0)
    .map((_, index) => ({ value: index + 1, label: `${index + 1} %` }))
    .reverse()

  const handlerChange = (percentage) => {
    setOpen(false)
    onChange({ ...item, percentage })
  }

  const isDefined = item.percentage > 0

  return (
    <AwardBox
      ref={itenRef}
      onClick={() => setOpen(true)}
      style={{
        borderColor: isDefined ? '#4b990e' : '#636363',
        color: isDefined ? '#ffffff' : '#a0a0a0'
      }}
    >
      <AwardContent style={{ background: isDefined ? 'linear-gradient(359deg, rgb(95 160 18 / 28%), transparent)' : 'transparent' }}>
        <Stack direction='row' justify='space-between'>
          <Typography size='1.8em' as='span' style={{ flex: 0 }}>{item.position}°</Typography>
          <Iconify icon='material-symbols:trophy-sharp' size='2em' />
          <PercentBox style={{ backgroundColor: isDefined ? '#5fa012' : '#60a01200' }}>
            {isDefined && (
              <Typography size='1em'>{item.percentage} %</Typography>
            )}
            <Iconify icon='eva:arrow-down-fill' size='1.2em' color='#FFF' />
          </PercentBox>
        </Stack>
        <Ripple color='#bbf15d' />
      </AwardContent>

      {open && (
        <OptionsPanel>
          {options.map((option, index) => (
            <OptionItem
              key={index}
              option={option}
              globalValue={item.percentage}
              onChange={handlerChange}
            />
          ))}
        </OptionsPanel>
      )}
    </AwardBox>
  )
}

const OptionItem = ({ option, globalValue, onChange }) => {
  const ref = useRef()

  const isSelected = option.value === globalValue

  useLayoutEffect(() => {
    // Scroll to element
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [ref, isSelected])

  return (
    <Option
      ref={ref}
      onMouseDown={() => onChange(option.value)}
      style={{
        background: isSelected ? 'linear-gradient(359deg, #5fa01270, transparent)' : 'transparent',
        border: isSelected ? '1px solid #78bc28' : 'initial'
      }}
    >
      {option.label}
      {isSelected && (
        <Iconify icon='vaadin:dot-circle' size='0.8em' ml={10} />
      )}
    </Option>
  )
}

Award.defaultProps = {
  value: 0,
  restToDistribute: 50
}

const AwardBox = styled.div`
  min-height: 65px;
  flex-basis: 140px;
  background-color: #111;
  border: 2px solid #3f3f3f;
  border-radius: 0.5em;
  position: relative;

  display: flex;
  align-items: center;
`

const AwardContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 0.5em;
`

const PercentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #5fa012;
  padding: 0.2em 0.5em;
  border-radius: 0.2em;
`

// -- options ---
const OptionsPanel = styled.div`
  height: 150px;
  width: 100%;
  background-color: #0c0c0c;
  text-align: center;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: none;
  border: 1px solid gray;
  padding: 1em 0;
  top: 100%;

  &::-webkit-scrollbar-thumb:hover {
    background-color: #005716;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`

const Option = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  margin: 0 1em;
  border-radius: 0.2em;
  margin-top: 2px;

  svg {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
  }
`
