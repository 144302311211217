import React from 'react';

import utils from '../../utils/utils';
import ReactTooltip from 'react-tooltip';
import { ShareButtons } from 'react-share';
import { Modal, Overlay, Button } from 'react-bootstrap/lib';
import firstBy from 'thenby';
import retar from '../../assets/images/vsIconHalf-50.png';
import ChallangeUserMail from '../ChallangeUserMail';

import {
  DisplaySport,
  DisplayFlag,
  DisplayTypes
} from '../../utils/quinielasComponents';

import numeral from 'numeral';
import * as Sentry from '@sentry/browser';
import $ from 'jquery';

import API from '../../utils/api';
import Clipboard from 'clipboard';

/**
 * Renders the Panel that shows the response of the invitation
 * @param  title  { String }
 * @param  showPanel  { Boolean }
 * @param  closePanel  { Function }
 * @param  responseStatus  { String }
 * @return Component
 */
const ResponseInvitation = ({ title, showPanel, closePanel, responseStatus }) => {
  return (
    <Modal
      bsSize="sm"
      backdropClassName="inviteResponseBackdrop"
      dialogClassName="inviteResponseDialog"
      onHide={closePanel}
      show={showPanel}
    >
      <div className="panel-abonar">
        <div id="panelTitle" className="panel-abonar-titulo">
          <span>
            <i className="ion-flag" />
            {title}
          </span>
          <button className="cerrar-panel" onClick={closePanel}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel">
          <div className="flex-col status-response">
            <div className="flex-row">
              <div className="flex-container align justify">
                {responseStatus ? (
                  <i
                    className="ion-checkmark-circled"
                    style={{ color: '#78b529', fontSize: '2em' }}
                  />
                ) : (
                    <i className="ion-close-circled" style={{ color: '#e00034', fontSize: '2em' }} />
                  )}
              </div>
            </div>
            <div className="flex-row">
              <div className="flex-container justify align">
                {responseStatus
                  ? 'Tu recordatorio se envío con éxito.'
                  : 'Tenemos un problema al enviar tu recordatorio.'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 *
 */
const RowSelectPool = ({ poolSelected, poolItem, selectPool, resetInviteData, type }) => {
  let format = null;
  let currency = null;

  let isEnough = null;
  let hasRegister = null;
  let userBalance = utils.getUserBalance();
  hasRegister = poolItem.entries.length > 0;
  // console.log( hasRegister )

  // console.log('row Selec ', userData, isEnough);
  isEnough = !poolItem.is_full && userBalance.balanceReal > poolItem.entry_fee;
  // console.log(userData.balanceReal);
  // console.log(poolItem.entry_fee);
  // console.log(isEnough);

  if (poolItem.entry_currency === 'real') {
    format = '$0,000';
    currency = 'MXN';
  } else if (poolItem.entry_currency === 'pickcoin') {
    format = '0,000';
    currency = 'PC';
  } else {
    format = '0,000';
    currency = 'Rf';
  }

  return (
    <label
      id={poolItem.id}
      className="flex-row"
      onClick={() => {
        if (resetInviteData) {
          resetInviteData();
        }
        selectPool(poolItem);
      }}
    >
      <div
        className={`flex-col list-pool-item ${
          poolSelected !== null && poolSelected.id === poolItem.id ? 'selected' : ''
          }
        ${type === 'challenge' && !isEnough ? 'disabled' : ''}
        `}
      >
        <div className="flex-row row-no-padding">
          <div className="flex-col">
            <div className="flex-row row-center row-no-padding ">
              <div className="flex-col col-70">
                <div className="flex-row row-center">
                  <DisplaySport id={poolItem.id} sports={poolItem.sport} type={'pool'} />
                  <DisplayFlag type="pool" flag={poolItem} />
                  <div style={poolItem.featured ? { color: '#FFF900' } : null}>
                    {poolItem.name_replaced}
                  </div>
                </div>
              </div>
              <div className="flex-col">
                <DisplayTypes id={poolItem} format={poolItem} />
              </div>
              {hasRegister ? (
                <div className="flex-col">
                  <div className="flex-row">
                    <div className="flex-container justify align column">
                      {$(window).width() < 669 ? 'Reg' : 'Registrado'}
                      <i className="ion-checkmark-circled"></i>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex-row row-no-padding">
          <div className="flex-col">
            <div className="flex-container align justify">Entrada:</div>
            <div className="flex-container align justify">
              {numeral(poolItem.entry_fee / 100).format(format)} {currency}
            </div>
          </div>
          <div className="flex-col">
            <div className="flex-container align justify">Registrados:</div>
            <div className="flex-container align justify">
              {poolItem.entry_count}
              {poolItem.pickngo ? `/${poolItem.max_entries}` : ''}
            </div>
          </div>
          <div className="flex-col">
            <div className="flex-container align justify">Bolsa:</div>
            <div
              className="flex-container align justify"
              style={poolItem.guaranteed_prize !== null ? { color: '#FFF900' } : null}
            >
              {numeral(poolItem.available / 100).format(format)} {currency}
            </div>
          </div>
        </div>
      </div>
    </label>
  );
};

/**
 * Renders the List of Pools
 * @param pools         { Object }
 * @param selectPool    { Function }
 * @param mounted       { Boolean }
 * @param poolSelected  { Object }
 * @return Component
 */
const ListPools = ({ resetInviteData, type, pools, selectPool, mounted, poolSelected }) => {
  if (mounted) {
    let listPools = pools.map((pool) => {
      return (
        <RowSelectPool
          type={type}
          key={pool.id}
          poolSelected={poolSelected}
          poolItem={pool}
          selectPool={selectPool}
          resetInviteData={resetInviteData}
        />
      );
    });

    console.log(listPools);
    return (
      <div className="flex-col">
        <div className={`flex-row ${poolSelected ? 'hide' : ''}`}>Elige una quiniela:</div>
        <div
          id="invitePools"
          className={`flex-col overflow-y ${!poolSelected ? 'overflow-300' : 'hide'} overflow`}
        >
          {listPools}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex-container justify align">
        <i className="ion-load-a loading " />
      </div>
    );
  }
};

const {
  FacebookShareButton,
  TwitterShareButton
} = ShareButtons;

let clipboardCode, clipboardReferal;

class PanelInvitaJugar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      showPanel: false,
      branchLink: null,
      pools: null,
      mounted: false,
      poolSelected: null,
      errorGettingPools: false,
      type: null,
    };

    this.registeredQuinielaInList = (poolId, entry) => {
      let tempPools = this.state.pools;

      tempPools.forEach((pool) => {
        if (poolId === pool.id) {
          pool.entries.push(entry);
          pool.entry_count += 1;
          pool.available += pool.entry_fee - pool.commission_fee;
        }
      });

      tempPools = tempPools.sort(
        firstBy('entries', -1)
          .thenBy('entry_currency', -1)
          .thenBy('featured', -1)
          .thenBy('sort_status', -1)
          .thenBy('deadline')
          .thenBy('available', -1)
      );
    };
    /**
     * This method Register the User and challenge to the user selected to a certain pool
     * @param user { Object } User Invited Data
     * @param href { String } direction of the invited pool
     *
     */
    this.challengeAndRegister = (user, href) => {
      this.setState({ submitting: true });
      let registerObject = {
        num_entries: 1,
        pool_id: this.state.poolSelected.id,
      };

      API.postSavePicks(registerObject)
        .then((responseRegister) => {
          try {
            utils.checkAxiosAllErrors([responseRegister]);
          } catch (e) {
            console.error(e);
          }

          this.props.sendChallange(user, href);

          this.setState(
            {
              submitting: false,
              registerError: false,
              registerAndInviteSuccess: true,
              registerResponse: responseRegister.data,
            },
            () => {
              this.registeredQuinielaInList(this.state.poolSelected.id, responseRegister.data);
              console.log('Saved picks');
              console.log(this.props.updateUserInfo);
              if (this.props.updateUserInfo) {
                this.props.updateUserInfo();
              }
            }
          );
        })
        .catch((e) => {
          console.error(e);
          let responseRegister;
          Sentry.captureException(e);
          this.setState({
            submitting: false,
            registerError: true,
            registerAndInviteSuccess: false,
            registerResponse: responseRegister,
          });
        });
    };

    this.selectPool = (poolInfo) => {
      console.log('SelectPool', poolInfo);
      API.fetchTickets(poolInfo.id, 'pool').then((responseTickets) => {
        console.log(responseTickets);
        let poolSelected = this.state.pools.find((pool) => pool.id === poolInfo.id);
        if (!poolSelected || poolSelected === -1) {
          return;
        }
        let isEnough = utils.getUserBalance().balanceReal > poolSelected.entry_fee;
        this.setState(
          {
            type: !poolInfo.max_entries ? null : 'challenge',
            ticketsForSelected: responseTickets.data,
            poolSelected,
            isEnoughToInvite: !isEnough && !responseTickets.data.tickets.length ? false : true,
            hasRegister: poolSelected.entries.length > 0,
          },
          () => {
            if (!this.state.type) {
              this.props
                .getBranchLinkChallenge(poolSelected, poolSelected.game_type)
                .then((url) => {
                  this.setState({
                    branchLink: url,
                  });
                });
            }
          }
        );
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.type === 'challenge' &&
      this.props.userData.balanceReal !== prevProps.userData.balanceReal
    ) {
      console.log('calculate Enough', utils.getUserInfo().balanceReal);
      let isEnough = utils.getUserInfo().balanceReal > this.state.poolSelected.entry_fee;
      this.setState({
        isEnoughToInvite: !isEnough && !this.state.ticketsForSelected.length ? false : true,
      });
    }

    if (!prevProps.showPanel && this.props.showPanel) {
      API.fetchPools()
        .then((response) => {
          console.log(response);
          if (!response || !response.data.now) {
            this.setState({
              pools: null,
              mounted: true,
              errorGettingPools: true,
              balanceReal: utils.getUserInfo().balanceReal,
            });
          } else {
            let filteredPools = response.data.pools.filter(
              (pool) =>
                (pool.status === 'upcoming' &&
                  pool.entry_currency === 'real' &&
                  pool.entries.length > 0) ||
                (pool.upcoming &&
                  pool.entry_currency === 'real' &&
                  !pool.is_full &&
                  pool.max_entries &&
                  pool.max_entries - pool.entry_count >= 2) ||
                (pool.upcoming &&
                  pool.entry_currency === 'real' &&
                  !pool.is_full &&
                  pool.limited_capacity &&
                  pool.max_entries - pool.entry_count >= 2)
            );

            // console.log(filteredPools.length)
            // filteredPools = filteredPools.filter( pool => {
            //   if(pool.upcoming && pool.entry_currency === 'real' && pool.max_entries ){
            //     let hasSpace = pool.max_entries - pool.entry_count > 2;
            //     if( hasSpace ){
            //       return pool
            //     }
            //   }
            // })
            console.log(filteredPools.length);

            filteredPools = filteredPools.sort(
              firstBy('entries', -1)
                .thenBy('entry_currency', -1)
                .thenBy('featured', -1)
                .thenBy('sort_status', -1)
                .thenBy('deadline')
                .thenBy('available', -1)
            );

            /**
             * Filter by type of Panel
             */
            if (this.state.type && this.state.type === 'challenge') {
              filteredPools = filteredPools.sort(
                firstBy('entries', -1)
                  .thenBy('entry_currency', -1)
                  .thenBy('featured', -1)
                  .thenBy('sort_status', -1)
                  .thenBy('deadline')
                  .thenBy('available', -1)
              );
              filteredPools = filteredPools.filter(
                (pool) => pool.max_entries || pool.entries.length > 0
              );
            } else {
              filteredPools = filteredPools.sort(
                firstBy('entries', -1)
                  .thenBy('entry_currency', -1)
                  .thenBy('featured', -1)
                  .thenBy('sort_status', -1)
                  .thenBy('deadline')
                  .thenBy('available', -1)
              );
            }
            // console.log(filteredPools);
            this.setState({
              pools: filteredPools,
              mounted: true,
            });
          }
          // console.log(response);
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error, { extra: 'Error inviting Play' });
        });
    }
  }

  render() {
    clipboardCode = new Clipboard('.copiar-btn');
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      $(wich).addClass('copied');
      e.clearSelection();
    });

    const userData = utils.getUserInfo();

    return (
      <Modal
        bsSize="sm"
        backdropClassName="inviteFriendsBackdrop"
        dialogClassName="inviteFriendsBackdrop modal-dialog-xxxs"
        onHide={() => {
          this.props.closePanel();
          this.setState({ poolSelected: null, branchLink: null });
        }}
        show={this.props.showPanel}
      >
        <div className="panel-abonar">
          <div id="panelTitle" className="panel-abonar-titulo">
            <span>
              <img className="btn-icon" src={retar} style={{ height: '15px' }} alt="" />
              {this.props.title}
              {this.props.type && this.props.type === 'challenge'
                ? !this.props.userInviteObject
                  ? 'Reta a jugar'
                  : this.props.userInviteObject.nick
                : !this.props.userInviteObject
                  ? 'tu amigo que juegue'
                  : this.props.userInviteObject.nick + ' que juegue'}
            </span>
            <button
              className="cerrar-panel"
              onClick={() => {
                this.props.closePanel();
                this.setState({ poolSelected: null, branchLink: null });
              }}
            >
              <div className="btn-icon-close">
                <i className="ion-ios-close-empty" />
              </div>
            </button>
          </div>
          <div className="panel-pickwin flex-col ">
            <div className="flex-row">
              <div className="flex-col  pickwin-panel">
                <ListPools
                  type={this.state.type}
                  resetInviteData={this.props.resetInviteData}
                  poolSelected={this.state.poolSelected}
                  pools={this.state.pools}
                  selectPool={this.selectPool}
                  mounted={this.state.mounted}
                />
              </div>
            </div>
            {!this.state.poolSelected ? null : !this.state.type ? (
              <div className="col-xs-12">
                <div
                  className={`detalles-privadas-compartir-container ${
                    this.props.responseStatus === true ? 'hide' : ''
                    }`}
                >
                  <div className="col-xs-12">
                    <div
                      className="text-center"
                      style={{ fontSize: '1.5em', fontWeight: 'bold', padding: '10px' }}
                    >
                      ¿Quién será el mejor jugando {this.state.poolSelected.name_replaced}?
                    </div>
                    <div className="flex-row row-center flex-container justify">
                      <div className="codigo-amigo flex-col">
                        <input
                          id="copiar-clipboard"
                          style={{ fontSize: '1.1em' }}
                          value={
                            this.state.branchLink !== undefined
                              ? this.state.branchLink
                              : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                          }
                        />
                      </div>
                      <div className="copiar-button-codigo flex-col col-15">
                        <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                          Copia tú código y compártelo
                        </ReactTooltip>
                        <button
                          data-tip
                          data-for="copiar-tool"
                          className="copiar-btn"
                          data-clipboard-target="#copiar-clipboard"
                        >
                          <i className="ion-ios-copy " />
                          <span className="visible-md">Copiar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="btn-group inviteToPlay flex-row">
                    <FacebookShareButton
                      quote={`¿Ya viste esta quiniela? Yo estoy jugando, éntrale ¡tiene buena bolsa!`}
                      hashtag={'#Pickwin #registrate #JalaTusPicks'}
                      url={
                        this.state.branchLink !== undefined
                          ? this.state.branchLink
                          : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                      }
                    >
                      <Button className="fb-btn">
                        Por Facebook <i className="ion-social-facebook" />
                      </Button>
                    </FacebookShareButton>
                    <TwitterShareButton
                      title={`¿Ya viste esta quiniela? Yo estoy jugando, éntrale ¡tiene buena bolsa!.`}
                      url={
                        this.state.branchLink !== undefined
                          ? this.state.branchLink
                          : `https://www.pickwin.mx/?referer=${userData.referalCode}`
                      }
                      hasthag={'#Pickwin #registrate #JalaTusPicks'}
                    >
                      <Button className="twitter-btn">
                        {' '}
                        Por Twitter <i className="ion-social-twitter" />
                      </Button>
                    </TwitterShareButton>
                    <div className="SocialMediaShareButton">
                      <Button
                        className="mail-btn"
                        onClick={() => {
                          this.props.sendChallange(
                            this.props.userInviteObject.id,
                            this.state.poolSelected
                          );
                        }}
                      >
                        Por Mail <i className="ion-email" />
                      </Button>
                    </div>
                    <div className="SocialMediaShareButton">
                      <Button
                        className="whatsapp-btn"
                        onClick={() => {
                          this.props.whatsAppInvitationChallenge(
                            this.state.branchLink,
                            this.state.poolSelected
                          );
                        }}
                      >
                        Por WhatsApp <i className="ion-social-whatsapp" />
                      </Button>
                    </div>
                  </div>
                </div>
                <ChallangeUserMail
                  showPanel={this.props.showMailInvite}
                  hidePanel={this.props.hidePanel}
                />
                <ResponseInvitation
                  title={'Recordatorio para jugar'}
                  showPanel={this.props.showInvitationResponse}
                  closePanel={this.props.hideInvitationResponse}
                  responseStatus={this.props.responseStatus}
                />
                <div className="btn-wrap">
                  <Button
                    className="btn-rojo gracias"
                    onClick={() => {
                      this.props.closePanel();
                      this.setState({ poolSelected: null, branchLink: null });
                    }}
                  >
                    No, gracias
                  </Button>
                </div>
              </div>
            ) : (
                <div className="flex-row row-no-padding row-center">
                  <div className="flex-col col-center ">
                    <div
                      className={`flex-row row-center row-no-padding  detalles-privadas-compartir-container ${
                        this.props.responseStatus === true ? 'hide' : ''
                        }`}
                    >
                      <div className="flex-col col-center">
                        {this.state.isEnoughToInvite || this.state.poolSelected.entries.length > 0 ? (
                          <div
                            className="text-center "
                            style={{ fontSize: '1.5em', fontWeight: 'bold', padding: '10px' }}
                          >
                            ¿Quién será el mejor jugando {this.state.poolSelected.name_replaced}?
                          </div>
                        ) : (
                            <div
                              className="text-center"
                              style={{ fontSize: '1.3em', fontWeight: 'bold', padding: '10px' }}
                            >
                              No tienes saldo suficiente para jugar esta quiniela.
                            </div>
                          )}
                        {this.state.isEnoughToInvite && !this.state.hasRegister ? (
                          <div className="text-center " style={{ padding: '10px' }}>
                            Te vas a registrar a esta quiniela despúes de retar a{' '}
                            <strong>
                              {this.props.userInviteObject
                                ? this.props.userInviteObject.nick
                                : 'amigo'}
                            </strong>
                          </div>
                        ) : null}
                        <div className="flex-row row-center row-no-padding">
                          <div
                            className={`flex-col col-center col-50 col-offset-25 ${
                              this.state.responseStatus === true ? 'hide' : ''
                              }`}
                          >
                            <button
                              data-tip
                              data-for="notification"
                              onClick={() => {
                                let gameType =
                                  this.state.poolSelected.game_type === 'pool'
                                    ? 'quiniela'
                                    : this.state.poolSelected.game_type;
                                if (
                                  !this.state.isEnoughToInvite &&
                                  this.state.poolSelected.entries.length === 0
                                ) {
                                  console.log('Abonar saldo popup');
                                  this.props.openAbonar();
                                } else {
                                  let href = `/${gameType}/${this.state.poolSelected.slug}/${this.state.poolSelected.id}`;
                                  console.log('Challange ' + this.props.userInviteObject.nick, href);
                                  if (this.state.poolSelected.entries.length > 0) {
                                    this.props.sendChallange(
                                      this.props.userInviteObject.id,
                                      this.state.poolSelected
                                    );
                                  } else {
                                    this.challengeAndRegister(
                                      this.props.userInviteObject.id,
                                      this.state.poolSelected
                                    );
                                  }
                                }
                              }}
                              className="btn-challenge challenge row-no-padding challenge  callToAction flex-row row-center"
                            >
                              {this.state.isEnoughToInvite ||
                                this.state.poolSelected.entries.length > 0 ? (
                                  <div className="flex-col col-center">
                                    Reta a{' '}
                                    {this.props.userInviteObject
                                      ? this.props.userInviteObject.nick
                                      : 'amigo'}
                                    <img src={retar} className="btn-icon" alt="" />
                                  </div>
                                ) : (
                                  <div className="flex-col col-center">
                                    Abonar Saldo
                                    <i className="ion-cash" style={{ marginLeft: '3px' }}></i>
                                  </div>
                                )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex-row row-center row-no-padding ${
                        this.props.responseStatus === true ? 'hide' : ''
                        }`}
                    >
                      <div className="flex-col col-center col-50 col-offset-25">
                        <Button
                          className=" flex-row row-center row-no-padding challenge  btn-gracias"
                          onClick={() => {
                            this.props.closePanel();
                            this.setState({ poolSelected: null, branchLink: null });
                          }}
                        >
                          <div className="flex-col col-center text-center">No, gracias</div>
                        </Button>
                      </div>
                    </div>
                    {!this.props.responseStatus ? null : this.props.responseStatus === true ? (
                      <div className=" challenge-success flex-container column justify align">
                        <i className="ion-checkmark-round success-challenge"></i>
                        <strong style={{ marginBottom: '10px' }}>
                          Tu registro y tu reto se enviaron exitosamente
                      </strong>
                        <Button
                          href={`/quiniela/${this.state.poolSelected.slug}/${
                            this.state.poolSelected.id
                            }/${
                            this.state.registerResponse
                              ? this.state.registerResponse.entryId
                              : this.state.poolSelected.entries[0].id
                            }`}
                          className="flex-col col-50 btn-challenge challenge callToAction"
                        >
                          Ir a La Quiniela
                      </Button>
                      </div>
                    ) : (
                        <div className="challenge-success flex-container column justify align">
                          <i className="ion-close-round error-challenge"></i>
                          <strong>
                            Tu reto y/o registro no se pudrieron enviar, inténtalo nuevamente.
                      </strong>
                        </div>
                      )}
                    <div></div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default PanelInvitaJugar;
