const errorsOpenPay = {
  tokenErrors: {
    1001: 'El código de verificaión debe de ser de 3 digitos.',
    2001: 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.',
    2002: 'La tarjeta con este número ya se encuentra registrada en el cliente.',
    2003: 'El cliente con este identificador externo (External ID) ya existe.',
    2004: 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.',
    2005: 'La fecha de vencimiento de la tarjeta es anterior a la fecha actual.',
    2006: 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.',
    2007: 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.',
    2008: 'La tarjeta no es valida para puntos Santander.',
    2009: 'El código de seguridad de la tarjeta (CVV2) es inválido.',
    1018: 'El número de intentos ha sido excedido',
    3001: 'La tarjeta fue declinada.',
    3002: 'La tarjeta ha expirado.',
    3003: 'La tarjeta no tiene fondos suficientes.',
    3004: 'La tarjeta ha sido identificada como una tarjeta robada.',
    3005: 'La tarjeta ha sido rechazada por el sistema antifraudes.',
    3006: 'La operación no esta permitida para este cliente o esta transacción.',
    3007: 'La tarjeta fue declinada.',
    3008: 'La tarjeta no es soportada en transacciones en línea.',
    3009: 'La tarjeta fue reportada como perdida.',
    3010: 'El banco ha restringido la tarjeta.',
    3011: 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.',
    3012: 'Se requiere solicitar al banco autorización para realizar este pago.',
  },
  requestErrors : {
    
  }
};

export default errorsOpenPay;