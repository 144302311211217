import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

// Slices
import { quinielaSlice } from './quiniela.store'
import { survivorSlice } from './survivor.store'

export const useWizardPrivate = create(
  devtools((...data) => ({
    showWizard: false,
    ...quinielaSlice(...data),
    ...survivorSlice(...data)
  }))
)
