

import styled from 'styled-components'
import React from 'react'
import { useQuery } from '@tanstack/react-query'

// Components
import { TextGradient } from '../components/TextGradient'

// Utils
import API from '../../../../../../utils/api'
import { capitalize } from '../../../../../../utils/text-utils'
import { getCurrencyFormatSmall } from '../../../../../../utils/currencyFormat'

// Assets
import level1 from '../assets/level-1.png'
import level2 from '../assets/level-2.png'
import level3 from '../assets/level-3.png'
import level4 from '../assets/level-4.png'
import level5 from '../assets/level-5.png'
import level6 from '../assets/level-6.png'
import level7 from '../assets/level-7.png'
import level8 from '../assets/level-8.png'
import level9 from '../assets/level-9.png'
import level10 from '../assets/level-10.png'
import iconPickwin from '../assets/icon-pickwin.png'


const levelImages = {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10
}


const useVipRewards = () => {
  const vipReferralRewards = useQuery(['vip_referral_rewards'], API.getVipReferralRewards)
  const levels = useQuery(['levels'], API.getLevelBenefitList)

  return {
    isFetching: vipReferralRewards.isFetching || levels.isFetching,
    vipReferralRewards: vipReferralRewards?.data?.data?.referredUsers || []
  }
}

export default function VipReferralRewards () {
  const { vipReferralRewards } = useVipRewards()

  return (
    <Box>
      <TableHead>
        <User><span>Invitado (usuario)</span></User>
        <LevelHeader>
          <span>Nivel Programa de Lealtad</span>
          <LevelRow>
            <LeveItem>Mes 1</LeveItem>
            <LeveItem>Mes 2</LeveItem>
            <LeveItem>Mes 3</LeveItem>
          </LevelRow>
        </LevelHeader>
        <Total><span>Total</span></Total>
      </TableHead>
      <TableBody>
        {vipReferralRewards.map((referral) => {
          const avatarSource = referral?.avatar_dir
            ? `${referral?.avatar_dir}${referral?.avatar}`
            : iconPickwin

          const currentProgress = ((referral.user_points - referral.level.min_points)/ (referral.level.max_points - referral.level.min_points)) * 100
  
          return (
            <BodyRow key={referral.id}>
              <User>
                <UserInfo>
                  <Avatar src={avatarSource} />
                  <UserName>
                    <span>{referral?.nick}</span>
                    <LevelName>{referral?.level?.name}</LevelName>
                  </UserName>
                </UserInfo>
                <BarBox>
                  <Bar>
                    <ProgressBar style={{ width: `${currentProgress}%`}}/>
                  </Bar>
                  <span>{currentProgress}%</span>
                </BarBox>
                
              </User>
              <LevelTable>
                {referral?.rewards?.map((item, index) => (
                  <LevelInfo key={index}>
                    <img
                      src={levelImages[item?.data_values?.level_id]}
                      alt='Imagen de nivel'
                    />
                    <span>{item?.data_values?.level_name !== 'MVP' ? capitalize(item?.data_values?.level_name) : item?.data_values?.level_name}</span>
                  </LevelInfo>
                ))}
              </LevelTable>
              <Total>
                <TextGradient style={{ fontSize: 16 }}>{getCurrencyFormatSmall(referral?.rewards_total)}</TextGradient>
              </Total>
            </BodyRow>
          )
        })}
      </TableBody>
    </Box>
  )
}

const BarBox = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  
  span {
    font-size: 0.7em;
    color: #f1e2a2;
    margin-bottom: 2px;
  }
`

const UserName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.2em;
`

const LevelName = styled.div`
  font-size: 0.45em;
  line-height: 1.7;
`

const LevelInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3em;

  & img {
    max-width: 2.7em;
    width: 30%;
  }

  & span {
    font-size: 0.6em !important;
    color: #AAA;
  }
`

const Box = styled.div`
  margin: 1em 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
`

const LevelRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 1em;

  &:first {
    margin-top: 2em;
  }
`

const LeveItem = styled.div`
  flex-basis: 33%;
  font-size: 0.7em;
  color: gray;
  text-align: center;
`

const TableHead = styled.div`
  height: 3.5em;
  width: 100%;
  display: flex;
  gap: 0.4em;

  & div {
    border-radius: 0.5em 0.5em 0 0;
  }
`

const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const BodyRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 0.4em;
  height: 4.7em;

  & div {
    border-top: 0;
  }
`

const Cell = styled.div`
  border: 1px solid #4e4e4e;
  height: 100%;
  background-color: #1A1A1A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4em;

  span {
    font-weight: 700;
    font-size: 0.75em;
  }
`

const UserInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3em;
  width: 100px;
`

const Bar = styled.div`
  position: relative;
  width: 100px;
  height: 0.5em;
  border-radius: 0.3em;
  overflow: hidden;
  background-color: #2a2a2a;
  border: 1px solid #8080802e;
`

const ProgressBar = styled.div`
  width: 25%;
  height: 100%;
  background-color: #78bc28;
  left: 0;
  position: absolute;
`

const User = styled(Cell)`
  flex-basis: 180px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Avatar = styled.img`
  background-color: lime;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  overflow: hidden;
`

const LevelHeader = styled(Cell)`
  flex-basis: 200px;
  flex-grow: 1;
  display: flex;
  width: 100%;
`

const LevelTable = styled(Cell)`
  flex-basis: 200px;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  color: #AAAAAA !important;
`

const Total = styled(Cell)`
  flex-basis: 110px;
`

