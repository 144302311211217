import styled from 'styled-components'

const Text = styled.div`
  font-size: 1em;
  line-height: 1.5;
  color: ${props => {
    switch (props.type) {
      case 'error': return 'red'
      case 'success': return '#88ba44'
      default: return '#FFF'
    }
  }};
`

export default Text
