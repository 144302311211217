//Libraries
import React from 'react';
import gsap from 'gsap'

//Css
import './imageTransitions.scss';


const ImageTransitions = ({ options, id }) =>  {

  const venetianBlinds = async (defaults) => {
    var link = document.createElement('a');
    link.href = defaults.url;
    link.style.width = "100%";
    link.style.height = "100%";
    link.style.position = "absolute";
    link.style.zIndex = "2";
    if (defaults.external) {
      link.target = '_blank';
    }

    var cols = defaults.columns;
    var margin = defaults.margin;
    var img = defaults.imgSrc;
    var placeholder = document.getElementsByClassName(defaults.containerName)[0];
    if (placeholder !== undefined) {
      placeholder.innerHTML = '';
      placeholder.append(link);
    }
    var directionX;

    var column, blind, blindImg;
    var bgImg;
    var colL;

    if (placeholder !== undefined) {
      var colW = (placeholder.offsetWidth / cols) - margin;
    }

    for (var i=0; i < cols; i++) {
      colL = ((colW + margin) * i);
      column = document.createElement('div');
      column.className = "column";
      column.style.width = colW + "px";
      column.style.left = colL + "px";
      if (placeholder !== undefined) {
        placeholder.appendChild(column);
      }

      for (var j=0; j<1; j++) {
        blind = document.createElement('div');
        blind.className = "blind";
        blind.style.width = colW + "px";
        blindImg = document.createElement('div');
        blindImg.className = "blindImg";

        switch (j) {
          case 0:
            gsap.set(blind, {rotationY: "90.1"});
            bgImg = img;
            break;
        }

        if (placeholder !== undefined)
          blindImg.style.width = placeholder.offsetWidth + "px";

        blindImg.style.backgroundImage = "url("+bgImg+")";
        blindImg.style.left = -colL + "px";

        column.appendChild(blind);
        blind.appendChild(blindImg);

        gsap.set(blind, { transformOrigin:"50% 50% " + -colW/2, transformStyle: "preserve-3d"});
      }

      gsap.set(column, { transformStyle:"preserve-3d", transformPerspective:1000, rotationY: 0 });

      var elem = column;

      var rotY = gsap.getProperty(elem, "rotationY");
      if (directionX > 0) {
        gsap.to(elem, { duration: 1, rotationY: Math.floor(rotY/90)*90+90.1, transformOrigin:"50% 50% -" + colW/2, ease: {_p1: '1.70', _p2: '2.59'}});
      } else {
        gsap.to(elem, { duration: 1, rotationY: Math.floor(rotY/90)*90-90.1, transformOrigin:"50% 50% -" + colW/2, ease: {_p1: '1.70', _p2: '2.59'}});
      }
      await delay(100);
    }
  }

  const delay = (ms) => {
    return new Promise(done => setTimeout(done, ms));
  }

  const billboard = (options) => {
    return new Promise(done => {
      venetianBlinds(options);
      setTimeout(done, options.interval);
    });
  }

  const showBillboards = async(options) => {
    for (var i = 0; i < options.length; i++) {
      await billboard(options[i]);
    }
  }

  React.useEffect(() => {
    // get global time to interval repeat
    let globalInterval = 0;
    options.map((itemOptions) => {
      globalInterval += itemOptions.interval;
    });

    let interval;
    setTimeout(() => {
      showBillboards(options);
      if (globalInterval !== 0) {
        interval = setInterval(() => {
          showBillboards(options);
        }, globalInterval);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
      new Promise(done => { return done(); });
    }
  }, []);

  return (
    <div className={`billboard-image-${id}`}></div>
  );

}

export default ImageTransitions;