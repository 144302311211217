import React from 'react';
import propTypes from 'prop-types';

import moment from 'moment';

import { LiveBadge } from './Picks';

import utils from '../../utils/utils';

import _ from 'underscore';

/**
 * This component handles the View and thefunctionallity of the clicked pick
 * @param type              { String }
 * @param fixtureProp       { Object }
 * @param handlePick        { Function }
 * @param handleRemovePick  { Function }
 * @param isDisabled        { Boolean }
 * @param selectedValue     { Object }
 * @return Component
 */
const PickProp = ({
  breaking,
  entryId,
  type,
  fixtureProp,
  handlePick,
  handleRemovePick,
  isDisabled,
  selectedValue,
  maxPicks,
}) => {
  let isSelected = false;

  // console.log(fixtureProp)

  if (
    selectedValue !== undefined &&
    selectedValue !== -1 &&
    selectedValue.prop_id === fixtureProp.id
  ) {
    //  console.log('selectedValue', selectedValue)
    isSelected = selectedValue.pick === type;
  }
  let correctClass = null;
  if (type === 'under') {
    if ( selectedValue && selectedValue.pick === 'under' ) {
      if (fixtureProp.fixture.suspended !== 1 && fixtureProp.fixture.sort_status !== 1 && fixtureProp.winning_pick !== 'tie' && selectedValue.pick === fixtureProp.winning_pick ) {
        correctClass = 'gano';
      }
      if (fixtureProp.fixture.suspended !== 1 && fixtureProp.fixture.sort_status !== 1 && fixtureProp.winning_pick !== 'tie' && selectedValue.pick !== fixtureProp.winning_pick ) {
        correctClass = 'perdio';
      }
    }

    return (
      <label
        className={`prop-category ${type === 'home' ? 'local' : 'visitante'} ${isSelected ? 'selected' : ''} ${correctClass}`}
        value={type}
        htmlFor={`propPartido-${entryId}-${fixtureProp.id}-${type}`}
        style={{ position: 'relative' }}
      // onClick={() =>{ handlePick('pick_prop', fixtureProp.id, type )}}
      >
        <li className="li-prop">
          <div className="radio-container">
            <input
              id={`propPartido-${entryId}-${fixtureProp.id}-${type}`}
              disabled={isDisabled}
              name={`propPartido-${fixtureProp.id}`}
              type="radio"
              value={type}
              checked={isSelected}
              onChange={() => handlePick('pick_prop', fixtureProp.id, type)}
              onClick={() => handleRemovePick('pick_prop', fixtureProp.id, type)}
            />
            <div className={`radio-check ${isDisabled ? 'disabled' : ''}`}></div>
          </div>
          <div style={{ display: 'contents' }}>
            {fixtureProp.boolean ? 'Si' : 'Menos de '} &nbsp; {breaking}
          </div>
        </li>
        {isSelected && fixtureProp.enabled && maxPicks !== null && (
          <i style={{ right: '-5px' }} className="ion-close-circled delete-pick"></i>
        )}
      </label>
    );
  } else if (type === 'over') {
    if ( selectedValue && selectedValue.pick === 'over' ) {
      if (fixtureProp.fixture.suspended !== 1 && fixtureProp.fixture.sort_status !== 1 && fixtureProp.winning_pick !== 'tie' && selectedValue.pick === fixtureProp.winning_pick ) {
        correctClass = 'gano';
      }
      if (fixtureProp.fixture.suspended !== 1 && fixtureProp.fixture.sort_status !== 1 && fixtureProp.winning_pick !== 'tie' && selectedValue.pick !== fixtureProp.winning_pick ) {
        correctClass = 'perdio';
      }
    }

    return (
      <label
        className={`prop-category  ${isSelected ? 'selected' : ''} ${correctClass}`}
        value={type}
        htmlFor={`propPartido-${entryId}-${fixtureProp.id}-${type}`}
        style={{ position: 'relative' }}
      // onClick={() =>{ handlePick('pick_prop', fixtureProp.id, type )}}
      >
        <li className="li-prop" style={{ justifyContent: 'flex-end', paddingRight: '1rem' }}>
          <div style={{ display: 'contents' }}>
            {fixtureProp.boolean ? 'No' : 'Más de '} &nbsp; {breaking}
          </div>
          <div className="radio-container">
            <input
              id={`propPartido-${entryId}-${fixtureProp.id}-${type}`}
              disabled={isDisabled}
              name={`propPartido-${fixtureProp.id}`}
              type="radio"
              value={type}
              checked={isSelected}
              onChange={() => handlePick('pick_prop', fixtureProp.id, type)}
              onClick={() => handleRemovePick('pick_prop', fixtureProp.id, type)}
            />
            <div className={`radio-check ${isDisabled ? 'disabled' : ''}`}></div>
          </div>
        </li>
        {isSelected && fixtureProp.enabled && maxPicks !== null && (
          <i style={{ left: '-5px' }} className="ion-close-circled delete-pick"></i>
        )}
      </label>
    );
  }
};

/**
 * This component renders the list of picks and props
 * @param fixtureProps            { Object }
 * @param handlePick              { Function }
 * @param handleRemovePick        { Function }
 * @param poolInfo                { Object }
 * @param updateStartedFixture    { Function }
 * @param myEntry                 { Object }
 */
const ListPicksProps = ({
  fixtures,
  fixtureProps,
  handlePick,
  handleRemovePick,
  poolInfo,
  updateStartedFixture,
  myEntry,
}) => {
  if (!fixtureProps || !myEntry) {
    return null;
  }

  let listTypes = [...new Set(fixtureProps.map((e) => e.name))];

  const getValue = (type, property) => {
    return fixtureProps.some((e) => e.name === type)
      ? fixtureProps.find((r) => r.name === type)[property]
      : '';
  };

  let groupTypesProps = listTypes.map((e) => {
    return {
      type: getValue(e, 'type'),
      name: getValue(e, 'name'),
      props: fixtureProps.filter((r) => r.name === e),
    };
  });

  const getPropsByGroup = (fixtprop) => {
    // console.log(fixtprop)
    // let propTitle = (
    //   <div key={fixtprop.id} className='titleProps row'>
    //   {` ${fixtprop.name} - [ ${fixtprop.fixture.home_team.abbreviation} VS ${fixtprop.fixture.away_team.abbreviation} ] ` }
    //   </div>
    // );

    let selectedValue = _.find(myEntry.picks_props, (myPick) => {
      if (
        myPick.fixture_id === fixtprop.fixture_id &&
        myPick.prop_id === fixtprop.id &&
        myPick.pick !== null
      ) {
        // console.log(myPick)
        return myPick;
      }
    });

    // console.log( 'selectedValue' );
    // console.log( selectedValue );

    let isDisabled = !fixtprop.enabled || (utils.isLogged() && utils.isSuPick());

    let closingDate = moment(fixtprop.fixture.deadline);
    let nowDate = moment();
    let itPassedSec = closingDate.diff(nowDate, 'seconds');

    let format24h = false;
    let format1h = false;
    // let itStarted = fixtprop.fixture.sort_status === 0;
    let isLive = fixtprop.fixture.sort_status === 0;

    if (itPassedSec < 3600) {
      format1h = true;
    }
    if (itPassedSec < 86400) {
      format24h = true;
    }
    if (itPassedSec < 0) {
      // console.log(itPassed)
      // this.props.updateStartedFixture(fixture.id);
      if (!fixtprop.fixture.finished) {
        isLive = true;
      }
    }

    const getLogo = (idFixture, isHome) => {
      const fixture = fixtures.find((e) => e.id === idFixture); //

      if (!fixture) return <React.Fragment></React.Fragment>;

      if (isHome) {
        return <i className={`${fixture.home_team.logo} home`}></i>;
      } else {
        return <i className={`${fixture.away_team.logo} away`}></i>;
      }
    };

    const getNameTeam = (idFixture, isHome) => {
      const fixture = fixtures.find((e) => e.id === idFixture); //

      if (!fixture) return '';

      if (isHome) {
        return fixture.home_team.name;
      } else {
        return fixture.away_team.name;
      }
    };

    return (
      <div key={fixtprop.id} className="props-row flex-container justify align column">
        {fixtprop.fixture && (
          <div className="flex-row row-no-padding no-margin-top">
            <LiveBadge fixture={fixtprop.fixture} />
          </div>
        )}

        <div className={'props-data flex-row row-no-padding no-margin-top'}>
          <label>
            <li className={'li-prop'}>
              {' '}
              {getLogo(fixtprop.fixture_id, true)} {getNameTeam(fixtprop.fixture_id, true)}{' '}
            </li>
          </label>
          <label>
            <li className={'li-prop'}>
              {' '}
              <b>VS</b>{' '}
            </li>
          </label>
          <label>
            <li className={'li-prop'}>
              {' '}
              {getNameTeam(fixtprop.fixture_id, false)} {getLogo(fixtprop.fixture_id, false)}{' '}
            </li>
          </label>
        </div>
        <div className={'props-data flex-row row-no-padding no-margin-top'}>
          <PickProp
            entryId={myEntry.entry_id}
            fixtureProp={fixtprop}
            type={'under'}
            isDisabled={isDisabled}
            selectedValue={selectedValue}
            handlePick={handlePick}
            handleRemovePick={handleRemovePick}
            maxPicks={poolInfo.max_picks}
            breaking={
              fixtprop.boolean ? null : (
                <span className="prop-value"> {!fixtprop.value ? 'PD' : fixtprop.value}</span>
              )
            }
          />
          <PickProp
            entryId={myEntry.entry_id}
            fixtureProp={fixtprop}
            type={'over'}
            isDisabled={isDisabled}
            selectedValue={selectedValue}
            handlePick={handlePick}
            handleRemovePick={handleRemovePick}
            maxPicks={poolInfo.max_picks}
            breaking={
              fixtprop.boolean ? null : (
                <span className="prop-value"> {!fixtprop.value ? 'PD' : fixtprop.value}</span>
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="props-list">
      <form className="1">
        <div className="form-group">
          {groupTypesProps.map((group, index) => {
            return (
              <React.Fragment key={index}>
                <div key={index + 'bs'} className="titleProps row">
                  {group.name}
                </div>
                <ul className="lista-Props">
                  {group.props.map((prop) => {
                    let ss = prop;
                    return getPropsByGroup(prop);
                  })}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
      </form>
    </div>
  );
};

class PicksProps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.fixtureProps || !this.props.fixtureProps.length) {
      return null;
    }

    return (
      <div className="">
        <div className="flex-row title-quiniela sub">
          <span>PROPS</span>
        </div>
        <ListPicksProps
          fixtures={this.props.fixtures}
          fixtureProps={this.props.fixtureProps}
          poolInfo={this.props.poolInfo.pool}
          handlePick={this.props.handlePick}
          handleRemovePick={this.props.handleRemovePick}
          updateStartedFixture={this.props.updateStartedFixture}
          myEntry={this.props.myEntry}
        />
      </div>
    );
  }
}

PicksProps.propTypes = {
  fixtureProps: propTypes.array.isRequired,
  myEntry: propTypes.object,
  handlePick: propTypes.func.isRequired,
  handleRemovePick: propTypes.func.isRequired,
};

export default PicksProps;
