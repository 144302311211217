import React from 'react';

import utils from '../../utils/utils';
import avatar from '../../assets/images/avatar-50.png';

class MisPicks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.withRegister) {
      return null;
    }
    // console.log(this.props)
    let dataList = this.props.survivorWeeks.map((week) => {
      let currentPick = this.props?.myEntry?.survivor_picks ? this.props?.myEntry?.survivor_picks[week.id] : null;
      let isThisWeek = week.id === this.props.currentWeek.id;

      if (!currentPick) {
        // console.log(this.props.currentWeek, this.props.myEntry, isThisWeek);
        return (
          <div key={week.id} className="item-container">
            <div className="img-container">
              {isThisWeek && this.props.myEntry.survivor_standing.alive ? (
                <div className="shirt-picks withoutPick">
                  <i className="ion-close" style={{ color: 'rgb(244,0,52)' }}></i>
                </div>
              ) : this.props.myEntry.survivor_standing.elimination_week_id < week.id ? null : (
                <div className="shirt-picks withoutPick underline">
                  <i className="ion-close" style={{ color: 'rgb(244, 0, 52)' }}></i>
                  {/*<span>N/D</span>*/}
                </div>
              )}
            </div>
          </div>
        );
      } else {
        currentPick['eliminated_this_week'] =
          this.props.myEntry.survivor_standing.elimination_week_id === week.id;

        let teamSelected = this.props.survivorTeams.find((team) => team.id === currentPick.pick);
        let correspondingFixture = this.props.survivorFixtures.find(
          (fixture) => fixture.id === currentPick.fixture_id
        );
        // console.log('weekId', week.id);
        // console.log('survivor Fixtures');
        // console.log(this.props.currentWeek.id);
        // console.log('currentPick ');
        // console.log(currentPick);
        // // console.log('correspondingFixture');
        // // console.log(correspondingFixture);
        // console.log('This Week');
        // console.log(week.id , this.props.currentWeek.id);

        if (week.id <= this.props.currentWeek.id) {
          // console.log('this or previous pick')

          if (teamSelected === -1 || teamSelected === undefined) {
            // console.log('Not Team Selected');
            return (
              <div key={week.id} className="item-container">
                <div className="img-container">
                  <i className="ion-close" style={{ color: 'rgb(244,0,52)' }}></i>
                </div>
              </div>
            );
          } else {
            // console.log('This pick isnt this week' , correspondingFixture , currentPick );
            if (correspondingFixture === -1 || correspondingFixture === undefined) {
              let pickStatus = '';
              if (currentPick.strike) {
                if (currentPick.eliminated_this_week) {
                  pickStatus = 'strike underline';
                } else {
                  pickStatus = 'strike';
                }
              } else if (currentPick.correct) {
                pickStatus = 'gano';
              } else if (!currentPick.correct) {
                pickStatus = 'perdio';
              }
              // console.log( 'PickStatus',pickStatus , currentPick);
              return (
                <div key={week.id} className="item-container">
                  <div className="img-container">
                    <div className={`shirt-picks ${pickStatus}`}>
                      <i className={` home helmet ${teamSelected.logo}`}></i>
                      <small className="team-name">
                        {teamSelected.abbreviation}
                        {this.props.pointFormat === 'line' ? (
                          <span className="linea">{currentPick.advantage}</span>
                        ) : null}
                      </small>
                    </div>
                  </div>
                </div>
              );
            } else {
              // console.log('This week fixture');
              // console.log('correspondingFixt', correspondingFixture );
              /**
               * Live Pick
               */
              let pickStatus = '';
              if (!correspondingFixture.enabled) {
                if (currentPick.strike) {
                  if (currentPick.eliminated_this_week) {
                    pickStatus = 'strike underline';
                  } else {
                    pickStatus = 'strike';
                  }
                } else if (currentPick.correct) {
                  pickStatus = 'gano';
                } else {
                  pickStatus = 'perdio';
                }
              }
              if (!correspondingFixture.enabled && !correspondingFixture.finished) {
                // console.log( pickStatus );
                return (
                  <div key={week.id} className="item-container">
                    <div className="img-container">
                      <div className={`shirt-picks ${pickStatus} `}>
                        <i className={` home helmet ${teamSelected.logo}`}></i>
                        <small className="team-name">
                          {teamSelected.abbreviation}
                          {this.props.pointFormat === 'line' ? (
                            <span className="linea">{currentPick.advantage}</span>
                          ) : null}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              } else {
                /**
                 * Closed Pick
                 */
                let pickStatus = '';
                if (!correspondingFixture.enabled) {
                  if (currentPick.strike) {
                    pickStatus = 'strike';
                  } else if (currentPick.correct) {
                    pickStatus = 'gano';
                  } else {
                    pickStatus = 'perdio';
                  }
                }
                return (
                  <div key={week.id} className="item-container">
                    <div className="img-container">
                      <div className={`shirt-picks ${pickStatus}`}>
                        <i className={` home helmet ${teamSelected.logo}`}></i>
                        <small className="team-name">
                          {teamSelected.abbreviation}
                          {this.props.pointFormat === 'line' ? (
                            <span className="linea">{currentPick.advantage}</span>
                          ) : null}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              }
            }
          }
        } else {
          console.log('upcoming pick');
          return (
            <div key={week.id} className="item-container">
              <div className="img-container"></div>
            </div>
          );
        }
      }
    });

    let userList = (
      <ul className="list-unstyled lower-list">
        <li className="lower-table-item user ownUser">
          <div className="item-container">
            <div className="image-container">
              <img
                className="avatarPicksGrupo"
                src={
                  utils.isLogged() && utils.getUserInfo().avatar === '/img/profile-image-01.png'
                    ? avatar
                    : utils.isLogged() &&
                      utils.getUserInfo().avatar_dir + utils.getUserInfo().avatar
                }
                alt=""
              />
            </div>
            <span className="picks-rank">
              {!this.props.myEntry.rank ? '0' : this.props.myEntry.rank}
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {utils.isLogged() ? utils.getUserInfo().nick : 'NA'}
              {this.props.myEntry.number !== null ? (
                <sup
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '75%',
                    margin: '0 2px',
                  }}
                >
                  {this.props.myEntry.number}
                </sup>
              ) : (
                ''
              )}
            </span>
          </div>
        </li>
      </ul>
    );

    let totalList = (
      <ul className="list-unstyled lower-list" style={{ width: '100%' }}>
        <li className="lower-table-item user ownUser">
          <div className="item-container">
            <div className="shirt-picks ">
              <i
                className={
                  this.props.myEntry.survivor_standing.alive ? 'ion-checkmark' : 'ion-close'
                }
                style={
                  this.props.myEntry.survivor_standing.alive
                    ? { color: 'rgb(120,180,40)' }
                    : { color: 'rgb(224,0,52)' }
                }
              />
            </div>
          </div>
        </li>
      </ul>
    );

    return (
      <div className="lista-container ownUser">
        <div className="userList ownUser">{userList}</div>
        <div className="dataList ownUser sync-horizontal">
          <div className="lower-table-item">{dataList}</div>
        </div>
        <div className="totalesList ownUser" style={{ width: '7%' }}>
          {totalList}
        </div>
      </div>
    );
  }
}

export default MisPicks;
