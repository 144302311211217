import { withFormsy } from 'formsy-react';
import React from 'react';
import cvvHelper from '../../assets/images/cvvHelper.png';
import {
  Form,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  Popover,
  OverlayTrigger,
  HelpBlock,
} from 'react-bootstrap/lib';

const popoverCvv = (
  <Popover id="popover-cvv" title="Busca tu código de seguridad">
    <img src={cvvHelper} className="img-responsive" />
  </Popover>
);

class AbonarFormsy extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popOverState: false, resetForm: false };
    this.changeValue = this.changeValue.bind(this);
    this.setNextYears = this.setNextYears.bind(this);
    this.setDays = this.setDays.bind(this);
    this.setMonths = this.setMonths.bind(this);
    this.openPopOver = this.openPopOver.bind(this);
  }

  openPopOver() {
    this.setState({
      popOverState: true,
    });
  }

  setNextYears() {
    let currentYear = new Date().getFullYear();
    // console.log(currentYear);
    let nextYears = [];
    for (let i = currentYear; i <= currentYear + 20; i++) {
      nextYears.push(i);
    }
    // console.log(nextYears);
    return nextYears;
  }

  setDays() {
    // console.log(currentYear);
    let days = [];
    for (let i = 1; i <= 31; i++) {
      if (i < 10) {
        // console.log(i);
        i = `0${i}`;
        // console.log(i);
        // i = Number(i);
      }
      days.push(i);
    }
    // console.log(days);
    return days;
  }

  setYears() {
    let currentYear = new Date().getFullYear();
    // console.log(currentYear);
    let years = [];
    for (let i = 1920; i <= currentYear - 18; i++) {
      years.push(i);
    }
    // console.log(nextYears);
    return years;
  }
  setMonths() {
    let currentMonth = new Date().getMonth();
    // console.log(currentYear);
    let months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }
    // console.log(nextYears);
    return months;
  }

  changeValue(event) {
    // setValue() will set the value of the component, which in
    // turn will validate it and the rest of the form
    // Important: Don't skip this step. This pattern is required
    // for Formsy to work.
    this.props.setValue(event.currentTarget.value);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.resetForm && this.props.resetForm !== prevProps.resetForm) {
      console.log('Reset Value');
      this.props.resetValue();
    }
  }

  render() {
    let conditionalComponent = null;
    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    let helpMessages =
      this.props.help !== undefined
        ? this.props.help.map((help, i) => <HelpBlock key={i}>{help}</HelpBlock>)
        : null;

    let colClass = 'col-50';
    if (this.props.noColWidth) {
      colClass='';
    }
    // console.log( this.props.name );
    // console.log( this.props.getValue() );

    switch (this.props.type) {
      case 'TEXT':
        if (!this.props.col) {
          conditionalComponent = (
            <div className="flex-row row-center row-no-padding">
              <FormGroup className={`flex-col`} controlId={this.props.name} style={{ marginBottom: 0 }}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                  type="text"
                  placeholder={this.props.placeholder}
                  minLength={this.props.minLength}
                  maxLength={this.props.maxLength}
                  dataId={this.props.dataId}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e, this.props.name);
                  }}
                ></FormControl>
                <span className="error-formulario">{errorMessage}</span>
                {helpMessages}
              </FormGroup>
            </div>
          );
        } else {
          conditionalComponent = (
            <div
              className={`flex-col ${!this.props.col ? '' : this.props.col}`}
              controlId={this.props.name}
            >
              <ControlLabel>{this.props.label}</ControlLabel>
              <FormControl
                type="text"
                placeholder={this.props.placeholder}
                minLength={this.props.minLength}
                maxLength={this.props.maxLength}
                value={this.props.getValue() || this.props.defaultValue}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, this.props.name);
                }}
              ></FormControl>
              <span className="error-formulario">{errorMessage}</span>
              {helpMessages}
            </div>
          );
        }

        break;
      case 'CARD_NUMBER':
        conditionalComponent = (
          <div className="flex-row row-center row-no-padding">
            <div className="flex-col">
              <FormGroup controlId={this.props.name} style={{marginBottom: '0px'}}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                  type="text"
                  inputMode="numeric"
                  placeholder={this.props.placeholder}
                  pattern="[0-9]*"
                  autoComplete="ccard"
                  maxLength={16}
                  value={this.props.getValue() || this.props.defaultValue}
                  onChange={(e) => {
                    this.changeValue(e);
                    this.props.inputHandler(e, this.props.name);
                  }}
                ></FormControl>
              </FormGroup>
              <span className="error-formulario">{errorMessage}</span>
              {helpMessages}
            </div>
          </div>
        );
        break;
      case 'SECURITY_CODE':
        conditionalComponent = (
          <React.Fragment>
            <div className={`flex-col cvvContainer col-no-padding`}>
              <FormControl
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={4}
                placeholder={this.props.placeholder}
                id={'cvv2'}
                autoComplete="cc-csc"
                value={this.props.getValue() || this.props.defaultValue}
                onChange={(e) => {
                  this.changeValue(e);
                  this.props.inputHandler(e, this.props.name);
                }}
              ></FormControl>
              {helpMessages}
            </div>
          </React.Fragment>
        );
        break;
      case 'AMOUNT':
        conditionalComponent = (
          <div className="flex-col col-no-padding">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              required
              name={this.props.name}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
              max={50000}
              min={50}
              onChange={(e) => {
                this.changeValue({ target: { value: Number(e.target.value) }});
                this.props.inputHandler(e, this.props.name);
              }}
            ></FormControl>
            <span className="error-formulario">{errorMessage}</span>
          </div>
        );
        break;
      case 'CARD_NIP':
        conditionalComponent = (
          <div className="flex-col col-no-padding">
            <ControlLabel>{this.props.label}</ControlLabel>
            <FormControl
              required
              name={this.props.name}
              type="number"
              inputMode="numeric"
              placeholder={this.props.placeholder}
              pattern="[0-9]*"
              max={9999}
              maxLength={4}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e, this.props.name);
              }}
              style={{width: '100px'}}
            ></FormControl>
            <div className='flex-row'>
              <span className="error-formulario">{errorMessage}</span>
            </div>
          </div>
        );
        break;
      case 'SAVE_CARD':
        conditionalComponent = (
          <FormGroup
            className="flex-row row-center row-no-padding point-format-wizard"
            controlId={this.props.name}
          >
            <ControlLabel className="">{this.props.label}</ControlLabel>
            <div className="" style={{ marginTop: 8 }}>
              <Checkbox onChange={this.props.inputHandler} inline={true}>
                <div className={` checkbox-custom `} />
              </Checkbox>
            </div>
          </FormGroup>
        );
        break;
      case 'YEAR':
        const nYears = this.setNextYears();
        conditionalComponent = (
          <div className="flex-col col-no-padding ">
            <FormControl
              required
              id="exp_year"
              value={this.props.getValue() || this.props.defaultValue}
              componentClass="select"
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e, this.props.name);
              }}
            >
              <option value={null}>AÑO</option>
              {nYears.map((year) => {
                // console.log(year);
                let yearAbbreviation = year - 2000;
                return (
                  <option key={yearAbbreviation} value={yearAbbreviation}>
                    {yearAbbreviation}
                  </option>
                );
              })}
            </FormControl>
          </div>
        );
        break;
      case 'MONTH':
        const nMonths = this.setMonths();
        conditionalComponent = (
          <div className="flex-col col-no-padding">
            <FormControl
              componentClass="select"
              required
              id="exp_month"
              value={this.props.getValue() || this.props.defaultValue}
              onChange={(e) => {
                this.changeValue(e);
                this.props.inputHandler(e, this.props.name);
              }}
            >
              ><option value={null}>MES</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </FormControl>
          </div>
        );
        break;
      default:
        break;
    }
    return conditionalComponent;
  }
}

export default withFormsy(AbonarFormsy);
