import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')


export const Pick = (props) => {
  const {
    fixture,
    week,
    handlePick,
    selectedPick,
    selectedTeam,
    pointFormat,
    isFirstMatch,
    firstMatch,
    firstOfWeek,
    currentEntry,
    survivorInfo,
    selectedOtherWeeks,
    isUserSelectedFirstMatch ,
    updateStartedFirstMatchOfWeek
  } = props

  const isPassedDeadlineWeek = firstOfWeek.time_left <= 0
  const isPassedMatchDeadline = fixture?.survivor_time_left <= 0


  // Cambia el color de el contador dependiendo del tiempo restante
  // para comenzar el survivor
  const lessThan24Hours = fixture?.survivor_time_left < 86400

  // Si ya paso el primer partido
  const isPassedFirstMatchDate = firstMatch?.survivor_time_left <= 0

  let isDisabled = isPassedDeadlineWeek

  // Soccer
  if (survivorInfo.sport_id === 1 && isPassedFirstMatchDate) {
    isDisabled = true
  } else {
    // NFL 
    // Desactiva si ya paso el primer partido pero no desactiva
    // Si aun no cierra la semana week.time_left === 0
    // y el usuario no escogio equipo del primer partido
    // -----------------------------------------------------
    if (isFirstMatch && isPassedFirstMatchDate) {
      isDisabled = true
    } else if (!isFirstMatch && isUserSelectedFirstMatch && isPassedFirstMatchDate) {
      isDisabled = true
    }
  }

  // Deshabilitar si ya esta descalificado y el survivor ya comenzo
  if (survivorInfo.status !== 'upcoming' && !currentEntry?.survivor_standing?.alive) {
    isDisabled = true
  }

  const handleSelect = (type) => {
    if (isDisabled) return
  
    if (type === 'home' && !selectedOtherWeeks?.[fixture?.home_team?.id]) {
      handlePick(fixture?.home_team?.id, week.id, fixture?.id)
    } else if (type === 'away' && !selectedOtherWeeks?.[fixture?.away_team?.id]) {
      
      handlePick(fixture?.away_team?.id, week.id, fixture?.id)
    }
  }

  const isHomeSelectedInOtherWeek = selectedOtherWeeks?.[fixture?.home_team?.id] && selectedTeam?.id !== fixture?.home_team?.id
  const isAwaySelectedInOtherWeek = selectedOtherWeeks?.[fixture?.away_team?.id] && selectedTeam?.id !== fixture?.away_team?.id

  useEffect(() => {
    let tick
    let count = 0

    if (isFirstMatch && firstMatch.survivor_time_left > 0) {
      count = firstMatch.survivor_time_left

      // Crear contador que cierra la opcion de seleccionar pick
      // Si ya paso la fecha de tiempo limite
      tick = window.setInterval(() => {
        count = count - 1

        if (count === 0) {
          updateStartedFirstMatchOfWeek(firstMatch)
          window.clearInterval(tick)
        }

      }, 1000)
    }

    return () => window.clearInterval(tick)
  }, [isFirstMatch, firstMatch])

  return (
    <PickRow isDisabled={isDisabled}>
      <div className='team home' onMouseDown={() => handleSelect('home')} style={{ opacity: isHomeSelectedInOtherWeek ? 0.5 : 1}}>
        <PickBox>
          {(!selectedOtherWeeks?.[fixture?.home_team?.id] || selectedTeam?.id === fixture?.home_team?.id) && (
            <RadioButton isSelected={selectedPick?.pick === fixture?.home_team?.id} isDisabled={isDisabled} />
          )}
        </PickBox>
        
        <i class={'team-logo home ' + fixture?.home_team?.logo}></i>
        <div>
          <Name>{fixture?.home_team.abbreviation}</Name>
          <TeamRecord teamData={fixture?.home_team} />
        </div>

        {pointFormat === 'line' && (
            fixture?.favorite !== null ? (
                <div>
                  <LineHome>
                    {fixture?.favorite === 'home' ? fixture?.advantage : null}
                  </LineHome>
                </div>
            ) : (
              <LineHome></LineHome>
            )
        )}
        
      </div>
      <div className='team away' onClick={() => handleSelect('away')} style={{ opacity: isAwaySelectedInOtherWeek ? 0.5 : 1}}>

        {pointFormat === 'line' && (
            fixture?.favorite !== null ? (
                  <LineAway>
                    {fixture?.favorite === 'away' ? fixture?.advantage : null}
                  </LineAway>
            ) : (
              <LineAway></LineAway>
            )
        )}

        <div>
          <Name>{fixture?.away_team?.abbreviation}</Name>
          <TeamRecord teamData={fixture?.away_team} />
        </div>


        <i class={'team-logo home ' + fixture?.away_team?.logo}></i>

        <PickBox>
          {(!selectedOtherWeeks?.[fixture?.away_team?.id] || selectedTeam?.id === fixture?.away_team?.id) && (
            <RadioButton isSelected={selectedPick?.pick === fixture?.away_team?.id} isDisabled={isDisabled} />
          )}
        </PickBox>
        
      </div>

      {!isPassedMatchDeadline && (
        <div className='date'
          style={{
            color: lessThan24Hours ? '#ff4047' : '#ff4047',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div>{moment(fixture?.survivor_deadline).format('ddd  D H:mm')}</div>
        </div>
      )}
      
    </PickRow>
  )
}

// ---------------------------------------------------------------------

const LineHome = styled.span`
  color: yellow;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  font-size: 0.7em;
`

const LineAway = styled(LineHome)`
  left: 0.5em;
  top: 50%;
`

const PickRow = styled.div`
  width: 100%;
  height: 26px;
  min-height: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #8f8f8f;
  text-align: center;
  position: relative;
  font-size: 0.65em;

  @media screen and (min-width: 1300px) {
    font-size: 0.8em;
  }

  &:last-child {
    border-bottom: none;
  }

  .team {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    border-right: 1px solid #8f8f8f;
    padding: 0 0.65em;
    gap: 0.2em;
    position: relative;
    user-select: none;
    cursor: ${p => p.isDisabled ? 'default' : 'pointer'};
  }

  .home {
    justify-content: flex-start;
  }

  .away {
    justify-content: flex-end;
  }

  .date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: sans-serif;
  }
`

const Name = styled.span`
  font-weight: bolder;
  font-style: italic;
  font-family: Chivo;
  margin-right: 0.3em;
`

const PickBox = styled.div`
  width: 15px;
  height: 15px;
`

const Glow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(255,255,255,0.6) 100%);
`

const RadioSelected = styled.span`
  font-size: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
`

const Border = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${p => {
    if (p.isDisabled) return '2px solid #757575'
    if (p.isSelected) return '2px solid #78BC28'
    return '2px solid #ffffff'
  }};
`

const PickBall = styled.div`
  background-color: #78BC28;
  width: 5px;
  height: 5px;
  border-radius: 50%;
`

const RadioButton = ({ isSelected, isDisabled }) => {
  if (isSelected) {
    return (
      <>
        <Glow />
        <RadioSelected><Border isDisabled={isDisabled} isSelected><PickBall></PickBall></Border></RadioSelected>
      </>
    )
  }

  return (
    <RadioSelected><Border isDisabled={isDisabled}></Border></RadioSelected>
  )
}

const TeamRecord = ({ teamData }) => {
  {
    teamData.sport_id =
      teamData.sport_id === 1 ? (
        !teamData.team_stats || !teamData.team_stats.hasOwnProperty('win') ? null : (
          <small className=''>
            {`(${teamData.team_stats.win}-${teamData.team_stats.lost})`}
          </small>
        )
      ) : null;
  }

  let conditionalComponent = null;
  if (teamData?.team_stats && teamData.team_stats.hasOwnProperty('win')) {
    if (teamData.sport_id === 1) {
      conditionalComponent = (
        <small className=''>
          {`(${teamData?.team_stats?.win}-${teamData?.team_stats?.ties}-${teamData?.team_stats?.lost}) `}
        </small>
      );
    } else {
      conditionalComponent = (
        <small className=''>
          {`(${teamData.team_stats.win}-${teamData.team_stats.lost}) `}
        </small>
      );
    }
  }

  return conditionalComponent;
};