import styled from 'styled-components'
import React from 'react'
import { useQuery } from '@tanstack/react-query'

// Utils
import API from '../../../../../../utils/api'
import { capitalize } from '../../../../../../utils/text-utils'
import { getCurrencyFormatSmall } from '../../../../../../utils/currencyFormat'

// Assets
import money from '../assets/money.png'
import money2 from '../assets/money2.png'
import level6 from '../assets/level-6.png'
import level7 from '../assets/level-7.png'
import level8 from '../assets/level-8.png'
import level9 from '../assets/level-9.png'
import level10 from '../assets/level-10.png'
import bonusLevel1 from '../assets/bonus-level-1.png'
import bonusLevel2 from '../assets/bonus-level-2.png'
import bonusLevel3 from '../assets/bonus-level-3.png'

const levelImages = {
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10
}

const levelTextImages = {
  1: bonusLevel1,
  2: bonusLevel2,
  3: bonusLevel3
}

const useVipRewards = () => {
  const vipReferralPrizes = useQuery(['vip_referral_prizes'], API.getVipRefealPrizes)
  const levels = useQuery(['levels'], API.getLevelBenefitList)

  // Adding data object with level info
  const vipReferralPrizesData = vipReferralPrizes?.data?.data?.prizes.map(prize => {
    if (prize.action === 'level') {
      return {
        ...prize,
        level: levels?.data?.data?.levels.find(
          level => level.id === prize.conditions_values.level_id
        )
      }
    }
    
    return prize
  })

  return {
    isLoading: vipReferralPrizes.isFetching || levels.isLoading,
    vipReferralPrizes: vipReferralPrizesData || []
  }
}


const TableInfo = () => {
  const { isFetching, vipReferralPrizes } = useVipRewards()


  if (isFetching) return null

  const purchaseList = vipReferralPrizes.filter(item => item.action === 'purchase')
  const levelList = vipReferralPrizes.filter(item => item.action === 'level')

  return (
    <Row>
      {/* <Deposit>
        <Header>
          <HeaderContent>
            <span>Obtén un <strong>bono inmediato</strong> dependiendo el monto del 1er depósito de tu invitado</span>
          </HeaderContent>
        </Header>
        <Body  style={{ gridTemplateColumns: `repeat(${purchaseList.length}, 1fr)` }}>
          {purchaseList.map(item => (
            <Item key={item?.id}>
              <ItemHeader><BonusImage src={levelTextImages[item?.id]} alt='Imagen bono' /></ItemHeader>
              <ItemContent>
                <MoneyImg src={money} alt='Icono billete' />
                <Title>{getCurrencyFormatSmall(item?.amount)}</Title>
              </ItemContent>
            </Item>
          ))}
        </Body>
      </Deposit> */}

      <Levels>
        <Header>
          <HeaderContent>
            <span><strong>Te abonaremos</strong> el monto correspondiente a los niveles alcanzados durante los primeros 3 meses de actividad de tu invitado.</span>
          </HeaderContent>
        </Header>
        <Body style={{ gridTemplateColumns: `repeat(${levelList.length}, 1fr)` }}>
          {levelList.map(item => (
            <Item key={item.id}>
              <ItemHeaderLevel>
                <LevelImage src={levelImages[item?.level?.id]} alt='Imagen Nivel 1' />
                <span>{item?.level?.name !== 'MVP' ? capitalize(item?.level?.name) : item?.level?.name}</span>
              </ItemHeaderLevel>
              <ItemContent>
                <Money2Img src={money2} alt='Icono de billete' />
                <Title>{getCurrencyFormatSmall(item?.amount)}</Title>
              </ItemContent>
            </Item>
          ))}
        </Body>
      </Levels>
    </Row>
  )
}

const BonusImage = styled.img`
  width: 50%;
`

const LevelImage = styled.img`
  width: 55%;
  max-width: 2.7em;
`


const Box = styled.div`
  margin: 1em 0;
  margin-bottom: 0;
  width: 100%;
`

const Deposit = styled(Box)`
  @media screen and (min-width: 750px) {
    width: 42%;
  }
`

const Levels = styled(Box)`
  @media screen and (min-width: 750px) {
    /* width: 58%; */
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #625e45;
  align-items: flex-start;

  &:last-of-type {
    border-right: 0;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 1em;
  align-items: center;

  @media screen and (min-width: 750px) {
    flex-direction: row;
  }

`

const Body = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`

const ItemHeader =  styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #918f8f42;
  height: 5em;
  width: 100%;
`

const ItemHeaderLevel =  styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background-color: #918f8f42;
  height: 5em;
  width: 100%;

  span {
    font-size: 0.65em;
    height: 2em;
    text-align: center;
    display: flex;
    align-items: flex-start;
    margin-top: 0.5em;
    color: #AAA;;

    @media screen and (min-width: 1368px) {
      font-size: 0.55em;
    }
  }
`

const ItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2424245c;
  height: 5.5em;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  padding: 0.6em;
`

const Title = styled.span`
  text-align: center;
  background: #F9E799;
  background: linear-gradient(to bottom, #F9E799 41%, #C8AE72 50%, #F2E3A3 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.8em;

  @media screen and (min-width: 660px) {
    font-size: 0.9em;
  }

  @media screen and (min-width: 1368px) {
    font-size: 1em;
  }
`

const MoneyImg = styled.img`
  width: 43%;
  width: 42px;
`

const Money2Img = styled.img`
  width: 70%;
  width: 42px;
`


const Header = styled.header`
  width: 100%;
  background: linear-gradient(180deg, rgb(247, 228, 152), rgb(207, 180, 115), rgb(250, 234, 169), rgb(119 89 37));
  font-size: 0.9em;
  text-align: center;
  padding: 1em;
  border-radius: 1em 1em 0 0;
  position: relative;
  height: 5.5em;
`

const HeaderContent = styled.div`
  position: absolute;
  background-color: #000;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 1em 1em 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5em;
  
  strong {
    color: #FDFFA9;
  }
`

export default TableInfo
