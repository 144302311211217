import { useEffect, useState } from 'react'

const ACTIVE_TAB_KEY = 'activetab'
const EVENT_KEY = 'message:activetab'

// Hook for avoid use the webapp on more than one tab
// using localStorage for across browser compatibility
export default function useSessionTabs () {
  // The tabID is the initial tab is unique for
  // every tab and it's calculated when the page loads
  const [tabID, setTabID] = useState()
  const [isActiveHere, setIsActiveHere] = useState(false)

  useEffect(() => {
    const id = getID()
    window.localStorage.setItem(ACTIVE_TAB_KEY, id)
    setTabID(id)
    // Notify to another tabs
    emmit(id)
  }, [])

  useEffect(() => {
    const storedActiveTab = window.localStorage.getItem(ACTIVE_TAB_KEY)
    if (storedActiveTab !== null && (tabID !== storedActiveTab)) {
      setIsActiveHere(true)
    } else {
      setIsActiveHere(false)
    }
  }, [])

  useEffect(() => {
    function messageReceive (event) {
      // Only dispatch for custom storage event name
      if (event.key === EVENT_KEY) {
        const message = JSON.parse(event.newValue)
        if (message) {
          window.localStorage.setItem(ACTIVE_TAB_KEY, message)
          const storageActiveTab = message
          if (message !== storageActiveTab) {
            setIsActiveHere(true)
          } else {
            setIsActiveHere(false)
          }
        }
      }
    }

    window.addEventListener('storage', messageReceive, false)

    // Cleanup listeners
    return () => {
      window.removeEventListener('storage', messageReceive, false)
    }
  }, [setIsActiveHere])

  // Function to use Active Tab and inactive others
  function useHere () {
    window.localStorage.setItem(ACTIVE_TAB_KEY, tabID)
    setIsActiveHere(true)
    // Notify to another tabs
    emmit(tabID)
  }

  // Is for receive temporal messages on local storage
  function emmit (message) {
    localStorage.setItem(EVENT_KEY, JSON.stringify(message))
    localStorage.removeItem(EVENT_KEY)
  }

  return {
    tabID,
    isActiveHere,
    useHere
  }
}

// Util function to get random string
function getID () {
  return Math
    .random()
    .toString(16)
    .substring(2)
}
