import React, { useEffect } from 'react'

// Components
import { Typography, Box, Iconify } from '../../../Xmerald-UI'
import { useWizardPrivate } from '../wizardPrivate.store'

export default function SurvivorSuccess () {
  const step = useWizardPrivate(state => state.step)
  const createdSurvivor = useWizardPrivate(state => state.createdSurvivor)

  useEffect(() => {
    if (createdSurvivor) {
      // Redirect to quiniela
      window.setTimeout(() => {
        const path = window.location.pathname.split('/')
        window.localStorage.setItem('createdPrivate', 'true')

        // if (path.length > 1 && path[1] === 'survivor') {
        window.location = `/survivor/${createdSurvivor.slug}/${createdSurvivor.id}`
        // }
      }, 800)
    }
  }, [createdSurvivor])

  if (step !== 'SURVIVOR_SUCCESS') return null

  return (
    <Box maxWidth={300}>
      <Typography center size='1.2em' mt={20}>Tu survivor fue creado exitosamente</Typography>
      <Typography center size='1em' mt={20} mb={20}>Te estamos redirigiendo</Typography>
      <Typography center>
        <Iconify icon='ep:success-filled' size='3em' color='#8cc63f' />
      </Typography>
    </Box>
  )
}
