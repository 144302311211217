import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import numeral from 'numeral'

// Utils
import API from '../../utils/api'

const MyPlays = () => {
  const [gameList, setGameList] = useState([])
  const [pager, setPager] = useState({
    page: 1,
    pages: 1,
    range: 'all',
    replaceList: true
  })

  const myGamesRef = useRef(null)
  const pagerRef = useRef(pager)

  useEffect(() => {
    getMyGamesCasino()
  }, [pager.page, pager.range])

  useEffect(() => {
    pagerRef.current = pager
  }, [pager])

  // Get my games api info
  const getMyGamesCasino = () => {
    API.getPPCasinoRounds(pager).then(response => {
      const data = response.data
      setPager(() => {
        return { ...pager, page: data.page, pages: data.pages }
      })

      // cuando se cambia el rango de fechas o cuando es la carga inicial
      // se debe de reemplazar toda la lista
      let newList
      if (pager.replaceList) {
        newList = data.rounds
      } else {
        // cuando se cambia el page se apendea al final
        const oldList = gameList
        newList = [...oldList, ...data.rounds]
      }
      setGameList(newList)
    })
  }

  // Handler dropdown
  const handleDropDown = ev => {
    const range = ev.target.value
    myGamesRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    setPager({
      page: 1,
      pages: 1,
      range,
      replaceList: true
    })
  }

  // Scroll event of my games
  const handleScroll = ev => {
    if (
      myGamesRef.current.scrollTop >=
      myGamesRef.current.scrollHeight - myGamesRef.current.offsetHeight
    ) {
      if (pagerRef.current.page < pagerRef.current.pages) {
        const oldPager = pager
        setPager({ ...oldPager, page: pagerRef.current.page + 1, replaceList: false })
      }
    }
  }
  useEffect(() => {
    myGamesRef.current.addEventListener('scroll', handleScroll)
    return () => {
      myGamesRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className='panel-pickwin' style={{ background: 'white' }}>
      <div className='flex-row'>Periodo:</div>
      <div className='flex-row'>
        <select
          id='range'
          className='dropdownForm'
          onChange={ev => handleDropDown(ev)}
          defaultValue='all'
        >
          <option value='all'>Todo</option>
          <option value='today'>Hoy</option>
          <option value='week'>Esta semana</option>
          <option value='month'>Este mes</option>
          <option value='semester'>Este semestre</option>
        </select>
      </div>

      <div className='my-games-content' ref={myGamesRef}>
        {gameList.map((casinoRound, index) => (
          <CasinoRoundCard casinoRound={casinoRound} key={index} />
        ))}
      </div>
    </div>
  )
}

const CasinoRoundCard = ({ casinoRound }) => {
  const [historyHidden, setHistoryHidden] = useState(true)

  let classTotal = null
  let classWin = null
  if (casinoRound.total_amount > 0) {
    classTotal = 'green'
    classWin = 'wager-win'
  }
  if (casinoRound.total_amount < 0) {
    classTotal = 'red'
    classWin = 'wager-lose'
  }
  if (casinoRound.total_amount === 0) {
    classTotal = 'gray'
    classWin = 'wager-none'
  }

  const onShowHideHistoryClicked = (e) => {
    e.preventDefault()
    setHistoryHidden(!historyHidden)
  }

  return (
    <div className={`round-content ${classTotal}`} key={casinoRound.pariplay_token_id}>
      <a
        href='#'
        onClick={onShowHideHistoryClicked}
      >
        <div className='flex-row'>
          <div className='flex-col col-10'>
            <img src={casinoRound.game_thumbnail} alt='' className='game-thumb img-responsive' />
          </div>
          <div className='flex-col'>
            <div className='flex-container between mt-05'>
              <span>#{casinoRound.pariplay_token_id}</span>
              <span>{moment(casinoRound.created).format('DD MMM YYYY hh:mm')}</span>
            </div>
            <div className='flex-container between mt-05'>
              <div>
                ARRIESGA:{' '}
                <strong className='wager-lose'>
                  {numeral(casinoRound.total_bet).format('$0,000.00')} mxn
                </strong>
              </div>
              <div>
                GANA:{' '}
                <strong className='wager-win'>
                  {numeral(casinoRound.total_win).format('$0,000.00')} mxn
                </strong>
              </div>
              <div>
                RES:{' '}
                <strong className={classWin}>
                  {numeral(casinoRound.total_amount).format('$0,000.00')} mxn
                </strong>
              </div>
            </div>

            <div className='flex-container justify mt-05'>
              {historyHidden
                ? (
                  <i className='ion-icon ion-arrow-down-b' />
                  )
                : (
                  <i className='ion-icon ion-arrow-up-b' />
                  )}
            </div>
          </div>
        </div>
      </a>
      {!historyHidden && <TransactionsList casinoRound={casinoRound} />}
    </div>
  )
}

const TransactionsList = ({ casinoRound }) => {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    API.getPPTransactions(casinoRound.pariplay_token_id).then(response => {
      const data = response.data
      setTransactions(data.transactions)
      setLoading(false)
    })
  }, [])

  if (loading) { return <div className='text-center'><i className='icon ion-load-b' /></div> }

  return (
    <div className='transaction-history'>
      {transactions.map((transaction, index) => {
        let classWin = null
        if (transaction.amount > 0) {
          classWin = 'wager-win'
        } else if (transaction.amount < 0) {
          classWin = 'wager-lose'
        } else if (transaction.amount === 0) {
          classWin = 'wager-none'
        }

        return (
          <div className='flex-row text-small' key={transaction.id}>
            <div className='flex-col'>
              {transaction.id} - {moment(transaction.created).format('DD MMM YYYY hh:mm:ss')}
            </div>
            <div className='flex-col'>
              {transaction.operation === 'debit' && 'Débito'}
              {transaction.operation === 'credit' && 'Crédito'}
              {transaction.operation === 'cancel' && 'Cancelación'}
              {transaction.operation === 'end' && 'Finalizar'}
              {transaction.operation === 'debit_credit' && 'Débito/Crédito'}
            </div>
            <div className='flex-col text-right'>
              <span className={classWin}>{numeral(transaction.amount).format('$0,000.00')} mxn</span>
            </div>

          </div>
        )
      })}
    </div>
  )
}

export default MyPlays
