import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import styles from './index.module.sass'

// Components
import Text from '../../Text'
import Divider from '../../Divider'
import LevelChart from './LevelChart'
import LevelBenefits from './LevelBenefits'
import NextLevelChart from './NextLevelChart'
import NextLevelBenefits from './NextLevelBenefits'
import LastMonthStats from './LastMonthStats'
import { Row, Column1, Column2 } from './Layout'

// Utils
import API from '../../../utils/api'

// Assets
import flechas from '../../../assets/images/flechasIconBlanco-50.png'


export default function Rewards({ userLoyalty }) {
  const [benefits, setBenefits] = useState({})
  const { nextLevel } = userLoyalty

  useEffect(() => {
    API.getLevelBenefitList().then((res) => {
      if (res.data.levels) {
        const benefits = res.data.levels
          .reduce((prev, next) => ({ ...prev, [next.name]: next.benefits }), {})

        setBenefits(benefits)
      }
    })
    .catch((error) => {
      Sentry.captureException(error, { extra: 'Error to get level benefits' })
    })
  }, [])



  return (
    <div className="user-categories-container col-xs-12 col-md-9">
      <div className={styles.rewardsContainer}>
        <img src={flechas} className={styles.rewardIcon} alt="" />
        <div className={styles.rewardTitle}>Progama de Lealtad</div>
      </div>
      <Divider />

      <Row>
        <Column1>
          <LevelChart userLoyalty={userLoyalty} />
        </Column1>
        <Column2>
          <LevelBenefits
            userLoyalty={userLoyalty}
            benefits={benefits}
          />
        </Column2>
      </Row>

      <Divider />
      
      <Text as='h6' mb={15} ml={30}>
        En el nivel <strong className='text-capitalize'>{nextLevel.name.toLowerCase()}</strong>, tu rembolso y beneficios habrían sido de:
      </Text>

      <Row>
        <Column1>
          <NextLevelChart userLoyalty={userLoyalty} />
        </Column1>
        <Column2>
          <NextLevelBenefits
            userLoyalty={userLoyalty}
            benefits={benefits}
          />
        </Column2>
      </Row>

      <Divider />

      <Row>
        <Column1>
          <LastMonthStats userLoyalty={userLoyalty} />
        </Column1>
      </Row>
    </div>
  )
}
