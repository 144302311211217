import styled from 'styled-components'

// Animations
import { fadeIn } from '../../animations/fade'
import { zoomIn } from '../../animations/zoom'
import { rotate360 } from '../../animations/rotate'

export const Modal = styled.div`
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-y: auto;
  user-select: none;
  position: fixed;
  animation: 250ms ease ${fadeIn};
  background-color: rgb(0 0 0 / 86%);
  backdrop-filter: blur(3px) grayscale(1);
  overflow: hidden auto;
  display: block;

  @media screen and (min-height: 600px) {
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10em; */
  }
`

export const Content = styled.div`
  width: 100%;
  min-width: 300px;
  max-width: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: 500ms ease ${zoomIn};
  margin: 3.75rem auto;
  margin: 3.75rem auto;
`

export const Move = styled.div`
  position: absolute;
  top: -90px;
  z-index: 10;
  width: 100%;
  opacity: 0.5;
  filter: blur(2px);
  animation: ${rotate360} 45s linear infinite;
`

export const Bar = styled.div`
  width: 100%;
  height: 80px;
  opacity: 0;
  border-radius: 0.5em;
  transform: scale(0.7);
  letter-spacing: 1px;
  text-align: center;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  background-color: #74BB20;
  text-shadow: 1px 1px 6px #00000099;
  animation: 600ms ease ${zoomIn} 180ms forwards;
  box-shadow: inset 0px 2px 18px #c6e70e, inset 0px 2px 4px #7de70e;
  background: radial-gradient(#74BB20, #74bb2045);
`

export const Box = styled.div`
  width: 96%;
  display: flex;
  gap: 1em;
  z-index: 100;
  margin: auto 0;
  background: #000000;
  border-radius: 0.8em;
  overflow: visible;
  min-height: 15em;
  margin-top: -2.7em;
  position: relative;
  flex-direction: column;
  align-items: center;
  border: 1px solid #383838;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1em 1em 1.5em 1em;
`

export const CloseButton = styled.button`
  color: #FFF;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.5em;
  border-radius: 1em;
  background-color: #00000026;
  position: absolute;
  right: 1em;
  top: 55%;
  transform: translateY(-50%);
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: background-color 300ms ease;

  &:hover {
    background-color: #00000061;
  }
`

export const Title = styled.div`
  font-family: Chivo;
  font-size: 1.15em;
  line-height: 2.7;
`

export const VisibleBar = styled.div`
  height: 40px;
  position: relative;
`
