import React from 'react'
import styled, { keyframes } from 'styled-components'

// Components
import Button from '../Form/Button'
import ModalBody from '../ModalPanel/ModalBody'

// Hooks
import useSessionTabs from '../../hooks/useSessionTabs'


export default function ModalOnlyOneTab () {
  const { isActiveHere, useHere } = useSessionTabs()

  if (isActiveHere) return null

  return (
    <Modal>
      <ModalBody>
        <Logo
          src='/images/Pickwin.png'
          alt='Pickwin logo'
        />
        <Title>¡Ups pickwinero parece que tienes Pickwin abierto en otra pestaña!</Title>
        <Text>Puedes hacer Click en "Activar Aquí" para usar Pickwin en esta ventana</Text>
        <Button onClick={useHere} bgColor='#6cbb34'>Activar Aquí</Button>
      </ModalBody>
    </Modal>
  )
}

const show = keyframes`
  from {
    opacity: 0;
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const Modal = styled.div`
  background-color: #000;
  background-color: rgb(0 0 0 / 86%);
  backdrop-filter: blur(3px) grayscale(1);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  animation: 500ms ease ${show};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-y: auto;
  user-select: none;
`

const Title = styled.div`
  text-align: center;
  margin: 1.4em 0 1em 0;
  font-size: 1.3em;
  font-weight: bold;
  line-height: 1.5;
`

const Text = styled.div`
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 1.5em;
`

const Logo = styled.img`
  max-width:  175px;
  margin: 0 auto;
`
