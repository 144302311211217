import palomaRegistro from '../../assets/images/flechasIconBlanco-50.png';
import premios from '../../assets/images/premiosIconBlanco-50.png';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import utils from '../../utils/utils';

class DisplayEstatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      pool: this.props.pool,
      showPanel: this.props.showPanel,
      updateStateFunc: this.props.updateStateFunc,
      userData: this.props.userDataProps,
      specialButton: false,
    };
    // console.log('CHeckStatus',this.props);
    this.redirectToQuina = this.redirectToQuina.bind(this);
    this.isNewUserButton = this.isNewUserButton.bind(this);
  }
  redirectToQuina() {
    console.log('redirect Please!');
    this.setState({
      redirect: true,
    });
  }

  isNewUserButton(gameType, game) {
    const isGiftCurrency = game.entry_currency === 'freeroll';

    // console.log( game );

    if (!utils.isLogged() || !game.name) {
      return;
    }

    let name = String(game.name).split('%');
    name = name[0];
    // console.log( game.entry_fee );

    if (
      !this.props.userDataProps ||
      (this.props.userDataProps.entry_count > 0 && !this.props.userDataProps.is_new)
    ) {
      return false;
    } else if (
      (name.trim() === 'LIGA MX' &&
        game.entry_fee === 1000 &&
        game.entry_currency === 'real' &&
        !this.props.userDataProps.entry_count) ||
      isGiftCurrency
    ) {
      // console.log('THIS ONE');
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.logged && this.props.logged) ||
      prevProps.updatePools !== this.props.updatePools
    ) {
      this.setState({
        pool: this.props.pool,
        showPanel: this.props.showPanel,
        updateStateFunc: this.props.updateStateFunc,
        userData: this.props.userDataProps,
      });
    }
  }

  render() {
    let gameType = null;
    if (this.props.pool.game_type === 'pool') {
      gameType = 'quiniela';
    } else if (this.props.pool.game_type === 'group_pool') {
      gameType = 'quinielaGrupo';
    } else {
      gameType = this.props.pool.game_type;
    }
    // console.log( gameType);

    let tag = null;
    let newUserButton = this.isNewUserButton(gameType, this.props.pool);
    if (this.state.redirect) {
      // console.log( this.props.pool.game_type);
      if (this.props.pool.game_type === 'bracket') {
        tag = (
          <Redirect
            to={{
              pathname: `/bracket/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`,
            }}
          />
        );
      } else if (this.props.pool.game_type === 'survivor') {
        tag = (
          <Redirect
            to={{
              pathname: `/survivor/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`,
            }}
          />
        );
      } else if (this.props.pool.game_type === 'group_pool') {
        tag = (
          <Redirect
            to={{
              pathname: `/quinielaGrupo/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`,
            }}
          />
        );
      } else {
        tag = (
          <Redirect
            to={{
              pathname: `/quiniela/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`,
            }}
          />
        );
      }

      // console.log('REdirect Quina', this.props, this.state);
    } else {
      /**
       *Logged
       */
      if (this.props.logged) {
        // console.log('DisplayEstatus Logged', this);
        /**
         * Full Quiniela
         */
        if (this.props.pool.pickngo && this.props.pool.is_full) {
          if (this.props.pool.status === 'closed') {
            if (this.props.pool.entries.lenght > 0) {
              tag = (
                <div
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  className="btn-pool-wrap"
                >
                  <Button
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    id="closed"
                    className="btn-pool registrate btn-rojo registrado-cerrada"
                    bsStyle="warning"
                  >
                    <Link
                      to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.entries[0].id} `}
                    >
                      <span
                        data-id={this.props.pool.id}
                        data-game-type={this.props.pool.game_type}
                        id="closed"
                      >
                        FINALIZÓ
                      </span>
                    </Link>
                  </Button>
                </div>
              );
            } else {
              tag = (
                <div
                  data-id={this.props.pool.id}
                  data-game-type={this.props.pool.game_type}
                  className="btn-pool-wrap"
                >
                  <Button
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    id="closed"
                    className="btn-pool registrate btn-rojo registrado-cerrada"
                    bsStyle="warning"
                  >
                    <Link to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}`}>
                      <span
                        data-id={this.props.pool.id}
                        data-game-type={this.props.pool.game_type}
                        id="closed"
                      >
                        FINALIZÓ
                      </span>
                    </Link>
                  </Button>
                </div>
              );
            }
          } else if (
            this.props.pool.status !== 'closed' &&
            this.props.pool.entries !== undefined &&
            this.props.pool.entries.length > 0
          ) {
            tag = (
              <ButtonGroup
                data-game-type={this.props.pool.game_type}
                data-id={this.props.pool.id}
                className="btn-pool-wrap"
              >
                <Button
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  className="btn-registrado"
                  bsStyle="primary"
                  id="redirect"
                >
                  <Link
                    to={{
                      pathanme: `/${gameType}/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`,
                    }}
                  >
                    <span
                      data-id={this.props.pool.id}
                      data-game-type={this.props.pool.game_type}
                      id="redirection"
                    >
                      MIS PICKS
                    </span>
                  </Link>
                </Button>
                {this.props.pool.status === 'live' ? null : (
                  <Button
                    data-tip
                    data-for="cancelRegister"
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    className="btn-no-back"
                    id="cancel"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.updateStateFuncCancel(this.props.pool);
                    }}
                  >
                    <i
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      // src={cancel}
                      id={'cancel'}
                      className="ion-close-circled"
                    />
                  </Button>
                )}
              </ButtonGroup>
            );
          } else if (this.props.pool.status === 'live') {
            tag = (
              <div
                data-game-type={this.props.pool.game_type}
                data-id={this.props.pool.id}
                className="btn-pool-wrap"
              >
                <Button
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  id="upcoming"
                  className="  btn-pool btn-registrate btn btn-info"
                  bsStyle="warning"
                >
                  <Link to={`quiniela/${this.props.pool.slug}/${this.props.pool.id}`}>
                    <span
                      data-id={this.props.pool.id}
                      data-game-type={this.props.pool.game_type}
                      id="live"
                    >
                      EN CURSO
                    </span>
                  </Link>
                </Button>
              </div>
            );
          } else {
            tag = (
              <div
                data-id={this.props.pool.id}
                data-game-type={this.props.pool.game_type}
                className="btn-pool-wrap"
              >
                <Button
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  id="closed"
                  className="btn-pool registrate btn-lleno registrado-cerrada"
                  bsStyle="warning"
                >
                  <Link to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}`}>
                    <span
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      id="closed"
                    >
                      LLENA
                    </span>
                  </Link>
                </Button>
              </div>
            );
          }
        } else if (
          /**
           *IS REGISTERED
           */
          this.props.pool.entries !== null ||
          this.props.pool.bracket_entries !== null
        ) {
          if (
            (this.props.pool.entries && this.props.pool.entries.length > 0) ||
            (this.props.pool.bracket_entries && this.props.pool.bracket_entries.length > 0)
          ) {
            //Si esta registrado
            if (this.props.pool.closed) {
              tag = (
                <div
                  data-id={this.props.pool.id}
                  data-game-type={this.props.pool.game_type}
                  className="btn-pool-wrap"
                >
                  {this.props.logged === false ? (
                    <Button
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      id="upcoming"
                      className="btn-pool registrate registrado-cerrada"
                      bsStyle="warning"
                    >
                      <Link to={`ingresar`}>
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="upcoming"
                        >
                          EN CURSO
                        </span>
                      </Link>
                    </Button>
                  ) : (
                    <Button
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      id="closed"
                      className="btn-pool registrate btn-rojo registrado-cerrada"
                      bsStyle="warning"
                    >
                      <Link to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}`}>
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="closed"
                        >
                          FINALIZÓ
                        </span>
                      </Link>
                    </Button>
                  )}
                </div>
              );
            } else if (this.props.pool.status === 'upcoming') {
              tag = (
                <ButtonGroup
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  className="btn-pool-wrap"
                >
                  <Button
                    data-game-type={this.props.pool.game_type}
                    data-tip
                    data-for="reRegister"
                    data-id={this.props.pool.id}
                    className="btn-no-back"
                    id="registerInner"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.updateStateFunc(e, this.props.pool);
                    }}
                  >
                    <i
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      // src={refresh}
                      className={'ion-plus-circled'}
                      id="registerInner"
                    />
                  </Button>

                  <Button
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    className="btn-registrado"
                    bsStyle="primary"
                    id="redirect"
                  >
                    {(this.props.pool.type === 'perfect') ?
                      <Link
                        to={`/bracketPerfect/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    : (this.props.pool.type === 'points') ?
                      <Link
                        to={`/bracketPoints/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    :
                      <Link
                        to={`/${gameType}/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    }
                  </Button>
                  <Button
                    data-tip
                    data-game-type={this.props.pool.game_type}
                    data-for="cancelRegister"
                    data-id={this.props.pool.id}
                    className="btn-no-back"
                    id="cancel"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.updateStateFuncCancel(this.props.pool);
                    }}
                  >
                    <i
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      // src={cancel}
                      id={'cancel'}
                      className="ion-close-circled"
                    />
                  </Button>
                </ButtonGroup>
              );
            } else {
              tag = (
                <div
                  data-id={this.props.pool.id}
                  data-game-type={this.props.pool.game_type}
                  className="btn-pool-wrap"
                >
                  <Button
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    id="redirect"
                    className=" btn-pool btn-registrate registrado"
                  >
                    {this.props.logged === false ? null : this.props.pool.game_type === 'pool' ? (
                      <Link
                        to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    ) : this.props.pool.game_type === 'survivor' ? (
                      <Link
                        to={`/survivor/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    ) : this.props.pool.game_type === 'group_pool' ? (
                      <Link
                        to={`/quinielaGrupo/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    ) : (
                      <Link
                        to={`/bracket/${this.props.pool.slug}/${this.props.pool.id}/${this.props.pool.first_entry.id}`}
                      >
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                          id="redirection"
                        >
                          MIS PICKS
                        </span>
                      </Link>
                    )}
                  </Button>
                </div>
              );
            }
          } else {
            /**
             * NOT REGISTERED
             */
            // console.log('Noot registered button' );
            /**
             *CLOSED
             */
            if (this.props.pool.status === 'closed') {
              tag = (
                <div
                  data-id={this.props.pool.id}
                  data-game-type={this.props.pool.game_type}
                  className="btn-pool-wrap"
                >
                  {this.props.logged === false ? null : (
                    <Button
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      id="closed"
                      className="btn-rojo btn-pool registrate"
                      bsStyle="warning"
                    >
                      {this.props.pool.game_type === 'pool' ? (
                        <Link to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}`}>
                          <span
                            data-id={this.props.pool.id}
                            data-game-type={this.props.pool.game_type}
                            id="closed"
                          >
                            FINALIZÓ
                          </span>
                        </Link>
                      ) : (
                        <Link to={`/${gameType}/${this.props.pool.slug}/${this.props.pool.id}`}>
                          <span
                            data-id={this.props.pool.id}
                            data-game-type={this.props.pool.game_type}
                            id="closed"
                          >
                            FINALIZÓ
                          </span>
                        </Link>
                      )}
                    </Button>
                  )}
                </div>
              );
            } else if (this.props.pool.status === 'live') {
              /**
               *LIVE
               */
              tag = (
                <div
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  className="btn-pool-wrap"
                >
                  <Button
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    className=" btn-pool btn-registrate"
                    bsStyle={'info'}
                  >
                    {this.props.logged === false ? (
                      <Link to={`ingresar`}>
                        <span
                          data-game-type={this.props.pool.game_type}
                          data-id={this.props.pool.id}
                        >
                          EN CURSO
                        </span>
                      </Link>
                    ) : this.props.pool.game_type === 'pool' ? (
                      <Link to={`/quiniela/${this.props.pool.slug}/${this.props.pool.id}`}>
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                        >
                          EN CURSO
                        </span>
                      </Link>
                    ) : this.props.pool.game_type === 'bracket' ? (
                      <Link to={`/bracket/bracket/${this.props.pool.id}`}>
                        <span
                          data-id={this.props.pool.id}
                          data-game-type={this.props.pool.game_type}
                        >
                          EN CURSO
                        </span>
                      </Link>
                    ) : (
                      <Link to={`/survivor/survivor/${this.props.pool.id}`}>
                        <span
                          data-game-type={this.props.pool.game_type}
                          data-id={this.props.pool.id}
                        >
                          EN CURSO
                        </span>
                      </Link>
                    )}
                  </Button>
                </div>
              );
            } else {
              /**
               *UPCOMING
               */
              /**
               *SPECIAL BUTTON
               */
              if (newUserButton) {
                tag = (
                  <div
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    className="btn-pool-wrap"
                  >
                    <Button
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      className="btn-pool btn-registrate curso btn-new-user "
                      bsStyle="success"
                      id="registerPopUp"
                    >
                      <div
                        data-game-type={this.props.pool.game_type}
                        data-id={this.props.pool.id}
                        className="inner-button"
                        id="registerPopUp"
                      >
                        <img
                          data-game-type={this.props.pool.game_type}
                          data-id={this.props.pool.id}
                          src={premios}
                          className="navbar-icons"
                        />
                        <span
                          data-game-type={this.props.pool.game_type}
                          data-id={this.props.pool.id}
                        >
                          JUEGA GRATIS
                        </span>
                      </div>
                    </Button>
                  </div>
                );
              } else {
                /**
                 *NORMAL BUTTON
                 */
                // console.log('NormablButton ' );
                tag = (
                  <div
                    data-id={this.props.pool.id}
                    data-game-type={this.props.pool.game_type}
                    className="btn-pool-wrap"
                  >
                    <Button
                      data-game-type={this.props.pool.game_type}
                      data-id={this.props.pool.id}
                      className={`btn-pool ${
                        this.props.pool.registration_enabled ? 'btn-registrate' : 'btn-proximamente'
                      } curso`}
                      bsStyle="success"
                      id="registerPopUp"
                    >
                      <div
                        data-game-type={this.props.pool.game_type}
                        data-id={this.props.pool.id}
                        className="inner-button"
                        id="registerPopUp"
                      >
                        {this.props.pool.registration_enabled ? (
                          <img
                            data-game-type={this.props.pool.game_type}
                            data-id={this.props.pool.id}
                            src={palomaRegistro}
                            className="navbar-icons"
                          />
                        ) : (
                          <i
                            className="ion-android-alarm-clock nav-bar-icons"
                            style={{ marginRight: '3px' }}
                          />
                        )}
                        {this.props.pool.registration_enabled ? (
                          <span
                            data-game-type={this.props.pool.game_type}
                            data-id={this.props.pool.id}
                          >
                            REGÍSTRATE
                          </span>
                        ) : (
                          <span
                            data-game-type={this.props.pool.game_type}
                            data-id={this.props.pool.id}
                          >
                            PRÓXIMAMENTE
                          </span>
                        )}
                      </div>
                    </Button>
                  </div>
                );
              }
            }
          }
        } else {
          // console.log('NormablButton Alt' );
          tag = (
            <div
              data-game-type={this.props.pool.game_type}
              data-id={this.props.pool.id}
              className="btn-pool-wrap"
            >
              <Button
                data-game-type={this.props.pool.game_type}
                data-id={this.props.pool.id}
                className="btn-pool btn-registrate curso"
                bsStyle="success"
                id="registerPopUp"
              >
                <div
                  data-game-type={this.props.pool.game_type}
                  data-id={this.props.pool.id}
                  className="inner-button"
                  id="registerPopUp"
                >
                  <img
                    data-game-type={this.props.pool.game_type}
                    data-id={this.props.pool.id}
                    src={palomaRegistro}
                    className="navbar-icons"
                  />
                  <span data-id={this.props.pool.id} data-game-type={this.props.pool.game_type}>
                    REGÍSTRATE
                  </span>
                </div>
              </Button>
            </div>
          );
        }
      } else {
        /**
         * NOT LOGGED
         */
        // console.log('not Logged', this.props );
        if (this.props.pool.status === 'closed') {
          tag = (
            <div
              data-game-type={this.props.pool.game_type}
              data-id={this.props.pool.id}
              className="btn-pool-wrap"
            >
              <Button
                data-game-type={this.props.pool.game_type}
                data-id={this.props.pool.id}
                id="closed"
                className="btn-pool btn-rojo registrate"
                bsStyle="warning"
              >
                <span
                  data-id={this.props.pool.id}
                  data-game-type={this.props.pool.game_type}
                  id="closed"
                >
                  FINALIZÓ
                </span>
              </Button>
            </div>
          );
        } else if (this.props.pool.status === 'live') {
          tag = (
            <div
              data-id={this.props.pool.id}
              className="btn-pool-wrap"
              data-game-type={this.props.pool.game_type}
            >
              <Button
                data-game-type={this.props.pool.game_type}
                data-id={this.props.pool.id}
                className=" btn-pool btn-registrate"
                bsStyle="info"
              >
                <span data-id={this.props.pool.id}>EN CURSO</span>
              </Button>
            </div>
          );
        } else if (this.props.pool.limited_capacity && this.props.pool.is_full) {
          tag = (
            <div className="btn-pool-wrap">
              <Button
                className="btn-pool registrate btn-lleno registrado-cerrada"
                bsStyle="warning"
                onClick={this.props.showLoginPopUp}
              >
                <span id="closed">LLENA</span>
              </Button>
            </div>
          );
        } else {
          tag = (
            <div className="btn-pool-wrap">
              <Button
                id="login"
                onClick={this.props.showLoginPopUp}
                className="btn-pool btn-registrate curso"
                bsStyle="success"
              >
                <i id="login" className="ion-log-in" />
                <span id="login">INGRESAR</span>
              </Button>
            </div>
          );
        }
      }
    }
    return tag;
  }
}

export default DisplayEstatus;
