import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Components
import Text from '../../Text'
import BarLevel from './BarLevel'
import Link from './Link'


function LevelChart (props) {
  const { level, lastLevel, points = {} } = props.userLoyalty
  const currentProgress = ((points.total - level.min_points)/ (level.max_points - level.min_points)) * 100
  const imageNextLevel = require(`../../../assets/img/levels/current/level-${level.id}.png`)

  return (
    <Panel>
      <Text
        bold
        size={22}
        mb={10}
        style={{ textTransform: 'uppercase' }}
      >
        Mi nivel: {lastLevel.name}
      </Text>
      <Current>
        <img src={imageNextLevel} />
      </Current>
      <BarLevel progress={currentProgress} />
      <Text mb={5} mt={25}>{points.total} / {level.max_points} puntos para el próximo nivel</Text>
      <Link href='/landing/programa-de-lealtad'>Ver niveles</Link>
    </Panel>
  )
}


const Panel = styled.div`
  padding: 1em;
`

const Current = styled.div`
  img {
    display: block;
    margin: 0 auto;
    max-width: 12em;
  }
`

export default LevelChart
