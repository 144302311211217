import styled from 'styled-components'
import React from 'react'

// Hooks
import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

Box.defaultProps = {}

Box.propTypes = {
  ...shorthandProptypes
}

export default function Box (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  return (
    <BoxBase
      style={{
        ...stylesShortcuts,
        maxWidth: props.maxWidth || 'initial'
      }}
    >
      {props.children}
    </BoxBase>
  )
}

const BoxBase = styled.div`
`
