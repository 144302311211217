
const STEPS = [
  'WELCOME',
  'SEARCH',
  'CREATE_TYPE',
  'SURVIVOR_NAME',
  'SURVIVOR_SPORT',
  'SURVIVOR_DURATION',
  'SURVIVOR_PRICE',
  'SURVIVOR_AWARDS',
  'SURVIVOR_SUCCESS'
]

const initialSurvivor = {
  point_format: 'natural',
  prizes_list: '3',
  sport_id: 0,
  name: '',
  password: '',
  limit_multientries: false,
  max_multientries: 0,
  affiliate_commission: 0,
  entry_fee: null,
  strikes_enabled: true,
  max_strikes: 3,
  starting_week: null,
  prize_scheme: 'dynamic', // dynamic | custom
  survivor_prizes: [],
  tournament_instances: {
    _ids: [0]
  }
}


export const survivorSlice = (set) => ({
  step: STEPS[0],
  survivorData: initialSurvivor,
  activeTournamentID: null,
  createdSurvivor: null,
  currentStepNumber: 1,
  close: () => set({ showWizard: false, step: STEPS[0], survivorData: initialSurvivor, currentStepNumber: 1 }),
  open: () => set({ showWizard: true }),
  setActiveTournamentID: (activeTournamentID) => set({ activeTournamentID }),
  setSurvivorData: (nextState) => set(state => ({ survivorData: { ...state.survivorData, ...nextState } })),
  setCreatedSurvivor: (createdSurvivor) => set({ createdSurvivor })
})
