import React from 'react';

import {
  Button,  
  Modal
} from 'react-bootstrap/lib';

import PieChart from 'react-simple-pie-chart';

import ReactTooltip from 'react-tooltip';

import avatarDefault from '../../assets/images/avatar-50.png';

import referalActive from '../../assets/images/referal-active.png';
import referalInactive from '../../assets/images/referal-inactive.png';
import referalInvited from '../../assets/images/referal-invited.png';
import referalCompleted from '../../assets/images/checked.png';
import potWinnings from '../../assets/images/cash3-50.png';
import winnings from '../../assets/images/ganancias-50.png';

import numeral from 'numeral';
import moment from 'moment';
import thenBy from 'thenby';

import API from '../../utils/api';
import branchUtils from '../../utils/branchUtils';
import utils from '../../utils/utils';

import retoIcon from '../../assets/images/vsIconHalf-50.png';

import ReferralProgressBar from '../UserInfo/Account/components/Referrals/ReferralProgressBar';

import PanelInvitaJugar from '../PanelInvitaJugar';
import { ShareButtons } from 'react-share';
import $ from 'jquery';
import _ from 'underscore';

export const multiplier = 1000;

export const maxQntyPlayed = 35000;
export const maxQntyPrize = 75000;

const {
  FacebookShareButton,  
  TwitterShareButton  
} = ShareButtons;

/**
 * Renders The Panel Of MultipleReminders of the Referrer
 * @param selectedReferer       { Object }
 * @param title                 { String }
 * @param closePanel            { Function }
 * @param openPanelJugar        { Function }
 * @param remimndPlay           { Function }
 * @param remimndDeposit        { Function }
 * @param linkBranch            { String }
 * @param isLinkReady           { Boolean }
 * @param updateBranchLink      { Function }
 */
const PanelMultipleReminder = ({
  selectedReferrer,
  title,
  showPanel,
  closePanel,
  openPanelJugar,  
  branchLink,
  isLinkReady,
  type,
  updateBranchLink,
  getBranchLinkRemainder,
  whatsAppReminder,
}) => {
  let typeCopy = '';
  if (type === 'bono') {
    typeCopy =
      'Te recuerdo que tu primer depósito se duplica hasta $300 pesos! Aprovecha y empieza a jugar… ';
  } else if (type === 'firstQuina') {
    typeCopy = 'No te he visto en las quinielas, ¿le vas a entrar a las de esta semana?';
  }
  return (
    <Modal
      show={showPanel}
      bsSize="sm"
      backdropClassName="inviteFriendsBackdrop"
      dialogClassName="inviteFriendsBackdrop"
      onHide={() => {
        closePanel();
      }}
    >
      <div className="panel-abonar">
        <div id="panelTitle" className="panel-abonar-titulo">
          <span>
            <i className="ion-android-notification" />
            {title}
          </span>
          <button
            className="cerrar-panel"
            onClick={() => {
              closePanel();
            }}
          >
            <i className="ion-ios-close-empty" />
          </button>
        </div>
      </div>
      <div className="flex-col col-center panel-pickwin">
        <div className="flex-row row-center">
          <strong className="flex-col col-center text-center">
            {' '}
            ¿Cómo quieres reactivar a tu referido?
          </strong>
        </div>
        <div className="flex-row row-no-padding" style={{ margin: '10px' }}>
          <div className="flex-col">
            <div className="flex-row row-center">
              <Button
                onClick={() => {
                  getBranchLinkRemainder('bono').then((newLink) => updateBranchLink(newLink));
                }}
                className=" flex-col col-offset-20 col-60 btn-reminder"
              >
                Recordárle del bono por depósito
              </Button>
            </div>
            <div className="flex-row row-center">
              <Button
                className=" flex-col col-offset-20 col-60 btn-reminder"
                onClick={() => {
                  closePanel();
                  openPanelJugar(selectedReferrer);
                }}
              >
                Reta / Invitar a jugar
              </Button>
            </div>
          </div>
        </div>
        {isLinkReady ? (
          <div className="flex-row row-no-padding" style={{ margin: '10px' }}>
            <div className="col-xs-12">
              <div className="detalles-privadas-compartir-container">
                <div className="col-xs-12">
                  <div className="text-center" style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                    {type === 'bono'
                      ? `Recuerda sobre el bono por depósito a ${
                          !selectedReferrer ? 'tu referido' : selectedReferrer.nick
                        }`
                      : `Recuerda a ${
                          !selectedReferrer ? 'tu referido' : selectedReferrer.nick
                        } que juegue su primera quiniela.`}
                  </div>
                  <div className="flex-row row-center flex-container justify">
                    <div className="codigo-amigo flex-col">
                      <input
                        id="copiar-clipboard"
                        style={{ fontSize: '1.1em' }}
                        value={
                          branchLink !== undefined
                            ? branchLink
                            : `https://www.pickwin.mx/?referer=${utils.getUserInfo().referalCode}`
                        }
                      />
                    </div>
                    <div className="copiar-button-codigo flex-col col-15">
                      <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                        Copia tú código y compártelo
                      </ReactTooltip>
                      <button
                        data-tip
                        data-for="copiar-tool"
                        className="copiar-btn"
                        data-clipboard-target="#copiar-clipboard"
                      >
                        <i className="ion-ios-copy " />
                        <span className="visible-md">Copiar</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="btn-group inviteToPlay flex-row">
                  <FacebookShareButton
                    quote={typeCopy}
                    hashtag={'#Pickwin #registrate #JalaTusPicks'}
                    url={
                      branchLink !== undefined
                        ? branchLink
                        : `https://www.pickwin.mx/?referer=${utils.getUserInfo().referalCode}`
                    }
                  >
                    <Button className="fb-btn">
                      Por Facebook <i className="ion-social-facebook" />
                    </Button>
                  </FacebookShareButton>
                  <TwitterShareButton
                    title={typeCopy}
                    url={
                      branchLink !== undefined
                        ? branchLink
                        : `https://www.pickwin.mx/?referer=${utils.getUserInfo().referalCode}`
                    }
                    hasthag={'#Pickwin #registrate #JalaTusPicks'}
                  >
                    <Button className="twitter-btn">
                      {' '}
                      Por Twitter <i className="ion-social-twitter" />
                    </Button>
                  </TwitterShareButton>
                  <div className="SocialMediaShareButton">
                    <Button
                      className="whatsapp-btn"
                      onClick={() => {
                        console.log(typeCopy);
                        whatsAppReminder(branchLink, type, typeCopy);
                      }}
                    >
                      Por WhatsApp <i className="ion-social-whatsapp" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="btn-wrap">
                <Button
                  className="btn-rojo gracias"
                  onClick={() => {
                    closePanel();
                  }}
                >
                  No, gracias
                </Button>
              </div>
            </div>
          </div>
        ) : null}
        {isLinkReady ? <div></div> : null}
      </div>
    </Modal>
  );
};

/**
 * Component that handles the selected
 * @param changeSection  { function }
 * @param activeSection  { Number }
 * @return   Component
 */
// const NavReferrals = ({ changeSection, activeSection }) => {
//   return (
//     <div id="referralsNav" className="flex-row  row-center">
//       <div
//         className={`referalsNavItem flex-col ${activeSection === '2' ? 'active' : ''}`}
//         onClick={() => {
//           changeSection(2);
//         }}
//       >
//         Todos
//       </div>
//       <div
//         className={`referalsNavItem flex-col ${activeSection === '3' ? 'active' : ''}`}
//         onClick={() => {
//           changeSection(3);
//         }}
//       >
//         Activos
//       </div>
//       <div
//         className={`referalsNavItem flex-col ${activeSection === '4' ? 'active' : ''}`}
//         onClick={() => {
//           changeSection(4);
//         }}
//       >
//         Inactivos
//       </div>
//       <div
//         className={`referalsNavItem flex-col ${activeSection === '5' ? 'active' : ''}`}
//         onClick={() => {
//           changeSection(5);
//         }}
//       >
//         Completo
//       </div>
//     </div>
//   );
// };

/**
 * Renders the Item of the Summary currenty selected
 * @param amount        { Number }
 * @param title         { String }
 * @param size          { Number }
 * @param format        { Boolean }
 * @param tooltipLabel  { String }
 * @return Component
 */
export const SummaryItem = ({
  activeSection,
  amount,
  title,
  size,
  format,
  tooltipLabel,
  isClickable,
  icon,
  handleClick,
}) => {
  // console.log('SummaryItem');
  let styleStatus = '';
  let section = null;
  if (title === 'Activos') {
    styleStatus = 'active';
    section = 3;
  } else if (title === 'Inactivos') {
    styleStatus = 'inactive';
    section = 4;
  } else if (title === 'Completados') {
    styleStatus = 'complete';
    section = 5;
  } else if (title === 'Invitados') {
    section = 2;
  } else if (title === 'G. Potenciales' || title === 'Ganancias Potenciales') {
    styleStatus = 'potential';
  }
  // console.log('SummaryItem');
  return (
    <div
      className={`flex-col stats-item
    ${activeSection === section ? 'active' : ''}
    col-center col-${size} ${isClickable ? 'clickable' : ''}`}
      onClick={
        !handleClick
          ? null
          : () => {
              handleClick(section);
            }
      }
    >
      <div className="flex-row stats-title row-no-padding">
        <div className="flex-col col-no-padding">{title}</div>
      </div>
      <div className="flex-row row-no-padding  stats-data">
        <div className="flex-col col-center text-center">
          <div className={` flex-container align justify ${styleStatus} `}>
            <div>{format ? numeral(amount).format('$0,000') : amount}</div>
            {!icon ? null : (
              <div id="btn-background" className="">
                <img src={icon} className="summary-icon flex-col col-center" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the Title ot the selected section
 * @param label           { String }
 * @param count           { Number }
 * @param activeSection   { Number }
 * @return Component
 */
// const TitleSection = ({ label, count, activeSection }) => {
//   let styleStatus = '';

//   switch (activeSection) {
//     case 3:
//       styleStatus = 'active';
//       break;
//     case 4:
//       styleStatus = 'inactive';
//       break;
//     case 5:
//       styleStatus = 'complete';
//       break;
//   }

//   return (
//     <div className="flex-row row-center">
//       <div className="stats-item">
//         <span className="stats-title">{label}:</span>
//       </div>
//       <div className="stats-item">
//         <span className={`stats-data ${styleStatus}`}> {count} </span>
//       </div>
//     </div>
//   );
// };

/**
 * Render the earnings of the Active Referal given the MaxPrizes
 * @param referal       { Object }
 * @param maxPrizes     { Number }
 * @return Component
 */
// const StatsPerReferrer = ({ referal, totalReferals }) => {
//   // console.log('referal');
//   // console.log(referal);
//   let progress = (referal.amount_given / maxQntyPrize) * totalReferals;

//   let remaining = 100 - progress;

//   return (
//     <div className="flex-row box-summary">
//       <SummaryItem title="Ganancias" amount={referal.amount_given / 100} size="30" format />
//       <SummaryItem
//         title={$(window).width() > 669 ? 'Ganancias Potenciales' : 'G. Potenciales'}
//         amount={(maxQntyPrize - referal.amount_given) / 100}
//         size="50"
//         format
//       />
//       <PieProgress progress={progress} remaining={remaining} />
//     </div>
//   );
// };

/**
 * This component renders the prgress of the referrer in a pie chart
 * @param show       { boolean }
 * @param progress  { Number }
 * @param remaingin { Number }
 * @return Component
 */
export const PieProgress = ({ show, progress, remaining }) => {
  // console.log(progress, remaining)
  if (!show) {
    return null;
  } else {
    return (
      <div className="flex-col col-no-padding hidden-sm hidden-xs">
        <div className="flex-row row-no-padding">
          {$(window).width() > 669 ? (
            <div className="flex-col col-center col-no-padding">
              <div className="flex-row indicator-progress row-no-padding">
                <div className="flex-col col-no-padding col-10 color" />
                <div className="flex-col col-no-padding ">Progreso</div>
              </div>
              <div className="flex-row indicator-remaining row-no-padding">
                <div className="flex-col col-no-padding col-10 color" />
                <div className="flex-col col-no-padding ">Faltante</div>
              </div>
            </div>
          ) : null}
          <div className="flex-col col-no-padding">
            <PieChart
              slices={[
                { color: '#7bd900', value: progress },
                { color: '#6a6a6a', value: remaining },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
};

/**
 * Renders the Container of the Stats Section
 * @param activeSection { Number }
 * @param referralsList { Array }
 * @param sectionLabel  { String }
 * @return Component
 */
// const SummaryStats = ({ activeSection, referralsList, sectionLabel }) => {
//   if (sectionLabel === 'Histórico') {
//     return null;
//   }
//   let statIcon = null;

//   // console.log(referralsList);

//   let totalPrizes = _.reduce(referralsList, (acc, referal) => acc + referal.levels_completed, 0);

//   let totalEarning = _.reduce(referralsList, (acc, referal) => acc + referal.amount_given, 0);
//   // console.log(totalEarning);
//   let potentialEarnings = maxQntyPrize * referralsList.length - totalEarning;

//   return (
//     <div id="summaryStatsSection" className="flex-col">
//       <div className="flex-row">
//         {sectionLabel === 'Activos' && sectionLabel !== 'Todos' && sectionLabel !== 'Histórico' ? (
//           <SummaryItem
//             title="Ganancias"
//             amount={totalEarning / 100}
//             size="35"
//             format
//             tooltipLabel="Los premios que ha ganado el usuario por sus referidos."
//           />
//         ) : null}
//         {sectionLabel !== 'Todos' && sectionLabel !== 'Histórico' ? (
//           <SummaryItem
//             title={sectionLabel === 'Completo' ? 'Ganancias Obtenidas' : 'Ganancias Potenciales'}
//             amount={potentialEarnings / 100}
//             size={sectionLabel === 'Ganancias Potenciales' ? '65' : '100'}
//             format
//             tooltipLabel="Los premios que puede ganar el usuario si el total de sus invitados juega el máximo."
//           />
//         ) : null}
//       </div>
//     </div>
//   );
// };

/**
 * Renders the Button and manages the Actions and icon
 * from status of the referred_user
 * @param referal           { Object }
 * @param handleInvitePanel { Function }
 * @param handleRemindPanel { Function }
 * @return Component
 */
const ButtonAction = ({ referal, handleInvitePanel, handleRemindPanel }) => {
  let status = '';
  let label = '';

  let iconButton = null;

  /**
   * Activos
   * Invitar y retar
   */
  if (referal.is_active) {
    iconButton = retoIcon;
    status = 'activeReferrer';
    label = 'Retar';
  } else if (!referal.is_active && !referal.finished) {
    /**
     * Inactivos
     * Multiple Option Panel
     */
    iconButton = 'ion-android-notifications-none';
    status = 'inactiveReferrer';
    label = 'Recordar';
  } else {
    /**
     * Completados
     * Seguir invitando y retando
     */
    iconButton = retoIcon;
    status = 'activeReferrer';
    label = 'Retar';
  }

  return (
    <div className="flex-col">
      <div className="flex-row responsive-sm row-no-padding">
        <Button
          onClick={() => {
            if (status === 'activeReferrer') {
              handleInvitePanel(referal.referred_user);
            } else {
              handleRemindPanel(referal.referred_user);
            }
          }}
          className={`flex-col text-center btn-action-referrer col-no-padding col-80 col-offset-20 ${status}`}
        >
          <div className="flex-container justify align">
            {$(window).width() > 669 ? label : ''}
            {label === 'Retar' ? (
              <img src={iconButton} className="pool-icon" />
            ) : (
              <i className={iconButton}></i>
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

/**
 * Renders the Prizes / Trophys Obtained by the user from the referrer
 * @param steps       { Array }
 * @param referal     { Object }
 * @return Component
 *
 */
// const PrizesGiven = ({ progress, steps, remaining, referal, max }) => {
//   // console.log(progress, remaining,referal, max);
//   let listPrizes = steps.map((step, i) => {
//     return (
//       <div key={step.prize} className="trophy-item">
//         <div data-tip data-for={referal.id + '-' + i} className="  flex-row row-no-padding">
//           <i
//             className={`ion-ribbon-b col-no-padding flex-col text-center col-center ${
//               i < referal.levels_completed ? 'completed' : 'notCompleted'
//             }`}
//           />
//         </div>
//         <ReactTooltip id={referal.id + '-' + i} place="bottom" type="light" effect="solid">
//           {numeral(step.prize).format('$0,000')}
//         </ReactTooltip>
//       </div>
//     );
//   });
//   // console.log(listPrizes);
//   return <div className="flex-row trophy-prizes">{listPrizes}</div>;
// };

/**
 * Renders the List of the invited users
 * @param referralsList { Array }
 * @param viewProgress  { boolean }
 * @return Component
 */
const ListInvited = ({
  sectionLabel,
  activeSection,
  referralsList,
  viewProgress,
  handleViewProgress,
  handleInvitePanel,
  handleRemindPanel,
}) => {
  // console.log(referralsList);

  let listInvited = referralsList.map((referal) => {
    let iconClass = null;

    if (referal.is_active) {
      iconClass = 'ion-android-walk';
    } else if (!referal.finished && !referal.active) {
      iconClass = 'ion-close-circled';
    } else {
      iconClass = 'ion-checkmark-circled';
    }

    let status = 'Activo';
    if (!referal.is_active && !referal.finished) {
      status = 'Inactivo';
    } else if (referal.finished) {
      status = 'Completo';
    }
    let showProgress = null;
    if (!viewProgress) {
      showProgress = false;
    } else {
      showProgress = viewProgress.find(
        (viewProgress) => referal.id === viewProgress.id && viewProgress.view
      );
    }

    // const progress = `${ Math.ceil(referal.amount_given / maxQntyPrize * referralsList.length ) }%`;
    const progress = `${Math.ceil(referal.progress)}%`;

    //Referal Progress Spend in percentage MAX ( 3000 )
    const progressReferedEntries = `${referal.amount_played / maxQntyPlayed}%`;

    let conditionalComponent = null;
    console.log(progress);

    if (activeSection === 2) {
      conditionalComponent = (
        <div key={referal.id} className="flex-row  row-no-padding">
          <div className="flex-col invited-item">
            <div className="flex-row row-center ">
              <div
                className={`flex-col ${
                  $(window).width() < 669 ? 'col-20' : 'col-15'
                } col-no-padding invited-nick col-center text-center`}
              >
                <div className="flex-row row-no-center row-center">
                  <div className="flex-col  col-center text-center">
                    <img
                      className="avatarIcon "
                      src={!referal.avatar ? avatarDefault : referal.avatar_dir + referal.avatar}
                    />
                  </div>
                </div>
                <div className="flex-row row-no-center row-center">
                  <strong
                    className=" nick-invited flex-col col-center text-center col-no-padding"
                    style={{ fontSize: '90%' }}
                  >
                    {referal.referred_user.nick}
                  </strong>
                </div>
              </div>
              <SummaryItem
                icon={winnings}
                title="Ganancias"
                amount={referal.amount_given / 100}
                size="30"
                format
              />
              <SummaryItem
                icon={potWinnings}
                title={$(window).width() > 669 ? 'Ganancias Potenciales' : 'G. Potenciales'}
                amount={(maxQntyPrize - referal.amount_given) / 100}
                size="35"
                format
              />
              <div
                className={`flex-col invited-action responsive-sm
                      ${$(window).width() < 669 ? 'col-15' : 'col-20'}
                       text-center col-center`}
              >
                {status === 'Completedo' ? null : (
                  <ButtonAction
                    referal={referal}
                    handleInvitePanel={handleInvitePanel}
                    handleRemindPanel={handleRemindPanel}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      conditionalComponent = (
        <div key={referal.id} className="flex-row  row-no-padding">
          <div className="flex-col invited-item">
            <div className="flex-row row-center ">
              <div
                className={`flex-col  col-no-padding invited-nick col-center text-center ${
                  $(window).width() < 669 ? 'col-20' : 'col-15'
                } `}
              >
                <div className="flex-row row-no-center row-center">
                  <div className="flex-col  col-center text-center">
                    <img
                      className="avatarIcon "
                      src={!referal.avatar ? avatarDefault : referal.avatar_dir + referal.avatar}
                    />
                  </div>
                </div>
                <div className="flex-row row-no-center row-center">
                  <strong
                    className="flex-col col-center text-center col-no-padding"
                    style={{ fontSize: '90%' }}
                  >
                    {referal.referred_user.nick}
                  </strong>
                </div>
              </div>
              <SummaryItem
                icon={winnings}
                title="Ganancias"
                amount={referal.amount_given / 100}
                size="30"
                format
              />
              <SummaryItem
                icon={potWinnings}
                title={$(window).width() > 669 ? 'Ganancias Potenciales' : 'G. Potenciales'}
                amount={(maxQntyPrize - referal.amount_given) / 100}
                size="35"
                format
              />
              <div
                className={`flex-col invited-action responsive-sm
                  ${$(window).width() < 669 ? 'col-15' : 'col-20'}
                   text-center col-center`}
              >
                {status === 'Completedo' ? null : (
                  <ButtonAction
                    referal={referal}
                    handleInvitePanel={handleInvitePanel}
                    handleRemindPanel={handleRemindPanel}
                  />
                )}
              </div>
            </div>
            <ActiveProgres
              status={status}
              show={showProgress}
              referal={referal}
              progress={progress}
              progressReferedEntries={progressReferedEntries}
              stepsProgress={[
                { percentage: 25, prize: 50 },
                { percentage: 50, prize: 100 },
                { percentage: 75, prize: 200 },
                { percentage: 100, prize: 400 },
              ]}
              stepsReferedEntries={[
                { percentage: 3, prize: 100 },
                { percentage: 15, prize: 500 },
                { percentage: 50, prize: 1500 },
                { percentage: 100, prize: 3000 },
              ]}
            />
          </div>
        </div>
      );
    }

    return conditionalComponent;
  });

  // console.log(listInvited);

  return (
    <div id="listInvited" className=" flex-col col-no-padding">
      <div className={`overflow overflow-y overflow-380`}>
        {listInvited.length > 0 ? (
          listInvited
        ) : (
          <div className="flex-container justify align">
            <strong className="flex-col col-center text-center">No tienes ningún referido</strong>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * This Component the container of the Stats Section
 * @param newReferrals { Object }
 * @return Component
 */
const ReferrerStats = ({ activeSection, newReferrals, handleClick }) => {
  let allEarnings = _.reduce(newReferrals, (acc, referal) => acc + referal.amount_given, 0);

  // console.log('allEarnings');
  // console.log(newReferrals);

  let potentialEarnings = maxQntyPrize * newReferrals.length - allEarnings;

  let allPotentialEarnings = maxQntyPrize * newReferrals.length;

  let activeReferrals = newReferrals.filter((referal) => referal.is_active && !referal.finished);
  let inActiveReferrals = newReferrals.filter((referal) => !referal.is_active && !referal.finished);
  let completedReferrals = newReferrals.filter((referal) => referal.finished);
  // console.log(activeReferrals, inActiveReferrals, completedReferrals);

  let progress = (allEarnings / allPotentialEarnings) * 100;

  // console.log(progress);

  let remaining = 100 - progress;
  // console.log(remaining);

  // console.log(SummaryItem);
  return (
    <div id="stats-referrer" className="flex-row row-no-padding">
      <div className="flex-col col-center col-no-padding">
        <div className="flex-row title-stats-list">Tus Estadísticas</div>
        <div className="stats-list box-summary flex-row row-no-padding row-center">
          <SummaryItem
            icon={winnings}
            title="Ganancias"
            amount={allEarnings / 100}
            size={$(window).width() > 669 ? '25' : '30'}
            format
            tooltipLabel="Los premios que ha ganado el usuario por sus referidos."
          />
          <SummaryItem
            icon={potWinnings}
            title="Ganancias Potenciales"
            amount={potentialEarnings / 100}
            size={$(window).width() > 669 ? '50' : '70'}
            format
            tooltipLabel="Los premios que puede ganar el usuario si el total de sus invitados juega el máximo."
          />
          {$(window).width() > 669 ? (
            <PieProgress show={newReferrals.length > 0} remaining={remaining} progress={progress} />
          ) : null}
        </div>
        <div className="stats-summary box-summary flex-row row-no-padding row-center">
          <SummaryItem
            activeSection={activeSection}
            handleClick={handleClick}
            isClickable
            icon={referalInvited}
            title="Invitados"
            amount={newReferrals.length}
            size="25"
            tooltipLabel="Todos los que has invitado y se registraron."
          />
          <SummaryItem
            activeSection={activeSection}
            handleClick={handleClick}
            isClickable
            icon={referalActive}
            title="Activos"
            amount={activeReferrals.length}
            size="20"
            tooltipLabel="Todos los registrados con depósito (1) y al menos un entry real."
          />
          <SummaryItem
            activeSection={activeSection}
            handleClick={handleClick}
            isClickable
            icon={referalInactive}
            title="Inactivos"
            amount={inActiveReferrals.length}
            size="20"
            tooltipLabel="Todos los registrados sin depósito."
          />
          <SummaryItem
            activeSection={activeSection}
            handleClick={handleClick}
            isClickable
            icon={referalCompleted}
            title="Completados"
            amount={completedReferrals.length}
            size="30"
            tooltipLabel="Todos los invitados que terminaron su proceso."
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the List of Historic Referrals
 * @param referralsList { Array }
 * @return Component
 */
const ListHistoric = ({ referralsList }) => {
  let listHistoric = referralsList.map((referal) => {
    return (
      <div key={referal.id} className="flex-row invited-item ">
        <div
          className="flex-col invited-month text-center"
          style={{ textTransform: 'capanel-pickwintalize' }}
        >
          {moment(referal.created).format('MMMM')}
        </div>
        <div className="flex-col invited-amount text-center">
          {numeral(referal.amount_given / 1000).format('$0,000')}
        </div>
      </div>
    );
  });

  return (
    <div id="listHistoric" className="flex-col col-center">
      <div className="flex-row row-cener headers">
        <div className="flex-col col-center text-center">Mes</div>
        <div className="flex-col col-center text-center">Ganancias</div>
      </div>
      <div className="overflow overflow-y overflow-380">{listHistoric}</div>
    </div>
  );
};

/**
 * Renders The History Section
 * @param show          { Boolean }
 * @param referralsList { Array }
 * @return Component
 */
// const HistorySection = ({ show, referralsList }) => {
//   if (!show) {
//     return null;
//   }
//   // console.log(referralsList);
//   let totalEarning = _.reduce(referralsList, (acc, referal) => acc + referal.amount_given, 0);
//   let potentialEarnings = maxQntyPrize * referralsList.length - totalEarning;

//   return (
//     <div id="historicSection" className="flex-col ">
//       <div className="flex-row row-no-padding">
//         <SummaryItem
//           title={'Ganancias'}
//           amount={totalEarning / 100}
//           size="50"
//           format
//           tooltipLabel="Los premios que ha ganado el usuario por sus referidos."
//         />
//         <div className="flex-col col-center">
//           <small>Los premios que ha ganado el usuario por sus referidos.</small>
//         </div>
//       </div>
//       <div className="flex-row row-no-padding">
//         <SummaryItem
//           title={$(window).width() > 669 ? 'Ganancias Potenciales' : 'G. Potenciales'}
//           amount={potentialEarnings / 100}
//           size="50"
//           format
//           tooltipLabel="Los premios que puede ganar el usuario si el total de sus invitados juega el máximo."
//         />
//         <div className="flex-col col-center">
//           <small>
//             Los premios que puede ganar el usuario si el total de sus invitados juega el máximo.
//           </small>
//         </div>
//       </div>
//       <div className="flex-row row-no-padding">
//         <div className="title-stats-list">Histórico:</div>
//       </div>
//       <div className="flex-row row-no-padding">
//         <ListHistoric referralsList={referralsList} />
//       </div>
//     </div>
//   );
// };
/**
 * Renders The active Progress Info
 * @param status { String }
 * @param show { Boolean}
 * @return Component
 */
const ActiveProgres = ({
  show,
  status,
  stepsProgress,
  stepsReferedEntries,
  progress,
  referal,
  progressReferedEntries,
}) => {
  if (status !== 'Activo') {
    return null;
  }

  return (
    <div id="activeProgress" className="flex-row">
      <div className="flex-col">
        <div className="flex-row responsive-sm">
          <div className="flex-col col-80 col-offset-10">
            <ReferralProgressBar
              referal={referal}
              progress={progressReferedEntries}
              steps={stepsReferedEntries}
              type={'referalEntries'}
            />
          </div>
        </div>
        <div className="flex-row responsive-sm">
          <div className="flex-col col-80  col-offset-10">
            <ReferralProgressBar
              referal={referal}
              progress={progress}
              steps={stepsProgress}
              type={'progress'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
class InvitedReferralList extends React.Component {
  constructor(props) {
    super(props);

    let tempReferrals = this.props.newReferrals;
    // console.log( this.props.newReferrals );

    let activeReferrals = tempReferrals.filter((referal) => referal.is_active && !referal.finished);
    activeReferrals = activeReferrals.sort(thenBy('amount_given', -1));

    let inactiveReferrals = tempReferrals.filter(
      (referal) => !referal.is_active && !referal.finished
    );

    let completedReferrals = tempReferrals.filter((referal) => referal.finished);

    let allReferrals = [...activeReferrals, ...inactiveReferrals, ...completedReferrals];

    this.state = {
      activeSection: 2,
      newReferrals: allReferrals,
      filteredReferrals: allReferrals,
      sectionLabel: 'Invitados',
      viewProgress: null,
      poolSelected: null,
      branchLink: null,
      linkReady: false,
      selectedReferrer: null,
      typeReminder: null,
      showReminder: false,
      showInviteJugar: false,
      showMailInvite: false,
      responseStatus: null,
      showInvitationResponse: null,
    };

    this.sendChallange = (friendId, poolInfo) => {
      console.log('Send Challenge', poolInfo);
      // onSelect( { userId, options } ) {
      const poolId = poolInfo.id;
      const href = `/quiniela/${poolInfo.slug}/${poolId}`;
      //const userId = friendId;
      const challengeData = { href: href, userId: friendId };

      API.inviteUserToPool(challengeData)
        .then((response) => {
          // console.log(response.data.success);
          if (response.message === 'ok') {
            this.setState({
              responseStatus: true,
              showInvitationResponse: true,
            });
          } else {
            this.setState({
              responseStatus: false,
              showInvitationResponse: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            responseStatus: false,
            showInvitationResponse: true,
          });
          console.error(error);
        });
    };

    // this.sendChallange = (userId, poolId) => {
    //
    //   console.log('-----Send Challange-----');
    //   let challangeData = {
    //     pool_id: poolId,
    //     user_id: userId
    //   };
    //   console.log(challangeData);
    //   API.postChallengeUser( challangeData )
    //     .then(response => {
    //       console.log(response.data.success);
    //       if (response.data.success === true) {
    //         this.setState({
    //           responseStatus: true,
    //           showInvitationResponse: true
    //         });
    //       } else {
    //         this.setState({
    //           responseStatus: false,
    //           showInvitationResponse: true
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       this.setState({
    //         responseStatus: false,
    //         showInvitationResponse: true
    //       });
    //       console.error(error);
    //     });
    // };

    this.getBranchLink = () => {
      return branchUtils.createLink(utils.getUserInfo(), 1234, 'pool');
    };

    this.getBranchLinkChallenge = (pool, gameType) => {
      return branchUtils.createLinkChallenge(utils.getUserInfo(), pool, gameType);
    };

    this.getBranchLinkRemainder = (typeReminder) => {
      this.setState({ typeReminder });
      return branchUtils.createLinkReminder(utils.getUserInfo(), typeReminder);
    };

    this.hideInvitationResponse = () => {
      this.setState({ showInvitationResponse: false, responseStatus: null });
    };

    this.openMailPanel = () => {
      this.setState({
        showMailInvite: true,
      });
    };

    this.getBranchLinkReferrer = () => {
      console.log('Get branchLink referer');
      return branchUtils.createLinkInviteReferrer(utils.getUserInfo());
    };

    this.updateBranchLink = (newLink) => {
      this.setState({
        branchLink: newLink,
      });
    };

    this.hideMailPanel = () => {
      this.setState({ showMailInvite: false, responseStatus: false });
    };

    this.hideInviteJugar = () => {
      this.setState({
        showInviteJugar: false,
        responseStatus: false,
      });
    };

    this.whatsAppInvitationReferer = (branchLink) => {
      console.log('WhatsApp Invitation Referrer');
      branchUtils.redirectNewReferrerToWhatsApp(branchLink, 'open');
    };

    this.whatsAppInvitationChallenge = (branchLink, pool) => {
      console.log('WhatsApp Invitation Challenge');
      branchUtils.redirectChallengeToWhatsApp(branchLink, 'open', pool);
    };

    this.whatsAppReminder = (branchLink, type, copyType) => {
      console.log('WhatsApp Invitation Challenge');
      branchUtils.redirectReminderToWhatsApp(branchLink, 'open', copyType);
    };

    this.openInviteJugar = (user) => {
      if (!user) {
        this.setState({
          showInviteJugar: true,
          responseStatus: null,
        });
      } else {
        this.setState({
          selectedReferrer: user,
          showInviteJugar: true,
        });
      }
    };

    /**
     * Handlers that control Reminder Panel
     * @param referer { Object }
     */
    this.showReminder = (referrer) => {
      console.log(referrer);
      this.setState({ showReminder: true, selectedReferrer: referrer });
    };
    this.closeReminder = () => {
      this.setState({ showReminder: false, responseStatus: null });
    };

    /**
     * Handler that control View Progress
     * @param id { Number }
     */
    this.handleViewProgress = (id) => {
      console.log('%c Handle View Progress of ', 'color:#1cb5e0;font-size:2em; ');
      console.log(id);
      let viewProgress = null;
      let indexFound = null;
      let objectProgress = {
        id: null,
        view: false,
      };
      if (this.state.viewProgress) {
        console.log('View progress exist');
        viewProgress = this.state.viewProgress;
        console.log(viewProgress);
        let selectedViewProgress = viewProgress.find((progress) => progress.id === id);
        indexFound = _.findIndex(viewProgress, (progress) => progress.id === id);
        console.log(selectedViewProgress);
        if (selectedViewProgress === -1 || selectedViewProgress === undefined) {
          objectProgress.id = id;
          objectProgress.view = true;
          viewProgress.push(objectProgress);
        } else {
          objectProgress = selectedViewProgress;
          objectProgress.view = !objectProgress.view;
          viewProgress[indexFound] = objectProgress;
        }
      } else {
        viewProgress = [];
        objectProgress.id = id;
        objectProgress.view = true;
        viewProgress.push(objectProgress);
      }
      this.setState({
        viewProgress: viewProgress,
      });
    };
    /**
     * @param section { Number }
     */
    this.changeSection = (section) => {
      let sectionLabel = null;
      switch (section) {
        case 1:
          sectionLabel = 'Histórico';
          break;
        case 2:
          sectionLabel = 'Todos';
          break;
        case 3:
          sectionLabel = 'Activos';
          break;
        case 4:
          sectionLabel = 'Inactivos';
          break;
        case 5:
          sectionLabel = 'Completo';
          break;
      }
      this.setState({ activeSection: section, sectionLabel }, () => {
        this.filteredReferrals(section);
      });
    };
    /**
     * @param activeSection { Number }
     */
    this.filteredReferrals = (activeSection) => {
      let tempReferrals = this.state.newReferrals;

      switch (activeSection) {
        //Histórico
        case 1:
          //Null
          break;
        //Todos
        case 2:
          //Null
          break;
        //Activos
        case 3:
          tempReferrals = tempReferrals.filter((referal) => referal.is_active && !referal.finished);
          break;
        //Inactivos
        case 4:
          tempReferrals = tempReferrals.filter(
            (referal) => !referal.is_active && !referal.finished
          );
          break;
        //Completo
        case 5:
          tempReferrals = tempReferrals.filter((referal) => referal.finished);
          break;
      }

      this.setState({ filteredReferrals: tempReferrals });
    };
  }

  render() {
    return (
      <div className="flex-row row-no-padding">
        <div className="flex-col">
          <PanelMultipleReminder
            whatsAppReminder={this.whatsAppReminder}
            title={'Reactivar referido inactivo'}
            showPanel={this.state.showReminder}
            closePanel={this.closeReminder}
            selectedReferrer={this.state.selectedReferrer}
            openPanelJugar={this.openInviteJugar}
            isLinkReady={this.state.branchLink}
            branchLink={this.state.branchLink}
            type={this.state.typeReminder}
            updateBranchLink={this.updateBranchLink}
            getBranchLinkRemainder={this.getBranchLinkRemainder}
          />
          <PanelInvitaJugar
            openAbonar={this.props.openAbonar}
            branchLink={this.state.branchLink}
            closePanel={this.hideInviteJugar}
            hidePanel={this.hideMailPanel}
            hideInvitationResponse={this.hideInvitationResponse}
            openInvitePanel={this.openMailPanel}
            poolSelected={this.poolSelected}
            getBranchLinkChallenge={this.getBranchLinkChallenge}
            responseStatus={this.state.responseStatus}
            sendChallange={this.sendChallange}
            showInvitationResponse={this.state.showInvitationResponse}
            showMailInvite={this.state.showMailInvite}
            showPanel={this.state.showInviteJugar}
            title={`Reta a `}
            type={'challenge'}
            userData={utils.getUserInfo()}
            updateUserInfo={this.props.updateUserInfo}
            userInviteObject={this.state.selectedReferrer}
            whatsAppInvitationChallenge={this.whatsAppInvitationChallenge}
          />
          <div className="flex-row">
            <ReferrerStats
              activeSection={this.state.activeSection}
              newReferrals={this.props.newReferrals}
              handleClick={this.changeSection}
            />
          </div>
          <div className="flex-row">
            <ListInvited
              handleInvitePanel={this.openInviteJugar}
              handleRemindPanel={this.showReminder}
              updateBranchLink={this.updateBranchLink}
              sectionLabel={this.state.sectionLabel}
              getBranchLink={this.getBranchLink}
              getBranchLinkReferrer={this.getBranchLinkReferrer}
              getBranchLinkChallenge={this.getBranchLinkChallenge}
              referralsList={this.state.filteredReferrals}
              viewProgress={this.state.viewProgress}
              activeSection={this.state.activeSection}
              handleViewProgress={this.handleViewProgress}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default InvitedReferralList;
