import React from 'react'

// Components
import { Divider, Stack, Box } from '../../../Xmerald-UI'
import Button from '../shared/Button'
import { useWizardPrivate } from '../wizardPrivate.store'
import Steps from './Steps'

export default function WizardLayout (props) {
  const setStep = useWizardPrivate(state => state.setStep)

  return (
    <Box>
      <Steps />
      <div style={{ width: '100%', margin: '0 auto' }}>
        {props.children}
      </div>
      <Divider />
      <Stack direction='row' spacing='1em' justify='center' mt={20} mb={20}>
        <Button
          className='guardar-picks btn-rojo'
          onClick={() => setStep(props.prevStep)}
        >
          REGRESAR
        </Button>
        <Button
          disabled={props.disabledNext}
          className='guardar-picks'
          onClick={(e) => props.onClickNext ? props.onClickNext(e) : setStep(props.nextStep)}
        >
          SIGUIENTE
        </Button>
      </Stack>
    </Box>
  )
}
