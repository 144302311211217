import React, { forwardRef } from 'react'
import { PulseLoader } from 'react-spinners'
import styled, { keyframes } from 'styled-components'
import propTypes from 'prop-types'
import CurrencyInput from 'react-currency-input-field'

import Iconify from '../../Iconify'

const InputCurrency = forwardRef((props, ref) => {
  const handleChange = (value, name) => {
    props.onChange({ [name]: value })
  }

  const id = `${props.name}-id`

  return (
    <InputBox>
      <Label htmlFor={id}>{props.label}</Label>
      <InputContainer style={{ maxWidth: props.maxWidth }}>
        <InputBase
          id={id}
          name={props.name}
          prefix={props.prefix}
          suffix={props.suffix}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          decimalsLimit={props.decimalsLimit}
          value={props.value}
          onValueChange={handleChange}
          maxLength={props.maxLength}
          type={props.type}
          ref={ref}
        />

        {(props.loading) && (
          <SpinerBox>
            <PulseLoader size={7} color='#3c8c03' />
          </SpinerBox>
        )}

        {props.isValid && (
          <SpinerBox>
            <Iconify icon='ooui:success' color='#2ea12a' />
          </SpinerBox>
        )}

        {props.error && (
          <SpinerBox>
            <Iconify icon='bxs:error-circle' color='#eb2943' />
          </SpinerBox>
        )}
      </InputContainer>

      {props.helpText && (
        <HelpText style={{ color: props.error ? 'red' : '#2ea12a' }}>
          {props.helpText}
        </HelpText>
      )}
    </InputBox>
  )
})

InputCurrency.defaultProps = {
  decimalsLimit: 2,
  placeholder: '',
  prefix: ' ',
  type: 'text'
}

export default InputCurrency

const HelpText = styled.div`
  font-size: 1em;
  color: red;
  text-align: center;
  line-height: 2;
`

const InputBox = styled.div`
  width: 100%;
  margin-bottom: 1em;
`

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.6) translateY(-50%);
  }

  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
`

const SpinerBox = styled.div`
  position: absolute;
  right: 0.5em;
  top: 50% ;
  transform: translateY(-50%);
  background-color: #ffffffc4;
  padding: 3px;
  animation: ${zoomIn} 300ms ease forwards;
  line-height: 1;
  font-size: 1.2em;
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

export const InputBase = styled(CurrencyInput)`
  width: 100%;
  max-width: 150px;
  padding: 0 0.5em;
  border-radius: 0.3em;
  color: #000;
  outline: 0;
  border: 0;
  box-shadow: 0px 0px 0px 0px #6abd0b00;
  transition: box-shadow 150ms linear;
  will-change: box-shadow;
  line-height: 2.3;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c9c9c9;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #c9c9c9;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #c9c9c9;
  }
  
  &:focus {
    box-shadow: ${p => p.$hasError ? '0px 0px 1px 4px #eb2943' : '0px 0px 1px 4px #78bc28'};
  }
`

const Label = styled.label`
  line-height: 2.5;
  font-size: 1.1em;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
