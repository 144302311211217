import React, { useState, useRef, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import pickSVG from '../../../assets/img/elements/pickwin-pick.svg'

export default function Image (props) {
  const [loaded, setLoaded] = useState(false)

  return (
    <Box>
      {!loaded && <ImageLoader src={pickSVG} />}
      
      <ImageComponent
        {...props}
        style={{
          display: loaded ? 'block' : 'none',
        }}
        onLoad={() => {
          setTimeout(() => setLoaded(true), 800)
        }}
      />
    </Box>
  )
}

const ImageLoader = styled.img`
  display: block;
  width: 7em;
`

const Box = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  background-color: #474747;
  border-radius: 0.4em;
  overflow: hidden;
`

const show = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ImageComponent = styled.img`
  height: 300px;
  width: 300px;
  object-fit: cover;
  opacity: 0;
  animation: ${show} 1200ms ease forwards;
`
