import axios from 'axios';
import io from 'socket.io-client';
import utils, { getToken, getUserInfo, isLogged } from './utils';
import $, { param } from 'jquery';
import _, { pick } from 'underscore';
import * as Sentry from '@sentry/browser';
import { publish } from './events';

// console.log(mockQuinielaGroupEntry)

const buildProduction = process.env.NODE_ENV === 'production';
const devMode = process.env.NODE_ENV === 'development';

// let APIurl            = 'http://10.10.0.116';
let APIurl = process.env.REACT_APP_PW_API_URL;
let socketLiveLobby = process.env.REACT_APP_PW_SOCKET_LOBBY;
let socketLivePane = process.env.REACT_APP_PW_SOCKET_LIVE_PANE;
let socialAPIUrl = process.env.REACT_APP_PW_API_SOCIAL_URL;
let purchasesUrl = process.env.REACT_APP_PW_SOCKET_PURCHASES;

//Incode
let ApiIncodeUrl = process.env.REACT_APP_INCODE_URL;
let ApiIncodeKey = process.env.REACT_APP_INCODE_KEY;
let ApiIncodeId = process.env.REACT_APP_INCODE_ID;

console.log('isProd:', buildProduction);
console.log('isDev:', devMode);


export const socketPurchases = io(purchasesUrl, {
  secure: true,
  transports: ['websocket'],
});


socketPurchases.on('connect', () => {
  const user = getUserInfo()

  if (user?.id) {
    socketPurchases.emit('subscribe', user?.id)
  }
})

// if (buildProduction) {
//   APIurl = process.env.REACT_APP_PW_API_URL;
//   socketLiveLobby = process.env.REACT_APP_PW_SOCKET_LOBBY;
//   socketLivePane = process.env.REACT_APP_PW_SOCKET_LIVE_PANE;

//   //Incode
//   ApiIncodeUrl = process.env.REACT_APP_INCODE_URL;
//   ApiIncodeKey = process.env.REACT_APP_INCODE_KEY;
//   ApiIncodeId = process.env.REACT_APP_INCODE_ID;
// }

let chatSocket, socketLiveLobbyPool, socketLiveSurvivor, socketLivePool, socketLiveGroupPool;
// const socketLivePane = 'http://10.10.0.109:8444/livepane';
let socketQuinielasDash = null;

const API = {
  /********************************
   * Notification Center Endpoints
   *******************************/
  postNotificationControls: (data) => {
    const url = `${APIurl}/api/notification_preferences/update.json`;
    return axios.post(url, data);
  },
  fetchNotificationOptions: () => {
    const url = `${APIurl}/api/notification_preferences/get.json`;
    return axios.get(url);
  },
  /**************
   * Sentry APIS
   **************/
  sendFeedbackSentry: function(reportData) {
    const dsn = 'https://1cd804634d3d4d78ab46999ee8b62b92@sentry.io/1220360';

    // const errorUrl = `https://sentry.io/api/embed/error-page/?dsn=https://1cd804634d3d4d78ab46999ee8b62b92@sentry.io/1220360&name=${reportData.name}&email=${reportData.email}&title=BugReportID&subtitle=&subtitle2=&successMessage=&comments=${reportData.comments}&eventId=`+reportData.event_id;
    // const errorUrl = `https://sentry.io/api/embed/error-page/?dsn=${dsn}&eventId=${reportData.event_id}`;

    const errorReportOptions = {
      method: 'post',
      url: `https://sentry.io/api/embed/error-page/?dsn=${dsn}&comments=${reportData.comments}&name=${reportData.name}&email=${reportData.email}&eventId=${reportData.event_id}`,
      data: {
        comments: reportData.comments,
        email: reportData.email,
        event_id: reportData.event_id,
        name: reportData.name,
      },
    };

    return $.ajax(errorReportOptions)
      .then((response) => {
        console.log('-----------Report sent with Ajax to Sentry-----------', response);
        return response;
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  },
  /**************************
   * Verification Endpoints
   * ***********************/
  requestSms: (smsData) => {
    const url = `${APIurl}/api/users/requestsms.json`;
    return axios.post(url, smsData);
  },
  verifySms: (smsData) => {
    const url = `${APIurl}/api/users/verifysms.json`;
    return axios.post(url, smsData);
  },
  /*********************
   * Transfer endpoints
   ********************/
  isTransfersEnabled: () => {
    const url = `${APIurl}/api/referrals/transfersEnabled.json`;
    return axios.get(url);
  },
  postTransfer: (transferObject) => {
    const url = `${APIurl}/api/transactions/transfer.json`;
    return axios.post(url, transferObject);
  },
  /*********************
   * Extra Financial Data
   ********************/
  extraFinancialData: () => {
    const url = `${APIurl}/api/user_data/get.json`
    return axios.get(url)
  },
  updateFinancialData: (financialData) => {
    const url = `${APIurl}/api/user_data/update.json`
    return axios.post(url, financialData)
  },
  /*******************
   * Users endpoints
   * ****************/
  updateUserTos: (tos) => {
    const url = `${APIurl}/api/users/updatetos.json`;
    return axios.post(url, tos);
  },
  searchUser: (userNick) => {
    const url = `${APIurl}/api/users/search.json`;
    return axios.post(url, { nick: userNick });
  },
  postChallengeUser(challangeData) {
    const url = `${APIurl}/api/referrals/challenge.json`;
    return axios.post(url, challangeData);
  },
  fetchIpValidation() {
    const url = `${APIurl}/api/users/validate.json`;
    return axios.get(url);
  },
  fetchUserReferrals(query) {
    // console.log('getting Data...')
    let url;
    if (query === undefined) {
      url = `${APIurl}/api/user_referals/referals.json?total`;
    } else {
      url = `${APIurl}/api/user_referals/referals.json${query}`;
    }
    return axios.get(url);
  },
  fetchUserDocuments() {
    // console.log('getting Data...')
    let url = `${APIurl}/api/documents/index.json`;
    return axios.get(url);
  },
  fetchUserData() {
    console.log('Fetch user data');
    let url = `${APIurl}/api/users/details.json`;
    return axios.get(url);
  },
  fetchUserBalance() {
    let url = `${APIurl}/api/transactions/checkBalance.json`;
    return axios.get(url);
  },
  getMisQuinielas() {
    let url = `${APIurl}/api/users/pools.json`;
    // console.log('API LOG  Mis quinielas...');
    return axios.get(url);
  },
  postAvatar(data) {
    let url = `${APIurl}/api/users/change_avatar.json`;
    return axios.post(url, data);
  },
  postChangePassword(data) {
    let url = `${APIurl}/api/users/change_password.json`;
    return axios.post(url, data);
  },
  postResetPassword(token, data) {
    let url = `${APIurl}/api/users/reset_password/${token}.json`;
    return axios.post(url, data);
  },
  postFacebookLogin(userID, accesToken) {
    let url = `${APIurl}/api/users/facebook_login.json`;
    return axios
      .post(url, { uid: userID, accessToken: accesToken })
      .then(function(response) {
        console.log('postfacebooklogin', response);
        var data = response.data;
        var user = data.user;
        var jwToken = data.jwtoken;

        axios.defaults.headers.common['Authorization'] = `Bearer ${jwToken}`;
        utils.setUserInfo(data);
        // utils.setUserBalance(data.user);
        utils.setToken(jwToken);
        // console.log(axios.defaults);
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },
  postLoginData(username, password, fingerprint, jwTokenFB=null) {
    let url = `${APIurl}/api/users/login.json`;
    return axios
      .post(url, { username: username, password: password, fingerprint: fingerprint, headers: { Authorization: '' } })
      .then((response) => {
        var data = response.data;
        var jwToken = jwTokenFB === null ?  data.jwtoken : jwTokenFB;
        var tokenHash = data.tokenHash;

        axios.defaults.headers.common['Authorization'] = `Bearer ${jwToken}`;

        utils.setToken(jwToken);
        utils.setTokenHash(tokenHash);

        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },
  postLogoutData() {
    let url = `${APIurl}/api/users/logout.json`;
    return new Promise((resolve, reject) => {
      if (utils.isLogged()) {
        return axios
          .post(url)
          .then(function(response) {
            delete axios.defaults.headers.common['Authorization'];
            utils.logout();
            // console.log('API LOG LogOUT...',response);
            resolve(response);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        let error = new Error('Error Logging !auth user');
        console.error(error);
        Sentry.captureException(error);
        window.location = '/';
        reject({ success: false, error: 'User isnt logged to get logged out' });
      }
    });
  },
  postRecoverPass(email) {
    let url = `${APIurl}/api/users/recover.json`;
    return axios.post(url, { email: email });
  },

  postRegister(data) {
    let url = `${APIurl}/api/users/register.json`;
    return axios
      .post(url, data)
      .then(function(response) {
        // let's fire the facebook pixel
        let responseData = response.data;
        if (responseData.hasOwnProperty('success') && responseData.success) {
          window.fbq('init', '551771658321328', {
            external_id: responseData.user.id,
            em: responseData.user.email,
          });
          window.fbq('track', 'PageView');

          window.fbq(
            'track', 'CompleteRegistration',
            {'value': 0.00, 'currency': 'mxn'},
            {'eventID': `pickwin-registration-${responseData.user.id}`}
          );
          axios.defaults.headers.common['Authorization'] = `Bearer ${responseData.jwtoken}`;
          utils.setUserInfo(responseData);
          utils.setToken(responseData.jwtoken);
        }

        return response;
        // console.log('API LOG LogOUT...',response);
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },
  postFacebookRegister(data) {
    let url = `${APIurl}/api/users/facebook_register.json`;
    return axios
      .post(url, data)
      .then(function(response) {
        var data = response.data;
        var user = data.user;
        var jwToken = data.jwtoken;
        if (data.hasOwnProperty('success') && data.success) {
          window.fbq('init', '551771658321328', {
            external_id: user.id,
            em: user.email,
          });
          window.fbq('track', 'PageView');
          window.fbq(
            'track', 'CompleteRegistration',
            {'value': 0.00, 'currency': 'mxn'},
            {'eventID': `pickwin-registration-${user.id}`}
          );
          axios.defaults.headers.common['Authorization'] = `Bearer ${jwToken}`;
          utils.setUserInfo(data);
          utils.setToken(jwToken);
        }

        return response;
        // console.log('API LOG LogOUT...',response);
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  },

  /******************
   * Entry Endpoints
   *****************/
  fetchBracketEntry(id) {
    const url = `${APIurl}/api/bracket_entries/get/${id}.json`;
    return axios.get(url);
  },
  postUnregisterBracket(ids) {
    const url = `${APIurl}/api/bracket_entries/unregister.json`;
    return axios.post(url, ids);
  },
  hideEntryBracket: (entryId) => {
    const url = `${APIurl}/api/bracket_entries/hide/${entryId}.json`;
    return axios.post(url);
  },
  unHideEntryBracket: (entryId) => {
    const url = `${APIurl}/api/bracket_entries/unhide/${entryId}.json`;
    return axios.post(url);
  },
  hideEntry: (entryId) => {
    const url = `${APIurl}/api/entries/hide/${entryId}.json`;
    return axios.post(url);
  },
  unHideEntry: (entryId) => {
    const url = `${APIurl}/api/entries/unhide/${entryId}.json`;
    return axios.post(url);
  },
  fetchCopyPicks: (which) => {
    let url = `${APIurl}/api/entries/copyPicks/${which}.json`;
    return axios.get(url);
  },
  fetchSurvivorEntry(survivorEntryId) {
    const url = `${APIurl}/api/survivor_entries/get/${survivorEntryId}.json`;
    return axios.get(url);
  },
  postUnregisterSurvivor(ids) {
    const url = `${APIurl}/api/survivor_entries/unregister.json`;
    return axios.post(url, ids);
  },
  hideSurvivorEntry: (entryId) => {
    const url = `${APIurl}/api/survivor_entries/hide/${entryId}.json`;
    return axios.post(url);
  },
  unHideSurvivorEntry: (entryId) => {
    const url = `${APIurl}/api/survivor_entries/unhide/${entryId}.json`;
    return axios.post(url);
  },
  fetchFirstEntrySurvivor(survivorId) {
    const url = `${APIurl}/api/survivor_entries/first_entry/${survivorId}.json`;
    return axios.get(url);
  },
  fetchSimilarPools(which) {
    let url = `${APIurl}/api/entries/similarByPool/${which}.json`;
    return axios.get(url);
  },
  fetchSimilarEntries(which) {
    let url = `${APIurl}/api/entries/similar/${which}.json`;
    return axios.get(url);
  },
  getEntriesInfo(entryID) {
    // console.log('API LOG  Informacon de Entries...');
    let url = `${APIurl}/api/entries/get/${entryID}.json`;
    return axios.get(url);
  },
  postCancelRegister(data) {
    let url = `${APIurl}/api/entries/unregister.json`;

    publish('UPDATE_MY_PICKS', 'justSave')

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return axios.post(url, data, config);
  },

  /***************************
   * New Referrals Endpoints
   **************************/
  postNewReferralInvitation(invitationData) {
    const url = `${APIurl}/api/referrals/invite.json`;
    return axios.post(url, invitationData);
  },
  fetchNewReferrals() {
    const url = `${APIurl}/api/referrals/get.json`;
    return axios.get(url);
  },
  deleteReferral(id) {
    const url = `${APIurl}/api/referrals/delete/${id}.json`;
    return axios.get(url);
  },

  /***************************
   * Private Pools Endpoints
   **************************/
  fetchNamePrivateStatus(name) {
    const url = `${APIurl}/api/pools/check_private_name.json`;
    return axios.post(url, { name: name });
  },
  fetchNamePrivateSurvivorStatus(name) {
    const url = `${APIurl}/api/survivors/check_private_name.json`;
    return axios.post(url, { name: name });
  },
  postSearchPrivate(data) {
    let url = `${APIurl}/api/pools/search_private_logged.json`;
    return axios.post(url, data);
  },

  // Get vip Refeal Prices
  getVipRefealPrizes() {
    const url = `${APIurl}/api/vip_referral_prizes/get_prizes.json`;
    return axios.get(url);
  },
    
  // To get rewards from my refeals
  getVipReferralRewards() {
    const url = `${APIurl}/api/vip_referral_rewards/get_rewards.json`;
    return axios.get(url);
  },

  /*********************
   * Tickets  Endpoints
   *********************/
  fetchTicketsAvailable: function() {
    const url = `${APIurl}/api/tickets/available.json`;
    return axios.get(url);
  },
  fetchTickets(gameId, gameType) {
    const url = `${APIurl}/api/tickets/get_for_product/${gameId}/${gameType}.json`;
    return axios.get(url);
  },
  /*********************
   * ReferralBalance  Endpoints
   *********************/
  fetchReferralBalanceAvailable: function() {
    const url = `${APIurl}/api/referrals/available.json`;
    return axios.get(url);
  },
  fetchReferralBalance(gameId, gameType) {
    const url = `${APIurl}/api/referrals/get_for_product/${gameId}/${gameType}.json`;
    return axios.get(url);
  },

  /************************
   *Transcations Endpoints
   ***********************/
  checktransactions(page) {
    let url = null;
    if (page === undefined) {
      url = `${APIurl}/api/transactions/history.json?page=1`;
    } else {
      url = `${APIurl}/api/transactions/history.json?page=${page}`;
    }
    // console.log('API LOG  Transacciones...')
    return axios.get(url);
  },
  fetchTransactions(query) {
    //console.log('QUERYTRANS:', query);
    let url;
    if (query === undefined) {
      url = `${APIurl}/api/transactions/history.json?page=1`;
    } else {
      const queryObject = $.param(query);
      url = `${APIurl}/api/transactions/history.json?${queryObject}`;
    }
    return axios.get(url, query);
  },
  postExchangePicoins(amount) {
    let url = `${APIurl}/api/transactions/exchangePickcoins.json`;
    const amountData = {
      amount: amount,
    };
    return axios.post(url, amountData);
  },
  /******************
   * Pool Groups Endpoints
   *****************/
  async fetchPoolGroupsDetail(poolId) {
    const url = `${APIurl}/api/group_pools/view/${poolId}.json`;
    // const fakeThrothle = () =>{
    //   return new Promise ( ( resolve , reject ) => {
    //     setTimeout( () => {
    //       resolve( mockGroupQuinielas );
    //     }, 800);
    //   })
    // };
    try {
      let result = await axios.get(url);
      if (result) {
        return result;
      } else {
        return { error: true, message: 'Quiniela no disponible' };
      }

      // let result = await  fakeThrothle();
      // // console.log(result);
      // if( result ){
      //   return result
      // }
      // else {
      //   return { error: true, message: "Quiniela no  disponible" }
      // }
    } catch (err) {
      return err;
    }
  },
  async fetchPoolGroupPlayers(poolId, contestId) {
    let url = `${APIurl}/api/player_results/get_players/${poolId}/${contestId}.json`;
    try {
      let result = await axios.get(url);
      if (result) {
        return result;
      } else {
        return { error: true, message: 'Quiniela no disponible' };
      }
    } catch (err) {
      return err;
    }
  },
  async fetchPoolGroupsEntry(entryId) {
    // const fakeThrothle = () =>{
    //   return new Promise ( ( resolve , reject ) => {
    //       resolve( mockQuinielaGroupEntry );
    //   })
    // };
    const url = `${APIurl}/api/group_pool_entries/get/${entryId}.json`;

    try {
      // let result = await  fakeThrothle();
      // console.log(mockQuinielaGroupEntry);
      // // console.log(result)
      // if( mockQuinielaGroupEntry.data ){
      //   return mockQuinielaGroupEntry
      // }
      // else {
      //   return { error: true, message: "No hay entradas disponibles" }
      // }

      let result = await axios.get(url);
      if (result) {
        return result;
      } else {
        return { error: true, message: 'No se guardo tu entrada' };
      }
    } catch (err) {
      return err;
    }
  },
  async fetchSimilarPoolGroups(poolId) {
    let url = `${APIurl}/api/group_pool_entries/similar/${poolId}.json`;
    return axios.get(url);
  },
  async fetchSimilarByPoolGroups(poolId) {
    let url = `${APIurl}/api/group_pool_entries/similarByPool/${poolId}.json`;
    return axios.get(url);
  },
  async postCancelRegisterPoolGroup(data) {
    let url = `${APIurl}/api/group_pool_entries/unregister.json`;
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return axios.post(url, data, config);
  },
  async savePoolGroup(groupPoolEntry) {
    const url = `${APIurl}/api/group_pool_picks/save.json?groupPoolId=${groupPoolEntry.group_pool_id}&groupPoolEntryId=${groupPoolEntry.group_pool_entry_id}`;
    try {
      let result = await axios.post(url, groupPoolEntry);
      if (result) {
        return result;
      } else {
        return { error: true, message: 'No se guardo tu entrada' };
      }
    } catch (error) {
      return { error: true, message: 'No se guardo tu entrada' };
    }
  },
  async fetchPoolGroupGroupPicks(poolId, queryParams, contestId) {
    // console.log('getting Group Picks...', queryParams);

    let url = null;
    if (!queryParams) {
      url = `${APIurl}/api/group_pool_standings/group_picks/${poolId}/${contestId}.json?&page=1&limit=100`;
    } else {
      const query = $.param(queryParams);
      url = `${APIurl}/api/group_pool_standings/group_picks/${poolId}/${contestId}.json?${query}`;
    }

    return axios.get(url);
  },
  async fetchPoolGroupsStandings(poolId, queryParams) {
    // console.log( 'Standings of',poolId );
    let url = null;
    if (!queryParams) {
      url = `${APIurl}/api/group_pool_standings/get/${poolId}.json?limit=100&page=1`;
    } else {
      const query = $.param(queryParams);
      url = `${APIurl}/api/group_pool_standings/get/${poolId}.json?${query}`;
    }

    // const fakeThrothle = () =>{
    //   return new Promise ( ( resolve , reject ) =>
    //     setTimeout( () => {
    //       resolve( mockQuinielaGroupsStandings );
    //     }, 800);
    //   })
    // };

    try {
      // let result = await  fakeThrothle();
      // console.log(result);
      // if( result ){
      //   return result
      // }
      // else {
      //   return { error: true, message: "No hay standings disponibles" }
      // }

      let result = await axios.get(url);
      if (result) {
        return result;
      } else {
        return { error: true, message: 'No se guardo tu entrada' };
      }
    } catch (error) {
      return { error: true, message: 'No se guardo tu entrada' };
    }
  },
  async fetchCopyGroupPoolPicks(which) {
    let url = `${APIurl}/api/group_pool_entries/copyPicks/${which}.json`;
    return axios.get(url);
  },
  /******************
   * Pools Endpoints
   *****************/
  fetchPools() {
    // return new Promise( (resolve , reject) => {
    //   resolve (mockPoolsAvailable);
    // })
    // console.log(utils.isLogged());
    // console.log('getting Data...')
    let url = `${APIurl}/pools/available.json`;
    if (utils.isLogged()) {
      url = `${APIurl}/api/pools/available.json`;
    }
    return axios.get(url);
  },
  fetchAvailableQuinielas() {
    let url = `${APIurl}/api/users/pools.json`;
    return axios.get(url);
  },
  fetchFixtures(which) {
    // console.log('getting Data...')
    let url = `${APIurl}/api/pools/fixtures/${which}.json`;
    return axios.get(url);
  },
  fetchIndividualPool(which) {
    // console.log('getting Data...')
    let url = `${APIurl}/api/pools/view/${which}.json`;
    return axios.get(url);
  },
  fetchPoolPrizes(which) {
    // console.log('getting Data...')
    let url = `${APIurl}/pools/prize_distribution/${which}.json`;
    return axios.get(url);
  },
  fetchRecomendations(which) {
    let url = `${APIurl}/api/pools/recommendations/${which}.json`;
    return axios.get(url);
  },
  fetchWeek(which) {
    let url = `${APIurl}/api/pools/weeks/${which}.json`;
    // console.log('Week',which);
    return axios.get(url);
  },
  fetchWeekFixtures(which, week) {
    let url = `${APIurl}/api/pools/fixtures/${which}/${week}.json`;
    // console.log('Week',which, week);
    return axios.get(url);
  },
  postInviteEmails(data) {
    let url = `${APIurl}/api/pools/invite_emails.json`;
    return axios.post(url, data);
  },

  /**********************
   * Standings Endpoints
   *********************/
  fetchGroupPicks(which, week, page, sortType = 'global') {
    // console.log('getting Data...')
    let url;
    if (page === undefined) {
      url = `${APIurl}/api/standings/group_picks/${which}/${week}.json?sortType=${sortType}`;
    } else {
      url = `${APIurl}/api/standings/group_picks/${which}/${week}.json?page=${page}&sortType=${sortType}`;
    }
    return axios.get(url);
  },
  fetchSurvivorStandings(which, queryObject) {
    let url = null;
    // console.log($.param( queryObject ));
    // => "forms%5Bperfect%5D=circle&forms%5Bimperfect%5D=square"
    // url = `${APIurl}/api/survivor_standings/get/${which}.json`;

    if (queryObject === undefined || queryObject === null) {
      url = `${APIurl}/api/survivor_standings/get/${which}.json?limit=100&page=1`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/survivor_standings/get/${which}.json?${query}`;
    }
    return axios.get(url);
  },
  fetchSurvivorStandingsFull(which) {
    let url = `${APIurl}/api/survivor_standings/full/${which}.json`;
    return axios.get(url);
  },
  fetchSurvivorGroupPicks(survivorId, page, limit) {
    let url = null;
    limit = limit === undefined ? 100 : limit;
    url = `${APIurl}/api/survivor_standings/group_picks/${survivorId}.json?&page=${page}&limit=${limit}`;
    //const url = `${APIurl}/api/bracket_standings/group_picks/${ bracketId }.json?stageIds[]=2,3,4,5&page=${page}`;
    return axios.get(url);
  },

  getSurvivorContestGroupPicks(survivorId, page) {
    return axios.get(
      `${APIurl}/api/survivor_standings/contest_group_picks/${survivorId}.json?page=${page}`
    );
  },

  getSurvivorContestGroupPicksF1(survivorId, page) {
    return axios.get(
      `${APIurl}/api/survivor_standings/race_group_picks/${survivorId}.json?page=${page}`
    );
  },
  /**
   * Fetches all players for a contest
   * @param {number} contestId
   */
  getSurvivorContestPlayers(contestId) {
    return axios.get(`${APIurl}/api/survivors/contest_players/${contestId}.json`);
  },

  fetchBracketStandings(bracketId, queryObject) {
    let url = null;
    if (queryObject !== undefined || queryObject !== null) {
    }
    // console.log($.param( queryObject ));
    // => "forms%5Bperfect%5D=circle&forms%5Bimperfect%5D=square"
    if (queryObject === undefined || queryObject === null) {
      url = `${APIurl}/api/bracket_standings/get/${bracketId}.json?limit=100&page=1`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/bracket_standings/get/${bracketId}.json?${query}`;
    }

    return axios.get(url);
  },
  fetchBracketGroupPicks(bracketId, stageIds, page, limit) {
    // console.log( bracketId );
    let url = null;
    limit = limit === undefined ? 100 : limit;
    if (stageIds !== 1) {
      url = `${APIurl}/api/bracket_standings/group_picks/${bracketId}.json?stageIds=2,3,4,5&page=${page}&limit=${limit}`;
    } else {
      url = `${APIurl}/api/bracket_standings/group_picks/${bracketId}.json?stageIds=1&page=${page}&limit=${limit}`;
    }
    // console.log(url);
    //const url = `${APIurl}/api/bracket_standings/group_picks/${ bracketId }.json?stageIds[]=2,3,4,5&page=${page}`;
    return axios.get(url);
  },

  fetchBracketPointsGroupPicks(bracketId, stageId, page, limit) {
    let url = null;
    limit = limit === undefined ? 100 : limit;
    url = `${APIurl}/api/bracket_standings/points_group_picks/${bracketId}/${stageId}.json?page=${page}&limit=${limit}`;
    return axios.get(url);
  },

  fetchBracketSeriesGroupPicks(bracketId, page, limit) {
    let url = null;
    limit = limit === undefined ? 100 : limit;
    url = `${APIurl}/api/bracket_standings/series_group_picks/${bracketId}.json?page=${page}&limit=${limit}`;
    return axios.get(url);
  },

  fetchStandings(which, queryObject) {
    let url = null;
    // console.log($.param( queryObject ));
    // => "forms%5Bperfect%5D=circle&forms%5Bimperfect%5D=square"

    if (queryObject === undefined || queryObject === null) {
      url = `${APIurl}/api/standings/get/${which}.json?limit=100&page=1`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/standings/get/${which}.json?${query}`;
    }
    return axios.get(url);
  },
  fetchStandingsPartial(which, queryObject) {
    let url = null;
    // console.log($.param( queryObject ));
    // => "forms%5Bperfect%5D=circle&forms%5Bimperfect%5D=square"

    if (queryObject === undefined || queryObject === null) {
      url = `${APIurl}/api/standings/get/${which}.json?page=1`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/standings/get/${which}.json?${query}`;
    }
    return axios.get(url);
  },
  getMyWeeklyStandings(which, week, userId) {
    let url = `${APIurl}/api/weeklyStandings/get/${which}/${week}.json?user_id=${userId}`;
    return axios.get(url);
  },
  fetchWeeklyStandingsPartial(which, week) {
    let url = `${APIurl}/api/weeklyStandings/get/${which}/${week}.json`;
    return axios.get(url);
  },
  fetchWeeklyStandings(which, week, queryObject) {
    let url = null;
    if (!queryObject) {
      url = `${APIurl}/api/weeklyStandings/get/${which}/${week}.json`;
    } else {
      const query = $.param(queryObject);
      url = `${APIurl}/api/weeklyStandings/get/${which}/${week}.json?${query}`;
    }
    return axios.get(url);
  },
  fetchStandingsGroups(which) {
    let url = `${APIurl}/api/standings/standing_groups/${which}.json`;
    return axios.get(url);
  },

  /**********************
   * Survivors endpoints
   *********************/
  fetchSurvivorDetails(survivorId) {
    const url = `${APIurl}/api/survivors/view/${survivorId}.json`;
    return axios.get(url);
  },
  fetchSurvivorFixtures(weekId) {
    const url = `${APIurl}/api/survivors/fixtures/${weekId}.json`;
    return axios.get(url);
  },
  fetchSurvivorTeams(survivorId) {
    const url = `${APIurl}/api/survivors/teams/${survivorId}.json`;
    return axios.get(url);
  },
  fetchSurvivorAllFixtures(survivorId) {
    const isUserLogged = isLogged()
    // console.log('🚀 ~ fetchSurvivorAllFixtures ~ isUserLogged:', isUserLogged)
    // if (!isUserLogged) return {
    //   fixtures: {  },
    //   firstsOfWeek: {  },
    //   pickStats: {  }
    // }
  
    const url = `${APIurl}/api/survivors/allFixtures/${survivorId}.json`;
    return axios.get(url);
  },

  unsetPick(pickId, survivorEntryId, survivorId, data) {
    console.log('🚀 ~ unsetPick ~ data:', data)
    let url = `${APIurl}/api/survivor_picks/unset_pick.json?pickId=${pickId}&survivorEntryId=${survivorEntryId}&survivorId=${survivorId}`;
    
    return axios.post(url, data);
  },

  resetAllPicks(survivorEntryId, survivorId, data) {
    let url = `${APIurl}/api/survivor_picks/reset_picks.json?survivorEntryId=${survivorEntryId}survivorId=${survivorId}`;
    return axios.post(url, data);
  },

  postSaveSurvivorPicks(survivorEntry) {
    const url = `${APIurl}/api/survivor_picks/save.json?survivorId=${survivorEntry.survivor_id}&survivorEntryId=${survivorEntry.survivor_entry_id}`;
    return axios.post(url, survivorEntry);
  },

  /**********************
   * Brackets Endpoints
   *********************/
  fetchBracketsDetails(bracketId) {
    const url = `${APIurl}/api/brackets/view/${bracketId}.json`;
    return axios.get(url);
  },
  fetchBracketStages(tournamentInstanceId) {
    const url = `${APIurl}/api/stages/view/${tournamentInstanceId}.json`;
    return axios.get(url);
  },
  fetchBracketStagesByBracketId(bracketId) {
    const url = `${APIurl}/api/stages/bracket/${bracketId}.json`;
    return axios.get(url);
  },
  fetchBracketGroups(tournamentInstancesId) {
    const url = `${APIurl}/api/brackets/groups/${tournamentInstancesId}.json`;
    return axios.get(url);
  },
  postSaveBracketPicks(bracket_entry) {
    const url = `${APIurl}/api/bracket_picks/save.json`;
    return axios.post(url, bracket_entry);
  },
  /**********************
   * Others endpoints
   **********************/
  fetchGamePdf(id, game_type) {
    const url = `${APIurl}/api/gamepdfs/get/${id}/${game_type}.json`;
    return axios.get(url);
  },
  fetchOpenPayCards() {
    let url = `${APIurl}/api/openpay_cards/index.json`;
    return axios.get(url);
  },
  fetchBannerData() {
    const url = 'https://pickwinfree.firebaseio.com/configuraciones/banners.json';
    return axios.get(url, { headers: { Authorization: '' } });
  },
  fetchPromotions() {
    const url = 'https://pickwinfree.firebaseio.com/promotions.json';
    return axios.get(url, { headers: { Authorization: '' } });
  },
  fetchTestPromotions() {
    const url = 'https://pickwinfree.firebaseio.com/promotions_test.json';
    return axios.get(url, { headers: { Authorization: '' } });
  },
  fetchSports(gameType) {
    if (!gameType) {
      let url = `${APIurl}/api/sports/forprivate.json`;
      return axios.get(url);
    } else {
      let url = `${APIurl}/api/sports/forprivate/${gameType}.json`;
      return axios.get(url);
    }
  },
  fetchSportsIndex() {
    let url = `${APIurl}/api/sports/index.json`;
    return axios.get(url);
  },
  fetchTeams(which) {
    let url = `${APIurl}/api/teams/from_tournament/${which}.json`;
    return axios.get(url);
  },
  fetchTournamentInstancesWeek(which) {
    let url = `${APIurl}/api/tournament_instances/next_weeks/${which}.json`;
    return axios.get(url);
  },
  fetchTournamentInstancesWeekSurvivor(which) {
    let url = `${APIurl}/api/tournament_instances/survivor_weeks/${which}.json`;
    return axios.get(url);
  },
  fetchTournamentInstancesFixtures(which) {
    let url = `${APIurl}/api/tournament_instances/next_fixtures/${which}.json`;
    return axios.get(url);
  },

  /*********************
   * Purchase endpoints
   ********************/
  fetchAstroPayMethods: function() {
    let url = `${APIurl}/api/astropay/banks.json`;
    return axios.get(url);
  },
  postPurchaseAstroPay: function(data) {
    let url = `${APIurl}/api/astropay/newinvoice.json`;
    return axios.post(url, data);
  },
  postPurchaseNeteller(data) {
    let url = `${APIurl}/api/neteller/purchase.json`;
    return axios.post(url, data);
  },
  postPurchaseZenki(data) {
    let url = `${APIurl}/api/zenki_orders/register.json`;
    return axios.post(url, data);
  },
  postPurchaseTodito(data) {
    let url = `${APIurl}/api/todito/purchase.json`;
    return axios.post(url, data);
  },
  postDoPurchase(data) {
    let url = `${APIurl}/api/users/dopurchase.json`;
    return axios.post(url, data);
  },
  postDepositConvenience(data) {
    let url = `${APIurl}/api/users/depositConvenience.json`;
    return axios.post(url, data);
  },
  getSpeiGateway() {
    let url = `${APIurl}/api/stpChargeOrders/getSpeiGateway.json`;
    return axios.get(url)
  },
  postSpeiSTP(data) {
    let url = `${APIurl}/api/stpChargeOrders/getDepositSlip.json`;
    return axios.post(url, data);
  },
  postOpenPayCardsDelete(cardId) {
    let url = `${APIurl}/api/openpay_cards/delete/${cardId}.json`;
    return axios.delete(url);
  },
  addOpenPayCard(card) {
    let url = `${APIurl}/api/openpay_cards/add.json`;
    return axios.post(url, card);
  },
  doPurchaseFeenicia(paymentData) {
    let url = `${APIurl}/api/feenicia/dopurchase.json`;
    return axios.post(url, paymentData);
  },
  saveCardFeenicia(cardData) {
    let url = `${APIurl}/api/feenicia_cards/save.json`;
    return axios.post(url, cardData);
  },
  removeCardFeenicia(cardData) {
    let url = `${APIurl}/api/feenicia_cards/delete/${cardData}.json`;
    return axios.delete(url, cardData);
  },
  fetchFeeniciaCards() {
    let url = `${APIurl}/api/feenicia_cards/index.json`;
    return axios.get(url);
  },

  /******************
   * Picks endpoints
   *****************/
  loadNextPicks: function(poolId, fixtures) {
    let url = `${APIurl}/api/picks/next/${poolId}.json`;
    return axios.post(url, { fixtures: fixtures });
  },
  loadNextPicksProps: function(poolId, props) {
    let url = `${APIurl}/api/picks_props/next/${poolId}.json`;
    return axios.post(url, { props: props });
  },
  postFastRegister(picks) {
    let url = `${APIurl}/api/picks/save.json?entryId=${picks.entry_id}&poolId=${picks.pool_id}`;
    return axios.post(url, picks);
  },
  postMultyEntry(data) {
    let url = `${APIurl}/api/picks/save_multiple.json`;
    return axios.post(url, data);
  },
  postSavePicks(data) {
    let url = `${APIurl}/api/picks/save.json?entryId=${data.entry_id}&poolId=${data.pool_id}`;
    return axios.post(url, data);
  },

  /**********************
   * Cashouts Endpoints
   *********************/
  postCashout(params) {
    let url = `${APIurl}/api/cashouts/request.json`;
    return axios.post(url, params);
  },
  fetchCashoutBanks() {
    let url = `${APIurl}/api/cashouts/banks.json`;
    return axios.post(url);
  },
  fetchAvailableCashout() {
    let url = `${APIurl}/api/cashouts/available.json`;
    return axios.post(url);
  },

  /**********************
   * Survivors Private
   **********************/
  postCreatePrivateSurvivor(data) {
    let url = `${APIurl}/api/survivors/create_private.json`;
    return axios.post(url, data);
  },
  postDeleteRegisterSurvivorPrivate(which) {
    let url = `${APIurl}/api/survivor_entries/delete/${which}.json`;
    return axios.post(url);
  },
  postEditPrivateSurvivor(data) {
    let url = `${APIurl}/api/survivors/edit_private.json`;
    return axios.post(url, data);
  },
  postSearchPrivateSurvivor(data) {
    let url = `${APIurl}/api/survivors/search_private_logged.json`;
    return axios.post(url, data);
  },

  /**********************
   * Pools Private
   *********************/
  postCreatePrivate(data) {
    let url = `${APIurl}/api/pools/create_private.json`;
    return axios.post(url, data);
  },
  postDeleteRegisterPrivate(which) {
    let url = `${APIurl}/api/entries/delete/${which}.json`;
    return axios.post(url);
  },
  postEditPrivate(data) {
    let url = `${APIurl}/api/pools/edit_private.json`;
    return axios.post(url, data);
  },

  /****************
   ******API*******
   **POST METHODS**
   ****************/
  /**
   * Social endpoints
   */
  archiveNotification(notificationId) {
    const url = `${socialAPIUrl}/social-api/notifications/${notificationId}/archive`;
    return axios.post(url).then((response) => {
      return response.data;
    });
  },
  searchUsers(queryParams) {
    // limit - offset
    const url = `${socialAPIUrl}/social-api/users`;
    return (
      axios
        .get(url, { params: queryParams })
        // .get(url, { params: { limit, offset, search, format: 'json' } })
        .then((response) => {
          return response.data;
        })
    );
  },
  listAlreadyFriendUsers({ name: search, limit = 10, offset = 0 } = {}) {
    const url = `${socialAPIUrl}/social-api/users/self/friends`;
    return axios
      .get(url, { params: { limit, offset, search, format: 'json' } })
      .then((response) => {
        console.log('LIST FRIEND_USERS', response);
        return response.data;
      });
  },
  followUser(userId) {
    const url = `${socialAPIUrl}/social-api/users/${userId}/friend`;
    return axios.post(url);
  },
  unfollowUser(id) {
    const url = `${socialAPIUrl}/social-api/users/${id}/friend`;
    return axios.delete(url).then((response) => {
      console.log('UNFOLLOW USER', response);
      return response.data;
    });
  },
  inviteUserToPool({ userId, href }) {
    console.log(userId, href);
    const url = `${socialAPIUrl}/social-api/users/${userId}/pool`;
    return axios.post(url, { href }).then((response) => {
      console.log('INVITE USER TO A POOL', response);
      return response.data;
    });
  },
  acceptFriendRequest(id) {
    const url = `${socialAPIUrl}/social-api/users/${id}/accept`;
    return axios.post(url).then((response) => {
      console.log('ACCEPT USER AS FRIEND', response);
      return response.data;
    });
  },
  rejectFriendRequest(id) {
    const url = `${socialAPIUrl}/social-api/users/${id}/reject`;
    return axios.post(url).then((response) => {
      console.log('ACCEPT USER AS FRIEND', response);
      return response.data;
    });
  },
  getNotificationsStatus() {
    const url = `${socialAPIUrl}/social-api/notifications/status`;
    return axios.get(url).then((response) => {
      // console.log('LIST FRIEND_USERS', response);
      return response.data;
    });
  },
  getNotifications(queryParams) {
    const url = `${socialAPIUrl}/social-api/notifications`;
    // console.log( 'queryParams' );
    // console.log( queryParams );
    if (!queryParams) {
      return axios.get(url, { params: { limit: 5, offset: 0, page: 1 } }).then((response) => {
        // console.log('GET NOTIFICATIONS', response);
        return response.data;
      });
    }
    return axios
      .get(url, {
        params: { limit: queryParams.limit, offset: queryParams.offset, page: queryParams.page },
      })
      .then((response) => {
        // console.log('GET NOTIFICATIONS', response);
        return response.data;
      });
  },
  markAllNotificationsAsRead() {
    const url = `${socialAPIUrl}/social-api/notifications/all/read`;
    return axios.post(url).then((response) => {
      // console.log('MARK ALL NOTIFICATIONS AS READ', response);
      return response.data;
    });
  },
  markNotificationAsRead(notificationId) {
    const url = `${socialAPIUrl}/social-api/notifications/${notificationId}/read`;
    return axios.post(url).then((response) => {
      console.log('MARK NOTIFICATION AS READ', response);
      return response.data;
    });
  },
  socialLinkAction({ method, href }) {
    const url = `${socialAPIUrl}${href}`;
    return axios[method.toLowerCase()](url).then((response) => {
      console.log(`${method} ${href}`, response);
      return response.data;
    });
  },
  acceptFollow({ userId }) {
    const url = `${socialAPIUrl}/users/${userId}/accept`;
    return axios.post(url).then((response) => {
      console.log(`Accept Follow`, response);
      return response.data;
    });
  },

  /*****************
   * Socket Methods*
   ****************/
  sendChatMessage(gameId, userId, gameType, msgData) {
    // console.log("API WS Sending message", gameId, userId, msgData, token);
    chatSocket.emit('agregar', userId, gameId, gameType, msgData);
    chatSocket.emit('sendchat', gameId, gameType, msgData);
  },
  subscribeSocket(gameType, gameId, userId, nick, token, cbAllMessages, cbUpdateMessage) {
    // console.log("API WS Chat", gameType, gameId, userId, nick, token);

    // chatSocket = io('https://staging-chat.pickwin.net/', {
    chatSocket = io('https://wschat.pickwin.mx/', {
      secure: true,
      query: {
        token: token,
      },
      transports: ['websocket'],
    });
    chatSocket.on('connect', function() {
      // console.log('onoconnect');
      chatSocket.emit('inicio', gameId, gameType, userId);
      chatSocket.emit('adduser', {
        gameId: gameId,
        gameType: gameType,
        nick: nick,
      });
    });
    chatSocket.on('inicio', function(message) {
      // console.log('WS inicio...',message);
      cbAllMessages(message);
    });
    chatSocket.on('userconected', function(username) {
      // console.log("new user connected", username);
    });
    chatSocket.on('updatechat', function(message) {
      // console.log('MESSAGE RECEIVED', message.message);
      cbUpdateMessage(message);
    });
  },
  /**************************************************
   * Initialize the sockets related to the Quiniela *
   **************************************************/
  subscribeSocketLiveFixtures(
    cbFixtUp,
    cbPropUp,
    cbFixtureClose,
    cbPoolClose,
    cbStandingsUpdated,
    cbStandingsWeeklyUpdated
  ) {
    // console.log('API WS Live Pool');
    // socket = io('wss://wslive.pickwin.mx/livepane', {
    // socket = io('ws://pickwin.astrata.mx:8444/livepane', {
    socketLivePool = io(socketLivePane, {
      secure: true,
      transports: ['websocket'],
    });
    // console.log(socket);s
    socketLivePool.on('fixture:update', (data) => {
      console.log('LiveFixturesUpdate ', data);
      // When match results are updated
      cbFixtUp(data);
    });
    socketLivePool.on('props:update', (data) => {
      //console.log('LivePropUpdate', data);
      cbPropUp(data);
    });
    socketLivePool.on('fixture:close', (fixtureID) => {
      //console.log('Fixture Closed', fixtureID);
      cbFixtureClose(fixtureID);
    });
    socketLivePool.on('pool:close', (poolId) => {
      // console.log('Pool Closed', poolId);
      cbPoolClose(poolId);
    });
    socketLivePool.on('standings:update', (data) => {
      //console.log('Standings Update', data);
      cbStandingsUpdated(data);
    });
    socketLivePool.on('weekly_standings:update', (data) => {
      // console.log('Standings Update', data);
      cbStandingsWeeklyUpdated(data);
    });
  },
  subscribeSocketLiveFixturesFromDashboard(
    cbFixtUp,
    cbPropUp,
    cbFixtureClose,
    cbPoolClose,
    cbStandingsUpdated,
    cbStandingsWeeklyUpdated
  ) {
    console.log('API Connect dashboard Fixtures');
    // socket = io('wss://wslive.pickwin.mx/livepane', {
    // socket = io('ws://pickwin.astrata.mx:8444/livepane', {
    // console.log(socket);s
    socketQuinielasDash.on('fixture:update', (data) => {
      // console.log('LiveFixturesUpdate ', data);
      cbFixtUp(data);
    });
    socketQuinielasDash.on('props:update', (data) => {
      // console.log('LivePropUpdate', data);
      cbPropUp(data);
    });
    socketQuinielasDash.on('fixture:close', (fixtureID) => {
      // console.log('Fixture Closed', fixtureID);
      cbFixtureClose(fixtureID);
    });
    socketQuinielasDash.on('pool:close', (poolId) => {
      // console.log('Pool Closed', poolId);
      cbPoolClose(poolId);
    });
    socketQuinielasDash.on('standings:update', (data) => {
      // console.log('Standings Update', data);
      cbStandingsUpdated(data);
    });
    socketQuinielasDash.on('weekly_standings:update', (data) => {
      // console.log('Standings Update', data);
      cbStandingsWeeklyUpdated(data);
    });
  },
  unSubscribeSocketLiveFixtures() {
    console.log('unsubscribe live fixtures');
    if (socketLivePool !== undefined && socketLivePool !== null) {
      socketLivePool.close();
    }
  },
  subscribeSurvivorSocketLiveStandings(survivorId, survivorStandingUpdate, survivorClose) {
    socketLiveSurvivor = io(socketLivePane, {
      secure: true,
      transports: ['websocket'],
    });

    socketLiveSurvivor.on('connect', () => {
      socketLiveSurvivor.emit('subscribe', Number(survivorId), 'survivor');
    });

    socketLiveSurvivor.on('survivor_standings:update', (standingsData) => {
      survivorStandingUpdate(standingsData);
    });

    socketLiveSurvivor.on('survivor:close', (survivorCloseData) => {
      survivorClose(survivorCloseData);
    });
  },
  subscribeSocketLiveGolf(
    groupPoolId,
    groupPoolContests,
    groupPoolStandingUpdate,
    groupPoolPlayersUpdate,
    groupPoolClose,
    groupPoolRaceResultUpdate,
    groupPoolContestStandingsUpdate,
    dispatch
  ) {
    socketLiveGroupPool = io(socketLivePane, {
      secure: true,
      transports: ['websocket'],
    });

    socketLiveGroupPool.on('connect', () => {
      console.log('Socket connected to', groupPoolId);
      socketLiveGroupPool.emit('subscribe', Number(groupPoolId), 'group_pool');
    });

    groupPoolContests.forEach((contest) => {
      socketLiveGroupPool.emit('subscribe', Number(contest.id), 'gp-contest');
    });

    socketLiveGroupPool.on('group_pool_standings:update', (standingsData) => {
      groupPoolStandingUpdate(dispatch, standingsData);
    });

    socketLiveGroupPool.on('player_results:update', (playersData) => {
      groupPoolPlayersUpdate(dispatch, playersData);
    });

    socketLiveGroupPool.on('group_pool:close', (groupPoolCloseData) => {
      groupPoolClose(dispatch, groupPoolCloseData);
    });

    socketLiveGroupPool.on('race_results:update', (groupPoolReceResultsData) => {
      groupPoolRaceResultUpdate(dispatch, groupPoolReceResultsData);
    });

    socketLiveGroupPool.on('contest_standings:update', (groupPoolContestStandingsUpdateData) => {
      groupPoolContestStandingsUpdate(dispatch, groupPoolContestStandingsUpdateData);
    });
  },

  subscribeSocketLiveGolfMisQuiielas(
    groupPoolId,
    groupPoolContests,
    groupPoolRaceResultUpdate,
    dispatch
  ) {
    socketLiveGroupPool = io(socketLivePane, {
      secure: true,
      transports: ['websocket'],
    });

    socketLiveGroupPool.on('connect', () => {
      console.log('Socket connected to', groupPoolId);
      socketLiveGroupPool.emit('subscribe', Number(groupPoolId), 'group_pool');
    });

    groupPoolContests.forEach((contest) => {
      socketLiveGroupPool.emit('subscribe', Number(contest.id), 'gp-contest');
    });

    socketLiveGroupPool.on('race_results:update', (groupPoolReceResultsData) => {
      groupPoolRaceResultUpdate(dispatch, groupPoolReceResultsData);
    });
  },

  async subscribeSocketLiveGroupPoolDashboard(
    groupPoolSocketItems,
    groupPoolContestsSocketItems,
    groupPoolStandingUpdate,
    groupPoolPlayersUpdate,
    groupPoolClose,
    groupPoolRaceResults,
    dispatch
  ) {
    let response = {
      success: false,
      groupPoolsSocket: {},
      contestsSocket: {},
    };

    try {
      _.each(groupPoolSocketItems, (socketItem, poolId) => {
        if (!socketItem.isConnected) {
          socketQuinielasDash.emit('subscribe', Number(socketItem.groupPoolId), 'group_pool');

          socketQuinielasDash.on('group_pool_standings:update', (standingsData) => {
            groupPoolStandingUpdate(standingsData);
          });

          socketQuinielasDash.on('player_results:update', (playersData) => {
            groupPoolPlayersUpdate(playersData);
          });

          socketQuinielasDash.on('group_pool:close', (groupPoolCloseData) => {
            // console.log( groupPoolCloseData );
            groupPoolClose(groupPoolCloseData);
          });

          socketQuinielasDash.on('race_results:update', (raceResultsData) => {
            //console.log("RACERESULTS", raceResultsData);
            groupPoolRaceResults(raceResultsData);
          });

          response.groupPoolsSocket[socketItem.groupPoolId] = {
            groupPoolId: socketItem.groupPoolId,
            isConnected: true,
            isDisconnected: false,
          };
        } else if (
          socketItem.isConnecting &&
          socketItem.isConnected &&
          socketItem.entries.length < 1
        ) {
          // console.log('Unsubscribe to to', socketItem.groupPoolId );
          socketQuinielasDash.emit('leave', Number(socketItem.groupPoolId), 'group_pool');
          response.groupPoolsSocket[socketItem.groupPoolId] = {
            groupPoolId: socketItem.groupPoolId,
            isConnected: false,
            isDisconnected: true,
          };
        }
      });

      _.each(groupPoolContestsSocketItems, (contestSocketItem, contestId) => {
        // console.log( 'connected', contestSocketItem.isConnected );
        // console.log('Connecting', contestSocketItem.isConnecting );

        if (contestSocketItem.isConnecting && !contestSocketItem.isConnected) {
          //check multi contestID
          let contestArray = contestId.split(",");
          if (contestArray.length > 1) {
            contestArray.map(contest => {
              socketQuinielasDash.emit('subscribe', Number(contest), 'gp-contest');
              response.contestsSocket[contestSocketItem.contestId] = {
                contestsId: contestSocketItem.contestId,
                isConnected: true,
                isDisconnected: false,
              };
            });
          } else {
            socketQuinielasDash.emit('subscribe', Number(contestId), 'gp-contest');
            response.contestsSocket[contestSocketItem.contestId] = {
              contestsId: contestSocketItem.contestId,
              isConnected: true,
              isDisconnected: false,
            };
          }
        } else if (contestSocketItem.isConnecting && contestSocketItem.isConnected) {
          // console.log('Disconnect ', contestSocketItem);
          socketQuinielasDash.emit('leave', Number(contestId), 'gp-contest');
          response.contestsSocket[contestSocketItem.contestId] = {
            contestsId: contestSocketItem.contestId,
            isConnected: false,
            isDisconnected: true,
          };
        }
      });

      response.success = true;
      return response;
    } catch (err) {
      console.error(err);
      return response;
    }
  },
  unSubscribeSurvivorSocketLiveStandings(survivorId) {
    if (socketLiveSurvivor) {
      socketLiveSurvivor.close();
    }
  },
  /**************************************************
   * Initialize the sockets related to the Survivor *
   **************************************************/
  subscribeSocketLiveSurvivor(
    survivorId,
    fixtureUpdate,
    survivorClose,
    fixtureClose,
    survivorStandingsUpdate,
    survivorStatsUpdate
  ) {
    socketLiveSurvivor = io(socketLivePane, {
      secure: true,
      transports: ['websocket'],
    });
    socketLiveSurvivor.on('connect', () => {
      socketLiveSurvivor.emit('subscribe', Number(survivorId), 'survivor');
    });

    socketLiveSurvivor.on('fixture:update', (fixtureData) => {
      fixtureUpdate(fixtureData);
    });

    socketLiveSurvivor.on('fixture:close', (fixtureID) => {
      fixtureClose(fixtureID);
    });

    socketLiveSurvivor.on('survivor:close', function(survivorCloseData) {
      survivorClose(survivorCloseData);
    });
    socketLiveSurvivor.on('survivor_standings:update', function(standingsData) {
      survivorStandingsUpdate(standingsData);
    });

    socketLiveSurvivor.on('survivor_picks_stats:update', function (data) {
      survivorStatsUpdate(data)
    })
  },
  subscribeLiveSurvivorContestSocket(
    survivorId,
    contestId,
    survivorStandingsUpdate,
    survivorClose,
    survivorPlayersUpdate,
    survivorRaceUpdate
  ) {
    socketLiveSurvivor = io(socketLivePane, {
      secure: true,
      transports: ['websocket'],
    });
    socketLiveSurvivor.on('connect', () => {
      socketLiveSurvivor.emit('subscribe', Number(survivorId), 'survivor');
      socketLiveSurvivor.emit('subscribe', Number(contestId), 'gp-contest');
    });

    socketLiveSurvivor.on('survivor_standings:update', function(standingsData) {
      survivorStandingsUpdate(standingsData);
    });

    socketLiveSurvivor.on('survivor:close', function(survivorCloseData) {
      survivorClose(survivorCloseData);
    });

    socketLiveSurvivor.on('player_results:update', function(playerData) {
      console.log('player_results:update');
      survivorPlayersUpdate(playerData);
    });

    socketLiveSurvivor.on('race_results:update', function(playerData) {
      console.log('race_results:update', playerData);
      survivorRaceUpdate(playerData);
    });
  },
  unSubscribeLiveSurvivorSocket() {
    if (socketLiveSurvivor) {
      socketLiveSurvivor.close();
    }
  },
  /*********************************************************
   * Initialize the sockets related to the Lobby live data *
   *********************************************************/
  subscribeSocketLiveLobby(cbPoolUpdate, cbPoolClose, cbPoolRefresh, cbPoolAdd, cbSignOutUser) {
    socketLiveLobbyPool = io(socketLiveLobby, {
      secure: true,
      transports: ['websocket'],
    });

    socketLiveLobbyPool.on('pools:update', function(pool) {
      cbPoolUpdate(pool);
    });

    socketLiveLobbyPool.on('pools:add', function(pool) {
      cbPoolAdd(pool);
    });

    socketLiveLobbyPool.on('pool:close', function(pool) {
      cbPoolClose(pool);
    });

    socketLiveLobbyPool.on('pool:re', function(poolId) {
      cbPoolClose(poolId);
    });

    socketLiveLobbyPool.on('reconnect', function() {
      cbPoolRefresh();
    });

    // /close-sessions
    socketLiveLobbyPool.on('app:signOutUser', function(data) {
      cbSignOutUser(data);
    });
  },

  unSubscribeSocketLobby() {
    console.log('Unsubscribing from:', 'Lobby');
    if (socketLiveLobbyPool) {
      socketLiveLobbyPool.close();
    }
    // socket.off( name , () => { console.log('Unsubscribed from:', name)} );
  },
  /****************************************************************************
   * Initialize the sockets related to the Lobby live data inQuinielaDashboard*
   ****************************************************************************/
  async initilizeSocketQuinielasDashboard() {
    console.log('Init Socket');
    try {
      socketQuinielasDash = io(socketLivePane, {
        secure: true,
        transports: ['websocket'],
      });
      console.log(
        ' %c Socket  from  Quinielas Dashboard initialized ',
        ' font-size:2em; color: #3daee6 ; '
      );
      return { success: true };
    } catch (err) {
      return { success: false, error: err };
    }
  },
  subscribeSocketLiveLobbyQuinielasDashboard(cbPoolUpdate) {
    socketQuinielasDash.on('pools:update', function(pool) {
      console.log('Pool Updated onoconnect', pool);
      cbPoolUpdate(pool);
    });
  },
  unSubscribeSocketQuinielasDashboard() {
    console.log('Unsubscribing from:', 'Quinielas Dashboard');
    if (socketQuinielasDash) {
      socketQuinielasDash.close();
    }
    // socket.off( name , () => { console.log('Unsubscribed from:', name)} );
  },
  updateChat: function updateChat(message, cb) {
    console.log('%c chat updated with', 'font-size:20px;');
    console.log('chat updated with:', message, cb);
    chatSocket.on('updatechat', function(message) {
      cb(message);
    });
  },
  trackPoolRegisterFB: function(pool) {
    const tournamentNames = pool.tournament_instances.map((tinstance) => {
      if (!tinstance.tournament) {
        return null;
      }
      return tinstance.tournament.name;
    });
    const tournamentIds = pool.tournament_instances.map((tinstance) => {
      if (!tinstance.tournament) {
        return null;
      }
      return tinstance.tournament.id;
    });
    window.fbq('track', 'PageView');
    window.fbq('trackCustom', 'registroQuiniela', {
      pool_id: pool.id,
      sport: pool.sport.name,
      tournaments: JSON.stringify(tournamentNames),
      tournament_ids: JSON.stringify(tournamentIds),
    });
  },
  trackPurchaseSuccessFB: function(transaction) {
    const user = JSON.parse(localStorage.getItem('user'));

    console.log('Track Purchase', transaction);
    window.fbq('init', '551771658321328', {
      external_id: user.id,
      em: user.email,
    });
    window.fbq('track', 'PageView');
    window.fbq('track', 'Purchase', {
      value: Number(transaction.amount).toFixed(2),
      currency: 'MXN',
      external_id: user.id,
      email: user.email,
    }, {eventID: transaction.transactionId});
    // fbq('trackCustom', 'purchaseSuccess', transaction);
  },
  trackWagerFB: function(amount) {
    window.fbq('track', 'PageView');
    window.fbq('trackCustom', 'PosturaRealizada', {
      amount: amount/100
    });
  },
  fetchUserRewardHistory(period) {
    let url = `${APIurl}/api/rewards/history.json`;
    if ( period !== null ) {
      url = `${APIurl}/api/rewards/history.json?period=${period}`;
    }
    return axios.get(url);
  },
  userRewardVideo(data) {
    let url = `${APIurl}/api/animations/seen.json`;
    return axios.post(url, data);
  },
  getLevelAnimations () {
    return axios.get(`${APIurl}/api/animations/pending.json`)
  },
  getLevelBenefitList () {
    return axios.get(`${APIurl}/api/levels/list.json`)
  },
  markAnimationsWatched (data) {
    return axios.post(`${APIurl}/api/animations/mark.json`, data)
  },
  getUserBonuses () {
    return axios.get(`${APIurl}/api/user_bonuses/list.json`)
  },
  getUserPopups () {
    return axios.get(`${APIurl}/api/popups/list.json`)
  },
  postMarkSeenPopup (popupId) {
    return axios.post(`${APIurl}/api/popups/markSeen.json`, { popup_id: popupId })
  },
  getPurchaseBanner () {
    return axios.get(`${APIurl}/api/popups/purchaseBanner.json`)
  },
  // Store
  getStoreProducts () {
    return axios.get(`${APIurl}/api/products/index.json`)
  },
  getStoreProductDetails (productID) {
    return axios.get(`${APIurl}/api/products/view/${productID}.json`)
  },
  postProductPurchase (data) {
    return axios.post(`${APIurl}/api/products/exchange.json`, data)
  },
  getUserWagers(page, status, range) {
    let url = `${APIurl}/api/wagers/mine.json?page=${page}&status=${status}&range=${range}`;
    return axios.get(url);
  },
  getWagerPdf(id) {
    return axios.get(`${APIurl}/api/wager_pdfs/pdf/${id}.json`);
  },
  getFixturesSummary() {
    let url = `${APIurl}/api/live_fixtures/summary.json`;
    return axios.get(url);
  },
  getAllLiveFixtures() {
    let url = `${APIurl}/api/live_fixtures/get_live.json`;
    return axios.get(url);
  },
  getFixturesLive(sportId) {
    let url = `${APIurl}/api/live_fixtures/get_live/${sportId}.json`;
    return axios.get(url);
  },
  getInfoFixturesLive(fixtures) {
    let url = `${APIurl}/api/live_fixtures/get_info.json`;
    return axios.post(url, fixtures);
  },
  getInfoIframe(params) {
    let url = `${APIurl}/api/partners/register/${params.product}.json`;
    return axios.post(url, params);
  },

  //Incode
  incodeSaveSession(params) {
    let url = `${APIurl}/api/incode_sessions/save_session.json`;
    return axios.post(url, params);
  },
  sendIncodeSms(params) {
    let url = `${APIurl}/api/incode_sessions/send_sms.json`;
    return axios.post(url, params);
  },
  addSignature(params) {
    let url = `${APIurl}/api/incode_sessions/add_signature.json`;
    return axios.post(url, params);
  },
  getIncodeOnBoarding() {
    let onBoarding = window.OnBoarding.create({
      apiKey: ApiIncodeKey,
      apiURL: ApiIncodeUrl,
      lang: 'es',
      countryCode: 'MX',
    });
    return onBoarding;
  },
  getIncodeResults(params) {
    let url = `${APIurl}/api/incode_sessions/check_results.json`;
    return axios.post(url, params);
  },
  incodeSignVoucher(params) {
    let url = `${APIurl}/api/incode_sessions/sign_voucher.json`;
    return axios.post(url, params);
  },
  getContestStandings(groupPoolId, contestId) {
    let url = `${APIurl}/api/contest_standings/get/${groupPoolId}/${contestId}.json`;
    return axios.get(url);
  },
  getMyGamesCasino(page, range) {
    let url = `${APIurl}/api/casino_rounds/mine.json?page=${page}&range=${range}`;
    return axios.get(url);
  },
  closeAllSessions(token) {
    let url = `${APIurl}/api/user_logins/close_active_sessions.json`;
    let config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };
    return axios.post(url, null, config);
  },
  checkOtherSessionsUser() {
    let url = `${APIurl}/api/user_logins/check.json`;
    return axios.get(url);
  },
  getActiveSessions(token=null) {
    let url = `${APIurl}/api/user_logins/get_active_sessions.json`;
    if (token === null) {
      return axios.get(url);
    } else {
      return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
    }
  },
  reactivateCurrentSession(token=null) {
    let url = `${APIurl}/api/user_logins/reactivate.json`;
    if (token === null) {
      return axios.post(url);
    } else {
      return axios.post(url, '', { headers: { Authorization: `Bearer ${token}` } });
    }
  },
  redeemBono(params) {
    let url = `${APIurl}/api/user_bonuses/redeem.json`;
    return axios.post(url, params);
  },
  getMarktimes(params) {
    let url = `${APIurl}/api/users/marktimes.json`;
    return axios.post(url, params);
  },
  getBillboards() {
    let url = 'https://pickwinfree.firebaseio.com/configuraciones/banners-new.json';
    return axios.get(url, { headers: { Authorization: '' } });
  },
  getZendeskSuggestions() {
    let url = 'https://pickwinfree.firebaseio.com/zendesk-suggestions.json';
    return axios.get(url, { headers: { Authorization: '' } });
  },
  getCashoutAccounts() {
    let url = `${APIurl}/api/cashout_accounts/getAccounts.json`;
    return axios.get(url);
  },
  deleteCashoutAccount(id) {
    let url = `${APIurl}/api/cashout_accounts/delete/${id}.json`;
    return axios.get(url);
  },
  getTennisBracketGroupPicks(bracketId, stageId, query) {
    let url = `${APIurl}/api/bracket_standings/tennis_group_picks/${bracketId}/${stageId}.json?page=${query.page}&limit=${query.limit}`;
    return axios.get(url);
  },
  getSurveys() {
    let url = `${APIurl}/api/surveys/available.json`;
    return axios.get(url);
  },
  saveSurvey(data) {
    let url = `${APIurl}/api/surveys/respond.json`;
    return axios.post(url, data);
  },
  getBracketGroupPicksPerfect(bracketId, stageId) {
    let url = `${APIurl}/api/bracket_standings/group_picks/${bracketId}/${stageId}.json`;
    return axios.get(url);
  },

  // bracket scoreboard
  getTournamentFixtures(tournamentInstanceId) {
    let url = `${APIurl}/api/fixtures/tournament/${tournamentInstanceId}.json`;
    return axios.get(url);
  },

  // Pariplay

  getGames() {
    return axios.get( `${APIurl}/api/games_catalog/list.json`);
  },

  getSBGames() {
    return axios.get( `${APIurl}/api/pariplay/get_sb_games.json`);
  },

  getGamesList() {
    return axios.get( `${APIurl}/api/pariplay/get_games.json`);
  },

  getJackpotsList() {
    return axios.get( `${APIurl}/api/pariplay/get_jackpots.json`);
  },

  launchGame(id, native) {
    return axios.post( `${APIurl}/api/pariplay/launch_game.json`, {
      id: id,
      native: native
    });
  },
  /**
   * launchDemoGame
   * @param {string} gameCode
   * @returns
   */
  launchDemoGame(id, native) {
    return axios.post( `${APIurl}/api/pariplay/launch_demo_game.json`, {
      id: id,
      native: native
    });
  },

  launchCasino() {
    return axios.post( `${APIurl}/api/pariplay/launch_casino.json`, {
      native: false,
    });
  },

  closeGameSession(data) {
    return new Promise((resolve, reject) => {
      try {
        const jwtoken = getToken()
        var xhr = new XMLHttpRequest()
        // Using synchronous XHR since beforeunload doesn't wait for async calls var xhr = new XMLHttpRequest();
        xhr.open('POST', APIurl + '/api/pariplay/end_game_session.json');
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.setRequestHeader('Authorization', `Bearer ${jwtoken}`);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.send(JSON.stringify(data));
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  getPPCasinoRounds(params) {
    var args = new URLSearchParams(params);
    return axios.get( `${APIurl}/api/pariplay_rounds/mine.json?${args}`);
  },
  getPPTransactions(tokenId) {
    return axios.get( `${APIurl}/api/pariplay_transactions/list/${tokenId}.json`);
  },
  launchSB() {
    return axios.post( `${APIurl}/api/pariplay/launch_sb.json`);
  },
  launchSBDemo() {
    return axios.post( `${APIurl}/api/pariplay/launch_sb_demo.json`);
  }




};

export default API;
