//Libraries
import React from 'react';
import moment from 'moment';

//Components
import PageWrapper from '../PageWrapper';
import utils from '../../utils/utils';
import API from '../../utils/api';

//Context
import { UserDataContextConsumer } from '../../context/UserDataContext';
import useAuthContext from '../../hooks/useAuthContext';

const CloseSessions = () => {
  const [error, setError] = React.useState(null);
  const [sessions, setSessions] = React.useState([]);
  const { isAuth } = useAuthContext()

  const closeAllSessionsClicked = () => {
    let tmpToken = window.localStorage.getItem("tmpToken");
    API.reactivateCurrentSession(tmpToken)
    .then((response) => {
      const data = response.data;
      if ( data.success ) {

        const redirectPath = localStorage.getItem('redirectAfterLogin') || '/'

        const tempStorageItems = ['tmpToken', 'redirectAfterLogin']

        tempStorageItems.forEach(
          item => localStorage.removeItem(item)
        )

        // Redirect to cached prev path
        window.location.assign(redirectPath)
      } else {
        alert('Algo no funciono');
      }
    }).catch((error) => {
      alert(error);
    });
  }

  React.useEffect(() => {
    let tmpToken = window.localStorage.getItem("tmpToken");
    if (isAuth) {
      API.getActiveSessions(tmpToken).then((response) => {
        setSessions(response.data.sessions);
      });
    } else {
      window.location.href = '/'
    }

    API.checkOtherSessionsUser().then((response) => {
      // Redirect if user don't have another active sesssions
      if (response?.data?.valid && !response?.data?.active_sessions) {
        window.location.href = '/'
      }
    })
  }, [isAuth]);

  return (
    <PageWrapper>
      <UserDataContextConsumer>
        {() => {
          return (
            <div className="container">
              <div className="col-xs-8 col-xs-offset-2 col-md-6 col-md-offset-3 validation-content">
                <div className="validation-header-title flex-container align">
                  <span className="ml-1">SESIONES ABIERTAS</span>
                </div>
                <div className="col-md-12" style={{padding: '0 2rem'}}>
                  <h3 className="text-center green-text ff-chivo">Tienes sesiones abiertas en otros dispositivos</h3>
                  <div className="flex-row mb-2">
                    <div className="flex-col">
                      Únicamente puedes tener una sesión activa en todo momento, para poder acceder en este dispositivo necesitas cerrar tus sesiones activas
                    </div>
                  </div>
                  {sessions.map((item, index) => {
                    return (
                      <div key={index}>
                       <div className="flex-row mb-1">
                          <div className="flex-col">
                            {moment(item.created).format('DD-MM-YY hh:mm a')}
                          </div>
                        </div>
                        <div className='flex-row'>
                          <div className="flex-col col-70">{item.ua}</div>
                          <div className="flex-col col-30" style={{overflowWrap: 'break-word'}}>
                              {item.ip}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="flex-row mt-2 text-center">
                    <div className="flex-col">
                      {error != null ?
                        <h4>{error}</h4>
                        : null}
                      <button type="button" className="btn-lobby btn btn-default" onClick={() => closeAllSessionsClicked() }><span>CERRAR SESIONES</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </UserDataContextConsumer>
    </PageWrapper>
  );
}

export default CloseSessions;
