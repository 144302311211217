import styled from 'styled-components'

const IconButton = styled.button`
  color: #FFF;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2em;
  border-radius: 1em;
  background-color: #00000026;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-132%);
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

export default IconButton
