import React from 'react'

// Components
import Typography from '../../Typography'
import Stack from '../../Stack'
import Box from '../../Box'
import ButtonSelect from './ButtonSelect'

MultiOptionRH.defaultProps = {
  title: '',
  subtitle: '',
  value: '',
  ketName: '',
  options: []
}

export default function MultiOptionRH (props) {
  return (
    <Box maxWidth={500} mx='auto'>
      <Typography center size='1.1em' mt={20} mb={5} weight='bold'>{props.title}</Typography>
      <Typography center color='#8d8d8d' size='0.85em'>{props.subtitle}</Typography>

      {props.error && (
        <Typography center color='#ff542a' size='0.9em' my={5}>
          <Typography mr={5} as='span'>⬤</Typography>
          {props.error}
        </Typography>
      )}

      <Stack wrap direction='row' spacing='1em' justify='center' mt={20} mb={20}>
        {props.options?.map((option, index) => (
          <ButtonSelect
            key={index}
            onClick={() => props.onChange({ [props.keyName]: option.value })}
            active={props.value === option.value}
          >
            {option.name}
          </ButtonSelect>
        ))}
      </Stack>
    </Box>
  )
}
