import React from 'react';

import NavigationBar from '../NavigationBar';
import Billboard from '../Billboard';
import Pool from '../Pool';
import utils from '../../utils/utils';

class LobbyUsers extends React.Component{
  constructor(props){
    super(props);
    this.state =  this.props.location.state
    console.log('LobbyUsers',this);
  }

  componentDidMount() {
    this.setState(() => {
      return {
        mounted: true,
        userData: utils.getUserInfo(),
      }
    });

    // API.fetchUserData()
    // .then( (user) =>{
    //   this.setState( () => {
    //      console.log('HOME',this);
    //     return {
    //       mounted: true,
    //       userData: user,
    //     };
    //   });
    //   console.log('HOME',this);
    // });
    // console.log('LobbyUsers',this.state);
  }

  render(){
    return(
      <div className='container-fluid'>
        <NavigationBar
          logged={this.state.loggedIn}
          userData={this.state.userFullData}  />
        <Billboard logged='true'/>
        <Pool
          popUpView={this.props.popUpView}
          popUpPoolId={this.props.popUpPoolId}
          logged={this.state.loggedIn}
          userData={this.state.userFullData}/>
      </div>
    )
  }
}

export default LobbyUsers