import React from 'react'
import ReactDOM from 'react-dom'

// Bootstrap App
import * as serviceWorker from './serviceWorker'
import './utils/branch-start'
import './services/analytics'
import './seo'

// Pollyfills
import 'url-search-params-polyfill'
import './utils/polyfills/object-values'
import 'url-search-params-polyfill'

// Services third party
import './services/zendesk'

import App from './App'

// Global css styles
import './assets/stylesheets/styles.scss'
import 'react-loading-skeleton/dist/skeleton.css'


ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

