import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import { Icon as Iconify } from '@iconify/react'

function TextField (props, ref) {
  const [focus, setFocus] = useState(false)
  const [showPassword, setShowPassword] = useState(props.type === 'password')

  const togglePassView = () => setShowPassword(prev => !prev)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      if (typeof props.onEnter === 'function') {
        props.onEnter()
      }
    }
  }

  const handleChange = (event) => {
    if (typeof props.onChange === 'function') {
      props.onChange(props.name, event.target.value)
    }
  }

  let type = 'text'

  if (props.type === 'password' && showPassword) {
    type = 'password'
  }

  return (
    <Box className={props.className}>
      {props.label && (
        <Label>{props.label}</Label>
      )}
      <Field style={props.style} focus={focus} className={props.className}>
        {props.iconSrc && <Icon src={props.iconSrc} />}
        <Input
          focus={focus}
          ref={ref}
          spellCheck={false}
          value={props.value}
          onChange={handleChange}
          disabled={props.disabled}
          placeholder={props.placeholder}
          onKeyDown={handleKeyDown}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          pattern={props.pattern}
          minLength={props.minLength}
          maxLength={props.maxLength}
          type={type}
        />
        {props.type === 'password' && (
          <IconPassword onClick={togglePassView}>
            <Iconify icon={showPassword ? 'icomoon-free:eye' : 'icomoon-free:eye-blocked'} />
          </IconPassword>
        )}
      </Field>
      {props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Box>
  )
}

export default forwardRef(TextField)

const Label = styled.label`
  font-size: 0.8em;
  padding-left: 1em;
  margin-top: 1.5em;
  opacity: 0.5;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
`

const IconPassword = styled.div`
  position: absolute;
  right: 0;
  width: 1.3em;
  color: #88ba44;
  cursor: pointer;
  font-size: 1.3em;
`

const Field = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-bottom: 2px solid #78bc28;
  padding: 0.5em;
  box-shadow: ${props => props.focus ? '0px 13px 20px -8px #83ba4e8c' : '0px 9px 24px 1px transparent'};
  transition: box-shadow 500ms ease;
  will-change: box-shadow;
  position: relative;
  overflow: hidden;
  margin: 0 0.2em;
  width: auto;
`

const ErrorMessage = styled.div`
  line-height: 3;
  text-align: center;
  color: red;
`

const Icon = styled.img`
  height: 1.4em;
  color: #FFF;
  margin-right: 1em;
`

const Input = styled.input`
  border: none;
  outline: none;
  font-size: 1.2em;
  background-color: transparent;
  flex-grow: 2;

  /* Chrome/Opera/Safari */
  ::-webkit-input-placeholder {
    color: #FFF;
    color: ${props => props.focus ? 'gray' : '#FFF'};
  }
  /* Firefox 19+ */
  ::-moz-placeholder { 
    color: #FFF;
    color: ${props => props.focus ? 'gray' : '#FFF'};
  }
  /* IE 10+ */
  :-ms-input-placeholder {
    color: #FFF;
    color: ${props => props.focus ? 'gray' : '#FFF'};
  }
  /* Firefox 18- */
  :-moz-placeholder { 
    color: #FFF;
    color: ${props => props.focus ? 'gray' : '#FFF'};
  }
`
