import React from 'react'
import styled from 'styled-components'
import Iconify from '../../Iconify/Iconify'

export default function Tabs ({ tabs, active, setActive }) {
  return (
    <Box>
      <Overflow>
        {tabs.map(({ title, icon }, index) => (
          <Item
            key={index}
            active={active === title}
            onClick={() => setActive(title)}
          >
            <Iconify icon={icon} />
            <span>{title}</span>
          </Item>
        ))}
      </Overflow>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: 1em;
  max-width: 750px;
  height: 60px;
  margin: 1em auto;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  margin-bottom: 0.4em;
  background: linear-gradient(359deg,#1e1d1d,#000000);
  box-shadow: 0px 1px 2px inset #9d9c9c, 1px 0px 9px inset #1c1c1c9e;
`

const Overflow = styled.div`
  display: flex;
  width: 100%;
  min-width: 350px;
  overflow-y: hidden;
  justify-content: center;
  overflow-x: auto;
  padding: 0 1em;
  gap: 4px;
`

const Item = styled.div`
  user-select: none;
  padding: 1em;
  font-size: 0.64em;
  cursor: pointer;
  font-family: 'Chivo';
  font-weight: 400;
  border-radius: 0.4em;
  transition: all 250ms ease;
  text-shadow: ${({ active }) => active ? 'initial' : '0px 1px 2px black'};
  background-color: ${({ active }) => active ? '#87b13d' : 'transparent'};
  color: ${({ active }) => active ? '#000' : '#FFF'};
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: 1px solid transparent;
  margin: 0.3em 0;
  min-width: 110px;
  flex-shrink: 0;

  svg {
    font-size: 1.5em;
  }

  span {
    padding-top: 0.3em;
  }

  &:hover {
    opacity: 0.7;
    border: 1px solid gray;
  }

  @media screen and (min-width: 750px) {
    font-size: 0.77em;
  }
`
