import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '@iconify/react'

// Hooks
import useStyleShortcuts from '../../hooks/useStyleShortcuts'

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  style: PropTypes.object
}

export default function Iconify (props) {
  const { icon, style, ...others } = props
  const buildStyles = useStyleShortcuts(props)

  return (
    <Box
      icon={icon}
      style={buildStyles}
      {...others}
    />
  )
}

const Box = styled(Icon)`
  flex-shrink: 0;
  line-height: 1;
`
