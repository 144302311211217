
const STEPS = [
  'WELCOME',
  'SEARCH',
  'CREATE_TYPE',
  'QUINIELA_NAME',
  'QUINIELA_SPORT',
  'QUINIELA_DURATION',
  'QUINIELA_PRICE',
  'QUINIELA_AWARDS',
  'QUINIELA_SUCCESS'
]

const initialQuiniela = {
  affiliate_commission: 0,
  autopicks: null,
  end_percentage: 50,
  entry_fee: null,
  limited_capacity: false,
  limit_multientries: false,
  max_multientries: 0,
  max_entries: null,
  name: '',
  password: '',
  point_format: 'natural',
  prize_scheme: 'dynamic', // dynamic | custom
  prizes_list: '3',
  send_notifications: true,
  sport_id: 0,
  tiebreaker_type: null, // null || 'new',
  tournament_instances: {
    _ids: [0]
  },
  type: '', // full | 'week'
  use_tibreakers: null, // null | true
  weekly_percentage: 50,
  weekly_prize_scheme: 'top',
  weekly_prize: false,
  pool_prizes: []
}

const getCurrentStepNumber = (step) => {
  switch (step) {
    case 'QUINIELA_NAME': return 1
    case 'QUINIELA_SPORT': return 2
    case 'QUINIELA_DURATION': return 3
    case 'QUINIELA_PRICE': return 4
    case 'QUINIELA_AWARDS': return 5
    case 'SURVIVOR_NAME': return 6
    case 'SURVIVOR_SPORT': return 7
    case 'SURVIVOR_DURATION': return 8
    case 'SURVIVOR_PRICE': return 9
    case 'SURVIVOR_AWARDS': return 10
  }
}

export const quinielaSlice = (set) => ({
  step: STEPS[0],
  quinielaData: initialQuiniela,
  activeTournamentID: null,
  createdPool: null,
  currentStepNumber: 1,
  close: () => set({ showWizard: false, step: STEPS[0], quinielaData: initialQuiniela, currentStepNumber: 1 }),
  open: () => set({ showWizard: true }),
  setStep: (step) => set({ step, currentStepNumber: getCurrentStepNumber(step) }),
  setActiveTournamentID: (activeTournamentID) => set({ activeTournamentID }),
  setQuinielaData: (nextState) => set(state => ({ quinielaData: { ...state.quinielaData, ...nextState } })),
  setCreatedPool: (createdPool) => set({ createdPool })
})
