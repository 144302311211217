import React from 'react'
import styled from 'styled-components'
import { PulseLoader } from 'react-spinners'

// Assets
import PickImage from '../../../assets/img/Pick.png'

import iconMail from '../../../assets/img/levels/icons/icono-mail.png'
import iconOk from '../../../assets/img/levels/icons/icono-ok.png'
import iconWa8 from '../../../assets/img/levels/icons/icono-whats-8h-20h.png'
import iconWaVip from '../../../assets/img/levels/icons/icono-whats-vip.png'
import iconWa24 from '../../../assets/img/levels/icons/icono-whats-24h.png'


ListComponent.defaultProps = {
  list: []
}

function ListComponent (props) {
  if (!props.list.length) return (
    <PulseLoader
      color='#78BC28'
      size={12}
    />
  )

  return (
    <List>
      {props.list.map((item, index) => (
        <li key={index}>
          <span>{item.name}</span>
          <IconBox>
            {getBenefitType(item.value)}
          </IconBox>
        </li>
      ))}
    </List>
  )
}

const getBenefitType = ({ type, value }) => {
  if (type === 'text') return <span>{value}</span>
  // If is type image
  switch (value) {
    case 'iconOk': return <img src={iconOk} alt='Pick' />
    case 'iconMail': return <img src={iconMail} alt='Pick' />
    case 'iconWa8': return <img src={iconWa8} alt='Pick' />
    case 'iconWaVip': return <img src={iconWaVip} alt='Pick' />
    case 'iconWa24': return <img src={iconWa24} alt='Pick' />
  }

  // Default pick
  return <img src={PickImage} alt='Pick' />
}

const IconBox = styled.div`
  width: 3em;
  display: flex;
  justify-content: center;
  
  img {
    width: 2em;
    display: block;
  }
`

const List = styled.ul`
  max-width: 350px;
  padding-left: 0;
  margin: 1em 0;
  font-size: 0.9em;

  li {
    background-color: #1B1B1B;
    padding: 0.3em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li:nth-child(2n) {
    background-color: #313131;
  }
`

export default ListComponent
