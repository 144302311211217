import React, { useState } from 'react'

// Components
import Tabs from './Tabs'

// Sections
import Referrals from './components/Referrals'
import ReferralsVIP from './components/ReferralsVIP'
import MyCards from './components/Cards/MyCards'
import MyAccount from './components/MyAccount'
import ListadoAmigos from '../../ListadoAmigos'
import NotificationControls from '../../NotificationControls'
import { NotificationControlsContextProvider } from '../../../context/NotificationControlsContext'

// Config
import config from '../../../config'

export default function Account (props) {
  const [active, setActive] = useState(config.accountMenu[0].title)

  const getSection = (type) => {
    switch (type) {
      case 'Mi Cuenta': return (
        <MyAccount {...props} />
      )
      case 'Amigos': return (
        <ListadoAmigos {...props} />
      )
      case 'Invitados': return (
        <Referrals {...props} />
      )
      case 'Invitados VIP': return (
        <ReferralsVIP {...props} />
      )
      case 'Mis Tarjetas': return (
        <MyCards {...props} />
      )
      case 'Notificaciones': return (
        <NotificationControlsContextProvider>
          <NotificationControls />
        </NotificationControlsContextProvider>
      )
    }
  }

  return (
    <div>
      <Tabs
        tabs={config.accountMenu}
        active={active}
        setActive={setActive}
      />
      <div>
        {getSection(active, props.userData)}
      </div>
    </div>
  )
}
