import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import _ from 'underscore'
import * as Sentry from '@sentry/browser'

// Components
import TextField from '../Form/TextField'
import Button from '../Form/Button'
import Checkbox from '../Form/Checkbox'
import TextLink from '../Form/TextLink'
import HelpText from '../Form/HelpText'
import Logo from './Logo'
import Title from './Title'
import PrevModalButton from '../ModalPanel/PrevModalButton'
import ModalBody from '../ModalPanel/ModalBody'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Utils
import utils from '../../utils/utils'
import API from '../../utils/api'

const initial = { value: '', errorMessage: null, status: 'warning', isValid: false }

export default function Signup (props) {
  const usernameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const referalRef = useRef()
  const passwordConfirmationRef = useRef()

  const [nick, setNick] = useState(initial)
  const [password, setPassword] = useState(initial)
  const [cpassword, setCPassword] = useState(initial)
  const [email, setEmail] = useState(initial)
  const [referalCode, setReferal] = useState('')

  const [acceptTerms, setAcceptTerms] = useState(false)
  const [checkAge, setCheckAge] = useState(false)
  const [checkGetNews, setCheckGetNews] = useState(true)

  const {
    submitting,
    signupEmail,
    fingerPrint,
    openLoginModal,
    responseFacebook
  } = useAuthContext()

  useEffect(() => {
    API.fetchSportsIndex()
      .then((response) => {})
      .catch((error) => {
        Sentry.captureException(error, { extra: 'Error FetchingSports' })
      })

    const referal = utils.getReferer()

    if (referal) {
      setReferal(referal)
    }
  }, [])

  //  This Function Sends the Register to the server
  const submit = () => {
    let userMetadata = utils.getUserMetadata()
    userMetadata = _.map(userMetadata, function (val, key) {
      return { name: key, value: val }
    })
    // push currentUrl
    userMetadata.push({ name: 'currentUrl', value: window.location.href })

    const data = {
      fingerprint: fingerPrint,
      referalCode,
      nick: nick.value,
      email: email.value,
      password: password.value,
      cpassword: cpassword.value,
      tos: acceptTerms ? '1' : false,
      oldenough: checkAge ? '1' : false,
      newsletter: checkGetNews ? '1' : false,
      users_metadata: userMetadata,
      source: 'web',
    }

    signupEmail(data, (response) => {
      if (response.data.errors && !response.data.success) {
        const errors = response.data.errors
        // ['email', 'nick']
        const errorsKeys = Object.keys(errors)
        errorsKeys.forEach((err) => {
          const errorTypeNick = Object.keys(errors[err])
          const errorMessage = errors[err][errorTypeNick[0]]
          switch (err) {
            case 'nick':
              setNick(prevState => ({
                ...prevState,
                errorMessage
              }))
              break
            case 'email':
              setEmail(prevState => ({
                ...prevState,
                errorMessage
              }))
              break
          }
        })
      }
    })
  }

  const setValue = (key, value) => {
    const regexMail = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

    if (key === 'nick') {
      if (value === '') {
        return setNick({ status: 'warning', value })
      }
      if (value.length < 4 || value.length > 12) {
        return setNick({
          value,
          status: 'error',
          errorMessage: 'Tu Nick debe de contener de 4 a 12 caracteres'
        })
      }
      return setNick({ status: 'success', value })
    }

    if (key === 'email') {
      if (value === '') {
        return setEmail({ status: 'warning', value })
      }
      if (value.length > 3) {
        if (regexMail.test(value)) {
          setEmail({ status: 'success', value })
        } else {
          setEmail({ status: 'error', value, errorMessage: 'Email Invalido' })
        }
      } else {
        setEmail({ status: 'error', value })
      }
    }

    if (key === 'password') {
      if (value === '') {
        return setPassword({ status: 'warning', value })
      }
      if (value.length > 0 && value.length < 6) {
        return setPassword({
          value,
          status: 'error',
          errorMessage: 'Debe contener minimo 6 caracteres'
        })
      }
      setPassword({ status: 'success', value })
    }

    if (key === 'cpassword') {
      if (value === '') {
        return setCPassword({ status: 'warning', value })
      }
      if (value.length >= 6 && password.value === value) {
        setCPassword({ status: 'success', value })
      } else if (password.value.length && value.length) {
        setCPassword({ status: 'error', value, errorMessage: 'Tus contraseñas no son iguales' })
      } else {
        setCPassword({ status: 'error', value })
      }
    }
  }

  const isValidForm = (
    nick.status === 'success' &&
    email.status === 'success' &&
    password.status === 'success' &&
    cpassword.status === 'success' &&
    // Checkboxes
    acceptTerms && checkAge
  )

  return (
    <ModalBody>
      <PrevModalButton onClick={openLoginModal} />
      <Logo
        src='/images/Pickwin.png'
        alt='Pickwin logo'
      />
      <Title>Crear Cuenta</Title>
      <FacebookLogin
        autoLoad
        appId='973176056074364'
        fields='id ,name ,first_name ,last_name ,gender ,installed ,verified ,email ,picture'
        scope='email, public_profile'
        disableMobileRedirect
        callback={responseFacebook}
        render={renderProps => (
          <Button
            bgColor='#2d5b9b'
            loading={renderProps.isProcessing || submitting === 'facebook-loading'}
            disabled={renderProps.isDisabled}
            onClick={renderProps.onClick}
          >
            Facebook login
          </Button>
        )}
      />
      <TextField
        name='nick'
        ref={usernameRef}
        onChange={setValue}
        errorMessage={nick.errorMessage}
        placeholder='Nombre de usuario'
        iconSrc='/images/Icono usuario.png'
        style={{ marginTop: '1em' }}
      />
      <TextField
        name='email'
        ref={emailRef}
        onChange={setValue}
        placeholder='Email'
        errorMessage={email.errorMessage}
        iconSrc='/images/Icono Mail.png'
      />
      <TextField
        name='password'
        ref={passwordRef}
        onChange={setValue}
        errorMessage={password.errorMessage}
        placeholder='Contraseña'
        iconSrc='/images/Icono candado.png'
        type='password'
      />
      <TextField
        name='cpassword'
        onChange={setValue}
        ref={passwordConfirmationRef}
        errorMessage={cpassword.errorMessage}
        placeholder='Confirmar Contraseña'
        iconSrc='/images/Icono candado.png'
        type='password'
      />
      <TextField
        name='referal'
        ref={referalRef}
        onChange={(_, value) => setReferal(value)}
        placeholder='Código de referido'
        iconSrc='/images/Icono codigo.png'
        style={{ marginBottom: '1em' }}
      />
      <Checkbox
        label='Acepto términos y condiciones del sitio'
        value={acceptTerms}
        onChange={(_, value) => setAcceptTerms(value)}
      />
      <Checkbox
        label='Certifico que soy mayor de 18 años'
        value={checkAge}
        onChange={(_, value) => setCheckAge(value)}
      />
      <Checkbox
        label='Acepto recibir comunicados de Pickwin'
        style={{ marginBottom: '1.6em' }}
        value={checkGetNews}
        onChange={(_, value) => setCheckGetNews(value)}
      />
      <Button
        bgColor='#88ba44'
        loading={submitting === 'email-loading'}
        onClick={submit}
        disabled={submitting === true || !isValidForm}
      >
        Crear cuenta
      </Button>
      <HelpText>¿Ya tienes cuenta? <strong onClick={openLoginModal}>Inicia sesión</strong></HelpText>
    </ModalBody>
  )
}
