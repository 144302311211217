import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { BarLoader, MoonLoader, PuffLoader } from 'react-spinners'

// Components
import Ripple from '../Ripple'
import Iconify from '../Iconify'

// Hooks
import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

// Assets
import lightImage from './light.svg'
import Stack from '../Stack/Stack'

ButtonGlass.propTypes = {
  ...shorthandProptypes,
  onClick: PropTypes.func,
  delayClick: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noRipple: PropTypes.bool,
  rippleColor: PropTypes.string,
  icon: PropTypes.string,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  spinner: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

ButtonGlass.defaultProps = {
  noRipple: false,
  rippleColor: '#bbf15d',
  iconAlign: 'left',
  rippleDuration: 1200,
  loaderColor: '#FFF',
  spinner: 'bar'
}

export default function ButtonGlass (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  const emitClick = (event) => {
    if (typeof props.onChange === 'function') {
      props.onChange(props.data)
    }
  }

  const handleClick = (event) => {
    if (props.disabled) return

    const delay = props.delayClick || (props.delayWithRibble ? props.rippleDuration - 500 : null)

    if (delay) {
      window.setTimeout(emitClick, delay)
    } else {
      emitClick(event)
    }
  }

  const isActive = props.active

  const styles = {
    ...stylesShortcuts,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    borderColor: isActive ? '#8ebf14' : 'gray',
    height: props.height || 70
  }

  return (
    <ButtonBase
      style={styles}
      onClick={handleClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
    >
      <Stack direction='colum' gap='0.2em' style={{ position: 'relative', zIndex: 10 }}>
        {props.image && (
          <Image alt={props.name} src={props.image} />
        )}
        {props.name && <Title>{props.name}</Title>}
        {props.children && props.children}
      </Stack>
      {isActive && (
        <>
          <Light src={lightImage} />
          <Background />
        </>
      )}

      <Ripple
        color={props.rippleColor}
        duration={props.rippleDuration}
      />

      <SpinnerBox>
        {props.loading && getSpinner(props.spinner, props.loaderColor)}
      </SpinnerBox>
    </ButtonBase>
  )
}

const getSpinner = (type, color) => {
  switch (type) {
    case 'bar': return <BarLoader color={color} />
    case 'moon': return <MoonLoader color={color} size={20} />
    case 'puff': return <PuffLoader color={color} size={20} />
  }
}

const Image = styled.img`
  width: 2em;
`

const SpinnerBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  font-size: 1em;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
`

const ButtonBase = styled.button`
  width: 100%;
  max-width: 160px;
  height: 70px;
  border-radius: 0.3em;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  outline: 0;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

const lightmove = keyframes`
  0% {
    transform: rotate(180deg); 
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  50% {
    transform: rotate(365deg);
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(535deg);
  }
`

const Background = styled.div`
  background: linear-gradient(45deg, #002e036b, #8cc63f2e);
  width: 100%;
  height: 100%;
  position: absolute;
`

const Light = styled.img`
  position: absolute;
  pointer-events: none;
  transform-origin: 100;
  transform: rotate(180deg); 
  will-change: transform, opacity;
  animation: ${lightmove} 30s infinite linear;
  opacity: 0.9;
  width: 885px;
  position: absolute;
  bottom: -171px;
  left: -123px;

  &:hover {
    opacity: 0.9;
  }
`
