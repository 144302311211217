import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom'
import numeral from 'numeral'
import {
  Button,
  Nav,
  Navbar,
  NavItem
} from 'react-bootstrap/lib/'

// Components
import AbonarSaldo from '../AbonarSaldo'
import AvatarDrop from '../AvatarDrop'
import PanelQuinielasPrivadas from '../PanelQuinielasPrivadas'
import NotificationCenterPopover from '../NotificationCenterPopover/NotificationCenterPopover'
import HorizontalProgressBar from '../Generals/HorizontalProgressBar'
import GuestNavBar from './GuestNavBar'

// Image assets
import homeIcon from '../../assets/images/lobbyIconVerde-100.png'
import iconQuiniela from '../../assets/images/icon-quiniela.png'
import iconApuestas from '../../assets/images/icon-apuestas.png'
import iconPrivate from '../../assets/images/icon-private.png'
import iconCasino from '../../assets/images/icon-casino.png'
import iconSlots from '../../assets/images/icon-slots.png'
import logoBrand from '../../assets/images/logoMx.png'
import abonarSaldo from '../../assets/images/abonarIconBlanco-50.png'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'
import useMergedState from '../../hooks/useMergedState'

// Utils
import utils from '../../utils/utils'
import ProgressBarLevel from '../ProgressBarLevel/ProgressBarLevel'
import { useWizardPrivate } from '../WizardPrivate/wizardPrivate.store'

/**
 * This function return the Type of bar dependig
 * if the user is logged and the screen size of the device
 */
export default function DrawNavBar (props) {
  const { isAuthenticated } = useAuthContext()
  const [state, setState] = useMergedState({
    showAbonarSaldo: false
  })

  /**
   * This function makes visible the QuinielasPrivaads Panel
   * */
  const showQuinasP = (e) => {
    setState((prevState) => {
      return {
        showQuinielasPrivadas: !prevState.showQuinielasPrivadas
      }
    })
  }

  /**
   * this function hides hthe QuinielasPrivadas PAnel
   * */
  const updateQuinasP = (e) => {
    setState({
      showQuinielasPrivadas: false
    })
  }

  /**
   * This Function makes visible the Abonar Saldo Panel
   * */
  const showAbonarPop = () => {
    setState(() => {
      window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'Abonar' })
      return {
        showAbonarSaldo: true
      }
    })
  }

  /**
   * This Function hides the Abonar Saldo Panel
   * */
  const updatePanelSate = () => {
    window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'Quinielas' })
    setState({
      showAbonarSaldo: false
    })
  }

  if (!isAuthenticated) {
    return (
      <div className='nav-container'>
        <GuestNavBar />
      </div>
    )
  }

  // When user is autthenticated
  return (
    <div className='nav-container'>
      <MediaQuery query='(max-width: 768px)'>
        <UserNavBarMobile
          showAbonarPop={showAbonarPop}
          showAbonarSaldo={state.showAbonarSaldo}
          showQuinasP={showQuinasP}
          showQuinielasPrivadas={state.showQuinielasPrivadas}
          updatePanelSate={updatePanelSate}
          updateQuinasP={updateQuinasP}
          updateUserInfo={props.updateUserInfo}
          updateUserBalance={props.updateUserBalance}
          userBalance={props.userBalance}
          userData={props.userData}
          userLoyalty={props.userLoyalty}
        />
      </MediaQuery>
      <MediaQuery query='(min-width: 769px)'>
        <UserNavBarDesktop
          showAbonarPop={showAbonarPop}
          showAbonarSaldo={state.showAbonarSaldo}
          showQuinasP={showQuinasP}
          showQuinielasP={state.showQuinielasPrivadas}
          updatePanelSate={updatePanelSate}
          updateQuinasP={updateQuinasP}
          updateUserInfo={props.updateUserInfo}
          updateUserBalance={props.updateUserBalance}
          userBalance={props.userBalance}
          userData={props.userData}
          userLoyalty={props.userLoyalty}
        />
      </MediaQuery>
    </div>
  )
}

const getLevelProgress = (userLoyalty) => {
  const { level, points } = userLoyalty
  return ((points.total - level.min_points) / (level.max_points - level.min_points)) * 100
}

/**
 *This function return the UserBar for Desktop
 */
const UserNavBarDesktop = (props) => {
  let {
    updateUserBalance,
    updateUserInfo,
    userData,
    userLoyalty,
    userBalance,
    showAbonarSaldo,
    showAbonarPop,
    showQuinasP,
    showQuinielasP,
    updatePanelSate,
    updateQuinasP
  } = props
  const { logout } = useAuthContext()
  userLoyalty = utils.getUserLoyalty()

  const openWizard = useWizardPrivate(state => state.open)


  let progress = 0
  if (userLoyalty !== undefined && userLoyalty !== null && userLoyalty.points) {
    progress = getLevelProgress(userLoyalty)
  }

  // Check casino url
  const showMyGames = window.location.pathname === '/'
  return (
    <Navbar className='navbar-custom ' fixedTop inverse fluid collapseOnSelect>
      <Navbar.Header>
        <Navbar.Toggle />
        <Navbar.Brand>
          <NavLink exact to='/'>
            <img src={logoBrand} className='pickwin-logo ' width='65px' height='auto' />
          </NavLink>
        </Navbar.Brand>
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className='pickwin-nav-categories'>

          <LinkContainer activeClassName='activeNav' to='/'>
            <NavItem className='nav-item' eventKey={2}>
              <MediaQuery minWidth={1110}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={iconApuestas} height='22' />
                  <div style={{ width: '65px', lineHeight: '0px' }}>
                    <span className='textNavbar'>APUESTAS DEPORTIVAS</span>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
                <img src={iconApuestas} height='22' />
                <div className='nav-subtitle'>APUESTAS DEPORTIVAS</div>
              </MediaQuery>
            </NavItem>
          </LinkContainer>

          <LinkContainer activeClassName='activeNav' to='/casino'>
            <NavItem className='nav-item' eventKey={2}>
              <MediaQuery minWidth={1110}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={iconCasino} height='22' />
                  <span className='textNavbar'>CASINO</span>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
                <img src={iconCasino} height='22' />
                <div className='nav-subtitle'>CASINO</div>
              </MediaQuery>
            </NavItem>
          </LinkContainer>

          <LinkContainer activeClassName='activeNav' to='/slots'>
            <NavItem className='nav-item' eventKey={2}>
              <MediaQuery minWidth={1110}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={iconSlots} height='22' />
                  <div style={{ width: '30px', lineHeight: '0px' }}>
                    <span className='textNavbar'>SLOTS</span>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
                <img src={iconSlots} height='22' />
                <div className='nav-subtitle'>SLOTS</div>
              </MediaQuery>
            </NavItem>
          </LinkContainer>

          <LinkContainer activeClassName='activeNav' exact to='/lobby' style={{ textAlign: 'center' }}>
            <NavItem className='nav-item' eventKey={1}>
              <img src={iconQuiniela} height='22' />
              <MediaQuery maxWidth={1110}>
                <div className='nav-subtitle'>QUINIELAS</div>
              </MediaQuery>
              <MediaQuery minWidth={1110}>
                <span className='textNavbar'>QUINIELAS</span>
              </MediaQuery>
            </NavItem>
          </LinkContainer>

          <LinkContainer activeClassName='activeNav' to='/mis_quinielas' style={{ textAlign: 'center' }}>
            <NavItem className='nav-item' eventKey={2}>
              <img src={iconQuiniela} height='22' />
              <MediaQuery maxWidth={1110}>
                <div className='nav-subtitle'>MIS QUINIELAS</div>
              </MediaQuery>
              <MediaQuery minWidth={1110}>
                <span className='textNavbar'>MIS QUINIELAS</span>
              </MediaQuery>
            </NavItem>
          </LinkContainer>

        </Nav>
        <AbonarSaldo
          showAbonarSaldo={showAbonarSaldo}
          updatePanelSate={updatePanelSate}
          showAbonarPop={showAbonarPop}
          updateUserInfo={updateUserInfo}
          updateUserBalance={updateUserBalance}
          type='modal'
        />
        <Nav className='pickwin-nav-right' pullRight>
          <LinkContainer activeClassName='activeNav' to='/usuario/recompensas'>
            <NavItem className='nav-item'>
              <ProgressBarLevel
                progress={progress}
                userLoyalty={userLoyalty}
              />
            </NavItem>
          </LinkContainer>
          {/* {showMyGames
            ? <LinkContainer activeClassName='activeNav' to='/?mybets=1'>
              <NavItem className='nav-item' eventKey={3}>
                <MediaQuery minWidth={1110}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={iconApuestas} height='22' />
                    <div style={{ width: '70px', lineHeight: '0px' }}>
                      <span className='textNavbar'>MIS APUESTAS</span>
                    </div>
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
                  <img src={iconApuestas} height='22' />
                  <div className='nav-subtitle'>MIS APUESTAS</div>
                </MediaQuery>
              </NavItem>
              </LinkContainer>
            : null} */}

          <NavItem className='nav-item'>
            <div className='quinielas-privadas' onClick={() => {
              //  showQuinasP() // Legacy wizard
               openWizard()
              }}>
              <MediaQuery minWidth={1110}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={iconPrivate} style={{ height: '22px' }} />
                  <div style={{ width: '58px', lineHeight: '0px' }}>
                    <span className='textNavbar'>QUINIELAS PRIVADAS</span>
                  </div>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={1110} style={{ textAlign: 'center' }}>
                <img src={iconPrivate} style={{ height: '22px' }} />
                <div className='nav-subtitle'>QUINIELAS PRIVADAS</div>
              </MediaQuery>
            </div>
          </NavItem>
          <NavItem className='nav-item-notification'>
            <NotificationCenterPopover />
          </NavItem>
          <NavItem className='nav-item-avatar'>
            <AvatarDrop userData={userData} userBalance={userBalance} logout={logout} />
          </NavItem>
          <NavItem className='nav-item'>
            <Button className='btn-pick' bsSize='small' onClick={showAbonarPop}>
              <img className='img-responsive navbar-icons' src={abonarSaldo} />
              <span className='visible-lg'>ABONAR SALDO</span>
              <span className='hidden-lg'>ABONAR</span>
            </Button>
          </NavItem>
        </Nav>
      </Navbar.Collapse>
      <PanelQuinielasPrivadas
        showQuinielasPrivadas={showQuinielasP}
        updatePanelState={updateQuinasP}
      />
    </Navbar>
  )
}

/**
 *This function return the UserBar for Mobile
 */
const UserNavBarMobile = (props) => {
  const {
    userData,
    userBalance,
    showAbonarSaldo,
    updatePanelSate,
    showAbonarPop,
    updateUserInfo,
    updateUserBalance
  } = props
  const { logout } = useAuthContext()

  return (
    <Navbar className='navbar-custom' fixedTop inverse fluid collapseOnSelect style={{ background: 'black' }}>
      <Navbar.Header>
        <div className='flex-row row-center'>
          <Navbar.Brand>
            <NavLink exact to='/'>
              <img src={logoBrand} className='pickwin-logo ' />
            </NavLink>
          </Navbar.Brand>
          <div className='flex-col col-10 col-center col-no-padding'>
            <NotificationCenterPopover />
          </div>
          <div className='flex-col col-no-padding'>
            <AvatarDrop userData={userData} userBalance={userBalance} logout={logout} />
          </div>
          <Navbar.Toggle />
        </div>
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav className='pickwin-nav-categories'>
          <LinkContainer className='nav-item' exact to='/'>
            <NavItem className='nav-item' eventKey={1}>
              <img src={homeIcon} className='img-responsive navbar-icons' />
              <span className='textNavbar'>LOBBY</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer className='nav-item' to='/mis_quinielas'>
            <NavItem className='nav-item' eventKey={2}>
              <img src={iconQuiniela} className='img-responsive navbar-icons' />
              <span className='textNavbar'>MIS QUINIELAS</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer className='nav-item' to='/'>
            <NavItem className='nav-item' eventKey={3}>
              <img src={iconApuestas} className='img-responsive navbar-icons' />
              <span className='textNavbar'>APUESTAS DEPORTIVAS</span>
            </NavItem>
          </LinkContainer>
        </Nav>
        <AbonarSaldo
          type='modal'
          showAbonarSaldo={showAbonarSaldo}
          updatePanelSate={updatePanelSate}
          showAbonarPop={showAbonarPop}
          updateUserInfo={updateUserInfo}
          updateUserBalance={updateUserBalance}
        />
        <Nav className='pickwin-nav-right' pullRight>
          <NavItem className='nav-item'>
            <Button className='btn-pick' bsSize='small' onClick={showAbonarPop}>
              <img className='img-responsive navbar-icons' src={abonarSaldo} />
              <span className=''>ABONAR SALDO</span>
            </Button>
          </NavItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
