import { useState, useEffect, useRef } from 'react'

// This hook simulate the prev legacy setState
// for legacy class components
function useMergedState (initialState) {
  const [state, setState] = useState(initialState)
  const callbackList = useRef([])

  const setStateWithCallback = (newState, callback) => {

    setState(state => {
      // We can pass a function to get previous state
      const nextState = typeof newState === 'function'
        ? {
          ...state,
          ...newState(state)
        }
        : {
          ...state,
          ...newState
        }

      return nextState
    })

    // We store the callbacks for call after
    if(callback) callbackList.current.push(callback)
  }

  useEffect(() => {
    // Call all callbacks and pass new state
    callbackList.current.forEach((callback) => callback(state))

    // Cleaning callback list
    callbackList.current = []
  }, [state])

  return [state, setStateWithCallback]
}

export default useMergedState

