import React , { useReducer, createContext} from 'react';

const ClockContext = createContext();


let initialState = {
  isInitialized: false,
  isLoading: false,
  currentTime: {
    day   : 0,
    hour  : 0,
    min   : 0,
    sec   : 0,
  },
  countdown:{
    days      : 0,
    hours     : 0,
    min       : 0,
    sec       : 0,
  },
  timers    : {},
  type      : null,
  elapsed   : 0,
}

const reducer = ( state , action ) => {
  switch( action.type ){
    case 'isLoading':
      // console.log(iSloading.);
      return {...state, isLoading: action.payload.isLoading }
    case 'updateElapsed':
      // console.log('updateElapsed', action );
      return { 
        ...state, 
        elapsed : Math.floor( action.payload.elapsed ), 
        isInitialized: true
      }
    case 'countdown':
        let newTimer = {  
          id: action.payload.id , 
          timeLeft: action.payload.timeLeft ,
          countdown: action.payload.countdown, 
          isReady: true , 
        };
      
        let tempTimers = state.timers;
        
        tempTimers[action.payload.id] = newTimer;
        
        return {
          ...state , 
          countdown   : action.payload.countdown , 
          timers      : tempTimers,
          timeLeft    : action.payload.timeLeft 
        }
      case 'instanceOfTimer':
        // console.log('Create instance of Timer in each object');
        // console.log( action.payload.timer );
        let timerObj = action.payload.timer;
        // console.log( timerObj );
        if( !timerObj ){
          return { ...state }
        }
        timerObj['timerInstance'] = action.payload.timerInstance ;

        let temporalTimers = state.timers;

        if( temporalTimers[ action.payload.timer.id ] ){
          temporalTimers[ action.payload.timer.id ] = timerObj;
        }


        return { ...state, timers: temporalTimers };
  }
};

const ClockContextProvider = ( props ) => {

  let [ state, dispatch ]  = useReducer( reducer, initialState );
  let value                = { state, dispatch };

  return (
    <ClockContext.Provider value={ value }>
      { props.children }
    </ClockContext.Provider>
  )
};

const ClockContextConsumer = ClockContext.Consumer;

export { ClockContext, ClockContextConsumer, ClockContextProvider };
