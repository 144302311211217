import React from 'react';
import ClockHandler from '../Clock/ClockHandler';
import {
  Popover,
  Button,
  OverlayTrigger,
  DropdownButton,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap/lib/';
import _ from 'underscore';
import ReglasOverlay from '../ReglasOverlay';
import groupPoolActions from '../QuinielaGroupsUI/actions';
import firstBy from 'thenby';
import { Redirect } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import garantizada from '../../assets/images/garantizadaIcon-50.png';
import natural from '../../assets/images/naturalIcon-50.png';
import line from '../../assets/images/lineaIcon-50.png';
import pickcoins from '../../assets/images/pickcoin1-50.png';
import pickcoinsPlata from '../../assets/images/pickcoin1-50-plata.png';
import freeRollIcon from '../../assets/images/currency-gift.png';
import bracket from '../../assets/images/bracketIcon.png';
import registrate from '../../assets/images/flechasIconBlanco-50.png';
import billete from '../../assets/images/cash1-50.png';
import iconUser from '../../assets/images/icon-user.png';
import iconClose from '../../assets/images/icon-close.png';
import iconRegister from '../../assets/images/icon-register.png';
import numeral from 'numeral';
import utils from '../../utils/utils';
import branchUtils from '../../utils/branchUtils';
import './header.scss';

import {
  DisplaySport,
  DisplayIconsType,
  DisplayFlag,
  CancelEntryButton,
  InviteButtonScore,
  DisplayBolsaScore,
} from '../../utils/quinielasComponents';
import API from '../../utils/api';

export class Reglas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    let { gameType, gameInfo, isReferral } = this.props;
    let whichPopover = null;


    if ( gameType === 'bracketPoints' && gameInfo.sport_id == 1 ) {
      whichPopover = popoverReglasPointsBracket;
    } else if ( gameType === 'bracketPerfect' && gameInfo.sport_id == 1 ) {
      whichPopover = popoverReglasPerfectBracket;
    } else if ( gameType === 'bracket' && gameInfo.sport_id == 5 ) {
      whichPopover = popoverReglasBracketTennis
    } else if ( gameType === 'bracket' && gameInfo.sport_id == 3 ) {
      whichPopover = popoverReglasBracket;
    } else if (gameType === 'survivor' && (gameInfo.sport_id !== 6 && gameInfo.sport_id !== 7)) {
      if (!gameInfo.strikes_enabled) {
        whichPopover = <PopoverReglasSurvivor gameInfo={gameInfo}></PopoverReglasSurvivor>;
      } else {
        whichPopover = (
          <PopoverReglasSurvivorStrikes gameInfo={gameInfo}></PopoverReglasSurvivorStrikes>
        );
      }
    } else if (gameType === 'survivor' && gameInfo.sport_id === 6) {
      whichPopover = <PopoverReglasSurvivorContest gameInfo={gameInfo} />;
    } else if (gameType === 'survivor' && gameInfo.sport_id === 7) {
      if (!gameInfo.strikes_enabled) {
        whichPopover = <PopoverReglasSurvivorContestF1 gameInfo={gameInfo} />;
      } else {
        whichPopover = <PopoverReglasSurvivorContestStrikesF1 gameInfo={gameInfo} />;
      }
    } else if (gameType === 'quinielaGrupo') {
      if (gameInfo.sport_id === 6) {
        whichPopover = popoverGroupPoolGolf;
      } else {
        whichPopover = popoverGroupPoolF1;
      }
    } else if (gameInfo.max_picks !== null) {
      whichPopover = textoReglasQuinielaPick5(gameInfo.type === 'full');
    } else {
      if (gameInfo.id === 12408) {
        whichPopover = popoverReglasMundial;
      } else {
        whichPopover = (
          <PopoverReglas
            isReferral={isReferral}
            isAutoPick={gameInfo.autopicks}
            gameInfo={gameInfo}
          />
        );
      }
    }

    return <ReglasOverlay whichPopover={whichPopover} gameType={gameType} gameInfo={gameInfo} />;
  }
}

const LowerRowButtons = ({
  players,
  changeSelectedEntry,
  closeDropdown,
  dispatch,
  entries,
  buckets,
  bucketKeys,
  ties,
  gameInfo,
  getBranchLink,
  handleClickDropdown,
  handleClickFirstRegister,
  handleClickReRegister,
  isSpecial,
  isReferral,
  logged,
  openDrop,
  selectedEntry,
  showInvite,
  status,
  type,
  updateBranchLink,
  updateStateFuncCancel,
  withRegister,
  handlerToggle,
  currentContest,
  entryCurrency,
  contests,
  ticketsAvailable
}) => {
  let conditionalComponent = null;

  const isTicketAndWithoutTickets = !(gameInfo.entry_currency === 'ticket' && !Boolean(ticketsAvailable))

  if (!logged) {
    conditionalComponent = (
      <div className="lower-row row">
        <span
          style={{
            color: '#78bc28',
            fontSize: '1em',
            fontWeight: 'bold',
            margin: '0px auto',
            padding: '0 20px',
            textAlign: 'center',
          }}
        >
          Para poder participar inicia sesión ó crea una cuenta.
        </span>
      </div>
    );
  } else if (isSpecial) {
    /**
     * Special Private
     **/
    conditionalComponent = (
      <div className="lower-row row logged">
        <Reglas gameType={gameInfo.game_type} gameInfo={gameInfo} isReferral={isReferral} />
      </div>
    );
  } else {
    /**
     * User Logged
     **/
    let listEntries = [];
    let gameType = null;
    if (gameInfo.game_type === 'survivor') {
      gameType = 'survivor';
    } else if (gameInfo.game_type === 'bracket') {
      gameType = 'bracket';
      switch (gameInfo.type) {
        case 'bracket':
          if (gameInfo.sport_id === 5) {
            gameType = 'bracketTennisLobby';
          } else {
            gameType = 'bracket';
          }
        break;
        case 'perfect':
          gameType = 'bracketPerfect';
        break;
        case 'points':
          gameType = 'bracketPoints';
        break;
        default:
          gameType = 'bracket';
      }
    } else if (gameInfo.game_type === 'group_pool') {
      gameType = 'quinielaGrupo';
    } else {
      gameType = 'quiniela';
    }

    if (entries !== undefined) {
      listEntries = _.map(entries, (entry, i) => {
        if (!entry) {
          return;
        }
        return (
          <LinkContainer
            key={entry.id}
            data-entry={entry.id}
            // style={
            //   entry.pick_count !== 0
            //     ? { color: '#78b529', fontWeigth: 'bold' }
            //     : { color: '#E00034', fontWeigth: 'bold' }
            // }
            to={`/${gameType}/${gameInfo.slug}/${gameInfo.id}/${entry.id}`}
            onClick={(e) => {
              if (entry.id === selectedEntry) {
                return;
              }
              closeDropdown(e);
              if ( dispatch === undefined ) {
                changeSelectedEntry(entry.id);
              } else  {
                changeSelectedEntry(dispatch, entry.id).then((response) => {
                  groupPoolActions.getSimilarPools(dispatch, entry.id).then((responseSimilar) => {
                    console.log(responseSimilar);
                    console.log('response change ', response, ties, bucketKeys);
                    groupPoolActions.initializeEntry(
                      dispatch,
                      gameInfo.id,
                      buckets,
                      bucketKeys,
                      ties,
                      response.data.group_pool_entry,
                      true,
                      players,
                      currentContest,
                      entryCurrency,
                      contests,
                    );
                  });
                });
              }
            }}
          >
            <ToggleButton
              id={entry.id}
              value={entry.id}
              className={`multiple-entry-button ${entry.id === selectedEntry ? 'current' : ''}`}
              onClick={closeDropdown}
            >
              Entrada #{entry.number === null ? '1' : entry.number}
            </ToggleButton>
          </LinkContainer>
        );
      });
    }

    //type='popup';
    switch (type) {
      case 'popup':
        conditionalComponent = (
          <div className="lower-row row logged">
            <Reglas gameType={gameType} gameInfo={gameInfo} isReferral={isReferral} />
          </div>
        );
        break;
      case 'lobby':
        if (!withRegister) {
          if (gameInfo.status === 'upcoming') {
            conditionalComponent = (
              <div className="lower-row row logged">
                <div className='flex-row' style={{justifyContent: 'center'}}>
                  <div className='col' style={{display: 'flex', alignItems: 'center'}}>
                    <div className='button-rules button-header-background-green button-header-radius' style={{height: '25px', width: '140px', fontSize: '15px', marginRight: '1rem'}}
                      onClick={() => {
                        if (dispatch) {
                          handleClickFirstRegister(dispatch);
                        } else {
                          handleClickFirstRegister();
                        }
                      }}
                    >
                      <span>Regístrate</span>
                      <img src={registrate} style={{width: '8px', height: 'auto'}}/>
                    </div>

                    <div className='col'>
                      <Reglas gameInfo={gameInfo} gameType={gameType} isReferral={isReferral} />
                    </div>
                  </div>
                </div>
              </div>
            );
            
            // No mostrar boton de registro para usuarios sin tickets
            if (ticketsAvailable?.length <= 0 && gameInfo.entry_currency === 'ticket') {
              conditionalComponent =  null
            }
          } else {
            conditionalComponent = (
              <div className="lower-row row logged">
                <div className="flex-col">
                  <Reglas gameType={gameType} gameInfo={gameInfo} isReferral={isReferral} />
                </div>
              </div>
            );
          }
        } else if (withRegister) {
          if (gameInfo.status === 'upcoming') {
            conditionalComponent = (
              <div className="lower-row row logged">
                <div className='lower-buttons-container'>
                  <div className='misRegistros'>
                    <DropdownButton
                      className="btn-registros-multiples"
                      rootCloseEvent={'click'}
                      title={
                        <span className="button-rules button-header-background1 button-header-radius">
                          <span>Mis Registros</span>
                          <i className='green icon ion-arrow-down-b' style={{fontSize: '16px'}}></i>
                        </span>
                      }
                      id="dropdown-registros-multiples"
                      open={openDrop}
                      onToggle={handleClickDropdown}
                    >
                      <ToggleButtonGroup name="estado" type="radio" defaultValue={selectedEntry}>
                        {listEntries.length === 0 ? 'Solo tienes un registro' : listEntries}
                      </ToggleButtonGroup>
                    </DropdownButton>
                  </div>

                  <Reglas gameInfo={gameInfo} gameType={gameType} isReferral={isReferral} />

                  <CancelEntryButton dispatch={dispatch} cancel={updateStateFuncCancel} />

                  {gameInfo.is_full ? (
                    <div>
                      <div className="volverRegistrar flex-col heafer-text" style={{textTransform: 'none'}}>
                        Lo sentimos pero la quiniela ya está llena
                      </div>
                    </div>
                  ) : gameInfo.max_multientries > 0 &&
                    entries &&
                    entries.length >= gameInfo.max_multientries ? null : (
                      <div>
                        {isTicketAndWithoutTickets && (
                          <div className='button-rules button-header-background1 button-header-radius' style={{margin: '0 auto'}}
                            onClick={() => {
                              if (dispatch) {
                                handleClickReRegister(dispatch);
                              } else {
                                handleClickReRegister();
                              }
                            }}
                            >
                              <span>Volver a registrarme</span>
                              <img src={iconRegister} style={{width: '12px', height: 'auto'}} />
                              {/* <i className="icon ion-plus-circled" style={{fontSize: '12px', color: '#78b529'}}/> */}
                            </div>
                        )}
                      </div>
                  )}
                  <div>
                    <InviteButtonScore
                      dispatch={dispatch}
                      getBranchLink={getBranchLink}
                      showInvite={showInvite}
                      updateBranchLink={updateBranchLink}
                    />
                  </div>
                </div>
              </div>
            );
          } else {
            conditionalComponent = (
              <div className="lower-row row logged">
                <div className={`misRegistros lower-button-container flex-col justify `}>
                  <DropdownButton
                    className="btn-registros-multiples"
                    rootCloseEvent={'click'}
                    title={
                      <span className="button-rules button-header-background1 button-header-radius">
                        <span>Mis Registros</span>
                        <i className='green icon ion-arrow-down-b' style={{fontSize: '14px'}}></i>
                      </span>
                    }
                    id="dropdown-registros-multiples"
                    open={openDrop}
                    onToggle={handleClickDropdown}
                  >
                    <ToggleButtonGroup name="estado" type="radio" value={selectedEntry} onChange={() => {}}>
                      {listEntries.length === 0 ? 'Solo tienes un registro' : listEntries}
                    </ToggleButtonGroup>
                  </DropdownButton>
                </div>
                <div className={`flex-col `}>
                  <Reglas gameInfo={gameInfo} gameType={gameType} isReferral={isReferral} />
                </div>
              </div>
            );
          }
        }
        break;
    }
  }
  return conditionalComponent;
};

/**
 *This function render the popUp of reglas that is attached into the
 *Scoreboard
 */
export const PopoverReglas = ({ isAutoPick, isReferral, gameInfo }) => {
  let sportPick = null;
  switch (gameInfo.sport_id) {
    case 1:
      sportPick =
        'Para definir el resultado de un pick, solamente se tomará en cuenta el tiempo regular, no se tomará en cuenta tiempos extras o tiros penales.';
      break;
    case 2:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta tiempo regular y tiempo extra en caso de que haya.';
      break;
    case 3:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta tiempo regular y tiempo extra en caso de que haya.';
      break;
    case 4:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta el total de las entradas disputadas (9 o más).';
      break;
    default:
      sportPick = null;
      break;
  }

  return (
    <div id="reglas-popover" title="Reglas de Quinielas">
      {isReferral ? (
        <React.Fragment>
          <h3 className="popover-title">Reglas de Quiniela de Referidos</h3>
          <ol>
            <li>La quiniela de referidos es una quiniela mensual.</li>
            <li>La bolsa garantizada podrá cambiar a criterio de Pickwin.</li>
            <li>
              Los criterios para ganar una entrada son: que un usuario se registre con tu nombre de
              usuario, link de invitación o código amigo, que deposite y juegue una quiniela pública
              antes de que comience la próxima Quiniela de Referidos.
            </li>
            <li>
              En caso de que el invitado no use tu nombre de usuario, link de invitación o código
              amigo no recibirás entradas para la quiniela
            </li>
            <li>
              La única forma de participar es ganando entradas por haber invitado a amigos que
              cumplan con los criterios antes mencionados.
            </li>
            <li>
              Para quinielas de referidos se abonarán entradas contabilizando a los usuarios que se
              registraron, depositaron y jugaron por primera vez en el mes en curso. Ejemplo: para
              la quiniela de referidos de julio se tomarán en cuenta los que por primera vez se
              registraron, depositaron y jugaron una quiniela en el mes de julio.
            </li>
            <li>
              Una vez jugada esta quiniela puedes ganar otra entrada a la quiniela mensual si
              invitas a un amigo nuevo y este cumple con los criterios antes mencionados. No hay
              límite de registros que puedes obtener.
            </li>
            <li>
              Un invitado solo te podrá hacer ganar una entrada a la quiniela de referidos, esto el
              primer mes cuando cumplan los criterios antes mencionados.
            </li>
          </ol>
        </React.Fragment>
      ) : null}
      {gameInfo.parlay_flag === true && (
        <>
          <h3 className="popover-title">Reglas de Quiniela Parlay</h3>
          <ol>
            <li>
              Selecciona y guarda tus picks.
            </li>
            <li>
              Los picks se cierran 5 minutos antes de iniciado EL PRIMER PARTIDO DE LA
              QUINIELA PARLAY y no podrán ser editados una vez que se cumpla la fecha
              límite, antes de eso se podrán cambiar los picks las veces que quieras.
            </li>
            <li>
              Tendrás que seleccionar 5 picks en total. En caso de seleccionar menos
              picks, estos se tomarán como incorrectos y no podrás participar por el
              premio.
            </li>
            <li>
              Tendrás que seleccionar por lo menos un pick de cada uno de los bloques de
              horarios que se muestra en la planilla de picks.
            </li>
            <li>
              El objetivo de la QUINIELA PARLAY es tener todos tus picks correctos, en
              caso de tener 5 picks correctos ganarás la parte correspondiente de la bolsa.
            </li>
            <li>
              Los premios se entregarán a los participantes que tengan 5 picks correctos,
              dividiendo la bolsa en partes iguales para todos los participantes con 5
              picks correctos.
            </li>
            <li>
              En caso de que ningún participante tenga 5 picks correctos, nadie ganará la
              bolsa y esta se acumulará. En caso de que se acumule, los participantes
              deberán volver a registrarse a la nueva QUINIELA PARLAY con la bolsa
              acumulada.
            </li>
            <li>
              En quinielas con línea, la línea no se modificará una vez publicada, esta
              podrá ser publicada hasta 5 minutos antes de la fecha límite de inicio.
            </li>
            <li>
              Si un partido es pospuesto, cancelado o anulado, será a discreción de
              Pickwin si se toma en cuenta el partido o si el pick es incorrecto o anulado.
            </li>
            <li>
              Podrás ver los picks de todos los participantes una vez que comience el
              primer partido.
            </li>
            <li>
              Todas las quinielas en las que únicamente haya 1 participante al comenzar
              la misma, serán canceladas.
            </li>
            <li>
              Para definir el resultado de un pick, en fútbol americano, básquetbol y
              béisbol, se toma en cuenta el tiempo extra o extra innings en caso de que
              haya, en quinielas de fútbol, no se tomará en cuenta tiempos extras o tiros
              penales.
            </li>
          </ol>
        </>
      ) || (
        <>
          <h3 className="popover-title">Reglas de Quinielas</h3>
          <ol>
            <li>Selecciona y guarda tus picks.</li>
            <li>
              Los picks se cierran 5 minutos antes de iniciado cada partido y no podrán ser editados,
              antes de eso se podrán cambiar los picks las veces que quieras.
            </li>
            {isAutoPick ? (
              <li>
                En caso de no seleccionar ningún pick, se asignará automáticamente el local. Para los
                desempates, no se asignara ningún valor si no se selecciona antes de iniciar la
                quiniela.
              </li>
            ) : null}
            <li>Cada pick acertado le dará a los participantes 1 punto.</li>
            <li>
              La posición en la quiniela se determina por la suma de los puntos obtenidos. Siendo el
              ganador de la quiniela quien mas puntos acumule al finalizar la quiniela.
            </li>
            <li>
              Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en
              la quiniela.
            </li>
            <li>
              Los desempates servirán para determinar el orden final de los participantes que tengan el
              mismo número de puntos.
              <ol>
                <li>
                  En caso de empate en posiciones premiadas, entrará en juego el sistema de desempates
                  empezando por el criterio #1, en caso de continuar el empate se procederá al criterio
                  #2 y así sucesivamente. En caso de continuar el empate una vez agotados los criterios
                  de desempate, se sumarán las bolsas de las posiciones empatadas y se repartirán entre
                  el número de participantes empatados.
                </li>
                <li>
                  En caso de empate en quinielas de toda la temporada, no se aplicarán criterios de
                  desempate semanales y se sumarán las bolsas de las posiciones empatadas y se repartirá
                  entre los participantes empatados.
                </li>
                <li>
                  Para definir el resultado de un desempate, se toma en cuenta el número o equipo más cercano al resultado real, no tiene que ser exacto.<br/>
                  Para los criterios de desempate en los que se tenga que escoger el número de goles o puntos totales, resultará ganador el jugador que haya escogido el total de goles o puntos totales que más se acerque al resultado real.<br/>
                  Para los criterios de desempate en los que se tenga que escoger al equipo que más puntos o menos puntos anote en una semana (o jornada), resultará ganador el jugador que haya escogido el equipo que más puntos (o menos) haya anotado en la semana contra los equipos que los otros jugadores empatados escogieron.
                </li>
              </ol>
            </li>
            <li>
              En quinielas con línea, la línea no se modificará una vez publicada, esta podrá ser
              publicada hasta 5 minutos antes de que inicie un partido.
            </li>
            <li>
              Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin si se toma
              en cuenta el partido o si se cancela ese pick.
            </li>
            <li>
              Cualquier anomalía en la actividad de un participante, podrá resultar en su
              descalificación a consideración de Pickwin.
            </li>
            <li>Podrás ver los picks de todos los participantes una vez que comience cada partido.</li>
            <li>
              Todas las quinielas en las que únicamente haya 1 participante al comenzar la misma, serán
              canceladas.
            </li>

            {sportPick !== null ? <li> {sportPick} </li> : null}

            {gameInfo.sport_id === 1 ? (
              <li>
                Las tarjetas amarillas y rojas a miembros de la banca (entrenadores, jugadores o cuerpo
                técnico) no serán tomadas en cuenta para la quiniela.
              </li>
            ) : null}

            {gameInfo.point_format === 'line' ? (
              <li>
                Esta quiniela se juega CON LINEA, al resultado final del partido se le suman los puntos
                de la línea.
              </li>
            ) : null}
            {gameInfo.perfect_prize !== null && gameInfo.sport_id === 1 && (
              <React.Fragment>
                <li>
                  Esta quiniela entregará un premio adicional ($1,000,000.00) al usuario o usuarios que
                  tengan {gameInfo.perfect_score} aciertos en la quiniela (sin contar el desempate).
                </li>
                <li>
                  En caso de que un partido o partidos no se lleven acabo o un pick de línea de goles se
                  empate y el participante tenga el resto los aciertos de los partidos celebrados, NO
                  participará por el premio adicional ya que no se cumpliría el requisito de obtener {gameInfo.perfect_score}
                  aciertos (9 por partidos y 9 por línea de goles).
                </li>
                <li>
                  En caso de que 2 o mas usuarios tengan {gameInfo.perfect_score} aciertos, la bolsa adicional se dividirá
                  entre los ganadores en partes iguales.
                </li>
              </React.Fragment>
            )}

            {gameInfo.perfect_prize !== null && gameInfo.sport_id === 2 && (
              <React.Fragment>
                <li>Esta quiniela entregará un premio adicional ($1,000,000.00) al usuario o usuarios que tengan {gameInfo.perfect_score} aciertos en la quiniela (sin contar el desempate).</li>
                <li>En caso de que un partido o partidos no se lleven acabo o un pick se empate por la línea de puntos y el participante tenga el resto de los aciertos de los partidos celebrados, NO participará por el premio adicional ya que no se cumpliría el requisito de obtener {gameInfo.perfect_score} aciertos.</li>
                <li>En caso de que 2 o mas usuarios tengan {gameInfo.perfect_score} aciertos, la bolsa adicional se dividirá entre los ganadores en partes iguales.</li>
              </React.Fragment>
            )}
          </ol>
        </>
      )}

    </div>
  );
};

export const popoverReglasMundial = (
  <div id="reglas-popover" title="Reglas de Quinielas">
    <h3 className="popover-title">Reglas de Quiniela </h3>
    <ol>
      <li>Selecciona y guarda tus picks.</li>
      <li>
        Los picks se cierran 5 minutos antes de iniciado cada partido y no podrán ser editados,
        antes de eso se podrán cambiar los picks las veces que quieras.
      </li>
      <li>
        En caso de no seleccionar ningún pick, se asignará automáticamente el local. Para los
        desempates, no se asignara ningún valor si no se selecciona antes de iniciar la quiniela.
      </li>
      <li>Cada pick acertado le dará a los participantes 1 punto.</li>
      <li>
        La posición en la quiniela se determina por la suma de los puntos obtenidos. Siendo el
        ganador de la quiniela quien mas puntos acumule al finalizar la quiniela.
      </li>
      <li>
        Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en la
        quiniela.
      </li>
      <li>
        Los desempates servirán para determinar el orden final de los participantes que tengan el
        mismo número de puntos al finalizar el último partido. En caso de empate en posiciones
        premiadas, entrará en juego el sistema de desempates empezando por el criterio #1, en caso
        de continuar el empate se procederá al criterio #2 y en caso de seguir al criterio #3. Si
        después de los 3 criterios de desempate, dos o mas usuarios siguen empatados, se sumarán las
        bolsas de los lugares en disputa y se dividirá el premio entre los usuarios empatados. No se
        tendrá que acertar exacto a cada criterio, en caso de empate el usuario mas cercano al
        resultado correcto será quien quede en mejor posición.
        <br />
        Los criterios de desempate son:
        <ol>
          <li>
            GOLES TOTALES EN EL MUNDIAL. Este criterio corresponde a todos los goles anotados en
            todos los partidos del mundial en TIEMPO REGLAMENTARIO (90 minutos). Los goles anotados
            en tiempos extras o tiros penales no contarán para el desempate.
          </li>
          <li>
            GOLES TOTALES HECHOS POR EL CAMPEON EN EL MUNDIAL. Este criterio corresponde a todos los
            goles anotados por el equipo campeón en sus 7 partidos jugados en el mundial en TIEMPO
            REGLAMENTARIO (90 minutos). Los goles anotados en tiempos extras o tiros penales no
            contarán para el desempate.
          </li>
          <li>
            GOLES TOTALES HECHOS POR EL SUB-CAMPEON EN EL MUNDIAL. Este criterio corresponde a todos
            los goles anotados por el equipo subcampeón en sus 7 partidos jugados en el mundial en
            TIEMPO REGLAMENTARIO (90 minutos). Los goles anotados en tiempos extras o tiros penales
            no contarán para el desempate.
          </li>
        </ol>
      </li>

      <li>
        Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin si se toma en
        cuenta el partido o si se cancela ese pick.
      </li>
      <li>
        Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación
        a consideración de Pickwin.
      </li>
      <li>Podrás ver los picks de todos los participantes una vez que comience cada partido.</li>
      <li>
        Todas las quinielas en las que únicamente haya 1 participante al comenzar la misma, serán
        canceladas.
      </li>
    </ol>
  </div>
);

export const popoverQueEsStrike = (
  <Popover id="reglas-popover">
    <div className="flex-col">
      <strong className="flex-row row-center popover-title text-center">¿Strikes?</strong>
      <ul className="flex-container column ">
        <li>
          Si el equipo que seleccionaste <strong>empata</strong>, obtendrás un strike.
        </li>
        <li>
          Los participantes únicamente contrarán con estos strikes antes de ser{' '}
          <strong>eliminados</strong>.
        </li>
        <li>
          Al acumular este número de strikes, serán <strong>eliminados</strong>.
        </li>
      </ul>
    </div>
  </Popover>
);

export const popoverQueEsStrikeLinea = (
  <Popover id="reglas-popover">
    <div className="flex-col">
      <strong className="flex-row row-center popover-title text-center">¿Strikes?</strong>
      <ul className="flex-container column ">
        <li>
          Si el equipo que seleccionaste <strong>empata o pierde CON LINEA</strong>, obtendrás un
          strike.
        </li>
        <li>
          Los participantes únicamente contrarán con estos strikes antes de ser{' '}
          <strong>eliminados</strong>.
        </li>
        <li>
          Al acumular este número de strikes, serán <strong>eliminados</strong>.
        </li>
      </ul>
    </div>
  </Popover>
);

export const popoverGroupPoolGolf = (
  <div id="reglas-popover" title="Reglas de Quiniela">
    <h3 className="popover-title">Reglas de Quiniela</h3>
    <ol className="ordered-list">
      <li> Selecciona y guarda tus picks.</li>
      <li> Los picks se cierran 5 minutos antes de iniciado el torneo y no podrán ser editados, antes de eso se podrán cambiar los picks las veces que quieras.</li>
      <li> Selecciona un golfista de cada grupo.</li>
      <li> De los 6 jugadores seleccionados por cada participante, se tomarán en cuenta los 4 mejores scores finales, los dos jugadores de cada participante con el peor score en el torneo no contarán para la quiniela.</li>
      <li> En caso de que un jugador seleccionado no pase el corte o se retire, se le asignará 10 golpes sobre el par del campo en cada ronda. Ejemplo: un jugador no pase el corte y el par de campo es 72, para las rondas 3 y 4 al jugador se le asignará el score “82”.</li>
      <li> En caso de no seleccionar ningún pick, se le asignará 10 golpes sobre el par del campo en cada ronda al jugador de ese grupo.</li>
      <li> La posición final en la quiniela se determina por la suma de los scores de los 4 mejores jugadores de cada participante.</li>
      <li> Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en la quiniela.</li>
      <li> <span>Los desempates servirán para determinar el orden final de los participantes que tengan el mismo número de puntos.</span>
        <ul>
          <li>En caso de empate en posiciones premiadas, entrará en juego el sistema de desempates empezando por el criterio #1, en caso de continuar el empate se procederá al criterio #2 y así sucesivamente. En caso de continuar el empate una vez agotados los criterios de desempate, se sumarán las bolsas de las posiciones empatadas y se repartirán entre el número de participantes empatados.</li>
        </ul>
      </li>
      <li> Si un torneo es pospuesto, cancelado o anulado, será a discreción de Pickwin si se toma en cuenta la quiniela.</li>
      <li> Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.</li>
      <li> Podrás ver los picks de todos los participantes una vez que comience el torneo.</li>
      <li> Todas las quinielas en las que únicamente haya 1 participante al comenzar la misma, serán canceladas.</li>
    </ol>
  </div>
);

export const popoverGroupPoolF1 = (
  <div id="reglas-popover" title="Reglas de Quiniela">
    <h3 className="popover-title">Reglas de Quiniela</h3>
    <ol className="ordered-list">
    <li>Selecciona y guarda tus picks.</li>
      <li>Los picks se cierran 5 minutos antes de la calificación y no podrán ser editados, antes de eso se podrán cambiar los picks las veces que quieras.</li>
      <li>Selecciona el piloto que quedará en cada una de las 10 primeras posiciones al finalizar la carrera.</li>
      <li>En caso de no seleccionar ningún pick, no se asignará ningún piloto para esa posición por lo que el participante perderá la posibilidad de sumar puntos para esa posición. </li>
      <li>Se darán puntos según la posición final y el pronostico de cada participante otorgando mas puntos entre mas exacto sea el pronostico de acuerdo a la tabla de puntuación. Solo se darán puntos si el piloto finaliza en las primeras 10 posiciones. Ej. Si el piloto acaba en la posición 11 y el pick era que acaba en la posición 10, no se dan puntos. Se considera el resultado final oficial, en caso de penalizaciones, estas se toman en cuenta para determinar el orden final.</li>
      <li>BONUS POINTS: Se darán puntos adicionales en caso de pronosticar correctamente cualquiera de las 3 primeras posiciones de la carrera (10 puntos al 1er lugar, 5 puntos al 2do lugar y 2 puntos al 3er lugar).</li>
      <li>La posición final en la quiniela se determina por la suma de los puntos obtenidos de todas las selecciones de cada participante. Siendo el ganador de la quiniela quien mas puntos acumule al finalizar esta.</li>
      <li>Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en la quiniela</li>
      <li>Los desempates servirán para determinar el orden final de los participantes que tengan el mismo número de puntos.
        <ul>
          <li>En caso de empate en posiciones premiadas, entrará en juego el sistema de desempates empezando por el criterio #1, en caso de continuar el empate se procederá al criterio #2 y así sucesivamente. En caso de continuar el empate una vez agotados los criterios de desempate, se sumarán las bolsas de las posiciones empatadas y se repartirán entre el número de participantes empatados.</li>
        </ul>
      </li>
      <li>Si una carrera es pospuesta, cancelada o anulada, será a discreción de Pickwin si se toma en cuenta la quiniela.</li>
      <li>Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.</li>
      <li>Podrás ver los picks de todos los participantes una vez que comience la calificación.</li>
      <li>Todas las quinielas en las que únicamente haya 1 participante al comenzar la misma, serán canceladas.</li>
    </ol>
  </div>
);

export const PopoverReglasSurvivor = (gameInfo) => {
  console.log('Popover PointFormat', gameInfo);
  let sportPick = null;
  switch (gameInfo.gameInfo.sport_id) {
    case 1:
      sportPick =
        'Para definir el resultado de un pick, solamente se tomará en cuenta el tiempo regular, no se tomará en cuenta tiempos extras o tiros penales.';
      break;
    case 2:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta tiempo regular y tiempo extra en caso de que haya.';
      break;
    case 3:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta tiempo regular y tiempo extra en caso de que haya.';
      break;
    case 4:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta el total de las entradas disputadas (9 o más).';
      break;
    default:
      sportPick = null;
      break;
  }

  return (
    <div id="reglas-popover" title="Reglas del Survivor">
      <h3 className="popover-title">Reglas del Survivor</h3>
      <ol>
        <li>
          El objetivo de este concurso, es escoger a un equipo distinto todas las semanas que gane
          su partido. El participante que `SOBREVIVA¨ mas semanas será el ganador.{' '}
        </li>
        <li>
          Los participantes tendrán que seleccionar su pick, cada semana estos deberán elegir
          únicamente un equipo el cual no podrán seleccionar para futuras semanas.
        </li>
        <li>
          Todos los picks, excepto los de partidos del jueves o sábado, cerrarán 5 minutos antes del
          inicio del primer partido del domingo y no podrá ser editado, antes de eso se podrán
          cambiar los picks las veces que quieras.
        </li>
        <li>
          En caso de no seleccionar ningún pick, el participante quedara eliminado del concurso.
        </li>
        {gameInfo.gameInfo.point_format === 'natural' ? (
          <li>
            En caso de que el equipo que selecciono el participante, gane o EMPATE su partido,
            "sobrevivirá" tendrá que seleccionar un equipo distinto para la próxima semana.
          </li>
        ) : (
          <li>
            En caso de que el equipo que selecciono el participante, gane (con línea) o EMPATE (con
            línea) su partido, "sobrevivirá" tendrá que seleccionar un equipo distinto para la
            próxima semana.
          </li>
        )}
        <li>
          La posición del survivor se determina por la semana en la que el participante quedo
          eliminado. Siendo el ganador del survivor quien sea el ultimo eliminado del concurso.
        </li>
        <li>
          Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en
          el survivor.
        </li>
        <li>
          En caso de empate se sumarán las bolsas de las posiciones empatadas y se repartirán entre
          el número de participantes empatados.
        </li>
        {gameInfo.gameInfo.point_format === 'natural' ? (
          <li>
            El survivor se juega SIN línea es decir, los participantes, tendrán que elegir quien
            gane el partido, no quien cubra la línea.
          </li>
        ) : (
          <li>
            Este Survivor es con línea, la línea es el número de puntos que se le restan a o suman a
            un equipo según sea el caso. Para que una selección sea correcta, el equipo deberá tener
            mas puntos que su rival una vez sumados o restados los puntos de la línea.
          </li>
        )}
        <li>
          Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin determinar
          que se hace con ese pick.
        </li>
        <li>
          Cualquier anomalía en la actividad de un participante, podrá resultar en su
          descalificación a consideración de Pickwin.
        </li>
        <li>
          Podrás ver los picks de todos los participantes una vez que cierren los picks o en caso
          que terminen los partidos del jueves.
        </li>
        <li>
          Todos los survivors en los que únicamente haya 1 participante al comenzar el mismo, serán
          cancelados.
        </li>
        <li>
          En caso de que un partido se posponga, el pick se mantendrá y cuando se juegue el partido
          se calificara de acuerdo a la semana que correspondía originalmente. Ej. Se suspende un
          partido en la semana 2, y el partido se juega en la semana 8, el usuario seguirá metiendo
          picks semana 3, 5, 6, … pero si pierde el partido cuando se juegue en la semana 8, el
          participante quedara eliminado en la jornada original (2).
        </li>
        {sportPick !== null ? <li> {sportPick} </li> : null}
        {gameInfo.gameInfo.point_format === 'line' ? (
          <li>
            Esta quiniela se juega CON LINEA, al resultado final del partido se le suman los puntos
            de la línea.
          </li>
        ) : null}
      </ol>
    </div>
  );
};

export const PopoverReglasSurvivorStrikes = (gameInfo) => {
  console.log('Popover PointFormat', gameInfo);
  let sportPick = null;
  switch (gameInfo.gameInfo.sport_id) {
    case 1:
      sportPick =
        'Para definir el resultado de un pick, solamente se tomará en cuenta el tiempo regular, no se tomará en cuenta tiempos extras o tiros penales.';
      break;
    case 2:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta tiempo regular y tiempo extra en caso de que haya.';
      break;
    case 3:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta tiempo regular y tiempo extra en caso de que haya.';
      break;
    case 4:
      sportPick =
        'Para definir el resultado de un pick, se toma en cuenta el total de las entradas disputadas (9 o más).';
      break;
    default:
      sportPick = null;
      break;
  }

  return (
    <div id="reglas-popover" title="Reglas del Survivor">
      <h3 className="popover-title">Reglas del Survivor</h3>
      <ol>
        <li>
          El objetivo de este concurso, es escoger a un equipo distinto todas las semanas que gane
          su partido. El participante que `SOBREVIVA¨ mas semanas será el ganador.{' '}
        </li>
        <li>
          Los participantes tendrán que seleccionar su pick, cada semana estos deberán elegir
          únicamente un equipo el cual no podrán seleccionar para futuras semanas.
        </li>
        <li>
          El pick se cierra 5 minutos antes del inicio del primer partido de la jornada y no podrá
          ser editado, antes de eso se podrán cambiar los picks las veces que quieras.
        </li>
        <li>
          En caso de no seleccionar ningún pick, el participante quedara eliminado del concurso.
        </li>
        {gameInfo.gameInfo.point_format === 'natural' ? (
          <ul className="list">
            <li>
              <strong>GANE</strong> el participante seguirá vivo.
            </li>
            <li>
              <strong>EMPATE</strong> dependerá del tipo de survivor,
            </li>
            <li>
              <strong>EMPATE STRIKES</strong>, el participante podrá empatar el numero de strikes
              que tenga asignado el concurso, quedando eliminado en el ultimo de sus strikes.
              Ejemplo si el survivor tiene 3 strikes, en el tercer empate el participante quedara
              eliminado.
            </li>
            <li>
              <strong>EMPATE VIVO</strong>, el participante <strong>NO</strong> quedara eliminado si
              su equipo empata en ningún caso.
            </li>
            <li>
              <strong>EMPATE ELIMINADO</strong>, el participante <strong>SI</strong> quedara
              eliminado si su euqipo empata.
            </li>
          </ul>
        ) : (
          <ul className="list">
            <li>
              <strong>GANE (CON LÍNEA)</strong> el participante seguirá vivo.
            </li>
            <li>
              <strong>EMPATE (CON LÍNEA)</strong>, el participante podrá empatar el numero de
              strikes que tenga asignado el concurso, quedando eliminado en el ultimo de sus
              strikes. Ejemplo si el survivor tiene 3 strikes, en el tercer empate el participante
              quedara eliminado.
            </li>
            <li>
              <strong>PIERDE (CON LÍNEA)</strong>, el participante podrá perder el numero de strikes
              que tenga asignado el concurso, quedando eliminado en el ultimo de sus strikes.
              Ejemplo si el survivor tiene 3 strikes, en el tercer empate el participante quedara
              eliminado.
            </li>
          </ul>
        )}
        <li>
          La posición del survivor se determina por la semana en la que el participante quedo
          eliminado. Siendo el ganador del survivor quien sea el ultimo eliminado del concurso.
        </li>
        <li>
          Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en
          el survivor.
        </li>
        <li>
          En caso de empate se sumarán las bolsas de las posiciones empatadas y se repartirán entre
          el número de participantes empatados.
        </li>
        {gameInfo.gameInfo.point_format === 'natural' ? (
          <li>
            El survivor se juega SIN línea es decir, los participantes, tendrán que elegir quien
            gane el partido, no quien cubra la línea.
          </li>
        ) : (
          <li>
            Este Survivor es con línea, la línea es el número de puntos que se le restan a o suman a
            un equipo según sea el caso. Para que una selección sea correcta, el equipo deberá tener
            mas puntos que su rival una vez sumados o restados los puntos de la línea.
          </li>
        )}
        <li>
          Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin determinar
          que se hace con ese pick.
        </li>
        <li>
          Cualquier anomalía en la actividad de un participante, podrá resultar en su
          descalificación a consideración de Pickwin.
        </li>
        <li>
          Podrás ver los picks de todos los participantes una vez que cierren los picks o en caso
          que terminen los partidos del jueves.
        </li>
        <li>
          Todos los survivors en los que únicamente haya 1 participante al comenzar el mismo, serán
          cancelados.
        </li>
        <li>
          En caso de que un partido se posponga, el pick se mantendrá y cuando se juegue el partido
          se calificara de acuerdo a la semana que correspondía originalmente. Ej. Se suspende un
          partido en la semana 2, y el partido se juega en la semana 8, el usuario seguirá metiendo
          picks semana 3, 5, 6, … pero si pierde el partido cuando se juegue en la semana 8, el
          participante quedara eliminado en la jornada original (2).
        </li>
        {sportPick !== null ? <li> {sportPick} </li> : null}
        {gameInfo.gameInfo.point_format === 'line' ? (
          <li>
            Esta quiniela se juega CON LINEA, al resultado final del partido se le suman los puntos
            de la línea.
          </li>
        ) : null}
      </ol>
    </div>
  );
};

export const PopoverReglasSurvivorContest = (gameInfo) => {
  console.log("GAMEINFO:", gameInfo);
  const contests = gameInfo.gameInfo.contests;
  return (
    <div id="reglas-popover" title="Reglas del Survivor">
      <h3 className="popover-title">Reglas del Survivor</h3>
      <ol>
        <li>El objetivo de este concurso, es escoger a un jugador distinto por torneo que quede en la posición 40 o mejor. El participante que "SOBREVIVA" mas torneos será el ganador.</li>
        <li>Para los siguientes torneos cambiara el criterio para sobrevivir.</li>
        <div className='popover-table'>
          <table className="survivor-rules-table">
            <tbody>
              <tr>
                <th>TORNEO</th>
                <th>CRITERIO</th>
              </tr>
              {contests && contests.length > 0 && contests.map((contest) => {
                return (
                  <tr>
                    <td>{contest.name}</td>
                    <td>Top {contest.elimination_threshold}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <li>
          Los participantes tendrán que seleccionar su pick, cada semana estos deberán elegir
          únicamente un jugador el cual no podrán seleccionar para futuros torneos.
        </li>
        <li>
          Cada participante tendrá {gameInfo.gameInfo.max_strikes ?? gameInfo.gameInfo.initial_strikes} strikes o vidas, en caso de que el jugador no finalice en la posición indicada por torneo, perderás una vida, al perder tu 3era vida quedarás eliminado del survivor. En caso de que el jugador que seleccionaste gane el torneo, obtendrás una vida extra (strike).
        </li>
        <li>
          Todos los picks, cerrarán 5 minutos antes del inicio del primer tee time del torneo y no
          podrá ser editado, antes de eso se podrán cambiar los picks las veces que quieras.
        </li>
        <li>
          En caso de no seleccionar ningún pick, el participante quedara eliminado del concurso.
        </li>
        <li>
          En caso de que el jugador que selecciono el participante, se retire del torneo, obtendrás un strike.
        </li>
        <li>
          La posición del survivor se determina por la semana en la que el participante quedo
          eliminado. Siendo el ganador del survivor quien sea el ultimo eliminado del concurso.
        </li>
        <li>
          Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en
          el survivor.
        </li>
        <li>
          En caso de empate se sumarán las bolsas de las posiciones empatadas y se repartirán entre
          el número de participantes empatados.
        </li>
        <li>
          Si un torneo es pospuesto, cancelado o anulado, será a discreción de Pickwin determinar
          que se hace con ese pick.
        </li>
        <li>
          Cualquier anomalía en la actividad de un participante, podrá resultar en su
          descalificación a consideración de Pickwin.
        </li>
        <li>Podrás ver los picks de todos los participantes una vez que cierren los picks.</li>
        <li>
          Todos los survivors en los que únicamente haya 1 participante al comenzar el mismo, serán
          cancelados.
        </li>
        <li>
          Para definir el resultado de un pick, se toma en cuenta la tabla oficial de posiciones del
          torneo.
        </li>
      </ol>
    </div>
  );
};

export const PopoverReglasSurvivorContestF1 = (gameInfo) => {
  return (
    <div id="reglas-popover" title="Reglas del Survivor">
      <h3 className="popover-title">Reglas del Survivor Formula 1</h3>
      <ol>
        <li>El objetivo de este concurso, es escoger un piloto distinto que finalice en el top <strong>{gameInfo.gameInfo.elimination_threshold}</strong> en cada carrera de la temporada. El participante que “SOBREVIVA” mas carreras será el ganador.</li>
        <li>Los participantes deberán seleccionar su pick para cada carrera, no podrán seleccionar para futuras carreras.</li>
        <li>
          Todos los picks cerrarán 5 minutos antes de que inicie la calificación y no podrá ser editado, antes de eso se podrán cambiar los picks las veces que quieras.
        </li>
        <li>
          En caso de no seleccionar ningún piloto, el participante quedará eliminado del concurso.
        </li>
        <li>
          La posición del Survivor se determina por la carrera en la que el participante quedó eliminado. Siendo el ganador del Survivor quien sea el último eliminado del concurso.
        </li>
        <li>
          Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en el Survivor.
        </li>
        <li>
          En caso de empates, se sumarán las bolsas de las posiciones empatadas y se repartirán entre el número de participantes empatados.
        </li>
        <li>
          Si una carrera es pospuesta, cancelada o anulada, será a discreción de Pickwin determinar que se hace con ese pick.
        </li>
        <li>
          Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.
        </li>
        <li>
          Podrás ver los picks de todos los participantes una vez que cierren los picks.
        </li>
        <li>
          Todos los survivors en los que únicamente haya 1 participante al comenzar el mismo, serán cancelados.
        </li>
        <li>
          Para definir el resultado de un pick, se toma en cuenta el resultado final oficial de posiciones de la carrera.
        </li>
      </ol>
    </div>
  );
};

export const PopoverReglasSurvivorContestStrikesF1 = (gameInfo) => {
  return (
    <div id="reglas-popover" title="Reglas del Survivor">
      <h3 className="popover-title">Reglas del Survivor Formula 1 Strikes</h3>
      <ol>
        <li>El objetivo de este concurso, es escoger un piloto distinto que finalice en el top <strong>{gameInfo.gameInfo.elimination_threshold}</strong> en cada carrera de la temporada. El participante que “SOBREVIVA” mas carreras será el ganador.</li>
        <li>Los participantes deberán seleccionar su pick para cada carrera, no podrán seleccionar para futuras carreras.</li>
        <li>
          Cada participante tendrá {gameInfo.gameInfo.max_strikes} strikes o vidas, en caso de que el piloto no finalice en el top <strong>{gameInfo.gameInfo.elimination_threshold}</strong> perderás una vida, después de perder tu <strong>{gameInfo.gameInfo.max_strikes}</strong> vida quedarás eliminado.
        </li>
        <li>
          Todos los picks cerrarán 5 minutos antes de que inicie la calificación y no podrá ser editado, antes de eso se podrán cambiar los picks las veces que quieras.
        </li>
        <li>
          En caso de no seleccionar ningún piloto, el participante quedará eliminado del concurso sin importar cuantos strikes tengas.
        </li>
        <li>
          La posición del Survivor se determina por la carrera en la que el participante quedó eliminado. Siendo el ganador del Survivor quien sea el último eliminado del concurso.
        </li>
        <li>
          Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en el Survivor.
        </li>
        <li>
          En caso de empates, se sumarán las bolsas de las posiciones empatadas y se repartirán entre el número de participantes empatados.
        </li>
        <li>
          Si una carrera es pospuesta, cancelada o anulada, será a discreción de Pickwin determinar que se hace con ese pick.
        </li>
        <li>
          Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.
        </li>
        <li>
          Podrás ver los picks de todos los participantes una vez que cierren los picks.
        </li>
        <li>
          Todos los survivors en los que únicamente haya 1 participante al comenzar el mismo, serán cancelados.
        </li>
        <li>
          Para definir el resultado de un pick, se toma en cuenta el resultado final oficial de posiciones de la carrera.
        </li>
      </ol>
    </div>
  );
};

export const textoReglasQuinielaPick5 = (isFull = false) => {
  return (
    <div id="reglas-popover" title="Reglas de Quiniela Pick5">
      <h3 className="popover-title">Reglas Quiniela Pick5</h3>
      <ol>
        <li>Selecciona y guarda tus picks.</li>
        <li>
          Los picks se cierran 5 minutos antes de iniciado EL PRIMER PARTIDO DE LA QUINIELA y no
          podrán ser editados, antes de eso se podrán cambiar los picks las veces que quieras.
        </li>
        {isFull ? (
          <li>
            Tendrás que seleccionar 5 picks solamente cada jornada o semana. En caso de seleccionar
            menos picks estos contarán como incorrectos.
          </li>
        ) : (
          <li>
            Tendrás que seleccionar el número de picks de acuerdo al nombre de la quiniela. Si la
            quiniela es PICK5, tendrás que seleccionar SOLAMENTE 5 picks. En caso de seleccionar,
            menos picks, estos se tomarán como picks incorrectos.
          </li>
        )}
        {isFull ? (
          <li>
            Cada pick acertado le dará a los participantes 1 punto. Cada pick incorrecto le restara
            a los participantes 1 punto.
          </li>
        ) : (
          <li>Cada pick acertado le dará a los participantes 1 punto.</li>
        )}
        <li>
          La posición en la quiniela se determina por la suma de los puntos obtenidos. Siendo el
          ganador de la quiniela quien mas puntos acumule al finalizar la quiniela.
        </li>
        <li>
          Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en
          la quiniela.
          <ol>
            <li>
              Los desempates servirán para determinar el orden final de los participantes que tengan
              el mismo número de puntos.En caso de empate en posiciones premiadas, entrará en juego
              el sistema de desempates empezando por el criterio #1, en caso de continuar el empate
              se procederá al criterio #2 y así sucesivamente. En caso de continuar el empate una
              vez agotados los criterios de desempate, se sumarán las bolsas de las posiciones
              empatadas y se repartirán entre el número de participantes empatados.
            </li>
            <li>
              En caso de empate en quinielas de toda la temporada, no se aplicarán criterios de
              desempate semanales y se sumarán las bolsas de las posiciones empatadas y se repartirá
              entre los participantes empatados.
            </li>
          </ol>
        </li>
        <li>
          En quinielas con línea, la línea no se modificará una vez publicada, esta podrá ser
          publicada hasta 5 minutos antes de que inicie un partido.
        </li>
        <li>
          Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin si se toma
          en cuenta el partido o si se cancela ese pick.
        </li>
        <li>
          Cualquier anomalía en la actividad de un participante, podrá resultar en su
          descalificación a consideración de Pickwin.
        </li>
        <li>
          Podrás ver los picks de todos los participantes una vez que comience el primer partido.
        </li>
        <li>
          Todas las quinielas en las que únicamente haya 1 participante al comenzar la misma, serán
          canceladas.
        </li>
        <li>
          Para definir el resultado de un pick, solamente se tomará en cuenta el tiempo regular, no
          se tomará en cuenta tiempos extras o tiros penales en quinielas de futbol. En quinielas de
          futbol americano, beisbol y basquetbol, si se tomará en cuenta el tiempo extra o extra
          innings.
        </li>
      </ol>
    </div>
  );
};

/**
 *This function render the popUp of reglas that is attached into the
 *Scoreboard
 */
export const popoverReglasBracket = (
  <div id="reglas-popover" title="Reglas del Bracket">
    <h3 className="popover-title"> Reglas del Bracket</h3>
    <ol>
      <li>Selecciona y guarda tus picks.</li>
      <li>
        Los picks se cierran 5 minutos antes del inicio de cada serie y no podrán ser editados, antes de eso se podrán cambiar los picks las veces que quieras. Cada ronda el participante deberá llenar sus selecciones sin importar que no haya tenido correctas las selecciones de rondas pasadas.
      </li>
      <li>
        Selecciona el equipo que avanzara en cada ronda así como el numero de partido en el cual terminara la serie.
      </li>
      <li>
        En caso de no seleccionar ningún pick, no se asignará ningún equipo para esa serie  por lo que el participante perderá la posibilidad de sumar puntos para esa selección.
      </li>
      <li>
        Se darán puntos de la siguiente manera:
        1 punto por seleccionar correctamente el equipo que gana la serie.
        3 puntos por seleccionar correctamente el equipo que gana la serie y acertar el numero de partidos que durara la serie.
        En caso de seleccionar el numero de partidos correcto pero el equipo incorrecto en ganar, el participante no sumara puntos.
      </li>
      <li>
        Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en el bracket.
      </li>
      <li>
        Los desempates servirán para determinar el orden final de los participantes que tengan el mismo número de puntos.
        <br/>
        En caso de empate en posiciones premiadas, entrará en juego el sistema de desempates empezando por el criterio
        <br/>
        #1 (equipo campeón), en caso de continuar el empate se procederá al criterio
        <br/>
        #2 (equipo subcampeón). En caso de continuar el empate una vez agotados los criterios de desempate, se sumarán las bolsas de las posiciones empatadas y se repartirán entre el número de participantes empatados.
      </li>
      <li>
        Si un partido o serie es pospuesta, cancelada o anulada, será a discreción de Pickwin si se toma en cuenta la quiniela.
      </li>
      <li>
        Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.
      </li>
      <li>
        Podrás ver los picks de todos los participantes una vez que comience cada serie.
      </li>
      <li>
        Todos los brackets en los que únicamente haya 1 participante al comenzar el mismo, serán canceladas.
      </li>
    </ol>
  </div>
);

/**
 *This function render the popUp of reglas that is attached into the
 *Scoreboard
 */
export const popoverReglasBracketTennis = (
  <div id="reglas-popover" title="Bracket Tenis">
    <h3 className="popover-title">Bracket Tenis</h3>
    <ol class="ordered-list">
      <li>Selecciona y guarda tus picks.</li>
      <li>Los picks se cierran 5 minutos antes del inicio del primer partido de cada día y no podrán ser editados, antes de eso se podrán cambiar los picks las veces que quieras. Cada ronda el participante deberá llenar sus selecciones sin importar que no haya tenido correctas las selecciones de rondas pasadas.</li>
      <li>Selecciona el jugador que ganará el partido, así como el numero de sets totales y el número de juegos totales. En caso de que el partido se cancele o un jugador se retire y no se completen el número de sets totales necesarios para ganar el partido, todos los picks referentes a ese partido se anularan.</li>
      <li>En caso de no seleccionar ningún pick, no se asignará ningún jugador, ni número de sets ni juegos por lo que el participante perderá la posibilidad de sumar puntos para esa selección.</li>
      <li>Se darán puntos de la siguiente manera:
        <ul>
          <li>1 punto por seleccionar correctamente el jugador que ganará el partido.</li>
          <li>3 puntos por seleccionar correctamente el jugador que gana el partido y acertar el número de sets. </li>
          <li>5 puntos por seleccionar correctamente el jugador que gana el partido, acertar el número de sets y acertar el número de juegos.</li>
        </ul>
      </li>
      <li>El máximo de puntos posibles a ganar por partido es 5 puntos si tienes los 3 picks por partido correctos.</li>
      <li>En caso de seleccionar el numero de sets o juegos correcto pero el jugador incorrecto a ganar el partido, el participante no sumara puntos.</li>
      <li>Los premios se entregarán de acuerdo a la estructura de pagos y distribución publicada en el bracket.</li>
      <li>Los desempates servirán para determinar el orden final de los participantes que tengan el mismo número de puntos.</li>
      <li>En caso de empate en posiciones premiadas, entrará en juego el sistema de desempates empezando por el criterio
        <ul>
          <li>#1 (jugador campeón), en caso de continuar el empate se procederá al criterio</li>
          <li>#2 (jugador subcampeón). En caso de continuar el empate una vez agotados los criterios de desempate, se sumarán las bolsas de las posiciones empatadas y se repartirán entre el número de participantes empatados.</li>
        </ul>
      </li>
      <li>Si un partido o partidos son pospuestos, cancelados o anulados, será a discreción de Pickwin si se toma en cuenta la quiniela.</li>
      <li>Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.</li>
      <li>Podrás ver los picks de todos los participantes una vez que comience cada partido.</li>
      <li>Todos los brackets en los que únicamente haya 1 participante al comenzar el mismo, serán canceladas.</li>
    </ol>
  </div>
);

export const popoverReglasPerfectBracket = (
  <div id="reglas-popover" title="Reglas del Bracket">
    <h3 className="popover-title">Reglas del Bracket</h3>
    <ol>
      <li>Selecciona y guarda tu bracket.</li>
      <li>
        El bracket cerrará 5 minutos antes de iniciado el primer partido del mundial y no
        podrán ser editados una vez comenzado el mundial. Antes de eso se podrán hacer las
        modificaciones que quieras a tu bracket.
      </li>
      <li>En caso de no seleccionar algún pick, se calificará como perdido ese Bracket</li>
      <li>
        El participante que haga el "mundial perfecto", es decir, le atine de manera correcta
        todo el bracket ganará el premio Garantizado. En caso de que haya mas de un bracket
        perfecto se dividirán la bolsa entre los que lo hayan acertado.
      </li>
      <li>
        Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin si se
        toma en cuenta el partido o si se cancela
      </li>
      <li>
        Cualquier anomalía en la actividad de un participante, podrá resultar en su
        descalificación a consideración de Pickwin.
      </li>
      <li>
        Podrás ver las selecciones de todos los participantes una vez que comiencen los
        partidos.
      </li>
    </ol>
  </div>
);

export const popoverReglasPointsBracket = (
  <div id="reglas-popover" title="Reglas del Bracket">
    <h3 className="popover-title">Reglas del Bracket</h3>
    <ol>
      <li>Selecciona y guarda tu bracket.</li>
      <li>El bracket cerrará 5 minutos antes de iniciado el primer partido de octavos de final del mundial y no podrán ser editados una vez comenzado el primer partido. Antes de eso se podrán hacer las modificaciones que quieras a tu bracket. SE TIENEN QUE LLENAR TODOS LOS PARTIDOS ANTES DE INICIADO EL PRIMER PARTIDO Y LOS CRITERIOS DE DESEMPATE.</li>
      <li>En caso de no seleccionar algún pick, se calificará como incorrecto, no se seleccionará ningún pick para ese partido, pero podrás seguir sumando puntos en el resto del Bracket.</li>
      <li>El objetivo del Bracket de puntos, es pronosticar correctamente el equipo que avanzará de ronda.</li>
      <li>Se darán puntos por cada acierto durante el Bracket y de acuerdo a la tabla de puntos del Bracket, el número de puntos otorgados por acierto, aumenta cada ronda de acuerdo a la siguiente tabla:</li>
      <table className="survivor-rules-table">
        <thead>
          <tr>
            <th className='text-center'>RONDA</th>
            <th className='text-center'>PUNTOS</th>
          </tr>
        </thead>
          <tbody>
            <tr className="text-center"><td>OCTAVOS</td><td>1</td></tr>
            <tr className="text-center"><td>CUARTOS</td><td>2</td></tr>
            <tr className="text-center"><td>SEMIFINAL</td><td>4</td></tr>
            <tr className="text-center"><td>FINAL</td><td>8</td></tr>
          </tbody>
        </table>
      <li>Los puntos se otorgan si el equipo que seleccionaste avanza de ronda sin importar si el rival era el seleccionado. Por ejemplo: Si un usuario selecciona que México ganará la final frente a Brasil y México gana la final frente a España. El usuario ganaría 8 puntos al pronosticar correctamente el ganador de la final sin importar que no era el rival pronosticado.</li>
      <li>Los premios se entregarán de acuerdo a la estructura de premios, resultando ganador el participante que mas puntos sume durante toda la 2da fase del mundial.</li>
      <li>En caso de empate en posiciones premiadas, se usarán los criterios de desempate. 1er criterio de desempate, el equipo seleccionado campeón, 2do criterio de desempate, el equipo seleccionado subcampeón. Los desempates se tienen que llenar en el apartado de desempates. En caso de solamente llenar el Bracket y no llenar los desempates, no se tomarán en cuenta los picks del Bracket como criterio de desempate.</li>
      <li>Si un partido es pospuesto, cancelado o anulado, será a discreción de Pickwin si se toma en cuenta el partido o si se cancela</li>
      <li>Cualquier anomalía en la actividad de un participante, podrá resultar en su descalificación a consideración de Pickwin.</li>
      <li>Podrás ver las selecciones de todos los participantes una vez que comiencen los partidos.</li>
    </ol>
  </div>
);

/**
 * This function return the Scoreboard component of the PopUp
 * it uses the props of his parent to render the data of the quiniela
 **/
function ScorePopup(props) {
  // console.log('SPopup', props);
  if (!props.props) {
    return null;
  }

  let currencyIcon = null;
  let currencyFormat = null;
  let currencyAbbrev = null;

  switch (props.props.entry_currency) {
    case 'real':
      currencyIcon = <img src={billete} className="score-icons" />;
      currencyFormat = '$0,000';
      currencyAbbrev = 'MXN';
      break;
    case 'pickcoin':
      currencyIcon = <img src={pickcoins} className="score-icons" />;
      currencyFormat = '0,000';
      currencyAbbrev = 'PC';
      break;
    case 'referral':
      currencyIcon = <img src={pickcoinsPlata} className="score-icons" />;
      currencyFormat = '0';
      currencyAbbrev = 'TK';
      break;
    case 'freeroll':
      currencyIcon = <img src={freeRollIcon} className="score-icons" />;
      currencyFormat = '0';
      currencyAbbrev = '';
      break;
  }

  let promoImage = null;
  switch (props.props.id) {
    case 12232:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/rotoplas-small.png"
          className="promo-image"
        />
      );
      break;
    case 12609:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/gen-small.png"
          className="promo-image"
        />
      );
      break;
    case 12509:
      promoImage = <img src="" className="promo-image" />;
      break;
    case 12752:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/curadeuda-small.png"
          className="promo-image"
        />
      );
      break;
    case 12805:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/google-logo.png"
          className="promo-image"
        />
      );
      break;
    case 12926:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-cinemex.png"
          className="promo-image"
        />
      );
      break;
    case 12222:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-nxt-company.png"
          className="promo-image"
        />
      );
      break;
    case 13102:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-maxim.jpg"
          className="promo-image"
        />
      );
      break;
    default:
      promoImage = null;
      break;
  }

  if (!promoImage && props.itNeedsLogo) {
    promoImage = (
      <img
        src="https://storage.googleapis.com/pw-resources/privadas/quiniela-invex-2018.png"
        className="promo-image"
      />
    );
  }

  return (
    <div id="score-lobby">
      <div className="score-container ">
        <div className="upper-row row">
          <button
            className="cerrar-panel pop"
            onClick={(e) => {
              props.close(e);
              if (props.popUpNewUserStatus && props.popUpNewUsers.firstTimeType === 'product') {
                console.log('Show Regalo Popup');
                props.openPopUpRegalo();
              }
            }}
          >
            <div className="btn-icon-close">
              <img src={iconClose} />
            </div>
          </button>
          <div className="row free">
            <div className="pickwin-score">
              {/*<img src={pickwinLogo} className="img-responsive" />*/}
            </div>
          </div>
        </div>

        {/* Principal content */}
        <div className="center-row row main-header-container">
          {/* Icon contest and rules */}
          <div className='col-xs-2 text-center'>
            <DisplayIconsType id={props.props.id} type={props.props.game_type} isPick5="" variation={props.props.variation} big={false} size={'65px'} />
          </div>

          {/* Information */}
          <div className='col-xs-8 text-center'>
            <div className="header-lobby-title">
              <DisplaySport sports={props.props.sport} type={'pool'} size={'20px'}/>
              <div className="flag-wrapper">
                <DisplayFlag type="pool" flag={props.props} />
              </div>
              {props.props.point_format === 'line' && (<img className="score-icons" src={line} />) || ''}
              {props.props.point_format === 'natural' && (<img className="score-icons" src={natural} />) }
              <img className="score-icons" src={props.props.guaranteed_prize !== null ? garantizada : null} />
              <img className="score-icons" src={props.props.brackets_entry_count !== undefined ? bracket : null} />
              <div className='font-chivo font18'>
                <strong>{props.props.name_replaced !== undefined ? props.props.name_replaced : props.props.name}</strong>
              </div>
              <DisplaySport sports={props.props.sport} type={'pool'} size={'20px'}/>
            </div>

            <div className="flex-row">
              <div className="flex-col sub-info-container">
                <div className="button-header-background2 header-title-info">
                  <div className="title-wrap">PRECIO:</div>
                </div>
                <div className="price-info-container mt-1">
                  <div className="entry-data money font16">
                    {currencyIcon}
                    <span> {numeral(props.props.entry_fee / 100).format(currencyFormat)} </span>
                    <sup style={{ fontSize: '8px' }}> {currencyAbbrev} </sup>
                  </div>
                  {props.props.entry_currency === 'real' ? (
                    <div style={{fontSize: '10px', marginTop: '5px' }}>
                      {numeral(props.props.real_entry_fee / 100).format('$0,000.00')} para bolsa,{' '}
                      {numeral(props.props.commission_fee / 100).format('$0,000.00')} comisión
                      {props.props.perfect_prize_reserve > 0 ? (
                        <span>
                          +({numeral(props.props.perfect_prize_reserve / 100).format('$0,000')})
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  {props.props.max_multientries > 0 ? (
                    <div>
                      <small style={{ color: '#fec830', fontSize: '10px', marginTop: '5px'}}>
                        Limitado a {props.props.max_multientries} registros por usuario
                      </small>
                    </div>
                  ) : null}
                  {props.props.strikes_enabled ? (
                    <div className="flex-container justify align maxStrikes">
                      <div style={{fontSize: '10px'}}> {props.props.max_strikes || props.props.initial_strikes} Strikes </div>
                      <div>
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={
                            props.props.point_format === 'natural'
                              ? popoverQueEsStrike
                              : popoverQueEsStrikeLinea
                          }
                        >
                          <i
                            className="ion-help-circled"
                            style={{ color: '#fff', marginLeft: '3px' }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="flex-col sub-info-container">
                <div className="button-header-background2 header-title-info">
                  <div className="title-wrap">BOLSA:</div>
                </div>
                <div className='mt-1 font16'>
                  <DisplayBolsaScore poolInfo={props.props} />
                  {props.props.perfect_prize && <b style={{ color: '#fff900', fontSize: '10px' }}> + ($1M PERFECTA)</b>}
                </div>
              </div>

              <div className="flex-col sub-info-container">
                <div className="button-header-background2 header-title-info">
                  <div className="title-wrap">{props.props.game_type === 'survivor' && 'VIVOS:' || 'PARTICIPANTES:'}</div>
                </div>
                <div className="users-info-container mt-1 font16">
                  {props.props.game_type === 'survivor' ? (
                    <div className='user-content'>
                      <img src={iconUser} style={{width: '14px', height: 'auto'}} />
                      {props.props.alive_count}/{props.props.entry_count}
                    </div>
                  ) : (
                    <div className='user-content'>
                      <img src={iconUser} style={{width: '14px', height: 'auto'}} />
                      {props.props.entry_count}
                      {props.props.pickngo === true ? ` / ${props.props.max_entries}` : ''}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Status */}
          <div className='col-xs-2'>
            {props.props.closed ? (
              <div className="clock-wrapper ">
                <div className="col-xs-10 col-xs-offset-1">
                  <div className='status-text closed'>Cerrada</div>
                  <div className="text-center">
                    {props.props.game_type === 'pool' || props.props.game_type === 'group_pool'
                      ? 'Esta quiniela'
                      : props.props.game_type === 'survivor'
                      ? 'Este survivor'
                      : 'Este bracket'}{' '}
                    ya finalizó.
                  </div>
                </div>
              </div>
            ) : props.status === 'live' ? (
              <div className="clock-wrapper ">
                <div className="col-xs-10 col-xs-offset-1">
                  <div className='status-text standby'>LIVE</div>
                  <div className="text-center">
                    {props.props.game_type === 'pool' || props.props.game_type === 'group_pool'
                      ? 'Esta  quiniela'
                      : props.props.game_type === 'survivor'
                      ? 'Este survivor'
                      : 'Este bracket'}{' '}
                    está en vivo.
                  </div>
                </div>
              </div>
            ) : (
              <div className="clock-wrapper ">
                <div className="col-xs-10 col-xs-offset-1">
                  <div className='status-text standby'>Empieza:</div>
                  <ClockHandler
                      type="flip"
                      id={props.props.id}
                      changeStatusPool={
                        props.changeStatusPool !== undefined ? props.changeStatusPool : undefined
                      }
                      endDate={props.props.deadline}
                      poolId={props.props.id}
                      dispatch={props.dispatch}
                      updateClosedQuiniela={props.updateClosedQuiniela}
                    />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* End principal content */}
        {utils.isLogged() && utils.isSuPick() ? null : (
          <LowerRowButtons
            isReferral={props.isReferral}
            dispatch={props.dispatch}
            bucketKeys={props.bucketKeys}
            players={props.players}
            buckets={props.buckets}
            ties={props.ties}
            gameInfo={props.props}
            showInvite={props.showInvite}
            isSpecial={promoImage !== null}
            logged={props.logged}
            type="popup"
            contests={props.contests}
            ticketsAvailable={props.ticketsAvailable}
          />
        )}
      </div>
    </div>
  );
}

/**
 * This function return the Score in the Quiniela Lobby
 * and uses the props of his parent to show the info of the quina
 */
function ScoreLobby(props) {
  let listEntries;
  if (props.entries) {
    props.entries.sort(firstBy('number'));
    listEntries = props.entries.map((entry, i) => {
      if (!entry) {
        return;
      }
      let gameType = null;
      // console.log( props.props.game_type);
      if (props.props.game_type === 'survivor') {
        gameType = 'survivor';
      } else if (props.props.game_type === 'bracket') {
        gameType = 'bracket';
      } else if (props.props.game_type === 'group_pool') {
        gameType = 'group_pool';
      } else {
        gameType = 'quiniela';
      }

      return (
        <LinkContainer
          key={i}
          data-entry={entry.id}
          // style={
          //   entry.pick_count !== 0
          //     ? { color: '#78b529', fontWeigth: 'bold' }
          //     : { color: '#E00034', fontWeigth: 'bold' }
          // }
          to={`/${gameType}/${props.props.slug}/${props.props.id}/${entry.id}`}
          onClick={(e) => {
            props.closeDropdown(e);
            props.changeSelectedEntry(entry.id);
          }}
        >
          <ToggleButton
            id={entry.id}
            value={entry.id}
            className="multiple-entry-button"
            onClick={props.closeDropdown}
          >
            Entrada #{entry.number === null ? '1' : entry.number}
          </ToggleButton>
        </LinkContainer>
      );
    });
  } else {
    listEntries = [];
  }

  let currencyIcon = null;
  let currencyFormat = null;
  let currencyAbbrev = null;

  switch (props.props.entry_currency) {
    case 'real':
      currencyIcon = <img src={billete} className="score-icons" />;
      currencyFormat = '$0,000';
      currencyAbbrev = 'MXN';
      break;
    case 'pickcoin':
      currencyIcon = <img src={pickcoins} className="score-icons" />;
      currencyFormat = '0,000';
      currencyAbbrev = 'PC';
      break;
    case 'referral':
      currencyIcon = <img src={pickcoinsPlata} className="score-icons" />;
      currencyFormat = '0';
      currencyAbbrev = 'TK';
      break;
    case 'freeroll':
      currencyIcon = <img src={freeRollIcon} className="score-icons" />;
      currencyFormat = '0';
      currencyAbbrev = '';
      {
        /* console.log('Precio freeroll',currencyIcon) */
      }
      break;
  }

  let promoImage = null;
  switch (props.props.id) {
    case 12232:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/rotoplas-small.png"
          className="promo-image"
        />
      );
      break;
    case 12609:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/gen-small.png"
          className="promo-image"
        />
      );
      break;
    case 12509:
      promoImage = <img src="" className="promo-image" />;
      break;
    case 12752:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/curadeuda-small.png"
          className="promo-image"
        />
      );
      break;
    case 12805:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/google-logo.png"
          className="promo-image"
        />
      );
      break;
    case 12926:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-cinemex.png"
          className="promo-image"
        />
      );
      break;
    case 12222:
      promoImage = (
        <img
          src="https://storage.googleapis.com/pw-resources/privadas/logo-nxt-company.png"
          className="promo-image"
        />
      );
      break;
    default:
      promoImage = null;
      break;
  }

  if (!promoImage && props.itNeedsLogo) {
    promoImage = (
      <img
        src="https://storage.googleapis.com/pw-resources/privadas/quiniela-invex-2018.png"
        className="promo-image"
      />
    );
  }

  return (
    <div className="flex-row">
      <div
        id="score-lobby"
        //className="col-xs-12 col-lg-10 col-lg-offset-1 col-fhd-8 col-fhd-offset-2 lobby"
        className="col-xs-12 col-lg-10 col-fhd-8 col-fhd-offset-2 lobby"
        style={{margin: '0 auto'}}
      >
        <div className="row">
          <div className="score-container col-xs-12 ">
            <div className="upper-row row">
              <div className="row free">
                <div className="pickwin-score" />
              </div>
            </div>

            <div className="center-row row main-header-container">
              {/* Icon contest and rules */}
              <div className='col-xs-2 text-center'>
                <DisplayIconsType id={props.props.id} type={props.props.game_type} isPick5="" variation={props.props.variation} big={false} size={'65px'} />
              </div>

              {/* Information */}
              <div className='col-xs-8 text-center'>
                <div className="header-lobby-title">
                  <DisplaySport sports={props.props.sport} type={'pool'} size={'20px'}/>
                  <div className="flag-wrapper">
                    <DisplayFlag type="pool" flag={props.props} />
                  </div>
                  {props.props.point_format === 'line' && (<img className="score-icons" src={line} />) || ''}
                  {props.props.point_format === 'natural' && (<img className="score-icons" src={natural} />) }
                  <img className="score-icons" src={props.props.guaranteed_prize !== null ? garantizada : null} />
                  <img className="score-icons" src={props.props.brackets_entry_count !== undefined ? bracket : null} />
                  <div className='font-chivo font18'>
                    <strong>{props.props.name_replaced !== undefined ? props.props.name_replaced : props.props.name}</strong>
                  </div>
                  <DisplaySport sports={props.props.sport} type={'pool'} size={'20px'}/>
                </div>

                <div className="flex-row">
                  <div className="flex-col sub-info-container">
                    <div className="button-header-background2 header-title-info">
                      <div className="title-wrap">PRECIO:</div>
                    </div>
                    <div className="price-info-container mt-1">
                      <div className="entry-data money font16">
                        {currencyIcon}
                        <span> {numeral(props.props.entry_fee / 100).format(currencyFormat)} </span>
                        <sup style={{ fontSize: '8px' }}> {currencyAbbrev} </sup>
                      </div>
                      {props.props.entry_currency === 'real' ? (
                        <div style={{fontSize: '9px', marginTop: '5px' }}>
                          {numeral(props.props.real_entry_fee / 100).format('$0,000.00')} para bolsa,{' '}
                          {numeral(props.props.commission_fee / 100).format('$0,000.00')} comisión
                          {props.props.perfect_prize_reserve > 0 ? (
                            <span>
                              +({numeral(props.props.perfect_prize_reserve / 100).format('$0,000')})
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                      {props.props.max_multientries > 0 ? (
                        <div>
                          <small style={{ color: '#fec830', fontSize: '9px'}}>
                            Limitado a {props.props.max_multientries} registros por usuario
                          </small>
                        </div>
                      ) : null}
                      {props.props.strikes_enabled ? (
                        <div className="flex-container justify align maxStrikes">
                          <div style={{fontSize: '9px'}}>{props.props.max_strikes || props.props.initial_strikes} Strikes </div>
                          <div>
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement="right"
                              overlay={
                                props.props.point_format === 'natural'
                                  ? popoverQueEsStrike
                                  : popoverQueEsStrikeLinea
                              }
                            >
                              <i
                                className="ion-help-circled"
                                style={{ color: '#fff', marginLeft: '3px' }}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex-col sub-info-container">
                    <div className="button-header-background2 header-title-info">
                      <div className="title-wrap">BOLSA:</div>
                    </div>
                    <div className='mt-1 font16'>
                      <DisplayBolsaScore poolInfo={props.props} />
                      {props.props.perfect_prize && <b style={{ color: '#fff900', fontSize: '10px' }}> + ($1M PERFECTA)</b>}
                    </div>
                  </div>

                  <div className="flex-col sub-info-container">
                    <div className="button-header-background2 header-title-info">
                      <div className="title-wrap">{props.props.game_type === 'survivor' && 'VIVOS:' || 'PARTICIPANTES:'}</div>
                    </div>
                    <div className="users-info-container mt-1 font16">
                      {props.props.game_type === 'survivor' ? (
                        <div className='user-content'>
                          <img src={iconUser} style={{width: '14px', height: 'auto'}} />
                          {props.props.alive_count}/{props.props.entry_count}
                        </div>
                      ) : (
                        <div className='user-content'>
                          <img src={iconUser} style={{width: '14px', height: 'auto'}} />
                          {props.props.entry_count}
                          {props.props.pickngo === true ? ` / ${props.props.max_entries}` : ''}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Status */}
              <div className='col-xs-2 text-center'>
                {props.props.closed ? (
                  <div className="clock-wrapper ">
                    <div className="col-xs-10 col-xs-offset-1">
                      <div className='status-text closed'>Cerrada</div>
                      <div className="text-center">
                        {props.props.game_type === 'pool' || props.props.game_type === 'group_pool'
                          ? 'Esta quiniela'
                          : props.props.game_type === 'survivor'
                          ? 'Este survivor'
                          : 'Este bracket'}{' '}
                        ya finalizó.
                      </div>
                    </div>
                  </div>
                ) : props.status === 'live' ? (
                  <div className="clock-wrapper ">
                    <div className="col-xs-10 col-xs-offset-1">
                      <div className='status-text standby'>LIVE</div>
                      <div className="text-center">
                        {props.props.game_type === 'pool' || props.props.game_type === 'group_pool'
                          ? 'Esta  quiniela'
                          : props.props.game_type === 'survivor'
                          ? 'Este survivor'
                          : 'Este bracket'}{' '}
                        está en vivo.
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="clock-wrapper ">
                    <div className="col-xs-10 col-xs-offset-1">
                      <div className='status-text standby'>Empieza:</div>
                      <ClockHandler
                        type="flip"
                        id={props.props.id}
                        changeStatusPool={
                          props.changeStatusPool !== undefined ? props.changeStatusPool : undefined
                        }
                        endDate={props.props.deadline}
                        poolId={props.props.id}
                        dispatch={props.dispatch}
                        updateClosedQuiniela={props.updateClosedQuiniela}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {utils.isLogged() && utils.isSuPick() ? null : (
              <LowerRowButtons
                players={props.players}
                bucketKeys={props.bucketKeys}
                isReferral={props.isReferral}
                dispatch={props.dispatch}
                ties={props.ties}
                buckets={props.buckets}
                changeSelectedEntry={props.changeSelectedEntry}
                closeDropdown={props.closeDropdown}
                updateBranchLink={props.updateBranchLink}
                gameInfo={props.props}
                entries={props.entries}
                getBranchLink={props.getBranchLink}
                handleClickDropdown={props.handleClickDropdown}
                handleClickFirstRegister={props.handleClickFirstRegister}
                handleClickReRegister={props.handleClickReRegister}
                isSpecial={promoImage !== null}
                logged={props.logged}
                openDrop={props.openDrop}
                selectedEntry={props.selectedEntry}
                showInvite={props.showInvite}
                type="lobby"
                updateStateFuncCancel={props.updateStateFuncCancel}
                withRegister={props.withRegister}
                LowerRowButtons={true}
                currentContest={props.currentContest}
                entryCurrency={props.entryCurrency}
                contests={props.contests}
                ticketsAvailable={props.ticketsAvailable}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

class Score extends React.Component {
  constructor(props) {
    super(props);
    // console.log('ScoreProps', this);
    // console.log('ScoreProps', this.props.entries !== null ? this.props.entries.filter(item => item.pool_id === this.props.quinaID) : null)))
    this.state = {
      openDrop: false,
      entries: null,
      item: this.props.poolInfo,
      misQuinielas: null,
      mounted: false,
      popup: this.props.popup,
      redirectLogin: false,
      redirectRegister: false,
      itNeedsLogo: false,
      selectedEntry: this.props.selectedEntry,
    };

    this.redirectButton = this.redirectButton.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleClickDropdown = this.handleClickDropdown.bind(this);

    this.getBranchLink = () => {
      console.log('branch link');
      console.log(this.props.poolInfo);

      let gameType = this.props.poolInfo.hasOwnProperty('pool')
        ? this.props.poolInfo.pool.game_type
        : this.props.poolInfo.hasOwnProperty('survivor')
        ? 'survivor'
        : 'bracket';
      let gameInfo = this.props.poolInfo.hasOwnProperty('pool')
        ? this.props.poolInfo.pool
        : this.props.poolInfo.hasOwnProperty('survivor')
        ? this.props.poolInfo.survivor
        : this.props.poolInfo;

      return branchUtils.createLink(utils.getUserInfo(), gameInfo, gameType);
    };

    // console.log('score', this.state )
  }

  /**
   * This fuction changes the state of the dropdown
   * of the Multiple Entries Menu
   * @param e
   */
  handleClickDropdown() {
    this.setState((prevState) => {
      return {
        openDrop: !prevState.openDrop,
      };
    });
  }

  /**
   * This fuction makes invisible the dropdown
   * of the Multiple Entries Menu
   * @param e
   */
  closeDropdown(e) {
    // console.log(e.target);
    // let drop = $('.misRegistros .dropdown');
    // let dropButton = $('#dropdown-registros-multiples');
    // drop !== undefined ? drop.removeClass('open'): null;
    // dropButton !== undefined ? dropButton.attr('aria-expanded', 'false'): null;
    setTimeout(() => {
      this.setState({ openDrop: false });
    }, 500);
  }

  /**
   *This function activate the redirection
   */
  redirectButton(e) {
    //Si dio click a login llevar a login,
    // si dio clic en register redireccionar a register
    let buttonVal = e.target.attributes[0].value;
    if (buttonVal === 'login') {
      console.log(buttonVal);
      this.setState({
        redirectLogin: true,
      });
    } else {
      console.log(buttonVal);
      this.setState({
        redirectRegister: true,
      });
    }
  }

  /********************++
   *LifeCycle Methods
   **********************/
  componentDidMount() {
    let gameInfo = this.props.poolInfo.hasOwnProperty('pool')
      ? this.props.poolInfo.pool
      : this.props.poolInfo.hasOwnProperty('bracket')
      ? this.props.poolInfo.bracket
      : this.props.poolInfo.survior;
    if (!gameInfo) {
      gameInfo = this.props.poolInfo;
    }

    if (this.props.withRegister) {
      let newEntries = [];
      if (this.props.entries.entry !== undefined) {
        newEntries = [this.props.entries.entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.bracket_entry !== undefined) {
        newEntries = [this.props.entries.bracket_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.survivorEntry !== undefined) {
        // console.log('Survivor', this.props.entries.survivorEntry)
        newEntries = [this.props.entries.survivor_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.group_pool_entry !== undefined) {
        newEntries = [this.props.entries.group_pool_entry, ...this.props.entries.other_entries];
      }
      // console.log( newEntries );
      this.setState({
        itNeedsLogo: utils.itNeedsImage('Invex', gameInfo.name_replaced),
        entries: newEntries,
      });
    } else {
      this.setState({
        itNeedsLogo: utils.itNeedsImage('Invex', gameInfo.name_replaced),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * SurivorRemapping Entries
     */
    if (this.props.entries && this.props.entries.survivor_entry && this.props.withRegister) {
      // console.log('changed other entries')
      let newEntriesSurvivor = [];

      if (this.props.entries.survivor_entry !== undefined) {
        newEntriesSurvivor = [
          this.props.entries.survivor_entry,
          ...this.props.entries.other_entries,
        ];
      }
      //console.log(newEntriesSurvivor , this.state.entries);

      if (!this.state.entries) {
        this.setState({
          entries: newEntriesSurvivor,
          popup: this.props.popup,
          item: this.props.poolInfo,
          selectedEntry: this.props.selectedEntry,
        });
      } else if (
        newEntriesSurvivor.length !== this.state.entries.length ||
        this.props.selectedEntry !== prevProps.selectedEntry
      ) {
        this.setState({
          entries: newEntriesSurvivor,
          popup: this.props.popup,
          item: this.props.poolInfo,
          selectedEntry: this.props.selectedEntry,
        });
      }
    } else if (
      this.props.entries &&
      this.props.entries.group_pool_entry &&
      this.props.withRegister
    ) {
      let newEntriesGroupPool = [];

      if (this.props.entries.group_pool_entry !== undefined) {
        newEntriesGroupPool = [
          this.props.entries.group_pool_entry,
          ...this.props.entries.other_entries,
        ];
      }
      //console.log(newEntriesGroupPool, this.props.entries);

      if (!this.state.entries) {
        this.setState({
          entries: newEntriesGroupPool,
          popup: this.props.popup,
          item: this.props.poolInfo,
          selectedEntry: this.props.selectedEntry,
        });
      } else if (
        newEntriesGroupPool.length !== this.state.entries.length ||
        this.props.selectedEntry !== prevProps.selectedEntry
      ) {
        this.setState({
          entries: newEntriesGroupPool,
          popup: this.props.popup,
          item: this.props.poolInfo,
          selectedEntry: this.props.selectedEntry,
        });
      }
    }

    if (this.props.selectedEntry !== prevProps.selectedEntry) {
      console.log('Changed Entry ');

      let newEntries = [];
      if (this.props.entries.entry !== undefined) {
        newEntries = [this.props.entries.entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.bracket_entry) {
        newEntries = [this.props.entries.bracket_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.survivorEntry) {
        newEntries = [this.props.survivor_entry, ...this.props.entries.other_entries];
      } else if (this.props.entries.group_pool_entry) {
        newEntries = [this.props.entries.group_pool_entry, ...this.props.entries.other_entries];
      }

      if (newEntries.length === 0) {
        // ----------------------------------------------------------------------------
        // For survivors
        if (this.props.entries.survivor_entry) {
          API.fetchSurvivorEntry(this.props.selectedEntry).then((response) => {
            this.setState({entries: [response.data.survivor_entry, ...response.data.other_entries]});
          });
        } else {
          // For pool
          API.getEntriesInfo(this.props.selectedEntry).then((response) => {
            this.setState({entries: [response.data.entry, ...response.data.other_entries]});
          });
        }
      }

      this.setState({
        entries: newEntries,
        popup: this.props.popup,
        item: this.props.poolInfo,
        selectedEntry: this.props.selectedEntry,
      });
    }
  }

  render() {
    let conditionalComponent;
    if (this.state.redirectLogin) {
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/ingresar`,
          }}
        />
      );
    } else if (this.state.redirectRegister) {
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/registrate`,
          }}
        />
      );
    } else {
      if (this.state.popup) {
        let gameInfo = this.props.poolInfo;

        // console.log(gameInfo)
        if (gameInfo !== null && gameInfo.pool !== undefined) {
          gameInfo = gameInfo.pool;
        } else if (gameInfo !== null && gameInfo.survivor !== undefined) {
          gameInfo = gameInfo.survivor;
        } else if (gameInfo !== null && gameInfo.bracket !== undefined) {
          gameInfo = gameInfo.bracket;
        }
        let isReferral = gameInfo && gameInfo.entry_currency === 'referral';

        // console.log( 'GameInfo',gameInfo );
        conditionalComponent = (
          <ScorePopup
            isReferral={isReferral}
            dispatch={this.props.dispatch}
            ties={this.props.ties}
            buckets={this.props.buckets}
            itNeedsLogo={this.state.itNeedsLogo}
            logged={this.props.logged}
            props={gameInfo}
            status={this.props.status}
            popUpNewUserStatus={this.props.popUpNewUsersStatus}
            popUpNewUsers={this.props.popUpNewUsers}
            openPopUpRegalo={this.props.openPopUpRegalo}
            close={this.props.close}
            changeStatusPool={this.props.changeStatusPool}
            redirectFunc={this.redirectButton}
            type={this.props.type}
            handleCancelPanel={this.props.handleCancelPanel}
            updateStateFuncCancel={this.props.updateStateFuncCancel}
          />
        );
      } else {
        let gameInfo = this.props.poolInfo;
        if (gameInfo.hasOwnProperty('pool') && gameInfo.pool !== undefined) {
          gameInfo = gameInfo.pool;
        } else if (gameInfo.survivor !== undefined) {
          gameInfo = gameInfo.survivor;
        }
        let isReferral = gameInfo.entry_currency === 'referral';

        conditionalComponent = (
          <ScoreLobby
            players={this.props.players}
            bucketKeys={this.props.bucketKeys}
            isReferral={isReferral}
            dispatch={this.props.dispatch}
            ties={this.props.ties}
            buckets={this.props.buckets}
            itNeedsLogo={this.state.itNeedsLogo}
            changeStatusPool={this.props.changeStatusPool}
            changeSelectedEntry={this.props.changeSelectedEntry}
            closeDropdown={this.closeDropdown}
            entries={this.state.entries}
            getBranchLink={this.getBranchLink}
            handleCancelPanel={this.props.handleCancelPanel}
            handleClickDropdown={this.handleClickDropdown}
            handleClickFirstRegister={this.props.handleClickFirstRegister}
            handleClickReRegister={this.props.handleClickReRegister}
            logged={this.props.logged}
            openDrop={this.state.openDrop}
            props={gameInfo}
            redirectFunc={this.redirectButton}
            selectedEntry={this.props.selectedEntry}
            showPanelCancel={this.props.showPanelCancel}
            showInvite={this.props.showInvite}
            hideInvite={this.props.hideInvite}
            status={this.props.status}
            type={this.props.type}
            updateClosedQuiniela={this.props.updateClosedQuiniela}
            updateBranchLink={this.props.updateBranchLink}
            updateStateFuncCancel={this.props.updateStateFuncCancel}
            withRegister={this.props.withRegister}
            currentContest={this.props.currentContest}
            entryCurrency={this.props.entryCurrency}
            contests={this.props.contests}
            ticketsAvailable={this.props.ticketsAvailable}
          />
        );
      }
    }

    return conditionalComponent;
  }
}

export default Score;
