import React, { useState, useEffect, useRef } from 'react'
import API from '../../../utils/api'
import utils from '../../../utils/utils'

// Get defaultBalanceVip
const getDefaultBalance = () => {
  return utils.isLogged()
    ? utils.getUserBalance()
    : {}
}

export default function useProducts () {
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState([])
  const [error, setError] = useState(null)
  const [productsFiltered, setProductsFiltered] = useState([])
  const [activeFilter, setActiveFilter] = useState('TODOS')
  const [balanceVip, setBalanceVip] = useState(0)
  const tick = useRef()

  // For modal confirm purchase
  const [activeProduct, setActiveProduct] = useState({})

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    const { balanceVip = 0 } = getDefaultBalance()
    setBalanceVip(balanceVip)
  }, [utils.isLogged()])

  const fetchProducts = async () => {
    setLoading(true)
    try {
      const { data } = await API.getStoreProducts()

      // Count total products
      const total = data.categories.reduce((prev, next) => prev + Number(next.total), 0)

      setProducts(data.products)
      setProductsFiltered(data.products)
      setCategories([
        { category: 'TODOS', total },
        ...data.categories,
      ])
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  const handleFilter = (activeFilter) => {
    setActiveFilter(activeFilter)

    setProductsFiltered([])

    window.setTimeout(() => {
      setProductsFiltered(getFilteredProducts(activeFilter))
    }, 300)
  }


  const getFilteredProducts = (activeFilter) => {
    if (activeFilter === 'TODOS') return products

    return products.filter(product => product.category === activeFilter)
  }


  const purchaseSuccess = (price) => {
    // Effect count down
    const start = balanceVip
    const end = balanceVip - price

    const duration = 1.5
    const fps = 60
    const realFPS = 1000 / fps
    const stepsCount = duration * 1000 / realFPS
    const step = (start - end) / stepsCount

    tick.current = window.setInterval(() => {
      setBalanceVip(prev => {
        const next = Math.round(prev - step)

        if (next <= end) {
          window.clearInterval(tick.current)
          tick.current = null
        }

        return next >= end ? next : end
      })
    }, realFPS)

  }

  return {
    productsFiltered,
    categories,
    error,
    loading,
    activeFilter,
    setActiveProduct,
    activeProduct,
    balanceVip,
    setBalanceVip,
    purchaseSuccess,
    setActiveFilter: handleFilter
  }
}