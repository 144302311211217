import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BarLoader, MoonLoader, PuffLoader } from 'react-spinners'

// Components
import Ripple from '../Ripple'
import Iconify from '../Iconify'

// Hooks
import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

Button.propTypes = {
  ...shorthandProptypes,
  onClick: PropTypes.func,
  delayClick: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  bgColor: PropTypes.string,
  noRipple: PropTypes.bool,
  rippleColor: PropTypes.string,
  icon: PropTypes.string,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  spinner: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

Button.defaultProps = {
  noRipple: false,
  bgColor: '#484848',
  rippleColor: '#bbf15d',
  iconAlign: 'left',
  rippleDuration: 1200,
  loaderColor: '#FFF',
  spinner: 'bar'
}

export default function Button (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  const emitClick = (event) => {
    if (typeof props.onClick === 'function') {
      props.onClick(event)
    }
  }

  const handleClick = (event) => {
    if (props.disabled) return

    const delay = props.delayClick || (props.delayWithRibble ? props.rippleDuration - 500 : null)

    if (delay) {
      window.setTimeout(emitClick, delay)
    } else {
      emitClick(event)
    }
  }

  const styles = {
    ...stylesShortcuts,
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    backgroundColor: props.disabled ? '#484848' : props.bgColor,
    color: props.disabled ? 'gray' : props.color,
    ...props.style
  }

  return (
    <ButtonBase
      onClick={handleClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      style={styles}
    >
      <Ripple
        color={props.rippleColor}
        duration={props.rippleDuration}
      />

      {/* ------------- Button content ------------- */}
      <Content style={{ opacity: props.loading ? 0 : 1 }}>
        {(props.icon && props.iconAlign === 'left') && (
          <Iconify icon={props.icon} mr={7} opacity={props.iconOpacity} />
        )}
        {props.children}
        {(props.icon && props.iconAlign === 'right') && (
          <Iconify icon={props.icon} ml={7} opacity={props.iconOpacity} />
        )}
      </Content>
      <SpinnerBox>
        {props.loading && getSpinner(props.spinner, props.loaderColor)}
      </SpinnerBox>
    </ButtonBase>
  )
}

const getSpinner = (type, color) => {
  switch (type) {
    case 'bar': return <BarLoader color={color} />
    case 'moon': return <MoonLoader color={color} size={20} />
    case 'puff': return <PuffLoader color={color} size={20} />
  }
}

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
`

const SpinnerBox = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonBase = styled.button`
  border: none;
  outline: none;
  border-radius: 0.4em;
  color: #FFF;
  width: fit-content;
  padding: 0.3em 2em;
  min-height: 33px;
  font-size: 1.1em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  transition: all 350ms ease;
  position: relative;
  overflow: hidden;

  &:hover {
    opacity: 0.9;
  }
`
