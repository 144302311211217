import React from 'react';
import firstBy from 'thenby';
import _ from 'underscore';

const ListBucketsShow = ({
  poolBuckets,
  myEntry,
  currentContest,
}) => {
  // console.log('ListBucketsShow', poolBuckets );
  // if( !poolBuckets || !myEntry ){ return null }
  if (!poolBuckets) {
    return null;
  }

  poolBuckets = poolBuckets[currentContest.id].sort(
    // firstBy((bucket) => {
    //   if (!bucket.player) {
    //     return null;
    //   }
    //   return bucket.player.position === null ? Number.MAX_SAFE_INTEGER : bucket.player.position;
    // }).thenBy((bucket) => {
    //   if (!bucket.player) {
    //     return null;
    //   }
    //   return bucket.player.score_num;
    // })
  );

  let listShow = _.map(poolBuckets, (bucket, bucketIndex) => {
    const selectedPlayer = myEntry.hasOwnProperty('group_pool_picks') && myEntry.group_pool_picks.hasOwnProperty(currentContest.id)
      ? bucket.players.find((o) => o.id === myEntry.group_pool_picks[currentContest.id][bucket.id].pick)
      : null;
    // console.log('selected Pick,' , bucket.players, selectedPlayer, bucketIndex );

    let titleButton = (
      <div className="btn-group-pick-collapse disabled align flex-container between ">
        <div className="flex-col col-5">{bucket.id}</div>
        <div className="flex-col">&nbsp;</div>
        <div className=" uppercase flex-col col-30 overflow-words" style={{ color: '#e00034' }}>
          N/D
        </div>
        <div className="flex-col text-center">-</div>
        <div className="flex-col text-center">-</div>
        <div className="flex-col text-center">-</div>
        <div className="flex-col text-center">-</div>
        <div className="flex-col text-center">0pts</div>
      </div>
    );
    if (selectedPlayer) {
      // console.log(selectedPlayer);

      let colorStyle = {};

      if (selectedPlayer.score > 0) {
        colorStyle = { color: 'rgb(255, 199, 48)' };
      } else if (selectedPlayer.score < 0) {
        colorStyle = { color: '#78bc28' };
      }

      titleButton = (
        <div className="btn-group-pick-collapse flex-row row-no-padding row-center ">
          <div className="flex-col col-5">{bucket.id}</div>
          <div className="flex-col">
            <img
              alt={selectedPlayer.name}
              src={selectedPlayer.avatar}
              className="player-avatar"
              style={{ width: '24px', height: '24px' }}
            />
          </div>
          <div className=" uppercase flex-col col-30">
            <div className="flex-row row-center row-no-padding">
              <i className={`${selectedPlayer.logo}`} style={{ marginRight: '3px' }} />
              <div className="overflow-words">{selectedPlayer.name}</div>
            </div>
          </div>
          <div className="flex-col  text-center">
            {selectedPlayer.position}{' '}
            {!selectedPlayer.position && selectedPlayer.position !== 0 ? '-' : '°'}
          </div>
          <div className="flex-col text-center" style={colorStyle}>
            {selectedPlayer.score === '0' ? 'E' : selectedPlayer.score}
          </div>
          <div className="flex-col  text-center">
            {selectedPlayer.data && selectedPlayer.data.today ? selectedPlayer.data.today : '-'}
          </div>
          <div className="flex-col  text-center">
            {selectedPlayer.data && selectedPlayer.data.hole !== ''
              ? selectedPlayer.data.hole
              : '-'}
          </div>
          <div className="flex-col  text-center">{selectedPlayer.points}</div>
        </div>
      );

      // if( selectedPlayer.score === 'CUT'){
      //   let cuttedElement = ( <div className='cutted bucket-item'>{titleButton}</div> )
      //   listCutted = [...listCutted, cuttedElement ]
      //   return null
      // }
    }

    return (
      <div
        key={bucket.id}
        data-rank={selectedPlayer ? selectedPlayer.score : null}
        className="bucket-item"
      >
        {titleButton}
      </div>
    );
  });

  // console.log( listShow );

  // listShow = _.sortBy(listShow, (item) => {
  //   console.log(item);
  //   return item.props['data-rank'];
  // });

  return (
    <div className="flex-col col-no-padding">
      <div className="flex-row title-quiniela sub">
        <div className="flex-col title-wrapper">Picks</div>
      </div>
      <div
        className="header-picks-partidos flex-row row-no-padding results"
        style={{ padding: '4px' }}
      >
        <div className="flex-col col-5">#</div>
        <div className="flex-col">&nbsp;</div>
        <div className="flex-col col-30">Jugador</div>
        <div className="flex-col ">Pos</div>
        <div className="flex-col ">Sc</div>
        <div className="flex-col ">Hoy</div>
        <div className="flex-col ">Hoyo</div>
        <div className="flex-col ">Pts</div>
      </div>
      {listShow}
      {/* {listCutted} */}
    </div>
  );
};

/**
 * This component Renders the title and the list of picks
 */
const PresentationPicks = ({ disabled, poolInfo, poolBuckets, dispatch, myEntry, currentContest }) => {
  return (
    <ListBucketsShow
      disabled={disabled}
      poolInfo={poolInfo}
      poolBuckets={poolBuckets}
      myEntry={myEntry}
      dispatch={dispatch}
      currentContest={currentContest}
    />
  );
};

export default PresentationPicks;
