import React from 'react'
import styled from 'styled-components'

// Components
import Iconify from '../../Iconify'
import Ripple from '../../Ripple'

const ButtonSelect = (props) => {
  const styles = {
    color: props.active ? '#FFF' : '#FFF',
    background: props.active ? 'linear-gradient(50deg, rgb(62 146 0 / 25%), rgb(140 198 63 / 32%))' : 'transparent',
    border: '2px solid gray',
    borderColor: props.active ? '#8cc63f' : 'gray'
  }

  return (
    <Button
      {...props}
      style={styles}
    >
      <Box />
      <div style={{ flexGrow: 10 }}>
        {props.children}
      </div>
      <Box>
        {props.active && (
          <Iconify icon='vaadin:dot-circle' color='#78bc28' />
        )}
      </Box>
      <Ripple
        color='#bbf15d'
        duration={1200}
      />
    </Button>
  )
}

const Button = styled.button`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding: 0.2em 0.3em;
  min-height: 35px;
  border-radius: 4px;
`

const Box = styled.div`
  width:25px;
  height: 1em;
  margin-left: 10px;
`

export default ButtonSelect
