// Libraries
import React from 'react';
import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';
import moment from 'moment';

// Components

// Images
import picks from '../../assets/images/quinielasIconBlanco-50.png';

// styles
import './bracket.scss';

const BracketResults = ({ stages, matches, currentStage }) => {
  const [selectedStage, setSelectedStage] = React.useState(currentStage ? currentStage.id : stages[0].id);
  const [stageMatches, setStagesMatches] = React.useState(null);
  var numMatches = [1,2,3,4,5,6,7];

  const handleStageChange = (stage) => {
    setSelectedStage(stage);
  }

  React.useEffect(() => {
    let filterMatches = matches.filter(match => match.stage_id === selectedStage);
    setStagesMatches(filterMatches);
  }, [selectedStage]);

  return (
    <div className="panel-pickwin flex-container column" >
      <div className="row title-quiniela ">
        <div className="title-wrapper">
          <img src={picks} className="pool-icons tablas" />
          <span>MARCADORES</span>
        </div>
      </div>


      <div className="flex-row font-chivo">
        <ButtonToolbar id="week-controls" className="flex-col">
          <ToggleButtonGroup
            className="flex-container align justify"
            name="weeks-controls"
            style={{width: '100%'}}
            type="radio"
            value={selectedStage}
            onChange={handleStageChange}
          >
            {stages.map((stage, i) => {
              return (
                <ToggleButton
                  key={stage.id}
                  className='bottom-separation font16'
                  value={stage.id}
                  style={{maxWidth: '15%', width: '15%', fontSize: '12px'}}
                >
                  {stage.name}
                </ToggleButton>
              );
            })}

          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>

      <div className="row overflow overflow-y overflow-picks-grupo m-1">
        {stageMatches && stageMatches.map((match => {
          let lastFixture =  match.fixtures[match.fixtures.length-1];
          return (
            <div key={match.id} className="marcador-partido-item-bracket col-sm-12 col-md-6 col-lg-4">
              <div className="marcador-panel flex-col col-no-padding border-gradient-marcador">
                <div className="backBlackBracket">
                  <div className='result-item'>
                    <div className="flex-row font-chivo text-bold" style={{borderBottom: '1px solid #424041', marginBottom: '5px'}}>
                      <div className="flex-col col-30 font12 align">
                        {match.sort_status === 0 && lastFixture.stats &&
                          <div className='flex-container align'>
                            <i className="icon ion-ios-clock icon-bracket"></i>
                            {lastFixture.stats && lastFixture.stats.quarter}
                            &nbsp;-&nbsp;'
                            {lastFixture.stats && lastFixture.stats.timer}
                          </div>
                        ||
                          moment(match.deadline).format("ddd DD, MMM HH:mm")
                        }
                      </div>
                      <div className="flex-col col-10 text-center"></div>
                      {numMatches.map(num => {
                        return <div className={`flex-col flex-container align justify font10 ${(num === match.fixtures.length && match.sort_status === 0 && lastFixture.stats) ? 'game-live' : ''}`} key={num}>{num}</div>
                      })}
                    </div>
                    {/* Away team */}
                    <div className="flex-row" style={{borderBottom: '1px solid #424041', marginBottom: '5px'}}>
                      <div className="flex-col col-30">
                        <div className="flex-container align around">
                          {match.away_team === null && <i className="quina team-gen-basketball"></i> || <i className={`quina ${match.away_team.logo}`}></i> }
                          <div className='text-center'>
                            <div className='font-chivo text-bold'>
                              { match.away_team !== null && match.away_team.abbreviation || 'PD'}
                            </div>
                            <div>
                              {match.away_team && match.away_team.team_stats &&
                                match.away_team.sport_id != 1 && (
                                  <small>
                                    ({match.away_team.team_stats.win}-
                                    {match.away_team.team_stats.lost})
                                  </small>
                                )}
                              { match.away_team && match.away_team.team_stats &&
                                match.away_team.sport_id == 1 && (
                                  <small>
                                    ({match.away_team.team_stats.win}-
                                    {match.away_team.team_stats.ties}-
                                    {match.away_team.team_stats.lost})
                                  </small>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-col col-10 flex-container align justify font-chivo text-bold font14">{match.games_won_away}</div>
                      {numMatches.map(num => {
                        let fixture = match.fixtures[num-1];
                        if (fixture) {
                          return <div className={`flex-col flex-container align justify font12 ${fixture.winner_team_id  === match.away_team_id ? 'font-chivo text-bold' : ''}`} key={num}>
                            {match.away_team_id === fixture.away_team_id ? fixture.score_away : fixture.score_home}
                          </div>;
                        } else {
                          return <div className="flex-col flex-container align justify font12" key={num}> - </div>;
                        }
                      })}
                    </div>

                    {/* Home team */}
                    <div className="flex-row">
                      <div className="flex-col col-30">
                        <div className="flex-container align around">
                          {match.home_team === null && <i className="quina team-gen-basketball"></i> || <i className={`quina ${match.home_team.logo}`}></i> }
                          <div className='text-center'>
                            <div className='font-chivo text-bold'>{match.home_team && match.home_team.abbreviation || 'PD'}</div>
                            <div>
                              {match.home_team && match.home_team.team_stats &&
                                match.home_team.sport_id != 1 && (
                                  <small>
                                    ({match.home_team.team_stats.win}-
                                    {match.home_team.team_stats.lost})
                                  </small>
                                )}
                              {match.home_team && match.home_team.team_stats &&
                                match.home_team.sport_id == 1 && (
                                  <small>
                                    ({match.home_team.team_stats.win}-
                                    {match.home_team.team_stats.ties}-
                                    {match.home_team.team_stats.lost})
                                  </small>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-col col-10 flex-container align justify font-chivo text-bold font14">{match.games_won_home}</div>
                      {numMatches.map(num => {
                        let fixture = match.fixtures[num-1];
                        if (fixture) {
                          return <div className={`flex-col flex-container align justify font12 ${fixture.winner_team_id  === match.home_team_id ? 'font-chivo text-bold' : ''}`} key={num}>
                            {match.home_team_id === fixture.home_team_id ? fixture.score_home : fixture.score_away}
                          </div>;
                        } else {
                          return <div className="flex-col flex-container align justify font12" key={num}> - </div>;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }))}
      </div>
    </div>
  );
}

export default BracketResults;
