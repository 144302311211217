import React from 'react'
import styled from 'styled-components'
import numeral from 'numeral'

// Hooks
import useCountUp from '../../../hooks/useCountUp'

export default function BalanceReal ({ prevBalance, nextBalance }) {
  const num = useCountUp({
    start: prevBalance,
    end: nextBalance,
    duration: 2
  })

  const balance = numeral(num / 100).format('$0,000.00')

  return (
    <Box>
      <span>Balance Real</span>
      <Real>{balance}</Real>
    </Box>
  )
}


const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.5em 1em;
  border-radius: 0.2em;
  margin-top: 1em;
  border: 1px solid #619b1e7a;

  span {
    color: #FFF;
    font-size: 0.8em;
    line-height: 2;
  }
`

const Real = styled.div`
  color: #99d928;
  font-size: 1.4em;
  font-size: 1.4em;
  font-family: 'Chivo';
  text-align: center;
  font-weight: 400;
  background: linear-gradient(to right, #88e700 0%, #ddff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
