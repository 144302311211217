import React from 'react';
import {
  FormControl,
  FormGroup,
  Button,
  ControlLabel,
  HelpBlock,
  Modal,
} from 'react-bootstrap/lib';

import API from '../../utils/api';

class ChallangeUserMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePanel: this.props.hidePanel,
      showPanel: this.props.showPanel,
      poolInfo: this.props.pool,
      userData: this.props.userDataProps,
      redirectLobbyPool: false,
      respuestas: null,
      invitationSend: null,
      inviteMail: '',
      submitting: false,
      nameMail: '',
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.handleNameMail = this.handleNameMail.bind(this);
    this.handleInviteMail = this.handleInviteMail.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
  }

  sendInvitation(e) {
    this.setState({
      submitting: true,
    });
    let invitationData = {
      pool_id: this.state.inviteMail,
      user_id: this.state.nameMail,
    };
    API.postChallengeUser(invitationData).then((response) => {
      console.log(response);
      if (response !== undefined && response.data.result.success) {
        console.log('Success');
        this.setState({
          submitting: false,
          invitationSend: true,
          respuestas: response.data.result.success,
        });
      } else {
        console.log('Failed');
        this.setState({
          submitting: false,
          invitationSend: false,
          errorInvite: true,
        });
      }
    });
  }

  handleNameMail(e) {
    let val = e.target.value;
    this.setState({
      nameMail: val,
    });
  }
  handleInviteMail(e) {
    let val = e.target.value;
    this.setState({
      inviteMail: val,
    });
  }

  close() {
    // console.log('Close panel');
    this.setState({
      showPanel: false,
    });
  }

  open() {
    console.log('InvitationPanel, open', this.state);
    this.setState({
      showPanel: true,
    });
  }

  componentDidMount() {
    // console.log(this.props.pool);
    // this.props.pool !== undefined  ?
    //     this.setState({
    //         payType:this.props.payType,
    //         logged: this.props.logged
    //     })
    //     :
    //     null
    // console.log('Panel Mounted', this.state)
  }

  render() {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isMailValid = re.test(this.state.inviteMail);
    let conditionalComponent;
    conditionalComponent = (
      <div>
        <Modal
          backdropClassName="backdropInvitarMail"
          dialogClassName="invitarMailModalDialog"
          className="invitarMailModal"
          show={this.props.showPanel}
          bsSize="sm"
          onHide={this.props.hidePanel}
        >
          {this.props.showPanel ? (
            this.state.respuestas !== null ? (
              this.state.respuestas ? (
                <div id="invitar-panel">
                  <div id="panelTitle" className="panel-abonar-titulo">
                    <span>{'Tu invitación se envío con éxito'}</span>
                    <button className="cerrar-panel" onClick={this.props.hidePanel}>
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                  <div className="firstSection">
                    <div className="mensaje">
                      Ahora sólo espera a que tú invitado se registre, y diviértanse jugando en
                      Pickwin.
                    </div>
                  </div>
                </div>
              ) : (
                <div id="invitar-panel">
                  <div id="panelTitle" className="panel-abonar-titulo failed">
                    <span>
                      {
                        'Tenemos algunos problemas enviando tu invitación, por favor inténtalo nuevamente'
                      }
                    </span>
                    <button className="cerrar-panel" onClick={this.props.hidePanel}>
                      <div className="btn-icon-close">
                        <i className="ion-ios-close-empty" />
                      </div>
                    </button>
                  </div>
                  <div className="firstSection">
                    <FormGroup id="invitarAmigos">
                      <div className="direcciones-correo-list">
                        <ControlLabel>Dirección</ControlLabel>
                        <FormControl
                          id="mailInvite"
                          type="text"
                          value={this.state.inviteMail}
                          onChange={this.handleInviteMail}
                        />
                        <HelpBlock>Escribe las dirección de tú amigo.</HelpBlock>
                      </div>
                      <div className="mensaje-correo">
                        <ControlLabel>Nombre</ControlLabel>
                        <FormControl
                          id="nameInvite"
                          type="text"
                          value={this.state.nameMail}
                          onChange={this.handleNameMail}
                        />
                        <HelpBlock>
                          Escribe un mensaje personalizado para tus amigos.(opcional)
                        </HelpBlock>
                      </div>
                    </FormGroup>
                    {this.state.errorInvite ? (
                      <div className="error-formulario">
                        Tuvimos un problema al enviar tus invitaciones, por favor intentalo
                        nuevamente más tarde.
                      </div>
                    ) : null}
                    <div className="flex-row">
                      <Button
                        className="btn-regstrate btn-block callToAction"
                        bsStyle="success"
                        onClick={this.sendInvitation}
                        disabled={!isMailValid || !this.state.nameMail}
                      >
                        <span className="flex-col col-center text-center">
                          ENVIAR INVITACIÓN
                          <i className="ion-ios-paperplane" />
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div id="invitar-panel">
                <div id="panelTitle" className="panel-abonar-titulo">
                  <span>Invita a tus amigos.</span>
                  <button className="cerrar-panel" onClick={this.props.hidePanel}>
                    <div className="btn-icon-close">
                      <i className="ion-ios-close-empty" />
                    </div>
                  </button>
                </div>
                <div className="firstSection">
                  <FormGroup id="invitarAmigos">
                    <div className="direcciones-correo-list">
                      <ControlLabel>Dirección</ControlLabel>
                      <FormControl
                        id="mailInvite"
                        type="text"
                        value={this.state.inviteMail}
                        onChange={this.handleInviteMail}
                      />
                      <HelpBlock>Escribe la dirección de tú amigo.</HelpBlock>
                    </div>
                    <div className="mensaje-correo">
                      <ControlLabel>Nombre</ControlLabel>
                      <FormControl
                        id="nameInvite"
                        type="text"
                        value={this.state.nameMail}
                        onChange={this.handleNameMail}
                      />
                    </div>
                  </FormGroup>
                  {this.state.errorInvite ? (
                    <div className="error-formulario">
                      Tuvimos un problema al enviar tus invitaciones, por favor intentalo nuevamente
                      más tarde.
                    </div>
                  ) : null}
                  <div className="flex-row">
                    <Button
                      className="btn-regstrate btn-block callToAction"
                      bsStyle="success"
                      onClick={this.sendInvitation}
                      disabled={!isMailValid || !this.state.nameMail}
                    >
                      <span className="flex-col col-center text-center">
                        ENVIAR INVITACIÓN
                        <i className="ion-ios-paperplane" />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )
          ) : (
            <span>LOADING...</span>
          )}
        </Modal>
      </div>
    );

    // console.log('Render Panel',this.props);
    return conditionalComponent;
  }
}

export default ChallangeUserMail;
