import React from 'react'
import styled from 'styled-components'

export default function Filters ({ categories, activeFilter, setActiveFilter }) {
  return (
    <Box>
      <Overflow>
        {categories.map(({ category }, index) => (
          <Item
            key={index}
            active={activeFilter === category}
            onClick={() => setActiveFilter(category)}
          >
            {category}
          </Item>
        ))}
      </Overflow>
    </Box>
  )
}


const Box = styled.div`
  display: flex;
  gap: 1em;
  height: 50px;
  margin: 1em auto;
  max-width: 700px;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  margin-bottom: 2em;
  background: linear-gradient(359deg,#1e1d1d,#000000);
  box-shadow: 0px 1px 2px inset #9d9c9c, 1px 0px 9px inset #1c1c1c9e;
`

const Overflow = styled.div`
  display: flex;
  width: 100%;
  min-width: 350px;
  overflow-y: hidden;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0 1em;
`

const Item = styled.div`
  padding: 1em;
  font-size: 0.8em;
  cursor: pointer;
  font-family: 'Chivo';
  font-weight: 400;
  border-radius: 0.4em;
  transition: all 250ms ease;
  text-shadow: ${({ active }) => active ?'initial': '0px 1px 2px black'};
  background-color: ${({ active }) => active ?'#78bc28': 'transparent'};
  color: ${({ active }) => active ?'#000': '#FFF'};

  :hover {
    opacity: 0.7;
  }
`
