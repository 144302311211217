import React from 'react';
import _ from 'underscore';
import avatar from '../../assets/images/avatar-50.png';
import action from './actions';
import PresentationPicks from './PresentationPicks';
import PresentationPicksGolf from './PresentationPicksGolf'

const Pick = ({ selectedPlayer, player, dispatch, currentContest }) => {
  // console.log(player);
  return (
    <label
      key={player.id}
      className={`pick-category flex-row row-center home ${
        selectedPlayer && selectedPlayer.id === player.id ? 'selected' : ''
        }`}
      value={player.id}
      onClick={() => {
        action.handlePick(dispatch, player.bucket, player.id, currentContest.id);
      }}
    >
      <div className="pick-category flex-container align">
        <div className="category-container groupPool row-center flex-row">
          <div
            className={`team-data start flex-col ${
              selectedPlayer && selectedPlayer.id === player.id ? 'col-70' : 'col-95'
              } uppercase`}
          >
            <i className={`${player.logo} `}></i>
            <span className="overflow-words">{player.name}</span>
          </div>
          {selectedPlayer && selectedPlayer.id === player.id ? (
            <div className=" team-data ">
              <i className="flex-row ion-checkmark-round selected" />
            </div>
          ) : null}
        </div>
      </div>
    </label>
  );
};

const ButtonsPlayers = ({ disabled, myEntry, bucketId, players, dispatch, selectedPlayer, currentContest }) => {
  let playersList = players.map((player) => {
    return (
      <div key={player.id} className="flex-col col-50 pick-group-wrapper">
        <Pick
          selectedPlayer={selectedPlayer}
          player={player}
          bucketId={bucketId}
          dispatch={dispatch}
          disabled={disabled}
          isCorrect={false}
          currentContest={currentContest}
        />
      </div>
    );
  });

  return (
    <form className="1">
      <div className="flex-row row-no-padding row-wrap list-players">{playersList}</div>
    </form>
  );
};

/**
 * This component renders the list of picks and props
 * @param fixtures                { Object }
 * @param handlePick              { Function }
 * @param updateStartedFixture    { Function }
 * @param myEntry                 { Object }
 */
const ListBuckets = ({
  disabled,
  poolBuckets,
  dispatch,
  poolInfo,
  updateStartedFixture,
  myEntry,
  currentContest
}) => {
  if (!poolBuckets) {
    return null;
  }

  //Initialize buckets if is empty
  if (poolBuckets[currentContest.id].length === 0) {
    poolInfo.contests.map(contest => {
      for (var i=1; i <= poolInfo.num_picks; i++) {
        poolBuckets[contest.id].push({
          "id":         contest.id + '-' + i,
          "contest_id": contest.id,
          "number":     i,
          "players":    [],
          "pick":       null,
          "position":   0,
        });
      }
    });
  }

  let listBuckets = _.map(poolBuckets[currentContest.id], (bucket, bucketIndex) => {
    const selectedPlayer =
      myEntry.hasOwnProperty('group_pool_picks') && myEntry.group_pool_picks.hasOwnProperty(currentContest.id)
        ? bucket.players.find((o) => o.id === myEntry.group_pool_picks[currentContest.id][bucket.id].pick)
        : null;

    let titleButton = (
      <div
        className="btn-group-pick-collapse  align flex-container  "
        onClick={() => {
          action.handleBucket(dispatch, bucket.id - 1);
        }}
      >
        <div className="flex-col col-80"> Grupo #{bucketIndex + 1} </div>
        <div className="flex-col">
          {bucket.isOpen ? (
            <i className="flex-container end align ion-arrow-up-b"></i>
          ) : (
              <i className="flex-container end align ion-arrow-down-b"></i>
            )}
        </div>
      </div>
    );

    if (selectedPlayer) {
      titleButton = (
        <div
          className="btn-group-pick-collapse  align flex-container between "
          onClick={() => {
            action.handleBucket(dispatch, bucketIndex);
          }}
        >
          <div className="flex-col col-10">#{bucketIndex + 1}</div>
          <img alt={selectedPlayer.name}
            src={selectedPlayer.avatar === null || selectedPlayer.avatar === undefined ? avatar : selectedPlayer.avatar}
            className="player-avatar"
            style={{ width: '24px', height: '24px' }}
          />
          <i className={`${selectedPlayer.logo} `} />
          <div className="uppercase flex-col col-70 overflow-words">{selectedPlayer.name}</div>
        </div>
      );
    }

    return (
      <div key={bucketIndex} className="bucket-item">
        {titleButton}
        <div className="flex-col col-no-padding">
          {bucket.isOpen ? (
            <ButtonsPlayers
              selectedPlayer={selectedPlayer}
              disabled={disabled}
              myEntry={myEntry}
              bucketId={bucketIndex}
              players={bucket.players}
              dispatch={dispatch}
              currentContest={currentContest}
            />
          ) : null}
        </div>
      </div>
    );
  });
  return (
    <div className="flex-col col-no-padding">
      <div className="flex-row title-quiniela sub">
        <div className="flex-col title-wrapper">Picks</div>
      </div>
      {listBuckets}
    </div>
  );
};

/**
 * This component Renders the title and the list of picks
 */
const Picks = ({ disabled, poolInfo, poolBuckets, dispatch, myEntry, bucketKeys, currentContest }) => {

  // Golf presentation picks
  if (currentContest.status !== 'upcoming' && poolInfo.sport_id === 6) return (
    <PresentationPicksGolf
      poolInfo={poolInfo}
      bucketKeys={bucketKeys}
      poolBuckets={poolBuckets}
      myEntry={myEntry}
      dispatch={dispatch}
      currentContest={currentContest}
    />
  )

  return (
    <div>
      {currentContest.status !== 'upcoming' ? (
        <PresentationPicks
          poolInfo={poolInfo}
          bucketKeys={bucketKeys}
          poolBuckets={poolBuckets}
          myEntry={myEntry}
          dispatch={dispatch}
          currentContest={currentContest}
        />
      ) : (
          <React.Fragment>
            <ListBuckets
              bucketKeys={bucketKeys}
              disabled={disabled}
              poolInfo={poolInfo}
              poolBuckets={poolBuckets}
              myEntry={myEntry}
              dispatch={dispatch}
              currentContest={currentContest}
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default Picks;
