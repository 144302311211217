import React from 'react'

import moment from 'moment' 
import numeral from 'numeral'

import API from '../../../utils/api'
import styles from './index.module.sass'

import baseball from '../../../assets/images/BaseballBall-50.png'
import americano from '../../../assets/images/AmericanoBall-50.png'
import futbol from '../../../assets/images/FutbolBall-50.png'
import basketball from '../../../assets/images/BasketballBall-50.png'
import hockey from '../../../assets/images/Hockey.png'

const OldWagers = () => {
  const statuses = {
    0: 'TODAS',
    1: 'PENDIENTE',
    2: 'GANADA',
    3: 'PERDIDA',
    4: 'PUSH',
    5: 'CANCELADA'
  }

  const ranges = {
    all: 'TODOS',
    today: 'HOY',
    week: 'ÚLTIMA SEMANA',
    month: 'ÚLTIMO MES',
    semester: 'ÚLTIMO SEMESTRE'
  }

  const myGamesRef = React.useRef(null)

  const [myBets, setMyBets] = React.useState([])
  const [selectedStatus, setSelectedStatus] = React.useState('1')
  const [selectedRange, setSelectedRange] = React.useState('today')
  const [pager, setPager] = React.useState({
    page: 1,
    pages: 1,
    loading: true
  })

  const handleChangeDropdown = (e) => {
    const status = document.getElementById('status').value
    const range = document.getElementById('period').value
    setMyBets([])
    setSelectedStatus(status)
    setSelectedRange(range)
    setPager({ ...pager, page: 1, loading: true })
  }

  React.useEffect(() => {
    API.getUserWagers(pager.page, selectedStatus, selectedRange).then(response => {
      const { page, pages, wagers } = response.data
      const newBets = [...myBets, ...wagers]
      setMyBets(newBets)

      setPager({ page, pages, loading: false })
    })
  }, [selectedStatus, selectedRange, pager.page])

  React.useEffect(() => {
    const ref = myGamesRef.current

    // Scroll event of my games
    const handleScroll = ev => {
      if (
        myGamesRef.current.scrollTop >=
          myGamesRef.current.scrollHeight - myGamesRef.current.offsetHeight
      ) {
        if (pager.page < pager.pages) {
          setPager({ ...pager, page: pager.page + 1, loading: true })
        }
      }
    }

    ref.addEventListener('scroll', handleScroll)
    return () => {
      ref.removeEventListener('scroll', handleScroll)
    }
  }, [myGamesRef, pager])

  return (

    <div className='panel-pickwin' style={{ background: 'white', color: '#333' }}>
      <div class='flex-row'>
        <div class='flex-col'>
          Estas son tus apuestas pendientes, tus nuevas apuestas las podrás revisar en el sportsbook en la sección "MIS APUESTAS"
        </div>
      </div>
      <div className='flex-row row-center'>
        <div className='flex-col col-15'>
          Estado:
        </div>
        <div className='flex-col'>
          <select
            name='status'
            id='status'
            className='dropdownForm'
            style={{ marginBottom: '0px' }}
            onChange={handleChangeDropdown}
            defaultValue='1'
          >
            <option value='0'>Todo</option>
            <option value='1'>Pendientes</option>
            <option value='2'>Ganados</option>
            <option value='3'>Perdidos</option>
            <option value='4'>Push</option>
            <option value='5'>Cancelados</option>
          </select>
        </div>
        <div className='flex-col col-15'>
          Periodo:
        </div>
        <div className='flex-col'>
          <select
            name='period'
            id='period'
            className='dropdownForm'
            style={{ marginBottom: '0px' }}
            onChange={handleChangeDropdown}
            defaultValue='all'
          >
            <option value='all'>Todo</option>
            <option value='today'>Hoy</option>
            <option value='week'>Esta semana</option>
            <option value='month'>Este mes</option>
            <option value='semester'>Este semestre</option>
          </select>
        </div>
      </div>
      {!pager.loading && myBets.length === 0 && (
        <div className='flex-row row-center'>
          <div className='flex-col text-center'>
            No hay apuestas para mostrar
          </div>
        </div>
      )}

      <div className='my-games-content' ref={myGamesRef}>
        <div className='flex-row row-wrap'>
          {myBets.map((bet, i) => <WagerCard key={i} wager={bet} />)}
        </div>
        {pager.loading &&
          <div className='flex-row'>
            <div className='col text-center'>
              Cargando...
            </div>
          </div>}
      </div>
    </div>
  )
}

const WagerCard = ({ wager }) => {
  const [collapsed, setCollapsed] = React.useState(true)
  let classRisk = null
  let classWin = null
  let classBack = null
  let icon = ''

  React.useEffect(() => {

  }, [])

  const statuses = {
    1: 'Pendiente',
    2: 'Ganada',
    3: 'Perdida',
    4: 'Push',
    5: 'Cancelada',
    6: 'Ganada por ¼',
    7: 'Perdida por ¼',
    8: 'Ganada por ¾',
    9: 'Perdida por ¾',
    10: 'No Bet',
    11: 'NA If Bets',
    12: 'Cambio de Pitcher',
    13: 'NA Short',
    14: 'NA Void'
  }

  const shortStatuses = {
    1: '-',
    2: 'G',
    3: 'L',
    4: 'P',
    5: 'C',
    6: '¼',
    7: '¼',
    8: '¾',
    9: '¾',
    10: 'NB',
    11: 'NA',
    12: 'CP',
    13: 'NS',
    14: 'NV'
  }

  switch (wager.status) {
    // PENDING
    case 1:
      classWin = styles.blue
      classBack = styles.blueBack
      classRisk = styles.purple
      icon = 'ion-clock'
      break
      // WIN, WIN BY 1/14, WIN BY 3/4
    case 2:
    case 6:
    case 8:
      classWin = styles.green
      classBack = styles.greenBack
      classRisk = styles.black
      icon = 'ion-trophy'
      break
      // LOSS, LOSS BY 1/4, LOSS BY 3/4
    case 3:
    case 7:
    case 9:
      classWin = styles.black
      classBack = styles.blackBack
      classRisk = styles.red
      icon = 'ion-minus-circled'
      break
      // PUSH Y CANCELADAS
    case 4:
    case 5:
    default:
      classWin = styles.black
      classBack = styles.blackBack
      classRisk = styles.black
      icon = 'ion-android-time'
      break
  }

  const getStatus = (status) => {
    let background
    switch (status) {
      // PENDING
      case 1:
        background = styles.blueBack
        break
        // WIN, WIN BY 1/14, WIN BY 3/4
      case 2:
      case 6:
      case 8:
        background = styles.greenBack
        break
        // LOSS, LOSS BY 1/4, LOSS BY 3/4
      case 3:
      case 7:
      case 9:
        background = styles.redBack
        break
        // PUSH Y CANCELADAS
      case 4:
      case 5:
      default:
        background = styles.blackBack
        break
    }

    return background
  }

  function showBetDetail () {
    setCollapsed(!collapsed)
  }

  const getSportName = (key) => {
    const options = [{ name: '', icon: '' }]
    switch (key) {
      case 'SOC':
        options.icon = futbol
        options.name = 'Fútbol'
        break
      case 'NFL':
        options.icon = americano
        options.name = 'Fútbol Americano'
        break
      case 'NBA':
        options.icon = basketball
        options.name = 'Basketball'
        break
      case 'MLB':
        options.icon = baseball
        options.name = 'Baseball'
        break
      case 'NHL':
        options.icon = hockey
        options.name = 'Hockey'
        break
      default:
        break
    }

    return options
  }

  const key = (wager.info !== null && wager.info[0] !== undefined) && wager.info[0].IdSport || ''
  const sport = getSportName(key)
  const headerInfo = (
    <div>
      <div className={`${styles.itemTitle} ${styles.itemTitlePrincipal}`}>
        <div className={styles.itemIcons}>
          <div className='flex-row row-center row-no-padding'>
            <div className='flex-col col-75 overflow-words'>
              <img src={sport.icon} style={{ height: '13px', width: 'auto', marginRight: '5px', marginBottom: '3px' }} />
              <i className={`${icon} ${classWin} ${styles.icon}`} />
              {wager.short ? wager.short.header : ''}
            </div>
            <div className='flex-col col-25 col-no-padding text-right'>
              <span style={{ fontSize: 'xx-small' }}>{statuses[wager.status]}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.itemTitle} style={{ justifyContent: 'flex-start', padding: '0 5px' }}>
        <div className={classRisk}>
          <div>Arriesga: ${numeral(wager.amount / 100).format('0,0')} mxn</div>
        </div>
          &nbsp;&nbsp; - &nbsp;&nbsp;
        <div className={classWin}>
          <div>Gana: ${numeral(wager.win / 100).format('0,0')} mxn</div>
        </div>
      </div>
    </div>
  )

  if (wager.short !== null) {
    if (collapsed === false) {
      return (
        <div className={`${styles.boxContainer} col-50`}>
          <div className={styles.item} onClick={() => showBetDetail()}>
            {headerInfo}
            <div className={styles.betInfo}>
              <div className='flex-row row-center row-no-padding' style={{ marginBottom: '5px' }}>
                <div className='flex-col col-70 col-no-padding'>
                  <strong>BOLETO</strong> #{wager.entity_id}
                </div>
                <div className='flex-col text-right'>{moment(wager.created).format('DD MMM YYYY H:mm')}</div>
              </div>

              {wager.short.games.map((game, index) => {
                return (
                  <div key={index} className={styles.gameRow}>
                    <div className='flex-col col-no-padding col-70 overflow-words'>
                      {wager.live_fixtures.length > 0 && (
                        <a
                            className={`${styles.statusGame} ${styles.gameFollow}`} onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              e.nativeEvent.preventDefault()
                              e.nativeEvent.stopPropagation()
                            }}
                          >
                            <i className='icon ion-eye' />
                          </a>
                      )}
                      <span className={`${styles.statusGame} ${getStatus(game.result)}`}>{shortStatuses[game.result]}</span>
                      {game.game}
                    </div>
                    <div className='flex-col col-no-padding text-right'>
                      {moment(game.date).format('DD MMM YYYY H:mm')}
                    </div>
                  </div>
                )
              })}
            </div>

            <div className={styles.arrow}>
              <i className='ion-ios-arrow-up' />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`${styles.boxContainer} col-50`}>
          <div
            className={styles.item}
            onClick={() => showBetDetail()}
          >
            {headerInfo}
            <div className={styles.betInfo}>
              <div className={styles.gameRow}>
                <div className='overflow-words'>
                  <span className={`${styles.statusGame} ${getStatus(wager.short.games[0].result)}`}>{shortStatuses[wager.short.games[0].result]}</span>
                  {wager.short.games[0].game}
                </div>
              </div>

              <div className={styles.gameRow}>
                {(
                  wager.short.games[1] !== undefined && (
                    <div className='overflow-words'>
                      <span className={`${styles.statusGame} ${getStatus(wager.short.games[1].result)}`}>{shortStatuses[wager.short.games[1].result]}</span>
                      {wager.short.games[1].game}
                      <br />...
                    </div>
                  )
                ) || ''}
              </div>
            </div>

            <div className={styles.arrow}>
              <i className='ion-ios-arrow-down' />
            </div>
          </div>
        </div>
      )
    }
  } else {
    return ''
  }
}

export default OldWagers
