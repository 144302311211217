import React from 'react';

/**
 * This component creates the Title of the Main HEader inPicksGrupo
 * for spread of standard format
 */
const  TitleList = ( {format} ) => {
  let titleList = null;
  /*
  if(format === 'line'){
    titleList = [ 'Visitante', 'Local', 'Resultados', 'Pick Ganador' ];
    titleList = titleList.map( (listItem, index) => {
      return (<div key={index} className='upper-table-category'>{ listItem }</div>)
    } );
  }
  else {
    titleList = [ 'Visitante', 'Local', 'Línea', 'Resultados', 'Pick Ganador' ];
    titleList = titleList.map( (listItem, index) => {
      return (<div key={index} className='upper-table-category'>{ listItem }</div>)
    } );
  }
  */
 titleList = [ 'Semana' ];

 titleList = titleList.map( (listItem, index) => {
   return (<div key={index} className='upper-table-category'>{ listItem }</div>)
 } );

  // console.log(titleList);
  return <div>{titleList}</div>
}

/**
 * This component create the list of My Pick of every  week
 */
const WeekList = ( { survivorWeeks } ) => {

  let weekList = survivorWeeks.map( week => {
      return (<div key={week.id} className='upper-table-item fixture'> {week.name} </div>)
    })

  return <div>{weekList}</div>

}


class MainHeaderSurvivor extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
  }


  render(){

    return (
      <div className="row-list">
        <div className="title-container font-chivo">
          <TitleList format={this.props.format}/>
        </div>
        <div className="picksData sync-horizontal font-chivo">
          <div className="data-row">
          { this.props.survivorWeeks.map( week => {
              return (<div key={week.id} className='upper-table-item fixture'> { week.week_name } </div>)
            })
          }
          </div>
        </div>
        <div className="total-container" style={{width: '7%'}}>
          <div className="upper-table-item total" />
        </div>
      </div>
    );
  }
}

export default  MainHeaderSurvivor;
