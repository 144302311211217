import React from 'react'
import styled, { keyframes } from 'styled-components'
import ReactDOM from 'react-dom'
import _ from 'underscore'

// Components
import Iconify from '../../../Iconify'


export default function ModalDetails (props) {
  const { current, onClose } = props 

  const stop = (e) => {
    e.stopPropagation()
  }

  const redirect = () => {
    window.location.href = current.fullData.redirect.desktop
  }

  if (!current) return null

  const content = (
    <Container onClick={onClose}>
        <Box onClick={stop}>
          <Bar>
            {current.fullData.title}
            <button onClick={onClose}>
              <Iconify
                icon='material-symbols:close'
                style={{ fontSize: 20 }}
              />
            </button>
          </Bar>

          <Body>
            <Col>
              <Image
                onClick={redirect}
                alt={current.fullData.name}
                src={current.fullData.imgDesktop}
              />
            </Col>
            <Information>
              <Title>{current.fullData.name}</Title>
              <Divider />
              <RichText dangerouslySetInnerHTML={{ __html: current.fullData.description }} />
            </Information>
          </Body>
        </Box>
    </Container>
  )

  return ReactDOM.createPortal(content, document.querySelector('body'))
}


const Image = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
`

const RichText = styled.div`
  font-size: 12px;
`

const Divider = styled.div`
  margin: 0.2em 0;
  width: 100%;
  border-bottom: 1px dashed #484848;
`

const Col = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
`

const Information = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
  height: 100%;
`

const show = keyframes`
  0% {
    transform: scale(0.60);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

const showShadow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: opacity 1000ms ease;
  background-color: rgba(0,0,0,0.65);
  backdrop-filter: blur(5px) grayscale(1);
  animation: ${showShadow} 550ms ease forwards;
`

const Title = styled.div`
  font-family: 'Chivo';
  font-weight: 400;
  font-size: 1.4em;
  margin: 0.5em 0;
  text-shadow: 1px 1px 3px #00000099;
`

const Box = styled.div`
  width: 100%;
  max-width: 640px;
  height: 80vh;
  margin: 1em;
  animation: ${show} 330ms ease-out forwards;
  will-change: transform, opacity;
  background: #000000;
  border-radius: 0.8em;
  border: 1px solid #383838;
  overflow: hidden;
`

const Bar = styled.div`
  width: 100%;
  height: 30px;
  background-color: lime;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  text-shadow: 0 1px 1px #00000085;
  background: linear-gradient(181deg, #87c342, #518b0f);

  button {
    background: transparent;
    border: 0;
    outline: 0;
    right: 1em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    :hover {
      opacity: 0.5;
    }
  }
`

const Body = styled.div`
  gap: 1em;
  padding: 0 1em;
  margin: 0 auto;
  overflow-y: auto;
  height: 80vh;
`
