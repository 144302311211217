import styled from 'styled-components'
import React from 'react'
import { DropdownButton as DropdownButtonBase } from 'react-bootstrap/lib'

// Components
import Iconify from '../Iconify/Iconify'


const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;

  & svg {
    font-size: 0.8em;
  }
`

const DropdownButton = (props) => {
  const Title = (
    <TitleBox>
      <Iconify icon='material-symbols:filter-alt' />
      <span>{props.title}</span>
    </TitleBox>
  )

  return (
    <DropdownButtonBase {...props} title={Title} />
  )
}

export default DropdownButton
