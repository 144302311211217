import React, { useEffect, useContext } from 'react';
import API from '../../utils/api';
import { Button, Modal } from 'react-bootstrap/lib';
import $ from 'jquery';

import FormularioNotifications from './FormularioNotifications';

import {
  NotificationControlsContext,
} from '../../context/NotificationControlsContext';

const mockSports = [
  {
    id: 1,
    name: 'FÚTBOL',
    tournaments: [
      { id: 1, name: 'LIGA MX' },
      { id: 2, name: 'LA LIGA' },
      { id: 10, name: 'PREMIER LEAGUE' },
      { id: 11, name: 'UEFA CL' },
      { id: 16, name: 'EUROCOPA' },
      { id: 19, name: 'COPA MX' },
      { id: 20, name: 'BUNDESLIGA' },
      { id: 26, name: 'SERIE A' },
      { id: 32, name: 'EUROPA LEAGUE' },
    ],
  },
  {
    id: 2,
    name: 'FÚTBOL AMERICANO',
    tournaments: [
      { id: 9, name: 'NFL' },
      { name: 'NCAAF', id: 31 },
    ],
  },
  {
    id: 3,
    name: 'BASKETBALL',
    tournaments: [{ id: 13, name: 'NBA' }],
  },
  {
    id: 4,
    name: 'BASEBALL',
    tournaments: [{ name: 'MLB', id: 15 }],
  },
  {
    id: 9,
    name: 'HOCKEY',
    tournaments: [{ name: 'NHL', id: 28 }],
  },
];

/**
 * This components show if an error ocurred in the process of handling the Notifications
 * @param { Function } handleError
 * @param { Boolean } show
 * @param { Object }  error
 * @reutrn Component
 */
const Error = ({ handleError, show, error }) => {
  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={show} keyboard={false}>
      <div className="panel-abonar">
        <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo failed '}`}>
          <span>{Error}</span>
          <button className="cerrar-panel" onClick={handleError}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-alert-circled alert" />
              {error.message}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SuccessfullChanges = ({ handleSuccess, show }) => {
  // const notePosition =
  //   $(window).width() < 769 ? $(window).scrollTop() - 300 : $(window).scrollTop();

  return (
    <Modal bsSize="sm" dialogClassName="confirmationPanelPopUp" show={show} keyboard={false}>
      <div className="panel-abonar">
        <div id="panelTitle" className={`flex-row row-no-padding panel-abonar-titulo '}`}>
          <span>Se guardaron con éxito</span>
          <button className="cerrar-panel" onClick={handleSuccess}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row pickwin-panel">
          <div className="flex-col col-80 col-offset-10">
            <div className="flex-container justify align column text-center">
              <i className="ion-checkmark-circled success" />
              ¡Tus notificaciones se guardaron correctamente!
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * This is the Main container of the NotificationControls
 * It must pair the state and reducers of the Context
 * with the current component by hooks and custom effects
 *
 */
const NotificationControls = () => {
  let { state, dispatch } = useContext(NotificationControlsContext);

  // console.log('NotificationControls State', state);

  /**
   * This custom effect handles the notifications and updates them
   * @param { boolean } serverUpdated
   * @return { Array } updatedNotifications
   */
  const useUpdatedNotifications = (serverUpdated) => {
    // const unique = (arr) => {
    //   var hash = {},
    //     result = [];
    //   for (var i = 0, l = arr.length; i < l; ++i) {
    //     if (!hash.hasOwnProperty(arr[i])) {
    //       //it works with objects! in FF, at least
    //       hash[arr[i]] = true;
    //       result.push(arr[i]);
    //     }
    //   }
    //   return result;
    // };

    useEffect(() => {
      // console.log( 'Use UpdatedNotifications' );
      API.fetchNotificationOptions()
        .then((responseNotifications) => {
          console.log(responseNotifications);
          if (responseNotifications.data) {
            if (responseNotifications.data.preferences) {
              console.log('Preferences  exist ');
              console.log('Saved preferences');
              updateNotifications(responseNotifications.data.preferences);
              updateSportsList(mockSports);
              isReady(true);
            } else {
              console.log('New preferences');
              updateNotifications(responseNotifications.data.preferences);
              initializeTournaments(mockSports);
              isReady(true);
            }
            return responseNotifications.data.preferences;
          } else {
            console.error('Error');
            isError(true, 'Ocurrio un problema.');
            return responseNotifications.data.preferences;
          }
        })
        .catch((error) => {
          console.error(error);
          isError(true, 'Ocurrio un error.');
          return null;
          // Sentry.captureException(error);
        });
    }, []);
  };

  /*******************
   * Reducer Handlers
   ******************/
  const submitting = (value) => {
    console.log('submitting', value);
    dispatch({ type: 'changeSubmit', payload: value });
  };
  const initializeTournaments = (sports, sportId) => {
    console.log('initializeTournaments', sports);
    dispatch({ type: 'initializeTournaments', payload: sports });
  };
  const isReady = (value) => {
    dispatch({ type: 'isReady', payload: value });
  };
  // const isSuccessfull = () => {
  //   dispatch({ type: 'isSuccessfull' });
  // };
  const isError = (value, msg) => {
    dispatch({ type: 'isError', payload: { value: value, message: msg } });
  };
  const updateData = (name) => {
    console.log('Update Data', name);
    dispatch({ type: 'updateControlsData', payload: { name: name } });
  };
  const updateSwitchData = (name, category) => {
    console.log('Update Switch Data', name);
    dispatch({ type: 'updateSwitchData', payload: { name: name, category: category } });
  };
  const updateOptionsStatus = (status, gameType) => {
    console.log('Update OptionsStatus', status, gameType);
    dispatch({ type: 'updateOptionStatus', payload: { gameType: gameType, status: status } });
  };

  const updateGameNotifications = (value) => {
    console.log(value);
    dispatch({ type: 'updateGameNotifications', payload: { value: value } });
  };
  const updateEntryNotifications = (tournamentId, sportId, value) => {
    console.log(sportId, tournamentId, value);
    dispatch({
      type: 'updateEntryNotifications',
      payload: { sportId: sportId, tournamentId: tournamentId, amountType: value },
    });
  };
  const updateSportsNotifications = (value) => {
    console.log('updatesports', value);
    dispatch({ type: 'updateSportsNotifications', payload: { value: value } });
  };
  const updateTournamentsNotifications = (tournamentId, sportId) => {
    console.log('updateTournmanets', tournamentId, sportId);
    dispatch({
      type: 'updateTournamentsNotifications',
      payload: { tournamentId: tournamentId, sportId: sportId },
    });
  };
  const updateGameTypeNotifications = (name, value) => {
    console.log('updates type', name, value);
    dispatch({ type: 'updateGameTypeNotifications', payload: { name: name, value: value } });
  };
  const updateStatusNotifications = (gameType, value) => {
    console.log('updates status', value);
    dispatch({ type: 'updateTypeNotifications', payload: { gameType: gameType, value: value } });
  };
  // const updateEntry = (gameType, value) => {
  //   console.log('updates type', value);
  //   dispatch({ type: 'updateTypeNotifications', payload: { gameType: gameType, value: value } });
  // };
  const handleErrorWindow = (name) => {
    console.log('Handle Error Window', name);
    dispatch({ type: 'handleErrorWindow' });
  };
  const handleSuccessfullChanges = (name) => {
    console.log('Handle Successfull Window', name);
    dispatch({ type: 'handleSuccessfullChanges' });
  };
  const updateNotifications = (notifications) => {
    dispatch({ type: 'updateNotifications', payload: notifications });
  };
  const updateSportsList = (sportsList) => {
    dispatch({ type: 'updateSportsList', payload: sportsList });
  };
  const updateHandleAllAmoutPerSport = (sportId, amountType) => {
    console.log('updateHandleAllAmoutPerSport');
    dispatch({ type: 'updateHandleAllAmoutPerSport', payload: { sportId, amountType } });
  };

  const submitData = () => {
    submitting(true);
    // console.log( state.controlsData );
    console.log('%c Submit Data', 'color:#ffc730; font-size:2em;');
    console.log(state.notificationsAvailable);
    API.postNotificationControls(state.notificationsAvailable)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          handleSuccessfullChanges();
        } else {
          isError(true, response.error.message);
        }
      })
      .catch((error) => {
        isError(true, 'Ocurrio un error al guardar tus datos.');
      });
  };

  useUpdatedNotifications();

  // console.log(state);
  return (
    <div className="notifications-controls flex-col">
      <div className="flex-row titulo-user-info">
        <div className="">
          <i className="title-icon ion-android-settings pool-icons" />
        </div>
        <div className="flex-col">
          <span>Centro de Notificaciones</span>
        </div>
      </div>
      <Error handleError={handleErrorWindow} show={state.showError} error={state.error} />
      <SuccessfullChanges
        handleSuccess={handleSuccessfullChanges}
        show={state.successfullChanges}
      />
      <form className="flex-col mb-3">
        <div className="flex-row">
          <FormularioNotifications
            controlsData={state.controlsData}
            initializeTournaments={initializeTournaments}
            notifications={state.notificationsAvailable}
            sportsList={state.sportsList}
            updateData={updateData}
            updateHandleAllAmoutPerSport={updateHandleAllAmoutPerSport}
            updateEntryNotifications={updateEntryNotifications}
            updateGameNotifications={updateGameNotifications}
            updateGameTypeNotifications={updateGameTypeNotifications}
            updateOptionsStatus={updateOptionsStatus}
            updateSportsList={updateSportsList}
            isReady={isReady}
            mounted={state.mounted}
            updateSportsNotifications={updateSportsNotifications}
            updateStatusNotifications={updateStatusNotifications}
            updateSwitchData={updateSwitchData}
            updateTournamentsNotifications={updateTournamentsNotifications}
          />
        </div>
        <div className="flex-row">
          <Button
            onClick={() => {
              submitData();
            }}
            disabled={state.submitting || !state.mounted}
            className="btn btn-pick callToAction flex-col "
          >
            {state.submitting ? (
              <i className="ion-load-a loading small" />
            ) : (
                <div>Guardar cambios</div>
              )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NotificationControls;
