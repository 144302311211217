import React from 'react';

/**
 * Set Headers
 * @param props, type ,status , format
 * @returns {Component}
 * @constructor
 */
const SetQuinielaHeaders = (props) => {
  // console.log(props);
  let conditionalComponent;
  if (props.poolInfo.pool === undefined) {
    /**
     * This ConditionWorks with QuinielaLobby
     * **/
    let format = props.poolInfo.point_format;
    let status = props.poolInfo.status;
    //let type = props.poolInfo.type;
    //let dias = props.poolInfo.days_until_close;
    let hasTie = props.poolInfo.sport.allow_ties;
    let closed = true;

    if (format === 'line') {
      if (status !== closed) {
        conditionalComponent = (
          <ul className="header-picks-partidos flex-row row-no-padding  results">
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    linea">Línea</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    cierre">Cierre</li>
          </ul>
        );
      } else {
        conditionalComponent = (
          <ul className="header-picks-partidos flex-row row-no-padding results">
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    linea">Línea</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    resultado">Resultado</li>
          </ul>
        );
      }
    } else if (format === 'natural') {
      if (status !== closed) {
        conditionalComponent = (
          <ul className={`header-picks-partidos flex-row results row-no-padding`}>
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    empate">{hasTie ? 'Empate' : ''}</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    resultado">Cierre</li>
          </ul>
        );
      } else {
        conditionalComponent = (
          <ul className="header-picks-partidos flex-row  row-no-padding results">
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    empate">{hasTie ? 'Empate' : ''}</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    resultado">Resultado</li>
          </ul>
        );
      }
    }
  } else {
    let format = props.poolInfo.pool.point_format;
    let status = props.poolInfo.pool.status;
    let type = props.poolInfo.pool.type;
    let dias = props.poolInfo.pool.days_until_close;
    let hasTie = props.poolInfo.pool.sport.allow_ties;
    let closed = true;
    if (format === 'line') {
      if (status !== closed) {
        conditionalComponent = (
          <ul className="header-picks-partidos flex-row row-no-padding results">
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    linea">Línea</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    cierre">Cierre</li>
          </ul>
        );
      } else {
        // console.log("%c HERE", "font-size:36px;");
        conditionalComponent = (
          <ul className="header-picks-partidos flex-row row-no-padding results">
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    linea">Línea</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    resultado">Resultado</li>
          </ul>
        );
      }
    } else if (format === 'natural') {
      if (status !== closed) {
        conditionalComponent = (
          <ul className={`header-picks-partidos flex-row row-no-padding results`}>
            {/*<li className="pick-category juegos">Juego</li>*/}
            <li className="pick-category    local">Local</li>
            <li className="pick-category    empate">{hasTie ? 'Empate' : ''}</li>
            <li className="pick-category    visitante">Visitante</li>
            <li className="pick-category    resultado">Cierre</li>
          </ul>
        );
      } else {
        if (dias === -1) {
          if (type === 'full') {
            conditionalComponent = (
              <ul className="header-picks-partidos flex-row row-no-padding results">
                {/*<li className="pick-category juegos">Juego</li>*/}
                <li className="pick-category    local">Local</li>
                <li className="pick-category    empate">{hasTie ? 'Empate' : ''}</li>
                <li className="pick-category    visitante">Visitante</li>
                <li className="pick-category    resultado">Resultado</li>
              </ul>
            );
          } else {
            // console.log('HERE', type);
            conditionalComponent = (
              <ul className="header-picks-partidos flex-row row-no-padding results">
                {/*<li className="pick-category juegos">Juego</li>*/}
                <li className="pick-category    local">Local</li>
                <li className="pick-category    empate">{hasTie ? 'Empate' : ''}</li>
                <li className="pick-category    visitante">Visitante</li>
                <li className="pick-category    resultado">Resultado</li>
              </ul>
            );
          }
        } else {
          conditionalComponent = (
            <ul className="header-picks-partidos flex-row row-no-padding results">
              {/*<li className="pick-category juegos">Juego</li>*/}
              <li className="pick-category    local">Local</li>
              <li className="pick-category    empate">{hasTie ? 'Empate' : ''}</li>
              <li className="pick-category    visitante">Visitante</li>
              <li className="pick-category    resultado">Cierre</li>
            </ul>
          );
        }
      }
    }
  }
  // console.log(this)
  return conditionalComponent;
};

export default SetQuinielaHeaders;
