import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@tanstack/react-query'


// Utils
import API from '../../../../../../utils/api'
import { getCurrencyFormatSmall } from '../../../../../../utils/currencyFormat'
import CurrencyText from '../components/CurrencyCountUpText'

export default function Resume () {
  const vipReferralRewards = useQuery(['vip_referral_rewards'], API.getVipReferralRewards)

  const { total } = vipReferralRewards?.data?.data?.totals || {}

  if (vipReferralRewards.sdf) return null

  return (
    <Box>
      <Item>
        <ButtonGradient>
          <ButtonContent>Total ganancias</ButtonContent>
        </ButtonGradient>
        <CurrencyText end={total}></CurrencyText>
      </Item>
    </Box>
  )
}

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonGradient = styled.div`
  background: #F9E799;
  background: linear-gradient(180deg, rgb(247, 228, 152), rgb(207, 180, 115), rgb(250, 234, 169), rgb(119 89 37));
  padding: 0.2em;
  border-radius: 0.5em;
  width: 170px;
`

const ButtonContent = styled.div`
  background: #000000;
  color: #FFF; 
  padding: 0.5em;
  border-radius: 0.5em;
  text-align: center;
`

const Box = styled.div`
  padding: 1em;
  padding-bottom: 0;
  display: flex;
  justify-content: space-around;
  border-top: 2px solid #575656;
  margin-top: 1em;
  padding-top: 1em;
`

const Title = styled.h3`
  background: #F9E799;
  background: linear-gradient(to bottom, #F9E799 41%, #C8AE72 50%, #F2E3A3 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin: 0.4em 0;
  font-size: 2.4em;
`

