import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap/lib/'

// Components
import LegacyBrowserNotification from './LegacyBrowserNotification'
import DrawNavBar from './DrawNavBar'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'
import useMergedState from '../../hooks/useMergedState'

// Utils
import utils from '../../utils/utils'

function NavigationBar (props) {
  const authContext = useAuthContext()
  const [state, setState] = useMergedState({
    logged: utils.isLogged(),
    mounted: false,
    usrData: utils.getUserInfo(),
    userLoyalty: utils.getUserLoyalty(),
    showNote: true,
    showLoggingOut: false
  })

  useEffect(() => {
    setState({
      mounted: true,
      logged: props.logged,
      isSupported: utils.supportEs6(),
      userBalance: props.userBalance,
      usrData: props.userData
    })
  }, [])

  const showLoggingOut = () => {
    setState({ showLoggingOut: true })
  }

  const closeNote = () => {
    setState({ showNote: false })
  }

  /**
   * This funciton deletes the data from the local storage
   * and logout the user
   */
  const logout = () => {
    authContext.logout()
    showLoggingOut()
  }

  if (!state.mounted) {
    return (
      <div className='flex-container justify align'>
        <i className='ion-load-a loading' />
      </div>
    )
  }

  return (
    <>
      <Modal hide={null} show={state.showLoggingOut}>
        <div
          className='pickwin-panel flex-container column justify align'
          style={{ background: '#000' }}
        >
          <i className='ion-load-a loading' />
          <strong>Regresa pronto a Pickwin ...</strong>
        </div>
      </Modal>
      <DrawNavBar
        userData={props.userData}
        userLoyalty={state.userLoyalty}
        userBalance={props.userBalance}
        logged={props.isLogged}
        logout={logout}
        updateUserInfo={props.updateUserInfo}
        updateUserBalance={props.updateUserBalance}
      />
      <LegacyBrowserNotification
        isSupported={state.isSupported}
        closeNote={closeNote}
        showNote={state.showNote}
      />
    </>
  )
}

export default NavigationBar
