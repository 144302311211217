import { useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'

useCountUp.propTypes = {
  // Start number
  start: propTypes.number,
  // End or target number
  end: propTypes.number,
  // Duration in seconds to complete the animation
  duration: propTypes.number,
  // Frames or calcules per second
  fps: propTypes.number,
  // Delay time in seconds to start
  delay: propTypes.number
}

useCountUp.defaultProps = {
  duration: 1.8,
  fps: 60
}

export default function useCountUp (props) {
  const [num, setNum] = useState(props.start)
  const tick = useRef()

  useEffect(() => {
    if (props.delay) {
      window.setTimeout(play, props.delay * 1000)
    } else {
      play()
    }
  }, [props.end])

  function play () {
    const realFPS = 1000 / props.fps || 60
    const stepsCount = props.duration * 1000 / realFPS
    const step = (props.end - props.start) / stepsCount

    // Cleanup interval at start
    window.clearInterval(tick.current)
    tick.current = null

    tick.current = window.setInterval(() => {
      setNum(prev => {
        const next = Math.round(prev + step)

        if (next >= props.end) {
          window.clearInterval(tick.current)
          tick.current = null
        }

        return next <= props.end ? next : props.end
      })
    }, realFPS)
  }

  return num
}
