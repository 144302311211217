//Libraries
import React from 'react';

//Components
import PageWrapper from '../PageWrapper';

const ResponsibleGaming = () => {
  return (
    <PageWrapper isLegals>
      <div className="flex-row responsive-md">
        <div className="flex-col col-80 col-offset-10 responsive-sm legal-section lateralPadding">
          <h1 style={{ fontSize: '130%' }}>
            <u>JUEGO RESPONSABLE</u>
          </h1>
          <p className='mt-2'>En www.pickwin.mx (el “Sitio”) tenemos como objetivo ser un operador fiable, equitativo, seguro y transparente, mantenemos nuestro compromiso con el juego responsable, y reconocemos que el juego puede causar problemas a una minoría de jugadores. Con este motivo, ofrecemos a los jugadores una amplia gama de herramientas, avisos y medidas que les permitan jugar en nuestro casino de manera segura y controlable.</p>

          <h4 className='mt-2'>Protección de menores.</h4>
          <p className='mt-2'>En el Sitio protegemos a los menores, es decir a todas las personas que no cuenten con al menos 18 años, razón por la cual llevamos estrictos procedimientos de seguridad y se reserva el derecho de solicitar documentos en cualquier momento para comprobar la identidad y edad de sus usuarios y así evitar que ningún menor tenga acceso al Sitio, bloquear a jugadores restringidos y confirmar la identidad de todos aquellos que reciban pagos. Si conoces a algún menor de 18 años que esté registrado con nosotros, ponte en contacto con nosotros inmediatamente. Te recomendamos que limites la cantidad de tiempo que los menores a tu cargo pasen en Internet e instales un programa de control parental.</p>
          <p>Además de lo anterior, garantizamos que toda la actividad publicitaria, promocional y de patrocinio no sea dirigida a los menores de 18 años.</p>
          <ol>
            <li>En caso de que comparta dispositivos con menores, como otra medida de protección, sugerimos las siguientes precauciones:</li>
            <li>Use software de protección infantil para bloquear sitios de juegos de azar remotos.</li>
            <li>No desatienda la computadora cuando inicie sesión en nuestro sitio.</li>
            <li>No comparta detalles de la cuenta bancaria con menores.</li>
            <li>No habilite “Guardar contraseña” en la pantalla de inicio de sesión de nuestro sitio.</li>
            <li>Cree un perfil en su computadora exclusiva para el uso de menores.</li>
            <li>
              Utilice alguna aplicación de “Control Parental”, nosotros le recomendamos los siguientes:
              <ul>
                <li>www.netnanny.com</li>
                <li>www.cybersitter.com</li>
              </ul>
            </li>
          </ol>

          <h4 className='mt-2'>Prevención y protección del jugador.</h4>
          <p className="mt-2">Cuando utilices nuestro Sitio, es fundamental que goces de un estado emocional y psicológico equilibrado que te permita pensar y reaccionar con claridad y de manera razonable. Si has consumido alcohol, drogas o cualquier otra sustancia que pueda mermar tus capacidades mentales, no deberías participar usar nuestro Sitio.</p>
          <p>También algunas presiones externas también pueden ser consideradas influencias perjudiciales porque modifican el fin lúdico del juego. Por ejemplo, si se juega para obtener un cierto estatus social o la integración en un grupo social. Estas presiones son innecesarias y pueden ser perjudiciales a corto, medio y/o largo plazo.</p>
          <p>El juego debe ser una elección personal cuyo único objetivo sea la diversión. En ningún caso, el juego debe ser una obligación o una compulsión como resultado de la influencia de otra persona o motivo. El tiempo o el dinero que inviertas en el juego no debe primar o interferir con tus compromisos familiares, profesionales o sociales; así como ni afectar a tu trabajo, estudios, familia o responsabilidades parentales o perjudicar tu salud o interferir en otras actividades que son cruciales para tu bienestar (deportes, una dieta sana, descanso). Debes saber definir tus prioridades y mantener un equilibrio adecuado entre un buen estado físico y emocional y en las relaciones con tus allegados.</p>

          <h4 className='mt-2'>Autoexclusión.</h4>
          <p className='mt-2'>Es necesario que controles el tiempo que pasa en nuestro. Si consideras que pasas tiempo en exceso en nuestro sitio, o bien, si te consideras dependiente al juego con apuestas. Ponte en contacto con nosotros para recibir asesoramiento y apoyo.</p>
          <p>Para ayudarte a evaluar tu comportamiento como jugador, puedes hacerte las siguientes preguntas:</p>
          <ol>
            <li>¿Dejaste de lado tus estudios o trabajo por el juego?</li>
            <li>¿Está afectando el juego a tus relaciones personales (familia, amigos)?</li>
            <li>¿Financiar el juego se convirtió en una prioridad antes que financiar tus responsabilidades familiares?</li>
            <li>¿Está afectando el juego a tu salud (insomnio, abandono, aislamiento, depresión, estrés, comportamientos extraños)?</li>
            <li>¿Te sientes mal o te rechazas después de jugar o necesitas volver a jugar lo antes posible para poder ganar más dinero o recuperar las pérdidas?</li>
            <li>¿Has jugado para recuperarte de las deudas o para solventar algún déficit financiero?</li>
            <li>¿Jugaste por encima de los límites de tiempo o dinero establecidos por ti?</li>
            <li>¿Tomaste prestado o vendido algo para financiar tu actividad de juego?</li>
            <li>¿Mentiste a tus amigos y familia sobre tus hábitos de juego?</li>
            <li>¿Consideraste cometer algún acto ilegal para financiar el juego?</li>
          </ol>
          <p>Si respondiste "Sí" a alguna de estas preguntas, te recomendamos que te pongas en contacto con atención al cliente o con alguna de las organizaciones siguientes:</p>
          <p>
            Ludopatía:
            www.ludopatia.org/web/index_es.htm
            <br/>
            Jugadores Anónimos:
            www.jugadoresanonimos.org/
            <br/>
            Support for families:
            www.gam-anon.com/
          </p>

          <h4 className='mt-2'>Información Adicional.</h4>
          <p className='mt-2'>Creemos que el juego siempre debe ser una actividad de ocio que pueda disfrutar. Recuerde estos sencillos consejos para asegurarse de que el juego no se convierte en un problema.</p>
          <ol>
            <li>Apostar debe ser un entretenimiento y no una forma de ganar dinero.</li>
            <li>No apueste tratando de recuperar las pérdidas.</li>
            <li>Sólo apueste el dinero que puede perder.</li>
            <li>Controle el tiempo que invierte jugando.</li>
            <li>Combine el juego con otras actividades. Si el juego es su única forma de entretenimiento, considere si aún está divirtiéndose.</li>
            <li>Tome descansos de jugar en línea de manera frecuente. El juego continuado puede hacerle perder el control sobre el tiempo, dinero invertido y sus consecuencias.</li>
            <li>No apueste bajo los efectos del alcohol, depresión o enojo.</li>
            <li>Revise de manera periódica cuánto dinero ha gastado en el juego. Puede ver su actividad en línea en el historial de su cuenta.</li>
          </ol>
          <p>Ofrecemos cobertura de chat 24/7 para asistirle con cualquier solicitud, si no puedes controlar tu juego o seguir las indicaciones previamente brindadas; o si sientes que el juego en línea puede estar causando dificultades en lugar de ser entretenido, tenemos diferentes alternativas para ayudarte a administrar tú tiempo de juego.</p>
        </div>
      </div>
    </PageWrapper>
  );
}

export default ResponsibleGaming;