import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';

import _ from 'underscore';
import onClickOutside from 'react-onclickoutside';

import { List, AutoSizer, InfiniteLoader, createTableMultiSort } from 'react-virtualized';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap/lib';
import moment from 'moment';
import api from '../../utils/api';

import { Redirect } from 'react-router-dom';

import { CSSTransitionGroup } from 'react-transition-group';

import avatar from '../../assets/images/avatar-50.png';

import NotificationList from './NotificationList';
import * as Sentry from '@sentry/browser';

class NotificationCenterPopover extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      status: {
        read: 0,
        unread: 0,
      },
      section: 'myNotifications',
      showNotificatonControls: false,
      notifications: [],
      submitting: false,
      activeDropdown: false,
      error: false,
      redirectTo: null,
      submitting: false,
      successFriendResolve: false,
      queryParams: { page: 1, limit: 10, offset: 0 },
      updateList: false,
    };

    this.handleNotificationControls = () => {
      console.log('handleNotificationsControls');
      this.setState({ showNotificatonControls: !this.state.showNotificatonControls });
    };
    /**
     * This the show prop of the dropdown
     */
    this.handleDropdownOver = (e) => {
      console.log('over');
      this.setState({ activeDropdown: !this.state.activeDropdown });
    };
    this.handleDropdownUserClick = () => {
      console.log('click');
      if (window.innerWidth > 669) {
        window.location = '/usuario/amigos';
      }
    };
    this.handleDropdownUserOnBlur = () => {
      console.log('ONBlur');
      this.setState({ activeDropdown: false });
    };

    this.handleDropdownUserOut = (e) => {
      // console.log(e);
      console.log('User Out');

      this.setState((prevState) => {
        return {
          activeDropdown: !prevState.activeDropdown,
        };
      });
    };
    this.handleUserMouseLeave = () => {
      console.log('ON Leave');
      this.setState({ activeDropdown: false });
    };

    this.callbackArchive = (response, notification) => {
      let temporalNotifications = this.state.notifications;
      console.log(temporalNotifications);
      console.log(notification);
      if (notification === 'all') {
        temporalNotifications.data = [];
        temporalNotifications.total = 0;
      } else {
        temporalNotifications.data = temporalNotifications.data.filter((n) => {
          if (n.id === notification.id) {
            console.log('this', n);
          }
          if (n.id !== notification.id) {
            console.log(n.id, notification.id);
            return n;
          }
        });

        if (temporalNotifications.total !== 0) {
          temporalNotifications.total -= 1;
        }

        console.log(temporalNotifications);
      }

      this.setState({ notifications: temporalNotifications, updateList: true }, () => {
        this.setState({ updateList: false, submitting: false });
      });
    };

    /**
     * This mark as viewd a notification
     * @param notification { Object }
     * @return
     */
    this.markNotificationViewed = (notification) => {
      console.log('mark As read');
      console.log(notification);
    };
    /**
     * this method archive a notification given an id
     * @param currentNotification { Object } Object of the notification;
     */
    this.handleArchive = (currentNotification) => {
      this.setState({ submitting: true });
      console.log('.-------- Handle Archive CurrentNotification --------.');
      console.log(currentNotification);

      api
        .archiveNotification(
          currentNotification.hasOwnProperty('id') ? currentNotification.id : 'all'
        )
        .then((response) => {
          if (response.hasOwnProperty('message') && response.message === 'ok') {
            this.markNotificationAsRead({
              id: currentNotification.hasOwnProperty('id') ? currentNotification.id : 'all',
            });

            // console.log('Notifications Length', this.state.notifications.data.length)
            this.callbackArchive(response, currentNotification);
          } else {
            throw ('Error archiving Notification', currentNotification);
          }
        })
        .catch((e) => {
          console.error(e);
          Sentry.captureException(e);
        });
    };

    /**
     * Handles the click into a notification
     * @param id { Number }
     * @param viewed { Boolean }
     * @param action { object }
     * @param type { String }
     *
     * */
    this.handleNotificationClick = (id, viewed, action, type) => {
      console.log(id, viewed, action, type);
      this.setState({ submitting: true });

      console.log({ id, viewed, href: action.href });
      switch (type) {
        case 'friend_request':
          this.resolveFriendRequest({ id, viewed, href: action.href });
          break;
        case 'pool_request':
          this.poolRequest({ id, viewed, href: action.href });
          break;
      }
    };

    this.handleDropdownUserOver = (e) => {
      console.log('over USer');
      this.setState({ activeDropdown: !this.state.activeDropdown });
    };

    this.handleClickOutside = () => {
      this.setState({
        activeDropdown: false,
      });
    };

    /**
     * This function sends a post to resolve te  friend request
     * @param id { Number }
     * @param viewed {boolean }
     * @param href { String }
     *
     */
    this.resolveFriendRequest = ({ id, viewed, href }) => {
      api
        .socialLinkAction({ method: 'POST', href })
        .then((response) => {
          console.log(response);
          if (response.message === 'ok') {
            let temporalNotifications = this.state.notifications;
            temporalNotifications.data = temporalNotifications.data.map((n) =>
              n.id === id ? { ...n, actions: [] } : n
            );

            this.setState(
              {
                submitting: false,
                successFriendResolve: true,
                notifications: temporalNotifications,
                updateList: true,
              },
              () => {
                this.setState({ updateList: false });
              }
            );

            if (!viewed) this.markNotificationAsRead({ id });
          } else {
            this.setState({
              submitting: false,
              error: true,
            });
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
        });
    };

    /**
     * This function get the ntootification status of the user
     *
     */
    this.getNotificationsStatus = () => {
      // console.log('Geting  notification Status');
      this.setState({ submitting: true });
      api
        .getNotificationsStatus()
        .then((status) =>
          this.setState(
            {
              submitting: false,
              status,
              updateList: true,
            },
            () => {
              this.setState({ updateList: false });
            }
          )
        )
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ error });
        });
    };

    this.getNotifications = () => {
      // console.log('-----Geting  notifications------');
      // console.log( 'currentPAge', currentPage );

      if (this.state.submitting) {
        return;
      }

      this.setState({ submitting: true });

      const { notifications } = this.state;

      api
        .getNotifications(this.state.queryParams)
        .then((newNotifications) => {
          // console.log( newNotifications );
          let temporalNotifications = this.state.notifications;

          if (this.state.notifications.hasOwnProperty('data')) {
            console.warn('It already has Data');
            temporalNotifications.data = [
              ...this.state.notifications.data,
              ...newNotifications.data,
            ];
            console.log(temporalNotifications);
          } else {
            temporalNotifications = newNotifications;
          }

          this.setState(
            {
              submitting: false,
              notifications: temporalNotifications,
              updateList: true,
            },
            () => {
              this.setState({ updateList: false });
            }
          );
        })
        .catch((error) => this.setState({ error }));
    };

    this.getStatusAndNotifications = () => {
      if (this.state.submitting) {
        return;
      }
      // this.setState({ submitting: true });
      // console.log('%c Get  Status and Notifications',' color:#2e8ccf; font-size:2em;');

      this.getNotificationsStatus();
      this.getNotifications(this.state.queryParams);
    };
    /**TODO
     * Missing Implementation
     */
    this.loadMoreNotifications = () => {
      console.log('Load More Notifications');
      let newQueryParams = this.state.queryParams;
      newQueryParams.offset += this.state.queryParams.limit;
      this.setState(
        {
          queryParams: newQueryParams,
        },
        () => {
          console.log(this.state.queryParams);
          this.getNotifications(this.state.queryParams);
        }
      );
    };

    this.markAllNotificationsAsRead = () => {
      api
        .markAllNotificationsAsRead()
        .then(() => {
          const {
            notifications,
            status: { read, unread },
          } = { ...this.state };

          let temporalNotifications = this.state.notifications;
          temporalNotifications.data = temporalNotifications.data.map((n) => ({
            ...n,
            viewed: true,
          }));

          let status = {};

          this.setState(
            {
              status: {
                read: read + unread,
                unread: 0,
              },
              notifications: temporalNotifications,
              updateList: true,
            },
            () => {
              this.setState({ updateList: false });
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ error });
        });
    };

    /**
     * This method send a request to mark a notification as raead
     * @param { Object }
     */
    this.markNotificationAsRead = ({ id: notificationId }) => {
      this.setState({ submitting: true });
      api
        .markNotificationAsRead(notificationId)
        .then(() => {
          const {
            notifications,
            status: { read, unread },
          } = { ...this.state };

          let temporalNotifications = this.state.notifications;

          console.log(temporalNotifications);

          temporalNotifications.data.forEach((notification) => {
            if (notification.id === notificationId) {
              notification.viewed = true;
            }
          });

          this.setState(
            {
              status: {
                read: read + 1,
                unread: unread !== 0 ? unread - 1 : 0,
              },
              submitting: false,
              notifications: temporalNotifications,
              updateList: true,
            },
            () => {
              this.setState({ updateList: false });
              console.log('After Update List');
            }
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ submitting: false, error });
        });
    };

    this.poolRequest = ({ id, viewed, href }) => {
      const { history } = this.props;

      if (!viewed) this.markNotificationAsRead({ id });
      this.setState({ redirectTo: href });
    };
  }

  componentDidMount() {
    this.setState({ submitting: true });
    Promise.all([this.getStatusAndNotifications()])
      .then(() => {
        this.setState({ submitting: false });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    const {
      status: { unread },
      notifications,
      notificationsPopoverOpen,
      submitting,
    } = this.state;

    if (!notifications) {
      return null;
    }

    if (this.state.redirectTo !== null) {
      console.log('Redirect To ', this.state.redirectTo);

      setTimeout(() => {
        window.location = this.state.redirectTo;
      }, 300);
      return (
        <Redirect
          push
          to={{
            pathname: this.state.redirectTo,
          }}
        />
      );
    } else {
      // console.log( 'Unseen notifications', unSeen);
      return (
        <div className="flex-row row-no-padding">
          {window.innerWidth > 669 ? (
            <ReactTooltip id="notificationTooltip" type="light" place="bottom" effect="solid">
              {this.state.status.unread === 0
                ? 'No tienes notificaciones'
                : `Tienes ${this.state.status.unread} ${
                    this.state.status.unread > 1 ? 'notificaciones nuevas' : 'notificación nueva'
                  } `}
            </ReactTooltip>
          ) : null}

          <div
            data-for="notificationTooltip"
            data-tip
            id="notificationIndicator"
            onClick={this.handleDropdownUserOver}
            className={`flex-row row-center ${this.state.activeDropdown ? 'active' : ''}`}
          >
            <div className={`flex-col col-no-padding col-center `}>
              {this.state.status.hasOwnProperty('unread') && this.state.status.unread === 0 ? (
                <div className="flex-row row-no-padding row-center">
                  <div className="flex-col col-center col-no-padding text-center">
                    <i className="ion-android-notifications-none "></i>
                  </div>
                </div>
              ) : (
                <div className="flex-row row-no-padding row-center">
                  <div className="flex-col col-center col-no-padding">
                    <div className="flex-row row-center row-no-padding">
                      <i className=" flex-col col-center ion-android-notifications col-no-padding"></i>
                      <strong className="qntyIndiacator">
                        {' '}
                        {this.state.status.hasOwnProperty('unread')
                          ? this.state.status.unread
                          : ''}{' '}
                      </strong>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <NotificationList
            updateList={this.state.updateList}
            error={this.state.error}
            handleNotificationControls={this.handleNotificationControls}
            handleArchive={this.handleArchive}
            successFriendResolve={this.state.successFriendResolve}
            show={this.state.activeDropdown}
            handleNotificationClick={this.handleNotificationClick}
            handleUserMouseLeave={this.handleUserMouseLeave}
            markNotificationAsRead={this.markNotificationAsRead}
            handleUserMouseOver={this.handleUserMouseOver}
            markAllNotificationsAsRead={this.markAllNotificationsAsRead}
            loadMoreNotifications={this.loadMoreNotifications}
            resolveFriendRequest={this.resolveFriendRequest}
            poolRequest={this.poolRequest}
            submitting={this.state.submitting}
            notifications={this.state.notifications}
            unread={this.state.status.unread}
            submitting={this.state.submitting}
          />
        </div>
      );
    }
  }
}

export default withRouter(onClickOutside(NotificationCenterPopover));
