import React, { useEffect, useRef } from "react";
import styled from 'styled-components'

const PromotionCard = ({ onShowDetails, promotion }) => {
  const blobRef = useRef()
  const fakeBlobRef = useRef()

  useEffect(() => {
    const hanlder = (ev) => {
      const rec = fakeBlobRef.current.getBoundingClientRect();
      blobRef.current.style.opacity = "1";

      blobRef.current.animate(
        [
          {
            transform: `translate(${
              (ev.clientX - rec.left) - (rec.width / 2)
            }px,${(ev.clientY - rec.top) - (rec.height / 2)}px)`
          }
        ],
        {
          duration: 1200,
          fill: "forwards"
        }
      );
    }

    window.addEventListener('mousemove', hanlder)

    return () => {
      window.removeEventListener('mousemove', hanlder)
    }
  }, [])

  const callToAction = () => {
    window.location.href = promotion.redirect.desktop
  }

  return (
    <Box>
      <Body>
        <Image src={promotion.thumb} />
        <Title>{promotion.name}</Title>
        <Description dangerouslySetInnerHTML={{ __html: promotion.descriptionShort }} />
        <Footer>
          <Button className='btn-pick btn btn-sm btn-default' onClick={() => onShowDetails(promotion)}>Ver Detalles</Button>
          <ButtonAction className='btn-pick btn btn-sm btn-default' onClick={callToAction}>{promotion.okText}</ButtonAction>
        </Footer>
      </Body>
      <Blob ref={blobRef}></Blob>
      <FakeBlob ref={fakeBlobRef}></FakeBlob>
    </Box>
  )
}


export default PromotionCard

const Box = styled.div`
  border-radius: 0.35em;
  overflow: hidden;
  background: rgba(58, 58, 58, 1);
  position: relative;
  transition: all 300ms ease-in-out;
  width: 300px;
  position: relative;
  display: flex;
  justify-content: center;
`

const Body = styled.div`
  background-color: #000000;
  z-index: 20;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.35em;
  overflow: hidden;
  min-height: 350px;
  width: 100%;
  margin: 2px;
  padding: 0.7em;
  padding-bottom: 1.7em;
`

const Image = styled.img`
  width: 100%;
`

const Title = styled.div`
  text-align: center;
  line-height: 2.5;
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Chivo';
`

const Description = styled.div`
  text-align: center;
  line-height: 2;
  margin-bottom: 0.8em;
  font-size: 0.9em;

  ul {
    list-style: none;
    margin: 0.5em 0;
    padding: 0;
    padding-left: 2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3em;
  }

  ul li:before {
    content: "•";
    color: #78BC28;
    display: inline-block; width: 1em;
    margin-left: -1em
  }
`

const Footer = styled.div`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
`

const Button = styled.button`
  background: black !important;
  outline: 0;
  line-height: 2;
  flex-grow: 2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.2em;
  font-size: 0.93em;

  &:hover {
    opacity: 0.8;
  }
`

const ButtonAction = styled.button`
  height: 2.2em;
  line-height: 2;
  flex-grow: 2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.93em;
`

const Blob = styled.div`
  filter: blur(40px);
  position: absolute;
  z-index: 1;
  top: 0;
  opacity: 0;
  left: 0;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: #95e00aff;
  transition: all 300ms ease-in-out;
`

const FakeBlob = styled.div`
  display: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
`
