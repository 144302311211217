import { shorthandPropList } from '../xme.config'
// We build a styles on steroids with shortcuts to build easy and clean layouts

export default function getShortcutStyles (style = {}, others = {}) {
  // Reducing merged styles for direct props and shorthands
  const styles = shorthandPropList.reduce((prev, [shortCutName, cssName]) => {
    const styles = { ...prev }

    if (others[shortCutName]) {
      styles[cssName] = others[shortCutName]
    }

    return styles
  }, { ...style })

  // Text Align
  if (others.center) {
    styles.textAlign = 'center'
  }

  if (others.left) {
    styles.textAlign = 'left'
  }

  if (others.right) {
    styles.textAlign = 'right'
  }

  // Margin shorcuts
  if (others.mx) {
    styles.marginLeft = others.mx
    styles.marginRight = others.mx
  }

  if (others.my) {
    styles.marginTop = others.my
    styles.marginBottom = others.my
  }

  // Paddings shorcuts
  if (others.px) {
    styles.paddingLeft = others.px
    styles.paddingRight = others.px
  }

  if (others.py) {
    styles.paddingTop = others.py
    styles.paddingBottom = others.py
  }

  return styles
}
