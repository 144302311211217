import React, { Component } from 'react';
import _ from 'underscore';
import ReactTooltip from 'react-tooltip';
import { List, AutoSizer, InfiniteLoader } from 'react-virtualized';
import { Button, Modal, ToggleButton, ToggleButtonGroup, ButtonToolbar } from 'react-bootstrap/lib';

import API from '../../utils/api';
import branchUtils from '../../utils/branchUtils';
import utils from '../../utils/utils';

import UserInvitation from '../UserInvitation';
import PanelInvitaJugar from '../PanelInvitaJugar/PanelInvitaJugar';
import PanelPlayerInfo from '../PanelPlayerInfo/PanelPlayerInfo';
import TransferRealToUser from '../TransferRealToUser/index';

import avatar from '../../assets/images/avatar-50.png';
import flechas from '../../assets/images/flechasIconBlanco-50.png';
import retar from '../../assets/images/vsIconHalf-50.png';

import * as Sentry from '@sentry/browser';
import $ from 'jquery';


/**
 * Render the confirmation of a deleteFreind Action
 * @param userSelected 		 { Object } user data
 * @param submitting	 		 { boolean } Is Submitting?
 * @param showConfirmation { Boolean } the value that controlls the show flag
 * @param hideConfirmation { Fucntion }  This hide the panel
 * @param title 					{ String }
 * @param label 					{ String }
 * @param unFollow 				{ Function } The function  that sends the request to the API
 * @param errorStatus 		{ Boolean } Is there an error
 * @param errorLabel 		  { String } Error  message
 * @param errorTitle 			{ String } Erro Title
 * @return Component
 */
const ConfirmationDeleteFriend = ({
  userSelected,
  submitting,
  id,
  showConfirmation,
  hideConfirmation,
  title,
  label,
  unFollow,
  errorStatus,
  errorLabel,
  errorTitle,
  successDelete,
}) => {
  return (
    <Modal
      bsSize="sm"
      dialogClassName="confirmationPanelPopUp modal-dialog-xxxs"
      show={showConfirmation}
      keyboard={false}
    >
      <div className="panel-abonar">
        {successDelete ? (
          <div
            id="panelTitle"
            className={`flex-row row-no-padding panel-abonar-titulo failed ${errorStatus ? 'failed' : ''}`}
          >
            <span>{`${userSelected.nick} ya no es tu amigo`}</span>
            <button className="cerrar-panel" onClick={hideConfirmation}>
              <div className="btn-icon-close">
                <i className="ion-ios-close-empty" />
              </div>
            </button>
          </div>
        ) : (
            <div
              id="panelTitle"
              className={`flex-row row-no-padding panel-abonar-titulo failed ${errorStatus ? 'failed' : ''}`}
            >
              <span>{errorStatus ? errorTitle : title}</span>
              <button className="cerrar-panel" onClick={hideConfirmation}>
                <div className="btn-icon-close">
                  <i className="ion-ios-close-empty" />
                </div>
              </button>
            </div>
          )}

        <div className="flex-row">
          <div className="flex-col col-80 col-offset-10">
            {successDelete ? (
              <div className="flex-container justify align column text-center">
                <i className="ion-checkmark-circled success" />
                {`Se eliminó a ${userSelected.nick} de tu lista de amigos `}
              </div>
            ) : (
                <div className="flex-container justify align column text-center">
                  <i className="ion-alert-circled alert" />
                  {errorStatus ? errorLabel : label}
                </div>
              )}
          </div>
        </div>
        <div className="flex-row row-center">
          <div className="flex-col col-90 col-offset-5">
            {successDelete ? (
              <div className="flex-row">
                <Button
                  className="flex-col col-80 col-offset-10 col-center btn-rojo btn-submit text-center"
                  onClick={() => {
                    hideConfirmation();
                  }}
                >
                  Regresar
                </Button>
              </div>
            ) : (
                <div className="flex-row">
                  <Button
                    className="flex-col col-40 col-offset-5 col-center btn-rojo btn-submit text-center"
                    onClick={() => {
                      hideConfirmation();
                    }}
                  >
                    No
                </Button>
                  <Button
                    disabled={submitting}
                    className="flex-col col-40 col-offset-5 col-center btn-pick btn-submit text-center"
                    onClick={() => {
                      unFollow(userSelected.id);
                    }}
                  >
                    {submitting ? (
                      <div className="flex-container justify align column">
                        <i className="ion-load-a loading small"></i>
                      </div>
                    ) : (
                        'Si'
                      )}
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

class ListadoAmigos extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      branchLink: null,
      error: null,
      friends: [],
      friendsLayout: 'list',
      queryParams: { limit: 10, offset: 0, format: 'json' },
      responseInviteStatus: null,
      showDeletePanel: false,
      showInvitePanel: false,
      showLoadingMore: true,
      showPanelPlayerInfo: false,
      showTransfer: false,
      submitting: false,
      successDelete: false,
      updateList: false,
      userSelected: null,
    };

    /**
     * Utilities to handle branch links
     */
    this.getBranchLinkChallenge = (pool, gameType) => {
      return branchUtils.createLinkChallenge(utils.getUserInfo(), pool, gameType);
    };
    this.whatsAppInvitationChallenge = (branchLink, pool) => {
      console.log('WhatsApp Invitation Challenge');
      branchUtils.redirectChallengeToWhatsApp(branchLink, 'open', pool);
    };

    /**
     * Handlers of Transfer Panel
     */
    this.showTransfer = (friend) => {
      this.setState({ showTransfer: true, userSelected: friend });
    };
    this.hideTransfer = () => {
      console.log('Close Transfer');
      this.setState({ showTransfer: false, userSelected: null });
    };
    /**
     * Handlers of Confirmation Panel
     */
    this.showConfirmationDelete = (friend) => {
      this.setState({ showDeletePanel: true, userSelected: friend });
    };
    this.hideConfirmationDelete = () => {
      this.setState({ showDeletePanel: false, updateList: true }, () => {
        this.setState({ userSelected: null, successDelete: false, updateList: false });
      });
    };
    /**
     * Handlers of Player Info Panel
     */
    this.showPlayerPanel = (friend) => {
      this.setState({ showPanelPlayerInfo: true, userSelected: friend });
    };
    this.hidePlayerPanel = () => {
      this.setState({ showPanelPlayerInfo: false }, () => {
        this.setState({ userSelected: null });
      });
    };

    this.handleFriendsLayout = (e) => {
      console.log('HAndle Friends Layout', e);
      this.setState({ friendsLayout: e });
    };

    /**
     * Handlers of Invite Panel
     */
    this.showConfirmationInvite = (friend) => {
      this.setState({ showInvitePanel: true, userSelected: friend });
    };
    this.hideConfirmationInvite = () => {
      this.setState({ showInvitePanel: false }, () => {
        this.setState({ userSelected: null });
      });
    };

    /**
     * This method Render The list of Friends
     * and uses the HOC props.style to handle the positioni
     */
    this.rendererFriendsList = (props) => {
      // console.log('props.style');
      // console.log(props.style);
      // let newStyles = props.style;
      // newStyles.top = newStyles.top+ 8;

      let currentFriend = this.state.friends.data[props.index];
      return (
        <div
          key={currentFriend.id}
          style={props.style}
          className={`flex-container justify align friend-row ${props.isVisible ? '' : 'blurItem'}`}
        >
          <div key={currentFriend.id} className={`flex-col friend-item col-90 `}>
            <div className="flex-row  row-center">
              <img
                className="flex-col avatar-default col-no-padding img-responsive"
                src={currentFriend.avatar_url ? currentFriend.avatar_url : avatar}
              />
              <div className="flex-col friend-actions col-no-padding">
                <strong className="flex-row"> {currentFriend.nick} </strong>
              </div>
              <div className="flex-col col-30 friendsActions col-no-padding col-center">
                <ReactTooltip id="deleteFriend" place="top" type="light" effect="solid">
                  Eliminar amigo
                </ReactTooltip>
                <Button
                  data-tip
                  data-for="deleteFriend"
                  onClick={() => {
                    this.showConfirmationDelete(currentFriend);
                  }}
                  className="flex-row row-no-padding row-center btn-rojo"
                >
                  <div className={`flex-col col-no-padding`}>
                    {$(window).width() > 768 ? 'Eliminar' : ''}
                    <i className="ion-trash-a" style={{ marginLeft: '3px' }}></i>
                  </div>
                </Button>
              </div>
              <div className="flex-col col-30 friendsActions col-center ">
                <ReactTooltip id="inviteFriend" place="top" type="light" effect="solid">
                  Retar amigo
                </ReactTooltip>
                <Button
                  data-tip
                  data-for="inviteFriend"
                  onClick={() => {
                    this.showConfirmationInvite(currentFriend);
                  }}
                  className="flex-row row-no-padding row-center btn-success"
                >
                  <div className="flex-col col-no-padding ">
                    {$(window).width() > 768 ? 'Retar' : ''}
                    <i>
                      <img src={retar} className="btn-icon"></img>
                    </i>
                  </div>
                </Button>
              </div>
              <div
                className=" hide view-more flex-col col-5 col-center text-center"
                onClick={() => {
                  this.showPlayerPanel(currentFriend);
                }}
              >
                <i className="ion-android-more-vertical  "></i>
              </div>
            </div>
          </div>
        </div>
      );
    };

    this.rendererFriendsGrid = (props) => {
      // console.log('rendererFriendsList');
      let itemWidth = 30;
      const currentFriend = this.state.friends.data[props.index];

      return (
        <div key={currentFriend.id} className={`flex-col friend-item grid col-${itemWidth} `}>
          <div className="flex-container column justify align">
            <div className="flex-col text-center">
              <div className="flex-row  row-center">
                <div className="flex-container justify align">
                  <img
                    className=" avatar-default img-responsive"
                    src={currentFriend.avatar_url ? currentFriend.avatar_url : avatar}
                  />
                </div>
              </div>
            </div>
            <div className="flex-col col-no-padding col-center">
              <div className="flex-row row-center row-no-padding">
                <div className="flex-container justify align friend-actions">
                  <strong className="flex-col col-90 col-center text-center">
                    {' '}
                    {currentFriend.nick}{' '}
                  </strong>
                  <div
                    className="view-more flex-col col-center text-center"
                    onClick={() => {
                      this.showPlayerPanel(currentFriend);
                    }}
                  >
                    <i className="ion-android-more-vertical"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row row-no-padding action-buttons">
            <div className="flex-col col-40 col-offset-5 friendsActions col-center">
              <ReactTooltip id="deleteFriend" place="left" type="light" effect="solid">
                Eliminar amigo
              </ReactTooltip>
              <Button
                data-tip
                data-for="deleteFriend"
                onClick={() => {
                  this.showConfirmationDelete(currentFriend);
                }}
                className="flex-row row-no-padding row-center btn-rojo"
              >
                <div className="flex-col col-no-padding ">
                  <i className="ion-trash-a" style={{ marginLeft: '3px' }}></i>
                </div>
              </Button>
            </div>
            <div className="flex-col col-40 col-offset-10  friendsActions col-center ">
              <ReactTooltip id="inviteFriend" place="right" type="light" effect="solid">
                Retar amigo
              </ReactTooltip>
              <Button
                data-tip
                data-for="inviteFriend"
                onClick={() => {
                  this.showConfirmationInvite(currentFriend);
                }}
                className="flex-row row-no-padding row-center btn-success"
              >
                <div className="flex-col  col-no-padding friendsActions ">
                  <img src={retar} className="btn-icon" />
                </div>
              </Button>
            </div>
          </div>
        </div>
      );
    };

    /**
     * API Method That fetch the List of friends
     * @param { Object } - THe object contains the limit, and the offset if the method
     */
    this.getFriends = () => {
      this.setState({ submitting: true });

      API.listAlreadyFriendUsers(this.state.queryParams)
        .then((friendsResponse) => {
          let temporalFriends = this.state.friends.hasOwnProperty('data')
            ? this.state.friends
            : friendsResponse;
          if (this.state.friends.hasOwnProperty('data')) {
            temporalFriends.data = [...temporalFriends.data, ...friendsResponse.data];
          } else {
            temporalFriends = friendsResponse;
          }

          this.setState(
            {
              friends: temporalFriends,
              submitting: false,
              updateList: true,
            },
            () => {
              this.setState({ updateList: false });
            }
          );
        })
        .catch((error) => {
          this.setState({
            error,
            submitting: false,
            showLoadingMore: true,
          });
        });
    };

    this.isRowLoaded = ({ index }) => {
      // console.log(index);
      return !!this.state.friends.data[index];
    };

    this.loadMoreRows = () => {
      let temporalQueryParams = this.state.queryParams;

      temporalQueryParams.offset += this.state.queryParams.limit;

      this.setState({ queryParams: temporalQueryParams }, () => {
        this.getFriends();
      });
    };

    /**
     * API Method that unfollows a Friend
     * @param { Number } - Id Nunmmber of the Friend to unfollow
     *
     */
    this.unFollow = (id) => {
      console.log('-----Unfollow-----');
      this.setState({ submitting: true });

      API.unfollowUser(id)
        .then((response) => {
          if (response.hasOwnProperty('message')) {
            let temporalFriends = this.state.friends;
            temporalFriends.data = _.filter(temporalFriends.data, (f) => f.id !== id);
            console.log(temporalFriends, response);
            this.setState({
              friends: temporalFriends,
              submitting: false,
              successDelete: true,
            });
          } else {
            throw 'Error unfollowing user';
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          this.setState({ error, submitting: false });
        });
    };

    this.resetInviteData = () => {
      this.setState({ responseInviteStatus: null });
    };

    this.sendChallange = (friendId, poolInfo) => {
      console.log('Send Challenge', poolInfo);
      // onSelect( { userId, options } ) {
      const poolId = poolInfo.id;
      const href = `/quiniela/${poolInfo.slug}/${poolId}`;
      //const userId = friendId;
      const challengeData = { href: href, userId: friendId };

      API.inviteUserToPool(challengeData).then((response) => {
        console.log(response);
        if (response.message === 'ok') {
          this.setState({
            responseInviteStatus: true,
          });
        } else {
          this.setState({
            responseInviteStatus: 'error',
          });
        }
      });
    };
  }

  componentWillMount() {
    this.getFriends();
  }

  render() {
    return (
      <div>
        <div className="category-user retirar flex-row">
          <div className="flex-col">
            <div className="flex-row responsive-sm row-no-padding">
              <div className="flex-col col-80 col-offset-10">
                <UserInvitation />
              </div>
            </div>
            <div
              id="pickwinOverlay"
              className={`flex-container justify align ${this.state.submitting ? '' : 'hide'}`}
            >
              <i className="ion-load-a loading"></i>
            </div>
            <div className="flex-row">
              {!this.state.friends.hasOwnProperty('data') || !this.state.friends.data.length ? (
                <div className="flex-container column justify align mb-2">
                  <i style={{ fontSize: '2.5em' }} className="ion-sad"></i>
                  <div> No tienes ningún amigo.</div>
                </div>
              ) : (
                  <div className="flex-col ">
                    <div className="flex-row ">
                      <div className="flex-col col-center">
                        <div className="flex-row ">
                          <div className="flex-col ">
                            <span style={{ textTransform: 'uppercase' }}>Lista de Amigos</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`listFriends ${
                        this.state.friendsLayout === 'list'
                          ? 'flex-col col-col-center responsive-sm'
                          : 'flex-container wrap  '
                        }`}
                    >
                      <InfiniteLoader
                        isRowLoaded={this.isRowLoaded}
                        loadMoreRows={this.loadMoreRows}
                        rowCount={this.state.friends.total}
                        threshold={2}
                      >
                        {({ onRowsRendered, registerChild }) => (
                          <AutoSizer>
                            {({ height, width }) => (
                              <List
                                ref={(registerChild) => (this.list = registerChild)}
                                height={height}
                                overscanRowCount={5}
                                onRowsRendered={onRowsRendered}
                                rowCount={
                                  this.state.friendsLayout === 'list'
                                    ? this.state.friends.data.length
                                    : this.state.friends.data.length / 3
                                }
                                rowHeight={this.state.friendsLayout === 'list' ? 58 : 127}
                                rowRenderer={
                                  this.state.friendsLayout === 'list'
                                    ? this.rendererFriendsList
                                    : this.rendererFriendsGrid
                                }
                                width={width}
                              />
                            )}
                          </AutoSizer>
                        )}
                      </InfiniteLoader>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <PanelPlayerInfo
            selectedUser={this.state.userSelected}
            showPanel={this.state.showPanelPlayerInfo}
            sendChallenge={this.showPlayerPanel}
            showTransfer={this.showTransfer}
            showConfirmationInvite={this.showConfirmationInvite}
            showConfirmationDelete={this.showConfirmationDelete}
            closePanel={this.hidePlayerPanel}
          />
          <PanelInvitaJugar
            branchLink={this.state.branchLink}
            closePanel={this.hideConfirmationInvite}
            getBranchLinkChallenge={this.getBranchLinkChallenge}
            hideInvitationResponse={this.hideInvitationResponse}
            hidePanel={this.hideMailPanel}
            openInvitePanel={this.openMailPanel}
            poolSelected={this.poolSelected}
            openAbonar={this.props.openAbonar}
            resetInviteData={this.resetInviteData}
            responseStatus={this.state.responseInviteStatus}
            sendChallange={this.sendChallange}
            showInvitationResponse={this.state.showInvitationResponse}
            showMailInvite={this.state.showMailInvite}
            showPanel={this.state.showInvitePanel}
            title={'Reta a tu amigo '}
            updateUserInfo={this.props.updateUserInfo}
            updateUserBalance={this.props.updateUserBalance}
            whatsAppInvitationChallenge={this.whatsAppInvitationChallenge}
            userData={this.props.userData}
            userData={this.props.userBalance}
            userInviteObject={this.state.userSelected}
          />
          <Modal
            backdropClassName="transferBackdrop"
            dialogClassName="transferDialog"
            onHide={this.hideTransfer}
            show={this.state.showTransfer}
          >
            <TransferRealToUser
              type="modal"
              closePanel={this.hideTransfer}
              availableCashout={this.props.availableCashout}
              updateUserInfo={this.props.updateUserInfo}
              updateUserBalance={this.props.updateUserBalance}
            />
          </Modal>
          <ConfirmationDeleteFriend
            userSelected={this.state.userSelected}
            submitting={this.state.submitting}
            showConfirmation={this.state.showDeletePanel}
            hideConfirmation={this.hideConfirmationDelete}
            title={'¿Estás seguro?'}
            label={`¿De verdad quieres eliminar a ${
              this.state.userSelected ? this.state.userSelected.nick : 'tu amigo'
              } de tu lista de amigos?`}
            unFollow={this.unFollow}
            errorStatus={this.state.errorDeleteStatus}
            errorLabel={'Error'}
            errorTitle={'Error'}
            successDelete={this.state.successDelete}
          />
        </div>
      </div>
    );
  }
}

export default ListadoAmigos;
