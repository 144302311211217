import * as Sentry from '@sentry/browser'
import branchUtils from './branchUtils'
import utils from './utils'


window.Sentry = Sentry


if (process.env.NODE_ENV === 'production') {
  utils.startRaven('production')
} else {
  // utils.startRaven( 'debug')
}

window.Sentry = Sentry


if (process.env.NODE_ENV === 'production') {
  utils.startRaven('production')
} else {
  // utils.startRaven( 'debug')
}

const mainPath = window.location.pathname.split('/')[1]

branchUtils
  .initBranch()
  .then((response) => {
    console.log('Branch Response:', response)
    if (
      !utils.isLogged() &&
      !utils.hasSeenLanding() &&
      mainPath !== 'password_perdido' &&
      mainPath !== 'reset_password' &&
      mainPath !== 'legal'
    ) {
      utils.saveDataNotLoggedUser(
        generateRedirectFrom(window.location.pathname)
      )
      console.info('redirect branch')
    }
  })
  .catch((error) => {
    console.error('Branch Error:', error)
    utils.getUserMetadataRegister(window.location.search)
    if (
      !utils.isLogged() &&
      !utils.hasSeenLanding() &&
      mainPath !== 'password_perdido' &&
      mainPath !== 'reset_password' &&
      mainPath !== 'legal'
    ) {
      utils.saveDataNotLoggedUser(
        generateRedirectFrom(window.location.pathname)
      )
      console.info('redirect no branch');
    }
  })

if (utils.isLogged()) {
  branchUtils.initBranchUser()
  try {
    utils.setUserContextRaven(utils.getUserInfo())
  } catch (error) {
    Sentry.captureException(error)
  }
}

/**
 * This method generate the redirect to object to retrieve the correct game through the landing page
 * @param {pathname} pathname
 */
const generateRedirectFrom = (pathname) => {
  const obj = { game_type: null, id: null }

  if (!pathname) {
    return null
  }

  pathname = pathname.split('/')
  if (pathname.length === 4) {
    switch (pathname[1]) {
      case 'quinielaPop':
        obj.game_type = 'pool'
        obj.id = pathname[3]
        break
      case 'survivorPop':
        obj.game_type = 'survivor'
        obj.id = pathname[3]
        break
      case 'game_typeop':
        obj.game_type = 'bracket'
        obj.id = pathname[3]
        break
      case 'quinielaGroupPop':
        obj.game_type = 'group_pool'
        obj.id = pathname[3]
        break
      default:
        break
    }
    return obj
  }
}

