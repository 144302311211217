import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Components
import Title from './Title'
import Button from '../Form/Button'
import ModalBody from '../ModalPanel/ModalBody'
import Iconify from '../Iconify/Iconify'
import Logo from './Logo'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

export default function WelcomeSuccess () {
  const { closeModal } = useAuthContext()

  return (
    <ModalBody>
      <Logo
        src='/images/Pickwin.png'
        alt='Pickwin logo'
      />
      <Iconify
        icon='ep:success-filled'
        style={{ fontSize: '3.5em', color: '#78bc28', margin: '0 auto' }}
      />
      <Title>¡Felicidades, tú cuenta se verificó con éxito!</Title>
      <Link to='/' onClick={closeModal}>
        <Button className='login-btn' bsStyle='primary' bsSize='large' block bgColor='#78BC28'>
          Regresar al Lobby Principal
        </Button>
      </Link>
    </ModalBody>
  )
}
