import React from 'react';
import CrearQuinielaFormsy from '../CrearQuinielaPrivada/CrearQuinielaFormsy';

import Formsy from 'formsy-react';
import { addValidationRule } from 'formsy-react';

import { Redirect } from 'react-router-dom';

import {
  Form,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
  Modal,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  HelpBlock,
} from 'react-bootstrap/lib';
import API from '../../utils/api';
import Slider from 'react-rangeslider';
import utils from '../../utils/utils';
import moment from 'moment';

import { LoadingProcess, ErrorMessage } from '../../utils/quinielasComponents';
import * as Sentry from '@sentry/browser';
import _ from 'underscore';

addValidationRule('maxValueCommission', (values, value) => {
  // console.log('Adding Validation Rule',  values, value, this);
  return Number(value) <= 15;
});
addValidationRule('maxPrizes', (values, value) => {
  // console.log('Adding Validation Rule',  values, value, this);
  return Number(value) <= 10;
});

addValidationRule('minRegister', (values, value) => {
  console.log('minRegister Validation', values, value, this);
  return Number(value) > 0;
});

const ListaPremios = ({
  prizesQnty,
  prizesSum,
  type,
  handlePrizesCustom,
  editarPrivadaData,
  listPrizes,
  setMaxPrizeQnty,
}) => {
  prizesQnty = Number(prizesQnty);
  // console.log('prizesQnty', prizesQnty );
  // console.log('CrearPrivadaData', editarPrivadaData.prizes_list);
  // console.log('CrearPrivadaData', editarPrivadaData.prizes_list);

  let listaPremios = [];

  for (let i = 1; i <= prizesQnty; i++) {
    listaPremios.push(i);
  }

  listaPremios = listaPremios.map((premio, premioIdx) => {
    return (
      <div key={premioIdx} className="flex-col col-no-padding col-30 prize-item">
        <div className="flex-row row-center row-no-padding">
          <div className="flex-container justify align">
            <span>{premio}°</span>
            <i className="ion-trophy" style={{ marginLeft: '5px' }}></i>
          </div>
          <div className="flex-container justify align"></div>
        </div>
        <div className="flex-row row-center ">
          <div className="flex-col">
            <CrearQuinielaFormsy
              prizesSum={prizesSum}
              listPrizes={listPrizes}
              prizeId={premio}
              setMaxPrizeQnty={setMaxPrizeQnty}
              name={'gamePrizesCustom' + premio}
              defaultValue={listPrizes[premioIdx].percentage}
              inputHandler={handlePrizesCustom}
              type="PRIZES_CUSTOM"
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="flex-col">
      <div className="flex-row">
        <div className="flex-col">
          <div className="control-label">Lista de premios</div>
        </div>
      </div>
      <div className="flex-container justify align wrap">{listaPremios}</div>
    </div>
  );
};

const Torneos = ({ deporte, lista, handleTorneos, defaultValue }) => {
  // console.log('lista',lista );
  let listaTorneos = lista.find((sport) => sport.id === deporte);
  if (!listaTorneos) {
    return;
  }
  listaTorneos = listaTorneos.tournament_instances.map((torneo, torneoIdx) => {
    return (
      <ToggleButton
        defaultValue={defaultValue}
        className="btn-torneo"
        key={torneo.id}
        value={torneo.id}
      >
        {torneo.name}
      </ToggleButton>
    );
  });
  return (
    <ButtonToolbar className="toolbar-toreno">
      <ToggleButtonGroup type="radio" name="torneo" onChange={handleTorneos}>
        {listaTorneos.length === 0 ? (
          <div className="intro-copy">
            Lo sentimos pero por el momento no tenemos ligas disponibles.
          </div>
        ) : (
          listaTorneos
        )}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

const WizardButtons = ({
  prizesSum,
  activeSection,
  canSubmit,
  nameExist,
  submitting,
  nextStep,
  prevStep,
  submitSearch,
  handleCancelSelection,
  editarPrivadaData,
  gameType,
}) => {
  let isReady = activeSection === 5;
  let conditionalComponent = null;
  if (editarPrivadaData.prize_scheme === 'custom' && prizesSum !== 100) {
    canSubmit = false;
  }
  conditionalComponent = (
    <div className="flex-col col-90">
      <div className="buttons-wizard flex-row row-no-padding">
        <div className="flex-col">
          <Button
            className="btn-submit btn-pick btn-wizard btn-block flex-container justify align"
            disabled={!canSubmit || submitting || nameExist}
            onClick={() => {
              submitSearch();
            }}
          >
            <span className="text-center">Guardar cambios</span>
          </Button>
        </div>
      </div>
    </div>
  );

  return conditionalComponent;
};

class EditarQuinielaFormulario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      isReady: false,
      deportesTorneos: null,
      listPrizes: [],
      mounted: false,
      errorMessage: false,
      canSubmit: false,
      jornadasLoaded: false,
      nameExist: false,
      searchingName: false,
      successfullCreation: false,
      errorCreation: false,
      errors: null,
    };
    if (this.props.gameInfo.game_type === 'pool') {
      this.editarPrivadaData = {
        pool_id: '',
        limit_multientries: '',
        max_multientries: '',
        entry_fee: '', //Required
        prize_scheme: 'dynamic', //Required
        weekly_percentage: null,
        end_percentage: null,
        weekly_prize_scheme: null,
        weekly_prize: false,
        pool_prizes: null, //Optional
      };
    } else if (this.props.gameInfo.game_type === 'survivor') {
      this.editarPrivadaData = {
        survivor_id: '',
        max_multientries: '',
        limit_multientries: false,
        entry_fee: '', //Required
        prize_scheme: 'dynamic', //Required
        survivor_prizes: null, //Optional
      };
    }

    /**
     *  This object handle the privata Custom Prize scheme
     * **/
    this.crearPrivadaPremioCustom = {
      position: null,
      type: 'percentage',
      percentage: null,
    };

    this.resetData = () => {
      this.setState(
        {
          successfullCreation: false,
          errorCreation: false,
        },
        () => {
          setTimeout(() => {
            this.initializeEditPrivada();
          }, 500);
        }
      );
    };

    /**
     * This function initialize the Fields of the Form
     * that edit the private pool
     */
    this.initializeEditPrivada = () => {
      if (!this.props.gameInfo) {
        console.error('PoolInfo doesnt exist');
        return;
      }

      this.setState({ mounted: false });

      this.editarPrivadaData.entry_fee = this.props.gameInfo.entry_fee / 100;
      this.editarPrivadaData.prize_scheme = this.props.gameInfo.prize_scheme;

      this.editarPrivadaData.max_multientries = this.props.gameInfo.max_multientries;
      this.editarPrivadaData.limit_multientries =
        this.props.gameInfo.max_multientries !== null && this.props.gameInfo.max_multientries > 0;

      // console.log(this.editarPrivadaData);

      if (this.props.gameInfo.game_type === 'pool') {
        this.editarPrivadaData.pool_id = this.props.gameInfo.id;
        this.editarPrivadaData.weekly_prize = this.props.gameInfo.weekly_prize;
        this.editarPrivadaData.weekly_prize_scheme = this.props.gameInfo.weekly_prize_scheme;
        this.editarPrivadaData.weekly_percentage = this.props.gameInfo.weekly_percentage / 100;
        this.editarPrivadaData.end_percentage = this.props.gameInfo.end_percentage / 100;

        if (this.props.gameInfo.prize_scheme === 'custom') {
          let tempListPrizes = this.props.premios.pool_prizes[0].prizes.map((premio) => {
            return {
              position: premio.position,
              type: premio.type,
              percentage: premio.percentage / 100,
            };
          });

          this.editarPrivadaData.prizes_list = Number(tempListPrizes.length);

          this.editarPrivadaData.pool_prizes = tempListPrizes;

          this.setState({
            listPrizes: tempListPrizes,
          });
        }
      } else if (this.props.gameInfo.game_type === 'survivor') {
        this.editarPrivadaData.survivor_id = this.props.gameInfo.id;

        if (this.props.gameInfo.prize_scheme === 'custom') {
          let tempListPrizes = this.props.premios.map((premio) => {
            console.log(premio.percentage);
            return {
              position: premio.position,
              type: premio.type,
              percentage: premio.percentage / 100,
            };
          });

          this.editarPrivadaData.prizes_list = Number(tempListPrizes.length);

          this.editarPrivadaData.survivor_prizes = tempListPrizes;

          this.setState({
            listPrizes: tempListPrizes,
          });
        }
      }

      if (this.editarPrivadaData.limit_multientries) {
        console.log('Last resource');
        this.handleLimitMultientries(true);
      }

      this.setState({ mounted: true });

      console.log(this.editarPrivadaData);

      // this.editarPrivadaData.tournament_instances      = this.props.gameInfo.tournament_instances;
      // this.editarPrivadaData.pool_prizes               = this.props.premios;
    };

    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleEntryFee = (e) => {
      // let val = e.target.value === 0 ? 1 : e.target.value ;
      this.editarPrivadaData.entry_fee = e.target.value;
      // this.props.updateCreatePrivateData(this.editarPrivadaData);
    };

    /**
     * Handle the value of the comission-fee
     * @param value is the value that changes the fee selected by the user
     * **/
    this.handleComissionFee = (e) => {
      // console.log( e.target.value );
      const value = e.target.value;
      this.editarPrivadaData.affiliate_commission = value;
      // this.props.updateCreatePrivateData(this.editarPrivadaData);
      // console.log(this.editarPrivadaData)
    };

    /**
     * Handle the value of the general prize  scheme
     * @param value is the value that changes the scheme of general prizes by the user
     * **/
    this.handlePrizeScheme = (value) => {
      this.editarPrivadaData.prize_scheme = value;
      if (value === 'custom') {
        this.disableButton();
        // this.editarPrivadaData.prizes_list = 10;
      } else {
        this.editarPrivadaData.prizes_list = null;
      }
      // this.props.updateCreatePrivateData(this.editarPrivadaData);
    };

    /**
     * Handle the boolean value of Limit_multienries
     * @param value is the data that handles the limit_multientries of the game
     * **/
    this.handleLimitMultientries = (value) => {
      this.editarPrivadaData.limit_multientries = value;
      this.editarPrivadaData.max_multientries = value
        ? !this.editarPrivadaData.max_multientries
          ? ''
          : this.editarPrivadaData.max_multientries
        : null;
      // console.log('Updated creation data', this.crearPrivadaData );
    };
    /**
     * Handle the Entry fee of the private game
     * @param e is the value that changes character by the user
     * **/
    this.handleMaxMultientries = (e) => {
      // let val = e.target.value === 0 ? 1 : e.target.value ;
      this.editarPrivadaData.max_multientries = e.target.value;
    };

    /**
     * Handle the value of the prize_list
     * @param value is the value that changes the prize list qnty
     * **/
    this.handlePrizeList = (e) => {
      this.editarPrivadaData.prizes_list = e.target.value;
      // this.props.updateCreatePrivateData(this.editarPrivadaData);
      this.calculateQntyPrizes(this.editarPrivadaData.prizes_list);
    };
    /**
     * Calculate qnty and list  default prizes
     * @param qntyPrizes how many prizes the user wants
     * **/
    this.calculateQntyPrizes = (qntyPrizes) => {
      if (!qntyPrizes || isNaN(qntyPrizes)) {
        return;
      }
      let newPrizesList = Object.keys(Array.apply(0, Array(Number(qntyPrizes))));
      newPrizesList = newPrizesList.map((prize, prizeIdx) => {
        let prizeModel = { ...this.crearPrivadaPremioCustom };
        // console.log('prizeModel', prizeModel);
        let currPosistion = prizeIdx + 1;
        prizeModel.position = Number(currPosistion);
        // prizeModel.percentage = 10;
        return prizeModel;
      });
      // console.log('newPrizeList', newPrizesList);
      this.setState({ listPrizes: newPrizesList }, () => {
        this.calculateListPrizes();
      });
    };
    /**
     * this function calculates the left of the custom prizes
     * **/
    this.calculateListPrizes = () => {
      console.log('%c calculateListPrizes', 'font-size:2em; color:rgb(110, 193, 113);');
      let _listPrizes = this.state.listPrizes.slice();
      console.log(_listPrizes);
      if (!_listPrizes || !_listPrizes.length) {
        return;
      }
      let sum = _.reduce(
        _listPrizes,
        (acc, curr) => {
          // console.log('acc', acc);
          // console.log('curr', curr.percentage);
          if (curr.percentage === null) {
            return (acc += 0);
          } else {
            return (acc += curr.percentage);
          }
        },
        0
      );

      console.log('customPrizeSum', sum);

      this.setState(
        {
          customPrizesSum: sum,
        },
        () => {
          //  console.log('customPrizesSum changed');
        }
      );
    };

    this.submitSearch = () => {
      console.log('Submit Search');
      console.log(this.editarPrivadaData);
      if (this.state.listPrizes.length && this.props.gameInfo.game_type === 'pool') {
        this.editarPrivadaData.pool_prizes = this.state.listPrizes;
      } else if (this.state.listPrizes.length && this.props.gameInfo.game_type === 'survivor') {
        this.editarPrivadaData.survivor_prizes = this.state.listPrizes;
      }

      if (this.props.gameInfo.game_type === 'pool') {
        API.postEditPrivate(this.editarPrivadaData)
          .then((response) => {
            this.callbackEditPrivate(response);
          })
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error, { extra: 'Error editing Quiniela Privada' });
          });
      } else if (this.props.gameInfo.game_type === 'survivor') {
        API.postEditPrivateSurvivor(this.editarPrivadaData)
          .then((response) => {
            this.callbackEditPrivateSurvivor(response);
          })
          .catch((error) => {
            console.error(error);
            Sentry.captureException(error, { extra: 'Error editing Quiniela Privada' });
          });
      }
    };

    this.setMaxPrizeQnty = (sum, prizesItems) => {
      sum = !sum ? 0 : sum;
      const left = 100 - sum;
      // console.log(left, sum);
      return left;
    };
    /**
     * Handle the value of the weeklyScheme
     * @param value is the value that changes the scheme of weekly prizes selected by the user
     * **/
    this.handleWeeklyScheme = (value) => {
      this.editarPrivadaData.weekly_prize_scheme = value;
    };

    /**
     * Handle the value of the weekly_percetage
     * @param value is the value that changes the weeklypercentage and the endpercentage
     * **/
    this.handleWeeklyPercentage = (value) => {
      this.editarPrivadaData.weekly_percentage = value;
      this.editarPrivadaData.end_percentage = 100 - value;
    };

    /**
     * Handle If it has a weekly_prize
     * @param value is the value that changes the id selected by the user
     * it also enables the data reffered to a full quiniela default data
     * **/
    this.handleWeeklyPrize = (value) => {
      const weekleyPrize = value;
      console.log('weekly PRize ', value);
      // console.log('use_tibreakers', this.crearPrivadaData.use_tibreakers );
      /**
       * Restore weekly paramentes
       * **/
      if (!weekleyPrize) {
        this.editarPrivadaData.weekly_prize = false;
        this.editarPrivadaData.use_tibreakers = false;
        this.editarPrivadaData.tiebreaker_type = null;
        this.editarPrivadaData.weekly_percentage = null;
        this.editarPrivadaData.weekly_prize_scheme = null;
        this.editarPrivadaData.end_percentage = 100;
      } else {
        this.editarPrivadaData.weekly_prize = weekleyPrize;
        this.editarPrivadaData.weekly_percentage = 50;
        this.editarPrivadaData.weekly_prize_scheme = 'first';
        this.editarPrivadaData.end_percentage = 50;
      }
    };

    /**
     * Handle the custom perecentages of the list prize_scheme
     * @param e is the event emmited,
     * @param prizeId is the position that mod the percentage
     * **/
    this.handlePrizesCustom = (e, prizeId) => {
      // console.log( 'Prizes Custom', e.target.value , prizeId );
      let temporalPrizes = this.state.listPrizes.slice();
      // console.log('temporalPrizes', temporalPrizes);
      temporalPrizes.forEach((prize, prizeIdx) => {
        // console.log('prize', prize);
        if (prizeId - 1 !== prizeIdx) {
          return;
        }
        prize.percentage = Number(e.target.value);
        // console.log('prizeId', prizeId, prize);
      });
      // console.log('temporalPrizes', temporalPrizes);
      this.setState({ listPrizes: temporalPrizes }, () => {
        this.calculateListPrizes();
      });
    };
    /**
     * This function handles the response of the post CreateQuiniela
     * @param data response data of the API call
     * **/
    this.callbackEditPrivate = (data) => {
      let callbackData = data.data;
      console.log('%c Callback Editar quiniela ', 'color:rgb(17, 186, 140); font-size:2em;');
      console.log(callbackData);
      if (callbackData.result && callbackData.result.success) {
        this.props.updatePoolInfo(callbackData.result.pool);
        this.setState({
          successfullCreation: true,
          submitting: false,
          createdGame: callbackData.result.pool,
        });
      } else {
        this.setState(
          {
            submitting: false,
            errorCreation: true,
            errors: data.errors,
          },
          () => {
            Sentry.captureException(data.erros, { extra: 'Error editing private' });
          }
        );
      }
    };
    /**
     * This function handles the response of the post EditarSurivovr
     * @param data response data of the API call
     * **/
    this.callbackEditPrivateSurvivor = (data) => {
      let callbackData = data.data;
      console.log('%c Callback Editar quiniela ', 'color:rgb(17, 186, 140); font-size:2em;');
      console.log(callbackData);
      if (callbackData.result.success) {
        this.props.updatePoolInfo(callbackData.result.survivor);
        this.setState({
          premios: callbackData.result.survivor.survivor_prizes,
          successfullCreation: true,
          submitting: false,
          createdGame: callbackData.result.survivor,
        });
      } else {
        this.setState(
          {
            submitting: false,
            errorCreation: true,
            errors: data.errors,
          },
          () => {
            Sentry.captureException(new Error(data.errors), { extra: 'Error editing private' });
          }
        );
      }
    };

    this.enableButton = () => {
      console.log('enableButton');
      this.setState({ canSubmit: true });
    };
    this.disableButton = () => {
      console.log('disableButton');
      this.setState({ canSubmit: false });
    };
  }

  componentDidMount() {
    this.initializeEditPrivada();
  }

  render() {
    let editPrize = null;
    let editPrizeScheme = null;
    let editWeeklyPrizePercentage = null;
    let conditionalComponent = null;

    // console.log(this.editarPrivadaData);

    if (this.state.mounted) {
      if (this.props.gameInfo && this.props.gameInfo.status === 'upcoming') {
        editPrizeScheme = (
          <div className="flex-col">
            <div className="flex-row ">
              <CrearQuinielaFormsy
                label={`¿Cómo quieres que se repartan los premios al final de ${
                  this.props.gameInfo.game_type === 'survivor' ? 'el survivor' : 'la quiniela'
                } ?`}
                name="gamePrizeScheme"
                defaultValue={this.props.gameInfo.prize_scheme}
                inputHandler={this.handlePrizeScheme}
                type="PRIZE_SCHEME"
              />
            </div>
          </div>
        );
        if (this.props.standings && this.props.standings.items.length === 0) {
          editPrize = (
            <div className="flex-col">
              <div className="flex-row">
                <CrearQuinielaFormsy
                  label="¿Quieres limitar los multi registros?"
                  name="limit_multientries"
                  defaultValue={this.editarPrivadaData.limit_multientries}
                  inputHandler={this.handleLimitMultientries}
                  type="LIMIT_MULTIENTRIES"
                />
              </div>
              {this.editarPrivadaData.limit_multientries ? (
                <div className="flex-row ">
                  <CrearQuinielaFormsy
                    label="Registros máximos por usuario"
                    name="maxMultientries"
                    defaultValue={this.editarPrivadaData.max_multientries}
                    inputHandler={this.handleMaxMultientries}
                    validations={('isNumeric', { minRegister: 15 })}
                    validationError="Debe ser mayor a 0"
                    type="MAX_MULTIENTRIES"
                  />
                </div>
              ) : null}
              <div className="flex-row">
                <CrearQuinielaFormsy
                  label={`Elige el nuevo precio de tú ${
                    this.props.gameInfo.game_type === 'survivor' ? 'survivor' : 'quiniela'
                  }`}
                  name="gameEntry"
                  defaultValue={this.editarPrivadaData.entry_fee}
                  inputHandler={this.handleEntryFee}
                  type="ENTRY_FEE"
                  maxLength={5}
                  validations={'isNumeric'}
                  validationError="Debes de elegir un precio"
                />
              </div>
            </div>
          );

          if (this.props.gameInfo.weekly_prize) {
            editWeeklyPrizePercentage = (
              <div className="flex-col">
                <div className="flex-row">
                  <CrearQuinielaFormsy
                    label="¿Quieres que tú quiniela tenga premios semanales?"
                    name="weeklyTie"
                    defaultValue={this.editarPrivadaData.weekly_prize}
                    inputHandler={this.handleWeeklyPrize}
                    type="WEEKLY_PRIZES"
                  />
                </div>
                {this.editarPrivadaData.weekly_prize !== true ? null : (
                  <div className="flex-row">
                    <CrearQuinielaFormsy
                      label="¿Cómo quieres que se repartan los premios semanales?"
                      name="gameWeeklyPrizeScheme"
                      defaultValue={this.editarPrivadaData.weekly_prize_scheme}
                      inputHandler={this.handleWeeklyScheme}
                      type="WEEKLY_PRIZE_SCHEME"
                    />
                  </div>
                )}
                {this.editarPrivadaData.weekly_prize === true ? (
                  <div className="flex-row">
                    <CrearQuinielaFormsy
                      label="Elige el porcentaje asignado a los premios semanales."
                      name="gameWeeklyPercentage"
                      defaultValue={this.editarPrivadaData.weekly_percentage}
                      inputHandler={this.handleWeeklyPercentage}
                      type="WEEKLY_PERCENTAGE"
                    />
                  </div>
                ) : null}
                {this.editarPrivadaData.weekly_prize ? (
                  this.editarPrivadaData.weekly_prize_scheme === 'top' ? (
                    <div className="flex-row row-no-padding">
                      <div className="flex-col col-no-padding text-center">1° - 50%</div>
                      <div className="flex-col col-no-padding text-center">2° - 30%</div>
                      <div className="flex-col col-no-padding text-center">3° - 20%</div>
                    </div>
                  ) : (
                    <div className="flex-row row-no-padding">
                      <div className="flex-col col-no-padding text-center">¡ Todo al 1° !</div>
                    </div>
                  )
                ) : null}
              </div>
            );
          }
        }
      }

      conditionalComponent = (
        <div>
          {editPrize}
          {editWeeklyPrizePercentage}
          {editPrizeScheme}
          {this.editarPrivadaData.prize_scheme === 'custom' ? (
            <div className="flex-row">
              <CrearQuinielaFormsy
                label="¿Cuántos premios quieres entregar?"
                name="gamePrizeScheme"
                maxLength={4}
                defaultValue={
                  !this.editarPrivadaData.prizes_list ? '' : this.editarPrivadaData.prizes_list
                }
                inputHandler={this.handlePrizeList}
                validations={'isNumeric'}
                validationError={'Debe de ser un número.'}
                type="PRIZES_LIST"
              />
            </div>
          ) : null}
          {this.editarPrivadaData.prizes_list !== null || this.state.listPrizes.length >= 1 ? (
            <ListaPremios
              type="input"
              notRequired={true}
              prizesSum={this.state.customPrizesSum}
              listPrizes={this.state.listPrizes}
              editarPrivadaData={this.editarPrivadaData}
              handlePrizesCustom={this.handlePrizesCustom}
              prizesQnty={this.editarPrivadaData.prizes_list}
              setMaxPrizeQnty={this.setMaxPrizeQnty}
            />
          ) : null}
        </div>
      );
    } else {
      conditionalComponent = null;
    }

    return (
      <Formsy
        onValidSubmit={(e) => {
          this.submitSearch();
        }}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <div className="flex-container justify align column">
          {this.state.successfullCreation ? (
            <div className="flex-container justify align column ">
              <i className="ion-checkmark-circled icon"></i>
              <span>
                ¡Felicidades tu{' '}
                {this.props.gameInfo.game_type === 'survivor' ? 'survivor' : 'quiniela'} se editó
                con éxito!
              </span>
            </div>
          ) : (
            conditionalComponent
          )}
          {this.state.successfullCreation ? (
            <div className="flex-row">
              <Button className="flex-col btn-pick btn-block" onClick={this.resetData}>
                {' '}
                Volver a editar{' '}
              </Button>
            </div>
          ) : (
            <WizardButtons
              gameType={this.props.gameInfo.game_type}
              editarPrivadaData={this.editarPrivadaData}
              handleCancelSelection={this.props.handleCancelSelection}
              activeSection={this.props.activeSection}
              canSubmit={this.state.canSubmit}
              prizesSum={this.state.customPrizesSum}
              nextStep={this.props.nextStep}
              prevStep={this.props.prevStep}
              submitSearch={this.submitSearch}
              submitting={this.state.submitting}
              nameExist={this.state.nameExist}
            />
          )}
        </div>
      </Formsy>
    );
  }
}

export default EditarQuinielaFormulario;
