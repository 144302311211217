import React from 'react'
import BenefitList from './BenefitList'

function LevelBenefits (props) {
  const { nextLevel } = props.userLoyalty

  const list = props.benefits[nextLevel.name] || []

  return (
    <>
      <BenefitList list={list} />
    </>
  )
}

export default LevelBenefits
