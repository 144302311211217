import React from 'react';
import { ToggleButtonGroup, ControlLabel, ToggleButton } from 'react-bootstrap/lib/';
import ReactTooltip from 'react-tooltip';

import utils from '../../utils/utils';

import { CSSTransitionGroup } from 'react-transition-group';

import baseball from '../../assets/images/BaseballBall-50.png';
import americano from '../../assets/images/AmericanoBall-50.png';
import futbol from '../../assets/images/FutbolBall-50.png';
import basketball from '../../assets/images/BasketballBall-50.png';
import tennis from '../../assets/images/TennisBall-50.png';
import golf from '../../assets/images/GolfBall-50.png';
import volante from '../../assets/images/Volante-50.png';
import cash from '../../assets/images/cash2-50.png';
import pickcoin from '../../assets/images/pickcoin2-50.png';
import pickcoinPlata from '../../assets/images/pickcoin2-50-plata.png';
import iconQuiniela from '../../assets/images/icon-quiniela.png';
import iconSurvivor from '../../assets/images/icon-survivor.png';
import iconCl from '../../assets/images/icon-cl.png';
import iconMax from '../../assets/images/icon-max.png';
import iconMax10 from '../../assets/images/icon-max10.png';
import iconParlay from '../../assets/images/icon-parlay.png';
import iconPick5 from '../../assets/images/icon-pick5.png';
import iconH2h from '../../assets/images/icon-1vs1.png';
import _ from 'underscore';

// import ListLeagues from '../ListLeagues';

class AccummulativeFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersModel: {
        sports: ['all'],
        gameType: ['all'],
        currency: ['all'],
        buyIn: ['all'],
        type: ['all'],
        league: ['all'],
        status: ['proximas'],
        isFull: [false],
        isPrivate: [false],
      },
      showFiltersMobile: false,
      ...this.props,
    };

    this.controlFiltersMobile = this.controlFiltersMobile.bind(this);

    this.handleFilterClick = (clickedCategory, value) => {
      console.log(clickedCategory, value);
      let modFilters = this.state.filtersModel;
      let stateContainAll = false;
      let valueContainAll = false;
      let newValues = [null];

      switch (clickedCategory) {
        case 'gameType':
          modFilters.gameType = [value];
          break;
        case 'currency':
          modFilters.currency = [value];
          break;
        case 'buyIn':
          modFilters.buyIn.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.buyIn = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.buyIn = ['all'];
          } else if (value.length === 0) {
            modFilters.buyIn = modFilters.buyIn;
          } else {
            modFilters.buyIn = value;
          }
          break;
        case 'type':
          modFilters.type.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.type = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.type = ['all'];
          } else if (value.length === 0) {
            modFilters.type = modFilters.type;
          } else {
            modFilters.type = value;
          }
          break;
        case 'sports':
          modFilters.sports.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.sports = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.sports = ['all'];
          } else if (value.length === 0) {
            modFilters.sports = modFilters.sports;
          } else {
            modFilters.sports = value;
          }
          break;
        case 'league':
          modFilters.league = [value];
          break;
        case 'isFull':
          // console.log( modFilters.isFull );
          modFilters.isFull = [!modFilters.isFull[0]];
          break;
        case 'isPrivate':
          modFilters.isPrivate = [value];
          break;
        case 'status':
          modFilters.status.forEach((filter) => {
            if (filter === 'all') {
              stateContainAll = true;
            }
          });
          value.forEach((val) => {
            if (val === 'all') {
              valueContainAll = true;
            }
          });
          // console.log( 'stateContainAll' , stateContainAll );
          // console.log( 'valueContainAll' , valueContainAll );

          if (stateContainAll && valueContainAll) {
            // console.log('itContainAll', value);
            newValues = _.without(value, 'all');
            // console.log(newValues );
            modFilters.status = newValues;
          } else if (valueContainAll && !stateContainAll) {
            modFilters.status = ['all'];
          } else if (value.length === 0) {
            modFilters.status = modFilters.status;
          } else {
            modFilters.status = value;
          }
          break;
      }

      this.setState({ filtersModel: modFilters }, () => {
        this.props.filterBar(this.state.filtersModel);
        this.props.updateMonto(this.state.filtersModel);
      });
    };
  }

  controlFiltersMobile() {
    this.setState((prevState) => {
      return {
        showFiltersMobile: !prevState.showFiltersMobile,
      };
    });
  }

  componentDidMount() {
    // let queryStringFilters = utils.getFilters();
    let queryStringFilters = false;
    if (queryStringFilters) {
      console.log('queryStringFilters');
      console.log(queryStringFilters);
      let tempFiltersModel = this.state.filtersModel;
      if (queryStringFilters.hasOwnProperty('sports')) {
        tempFiltersModel.sports = queryStringFilters.sports;
      }
      if (queryStringFilters.hasOwnProperty('gameType')) {
        tempFiltersModel.gameType = queryStringFilters.gameType;
      }
      if (queryStringFilters.hasOwnProperty('buyIn')) {
        tempFiltersModel.buyIn = queryStringFilters.buyIn;
      }
      if (queryStringFilters.hasOwnProperty('currency')) {
        tempFiltersModel.currency = queryStringFilters.currency;
      }
      if (queryStringFilters.hasOwnProperty('type')) {
        tempFiltersModel.type = queryStringFilters.type;
      }
      if (queryStringFilters.hasOwnProperty('league')) {
        tempFiltersModel.league = queryStringFilters.league;
      }
      if (queryStringFilters.hasOwnProperty('status')) {
        tempFiltersModel.status = queryStringFilters.status;
      }
      if (queryStringFilters.hasOwnProperty('isfull')) {
        tempFiltersModel.isFull[0] = Boolean(queryStringFilters.isfull[0]);
      }
      if (queryStringFilters.hasOwnProperty('isPrivate')) {
        tempFiltersModel.isPrivate = queryStringFilters.isPrivate;
      }

      console.log('Setting Filters from query', tempFiltersModel);
      this.setState({ filtersModel: tempFiltersModel }, () => {
        this.props.filterBar(this.state.filtersModel);
      });
    } else {
      this.props.filterBar(this.state.filtersModel);
    }
  }

  render() {
    // console.log(this);

    return (
      <div>
        {!this.state.showFiltersMobile ? (
          <div className="col-xs-12 message-sidebar" onClick={this.controlFiltersMobile}>
            <span>
              Da click aqui para mostrar los filtros
              <i className="ion-funnel"></i>
            </span>
            <i className="ion-chevron-down"></i>
          </div>
        ) : (
          <div className="col-xs-12 message-sidebar" onClick={this.controlFiltersMobile}>
            <span>
              Da click aqui para ocultar los filtros
              <i className="ion-funnel"></i>
            </span>
            <i className="ion-chevron-up"></i>
          </div>
        )}

        <div className="control-title mobile row">
          {/*<span><i className="ion-funnel"></i>FILTROS</span>*/}
        </div>
        {this.state.showFiltersMobile ? (
          <CSSTransitionGroup
            transitionName="example"
            transitionAppear={true}
            transitionAppearTimeout={200}
            transitionEnter={true}
            transitionLeave={true}
            transitionLeaveTimeout={200}
          >
            <div>
              <div className="filter-category mobile">
                <div className="control-title row">
                  <span>CONCURSO</span>
                </div>
                <ToggleButtonGroup
                  name="concurso"
                  type="radio"
                  value={this.state.filtersModel.gameType}
                  onChange={(e) => {
                    this.handleFilterClick('gameType', e);
                  }}
                >
                  <ToggleButton value={'all'} className="itemFilterMobile">
                    <span className="concurso-item concurso-all">TODOS</span>
                  </ToggleButton>
                  <ToggleButton value={'pool'} className="itemFilterMobile">
                    <span className="concurso-item concurso-all">
                      <img
                        data-tip
                        data-for="contest-quiniela"
                        src={iconQuiniela}
                        width="20"
                        height="20"
                      />
                    </span>
                    <ReactTooltip id="contest-quiniela" place="top" type="light" effect="solid">
                      Quinielas
                    </ReactTooltip>
                  </ToggleButton>
                  <ToggleButton value={'survivor'} className="itemFilterMobile">
                    <span className="concurso-item concurso-all">
                      <img
                        data-tip
                        data-for="contest-survivor"
                        src={iconSurvivor}
                        width="20"
                        height="20"
                      />
                    </span>
                    <ReactTooltip id="contest-survivor" place="top" type="light" effect="solid">
                      Survivor
                    </ReactTooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="filter-category mobile">
                <ControlLabel>DEPORTES</ControlLabel>
                <ToggleButtonGroup
                  name="deporte"
                  type="checkbox"
                  value={this.state.filtersModel.sports}
                  onChange={(e) => {
                    this.handleFilterClick('sports', e);
                  }}
                >
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'all'}
                  >
                    <span className={'deporte-item deporte-all'}>TODOS</span>
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Baseball'}
                  >
                    <img className="pool-icons filter" src={baseball} />
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Basketball'}
                  >
                    <img className="pool-icons filter" src={basketball} />
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Fútbol'}
                  >
                    <img className="pool-icons filter" src={futbol} />
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Fútbol Americano'}
                  >
                    <img className="pool-icons filter" src={americano} />
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Tenis'}
                  >
                    <img className="pool-icons filter" src={tennis} />
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Golf'}
                  >
                    <img className="pool-icons filter" src={golf} />
                  </ToggleButton>
                  <ToggleButton
                    className="sidebar-filter-button deporte itemFilterMobile"
                    value={'Volante'}
                  >
                    <img className="pool-icons filter" src={volante} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="filter-category mobile">
                <ControlLabel>TIPO</ControlLabel>
                <ToggleButtonGroup
                  name="type"
                  value={this.state.filtersModel.type}
                  onChange={(e) => {
                    this.handleFilterClick('type', e);
                  }}
                  type="checkbox"
                >
                  <ToggleButton value={'all'} className="itemFilterMobile">
                    <span className="type-item type-all">TODAS</span>
                  </ToggleButton>
                  <ToggleButton value={'abierta'} className="itemFilterMobile">
                    <img
                      data-tip
                      data-for="type-abierta"
                      src={iconQuiniela}
                      width="20"
                      height="20"
                    />
                    <ReactTooltip id="type-abierta" place="top" type="light" effect="solid">
                      Abierta
                    </ReactTooltip>
                  </ToggleButton>
                  <ToggleButton className="itemFilterMobile" value={'cupo'}>
                    <img data-tip data-for="type-cupo" src={iconCl} width="20" height="20" />
                    <ReactTooltip id="type-cupo" place="top" type="light" effect="solid">
                      Cupo Limitado
                    </ReactTooltip>
                  </ToggleButton>
                  <ToggleButton value={'max'} className="itemFilterMobile">
                    <img data-tip data-for="type-max" src={iconMax} width="20" height="20" />
                    <ReactTooltip id="type-max" place="top" type="light" effect="solid">
                      MAX
                    </ReactTooltip>
                  </ToggleButton>
                  <ToggleButton value={'parlay'} className="itemFilterMobile">
                    <img data-tip data-for="type-parlay" src={iconParlay} width="20" height="20" />
                    <ReactTooltip id="type-parlay" place="top" type="light" effect="solid">
                      Parlay
                    </ReactTooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="filter-category mobile">
                <ControlLabel>MONEDA</ControlLabel>
                <ToggleButtonGroup
                  name="moneda"
                  type="radio"
                  value={this.state.filtersModel.currency}
                  onChange={(e) => {
                    this.handleFilterClick('currency', e);
                  }}
                >
                  <ToggleButton value={'all'} className="itemFilterMobile">
                    <span className="moneda-item moneda-all">TODAS</span>
                  </ToggleButton>
                  <ToggleButton value={'real'} className="itemFilterMobile">
                    <img className="moneda-real pool-icons filter" src={cash} />
                  </ToggleButton>
                  <ToggleButton value={'pickcoin'} className="itemFilterMobile">
                    <img className="moneda-pickcoins pool-icons filter" src={pickcoin} />
                  </ToggleButton>
                  <ToggleButton value={'referral'} className="itemFilterMobile">
                    <img className="moneda-referral pool-icons filter" src={pickcoinPlata} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="dropdownfilter">
                <div className="control-title">
                  <ControlLabel>ESTADO</ControlLabel>
                  <ToggleButtonGroup
                    name="estado"
                    className="filter-category"
                    type="checkbox"
                    value={this.state.filtersModel.status}
                    onChange={(e) => {
                      this.handleFilterClick('status', e);
                    }}
                  >
                    <ToggleButton value={'all'} className="itemFilterMobile">
                      <span className="estado-item estado-all">TODOS</span>
                    </ToggleButton>
                    <ToggleButton value={'proximas'} className="itemFilterMobile">
                      <span className={'estado-item estado-proximas'}>PRÓX</span>
                    </ToggleButton>
                    <ToggleButton value={'curso'} className="itemFilterMobile">
                      <span className={'estado-item estado-curso'}>EN CURSO</span>
                    </ToggleButton>
                    <ToggleButton value={'finalizadas'} className="itemFilterMobile">
                      <span className={'estado-item estado-all'}>CERRADAS</span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div className="flex-row">
                <div className=" flex-col filter-category mobile">
                  <div className="control-title row">
                    <span>LLENA</span>
                  </div>
                  <ToggleButtonGroup
                    name="llena"
                    type="radio"
                    value={this.state.filtersModel.isFull}
                    onChange={(e) => {
                      this.handleFilterClick('isFull', e);
                    }}
                  >
                    <ToggleButton value={true} className="itemFilterMobile">
                      SI
                    </ToggleButton>
                    <ToggleButton value={false} className="itemFilterMobile">
                      NO
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {!utils.isLogged() || !utils.isSuPick() ? null : (
                  <div className=" flex-col filter-category mobile">
                    <div className="control-title row">
                      <span>PRIVADAS</span>
                    </div>
                    <ToggleButtonGroup
                      name="privadas"
                      type="radio"
                      value={this.state.filtersModel.isPrivate}
                      onChange={(e) => {
                        this.handleFilterClick('isPrivate', e);
                      }}
                    >
                      <ToggleButton value={true}>SI</ToggleButton>
                      <ToggleButton value={false}>NO</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                )}
              </div>
            </div>
          </CSSTransitionGroup>
        ) : null}
      </div>
    );
  }
}

class FilterControlsMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeBar: this.props.typeBar,
      tournament: this.props.setTitle,
      pickcoinsActive: false,
    };
    this.updateMontoIcons = this.updateMontoIcons.bind(this);
  }

  updateMontoIcons(filtersModel) {
    let pickcoinsActive = filtersModel.currency[0] === 'pickcoin';

    this.setState({
      pickcoinsActive: pickcoinsActive,
    });

    // console.log('Updating Monto...' , this.state.pickcoinsActive);
  }

  componentDidMount() {
    this.setState({
      pools: this.props.pools,
    });
  }

  render() {
    let conditionalComponent;
    conditionalComponent = (
      <div className="filters-col-mobile">
        <AccummulativeFilters
          {...this.props}
          {...this.state}
          filterBar={this.props.filterBar}
          updateMonto={this.updateMontoIcons}
        ></AccummulativeFilters>
      </div>
    );
    return conditionalComponent;
  }
}

export default FilterControlsMobile;
