import moment from 'moment';
import FlipClock from 'flipclock/compiled/flipclock';
import flipStyles from '../../assets/stylesheets/overrides/flip-clock.scss';
import $ from 'jquery';
const d3Timer = require('d3-timer');
let actionSuccess = { success: true };
let actionError = { success: false, error: new Error('Action Failed') };

const ClockActions = {
  interval: null,
  isLoading: (dispatch, status) => {
    try {
      dispatch({ type: 'isLoading', payload: { isLoading: status } });
      return actionSuccess;
    } catch (err) {
      actionError.error = err;
      return actionError;
    }
  },
  stop: () => {
    if (ClockActions.interval) {
      ClockActions.interval.stop();
    } else {
      // console.error("Interval Doesn't exist")
    }
  },
  emptyTimers: (dispatch) => {
    dispatch({ type: 'emptyTimers' });
  },
  initializeClock: async (dispatch) => {
    // console.log( 'Initialize Clock' );
    dispatch({ type: 'isLoading', payload: { isLoading: true } });
    try {
      //This is the main interval that handles every time in the pageWrapper
      ClockActions.interval = d3Timer.interval((elapsed) => {
        dispatch({ type: 'updateElapsed', payload: { elapsed } });
        ClockActions.isLoading(dispatch, false);
      }, 1000);

      return actionSuccess;
    } catch (err) {
      actionError.error = err;
      return actionError;
    }
  },
  stopTimer: (
    poolId,
    fixtureId,
    timer,
    type,
    updateStartedFixture,
    changeStatusButton,
    changeStatusPool
  ) => {
    //console.log('Stop this timer', poolId, fixtureId);
    try {
      if (type === 'row' && updateStartedFixture) {
        // console.log( 'Update Started Fixture' );
        updateStartedFixture(fixtureId);
      }
      if (type === 'row' && changeStatusPool) {
        // console.log( 'Change status of fixture', poolId, fixtureId );
        changeStatusPool(poolId, fixtureId);
      }
      if (type === 'row-stopwatch' && changeStatusButton) {
        changeStatusButton();
      }
      return actionSuccess;
    } catch (err) {
      console.error(err);
      actionError.error = err;
      return actionError;
    }

    //  timer.timerInstance.stop();
    return;
  },
  updateTimer: (dispatch, timer) => {
    try {
      // console.log( 'Timer', timer );
      dispatch({ type: 'instanceOfTimer', payload: { timer: timer } });
      return actionSuccess;
    } catch (err) {
      console.error(err);
      actionError.error = err;
      return actionError;
    }
  },
  calculateCountdown: async (dispatch, endDate, id) => {
    // console.log( '%c -----CalculateCountdown-----','color: #e0df04;font-size:2em;'  );
    // console.log(  endDate );
    try {
      let closing = moment(endDate);
      let now = moment();
      let diff = Math.floor((closing - now) / 1000);
      // diff = closing.diff(now, 'miliseconds');

      // console.log( closing , now , diff );
      // console.log(leftTime);
      const timeLeft = {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
        millisec: 0,
      };

      if (diff > 0) {
        if (diff > 0x7fffffff) {
          diff = 0x7fffffff;
        }

        if (diff >= 365.25 * 86400) {
          // 365.25 * 24 * 60 * 60
          timeLeft.years = Math.floor(diff / (365.25 * 86400));
          diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) {
          // 24 * 60 * 60
          timeLeft.days = Math.floor(diff / 86400);
          diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
          // 60 * 60
          timeLeft.hours = Math.floor(diff / 3600);
          diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
          timeLeft.min = Math.floor(diff / 60);
          diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        // console.log( timeLeft );

        dispatch({
          type: 'countdown',
          payload: { countdown: timeLeft, timeLeft: diff * 1000, id: id },
        });
        return actionSuccess;
      } else {
        //console.error('no hay diff', diff);
        timeLeft.sec = 0;
        dispatch({ type: 'countdown', payload: { countdown: timeLeft, timeLeft: 0, id: id } });

        actionSuccess.data = { isFinished: true };
        return actionSuccess;
      }
    } catch (err) {
      console.error('HERE');
      console.error(err);
      actionError.error = err;
      return actionError;
    }
  },
  initFlipClock: (dispatch, endDate, poolId, fixtureId, changeStatusPool, updateClosedQuiniela) => {
    let pastDate = endDate;
    pastDate = moment(pastDate);
    let diff = pastDate / 1000 - moment() / 1000;

    window.$('.flipClock').FlipClock(diff, {
      clockFace: 'DailyCounter',
      countdown: true,
      language: 'spanish',
      callbacks: {
        stop: () => {
          console.log('Se Cerro');
          if (changeStatusPool !== undefined) {
            if (dispatch) {
              changeStatusPool(dispatch);
            } else {
              changeStatusPool(poolId, fixtureId);
            }
          }

          if (updateClosedQuiniela !== undefined) {
            updateClosedQuiniela();
          }
        },
      },
    });
  },
};

export default ClockActions;
