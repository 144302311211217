import React, { Component } from 'react';
import Utils from '../../utils/utils';
import numeral from 'numeral';
import {
  Form,
  FormGroup,
  Button,
  ButtonGroup,
  FormControl,
  HelpBlock,
  ControlLabel,
  Modal,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
} from 'react-bootstrap/lib';

import TransferFormulario from './TransferFormulario';
import API from '../../utils/api';

import pickoins from '../../assets/images/pickcoin1-50.png';
import cash from '../../assets/images/cash1-50.png';

const ExchangeControls = ({
  changePercentage,
  changeQuantity,
  convertedPickoins,
  exchangePercentage,
  quantityExchange,
}) => {
  return (
    <FormGroup id="exchange-controls">
      <div className="row">
        <ControlLabel>¿Cuánto quieres canjear?</ControlLabel>
        <ButtonToolbar className="porcentajes-exchange">
          <ToggleButtonGroup
            type="radio"
            name="exchangeQuantity"
            value={exchangePercentage}
            onChange={changePercentage}
          >
            <ToggleButton value={10} className="btn-formulario">
              10%
            </ToggleButton>
            <ToggleButton value={25} className="btn-formulario abonar">
              25%
            </ToggleButton>
            <ToggleButton value={50} className="btn-formulario abonar">
              50%
            </ToggleButton>
            <ToggleButton value={75} className="btn-formulario abonar">
              75%
            </ToggleButton>
            <ToggleButton value={100} className="btn-formulario abonar">
              100%
            </ToggleButton>
            {/* <ToggleButton value={'other'} className="btn-formulario abonar">Otra Cantidad</ToggleButton> */}
          </ToggleButtonGroup>
        </ButtonToolbar>
      </div>
      <div className="row quantity-balance">
        <div className="col-xs-5">
          <FormControl
            type="number"
            step="200"
            value={quantityExchange}
            onChange={changeQuantity}
            minLength={3}
          />
        </div>
        <div className="col-xs-2 text-center">
          <i className="ion-arrow-right-a"></i>
        </div>
        <div className="col-xs-5">
          <span className="converted-balance">
            <img src={cash} className="balance-icons" />
            <span className="balance-real converted">
              ${numeral(convertedPickoins).format('0,000.0')}
              <small>{'MXN'}</small>
            </span>
          </span>
        </div>
      </div>
    </FormGroup>
  );
};

class TransferRealToUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availablePc: null,
      availableReal: null,
      isTransferEnabled: false,
      maxForTransfer: 0,
    };
  }

  // = ({balance, updateUserInfo, closePanel}) => {

  componentDidMount() {
    let userData = Utils.getUserInfo();
    API.isTransfersEnabled().then((response) => {
      this.setState({
        availablePc: userData.balancePc,
        availableReal: userData.balanceReal,
        isTransferEnabled: response.data.enabled,
        maxForTransfer: response.data.maxForTransfer,
      });
    });
  }
  render() {
    if (this.props.type === 'modal') {
      return (
        <div className="transactionPanel">
          <div id="panelTitle" className="panel-abonar-titulo">
            <span>Transfiere Saldo</span>
            <button className="cerrar-panel" onClick={this.props.closePanel}>
              <div className="btn-icon-close">
                <i className="ion-ios-close-empty"></i>
              </div>
            </button>
          </div>
          <div className="exchangeContainer col-xs-10 col-xs-offset-1">
            <div className="subtitulo-user-info text-center">
              <strong className="text-center">
                {' '}
                Transfiere de tu saldo retirable a otros usuarios de pickwin.
              </strong>
            </div>
            <div className="flex-row row-center current-balance">
              <span className=""> Saldo disponible para transferir:</span>
              <span className="balance-real flex-container start align">
                <img src={cash} style={{ height: '14px' }} />
                <span style={{ fontSize: '14px' }}>
                  {numeral(this.state.maxForTransfer/100).format('$0,000')} MXN
                </span>
              </span>
            </div>
            {!this.state.maxForTransfer || (this.state.maxForTransfer / 100) < 1 ? (
              <div className="col-xs-12">
                {' '}
                <div
                  className="text-center"
                  style={{ color: '#f9cc30', margin: '10px 0', fontWeight: 'bold' }}
                >
                  {' '}
                  No tienes suficiente saldo para transferir.
                </div>{' '}
              </div>
            ) : (
              <div className="col-xs-10 col-xs-offset-1">
                {!this.state.isTransferEnabled ? (
                  <div
                    className={`flex-row row-no-padding row-center ${
                      !this.state.isTransferEnabled ? 'pickwin-feature-overlay' : ''
                    }`}
                  >
                    <div className=" flex-container justify align column">
                      <span className="noteTransferDisabled text-center">
                        {' '}
                        Para desbloquear esta función invita a 3 amigos a que depositen y jueguen en
                        Pickwin.
                      </span>
                      <div className="flex-row">
                        <Button
                          href="/usuario/invitados"
                          className="flex-col col-80 col-offset-10 btn-pick"
                        >
                          {' '}
                          Invita Amigos{' '}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className={`exchangeContainer ${!this.state.isTransferEnabled ? 'blurThis' : ''}`}
                >
                  <TransferFormulario
                    updateUserInfo={this.props.updateUserInfo}
                    updateUserBalance={this.props.updateUserBalance}
                    updateUserAvailable={this.props.updateUserAvailable}
                    availableCashout={this.props.availableCashout}
                    maxForTransfer={this.state.maxForTransfer}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="category-user flex-row canjear">
          <div className="info-historial">
            <div className="titulo-user-info">
              <i className="title-icon ion-arrow-swap"></i>
              <span>Transferir Saldo</span>
            </div>
            <div className="flex-row row-no-padding subtitulo-user-info">
              <span className="flex-col col-center text-center">
                {' '}
                Transfiere de tu saldo retirable a otros usuarios de pickwin.
              </span>
            </div>
            <div className="flex-container  jusitify align column current-balance">
              <div className="flex-row row-center text-center">{' '}Saldo disponible para transferir:
                <span>
                  <img src={cash} style={{ height: '14px' }} />
                  <span style={{ color: '#78bc28', fontSize: '14px' }}>
                    {numeral(this.state.maxForTransfer/100).format('$0,000')} MXN
                  </span>
                </span>
              </div>
            </div>
          </div>
          {!this.state.maxForTransfer || (this.state.maxForTransfer / 100) < 1 ? (
            <div className="col-xs-12">
              {' '}
              <div
                className="text-center"
                style={{ color: '#f9cc30', margin: '10px 0', fontWeight: 'bold' }}
              >
                {' '}
                No tienes suficiente saldo para transferir.
              </div>{' '}
            </div>
          ) : (
            <div className="col-xs-10 col-xs-offset-1">
              {!this.state.isTransferEnabled ? (
                <div
                  className={`flex-row row-no-padding row-center ${
                    !this.state.isTransferEnabled ? 'pickwin-feature-overlay' : ''
                  }`}
                >
                  <div className=" flex-container justify align column">
                    <span className="noteTransferDisabled text-center">
                      {' '}
                      Para desbloquear esta función invita a 3 amigos a que depositen y jueguen en
                      Pickwin.
                    </span>
                    <div className="flex-row">
                      <Button
                        href="/usuario/invitados"
                        className="flex-col col-80 col-offset-10 btn-pick"
                      >
                        {' '}
                        Invita Amigos{' '}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                className={`exchangeContainer ${!this.state.isTransferEnabled ? 'blurThis' : ''}`}
              >
                <TransferFormulario
                  updateUserInfo={this.props.updateUserInfo}
                  updateUserBalance={this.props.updateUserBalance}
                  updateUserAvailable={this.props.updateUserAvailable}
                  availableCashout={this.props.availableCashout}
                  maxForTransfer={this.state.maxForTransfer}
                />
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default TransferRealToUser;
