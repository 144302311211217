import React from 'react';
import billete from '../../assets/images/cash1-50.png';
import numeral from 'numeral';
import API from '../../utils/api';
import AbonarFormsy from '../AbonarFormsy';
import Formsy from 'formsy-react';
import utils from '../../utils/utils';
import {
  Modal,
} from 'react-bootstrap/lib';
import Button from '../Form/Button'

import { publish, subscribe, unsubscribe } from '../../utils/events'


import gaUtils from '../GAWrapper';

const DepositResponse = ({
  showDepositResponse,
  closeDepositResponse,
  paymentResponse,
  error,
  nick,
  newUserBalance,
}) => {
  //let montoDeposito = 0;

  return (
    <Modal
      show={showDepositResponse}
      className="responseDepositModal absoluteModal"
      dialogClassName="responseDepositDialog"
      backdropClassName="responseDepositBackdrop"
    >
      <div className="transactionPanel">
        <div id="panelTitle" className={`panel-abonar-titulo ${error ? 'failed' : ''}`}>
          {error ? (
            <span>Tenemos un problema.</span>
          ) : (
              <span>!Tú depósito se realizó con exito!</span>
            )}
          <button className="cerrar-panel" onClick={closeDepositResponse}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty"></i>
            </div>
          </button>
        </div>
        <div className="toditoPanelResponse">
          {!error ? (
            <span className="icon-success flex-container justify align column">
              <i className="ion-checkmark-circled"></i>
              <strong>{nick} tu balance actual es: </strong>
            </span>
          ) : (
              <span className="flex-container justify align column">
                <i className="icon-failed  ion-close-circled"></i>
                <span>{nick} tenemos un problema con tu pago</span>
              </span>
            )}
          <div
            className="flex-col col-80 col-offset-10 col-no-padding text-center transaction-success"
            style={{ marginBottom: '15px' }}
          >
            {!error ? (
              <div className="flex-col">
                <div className="flex-container justify align " style={{ marginBottom: '10px' }}>
                  <img src={billete} className="pool-icons" />
                  <span className="text-container" style={{ fontSize: '1.5em', color: '#78bc28' }}>
                    ${numeral(newUserBalance / 100).format('0,000.00')}
                    <small>MXN</small>
                  </span>
                </div>
                {paymentResponse ? (
                  <div className="flex-container justify align">
                    <div className="flex-col col-no-padding text-center transaction-success">
                      <div className="flex-row ">
                        <div className="flex-col col-center text-center">
                          <strong> Número de Transacción de Todito: </strong>{' '}
                          <span> {paymentResponse.idTranTodito} </span>
                        </div>
                      </div>
                      <div className="flex-row ">
                        <div className="flex-col col-center text-center">
                          <strong> Número de Contrato: </strong>{' '}
                          <span> {paymentResponse.idContrato} </span>
                        </div>
                      </div>
                      <div className="flex-row ">
                        <div className="flex-col col-center text-center">
                          <strong> Número de Transacción: </strong>{' '}
                          <span> {paymentResponse.idTran} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
                <div className="flex-container column text-center align justify">
                  <strong>Error: {error.code} </strong>
                  <span>
                    {error.message ? error.message : error.length > 1 ? error : 'Algo no anda bien'}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

class PrepagoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDataModel: {
        amount: 4,
        other_amount: null,
        toditoCashData: {
          card_number: null,
          nip: null,
        },
      },
      canSubmit: false,
      submitting: false,
      requestStatus: null,
      fieldsWithErrors: null,
    };

    this.convertToPesos = (cant, otraCantidad) => {
      // console.log(cant, otraCantidad);
      switch (cant) {
        case 2:
          return '$50.00';
          break;
        case 4:
          return '$200.00';
          break;
        case 5:
          return '$100.00';
          break;
        case 7:
          return '$500.00';
          break;
        case 'other':
          return `$${otraCantidad}.00`;
          break;
      }
    };

    this.handleCardData = (e, category) => {
      // console.log( e, category);
      let temporalPaymentData = this.state.paymentDataModel;
      temporalPaymentData.toditoCashData[category] = e.target.value;

      console.log('Payment Model');
      console.log(temporalPaymentData);

      this.setState({
        cardData: temporalPaymentData,
      });
    };

    this.closeResponse = () => {
      this.setState({ showResponse: false, error: null });
    };

    this.enableButton = () => {
      this.setState({ canSubmit: true });
    };

    this.disableButton = () => {
      let fieldsWithErrors = [];
      // console.log('Invalid Form');
      // console.log(this.refs.form);
      this.refs.form.inputs.forEach((input) => {
        let fieldObject = { name: null, errorMessage: null };
        // console.log(input.props.name);
        // console.log('pristine:',input.isPristine());
        // console.log('valid:',input.isValid());
        if (
          !input.isPristine() &&
          !input.isValid() &&
          (input.props.name === 'expiration_month' ||
            input.props.name === 'exp_year' ||
            input.props.name === 'cvv2')
        ) {
          fieldObject.name = input.props.name;
          fieldObject.errorMessage = input.props.validationError;
          fieldsWithErrors = [...fieldsWithErrors, fieldObject];
        }
      });
      //  console.log(  'fieldsWithErrors', fieldsWithErrors );
      this.setState({ canSubmit: false, fieldsWithErrors: fieldsWithErrors });
    };

    this.showPaymentResponse = (data) => {
      console.log(data);
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      this.setState(
        {
          submitting: false,
          showResponse: true,
          newBalance: data.balance,
          depositResponse: data.response,
        },
        () => {
          let userData = utils.getUserInfo();
          if (userData.is_new) {
            console.log('%c Is New And Deposit!!', 'color: #629022; font-size:2em;');
            console.log(this.props);
            if (this.props.handleNewUserWithDeposit) {
              this.props.handleNewUserWithDeposit(true);
            }
          }
          const transaction = {
            amount: data.amount,
            provider: 'Todito',
            transactionId: data.response.idTran,
          };
          console.log('send this transaction', transaction);
          API.trackPurchaseSuccessFB(transaction);

          gaUtils.trackUserPurchase(transaction);

          const balanceObj = data.balance;
          if (this.props.updateUserBalance) {
            this.props.updateUserBalance(balanceObj);
            if (this.props.dispatch) {
              this.props.updateUserBalance(this.props.dispatch, balanceObj);
            } else {
              this.props.updateUserBalance(balanceObj);
            }
          }
        }
      );
    };

    this.showPaymentError = (error, code) => {
      console.log(error);
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      this.setState({
        submitting: false,
        showResponse: true,
        error: { message: String(error), code: code },
      });
    };

    this.fakeSubmitAPI = (data) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ success: true, newBalance: 999999 });
          // reject( { success: false, error: { status: 500, message: 'Algo no anda bien' } } );
          // resolve( { success: false, error: { status: 500, message: 'Algo no anda bien' } } );
        }, 800);
      });
    };

    this.callbackPayment = (data) => {
      console.log(data);
      if (data.hasOwnProperty('amount') && data.hasOwnProperty('balance')) {
        this.showPaymentResponse(data);
      } else {
        this.showPaymentError(data.error, data.code);
      }
    };

    this.handleDepositoCantidad = (eventKey) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;

      temporalPaymentDataModel.other_amount = eventKey === 'other' ? '300' : null;
      temporalPaymentDataModel.amount = eventKey;
      console.log(temporalPaymentDataModel);

      this.setState({ paymentDataModel: temporalPaymentDataModel });
    };

    this.handleOtraCantidad = (e) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;

      temporalPaymentDataModel.other_amount = e.target.value.replace(/[e,E]/, '');
      console.log(temporalPaymentDataModel);
      this.setState({ paymentDataModel: temporalPaymentDataModel });
    };

    this.submitPaymentAwaitSMS = () => {
      this.submitPayment()
    }

    subscribe('SMS:ACOUNT_VERIFIED', this.submitPaymentAwaitSMS)

    this.submitPayment = () => {
      const userData = utils.getUserInfo()

      if (!userData.identity_verified) {
        return publish('SMS:SHOW_MODAL')
      }

      console.log('submitPayment', this.state.paymentDataModel);
      this.setState({ submitting: true });
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      API.postPurchaseTodito(this.state.paymentDataModel)
        .then((response) => {
          console.log(response);
          this.callbackPayment(response.data);
        })
        .catch((err) => {
          console.error(err);
          this.showPaymentError(err);
        });
    };
    this.resetData = () => {
      this.setState({
        requestStatus: null,
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.amount !== this.props.amount ||
      prevProps.other_amount !== this.props.other_amount
    ) {
      let newPaymentData = this.state.paymentDataModel;

      newPaymentData.amount = this.props.amount;
      newPaymentData.other_amount = this.props.other_amount;
      this.setState({ paymentDataModel: newPaymentData });
    }
  }
  componentDidMount() {
    let newPaymentData = this.state.paymentDataModel;

    newPaymentData.amount = this.props.amount ? this.props.amount : null;
    newPaymentData.other_amount = this.props.other_amount ? this.props.other_amount : null;

    this.setState({
      paymentDataModel: newPaymentData,
      mounted: true,
    });
  }

  componentWillUnmount () {
    unsubscribe('SMS:ACOUNT_VERIFIED', this.submitPaymentAwaitSMS)
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    // console.log( this.state.fieldsWithErrors )
    return (
      <React.Fragment>
        <DepositResponse
          dispatch={this.props.dispatch}
          showDepositResponse={this.state.showResponse}
          closeDepositResponse={this.closeResponse}
          nick={utils.getUserInfo().nick}
          newUserBalance={this.state.newBalance}
          paymentResponse={this.state.depositResponse}
          error={this.state.error}
          updateUserInfo={this.props.updateUserInfo}
        />

        <Formsy
          ref="form"
          onValidSubmit={(e) => {
            this.submitPayment(e);
          }}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <div className="flex-col col-no-padding mt-2" id="card-info">
            {this.props.depositType === 'TODITO' ? (
              <div className='flex-container justify align'>
                <div style={{width: '100%', margin: '0px 5px -15px 0px', height: '105px'}}>
                  <AbonarFormsy
                    type={'CARD_NUMBER'}
                    isTodito={true}
                    name={'card_number'}
                    placeholder='NÚMERO DE TARJETA'
                    required
                    validationError={'El número de tu tarjeta debe de ser de 10 dígitos mínimo'}
                    validations={('isNumeric', { minLength: 10, maxLength: 10 })}
                    inputHandler={this.handleCardData}
                  />
                </div>
                <div style={{width: '100%', margin: '0px 5px 0px 0px', height: '90px'}}>
                  <AbonarFormsy
                    type={'CARD_NIP'}
                    name={'nip'}
                    required
                    placeholder='NIP'
                    validationError={'El NIP consta de 4 dígitos'}
                    validations={('isNumeric', { minLength: 4, maxLength: 4 })}
                    inputHandler={this.handleCardData}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex-row row-no-padding">
            <Button
              onClick={this.submitPayment}
              type="submit"
              className='btn-pick btn btn-sm btn-default'
              bgColor='#88ba44'
              style={{
                maxWidth: 350,
                height: 35,
                margin: '1em auto'
              }}
              disabled={
                !this.state.canSubmit || this.state.submitting || this.props.errorOtraCantidad
                  ? true
                  : false
              }
            >
              <span className="flex-col col-center text-center">
                {this.state.submitting ? (
                  <div className="flex-container justify align">
                    <i className="ion-load-a loading small"></i>
                  </div>
                ) : (
                    <div className="flex-col">
                      <i className="ion-cash mr-1"></i>
                      {`Abonar ${this.convertToPesos(
                        this.state.paymentDataModel.amount,
                        this.state.paymentDataModel.other_amount
                      )}`}
                    </div>
                  )}
              </span>
            </Button>
          </div>
        </Formsy>
      </React.Fragment>
    );
  }
}

export default PrepagoForm;
