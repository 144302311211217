import React from 'react'
import QuinielaName from './QuinielaName'
import QuinielaSport from './QuinielaSport'
import QuinielaDuration from './QuinielaDuration'
import QuinielaPrice from './QuinielaPrice'
import QuinielaAwards from './QuinielaAwards'
import QuinielaSuccess from './QuinielaSuccess'

export default function QuinielaPrivate () {
  return (
    <>
      <QuinielaSport />
      <QuinielaName />
      <QuinielaDuration />
      <QuinielaPrice />
      <QuinielaAwards />
      <QuinielaSuccess />
    </>
  )
}
