import React, { useRef, useState, useEffect } from 'react'
import propTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Icon as Iconify } from '@iconify/react'

// Hooks
import useClickOutside from '../../hooks/useClickOutside'

const listCountries = [
  { country: 'Mexico', ccode: 'MX', code: '52', flag: '/images/Icono bandera México.png' },
  { country: 'Argentina', ccode: 'AR', code: '54', flag: '/images/Icono bandera México.png' },
  { country: 'Brazil', ccode: 'BR', code: '55', flag: '/images/Icono bandera Brasil.png' }
]

const countries = listCountries.reduce((prev, next) => {
  return {
    ...prev,
    [next.country]: next
  }
}, {})

// Failed prop type: Invalid prop `value` of type `object` supplied to `SelectCountry`, expected `string`.
SelectCountry.propTypes = {
  // List of Countries eg: [ 'Mexico', 'Brazil' ]
  options: propTypes.array,
  // Phone code selected
  onChange: propTypes.func
}

SelectCountry.defaultProps = {
  options: []
}

export default function SelectCountry (props) {
  const menuRef = useRef()
  const [showOptions, setShowOptions] = useState(false)
  useClickOutside(menuRef, () => setShowOptions(false))

  useEffect(() => {
    if (props.options.length === 1) {
      // Auto select the only option
      if (typeof props.onChange === 'function') {
        props.onChange(countries[props.options[0]])
      }
    }
  }, [])

  const handleClick = (event) => {
    // Open Show menu only if options are more than one
    if (props.options.length > 1) {
      setShowOptions(true)
    }
  }

  const handleSelect = (event, data) => {
    event.stopPropagation()
    console.log(data)
    setShowOptions(false)
    if (typeof props.onChange === 'function') {
      props.onChange(data)
    }
  }

  return (
    <Box className={props.className} onClick={handleClick}>
      {props.label && (
        <Label>{props.label}</Label>
      )}
      <Field style={props.style} focus={showOptions} className={props.className}>
        {props.value && (
          <Icon src={props.value.flag} />
        )}

        <Input>
          {props.value
            ? <span>{props.value.country}</span>
            : <span>Seleccionar País</span>}
        </Input>
      </Field>
      {showOptions && (
        <Menu ref={menuRef}>
          {props.options.map(name => (
            <Item onClick={(event) => handleSelect(event, countries[name])}>
              <Icon src={countries[name].flag} />
              <span>{name}</span>
            </Item>
          ))}
        </Menu>
      )}
    </Box>
  )
}

const Label = styled.label`
  font-size: 0.8em;
  padding-left: 1em;
  margin-top: 1.5em;
  opacity: 0.5;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;
`

const showMenu = keyframes`
  to {
    transform: scale(1);
  }
`

const Menu = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
  min-height: 100px;
  background-color: #1d1f21;
  border: 1px solid #83ba4e;
  border-radius: 0.3em;
  transform-origin: 0% 100%;
  transform: scale(0);
  animation: ${showMenu} 300ms ease forwards;
`

const Item = styled.div`
  display: flex;
  padding: 1em;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const Field = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-bottom: 2px solid #78bc28;
  padding: 0.5em;
  box-shadow: ${props => props.focus ? '0px 13px 20px -8px #83ba4e8c' : '0px 9px 24px 1px transparent'};
  transition: box-shadow 500ms ease;
  will-change: box-shadow;
  position: relative;
  overflow: hidden;
  margin: 0 0.2em;
  width: auto;
`

const Icon = styled.img`
  height: 1.4em;
  color: #FFF;
  margin-right: 1em;
`

const Input = styled.div`
  border: none;
  outline: none;
  font-size: 1.2em;
  background-color: transparent;
  flex-grow: 2;
`
