import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@tanstack/react-query'

// Components
import PromotionDetails from './PromotionDetails'
import BannerPromotions from './PromotionsBanner'
import PromotionCard from './PromotionCard'
import PageWrapper from '../PageWrapper'
import Loading from '../Loading'

// Utils
import API from '../../utils/api'

// Styes
import './promotions.scss'
import _ from 'underscore'

const isProduction = process.env.NODE_ENV === 'production'


const getNormalizedPromotions = (data) => {
  if (!data) return []

  const promotionsNormalized = Object.entries(data).map(([id, data]) => ({ id, ...data }))

  return promotionsNormalized.filter((promotion) => promotion && promotion.enabled)
}

const Promotions = () => {
  const { isLoading, data: { data } = {} } = useQuery(['promotions'], () => {
    return isProduction ? API.fetchPromotions() : API.fetchTestPromotions()
  })

  const [detailsItem, setDetailsItem] = useState({})
  const promotions = getNormalizedPromotions(data)

  return (
    <PageWrapper>
      <PromotionDetails
        isOpen={!_.isEmpty(detailsItem)}
        onClose={setDetailsItem}
        promotion={detailsItem}
      />
      <div className="container">
        <Content>
          <BannerPromotions />
          <PromotionsContainer className="flex-row row-wrap responsive-md">
            { isLoading && (<Loading/>)}
            { !isLoading && (
               promotions.map((promotion, index) => (
                <PromotionCard
                  key={index}
                  promotion={promotion}
                  onShowDetails={setDetailsItem}
                />
               ))
            )}
          </PromotionsContainer>
        </Content>
      </div>
    </PageWrapper>
  )
}

export default Promotions;

const PromotionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background-color: #000000cb;
  backdrop-filter: blur(6px);
  padding: 3em  2em !important;
  border-radius: 0.4em;
  align-items: flex-start;
  box-shadow: 0px 1px 5px 0px #000000 inset;
  min-height: 60vh;

  @media screen and (min-width: 800px) {
    align-items: flex-start;
  }
`

const Content = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`
