import React from 'react';

import Formsy from 'formsy-react';
import {
  Form,
  Button,
  ButtonGroup,
  FormGroup,
  ControlLabel,
  Checkbox,
  FormControl,
  HelpBlock,
} from 'react-bootstrap/lib';
import API from '../../utils/api';

import { ErrorMessage } from '../../utils/quinielasComponents';
import BuscarQuinielaFormsy from './BuscarQuinielaFormsy';
import * as Sentry from '@sentry/browser';

const SelectGameType = ({ handleGameType, gameType }) => {
  return (
    <div className="flex-row">
      <ButtonGroup className="private-controls-buttons flex-container justify center">
        <Button
          className={`flex-col rounded font-chivo ${gameType === 'pool' ? 'active' : ''} `}
          onClick={() => {
            handleGameType('pool');
          }}
        >
          Quiniela
        </Button>
        <Button
          className={`flex-col rounded font-chivo ${gameType === 'survivor' ? 'active' : ''} `}
          onClick={() => {
            handleGameType('survivor');
          }}
        >
          Survivor
        </Button>
      </ButtonGroup>
    </div>
  );
};

class BuscarQuinielaPrivada extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPanel: false,
      gameName: '',
      gamePass: '',
      gameType: null,
      submitting: false,
      canSubmit: false,
      errorMessage: '',
      searchError: false,
    };

    this.handleGameType = (value) => {
      this.setState({
        gameType: value,
      });
    };

    this.enableButton = () => {
      this.setState({ canSubmit: true });
    };

    this.disableButton = () => {
      this.setState({ canSubmit: false });
    };

    this.resetData = () => {
      this.setSTate({ gameName: '', gamePass: '', canSubmit: false, submitting: false });
    };

    this.callbackSearch = (data) => {
      console.log('callbackSearch');
      console.log(data);
      if (data.success) {
        window.location = `/${data.pool.game_type === 'pool' ? 'quiniela' : 'survivor'}/${
          data.pool.slug
        }/${data.pool.id}/${!data.pool.first_entry ? '' : data.pool.first_entry.id}`;
      } else {
        console.log('not success');
        this.setState({ searchError: true, errorMessage: data.error, submitting: false });
      }
    };
    this.getNameQuina = (e) => {
      this.setState({ gameName: e.target.value, searchError: false, errorMessage: '' });
    };
    this.getPassQuina = (e) => {
      this.setState({ gamePass: e.target.value, searchError: false, errorMessage: '' });
    };

    this.getAvailabilityName = (name) => {
      console.log('Check Availability');
      this.setState({ submitting: true });
      API.fetchNamePrivateStatus(this.state.gameName)
        .then((response) => {
          this.setState({ nameExist: !response.data.available, searchingName: false });
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error, {
            extra: 'Error checking availability name in survivor private',
          });
        });
    };

    /**
     * This function post the data fo search the private game
     */
    this.submitSearch = () => {
      this.setState({ submitting: true });
      const formData = {
        name: this.state.gameName,
        password: this.state.gamePass,
      };
      console.log(formData);
      if (this.state.gameType === 'pool') {
        API.postSearchPrivate(formData).then((response) => {
          this.callbackSearch(response.data);
        });
      } else if (this.state.gameType === 'survivor') {
        API.postSearchPrivateSurvivor(formData).then((response) => {
          this.callbackSearch(response.data);
        });
      }
    };
  }

  render() {
    if (this.props.showPanel) {
      return (
        <Formsy
          onValidSubmit={(e) => {
            this.submitSearch();
          }}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          {!this.state.gameType ? (
            <div className="privadas-container">
              <div className="flex-container justify align">
                <span className="intro-copy">
                  <i className="ion-search" /> ¿Que tipo de juego quieres buscar?
                </span>
              </div>
              <SelectGameType handleGameType={this.handleGameType} gameType={this.state.gameTupe} />
            </div>
          ) : null}
          {this.state.gameType !== null ? (
            <div className="formulario-busqueda-quina-priv flex-col col-offset-10 col-80">
              <BuscarQuinielaFormsy
                label="Nombre de la quiniela"
                name="gameName"
                inputHandler={this.getNameQuina}
                type="TEXT"
                validations="isExisty"
                validationError="El nombre es obligatorio"
                required
              />
              <BuscarQuinielaFormsy
                label="Contraseña"
                name="gamePass"
                inputHandler={this.getPassQuina}
                type="TEXT"
                validations="isExisty"
                validationError="La contraseña es obligatorio"
                required
              />
              <ErrorMessage label={this.state.errorMessage} showError={this.state.searchError} />
              <div className="flex-row">
                <div className="flex-col">
                  <Button
                    className="btn-rojo btn-block btn-submit font-chivo"
                    onClick={this.props.handleCancelSelection}
                  >
                    <span>Regresar</span>
                  </Button>
                </div>
                <div className="flex-col">
                  <Button
                    className="btn-block btn-pick btn-submit font-chivo"
                    disabled={!this.state.canSubmit || this.state.submitting}
                    onClick={this.submitSearch}
                  >
                    <span>Buscar</span>
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </Formsy>
      );
    } else {
      return null;
    }
  }
}

export default BuscarQuinielaPrivada;
