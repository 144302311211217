import React, { useEffect, useState } from 'react';
import avatar from '../../assets/images/avatar-50.png';

const SurvivorContestPickF1 = ({
  pick,
  currentContest,
  contest,
  players,
  eliminationContestId,
  alive,
  togglePlayerList,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerList, setPlayerList] = useState(players);

  useEffect(() => {
    if (pick !== undefined && pick.pick) {
      let player = playerList.find((player) => player.id === pick.pick);
      //let playerIdx = playerList.findIndex((player) => player.id === pick.pick);
      setSelectedPlayer(() => player);
    }
    // terrible way...
  }, [JSON.stringify(pick)]);

  useEffect(() => {
    setPlayerList(() => {
      return players;
    });

    if (pick !== undefined && pick.pick) {
      let player = playerList.find((player) => player.id === pick.pick);
      setSelectedPlayer(() => player);
    }
  }, [players]);

  const onRowClicked = () => {
    if (contest.status === 'upcoming') {
      togglePlayerList();
    }
  };

  let pickClass = null;
  if (pick) {
    if (contest.status !== 'upcoming' && pick.correct) {
      pickClass = 'correct';
    }

    if (contest.status !== 'upcoming' && !pick.correct) {
      // && ( !pick.strike || eliminationContestId === contest.id ) ) {
      pickClass = 'incorrect';
    }

    if (!pick.correct && pick.strike && contest.id !== eliminationContestId) {
      pickClass = 'strike';
    }
  }

  return (
    <div className="survivor-contest-pick">
      {selectedPlayer === null ?
        (alive && contest.id === currentContest.id && contest.status === 'upcoming' ?
          (
            <div style={{ padding: '4px', display: 'flex', alignItems: 'center', minHeight: '47px' }} onClick={() => onRowClicked()}>
              <div className="flex-col"><strong>ELIGE PILOTO</strong></div>
            </div>
          ) : null
        ) : (
          <React.Fragment>
            {contest.id === currentContest.id && selectedPlayer ? (
              <div
                className={pickClass}
                style={{ padding: '4px', display: 'flex', alignItems: 'center', minHeight: '47px' }}
                onClick={() => onRowClicked()}
              >
                <div className="flex-col col-no-padding">
                  <div className="flex-row">
                    <div className="flex-col col-15 flex-container justify align">
                      <img className="player-avatar" src={selectedPlayer.avatar === null ? avatar : selectedPlayer.avatar} />
                    </div>
                    <div className="flex-col col-50 uppercase">
                      <div>
                        <i className={`flag ${selectedPlayer.logo}`}></i>
                        <strong>{selectedPlayer.name}</strong>
                      </div>
                      <div>
                        <img className="" src={selectedPlayer.alt_avatar === null ? '' : selectedPlayer.alt_avatar} style={{width: '85px', height: 'auto'}} />
                      </div>
                    </div>
                    <div className="flex-col col-25">
                      <strong>({selectedPlayer.team_name})</strong>
                    </div>
                    {contest.status !== 'upcoming' ? (
                      <div className="col-10 flex-container justify align">
                        <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                          {selectedPlayer.finish_pos}
                        </div>
                      </div>
                      ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className={pickClass}>
                <div className="flex-col col-no-padding">
                  <div className="flex-row">
                    <div className="flex-col col-15 flex-container justify align">
                      <img className="player-avatar" src={pick.p.avatar === null ? avatar : pick.p.avatar} />
                    </div>
                    <div className="flex-col col-50 uppercase">
                      <div>
                        <i className={`flag ${pick.p.logo}`}></i>
                        <strong>{pick.p.name}</strong>
                      </div>
                      <div>
                        <img className="" src={pick.p.alt_avatar === null ? '' : pick.p.alt_avatar} style={{width: '85px', height: 'auto'}} />
                      </div>
                    </div>
                    <div className="flex-col col-25">
                      <strong>({pick.team_name})</strong>
                    </div>
                    {contest.status !== 'upcoming' ? (
                      <div className="col-10 flex-container justify align">
                        <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                          {pick.rr.finish_pos}
                        </div>
                      </div>
                      ) : null}
                  </div>
                </div>
              </div>
              )}
          </React.Fragment>
        )}
    </div>
  );
};

export default SurvivorContestPickF1;
