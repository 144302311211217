import React from 'react'

// Assets
import spei from '../../assets/images/newPaymentIcons/SE.png'
import openPayBlanco from '../../assets/images/kit_openpay/openpay_contraste.png'


export default function Logos () {
  return (
    <div className='flex-row row-center row-no-padding' style={{ marginBottom: '15px' }}>
      <div className='flex-col col-center text-center col-no-padding'>
        <img src={spei} style={{ height: '25px', filter: 'drop-shadow( 0 0 4px)' }} alt='' />
      </div>
      <div className='flex-col col-center text-center col-no-padding'>
        <img src={openPayBlanco} style={{ height: '50px', filter: 'invert( 1)' }} alt='' />
      </div>
    </div>
  )
}
