import React from 'react';
import '../../assets/stylesheets/types-sprites.scss';
import firstBy from 'thenby';
import avatar from '../../assets/images/avatar-50.png';
import { QuinielaGroupsContext } from '../../context/QuinielaGroupsContext';
import _ from 'underscore';

const categories = [
  { label: 'Pos', size: 10 },
  { label: 'Jugador', size: 30 },
  { label: 'Sc', size: null },
  { label: 'H', size: null },
  { label: 'Hoy', size: null },
  { label: 'R1', size: null },
  { label: 'R2', size: null },
  { label: 'R3', size: null },
  { label: 'R4', size: null }
];

const LeadersHeader = ({ categories, sportID }) => {
  
  const categoryList = [ ...categories ]

  const isNotGolf = sportID !== 6

  if (isNotGolf) {
    // Only show Pts when sport is not golf
    categoryList.push({ label: 'Pts', size: null })
  }

  let listCategories = categoryList.map((c, cIdx) => {
    return (
      <li
        key={cIdx}
        className={`flex-col col-no-padding col-${c.size} ${
          c.label !== 'Jugador' ? 'text-center col-center' : ''
          } col-center`}
      >
        {c.label}
      </li>
    );
  });
  return <ul className="header-leaderboard flex-row row-center">{listCategories}</ul>;
};

const LeadersList = ({ players, buckets, dispatch, myEntry, sportID }) => {
  // console.log(players);
  let sortedPlayers = _.map(players, (p) => p);
  // console.log(sortedPlayers);
  sortedPlayers = sortedPlayers.sort(
    firstBy(function (p1) {
      return p1.position === null ? Number.MAX_SAFE_INTEGER : p1.position;
    }).thenBy('score_num')
  );

  // console.log(sortedPlayers);

  let list = _.map(sortedPlayers, (player) => {
    // console.log(player);
    let selectedPlayer = null;

    // Si este jugador esta en mis picks

    if (myEntry) {
      selectedPlayer = _.find(myEntry.group_pool_picks, (pick) => pick.pick === player.id);
    }
    // console.log( 'selectedPlauyer', selectedPlayer);

    let colorStyle = {};

    if (player.score > 0) {
      colorStyle = { color: 'rgb(255, 199, 48)' };
    } else if (player.score < 0) {
      colorStyle = { color: '#78bc28' };
    }

    const isNotGolf = sportID !== 6

    return (
      <li
        key={player.id}
        className={`flex-row row-center row-no-padding leaderboard-item ${
          selectedPlayer ? 'myPick' : ''
          } `}
      >
        <div className={`flex-col col-${categories[0].size} col-center text-center `}>
          {player.position} {!player.position && player.position !== '0' ? '-' : '°'}
        </div>
        <div className={`flex-col col-${categories[1].size} `}>
          <div className="flex-row row-center uppercase">
            <img alt={player.name} src={player.avatar === null ? avatar : player.avatar} className="player-avatar" />
            <i className={player.logo} style={{ marginRight: '5px' }} />
            <div className='overflow-words'> {player.name} </div>
          </div>
        </div>
        <div
          className={`flex-col col-${categories[2].size} col-center text-center`}
          style={colorStyle}
        >
          {player.score === '0' ? 'E' : player.score}
        </div>
        <div className={`flex-col  col-${categories[3].size} col-center text-center `}>
          {player.data && player.data.hole && player.data.hole !== '' ? player.data.hole : '-'}
        </div>
        <div className={`flex-col  col-${categories[4].size} col-center text-center`}>
          {player.data && player.data.today && player.data.today !== '' ? player.data.today : '-'}
        </div>
        <div className={`flex-col  col-${categories[5].size} col-center text-center`}>
          {player.data && player.data.r1 ? player.data.r1 : '-'}
        </div>
        <div className={`flex-col col-${categories[6].size} col-center text-center`}>
          {player.data && player.data.r2 ? player.data.r2 : '-'}
        </div>
        <div className={`flex-col col-${categories[7].size} col-center text-center`}>
          {player.data && player.data.r3 ? player.data.r3 : '-'}
        </div>
        <div className={`flex-col col-${categories[8].size} col-center text-center`}>
          {player.data && player.data.r4 ? player.data.r4 : '-'}
        </div>
        {isNotGolf && (
          <div className={`flex-col col-${categories[9].size} col-center text-center`}>
            {player.points}
          </div>
        )}
      </li>
    );
  });

  return (
    <ul className="list-unstyled flex-col col-no-padding leaderboard-list overflow-y overflow overflow-picks-grupo">
      {list}
    </ul>
  );
};

const Leaderboard = () => {
  let { state, dispatch } = React.useContext(QuinielaGroupsContext);


  return (
    <div className=" multiple-quiniela-info principal flex-row row-no-padding responsive-sm lista-jugadores panel-pickwin flex-container justify align">
      <div style={{ margin: '15px ' }} className="flex-col col-95 col-no-padding panel-pickwin">
        <div className={`flex-row title-quiniela`}>
          <div className="flex-col title-wrapper">
            <div className="flex-row row-no-padding row-center">
              <i className=" ion-podium " />
              <span className="flex-col">LEADERBOARD</span>
            </div>
          </div>
        </div>
        <LeadersHeader
          categories={categories}
          sportID={state.poolInfo.sport_id}
        />
        <LeadersList
          myEntry={state.myEntry}
          players={state.players}
          buckets={state.poolBuckets}
          sportID={state.poolInfo.sport_id}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
};

export default Leaderboard;
