import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { Redirect } from 'react-router-dom'

// Components
import Footer from '../Footer'
import Loading from '../Loading'
import RewardVideo from './RewardVideo'
import ErrorBoundary from '../ErrorBoundary'
import NavigationBar from '../NavigationBar'
import ClockHandler from '../Clock/ClockHandler'
import NewTosConfirmation from '../NewTosConfirmation'
import { ClockContextProvider } from '../../context/ClockContext'
import { UserDataContextProvider } from '../../context/UserDataContext'
import {
  ShowFreeRoll,
  TutorialAbonar,
  HelpPanel
} from '../TutorialPickwinUI/TutorialPickwinUI'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import branchUtils from '../../utils/branchUtils'

// Hooks
import useMergedState from '../../hooks/useMergedState'
import useAuthContext from '../../hooks/useAuthContext'
import useUserContext from '../../hooks/useUserContext'

const initialState = {
  isLogged: false,
  userData: false,
  userBalance: null,
  promotions: null,
  // TOS = Terms of service
  isNewTosConfirmed: false,
  // If was mouted or initialized
  mounted: false,
  // Boolean for show freeRoll
  showFreeRoll: false,
  // For useEffect start Tutorial
  startTutorial: false,
  // Boolean for help panel
  showHelpPanel: false,
  // Boolean for show modal invitation
  // Actually is not used
  showInvite: false,
  // Boolean for show Tutorial Organic
  showTutorialOrganic: false,
  organicTutorialStep: null,
  // Boolean for show product Tutorial
  showTutorialProduct: false,
  // Step for Product Tutorial
  productTutorialStep: null,
  // Step for FreeRoll
  gameFreeRollStep: null,
  // Help panel step
  helpPanelStep: null,
  branchLink: null,
  availableCashout: null,
  userLoyalty: null,
  device: null,
  showPromotions: false
}

function PageWrapper (props) {
  const [state, setState] = useMergedState(initialState)
  const { updateUserInfo, updateUserBalance } = useUserContext()
  const isLogged = utils.isLogged()

  useEffect(() => {
    if (isLogged) {
      API.fetchUserData().then((responseUserData) => {
        API.fetchAvailableCashout()
          .then((responseAvailable) => {
            utils.setUserInfo(responseUserData.data)
            const balanceObj = utils.setUserBalance(responseUserData.data)
            setState({
              isLogged: true,
              branchLink: null,
              isNewTosConfirmed: responseUserData.data.user.tos,
              userData: responseUserData.data.user,
              promotions: responseUserData.data.promotions,
              userBalance: balanceObj,
              availableCashout: responseAvailable.data,
              userDataChanged: true,
              mounted: true,
              startTutorial: true,
              userLoyalty: utils.getUserLoyalty()
            })
          })
          .catch((error) => {
            setState({
              mounted: true
            })
            console.error('Error getting userData', error)
            Sentry.captureException(error)
          })
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          // logoutExpired()
        }
      })
    } else {
      setState({ mounted: true })
    }
  }, [isLogged])

  useEffect(() => {
    if (state.startTutorial) {
      initializeTutorial()
    }
  }, [state.startTutorial])

  const handleTutorial = (type, status) => {
    console.log('%c %s Handle Tutorial', 'font-size:2em; color:#dc3c75;', type)
    console.log(type, status)
    if (type === 'organic') {
      console.log('organic', state.showTutorialOrganic)
      setState({ showTutorialOrganic: status })
    } else if (type === 'product') {
      setState({ showTutorialProduct: status })
    } else if (type === 'freeRoll') {
      console.log('freeRoll', state.showFreeRoll)
      setState({ showFreeRoll: status })
    }
  }

  const handleTutorialStep = (step, type, status) => {
    // When is a new user
    console.log(step, type, status)
    if (isNaN(step)) {
      console.error("The step isn't a number", step)
      return
    }
    if (type === 'organic') {
      utils.showTutorialLobby(step, status)
      setState({ organicTutorialStep: step, showTutorialOrganic: status })
    } else if (type === 'product') {
      console.log('Product')
      utils.showGameTutorial(step, status)
      setState({ productTutorialStep: step, showTutorialProduct: status })
    } else if (type === 'freeRoll') {
      utils.setShowGameFreeRoll(step, status)
      setState({ gameFreeRollStep: step, showFreeRoll: status })
    } else if (type === 'help') {
      utils.setShowHelpPanel(status)
      if (!status) {
        utils.removeHelpPanel()
      }
      setState({ helpPanelStep: step, showHelpPanel: status })
    }
  }

  const initializeTutorial = () => {
    // console.log('%c Initialize tutorials','color:#245c4f; font-size:2em;');
    const organicTutorial = utils.getShowTutorialLobby()
    const productTutorial = utils.getShowGameTutorial()
    const showFreeRoll = utils.getShowGameFreeRoll()
    // console.log('organicTutorial',organicTutorial);
    // console.log('productTutorial', productTutorial);
    if (organicTutorial && organicTutorial.step) {
      // handleTutorial( 'organic' );
      handleTutorialStep(organicTutorial.step, 'organic', organicTutorial.show)
    } else if (productTutorial && productTutorial.show) {
      // handleTutorial( 'product' );
      handleTutorialStep(0, 'product', true)
    } else if (showFreeRoll && showFreeRoll.show) {
      // handleTutorial( 'freeRoll' );
      handleTutorialStep(showFreeRoll.step, 'freeRoll', true)
    }
  }

  /**
   * handles the redirection from whats app click
   */
  const whatsAppInvitation = () => {
    console.log('WhatsApp invitation')
    branchUtils.redirectToWhatsApp(state.branchLink, 'open', state.poolInfo.pool.id)
  }

  /**
   * This function updates the branch Link after it is ready
   */
  const updateBranchLink = (newLink) => {
    console.log('UpdateBranchLink', newLink)
    setState({
      branchLink: newLink
    })
  }

  const getBranchLink = () => {
    //  return branchUtils.createLink( utils.getUserInfo() )
  }

  const updateUserAvailable = (available) => {
    if (!available) {
      console.log('Without Available ')

      API.fetchAvailableCashout()
        .then((responseAvailable) => {
          // utils.setUserInfo( responseUserData.data );
          // console.log( 'response', response );
          setState({
            availableCashout: responseAvailable.data
          })
        })
        .catch((error) => {
          setState({
            mounted: true
          })
          console.error('Error getting branch Link', error)
          Sentry.captureException(error)
        })
    } else {
      console.log('WithAvailable ')
      setState({
        availableCashout: available
      })
    }
  }

  const handleTosConfirmation = (status) => {
    console.log('Handle Tos Confirmation ', status)

    const userD = utils.getUserInfo()
    console.log(userD)

    userD.tos = status

    utils.setUserInfo({ user: userD })

    setState({ isNewTosConfirmed: status })
  }

  const showOpenInvite = () => {
    console.log('ShowInvite')
    setState({ showInvite: !state.showInvite }, () => {
      if (state.showInvite) {
        getBranchLink().then((response) => {
          console.log(response)
          updateBranchLink(response)
        })
      }
    })
  }

  const handleDocumentAttributes = () => {}

  if (
    state.isLogged &&
    !utils.isVerified(utils.getUserInfo()) &&
    window.location.pathname !== '/'
  ) {
    console.log('Verificar cuenta desde page', window.location)
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { prevLocation: window.location.pathname }
        }}
      />
    )
  } else if (state.mounted) {
    return (
      <>
        {state.userLoyalty != null && <RewardVideo userLoyalty={state.userLoyalty} />}
        <ErrorBoundary userBalance={state.userBalance}>
          <UserDataContextProvider
            handleTutorial={handleTutorial}
            handleTutorialStep={handleTutorialStep}
            userData={state.userData}
            promotions={state.promotions}
            userBalance={state.userBalance}
            isLogged={state.isLogged}
            availableCashout={state.availableCashout}
            branchRegisterLink={state.branchLink}
            updateUserInfo={updateUserInfo}
            updateUserBalance={updateUserBalance}
            updateUserAvailable={updateUserAvailable}
            handleDocumentAttributes={handleDocumentAttributes}
          >
            <ClockContextProvider>
              <div className='page-wrapper'>
                <div className='inner-wrapper'>
                  <NavigationBar
                    isLogged={state.isLogged}
                    userBalance={state.userBalance}
                    userData={state.userData}
                    updateUserBalance={updateUserBalance}
                    updateUserInfo={updateUserInfo}
                  />
                  <TutorialAbonar
                    show={state.showTutorialProduct}
                    step={state.productTutorialStep}
                    handleTut={handleTutorial}
                    handleTutStep={handleTutorialStep}
                  />
                  <ShowFreeRoll
                    show={state.showFreeRoll}
                    step={state.gameFreeRollStep}
                    handleTut={handleTutorial}
                    handleTutStep={handleTutorialStep}
                  />
                  <HelpPanel
                    step={state.helpPanelStep}
                    show={state.showHelpPanel}
                    handleTutStep={handleTutorialStep}
                  />
                  <NewTosConfirmation
                    isLogged={state.isLogged}
                    isLegals={props.isLegals}
                    isNewTosConfirmed={state.isNewTosConfirmed}
                    handleTosConfirmation={handleTosConfirmation}
                  />
                  {props.children}
                  <ClockHandler type='initializer' />
                  <div id='push' />
                </div>
                <div className='footer' style={{ marginTop: props.isFixed ? '50vh' : 'initial'}}>
                  <Footer />
                </div>
              </div>
            </ClockContextProvider>
          </UserDataContextProvider>
        </ErrorBoundary>
      </>
    )
  } else {
    return (
      <div className='page-wrapper'>
        <div className='inner-wrapper'>
          <Loading />
          <div id='push' />
        </div>
        <div className='footer'>
          <Footer />
        </div>
      </div>
    )
  }
}

export default PageWrapper

