import styled from 'styled-components'
import propTypes from 'prop-types'
import React from 'react'

// Hooks
import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

Avatar.defaultProps = {
  size: '2em'
}

Avatar.propTypes = {
  ...shorthandProptypes,
  src: propTypes.string,
  alt: propTypes.string,
  size: propTypes.oneOfType([
    propTypes.string,
    propTypes.number
  ])
}

export default function Avatar (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  return (
    <Image
      alt={props.alt}
      src={props.src}
      style={{
        ...stylesShortcuts,
        width: props.size,
        height: props.size
      }}
    />
  )
}

const Image = styled.img`
  max-height: 150px;
  max-width: 150px;
  border-radius: 50%;
  margin: auto;
`
