import styled from 'styled-components'
import propTypes from 'prop-types'
import React from 'react'

// Hooks
import useStyleShortcuts, { shorthandProptypes } from '../../hooks/useStyleShortcuts'

Divider.defaultProps = {
  size: '2em'
}

Divider.propTypes = {
  ...shorthandProptypes,
  src: propTypes.string,
  alt: propTypes.string,
  size: propTypes.oneOfType([
    propTypes.string,
    propTypes.number
  ])
}

export default function Divider (props) {
  const stylesShortcuts = useStyleShortcuts(props)

  return (
    <Comp
      style={{
        ...stylesShortcuts
      }}
    />
  )
}

const Comp = styled.div`
  border: 1px dashed #313131;
  margin: 0.6em 0.1em;
`
