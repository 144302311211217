
import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'

// Components
import { Box, Grid, Iconify, Stack, Typography } from '../../../Xmerald-UI'
import { useWizardPrivate } from '../wizardPrivate.store'

// Utils
import sleep from '../../../Xmerald-UI/utilities/sleep'
import API from '../../../utils/api'

const FIXTURES = '/api/tournament_instances/next_fixtures/'

export default function PoolFixtures () {
  const activeTournamentID = useWizardPrivate(state => state.activeTournamentID)
  const { data, isLoading, isFetching } = useQuery(
    [FIXTURES, activeTournamentID],
    async () => {
      await sleep()
      const { data } = await API.fetchTournamentInstancesFixtures(activeTournamentID)
      return data
    }
  )

  return (
    <Box>
      <Typography center size='1.2em' mt={20} mb={10}>
        Partidos {isFetching && <Iconify icon='eos-icons:three-dots-loading' color='#70f324' />}
      </Typography>

      {isLoading && (
        <Grid columns='repeat(3, 1fr)' gap='0.5em'>
          <Skeleton count={1} height={85} />
          <Skeleton count={1} height={85} />
          <Skeleton count={1} height={85} />
        </Grid>
      )}

      {!isLoading && data?.fixtures && (
        <Grid columns='repeat(3, 1fr)' gap='0.5em'>
          {data.fixtures.map(fixture => (
            <Fixture
              key={fixture.id}
              fixture={fixture}
            />
          ))}
        </Grid>
      )}
    </Box>
  )
}

const Fixture = ({ fixture }) => {
  return (
    <FixtureBox>
      <Stack direction='row'>
        <Stack direction='column'>
          {fixture.home_team.logo && (
            <span className='flex-container justify align '>
              <i className={` ${fixture.home_team.logo} home`} />
            </span>
          )}

          <Typography center size='0.8em'>
            {fixture.home_team.abbreviation}
          </Typography>
        </Stack>
        <Typography center>VS</Typography>

        <Stack direction='column'>
          {fixture.away_team.logo && (
            <span className='flex-container justify align '>
              <i className={` ${fixture.away_team.logo} home`} />
            </span>
          )}

          <Typography center size='0.8em'>
            {fixture.away_team.abbreviation}
          </Typography>
        </Stack>
      </Stack>
      <Typography center size='0.8em' mt={5}>{moment(fixture.match_date).format('MM/DD/YY hh:mm')}</Typography>
    </FixtureBox>
  )
}

const FixtureBox = styled.div`
  min-height: 75px;
  width: 100%;
  background-color: #111;
  border: 1px solid #3f3f3f;
  border-radius: 0.5em;
  padding: 0.3em;
`
