import React, { useEffect, useState } from 'react'
import Formsy from 'formsy-react'
import { PuffLoader } from 'react-spinners'
import styled from 'styled-components'

import { useOpenModal2 } from '@zenkipay-pkg/react';

// Components
import Text from '../Text/Text'
import Button from '../Form/Button'
import AbonarFormsy from '../AbonarFormsy'
import DepositResponse from './DepositResponse'
import { Typography } from '../../Xmerald-UI'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import { publish, subscribe, unsubscribe } from '../../utils/events'

// Hooks
import useMergedState from '../../hooks/useMergedState'
import Logos from './Logos'

const ERROR_MESSAGES = {
  CANCELLED_BY_USER: 'Has cancelado, puedes volver al proceso de pago haciendo click en "PAGAR CON ZENKIPAY"',
  OPEN_MODAL_FAILED: 'Ha ocurrido un error al abrir la ventana de pago, intenta nuevamente haciendo click en "PAGAR CON ZENKIPAY"'
}


function CryptoPaymentForm (props) {
  const userData = utils.getUserInfo()
  const openModal = useOpenModal2()

  function handleZenkipayEvents(error, data) {
    setState({ submitting: false })

    if (error) {
      setState({
        submitting: false,
        errorMessage: data.postMsgType
      })
    }

    // Payment in process, user will receive an email and push notification
    if (data.postMsgType === 'shopper_payment_confirmation' && data.isCompleted) {
      return setState({ showResponse: true })
    }

    if (data.postMsgType === 'cancel') {
      return setState({ errorMessage: ERROR_MESSAGES.CANCELLED_BY_USER })
    }

    setState({ submitting: false })
    console.log(data);
  }

  useEffect(() => {
    subscribe('SMS:ACOUNT_VERIFIED', openPaymentModal)

    return () => unsubscribe('SMS:ACOUNT_VERIFIED', openPaymentModal)
  }, [])

  const openPaymentModal = async () =>  {
    setState({ submitting: true, errorMessage: false })
  
    const userData = utils.getUserInfo()

    // If user is not verified SMS then first open modal to verify
    // Then called again this function (openPaymentModal)
    if (!userData.identity_verified) {
      return publish('SMS:SHOW_MODAL')
    }

    try {
      const res = await API.postPurchaseZenki(state.paymentDataModel)

      if (res.data.order) {
        const { zenkiOrderId, paymentSignature } = res.data.order

        if (openModal) {
          openModal({ orderId: zenkiOrderId, lang: "es_419", paymentSignature }, handleZenkipayEvents)
        }

      } else {
       // Show notification error
       setState({ errorMessage: ERROR_MESSAGES.OPEN_MODAL_FAILED })
      }
    
    } catch (error) {}
    
  }

  const [state, setState] = useMergedState({
    paymentDataModel: {
      amount: 'pther',
      other_amount: null,
      gateway: 'zenki',
      method: "zenki",
      name: null,
      last_name: null
    },
    submitting: false,
    errorMessage: null
  })

  useEffect(() => {
    const newPaymentData = state.paymentDataModel

    newPaymentData.amount = props.amount ? props.amount : null
    newPaymentData.other_amount = props.other_amount ? props.other_amount : null

    setState({
      paymentDataModel: newPaymentData
    })
  }, [])

  useEffect(() => {
    // Component Did Update
    if (
      state.paymentDataModel.amount !== props.amount ||
      state.paymentDataModel.other_amount !== props.other_amount
    ) {
      setState(prevState => ({
        ...prevState,
        paymentDataModel: {
          ...prevState.paymentDataModel,
          other_amount: props.other_amount,
          amount: props.amount
        }
      }))
    }
  }, [props])



  const handleSubmit = (status) => {
    setState({ canSubmit: status })
  }

  const handleCardData = (e, category) => {
    const temporalPaymentData = state.paymentDataModel
    temporalPaymentData[category] = e.target.value

    setState({
      paymentDataModel: temporalPaymentData
    })
  }

  const closeResponse = () => {
    setState({ showResponse: false, error: null })
  }

  if (!props.show) return null

  return (
    <>
      {state.showResponse && (
          <DepositResponse
            amount={props.amount}
            other_amount={props.other_amount}
            closeDepositResponse={closeResponse}
            nick={utils.getUserInfo().nick}
            error={state.error}
            updateUserInfo={props.updateUserInfo}
          />
      )}
      <div className='flex-row row-no-padding mt-1'>
        <div className='flex-col col-no-padding' style={{ marginLeft: '3rem', alignItems: 'flex-start' }}>
          <div className='flex-row row-center'>
            <h4 style={{ color: '#e5e6e8' }} className='flex-col col-center text-center'>
              ¿CÓMO ABONAR EN CRIPTO MONEDAS?
            </h4>
          </div>
          <div className='flex-row row-center'>
            <ol className=' flex-col col-offset-5 col-90' style={{ fontSize: '12px' }}>
              {!userData.name ? <li> Dínos tu nombre</li> : null}
              <li>Elige el monto en pesos que quieres abonar a tu cuenta</li>
              <li>Da click en el botón "PAGAR CON ZENKIPAY"</li>
              <li>Se abrirá una ventana con los pasos para hacer el depósito</li>
              <li>Elige de que wallet quieres hacer tu retiro</li>
              <li>Escanea el código QR o copia la dirección de la billetera</li>
              <li>Realiza el depósito</li>
              <li>Una vez que tu depósito sea confirmado, tu saldo será abonado a tu cuenta de Pickwin</li>
            </ol>
          </div>
        </div>
        <div flex-col>
          {props.amountComponent}
        </div>
      </div>
      {!userData.name
        ? (
          <Formsy
            onValid={() => {
              handleSubmit(true)
            }}
            onInvalid={() => {
              handleSubmit(false)
            }}
          >
            <div className='flex-row row-center row-no-padding mt-1'>
              <AbonarFormsy
                col='col-45'
                type='TEXT'
                required
                resetForm={null}
                defaultValue=''
                name='name'
                placeholder='NOMBRE'
                validations={{ minLength: 2 }}
                validationError='Utiliza tu nombre.'
                inputHandler={handleCardData}
              />
              <AbonarFormsy
                col='col-50 col-offset-5'
                type='TEXT'
                required
                resetForm={null}
                defaultValue=''
                name='last_name'
                placeholder='APELLIDOS'
                validations={{ minLength: 2 }}
                validationError='Utiliza tu apellido.'
                inputHandler={handleCardData}
              />
            </div>
          </Formsy>
          )
        : null}
      <Text className={`flex-row row-center ${!userData.name ? '' : 'hide'}`} size={14}>
        Por favor dinos tu nombre y apellidos. Estos serán verificados al momento de realizar
        alguna solicitud de retiro.
      </Text>
      <div className='flex-col row-center '>
        {Boolean(state.errorMessage) && (
          <Typography color='red' center>{ state.errorMessage ?? 'Ha ocurrido un error'}</Typography>
        )}
        <Button
          onClick={openPaymentModal}
          disabled={props.errorOtraCantidad || (!userData.name && !state.canSubmit) || state.submitting}
          type='submit'
          className='btn-pick btn btn-sm btn-default'
          bgColor='#88ba44'
          style={{
            maxWidth: 300,
            margin: '1em auto'
          }}
        >
          <Status>
            {state.submitting && <><PuffLoader color='#FFF' size={20} /> <span>Cargando</span></>}
            {!state.submitting && <div>PAGAR CON ZENKIPAY</div>}
          </Status>
        </Button>

        <Logos />
      </div>
    </>
  )
}

const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.2em;
  text-align: center;
  gap: 1em;
`

export default CryptoPaymentForm
