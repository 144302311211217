import React, { useRef, useState, useEffect } from 'react'

import styled, { keyframes } from 'styled-components'
import Particles from './Particles'

LevelName.defaultProps = {
  time: 200,
  times: 6
}

function LevelName (props) {
  const [count, setCount] = useState(0)
  const [scale, setScale] = useState(0.2)
  const [color, setColor] = useState('#FFF')
  const tickRef = useRef()

  useEffect(() => {
    setCount(0)

    tickRef.current = window.setInterval(() => {
      setCount(count => {
        if (count >= props.times) {
          window.clearInterval(tickRef.current)
          tickRef.current = null
        }

        const next = count + 1

        if (next % 3 === 0) {
          setColor('#c0ff33')
        } else {
          setColor('#FFF')
        }

        return count + 1
      })
    }, 150)

    setScale(0.8)

    window.setTimeout(() => {
      setScale(1)
    }, 1500)
  }, [props.levelName])

  return (
    <Container>
      <Particles show={scale === 0.8} />
      <Text style={{ color, transform: `scale(${scale})` }}>
        {scale === 0.8 && (
          <>
            <Gradient />
            <Gradient2 />
            <Gradient3 />
          </>
        )}

        {props.levelName}
      </Text>
    </Container>
  )
}

const anima = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-120%);
  }
`

const anima2 = keyframes`
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
`

const Container = styled.div`
  width: fit-content;
  position: relative;
`

const Gradient = styled.div`
  background: linear-gradient(90deg, transparent, #78BC28, transparent);
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  animation: ${anima} 750ms forwards 600ms;
  position: absolute;
`

const Gradient2 = styled.div`
  background: linear-gradient(90deg, transparent, #78BC28, transparent);
  width: 100%;
  height: 50%;
  bottom: 0;
  transform: translateX(100%);
  animation: ${anima2} 750ms forwards 650ms;
  position: absolute;
  opacity: 0.8;
`

const Gradient3 = styled(Gradient2)`
  animation: ${anima2} reverse 750ms forwards 500ms;
`

const Text = styled.div`
  color: lime;
  transition: color 120ms ease, transform 750ms ease;
  position: relative;
  overflow: hidden;
  padding: 0 0.7em;
  border-radius: 0.1em;
`

export default LevelName
