import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import { AppProvider } from './context/AppContext'
import { AuthProvider } from './context/AuthContext'
import { UserProvider } from './context/UserContext'

import Routes from './routes/Routes'

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AuthProvider>
          <UserProvider>
            <SkeletonTheme
              baseColor='#1a1a1a'
              highlightColor='#94949436'
              borderRadius='0.5rem'
            >
              <Routes />
            </SkeletonTheme>
          </UserProvider>
        </AuthProvider>
      </AppProvider>
    </QueryClientProvider>
    
  )
}

export default App

