import React from 'react';
import PageWrapper from '../PageWrapper';
import { UserDataContextConsumer } from '../../context/UserDataContext';
import { Button, FormControl } from 'react-bootstrap/lib';
import API from '../../utils/api';

import './index.scss';

import pickwinLogo from '../../assets/images/pickwinBCO.png';

export default function ValidarUsuario () {
  const [smsButtonText, setSmsButtonText] = React.useState('Envíar SMS');
  const [smsGetResult, setSmsGetResult] = React.useState(false);
  const [smsInterview, setSmsInterview] = React.useState(null);
  const [smsResultStatus, setSmsResultStatus] = React.useState(null);
  const [smsSubmit, setSmsSubmit] = React.useState(false);


  React.useEffect(() => {
    //add incode onBoarding sdk
    var fileref=document.createElement('script');
    fileref.setAttribute("type","text/javascript");
    fileref.setAttribute("src", "https://sdk.incode.com/sdk/onBoarding-1.48.0.js");
    document.getElementsByTagName("head")[0].appendChild(fileref);
  }, []);

  const sendSms = (userData) => {
    setSmsSubmit(true);

    let phone = userData.phone
    if (phone !== '' || phone !== null) {
      let onBoarding = API.getIncodeOnBoarding();

      let confId = process.env.REACT_APP_INCODE_CONF;

      onBoarding.createSession('MX', null, {configurationId: confId}).then((token) => {
        let interviewSession = token;
        //save session in pickwin
        let params = {type: 'ban', session: interviewSession};
        API.incodeSaveSession(params).then((response) => {
          if (response.data.success) {
            let params = {phone: phone, session: interviewSession}
            API.sendIncodeSms(params).then((response) => {
              if (response.data.res.success) {
                //show result button for user validation in 60 seconds
                setSmsInterview(interviewSession);
                let counter = 120;
                let interval = setInterval(() => {
                  counter = counter - 1;
                  setSmsButtonText(`Esperando la validación vía SMS: ${counter} seg.`);
                  if (counter === 0) {
                    clearInterval(interval);
                    setSmsGetResult(true);
                  }
                }, 1000);
              } else {
                console.log("Error send sms");
              }
            });
          } else {
            console.log("Error to save session");
          }
        });
      });
    } else {
      alert("Debes ingresar un número de teléfono");
    }
  };

  const getIncodeResults = () => {
    API.getIncodeResults(smsInterview).then((response) => {
      setSmsSubmit(false);

      switch (response.data.result) {
        case null:
          setSmsResultStatus('No se pudo realizar la verificación');
          break;
        case 'OK':
          setSmsResultStatus('Tu verificación ha sido exitosa, seras redirigido en un momento...');
          break;
        case 'FAIL':
          setSmsResultStatus('No se pudo verificar tu cuenta, por favor comunicate a soporte@pickwin.net');
          break;
        case 'MANUAL':
        case 'WARN':
          setSmsResultStatus('No pudimos verificar tus documentos, el equipo de pickwin revisará tu caso. para cualquier aclaración comunicate a soporte@pickwin.net');
          break;
        case 'UNKNOWN':
        default:
          setSmsResultStatus('No pudimos verificar tu identidad');
          break;
      }
      if (response.data.result === "OK") {
        setTimeout(() => {
          window.location = "/";
        }, 3000);
      }
    });
  }

  return (
    <PageWrapper>
      <UserDataContextConsumer>
        {({
          isLogged,
          userData,
          userBalance,
          updateUserInfo,
          updateUserBalance,
          isNewFlowActive,
          handleTutorial,
          handleTutorialStep,
        }) => {
          return (
            <div className="container">
              <div className="col-xs-8 col-xs-offset-2 border-gradient validation-content">
                <div className="validation-wrap">
                  <div className="flex-container justify">
                    <img src={pickwinLogo} width="150"/>
                  </div>
                  <div className="flex-container">
                    <h4 className="font-chivo">Cuenta en verificación</h4>
                  </div>
                  <div className="flex-container column">
                    <p>Notamos actividad sospechosa con tu cuenta y ha sido puesta en proceso de verificación. Como parte del proceso de reactivación necesitamos que vuelvas a verificar tu identidad mediante el envío de un sms, solo sigue las intrucciones.</p>
                    <p>Una vez terminado el proceso en tu telefono da click en: "Obtener resultados de validación"</p>
                  </div>
                  <div className="flex-container">
                    <h5 className="font-chivo">{userData.ban_reason}</h5>
                  </div>


                  {/* when user is blocked */}
                  { userData.suspension_status === 1 && (
                    <div className="flex-container justify">
                      { smsGetResult === false && (
                        <Button
                          onClick={() => {sendSms(userData)}}
                          type="button"
                          className="btn-pick"
                          disabled={smsSubmit}
                          style={{width: '400px', marginTop: '1rem', justifyContent: 'center'}}
                          >
                          {smsButtonText}
                        </Button>
                      )}

                      { smsGetResult === true && (
                        <div className='flex-container justify around wrap'>
                          <Button
                            onClick={() => {sendSms(userData)}}
                            type="button"
                            disabled={false}
                            className="btn-pick-blue"
                            style={{width: '350px', marginTop: '1rem', justifyContent: 'center'}}
                          >
                            No me ha llegado el SMS, enviar de nuevo
                          </Button>

                          <Button
                            onClick={() => {getIncodeResults()}}
                            type="button"
                            disabled={false}
                            className="btn-pick"
                            style={{width: '350px', marginTop: '1rem', justifyContent: 'center'}}
                          >
                            Obtener resultados de validación
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {/* check current results */}
                  { userData.suspension_status === 5 && (
                    <p className="text-center"><strong>No se completo el proceso de verificación de cuenta.</strong></p>
                  )}
                  { userData.suspension_status === 2 && (
                    <p className="text-center"><strong>No pudimos verificar correctamente tu información por favor comunicate al correo <a href="mailto:soporte@pickwin.net">soporte@pickwin.net.</a></strong></p>
                  )}
                  { userData.suspension_status === 3 && (
                    <p className="text-center"><strong>Tu información será verificada y en breve se te notificará el resultado.</strong></p>
                  )}
                  { userData.suspension_status === 4 && (
                    <p className="text-center"><strong>Tu información ha sido válidada y ya pordrás iniciar sesión normalmente.</strong></p>
                  )}



                  { smsResultStatus !== null && (
                    <div className="flex-container justify">
                      <h5 className="font-chivo">{smsResultStatus}</h5>
                    </div>
                  )}

                </div>
              </div>
            </div>
          );
        }}
      </UserDataContextConsumer>
    </PageWrapper>
  );
}
