import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import onClickOutside from 'react-onclickoutside';
import ReactTooltip from 'react-tooltip';
import { Button, Nav, NavItem as NavItemBase } from 'react-bootstrap/lib/';
import $ from 'jquery';
import numeral from 'numeral';

// Assets
import tickets from '../../assets/images/silver_ticket_pick_empty.png';
import cash from '../../assets/images/cash1-50.png';
import pickcoin from '../../assets/images/pickcoin1-50.png';
import avatar from '../../assets/images/avatar-50.png';

// Utils
import utils from '../../utils/utils';

// Components
import PanelQuinielasPrivadas from '../PanelQuinielasPrivadas';

// Hooks
import useUserContext from '../../hooks/useUserContext';
import Iconify from '../Iconify';
import styled from 'styled-components';

// Config
import config from '../../config';

const SlideCurrency = ({ userData, currentCurrency }) => {
  const { userBalance, isLogged } = useUserContext()
  //let currencyItem = $('.currency-item');

  if (!isLogged) return null

  let sliderPosition = 0;
  switch (currentCurrency) {
    case 0:
      sliderPosition = '-0%';
      break;
    case 1:
      sliderPosition = '-110%';
      break;
    // case 2:
    //   sliderPosition = '-220%';
    //   break;
  }
  // userBalance.balanceReal = 5687900;
  let bigBalance = userBalance.balanceReal > 9999999;

  let conditionalComponent = (
    <div className={`flex-row currency-container`} style={{ transform: `translateX( ${sliderPosition} )` }}>
      <div data-id="0" className={`flex-col col-center col-99 currency-item  `}>
        <div className="flex-container end balance">
          <img src={cash} className={`dropdown-icons ${bigBalance ? 'small-currency' : ''}`} /> {' '}
          <span
            className={`${bigBalance ? 'small-currency' : ''}`}
            style={
              userData
                ? userBalance.balanceReal < 100
                  ? { color: '#e5e6e8' }
                  : { color: '#78BC28', fontWeight: '900' }
                : {}
            }
          >
            {numeral(userBalance.balanceReal / 100).format('$0,000.00')} <small className="">MXN</small>
          </span>
        </div>
        {/* { userBalance.balanceBonus > 0 &&
          (<div className="flex-container end balance">
            <img src={freePlay} className="dropdown-icons small-currency" />{' '}
            <span style={{color: '#886aea', fontWeight: '900'}}>
              {numeral(userBalance.balanceBonus / 100).format('$0,000.00')} <small className="">MXN</small>
            </span>
          </div>)
        } */}
        {
          userBalance.balancePc > 0 && (
            <div className="flex-container end balance">
              <img src={pickcoin} className="dropdown-icons small-currency" />{' '}
              <span style={{color: '#F9CC30', fontWeight: '900'}}>
                {numeral(userBalance.balancePc / 100).format('0,000.00')} <small className="">PC</small>
              </span>
            </div>
          )
        }
        {/* <span className="flex-container end align user-info-item balance">
          <div className="flex-container center between">
            <div className=" text-center">
              <img
                src={pickcoin}
                className={`dropdown-icons ${bigBalance ? 'small-currency' : ''}`}
              />
              <span
                className={` ${bigBalance ? 'small-currency' : ''} `}
                style={
                  userBalance.balancePc < 100
                    ? { color: '#e5e6e8', fontWeight: 'bold' }
                    : { color: '#F9CC30', fontWeight: 'bold' }
                }
              >
                {numeral(userBalance.balancePc / 100).format('0,000.00')}
                <small>PC</small>
              </span>
            </div>
            <div>|</div>
            <div>

            </div>
          </div>
        </span> */}
      </div>
      <div data-id="1" className="flex-col col-center col-99 currency-item">
        <div className="flex-container end align user-info-item balance">
          <div className="text-center ">
            <img
              src={tickets}
              className={`dropdown-icons ticket ${bigBalance ? 'small-currency' : ''}`}
            />
            <span
              className={` ${bigBalance ? 'small-currency' : ''} `}
              style={{ fontWeight: 'bold' }}
            >
              {userBalance.tickets && userBalance.tickets.length > 0
                ? userBalance.tickets.length
                : 0}{' '}
              {userBalance.tickets && userBalance.tickets.length !== 1 ? 'Tickets' : 'Ticket'}
            </span>
          </div>
        </div>
        {/* <div className=" flex-container end align user-info-item balance">
          <div className="text-center">
            <img
              src={pickcoinPlata}
              className={`dropdown-icons ${bigBalance ? 'small-currency' : ''}`}
            />
            <span
              style={
                userBalance.balanceReferral < 100
                  ? { color: '#fff', fontWeight: 'bold' }
                  : { color: '#e5e6e8', fontWeight: 'bold' }
              }
            >
              {numeral(userBalance.balanceReferral / 100).format('0')}
              <small>TK</small>
            </span>
          </div>
        </div> */}
      </div>
      {/* <div data-id="1" className="flex-col col-center col-99 currency-item">
        <div className=" flex-container end align user-info-item balance">
          <div className="text-center">
            <img
              src={freeRollIcon}
              className={`dropdown-icons ${bigBalance ? 'small-currency' : ''}`}
            />
            <span
              style={
                userBalance.balanceFreeroll < 100
                  ? { color: '#fff', fontWeight: 'bold' }
                  : { color: '#e5e6e8', fontWeight: 'bold' }
              }
            >
              {numeral(userBalance.balanceFreeroll / 100).format('0')}
              <small>R</small>
            </span>
          </div>
        </div>
        <div
          className=" flex-container end align user-info-item balance"
          style={{ content: '' }}
        ></div>
      </div> */}
    </div>
  );

  return conditionalComponent;
};


class AvatarDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDropdown: false,
      userData: this.props.userData,
      showQuinielasP: false,
      currentCurrency: 0,
    };

    this.animateCurrency = (int, current, totalItems) => {
      let currencyContainer = $('#userBalanceInfoSlider');
      let currencyItem = $('.currency-item');

      this.setState({ isTimeoutRunning: true }, () => {
        setTimeout(() => {
          console.log('Set Timeout', current);

          let findThis = `[data-id=${current}]`;
          //let scrollTo = currencyItem.find(findThis);
          currencyContainer.animate(
            { scrollLeft: current >= totalItems ? 0 : currencyItem.width() + 30 },
            250
          );
          this.setState({
            current: current >= totalItems ? 0 : current + 1,
            isTimeoutRunning: false,
          });
        }, int);
      });
    };

    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.handleDropdownUserOver = this.handleDropdownUserOver.bind(this);
    this.handleDropdownUserOut = this.handleDropdownUserOut.bind(this);
    this.handleDropdownUserOnBlur = this.handleDropdownUserOnBlur.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateQuinasP = this.updateQuinasP.bind(this);
    this.showQuinasP = this.showQuinasP.bind(this);

    this.showNextCurrency = (e) => {
      e.stopPropagation();
      e.preventDefault();
      console.log('nextCurrency');
      console.log('nextCurrency', e, this.state.currentCurrency);
      this.setState({
        currentCurrency: this.state.currentCurrency === 2 ? 0 : this.state.currentCurrency + 1,
      });
    };
  }

  /**
   *This function the Updates User Info rendered in the component
   *
   * @memberof AvatarDrop
   */
  updateUserInfo() {
    this.setState({
      userData: utils.getUserInfo(),
    });
  }

  /**
   *Private Quinielas Modal Handlers
   Show and hide
   *
   * @memberof AvatarDrop
   */
  showQuinasP() {
    this.setState({
      showQuinielasP: true,
    });
  }

  updateQuinasP() {
    this.setState({
      showQuinielasP: false,
    });
  }

  /**
   *Handlers for the Dropdown Menu
   *
   * includes Mouse Over
   * Click
   * Focus
   * Blur
   * @param {*} e
   * @memberof AvatarDrop
   */
  handleDropdownUserOver(e) {
    console.log('over');
    this.setState({
      activeDropdown: !this.state.activeDropdown,
    });
  }

  handleClickOutside() {
    this.setState({
      activeDropdown: false,
    });
  }

  handleDropdownUserClick(e) {
    console.log('click');
    if (window.innerWidth > 669) {
      window.location = '/usuario/personal';
    }
  }

  handleDropdownUserOnBlur(e) {
    // console.log('onBlur',e);
    this.setState({
      activeDropdown: false,
    });
  }

  handleDropdownUserOut(e) {
    // console.log(e);
    this.setState((prevState) => {
      return {
        activeDropdown: !prevState.activeDropdown,
      };
    });
  }

  render() {
    const hideReferralVIP = !this.state.userData?.has_vip_referrals

    let menuList = config.userMenu

    if (hideReferralVIP) {
      menuList = config.userMenu.filter(item => item.id !== 'invitados-vip')
    }

    return (
      <div className="wrapper avatar-drop">
        {window.innerWidth > 669 ? (
          <ReactTooltip id="userInfoTooltip" type="light" place="bottom" effect="solid">
            Información del usuario
          </ReactTooltip>
        ) : null}
        <div
          data-tip
          data-for="userInfoTooltip"
          className={`avatar-wrapper ${this.state.activeDropdown ? 'active' : ''} `} // onClick={this.handleDropdownUserOut}
          onClick={this.handleDropdownUserOver}
        >
          <div className="flex-row row-no-padding row-center">
            <div
              className="flex-col col-center text-center col-10 "
              onClick={(e) => {
                this.showNextCurrency(e);
              }}
            >
              <div className="flex-row  row-center">
                <i
                  className={`ion-ios-arrow-${this.state.currentCurrency !== 2 ? 'left' : 'right'}`}
                ></i>
                <i
                  className={`ion-ios-arrow-${this.state.currentCurrency !== 2 ? 'left' : 'right'}`}
                ></i>
              </div>
            </div>
            <div id="userBalanceInfoSlider" className="flex-col col-no-padding">
              <SlideCurrency
                userData={this.props.userData}
                userBalance={this.props.userBalance}
                currentCurrency={this.state.currentCurrency}
              />
            </div>
            <div className={`flex-col col-30  ${$(window).width() < 300 ? 'hide' : ''}`}>
              <span className="btnAvatar flex-container  justify align">
                {this.props.userData.avatar === null || this.props.userData.avatar === undefined ? (
                  <img
                    style={{ backgroundColor: '#fff' }}
                    src={avatar}
                    className="img-responsive usr-avatar"
                  />
                ) :
                  this.props.userData.avatar !== undefined ?
                  (
                  <img
                    style={{ backgroundColor: '#fff' }}
                    src={
                      this.props.userData.avatar === avatar
                        ? avatar
                        : `${this.props.userData.avatar_dir + this.props.userData.avatar}`
                    }
                    className="img-responsive usr-avatar"
                  />
                )
                :
                (
                  <img
                    style={{ backgroundColor: '#fff' }}
                    src={
                      this.props.userData.user.avatar === avatar
                        ? avatar
                        : `${this.props.userData.user.avatar_dir + this.props.userData.user.avatar}`
                    }
                    className="img-responsive usr-avatar"
                  />
                )
                }
              </span>
            </div>
          </div>
        </div>

        {this.state.activeDropdown ? (
          <div className="user-Dropdown flex-col">
            <div className="user-info-dropdown flex-col">
              <div className="flex-row row-no-padding">
                <LinkContainer className="user-info-item" to='/usuario/mi-cuenta'>
                  <strong style={{ fontSize: '1.1em' }}>{this.props.userData.nick}</strong>
                </LinkContainer>
              </div>
            </div>
            <div className="user-actions-dropdown flex-col">
              <Nav bsStyle="pills" stacked>
                {menuList.map(option => (
                  <LinkContainer to={option.link} key={option.link}>
                    <NavItem>
                      <Iconify icon={option.icon} />
                      <span>{option.title}</span>
                    </NavItem>
                  </LinkContainer>
                ))}
              </Nav>
              <Button className="btn-transparent btn-salir" onClick={this.props.logout}>
                <i className="ion-log-out" />
                <span>SALIR</span>
              </Button>
            </div>
          </div>
        ) : null}

        <PanelQuinielasPrivadas
          showQuinielasPrivadas={this.state.showQuinielasP}
          updatePanelState={this.updateQuinasP}
        />
      </div>
    );
  }
}


const NavItem = styled(NavItemBase)`
  a {
    gap: 0.5em 0.7em;
    display: flex !important;
    align-items: center;
  }
  
  svg {
    font-size: 16px;
  }

  span {
    font-size: 13px;
  }
`

export default onClickOutside(AvatarDrop);
