import React from 'react'
import Formsy from 'formsy-react'
import {
  Button,
  Modal,
} from 'react-bootstrap/lib'

// Components
import ChangePassInput from './ChangePassInput'

// Utils
import API from '../../../../../utils/api'
import utils from '../../../../../utils/utils'

class ChangePass extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitting: false,
      failed: false,
      canSubmit: false,
      currentPass: null,
      newPass: null,
      newPassConfirmation: null,
      responseData: null,
      showResponse: false
    }

    this.mapErrors = () => {
      if (!this.state.responseData) { return }
      const errors = this.state.responseData.errors
      const errorsFields = Object.keys(errors)
      const errorMessage = []
      console.log('Errors Fields', errorsFields)
      errorsFields.forEach((err, errIndex) => {
        const errorTypeField = Object.keys(errors[errorsFields[errIndex]])
        console.log('ErrorTypeField', errorTypeField)
        errorTypeField.forEach(errType => {
          errorMessage.push(errors[errorsFields[errIndex]][errType])
        })
      })
      console.log('Errors Message', errorMessage)
      const errorsList = errorMessage.map((err, errIndex) => {
        return (
          <div key={errIndex} className='text-container text-center'>
            <strong style={{ textTransform: 'capitalize', marginRight: '3px' }}>{errorsFields[errIndex]}</strong>
            <span>{err}</span>
          </div>
        )
      })
      return errorsList
    }

    this.handleResponse = () => {
      this.setState({ showResponse: !this.state.showResponse })
    }

    this.savePassword = () => {
      API.postChangePassword({
        oldpassword: this.state.currentPass,
        password: this.state.newPass,
        cpassword: this.state.newPassConfirmation
      }).then(response => {
        console.log(response)

        this.handleResponse()
        // Good
        if (response.data.success) {
          this.setState(() => {
            return {
              responseData: response.data,
              successChange: true,
              errorChange: false
            }
          }, () => {
          })
        } else {
          this.setState(() => {
            return {
              responseData: response.data,
              errorChange: true,
              successChange: false
            }
          })
        }
      })
    }

    this.handleNewPassword = (e) => {
      this.setState({
        newPass: e.target.value
      })
    }

    this.handleNewPasswordConfirmation = (e) => {
      this.setState({
        newPassConfirmation: e.target.value
      })
    }

    this.handlePassword = (e) => {
      this.setState({
        currentPass: e.target.value
      })
    }
    this.disableButton = this.disableButton.bind(this)
    this.enableButton = this.enableButton.bind(this)
    // console.log('formLogin', this.props)
  }

  disableButton () {
    // console.log('disable button');
    this.setState({ canSubmit: false })
  }

  enableButton () {
    // console.log('Enable Button')
    this.setState({ canSubmit: true })
  }

  render () {
    const nick = utils.getUserInfo().nick
    if (!this.props.showChangePass) {
      return null
    }
    return (
      <Formsy
        className='flex-col col-90 col-offset-5'
        onValidSubmit={e => { this.savePassword(e) }}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Modal
          show={this.state.showResponse}
          className='responseCashoutModal'
          dialogClassName='responseCashoutDialog'
          backdropClassName='responseCashoutBackdrop'
        >
          <div className='transactionPanel panel-pickwin'>
            <div
              id='panelTitle'
              className={`panel-abonar-titulo tex-center ${
              !this.state.responseData || !this.state.responseData.success ? 'failed' : ''
            }`}
            >
              {this.state.responseData && this.state.responseData.success
                ? (
                  <span className='text-center'>! {nick} tu contraseña se cambio exitosamente!</span>
                  )
                : (
                  <span className='text-center'>{nick} tenemos un problema.</span>
                  )}
              <button
                className='cerrar-panel' onClick={() => {
                  this.handleResponse()
                  if (this.state.successChange) {
                    this.props.showPass()
                  }
                }}
              >
                <div className='btn-icon-close'>
                  <i className='ion-ios-close-empty' />
                </div>
              </button>
            </div>
            <div className='flex-col'>
              <div className='flex-row'>
                <div className='flex-container column justify align'>
                  {this.state.successChange
                    ? <i className='ion-checkmark-circled icon-success success' />
                    : <i className='ion-close-circled icon-failed failed' />}
                </div>
              </div>
              <div className='flex-row'>
                {this.state.successChange
                  ? <div className='flex-container justify align column'>
                    ¡Tu contraseña se cambio con éxito!
                  </div>
                  : <div className='flex-container justify align column'>
                    {this.mapErrors()}
                    </div>}
              </div>
            </div>
          </div>
        </Modal>
        <div className='flex-row row-no-padding'>
          <div className='flex-col'>
            <ChangePassInput
              inputHandler={this.handlePassword}
              label='Contraseña Actual'
              name='password'
              required
              defaultValue={this.state.currentPass}
              type='PASS'
              validations='minLength:6'
              validationError='La contraseña es requerida.'
              viewPass={false}
              handleViewPass={null}
            />
          </div>
        </div>
        <div className='flex-row row-no-padding'>
          <div className='flex-col'>
            <ChangePassInput
              inputHandler={this.handleNewPassword}
              label='Nueva Contraseña'
              name='newPassword'
              required
              defaultValue={this.state.newPass}
              type='PASS'
              validations='minLength:6'
              validationError='La contraseña nueva es requerida.'
              viewPass={false}
              handleViewPass={null}
            />
          </div>
          <div className='flex-col'>
            <ChangePassInput
              inputHandler={this.handleNewPasswordConfirmation}
              label='Confirma Nueva Contraseña'
              name='newPasswordConf'
              required
              defaultValue={this.state.newPassConfirmation}
              type='PASS'
              validations='equalsField:newPassword'
              validationError='La contraseña no es la misma.'
              viewPass={false}
              handleViewPass={null}
            />
          </div>
        </div>
        <div className='flex-row'>
          <Button
            type='submit'
            disabled={this.state.submitting || !this.state.canSubmit}
            className='callToAction btn-form btn-pick'
          >
            {this.state.submitting
              ? (
                <div className='flex-container justify align'>
                  <i className='ion-load-a loading small' />
                </div>
                )
              : (
                <span className='btn-text'>
                  Guardar contraseña
                </span>
                )}
          </Button>
        </div>

      </Formsy>
    )
  }
}

export default ChangePass
