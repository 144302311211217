import React, { useEffect } from 'react';
import NavigationBar from '../NavigationBar';
import {Form, FormGroup, Button, FormControl} from 'react-bootstrap/lib/';
import API  from '../../utils/api';
import PageWrapper from '../PageWrapper';
import useMergedState from '../../hooks/useMergedState';
import useAuthContext from '../../hooks/useAuthContext';


const ResetPassword = (props) => {
  const { isAuthenticated, userData, logout, loginStatus } = useAuthContext()

  const [state, setState] = useMergedState({
    password: null, 
    cpassword: null,
    token: props.match.params.token, 
    errorMismatch: false,
    changeSuccess: null,
    submittingForm: false,
  })

  const doResetPassword = (e) => {
    e.preventDefault();
    setState({errorMismatch: false, changeSuccess: null});
    if ( state.password !== state.cpassword ) {
      setState({errorMismatch: true});
      return false;
    }
    setState({submittingForm: true});
    API.postResetPassword(state.token, {'password': state.password, 'cpassword': state.cpassword})
    .then((response) => {
      let data = response.data;
      if ( data.hasOwnProperty('success') && data.success ) {
        setState({changeSuccess: true, submittingForm : false});
        setTimeout(() => {
          window.location = '/ingresar';
        }, 2000);
      }
    })
    .catch((error) => {     
      setState({changeSuccess: false, submittingForm: false});
    }) 
    return false;
  }

  const handlePassword = (e) => {
    setState({password: e.target.value});
  }

  const handleConfirmPassword = (e) =>  {
    setState({cpassword: e.target.value});
  }

  useEffect(() => {
    if (isAuthenticated && logout && !loginStatus) {
      logout(false)
    }
  }, [isAuthenticated, loginStatus])

  return (
    <div>
      <NavigationBar logged={isAuthenticated} userData={userData} />
      <div id='loginPanel' >
        <div className='loginTitle'>
          <span>CAMBIAR PASSWORD</span>
        </div>
        <div className='login-fields'>
          { state.errorMismatch ? <span className={'error-formulario'}>Las contraseñas no coinciden</span> : null }
          { state.changeSuccess === false ? <span className={'error-formulario'}>Lo sentimos, no podemos cambiar tu contraseña.</span> : null }
          { state.changeSuccess === true ? <div style={{'color':'#78BC28', 'textAlign': 'center'}}><strong><i className="ion-checkmark-circled"></i>Tu contraseña se cambio con exito.<br/>En un momento te redireccionaremos a la pantalla de login.</strong></div> : null }
          <Form horizontal onSubmit={doResetPassword}>
            <div className='field-form'>
              <FormGroup>
                <FormControl type='password' minLength='4' onChange={handlePassword} placeholder="Nueva Contraseña" required/>
              </FormGroup>
            </div>
            <div className='field-form'>
              <FormGroup>
                <FormControl type='password' minLength='4' onChange={handleConfirmPassword} placeholder="Repetir Contraseña" required/>
              </FormGroup>
            </div>
            <Button
              type='submit'              
              className='login-btn'
              bsStyle="primary"
              bsSize="large"
              block
              disabled={state.submittingForm}
            >
              { state.submittingForm ? 'ESPERA UN MOMENTO...' : ' CAMBIAR PASSWORD' }
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword;