import Score, {
  Reglas,
  PopoverReglas,
  popoverReglasMundial,
  popoverReglasBracket,
  //popoverReglasSurvivorNatural,
  //popoverReglasSurvivorLinea,
  //popoverReglasSurvivorStrikeNatural,
  //popoverReglasSurvivorStrikeLinea,
  popoverQueEsStrike,
  popoverQueEsStrikeLinea,
} from './Score';
export default Score;

export {
  Reglas,
  PopoverReglas,
  popoverReglasMundial,
  popoverReglasBracket,
  //popoverReglasSurvivorNatural,
  //popoverReglasSurvivorLinea,
  //popoverReglasSurvivorStrikeNatural,
  //popoverReglasSurvivorStrikeLinea,
  popoverQueEsStrike,
  popoverQueEsStrikeLinea,
};
