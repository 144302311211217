import React from 'react';
import $ from 'jquery';

import { ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap/lib';

const WeeksControls = ({ selectedWeek, weeks, handleWeekChange }) => {
  if (!weeks) {
    return null;
  }

  let weeksButtons = weeks.map((week) => {
    return (
      <ToggleButton
        key={week.id}
        className={`${$(window).width() < 769 ? 'col-10 bottom-separation' : ''} flex-col col-5`}
        value={week.id}
        style={{ width: 'fit-content', padding: '0 0.8em', display: 'flex', maxWidth: '100%' }}
      >
        {week.week_name}
      </ToggleButton>
    );
  });

  //let conditionalComponent = null;
  return (
    <div className="flex-row font-chivo">
      <ButtonToolbar id="week-controls" className="flex-col">
        <ToggleButtonGroup
          style={$(window).width() <= 768 ? { flexWrap: 'wrap' } : {}}
          className={`
              ${$(window).width() <= 768 ? 'flex-container align justify' : 'flex-row row-wrap'}`}
          name="weeks-controls"
          type="radio"
          value={selectedWeek}
          onChange={handleWeekChange}
        >
          {weeksButtons}
        </ToggleButtonGroup>
      </ButtonToolbar>
    </div>
  );
};

export default WeeksControls;
