import styled from 'styled-components'

export const TextGradient = styled.span`
  background: #F9E799;
  background: linear-gradient(to bottom, #F9E799 41%, #C8AE72 50%, #F2E3A3 64%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  line-height: 2.2;
  margin: 0;
`
