import React from 'react'
import { Button, Modal, Typography } from '../../Xmerald-UI'

import './promotions.scss';
import styled from "styled-components";

export default function PromotionDetails ({ isOpen, onClose, promotion }) {
  const callToAction = () => {
    window.location.href = promotion.redirect.desktop    
  }

  return (
    <Modal show={isOpen} onClose={() => onClose({})} title={promotion.title}>
      <Content>
        <Image src={promotion.imgDesktop} alt='Imagen de promocion' />
        <Typography center as='h4' mt={10} mb={20}>{promotion.name}</Typography>

        <ButtonContent>
          <Button
            delayClick={250}
            bgColor='#78bc28'
            onClick={callToAction}
          >
            {promotion.okText}
          </Button>
        </ButtonContent>
        
        <Descripcion dangerouslySetInnerHTML={{ __html: promotion.description }} />
      </Content>
    </Modal>    
  )
}

const ButtonContent = styled.div`
  font-size: 1.3em;
  margin: 0 auto;
  margin-bottom: 2em;
  padding-bottom: 1.5em;
  width: 90%;
  border-bottom: 1px dashed #313131;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 80vh;
`

const Image = styled.img`
  width: 95%;
`

const Descripcion = styled.div`
  font-size: 0.9em;

  ul {
    list-style: none;
    margin: 0.5em 0;
    padding: 0;
    padding-left: 2em;
  }

  ul li:before {
    content: "•";
    color: #78BC28;
    display: inline-block; width: 1em;
    margin-left: -1em
  }
`