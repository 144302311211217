// Libraries
import React from 'react';

// Images
import picks from '../../assets/images/quinielasIconBlanco-50.png';
import guardar from '../../assets/images/guardarPicks-50.png';
import abonar from '../../assets/images/abonarIconBlanco-50.png';

const BracketTennisRegisterSteps = (props) => {
  let conditionalComponent = (
    <div id="registerSteps" className="row">
      <div className="col-xs-12 text-center register-title-container">
        <span className="register-title">
          <i className="ion-flag" />
          Sigue estos pasos para participar en el bracket.
        </span>
      </div>
      {props.isBalanceEnough ? (
        <div className="col-xs-12 steps-container">
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Llena tus picks</span>
          </div>
          <div className="col-xs-6 col-md-6 register-step-item">
            <span className="step-number">2</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu bracket</span>
          </div>
        </div>
      ) : (
        <div className="col-xs-12 steps-container">
          <div className="col-xs-4  register-step-item">
            <span className="step-number">1</span>
            <img src={picks} className="step-icon" />
            <span className="step-label">Llena tus picks</span>
          </div>
          <div className="col-xs-4  register-step-item">
            <span className="step-number">2</span>
            <img src={abonar} className="step-icon" />
            <span className="step-label">Abona saldo</span>
          </div>
          <div className="col-xs-4  register-step-item">
            <span className="step-number">3</span>
            <img src={guardar} className="step-icon" />
            <span className="step-label">Guarda tu bracket</span>
          </div>
        </div>
      )}
    </div>
  );
  return conditionalComponent;
};

export default BracketTennisRegisterSteps;