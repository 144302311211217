import React from 'react';
import API from '../../utils/api';
import Utils from '../../utils/quinielasUtils';
import { Button, Modal } from 'react-bootstrap/lib';
import ticket from '../../assets/images/silver_ticket_pick_empty.png';
import utils from '../../utils/utils';
import './cancelRegister.scss';
import groupPoolActions from '../QuinielaGroupsUI/actions';
import { DisplayFlag, DisplaySport } from '../../utils/quinielasComponents';
import line from '../../assets/images/lineaIcon-50.png';
import garantizada from '../../assets/images/garantizadaIcon-50.png';
import natural from '../../assets/images/naturalIcon-50.png';
import bracket from '../../assets/images/bracketIcon.png';

import mockSurvivorEntry from '../../tests/__mockData__/mockSurvivorEntry.json';
import $ from 'jquery';
import _ from 'underscore';
import { publish } from '../../utils/events';

const CancelList = ({ entries, gameType, handleCancelation, cancelationModel }) => {
  let conditionalComponent = null;

  if (!entries) {
    return <div className="lista-cancelar-entradas">Cargando tus entradas ...</div>;
  } else {
    switch (gameType) {
      case 'pool':
        conditionalComponent = (
          <label
            id={entries.entry.id}
            onClick={(e) => {
              e.preventDefault();
              handleCancelation(entries.entry.id);
            }}
          >
            <input
              data-id={entries.entry.id}
              type="checkbox"
              name="id[]"
              checked={
                !cancelationModel[entries.entry.id]
                  ? 'null'
                  : cancelationModel[entries.entry.id].selected
              }
              id={`${entries.entry.id}`}
              value={
                !cancelationModel[entries.entry.id]
                  ? 'null'
                  : cancelationModel[entries.entry.id].selected
              }
              onChange={() => {}}
            />
            <div className={`checkbox-custom`}></div>
            <span>{entries.entry.number}</span>
            <span>
              {!cancelationModel[entries.entry.id]
                ? 'null'
                : cancelationModel[entries.entry.id].selected}
            </span>
            {entries.entry.ticket_id !== null ? (
              <img src={ticket} className="entry_ticket" />
            ) : null}
          </label>
        );
        break;
      case 'survivor':
        conditionalComponent = (
          <label
            id={entries.survivor_entry.id}
            onClick={(e) => {
              e.preventDefault();
              handleCancelation(entries.survivor_entry.id);
            }}
          >
            <input
              data-id={entries.survivor_entry.id}
              type="checkbox"
              name="id[]"
              checked={
                !cancelationModel[entries.survivor_entry.id]
                  ? 'null'
                  : cancelationModel[entries.survivor_entry.id].selected
              }
              id={`${entries.survivor_entry.id}`}
              value={
                !cancelationModel[entries.survivor_entry.id]
                  ? 'null'
                  : cancelationModel[entries.survivor_entry.id].selected

              }
              onChange={() => {}}
            />
            <div className={`checkbox-custom`}></div>
            <span>{entries.survivor_entry.number}</span>
            {entries.survivor_entry.ticket_id !== null ? (
              <img src={ticket} className="entry_ticket" />
            ) : null}
          </label>
        );
        break;
      case 'bracket':
        conditionalComponent = (
          <label
            id={entries.bracket_entry.id}
            onClick={(e) => {
              e.preventDefault();
              handleCancelation(entries.bracket_entry.id);
            }}
          >
            <input
              data-id={entries.bracket_entry.id}
              type="checkbox"
              name="id[]"
              checked={
                !cancelationModel[entries.bracket_entry.id]
                  ? 'null'
                  : cancelationModel[entries.bracket_entry.id].selected
              }
              id={`${entries.bracket_entry.id}`}
              value={
                !cancelationModel[entries.bracket_entry.id]
                  ? 'null'
                  : cancelationModel[entries.bracket_entry.id].selected
              }
            />
            <div className={`checkbox-custom`}></div>
            <span>{entries.bracket_entry.number}</span>
            {entries.bracket_entry.ticket_id !== null ? (
              <img src={ticket} className="entry_ticket" />
            ) : null}
          </label>
        );
        break;
      case 'group_pool':
        conditionalComponent = (
          <label
            id={entries.group_pool_entry.id}
            onClick={(e) => {
              e.preventDefault();
              handleCancelation(entries.group_pool_entry.id);
            }}
          >
            <input
              data-id={entries.group_pool_entry.id}
              type="checkbox"
              name="id[]"
              checked={
                !cancelationModel[entries.group_pool_entry.id]
                  ? 'null'
                  : cancelationModel[entries.group_pool_entry.id].selected
              }
              id={`${entries.group_pool_entry.id}`}
              value={
                !cancelationModel[entries.group_pool_entry.id]
                  ? 'null'
                  : cancelationModel[entries.group_pool_entry.id].selected
              }
            />
            <div className={`checkbox-custom`}></div>
            <span>
              {(entries.group_pool_entry.number === null && '1') || entries.group_pool_entry.number}
            </span>
            {entries.group_pool_entry.ticket_id !== null ? (
              <img src={ticket} className="entry_ticket" />
            ) : null}
          </label>
        );
        break;
    }

    const listEntries =
      entries.other_entries.length === 0
        ? null
        : entries.other_entries.map((entry, i) => {
            return (
              <label
                key={entry.id}
                id={entry.id}
                onClick={(e) => {
                  e.preventDefault();
                  handleCancelation(entry.id);
                }}
              >
                <input
                  data-id={entry.id}
                  type="checkbox"
                  name="id[]"
                  id={`${entry.id}`}
                  checked={
                    !cancelationModel[entry.id] ? 'null' : cancelationModel[entry.id].selected
                  }
                  value={!cancelationModel[entry.id] ? 'null' : cancelationModel[entry.id].selected}
                  onChange={() => {}}
                />
                <div className={`checkbox-custom`}></div>
                <span>{entry.number === null ? '1' : entry.number}</span>
                {entry.ticket_id !== null ? <img src={ticket} className="entry_ticket" /> : null}
              </label>
            );
          });

    return (
      <div className="lista-cancelar-entradas">
        {conditionalComponent}
        {listEntries}
      </div>
    );
  }
};

class CancelRegister extends React.Component {
  constructor(props) {
    super(props);
    // console.log('Register Panel', this.props);

    this.state = {
      submitting: false,
      cancelationData: this.props.temporalEntry,
      cancelationSuccess: null,
      cancelationModel: null,
      cancelationList: [],
      entries: null,
      errorCupo: false,
      logged: this.props.logged,
      redirect: false,
      refresh: null,
      respuestas: null,
      mounted: false,
      changedCancelModel: false,
      changeCancelationStatus: false,
      gameType: null,
      showPanel: this.props.showPanel,
      updateStateFunc: this.props.updateStateFunc,
      userData: this.props.userDataProps,
      selectAll: false,
    };
    this.disableClickedButton = Utils.disableClickedButton.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.redirectAfterCancel = this.redirectAfterCancel.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleCancelation = this.handleCancelation.bind(this);
    this.cancelation = this.cancelation.bind(this);
    this.resetCancelation = this.resetCancelation.bind(this);
    // console.log('Register Panel', this);
    this.resetData = () => {
      console.log('reset');
      this.setState({
        cancelationData: null,
        cancelationList: [],
        showPanel: false,
      });
    };

    this.handleSelectAll = () => {
      // console.log('%c Select All', 'font-size:2em;');
      let tempCancelationModel = this.state.cancelationModel;
      let changedCancelationStatus = false;
      this.setState((prevState) => {
        if (prevState.selectAll) {
          _.each(tempCancelationModel, (entry) => {
            let searchThis = `.lista-cancelar-entradas  #${entry.id}input`;
            $(searchThis).val(false);
            // console.log('UnCanceling all...', entry);
            entry.selected = false;
          });
        } else {
          _.each(tempCancelationModel, (entry) => {
            let searchThis = `.lista-cancelar-entradas #${entry.id}input`;
            // console.log(searchThis);
            // console.log($(searchThis));
            $(searchThis).val(true);
            // console.log('Canceling all...', entry);
            entry.selected = true;
          });
          changedCancelationStatus = true;
        }

        // console.log(tempCancelationModel);

        return {
          cancelationModel: tempCancelationModel,
          selectAll: !prevState.selectAll,
          changeCancelationStatus: changedCancelationStatus,
        };
      });
    };
    // console.log('Register Panel', this);
    this.initializeCancelation = (entries) => {
      // console.log( '%c initialize Cancelation', 'color: #2e8ccf ; font-size: 2em ;' );
      if (!entries) {
        return;
      }
      this.setState({ mounted: false });
      // console.log(entries);
      let allEntries = null;
      let cancelationModel = {};

      switch (this.state.gameType) {
        case 'pool':
          allEntries = [entries.entry, ...entries.other_entries];
          break;
        case 'group_pool':
          allEntries = [entries.group_pool_entry, ...entries.other_entries];
          break;
        case 'bracket':
          allEntries = [entries.bracket_entry, ...entries.other_entries];
          break;
        case 'survivor':
          allEntries = [entries.survivor_entry, ...entries.other_entries];
          break;
      }

      allEntries.forEach((entries) => {
        cancelationModel[entries.id] = { selected: false, id: entries.id };
      });

      this.setState({ cancelationModel, mounted: true }, () => {
        // console.log('cancellationReady', this.state )
      });
    };

    this.updateBalanceFromPartner = (cancelData, entryFee) => {
      console.log('Updating Partner balance ');
      console.log(cancelData, entryFee);
      let currentBalance = utils.getUserBalance();
      console.log('Balance:', currentBalance);

      let newBalanceReal = null;

      if (cancelData.hasOwnProperty('allEntries')) {
        newBalanceReal = currentBalance.balanceReal + entryFee * cancelData.allEntries.length;
      } else if (cancelData.hasOwnProperty('groupPoolEntries')) {
        newBalanceReal = currentBalance.balanceReal + entryFee * cancelData.groupPoolEntries.length;
      } else if (cancelData.hasOwnProperty('survivorEntries')) {
        newBalanceReal = currentBalance.balanceReal + entryFee * cancelData.survivorEntries.length;
      } else {
        newBalanceReal = currentBalance.balanceReal + entryFee * cancelData.entries.length;
      }

      console.log('NewBalance', newBalanceReal);

      let newBalanceAfterRegister = {
        balanceReal: newBalanceReal,
        balancePc: 0,
        balanceReferral: 0,
        balancePcReal: 0,
        tickets: [],
      };
      console.log(newBalanceAfterRegister);
      utils.setUserBalance(newBalanceAfterRegister, true);
    };
  }

  /**
   * This function makes a list of the selected entries to cancel
   * It pases the target to get the id of the quiniela that will be canceled
   * @param e
   */
  handleCancelation(e) {
    //let value = e;

    let tempCancelationModel = this.state.cancelationModel;
    let currentSelection = tempCancelationModel[e];

    // console.log("EEE", e);
    // console.log( currentSelection );

    if (!currentSelection) {
      return;
    }

    tempCancelationModel[e].selected = !tempCancelationModel[e].selected;

    this.setState(
      {
        cancelationModel: tempCancelationModel,
        changedCancelModel: true,
        changeCancelationStatus: true,
        selectAll: false,
      },
      () => {
        this.setState({
          changedCancelModel: false,
        });
      }
    );
  }

  /**
   * This function refresh after the cancelation
   */
  refresh() {
    this.setState({
      refresh: true,
    });
  }

  /**
   * This function reset the state after the Panel is closed
   */
  resetCancelation() {
    console.log('resetCancelation');
    this.setState(
      {
        showPanel: false,
        cancelationList: [],
        selectAll: false,
        entries: null,
        cancelationSuccess: null,
        cancelationResponse: null,
      },
      () => {
        this.initializeCancelation();
      }
    );
  }

  /**
   * This function cancel makes the Post to cancel one or several Entries
   */
  cancelation() {
    if (this.state.submitting) {
      return;
    }
    // console.log('%c Canceling entries', 'background:#000; color:#fff;');
    let formData = new FormData();
    this.setState({
      submitting: true,
    });
    // utils.setMisQuinielasActiveItems('');
    _.each(this.state.cancelationModel, (item) => {
      // console.log(typeof(item));

      // let it = {'id[]':Number(item)};
      if (item.selected) {
        formData.append('id[]', Number(item.id));
      }
      // console.log(formData);
    });
    console.log( this.props.type );
    if (this.props.type === 'bracket' || this.props.pool.game_type === 'bracket') {
      API.postUnregisterBracket(formData).then((response) => {
        if (response.data.result.success) {
          console.log('Response de Cancelation',response.data);

          this.props.updateUserInfo();

          this.setState(
            {
              selectAll: false,
              submitting: false,
              cancelationSuccess: true,
              cancelationResponse: response.data,
            },
            () => {
              /**
               * If the cancelation came from the QuinielasInfoPopUp
               * check if there are remaining Entries to redirect
               */
              if (this.props.redirectAfterCancel !== undefined) {
                if (response.data.result.remaining.length < 1) {
                  if (this.props.type === 'lobby') {
                    this.props.redirectAfterCancel(
                      this.props.pool.id,
                      this.state.cancelationModel,
                      true
                    );
                  } else {
                    window.location.assign('/lobby');
                    if (this.props.redirectToLobby !== undefined) {
                      this.props.redirectToLobby();
                    }
                  }
                } else if (this.props.allEntries !== undefined) {
                  /**
                   * IF the cancelation came from QuinielasLobby
                   * Update the data
                   */
                  if (response.data.result.remaining.length < 1) {
                    console.log('here');
                    this.props.redirectToLobby();
                  } else {
                    this.props.refreshAfterCancel(
                      response.data.result.remaining,
                      response.data.result.remaining[0].id,
                      response.data.result.remaining[0].bracket_id,
                      response.data.result.entries
                    );
                  }
                } else {
                  if (this.props.type === 'lobby') {
                    this.props.redirectAfterCancel(this.props.pool.id, this.state.cancelationModel);
                  } else {
                    if (this.props.refreshAfterCancel !== undefined) {
                      this.props.refreshAfterCancel(
                        response.data.result.remaining,
                        response.data.result.remaining[0].id,
                        response.data.result.remaining[0].pool_id,
                        response.data.result.entries
                      );
                    }
                  }
                }
              }
            }
          );
        } else {
          this.setState({
            selectAll: false,
            submitting: false,
            cancelationSuccess: false,
            cancelationResponse: response.data,
          });
        }
      });
    } else if (this.props.type === 'survivor' || this.props.pool.game_type === 'survivor') {
      let currentBalance = utils.getUserBalance();
      API.postUnregisterSurvivor(formData).then((response) => {
        if (response.data.result.success) {
          console.log('Response de Cancelation Surivor', response.data);

          publish('UPDATE_MY_PICKS', 'cancel Survivor')


          this.props.updateUserInfo();

          this.setState(
            {
              selectAll: false,
              submitting: false,
              cancelationSuccess: true,
              cancelationResponse: response.data,
            },
            () => {
              /**
               * If the cancelation came from the QuinielasInfoPopUp
               * check if there are remaining Entries to redirect
               */
              if (this.props.refreshAfterCancel !== undefined) {
                /**
                 * If remain less than 1 redirect to lobby
                 */
                if (response.data.result.remaining.length < 1) {
                  if (this.props.type === 'lobby') {
                    if (this.props.updateUserBalance) {
                      let entriesBalance =
                        this.props.pool.entry_fee * response.data.result.survivorEntries.length;
                      let newBalance = currentBalance.balanceReal + entriesBalance;
                      this.props.updateUserBalance(newBalance);
                    }

                    this.props.redirectAfterCancel(
                      this.props.pool.id,
                      this.state.cancelationModel,
                      true
                    );
                    this.resetData();
                  } else {
                    window.location.assign('/lobby');
                    if (this.props.redirectToLobby !== undefined) {
                      this.props.redirectToLobby();
                    }
                  }
                } else if (
                  /**
                   * IF the cancelation came from QuinielasLobby
                   * Update the data
                   */
                  this.props.hasOwnProperty('survivorEntries') ||
                  this.props.allEntries.hasOwnProperty('survivor_entry')
                ) {
                  if (response.data.result.remaining.length < 1) {
                    console.log('here');
                    this.props.redirectToLobby();
                  } else {
                    console.log('Refresh');
                    if (this.props.updateUserBalance) {
                      let entriesBalance =
                        this.props.pool.entry_fee * response.data.result.survivorEntries.length;
                      let newBalance = currentBalance.balanceReal + entriesBalance;
                      this.props.updateUserBalance(newBalance);
                    }
                    this.props.refreshAfterCancel(
                      response.data.result.remaining,
                      response.data.result.remaining[0].id,
                      response.data.result.remaining[0].survivor_id,
                      response.data.result.survivorEntries
                    );
                  }
                } else {
                  if (this.props.type === 'lobby') {
                    if (this.props.updateUserBalance) {
                      let entriesBalance =
                        this.props.pool.entry_fee * response.data.result.survivorEntries.length;
                      let newBalance = currentBalance.balanceReal + entriesBalance;
                      this.props.updateUserBalance(newBalance);
                    }
                    this.props.redirectAfterCancel(this.props.pool.id, this.state.cancelationModel);
                    this.resetData();
                  } else {
                    console.log('Refresh');

                    if (this.props.refreshAfterCancel !== undefined) {
                      this.props.refreshAfterCancel(
                        response.data.result.remaining,
                        response.data.result.remaining[0].id,
                        response.data.result.remaining[0].survivor_id,
                        response.data.result.survivorEntries
                      );
                    }
                  }
                }
              }
              //Row Cancellation
              else {
                this.resetData();
                this.props.redirectAfterCancel(this.props.pool.id, this.state.cancelationModel);
                if (this.props.updateUserBalance) {
                  let entriesBalance =
                    this.props.pool.entry_fee * response.data.result.survivorEntries.length;
                  let newBalance = currentBalance.balanceReal + entriesBalance;
                  this.props.updateUserBalance(newBalance);
                }
              }
            }
          );
        } else {
          this.setState({
            selectAll: false,
            submitting: false,
            cancelationSuccess: false,
            cancelationResponse: response.data,
          });
        }
      });
    } else if (this.props.type === 'group_pool' || this.props.pool.game_type === 'group_pool') {
      groupPoolActions
        .cancelRegister(formData)
        .then((response) => {
          console.log(response);
          if (response) {
            if (this.props.dispatch) {
              groupPoolActions.handleCancellation(this.props.dispatch, response.data.result);

              this.setState({ submitting: false });
              if (response.data.result.remaining.length < 1) {
                console.log('not remaining entries');
                groupPoolActions.redirectTo(this.props.dispatch, '/lobby');
              } else {
                console.log('Update Balance ');

                groupPoolActions.updateUserBalance(
                  this.props.dispatch,
                  response.data.result.balanceReal
                );
                if (this.props.updateUserBalance) {
                  this.props.updateUserBalance(response.data.result.balanceReal);
                }
                // groupPoolActions.removeCancelEntriesToStandings(  this.props.dispatch, response.data.result.entries );
                let currentEntryExist = _.find(
                  response.data.result.remaining,
                  (rem) => rem.id === this.props.allEntries.group_pool_entry.id
                );

                // si es la misma entrada mandarlo a ala [0]
                if (!currentEntryExist) {
                  console.log('redirect to the 0  entry', response.data);
                  groupPoolActions
                    .updatePoolEntries(this.props.dispatch, response.data.result.remaining)
                    .then((responseUpdateEntries) => {
                      // let newLocation =
                      console.log(responseUpdateEntries);
                      groupPoolActions.getPoolEntry(
                        this.props.dispatch,
                        responseUpdateEntries.data.group_pool_entry.id
                      );
                      groupPoolActions.updateLocation(
                        this.props.pool.slug,
                        this.props.pool.id,
                        responseUpdateEntries.data.group_pool_entry.id,
                        false
                      );
                    });
                } else {
                  groupPoolActions
                    .updatePoolEntries(this.props.dispatch, response.data.result.remaining)
                    .then((responseUpdateEntries) => {
                      // let newLocation =
                      console.log(responseUpdateEntries);
                    });
                }
              }
            } else {
              let currentBalance = utils.getUserBalance();

              console.log('Sup');
              this.setState(
                {
                  selectAll: false,
                  submitting: false,
                  cancelationSuccess: true,
                  cancelationResponse: response.data,
                },
                () => {
                  /**
                   * If the cancelation came from the QuinielasInfoPopUp
                   * check if there are remaining Entries to redirect
                   */
                  if (this.props.redirectAfterCancel !== undefined) {
                    if (response.data.result.remaining.length < 1) {
                      console.log(this.props);
                      if (this.props.type === 'lobby') {
                        if (this.props.updateUserBalance) {
                          let entriesBalance =
                            this.props.pool.entry_fee * response.data.result.entries.length;
                          let newBalance = currentBalance.balanceReal + entriesBalance;
                          this.props.updateUserBalance(newBalance);
                        }
                        this.props.redirectAfterCancel(
                          this.props.pool.id,
                          this.state.cancelationModel,
                          true
                        );
                        this.resetData();
                      } else {
                        if (this.props.updateUserBalance) {
                          let entriesBalance =
                            this.props.pool.entry_fee * response.data.result.entries.length;
                          let newBalance = currentBalance.balanceReal + entriesBalance;
                          this.props.updateUserBalance(newBalance);
                        }
                        this.props.redirectToLobby();
                      }
                    } else if (this.props.allEntries !== undefined) {
                      /**
                       * IF the cancelation came from QuinielasLobby
                       * Update the data
                       */
                      if (response.data.result.remaining.length < 1) {
                        // console.log('here');
                        this.props.redirectToLobby();
                      } else {
                        this.props.refreshAfterCancel(
                          response.data.result.remaining,
                          response.data.result.remaining[0].id,
                          response.data.result.remaining[0].pool_id,
                          response.data.result.entries
                        );
                      }
                    } else {
                      if (this.props.type === 'lobby') {
                        if (this.props.updateUserBalance) {
                          let entriesBalance =
                            this.props.pool.entry_fee * response.data.result.entries.length;
                          let newBalance = currentBalance.balanceReal + entriesBalance;
                          this.props.updateUserBalance(newBalance);
                        }
                        this.props.redirectAfterCancel(
                          this.props.pool.id,
                          this.state.cancelationModel
                        );
                        this.resetData();
                      } else {
                        this.props.refreshAfterCancel(
                          response.data.result.remaining,
                          response.data.result.remaining[0].id,
                          response.data.result.remaining[0].pool_id,
                          response.data.result.entries
                        );
                      }
                    }
                  }
                }
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      API.postCancelRegister(formData).then((response) => {
        if (response.data.result.success) {
          console.log('Response de Cancelation', response.data);
          this.props.updateUserInfo();
          this.setState(
            {
              selectAll: false,
              submitting: false,
              cancelationSuccess: true,
              cancelationResponse: response.data,
            },
            () => {
              /**
               * If the cancelation came from the QuinielasInfoPopUp
               * check if there are remaining Entries to redirect
               */
              if (this.props.redirectAfterCancel !== undefined) {
                if (response.data.result.remaining.length < 1) {
                  if (this.props.type === 'lobby') {
                    this.props.redirectAfterCancel(
                      this.props.pool.id,
                      this.state.cancelationModel,
                      true
                    );
                    this.resetData();
                  } else {
                    this.props.redirectToLobby();
                  }
                } else if (this.props.allEntries !== undefined) {
                  /**
                   * IF the cancelation came from QuinielasLobby
                   * Update the data
                   */
                  if (response.data.result.remaining.length < 1) {
                    // console.log('here');
                    this.props.redirectToLobby();
                  } else {
                    this.props.refreshAfterCancel(
                      response.data.result.remaining,
                      response.data.result.remaining[0].id,
                      response.data.result.remaining[0].pool_id,
                      response.data.result.entries
                    );
                  }
                } else {
                  if (this.props.type === 'lobby') {
                    this.props.redirectAfterCancel(this.props.pool.id, this.state.cancelationModel);
                    this.resetData();
                  } else {
                    this.props.refreshAfterCancel(
                      response.data.result.remaining,
                      response.data.result.remaining[0].id,
                      response.data.result.remaining[0].pool_id,
                      response.data.result.entries
                    );
                  }
                }
              }
            }
          );
        } else {
          this.setState({
            selectAll: false,
            submitting: false,
            cancelationSuccess: false,
            cancelationResponse: response.data,
          });
        }
      });
    }
  }

  /**
   * This function Redirects to the lobby after the user cancel and dont have any Entry remaining
   */
  redirectAfterCancel() {
    this.setState({
      redirect: true,
    });
  }

  /**
   * This function handles if the User closes the panel
   */
  close() {
    // console.log('Close panel');
    this.setState({
      showPanel: false,
      cancelationSuccess: null,
      cancelationResponse: null,
    });
  }

  /**
   * This function handles if the User Opens the panel
   */
  open() {
    console.log('CancelPanel, open', this.state);
    this.setState({
      showPanel: true,
      // payType: this.state.payType,
    });
  }

  /********************
   * Lifetime Cycles
   *********************/

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('shouldComponentUpdate');
    // console.log(nextProps, this.props );
    // console.log(nextProps, this.props, this.state.entries, nextState.entries);
    // console.log(this.props);
    // console.log(this.state.cancelationList, nextState.cancelationList);
    if (this.props.showPanel !== nextProps.showPanel && nextProps.showPanel) {
      // console.log('Show Panel');
      // console.log(nextProps.pool);
      this.setState({ cancelationSuccess: null, cancelationResponse: null });
      if (nextProps.pool.entries) {
        // console.log('With Entries');

        if (nextProps.pool.entries !== undefined && nextProps.pool.game_type === 'pool') {
          // console.log('Get Entries', nextProps.pool);
          API.getEntriesInfo(nextProps.pool.entries[0].id).then((response) => {
            this.setState(
              () => {
                return {
                  entries: response.data,
                  gameType: 'pool',
                };
              },
              () => {
                this.initializeCancelation(this.state.entries);
              }
            );
          });
        } else if (nextProps.pool.game_type === 'bracket') {
          console.log('Get brackets Entries', nextProps.pool);
          API.fetchBracketEntry(nextProps.pool.bracket_entries[0].id).then((response) => {
            console.log("CANCELRESPONSE:", response);
            this.setState(
              () => {
                return {
                  entries: response.data,
                  gameType: 'bracket',
                };
              },
              () => {
                this.initializeCancelation(this.state.entries);
              }
            );
          });
        } else if (nextProps.pool.game_type === 'survivor') {
          console.log('Get survivor Entries', nextProps.pool);
          API.fetchSurvivorEntry(nextProps.pool.survivor_entries[0].id).then((response) => {
            this.setState(
              () => {
                return {
                  entries: response.data,
                  gameType: 'survivor',
                };
              },
              () => {
                this.initializeCancelation(this.state.entries);
              }
            );
          });
        } else if (nextProps.pool.game_type === 'group_pool') {
          console.log(nextProps);
          //TODO there are missing some parameters of the pool if the user is registered
          let myEntry = null;
          console.log('Get Group Pool Entries', nextProps.pool);
          if (nextProps.hasOwnProperty('allEntries')) {
            myEntry = nextProps.allEntries.group_pool_entry;
          } else {
            myEntry = nextProps.pool.first_entry;
          }

          console.log(myEntry);
          API.fetchPoolGroupsEntry(myEntry.id).then((response) => {
            this.setState(
              () => {
                return {
                  entries: response.data,
                  gameType: 'group_pool',
                };
              },
              () => {
                this.initializeCancelation(this.state.entries);
              }
            );
          });
        } else {
          console.log('Get Entries Pool', nextProps.pool);
          // console.log(nextProps.allEntries)
          if (nextProps.allEntries !== undefined) {
            if (nextProps.allEntries.entry !== undefined) {
              API.getEntriesInfo(nextProps.allEntries.entry.id).then((response) => {
                this.setState(
                  () => {
                    return {
                      entries: response.data,
                      gameType: 'pool',
                    };
                  },
                  () => {
                    this.initializeCancelation(this.state.entries);
                  }
                );
              });
            } else if (nextProps.allEntries.survivor_entry !== undefined) {
              console.log('Get Survivor', nextProps);
              // API.fetchBracketEntry(nextProps.allEntries.bracket_entry.id)
              //   .then( response => {
              //     this.setState(() => {
              //       return {
              //         entries: response.data,
              //       }
              //     })
              //   });

              this.setState({
                entries: mockSurvivorEntry,
              });
            } else {
              console.log('Get bracket', nextProps.pool);
              API.fetchBracketEntry(nextProps.allEntries.bracket_entry.id).then((response) => {
                this.setState(
                  () => {
                    return {
                      entries: response.data,
                      gameType: 'bracket',
                    };
                  },
                  () => {
                    this.initializeCancelation(this.state.entries);
                  }
                );
              });
            }
          }
        }
      } else if (nextProps.pool.brackets_entry_count !== undefined) {
        console.log('Get brackets Entries');
        API.fetchBracketEntry(nextProps.allEntries.bracket_entry.id).then((response) => {
          this.setState(
            () => {
              return {
                entries: response.data,
                gameType: 'bracket',
              };
            },
            () => {
              this.initializeCancelation(this.state.entries);
            }
          );
        });
      } else if (nextProps.pool.game_type === 'survivor') {
        // console.log('Get survivor Entries',  nextProps.allEntries);
        API.fetchSurvivorEntry(nextProps.allEntries.survivor_entry.id).then((response) => {
          // console.log(response)
          this.setState(
            () => {
              return {
                entries: response.data,
                gameType: 'survivor',
              };
            },
            () => {
              this.initializeCancelation(this.state.entries);
            }
          );
        });
      } else if (nextProps.pool.game_type === 'group_pool') {
        //TODO there are missing some parameters of the pool if the user is registered
        console.log(' Get Group Pool Entries ', nextProps.allEntries.group_pool_entry.id);
        let entryId = nextProps.allEntries.group_pool_entry.id;
        API.fetchPoolGroupsEntry(entryId).then((response) => {
          this.setState(
            () => {
              return {
                entries: response.data,
                gameType: 'group_pool',
              };
            },
            () => {
              this.initializeCancelation(this.state.entries);
            }
          );
        });
      } else {
        console.log('this', this);
        API.getEntriesInfo(nextProps.allEntries.entry.id).then((response) => {
          this.setState(
            () => {
              return {
                entries: response.data,
                gameType: 'pool',
              };
            },
            () => {
              this.initializeCancelation(this.state.entries);
            }
          );
        });
      }
      console.log(true);
      return true;
    }
    if (this.state.selectAll !== nextState.selectAll) {
      return true;
    }
    if (this.state.changedCancelModel !== nextState.changedCancelModel) {
      return true;
    }
    if (this.state.entries !== nextState.entries) {
      if (nextState.entries !== null) {
        // console.log('entries Updated');
        return true;
      }
    }
    if (nextState.cancelationList !== null) {
      // console.log('different cancelationList');
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.pool !== undefined) {
      this.setState({ logged: this.props.logged });
    }
    // console.log('Panel Mounted', this.state)
  }
  componentDidUpdate(prevState, prevProps) {}

  render() {
    let conditionalComponent;
    if (this.props.showPanel && this.props.pool !== undefined && this.props.pool !== null) {
      if (this.state.cancelationSuccess) {
        setTimeout(() => {
          if (this.props.updateStateFunc !== undefined) {
            this.props.updateStateFunc();
          } else if (this.props.updateStateFuncCancel !== undefined) {
            this.props.updateStateFuncCancel();
          }
          this.setState({
            cancelationSuccess: null,
            cancelationList: [],
            entries: null,
            errorCupo: false,
            logged: utils.isLogged(),
            redirect: false,
            refresh: null,
            respuestas: null,
            userData: utils.getUserInfo(),
          });
        }, 1800);
      }
      conditionalComponent = (
        <div>
          <Modal
            backdropClassName="backdropCancelRegister"
            dialogClassName="cancelModal modal-dialog-xxxs"
            className="cancelModal"
            show={this.props.showPanel}
            onHide={this.props.updateStateFunc}
          >
            {this.state.cancelationSuccess === null ? (
              this.props.showPanel && this.state.mounted ? (
                <div id="register-panel">
                  <div className='flex-row no-padding'>
                    <div className='cancel-title-container'>
                      <div>CANCELAR REGISTRO</div>
                      <div>
                        <i
                          className="ion-ios-close-empty generic-button-close"
                          onClick={(e) => {
                            if (this.props.dispatch) {
                              this.props.updateStateFunc(this.props.dispatch);
                            } else {
                              this.props.updateStateFunc();
                            }
                            this.resetCancelation();
                          }}></i>
                      </div>
                    </div>
                  </div>

                  <div className='flex-row'>
                    <div className='flex-col text-center mt-1'>
                      {`Elige los registros para cancelar tus entradas en ${
                        this.props.type === 'survivor' ? ' el survivor' : ' la quiniela'
                      } :`}
                    </div>
                  </div>
                  <div className='flex-row'>
                    <div className='flex-col text-center'>
                      <div className='cancel-title-items'>
                        <DisplaySport sports={this.props.pool.sport} type={'pool'} size={'22px'}/>
                        <div className="flag-wrapper">
                          <DisplayFlag type="pool" flag={this.props.pool} />
                        </div>
                        {this.props.pool.point_format === 'line' && (<img className="score-icons" src={line} />) || ''}
                        {this.props.pool.point_format === 'natural' && (<img className="score-icons" src={natural} />) }
                        <img className="score-icons" src={this.props.pool.guaranteed_prize !== null ? garantizada : null} />
                        <img className="score-icons" src={this.props.pool.brackets_entry_count !== undefined ? bracket : null} />
                        <span className="registro-main-title font18">
                        {this.props.pool.name_replaced !== undefined ? this.props.pool.name_replaced : this.props.pool.name}
                        </span>
                        {/* <DisplaySport sports={this.props.pool.sport} type={'pool'} size={'30px'}/> */}
                      </div>
                    </div>
                  </div>

                  <form>
                    <div className='flex-row'>
                      <div className='flex-col mt-1 text-center'>
                        <CancelList
                          entries={this.state.entries}
                          gameType={this.state.gameType}
                          cancelationModel={this.state.cancelationModel}
                          handleCancelation={this.handleCancelation}
                        />
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="flex-container justify align ">
                        <label
                          id={'selectAll'}
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleSelectAll();
                          }}
                        >
                          <input
                            type="checkbox"
                            name="selectAll"
                            id={`selectAll`}
                            checked={this.state.selectAll}
                            value={this.state.selectAll}
                            onChange={() => {}}
                          />
                          <div className={`checkbox-custom`}></div>
                          <span>Seleccionar todas las entradas</span>
                        </label>
                      </div>
                    </div>
                  </form>

                  <div className="register-buttons">
                    <Button
                      className={'guardar-picks btn-rojo'}
                      bsStyle="danger"
                      onClick={(e) => {
                        if (this.props.dispatch) {
                          this.props.updateStateFunc(this.props.dispatch);
                        } else {
                          this.props.updateStateFunc();
                        }
                        this.resetCancelation();
                      }}
                    >
                      Regresar
                    </Button>
                    <Button
                      bsStyle="success"
                      className={'guardar-picks'}
                      disabled={this.state.submitting || !this.state.changeCancelationStatus}
                      type="submit"
                      onClick={(e) => {
                        if (this.state.submitting) {
                          return;
                        }
                        this.cancelation();
                        // this.disableClickedButton(e,1500);
                      }}
                    >
                      Continuar
                    </Button>
                  </div>
                </div>
              ) : null
            ) : this.state.cancelationSuccess ? (
              <div id={'register-panel'}>
                <div className="firstSection">
                  <div className="row registro-titulo">
                    <div className="cancelation-msg">
                      {this.state.cancelationResponse.result.entries !== undefined &&
                      this.state.cancelationResponse.result.entries.length > 1
                        ? 'Se cancelaron tus registros correctamente'
                        : this.state.cancelationResponse.result.remaining !== undefined &&
                          this.state.cancelationResponse.result.remaining.length > 1
                        ? 'Se cancelaron tus registros correctamente'
                        : 'Se canceló tu registro correctamente'}
                    </div>
                    {/*<button className={'cerrar-panel'} onClick={() => {this.props.updateStateFunc(); this.resetCancelation()}}>*/}
                    {/*<div className='btn-icon-close'><img src={closeW} className={'closeIcon'}/></div>*/}
                    {/*</button>*/}
                  </div>
                  <span className={'cancelRegister '}>
                    <i className="ion-checkmark-circled"></i>
                  </span>
                </div>
              </div>
            ) : (
              <div id={'register-panel'}>
                <div className="firstSection">
                  <div className="row registro-titulo">
                    <div className="cancelation-msg">
                      {'Intenta nuevamente, hubó un error al cancelar tus registros '}
                    </div>
                    {/*<button className={'cerrar-panel'} onClick={this.props.updateStateFunc}>*/}
                    {/*<span>X</span>*/}
                    {/*</button>*/}
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      );
    } else {
      conditionalComponent = null;
    }
    // console.log('Render Panel',this.props);
    return conditionalComponent;
  }
}

export default CancelRegister;
