import React from 'react';
import NavigationBar from '../NavigationBar';
import pickLoader from '../../assets/images/quinielasIconBlanco-50.png';
import utils from '../../utils/utils';
import Loading from '../Loading/Loading';
import Footer from '../Footer/Footer';
import ReportIssueFormulario from './ReportIssueFormulario';
import * as Sentry from '@sentry/browser';

/**
 * This component renders the content of the error Component
 * @param errorObject   { Object }
 * @param errorInfo     { errorInfo }
 * @return Component
 */
const Container = ({ errorObject, errorInfo }) => {
  return (
    <div className="container">
      <div className=" col-xs-12 col-md-10 col-md-offset-1  perdido">
        <div className="content-lost">
          <span className="title flex-container justify align ">
            <div>
              <i className="ion-heart-broken"></i> Parece que algo se rompió:
            </div>
          </span>
          <div className="row">
            <span className=" col-xs-12 col-md-10 col-md-offset-1 question">
              El equipo de Pickwin ha sido notificado.
            </span>
          </div>
          <div className="row">
            <span className=" col-xs-12 question">
              <div className="flex-container justify column align">
                <strong>Si quieres ayudarnos cuéntanos que pasó.</strong>
              </div>
            </span>
          </div>
          <div className="row">
            <details className=" hidden text-center col-xs-12 col-md-10 col-md-offset-1 message">
              <summary>
                {' '}
                <i className="ion-eye" /> Ver Información del error:
              </summary>
              <pre>{!errorObject ? 'Error' : errorObject.message}</pre>
              <pre>{!errorInfo ? 'Error' : errorInfo.componentStack}</pre>
            </details>
            <div className="text-center col-xs-12 col-md-10 col-md-offset-1 message">
              <div className="flex-row responsive-sm">
                <ReportIssueFormulario errorInfo={errorInfo} errorObject={errorObject} />
              </div>
            </div>
          </div>
          <div className=" hidden contacto col-xs-10 col-xs-offset-1">
            <span>
              <a href="mailto:hola@pickwin.net">
                <i className="ion-email"></i> hola@pickwin.net
              </a>
            </span>
            <span className="">
              <i className="ion-paper-airplane"></i> O si lo prefieres en los chats de las
              quinielas.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: null,
      userData: null,
      hasError: false,
      errorInfo: null,
      errorObject: null,
    };
    // console.log(this.state);
  }

  componentDidMount() {
    this.setState({
      logged: utils.isLogged(),
      userData: utils.getUserInfo(),
    });
  }

  componentDidCatch(error, info) {
    console.error('Error', error, info);
    this.setState(
      {
        hasError: true,
        errorInfo: info,
        errorObject: error,
      },
      () => {
        console.error(error);
        Sentry.captureException(error);
      }
    );
  }

  render() {
    // console.log(this.state);
    let conditionalComponent = null;
    if (this.state.hasError && !this.props.isPanel) {
      return (
        <div className="page-wrapper">
          <div className="inner-wrapper">
            <NavigationBar
              isLogged={utils.isLogged()}
              userBalance={this.props.userBalance}
              userData={utils.getUserInfo()}
            />
            <Container errorObject={this.state.errorObject} errorInfo={this.state.errorInfo} />
          </div>
          <div id="push"></div>
          <Footer />
        </div>
      );
    } else if (this.state.hasError && this.props.isPanel) {
      return <Container errorObject={this.state.errorObject} errorInfo={this.state.errorInfo} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
