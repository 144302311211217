import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import Text from '../Text'

function TextField (props, ref) {
  const handleChange = (event) => {
    const value = props.uppercase
      ? event.target.value.toUpperCase()
      : event.target.value
  
    const data = {
      [props.name]: value
    }
  
    props.onChange(data, props.name, value)
  }

  const handleBlur = (event) => {
    props.onBlur(props.name, event.target.value)
  }

  const sharedProps = {
    ref,
    id: `in-${props.name}`,
    spellCheck: false,
    value: props.value,
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: props.disabled,
    placeholder: props.placeholder,
    pattern: props.pattern,
    minLength: props.minLength,
    maxLength: props.maxLength,
    style: {
      borderColor: props.errorMessage ? '#efbcb6' : '#6a6a6a'
    }
  }

  return (
    <Box>
      <Label htmlFor={`in-${props.name}`}>{props.label}</Label>
      {props.textarea && (
        <Textarea
          {...sharedProps}
        />
      )}
      {!props.textarea && (
        <Input
          {...sharedProps}
        />
      )}

     {(props.error && props.error.errorMessage) && (
      <Text as='caption' color={props.error.color} my={8}>
        {props.error.errorMessage}
      </Text>
     )}
    </Box>
  )
}

export default forwardRef(TextField)

const Label = styled.label`
  opacity: 0.9;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 1.5em;
  margin-bottom: 0.6em !important;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
`

const InputBase = css`
  transition: box-shadow 300ms;
  box-shadow: 0 0 1px 3px #8fff0000;
  background-color: #2a2a2a;
  border: 1px solid #6a6a6a;
  color: #FFF;
  padding: 0.3em 0.8em;
  font-size: 1.1em;
  flex-grow: 2;
  outline: none;

  &:focus {
    box-shadow: 0 0 1px 3px #97cf4e;
  }

  /* Chrome/Opera/Safari */
  ::-webkit-input-placeholder {
    color: #bdbdbd;
  }
  /* Firefox 19+ */
  ::-moz-placeholder { 
    color: #bdbdbd;
  }
  /* IE 10+ */
  :-ms-input-placeholder {
    color: #bdbdbd;
  }
  /* Firefox 18- */
  :-moz-placeholder { 
    color: #bdbdbd;
  }
`

const Input = styled.input`
  ${InputBase};
`

const Textarea = styled.textarea`
  ${InputBase};
`
