import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Components
import TextField from '../Form/TextField'
import Button from '../Form/Button'
import Logo from './Logo'
import Text from '../Form/Text'
import PrevModalButton from '../ModalPanel/PrevModalButton'
import ModalBody from '../ModalPanel/ModalBody'
import Content from './Content'

// Hooks
import useAuthContext from '../../hooks/useAuthContext'

// Utils
import API from '../../utils/api'
import isValidEmail from '../../utils/isValidEmail'

export default function ForgotPassword () {
  const { openLoginModal, closeModal } = useAuthContext()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const isValid = isValidEmail(email)

  const handleChangeEmail = (_, value) => {
    setEmail(value)
  }

  const submit = async () => {
    if (!isValid) return setStatus('ERROR')
    setLoading(true)
    try {
      const response = await API.postRecoverPass(email)
      if (response.data.success) {
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
    } catch (error) {
      setStatus('ERROR')
    }
    setLoading(false)
  }

  return (
    <ModalBody>
      <PrevModalButton onClick={openLoginModal} />
      <Logo
        src='/images/Pickwin.png'
        alt='Pickwin logo'
      />
      {(!status || status === 'ERROR') && (
        <Content>
          <div>RECUPERAR PASSWORD</div>
          <Text>Se enviarán instrucciones para cambiar tu contraseña al email con el que te registraste.</Text>
          <TextField
            placeholder='Email'
            onChange={handleChangeEmail}
            iconSrc='/images/Icono Mail.png'
          />
          {status === 'ERROR' && (
            <Text type='error'>El email que ingresaste no existe, por favor ingrésalo nuevamente.</Text>
          )}
          <Button
            onClick={submit}
            loading={loading}
            disabled={loading || !isValid}
            bgColor={isValid ? '#88ba44' : '#787878'}
          >
            Recuperar Contraseña
          </Button>
        </Content>
      )}
      {status === 'SUCCESS' && (
        <Content>
          <Text>Ahora revisa tu correo para restablecer tu contraseña.</Text>
          <i
            className='ion-checkmark-circled'
            style={{ color: '#78BC28', fontSize: '52px', textAlign: 'center' }}
          />
          <div className='btn-wrapper'>
            <Link to='/' onClick={closeModal}>
              <Button className='login-btn' bsStyle='primary' bsSize='large' block bgColor='#78BC28'>
                Regresar al Lobby Principal
              </Button>
            </Link>
          </div>
        </Content>
      )}
    </ModalBody>
  )
}
