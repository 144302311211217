import React from 'react'

import GameInfoPopup from './GameInfoPopup'
import ImageLoader from './ImageLoader'

// Utils
import Iconify from '../../Iconify'
import styled from 'styled-components'

const GameCard = ({ game, setGameSlug, setIsDemo }) => {

  const onLaunchGameClicked = (mode) => {
    setIsDemo(mode)
    // setGameOpened(true)
    setGameSlug(game.name.toLowerCase().replace(/\s/g, '-'))
  }

  return (
    <div className='game-card'>
      {game.rank < 11 && (
        <Ranked>
          <Iconify icon='solar:fire-bold' />
        </Ranked>
      )}
      <a className='game-link' onClick={() => onLaunchGameClicked(false)}>
        <ImageLoader src={game.thumbnail} alt={game.name} />
      </a>
      <GameInfoPopup game={game} onLaunchGameClicked={onLaunchGameClicked} />
    </div>
  )
}

const Ranked = styled.div`
  position: absolute;
  right: 0.6em;
  top: 0.5em;
  background-color: #000000b5;
  width: 1.7em;
  height: 1.7em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  svg {
    color: orangered;
    font-size: 1.4em;
  }
`

export default GameCard
