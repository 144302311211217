import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import API from '../utils/api'
import { setAllowedUser } from '../utils/utils'

const initialState = {}

const AppContext = createContext(initialState)

AppProvider.propTypes = {
  children: PropTypes.node
}

function AppProvider ({ children }) {
  const [isNetworkAvailable, setIsNetworkAvailable] = useState(true)

  useEffect(() => {
    API.fetchIpValidation()
    .then((response) => {
      const ipIsValid = response.data.status
      if (!ipIsValid) {
        setAllowedUser(false)
        window.location.assign('https://na.pickwin.mx')
      } else {
        setAllowedUser(true)
      }
    })
    .catch((error) => {
      console.error(error)
      setIsNetworkAvailable(false)
    })
  }, [])

  return (
    <AppContext.Provider
      value={{
        isNetworkAvailable
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }

