import React, { useEffect } from 'react'
import Formsy from 'formsy-react'

// Components
import Text from '../Text/Text'
import Button from '../Form/Button'
import AbonarFormsy from '../AbonarFormsy'
import Logos from './Logos'
import DepositResponse from './DepositResponse'

// Utils
import API from '../../utils/api'
import utils from '../../utils/utils'
import { publish, subscribe, unsubscribe } from '../../utils/events'

// Hooks
import useMergedState from '../../hooks/useMergedState'

function SpeiPaymentForm (props) {
  const userData = utils.getUserInfo()

  const [state, setState] = useMergedState({
    pdfUrl: null,
    imageUrl: null,
    paymentDataModel: {
      amount: 4,
      other_amount: null,
      gateway: 'openpay',
      method: 'spei',
      name: null,
      last_name: null
    },
    canSubmit: false,
    submitting: false,
    requestStatus: null,
    fieldsWithErrors: null
  })

  useEffect(() => {
    const newPaymentData = state.paymentDataModel

    newPaymentData.amount = props.amount ? props.amount : null
    newPaymentData.other_amount = props.other_amount ? props.other_amount : null

    setState({
      paymentDataModel: newPaymentData,
      mounted: true
    })
  }, [])

  useEffect(() => {
    // Component Did Update
    if (
      state.paymentDataModel.amount !== props.amount ||
      state.paymentDataModel.other_amount !== props.other_amount
    ) {
      setState(prevState => ({
        ...prevState,
        paymentDataModel: {
          ...prevState.paymentDataModel,
          other_amount: props.other_amount,
          amount: props.amount
        }
      }))
    }
  }, [props])

  useEffect(() => {
    subscribe('SMS:ACOUNT_VERIFIED', submitPayment)

    return () => unsubscribe('SMS:ACOUNT_VERIFIED', submitPayment)
  }, [])

  const convertToPesos = (cant, otraCantidad) => {
    switch (cant) {
      case 2: return '$50'
      case 4: return '$200'
      case 5: return '$100'
      case 7: return '$500'
      case 'other': return `$${otraCantidad}`
      default: break
    }
  }

  const handleScrollModal = () => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 250)
  }

  const handleSubmit = (status) => {
    setState({ canSubmit: status })
  }

  const handleCardData = (e, category) => {
    const temporalPaymentData = state.paymentDataModel
    temporalPaymentData[category] = e.target.value

    setState({
      paymentDataModel: temporalPaymentData
    })
  }

  const closeResponse = () => {
    setState({ showResponse: false, error: null })
  }

  const showPaymentResponse = (data) => {
    if (props.blockUi) {
      props.blockUi()
    }
    setState({
      submitting: false,
      showResponse: true,
      newBalance: data.balance
    })
  }

  const showPaymentError = (error, code) => {
    if (props.blockUi) {
      props.blockUi()
    }
    setState({
      submitting: false,
      showResponse: true,
      error: {
        message: getErrorMessage(error),
        code
      }
    })
  }

  const getErrorMessage = (error) => {
    if (typeof error.message === 'object') {
      return error.message.message
    }
    return error.message
  }

  const callbackPayment = (data) => {
    handleScrollModal()

    if (data.success) {
      const reference = data.response.id
      const url =
        process.env.NODE_ENV === 'production'
          ? `https://dashboard.openpay.mx/spei-pdf/myabfqccohuj4kszwr7y/${reference}`
          : `https://sandbox-dashboard.openpay.mx/spei-pdf/mqfki2pbqpbve54kabor/${reference}`
      props.updateUserInfo()

      setState({
        pdfUrl: url,
        imageUrl: `https://api.openpay.mx/barcode/${reference}?width=2&height=80&text=true`
      })
      showPaymentResponse(data)
    } else {
      showPaymentError(data.error, data.code)
    }
  }

  const openPdf = () => {
    window.location = state.pdfUrl
  }

  const submitPayment = () => {
    const userData = utils.getUserInfo()
    setState({ submitting: true })

    if (!userData.identity_verified) {
      return publish('SMS:SHOW_MODAL')
    }

    if (props.blockUi) {
      props.blockUi()
    }
    API.postDepositConvenience(state.paymentDataModel)
      .then((response) => {
        callbackPayment(response.data)
      })
      .catch((err) => {
        console.error(err)
        showPaymentError(err)
      })
  }

  if (!props.show) return null

  return (
    <>
      <DepositResponse
        amount={props.amount}
        other_amount={props.other_amount}
        dispatch={props.dispatch}
        showDepositResponse={state.showResponse}
        closeDepositResponse={closeResponse}
        nick={utils.getUserInfo().nick}
        imageUrl={state.imageUrl}
        pdfUrl={state.pdfUrl}
        openPdf={openPdf}
        convertToPesos={convertToPesos}
        newUserBalance={state.newBalance}
        error={state.error}
        updateUserInfo={props.updateUserInfo}
      />

      <div className='flex-row row-no-padding mt-1'>
        <div className='flex-col col-no-padding' style={{ marginLeft: '3rem', alignItems: 'flex-start' }}>
          <div className='flex-row row-center'>
            <h4 style={{ color: '#e5e6e8' }} className='flex-col col-center text-center'>
              ¿CÓMO ABONAR?
            </h4>
          </div>
          <div className='flex-row row-center'>
            <ol className=' flex-col col-offset-5 col-90' style={{ fontSize: '12px' }}>
              {!userData.name ? <li> Dínos tu nombre</li> : null}
              <li> Obten tu ficha de depósito</li>
              <li> Imprime o guarda tu ficha</li>
              <li> Sigue las instrucciones de la ficha para realizar la transferencia</li>
              <li> Tu saldo se verá reflejado en 30 minutos aproximadamente</li>
            </ol>
          </div>
        </div>
        <div flex-col>
          {props.amountComponent}
        </div>
      </div>
      {!userData.name
        ? (
          <Formsy
            onValid={() => {
              handleSubmit(true)
            }}
            onInvalid={() => {
              handleSubmit(false)
            }}
          >
            <div className='flex-row row-center row-no-padding mt-1'>
              <AbonarFormsy
                col='col-45'
                type='TEXT'
                required
                resetForm={null}
                defaultValue=''
                name='name'
                placeholder='NOMBRE'
                validations={{ minLength: 2 }}
                validationError='Utiliza tu nombre.'
                inputHandler={handleCardData}
              />
              <AbonarFormsy
                col='col-50 col-offset-5'
                type='TEXT'
                required
                resetForm={null}
                defaultValue=''
                name='last_name'
                placeholder='APELLIDOS'
                validations={{ minLength: 2 }}
                validationError='Utiliza tu apellido.'
                inputHandler={handleCardData}
              />
            </div>
          </Formsy>
          )
        : null}
      <Text className={`flex-row row-center ${!userData.name ? '' : 'hide'}`} size={14}>
        Por favor dinos tu nombre y apellidos. Estos serán verificados al momento de realizar
        alguna solicitud de retiro.
      </Text>
      <div className='flex-row row-center '>
        <Button
          onClick={submitPayment}
          disabled={props.errorOtraCantidad || (!userData.name && !state.canSubmit)}
          type='submit'
          className='btn-pick btn btn-sm btn-default'
          bgColor='#88ba44'
          style={{
            maxWidth: 300,
            margin: '1em auto'
          }}
        >
          <span className=''>
            <div>
              {' '}
              <i className='ion-ios-barcode-outline' />
              {' Obtener tu ficha de depósito '}
            </div>
          </span>
        </Button>
      </div>
      <Logos />
    </>
  )
}

export default SpeiPaymentForm
