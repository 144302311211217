import React from 'react'
import numeral from 'numeral'

// Assets
import rewardIcon from '../../../../../assets/images/iconRewardBorder-50.png'
import inGameIcon from '../../../../../assets/images/iconInGame-50.png'

/**
 * This Component Renderes the Progress bar of the achievements and prizes
 * of the Referrals program
 * @param referal  { Object  }
 * @param progress { Number  }
 * @param steps    { Number  }
 * @param type     { String  }
 * @return Component
 */
const ReferralProgressBar = ({ referal, progress, steps, type }) => {
  // setTimeout( () => {
  //   let searchThis = `#referal-${referal.id}-${type}`;
  //   // console.log('SetIT', searchThis)
  //   $(searchThis).animate({
  //       width: progress
  //     }, 500);

  //     steps.forEach( step => {
  //       searchThis = `#rewardObtained-${step.percentage}-${referal.id}`
  //       // console.log(searchThis);
  //       // console.log($(searchThis));
  //       $(searchThis).animate({
  //         opacity: "1",
  //       }, 250);
  //     });

  // }, 500 );

  // console.log(steps);

  const listSteps = steps.map((step) => {
    const isObtained = referal.amount_given / 100 > step.prize

    return (
      <div
        key={step.percentage}
        className='step-progress-referral '
        style={{ left: `${step.percentage}%` }}
      >
        <div className='indicator flex-col col-no-padding'>
          <div className='flex-row row-no-padding'>{numeral(step.prize).format('$0,000')}</div>
          {isObtained && type === 'progress'
            ? (
              <i
                style={{ color: '#75b727' }}
                id={`rewardObtained-${step.percentage}-${referal.id}`}
                className='ion-checkmark-round'
              />
              )
            : null}
        </div>
      </div>
    )
  })

  console.log('.....ReferralProgressBar....')
  console.log(progress)

  return (
    <div
      className={` referrer-progress-bar flex-row row-center row-no-padding ${
        type === 'progress' ? 'withMargin' : ''
        }`}
    >
      <div className='type-progress-icon flex-col col-10 col-center text-center'>
        <img className='type-progress-icon' src={type === 'progress' ? rewardIcon : inGameIcon} />
      </div>
      <div className='  flex-col '>
        {type === 'referalEntries'
          ? (
            <div className='flex-row row-center extra-info'>
              <div className='message-referer flex-col'>
                {referal.referred_user.nick} ha jugado
                <strong className='amount'>
                  {numeral(referal.amount_played / 100).format('$0,000')}
                </strong>
              </div>
            </div>
            )
          : (
            <div className='flex-row row-center extra-info'>
              <div className='message-referer flex-col'>
                Puedes ganar
                <strong className='amount'>
                  {numeral(referal.next_prize / 100).format('$0,000')}
                </strong>
              </div>
            </div>
            )}
        <div className='flex-row row-center'>
          <div className='flex-col '>
            <div className='progress-bar-background'>
              <div
                id={`referal-${referal.id}-${type}`}
                data-tip
                data-for={`progress-bar-${referal.id}`}
                className='referral-progress flex-container justify align'
                style={
                  type !== 'referalEntries'
                    ? { width: referal.levels_completed * 25 + '%' }
                    : { width: referal.progress + '%' }
                }
              />
              {listSteps}
            </div>
          </div>
          {progress === '100%'
            ? (
              <i className='ion-checkmark-circled' style={{ color: '#78BC28', fontSize: '1.2em' }} />
              )
            : null}
        </div>
      </div>
    </div>
  )
}

export default ReferralProgressBar
